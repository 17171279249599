import { useMemo } from "react";
import {
    formatTaxonPrefixingParent,
    getTaxonById,
    getTaxonomyByField,
} from "../../api/compute/Taxon";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useFormatter } from "../../hooks/useFormatter";

type PieLegendProps = {
    data: any[];
    isTcrMode: boolean;
    taxonField: string;
    selectedValue: string | undefined;
    onClickHandler?: (event) => any;
};

const onClickLegendItem = (event, item, handler) => {
    if (handler != null) {
        event.value = item;
        handler(event);
    }
};

const getItemName = (id, field, taxonomy, selectedValue) => {
    let name = "";

    if (!field || !taxonomy) {
        return id;
    }

    if (field !== "SizeClassification") {
        let level = field === "Region" ? "Region" : "3 Sector";

        if (selectedValue && selectedValue === "Theme") {
            level = "4 Subsector";
        }

        name = formatTaxonPrefixingParent(
            getTaxonById(id, [taxonomy], 0),
            [taxonomy],
            level
        );
    }

    return name;
};

export default function PieLegend({
    data,
    taxonField,
    isTcrMode,
    onClickHandler,
    selectedValue,
}: PieLegendProps) {
    const environment = useEnvironment();
    const taxonFields = environment.get("taxonomyFields");
    const format = useFormatter();
    const taxonomyType = useMemo(() => {
        if (taxonField === "etfclass" || taxonField === "etfgeo") {
            return "ETF";
        } else {
            return "security";
        }
    }, [taxonField]);
    const rawTaxonomies = environment.get("rawTaxonomies");

    return (
        data && (
            <div className="tWhatWherePie-tableContainer">
                <table className="tWhatWherePie-table">
                    <tbody>
                        {data.map((item) => {
                            const name = getItemName(
                                item["id"],
                                taxonField,
                                getTaxonomyByField(
                                    taxonField,
                                    taxonomyType,
                                    taxonFields,
                                    rawTaxonomies
                                ),
                                selectedValue
                            );

                            return (
                                <tr
                                    style={
                                        onClickHandler
                                            ? { cursor: "pointer" }
                                            : {}
                                    }
                                    onClick={(e) =>
                                        onClickLegendItem(
                                            e,
                                            item,
                                            onClickHandler
                                        )
                                    }
                                    key={`${item["id"]}__${item["type"]}`}
                                >
                                    <th className="tWhatWherePie-itemName">
                                        <span
                                            title={name}
                                            className="tWhatWherePie-itemNameValue"
                                        >
                                            {name}
                                        </span>
                                    </th>
                                    {isTcrMode && (
                                        <td
                                            className="tWhatWherePie-itemTcr"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item["rate"] != null
                                                        ? format.tcr(
                                                              item["rate"],
                                                              "HTML"
                                                          )
                                                        : "-",
                                            }}
                                        ></td>
                                    )}
                                    <td
                                        className="tWhatWherePie-itemWeight"
                                        dangerouslySetInnerHTML={{
                                            __html: format.custom("number", {
                                                options: {
                                                    isPercentage: true,
                                                    notAvailable: {
                                                        input: 0,
                                                        output: 0,
                                                    },
                                                },
                                                output: "HTML",
                                                value: item["weight"],
                                                valueHelper: null,
                                            }),
                                        }}
                                    ></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        )
    );
}
