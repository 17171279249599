import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { Alternatives } from "./Alternatives";

type TabAlternativesProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabAlternatives({ dataManager }: TabAlternativesProps) {
  return (
    <div>
      <Alternatives portfolioDataManagement={dataManager} />
    </div>
  );
}
