/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/_CommonPublicationsSubscriptions
 * @summary Common functions and data structrues for Publications and
 *      Subscriptions
 *
 */

import { deepClone } from "../../deepClone";
import { endpoints } from "../endpoints";
import { httpAll } from "../../httpAll";
import { _Base } from "../_Base";

export const PUBLISHER_USER_ID = 35 as const;

export const SHARED_OBJECT_TYPE_SERVER_TO_UI = {
  // used in app-support/Storage
  BASKET: "list",
  PORTFOLIO: "list",
  COLLECTION: "list",
  RANKING_ABOUT_TARGET: "ranking",
  PREFERENCE_INDEX: "strategy",
  SYSTEMATIC_PRODUCT: "systematicPortfolio",
  COMBINED_STRATEGY: "combinedStrategy",
  COMBINED_PRODUCT: "combinedProduct",
  SCREENING: "SCREENING",
  PREFERENCE: "PREFERENCE",
} as const;

export const SHARED_OBJECT_TYPE_UI_TO_SERVER = {
  list: "COLLECTION",
  ranking: "RANKING_ABOUT_TARGET",
  strategy: "PREFERENCE_INDEX",
  systematicPortfolio: "SYSTEMATIC_PRODUCT",
  combinedStrategy: "COMBINED_STRATEGY",
  combinedProduct: "COMBINED_PRODUCT",
  screening: "SCREENING",
} as const;

export class _CommonPublicationsSubscriptions extends _Base {
  decode(response: any) {
    const pubSubItems: any = [];

    const rawSharedObjects = response.data;
    for (const rawSharedObject of rawSharedObjects) {
      pubSubItems.push({
        id: rawSharedObject.objectId,
        ownerId: rawSharedObject.ownerId,
        pubSubId: rawSharedObject.id,
        pubSubTypeRaw: rawSharedObject.objectType,
      });
    }

    return pubSubItems;
  }

  decodeAll(response: any) {
    const decoded: any = {};

    for (const key in response) {
      decoded[key] = this.decode(response[key]);
    }

    return decoded;
  }

  /**
   *
   * @param {string} type - one of "publications" or "subscriptions"
   * @param {array} responseDecoded - array of
   * @returns
   */
  async getDetails(type: any, responseDecoded: any) {
    if (type == null && (type !== "publications" || type !== "subscriptions")) {
      throw new Error(
        'Type not specified. Must be one of "publications" or "subscriptions"'
      );
    }

    if (responseDecoded.length === 0) {
      return responseDecoded;
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.commons.get;

    const pubSubTypeRaw: keyof typeof SHARED_OBJECT_TYPE_SERVER_TO_UI =
      responseDecoded[0].pubSubTypeRaw;
    const pubSubIds = responseDecoded.map(function (item: any) {
      return item.id;
    });

    const params = [
      {
        classType: pubSubTypeRaw,
        id: pubSubIds,
        extendedRequest: {
          onDemandResult: [
            {
              dimension: "name",
            },
            {
              dimension: "ownerId",
            },
            {
              dimension: "type",
            },
          ],
        },
      },
    ];

    const responseGet = await this.preparePost(url, params, null);
    const responseUnboxed = responseGet.data[0].rows;

    const responseDecodedMap: any = {};
    for (const sharedObjectDecoded of responseDecoded) {
      responseDecodedMap[sharedObjectDecoded.id] = sharedObjectDecoded;
    }

    const pubSubTypes = SHARED_OBJECT_TYPE_SERVER_TO_UI;
    const response = deepClone(responseUnboxed);
    for (const pubSubItem of response) {
      pubSubItem.isReadOnly = true;
      pubSubItem.pubSubType = pubSubTypes[pubSubTypeRaw];

      switch (type) {
        case "publications": {
          pubSubItem.publicationId = responseDecodedMap[pubSubItem.id].pubSubId;

          break;
        }
        case "subscriptions": {
          pubSubItem.subscriptionId =
            responseDecodedMap[pubSubItem.id].pubSubId;

          break;
        }
      }
    }

    return response;
  }

  /**
   * TODO check loop
   * @param type
   * @param response
   * @returns
   */
  async getDetailsAll(type: any, response: any) {
    const subscribed: any = {};

    for (const key in response) {
      subscribed[key] = this.getDetails(type, response[key]);
    }

    return httpAll(subscribed);
  }
}
