import { Box, Button, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import Tippy from "@tippyjs/react";
import { addYears } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Preferences } from "../../../../../../api/account/Preferences";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { messageError, messageSuccess } from "../../../../utils";
import { TDate } from "../../../../../../trendrating/date/TDate";
import { deepClone } from "../../../../../../deepClone";

const adapter = new AdapterDayjs();
type Props = {
  strategy: any;
  strategies: any[];
  storage: any;
  onTrackUntrack: Function;
};

export default function TrackAction({
  onTrackUntrack,
  strategies,
  strategy,
  storage,
}: Props) {
  const environment = useEnvironment();
  const configuration = useMemo(() => storage.getConfiguration(), [storage]);
  const preferencesAPI = useMemo(
    () => new Preferences(environment.get("setup")),
    [environment]
  );
  const strategiesAPI = useMemo(
    () => new Strategies(environment.get("setup")),
    [environment]
  );
  const maxCardinality = useMemo(
    () => configuration.pages.strategyTracker.maxCardinality,
    [configuration.pages.strategyTracker.maxCardinality]
  );
  const trackingDay = useMemo(() => strategy["params"]["tracking"], [strategy]);
  const label = useMemo(
    () => (trackingDay != null ? "Stop tracking" : "Start tracking"),
    [trackingDay]
  );
  const title = useMemo(
    () =>
      trackingDay != null
        ? "Stop tracking this strategy"
        : "Start tracking this strategy",
    [trackingDay]
  );
  const [trackedStrategiesIds, setTrackedStrategiesIds] = useState(
    preferencesAPI.getTrackedStrategies()
  );
  const { broadcast } = useBroadcast();
  const tippiRef = useRef<any>(null);
  const [showTrackPanel, setShowTrackPanel] = useState(false);
  const [showUntrackPanel, setShowUntrackPanel] = useState(false);

  const _getDate = useCallback((type?) => {
    var date = new Date();
    var today = date.getUTCDay();
    var delta = 1;

    switch (type) {
      case "TRACKING":
      case "INCEPTION":
      default: {
        switch (today) {
          case 0: {
            delta = 2;
            break;
          } // Sunday
          case 1: {
            delta = 3;
            break;
          } // Monday
          default: {
            delta = 1;
          } // Other days
        }
        date.setDate(date.getUTCDate() - delta);
      }
    }

    return date;
  }, []);

  const initTrackingDate = useMemo(() => _getDate("TRACKING"), [_getDate]);

  const [trackingDate, setTrackingDate] = useState(initTrackingDate);

  const onCancel = useCallback(() => {
    if (tippiRef.current) {
      tippiRef.current._tippy.hide();
    }
  }, []);
  const onTrack = useCallback(async () => {
    const date = TDate.dateToIso8601(trackingDate);
    var strategyToUpdate = deepClone(strategy);
    if (strategy.LEGACY != null) {
      strategyToUpdate.LEGACY.params.indexContext.trackingDay = date;
    } else {
      strategyToUpdate.params.tracking = {
        trackingDate: date,
      };
    }
    const strategyUpdated = await strategiesAPI.update(strategyToUpdate);
    var trackedStrategies = trackedStrategiesIds;
    var trackedStrategiesUpdated = trackedStrategies
      ? deepClone(trackedStrategies)
      : [];
    trackedStrategiesUpdated.push(Number(strategy.id));
    await preferencesAPI.setPreferenceKey("indexes", trackedStrategiesUpdated);
    setTrackedStrategiesIds(trackedStrategiesUpdated);
    const [channel, msg] = messageSuccess(
      `You have started to track <strong>${strategy.name}</strong>!`
    );
    broadcast(channel as string, msg);
    onTrackUntrack(strategyUpdated);

    // ****************** USAGE ********************
    var usage = window.App.usage;
    var info = {
      action: "START_TRACKING",
      actionParam: strategy?.["id"],
      function: "STRATEGY_BUILDER",
    };
    usage.record(info);
    // ****************** USAGE ********************
  }, [
    broadcast,
    onTrackUntrack,
    preferencesAPI,
    strategiesAPI,
    strategy,
    trackedStrategiesIds,
    trackingDate,
  ]);

  const onStopTrack = useCallback(
    async (strategyId: number, openCalendar: boolean) => {
      var strategyToUpdate = await strategiesAPI.getById(strategyId);
      if (strategyToUpdate.LEGACY != null) {
        delete strategyToUpdate.LEGACY.params.indexContext.trackingDay;
      } else {
        strategyToUpdate.params.tracking = null;
      }
      const strategyUpdated = await strategiesAPI.update(strategyToUpdate);
      const trackedStrategiesUpdated = trackedStrategiesIds?.filter((id) => {
        if (id !== strategyId) {
          return true;
        }
        return false;
      });
      await preferencesAPI
        .setPreferenceKey("indexes", trackedStrategiesUpdated)
        .then(
          () => {
            setTrackedStrategiesIds(trackedStrategiesUpdated ?? []);

            const [channel, msg] = messageSuccess(
              `Stopped tracking ${strategyToUpdate.name} successfully!`
            );
            broadcast(channel as string, msg);
            if (openCalendar) {
              setShowTrackPanel(true);
            } else {
              onTrackUntrack(strategyUpdated);
            }
            setShowUntrackPanel(false);

            // ****************** USAGE ********************
            var usage = window.App.usage;
            var info = {
              action: "STOP_TRACKING",
              actionParam: strategyToUpdate?.["id"],
              function: "STRATEGY_BUILDER",
            };
            usage.record(info);
            // ****************** USAGE ********************
          },
          () => {
            const [channel, msg] = messageError(
              `Something went wrong on stop tracking ${strategyToUpdate.name}...`
            );
            broadcast(channel as string, msg);
          }
        );
    },
    [
      broadcast,
      onTrackUntrack,
      preferencesAPI,
      strategiesAPI,
      trackedStrategiesIds,
    ]
  );

  const onTriggerPopup = useCallback(async () => {
    if (strategy.id) {
      if (strategy["params"]["tracking"] != null) {
        // stop tracking
        tippiRef.current._tippy.hide();
        onStopTrack(strategy.id, false);
      } else {
        // start tracking
        if (
          trackedStrategiesIds == null ||
          trackedStrategiesIds.length < maxCardinality
        ) {
          setShowTrackPanel(true);
          setShowUntrackPanel(false);
        } else {
          setShowUntrackPanel(true);
          setShowTrackPanel(false);
        }
      }
    }
  }, [maxCardinality, onStopTrack, strategy, trackedStrategiesIds]);
  return (
    <Tippy
      ref={tippiRef}
      trigger={/*trackingDay != null ? "none" :*/ "click"}
      interactive
      onTrigger={onTriggerPopup}
      content={
        <Box
          position={"relative"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          p={1}
        >
          {showTrackPanel && (
            <TrackPanel
              maxCardinality={maxCardinality}
              initTrackingDate={initTrackingDate}
              setTrackingDate={setTrackingDate}
              onTrack={onTrack}
              onCancel={onCancel}
            />
          )}
          {showUntrackPanel && (
            <UntrackPanel
              maxCardinality={0}
              onStopTrack={onStopTrack}
              strategiesAPI={strategiesAPI}
              trackedStrategiesIds={trackedStrategiesIds}
            />
          )}
          {!showUntrackPanel && !showTrackPanel && (
            <Typography>
              Save this strategy before start tracking it!
            </Typography>
          )}
        </Box>
      }
      allowHTML
      theme="security-tooltip"
    >
      <li className="menu__item" title={title}>
        {label}
      </li>
    </Tippy>
  );
}

type TrackPanelProps = {
  maxCardinality: number;
  initTrackingDate: Date;
  setTrackingDate: Function;
  onTrack: Function;
  onCancel: Function;
};
const TrackPanel = ({
  maxCardinality,
  initTrackingDate,
  setTrackingDate,
  onTrack,
  onCancel,
}: TrackPanelProps) => {
  const [actualDate, setActualDate] = useState(adapter.date(initTrackingDate));
  return (
    <>
      <Typography textAlign={"left"}>
        From the tracking date the strategy will be computed on a daily basis.
        <br />
        {`Tracked strategies are displayed on top of the alerts page. You can track up to ${maxCardinality} strategies.`}
        <br />
        Start tracking from closing prices:
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CalendarPicker
          maxDate={adapter.date(initTrackingDate)}
          minDate={adapter.date(addYears(initTrackingDate, -1))}
          date={actualDate}
          onChange={(newTrackingDate) => {
            const _date: any = newTrackingDate?.toDate();
            setTrackingDate(_date);
            setActualDate(adapter.date(_date));
          }}
          readOnly={false}
        />
      </LocalizationProvider>

      <Box display={"flex"} justifyContent={"space-between"}>
        <Button onClick={() => onTrack()}>Track</Button>
        <Button onClick={() => onCancel()} variant="tr_button_cancel">
          Cancel
        </Button>
      </Box>
    </>
  );
};

type UntrackPanelProps = {
  maxCardinality: number;
  onStopTrack: Function;
  strategiesAPI: any;
  trackedStrategiesIds: any;
};
const UntrackPanel = ({
  maxCardinality,
  onStopTrack,
  strategiesAPI,
  trackedStrategiesIds,
}: UntrackPanelProps) => {
  const [returningComponent, setReturningComponent] = useState<any>(null);

  useEffect(() => {
    strategiesAPI.fetch(trackedStrategiesIds ?? [], ["name"]).then((data) => {
      if (data.status === "OK") {
        data = data.data[0].rows;
        let content: any[] = [];
        content.push(
          <Typography>{`You can track up to ${maxCardinality} strategies.`}</Typography>
        );
        const arrItems: any = data.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{item.name}</Typography>
            <Button onClick={() => onStopTrack(item.id, true)} size="small">
              Stop tracking
            </Button>
          </Box>
        ));
        content = [...content, ...arrItems];
        setReturningComponent(content);
      } else {
        setReturningComponent(null);
      }
    });
  }, [maxCardinality, onStopTrack, strategiesAPI, trackedStrategiesIds]);

  return returningComponent;
};
