import React, { useMemo } from "react";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { Properties } from "../../../../../api/Properties";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { ColumnDefinition } from "tabulator-tables";

type Props = {
  data: any[];
};

export default function LastAllocationsTable({ data }: Props) {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useFormatter();
  const label = useMemo(() => {
    return new Properties({
      properties: envSetup["properties"],
    });
  }, [envSetup]);

  const lastAllocationsColumns = useMemo(() => {
    let _columns: ColumnDefinition[] = [];
    if (!data) {
      return _columns;
    }
    _columns = [
      {
        field: "weight",
        sorter: "number",
        formatter: (cell, object) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["weight"],
            valueHelper: null,
          });
        },
        title: label.get("weight", 0, null),
      },
      {
        sorter: "alphanum",
        field: "isin",
        title: label.get("isin", 0, null),
      },
      {
        sorter: "alphanum",
        field: "ticker",
        title: label.get("ticker", 0, null),
      },
      {
        sorter: "alphanum",
        field: "name",
        title: label.get("name", 0, null),
      },
      {
        field: "vc",
        sorter: "number",
        title: label.get("vc", 0, null),
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return formatter.custom("price", {
            options: {
              isPercentage: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["vc"],
            valueHelper: {
              currency: data["currency"],
            },
          });
          // return/*node.innerHTML =*/ formatter.table("vc", "table", object);
        },
      },
      {
        field: "rc",
        sorter: "number",
        title: label.get("rc", 0, null),
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return formatter.table("rc", "table", data);
        },
      },
      {
        field: "dr",
        sorter: "date",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("date", {
            options: {
              format: ["Y", "m", "d"],
              notAvailable: {
                input: null,
                output: "",
              },
              separator: "-",
            },
            output: "HTML",
            value: data["dr"],
            valueHelper: null,
          });
        },
        title: label.get("dr", 0, null),
      },
      {
        field: "mc",
        sorter: "number",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              decimals: 4,

              colored: "positive",
              hasPositiveSign: true,
              isPercentage: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["mc"],
            valueHelper: null,
          });
        },
        title: label.get("mc", 0, null),
      },
      {
        field: "px",
        sorter: "number",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              decimals: 2,
              hasPositiveSign: true,
              hasNegativeSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["px"],
            valueHelper: null,
          });
        },
        title: label.get("px", 0, null),
      },
      {
        field: "pm",
        sorter: "number",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              decimals: 2,
              hasPositiveSign: true,
              hasNegativeSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["pm"],
            valueHelper: null,
          });
        },
        title: label.get("pm", 0, null),
      },
      {
        field: "marketcap",
        formatter: (cell) => {
          return formatter.table("marketcap", "table", cell.getData());
        },
        title: label.get("marketcap", 1, null),
      },
      {
        field: "_synthWhat",
        // formatter: (value, object) => {
        //   return {
        //     html: '<span title="' + value + '">' + value + "</span>",
        //   };
        // },
        title: label.get("industry", 0, null),
      },
    ];
    return _columns;
  }, [data, formatter, label]);

  return (
    <TrendratingTable
      disableDefaultRowClick
      columns={lastAllocationsColumns ?? []}
      data={data}
      autoResize={true}
      sorting={[{ property: "weight", descending: true }]}
      tooltip={{ actions: { info: { enabled: true } } }}
      options={{
        ajaxSorting: false,
        columnHeaderVertAlign: "top",
      }}
    />
  );
}
