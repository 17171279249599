import { useMemo } from "react";

type SaveActionCombinedStrategiesProps = {
  onClickCallback: Function;
  itemToSave: any
};

export default function SaveActionCombinedStrategies({
  onClickCallback,
  itemToSave
}: SaveActionCombinedStrategiesProps) {
  const label = useMemo(() => {
    let type =
      itemToSave.type === "COMBINED_PRODUCT"
        ? "combined product"
        : "combined strategy";
    if (itemToSave.type === "COMPARED_STRATEGY") {
      type = "compare strategy";
    }
    return type
  }, [itemToSave.type]);

  return (
    <li onClick={() => onClickCallback()} className="menu__item">
      {`Save ${label}`}
    </li>
  );
}
