import { Box, FormControlLabel, Switch } from "@mui/material";
import { Fragment, useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { getInterval } from "../AlternativesList";
import { AlternativesState } from "../AlternativesReducer";
import { Taxonomy } from "../../../../../../../../trendrating/formatter/Taxonomy";
import { useTaxonomyByType } from "../../../../../../../../hooks/useTaxonomyByType";

type AlternativesFilterControlsProps = {
  type: "ETF" | "Stock";
  what: string;
  where: string;
  mktCap?: number;
  domicile?: string;
  constraints: AlternativesState["constraints"];
  updateConstraints: (payload: { key: string; value: boolean }) => void;
};

const options = {
  ETF: ["Asset Class:", "Specialty:", "Inv. Region:", "Domicile:"],
  Stock: ["Mkt. Cap.:", "Market:", "Sector:", "Industry:"],
};

export function AlternativesFilterControls({
  type,
  what,
  where,
  mktCap,
  domicile,
  constraints,
  updateConstraints,
}: AlternativesFilterControlsProps) {
  const taxonType = useMemo(() => (type === "ETF" ? "ETF" : "stock"), [type]);
  const { taxonomiesMapX, taxonomiesMapY, getTaxonomyMap } =
    useTaxonomyByType(taxonType);
  const domicileTaxonMap = getTaxonomyMap("security", "market");
  const taxon = useMemo(() => new Taxonomy(), []);
  const getLabel = useCallback(
    (option) => {
      switch (option) {
        default:
          return "";

        case "Mkt. Cap.:":
          return getInterval(mktCap).label;

        case "Inv. Region:":
        case "Market:":
          return taxonomiesMapX?.[where]?.name ?? where;

        case "Asset Class:":
        case "Sector:":
          const parentId = taxon.getAncestorAtLevel(
            taxonomiesMapY,
            what,
            "1 Industry"
          );
          if (parentId) {
            return taxonomiesMapY?.[parentId]?.name ?? parentId;
          } else {
            return "";
          }

        case "Specialty:":
        case "Industry:":
          return taxonomiesMapY?.[what]?.name ?? what;

        case "Domicile:":
          return domicileTaxonMap?.[domicile ?? ""].name ?? domicile;
      }
    },
    [
      domicile,
      domicileTaxonMap,
      mktCap,
      taxon,
      taxonomiesMapX,
      taxonomiesMapY,
      what,
      where,
    ]
  );

  const getIsChecked = useCallback(
    (option) => {
      switch (option) {
        default:
          return false;

        case "Mkt. Cap.:":
          return constraints.marketCapChecked;

        case "Inv. Region:":
          return constraints.etfGeoChecked;
        case "Market:":
          return constraints.countryChecked;

        case "Asset Class:":
          return constraints.assetClassChecked;
        case "Sector:":
          return constraints.icbChecked;

        case "Specialty:":
          return constraints.specialtyChecked;

        case "Industry:":
          return constraints.industryChecked;

        case "Domicile:":
          return constraints.domicileChecked;
      }
    },
    [
      constraints.assetClassChecked,
      constraints.countryChecked,
      constraints.domicileChecked,
      constraints.etfGeoChecked,
      constraints.icbChecked,
      constraints.industryChecked,
      constraints.marketCapChecked,
      constraints.specialtyChecked,
    ]
  );

  const getKey = useCallback((option) => {
    switch (option) {
      default:
        return "";

      case "Mkt. Cap.:":
        return "marketCapChecked";

      case "Inv. Region:":
        return "etfGeoChecked";
      case "Market:":
        return "countryChecked";

      case "Asset Class:":
        return "assetClassChecked";
      case "Sector:":
        return "icbChecked";

      case "Specialty:":
        return "specialtyChecked";

      case "Industry:":
        return "industryChecked";

      case "Domicile:":
        return "domicileChecked";
    }
  }, []);

  const whatLevel = useMemo(() => {
    const node = taxonomiesMapY[what];

    return node.type;
  }, [taxonomiesMapY, what]);

  return (
    <Box>
      {options[type].map((option) =>
        (option === "Sector:" || option === "Asset Class:") &&
        whatLevel === "1 Industry" ? (
          <Fragment key={uuidv4()}></Fragment>
        ) : (
          <FormControlLabel
            key={uuidv4()}
            control={
              <Switch
                size="small"
                checked={getIsChecked(option)}
                onChange={(e) =>
                  updateConstraints({
                    key: getKey(option),
                    value: e.target.checked,
                  })
                }
              />
            }
            label={`${option} ${getLabel(option)}`}
          />
        )
      )}
    </Box>
  );
}
