import { MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { PredefinedRankingTemplate } from "../../../../../js/app/pages/strategies/builder/editors/components/PredefinedRankingTemplate";
import { Strategy } from "../../../../../js/app/pages/strategies/builder/editors/components/editorReducer";

type SelectNestedProps = {
  globalState: Strategy;
  dispatch: Function;
};

// Wrap the dojo component to mangae the internal state in a better way.
// This compent is very specific for the strategy builder

export default function SelectNested({
  globalState,
  dispatch,
}: SelectNestedProps) {
  const [widgetState, updateWidgetState] = useImmer<{
    selectedValue: string;
    optionsList: any[];
    isValueInOptionList: boolean;
  }>({
    selectedValue: "HIGH_PERFORMANCE",
    optionsList: [],
    isValueInOptionList: false,
  });

  /**
   * This is the rating object extrated from the globalState
   */

  const ratingRadioValue = globalState.selection!.find(
    (item) => item.property === "rc"
  )!.operatorParams.value;

  const { A, B, C, D } = ratingRadioValue;

  /**
   * The option list depends from the rating so we need to update them every time that the
   * globalState rating change.
   *
   * Every time that the ratig is updated we need to check if the selected value is in the
   * option lis tand if the check return true we have to mantain this value else we need to
   * return to a default value based on the rating state.
   *
   * Option list coming from the get method provided by PredefinedRankingTemplate class.
   *
   */
  useEffect(() => {
    let optionsList = PredefinedRankingTemplate.get("OPTIONS_EXTENDED", null);
    let children = optionsList[optionsList.length - 1].children;
    optionsList.splice(optionsList.length - 1, 1);
    optionsList = [...optionsList, ...children];
    optionsList = optionsList.filter((item) => item.type !== "separator");

    /**
     * When the rating radio's value is not A the option list change.
     */
    if (A && !B && !C && !D) {
      optionsList = PredefinedRankingTemplate.get("OPTIONS", null);
      // Check in rank se trova rc con sort desc
      const rank = globalState?.ranking ?? [];
      const rc = rank.find((item) => item.property === "rc");
      if (rc && rc.operatorParams.value === "desc") {
        optionsList.splice(1, 0, {
          label: "A rating first",
          value: "A",
        });
      }
    }

    updateWidgetState((draft) => {
      draft.optionsList = optionsList;
    });
  }, [A, B, C, D, globalState?.ranking, updateWidgetState]);

  /**
   * To get the selected value we need to pass a specific array to the get method
   * of the PredefinedRankingTemplate class. At this time the first argument to pass at this method
   * is the string: "OPTION_LOOKUP".
   *
   * The object to get the select value comes from the ranking array of the global state, for these
   * reason has to be updated every time the ranking global state.
   *
   */

  let rankingArray = globalState.ranking;

  /**
   * This method generate the correct array to pass as a key to the PredefinedRankingTemplateCLass
   */
  const generateKeyForSelectedValue = (ranking) => {
    let rankingFactor: any = {};
    let keyForSelectedValue: any = [];
    ranking.forEach((element) => {
      switch (element.property) {
        case "pr":
          if (element.function === "quantile") {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
              rankType: element.function,
              rankTypeParams: {
                n: element.functionParams.value,
                trimOutlier: false,
              },
            };
          } else {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
            };
          }
          break;
        default:
          rankingFactor = {
            field: element.property,
            sort: element.operatorParams.value,
          };
      }
      keyForSelectedValue.push(rankingFactor);
    });
    return keyForSelectedValue;
  };

  useEffect(() => {
    let keyForSelectValue: any = [];

    if (rankingArray) {
      keyForSelectValue = generateKeyForSelectedValue(rankingArray);
    }

    const key = JSON.stringify(
      keyForSelectValue.filter((item) => item.field !== "exists")
    );
    const selectValue = PredefinedRankingTemplate.get("OPTION_LOOKUP", key);

    /**
     * Now we have the select value and we need to store it in the internal state, but before
     * doing this we need to check that this value is not null for some reasons (bad
     * strategies or some errors for example). In that case we provide as a fallback value the
     * first item in the option list.
     */
    updateWidgetState((draft) => {
      switch (selectValue) {
        case "CLOSEST_TO_THE_MAXIMUM_NO_KEEP":
          draft.selectedValue = "CLOSEST_TO_THE_MAXIMUM";
          break;
        case null:
          if (A && !B && !C && !D) {
            draft.selectedValue = "HIGH_PERFORMANCE";
          } else if (A && B && !C && !D) {
            draft.selectedValue = "A";
          }
          break;
        default:
          draft.selectedValue = selectValue;
      }
    });
  }, [A, B, C, D, rankingArray, updateWidgetState]);

  /**
   * The internal state is correctly updated with the right options and the correct select value.
   * Now we have to update the global state with the new value when it changes.
   *
   */
  useEffect(() => {
    const { selectedValue, optionsList } = widgetState;

    dispatch({
      type: "SET_PRIORITY_SELECT",
      payload: selectedValue,
    });

    if (optionsList.length !== 0) {
      const isValueInOptionList =
        optionsList.find((option) => option.value === selectedValue) != null
          ? true
          : false;

      const nestedList = optionsList.find((option) => option.children != null);

      updateWidgetState((draft) => {
        if (isValueInOptionList) {
          return;
        } else {
          if (nestedList != null) {
            const isValueInNestedList = nestedList.children.find(
              (option) => option.value === selectedValue
            );
            if (isValueInNestedList != null) {
              return;
            } else {
              if (A && !B && !C && !D) {
                draft.selectedValue = "HIGH_MKT_CAP";
              } else {
                draft.selectedValue = "A";
              }
            }
          } else {
            if (A && !B && !C && !D) {
              draft.selectedValue = "HIGH_MKT_CAP";
            } else {
              draft.selectedValue = "A";
            }
          }
        }
      });
    }
  }, [A, B, C, D, dispatch, updateWidgetState, widgetState]);

  return (
    <Select
      value={widgetState.selectedValue}
      size="small"
      onChange={(e) => {
        let value: string = e.target.value as string;
        updateWidgetState((draft) => {
          if (value !== draft.selectedValue) {
            draft.selectedValue = value;
          }
        });
      }}
    >
      {widgetState.optionsList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          <Typography>{item.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
