import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { formatTaxonPrefixingParent } from "../../../../../../../../../../../api/compute/Taxon";
import { TaxonomySelect } from "../../../../../../../../../../../components/TaxonomySelect/TaxonomySelect";
import { useTaxonomyByType } from "../../../../../../../../../../../hooks/useTaxonomyByType";
import ScreenerETF from "./ScreenerETF";
import ScreenerStock from "./ScreenerStock";
import { Properties } from "../../../../../../../../../../../api/Properties";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";

export type ScreenerPanelProps = {
  type: "stock" | "ETF";
  actualInvUniverse: {
    eligibility?: {
      cardinality: number;
      isEnabled: boolean;
      sortBy: "desc" | "asc";
    };
    instrumentType: "stock" | "etf";
    what: any[];
    whereSource: {
      domestic: boolean;
      foreign: boolean;
      market: string[];
      stockClassification: string[];
    };
    whereTarget?: {
      domestic: boolean;
      foreign: boolean;
      market: string[];
      stockClassification: string[];
    };
    subtype: [];
  };
  setScreening: (action: { type: "SET_SCREENING"; payload: any }) => void;
  setIsDisabled: (value: boolean) => void;
  enableEligibility?: boolean;
};

export type TaxonomyFieldRowProps = {
  taxonomyMap: any;
  taxonomyType: string;
  taxonomyField: string;
  handleValuesSelection: (selectedValues) => void;
  selectedValues: any;
  label?: string;
  columnsDefinition?: { taxonomyId: string }[][];
  validTreeNodes?: string[];
  validLevels?: string[];
  hasDomicileFilters?: boolean;
  initDomicileFilters?: {
    domestic: boolean;
    foreign: boolean;
    type: string[];
  };
  phraseOnEmpty?: string;
  hasMultipleChoices?: boolean;
  showAllSelected?: boolean;
};

export default function ScreenerPanel({
  actualInvUniverse,
  type,
  setScreening,
  setIsDisabled,
}: ScreenerPanelProps) {
  return (
    <Box display={"flex"} flex={2}>
      {type === "ETF" ? (
        <ScreenerETF
          setScreening={setScreening}
          actualInvUniverse={actualInvUniverse}
          setIsDisabled={setIsDisabled}
        />
      ) : (
        <ScreenerStock
          setScreening={setScreening}
          setIsDisabled={setIsDisabled}
          actualInvUniverse={actualInvUniverse}
        />
      )}
    </Box>
  );
}

export const TaxonomyFieldRow = ({
  taxonomyMap,
  handleValuesSelection,
  selectedValues,
  label,
  taxonomyType,
  taxonomyField,
  columnsDefinition,
  validTreeNodes,
  hasDomicileFilters = false,
  initDomicileFilters,
  phraseOnEmpty = "",
  hasMultipleChoices = true,
  showAllSelected = true,
}: TaxonomyFieldRowProps) => {
  const panelRef = useRef<any>(null);

  const handleTaxonomyPanelOpen = useCallback(() => {
    if (panelRef.current) {
      panelRef.current.showTreePanel(true);
    }
  }, []);

  const type = useMemo(
    () => (taxonomyType === "security" ? "stock" : "ETF"),
    [taxonomyType]
  );

  const { taxonomiesMapY } = useTaxonomyByType(type);

  const rootNode = useMemo(() => {
    const txMap = taxonomyMap;
    const rootNode = Object.values<any>(txMap).find(
      (node) => node.parent == null
    );
    return rootNode;
  }, [taxonomyMap]);

  const selectedName = useCallback(
    (id) => {
      const taxonomyNode = taxonomyMap?.[id];
      if (taxonomyNode) {
        const level = taxonomyNode.type;

        if (level === "3 Sector" || level === "4 Subsector") {
          return formatTaxonPrefixingParent(
            taxonomyNode,
            [taxonomiesMapY],
            level
          );
        }

        return taxonomyNode.name;
      }
      return id;
    },
    [taxonomiesMapY, taxonomyMap]
  );

  const content = useMemo(() => {
    if (selectedValues.length <= 0) {
      return (
        <li style={{ textAlign: "left" }} key={uuidv4()}>
          <Typography>{rootNode?.name ?? ""}</Typography>
        </li>
      );
    }
    if (showAllSelected) {
      return selectedValues.map((filter) => (
        <li style={{ textAlign: "left" }} key={uuidv4()}>
          <Typography>{selectedName(filter)}</Typography>
        </li>
      ));
    } else {
      if (selectedValues.length <= 3) {
        return selectedValues.map((filter) => (
          <li style={{ textAlign: "left" }} key={uuidv4()}>
            <Typography>{selectedName(filter)}</Typography>
          </li>
        ));
      } else {
        return (
          <>
            <li style={{ textAlign: "left" }} key={uuidv4()}>
              <Typography>{selectedName(selectedValues[0])}</Typography>
            </li>
            <li>
              <Typography>+{selectedValues?.length - 1}</Typography>
            </li>
          </>
        );
      }
    }
  }, [rootNode?.name, selectedName, selectedValues, showAllSelected]);
  const environment = useEnvironment();
  const setup = environment.get("setup");
  const propertiesAPI = useMemo(
    () => new Properties({ properties: setup["properties"] }),
    [setup]
  );
  const _label = useMemo(() => {
    let field: string | undefined = undefined;
    if (taxonomyField === "icb") {
      field = "industry";
    } else {
      field = taxonomyField;
    }
    return propertiesAPI.get(field, 0, "auto");
  }, [propertiesAPI, taxonomyField]);

  return (
    <TaxonomySelect
      hasMultipleChoices={hasMultipleChoices}
      ref={panelRef}
      taxonomyInfo={{ type: taxonomyType, field: taxonomyField }}
      panelTitle={_label}
      onSetFilters={handleValuesSelection}
      validTreeNodes={validTreeNodes}
      columnsDefinition={columnsDefinition}
      defaultValue={selectedValues}
      hasFilters={hasDomicileFilters}
      initFiltersState={initDomicileFilters}
      selectValue={handleValuesSelection}
    >
      <Box display={"flex"} position={"relative"}>
        <Card
          onClick={() => handleTaxonomyPanelOpen()}
          sx={{
            position: "relative",
            border: "1px solid",
            borderColor: "transparent",
            transition: "0.5s",
            cursor: "pointer",
            "&:hover": {
              borderColor: "#2a7090",
            },
            display: "flex",
          }}
        >
          {/* {showIconCancel || editorCloseFn != null ? (
            <Box
              title={`Remove ${label ?? ""}`}
              sx={{
                position: "absolute",
                right: "2px",
                top: "2px",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={editorCloseFn ?? removeFilter}
                sx={{
                  fontSize: "14px",
                  color: "black",
                }}
              />
            </Box>
          ) : (
            <></>
          )} */}
          <CardContent
            sx={{
              padding: "2px 6px!important",
              display: "flex",
              flex: 1,
            }}
          >
            <Box display={"flex"} flexDirection={"column"} textAlign={"center"}>
              <Typography sx={{ mr: 0 }}>
                <strong>{_label ?? ""}</strong>
              </Typography>

              <Box
                flex={1}
                component={"ul"}
                display={"flex"}
                // alignItems={"center"}
                // justifyContent={"center"}
                flexDirection={"column"}
                gap={0.2}
              >
                {/* {showAllSelected && selectedValues?.length > 0 ? (
                  selectedValues.map((filter) => (
                    <li style={{ textAlign: "left" }} key={uuidv4()}>
                      <Typography>{selectedName(filter)}</Typography>
                    </li>
                  ))
                ) : (
                  <li style={{ textAlign: "left" }} key={uuidv4()}>
                    <Typography>{rootNode?.name ?? ""}</Typography>
                  </li>
                )} */}

                {/* {!showAllSelected && selectedValues?.length > 3 ? (
                  <>
                    <li style={{ textAlign: "left" }} key={uuidv4()}>
                      <Typography>{selectedName(selectedValues[0])}</Typography>
                    </li>
                    <li>
                      <Typography>+{selectedValues?.length - 1}</Typography>
                    </li>
                  </>
                ) : (
                  selectedValues.map((filter) => {
                    return (
                      <li style={{ textAlign: "left" }} key={uuidv4()}>
                        <Typography>{selectedName(filter)}</Typography>
                      </li>
                    );
                  })
                  // <li style={{ textAlign: "left" }} key={uuidv4()}>
                  //   <Typography>{rootNode?.name ?? ""}</Typography>
                  // </li>
                )} */}
                {content}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </TaxonomySelect>
  );
};
