import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Instruments } from "../../../../../api/compute/Instruments";
import List from "../../../../../components/DragAndDropList/List";
import Modal from "../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import AvailableFields from "../../../../trendrating-report/widgets/form/fields/reactComponents/commonWidgets/AvailableFields";
import styles from "./FundamentalsTable.module.scss";

type FundamentalsTableProps = {
  data: any;
  availableColumns: any;
  tab: string;
};

export function FundamentalsTable({
  data,
  availableColumns,
  tab,
}: FundamentalsTableProps) {
  //!--------------------------------
  //!--------------------------------
  //#region states and variables
  const { t } = useTranslation();
  const environment = useEnvironment();
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );

  const pageStatusAPI = useMemo(
    () => environment.get("preferenceStatus"),
    [environment]
  );

  const formatter = useFormatter();
  const [showTableEditor, setShowTableEditor] = useState(false);
  const [securityAnalytics, setSecurityAnalytics] = useState<any>(null);
  const properties = useMemo(
    () => environment.get("properties"),
    [environment]
  );

  const rawProperties = useMemo(() => {
    const p = properties.properties;
    const rawProperties = {
      ...p.stock,
      ...p.security,
      ...p.etf,
      ...p.index,
    };
    return rawProperties;
  }, [properties.properties]);

  const [exposed, setExposed] = useState<any>(null);

  const getPreferences = useCallback(async () => {
    const fields = await pageStatusAPI.get(["screening", tab, "securityView"]);

    if (fields) {
      setExposed(fields);
    }
  }, [pageStatusAPI, tab]);

  useEffect(() => {
    // Retrieves preferences on landing
    getPreferences();
  }, [getPreferences]);

  const saveAnalyticsConfig = useCallback(
    async (analytics) => {
      await pageStatusAPI.patch(["screening", tab, "securityView"], analytics);
      setExposed(analytics);
    },
    [pageStatusAPI, tab]
  );

  const defaultColumns = useMemo(() => {
    const configuration =
      environment.get("setup")["account"]["product"]["configuration"][
        "screening"
      ]?.["widgets"]["securityView"]["defaultFields"][tab];

    let defaultCols = configuration.map((field) => ({
      field,
      label: rawProperties[field]?.name?.[0],
    }));

    try {
      const storedAnalytics: any = exposed;
      //! the list of preference can be empty!
      if (storedAnalytics != null && storedAnalytics.length != null) {
        const cols = storedAnalytics.map((item) => ({
          field: item,
          label: rawProperties?.[item]?.name?.[0] ?? "",
        }));
        defaultCols = cols;
      }
    } catch (error) {
      console.error(error);
    } finally {
      return defaultCols;
    }
  }, [environment, exposed, rawProperties, tab]);

  const [columns, setColumns] = useState(defaultColumns);
  const [preSave, setPreSave] = useState(defaultColumns);

  useEffect(() => {
    setColumns(defaultColumns);
    setPreSave(defaultColumns);
    const columnsAvailableWithPropertiesneeded =
      getPropertiesToColumnsAvailable(availableColumns, environment, t);
    let temp = getPropertiesToColumnsAvailable(
      availableColumns,
      environment,
      t
    );
    const keys = Object.keys(temp);
    keys.forEach((key) => {
      columnsAvailableWithPropertiesneeded[key].forEach((element, index) => {
        defaultColumns.forEach((column) => {
          if (element.field === column.field) {
            temp[key][index]["selected"] = true;
          }
        });
      });
    });
    setSelectedColumns(temp);
  }, [availableColumns, defaultColumns, environment, t]);

  const initSelectedColumns = useCallback(() => {
    const columnsAvailableWithPropertiesneeded =
      getPropertiesToColumnsAvailable(availableColumns, environment, t);
    let temp = getPropertiesToColumnsAvailable(
      availableColumns,
      environment,
      t
    );
    const keys = Object.keys(temp);
    keys.forEach((key) => {
      columnsAvailableWithPropertiesneeded[key].forEach((element, index) => {
        defaultColumns.forEach((column) => {
          if (element.field === column.field) {
            temp[key][index]["selected"] = true;
          }
        });
      });
    });
    return temp;
  }, [availableColumns, defaultColumns, environment, t]);
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const returningValue = initSelectedColumns();
    return returningValue;
  });
  //#endregion
  //!--------------------------------------
  //!--------------------------------------

  const fetchFields = useCallback(async () => {
    try {
      if (defaultColumns != null && defaultColumns?.length) {
        const properties = defaultColumns.map((item) => ({
          date: null,
          property: item.field,
        }));

        const response = await instrumentsAPI.fetch({
          properties,
          type: "security",
          symbols: [data.symbol],
        });

        setSecurityAnalytics(response?.data?.[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [data.symbol, defaultColumns, instrumentsAPI]);

  //#region - getting security analytics
  useEffect(() => {
    fetchFields();
  }, [fetchFields]);
  //#endregion

  useEffect(() => {
    // every time that selectedColumns changes, we filter
    // the objs that has selected:true and compare the arr
    // with state.columns
    const arr: any[] = [];
    const keys = Object.keys(selectedColumns);
    keys.forEach((key) => {
      selectedColumns[key].forEach((element) => {
        if (element.selected != null && element.selected === true) {
          arr.push({ label: element.label, field: element.field });
        }
      });
    });
    if (
      JSON.stringify(preSave) !== JSON.stringify(arr) &&
      preSave.length === arr.length
    ) {
      //when this condition is satisfied it means that the order changed
      setPreSave(preSave);
    } else if (
      JSON.stringify(preSave) !== JSON.stringify(arr) &&
      preSave.length !== arr.length
    ) {
      //means that something has been added  or removed from the array
      if (preSave.length > arr.length) {
        // something has been removed,
        // then we look for the difference between the arrays
        // and remove the difference from preSave and set the
        let set1 = preSave.map((item) => item.field);
        let set2 = arr.map((item) => item.field);
        let difference = set1.filter((x) => !set2.includes(x))[0];
        for (let i = 0; i < preSave.length; i++) {
          if (preSave[i].field === difference) {
            // removing from array the item
            let temp: any[] = deepClone(preSave);
            let firstHalf = temp.splice(0, i);
            let secondHalf = temp.splice(1);
            temp = firstHalf.concat(secondHalf);
            setPreSave(temp);
            break;
          }
        }
      }
      if (preSave.length < arr.length) {
        let set1 = preSave.map((item) => item.field);
        let set2 = arr.map((item) => item.field);
        let difference = set2.filter((x) => !set1.includes(x))[0];
        let temp: any[] = deepClone(preSave);
        temp.push({
          field: difference,
          label: rawProperties[difference].name[0],
        });
        //!!
        // let temp: any[] = deepClone(preSave);
        // keys.forEach((key) => {
        //   selectedColumns[key].forEach((element) => {
        //     if (element.field === difference) {
        //       temp.push({ label: rawProperties[element.field].name[0], field: element.field });
        //     }
        //   });
        // });
        setPreSave(temp);
      }
    }
  }, [preSave, rawProperties, selectedColumns]);

  const dndRef = useRef<any>(null);

  const handleApply = useCallback(() => {
    let mergedAnalytics: any = [];
    for (const value of Object.values(selectedColumns)) {
      mergedAnalytics = mergedAnalytics.concat(value);
    }
    const dndContent = dndRef.current.getState().map((item) => item.value);
    let arr: any[] = [];
    if (dndContent.length > 0) {
      dndContent.forEach((field) => {
        let obj = mergedAnalytics.filter(
          (analytic) => analytic.field === field
        )[0];
        if (obj) {
          arr.push({ field: obj.field, label: obj.label });
        }
      });
      const analyticsToStore = arr.map((item) => item.field);
      saveAnalyticsConfig(analyticsToStore);
      setShowTableEditor(false);
    } else {
      saveAnalyticsConfig([]);
      setShowTableEditor(false);
    }
  }, [saveAnalyticsConfig, selectedColumns]);

  const tableBody = useMemo(() => {
    if (securityAnalytics != null) {
      return columns.map((item) => {
        const field = item.field;
        return {
          field: item.label,
          value: formatter.table(field, "table", securityAnalytics),
        };
      });
    }
  }, [columns, formatter, securityAnalytics]);
  const dndData = useMemo(() => {
    return preSave.map((col) => ({
      value: col.field,
      content: (
        <Box
          sx={{ bgcolor: "white" }}
          border={"solid 1px #2a7092"}
          p={1}
          borderRadius={1}
          flex={1}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <Typography>{col.label}</Typography>
        </Box>
      ),
    }));
  }, [preSave]);
  const onDndListChange = useCallback((e) => {
    const fields = e.map((item) => item.value);
    setPreSave((prev) => {
      let arr: any[] = [];
      fields.forEach((element) => {
        let label = prev.filter((item) => item.field === element);
        label = label[0].label;
        arr.push({ field: element, label: label });
      });
      return arr;
    });
  }, []);

  return (
    <Card sx={{ boxShadow: 3, flex: 1, minHeight: 0, display: "flex" }}>
      <CardContent
        sx={{
          pb: "16px !important",
          flex: 1,
          minHeight: 0,
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box
          display={"flex"}
          sx={{ cursor: "pointer" }}
          justifyContent={"flex-end"}
          onClick={() => setShowTableEditor(true)}
        >
          <span
            className="button--expand-screening"
            style={{ padding: "3px 5px" }}
          >
            <span className="i-edit"></span>
          </span>
        </Box>
        {showTableEditor && (
          <Modal
            closeIcon={false}
            closeOnClickAway={false}
            isResizable={true}
            buttonsEnalbed={true}
            buttons={[
              {
                name: "Apply",
                callback: handleApply,
              },
              {
                name: "Cancel",
                callback: () => {
                  setSelectedColumns(initSelectedColumns());
                  setShowTableEditor(false);
                },
                variant: "cancel",
              },
            ]}
            headerConfig={{ headerContent: <strong>Select Analytics</strong> }}
            onClose={() => setShowTableEditor(false)}
            customCss={{ maxWidth: "100%", width: "60%" }}
          >
            <Box display={"flex"} flexDirection={"row"} gap={2}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent sx={{ pb: "16px !important" }}>
                  <AvailableFields
                    onchangeState={setSelectedColumns}
                    columnsAvailable={selectedColumns}
                  />
                </CardContent>
              </Card>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent
                  sx={{
                    pb: "16px !important",
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>Drag and drop to change the order.</Typography>
                  <Box sx={{ overflow: "auto", maxHeight: 500 }}>
                    <List
                      content={dndData}
                      ref={dndRef}
                      setState={(e) => onDndListChange(e)}
                      orientation={"vertical"}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Modal>
        )}
        <ul className={styles.list}>
          {tableBody &&
            tableBody.map((item, index) =>
              securityAnalytics != null ? (
                <li key={index} className={styles.listItem}>
                  <Typography>{item.field}</Typography>
                  <Typography>
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: item?.value ?? "",
                      }}
                    ></strong>
                  </Typography>
                </li>
              ) : null
            )}
        </ul>
      </CardContent>
    </Card>
  );
}

const getPropertiesToColumnsAvailable = (
  columnsAvailable: any[],
  env,
  translator
) => {
  const arrProps: Object = env.setup.properties;
  //* unpacking elements from arrProps["ETF"]
  if (arrProps["ETF"] != null) {
    const keysETF = Object.keys(arrProps["ETF"]);
    keysETF.forEach((key) => {
      arrProps[key] = arrProps["ETF"][key];
    });
    delete arrProps["ETF"];
  }
  //*****************************************

  //* unpacking elements from arrProps["ETF"]
  if (arrProps["Stock"]) {
    const keysStock = Object.keys(arrProps["Stock"]);
    keysStock.forEach((key) => {
      arrProps[key] = arrProps["Stock"][key];
    });
    delete arrProps["Stock"];
  }
  //*****************************************

  //* retrieve the tags
  const tagSet = new Set();
  columnsAvailable.forEach((element) => {
    const field = element.field;
    if (arrProps[field] != null) {
      if (arrProps[field]["tags"] != null) {
        tagSet.add(arrProps[field]["tags"][0]);
      }
    }
  });
  //*****************************************

  //* creating the obj which every key is an array
  let obj: Object = {};
  Array.from(tagSet).forEach((element: any) => {
    obj[translator(element)] = [];
  });
  columnsAvailable.forEach((element) => {
    if (arrProps[element.field]["tags"][0] != null) {
      const tag = arrProps[element.field]["tags"][0];
      let test = translator(tag);
      if (element.panelIndex != null) {
        obj[test].push({
          ...element,
          label: arrProps[element.field]["name"][element.panelIndex],
        });
      } else {
        obj[test].push({
          ...element,
          label: arrProps[element.field]["name"][0],
        });
      }
    }
  });
  //*****************************************
  return obj;
};
