import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { targetNameVariable } from "../../utils/utils";
import {
  ActionType,
  AsOfTodayType,
  asOfTodayReducer,
} from "./React_AsOfTodayPerformance_reducer";

type AsOfTodayPerformanceProps = {
  configObj: any;
};

const AsOfTodayPerformance = forwardRef(
  ({ configObj }: AsOfTodayPerformanceProps, ref) => {
    // const targetNameVariable = "${name}";

    const initState: AsOfTodayType = useMemo(
      () => ({
        benchmark: configObj.widgetValue.content.benchmark,
        delta: configObj.widgetValue.content.delta,
        headline: configObj.widgetValue.content.headline,
        performance10Years: {
          ...configObj.widgetValue.content.performance10Years,
          strict:
            configObj?.widgetValue?.content?.performance10Years?.strict ??
            false,
          annualized:
            configObj?.widgetValue?.content?.performance10Years?.annualized ??
            true,
        },
        performance1Day: configObj.widgetValue.content.performance1Day,
        performance1Month: configObj.widgetValue.content.performance1Month,
        performance1Week: configObj.widgetValue.content.performance1Week,
        performance1Year: {
          ...configObj.widgetValue.content.performance1Year,
          strict:
            configObj?.widgetValue?.content?.performance1Year?.strict ?? true,
          annualized:
            configObj?.widgetValue?.content?.performance1Year?.annualized ??
            true,
        },
        performance3Months: configObj.widgetValue.content.performance3Months,
        performance3Years: {
          ...configObj.widgetValue.content.performance3Years,
          strict:
            configObj?.widgetValue?.content?.performance3Years?.strict ?? true,
          annualized:
            configObj?.widgetValue?.content?.performance3Years?.annualized ??
            true,
        },
        performance5Years: {
          ...configObj.widgetValue.content.performance5Years,
          strict:
            configObj?.widgetValue?.content?.performance5Years?.strict ?? false,
          annualized:
            configObj?.widgetValue?.content?.performance5Years?.annualized ??
            true,
        },
        performanceLTD: {
          ...configObj.widgetValue.content.performanceLTD,
          annualized:
            configObj?.widgetValue?.content?.performanceLTD?.annualized ?? true,
        },
        performanceMTD: configObj.widgetValue.content.performanceMTD,
        performanceQTD: configObj.widgetValue.content.performanceQTD,
        performanceYTD: configObj.widgetValue.content.performanceYTD,
        strategy: configObj.widgetValue.content.strategy,
      }),
      [
        configObj.widgetValue.content.benchmark,
        configObj.widgetValue.content.headline,
        configObj.widgetValue.content.delta,
        configObj.widgetValue.content.performance10Years,
        configObj.widgetValue.content.performance1Day,
        configObj.widgetValue.content.performance1Month,
        configObj.widgetValue.content.performance1Week,
        configObj.widgetValue.content.performance1Year,
        configObj.widgetValue.content.performance3Months,
        configObj.widgetValue.content.performance3Years,
        configObj.widgetValue.content.performance5Years,
        configObj.widgetValue.content.performanceLTD,
        configObj.widgetValue.content.performanceMTD,
        configObj.widgetValue.content.performanceQTD,
        configObj.widgetValue.content.performanceYTD,
        configObj.widgetValue.content.strategy,
      ]
    );

    const [state, dispatch] = useImmerReducer(asOfTodayReducer, initState);

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state;
        return tempState;
      },
    }));

    const onChangeStrictMode = useCallback(
      (
        value,
        actType:
          | "SET_PERFORMANCE_5_YEARS_STRICT"
          | "SET_PERFORMANCE_10_YEARS_STRICT"
      ) => {
        dispatch({ type: actType, payload: value });
      },
      [dispatch]
    );

    const dispatchAction = useCallback(
      (value: any, actionType: ActionType["type"]) => {
        dispatch({ type: actionType, payload: value });
      },
      [dispatch]
    );

    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.headline.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_HEADLINE_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Headline"
              />
              <TextField
                value={state.headline.content}
                disabled={!state.headline.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_HEADLINE_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </CardContent>
        </Card>

        <Card>
          <CardContent sx={{ display: "flex", flexDirection: "column" }}>
            {/* Portfolio */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.strategy.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_STRATEGY_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="Portfolio"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  disabled={!state.strategy.isEnabled}
                  onClick={() => {
                    dispatch({
                      type: "SET_STRATEGY_CONTENT",
                      payload: `${state.strategy.content} ${targetNameVariable}`,
                    });
                  }}
                >
                  Add target's name
                </Button>
                <TextField
                  disabled={!state.strategy.isEnabled}
                  value={state.strategy.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_STRATEGY_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* Benchmark */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.benchmark.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_BENCHMARK_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="Benchmark"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  disabled={!state.benchmark.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_BENCHMARK_CONTENT",
                      payload: `${state.benchmark.content} ${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>
                <TextField
                  disabled={!state.benchmark.isEnabled}
                  value={state.benchmark.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_BENCHMARK_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* Delta */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.delta.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_DELTA_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="Delta"
                />
              </Box>

              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={2}
              >
                <Button
                  disabled={!state.delta.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_DELTA_CONTENT",
                      payload: `${state.delta.content} ${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>
                <TextField
                  disabled={!state.delta.isEnabled}
                  value={state.delta.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_DELTA_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* 1 day */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance1Day.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_1_DAY_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="1 day"
                />
              </Box>

              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  disabled={!state.performance1Day.isEnabled}
                  value={state.performance1Day.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_1_DAY_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* 1 week */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance1Week.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_1_WEEK_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="1 week"
                />
              </Box>

              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performance1Week.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_1_WEEK_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performance1Week.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* MTD */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performanceMTD.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_MTD_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="MTD"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performanceMTD.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_MTD_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performanceMTD.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 1 month */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance1Month.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_1_MONTH_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="1 month"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  disabled={!state.performance1Month.isEnabled}
                  value={state.performance1Month.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_1_MONTH_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* QTD */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performanceQTD.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_QTD_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="QTD"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performanceQTD.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_QTD_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performanceQTD.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 3 months */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance3Months.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_3_MONTHS_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="3 months"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  disabled={!state.performance3Months.isEnabled}
                  value={state.performance3Months.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_3_MONTHS_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>

            {/* YTD */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performanceYTD.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_YTD_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="YTD"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performanceYTD.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_YTD_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performanceYTD.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 1 year */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance1Year.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_1_YEAR_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="1 year"
                />
              </Box>
              {state.performance1Year.isEnabled && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_1_YEARS_ANNUALIZED"
                          )
                        }
                        checked={state?.performance1Year?.annualized ?? true}
                        size="small"
                      />
                    }
                    label="Annualized"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_1_YEARS_STRICT"
                          )
                        }
                        checked={state?.performance1Year?.strict ?? true}
                        size="small"
                      />
                    }
                    label="Although shorter"
                    title="also on shorter price history"
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performance1Year.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_1_YEAR_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performance1Year.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 3 year */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance3Years.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_3_YEARS_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="3 year"
                />
              </Box>
              {state.performance3Years.isEnabled && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_3_YEARS_ANNUALIZED"
                          )
                        }
                        checked={state?.performance3Years?.annualized ?? true}
                        size="small"
                      />
                    }
                    label="Annualized"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_3_YEARS_STRICT"
                          )
                        }
                        checked={state?.performance3Years?.strict ?? true}
                        size="small"
                      />
                    }
                    label="Although shorter"
                    title="also on shorter price history"
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performance3Years.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_3_YEARS_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performance3Years.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 5 year */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance5Years.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_5_YEARS_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="5 year"
                />
              </Box>
              {state.performance5Years.isEnabled && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_5_YEARS_ANNUALIZED"
                          )
                        }
                        checked={state?.performance5Years?.annualized ?? true}
                        size="small"
                      />
                    }
                    label="Annualized"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          onChangeStrictMode(
                            checked,
                            "SET_PERFORMANCE_5_YEARS_STRICT"
                          )
                        }
                        checked={state?.performance5Years?.strict ?? false}
                        size="small"
                      />
                    }
                    label="Although shorter"
                    title="also on shorter price history"
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performance5Years.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_5_YEARS_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performance5Years.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* 10 year */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performance10Years.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_10_YEARS_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="10 year"
                />
              </Box>
              {state.performance10Years.isEnabled && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_10_YEARS_ANNUALIZED"
                          )
                        }
                        checked={state?.performance10Years?.annualized ?? true}
                        size="small"
                      />
                    }
                    label="Annualized"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          onChangeStrictMode(
                            checked,
                            "SET_PERFORMANCE_10_YEARS_STRICT"
                          )
                        }
                        checked={state?.performance10Years?.strict ?? false}
                        size="small"
                      />
                    }
                    label="Although shorter"
                    title="also on shorter price history"
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performance10Years.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_10_YEARS_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performance10Years.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* Starting date */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.performanceLTD.isEnabled}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_PERFORMANCE_LTD_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="Starting date"
                />
              </Box>
              {state.performanceLTD.isEnabled && (
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(evt, checked) =>
                          dispatchAction(
                            checked,
                            "SET_PERFORMANCE_LTD_ANNUALIZED"
                          )
                        }
                        checked={state?.performanceLTD?.annualized ?? true}
                        size="small"
                      />
                    }
                    label="Annualized"
                  />
                </Box>
              )}
              <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TextField
                  value={state.performanceLTD.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PERFORMANCE_LTD_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.performanceLTD.isEnabled}
                  size="small"
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default AsOfTodayPerformance;
