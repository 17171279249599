import { Box } from "@mui/material";
import { useMemo } from "react";
import DndList from "../../../../components/DragAndDrop/DndList";

type DragAndDropSectionsProps = {
  value: any;
  // addField: Function;
};

export default function DragAndDropSections({
  value,
  // addField,
}: DragAndDropSectionsProps) {
  const widgets = useMemo(() => widgetFactory(value), [value]);

  // useEffect(() => {
  //   console.log("widgets ate reactSections", widgets);
  // }, [widgets]);

  return (
    <Box sx={{ backgroundColor: "#f2f2f2" }} padding={2}>
      <DndList
        listcustomStyle={{ listItempPadding: 5 }}
        isSource={true}
        isDragable={true}
        showHandlers={false}
        listContentObjBuilder={widgets}
        isSortable={false}
        setOutterState={() => {}}
      />
    </Box>
  );
}

const widgetFactory = (listContentObjBuilder) => {
  if (listContentObjBuilder != null) {
    // widgetFactory returns an array of widgets
    let baseURL = window.location.origin;
    let arrElements = listContentObjBuilder.map((element, index) => (
      <Widget
        key={index}
        imgURL={baseURL + element["preview"]["imageUrl"]}
        label={element.label}
        configObj={element}
      />
    ));
    return arrElements;
  }
  return [];
};

type WydgetProps = {
  imgURL: string;
  label: string;
  configObj: any;
};
const Widget = ({ imgURL, label, configObj }: WydgetProps, ref) => {
  return (
    <>
      <div
        style={{ position: "relative", backgroundColor: "#fff" }}
        onClick={() => {}}
      >
        <p>{label}</p>
        <img width="100%" style={{ pointerEvents: "none", zIndex: -1 }}
            draggable="false" src={`${imgURL}`} alt={label} />
      </div>
    </>
  );
};
