import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";

type SubscribableProps = {
  input: any[];
  unsubscribe: Function;
  subscribe: Function;
};

export default function Subscribable({
  input,
  unsubscribe,
  subscribe,
}: SubscribableProps) {

  const _input = useMemo(() => input, [input]);
  const checkboxHandler = useCallback(
    (e) => {
      let id = parseInt(e.target.name.split("_")[1]);
      if (e.target.checked) {
        subscribe(id)
      } else {
        unsubscribe(id);
      }
    },
    [subscribe, unsubscribe]
  );

  return (
    <Box px={2}>
      {_input != null && _input.length>0? (
        <FormGroup>
          {_input.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  onChange={checkboxHandler}
                  size="small"
                  defaultChecked={item.checked}
                  name={item.value}
                />
              }
              label={<Typography>{item.label}</Typography>}
            />
          ))}
        </FormGroup>
      ):<Typography p={2}>Nothing has been shared yet </Typography>}
    </Box>
  );
}
