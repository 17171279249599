import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { ClusterAnalytics } from "../../../../../../../../api/compute/ClusterAnalytics";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import { useTranslation } from "react-i18next";
import { Instruments } from "../../../../../../../../api/compute/Instruments";

type KeyDataProps = {
  portfolioId: any;
  portfolioType: "BASKET" | "PORTFOLIO";
  holdingsLength: number;
  benchmarkSymbol?: string;
};

export function KeyData({
  portfolioId,
  portfolioType,
  holdingsLength,
  benchmarkSymbol,
}: KeyDataProps) {
  const [allocation, setAllocation] = useState({});
  const [benchmark, setBenchmark] = useState<any>();
  const environment = useEnvironment();
  const { t } = useTranslation();
  const clusterAPI = useMemo(
    () => new ClusterAnalytics(environment.get("setup")),
    [environment]
  );
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const format = useFormatter();
  const holdings = useMemo(() => {
    if (portfolioType === "PORTFOLIO") {
      return holdingsLength ?? 0;
    } else {
      if (portfolioType) {
        var innerHTML: any = [];
        var item: any = null;

        for (const key in allocation) {
          item = allocation[key]?.cardinality;

          if (item > 0) {
            innerHTML.push(
              format.custom("number", {
                options: {
                  decimals: 0,
                  notAvailable: {
                    input: 0,
                    output: 0,
                  },
                },
                output: "HTML",
                value: item,
                valueHelper: null,
              }) +
                " " +
                t("default_screening_" + key)
            );
          }
        }

        return innerHTML.join(", ");
      }

      return "";
    }
  }, [allocation, format, holdingsLength, portfolioType, t]);

  const allocationValues = useMemo(() => {
    if (portfolioType) {
      var innerHTML: any = [];
      var item: any = null;

      for (const key in allocation) {
        item = allocation[key]?.weight;

        if (item > 0) {
          innerHTML.push(
            format.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: 0,
                  output: 0,
                },
              },
              output: "HTML",
              value: item,
              valueHelper: null,
            }) +
              " " +
              t("default_screening_" + key)
          );
        }
      }

      return innerHTML.join(", ");
    }

    return "";
  }, [allocation, format, portfolioType, t]);

  const onComponentMount = useCallback(async () => {
    if (portfolioId) {
      if (benchmarkSymbol) {
        let benchmarkReposne = await instrumentsAPI.fetch(
          {
            symbols: [benchmarkSymbol],
            properties: [{ date: null, property: "name" }],
            type: "security",
          },
          true
        );

        setBenchmark(benchmarkReposne?.data?.[0]);
      }

      const resposne = await clusterAPI.get({
        analytics: ["weight", "cardinality"],
        method: "INTERSECTION",
        clusters: [
          {
            dimension: "type",
            transform: {
              function: "value",
            },
          },
        ],
        universe: {
          search: {
            constraints: [
              [
                {
                  dimension: "COLLECTION",
                  operator: "relation",
                  segments: [portfolioId],
                },
              ],
            ],
            page: {
              page: 1,
              rows: 20000,
            },
            sort: [
              {
                dimension: "marketcap",
                rev: true,
              },
            ],
          },
        },
      });

      const classTypeLabels = {
        Commodity: "commodity",
        Currency: "currency",
        ETF: "etf",
        Index: "index",
        Sector: "sector",
        Stock: "stock",
        ExpiredStock: "expired",
      };

      const constituents = {};

      if (resposne) {
        const axis = resposne?.axis?.[0];
        const stats = resposne?.clustersStats?.stats;
        let key: null | string = null;

        if (axis && stats) {
          for (const type of axis) {
            key = classTypeLabels[type];

            if (key) {
              constituents[key] = stats?.[type];
            }
          }
        }
      }

      setAllocation(constituents);
    }
  }, [benchmarkSymbol, clusterAPI, instrumentsAPI, portfolioId]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return (
    <div>
      <h1 className="tAnalysisOverview-title">Key data</h1>
      <table className="tAnalysisOverview-table">
        <tbody>
          <tr>
            <th className="tAnalysisOverview-tableLabel">Benchmark</th>
            <td
              className="tAnalysisOverview-tableValue"
              data-dojo-attach-point="nodeBenchmark"
            >
              {benchmark ? benchmark?.name : "Not set"}
            </td>
          </tr>
          <tr>
            <th className="tAnalysisOverview-tableLabel">Holdings</th>
            <td
              className="tAnalysisOverview-tableValue"
              data-dojo-attach-point="nodeHoldings"
            >
              {holdings}
            </td>
          </tr>
          {allocationValues && allocationValues.length ? (
            <tr data-dojo-attach-point="nodeAllocations">
              <th className="tAnalysisOverview-tableLabel">Allocations</th>
              <td
                className="tAnalysisOverview-tableValue"
                data-dojo-attach-point="nodeAllocationsValue"
              >
                {allocationValues}
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}
