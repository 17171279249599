/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/pages/strategies/builder/editors/_EditorBasePredefinedRankingTemplate
 * @summary Predefined ranking templates
 *
 */

import { deepClone } from "../../../../../../../deepClone";

export class PredefinedRankingTemplate {
    static _options = {
        choose: { label: "Choose", value: "" },

        BWS_PX: { label: "BWS PX", value: "BWS_PX" },
        BWS_A_PX: { label: "BWS A PX", value: "BWS_A_PX" },

        A: { label: "A rating first", value: "A" },
        A_HIGH_MKT_CAP: {
            label: "A rating & Higher mkt. cap.",
            value: "A_HIGH_MKT_CAP",
        },
        A_LOW_MKT_CAP: {
            label: "A rating & Lower mkt. cap.",
            value: "A_LOW_MKT_CAP",
        },
        A_HIGH_PERFORMANCE: {
            label: "A rating & Higher performance since rated",
            value: "A_HIGH_PERFORMANCE",
        },
        A_LOW_PERFORMANCE: {
            label: "A rating & Lower performance since rated",
            value: "A_LOW_PERFORMANCE",
        },
        A_HIGH_VOLATILITY: {
            label: "A rating & Higher volatility",
            value: "A_HIGH_VOLATILITY",
        },
        A_LOW_VOLATILITY: {
            label: "A rating & Lower volatility",
            value: "A_LOW_VOLATILITY",
        },
        A_HIGH_MOMENTUM: {
            label: "A rating & Higher smart momentum",
            value: "A_HIGH_MOMENTUM",
        },
        A_LOW_MOMENTUM: {
            label: "A rating & Lower smart momentum",
            value: "A_LOW_MOMENTUM",
        },
        A_HIGH_TREND_STRENGTH: {
            label: "A rating & Higher Trend Strength",
            value: "A_HIGH_TREND_STRENGTH",
        },
        A_LOW_TREND_STRENGTH: {
            label: "A rating & Lower Trend Strength",
            value: "A_LOW_TREND_STRENGTH",
        },

        B: { label: "B rating first", value: "B" },
        B_HIGH_MKT_CAP: {
            label: "B rating & Higher mkt. cap.",
            value: "B_HIGH_MKT_CAP",
        },
        B_LOW_MKT_CAP: {
            label: "B rating & Lower mkt. cap.",
            value: "B_LOW_MKT_CAP",
        },
        B_HIGH_PERFORMANCE: {
            label: "B rating & Higher performance since rated",
            value: "B_HIGH_PERFORMANCE",
        },
        B_LOW_PERFORMANCE: {
            label: "B rating & Lower performance since rated",
            value: "B_LOW_PERFORMANCE",
        },
        B_HIGH_VOLATILITY: {
            label: "B rating & Higher volatility",
            value: "B_HIGH_VOLATILITY",
        },
        B_LOW_VOLATILITY: {
            label: "B rating & Lower volatility",
            value: "B_LOW_VOLATILITY",
        },
        B_HIGH_MOMENTUM: {
            label: "B rating & Higher smart momentum",
            value: "B_HIGH_MOMENTUM",
        },
        B_LOW_MOMENTUM: {
            label: "B rating & Lower smart momentum",
            value: "B_LOW_MOMENTUM",
        },
        B_HIGH_TREND_STRENGTH: {
            label: "B rating & Higher Trend Strength",
            value: "B_HIGH_TREND_STRENGTH",
        },
        B_LOW_TREND_STRENGTH: {
            label: "B rating & Lower Trend Strength",
            value: "B_LOW_TREND_STRENGTH",
        },

        CLOSEST_TO_THE_MAXIMUM: {
            label: "Closest to the maximum",
            value: "CLOSEST_TO_THE_MAXIMUM",
        },
        CLOSEST_TO_THE_MAXIMUM_NO_KEEP: {
            label: "Closest to the maximum (no keep)",
            value: "CLOSEST_TO_THE_MAXIMUM_NO_KEEP",
        },
        HIGH_MKT_CAP: {
            label: "Higher mkt. cap.",
            value: "HIGH_MKT_CAP",
        },
        LOW_MKT_CAP: { label: "Lower mkt. cap.", value: "LOW_MKT_CAP" },
        HIGH_PERFORMANCE: {
            label: "Higher performance since rated",
            value: "HIGH_PERFORMANCE",
        },
        LOW_PERFORMANCE: {
            label: "Lower performance since rated",
            value: "LOW_PERFORMANCE",
        },
        HIGH_VOLATILITY: {
            label: "Higher volatility",
            value: "HIGH_VOLATILITY",
        },
        LOW_VOLATILITY: {
            label: "Lower volatility",
            value: "LOW_VOLATILITY",
        },
        HIGH_MOMENTUM: {
            label: "Higher smart momentum",
            value: "HIGH_MOMENTUM",
        },
        LOW_MOMENTUM: {
            label: "Lower smart momentum",
            value: "LOW_MOMENTUM",
        },
        HIGH_TREND_STRENGTH: {
            label: "Higher Trend Strength",
            value: "HIGH_TREND_STRENGTH",
        },
        LOW_TREND_STRENGTH: {
            label: "Lower Trend Strength",
            value: "LOW_TREND_STRENGTH",
        },
    };

    static get(type, refValue) {
        refValue = refValue
            ? refValue.replace(/{"field":"exists","sort":"desc"},/gi, "")
            : null;

        var options = this._options;
        var prototype: any = null;

        switch (type) {
            case "LABEL_LOOKUP": {
                var key = this.get("OPTION_LOOKUP", refValue);
                if (key === undefined || key == null) {
                    return "#UNKNOWN";
                }
                return options[key].label;
            }
            case "OPTION_LOOKUP": {
                var A = { field: "rc", sort: "desc" };
                var B = { field: "rc", sort: "asc" };
                var EXISTS = { field: "exists", sort: "desc" };
                var HIGH_MOMENTUM = { field: "mc", sort: "desc" };
                var HIGH_MKT_CAP = { field: "marketcap", sort: "desc" };
                var HIGH_PERFORMANCE = { field: "pr", sort: "desc" };
                var HIGH_PERFORMANCE_QUANTILE = {
                    field: "pr",
                    sort: "asc",
                    rankType: "quantile",
                    rankTypeParams: {
                        n: 5,
                        trimOutlier: false,
                    },
                };
                var HIGH_VOLATILITY = { field: "sd", sort: "desc" };
                var LOW_MOMENTUM = { field: "mc", sort: "asc" };
                var LOW_MKT_CAP = { field: "marketcap", sort: "asc" };
                var LOW_PERFORMANCE = { field: "pr", sort: "asc" };
                var LOW_VOLATILITY = { field: "sd", sort: "asc" };
                // [2018-09-06] ensure reproducibility
                var ORDER_BY_TICKER_ASC = { field: "ticker", sort: "asc" };
                var RETRACEMENT = { field: "px", sort: "desc" };
                var HIGH_TREND_STRENGTH = { field: "ts", sort: "desc" };
                var LOW_TREND_STRENGTH = { field: "ts", sort: "asc" };

                var priorities: any = {
                    HIGH_MKT_CAP: [HIGH_MKT_CAP, ORDER_BY_TICKER_ASC],
                    LOW_MKT_CAP: [LOW_MKT_CAP, ORDER_BY_TICKER_ASC],
                    HIGH_VOLATILITY: [HIGH_VOLATILITY, ORDER_BY_TICKER_ASC],
                    LOW_VOLATILITY: [LOW_VOLATILITY, ORDER_BY_TICKER_ASC],
                    HIGH_PERFORMANCE: [HIGH_PERFORMANCE, ORDER_BY_TICKER_ASC],
                    LOW_PERFORMANCE: [LOW_PERFORMANCE, ORDER_BY_TICKER_ASC],
                    HIGH_MOMENTUM: [HIGH_MOMENTUM, ORDER_BY_TICKER_ASC],
                    LOW_MOMENTUM: [LOW_MOMENTUM, ORDER_BY_TICKER_ASC],
                    CLOSEST_TO_THE_MAXIMUM: [
                        A,
                        HIGH_PERFORMANCE_QUANTILE,
                        EXISTS,
                        RETRACEMENT,
                        ORDER_BY_TICKER_ASC,
                    ],
                    CLOSEST_TO_THE_MAXIMUM_NO_KEEP: [
                        A,
                        HIGH_PERFORMANCE_QUANTILE,
                        RETRACEMENT,
                        ORDER_BY_TICKER_ASC,
                    ],
                    HIGH_TREND_STRENGTH: [
                        HIGH_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],
                    LOW_TREND_STRENGTH: [
                        LOW_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],

                    BWS_PX: [RETRACEMENT],
                    BWS_A_PX: [A, RETRACEMENT],

                    A: [A, ORDER_BY_TICKER_ASC],
                    A_HIGH_MKT_CAP: [A, HIGH_MKT_CAP, ORDER_BY_TICKER_ASC],
                    A_LOW_MKT_CAP: [A, LOW_MKT_CAP, ORDER_BY_TICKER_ASC],
                    A_HIGH_VOLATILITY: [
                        A,
                        HIGH_VOLATILITY,
                        ORDER_BY_TICKER_ASC,
                    ],
                    A_LOW_VOLATILITY: [A, LOW_VOLATILITY, ORDER_BY_TICKER_ASC],
                    A_HIGH_PERFORMANCE: [
                        A,
                        HIGH_PERFORMANCE,
                        ORDER_BY_TICKER_ASC,
                    ],
                    A_LOW_PERFORMANCE: [
                        A,
                        LOW_PERFORMANCE,
                        ORDER_BY_TICKER_ASC,
                    ],
                    A_HIGH_MOMENTUM: [A, HIGH_MOMENTUM, ORDER_BY_TICKER_ASC],
                    A_LOW_MOMENTUM: [A, LOW_MOMENTUM, ORDER_BY_TICKER_ASC],
                    A_HIGH_TREND_STRENGTH: [
                        A,
                        HIGH_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],
                    A_LOW_TREND_STRENGTH: [
                        A,
                        LOW_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],

                    B: [B, ORDER_BY_TICKER_ASC],
                    B_HIGH_MKT_CAP: [B, HIGH_MKT_CAP, ORDER_BY_TICKER_ASC],
                    B_LOW_MKT_CAP: [B, LOW_MKT_CAP, ORDER_BY_TICKER_ASC],
                    B_HIGH_VOLATILITY: [
                        B,
                        HIGH_VOLATILITY,
                        ORDER_BY_TICKER_ASC,
                    ],
                    B_LOW_VOLATILITY: [B, LOW_VOLATILITY, ORDER_BY_TICKER_ASC],
                    B_HIGH_PERFORMANCE: [
                        B,
                        HIGH_PERFORMANCE,
                        ORDER_BY_TICKER_ASC,
                    ],
                    B_LOW_PERFORMANCE: [
                        B,
                        LOW_PERFORMANCE,
                        ORDER_BY_TICKER_ASC,
                    ],
                    B_HIGH_MOMENTUM: [B, HIGH_MOMENTUM, ORDER_BY_TICKER_ASC],
                    B_LOW_MOMENTUM: [B, LOW_MOMENTUM, ORDER_BY_TICKER_ASC],
                    B_HIGH_TREND_STRENGTH: [
                        B,
                        HIGH_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],
                    B_LOW_TREND_STRENGTH: [
                        B,
                        LOW_TREND_STRENGTH,
                        ORDER_BY_TICKER_ASC,
                    ],
                };

                if (refValue in priorities) {
                    return priorities[refValue];
                }

                var inverted_priorities = {};
                for (let key in priorities) {
                    inverted_priorities[JSON.stringify(priorities[key])] = key;
                }

                if (refValue in inverted_priorities) {
                    return inverted_priorities[refValue];
                }

                return null;
            }
            case "OPTIONS": {
                prototype = [
                    options["HIGH_MKT_CAP"],
                    options["LOW_MKT_CAP"],
                    options["HIGH_PERFORMANCE"],
                    options["LOW_PERFORMANCE"],
                    options["HIGH_VOLATILITY"],
                    options["LOW_VOLATILITY"],
                    options["HIGH_MOMENTUM"],
                    options["LOW_MOMENTUM"],
                    options["CLOSEST_TO_THE_MAXIMUM"],
                    options["HIGH_TREND_STRENGTH"],
                    options["LOW_TREND_STRENGTH"],
                ];
                return deepClone(prototype);
            }
            case "OPTIONS_BWS": {
                prototype = [
                    options["BWS_PX"],
                    options["BWS_A_PX"],
                    options["HIGH_MKT_CAP"],
                    options["LOW_MKT_CAP"],
                    options["HIGH_PERFORMANCE"],
                    options["LOW_PERFORMANCE"],
                    options["HIGH_VOLATILITY"],
                    options["LOW_VOLATILITY"],
                    options["HIGH_MOMENTUM"],
                    options["LOW_MOMENTUM"],
                    options["CLOSEST_TO_THE_MAXIMUM"],
                    options["HIGH_TREND_STRENGTH"],
                    options["LOW_TREND_STRENGTH"],
                ];
                return deepClone(prototype);
            }
            case "OPTIONS_EXTENDED": {
                prototype = [
                    options["A"],
                    options["B"],
                    options["HIGH_MKT_CAP"],
                    options["LOW_MKT_CAP"],
                    options["HIGH_MOMENTUM"],
                    options["LOW_MOMENTUM"],
                    {
                        label: "<em>More ...</em>",
                        value: null,
                        children: [
                            options["HIGH_PERFORMANCE"],
                            options["LOW_PERFORMANCE"],
                            options["HIGH_VOLATILITY"],
                            options["LOW_VOLATILITY"],
                            options["CLOSEST_TO_THE_MAXIMUM"],
                            options["HIGH_TREND_STRENGTH"],
                            options["LOW_TREND_STRENGTH"],
                            { type: "separator" },
                            options["A_HIGH_MKT_CAP"],
                            options["A_LOW_MKT_CAP"],
                            options["A_HIGH_PERFORMANCE"],
                            options["A_LOW_PERFORMANCE"],
                            options["A_HIGH_VOLATILITY"],
                            options["A_LOW_VOLATILITY"],
                            options["A_HIGH_MOMENTUM"],
                            options["A_LOW_MOMENTUM"],
                            options["A_HIGH_TREND_STRENGTH"],
                            options["A_LOW_TREND_STRENGTH"],
                            { type: "separator" },
                            options["B_HIGH_MKT_CAP"],
                            options["B_LOW_MKT_CAP"],
                            options["B_HIGH_PERFORMANCE"],
                            options["B_LOW_PERFORMANCE"],
                            options["B_HIGH_VOLATILITY"],
                            options["B_LOW_VOLATILITY"],
                            options["B_HIGH_MOMENTUM"],
                            options["B_LOW_MOMENTUM"],
                            options["B_HIGH_TREND_STRENGTH"],
                            options["B_LOW_TREND_STRENGTH"],
                        ],
                    },
                ];
                return deepClone(prototype);
            }
            case "OPTIONS_EXTENDED_BWS": {
                prototype = [
                    options["BWS_PX"],
                    options["BWS_A_PX"],
                    options["A"],
                    options["B"],
                    options["HIGH_MKT_CAP"],
                    options["LOW_MKT_CAP"],
                    options["HIGH_MOMENTUM"],
                    options["LOW_MOMENTUM"],
                    {
                        label: "<em>More ...</em>",
                        value: null,
                        children: [
                            options["HIGH_PERFORMANCE"],
                            options["LOW_PERFORMANCE"],
                            options["HIGH_VOLATILITY"],
                            options["LOW_VOLATILITY"],
                            options["CLOSEST_TO_THE_MAXIMUM"],
                            options["HIGH_TREND_STRENGTH"],
                            options["LOW_TREND_STRENGTH"],
                            { type: "separator" },
                            options["A_HIGH_MKT_CAP"],
                            options["A_LOW_MKT_CAP"],
                            options["A_HIGH_PERFORMANCE"],
                            options["A_LOW_PERFORMANCE"],
                            options["A_HIGH_VOLATILITY"],
                            options["A_LOW_VOLATILITY"],
                            options["A_HIGH_MOMENTUM"],
                            options["A_LOW_MOMENTUM"],
                            options["A_HIGH_TREND_STRENGTH"],
                            options["A_LOW_TREND_STRENGTH"],
                            { type: "separator" },
                            options["B_HIGH_MKT_CAP"],
                            options["B_LOW_MKT_CAP"],
                            options["B_HIGH_PERFORMANCE"],
                            options["B_LOW_PERFORMANCE"],
                            options["B_HIGH_VOLATILITY"],
                            options["B_LOW_VOLATILITY"],
                            options["B_HIGH_MOMENTUM"],
                            options["B_LOW_MOMENTUM"],
                            options["B_HIGH_TREND_STRENGTH"],
                            options["B_LOW_TREND_STRENGTH"],
                        ],
                    },
                ];
                return deepClone(prototype);
            }
        }

        return null;
    }

    static getLookUpForServer = (key: string, keepValidPositions: boolean) => {
        let serverFormat: any = [];

        switch (key) {
            case "CLOSEST_TO_THE_MAXIMUM":
                serverFormat = [
                    { dimension: "rc", rev: true },
                    {
                        dimension: "pr",
                        rev: false,
                        rankType: "quantile",
                        rankTypeParams: {
                            n: 5,
                            trimOutlier: false,
                        },
                    },
                    { dimension: "exists", rev: true },
                    { dimension: "px", rev: true },
                    { dimension: "ticker", rev: false },
                ];
                break;
            default:
                const ranking = this.get("OPTION_LOOKUP", key);
                if (ranking != null) {
                    ranking.forEach((element) => {
                        if (element.field !== undefined) {
                            element.dimension = element.field;
                            delete element.field;
                        }
                        if (element.sort !== undefined) {
                            element.rev = element.sort === "desc";
                            delete element.sort;
                        }
                        if (element.dimension === "ticker") {
                            element.rankType = "string-to-double";
                        }
                        serverFormat.push(element);

                        const checkForExistObject = serverFormat.find(
                            (item) => item.dimension === "exists"
                        );

                        if (keepValidPositions && !checkForExistObject) {
                            serverFormat.splice(0, 0, {
                                dimension: "exists",
                                rev: true,
                            });
                        }
                    });
                }
        }
        return serverFormat;
    };
}
