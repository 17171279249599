import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { SystematicProductFormatter } from "../../../../../trendrating/app/formatter/SystematicProduct";
import { Strategy, SystematicProduct } from "../../../../../types/Api";
import { TDate } from "../../../../../trendrating/date/TDate";
import { OpenInNewTabButton } from "../../../../../components/OpenInNewTabButton/OpenInNewTabButton";
import { Box } from "@mui/system";

type ProductMetaProps = {
  strategy?: Strategy;
  benchmark: any;
  product: SystematicProduct;
  holdings: any;
};

export function ProductMeta({
  strategy,
  benchmark,
  product,
  holdings,
}: ProductMetaProps) {
  const { t } = useTranslation();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useMemo(
    () => new SystematicProductFormatter(appSetup),
    [appSetup]
  );

  const creeationTime = useMemo(() => {
    return TDate.dateToDays(new Date(product.creationTime));
  }, [product.creationTime]);
  const updateTime = useMemo(() => {
    return TDate.dateToDays(new Date(product.updateTime));
  }, [product.updateTime]);

  const inceptionDate = useMemo(() => {
    return product.inceptionDate < TDate.today() ? product.inceptionDate : null;
  }, [product.inceptionDate]);

  return (
    <div className="wSystematicProductMeta">
      <table>
        <tbody>
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_date_create")}</th>
            <td
              data-dojo-attach-point="nodeDateCreate"
              dangerouslySetInnerHTML={{
                __html: formatter.date(creeationTime),
              }}
            ></td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_date_update")}</th>
            <td
              data-dojo-attach-point="nodeDateUpdate"
              dangerouslySetInnerHTML={{
                __html: formatter.date(updateTime),
              }}
            ></td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_currency")}</th>
            <td
              data-dojo-attach-point="nodeCurrency"
              dangerouslySetInnerHTML={{
                __html: formatter.currency(product.currency),
              }}
            ></td>
          </tr>
          {benchmark != null && (
            <tr data-dojo-attach-point="nodeBenchmarkRow">
              <th style={{ textAlign: "left" }}>{t("c_benchmark")}</th>
              <td
                data-dojo-attach-point="nodeBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.benchmark(benchmark),
                }}
              ></td>
            </tr>
          )}
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_inception_date")}</th>
            <td
              data-dojo-attach-point="nodeInceptionDate"
              dangerouslySetInnerHTML={{
                __html: inceptionDate ? formatter.date(inceptionDate) : "",
              }}
            ></td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_inception_value")}</th>
            <td
              data-dojo-attach-point="nodeInceptionValue"
              title={inceptionDate ? formatter.date(inceptionDate) : ""}
            >
              {product.inceptionValue}
            </td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>
              {t("w_systematic_product_overview_holdings_current")}
            </th>
            <td data-dojo-attach-point="nodeHoldingsCurrent">
              {holdings ? holdings.length : "-"}
            </td>
          </tr>
          {strategy && (
            <tr>
              <th style={{ textAlign: "left" }}>{t("c_strategy")}</th>
              <td>
                <Box display={"flex"} alignItems={"center"}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        strategy["name"] +
                        (strategy["isReadOnly"]
                          ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
                          : ""),
                    }}
                  ></span>
                  <OpenInNewTabButton
                    type={"strategies"}
                    id={strategy["id"]!}
                  />
                </Box>
              </td>
            </tr>
          )}
          {/* <tr>
            <th>{t("w_systematic_product_overview_holdings_historical")}</th>
            <td data-dojo-attach-point="nodeHoldingsHistorical">
              {product.historicalPortfolioName}
            </td>
          </tr> */}
          <tr>
            <th style={{ textAlign: "left" }}>{t("c_rebalance")}</th>
            <td
              data-dojo-attach-point="nodeRebalance"
              dangerouslySetInnerHTML={{
                __html: formatter.rebalance(product.reviewGranularity),
              }}
            ></td>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>Autorebalance</th>
            <td data-dojo-attach-point="nodeRebalance">
              {product.autorebalance != null
                ? product.autorebalance
                  ? "On"
                  : "Off"
                : "unknow"}
            </td>
          </tr>
          {strategy && (
            <tr>
              <th style={{ textAlign: "left" }}>
                {t("page_builder_field_performance_label")}
              </th>
              <td
                data-dojo-attach-point="nodePerformance"
                dangerouslySetInnerHTML={{
                  __html: formatter.strategyPerformance(
                    strategy.params.strategy.performance
                  ),
                }}
              ></td>
            </tr>
          )}
          <tr>
            <th style={{ textAlign: "left" }}>Management fees</th>
            <td
              data-dojo-attach-point="nodeExpenseRatio"
              dangerouslySetInnerHTML={{
                __html: product.expenseRatio
                  ? formatter.formatPercentage(product.expenseRatio)
                  : "-",
              }}
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
