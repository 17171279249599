import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { targetNameVariable, targetTickerVariable } from "../../utils/utils";
import { KeyfactsType, keyfactsReducer } from "./React_Keyfacts_reducer";

type KeyFactsProps = {
  configObj: any;
};

const KeyFacts = forwardRef(({ configObj }: KeyFactsProps, ref) => {
  // const targetNameVariable = "${name}";
  // const targetTickerVariable = "${ticker}";

  const initState: KeyfactsType = useMemo(
    () => ({
      annualized: configObj.widgetValue.content.annualized,
      averageYearlyDrawdown:
        configObj.widgetValue.content.averageYearlyDrawdown,
      benchmark: configObj.widgetValue.content.benchmark,
      beta: configObj.widgetValue.content.beta,
      cumulative: configObj.widgetValue.content.cumulative,
      delta: configObj.widgetValue.content.delta,
      headlineKeyRatio: configObj.widgetValue.content.headlineKeyRatio,
      headlinePerformance: configObj.widgetValue.content.headlinePerformance,
      headlineRisk: configObj.widgetValue.content.headlineRisk,
      maxConsecutiveNegativeMonths:
        configObj.widgetValue.content.maxConsecutiveNegativeMonths,
      maxConsecutivePositiveMonths:
        configObj.widgetValue.content.maxConsecutivePositiveMonths,
      maxDrawdown: configObj.widgetValue.content.maxDrawdown,
      monthlyStandardDeviation:
        configObj.widgetValue.content.monthlyStandardDeviation,
      oneWayTurnover: configObj.widgetValue.content.oneWayTurnover,
      percentPositiveMonths:
        configObj.widgetValue.content.percentPositiveMonths,
      sharpeRatio: configObj.widgetValue.content.sharpeRatio,
      sortinoRatio: configObj.widgetValue.content.sortinoRatio,
      sterlingRatio: configObj.widgetValue.content.sterlingRatio,
      strategy: configObj.widgetValue.content.strategy,
      informationRatio: configObj.widgetValue.content.informationRatio,
      trackingError: configObj.widgetValue.content.trackingError,
      treynorRatio: configObj.widgetValue.content.treynorRatio,
      yearlyAverage: configObj.widgetValue.content.yearlyAverage,
    }),
    [
      configObj.widgetValue.content.annualized,
      configObj.widgetValue.content.averageYearlyDrawdown,
      configObj.widgetValue.content.benchmark,
      configObj.widgetValue.content.beta,
      configObj.widgetValue.content.cumulative,
      configObj.widgetValue.content.delta,
      configObj.widgetValue.content.headlineKeyRatio,
      configObj.widgetValue.content.headlinePerformance,
      configObj.widgetValue.content.headlineRisk,
      configObj.widgetValue.content.informationRatio,
      configObj.widgetValue.content.maxConsecutiveNegativeMonths,
      configObj.widgetValue.content.maxConsecutivePositiveMonths,
      configObj.widgetValue.content.maxDrawdown,
      configObj.widgetValue.content.monthlyStandardDeviation,
      configObj.widgetValue.content.oneWayTurnover,
      configObj.widgetValue.content.percentPositiveMonths,
      configObj.widgetValue.content.sharpeRatio,
      configObj.widgetValue.content.sortinoRatio,
      configObj.widgetValue.content.sterlingRatio,
      configObj.widgetValue.content.strategy,
      configObj.widgetValue.content.trackingError,
      configObj.widgetValue.content.treynorRatio,
      configObj.widgetValue.content.yearlyAverage,
    ]
  );

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state;
      return tempState;
    },
  }));

  const [state, dispatch] = useImmerReducer(keyfactsReducer, initState);



  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ display: "flex", flexDirection: "column",pb: "16px !important", }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={state.strategy.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_strategy_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Strategy"
            />
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={2}
              justifyContent={"end"}
            >
              <Button
                onClick={(e) =>
                  dispatch({
                    type: "SET_strategy_CONTENT",
                    payload: `${state.strategy.content} ${targetNameVariable}`,
                  })
                }
              >
                Add target's name
              </Button>
              <TextField
                value={state.strategy.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_strategy_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.strategy.isEnabled}
                size="small"
              />
            </Box>
          </Box>

          {/* Benchmark */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={state.benchmark.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_benchmark_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Benchmark"
            />

            <Box
              display={"flex"}
              alignItems={"center"}
              gap={2}
              justifyContent={"end"}
            >
              <Button
                onClick={(e) =>
                  dispatch({
                    type: "SET_benchmark_CONTENT",
                    payload: `${state.benchmark.content} ${targetNameVariable}`,
                  })
                }
              >
                Add target's name
              </Button>
              <Button
                onClick={(e) =>
                  dispatch({
                    type: "SET_benchmark_CONTENT",
                    payload: `${state.benchmark.content} ${targetTickerVariable}`,
                  })
                }
              >
                Add target's ticker
              </Button>
              <TextField
                value={state.benchmark.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_benchmark_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Delta */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={state.delta.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_delta_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                />
              }
              label="Difference"
            />

            <Box
              display={"flex"}
              alignItems={"center"}
              gap={2}
              justifyContent={"end"}
            >
              <Button
                onClick={(e) =>
                  dispatch({
                    type: "SET_delta_CONTENT",
                    payload: `${state.delta.content} ${targetNameVariable}`,
                  })
                }
              >
                Add target's name
              </Button>

              <TextField
                value={state.delta.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_delta_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.delta.isEnabled}
                size="small"
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{pb: "16px !important"}}>
          <Typography> Key Facts </Typography>
         

          <CustomComponent
            switchLabel={"Key ratio headline"}
            switchChecked={state.headlineKeyRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_headlineKeyRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.headlineKeyRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_headlineKeyRatio_CONTENT",
                payload: e,
              })
            }
          />


          <CustomComponent
            switchLabel={"One Way Turnover"}
            switchChecked={state.oneWayTurnover.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_oneWayTurnover_ISENABLED",
                payload: e,
              })
            }
            textValue={state.oneWayTurnover.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_oneWayTurnover_CONTENT",
                payload: e,
              })
            }
          />

     
          <CustomComponent
            switchLabel={"Sharpe Ratio"}
            switchChecked={state.sharpeRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_sharpeRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.sharpeRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_sharpeRatio_CONTENT",
                payload: e,
              })
            }
          />

          
          <CustomComponent
            switchLabel={"Sterling Ratio"}
            switchChecked={state.sterlingRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_sterlingRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.sterlingRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_sterlingRatio_CONTENT",
                payload: e,
              })
            }
          />
     

          <CustomComponent
            switchLabel={"Sortino Ratio"}
            switchChecked={state.sortinoRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_sortinoRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.sortinoRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_sortinoRatio_CONTENT",
                payload: e,
              })
            }
          />

        
          <CustomComponent
            switchLabel={"Beta"}
            switchChecked={state.beta.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_beta_ISENABLED",
                payload: e,
              })
            }
            textValue={state.beta.content}
            textCallback={(e) =>
              dispatch({ type: "SET_beta_CONTENT", payload: e })
            }
          />

       

          <CustomComponent
            switchLabel={"Tracking Error"}
            switchChecked={state.trackingError.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_trackingError_ISENABLED",
                payload: e,
              })
            }
            textValue={state.trackingError.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_trackingError_CONTENT",
                payload: e,
              })
            }
          />
        

          <CustomComponent
            switchLabel={"Information Ratio"}
            switchChecked={state.informationRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_informationRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.informationRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_informationRatio_CONTENT",
                payload: e,
              })
            }
          />
        

          <CustomComponent
            switchLabel={"Treynor ratio"}
            switchChecked={state.treynorRatio.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_treynorRatio_ISENABLED",
                payload: e,
              })
            }
            textValue={state.treynorRatio.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_treynorRatio_CONTENT",
                payload: e,
              })
            }
          />
         

          <CustomComponent
            switchLabel={"Percent Positive Months"}
            switchChecked={state.percentPositiveMonths.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_percentPositiveMonths_ISENABLED",
                payload: e,
              })
            }
            textValue={state.percentPositiveMonths.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_percentPositiveMonths_CONTENT",
                payload: e,
              })
            }
          />
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{pb: "16px !important"}}>
          <Typography>Risk </Typography>
          
         

          <CustomComponent
            switchLabel={"Risk Headline"}
            switchChecked={state.headlineRisk.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_headlineRisk_ISENABLED",
                payload: e,
              })
            }
            textValue={state.headlineRisk.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_headlineRisk_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Max drawdown"}
            switchChecked={state.maxDrawdown.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_maxDrawdown_ISENABLED",
                payload: e,
              })
            }
            textValue={state.maxDrawdown.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_maxDrawdown_CONTENT",
                payload: e,
              })
            }
          />
      

          <CustomComponent
            switchLabel={"Average yearly drawdown"}
            switchChecked={state.averageYearlyDrawdown.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_averageYearlyDrawdown_ISENABLED",
                payload: e,
              })
            }
            textValue={state.averageYearlyDrawdown.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_averageYearlyDrawdown_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Monthly Standard Deviation"}
            switchChecked={state.monthlyStandardDeviation.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_monthlyStandardDeviation_ISENABLED",
                payload: e,
              })
            }
            textValue={state.monthlyStandardDeviation.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_monthlyStandardDeviation_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Max consecutive negative months"}
            switchChecked={state.maxConsecutiveNegativeMonths.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_maxConsecutiveNegativeMonths_ISENABLED",
                payload: e,
              })
            }
            textValue={state.maxConsecutiveNegativeMonths.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_maxConsecutiveNegativeMonths_CONTENT",
                payload: e,
              })
            }
          />
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{pb: "16px !important"}}>
          <Typography>Performance </Typography>

          <CustomComponent
            switchLabel={"Performance headline"}
            switchChecked={state.headlinePerformance.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_headlinePerformance_ISENABLED",
                payload: e,
              })
            }
            textValue={state.headlinePerformance.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_headlinePerformance_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Cumulative"}
            switchChecked={state.cumulative.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_cumulative_ISENABLED",
                payload: e,
              })
            }
            textValue={state.cumulative.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_cumulative_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Annualized"}
            switchChecked={state.annualized.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_annualized_ISENABLED",
                payload: e,
              })
            }
            textValue={state.annualized.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_annualized_CONTENT",
                payload: e,
              })
            }
          />
         
          <CustomComponent
            switchLabel={"Yearly Average"}
            switchChecked={state.yearlyAverage.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_yearlyAverage_ISENABLED",
                payload: e,
              })
            }
            textValue={state.yearlyAverage.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_yearlyAverage_CONTENT",
                payload: e,
              })
            }
          />
          
          <CustomComponent
            switchLabel={"Max consecutive positive months"}
            switchChecked={state.maxConsecutivePositiveMonths.isEnabled}
            switchCallback={(e) =>
              dispatch({
                type: "SET_maxConsecutivePositiveMonths_ISENABLED",
                payload: e,
              })
            }
            textValue={state.maxConsecutivePositiveMonths.content}
            textCallback={(e) =>
              dispatch({
                type: "SET_maxConsecutivePositiveMonths_CONTENT",
                payload: e,
              })
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
});
export default KeyFacts;

type customComponentType = {
  switchLabel: string;
  switchChecked: boolean;
  switchCallback: Function;
  textValue: any;
  textCallback: Function;
};
export const CustomComponent = ({
  switchLabel,
  switchChecked,
  switchCallback,
  textCallback,
  textValue,
}: customComponentType) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={switchChecked}
              onChange={(e) => switchCallback(e.target.checked)}
              size="small"
            />
          }
          label={switchLabel}
        />
      </Box>
      <Box>
        <TextField
          value={textValue}
          onChange={(e) => textCallback(e.target.value)}
          disabled={!switchChecked}
          size="small"
        />
      </Box>
    </Box>
  );
};
