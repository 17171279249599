export type MomentumBreakdownType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    isBreadth: boolean;
    dataCross: "MARKET_INDUSTRY" | "MARKET_SECTOR" | "REGION_INDUSTRY" | "REGION_SECTOR";
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_NUMBERS_IS_BREADTH"
    | "SET_CONTENT_NUMBERS_DATACROSS";
  payload: any;
};

export const momentumBreakdownReducer = (draft: MomentumBreakdownType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_NUMBERS_IS_BREADTH":
      draft.content.isBreadth = action.payload;
      break;
    case "SET_CONTENT_NUMBERS_DATACROSS":
      draft.content.dataCross = action.payload;
      break;
    default:
      break;
  }
};
