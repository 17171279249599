import React, { useCallback, useMemo } from "react";
import { deepClone } from "../../../../../../deepClone";
import { widgetsConfiguration } from "../../../../widgets/widgetsConfiguration";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { CSV } from "../../../../utils/SingletonCSV";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type ExportConstituentsProps = {
  strategy: any;
  runManager: any;
};

export default function NewExportConstituents({
  strategy,
  runManager,
}: ExportConstituentsProps) {
  const environment = useEnvironment();
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const format = useFormatter();
  const onExportConstituents = useCallback(async () => {
    const curves = await runManager.getCurves();
    let positions = deepClone(curves.POS.H[curves.POS.H.length - 1]);
    let item: any = null;
    let symbols: any[] = [];
    for (let i = 0, length = positions.v.length; i < length; i++) {
      item = positions.v[i];
      if (item.S !== "CASH") {
        symbols.push(item.S);
      }
    }

    const properties =
      widgetsConfiguration["widgets/csv/builder/constituents"]["properties"];

    const response = await instrumentsAPI.fetch({
      properties: properties,
      symbols: symbols,
      type: "security",
    });

    var securities = {};
    var security: any = null;
    for (let i = 0, length = response.data.length; i < length; i++) {
      security = response.data[i];
      securities[security.symbol] = security;
    }
    var lines = _format(positions, securities, format);
    CSV.create(lines, strategy.name + ".csv");
    // **************** USAGE ******************
    var usage = window.App.usage;
    var info = {
      action: "EXPORT_CONSTITUENT",
      actionParam: strategy["id"],
      function: "STRATEGY_BUILDER",
    };
    usage.record(info);
    // **************** USAGE ******************
  }, [format, instrumentsAPI, runManager, strategy]);
  return (
    <li
      onClick={onExportConstituents}
      className="menu__item"
      title={"Export to MS Excel the current constituents of this strategy"}
    >
      Export constituents
    </li>
  );
}

const _format = (constituents, securities, format) => {
  var lines: any[] = [];

  var data = constituents.v;
  var header = [
    '"Weight"',
    '"Ticker"',
    '"Market ISO"',
    '"Currency"',
    '"ISIN"',
    '"Name"',
    '"Date"',
  ];
  lines.push(header);

  var cash: any = null;
  var line: any = null;
  var security: any = null;
  var symbol: any = null;
  for (let i = 0, length = data.length; i < length; i++) {
    symbol = data[i].S;
    if (symbol === "CASH") {
      cash = [
        '"' +
          format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: "",
                output: "",
              },
            },
            output: "TEXT",
            value: constituents.v[i].A,
            valueHelper: null,
          }) +
          '"', // weight
        '"CASH"', // ticker
        '""', // market ISO
        '""', // currency
        '""', // ISIN
        '""', // name
        '"' +
          format.custom("date", {
            options: {
              notAvailable: {
                input: "",
                output: "",
              },
            },
            output: "TEXT",
            value: constituents.d,
            valueHelper: null,
          }) +
          '"', // date
      ];
    } else {
      security = securities[symbol];
      line = [
        '"' +
          format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: "",
                output: "",
              },
            },
            output: "TEXT",
            value: constituents.v[i].A,
            valueHelper: null,
          }) +
          '"', // weight
        '"' + security.ticker + '"', // ticker
        '"' + security.country + '"', // market ISO
        '"' + security.currency + '"', // currency
        '"' + security.isin + '"', // ISIN
        '"' + security.name + '"', // name
        '"' +
          format.custom("date", {
            options: {
              notAvailable: {
                input: "",
                output: "",
              },
            },
            output: "TEXT",
            value: constituents.d,
            valueHelper: null,
          }) +
          '"', // date
      ];
      lines.push(line);
    }
  }
  if (cash) {
    lines.push(cash);
  }

  return lines;
};
