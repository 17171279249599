/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/customPropertiesFormattingOptions
 * @summary Custom propertiers used for formatting in a standard way
 *
 */
export const customPropertiesFormattingOptions = {
    DEFAULT: {
        notAvailable: {
            input: null,
            output: "",
        },
    },
    NUMBER: {
        decimals: 0,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    NUMBER_4_DECIMALS: {
        decimals: 4,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    NUMBER_4_DECIMALS_COLORED: {
        colored: "positive",
        decimals: 4,
        hasPositiveSign: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    NUMBER_COLORED: {
        colored: "positive",
        decimals: 0,
        hasPositiveSign: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    NUMBER_COLORED_INVERTED: {
        colored: "negative",
        decimals: 0,
        hasPositiveSign: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE: {
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_ANALYTICS: {
        isPercentage: true,
        notAvailable: {
            input: "",
            output: "",
        },
    },
    PERCENTAGE_4_DECIMALS: {
        decimals: 4,
        notAvailable: {
            input: null,
            output: "",
        },
        percentage: true,
    },
    PERCENTAGE_NO_DECIMALS: {
        decimals: 0,
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_COLORED: {
        colored: "positive",
        notAvailable: {
            input: null,
            output: "",
        },
        percentage: true,
    },
    PERCENTAGE_COLORED_INVERTED: {
        colored: "negative",
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_COLORED_NO_DECIMALS: {
        colored: "positive",
        decimals: 0,
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_SIGN: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_SIGN_COLORED: {
        colored: "positive",
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_SIGN_COLORED_INVERTED: {
        colored: "negative",
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
            input: null,
            output: "",
        },
    },
    PERCENTAGE_SIGN_COLORED_4_DECIMALS: {
        colored: "positive",
        decimals: 4,
        notAvailable: {
            input: null,
            output: "",
        },
        percentage: true,
        positiveSign: true,
    },
};
