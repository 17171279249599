import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";

type ListGenericType = {
  headerTitle: string;
  description?: string;
  logoURL: string;
  actionButton: Function | undefined;
  actionButtonTitle: string;
  child: React.ReactElement;
};
const ListGeneric = ({
  headerTitle,
  description,
  logoURL,
  actionButton,
  actionButtonTitle,
  child,
}: ListGenericType) => {
  return (
    <Card sx={{ flex: 1, maxWidth: "33.3%", boxShadow: 3 }}>
      <CardContent
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
        }}
      >
        <Box
          p={2}
          pb={0}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="subtitle1">{headerTitle}</Typography>
          <img height={48} width={48} src={logoURL} alt="" />
          {description && <Typography>{description}</Typography>}

          {actionButton ? (
            <Button onClick={() => actionButton()}>{actionButtonTitle}</Button>
          ) : (
            <Button style={{ visibility: "hidden" }}></Button>
          )}

          <Divider
            sx={{ color: "primary", width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
        </Box>
        <Box overflow={"hidden"} height={"100%"}>
          {child}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ListGeneric;
