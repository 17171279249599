import { Box, Card, CardContent, Switch, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { deepClone } from "../../../../../deepClone";
import IssuesList from "./Issues/IssuesList";
import TacticalAndImplement from "./widgets/TacticalAndImplement";
import { getIssues } from "./utils";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip";

type IssuesOutputWrapperProps = {
  paramsWizard: any;
  orders: any;
  list: any;
  onChangeOrder: Function;
  setOrders: Function;
};

export default function IssuesOutputWrapper({
  paramsWizard,
  list,
  orders,
  onChangeOrder,
  setOrders,
}: IssuesOutputWrapperProps) {
  const [view, setView] = useState<"list" | "table">("list");
  const format = useFormatter();

  const _computeTurnover = useMemo(() => {
    var buy = 0;
    for (let i = 0, length = orders?.buy.length; i < length; i++) {
      const _weight = orders?.buy[i].weight;
      buy = buy + _weight;
    }
    var sell = 0;
    for (let i = 0, length = orders?.sell.length; i < length; i++) {
      sell = sell + orders?.sell[i].weight;
    }
    var turnover = 0;
    if (buy > 0 && sell > 0) {
      turnover = Math.min(buy, sell);
    } else {
      turnover = Math.max(buy, sell);
    }
    const returningValue = format.custom("number", {
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: turnover,
      valueHelper: null,
    });
    return returningValue;
  }, [format, orders?.buy, orders?.sell]);
  const [issues, setIssues] = useState<any>(list ? getIssues(list) : null);

  const [issuesCounter, setIssuesCounter] = useState(() => {
    const _issues = getIssues(list);
    return _issues.C.holdings.length + _issues.D.holdings.length;
  });
  useEffect(() => {
    if (issuesCounter === 0) {
      setView("table");
    }
  }, [issuesCounter]);

  return (
    <Box display={"flex"} overflow={"hidden"} flexDirection={"column"} gap={1}>
      <Typography px={1} variant="subtitle1">
        There are {issues?.D?.total} <strong className="rate rate--D">D</strong>{" "}
        and {issues?.C?.total} <strong className="rate rate--C">C</strong> rated
        securities that match{" "}
        <strong>
          {((issues?.D?.weight + issues?.C?.weight) * 100).toFixed(2)}%
        </strong>{" "}
        of the portfolio.
      </Typography>
      <Box px={1}>
        <Card sx={{ boxShadow: 1 }}>
          <CardContent sx={{ p: "8px !important" }}>
            <Box display={"flex"} justifyContent={"space-between"} gap={1}>
              <Box>
                Turnover: <strong>{_computeTurnover}</strong>
              </Box>
              <Tooltip
                content={
                  <div className="optimization__tooltip">
                    <h1 className="optimization__tooltip-title">
                      One-way turnover
                    </h1>
                    <p className="optimization__tooltip-text">
                      Turnover measures the percentage change in the composition
                      of the portfolio, i.e. the percentage change of
                      portfolio's holdings that have been &quot;turned
                      over&quot;.
                    </p>
                    <p className="optimization__tooltip-text">
                      Trendrating computes the one-way turnover at the
                      rebalancing date using the standard measure of turnover,
                      the one adopted by the SEC (Security Exchange Commission)
                      which consider the lesser between sales and purchases.
                    </p>
                    <br />
                  </div>
                }
                tooltipIcon={false}
              >
                <span className="alerts-info-tooltip-icon i-help"></span>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {(orders?.buy.length > 0 || orders?.sell.length > 0) && (
        <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setView("list")}
          >
            Finder Viewer
          </Typography>
          <Switch
            sx={{
              "& .MuiSwitch-thumb": {
                backgroundColor: "#2A7090",
              },
            }}
            color="default"
            size="small"
            checked={view === "table"}
            onChange={(e) => {
              if (e.target.checked) {
                setView("table");
              } else {
                setView("list");
              }
            }}
          />
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => setView("table")}
          >
            Buy and Sell Viewer
          </Typography>
        </Box>
      )}
      <Box display={view === "list" ? "flex" : "none"} overflow={"hidden"}>
        <IssuesList
          paramsWizard={paramsWizard}
          list={list}
          onSell={(sellingItem) => {
            const _sellingItem = deepClone(sellingItem);
            _sellingItem["from"] = "sell";
            const temp = deepClone(orders);
            temp.sell = [...temp.sell, sellingItem];
            onChangeOrder({
              payload: temp,
              action: "sellFromIssue",
            });
            //!---------------------
            var rating = sellingItem.rc === -2 ? "D" : "C";
            const _issues = deepClone(issues);

            _issues[rating].total = _issues[rating].total - 1;
            _issues[rating].weight =
              _issues[rating].weight - sellingItem.weight;

            var index: any = null;
            for (
              var i = 0, length = _issues[rating].holdings.length;
              i < length;
              i++
            ) {
              if (_issues[rating].holdings[i].symbol === sellingItem.symbol) {
                index = i;
                break;
              }
            }
            _issues[rating].holdings.splice(index, 1);
            setIssues(_issues);
            setIssuesCounter(issuesCounter - 1);
            //!---------------------
          }}
          onHold={() => {
            //??dovrebbe soltanto rimuovere il widget issue dalla
            //??lista senza effettuare nessuna modifica nello stato superiore?
            setIssuesCounter(issuesCounter - 1);
          }}
          onSwap={(swapOrder) => {
            swapOrder = {
              buy: swapOrder.buy.map((item) => ({ ...item, skip: false })),
              sell: swapOrder.sell.map((item) => ({ ...item, skip: false })),
            };
            setIssuesCounter(issuesCounter - 1);
            onChangeOrder({
              payload: swapOrder,
              action: "swapFromIssue",
            });
          }}
        />
      </Box>

      <Box p={1} width={"100%"} display={view === "table" ? "flex" : "none"}>
        <TacticalAndImplement orders={orders} onChangeOrder={onChangeOrder} />
      </Box>
    </Box>
  );
}
