/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/core/Array
 * @summary Array utilities
 *
 */

export class UtilsArray {
    /**
     * Remove duplicates from array
     *
     * @param {array} elements
     * @param {string} discriminantProperty - if it is an array of objects,
     *      it indicates the property used as discriminant
     */
    static removeDuplicates(elements: any[], discriminantProperty: string) {
        const elementsMap: Record<string, any> = {};
        for (const element of elements) {
            if (discriminantProperty != null) {
                if (!(element[discriminantProperty] in elementsMap)) {
                    elementsMap[element[discriminantProperty]] = element;
                }
            } else {
                if (!(element in elementsMap)) {
                    elementsMap[element] = element;
                }
            }
        }

        const _array: any = [];
        for (const element of Object.values(elementsMap)) {
            _array.push(element);
        }

        return _array;
    }

    static intersection(arrayA: any[], arrayB: any[]) {
        console.log("Not implemented");
        // const fastIntersection = (a1, a2) => {
        //     if (a1.length < a2.length) (
        //         shortest = a1;
        //     longest = a2;
        // }
        // else {
        //     shortest = a1;
        //     longest = a2;
        // }
        // result = [];
        // for (let i = 0, N = shortest.length; i < N; i++) {
        //     const item = shortest[i];
        //     if (longest.includes(item)) {
        //         result.push(item);
        //     }
        // }
        // return result;
        // }
        return [];
    }

    static union(arrayA: any[], arrayB: any[]) {
        // TODO better use Array.from(new Set(...))
        const _union: any = {};
        for (const itemA of arrayA) {
            _union[itemA] = 1;
        }

        for (const itemB of arrayB) {
            _union[itemB] = 1;
        }

        const elements: any = [];
        for (const element in _union) {
            elements.push(element);
        }

        return elements;
    }
}
