import { Box, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import BackNavigation from "../BackNavigation/BackNavigation";
import styles from "./PeerPresetsRow.module.scss";

type PeerPresetsRowProps = {
    changePeer: (value) => void;
    navigationHistory: any;
    backHandler: ({
        peer,
        analytic,
    }: {
        peer: string;
        analytic:
            | "tcr"
            | "history"
            | "dispersion"
            | "securities"
            | "dispersionBy";
    }) => void;
};

const presets = [
    {
        label: "Asset Class",
        etfClass: "ETFSEGMENT",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: {
                function: "taxonomy",
                params: { level: "1 Industry" },
            },
        },
    },
    {
        label: "Commodities",
        etfClass: "ETF_CO",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: { function: "taxonomy", params: { level: "3 Sector" } },
        },
    },
    {
        label: "Currencies",
        etfClass: "ETF_CU",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: { function: "taxonomy", params: { level: "3 Sector" } },
        },
    },
    {
        label: "Fixed Income",
        etfClass: "ETF_FI",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: { function: "taxonomy", params: { level: "3 Sector" } },
        },
    },
    {
        label: "Equity",
        etfClass: "ETF_EQ",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: { function: "taxonomy", params: { level: "3 Sector" } },
        },
    },
    {
        label: "Equity Sector",
        etfClass: "ETF_EQ_FO",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: {
                function: "taxonomy",
                params: { level: "4 Subsector" },
            },
        },
    },
    {
        label: "Equity Size",
        etfClass: "ETF_EQ_SZ",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: {
                function: "taxonomy",
                params: { level: "4 Subsector" },
            },
        },
    },
    {
        label: "Equity Factor",
        etfClass: "ETF_EQ_SR",
        etfgeo: "WWW",
        groupBy: {
            dimension: "etfclass",
            transform: {
                function: "taxonomy",
                params: { level: "4 Subsector" },
            },
        },
    },
];

export function PeerPresetsRow({
    changePeer,
    navigationHistory,
    backHandler,
}: PeerPresetsRowProps) {
    return (
        <>
            <Box display={"flex"} alignItems={"center"} pl={"10px"}>
                <Typography
                    sx={{
                        color: "#2A7091",
                        marginRight: "5px",
                        fontSize: "10px",
                    }}
                >
                    Main Segments
                </Typography>
                <ul className={styles.button__list}>
                    {presets.map((preset) => {
                        return (
                            <li key={uuidv4()}>
                                <button
                                    onClick={() => changePeer(preset)}
                                    className={styles.button}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {preset.label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
                <Box alignSelf={"flex-end"} flex={1}>
                    <BackNavigation
                        backHandler={backHandler}
                        navigationHistory={navigationHistory}
                    />
                </Box>
            </Box>
        </>
    );
}
