export type HoldingChartsStateType = {
  headline: {
    content: string;
    isEnabled: boolean;
  };
  rate?: { A: boolean; B: boolean; C: boolean; D: boolean };
  sortBy: any;
  top: {
    content: number;
    isEnabled: boolean;
  };
};

type ActionType = {
  type:
    | "SET_HEADELINE_CONTENT"
    | "SET_HEADLINE_ISENABLED"
    | "SET_TOP_CONTENT"
    | "SET_TOP_ISENABLED"
    | "SET_RATE"
    | "SET_SORT_BY";
  payload: any;
};

export const holdinhChartsReducer = (draft: HoldingChartsStateType, action: ActionType) => {
  switch (action.type) {
    case "SET_HEADLINE_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_HEADELINE_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_TOP_ISENABLED":
      draft.top.isEnabled = action.payload;
      break;
    case "SET_TOP_CONTENT":
      draft.top.content = action.payload;
      break;
    case "SET_RATE":
      draft.rate = action.payload;
      break;
    case "SET_SORT_BY":
      draft.sortBy = action.payload;
      break;
  }
};
