import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import {
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { v4 as uuidv4 } from "uuid";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

type HelpContentAdvancedGlossaryProps = {
  staticAssets: string;
  onExpand: () => void;
};

type GlossaryContentProps = {
  children: ReactNode;
  assetsPath: string;
  expandSection: () => void;
};

type GlossarySectionProps = {
  children: ReactNode;
  name: string;
  selectedSection: string | null;
  selectedSubsection: string | null;
  title: string;
  setSection: (section: string) => void;
  setSubsection: (section: string) => void;
};

type GlossarySubsectionProps = {
  name: string;
  selectedSubsection?: string | null;
  setSubsection?: (value: string) => void;
  title: string;
};

const DICT = {
  SECTIONS_NAMES: {
    "Trend Analytics": "trend-analytics",
    "Strategy Builder Analytics": "strategy-builder-analytics",
    "Fundamentals Analytics": "fundamentals-analytics",
  },

  SUBSECTIONS_NAMES: {
    Rating: "rating",
    "Smart Momentum": "smart-momentum",
    Retracement: "retracement",
    "Trend Strength": "trend-strength",
    Duration: "duration",
    Magnitude: "magnitude",
    UPI: "upi",
    "Trend Capture Rating": "trend-capture-rating",
    "Performance since trend": "performance-since-trend",
    "Performance since rated": "performance-since-rated",
    "Annualized performance": "annualized-performance",
    "Average yearly drawdown": "average-yearly-drawdown",
    "Beta (CAPM)": "beta-capm",
    "Cumulative performance": "cumulative-performance",
    "Information ratio": "information-ratio",
    Liquidity: "liquidity",
    "Losing average spread": "losing-average-spread",
    "Losing months": "losing-months",
    "Max consecutive negative months": "max-consecutive-negative-months",
    "Max consecutive positive months": "max-consecutive-positive-months",
    "Max Drawdown": "max-drawdown",
    "Monthly standard deviation": "monthly-standard-deviation",
    "One-way yearly turnover": "one-way-yearly-turnover",
    "% positive months": "percent-positive-months",
    "Sharpe ratio": "sharpe-ratio",
    "Sortino ratio": "sortino-ratio",
    "Sterling ratio": "sterling-ratio",
    "Tracking error": "tracking-error",
    "Treynor ratio": "treynor-ratio",
    "Winning average spread": "winning-average-spread",
    "Winning months": "winning-months",
    "Yearly average performance": "yearly-average-performance",
    "P/E Ratio": "price-earnings-ratio",
    "P/S Ratio": "price-sales-ratio",
    "P/B Ratio": "price-bookvalue-ratio",
    "P/CF Ratio": "price-cashflow-ratio",
    "P/D Ratio": "price-dividends-ratio",
    "3M Sales Growth": "sales-3-months-growth-rate",
    "12M Sales Growth": "sales-12-months-growth-rate",
    "3M Earnings Growth": "earnings-3-months-growth-rate",
    "12M Earnings Growth": "earnings-12-months-growth-rate",
  },

  PDF_FILENAME: {
    rating: "Rating.pdf",
    "smart-momentum": "Smart_Momentum.pdf",
    retracement: "Retracement.pdf",
    "trend-strength": "Trend_Strength.pdf",
    duration: "Duration.pdf",
    magnitude: "Magnitude.pdf",
    upi: "UPI.pdf",
    "trend-capture-rating": "Trend_Capture_Rating.pdf",
    "performance-since-rated": "Performance_since_rated.pdf",
    "performance-since-trend": "Performance_since_trend.pdf",
    "annualized-performance": "Annualized_performance.pdf",
    "average-yearly-drawdown": "Average_yearly_drawdown.pdf",
    "beta-capm": "Beta_(CAPM).pdf",
    "cumulative-performance": "Cumulative_performance.pdf",
    "information-ratio": "Information_ratio.pdf",
    liquidity: "Liquidity.pdf",
    "losing-average-spread": "Losing_average_spread.pdf",
    "losing-months": "Losing_months.pdf",
    "max-consecutive-negative-months": "Max_consecutive_negative_months.pdf",
    "max-consecutive-positive-months": "Max_consecutive_positive_months.pdf",
    "max-drawdown": "Max_Drawdown.pdf",
    "monthly-standard-deviation": "Monthly_standard_deviation.pdf",
    "one-way-yearly-turnover": "One-way_yearly_turnover.pdf",
    "percent-positive-months": "percent_positive_months.pdf",
    "sharpe-ratio": "Sharpe_ratio.pdf",
    "sortino-ratio": "Sortino_ratio.pdf",
    "sterling-ratio": "Sterling_ratio.pdf",
    "tracking-error": "Tracking_error.pdf",
    "treynor-ratio": "Treynor_ratio.pdf",
    "winning-average-spread": "Winning_average_spread.pdf",
    "winning-months": "Winning_months.pdf",
    "yearly-average-performance": "Yearly_average_performance.pdf",
    "price-earnings-ratio": "PE_Ratio.pdf",
    "price-sales-ratio": "PS_Ratio.pdf",
    "price-bookvalue-ratio": "PB_Ratio.pdf",
    "price-cashflow-ratio": "PCF_Ratio.pdf",
    "price-dividends-ratio": "PD_Ratio.pdf",
    "sales-3-months-growth-rate": "3M_Sales_Growth.pdf",
    "sales-12-months-growth-rate": "12M_Sales_Growth.pdf",
    "earnings-3-months-growth-rate": "3M_Earnings_Growth.pdf",
    "earnings-12-months-growth-rate": "12M_Earnings_Growth.pdf",
  },
};

const GlossaryContent = ({
  children,
  assetsPath,
  expandSection,
}: GlossaryContentProps) => {
  const [expanded, setExpanded] = useState(false);

  const pdf = useMemo(
    () => (
      <embed
        style={{ borderRadius: "6px" }}
        width={"100%"}
        height={"100%"}
        type="application/pdf"
        src={`${assetsPath}#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&zoom=100&view=FitH&ts=${Date.now()}`}
        onContextMenu={() => false}
      ></embed>
    ),
    [assetsPath]
  );

  const onExpandClick = useCallback(() => {
    setExpanded(!expanded);
    expandSection();
  }, [expandSection, expanded]);

  return (
    <Box
      height={"100%"}
      className="tPageHelpIndex-contentPage tHelpPage"
      data-id="glossary"
    >
      <Box
        height={"100%"}
        minHeight={0}
        className="tHelpPage-content tHelpPage-content--dual"
      >
        <div className="tHelpPage-content-sidebar">{children}</div>
        <Box className="tHelpPage-content-area" position={"relative"}>
          <button
            className="expand-glossary-btn"
            title="Expand View"
            onClick={onExpandClick}
          >
            {expanded ? (
              <CloseFullscreenIcon sx={{ fontSize: "0.7vw" }} />
            ) : (
              <OpenInFullIcon sx={{ fontSize: "0.7vw" }} />
            )}
          </button>
          {pdf}
        </Box>
      </Box>
    </Box>
  );
};

const GlossarySubsection = ({
  name,
  selectedSubsection,
  setSubsection,
  title,
}: GlossarySubsectionProps) => {
  return (
    <li>
      <Typography
        sx={{
          cursor: "pointer",
          color: selectedSubsection === name ? "#2a7090" : "black",
        }}
        onClick={setSubsection ? () => setSubsection(name) : () => {}}
      >
        {title}
      </Typography>
    </li>
  );
};

const GlossarySection = ({
  children,
  name,
  selectedSection,
  selectedSubsection,
  title,
  setSubsection,
  setSection,
}: GlossarySectionProps) => {
  return (
    <Accordion
      expanded={selectedSection === name}
      onChange={() => setSection(name)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ul className="tHelpSubtree">
          {Children.map(children, (child) => {
            return isValidElement(child)
              ? cloneElement<any>(child, {
                  selectedSubsection,
                  setSubsection,
                })
              : child;
          })}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
};

export function HelpContentAdvancedGlossary({
  staticAssets,
  onExpand,
}: HelpContentAdvancedGlossaryProps) {
  const environment = useEnvironment();

  const product = useMemo(
    () => environment.get("account")?.product?.configuration,
    [environment]
  );

  const helpConfiguraiton = useMemo(() => product.help, [product.help]);
  const glossaryConfig = useMemo(
    () => helpConfiguraiton?.glossary ?? null,
    [helpConfiguraiton?.glossary]
  );

  const sectionsTitles = useMemo(() => {
    const titles = Object.keys(glossaryConfig ?? {});

    if (titles && titles.length) {
      titles.sort((a, b) => {
        const aTitleOrder = glossaryConfig?.[a]?.order ?? 99999;
        const bTitleOrder = glossaryConfig?.[b]?.order ?? -99999;

        return aTitleOrder > bTitleOrder ? 1 : -1;
      });
    }

    return titles;
  }, [glossaryConfig]);

  const [section, setSection] = useState("trend-analytics");
  const [subsection, setSubsection] = useState("rating");

  useEffect(() => {
    switch (section) {
      case "trend-analytics":
        setSubsection("rating");
        break;
      case "strategy-builder-analytics":
        setSubsection("annualized-performance");
        break;
      case "fundamentals-analytics":
        setSubsection("price-earnings-ratio");
    }
  }, [section]);

  const fileName = useMemo(() => {
    return DICT["PDF_FILENAME"][subsection] ?? "";
  }, [subsection]);

  return (
    <GlossaryContent
      expandSection={onExpand}
      assetsPath={`${staticAssets}/glossary/${fileName}`}
    >
      {glossaryConfig != null
        ? sectionsTitles.map((sectionTitle) => {
            return (
              <GlossarySection
                key={uuidv4()}
                name={DICT?.["SECTIONS_NAMES"]?.[sectionTitle] ?? "-"}
                selectedSection={section}
                selectedSubsection={subsection}
                title={sectionTitle}
                setSection={setSection}
                setSubsection={setSubsection}
              >
                {glossaryConfig[sectionTitle]?.field.map((subsectionTitle) => {
                  return (
                    <GlossarySubsection
                      key={uuidv4()}
                      name={
                        DICT?.["SUBSECTIONS_NAMES"]?.[subsectionTitle] ?? "-"
                      }
                      title={subsectionTitle}
                    ></GlossarySubsection>
                  );
                }, [])}
              </GlossarySection>
            );
          })
        : null}
    </GlossaryContent>
  );
}
