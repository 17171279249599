import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { deepClone } from "../../../../../../../deepClone";

type AnalysisInstrumentProps = {
  analysisSecurity: {
    historical: {
      enabled: boolean;
    };
    compare: {
      lastRating: boolean;
    };
  };
  preferenceDispatcher: Function;
  saving: Function;
};

export default function AnalysisInstrument({
  analysisSecurity,
  preferenceDispatcher,
  saving,
}: AnalysisInstrumentProps) {
  console.log("analysisSecurity", analysisSecurity);

  const switchesHandler = useCallback(
    (e) => {
      const temp = deepClone(analysisSecurity);
      switch (e.target.name) {
        case "historical":
          temp.historical.enabled = e.target.checked;
          preferenceDispatcher({
            type: "SET_ANALYSIS_INSTRUMENT",
            payload: temp,
            saving: saving,
          });
          break;

        case "compare":
          temp.compare.lastRating = e.target.checked;
          preferenceDispatcher({
            type: "SET_ANALYSIS_INSTRUMENT",
            payload: temp,
            saving: saving,
          });
          break;
        default:
          return;
      }
    },
    [analysisSecurity, preferenceDispatcher, saving]
  );

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box display={"flex"} flexDirection={"column"}>
          <FormGroup onChange={switchesHandler}>
            <FormControlLabel
              name={"historical"}
              control={
                <Switch
                  size="small"
                  checked={analysisSecurity.historical.enabled}
                />
              }
              label={<Typography>Show historical tab</Typography>}
            />
            <FormControlLabel
              name={"compare"}
              control={
                <Switch
                  size="small"
                  checked={analysisSecurity.compare.lastRating}
                />
              }
              label={<Typography> Enable last rating on comparison</Typography>}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
