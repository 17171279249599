import styles from "./../Alternatives.module.scss";
import { AlternativesCard } from "./AlternativesCard";

type AlternativesBlocksContentProps = {
    list: any[];
    onClickHandler: (security) => any;
    isCardSelectable: boolean;
    activeCard?: string | undefined;
    hasCardButtons?: boolean;
    onClickAdd?: (security) => void;
    hasCounter?: boolean;
    sellList?: any[];
    hasListBorder?: boolean;
    removeFromReplaceList?: Function;
    buttonLabel?: string;
};

export default function AlternativesBlocksContent({
    list,
    onClickHandler,
    isCardSelectable,
    activeCard,
    hasCardButtons = false,
    onClickAdd,
    hasCounter,
    sellList,
    hasListBorder = false,
    removeFromReplaceList,
    buttonLabel,
}: AlternativesBlocksContentProps) {
    const isSecurityInSellList: (symbol: string) => boolean = (symbol) => {
        if (sellList == null) {
            return false;
        } else {
            return sellList.some((security) => security.item.symbol === symbol);
        }
    };

    return (
        <div className={styles.position__list__panel}>
            <ul
                className={`${styles.positions__list} ${
                    hasListBorder ? styles.alternatives__list__with__border : ""
                }`}
            >
                {list?.map((security, index) => (
                    <AlternativesCard
                        removeFromReplaceList={removeFromReplaceList}
                        isInSellList={isSecurityInSellList(security?.symbol)}
                        key={security.symbol}
                        // + 1 because the array index is 0
                        hasCounter={
                            hasCounter ? `${index + 1} of ${list.length}` : null
                        }
                        security={security}
                        hanldeActiveListItem={() => onClickHandler(security)}
                        selected={
                            isCardSelectable
                                ? security.ticker === activeCard
                                : false
                        }
                        hasButtons={hasCardButtons}
                        onClickAdd={onClickAdd}
                        buttonLabel={buttonLabel}
                    />
                ))}
            </ul>
        </div>
    );
}
