import { Box } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import styles from "./Alternatives.module.scss";
import { AlternativesDataManager } from "./AlternativesDataManager";
import { Action, AlternativesState } from "./AlternativesReducer";
import AlternativesBlocksContent from "./widgets/AlternativesBlocksContent";
import Block from "../../../../../../../components/LayoutElements/Block/Block";

type AlternativesPositionsListProps = {
  mainDispatch: (act: Action) => void;
  DM: AlternativesDataManager;
  positions: any[];
  sellList: AlternativesState["swapList"]["sell"];
};

type AlternativesPositionsListContentProps = {
  data?: any;
  dispatch: (act: Action) => void;
  sellList: AlternativesState["swapList"]["sell"];
};

const getInstrumentsData = (
  dataManager: AlternativesDataManager,
  positions
) => {
  /**
   *  If the positions are just loaded in the state avoid to call
   *  API on every mount.
   */
  if (positions) {
    return dataManager.getInstrumentData(positions);
  }
};

export const AlternativesPositionsList = memo(
  ({
    mainDispatch,
    DM,
    positions,
    sellList,
  }: AlternativesPositionsListProps) => {
    return (
      <Block
        className={styles.positions__list__block}
        autoResize={true}
        styleProps={{ flex: 9 }}
        serviceCaller={() => getInstrumentsData(DM, positions)}
      >
        <PortfolioStocksContent sellList={sellList} dispatch={mainDispatch} />
      </Block>
    );
  }
);

const PortfolioStocksContent = ({
  data,
  dispatch,
  sellList,
}: AlternativesPositionsListContentProps) => {
  const [activeCard, setActiveCard] = useState<string>();

  const onRemoveFromReplaceList = useCallback(
    (e, positionToRemove) => {
      e.stopPropagation();

      const itemToRemove = sellList.find(
        (item) => item.item.symbol === positionToRemove.symbol
      )?.["swapId"];

      dispatch({ type: "REMOVE_FROM_SWAP_LIST", payload: itemToRemove });
      dispatch({ type: "SET_FOCUSED_SECURITY", payload: null });
    },
    [dispatch, sellList]
  );

  const onCardClickHandler = useCallback(
    async (security) => {
      const { ticker, type } = security;

      if (ticker !== activeCard) {
        setActiveCard(ticker);
        if (type === "Stock" || type === "ETF") {
          dispatch({
            type: "SET_FOCUSED_SECURITY",
            payload: security,
          });
        } else {
          dispatch({
            type: "SET_FOCUSED_SECURITY",
            payload: security,
          });
        }
      } else {
        setActiveCard("");
        dispatch({ type: "SET_FOCUSED_SECURITY", payload: null });
      }
    },
    [activeCard, dispatch]
  );

  const list = useMemo(() => data, [data]);
  return data.length === 0 ? (
    <Box
      p={2}
      border={"1px solid green"}
      borderRadius={"4px"}
      alignSelf={"flex-start"}
      flex={1}
      textAlign={"center"}
      bgcolor={"#dff0d8"}
      color={"#468847"}
    >
      <strong>There are no securities that can be improved</strong>
    </Box>
  ) : (
    <AlternativesBlocksContent
      sellList={sellList}
      removeFromReplaceList={onRemoveFromReplaceList}
      onClickHandler={onCardClickHandler}
      list={list}
      isCardSelectable={true}
      activeCard={activeCard}
    />
  );
};
