import { useCallback, useEffect, useMemo, useState } from "react";
import { Product } from "../../../storage/SystematicPortfoliosStorage";
import { SystematicProductFormatter } from "../../../../../trendrating/app/formatter/SystematicProduct";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useNavigate } from "react-router-dom";
import { useActionModal } from "../../../utils/useActionModal";
import { Box, Button, Typography } from "@mui/material";

type RebalanceInfoProps = {
  product: Product;
  enableClickableLink: boolean;
};

export function RebalanceInfo({
  product,
  enableClickableLink,
}: RebalanceInfoProps) {
  const [rebalanceInfo, setRebalanceInfo] = useState<any>();
  const { setShowModal, setContent, setModalTitle } = useActionModal();
  const navigate = useNavigate();
  const productId = useMemo(() => {
    if (product) {
      const info = product.info();

      return info.id;
    }

    return null;
  }, [product]);

  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  const goToRebalance = useCallback(() => {
    if (productId) {
      const url = `/app/systematic-portfolios/${productId}/rebalance/`;

      closeModal();
      navigate(url);
    }
  }, [closeModal, navigate, productId]);

  const getRebalanceInfo = useCallback(async () => {
    const productObj = await product.rebalanceInfo();

    setRebalanceInfo(productObj.rebalanceInfo);
  }, [product]);

  useEffect(() => {
    if (product) {
      getRebalanceInfo();
    }

    return () => {
      setRebalanceInfo(undefined);
    };
  }, [getRebalanceInfo, product]);

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useMemo(
    () => new SystematicProductFormatter(appSetup),
    [appSetup]
  );

  const innerHTML = useMemo(
    () => (rebalanceInfo ? formatter.rebalanceInfo(rebalanceInfo) : ""),
    [formatter, rebalanceInfo]
  );

  const showRebalance = useCallback(() => {
    const productInfo = product.info();
    setModalTitle(`Rebalance ${productInfo.name}`);
    setContent(
      <Box>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Typography>
            The {productInfo.name} will rebalance the underlying portfolio{" "}
            <strong>{productInfo.historicalPortfolioName}</strong>.
          </Typography>

          <Typography sx={{ color: "red" }}>
            Before rebalancing, please verify that the portfolio holdings and
            weights are up-to-date. To update the portfolio, import it into the
            historical portfolios section.
          </Typography>
        </Box>

        <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
          <Button variant="contained" onClick={goToRebalance}>
            Rebalance
          </Button>
          <Button variant="tr_button_cancel" onClick={closeModal}>
            Cancel
          </Button>
        </Box>
      </Box>
    );
    setShowModal(true);
  }, [
    closeModal,
    goToRebalance,
    product,
    setContent,
    setModalTitle,
    setShowModal,
  ]);

  return rebalanceInfo ? (
    <div className="wSystematicProductRebalanceInformation">
      {(() => {
        switch (rebalanceInfo.status) {
          default:
            return <></>;

          case "overdue": {
            if (enableClickableLink) {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href={"#"}
                  onClick={showRebalance}
                  className="wSystematicProductRebalanceInformation-rebalance wSystematicProductRebalanceInformation-rebalance-isOverdue"
                >
                  {innerHTML}
                </a>
              );
            } else {
              return (
                <p className="wSystematicProductRebalanceInformation-rebalance wSystematicProductRebalanceInformation-rebalance-isOverdue">
                  {innerHTML}
                </p>
              );
            }
          }

          case "rebalance": {
            if (enableClickableLink) {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href={"#"}
                  onClick={showRebalance}
                  className="wSystematicProductRebalanceInformation-rebalance  wSystematicProductRebalanceInformation-rebalance-isToday"
                >
                  {innerHTML}
                </a>
              );
            } else {
              return (
                <p className="wSystematicProductRebalanceInformation-rebalance  wSystematicProductRebalanceInformation-rebalance-isToday">
                  {innerHTML}
                </p>
              );
            }
          }

          case "rebalanced": {
            if (rebalanceInfo["next"] - rebalanceInfo["today"] !== 0) {
              return (
                <p
                  className={
                    "wSystematicProductRebalanceInformation-rebalance wSystematicProductRebalanceInformation-rebalance-rebalance-isNext"
                  }
                >
                  {innerHTML}
                </p>
              );
            }

            break;
          }
        }
      })()}
    </div>
  ) : (
    <></>
  );
}
