import { Box, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type CellTooltipProps = {
  children: JSX.Element | JSX.Element[];
  getData: Function;
  placement?: "top" | "right" | "bottom" | "left";
  dispersionAnalytic: string;
};
type TooltipContentProps = {
  data: any;
  dispersionAnalytic: string;
};

const formatPercentage = (value, format) => {
  var percentage = format.custom("number", {
    options: {
      decimals: value === 0 || value === 1 ? 0 : 2,
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    },
    output: "HTML",
    value: value,
    valueHelper: null,
  });
  percentage =
    /00%$/gi.test(percentage) === true
      ? percentage.replace(".00%", "%")
      : percentage;

  return percentage;
};

const formatTcr = (value, format) => {
  return format.tcr(value, "HTML");
};

export function CellTooltip({
  children,
  getData,
  placement,
  dispersionAnalytic,
}: CellTooltipProps) {
  const [data, setData] = useState();
  const getTooltipData = useCallback(async () => {
    const response = await getData();

    setData(response);
  }, [getData]);

  return (
    <Tooltip
      onShow={() => getTooltipData()}
      content={
        <TooltipContent data={data} dispersionAnalytic={dispersionAnalytic} />
      }
      tooltipIcon={false}
      placement={placement}
      interactive={true}
    >
      {children}
    </Tooltip>
  );
}

const TooltipContent = ({ data, dispersionAnalytic }: TooltipContentProps) => {
  const { t } = useTranslation();
  const format = useFormatter();
  const dispersionTimeframeText = useMemo(() => {
    switch (dispersionAnalytic) {
      case "pq":
      default:
        return "last quarter";

      case "ps":
        return "last 6 months";

      case "py":
        return "last year";
    }
  }, [dispersionAnalytic]);

  return data ? (
    <div className="tPeerTooltipContent">
      <div className="tPeerTooltipContent-layout">
        <div className="tPeerTooltipContent-info">
          <div
            className="tPeerTooltipContent-tcr"
            dangerouslySetInnerHTML={{
              __html: format.tcr(data?.["tcr"], "HTML"),
            }}
          ></div>
        </div>
        <div className="tPeerTooltipContent-info">
          <div
            className="tPeerTooltipContent-box tPeerTooltipContent-name"
            dangerouslySetInnerHTML={{
              __html:
                data?.["name"] == null
                  ? "-"
                  : data["name"].replace(",", "<br />"),
            }}
          ></div>

          <div>
            {t("Instruments")}: <span>{data?.["cardinality"]}</span>
          </div>

          <div className="tPeerTooltipContent-box">
            {t("Mkt_Cap")}:{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: format.custom("numberBig", {
                  options: {
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                  },
                  output: "HTML",
                  value: data?.["marketcap"],
                  valueHelper: null,
                }),
              }}
            ></span>
          </div>

          {data?.dispersion?.[dispersionAnalytic]?.top != null ||
          data?.dispersion?.[dispersionAnalytic]?.bottom != null ? (
            <Box mb={2}>
              <Typography>
                <strong>
                  Performance Dispersion ({dispersionTimeframeText})
                </strong>
              </Typography>
              <Box>
                {data?.dispersion?.[dispersionAnalytic]?.top && (
                  <Box>
                    <Typography>
                      Top 25%:{" "}
                      {formatPercentage(
                        data?.dispersion?.[dispersionAnalytic]?.top,
                        format
                      )}
                    </Typography>
                  </Box>
                )}
                {data?.dispersion?.[dispersionAnalytic]?.bottom && (
                  <Box>
                    <Typography>
                      Bottom 25%:{" "}
                      {formatPercentage(
                        data?.dispersion?.[dispersionAnalytic]?.bottom,
                        format
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <TimeFrameWidget data={data} />
          {/* <RatingCardinality data={data} /> */}

          <div className="tPeerTooltipContent-box">
            <div className="tPeerTooltipContent-upgrade">
              <i className="tPeerTooltipContent-alertTriangle i-triangle-up"></i>{" "}
              {t("Upgrades")}:{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: formatPercentage(
                    data?.["instrumentsAlert"]?.["upgrades"],
                    format
                  ),
                }}
              ></span>{" "}
              | {t("Instruments")}:{" "}
              <span>{data?.["instrumentsAlert"]?.["upgradesCardinality"]}</span>
            </div>
            <div className="tPeerTooltipContent-downgrade">
              <i className="tPeerTooltipContent-alertTriangle i-triangle-down"></i>{" "}
              {t("Downgrades")}:{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: formatPercentage(
                    data?.["instrumentsAlert"]?.["downgrades"],
                    format
                  ),
                }}
              ></span>{" "}
              | {t("Instruments")}:{" "}
              <span>
                {data?.["instrumentsAlert"]?.["downgradesCardinality"]}
              </span>
            </div>
          </div>

          {data?.["changeReversal"] && <ChangeReversalWidget data={data} />}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const TimeFrameWidget = ({ data }) => {
  const { t } = useTranslation();
  const value = useMemo(() => {
    switch (data?.["timeframe"]) {
      case "lastMonth": {
        return t("Last_month_changes");
      }
      case "lastWeek": {
        return t("Last_week_changes");
      }
      case "today":
      default: {
        return "";
      }
    }
  }, [data, t]);

  return <p className="tPeerTooltipContent-timeframe">{value}</p>;
};

// const RatingCardinality = ({ data }) => {
//   const format = useFormatter();

//   const ab = useMemo(() => data?.["instrumentsRating"]?.["ab"], [data]);
//   const child = useMemo(() => {
//     switch (data["timeframe"]) {
//       case "lastMonth":
//       case "lastWeek": {
//         return (
//           (ab > 0
//             ? `<i class="i-arrow-u"></i>`
//             : ab < 0
//             ? `<i className="i-arrow-d"></i>`
//             : "") + `<span>${formatPercentage(ab, format)}</span>`
//         );
//       }
//       case "today":
//       default: {
//         return formatPercentage(ab, format);
//       }
//     }
//   }, [ab, data, format]);

//   return (
//     <div className="tPeerTooltipContent-box">
//       <div>
//         <strong className="format-rate format-rate--A">A</strong> and{" "}
//         <strong className="format-rate format-rate--B">B</strong>:{" "}
//         <span dangerouslySetInnerHTML={{ __html: child }}></span>
//       </div>
//       {data?.["instrumentsRating"]?.["cd"] != null ? (
//         <div>
//           <strong className="format-rate format-rate--C">C</strong> and{" "}
//           <strong className="format-rate format-rate--D">D</strong>:{" "}
//           <span
//             dangerouslySetInnerHTML={{
//               __html: formatPercentage(
//                 data?.["instrumentsRating"]?.["cd"],
//                 format
//               ),
//             }}
//           ></span>
//         </div>
//       ) : null}
//     </div>
//   );
// };

const ChangeReversalWidget = ({ data }) => {
  const { t } = useTranslation();
  const from = useMemo(() => data?.["changeReversal"]?.["from"], [data]);
  const to = useMemo(() => data?.["changeReversal"]?.["to"], [data]);
  const format = useFormatter();

  return (
    <div>
      {from !== to && (
        <>
          <span>{from > to ? t("Downgraded_from") : t("Upgraded_from")}</span>{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: formatTcr(from, format),
            }}
          ></span>{" "}
          {t("to")}{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: formatTcr(to, format),
            }}
          ></span>{" "}
        </>
      )}
    </div>
  );
};
