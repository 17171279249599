import Tippy from "@tippyjs/react";
import {
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Instruments } from "../../api/compute/Instruments";
import { Environment } from "../../Environment";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { widgetsConfiguration } from "../../js/app/widgets/widgetsConfiguration";
import { Formatter } from "../../trendrating/utils/Formatter";
import { Instrument } from "../../types/Api";
import { config } from "./../../js/app/config-ts";
import CreateBasketDialog from "./CreateListDialog";
import styles from "./SecurityTooltip.module.scss";
import { Lists } from "../../api/compute/Lists";
import { Box, MenuItem, Typography } from "@mui/material";
import { useBroadcast } from "../../hooks/useBroadcast";
import { Portal } from "../Portal/Portal";

const { context__menu } = styles;

type SecurityTooltipCoreProps = {
  symbol: string;
  environment: Environment;
  chartButtonHandler: (security) => void;
  enableAddToAction?: boolean;
  isIconVisible?: boolean;
  children?: JSX.Element;
  setSecurityData: (value) => void;
  additionalButtons?: { label: string; callback: Function }[];
};

type TooltipContentProps = {
  isLoading: boolean;
  security: any;
  environment: Environment;
  showContextMenu: boolean;
  setShowContextMenu: (val: boolean) => void;
  chartButtonHandler: Function;
  tooltipRef: any;
  enableAddToAction: boolean;
  error: boolean;
  additionalButtons?: { label: string; callback: Function }[];
};

export const getSymbols = (securities, collectionType) => {
  let weight = 0;
  // if (collectionType && collectionType === "PORTFOLIO") {
  //   weight = 1 / securities.length;
  // }

  let positions: any = [];
  let security: any = null;
  for (let i = 0, length = securities.length; i < length; i++) {
    security = securities[i];
    positions.push({
      symbol: security.symbol,
      weight: weight,
    });
  }

  return positions;
};

export const newGetSymbols = (
  securities: any[],
  collectionType: "PORTFOLIO" | "BASKET",
  isAddToExisting: boolean
) => {
  const isPortfolio = collectionType === "PORTFOLIO";
  let weight = 0;
  if (!isAddToExisting && isPortfolio) {
    weight = 1 / securities.length;
  }
  let positions: any = [];
  let security: any = null;
  for (let i = 0, length = securities.length; i < length; i++) {
    security = securities[i];
    positions.push({
      symbol: security.symbol,
      weight: weight,
    });
  }

  return positions;
};

const getInstrumentData = async (apiInstrument, symbol) => {
  const properties = {
    properties: [
      {
        date: null,
        property: "type",
      },
    ],
    symbols: [symbol],
    type: "security",
  };

  const response = await apiInstrument.fetch(properties);
  const propertiesByType = getSecurityProperiesByType(response);

  return await apiInstrument.fetch({
    properties: propertiesByType,
    symbols: [symbol],
    type: "security",
  });
};

export const getInfoNodeData = (
  rawTaxonomies,
  security,
  environment,
  fields
) => {
  if (!security) {
    return "";
  }
  const taxonomyCountry = rawTaxonomies["countries"];
  const taxonomyMarket = rawTaxonomies["markets"];
  const taxonomySector = rawTaxonomies["sectors"];

  let market = taxonomyMarket.find((node) => node.id === security.country);
  if (!market) {
    market = { id: security.country, name: security.country };
  }
  let country = taxonomyCountry.find((node) => node.id === security.domicile);
  if (!country) {
    country = {
      id: security.domicile,
      name: security.domicile,
    };
  }
  let sector = taxonomySector.find((node) => node.id === security.icb);

  let innerHTML = "";
  let useMarketcapAsLabel = true;
  switch (security.type) {
    case "Stock": {
      innerHTML =
        (market.id !== country.id
          ? market.name + " (" + country.name + ")"
          : market.name) + "<br/>";

      var info = getIntervalLabel(
        security.marketcap,
        useMarketcapAsLabel,
        environment
      );
      if (info !== "") {
        innerHTML += info;
      }
      if (info && sector) {
        innerHTML += " | ";
      }
      if (sector) {
        innerHTML += sector.name;
      }

      break;
    }
    case "ETF": {
      innerHTML = market.name;

      market = rawTaxonomies[fields["ETF"]["etfgeo"]][security?.etfgeo];
      sector = getETFClass(security?.etfclass, environment);

      innerHTML +=
        "<br/>" +
        (sector.length === 1
          ? sector?.[0]?.name
          : sector?.[sector.length - 1]?.name + " " + sector?.[0]?.name) +
        " : " +
        market?.name;
      break;
    }
    default: {
      innerHTML = security.type + " | " + market?.name;
    }
  }

  return innerHTML;
};

const getETFClass = (etfclass, environment: Environment) => {
  var taxonomies = environment.get("rawTaxonomies");
  var taxonomy = taxonomies.ETFclassification;
  var data: any = [];

  var node: any = taxonomy[etfclass];
  if (node?.parent != null) {
    data.push(node);
    return data.concat(getETFClass(node?.parent, environment));
  }

  return data;
};

const getSecurityProperiesByType = (response) => {
  const securityType = response?.["data"]?.[0]?.["type"]?.toLowerCase() ?? null;
  let properties;

  const config = widgetsConfiguration["widgets/security/Tooltip"];

  if (securityType) {
    switch (securityType) {
      case "etf":
      case "stock": {
        properties = config?.["properties_" + securityType];
        break;
      }
      default: {
        properties = config?.["properties"];
      }
    }

    return properties;
  }

  return null;
};

export const getIntervalLabel = (
  marketcap,
  useMarketcapAsLabel,
  environment: Environment
) => {
  const intervals: any = [
    {
      left: null,
      right: 300000000,
      checked: false,
      innerHTML: "Micro Cap",
      label: "Micro Cap ($50M-$300M)",
      node: null,
    },
    {
      left: 300000000,
      right: 2000000000,
      checked: false,
      innerHTML: "Small Cap",
      label: "Small Cap ($300M-$2B)",
      node: null,
    },
    {
      left: 2000000000,
      right: 10000000000,
      checked: false,
      innerHTML: "Mid Cap",
      label: "Mid Cap ($2B-$10B)",
      node: null,
    },
    {
      left: 10000000000,
      right: 200000000000,
      checked: false,
      innerHTML: "Large Cap",
      label: "Large Cap ($10B-$200B)",
      node: null,
    },
    {
      left: 200000000000,
      right: null,
      checked: false,
      innerHTML: "Mega Cap",
      label: "Mega Cap (over $200B)",
      node: null,
    },
  ];
  var interval: any = null;
  var label: any = null;

  if (!marketcap) {
    return "";
  }

  if (marketcap >= intervals[0].left && marketcap < intervals[0].right) {
    interval = intervals[0];
  } else if (marketcap >= intervals[1].left && marketcap < intervals[1].right) {
    interval = intervals[1];
  } else if (marketcap >= intervals[2].left && marketcap < intervals[2].right) {
    interval = intervals[2];
  } else if (marketcap >= intervals[3].left && marketcap < intervals[3].right) {
    interval = intervals[3];
  } else if (marketcap >= intervals[4].left) {
    interval = intervals[4];
  }

  const appEnvironment = environment.get("setup");
  const format = new Formatter(appEnvironment);
  if (!interval) {
    interval = {
      innerHTML: format.html(
        "marketcap",
        "marketcap",
        marketcap,
        null,
        "instrument"
      ),
    };
  }

  if (useMarketcapAsLabel) {
    label =
      "Mkt. cap.: " +
      format.html("marketcap", "marketcap", marketcap, null, "instrument");
  } else {
    label = interval.label;
  }

  return '<span title="' + label + '">' + interval.innerHTML + "</span>";
};

const closeTooltip = (ref) => {
  const instance = ref?.current?._tippy ?? null;
  if (instance) {
    instance?.hide();
  }
};

const onChartClickHandler = (security, handler, ref) => {
  //************************** USAGE *****************************
  var usage = window.App.usage;
  var info = {
    action: "CHART",
    actionParam: security.symbol,
    function: "MESSAGE",
  };
  usage.record(info);
  //************************** USAGE *****************************

  closeTooltip(ref);
  handler(security);
};

// const setHelper = async (params, callback, apiPreference: Preferences) => {
//   var data: any = {
//     name: "global",
//     preferences: {},
//     version: 1,
//   };
//   if (window.App.user.preferences) {
//     // ID
//     data.id = window.App.user.preferences.id;
//     // name
//     data.name = window.App.user.preferences.name;
//     // version
//     data.version = window.App.user.preferences.version;

//     if (window.App.user.preferences.preferences) {
//       data.preferences = window.App.user.preferences.preferences;
//     }
//   }

//   for (let i = 0, length = params.length; i < length; i++) {
//     data.preferences[params[i].key] = params[i].value;
//   }

//   if (callback) {
//     await apiPreference.save(data);
//     callback(data);
//   } else {
//     // return promise
//     return apiPreference.save(data);
//   }
// };

// const _post = (params, callback, callbackError, response, apiPreference) => {
//   var key = "favorites";

//   var favorite: any;
//   var favorites: any = {
//     data: [],
//     index: {},
//   };
//   var index;
//   var security: any;
//   var size = 10;
//   var symbols = params.symbols;
//   var symbol;
//   var timestamp: any;

//   if (response && key in response.data && response.data[key] != null) {
//     favorites = response.data[key];
//   }

//   for (let i = 0; i < symbols.length; i++) {
//     symbol = symbols[i];
//     timestamp = new Date().getTime();

//     if (symbol in favorites["index"]) {
//       // update timestamp
//       index = favorites["index"][symbol];
//       favorite = favorites["data"][index];

//       favorite["timestamp"] = timestamp;
//     } else {
//       //
//       if (favorites.data.length >= size) {
//         makeSpace(favorites, symbols);
//       }

//       // insert new favorite
//       favorite = {
//         symbol: symbol,
//         timestamp: timestamp,
//       };

//       favorites["data"].push(favorite);
//     }
//   }
//   // sort
//   favorites["data"].sort(function (a, b) {
//     if (a.timestamp > b.timestamp) {
//       return -1;
//     }
//     if (a.timestamp < b.timestamp) {
//       return 1;
//     }
//     return 0;
//   });
//   // build index
//   for (let i = 0; i < favorites["data"].length; i++) {
//     security = favorites["data"][i];
//     favorites["index"][security.symbol] = i;
//   }

//   var paramsTarget = {
//     key: key,
//     value: favorites,
//   };

//   setHelper([paramsTarget], callback, apiPreference);
// };

// const makeSpace = (favorites, symbols) => {
//   // favorites are sorted by timestamp: newest -> oldest
//   // size check: if the size limit is reached, the last
//   // elements (oldest) are removed

//   var size = 10;
//   var overflow = favorites.data.length - size;
//   var offset = favorites.data.length - overflow - symbols.length;

//   favorites.data.splice(offset, symbols.length + overflow);

//   // rebuild index
//   favorites.index = {};
//   for (let i = 0, length = favorites.data.length; i < length; i++) {
//     const symbol = favorites.data[i].symbol;
//     favorites.index[symbol] = i;
//   }
// };

// const setPreferences = async (
//   security: Instrument,
//   environment: Environment
// ) => {
//   const handleResponse = (response) => {
//     if (response?.data?.favorites) {
//       window.App.user.preferences.preferences.favorites =
//         response.data.favorites;
//     }
//   };

//   const symbols =
//     security != null && "symbol" in security ? [security.symbol] : [];

//   if (symbols.length === 0) {
//     console.warn("Favorite: symbols not specified.", security);
//     return;
//   }

//   const appEnv = environment.get("setup");
//   const apiPreference = new Preferences(appEnv);
//   const params = { symbols };
//   const callback: any = (response) => {
//     const data = {};
//     for (const [key, value] of Object.entries(response)) {
//       if (key === "preferences") {
//         data["data"] = value;
//       } else {
//         data[key] = value;
//       }
//     }

//     _post(params, handleResponse, null, data, apiPreference);
//   };
//   const response = await apiPreference.get();
//   callback(response);
// };

// const onSecurityAnalysisHandler = async (security, environment) => {
//   //************************** USAGE *****************************
//   var usage = window.App.usage;
//   var info = {
//     action: "LANDING",
//     actionParam: security.symbol,
//     function: "SECURITY_ANALYSIS",
//   };
//   usage.record(info);
//   //************************** USAGE *****************************

//   await setPreferences(security, environment);
//   window.location.href = `/app/analysis/instrument/${security.symbol}/`;
// };

// const handleContextMenu = (event, openMenu) => {
//   event.preventDefault();
//   openMenu(true);
// };

export default function SecurityTooltipCore({
  symbol,
  chartButtonHandler,
  environment,
  enableAddToAction = false,
  isIconVisible = true,
  children,
  setSecurityData,
  additionalButtons = [],
}: SecurityTooltipCoreProps) {
  const appEnvironment = environment.get("setup");
  const apiInstrument = useMemo(
    () => new Instruments(appEnvironment),
    [appEnvironment]
  );

  const [security, setSecurity] = useState<Instrument | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const tooltipRef = useRef(null);

  const tooltipIconStyle = {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Tippy
        zIndex={999999}
        ref={tooltipRef}
        trigger="click"
        onTrigger={(instance, event) => {
          event.stopPropagation();
        }}
        onUntrigger={(instance, event) => {
          event.stopPropagation();
        }}
        onShow={() => {
          const getData = async () => {
            const response = await getInstrumentData(apiInstrument, symbol);
            setSecurity(response?.["data"]?.[0] ?? null);
            setSecurityData(response?.["data"]?.[0] ?? null);
            setIsLoading(false);
          };
          try {
            //Reset error state on call
            setError(false);
            setIsLoading(true);
            getData();
          } catch (error) {
            console.error(error);
            setError(true);
            setIsLoading(false);
          }
        }}
        popperOptions={{
          strategy: "fixed",
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom", "top"],
              },
            },
          ],
        }}
        maxWidth={500}
        placement="right"
        interactive={true}
        theme="security-tooltip"
        appendTo={() => document.body}
        onHidden={() => setShowContextMenu(false)}
        content={
          <TooltipContent
            error={error}
            isLoading={isLoading}
            security={security}
            environment={environment}
            showContextMenu={showContextMenu}
            setShowContextMenu={setShowContextMenu}
            chartButtonHandler={chartButtonHandler}
            tooltipRef={tooltipRef}
            enableAddToAction={enableAddToAction}
            additionalButtons={additionalButtons}
          />
        }
      >
        {children == null ? (
          <span
            onClick={(e) => e.stopPropagation()}
            className={"alerts-info-tooltip-icon i-info"}
            style={
              !isIconVisible
                ? { ...tooltipIconStyle, visibility: "hidden" }
                : tooltipIconStyle
            }
          ></span>
        ) : (
          children
        )}
      </Tippy>
    </>
  );
}

export const TooltipContent = ({
  isLoading,
  security,
  error,
  environment,
  showContextMenu,
  setShowContextMenu,
  chartButtonHandler,
  tooltipRef,
  enableAddToAction,
  additionalButtons = [],
}: TooltipContentProps) => {
  const appEnvironment = environment.get("setup");
  const format = new Formatter(appEnvironment);
  const rawTaxonomies = environment.get("rawTaxonomies");

  const taxonFields = environment.get("taxonomyFields");
  const innerHtmlInfo = getInfoNodeData(
    rawTaxonomies,
    security,
    environment,
    taxonFields
  );
  // const contextAncorRef = useRef<HTMLLIElement>(null);
  // const contextMenuRef = useRef<HTMLUListElement>(null);

  const price = security
    ? format.html("vc", "price", security?.["vc"], security, security?.["type"])
    : "";
  const priceDate = security
    ? format.html(
        "dc",
        "closure_date_iso",
        security?.["dc"],
        security,
        security?.["type"]
      )
    : "";
  const newHl = security
    ? format.html(
        "lhl",
        "high_low",
        security?.["lhl"],
        security,
        security?.["type"]
      )
    : "";
  const rate = security
    ? format.html(
        "rc",
        "rating",
        security?.["rc"],
        security,
        security?.["type"]
      )
    : "";
  const rateDate = security
    ? format.html(
        "dr",
        "rated_on_iso",
        security["dr"],
        security,
        security["type"]
      )
    : "";

  return !isLoading ? (
    security != null ? (
      <div className="securty-tooltip">
        <div className="securty-tooltip__wrapper">
          <div>
            <h1
              className="securty-tooltip__ticker"
              data-dojo-attach-point="_ticker"
            >
              {security?.ticker ?? ""}
            </h1>
            <h1
              className="securty-tooltip__name"
              data-dojo-attach-point="_name"
            >
              {security?.name ?? ""}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: innerHtmlInfo,
              }}
              className="securty-tooltip__info"
              data-dojo-attach-point="_info"
            ></p>
          </div>
          <div className="layout-grid">
            <div className="layout-grid__col layout-grid__col--50">
              <div
                className="securty-tooltip__price"
                data-dojo-attach-point="_price"
                dangerouslySetInnerHTML={{
                  __html: price,
                }}
              ></div>
              <div
                className="securty-tooltip__price-date"
                data-dojo-attach-point="_priceDate"
                dangerouslySetInnerHTML={{
                  __html: priceDate,
                }}
              ></div>
              <div
                className="securty-tooltip__new-HL"
                data-dojo-attach-point="_newHL"
                dangerouslySetInnerHTML={{
                  __html: newHl,
                }}
              ></div>
            </div>
            <div className="layout-grid__col layout-grid__col--50">
              <div
                className="securty-tooltip__rate"
                data-dojo-attach-point="_rate"
                dangerouslySetInnerHTML={{
                  __html: rate,
                }}
              ></div>
              <div
                className="securty-tooltip__rate-date"
                data-dojo-attach-point="_rateDate"
                dangerouslySetInnerHTML={{
                  __html: rateDate,
                }}
              ></div>
            </div>
          </div>
        </div>
        <ul
          className="menu menu--actions menu--horizontal"
          data-dojo-attach-point="_actionMenu"
        >
          <li
            style={{ cursor: "pointer" }}
            className="menu__item"
            data-dojo-attach-point="_actionChart"
            onClick={() =>
              onChartClickHandler(security, chartButtonHandler, tooltipRef)
            }
            role="button"
          >
            Chart
          </li>
          {additionalButtons.map((btn, index) => (
            <li
              key={index}
              style={{ cursor: "pointer" }}
              className="menu__item"
              data-dojo-attach-point="_actionChart"
              onClick={() => btn.callback()}
              role="button"
            >
              {btn.label}
            </li>
          ))}
          {/* {analysisInstrumentSetup["enabled"] === true ? (
            <>
              <li
                ref={contextAncorRef}
                title="Right click for more options"
                className="menu__item"
                data-dojo-attach-point="_actionAnalysis"
                onClick={() => onSecurityAnalysisHandler(security, environment)}
                onContextMenu={(e) => handleContextMenu(e, setShowContextMenu)}
              >
                Security analysis
              </li>
              {showContextMenu && (
                <ContextMenu ref={contextMenuRef} targetRef={contextAncorRef}>
                  <li
                    onClick={() =>
                      window
                        .open(
                          `/app/analysis/instrument/${security?.symbol}/`,
                          "_blank"
                        )
                        ?.focus()
                    }
                  >
                    Open in a new Tab
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href = `/app/analysis/instrument/${security?.symbol}/`)
                    }
                  >
                    Open in current Tab
                  </li>
                  <li
                    onClick={() =>
                      window.open(
                        `/app/analysis/instrument/${security?.symbol}/`,
                        "_blank",
                        "location=yes,scrollbars=yes,status=yes"
                      )
                    }
                  >
                    Open in a new Window
                  </li>
                </ContextMenu>
              )}
            </>
          ) : null} */}
          {enableAddToAction && (
            <AddToMenuAction security={security} environment={environment} />
          )}
        </ul>
      </div>
    ) : (
      <p style={{ textAlign: "center", padding: 15 }}>
        {error
          ? "Something goes wrong loading security info, please retry later"
          : "no data was found"}
      </p>
    )
  ) : (
    <>
      <p style={{ textAlign: "center", padding: 15 }}>Data are loading...</p>
    </>
  );
};

const ContextMenu = forwardRef<
  HTMLUListElement,
  {
    targetRef: RefObject<HTMLElement>;
    offset?: number;
    children: ReactNode | ReactNode[];
  }
>(({ targetRef, offset, children }, ref) => {
  const refList = ref != null && typeof ref !== "function" ? ref : null;

  useEffect(() => {
    const target = targetRef?.current ?? null;
    const menu = refList?.current;
    if (target && menu) {
      const targetDims = target.getBoundingClientRect();
      const menuDims = menu.getBoundingClientRect();
      const { left, top, width } = targetDims;
      const { height: menuHeight, width: menuWidth } = menuDims;
      const { innerHeight, innerWidth } = window;

      let flipY = false;
      let flipX = false;
      let updatedPositionY;
      let updatedPositionX;

      if (top + menuHeight > innerHeight) {
        flipY = true;

        //Has to be flipped top bacuse exceed bottom
        updatedPositionY = top - menuHeight;
      }

      const whiteSpace = offset ?? 0;

      if (left + width + whiteSpace > innerWidth) {
        flipX = true;

        //Has to be flipped left because exceed right
        updatedPositionX = left - whiteSpace - menuWidth;
      }

      menu.style.top = `${flipY ? updatedPositionY : top}px`;
      menu.style.left = `${
        flipX ? updatedPositionX : left + width + whiteSpace
      }px`;
    }
  }, [targetRef, offset, refList]);

  return (
    <Portal>
      <ul className={context__menu} ref={ref}>
        {children}
      </ul>
    </Portal>
  );
});

const AddToMenuAction = ({ environment, security }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showNestedMenu, setShowNestedMenu] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const addToListRef = useRef<HTMLUListElement>(null);
  const nestedListRef = useRef<HTMLUListElement>(null);

  const [list, setList] = useState<any>([]);
  const buttonRef = useRef<HTMLLIElement>(null);
  const nestedListTargetRef = useRef<HTMLLIElement>(null);
  const apiList = useMemo(() => {
    return new Lists(environment.get("setup"));
  }, [environment]);

  const getUserCollection = useCallback(async (apiList) => {
    const resp = await apiList._getFilter();
    let data = await apiList.portfolioFetch(resp, ["name", "type"]);
    data = data.filter((item) => item.type === "BASKET");
    data.sort((a, b) => {
      // Access the values of the specified property for comparison
      const aValue = a["name"].toLowerCase(); // Convert to lowercase for case-insensitive sorting
      const bValue = b["name"].toLowerCase();
      // Compare the values
      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });

    setList(data);
  }, []);
  useEffect(() => {
    getUserCollection(apiList);
  }, [apiList, getUserCollection]);

  const addTo = async (securities, collection, environment) => {
    var newPositions = getSymbols(securities, null);
    const apiLists = environment.get("http")["lists"];

    const response = await apiLists.get(collection["id"]);

    await addToHelper(newPositions, response, apiLists);
  };

  const { broadcast } = useBroadcast();

  const addToHelper = async (newPositions, list, apiList) => {
    list["positions"] = list["positions"].concat(newPositions);

    await apiList.update(list);

    //Use the non updated list to get name and type used to be sended to the topic
    const oldList = list;

    const message = {
      from: "Routes",
      content: {
        type: "success",
        text:
          "Item/s saved in " +
          oldList.type.toLowerCase() +
          " <strong>" +
          oldList.name +
          "</strong>.",
        onAddToNewBasket,
      },
    };

    broadcast(config["channels"]["feedback"]["input"], message);

    //********************** USAGE *************************
    var usage = window.App.usage;
    var info = {
      action: "ADD_TO",
      actionParam: list["id"],
      function: "MESSAGE",
    };
    usage.record(info);
    //********************** USAGE *************************
  };

  const onAddToExisting = (collection, environment) => {
    setShowMenu(false);
    setShowNestedMenu(false);
    addTo([security ?? {}], collection, environment);
  };

  const clickOutsideHandler = (event) => {
    if (
      !addToListRef?.current?.contains(event.target) &&
      !nestedListRef?.current?.contains(event.target)
    ) {
      setShowMenu(false);
      setShowNestedMenu(false);
    }
  };

  useDetectOutsideClick(clickOutsideHandler);

  const onAddToNewBasket = () => {
    setShowMenu(false);
    setShowNestedMenu(false);
    // setShowModal(true);
  };

  const tippyRef = useRef<any>(null);
  return (
    <>
      {/* <CreateBasketDialog
        security={security}
        type={"BASKET"}
        showModal={showModal}
        setShowModal={setShowModal}
        environment={environment}
      /> */}
      {/* <Feedback channelInput={config["channels"]["feedback"]["input"]} /> */}
      <Tippy
        interactive={true}
        allowHTML
        theme="security-tooltip"
        placement="right"
        trigger="click"
        content={
          <Box display={"flex"} flexDirection={"column"}>
            <Tippy
              interactive={true}
              allowHTML
              theme="security-tooltip"
              placement="right"
              trigger="click"
              content={
                <Box
                  display={"flex"}
                  overflow={"auto"}
                  maxHeight={"500px"}
                  flexDirection={"column"}
                >
                  {list.map((basket) => (
                    <MenuItem
                      sx={{
                        display: "flex !important",
                        justifyContent: "start !important",
                      }}
                      onClick={() => onAddToExisting(basket, environment)}
                      key={`${basket.name}__${basket.id}`}
                    >
                      <Typography>{basket?.name ?? ""}</Typography>
                    </MenuItem>
                  ))}
                </Box>
              }
            >
              <MenuItem
                sx={{
                  display: "flex !important",
                  justifyContent: "start !important",
                }}
              >
                <Typography>Basket</Typography>
              </MenuItem>
            </Tippy>

            <Tippy
              ref={tippyRef}
              interactive={true}
              allowHTML
              theme="security-tooltip"
              placement="right"
              trigger="click"
              content={
                <CreateBasketDialog
                  key={Date.now().toLocaleString()}
                  security={security}
                  type={"BASKET"}
                  isForTippy
                  environment={environment}
                  showModal={false}
                  setShowModal={() => {}}
                  onCreate={() => {
                    if (tippyRef?.current) {
                      tippyRef?.current?._tippy.hide();
                    }
                    getUserCollection(apiList);
                  }}
                  onCancel={() => {
                    if (tippyRef?.current) {
                      tippyRef?.current?._tippy.hide();
                    }
                  }}
                />
              }
            >
              <MenuItem onClick={onAddToNewBasket}>
                <Typography>New basket</Typography>
              </MenuItem>
            </Tippy>
          </Box>
        }
      >
        <li
          className="menu__item"
          // onClick={() => setShowMenu(true)}
          role="button"
          // ref={buttonRef}
        >
          Add to
        </li>
      </Tippy>
      {showMenu && (
        <ContextMenu targetRef={buttonRef} ref={addToListRef}>
          <li
            onMouseEnter={() => setShowNestedMenu(true)}
            ref={nestedListTargetRef}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Baskets <span style={{ fontSize: 10 }} className="i-right"></span>
            {/* Nested Menu */}
            {showNestedMenu && (
              <ContextMenu targetRef={nestedListTargetRef} ref={nestedListRef}>
                {list.map((basket) => (
                  <li
                    onClick={() => onAddToExisting(basket, environment)}
                    key={`${basket.name}__${basket.id}`}
                  >
                    {basket?.name ?? ""}
                  </li>
                ))}
              </ContextMenu>
            )}
          </li>
          <li
            onMouseEnter={() => setShowNestedMenu(false)}
            onClick={onAddToNewBasket}
          >
            {" "}
            New Basket
          </li>
        </ContextMenu>
      )}
    </>
  );
};
