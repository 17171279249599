import { useState } from "react";
import { Item } from "./BlackList";

type BlacklistListProps = {
  data: Item[] | undefined;
  updateList: Function;
};

export default function BlacklistList({
  data,
  updateList,
}: BlacklistListProps) {
  return (
    <div>
      {data && data.length <= 0 ? "0 securities selected" : ""}
      <ul
        style={{
          border: "1px solid #d3d3d3",
          padding: 2,
          backgroundColor: "#f2f2f2",
          width: "100%",
          minHeight: 50,
        }}
      >
        {data?.map((item, index) => (
          <BlacklistListItem
            symbol={item.symbol}
            key={`${index}__${item.symbol}`}
            ticker={item.ticker}
            name={item.name}
            updateList={updateList}
            style={{
              border: "1px solid #d3d3d3",
              padding: 3,
              position: "relative",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: index === data.length - 1 ? 0 : 3,
            }}
          />
        ))}
      </ul>
    </div>
  );
}

// ---------------------------------------
// ------------BlacklistListItem----------
// ---------------------------------------
type BlacklistListItemProps = {
  name: string;
  updateList: Function;
  ticker: string;
  symbol: string;
  style: Object;
};
function BlacklistListItem({
  ticker,
  name,
  updateList,
  style,
}: BlacklistListItemProps) {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={style}
    >
      <div style={{ display: "inline-block" }}>
        <span>
          {ticker} - {name}
        </span>
      </div>
      {isHover && (
        <div
          style={{
            display: "inline-block",
            position: "initial",
            width: "auto",
            height: "100%",
            cursor: "pointer",
          }}
        >
          &nbsp;
          <span
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#2a7092";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "#000";
            }}
            onClick={(e) => {
              updateList(name);
            }}
            className="i-delete"
          ></span>
        </div>
      )}
    </div>
  );
}
