/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyKeyFacts
 * @summary Strategy - Key facts
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity, substituteVariables } from "./Generator";

export function sectionsKeyFacts(input, section, datum, formatter) {
  const _sections: any = [];

  // Check that at least one is active
  if (
    section["content"]["oneWayTurnover"]["isEnabled"] ||
    section["content"]["sharpeRatio"]["isEnabled"] ||
    section["content"]["sterlingRatio"]["isEnabled"] ||
    section["content"]["sortinoRatio"]["isEnabled"] ||
    section["content"]["beta"]["isEnabled"] ||
    section["content"]["trackingError"]["isEnabled"] ||
    section["content"]["informationRatio"]["isEnabled"] ||
    section["content"]["treynorRatio"]["isEnabled"] ||
    section["content"]["percentPositiveMonths"]["isEnabled"]
  ) {
    if (section["content"]["headlineKeyRatio"]["isEnabled"]) {
      const _section = {
        data: {
          text: escapeEntity(
            (
              section["content"]["headlineKeyRatio"]["content"] || "Key ratios"
            ).toUpperCase()
          ),
        },
        type: "header1",
      };
      _sections.push(_section);
    }
    const _section = sectionKeyFactsKeyRatios(input, section, datum, formatter);
    _sections.push(_section);
  }

  // Check that at least one is active
  if (
    section["content"]["maxDrawdown"]["isEnabled"] ||
    section["content"]["averageYearlyDrawdown"]["isEnabled"] ||
    section["content"]["monthlyStandardDeviation"]["isEnabled"] ||
    section["content"]["maxConsecutiveNegativeMonths"]["isEnabled"]
  ) {
    if (section["content"]["headlineRisk"]["isEnabled"]) {
      const _section = {
        data: {
          text: escapeEntity(
            (
              section["content"]["headlineRisk"]["content"] || "Risk (%)"
            ).toUpperCase()
          ),
        },
        type: "header1",
      };
      _sections.push(_section);
    }
    const _section = sectionKeyFactsRisk(input, section, datum, formatter);
    _sections.push(_section);
  }

  // Check that at least one is active
  if (
    section["content"]["cumulative"]["isEnabled"] ||
    section["content"]["annualized"]["isEnabled"] ||
    section["content"]["yearlyAverage"]["isEnabled"] ||
    section["content"]["maxConsecutivePositiveMonths"]["isEnabled"]
  ) {
    if (section["content"]["headlinePerformance"]["isEnabled"]) {
      const _section = {
        data: {
          text: escapeEntity(
            (
              section["content"]["headlinePerformance"]["content"] ||
              "Performance (%)"
            ).toUpperCase()
          ),
        },
        type: "header1",
      };
      _sections.push(_section);
    }
    const _section = sectionKeyFactsPerformance(
      input,
      section,
      datum,
      formatter
    );
    _sections.push(_section);
  }

  return _sections;
}

function sectionKeyFactsKeyRatios(input, section, data, formatter) {
  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [
        [
          {
            style: null,
            value: "",
          },
        ],
      ],
    },
    type: "table",
  };

  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];

  // Build Head
  if (hasStrategy) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["strategy"]["isEnabled"]
        ? substituteVariables(
            section["content"]["strategy"]["content"],
            input["target"]
          )
        : "Strategy",
    });
  }
  if (hasBenchmark) {
    const benchmark = data["benchmark"];
    const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
    const isNeutralEqualWeighted =
      benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

    let benchmarkTitle: any = "";

    if (isNeutralStrategy) {
      benchmarkTitle = "Neutral Strategy";
    } else if (isNeutralEqualWeighted) {
      benchmarkTitle = "Neutral Strategy Equal Weighted";
    } else {
      benchmarkTitle = substituteVariables(
        section["content"]["benchmark"]["content"],
        data["benchmark"]
      );
    }

    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["benchmark"]["isEnabled"]
        ? benchmarkTitle
        : "Benchmark",
    });
  }
  if (hasDelta) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["delta"]["isEnabled"]
        ? section["content"]["delta"]["content"] || "Difference"
        : "Difference",
    });
  }

  /*
                Build body

                One Way Turnover
                Sharpe ratio
                Sterling ratio
                Sortino ratio
                Beta
                Tracking error
                Information ratio
                Treynor ratio
                % Positive Months
            */

  if (section["content"]["oneWayTurnover"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["oneWayTurnover"]["content"] || "One Way Turnover",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_NO_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["avgTurnover"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_NO_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["avgTurnover"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options:
            customPropertiesFormattingOptions["PERCENTAGE_COLORED_NO_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["avgTurnover"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["sharpeRatio"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["sharpeRatio"]["content"] || "sharpeRatio",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sharpeRatio"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sharpeRatio"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options:
            customPropertiesFormattingOptions["NUMBER_4_DECIMALS_COLORED"],
          output: "PDF",
          value: data["keyRatios"]["sharpeRatio"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["sterlingRatio"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["sterlingRatio"]["content"] || "Sterling ratio",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sterlingRatio"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sterlingRatio"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options:
            customPropertiesFormattingOptions["NUMBER_4_DECIMALS_COLORED"],
          output: "PDF",
          value: data["keyRatios"]["sterlingRatio"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["sortinoRatio"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["sortinoRatio"]["content"] || "Sortino ratio",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sortinoRatio"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["sortinoRatio"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options:
            customPropertiesFormattingOptions["NUMBER_4_DECIMALS_COLORED"],
          output: "PDF",
          value: data["keyRatios"]["sortinoRatio"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["beta"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["beta"]["content"] || "Beta",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["beta"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["trackingError"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["trackingError"]["content"] || "Tracking error",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["keyRatios"]["trackingError"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["informationRatio"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["informationRatio"]["content"] ||
          "Information ratio",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["infoRatio"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["treynorRatio"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["treynorRatio"]["content"] || "Treynor ratio",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_4_DECIMALS"],
          output: "PDF",
          value: data["keyRatios"]["treynorRatio"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["percentPositiveMonths"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["percentPositiveMonths"]["content"] ||
          "% Positive Months",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["keyRatios"]["percentagePositivePeriod"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["keyRatios"]["percentagePositivePeriod"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
          output: "PDF",
          value: data["keyRatios"]["percentagePositivePeriod"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  return table;
}

function sectionKeyFactsRisk(input, section, data, formatter) {
  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [
        [
          {
            style: null,
            value: "",
          },
        ],
      ],
    },
    type: "table",
  };

  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];

  // Build Head
  if (hasStrategy) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["strategy"]["isEnabled"]
        ? substituteVariables(
            section["content"]["strategy"]["content"],
            input["target"]
          )
        : "Strategy",
    });
  }
  if (hasBenchmark) {
    const benchmark = data["benchmark"];
    const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
    const isNeutralEqualWeighted =
      benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

    let benchmarkTitle: any = "";

    if (isNeutralStrategy) {
      benchmarkTitle = "Neutral Strategy";
    } else if (isNeutralEqualWeighted) {
      benchmarkTitle = "Neutral Strategy Equal Weighted";
    } else {
      benchmarkTitle = substituteVariables(
        section["content"]["benchmark"]["content"],
        data["benchmark"]
      );
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["benchmark"]["isEnabled"]
        ? benchmarkTitle
        : "Benchmark",
    });
  }
  if (hasDelta) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["delta"]["isEnabled"]
        ? section["content"]["delta"]["content"] || "Difference"
        : "Difference",
    });
  }

  /*
                Build body

                Max drawdown
                Average yearly Drawdown
                Monthly Standard Deviation
                Max consecutive negative months
            */

  if (section["content"]["maxDrawdown"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["maxDrawdown"]["content"] || "Max drawdown",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["risk"]["maxDrawdown"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["risk"]["maxDrawdown"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
          output: "PDF",
          value: data["risk"]["maxDrawdown"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["averageYearlyDrawdown"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["averageYearlyDrawdown"]["content"] ||
          "Average yearly Drawdown",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["risk"]["avgYearlyDrawdown"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["risk"]["avgYearlyDrawdown"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
          output: "PDF",
          value: data["risk"]["avgYearlyDrawdown"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["monthlyStandardDeviation"]["content"] ||
          "Monthly Standard Deviation",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["risk"]["monthlyStdDev"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["risk"]["monthlyStdDev"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options:
            customPropertiesFormattingOptions["PERCENTAGE_COLORED_INVERTED"],
          output: "PDF",
          value: data["risk"]["monthlyStdDev"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["maxConsecutiveNegativeMonths"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["maxConsecutiveNegativeMonths"]["content"] ||
          "Max consecutive negative months",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER"],
          output: "PDF",
          value: data["risk"]["maxConsecutiveLoosingPeriod"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER"],
          output: "PDF",
          value: data["risk"]["maxConsecutiveLoosingPeriod"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_COLORED"],
          output: "PDF",
          value: data["risk"]["maxConsecutiveLoosingPeriod"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  return table;
}

function sectionKeyFactsPerformance(input, section, data, formatter) {
  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [
        [
          {
            style: null,
            value: "",
          },
        ],
      ],
    },
    type: "table",
  };

  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];

  // Build Head
  if (hasStrategy) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["strategy"]["isEnabled"]
        ? substituteVariables(
            section["content"]["strategy"]["content"],
            input["target"]
          )
        : "Strategy",
    });
  }
  if (hasBenchmark) {
    const benchmark = data["benchmark"];
    const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
    const isNeutralEqualWeighted =
      benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

    let benchmarkTitle: any = "";

    if (isNeutralStrategy) {
      benchmarkTitle = "Neutral Strategy";
    } else if (isNeutralEqualWeighted) {
      benchmarkTitle = "Neutral Strategy Equal Weighted";
    } else {
      benchmarkTitle = substituteVariables(
        section["content"]["benchmark"]["content"],
        data["benchmark"]
      );
    }

    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["benchmark"]["isEnabled"]
        ? benchmarkTitle
        : "Benchmark",
    });
  }
  if (hasDelta) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["delta"]["isEnabled"]
        ? section["content"]["delta"]["content"] || "Difference"
        : "Difference",
    });
  }

  /*
                Build body

                Cumulative
                Annualized
                Yearly Average
                Max consecutive positive months
            */

  if (section["content"]["cumulative"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["cumulative"]["content"] || "Cumulative",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
            output: "PDF",
            value: data["performance"]["cumulative"]["strategy"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
            output: "PDF",
            value: data["performance"]["cumulative"]["benchmark"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options:
              customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
            output: "PDF",
            value: data["performance"]["cumulative"]["delta"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["annualized"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["annualized"]["content"] || "Annualized",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
            output: "PDF",
            value: data["performance"]["annualized"]["strategy"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
            output: "PDF",
            value: data["performance"]["annualized"]["benchmark"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value:
          "<strong>" +
          format.custom("number", {
            options:
              customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
            output: "PDF",
            value: data["performance"]["annualized"]["delta"],
            valueHelper: null,
          }) +
          "</strong>",
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["yearlyAverage"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["yearlyAverage"]["content"] || "Yearly Average",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["performance"]["yearlyAverage"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN"],
          output: "PDF",
          value: data["performance"]["yearlyAverage"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE_SIGN_COLORED"],
          output: "PDF",
          value: data["performance"]["yearlyAverage"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  if (section["content"]["maxConsecutivePositiveMonths"]["isEnabled"]) {
    const tableRow: any = [
      {
        style: null,
        value:
          section["content"]["maxConsecutivePositiveMonths"]["content"] ||
          "Max consecutive positive months",
      },
    ];
    if (hasStrategy) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER"],
          output: "PDF",
          value: data["performance"]["winningPeriods"]["strategy"],
          valueHelper: null,
        }),
      });
    }
    if (hasBenchmark) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER"],
          output: "PDF",
          value: data["performance"]["winningPeriods"]["benchmark"],
          valueHelper: null,
        }),
      });
    }
    if (hasDelta) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["NUMBER_COLORED"],
          output: "PDF",
          value: data["performance"]["winningPeriods"]["delta"],
          valueHelper: null,
        }),
      });
    }
    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  return table;
}
