import { Box } from "@mui/material";
import { useCallback } from "react";
import { DropdownMenu } from "../../../../../../../components/DropdownMenu/DropdownMenu";

type SortByProps = {
  rotate?: boolean;
  options: { label: string; value: string }[];
  onChangeSort: (value) => void;
};

export function SortBy({ rotate = false, options, onChangeSort }: SortByProps) {
  const onValueChange = useCallback(
    (value) => {
      onChangeSort(value);
    },
    [onChangeSort]
  );

  return (
    <Box>
      <DropdownMenu options={options} handleDropDownClick={onValueChange}>
        <Box
          sx={
            rotate
              ? {
                  rotate: "-90deg",
                }
              : {}
          }
        >
          <span className={`i-sort-asc`} title="Sort By"></span>
        </Box>
      </DropdownMenu>
    </Box>
  );
}
