import { forwardRef } from "react";
import { useFormatter } from "../../../../../../../hooks/useFormatter";

type UpgradesDowngradesWidgetProps = {
  data: any[];
  title: "Upgrades" | "Downgrades";
  ref?: any;
};

const formatterTcr = (formatter, item) => {
  const tcr = [
    formatter.custom("rating", {
      options: {
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      output: "HTML",
      value: item["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      output: "HTML",
      value: item["rc"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");
  return tcr;
};

export const UpgradesDowngradesWidget = forwardRef(
  ({ data, title }: UpgradesDowngradesWidgetProps, ref: any) => {
    const formatter = useFormatter();

    return (
      <div className="upgrades-downgrades-list-wrapper-compare">
        <h2>{title}</h2>
        <div ref={ref} className="upgrades-downgrades-list-compare">
          <ul style={{ display: "flex", flexDirection: "column" }}>
            {data?.length ? (
              data?.map((el, index) => {
                return (
                  <li
                    key={`${index}__${el.name}`}
                    className="upgrades-downgrades-listitem-compare"
                  >
                    <div
                      className="upgrades-downgrades-listitem-tcr"
                      dangerouslySetInnerHTML={{
                        __html: formatterTcr(formatter, el),
                      }}
                    ></div>
                    <div
                      className="upgrades-downgrades-listitem-name"
                      dangerouslySetInnerHTML={{
                        __html: formatter.table("name", "table", el),
                      }}
                    ></div>
                    <div className="upgrades-downgrades-listitem-date">
                      {`${formatter.table("dr", "table", el)}`}
                    </div>
                  </li>
                );
              })
            ) : (
              <li style={{ minHeight: 35 }}>
                No {title.toLowerCase()} found for the selected period
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
);
