import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StrategyAndApproach } from "./TacticalAllocation";

type Props = {
  onClickHandler: Function;
  open: boolean;
  onOptimize: Function;
};
export default function ImplementStrategy({
  onOptimize,
  onClickHandler,
  open,
}: Props) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{ boxShadow: 3, backgroundColor: open ? "transparent" : "#f2f2f2" }}
    >
      <CardContent>
        <Box display={"flex"}>
          <Box
            sx={{ cursor: "pointer" }}
            display={"flex"}
            width={open ? "60%" : "100%"}
            flexDirection={"column"}
            onClick={() => {
              onClickHandler();
            }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <i
                style={{ fontSize: "2.5vw", color: open ? "#2A7090" : "#aaa" }}
                className="i-alpha"
              />
              <Box>
                <Typography
                  sx={{ color: open ? "#2A7090" : "#000" }}
                  variant="subtitle1"
                >
                  {open ? (
                    <strong> {t("Implement_strategy")}</strong>
                  ) : (
                    t("Implement_strategy")
                  )}
                </Typography>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Buy_and_sell_positions_in_order_to_match_the_alpha_strategy"
                      ),
                    }}
                  ></span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {open && (
            <Box width={"40%"}>
              <StrategyAndApproach
                entity_type={"ALPHA"}
                onOptimize={onOptimize}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
