import { ReactChild } from "react";
import { Spinner } from "../Spinner/Spinner";
import styles from "./ComponentStatusWizard.module.scss";

type ComponentStatusWizardProps = {
    status: "loading" | "complete" | "errored";
    customLoader?: ReactChild | ReactChild[];
    children: ReactChild | ReactChild[];
    customErrComponent?: ReactChild | ReactChild[];
    errorMessage?: string;
};

/**
 *
 * @param {props}
 * @returns A React component based on status. This component is born thinking about a standard
 *          method to handle different views based on the loading status when a component calls an API servce.
 *
 * TODO - If in the future will be necessary, a warning state could be handled.
 */
export default function ComponentStatusWizard({
    status,
    customLoader,
    children,
    customErrComponent,
    errorMessage = "An error occured during loading. Please retry later.",
}: ComponentStatusWizardProps) {
    return (
        <>
            {(() => {
                switch (status) {
                    case "loading":
                        return customLoader != null ? (
                            customLoader
                        ) : (
                            <div className={styles.spinner_loader__wrapper}>
                                <Spinner />
                            </div>
                        );

                    case "complete":
                        return <>{children}</>;

                    case "errored":
                        return customErrComponent != null ? (
                            customErrComponent
                        ) : (
                            <ErrorStatus message={errorMessage} />
                        );

                    default:
                        return <></>;
                }
            })()}
        </>
    );
}

const ErrorStatus = ({ message }) => {
    return <div className={styles.error_status__loader}>{message}</div>;
};
