import { useEffect, useMemo, useState } from "react";
import InputNumber from "../../../../InputNumber/InputNumber";

type OptionNumberProps = {
  item: any;
  setOuterState: any;
  initialValue?: any;
  constraints: any;
  getInputValidity: Function;
};

export function OptionNumber({
  item,
  setOuterState,
  initialValue,
  constraints,
  getInputValidity,
}: OptionNumberProps) {
  const [val, setVal] = useState<number | null>(0);

  useEffect(() => {
    const updatedState = {
      augmentation: {
        value: val,
      },
      subject: item["subject"],
    };

    setOuterState(updatedState);
  }, [val, item, setOuterState]);

  const operatorSign = useMemo(() => {
    switch (item["subject"]) {
      case "top":
        return (
          <span>
            <strong>{"Top"}</strong>
          </span>
        );
      case "bottom":
        return (
          <span>
            <strong>{"Bottom"}</strong>
          </span>
        );
      default:
        return "";
    }
  }, [item]);

  // If you want to set constraints use item["widget"]["constraints"]

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <>{operatorSign}</>
      <InputNumber
        getIsValid={(e) => getInputValidity(e)}
        constraints={constraints}
        setOuterState={setVal}
        isPercentage={false}
        isFloating={false}
        initVal={initialValue ?? 0}
      />
    </div>
  );
}
