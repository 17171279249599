import { useCallback, useMemo } from "react";
import { ActionsETFMarketsControls } from "../../AnalysisMarketsETF";
import { SelectorETFMarkets } from "../FiltersBarMarketDetail/FiltersBarMarketDetail";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";

type ConstraintsBarETFMarketsProps = {
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  dispatch: (action: ActionsETFMarketsControls) => void;
  segment:
    | "Area"
    | "Region"
    | "WWW"
    | "Country"
    | "1 Industry"
    | "3 Sector"
    | "4 Subsector";
  getSegmentOptionsByPeerType: Function;
};

const PAGE_KEY = "analysis_peer";

const CONFIG_TABS_DICT = {
  tcr: "TCR",
  dispersion: "dispersion",
  dispersionBy: "dispersionBySector",
  performanceSinceRated: "performanceSinceRated",
  history: "ratingHistory",
  securities: "securities",
};

const turnSegmentInLabel = (sg) => {
  switch (sg) {
    case "1 Industry":
      return "Asset Class";

    case "4 Subsector":
      return "Theme";
    case "3 Sector":
      return "Specialty";

    case "Area":
      return "Areas";

    case "Region":
      return "Regions";

    case "WWW":
      return "World Wide";

    default:
      return sg;
  }
};

const getOptions = (
  segment:
    | "Area"
    | "Region"
    | "WWW"
    | "Country"
    | "1 Industry"
    | "3 Sector"
    | "4 Subsector",
  config
) => {
  const tabs = [
    {
      label: "TCR",
      value: "tcr",
    },
    {
      label: "Rating history",
      value: "history",
    },
    {
      label: "Dispersion",
      value: "dispersion",
    },
    {
      label: "Securities",
      value: "securities",
    },
    {
      label: `Dispersion by ${segment}`,
      value: "dispersionBy",
    },
    {
      label: `Performance since rating`,
      value: "performanceSinceRated",
    },
  ];

  const availableTabs = config?.tabs?.["ETF"];

  if (availableTabs) {
    const tabsFromConfig: any = [];
    let tabConfig: any = null;

    for (const tab of tabs) {
      tabConfig = availableTabs[CONFIG_TABS_DICT[tab.value]];

      if (tabConfig && tabConfig.enabled === true) {
        tabsFromConfig.push(tab);
      }
    }

    if (config?.order) {
      tabsFromConfig.sort((a, b) => {
        return config?.order?.indexOf(CONFIG_TABS_DICT?.[a.value]) >
          config?.order?.indexOf(CONFIG_TABS_DICT?.[b.value])
          ? 1
          : -1;
      });
    }

    return tabsFromConfig;
  }

  return tabs;
};

export function ConstraintsBarETFMarkets({
  analytic,
  dispatch,
  segment,
  getSegmentOptionsByPeerType,
}: ConstraintsBarETFMarketsProps) {
  const environment = useEnvironment();
  const config = useMemo(() => {
    const configuration = environment.get("account")?.product?.configuration;
    const tabs = configuration?.[PAGE_KEY]?.tabs;
    const order = configuration?.[PAGE_KEY]?.tabOrder;
    return { tabs, order };
  }, [environment]);
  const setAnalytic = useCallback(
    (value) => dispatch({ type: "SET_ANALYTIC", payload: value }),
    [dispatch]
  );

  const areOnlyGeoOptions = useCallback((segmentLevels) => {
    let areOnlyGeoOptions = true;
    for (const level of segmentLevels) {
      if (
        level.value === "1 Industry" ||
        level.value === "3 Sector" ||
        level.value === "4 Subsector"
      ) {
        areOnlyGeoOptions = false;
      }
    }

    return areOnlyGeoOptions;
  }, []);

  const options = useMemo(() => {
    const optionsList = getOptions(turnSegmentInLabel(segment), config);
    // if xDim and yDim are leafs in taxonomies there's no options available so don't show
    // the dispersion by tab
    const segmentLevels = getSegmentOptionsByPeerType();

    if (segmentLevels.length === 0) {
      return optionsList.filter((option) => option.value !== "dispersionBy");
    }

    if (areOnlyGeoOptions(segmentLevels)) {
      return optionsList.filter(
        (option) => option.value !== "dispersionBy" && option.value !== "tcr"
      );
    }

    return optionsList;
  }, [areOnlyGeoOptions, config, getSegmentOptionsByPeerType, segment]);

  return (
    <SelectorETFMarkets
      options={options}
      selectOption={setAnalytic}
      selectedOption={analytic}
    />
  );
}
