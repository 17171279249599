import {
  Box,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";

type Props = {
  managementFeesHandler: any;
  expenseRatio: any;
};

const StyledInput = styled((props: any) => (
  <TextField
    helperText={!props.feeIsValid && "insert a valid fee"}
    error={!props.feeIsValid}
    onChange={(e) => {
      props.validateFee(e.target.value);
      props.managementFeesHandler(e);
    }}
    sx={{ fontSize: "0.7vw", pt: 1 }}
    className={props.className}
    size="small"
    type="number"
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    value={
      props.expenseRatio === null
        ? ""
        : props.expenseRatio === 0
        ? 0
        : props.expenseRatio * 100
    }
  />
))({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

const Fees = forwardRef(
  ({ managementFeesHandler, expenseRatio }: Props, ref) => {
    const [feeIsValid, setFeeIsValid] = useState(true);
    const validateFee = useCallback((fee) => {
      if (fee.length > 0) {
        setFeeIsValid(true);
        return;
      }
      setFeeIsValid(false);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        validateFee: (isFeeValid: boolean) => {
          setFeeIsValid(isFeeValid);
        },
      }),
      []
    );

    return (
      <Card sx={{ flex: 1, boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box pb={1}>
            <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
              Management Fees
            </Typography>
          </Box>
          <Divider
            sx={{ width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
          <StyledInput
            feeIsValid={feeIsValid}
            validateFee={validateFee}
            managementFeesHandler={managementFeesHandler}
            expenseRatio={expenseRatio}
          />
        </CardContent>
      </Card>
    );
  }
);

export default Fees;
