import { Card, CardContent } from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { helpTextobjs } from "./helpItems";

type HelpType = {
  caller: string;
  showHelp: boolean;
};
export const Help = forwardRef(({ caller, showHelp }: HelpType, ref) => {
  const [st, setSt] = useState<any>();
  useImperativeHandle(
    ref,
    () => ({
      setHelp: (value) => {
        setSt(value);
      },
    }),
    []
  );

  const helperToShow = useMemo(() => {
    const property = st?.property;
    const operator = st?.operator;
    const operatorParams = st?.operatorParam;
    const helpText = helpTextobjs[caller];
    if (helpText != null) {
      if (helpText.hasOwnProperty(property as string)) {
        if (helpText[property].type === "section") {
          return helpText[property];
        }
        if (helpText[property]?.[operator]?.[operatorParams]) {
          return helpText[property][operator][operatorParams];
        }
      }
    }
  }, [caller, st?.operator, st?.operatorParam, st?.property]);

  return showHelp && helperToShow != null ? (
    <div style={{ height: "100%" }}>
      <Card sx={{ height: "100%", boxShadow: 3 }}>
        <CardContent>{helperToShow}</CardContent>
      </Card>
    </div>
  ) : null;
});
