import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DAYS_5 = "05_DAYS";
const DAYS_20 = "20_DAYS";
const DAYS_60 = "60_DAYS";

type RebalanceProps = {
  strategyDispatch: Function;
  input: string | null;
};

export default function Rebalance({ strategyDispatch, input }: RebalanceProps) {
  const { t } = useTranslation();

  const [innerState, setInnerState] = useState(input);
  useEffect(() => {
    setInnerState(input);
  }, [input]);

  return (
    <Card sx={{ height: "100%", flex: 1 }}>
      <CardContent>
        <FormControl
          onChange={(e: any) => {
            strategyDispatch({ type: "SET_REBALANCE", payload: e.target.value });
            setInnerState(e.target.value);
          }}
        >
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <Typography variant="subtitle1">{t("page_builder_field_rebalance_label")}</Typography>
          </FormLabel>
          <RadioGroup value={innerState ?? DAYS_20}>
            <FormControlLabel
              value={DAYS_5}
              control={
                <Radio
                  sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
                  size="small"
                />
              }
              label={
                <span style={{ fontSize: "0.7vw " }}>
                  {t("page_builder_field_rebalance_label_05_days")}
                </span>
              }
            />
            <FormControlLabel
              value={DAYS_20}
              control={
                <Radio
                  sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
                  size="small"
                />
              }
              label={
                <span style={{ fontSize: "0.7vw " }}>
                  {t("page_builder_field_rebalance_label_20_days")}
                </span>
              }
            />
            <FormControlLabel
              value={DAYS_60}
              control={
                <Radio
                  sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
                  size="small"
                />
              }
              label={
                <span style={{ fontSize: "0.7vw " }}>
                  {t("page_builder_field_rebalance_label_60_days")}
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}
