import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import SimpleTextEditor from "../../../../../../../components/SimpleTextEditor/SimpleTextEditor";
import { deepClone } from "../../../../../../../deepClone";

type ParagraphProps = {
  configObj: any;
};

const Paragraph = forwardRef(({ configObj }: ParagraphProps, ref) => {
  const [fontSize, setFontSize] = useState(
    configObj.widgetValue.content.fontSize
  );

  const getTexEditoValue = useCallback(() => {
    const widget = textEditorRef?.current;
    if (widget != null) {
      return widget.getEditorValue();
    }
    return null;
  }, []);
  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      const textEditorValue = getTexEditoValue();
      tempState.widgetValue.content = {
        fontSize: fontSize,
        text: textEditorValue,
      };
      return tempState;
    },
  }));
  const textEditorRef = useRef<any>(null);
  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      <SimpleTextEditor
        initVal={configObj.widgetValue.content.text}
        ref={textEditorRef}
        minHeight={40}
      />

      <Box>
        <Typography>Font size</Typography>
        <RadioGroup
          sx={{ display: "flex", flexDirection: "row" }}
          defaultValue={fontSize}
          onChange={(e) => {
            setFontSize(e.target.value);
          }}
        >
          <FormControlLabel
            value="small"
            control={<Radio size="small" />}
            label="small"
          />
          <FormControlLabel
            value="normal"
            control={<Radio size="small" />}
            label="normal"
          />
          <FormControlLabel
            value="large"
            control={<Radio size="small" />}
            label="large"
          />
        </RadioGroup>
      </Box>
    </Box>
  );
});

export default Paragraph;
