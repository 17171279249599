const _intervals: any[] = [
  {
    left: null,
    right: 300000000,
    checked: false,
    innerHTML: "Micro Cap",
    label: "Micro Cap ($50M-$300M)",
    node: null,
  },
  {
    left: 300000000,
    right: 2000000000,
    checked: false,
    innerHTML: "Small Cap",
    label: "Small Cap ($300M-$2B)",
    node: null,
  },
  {
    left: 2000000000,
    right: 10000000000,
    checked: false,
    innerHTML: "Mid Cap",
    label: "Mid Cap ($2B-$10B)",
    node: null,
  },
  {
    left: 10000000000,
    right: 200000000000,
    checked: false,
    innerHTML: "Large Cap",
    label: "Large Cap ($10B-$200B)",
    node: null,
  },
  {
    left: 200000000000,
    right: null,
    checked: false,
    innerHTML: "Mega Cap",
    label: "Mega Cap (over $200B)",
    node: null,
  },
];

export const _getIntervalLabel = (
  marketcap,
  useMarketcapAsLabel: any,
  format
) => {
  var intervals = _intervals;
  var interval: any = null;
  var label: any = null;

  if (!marketcap) {
    return "";
  }

  if (marketcap >= intervals[0].left && marketcap < intervals[0].right) {
    interval = intervals[0];
  } else if (marketcap >= intervals[1].left && marketcap < intervals[1].right) {
    interval = intervals[1];
  } else if (marketcap >= intervals[2].left && marketcap < intervals[2].right) {
    interval = intervals[2];
  } else if (marketcap >= intervals[3].left && marketcap < intervals[3].right) {
    interval = intervals[3];
  } else if (marketcap >= intervals[4].left) {
    interval = intervals[4];
  }

  if (!interval) {
    interval = {
      innerHTML: format.html(
        "marketcap",
        "marketcap",
        marketcap,
        null,
        "instrument"
      ),
    };
  }

  if (useMarketcapAsLabel) {
    label =
      "Mkt. cap.: " +
      format.html("marketcap", "marketcap", marketcap, null, "instrument");
  } else {
    label = interval.label;
  }

  return '<span title="' + label + '">' + interval.innerHTML + "</span>";
};
export const _getETFClass = (etfclass, environment, envSetup) => {
  var taxonomies = environment.get("rawTaxonomies");
  const fields = envSetup["taxonomyFields"];

  var taxonomy = taxonomies[fields["ETF"]["etfclass"]];
  var data: any[] = [];

  var node: any = taxonomy[etfclass];
  if (node.parent != null) {
    data.push(node);
    return data.concat(_getETFClass(node.parent, environment, envSetup));
  }

  return data;
};
export const _getInterval = (marketcap) => {
  var intervals = _intervals;
  if (marketcap >= intervals[0].left && marketcap < intervals[0].right) {
    return intervals[0];
  } else if (marketcap >= intervals[1].left && marketcap < intervals[1].right) {
    return intervals[1];
  } else if (marketcap >= intervals[2].left && marketcap < intervals[2].right) {
    return intervals[2];
  } else if (marketcap >= intervals[3].left && marketcap < intervals[3].right) {
    return intervals[3];
  } else if (marketcap >= intervals[4].left) {
    return intervals[4];
  }
};
