import React, { useEffect } from "react";
import { useReport } from "../../../../../../../hooks/useReport";

type Props = {
  page: string;
  target: any;
  uiState?: any;
  rankingCache?: any;
  strategyCache?: any;
  widgets?: any;
  storage?: any;
  usage?: any;
  timeframe?: any;
  trimOutliers?: any;
  dispersionByConstraints?: any;
  segment?: any;
  sortState?: any;
  title?: string;
  additionalOnClick?: Function;
};

export default function ReportButton({
  page,
  target,
  uiState,
  rankingCache,
  widgets = null,
  storage = null,
  usage = null,
  timeframe = null,
  trimOutliers = null,
  dispersionByConstraints = null,
  segment = null,
  sortState = null,
  title,
  additionalOnClick,
}: Props) {
  const { doAction, setReportParams } = useReport({ page });
  useEffect(() => {
    setReportParams({
      page,
      target,
      // uiState,
      title,
      rankingCache,
      widgets,
      storage,
      usage,
      timeframe,
      trimOutliers,
      dispersionByConstraints,
      segment,
      sortState,
    });
  }, [
    dispersionByConstraints,
    page,
    rankingCache,
    segment,
    setReportParams,
    sortState,
    storage,
    target,
    timeframe,
    title,
    trimOutliers,
    uiState,
    usage,
    widgets,
  ]);
  return (
    <li
      className="menu__item"
      onClick={() => {
        doAction();
        if (additionalOnClick) {
          additionalOnClick();
        }
      }}
    >
      Report
    </li>
  );
}
