import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { messageError, messageSuccess, removeLoader } from "../../../utils";
import { CompareStrategiesEditor } from "../widgets/compare/CompareStrategiesEditor";
import { config } from "../../../config-ts";
import { v4 as uuidv4 } from "uuid";
import { WorkflowBarButton } from "../../../Workflow";
import { CompareStrategiesStorage } from "../../../storage/CompareStrategiesStorage";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import {
  LoaderContext,
  STRATEGIES_LOADER_EVENTS,
} from "../builder/StrategiesBuilder";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { Box, Typography } from "@mui/material";
import Result from "../builder/editors/Advanced/Result/Result";
import { Loader } from "../builder/Loader";
import Modal from "../../../../../components/Modal/Modal";
import { DialogSaveComponent } from "../../../ui/commons/DialogSave/DialogSaveComponent";
import { CompareStrategies as CompareStrategiesAPI } from "../../../../../api/compute/CompareStrategies";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

type StrategiesToCompareType = {
  firstStrategyId: null | number | string;
  secondStrategyId: null | number | string;
};

export function CompareStrategies() {
  const [workflowState, setWorkflowState] = useState<"s0" | "s1" | "s2">("s0");
  const [strategiesToCompare, setStrategiesToCompare] =
    useState<StrategiesToCompareType>({
      firstStrategyId: null,
      secondStrategyId: null,
    });
  const [errorUniverse, setErrorUniverse] = useState(false);
  const [results, setResults] = useState<any>();
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [activeCompare, setActiveCompare] = useState<any>();
  const [itemToRename, setItemToRename] = useState<any>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();

  useEffect(() => {
    removeLoader();
  }, []);

  const params = useParams();
  const environment = useEnvironment();
  const { dispatch, on, remove } = useEventBus();

  const description = t("Compare_Strategies");
  const baseUrlImages = appConfig.baseUrlImages;
  const icon = baseUrlImages + "strategy_compare.png";
  const title = t("Compare_Strategies");
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const compareStrategiesAPI = useMemo(
    () => new CompareStrategiesAPI(appSetup),
    [appSetup]
  );
  const showRenameDialog = useMemo(() => Boolean(itemToRename), [itemToRename]);

  const wrapSavePromises = useCallback(
    async (action) => {
      try {
        await action();
      } catch (error: any) {
        let errorMessage = "";
        if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
          errorMessage = "A strategy with this name already exists.";
        } else if (error) {
          errorMessage = "Unknown error, please retry later.";
        }
        const [channel, msg] = messageError(errorMessage);
        broadcast(channel as string, msg);
      } finally {
        setShowDialogSave(false);

        // Close rename dialog if the action is a rename and is invoked from the list of compares
        if (itemToRename) {
          setItemToRename(undefined);
        }
      }
    },
    [broadcast, itemToRename]
  );

  const onSuccess = useCallback(
    (message: string, response: any) => {
      const [channel, msg] = messageSuccess(message);
      broadcast(channel as string, msg);
      setActiveCompare(response);
      dispatch("refresh-compare-list");
    },
    [broadcast, dispatch]
  );

  const onSaveCompareStrategy = useCallback(
    async (name, resource?) => {
      wrapSavePromises(async () => {
        if (activeCompare != null || resource != null) {
          // If resource is passed as argument use it intead of use the activeCompare state (default behaviour)
          const newCompareStrategy =
            resource != null ? { ...resource } : { ...activeCompare };

          newCompareStrategy["strategy1"] = {
            id:
              typeof strategiesToCompare.firstStrategyId === "string"
                ? parseInt(strategiesToCompare.firstStrategyId)
                : strategiesToCompare.firstStrategyId,
          };
          newCompareStrategy["strategy2"] = {
            id:
              typeof strategiesToCompare.secondStrategyId === "string"
                ? parseInt(strategiesToCompare.secondStrategyId)
                : strategiesToCompare.secondStrategyId,
          };

          const isRenameAction = name != null;

          if (isRenameAction) {
            newCompareStrategy["name"] = name;
          }

          const response = await compareStrategiesAPI.update(
            newCompareStrategy
          );
          let successMessage = isRenameAction
            ? `    "<strong>${
                resource ? resource.name : activeCompare.name
              }</strong> has been renamed as <strong>${
                response.name
              }</strong>.",
            `
            : `<strong>${response.name}</strong> has been saved successfully`;

          onSuccess(successMessage, response);
        }
      });
    },
    [
      activeCompare,
      compareStrategiesAPI,
      onSuccess,
      strategiesToCompare.firstStrategyId,
      strategiesToCompare.secondStrategyId,
      wrapSavePromises,
    ]
  );

  const onSaveAsNewCompareStrategy = useCallback(
    async (name) => {
      wrapSavePromises(async () => {
        const newCompareStrategy: any = compareStrategiesAPI.getPrototype();

        newCompareStrategy["name"] = name;
        newCompareStrategy["strategy1"] = {
          id:
            typeof strategiesToCompare.firstStrategyId === "string"
              ? parseInt(strategiesToCompare.firstStrategyId)
              : strategiesToCompare.firstStrategyId,
        };
        newCompareStrategy["strategy2"] = {
          id:
            typeof strategiesToCompare.secondStrategyId === "string"
              ? parseInt(strategiesToCompare.secondStrategyId)
              : strategiesToCompare.secondStrategyId,
        };

        const response = await compareStrategiesAPI.create(newCompareStrategy);
        const successMessage = `<strong>${response.name}</strong> has been saved successfully`;

        onSuccess(successMessage, response);
      });
    },
    [
      compareStrategiesAPI,
      onSuccess,
      strategiesToCompare.firstStrategyId,
      strategiesToCompare.secondStrategyId,
      wrapSavePromises,
    ]
  );

  const onDelete = useCallback(
    async (resource) => {
      try {
        if (resource && !resource.isReadOnly) {
          await compareStrategiesAPI.remove(resource);

          const [channel, msg] = messageSuccess(
            `${resource.name} has been removed successfully`
          );
          broadcast(channel as string, msg);
        }
      } catch (error) {
      } finally {
        setActiveCompare(undefined);
        dispatch("refresh-compare-list");
      }
    },
    [broadcast, compareStrategiesAPI, dispatch]
  );

  const actionsDialogSave = useMemo(
    () => ({
      onSave: activeCompare != null ? onSaveCompareStrategy : null,
      onSaveAs: onSaveAsNewCompareStrategy,
      onRename: activeCompare != null ? onSaveCompareStrategy : null,
    }),
    [activeCompare, onSaveAsNewCompareStrategy, onSaveCompareStrategy]
  );

  const onRenameFromMenu = useCallback((resource) => {
    setItemToRename(resource);
  }, []);

  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
    }),
    [dispatch]
  );

  const runAPI = useMemo(
    () =>
      new CompareStrategiesStorage(environment.get("setup"), loadingBehaviours),
    [environment, loadingBehaviours]
  );

  const getStrategies = useCallback(
    (strategiesIds: StrategiesToCompareType) => {
      setStrategiesToCompare(strategiesIds);
    },
    []
  );

  const actionRun = useCallback(
    async (strategy1Id, strategy2Id) => {
      setResults(undefined);
      if (strategy1Id && strategy2Id) {
        dispatch(STRATEGIES_LOADER_EVENTS.startPreload);
        setWorkflowState("s1");
        const strategies = await runAPI.loadStrategies(
          strategy1Id,
          strategy2Id
        );

        const context = {
          strategy: {
            params: {
              strategy: {
                currency: null,
                benchmark: {
                  name: strategies?.strategy2?.name ?? "Strategy 2",
                },
              },
            },
            name: strategies?.strategy1?.name ?? "Strategy 1",
          },

          strategyInstrumentBenchmark: {
            name: strategies?.strategy2?.name ?? "Strategy 2",
          },
          compare: {
            ids: {
              strategyId: strategy1Id,
              strategyId2: strategy2Id,
            },
          },
        };

        setResults(context);
      } else {
        setErrorUniverse(true);
      }
    },
    [dispatch, runAPI]
  );

  const actionRunOnLanding = useCallback(async () => {
    const id1 = params?.id;
    const id2 = params?.id2;

    actionRun(id1, id2);
  }, [actionRun, params?.id, params?.id2]);

  const actionRunOnClick = useCallback(async () => {
    const strategy1Id = strategiesToCompare.firstStrategyId;
    const strategy2Id = strategiesToCompare.secondStrategyId;

    actionRun(strategy1Id, strategy2Id);
  }, [
    actionRun,
    strategiesToCompare.firstStrategyId,
    strategiesToCompare.secondStrategyId,
  ]);

  const actionBack = useCallback(() => {
    let url = "/app/strategies/";

    if (strategiesToCompare.firstStrategyId != null) {
      url = `/app/strategies/builder/advanced/${strategiesToCompare.firstStrategyId}`;
    }

    navigate(url);
  }, [navigate, strategiesToCompare.firstStrategyId]);

  const actionSave = useCallback(() => {
    setShowDialogSave(true);
  }, []);

  const handleRunFinish = useCallback(() => {
    setWorkflowState("s2");
  }, []);

  const closeSaveDialog = useCallback(() => setShowDialogSave(false), []);

  const closeRenameDialog = useCallback(() => setItemToRename(undefined), []);

  // Workflow - actions
  useEffect(() => {
    let actions: any = [];
    let action: any = null;

    switch (workflowState) {
      case "s0": {
        action = {
          componentJSX: (
            <WorkflowBarButton label={"Back"} onClickHandler={actionBack} />
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <WorkflowBarButton
              label={"Run"}
              onClickHandler={actionRunOnClick}
            />
          ),
        };

        actions.push(action);

        break;
      }

      case "s1": {
        // Do nothing page is loading

        break;
      }

      case "s2": {
        action = {
          componentJSX: (
            <WorkflowBarButton label={"Back"} onClickHandler={actionBack} />
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <WorkflowBarButton
              label={"Run"}
              onClickHandler={actionRunOnClick}
            />
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <WorkflowBarButton label={"Save"} onClickHandler={actionSave} />
          ),
        };

        actions.push(action);

        if (activeCompare != null) {
          action = {
            componentJSX: (
              <WorkflowBarButton
                className="menu__item--delete"
                label={"Delete"}
                onClickHandler={() => onDelete(activeCompare)}
              />
            ),
          };

          actions.push(action);
        }

        break;
      }
    }

    var message = {
      from: uuidv4(),
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    actionBack,
    actionRunOnClick,
    actionSave,
    activeCompare,
    broadcast,
    onDelete,
    workflowState,
  ]);

  useEffect(() => {
    if ("action" in params && params.action === "run") {
      actionRunOnLanding();
    }
  }, [actionRunOnLanding, params]);

  useEffect(() => {
    on("run-finish", handleRunFinish);

    return () => remove("run-finish", handleRunFinish);
  }, [handleRunFinish, on, remove]);

  return (
    <ErrorBoundary
      fallback={
        <Typography>
          An Error occures during your last operation, please refresh the page
          and try again, if the issue persist, contact our customers support
        </Typography>
      }
    >
      <LoaderContext.Provider
        value={{
          availableEvents: STRATEGIES_LOADER_EVENTS,
          on,
          remove,
          dispatch,
        }}
      >
        {errorUniverse && (
          <Modal
            closeIcon={false}
            buttonsEnalbed={true}
            buttons={[
              {
                name: "Cancel",
                callback: () => setErrorUniverse(false),
                variant: "cancel",
              },
            ]}
          >
            <Typography>
              Please select 2 strategies to compare each other
            </Typography>
          </Modal>
        )}
        {showDialogSave && (
          <DialogSaveComponent
            item={{
              name: activeCompare?.name ?? "",
            }}
            dialogType={"Compare Strategy"}
            onSave={actionsDialogSave.onSave}
            onSaveAs={actionsDialogSave.onSaveAs}
            onRename={actionsDialogSave.onRename}
            hide={closeSaveDialog}
          />
        )}
        {showRenameDialog && (
          <DialogSaveComponent
            item={{
              name: itemToRename?.name ?? "",
            }}
            dialogType={"Compare Strategy"}
            onSave={null}
            onSaveAs={null}
            onRename={(name) => onSaveCompareStrategy(name, itemToRename)}
            hide={closeRenameDialog}
          />
        )}
        <article style={{ height: "100%", display: "flex" }}>
          <Box display={"flex"} flex={1} minHeight={0} minWidth={0}>
            <Box flex={1}>
              <CompareStrategiesEditor
                onRun={actionRun}
                onDelete={onDelete}
                onRename={onRenameFromMenu}
                icon={icon}
                title={title}
                description={description}
                hasList
                idStrategy1={params?.id}
                idStrategy2={params?.id2}
                readStrategiesToCompare={getStrategies}
                readLoadedCompare={setActiveCompare}
              />
            </Box>
            <Box flex={3}>
              {results && (
                <Result
                  helpEnabled={false}
                  page="compare"
                  value={results}
                  tabs={["keyFacts", "chart", "analytics", "definition"]}
                  runManager={runAPI}
                  dashboard={{
                    V1: undefined,
                    V2: undefined,
                    catalog: undefined,
                    status: "loading",
                  }}
                />
              )}
            </Box>

            <Loader />
          </Box>
        </article>
      </LoaderContext.Provider>
    </ErrorBoundary>
  );
}
