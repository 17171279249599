import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import MinMaxInputNumer from "./MinMaxInputNumer";
import NewSlider from "./NewSlider";
import { Strategy } from "./editorReducer";

type MacroRotationSmartBetaRulesProps = {
  strategy: Strategy;
  dispatch: Function;
};

export default function MacroRotationSmartBetaRules({
  strategy,
  dispatch,
}: MacroRotationSmartBetaRulesProps) {
  const [sliderState, updateSliderState] = useImmer({ A: 1, B: 1 });
  const [textBox, updateTextBox] = useImmer({
    maximum: 10,
    discreteValues: 10,
  });
  const [textBox_B, updateTextBox_B] = useImmer({
    maximum: 10,
    discreteValues: 10,
  });

  const weightingSchema =
    strategy?.weighting?.weightingSchema ?? "WEIGHT_EQUAL";

  const selectionParams = strategy.selection!.find(
    (item) => item.property === "rc"
  )?.operatorParams.value;
  const {
    A: selectionA,
    B: selectionB,
    C: selectionC,
    D: selectionD,
  } = selectionParams;

  const ref = useRef<any>(null);
  const refB = useRef<any>(null);

  const updateRangeLimit = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      switch (sliderKey) {
        case "A":
          updateTextBox((draft) => {
            draft.discreteValues = value + 1;
            draft.maximum = value + 1;
            if (ref.current != null) {
              ref.current.widget.set("count", value + 1);
              ref.current.widget.buildRendering();
            }
          });
          break;
        case "B":
          updateTextBox_B((draft) => {
            draft.discreteValues = value + 1;
            draft.maximum = value + 1;
            if (refB.current != null) {
              refB.current.widget.set("count", value + 1);
              refB.current.widget.buildRendering();
            }
          });
          break;
      }
    },
    [updateTextBox, updateTextBox_B]
  );

  const syncSliderAtRender = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      if (value > 10 && value < 100) {
        updateRangeLimit(value, sliderKey);
      }
    },
    [updateRangeLimit]
  );

  const sliderMaxValueChangeHandler = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      switch (sliderKey) {
        case "A":
          if (value === textBox.maximum) {
            if (textBox.maximum < 100) {
              updateRangeLimit(value, sliderKey);
            }
          }
          break;
        case "B":
          if (value === textBox_B.maximum) {
            if (textBox_B.maximum < 100) {
              updateRangeLimit(value, sliderKey);
            }
          }
          break;
      }
    },
    [textBox.maximum, textBox_B.maximum, updateRangeLimit]
  );

  useEffect(() => {
    const payload = {
      A: sliderState.A,
      B: sliderState.B,
    };
    dispatch({ type: "SET_SMART_BETA_RULES_MACRO", payload });

    /** //!old
     * Update the range limit when the value of the widget is greater than the maximum but
     * less than 100. This check is important when the user save a value that exceed the max,
     * because when we load that value if there's not some check, at the render the the maximum
     * value of the widget will not be updated and the widget will render itself in a wrong way.
     */
    syncSliderAtRender(sliderState.A, "A");
    syncSliderAtRender(sliderState.B, "B");

    /** //!old
     * Update the widget limit when the user set a value that is greather than 10
     */
    sliderMaxValueChangeHandler(sliderState.A, "A");
    sliderMaxValueChangeHandler(sliderState.B, "B");
  }, [dispatch, sliderMaxValueChangeHandler, sliderState, syncSliderAtRender]);

  useEffect(() => {
    updateSliderState((draft) => {
      draft.A = strategy.weighting.smartBeta![0].operator.A;
      draft.B = strategy.weighting.smartBeta![0].operator.B;
    });
  }, [strategy, updateSliderState]);

  const [inputAKey, setInputAKey] = useState(Date.now());
  const [inputBKey, setInputBKey] = useState(Date.now());

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">5</span> Smart beta rules
      </legend>
      <div className="form__field">
        <FormControl>
          <Typography>Weights</Typography>
          <RadioGroup
            row
            value={weightingSchema}
            onChange={(e) => {
              const value = e.target.value;
              dispatch({
                type: "SET_WEIGHTING_SCHEMA",
                payload: value,
              });
            }}
          >
            <FormControlLabel
              value={"WEIGHT_EQUAL"}
              control={<Radio />}
              label={<Typography>Equal weighted</Typography>}
            />
            <FormControlLabel
              value={"WEIGHT_MARKET_CAP"}
              control={<Radio />}
              label={<Typography>Mkt. Cap. weighted</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </div>
      {/**
       * Show the panel only if the rating is AB or any
       */}
      {selectionA && !selectionB && !selectionC && !selectionD ? null : (
        <>
          <div className="form__field" data-dojo-attach-point="_nodeSliderA">
            <Typography>Factors</Typography>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography>
                <strong className="rate rate--A builder-smart-beta__rate">
                  A
                </strong>
              </Typography>
              <Box width={"100%"}>
                <NewSlider
                  minValue={1}
                  maxValue={100}
                  onChangeValue={(val) => {
                    updateSliderState((draft) => {
                      draft.A = val;
                    });
                    setInputAKey(Date.now());
                  }}
                  value={sliderState.A}
                />
              </Box>
              <MinMaxInputNumer
                key={inputAKey}
                value={sliderState.A}
                min={1}
                max={100}
                stateSetter={(value) => {
                  updateSliderState((draft) => {
                    draft.A = value;
                  });
                }}
              />
            </Box>
          </div>
          <div className="form__field" data-dojo-attach-point="_nodeSliderB">
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography>
                <strong className="rate rate--B builder-smart-beta__rate">
                  B
                </strong>
              </Typography>
              <Box width={"100%"}>
                <NewSlider
                  minValue={1}
                  maxValue={100}
                  onChangeValue={(val) => {
                    const constraints = {
                      min: 1,
                      max: 100,
                    };

                    if (val >= constraints.min && val <= constraints.max) {
                      updateSliderState((draft) => {
                        draft.B = val;
                      });
                    } else if (val > constraints.max) {
                      updateSliderState((draft) => {
                        draft.B = constraints.max;
                      });
                    } else if (val < constraints.min) {
                      updateSliderState((draft) => {
                        draft.B = constraints.min;
                      });
                    }

                    setInputBKey(Date.now());
                  }}
                  value={sliderState.B}
                />
              </Box>
              <MinMaxInputNumer
                key={inputBKey}
                value={sliderState.B}
                min={1}
                max={100}
                stateSetter={(value) => {
                  updateSliderState((draft) => {
                    draft.B = value;
                  });
                }}
              />
            </Box>
          </div>
        </>
      )}
    </fieldset>
  );
}
