import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

type Props = {
  intervals: "20" | "10" | "4";
  timeframe: "1_month" | "3_months" | "6_months" | "12_months";
  onsSetIntervals: Function;
  onSetTimeframe: Function;
};

export default function PerformanceDispersion({
  intervals,
  onSetTimeframe,
  onsSetIntervals,
  timeframe,
}: Props) {
  const timeframeButtons = useMemo(() => {
    const obj = {
      "1_month": "1M",
      "3_months": "3M",
      "6_months": "6M",
      "12_months": "12M",
    };
    const btnValues = Object.keys(obj);
    const btns: any[] = [];
    btnValues.forEach((btn, index) => {
      btns.push(
        <Button
          onClick={() => onSetTimeframe(btn)}
          key={index}
          variant={btn === timeframe ? "contained" : undefined}
        >
          {obj[btn]}
        </Button>
      );
    });
    return btns;
  }, [onSetTimeframe, timeframe]);

  const topBottomButtons = useMemo(() => {
    const obj = {
      "20": "5%",
      "10": "10%",
      "4": "25%",
    };
    const objKeys = Object.keys(obj);
    let btns: any[] = [];
    objKeys.forEach((key, index) => {
      btns.push(
        <Button
          key={index}
          variant={intervals === key ? "contained" : undefined}
          onClick={() => onsSetIntervals(key)}
        >
          {obj[key]}
        </Button>
      );
    });
    return btns;
  }, [intervals, onsSetIntervals]);

  return (
    <Card>
      <CardContent sx={{ p: 1, pb: "8px !important" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="tr_subtitle_blue">
            Performance Dispersion
          </Typography>

          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>Performance</Typography>
            <ButtonGroup>{timeframeButtons}</ButtonGroup>
          </Box>

          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>Top/Bottom</Typography>
            <ButtonGroup>{topBottomButtons}</ButtonGroup>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
