import { createTheme } from "@mui/material";
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    status?: {
      danger?: React.CSSProperties["color"];
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    tr_button_cancel: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tr_text_body: true;
    tr_subtitle_blue: true;
  }
}

export const TR_theme = createTheme({
  palette: {
    primary: { main: "#2A7090" },
    secondary: { main: "#ffc001" },
  },

  components: {
    MuiButton: {
      defaultProps: { variant: "contained", size: "small" },
      styleOverrides: {
        root: {
          textTransform: "none",
          height: "2rem",
          fontSize: "0.7vw",
        },
      },
      variants: [
        {
          props: { variant: "tr_button_cancel" },
          style: {
            backgroundColor: "#fff",
            color: "#2A7090",
            boxShadow:
              "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            "&:hover": { backgroundColor: "#f2f2f2" },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "0.7vw",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ":last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: { size: "small" },
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.7vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: { variant: "tr_text_body" },
      variants: [
        {
          props: { variant: "tr_text_body" },
          style: {
            fontSize: "0.7vw",
          },
        },
        {
          props: { variant: "tr_subtitle_blue" },
          style: {
            fontSize: "0.7vw",
            margin: 0,
            color: "#2A7090",
            textTransform: "uppercase",
          },
        },
        {
          props: { variant: "subtitle1" },
          style: {
            fontSize: "0.9vw",
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: { size: "small" },
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
  },
});
