/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportPortfolio
 * @summary Manages portfolio analysis report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportPortfolio extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_PORTFOLIO" as const;
}
