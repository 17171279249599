import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { RapidMenu } from "../../../../../../components/RapidMenu/RapidMenu";
import { TDate } from "../../../../../../trendrating/date/TDate";
import { DialogSaveComponent } from "../../../../ui/commons/DialogSave/DialogSaveComponent";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { CombinedStrategies } from "../../../../../../api/compute/CombinedStrategies";
import { CompareStrategies } from "../../../../../../api/compute/CompareStrategies";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { messageError, messageSuccess } from "../../../../utils";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";
import { styled } from "@mui/system";

type StrategiesListProps = {
  params: any;
  listenerRemove: any;
  listenerReport: any;
  listenerSummary: any;
  strategyType: string;
  // only if used in dojo
  onRenameCallback?: Function;
};

export function StrategiesList({
  params,
  listenerRemove,
  listenerReport,
  listenerSummary,
  strategyType,
  onRenameCallback,
}: StrategiesListProps) {
  const [collection, setCollection] = useState(params.items);
  const [sort, setSort] = useState<
    "name" | "name_rev" | "recent_first" | "recent_bottom"
  >("name");
  const [itemToRename, setitemToRename] = useState<any>();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const combinedStrategiesAPI = useMemo(() => {
    return new CombinedStrategies(appSetup);
  }, [appSetup]);
  const compareStrategiesAPI = useMemo(() => {
    return new CompareStrategies(appSetup);
  }, [appSetup]);
  const strategiesAPI = useMemo(() => {
    return new Strategies(appSetup);
  }, [appSetup]);

  useEffect(() => {
    setCollection(params.items);
  }, [params.items]);

  const baseClass = "ListStrategies";
  const cssClassListItem = baseClass + "-listItem";

  const actions: any = useMemo(() => {
    let arrActions: {
      label: string;
      type: string;
      value: string;
    }[] = [
      {
        label: "Rename",
        type: "item",
        value: "actionRename",
      },
    ];
    if (listenerRemove != null) {
      arrActions.push({
        label: "Delete",
        type: "item",
        value: "actionDelete",
      });
    }
    if (listenerReport != null) {
      arrActions.push({
        label: "Report",
        type: "item",
        value: "actionReport",
      });
    }
    if (listenerSummary != null) {
      arrActions.push({
        label: "Summary",
        type: "item",
        value: "actionSummary",
      });
    }
    return arrActions;
  }, [listenerRemove, listenerReport, listenerSummary]);

  const wrapMenuActionHandler = useCallback(
    (event, resource) => {
      event.stopPropagation();
      const action = event?.value.action;
      switch (action) {
        case "actionReport": {
          listenerReport(resource);

          break;
        }

        case "actionRename": {
          setitemToRename(resource);

          break;
        }

        case "actionSummary": {
          listenerSummary(resource);

          break;
        }

        case "actionDelete": {
          listenerRemove(resource);
        }
      }
    },
    [listenerRemove, listenerReport, listenerSummary]
  );

  const handleListItemClick = useCallback(
    (resource) => {
      const getType = () => {
        switch (strategyType) {
          case "alpha": {
            return "alpha";
          }
          case "advanced": {
            return "advanced";
          }
          case "combined": {
            // not used in buider
            // they have their own ad-hoc page
            return "combined";
          }
          case "macroRotation": {
            return "macro-rotation";
          }
          case "smartBeta": {
            return "smart-beta";
          }
          default: {
            return "unknownStrategyType";
          }
        }
      };
      if (strategyType === "combined") {
        (window as any).__page_navigate(
          `/strategies/${getType()}/${resource.id}`
        );
      } else {
        (window as any).__page_navigate(
          `/strategies/builder/${getType()}/${resource.id}`
        );
      }
    },
    [strategyType]
  );

  const onSortChange = useCallback(
    (sort) => {
      const collectionCopy = [...collection];
      const sortFields = {
        name: "name",
        name_rev: "name",
        recent_first: "modified",
        recent_bottom: "modified",
      };

      const directionMap = {
        name: "desc",
        name_rev: "asc",
        recent_first: "desc",
        recent_bottom: "asc",
      };

      const property = sortFields[sort];
      const dir = directionMap[sort];

      let aDateToDays: any = null;
      let bDateToDays: any = null;

      collectionCopy.sort((a, b) => {
        if (property === "modified") {
          aDateToDays = TDate.dateToDays(new Date(a[property]));
          bDateToDays = TDate.dateToDays(new Date(b[property]));

          if (aDateToDays > bDateToDays) {
            return dir === "desc" ? -1 : 1;
          } else if (aDateToDays < bDateToDays) {
            return dir === "desc" ? 1 : -1;
          }

          return 0;
        }

        if (a[property] > b[property]) {
          return dir === "desc" ? 1 : -1;
        } else if (a[property] < b[property]) {
          return dir === "desc" ? -1 : 1;
        }

        return 0;
      });

      setCollection(collectionCopy);
      setSort(sort);
    },
    [collection]
  );

  const onRename = useCallback(
    async (name) => {
      switch (itemToRename.type) {
        case "COMBINED_STRATEGY": {
          let wholeObj = await combinedStrategiesAPI.get(itemToRename.id);
          wholeObj["name"] = name;
          combinedStrategiesAPI.update(wholeObj).then(
            (response) => {
              if (onRenameCallback) {
                onRenameCallback(response);
              }
              setitemToRename(undefined);
              const [channel, msg] = messageSuccess(
                `<strong>${itemToRename.name}</strong> has been renamed as <strong>${name}</strong>.`
              );
              broadcast(channel as string, msg);
            },
            (error) => {
              let errorMessage = "";
              if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
                errorMessage = "A strategy with this name already exists.";
              } else if (error) {
                errorMessage = "Unknown error, please retry later.";
              }
              setitemToRename(undefined);
              const [channel, msg] = messageError(errorMessage);
              broadcast(channel as string, msg);
            }
          );

          break;
        }
        case "COMPARED_STRATEGY": {
          let wholeObj = await compareStrategiesAPI.get(itemToRename.id);
          wholeObj["name"] = name;
          compareStrategiesAPI.update(wholeObj).then(
            (response) => {
              if (onRenameCallback) {
                onRenameCallback(response);
              }
              setitemToRename(undefined);
              const [channel, msg] = messageSuccess(
                `<strong>${itemToRename.name}</strong> has been renamed as <strong>${name}</strong>.`
              );
              broadcast(channel as string, msg);
            },

            (error) => {
              let errorMessage = "";
              if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
                errorMessage = "A strategy with this name already exists.";
              } else if (error) {
                errorMessage = "Unknown error, please retry later.";
              }
              setitemToRename(undefined);
              const [channel, msg] = messageError(errorMessage);
              broadcast(channel as string, msg);
            }
          );

          break;
        }
        case "ALPHA":
        case "BUILDER":
        case "SECTOR_ROTATION":
        case "SMART_BETA":
        default: {
          let wholeObj = await strategiesAPI.getById(itemToRename.id);
          wholeObj["name"] = name;
          return strategiesAPI.update(wholeObj).then(
            (response) => {
              if (onRenameCallback) {
                onRenameCallback(response);
              }
              setitemToRename(undefined);
              const [channel, msg] = messageSuccess(
                `<strong>${itemToRename.name}</strong> has been renamed as <strong>${name}</strong>.`
              );
              broadcast(channel as string, msg);
            },
            (error) => {
              let errorMessage = "";
              if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
                errorMessage = "A strategy with this name already exists.";
              } else if (error) {
                errorMessage = "Unknown error, please retry later.";
              }
              setitemToRename(undefined);
              const [channel, msg] = messageError(errorMessage);
              broadcast(channel as string, msg);
            }
          );
        }
      }
    },
    [
      broadcast,
      combinedStrategiesAPI,
      compareStrategiesAPI,
      itemToRename?.id,
      itemToRename?.name,
      itemToRename?.type,
      onRenameCallback,
      strategiesAPI,
    ]
  );

  const userID = useMemo(
    () => environment.get("account").user?.id,
    [environment]
  );

  return (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      overflow={"hidden"}
    >
      {itemToRename && (
        <DialogSaveComponent
          item={{
            name: itemToRename.name,
          }}
          onSave={null}
          onSaveAs={null}
          onRename={onRename}
          dialogType={"strategy"}
          hide={() => setitemToRename(undefined)}
        />
      )}

      <Box display={"flex"} justifyContent={"flex-start"} px={2}>
        <Box>
          <SortBy sort={sort} setSort={onSortChange} />
        </Box>
      </Box>
      <Box height={"100%"} overflow={"auto"}>
        <Box display={"flex"} flexDirection={"column"} px={2}>
          {collection.map((resource) => (
            <li
              key={uuidv4()}
              className={cssClassListItem}
              title={resource.isReadOnly ? t("Show") : t("Edit")}
              onClick={() => handleListItemClick(resource)}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  {resource.name}
                  {resource.ownerId !== userID && (
                    <div className="sharedObjectIndicator"></div>
                  )}
                </Box>
                <Box boxShadow={2}>
                <RapidMenu
                  smallBtn
                  availableForSharedObject={["actionReport", "actionSummary"]}
                  onOptionClickHandler={(evt) =>
                    wrapMenuActionHandler(evt, resource)
                  }
                  options={actions}
                  isReadOnly={resource.ownerId !== userID}
                /></Box>
              </Box>
            </li>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

type SortByProps = {
  sort: "name" | "name_rev" | "recent_first" | "recent_bottom";

  setSort: (sort: SortByProps["sort"]) => void;
};

const OPTIONS = [
  { label: "Recent: First", value: "recent_first" },
  { label: "Recent: Bottom", value: "recent_bottom" },
  { label: "Name: A to Z", value: "name" },
  { label: "Name: Z to A", value: "name_rev" },
];

const StyledSelect = styled((props: any) => (
  <Select
    value={props.sort}
    fullWidth
    size="small"
    variant="outlined"
    classes={{ select: props.className }}
  >
    {props.children}
  </Select>
))({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

export function SortBy({ sort, setSort }: SortByProps) {
  const selectSort = useCallback(
    (value: "name" | "name_rev" | "recent_first" | "recent_bottom") => {
      setSort(value);
    },
    [setSort]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        <StyledSelect sort={sort}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => selectSort(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
}
