/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Publications
 * @summary API for publications
 *
 */

import { httpAll } from "../../httpAll";
import { endpoints } from "../endpoints";
import {
  PUBLISHER_USER_ID,
  SHARED_OBJECT_TYPE_SERVER_TO_UI,
  SHARED_OBJECT_TYPE_UI_TO_SERVER,
  _CommonPublicationsSubscriptions,
} from "./_CommonPublicationsSubscriptions";

export class Publications extends _CommonPublicationsSubscriptions {
  /**
   * Creates a publication
   *
   * @param {object} params - request parameters
   * @param {number} params.id - the id of the object to be published
   * @param {string} params.type - type of object to be published.
   *      One of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  create({
    id,
    type,
  }: {
    id: number;
    type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.publications.create;

    const objectType = SHARED_OBJECT_TYPE_UI_TO_SERVER[type];
    const paramsCreate = {
      objectId: id,
      objectType: objectType,
      ownerId: this.environment.account.user?.id,
    };

    return this.preparePost(url, paramsCreate, null);
  }

  /**
   * Retrieves publication minimum information
   *
   * @param {object} params - request parameters
   * @param {string} params.type - type of publications to retrieve. One
   *      of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   *      If params.type is not specified, all available publications
   *      are retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async select({
    type,
  }: {
    type?: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.publications.get;

    // used and set by app-support
    const isAdminMode = this.environment.isAdminMode ? true : false;

    const publisherUserId = PUBLISHER_USER_ID;
    const publicationsType = SHARED_OBJECT_TYPE_UI_TO_SERVER;
    const userId = this.environment.account.user?.id;

    if (type != null && type in publicationsType) {
      if (userId === publisherUserId && isAdminMode === false) {
        return Promise.resolve([]);
      }

      const paramsType = {
        objectType: publicationsType[type],
        ownerId: publisherUserId,
      };

      const response = await this.prepareGet(url, paramsType, null);
      const decodedResponse = this.decode(response);

      return decodedResponse;
      // return this.getDetails("publications", decodedResponse);
    }
  }

  /**
   *
   * @param {string} type - one of "publications" or "subscriptions"
   * @param {array} responseDecoded - array of
   * @returns
   */
  async fetch(
    type: keyof typeof SHARED_OBJECT_TYPE_SERVER_TO_UI,
    ids: number[],
    properties: string[]
  ) {
    if (ids.length === 0) {
      return ids;
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.commons.get;

    const pubSubTypeRaw = type;
    const pubSubIds = ids;
    let fields = properties.map((field) => ({ dimension: field }));

    const params = [
      {
        classType: pubSubTypeRaw,
        id: pubSubIds,
        extendedRequest: {
          onDemandResult: fields,
        },
      },
    ];

    const responseGet = await this.preparePost(url, params, null);
    const responseUnboxed = responseGet.data[0].rows;

    return responseUnboxed;
  }

  /**
   * Retrieves publications
   *
   * @param {object} params - request parameters
   * @param {string} params.type - type of publications to retrieve. One
   *      of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   *      If params.type is not specified, all available publications
   *      are retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async get({
    type,
  }: {
    type?: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  } = {}) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.publications.get;

    // used and set by app-support
    const isAdminMode = this.environment.isAdminMode ? true : false;

    const publisherUserId = PUBLISHER_USER_ID;
    const publicationsType = SHARED_OBJECT_TYPE_UI_TO_SERVER;
    const userId = this.environment.account.user?.id;

    if (type != null && type in publicationsType) {
      if (userId === publisherUserId && isAdminMode === false) {
        return Promise.resolve([]);
      }

      const paramsType = {
        objectType: publicationsType[type],
        ownerId: publisherUserId,
      };

      const response = await this.prepareGet(url, paramsType, null);
      const decodedResponse = this.decode(response);
      return this.getDetails("publications", decodedResponse);
    }

    if (userId === publisherUserId && isAdminMode === false) {
      const empty = {
        lists: [],
        rankings: [],
        strategies: [],
        systematicPortfolios: [],
      };

      return Promise.resolve(empty);
    }

    const paramsTypeLists = {
      objectType: publicationsType.list,
      ownerId: publisherUserId,
    };

    const paramsTypeRankings = {
      objectType: publicationsType.ranking,
      ownerId: publisherUserId,
    };

    const paramsTypeStrategies = {
      objectType: publicationsType.strategy,
      ownerId: publisherUserId,
    };

    const paramsTypeSystematicPortfolios = {
      objectType: publicationsType.systematicPortfolio,
      ownerId: publisherUserId,
    };

    const paramsTypeCombinedStrategies = {
      objectType: publicationsType.combinedStrategy,
      ownerId: publisherUserId,
    };

    const paramsTypeCombinedProducts = {
      objectType: publicationsType.combinedProduct,
      ownerId: publisherUserId,
    };

    const response = await httpAll({
      lists: this.prepareGet(url, paramsTypeLists, null),
      rankings: this.prepareGet(url, paramsTypeRankings, null),
      strategies: this.prepareGet(url, paramsTypeStrategies, null),
      systematicPortfolios: this.prepareGet(
        url,
        paramsTypeSystematicPortfolios,
        null
      ),
      combinedStrategies: this.prepareGet(
        url,
        paramsTypeCombinedStrategies,
        null
      ),
      combinedProducts: this.prepareGet(url, paramsTypeCombinedProducts, null),
    });

    const decodedResponse = this.decodeAll(response);
    return this.getDetailsAll("publications", decodedResponse);
  }

  /**
   * Retrieves publications
   *
   * @param {object} params - request parameters
   * @param {string} params.type - type of publications to retrieve. One
   *      of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   *      If params.type is not specified, all available publications
   *      are retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getById(
    type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER,
    id: number
  ) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.commons.objectGet;

    // used and set by app-support
    const isAdminMode = this.environment.isAdminMode ? true : false;

    const publisherUserId = PUBLISHER_USER_ID;
    const publicationsType = SHARED_OBJECT_TYPE_UI_TO_SERVER;
    const userId = this.environment.account.user?.id;

    if (type != null && type in publicationsType) {
      if (userId === publisherUserId && isAdminMode === false) {
        return Promise.resolve([]);
      }

      const params = { id };

      try {
        const response = await this.prepareGet(url, params, null);

        return response?.data?.preference?.object;
      } catch (error) {
        return error;
      }
    }
  }

  /**
   * Utility to check if a item is among publications
   *
   * @param {object} publication
   * @param {array}  publications
   *
   * @returns {boolean}
   */
  isPublication(item: any, publications: any) {
    for (let i = 0; i < publications.length; i++) {
      if (item.id === publications[i].id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Removes a publication
   *
   * @param {object} params - request parameters
   * @param {number} params.id - the id of the object to be removed as
   *      publication
   * @param {string} params.type - type of object to be removed as
   *      publication.
   *      One of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  remove({
    id,
    type,
  }: {
    id: number;
    type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.publications.remove;

    const objectType = SHARED_OBJECT_TYPE_UI_TO_SERVER[type];
    const paramsCreate = {
      objectId: id,
      objectType: objectType,
    };

    return this.preparePost(url, paramsCreate, null);
  }
}
