import { useCallback } from "react";

type ActionRankProps = {
  label?: string;
  openRankDialog: () => void;
};

export function ActionRank({
  label = "Rank",
  openRankDialog,
}: ActionRankProps) {
  const doAction = useCallback(() => {
    openRankDialog();
  }, [openRankDialog]);

  return (
    <>
      <li className="menu__item" onClick={doAction}>
        {label}
      </li>
    </>
  );
}
