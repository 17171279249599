import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ClusterAnalytics } from "../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../hooks/useFormatter";
import styles from "./Rank.module.scss";
import { ActionRankContext } from "./actions/ActionRankContext/ActionRankContext";

export function ClusterBricks() {
  const { rank, getCurrentState } = useContext(ActionRankContext);
  const environment = useEnvironment();
  const [clusterResults, setClusterResults] = useState();
  const formatter = useFormatter();
  const [currentSegment, setCurrentSegment] = useState<"markets" | "sector">();
  const [activeCluster, setActiveCluster] = useState();
  // const [currentConstraints, setCurrentConstraints] = useState<any>();

  const templateConstraints = useRef(getCurrentState().constraints);

  const clustersAPI = useMemo(() => {
    const appEnvironment = environment.get("setup");
    return new ClusterAnalytics(appEnvironment);
  }, [environment]);
  const instrumentsAPI = useMemo(() => {
    const appEnvironment = environment.get("setup");
    return new Instruments(appEnvironment);
  }, [environment]);

  // const takeSnapShot = useCallback(() => {
  //   const currentUiState = getCurrentState().constraints;

  //   setCurrentConstraints(currentUiState);
  // }, [getCurrentState]);

  const backToTemplateRank = useCallback(() => {
    rank({
      page: 1,
      sortField: "rank",
      rev: false,
      constraints: templateConstraints.current,
    });
  }, [rank]);

  const clusterizeResults = useCallback(
    async (segment?: "markets" | "sector") => {
      setActiveCluster(undefined);

      if (!segment) {
        setClusterResults(undefined);
        setCurrentSegment(undefined);

        backToTemplateRank();

        return;
      }

      const currentUniverseConstraints = templateConstraints.current;

      const filters =
        "constraints" in currentUniverseConstraints
          ? currentUniverseConstraints
          : instrumentsAPI.convertToIndexBuilderSyntax(
              currentUniverseConstraints
            );

      // Override page to get all the titles base on constraints (required by the cluster Analytics)
      filters["page"] = { page: 1, rows: 20000 };

      const taxonSegment = segment === "markets" ? "Country" : "1 Industry";

      // takeSnapShot();

      const clusterConfiguration = await clustersAPI
        .createConfiguration()
        .segment(taxonSegment, true)
        .method("INTERSECTION")
        .analytics([])
        .universeFromConstraints(filters)
        .fetchAnalytics();

      setClusterResults(clusterConfiguration?.clustersStats?.clusters);
      setCurrentSegment(segment);
    },
    [backToTemplateRank, clustersAPI, instrumentsAPI]
  );

  const rankCluster = useCallback(
    (clusterKey) => {
      if (activeCluster === clusterKey) {
        setActiveCluster(undefined);

        rank({
          page: 1,
          sortField: "rank",
          rev: false,
          constraints: templateConstraints.current,
        });

        return;
      }

      setActiveCluster(clusterKey);

      const newUniverse = {
        constraints: [
          [
            {
              operator: "equals",
              dimension: "symbol",
              segments: clusterResults?.[clusterKey] ?? [],
            },
          ],
        ],
      };

      rank({
        page: 1,
        sortField: "rank",
        rev: false,
        constraints: newUniverse as any,
      });
    },
    [activeCluster, clusterResults, rank]
  );

  return (
    <Box
      p={1}
      width={"100%"}
      className={styles.clustersBrickRowContainer}
      gap={1}
    >
      <SegmentSelector onSegmentChange={clusterizeResults} />
      <Box className={styles.listScrollable}>
        {clusterResults ? (
          Object.entries<any>(clusterResults).map(([key, value]) => {
            const property = currentSegment === "markets" ? "country" : "icb";
            const cell = { [property]: key };
            const isActive = key === activeCluster;
            return (
              <Box
                key={uuidv4()}
                className={`${styles.listItemCardContent} ${
                  isActive ? styles.activeCluster : ""
                }`}
                onClick={() => rankCluster(key)}
              >
                <Box
                  display={"flex"}
                  gap={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {property === "country" && (
                    <img alt="market-flag" src={`/img/flags/${key}.png`} />
                  )}
                  <Typography
                    sx={{ textWrap: "nowrap" }}
                    dangerouslySetInnerHTML={{
                      __html: formatter.table(property, "table", cell),
                    }}
                  ></Typography>
                  <Typography>
                    <strong>({value.length})</strong>
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

const SegmentSelector = ({ onSegmentChange }) => {
  const [segment, setSegment] = useState<"markets" | "sector">();

  const toggleSegment = useCallback(
    (currentSegment, newSegment) => {
      let sgtm = undefined;
      if (currentSegment === newSegment) {
        sgtm = undefined;
      } else {
        sgtm = newSegment;
      }

      onSegmentChange(sgtm);

      return sgtm;
    },
    [onSegmentChange]
  );

  const selectMarkets = useCallback(() => {
    setSegment((current) => toggleSegment(current, "markets"));
  }, [toggleSegment]);

  const selectSectors = useCallback(() => {
    setSegment((current) => toggleSegment(current, "sector"));
  }, [toggleSegment]);

  return (
    <Box className={styles.segmentSelectorBox}>
      <Card
        onClick={selectMarkets}
        className={`${styles.listItemCard} ${
          segment === "markets" ? styles.cardActive : ""
        }`}
      >
        <CardContent className={styles.listItemCardContent}>
          <Typography>
            <strong>Markets</strong>
          </Typography>
        </CardContent>
      </Card>
      <Card
        onClick={selectSectors}
        className={`${styles.listItemCard} ${
          segment === "sector" ? styles.cardActive : ""
        }`}
      >
        <CardContent className={styles.listItemCardContent}>
          <Typography>
            <strong>Sectors</strong>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
