export type QuarterlyAnalyticsType = {
  headline: {
    content: string;
    isEnabled: boolean;
  };
  maxDrawdown: {
    content: string;
    isEnabled: boolean;
  };
  quarterlyPerformance: {
    content: string;
    isEnabled: boolean;
  };
  quarterlyStandardDeviation: {
    content: string;
    isEnabled: boolean;
  };
  years: string;
};

export type ActionType = {
  type:
    | "SET_headline_CONTENT"
    | "SET_headline_ISENABLED"
    | "SET_maxDrawdown_CONTENT"
    | "SET_maxDrawdown_ISENABLED"
    | "SET_quarterlyPerformance_CONTENT"
    | "SET_quarterlyPerformance_ISENABLED"
    | "SET_quarterlyStandardDeviation_CONTENT"
    | "SET_quarterlyStandardDeviation_ISENABLED"
    | "SET_years";
  payload: any;
};

export const quarterlyAnalyticsReducer = (draft: QuarterlyAnalyticsType, action: ActionType) => {
  switch (action.type) {
    case "SET_headline_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_headline_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_maxDrawdown_CONTENT":
      draft.maxDrawdown.content = action.payload;
      break;
    case "SET_maxDrawdown_ISENABLED":
      draft.maxDrawdown.isEnabled = action.payload;
      break;
    case "SET_quarterlyPerformance_CONTENT":
      draft.quarterlyPerformance.content = action.payload;
      break;
    case "SET_quarterlyPerformance_ISENABLED":
      draft.quarterlyPerformance.isEnabled = action.payload;
      break;
    case "SET_quarterlyStandardDeviation_CONTENT":
      draft.quarterlyStandardDeviation.content = action.payload;
      break;
    case "SET_quarterlyStandardDeviation_ISENABLED":
      draft.quarterlyStandardDeviation.isEnabled = action.payload;
      break;
    case "SET_years":
      draft.years = action.payload;
      break;
    default:
      break;
  }
};
