import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Instruments } from "../../../../../api/compute/Instruments";
import { Publications } from "../../../../../api/compute/Publications";
import { SortableBricks } from "../../../../../components/SortableBricks/SortableBricks";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { FiltersContext } from "../FilterBar/FilterRowContraints";
import { deepClone } from "../../../../../deepClone";
import styles from "./SavedTemplatesBasr.module.scss";

type SavedTemplatesBarProps = {
  currentTab: string;
  onSetActiveTemplate: (value) => void;
  getActiveTemplate: () => { id: number; name: string } | undefined;
  clearFilters: () => void;
};

type DropDownListProps = {
  menuOptions: { groupId: string; groupLabel: string; screens: any[] }[];
  loadFromPopular: Function;
  currentTab: string;
};

const PREFERENCE_INFO = {
  page: "screening",
  orderProperty: "savedScreeningOrder",
};

const SavedTemplatesBar = memo(
  ({
    currentTab,
    getActiveTemplate,
    onSetActiveTemplate,
    clearFilters,
  }: SavedTemplatesBarProps) => {
    const context = useContext(FiltersContext);
    const environment = useEnvironment();
    const appEnvironment = environment.get("setup");
    const rawTaxonomies = environment.get("rawTaxonomies");
    const taxonomyFields = environment.get("taxonomyFields");
    const configuration = environment.get("account")["product"];

    const isPopularScreeningEnabled = useMemo(() => {
      return configuration["configuration"]["screening"].tabs[currentTab][
        "popular"
      ];
    }, [configuration, currentTab]);

    const pageStatusAPI = useMemo(
      () => environment.get("preferenceStatus"),
      [environment]
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loadingMenu, setLoadingMenu] = useState(true);
    const [popularScreens, setPopularScreens] = useState<any>([]);
    const [popularActive, setPopularActive] = useState<any>();

    const open = Boolean(anchorEl);

    const publicationsAPI = useMemo(
      () => new Publications(environment.get("setup")),
      [environment]
    );

    const menuOptions = useMemo(() => {
      let popularScreensFiltered = deepClone(popularScreens);

      popularScreensFiltered = popularScreensFiltered.filter(
        (screen) => screen.assetClass === currentTab.toLowerCase()
      );

      const options: { groupId: string; groupLabel: string; screens: any[] }[] =
        [];

      let grouped = {};

      let constraints: any = null;
      let segments: any = null;
      let firstSegment: any = null;
      let node: any = null;

      const type = currentTab === "ETF" ? "ETF" : "security";
      const field = currentTab === "ETF" ? "etfgeo" : "country";

      const taxonomies = rawTaxonomies[taxonomyFields[type][field]];

      for (const screening of popularScreensFiltered) {
        constraints = screening?.["data"]?.["constraints"]?.[0] ?? null;

        if (constraints != null && constraints.length > 0) {
          for (const constraint of constraints) {
            if (constraint.dimension === "country") {
              segments = constraint.segments;

              if (segments.length === 1) {
                if (segments[0] in grouped && grouped[segments[0]] != null) {
                  grouped[segments[0]].push(screening);
                } else {
                  grouped[segments[0]] = [];
                  grouped[segments[0]].push(screening);
                }
              } else if (segments.length > 1) {
                firstSegment = segments[0];
                node = taxonomies[firstSegment];

                if (node.parent in grouped && grouped[node.parent] != null) {
                  grouped[node.parent].push(screening);
                } else {
                  grouped[node.parent] = [];

                  grouped[node.parent].push(screening);
                }
              }
            }
          }
        }
      }

      for (const key in grouped) {
        options.push({
          groupId: key,
          groupLabel: taxonomies[key].name,
          screens: grouped[key],
        });
      }

      options.sort((a: any, b: any) => {
        a = a.groupLabel;
        b = b.groupLabel;

        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        }

        return 0;
      });

      return options;
    }, [currentTab, popularScreens, rawTaxonomies, taxonomyFields]);

    const getPopularScreens = useCallback(
      async (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setLoadingMenu(true);

        try {
          const response = await publicationsAPI.select({ type: "screening" });
          const fetchResponse = await publicationsAPI.fetch(
            "PREFERENCE",
            response.map((item) => item.id),
            ["object"]
          );

          let opts: any = [];

          for (const templ of fetchResponse) {
            opts.push({
              data: templ.object.data,
              assetClass: templ.object.assetClass,
              name: templ.object.name,
              id: templ.id,
              ownerId: templ.object.ownerId,
            });
          }

          setPopularScreens(
            opts.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }

              return 0;
            })
          );
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingMenu(false);
        }
      },
      [publicationsAPI]
    );

    const handleClose = () => {
      setAnchorEl(null);
    };

    const instrumentsApi = useMemo(
      () => new Instruments(appEnvironment),
      [appEnvironment]
    );
    const [templates, setTemplates] = useState<
      {
        id: number;
        name: string;
        assetClass: string;
        data: any;
        ownerId: number;
      }[]
    >([]);
    const [activeTemplate, setActiveTemplate] = useState(getActiveTemplate());
    const { on, remove } = useEventBus();

    const templateList = useMemo(() => {
      return templates.map((item) => ({
        id: item.id,
        name: item.name,
        ownerId: item.ownerId,
      }));
    }, [templates]);

    useEffect(() => {
      // On Tab change
      setActiveTemplate(undefined);
    }, [currentTab]);

    const getPreferences = useCallback(async () => {
      try {
        const rawPreferences = await instrumentsApi.getScreeningTemplates();
        const preferences = rawPreferences
          .map((preference) => ({
            data: preference.data,
            assetClass: preference.assetClass,
            name: preference.name,
            id: preference.id,
            ownerId: preference.ownerId,
          }))
          .filter(
            (preference) => preference.assetClass === currentTab.toLowerCase()
          );

        const order = await pageStatusAPI.get([
          PREFERENCE_INFO.page,
          currentTab,
          PREFERENCE_INFO.orderProperty,
        ]);

        if (order) {
          let aId: any = undefined;
          let bId: any = undefined;
          let aIndex: any = undefined;
          let bIndex: any = undefined;

          preferences.sort((a, b) => {
            aId = a.id;
            bId = b.id;

            if (aId in order && bId in order) {
              aIndex = order[aId];
              bIndex = order[bId];

              return aIndex > bIndex ? 1 : -1;
            }

            return aId in order > (bId in order) ? -1 : 1;
          });
        }

        setTemplates(preferences);
      } catch (error) {
        console.log(error);
      }
    }, [currentTab, instrumentsApi, pageStatusAPI]);

    const saveTemplatesOrder = useCallback(
      async (items) => {
        const orderMap = items.reduce((prev, current, idx) => {
          prev[current.id] = idx;

          return prev;
        }, {});

        await pageStatusAPI.patch(
          [PREFERENCE_INFO.page, currentTab, PREFERENCE_INFO.orderProperty],
          orderMap
        );
      },
      [currentTab, pageStatusAPI]
    );

    const onTemplateDeleted = useCallback(() => {
      getPreferences();
      setActiveTemplate(undefined);
      onSetActiveTemplate(undefined);
    }, [getPreferences, onSetActiveTemplate]);

    /**
     * Assign those functions to variable to create a reference needed to remove the handlers on events when component unmount
     * this prevent extra server calls
     */
    const onResourceSaved = useCallback(() => {
      getPreferences();
    }, [getPreferences]);

    const onResourceCreated = useCallback(() => {
      getPreferences();
    }, [getPreferences]);

    const onResourceDeleted = useCallback(() => {
      onTemplateDeleted();
    }, [onTemplateDeleted]);

    useEffect(() => {
      on("resource-saved", onResourceSaved);
      on("resource-created", onResourceCreated);
      on("resource-deleted", onResourceDeleted);

      return () => {
        remove("resource-saved", onResourceSaved);
        remove("resource-created", onResourceCreated);
        remove("resource-deleted", onResourceDeleted);
      };
    }, [on, onResourceCreated, onResourceDeleted, onResourceSaved, remove]);

    const releaseTemplate = useCallback(() => {
      setActiveTemplate(undefined);
      onSetActiveTemplate(null);
      clearFilters();
    }, [onSetActiveTemplate, clearFilters]);

    const loadTemplate = useCallback(
      (templateId: number) => {
        if (!templateId) {
          return;
        }

        if (popularActive != null) {
          setTemplates((currentTemplates) => {
            return currentTemplates.filter(
              (template) => template.id !== popularActive
            );
          });
        }

        if (activeTemplate && templateId === activeTemplate.id) {
          releaseTemplate();
          return;
        }

        const template = templates.find((item) => item.id === templateId);
        if (template && template.data) {
          const userId = environment.get("account")["user"]["id"];
          const isReadOnly = template.ownerId !== userId;
          setActiveTemplate({ id: template.id, name: template.name });
          context?.loadTemplates(template.data);
          onSetActiveTemplate({
            id: template.id,
            name: template.name,
            isReadOnly,
          });
        }
      },
      [
        activeTemplate,
        context,
        environment,
        onSetActiveTemplate,
        popularActive,
        releaseTemplate,
        templates,
      ]
    );

    const loadFromPopular = useCallback(
      (template) => {
        setTemplates((currentTmpls) => {
          currentTmpls.unshift(template);

          if (popularActive) {
            return [...currentTmpls].filter(
              (screen) => screen.id !== popularActive
            );
          } else {
            return [...currentTmpls];
          }
        });

        if (template && template.data) {
          setPopularActive(template.id);
          setActiveTemplate({ id: template.id, name: template.name });
          context?.loadTemplates(template.data);
          onSetActiveTemplate({
            id: template.id,
            name: template.name,
            isReadOnly: true,
          });
        }

        handleClose();
      },
      [context, onSetActiveTemplate, popularActive]
    );

    useEffect(() => {
      getPreferences();

      return () => setTemplates([]);
    }, [getPreferences]);

    const showRow = useMemo(
      () =>
        (templates != null && templates.length) || isPopularScreeningEnabled,
      [isPopularScreeningEnabled, templates]
    );

    return showRow ? (
      <Box component={"ul"} className={styles.bar}>
        {isPopularScreeningEnabled && (
          <>
            <Box p={1} borderRight={"1px solid #ddd"}>
              <Button
                variant="tr_button_cancel"
                onClick={getPopularScreens}
                onMouseEnter={getPopularScreens}
                sx={{
                  border: "1px solid #2a7090",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "5px",
                  padding: "5px 2px",
                  cursor: "pointer",
                }}
              >
                <LanguageIcon sx={{ color: "#777" }} />
                <Typography sx={{ color: "black" }}>Popular screens</Typography>
                <Box
                  borderLeft={"1px solid #2a7090"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MoreVertIcon sx={{ color: "#2a7090" }} />
                </Box>
              </Button>
            </Box>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box display={"flex"} flex={1} onMouseLeave={handleClose}>
                {loadingMenu ? (
                  <Box
                    p={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <CircularProgress sx={{ color: "#2a7090" }} />
                  </Box>
                ) : (
                  <DropDownList
                    menuOptions={menuOptions}
                    loadFromPopular={loadFromPopular}
                    currentTab={currentTab}
                  />
                )}
              </Box>
            </Menu>
          </>
        )}
        <SortableBricks
          list={templateList}
          onClickBrick={loadTemplate}
          disabled={false}
          selectedBrickId={activeTemplate?.id}
          onDropFinish={saveTemplatesOrder}
        />
      </Box>
    ) : (
      <></>
    );
  }
);

const DropDownList = memo(function DropDownList({
  menuOptions,
  loadFromPopular,
  currentTab,
}: DropDownListProps) {
  return menuOptions.length ? (
    <Box display={"flex"} flexDirection={"column"} p={1} gap={1}>
      {menuOptions.map((optionsGroup) => {
        return (
          <Box key={uuidv4()} display={"flex"} flexDirection={"column"}>
            <Typography sx={{ fontWeight: "bold" }}>
              {optionsGroup.groupLabel}
            </Typography>
            <Box component={"ul"} display={"flex"} flexDirection={"column"}>
              {optionsGroup.screens.map((item) => (
                <MenuItem
                  key={uuidv4()}
                  onClick={() => loadFromPopular(item)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    paddingLeft: "15px",
                  }}
                >
                  <span className="sharedObjectIndicator"></span>
                  <Typography>{item.name}</Typography>
                </MenuItem>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  ) : (
    <Box p={1}>
      <Typography>No popular screen available for {currentTab}</Typography>
    </Box>
  );
});

export default SavedTemplatesBar;
