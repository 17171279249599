export type AllocationType = {
  weightInCashMin: number | null;
  weightInCashMax: number | null;

  weightCappingPeer: null | {
    peerLevel: string | null;
    // weightCappedMin: any;
    weightCappedMax: number | null;
    weightCappedMethod: any;
  };
  weightCappingSecurity: null | {
    weightCappedMax: number | null;
    weightCappedMin: number | null;
  };
};
export const initAllocationState = {
  weightInCashMin: null,
  weightInCashMax: null,
  weightCappingPeer: null,
  // {
  //   peerLevel: null,
  //   weightCappedMin: null,
  //   weightCappedMax: null,
  //   weightCappedMethod: null,
  // },
  weightCappingSecurity: null,
  // {
  //   weightCappedMax: null,
  //   weightCappedMin: null,
  // },
};
type AllocationActionType = {
  payload: any;
  type:
    | "SET_STATE"
    | "SET_WEIGHT_IN_CASH_MIN"
    | "SET_CAPPING_SECURITY_TO_NULL"
    | "SET_CAPPING_SECURITY_MAX"
    | "SET_CAPPING_SECURITY_MIN"
    | "INIT_CAPPING_SECURITY"
    | "INIT_CAPPING_PEER"
    | "SET_CAPPING_PEER_TO_NULL"
    | "SET_CAPPING_PEER_PEERLEVEL"
    | "SET_CAPPING_PEER_CAPPEDMETHOD"
    | "SET_CAPPING_PEER_MIN"
    | "SET_WEIGHT_IN_CASH_MAX"
    | "SET_CAPPING_PEER_MAX";
};
export function AllocationReducer(draft: AllocationType, action: AllocationActionType) {
  switch (action.type) {
    case "SET_STATE":
      return action.payload;
    case "SET_WEIGHT_IN_CASH_MIN":
      draft.weightInCashMin = action.payload;
      break;
    case "SET_WEIGHT_IN_CASH_MAX":
      draft.weightInCashMax = action.payload;
      break;
    case "SET_CAPPING_SECURITY_TO_NULL":
      draft.weightCappingSecurity = null;
      return;
    case "INIT_CAPPING_SECURITY":
      draft.weightCappingSecurity = {
        weightCappedMax: 0.2,
        weightCappedMin: 0.01,
      };
      break;
    case "SET_CAPPING_SECURITY_MAX":
      if (draft.weightCappingSecurity) {
        draft.weightCappingSecurity!.weightCappedMax = action.payload;
      }
      break;
    case "SET_CAPPING_SECURITY_MIN":
      if (draft.weightCappingSecurity) {
        draft.weightCappingSecurity.weightCappedMin = action.payload;
      }
      break;
    case "INIT_CAPPING_PEER":
      draft.weightCappingPeer = {
        peerLevel: action.payload.peerLevel ?? "Country",
        weightCappedMax: action.payload.weightCappedMax ?? null,
        weightCappedMethod: action.payload.weightCappedMethod ?? null,
        // weightCappedMin: action.payload.weightCappedMin ?? 0,
      };
      break;
    case "SET_CAPPING_PEER_TO_NULL":
      draft.weightCappingPeer = null;
      break;
    case "SET_CAPPING_PEER_PEERLEVEL":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.peerLevel = action.payload;
      }
      break;
    case "SET_CAPPING_PEER_CAPPEDMETHOD":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.weightCappedMethod = action.payload;
      }
      break;
    // case "SET_CAPPING_PEER_MIN":
    //   if (draft.weightCappingPeer) {
    //     draft.weightCappingPeer!.weightCappedMin = action.payload;
    //   }
    //   break;
    case "SET_CAPPING_PEER_MAX":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.weightCappedMax = action.payload;
      }
      break;
    default:
      break;
  }
}
