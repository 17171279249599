import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
import MinMaxInputNumer from "./MinMaxInputNumer";
import { Rotation } from "./Rotation";
import { Strategy } from "./editorReducer";

type MacroRotationRulesProps = {
  strategy: Strategy;
  dispatch: Function;
  more?: boolean;
};

export default function MacroRotationRules({
  strategy,
  dispatch,
  more,
}: MacroRotationRulesProps) {
  const [rotationsRuleState, setRotatiosRulesState] = useImmer({
    rotationFactor: "FACTOR_MOMENTUM",
    rotationValue: "Country",
    // neutralLabelValue: "Market",
    isSubPortfolioCapped: false,
    cappingValue: 0.01,
  });

  const options = Rotation.get("OPTIONS_EXTENDED", null);
  const nestedSelectValue = useMemo(
    () => strategy?.weighting?.rotation?.rotate ?? "Country__3 Sector",
    [strategy?.weighting?.rotation?.rotate]
  );

  useEffect(() => {
    const {
      rotationFactor,
      isSubPortfolioCapped,
      cappingValue,
      rotationValue,
    } = rotationsRuleState;

    dispatch({
      type: "SET_ROTATION",
      payload: rotationValue,
    });

    dispatch({ type: "SET_ROTATION_FACTOR", payload: rotationFactor });

    if (isSubPortfolioCapped) {
      dispatch({
        type: "SET_SUB_PORTFOLIO_CAPPING_PEER",
        payload: {
          peerLevel: rotationValue,
          weightCappedMin: null,
          weightCappedMax: cappingValue,
        },
      });
    } else {
      dispatch({ type: "SET_SUB_PORTFOLIO_CAPPING_PEER", payload: null });
    }
  }, [rotationsRuleState, dispatch]);

  /**
   * Sync global state with internal state
   */
  useEffect(() => {
    setRotatiosRulesState((draft) => {
      draft.rotationFactor = strategy.weighting.rotation!.factor;
      draft.rotationValue = strategy.weighting.rotation!.rotate;
      draft.isSubPortfolioCapped =
        strategy.allocation.weightCappingPeer !== null;
      draft.cappingValue =
        strategy.allocation.weightCappingPeer?.weightCappedMax ?? 0.01;
    });
  }, [setRotatiosRulesState, strategy]);

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">2</span> Rotation rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <label>Rotate</label>{" "}
          <Select
            size="small"
            value={nestedSelectValue}
            onChange={(e) => {
              let value = e.target.value;
              if (value !== nestedSelectValue) {
                setRotatiosRulesState((draft) => {
                  draft.rotationValue = value;
                });
              }
            }}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </li>
        <li className="form__field">
          <FormControl>
            <Typography>Rotation factor</Typography>
            <RadioGroup
              onChange={(e) => {
                let value = e.target.value;
                setRotatiosRulesState((draft) => {
                  draft.rotationFactor = value;
                });
              }}
              value={rotationsRuleState.rotationFactor}
              row
            >
              <FormControlLabel
                value={"FACTOR_MOMENTUM"}
                control={<Radio />}
                label={<Typography>Momentum</Typography>}
              />
              <FormControlLabel
                value={"FACTOR_MOMENTUM_GROWTH"}
                control={<Radio />}
                label={<Typography>Momentum growth</Typography>}
              />
              <FormControlLabel
                value={"FACTOR_MARKET_CAP_NEUTRAL"}
                control={<Radio />}
                label={
                  <Typography>
                    {`${Rotation.get(
                      "LABEL_LOOKUP",
                      rotationsRuleState.rotationValue
                    )} neutral`}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </li>
        <CustomFieldCollapser
          isOpen={more ?? false}
          setIsOpen={(e) =>
            dispatch({ type: "MORE_ROTATION_RULES", payload: e })
          }
        >
          <li>
            <div className="form__more">
              <div className="form__field">
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ m: 0 }}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            let isCapped = e.target.checked;
                            setRotatiosRulesState((draft) => {
                              draft.isSubPortfolioCapped = isCapped;
                            });
                          }}
                          checked={rotationsRuleState.isSubPortfolioCapped}
                        />
                      }
                      label={
                        <Typography>
                          Sub-portfolio % weight capped at
                        </Typography>
                      }
                    />
                  </FormGroup>
                  <MinMaxInputNumer
                    step={0.01}
                    value={parseFloat(
                      (rotationsRuleState.cappingValue * 100).toFixed(2)
                    )}
                    disabled={!rotationsRuleState.isSubPortfolioCapped}
                    min={1}
                    max={100}
                    stateSetter={(value) => {
                      setRotatiosRulesState((draft) => {
                        draft.cappingValue = parseFloat(
                          parseFloat((value / 100).toString()).toFixed(5)
                        );
                      });
                    }}
                  />
                </Box>
              </div>
            </div>
          </li>
        </CustomFieldCollapser>
      </ul>
    </fieldset>
  );
}
