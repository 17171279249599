/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyYearToDateAnalytics
 * @summary Strategy - Year To Date Analytics
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity, substituteVariables } from "./Generator";

function findLastValidYear(data) {
  // Find the maximum year, use this year as fallback
  let dataThisYear = 0;
  for (let i = 0, length = data.length; i < length; i++) {
    const item = data[i];
    const timeFrame = item["timeFrame"].split("_");
    const year = parseInt(timeFrame[0], 10);
    if (dataThisYear < year) {
      dataThisYear = year;
    }
  }
  let thisYear = String(new Date().getUTCFullYear()); // fallback
  if (dataThisYear !== 0) {
    thisYear = String(dataThisYear);
  }
  return thisYear;
}

export function sectionsYearToDateAnalytics(input, section, datum, formatter) {
  const _sections: any = [];

  if (section["content"]["headline"]["isEnabled"]) {
    const _section = {
      data: {
        text: escapeEntity(datum["headline"].toUpperCase()),
      },
      type: "header1",
    };
    _sections.push(_section);
  }
  if (section["presentation"]["table"] === "ROWS") {
    const _section = sectionsYearToDateAnalyticsDetailByRows(
      input,
      section,
      datum,
      formatter
    );
    _sections.push(_section);
  } else {
    if (section["content"]["monthlyPerformance"]["isEnabled"]) {
      if (section["content"]["monthlyPerformance"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["monthlyPerformance"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearToDateAnalyticsDetail(
        input,
        section,
        datum,
        "strategyMonthlyPerformance",
        "benchmarkMonthlyPerformance",
        "deltaMonthlyPerformance",
        formatter
      );
      _sections.push(_section);
    }
    if (section["content"]["maxDrawdown"]["isEnabled"]) {
      if (section["content"]["maxDrawdown"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["maxDrawdown"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearToDateAnalyticsDetail(
        input,
        section,
        datum,
        "strategyMaxDrawdown",
        "benchmarkMaxDrawdown",
        "deltaMaxDrawdown",
        formatter
      );
      _sections.push(_section);
    }
    if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
      if (section["content"]["monthlyStandardDeviation"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["monthlyStandardDeviation"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearToDateAnalyticsDetail(
        input,
        section,
        datum,
        "strategyVolatility",
        "benchmarkVolatility",
        "deltaVolatility",
        formatter
      );
      _sections.push(_section);
    }
  }

  return _sections;
}

function sectionsYearToDateAnalyticsDetailByRows(
  input,
  section,
  data,
  formatter
) {
  const format = formatter;

  const thisYear = findLastValidYear(data["analytics"]["monthly"]);

  const dates: any = [];
  for (
    let i = 0, length = data["analytics"]["monthly"].length;
    i < length;
    i++
  ) {
    const item = data["analytics"]["monthly"][i];
    const timeFrame = item["timeFrame"].split("_");
    if (timeFrame[0] === thisYear) {
      dates.push(item);
    }
  }

  // Table has two headers
  const table: any = {
    data: {
      body: [],
      head: [[], []],
    },
    type: "table",
  };
  // Used to put the title of the _section over portfolio/benchmark/delta
  // depending on what is enabled
  let alreadyHaveRowPadding = false;

  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];

  let hasAlreadyCreatedMonth = false;

  // Head setup
  if (section["content"]["monthlyPerformance"]["isEnabled"]) {
    if (!hasAlreadyCreatedMonth) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: {
          color: "#000000",
        },
        value: "Month",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["monthlyPerformance"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }

      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedMonth = true;
  }

  if (section["content"]["maxDrawdown"]["isEnabled"]) {
    if (!hasAlreadyCreatedMonth) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: null,
        value: "Month",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["maxDrawdown"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedMonth = true;
  }

  if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
    if (!hasAlreadyCreatedMonth) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: null,
        value: "Month",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["monthlyStandardDeviation"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedMonth = true;
  }

  for (let i = 0; i < dates.length; i++) {
    const item = dates[i];
    const itemTimeFrame = item["timeFrame"].split("_");
    const date = new Date(
      Date.UTC(
        parseInt(itemTimeFrame[0], 10),
        parseInt(itemTimeFrame[1], 10) - 1,
        1
      )
    );
    const timeFrame = format.custom("date", {
      options: {
        format: ["M"],
        isMillisecond: true,
        notAvailable: {
          input: "",
          output: "",
        },
        separator: " ",
      },
      output: "HTML",
      value: date.getTime(),
      valueHelper: null,
    });

    const tableRow: any = [];
    hasAlreadyCreatedMonth = false; // Reset state
    if (section["content"]["monthlyPerformance"]["isEnabled"]) {
      if (!hasAlreadyCreatedMonth) {
        tableRow.push({
          style: null,
          value: timeFrame,
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyMonthlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkMonthlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaMonthlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedMonth = true;
    }

    if (section["content"]["maxDrawdown"]["isEnabled"]) {
      if (!hasAlreadyCreatedMonth) {
        tableRow.push({
          style: null,
          value: timeFrame,
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedMonth = true;
    }

    if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
      if (!hasAlreadyCreatedMonth) {
        tableRow.push({
          style: null,
          value: timeFrame,
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyVolatility"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkVolatility"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaVolatility"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedMonth = true;
    }

    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  return table;
}

function sectionsYearToDateAnalyticsDetail(
  input,
  section,
  data,
  strategyField,
  benchmarkField,
  deltaField,
  formatter
) {
  const thisYear = findLastValidYear(data["analytics"]["monthly"]);

  const dates: any = [];
  for (let i = 0; i < data["analytics"]["monthly"].length; i++) {
    const item = data["analytics"]["monthly"][i];
    const timeFrame = item["timeFrame"].split("_");
    if (timeFrame[0] === thisYear) {
      dates.push(item);
    }
  }

  // Get the actual

  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [[]],
    },
    type: "table",
  };
  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];
  // Head setup
  table["data"]["head"][0].push({
    style: null,
    value: "",
  });

  // Build header with all months
  for (let i = 1; i <= 12; i++) {
    const date = new Date(Date.UTC(parseInt(thisYear), i - 1, 1));
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: format.custom("date", {
        options: {
          format: ["M"],
          isMillisecond: true,
          notAvailable: {
            input: "",
            output: "",
          },
          separator: " ",
        },
        output: "HTML",
        value: date.getTime(),
        valueHelper: null,
      }),
    });
  }

  if (hasStrategy) {
    const tableRow: any = [];
    // Fill all months
    for (let i = 1; i <= 12; i++) {
      tableRow[i] = {
        style: null,
        value: "",
      };
    }
    tableRow[0] = {
      style: null,
      value: section["content"]["strategy"]["isEnabled"]
        ? substituteVariables(
            section["content"]["strategy"]["content"],
            input["target"]
          )
        : "Strategy",
    };
    for (let i = 0; i < dates.length; i++) {
      const item = dates[i];
      const timeFrame = item["timeFrame"].split("_");
      const monthIndex = parseInt(timeFrame[1], 10);
      tableRow[monthIndex] = {
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[strategyField],
          valueHelper: null,
        }),
      };
    }
    table["data"]["body"].push(tableRow);
  }

  if (hasBenchmark && benchmarkField != null) {
    const tableRow: any = [];
    for (let i = 1; i <= 12; i++) {
      tableRow[i] = {
        style: null,
        value: "",
      };
    }
    tableRow[0] = {
      style: null,
      value: section["content"]["benchmark"]["isEnabled"]
        ? substituteVariables(
            section["content"]["benchmark"]["content"],
            data["benchmark"]
          )
        : "Benchmark",
    };
    for (let i = 0; i < dates.length; i++) {
      const item = dates[i];
      const timeFrame = item["timeFrame"].split("_");
      const monthIndex = parseInt(timeFrame[1], 10);
      tableRow[monthIndex] = {
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[benchmarkField],
          valueHelper: null,
        }),
      };
    }
    table["data"]["body"].push(tableRow);
  }
  if (hasDelta && deltaField != null) {
    const tableRow: any = [];
    for (let i = 1; i <= 12; i++) {
      tableRow[i] = {
        style: null,
        value: "",
      };
    }
    tableRow[0] = {
      style: null,
      value: section["content"]["delta"]["content"],
    };
    for (let i = 0; i < dates.length; i++) {
      const item = dates[i];
      const timeFrame = item["timeFrame"].split("_");
      const monthIndex = parseInt(timeFrame[1], 10);
      tableRow[monthIndex] = {
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[deltaField],
          valueHelper: null,
        }),
      };
    }
    table["data"]["body"].push(tableRow);
  }
  return table;
}
