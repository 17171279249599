import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type YearRangeProps = {
  input: number | null;
  onChangeValue: Function;
};
const YearRange = ({ input, onChangeValue }: YearRangeProps) => {
  return (
    <FormControl fullWidth sx={{}}>
      <InputLabel
        size="small"
        sx={{
          fontSize: "0.7vw",
          "&.Mui-focused ": {
            color: "#2a7092 !important",
          },
        }}
        id="demo-simple-select-label"
      >
        years
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={input ?? 8}
        label={"input"}
        onChange={(e) => onChangeValue(e.target.value)}
        size="small"
        sx={{
          minWidth: "100px",
          fontSize: "0.7vw",
          "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2a7092 !important",
          },
        }}
      >
        <MenuItem sx={{ fontSize: "0.7vw" }} value={5}>
          5 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={6}>
          6 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={7}>
          7 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={8}>
          8 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={10}>
          10 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={12}>
          12 years
        </MenuItem>
        <MenuItem sx={{ fontSize: "0.7vw" }} value={15}>
          15 years
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default YearRange;
