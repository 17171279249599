import { useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { UpgradesDowngradesWidget } from "./UpgradesDowngradesWidget";
import { RadioButtonBar } from "../../../../../../../components/RadioButtonBar/RadioButtonBar";

type UpgradesDowngradesBlockProps = {
  dataList1: any;
  dataList2: any;
  onChangeTimeframe: (timeframe) => void;
};

export function UpgradesDowngradesBlock({
  onChangeTimeframe,
  dataList1,
  dataList2,
}: UpgradesDowngradesBlockProps) {
  const [timeframe, setTimeframe] = useState("today");
  const [showContent, setShowContent] = useState(true);

  const refUpgrades1 = useRef();
  const refUpgrades2 = useRef();
  const refDowngrades1 = useRef();
  const refDowngrades2 = useRef();

  const equalizeBoxHeights = (
    firstBlockRef,
    secondBlockRef,
    length1,
    lenght2
  ) => {
    const firstBlock: any = firstBlockRef?.current ?? null;
    const secondBlock: any = secondBlockRef?.current ?? null;
    const itemHeight =
      firstBlock?.firstChild?.firstChild?.getBoundingClientRect()?.height ?? 0;

    if (firstBlock && secondBlock) {
      const itemsInFirstBlock = length1 !== 0 ? length1 : 1;
      const itemsInSecondBlock = lenght2 !== 0 ? lenght2 : 1;

      let correctItemHeight = 35;

      if (itemHeight != null && itemHeight !== 0) {
        correctItemHeight = itemHeight;
      }

      const height = Math.max(
        itemsInFirstBlock * correctItemHeight,
        itemsInSecondBlock * correctItemHeight
      );

      //Add 20 px because is the padding of the parent element
      firstBlock.style.height = `${height + 20}px`;
      secondBlock.style.height = `${height + 20}px`;
    }
  };

  useEffect(() => {
    equalizeBoxHeights(
      refUpgrades1,
      refUpgrades2,
      dataList1?.upgrades?.length,
      dataList2?.upgrades?.length
    );
    equalizeBoxHeights(
      refDowngrades1,
      refDowngrades2,
      dataList1?.downgrades?.length,
      dataList2?.downgrades?.length
    );
  });

  const toggleShowContent = () => setShowContent(!showContent);

  const arrowDirection = showContent ? "down" : "up";

  const timeframeSelectorWidget = (
    <div style={{ fontSize: 12 }}>
      <RadioButtonBar
        options={[
          {
            label: "Today",
            value: "today",
          },
          {
            label: "Last Week",
            value: "lastWeek",
          },
          {
            label: "Last Month",
            value: "lastMonth",
          },
        ]}
        initValue={timeframe}
        onChange={(value) => {
          onChangeTimeframe(value);
          setTimeframe(value);
        }}
      />
    </div>
  );

  const accordionTitle = (
    <div className="accordion-compare-title">
      {showContent ? timeframeSelectorWidget : <h2>Upgrades Downgrades</h2>}
      <span
        onClick={toggleShowContent}
        className={`i-${arrowDirection}`}
      ></span>
    </div>
  );

  return (
    <div className="upgrades-downgrades-block">
      <Accordion titleJSX={accordionTitle} isOpen={showContent}>
        <div className="upgrades-downgrades-content">
          <div className="sectionRow">
            <UpgradesDowngradesWidget
              ref={refUpgrades1}
              data={dataList1?.upgrades}
              title="Upgrades"
            />
            <UpgradesDowngradesWidget
              ref={refUpgrades2}
              data={dataList2?.upgrades}
              title="Upgrades"
            />
          </div>
          <div className="sectionRow">
            <UpgradesDowngradesWidget
              ref={refDowngrades1}
              data={dataList1?.downgrades}
              title="Downgrades"
            />
            <UpgradesDowngradesWidget
              ref={refDowngrades2}
              data={dataList2?.downgrades}
              title="Downgrades"
            />
          </div>
        </div>
      </Accordion>
    </div>
  );
}
