import { Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "../../../../../components/Chart";

type DownUpGradesColumnsProps = {
  hasDataLabels: boolean;
  hasLegend: boolean;
  hasTooltip: boolean;
  hasXAxisLabels: boolean;
  height: number;
  margin: any;
  value: any;
  title: any;
  width: number;
};

export function DownUpGradesColumns({
  hasDataLabels,
  hasLegend,
  hasTooltip,
  hasXAxisLabels,
  height,
  margin,
  value,
  title,
  width,
}: DownUpGradesColumnsProps) {
  const { t } = useTranslation();

  const dataToSerie = useCallback(() => {
    var _downgrades: any = [];
    var _upgrades: any = [];
    var key: any = null;
    var keysOrder = ["last3Months", "lastMonth", "lastWeek", "today"];
    for (let i = 0; i < keysOrder.length; i++) {
      key = keysOrder[i];
      _downgrades.push(value[key]["downgrades"]);
      _upgrades.push(value[key]["upgrades"]);
    }

    // numbers to percentages
    var downgrades: any = [];
    var upgrades: any = [];
    for (let i = 0; i < _downgrades.length; i++) {
      upgrades.push(
        Math.round((100 * _upgrades[i]) / (_upgrades[i] + _downgrades[i]))
      );
      downgrades.push(
        Math.round((100 * _downgrades[i]) / (_upgrades[i] + _downgrades[i]))
      );
    }

    var series = {
      downgrades: downgrades,
      upgrades: upgrades,
    };

    return series;
  }, [value]);

  const config = useMemo(() => {
    const series = dataToSerie();

    var colors = {
      border: "#d3d3d3",
    };

    const highchartsConfig = {
      chart: {
        borderColor: colors["border"],
        borderWidth: 0,
        height: height,
        margin: margin,
        type: "column",
        width: width,
      },
      title: { text: title },
      xAxis: {
        categories: ["Last 3 months", "Last month", "Last week", "Today"],
        endOnTick: true,
        // gridLineColor: colors["border"],
        // gridLineWidth: 1,
        labels: { enabled: hasXAxisLabels },
        startOnTick: true,
        tickColor: colors["border"],
      },
      yAxis: {
        min: 0,
        max: 100,
        labels: { enabled: false },
        title: { text: null },
        gridLineWidth: 0,
      },
      legend: {
        enabled: hasLegend,
        align: "center",
        verticalAlign: "bottom",
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      series: [
        {
          name: "Upgrades",
          color: "rgba(0, 128, 0, .8)",
          data: series.upgrades,
        },
        {
          name: "Downgrades",
          color: "rgba(240, 0, 0, .8)",
          data: series.downgrades,
        },
      ],
      tooltip: {
        enabled: hasTooltip,
        valueDecimals: 2,
        valueSuffix: "%",
      },
      plotOptions: {
        series: { animation: false },
        column: {
          dataLabels: {
            enabled: hasDataLabels,
            format: "{y}%",
          },
          marker: { enabled: false },
        },
      },
    };

    return highchartsConfig;
  }, [
    dataToSerie,
    hasDataLabels,
    hasLegend,
    hasTooltip,
    hasXAxisLabels,
    height,
    margin,
    title,
    width,
  ]);

  return value ? (
    <Chart options={config} constructorType="chart" />
  ) : (
    <Typography>{t("no_data_to_display")}</Typography>
  );
}
