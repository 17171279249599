import { Card, CardContent, FormControl, FormLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputNumber from "../../../InputNumber/InputNumber";

type Props = {
  strategyDispatch: Function;
  input: number | null;
};

export default function Holdings({ strategyDispatch, input }: Props) {
  const { t } = useTranslation();

  const [innerState, setInnerState] = useState<number>(0);

  useEffect(() => {
    if (innerState != null && innerState !== input) {
      strategyDispatch({ type: "SET_HOLDINGS", payload: innerState });
    }
  }, [innerState, input, strategyDispatch]);
  return input != null ? (
    <Card sx={{ height: "100%", flex: 1 }}>
      <CardContent>
        <FormControl
          sx={{
            "& > * .Mui-focused > .MuiOutlinedInput-notchedOutline": {
              borderColor: "#2a7092 !important",
            },
          }}
        >
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "#2a7092",
              },
            }}
          >
            <Typography variant="subtitle1">
              {t("page_builder_field_holdings_threshold")}
            </Typography>
          </FormLabel>
          <InputNumber
            getIsValid={(e) => strategyDispatch({ type: "SET_ERROR_AT_INPUT_VALUE", payload: e })}
            constraints={{ minVal: 0 }}
            isPercentage={false}
            setOuterState={(e) => {
              if (e != null) {
                setInnerState(e);
              }
            }}
            isFloating={false}
            initVal={input}
          />
        </FormControl>
      </CardContent>
    </Card>
  ) : null;
}
