/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportRanking
 * @summary Manages ranking report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportRanking extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_RANKING" as const;
}
