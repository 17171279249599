import { Button, ButtonGroup } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type PeerSizeProps = {
  options: any[];
  constraints?: { target: string };
  isNullWhenAllAreSelected?: boolean;
  onChangeValueHandler?: (value: string | null) => void;
  onClickItem?: PeerSizeProps["onChangeValueHandler"];
  defaultValue?: string;
};

const intervalValueSplit = (value, optionsMap) => {
  if (!optionsMap || !value || !value.length) {
    return;
  }
  // single interval
  var valueEnd = value;
  var valueStart = value;
  // multiple interval
  var cutoffIndex = value.search(/[A-Z]/g);
  if (cutoffIndex !== -1) {
    valueEnd = value.substring(cutoffIndex).toLowerCase();
    valueStart = value.substring(0, cutoffIndex);
  }

  var optionsIndexEnd = optionsMap[valueEnd]["index"];
  var optionsIndexStart = optionsMap[valueStart]["index"];

  return {
    end: optionsIndexEnd,
    start: optionsIndexStart,
    valueEnd: valueEnd,
    valueStart: valueStart,
  };
};

const intervalGet = (options) => {
  if (options == null) {
    return null;
  }

  let indexEnd: any = null;
  let indexStart: any = null;
  for (let i = 0, length = options.length; i < length; i++) {
    const option = options[i];
    if (option["isConstrained"] === true || option["isSelected"] === true) {
      if (indexEnd == null || i > indexEnd) {
        indexEnd = i;
      }

      if (indexStart == null || i < indexStart) {
        indexStart = i;
      }
    }
  }

  return indexEnd != null && indexStart != null
    ? {
        end: indexEnd,
        start: indexStart,
      }
    : null;
};

const getIndexAndValue = (options) => {
  var interval = intervalGet(options);
  if (interval == null) {
    return null;
  }

  var indexEnd = interval["end"];
  var indexStart = interval["start"];

  var valueEnd = options[indexEnd]["value"];
  var valueStart = options[indexStart]["value"];

  return {
    indexEnd: indexEnd,
    indexStart: indexStart,
    valueEnd: valueEnd,
    valueStart: valueStart,
  };
};

const getValue = (opts, isNullWhenAllAreSelected, options) => {
  var _value = getIndexAndValue(opts);

  if (_value) {
    const indexEnd = _value["indexEnd"];
    const indexStart = _value["indexStart"];
    const valueEnd = _value["valueEnd"];
    const valueStart = _value["valueStart"];

    if (valueStart === valueEnd) {
      return valueStart;
    } else if (
      isNullWhenAllAreSelected === true &&
      indexStart === 0 &&
      indexEnd === options.length - 1
    ) {
      return null;
    } else {
      var val =
        valueStart + valueEnd.charAt(0).toUpperCase() + valueEnd.slice(1);

      return val;
    }
  } else {
    return null;
  }
};

export function PeerSize({
  options,
  constraints,
  isNullWhenAllAreSelected = true,
  onChangeValueHandler,
  onClickItem,
  defaultValue,
}: PeerSizeProps) {
  const [optMap, setOptMap] = useState<any>();
  const [opts, setOpts] = useState<any>([]);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setOpts(options);
  }, [options]);

  const updateOptionsWithInterval = useCallback(
    (options, interval) => {
      if (
        !interval ||
        (interval.start === 0 &&
          interval.end === options.length - 1 &&
          isNullWhenAllAreSelected === true)
      ) {
        return;
      }

      for (const opt of options) {
        if (interval != null) {
          opt["isSelected"] = false;

          const i = options.indexOf(opt);

          if (
            interval["start"] != null &&
            interval["end"] != null &&
            i >= interval["start"] &&
            i <= interval["end"]
          ) {
            opt["isSelected"] = true;
          }
        }
      }

      setOpts(options);
    },
    [isNullWhenAllAreSelected]
  );

  const onClickHandler = useCallback(
    (option, e?) => {
      if (!option["isConstrained"] || option["isConstrained"] === false) {
        const updatedOpts = opts.map((opt) => {
          if (opt.label === option.label) {
            return { ...opt, isSelected: !opt["isSelected"] };
          }

          return opt;
        });

        const interval = intervalGet(updatedOpts);

        updateOptionsWithInterval(updatedOpts, interval);

        onClickItem &&
          onClickItem(getValue(updatedOpts, isNullWhenAllAreSelected, options));
      }
    },
    [
      isNullWhenAllAreSelected,
      onClickItem,
      options,
      opts,
      updateOptionsWithInterval,
    ]
  );

  /**
   * Used to set a precise value from outside component
   */
  useEffect(() => {
    if (defaultValue) {
      const interval = intervalValueSplit(defaultValue, optMap);

      if (interval) {
        updateOptionsWithInterval(options, interval);
      }
    }
  }, [defaultValue, optMap, options, updateOptionsWithInterval]);

  useEffect(
    () => onChangeValueHandler && onChangeValueHandler(value),
    [onChangeValueHandler, value]
  );

  useEffect(() => {
    setValue(getValue(opts, isNullWhenAllAreSelected, options));
  }, [isNullWhenAllAreSelected, options, opts]);

  useEffect(() => {
    const optionsMap = {};

    let option: any = null;
    for (let i = 0, length = options.length; i < length; i++) {
      option = options[i];
      option["index"] = i;
      option["isConstrained"] = options[i].value === constraints?.target;
      option["isSelected"] = false;
      option["node"] = null;
      optionsMap[option["value"]] = option;
    }

    setOptMap(optionsMap);
  }, [constraints?.target, options]);

  const constrainted = useMemo(
    () =>
      constraints != null
        ? intervalValueSplit(constraints?.["target"], optMap)
        : null,
    [constraints, optMap]
  );

  useEffect(() => {
    for (const opt of opts) {
      if (
        constrainted != null &&
        opt >= constrainted["start"] &&
        opt <= constrainted["end"]
      ) {
        setOpts([...opts, (opts[opt]["isConstrained"] = true)]);
      }
    }
  }, [constrainted, options, opts]);

  return (
    <div>
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label="Basic button group"
      >
        {opts?.map((option, index) => {
          return (
            <Button
              key={uuidv4()}
              onClick={(e) => onClickHandler(option, e)}
              variant={option["isSelected"] ? "contained" : "outlined"}
              title={option?.title}
            >
              <span
                dangerouslySetInnerHTML={{ __html: option?.label ?? "" }}
              ></span>
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}
