import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { _valueGet } from "../utils";
import style from "./WizzardComponents.module.scss";

type WizzPropertyProps = {
  properties: any;
  rState: any;
  propertyId: any;
  options: any;
  dispatcher: any;
  isEditing: any;
  sortedList: any;
  customStyle: React.CSSProperties;
};

export default function WizzProperty({
  properties,
  rState,
  propertyId,
  options,
  dispatcher,
  isEditing,
  sortedList,
  customStyle,
}: WizzPropertyProps) {
  const [propertiesToAccordion, setPropertiesToAccordion] = useState<any>(null);
  const [expandedPanel, setExpandedPanel] = useState("");
  const defStyleClass = (item) => {
    if (item["value"] === propertyId && rState["editing"]) {
      return "tFormSelectionAddRule-listItem--selected";
    } else {
      if (item["value"] === propertyId && rState["editing"] === false) {
        return "tFormSelectionAddRule-listItem--selected";
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    /* 
      creating an obj that has sortedList elements
      as keys and Object[] as value
    */
    if (properties) {
      const obj: any = {};
      sortedList.forEach((topic) => {
        let arr: any = [];
        properties.forEach((item) => {
          if (item["builderGroup"] === topic) {
            arr.push(item);
          }
          //to identify the accordion that has to be open initially
          if (item["value"] === propertyId) {
            setExpandedPanel(item["builderGroup"]);
          }
        });
        obj[topic] = arr;
      });
      setPropertiesToAccordion(obj);
    }
  }, [properties, propertyId, sortedList]);

  const handleAccordionChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : "");
    },
    []
  );

  //!!-----------------------------------------------------------------
  //!!-----------------------------------------------------------------
  //!!-----------------------------------------------------------------
  return (
    <div style={customStyle ? customStyle : {}}>
      <Card sx={{ height: "100%", boxShadow: 3 }}>
        <CardContent>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Rank on
          </Typography>
          {propertiesToAccordion &&
            sortedList.map((item, index) => {
              return (
                <Accordion
                  sx={{ marginBottom: 0 }}
                  key={index}
                  onChange={handleAccordionChange(item)}
                  expanded={expandedPanel === item}
                >
                  <AccordionSummary
                    sx={{
                      margin: 0,
                      minHeight: "unset !important",
                      "& .Mui-expanded": {
                        margin: "0 !important",
                        marginTop: "20px !important",
                      },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle1">{item}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {propertiesToAccordion[item].map((prop, indexx) => (
                        <li key={indexx} className={defStyleClass(prop)}>
                          <span
                            onClick={(e: any) => {
                              const newRstate = { ...rState, editing: false };
                              onClickOnPropertyOption(newRstate, prop, options, dispatcher);
                              if (!isEditing) {
                                highlightSelectedWhenCreating(
                                  e.target,
                                  "tFormSelectionAddRule-listItem--selected"
                                );
                              }
                            }}
                            className={style.listItemContent}
                          >
                            {prop.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
}

// as following the step-to-step approach, on click at property option
// in the only function section has to be shown
const onClickOnPropertyOption = (rState, prop, options, dispatcher) => {
  let temp = _valueGet(rState, prop.value, null, null, options._optionsIndex, options._options);
  const { field, index, /*optionsFunction, optionsOperator,*/ editing } = temp;
  const { /*function: functionP, functionParams, operator, operatorParams,*/ property } = field;
  dispatcher({ type: "SET_FUNCTION", payload: null });
  dispatcher({ type: "SET_FUNCTION_PARAMS", payload: null });
  dispatcher({ type: "SET_PROPERTY", payload: property });
  // dispatcher({ type: "SET_OPERATOR", payload: operator });
  // dispatcher({ type: "SET_OPERATOR_PARAMS", payload: operatorParams });
  dispatcher({ type: "SET_INDEX", payload: index });
  // dispatcher({ type: "SET_OPTIONS_FUNCTION", payload: optionsFunction });
  // dispatcher({ type: "SET_OPTIONS_OPERATOR", payload: optionsOperator });
  dispatcher({
    type: "SET_SHOW_FUNCTION_SECTION",
    payload: true,
  });
  dispatcher({
    type: "SET_SHOW_OPERATOR_SECTION",
    //need a condition here for case when have only one function
    payload: false,
  });
  dispatcher({
    type: "SET_SHOW_VALUE_SECTION",
    payload: false,
  });
  dispatcher({
    type: "SET_EDITING",
    payload: editing,
  });
  dispatcher({ type: "SET_ERROR_AT_INPUT_VALUE", payload: false });
};

const highlightSelectedWhenCreating = (element: HTMLElement, classToApply: string) => {
  if (element) {
    let siblings: any = Array.from(element.parentNode!.parentNode!.childNodes);
    siblings.forEach((item: HTMLElement) => {
      if (item.parentElement!.classList.contains(classToApply)) {
        item.parentElement!.classList.remove(classToApply);
      }
    });
    element.parentElement!.classList.add(classToApply);
  }
};
