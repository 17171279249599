/*
TODOS:
!-> implement error handling on actions (save,rename etc...)
!-> error handling on print report
*/

import { useCallback, useMemo, useState } from "react";
import ReactContent from "../../../../dialog/report/reactReport/ReactContent";
import { deepClone } from "../../../../../../../deepClone";
import { DialogSaveComponent } from "../../../../../ui/commons/DialogSave/DialogSaveComponent";
import Modal from "../../../../../../../components/Modal/Modal";
import { useActionModal } from "../../../../../utils/useActionModal";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Collector } from "../../../../../../trendrating-report/data/Collector";
import { Generator } from "../../../../../../trendrating-report/generator/Generator";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { Downloader } from "../../../../../../trendrating-report/downloader/DownloaderReact";
import { _mergeWysiwygStateActions } from "./utils";
import { themeBlueAndGrey } from "../../../../dialog/report/themeBlueAndGrey-ts";
import { messageError } from "../../../../../utils";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type ReportWizzardProps = {
  data: {
    columnsAvailable: any[];
    defaultTemplates: any[];
    lastUsedTemplateName: string;
    lastUsedTemplate: any;
    page: string;
    wysiwygState: any;
    templates: any;
  };
  httpTemplates: any;
  refresh: Function;
  rankingCache: any;
};

export default function ReportWizzard({
  data,
  httpTemplates,
  refresh,
  rankingCache,
}: ReportWizzardProps) {
  const actionModal = useActionModal();
  const environment = useEnvironment();
  const formatter = useFormatter();
  const { broadcast } = useBroadcast();

  //! templates = defaultTemplates + templates
  const templates = useMemo(() => {
    var updatedOptions: any[] = [];
    for (let i = 0; i < data.defaultTemplates.length; i++) {
      updatedOptions.push(deepClone(data.defaultTemplates[i]));
    }
    if (data.templates != null) {
      for (let i = 0; i < data.templates.length; i++) {
        updatedOptions.push(deepClone(data.templates[i]));
      }
    }
    // For each option, add a special field 'index' to recognize each
    // one without resorting to checking name or id (that can be
    // duplicate, like null id and same name)
    for (let i = 0, length = updatedOptions.length; i < length; i++) {
      updatedOptions[i]["index"] = i;
    }
    return updatedOptions;
  }, [data.defaultTemplates, data.templates]);

  const [dialog, setDialog] = useState<any>(undefined);
  const hideDialog = useCallback(() => setDialog(null), []);
  const [dialogDelete, setDialogDelete] = useState<any>(null);

  //#region ACTIONS (save, delete,rename, print)
  const rename = useCallback(
    (newName, templateToRename) => {
      let temp = deepClone(templateToRename);
      temp["name"] = newName;
      httpTemplates.save(temp).then(
        (response) => {
          refresh();
          hideDialog();
        },
        (error) => {
          console.error(error);
        }
      );
    },
    [hideDialog, httpTemplates, refresh]
  );
  //------
  const save = useCallback(
    (templateToSave) => {
      httpTemplates.save(templateToSave).then((response) => {
        refresh();
        hideDialog();
      });
    },
    [hideDialog, httpTemplates, refresh]
  );
  //------
  const saveAs = useCallback(
    (nameToSaveAs, template) => {
      let temp = deepClone(template);
      temp["name"] = nameToSaveAs;
      temp["id"] = null;
      temp["isEditable"] = true;
      httpTemplates.save(temp).then(
        (response) => {
          refresh();
          hideDialog();
        },
        (error) => {
          console.error(error);
        }
      );
    },
    [hideDialog, httpTemplates, refresh]
  );
  //------
  const deleteTemplate = useCallback(
    (templateToDelete) => {
      httpTemplates
        .remove({
          id: templateToDelete.id,
        })
        .then((response) => {
          setDialogDelete(null);
          refresh();
        });
    },
    [httpTemplates, refresh]
  );
  //#endregion

  const prepareDialog = useCallback(
    (
      dialogType: "save" | "saveAs" | "rename" | "delete" | "error",
      template: any
    ) => {
      switch (dialogType) {
        case "rename":
          setDialog(
            <DialogSaveComponent
              dialogType={"Report"}
              onRename={(newName) => {
                rename(newName, template);
              }}
              item={template}
              onSave={null}
              onSaveAs={null}
              reset={undefined}
              hide={() => hideDialog()}
            />
          );
          break;
        case "save":
          setDialog(
            <DialogSaveComponent
              dialogType={"Report"}
              onRename={null}
              item={template}
              onSave={() => save(template)}
              onSaveAs={null}
              reset={undefined}
              hide={() => hideDialog()}
            />
          );
          break;
        case "saveAs":
          setDialog(
            <DialogSaveComponent
              dialogType={"Report"}
              onRename={null}
              item={template}
              onSave={null}
              onSaveAs={(nameToSaveAs) => saveAs(nameToSaveAs, template)}
              reset={undefined}
              hide={() => hideDialog()}
            />
          );
          break;
        case "delete":
          setDialogDelete(
            <Modal
              closeIcon={false}
              buttonsEnalbed
              customCss={{ maxWidth: 300 }}
              onClose={() => setDialogDelete(null)}
              headerConfig={{ headerContent: "Warning" }}
              buttons={[
                { name: "Delete", callback: () => deleteTemplate(template) },
                {
                  name: "Go back",
                  callback: () => setDialogDelete(null),
                  variant: "cancel",
                },
              ]}
            >
              Do you really want to delete this template?
            </Modal>
          );
          break;
      }
    },
    [deleteTemplate, hideDialog, rename, save, saveAs]
  );
  const _print = useCallback(
    (printTemplate) => {
      var template = printTemplate["template"];
      if (
        template == null ||
        template["configuration"] == null ||
        template["configuration"]["sections"] == null ||
        template["configuration"]["sections"].length === 0
      ) {
        //! hide modal
        return;
      }

      var environmentSetup = environment.get("setup");
      var user = environmentSetup["account"]["user"];
      var userPreference = _getUserPreference(user, printTemplate);

      // executable template sections
      //
      // !!! TO BE CLONED !!!
      // data formating modifies sections in order to optimize
      // e.g. remove empty rows, etc
      var sections = printTemplate["executableSections"];
      var wysiwygState = printTemplate["wysiwygState"];
      var data = new Collector(environmentSetup, {
        rankingCache: rankingCache,
        // rankingType: "instruments" /*//?is it always "instruments"*/,
        sections: deepClone(sections), // executable template sections
        template: deepClone(template),
        userPreference: userPreference,
        wysiwygState: wysiwygState,
      });

      return data.retrieve().then(
        ({ response, _wysiwygState }) => {
          var report: any = new Generator(environmentSetup, {
            data: response,
            formatter: formatter,
            sections: deepClone(sections), // executable template sections
            template: deepClone(template),
            userPreference: userPreference,
            wysiwygState: _wysiwygState,
          });
          return report
            .create()
            .print()
            .then(
              (response) => {
                var downloader = new Downloader(report);
                downloader.download(response);
                return httpTemplates.save(template).then(
                  (response) => {
                    // nothing to do
                  },
                  (error) => {
                    console.error(error);
                  }
                );
              },
              (error) => {
                console.error(error);
              }
            );
        },
        (error) => {
          let output: any | string = null;
          if (error.error === "MAXIMUM_HOLDINGS_EXCEEDED") {
            output = [
              "Holdings (table): cannot be printed more than",
              error.maximum,
              "securities (total: " + error.actual + ").",
            ].join(" ");
          } else if (error.error === "MAXIMUM_CHART_HOLDINGS_EXCEEDED") {
            output = [
              "Holdings (charts): cannot be printed more than",
              error.maximum,
              "charts (total: " + error.actual + ").",
            ].join(" ");
          }
          const [channel, msg] = messageError(output);
          broadcast(channel as string, msg);
        }
      );
    },
    [broadcast, environment, formatter, httpTemplates, rankingCache]
  );

  const logPrintAction = useCallback(() => {
    const PAGE_TO_FN_DICT = {
      screening: "SCREENING",
      systematicPortfolios: "SYSTEMATIC_PORTFOLIO",
      analysisMarketsDetail: "PEER_ANALYSIS",
      analysisList: "PORTFOLIO_ANALYSIS",
      dispersionBasketTab: "PORTFOLIO_ANALYSIS",
      ranking: "RANKING",
      strategyLongShort: "STRATEGY_LONG_SHORT",
      strategyBuilder: "STRATEGY_BUILDER",
    };

    const usageFunction = PAGE_TO_FN_DICT?.[data.page] ?? null;

    if (usageFunction != null) {
      // ***************** USAGE *****************
      var usage = window.App.usage;
      var info = {
        action: "REPORT",
        actionParam: null,
        function: usageFunction,
      };
      usage.record(info);
      // ***************** USAGE *****************
    }
  }, [data.page]);

  const print = useCallback(
    (templateToPrint) => {
      logPrintAction();
      const prep = _preparePrint(
        templateToPrint,
        data.lastUsedTemplate,
        data.wysiwygState
      );
      return _print(prep);
    },
    [_print, data.lastUsedTemplate, data.wysiwygState, logPrintAction]
  );

  const componentToShow = useMemo(() => {
    switch (data.page) {
      case "screening":
      case "systematicPortfolios":
      case "analysisMarketsDetail":
      case "analysisList":
      case "dispersionBasketTab":
      case "ranking":
      case "strategyLongShort":
      case "strategyBuilder":
        return (
          <ReactContent
            templates={templates}
            value={data.lastUsedTemplate}
            columnsAvailable={data.columnsAvailable}
            wysiwygState={data.wysiwygState}
            listenerPrintAndClose={(templateToPrint) => {
              return print(templateToPrint)?.then(() => {
                actionModal.setShowModal(false);
              });
            }}
            listenerPrint={(templateToPrint) => {
              return print(templateToPrint);
            }}
            listenerCancel={() => actionModal.setShowModal(false)}
            listenerSaveAs={(template) => prepareDialog("saveAs", template)}
            listenerSave={(template) => {
              prepareDialog("save", template);
            }}
            page={data.page}
            listenerDelete={(template) => prepareDialog("delete", template)}
            listenerRename={(template) => prepareDialog("rename", template)}
          />
        );
      default:
        console.error(`page "${data.page}" not implemented!`);
        return null;
    }
  }, [
    actionModal,
    data.columnsAvailable,
    data.lastUsedTemplate,
    data.page,
    data.wysiwygState,
    prepareDialog,
    print,
    templates,
  ]);

  return (
    <>
      {componentToShow}
      {dialog}
      {dialogDelete}
    </>
  );
}

export const _getUserPreference = (user, printDialogValue) => {
  var userPreference: any =
    user?.preferences?.preferences?.report?.general ?? null;

  if (userPreference == null) {
    // Prepare default values
    userPreference = {
      disclaimer: null,
      logo: null,
      theme: null,
    };
  }
  userPreference["theme"] = printDialogValue["theme"];
  return userPreference;
};
const _preparePrint = (value, lastUsedTemplate, wysiwygState) => {
  // If there is no last used template, just make one from the actual
  // value
  var printTemplate: any = null;
  if (lastUsedTemplate == null) {
    printTemplate = deepClone(value);
    printTemplate["id"] = null;
  } else {
    printTemplate = deepClone(lastUsedTemplate);
    printTemplate.configuration.sections = deepClone(
      value.configuration.sections
    );
    printTemplate.configuration.headerConfig = deepClone(
      value.configuration.headerConfig
    );
    printTemplate.configuration.theme = value.configuration.theme;
    printTemplate.configuration.orientation = value.configuration.orientation;
    printTemplate.configuration.integration = value.configuration.integration;
  }

  var theme: any = null;
  var pageTheme = printTemplate.configuration.theme;
  if (pageTheme === "theme1") {
    theme = themeBlueAndGrey;
  }
  printTemplate.configuration.theme = pageTheme;
  printTemplate["name"] = "LAST_USED_TEMPLATE";

  // Remove index field that is only used by ui
  if (printTemplate["index"] != null) {
    delete printTemplate["index"];
  }

  var _value = {
    executableSections: _mergeWysiwygStateActions(
      deepClone(printTemplate.configuration.sections),
      wysiwygState
    ),
    template: printTemplate,
    theme: theme,
    wysiwygState: wysiwygState,
  };
  return _value;
};

//!as new "rename" and "save as" panels already implement this kind of
//!error handling we can ommit this error listener
// const _errorListenerTemplateHandler = (error) => {
//   switch (error["status"]) {
//     case 409: {
//       return {
//         text: "A template with the same name already exists, please use another name.",
//         type: "ERROR",
//         response: error,
//       };
//     }
//     default: {
//       return {
//         text: "Unknown error. Please contact Trendrating support.",
//         type: "ERROR",
//         response: error,
//       };
//     }
//   }
// };
