import { useMemo } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { TrendratingTable } from "../../../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../../../hooks/useFormatter";

type ComparisonTableWidgetTableProps = {
  name1: string;
  name2: string;
  rowClickHandler: Function;
  title: string;
  value: any;
};

const weightFormatter = (cell, field, key) => {
  const data = cell.getData();

  if (key != null) {
    if (data[key] === "BASKET") {
      return '<span style="height: 10px; width: 10px; border-radius: 50%; background-color: #333; display: inline-block;"></span>';
    } else {
      return data[field] !== 0 ? `${(data[field] * 100).toFixed(2)}%` : "-";
    }
  }

  return data[field] !== 0 ? `${(data[field] * 100).toFixed(2)}%` : "-";
};

export function ComparisonTableWidgetTable({
  name1,
  name2,
  rowClickHandler,
  title,
  value,
}: ComparisonTableWidgetTableProps) {
  const formatter = useFormatter();

  const tableOptions = useMemo(
    () => ({
      ajaxSorting: false,
      selectableRows: 1,
    }),
    []
  );

  const sorter = { field: "weightDiff", direction: "desc" };

  const columns = useMemo<ColumnDefinition[]>(
    () => [
      {
        title: title,
        field: "segment",
        hozAlign: "left",
        widthGrow: 2,
      },
      {
        title: `${name1}`,
        headerHozAlign: "center",
        columns: [
          {
            title: "TCR",
            headerHozAlign: "center",
            hozAlign: "center",
            field: "rate1",
            formatter: (cell) =>
              `<div style="display: flex; justify-content: flex-start; width: 50%; margin-left: 48%;">${formatter.tcr(
                cell.getData()["rate1"],
                "HTML"
              )}</div>`,
          },
          {
            title: "Weight",
            field: "weight1",
            headerHozAlign: "center",
            hozAlign: "center",
            formatter: (cell) =>
              weightFormatter(cell, "weight1", "firstListType"),
          },
        ],
      },
      {
        title: `${name2}`,
        headerHozAlign: "center",
        columns: [
          {
            title: "TCR",
            field: "rate2",
            headerHozAlign: "center",
            formatter: (cell) =>
              `<div style="display: flex; justify-content: flex-start; width: 50%; margin-left: 48%;">${formatter.tcr(
                cell.getData()["rate2"],
                "HTML"
              )}</div>`,
          },

          {
            title: "Weight",
            field: "weight2",
            headerHozAlign: "center",
            hozAlign: "center",
            formatter: (cell) =>
              weightFormatter(cell, "weight2", "secondListType"),
          },
        ],
      },
      {
        title: "Difference",
        field: "weightDiff",
        formatter: (cell) => weightFormatter(cell, "weightDiff", null),
      },
    ],
    [formatter, name1, name2, title]
  );

  return (
    <TrendratingTable
      disableDefaultRowClick
      options={tableOptions}
      sorting={sorter}
      autoResize={false}
      columns={columns}
      data={value ?? []}
      eventCallback={rowClickHandler}
    />
  );
}
