import { Box, Typography } from "@mui/material";
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { SystematicIndex } from "../SystematicIndex/SystematicIndex";
import styles from "./SystematicList.module.scss";
import { ChangeViewBtn, SystematicPortfolioContext } from "../SystematicPortfolios";
import { SortBy } from "../../strategies/widgets/StrategiesList/StrategiesList";
import { useEnvironment } from "../../../../../hooks/useEnvironment";

type SystematicListProps = {
  // showShared: boolean;
  onClickItem: (id) => void;
  view: "tile" | "table";
  handleChangeView: any;
};

type Ref = { get: () => HTMLElement; load: () => any };

// Server side preference
const SORT_STATE_PATH = ["systematicProducts", "sort"];

export function SystematicList({
  // showShared,
  onClickItem,
  view,
  handleChangeView
}: SystematicListProps) {
  const listRef = useRef<HTMLUListElement>(null);
  const [products, setProducts] = useState<number[]>([]);
  const storage = useContext(SystematicPortfolioContext);
  const environment = useEnvironment();
  const pageStatusAPI = useMemo(
    () => environment.get("preferenceStatus"),
    [environment]
  );
  const [sort, setSort] = useState<
    undefined | "name" | "name_rev" | "recent_first" | "recent_bottom"
  >(undefined);

  const preferencesReady = useMemo(() => {
    return sort != null;
  }, [sort]);

  /**
   * Is a map that keeps the refs of the list item and triggers the load function on them when intersecting
   */
  const nodesMap = useRef<{ [id: number]: MutableRefObject<Ref> }>({});

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const systematicPortfolioId =
              entry["target"].getAttribute("data-list-id");
            if (
              entry.isIntersecting === true &&
              entry.intersectionRatio > 0 &&
              systematicPortfolioId
            ) {
              nodesMap?.current?.[systematicPortfolioId]?.load();
            }
          });
        },
        { threshold: 0.1, root: listRef.current }
      ),
    []
  );

  useEffect(() => {
    return () => observer.disconnect();
  }, [observer]);

  const observeNode = useCallback(
    (node: any) => {
      if (!node) {
        return;
      }

      const htmlNode = node.get();
      const systematicPortfolioId = parseInt(
        htmlNode.getAttribute("data-list-id")
      );

      if (systematicPortfolioId) {
        nodesMap.current[systematicPortfolioId] = node;
        observer.observe(htmlNode);
      }
    },
    [observer]
  );

  const getSorter = useCallback((sort) => {
    let property = "name";
    let rev = false;

    switch (sort) {
      case "name":
      default:
        break;

      case "name_rev":
        property = "name";
        rev = true;

        break;

      case "recent_bottom":
        property = "creationTime";
        rev = false;

        break;

      case "recent_first":
        property = "creationTime";
        rev = true;

        break;
    }

    return { property, rev };
  }, []);

  const getProducts = useCallback(async () => {
    let productsList: any[] = [];

    if (preferencesReady) {
      const subscribed = await storage.getSubscribedProducts();

      const custom = await storage.getUserProducts();
      productsList = [...custom,...subscribed]

      const { property, rev } = getSorter(sort);

      const response = await storage.smsAPI.fetch({
        ids: productsList,
        properties: [property],
      });

      let aValue: any = null;
      let bValue: any = null;

      response.sort((a, b) => {
        aValue = property !== "name" ? a[property] : a[property].toLowerCase();
        bValue = property !== "name" ? b[property] : b[property].toLowerCase();

        if (aValue > bValue) {
          return rev === false ? 1 : -1;
        } else if (aValue < bValue) {
          return rev === false ? -1 : 1;
        }

        return 0;
      }, []);

      productsList = response.map((item) => item.id);
    }

    setProducts(productsList);
  }, [getSorter, preferencesReady, sort, storage]);

  const setInitialSort = useCallback(async () => {
    const savedSort = await pageStatusAPI.get(SORT_STATE_PATH);
    let sortValue: any = "name";

    if (savedSort) {
      const field = savedSort?.property ?? "name";
      const rev = savedSort?.rev ?? false;

      if (field.startsWith("name")) {
        if (field === "name") {
          if (rev === true) {
            sortValue = "name_rev";
          } else {
            sortValue = "name";
          }
        }
      } else {
        if (rev === true) {
          sortValue = "recent_first";
        } else {
          sortValue = "recent_bottom";
        }
      }
    }

    setSort(sortValue);
  }, [pageStatusAPI]);

  const onSortChange = useCallback(
    async (sortValue) => {
      const sorter = getSorter(sortValue);
      await pageStatusAPI.patch(SORT_STATE_PATH, sorter);

      setSort(sortValue);
    },
    [getSorter, pageStatusAPI]
  );

  useEffect(() => {
    getProducts();

    return () => setProducts([]);
  }, [getProducts]);

  useEffect(() => {
    setInitialSort();
  }, [setInitialSort]);

  return (
    <ErrorBoundary
      fallback={<Typography>Cannot load Systematic Portfolios list</Typography>}
    >
      <Box
        minHeight={0}
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        gap={1}
      >
        <Box p={1} pb={0} width={"10%"} display={"flex"} gap={1}>
          <SortBy sort={sort ?? "name"} setSort={onSortChange} />
          <ChangeViewBtn viewType={view} changeView={handleChangeView} />

        </Box>
        <Box sx={{ minHeight: 0, flex: 1, display: "flex" }}>
          <ul ref={listRef} className={styles.smsList}>
            {products &&
              products.map((product) => {
                return (
                  <SystematicIndex
                    onClickItem={onClickItem}
                    ref={observeNode}
                    key={uuidv4()}
                    productId={product}
                  />
                );
              })}
          </ul>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
