export const optionsForSortingSelect = (columns) => {
  //* this func returns the input needed for the SortingSelect component
  var options: any = [];
  var propertiesWithAD = ["rc", "rrr"];
  var propertiesWithAZ = [
    "ticker",
    "name",
    "currency",
    "country",
    "icb",
    "industry",
    "sector",
    "domicile",
    "etfgeo",
    "etfclass",
  ];
  if (columns != null) {
    for (let i = 0, length = columns.length; i < length; i++) {
      var column = columns[i];
      if (column["label"] == null) {
        // Do not use columns without labels
        continue;
      }
      //if (column["sortable"]) { sortable is always false?
      var option: any = {
        property: column["property"], // property vs field: what to use?
        label: column["label"],
        sortBy: {
          asc: {
            label: "Ascending",
          },
          desc: {
            label: "Descending",
          },
        },
      };
      // Adjust labels
      if (propertiesWithAD.indexOf(column["property"]) > -1) {
        option["sortBy"]["asc"]["label"] = "D to A";
        option["sortBy"]["desc"]["label"] = "A to D";
      }
      if (propertiesWithAZ.indexOf(column["property"]) > -1) {
        option["sortBy"]["asc"]["label"] = "A to Z";
        option["sortBy"]["desc"]["label"] = "Z to A";
      }
      options.push(option);
      //}
    }
  }
  return options;
};

// eslint-disable-next-line no-template-curly-in-string
export const targetNameVariable = "${name}";
// eslint-disable-next-line no-template-curly-in-string
export const targetTickerVariable = "${ticker}";