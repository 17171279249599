import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import PerformerBoxTable from "./PerformerBoxTable";
import styles from "./PerformerWidget.module.scss";

type PerformerWidgetProps = {
  portfolioId: any;
};

type RatingBoxProps = {
  data: any;
  rcGroup: "AB" | "CD";
  performanceAt: "20_DAYS" | "60_DAYS" | "90_DAYS";
};

const ITEMS_TO_DISPLAY = 5;

const OPTIONS = [
  { value: "20_DAYS", label: "1 Month" },
  { value: "60_DAYS", label: "3 Months" },
  { value: "90_DAYS", label: "6 Months" },
];

const analyticsMap = {
  "20_DAYS": "pm",
  "60_DAYS": "pq",
  "90_DAYS": "ps",
};

export function PerformerWidget({ portfolioId }: PerformerWidgetProps) {
  const [performaceAt, setPerformanceAt] = useState<
    "20_DAYS" | "60_DAYS" | "90_DAYS"
  >("20_DAYS");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const environment = useEnvironment();
  const apiInstrument = useMemo(
    () => environment.get("http")["instruments"],
    [environment]
  );
  const [data, setData] = useState<any>();

  const getPerformanceMinMax = useCallback((data, property) => {
    var result = {
      max: Number.MIN_VALUE,
      min: Number.MAX_VALUE,
    };
    var value = null;

    for (let i = 0, length = data.length; i < length; i++) {
      value = data[i][property];
      if (value != null) {
        if (value > result["max"]) {
          result["max"] = value;
        }
        if (value < result["min"]) {
          result["min"] = value;
        }
      }
    }

    var absMax = Math.max(Math.abs(result["max"]), Math.abs(result["min"]));

    result["max"] = absMax;
    result["min"] = -absMax;

    return result;
  }, []);

  const getData = useCallback(async () => {
    const what = performaceAt;

    setError(false);
    setIsLoading(true);

    const payloadCD = {
      constraints: [
        [
          {
            dimension: "rc",
            operator: "equals",
            segments: [-2, -1],
          },
          {
            dimension: "COLLECTION",
            operator: "relation",
            segments: [portfolioId],
          },
        ],
      ],
      page: {
        page: 1,
        rows: 25,
      },
      sort: [
        {
          dimension: analyticsMap[performaceAt],
          rev: false,
        },
      ],
    };

    const payloadAB = {
      constraints: [
        [
          {
            dimension: "rc",
            operator: "equals",
            segments: [2, 1],
          },
          {
            dimension: "COLLECTION",
            operator: "relation",
            segments: [portfolioId],
          },
        ],
      ],
      page: {
        page: 1,
        rows: 25,
      },
      sort: [
        {
          dimension: analyticsMap[performaceAt],
          rev: true,
        },
      ],
    };

    const responseCD = await apiInstrument.screening(payloadCD, true);
    const responseAB = await apiInstrument.screening(payloadAB, true);

    const fetchProperties = [
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: analyticsMap[performaceAt],
      },
      {
        date: null,
        property: "ticker",
      },
    ];

    let fetchResultAB: any = null;
    let fetchResultCD: any = null;

    if (responseAB && responseAB.data) {
      fetchResultAB = await apiInstrument.fetch({
        properties: fetchProperties,
        symbols: responseAB.data,
        type: "security",
      });
    }

    if (responseCD && responseCD.data) {
      fetchResultCD = await apiInstrument.fetch({
        properties: fetchProperties,
        symbols: responseCD.data,
        type: "security",
      });
    }

    if (
      fetchResultCD &&
      fetchResultCD.data &&
      fetchResultAB &&
      fetchResultAB.data
    ) {
      const analytic = analyticsMap[what];
      const ab = fetchResultAB.data;
      const cd = fetchResultCD.data;

      const performanceRangeAb = getPerformanceMinMax(ab, analytic);

      const performanceRangeCd = getPerformanceMinMax(cd, analytic);

      const data = {
        positions: [...ab, ...cd],
        AB: ab,
        CD: cd,
        range: { ab: performanceRangeAb, cd: performanceRangeCd },
      };

      setData(data);
      setIsLoading(false);
    } else {
      throw new Error("Fetch failed");
    }
  }, [apiInstrument, getPerformanceMinMax, performaceAt, portfolioId]);

  useEffect(() => {
    if (portfolioId) {
      try {
        getData();
      } catch (error) {
        setError(true);
      }
    }
  }, [getData, portfolioId]);

  const setValue = useCallback((value) => {
    setPerformanceAt(value);
  }, []);

  return (
    <Box>
      <Box className={styles.select_wrapper} mb={1}>
        <Typography sx={{ fontSize: "1.5em" }}>Performance</Typography>
        <Select value={performaceAt} size="small" variant="outlined">
          {OPTIONS.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => setValue(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box display={"flex"} gap={2}>
        {isLoading ? (
          <>
            <Loader />
            <Loader />
          </>
        ) : (
          <>
            {error === true ? (
              <Typography>
                Cannot load performance data please refresh your browser. If the
                error persist contact our customers support
              </Typography>
            ) : (
              <>
                <RatingBox
                  rcGroup="CD"
                  data={data}
                  performanceAt={performaceAt}
                />
                <RatingBox
                  rcGroup="AB"
                  data={data}
                  performanceAt={performaceAt}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

const dataPrepareRescale = (value, minFrom, maxFrom, minTo, maxTo) => {
  return minTo + ((maxTo - minTo) * (value - minFrom)) / (maxFrom - minFrom);
};

const RatingBox = ({ rcGroup, data, performanceAt }: RatingBoxProps) => {
  const dataForBox = useMemo(() => {
    if (data) {
      const positions = data[rcGroup];
      const range = data["range"][rcGroup.toLowerCase()];

      let _data = positions;
      var max = range["max"];
      var timeframe = analyticsMap[performanceAt];

      // build index in order to save current order
      var index = {};
      for (let i = 0; i < _data.length; i++) {
        index[_data[i]["symbol"]] = null;
      }
      // order data by absolute values: high to low
      var security: any = null;
      var sign: any = null;
      var valueAbs: any = null;
      var valueCapped: any = null;
      var rescaled: any = null;
      var value: any = null;
      for (let i = 0, length = _data.length; i < length; i++) {
        security = _data[i];
        value = security[timeframe];

        security["_" + timeframe] = null;
        if (value != null) {
          sign = value > 0 ? 1 : -1;
          valueAbs = Math.abs(value);
          valueCapped = Math.min(valueAbs, max);
          rescaled = dataPrepareRescale(valueCapped, 0, max, 0, 100);

          security["_" + timeframe] = sign * rescaled;
        }
        // populating index
        if (security["symbol"] in index) {
          index[security["symbol"]] = security;
        }
      }
      // rebuild original order
      var result: any = [];
      for (var key in index) {
        result.push(index[key]);
      }

      return result;
    } else {
      return undefined;
    }
  }, [data, performanceAt, rcGroup]);

  return dataForBox ? (
    <Card className={styles.card}>
      <CardContent>
        {rcGroup === "CD" ? (
          <h2 className="tAnalysisOverview-performerRate">
            <strong className="tAnalysisOverview-rate format-rate format-rate--C">
              C
            </strong>
            <strong className="tAnalysisOverview-rate format-rate format-rate--D">
              D
            </strong>
          </h2>
        ) : (
          <h2 className="tAnalysisOverview-performerRate">
            <strong className="tAnalysisOverview-rate format-rate format-rate--A">
              A
            </strong>
            <strong className="tAnalysisOverview-rate format-rate format-rate--B">
              B
            </strong>
          </h2>
        )}
        <PerformerBoxTable
          data={dataForBox}
          itemsToDisplay={ITEMS_TO_DISPLAY}
          timeframe={analyticsMap[performanceAt]}
        />
      </CardContent>
    </Card>
  ) : (
    <>No Data to display</>
  );
};

const Loader = () => {
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Stack spacing={1}>
          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="rectangular" width={"100%"} height={15} />
          <Skeleton variant="rectangular" width={"100%"} height={15} />
          <Skeleton variant="rectangular" width={"100%"} height={15} />
          <Skeleton variant="rectangular" width={"100%"} height={15} />
          <Skeleton variant="rectangular" width={"100%"} height={15} />
        </Stack>
      </CardContent>
    </Card>
  );
};
