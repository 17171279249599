import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { NewsFilterETF } from "./NewsFilterETF";

type EtfWidgetProps = {
  data: any;
  etfProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

export function EtfWidget({
  data,
  etfProps,
  accordionHandler,
  accordionState,
}: EtfWidgetProps) {
  const { saveHandler, stateChangeHandler } = etfProps;
  const formatter = useFormatter();
  const environment = useEnvironment();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = (event) => {
    if (securityLink !== "popup_instrument" && event.type === "rowClick") {
      const data = event.value.getData();
      const symbol = data?.symbol ?? null;
      if (symbol) {
        const url = `/app/analysis/instrument/${symbol}/`;
        window.location.href = url;
      }
    }
  };

  return (
    <div className="tNews-item">
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          ETFs
          <div className="tooltip-wrapper-icon"></div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
        ></span>
      </div>

      {accordionState && (
        <div data-dojo-attach-point="nodeETFAccordionContainer">
          <div>
            <NewsFilterETF
              setFiltersETF={stateChangeHandler}
              saveFiltersETF={saveHandler}
            />
          </div>

          {data && data.length ? (
            <div className="table-with-filters">
              <TrendratingTable
                autoResize={false}
                eventCallback={onRowClickHandler}
                options={{
                  ajaxSorting: false,
                  movableColumns: false,
                  resizableColumns: false,
                }}
                tooltip={{
                  actions: {
                    info: {
                      enabled: securityLink === "popup_instrument",
                    },
                  },
                }}
                columns={[
                  {
                    field: "ticker",
                    title: "Ticker",
                    resizable: false,
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 1,
                  },
                  {
                    field: "name",
                    title: "Name",
                    resizable: false,
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 3,
                  },
                  {
                    field: "rc",
                    resizable: false,
                    title: "TCR",
                    formatter: (cell) => formatterTcr(cell, formatter),
                  },
                  {
                    field: "etfclass",
                    title: "Asset Class",
                    resizable: false,
                  },
                  {
                    field: "etfgeo",
                    title: "Inv. Region",
                    resizable: false,
                  },
                ]}
                data={data}
              />
            </div>
          ) : (
            <div className="empty-news-data">No alert found</div>
          )}
        </div>
      )}
    </div>
  );
}
