import React, { useEffect, useState } from "react";

import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Environment } from "../../../../../../../Environment";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { widgetsConfiguration } from "../../../../../widgets/widgetsConfiguration";
import Panelright from "./layout/Panelright";
import ReactList from "./widgets/ReactList";

import style from "./PinnedDialog.module.scss";

type PinnedContentProps = {
    onSavePreferences: Function;
    onCloseBtnHandler: () => void;
};
type SelectorStatus = {
    market: null | string;
    sector: null | string;
    size: null | string;
    instrument: null | string;
};
type PinnedItem = { name: string; value: Object };

const fetchInstrumentsAnalytics = async (
    arrInstruments,
    environment: Environment,
    format,
    arrPeers,
    setList
) => {
    var params: any = {
        properties:
            widgetsConfiguration["widgets/search/ResultAll"][
                "properties_security"
            ],
        symbols: arrInstruments ?? [],
        type: "security",
    };
    const appEnvironment = environment.get("setup");
    const apiInstrument = new Instruments(appEnvironment);
    const response = await apiInstrument.fetch(params);

    const result = dataFormatInstrument(
        response?.["data"] ?? [],
        environment,
        format
    );

    const merged = [...arrPeers, ...result];
    setList(merged);
};
const getName = (item, value, environment, format) => {
    var params = {
        item: {
            data: value,
            idInfo: item,
        },
        options: {
            suffixWithName: true,
        },
        taxonomies: environment.get("taxonomies"),
    };

    return format.pinnedName(params);
};
const dataFormatInstrument = (response, environment, format) => {
    const instruments = {};

    response?.forEach((element) => {
        instruments[element["symbol"]] = element;
    });

    return Object.entries(instruments).map(([key, value]) => {
        return {
            name: getName(
                { type: "instrument", symbol: key },
                value,
                environment,
                format
            ),
            value: { type: "instrument", symbol: key },
        };
    });
};

export default function PinnedContent({
    onSavePreferences,
    onCloseBtnHandler,
}: PinnedContentProps) {
    const [isAddShowVisible, setIsAddShowVisible] = useState(false);
    const [selectorStatus, setSelectorStatus] = useState<SelectorStatus>();
    const [arrItems, setArrItems] = useState<PinnedItem[]>([]);
    const [areSelectorsTouched, setAreSelectorsTouched] = useState(false);

    const [isArrChanged, setIsArrChanged] = useState(false);

    //useEffect to enable/disable ADD btn on add preferences
    useEffect(() => {
        if (selectorStatus) {
            setAreSelectorsTouched(false);
            const arrValues = Object.values(selectorStatus);
            for (let val of arrValues) {
                if (val) {
                    setAreSelectorsTouched(true);
                    break;
                }
            }
        }
    }, [areSelectorsTouched, selectorStatus]);

    const reloadOnClosePinned = () => {
        if (isArrChanged) {
            window.location.reload();
        }
        onCloseBtnHandler();
    };

    const environment = useEnvironment();

    const format = useFormatter();

    useEffect(() => {
        const userPreferencesPinned =
            environment.get("account")?.["user"]?.["preferences"]?.[
                "preferences"
            ]?.["alerts"]?.["pinned"] ?? [];
        //fetch to get instrument analytics
        let arrInstruments = [];
        let arrPeers = [];
        arrInstruments =
            userPreferencesPinned
                ?.filter((pinned) => pinned.type === "instrument")
                ?.map((item) => item.symbol) ?? [];

        arrPeers = userPreferencesPinned?.filter(
            (pinned) => pinned.type === "peer"
        );
        let arrTemp: any[] = [];

        for (let element of arrPeers) {
            arrTemp.push({
                name: getName(element, null, environment, format),
                value: element,
            });
        }

        try {
            fetchInstrumentsAnalytics(
                arrInstruments,
                environment,
                format,
                arrTemp,
                setArrItems
            );
        } catch (error) {}
    }, [environment, format]);

    const updateSelectorStatus = (obj: any) => {
        setSelectorStatus(obj);
    };

    const getState = (selectorStatus) => {
        var value = {
            type: "",
        };

        var searchInstrument = selectorStatus?.instrument ?? null;

        var instrument: SelectorStatus["instrument"] = null;
        if (searchInstrument != null) {
            instrument = searchInstrument;
        }

        if (instrument != null) {
            value["type"] = "instrument";
            value["symbol"] = instrument["symbol"];
        } else {
            var size = selectorStatus?.size ?? null;
            var what = selectorStatus?.sector ?? null;
            var where = selectorStatus?.market ?? null;

            if (what == null) {
                what = "ICB";
            }

            if (where == null) {
                where = "WWW";
            }

            if (size == null) {
                size = "microLarge";
            }

            value["type"] = "peer";
            value["instrumentType"] = "Stock";
            value["size"] = size;
            value["what"] = what;
            value["where"] = where;
        }
        var name = getName(value, instrument, environment, format);
        return {
            name: name,
            value: value,
        };
    };

    const addItemToList = () => {
        let arrTemp: any = [...arrItems, getState(selectorStatus)];

        // removing duplicates
        let filtered: any = [];
        let names: string[] = [];
        arrTemp.forEach((element) => {
            if (!names.includes(element.name)) {
                filtered.push(element);
                names.push(element.name);
            }
        });
        // ---------
        setArrItems(filtered);
        filtered = filtered.map((item) => item.value);
        onSavePreferences(filtered);
        setIsArrChanged(true);
    };

    return (
        <div className={style.tPinnedPreferences}>
            <div className={style.tPinnedPreferences__container}>
                {/* ----- */}
                <div className={style.column}>
                    <ReactList
                        data={arrItems}
                        onSavePreferences={onSavePreferences}
                        updatePinnedContentState={setArrItems}
                        onChangeList={setIsArrChanged}
                    />
                </div>

                {/* ----- */}
                <div className={style.column}>
                    <Panelright
                        showSelector={isAddShowVisible}
                        updateSelectorStatus={updateSelectorStatus}
                    />
                </div>
            </div>

            {/* buttons */}

            <div className="tLayout" style={{ minHeight: 44 }}>
                <div className="tLayout--width-60-spaced">
                    {!isAddShowVisible && (
                        <div style={{ marginTop: 10, textAlign: "right" }}>
                            <div>
                                <button
                                    className="tPinnedPreferences-buttonAddShow button"
                                    onClick={() => {
                                        setIsAddShowVisible(true);
                                    }}
                                    title="Add relevant market to the list"
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {isAddShowVisible && (
                    <div className="tLayout--width-38-spaced">
                        <div style={{ marginTop: 10, textAlign: "right" }}>
                            <button
                                className="tPinnedPreferences-buttonAddHide button"
                                title="Cancel"
                                onClick={() => {
                                    setIsAddShowVisible(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                style={{ marginLeft: "10px" }}
                                className="tPinnedPreferences-buttonAdd button button--primary"
                                title="Add"
                                onClick={addItemToList}
                                disabled={!areSelectorsTouched}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="">
                <div
                    style={{
                        marginTop: 10,
                        textAlign: "right",
                        marginBottom: 8,
                    }}
                >
                    <button
                        className="tPinnedPreferences-buttonClose button button--primary"
                        title="Close dialog"
                        onClick={reloadOnClosePinned}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}
