import { Box } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../deepClone";
import Fees from "../CreateContentReact/cards/Fees";
import UpcomingRebalances from "../CreateContentReact/cards/UpcomingRebalances";
import Backtesting from "./Cards/Backtesting";
import BasicInfo from "./Cards/BasicInfo";
import { editContentReducer, initState } from "./EditContentReactReducer";

type Props = {
  lists: any;
  strategies: any;
  product: any;
  onUpdateBacktesting: any;
  hideBacktestOverride: boolean;
};

const EditContentReact = forwardRef(
  (
    {
      lists,
      strategies,
      onUpdateBacktesting,
      product,
      hideBacktestOverride,
    }: Props,
    ref
  ) => {
    const [state, dispatch] = useImmerReducer(editContentReducer, initState);
    // const [granularity, setGranularity] = useState("MONTHLY");

    // const environment = useEnvironment();
    // const strategyAPI = useMemo(
    //   () => new Strategies(environment.get("setup")),
    //   [environment]
    // );

    // const granularityLabel = useMemo(() => {
    //   switch (granularity) {
    //     case "MONTHLY":
    //       return "The Strategy granularity: monthly";
    //     case "QUARTERLY":
    //       return "The Strategy granularity: quarterly";
    //     case "WEEKLY":
    //       return "The Strategy granularity: weekly";
    //   }
    // }, [granularity]);

    useEffect(() => {
      if (product) {
        let _product = deepClone(product);
        if (
          _product.expenseRatio === null ||
          _product.expenseRatio === undefined
        ) {
          _product.expenseRatio = 0;
        }
        // setGranularity(_product.reviewGranularity);
        dispatch({ type: "INIT", value: _product });
      }
    }, [dispatch, product]);

    //! run button must be disabled if something has changed.
    const runButtonIsEnabled = useMemo(() => {
      if (
        state.basic.name === product.name &&
        state.basic.benchmark === product.benchmark &&
        state.basic.currency === product.currency &&
        state.basic.strategyId === product.strategyId &&
        state.basic.expenseRatio === product.expenseRatio &&
        state.basic.autoRebalance === product.autorebalance
      ) {
        return true;
      }
      return false;
    }, [
      product.autorebalance,
      product.benchmark,
      product.currency,
      product.expenseRatio,
      product.name,
      product.strategyId,
      state.basic.autoRebalance,
      state.basic.benchmark,
      state.basic.currency,
      state.basic.expenseRatio,
      state.basic.name,
      state.basic.strategyId,
    ]);

    //#region HANDLERS
    const nameHandler = useCallback(
      (name) => dispatch({ type: "SET_NAME", value: name }),
      [dispatch]
    );
    const strategyIDHandler = useCallback(
      async (strategyID) => {
        // const response = await strategyAPI.fetch([strategyID], ["object"]);
        // const strategy = response?.data?.[0]?.rows?.[0]?.object ?? null;

        // if (strategy) {
        //   const granularity = strategy.params.backtesting.reviewGranularity;

        //   // setGranularity(granularity);
        // }

        dispatch({ type: "SET_STRATEGY", value: strategyID });
      },
      [dispatch]
    );
    const expenseRationHandler = useCallback(
      (expense) => dispatch({ type: "SET_EXPENSE_RATIO", value: expense }),
      [dispatch]
    );
    const currencyHandler = useCallback(
      (currency) => dispatch({ type: "SET_CURRENCY", value: currency }),
      [dispatch]
    );
    const benchmarkHandler = useCallback(
      (benchmarkValue) =>
        dispatch({ type: "SET_BENCHMARK", value: benchmarkValue }),
      [dispatch]
    );
    const autoRebalanceHandler = useCallback(
      (autorebalance) =>
        dispatch({
          type: "SET_AUTOREBALANCE",
          value: autorebalance.target.checked,
        }),
      [dispatch]
    );
    const backtestingHandler = useCallback(() => {
      var value = {
        historicalPortfolioId: product.historicalPortfolioId,
        id: product.id,
        name: product.name,
        strategyId: product.strategyId,
      };
      onUpdateBacktesting({ value: value });
    }, [
      onUpdateBacktesting,
      product.historicalPortfolioId,
      product.id,
      product.name,
      product.strategyId,
    ]);
    //#endregion

    const basicInfoRef = useRef<any>(null);
    const feesRef = useRef<any>(null);

    const validateName = useCallback((name: string) => {
      if (name.length > 0) {
        basicInfoRef?.current?.validateName(true);
        return true;
      }
      basicInfoRef?.current?.validateName(false);
      return false;
    }, []);
    const validateFee = useCallback((fee) => {
      if (fee != null) {
        feesRef?.current?.validateFee(true);
        return true;
      }
      feesRef?.current?.validateFee(false);
      return false;
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        getState: () => {
          const isNameValid = validateName(state.basic.name);
          const isFeeValid = validateFee(state.basic.expenseRatio);
          if (isNameValid === true && isFeeValid === true) {
            return { ...state };
          }
          return null;
        },
      }),
      [state, validateFee, validateName]
    );

    return (
      <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={2}>
        <Box display={"flex"}>
          <BasicInfo
            ref={basicInfoRef}
            currencyHandler={currencyHandler}
            nameHandler={nameHandler}
            strategyIDHandler={strategyIDHandler}
            benchmarkHandler={benchmarkHandler}
            strategyID={state.basic.strategyId}
            name={state.basic.name}
            strategies={strategies}
            currency={state.basic.currency}
            benchmark={state.basic.benchmark}
          />
        </Box>

        <Box display={"flex"} gap={2}>
          <UpcomingRebalances
            value={state.basic.autoRebalance}
            handler={autoRebalanceHandler}
            // message={granularityLabel}
          />
          <Fees
            ref={feesRef}
            managementFeesHandler={(e) => {
              let fee = e.target.value;
              if (fee.length > 0) {
                fee = parseFloat(fee) / 100;
                expenseRationHandler(fee);
              } else {
                expenseRationHandler(null);
              }
            }}
            expenseRatio={state.basic.expenseRatio}
          />
        </Box>
        {!hideBacktestOverride && (
          <Box display={"flex"}>
            <Backtesting
              disabled={!runButtonIsEnabled}
              onUpdateBacktesting={backtestingHandler}
            />
          </Box>
        )}
      </Box>
    );
  }
);
export default EditContentReact;
