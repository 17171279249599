import { mergePropertiesToObjects } from "../../../../../../../api/compute/commons";
import { deepClone } from "../../../../../../../deepClone";

export const _dataGetTags = (response, http, httpAll) => {
  function mapId(item) {
    return item.id;
  }

  var publications = deepClone(response.publications);
  var strategies = publications.strategies;
  var systematicPortfolios = publications.systematicPortfolios;

  var requests = {
    strategies: http["strategies"].getTags(strategies.map(mapId)),
    systematicPortfolios: http["systematicProducts"].getTags(
      systematicPortfolios.map(mapId)
    ),
  };

  return httpAll(requests).then(function (tagsResponse) {
    publications.strategies = mergePropertiesToObjects({
      properties: ["tags"],
      source: tagsResponse.strategies,
      target: publications.strategies,
    });

    publications.systematicPortfolios = mergePropertiesToObjects({
      properties: ["strategyId", "strategyName", "tags"],
      source: tagsResponse.systematicPortfolios,
      target: publications.systematicPortfolios,
    });

    return {
      publications: publications,
      subscriptions: response.subscriptions,
    };
  }, publications);
};
export const _dataPrepare = (response, setOpetions, setStore) => {
  var publications = deepClone(response.publications);
  var subscriptions = deepClone(response.subscriptions);

  var subscriptionsMap: any = {};
  var subscriptionsItem: any = null;
  var subscriptionsItems: any = null;
  for (var type in subscriptions) {
    subscriptionsItems = subscriptions[type];

    subscriptionsMap[type] = {};

    for (var i = 0, length = subscriptionsItems.length; i < length; i++) {
      subscriptionsItem = subscriptionsItems[i];
      subscriptionsMap[type][subscriptionsItem.id] = subscriptionsItem;
    }
  }

  var pubSubs = [];
  for (const type in publications) {
    publications[type].map(function (item) {
      item._s_id = type + "_" + item.id;
      item._s_name = item.name.toLowerCase();

      item.isSubscribed = false;
      item.subscriptionId = null;
      if (subscriptionsMap[type] && item.id in subscriptionsMap[type]) {
        item.isSubscribed = true;
        item.subscriptionId = subscriptionsMap[type][item.id].subscriptionId;
      }

      return item;
    });

    publications[type].sort(function (a, b) {
      if (a._s_name > b._s_name) {
        return 1;
      }

      if (a._s_name < b._s_name) {
        return -1;
      }

      return 0;
    });

    pubSubs = pubSubs.concat(publications[type]);
  }

  function filterLists(type) {
    return function (item) {
      if (item.type === type) {
        return true;
      }

      return false;
    };
  }

  function mapLists(item) {
    var option = {
      checked: item.isSubscribed,
      label: item.name,
      value: item._s_id,
    };

    return option;
  }

  var options = {};
  for (const type in publications) {
    if (type === "lists") {
      options["baskets"] = publications[type]
        .filter(filterLists("BASKET"))
        .map(mapLists);

      options["portfolios"] = publications[type]
        .filter(filterLists("PORTFOLIO"))
        .map(mapLists);
    } else {
      options[type] = publications[type].map(mapLists);
    }
  }
  setOpetions(options);

  // for (let i = 0; i < pubSubs.length; i++) {
  //   const item = pubSubs[i];
  //   if (item.tags != null && item.tags.type === "list") {
  //     item["_s_tags"] = item["tags"]["data"];
  //   }
  // }

  // const store = new Memory({
  //   data: pubSubs,
  //   idProperty: "_s_id",
  // });
  setStore(pubSubs);

  // return this.render();
};

export const _testingDataPrepare = (
  response,
  setOpetions,
  setStore,
  isPortfolio: boolean | null,
  isRanking: boolean,
  isSystematicPortfolio:boolean,
  type:
    | "list"
    | "ranking"
    | "strategy"
    | "systematicPortfolio"
    | "combinedStrategy"
    | "combinedProduct"
) => {
  var publications = deepClone(response.publications);
  var subscriptions = deepClone(response.subscriptions);

  var subscriptionsMap: any = {};
  var subscriptionsItem: any = null;
  var subscriptionsItems: any = null;
  subscriptionsItems = subscriptions;

  subscriptionsMap = {};

  for (var i = 0, length = subscriptionsItems.length; i < length; i++) {
    subscriptionsItem = subscriptionsItems[i];
    subscriptionsMap[subscriptionsItem.id] = subscriptionsItem;
  }

  var pubSubs = [];
  // for (const type in publications) {
  publications.map(function (item) {
    item._s_id = item.pubSubType + "_" + item.id;
    item._s_name = item.name.toLowerCase();

    item.isSubscribed = false;
    item.subscriptionId = null;
    if (subscriptionsMap && item.id in subscriptionsMap) {
      item.isSubscribed = true;
      item.subscriptionId = subscriptionsMap[item.id].subscriptionId;
    }

    return item;
  });

  publications.sort(function (a, b) {
    if (a._s_name > b._s_name) {
      return 1;
    }

    if (a._s_name < b._s_name) {
      return -1;
    }

    return 0;
  });

  pubSubs = pubSubs.concat(publications);
  // }

  function filterLists(type) {
    return function (item) {
      if (item.type === type) {
        return true;
      }

      return false;
    };
  }

  function mapLists(item) {
    var option = {
      checked: item.isSubscribed,
      label: item.name,
      value: item._s_id,
    };

    return option;
  }

  var options = [];

  if (isPortfolio === true) {
    options = publications.filter(filterLists("PORTFOLIO")).map(mapLists);
  } else if (isPortfolio === false) {
    options = publications.filter(filterLists("BASKET")).map(mapLists);
  } else if (isRanking === true) {
    options = publications.filter(filterLists("RANKING_ABOUT_TARGET")).map(mapLists);
  } else if(isSystematicPortfolio ===true){
    options = publications.filter((item)=>{
      if (item.pubSubType === "systematicPortfolio") {
        return true;
      }
      return false;
    }).map(mapLists);
  } else {
    options = publications.map(mapLists);
  }
  setOpetions(options);
  setStore(pubSubs);
};
