/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/input/CollectStrategies
 * @summary Retrieves data to build report
 *
 */

import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import { Strategies } from "../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../api/compute/SystematicProducts";
import { Utils } from "../../../../api/compute/Utils";
import { httpAll } from "../../../../httpAll";
import {
  CombinedStrategy,
  List,
  ListPosition,
  SystematicProduct,
} from "../../../../types/Api";
import { AppEnvironment } from "../../../../types/Defaults";
import { StrategiesStorage } from "../../../app/storage/StrategiesStorage";
import { sectionProperties } from "../../configuration/sectionProperties";
import { WysiwygState } from "../Types";
import { prepareCustomStrategyAnalitycs } from "../Utils";

export class CollectStrategies {
  apiInstruments: Instruments;
  apiLists: Lists;
  apiStrategies: Strategies;
  apiUtils: Utils;
  apiSystematic: SystematicProducts;
  strategyStorage: StrategiesStorage;

  //! custom fields for output
  strategyFinalDate: any; // End / final date computed from strategy run
  strategyLaunchDate: any; // Starting / launch date computed from strategy run
  hasStrategyFinalDate: any; // End / final date set by user
  hasStrategyLaunchDate: any; // Starting / launch date set by user

  constructor(
    protected readonly environment: AppEnvironment,
    protected readonly wysiwygState: WysiwygState,
    protected readonly launchDate?: number,
    protected readonly finalDate?: number
  ) {
    this.apiInstruments = environment.http.instruments;
    this.apiLists = environment.http.lists;
    this.apiStrategies = environment.http.strategies;
    this.strategyStorage = wysiwygState.storage;
    this.apiSystematic = new SystematicProducts(environment);
    this.apiUtils = new Utils(environment);
  }

  async retrieve() {
    const wysiwygState = this.wysiwygState;
    // Check type so later the target is of correct type
    if (
      wysiwygState.targetType !== "COMBINED_STRATEGY" &&
      wysiwygState.targetType !== "STRATEGY"
    ) {
      throw new Error(
        "Invalid target type, must be STRATEGY or COMBINED_STRATEGY"
      );
    }

    if (wysiwygState.targetType !== "COMBINED_STRATEGY") {
      const target = wysiwygState.target;

      let mainId: number | null = null;

      if ("strategy1" in target) {
        mainId = (target as any).strategy1.id;
        await this.prepareProxyStrategy(mainId! as number);
      } else if ("product1" in target) {
        mainId = (target as any).product1.id;
        await this.prepareProxyStrategy(mainId! as number);
      }
    }

    const systematicBenchmarkSectionProperties =
      sectionProperties["trendrating-report/systematic-portfolios/benchmark"][
        "properties"
      ];

    let strategyRun: any = {
      data: await this.strategyStorage.getCurves(
        this.launchDate,
        this.finalDate
      ),
    };

    // Cut strategy
    this.strategyLaunchDate = null; // Needs to be reset
    this.hasStrategyLaunchDate = this.launchDate != null;
    this.hasStrategyFinalDate = this.finalDate != null;

    const strategyPriceLevel = strategyRun?.data?.CURVES?.H;
    const benchmarkPriceLevel = strategyRun?.data?.CURVES?.B;
    const POS = strategyRun?.data?.POS?.H;

    const customStrategyAnalitycs = prepareCustomStrategyAnalitycs(
      this.launchDate,
      this.finalDate,
      strategyPriceLevel,
      POS,
      benchmarkPriceLevel
    );
    this.strategyLaunchDate = customStrategyAnalitycs.launchDate;
    this.strategyFinalDate = customStrategyAnalitycs.finalDate;

    const strategyRunCutted = customStrategyAnalitycs.strategyRun;
    // End cut strategy

    // Cut Benchmark on strategy
    const benchmarkCurve = strategyRun?.data?.CURVES?.B;
    const cuttedBenchmark: any = [];

    const finalDate = this.finalDate ?? this.strategyFinalDate;

    if (benchmarkCurve) {
      benchmarkCurve.forEach((point) => {
        if (point.d >= this.strategyLaunchDate && point.d <= finalDate) {
          cuttedBenchmark.push(point);
        }
      });

      strategyRunCutted.data.CURVES.B = cuttedBenchmark;
    }

    const response: {
      strategy: any;
    } = {
      strategy: strategyRunCutted,
    };

    const allocations = response.strategy.data.POS;
    let paramsHoldings;
    if (allocations != null) {
      const lastAllocationIndex = allocations.length - 1;
      const lastAllocation = allocations[lastAllocationIndex];

      if (!("de" in lastAllocation)) {
        const utilsDate = await this.apiUtils.today();

        lastAllocation["de"] = utilsDate.today;
      }
      paramsHoldings = {
        allocation: lastAllocation,
        instrumentsProperties: [
          {
            date: null,
            property: "country",
          },
          {
            date: null,
            property: "icb",
          },
          {
            date: null,
            property: "marketcap",
          },
          {
            date: null,
            property: "name",
          },
          {
            date: null,
            property: "rc",
          },
          {
            date: null,
            property: "symbol",
          },
          {
            date: null,
            property: "ticker",
          },
          {
            date: null,
            property: "type",
          },
          {
            date: null,
            property: "vc",
          },
        ],
      };
    }

    let paramsBenchmark;
    const hasBenchmark =
      wysiwygState?.target?.params?.strategy?.benchmark != null;
    if (hasBenchmark) {
      const benchmark = wysiwygState.target.params.strategy.benchmark;
      if (benchmark) {
        const blendedBenchmarkTag = "COLLECTION";
        const isBlendedBenchmark = benchmark.includes(blendedBenchmarkTag);
        if (isBlendedBenchmark) {
          const splitBenchmarkValue = benchmark.split(":");
          const blendedTarget = parseInt(splitBenchmarkValue[1]);

          paramsBenchmark = {
            properties: systematicBenchmarkSectionProperties,
            symbols: [blendedTarget],
            type: "list",
          };
        } else {
          paramsBenchmark = {
            properties: systematicBenchmarkSectionProperties,
            symbols: [benchmark],
            type: "security" as const,
          };
        }
      }
    }

    let paramsInstrumentHedging;
    if (wysiwygState?.target?.params?.hedging?.instrument != null) {
      paramsInstrumentHedging = {
        properties: systematicBenchmarkSectionProperties,
        symbols: [wysiwygState.target.params.hedging.instrument],
        type: "security" as const,
      };
    }

    const getBenchmark = hasBenchmark
      ? wysiwygState?.target?.params?.strategy?.benchmark?.includes(
          "COLLECTION"
        )
        ? this.apiLists.getListAnalytics(paramsBenchmark.symbols)
        : this.apiInstruments.fetch(paramsBenchmark)
      : null;

    // any does ignore the "| null", but after using the correcty type
    // it will be needed
    // TODO type all remaining "any" types
    const responseCompute: {
      strategy: {
        analytics: any | null;
        benchmark: {
          data: any;
        } | null;
        holdings: ListPosition[] | null;
        instrumentHedging: any | null;
        keyFacts: any | null;
        performances: any | null;
        whiteList: List | null;
      };
    } = {
      strategy: await httpAll({
        analytics: await this.strategyStorage.getAnalytics(
          "analytics",
          this.launchDate,
          this.finalDate
        ),
        benchmark: paramsBenchmark != null ? getBenchmark : null,
        holdings:
          paramsHoldings != null
            ? this.apiStrategies.holdings(paramsHoldings)
            : null,
        instrumentHedging:
          paramsInstrumentHedging != null
            ? this.apiInstruments.fetch(paramsInstrumentHedging)
            : null,
        keyFacts: await this.strategyStorage.getAnalytics(
          "keyFacts",
          this.launchDate,
          this.finalDate
        ),
        performances: null,
        whiteList:
          wysiwygState?.target?.params?.universe?.whiteList?.id != null
            ? this.apiLists.get(
                wysiwygState.target.params.universe.whiteList.id
              )
            : null,
      }),
    };

    if (
      responseCompute.strategy.benchmark != null &&
      wysiwygState.target?.params?.strategy?.benchmark ===
        this.apiStrategies.SYMBOL_NEUTRAL_STRATEGY
    ) {
      responseCompute.strategy.benchmark.data.push(
        this.apiStrategies.SYMBOL_NEUTRAL_STRATEGY
      );
    }

    if (
      responseCompute.strategy.benchmark != null &&
      wysiwygState.target?.params?.strategy?.benchmark ===
        this.apiStrategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
    ) {
      responseCompute.strategy.benchmark.data.push(
        this.apiStrategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
      );
    }

    const computing: {
      instruments: ListPosition[];
    } = {
      instruments:
        responseCompute.strategy.holdings?.map((instrument) => ({
          symbol: instrument.symbol,
          weight: instrument.weight,
        })) ?? [],
    };

    return {
      computing,
      response,
      responseCompute,
    };
  }

  private async prepareProxyStrategy(strategyId) {
    let strategy: any = null;

    if (this.wysiwygState.target.type === "COMBINED_PRODUCT") {
      const product: SystematicProduct = await this.apiSystematic.get({
        id: strategyId,
      });
      strategy = await this.apiStrategies.getById(product.strategyId);
    } else {
      strategy = await this.apiStrategies.getById(strategyId);
    }

    if (strategy) {
      const combinedStrategy = this.wysiwygState.target as CombinedStrategy;

      const combinedStrategyName = combinedStrategy.name;

      if (strategy != null) {
        // Adjust benchmark
        let benchmarkString: any = null;

        if (combinedStrategy?.benchmark) {
          if (typeof combinedStrategy.benchmark === "string") {
            benchmarkString = combinedStrategy.benchmark;
          } else {
            benchmarkString = combinedStrategy.benchmark?.item?.symbol ?? null;
          }

          strategy["params"]["strategy"]["benchmark"] = benchmarkString;
        } else {
          strategy["params"]["strategy"]["benchmark"] = null;
        }

        // Adjust currency, if missing do not change the
        // strategy value
        if (combinedStrategy["currency"] != null) {
          strategy["params"]["strategy"]["currency"] =
            combinedStrategy["currency"];
        }
        // Adjust period, if missing do not change the
        // strategy value
        if (combinedStrategy["period"] != null) {
          strategy["params"]["backtesting"]["period"] =
            combinedStrategy["period"];
        }

        strategy["name"] = combinedStrategyName;
      }

      this.wysiwygState.target = strategy;
    } else {
      throw new Error(
        `Strategy cannot be null, failed to collect data for strategy: ${strategyId}`
      );
    }
  }
}
