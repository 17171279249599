import { Box, Typography } from "@mui/material";
import { rateScale } from "../Chart";
import { styled } from "@mui/system";
import styles from "./IllustrationTCR.module.scss";

const colorsMap = rateScale;

const linearGradient = `linear-gradient(${colorsMap.A.color}, ${colorsMap.B.color}, ${colorsMap.C.color}, ${colorsMap.D.color})`;

const StyledColoredBar = styled((props: any) => (
  <Box className={props.className}></Box>
))({
  height: "100%",
  background: linearGradient,
});

export function IllustrationTCR() {
  return (
    <Box className={styles.outerBox}>
      <Box className={styles.coloredBarCol}>
        <StyledColoredBar />
      </Box>
      <Box className={styles.ratingBar}>
        <Box className={styles.ratingIndicatorBoxWrapper}>
          <Box className={styles.ratingIndicatorBox}>
            <Typography>A</Typography>
            <Typography>A-</Typography>
            <Typography>B+</Typography>
          </Box>

          <Box
            className={styles.textBox}
            title={"> 90% of holdings in Bull trend"}
          >
            <Typography className={styles.ellipsisText}>
              {"> 90% of holdings in Bull trend"}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.ratingIndicatorBoxWrapper}>
          <Box className={styles.ratingIndicatorBox}>
            <Typography>B</Typography>
            <Typography>B-</Typography>
          </Box>

          <Box
            className={styles.textBox}
            title={"> 30% of holdings in Bear trend"}
          >
            <Typography className={styles.ellipsisText}>
              {"> 30% of holdings in Bear trend"}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.ratingIndicatorBoxWrapper}>
          <Box className={styles.ratingIndicatorBox}>
            <Typography>C+</Typography>
            <Typography>C</Typography>
            <Typography>C-</Typography>
            <Typography>D+</Typography>
            <Typography>D</Typography>
          </Box>

          <Box
            className={styles.textBox}
            title={"> 50% of holdings in Bear trend"}
          >
            <Typography className={styles.ellipsisText}>
              {"> 50% of holdings in Bear trend"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
