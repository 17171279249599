import { Button, ClickAwayListener, Portal } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import CreateListDialog from "../SecurityTooltip/CreateListDialog";
import { newGetSymbols } from "../SecurityTooltip/SecurityTooltipCore";
import { config } from "./../../js/app/config-ts";
// This style import is because originally this button was binded to a component
import { useEnvironment } from "../../hooks/useEnvironment";
import styles from "./../SecurityTooltip/SecurityTooltip.module.scss";
import { useBroadcast } from "../../hooks/useBroadcast";

type AddToButtonProps = {
  disabled?: boolean;
  securities?: any[];
  getSecuritiesFunc?: Function;
};

export function AddToButton({
  disabled,
  securities,
  getSecuritiesFunc,
}: AddToButtonProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [showNestedMenuBasket, setShowNestedMenuBasket] = useState(false);
  const [showNestedMenuPortfolio, setShowNestedMenuPortfolio] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const buttonRef = useRef<any>(null);
  const nestedListTargetRefBasket = useRef(null);
  const nestedListTargetRefPortfolio = useRef(null);

  const environment = useEnvironment();
  const [securitiesToAdd, setSecuritiesToAdd] = useState<any>(securities ?? []);
  const portfolios = useRef<any>([]);
  const baskets = useRef<any>([]);

  const getUserCollections = useCallback(async () => {
    const userCollections = await environment
      .get("setup")
      ["account"]["user"]["data"]["collections"].get();

    if (userCollections) {
      const userPortfolios: any = [];
      const userBaskets: any = [];

      for (const list of userCollections) {
        if (!list.isReadOnly) {
          if (list.type.toLowerCase() === "portfolio") {
            userPortfolios.push(list);
          } else if (list.type.toLowerCase() === "basket") {
            userBaskets.push(list);
          }
        }
      }

      userPortfolios.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName > bName) {
          return 1;
        } else if (aName < bName) {
          return -1;
        }

        return 0;
      });

      userBaskets.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName > bName) {
          return 1;
        } else if (aName < bName) {
          return -1;
        }

        return 0;
      });

      portfolios.current = userPortfolios;
      baskets.current = userBaskets;
    }
  }, [environment]);

  useEffect(() => {
    getUserCollections();
  }, [getUserCollections]);

  const addTo = async (securities, collection, environment) => {
    var newPositions = newGetSymbols(securities, collection.type, true);
    const apiLists = environment.get("http")["lists"];

    const response = await apiLists.get(collection["id"]);

    await addToHelper(newPositions, response, apiLists);
  };

  const { broadcast } = useBroadcast();

  const addToHelper = async (newPositions, list, apiList) => {
    list["positions"] = list["positions"].concat(newPositions);

    await apiList.update(list);

    //Use the non updated list to get name and type used to be sended to the topic
    const oldList = list;

    const message = {
      from: "Routes",
      content: {
        type: "success",
        text:
          "Item/s saved in " +
          oldList.type.toLowerCase() +
          " <strong>" +
          oldList.name +
          "</strong>.",
      },
    };

    broadcast(config["channels"]["feedback"]["input"], message);

    //********************** USAGE *************************
    var usage = window.App.usage;
    var info = {
      action: "ADD_TO",
      actionParam: list["id"],
      function: "MESSAGE",
    };
    usage.record(info);
    //********************** USAGE *************************
  };

  const onAddToExisting = (collection, environment) => {
    setShowMenu(false);
    setShowNestedMenuBasket(false);
    setShowNestedMenuPortfolio(false);
    addTo(securitiesToAdd, collection, environment);
  };

  const [dialogType, setDialogType] = useState<"PORTFOLIO" | "BASKET" | null>();

  const onAddToNew = (type) => {
    setShowMenu(false);
    setDialogType(type);
    setShowNestedMenuBasket(false);
    setShowNestedMenuPortfolio(false);
    setShowModal(true);
  };

  const handleClickOutside = useCallback(() => {
    setShowMenu(false);
    setShowNestedMenuBasket(false);
    setShowNestedMenuPortfolio(false);
  }, []);

  const onCloseCreateModal = useCallback(() => {
    setDialogType(null);
    setShowModal(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <div>
        <CreateListDialog
          type={dialogType ?? "BASKET"}
          security={securitiesToAdd}
          showModal={showModal}
          setShowModal={onCloseCreateModal}
          environment={environment}
        />
        <Button
          variant="outlined"
          disabled={disabled}
          size={"small"}
          sx={{
            marginLeft: "10px",
            borderColor: "#2a7090",
            color: "#2a7090",
            fontSize: "12px !important",
            textTransform: "none",
          }}
          onClick={() => {
            if (getSecuritiesFunc) {
              setSecuritiesToAdd(getSecuritiesFunc());
            }
            setShowMenu(true);
          }}
          ref={buttonRef}
        >
          Add to
        </Button>
        {showMenu && (
          <ContextMenu targetRef={buttonRef} isNested={false}>
            <li
              onMouseEnter={() => {
                setShowNestedMenuBasket(true);
                setShowNestedMenuPortfolio(false);
              }}
              ref={nestedListTargetRefBasket}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Baskets <span style={{ fontSize: 10 }} className="i-right"></span>
              {/* Nested Menu */}
              {showNestedMenuBasket && (
                <ContextMenu
                  targetRef={nestedListTargetRefBasket}
                  isNested={true}
                >
                  {baskets.current.map((basket) => (
                    <li
                      onClick={() => onAddToExisting(basket, environment)}
                      key={`${basket.name}__${basket.id}`}
                    >
                      {basket?.name ?? ""}
                    </li>
                  ))}
                </ContextMenu>
              )}
            </li>
            <li
              onMouseEnter={() => {
                setShowNestedMenuPortfolio(true);
                setShowNestedMenuBasket(false);
              }}
              ref={nestedListTargetRefPortfolio}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Portfolios{" "}
              <span style={{ fontSize: 10 }} className="i-right"></span>
              {/* Nested Menu */}
              {showNestedMenuPortfolio && (
                <ContextMenu
                  targetRef={nestedListTargetRefPortfolio}
                  isNested={true}
                >
                  {portfolios.current.map((portfolio) => (
                    <li
                      onClick={() => onAddToExisting(portfolio, environment)}
                      key={`${portfolio.name}__${portfolio.id}`}
                    >
                      {portfolio?.name ?? ""}
                    </li>
                  ))}
                </ContextMenu>
              )}
            </li>
            <li
              onMouseEnter={() => {
                setShowNestedMenuBasket(false);
                setShowNestedMenuPortfolio(false);
              }}
              onClick={() => onAddToNew("BASKET")}
            >
              {" "}
              New Basket
            </li>
            <li
              onMouseEnter={() => {
                setShowNestedMenuBasket(false);
                setShowNestedMenuPortfolio(false);
              }}
              onClick={() => onAddToNew("PORTFOLIO")}
            >
              {" "}
              New Portfolio
            </li>
          </ContextMenu>
        )}
      </div>
    </ClickAwayListener>
  );
}

const ContextMenu = ({ targetRef, offset, children, isNested }: any) => {
  const showMenu = useCallback(
    (menu) => {
      const target = targetRef?.current ?? null;

      if (target && menu) {
        const targetDims = target.getBoundingClientRect();

        const { left, top, width, height } = targetDims;

        if (!isNested) {
          menu.style.top = `${offset ?? 0 + top + height}px`;
          menu.style.left = `${left}px`;
        } else {
          menu.style.top = `${top}px`;
          menu.style.left = `${left + width}px`;
        }
      }
    },
    [isNested, offset, targetRef]
  );

  return (
    <Portal>
      <ul className={styles.context__menu} ref={showMenu}>
        {children}
      </ul>
    </Portal>
  );
};
