import { deepClone } from "../../../deepClone";
import { sortBy } from "../../../trendrating/core/UtilsObject";
import { Formatter } from "../../../trendrating/formatter/Formatter";
import { FormOptions } from "../../trendrating-widgets/form/FormOptions";
/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/SectionPeer
 * @summary Generates report data about peer sections
 *
 */
import { i18n } from "../nls/i18n-report";
import { addEmptyRow, escapeEntity } from "./Generator";

export class SectionPeer {
  format: any;

  constructor() {
    this.format = new Formatter();
  }

  generatePeerTable(data, section) {
    const sections: any = [];
    const timeframe = section.content.timeframe;
    // header
    if (section["content"]["headline"]["isEnabled"]) {
      let headline = this._formatHeadline(
        section["content"]["headline"]["content"]
      );

      if (section["content"]["headline"]["showTimeframeInTitle"] === true) {
        headline += ` - ${this._formatTimeframe(section)}`;
      }

      const _section = {
        data: {
          text: headline,
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const columnsFromSection = section?.presentation?.columns ?? [];
    const layout: any = {
      data: {
        body: [],
        head: [],
      },
      type: "table",
    };

    const sort = section?.content?.sort;

    if (sort != null) {
      sortBy(data.items, sort.property, sort.descending);
    }

    let row: any = [];
    const fields: (
      | "name"
      | "tcr"
      | "cardinality"
      | "abPercentage"
      | "cdPercentage"
      | "tcrAlerts"
      | "abChanges"
      | "upgrades"
      | "downgrades"
      | "upgradesPerc"
      | "downgradesPerc"
    )[] = [];

    for (const col of columnsFromSection) {
      row.push({
        style: null,
        value:
          col.label === "name"
            ? this.getLabelFromSegment(section["content"]["segment"], "stock")
            : col.label,
      });
      fields.push(col.property);
    }

    layout.data.head.push(row);

    const formatValue = (field, dataRow) => {
      switch (field) {
        case "name": {
          return dataRow[field];
        }
        case "tcr": {
          return this._formatTcr(dataRow[field]);
        }
        case "upgrades":
        case "downgrades":
        case "cardinality": {
          return JSON.stringify(dataRow[field]);
        }
        case "abPercentage":
        case "cdPercentage":
        case "downgradesPerc":
        case "upgradesPerc": {
          return this._formatPercentage(dataRow[field]);
        }

        case "abChanges": {
          let arrow = "";
          switch (dataRow[field]) {
            case 1: {
              arrow = "&uarr;";

              break;
            }
            case -1: {
              arrow = "&darr;";

              break;
            }
            case 0:
            default: {
              arrow = "";

              break;
            }
          }

          const percentageValueHTML = this._formatPercentage(
            dataRow["abChangesPercentageFrom"]
          );

          return "<span>" + arrow + " " + percentageValueHTML + "</span>";
        }

        case "tcrAlerts": {
          const data = dataRow;
          const tcr = data["tcr"];
          const timeframeKey = timeframe;
          const tcrFrom =
            timeframe === "today"
              ? (data as any).tcrInfo["yesterday"]
              : (data as any).tcrInfo[timeframeKey];

          if (tcr !== tcrFrom) {
            const stringPrefix =
              tcrFrom > tcr ? "downgraded to" : "upgraded to";

            const tcrHTML = this._formatTcr(tcr);
            const tcrFromHTML = this._formatTcr(tcrFrom);

            return `${stringPrefix} ${tcrHTML} from ${tcrFromHTML}`;
          } else {
            return "";
          }
        }
      }
    };

    const bodyRows: any[] = [];

    const rowBuilder = (data) => {
      const newRow: any = [];

      let value = null;

      for (const field of fields) {
        value = formatValue(field, data);
        newRow.push({ style: null, value });
      }

      return newRow;
    };

    for (row of data.items) {
      bodyRows.push(rowBuilder(row));
    }

    layout["data"]["body"] = bodyRows;

    sections.push(layout);

    return sections;
  }

  generateDispersion(data, section, peerType) {
    const sections: any = [];

    if (
      data?.["bottom"] == null ||
      data?.["middle"] == null ||
      data?.["top"] == null
    ) {
      return null;
    }

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }
    //
    // It compares the current interval average to the other interval
    // averages and returns a color accordingly
    //
    // This function is implemented also in
    // app/pages/analysisMarkets/widgets/PeerDispersion._intervalCssClass
    //
    function intervalColor(average, averageThreshold1, averageThreshold2) {
      if (average > 0) {
        if (average > averageThreshold1 && average > averageThreshold2) {
          return "#008000"; // positive high
        }
        if (average > averageThreshold1 || average > averageThreshold2) {
          return "#399608"; // positive normal
        }
        return "#078c52"; // positive low
      }

      if (average < averageThreshold1 && average < averageThreshold2) {
        return "#b00000"; // negative high
      }

      if (average < averageThreshold1 || average < averageThreshold2) {
        return "#f00000"; // negative normal
      }

      return "#f24949"; // negative low
    }

    function intervalLabels(intervals) {
      if (typeof intervals === "string") {
        intervals = parseInt(intervals);
      }

      switch (intervals) {
        case 10: {
          return {
            bottom: i18n["Bottom_10_percent"],
            middle: i18n["Mid_80_percent"],
            top: i18n["Top_10_percent"],
          };
        }
        case 5:
        case 20: {
          return {
            bottom: i18n["Bottom_5_percent"],
            middle: i18n["Mid_90_percent"],
            top: i18n["Top_5_percent"],
          };
        }
        case 25:
        case 4:
        default: {
          return {
            bottom: i18n["Bottom_25_percent"],
            middle: i18n["Mid_50_percent"],
            top: i18n["Top_25_percent"],
          };
        }
      }
    }

    function generateEmptyRows(numberOfEmptyRows) {
      const emptyRows: any = [];

      for (let i = 0; i < numberOfEmptyRows; i++) {
        emptyRows.push([]);
      }

      return emptyRows;
    }

    // Used to generate proportional table for each interval
    //
    // UI allows to choose among:
    //   - 4 quantiles (25%)
    //   - 10 quantiles (10%)
    //   - 20 quantiles (5%)
    //
    // The quantiles are grouped in top, middle and bottom iterval
    //
    // To represent on PDF we need at least a table of 2 rows for each
    // interval
    //
    // The table has 40 rows: 2 rows * 20 quantiles
    let rowsBottom: any = null;
    let rowsMiddle: any = null;
    let rowsTop: any = null;
    switch (section["content"]["intervals"]) {
      case 10: {
        rowsBottom = generateEmptyRows(2); // 4 -2
        rowsMiddle = generateEmptyRows(30); // 32 -2
        rowsTop = generateEmptyRows(2); // 4 -2

        break;
      }
      case 20: {
        rowsBottom = generateEmptyRows(0); // 2 - 2
        rowsMiddle = generateEmptyRows(34); // 36 - 2
        rowsTop = generateEmptyRows(0); // 2 - 2

        break;
      }
      case 4:
      default: {
        rowsBottom = generateEmptyRows(8); // 10 - 2
        rowsMiddle = generateEmptyRows(18); // 20 - 2
        rowsTop = generateEmptyRows(8); // 10 - 2
      }
    }

    const labels = intervalLabels(section["content"]["intervals"]);
    const top = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["top"],
            },
            {
              style: null,
              value:
                data["top"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: {
                align: "right",
              },
              value: "max:",
            },
            {
              style: null,
              value: this._formatPercentageWithSign(data["top"]["max"]),
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["top"]["average"]),
            },
          ],
        ]
          .concat(rowsTop)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: {
                  align: "right",
                },
                value: "min:",
              },
              {
                style: null,
                value: this._formatPercentageWithSign(data["top"]["min"]),
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["top"]["average"],
          data["bottom"]["average"],
          data["middle"]["average"]
        ),
        head: [],
      },
      type: "table",
    };
    const middle = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["middle"],
            },
            {
              style: null,
              value:
                data["middle"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["middle"]["average"]),
            },
          ],
        ]
          .concat(rowsMiddle)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "&nbsp;",
              },
              {
                style: null,
                value: "&nbsp;",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["middle"]["average"],
          data["bottom"]["average"],
          data["top"]["average"]
        ),
        head: [],
      },
      type: "table",
    };
    const bottom = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["bottom"],
            },
            {
              style: null,
              value:
                data["bottom"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: {
                align: "right",
              },
              value: "max:",
            },
            {
              style: null,
              value: this._formatPercentageWithSign(data["bottom"]["max"]),
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["bottom"]["average"]),
            },
          ],
        ]
          .concat(rowsBottom)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: {
                  align: "right",
                },
                value: "min:",
              },
              {
                style: null,
                value: this._formatPercentageWithSign(data["bottom"]["min"]),
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["bottom"]["average"],
          data["middle"]["average"],
          data["top"]["average"]
        ),
        head: [],
      },
      type: "table",
    };

    const _section = {
      data: [[top], [middle], [bottom]],
      type: "peerDispersion",
    };
    sections.push(_section);

    // legend
    const _sectionLegend = {
      data: {
        style: {
          fontSize: "normal",
        },
        text:
          "<br/>" +
          i18n["report_peer_dispersion_legend_$timeframe"].replaceAll(
            // eslint-disable-next-line no-template-curly-in-string
            "${timeframe}",
            this._formatTimeframeDispersion(section)
          ),
      },
      type: "text",
    };
    sections.push(_sectionLegend);

    return sections;
  }

  getLabelFromSegment(segment, peerType) {
    let label = "Sector";

    if (peerType === "ETF") {
      switch (segment) {
        case "1 Industry":
        default:
          return (label = "Asset Class");

        case "3 Sector":
          label = "Specialty";

          break;

        case "4 Subsector":
          label = "Theme";

          break;
        case "3 Level":
          label = "Size";

          break;

        case "Country":
        case "etfgeo":
          label = "Inv. Region";

          break;

        case "Region":
          label = "Region";

          break;

        case "Area":
          label = "Area";

          break;
      }
    } else {
      switch (segment) {
        case "1 Industry":
        default:
          return label;

        case "3 Sector":
          label = "Industry";

          break;
        case "3 Level":
          label = "Size";

          break;

        case "Country":
          label = "Market";

          break;

        case "Region":
          label = "Region";

          break;

        case "Area":
          label = "Area";

          break;
      }
    }

    return label;
  }

  generateDispersionChildren(data, section, peerType) {
    const sections: any = [];
    const clonedSection = deepClone(section);
    // Disable title, add manually
    clonedSection["content"]["headline"]["isEnabled"] = false;

    // header, use original section data
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    for (let i = 0, lengthI = data.length; i < lengthI; i++) {
      const item = data[i];

      if (
        item.dispersion["bottom"] == null ||
        item.dispersion["middle"] == null ||
        item.dispersion["top"] == null
      ) {
        continue;
      }

      // header
      if (item.name != null && item.name !== "") {
        const _section = {
          data: {
            text: this._formatHeadline(this._formatName(item.name, null)),
          },
          type: "header1",
        };
        sections.push(_section);
      }

      const itemSections = this.generateDispersion(
        item.dispersion,
        clonedSection,
        peerType
      );

      if (itemSections == null) {
        continue;
      }

      for (
        let j = 0;
        j < itemSections.length - 1; // skip legend
        j++
      ) {
        const itemSection = itemSections[j];
        sections.push(itemSection);
      }
    }

    return sections;
  }

  generateDispersionChart(data, section, pageOrientation, peerType) {
    const _sections: any = [];
    const timeframeCut = section?.["content"]?.["timeframe"] ?? "";
    if (section["content"]["headline"]["isEnabled"]) {
      const timeframeToAppend = timeframeCut.replace("_", " ");
      const rightSegment = this.getLabelFromSegment(
        section["content"]["segment"],
        peerType
      );

      let title = escapeEntity(
        section["content"]["headline"]["content"].replace(
          "sector",
          rightSegment
        )
      ).toUpperCase();

      if (section["content"]["showTimeframeInTitle"]) {
        title += " " + timeframeToAppend.toUpperCase();
      }

      const _section = {
        data: {
          text: title,
        },
        type: "header1",
      };
      _sections.push(_section);
    }

    let chartStyle = {
      height: 400,
      width: 632,
    };

    const orientation = pageOrientation;
    if (orientation === "portrait") {
      // Base
      chartStyle.height = 334;
      chartStyle.width = 530;
    } else {
      chartStyle.height = 474;
      chartStyle.width = 750;
    }

    const _section: any = {
      data: {
        head: [[]],
        body: [
          [
            {
              style: chartStyle,
              value: data.svg,
            },
          ],
        ],
      },
      type: "chartCustom",
    };
    const layout = {
      data: {
        layoutRow: [[_section /*, ...*/]],
      },
      type: "layout",
    };
    _sections.push(layout);

    return _sections;
  }

  generateDispersionByRating(data, section, peerType) {
    if (data.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];

    const segment = section["content"]["segment"];
    const segmentLabel = this.getLabelFromSegment(segment, peerType);

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const title = section["content"]["headline"]["content"].replace(
        "sector",
        segmentLabel
      );
      const _section = {
        data: {
          text: this._formatHeadline(title),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: segmentLabel,
            },
            {
              style: null,
              value: "TCR",
            },
            {
              style: {
                align: "center",
              },
              value: "Stocks",
            },
            {
              style: {
                align: "center",
              },
              value: "AB%",
            },
            {
              style: {
                align: "center",
              },
              value: "CD%",
            },
          ],
        ],
      },
      type: "table",
    };

    const sorter = section.content.sort;

    if (sorter != null) {
      const property = sorter.property;
      const rev = sorter.descending;

      sortBy(data, property, rev);
    }

    for (const sector of data) {
      const row = [
        {
          style: null,
          value: format.string({
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["name"],
            valueHelper: null,
          }),
        },
        {
          style: null,
          value: this._formatTcr(sector["TCR"]),
        },
        {
          style: {
            align: "center",
          },
          value: format.number({
            options: {
              decimals: 0,
              isPercentage: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["cardinality"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(sector["AB"]),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(sector["CD"]),
        },
      ];
      layout["data"]["body"].push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateDispersionRatioTable(data, section, peerType) {
    if (data.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];
    const rawTimeframe = section["content"]["timeframe"];
    let timeframe = "Today";

    switch (rawTimeframe) {
      case "today":
      default:
        break;
      case "lastWeek":
        timeframe = "Last 5 Days";

        break;
      case "lastMonth":
        timeframe = "Last 20 Days";

        break;
    }

    const segment = this.getLabelFromSegment(
      section["content"]["segment"],
      peerType
    );

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const text = section["content"]["headline"]["content"];
      const title = text + " " + timeframe;
      const _section = {
        data: {
          text: this._formatHeadline(title),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: segment,
            },
            {
              style: null,
              value: "Rating",
            },
            {
              style: {
                align: "center",
                color: "green",
              },
              value: "Upgrades",
            },
            {
              style: {
                align: "center",
                color: "red",
              },
              value: "Downgrades",
            },
            {
              style: { align: "center" },
              value: "Ratio",
            },
          ],
        ],
      },
      type: "table",
    };

    const sorter = section.content.sort;

    if (sorter != null) {
      const property = sorter.property;
      const rev = sorter.descending;

      sortBy(data, property, rev);
    }

    for (const sector of data) {
      const row = [
        {
          style: null,
          value: format.string({
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["name"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: null,
          },
          value: this._formatTcr(sector["tcr"]),
        },
        {
          style: {
            align: "center",
          },
          value: format.number({
            options: {
              decimals: 0,
              isPercentage: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["upgrades"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: "center",
          },
          value: format.number({
            options: {
              decimals: 0,
              isPercentage: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["downgrades"],
            valueHelper: null,
          }),
        },
        {
          style: { align: "center" },
          value: format.number({
            options: {
              decimals: 0,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["ratio"],
            valueHelper: null,
          }),
        },
      ];

      layout["data"]["body"].push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateDispersionBySectorIntervalTable(data, section, peerType) {
    if (data.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];
    const segment = section["content"]["segment"];
    const intervals = section["content"]["intervals"];

    const intervalsTitle = {
      4: {
        title_top: "Top 25%",
        title_mid: "Mid 50%",
        title_bottom: "Bottom 25%",
      },
      10: {
        title_top: "Top 10%",
        title_mid: "Mid 80%",
        title_bottom: "Bottom 10%",
      },
      20: {
        title_top: "Top 5%",
        title_mid: "Mid 90%",
        title_bottom: "Bottom 5%",
      },
    };

    const segmentLabel = this.getLabelFromSegment(segment, peerType);

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const timeframeCut = section?.["content"]?.["timeframe"] ?? "";
      const decodedTimeframeCut = timeframeCut.replace("_", " ");

      let title = section["content"]["headline"]["content"].replace(
        "sector",
        segmentLabel
      );

      if (section?.["content"]?.["showTimeframeInTitle"]) {
        title += " " + decodedTimeframeCut.toUpperCase();
      }

      const _section = {
        data: {
          text: this._formatHeadline(title),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: segmentLabel,
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_top"],
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_mid"],
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_bottom"],
            },
          ],
        ],
      },
      type: "table",
    };

    const sorter = section.content.sort;

    if (sorter != null) {
      const property = sorter.property;
      const rev = sorter.descending;

      if (property === "_s_label" || property === "name") {
        sortBy(data, "name", rev);
      } else {
        data.sort((a, b) => {
          if (
            a["dispersion"][property]["average"] >
            b["dispersion"][property]["average"]
          ) {
            return rev === true ? -1 : 1;
          } else if (
            a["dispersion"][property]["average"] <
            b["dispersion"][property]["average"]
          ) {
            return rev === true ? 1 : -1;
          }

          return 0;
        });
      }
    }

    for (const sector of data) {
      const row = [
        {
          style: null,
          value: format.string({
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["name"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(sector["dispersion"]["top"]["average"]),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(
            sector["dispersion"]["middle"]["average"]
          ),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(
            sector["dispersion"]["bottom"]["average"]
          ),
        },
      ];

      layout["data"]["body"].push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateMarketAbChanges(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value:
                data.childrenType === "Country"
                  ? i18n["Market"]
                  : i18n["Region"],
            },
            {
              style: {
                align: "center",
              },
              value: i18n["Change"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Current"],
            },
            {
              style: {
                align: "right",
              },
              value: this._formatTimeframe(section),
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      switch (item.abPercentageChange) {
        case 1: {
          row.push({
            style: {
              align: "center",
              color: "#008000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&uarr;",
              valueHelper: null,
            }),
          });

          break;
        }
        case -1: {
          row.push({
            style: {
              align: "center",
              color: "#F00000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&darr;",
              valueHelper: null,
            }),
          });

          break;
        }
        case 0:
        default: {
          row.push({
            style: {
              align: "center",
              color: "#000000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&harr;",
              valueHelper: null,
            }),
          });
        }
      }

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentage),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentageFrom),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateMarketOverview(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value:
                data.childrenType === "Country"
                  ? i18n["Market"]
                  : i18n["Region"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Securities"],
            },
            {
              style: null,
              value: i18n["TCR"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["AB_percentage"],
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.cardinality),
      });

      row.push({
        style: null,
        value: this._formatTcr(item.tcr),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentage),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateMarketTcrChanges(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value:
                data.childrenType === "Country"
                  ? i18n["Market"]
                  : i18n["Region"],
            },
            {
              style: null,
              value: i18n["TCR"],
            },
            {
              style: null,
              value: this._formatTimeframe(section),
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: null,
        value: this._formatTcr(item.tcr),
      });

      if (item.tcr === item.tcrFrom) {
        // No need to make a custom message
        row.push({
          style: null,
          value: "-",
        });
      } else {
        // Prepare message
        const tcrText = this._formatTcr(item.tcr);
        const previousTcrText = this._formatTcr(item.tcrFrom);

        if (item.tcr > item.tcrFrom) {
          row.push({
            style: null,
            value: "Upgraded to " + tcrText + " from " + previousTcrText,
          });
        } else {
          row.push({
            style: null,
            value: "Downgraded to " + tcrText + " from " + previousTcrText,
          });
        }
      }

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateMarketUpgradesDowngrades(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }
    // content
    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value:
                data.childrenType === "Country"
                  ? i18n["Market"]
                  : i18n["Region"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Upgrades"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Downgrades"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Ratio"],
            },
          ],
        ],
      },
      type: "table",
    };
    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.upgrades),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.downgrades),
      });

      row.push({
        style: {
          align: "right",
        },
        value: format.number({
          options: {
            decimals: 2,
            isPercentage: false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "PDF",
          value: item.ratio,
          valueHelper: null,
        }),
      });

      layout.data.body.push(row);
    }
    sections.push(layout);
    // legend
    const _section = {
      data: {
        style: {
          fontSize: "normal",
        },
        text:
          "<br/><br/>" +
          i18n["report_peer_upgrades_downgrades_legend_$timeframe"].replaceAll(
            // eslint-disable-next-line no-template-curly-in-string
            "${timeframe}",
            this._formatTimeframe(section)
          ),
      },
      type: "text",
    };

    sections.push(_section);

    return sections;
  }

  generateTcrFocus(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    const tableTitle = label;

    if (section["content"]["timeframe"] !== "today") {
      section["content"]["headline"]["content"] +=
        " - " + this._formatTimeframe(section);
    }

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: {
                align: "center",
              },
              value: "TCR",
            },
            {
              style: {
                align: "right",
              },
              value: "Stocks",
            },
            {
              style: {
                align: "right",
              },
              value: i18n["AB_percentage"],
            },
            {
              style: {
                align: "right",
              },
              value: "CD%",
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "center",
        },
        value: this._formatTcr(item.tcr),
      });

      row.push({
        style: {
          align: "right",
        },
        value: `${item.cardinality}`,
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentage),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.cdPercentage),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateUpgradesDowngradesFocus(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    const tableTitle = label;

    if (section["content"]["timeframe"] !== "today") {
      section["content"]["headline"]["content"] +=
        " - " + this._formatTimeframe(section);
    }

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [6, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: {
                align: "center",
              },
              value: "Upgrades",
            },
            {
              style: {
                align: "right",
              },
              value: "Downgrades",
            },
            {
              style: {
                align: "right",
              },
              value: "Upgrades %",
            },
            {
              style: {
                align: "right",
              },
              value: "Downgrades %",
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "right",
        },
        value: `${item.upgrades}`,
      });

      row.push({
        style: {
          align: "right",
        },
        value: `${item.downgrades}`,
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.upgradesPerc),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.downgradesPerc),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateSectorAbChanges(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    // This check is useful to give the correct label to the widget because this method
    // is used by 2 widgets with different needs.
    const defaultWidgetLabel =
      data.childrenType === "1 Industry" ? i18n["Sector"] : i18n["Industry"];
    const tableTitle =
      section["type"] === "REPORT_PEER_AB_CHANGES" ? label : defaultWidgetLabel;

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: {
                align: "center",
              },
              value: i18n["Change"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Current"],
            },
            {
              style: {
                align: "right",
              },
              value: this._formatTimeframe(section),
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      switch (item.abPercentageChange) {
        case 1: {
          row.push({
            style: {
              align: "center",
              color: "#008000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&uarr;",
              valueHelper: null,
            }),
          });

          break;
        }
        case -1: {
          row.push({
            style: {
              align: "center",
              color: "#F00000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&darr;",
              valueHelper: null,
            }),
          });

          break;
        }
        case 0:
        default: {
          row.push({
            style: {
              align: "center",
              color: "#000000",
            },
            value: format.string({
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "PDF",
              value: "&harr;",
              valueHelper: null,
            }),
          });
        }
      }

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentage),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentageFrom),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateSectorOverview(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    // This check is useful to give the correct label to the widget because this method
    // is used by 2 widgets with different needs.
    const defaultWidgetLabel =
      data.childrenType === "1 Industry" ? i18n["Sector"] : i18n["Industry"];
    const tableTitle =
      section["type"] === "REPORT_PEER_OVERVIEW" ? label : defaultWidgetLabel;

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Securities"],
            },
            {
              style: null,
              value: i18n["TCR"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["AB_percentage"],
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.cardinality),
      });

      row.push({
        style: null,
        value: this._formatTcr(item.tcr),
      });

      row.push({
        style: {
          align: "right",
        },
        value: this._formatPercentage(item.abPercentage),
      });

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateSectorTcrChanges(data, section) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    // This check is useful to give the correct label to the widget because this method
    // is used by 2 widgets with different needs.
    const defaultWidgetLabel =
      data.childrenType === "1 Industry" ? i18n["Sector"] : i18n["Industry"];
    const tableTitle =
      section["type"] === "REPORT_PEER_TCR_CHANGES"
        ? label
        : defaultWidgetLabel;

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: null,
              value: i18n["TCR"],
            },
            {
              style: null,
              value: this._formatTimeframe(section),
            },
          ],
        ],
      },
      type: "table",
    };

    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: null,
        value: this._formatTcr(item.tcr),
      });

      if (item.tcr === item.tcrFrom) {
        // No need to make a custom message
        row.push({
          style: null,
          value: "-",
        });
      } else {
        // Prepare message
        const tcrText = this._formatTcr(item.tcr);
        const previousTcrText = this._formatTcr(item.tcrFrom);

        if (item.tcr > item.tcrFrom) {
          row.push({
            style: null,
            value: "Upgraded to " + tcrText + " from " + previousTcrText,
          });
        } else {
          row.push({
            style: null,
            value: "Downgraded to " + tcrText + " from " + previousTcrText,
          });
        }
      }

      layout.data.body.push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateSectorUpgradesDowngrades(data, section, showTimeframe?) {
    // Smart section: do not generate if there are no items
    if (data.items.length === 0) {
      return null;
    }

    const format = this.format;
    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const timeframe = section;
      const timeLabel = this._formatTimeframe(timeframe ?? null) ?? null;
      const titleWithTimeframe = this._formatHeadline(
        timeLabel !== null
          ? (section["content"]["headline"]["content"] += ` ${timeLabel}`)
          : section["content"]["headline"]["content"]
      );
      const _section = {
        data: {
          text: showTimeframe
            ? titleWithTimeframe
            : this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const segment = section["content"]["segment"];
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        break;

      case "3 Sector":
        label = "Industry";

        break;

      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Area":
      case "Region":
      case "World":
        label = segment;
    }

    // This check is useful to give the correct label to the widget because this method
    // is used by 2 widgets with different needs.
    const defaultWidgetLabel =
      data.childrenType === "1 Industry" ? i18n["Sector"] : i18n["Industry"];
    const tableTitle =
      section["type"] === "REPORT_PEER_UPGRADES_DOWNGRADES"
        ? label
        : defaultWidgetLabel;

    // content
    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: tableTitle,
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Upgrades"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Downgrades"],
            },
            {
              style: {
                align: "right",
              },
              value: i18n["Ratio"],
            },
          ],
        ],
      },
      type: "table",
    };
    for (let i = 0, length = data.items.length; i < length; i++) {
      const row: any = [];
      const item = data.items[i];

      row.push({
        style: null,
        value: this._formatName(
          item.name,
          length === 1 ? "normal" : item.itemType
        ),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.upgrades),
      });

      row.push({
        style: {
          align: "right",
        },
        value: String(item.downgrades),
      });

      row.push({
        style: {
          align: "right",
        },
        value: format.number({
          options: {
            decimals: 2,
            isPercentage: false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "PDF",
          value: item.ratio,
          valueHelper: null,
        }),
      });

      layout.data.body.push(row);
    }
    sections.push(layout);
    // legend
    const _sectionPeerUpDown = {
      data: {
        style: {
          fontSize: "normal",
        },
        text:
          "<br/><br/>" +
          i18n["report_peer_upgrades_downgrades_legend_$timeframe"].replaceAll(
            // eslint-disable-next-line no-template-curly-in-string
            "${timeframe}",
            this._formatTimeframe(section)
          ),
      },
      type: "text",
    };
    sections.push(_sectionPeerUpDown);

    return sections;
  }

  generateTcr(data, section) {
    const paddingRows = 6;
    const sections: any = [];

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }
    // layout
    const layout: any = {
      data: {
        layoutRow: [],
      },
      type: "layout4ColPeerTcr",
    };
    const row: any = [];
    // TCR
    const _sectionTcr = {
      data: {
        body: [
          [
            {
              style: {
                align: "center",
                fontSize: "large",
              },
              value: this._formatTcr(data["tcr"]),
            },
          ],
        ],
        head: [
          [
            {
              style: {
                align: "center",
                color: "#000000",
              },
              value: i18n["Overall_TCR"],
            },
          ],
        ],
      },
      type: "portfolioMomentumRating",
    };
    addEmptyRow(_sectionTcr.data.body, paddingRows - 4, 1);
    row.push(_sectionTcr);
    // rating weights
    const _sectionRatingWeights: any = {
      data: {
        body: [
          [
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: null,
              value: "&nbsp;",
            },
          ],
        ],
        head: [
          [
            {
              style: {
                align: "center",
                color: "#000000",
              },
              value: i18n["Rating_weights"],
            },
            {
              style: null,
              value: "&nbsp;",
            },
          ],
        ],
      },
      type: "ratingWeight",
    };
    _sectionRatingWeights.data.body.push(
      this._generateTcrRow(data["ratingWeights"]["2"], "2")
    );
    _sectionRatingWeights.data.body.push(
      this._generateTcrRow(data["ratingWeights"]["1"], "1")
    );
    _sectionRatingWeights.data.body.push(
      this._generateTcrRow(data["ratingWeights"]["-1"], "-1")
    );
    _sectionRatingWeights.data.body.push(
      this._generateTcrRow(data["ratingWeights"]["-2"], "-2")
    );
    addEmptyRow(_sectionRatingWeights.data.body, paddingRows, 2);
    row.push(_sectionRatingWeights);
    // cardinality
    const _sectionCardinality = {
      data: {
        body: [
          [
            {
              style: null,
              value: "&nbsp;",
            },
          ],
          [
            {
              style: {
                align: "center",
              },
              value: String(data["cardinality"]),
            },
          ],
          [
            {
              style: null,
              value: "&nbsp;",
            },
          ],
          [
            {
              style: {
                align: "center",
              },
              value:
                data["peerType"] !== "ETF"
                  ? this._formatSize(data["size"])
                  : data["size"],
            },
          ],
        ],
        head: [
          [
            {
              style: {
                align: "center",
                color: "#000000",
              },
              value: i18n["Securities"],
            },
          ],
        ],
      },
      type: "cardinality",
    };
    addEmptyRow(_sectionCardinality.data.body, paddingRows - 2, 1);
    row.push(_sectionCardinality);
    // upgrades / downgrades
    const timeframe = this._formatTimeframe(section);
    const _sectionUpDown = {
      data: {
        body: [
          [
            {
              style: null,
              value:
                '<span color="#008000" fontName="Times">&#9650;</span> ' +
                i18n["Upgrades"],
            },
            {
              style: null,
              value: String(data["upgrades"]),
            },
          ],
          [
            {
              style: null,
              value:
                '<span color="#F00000" fontName="Times">&#9660;</span> ' +
                i18n["Downgrades"],
            },
            {
              style: null,
              value: String(data["downgrades"]),
            },
          ],
          [
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: null,
              value: "&nbsp;",
            },
          ],
          [
            {
              style: {
                align: "center",
              },
              value: timeframe,
            },
            {
              style: null,
              value: "",
            },
          ],
        ],
        head: [
          [
            {
              style: {
                align: "center",
                color: "#000000",
              },
              value: "Alerts",
            },
            {
              style: null,
              value: "",
            },
          ],
        ],
      },
      type: "alert",
    };
    addEmptyRow(_sectionUpDown.data.body, paddingRows - 2, 2);
    row.push(_sectionUpDown);

    layout.data.layoutRow.push(row);

    sections.push(layout);

    return sections;
  }

  // ----------------------------------------------------- private methods

  _formatAverage(value) {
    return [
      "<strong>",
      this._formatPercentageWithSign(value),
      "</strong>",
    ].join("");
  }

  _formatHeadline(headline) {
    return this.format
      .string({
        options: {
          hasToEscapeXmlEntities: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "PDF",
        value: headline,
        valueHelper: null,
      })
      .toUpperCase();
  }

  _formatName(name, type) {
    if (name == null) {
      return "";
    }

    const tokens = name.split(",");
    name = tokens.length === 1 ? tokens[0] : tokens[1];
    switch (type) {
      case "main": {
        return "<strong>" + name + "</strong>";
      }
      case "sub": {
        return "&nbsp;&nbsp;&nbsp;" + name;
      }
      case "normal":
      default: {
        return name;
      }
    }
  }

  _formatPercentage(value) {
    return this.format.number({
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "PDF",
      value: value,
      valueHelper: null,
    });
  }

  _formatPercentageWithSign(value) {
    return this.format.number({
      options: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "PDF",
      value: value,
      valueHelper: null,
    });
  }

  _formatSize(value) {
    if (value === "microLarge") {
      return i18n["All_cap"];
    }

    const sizes = FormOptions.get("SIZE");
    const sizesMap = {};
    for (let i = 0, length = sizes.length; i < length; i++) {
      const item = sizes[i];
      sizesMap[item.value] = item;
    }

    // single interval
    let valueEnd = value;
    let valueStart = value;
    // multiple interval
    const cutoffIndex = value.search(/[A-Z]/g);
    if (cutoffIndex !== -1) {
      valueEnd = value.substring(cutoffIndex).toLowerCase();
      valueStart = value.substring(0, cutoffIndex);
    }

    if (valueStart === valueEnd) {
      return sizesMap[valueStart]["label"] + " " + i18n["cap"];
    }

    return [
      sizesMap[valueStart]["label"],
      "-",
      sizesMap[valueEnd]["label"],
      i18n["cap"],
    ].join(" ");
  }

  _formatTcr(value) {
    return this.format.tcr({
      options: {
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "PDF",
      value: value,
      valueHelper: null,
    });
  }

  _formatTimeframe(section) {
    const timeframeValue = section["content"]["timeframe"];

    switch (timeframeValue) {
      case "lastMonth": {
        return i18n["1_Month"];
      }
      case "lastWeek": {
        return i18n["1_Week"];
      }
      case "today":
      default: {
        switch (section.type) {
          case "REPORT_PEER_WHAT_AB_CHANGES": {
            return i18n["1_Day"];
          }
          default: {
            return i18n["Today"];
          }
        }
      }
    }
  }

  _formatTimeframeDispersion(section) {
    const timeframeValue = section["content"]["timeframe"];

    switch (timeframeValue) {
      case "3_months": {
        return i18n["3_Months"];
      }
      case "6_months": {
        return i18n["6_Months"];
      }
      case "12_months": {
        return i18n["12_Months"];
      }
      case "1_month":
      default: {
        return i18n["1_Month"];
      }
    }
  }

  _generateTcrRow(data, rating) {
    const format = this.format;

    return [
      {
        style: {
          align: "right",
        },
        value: format.rating({
          options: {
            notAvailable: {
              input: 0,
              output: "-",
            },
          },
          output: "PDF",
          value: parseInt(rating),
          valueHelper: null,
        }),
      },
      {
        style: {
          align: "right",
        },
        value: this._formatPercentage(data),
      },
    ];
  }
}
