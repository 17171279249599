import { Box, Card, CardContent, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../deepClone";

type SpacingProps = {
  configObj: any;
};

const VerticalSpacing = forwardRef(({ configObj }: SpacingProps, ref) => {
  const [state, setState] = useState(configObj.widgetValue.content.lines);
  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.lines = state;
      return tempState;
    },
  }));
  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <TextField
            InputProps={{
              inputProps: { min: 0 },
            }}
            label="Vertical lines"
            type={"number"}
            defaultValue={state}
            onChange={(e) => setState(parseInt(e.target.value))}
            size="small"
          />
        </CardContent>
      </Card>
    </Box>
  );
});

export default VerticalSpacing;
