/* eslint-disable no-template-curly-in-string */
import { mergeProperties } from "../../../../../../../api/compute/commons";
import { sectionProperties } from "../../../../../../trendrating-report/configuration/sectionProperties";

export const _mergeWysiwygStateActions = (sections, _wysiwygState) => {
  const baseId = new Date().getTime();
  var column: any = null;
  var hasRank = false;
  var section: any = null;
  var wysiwygState = _wysiwygState;
  for (let i = 0, length = sections.length; i < length; i++) {
    const id = baseId + "-" + i;
    hasRank = false;
    section = sections[i];
    section["id"] = id;

    switch (section["type"]) {
      case "REPORT_LIST_ALERTS_TABLE": {
        section.content.timeframe = wysiwygState?.timeframe ?? "today";

        break;
      }

      case "REPORT_COMMON_TITLE": {
        const timeframe = wysiwygState?.timeframe ?? undefined;
        const textContent = section.content.text;

        const timeframeDict = {
          today: "today",
          lastWeek: "last week",
          lastMonth: "last month",
        };

        if (timeframe != null && textContent.includes("${timeframe}")) {
          section.content.text = textContent.replace(
            "${timeframe}",
            timeframeDict[timeframe]
          );
        }

        break;
      }
      case "REPORT_STRATEGY_FACTS": {
        // currency of Systematic portfolio
        if (wysiwygState.targetType === "SYSTEMATIC") {
          section.content.currency.content = wysiwygState.target.currency;
        }

        break;
      }
      case "REPORT_STRATEGY_HOLDINGS": {
        section.content.constraints = wysiwygState.actions.constraints;
        section.content.rank = wysiwygState.actions.rank;
        if (
          section.content.sortBy == null &&
          wysiwygState.actions.sortBy != null
        ) {
          section.content.sortBy = wysiwygState.actions.sortBy;
        }

        // Update labels with wysiwyg
        for (
          var j = 0, lengthJ = section.presentation.columns.length;
          j < lengthJ;
          j++
        ) {
          for (
            var k = 0, lengthK = wysiwygState.columns.length;
            k < lengthK;
            k++
          ) {
            if (
              wysiwygState.columns[k].property ===
              section.presentation.columns[j].property
            ) {
              section.presentation.columns[j].label =
                wysiwygState.columns[k].label;
            }
          }
        }

        break;
      }
      case "REPORT_COMMON_SECURITY_CHART": {
        section.content.constraints = wysiwygState.actions.constraints;
        section.content.rank = wysiwygState.actions.rank;
        if (
          section.content.sortBy == null &&
          wysiwygState.actions.sortBy != null
        ) {
          section.content.sortBy = wysiwygState.actions.sortBy;
        }
        /*
                    Chart needs to have fixed columns, and the chart
                    section does not have presentation.columns.
                    So here we fill that field with the correct columns.
                    This is only made for chart because 
                */
        if (section.presentation == null) {
          section.presentation = {
            columns: null,
            rank: false,
          };
        }
        // Prepare columns and update wysiwygState for using with chart
        section.presentation.columns = mergeProperties(
          wysiwygState.columns ?? [],
          sectionProperties["trendrating-report/common/security-chart"][
            "properties"
          ]
        );

        if (
          section.content.sortBy != null &&
          section.content.sortBy.property === "rank"
        ) {
          section.presentation.rank = true;
        }

        break;
      }
      case "REPORT_COMMON_SECURITY_TABLE": {
        // Merge dynamic rank columns to user customized columns
        if (section.presentation.rank === true) {
          var existingProperties: any = [];
          for (let j = 0; j < section.presentation.columns.length; j++) {
            existingProperties.push(
              section.presentation.columns[j]["property"]
            );
          }

          for (let j = 0; j < wysiwygState.columns.length; j++) {
            column = wysiwygState.columns[j];
            if (column.property === "rank" || hasRank === true) {
              hasRank = true;

              // Fixes duplicate bug in rankValue, if it
              // is already available
              // Ignore property if it is already present
              if (existingProperties.indexOf(column.property) === -1) {
                section.presentation.columns.push(column);
              }
            }
          }
        }
        if (wysiwygState != null) {
          section.content.constraints = wysiwygState.actions.constraints;
          section.content.rank = wysiwygState.actions.rank;
        }
        if (
          section.content.sortBy == null &&
          wysiwygState.actions.sortBy != null
        ) {
          section.content.sortBy = wysiwygState.actions.sortBy;
        }

        break;
      }

      case "REPORT_DISPERSION_RATIO_TABLE": {
        if (
          wysiwygState != null &&
          (section?.content?.useWysiwyg ?? true) === true
        ) {
          if ("timeframe" in wysiwygState && wysiwygState.timeframe != null) {
            section.content.timeframe = wysiwygState.timeframe;
          }

          if ("segment" in wysiwygState && wysiwygState.segment != null) {
            section.content.segment = wysiwygState.segment;
          }
        }

        break;
      }

      // no default
    }
  }

  return sections;
};
