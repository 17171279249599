import {
    Box,
    Card,
    CardContent,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../deepClone";
import ReportSelect from "../commonWidgets/Select";
import { TCRType, tcrReducer } from "./React_TCR_reducer";
const selectOptions = [
    {
        label: "Sectors",
        value: "INDUSTRY",
    },
    {
        label: "Industries",
        value: "SECTOR",
    },
    {
        label: "Markets",
        value: "MARKET",
    },
    {
        label: "Regions",
        value: "REGION",
    },
    {
        label: "Areas",
        value: "AREA",
    },
    {
        label: "Inv. Region",
        value: "INV_REGION",
    },
    {
        label: "Asset Class",
        value: "ASSET_CLASS",
    },
    {
        label: "Specialty",
        value: "SPECIALTY",
    },
    {
        label: "Theme",
        value: "THEME",
    },
];
type TCRProps = {
    configObj: any;
};

const TCR = forwardRef(({ configObj }: TCRProps, ref) => {
    const initState: TCRType = useMemo(
        () => ({
            content: configObj.widgetValue.content,
            presentation: configObj.widgetValue.presentation,
        }),
        [configObj.widgetValue.content, configObj.widgetValue.presentation]
    );
    const [state, dispatch] = useImmerReducer(tcrReducer, initState);

    useImperativeHandle(ref, () => ({
        getState: () => {
            let tempState = deepClone(configObj);
            tempState.widgetValue.content = state.content;
            tempState.widgetValue.presentation = state.presentation;
            return tempState;
        },
    }));

    return state ? (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Card sx={{ boxShadow: 3 }}>
                <CardContent
                    sx={{
                        pb: "16px !important",
                    }}
                >
                    <Box display={"flex"}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.content.headline.isEnabled}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_CONTENT_HEADLINE_ISENABLED",
                                            payload: e.target.checked,
                                        })
                                    }
                                    size="small"
                                />
                            }
                            label="Headline"
                        />
                        <TextField
                            defaultValue={state.content.headline.content}
                            disabled={!state.content.headline.isEnabled}
                            onChange={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_HEADLINE_CONTENT",
                                    payload: e.target.value,
                                })
                            }
                            size="small"
                        />
                    </Box>
                </CardContent>
            </Card>

            <Card sx={{ boxShadow: 3 }}>
                <CardContent
                    sx={{
                        pb: "16px !important",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    state.presentation.portfolioMomentumRating
                                }
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_PORTFOLIO_MOMENTUM_RATING",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                        }
                        label="Portfolio Momentum Rating"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.presentation.ratingWeight}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_RATING_WEIGHT",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                        }
                        label="Rating Weight"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.presentation.alert}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_ALERT",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                        }
                        label=" Alert"
                    />

                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        state.presentation.marketAllocation
                                    }
                                    size="small"
                                    // defaultChecked={state.presentation.marketAllocation}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_PRESENTATION_MARKET_ALLOCATION",
                                            payload: e.target.checked,
                                        })
                                    }
                                />
                            }
                            label="Left allocation"
                        />
                        <ReportSelect
                            setOutterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_MARKET_ALLOCATION",
                                    payload: e,
                                })
                            }
                            options={selectOptions}
                            defaultValue={state.content.marketAllocation}
                        />
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Typography>Max number of slices: </Typography>
                        <InputNumber
                            maxWidth={100}
                            disableGutters
                            initVal={state.content.marketRows}
                            isPercentage={false}
                            setOuterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_MARKET_ROWS",
                                    payload: e,
                                })
                            }
                            isFloating={false}
                        />
                    </Box>

                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap={2}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={
                                        state.presentation.sectorAllocation
                                    }
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_PRESENTATION_SECTOR_ALLOCATION",
                                            payload: e.target.checked,
                                        })
                                    }
                                />
                            }
                            label="Right allocation"
                        />
                        <ReportSelect
                            setOutterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_SECTOR_ALLOCATION",
                                    payload: e,
                                })
                            }
                            options={selectOptions}
                            defaultValue={state.content.sectorAllocation}
                        />
                    </Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap={2}
                    >
                        <Typography>Max number of slices: </Typography>
                        <InputNumber
                            maxWidth={100}
                            disableGutters
                            initVal={state.content.sectorRows}
                            isPercentage={false}
                            setOuterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_SECTOR_ROWS",
                                    payload: e,
                                })
                            }
                            isFloating={false}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    ) : null;
});
export default TCR;

// type TCRSelectType = {
//   options: {
//     label: string;
//     value: string;
//   }[];
//   defaultValue: any;
//   setOutterState: Function;
// };
// const TCRSelect = ({ options, defaultValue, setOutterState }: TCRSelectType) => {
//   return (
//     <Select size="small" value={defaultValue} onChange={(e) => setOutterState(e.target.value)}>
//       {options.map((option, index) => (
//         <MenuItem key={index} value={option.value}>
//           {option.label}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// };
