import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { useEffect, useState } from "react";

type ReportHeaderOptionsProps = {
  setOutterState: Function;
  logoSize: any;
  dateEnabled: boolean;
  logoHandler: Function;
  dateHandler: Function;
};

export default function ReportHeaderOptions({
  logoSize /*= "small"*/,
  dateEnabled /*= false*/,
  setOutterState,
  logoHandler,
  dateHandler,
}: ReportHeaderOptionsProps) {
  const [size, setSize] = useState(logoSize);
  const [date, setDate] = useState(dateEnabled);

  useEffect(() => {
    logoHandler(size);
    dateHandler(date);
  }, [date, dateHandler, logoHandler, size]);

  return (
    <Card>
      <CardContent sx={{ p:1, pb:"8px !important" }}>
        <Box>
          <Typography variant="tr_subtitle_blue">Header options</Typography>

          <Box
            display={"flex"}
            flexDirection="column"
            // sx={{ border: "1px solid #d3d3d3", padding: "5px" }}
          >
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography>Logo</Typography>

              <RadioGroup
                sx={{ display: "flex", flexDirection: "row" }}
                // aria-labelledby="demo-radio-buttons-group-label"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <FormControlLabel
                  value="none"
                  control={<Radio size="small" />}
                  label="None"
                />
                <FormControlLabel
                  value="small"
                  control={<Radio size="small" />}
                  label="Small"
                />
                <FormControlLabel
                  value="medium"
                  control={<Radio size="small" />}
                  label="Mid"
                />
                <FormControlLabel
                  value="large"
                  control={<Radio size="small" />}
                  label="Large"
                />
              </RadioGroup>
            </FormControl>

            <Box display={"flex"} alignItems="center" flexDirection={"row"}>
              <Typography>Today date</Typography>
              <Checkbox
                size="small"
                checked={date}
                onChange={(e) => setDate(e.target.checked)}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
