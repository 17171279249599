// import { isElementIn } from "./filterWidgetHelper";

export type FilterStocksState = {
  where: string[];
  what: string[];
  size: { left: number | null; right: number | null } | null;
};

export type FilterStateETF = {
  whereETF: string[];
  assetClass: string[];
  marketCap: { left: number | null; right: number | null } | null;
};

type ActionETF =
  | { type: "SET_COUNTRY_ETF"; payload: string }
  | { type: "REMOVE_COUNTRY_FILTER_ETF"; payload: string | number }
  | { type: "REMOVE_ASSET_CLASS_FILTER_ETF"; payload: string | number }
  | { type: "SET_ASSET_CLASS_ETF"; payload: string }
  | {
      type: "SET_MARKET_CAP_ETF";
      payload: { left: number | null; right: number | null };
    };

type Action =
  | { type: "SET_COUNTRY"; payload: string }
  | { type: "SET_SECTORS"; payload: string }
  | {
      type: "SET_SIZE";
      payload: { left: number | null; right: number | null };
    }
  | { type: "REMOVE_COUNTRY_FILTER"; payload: string | number }
  | { type: "REMOVE_SECTOR_FILTER"; payload: string | number }
  | { type: "LOAD_FILTERS_FROM_SAVED"; payload: FilterStocksState }
  | {
      type: "REFRESH_INIT_STATE";
      payload: {
        where: string[];
        what: string[];
        size: { left: null | number; right: null | number };
      };
    };

export const filterStockState: FilterStocksState = {
  where: [],
  what: [],
  size: { left: null, right: null },
};

export const filterStateETF: FilterStateETF = {
  whereETF: [],
  assetClass: [],
  marketCap: { left: null, right: null },
};

export const filterReducerStock = (
  draft: FilterStocksState,
  action: Action
) => {
  switch (action.type) {
    case "SET_COUNTRY":
      //   let isFilterAlreadyActive = isElementIn(draft.where, action.payload);

      //   if (!isFilterAlreadyActive && action.payload !== "WWW") {
      //     draft.where.push(action.payload);
      //   } else if (action.payload === "WWW") {
      //     draft.where = [];
      //   }
      draft.where = action.payload as any;

      break;
    case "SET_SECTORS":
      //   let isSectorAlreadyActive = isElementIn(draft.what, action.payload);

      //   if (!isSectorAlreadyActive && action.payload !== "ICB") {
      //     draft.what.push(action.payload);
      //   } else if (action.payload === "ICB") {
      //     draft.what = [];
      //   }

      draft.what = action.payload as any;
      break;
    case "SET_SIZE":
      draft.size = action.payload;

      break;
    case "REMOVE_COUNTRY_FILTER":
      draft.where = draft.where.filter((isoCode) => isoCode !== action.payload);
      break;
    case "REMOVE_SECTOR_FILTER":
      draft.what = draft.what.filter((sectorID) => sectorID !== action.payload);
      break;
    case "REFRESH_INIT_STATE":
      if (JSON.stringify(draft) !== JSON.stringify(action.payload)) {
        return action.payload;
      }
      break;
    default:
      return draft;
  }
};

export const filterReducerETF = (draft: FilterStateETF, action: ActionETF) => {
  switch (action.type) {
    case "SET_COUNTRY_ETF": {
      draft.whereETF = action.payload as any;

      break;
    }

    case "SET_ASSET_CLASS_ETF":
      draft.assetClass = action.payload as any;

      break;

    case "SET_MARKET_CAP_ETF":
      draft.marketCap = action.payload;

      break;
    case "REMOVE_COUNTRY_FILTER_ETF":
      draft.whereETF = draft.whereETF.filter(
        (isoCode) => isoCode !== action.payload
      );
      break;

    case "REMOVE_ASSET_CLASS_FILTER_ETF":
      draft.assetClass = draft.assetClass.filter(
        (assetClass) => assetClass !== action.payload
      );
      break;
    default:
      return draft;
  }
};
