/* eslint-disable no-template-curly-in-string */
export const defaultTemplateReportPeer = [
  {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Contact us to receive the full list of investment ideas or for further insights on your sectors, investment universes of interest:</b><br/><br/><div><b>LONDON     </b>+44(20) 3823 2215</div><br/><br/><div><b>LUGANO     </b>+41 91 910 65 90</div><br/><br/><div><b>BOSTON     </b>+1 508-315-6313</div><br/><br/><div><b><i>info@trendrating.net</i></b></div>",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Focus on Area/Region/Market - Overview",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION_CHILDREN",
          content: {
            focusOn: "where",
            headline: {
              content: "",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Contact us to receive the full list of investment ideas or for further insights on your sectors, investment universes of interest:</b><br/><br/><div><b>LONDON     </b>+44(20) 3823 2215</div><br/><br/><div><b>LUGANO     </b>+41 91 910 65 90</div><br/><br/><div><b>BOSTON     </b>+1 508-315-6313</div><br/><br/><div><b><i>info@trendrating.net</i></b></div>",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Focus on Area/Region/Market - Detailed",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Contact us to receive the full list of investment ideas or for further insights on your sectors, investment universes of interest:</b><br/><br/><div><b>LONDON     </b>+44(20) 3823 2215</div><br/><br/><div><b>LUGANO     </b>+41 91 910 65 90</div><br/><br/><div><b>BOSTON     </b>+1 508-315-6313</div><br/><br/><div><b><i>info@trendrating.net</i></b></div>",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Focus on Sector/Industry - Overview",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION_CHILDREN",
          content: {
            focusOn: "what",
            headline: {
              content: "",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Contact us to receive the full list of investment ideas or for further insights on your sectors, investment universes of interest:</b><br/><br/><div><b>LONDON     </b>+44(20) 3823 2215</div><br/><br/><div><b>LUGANO     </b>+41 91 910 65 90</div><br/><br/><div><b>BOSTON     </b>+1 508-315-6313</div><br/><br/><div><b><i>info@trendrating.net</i></b></div>",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Focus on Sector/Industry - Detailed",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
];
