import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./InputNumber.module.scss";

type InputNumberProps = {
  inputLabel?: string;
  size?: "tr_small" | "small" | "medium";
  initVal?: number;
  isPercentage: boolean;
  setOuterState: (val: number | null) => void;
  constraints?: {
    maxVal?: number;
    minVal?: number;
  };
  isFloating: boolean;
  isDisabled?: boolean;
  getIsValid?: (validity: boolean) => any;
  disableGutters?: boolean;
  required?: boolean;
  maxWidth?: string | number;
  decimals?: number;
  multiplyBy100?: boolean;
};

const validate = (
  val: number,
  setError: Function,
  constraints: InputNumberProps["constraints"],
  setMessage: Function,
  required: boolean
) => {
  if (required === true) {
    if (Number.isNaN(val)) {
      setError(true);
      setMessage("invalid number");
      return;
    } else {
      setError(false);
      setMessage("");
    }
  }

  let isMaxValid = true;
  let isMinValid = true;

  if (constraints) {
    if (constraints.maxVal != null) {
      if (val > constraints.maxVal) {
        isMaxValid = false;
      } else {
        isMaxValid = true;
      }
    }

    if (constraints.minVal != null) {
      if (val < constraints.minVal) {
        isMinValid = false;
      } else {
        isMinValid = true;
      }
    }

    if (constraints.maxVal != null && constraints.minVal != null) {
      setError(!isMinValid || !isMaxValid);

      if (!isMinValid) {
        setMessage(`min ${constraints.minVal}`);
      } else if (!isMaxValid) {
        setMessage(`max ${constraints.maxVal}`);
      } else {
        setMessage("");
      }
    }

    if (constraints.maxVal != null && constraints.minVal == null) {
      setError(!isMaxValid);

      if (!isMaxValid) {
        setMessage(`max ${constraints.maxVal}`);
      } else {
        setMessage("");
      }
    }

    if (constraints.minVal != null && constraints.maxVal == null) {
      setError(!isMinValid);

      if (!isMinValid) {
        setMessage(`min ${constraints.minVal}`);
      } else {
        setMessage("");
      }
    }
  }
};

export default function InputNumber({
  multiplyBy100 = true,
  size = "small",
  initVal,
  constraints,
  isPercentage,
  isFloating,
  getIsValid,
  setOuterState,
  isDisabled,
  disableGutters = false,
  required = false,
  maxWidth = "auto",
  decimals,
}: InputNumberProps) {
  const initialValue = useMemo(() => {
    if (required === true) {
      if (isPercentage === true && multiplyBy100 === true) {
        const ii = initVal ?? 0;
        const returningVal = ii * 100;
        return returningVal;
      } else {
        return initVal;
      }
    }
    if (initVal != null) {
      return isPercentage && multiplyBy100 ? initVal * 100 : initVal;
    } else {
      return initVal;
    }
  }, [initVal, isPercentage, multiplyBy100, required]);
  const [inpVal, setInpVal] = useState<any>(initialValue);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (getIsValid != null) {
      getIsValid(error);
    }
  }, [error, getIsValid]);

  useEffect(() => {
    let val: number | null = inpVal;

    if (inpVal != null) {
      if (error) {
        val = null;
      } else if (isPercentage && multiplyBy100) {
        val = inpVal / 100;
      }
    }

    setOuterState(val);
  }, [error, inpVal, isPercentage, multiplyBy100, setOuterState]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = e.target.value;
      const numberValue = isFloating ? parseFloat(value) : parseInt(value);
      validate(numberValue, setError, constraints, setMessage, required);

      if (isNaN(numberValue)) {
        setInpVal(null);
      } else {
        setInpVal(numberValue);
      }
    },
    [constraints, isFloating, required]
  );

  const stepValue = useMemo(() => {
    if (decimals) {
      return 1 / 10 ** decimals;
    } else {
      return 0.01;
    }
  }, [decimals]);

  return (
    <Box
      component={"div"}
      sx={{
        "& > :not(style)": { m: 1, width: "auto" },
      }}
      display={"flex"}
      gap={"4px"}
      alignItems={"center"}
    >
      <TextField
        disabled={isDisabled}
        type={"number"}
        error={error}
        sx={
          disableGutters
            ? {
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
                margin: "0!important",
                maxWidth: maxWidth,
              }
            : {
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
                maxWidth: maxWidth,
              }
        }
        inputProps={{
          className: size === "tr_small" ? styles.inputNumber : "",
          step: isFloating ? stepValue : 1,
        }}
        onChange={handleChange}
        id="outlined-basic"
        label={message.length ? <Typography>{message}</Typography> : null}
        variant="outlined"
        value={inpVal ?? ""}
        size={size !== "tr_small" ? size : "small"}
      />
      {isPercentage && (
        <Typography sx={{ margin: "0 !important" }} variant={"body1"}>
          %
        </Typography>
      )}
    </Box>
  );
}
