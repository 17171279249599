import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import axios from "axios";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Users } from "../../api/account/Users";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useEventBus } from "../../hooks/useEventBus";
import { Menu } from "./Menu";
import Search from "./widgets/ReactSearch/Search";
import Preferences from "./widgets/preferences/react_preferences/Preferences";
import { config } from "./config-ts";
import { appCookie } from "./Cookies";

export function Header({ hide = false }: { hide: boolean } = { hide: false }) {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const { on, remove } = useEventBus();
  const [preferencesInitTab, setPreferencesInitTab] = useState();
  const [preferencesSubscriptionsInitTab, setPreferencesSubscriptionsInitTab] = useState();

  const theme = useMemo(() => {
    const colorTag =
      environment.get("account")?.product?.configuration?.colorSchema ??
      "legacy";
    switch (colorTag) {
      default:
      case "legacy":
        return "app__header";

      case "advisor":
        return "app__header__advisor";
    }
  }, [environment]);

  const [showPreferences, setShowPreferences] = useState<boolean>(false);

  const SETTINGS_INPUT = useMemo(() => {
    var widgetValue = null;
    if (
      window.App != null &&
      window.App.user != null &&
      window.App.user.preferences != null &&
      window.App.user.preferences.preferences != null
    ) {
      widgetValue = window.App.user.preferences.preferences;
    }
    return {
      product: window.App.user.product,
      user: window.App.user,
      value: widgetValue,
    };
  }, []);

  const landingPageOptions = useMemo(() => {
    var productConfiguration = SETTINGS_INPUT["product"]["configuration"];
    var _landingPageOptions = productConfiguration["landing_page_options"];

    var landingPageOptions: any = [];
    var key: any = null;
    for (var i = 0, length = _landingPageOptions.length; i < length; i++) {
      key = _landingPageOptions[i];
      landingPageOptions.push({
        label:
          t(productConfiguration[key]["menu_label"]) ??
          productConfiguration[key]["menu_label"],
        value: _landingPageOptions[i],
      });
    }
    return landingPageOptions;
  }, [SETTINGS_INPUT, t]);

  const landingPageDefault = useMemo(() => {
    return SETTINGS_INPUT["product"]["configuration"]["landing_page"];
  }, [SETTINGS_INPUT]);

  const openPreferences = useCallback((e) => {
    setPreferencesInitTab(e.detail.preferencesTab);
    setPreferencesSubscriptionsInitTab(e.detail.subscriptionsTab)
    setShowPreferences(true);
  }, []);

  useEffect(() => {
    on("open-preferences", openPreferences);

    return () => {
      remove("open-preferences", openPreferences);
    };
  }, [on, openPreferences, remove]);

  const showSearchInput = useMemo(() => {
    const configuration = SETTINGS_INPUT["product"]["configuration"];

    return configuration?.securitySearchWidget ?? true;
  }, [SETTINGS_INPUT]);

  const headerSentence = useMemo(() => {
    const configuration = SETTINGS_INPUT["product"]["configuration"];

    return configuration.mainMenuSentence;
  }, [SETTINGS_INPUT]);

  return (
    <header className={theme} id="app-header" role="banner">
      <div
        style={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          paddingRight: "10px",
        }}
      >
        <Box
          display={"flex"}
          gap={1}
          alignItems={"center"}
          // justifyContent={"space-between"}
        >
          <img
            className="app__logo"
            src="/img/trendrating_logo.png"
            width="150"
            height="29"
            alt="Trendrating"
          />
          {!hide && showSearchInput && (
            <div
              style={{
                display: "inline-block",
                marginTop: ".5em",
                verticalAlign: "middle",
              }}
            >
              <Search lookUp="SECURITY" onSelectInstrument={() => {}} />
            </div>
          )}
          {!hide && <Menu />}
        </Box>
        {headerSentence && (
          <Box
            sx={{
              display: "inline-block",
              margin: "0.5em 0.75em",
              padding: "0.15em 0.25em",
              marginLeft: "25em",
            }}
          >
            <Typography sx={{ fontSize: "1.2em", color: "white" }}>
              {headerSentence}
            </Typography>
          </Box>
        )}
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <div
            className="app__preference"
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              margin: 0,
            }}
          >
            <div onClick={() => setShowPreferences(!showPreferences)}>
              SETTINGS<span className="a11y">Preferences</span>
            </div>
          </div>
          <FeedbackSupport title={"Get help or give feedback"} />
          <ProductSwitch />
        </Box>
      </div>
      {showPreferences === true && (
        <Preferences
          closePreferences={() => setShowPreferences(false)}
          input={SETTINGS_INPUT.value}
          userInfo={SETTINGS_INPUT.user}
          landingPageDefault={landingPageDefault}
          landingPageOptions={landingPageOptions}
          initTab={preferencesInitTab}
          initSubscriptionTab={preferencesSubscriptionsInitTab}
        />
      )}
    </header>
  );
}

type FeedbackSupportType = {
  title: string;
};
const FeedbackSupport = ({ title }: FeedbackSupportType) => {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const userAPI = useMemo(() => new Users(setup), [setup]);
  const user = useMemo(() => environment.get("account").user, [environment]);
  const tippyRef = useRef<any>(null);
  const onFormCancel = useCallback(() => {
    tippyRef.current._tippy.hide();
  }, []);
  const FeedBackFormRef = useRef<any>(null);
  const onFormSend = useCallback(
    (textContent) => {
      const message = [
        "---------------------------------------------------",
        "Account ID: " + user.id,
        "Client    : " + user.client,
        "First name: " + user.firstName,
        "Last name : " + user.lastName,
        "Email     : " + user.email,
        "Type      : " + user.groups,
        "Product   : " + user.product.codeName,
        "---------------------------------------------------",
        "Version   : " + window.App.system.version,
        "Node      : " + window.App.system.node,
        "---------------------------------------------------",
      ];
      const params = {
        fromName: user.firstName + " " + user.lastName,
        from: user.email,
        message: message.join("\n") + "\n\n" + textContent,
      };
      const onSuccess = () => {
        FeedBackFormRef.current.onSuccess();
      };
      const onError = () => {
        FeedBackFormRef.current.onError();
      };
      userAPI.askSupport(params).then(
        (e) => {
          onSuccess();
        },
        (e) => {
          onError();
        }
      );
    },
    [
      user.client,
      user.email,
      user.firstName,
      user.groups,
      user.id,
      user.lastName,
      user.product.codeName,
      userAPI,
    ]
  );
  return (
    <Box className="app__customer-care menu menu--horizontal menu--workflow">
      <Tippy
        ref={tippyRef}
        content={
          <FeedBackForm
            ref={FeedBackFormRef}
            onCancel={onFormCancel}
            onSend={onFormSend}
          />
        }
        placement="auto-start"
        trigger="click"
        theme="security-tooltip"
        interactive
        allowHTML
      >
        <span
          style={{ cursor: "pointer" }}
          title={title}
          className="i-feedback"
        ></span>
      </Tippy>
    </Box>
  );
};

type FeedBackFormType = {
  onCancel: Function;
  onSend: Function;
};
const FeedBackForm = forwardRef(
  ({ onCancel, onSend }: FeedBackFormType, ref) => {
    const [isSendClicked, setIsSendClicked] = useState(false);
    const [textContent, setTextContent] = useState("");
    const [feedbackPhrase, setFeedbackPhrase] = useState<any>(null);
    useImperativeHandle(
      ref,
      () => ({
        onSuccess: () => {
          setIsSending(false);
          setIsSendClicked(true);
          setFeedbackPhrase(
            <Typography color={"green"}>
              <strong>Message sent!</strong>
              <br />
              Thank you to help us improve Trendrating.We'll be in touch.
            </Typography>
          );
          setTimeout(() => {
            onCancel();
          }, 2500);
          setTimeout(() => {
            setIsSendClicked(false);
          }, 3000);
        },
        onError: () => {
          setIsSending(false);
          setIsSendClicked(true);
          setFeedbackPhrase(
            <Typography color={"red"}>
              <strong>Sorry, we got an error!</strong>
              <br />
              Please, call the Trendrating Customer Support.
            </Typography>
          );
          setTimeout(() => {
            onCancel();
          }, 2500);
          setTimeout(() => {
            setIsSendClicked(false);
          }, 3000);
        },
      }),
      [onCancel]
    );

    const [isSending, setIsSending] = useState(false);

    return isSending ? (
      <Box display={"flex"} alignItems={"center"} p={2}>
        <CircularProgress size={"0.9vw"} />
        <Typography>Sending message...</Typography>
      </Box>
    ) : (
      <Box width={300} display={"flex"} flexDirection={"column"} gap={1} p={1}>
        {isSendClicked ? (
          <>{feedbackPhrase}</>
        ) : (
          <>
            <Typography textAlign={"start"}>
              Get help or give a feedback!
            </Typography>
            <TextField
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
              type="text"
              size="small"
              multiline
              maxRows={6}
              minRows={4}
            />

            <Box
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              gap={1}
            >
              <Button
                onClick={() => {
                  onCancel();
                  setTextContent("");
                }}
                variant="tr_button_cancel"
              >
                Cancel
              </Button>
              <Button
                disabled={textContent.trim().length > 0 ? false : true}
                onClick={() => {
                  if (textContent.trim().length > 0) {
                    onSend(textContent);
                    setTextContent("");
                    setIsSending(true);
                  } else {
                    setTextContent("");
                  }
                }}
              >
                Send
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

const SALES_LABELS = {
  PREMIUM: "PERFORMANCE MANAGEMENT PLATFORM",
  SYSTEMATIC_ENGINE: "STRATEGY MANAGEMENT SOLUTION",
};
const VALID_FLAVOUR_CODES = ["PREMIUM", "SYSTEMATIC_ENGINE"];

const ProductSwitch = () => {
  const [products, setProducts] = useState<any>();
  const [label, setLabel] = useState("");
  const [switchToProduct, setSwitchToProduct] = useState<any>();

  const environment = useEnvironment();

  const showBtn = useMemo(() => {
    if (products == null) {
      return false;
    }

    var environmentSetup = environment.get("setup");
    var selectedProductId = environmentSetup["account"]["product"]["id"];

    var userProductCanSwitch = false;
    if (
      environmentSetup["account"]["user"]["rootProduct"]["productCode"] ===
      "SYSTEMATIC_ENGINE_AND_PREMIUM"
    ) {
      userProductCanSwitch = true;
    }

    let _switchToProduct: any = null;
    if (products != null) {
      var product: any = null;
      for (let i = 0, length = products.length; i < length; i++) {
        product = products[i];
        if (product.value !== selectedProductId) {
          if (_switchToProduct != null) {
            // Disable switch, must have only one option
            userProductCanSwitch = false;
          }

          _switchToProduct = product;
        }
      }
    }

    setSwitchToProduct(_switchToProduct);

    if (_switchToProduct != null && userProductCanSwitch) {
      var label = _switchToProduct.label;
      switch (_switchToProduct.label) {
        case "PERFORMANCE MANAGEMENT PLATFORM": {
          label = "Performance Management Platform";

          break;
        }
        case "STRATEGY MANAGEMENT SOLUTION": {
          label = "Strategy Management Solution";

          break;
        }
        // no default
      }

      setLabel(label);
    }

    return _switchToProduct != null && userProductCanSwitch;
  }, [environment, products]);

  const imgSrc = useMemo(
    () => appConfig.baseUrlImages + "trendrating_logo_small_white.png",
    []
  );

  const onComponentMount = useCallback(async () => {
    const url = "/trendrating-rest/api/product/all";
    const response = await axios.get(url, undefined);
    let data = response["data"]["data"]["products"];
    var datum: any = null;
    var _products: any[] = [];
    var salesLabels = SALES_LABELS;
    var validFlavourCodes = VALID_FLAVOUR_CODES;
    for (let i = 0, length = data.length; i < length; i++) {
      datum = data[i];
      if (validFlavourCodes.indexOf(datum["productCode"]) !== -1) {
        _products.push({
          label: salesLabels[datum["productCode"]],
          value: datum["id"],
        });
      }
    }

    setProducts(_products);
  }, []);

  const switchProduct = useCallback(() => {
    if (switchToProduct == null) {
      return;
    }

    appCookie("productId", switchToProduct.value);

    var url = config.router.base + "/";
    window.location.href = url;
  }, [switchToProduct]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return showBtn ? (
    <Tippy
      theme="security-tooltip"
      content={
        <Box p={0.5}>
          <Typography>Switch to product {label}</Typography>
        </Box>
      }
    >
      <div className="app__switch" id="app-switch" onClick={switchProduct}>
        <div className="app__switch__wrap">
          <img src={imgSrc} alt="" />
        </div>
      </div>
    </Tippy>
  ) : (
    <></>
  );
};
