/**
 * @author Trendrating <info@trendrating.net>
 *
 * @summary Trendrating Price Chart - reviewed code
 */

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    forwardRef,
    RefObject,
    useEffect,
    useImperativeHandle,
    useRef,
} from "react";

type ChartProps = {
    className?: string | undefined;
    options: any;
};

type HighchartsRef = {
    chart: Highcharts.Chart;
    container: RefObject<HTMLDivElement>;
};

/**
 * TrendratingPriceChart
 * @param {object}   displayRatio - chart ratio
 * @param {boolean}  ratings - enable rating view
 * @param {number}   firstDate - Trendrating date
 * @param {object}   functions - event listeners
 * @param {function} functions.measure - listener for measure
 * @param {function} functions.tooltip - listener for tooltip
 * @param {boolean}  historicalRating -
 * @param {string}   scale - "linear" or "logarithmic". Default "logarithmic"
 * @param {boolean}  scaleButton -
 */
export const Chart = forwardRef(({ className, options }: ChartProps, ref) => {
    const chartRef = useRef<HighchartsRef>(null);

    useImperativeHandle(ref, () => chartRef.current?.chart);

    useEffect(() => {
        if (options != null) {
            chartRef.current?.chart?.reflow();
        }
    }, [options]);

    if (options == null) {
        return <></>;
    }

    return (
        <div className={`highchart-container ${className ?? ""}`}>
            <HighchartsReact
                constructorType="stockChart"
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
                immutable={true}
            />
        </div>
    );
});

export const rateScale = {
    "2": { label: "A", class: "rate--A", color: "#008000", value: 2 },
    "1": { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
    "0": {
        label: "-",
        class: "rate--U",
        color: "#efefef",
        value: null,
    }, // in strategy 2018-08-21 (convert)
    "-1": { label: "C", class: "rate--C", color: "#f48400", value: -1 },
    "-2": { label: "D", class: "rate--D", color: "#f00000", value: -2 },
    "A": { label: "A", class: "rate--A", color: "#008000", value: 2 },
    "B": { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
    "C": { label: "C", class: "rate--C", color: "#f48400", value: -1 },
    "D": { label: "D", class: "rate--D", color: "#f00000", value: -2 },
    "U": { label: "-", class: "rate--U", color: "#efefef", value: null },
};

export const colors = {
    border: "#d3d3d3",
    lineGrid: "#d3d3d3",
    line: "#d3d3d3",
    securityDefault: "#2a7092", // #2F7ED8
    securityBenchmark: "#ffcc00", // #133458
    securityRateA: rateScale["2"].color,
    securityRateB: rateScale["1"].color,
    securityRateC: rateScale["-1"].color,
    securityRateD: rateScale["-2"].color,
    securityRateU: rateScale["U"].color,
    trendrating: "#2a7092", // #ffc001 #0da760,
    trendratingGold: "#ffc001",
    trendratingGrey: "#a3a3a3",
};

export function calculateExcessReturn(primaryPrice, benchmarkPrice) {
    let map: Record<number, { b?: number; h: number }> = primaryPrice.reduce(
        (acc, price) => {
            acc[price.d] = { h: price.v };
            return acc;
        },
        {}
    );
    benchmarkPrice.reduce((acc, price) => {
        if (acc[price.d]) {
            acc[price.d].b = price.v;
        }
        return acc;
    }, map);

    let first = true;
    let k: number = 1;

    let serie: any = [];
    for (let date in map) {
        let day = map[date];
        if (day.h && day.b) {
            if (first) {
                first = false;
                k = (100 * day.b) / day.h;
            }
            let v = (k * day.h) / day.b;
            serie.push({ d: date, v: v });
        }
    }
    return serie;
}

/**
 *
 * @param data must sorted from oldest to newest
 * @param targetData
 * @param rescaleAt
 * @param startDate
 * @returns
 */
export function fromJsonToSerieAndMinMaxAndRescale(
    data,
    targetData,
    rescaleAt,
    startDate
) {
    var serie: any = [];
    var rescaleRatio = 1.0;

    if (data == null || data.length === 0) {
        return [];
    }

    if (rescaleAt != null) {
        let firstDate = data[0].d;
        if (firstDate > rescaleAt.d) {
            // storia piu corta di quella del target
            for (let i = targetData.length - 1; i >= 0; i--) {
                if (targetData[i].d <= firstDate) {
                    rescaleAt = targetData[i];
                    break;
                }
            }
        }
        // Starts from the end
        for (let i = data.length - 1; i >= 0; i--) {
            const d = data[i].d;
            // There are no more available elements
            if (d < rescaleAt.d) {
                break;
            }
            if (d === rescaleAt.d) {
                rescaleRatio = rescaleAt.v / data[i].v;
                break;
            }
        }
    }
    for (let i = 0; i < data.length; i++) {
        if (startDate != null && data[i].d < startDate) {
            continue;
        }
        const v = data[i].v * rescaleRatio;
        serie.push([daysToMilliseconds(data[i].d), v <= 0 ? null : v]);
    }
    return serie;
}

/**
 * Converts days in milliseconds
 *
 * @param {Number} days - number of days
 */
export function daysToMilliseconds(days) {
    var quot = Math.floor(days / 5);
    var rem = Math.floor(days % 5);
    var ms = Math.floor((quot * 7 + rem + 4) * 86400 * 1000);
    return ms;
}

/**
 * Converts milliseconds in days
 *
 * @param {Number} milliseconds - milliseconds
 */
export function millisecondsToDays(milliseconds) {
    // http://stackoverflow.com/questions/4055633/what-does-double-tilde-do-in-javascript
    // ~ not: bitwise operator
    // ~~ -> (int)
    var seconds = milliseconds / 1000;
    var t = ~~(seconds / 86400) - 4;
    var quot = ~~(t / 7);
    var rem = ~~(t % 7);
    if (rem === 6) {
        rem = 5; // sunday as saturday
    }
    var days = quot * 5 + rem;
    return days;
}

/**
 * Create marker for last rate
 *
 * @param {Number} milliseconds - a date
 * @param {Number} rate - the rate
 */
export function prepareFlag(milliseconds, rate) {
    // var rateInfo = entity.rateScale[rate];
    var rateInfo = rateScale[rate];

    var flag: any = [];
    switch (rate) {
        case 2:
        case 1:
        case -1:
        case -2:
            flag.push({
                x: milliseconds,
                title: rateInfo.label,
                text: rateInfo.label,
                fillColor: rateInfo.color,
            });
            break;
    }
    return flag;
}

/**
 * Create marker for previous rate
 *
 * @param {Array} history - security history
 * @param {Object} security - a security
 */
export function flagPrev(history, security) {
    var flag = [];
    if (
        security.prr &&
        ((security.rrr > 0 && security.rc > 0) ||
            (security.rrr < 0 && security.rc < 0))
    ) {
        flag = prepareFlag(daysToMilliseconds(security.drr), security.rrr);
    }
    return flag;
}

export function fromJsonToExcessReturn(H, B) {
    var map = {};
    var day;
    var v;
    var serie: any = [];
    var first;

    for (let i = 0, N = H.length; i < N; i++) {
        day = H[i];
        map[day["d"]] = { h: day["v"] };
    }
    for (let i = 0, N = B.length; i < N; i++) {
        day = B[i];
        if (map[day["d"]]) {
            map[day["d"]].b = day["v"];
        }
    }
    first = true;
    let k: number = 1;
    for (let date in map) {
        day = map[date];
        v = null;
        if (day.h && day.b) {
            if (first) {
                first = false;
                k = (100 * day.b) / day.h;
            }
            v = (k * day.h) / day.b;
        }
        serie.push([daysToMilliseconds(date), v]);
    }
    return serie;
}

export function fromJsonToSerie(/*Array*/ data) {
    var serie: any = [];
    for (let i = 0, length = data.length; i < length; i++) {
        serie.push([
            daysToMilliseconds(data[i]["d"]), // the date
            data[i]["v"], // close
        ]);
    }
    serie.sort(function (a, b) {
        return a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0;
    });
    return serie;
}

export function fromJsonToSerieAndMinMax(
    /*Array*/ data,
    /*Object*/ boundaries,
    /*Number*/ translationFactor?: any
) {
    var serie: any = [];
    var vy: any = null;
    for (let i = 0, length = data.length; i < length; i++) {
        vy = parseFloat(data[i]["v"]);
        if (vy > boundaries.max) {
            boundaries.max = vy;
        }
        if (vy < boundaries.min) {
            boundaries.min = vy;
        }
        serie.push([
            daysToMilliseconds(data[i]["d"]), // the date
            translationFactor ? vy / translationFactor : vy, // close
        ]);
    }
    return serie;
}

/**
 * Return min and max price value
 *
 * @param {Array} history - security history
 */
export function historyMinMax(history) {
    var minPrice = Number.MAX_VALUE;
    var maxPrice = Number.MIN_VALUE;
    var price: any = null;
    for (let i = 0, length = history.length; i < length; i++) {
        price = history[i].y;
        if (price < minPrice) {
            minPrice = price;
        }

        if (price > maxPrice) {
            maxPrice = price;
        }
    }
    return {
        max: maxPrice,
        min: minPrice,
    };
}

/**
 * Normalize data based on a given min/max interval and target scale
 * (scaleOut)
 *
 * @param {Array} data - array of object with a unique id property
 * @param {String} property - property to consider
 * @param {Object} interval - an object calculate using getMinMax
 * @param {Object} scaleOut - the target scale
 */
export function normalize(data, idProperty, property, interval, scaleOut) {
    if (data.length > 0) {
        var max = interval.max;
        // order data by absolute values: high to low
        var object: any = null;
        for (let i = 0, length = data.length; i < length; i++) {
            object = data[i];

            let sign = object[property] > 0 ? 1 : -1;
            let valueAbs = Math.abs(object[property]);
            let valueCapped = Math.min(valueAbs, max);
            let rescaled = rescale(
                valueCapped,
                {
                    max: max,
                    min: 0,
                },
                scaleOut
            );

            object[property + "_rescaled"] = sign * rescaled;
        }
        return data;
    }
    return [];
}

/**
 * Re-scale a value from a scale (scaleIn) to another (scaleOut)
 *
 * @param {Number} value - the value to be rescaled
 * @param {Object} scaleIn - the starting scale
 * @param {Object} scaleOut - the target scale
 */
export function rescale(value, scaleIn, scaleOut) {
    var iMin = scaleIn.min;
    var iMax = scaleIn.max;
    var oMin = scaleOut.min;
    var oMax = scaleOut.max;

    return oMin + ((oMax - oMin) * (value - iMin)) / (iMax - iMin);
}
