import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PeerSize } from "../../../../../../../components/PeerSize/PeerSize";
import { ActionsPeerConstraints } from "../PeerConstraintsBar";
import { IssuerTree } from "./IssuerTree";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import Search from "../../../../../widgets/ReactSearch/Search";
import { Box } from "@mui/material";
import { RadioButtonBar } from "../../../../../../../components/RadioButtonBar/RadioButtonBar";
import { RadioButtonGroup } from "../../../../../../../components/RadioButtonGroup/RadioButtonGroup";

type PeerControlsRowProps = {
  dispatch: (act: ActionsPeerConstraints) => void;
  onSearch: (value) => void;
  options: (type) => any[];
  assetClass: "ETF" | "stock";
  defaultState: any;
};

type Controls = {
  analytic: string | null;
  thirdDimension: string | null;
  timeframe: string | null;
  visualization: string | null;
  dispersionTimeframe: string;
};

const PAGE_KEY = "analysis_market";

const TIMEFRAME_DICT = {
  1: "today",
  5: "lastWeek",
  20: "lastMonth",
};

export default function PeerControlsRow({
  dispatch,
  onSearch,
  options,
  assetClass,
  defaultState,
}: PeerControlsRowProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const usage = window.App.usage;
  const environment = useEnvironment();
  const configuration = useMemo(
    () => environment.get("account")?.product?.configuration[PAGE_KEY],
    [environment]
  );
  const [controls, setControls] = useState<Controls>({
    analytic: defaultState.analytic,
    thirdDimension: null,
    visualization: defaultState.visualization,
    timeframe: TIMEFRAME_DICT?.[configuration?.timeFrameDefault ?? 1],
    dispersionTimeframe: defaultState.dispersionTimeframe,
  });
  const showAssetClassSelector = useMemo(() => {
    const assetClasses = configuration.assetClasses;

    const isStockEnabled =
      "stock" in assetClasses && assetClasses.stock === true;
    const isEtfEnabled = "etf" in assetClasses && assetClasses.etf === true;

    return isStockEnabled && isEtfEnabled;
  }, [configuration]);
  const analyticOpts = useMemo(() => options("analytic"), [options]);
  const assetClassOpts = useMemo(
    () => [
      {
        label: "Stock",
        value: "stock",
        selected: true,
      },
      {
        label: "ETF",
        value: "ETF",
      },
    ],
    []
  );
  const sizeOpts = useMemo(() => options("size"), [options]);
  const timeframeOpts = useMemo(() => options("timeframe"), [options]);
  const dispersionTimeframe = useMemo(
    () => options("dispersionTimeframe"),
    [options]
  );

  const defaultVizOpts = useMemo(() => {
    switch (defaultState.analytic) {
      default:
      case "tcr": {
        if (controls.timeframe === "today") {
          return options("visualization_tcr");
        } else {
          return options("visualization_tcr_changesReversals");
        }
      }

      case "topBottom": {
        return options("visualization_topBottom_number");
      }
      case "abPercentage": {
        if (controls.timeframe === "today") {
          return options("visualization_abPercentage");
        } else {
          return options("visualization_abPercentage_changes");
        }
      }

      case "upgradesDowngrades": {
        if (configuration.upgradeDowngradePerc === true) {
          return options("visualization_upgradesDowngrades");
        } else {
          return options("visualization_upgradesDowngrades_number_only");
        }
      }
    }
  }, [
    configuration.upgradeDowngradePerc,
    controls.timeframe,
    defaultState.analytic,
    options,
  ]);

  const [vizOpts, setVizOpts] = useState(defaultVizOpts);

  const onChangeControls = useCallback(
    (widgetType: keyof Controls, value, controls) => {
      const updatedControls = {
        ...controls,
        [widgetType]: value,
      };
      setControls(updatedControls);
      const { analytic, timeframe } = controls;

      var _options: any = [];
      if (widgetType === "analytic" || widgetType === "timeframe") {
        switch (widgetType === "analytic" ? value : analytic) {
          case "topBottom": {
            _options = options("visualization_topBottom_number");

            setVizOpts(_options);

            break;
          }
          case "cdPercentage":
          case "abPercentage": {
            switch (widgetType === "analytic" ? timeframe : value) {
              case "lastMonth":
              case "lastWeek": {
                _options = options("visualization_abPercentage_changes");

                setVizOpts(_options);

                break;
              }
              case "today":
              default: {
                _options = options("visualization_abPercentage");

                setVizOpts(_options);
              }
            }

            break;
          }
          case "upgradesDowngrades": {
            if (configuration.upgradeDowngradePerc === true) {
              _options = options("visualization_upgradesDowngrades");
            } else {
              _options = options(
                "visualization_upgradesDowngrades_number_only"
              );
            }

            setVizOpts(_options);

            break;
          }
          case "tcr":
          default: {
            switch (widgetType === "analytic" ? timeframe : value) {
              case "lastMonth":
              case "lastWeek": {
                _options = options("visualization_tcr_changesReversals");

                setVizOpts(_options);

                break;
              }
              case "today":
              default: {
                _options = options("visualization_tcr");

                setVizOpts(_options);
              }
            }
          }
        }
      }
    },
    [configuration.upgradeDowngradePerc, options]
  );

  const onChangeDispersionTimeframe = useCallback(
    (value) => {
      setControls({ ...controls, dispersionTimeframe: value });
    },
    [controls]
  );

  useEffect(
    () =>
      setControls((prevState) => {
        if (!vizOpts.some((item) => item.value === prevState.visualization)) {
          return {
            ...prevState,
            visualization: vizOpts.find((item) => item.selected).value,
          };
        } else return prevState;
      }),
    [vizOpts]
  );

  useEffect(() => {
    const { analytic, visualization, thirdDimension, timeframe } = controls;
    dispatch({ type: "SET_ANALYTIC", payload: analytic as any });
    dispatch({ type: "SET_VISUALIZATION", payload: visualization as any });
    dispatch({ type: "SET_THIRD_DIM", payload: thirdDimension });
    dispatch({ type: "SET_TIMEFRAME", payload: timeframe as any });
    dispatch({
      type: "SET_DISPERSION_TIMEFRAME",
      payload: controls.dispersionTimeframe as any,
    });
  }, [controls, dispatch]);

  const onSearchHandler = useCallback(
    (e) => {
      if (!e) {
        return;
      }
      onSearch(e.id);
    },
    [onSearch]
  );

  return (
    <fieldset className="tPageAnalysisMarket-constraintsAnalytic">
      <div className="tMarketConstraintsBar">
        <div className="tMarketConstraintsBar-layout">
          {showAssetClassSelector && (
            <div className="tMarketConstraintsBar-section">
              <label className="tMarketConstraintsBar-label">Asset Class</label>
              <div>
                <RadioButtonBar
                  options={assetClassOpts}
                  onChange={(value) => {
                    if (value === "ETF") {
                      const info = {
                        action: "LANDING",
                        function: "MARKET_ETF",
                      };

                      usage.record(info);

                      navigate("/app/analysis/markets/ETF");
                    } else {
                      const info = {
                        action: "LANDING",
                        function: "MARKET",
                      };

                      usage.record(info);
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="tMarketConstraintsBar-section">
            <label className="tMarketConstraintsBar-label">
              {t("Analytic")}
            </label>
            <div>
              <RadioButtonBar
                options={analyticOpts}
                onChange={(value) =>
                  onChangeControls("analytic", value, controls)
                }
              />
            </div>
          </div>
          <div className="tMarketConstraintsBar-section">
            {assetClass === "ETF" ? (
              <>
                <label className="tMarketConstraintsBar-label">Issuer</label>

                <IssuerTree
                  onChangeZDimension={(zDimension) =>
                    onChangeControls("thirdDimension", zDimension, controls)
                  }
                />
              </>
            ) : (
              <>
                <label className="tMarketConstraintsBar-label">
                  {t("Size")}
                </label>
                <div>
                  <PeerSize
                    options={sizeOpts}
                    isNullWhenAllAreSelected={true}
                    onClickItem={(value) =>
                      onChangeControls("thirdDimension", value, controls)
                    }
                  />
                </div>
              </>
            )}
          </div>
          {configuration?.timeFrameSwitch === true && (
            <div className="tMarketConstraintsBar-section">
              <label className="tMarketConstraintsBar-label">
                {t("Timeframe")}
              </label>
              <div>
                {controls.analytic !== "topBottom" ? (
                  <RadioButtonGroup
                    options={timeframeOpts}
                    initValue={controls.timeframe}
                    onChange={(value) =>
                      onChangeControls("timeframe", value, controls)
                    }
                  />
                ) : (
                  <RadioButtonGroup
                    options={dispersionTimeframe}
                    initValue={controls.dispersionTimeframe}
                    onChange={(value) => onChangeDispersionTimeframe(value)}
                  />
                )}
              </div>
            </div>
          )}
          {vizOpts.length > 1 && (
            <div className="tMarketConstraintsBar-section">
              <label className="tMarketConstraintsBar-label">
                {t("Visualization")}
              </label>
              <div>
                <RadioButtonGroup
                  options={vizOpts}
                  initValue={controls.visualization}
                  onChange={(value) =>
                    onChangeControls("visualization", value, controls)
                  }
                />
              </div>
            </div>
          )}

          <Box pl={20} display={"inline-flex"} alignItems={"center"}>
            <Search
              placeHolder={t("c_placeholder_lookup_peer")}
              lookUp={"PEER"}
              hasRecents={false}
              showInstrumentInfoOnSelect={false}
              onSelectInstrument={onSearchHandler}
            />
          </Box>
        </div>
      </div>
    </fieldset>
  );
}
