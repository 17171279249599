import { Instruments } from "../../../../../../../../api/compute/Instruments";
import { dataInjector } from "../../../../../../../../api/compute/commons";

export class SummaryTableData {
  constructor(
    public holdings,
    public comparisonListHoldings,
    public list1Positions,
    public list2Positions,
    public listType,
    public list2Type
  ) {}

  //TODO: optimize this step by using a map instead a find in a loop
  getSecurityFromList = (symbol, list) => {
    return list.find((item) => item.symbol === symbol);
  };

  prepareDataStructure = (data, positions, env) => {
    if (data != null) {
      const instruments = new Instruments(env);
      const result = data.map((item) => {
        return {
          name: item.name,
          symbol: item.symbol,
          vc: item.vc,
          rc: item.rc,
          ticker: item.ticker,
          currency: item.currency,
          chartUri: instruments.chart({ symbol: item.symbol }),
        };
      });

      return dataInjector(result, positions, ["weight"]);
    }
  };

  extractSymbolsFromDataTable = (env) => {
    const result = this.get(env)?.map((item) => item.symbol) ?? [];

    return result;
  };

  get = (env) => {
    const holdings = this.holdings;
    const holdingsComparison = this.comparisonListHoldings;
    const listType = this.listType;
    const list2Type = this.list2Type;

    if (holdings != null && holdingsComparison != null) {
      const cleanedHoldings = this.prepareDataStructure(
        holdings,
        this.list1Positions,
        env
      );
      const cleanedHoldingsComparison = this.prepareDataStructure(
        holdingsComparison,
        this.list2Positions,
        env
      );

      const operationalList = [
        ...cleanedHoldings,
        ...cleanedHoldingsComparison,
      ];

      const result = operationalList.map((el) => {
        const security = el;
        const result = {
          ...security,
          weightDiff: null,
          firstListType: listType,
          secondListType: list2Type,
          weight1:
            this.getSecurityFromList(el.symbol, cleanedHoldings)?.weight ??
            null,
          weight2:
            this.getSecurityFromList(el.symbol, cleanedHoldingsComparison)
              ?.weight ?? null,
          isInFirstList: this.isInHoldings(el.symbol, this.list1Positions),
          isInSecondList: this.isInHoldings(el.symbol, this.list2Positions),
        };

        delete result.weight;

        if (listType === "BASKET" || list2Type === "BASKET") {
          if (listType === "BASKET" && list2Type !== "BASKET") {
            result.weight1 = null;
            result.weightDiff = result.weight2;
          }

          if (listType !== "BASKET" && list2Type === "BASKET") {
            result.weight2 = null;
            result.weightDiff = result.weight1;
          }

          if (listType === "BASKET" && list2Type === "BASKET") {
            result.weight1 = null;
            result.weight2 = null;
            result.weightDiff = false;
          }
        } else {
          result.weightDiff = result.weight1 - result.weight2;
        }

        return result;
      });

      return Array.from(new Set(result.map((item) => item.symbol))).map(
        (symbol) => {
          return result.find((el) => el.symbol === symbol);
        }
      );
    }
  };

  isInHoldings(security, holdings) {
    return holdings.some((item) => item.symbol === security);
  }
}
