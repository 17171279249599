/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyYearlyAnalytics
 * @summary Strategy - Yearly Analytics
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity, substituteVariables } from "./Generator";

export function sectionsYearlyAnalytics(input, section, datum, formatter) {
  const _sections: any = [];

  if (section["content"]["headline"]["isEnabled"]) {
    const _section = {
      data: {
        text: escapeEntity(datum["headline"].toUpperCase()),
      },
      type: "header1",
    };
    _sections.push(_section);
  }

  if (
    !section["content"]["yearlyPerformance"]["isEnabled"] &&
    !section["content"]["maxDrawdown"]["isEnabled"] &&
    !section["content"]["monthlyStandardDeviation"]["isEnabled"] &&
    !section["content"]["turnover"]["isEnabled"]
  ) {
    // No option is enabled, just return
    return _sections;
  }

  if (section["content"]["years"] === "ROWS") {
    const _section = sectionsYearlyAnalyticsDetailByRows(
      input,
      section,
      datum,
      formatter
    );
    _sections.push(_section);
  } else {
    if (section["content"]["yearlyPerformance"]["isEnabled"]) {
      if (section["content"]["yearlyPerformance"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["yearlyPerformance"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearlyAnalyticsDetail(
        input,
        section,
        datum,
        "strategyYearlyPerformance",
        "benchmarkYearlyPerformance",
        "deltaYearlyPerformance",
        formatter
      );
      _sections.push(_section);
    }
    if (section["content"]["maxDrawdown"]["isEnabled"]) {
      if (section["content"]["maxDrawdown"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["maxDrawdown"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearlyAnalyticsDetail(
        input,
        section,
        datum,
        "strategyMaxDrawdown",
        "benchmarkMaxDrawdown",
        "deltaMaxDrawdown",
        formatter
      );
      _sections.push(_section);
    }
    if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
      if (section["content"]["monthlyStandardDeviation"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["monthlyStandardDeviation"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearlyAnalyticsDetail(
        input,
        section,
        datum,
        "strategyVolatility",
        "benchmarkVolatility",
        "deltaVolatility",
        formatter
      );
      _sections.push(_section);
    }
    if (section["content"]["turnover"]["isEnabled"]) {
      if (section["content"]["turnover"]["content"]) {
        const _section = {
          data: {
            text: section["content"]["turnover"]["content"],
          },
          type: "header1",
        };
        _sections.push(_section);
      }
      const _section = sectionsYearlyAnalyticsDetail(
        input,
        section,
        datum,
        "turnover",
        null,
        null,
        formatter
      );
      _sections.push(_section);
    }
  }

  return _sections;
}

function sectionsYearlyAnalyticsDetail(
  input,
  section,
  data,
  strategyField,
  benchmarkField,
  deltaField,
  formatter
) {
  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [[]],
    },
    type: "table",
  };
  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];
  const hasAnnualized = section["content"]["annualized"]["isEnabled"];
  // Head setup
  table["data"]["head"][0].push({
    style: null,
    value: "Year",
  });
  for (let i = 0; i < data["analytics"]["yearly"].length; i++) {
    const item = data["analytics"]["yearly"][i];

    if (item["timeFrame"].toLowerCase() === "annualized") {
      if (hasAnnualized) {
        table["data"]["head"][0].push({
          style: {
            align: "right",
          },
          value:
            section["content"]["annualized"]["content"] || item["timeFrame"],
        });
      } else {
        continue;
      }
    } else {
      table["data"]["head"][0].push({
        style: {
          align: "right",
        },
        value: item["timeFrame"],
      });
    }
  }
  if (hasStrategy) {
    const tableRow: any = [];
    tableRow.push({
      style: null,
      value: section["content"]["strategy"]["isEnabled"]
        ? substituteVariables(
            section["content"]["strategy"]["content"],
            input["target"]
          )
        : "Strategy",
    });
    for (let i = 0; i < data["analytics"]["yearly"].length; i++) {
      const item = data["analytics"]["yearly"][i];

      if (item["timeFrame"].toLowerCase() === "annualized" && !hasAnnualized) {
        continue;
      }

      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[strategyField],
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }

  if (hasBenchmark && benchmarkField != null) {
    const benchmark = data["benchmark"];
    const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
    const isNeutralEqualWeighted =
      benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";
    let benchmarkName: any = "";
    if (isNeutralStrategy) {
      benchmarkName = "Neutral Strategy";
    } else if (isNeutralEqualWeighted) {
      benchmarkName = "Neutral Strategy Equal Weighted";
    } else {
      benchmarkName = substituteVariables(
        section["content"]["benchmark"]["content"],
        data["benchmark"]
      );
    }

    const tableRow: any = [];
    tableRow.push({
      style: null,
      value: section["content"]["benchmark"]["isEnabled"]
        ? benchmarkName
        : "Benchmark",
    });
    for (let i = 0; i < data["analytics"]["yearly"].length; i++) {
      const item = data["analytics"]["yearly"][i];

      if (item["timeFrame"].toLowerCase() === "annualized" && !hasAnnualized) {
        continue;
      }

      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[benchmarkField],
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }
  if (hasDelta && deltaField != null) {
    const tableRow: any = [];
    tableRow.push({
      style: null,
      value: section["content"]["delta"]["content"],
    });
    for (let i = 0; i < data["analytics"]["yearly"].length; i++) {
      const item = data["analytics"]["yearly"][i];

      if (item["timeFrame"].toLowerCase() === "annualized" && !hasAnnualized) {
        continue;
      }

      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: item[deltaField],
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }
  return table;
}

function sectionsYearlyAnalyticsDetailByRows(input, section, data, formatter) {
  const format = formatter;
  // Table has two headers
  const table: any = {
    data: {
      body: [],
      head: [[], []],
    },
    type: "table",
  };
  // Used to put the title of the _section over portfolio/benchmark/delta
  // depending on what is enabled
  let alreadyHaveRowPadding = false;

  const hasStrategy = section["content"]["strategy"]["isEnabled"];
  const hasBenchmark = section["content"]["benchmark"]["isEnabled"];
  const hasDelta = section["content"]["delta"]["isEnabled"];
  const hasAnnualized = section["content"]["annualized"]["isEnabled"];

  let hasAlreadyCreatedYear = false;

  // Head setup
  if (section["content"]["yearlyPerformance"]["isEnabled"]) {
    if (!hasAlreadyCreatedYear) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: {
          color: "#000000",
        },
        value: "Year",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["yearlyPerformance"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }

      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedYear = true;
  }

  if (section["content"]["maxDrawdown"]["isEnabled"]) {
    if (!hasAlreadyCreatedYear) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: null,
        value: "Year",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["maxDrawdown"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }

      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedYear = true;
  }

  if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
    if (!hasAlreadyCreatedYear) {
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
      table["data"]["head"][1].push({
        style: null,
        value: "Year",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["monthlyStandardDeviation"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    }
    if (hasBenchmark) {
      const benchmark = data["benchmark"];
      const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
      const isNeutralEqualWeighted =
        benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

      let benchmarkTitle: any = "";

      if (isNeutralStrategy) {
        benchmarkTitle = "Neutral Strategy";
      } else if (isNeutralEqualWeighted) {
        benchmarkTitle = "Neutral Strategy Equal Weighted";
      } else {
        benchmarkTitle = substituteVariables(
          section["content"]["benchmark"]["content"],
          data["benchmark"]
        );
      }

      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["benchmark"]["isEnabled"]
          ? benchmarkTitle
          : "Benchmark",
      });
    }
    if (hasDelta) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["delta"]["content"],
      });
    }
    hasAlreadyCreatedYear = true;
  }

  if (section["content"]["turnover"]["isEnabled"]) {
    if (!hasAlreadyCreatedYear) {
      table["data"]["head"][1].push({
        style: null,
        value: "Year",
      });
      table["data"]["head"][0].push({
        style: null,
        value: "",
      });
    }
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["turnover"]["content"],
    });
    alreadyHaveRowPadding = false;
    if (hasStrategy) {
      if (alreadyHaveRowPadding) {
        table["data"]["head"][0].push({
          style: null,
          value: "",
        });
      } else {
        alreadyHaveRowPadding = true;
      }
      table["data"]["head"][1].push({
        style: {
          align: "right",
          color: "#000000",
        },
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      });
    } else {
      // Same column
      table["data"]["head"][1].push({
        style: {
          align: "right",
        },
        value: "",
      });
    }
    hasAlreadyCreatedYear = true;
  }

  for (let i = 0; i < data["analytics"]["yearly"].length; i++) {
    const tableRow: any = [];
    const item = data["analytics"]["yearly"][i];
    hasAlreadyCreatedYear = false; // Reset state
    if (section["content"]["yearlyPerformance"]["isEnabled"]) {
      if (!hasAlreadyCreatedYear) {
        if (item["timeFrame"].toLowerCase() === "annualized") {
          if (hasAnnualized) {
            tableRow.push({
              style: null,
              value:
                section["content"]["annualized"]["content"] ||
                item["timeFrame"],
            });
          } else {
            continue;
          }
        } else {
          tableRow.push({
            style: null,
            value: item["timeFrame"],
          });
        }
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyYearlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkYearlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaYearlyPerformance"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedYear = true;
    }

    if (section["content"]["maxDrawdown"]["isEnabled"]) {
      if (!hasAlreadyCreatedYear) {
        tableRow.push({
          style: null,
          value: item["timeFrame"],
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaMaxDrawdown"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedYear = true;
    }

    if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
      if (!hasAlreadyCreatedYear) {
        tableRow.push({
          style: null,
          value: item["timeFrame"],
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["strategyVolatility"],
            valueHelper: null,
          }),
        });
      }
      if (hasBenchmark) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["benchmarkVolatility"],
            valueHelper: null,
          }),
        });
      }
      if (hasDelta) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["deltaVolatility"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedYear = true;
    }

    if (section["content"]["turnover"]["isEnabled"]) {
      if (!hasAlreadyCreatedYear) {
        tableRow.push({
          style: null,
          value: item["timeFrame"],
        });
      }
      if (hasStrategy) {
        tableRow.push({
          style: {
            align: "right",
          },
          value: format.custom("number", {
            options: customPropertiesFormattingOptions["PERCENTAGE"],
            output: "PDF",
            value: item["turnover"],
            valueHelper: null,
          }),
        });
      }
      hasAlreadyCreatedYear = true;
    }

    // Add row to table
    table["data"]["body"].push(tableRow);
  }

  return table;
}
