import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { v4 as uuidv4 } from "uuid";

type DropdownMenuProps = {
  children: JSX.Element | JSX.Element[];
  handleDropDownClick: (value: string) => void;
  options: ({ label: string; value: any } | { type: "separator" })[];
};

const ListItemStyled = styled(
  (props: { className?: string; options: any; handleDropDownClick: any }) => (
    <Box display={"flex"} padding={1} flex={1} minHeight={0}>
      <Box
        flex={1}
        minHeight={0}
        component={"ul"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        overflow={"auto"}
      >
        {props.options.map((node: any) => {
          if ("type" in node && node.type === "separator") {
            return (
              <hr
                style={{
                  border: 0,
                  clear: "both",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#77797a",
                  height: "1px",
                }}
                key={uuidv4()}
              />
            );
          } else {
            return (
              <li
                key={uuidv4()}
                className={props.className}
                onClick={(event) => {
                  event.stopPropagation();
                  props.handleDropDownClick(node.value);
                }}
              >
                <Typography>{node.label}</Typography>
              </li>
            );
          }
        })}
      </Box>
    </Box>
  )
)({
  cursor: "pointer",
  "&:hover": {
    color: "#2a7090",
  },
});

const PopperStyled = styled(
  (props: {
    children: any;
    className?: string;
    options: any;
    handleDropDownClick: any;
  }) => (
    <Tooltip
      classes={{ tooltip: props.className }}
      title={
        <ListItemStyled
          options={props.options}
          handleDropDownClick={props.handleDropDownClick}
        />
      }
    >
      {props.children}
    </Tooltip>
  )
)({
  backgroundColor: "white!important",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
  color: "black",
  minWidth: "10vw",
  maxHeight: "35vh",
  display: "flex",
  minHeight: 0,
});

export function DropdownMenu({
  children,
  options,
  handleDropDownClick,
}: DropdownMenuProps) {
  return (
    <PopperStyled options={options} handleDropDownClick={handleDropDownClick}>
      <Box sx={{ cursor: "pointer" }}>{children}</Box>
    </PopperStyled>
  );
}
