import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { SelectionUi2Api } from "../../../../../../../api/compute/SelectionUi2Api";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
import Search from "../../../../../widgets/ReactSearch/Search";
import DatePicker from "../advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";
import SelectHistory from "./SelectHistory";

type TrackingProps = {
  strategy: any;
  dispatch: Function;
  more?: boolean;
  benchmark?: string | null;
  hedging?: any;
  currency?: string;
  backtesting?: any;
};

const currencyMap = {
  LOCAL: "Local",
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  CAD: "CAD",
  JPY: "JPY",
  AUD: "AUD",
};

export default function Tracking({
  strategy,
  dispatch,
  more,
  benchmark,
  hedging,
  currency,
  backtesting,
}: TrackingProps) {
  const environment = useEnvironment();
  const envTransformation = useMemo(
    () =>
      environment.get("configuration").get("strategyBuilder")["hedging"][
        "edit"
      ]["transformations"],
    [environment]
  );
  const serverEncoder = useMemo(
    () => new SelectionUi2Api(envTransformation),
    [envTransformation]
  );
  const trackingSearch = useMemo(() => benchmark ?? undefined, [benchmark]);

  const constraintsValue = useMemo(
    () => [
      {
        dimension: "rc",
        operator: "equals",
        segments: [-1, -2],
      },
    ],
    []
  );

  const headgingObj = useMemo(
    () => ({
      constraints: {
        hedgingStrategy: "HEDGING_SMART",
        value: serverEncoder.decode(constraintsValue),
      },
      instrument: trackingSearch,
      leverage: 1,
    }),
    [constraintsValue, serverEncoder, trackingSearch]
  );

  const _getDate = useCallback((type?) => {
    var date = new Date();
    var today = date.getUTCDay();
    var delta = 1;

    switch (type) {
      case "TRACKING":
      case "INCEPTION":
      default: {
        switch (today) {
          case 0: {
            delta = 2;
            break;
          } // Sunday
          case 1: {
            delta = 3;
            break;
          } // Monday
          default: {
            delta = 1;
          } // Other days
        }
        date.setDate(date.getUTCDate() - delta);
      }
    }

    return date;
  }, []);
  const date = useMemo(() => _getDate(), [_getDate]);

  const inceptionDate = useMemo(
    () => backtesting?.inceptionDate ?? date,
    [date, backtesting?.inceptionDate]
  );

  const inceptionValue = useMemo(
    () => backtesting?.inceptionValue ?? 100,
    [backtesting?.inceptionValue]
  );

  const onSearchSelectHandler = useCallback(
    (e) => {
      if (!e) {
        dispatch({
          type: "SET_BENCHMARK_SEARCH",
          payload: null,
        });
      } else {
        if (e.symbol !== benchmark) {
          dispatch({
            type: "SET_BENCHMARK_SEARCH",
            payload: e?.symbol ?? null,
          });
        }
      }
    },
    [benchmark, dispatch]
  );

  const search = useMemo(
    () => (
      <Search
        value={trackingSearch}
        showInstrumentInfoOnSelect={false}
        hasRecents={false}
        cleanOnSelect={false}
        onSelectInstrument={onSearchSelectHandler}
      />
    ),
    [onSearchSelectHandler, trackingSearch]
  );

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">4</span> Tracking
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <div className="layout-grid__col layout-grid__col--50">
            <label>Benchmark</label>
            <br />
            {search}
          </div>
          <div className="layout-grid__col layout-grid__col--50">
            <label>History</label>
            <br />
            <SelectHistory
              type={backtesting.period.type}
              dispatcher={dispatch}
              value={backtesting.period.value}
            />
          </div>
        </li>
        <FormControl>
          <Typography>Performance</Typography>
          <RadioGroup
            onChange={(e) => {
              let value = e.target.value;
              dispatch({
                type: "SET_PERFORMACE",
                payload: value,
              });
            }}
            value={strategy.performance}
            row
          >
            <FormControlLabel
              value="NONE"
              control={<Radio size="small" />}
              label="Price"
            />
            <FormControlLabel
              value="REBALANCE"
              control={<Radio size="small" />}
              label="Total return"
            />
          </RadioGroup>
        </FormControl>
        <CustomFieldCollapser
          isOpen={more}
          setIsOpen={(e) => dispatch({ type: "MORE_TRACKING", payload: e })}
        >
          <li className="form__field">
            <div
              className="form__more"
              data-dojo-attach-event="collapser-resize:_collapserResize"
              data-dojo-type="app/ui/form/FieldCollapser"
            >
              <div data-dojo-attach-point="nodeDynamicHedging">
                {/* <label> */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={trackingSearch != null ? false : true}
                        onChange={(e) => {
                          dispatch({
                            type: "SET_HEADGING",
                            payload: e.target.checked ? headgingObj : null,
                          });
                        }}
                        checked={hedging ? true : false}
                      />
                    }
                    label="Dynamic hedging"
                  />
                </FormGroup>
              </div>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>Currency</Typography>
                <Select
                  sx={{ width: "46%" }}
                  value={currency?.toUpperCase()}
                  size="small"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_CURRENCY",
                      payload: e.target.value,
                    });
                  }}
                >
                  {Object.entries(currencyMap).map((item, index) => (
                    <MenuItem key={index} value={item[0]}>
                      <Typography>{item[1]}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <div className="layout-grid">
                <div className="layout-grid__col layout-grid__col--50">
                  <label>Inception date</label>
                  <DatePicker
                    maxDate={date}
                    input={inceptionDate}
                    onChangeDate={(e) => {
                      dispatch({
                        type: "SET_INCEPTION_DATE",
                        payload: new Date(e),
                      });
                    }}
                  />
                </div>
                <div className="layout-grid__col layout-grid__col--50">
                  <label>Inception value</label>
                  <TextField
                    size="small"
                    type="text"
                    value={inceptionValue}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_INCEPTION_VALUE",
                        payload: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </li>
        </CustomFieldCollapser>
      </ul>
    </fieldset>
  );
}
