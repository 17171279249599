/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/_color
 * @summary Colors used for special formatting, charts
 *
 */
export const _color = {
    trendratingColors: [
        "#47ACB1",
        "#F26522",
        "#542923",
        "#286C4F",
        "#676766",
        "#C9222B",
        "#96247A",
        "#FFCD34",
        "#F9AA7B",
        "#ADD5D7",
        "#FFE8AF",
    ],
};
