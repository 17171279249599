/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/LeadPage
 * @summary Page for lead users
 *
 */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function LeadPage() {
    const { t, ready } = useTranslation();

    useEffect(() => {
        if (ready) {
            document.title = `${t("Request_a_trial")} - Trendrating`;
        }
    }, [t, ready]);

    return (
        <div className="PageLead">
            <div className="lead">
                <h1 className="lead-title">
                    Trendrating is the leader in momentum models, analytics and
                    technology.
                </h1>
                <p className="lead-message">
                    The momentum factor has been widely documented as a very
                    strong and persistent market anomaly that can improve equity
                    investment returns.
                </p>
                <p className="lead-message">
                    We provide proven investment intelligence to improve
                    portfolio returns. The positive contribution can be easily
                    tested and measured. The effectiveness of the ratings within
                    our web-based platform is fully transparent.
                </p>
                <div className="lead-boxes">
                    <div className="lead-box">
                        <h1 className="lead-title">
                            Do not have a Trendrating account?
                        </h1>

                        <p className="lead-message">
                            With Trendrating you can evaluate the direction and
                            the quality of medium to long-term price trends for
                            stocks, ETFs, indices, sectors and countries.
                            <br />
                            Trendrating is equally effective at identifying
                            meaningful trends at the macro level, as well as
                            screening from the bottom up to validate or generate
                            new ideas.
                        </p>

                        <p className="lead-message">
                            In addition, you can build, back-test and implement
                            bespoke momentum-based strategies in just a few
                            clicks.
                        </p>

                        <p className="lead-message">
                            Click on the Request Access button and a sales
                            representative in your region will contact you to
                            discuss a trial.
                        </p>

                        <a
                            className="button button--login lead-button"
                            href="https://www.trendrating.com/contact-us/"
                        >
                            Request Access
                        </a>
                        <br />
                        <Link to="/">Back to login</Link>
                    </div>
                    <div className="lead-box">
                        <iframe
                            className="lead-video"
                            title="Lead video"
                            src="//player.vimeo.com/video/114216531?title=0&amp;byline=0&amp;portrait=0"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
