import { Box, Card, CardContent } from "@mui/material";
import TemplateListItem from "./TemplateListItem";

type Props = {
  options: any[];
  onEdit: Function;
  onSelect: Function;
  onDelete: Function;
  value: any;
};

export default function TemplateList({
  options,
  onEdit,
  onSelect,
  onDelete,
  value,
}: Props) {
  return (
    <Box
      paddingY={1}
      paddingX={2}
      height={"100%"}
      gap={1}
      display={"flex"}
      flexDirection={"column"}
      overflow={"auto"}
    >
      {options.map((opt, index) => (
        <Card
          onClick={() => {
            onSelect(opt);
          }}
          key={index}
          sx={{
            boxShadow: 3,
            cursor: "pointer",
            backgroundColor: value.index === index ? "#2a7092" : "#fff",
            overflow:"unset"
          }}
        >
          <CardContent sx={{ pb: "16px !important" }}>
            <TemplateListItem
              orientation={opt.configuration.orientation}
              isEditable={opt.isEditable}
              name={opt.name}
              theme={opt.configuration.theme}
              selected={value.index === index}
              onEdit={() => onEdit(opt)}
              onDelete={() => onDelete(opt)}
            />
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
