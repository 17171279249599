import { Card, CardContent, Typography } from "@mui/material";
import styles from "./Brick.module.scss";

type BrickProps = {
  onClick: (templateId) => any;
  isActive: boolean;
  titleAttr?: string;
  label: string;
  isSharedObject?: boolean;
  disabled?: boolean;
};

export function Brick({
  onClick,
  label,
  titleAttr,
  isActive,
  isSharedObject = false,
  disabled,
}: BrickProps) {
  const isDisabled = disabled ?? false;

  return (
    <Card
      onClick={onClick}
      className={`${styles.listItemCard} ${isActive ? styles.cardActive : ""} ${
        isDisabled ? styles.disabled : ""
      }`}
      title={titleAttr}
    >
      <CardContent className={styles.listItemCardContent}>
        {isSharedObject && (
          <span style={{ marginRight: "5px" }}>
            <span className="sharedObjectIndicator"></span>
          </span>
        )}
        <Typography>
          <strong
            style={{
              minWidth: 0,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {label}
          </strong>
        </Typography>
      </CardContent>
    </Card>
  );
}
