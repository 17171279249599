import { useCallback, useEffect, useMemo, useState } from "react";
import { useTaxonomyByType } from "../../../../../../../../../../../hooks/useTaxonomyByType";
import {
  ScreenerPanelProps,
  TaxonomyFieldRow,
  TaxonomyFieldRowProps,
} from "./ScreenerPanel";
import { deepClone } from "../../../../../../../../../../../deepClone";
import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import styles from "./ScreenerPanel.module.scss";
import { v4 as uuidv4 } from "uuid";

const marketCapSelectValue = [
  { value: 1, label: "Highest Mkt. Cap." },
  { value: 2, label: "Lowest Mkt. Cap." },
];
const ScreenerStock = ({
  actualInvUniverse,
  setScreening,
  setIsDisabled,
  enableEligibility = true,
}: Pick<
  ScreenerPanelProps,
  "actualInvUniverse" | "setScreening" | "setIsDisabled" | "enableEligibility"
>) => {
  const initStateConfiguration =
    (actualInvUniverse?.instrumentType ?? null) === "stock"
      ? actualInvUniverse
      : null;
  const {
    rootNodeX,
    taxonomiesMapX,
    taxonomiesMapY,
    rootNodeY,
    advancedNodesSelector,
  } = useTaxonomyByType("stock");

  const validLevels = useMemo(() => ["1 Industry", "3 Sector"], []);
  const initialMarketsValue =
    initStateConfiguration && initStateConfiguration.whereSource.market.length
      ? initStateConfiguration.whereSource.market
      : [rootNodeX];
  const initialSectorsValue =
    initStateConfiguration && initStateConfiguration.what.length
      ? initStateConfiguration.what
      : [];
  const [selectedMarkets, setSelectedMarkets] = useState(initialMarketsValue);
  const [selectedSectors, setSelectedSectors] = useState(initialSectorsValue);
  const [marketCapOrder, setMarketCapOrder] = useState(
    (initStateConfiguration?.eligibility?.sortBy ?? "desc") === "desc" ? 1 : 2
  );
  const [eligibility, setEligibility] = useState(
    initStateConfiguration?.["eligibility"]?.["cardinality"] ?? 200
  );
  const [isEligibilityValid, setIsEligibilityValid] = useState(true);
  const [domestic, setDomestic] = useState(
    initStateConfiguration?.whereSource?.domestic ?? false
  );
  const [foreign, setForeign] = useState(
    initStateConfiguration?.whereSource?.foreign ?? false
  );
  const [stockClass, setStockClass] = useState(
    initStateConfiguration?.whereSource?.stockClassification ?? []
  );

  useEffect(() => {
    setIsEligibilityValid(eligibility >= 1 && eligibility <= 5000);
  }, [eligibility]);

  const invalidEligibilityMessage = useMemo(() => {
    let message = "";

    if (!isEligibilityValid) {
      if (eligibility < 1) {
        message = "Min 1";
      } else if (eligibility > 5000) {
        message = "Max 5000";
      }
    }

    return message;
  }, [eligibility, isEligibilityValid]);

  const handleMarketsSelection = useCallback(
    (selectedValues) => {
      setIsDisabled(false);
      const marketsSegments = selectedValues.find(
        (item) => item.value.dimension === "country"
      );
      const domicileSegments = selectedValues.find(
        (item) => item.value.dimension === "subtype"
      );
      const stockClassSegments = selectedValues.find(
        (item) => item.value.dimension === "stockclass"
      );

      if (domicileSegments) {
        if (domicileSegments.value.segments[0] === "Foreign Stock") {
          setForeign(true);
        } else {
          setDomestic(true);
        }
      } else {
        setDomestic(false);
        setForeign(false);
      }

      if (stockClassSegments != null) {
        setStockClass(stockClassSegments.value.segments);
      } else {
        setStockClass([]);
      }

      setSelectedMarkets(
        advancedNodesSelector(marketsSegments?.value?.segments, "country", true)
      );
    },
    [advancedNodesSelector, setIsDisabled]
  );

  const handleSectorsSelection = useCallback(
    (selectedValues) => {
      setIsDisabled(false);
      setSelectedSectors(
        advancedNodesSelector(
          selectedValues[0]?.value?.segments,
          "icb",
          true,
          validLevels
        )
      );
    },
    [advancedNodesSelector, setIsDisabled, validLevels]
  );

  const icbTaxonomyPanelConfig: TaxonomyFieldRowProps["columnsDefinition"] =
    useMemo(
      () => [
        [{ taxonomyId: "20" }, { taxonomyId: "60" }],
        [{ taxonomyId: "25" }, { taxonomyId: "50" }],
        [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
        [{ taxonomyId: "40" }, { taxonomyId: "45" }],
        [{ taxonomyId: "55" }, { taxonomyId: "30" }],
      ],
      []
    );

  // Sync the strategy object
  useEffect(() => {
    if (initStateConfiguration) {
      const invUniverseScreening = deepClone(initStateConfiguration);
      if ("eligibility" in invUniverseScreening) {
        invUniverseScreening.eligibility!.cardinality = eligibility;
        invUniverseScreening.eligibility!.sortBy =
          marketCapOrder === 1 ? "desc" : "asc";
      }
      invUniverseScreening["instrumentType"] = "stock";
      invUniverseScreening["what"] =
        selectedSectors.length === 1 && selectedSectors[0] === rootNodeY
          ? []
          : selectedSectors;
      invUniverseScreening["whereSource"]["domestic"] = domestic;
      invUniverseScreening["whereSource"]["foreign"] = foreign;
      invUniverseScreening["whereSource"]["market"] =
        selectedMarkets.length === 1 && selectedMarkets[0] === rootNodeX
          ? []
          : selectedMarkets;
      invUniverseScreening["whereSource"]["stockClassification"] = stockClass;

      setScreening({
        type: "SET_SCREENING",
        payload: invUniverseScreening as any,
      });
    } else {
      const universe: any = {
        eligibility: {
          cardinality: null,
          isEnabled: true,
          sortBy: "desc",
        },
        instrumentType: "stock",
        what: [],
        whereSource: {
          domestic: false,
          foreign: false,
          market: [],
          stockClassification: [],
        },
        whereTarget: {
          domestic: false,
          foreign: false,
          market: [],
          stockClassification: [],
        },
      };

      universe["eligibility"]["cardinality"] = eligibility;
      universe["eligibility"]["sortBy"] = marketCapOrder === 1 ? "desc" : "asc";
      universe["instrumentType"] = "stock";
      universe["what"] =
        selectedSectors.length === 1 && selectedSectors[0] === rootNodeY
          ? []
          : selectedSectors;
      universe["whereSource"]["domestic"] = domestic;
      universe["whereSource"]["foreign"] = foreign;
      universe["whereSource"]["market"] =
        selectedMarkets.length === 1 && selectedMarkets[0] === rootNodeX
          ? []
          : selectedMarkets;
      universe["whereSource"]["stockClassification"] = stockClass;

      setScreening({
        type: "SET_SCREENING",
        payload: universe as any,
      });
    }
  }, [
    initStateConfiguration,
    domestic,
    eligibility,
    foreign,
    marketCapOrder,
    selectedMarkets,
    selectedSectors,
    setScreening,
    stockClass,
    rootNodeY,
    rootNodeX,
  ]);

  return (
    <div className={styles.screener__panel__content}>
      <Box display={"flex"} gap={2} flex={1}>
        <TaxonomyFieldRow
          // label={"Market"}
          handleValuesSelection={handleMarketsSelection}
          taxonomyMap={taxonomiesMapX}
          selectedValues={selectedMarkets}
          taxonomyType={"security"}
          taxonomyField={"country"}
          hasDomicileFilters={true}
          initDomicileFilters={{
            domestic,
            foreign,
            type: stockClass,
          }}
        />

        <TaxonomyFieldRow
          // label={"Sectors"}
          handleValuesSelection={handleSectorsSelection}
          taxonomyMap={taxonomiesMapY}
          taxonomyType={"security"}
          taxonomyField={"icb"}
          selectedValues={selectedSectors}
          columnsDefinition={icbTaxonomyPanelConfig}
          validTreeNodes={validLevels}
        />
      </Box>
      {enableEligibility === true && (
        <Box
          className={styles.outlined__box}
          component={"fieldset"}
          display={"flex"}
          flex={1}
          mt={1}
        >
          <legend className={styles.outlined__box__label}>Eligibility</legend>
          <Box className={styles.outlined__box__content}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography sx={{ fontSize: "0.8vw", marginRight: "5px" }}>
                {marketCapOrder === 1 ? "Top" : "Bottom"}
              </Typography>
              <TextField
                size="small"
                id="outlined-number"
                type="number"
                value={eligibility}
                onChange={(e) => {
                  setIsDisabled(false);
                  setEligibility(parseInt(e?.target?.value ?? 1));
                }}
                error={!isEligibilityValid}
                helperText={invalidEligibilityMessage}
                sx={{
                  maxWidth: "150px",
                  marginRight: "10px",
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 5000,
                    style: {
                      fontSize: "0.8vw",
                    },
                  },
                }}
              />
            </Box>
            <div>
              <Select
                labelId="market-cap-order"
                id="market-cap-order"
                sx={{ fontSize: "0.8vw!important" }}
                value={marketCapOrder}
                onChange={(e) => {
                  setIsDisabled(false);
                  setMarketCapOrder(e.target.value as number);
                }}
                size={"small"}
              >
                {marketCapSelectValue.map((item) => (
                  <MenuItem
                    sx={{ fontSize: "0.8vw!important" }}
                    key={uuidv4()}
                    value={item.value}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ScreenerStock;
