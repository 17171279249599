type PerformanceProps = {
    data: any;
    children?: React.ReactChild;
};

export function Performance({ data, children }: PerformanceProps) {
    return (
        <>
            <div className="tAnalysisOverview-layout">
                {data.upgrades && data.downgrades ? (
                    <>
                        <div className="tAnalysisOverview-box tAnalysisOverview-box--downgradeUpgrade tAnalysisOverview-box--49">
                            <span className="format-number format-number--negative">
                                &#9660;
                            </span>
                            {""}
                            Down
                            <span className="tAnalysisOverview-downgradeUpgradeValue performance-value">
                                {""}
                                {data?.downgrades ?? ""}
                            </span>
                        </div>
                        <div className="tAnalysisOverview-box tAnalysisOverview-box--downgradeUpgrade tAnalysisOverview-box--49">
                            <span className="format-number format-number--positive">
                                &#9650;
                            </span>
                            {""}
                            Up
                            <span className="tAnalysisOverview-downgradeUpgradeValue performance-value">
                                {""}
                                {data?.upgrades ?? ""}
                            </span>
                        </div>
                    </>
                ) : (
                    <p>Data are Loading...</p>
                )}
            </div>
            {children}
        </>
    );
}
