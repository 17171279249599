import { useCallback } from "react";
import styles from "../Alternatives.module.scss";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";

type SwapDrawDownProps = {
  list: any[];
  deleteHandler?: Function;
};

export function SwapDrawDown({ list, deleteHandler }: SwapDrawDownProps) {
  const format = useFormatter();
  const fromRcToClassName = useCallback((rc) => {
    switch (rc) {
      case 2:
        return "A";
      case 1:
        return "B";
      case -1:
        return "C";
      case -2:
        return "D";

      default:
        return "U";
    }
  }, []);

  return (
    <div
      className={
        styles.dialog__swap__list__content__column__content__row__panel
      }
    >
      <ul
        className={
          styles[
            `dialog__swap__list__content__column__content__row__panel__list`
          ]
        }
      >
        {list?.map((listItem, index) => (
          <li key={`${index}__${listItem?.symbol}`}>
            <div
              title={`${listItem?.name} ${listItem?.ticker}`}
              className={
                styles[
                  `dialog__swap__list__content__column__content__row__panel__list__item__${fromRcToClassName(
                    listItem.rc
                  )}`
                ]
              }
            >
              <span>
                <strong>{listItem?.ticker}</strong>
              </span>
              <p>{listItem?.name}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: format.custom("rating", {
                    options: {
                      notAvailable: {
                        input: 0,
                        output: "-",
                      },
                    },
                    output: "HTML",
                    value: listItem?.["rc"],
                    valueHelper: {
                      rateChange: listItem?.["lr"],
                      rateDate: listItem?.["dr"],
                      ratePrev: listItem?.["rrr"],
                    },
                  }),
                }}
              ></p>
            </div>
            {deleteHandler != null && (
              <span
                onClick={() => (deleteHandler ? deleteHandler(listItem) : null)}
                className="i-delete"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
