import { Box, Skeleton, Stack, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Environment } from "../../../../../Environment";
import { Lists } from "../../../../../api/compute/Lists";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { ListViewerTileItemAlerts } from "./ListViewerTileItemAlerts";
import { ListViewerTileItemAllocation } from "./ListViewerTileItemAllocation";
import { ListViewerTileItemExposure } from "./ListViewerTileItemExposure";
import { ListViewerTileItemMenu } from "./ListViewerTileItemMenu";

type ListViewerTileItemProps = {
  list: any;
  onItemClick: (value) => void;
  timeframe: string;
  environment: Environment;
};

export const ListViewerTileItem = forwardRef(
  (
    { list, onItemClick, timeframe, environment }: ListViewerTileItemProps,
    ref: any
  ) => {
    const [value, setValue] = useState(list);
    const nodeRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const { dispatch } = useEventBus();

    useImperativeHandle(ref, () => {
      return {
        loadListData: (listData) => {
          if (listData) {
            setValue(listData);
            setIsLoading(false);
          } else {
            setIsEmpty(true);
          }
        },
        getNode: () => nodeRef.current,
      };
    });

    const preferences = useMemo(
      () => environment.get("preferences"),
      [environment]
    );

    const exposurePreference = useMemo(
      () => preferences.get("analysisList")["exposure"],
      [preferences]
    );

    const onClickMenuItem = useCallback(
      (event) => {
        const data = deepClone(event["value"]);
        data["list"] = value;
        dispatch("menu-click", data);
      },
      [dispatch, value]
    );

    return !isEmpty ? (
      <div
        onClick={() => onItemClick({ value })}
        className="tListViewerTileItem"
        data-list-id={value.id}
        ref={nodeRef}
      >
        {isLoading ? (
          <Stack spacing={1}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: "20px",
                marginBottom: "20px",
              }}
            />

            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
          </Stack>
        ) : (
          <>
            {value != null && (
              <>
                <h1 className="tListViewerTileItem-name">
                  {value.name}
                  <div
                    className={`sharedObjectIndicator ${
                      !value.isReadOnly ? "hide" : ""
                    }`}
                    data-dojo-attach-point="nodeReadOnlyIcon"
                  ></div>
                  <div className="tListViewerTileItem-menu">
                    <ListViewerTileItemMenu
                      onOptionClickHandler={onClickMenuItem}
                      optionType={value.type.toLowerCase()}
                      isReadOnly={value.isReadOnly}
                      environment={environment}
                    />
                  </div>
                </h1>
                <div data-dojo-attach-point="nodeContent">
                  <div className="tLayout tListViewerTileItem-layout">
                    <div className="tLayout--width-25-spaced">
                      <div className="tListViewerTileItem-exposure">
                        <ListViewerTileItemExposure
                          source={value}
                          viewType={exposurePreference["ratings"]}
                          timeframe={timeframe}
                        />
                      </div>
                    </div>
                    <div className="tLayout--width-75-spaced">
                      <div className="tListViewerTileItem-allocation">
                        <div>
                          <ListViewerTileItemAllocation
                            value={value["pieChartData"]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tListViewerTileItem-alerts">
                    <div>
                      <ListViewerTileItemAlerts
                        timeframe={timeframe}
                        value={value.alerts.length ? value.alerts : null}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    ) : (
      <EmptyPortfolio ref={nodeRef} portfolioId={value.id} />
    );
  }
);

const EmptyPortfolio = forwardRef<any, any>(({ portfolioId }, nodeRef) => {
  const [portfolioName, setPortfolioName] = useState("");
  const environment = useEnvironment();

  const listsAPI = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );

  const getName = useCallback(async () => {
    try {
      const response = await listsAPI.portfolioFetch([portfolioId], ["name"]);
      const name = response?.[0]?.name;

      setPortfolioName(name);
    } catch (error) {
      setPortfolioName(`The portfolio with id: ${portfolioId}`);
    }
  }, [listsAPI, portfolioId]);

  const onComponentMount = useCallback(() => {
    getName();
  }, [getName]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return (
    <div
      className="tListViewerTileItem"
      data-list-id={portfolioId}
      ref={nodeRef}
    >
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Typography>
          <strong style={{ fontSize: "1.2em" }}>
            {portfolioName} is empty
          </strong>
        </Typography>
      </Box>
    </div>
  );
});
