export class DataPreparation {
  /**
   * Filters by the given property and value
   *
   * @param {string} property
   * @param {number|string} value
   *
   * @returns {function} the function to be used as filter
   */
  filterBy = (property, value) => {
    return function (item) {
      if (item[property] === value) {
        return true;
      }

      return false;
    };
  };

  /**
   * Sort by the given property in a case insensitive way
   *
   * @param {string} type
   *
   * @returns {function} the function to be used as sort
   */
  sortByCaseInsensitive = (property) => {
    return function (a, b) {
      var valueA = String(a[property]).toLowerCase();
      var valueB = String(b[property]).toLowerCase();

      if (valueA > valueB) {
        return 1;
      }

      if (valueA < valueB) {
        return -1;
      }

      return 0;
    };
  };

  strategyUiTypeToServerType = (strategyType) => {
    switch (strategyType) {
      case "alpha": {
        return "ALPHA";
      }
      case "advanced": {
        return "BUILDER";
      }
      case "combined": {
        // not used in buider
        // they have their own ad-hoc page
        return "COMBINED_STRATEGY";
      }
      case "macroRotation": {
        return "SECTOR_ROTATION";
      }
      case "smartBeta": {
        return "SMART_BETA";
      }
      default: {
        return "UNKNOWN_STRATEGY_TYPE";
      }
    }
  };

  /**
   * Return the strategy type according to SEO uri conventions and best
   * practices
   *
   * https://developers.google.com/search/docs/advanced/guidelines/url-structure
   *
   * @param {string} strategyType
   *
   * @returns {string}
   */
  strategyTypeToUriFragment = (strategyType) => {
    switch (strategyType) {
      case "ALPHA":
      case "alpha": {
        return "alpha";
      }
      case "BUILDER":
      case "advanced": {
        return "advanced";
      }
      case "COMBINED_STRATEGY":
      case "combined": {
        // not used in buider
        // they have their own ad-hoc page
        return "combined";
      }
      case "SECTOR_ROTATION":
      case "macroRotation": {
        return "macro-rotation";
      }
      case "SMART_BETA":
      case "smartBeta": {
        return "smart-beta";
      }
      default: {
        return "unknown-strategy-type";
      }
    }
  };

  /**
   * Return the UI strategy type for the given URI fragment
   *
   * @param {string} uriFragment
   *
   * @returns {string}
   */
  uriFragmentToStrategyType = (uriFragment) => {
    switch (uriFragment) {
      case "alpha": {
        return "alpha";
      }
      case "advanced": {
        return "advanced";
      }
      case "combined": {
        // not used in buider
        // they have their own ad-hoc page
        return "combined";
      }
      case "macro-rotation": {
        return "macroRotation";
      }
      case "smart-beta": {
        return "smartBeta";
      }
      default: {
        return "unknownStrategyType";
      }
    }
  };
}
