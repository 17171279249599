import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import React, { useMemo } from "react";
import DatePicker from "../advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";

type Props = {
  type: "DAY" | "YEAR";
  value: any;
  dispatcher: Function;
};

const yearsValueMap = {
  5: "5 years",
  6: "6 years",
  7: "7 years",
  8: "8 years",
  10: "10 years",
  12: "12 years",
  15: "15 years",
};

export default function SelectHistory({ type, value, dispatcher }: Props) {
  const widget = useMemo(() => {
    if (type === "YEAR") {
      return (
        <Select
          size="small"
          value={value ?? "-"}
          onChange={(e) => {
            dispatcher({
              type: "SET_HISTORY",
              payload: { type: "YEAR", value: parseInt(e.target.value) },
            });
          }}
        >
          <MenuItem disabled value={"-"}>
            <Typography>select years</Typography>
          </MenuItem>
          {Object.entries(yearsValueMap).map((item, index) => (
            <MenuItem key={index} value={item[0]}>
              <Typography>{item[1]}</Typography>
            </MenuItem>
          ))}
        </Select>
      );
    }

    if (type === "DAY") {
      return (
        <DatePicker
          input={value}
          onChangeDate={(e) => {
            dispatcher({
              type: "SET_HISTORY",
              payload: { type: "DAY", value: e },
            });
          }}
        />
      );
    }
  }, [dispatcher, type, value]);
  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <Box>{widget}</Box>
      <Box>
        <Button
          onClick={() => {
            if (type === "DAY") {
              dispatcher({
                type: "SET_HISTORY",
                payload: { type: "YEAR", value: null },
              });
            }
            if (type === "YEAR") {
              dispatcher({
                type: "SET_HISTORY",
                payload: { type: "DAY", value: null },
              });
            }
          }}
        >
          <span className="i-calendar"></span>
        </Button>
      </Box>
    </Box>
  );
}
