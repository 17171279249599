/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportSystematicPortfolios
 * @summary Manages systematic portfolios report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportAlerts extends _TemplateReport {
  storedObjectType = "TEMPLATE_REPORT_ALERTS" as const;
}
