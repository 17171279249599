export const defaultTemplateAvoidableLossReport = {
  configuration: {
    integration: {
      portfolioAnalysis: null,
      screening: null,
      strategy: {
        date: null,
      },
      systematicProduct: null,
    },
    orientation: "portrait",
    headerConfig: {
      logo: "small",
      date: true,
    },
    sections: [
      {
        type: "REPORT_COMMON_TITLE",
        content: {
          // eslint-disable-next-line no-template-curly-in-string
          text: "${name}",
        },
        presentation: null,
        sections: null,
        isEdited: false,
      },
      {
        type: "REPORT_COMMON_SECURITY_TABLE",
        content: {
          headline: {
            content: "Holdings",
            isEnabled: true,
          },
          sortBy: {
            property: "weight",
            descending: true,
          },
          top: {
            content: 5,
            isEnabled: false,
          },
        },
        presentation: {
          columns: [
            {
              label: "Weight",
              property: "weight",
            },
            {
              label: "Ticker",
              property: "ticker",
            },
            {
              label: "Name",
              property: "name",
            },
            {
              label: "Price",
              property: "vc",
            },
            {
              label: "Rating",
              property: "rc",
            },
            {
              label: "Rated on",
              property: "dr",
            },
            {
              label: "Market",
              property: "country",
            },
            {
              label: "Sector",
              property: "industry",
            },
          ],
          rank: false,
        },
        sections: null,
        isEdited: false,
      },
      {
        type: "REPORT_COMMON_SECURITY_TABLE",
        content: {
          headline: {
            content: "Avoidable Losses",
            isEnabled: true,
          },
          sortBy: {
            property: "weight",
            descending: true,
          },
          top: {
            content: 5,
            isEnabled: false,
          },
        },
        presentation: {
          columns: [
            {
              label: "Weight",
              property: "weight",
            },
            {
              label: "Ticker",
              property: "ticker",
            },
            {
              label: "Name",
              property: "name",
            },
            {
              label: "Price",
              property: "vc",
            },
            {
              label: "Rating",
              property: "rc",
            },
            {
              label: "Rated on",
              property: "dr",
            },
            {
              label: "Market",
              property: "country",
            },
            {
              label: "Sector",
              property: "industry",
            },
          ],
          rank: false,
        },
        sections: null,
        isEdited: false,
      },
      {
        type: "REPORT_COMMON_SECURITY_TABLE",
        content: {
          headline: {
            content: "",
            isEnabled: true,
          },
          sortBy: {
            property: "weight",
            descending: true,
          },
          top: {
            content: 5,
            isEnabled: false,
          },
        },
        presentation: {
          columns: [
            {
              label: "Weight",
              property: "weight",
            },
            {
              label: "Ticker",
              property: "ticker",
            },
            {
              label: "Name",
              property: "name",
            },
            {
              label: "Price",
              property: "vc",
            },
            {
              label: "Rating",
              property: "rc",
            },
            {
              label: "Rated on",
              property: "dr",
            },
            {
              label: "Market",
              property: "country",
            },
            {
              label: "Sector",
              property: "industry",
            },
          ],
          rank: false,
        },
        sections: null,
        isEdited: false,
      },
      {
        type: "REPORT_COMMON_SPACING",
        content: {
          lines: 1,
        },
        presentation: null,
        sections: null,
        isEdited: false,
      },
      {
        type: "REPORT_COMMON_DISCLAIMER",
        content: null,
        presentation: null,
        sections: null,
        isEdited: false,
      },
    ],
    theme: "theme2",
  },
  id: null,
  isEditable: false,
  name: "Avoidable Loss",
  ownerId: null,
  type: "TEMPLATE_REPORT_PORTFOLIO",
  version: "1.0",
};
