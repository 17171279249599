/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Peers
 * @summary Requests for peers
 *
 */

import { TDate } from "../../trendrating/date/TDate";
import { Instrument } from "../../types/Api";
import { endpoints } from "../endpoints";
import { toFloat, toInt } from "../utils";
import { _Base } from "../_Base";
import { extractSymbols } from "./commons";

const OLD_FIELDS_DICT = {
  fact_cardinality: "cardinality",
  fact_downgrades_1: "downgrades",
  fact_downgrades_5: "downgrades_W",
  fact_downgrades_20: "downgrades_M",
  fact_downgrades_60: "downgrades_Q",
  fact_marketcap: "marketcap",
  fact_move_down_20: "CDnewLow",
  fact_move_up_20: "ABnewHigh",
  fact_rate_A: "A",
  fact_rate_B: "B",
  fact_rate_C: "C",
  fact_rate_D: "D",
  fact_rc: "TCR",
  fact_rc_t_1: "TCR_D",
  fact_rc_t_5: "TCR_W",
  fact_rc_t_20: "TCR_M",
  fact_upgrades_1: "upgrades",
  fact_upgrades_5: "upgrades_W",
  fact_upgrades_20: "upgrades_M",
  fact_upgrades_60: "upgrades_Q",
  pd_avg: "pd_avg",
  pq_bottom_avg_25: "pq_bottom_avg_25",
  pq_top_avg_25: "pq_top_avg_25",
  ps_bottom_avg_25: "ps_bottom_avg_25",
  ps_top_avg_25: "ps_top_avg_25",
  py_bottom_avg_25: "py_bottom_avg_25",
  py_top_avg_25: "py_top_avg_25",
  rate_1: "B_%",
  rate_2: "A_%",
  "rate_-1": "C_%",
  "rate_-2": "D_%",
  ratio_ABChangePerc_1: "ABchange_%",
  ratio_ABChangePerc_5: "ABchange_%_W",
  ratio_ABChangePerc_20: "ABchange_%_M",
  ratio_CDChangePerc_1: "CDchange_%",
  ratio_CDChangePerc_5: "CDchange_%_W",
  ratio_CDChangePerc_20: "CDchange_%_M",
  ratio_downgrades_1_perc: "downgrades_%",
  ratio_downgrades_5_perc: "downgrades_%_W",
  ratio_downgrades_20_perc: "downgrades_%_M",
  ratio_upgrades_1_perc: "upgrades_%",
  ratio_upgrades_5_perc: "upgrades_%_W",
  ratio_upgrades_20_perc: "upgrades_%_M",
};

// const NEW_FIELDS_DICT = {
//    "cardinality": "fact_cardinality",
//   "downgrades":"fact_downgrades_1" ,
//    "downgrades_W": "fact_downgrades_5",
//   "downgrades_M": "fact_downgrades_20",
//   "downgrades_Q": "fact_downgrades_60" ,
//   "marketcap": "fact_marketcap",
//   "CDnewLow": "fact_move_down_20",
//   "ABnewHigh" : "fact_move_up_20",
//   "A": fact_rate_A,
//   fact_rate_B: "B",
//   fact_rate_C: "C",
//   fact_rate_D: "D",
//   fact_rc: "TCR",
//   fact_rc_t_1: "TCR_D",
//   fact_rc_t_5: "TCR_W",
//   fact_rc_t_20: "TCR_M",
//   fact_upgrades_1: "upgrades",
//   fact_upgrades_5: "upgrades_W",
//   fact_upgrades_20: "upgrades_M",
//   fact_upgrades_60: "upgrades_Q",
//   pd_avg: "pd_avg",
//   pq_bottom_avg_25: "pq_bottom_avg_25",
//   pq_top_avg_25: "pq_top_avg_25",
//   ps_bottom_avg_25: "ps_bottom_avg_25",
//   ps_top_avg_25: "ps_top_avg_25",
//   py_bottom_avg_25: "py_bottom_avg_25",
//   py_top_avg_25: "py_top_avg_25",
//   rate_1: "B_%",
//   rate_2: "A_%",
//   "rate_-1": "C_%",
//   "rate_-2": "D_%",
//   ratio_ABChangePerc_1: "ABchange_%",
//   ratio_ABChangePerc_5: "ABchange_%_W",
//   ratio_ABChangePerc_20: "ABchange_%_M",
//   ratio_CDChangePerc_1: "CDchange_%",
//   ratio_CDChangePerc_5: "CDchange_%_W",
//   ratio_CDChangePerc_20: "CDchange_%_M",
//   ratio_downgrades_1_perc: "downgrades_%",
//   ratio_downgrades_5_perc: "downgrades_%_W",
//   ratio_downgrades_20_perc: "downgrades_%_M",
//   ratio_upgrades_1_perc: "upgrades_%",
//   ratio_upgrades_5_perc: "upgrades_%_W",
//   ratio_upgrades_20_perc: "upgrades_%_M",
// };

export class Peers extends _Base {
  // App API     | Compute API
  // ------------|------------
  // peer        | peer
  // peerHistory | peerhistory
  static apiType = {
    commodity: "Commodity",
    currency: "Currency",
    etf: "ETF",
    index: "Index",
    peer: "peer",
    peerHistory: "peerhistory",
    peerMetric: "peermetric",
    sector: "Sector",
    stock: "Stock",
  } as const;

  decode(peer: any) {
    const peerType = peer.type === "ETF" ? "ETF" : "security";
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";

    function alertsRatio(upgrades: any, downgrades: any) {
      if (upgrades === 0 && downgrades === 0) {
        return -500;
      } else if (downgrades === 0) {
        return upgrades * 1000;
      } else if (upgrades === 0) {
        return -1000 * downgrades;
      }
      return upgrades / downgrades;
    }

    const abPercentageA = toFloat(peer, OLD_FIELDS_DICT["rate_2"]);
    const abPercentageB = toFloat(peer, OLD_FIELDS_DICT["rate_1"]);
    const cdPercentageC = toFloat(peer, OLD_FIELDS_DICT["rate_-1"]);
    const cdPercentageD = toFloat(peer, OLD_FIELDS_DICT["rate_-2"]);
    let abPercentageToday: any = null;
    let cdPercentageToday: any = null;
    if (abPercentageA != null && abPercentageB != null) {
      abPercentageToday = abPercentageA + abPercentageB;
    }
    if (cdPercentageC != null && cdPercentageD != null) {
      cdPercentageToday = cdPercentageC + cdPercentageD;
    }

    var _peer = {
      alerts: {
        last3Months: {
          downgrades: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_60"]),
          ratio: alertsRatio(
            toFloat(peer, OLD_FIELDS_DICT["fact_upgrades_60"]),
            toFloat(peer, OLD_FIELDS_DICT["fact_downgrades_60"])
          ),
          upgrades: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_60"]),
        },
        lastMonth: {
          downgrades: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_20"]),
          ratio: alertsRatio(
            toFloat(peer, OLD_FIELDS_DICT["fact_upgrades_20"]),
            toFloat(peer, OLD_FIELDS_DICT["fact_downgrades_20"])
          ),
          upgrades: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_20"]),
        },
        lastWeek: {
          downgrades: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_5"]),
          ratio: alertsRatio(
            toFloat(peer, OLD_FIELDS_DICT["fact_upgrades_5"]),
            toFloat(peer, OLD_FIELDS_DICT["fact_downgrades_5"])
          ),
          upgrades: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_5"]),
        },
        today: {
          downgrades: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_1"]),
          ratio: alertsRatio(
            toFloat(peer, OLD_FIELDS_DICT["fact_upgrades_1"]),
            toFloat(peer, OLD_FIELDS_DICT["fact_downgrades_1"])
          ),
          upgrades: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_1"]),
        },
      },
      id: [
        peer[fieldX],
        peer[fieldY],
        peer["type"],
        peer?.["zDimension"] ?? "microLarge",
      ].join("-"),
      info: {
        cardinality: toInt(peer, OLD_FIELDS_DICT["fact_cardinality"]),
        marketcap: toFloat(peer, OLD_FIELDS_DICT["fact_marketcap"]),
      },
      name: null,
      size: peer["zDimension"],
      statistic: {
        dispersion: {
          pq: {
            top: toFloat(peer, OLD_FIELDS_DICT["pq_top_avg_25"]),
            bottom: toFloat(peer, OLD_FIELDS_DICT["pq_bottom_avg_25"]),
          },
          ps: {
            top: toFloat(peer, OLD_FIELDS_DICT["ps_top_avg_25"]),
            bottom: toFloat(peer, OLD_FIELDS_DICT["ps_bottom_avg_25"]),
          },
          py: {
            top: toFloat(peer, OLD_FIELDS_DICT["py_top_avg_25"]),
            bottom: toFloat(peer, OLD_FIELDS_DICT["py_bottom_avg_25"]),
          },
        },
        abPercentage: {
          today: abPercentageToday,
          yesterday: toFloat(peer, OLD_FIELDS_DICT["ratio_ABChangePerc_1"]),
          lastWeek: toFloat(peer, OLD_FIELDS_DICT["ratio_ABChangePerc_5"]),
          lastMonth: toFloat(peer, OLD_FIELDS_DICT["ratio_ABChangePerc_20"]),
        },
        cdPercentage: {
          today: cdPercentageToday,
          yesterday: toFloat(peer, OLD_FIELDS_DICT["ratio_CDChangePerc_1"]),
          lastWeek: toFloat(peer, OLD_FIELDS_DICT["ratio_CDChangePerc_5"]),
          lastMonth: toFloat(peer, OLD_FIELDS_DICT["ratio_CDChangePerc_20"]),
        },
        downgrades: {
          today: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_1"]),
            percentage: toFloat(
              peer,
              OLD_FIELDS_DICT["ratio_downgrades_1_perc"]
            ),
          },
          yesterday: {
            number: null,
            percentage: null,
          },
          lastWeek: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_5"]),
            percentage: toFloat(
              peer,
              OLD_FIELDS_DICT["ratio_downgrades_5_perc"]
            ),
          },
          lastMonth: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_downgrades_20"]),
            percentage: toFloat(
              peer,
              OLD_FIELDS_DICT["ratio_downgrades_20_perc"]
            ),
          },
        },
        ratings: {
          // used in analysisInstrument
          today: {
            A: {
              number: toInt(peer, OLD_FIELDS_DICT["fact_rate_A"], null),
              percentage: toFloat(peer, OLD_FIELDS_DICT["rate_2"]),
            },
            B: {
              number: toInt(peer, OLD_FIELDS_DICT["fact_rate_B"], null),
              percentage: toFloat(peer, OLD_FIELDS_DICT["rate_1"]),
            },
            C: {
              number: toInt(peer, OLD_FIELDS_DICT["fact_rate_C"], null),
              percentage: toFloat(peer, OLD_FIELDS_DICT["rate_-1"]),
            },
            D: {
              number: toInt(peer, OLD_FIELDS_DICT["fact_rate_D"], null),
              percentage: toFloat(peer, OLD_FIELDS_DICT["rate_-2"]),
            },
          },
          yesterday: null,
          lastWeek: null,
          lastMonth: null,
        },
        quantiles: {
          pm: {
            q0: toFloat(peer, "quantile_pm_0"),
            q1: toFloat(peer, "quantile_pm_1"),
            q2: toFloat(peer, "quantile_pm_2"),
            q3: toFloat(peer, "quantile_pm_3"),
            q4: toFloat(peer, "quantile_pm_4"),
          },
          pq: {
            q0: toFloat(peer, "quantile_pq_0"),
            q1: toFloat(peer, "quantile_pq_1"),
            q2: toFloat(peer, "quantile_pq_2"),
            q3: toFloat(peer, "quantile_pq_3"),
            q4: toFloat(peer, "quantile_pq_4"),
          },
          py: {
            q0: toFloat(peer, "quantile_py_0"),
            q1: toFloat(peer, "quantile_py_1"),
            q2: toFloat(peer, "quantile_py_2"),
            q3: toFloat(peer, "quantile_py_3"),
            q4: toFloat(peer, "quantile_py_4"),
          },
          pw: {
            q0: toFloat(peer, "quantile_pw_0"),
            q1: toFloat(peer, "quantile_pw_1"),
            q2: toFloat(peer, "quantile_pw_2"),
            q3: toFloat(peer, "quantile_pw_3"),
            q4: toFloat(peer, "quantile_pw_4"),
          },
        },
        upgrades: {
          today: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_1"]),
            percentage: toFloat(peer, OLD_FIELDS_DICT["ratio_upgrades_1_perc"]),
          },
          yesterday: {
            number: null,
            percentage: null,
          },
          lastWeek: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_5"]),
            percentage: toFloat(peer, OLD_FIELDS_DICT["ratio_upgrades_5_perc"]),
          },
          lastMonth: {
            number: toInt(peer, OLD_FIELDS_DICT["fact_upgrades_20"]),
            percentage: toFloat(
              peer,
              OLD_FIELDS_DICT["ratio_upgrades_20_perc"]
            ),
          },
        },
      },
      tcr: {
        today: toInt(peer, OLD_FIELDS_DICT["fact_rc"], null),
        yesterday: toInt(peer, OLD_FIELDS_DICT["fact_rc_t_1"], null),
        lastMonth: toInt(peer, OLD_FIELDS_DICT["fact_rc_t_20"], null),
        lastWeek: toInt(peer, OLD_FIELDS_DICT["fact_rc_t_5"], null),
      },
      type: peer["type"],
      what: null,
      where: null,
    };

    _peer["what"] = peer[fieldY];
    _peer["where"] = peer[fieldX];

    return _peer;
  }
  /**
   * Get dispersion data
   *
   * @param {object}   params
   * @param {string}   params.performanceTimeframe - one of
   *      "1_week", "1_month", "3_months", "6_months", "12_months".
   *      Default is "12_months"
   * @param {number}   params.intervals - number of quantiles.
   *      Default is 4
   * @param {object[]} params.instruments
   * @param {string}   params.instruments[].symbol
   * @param {boolean}   params.trimOutliers - trim outliers
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async dispersion(params: any) {
    var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    var url = endPointRoot + endpoints.peers.dispersions;

    var paramsEncoded: any = {
      dimension: null,
      n: params["intervals"] == null ? 4 : params["intervals"],
      symbols: [],
      trimOutliers: params.trimOutliers ?? false,
    };

    switch (params["performanceTimeframe"]) {
      case "1_week": {
        paramsEncoded["dimension"] = "pw";

        break;
      }
      case "1_month": {
        paramsEncoded["dimension"] = "pm";

        break;
      }
      case "3_months": {
        paramsEncoded["dimension"] = "pq";

        break;
      }
      case "6_months": {
        paramsEncoded["dimension"] = "ps";

        break;
      }
      case "12_months":
      default: {
        paramsEncoded["dimension"] = "py";

        break;
      }
    }

    paramsEncoded["symbols"] = extractSymbols(params["instruments"]);

    const response = await this.preparePost(url, paramsEncoded, null);

    return this._decodeDispersion(paramsEncoded, response);
  }

  /**
   * Retrieves peer information
   *
   * @param {object[][]} params - request parameters
   * @param {string}     params[][].zDimension - "micro", "small", "mid", "large" | ETFProviders,
   *      "microSmall", "microMid" ...
   * @param {string}     params[][].type - Commodity, Currency, ETF,
   *      Index, Sector, Stock
   * @param {string}     params[][].what - a GICS id
   * @param {string}     params[][].where - a MarketFinancials id
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */

  async get(
    params: {
      zDimension:
        | "micro"
        | "small"
        | "mid"
        | "large"
        | "microSmall"
        | "microMid"
        | string;
      type: typeof Peers.apiType[keyof typeof Peers.apiType];
      what: string;
      where: string;
    }[][],
    fields?: string[]
  ) {
    var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    var url = endPointRoot + endpoints.peers.details;

    var classType = Peers.apiType["peer"];
    var paramsGet: any = [];
    const _fields: any = [];
    if (fields) {
      for (const field of fields) {
        if (OLD_FIELDS_DICT?.[field]) {
          _fields.push(OLD_FIELDS_DICT[field]);
        }
      }
    } else {
      for (const key in OLD_FIELDS_DICT) {
        _fields.push(OLD_FIELDS_DICT[key]);
      }
    }

    for (let i = 0, lengthI = params.length; i < lengthI; i++) {
      paramsGet.push([]);
      for (let j = 0, lengthJ = params[i].length; j < lengthJ; j++) {
        const instrumentType =
          Peers.apiType[
            params[i][j]["type"].toLowerCase() as keyof typeof Peers.apiType
          ];
        if (instrumentType === "ETF") {
          paramsGet[i][j] = {
            classType: classType,
            etfgeo: params[i][j]["where"],
            etfclass: params[i][j]["what"],
            zDimension: params[i][j]["zDimension"],
            type: instrumentType,
          };

          if (_fields && _fields.length) {
            paramsGet[i][j]["fields"] = _fields;
          }
        } else {
          paramsGet[i][j] = {
            classType: classType,
            country: params[i][j]["where"],
            icb: params[i][j]["what"],
            zDimension: params[i][j]["zDimension"],
            type: instrumentType,
          };

          if (_fields && _fields.length) {
            paramsGet[i][j]["fields"] = _fields;
          }
        }
      }
    }

    const response = await this.preparePost(url, paramsGet, null);

    return this._decodeGet(params, response);
  }

  /**
   * @deprecated
   *
   * Retrieves peer information
   *
   * @param {object}   params - request parameters
   * @param {object[]} params.peers - list of peers to retrieve
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  fetch(params: any) {
    this.deprecated("Peers", "fetch()", "get()");

    return this.get(params);
  }

  /**
   * Retrives peer/s history/ies
   *
   * @param {object[][]} params - request parameters
   * @param {boolean}    raw - do not decode history
   * @param {string[]}   params[][].metrics - array of string metrics to get
   * @param {string}     params[][].size - "micro", "small", "mid", "large",
   *      "microSmall", "microMid" ...
   * @param {string}     params[][].type - Commodity, Currency, ETF,
   *      Index, Sector, Stock
   * @param {string}     params[][].years - how many years, defaults to 20
   * @param {string}     params[][].what - a GICS id
   * @param {string}     params[][].where - a MarketFinancials id
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   *
   */
  async history(
    params: {
      metrics: string[];
      zDimension:
        | "micro"
        | "small"
        | "mid"
        | "large"
        | "microSmall"
        | "microMid";
      type: typeof Peers.apiType[keyof typeof Peers.apiType];
      what: string;
      where: string;
      years: number;
    }[][],
    raw: boolean
  ) {
    var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    var url = endPointRoot + endpoints["peers"]["details"];

    var paramsHistory: any = [];
    for (let i = 0, lengthI = params.length; i < lengthI; i++) {
      paramsHistory.push([]);
      // parseInt(String()) on years is made because the API needs
      // a number
      for (let j = 0, lengthJ = params[i].length; j < lengthJ; j++) {
        const instrumentType =
          Peers.apiType[
            params[i][j]["type"].toLowerCase() as keyof typeof Peers.apiType
          ];
        paramsHistory[i][j] = {
          classType: Peers.apiType["peerMetric"],
          country: params[i][j]["where"],
          icb: params[i][j]["what"],
          metrics: params[i][j].metrics,
          zDimension: params[i][j]["zDimension"],
          type: instrumentType,
          years: parseInt(String(params[i][j]["years"]), 10) || 20,
        };
      }
    }

    const response = await this.preparePost(url, paramsHistory, null);

    if (raw) {
      return response;
    }

    return this._decodeHistory(params, response);
  }

  /**
   * Retrieves the peer of the instrument
   *
   * @param {object} instrument - request parameters
   * @param {string} instrument.symbol - the instrument symbol
   * @param {string} instrument.type - the instrument type
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async ofInstrument(instrument: Instrument) {
    var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    var url = endPointRoot + endpoints["peers"]["ofInstrument"];

    var params = {
      dimensions: ["country", "icb", "zDimension"],
      symbol: instrument["symbol"],
      type: instrument["type"],
    };

    if (instrument.type === "ETF") {
      params = {
        dimensions: ["etfgeo", "etfclass"],
        symbol: instrument["symbol"],
        type: instrument["type"],
      };
    }

    const response = await this.prepareGet(url, params, null);
    return this._ofInstrument(response);
  }

  /**
   * Search for peers
   *
   * @param {object}        params - request parameters
   * @param {object}        params.constraints - search constraints
   * @param {array<string>} params.constraints.instrumentType - types of
   *      peer instruments to search
   * @param {number}        params.page - the page to be retrieved
   * @param {number}        params.perPage - how may items per page
   * @param {string}        params.query - the query of the user
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  search(params: any) {
    var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    var url = endPointRoot + endpoints["peers"]["searches"];

    var paramsSearch = {
      classType: "peer",
      input: params["query"],
      page: params["page"] - 1,
      rows: params["perPage"],
    };

    var request = this.prepareGet(url, paramsSearch, null);

    return request.then(function (response) {
      var _response = {
        data: response["data"]["rows"],
        dataTotalCount: response["data"]["total"],
      };

      return _response;
    });
  }

  /**
   * Search and fetch details for peers
   *
   * @param {object}        params - request parameters
   * @param {object}        params.constraints - search constraints
   * @param {array<string>} params.constraints.instrumentType - types of
   *      peer instruments to search
   * @param {number}        params.page - the page to be retrieved
   * @param {number}        params.perPage - how may items per page
   * @param {string}        params.query - the query of the user
   *
   * @param {string}        objectType - type of object, e.g. peer |
   *      peerHistory
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async searchAndFetch(params: any) {
    const response = await this.search(params);

    var data = response["data"];
    var dataTotalCount = response["dataTotalCount"];

    var paramsFetch: any = [[]];

    for (let i = 0, length = data.length; i < length; i++) {
      const peer = {
        where: data[i]["country"] ? data[i]["country"] : null,
        what: data[i]["icb"] ? data[i]["icb"] : null,
        zDimension: "microLarge",
        type: "Stock",
      };
      paramsFetch[0].push(peer);
    }

    const responseFetch = await this.get(paramsFetch);
    return {
      dataTotalCount: dataTotalCount,
      data: responseFetch,
    };
  }

  /**
   * Replace old peersWeight
   *
   */
  async weightAnalytics(
    holdings: { symbol: string; weight: number }[],
    segment: string | string[],
    additionalAnalytics?: string[]
  ) {
    if (segment != null) {
      const apiCluster = this.environment.http.clusterAnalytics;
      const analytics =
        additionalAnalytics != null
          ? ["weight", "TCR"].concat(additionalAnalytics)
          : ["weight", "TCR"];
      if (typeof segment === "string") {
        return await apiCluster
          .createConfiguration()
          .segment(segment, true)
          .method("INTERSECTION")
          .analytics(analytics)
          .universeFromPositions(holdings)
          .fetchAnalytics();
      } else if (segment.length) {
        const cluster: any = [];

        for (const dim of segment) {
          const clusterObj =
            apiCluster.createConfiguration().segment(dim)?.state
              ?.clusters?.[0] ?? null;

          if (clusterObj != null) {
            cluster.push(clusterObj);
          }
        }

        return await apiCluster
          .createConfiguration()
          .clusters(cluster)
          .method("INTERSECTION")
          .analytics(analytics)
          .universeFromPositions(holdings)
          .fetchAnalytics();
      }
    }

    throw new Error(
      "No cluster or segment were passed to call correctly the cluster service."
    );
  }

  /**
   * Retrieves peer weights
   *
   * @param {object}   params - request parameters
   * @param {object[]} params.constituents - request parameters
   * @param {string}   params.constituents[].symbol - symbol of security
   * @param {number}   params.constituents[].weight - weight of security
   * @param {string}   params.countryLevel - supported values are 'Area' | 'Country' | 'Region'
   * @param {string}   params.metricType - value is 'abHistoryPerc'
   * @param {boolean}  params.normalize - if true it normalizes weights in statistics. Default false
   * @param {string}   params.sectorLevel - supported values are '1 Industry'| '2 Supersector' | '3 Sector' | '4 Subsector'
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  //COMMENTED_OUT TO CHECK IF IS STILL USED
  // weight(params: any) {
  //     var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
  //     var url = endPointRoot + endpoints["peers"]["weights"];
  //     var request = this.preparePost(url, params, null);
  //     return request;
  // }
  // ----------------------------------------------------- private methods
  _decodeDispersion(paramsEncoded: any, response: any) {
    /**
     * Add instrument to an array
     *
     * @param {object[]} source - objects are like
     *      { SYMBOL: DIMENSION_VALUE}
     *      e.g. { AAPL_NMS_USD_908440: 0.27400986 }
     * @param {Array} target - the array where add instrument
     * @param {string} property - defined in paramsEncoded["dimension"]:
     *      one of "pm", "pq", "ps", "py"
     *
     * @ignore
     */
    function addInstrument(source: any, target: any, property: any) {
      var instrument: any = null;

      for (let i = 0, length = source.length; i < length; i++) {
        const itemKey = Object.keys(symbols[i])[0];

        instrument = { symbol: itemKey };
        instrument[property] = symbols[i][itemKey];

        target.push(instrument);
      }
    }

    let error: any = null;
    if ("error" in response) {
      error = this.simulateHttpError(response);

      if (error != null) {
        return error;
      }
    }

    var decoded = {
      bottom: {
        average: 0,
        data: [],
        dataTotalCount: 0,
        distribution: null,
        max: 0,
        min: 0,
      },
      intervals: paramsEncoded["n"],
      middle: {
        average: 0,
        data: [],
        dataTotalCount: 0,
        distribution: null,
        max: 0,
        min: 0,
      },
      quantiles: null, // used in instrument analysis
      top: {
        average: 0,
        data: [],
        dataTotalCount: 0,
        distribution: null,
        max: 0,
        min: 0,
      },
    };

    // read population
    // this field is always available, used in case the symbols aren't
    // enough to calculate the data.
    if ("population" in response["data"]) {
      decoded.quantiles = response["data"]["population"];
    }

    // read quantiles
    // Overwrite population because if this is available, is the
    // correct data to use, already calculated.
    if ("quantiles" in response["data"]) {
      decoded.quantiles = response["data"]["quantiles"];
    }
    /* widget expects this structure
            {
                q0: 0,
                q1: 0,
                q2: 0,
                q3: 0,
                q4: 0
            }
        */

    // read response bottom, mid, top
    if (
      "bottom" in response["data"] &&
      "mid" in response["data"] &&
      "top" in response["data"]
    ) {
      decoded.bottom.average = response["data"]["bottom"]["average"];
      decoded.bottom.dataTotalCount = response["data"]["bottom"]["card"];
      decoded.bottom.max = response["data"]["bottom"]["max"];
      decoded.bottom.min = response["data"]["bottom"]["min"];

      decoded.intervals = paramsEncoded["n"];

      decoded.middle.average = response["data"]["mid"]["average"];
      decoded.middle.dataTotalCount = response["data"]["mid"]["card"];
      decoded.middle.max = response["data"]["mid"]["max"];
      decoded.middle.min = response["data"]["mid"]["min"];

      decoded.top.average = response["data"]["top"]["average"];
      decoded.top.dataTotalCount = response["data"]["top"]["card"];
      decoded.top.max = response["data"]["top"]["max"];
      decoded.top.min = response["data"]["top"]["min"];

      var _data = response["data"]["stats"]["quantiles"];
      var indexBottom = paramsEncoded["n"];
      var indexTop = 1;

      // bottom
      var symbols = _data[indexBottom]["symbols"];
      addInstrument(
        symbols,
        decoded["bottom"]["data"],
        paramsEncoded["dimension"]
      );
      // top
      symbols = _data[indexTop]["symbols"];
      addInstrument(
        symbols,
        decoded["top"]["data"],
        paramsEncoded["dimension"]
      );
      // middle
      indexBottom = indexBottom - 1;
      indexTop = indexTop + 1;
      for (let i = indexTop; i <= indexBottom; i++) {
        symbols = _data[i]["symbols"];
        addInstrument(
          symbols,
          decoded["middle"]["data"],
          paramsEncoded["dimension"]
        );
      }
    }

    return decoded;
  }

  _decodeGet(params: any, response: any) {
    var _data = response["data"];
    var data: any = [];

    for (let i = 0, lengthI = _data.length; i < lengthI; i++) {
      const row = _data[i];
      const _row: any = [];
      for (let j = 0, lengthJ = row.length; j < lengthJ; j++) {
        const itemRequest = params[i][j];
        const itemResponse = row[j];
        const peerType = itemRequest.type;
        const fieldX = peerType === "ETF" ? "etfgeo" : "country";
        const fieldY = peerType === "ETF" ? "etfclass" : "icb";

        //
        // managing unexisting peers
        //
        // server returns wrong data if peer does not exist
        // It needs to check if what we asked, it what we get
        //
        if (
          itemRequest["what"] !== itemResponse[fieldY] ||
          itemRequest["where"] !== itemResponse[fieldX]
        ) {
          itemResponse[fieldX] = itemRequest["where"];
          itemResponse[fieldY] = itemRequest["what"];
          itemResponse["zDimension"] = itemRequest["zDimension"];
          itemResponse["type"] = itemRequest["type"];
        }

        _row.push(this.decode(itemResponse));
      }
      data.push(_row);
    }

    return data;
  }

  _decodeHistory(params: any, response: any) {
    const _data = response["data"][0][0];
    const data: any = [];
    const start = _data["start"];
    const end = _data["end"];

    for (let i = start; i <= end; i++) {
      data.push({
        d: i,
        t: TDate.daysToMilliseconds(i),
        A_n: _data?.ratingHistory?.[i]?.A ?? 0,
        B_n: _data?.ratingHistory?.[i]?.B ?? 0,
        C_n: _data?.ratingHistory?.[i]?.C ?? 0,
        D_n: _data?.ratingHistory?.[i]?.D ?? 0,
        N_n: _data?.ratingHistory?.[i]?.NA ?? 0,
        AB_n: _data?.abHistory?.[i] ?? 0,
        CD_n: _data?.cdHistory?.[i] ?? 0,
        A_p: (_data?.ratingHistoryPerc?.[i]?.A ?? 0) / 100,
        B_p: (_data?.ratingHistoryPerc?.[i]?.B ?? 0) / 100,
        C_p: (_data?.ratingHistoryPerc?.[i]?.C ?? 0) / 100,
        D_p: (_data?.ratingHistoryPerc?.[i]?.D ?? 0) / 100,
        AB_p: (_data?.abHistoryPerc?.[i] ?? 0) / 100,
        CD_p: (_data?.cdHistoryPerc?.[i] ?? 0) / 100,
        up_n: _data?.upCumHistory?.[i] ?? 0,
        dn_n: _data?.downCumHistory?.[i] ?? 0,
        up_p: (_data?.upCumHistoryPerc?.[i] ?? 0) / 100,
        dn_p: (_data?.downCumHistoryPerc?.[i] ?? 0) / 100,
        updn: (_data?.upDownCumHistoryPerc?.[i] ?? 0) / 100,
      });
    }

    data.sort(function (a: any, b: any) {
      return a.t > b.t ? 1 : b.t > a.t ? -1 : 0;
    });

    return data;
  }

  _ofInstrument(response: any) {
    return this.decode(response["data"]);
  }
}
