import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../../deepClone";
import AvailableFields from "../../commonWidgets/AvailableFields";
import DnD from "../../commonWidgets/DnD_Generic";
import { optionsForSortingSelect } from "../../utils/utils";
import { holdingsReducer } from "./React_Holdings_reducer";
import SortingSelect from "../../commonWidgets/SortingSelect";

type HoldingsProps = {
  configObj: any;
  columnsAvailable: any;
};

const Holdings = forwardRef(
  ({ configObj, columnsAvailable }: HoldingsProps, ref) => {
    const [state, dispatch] = useImmerReducer<any, any>(holdingsReducer, {
      content: { ...configObj.widgetValue.content },
      presentation: { columns: configObj.widgetValue.presentation.columns },
    });

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content.headline = state.content.headline;
        tempState.widgetValue.content.sortBy = state.content.sortBy;
        tempState.widgetValue.content.top = state.content.top;
        tempState.widgetValue.presentation.columns =
          dndRef.current.getInnerValue();
        return tempState;
      },
    }));

    const inputForSortingSelect = useMemo(() => {
      return optionsForSortingSelect(state.presentation.columns);
    }, [state.presentation.columns]);

    const operativeColumnsAvailable = useMemo(
      () => columnsAvailable,
      [columnsAvailable]
    );
    const [selectedColumns, setSelectedColumns] = useState(() => {
      let temp = deepClone(operativeColumnsAvailable);
      const keys = Object.keys(operativeColumnsAvailable);
      keys.forEach((key) => {
        operativeColumnsAvailable[key].forEach((element, index) => {
          state.presentation.columns.forEach((column) => {
            if (element.field === column.property) {
              temp[key][index]["selected"] = true;
            }
          });
        });
      });
      return temp;
    });
    const dndRef = useRef<any>(null);
    const [preSaved, setPreSaved] = useState(state.presentation.columns);

    useEffect(() => {
      const dndInnerValue = dndRef.current.getInnerValue();
      // every time that selectedColumns changes, we filter
      // the objs that has selected:true and compare the arr
      // with preSaved
      const arr: any[] = [];
      const keys = Object.keys(selectedColumns);
      keys.forEach((key) => {
        selectedColumns[key].forEach((element) => {
          if (element.selected != null && element.selected === true) {
            arr.push({ label: element.label, property: element.field });
          }
        });
      });
      if (
        JSON.stringify(preSaved) !== JSON.stringify(arr) &&
        preSaved.length === arr.length
      ) {
        //when this condition is satisfied it means that the order changed
        setPreSaved(preSaved);
      } else if (
        JSON.stringify(preSaved) !== JSON.stringify(arr) &&
        preSaved.length !== arr.length
      ) {
        //means that something has been added  or removed from the array
        if (preSaved.length > arr.length) {
          // something has been removed,
          // then we look for the difference between the arrays
          // and remove the difference from dndInnerValue and set the state.
          let set1 = dndInnerValue.map((item) => item.property);
          let set2 = arr.map((item) => item.property);
          let difference = set1.filter((x) => !set2.includes(x))[0];
          for (let i = 0; i < dndInnerValue.length; i++) {
            if (dndInnerValue[i].property === difference) {
              // removing from array the item
              let temp: any[] = deepClone(dndInnerValue);
              let firstHalf = temp.splice(0, i);
              let secondHalf = temp.splice(1);
              temp = firstHalf.concat(secondHalf);
              setPreSaved(temp);
              break;
            }
          }
        }
        if (preSaved.length < arr.length) {
          // something has been added
          let set1 = dndInnerValue.map((item) => item.property);
          let set2 = arr.map((item) => item.property);
          let difference = set2.filter((x) => !set1.includes(x))[0];
          let temp: any[] = deepClone(dndInnerValue);
          keys.forEach((key) => {
            selectedColumns[key].forEach((element) => {
              if (element.field === difference) {
                temp.push({ label: element.label, property: element.field });
              }
            });
          });
          setPreSaved(temp);
        }
      }
    }, [preSaved, selectedColumns]);

    return (
      <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
        <Box display={"flex"} gap={2} width={"100%"}>
          <Card sx={{ boxShadow: 3, display: "flex", width: "40%" }}>
            <CardContent
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                pb: "16px !important",
                gap: 2,
              }}
            >
              <Box display={"flex"} alignItems="center" width={"100%"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.content.headline.isEnabled}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_CONTENT_headline_ISENABLED",
                          payload: e.target.checked,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Headline"
                />
                <TextField
                  size="small"
                  value={state.content.headline.content}
                  disabled={!state.content.headline.isEnabled}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_CONTENT_headline_CONTENT",
                      payload: e.target.value,
                    });
                  }}
                />
              </Box>

              <Box display={"flex"} alignItems="center" width={"100%"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.content.top.isEnabled}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_CONTENT_top_ISENABLED",
                          payload: e.target.checked,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Top"
                />
                <InputNumber
                  isPercentage={false}
                  initVal={state.content.top.content}
                  isDisabled={!state.content.top.isEnabled}
                  setOuterState={(e) =>
                    dispatch({
                      type: "SET_CONTENT_top_CONTENT",
                      payload: e,
                    })
                  }
                  isFloating={false}
                />
              </Box>

              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Typography>Sort by</Typography>

                <SortingSelect
                  options={inputForSortingSelect}
                  value={state.content.sortBy}
                  onSortChange={(e) => {
                    dispatch({
                      type: "SET_CONTENT_sortBy",
                      payload: e,
                    });
                  }}
                />
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent sx={{ pb: "16px !important" }}>
              <AvailableFields
                columnsAvailable={selectedColumns}
                onchangeState={setSelectedColumns}
              />
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent sx={{ pb: "16px !important" }}>
              <Typography>Drag and drop column to change order.</Typography>
              <DnD
                ref={dndRef}
                listContentObjBuilder={deepClone(preSaved)}
                direction={"horizontal"}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
);

export default Holdings;
