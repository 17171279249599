import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RankOutput,
  Ranking,
  RankingUniverseConstraintsType,
} from "../js/app/pages/rank/Ranking";
import { useEnvironment } from "./useEnvironment";

export type RankReducerActionType =
  | {
      type: "SET_CONSTRAINTS";
      payload: RankingUniverseConstraintsType | undefined;
    }
  | {
      type: "SET_OPTIONAL_CONSTRAINTS";
      payload: RankingUniverseConstraintsType | undefined;
    }
  | { type: "SET_RANKING_RULES"; payload: any[] | undefined }
  | { type: "SET_COLUMNS"; payload: any[] }
  | { type: "SET_RANKING_RESULT"; payload: RankOutput }
  | { type: "SET_CURRENT_TEMPLATE_ID"; payload: any }
  | { type: "SET_LIST_HIGHLIGHT"; payload: number | undefined }
  | { type: "SET_OPTIONAL_CONSTRAINTS_ENABLED"; payload: boolean }
  | {
      type: "SET_UNIVERSE_FROM";
      payload: undefined | "screenerETF" | "screenerStock" | "whiteList";
    }
  | {
      type: "SET_OPTIONAL_UNIVERSE_FROM";
      payload: undefined | "screenerETF" | "screenerStock" | "whiteList";
    }
  | {
      type: "SET_FROM_DATE";
      payload:
        | undefined
        | "PREVIOUS_DAY"
        | "PREVIOUS_WEEK"
        | "PREVIOUS_2_WEEKS"
        | "PREVIOUS_MONTH"
        | "PREVIOUS_3_MONTHS";
    };

export const useRank = () => {
  const [constraints, setConstraints] =
    useState<RankingUniverseConstraintsType>();
  const [rankingRules, setRankingRules] = useState<any[]>();
  const [rankResults, setRankResults] = useState<RankOutput>({
    columns: [],
    data: [],
    dataTotalCount: 0,
    universeDefinition: { filters: [] },
  });
  const [currentTemplateId, setCurrentTemplateId] = useState<any>();
  const [highlightListId, setHighlightListId] = useState<number>();
  const [rankingCache, setRankingCache] = useState();
  const [universeFrom, setUniverseFrom] = useState<
    "screenerETF" | "screenerStock" | "whiteList"
  >();
  const [optionalUniverseFrom, setOptionalUniverseFrom] = useState<
    "screenerETF" | "screenerStock" | "whiteList"
  >();
  const [optionalConstraintsEnabled, setOptionalConstraintsEnabled] =
    useState(false);

  // Used by the portfolio analysis page
  const [optionalConstraints, setOptionalConstraints] =
    useState<RankingUniverseConstraintsType>();

  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);

  const rankEngine = useMemo(() => {
    return new Ranking(setup);
  }, [setup]);

  useEffect(() => {
    if (rankEngine instanceof Ranking) {
      rankEngine?.clearUniverseIds();
    }
  }, [constraints, rankEngine, rankingRules]);

  useEffect(() => {
    if (rankEngine instanceof Ranking) {
      rankEngine.setHighlightListId(highlightListId);
    }
  }, [highlightListId, rankEngine]);

  const rankReducer = useCallback(
    (action: RankReducerActionType) => {
      switch (action.type) {
        case "SET_CONSTRAINTS":
          setConstraints(action.payload);
          rankEngine.setConstraints(action.payload);
          break;

        case "SET_RANKING_RULES":
          setRankingRules(action.payload);
          rankEngine.setRules(action.payload);
          break;

        case "SET_COLUMNS":
          const fields = action.payload.map((col) => col.field!);
          rankEngine.setColumns(fields);
          break;

        case "SET_RANKING_RESULT":
          setRankResults(action.payload);
          break;

        case "SET_CURRENT_TEMPLATE_ID":
          setCurrentTemplateId(action.payload);
          break;

        case "SET_LIST_HIGHLIGHT":
          setHighlightListId(action.payload);
          break;

        case "SET_UNIVERSE_FROM":
          setUniverseFrom(action.payload);
          break;

        case "SET_OPTIONAL_CONSTRAINTS":
          setOptionalConstraints(action.payload);
          rankEngine.setOptionalConstraints(action.payload);
          break;

        case "SET_OPTIONAL_UNIVERSE_FROM":
          setOptionalUniverseFrom(action.payload);
          break;

        case "SET_OPTIONAL_CONSTRAINTS_ENABLED":
          setOptionalConstraintsEnabled(action.payload);
      }
    },
    [rankEngine]
  );

  const rank = useCallback(
    async (params?: {
      page?: number;
      itemsPerPage?: number;
      rev?: boolean;
      sortField?: string;
      columns?: string[];
      rules?: any[];
      constraints?: any;
      fromDate?:
        | undefined
        | "PREVIOUS_DAY"
        | "PREVIOUS_WEEK"
        | "PREVIOUS_2_WEEKS"
        | "PREVIOUS_MONTH"
        | "PREVIOUS_3_MONTHS";
    }) => {
      if (rankEngine instanceof Ranking) {
        const actionsDict = {
          page: () => rankEngine.setPage(params?.page ?? 1),
          constraints: () => rankEngine.setConstraints(params?.constraints),
          itemsPerPage: () =>
            rankEngine.setItemsPerPage(params?.itemsPerPage ?? 25),
          sortField: () => rankEngine.setSortField(params?.sortField ?? "rank"),
          rev: () => rankEngine.setSortDirection(params?.rev ?? true),
          columns: () => rankEngine.setColumns(params?.columns ?? []),
          rules: () => rankEngine.setRules(params?.rules ?? []),
          fromDate: () => rankEngine.setFromDate(params?.fromDate ?? undefined),
        };

        for (const paramKey of Object.keys(params ?? {})) {
          if (actionsDict[paramKey]) {
            actionsDict[paramKey]();
          } else {
            throw new Error(`Wrong param: ${paramKey}`);
          }
        }

        const rankResult = await rankEngine.rank();

        rankReducer({ type: "SET_RANKING_RESULT", payload: rankResult });

        const results = rankEngine.getRankingCache();

        setRankingCache(results);

        return rankResult;
      }
    },
    [rankEngine, rankReducer]
  );

  const getCurrentState = useCallback(() => {
    const constraints = rankEngine.getConstraints();
    const rules = rankEngine.getRules();
    const againstListId = rankEngine.getsetHighlightListId();
    const buildUniversefrom = universeFrom;
    const fromDate = rankEngine.getFromDate();
    const fields = rankEngine.getCurrentColumns();

    const snapShot: any = {
      rules,
      highlightListId: againstListId,
      constraints,
      universeFrom: buildUniversefrom,
      fromDate,
      columnsFields: fields,
    };

    return snapShot;
  }, [rankEngine, universeFrom]);

  return {
    rankReducer,
    rankResults,
    rank,
    currentTemplateId,
    rankingRules,
    highlightListId,
    rankingCache,
    universeFrom,
    constraints,
    getCurrentState,
    optionalConstraints,
    optionalUniverseFrom,
    optionalConstraintsEnabled,
  };
};
