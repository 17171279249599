/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Subscriptions
 * @summary API for subscriptions
 *
 */

import { httpAll } from "../../httpAll";
import { endpoints } from "../endpoints";
import {
  PUBLISHER_USER_ID,
  SHARED_OBJECT_TYPE_UI_TO_SERVER,
  _CommonPublicationsSubscriptions,
} from "./_CommonPublicationsSubscriptions";

export class Subscriptions extends _CommonPublicationsSubscriptions {
  /**
   * Create a subscription
   *
   * @param {object} params - request parameters
   * @param {number} params.id - id of the publication to be subscribed
   * @param {string} params.type - type of the publication to be subscribed.
   *      One of: "list", "ranking", "strategy" or "systematicPortfolio".
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  create({
    id,
    type,
  }: {
    id: number;
    type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.subscriptions.create;

    const objectType = SHARED_OBJECT_TYPE_UI_TO_SERVER[type];
    const paramsCreate = {
      objectId: id,
      objectType: objectType,
      userId: this.environment.account.user?.id,
    };

    return this.preparePost(url, paramsCreate, null);
  }

  /**
   * Retrieves subscriptions
   *
   * @param {object} params - request parameters
   * @param {string} params.type - type of subscriptions to retrieve. One
   *      of: "basket", "portfolio", "ranking", "strategy" or
   *      "systematicPortfolio".
   *
   *      If params.type is not specified, all available subscriptions
   *      are retrieved
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async get({
    type,
  }: {
    type?: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER;
  } = {}) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.subscriptions.get;

    const publisherUserId = PUBLISHER_USER_ID;
    const subscriptionsType = SHARED_OBJECT_TYPE_UI_TO_SERVER;
    const userId = this.environment.account.user?.id;

    if (type != null && type in subscriptionsType) {
      if (userId === publisherUserId) {
        return Promise.resolve([]);
      }

      const paramsType = {
        objectType: subscriptionsType[type],
        userId: userId,
      };

      const response = await this.prepareGet(url, paramsType, null);
      return this.decode(response);
      // return this.getDetails("subscriptions", decodedResponse);
    }

    if (userId === publisherUserId) {
      const empty = {
        lists: [],
        rankings: [],
        strategies: [],
        systematicPortfolios: [],
      };

      return Promise.resolve(empty);
    }

    const paramsTypeLists = {
      objectType: subscriptionsType.list,
      userId: userId,
    };

    const paramsTypeRankings = {
      objectType: subscriptionsType.ranking,
      userId: userId,
    };

    const paramsTypeStrategies = {
      objectType: subscriptionsType.strategy,
      userId: userId,
    };

    const paramsTypeSystematicPortfolios = {
      objectType: subscriptionsType.systematicPortfolio,
      userId: userId,
    };

    const paramsTypeCombinedStrategies = {
      objectType: subscriptionsType.combinedStrategy,
      userId: userId,
    };

    const paramsTypeCombinedProducts = {
      objectType: subscriptionsType.combinedProduct,
      userId: userId,
    };

    const response = await httpAll({
      lists: this.prepareGet(url, paramsTypeLists, null),
      rankings: this.prepareGet(url, paramsTypeRankings, null),
      strategies: this.prepareGet(url, paramsTypeStrategies, null),
      systematicPortfolios: this.prepareGet(
        url,
        paramsTypeSystematicPortfolios,
        null
      ),
      combinedStrategies: this.prepareGet(
        url,
        paramsTypeCombinedStrategies,
        null
      ),
      combinedProducts: this.prepareGet(url, paramsTypeCombinedProducts, null),
    });
    return this.decodeAll(response);
    // return this.getDetailsAll("subscriptions", decodedResponse);
  }

  /**
   * Utility to check if a item is among subscriptions
   *
   * @param {object} publication
   * @param {array}  subscriptions
   *
   * @returns {boolean}
   */
  isSubscription(item: any, subscriptions: any) {
    return subscriptions.some(
      (subscription: any) => item.id === subscription.id
    );
  }

  /**
   * Remove a subscription
   *
   * @param {number} pubSubId - the id of subscription to remove
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  remove(pubSubId: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.subscriptions.remove;

    const paramsRemove = {
      id: pubSubId,
    };

    return this.preparePost(url, paramsRemove, null);
  }

  async find(
    preferenceId: number,
    type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER
  ) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.pubSub.subscriptions.find;

    let userId = this.environment?.account?.user?.id;

    const queryParams = {
      objectType: SHARED_OBJECT_TYPE_UI_TO_SERVER[type],
      objectId: preferenceId,
      userId,
    };

    if (userId == null || type == null || preferenceId == null) {
      return new Error(
        `Some param is missing: type: ${type}, preferenceId: ${preferenceId}, userId: ${userId}`
      );
    }

    try {
      const response = await this.prepareGet(url, queryParams);

      if (response) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }
}
