export const _formatAction = (output, value, translate) => {
  var baseClass = "tSystematicProductRebalance";
  var cssOrder = baseClass + "-order";
  var cssBuy = cssOrder + " " + cssOrder + "--buy";
  var cssDecrease = cssOrder + " " + cssOrder + "--decrease";
  var cssIncrease = cssOrder + " " + cssOrder + "--increase";
  var cssSell = cssOrder + " " + cssOrder + "--sell";

  switch (output) {
    case "TEXT": {
      switch (value) {
        case "buy": {
          return translate("c_position_buy");
        }
        case "decrease": {
          return translate("c_position_decrease");
        }
        case "increase": {
          return translate("c_position_increase");
        }
        case "sell": {
          return translate("c_position_sell");
        }
        // no default
      }
      break;
    }
    // eslint-disable-next-line no-fallthrough
    case "HTML":
    default: {
      switch (value) {
        case "buy": {
          return {
            html:
              '<strong class="' +
              cssBuy +
              '">' +
              translate("c_position_buy") +
              "</strong>",
          };
        }
        case "decrease": {
          return {
            html:
              '<strong class="' +
              cssDecrease +
              '">' +
              translate("c_position_decrease") +
              "</strong>",
          };
        }
        case "increase": {
          return {
            html:
              '<strong class="' +
              cssIncrease +
              '">' +
              translate("c_position_increase") +
              "</strong>",
          };
        }
        case "sell": {
          return {
            html:
              '<strong class="' +
              cssSell +
              '">' +
              translate("c_position_sell") +
              "</strong>",
          };
        }

        // no default
      }
    }
  }
};
export const _prepareThresholdValue = (threshold) => {
  var _threshold = parseFloat(threshold);
  _threshold = _threshold === 0 ? 0.0001 : _threshold;
  return _threshold;
};
