import { useCallback, useEffect, useMemo, useState } from "react";
import { PortfolioRanking } from "./PortfolioRanking";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";

type TabRankingProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabRanking({ dataManager }: TabRankingProps) {
  const [portfolio, setPortfolio] = useState<any>();

  const onComponentMount = useCallback(async () => {
    const response = await dataManager.get("ranking");

    setPortfolio(response);
  }, [dataManager]);

  const portfolioId = useMemo(() => portfolio?.id, [portfolio?.id]);

  const portfolioType = useMemo(() => portfolio?.type, [portfolio?.type]);

  const portfolioName = useMemo(() => portfolio?.name, [portfolio?.name]);

  const portfolioPositions = useMemo(
    () => portfolio?.positions,
    [portfolio?.positions]
  );

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return portfolio != null ? (
    <div>
      <PortfolioRanking
        portfolioPositions={portfolioPositions}
        portfolioId={portfolioId}
        portfolioName={portfolioName}
        portfolioType={portfolioType}
      />
    </div>
  ) : (
    <></>
  );
}
