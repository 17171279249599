import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../deepClone";
import {
  RatingChangeReducer,
  RatingChangeType,
} from "./React_RatingChange_reducer";

type RatingChangeProps = {
  configObj: any;
};

const RatingChange = forwardRef(({ configObj }: RatingChangeProps, ref) => {
  const initState: RatingChangeType = useMemo(
    () => ({
      content: configObj.widgetValue.content,
      presentation: configObj.widgetValue.presentation,
    }),
    [configObj.widgetValue.content, configObj.widgetValue.presentation]
  );
  const [state, dispatch] = useImmerReducer(RatingChangeReducer, initState);



  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state.content;
      tempState.widgetValue.presentation = state.presentation;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            pb: "16px !important",
          }}
        >
          <Box display={"flex"}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.headline.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_HEADLINE_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              disabled={!state.content.headline.isEnabled}
              defaultValue={state.content.headline.content}
              onChange={(e) =>
                dispatch({
                  type: "SET_CONTENT_HEADLINE_CONTENT",
                  payload: e.target.value,
                })
              }
              size="small"
            />
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            pb: "16px !important",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.downgrade}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_DOWNGRADE",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="Downgrades"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.upgrade}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_UPGRADE",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="Upgrades"
          />
          <Box display={"flex"} alignItems={"center"}>
            <Typography>Max rows:</Typography>
            <InputNumber
              initVal={state.content.numberOfItems}
              maxWidth={100}
              isPercentage={false}
              setOuterState={(e) =>
                dispatch({ type: "SET_CONTENT_NUMBERS_OF_ITEMS", payload: e })
              }
              isFloating={false}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default RatingChange;
