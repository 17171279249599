/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/SmartBetaUi2Api
 * @summary Converts UI smart beta paramters to Compute API parameters
 *
 */
export class SmartBetaUi2Api {
    decode(source: any) {
        const decoded: any = [];
        for (const rule of source) {
            if (rule.name === "rotation") {
                // managed in
                // trendrating/api/compute/Strategy.decode
                continue;
            }

            const ruleDecoded: any = {
                operator: null,
                property: rule.dimension,
                weight: rule.weight,
            };

            switch (rule.dimension) {
                case "rc": {
                    ruleDecoded.operator = {
                        A: rule.bySegments.A,
                        B: rule.bySegments.B,
                        C: rule.bySegments.C,
                        D: rule.bySegments.D,
                    };

                    break;
                }
                default: {
                    // bottom
                    if (rule.order === "asc" && rule.score === "cumulative") {
                        ruleDecoded.operator = "bottom";
                    }
                    // middle
                    if (rule.order === "desc" && rule.score === "frequency") {
                        ruleDecoded.operator = "middle";
                    }
                    // top
                    if (rule.order === "desc" && rule.score === "cumulative") {
                        ruleDecoded.operator = "top";
                    }
                }
            }

            decoded.push(ruleDecoded);
        }

        return decoded;
    }

    /**
     * Enconde smart beta rules
     *
     * @param {object[]} source
     * @param {string|object} source.operator - if string, options are:
     *      "bottom", "middle", "top"
     * @param {string} source.property - a trendrating instrument property
     * @param {number} source.weight - the weight
     */
    encode(source: any) {
        const encoded: any = [];
        for (const rule of source) {
            const ruleEncoded: any = {
                // bySegments: null,
                dimension: rule.property,
                // name: null,
                // order: null,
                // score: null,
                weight: rule.weight,
            };

            switch (rule.property) {
                case "f_eps_CC_g_3":
                case "f_sps_CC_g_3": {
                    ruleEncoded.computation = {
                        function: "g",
                        params: {
                            lag: 3,
                        },
                    };

                    this._encodeOperator(rule, ruleEncoded);

                    break;
                }
                case "f_eps_CC_g_12":
                case "f_sps_CC_g_12": {
                    ruleEncoded.computation = {
                        function: "g",
                        params: {
                            lag: 12,
                        },
                    };

                    this._encodeOperator(rule, ruleEncoded);

                    break;
                }
                case "rc": {
                    ruleEncoded.bySegments = {
                        A: rule.operator.A,
                        B: rule.operator.B,
                        C: rule.operator.C,
                        D: rule.operator.D,
                        NA: 1.0, // not in UI
                    };
                    ruleEncoded.name = "rating";

                    break;
                }
                default: {
                    this._encodeOperator(rule, ruleEncoded);
                }
            }

            encoded.push(ruleEncoded);
        }

        return encoded;
    }
    // ----------------------------------------------------- private methods
    _encodeOperator(rule: any, ruleEncoded: any) {
        switch (rule.operator) {
            case "bottom": {
                ruleEncoded.order = "asc";
                ruleEncoded.score = "cumulative";

                break;
            }
            case "middle": {
                ruleEncoded.order = "desc";
                ruleEncoded.score = "frequency";

                break;
            }
            case "top": {
                ruleEncoded.order = "desc";
                ruleEncoded.score = "cumulative";

                break;
            }
        }
    }
}
