import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import EditContentReact from "./EditContentReact/EditContentReact";
import { Box, Button, Typography } from "@mui/material";

type DialogEditProps = {
  closeDialog: Function;
  onDialogOk: (event) => void;
  onUpdateBacktesting?: (event) => void;
  saveAsNew?: boolean;
  product;
};

export function DialogEdit({
  closeDialog,
  onDialogOk,
  onUpdateBacktesting,
  product,
  saveAsNew = false,
}: DialogEditProps) {
  const [strategies, setStrategies] = useState([]);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);

  const getUserCollections = useCallback(async () => {
    setLoading(true);

    function sortByCaseInsensitive(property) {
      return function (a, b) {
        var valueA = String(a[property]).toLowerCase();
        var valueB = String(b[property]).toLowerCase();

        if (valueA > valueB) {
          return 1;
        }

        if (valueA < valueB) {
          return -1;
        }

        return 0;
      };
    }

    try {
      const userLists = await listsAPI.get();
      const userStrategy: any = await strategiesAPI.getList([
        "object.entity_type",
      ]);

      let item: any = null;

      const _lists = userLists?.data?.sort(sortByCaseInsensitive("name"));
      const lists: any = [];
      for (let i = 0, length = _lists.length; i < length; i++) {
        item = _lists[i];
        if (item["type"] === "PORTFOLIO") {
          lists.push({
            label:
              item["name"] +
              (item["isReadOnly"]
                ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
                : ""),
            value: item["id"],
          });
        }
      }

      const _strategies = userStrategy?.sort(sortByCaseInsensitive("name"));
      const strategies: any = [];
      for (let i = 0, length = _strategies.length; i < length; i++) {
        item = _strategies[i];
        if (item["entity_type"] === "BUILDER") {
          strategies.push({
            label:
              item["name"] +
              (item["isReadOnly"]
                ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
                : ""),
            value: item["id"],
          });
        }
      }

      setLists(lists);
      setStrategies(strategies);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [listsAPI, strategiesAPI]);

  useEffect(() => {
    getUserCollections();

    return () => {
      setStrategies([]);
      setLists([]);
    };
  }, [getUserCollections]);

  const editContentRef = useRef<any>(null);
  const onSaveHandler = useCallback(() => {
    const state = editContentRef?.current?.getState();
    if (state) {
      onDialogOk({ value: state });
      closeDialog();
    }
  }, [closeDialog, onDialogOk]);

  return loading ? (
    <></>
  ) : (
    <Box
      height={"auto"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
      gap={1}
    >
      <Box
        display={"flex"}
        width={"100%"}
        height={"auto"}
        overflow={"auto"}
        position={"relative"}
        pb={2}
      >
        <EditContentReact
          hideBacktestOverride={saveAsNew}
          ref={editContentRef}
          lists={lists}
          strategies={strategies}
          product={product}
          onUpdateBacktesting={onUpdateBacktesting}
        />
      </Box>
      <Box display={"flex"} justifyContent={"end"} width={"100%"} gap={2}>
        {saveAsNew && (
          <Typography>
            You are editing a subscribed systematic portfolio, a personal copy
            will be created
          </Typography>
        )}
        <Button onClick={onSaveHandler}>{saveAsNew ? "Create" : "Save"}</Button>
        <Button variant="tr_button_cancel" onClick={() => closeDialog()}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
