import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./RadioButtonBar.module.scss";

type RadioButtonBarProps = {
  options: { label: string; value: any }[];
  onChange: (value: any) => void;
  initValue?: any;
  className?: string;
};

export function RadioButtonBar({
  options,
  onChange,
  initValue,
  className,
}: RadioButtonBarProps) {
  const [selected, setSelected] = useState(initValue ?? options[0].value);

  const selectOption = useCallback(
    (value) => {
      setSelected(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <ul className={`${styles.list} ${className != null ? className : ""}`}>
      {options.map((option) => (
        <li
          className={`${styles.item} ${
            selected === option.value ? styles.active : ""
          }`}
          key={uuidv4()}
          onClick={() => selectOption(option.value)}
        >
          <Typography sx={{ fontSize: "0.9vw" }}>{option.label}</Typography>
        </li>
      ))}
    </ul>
  );
}
