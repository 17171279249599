export class Mutex {
  private count: number;
  private waiting: Array<() => void>;

  constructor(initialCount: number = 1) {
    this.count = initialCount;
    this.waiting = [];
  }

  private async acquire(): Promise<void> {
    if (this.count > 0) {
      this.count--;
      return Promise.resolve();
    } else {
      return new Promise<void>((resolve) => {
        this.waiting.push(resolve);
      });
    }
  }

  private release(): void {
    if (this.waiting.length > 0) {
      const next = this.waiting?.shift();

      if (next) {
        next();
      }
    } else {
      this.count++;
    }
  }

  public async runWithMutex(
    promise,
    options?: {
      onSuccess?: (response) => any;
      onError?: (error: { error: string }) => any;
    }
  ) {
    try {
      await this.acquire();

      const response = await promise();

      if (options && options.onSuccess) {
        options.onSuccess(response);
      } else {
        return response;
      }
    } catch (error) {
      const customError = {
        error:
          "Something gone wrong, please reload the page and if the error occurs again contact our customers support.",
      };

      if (options && options.onError) {
        options.onError(customError);
      } else {
        return customError;
      }
    } finally {
      this.release();
    }
  }

  public available(): number {
    return this.count;
  }

  public waitingCount(): number {
    return this.waiting.length;
  }
}
