/* eslint-disable no-template-curly-in-string */
export const defaultTemplateReportPeerWizard = {
  detailed_what: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION_CHILDREN",
          content: {
            focusOn: "what",
            headline: {
              content: "",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Overview - Sectors - Detailed",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  detailed: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
            notCustomizable: true,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_DISPERSION_BY_SECTORS",
          content: {
            headline: {
              content: "Performance dispersion by sector",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
            segment: "1 Industry",
            trimOutliers: false,
            sort: { descending: false, property: "_s_label" },
            addPageBreak: false,
            showTimeframeInTitle: true,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Overview - Detailed",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  dispersion: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_DISPERSION_BY_CHART",
          content: {
            headline: {
              content: "Dispersion by sector chart",
              isEnabled: true,
            },
            title: {
              content: "",
              isEnabled: false,
            },
            intervals: 4,
            timeframe: "3_months",
            segment: "1 Industry",
            trimOutliers: false,
            hideIfOneResult: false,
            sort: { descending: false, property: "_s_label" },
            addPageBreak: false,
            showTimeframeInTitle: true,
          },
          presentation: {
            logAxis: true,
            legend: true,
            size: "SMALL",
            align: "left",
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_DISPERSION_BY_SECTORS",
          content: {
            headline: {
              content: "Performance dispersion by sector",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
            segment: "1 Industry",
            trimOutliers: false,
            sort: { descending: false, property: "_s_label" },
            addPageBreak: false,
            showTimeframeInTitle: true,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_DISPERSION_TCR_TABLE",
          content: {
            headline: {
              content: "Rating by sector",
              isEnabled: true,
            },
            segment: "1 Industry",
            sort: { descending: false, property: "name" },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_DISPERSION_RATIO_TABLE",
          content: {
            headline: {
              content: "Ratio upgrades - downgrades",
              isEnabled: true,
            },
            segment: "1 Industry",
            timeframe: "today",
            hideIfOneResult: false,
            useWysiwyg: true,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Performance Dispersion by Sector/Market:</b> Measures the average returns of all stocks within top, middle and bottom percentage ranges. <br><br> <b>Rating by Sector/Market/Size:</b> Records the percentage of stocks in a bull trend (AB%) vs. the percentage of stocks in a bear phase (CD%). The ratio between the two groups drives the calculation of the aggregated rating, known as the Trend Capture Rating (TCR). The higher the TCR, the stronger the universe. A low TCR indicates overall trend weakness.<br><br>  <b>Ratio of Upgrades to Downgrades:</b> Displays the number of upgrades vs. downgrades for the selected time period. The ratio measures the percentage of upgrades vs. all rating changes. A ratio above 50% indicates more upgrades than downgrades.",
          },
          presentation: null,
          sections: null,
        },
      ],
      theme: {
        line: {
          color: "#DFDFDF",
        },
        table: {
          border: "#DFDFDF",
          head: {
            background_color: "#FFFFFF",
            border: "#FFFFFF",
            color: "#000000",
            separator: "#2a7092",
          },
          body: {
            background_color: ["#ffffff", "#FFFFFF"],
            border: "#aaaaaa",
            color: "#000000",
          },
        },
        text: {
          header_1: {
            color: "#2a7092",
          },
          title: {
            color: "#000000",
          },
          title_1: {
            color: "#000000",
          },
          title_2: {
            color: "#AAAAAA",
          },
        },
      },
    },
    id: null,
    isEditable: false,
    name: "Dispersion",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  detailed_where: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION_CHILDREN",
          content: {
            focusOn: "where",
            headline: {
              content: "",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Overview - Markets - Detailed",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  overview_what: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Overview - Sectors",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
  overview_where: {
    configuration: {
      integration: {
        peer: {
          timeframe: null,
        },
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_DISPERSION",
          content: {
            headline: {
              content: "Performance Dispersion",
              isEnabled: true,
            },
            intervals: 4,
            timeframe: "3_months",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_OVERVIEW",
          content: {
            headline: {
              content: "Today's Trends",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_TCR_CHANGES",
          content: {
            headline: {
              content: "TCR Changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_AB_CHANGES",
          content: {
            headline: {
              content: "AB% changes",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES",
          content: {
            headline: {
              content: "Upgrades / Downgrades",
              isEnabled: true,
            },
            timeframe: "lastMonth",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: null,
    },
    id: null,
    isEditable: false,
    name: "Overview - Markets",
    ownerId: null,
    type: "TEMPLATE_REPORT_PEER",
    version: "1.0",
  },
};
