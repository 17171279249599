import { Box } from "@mui/system";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./DnD_Generic.module.scss";
import { deepClone } from "../../../../../../../deepClone";

type DnDProps = {
  listContentObjBuilder: { label: any; field: any }[];
  setOutterState?: Function;
  direction: "horizontal" | "vertical";
};

const DnD = forwardRef(
  ({ listContentObjBuilder, setOutterState, direction }: DnDProps, ref) => {
    const [OperativeContent, setOperativeContent] = useState(
      listContentObjBuilder
    );
    useEffect(() => {
      setOperativeContent(listContentObjBuilder);
    }, [listContentObjBuilder]);

    useEffect(() => {
      if (setOutterState != null) {
        setOutterState(OperativeContent);
      }
    }, [OperativeContent, setOutterState]);
    const [draggingElement, setDraggingdElement] = useState<any>(null);
    const listRef = useRef<HTMLUListElement>(null);

    useImperativeHandle(ref, () => ({
      getInnerValue: () => deepClone(OperativeContent),
    }));

    const DND_GHOST_CLASS = "dndGhost";
    //*----------------------
    //*----------------------
    //* DRAG & DROP FUNCTIONS
    const dragOver = useCallback(
      (e: any) => {
        e.preventDefault();

        if (direction === "horizontal") {
          if (
            e.clientX <
            e.target.getBoundingClientRect().left + e.target.offsetWidth / 2
          ) {
            //insert before
            if (e.currentTarget.classList.contains(styles.after_horiz)) {
              e.currentTarget.classList.remove(styles.after_horiz);
            }
            e.currentTarget.classList.add(styles.before_horiz);
          } else {
            //insert after
            if (e.currentTarget.classList.contains(styles.before_horiz)) {
              e.currentTarget.classList.remove(styles.before_horiz);
            }
            e.currentTarget.classList.add(styles.after_horiz);
          }
        }

        if (direction === "vertical") {
          if (
            e.clientY <
            e.target.getBoundingClientRect().top + e.target.offsetHeight / 2
          ) {
            if (e.currentTarget.classList.contains(styles.after_vert)) {
              e.currentTarget.classList.remove(styles.after_vert);
            }
            e.currentTarget.classList.add(styles.before_vert);
          } else {
            if (e.currentTarget.classList.contains(styles.before_vert)) {
              e.currentTarget.classList.remove(styles.before_vert);
            }
            e.currentTarget.classList.add(styles.after_vert);
          }
        }
      },
      [direction]
    );
    const dragStart = useCallback((e: any) => {
      //!! creating dndGhost
      var crt = e.currentTarget.cloneNode(true);
      crt.style.height = `${e.currentTarget.clientHeight}px`;
      crt.style.width = `${e.currentTarget.clientWidth}px`;
      crt.style.display = "hidden";
      crt.style.transform = "translateX(-500px)";
      crt.style.backgroundColor = "rgba(0,0,0,.2)";
      crt.style.position = "absolute";
      crt.style.left = "-100%";
      crt.style.top = "50%";
      crt.style.zIndex = -5;
      crt.classList.add(DND_GHOST_CLASS);
      document.body.appendChild(crt);
      e.dataTransfer.setDragImage(crt, 0, 0);
      //!---------------------
      setDraggingdElement(e.currentTarget.id);
    }, []);
    const drop = useCallback(
      (e: any) => {
        //!! removing dndGhost from dom
        let ghosts = Array.from(
          document.getElementsByClassName(DND_GHOST_CLASS)
        );
        ghosts.forEach((ghost) => ghost.remove());
        //!----------------------------

        let newArr = [...OperativeContent];
        const currentPos = parseInt(draggingElement);
        const droppedPos = parseInt(e.currentTarget.id);
        const absDifference = Math.abs(currentPos - droppedPos);

        const arrChildren = Array.from(listRef.current!.children);
        arrChildren.forEach((item) => {
          if (direction === "horizontal") {
            if (item.classList.contains(styles.before_horiz)) {
              item.classList.remove(styles.before_horiz);
            }
            if (item.classList.contains(styles.after_horiz)) {
              item.classList.remove(styles.after_horiz);
            }
          }
          if (direction === "vertical") {
            if (item.classList.contains(styles.before_vert)) {
              item.classList.remove(styles.before_vert);
            }
            if (item.classList.contains(styles.after_vert)) {
              item.classList.remove(styles.after_vert);
            }
          }
        });

        if (currentPos !== droppedPos) {
          if (direction === "horizontal") {
            if (
              e.clientX <
              e.target.getBoundingClientRect().left + e.target.offsetWidth / 2
            ) {
              //---------insert before
              if (!(absDifference === 1 && currentPos < droppedPos)) {
                if (currentPos !== -1 && droppedPos !== -1) {
                  const item = newArr.splice(currentPos, 1);
                  newArr.splice(droppedPos, 0, item[0]);
                  setOperativeContent(newArr);
                }
              }
            } else {
              //--------insert after
              if (!(absDifference === 1 && currentPos > droppedPos)) {
                if (currentPos !== -1 && droppedPos !== -1) {
                  const item = newArr.splice(currentPos, 1);
                  newArr.splice(droppedPos, 0, item[0]);
                  setOperativeContent(newArr);
                }
              }
            }
          }

          if (direction === "vertical") {
            if (
              e.clientY <
              e.target.getBoundingClientRect().top + e.target.offsetHeight / 2
            ) {
              //---------insert before
              if (!(absDifference === 1 && currentPos < droppedPos)) {
                if (currentPos !== -1 && droppedPos !== -1) {
                  const item = newArr.splice(currentPos, 1);
                  newArr.splice(droppedPos, 0, item[0]);
                  setOperativeContent(newArr);
                }
              }
            } else {
              //--------insert after
              if (!(absDifference === 1 && currentPos > droppedPos)) {
                if (currentPos !== -1 && droppedPos !== -1) {
                  const item = newArr.splice(currentPos, 1);
                  newArr.splice(droppedPos, 0, item[0]);
                  setOperativeContent(newArr);
                }
              }
            }
          }
        }
      },
      [OperativeContent, direction, draggingElement]
    );
    // const dragEnter = (e: React.DragEvent) => {};
    const dragLeave = useCallback(
      (e: any) => {
        if (direction === "vertical") {
          if (e.currentTarget.classList.contains(styles.before_vert)) {
            e.currentTarget.classList.remove(styles.before_vert);
          }
          if (e.currentTarget.classList.contains(styles.after_vert)) {
            e.currentTarget.classList.remove(styles.after_vert);
          }
        }

        if (direction === "horizontal") {
          if (e.currentTarget.classList.contains(styles.before_horiz)) {
            e.currentTarget.classList.remove(styles.before_horiz);
          }
          if (e.currentTarget.classList.contains(styles.after_horiz)) {
            e.currentTarget.classList.remove(styles.after_horiz);
          }
        }
      },
      [direction]
    );
    //*----------------------
    //*----------------------

    //! since we create ghosts that hang somewhere in the dom,
    //! we need to get rid of them once the panel is closed.
    //#region
    useEffect(() => {
      return () => {
        let ghosts = Array.from(
          document.getElementsByClassName(DND_GHOST_CLASS)
        );
        if (ghosts.length > 0) {
          ghosts.forEach((ghost) => ghost.remove());
        }
      };
    });
    //#endregion

    return (
      <Box>
        <ul
          ref={listRef}
          className={`${styles.list} ${
            direction === "horizontal" ? styles.horizontal : styles.vertical
          }`}
        >
          {OperativeContent.map((item: any, index: number) => (
            <li
              title={item.label}
              key={index}
              onDragOver={(e) => dragOver(e)}
              onDragStart={(e) => dragStart(e)}
              onDrop={(e) => drop(e)}
              onDragLeave={(e) => dragLeave(e)}
              id={index.toString()}
              className={styles.listItem}
              draggable={true}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </Box>
    );
  }
);

export default DnD;
