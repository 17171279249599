/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/_Base
 * @summary Mixin for HTTP requests
 *
 */
import axios, { AxiosRequestConfig } from "axios";
import qs from "query-string";
import { EndpointMeta, ServerError } from "../types/Api";
import { AppEnvironment } from "../types/Defaults";

export function cleanUrlFromBase(url: string): string {
  return url.replace(/\/\/(.*?)\//, "");
}

export class _Base {
  environment: AppEnvironment;

  constructor(environment: AppEnvironment) {
    if (environment == null) {
      throw new Error("[CRITICAL] No environment!");
    }
    this.environment = environment;
  }

  /**
   * Print deprecation messages
   */
  deprecated(
    objectModelName: string,
    deprecatedMethodName: string,
    newMethodName: string
  ) {
    console.warn(
      [
        "[DEPRECATED]",
        objectModelName,
        deprecatedMethodName,
        "use",
        newMethodName,
      ].join(" ")
    );
  }

  /**
   * @param endpointMeta - Endpoint configuration
   * @returns endpoint url
   */
  getEndpointRoot(endpointMeta: EndpointMeta): string {
    return `${window.location.origin}/${endpointMeta.baseEndPoint}`;
  }

  prepareDelete<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    return Promise.reject("Not implemented");
  }

  async prepareGet<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    const reqParams: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        ...params,
        ts: new Date().getTime(),
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params);
      },
    };

    this._setHeaders(reqParams, headers);

    const response = await axios.get(url, reqParams);
    return response.data;
  }

  async prepareGetBlob<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    const reqParams: AxiosRequestConfig = {
      responseType: "blob",
      params: {
        ...params,
        ts: new Date().getTime(),
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params);
      },
    };

    this._setHeaders(reqParams, headers);

    const response = await axios.get(url, reqParams);
    return response.data;
  }

  async prepareGetText<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    const reqParams: AxiosRequestConfig = {
      responseType: "text",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        ...params,
        ts: new Date().getTime(),
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params);
      },
    };

    this._setHeaders(reqParams, headers);

    const response = await axios.get(url, reqParams);
    return response.data;
  }

  preparePatch<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    console.log(
      "%cNot implemented",
      "background-color: red; color: #FFF; font-weight: bold"
    );
    return Promise.reject("Not implemented");
  }

  async preparePost<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    const reqParams: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        ts: new Date().getTime(),
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params);
      },
    };

    this._setHeaders(reqParams, headers);

    const response = await axios.post(url, params, reqParams);
    return response.data;
  }

  async preparePostBlob<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    const reqParams: AxiosRequestConfig = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        ts: new Date().getTime(),
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params);
      },
    };

    this._setHeaders(reqParams, headers);

    const response = await axios.post(url, params, reqParams);
    return response.data;
  }

  preparePut<T = any, R = any>(
    url: string,
    params: T,
    headers?: any
  ): Promise<R> {
    console.log(
      "%cNot implemented",
      "background-color: red; color: #FFF; font-weight: bold"
    );
    return Promise.reject("Not implemented");
  }

  simulateHttpError(response: any): Promise<ServerError> | null {
    // errors
    if ("error" in response) {
      switch (response["error"]) {
        case "INPUT_CARDINALITY_ERROR": {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 422, // Unprocessable Entity
          });
        }
        case "OBJECT_ALREADY_EXISTING":
        case "DUPLICATE_ENTRY": {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 409, // Conflict
          });
        }
        case "OBJECT_NOT_SUBSCRIBED": {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 404, // Object not found
          });
        }
        case "UNEXPECTED_ERROR":
        case "UNIVERSE_EMPTY": {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 500, // Internal server error
          });
        }
        case "H cannot be empty": {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 400, // Bad Request
          });
        }
        case "UNKNOWN_TYPE":
        default: {
          console.warn("[simulateHttpError]", response);

          return Promise.reject({
            response: response,
            status: 400, // Bad Request
          });
        }
      }
    }
    // warnings
    // The "in" is needed to check the key, even if it is undefined or null
    if (
      "data" in response &&
      response["data"] != null &&
      "WARNING" in response["data"]
    ) {
      const warnings = response.data.WARNING;

      for (let i = 0, length = warnings.length; i < length; i++) {
        switch (warnings[i]["code"]) {
          case "INDEX_NOT_AVAILABLE":
          case "UNIVERSE_EMPTY": {
            console.warn("[simulateHttpError]", response);

            return Promise.reject({
              response: warnings[i],
              status: 500, // Internal Server Error
            });
          }
        }
      }
    }
    // _StoreObjects: deleted, not found or empty
    // Check if key also exists (special case)
    // The "in" is needed to check the key, even if it is undefined or null
    if (
      "data" in response &&
      response["data"] != null &&
      "preference" in response["data"] &&
      response["data"]["preference"] == null
    ) {
      console.warn("[simulateHttpError]", response);

      return Promise.reject({
        response: response,
        status: 404, // Not found
      });
    }
    // Lists, Systematic Portfolios
    if (response["initiator"] != null) {
      console.warn("[simulateHttpError]", response);

      switch (response["initiator"]) {
        case "trendrating/api/compute/Lists.get(id)":
        case "trendrating/api/compute/SystematicPortfolios.get(ids)":
        case "trendrating/api/compute/SystematicPortfolios.getWithSubscriptions(params)": {
          return Promise.reject({
            response: response,
            status: 404, // Not found
          });
        }
      }
    }

    return null;
  }

  simulateHttpSuccess<T = any>(fakeData: T): Promise<T> {
    if (this.environment.api.isDebug) {
      console.warn("[simulateHttpSuccess] fakeData:", fakeData);
    }
    return Promise.resolve(fakeData);
  }

  // ----------------------------------------------------- private methods

  _setHeaders(reqParams: any, headers?: any): void {
    if (this.environment.api.isDebug) {
      reqParams.headers["X-Trendrating-Debug"] = 1;
      console.log(this.environment);
    }

    for (const header in headers) {
      reqParams.headers[header] = headers[header];
    }
  }
}
