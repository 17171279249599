import { FormControl, MenuItem, Theme, useTheme } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";

type IssuerTreeProps = {
    onChangeZDimension: (value: string) => void;
    initVal?: string;
};

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(taxonomy: string, taxonomyList: string[], theme: Theme) {
    return {
        fontWeight:
            taxonomyList.indexOf(taxonomy) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

/**
 *
 * @param a
 * @param b
 *
 * Sort by rank and use name sorting as fallback
 */
const sortIssuersChriteria = (a, b) => {
    // if (a.parent == null) {
    //     return -1;
    // }

    // if (b.parent == null) {
    //     return 1;
    // }

    if (a.rank > b.rank) {
        return 1;
    } else if (a.rank < b.rank) {
        return -1;
    }

    if (a.name > b.name) {
        return 1;
    } else if (a.name < b.name) {
        return -1;
    }

    return 0;
};

export function IssuerTree({ onChangeZDimension, initVal }: IssuerTreeProps) {
    const [taxonomy, setTaxonomy] = useState<any>([]);
    const [issuer, setIssuer] = useState<string>("ALL");
    const environment = useEnvironment();
    const taxonomies = useMemo(
        () => environment.get("setup")["taxonomies"],
        [environment]
    );
    const fieldsMap = useMemo(
        () => environment.get("setup")["taxonomyFields"],
        [environment]
    );
    const etfSubtypeTaxonomy = taxonomies[fieldsMap["ETF"]["subtype"]];
    const theme = useTheme();

    useEffect(() => {
        if (initVal) {
            setIssuer(initVal);
        }
    }, [initVal]);

    useEffect(() => {
        if (etfSubtypeTaxonomy) {
            const parentMap = {};
            const subtypeArray = Object.values<any>(etfSubtypeTaxonomy);
            let taxonomy: any = [];

            for (const node of subtypeArray) {
                if (!(node.parent in parentMap)) {
                    parentMap[node.parent] = node.parent;
                }
            }

            for (const taxonomyNode of subtypeArray) {
                if (taxonomyNode.parent != null) {
                    if (!(taxonomyNode.id in parentMap)) {
                        taxonomy.push(taxonomyNode);
                    }
                } else {
                    taxonomy.push(taxonomyNode);
                }
            }

            setTaxonomy(
                taxonomy
                    .sort(sortIssuersChriteria)
                    .map((taxonomy) => taxonomy.id)
            );
        }
    }, [etfSubtypeTaxonomy]);

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            setIssuer(event.target.value as string);
            onChangeZDimension(event.target.value as string);
        },
        [onChangeZDimension]
    );

    return taxonomy.length ? (
        <div style={{ minWidth: 150 }}>
            <FormControl fullWidth>
                <Select
                    sx={{ height: "25px" }}
                    size={"small"}
                    labelId="etfIssuer-label"
                    id="etfIssuer-label"
                    placeholder="Any"
                    value={issuer}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                >
                    {taxonomy.map((taxonomyName) => (
                        <MenuItem
                            key={taxonomyName}
                            value={taxonomyName}
                            style={getStyles(taxonomyName, taxonomy, theme)}
                        >
                            {taxonomyName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    ) : (
        <></>
    );
}
