export type MonthlyAnalyticsType = {
  headline: {
    content: string;
    isEnabled: boolean;
  };
  maxDrawdown: {
    content: string;
    isEnabled: boolean;
  };
  monthlyPerformance: {
    content: string;
    isEnabled: boolean;
  };
  monthlyStandardDeviation: {
    content: string;
    isEnabled: boolean;
  };
  years: string;
};

export type ActionType = {
  type:
    | "SET_headline_CONTENT"
    | "SET_headline_ISENABLED"
    | "SET_maxDrawdown_CONTENT"
    | "SET_maxDrawdown_ISENABLED"
    | "SET_monthlyPerformance_CONTENT"
    | "SET_monthlyPerformance_ISENABLED"
    | "SET_monthlyStandardDeviation_CONTENT"
    | "SET_monthlyStandardDeviation_ISENABLED"
    | "SET_years";
  payload: any;
};

export const monthlyAnalyticsReducer = (draft: MonthlyAnalyticsType, action: ActionType) => {
  switch (action.type) {
    case "SET_headline_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_headline_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_maxDrawdown_CONTENT":
      draft.maxDrawdown.content = action.payload;
      break;
    case "SET_maxDrawdown_ISENABLED":
      draft.maxDrawdown.isEnabled = action.payload;
      break;
    case "SET_monthlyPerformance_CONTENT":
      draft.monthlyPerformance.content = action.payload;
      break;
    case "SET_monthlyPerformance_ISENABLED":
      draft.monthlyPerformance.isEnabled = action.payload;
      break;
    case "SET_monthlyStandardDeviation_CONTENT":
      draft.monthlyStandardDeviation.content = action.payload;
      break;
    case "SET_monthlyStandardDeviation_ISENABLED":
      draft.monthlyStandardDeviation.isEnabled = action.payload;
      break;
    case "SET_years":
      draft.years = action.payload;
      break;
    default:
      break;
  }
};
