/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/components/Feedback
 * @summary Display application feedbacks
 *
 */

import { useCallback, useEffect, useRef, useState } from "react";
import { useBroadcast } from "../../../hooks/useBroadcast";

type Message = {
  content: {
    pinned?: boolean;
    text: string;
    type: string;
  };
};

type FeedbackProps = {
  channelInput: string;
};

export function Feedback({ channelInput }: FeedbackProps) {
  const [fade, setFade] = useState<string | null>();
  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [type, setType] = useState<string>();
  const timeoutId = useRef<null | ReturnType<typeof setTimeout>>(null);

  const { subscribe, unsubscribe } = useBroadcast();

  const handleFeedback = useCallback((message: Message) => {
    // Reset
    setShow(false);
    setFade("fadeOut");

    if (message.content.text == null || message.content.text === "") {
      return;
    }

    setText(message.content.text);
    setType(message.content.type);
    setShow(true);

    setTimeout(() => {
      setFade("fadeIn");

      if (timeoutId.current != null) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        setFade("fadeOut");
      }, 2500);
    }, 0);

    // pins message
  }, []);

  useEffect(() => {
    subscribe(channelInput, handleFeedback);
    return () => unsubscribe(channelInput, handleFeedback);
  }, [channelInput, handleFeedback, subscribe, unsubscribe]);

  //TODO: Migrate this component using cssTransition

  return show ? (
    <div className="feedback">
      <div
        className={`feedback-message ${`feedback-message--${type}`} ${
          fade ? fade : ""
        }`}
        onTransitionEnd={() => console.log("onTransitionEnd")}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  ) : null;
}
