import { useCallback, useMemo } from "react";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { widgetsConfiguration } from "../../../widgets/widgetsConfiguration";
import { Instruments } from "../../../../../api/compute/Instruments";
import { httpAll } from "../../../../../httpAll";
import { dataInjector } from "../../../../../api/compute/commons";
import { CSV } from "../../../utils/SingletonCSV";
import { useFormatter } from "../../../../../hooks/useFormatter";

type ExportOrdersProps = {
  label: string;
  target: any;
  title: string;
};

export function ExportOrders({ title, label, target }: ExportOrdersProps) {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);

  const instrumentsAPI = useMemo(() => new Instruments(setup), [setup]);
  const formatter = useFormatter();

  const format = useCallback(
    (positions) => {
      var lines: any = [];

      var line = [
        '"Weight"',
        '"Ticker"',
        '"Market ISO"',
        '"Currency"',
        '"ISIN"',
        '"Name"',
        '"Country"',
        '"Rating"',
        '"Rated on"',
      ];
      lines.push(line);

      var position: any = null;
      for (let i = 0, length = positions.length; i < length; i++) {
        position = positions[i];
        line = [
          '"' +
            formatter.text(
              "weight",
              "weight",
              position["weight"],
              position,
              position["type"]
            ) +
            '"',
          '"' +
            formatter.text(
              "ticker",
              "ticker",
              position["ticker"],
              position,
              position["type"]
            ) +
            '"',
          '"' + position.country + '"',
          '"' + position.currency + '"',
          '"' +
            formatter.text(
              "isin",
              "isin",
              position["isin"],
              position,
              position["type"]
            ) +
            '"',
          '"' +
            formatter.text(
              "name",
              "name",
              position["name"],
              position,
              position["type"]
            ) +
            '"',
          '"' + position.domicile + '"',
          '"' +
            formatter.text(
              "rc",
              "rating",
              position["rc"],
              {
                rateChange: position["lr"],
                rateDate: position["dr"],
                ratePrev: position["rrr"],
              },
              position["type"]
            ) +
            '"',
          '"' +
            formatter.text(
              "dr",
              "rated_on_iso",
              position["dr"],
              position,
              position["type"]
            ) +
            '"',
        ];

        lines.push(line);
      }

      return lines;
    },
    [formatter]
  );

  const _export = useCallback(
    (orders, response) => {
      var buy = dataInjector(response["buy"]["data"], orders.buy, ["weight"]);

      var sell = dataInjector(response["sell"]["data"], orders.sell, [
        "weight",
      ]);

      var lines = [['"BUY"']];
      lines = lines.concat(format(buy));

      lines.push(['"SELL"']);
      lines = lines.concat(format(sell));

      CSV.create(lines, target.list.name + " - orders.csv");
    },
    [format, target.list.name]
  );

  const doAction = useCallback(async () => {
    var orders = target.orders;
    // buy
    var symbolsBuy = orders.buy.map((item) => item.symbol);

    // sell
    var symbolsSell = orders.sell.map((item) => item.symbol);

    var properties =
      widgetsConfiguration["widgets/csv/portfolio/buy-sell"]["properties"];
    var fetchBuy = instrumentsAPI.fetch({
      properties: properties,
      symbols: symbolsBuy,
      type: "security",
    });
    var fetchSell = instrumentsAPI.fetch({
      properties: properties,
      symbols: symbolsSell,
      type: "security",
    });

    const response = await httpAll({
      buy: fetchBuy,
      sell: fetchSell,
    });

    await _export(orders, response);
  }, [_export, instrumentsAPI, target.orders]);

  return (
    <li className="menu__item" title={title} onClick={doAction}>
      {label}
    </li>
  );
}
