import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { clone, deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { DataPreparation } from "../_DataPreparation";
import TypeNavigator from "../widgets/header/TypeNavigator";
import Advanced from "./editors/Advanced/Advanced";

import { config } from "../../../config-ts";
import { getDefaultStrategy } from "./editors/Advanced/utils";

import { Box } from "@mui/material";
import axios from "axios";
import { useImmerReducer } from "use-immer";
import { Strategies } from "../../../../../api/compute/Strategies";
import { PanelForLists } from "../../../../../components/PanelForLists/PanelForLists";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { Remove } from "../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { StrategiesStorage } from "../../../storage/StrategiesStorage";
import { DialogSaveComponent } from "../../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess } from "../../../utils";
import ReportButton from "../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { DialogDebug } from "../widgets/dialogDebug/DialogDebug";
import { Export } from "./actions/Export";
import NewExportConstituents from "./actions/NewExportConstituents";
import { OptimizeAction } from "./actions/OptimizeAction";
import { Subscribe } from "./actions/Subscribe";
import TrackAction from "./actions/TrackAction";
import { TrackPortfolio, useTracker } from "./actions/TrackPortfolio";
import { Unsubscribe } from "./actions/Unsubscribe";
import {
  Dashboards,
  InsightsContext,
  InsightsState,
} from "./editors/Advanced/Result/tabs/Insights/Insights";
import { EditorAlpha } from "./editors/components/EditorAlpha";
import EditorMacroRotation from "./editors/components/EditorMacroRotation";
import SmartBeta from "./editors/components/EditorSmartBeta";
import { Loader } from "./Loader";
import {
  initStrategyBuildersState,
  strategiesBuilderReducer,
  StrategyBuilderState,
} from "./StrategiesBuilder.reducer";

type LoaderEvents = {
  startPreload: "start-strategy-preloading";
  stopPreload: "stop-strategy-preload";
  newProgram: "start-new-prog";
  updateProgram: "update-prog";
  completeProgram: "complete-prog";
  clearProgresses: "clear-progresses";
};

type LoaderContextType = {
  availableEvents: {
    startPreload: "start-strategy-preloading";
    stopPreload: "stop-strategy-preload";
    newProgram: "start-new-prog";
    updateProgram: "update-prog";
    completeProgram: "complete-prog";
    clearProgresses: "clear-progresses";
  };
  on: (e: string, listener: (e: string, data?: any) => void) => void;
  remove: (e: string, listener: (e: string, data: any) => void) => void;
  dispatch: (e: string, data?: any) => void;
};

export const STRATEGIES_LOADER_EVENTS: LoaderEvents = {
  startPreload: "start-strategy-preloading",
  stopPreload: "stop-strategy-preload",
  newProgram: "start-new-prog",
  updateProgram: "update-prog",
  completeProgram: "complete-prog",
  clearProgresses: "clear-progresses",
};

const dp = new DataPreparation();

export const LoaderContext = createContext<LoaderContextType>(undefined!);

export default function StrategiesBuilder() {
  const [strategyPubStatus, setStrategyPubStatus] = useState<
    "notPublic" | "subscribable" | "subscribed" | undefined
  >();

  //! Hiding page loader at render
  //#region
  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");
  }, []);
  //#endregion

  //! variables and states
  //#region

  const params = useParams<{
    id: string;
    type: "alpha" | "smartBeta" | "macroRotation" | "advanced";
    "*": string;
  }>();
  const environment = useEnvironment();
  const { broadcast } = useBroadcast();
  const { collectTrackInfo, getTrakingParams } = useTracker();

  const decodedType = useMemo(
    () => dp.uriFragmentToStrategyType(params.type),
    [params.type]
  );
  //#endregion

  const environmentSetup = useMemo(
    () => environment.get("setup"),
    [environment]
  );
  const { t } = useTranslation();

  const runManager = useMemo(
    () => new StrategiesStorage(environment.get("setup")),
    [environment]
  );

  const configurationBuilder = useMemo(
    () => environmentSetup?.configuration.get("strategyBuilder"),
    [environmentSetup?.configuration]
  );
  const configurationTracker = useMemo(
    () => environmentSetup?.configuration.get("strategyTracker"),
    [environmentSetup?.configuration]
  );
  const enabledTrackSMS = useMemo(() => {
    return configurationBuilder.workflow.trackInSMS.enabled;
  }, [configurationBuilder]);

  const userID = useMemo(
    () => environmentSetup.account.user.id,
    [environmentSetup.account.user.id]
  );

  const strategiesAPI = useMemo(
    () => new Strategies(environment.get("setup")),
    [environment]
  );

  const [readyToRun, setIsReadyToRun] = useState(true);
  const [dialogDebugParams, setDialogDEbugParams] = useState<
    | {
        params: any;
        type: any;
      }
    | undefined
  >();

  //#region - editor's ref
  const editorMacroRotationRef = useRef<any>(null);
  const editorAlphaRef = useRef<any>(null);
  const editorSmartBeta = useRef<any>(null);
  const editorAdvancedRef = useRef<any>(null);
  //#endregion

  const getCurrentEditor = useCallback(() => {
    switch (decodedType) {
      case "advanced":
        return editorAdvancedRef.current;
      case "alpha":
        return editorAlphaRef.current;
      case "macroRotation":
        return editorMacroRotationRef.current;
      case "smartBeta":
        return editorSmartBeta.current;

      default:
        return;
    }
  }, [decodedType]);

  //! tripartition of state -------
  //#region inits and state declaration
  const initAlpha = useMemo(() => {
    let strategyPrototype = strategiesAPI.getPrototype() as any;
    strategyPrototype.params = /*initialEditorState*/ null;
    strategyPrototype.name = "Alpha Strategy";
    strategyPrototype.entity_type = "ALPHA";
    let temp = deepClone(initStrategyBuildersState);
    temp.strategy = strategyPrototype;
    return temp;
  }, [strategiesAPI]);
  const initSmartBeta = useMemo(() => {
    let strategyPrototype = strategiesAPI.getPrototype() as any;
    strategyPrototype.params = /*smartBetaDefaultState*/ null;
    strategyPrototype.name = "Smart beta Strategy";
    strategyPrototype.entity_type = "SMART_BETA";
    let temp = deepClone(initStrategyBuildersState);
    temp.strategy = strategyPrototype;
    return temp;
  }, [strategiesAPI]);
  const initMacroRotation = useMemo(() => {
    let strategyPrototype = strategiesAPI.getPrototype() as any;
    strategyPrototype.params = /*macroRotationInitialState*/ null;
    strategyPrototype.name = "Macro rotation Strategy";
    strategyPrototype.entity_type = "SECTOR_ROTATION";

    let temp = deepClone(initStrategyBuildersState);
    temp.strategy = strategyPrototype;
    return temp;
  }, [strategiesAPI]);
  const initAdvanced = useMemo(() => {
    let strategyPrototype = strategiesAPI.getPrototype() as any;
    strategyPrototype.params = getDefaultStrategy();
    let productType = environment.get("account").product.productCode;
    if (productType === "ADVANCED" && strategyPrototype.id == null) {
      if (strategyPrototype?.params?.universe?.trimOutliers != null) {
        strategyPrototype.params.universe.trimOutliers = true;
      }
    }

    strategyPrototype.name = "Advanced Strategy";
    let temp = deepClone(initStrategyBuildersState);
    temp.strategy = strategyPrototype;

    return temp;
  }, [environment, strategiesAPI]);
  const [alpha_state, alpha_stateDispatcher] = useImmerReducer<
    StrategyBuilderState,
    any
  >(strategiesBuilderReducer, initAlpha);
  const [macroRoration_state, macroRoration_stateDispatcher] = useImmerReducer<
    StrategyBuilderState,
    any
  >(strategiesBuilderReducer, initMacroRotation);
  const [smartBeta_state, smartBeta_stateDispatcher] = useImmerReducer<
    StrategyBuilderState,
    any
  >(strategiesBuilderReducer, initSmartBeta);
  const [advanced_state, advanced_stateDispatcher] = useImmerReducer<
    StrategyBuilderState,
    any
  >(strategiesBuilderReducer, initAdvanced);
  //#endregion

  //! loading strategy types to nav
  //#region
  const optionsStrategyTypesForStrategyNav = useMemo(() => {
    let optionsStrategyTypes: any = [];
    if (configurationBuilder["formAlpha"]["enabled"] === true) {
      optionsStrategyTypes.push({
        label: t("Momentum_Alpha"),
        value: "alpha",
      });
    }
    if (configurationBuilder["formSmartBeta"]["enabled"] === true) {
      optionsStrategyTypes.push({
        label: t("Momentum_Smart_Beta"),
        value: "smartBeta",
      });
    }
    if (configurationBuilder["formMacroRotation"]["enabled"] === true) {
      optionsStrategyTypes.push({
        label: t("Momentum_Macro_Rotation"),
        value: "macroRotation",
      });
    }
    if (configurationBuilder["formAdvanced"]["enabled"] === true) {
      optionsStrategyTypes.push({
        label: t("Advanced_strategy"),
        value: "advanced",
      });
    }
    return optionsStrategyTypes;
  }, [configurationBuilder, t]);
  //#endregion -----------------------

  const { dispatch, on, remove } = useEventBus();

  //#region INSIGHTS STUFF
  const isInsightsEnabled = useMemo(() => {
    const configuration =
      environment.get("account").product?.configuration?.strategy_builder;
    const formType = {
      alpha: "form_alpha",
      advanced: "form_advanced",
      combined: "form_long_short",
      macroRotation: "form_macro_rotation",
      smartBeta: "form_smart_beta",
    };
    return (
      configuration?.[formType?.[decodedType]]?.resultTab?.insights ?? false
    );
  }, [decodedType, environment]);
  const [dashboards, setDashboards] = useState<Dashboards>({
    V1: undefined,
    V2: undefined,
    catalog: undefined,
    status: "loading",
  });

  const getDashboard = useCallback(async (url) => {
    try {
      return await axios.get(url);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getInisghtsV1 = useCallback(async () => {
    const endpointRoot = `${window.location.origin}/`;
    const url = endpointRoot + "static-assets/dashboard/main_insights.json";

    return getDashboard(url);
  }, [getDashboard]);

  const getInsightsV2 = useCallback(async () => {
    const dashboard_V2 = "insights_strategies_analytics.json";
    const endpointRoot = `${window.location.origin}/`;
    const url = endpointRoot + `static-assets/dashboard/${dashboard_V2}`;

    return getDashboard(url);
  }, [getDashboard]);

  const getCatalog = useCallback(async () => {
    const catalog = "main_catalog.json";
    const endpointRoot = `${window.location.origin}/`;
    const url = endpointRoot + `static-assets/dashboard/catalog/${catalog}`;

    return getDashboard(url);
  }, [getDashboard]);

  const getInsightsDashboards = useCallback(async () => {
    const requestV1 = getInisghtsV1();
    const requestV2 = getInsightsV2();
    const requestCatalog = getCatalog();

    try {
      setDashboards((current) => {
        return { ...current, status: "loading" };
      });
      const [dashboardV1, dashboardV2, dashboardCatalog]: any =
        await Promise.all([requestV1, requestV2, requestCatalog]);
      setDashboards({
        V1: dashboardV1?.data,
        V2: dashboardV2?.data,
        catalog: dashboardCatalog?.data,
        status: "success",
      });
    } catch (error) {
      console.log(error);
      setDashboards((current) => {
        return { ...current, status: "error" };
      });
    }
  }, [getCatalog, getInisghtsV1, getInsightsV2]);

  useEffect(() => {
    if (isInsightsEnabled) {
      getInsightsDashboards();
    }
  }, [getInsightsDashboards, isInsightsEnabled]);
  //#endregion

  const _dataGetStrategy = useCallback(
    (strategyId) => {
      return strategiesAPI.getById(strategyId).then(async (strategy) => {
        await collectTrackInfo(strategy);
        // const implementations = await getStrategyImplementations(strategyId);

        // if (implementations.length === 0) {
        //   strategy.tracked = false;
        // } else {
        //   strategy.tracked = true;
        // }

        // strategy.implementedBy = implementations;

        return runManager
          .getBenchmarkAndHedgingInstrument(strategy)
          .then((instruments) => {
            var data = {
              strategy: strategy,
              strategyInstrumentBenchmark: instruments?.instrumentBenchmark,
              strategyInstrumentHedging: instruments?.instrumentHedging,
            };
            return data;
          });
      });
    },
    [collectTrackInfo, runManager, strategiesAPI]
  );

  const loadStrategyFromJSON = useCallback(
    (json, strategyName) => {
      setStrategyPubStatus(undefined);
      const wrappedInPreference = {
        object: {
          name: strategyName,
          id: null,
          isReadOnly: true,
          params: clone(json),
          type: "PREFERENCE_INDEX",
          version: "2.0",
          entity_type: "BUILDER",
        },
        id: null,
        tags: {},
        tagsString: "{}",
        name: strategyName,
        ownerId: 35,
        type: "PREFERENCE_INDEX",
      };

      const strategyUI = strategiesAPI._decodeGetHelper(wrappedInPreference);
      collectTrackInfo(strategyUI);
      return runManager
        .getBenchmarkAndHedgingInstrument(strategyUI)
        .then((instruments) => {
          var data = {
            strategy: strategyUI,
            strategyInstrumentBenchmark: instruments?.instrumentBenchmark,
            strategyInstrumentHedging: instruments?.instrumentHedging,
          };
          return data;
        })
        .then((data) => {
          let _strategy = deepClone(data.strategy);
          if (_strategy.ownerId !== userID) {
            _strategy.isReadOnly = true;
          }
          const payload = {
            instrumentBenchmark: data.strategyInstrumentBenchmark,
            strategy: _strategy,
            instrumentHedging: data.strategyInstrumentHedging,
          };
          switch (decodedType) {
            case "alpha":
              alpha_stateDispatcher({
                type: "SET_STUFF_ON_ID_AVAILABLE",
                payload: payload,
              });
              break;
            case "macroRotation":
              macroRoration_stateDispatcher({
                type: "SET_STUFF_ON_ID_AVAILABLE",
                payload: payload,
              });
              break;
            case "smartBeta":
              smartBeta_stateDispatcher({
                type: "SET_STUFF_ON_ID_AVAILABLE",
                payload: payload,
              });
              break;
            case "advanced":
              advanced_stateDispatcher({
                type: "SET_STUFF_ON_ID_AVAILABLE",
                payload: payload,
              });
              break;
          }

          // Only Run action
          setWorkflow("s1");
        });
    },
    [
      advanced_stateDispatcher,
      alpha_stateDispatcher,
      collectTrackInfo,
      decodedType,
      macroRoration_stateDispatcher,
      runManager,
      smartBeta_stateDispatcher,
      strategiesAPI,
      userID,
    ]
  );

  const workflowBarAppearence = useCallback((action: "hide" | "show") => {
    const workflowNode = document.querySelector(".app__workflow");

    if (workflowNode) {
      if (action === "hide") {
        workflowNode.classList.add("hide");
      } else {
        workflowNode.classList.remove("hide");
      }
    }
  }, []);

  const handleSubscritionsOps = useCallback(async (strategy) => {
    if (strategy.id != null && strategy.isReadOnly) {
      // const subscribedList = await getSubscribedStrategies();
      let publicationStatus: "notPublic" | "subscribable" | "subscribed" =
        "notPublic";

      const ownershipToState = {
        subscribed: "subscribed",
        public: "subscribable",
        unknown: "notPublic",
      };

      publicationStatus = ownershipToState[strategy.ownership];

      setStrategyPubStatus(publicationStatus);
    } else {
      setStrategyPubStatus(undefined);
    }
  }, []);

  const resetEditorIsChanged = useCallback(() => {
    const editor = getCurrentEditor();

    if (editor) {
      const dispatcher = editor.getDispatcher();

      if (dispatcher) {
        dispatcher({ type: "SET_ASKBEFOREINSIGHTSREDIRECT", payload: false });
      }
    }
  }, [getCurrentEditor]);

  const _dataGet = useCallback(() => {
    // Used to hide run button while the strategy is not loaded
    setWorkflow("s4");
    if (params.id != null && params.id !== "new") {
      //! if strategy in URL
      return _dataGetStrategy(params.id).then(async (data) => {
        let _strategy = deepClone(data.strategy);
        if (_strategy.ownerId !== userID) {
          _strategy.isReadOnly = true;
        }

        handleSubscritionsOps(_strategy);
        resetEditorIsChanged();

        const payload = {
          instrumentBenchmark: data.strategyInstrumentBenchmark,
          strategy: _strategy,
          instrumentHedging: data.strategyInstrumentHedging,
        };
        switch (decodedType) {
          case "alpha":
            alpha_stateDispatcher({
              type: "SET_STUFF_ON_ID_AVAILABLE",
              payload: payload,
            });
            break;
          case "macroRotation":
            macroRoration_stateDispatcher({
              type: "SET_STUFF_ON_ID_AVAILABLE",
              payload: payload,
            });
            break;
          case "smartBeta":
            smartBeta_stateDispatcher({
              type: "SET_STUFF_ON_ID_AVAILABLE",
              payload: payload,
            });
            break;
          case "advanced":
            advanced_stateDispatcher({
              type: "SET_STUFF_ON_ID_AVAILABLE",
              payload: payload,
            });
            break;
        }

        setWorkflow("s0");
      });
    } else {
      collectTrackInfo(null);
      //! if no strategy in URL load a default strategy
      setWorkflow("s1");
    }
  }, [
    _dataGetStrategy,
    advanced_stateDispatcher,
    alpha_stateDispatcher,
    collectTrackInfo,
    decodedType,
    handleSubscritionsOps,
    macroRoration_stateDispatcher,
    params.id,
    resetEditorIsChanged,
    smartBeta_stateDispatcher,
    userID,
  ]);

  const getReducer = useCallback<any>(() => {
    let state: any = null;
    let dispatcher: any = null;

    //! get actual state
    switch (decodedType) {
      case "alpha":
        state = deepClone(alpha_state);
        dispatcher = alpha_stateDispatcher;
        break;
      case "macroRotation":
        state = deepClone(macroRoration_state);
        dispatcher = macroRoration_stateDispatcher;
        break;
      case "smartBeta":
        state = deepClone(smartBeta_state);
        dispatcher = smartBeta_stateDispatcher;
        break;
      case "advanced":
        state = deepClone(advanced_state);
        dispatcher = advanced_stateDispatcher;
        break;
      default:
        console.log("ERROR at run");
        return;
    }

    return { state, dispatcher };
  }, [
    advanced_state,
    advanced_stateDispatcher,
    alpha_state,
    alpha_stateDispatcher,
    decodedType,
    macroRoration_state,
    macroRoration_stateDispatcher,
    smartBeta_state,
    smartBeta_stateDispatcher,
  ]);

  //! on page load we have to check the params in the URL
  //#region
  useEffect(() => {
    switch (decodedType) {
      case "alpha":
        alpha_stateDispatcher({
          type: "SET_STRATEGY_TYPE",
          payload: decodedType,
        });
        break;
      case "macroRotation":
        macroRoration_stateDispatcher({
          type: "SET_STRATEGY_TYPE",
          payload: decodedType,
        });
        break;
      case "smartBeta":
        smartBeta_stateDispatcher({
          type: "SET_STRATEGY_TYPE",
          payload: decodedType,
        });
        break;
      case "advanced":
        advanced_stateDispatcher({
          type: "SET_STRATEGY_TYPE",
          payload: decodedType,
        });
        break;
    }

    if (params.id != null && params.id !== "new") {
      //! we have a strategy
      switch (decodedType) {
        case "alpha":
          alpha_stateDispatcher({
            type: "SET_STRATEGY_ID",
            payload: params.id,
          });
          break;
        case "macroRotation":
          macroRoration_stateDispatcher({
            type: "SET_STRATEGY_ID",
            payload: params.id,
          });
          break;
        case "smartBeta":
          smartBeta_stateDispatcher({
            type: "SET_STRATEGY_ID",
            payload: params.id,
          });
          break;
        case "advanced":
          advanced_stateDispatcher({
            type: "SET_STRATEGY_ID",
            payload: params.id,
          });
          break;
      }
      setWorkflow("s0");
    } else {
      //! no strategy! load the default one
      setWorkflow("s1");
    }
    _dataGet();
  }, [
    _dataGet,
    advanced_stateDispatcher,
    alpha_stateDispatcher,
    decodedType,
    macroRoration_stateDispatcher,
    params.id,
    smartBeta_stateDispatcher,
  ]);
  //#endregion

  const getStrategyParamsFromCaller = useCallback(() => {
    let strategyParams: any = null;
    let { dispatcher } = getReducer();
    //#region - get the state of the caller
    switch (decodedType) {
      case "alpha":
        strategyParams = editorAlphaRef.current.getState();
        break;
      case "macroRotation":
        strategyParams = editorMacroRotationRef.current.getState();
        break;
      case "smartBeta":
        strategyParams = editorSmartBeta.current.getState();
        break;
      case "advanced":
        strategyParams = editorAdvancedRef.current.getState();
        break;
      default:
        console.log("ERROR at run");
        return;
    }

    if (strategyParams) {
      //updating the state with the strategy params of the caller
      dispatcher({ type: "SET_STRATEGY_PARAMS", payload: strategyParams });
      return strategyParams;
    } else {
      throw new Error(`could not get state from ${decodedType} editor!`);
    }
  }, [decodedType, getReducer]);

  //! run
  //#region

  const run = useCallback(async () => {
    const reducer = getReducer();

    if (reducer != null) {
      const { state, dispatcher } = reducer;
      let strategyParams = getStrategyParamsFromCaller();

      //! ------------------------------------------------------
      //! ------------------------------------------------------
      //! on click run, we have to update the Results component.
      //! to do so, since the Results component is unmounted when state.strategyResult is null
      //! we set state.strategyResult then Results will be unmounted and then mounted with the updated input.
      if (state.strategyResult != null) {
        dispatcher({ type: "SET_STRATEGY_RESULT", payload: null });
      }
      //! ------------------------------------------------------
      //! ------------------------------------------------------

      setWorkflow("s4");

      try {
        const loadingBehaviours = {
          startNew: (obj) => {
            dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj);
            setWorkflow("s4");
          },
          update: (id, point) =>
            dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
          complete: (obj) => {
            dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj);
            let workflowState =
              params.id != null && params?.id !== "new" ? "s3" : "s2";
            setWorkflow(workflowState);
          },
        };
        let strategyToRun = deepClone(state.strategy);
        strategyToRun.params = strategyParams;

        // ******************* USAGE *******************
        var usage = window.App.usage;
        var info = {
          action: "RUN",
          actionParam: strategyToRun.id,
          function: "STRATEGY_BUILDER",
        };
        usage.record(info);
        // ******************* USAGE *******************

        runManager.set(strategyToRun, loadingBehaviours);
        try {
          await runManager.processStrategy();
        } catch (error: any) {
          if (error.message === "EMPTY_UNIVERSE") {
            const [channel, msg] = messageError(
              "Cannot run with universe empty!"
            );
            broadcast(channel as string, msg);

            dispatch(STRATEGIES_LOADER_EVENTS.clearProgresses);
            dispatch(STRATEGIES_LOADER_EVENTS.stopPreload);
            setWorkflow("s0");
            return;
          }
        }

        let strat = deepClone(strategyToRun);

        var context = {
          strategy: strat,
        };

        const benchmarkInfo = runManager.benchmarkInfo;
        var results: any = deepClone(context);

        results.strategyInstrumentBenchmark = benchmarkInfo;
        if (reducer?.state.strategyId == null) {
          //!strategy not saved
          setWorkflow("s2");
        } else {
          //!strategy saved
          setWorkflow("s3");
        }
        workflowBarAppearence("hide");
        reducer.dispatcher({ type: "SET_STRATEGY_RESULT", payload: results });
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    broadcast,
    dispatch,
    getReducer,
    getStrategyParamsFromCaller,
    params?.id,
    runManager,
    workflowBarAppearence,
  ]);
  //#endregion

  const clearWorkflow = useCallback(() => {
    var message = {
      from: "STRATEGIES",
      content: {
        actions: [],
      },
    };
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast]);

  //#region //!!reseting run results from Insights widget
  /*
    !! on redirect from a strategy to another one
    !! (when clicking on a table row in Insights widget )
    !! we want to hide the results widget (the results of a run)
    !! then in Insights widget (..../Advanced/Result/tabs/Insights/Insights.tsx)
    !! we dispatch the action "reset-results-on-redirect-from-insights"
  */
  const onRedirectFromInsights = useCallback(() => {
    const { state, dispatcher } = getReducer();
    if (state.strategyResult != null) {
      dispatcher({
        type: "RESET_STRATEGY_RESULT",
        payload: null,
      });
    }
  }, [getReducer]);

  //#endregion

  const navigator = useNavigate();

  //#region - ACTION COMPARE logic
  const [showCompareDialog, setShowCompareDialog] = useState(false);
  const [strategiesToCompare, setStrategiesToCompare] = useState<any[]>([]);
  const [loadingCompareData, setLoadingCompareData] = useState(false);
  const getDataToCompare = useCallback(async () => {
    setLoadingCompareData(true);
    setShowCompareDialog(true);
    let strategies = await strategiesAPI.getList(["type", "object"]);
    if (Array.isArray(strategies)) {
      strategies = strategies.filter(
        (item) => item.object.entity_type === "BUILDER"
      );
      strategies = strategies.map((strategy) => ({
        name: strategy.name,
        id: strategy.id,
        isSubscribed: strategy.isReadOnly,
        type: "Advanced Strategies",
      }));
      setStrategiesToCompare(strategies);
    }
    setLoadingCompareData(false);
  }, [strategiesAPI]);
  const redirectOnSelectStrategyToCompare = useCallback(
    (strategyToCompare) => {
      //**************** USAGE *******************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: params.id,
        function: "STRATEGY_COMPARE",
      };
      usage.record(info);
      //**************** USAGE *******************
      navigator(
        `/app/strategies/compare/${params.id}/${strategyToCompare}/run/`
      );
    },
    [navigator, params.id]
  );
  //#endregion

  //! change strategy type handler
  //#region
  const onChangeTab = useCallback(
    (e) => {
      setWorkflow("s1");
      setStrategyPubStatus(undefined);
      resetEditorIsChanged();
      const type = dp.strategyTypeToUriFragment(e.value); //encoding strategy type
      const uri = `/strategies/builder/${type}/`;
      (window as any).__page_navigate(uri);
    },
    [resetEditorIsChanged]
  );
  //#endregion

  const backToBuilder = useCallback(() => {
    onChangeTab({ value: params.type });
  }, [onChangeTab, params.type]);

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const onErrorCallback = useCallback(
    (error) => {
      let errorMessage = "";
      if (error && error.response.error === "EMPTY_UNIVERSE") {
        errorMessage = "Cannot save strategy with an empty universe!";
      } else if (error) {
        errorMessage = "Unknown error, please retry later.";
      }
      const [channel, msg] = messageError(errorMessage);
      broadcast(channel as string, msg);
    },
    [broadcast]
  );
  const onSeccessCallback = useCallback(
    (strategy, redirect = true) => {
      const [channel, msg] = messageSuccess(
        `The strategy ${strategy.name} has been saved`
      );
      broadcast(channel as string, msg);
      _dataGetStrategies(strategiesAPI).then((strategies) => {
        _dataGetStrategy(strategy.id).then((data) => {
          const { state, dispatcher } = getReducer();
          var newState = deepClone(state);
          newState.strategies = strategies;
          newState.strategy = data.strategy;
          newState.strategyId = data.strategy.id;
          newState.strategyInstrumentBenchmark =
            data.strategyInstrumentBenchmark;
          newState.strategyInstrumentHedging = data.strategyInstrumentHedging;
          const setState = (newState) => {
            dispatcher({ type: "RESET", payload: newState });
            const type = dp.strategyTypeToUriFragment(
              newState.strategy.entity_type
            ); //encoding strategy type
            if (redirect) {
              const uri = `/strategies/builder/${type}/${newState.strategyId}`;
              (window as any).__page_navigate(uri);
            }
          };
          setState(newState);
          setWorkflow("s3");
          // ******************* USAGE *******************
          var usage = window.App.usage;
          var info = {
            action: "SAVE",
            actionParam: strategy.id,
            function: "STRATEGY_BUILDER",
          };
          usage.record(info);
          // ******************* USAGE *******************
          setShowSaveDialog(false);
        });
      });
    },
    [_dataGetStrategy, broadcast, getReducer, strategiesAPI]
  );
  const createCallback = useCallback(
    (strategy) => {
      const strategyObject = {
        id: null,
        ownerId: strategy.ownerId,
        params: strategy.params,
        type: "PREFERENCE_INDEX",
        version: "2.0",
        name: strategy.name,
        entity_type: strategy.entity_type,
      };

      return strategiesAPI.create(strategyObject);
    },
    [strategiesAPI]
  );
  const updateCallback = useCallback(
    (strategy) => {
      const strategyObject = {
        id: strategy.id,
        ownerId: strategy.ownerId,
        params: strategy.params,
        type: "PREFERENCE_INDEX",
        version: "2.0",
        name: strategy.name,
        entity_type: strategy.entity_type,
        created: strategy.created,
      };

      return strategiesAPI.update(strategyObject);
    },
    [strategiesAPI]
  );
  const onSaveStrategyCallback = useCallback(
    (redirect?) => {
      const { state } = getReducer();
      const strategy = state.strategy;
      let _params = deepClone(strategy.params);
      // parsing params to check if universe exists
      _params = strategiesAPI.encode(_params);
      const isUniverseEmpty =
        !("relations" in _params?.universe?.search) &&
        !("filters" in _params?.universe?.search);
      if (isUniverseEmpty) {
        onErrorCallback("EMPTY_UNIVERSE");
      } else {
        updateCallback(strategy).then(
          (response) => {
            onSeccessCallback(response, redirect);
          }
          // },
          // (error) => onErrorCallback(error)
        );
      }
    },
    [
      getReducer,
      onErrorCallback,
      onSeccessCallback,
      strategiesAPI,
      updateCallback,
    ]
  );
  const onSaveAsStrategyCallback = useCallback(
    async (newName, redirect?) => {
      const { state } = getReducer();
      const strategy = state.strategy;
      strategy.name = newName;
      let _params = deepClone(strategy.params);
      // parsing params to check if universe exists
      _params = strategiesAPI.encode(_params);
      const isUniverseEmpty =
        !("relations" in _params?.universe?.search) &&
        !("filters" in _params?.universe?.search);
      if (isUniverseEmpty) {
        onErrorCallback("EMPTY_UNIVERSE");
      } else {
        // try {
        const response = await createCallback(strategy);
        onSeccessCallback(response, redirect);

        return response;
        // } catch (error) {
        //   onErrorCallback(error);
        // }
      }
    },
    [
      createCallback,
      getReducer,
      onErrorCallback,
      onSeccessCallback,
      strategiesAPI,
    ]
  );

  const resetWorkflow = useCallback(() => {
    if (params.id !== "new" && params.id != null) {
      setWorkflow("s0");
    } else {
      setWorkflow("s1");
    }

    const reducer = getReducer();
    reducer.dispatcher({ type: "SET_STRATEGY_RESULT", payload: null });
  }, [getReducer, params.id]);

  //! workflow bar handling
  //#region
  const [workflow, setWorkflow] = useState("s0");

  const manageAsyncWorkflow = useCallback(async () => {
    const actions: any = [];
    let action: any = null;
    let state: any = null;
    let stateDispatcher: any = null;
    switch (decodedType) {
      case "alpha":
        state = alpha_state;
        stateDispatcher = alpha_stateDispatcher;
        break;
      case "smartBeta":
        state = smartBeta_state;
        stateDispatcher = smartBeta_stateDispatcher;
        break;
      case "macroRotation":
        state = macroRoration_state;
        stateDispatcher = macroRoration_stateDispatcher;
        break;
      case "advanced":
        state = advanced_state;
        stateDispatcher = advanced_stateDispatcher;
        break;
      default:
        console.log("ERROR AT WORKFLOW HANDLER");
        return;
    }

    const editor = getCurrentEditor();
    let editorState = editor?.getState();
    const isChangedSomething = editorState?.askBeforeInsightsRedirect ?? false;

    switch (workflow) {
      case "s0": //*strategy selected
        //#region  appending Run to actions
        action = {
          componentJSX: (
            <li
              className="menu__item"
              onClick={() => {
                dispatch(STRATEGIES_LOADER_EVENTS.startPreload);
                run();
              }}
            >
              Run
            </li>
          ),
        };

        actions.push([action]);

        //#endregion

        //#region - appending Remove to actions
        if (!state?.strategy?.["isReadOnly"]) {
          action = [
            {
              componentJSX: (
                <Remove
                  item={state.strategy}
                  label={t("Delete")}
                  onRemoveDone={() => listenerActionRemove(state.strategyType)}
                />
              ),
            },
          ];

          actions.push(action);
        } else if (strategyPubStatus != null && !isChangedSomething) {
          if (strategyPubStatus === "subscribable") {
            action = [
              { componentJSX: <Subscribe strategyId={state.strategy.id} /> },
            ];

            actions.push(action);
          }

          if (strategyPubStatus === "subscribed") {
            action = [
              {
                componentJSX: (
                  <Unsubscribe
                    handleUnsubscribeFinish={backToBuilder}
                    strategyToUnsubscribe={deepClone(state.strategy)}
                  />
                ),
              },
            ];

            actions.push(action);
          }
        }

        //#endregion

        //#region - appending Compare to actions
        if (
          configurationBuilder.workflow != null &&
          configurationBuilder.workflow.comparison != null &&
          configurationBuilder.workflow.comparison.enabled &&
          (state.strategy != null || state.strategy?.id != null)
        ) {
          action = {
            componentJSX: (
              <li className="menu__item" onClick={() => getDataToCompare()}>
                Compare
              </li>
            ),
          };

          actions[0].push(action);
        }
        //#endregion

        break;
      case "s1": //* creating a strategy
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => run()}>
              Run
            </li>
          ),
        };

        actions.push([action]); //run

        break;
      case "s2": {
        //strategy (not saved) ran-> no compare, no delete
        //#region - appending Run to actions
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => run()}>
              Run
            </li>
          ),
        };

        actions.push([action]);
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => setShowSaveDialog(true)}>
              Save
            </li>
          ),
        };

        actions.push([action]);

        if (
          strategyPubStatus != null &&
          strategyPubStatus === "subscribable" &&
          !isChangedSomething
        ) {
          action = {
            componentJSX: <Subscribe strategyId={state.strategy.id} />,
          };

          actions[1].push(action);
        }

        if (enabledTrackSMS) {
          const trackInfo = await getTrakingParams(isChangedSomething);
          const rebalanceDict = {
            "05_DAYS": "WEEKLY",
            "20_DAYS": "MONTHLY",
            "60_DAYS": "QUARTERLY",
          };

          action = {
            componentJSX: (
              <TrackPortfolio
                trackInfo={trackInfo as any}
                strategyId={state.strategy.id}
                strategyName={state.strategy.name}
                runManager={runManager}
                strategyBenchmark={state.strategy.params.strategy.benchmark}
                strategyCurrency={state.strategy.params.strategy.currency}
                strategyGranularity={
                  rebalanceDict[state.strategy.params.strategy.rebalance]
                }
                isReadOnly={state.strategy.isReadOnly}
                save={onSaveStrategyCallback}
                saveAs={onSaveAsStrategyCallback}
              />
            ),
          };

          actions[1].push(action);
        }

        //#endregion

        action = {
          componentJSX: (
            <Export
              data={{
                strategy: state.strategy,
                runManager: runManager,
              }}
              logFunction={() => {
                // **************** USAGE ******************
                var usage = window.App.usage;
                var info = {
                  action: "EXPORT_STRATEGY",
                  actionParam: state.strategy?.id,
                  function: "STRATEGY_BUILDER",
                };
                usage.record(info);
                // **************** USAGE ******************
              }}
            />
          ),
        };

        actions[0].push(action);
        //#endregion

        //#region - appending ExportConstituents to actions
        action = {
          componentJSX: (
            <NewExportConstituents
              strategy={state.strategy}
              runManager={runManager}
            />
          ),
        };
        actions[0].push(action);

        //#endregion

        actions[0].push({
          componentJSX: (
            <ReportButton
              page={"strategyBuilder"}
              target={state.strategy}
              storage={runManager}
              rankingCache={null}
              strategyCache={{ data: state?.strategyResult?.strategyResult }}
              title={state.strategy?.name}
              usage={{
                function: "STRATEGY_BUILDER",
              }}
              widgets={null}
            />
          ),
        });

        //#region - appending Optimize to actions
        if (
          !state.strategy["isReadOnly"] &&
          configurationBuilder.workflow.optimize.enabled === true
        ) {
          action = {
            componentJSX: <OptimizeAction strategy={state.strategy} />,
          };
          actions[0].push(action);
        }
        //#endregion

        //#region - appending Track to actions
        //! Only Alpha, Smart beta and Macro rotation strategies can be tracked
        //! Advanced must not be tracked!
        if (
          configurationTracker.enabled &&
          configurationBuilder.workflow.track.enabled &&
          !state.strategy.isReadOnly
        ) {
          action = {
            componentJSX: (
              <TrackAction
                onTrackUntrack={(strategy) => {
                  var newState = deepClone(state);
                  newState.strategy = strategy;
                  stateDispatcher({ type: "RESET", payload: newState });
                  setWorkflow("s2");
                }}
                strategy={state.strategy}
                strategies={state.strategies}
                storage={runManager}
              />
            ),
          };
          actions[0].push(action);
        }
        //#endregion
        break;
      }
      case "s3": // ran
        //#region - appending Run to actions
        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => run()}>
              Run
            </li>
          ),
        };

        actions.push([action]);

        //#region - appending Compare to actions
        if (
          configurationBuilder.workflow != null &&
          configurationBuilder.workflow.comparison != null &&
          configurationBuilder.workflow.comparison.enabled &&
          (state.strategy != null || state.strategy?.id != null)
        ) {
          action = {
            componentJSX: (
              <li className="menu__item" onClick={() => getDataToCompare()}>
                Compare
              </li>
            ),
          };

          actions[0].push(action);
        }
        //#endregion

        //#endregion

        action = {
          componentJSX: (
            <li className="menu__item" onClick={() => setShowSaveDialog(true)}>
              Save
            </li>
          ),
        };

        if (actions?.[1] != null) {
          actions[1].push(action);
        } else {
          actions.push([action]);
        }

        if (strategyPubStatus === "subscribable" && !isChangedSomething) {
          action = {
            componentJSX: <Subscribe strategyId={state.strategy.id} />,
          };

          if (actions?.[1] != null) {
            actions[1].push(action);
          } else {
            actions.push([action]);
          }
        }

        //#region - appending Remove to actions
        if (!state?.strategy?.["isReadOnly"]) {
          action = {
            componentJSX: (
              <Remove
                item={state.strategy}
                label={t("Delete")}
                onRemoveDone={() => listenerActionRemove(state.strategyType)}
              />
            ),
          };

          if (actions?.[1] != null) {
            actions[1].push(action);
          } else {
            actions.push([action]);
          }
        } else if (strategyPubStatus === "subscribed" && !isChangedSomething) {
          action = {
            componentJSX: (
              <Unsubscribe
                strategyToUnsubscribe={deepClone(state.strategy)}
                handleUnsubscribeFinish={backToBuilder}
              />
            ),
          };

          if (actions?.[1] != null) {
            actions[1].push(action);
          } else {
            actions.push([action]);
          }
        }

        if (enabledTrackSMS) {
          const trackInfo = await getTrakingParams(isChangedSomething);
          const rebalanceDict = {
            "05_DAYS": "WEEKLY",
            "20_DAYS": "MONTHLY",
            "60_DAYS": "QUARTERLY",
          };

          action = {
            componentJSX: (
              <TrackPortfolio
                trackInfo={trackInfo as any}
                strategyId={state.strategy.id}
                strategyName={state.strategy.name}
                runManager={runManager}
                strategyBenchmark={state.strategy.params.strategy.benchmark}
                strategyCurrency={state.strategy.params.strategy.currency}
                strategyGranularity={
                  rebalanceDict[state.strategy.params.strategy.rebalance]
                }
                isReadOnly={state.strategy.isReadOnly}
                save={onSaveStrategyCallback}
                saveAs={onSaveAsStrategyCallback}
              />
            ),
          };
          if (actions?.[1] != null) {
            actions[1].push(action);
          } else {
            actions.push([action]);
          }
        }

        //#endregion

        action = {
          componentJSX: (
            <Export
              data={{
                strategy: state.strategy,
                runManager: runManager,
              }}
              logFunction={() => {
                // **************** USAGE ******************
                var usage = window.App.usage;
                var info = {
                  action: "EXPORT_STRATEGY",
                  actionParam: state.strategy?.id,
                  function: "STRATEGY_BUILDER",
                };
                usage.record(info);
                // **************** USAGE ******************
              }}
            />
          ),
        };

        actions[0].push(action);

        //#endregion

        //#region - appending ExportConstituents to actions
        action = {
          componentJSX: (
            <NewExportConstituents
              strategy={state.strategy}
              runManager={runManager}
            />
          ),
        };
        actions[0].push(action);
        //#endregion

        actions[0].push({
          componentJSX: (
            <ReportButton
              page={"strategyBuilder"}
              target={state.strategy}
              rankingCache={null}
              strategyCache={{ data: state?.strategyResult?.strategyResult }}
              usage={{
                function: "STRATEGY_BUILDER",
              }}
              title={state.strategy?.name}
              widgets={null}
              storage={runManager}
            />
          ),
        });
        //#region - appending Optimize to actions
        if (
          !state.strategy["isReadOnly"] &&
          configurationBuilder.workflow.optimize.enabled === true
        ) {
          action = {
            componentJSX: <OptimizeAction strategy={state.strategy} />,
          };
          actions[0].push(action);
        }
        //#endregion

        //#region - appending Track to actions
        //! Only Alpha, Smart beta and Macro rotation strategies can be tracked
        //! Advanced must not be tracked!
        if (
          configurationTracker.enabled &&
          configurationBuilder.workflow.track.enabled &&
          !state.strategy.isReadOnly
        ) {
          action = {
            componentJSX: (
              <TrackAction
                onTrackUntrack={(strategy) => {
                  var newState = deepClone(state);
                  newState.strategy = strategy;
                  stateDispatcher({ type: "RESET", payload: newState });
                  setWorkflow("s2");
                }}
                strategy={state.strategy}
                strategies={state.strategies}
                storage={runManager}
              />
            ),
          };
          actions[0].push(action);
        }
        //#endregion

        break;
      case "s4": // running -> no actions
        break;

      default:
        // Must never go here
        console.log("Unknown workflow", workflow);
        break;
    }

    var message = {
      from: "STRATEGIES",
      content: {
        actions: readyToRun ? actions : [],
      },
    };
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    decodedType,
    getCurrentEditor,
    workflow,
    readyToRun,
    broadcast,
    alpha_state,
    alpha_stateDispatcher,
    smartBeta_state,
    smartBeta_stateDispatcher,
    macroRoration_state,
    macroRoration_stateDispatcher,
    advanced_state,
    advanced_stateDispatcher,
    strategyPubStatus,
    configurationBuilder?.workflow,
    enabledTrackSMS,
    runManager,
    configurationTracker?.enabled,
    dispatch,
    run,
    t,
    backToBuilder,
    getDataToCompare,
    getTrakingParams,
    onSaveStrategyCallback,
    onSaveAsStrategyCallback,
  ]);

  useEffect(() => {
    manageAsyncWorkflow();

    return () => {
      clearWorkflow();
    };
  }, [clearWorkflow, manageAsyncWorkflow]);
  //#endregion

  const listenerChangeStrategy = useCallback(
    (
      obj: { isReadOnly: boolean; label: string; value: number },
      strategyType
    ) => {
      const type = dp.strategyTypeToUriFragment(strategyType); //encoding strategy type
      let uri = `/strategies/builder/${type}/`;

      if (obj.value != null) {
        uri += obj.value;
      }

      const id =
        params?.id != null && params?.id !== "new"
          ? parseInt(params.id)
          : undefined;

      if (obj.value === id) {
        (window as any).location.reload();
      } else {
        (window as any).__page_navigate(uri);
      }
    },
    [params.id]
  );

  const cleanupAfterError = useCallback(() => {
    const reducer = getReducer();

    if (reducer?.dispatcher != null) {
      reducer.dispatcher({ type: "SET_STRATEGY_RESULT", payload: null });
    }
  }, [getReducer]);

  const runFinishListener = useCallback(
    (e) => {
      const error = e?.detail;
      if (error === true) {
        setWorkflow("s1");
      }

      workflowBarAppearence("show");
    },
    [workflowBarAppearence]
  );

  useEffect(() => {
    on("run-finish", runFinishListener);

    return () => remove("run-finish", runFinishListener);
  }, [on, remove, runFinishListener]);

  useEffect(() => {
    return () => {
      workflowBarAppearence("show");
    };
  });

  const openDebugDialog = useCallback((debugDialogInput: any) => {
    setDialogDEbugParams(debugDialogInput);
  }, []);

  const closeDialogDebug = useCallback(() => {
    setDialogDEbugParams(undefined);
  }, []);

  //! CTRL + i handler ---------
  //#region
  const handleKeyPress = useCallback(
    (event) => {
      event.preventDefault();
      if (event.keyCode === 73 && event.ctrlKey === true) {
        let strategyParams = getStrategyParamsFromCaller();
        let temp: any = null;
        switch (decodedType) {
          case "alpha":
            temp = deepClone(alpha_state);
            break;
          case "smartBeta":
            temp = deepClone(smartBeta_state);
            break;
          case "macroRotation":
            temp = deepClone(macroRoration_state);
            break;
          case "advanced":
            temp = deepClone(advanced_state);
            break;
          default:
            console.log("ERROR AT CTRL+i");
            return;
        }
        let strategyFragments = {
          params: strategyParams,
          type: temp.strategyType,
        };

        openDebugDialog(strategyFragments);
      }
    },
    [
      advanced_state,
      alpha_state,
      decodedType,
      getStrategyParamsFromCaller,
      macroRoration_state,
      openDebugDialog,
      smartBeta_state,
    ]
  );
  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleKeyPress]);
  //#endregion

  const loadStrategyFromId = useCallback(
    (id) => {
      let url = `/app/strategies/builder/advanced/${id}`;
      onRedirectFromInsights();
      navigator(url);
    },
    [navigator, onRedirectFromInsights]
  );

  const overrrideStrategy = useCallback(
    (id: number) => {
      let url = `/app/strategies/builder/advanced/${id}`;
      onRedirectFromInsights();

      let currentId: any = window.location.pathname.split("/");
      currentId = currentId?.[5] ?? undefined;

      const hardRefreshState = currentId != null && parseInt(currentId) === id;

      if (hardRefreshState) {
        navigator(url);
        const location = (window as any).location;
        location.reload();
      } else {
        navigator(url);
      }
    },
    [navigator, onRedirectFromInsights]
  );

  const insightsStateRef = useRef<InsightsState>({
    selectedInsightsVersion: "V1",
    usedIn: "STRATEGIES",
    currentTab: "US",
    selectedStrategy: undefined,
    insightsV1State: {
      multiFactorTableSorter: { field: "name", rev: false },
      singleFactorTableSorter: { field: "name", rev: false },
      trStrategiesTableSorter: { field: "name", rev: false },
      bestByTableSorter: { field: "year_10_perf_H", rev: false },
      bestByActiveCheckbox: [],
      bestByRadioValue: "10_years_return",
      scrollToTargetId: undefined,
      handleRowClick: loadStrategyFromId,
      overideStrategy: overrrideStrategy,
    },
    insightsV2State: {
      activeFilters: undefined,
      selectedColumn: "type",
      sorter: { field: "name", rev: false },
      hidedFields: [],
      handleRowClick: loadStrategyFromJSON,
    },
    catalogState: {
      activeFilters: [],
      sorter: { field: "name", rev: false },
      handleRowClick: loadStrategyFromId,
      overideStrategy: overrrideStrategy,
    },
  });

  const updateInsightsState = useCallback((value: InsightsState) => {
    insightsStateRef.current = value;
  }, []);

  const getInsightsState = useCallback(() => {
    return insightsStateRef.current;
  }, []);

  const insightsContext = useMemo(() => {
    return {
      state: insightsStateRef.current,
      get: getInsightsState,
      set: updateInsightsState,
    };
  }, [getInsightsState, updateInsightsState]);

  //#region - EDITORS
  const advancedEditor = useMemo(() => {
    if (
      decodedType === "advanced" &&
      advanced_state.strategy != null &&
      advanced_state?.strategyType != null
    ) {
      return (
        <InsightsContext.Provider value={insightsContext}>
          <Advanced
            resetWorkflow={resetWorkflow}
            loadedStrategyId={params?.id ?? undefined}
            ref={editorAdvancedRef}
            cleanup={cleanupAfterError}
            value={advanced_state?.strategy?.params}
            configurationBuilder={configurationBuilder}
            runManager={runManager}
            runResults={advanced_state.strategyResult}
            strategyName={advanced_state?.strategy?.name}
            readOnlyStrategy={advanced_state?.strategy?.isReadOnly}
            strategyNavigatorProperties={{
              strategyType: advanced_state?.strategyType,
              listenerChangeStrategy: (obj) => {
                listenerChangeStrategy(obj, advanced_state.strategyType);
                // set result to null when change strategy
                advanced_stateDispatcher({
                  type: "SET_STRATEGY_RESULT",
                  payload: null,
                });
              },
            }}
            setIsRunReady={setIsReadyToRun}
            dashboard={dashboards}
          />
        </InsightsContext.Provider>
      );
    } else {
      return null;
    }
  }, [
    advanced_state.strategy,
    advanced_state.strategyResult,
    advanced_state.strategyType,
    advanced_stateDispatcher,
    cleanupAfterError,
    configurationBuilder,
    dashboards,
    decodedType,
    insightsContext,
    listenerChangeStrategy,
    params?.id,
    resetWorkflow,
    runManager,
  ]);

  // useEffect(() => console.log("alpha_state", alpha_state), [alpha_state]);
  const alphaEditor = useMemo(() => {
    // if (
    //   decodedType === "alpha" &&
    //   alpha_state.strategy != null &&
    //   alpha_state?.strategyType != null
    // ) {
    return (
      <InsightsContext.Provider value={insightsContext}>
        <EditorAlpha
          ref={editorAlphaRef}
          cleanup={cleanupAfterError}
          runManager={runManager}
          key={Date.now()}
          strategyNavigatorProperties={{
            strategyType: decodedType,
            listenerChangeStrategy: (obj) => {
              listenerChangeStrategy(obj, decodedType);
              // set result to null when change strategy
              alpha_stateDispatcher({
                type: "SET_STRATEGY_RESULT",
                payload: null,
              });
            },
          }}
          runResults={alpha_state.strategyResult}
          dashboard={dashboards}
          isReadOnlyStrategy={alpha_state.strategy.isReadOnly}
          strategyParams={alpha_state?.strategy.params}
          strategyName={alpha_state.strategy.name ?? ""}
          strategyType={alpha_state.strategy.type}
        />
      </InsightsContext.Provider>
    );
    // } else {
    //   return null;
    // }
  }, [
    alpha_state.strategy.isReadOnly,
    alpha_state.strategy.name,
    alpha_state.strategy.params,
    alpha_state.strategy.type,
    alpha_state.strategyResult,
    alpha_stateDispatcher,
    cleanupAfterError,
    dashboards,
    decodedType,
    insightsContext,
    listenerChangeStrategy,
    runManager,
  ]);

  const macroRotationEditor = useMemo(() => {
    if (
      decodedType === "macroRotation" &&
      macroRoration_state.strategy != null &&
      macroRoration_state?.strategyType != null
    ) {
      return (
        <InsightsContext.Provider value={insightsContext}>
          <EditorMacroRotation
            ref={editorMacroRotationRef}
            cleanup={cleanupAfterError}
            runManager={runManager}
            key={Date.now()}
            strategy={macroRoration_state?.strategy}
            strategyNavigatorProperties={{
              strategyType: macroRoration_state?.strategyType,
              listenerChangeStrategy: (obj) => {
                listenerChangeStrategy(obj, macroRoration_state.strategyType);
                // set result to null when change strategy
                macroRoration_stateDispatcher({
                  type: "SET_STRATEGY_RESULT",
                  payload: null,
                });
              },
            }}
            runResults={macroRoration_state.strategyResult}
            dashboard={dashboards}
          />
        </InsightsContext.Provider>
      );
    } else {
      return null;
    }
  }, [
    cleanupAfterError,
    dashboards,
    decodedType,
    insightsContext,
    listenerChangeStrategy,
    macroRoration_state.strategy,
    macroRoration_state.strategyResult,
    macroRoration_state.strategyType,
    macroRoration_stateDispatcher,
    runManager,
  ]);

  const smartBetaEditor = useMemo(() => {
    if (
      decodedType === "smartBeta" &&
      smartBeta_state.strategy != null &&
      smartBeta_state?.strategyType != null
    ) {
      return (
        <InsightsContext.Provider value={insightsContext}>
          <SmartBeta
            ref={editorSmartBeta}
            cleanup={cleanupAfterError}
            runManager={runManager}
            key={Date.now()}
            strategy={smartBeta_state?.strategy}
            strategyNavigatorProperties={{
              strategyType: smartBeta_state?.strategyType,
              listenerChangeStrategy: (obj) => {
                listenerChangeStrategy(obj, smartBeta_state.strategyType);
                // set result to null when change strategy
                smartBeta_stateDispatcher({
                  type: "SET_STRATEGY_RESULT",
                  payload: null,
                });
              },
            }}
            runResults={smartBeta_state.strategyResult}
            dashboard={dashboards}
          />
        </InsightsContext.Provider>
      );
    } else {
      return null;
    }
  }, [
    cleanupAfterError,
    dashboards,
    decodedType,
    insightsContext,
    listenerChangeStrategy,
    runManager,
    smartBeta_state.strategy,
    smartBeta_state.strategyResult,
    smartBeta_state.strategyType,
    smartBeta_stateDispatcher,
  ]);
  //#endregion

  const handleSaveErrors = useCallback((err, updateErr) => {
    let errorMessage = "The strategy cannot be saved";
    if (err && err.response.error === "OBJECT_ALREADY_EXISTING") {
      errorMessage = "A strategy with this name already exists.";
    }

    updateErr(errorMessage);
  }, []);

  const dialogSaveComponent = useMemo(() => {
    const { state } = getReducer();
    const strategy = state.strategy;
    return (
      <DialogSaveComponent
        item={{ name: strategy.name }}
        dialogType={"Strategy"}
        onSave={
          !strategy.isReadOnly && strategy.id != null
            ? onSaveStrategyCallback
            : null
        }
        onSaveAs={onSaveAsStrategyCallback}
        onRename={null}
        hide={() => setShowSaveDialog(false)}
        handleError={handleSaveErrors}
      />
    );
  }, [
    getReducer,
    handleSaveErrors,
    onSaveAsStrategyCallback,
    onSaveStrategyCallback,
  ]);
  return (
    <LoaderContext.Provider
      value={{
        availableEvents: STRATEGIES_LOADER_EVENTS,
        on,
        remove,
        dispatch,
      }}
    >
      <DialogDebug
        strategyFragments={dialogDebugParams}
        closeModal={closeDialogDebug}
      />
      {showSaveDialog && dialogSaveComponent}
      {showCompareDialog && (
        <PanelForLists
          showDialog={showCompareDialog}
          closeDialog={() => setShowCompareDialog(false)}
          list={strategiesToCompare}
          isLoadingData={loadingCompareData}
          sectionsTag={["Advanced Strategies"]}
          selectItem={redirectOnSelectStrategyToCompare}
          headerTitle={"Select a strategy"}
        />
      )}
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
      >
        {decodedType && (
          <TypeNavigator
            tabs={optionsStrategyTypesForStrategyNav}
            onChangeStrategy={onChangeTab}
            value={decodedType}
          />
        )}
        {decodedType === "alpha" && alphaEditor}
        {macroRotationEditor}
        {smartBetaEditor}
        {advancedEditor}
        <Loader />
      </Box>
    </LoaderContext.Provider>
  );
}

const listenerActionRemove = (strategyType) => {
  const type = dp.strategyTypeToUriFragment(strategyType);
  const uri = `/strategies/builder/${type}/`;
  (window as any).__page_navigate(uri);
};

const _dataGetStrategies = (apiStrategy: Strategies) => {
  return apiStrategy.getList(["object.entity_type"]).then((response: any) => {
    if (response) {
      var strategies = {
        advanced: response
          .filter(dp.filterBy("entity_type", "BUILDER"))
          .sort(dp.sortByCaseInsensitive("name")),
        alpha: response
          .filter(dp.filterBy("entity_type", "ALPHA"))
          .sort(dp.sortByCaseInsensitive("name")),
        macroRotation: response
          .filter(dp.filterBy("entity_type", "SECTOR_ROTATION"))
          .sort(dp.sortByCaseInsensitive("name")),
        smartBeta: response
          .filter(dp.filterBy("entity_type", "SMART_BETA"))
          .sort(dp.sortByCaseInsensitive("name")),
      };
      return strategies;
    }

    return {
      advanced: [],
      alpha: [],
      macroRotation: [],
      smartBeta: [],
    };
  });
};
