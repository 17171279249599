import {
  Box,
  CircularProgress,
  MenuItem as MuiMenuItem,
  Typography,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import {
  MouseEventHandler,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  NavLink,
  NavigateOptions,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Lists } from "../../api/compute/Lists";
import { useEnvironment } from "../../hooks/useEnvironment";
import { MenuItemConfiguration } from "./_menu";

type MenuItemProps = {
  handleClick: (item: MenuItemConfiguration) => MouseEventHandler;
  item: MenuItemConfiguration;
};

export function MenuItem({ handleClick, item }: MenuItemProps) {
  const location = useLocation();
  const navRef = useRef(null);
  const navigate = useNavigate();
  const handleActive = useCallback(
    (isActive: boolean, item: MenuItemConfiguration) => {
      let isActiveFiltered = isActive;
      if (item.excluded != null) {
        for (const excluded of item.excluded) {
          // TODO check if it is possible to get the root /app
          if (location.pathname.startsWith(`/app/${excluded}`)) {
            isActiveFiltered = false;
            break;
          }
        }
      }
      if (isActiveFiltered) {
        return "menu__item menu__item--selected";
      } else {
        return "menu__item";
      }
    },
    [location.pathname]
  );
  const tippyRef = useRef<any>(null);
  const liRef = useRef<any>(null);
  const createList = useCallback((type: "BASKET" | "PORTFOLIO") => {
    const _type = type === "BASKET" ? "basket" : "portfolio";
    window.location.href = `/app/analysis/lists/create/${_type}/`;
  }, []);

  const hideTippy = useCallback(() => liRef?.current?._tippy?.hide(), []);

  const analyse = useCallback(
    (item) => {
      const url = `analysis/lists/${item.id}/analyze/`;
      hideTippy();
      let options: NavigateOptions = { replace: true };
      navigate(url, options);
    },
    [hideTippy, navigate]
  );
  const compare = useCallback(
    (item) => {
      const url = `analysis/lists/${item.id}/analyze/compare/`;
      hideTippy();
      navigate(url);
    },
    [hideTippy, navigate]
  );
  const rank = useCallback(
    (item) => {
      const url = `analysis/lists/${item.id}/analyze/ranking/`;
      hideTippy();
      navigate(url);
    },
    [hideTippy, navigate]
  );
  const edit = useCallback(
    (item) => {
      const url = `analysis/lists/${item.id}/edit/`;
      hideTippy();
      navigate(url);
    },
    [hideTippy, navigate]
  );

  const mainContentRef = useRef<any>(null);

  const content = useMemo(() => {
    if (item.module !== "analysis_list") {
      return (
        <li>
          <NavLink
            ref={navRef}
            to={item.uri}
            className={({ isActive }) => handleActive(isActive, item)}
            title={item.description}
            onClick={handleClick(item)}
          >
            {item.menu_label}
          </NavLink>
        </li>
      );
    } else {
      return (
        <li ref={liRef}>
          <Tippy
            placement="bottom"
            ref={tippyRef}
            delay={500}
            allowHTML
            reference={liRef.current}
            theme="security-tooltip"
            interactive
            onHide={() => {
              mainContentRef?.current?.resetLists();
            }}
            content={
              <MainContent
                ref={mainContentRef}
                analyse={analyse}
                compare={compare}
                rank={rank}
                edit={edit}
                createList={createList}
              />
            }
          >
            <NavLink
              ref={navRef}
              to={item.uri}
              className={({ isActive }) => handleActive(isActive, item)}
              title={item.description}
              onClick={handleClick(item)}
            >
              {item.menu_label}
            </NavLink>
          </Tippy>

          {/* <FastAccess renderRef={navRef}></FastAccess> */}
        </li>
      );
    }
  }, [
    analyse,
    compare,
    createList,
    edit,
    handleActive,
    handleClick,
    item,
    rank,
  ]);

  return content;
}

const sortByName = (list) => {
  list.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    return 0;
  });

  return list;
};

type MainContentType = {
  analyse: Function;
  compare: Function;
  rank: Function;
  edit: Function;
  createList: Function;
};
const MainContent = forwardRef(
  ({ analyse, compare, edit, rank, createList }: MainContentType, ref) => {
    const [baskets, setBaskets] = useState<null | any[]>(null);
    const [portfolios, setPortfolios] = useState<null | any[]>(null);
    const environment = useEnvironment();
    const setup = useMemo(() => environment.get("setup"), [environment]);
    const listAPI = useMemo(() => {
      return new Lists(setup);
    }, [setup]);

    useImperativeHandle(
      ref,
      () => ({
        resetLists: () => {
          setBaskets(null);
          setPortfolios(null);
        },
      }),
      []
    );

    const getUserCollections = useCallback(async () => {
      const response = await listAPI.newGet();

      return response;
    }, [listAPI]);

    const getLists = useCallback(async () => {
      if (baskets == null || portfolios == null) {
        const collections = await getUserCollections();

        const _baskets = collections.filter((item) => item.type === "BASKET");
        const _portfolios = collections.filter(
          (item) => item.type === "PORTFOLIO"
        );

        setBaskets(sortByName(_baskets));
        setPortfolios(sortByName(_portfolios));
      }
    }, [baskets, getUserCollections, portfolios]);

    const basketContent = useMemo(() => {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          borderRadius={"4px"}
          maxHeight={"50vh"}
        >
          {baskets && baskets.length === 0 && "no basket"}
          {baskets &&
            baskets.length > 0 &&
            baskets.map((item: any, index) => (
              <Tippy
                key={index}
                placement="right-start"
                interactive
                allowHTML
                theme="security-tooltip"
                maxWidth={"auto"}
                content={
                  <Box display={"flex"} flexDirection={"column"}>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => analyse(item)}
                    >
                      <Typography>Analyze</Typography>
                    </MuiMenuItem>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => compare(item)}
                    >
                      <Typography>Compare </Typography>
                    </MuiMenuItem>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => rank(item)}
                    >
                      <Typography>Rank</Typography>
                    </MuiMenuItem>
                    {!item.isReadOnly && (
                      <MuiMenuItem
                        sx={{
                          justifyContent: "start !important",
                          alignItems: "center !important",
                          display: "flex !important",
                        }}
                        onClick={() => edit(item)}
                      >
                        <Typography>Edit</Typography>
                      </MuiMenuItem>
                    )}
                  </Box>
                }
              >
                <MuiMenuItem
                  onClick={() => analyse(item)}
                  sx={{
                    justifyContent: "start !important",
                    alignItems: "center !important",
                    display: "flex !important",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {item.name}
                    {item.isReadOnly ? (
                      <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                    ) : null}
                  </Typography>
                </MuiMenuItem>
              </Tippy>
            ))}
        </Box>
      );
    }, [analyse, baskets, compare, edit, rank]);

    const portfolioContent = useMemo(() => {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          borderRadius={"4px"}
          maxHeight={"50vh"}
        >
          {portfolios && portfolios.length === 0 && "no portfolio"}
          {portfolios &&
            portfolios.length > 0 &&
            portfolios.map((item: any, index) => (
              <Tippy
                key={index}
                placement="right-start"
                interactive
                allowHTML
                theme="security-tooltip"
                maxWidth={"auto"}
                content={
                  <Box display={"flex"} flexDirection={"column"}>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => analyse(item)}
                    >
                      <Typography>Analyze</Typography>
                    </MuiMenuItem>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => compare(item)}
                    >
                      <Typography>Compare </Typography>
                    </MuiMenuItem>
                    <MuiMenuItem
                      sx={{
                        justifyContent: "start !important",
                        alignItems: "center !important",
                        display: "flex !important",
                      }}
                      onClick={() => rank(item)}
                    >
                      <Typography>Rank</Typography>
                    </MuiMenuItem>
                    {!item.isReadOnly && (
                      <MuiMenuItem
                        sx={{
                          justifyContent: "start !important",
                          alignItems: "center !important",
                          display: "flex !important",
                        }}
                        onClick={() => edit(item)}
                      >
                        <Typography>Edit</Typography>
                      </MuiMenuItem>
                    )}
                  </Box>
                }
              >
                <MuiMenuItem
                  onClick={() => analyse(item)}
                  sx={{
                    justifyContent: "start !important",
                    alignItems: "center !important",
                    display: "flex !important",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {item.name}
                    {item.isReadOnly ? (
                      <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                    ) : null}
                  </Typography>
                </MuiMenuItem>
              </Tippy>
            ))}
        </Box>
      );
    }, [analyse, compare, edit, portfolios, rank]);

    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Tippy
          theme="security-tooltip"
          allowHTML
          interactive
          trigger="click"
          onShow={getLists as any}
          content={
            baskets == null ? (
              <Box
                height={"100%"}
                p={2}
                width={"100%"}
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={"0.9vw"} />
              </Box>
            ) : (
              basketContent
            )
          }
          placement="right-start"
        >
          <MuiMenuItem
            sx={{
              justifyContent: "start !important",
              alignItems: "center !important",
              display: "flex !important",
            }}
          >
            <Typography>Baskets</Typography>
          </MuiMenuItem>
        </Tippy>
        <Tippy
          theme="security-tooltip"
          allowHTML
          interactive
          onShow={getLists as any}
          trigger="click"
          content={
            portfolios == null ? (
              <Box
                height={"100%"}
                p={2}
                width={"100%"}
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={"0.9vw"} />
              </Box>
            ) : (
              portfolioContent
            )
          }
          placement="right-start"
        >
          <MuiMenuItem
            sx={{
              justifyContent: "start !important",
              alignItems: "center !important",
              display: "flex !important",
            }}
          >
            <Typography>Portfolios</Typography>
          </MuiMenuItem>
        </Tippy>
        <MuiMenuItem
          sx={{
            bgcolor: "#2a7092 !important",
            color: "white",
            justifyContent: "start !important",
            alignItems: "center !important",
            display: "flex !important",
          }}
          onClick={() => createList("BASKET")}
        >
          <Typography>New Basket</Typography>
        </MuiMenuItem>
        <MuiMenuItem
          sx={{
            bgcolor: "#2a7092 !important",
            color: "white",
            justifyContent: "start !important",
            alignItems: "center !important",
            display: "flex !important",
          }}
          onClick={() => createList("PORTFOLIO")}
        >
          <Typography>New Portfolio</Typography>
        </MuiMenuItem>
      </Box>
    );
  }
);
