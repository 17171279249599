import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "../Modal/Modal";
import { Spinner } from "../Spinner/Spinner";
import styles from "./PanelForLists.module.scss";

type PanelForListsProps = {
  showDialog: boolean;
  closeDialog: () => void;
  list: { name: string; id: number; type: string; isSubscribed: boolean }[];
  itemsPerColumn?: number;
  //? Must be an array with the keys of type list.type ex if list = [{...list, type: portfolio}] the sectionsTag prop must be ["porftolio"]
  sectionsTag: string[];
  selectItem: (ids) => void;
  headerTitle: string;
  isLoadingData?: boolean;
  multipleChoice?: boolean;
  initialValue?: any[];
  closeIcon?: boolean;
};

type ListSectionProps = {
  columns: PanelForListsProps["list"][][];
  sectionTitle: string;
  selectItem: (id) => void;
  multipleChoice?: boolean;
  checkedIds?: string[];
};

export const PanelForLists = forwardRef(
  (
    {
      showDialog,
      closeDialog,
      itemsPerColumn = 20,
      sectionsTag, //? Must be an array with the keys of type list.type ex if list = [{...list, type: portfolio}] the sectionsTag prop must be ["porftolio"]
      list,
      selectItem,
      headerTitle,
      isLoadingData,
      initialValue,
      multipleChoice = false,
      closeIcon = false,
    }: PanelForListsProps,
    ref
  ) => {
    const modalHeaderConfig = useMemo(
      () => ({
        headerContent: <p style={{ fontSize: "0.8vw" }}>{headerTitle}</p>,
        hasBorder: false,
        hasBackground: false,
      }),
      [headerTitle]
    );
    const [checkedIds, updateCheckedIds] = useState<string[]>(
      initialValue ?? []
    );

    // Backdoor used only for widget that uses dojo and need to render the widget multiple time by hand and not
    // using react render logic
    useImperativeHandle(ref, () => {
      return {
        set: (ids) => {
          updateCheckedIds(ids);
        },
      };
    });

    const handleItemSelection = useCallback(
      (value) => {
        if (multipleChoice === false) {
          selectItem(value);
          closeDialog();
        } else if (multipleChoice === true) {
          updateCheckedIds(value);
        }
      },
      [closeDialog, multipleChoice, selectItem]
    );

    const onClickApply = useCallback(() => {
      if (multipleChoice === true) {
        selectItem(checkedIds);
        closeDialog();
      }
    }, [checkedIds, closeDialog, multipleChoice, selectItem]);

    const getColumnsConfiguration = useCallback(
      (list: PanelForListsProps["list"]) => {
        let aValue,
          bValue: any = null;
        list.sort((a, b) => {
          aValue = a.name.toLowerCase();
          bValue = b.name.toLowerCase();

          if (aValue > bValue) {
            return 1;
          } else if (aValue < bValue) {
            return -1;
          }

          return 0;
        });
        const maxColLenTreshold = itemsPerColumn;
        const columnsMap = {};

        for (let i = 0; i < sectionsTag.length; i++) {
          const tag = sectionsTag[i];
          columnsMap[tag] = [];

          const sectionItems = list.filter((item) => item.type === tag) ?? [];

          for (let j = 0; j < sectionItems.length; j += maxColLenTreshold) {
            if (j < maxColLenTreshold * 2) {
              const chunk = sectionItems.slice(j, j + maxColLenTreshold);

              columnsMap[tag].push(chunk);
            } else {
              const chunk = sectionItems.slice(j);

              columnsMap[tag].push(chunk);
              break;
            }
          }
        }

        return columnsMap;
      },
      [itemsPerColumn, sectionsTag]
    );

    const config = useMemo(
      () => getColumnsConfiguration(list),
      [list, getColumnsConfiguration]
    );

    const modalButtons = useMemo(
      () =>
        multipleChoice === true
          ? [
              {
                name: "Apply",
                callback: onClickApply,
              },
              { name: "Cancel", callback: closeDialog, variant: "cancel" },
            ]
          : undefined,
      [closeDialog, multipleChoice, onClickApply]
    );

    return showDialog ? (
      <Modal
        customCss={{
          width: "fit-content",
          maxWidth: "90vw",
          position: "static",
          transform: "translate(0, -50%)",
          margin: "auto",
          marginTop: "50vh",
          minWidth: 0,
        }}
        bodyCustomClass={styles.modalContentOverride}
        headerConfig={modalHeaderConfig}
        onClose={closeDialog}
        buttonsEnalbed={multipleChoice === true}
        buttons={modalButtons}
        closeIcon={closeIcon}
      >
        {!isLoadingData ? (
          <Box display={"flex"} flex={1} minHeight={0} minWidth={0}>
            {sectionsTag.map((tag) =>
              config?.[tag]?.length ?? 0 ? (
                <ListSection
                  multipleChoice={multipleChoice}
                  selectItem={handleItemSelection}
                  key={uuidv4()}
                  checkedIds={checkedIds}
                  sectionTitle={tag}
                  columns={config?.[tag] ?? []}
                />
              ) : (
                <Fragment key={uuidv4()}></Fragment>
              )
            )}
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Spinner />
          </Box>
        )}
      </Modal>
    ) : (
      <></>
    );
  }
);

const ListSection = ({
  columns,
  sectionTitle,
  selectItem,
  multipleChoice = false,
  checkedIds = [],
}: ListSectionProps) => {
  const handleSelection = useCallback(
    (id) => {
      if (multipleChoice === false) {
        selectItem(id);
      }
    },
    [multipleChoice, selectItem]
  );

  const onCheckboxChange = useCallback(
    (checked, id) => {
      let updatedIds = [...checkedIds];
      if (checked) {
        updatedIds.push(id);
      } else {
        updatedIds = updatedIds.filter((element) => element !== id);
      }

      selectItem(updatedIds);
    },
    [checkedIds, selectItem]
  );

  const getColumnsMaxWidth = useCallback((sectionsNumber?: number) => {
    const vw = window.innerWidth;

    // dialog max width is 90vh that corresponds to the 90% of the viewport
    const maxDialogWidth = vw * 0.9;
    // subtract from the dialog width the padding and spaces
    const dialogWidth = maxDialogWidth - 60;
    let sectionWidth = dialogWidth;
    if (sectionsNumber != null && sectionsNumber > 1) {
      // get the width of the section that will host the columns
      sectionWidth = sectionWidth / sectionsNumber;
    }

    const maxColAvailable = 3;
    const colMaxWidth = sectionWidth / maxColAvailable;

    return colMaxWidth;
  }, []);

  const applyMaxWidthOnCol = useCallback(
    (colRef) => {
      const numberOfSections = 2;
      const maxWidth = getColumnsMaxWidth(numberOfSections);

      if (maxWidth && colRef) {
        colRef.style.maxWidth = `${maxWidth}px`;
      }
    },
    [getColumnsMaxWidth]
  );

  return (
    <Box component={"fieldset"} className={styles.outlinedBox} boxShadow={1}>
      <legend
        style={{
          color: "#2a7090",
          fontWeight: "bold",
          marginLeft: "-10px",
        }}
      >
        {sectionTitle}
      </legend>
      <ul className={styles.listInDialog}>
        {columns.map((col: any) => (
          <Box
            key={uuidv4()}
            ref={applyMaxWidthOnCol}
            className={styles.column}
            display={"flex"}
            flexDirection={"column"}
          >
            {col.length ? (
              col.map((item) => (
                <li
                  onClick={() => handleSelection(item.id)}
                  title={item.name}
                  key={uuidv4()}
                  className={
                    multipleChoice ? styles.listItemCheckboxes : styles.listItem
                  }
                >
                  {!multipleChoice ? (
                    <span className={styles.listVoice}>
                      {item.isSubscribed && (
                        <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                      )}{" "}
                      {item.name}
                    </span>
                  ) : (
                    <span className={styles.listVoice}>
                      <FormControlLabel
                        onChange={(e, checked) =>
                          onCheckboxChange(checked, item.id)
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={checkedIds.includes(item.id)}
                          />
                        }
                        label={item.name}
                      />
                      {item.isSubscribed && (
                        <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                      )}
                    </span>
                  )}
                </li>
              ))
            ) : (
              <Typography>Empty</Typography>
            )}
          </Box>
        ))}
      </ul>
    </Box>
  );
};
