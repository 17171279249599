/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/InputManager
 * @summary Retrieves data to build report (also ranking data)
 *
 */
import {
  extractForDataIngestion,
  extractSymbols,
} from "../../../api/compute/commons";
import { Instruments } from "../../../api/compute/Instruments";
import { Lists } from "../../../api/compute/Lists";
import { Peers } from "../../../api/compute/Peers";
import { Rankings } from "../../../api/compute/Rankings";
import { Strategies } from "../../../api/compute/Strategies";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { Utils } from "../../../api/compute/Utils";
import { Report } from "../../../api/report/Report";
import { deepClone } from "../../../deepClone";
import { httpAll } from "../../../httpAll";
import { List, Property, Strategy } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { sectionProperties } from "../configuration/sectionProperties";
import { CollectAlerts } from "./input/CollectAlerts";
import { CollectLists } from "./input/CollectLists";
import { CollectPeers } from "./input/CollectPeers";
import { CollectScreenings } from "./input/CollectScreenings";
import { CollectStrategies } from "./input/CollectStrategies";
import { CollectSystematic } from "./input/CollectSystematic";
import { Peer } from "./Peer";
import { RequestsManager } from "./RequestManager";
import { Section, WysiwygState } from "./Types";
import {
  getStrategyFinalDate,
  getStrategyLaunchDate,
  getSymbolWeight,
  prepareParams,
} from "./Utils";

export type RankingCache = {
  rankingParams: {
    againstList: {
      positionsIndex: Record<string, number>;
    };
  };
  rankingResult: {
    data: any;
    index: any;
  };
};

export type RankingProps = {
  constraints?: any;
  date: number;
  list?: List;
  pagination: {
    page: number;
    rows: number;
  };
  sortBy: {
    descending: boolean;
    property: string;
  };
};

function httpError(error, object?) {
  // Deal with cancelled promises (from makeCancelable)
  if (error.isCanceled) {
    console.log("Request cancelled");
    return error;
  }

  if (error instanceof Error) {
    if (object != null) {
      object.then((result) => console.log("Request cancelled", result));
    }
    return error;
  }

  var status = error["status"];
  var responseStatus = error["response"]["status"];

  if (status == null) {
    status = responseStatus;
  }

  // request canceled
  if (status == null && error["message"] === "Request canceled") {
    return {
      message: "Request canceled",
    };
  }
}

export class InputManager {
  readonly HOLDING_CHART_THRESHOLD = 300;
  readonly HOLDING_TABLE_THRESHOLD = 3000;

  apiInstruments: Instruments;
  apiLists: Lists;
  apiPeers: Peers;
  apiRankings: Rankings;
  apiReport: Report;
  apiStrategies: Strategies;
  apiSystematicProducts: SystematicProducts;
  apiUtils: Utils;

  readonly instrumentTypes = {
    dimension: "type",
    segments: ["Commodity", "Currency", "ETF", "Index", "Sector", "Stock"],
  } as const;

  hasStrategyLaunchDate; // Starting / launch date set by user
  hasStrategyFinalDate; // End / final date set by user

  rankingCache;
  rankingType;
  sections: Section[]; // template sections
  strategyLaunchDate; // Starting / launch date computed from strategy run
  strategyFinalDate; // End / final date computed from strategy run
  template;
  userPreference;

  wysiwygState: WysiwygState;

  // contains current computing data
  _computing: any;

  constructor(
    protected environment: AppEnvironment,
    {
      rankingCache,
      sections,
      template,
      userPreference,
      wysiwygState,
    }: {
      rankingCache: any;
      sections: Section[];
      template: any;
      userPreference: any;
      wysiwygState: WysiwygState;
    }
  ) {
    this.apiInstruments = environment.http.instruments;
    this.apiLists = environment.http.lists;
    this.apiPeers = environment.http.peers;
    this.apiRankings = environment.http.rankings;
    this.apiReport = environment.http.reports;
    this.apiStrategies = environment.http.strategies;
    this.apiSystematicProducts = environment.http.systematicProducts;
    this.apiUtils = environment.http.utils;

    this.rankingCache = rankingCache;
    this.sections = sections;
    this.template = template;
    this.userPreference = userPreference;
    this.wysiwygState = wysiwygState;
  }

  /**
   * Retrieve all data, starting point
   *
   * It does get the primary data (like the strategy).
   *
   * Chain: retrieve -> _compute -> _retrieve (original retrieve method)
   *
   */
  async retrieve() {
    const environment = this.environment;
    const wysiwygState = this.wysiwygState;

    let collector;
    switch (wysiwygState.targetType) {
      case "LIST": {
        if ("id" in wysiwygState.target && wysiwygState.target != null) {
          wysiwygState.target = await this.apiLists.get(wysiwygState.target.id);
        }
        collector = new CollectLists(environment, wysiwygState);
        break;
      }
      case "PEER": {
        collector = new CollectPeers(environment, wysiwygState);
        break;
      }
      case "SCREENING": {
        collector = new CollectScreenings(environment, wysiwygState);
        break;
      }
      case "COMBINED_STRATEGY":
      case "STRATEGY": {
        // TODO rename launch and final to start and end
        const launchDate = getStrategyLaunchDate(this.template);
        const finalDate = getStrategyFinalDate(this.template);
        collector = new CollectStrategies(
          environment,
          wysiwygState,
          launchDate,
          finalDate
        );
        break;
      }
      case "SYSTEMATIC": {
        // TODO rename launch and final to start and end
        const launchDate = getStrategyLaunchDate(this.template);
        const finalDate = getStrategyFinalDate(this.template);
        collector = new CollectSystematic(
          environment,
          wysiwygState,
          launchDate,
          finalDate
        );
        break;
      }
      case "ALERTS": {
        collector = new CollectAlerts(environment, wysiwygState);
      }
    }

    if (collector == null) {
      throw new Error("Unrecognized type " + wysiwygState.targetType);
    }

    const result = await collector.retrieve();
    this._computing = result.computing;
    this.strategyFinalDate = result.strategyFinalDate;
    this.strategyLaunchDate = result.strategyLaunchDate;
    this.hasStrategyFinalDate = result.hasStrategyFinalDate;
    this.hasStrategyLaunchDate = result.hasStrategyLaunchDate;

    const instruments = this._computing["instruments"];
    return this._retrieve(result.response, result.responseCompute, instruments);
  }

  /**
   * Retrieve additional requests
   *
   * @param {object} response Primary objects (strategy, portfolio,
   *      universe)
   * @param {object} responseCompute Computed data from primary objects
   */
  async _retrieve(response, responseCompute, instruments) {
    const requests = {};
    const sections = this.sections;
    const userPreference = this.userPreference;
    const wysiwygState = this.wysiwygState;

    //
    // Requests manager
    // New concept (2020-12-04) to manage Peer requests in a separate
    // class
    let requestsManager = new RequestsManager(this.environment);
    let requestManagerPeer: Peer | undefined;

    if (wysiwygState?.storage?.peers != null) {
      requestManagerPeer = requestsManager["peer"];
    }

    // if (wysiwygState?.storage?.peers != null) {
    //     requestsManager = new Peer(wysiwygState.storage.peers);
    // }

    const instrumentTypes = this.instrumentTypes;
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      // id|type|more
      const key = section.id + "|" + section.type;

      switch (section.type) {
        // #region ------------------------------------------ Common
        case "REPORT_LIST_ALERTS_TABLE": {
          const listType = section.content.listType;
          const timeframe = section.content.timeframe;
          const userCollectionIds = await this.apiLists._getFilter(listType);
          const subscribed = await this.apiLists._getSubscribed();
          const listIds = [
            ...userCollectionIds,
            ...subscribed.map((subscription) => subscription.id),
          ];
          const analytics = {
            today: [
              "name",
              "TCR",
              "upgrades",
              "downgrades",
              "ABnewHigh",
              "CDnewLow",
              "type",
            ],
            lastWeek: [
              "name",
              "TCR",
              "upgrades_W",
              "downgrades_W",
              "ABnewHigh",
              "CDnewLow",
              "type",
            ],
            lastMonth: [
              "name",
              "TCR",
              "upgrades_M",
              "downgrades_M",
              "ABnewHigh",
              "CDnewLow",
              "type",
            ],
          };

          const response = await this.apiLists.portfolioFetch(
            listIds,
            analytics[timeframe]
          );

          // This filter is needed because the get subscribed return the ids of all protfolios and baskets together
          const lists = response.filter((item) => item.type === listType);
          const data: {
            name: string;
            TCR: number;
            upgrades: number;
            downgrades: number;
            moversUp: number;
            moversDown: number;
          }[] = [];

          const keyByTimeFrame = {
            today: {
              upgrades: "upgrades",
              downgrades: "downgrades",
            },
            lastWeek: {
              upgrades: "upgrades_W",
              downgrades: "downgrades_W",
            },
            lastMonth: {
              upgrades: "upgrades_M",
              downgrades: "downgrades_M",
            },
          };

          for (const row of lists) {
            const newRow: any = {
              name: row.name,
              TCR: row.TCR,
              moversUp: row.ABnewHigh,
              moversDown: row.CDnewLow,
            };

            const upgradesKey = keyByTimeFrame[timeframe]["upgrades"];
            const downgradesKey = keyByTimeFrame[timeframe]["downgrades"];

            newRow["upgrades"] = row[upgradesKey];
            newRow["downgrades"] = row[downgradesKey];

            data.push(newRow);
          }

          requests[key] = data;

          break;
        }
        // #endregion ----------------------------------------------
        // #region ------------------------------------------ Common
        case "REPORT_COMMON_ALLOCATION": {
          if (
            section != null &&
            section.content != null &&
            section.presentation != null
          ) {
            if (
              section.presentation["first"] ||
              section.presentation["second"]
            ) {
              if (section.presentation["first"]) {
                const params = {
                  constituents: getSymbolWeight(instruments),
                  level: "Country",
                  // sectorLevel: "1 Industry",
                };

                if (
                  wysiwygState.targetType === "COMBINED_STRATEGY" ||
                  wysiwygState.targetType === "STRATEGY" ||
                  wysiwygState.targetType === "SYSTEMATIC"
                ) {
                  params["metricType"] = "abHistoryPerc";
                }

                switch (section.content["firstType"]) {
                  case "INDUSTRY": {
                    // params.countryLevel = "WWW";
                    params.level = "1 Industry";

                    break;
                  }
                  case "SECTOR": {
                    // params.countryLevel = "WWW";
                    params.level = "3 Sector";

                    break;
                  }
                  case "MARKET": {
                    // params.countryLevel = "Country";
                    params.level = "Country";

                    break;
                  }
                  case "REGION": {
                    // params.countryLevel = "Region";
                    params.level = "Region";

                    break;
                  }
                  case "AREA": {
                    // params.countryLevel = "Area";
                    params.level = "Area";

                    break;
                  }
                  case "INV_REGION": {
                    params.level = "etfgeo";

                    break;
                  }

                  case "ASSET_CLASS": {
                    params.level = "AssetClass";

                    break;
                  }

                  case "SPECIALTY": {
                    params.level = "Specialty";

                    break;
                  }
                  case "THEME": {
                    params.level = "Theme";

                    break;
                  }
                  default:
                    console.log(
                      "Unrecognized type " + section.content["firstType"]
                    );
                    break;
                }

                requests[key + "|first"] = params.constituents.length
                  ? this.apiPeers.weightAnalytics(
                      params.constituents,
                      params.level
                    )
                  : null;

                //OLD PEER WEIGHTS
                // requests[key + "|first"] =
                //     this.apiPeers.weight(params);
              }
              if (section.presentation["second"]) {
                const params = {
                  constituents: getSymbolWeight(instruments),
                  level: "1 Industry",
                };

                if (
                  wysiwygState.targetType === "COMBINED_STRATEGY" ||
                  wysiwygState.targetType === "STRATEGY" ||
                  wysiwygState.targetType === "SYSTEMATIC"
                ) {
                  params["metricType"] = "abHistoryPerc";
                }

                switch (section.content["secondType"]) {
                  case "INDUSTRY": {
                    params.level = "1 Industry";

                    break;
                  }
                  case "SECTOR": {
                    params.level = "3 Sector";

                    break;
                  }
                  case "MARKET": {
                    params.level = "Country";

                    break;
                  }
                  case "REGION": {
                    params.level = "Region";

                    break;
                  }
                  case "AREA": {
                    params.level = "Area";

                    break;
                  }
                  case "INV_REGION": {
                    params.level = "etfgeo";

                    break;
                  }

                  case "ASSET_CLASS": {
                    params.level = "AssetClass";

                    break;
                  }

                  case "SPECIALTY": {
                    params.level = "Specialty";

                    break;
                  }

                  case "THEME": {
                    params.level = "Theme";

                    break;
                  }

                  default:
                    console.log(
                      "Unrecognized type " + section.content["secondType"]
                    );
                    break;
                }

                requests[key + "|second"] = params.constituents.length
                  ? this.apiPeers.weightAnalytics(
                      params.constituents,
                      params.level
                    )
                  : null;

                //OLD PEERS WEIGHT
                // requests[key + "|second"] =
                //     this.apiPeers.weight(params);
              }
            }
          }

          break;
        }
        case "REPORT_COMMON_COVER":
          // Do nothing
          break;
        case "REPORT_COMMON_DISCLAIMER": {
          if (userPreference == null || userPreference.disclaimer == null) {
            requests[key] = this.apiReport.disclaimer();
          }

          break;
        }
        case "REPORT_COMMON_HEADER_1":
          // Do nothing
          break;
        case "REPORT_COMMON_PAGE_BREAK":
          // Do nothing
          break;
        case "REPORT_COMMON_PARAGRAPH":
          // Do nothing
          break;
        case "REPORT_COMMON_SECURITY_CHART": {
          const preparedParams = prepareParams(
            wysiwygState,
            section,
            responseCompute,
            this._computing,
            this.hasStrategyFinalDate,
            this.strategyFinalDate
          );
          requests[key + "|columns"] = preparedParams["columns"];
          requests[key] = this.collectRanking(
            preparedParams.params,
            preparedParams.properties
          ).then((response) => {
            let totalCount = response.dataTotalCount;
            // Check if there is top enabled, and the result is
            // at least top
            if (
              section.content.top.isEnabled &&
              section.content.top.content >= response.data.length
            ) {
              totalCount = response.data.length;
            }
            if (totalCount > this.HOLDING_CHART_THRESHOLD) {
              return Promise.reject({
                error: "MAXIMUM_CHART_HOLDINGS_EXCEEDED",
                actual: totalCount,
                maximum: this.HOLDING_CHART_THRESHOLD,
              });
            }

            let svgPositions = {};
            for (let j = 0; j < response.data.length; j++) {
              let value = response.data[j];
              svgPositions[value.symbol] = this.apiInstruments.chartAsPromise({
                symbol: value.symbol,
              });
            }

            return httpAll({
              positions: response.data,
              svg: httpAll(svgPositions),
            });
          });

          break;
        }
        case "REPORT_COMMON_SECURITY_TABLE": {
          let preparedParams = prepareParams(
            wysiwygState,
            section,
            responseCompute,
            this._computing,
            this.hasStrategyFinalDate,
            this.strategyFinalDate
          );
          requests[key + "|columns"] = preparedParams["columns"];
          requests[key] = this.collectRanking(
            preparedParams.params,
            preparedParams.properties
          ).then((response) => {
            let totalCount = response.dataTotalCount;
            // Check if there is top enabled, and the result is
            // at least top
            if (
              section.content.top.isEnabled &&
              section.content.top.content >= response.data.length
            ) {
              totalCount = response.data.length;
            }

            if (totalCount > this.HOLDING_TABLE_THRESHOLD) {
              return Promise.reject({
                error: "MAXIMUM_HOLDINGS_EXCEEDED",
                actual: totalCount,
                maximum: this.HOLDING_TABLE_THRESHOLD,
              });
            }
            return response;
          });

          break;
        }
        case "REPORT_COMMON_SPACING":
          // Do nothing
          break;
        case "REPORT_COMMON_TITLE":
          // Do nothing
          break;
        // #endregion ----------------------------------------------

        // #region -------------------------------------------- Peer
        case "REPORT_PEER_DISPERSION": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getDispersion(
            wysiwygState.target, // the peer
            section,
            wysiwygState.target.type
          );

          break;
        }
        case "REPORT_DISPERSION_TCR_TABLE": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = await requestManagerPeer.getDispersionRating(
            wysiwygState.target, // the peer
            section
          );

          break;
        }

        case "REPORT_DISPERSION_RATIO_TABLE": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }

          requests[key] = await requestManagerPeer.getDispersionRatio(
            wysiwygState.target, // the peer
            section
          );

          break;
        }

        case "REPORT_DISPERSION_OVERVIEW":
          if (requestsManager == null) {
            throw new Error("Wrong configuration, RequestManager is null");
          }

          const assetType = requestsManager.getAssetType(
            wysiwygState["target"] ?? {}
          );
          const isListMultiType = assetType > 1;
          const isEtfOnly = !isListMultiType && assetType[0] === "ETF";

          const data = await requestsManager.getPortfolioDispersion(
            wysiwygState,
            section
          );

          requests[key] = {
            dispersion: data,
            type: isEtfOnly ? "ETF" : "Stock",
          };

          break;

        case "REPORT_DISPERSION_BY_CHART":
        case "REPORT_DISPERSION_BY_SECTORS": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = await requestManagerPeer.getDispersionBySegment(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_DISPERSION_CHILDREN": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = await requestManagerPeer.getDispersionChildren(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_TCR": {
          break;
        }
        case "REPORT_CUSTOMIZABLE_PEER_TABLE": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }

          requests[key] = requestManagerPeer.getPeerUsingSegment(
            wysiwygState.target, // the peer
            section
          );
          break;
        }
        case "REPORT_PEER_TCR_FOCUS": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }

          requests[key] = requestManagerPeer.getTcrFocus(
            wysiwygState.target, // the peer
            section
          );
          break;
        }
        case "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }

          requests[key] = requestManagerPeer.getUpgradesDowngradesFocus(
            wysiwygState.target, // the peer
            section
          );
          break;
        }
        case "REPORT_PEER_AB_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getAbChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHAT_AB_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getSectorAbChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_OVERVIEW": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getOverview(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHAT_OVERVIEW": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getSectorOverview(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_TCR_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getTcrChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }

        case "REPORT_PEER_WHAT_TCR_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getSectorTcrChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_UPGRADES_DOWNGRADES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getPeerUpgradesDowngrades(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getSectorUpgradesDowngrades(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHERE_AB_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getMarketAbChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHERE_OVERVIEW": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getMarketOverview(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHERE_TCR_CHANGES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getMarketTcrChanges(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES": {
          if (requestManagerPeer == null) {
            throw new Error(
              "Wrong configuration, needs Peer class (check type = PEER and wysiwygState.storage.peers)"
            );
          }
          requests[key] = requestManagerPeer.getMarketUpgradesDowngrades(
            wysiwygState.target, // the peer
            section
          );

          break;
        }
        // #endregion ----------------------------------------------

        // #region --------------------------------------- Portfolio
        case "REPORT_BASKET_DISPERSION_BY_CHART": {
          if (requestsManager == null) {
            throw new Error("Wrong configuration, RequestManager is null");
          }

          const assetType = requestsManager.getAssetType(
            wysiwygState["target"] ?? {}
          );
          const isListMultiType = assetType > 1;
          const isEtfOnly = !isListMultiType && assetType[0] === "ETF";

          const data = await requestsManager.getDispersionForLists(
            wysiwygState,
            section
          );

          requests[key] = {
            data: requestsManager.getDispersionSerieForChart(data, section),
            isEtfOnly,
          };

          break;
        }
        case "REPORT_BASKET_DISPERSION_BY_SECTORS": {
          if (requestsManager == null) {
            throw new Error("Wrong configuration, RequestManager is null");
          }

          const assetType = requestsManager.getAssetType(
            wysiwygState["target"] ?? {}
          );
          const isListMultiType = assetType > 1;
          const isEtfOnly = !isListMultiType && assetType[0] === "ETF";

          const data = await requestsManager.getDispersionForLists(
            wysiwygState,
            section
          );

          requests[key] = { data, isEtfOnly };

          break;
        }
        case "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN": {
          const value = section.content["dataCross"];
          const params = {
            constituents: getSymbolWeight(instruments),
            countryLevel: "Country",
            normalize: wysiwygState.target.type === "PORTFOLIO" ? false : true,
            sectorLevel: "1 Industry",
          };

          switch (value) {
            case "MARKET_INDUSTRY": {
              break;
            }
            case "MARKET_SECTOR": {
              params.sectorLevel = "3 Sector";

              break;
            }
            case "REGION_INDUSTRY": {
              params.countryLevel = "Region";

              break;
            }
            case "REGION_SECTOR": {
              params.countryLevel = "Region";
              params.sectorLevel = "3 Sector";

              break;
            }
            default:
              console.log("Unrecognized type " + value);
              break;
          }

          // OLD PEER WEIGHT
          // requests[key] = this.apiPeers.weight(params);
          requests[key] = this.apiPeers.weightAnalytics(params.constituents, [
            params.countryLevel,
            params.sectorLevel,
          ]);

          break;
        }
        case "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW": {
          let api = this.apiInstruments;
          const params = {
            filters: [
              instrumentTypes,
              {
                dimension: "symbol",
                segments: extractSymbols(instruments),
              },
            ],
            ranges: [
              {
                dimension: "lhigh",
                segments: [
                  {
                    max: null,
                    min: 20,
                  },
                ],
              },
            ],
            sort: [
              {
                dimension: "lhl",
                rev: true,
              },
            ],
            page: {
              page: 1,
              rows: section.content["numberOfItems"],
            },
          };
          api = this.apiInstruments;

          const properties =
            sectionProperties["trendrating-report/portfolio/new-high-new-low"]
              .properties;

          if (section.presentation.newHigh) {
            requests[key + "|high"] = api.filterAndFetch(
              params,
              "security",
              properties
            );
          }

          if (section.presentation.newLow) {
            params.ranges[0].dimension = "llow";
            params.sort[0].rev = false;
            requests[key + "|low"] = api.filterAndFetch(
              params,
              "security",
              properties
            );
          }

          break;
        }
        case "REPORT_PORTFOLIO_PERFORMER": {
          const value = section.presentation;

          const performerType = section.content.type;
          const symbols = extractSymbols(instruments);

          const properties =
            sectionProperties[
              "trendrating-report/portfolio/positive-negative-performer"
            ].properties;

          const params = {
            filters: [
              instrumentTypes,
              {
                dimension: "symbol",
                segments: symbols,
              },
            ],
            ranges: [
              {
                dimension: "rc",
                segments:
                  performerType === "POSITIVE"
                    ? [
                        {
                          max: 1,
                          min: 1,
                        },
                        {
                          max: 2,
                          min: 2,
                        },
                      ]
                    : [
                        {
                          max: -1,
                          min: -1,
                        },
                        {
                          max: -2,
                          min: -2,
                        },
                      ],
              },
            ],
            sort: [
              {
                dimension: "pw",
                rev: performerType === "POSITIVE",
              },
            ],
            page: {
              page: 1,
              rows: section.content["numberOfItems"],
            },
          };

          for (const timeInterval in value) {
            if (value[timeInterval]) {
              switch (timeInterval) {
                case "05_days": {
                  params.sort[0].dimension = "pw";

                  break;
                }
                case "20_days": {
                  params.sort[0].dimension = "pm";

                  break;
                }
                case "60_days": {
                  params.sort[0].dimension = "pq";

                  break;
                }
                default:
                  console.log("Unrecognized timeInterval " + timeInterval);
                  break;
              }
              requests[key + "|" + timeInterval] =
                this.apiInstruments.filterAndFetch(
                  params,
                  "security",
                  properties
                );
            }
          }

          break;
        }
        case "REPORT_PORTFOLIO_RATING_CHANGE": {
          const params = {
            filters: [
              instrumentTypes,
              {
                dimension: "symbol",
                segments: extractSymbols(instruments),
              },
            ],
            ranges: [
              {
                dimension: "direction",
                segments: [
                  {
                    max: 4,
                    min: 0,
                  },
                ],
              },
            ],
            sort: [
              {
                dimension: "lr",
                rev: false,
              },
            ],
            page: {
              page: 1,
              rows: section.content["numberOfItems"],
            },
          };

          if (section.content["daysFromToday"] != null) {
            params.ranges.push({
              dimension: "lr",
              segments: [
                {
                  max: section.content["daysFromToday"],
                  min: 0,
                },
              ],
            });
          }

          const properties =
            sectionProperties["trendrating-report/portfolio/rating-change"]
              .properties;

          if (section.presentation["upgrade"]) {
            requests[key + "|upgrade"] = this.apiInstruments.filterAndFetch(
              params,
              "security",
              properties
            );
          }

          if (section.presentation["downgrade"]) {
            params.ranges[0].segments[0].max = 0;
            params.ranges[0].segments[0].min = -4;
            requests[key + "|downgrade"] = this.apiInstruments.filterAndFetch(
              params,
              "security",
              properties
            );
          }

          break;
        }
        case "REPORT_PORTFOLIO_TCR": {
          if (
            section != null &&
            section.content != null &&
            section.presentation != null
          ) {
            if (section.presentation["alert"]) {
              // upgrades
              const paramsUpgrade = {
                filters: [
                  {
                    dimension: "type",
                    segments: [
                      "Commodity",
                      "Currency",
                      "ETF",
                      "Index",
                      "Sector",
                      "Stock",
                    ],
                  },
                  {
                    dimension: "symbol",
                    segments: extractSymbols(instruments),
                  },
                ],
                ranges: [
                  {
                    dimension: "direction",
                    segments: [
                      {
                        max: 4,
                        min: 0,
                      },
                    ],
                  },
                  {
                    dimension: "lr",
                    segments: [
                      {
                        max: 19,
                        min: 0,
                      },
                    ],
                  },
                ],
                page: {
                  page: 1,
                  rows: 99999,
                },
              };

              const properties =
                sectionProperties["trendrating-report/portfolio/alert"]
                  .properties;

              requests[key + "|alert_upgrade"] =
                this.apiInstruments.filterAndFetch(
                  paramsUpgrade,
                  "security",
                  properties
                );
              // downgrades
              const paramsDowngrade = {
                filters: [
                  {
                    dimension: "symbol",
                    segments: extractSymbols(instruments),
                  },
                ],
                ranges: [
                  {
                    dimension: "direction",
                    segments: [
                      {
                        max: 0,
                        min: -4,
                      },
                    ],
                  },
                  {
                    dimension: "lr",
                    segments: [
                      {
                        max: 19,
                        min: 0,
                      },
                    ],
                  },
                ],
                page: {
                  page: 1,
                  rows: 99999,
                },
              };
              requests[key + "|alert_downgrade"] =
                this.apiInstruments.filterAndFetch(
                  paramsDowngrade,
                  "security",
                  properties
                );
            }

            if (
              section.presentation["marketAllocation"] ||
              section.presentation["sectorAllocation"]
            ) {
              if (section.presentation["marketAllocation"]) {
                const params = {
                  constituents: getSymbolWeight(instruments),
                  level: "Country",
                  normalize:
                    wysiwygState.target.type === "PORTFOLIO" ? false : true,
                };

                switch (section.content["marketAllocation"]) {
                  case "INDUSTRY": {
                    params.level = "1 Industry";

                    break;
                  }
                  case "SECTOR": {
                    params.level = "3 Sector";

                    break;
                  }
                  case "MARKET": {
                    params.level = "Country";

                    break;
                  }
                  case "REGION": {
                    params.level = "Region";

                    break;
                  }
                  case "AREA": {
                    params.level = "Area";

                    break;
                  }
                  case "INV_REGION": {
                    params.level = "etfgeo";

                    break;
                  }
                  case "ASSET_CLASS": {
                    params.level = "AssetClass";

                    break;
                  }
                  case "SPECIALTY": {
                    params.level = "Specialty";

                    break;
                  }
                  case "THEME": {
                    params.level = "Theme";

                    break;
                  }
                  default:
                    console.log(
                      "Unrecognized type " + section.content["marketAllocation"]
                    );
                    break;
                }

                //OLD PEERS WEIGHT
                // requests[key + "|marketAllocation"] =
                //     this.apiPeers.weight(params);

                // l1 and l2 is te naming used by the old peer wieghts
                const l1 = this.apiPeers.weightAnalytics(
                  params.constituents,
                  params.level
                );
                // const l1 = this.apiPeers.weightAnalytics(
                //     params.constituents,
                //     params.level
                // );
                requests[key + "|marketAllocation"] = Promise.all([l1]);
              }
              if (section.presentation["sectorAllocation"]) {
                const params = {
                  constituents: getSymbolWeight(instruments),
                  level: "1 Industry",
                  normalize:
                    wysiwygState.target.type === "PORTFOLIO" ? false : true,
                };

                switch (section.content["sectorAllocation"]) {
                  case "INDUSTRY": {
                    params.level = "1 Industry";

                    break;
                  }
                  case "SECTOR": {
                    params.level = "3 Sector";

                    break;
                  }
                  case "MARKET": {
                    params.level = "Country";

                    break;
                  }
                  case "REGION": {
                    params.level = "Region";

                    break;
                  }
                  case "AREA": {
                    params.level = "Area";

                    break;
                  }
                  case "INV_REGION": {
                    params.level = "etfgeo";

                    break;
                  }
                  case "ASSET_CLASS": {
                    params.level = "AssetClass";

                    break;
                  }
                  case "SPECIALTY": {
                    params.level = "Specialty";

                    break;
                  }

                  case "THEME": {
                    params.level = "Theme";

                    break;
                  }
                  default:
                    console.log(
                      "Unrecognized type " + section.content["sectorAllocation"]
                    );
                    break;
                }

                // requests[key + "|sectorAllocation"] =
                //     this.apiPeers.weight(params);

                const l2 = this.apiPeers.weightAnalytics(
                  params.constituents,
                  params.level
                );
                // const l1 = this.apiPeers.weightAnalytics(
                //     params.constituents,
                //     params.sectorLevel
                // );
                requests[key + "|sectorAllocation"] = Promise.all([l2]);
              }
            }
          }
          break;
        }
        // #endregion ----------------------------------------------

        // #region ---------------------------------------- Strategy
        case "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE": {
          const strategy = wysiwygState.target as any;
          let isBlendedBenchmark = false;

          const requestsObj = {
            analytics: responseCompute.strategy.analytics,
            benchmark: null,
            strategy: wysiwygState.target,
            performances: null,
          };
          const launchDate = getStrategyLaunchDate(this.template);
          const finalDate = getStrategyFinalDate(this.template);

          switch (wysiwygState.targetType) {
            case "STRATEGY":
              {
                isBlendedBenchmark =
                  strategy.params?.strategy?.benchmark?.includes("COLLECTION");

                if (responseCompute.strategy.benchmark != null) {
                  if (isBlendedBenchmark) {
                    requestsObj.benchmark =
                      responseCompute.strategy.benchmark[0];
                  } else {
                    requestsObj.benchmark =
                      responseCompute.strategy.benchmark?.data?.[0];
                  }
                } else {
                  requestsObj.benchmark = null;
                }

                const hasBenchmark = requestsObj.benchmark != null;

                requestsObj.performances =
                  wysiwygState.storage.getAsOfTodayPerfAnalytics(
                    section,
                    hasBenchmark,
                    launchDate,
                    finalDate
                  );
              }

              break;

            case "SYSTEMATIC": {
              requestsObj.benchmark = responseCompute.strategy.benchmark;
              const hasBenchmark = requestsObj.benchmark != null;
              requestsObj.performances =
                responseCompute.strategy.storage.getAsOfTodayPerfAnalytics(
                  section,
                  hasBenchmark,
                  launchDate,
                  finalDate
                );
            }
          }

          requests[key] = httpAll(requestsObj);

          break;
        }
        case "REPORT_STRATEGY_CHART": {
          const chartRequests = {
            benchmark: null,
            strategy: wysiwygState.target as any,
            strategyResult: response.strategy.data,
          };

          if (section.content.benchmark) {
            let isBlendedBenchmark = false;
            switch (wysiwygState.targetType) {
              case "STRATEGY":
                isBlendedBenchmark =
                  chartRequests.strategy.params?.strategy?.benchmark?.includes(
                    "COLLECTION"
                  ) ?? false;

                if (responseCompute.strategy.benchmark != null) {
                  if (isBlendedBenchmark) {
                    chartRequests.benchmark =
                      responseCompute.strategy.benchmark[0];
                  } else {
                    chartRequests.benchmark =
                      responseCompute.strategy.benchmark;
                  }
                } else {
                  chartRequests.benchmark = null;
                }

                break;

              case "SYSTEMATIC":
                chartRequests.benchmark = responseCompute.strategy.benchmark;
            }
          }

          requests[key] = httpAll(chartRequests);
          break;
        }
        case "REPORT_STRATEGY_FACTS": {
          requests[key] = httpAll({
            strategy: wysiwygState.target,
            strategyResult: response.strategy.data,
            holdings: responseCompute.strategy.holdings,
          });

          break;
        }
        case "REPORT_STRATEGY_HOLDINGS": {
          requests[key] = httpAll({
            strategy: wysiwygState.target,
            strategyResult: response.strategy.data,
            holdings: responseCompute.strategy.holdings,
          });

          break;
        }
        case "REPORT_STRATEGY_KEY_FACTS": {
          const strategy = wysiwygState.target as Strategy;
          const requestsObj = {
            benchmark: null,
            strategy: wysiwygState.target,
            keyFacts: responseCompute.strategy.keyFacts,
          };

          switch (wysiwygState.targetType) {
            case "STRATEGY":
              if (responseCompute.strategy.benchmark != null) {
                const isBlendedBenchmark =
                  strategy?.params?.strategy?.benchmark?.includes(
                    "COLLECTION"
                  ) ?? false;

                requestsObj["benchmark"] = isBlendedBenchmark
                  ? responseCompute.strategy.benchmark[0]
                  : responseCompute.strategy.benchmark["data"][0];
              }

              break;

            case "SYSTEMATIC":
              requestsObj["benchmark"] = responseCompute.strategy.benchmark;
          }

          requests[key] = httpAll(requestsObj);

          break;
        }
        case "REPORT_STRATEGY_BREAKDOWN": {
          if (
            section != null &&
            section.content != null &&
            section.presentation != null
          ) {
            const params = {
              constituents: getSymbolWeight(instruments),
              countryLevel: "Country",
              metricType: "abHistoryPerc",
              sectorLevel: "1 Industry",
            };

            switch (section.content.dimension) {
              case "INDUSTRY": {
                params.countryLevel = "WWW";
                params.sectorLevel = "1 Industry";

                break;
              }
              case "SECTOR": {
                params.countryLevel = "WWW";
                params.sectorLevel = "3 Sector";

                break;
              }
              case "MARKET": {
                params.countryLevel = "Country";
                params.sectorLevel = "ICB";

                break;
              }
              case "REGION": {
                params.countryLevel = "Region";
                params.sectorLevel = "ICB";

                break;
              }
              case "AREA": {
                params.countryLevel = "Area";
                params.sectorLevel = "ICB";

                break;
              }
              default:
                console.log(
                  "Unrecognized dimension " + section.content.dimension
                );
                break;
            }

            //OLD PEERS WEIGHTS
            // requests[key] = this.apiPeers.weight(params);

            const additionalAnalytics = ["cardinality"];
            //Old peers weight syntax
            const l1 = this.apiPeers.weightAnalytics(
              params.constituents,
              params.sectorLevel,
              additionalAnalytics
            );
            const l2 = this.apiPeers.weightAnalytics(
              params.constituents,
              params.countryLevel,
              additionalAnalytics
            );

            requests[key] = Promise.all([l1, l2]);
          }

          break;
        }
        case "REPORT_STRATEGY_MONTHLY_ANALYTICS": {
          requests[key] = httpAll({
            analytics: responseCompute.strategy.analytics,
            strategy: wysiwygState.target,
          });

          break;
        }
        case "REPORT_STRATEGY_QUARTERLY_ANALYTICS": {
          requests[key] = httpAll({
            analytics: responseCompute.strategy.analytics,
            strategy: wysiwygState.target,
          });

          break;
        }
        case "REPORT_STRATEGY_SUMMARY": {
          requests[key] = httpAll({
            benchmark:
              responseCompute.strategy.benchmark != null
                ? "data" in responseCompute.strategy.benchmark
                  ? responseCompute.strategy.benchmark["data"][0]
                  : responseCompute.strategy.benchmark
                : null,
            instrumentHedging:
              responseCompute.strategy.instrumentHedging != null
                ? responseCompute.strategy.instrumentHedging.data[0]
                : null,
            strategy: wysiwygState.target,
            strategyResult: response.strategy.data,
            holdings: responseCompute.strategy.holdings,
            whiteList: responseCompute.strategy.whiteList,
          });
          break;
        }
        case "REPORT_STRATEGY_YEARLY_ANALYTICS": {
          const strategy = wysiwygState.target as Strategy;

          const requestsObj = {
            analytics: responseCompute.strategy.analytics,
            benchmark: null,
            strategy: wysiwygState.target,
          };

          let isBlendedBenchmark = false;
          switch (wysiwygState.targetType) {
            case "STRATEGY":
              if (responseCompute.strategy.benchmark) {
                isBlendedBenchmark =
                  strategy?.params?.strategy?.benchmark?.includes(
                    "COLLECTION"
                  ) ?? false;

                requestsObj["benchmark"] = isBlendedBenchmark
                  ? responseCompute.strategy.benchmark[0]
                  : responseCompute.strategy.benchmark["data"][0];
              }

              break;

            case "SYSTEMATIC":
              requestsObj["benchmark"] = responseCompute.strategy.benchmark;
          }

          requests[key] = httpAll(requestsObj);

          break;
        }
        case "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS": {
          const strategy = wysiwygState.target as Strategy;
          let isBlendedBenchmark = false;
          switch (wysiwygState.targetType) {
            case "STRATEGY":
              isBlendedBenchmark =
                strategy?.params?.strategy?.benchmark?.includes("COLLECTION") ??
                false;

              break;

            case "SYSTEMATIC":
              const state = wysiwygState as any;
              const originalTarget = state?.originalTarget ?? null;
              isBlendedBenchmark =
                originalTarget?.benchmark.includes("COLLECTION") ?? false;
          }

          let benchmark = responseCompute.strategy.benchmark;

          if (benchmark != null && "data" in benchmark) {
            benchmark = responseCompute.strategy.benchmark["data"][0];
          }

          requests[key] = httpAll({
            analytics: responseCompute.strategy.analytics,
            benchmark:
              responseCompute.strategy.benchmark != null
                ? isBlendedBenchmark
                  ? responseCompute.strategy.benchmark[0]
                  : benchmark
                : null,
            strategy: wysiwygState.target,
          });

          break;
        }
        // #endregion ----------------------------------------------
      }
    }

    const responseAll = await httpAll(requests);
    //???
    return responseAll;
  }

  /**
   * Like dataGet but alterate pagination to rows
   *
   * http["rankings"]["RANKING_CARDINALITY"]
   */
  async collectRanking(params, properties: Property[]) {
    var _params = deepClone(params);
    if (_params["pagination"]["rows"] == null) {
      // Adjust limit only for ranking
      if (this.rankingCache != null) {
        _params["pagination"]["rows"] = this.apiRankings["RANKING_CARDINALITY"];
      } else {
        _params["pagination"]["rows"] = 99999;
      }
    }

    // If exporting in CSV use this (forced limit 3000 maximum)
    // This is also for legal "issues" if someone can export all...
    // _params["pagination"]["page"] = 1;
    // // Export is forced to 3000 maximum
    // _params["pagination"]["rows"] = 3000;

    return this._collectRanking(_params, properties, this.rankingCache);
  }

  /**
   * Get instruments belong to the ranking
   *
   * IMPORTANT: at the time of writing (2020-04-01)
   *
   * params.constraints and params.list are mutually exclusive
   *
   * @param {object}   params - instruments params
   * @param {object}   params.constraints - the ranking target instruments
   *      constraints. It is null if params.list != null
   * @param {object}   params.date - date
   * @param {object}   params.list - the ranking target list. It is null
   *      if params.constraints != null
   *
   * @param {object}   params.pagination - pagination
   * @param {number}   params.pagination.page - the page to retrieve
   * @param {number}   params.pagination.rows - the number of items per
   *      page
   *
   * @param {object}   params.sortBy - sorting criteria
   * @param {boolean}  params.sortBy.descending - is sorting descending
   * @param {string}   params.sortBy.property - sorting property
   *
   * @param {object[]} properties - properties to retrieve
   * @param {number}   properties.date - date. Used for point in time
   *      purposes.
   * @param {string}   params.properties.property - property
   *
   * @param {object}   rankingCache - the ranking cache
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async _collectRanking(
    params: RankingProps,
    properties: Property[],
    rankingCache: RankingCache
  ) {
    // This method is usually called from another function like
    // collectRanking to check and set correctly the pagination
    // like settings the maximum
    const constraints =
      params.constraints != null ? deepClone(params.constraints) : {};
    const list: any = params.list != null ? deepClone(params.list) : null;
    const pagination = deepClone(params.pagination);
    const sortBy =
      params.sortBy != null
        ? deepClone(params.sortBy)
        : {
            property: "rank",
            descending: false,
          };

    // If there is no sort set, use rank as default
    // Below logic will manage rank in case there is an existing rankingCache, or not
    // rank does also have an automatic fallback for existing columns
    const sortProperty = sortBy["property"];

    const _params = constraints;
    if (list != null) {
      // Check for back compatibility with old system because the posisitons were expected to be already in params
      if (!("positions" in list)) {
        const listId = (list as any).id;
        const listPositionsResponse = await this.apiLists.portfolioFetch(
          [listId],
          ["positionsToday"]
        );

        list["positions"] = listPositionsResponse?.[0]?.positionsToday ?? [];

        const positionsIndexMap = list["positions"].reduce(
          (prev, current, index) => {
            prev[current.symbol] = index;

            return prev;
          },
          {}
        );

        list["positionsIndex"] = positionsIndexMap;
      }

      const symbolsHoldings = extractSymbols(list.positions);

      if (!("filters" in _params)) {
        _params.filters = [];
      }

      _params.filters.push({
        dimension: "symbol",
        segments: symbolsHoldings,
      });
    }

    _params["page"] = pagination;
    _params.sort = [
      {
        dimension: sortProperty,
        rev: sortBy.descending,
      },
    ];

    if (params.date != null) {
      // TODO check date format
      _params.date = params.date;
    }

    let sortPropertyId;

    if (rankingCache != null) {
      //
      // 2020-06-03 Dirty patch for justInTimeTops
      //
      // if the ranking cache is enable, justInTimeTops MUST BE removed
      // It only need at the first stage of ranking process, when the
      // domain has to definined
      //
      if ("justInTimeTops" in constraints) {
        delete constraints["justInTimeTops"];
      }
      //
      // 2020-06-03 end
      //
      const data = rankingCache.rankingResult.data;
      const symbols = extractSymbols(data);

      if (!("filters" in _params)) {
        _params.filters = [];
      }

      // universe is the ranking
      _params.filters.push({
        dimension: "symbol",
        segments: symbols,
      });
      // #region ------------------------------------------------ sort
      _params.sort = [
        {
          dimension: sortProperty,
          rev: sortBy["descending"],
        },
      ];

      var symbolsAndRank: any = null;
      var symbolsAndWeight: any = null;

      // multisort: ranking as second dimension
      if (!/^rank/.test(sortProperty) && sortProperty !== "rank") {
        symbolsAndRank = extractForDataIngestion(data, "symbol", "rank");

        sortPropertyId = "rank" + new Date().getTime() + ":rank";

        _params.sort.push({
          dimension: sortPropertyId,
          rev: false,
        });

        _params.injestion = {
          data: symbolsAndRank,
          field: sortPropertyId,
          type: "number",
        };
      }

      // sort by ranking columns
      if (/^rank/.test(sortProperty)) {
        symbolsAndRank = extractForDataIngestion(data, "symbol", sortProperty);

        sortPropertyId = "rank" + new Date().getTime() + ":" + sortProperty;

        _params.sort[0].dimension = sortPropertyId;

        _params.injestion = {
          data: symbolsAndRank,
          field: sortPropertyId,
          type: "number",
        };
      }

      // sort by aginstList
      if (sortProperty === this.apiRankings["RANKING_PROPERTY_LIST"]) {
        symbolsAndRank = extractForDataIngestion(data, "symbol", "rank");

        symbolsAndWeight = [];

        for (let i = 0; i < symbolsAndRank.length; i++) {
          const datum = symbolsAndRank[i];
          if (
            !(
              datum.symbol in
              rankingCache.rankingParams.againstList.positionsIndex
            )
          ) {
            // enables grouping while sorting
            datum.value = "100000";
          }
          symbolsAndWeight.push(datum);
        }

        sortPropertyId = "rank" + new Date().getTime() + ":" + sortProperty;

        _params.sort[0]["dimension"] = sortPropertyId;

        _params.injestion = {
          data: symbolsAndWeight,
          field: sortPropertyId,
          type: "number",
        };
      }
      // #endregion ------------------------------------------------------
    } else {
      // No ranking data, adjust input data if ranking values are used
      // For example remove rank sort if there is no ranking
      // #region ---------------------------------------------------- sort
      if (/^rank/.test(sortProperty)) {
        // Fallback to marketcap if available
        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];
          if (property["property"] === "marketcap") {
            _params.sort = [
              {
                dimension: "marketcap",
                rev: true,
              },
            ];
          }
        }
        // If there is no marketcap, don't send sort parameter
        delete _params.sort;
      }
      // #endregion ------------------------------------------------------
    }

    // manging weight for lists
    //
    // 2020-09-01
    // contribution and performance for systematic portfolio holdings
    //
    if (
      list != null &&
      (sortProperty === "contribution" ||
        sortProperty === "contributionCurrency" ||
        sortProperty === "contributionMarket" ||
        sortProperty === "performance" ||
        sortProperty === "performanceCurrency" ||
        sortProperty === "weight")
    ) {
      sortPropertyId =
        list.id + ":" + new Date().getTime() + ":" + sortProperty;

      _params.sort = [
        {
          dimension: sortPropertyId,
          rev: sortBy["descending"],
        },
      ];

      symbolsAndWeight = extractForDataIngestion(
        list.positions,
        "symbol",
        sortProperty
      );
      _params.injestion = {
        data: symbolsAndWeight,
        field: sortPropertyId,
        type: "number",
      };
    }

    let response;
    try {
      // It uses screening endpoint
      response = await this.apiInstruments.newFilterAndFetch(
        _params,
        "security",
        properties
      );
    } catch (error) {
      console.trace(error);
      return httpError(error);
    }

    if (list != null) {
      // merging weights
      const holdings = list.positions;
      const holdingsIndex = list.positionsIndex;

      for (let i = 0; i < response.data.length; i++) {
        const instrument = response.data[i];
        const index = holdingsIndex[instrument.symbol];
        instrument.weight = holdings[index].weight;

        // Merge additional contribution/performance
        // Property must exists, set as null on instrument, and available on holdings to set
        if (
          instrument.contribution === null &&
          holdings[index].contribution != null
        ) {
          instrument.contribution = holdings[index].contribution;
        }
        if (
          instrument.contributionCurrency === null &&
          holdings[index].contributionCurrency != null
        ) {
          instrument.contributionCurrency =
            holdings[index].contributionCurrency;
        }
        if (
          instrument.contributionMarket === null &&
          holdings[index].contributionMarket != null
        ) {
          instrument.contributionMarket = holdings[index].contributionMarket;
        }
        if (
          instrument.performance === null &&
          holdings[index].performance != null
        ) {
          instrument.performance = holdings[index].performance;
        }
        if (
          instrument.performanceCurrency === null &&
          holdings[index].performanceCurrency != null
        ) {
          instrument.performanceCurrency = holdings[index].performanceCurrency;
        }
      }

      // ...
      // can be merged other properties, e.g. point in time and
      // strategy contributions
    }

    if (rankingCache != null) {
      // merging ranking properties
      const ranking = rankingCache.rankingResult;
      for (let i = 0; i < response.data.length; i++) {
        const instrument = response.data[i];
        const index = ranking.index[instrument.symbol];
        const augmentation = ranking.data[index];

        for (const key in augmentation) {
          instrument[key] = augmentation[key];
        }
      }
    }

    return response;
  }
}
