import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./useDragAndDrop.module.scss";

/**
 *
 * @param list
 *
 * @returns the reordered list and the listeners to handle drag an drop events
 *
 * ?ATTENTION: To work correctly you have to give draggable={true} and id attributes to the draggable DOM node
 *
 */
export const useDragAndDrop = (list: any[]) => {
  const [items, setItems] = useState(list ?? []);

  useEffect(() => {
    setItems(list);
  }, [list]);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = useCallback((e) => {
    dragItem.current = e.target.id;
  }, []);

  const dragEnter = useCallback((e) => {
    dragOverItem.current = e.currentTarget.id;
  }, []);

  const dragOver = useCallback((e) => {
    e.currentTarget.classList.add(styles.overlappedBrick);
  }, []);

  const dragOut = useCallback((e) => {
    e.currentTarget.classList.remove(styles.overlappedBrick);
  }, []);

  const drop = useCallback(
    (e, callback?: Function) => {
      const copyListItems = [...items];
      const dragItemContent = copyListItems[dragItem.current!];
      copyListItems.splice(dragItem.current!, 1);
      copyListItems.splice(dragOverItem.current!, 0, dragItemContent);
      dragItem.current = undefined;
      dragOverItem.current = undefined;
      setItems(copyListItems);

      if (callback) {
        callback(copyListItems);
      }
    },
    [items]
  );

  return { items, drop, dragStart, dragEnter, dragOver, dragOut };
};
