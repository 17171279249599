import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Typography
} from "@mui/material";


import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import RatingCheckbox from "../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { ActionType } from "./TableCustomizer_reducer";

// import { SortingSelect } from "../../../../configurator/SortingSelect";
import { useMemo } from "react";
import SortingSelect from "../commonWidgets/SortingSelect";
import { optionsForSortingSelect } from "../utils/utils";

type TableCustomizerProps = {
  input: any;
  useWysiwyg: boolean;
  dispatcher: (action: ActionType) => void;
};

export default function TableCustomizer({
  input,
  dispatcher,
  useWysiwyg,
}: TableCustomizerProps) {
  const inputForSortingSelect = useMemo(() => {
    return optionsForSortingSelect(input.columns);
  }, [input.columns]);

  return (
    <FormControl disabled={useWysiwyg}>
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Box display={"flex"} alignItems="center">
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  dispatcher({
                    type: "SET_TOP_ISENABLED",
                    payload: e.target.checked,
                  });
                }}
                size="small"
              />
            }
            label="Top"
            checked={input.top.isEnabled}
          />
          <InputNumber
            isDisabled={!input.top.isEnabled}
            initVal={input.top.content}
            isPercentage={false}
            setOuterState={(e) => {
              dispatcher({ type: "SET_TOP_CONTENT", payload: e });
            }}
            isFloating={false}
          />
        </Box>
        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography>Rating:</Typography>
          <RatingCheckbox
            isDisabled={useWysiwyg}
            initState={input.rate}
            stateGetter={(e) => {
              dispatcher({ type: "SET_RATE", payload: e });
            }}
          />
        </Box>

        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography>Sort by:</Typography>
          <SortingSelect
            options={inputForSortingSelect}
            value={input.sortBy}
            onSortChange={(e) => {
              dispatcher({ type: "SET_SORT_BY", payload: e });
            }}
          />
        </Box>

        <Box display={"flex"} alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={input.rank}
                onChange={(e) =>
                  dispatcher({ type: "SET_RANK", payload: e.target.checked })
                }
                size="small"
              />
            }
            label="Add ranking columns if rank is active"
          />
          <FormControlLabel
            disabled={input.rank === false && useWysiwyg === false}
            control={
              <Checkbox
                onChange={(e) =>
                  dispatcher({
                    type: "SET_SORT_BY_RANK",
                    payload: e.target.checked,
                  })
                }
                checked={input.sortByRank}
              />
            }
            label="Sort by ranking"
          />
        </Box>
      </Box>
    </FormControl>
  );
}
