export const getIssues = (list) => {
  var issues: any = {
    D: {
      holdings: [],
      total: 0,
      weight: 0,
    },
    C: {
      holdings: [],
      total: 0,
      weight: 0,
    },
  };

  var holding: any = null;
  var holdings = list.positions;
  for (let i = 0, length = holdings.length; i < length; i++) {
    holding = holdings[i];

    if (holding.rc === -2) {
      issues.D.holdings.push(holding);
      issues.D.total++;
      issues.D.weight = issues.D.weight + holding.weight;
    }

    if (holding.rc === -1) {
      issues.C.holdings.push(holding);
      issues.C.total++;
      issues.C.weight = issues.C.weight + holding.weight;
    }
  }

  return issues;
};
export const formatForCsvExport = (positions, format) => {
  var lines: any[] = [];

  var line: any = [
    '"Weight"',
    '"Ticker"',
    '"Market ISO"',
    '"Currency"',
    '"ISIN"',
    '"Name"',
    '"Country"',
    '"Rating"',
    '"Rated on"',
  ];
  lines.push(line);

  var position: any = null;
  for (let i = 0, length = positions.length; i < length; i++) {
    position = positions[i];
    line = [
      '"' +
        format.text(
          "weight",
          "weight",
          position["weight"],
          position,
          position["type"]
        ) +
        '"',
      '"' +
        format.text(
          "ticker",
          "ticker",
          position["ticker"],
          position,
          position["type"]
        ) +
        '"',
      '"' + position.country + '"',
      '"' + position.currency + '"',
      '"' +
        format.text(
          "isin",
          "isin",
          position["isin"],
          position,
          position["type"]
        ) +
        '"',
      '"' +
        format.text(
          "name",
          "name",
          position["name"],
          position,
          position["type"]
        ) +
        '"',
      '"' + position.domicile + '"',
      '"' +
        format.text(
          "rc",
          "rating",
          position["rc"],
          {
            rateChange: position["lr"],
            rateDate: position["dr"],
            ratePrev: position["rrr"],
          },
          position["type"]
        ) +
        '"',
      '"' +
        format.text(
          "dr",
          "rated_on_iso",
          position["dr"],
          position,
          position["type"]
        ) +
        '"',
    ];

    lines.push(line);
  }

  return lines;
};
