/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/Collector
 * @summary Retrieves data to build report
 *
 */

import Highcharts from "highcharts/highstock";
import HighchartsExporting from "highcharts/modules/exporting";
import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { _color } from "../../../trendrating/formatter/_color";
import { _rate } from "../../../trendrating/formatter/_rate";
import { List } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { InputManager } from "./InputManager";
import { Peer } from "./Peer";
import { Section, WysiwygState } from "./Types";
import { Strategies } from "../../../api/compute/Strategies";
import { sortBy } from "../../../trendrating/core/UtilsObject";

HighchartsExporting(Highcharts);

export class Collector {
  inputManager: InputManager;

  hasStrategyLaunchDate; // Starting / launch date set by user
  hasStrategyFinalDate; // End / final date set by user

  sections: Section[]; // template sections
  strategyLaunchDate; // Starting / launch date computed from strategy run
  strategyFinalDate; // End / final date computed from strategy run
  template;
  userPreference;
  wysiwygState: WysiwygState;

  // contains current computing data
  _computing;
  static Collector: any;

  constructor(
    environment: AppEnvironment,
    {
      rankingCache,
      sections,
      template,
      userPreference,
      wysiwygState,
    }: {
      rankingCache: any;
      sections: Section[];
      template: any;
      userPreference: any;
      wysiwygState: WysiwygState;
    }
  ) {
    // Copy so there is no issues if something changes
    this.sections = deepClone(sections);
    this.template = deepClone(template);
    this.userPreference = deepClone(userPreference);
    //! Warning wysiwyg state can be recursive!
    // Only clone target
    this.wysiwygState = {
      ...wysiwygState,
      target: deepClone(wysiwygState.target),
    } as WysiwygState;

    this.inputManager = new InputManager(environment, {
      rankingCache: rankingCache,
      sections: this.sections,
      template: this.template,
      userPreference: this.userPreference,
      wysiwygState: this.wysiwygState,
    });
  }

  /**
   *
   * prepare data for formatter
   *
   * key in response are in the format id|type[|more]
   *
   * type is one of the one specified in trendrating-report/sections
   */
  prepare(response: any) {
    const dataMap = {};
    const sectionPattern = /^(.[^|]*)\|(.[^|]*)\|?(.*)?/;
    const sections = this.sections;
    const userPreference = this.userPreference;
    const wysiwygState = this.wysiwygState;
    let value;

    const sectionPeer =
      wysiwygState.targetType === "PEER"
        ? new Peer(wysiwygState.storage)
        : null;

    for (const section of sections) {
      const _sId = section.id;
      const _sType = section.type;
      this._addProperty(dataMap, _sId);

      switch (_sType) {
        // #region ------------------------------------------ Common
        case "REPORT_COMMON_ALLOCATION": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["firstRows"] = section.content["firstRows"];
            dataMap[_sId][_sType]["firstType"] = section.content["firstType"];
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
            dataMap[_sId][_sType]["secondRows"] = section.content["secondRows"];
            dataMap[_sId][_sType]["secondType"] = section.content["secondType"];
            if (section.content["sortByWeight"] != null) {
              dataMap[_sId][_sType]["sortByWeight"] =
                section.content["sortByWeight"];
            } else {
              dataMap[_sId][_sType]["sortByWeight"] = true;
            }
          }
          if (section["presentation"] != null) {
            dataMap[_sId][_sType]["colorByPosition"] =
              section["presentation"]["colorByPosition"];
          }

          break;
        }
        case "REPORT_COMMON_COVER": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {
            subTitle: section.content["subTitle"],
            title: section.content["title"],
          };

          break;
        }
        case "REPORT_COMMON_DISCLAIMER": {
          this._addProperty(dataMap[_sId], _sType);

          // custom disclaimer
          if (!(_sId + "|" + _sType in response)) {
            dataMap[_sId][_sType] = {
              text:
                userPreference != null && userPreference.disclaimer != null
                  ? userPreference.disclaimer
                  : "",
            };
          }

          break;
        }
        case "REPORT_COMMON_TITLE": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {
            text: section.content.text,
          };

          break;
        }
        case "REPORT_COMMON_HEADER_1": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {
            text: section.content.text,
          };

          break;
        }
        case "REPORT_COMMON_PAGE_BREAK": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {};

          break;
        }
        case "REPORT_COMMON_PARAGRAPH": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {
            fontSize: section.content.fontSize,
            text: section.content.text,
          };

          break;
        }
        case "REPORT_COMMON_SECURITY_CHART": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_COMMON_SECURITY_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }
          if (section["presentation"] != null) {
            dataMap[_sId][_sType]["enableRank"] =
              section["presentation"]["useWysiwyg"] ||
              section["presentation"]["rank"];
          }

          break;
        }
        case "REPORT_COMMON_SPACING": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType] = {
            lines: section.content["lines"],
          };

          break;
        }
        // #endregion ----------------------------------------------
        // #region -------------------------------------------- Alerts
        case "REPORT_LIST_ALERTS_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            const checkAlerts = (item) => {
              return (
                item.upgrades !== 0 ||
                item.downgrades !== 0 ||
                item.moversUp !== 0 ||
                item.moversDown !== 0
              );
            };

            dataMap[_sId][_sType] =
              response[_sId + "|" + _sType].filter(checkAlerts);
          }

          break;
        }
        // #endregion ----------------------------------------------
        // #region -------------------------------------------- Peer
        case "REPORT_PEER_DISPERSION": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareDispersion(
              response[_sId + "|" + _sType], // dispersion data
              section,
              wysiwygState.target.type
            );
          }

          break;
        }
        case "REPORT_DISPERSION_RATIO_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = response[_sId + "|" + _sType];
          }

          break;
        }
        case "REPORT_DISPERSION_TCR_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = response[_sId + "|" + _sType];
          }

          break;
        }

        case "REPORT_BASKET_DISPERSION_BY_CHART": {
          this._addProperty(dataMap[_sId], _sType);
          const serie = response[_sId + "|" + _sType]["data"];
          const useWysiwyg = section?.content?.useWysiwyg;

          const intervalsDict = {
            4: 25,
            10: 10,
            20: 5,
          };

          const interval = useWysiwyg
            ? wysiwygState?.["dispersionByConstraints"]?.["intervals"] ?? 25
            : intervalsDict[section?.content?.intervals];
          const trimmedSerie = serie.filter(
            (obj) => obj.top != null && obj.middle != null && obj.bottom != null
          );

          // filter cumulative &&
          const serieFiltered = trimmedSerie.filter(
            (item) =>
              item.name !== "Portfolio" &&
              item.name !== "Basket" &&
              item.name !== "All"
          );

          const hasOnlyOneSerie = serieFiltered.length < 2;
          const hideSerie =
            section.content.hideIfOneResult === true && hasOnlyOneSerie;
          const sorter = section.content.sort;

          if (sorter != null) {
            const property = sorter.property;
            const rev = sorter.descending;

            sortBy(trimmedSerie, property, rev);
          }

          dataMap[_sId][_sType]["svg"] = hideSerie
            ? undefined
            : this._dispersionChartSvg(trimmedSerie, interval);
          dataMap[_sId][_sType]["isEtfOnly"] =
            response[_sId + "|" + _sType]["isEtfOnly"];

          break;
        }
        case "REPORT_DISPERSION_OVERVIEW":
        case "REPORT_BASKET_DISPERSION_BY_SECTORS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = response[_sId + "|" + _sType];
          }

          break;
        }
        case "REPORT_DISPERSION_BY_CHART": {
          this._addProperty(dataMap[_sId], _sType);
          const serie = sectionPeer?.prepareSeriesDispersionChart(
            response[_sId + "|" + _sType].data,
            section
          );

          const interval = section?.["content"]?.["intervals"] ?? 4;
          const hasOnlyOneSerie = serie.length < 2;
          const hideSerie =
            section.content.hideIfOneResult === true && hasOnlyOneSerie;
          const sorter = section.content.sort;

          if (sorter != null) {
            const property = sorter.property;
            const rev = sorter.descending;

            sortBy(serie, property, rev);
          }

          dataMap[_sId][_sType]["svg"] = hideSerie
            ? undefined
            : this._dispersionChartSvg(serie, interval);
          dataMap[_sId][_sType]["peerType"] =
            response[_sId + "|" + _sType].peerType;

          break;
        }
        case "REPORT_DISPERSION_BY_SECTORS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = {
              datum: sectionPeer?.prepareDispersionBySegment(
                response[_sId + "|" + _sType].data, // dispersion data
                section
              ),
              peerType: response[_sId + "|" + _sType].peerType,
            };
          }

          break;
        }
        case "REPORT_PEER_DISPERSION_CHILDREN": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareDispersionChildren(
              response[_sId + "|" + _sType], // dispersion data
              section,
              wysiwygState.target.type
            );
          }

          break;
        }
        case "REPORT_PEER_TCR": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareTcr(
              wysiwygState.target, // peer
              section
            );
          }

          break;
        }
        case "REPORT_PEER_TCR_FOCUS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareTcrFocus(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_CUSTOMIZABLE_PEER_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.preparePeerTable(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareUpgradesDowngradesFocus(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_AB_CHANGES":
        case "REPORT_PEER_WHAT_AB_CHANGES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareSectorAbChanges(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_OVERVIEW":
        case "REPORT_PEER_WHAT_OVERVIEW": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareSectorOverview(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_TCR_CHANGES":
        case "REPORT_PEER_WHAT_TCR_CHANGES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareSectorTcrChanges(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_UPGRADES_DOWNGRADES":
        case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] =
              sectionPeer?.prepareSectorUpgradesDowngrades(
                response[_sId + "|" + _sType],
                section
              );
          }

          break;
        }
        case "REPORT_PEER_WHERE_AB_CHANGES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareMarketAbChanges(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_WHERE_OVERVIEW": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareMarketOverview(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_WHERE_TCR_CHANGES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] = sectionPeer?.prepareMarketTcrChanges(
              response[_sId + "|" + _sType],
              section
            );
          }

          break;
        }
        case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType] =
              sectionPeer?.prepareMarketUpgradesDowngrades(
                response[_sId + "|" + _sType],
                section
              );
          }

          break;
        }
        // #endregion ----------------------------------------------
        // #region --------------------------------------- Portfolio
        case "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_PORTFOLIO_PERFORMER": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_PORTFOLIO_RATING_CHANGE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }

        case "REPORT_PORTFOLIO_TCR": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
            dataMap[_sId][_sType]["marketAllocationType"] =
              section.content["marketAllocation"];
            dataMap[_sId][_sType]["sectorAllocationType"] =
              section.content["sectorAllocation"];
            dataMap[_sId][_sType]["marketRows"] = section.content["marketRows"];
            dataMap[_sId][_sType]["sectorRows"] = section.content["sectorRows"];
          }

          value = section["presentation"];

          if (value?.portfolioMomentumRating) {
            dataMap[_sId][_sType]["portfolioMomentumRating"] = (
              wysiwygState.target as List
            ).statistics?.tcr;
          }

          if (value?.ratingWeight) {
            dataMap[_sId][_sType]["ratingWeight"] = (
              wysiwygState.target as List
            ).statistics?.weightPerRating;
          }

          break;
        }

        // #endregion ----------------------------------------------
        // #region ---------------------------------------- Strategy
        case "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_BREAKDOWN": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];

            dataMap[_sId][_sType].dimension = section.content.dimension;
            dataMap[_sId][_sType].rows = section.content.rows;
          }

          break;
        }
        case "REPORT_STRATEGY_CHART": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];

            dataMap[_sId][_sType]["title"] = section.content["title"][
              "isEnabled"
            ]
              ? section.content["title"]["content"]
              : null;
          }
          if (section["presentation"] != null) {
            dataMap[_sId][_sType]["align"] = section["presentation"]["align"];
            dataMap[_sId][_sType]["legend"] = section["presentation"]["legend"];
            dataMap[_sId][_sType]["logAxis"] =
              section["presentation"]["logAxis"];
            dataMap[_sId][_sType]["size"] = section["presentation"]["size"];
          }
          break;
        }
        case "REPORT_STRATEGY_FACTS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_HOLDINGS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_MONTHLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_QUARTERLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_SUMMARY": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          dataMap[_sId][_sType]["isSystematic"] =
            wysiwygState.targetType === "SYSTEMATIC";
          break;
        }
        case "REPORT_STRATEGY_YEARLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["annualized"] = section.content
              ?.annualized ?? {
              content: "Annualized",
              isEnabled: true,
            };
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        case "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          if (section.content != null) {
            dataMap[_sId][_sType]["headline"] =
              section.content["headline"]["content"];
          }

          break;
        }
        // #endregion ----------------------------------------------
        default:
          console.log("Unrecognized type " + _sType);
          break;
      }
    }

    for (let section in response) {
      const tokens = sectionPattern.exec(section);
      if (tokens == null) {
        throw new Error("Missing token from section");
      }
      const _sId = tokens[1];
      const _sType = tokens[2];
      const _sMore = tokens[3];

      this._addProperty(dataMap, _sId);

      switch (_sType) {
        // #region ------------------------------------------ Common
        case "REPORT_COMMON_ALLOCATION": {
          this._addProperty(dataMap[_sId], _sType);

          let meaningful = 4; // meaningful rows

          let sortByWeight = true;
          if (dataMap[_sId][_sType]["sortByWeight"] != null) {
            sortByWeight = dataMap[_sId][_sType]["sortByWeight"];
          }

          switch (_sMore) {
            case "first": {
              if (dataMap[_sId][_sType]["firstRows"] != null) {
                meaningful = dataMap[_sId][_sType]["firstRows"] - 1;
              } else {
                meaningful = 4;
              }

              value = response[section]?.clustersStats?.stats;

              dataMap[_sId][_sType][_sMore] = {
                data: this._allocationData(value, meaningful, sortByWeight),
                svg: this._allocationSvg(
                  value,
                  sortByWeight,
                  dataMap[_sId][_sType]["colorByPosition"]
                ),
              };

              break;
            }
            case "second": {
              if (dataMap[_sId][_sType]["secondRows"] != null) {
                meaningful = dataMap[_sId][_sType]["secondRows"] - 1;
              } else {
                meaningful = 4;
              }

              value = response[section]?.clustersStats?.stats;
              dataMap[_sId][_sType][_sMore] = {
                data: this._allocationData(value, meaningful, sortByWeight),
                svg: this._allocationSvg(
                  value,
                  sortByWeight,
                  dataMap[_sId][_sType]["colorByPosition"]
                ),
              };

              break;
            }
            default: {
              console.log("Missing _sMore");
            }
          }

          break;
        }
        case "REPORT_COMMON_DISCLAIMER": {
          dataMap[_sId][_sType] = {
            text: response[section],
          };

          break;
        }
        case "REPORT_COMMON_SECURITY_CHART": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType]["columns"] = response[section + "|columns"];
          dataMap[_sId][_sType]["position"] = response[section]["positions"];
          dataMap[_sId][_sType]["svg"] = response[section]["svg"];

          break;
        }
        case "REPORT_COMMON_SECURITY_TABLE": {
          this._addProperty(dataMap[_sId], _sType);

          if (_sMore === "columns") {
            dataMap[_sId][_sType]["columns"] = response[section];
          } else {
            const instruments = response[section].data;

            if (wysiwygState.targetType === "SYSTEMATIC") {
              //
              // 2020-07-22 merge contribution data
              //
              const allocationAt = this._computing["allocationAt"];

              for (let i = 0; i < instruments.length; i++) {
                instruments[i] = {
                  ...instruments[i],
                  ...allocationAt[instruments[i]["symbol"]],
                };
              }
            }

            dataMap[_sId][_sType]["position"] = instruments;
          }

          break;
        }
        // #endregion ----------------------------------------------
        // #region --------------------------------------- Portfolio
        case "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN": {
          this._addProperty(dataMap[_sId], _sType);

          const responseWhat = response[section]?.["axis"]?.[1] ?? [];
          const responseWhere = response[section]?.["axis"]?.[0] ?? [];

          //Map response Array into an object array that has id key equal to the
          //array item
          const what = responseWhat.map((key) => ({ id: key }));
          const where = responseWhere.map((key) => ({ id: key }));

          dataMap[_sId][_sType]["what"] = what;
          dataMap[_sId][_sType]["where"] = where;
          dataMap[_sId][_sType]["whatWhere"] =
            response[section]?.clustersStats?.stats;

          break;
        }
        case "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType][_sMore] = response[section].data;

          break;
        }
        case "REPORT_PORTFOLIO_PERFORMER": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType][_sMore] = response[section].data;

          break;
        }
        case "REPORT_PORTFOLIO_RATING_CHANGE": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType][_sMore] = response[section].data;

          break;
        }
        case "REPORT_PORTFOLIO_TCR": {
          this._addProperty(dataMap[_sId], _sType);

          let meaningful = 4; // meaningful rows

          switch (_sMore) {
            case "alert_downgrade": {
              this._addProperty(dataMap[_sId][_sType], "alert");
              dataMap[_sId][_sType]["alert"]["downgrade"] =
                response[section].data.length;
              break;
            }
            case "alert_upgrade": {
              this._addProperty(dataMap[_sId][_sType], "alert");
              dataMap[_sId][_sType]["alert"]["upgrade"] =
                response[section].data.length;
              break;
            }
            case "marketAllocation": {
              if (dataMap[_sId][_sType]["marketRows"] != null) {
                meaningful = dataMap[_sId][_sType]["marketRows"] - 1;
              } else {
                meaningful = 4;
              }

              value = response[section]?.[0]?.["clustersStats"]?.["stats"];

              dataMap[_sId][_sType][_sMore] = {
                data: this._allocationData(value, meaningful, true),
                svg: this._allocationSvg(value, true),
              };
              break;
            }
            case "sectorAllocation": {
              if (dataMap[_sId][_sType]["sectorRows"] != null) {
                meaningful = dataMap[_sId][_sType]["sectorRows"] - 1;
              } else {
                meaningful = 4;
              }

              value = response[section]?.[0]?.["clustersStats"]?.["stats"];

              dataMap[_sId][_sType][_sMore] = {
                data: this._allocationData(value, meaningful, true),
                svg: this._allocationSvg(value, true),
              };
              break;
            }
            default: {
              console.log("Missing _sMore");
            }
          }

          break;
        }
        // #endregion ----------------------------------------------
        // #region ---------------------------------------- Strategy
        case "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].analytics = response[section].analytics;
          dataMap[_sId][_sType].benchmark = response[section].benchmark;
          dataMap[_sId][_sType]["benchmarkPerformance"] = null;
          dataMap[_sId][_sType]["delta"] = null;
          dataMap[_sId][_sType].strategy = null;

          /*
                            If the difference in days between inception date and today is over 260.
                            If true, hide the inception annualized field and show the inception total row.
                        */

          const inceptionDateForLTD =
            this.strategyLaunchDate != null
              ? this.strategyLaunchDate
              : wysiwygState.target["inceptionDate"];

          const isLessThanYear = TDate.today() - inceptionDateForLTD <= 260;

          if (response[section]["performances"].benchmark != null) {
            let benchmarkLTD =
              response[section]["performances"].benchmark["LTD"];
            if (isLessThanYear) {
              benchmarkLTD =
                response[section].analytics["aggregatedTotals"][
                  "benchmarkTotalPerformance"
                ];
            } else {
              // ["performances"].strategy["LTD"] is equivalent to AnnualizedRateOfReturn
              // Do not change anything
            }
            dataMap[_sId][_sType]["benchmarkPerformance"] = {
              performance10Years:
                response[section]["performances"].benchmark["YEARLY_10_N"],
              performance1Day:
                response[section]["performances"].benchmark["DAILY"],
              performance1Month:
                response[section]["performances"].benchmark["MONTHLY"],
              performance1Week:
                response[section]["performances"].benchmark["WEEKLY"],
              performance1Year:
                response[section]["performances"].benchmark["YEARLY"],
              performance3Months:
                response[section]["performances"].benchmark["QUARTERLY"],
              performance3Years:
                response[section]["performances"].benchmark["YEARLY_3_N"],
              performance5Years:
                response[section]["performances"].benchmark["YEARLY_5_N"],
              performanceLTD: benchmarkLTD,
              performanceMTD:
                response[section]["performances"].benchmark["MTD"],
              performanceQTD:
                response[section]["performances"].benchmark["QTD"],
              performanceYTD:
                response[section]["performances"].benchmark["YTD"],
            };
          }

          if (response[section]["performances"]["delta"] != null) {
            let deltaLTD = response[section]["performances"]["delta"]["LTD"];
            if (isLessThanYear) {
              deltaLTD =
                response[section].analytics["aggregatedTotals"][
                  "deltaTotalPerformance"
                ];
            } else {
              // ["performances"].strategy["LTD"] is equivalent to AnnualizedRateOfReturn
              // Do not change anything
            }
            dataMap[_sId][_sType]["delta"] = {
              performance10Years:
                response[section]["performances"]["delta"]["YEARLY_10_N"],
              performance1Day:
                response[section]["performances"]["delta"]["DAILY"],
              performance1Month:
                response[section]["performances"]["delta"]["MONTHLY"],
              performance1Week:
                response[section]["performances"]["delta"]["WEEKLY"],
              performance1Year:
                response[section]["performances"]["delta"]["YEARLY"],
              performance3Months:
                response[section]["performances"]["delta"]["QUARTERLY"],
              performance3Years:
                response[section]["performances"]["delta"]["YEARLY_3_N"],
              performance5Years:
                response[section]["performances"]["delta"]["YEARLY_5_N"],
              performanceLTD: deltaLTD,
              performanceMTD: response[section]["performances"]["delta"]["MTD"],
              performanceQTD: response[section]["performances"]["delta"]["QTD"],
              performanceYTD: response[section]["performances"]["delta"]["YTD"],
            };
          }

          if (response[section]["performances"].strategy != null) {
            let strategyLTD = response[section]["performances"].strategy["LTD"];
            if (isLessThanYear) {
              strategyLTD =
                response[section].analytics["aggregatedTotals"][
                  "strategyTotalPerformance"
                ];
            } else {
              // ["performances"].strategy["LTD"] is equivalent to AnnualizedRateOfReturn
              // Do not change anything
            }
            dataMap[_sId][_sType].strategy = {
              performance10Years:
                response[section]["performances"].strategy["YEARLY_10_N"],
              performance1Day:
                response[section]["performances"].strategy["DAILY"],
              performance1Month:
                response[section]["performances"].strategy["MONTHLY"],
              performance1Week:
                response[section]["performances"].strategy["WEEKLY"],
              performance1Year:
                response[section]["performances"].strategy["YEARLY"],
              performance3Months:
                response[section]["performances"].strategy["QUARTERLY"],
              performance3Years:
                response[section]["performances"].strategy["YEARLY_3_N"],
              performance5Years:
                response[section]["performances"].strategy["YEARLY_5_N"],
              performanceLTD: strategyLTD,
              performanceMTD: response[section]["performances"].strategy["MTD"],
              performanceQTD: response[section]["performances"].strategy["QTD"],
              performanceYTD: response[section]["performances"].strategy["YTD"],
            };
          }

          // Set dynamic data (benchmark and strategy)
          dataMap[_sId][_sType]["targetBenchmark"] = {
            name: null,
            ticker: null,
          };
          if (response[section].benchmark != null) {
            dataMap[_sId][_sType]["targetBenchmark"]["name"] =
              response[section].benchmark["name"];
            dataMap[_sId][_sType]["targetBenchmark"]["ticker"] =
              response[section].benchmark["ticker"];
          }

          dataMap[_sId][_sType]["targetStrategy"] = {
            name: this.wysiwygState.target["name"],
          };
          // End set dynamic data

          break;
        }
        case "REPORT_STRATEGY_BREAKDOWN": {
          this._addProperty(dataMap[_sId], _sType);

          let meaningful = 4; // meaningful rows

          if (dataMap[_sId][_sType].rows != null) {
            meaningful = dataMap[_sId][_sType].rows - 1;
          } else {
            meaningful = 4;
          }
          switch (dataMap[_sId][_sType].dimension) {
            case "INDUSTRY":
            case "SECTOR": {
              // value = response[section].data.peersWeights.l1;
              value = response[section]?.[0]?.["clustersStats"]?.["stats"];

              break;
            }
            case "MARKET":
            case "REGION":
            case "AREA": {
              // value = response[section].data.peersWeights.l2;
              value = response[section]?.[1]?.["clustersStats"]?.["stats"];

              break;
            }
            default:
              console.log("Missing breakdown dimension");
              break;
          }

          dataMap[_sId][_sType].data = this._breakdownData(
            value,
            meaningful,
            true
          );

          break;
        }
        case "REPORT_STRATEGY_CHART": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].benchmark = response[section].benchmark;
          dataMap[_sId][_sType].strategy = response[section].strategy;

          let params = {
            benchmark: dataMap[_sId][_sType].benchmark,
            legend: dataMap[_sId][_sType]["legend"],
            logAxis: dataMap[_sId][_sType]["logAxis"],
            size: dataMap[_sId][_sType]["size"],
            title: dataMap[_sId][_sType]["title"],
          };
          // If title is enabled and empty (not null), set the
          // strategy name
          const getName = () => {
            const benchmark = response[section].benchmark;

            if (benchmark != null) {
              if (benchmark?.data?.[0] === Strategies.SYMBOL_NEUTRAL_STRATEGY) {
                return "Neutral strategy";
              }

              if (
                benchmark?.data?.[0] ===
                Strategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
              ) {
                return "Neutral strategy equal weighted";
              }

              return benchmark?.name ?? undefined;
            }

            return "undefined";
          };
          if (params["title"] === "") {
            params["title"] = response[section].strategy["name"];
            if (response[section].benchmark != null) {
              params["title"] += " vs " + getName();
            }
          }

          dataMap[_sId][_sType]["svg"] = this._strategyChartSvg(
            params,
            response[section].strategy,
            response[section].strategyResult
          );

          break;
        }
        case "REPORT_STRATEGY_FACTS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType]["rebalancingFrequency"] = "#UNKNOWN";
          switch (response[section].strategy.params.strategy["rebalance"]) {
            case "05_DAYS": {
              dataMap[_sId][_sType]["rebalancingFrequency"] = "Weekly";

              break;
            }
            case "20_DAYS": {
              dataMap[_sId][_sType]["rebalancingFrequency"] = "Monthly";

              break;
            }
            case "60_DAYS": {
              dataMap[_sId][_sType]["rebalancingFrequency"] = "Quarterly";

              break;
            }
            default:
              console.log(
                "Unrecognized strategy rebalance " +
                  response[section].strategy.params["strategy"]["rebalance"]
              );
              break;
          }

          dataMap[_sId][_sType].currency =
            response[section].strategy.params.strategy.currency;

          // TODO use i18n
          dataMap[_sId][_sType].performance = "Price";
          switch (response[section].strategy.params.strategy["performance"]) {
            case "NONE": {
              dataMap[_sId][_sType].performance = "Price";

              break;
            }
            case "REBALANCE": {
              dataMap[_sId][_sType].performance = "Total return";

              break;
            }
            default:
              console.log(
                "Unrecognized strategy performance " +
                  response[section].strategy.params["strategy"].performance
              );
              break;
          }

          if (response[section].holdings != null) {
            dataMap[_sId][_sType].numberOfHoldings =
              response[section].holdings.length;
          }

          const curves = response[section].strategyResult["CURVES"]["H"];

          const launchDate =
            this.strategyLaunchDate != null
              ? this.strategyLaunchDate
              : curves[0]["d"];

          const endingDate =
            this.strategyFinalDate ?? curves[curves.length - 1]["d"];

          dataMap[_sId][_sType]["launchDate"] = TDate.daysToIso8601(launchDate);
          dataMap[_sId][_sType]["endingDate"] = TDate.daysToIso8601(endingDate);

          break;
        }
        case "REPORT_STRATEGY_HOLDINGS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].strategy = response[section].strategy;
          dataMap[_sId][_sType].strategyResult =
            response[section].strategyResult;
          dataMap[_sId][_sType].holdings = response[section].holdings;

          break;
        }
        case "REPORT_STRATEGY_KEY_FACTS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].benchmark = response[section].benchmark;
          dataMap[_sId][_sType].keyRatios =
            response[section].keyFacts.keyFacts.keyRatios;
          dataMap[_sId][_sType].performance =
            response[section].keyFacts.keyFacts.performance;
          dataMap[_sId][_sType].period =
            response[section].keyFacts.keyFacts.period;
          dataMap[_sId][_sType].risk = response[section].keyFacts.keyFacts.risk;
          break;
        }
        case "REPORT_STRATEGY_MONTHLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].analytics = response[section].analytics;

          break;
        }
        case "REPORT_STRATEGY_QUARTERLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].analytics = response[section].analytics;

          break;
        }
        case "REPORT_STRATEGY_SUMMARY": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].benchmark =
            response[section].benchmark != null
              ? response[section].benchmark
              : null;

          dataMap[_sId][_sType].instrumentHedging =
            response[section].instrumentHedging;

          dataMap[_sId][_sType].whiteList = response[section].whiteList;

          if (wysiwygState.targetType === "SYSTEMATIC") {
            const adaptedParams = deepClone(response[section].strategy.params);
            //! Warning the wysiwyg target for systematic is
            //! replaced by strategy for compatibility with other
            //! widgets.
            adaptedParams.strategy.benchmark =
              wysiwygState.target.benchmark ??
              wysiwygState.originalTarget?.benchmark;
            adaptedParams.strategy.currency =
              wysiwygState.target.currency ??
              wysiwygState.originalTarget?.currency;
            dataMap[_sId][_sType].params = adaptedParams;
          } else {
            dataMap[_sId][_sType].params = response[section].strategy.params;
          }

          // Disable backtesting if there is a custom report date set
          dataMap[_sId][_sType]["hasCustomStartingDate"] =
            this.hasStrategyLaunchDate;
          dataMap[_sId][_sType]["hasCustomEndDate"] = this.hasStrategyFinalDate;

          break;
        }
        case "REPORT_STRATEGY_YEARLY_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].analytics = response[section].analytics;
          dataMap[_sId][_sType].benchmark = response[section].benchmark;
          break;
        }
        case "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS": {
          this._addProperty(dataMap[_sId], _sType);

          dataMap[_sId][_sType].analytics = response[section].analytics;
          dataMap[_sId][_sType].benchmark = response[section].benchmark;
          break;
        }
        default:
          console.log("Unrecognized type " + wysiwygState.targetType);
          break;
        // #endregion ----------------------------------------------
      }
    }

    // reorder stuff: from map to array
    const data: any = [];
    for (let i = 0; i < sections.length; i++) {
      const datum = dataMap[sections[i].id][sections[i].type];
      datum.type = sections[i].type;
      data.push(datum);
    }

    return { response: data, _wysiwygState: wysiwygState };
  }

  /**
   * Retrieve all data, starting point
   *
   * It does get the primary data (like the strategy).
   *
   * Chain: retrieve -> _compute -> _retrieve (original retrieve method)
   *
   */
  async retrieve() {
    let responseAll;
    try {
      responseAll = await this.inputManager.retrieve();
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.trace(e.message);
      }
      throw e;
    }

    this._computing = this.inputManager._computing;
    this.strategyFinalDate = this.inputManager.strategyFinalDate;
    this.strategyLaunchDate = this.inputManager.strategyLaunchDate;
    this.hasStrategyFinalDate = this.inputManager.hasStrategyFinalDate;
    this.hasStrategyLaunchDate = this.inputManager.hasStrategyLaunchDate;
    return this.prepare(responseAll);
  }
  // ----------------------------------------------------- private methods
  _addProperty(object, property) {
    if (!(property in object) || object[property] == null) {
      object[property] = {};
    }
  }

  _allocationData(rawData, meaningful, sortByWeight) {
    const data: any = [];
    if (meaningful == null) {
      meaningful = 4;
    }

    for (const id in rawData) {
      const item = rawData[id];
      data.push({
        id: id,
        rate: item.TCR,
        weight: item.weight,
      });
    }

    if (sortByWeight) {
      this._allocationSortWeight(data); // 2019-03-20 same of UI
    } else {
      this._allocationSort(data); // 2019-03-20 same of UI
    }

    if (data.length >= meaningful + 1) {
      const others = data.splice(meaningful, data.length);
      const item = {
        id: "OTHER",
        rate: null,
        weight: 0,
      };

      for (let i = 0, length = others.length; i < length; i++) {
        item.weight = item.weight + others[i].weight;
      }

      data.push(item);
    }

    return data;
  }

  _allocationSort(data) {
    // same of
    // app/modules/analysis-collection/analysis/widgets/WhatWhereAllocation
    // sort by rate (D->A), weight (10 -> 0)
    data.sort(function (a, b) {
      const sortByL1 = "rate";
      const sortByL2 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return 1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return -1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  }

  _allocationSortWeight(data) {
    data.sort(function (a, b) {
      const sortByL1 = "weight";
      const sortByL2 = "rate";

      if (a[sortByL1] > b[sortByL1]) {
        return -1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return 1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  }

  _allocationSvg(
    rawData: any,
    sortByWeight: boolean,
    colorByPosition: boolean = false
  ) {
    const chartNode = document.createElement("div");
    chartNode.style.width = "600px";
    document.body.appendChild(chartNode);
    const data: any = [];
    const dataSerie: any = [];
    let rateMeta;
    const ratingScale = _rate.trendCaptureRating;
    let currentColor = 0;
    function getChartColor() {
      if (currentColor >= _color.trendratingColors.length) {
        currentColor = 0;
      }
      return _color.trendratingColors[currentColor++];
    }

    for (const id in rawData) {
      const item = rawData[id];
      data.push({
        id: id,
        rate: item.TCR,
        weight: item.weight,
      });
    }

    if (sortByWeight) {
      this._allocationSortWeight(data); // 2019-03-20 same of UI
    } else {
      this._allocationSort(data); // 2019-03-20 same of UI
    }

    let totalWeight = 0;
    for (let i = 0, length = data.length; i < length; i++) {
      const item = data[i];
      totalWeight = totalWeight + item["weight"];
      rateMeta = ratingScale[item.rate == null ? "U" : String(item.rate)];
      dataSerie.push({
        color: colorByPosition ? getChartColor() : rateMeta["colorChart"],
        name: rateMeta["label"],
        y: item["weight"],
      });
    }
    // cash
    if (totalWeight < 1) {
      rateMeta = ratingScale["CASH"];
      dataSerie.push({
        color: rateMeta["colorChart"],
        name: rateMeta["label"],
        trendrating: null,
        y: 1 - totalWeight,
      });
    }

    const chart = new Highcharts.Chart({
      chart: {
        animation: false,
        height: 250,
        margin: 0,
        renderTo: chartNode,
        type: "pie",
        width: 250,
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: { text: undefined },
      tooltip: { enabled: false },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          borderColor: undefined,
          borderWidth: 1,
          cursor: "pointer",
          dataLabels: { enabled: false },
          innerSize: "60%",
          shadow: false,
        },
      },
      series: [
        {
          type: "pie",
          name: "Rating",
          animation: false,
          data: dataSerie,
        },
      ],
    });

    const svg = chart.getSVG();
    chart.destroy();

    // chartNode will go out of scope so no need to also set it to null
    document.body.removeChild(chartNode);

    return svg;
  }

  _breakdownData(rawData, meaningful, sortByWeight) {
    const data: any = [];
    if (meaningful == null) {
      meaningful = 4;
    }

    for (const id in rawData) {
      const item = rawData[id];
      data.push({
        card: item.cardinality,
        id: id,
        rate: item.TCR,
        weight: item.weight,
      });
    }

    if (sortByWeight) {
      this._allocationSortWeight(data); // 2019-03-20 same of UI
    } else {
      this._allocationSort(data); // 2019-03-20 same of UI
    }

    if (data.length >= meaningful + 1) {
      const others = data.splice(meaningful, data.length);
      const item = {
        card: 0,
        id: "OTHER",
        rate: null,
        weight: 0,
      };
      for (let i = 0, length = others.length; i < length; i++) {
        item.card = item.card + others[i].card;
        item.weight = item.weight + others[i].weight;
      }
      data.push(item);
    }

    return data;
  }

  // CHART

  /**
   *
   * @param {array} data
   * @param {object} boundaries
   * @param {number} translationFactor
   */
  _fromJsonToSerieAndMinMax(data, boundaries, translationFactor?: number) {
    const serie: any = [];
    for (let i = 0, length = data.length; i < length; i++) {
      const vy = parseFloat(data[i]["v"]);
      if (vy > boundaries.max) {
        boundaries.max = vy;
      }
      if (vy < boundaries.min) {
        boundaries.min = vy;
      }
      serie.push([
        // the date
        TDate.daysToMilliseconds(data[i]["d"]),
        // close
        translationFactor != null ? vy / translationFactor : vy,
      ]);
    }
    return serie;
  }

  _dispersionChartSvg(serie, selectedInterval) {
    const chartNode = document.createElement("div");
    chartNode.style.width = "632px";
    document.body.appendChild(chartNode);

    const colors = {
      top: "#008000",
      middle: "#ff9900",
      bottom: "#ff0000",
    };

    const peerSerie = serie;

    /**
     * TODO: implement sort on the serie
     */

    const topSerie: any = [];
    const midSerie: any = [];
    const bottomSerie: any = [];
    const categories: any = peerSerie.map((item) => item.name);

    let min = 0;
    let max = 0;

    for (const item of peerSerie) {
      topSerie.push({
        custom: item,
        y: item.top,
      });
      midSerie.push({
        custom: item,
        y: item.middle,
      });
      bottomSerie.push({
        custom: item,
        y: item.bottom,
      });

      // Prepare min / max
      min = Math.min(min, item.top, item.middle, item.bottom);
      max = Math.max(max, item.top, item.middle, item.bottom);
    }

    //Calculate the correct interval ticker for yAxis
    const decimals = 1;

    function truncate(val: number, n: number) {
      let toFixed = val.toFixed(n);
      return Number.parseFloat(toFixed);
    }

    var adjustStep = function (step) {
      var i = 1;
      var m = step;
      var adjStep = 1;
      if (step < 1) {
        while (m < 1) {
          adjStep = Math.pow(10, i);
          m = m * adjStep;
          i++;
        }
      } else if (step >= 10 && step < 100) {
        adjStep = Math.pow(10, -1);
      }

      return adjStep;
    };

    var floor = function (min, step) {
      var floor = min;
      var f = 0;
      if (min < 0) {
        while (f > min) {
          f -= step;
        }
        floor = f;
      } else if (min > 0) {
        while (f < min) {
          f += step;
        }
        floor = f - step;
      }

      return floor;
    };

    var getStep = function (min, max, desiredSteps) {
      //moltiplicatore sufficientemente grande per avere sicuramente estremi interi
      var mult = 10000000000000;
      var minMult = min * mult;
      var maxMult = max * mult;
      //calcoliamo lo step intero minimo che soddisfa la disequazione (max-min)/n<step
      var result = Math.ceil((maxMult - minMult) / desiredSteps);
      //step esatto
      var step = result / mult;
      //------------PLOT STEP

      return step;
    };

    var plot = function (min, max, step) {
      let i = 0;
      let x = min;
      while (x < max) {
        x = min + step * i;
        i++;
      }
    };

    var desiredSteps = 10;

    var step = getStep(min, max, desiredSteps);
    //qui calcoliamo lo step arrotondato al decimale superiore
    var adjStep = adjustStep(step);

    var stepToRound = step * adjStep;

    //step arrotondato al decimale superiore
    var finalStep = Math.ceil(stepToRound) / adjStep;
    var finalMin = floor(min, finalStep);

    plot(finalMin, max, finalStep);

    const interval = selectedInterval;
    let intervalLabel = {
      top: "",
      middle: "",
      bottom: "",
    };

    switch (interval) {
      case 25:
      case 4:
        intervalLabel.top = "25%";
        intervalLabel.middle = "50%";
        intervalLabel.bottom = "25%";
        break;
      case 10:
        intervalLabel.top = "10%";
        intervalLabel.middle = "80%";
        intervalLabel.bottom = "10%";
        break;
      case 5:
      case 20:
        intervalLabel.top = "5%";
        intervalLabel.middle = "90%";
        intervalLabel.bottom = "5%";
        break;
      // no default
    }

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: `Top ${intervalLabel.top}`,
        color: colors.top,
        data: topSerie,
        type: "column",
      },
      {
        name: `Mid ${intervalLabel.middle}`,
        color: colors.middle,
        data: midSerie,
        type: "column",
      },
      {
        name: `Bottom ${intervalLabel.bottom}`,
        color: colors.bottom,
        data: bottomSerie,
        type: "column",
      },
    ];

    const chart = new Highcharts.Chart({
      chart: {
        animation: false,
        borderWidth: 0,
        // height: 300,
        type: "column",
        renderTo: chartNode,
      },
      title: {
        text: undefined,
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: { enabled: true, verticalAlign: "top" },
      scrollbar: { enabled: false },
      navigator: { enabled: false },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        visible: true,
        categories: categories,
        startOnTick: true,
        endOnTick: true,
        gridLineColor: "#e6e6e6",
        gridLineWidth: 1,
        labels: { enabled: true },
        tickColor: "#e6e6e6",
      },
      yAxis: {
        tickPositioner: function () {
          const self: any = this;
          var positions: any = [],
            tick = finalMin,
            increment = finalStep;

          if (self.dataMax !== null && self.dataMin !== null) {
            for (tick; tick - increment < self.dataMax; tick += increment) {
              positions.push(truncate(tick, decimals));
            }
          }
          return positions;
        },
        showLastLabel: true,
        opposite: false,
        labels: {
          formatter: function () {
            return ((this.value as number) * 100).toFixed(0) + "%";
          },
        },
        startOnTick: false,
        endOnTick: false,
        maxPadding: 0,
        minPadding: 0,
      },
      series: series,
      plotOptions: {
        series: {
          animation: false,
          dataSorting: {
            enabled: false,
          },
          states: { inactive: { opacity: 1 } },
        },
        column: {
          dataLabels: {
            enabled: false,
            format: "{y}%",
          },
        },
      },
    });

    const svg = chart.getSVG();
    chart.destroy();

    // chartNode will go out of scope so no need to also set it to null
    document.body.removeChild(chartNode);

    return svg;
  }

  /**
   *
   * @param {object}  params
   * @param {boolean} params.legend
   * @param {boolean} params.logAxis
   * @param {string}  params.title
   * @param {object}  strategy
   * @param {object}  data
   */
  _strategyChartSvg(params, strategy, data) {
    const chartNode = document.createElement("div");
    chartNode.style.width = "632px";
    document.body.appendChild(chartNode);
    const pboundaries = { max: 0, min: 999999 };
    let price;
    let benchmark;
    const colors = {
      border: "#d3d3d3",
      lineGrid: "#d3d3d3",
      line: "#d3d3d3",
      securityDefault: "#2a7092", // #2F7ED8
      securityBenchmark: "#ffcc00", // #133458
      securityRateA: _rate.colors.A,
      securityRateB: _rate.colors.B,
      securityRateC: _rate.colors.C,
      securityRateD: _rate.colors.D,
      securityRateU: _rate.colors.U,
      trendrating: "#2a7092", // #ffc001 #0da760,
      trendratingGold: "#ffc001",
      trendratingGrey: "#a3a3a3",
    };

    if (
      data.CURVES.hasOwnProperty("B") &&
      data.CURVES.B !== null &&
      data.CURVES.B.length > 0
    ) {
      price = this._fromJsonToSerieAndMinMax(data.CURVES.H, pboundaries);

      let indexOfFirstBenchmarkDay = 0;
      for (let i = 0, length = data.CURVES.H.length; i < length; i++) {
        if (data.CURVES.H[i].d >= data.CURVES.B[0].d) {
          indexOfFirstBenchmarkDay = i;
          break;
        }
      }

      const _translationFactor =
        data.CURVES.B[0].v / price[indexOfFirstBenchmarkDay]["1"];
      benchmark = this._fromJsonToSerieAndMinMax(
        data.CURVES.B,
        pboundaries,
        _translationFactor
      );
    } else {
      price = this._fromJsonToSerieAndMinMax(data.CURVES.H, pboundaries);
    }

    const axys: Highcharts.YAxisOptions[] = [
      {
        lineColor: colors["lineGrid"],
        lineWidth: 1,
        type: params.logAxis ? "logarithmic" : "linear",
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        opposite: false,
        showEmpty: false,
        title: {
          text: null,
        },
      },
      {
        lineColor: colors["lineGrid"],
        lineWidth: 1,
        type: params.logAxis ? "logarithmic" : "linear",
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
        opposite: false,
        showEmpty: false,
        title: {
          text: null,
        },
      },
    ];

    const series: any = [];

    const sPrice = {
      id: "price",
      //! we want to show the name of the product when
      //! reporting systematic portfolios, not the strategy name
      name:
        this.wysiwygState.targetType === "SYSTEMATIC"
          ? this.wysiwygState.originalTarget.name
          : strategy.name,
      data: price,
      yAxis: 0,
      color: colors["trendrating"],
      index: 0,
      lineWidth: 2,
      marker: { enabled: false },
      showInLegend: true,
      tooltip: {
        valueDecimals: 2,
      },
    };
    series.push(sPrice);

    if (params.benchmark) {
      const benchmarkInfo = params.benchmark;
      let benchmarkName: any = undefined;

      if (benchmarkInfo != null) {
        if (benchmarkInfo?.name != null) {
          benchmarkName = benchmarkInfo.name;
        } else if (
          benchmarkInfo?.data?.[0] === Strategies.SYMBOL_NEUTRAL_STRATEGY
        ) {
          benchmarkName = "Neutral strategy";
        } else if (
          benchmarkInfo?.data?.[0] ===
          Strategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED
        ) {
          benchmarkName = "Neutral strategy equal weighted";
        } else {
          benchmarkName = benchmarkInfo?.data?.[0]?.name ?? undefined;
        }
      }

      const sBenchmark = {
        id: "benchmark",
        name: benchmarkName,
        data: benchmark,
        yAxis: 0,
        color: colors["securityBenchmark"],
        index: 1,
        lineColor: colors["securityBenchmark"],
        lineWidth: 2,
        marker: { enabled: false },
        showInLegend: true,
        tooltip: {
          valueDecimals: 2,
        },
      };
      series.push(sBenchmark);
    }

    const chart = new Highcharts.StockChart({
      chart: {
        alignTicks: false,
        animation: false,
        height: 474,
        borderRadius: 0,
        borderWidth: 0,
        renderTo: chartNode,
        type: "line",
        width: 750,
        zoomType: "x",
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: params["legend"],
        itemHiddenStyle: {
          color: "#000",
          textDecoration: "none",
        },
        itemStyle: {
          fontSize: "9px",
          color: "#000",
          textDecoration: "underline",
        },
        symbolWidth: 10,
      },
      rangeSelector: { enabled: false },
      navigator: { enabled: false },
      credits: { enabled: false },
      title: {
        text: params["title"],
      },
      tooltip: { enabled: false },
      scrollbar: { enabled: false },
      plotOptions: {
        arearange: {
          fillOpacity: 0.2,
        },
      },
      yAxis: axys,
      series: series,
    });

    const svg = chart.getSVG();
    chart.destroy();

    // chartNode will go out of scope so no need to also set it to null
    document.body.removeChild(chartNode);

    return svg;
  }
}
