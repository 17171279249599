import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { TaxonomySelect } from "../../../../../../../../../../../../components/TaxonomySelect/TaxonomySelect";
import { useTaxonomyByType } from "../../../../../../../../../../../../hooks/useTaxonomyByType";
import { deepClone } from "../../../../../../../../../../../../deepClone";

type Props = {
  markets: any;
  marketSelectionFilterHandler: any;
};

export default function MarketSelector({
  markets,
  marketSelectionFilterHandler,
}: Props) {
  const [panelInit, setPanelInit] = useState<string[]>([]);

  const [filtersInit, setFiltersInit] = useState<{
    domestic: boolean;
    foreign: boolean;
    type: string[];
  }>();
  const panelRef = useRef<any>(null);
  const { getTaxonomyMap, advancedNodesSelector } = useTaxonomyByType("stock");
  const taxonomiesMap = useMemo(() => {
    return getTaxonomyMap("security", "country");
  }, [getTaxonomyMap]);
  // const stockClassTaxonomies = useMemo(() => {
  //   let txList = getTaxonomyMap("Stock", "stockclass");
  //   txList = txList.reduce((prev, current) => {
  //     prev[current.id] = current;

  //     return prev;
  //   }, {});

  //   return txList;
  // }, [getTaxonomyMap]);

  const selectedValues = useMemo(() => {

    if (markets && markets.length) {
      const values: any = [];
      const panelSelectedCheckboxes: string[] = [];
      const marketsConstraints = markets;
      const marketsAdditionalFilters: typeof filtersInit = {
        domestic: false,
        foreign: false,
        type: [],
      };
      const additionalFiltersToShow: string[] = [];
      const stockclassType: string[] = [];

      marketsConstraints.forEach((constraint) => {
        if (constraint.segments.length) {
          constraint.segments.forEach((segment) => {
            switch (constraint.dimension) {
              case "country":
                values.push(taxonomiesMap?.[segment]?.name ?? "");
                panelSelectedCheckboxes.push(segment);
                break;
              // case "stockclass":
              //   stockclassType.push(stockClassTaxonomies[segment]?.name ?? "");
              //   marketsAdditionalFilters.type.push(segment);

              //   break;
              case "subtype":
                additionalFiltersToShow.push(segment);
                if (segment === "Domestic Stock") {
                  marketsAdditionalFilters.domestic = true;
                } else {
                  marketsAdditionalFilters.foreign = true;
                }
            }
          });
        }
      });

      setPanelInit(panelSelectedCheckboxes);
      setFiltersInit(marketsAdditionalFilters);
      if (stockclassType.length) {
        const stockclassFilter = stockclassType.join(", ");
        additionalFiltersToShow.push(`(${stockclassFilter})`);
      }

      let result: string[] = [];

      if (values.length) {
        if (values.length > 3) {
          const firstSelected = values[0];
          result = [firstSelected, `+ ${values.length - 1}`];
        } else {
          result = values;
        }
      } else {
        result = [""];
      }

      if (additionalFiltersToShow.length) {
        result.push(...additionalFiltersToShow);
      }

      return result;
    }else{
      return [""]
    }
  }, [markets, taxonomiesMap]);



  const handlePanelAppearence = useCallback((value: boolean) => {
    if (panelRef.current) {
      panelRef.current.showTreePanel(value);
    }
  }, []);

  return (
    <TaxonomySelect
      ref={panelRef}
      taxonomyInfo={{ type: "security", field: "country" }}
      panelTitle={"Market"}
      hasFilters={true}
      onSetFilters={(value)=>{
        if(value.length>0){
          const temp = deepClone(value);
          const newSegments  = advancedNodesSelector(value[0].value.segments, "country", true);
          temp[0].value.segments = newSegments;
          marketSelectionFilterHandler(temp) 
        }else{
          marketSelectionFilterHandler([])
        }

        
        }}
      defaultValue={panelInit}
      initFiltersState={filtersInit}
      hideStockClassFilter={true}
    >
      <Card
        onClick={() => handlePanelAppearence(true)}
        sx={{
          position: "relative",
          border: "1px solid",
          borderColor: "transparent",
          transition: "0.5s",
          cursor: "pointer",
          "&:hover": {
            borderColor: "#2a7090",
          },
          display: "flex",
        }}
      >
      
        <CardContent
          sx={{
            padding: "2px 6px!important",

            display: "flex",
            flex: 1,
          }}
        >
          <Box
            mr={0}
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            flex={1}
          >
            <Typography mr={markets.length ? "10px" : 0}>
              <strong>{"Markets"}</strong>
            </Typography>
            {markets && markets.length > 3 ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                flex={1}
                justifyContent={"center"}
              >
                {" "}
                <Typography>Many countries</Typography>
              </Box>
            ) : (
              <Box
                component={"ul"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                flex={1}
                justifyContent={"center"}
                gap={0.2}
              >
                {selectedValues.map((filter, index) => {
                  return (
                    <li key={index}>
                      <Typography>{filter}</Typography>
                    </li>
                  );
                })}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </TaxonomySelect>
  );
}
