export const defaultTemplateReportRanking = [
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Rank",
              isEnabled: false,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: {
              property: "rank",
              descending: false,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: true,
            useWysiwyg: true,
            sortByRank: false,
          },
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Print current view",
    ownerId: null,
    type: "TEMPLATE_REPORT_RANKING",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Rank",
              isEnabled: false,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: {
              property: "rank",
              descending: false,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: true,
            sortByRank: true,
          },
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Ranking",
    ownerId: null,
    type: "TEMPLATE_REPORT_RANKING",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Rank",
              isEnabled: false,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: {
              property: "industry",
              descending: false,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: true,
          },
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Ranking - Group by Sector",
    ownerId: null,
    type: "TEMPLATE_REPORT_RANKING",
    version: "1.0",
  },
];
