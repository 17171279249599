import { Box, Skeleton, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { config } from "../../config-ts";
import { InfoTooltip } from "./InfoTooltip";
import { Lists } from "./widgets/lists/Lists";
import { Markets } from "./widgets/markets/Markets";
import { News } from "./widgets/news/News";
import { Pinned } from "./widgets/pinned/Pinned";

type AlertsPageProps = {
  getDataGateway: (
    endpoint:
      | "pinned"
      | "listsData"
      | "markets"
      | "peersAndInstrumentsNews"
      | "indexNews"
      | "sectorNews",
    timeframe
  ) => any;
  timeframeInit: "today" | "lastWeek" | "lastMonth";
  onSavePreferences: Function;
  getNewsFiltered: (
    filters: any,
    type: "stock" | "etf",
    setFunction: (value) => void,
    timeframe
  ) => void;
  createListner: (listType: "basket" | "portfolio") => void;
  onSaveFilters: (filters, type) => void;
};

type InitState = {
  portfoliosData: any;
  portfoliosTotal: any;
  basketsTotal: any;
  basketsData: any;
  summaryAlertsData: any;
  pinnedData: any;
  marketsNewsData: any;
  indicesData: any;
  sectorsData: any;
  etfs: any;
  stocks: any;
  loadingList: boolean;
  loadingPinned: boolean;
  loadingSummaryAlerts: boolean;
  loadingStocks: boolean;
  loadingIndices: boolean;
  loadingMarkets: boolean;
  loadingEtfs: boolean;
};

class AlertCache {
  state: InitState;
  fetchData: (dataId, timeframe) => any;
  timeframe: any;

  constructor(
    initState: InitState,
    fetchData: (dataId, timeframe) => any,
    timeframe: any
  ) {
    this.state = initState;
    this.fetchData = fetchData;
    this.timeframe = timeframe;
  }

  public invalidate() {
    for (const key in this.state) {
      this.state[key] = undefined;
    }
  }

  public async get(key: string) {
    if (this.state[key] != null) {
      return this.state[key];
    }

    const response = await this.getData(key, this.timeframe);

    this.set(key, response);

    return this.state[key];
  }

  private set(key: string, value: any) {
    this.state[key] = value;
  }

  private async getData(key: string, timeframe) {
    switch (key) {
      case "lists": {
        const data = await this.fetchData("listsData", timeframe);

        return {
          baskets: data.basketsData,
          portfolios: data.portfoliosData,
          portfoliosTotal: data.portfoliosTotal,
          basketsTotal: data.basketsTotal,
        };
      }

      case "summaryAlerts": {
        const data = await this.fetchData("markets", timeframe);
        return data.summaryAlertsData;
      }

      case "pinned": {
        const data = await this.fetchData("pinned", timeframe);
        return data.pinnedData;
      }

      case "marketsNewsData": {
        const data = await this.fetchData("peersAndInstrumentsNews", timeframe);
        return data.marketsNewsData;
      }

      case "sectorsData": {
        const data = await this.fetchData("sectorNews", timeframe);

        return data.sectorsData;
      }

      case "indicesData": {
        const data = await this.fetchData("indexNews", timeframe);

        return data.indicesData;
      }
    }
  }
}

export function AlertsPage({
  getDataGateway,
  onSavePreferences,
  getNewsFiltered,
  onSaveFilters,
  createListner,
  timeframeInit,
}: AlertsPageProps) {
  const { t } = useTranslation();
  const [timeframe, setTimeFrame] = useState<
    "today" | "lastWeek" | "lastMonth"
  >(timeframeInit ?? "today");
  const initState = useMemo(
    () => ({
      portfoliosData: undefined,
      portfoliosTotal: undefined,
      basketsTotal: undefined,
      basketsData: undefined,
      summaryAlertsData: undefined,
      pinnedData: undefined,
      marketsNewsData: undefined,
      indicesData: undefined,
      sectorsData: undefined,
      etfs: undefined,
      stocks: undefined,
      loadingList: true,
      loadingPinned: true,
      loadingSummaryAlerts: true,
      loadingStocks: false,
      loadingIndices: true,
      loadingMarkets: true,
      loadingEtfs: false,
    }),
    []
  );

  const [lists, setLists] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [summaryAlerts, setSummaryAlerts] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [pinned, setPinned] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [marketsData, setMarketsData] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [sectorsData, setSectorsData] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [indicesData, setIndicesData] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "loading" });
  const [stocks, setStocks] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "success" });
  const [etfs, setEtfs] = useState<{
    data: any;
    status: "loading" | "success" | "error";
  }>({ data: null, status: "success" });

  const [loadingQueue, setLoadingQueue] = useState<string[]>([]);

  const handleStocksDataGet = useCallback(
    (filters) => {
      // setStocks({ data: undefined, status: "loading" });

      const getData = (data) => {
        setStocks({ data, status: "success" });
      };

      getNewsFiltered(filters, "stock", getData, timeframe);
    },
    [getNewsFiltered, timeframe]
  );

  const stocksProps = useMemo(
    () => ({
      saveHandler: (filters) => onSaveFilters(filters, "stock"),
      stateChangeHandler: handleStocksDataGet,
    }),
    [handleStocksDataGet, onSaveFilters]
  );

  const handleETFsDataGet = useCallback(
    (filters) => {
      // setEtfs({ data: undefined, status: "loading" });

      const getData = (data) => {
        setEtfs({ data, status: "success" });
      };

      getNewsFiltered(filters, "etf", getData, timeframe);
    },
    [getNewsFiltered, timeframe]
  );

  const etfsProps = useMemo(
    () => ({
      saveHandler: (filters) => onSaveFilters(filters, "etfs"),
      stateChangeHandler: handleETFsDataGet,
    }),
    [handleETFsDataGet, onSaveFilters]
  );

  const cache = useMemo(
    () => new AlertCache(initState, getDataGateway, timeframe),
    [getDataGateway, initState, timeframe]
  );

  const reload = useCallback(() => {
    cache.invalidate();
    setLoadingQueue((current) => [...current]);
  }, [cache]);

  const changeTimeFrame = useCallback(
    (value) => {
      setTimeFrame(value);
      reload();
    },
    [reload]
  );

  useEffect(() => {
    if (loadingQueue.length) {
      for (const key of loadingQueue) {
        switch (key) {
          case "lists":
            setLists({ data: undefined, status: "loading" });
            cache.get(key).then((res) => {
              setLists({ data: res, status: "success" });
            });
            break;

          case "summaryAlerts":
            setSummaryAlerts({ data: undefined, status: "loading" });
            cache
              .get(key)
              .then((res) =>
                setSummaryAlerts({ data: res, status: "success" })
              );

            break;

          case "pinned": {
            setPinned({ data: undefined, status: "loading" });
            cache
              .get(key)
              .then((res) => setPinned({ data: res, status: "success" }));

            break;
          }

          case "news": {
            setMarketsData({ data: undefined, status: "loading" });
            cache
              .get("marketsNewsData")
              .then((res) => setMarketsData({ data: res, status: "success" }));

            setSectorsData({ data: undefined, status: "loading" });
            cache
              .get("sectorsData")
              .then((res) => setSectorsData({ data: res, status: "success" }));

            setIndicesData({ data: undefined, status: "loading" });
            cache
              .get("indicesData")
              .then((res) => setIndicesData({ data: res, status: "success" }));

            break;
          }
        }
      }
    }
  }, [cache, loadingQueue]);

  // Lazy loading
  const observerHandler = useCallback((entries, observer) => {
    entries.forEach((en) => {
      if (en.isIntersecting) {
        setLoadingQueue((current) => [...current, en.target.dataset.component]);
        observer.unobserve(en.target);
      }
    });
  }, []);
  const listsRowRef = useRef<HTMLDivElement>(null);
  const pinnedRowRef = useRef<HTMLDivElement>(null);
  const summaryAlertsRowRef = useRef<HTMLDivElement>(null);
  const newsRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targets: any[] = [];

    if (listsRowRef.current) {
      targets.push(listsRowRef.current);
    }

    if (pinnedRowRef.current) {
      targets.push(pinnedRowRef.current);
    }

    if (summaryAlertsRowRef.current) {
      targets.push(summaryAlertsRowRef.current);
    }

    if (newsRowRef.current) {
      targets.push(newsRowRef.current);
    }
    const observer = new IntersectionObserver(observerHandler, {
      threshold: 0.1,
    });

    if (targets.length) {
      targets.forEach((target) => observer.observe(target));
    }
  }, [observerHandler]);

  const timeframeOptions = useMemo(
    () => [
      {
        label: "Today",
        selected: true,
        value: "today",
      },
      {
        label: "Last Week",
        value: "lastWeek",
      },
      {
        label: "Last Month",
        value: "lastMonth",
      },
    ],
    []
  );

  const printAlertsReport = useCallback(async () => {
    // ***************** USAGE *****************
    var usage = window.App.usage;
    var info = {
      action: "REPORT",
      actionParam: null,
      function: "ALERT",
    };
    usage.record(info);
    // ***************** USAGE *****************

    const value = {
      today: "alerts",
      lastWeek: "alerts_w",
      lastMonth: "alerts_m",
    };
    window.open(
      `/app/pdf/market/${value[timeframe]}`,
      "_blank",
      "rel=noopener noreferrer"
    );
  }, [timeframe]);

  const { broadcast } = useBroadcast();

  /**
   * Manage Workflow
   */
  useEffect(() => {
    let actions: any = [];

    let action: any = null;

    action = {
      componentJSX: (
        <li className="menu__item" onClick={printAlertsReport}>
          Report
        </li>
      ),
    };

    actions.push(action);

    var message = {
      from: "Alerts",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);

    return () => {
      var message = {
        from: "Alerts",
        content: {
          actions: [],
        },
      };

      broadcast(config["channels"]["workflow"]["input"], message);
    };
  }, [broadcast, printAlertsReport]);

  return (
    <article className="tPageAlerts--1 tPage">
      <h1 className="tPage-title tPage-title--alerts--1">
        {t("what_s_new")}
        <Box component={"ul"} display={"flex"} gap={2}>
          {timeframeOptions.map((opt) => (
            <li
              onClick={() => changeTimeFrame(opt.value as any)}
              key={uuidv4()}
              style={
                opt.value !== timeframe
                  ? { cursor: "pointer", padding: "6px 0" }
                  : {
                      cursor: "pointer",
                      color: "#2a7090",
                      borderBottom: "2px solid #2a7090",
                      padding: "6px 0",
                    }
              }
            >
              <span>{opt.label}</span>
            </li>
          ))}
        </Box>
      </h1>

      <div className="tLayout-item--1">
        <div
          ref={listsRowRef}
          className="tPageAlerts-lists--1"
          data-component="lists"
        >
          <div
            className="tPageAlerts-portfolios--1"
            data-dojo-attach-point="nodePortfolios"
          >
            <div className="portfolio-widget-title">
              <h2 data-dojo-attach-point="nodePortfoliosTitle">
                {t("portfolios")}
              </h2>
              <div data-dojo-attach-point="portfolioTooltipNode">
                <InfoTooltip
                  actionInfo="Please upload all your equity portfolios in order to enable this functionality. To learn how to upload your portfolios please go to the Help tab – Tutorials."
                  callToAction="Bring your portfolio risk management to the next level"
                  text={`Gain more control on the “changing price trends risk”.  Monitor the exposure to negative trends for any portfolio.  A “TCR” below B+ signals a dangerous “ trend allocation”,  Check the downgrades in the portfolios TCR to spot increasing risks.  Identify negative trend reversals across the holdings via the “downgrade” column.`}
                  title={"Portfolios"}
                />
              </div>
            </div>

            <div
              data-dojo-attach-point="widgetTablePortfolios"
              style={{ display: "flex" }}
            >
              <ErrorBoundary
                fallback={<h2>Error during loading portfolio data</h2>}
              >
                {lists.status === "loading" ? (
                  <Stack spacing={1} width={"100%"}>
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                  </Stack>
                ) : (
                  <Lists
                    listType="portfolio"
                    createListner={createListner}
                    data={lists.data.portfolios ?? []}
                    timeframe={timeframe}
                    total={lists.data.portfoliosTotal as any}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>

          <div
            className="tPageAlerts-baskets--1"
            data-dojo-attach-point="nodeBaskets"
          >
            <div className="portfolio-widget-title">
              <h2 data-dojo-attach-point="nodeBasketsTitle">{t("baskets")}</h2>
              <div data-dojo-attach-point="basketTooltipNode">
                <InfoTooltip
                  title={"Baskets"}
                  text={`The TCR of a basket measures the distribution between stocks in a bull trend ( A and B rated)  and securities in a bear phase  ( C and D rated ) , with a reasonable level of accuracy.  The lower is the TCR the weaker is the overall picture.   Identify potential trend reversals in the list of stocks by monitoring the upgrades and downgrades .`}
                  callToAction="Bring your portfolio risk management to the next level"
                />
              </div>
            </div>

            <div
              data-dojo-attach-point="widgetTableBaskets"
              style={{ display: "flex", minWidth: 0 }}
            >
              <ErrorBoundary
                fallback={<h2>Error during loading basket data</h2>}
              >
                {lists.status === "loading" ? (
                  <Stack spacing={1} width={"100%"}>
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                  </Stack>
                ) : (
                  <Lists
                    listType="basket"
                    createListner={createListner}
                    data={lists.data.baskets ?? []}
                    timeframe={timeframe}
                    total={lists.data.basketsTotal as any}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>

      <div
        className="tPageAlerts-pinned--1"
        ref={pinnedRowRef}
        data-component="pinned"
      >
        <div data-dojo-attach-point="widgetPinned" className="tPinnedObjects">
          {pinned.status === "loading" ? (
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "20px", marginBottom: "20px" }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          ) : (
            <Pinned
              onSavePreferences={(event, state) =>
                onSavePreferences(event, state)
              }
              data={pinned.data ?? []}
              timeframe={timeframe}
            />
          )}
        </div>
      </div>

      <div className="tLayout">
        <div className="tLayout-item__market tLayout-item--width-100">
          <div className="tPageAlerts-instrumentsAndPeers--1">
            <div
              className="tPageAlerts-markets"
              ref={summaryAlertsRowRef}
              data-component="summaryAlerts"
            >
              <h2 className="markets-title">
                Summary <br />
                of Alerts
                <span data-dojo-attach-point="summaryAlertsTooltipNode">
                  <InfoTooltip
                    callToAction="Bring your portfolio risk management to the next level"
                    title="Summary of alerts"
                    text="A valuable snapshot on trend developments.  The ratio of upgrades vs. downgrades for the last 20 days provides a market assessment for “ risk on “ / “ risk off “ decisions."
                  />
                </span>
              </h2>
              <div data-dojo-attach-point="widgetMarkets">
                {summaryAlerts.status === "loading" ? (
                  <Stack spacing={1}>
                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                  </Stack>
                ) : (
                  <Markets
                    markets={summaryAlerts.data ?? []}
                    timeframe={timeframe}
                  />
                )}
              </div>
            </div>
            <div
              className="tPageAlerts-news--1"
              data-dojo-attach-point="nodeNewsReact"
              ref={newsRowRef}
              data-component="news"
            >
              {etfs.status === "loading" ||
              stocks.status === "loading" ||
              indicesData.status === "loading" ||
              marketsData.status === "loading" ? (
                <div className="tNews--1">
                  <div className="tNews-row__item">
                    <div className="tNews-item">
                      {" "}
                      <Stack spacing={1}>
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "20px",
                            marginBottom: "20px",
                          }}
                        />

                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={30}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={30}
                        />
                      </Stack>
                    </div>
                    <div className="tNews-item">
                      {" "}
                      <Stack spacing={1}>
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "20px",
                            marginBottom: "20px",
                          }}
                        />

                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={30}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={30}
                        />
                      </Stack>
                    </div>
                  </div>
                  <div className="tNews-row__item">
                    <Stack spacing={1}>
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      />

                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      />

                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={30}
                      />
                    </Stack>
                  </div>
                </div>
              ) : (
                <News
                  data={{
                    markets: marketsData.data ?? [],
                    sectors: sectorsData.data ?? [],
                    indices: indicesData.data ?? [],
                    etfs: etfs.data ?? [],
                    stocks: stocks.data ?? [],
                  }}
                  timeframe={timeframe}
                  stocksProps={stocksProps}
                  etfsProps={etfsProps}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
