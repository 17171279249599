/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-widgets/form/Options
 * @summary Defines options for select widgets
 *
 */

import { deepClone } from "../../../deepClone";

declare var window: any;
/*

        Interval definition
        ------------------------------------------------------------------------
        eq - equal to
        ge - greater than or equal to
        gt - greater than
        le - less than or equal to
        lt - less than

    */

export const FormOptions = {
  //user: window.App.user,

  get(field) {
    var options: any = [];
    //var user = this.user;
    switch (field) {
      case "ALERT": {
        options = [
          /* { "label": "Any", "value": "None", "labelShort": "Any" },
                    { "label": "Upgrades", "value": null },
                    { "label": "Today upgrades", "value": "1", "labelShort": "Today" },
                    { "label": "Last 5 days upgrades", "value": "5", "labelShort": "Last 5 days" },
                    { "label": "Last 20 days upgrades", "value": "20", "labelShort": "Last 20 days" },
                    { "label": "Last 60 days upgrades", "value": "60", "labelShort": "Last 60 days" },
                    { "label": "Downgrades", "value": null },
                    { "label": "Today downgrades", "value": "-1", "labelShort": "Today" },
                    { "label": "Last 5 days downgrades", "value": "-5", "labelShort": "Last 5 days" },
                    { "label": "Last 20 days downgrades", "value": "-20", "labelShort": "Last 20 days" },
                    { "label": "Last 60 days downgrades", "value": "-60", "labelShort": "Last 60 days" } */
          { label: "Any", value: "None", labelShort: "Any" },
          { label: "Upgrades", value: null },
          {
            label: "Today upgrades",
            value: "U_0",
            labelShort: "Today",
          },
          {
            label: "Last 5 days upgrades",
            value: "U_4",
            labelShort: "Last 5 days",
          },
          {
            label: "Last 20 days upgrades",
            value: "U_19",
            labelShort: "Last 20 days",
          },
          {
            label: "Last 60 days upgrades",
            value: "U_59",
            labelShort: "Last 60 days",
          },
          { label: "Downgrades", value: null },
          {
            label: "Today downgrades",
            value: "D_0",
            labelShort: "Today",
          },
          {
            label: "Last 5 days downgrades",
            value: "D_4",
            labelShort: "Last 5 days",
          },
          {
            label: "Last 20 days downgrades",
            value: "D_19",
            labelShort: "Last 20 days",
          },
          {
            label: "Last 60 days downgrades",
            value: "D_59",
            labelShort: "Last 60 days",
          },
          { label: "Movers", value: null },
          {
            label: "Positive movers",
            value: "MOVERS_POSITIVE",
            labelShort: "Positive",
          },
          {
            label: "Negative movers",
            value: "MOVERS_NEGATIVE",
            labelShort: "Negative",
          },
        ];
        break;
      }
      // case "ASSET_CLASS": {
      //     options = [
      //         {
      //             'label': 'Any',
      //             'value': 'None'
      //         },
      //         {
      //             'label': 'Stocks',
      //             'value': 'Stock'
      //         },
      //         {
      //             'label': 'Sectors',
      //             'value': 'Sector'
      //         },
      //         {
      //             'label': 'ETFs',
      //             'value': 'ETF'
      //         },
      //         {
      //             'label': 'Indices',
      //             'value': 'Index'
      //         },
      //         {
      //             'label': 'Currencies',
      //             'value': 'Currency'
      //         },
      //         {
      //             'label': 'Commodities',
      //             'value': 'Commodity'
      //         }
      //     ];
      //     break;
      // }
      case "HIGH_LOW": {
        options = [
          { label: "Any", value: "None", labelShort: "Any" },
          { label: "New high", value: null },
          {
            label: "1 month new high",
            value: "20",
            labelShort: "1 month",
          },
          {
            label: "3 months new high",
            value: "60",
            labelShort: "3 months",
          },
          {
            label: "6 months new high",
            value: "120",
            labelShort: "6 months",
          },
          {
            label: "12 months new high",
            value: "260",
            labelShort: "12 months",
          },
          { label: "New low", value: null },
          {
            label: "1 month new low",
            value: "-20",
            labelShort: "1 month",
          },
          {
            label: "3 months new low",
            value: "-60",
            labelShort: "3 months",
          },
          {
            label: "6 months new low",
            value: "-120",
            labelShort: "6 months",
          },
          {
            label: "12 months new low",
            value: "-260",
            labelShort: "12 months",
          },
        ];
        break;
      }
      case "NOTIFICATION": {
        options = [
          { label: "Any", value: "None", labelShort: "Any" },
          { label: "Change in duration", value: null },
          {
            label: "Today change in duration",
            value: "T_0_0",
            labelShort: "Today",
          },
          {
            label: "Last week change in duration",
            value: "T_0_4",
            labelShort: "Last week",
          },
          { label: "Change in magnitude", value: null },
          {
            label: "Today change in magnitude",
            value: "P_0_0",
            labelShort: "Today",
          },
          {
            label: "Last week change in magnitude",
            value: "P_0_4",
            labelShort: "Last week",
          },
          { label: "Change in UPI", value: null },
          {
            label: "Today change in UPI",
            value: "U_0_0",
            labelShort: "Today",
          },
          {
            label: "Last week change in UPI",
            value: "U_0_4",
            labelShort: "Last week",
          },
        ];
        break;
      }
      case "MARKET_CAP": {
        options = [
          // new ??? [2019-09-26]
          {
            ge: null,
            le: 300,
            checked: false,
            innerHTML: "Micro",
            label: "Micro Cap",
            node: null,
            title: "Micro Cap ($50M - $300M)",
          },
          {
            ge: 300,
            le: 2000,
            checked: false,
            innerHTML: "Small",
            label: "Small Cap",
            node: null,
            title: "Small Cap ($300M - $2B)",
          },
          {
            ge: 2000,
            le: 10000,
            checked: false,
            innerHTML: "Mid",
            label: "Mid Cap",
            node: null,
            title: "Mid Cap ($2B - $10B)",
          },
          {
            ge: 10000,
            le: 200000,
            checked: false,
            innerHTML: "Large",
            label: "Large Cap",
            node: null,
            title: "Large Cap ($10B - $200B)",
          },
          {
            ge: 200000,
            le: null,
            checked: false,
            innerHTML: "Mega",
            label: "Mega Cap",
            node: null,
            title: "Mega Cap (over $200B)",
          },
          // legacy
          /* {
                            ge: 50000000,
                            le: 300000000,
                            checked: false,
                            innerHTML: "Micro",
                            label: "Micro Cap",
                            node: null,
                            title: "Micro Cap ($50M - $300M)"
                        },
                        {
                            ge: 300000000,
                            le: 2000000000,
                            checked: false,
                            innerHTML: "Small",
                            label: "Small Cap",
                            node: null,
                            title: "Small Cap ($300M - $2B)"
                        },
                        {
                            ge: 2000000000,
                            le: 10000000000,
                            checked: false,
                            innerHTML: "Mid",
                            label: "Mid Cap",
                            node: null,
                            title: "Mid Cap ($2B - $10B)"
                        },
                        {
                            ge: 10000000000,
                            le: 200000000000,
                            checked: false,
                            innerHTML: "Large",
                            label: "Large Cap",
                            node: null,
                            title: "Large Cap ($10B - $200B)"
                        },
                        {
                            ge: 200000000000,
                            le: null,
                            checked: false,
                            innerHTML: "Mega",
                            label: "Mega Cap",
                            node: null,
                            title: "Mega Cap (over $200B)"
                        } */
        ];

        break;
      }
      case "SIZE": {
        // which level of nodes will be displayed and the rendering order
        var LEVEL_TO_DISPLAY = "3 Level";
        var LEVEL_TO_DISPLAY_ITEM_ORDER = ["micro", "small", "mid", "large"];
        var LEVEL_TO_DISPLAY_ITEM_TITLE = {
          micro: "Micro Cap ($50M - $300M)",
          small: "Small Cap ($300M - $2B)",
          mid: "Mid Cap ($2B - $10B)",
          large: "Large Cap (over $10B)",
        };

        var _options: any = [];
        var taxonomy = window.App.taxonomies["SizeClassification"];
        for (var key in taxonomy) {
          let datum = taxonomy[key];
          _options.push({
            label: datum["name"],
            type: datum["type"],
            value: key,
          });
        }

        var optionsMapAtLevel = {};
        for (let i = 0, length = _options.length; i < length; i++) {
          let datum = _options[i];
          if (datum["type"] === LEVEL_TO_DISPLAY) {
            optionsMapAtLevel[datum["value"]] = datum;
          }
        }

        options = [];
        var optionsOrder = LEVEL_TO_DISPLAY_ITEM_ORDER;
        for (let i = 0; i < optionsOrder.length; i++) {
          let datum = optionsMapAtLevel[optionsOrder[i]];
          options.push({
            label: datum["label"],
            title: LEVEL_TO_DISPLAY_ITEM_TITLE[datum["value"]],
            value: datum["value"],
          });
        }

        break;
      }
      // case "DURATION":
      // case "MAGNITUDE":
      // case "PERCENTAGE":
      // case "UPI": {
      //     options = [
      //         { "left": .01, "right": .25, "checked": false, "innerHTML": "25%", "label": "25%", "node": null, "title": "0% - 25%" },
      //         { "left": .25, "right": .50, "checked": false, "innerHTML": "50%", "label": "50%", "node": null, "title": "25% - 50%" },
      //         { "left": .50, "right": .75, "checked": false, "innerHTML": "75%", "label": "75%", "node": null, "title": "50% - 75%" },
      //         { "left": .75, "right": null, "checked": false, "innerHTML": "&gt; 75%", "label": "&gt; 75%", "node": null, "title": "greater than 75%" }
      //     ];
      //     break;
      // }
      // case "SECTOR": {
      //     options = [
      //         {
      //             "label": "Any",
      //             "selected": false,
      //             "value": "0 root"
      //         },
      //         {
      //             "label": user.product.industryClass == "INDUSTRIES"
      //                 ? "Industries"
      //                 : "Sectors",
      //             "selected": true,
      //             "value": "1 Industry"
      //         },
      //         {
      //             "label": user.product.industryClass == "INDUSTRIES"
      //                 ? "Sectors"
      //                 : "Industries",
      //             "selected": false,
      //             "value": "3 Sector"
      //         }
      //     ];
      //     break;
      // }
      // case "SECTOR_LITE": {
      //     options = [
      //         {
      //             "label": user.product.industryClass == "INDUSTRIES"
      //                 ? "Industries"
      //                 : "Sectors",
      //             "selected": true,
      //             "value": "1 Industry"
      //         },
      //         {
      //             "label": user.product.industryClass == "INDUSTRIES"
      //                 ? "Sectors"
      //                 : "Industries",
      //             "selected": false,
      //             "value": "3 Sector"
      //         }
      //     ];
      //     break;
      // }
      case "VOLATILITY": {
        options = [
          {
            ge: null,
            le: 0.1,
            checked: false,
            innerHTML: "Low",
            label: "Low volatility (under 10%)",
            node: null,
            title: "Low volatility (under 10%)",
          },
          {
            ge: 0.1,
            le: 0.5,
            checked: false,
            innerHTML: "Mid",
            label: "Medium volatility (10% - 50%)",
            node: null,
            title: "Medium volatility (10% - 50%)",
          },
          {
            ge: 0.5,
            le: null,
            checked: false,
            innerHTML: "High",
            label: "High volatility (over 50%)",
            node: null,
            title: "High volatility (over 50%)",
          },
        ];

        break;
      }
    }
    return deepClone(options);
  },

  /**
   * Get the option that matches the value
   *
   * @param {String} field - One of ALERT, HIGH_LOW, NOTIFICATION, MARKET_CAP, DURATION, MAGNITUDE, PERCENTAGE, UPI
   * @param {Object} value - the value of the option to get
   */
  getOption: function (field, value) {
    var options = this.get(field);
    switch (field) {
      // case "ALERT":
      // case "HIGH_LOW":
      // case "NOTIFICATION": {
      //     for (let i = 0, length = options.length; i < length; i++) {
      //         if (options[i].value === value.max) {
      //             return options[i];
      //         }
      //     }
      //     break;
      // }
      case "MARKET_CAP": {
        // used to print security info
        if (isFinite(value)) {
          if (value >= options[0].ge && value < options[0].le) {
            return options[0];
          } else if (value >= options[1].ge && value < options[1].le) {
            return options[1];
          } else if (value >= options[2].ge && value < options[2].le) {
            return options[2];
          } else if (value >= options[3].ge && value < options[3].le) {
            return options[3];
          } else if (value >= options[4].ge) {
            return options[4];
          }
        }
        // used in widgets to select marketcap range
        // 2019-09-28 - START
        var ge: any = null;
        var le: any = null;
        if (value["ge"] !== undefined && value["le"] !== undefined) {
          for (let i = 0, length = options.length; i < length; i++) {
            if (value.ge === options[i].ge) {
              ge = options[i];
            }

            if (value.le === options[i].le) {
              le = options[i];
            }
          }
        }

        if (le != null && ge != null) {
          return {
            ge: ge.label,
            le: le.label,
            raw_value: {
              ge: ge,
              le: le,
            },
          };
        } else {
          return {
            ge: value["ge"],
            le: value["le"],
            raw_value: {
              ge: ge,
              le: le,
            },
          };
        }

        // 2019-09-28 - END

        /* if (
                        value["ge"] !== undefined &&
                        value["le"] !== undefined
                    ) {
                        var ge = null;
                        var le = null;
                        for (
                            var i = 0, length = options.length;
                            i < length;
                            i++
                        ) {
                            if (value.ge == options[i].ge) {
                                ge = options[i];
                            }

                            if (value.le == options[i].le) {
                                le = options[i];
                            }
                        }

                        // custom marketcap range
                        if (le && ge) {
                            return {
                                ge: ge.label,
                                le: le.label,
                                raw_value: {
                                    ge: ge,
                                    le: le
                                }
                            };
                        }

                        var formatOptions = {
                            notAvailable: {
                                input: null,
                                output: ""
                            }
                        };

                        return {
                            ge: format.value(
                                "PDF",
                                "numberBig",
                                value.ge - 0.0001, // correction (is custom or not)
                                formatOptions
                            ),
                            le: format.value(
                                "PDF",
                                "numberBig",
                                value.le - 0.0001, // correction (is custom or not)
                                formatOptions
                            ),
                            raw_value: {
                                ge: format.value(
                                    "PDF",
                                    "numberBig",
                                    value.ge - 0.0001, // correction (is custom or not)
                                    formatOptions
                                ),
                                le: format.value(
                                    "PDF",
                                    "numberBig",
                                    value.le - 0.0001, // correction (is custom or not)
                                    formatOptions
                                )
                            }
                        };
                    } */

        // return null;
      }
      // case "DURATION":
      // case "MAGNITUDE":
      // case "PERCENTAGE":
      // case "UPI": {
      //     var option = {
      //         "max": null,
      //         "min": null
      //     };
      //     for (let i = 0, length = options.length; i < length; i++) {
      //         if (value.min == options[i].ge) {
      //             option.min = options[i];
      //         }
      //         if (value.max == options[i].le) {
      //             option.max = options[i];
      //         }
      //     }
      //     return option;
      //     break;
      // }
      case "VOLATILITY": {
        var startIndex;
        var endIndex;
        if (value.ge || value.le) {
          for (let i = 0, length = options.length; i < length; i++) {
            if (value.ge && value.ge === options[i].ge) {
              startIndex = i;
            }
            if (value.ge == null) {
              startIndex = 0;
            }
            if (value.le && value.le === options[i].le) {
              endIndex = i;
            }
            if (value.le == null) {
              endIndex = length - 1;
            }
          }
        }

        var option = {
          ge: startIndex != null ? options[startIndex]["label"] : null,
          le: endIndex != null ? options[endIndex]["label"] : null,
          raw_value: {
            ge: startIndex != null ? options[startIndex] : value.ge,
            le: endIndex != null ? options[endIndex] : value.le,
          },
        };

        return option;
      }
    }

    return "None";
  },
};
