/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/commons
 * @summary Common utilities for Compute API
 *
 * IMPORTANT: keep this class as a singleton without external dependecies
 *
 */

import { deepClone } from "../../deepClone";

/**
 * Injects additional data in given instruments
 * Mainly used in point in time
 *
 * @param {object[]} dataTarget - list of instruments
 *
 * @param {object[]} dataSource - list of additional data for each
 *       symbol
 * @param {object[]} dataSource[].symbol - instrument symbol
 * @param {object[]} dataSource[].fm - instrument 1 month forward
 *       looking performance
 * @param {object[]} dataSource[].fq - instrument 3 months (quarterly)
 *       forward looking performance
 * @param {object[]} dataSource[].fw - instrument 1 week forward
 *       looking performance
 * @param {object[]} dataSource[].fy - instrument 12 months (yearly)
 *       forward looking performance
 * @param {object[]} dataSource[].weight - instrument weight
 *
 * @param {array.<string>} properties - properties to be injected
 *
 * @returns {object[]} list of instruments with their additional data
 */
export function dataInjector(
    dataTarget: any,
    dataSource: any,
    properties: any
) {
    const data = deepClone(dataTarget);
    const dataToInject: any = {};

    for (const item of dataSource) {
        dataToInject[item.symbol] = {};
        for (const property of properties) {
            dataToInject[item.symbol][property] =
                item[property] !== undefined ? item[property] : null;
        }
    }

    for (const item of data) {
        let itemDataToInject = dataToInject[item["symbol"]];
        for (const property in itemDataToInject) {
            item[property] = itemDataToInject[property];
        }
    }

    return data;
}

/**
 * Extracts data suitable for data ingestion
 *
 * @param {object[]} data - the list of data to be used for ingestion
 *
 * @returns {array} the list of pairs propertyToIngest, propertyForValueIngestion
 */
export function extractForDataIngestion(
    data: any[],
    propertyToIngest: string,
    propertyForValueIngestion: string
) {
    const dataToIngest: any = [];

    for (const datum of data) {
        const preparedData = {
            [propertyToIngest]: datum[propertyToIngest],
            value: String(datum[propertyForValueIngestion]),
        };
        dataToIngest.push(preparedData);
    }

    return dataToIngest;
}

/**
 * Extracts symbols from the given list of objects (instruments or
 * holdings)
 *
 * @param {object[]} instruments - the list of instruments
 * @param {object[]} instruments[].symbol - the symbol
 *
 * @returns {array} the list of symbols
 */
export function extractSymbols(instruments: any[]) {
    return instruments.map((instrument: any) => instrument.symbol);
}

/**
 * Extracts only symbols and weights from the given list of instruments
 *
 * @param {object[]} instruments - the list of instruments
 * @param {object[]} instruments[].symbol - the symbol
 * @param {object[]} instruments[].weight - the weight
 *
 * @returns {array} the list of symbols
 */
export function extractSymbolsAndWeights(instruments: any) {
    return instruments.map((instrument: any) => ({
        symbol: instrument.symbol,
        weight: instrument.weight,
    }));
}

/**
 * Merge 2 arrays of properties avoiding duplicates
 *
 * @param {object[]} properties1 - list of properties to retrieve for each symbol
 * @param {object[]} properties1[].property - name of the property, e.g rc, vc ...
 * @param {object[]} properties1[].date - compute the property value at this date (point in time)
 * @param {object[]} properties2 - list of properties to retrieve for each symbol
 * @param {object[]} properties2[].property - name of the property, e.g rc, vc ...
 * @param {object[]} properties2[].date - compute the property value at this date (point in time)
 *
 * @returns {array} properties to be retrieved
 *
 */
export function mergeProperties(properties1: any, properties2: any) {
    const processed: any = {};
    const properties: any = [];

    for (const item of [...properties1, ...properties2]) {
        // avoid duplicates
        if (!(item.property in processed)) {
            processed[item.property] = true;
            properties.push({
                date: item.date ?? null,
                label: item.label ?? null,
                property: item.property,
            });
        }
    }

    return properties;
}

/**
 * Merge in a object a list of properties
 *
 * Expects two arrays, each have an id field to merge correctly each items
 * Merge fields from source to target.
 *
 * @param {boolean}  enableNullOnEmpty - (default: true) assign null value on empty fields
 * @param {string[]} properties - list of properties to merge
 * @param {boolean}  skipExisting - (default: false) skip assigning the value if it is not undefined
 * @param {object[]} source - array of objects, with an id field to merge correctly
 * @param {object[]} target - list of objects, needs an id field
 *
 * @returns {array} properties to be retrieved
 *
 */
export function mergePropertiesToObjects({
    enableNullOnEmpty = true,
    properties,
    skipExisting = false,
    source,
    target,
}: {
    enableNullOnEmpty?: boolean;
    properties: string[];
    skipExisting?: boolean;
    source: any[];
    target: any[];
}) {
    const clonedProcessed = deepClone(target);

    // Prepare map using id
    const sourceMap: any = {};
    for (const item of source) {
        sourceMap[item.id] = item;
    }

    // Merge data
    for (const item of clonedProcessed) {
        const sourceItem = sourceMap[item.id];

        if (sourceItem != null) {
            for (const property of properties) {
                /*
                    If the source does not have the property value
                    check the destination and set explicit null only
                    if there is no property set on destination and the
                    enableNullOnEmpty flag is true.

                    If the source has the property value, check if
                    it already exists some value on destination, if yes
                    check if the skipExisting is false.
                */
                if (sourceItem[property] === undefined) {
                    if (enableNullOnEmpty && item[property] === undefined) {
                        item[property] = null;
                    }
                } else {
                    if (item[property] === undefined || !skipExisting) {
                        item[property] = deepClone(sourceItem[property]);
                    }
                }
            }
        }
    }

    return clonedProcessed;
}

/**
 * Converts an array of symbols in an array of objects with symbol
 * property
 *
 * @param {array} symbols - array of symbols
 *
 * @returns {object[]} an array of objects with symbol property
 */
export function symbolsToObjects(symbols: any) {
    return symbols.map((symbol) => ({
        symbol: symbol,
    }));
}
