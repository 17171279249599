import { Box } from "@mui/material";
import Highcharts from "highcharts/highstock";
import { useCallback, useMemo, useRef, useState } from "react";
import { Chart } from "../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";

type ProductHistogramQuartersDiffProps = {
  histogramData: any;
};

const sorterMap = {
  0: (a: any, b: any) => {
    if (a.value > b.value) {
      return -1;
    } else {
      if (a.value < b.value) {
        return 1;
      }
    }

    return 0;
  },
  1: (a: any, b: any) => {
    if (a.date > b.date) {
      return -1;
    } else {
      if (a.date < b.date) {
        return 1;
      }
    }

    return 0;
  },
  2: (a: any, b: any) => {
    if (a.value > b.value) {
      return 1;
    } else {
      if (a.value < b.value) {
        return -1;
      }
    }

    return 0;
  },
};

export function ProductHistogramQuartersDiff({
  histogramData,
}: ProductHistogramQuartersDiffProps) {
  const formatter = useFormatter();
  const [btnActive, setBtnActive] = useState<"distribution" | "calendar">(
    "distribution"
  );
  const [visibleSerie, setVisibleSerie] = useState(0);

  const chartRef = useRef<any>();

  const categoryFormatter = useCallback(
    (value) => {
      const valueToDate = formatter.custom("date", {
        options: {
          format: ["Y", "m"],
          isMillisecond: false,
          notAvailable: {
            input: "",
            output: "",
          },
          separator: "_",
        },
        output: "HTML",
        value: parseInt(value),
        valueHelper: null,
      });
      var tokens = valueToDate.split("_");

      var date = new Date(
        Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
      );
      var quarter = "";
      switch (tokens[1]) {
        case "01":
        case "02":
        case "03": {
          quarter = "Q1";
          break;
        }
        case "04":
        case "05":
        case "06": {
          quarter = "Q2";
          break;
        }
        case "07":
        case "08":
        case "09": {
          quarter = "Q3";
          break;
        }
        case "10":
        case "11":
        case "12": {
          quarter = "Q4";
          break;
        }
      }
      return (
        quarter +
        " " +
        formatter.custom("date", {
          options: {
            format: ["Y"],
            isMillisecond: true,
            notAvailable: {
              input: "",
              output: "",
            },
            separator: " ",
          },
          output: "HTML",
          value: date.getTime(),
          valueHelper: null,
        })
      );
    },
    [formatter]
  );

  const percentageFormatter = useCallback(
    (value) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: value,
        valueHelper: {
          normalizationThreshold: 1,
        },
      });
    },
    [formatter]
  );

  const initialdata = useMemo(() => {
    const dataObjectDiff = histogramData["performance|quarterly|D"];
    const dataObjectPerf = histogramData["performance|quarterly|H"];

    const dataXYDiff = Object.entries(dataObjectDiff).reduce(
      (prev: any, [key, value]) => {
        prev.push({
          value,
          date: key,
        });

        return prev;
      },
      []
    );

    const dataXYPerf = Object.entries(dataObjectPerf).reduce(
      (prev: any, [key, value]) => {
        prev.push({
          value,
          date: key,
        });

        return prev;
      },
      []
    );

    dataXYPerf.sort(sorterMap[0]);
    dataXYDiff.sort(sorterMap[0]);

    return [dataXYPerf, dataXYDiff];
  }, [histogramData]);

  const [data, setData] = useState<any>(initialdata ?? []);

  const categories = useMemo(() => {
    return data?.[visibleSerie].map((item: any) =>
      categoryFormatter(item.date)
    );
  }, [categoryFormatter, data, visibleSerie]);

  const sortBy = useCallback((sortIndex: 0 | 1 | 2) => {
    setData((currentData: any) => {
      const serie1 = [...currentData[0]];
      const serie2 = [...currentData[1]];

      serie1.sort(sorterMap[sortIndex]);
      serie2.sort(sorterMap[sortIndex]);

      return [serie1, serie2];
    });
  }, []);

  const onClickDistributionBtn = useCallback(() => {
    setBtnActive("distribution");
    sortBy(0);
  }, [sortBy]);

  const onClickCalendarBtn = useCallback(() => {
    setBtnActive("calendar");
    sortBy(1);
  }, [sortBy]);

  const series = useMemo(() => {
    const valuesDiff: any = [];
    const valuesPerf: any = [];

    let max = -9999;
    let value: any = null;

    for (const item of data[0]) {
      value = item.value;
      max = Math.max(Math.abs(value), max);

      valuesPerf.push(value);
    }

    let maxDiff: any = -9999;
    let valueDiff: any = null;

    for (const item of data[1]) {
      valueDiff = item.value;
      maxDiff = Math.max(Math.abs(valueDiff), maxDiff);

      valuesDiff.push(valueDiff);
    }

    const serieDiff = {
      name: "Relative",
      data: valuesDiff,
      type: "bar",
      visible: visibleSerie === 1,
      max: maxDiff,
      id: "diff_perf",
    };

    const seriePerc = {
      id: "perf",
      name: "Absolute",
      data: valuesPerf,
      type: "bar",
      visible: visibleSerie === 0,
      max: max,
    };

    return [seriePerc, serieDiff];
  }, [data, visibleSerie]);

  const options: Highcharts.Options = useMemo(() => {
    const s: any = series;
    const max = s[visibleSerie].max;

    return {
      chart: {
        type: "bar",
      },
      style: {
        width: "100%",
        height: "100%",
      },
      title: {
        text: "Quarterly Performance",
      },
      subtitle: {
        text: "Strategy",
      },
      xAxis: {
        categories,
        title: {
          text: null,
        },
        labels: {
          step: 1,
        },
        gridLineWidth: 1,
        lineWidth: 0,
      },
      yAxis: {
        title: {
          text: null,
        },
        minPadding: 0,
        startOnTick: true,
        max,
        min: -max,

        labels: {
          formatter: function () {
            return ((this.value as any) * 100).toFixed(0) + "%";
          },
          overflow: "justify",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        formatter: function () {
          return `${this.x}:  <strong>${percentageFormatter(this.y)}</strong>`;
        },
      },
      plotOptions: {
        series: {
          animation: false,
          events: {
            legendItemClick: function (event) {
              event.preventDefault();

              const series = this.chart.series;
              let name = "";

              for (const serie of series) {
                serie.setVisible(!serie.visible);

                if (serie.visible) {
                  name = serie.getName();
                }
              }

              this.chart.subtitle.update({
                text:
                  name === "Absolute" ? "Strategy" : "Difference vs Benchmark",
              });

              const serieVisible = name === "Absolute" ? 0 : 1;
              setVisibleSerie(serieVisible);
            },
          },
        },
        bar: {
          dataLabels: {
            enabled: false,
          },
          groupPadding: 0.1,
          color: "green",
          negativeColor: "red",
        },
      },
      credits: {
        enabled: false,
      },
      series: s,
      exporting: { enabled: false },
    };
  }, [categories, percentageFormatter, series, visibleSerie]);

  return (
    <Box height={"100%"} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} justifyContent={"center"} gap={1} marginBottom={1}>
        <Box
          sx={{
            backgroundColor: btnActive === "distribution" ? "#2a7090" : "white",
            padding: "8px 16px",
            borderRadius: "6px",
            border: "1px solid #2a7090",
            color: btnActive === "distribution" ? "white" : "#2a7090",
            cursor: "pointer",
          }}
          onClick={onClickDistributionBtn}
        >
          Distribution
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            backgroundColor: btnActive === "calendar" ? "#2a7090" : "white",
            padding: "8px 16px",
            borderRadius: "6px",
            border: "1px solid #2a7090",
            color: btnActive === "calendar" ? "white" : "#2a7090",
          }}
          onClick={onClickCalendarBtn}
        >
          Calendar
        </Box>
      </Box>

      <Box flex={1}>
        <Chart constructorType="chart" ref={chartRef} options={options}></Chart>
      </Box>
    </Box>
  );
}
