import { createContext, useContext, useRef } from "react";

type BroadcastContextType = {
  broadcast: (event: string, data: any) => void;
  subscribe: (event: string, callback: Function) => void;
  unsubscribe: (event: string, callback: Function) => void;
};

type ChannelProps = {
  children: JSX.Element | JSX.Element[];
};

const BroadcastContext = createContext<BroadcastContextType>(undefined!);

export const useBroadcast = () => useContext(BroadcastContext);

export const Channel = ({ children }: ChannelProps) => {
  const registry = useRef({});

  const subscribe = (event, callback) => {
    if (!registry.current[event]) {
      registry.current[event] = [callback];
    }
    registry.current[event].push(callback);
  };

  const unsubscribe = (event, callback) => {
    if (!registry.current[event]) {
      return;
    }

    registry.current[event] = registry.current[event].filter(
      (cb) => cb !== callback
    );
  };

  const broadcast = (event, data) => {
    if (!registry.current[event]) return;
    registry.current[event].forEach((callback) => callback(data));
  };

  return (
    <BroadcastContext.Provider value={{ subscribe, broadcast, unsubscribe }}>
      {children}
    </BroadcastContext.Provider>
  );
};
