export type BacktestingType = {
  inceptionDate: null | string;
  inceptionValue: null | number;
  period: {
    type: null | "YEAR" | "DAY";
    value: null | number | string;
  };
};
export const initState: BacktestingType = {
  inceptionDate: null,
  inceptionValue: null,
  period: {
    type: "YEAR",
    value: null,
  },
};
type BacktestingActionType = {
  type: "SET_INCEPTION_DATE" | "SET_INCEPTION_VALUE" | "SET_PERIOD_VALUE" | "SET_PERIOD_TYPE";
  payload: any;
};

export function backtestingReducer(draft: BacktestingType, action: BacktestingActionType) {
  switch (action.type) {
    case "SET_INCEPTION_DATE":
      draft.inceptionDate = action.payload;
      break;
    case "SET_INCEPTION_VALUE":
      draft.inceptionValue = action.payload;
      break;
    case "SET_PERIOD_VALUE":
      draft.period.value = action.payload;
      break;
    case "SET_PERIOD_TYPE":
      draft.period.type = action.payload;
      draft.period.value = null;
      break;
  }
}
