export type YearlyAnaliticsType = {
  annualized: {
    content: string;
    isEnabled: true;
  };
  benchmark: {
    content: string;
    isEnabled: true;
  };
  delta: {
    content: string;
    isEnabled: true;
  };
  headline: {
    content: string;
    isEnabled: true;
  };
  maxDrawdown: {
    content: string;
    isEnabled: true;
  };
  monthlyStandardDeviation: {
    content: string;
    isEnabled: true;
  };
  strategy: {
    content: string;
    isEnabled: true;
  };
  turnover: {
    content: string;
    isEnabled: true;
  };
  yearlyPerformance: {
    content: string;
    isEnabled: true;
  };
  years: "ROWS" | "COLUMNS";
};

export type ActionType = {
  type:
    | "SET_annualized_CONTENT"
    | "SET_annualized_ISENABLED"
    | "SET_benchmark_CONTENT"
    | "SET_benchmark_ISENABLED"
    | "SET_delta_CONTENT"
    | "SET_delta_ISENABLED"
    | "SET_headline_CONTENT"
    | "SET_headline_ISENABLED"
    | "SET_maxDrawdown_CONTENT"
    | "SET_maxDrawdown_ISENABLED"
    | "SET_monthlyStandardDeviation_CONTENT"
    | "SET_monthlyStandardDeviation_ISENABLED"
    | "SET_strategy_CONTENT"
    | "SET_strategy_ISENABLED"
    | "SET_turnover_CONTENT"
    | "SET_turnover_ISENABLED"
    | "SET_yearlyPerformance_CONTENT"
    | "SET_yearlyPerformance_ISENABLED"
    | "SET_years";
  payload: any;
};

export const yearlyAnaliticsReducer = (draft: YearlyAnaliticsType, action: ActionType) => {
  switch (action.type) {
    case "SET_annualized_CONTENT":
      draft.annualized.content = action.payload;
      break;
    case "SET_annualized_ISENABLED":
      draft.annualized.isEnabled = action.payload;
      break;
    case "SET_benchmark_CONTENT":
      draft.benchmark.content = action.payload;
      break;
    case "SET_benchmark_ISENABLED":
      draft.benchmark.isEnabled = action.payload;
      break;
    case "SET_delta_CONTENT":
      draft.delta.content = action.payload;
      break;
    case "SET_delta_ISENABLED":
      draft.delta.isEnabled = action.payload;
      break;
    case "SET_headline_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_headline_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_maxDrawdown_CONTENT":
      draft.maxDrawdown.content = action.payload;
      break;
    case "SET_maxDrawdown_ISENABLED":
      draft.maxDrawdown.isEnabled = action.payload;
      break;
    case "SET_monthlyStandardDeviation_CONTENT":
      draft.monthlyStandardDeviation.content = action.payload;
      break;
    case "SET_monthlyStandardDeviation_ISENABLED":
      draft.monthlyStandardDeviation.isEnabled = action.payload;
      break;
    case "SET_strategy_CONTENT":
      draft.strategy.content = action.payload;
      break;
    case "SET_strategy_ISENABLED":
      draft.strategy.isEnabled = action.payload;
      break;
    case "SET_turnover_CONTENT":
      draft.turnover.content = action.payload;
      break;
    case "SET_turnover_ISENABLED":
      draft.turnover.isEnabled = action.payload;
      break;
    case "SET_yearlyPerformance_CONTENT":
      draft.yearlyPerformance.content = action.payload;
      break;
    case "SET_yearlyPerformance_ISENABLED":
      draft.yearlyPerformance.isEnabled = action.payload;
      break;
    case "SET_years":
      draft.years = action.payload;
      break;

    default:
      break;
  }
};
