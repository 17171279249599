import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Instruments } from "../../../../../api/compute/Instruments";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../../api/compute/SystematicProducts";
import { Utils } from "../../../../../api/compute/Utils";
import { dataInjector } from "../../../../../api/compute/commons";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { httpAll } from "../../../../../httpAll";
import { TDate } from "../../../../../trendrating/date/TDate";
import { config } from "../../../config-ts";
import { messageError, messageSuccess, removeLoader } from "../../../utils";
import { CSV } from "../../../utils/SingletonCSV";
import ReactDialogRationale from "../../../widgets/dialog/rationale/ReactDialogRationale";
import { widgetsConfiguration } from "../../../widgets/widgetsConfiguration";
import DifferenceTable from "./DifferenceTable";
import LastAllocationsTable from "./LastAllocationsTable";
import { _formatAction, _prepareThresholdValue } from "./utils";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { styled } from "@mui/system";

const StyledInput = styled((props: any) => (
  <TextField
    onChange={(e) => {
      props.setThreshold(e.target.value);
      props.refreshSlider(parseFloat(e.target.value));
      props.thresholdHandler(parseFloat(e.target.value));
    }}
    value={props.threshold}
    className={props.className}
    size="small"
    type="number"
    inputProps={{
      step: 0.05,
      max: props.maxSliderAndInput,
      maxLength: 3,
      min: props.minSliderAndInput,
    }}
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
  />
))({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
});

export default function Rebalance(/*{}: Props*/) {
  const urlParams = useParams();
  const formatter = useFormatter();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();

  const productID = useMemo(() => {
    if (urlParams.id) {
      return parseInt(urlParams?.id);
    }
    return null;
  }, [urlParams.id]);
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);

  //#region - APIS
  const smsAPI = useMemo(() => new SystematicProducts(envSetup), [envSetup]);
  const listAPI = useMemo(() => new Lists(envSetup), [envSetup]);
  const utilsAPI = useMemo(() => new Utils(envSetup), [envSetup]);
  const instrumentsAPI = useMemo(() => new Instruments(envSetup), [envSetup]);
  const strategyAPI = useMemo(() => new Strategies(envSetup), [envSetup]);
  //#endregion

  const [product, setProduct] = useState<any>(null);
  const [rebalancedProduct, setRebalancedProduct] = useState<any>(null);
  const [productSnapshot, setProductSnapshot] = useState<any>(null);
  const [strategy, setStrategy] = useState<any>(null);
  const [_data, setData] = useState<any>(null);
  const userID = useMemo(
    () => environment.get("account")["user"]["id"],
    [environment]
  );

  //#region GET DATA AND PROCESS DATA
  const _dataPrepare = useCallback(
    (dataCompare, response) => {
      var dataLast = dataInjector(
        response["last"]["data"],
        dataCompare["result"]["positions"],
        ["weight"]
      );
      var position: any = null;
      var taxonomies = window.App.taxonomies;
      for (let i = 0, length = dataLast.length; i < length; i++) {
        position = dataLast[i];
        // replace ICB code with string to enable dgrid native sort
        // avoiding calls to server
        position["_synthWhat"] = formatter.custom("taxon", {
          options: {
            ancestorAtLevel: "1 Industry",
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomies["ICB"],
          },
          output: "TEXT",
          value: position["icb"],
          valueHelper: null,
        });
      }
      var dataDiff: any = response["diff"]["data"];
      var diffMap: any = {};
      for (let i = 0; i < dataDiff.length; i++) {
        position = dataDiff[i];
        // enriching dataDiff with synthetic fields
        position["_synthAction"] = null;
        position["_synthActionValue"] = null;
        diffMap[position["symbol"]] = i;
      }
      var dataRate = response["rate"];
      var difference = dataCompare["diff"];
      var index: any = null;
      for (let i = 0; i < difference.length; i++) {
        position = difference[i];
        index = diffMap[position["symbol"]];
        if (index != null) {
          dataDiff[index]["_synthActionValue"] = position["delta"];
          if (position["from"] === 0 && position["to"] > 0) {
            dataDiff[index]["_synthAction"] = "buy";
          } else if (position["from"] > 0 && position["to"] === 0) {
            dataDiff[index]["_synthAction"] = "sell";
          } else if (position["delta"] > 0) {
            dataDiff[index]["_synthAction"] = "increase";
          } else {
            dataDiff[index]["_synthAction"] = "decrease";
          }
        }
      }
      var data = {
        diff: dataDiff,
        last: dataLast,
        rate: dataRate,
        turnover: dataCompare["turnover"],
      };
      setData(data);
      setStrategy(response["strategy"]);
    },
    [formatter]
  );
  const _dataGetRebalanceResult = useCallback(
    async (response, snapshotOfProduct, product) => {
      let responseToday: any = null;
      responseToday = await utilsAPI.today();
      var data = deepClone(response);
      // var snapshot = deepClone(snapshotOfProduct);
      var today = TDate.daysToIso8601(responseToday.today);
      /* Data cleanup, removing diff symbols with delta 0 */
      var newDiff: any[] = [];
      // var maximumDiff = 0;
      if (data["diff"] != null) {
        for (let i = 0; i < data["diff"].length; i++) {
          if (data["diff"][i]["delta"] !== 0) {
            newDiff.push(data["diff"][i]);
          }
        }
        data["diff"] = newDiff;
      }
      // last allocation
      var propertiesLast =
        widgetsConfiguration["widgets/systematic-product/rebalance/last"][
          "properties"
        ];
      var symbols: any[] = [];
      var positions = data["result"]["positions"];
      for (let i = 0; i < positions.length; i++) {
        symbols.push(positions[i]["symbol"]);
      }
      var reqLast = instrumentsAPI.fetch({
        properties: propertiesLast,
        symbols: symbols,
        type: "security",
      });
      // difference (rebalance orders)
      var propertiesDiff =
        widgetsConfiguration["widgets/systematic-product/rebalance/diff"][
          "properties"
        ];
      symbols = [];
      positions = data["diff"];
      for (let i = 0; i < positions.length; i++) {
        symbols.push(positions[i]["symbol"]);
      }
      var reqDiff = instrumentsAPI.fetch({
        properties: propertiesDiff,
        symbols: symbols,
        type: "security",
      });
      // Statistics from target portfolio
      positions = data["result"]["positions"];
      var pos: any[] = [];
      for (let i = 0; i < positions.length; i++) {
        pos.push({
          S: positions[i].symbol,
          A: positions[i].weight,
        });
      }
      var reqRating = smsAPI.ratingAt({
        adjustWeights: true,
        asOf: today,
        // currency: snapshot.weightsManagement.currency,
        equalWeighted: false,
        normalize: false,
        perfMetricMode: "active",
        v: pos,
        // weightsAsOf: snapshot.weightsManagement.date,
        productId: product.id,
      });

      // const pp = new Product(envSetup, product);
      // await pp.initProduct();
      var params = {
        diff: reqDiff,
        last: reqLast,
        rate: reqRating,
        strategy: product.strategy /*pp.getStrategy()*/,
      };
      const dataCompare = await httpAll(params);
      _dataPrepare(data, dataCompare);
    },
    [_dataPrepare, instrumentsAPI, smsAPI, utilsAPI]
  );
  const _dataGetCompare = useCallback(
    async (rebalancedProduct, snapshotOfProduct, product) => {
      var threshold = _prepareThresholdValue(0);
      var paramsCompare = {
        source: {
          name: "Snapshot",
          positions: smsAPI._rebalance(snapshotOfProduct)?.["positions"] ?? [],
        },
        target: {
          name: "Last rebalance",
          positions: rebalancedProduct["positions"],
        },
        threshold: threshold,
        thresholdInOut: threshold,
      };
      const listCompareResponse = await listAPI.compare(paramsCompare);
      _dataGetRebalanceResult(listCompareResponse, snapshotOfProduct, product);
    },
    [_dataGetRebalanceResult, listAPI, smsAPI]
  );
  const _dataGetCompareWithThreshold = useCallback(
    async (threshold) => {
      var _threshold = _prepareThresholdValue(threshold);
      if (rebalancedProduct && productSnapshot) {
        var paramsCompare = {
          source: {
            name: "Snapshot",
            positions: smsAPI._rebalance(productSnapshot)?.["positions"] ?? [],
          },
          target: {
            name: "Last rebalance",
            positions: rebalancedProduct?.["positions"] ?? [],
          },
          threshold: _threshold,
          thresholdInOut: _threshold,
        };
        const listCompareResponse = await listAPI.compare(paramsCompare);
        _dataGetRebalanceResult(listCompareResponse, productSnapshot, {
          ...product,
          strategy: strategy,
        });
      }
    },
    [
      _dataGetRebalanceResult,
      listAPI,
      product,
      productSnapshot,
      rebalancedProduct,
      smsAPI,
      strategy,
    ]
  );

  //#endregion

  const dataGet = useCallback(async () => {
    if (productID) {
      const data = await smsAPI.fetch({
        ids: [productID],
        properties: [
          "positionsToday",
          "strategyId",
          "historicalPortfolioId",
          "name",
          "ownerId",
        ],
      });
      const strategyID = data[0].strategyId;
      setProduct({ ...data[0], isReadOnly: userID !== data[0].ownerId });
      const _strategy = await strategyAPI.getById(strategyID);
      setStrategy(_strategy);
      let THETODAYDATE: any = await utilsAPI.today();
      let THEPOSITIONTODAY = data?.[0]?.POS?.[0];
      if (THEPOSITIONTODAY) {
        THEPOSITIONTODAY.d = THETODAYDATE.today;
      } else {
        THEPOSITIONTODAY = {};
        THEPOSITIONTODAY["d"] = THETODAYDATE.today;
        THEPOSITIONTODAY["v"] = [];
      }
      const THESTRATEGY = strategyAPI.prepareParamsForRun(
        _strategy.params,
        null
      )["strategy"];
      const THESTRATEGYUNIVERSE = strategyAPI.prepareParamsForRun(
        _strategy.params,
        null
      )["universe"];
      const param = {
        date: THETODAYDATE.today,
        exAntePortfolio: THEPOSITIONTODAY,
        strategy: THESTRATEGY,
        universe: THESTRATEGYUNIVERSE,
        verbose: false,
      };
      let THENEWPORTFOLIO = await smsAPI.listRebalance(param);
      THENEWPORTFOLIO = smsAPI._rebalance(THENEWPORTFOLIO);
      setRebalancedProduct(THENEWPORTFOLIO);
      setProductSnapshot(THEPOSITIONTODAY);
      _dataGetCompare(THENEWPORTFOLIO, THEPOSITIONTODAY, {
        ...data[0],
        strategy: _strategy,
      });
    }
  }, [_dataGetCompare, productID, smsAPI, strategyAPI, userID, utilsAPI]);

  useEffect(() => {
    removeLoader();
    dataGet();
  }, [dataGet]);

  //#region
  const loading = useMemo(() => {
    return <CircularProgress size={"0.9em"} />;
  }, []);
  const loadingTable = useMemo(() => {
    return (
      <Box
        height={"100%"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={2}
      >
        <CircularProgress />
        <Typography>Loading table </Typography>
      </Box>
    );
  }, []);
  const tcr = useMemo(() => {
    if (_data && _data["rate"]["statistics"]) {
      const _tcr = formatter.tcr(_data["rate"]["statistics"]["tcr"], "HTML");
      return (
        <Typography variant="subtitle1">
          <strong>
            <span dangerouslySetInnerHTML={{ __html: _tcr }}></span>
          </strong>
        </Typography>
      );
    }
    return loading;
  }, [_data, formatter, loading]);
  const allocation = useMemo(() => {
    if (!_data) {
      return loading;
    }
    const rate = _data["rate"];
    if (rate == null || rate["statistics"] == null) {
      return "0";
    } else {
      var totalWeight = rate["statistics"]["weightTotal"];
      const _alocation = formatter.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: totalWeight,
        valueHelper: null,
      });
      return (
        <strong>
          <span dangerouslySetInnerHTML={{ __html: _alocation }}></span>
        </strong>
      );
    }
  }, [_data, formatter, loading]);
  const numberOfHoldings = useMemo(() => {
    if (!_data) {
      return loading;
    }
    const _numberOfHoldings = _data.rate.positions.length;
    return (
      <strong>
        <span dangerouslySetInnerHTML={{ __html: _numberOfHoldings }}></span>
      </strong>
    );
  }, [_data, loading]);
  //#endregion

  //#region THRESHOLD
  const [threshold, setThreshold] = useState("0.00");
  const thresholdHandler = useCallback(
    (thr) => {
      if (!isNaN(thr)) {
        if (thr !== 0) {
          _dataGetCompareWithThreshold(thr / 100);
        } else {
          _dataGetCompareWithThreshold(thr);
        }
      }
    },
    [_dataGetCompareWithThreshold]
  );
  //#endregion
  const rebalanceTurnover = useMemo(() => {
    if (!_data) {
      return loading;
    }
    return <strong> {(_data.turnover * 100).toFixed(2)}%</strong>;
  }, [_data, loading]);

  //#region CSV HANDLERS
  const listenerExportOrders = useCallback(() => {
    if (_data == null) {
      return;
    }
    var data = _data["diff"];
    var filename =
      product["name"] + " - " + t("w_systematic_product_rebalance_orders");
    var lines = [
      [
        '"ISIN"',
        '"Ticker"',
        '"Name"',
        '"Rating"',
        '"Currency"',
        '"Market"',
        '"Mkt. Cap."',
        '"Action"',
        '"Quantity"',
      ],
    ];
    var line: any = null;
    var position: any = null;
    for (let i = 0, length = data.length; i < length; i++) {
      line = [];
      position = data[i];

      line.push('"' + position["isin"] + '"');
      line.push('"' + position["ticker"] + '"');
      line.push('"' + position["name"] + '"');
      line.push(
        '"' +
          formatter.text(
            "rc",
            "table",
            position["rc"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + position["currency"] + '"');
      line.push('"' + position["country"] + '"');
      line.push(
        '"' +
          formatter.text(
            "marketcap",
            "table",
            position["marketcap"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + _formatAction("TEXT", position["_synthAction"], t) + '"');
      line.push(
        '"' +
          formatter.custom("number", {
            options: {
              hasPositiveSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: position["_synthActionValue"],
            valueHelper: null,
          }) +
          '"'
      );
      lines.push(line);
    }
    CSV.create(lines, filename);
  }, [_data, formatter, product, t]);
  const listenerExportNewAllocation = useCallback(() => {
    var data = deepClone(_data["last"]);

    var filename =
      product["name"] +
      " - " +
      t("w_systematic_product_rebalance_new_allocation");
    var lines = [
      [
        '"Weight"',
        '"ISIN"',
        '"Ticker"',
        '"Name"',
        '"Rating"',
        '"Currency"',
        '"Market"',
        '"Mkt. Cap."',
      ],
    ];

    var line: any = null;
    var position: any = null;
    for (let i = 0, length = data.length; i < length; i++) {
      line = [];
      position = data[i];

      line.push(
        '"' +
          formatter.text(
            "weight",
            "table",
            position["weight"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + position["isin"] + '"');
      line.push('"' + position["ticker"] + '"');
      line.push('"' + position["name"] + '"');
      line.push(
        '"' +
          formatter.text(
            "rc",
            "table",
            position["rc"],
            position,
            position["type"]
          ) +
          '"'
      );
      line.push('"' + position["currency"] + '"');
      line.push('"' + position["country"] + '"');
      line.push(
        '"' +
          formatter.text(
            "marketcap",
            "table",
            position["marketcap"],
            position,
            position["type"]
          ) +
          '"'
      );

      lines.push(line);
    }

    CSV.create(lines, filename);
  }, [_data, formatter, product, t]);
  //#endregion

  //#region ConfirmRebalance
  const listenerConfirmRebalance = useCallback(() => {
    var paramsRebalanceAdd = {
      rebalance: {
        date: rebalancedProduct["date"],
        positions: _data["last"],
      },
      product: product,
    };
    smsAPI.rebalanceAdd(paramsRebalanceAdd).then(
      () => {
        const [channel, msg] = messageSuccess(
          `${product.name} has been rebalanced successfully!`
        );
        broadcast(channel as string, msg);
        (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
      },
      (error) => {
        const [channel, msg] = messageError(
          `${product.name} can't be reabalanced... contact Trendrating.`
        );
        broadcast(channel as string, msg);
        (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
      }
    );
    //************************** USAGE *****************************
    var info = {
      action: "SAVE",
      actionParam: productID,
      function: "SYSTEMATIC_PORTFOLIO_REBALANCE",
    };
    var usage = window.App.usage;
    usage.record(info);
    //************************** USAGE *****************************
  }, [_data, broadcast, product, productID, rebalancedProduct, smsAPI]);

  //#endregion

  //#region WORKFLOW BAR
  const [workflow, setWorkflow] = useState("s0");
  useEffect(() => {
    const actions: any = [];
    let action: any = null;
    switch (workflow) {
      case "s0": {
        action = {
          componentJSX: (
            <li
              className={"menu__item"}
              onClick={() => listenerBackAction(productID, setWorkflow)}
            >
              Back
            </li>
          ),
        };
        actions.push(action);

        if (product?.["isReadOnly"] === false) {
          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={listenerExportOrders}>
                Export rebalance orders
              </li>
            ),
          };
          actions.push(action);

          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={listenerExportNewAllocation}
              >
                Export new allocation
              </li>
            ),
          };
          actions.push(action);

          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={listenerConfirmRebalance}>
                Confirm
              </li>
            ),
            isHighlighted: true,
          };
          actions.push(action);
        }

        break;
      }
      // case "s1": {
      //   // When going back, used to hide everything because it
      //   // still loading data but previous actions were visible

      //   break;
      // }
      // no default
    }
    if (productID) {
      var message = {
        from: "rebalance",
        content: {
          actions: actions,
        },
      };
      broadcast(config["channels"]["workflow"]["input"], message);
    }
  }, [
    broadcast,
    listenerConfirmRebalance,
    listenerExportNewAllocation,
    listenerExportOrders,
    product,
    productID,
    workflow,
  ]);
  //#endregion

  //#region RATIONALE STUFF
  const [showRationale, setShowRationale] = useState(false);
  const [rationaleData, setRationaleData] = useState<any>(null);
  const getRagionaleData = useCallback(async () => {
    setShowRationale(true);
    var rebalanceDate = rebalancedProduct["date"];
    var allocation: any = {
      d: rebalanceDate,
      v: [],
    };
    var position: any = null;
    var positions = smsAPI._rebalance(productSnapshot)["positions"];
    for (let i = 0, length = positions.length; i < length; i++) {
      position = {
        A: positions[i]["weight"],
        D: rebalanceDate,
        S: positions[i]["symbol"],
      };
      allocation["v"].push(position);
    }

    var paramsRationale = {
      allocation: allocation,
      day: rebalanceDate,
      properties: [
        {
          date: null,
          property: "currency",
        },
        {
          date: null,
          property: "name",
        },
        {
          date: null,
          property: "ticker",
        },
        {
          date: null,
          property: "symbol",
        },
        {
          date: null,
          property: "type",
        },
      ],
      strategy: deepClone(strategy),
    };
    const response = await strategyAPI.rationale(paramsRationale);
    setRationaleData(response);
  }, [productSnapshot, rebalancedProduct, smsAPI, strategy, strategyAPI]);
  const showContent = useMemo(() => {
    return !(showRationale && !rationaleData);
  }, [rationaleData, showRationale]);
  const loadingRationale = (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
      <Typography>Loading rationale data...</Typography>
    </Box>
  );
  //#endregion

  const [sliderValue, setSliderValue] = useState(0);
  const refreshSlider = useCallback((val) => {
    setSliderValue(val);
  }, []);

  const lastAllocationsTable = useMemo(
    () => <LastAllocationsTable data={_data?.last} />,
    [_data?.last]
  );

  const maxSliderAndInput = useMemo(() => 2, []);
  const minSliderAndInput = useMemo(() => 0, []);

  return (
    <Box
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      p={1}
    >
      {showRationale && rationaleData == null && loadingRationale}
      {showRationale && rationaleData && (
        <ReactDialogRationale
          closeFn={() => {
            setShowRationale(false);
            setRationaleData(null);
          }}
          tableData={rationaleData}
          isLoading={false}
        />
      )}
      {product && showContent && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
          pb={2}
        >
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Typography variant="subtitle1">
              <strong>{product.name}</strong> - New allocation
            </Typography>
            {tcr}
            <Typography>
              Allocation: {allocation} in {numberOfHoldings} {"holdings"}
            </Typography>
          </Box>
          <Box>
            <Button onClick={getRagionaleData}>Rationale</Button>
          </Box>
        </Box>
      )}
      {showContent && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          overflow={"hidden"}
          height={"100%"}
        >
          <Box flex={1} height={"100%"} maxHeight={"50%"} overflow={"hidden"}>
            {!_data ? loadingTable : lastAllocationsTable}
          </Box>
          <Box
            display={"flex"}
            gap={2}
            p={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="subtitle1">Rebalance orders</Typography>
            <Box
              minWidth={"30%"}
              gap={2}
              display={"flex"}
              alignItems={"center"}
            >
              <Box>
                <Typography>Order threshold:</Typography>
              </Box>
              <Slider
                value={sliderValue}
                step={0.1}
                max={maxSliderAndInput}
                min={minSliderAndInput}
                valueLabelDisplay="auto"
                onChange={(e: any) => {
                  setSliderValue(e.target.value);
                }}
                onChangeCommitted={(_, value) => {
                  thresholdHandler(value);
                  setThreshold(value.toString());
                }}
              />
              <Box width={"30%"}>
                <StyledInput
                  setThreshold={setThreshold}
                  refreshSlider={refreshSlider}
                  thresholdHandler={thresholdHandler}
                  threshold={threshold}
                  maxSliderAndInput={maxSliderAndInput}
                  minSliderAndInput={minSliderAndInput}
                />
              </Box>
            </Box>
            <Typography>Rebalance turnover: {rebalanceTurnover}</Typography>
          </Box>
          <Box flex={1} maxHeight={"50%"} height={"100%"} overflow={"hidden"}>
            {!_data ? loadingTable : <DifferenceTable data={_data.diff} />}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const listenerBackAction = (productID, setWorflow) => {
  if (productID == null) {
    // error
    (window as any).__page_navigate("/systematic-portfolios/");
  } else {
    setWorflow("s1");
    (window as any).__page_navigate(`/systematic-portfolios/${productID}`);
  }
};
