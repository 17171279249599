import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { handleTitle } from "../../../../Utility/DocumentTitleHanlder";
import { Properties } from "../../../../api/Properties";
import { Lists } from "../../../../api/compute/Lists";
import { PanelForLists } from "../../../../components/PanelForLists/PanelForLists";
import { newGetSymbols } from "../../../../components/SecurityTooltip/SecurityTooltipCore";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../hooks/useFormatter";
import { Instrument } from "../../../../types/Api";
import { config } from "../../config-ts";
import { removeLoader } from "../../utils";
import Search from "../../widgets/ReactSearch/Search";
import styles from "./AnalisysSecurity.module.scss";
import { Storage } from "./Storage";
import { TabAlternatives } from "./TabAlternatives";
import { TabCompare } from "./TabCompare";
import { TabHistorical } from "./TabHistorical";
import { TabOverview } from "./TabOverview";
import { TabRank } from "./TabRank";
import { useBroadcast } from "../../../../hooks/useBroadcast";

type SecurityAnalisysLayoutProps = {
  children: JSX.Element | JSX.Element[];
  tab: AnalisysSecurityTabs;
  handleTabChange: (event, value) => void;
  security?: Instrument;
  onSelectSymbol: (symbol) => void;
};

type SearchBarProps = {
  onSelectSecurity: (security) => void;
};

type SecurityBarInfoProps = {
  security?: Instrument;
};

type AnalisysSecurityTabs =
  | "overview"
  | "rank"
  | "compare"
  | "alternatives"
  | "historicalTrends";

export function AnalisysSecurity() {
  const [security, setSecurity] = useState<any>();
  const [tab, setTab] = useState<AnalisysSecurityTabs>("overview");
  const [userCollection, setUserCollection] = useState<
    {
      name: string;
      id: number;
      type: "Portfolios" | "Baskets";
      isSubscribed: boolean;
    }[]
  >();

  const environment = useEnvironment();
  const navigate = useNavigate();
  const urlParams = useParams();

  const setup = useMemo(() => environment.get("setup"), [environment]);
  const storage = useMemo(() => new Storage(setup), [setup]);
  const showPanel = useMemo(() => userCollection != null, [userCollection]);
  const listsAPI = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );

  const getSecurity = useCallback(
    async (symbol: string) => {
      const instrument = await storage.instrument({ symbol });

      setSecurity(instrument);
    },
    [storage]
  );

  const handleTabChange = (evt, value) => {
    setTab(value);
  };

  const selectSymbol = useCallback(
    (security) => {
      // *********************** USAGE ***********************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: security.symbol,
        function: "SECURITY_ANALYSIS",
      };
      usage.record(info);
      // *********************** USAGE ***********************
      navigate(`/app/analysis/instrument/${security.symbol}`);

      storage.addToRecents(security.symbol);
    },
    [navigate, storage]
  );

  const openPanel = useCallback(async () => {
    try {
      const userCollectionIds = await listsAPI._getFilter();
      const response = await listsAPI.portfolioFetch(userCollectionIds, [
        "name",
        "ownerId",
        "type",
      ]);

      const userId = environment.get("setup")["account"]["user"]["id"];

      const collection: any = [];

      for (const element of response) {
        collection.push({
          name: element.name,
          type: element.type === "PORTFOLIO" ? "Portfolios" : "Baskets",
          id: element.id,
          isSubscribed: element.ownerId !== userId,
        });
      }

      setUserCollection(collection);
    } catch (error) {
      console.log(error);
    }
  }, [environment, listsAPI]);

  const addTo = async (id) => {
    let _listTemp = userCollection?.filter((item) => item.id === id)[0];
    // var allocation = getSymbols([security], null);
    var allocation = newGetSymbols(
      [security],
      _listTemp?.type === "Portfolios" ? "PORTFOLIO" : "BASKET",
      true
    );
    if (allocation) {
      const response = await listsAPI.get(id);
      await addToHelper(allocation, response);
    }
  };

  const { broadcast } = useBroadcast();

  const addToHelper = async (allocation, list) => {
    list["positions"] = list["positions"].concat(allocation);

    await listsAPI.update(list);

    //Use the non updated list to get name and type used to be sended to the topic
    const oldList = list;

    const message = {
      from: "Routes",
      content: {
        type: "success",
        text:
          "Item/s saved in " +
          oldList.type.toLowerCase() +
          " <strong>" +
          oldList.name +
          "</strong>.",
      },
    };

    broadcast(config["channels"]["feedback"]["input"], message);

    //********************** USAGE *************************
    var usage = window.App.usage;
    var info = {
      action: "ADD_TO",
      actionParam: list["id"],
      function: "MESSAGE",
    };
    usage.record(info);
    //********************** USAGE *************************
  };

  const closePanel = useCallback(() => {
    setUserCollection(undefined);
  }, []);

  useEffect(() => {
    removeLoader();
  });

  useEffect(() => {
    if (urlParams?.symbol) {
      getSecurity(urlParams.symbol);
    }
  }, [getSecurity, urlParams?.symbol]);

  useEffect(() => {
    handleTitle({
      type: "SECURITY_ANALYSIS",
      name: security?.name ?? undefined,
    });
  }, [security?.name]);

  // Manage Workflow
  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    if (security != null) {
      action = {
        componentJSX: (
          <li onClick={openPanel} className="menu__item">
            Add to
          </li>
        ),
      };

      actions.push(action);
    }

    var message = {
      from: "SECURITY_ANALYSIS",
      function: "SECURITY_ANALYSIS",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, openPanel, security]);

  return (
    <SecurityAnalisysLayout
      handleTabChange={handleTabChange}
      tab={tab}
      security={security}
      onSelectSymbol={selectSymbol}
    >
      <PanelForLists
        showDialog={showPanel}
        closeDialog={closePanel}
        list={userCollection ?? []}
        sectionsTag={["Portfolios", "Baskets"]}
        selectItem={addTo}
        headerTitle={"Add to Portfolio or Basket"}
        multipleChoice={false}
      />
      {tab === "overview" && security != null ? (
        <TabOverview value={security} />
      ) : (
        <></>
      )}
      {tab === "rank" && security != null ? (
        <TabRank value={security} />
      ) : (
        <></>
      )}
      {tab === "compare" && security != null ? (
        <TabCompare value={security} />
      ) : (
        <></>
      )}
      {tab === "historicalTrends" && security != null ? (
        <TabHistorical value={security} />
      ) : (
        <></>
      )}
      {tab === "alternatives" && security != null ? (
        <TabAlternatives value={security} />
      ) : (
        <></>
      )}
    </SecurityAnalisysLayout>
  );
}

const SecurityAnalisysLayout = ({
  children,
  security,
  onSelectSymbol,
  tab,
  handleTabChange,
}: SecurityAnalisysLayoutProps) => {
  const environment = useEnvironment();

  const insertHistoricalTab = useCallback(
    (tabs) => {
      const historicalTab = {
        label: "Historical Trends",
        value: "historicalTrends",
      };

      const account = environment.get("account");
      const preferences = account.user.preferences.preferences;

      const analysisSecurityPreferences = preferences?.["analysisSecurity"];

      if (analysisSecurityPreferences?.historical?.enabled === true) {
        tabs.push(historicalTab);
      }
    },
    [environment]
  );

  const insertAlternativesTab = useCallback(
    (tabs) => {
      const alternativesTab = {
        label: "Alternatives",
        value: "alternatives",
      };

      if (security?.["rc"] < 0) {
        tabs.push(alternativesTab);
      }
    },
    [security]
  );

  const tabs = useMemo(() => {
    if (security) {
      if (security.type === "Stock" || security.type === "ETF") {
        const tabs = [
          { label: "Overview", value: "overview" },
          { label: "Rank", value: "rank" },
          { label: "Compare", value: "compare" },
        ];

        insertHistoricalTab(tabs);
        insertAlternativesTab(tabs);

        return tabs;
      } else {
        const tabs = [
          { label: "Overview", value: "overview" },
          { label: "Compare", value: "compare" },
        ];

        insertHistoricalTab(tabs);

        return tabs;
      }
    }

    return [];
  }, [insertAlternativesTab, insertHistoricalTab, security]);

  return (
    <Box className={styles.analisysSecurityMain} p={1} gap={1}>
      <SearchBar onSelectSecurity={onSelectSymbol} />
      <Box className={styles.bottomPanelMain}>
        {/* <RecentSideBar list={recentlyVisited} selectSecurity={onSelectSymbol} /> */}
        <Box className={styles.rightPanelMain}>
          {security != null && <SecurityBarInfo security={security} />}
          <Box className={styles.rightPanelMain__section}>
            <Tabs value={tab} onChange={handleTabChange}>
              {tabs.map((item) => (
                <Tab key={uuidv4()} label={item.label} value={item.value} />
              ))}
            </Tabs>
            <Box className={styles.tabsContentMain}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SearchBar = ({ onSelectSecurity }: SearchBarProps) => {
  return (
    <Box className={styles.searchBarMain}>
      <Card className={styles.searchCardMain}>
        <CardContent className={styles.searchCardContent}>
          <Search
            showInstrumentInfoOnSelect={false}
            formGrouopStyle={{ flex: 1 }}
            onSelectInstrument={onSelectSecurity}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const SecurityBarInfo = ({ security }: SecurityBarInfoProps) => {
  const environment = useEnvironment();
  const format = useFormatter();
  const label = useMemo(
    () =>
      new Properties({
        properties: environment.get("setup")["properties"],
      }),
    [environment]
  );

  const name = useMemo(
    () =>
      format.html("name", "name", security?.["name"], null, security?.["type"]),
    [format, security]
  );

  const ticker = useMemo(() => {
    return format.html(
      "ticker",
      "ticker",
      security?.["ticker"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  const price = useMemo(() => {
    return format.html(
      "vc",
      "price",
      security?.["vc"],
      security,
      security?.["type"]
    );
  }, [format, security]);

  const priceDate = useMemo(() => {
    return format.html(
      "dc",
      "closure_date_human",
      security?.["dc"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  const rateLabel = useMemo(
    () => label.get("rc", 0, security?.["type"]),
    [label, security]
  );

  const securityRate = useMemo(() => {
    return format.html(
      "rc",
      "rating",
      security?.["rc"],
      security,
      security?.["type"]
    );
  }, [format, security]);

  const momentumLabel = useMemo(() => {
    return label.get("mc", 0, security?.["type"]);
  }, [security, label]);

  const securityMomentum = useMemo(() => {
    return format.html(
      "mc",
      "smart_momentum",
      security?.["mc"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  const securityRateDate = useMemo(() => {
    return format.html(
      "dr",
      "rated_on_human",
      security?.["dr"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  const securityRetracementLabel = useMemo(() => {
    return label.get("px", 0, security?.["type"]);
  }, [security, label]);

  const securityRetracement = useMemo(() => {
    return format.html(
      "px",
      "retracement",
      security?.["px"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  const securityNewLow = useMemo(() => {
    return format.html(
      "lhl",
      "high_low",
      security?.["lhl"],
      null,
      security?.["type"]
    );
  }, [format, security]);

  return security != null ? (
    <Box className={styles.securityGeneralInfoBar}>
      <Card className={styles.securityTitleBox}>
        <CardContent className={styles.securityTitleBox_content}>
          <div>
            <div>
              <span
                className="tSecurityAnalysisHeader-name"
                data-dojo-attach-point="nodeSecurityName"
                dangerouslySetInnerHTML={{ __html: name }}
              ></span>{" "}
              <span className="tSecurityAnalysisHeader-ticker">
                (
                <span
                  className="tSecurityAnalysisHeader-tickerContent"
                  data-dojo-attach-point="nodeSecurityTicker"
                  dangerouslySetInnerHTML={{ __html: ticker }}
                ></span>
                )
              </span>
              {securityNewLow && (
                <span
                  className="tSecurityAnalysisHeader-newHighLow"
                  data-dojo-attach-point="nodeSecuritynewHighLow"
                  dangerouslySetInnerHTML={{ __html: securityNewLow }}
                ></span>
              )}
            </div>
          </div>
          <div>
            <span
              className="tSecurityAnalysisHeader-price"
              data-dojo-attach-point="nodeSecurityPrice"
              dangerouslySetInnerHTML={{ __html: price }}
            ></span>
            <span
              className="tSecurityAnalysisHeader-since"
              data-dojo-attach-point="nodeSecurityPriceDate"
            >
              {priceDate}
            </span>
          </div>
        </CardContent>
      </Card>
      <Card className={styles.securityGeneralInfoBox}>
        <CardContent className={styles.securityGeneralInfoBox_content}>
          <div
            className="tSecurityAnalysisHeader-cell-label"
            dangerouslySetInnerHTML={{ __html: rateLabel }}
          ></div>
          <Box
            display={"flex"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box>
              <div
                className="tSecurityAnalysisHeader-rate"
                dangerouslySetInnerHTML={{ __html: securityRate }}
              ></div>
              <div
                className="tSecurityAnalysisHeader-rateDate"
                dangerouslySetInnerHTML={{ __html: securityRateDate }}
              ></div>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card className={styles.securityGeneralInfoBox}>
        <CardContent className={styles.securityGeneralInfoBox_content}>
          <div
            className="tSecurityAnalysisHeader-cell-label"
            dangerouslySetInnerHTML={{ __html: momentumLabel }}
          ></div>
          <Box
            display={"flex"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <div
              className="tSecurityAnalysisHeader-momentum"
              dangerouslySetInnerHTML={{ __html: securityMomentum }}
            ></div>
          </Box>
        </CardContent>
      </Card>
      <Card className={styles.securityGeneralInfoBox}>
        <CardContent className={styles.securityGeneralInfoBox_content}>
          <div
            className="tSecurityAnalysisHeader-cell-label"
            dangerouslySetInnerHTML={{ __html: securityRetracementLabel }}
          ></div>
          <Box
            display={"flex"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <div
              className="tSecurityAnalysisHeader-retracement"
              dangerouslySetInnerHTML={{ __html: securityRetracement }}
            ></div>
          </Box>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <></>
  );
};
