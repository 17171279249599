import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

type SortByProps = {
  sort:
    | "name"
    | "name_rev"
    | "TCR"
    | "TCR_rev"
    | "CD"
    | "CD_rev"
    | "AB"
    | "AB_rev"
    | "upgrades"
    | "downgrades"
    | "movers_up"
    | "movers_down";

  setSort: (sort: SortByProps["sort"]) => void;
};

const OPTIONS = [
  { label: "Risk: High to Low", value: "TCR" },
  { label: "Risk: Low to High", value: "TCR_rev" },
  { label: "Name: A to Z", value: "name" },
  { label: "Name: Z to A", value: "name_rev" },
  { label: "CD%", value: "CD" },
  // { label: "CD% (low to high)", value: "CD_rev" },
  { label: "AB%", value: "AB" },
  // { label: "AB% (low to high)", value: "AB_rev" },
  { label: "Upgrades", value: "upgrades" },
  { label: "Downgrades", value: "downgrades" },
  { label: "Movers Up", value: "movers_up" },
  { label: "Movers Down", value: "movers_down" },
];

const StyledSelect = styled((props: any) => (
  <Select
    value={props.sort}
    fullWidth
    size="small"
    variant="outlined"
    classes={{ select: props.className }}
  >
    {props.children}
  </Select>
))({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

export function SortBy({ sort, setSort }: SortByProps) {
  const selectSort = useCallback(
    (
      value:
        | "name"
        | "name_rev"
        | "TCR"
        | "TCR_rev"
        | "CD"
        | "CD_rev"
        | "AB"
        | "AB_rev"
        | "upgrades"
        | "downgrades"
        | "movers_up"
        | "movers_down"
    ) => {
      setSort(value);
    },
    [setSort]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        <StyledSelect sort={sort}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => selectSort(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>{" "}
      </Box>
    </Box>
  );
}
