import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../../../components/InputNumber/InputNumber";
import { useResizer } from "../../../../../../../../../hooks/useResizer";
import { backtestingReducer, BacktestingType } from "./BacktestingReducer";
import DatePicker from "./components/DatePicker";
import Period from "./components/Period";
import { UI_CONSTRAINTS_TYPE } from "../../../Advanced/utils";

type BacktestingControllerProps = {
  input: BacktestingType;
  onClose: Function;
  onCancel: Function;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function BacktestingController({
  input,
  onClose,
  onCancel,
  UI_CONSTRAINTS,
}: BacktestingControllerProps) {
  const [state, dispatcher] = useImmerReducer(backtestingReducer, input);
  const [showInceptionSection, setShowInceptionSection] =
    useState<boolean>(input.inceptionDate !=null);

  // useEffect(() => {
  //   if (!showInceptionSection) {
  //     dispatcher({
  //       type: "SET_INCEPTION_DATE",
  //       payload: input.inceptionDate,
  //     });
  //     dispatcher({
  //       type: "SET_INCEPTION_VALUE",
  //       payload: input.inceptionValue,
  //     });
  //   }
  // }, [
  //   dispatcher,
  //   input.inceptionDate,
  //   input.inceptionValue,
  //   showInceptionSection,
  // ]);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      JSON.stringify(input) !== JSON.stringify(state) &&
      // state.inceptionDate &&
      // state.inceptionValue &&
      state.period.type &&
      state.period.value
    ) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [input, state]);

  const containerRef = useRef<HTMLDivElement>(null);
  useResizer({
    // isEnable: true,
    ref: containerRef,
  });
  return (
    <div ref={containerRef}>
      <Box sx={{ display: "flex", p: 2, gap: 2 }}>
        <Card sx={{ width: "80%", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingBottom: "16px !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Card sx={{ flex: 1 }}>
                <CardContent>
                  <Typography variant="subtitle1">Period</Typography>
                  <FormControl fullWidth>
                    <Box sx={{ display: "flex" }}>
                      <Period
                        state={state}
                        dispatcher={dispatcher}
                        onChangeType={(val) =>
                          dispatcher({
                            type: "SET_PERIOD_TYPE",
                            payload: val,
                          })
                        }
                        periodInputType={state.period.type!}
                      />
                    </Box>
                    {UI_CONSTRAINTS.backtesting.inceptionValue.enabled ===
                      true && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          sx={{ width: "max-content" }}
                          control={
                            <Switch
                            checked={showInceptionSection}
                              size="small"
                              sx={{
                                ".Mui-checked": {
                                  color: "#2a7092 !important",
                                },
                              }}
                            />
                          }
                          label={<Typography>Set inception value</Typography>}
                          onChange={(e: any) => {
                            setShowInceptionSection(e.target.checked);
                            if (!e.target.checked) {
                              dispatcher({
                                type: "SET_INCEPTION_DATE",
                                payload: null,
                              });
                            }
                          }}
                        />
                        {showInceptionSection && (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              "& > * .Mui-focused > .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#2a7092 !important",
                                },
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  padding: "8px",
                                }}
                              >
                                Date
                              </Typography>
                              <DatePicker
                                input={state.inceptionDate as string}
                                onChangeDate={(val) => {
                                  dispatcher({
                                    type: "SET_INCEPTION_DATE",
                                    payload: val,
                                  });
                                }}
                              />
                            </span>

                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Value</Typography>
                              <InputNumber
                                size="small"
                                initVal={state.inceptionValue ?? 0}
                                isPercentage={false}
                                setOuterState={(val) => {
                                  dispatcher({
                                    type: "SET_INCEPTION_VALUE",
                                    payload: val,
                                  });
                                }}
                                isFloating={true}
                              />
                            </span>
                          </Box>
                        )}
                      </Box>
                    )}
                  </FormControl>
                </CardContent>
              </Card>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={saveButtonDisabled}
                onClick={() => {
                  onClose(state);
                }}
                type="button"
              >
                Apply rule
              </Button>

              <Button
                sx={{
                  ml: 2,
                }}
                variant="tr_button_cancel"
                type="button"
                size="small"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: "20%",
            boxShadow: 3,
            backgroundColor: "#f2f2f2",
          }}
        >
          <CardContent>
            <h3>Backtesting</h3>
            <br />
            <p>
              Determine the length of the backtest history, the inception date
              from which to index the strategy and benchmark return, as well as
              the inception value for the strategy and benchmark.
              <br />
              <br />
              <i>
                The purpose of the inception date and value is purely visual. It
                has no impact on the actual performance of the strategy.
              </i>
            </p>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
