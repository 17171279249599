import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DatePicker from "./DatePicker";
import YearRange from "./YearRange";

type PeriodProps = {
  onChangeType: Function;
  periodInputType: string;
  state: any;
  dispatcher: Function;
};

const Period = ({
  onChangeType,
  periodInputType,
  state,
  dispatcher,
}: PeriodProps) => {
  return (
    <Box>
      <FormControl>
        <RadioGroup

          value={periodInputType}
          onChange={(e) => {
            onChangeType(e.target.value);

            if (e.target.value === "YEAR") {
              dispatcher({ type: "SET_PERIOD_VALUE", payload: 8 });
              dispatcher({
                type: "SET_INCEPTION_DATE",
                payload: null,
              });
            }
          }}
        >
          <FormControlLabel
            value={"YEAR"}
            control={
              <Radio
                size="small"
                sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
              />
            }
            label={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.7vw",
                }}
              >
                <Typography style={{ marginRight: 16 }}>Years</Typography>{" "}
                {state.period.type === "YEAR" && (
                  <YearRange
                    input={state.period.value as number}
                    onChangeValue={(val) => {
                      dispatcher({ type: "SET_PERIOD_VALUE", payload: val });
                      dispatcher({
                        type: "SET_INCEPTION_DATE",
                        payload: null,
                      });
                    }}
                  />
                )}
              </span>
            }
          />

          <FormControlLabel
            sx={{ width: "max-content" }}
            value={"DAY"}
            control={
              <Radio
                size="small"
                sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
              />
            }
            label={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.7vw",
                }}
              >
                <Typography style={{ marginRight: 16 }}>From date</Typography>
                {state.period.type === "DAY" && (
                  <DatePicker
                    input={state.period.value as string}
                    onChangeDate={(val) => {
                      dispatcher({ type: "SET_PERIOD_VALUE", payload: val });
                    }}
                  />
                )}
              </span>
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Period;
