export const defaultTemplateReportStrategyLongShort = [
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_FACTS",
          content: {
            currency: {
              content: "",
              label: "Currency",
              isEnabled: true,
            },
            customText1: {
              content: "",
              label: "",
              isEnabled: true,
            },
            customText2: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText3: {
              content: "",
              label: "",
              isEnabled: false,
            },
            headline: {
              content: "Facts",
              isEnabled: true,
            },
            launchDate: {
              content: "",
              label: "Starting date",
              isEnabled: true,
            },
            numberOfHoldings: {
              content: "",
              label: "Number of holdings",
              isEnabled: false,
            },
            performance: {
              content: "",
              label: "Performance",
              isEnabled: true,
            },
            rebalancingFrequency: {
              content: "",
              label: "Rebalancing frequency",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 2,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_CHART",
          content: {
            benchmark: true,
            headline: {
              content: "Historical Performance Chart",
              isEnabled: true,
            },
            title: {
              content: "",
              isEnabled: false,
            },
          },
          presentation: {
            logAxis: true,
            legend: true,
            size: "SMALL",
            align: "left",
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Performance",
              isEnabled: true,
            },
            performance10Years: {
              content: "10 Yr",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performance1Day: {
              content: "1 day",
              isEnabled: false,
            },
            performance1Month: {
              content: "1 Mo",
              isEnabled: true,
            },
            performance1Week: {
              content: "1 week",
              isEnabled: false,
            },
            performance1Year: {
              content: "1 Yr",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance3Months: {
              content: "3 Mo",
              isEnabled: true,
            },
            performance3Years: {
              content: "3 Yr",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance5Years: {
              content: "5 Yr",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performanceMTD: {
              content: "MTD",
              isEnabled: false,
            },
            performanceQTD: {
              content: "QTD",
              isEnabled: false,
            },
            performanceLTD: {
              content: "Starting date",
              isEnabled: true,
              annualized: true,
            },
            performanceYTD: {
              content: "YTD",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_YEARLY_ANALYTICS",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Calendar Year Analytics",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: false,
            },
            monthlyStandardDeviation: {
              content: "Volatility",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            turnover: {
              content: "One Way turnover",
              isEnabled: false,
            },
            yearlyPerformance: {
              content: "Performance",
              isEnabled: true,
            },
            years: "ROWS",
            portfolio: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Fact sheet",
    ownerId: null,
    type: "TEMPLATE_REPORT_STRATEGY_LONG_SHORT",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_COVER",
          content: {
            subTitle: "",
            // eslint-disable-next-line no-template-curly-in-string
            title: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_CHART",
          content: {
            benchmark: true,
            headline: {
              content: "Historical Performance Chart",
              isEnabled: true,
            },
            title: {
              content: "",
              isEnabled: false,
            },
          },
          presentation: {
            align: "left",
            logAxis: true,
            legend: true,
            size: "medium",
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Performance",
              isEnabled: true,
            },
            performance10Years: {
              content: "10 Yr",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performance1Day: {
              content: "1 day",
              isEnabled: false,
            },
            performance1Month: {
              content: "1 Mo",
              isEnabled: true,
            },
            performance1Week: {
              content: "1 week",
              isEnabled: false,
            },
            performance1Year: {
              content: "1 Yr",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance3Months: {
              content: "3 Mo",
              isEnabled: true,
            },
            performance3Years: {
              content: "3 Yr",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance5Years: {
              content: "5 Yr",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performanceMTD: {
              content: "MTD",
              isEnabled: false,
            },
            performanceQTD: {
              content: "QTD",
              isEnabled: false,
            },
            performanceLTD: {
              content: "Starting date",
              isEnabled: true,
              annualized: true,
            },
            performanceYTD: {
              content: "YTD",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_FACTS",
          content: {
            currency: {
              content: "",
              label: "Currency",
              isEnabled: true,
            },
            customText1: {
              content: "",
              label: "",
              isEnabled: true,
            },
            customText2: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText3: {
              content: "",
              label: "",
              isEnabled: false,
            },
            headline: {
              content: "Facts",
              isEnabled: true,
            },
            launchDate: {
              content: "",
              label: "Starting date",
              isEnabled: true,
            },
            numberOfHoldings: {
              content: "",
              label: "Number of holdings",
              isEnabled: false,
            },
            performance: {
              content: "",
              label: "Performance",
              isEnabled: true,
            },
            rebalancingFrequency: {
              content: "",
              label: "Rebalancing frequency",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_KEY_FACTS",
          content: {
            annualized: {
              content: "Annualized",
              isEnabled: true,
            },
            averageYearlyDrawdown: {
              content: "Average yearly drawdown",
              isEnabled: true,
            },
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            beta: {
              content: "Beta",
              isEnabled: true,
            },
            cumulative: {
              content: "Cumulative",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headlineKeyRatio: {
              content: "Key Ratios",
              isEnabled: true,
            },
            headlinePerformance: {
              content: "Performance",
              isEnabled: true,
            },
            headlineRisk: {
              content: "Risk",
              isEnabled: true,
            },
            maxConsecutiveNegativeMonths: {
              content: "Max consecutive negative months",
              isEnabled: true,
            },
            maxConsecutivePositiveMonths: {
              content: "Max consecutive positive months",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: true,
            },
            monthlyStandardDeviation: {
              content: "Monthly Standard Deviation",
              isEnabled: true,
            },
            oneWayTurnover: {
              content: "One Way Turnover",
              isEnabled: false,
            },
            percentPositiveMonths: {
              content: "% Positive Months",
              isEnabled: true,
            },
            sharpeRatio: {
              content: "Sharpe Ratio",
              isEnabled: true,
            },
            sortinoRatio: {
              content: "Sortino Ratio",
              isEnabled: true,
            },
            sterlingRatio: {
              content: "Sterling Ratio",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            informationRatio: {
              content: "Information Ratio",
              isEnabled: true,
            },
            trackingError: {
              content: "Tracking Error",
              isEnabled: true,
            },
            treynorRatio: {
              content: "Treynor ratio",
              isEnabled: true,
            },
            yearlyAverage: {
              content: "Yearly average",
              isEnabled: true,
            },
            portfolio: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_YEARLY_ANALYTICS",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Calendar Year Analytics",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: true,
            },
            monthlyStandardDeviation: {
              content: "Volatility",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            turnover: {
              content: "One Way turnover",
              isEnabled: false,
            },
            yearlyPerformance: {
              content: "Performance",
              isEnabled: true,
            },
            years: "ROWS",
            portfolio: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "YTD Analytics",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: true,
            },
            monthlyPerformance: {
              content: "Performance",
              isEnabled: true,
            },
            monthlyStandardDeviation: {
              content: "Volatility",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            portfolio: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: {
            table: "ROWS",
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Detailed Analytics",
    ownerId: null,
    type: "TEMPLATE_REPORT_STRATEGY_LONG_SHORT",
    version: "1.0",
  },
];
