import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { ConstraintsBarETFMarkets } from "../ConstraintsBarETFMarkets/ConstraintsBarETFMarkets";
import { TimeframeControl } from "../ContentETFMarkets/widgets/TimeframeControl/TimeframeControl";
import styles from "./FiltersBarMarketDetail.module.scss";

type ConstraintsEtfMarketsProps = {
  segment:
    | "Area"
    | "Region"
    | "WWW"
    | "Country"
    | "1 Industry"
    | "3 Sector"
    | "4 Subsector";
  dispatch: (ActionsETFMarketsControls) => void;
  getOptionsByPeerType: Function;
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  timeframe: 0 | 4 | 19;
};

type SelectorProps = {
  options: { label: string; value: string | number }[];
  selectOption: (option) => void;
  selectedOption: string | number;
  fontSize?: number;
  buttonList?: boolean;
};

export function FiltersBarMarketDetail({
  segment,
  dispatch,
  getOptionsByPeerType,
  analytic,
  timeframe,
}: ConstraintsEtfMarketsProps) {
  const setSegment = useCallback(
    (value) => dispatch({ type: "SET_SEGMENT", payload: value }),
    [dispatch]
  );

  const options = useMemo(() => getOptionsByPeerType(), [getOptionsByPeerType]);

  return (
    <>
      <Box className={styles.constraints__card__wrapper}>
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent!important",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "5px!important",
              paddingTop: "5px!important",
            }}
          >
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Timeframe
            </Typography>
            <TimeframeControl dispatch={dispatch} timeframe={timeframe} />
          </CardContent>
        </Card>
      </Box>
      <Box className={styles.constraints__card__wrapper}>
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent!important",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "5px!important",
              paddingTop: "5px!important",
            }}
          >
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Segment
            </Typography>
            <SelectorETFMarkets
              options={options}
              selectOption={setSegment}
              selectedOption={segment}
            />
          </CardContent>
        </Card>
      </Box>
      {/* Analytic Bar  */}
      <Box className={styles.constraints__card__wrapper}>
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "transparent!important",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "5px!important",
              paddingTop: "5px!important",
            }}
          >
            <Typography
              sx={{
                color: "#2A7091",
                marginBottom: "5px",
                fontSize: "10px",
              }}
            >
              Analytics
            </Typography>
            <ConstraintsBarETFMarkets
              segment={segment}
              dispatch={dispatch}
              analytic={analytic}
              getSegmentOptionsByPeerType={getOptionsByPeerType}
            />
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export const SelectorETFMarkets = ({
  options,
  selectOption,
  selectedOption,
  fontSize,
  buttonList = false,
}: SelectorProps) => {
  const SELECTED__CLASS = useMemo(() => "radio-bar__option--selected", []);

  const handleOptionSelect = useCallback(
    (value: string | number) => {
      selectOption(value);
    },
    [selectOption]
  );

  return (
    <ul className={styles.card__selector__list}>
      {options.map((option) =>
        !buttonList ? (
          <li
            key={uuidv4()}
            onClick={() => handleOptionSelect(option.value)}
            className={`radio-bar__option ${
              selectedOption === option.value ? SELECTED__CLASS : ""
            }`}
          >
            <span
              className="radio-bar__label"
              style={{
                fontSize: `${fontSize ?? 12}px`,
                padding: "5px 0",
              }}
            >
              {option.label}
            </span>
          </li>
        ) : (
          <button
            key={uuidv4()}
            onClick={() => handleOptionSelect(option.value)}
            className={`${styles.button__list} ${
              selectedOption === option.value
                ? styles["button__list-selected"]
                : ""
            }`}
          >
            {option.label}
          </button>
        )
      )}
    </ul>
  );
};
