import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { FiltersContext } from "../../FilterRowContraints";

export function FamilyFilter() {
  const context = useContext(FiltersContext);
  const environment = useEnvironment();
  const fields = useMemo(
    () => environment.get("setup")["taxonomyFields"],
    [environment]
  );
  const taxonomies = useMemo(
    () => environment.get("setup")["taxonomies"],
    [environment]
  );
  const indicesClassification = useMemo(
    () => taxonomies[fields["Index"]["subtype"]],
    [fields, taxonomies]
  );
  const list: {
    id: string;
    name: string;
    parent: string;
    rank: null | number;
    showInTree: boolean;
    showInWidget: any;
    type: any;
  }[] = useMemo(
    () =>
      Object.values<any>(indicesClassification).filter(
        (node) => node.showInTree === true
      ),
    [indicesClassification]
  );

  const rootNode = useMemo(
    () => list.find((item) => item.parent == null),
    [list]
  );
  const [activeValues, setActiveValues] = useState<string[]>(
    context?.indexFamily ?? []
  );

  const handleChange = (value) => {
    let newActiveValues: any = [];
    const activeValuesCopy = [...activeValues];

    if (value === rootNode?.id) {
      if (activeValuesCopy.indexOf(value) === -1) {
        newActiveValues = list.map((item) => item.id);
      }

      setActiveValues(newActiveValues);

      return;
    }

    if (activeValuesCopy.indexOf(value) !== -1) {
      newActiveValues = [...activeValuesCopy].filter(
        (item) => item !== value && item !== rootNode?.id
      );
    } else {
      newActiveValues = [...activeValuesCopy, value];

      const valuesWithoutRoot = newActiveValues.filter(
        (item) => item !== rootNode?.id
      );
      let areAllSelected = true;

      for (const opt of list) {
        if (opt.parent == null) {
          continue;
        }
        if (valuesWithoutRoot.indexOf(opt.id) === -1) {
          areAllSelected = false;
        }
      }

      if (areAllSelected) {
        newActiveValues = list.map((i) => i.id);
      }
    }

    setActiveValues(newActiveValues);
  };

  const handleLabelRendering = useCallback(
    (selected) => {
      if (selected.length) {
        return selected
          .map((item) => list?.find((el) => el.id === item)?.name ?? "")
          .join(", ");
      }
    },
    [list]
  );

  const onApplyBtnClick = useCallback(() => {
    context?.updateIndexFamily(activeValues);
  }, [activeValues, context]);

  return (
    <FormControl>
      <Select
        sx={{ width: "120px", fontSize: "0.7vw" }}
        fullWidth
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={activeValues.length ? activeValues : [rootNode?.id ?? ""]}
        inputProps={{
          sx: {
            paddingTop: "5px !important",
            paddingBottom: "5px !important",
          },
        }}
        input={
          <OutlinedInput
            sx={{
              fontSize: "0.7vw",
            }}
            size="small"
          />
        }
        renderValue={handleLabelRendering}
      >
        {list.map((item) => (
          <MenuItem
            sx={{
              fontSize: "0.7vw!important",
            }}
            key={uuidv4()}
            value={item.id}
            onClick={() => handleChange(item.id)}
          >
            <Checkbox
              size="small"
              sx={{ fontSize: "0.7vw" }}
              checked={activeValues.indexOf(item.id) > -1}
            />
            <Typography>{item.name}</Typography>
          </MenuItem>
        ))}

        <Box
          display={"flex"}
          flex={1}
          mt={1}
          px={1}
          justifyContent={"center"}
          gap={1}
        >
          <Button variant="contained" onClick={onApplyBtnClick}>
            Apply
          </Button>
        </Box>
      </Select>
    </FormControl>
  );
}
