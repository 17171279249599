import { useMemo } from "react";
import { useEnvironment } from "../../hooks/useEnvironment";
import { config } from "./config-ts";
import { Workflow } from "./Workflow";

export function Footer() {
  const environment = useEnvironment();
  const theme = useMemo(() => {
    const colorTag =
      environment.get("account")?.product?.configuration?.colorSchema ??
      "legacy";
    switch (colorTag) {
      default:
      case "legacy":
        return "app__footer";

      case "advisor":
        return "app__footer__advisor";
    }
  }, [environment]);
  return (
    <footer className={theme} id="app-footer">
      <Workflow channelInput={config["channels"]["workflow"]["input"]} />
    </footer>
  );
}
