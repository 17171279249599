import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { targetNameVariable, targetTickerVariable } from "../../utils/utils";
import { CustomComponent } from "../React_KeyFacts/React_KeyFacts";
import {
  YearlyAnaliticsType,
  yearlyAnaliticsReducer,
} from "./React_YealyAnalytics_reducer";

type YearlyAnaliticsProps = {
  configObj: any;
};

const YearlyAnalitics = forwardRef(
  ({ configObj }: YearlyAnaliticsProps, ref) => {
    const initState: YearlyAnaliticsType = useMemo(
      () => ({
        annualized: configObj.widgetValue.content.annualized,
        benchmark: configObj.widgetValue.content.benchmark,
        delta: configObj.widgetValue.content.delta,
        headline: configObj.widgetValue.content.headline,
        maxDrawdown: configObj.widgetValue.content.maxDrawdown,
        monthlyStandardDeviation:
          configObj.widgetValue.content.monthlyStandardDeviation,
        strategy: configObj.widgetValue.content.strategy,
        turnover: configObj.widgetValue.content.turnover,
        yearlyPerformance: configObj.widgetValue.content.yearlyPerformance,
        years: configObj.widgetValue.content.years,
      }),
      [
        configObj.widgetValue.content.annualized,
        configObj.widgetValue.content.benchmark,
        configObj.widgetValue.content.delta,
        configObj.widgetValue.content.headline,
        configObj.widgetValue.content.maxDrawdown,
        configObj.widgetValue.content.monthlyStandardDeviation,
        configObj.widgetValue.content.strategy,
        configObj.widgetValue.content.turnover,
        configObj.widgetValue.content.yearlyPerformance,
        configObj.widgetValue.content.years,
      ]
    );

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state;
        return tempState;
      },
    }));

    const [state, dispatch] = useImmerReducer(
      yearlyAnaliticsReducer,
      initState
    );



    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box display={"flex"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.headline.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_headline_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Headline"
              />
              <TextField
                value={state.headline.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_headline_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.headline.isEnabled}
                size="small"
              />
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            {/* table */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>Years shown on:</Typography>
              <Select
                size="small"
                value={state.years}
                onChange={(e) =>
                  dispatch({ type: "SET_years", payload: e.target.value })
                }
              >
                <MenuItem value={"ROWS"}> Rows</MenuItem>
                <MenuItem value={"COLUMNS"}>Columns</MenuItem>
              </Select>
            </Box>

            {/* portfolio */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.strategy.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_strategy_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                  />
                }
                label="Portfolio"
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={2}
                justifyContent={"end"}
              >
                <Button
                  disabled={!state.strategy.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_strategy_CONTENT",
                      payload:
                        state.strategy.content !== ""
                          ? `${state.strategy.content} ${targetNameVariable}`
                          : `${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>

                <TextField
                  value={state.strategy.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_strategy_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.strategy.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* benchmark */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.benchmark.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_benchmark_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                  />
                }
                label="Benchmark"
              />
              <Box
                justifyContent={"end"}
                display={"flex"}
                gap={2}
                alignItems={"center"}
              >
                <Button
                  disabled={!state.benchmark.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_benchmark_CONTENT",
                      payload:
                        state.benchmark.content !== ""
                          ? `${state.benchmark.content} ${targetNameVariable}`
                          : `${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>
                <Button
                  disabled={!state.benchmark.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_benchmark_CONTENT",
                      payload:
                        state.benchmark.content !== ""
                          ? `${state.benchmark.content} ${targetTickerVariable}`
                          : `${targetTickerVariable}`,
                    })
                  }
                >
                  Add target's ticker
                </Button>
                <TextField
                  value={state.benchmark.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_benchmark_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.benchmark.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* delta */}
            <CustomComponent
              switchLabel={"Delta"}
              switchChecked={state.delta.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_delta_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.delta.content}
              textCallback={(e) =>
                dispatch({ type: "SET_delta_CONTENT", payload: e })
              }
            />
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Enable</Typography>
              <TextField size="small" value={"Row headline"} disabled />
            </Box>
            {/* Return */}
            <CustomComponent
              switchLabel={"Return"}
              switchChecked={state.yearlyPerformance.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_yearlyPerformance_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.yearlyPerformance.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_yearlyPerformance_CONTENT",
                  payload: e,
                })
              }
            />

            {/* Max Drawdown */}
            <CustomComponent
              switchLabel={"Max Drawdown"}
              switchChecked={state.maxDrawdown.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_maxDrawdown_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.maxDrawdown.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_maxDrawdown_CONTENT",
                  payload: e,
                })
              }
            />

            {/* Standard Deviation */}
            <CustomComponent
              switchLabel={"Standard Deviation"}
              switchChecked={state.monthlyStandardDeviation.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_monthlyStandardDeviation_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.monthlyStandardDeviation.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_monthlyStandardDeviation_CONTENT",
                  payload: e,
                })
              }
            />

            {/* Turnover */}
            <CustomComponent
              switchLabel={"Turnover"}
              switchChecked={state.turnover.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_turnover_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.turnover.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_turnover_CONTENT",
                  payload: e,
                })
              }
            />

            {/* Show Annualized data */}
            <CustomComponent
              switchLabel={"Show Annualized data"}
              switchChecked={state.annualized.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_annualized_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.annualized.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_annualized_CONTENT",
                  payload: e,
                })
              }
            />
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default YearlyAnalitics;
