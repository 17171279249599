import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import RatingCheckbox from "../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { deepClone } from "../../../../../../../deepClone";
import { optionsForSortingSelect } from "../utils/utils";
import {
  HoldingChartsStateType,
  holdinhChartsReducer,
} from "./holdingChartsReducer";
import SortingSelect from "../commonWidgets/SortingSelect";

type HoldingsChartsProps = {
  configObj: any;
  columns: any;
};

const HoldingsCharts = forwardRef(
  ({ configObj, columns }: HoldingsChartsProps, ref) => {
    const initState: HoldingChartsStateType = useMemo(
      () => configObj.widgetValue.content,
      [configObj.widgetValue.content]
    );
    const [state, dispatch] = useImmerReducer(holdinhChartsReducer, initState);
    const inputForSortingSelect = useMemo(() => {
      return optionsForSortingSelect(columns);
    }, [columns]);

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state;

        return tempState;
      },
    }));

    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) =>
                    dispatch({
                      type: "SET_HEADLINE_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  defaultChecked={state.headline.isEnabled}
                  size="small"
                />
              }
              label={
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Typography>Headline</Typography>
                  <TextField
                    onChange={(e) =>
                      dispatch({
                        type: "SET_HEADELINE_CONTENT",
                        payload: e.target.value,
                      })
                    }
                    defaultValue={state.headline.content}
                    size="small"
                  />
                </Box>
              }
            />
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box gap={1} display={"flex"} flexDirection={"column"}>
              <Box display={"flex"} alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) =>
                        dispatch({
                          type: "SET_TOP_ISENABLED",
                          payload: e.target.checked,
                        })
                      }
                      size="small"
                    />
                  }
                  label="Top"
                />
                <InputNumber
                  isDisabled={!state.top.isEnabled}
                  initVal={state.top.content}
                  isPercentage={false}
                  setOuterState={(e) =>
                    dispatch({ type: "SET_TOP_CONTENT", payload: e })
                  }
                  isFloating={false}
                />
              </Box>

              <Box display={"flex"} gap={2} alignItems="center">
                <Typography>Rating:</Typography>
                <RatingCheckbox
                  initState={
                    state.rate != null
                      ? state.rate
                      : { A: false, B: false, C: false, D: false }
                  }
                  stateGetter={(e) => {
                    dispatch({ type: "SET_RATE", payload: e });
                  }}
                />
              </Box>

              <Box display={"flex"} gap={2} alignItems="center">
                <Typography>Sort by</Typography>
                <SortingSelect
                  options={inputForSortingSelect}
                  value={state.sortBy}
                  onSortChange={(e) => {
                    dispatch({ type: "SET_SORT_BY", payload: e.value });
                  }}
                />
              </Box>
              {/* 
            <FormControlLabel
              control={<Switch defaultChecked size="small" />}
              label="Enable ranking fields (can also sort by rank if needed)"
            /> */}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default HoldingsCharts;
