export type State = {
    keepValidPositions: boolean;
    prioritySelect: string;
};

type Action =
    | {
          type: "SET_PRIORITY_SELECT";
          payload: string;
      }
    | {
          type: "SET_KEEP_VALID_POSITIONS";
          payload: boolean;
      }
    | { type: "SYNC_RANKING_WITH_STATE"; payload: any };

export const rankingState: State = {
    keepValidPositions: true,
    prioritySelect: "HIGH_MKT_CAP",
};

export const rankingReducer = (draft: State, action: Action) => {
    switch (action.type) {
        case "SYNC_RANKING_WITH_STATE":
            return action.payload;
        case "SET_PRIORITY_SELECT":
            draft.prioritySelect = action.payload;
            break;
        case "SET_KEEP_VALID_POSITIONS":
            draft.keepValidPositions = action.payload;
            break;
        default:
            return draft;
    }
};
