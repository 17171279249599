import { useCallback, useMemo } from "react";

export const useEventBus = () => {
  const on = useCallback((event, handler) => {
    document.addEventListener(event, handler);
  }, []);

  const dispatch = useCallback((event, data?) => {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }, []);

  const remove = useCallback((event, handler) => {
    document.removeEventListener(event, handler);
  }, []);

  const eventController = useMemo(
    () => ({
      on,
      dispatch,
      remove,
    }),
    [dispatch, on, remove]
  );

  return eventController;
};
