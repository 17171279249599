import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../deepClone";
import { targetNameVariable } from "./utils/utils";

type CoverProps = {
  configObj: any;
};

const Cover = forwardRef(({ configObj }: CoverProps, ref) => {
  const [title, setTitle] = useState(configObj.widgetValue.content.title);
  const [subtitle, setSubtitle] = useState(
    configObj.widgetValue.content.subTitle
  );

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = {
        subTitle: subtitle,
        title: title,
      };
      return tempState;
    },
  }));

  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box gap={1} display={"flex"} flexDirection={"column"}>
            <TextField
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              defaultValue={title}
              value={title}
              label="Title"
              size="small"
            />

            <Button
              onClick={() => {
                setTitle(`${title}${targetNameVariable}`);
              }}
            >
              Add target's name to title
            </Button>
            <TextField
              defaultValue={subtitle}
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
              label={"Subtitle"}
              size="small"
            />
            <Typography>Logo can be set in your Preferences</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Cover;
