import { Card, CardContent } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import styles from "./DispersionBlock.module.scss";

type DispersionBlockProps = {
  intervalType: "top" | "middle" | "bottom";
  intervalValue: 4 | 10 | 20;
  data: any;
  type: "Stock" | "ETF";
  showRating?: boolean;
};

type RatingBoxProps = {
  data: any;
  isPositiveRating: boolean;
};

export default function DispersionBlock({
  intervalType,
  intervalValue,
  data,
  type,
  showRating = true,
}: DispersionBlockProps) {
  const percentage = useMemo(() => {
    switch (intervalValue) {
      default:
      case 4: {
        return {
          top: "25%",
          middle: "50%",
          bottom: "25%",
        };
      }

      case 10: {
        return {
          top: "10%",
          middle: "80%",
          bottom: "10%",
        };
      }

      case 20: {
        return {
          top: "5%",
          middle: "90%",
          bottom: "5%",
        };
      }
    }
  }, [intervalValue]);

  const formatter = useFormatter();

  const formatNumber = useCallback(
    (number, isPerc = true, hasPositiveSign = false) => {
      return formatter.custom("number", {
        options: {
          hasPositiveSign: hasPositiveSign,
          isPercentage: isPerc,
          notAvailable: {
            input: 0,
            output: 0,
          },
        },
        output: "TEXT",
        value: number,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const intervalLabel = useMemo(
    () =>
      `${intervalType[0].toUpperCase() + intervalType.slice(1)} ${
        percentage[intervalType]
      }`,
    [intervalType, percentage]
  );

  const minValue = useMemo(
    () => data && formatNumber(data[intervalType].min, true, true),
    [data, formatNumber, intervalType]
  );

  const maxValue = useMemo(
    () => data && formatNumber(data[intervalType].max, true, true),
    [data, formatNumber, intervalType]
  );

  const average = useMemo(
    () => formatNumber(data[intervalType].average, true, true),
    [data, formatNumber, intervalType]
  );

  const cardinality = useMemo(
    () => data[intervalType].cardinality,
    [data, intervalType]
  );

  const cardinalityAB = useMemo(
    () => (data?.[intervalType]?.A ?? 0) + (data?.[intervalType]?.B ?? 0),
    [data, intervalType]
  );
  const cardinalityPercAB = useMemo(
    () =>
      (data?.[intervalType]?.["A_%"] ?? 0) +
      (data?.[intervalType]?.["B_%"] ?? 0),
    [data, intervalType]
  );

  const cardinalityCD = useMemo(
    () => (data?.[intervalType]?.C ?? 0) + (data?.[intervalType]?.D ?? 0),
    [data, intervalType]
  );
  const cardinalityPercCD = useMemo(
    () =>
      (data?.[intervalType]?.["D_%"] ?? 0) +
      (data?.[intervalType]?.["C_%"] ?? 0),
    [data, intervalType]
  );

  const unratedLabel = useMemo(() => {
    const unratedCardinality = data[intervalType].N;
    const unratedPerc = data[intervalType]["N_%"];

    return `* ${unratedCardinality} (${formatNumber(
      unratedPerc,
      true,
      false
    )}) unrated`;
  }, [data, formatNumber, intervalType]);

  return (
    <Card className={`${styles.block__card} ${intervalType}`}>
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          paddingTop: "5px",
          paddingBottom: "5px!important",
        }}
      >
        <div
          className={`${styles.block__column} ${styles.block__column__interval__section}`}
        >
          <span>
            <strong>{intervalLabel}</strong>
          </span>
        </div>
        <div className={`${styles.block__column} ${styles.min__max__column}`}>
          {intervalType !== "middle" && (
            <>
              <span>
                max: <strong>{maxValue}</strong>
              </span>
              <span>
                min: <strong>{minValue}</strong>
              </span>
            </>
          )}
        </div>
        <div
          className={`${styles.block__column} ${styles.block__column__average__section}`}
        >
          <span>
            avg: <strong>{average}</strong>
          </span>
        </div>
        <div
          className={`${styles.block__column} ${styles.block__column__cardinality__section}`}
        >
          {cardinality} {type + "s"}
        </div>
        {showRating && (
          <div className={styles.block__column}>
            {intervalType !== "middle" && (
              <>
                {" "}
                <span>% of stocks by rating</span>
                <div className={styles.block__column__rating__section}>
                  <RatingBox
                    data={{
                      cardinality: cardinalityAB,
                      cardinalityPerc: formatNumber(
                        cardinalityPercAB,
                        true,
                        false
                      ),
                    }}
                    isPositiveRating={true}
                  />
                  <RatingBox
                    data={{
                      cardinality: cardinalityCD,
                      cardinalityPerc: formatNumber(
                        cardinalityPercCD,
                        true,
                        false
                      ),
                    }}
                    isPositiveRating={false}
                  />
                </div>
                <span>{unratedLabel}</span>
              </>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

const RatingBox = ({ isPositiveRating, data }: RatingBoxProps) => {
  return (
    <Card
      sx={{ flex: 1, display: "flex" }}
      className={styles.rating__box__card}
    >
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: "5px !important",
        }}
      >
        <div className={styles.rating__box__title}>
          <span
            className={`format-rate format-rate--${
              isPositiveRating ? "A" : "C"
            }`}
          >
            {isPositiveRating ? "A" : "C"}
          </span>
          <span
            className={`format-rate format-rate--${
              isPositiveRating ? "B" : "D"
            }`}
          >
            {isPositiveRating ? "B" : "D"}
          </span>
        </div>
        <div className={styles.rating__box__content}>
          <ul>
            <li>
              <strong>{data.cardinality}</strong>
            </li>
            <li>
              <strong>{data.cardinalityPerc}</strong>
            </li>
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};
