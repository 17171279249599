import { Box, Card, CardContent, FormControlLabel, Switch, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import InputNumber from "../../../../../../../../../components/InputNumber/InputNumber";

type Props = {
  min: any;
  max: any;
  dispatcher: Function;
};

export default function CostituentsCapping({ min, max, dispatcher }: Props) {
  const inputExists = useMemo(() => {
    if (min != null || max != null) {
      return true;
    }
    return false;
  }, [max, min]);

  const [isSectionActive, setIsSectionActive] = useState(inputExists);

  const switchHandler = (e) => {
    setIsSectionActive(e.target.checked);
    if (e.target.checked === false) {
      dispatcher({ type: "SET_CAPPING_SECURITY_TO_NULL" });
    }
    if (e.target.checked === true && inputExists === false) {
      dispatcher({ type: "INIT_CAPPING_SECURITY" });
    }
  };
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          gap={2}
        >
          <FormControlLabel
            sx={{ width: "max-content", gap: 1 }}
            control={
              <Switch
                size="small"
                checked={isSectionActive}
                onChange={(e) => {
                  switchHandler(e);
                }}
              />
            }
            label={<Typography>Constituents capping</Typography>}
          />

          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }} sx={{ gap: 1 }}>
              <Typography>Min</Typography>
              <InputNumber
                maxWidth={100}
                disableGutters
                // constraints={{ minVal: 0, maxVal: 100 }}
                initVal={min != null ? min : inputExists ? null : 0.01}
                isDisabled={!isSectionActive}
                isPercentage={true}
                setOuterState={(val) => {
                  // if (val != null) {
                  dispatcher({ type: "SET_CAPPING_SECURITY_MIN", payload: val });
                  // }
                }}
                isFloating={true}
              />
            </Box>

            <Box style={{ display: "flex", alignItems: "center" }} sx={{ gap: 1 }}>
              <Typography>Max</Typography>
              <InputNumber
                maxWidth={100}
                disableGutters
                // constraints={{ minVal: 0, maxVal: 100 }}
                initVal={max != null ? max : inputExists ? null : 0.2}
                isDisabled={!isSectionActive}
                isPercentage={true}
                setOuterState={(val) => {
                  // if (val != null) {
                  dispatcher({ type: "SET_CAPPING_SECURITY_MAX", payload: val });
                  // }
                }}
                isFloating={true}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
