import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        // Main container
        const appMainWrapper = document.getElementById("app-main-wrapper");
        if (appMainWrapper) {
            if (appMainWrapper.scrollTo) {
                appMainWrapper.scrollTo(0, 0);
            }
            if (appMainWrapper.scrollTop) {
                appMainWrapper.scrollTop = 0; // IE11 fix
            }
        }
    }, [location]);

    return null;
}
