import { Card, CardContent } from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { helpTextobjs } from "./helpItems";

type HelpType = {
  caller: string;
};
export const Help = forwardRef(({ caller }: HelpType, ref) => {
  const [st, setSt] = useState<any>();
  useImperativeHandle(
    ref,
    () => ({
      setHelp: (value) => {
        setSt(value);
      },
    }),
    []
  );

  const helperToShow = useMemo(() => {
    const property = st?.property;
    const operator = st?.operator;
    const helpText = helpTextobjs[caller];
    if (helpText != null) {
      if (property == null && operator == null) {
        // return helpText["init"];
        return null;
      } else if (property === "rc") {
        return helpText["rc"];
      } else if (property === "tradedValue") {
        return helpText["tradedValue"];
      } else if (helpText.hasOwnProperty(property as string)) {
        return helpText[property as string][operator];
      } else {
        return null;
      }
    }
    return <>no descriptions...</>;
  }, [caller, st?.operator, st?.property]);
  return helperToShow != null ? (
    <div style={{ height: "100%" }}>
      <Card sx={{ height: "100%", boxShadow: 3 }}>
        <CardContent>{helperToShow}</CardContent>
      </Card>
    </div>
  ) : null;
});
