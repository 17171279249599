import { useMemo } from "react";
import { Cluster } from "../../js/app/pages/analysisLists/analyze/tabs/TabCompare/ComparisonTableWidget";
import { RadioButtonBar } from "../RadioButtonBar/RadioButtonBar";

export const options = {
  Instrument: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Regions",
      value: "Region",
    },
    {
      label: "Areas",
      value: "Area",
    },
    {
      label: "Sector",
      value: "1 Industry",
    },
    {
      label: "Industry",
      value: "3 Sector",
    },
    //TODO: when will be reenabled check filters synch and the API call in the index page
    // {
    //     label: "Inv. Region",
    //     value: "etfgeo",
    // },
    // {
    //     label: "Asset class",
    //     value: "etfclass",
    // },
    {
      label: "Type",
      value: "Type",
    },
    {
      label: "Currency",
      value: "Currency",
    },
    {
      label: "Size",
      value: "3 Level",
    },
  ],
  ETF: [
    {
      label: "Inv. Region",
      value: "etfgeo",
    },
    {
      label: "Asset class",
      value: "AssetClass",
    },
    {
      label: "Specialty",
      value: "Specialty",
    },
  ],
  Stock: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Regions",
      value: "Region",
    },
    {
      label: "Areas",
      value: "Area",
    },
    {
      label: "Sector",
      value: "1 Industry",
    },
    {
      label: "Industry",
      value: "3 Sector",
    },
    {
      label: "Currency",
      value: "Currency",
    },
    {
      label: "Size",
      value: "3 Level",
    },
  ],
  Index: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Regions",
      value: "Region",
    },
    {
      label: "Areas",
      value: "Area",
    },
  ],
  Commodity: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Currency",
      value: "Currency",
    },
  ],
  Currency: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Currency",
      value: "Currency",
    },
  ],
  Sector: [
    {
      label: "Markets",
      value: "Country",
    },
    {
      label: "Regions",
      value: "Region",
    },
    {
      label: "Areas",
      value: "Area",
    },
    {
      label: "Sector",
      value: "1 Industry",
    },
    {
      label: "Industry",
      value: "3 Sector",
    },
  ],
} as const;

type AdvancedSelectorProps = {
  actualValue: Cluster | string | number;
  setValue: (segment: Cluster) => void;
  optionKey?: keyof typeof options;
  manuallyProvidedOptions?: { label: string; value: string | number }[];
};

/**
 *
 * @param { actualValue } string: the active voice of the selector.
 * @param { setValue } callback: function to get the selected value from this component.
 * @param { optionKey } string: the key to extract options from the options object.
 *
 * @returns JSX element.
 */
export function AdvancedSelector({
  actualValue,
  setValue,
  optionKey,
  manuallyProvidedOptions,
}: AdvancedSelectorProps) {
  const onSetSelector = (value) => {
    setValue(value);
  };

  const _options = useMemo(
    () => manuallyProvidedOptions ?? options[optionKey ?? "Instrument"],
    [manuallyProvidedOptions, optionKey]
  );

  return (
    <RadioButtonBar
      options={_options as any}
      onChange={(value) => onSetSelector(value)}
      initValue={actualValue}
    />
  );
}
