// /**
//  * @author Trendrating <info@trendrating.net>
//  *
//  * @module app/utils/CSV
//  * @summary Export data in CSV format
//  */
//  define([
//     "dojo/_base/declare",
//     "dojo/_base/lang",
//     "dojo/dom-construct",
//     "dojo/request"
// ], function(declare, lang, domConstruct, http) {
//     var CSV = declare(null, {
//         lineEnd: "\r\n",

//         separator: function() {
//             var list = ["a", "b"];
//             var listLocaleString = list.toLocaleString();
//             return listLocaleString[1];
//         },

//         /**
//          * Create a temporary node and simulate a click to activate download
//          *
//          * @param {Array} lines - The lines of the file: array of arrays
//          * @param {String} filename - The name of the file to be downloaded
//          */
//         create: function(lines, filename) {
//             this.createClientSide(lines, filename);

//         },

//         /**
//          * Create a temporary node and simulate a click to activate download
//          *
//          * @param {Array} lines - The lines of the file: array of arrays
//          * @param {String} filename - The name of the file to be downloaded
//          */
//         createClientSide: function(lines, filename) {
//             var lineEnd = this.lineEnd;
//             var separator = this.separator();
//             filename = /\.csv$/gi.test(filename) ? filename : filename + ".csv";

//             for (let i = 0, length = lines.length; i < length; i++) {
//                 lines[i] = lines[i].join(separator);
//             }

//             var csv = lines.join(lineEnd);

//             var blob = new Blob([csv], {
//                 type: "text/csv;charset=utf8;"
//             });

//             if (navigator.msSaveBlob) {
//                 // IE 11
//                 var downloadLink = domConstruct.create(
//                     "a",
//                     {
//                         href: "#" + filename
//                     },
//                     document.body
//                 );
//                 downloadLink.addEventListener(
//                     "click",
//                     function(event) {
//                         navigator.msSaveBlob(blob, filename);
//                     },
//                     false
//                 );
//             } else {
//                 downloadLink = domConstruct.create(
//                     "a",
//                     {
//                         class: "a11y",
//                         download: filename,
//                         href: window.URL.createObjectURL(blob)
//                     },
//                     document.body
//                 );
//             }

//             downloadLink.click();
//             domConstruct.destroy(downloadLink);
//         }
//     });

//     return new CSV();
// });

export class CSV {
  static lineEnd = "\r\n";

  //   constructor() {}

  static separator = function () {
    var list = ["a", "b"];
    var listLocaleString = list.toLocaleString();
    return listLocaleString[1];
  };

  /**
   * Create a temporary node and simulate a click to activate download
   *
   * @param {Array} lines - The lines of the file: array of arrays
   * @param {String} filename - The name of the file to be downloaded
   */
  static create(lines, filename) {
    this.createClientSide(lines, filename);
  }

  /**
   * Create a temporary node and simulate a click to activate download
   *
   * @param {Array} lines - The lines of the file: array of arrays
   * @param {String} filename - The name of the file to be downloaded
   */
  static createClientSide(lines, filename) {
    var lineEnd = this.lineEnd;
    var separator = this.separator();
    filename = /\.csv$/gi.test(filename) ? filename : filename + ".csv";

    for (let i = 0, length = lines.length; i < length; i++) {
      lines[i] = lines[i].join(separator);
    }

    var csv = lines.join(lineEnd);

    var blob = new Blob([csv], {
      type: "text/csv;charset=utf8;",
    });

    const nav = navigator as any;
    if (nav.msSaveBlob) {
      // IE 11
      var downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", `#${filename}`);
      document.body.appendChild(downloadLink);
      downloadLink.addEventListener(
        "click",
        function (event) {
          nav.msSaveBlob(blob, filename);
        },
        false
      );
    } else {
      downloadLink = document.createElement("a");
      const arrAttr = [
        { attr: "download", value: filename },
        { attr: "href", value: `${window.URL.createObjectURL(blob)}` },
      ];

      arrAttr.forEach((item) => {
        downloadLink.setAttribute(item.attr, item.value);
      });
      downloadLink.classList.add("a11y");
      document.body.appendChild(downloadLink);
    }

    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
