/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/News
 *
 * @summary Requests for News about instruments and peers
 *
 */

import { httpAll } from "../../httpAll";
import { Timeframe } from "../../types/Api";
import { AppEnvironment } from "../../types/Defaults";
import { endpoints } from "../endpoints";
import { _Base } from "../_Base";
import { Instruments } from "./Instruments";
import { Peers } from "./Peers";

export class NewsSelect extends _Base {
    http: any;

    // It overwrites _Base constructor because of extra initialization
    // operations are needed
    constructor(environment: AppEnvironment) {
        super(environment);

        this.http = {
            instruments: new Instruments(environment),
            peers: new Peers(environment),
        };
    }

    /**
     * Get news for the given timeframe
     *
     * @param {string} timeframe - one of
     *      "today" (default), "lastWeek", "lastMonth" or "last3Months"
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async get(
        timeframe: Timeframe,
        dataType: "Stock" | "Sector" | "Index" | "ETF",
        widgetFilters: any
    ) {
        var endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        var url = endPointRoot + endpoints.newsSelect.get;
        const timeFrame = this._encodeTimeframe(timeframe);

        const _params = {
            filters: [{ dimension: "type", segments: [dataType] }],
            ranges: [
                { dimension: "direction", segments: [{ max: 4, min: -4 }] },
                { dimension: "lr", segments: [{ max: timeFrame, min: 0 }] },
            ],
            page: {
                page: 1,
                rows: 50,
            },
            sort: [{ dimension: "marketcap", rev: true }],
        };

        if (widgetFilters != null) {
            this._encodeExternalConstraints(_params, widgetFilters);
        } else {
            var userPreferences = this._getUserPreferences();
            this._encodeConstraints(_params, userPreferences);
        }

        const response = await this.preparePost(url, _params, null);
        const fullResponse = await this._get(response);

        return this._decodeGet(fullResponse);
    }
    // ----------------------------------------------------- private methods
    // decode get() response
    _decodeGet(response: any) {
        const _response: any = {
            instruments: {
                commodity: [],
                currency: [],
                etf: [],
                index: [],
                sector: [],
                stock: [],
            },
        };

        var data = response["instruments"];
        for (let i = 0, length = data.length; i < length; i++) {
            const item = data[i];
            switch (item["type"]) {
                case "Commodity": {
                    _response["instruments"]["commodity"].push(item);

                    break;
                }
                case "Currency": {
                    _response["instruments"]["currency"].push(item);

                    break;
                }
                case "ETF": {
                    _response["instruments"]["etf"].push(item);

                    break;
                }
                case "Index": {
                    _response["instruments"]["index"].push(item);

                    break;
                }
                case "Sector": {
                    _response["instruments"]["sector"].push(item);

                    break;
                }
                case "Stock": {
                    _response["instruments"]["stock"].push(item);

                    break;
                }
            }
        }

        return _response;
    }

    _encodeTimeframe(timeframe: Timeframe) {
        switch (timeframe) {
            case "lastWeek": {
                return 4;
            }
            case "lastMonth":
                return 19;
            case "today":
            default: {
                return 0;
            }
        }
    }

    _encodeExternalConstraints(
        target: any,
        constraints: {
            where: string[];
            what: string[];
            size: any;
            whereETF: string[];
            assetClass: string[];
            marketCap: any;
        }
    ) {
        let encodedConstraints: {
            dimension: string;
            segments: string[];
        }[] = [];

        //In segments value is already the final array by the reducer in UI component
        for (let [key, value] of Object.entries(constraints)) {
            switch (key) {
                case "whereETF":
                    if (value.length && !("WWW" in value)) {
                        encodedConstraints.push({
                            dimension: "etfgeo",
                            segments: value,
                        });
                    }
                    break;
                case "assetClass":
                    if (value.length && !("ETFSEGMENT" in value)) {
                        encodedConstraints.push({
                            dimension: "etfclass",
                            segments: value,
                        });
                    }
                    break;
                case "marketCap":
                    const { left, right } = value;
                    if (value != null) {
                        const rangeConstraint = {
                            dimension: "marketcap",
                            segments: [{ max: right, min: left }],
                        };

                        target["ranges"].push(rangeConstraint);
                    }
                    break;
                case "where":
                    if (value.length && !("WWW" in value)) {
                        encodedConstraints.push({
                            dimension: "country",
                            segments: value,
                        });
                    }
                    break;
                case "what":
                    if (value.length && !("ICB" in value)) {
                        encodedConstraints.push({
                            dimension: "icb",
                            segments: value,
                        });
                    }
                    break;
                case "size":
                    {
                        const { left, right } = value;
                        if (value != null) {
                            const rangeConstraint = {
                                dimension: "marketcap",
                                segments: [{ max: right, min: left }],
                            };

                            target["ranges"].push(rangeConstraint);
                        }
                    }
                    break;
            }
        }

        const filters = target.filters;

        for (const filter of encodedConstraints) {
            let isFilterAlreadyInside =
                filters.find(
                    (item: any) => item.dimension === filter.dimension
                ) != null
                    ? true
                    : false;

            if (!isFilterAlreadyInside) {
                filters.push(filter);
            }
        }
    }

    // modify target according to decodedConstraints
    _encodeConstraints(target: any, decodedConstraints: any) {
        if (!("filters" in target)) {
            target["filters"] = [];
        }

        if (
            decodedConstraints["where"] != null &&
            decodedConstraints["where"].length > 0
        ) {
            target.filters.push({
                dimension: "country",
                segments: decodedConstraints["where"],
            });
        }

        if (
            decodedConstraints["whereDomestic"] === true &&
            decodedConstraints["whereForeign"] === true
        ) {
            // Do not add any subtype, already selected all
        } else if (decodedConstraints["whereDomestic"] === true) {
            target.filters.push({
                dimension: "subtype",
                segments: ["Domestic Stock"],
            });
        } else if (decodedConstraints["whereForeign"] === true) {
            target.filters.push({
                dimension: "subtype",
                segments: ["Foreign Stock"],
            });
        }
    }

    // get data about instruments and peers
    _get(response: any) {
        var data = response["data"];

        return httpAll({
            instruments: this.http["instruments"].fetch({
                properties: [
                    {
                        date: null,
                        property: "country",
                    },
                    {
                        date: null,
                        property: "etfclass",
                    },
                    {
                        date: null,
                        property: "etfgeo",
                    },
                    {
                        date: null,
                        property: "icb",
                    },
                    {
                        date: null,
                        property: "marketcap",
                    },
                    {
                        date: null,
                        property: "name",
                    },
                    {
                        date: null,
                        property: "rc",
                    },
                    {
                        date: null,
                        property: "ticker",
                    },
                    {
                        date: null,
                        property: "type",
                    },
                ],
                symbols: data.ids,
                type: "security",
            }),
        }).then(function _unwrap(response) {
            return {
                instruments: response["instruments"]["data"],
            };
        });
    }

    _getUserPreferences() {
        var _preferences =
            this.environment.account.user?.preferences?.preferences ?? null;

        var preferences = {
            where: null,
        };

        if (_preferences?.screening?.markets != null) {
            var constraints = _preferences.screening.markets;

            if (constraints["ids"] != null) {
                preferences["where"] = constraints["ids"];
            }
        }

        return preferences;
    }
}
