import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useImmerReducer } from "use-immer";
import Modal from "../../../../../../../../../components/Modal/Modal";
import SelectionRulesWizzard from "../../../../../../../../../components/RuleWizzard/SelectionRulesWizzard/SelectionRulesWizzard";
import {
  elementBuilder,
  setOptionsAttr,
} from "../../../../../../../../../components/RuleWizzard/SelectionRulesWizzard/utils";
import SortableList from "../../../../../../../../../components/SortableList/SortableList";
import { useResizer } from "../../../../../../../../../hooks/useResizer";
import BlackList from "../../fields/BlackList";
import styles from "./InvestmentUniverseController.module.scss";
import ScreenerPanel from "./widgets/ScreenerPanel/ScreenerPanel";
import { WhiteListPanel } from "./widgets/WhiteListPanel/WhiteListPanel";
import { UI_CONSTRAINTS_TYPE } from "../../../Advanced/utils";
import { useEnvironment } from "../../../../../../../../../hooks/useEnvironment";
import { Lists } from "../../../../../../../../../api/compute/Lists";

type InvestmentUniverseControllerProps = {
  selectionOptions: any;
  titleRuleList: any;
  caller: any;
  onCloseSelection: any;
  onCancel: any;
  actualStrategyConfig: any;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

type InvestmentUniverseReducerActions =
  | {
      type: "SET_WHITELIST_VALUE";
      payload: any;
    }
  | { type: "SET_TRIM_OUTLIERS"; payload: boolean }
  | { type: "SET_BLACKLIST"; payload: string[] }
  | { type: "SET_SCREENING"; payload: any }
  | { type: "SET_SELECTION"; payload: any };

type InvestmentUniverseDraft = {
  whiteList?: string;
  screening?: any;
  blacklist?: string[];
  selection?: any;
  trimOutliers: boolean;
};

const listContentForListItem = (ruleList, opt) => {
  let arr: any = [];
  if (ruleList != null) {
    ruleList.forEach((item) => {
      const objTemp = {
        content: elementBuilder(item, opt?._options, opt?._optionsIndex),
        value: item,
      };
      arr.push(objTemp);
    });
    return arr;
  }
  return arr;
};

const investmentUniverseDraft: InvestmentUniverseDraft = {
  whiteList: undefined,
  screening: undefined,
  blacklist: [],
  selection: undefined,
  trimOutliers: false,
};

const investmentUniverseReducer = (
  draft: InvestmentUniverseDraft,
  action: InvestmentUniverseReducerActions
) => {
  switch (action.type) {
    case "SET_WHITELIST_VALUE":
      draft.screening = undefined;
      draft.whiteList = action.payload;

      break;

    case "SET_TRIM_OUTLIERS":
      draft.trimOutliers = action.payload;

      break;

    case "SET_BLACKLIST":
      draft.blacklist = action.payload;
      break;

    case "SET_SCREENING":
      draft.whiteList = undefined;
      draft.screening = action.payload;

      break;

    case "SET_SELECTION":
      draft.selection = action.payload;

      break;
  }
};

export function InvestmentUniverseController({
  actualStrategyConfig,
  selectionOptions,
  titleRuleList,
  caller,
  onCloseSelection,
  onCancel,
  UI_CONSTRAINTS,
}: InvestmentUniverseControllerProps) {
  const containerRef = useRef(null);
  useResizer({ ref: containerRef });
  const [controllerState, dispatch] = useImmerReducer(
    investmentUniverseReducer,
    actualStrategyConfig ?? investmentUniverseDraft
  );

  const [widgetToShow, setWidgetToShow] = useState<
    "screenerStock" | "screenerEtf" | "whiteList"
  >("whiteList");
  const [showRulesModal, setShowRulesModal] = useState(false);

  const selectionOptionsSet = useMemo(
    () => setOptionsAttr(selectionOptions["edit"]),
    [selectionOptions]
  );
  const selectionRulesForSortableList = useMemo(() => {
    if (controllerState.selection && controllerState.selection.length) {
      const adaptedRules = controllerState.selection.map((item, index) => ({
        field: item,
        index: index,
      }));
      return listContentForListItem(adaptedRules, selectionOptionsSet);
    } else {
      return [];
    }
  }, [controllerState.selection, selectionOptionsSet]);

  useEffect(() => {
    if (
      controllerState.whiteList != null &&
      UI_CONSTRAINTS.investementUnivese.whiteList === true
    ) {
      setWidgetToShow("whiteList");
    } else if (controllerState.screening != null) {
      const screenerType = controllerState.screening.instrumentType;
      const screenerToShow =
        screenerType === "etf" ? "screenerEtf" : "screenerStock";

      setWidgetToShow(screenerToShow);
    }
  }, [
    UI_CONSTRAINTS.investementUnivese.whiteList,
    controllerState.screening,
    controllerState.whiteList,
  ]);

  const [isDisabled, setIsDisabled] = useState(true);

  const handleWidgetSelectionChange = useCallback((e) => {
    const value = e.target.value;
    setIsDisabled(false);
    setWidgetToShow(value);
  }, []);

  const environment = useEnvironment();

  const handleWhiteListSelection = useCallback(
    async (selectedWhiteList) => {
      setIsDisabled(false);

      if (selectedWhiteList != null) {
        let listAPI = new Lists(environment.get("setup"));
        let data = await listAPI.portfolioFetch(
          [selectedWhiteList],
          ["id", "type"]
        );
        dispatch({
          type: "SET_WHITELIST_VALUE",
          payload: data[0],
        });
      }
    },
    [dispatch, environment]
  );

  const setTrimOutliers = useCallback(
    (value) => {
      setIsDisabled(false);
      dispatch({ type: "SET_TRIM_OUTLIERS", payload: value });
    },
    [dispatch]
  );

  const handleRulesSelection = useCallback(
    (selectionRules) => {
      setIsDisabled(false);
      setShowRulesModal(false);
      dispatch({ type: "SET_SELECTION", payload: selectionRules });
    },
    [dispatch]
  );

  return (
    <Box
      flex={1}
      display={"flex"}
      p={2}
      ref={containerRef}
      alignItems={"flex-start"}
    >
      <Box
        display={"flex"}
        flex={8}
        marginRight={2}
        justifyContent={"flex-start"}
        flexDirection={"column"}
      >
        {showRulesModal && (
          <Modal
            closeIcon={false}
            onClose={() => setShowRulesModal(false)}
            headerConfig={{ headerContent: "Selection Rules" }}
            customCss={{
              maxWidth: "80%",
              maxHeight: "90%",
            }}
            bodyCustomClass={styles.modal__selection__custom}
          >
            <SelectionRulesWizzard
              autoResize={false}
              titleRuleList={titleRuleList}
              onClose={handleRulesSelection}
              caller={caller}
              options={selectionOptions}
              onCancel={() => setShowRulesModal(false)}
              rules={controllerState.selection}
              startsWithWizzardOpen={true}
            />
          </Modal>
        )}
        <Box minHeight={0} pb={0} minWidth={0} display={"flex"} mb={2}>
          <Card
            sx={{
              display: "flex",
              flex: 1,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                minWidth: 0,
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Box display={"flex"} flex={1}>
                <FormControl sx={{ flex: 1 }}>
                  <FormLabel
                    focused={false}
                    id="inv-universe-radio-buttons-group-label"
                    sx={{
                      fontSize: "1vw!important",
                      ".Mui-focused": {
                        color: "#2a7092 !important",
                      },
                    }}
                  >
                    Investment Universe:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="inv-universe-radio-buttons-group-label"
                    value={widgetToShow}
                    onChange={handleWidgetSelectionChange}
                    name="radio-buttons-group"
                  >
                    {UI_CONSTRAINTS.investementUnivese.whiteList === true ? (
                      <Box display={"flex"}>
                        <FormControlLabel
                          classes={{
                            label: styles.radio__labels,
                          }}
                          value="whiteList"
                          control={<Radio size="small" />}
                          label="White list"
                          sx={{
                            ".Mui-checked": {
                              color: "#2a7092 !important",
                            },
                          }}
                        />

                        {widgetToShow === "whiteList" && (
                          <WhiteListPanel
                            subject={"White List"}
                            setWhiteListValue={handleWhiteListSelection}
                            initValue={controllerState?.whiteList?.id}
                            closeIcon={true}
                          />
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}

                    <FormControlLabel
                      classes={{
                        label: styles.radio__labels,
                      }}
                      sx={{
                        ".Mui-checked": {
                          color: "#2a7092 !important",
                        },
                      }}
                      value="screenerStock"
                      control={<Radio size="small" />}
                      label="Screener Stocks"
                    />
                    <FormControlLabel
                      classes={{
                        label: styles.radio__labels,
                      }}
                      sx={{
                        ".Mui-checked": {
                          color: "#2a7092 !important",
                        },
                      }}
                      value="screenerEtf"
                      control={<Radio size="small" />}
                      label="Screener ETFs"
                    />
                  </RadioGroup>
                </FormControl>

                {widgetToShow !== "whiteList" && (
                  <ScreenerPanel
                    setIsDisabled={setIsDisabled}
                    actualInvUniverse={actualStrategyConfig.screening}
                    type={widgetToShow !== "screenerEtf" ? "stock" : "ETF"}
                    setScreening={dispatch}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box flex={1} minHeight={0}>
          <Accordion
            disableGutters={true}
            sx={{ minHeight: 0, marginBottom: 2 }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Optional Parameters</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ overflow: "auto" }}>
              <Box display={"flex"} flex={1} sx={{ flexDirection: "column" }}>
                {UI_CONSTRAINTS.investementUnivese.filterOutlayers.enabled && (
                  <Box display={"flex"} flex={1} flexDirection={"column"}>
                    <FormControlLabel
                      sx={{ m: 0, mb: 1 }}
                      control={
                        <Switch
                          size="small"
                          checked={controllerState.trimOutliers}
                          onChange={(e) => setTrimOutliers(e.target.checked)}
                        />
                      }
                      label="Filter Outliers"
                      title="The outliers are securities that perform abnormally relative to the average of all stocks in the same set"
                    />
                  </Box>
                )}
                <Card sx={{ flex: 2 }}>
                  <CardContent
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      component={"fieldset"}
                      className={styles.outlined__box}
                    >
                      <legend className={styles.outlined__box__label}>
                        Exclusion List
                      </legend>
                      <Box className={styles.outlined__box__content}>
                        <BlackList
                          blacklist={controllerState.blacklist ?? []}
                          setBlacklist={(blackList) => {
                            setIsDisabled(false);
                            dispatch({
                              type: "SET_BLACKLIST",
                              payload: blackList,
                            });
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              backgroundColor: "#2a7092",
                              "&:hover": {
                                backgroundColor: "#00496d",
                              },
                              textTransform: "capitalize",
                            }}
                            type="button"
                          >
                            Exclusion List
                          </Button>
                        </BlackList>
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                          }}
                        >
                          {controllerState.blacklist?.length ?? 0} in Exclusion
                          List
                        </Typography>
                      </Box>
                    </Box>
                    {UI_CONSTRAINTS.investementUnivese.additionalContraints
                      .enabled === true && (
                      <Box
                        mt={1}
                        component={"fieldset"}
                        className={styles.outlined__box}
                      >
                        <legend className={styles.outlined__box__label}>
                          Additional Rules
                        </legend>
                        <Box className={styles.outlined__box__content}>
                          <Box
                            display={"flex"}
                            flex={1}
                            flexDirection={"column"}
                          >
                            <SortableList
                              listContainerStyle={{
                                border: "solid #2a7092 2px",
                                overflow: "hidden",
                                flex: 1,
                              }}
                              header={{
                                headerTitle: (
                                  <h3
                                    style={{
                                      fontWeight: "inherit",
                                    }}
                                  >
                                    {titleRuleList}
                                  </h3>
                                ),
                                headerStyle: {
                                  backgroundColor: "#2a7092",
                                  color: "#fff",
                                  fontWeight: "bold",
                                },
                              }}
                              listSetter={(obj) => {}}
                              arrItems={selectionRulesForSortableList ?? []}
                              isSortable={false}
                              getSelectedItemToEdit={() => {}}
                              selectedItemFromOutside={() => {}}
                              emptyListStatement={"No rules defined"}
                            />
                            <div>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  backgroundColor: "#2a7092",
                                  "&:hover": {
                                    backgroundColor: "#00496d",
                                  },
                                  textTransform: "capitalize",
                                  marginTop: 1,
                                }}
                                onClick={() => setShowRulesModal(true)}
                                type="button"
                              >
                                {controllerState.selection == null ||
                                controllerState.selection.length < 1
                                  ? "Add"
                                  : "Edit"}
                              </Button>
                            </div>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Card>
            <CardContent sx={{ paddingBottom: "16px!important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#2a7092",
                    "&:hover": {
                      backgroundColor: "#00496d",
                    },
                    ml: 2,
                    textTransform: "capitalize",
                  }}
                  onClick={() => onCloseSelection(controllerState)}
                  type="button"
                  disabled={isDisabled}
                >
                  Apply rule
                </Button>

                <Button
                  sx={{
                    color: "#2a7092",
                    backgroundColor: "#fff",
                    ml: 2,
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#f2f2f2",
                    },
                  }}
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  cancel
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Help />
    </Box>
  );
}

const Help = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flex: 2,
        backgroundColor: "#f2f2f2",
      }}
    >
      <CardContent sx={{ boxShadow: 3, flex: 1 }}>
        <section>
          <h2 style={{ marginBottom: "16px" }}>Investment Universe</h2>
          <ul>
            <li>
              <p>
                The investment universe defines the complete set of securities
                from which the strategy can pick.
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>White list:</strong> static list of securities from
                which securities entitled to be held in the portfolio will be
                drawn. The same set of securities will constitute the investment
                universe for each backtesting period.
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Screener:</strong> select markets and sectors from which
                securities can be selected to define the investment universe.
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Eligibility:</strong> Controls to further narrow the
                investment in the screener module. In the first control the
                number of securities to which to narrow the universe is
                specified, and the second set of controls provides the option to
                narrow by either "Highest Mkt. Cap." or by "Lower Mkt. Cap.".
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Additional rules:</strong> Flexible rules to further
                narrow the investment universe.
              </p>
            </li>
          </ul>
        </section>
      </CardContent>
    </Card>
  );
};
