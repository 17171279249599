import { useTranslation } from "react-i18next";
import { MarketsItem } from "./MarketsItem";

type MarketsProps = {
    markets: any;
    timeframe: any;
};

const getSortedMarkets = (data) => {
    const sortMarketsOrder = {
        index: 1,
        sector: 2,
        stock: 3,
        etf: 4,
    };

    return data.sort(
        (a, b) => sortMarketsOrder[a["id"]] - sortMarketsOrder[b["id"]]
    );
};

export function Markets({ markets, timeframe }: MarketsProps) {
    const { t } = useTranslation();
    const sortedMarkets = markets ? getSortedMarkets(markets) : [];

    return (
        <div className="tMarkets">
            <ul className="tMarkets-list">
                {timeframe == null || markets == null ? (
                    <li className="tMarkets-item tMarkets-item--noData">
                        {t("no_data_to_display")}
                    </li>
                ) : sortedMarkets.length ? (
                    sortedMarkets.map((item, index) => {
                        if (item.label !== "Currencies") {
                            return (
                                <MarketsItem
                                    key={`${index}__${item.id}`}
                                    data={item}
                                    timeframe={timeframe}
                                />
                            );
                        }
                        return null;
                    })
                ) : (
                    <div>No data available</div>
                )}
            </ul>
        </div>
    );
}
