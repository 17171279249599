import {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { PortfolioHomeStorage } from "../../../storage/PortfolioHomeStorage";
import { ListViewerTileItem } from "./ListViewerTileItem";

type ListViewerTileProps = {
  storage: PortfolioHomeStorage;
  timeframe: "daily" | "weekly" | "monthly";
  onClickTileItem: Function;
  lists: any;
};

export const ListViewerTile = memo(function ListViewerTile({
  storage,
  timeframe,
  lists,
  onClickTileItem,
}: ListViewerTileProps) {
  const nodeListRef = useRef<HTMLDivElement>(null);
  const [itemsData, setitemsData] = useState<any>(undefined);
  const { on, remove } = useEventBus();
  const refs = useMemo(() => {
    if (itemsData) {
      const refsObj = {};
      itemsData.forEach((id) => {
        refsObj[id] = createRef();
      });

      return refsObj;
    }

    return undefined;
  }, [itemsData]);

  const environment = useEnvironment();

  const dataGetList = useCallback(
    async (listId) => {
      let list: any = undefined;

      try {
        list = await storage.getListForTile(parseInt(listId), timeframe);
      } catch (error) {
        console.log(error);
        list = undefined;
      }

      const listViewerTileRef = refs ? refs[listId]?.current : undefined;
      if (listViewerTileRef) {
        listViewerTileRef.loadListData(list);
      }
    },
    [refs, storage, timeframe]
  );

  const listenerObserver = useCallback(
    (entries, observer) => {
      let entry: any = null;
      let listId: any = null;
      for (let i = 0, length = entries.length; i < length; i++) {
        entry = entries[i];

        if (entry.isIntersecting === true && entry.intersectionRatio > 0) {
          listId = entry["target"].getAttribute("data-list-id");
          dataGetList(listId);
          observer.unobserve(entry.target);
        }
      }
    },
    [dataGetList]
  );

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        (entries, observer) => listenerObserver(entries, observer),
        {
          root: nodeListRef.current,
          rootMargin: "0px",
          threshold: 0.01,
        }
      ),
    [listenerObserver]
  );

  const dataGet = useCallback(async () => {
    if (lists) {
      setitemsData(lists);
    }
  }, [lists]);

  useEffect(() => {
    dataGet();
  }, [dataGet]);

  useEffect(() => {
    if (refs) {
      let node: any = null;
      Object.values(refs).forEach((ref: any) => {
        node = ref.current.getNode();
        observer.observe(node);
      });
    }
  }, [observer, refs]);

  useEffect(() => {
    on("refresh-view", dataGet);

    return () => remove("refresh-view", dataGet);
  }, [dataGet, on, remove]);

  return (
    <div className="tListViewerTile" ref={nodeListRef}>
      <div className="tListViewerTile-items">
        {itemsData !== undefined &&
          itemsData.map((item: any) => {
            return (
              <ListViewerTileItem
                ref={refs ? refs[item] : undefined}
                key={uuidv4()}
                environment={environment}
                timeframe={timeframe}
                onItemClick={(value) => onClickTileItem(value)}
                list={{ id: item }}
              />
            );
          })}
      </div>
    </div>
  );
});
