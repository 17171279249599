import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { ClusterAnalytics } from "../../../../../../../api/compute/ClusterAnalytics";
import {
  formatTaxonPrefixingAncestor,
  getTaxonById,
} from "../../../../../../../api/compute/Taxon";
import { Tooltip } from "../../../../../../../components/Tooltip/Tooltip";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { _rate } from "../../../../../../../trendrating/formatter/_rate";

type WhatWhereTableProps = {
  portfolioAssetType: string;
  portfolioPositions: any;
  onCellClick: (value) => void;
};

const staticFolder = `${window.appConfig.baseUrlImages}flags/`;

export function WhatWhereTable({
  portfolioAssetType,
  portfolioPositions,
  onCellClick,
}: WhatWhereTableProps) {
  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState("Country");
  const [what, setWhat] = useState("1 Industry");
  const [dataVizParams, setDataVizParams] = useState({
    visualization: "color",
    analytic: "tcr",
    timeframe: "today",
  });
  const [crossData, setCrossData] = useState<any>();

  const { t } = useTranslation();
  const environment = useEnvironment();

  const clusterAPI = useMemo(
    () => new ClusterAnalytics(environment.get("setup")),
    [environment]
  );

  const positionType = useMemo(() => {
    return portfolioAssetType === "ETF" ? "etf" : "instrument";
  }, [portfolioAssetType]);

  const taxonomies = useMemo(() => {
    return environment.get("rawTaxonomies");
  }, [environment]);

  const options = useCallback(
    (widgetType) => {
      switch (widgetType) {
        case "what": {
          if (positionType === "etf") {
            return [
              {
                label: "Any",
                value: "ETFSEGMENT",
              },
              {
                label: "Asset class",
                value: "ETF - 1 Industry",
              },
              {
                label: "Specialty",
                value: "ETF - 3 Sector",
              },
            ];
          } else {
            return [
              {
                label: "Any",
                value: "0 root",
              },
              {
                label: "Sectors",
                value: "1 Industry",
              },
              {
                label: "Industries",
                value: "3 Sector",
              },
            ];
          }
        }
        case "where": {
          if (positionType === "etf") {
            return [
              {
                label: "Market",
                selected: true,
                value: "etfgeo",
              },
            ];
          }
          return [
            {
              label: t("World_wide"),
              value: "World",
            },
            {
              label: t("Areas"),
              value: "Area",
            },
            {
              label: t("Regions"),
              value: "Region",
            },
            {
              label: t("Markets"),
              value: "Country",
            },
          ];
        }

        // no default
      }
    },
    [positionType, t]
  );

  const whereOptions = useMemo(() => options("where"), [options]);
  const whatOptions = useMemo(() => options("what"), [options]);

  const prepareParams = useCallback(
    ({ analytic, timeframe, visualization, what, where }) => {
      // constraits bar
      var valueAnalytic = analytic;
      var valueTimeframe = timeframe;
      var valueVisualization = visualization;
      let whatValue = what;

      switch (whatValue) {
        case "ETF - 1 Industry":
          whatValue = "AssetClass";

          break;

        case "ETF - 3 Sector":
          whatValue = "Specialty";

        //no default
      }
      const whereValue = where;
      // instrument type
      var params = {
        analytic: valueAnalytic,
        analyticKey: [valueAnalytic, valueTimeframe, valueVisualization].join(
          "_"
        ),
        instrumentType: "stock",
        timeframe: valueTimeframe,
        visualization: valueVisualization,
        whatType: whatValue,
        whereType: whereValue,
      };

      return params;
    },
    []
  );

  const injectCostituens = useCallback((response) => {
    const clusters = response["clustersStats"]["clusters"];
    const stats = response["clustersStats"]["stats"];

    for (const [key, value] of Object.entries<any>(stats)) {
      value["costituents"] = clusters[key];
    }

    return stats;
  }, []);

  const formatResponse = useCallback(
    (response) => {
      const data = injectCostituens(response);

      const sanitizeNaN = (value) => {
        return value ?? 0;
      };

      /**
       * Statistic
       * tcr
       */
      for (const value of Object.values<any>(data)) {
        value["fact_rc"] = value["TCR"];

        value["tcr"] = {
          today: value["TCR"],
          yesterday: value["TCR_D"],
          lastWeek: value["TCR_W"],
          lastMonth: value["TCR_M"],
        };

        value["statistic"] = {
          abPercentage: {
            today: sanitizeNaN(value["A_%"]) + sanitizeNaN(value["B_%"]),
            yesterday: value["ABchange_%"],
            lastWeek: value["ABchange_%_W"],
            lastMonth: value["ABchange_%_M"],
          },
          cdPercentage: {
            today: sanitizeNaN(value["C_%"]) + sanitizeNaN(value["D_%"]),
            yesterday: value["CDchange_%"],
            lastWeek: value["CDchange_%_W"],
            lastMonth: value["CDchange_%_M"],
          },
          downgrades: {
            today: {
              number: value["downgrades"],
              percentage: value["downgrades_%"],
            },
            lastMonth: {
              number: value["downgrades_M"],
              percentage: value["downgrades_%_M"],
            },
            lastWeek: {
              number: value["downgrades_W"],
              percentage: value["downgrades_%_W"],
            },
          },
          upgrades: {
            today: {
              number: value["upgrades"],
              percentage: value["upgrades_%"],
            },
            lastMonth: {
              number: value["upgrades_M"],
              percentage: value["upgrades_%_M"],
            },
            lastWeek: {
              number: value["upgrades_W"],
              percentage: value["upgrades_%_W"],
            },
          },
        };

        delete value["TCR"];
        delete value["TCR_D"];
        delete value["TCR_W"];
        delete value["TCR_M"];
        delete value["A_%"];
        delete value["B_%"];
        delete value["C_%"];
        delete value["D_%"];
        delete value["ABchange_%"];
        delete value["ABchange_%_W"];
        delete value["ABchange_%_M"];
        delete value["CDchange_%"];
        delete value["CDchange_%_W"];
        delete value["CDchange_%_M"];
        delete value["downgrades_%"];
        delete value["downgrades"];
        delete value["downgrades_%_W"];
        delete value["downgrades_W"];
        delete value["downgrades_%_M"];
        delete value["downgrades_M"];
        delete value["upgrades_%"];
        delete value["upgrades"];
        delete value["upgrades_%_W"];
        delete value["upgrades_W"];
        delete value["upgrades_%_M"];
        delete value["upgrades_M"];
      }

      return data;
    },
    [injectCostituens]
  );

  const dataPrepareSort = useCallback((data) => {
    // sort by rate (D->A), weight (10 -> 0)
    data.sort(function (a, b) {
      var sortByL1 = "rate";
      var sortByL2 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return 1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return -1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  }, []);

  const sortBy = useCallback((arrayOfObject, property, descending) => {
    descending = descending !== undefined ? descending : false;

    arrayOfObject.sort(function (a, b) {
      var itemA =
        typeof a[property] === "string"
          ? a[property].toLowerCase()
          : a[property];
      var itemB =
        typeof b[property] === "string"
          ? b[property].toLowerCase()
          : b[property];

      if (itemA > itemB) {
        return descending ? -1 : 1;
      }

      if (itemA < itemB) {
        return descending ? 1 : -1;
      }

      return 0;
    });
  }, []);

  const dataPrepare = useCallback(
    (rawData) => {
      let data: any = [];
      let decodedItems = {}; // Used for tooltip
      let what = rawData["what"];
      let whatWhere = rawData["whatWhere"];
      let where = rawData["where"];
      // const portfolio = this.portfolio;

      const portfoliosAssetType =
        portfolioAssetType === "ETF" ? "ETF" : "security";

      const fieldsMap = environment.get("setup")["taxonomyFields"];
      const fieldsKey = portfoliosAssetType;
      const whereField = portfoliosAssetType === "ETF" ? "etfgeo" : "country";
      const whatField = portfoliosAssetType === "ETF" ? "etfclass" : "sector";

      // WHERE | defining header order: sort by peer weight descending
      let xAxys: any = [];
      for (const [key, value] of Object.entries<any>(where)) {
        let item = value;
        // let decoded = http["peers"].decode(item);

        let decodedItem = {
          constituents: item["costituents"],
          cardinality: item["cardinality"],
          id: key,
          name: getTaxonById(
            key,
            [taxonomies[fieldsMap[fieldsKey][whereField]]],
            null
          ).name,
          rate: item["fact_rc"],
          statistic: item.statistic,
          tcr: item.tcr,
          type: "where",
          weight: item["weight"],
        };
        xAxys.push(decodedItem);
        decodedItems[String(decodedItem.id)] = decodedItem;
      }
      dataPrepareSort(xAxys);
      // WHAT | defining body order: sort by peer label ascending
      let yAxys: any = [];
      for (const [key, value] of Object.entries<any>(what)) {
        let item = value;

        let decodedItem = {
          constituents: item["costituents"],
          cardinality: item["cardinality"],
          id: key,
          // used for sort
          label: formatTaxonPrefixingAncestor(
            getTaxonById(
              key,
              [taxonomies[fieldsMap[fieldsKey][whatField]]],
              null
            ),
            taxonomies,
            "1 Industry"
          ),
          name: getTaxonById(
            key,
            [taxonomies[fieldsMap[fieldsKey][whatField]]],
            null
          ).name,
          rate: item["fact_rc"],
          statistic: item.statistic,
          tcr: item.tcr,
          type: "what",
          weight: item["weight"],
        };
        yAxys.push(decodedItem);
        decodedItems[String(decodedItem.id)] = decodedItem;
      }
      sortBy(yAxys, "label", false);
      // preparing data for rendering
      data = [[null].concat(xAxys)];

      for (let y = 0; y < yAxys.length; y++) {
        let items: any = [];
        let itemY = yAxys[y];

        // for consistency: returned objects
        // have only id property
        delete itemY["label"];
        items.push(itemY);
        for (let x = 0; x < xAxys.length; x++) {
          let itemX = xAxys[x];
          let itemXYKey = itemY["id"] + "," + itemX["id"];
          let itemXYKeyRaw = itemX["id"] + "|" + itemY["id"];

          if (itemXYKeyRaw in whatWhere) {
            let itemXY = whatWhere[itemXYKeyRaw];

            let fullName =
              getTaxonById(
                itemY["id"],
                [taxonomies[fieldsMap[fieldsKey][whatField]]],
                null
              ).name +
              "<br/>" +
              getTaxonById(
                itemX["id"],
                [taxonomies[fieldsMap[fieldsKey][whereField]]],
                null
              ).name;

            let decodedItemXY = {
              constituents: itemXY["costituents"],
              cardinality: itemXY["cardinality"],
              id: itemXYKey,
              name: fullName,
              rate: itemXY["fact_rc"],
              statistic: itemXY.statistic,
              tcr: itemXY.tcr,
              type: "whatWhere",
              weight: itemXY["weight"],
            };
            items.push(decodedItemXY);
            decodedItems[String(decodedItemXY.id)] = decodedItemXY;
          } else {
            items.push(null);
          }
        }
        data.push(items);
      }

      setCrossData(data);
    },
    [dataPrepareSort, environment, portfolioAssetType, sortBy, taxonomies]
  );

  const dataGet = useCallback(
    async ({ analytic, visualization, timeframe, what, where }) => {
      setLoading(true);

      try {
        const params = prepareParams({
          analytic,
          visualization,
          timeframe,
          what,
          where,
        });

        const analytics = [
          "cardinality",
          "weight",
          "TCR",
          "TCR_D",
          "TCR_W",
          "TCR_M",
          "A_%",
          "B_%",
          "C_%",
          "D_%",
          "ABchange_%",
          "ABchange_%_M",
          "ABchange_%_W",
          "CDchange_%",
          "CDchange_%_M",
          "CDchange_%_W",
          "upgrades",
          "upgrades_%",
          "upgrades_W",
          "upgrades_%_W",
          "upgrades_M",
          "upgrades_%_M",
          "downgrades",
          "downgrades_%",
          "downgrades_W",
          "downgrades_%_W",
          "downgrades_M",
          "downgrades_%_M",
        ];

        const request = await clusterAPI
          .createConfiguration()
          .segment(params.whatType)
          .segment(params.whereType)
          .method("INTERSECTION")
          .analytics(analytics)
          .universeFromPositions(portfolioPositions)
          .fetchAnalytics();

        const whatRawData = await clusterAPI
          .createConfiguration()
          .segment(params.whatType)
          .method("INTERSECTION")
          .analytics(analytics)
          .universeFromPositions(portfolioPositions)
          .fetchAnalytics();

        const whereRawData = await clusterAPI
          .createConfiguration()
          .segment(params.whereType)
          .method("INTERSECTION")
          .analytics(analytics)
          .universeFromPositions(portfolioPositions)
          .fetchAnalytics();

        const whatWhere = formatResponse(request);
        const _what = formatResponse(whatRawData);
        const _where = formatResponse(whereRawData);

        //Remove empty clusters
        for (const [key, value] of Object.entries<any>(whatWhere)) {
          if (value.costituents.length === 0) {
            delete whatWhere[key];
          }
        }

        dataPrepare({ whatWhere, where: _where, what: _what });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [clusterAPI, dataPrepare, formatResponse, portfolioPositions, prepareParams]
  );

  const onComponentMount = useCallback(async () => {
    const startingParameters = {
      visualization: "color",
      analytic: "tcr",
      timeframe: "today",
      what: "1 Industry",
      where: "Country",
    };

    dataGet(startingParameters);
  }, [dataGet]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  const currentUIParams = useMemo(() => {
    return prepareParams({
      analytic: dataVizParams.analytic,
      visualization: dataVizParams.visualization,
      timeframe: dataVizParams.timeframe,
      what,
      where,
    });
  }, [
    dataVizParams.analytic,
    dataVizParams.timeframe,
    dataVizParams.visualization,
    prepareParams,
    what,
    where,
  ]);

  const TableHeader = withFormatters(TableHead);
  const TBody = withFormatters(TableBody);

  const changeWhereLevel = useCallback(
    (value) => {
      setWhere(value);

      dataGet({ what, where: value, ...dataVizParams });
    },
    [dataGet, dataVizParams, what]
  );

  const changeWhatLevel = useCallback(
    (value) => {
      setWhat(value);

      dataGet({ what: value, where, ...dataVizParams });
    },
    [dataGet, dataVizParams, where]
  );

  return loading ? (
    <Box
      display={"flex"}
      flex={1}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress sx={{ color: "#2a7090" }} />
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  ) : (
    <div
      className="tAnalysisAllocation"
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <div className="tAnalysisAllocation-wrap">
        <h1 className="tAnalysisAllocation-title">
          <Box display={"flex"} gap={1}>
            <LevelMenu
              options={whereOptions}
              selectedValue={where}
              onValueChange={changeWhereLevel}
            />
            and
            <LevelMenu
              options={whatOptions}
              selectedValue={what}
              onValueChange={changeWhatLevel}
            />
          </Box>
        </h1>
        <div className="tAnalysisAllocation-constraintsWrap">
          <form
            action="#"
            className="tAnalysisAllocation-constraints"
            method="get"
          >
            <fieldset className="tAnalysisAllocation-constraintsAnalytic">
              <div
                data-dojo-attach-point="widgetConstraintsBar"
                data-dojo-type="app/pages/analysisLists/analyze/allocation/ConstraintsBar"
              >
                <WidgetConstraintsBar onParamsChange={setDataVizParams} />
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <div className="tAnalysisAllocation-content">
        <table className="tAnalysisAllocation-table">
          <thead data-dojo-attach-point="nodeHead">
            <TableHeader
              onCellClick={onCellClick}
              data={crossData}
              uiParams={currentUIParams}
              portfolioAssetTypes={portfolioAssetType}
            />
          </thead>
          <tbody data-dojo-attach-point="nodeBody">
            <TBody
              onCellClick={onCellClick}
              data={crossData}
              uiParams={currentUIParams}
              portfolioAssetTypes={portfolioAssetType}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

const TableHead = ({ data, uiParams, formatTaxon, rateScale, onCellClick }) => {
  const where = useMemo(() => uiParams.whereType, [uiParams.whereType]);

  return data ? (
    <tr>
      {data[0].map((item) => {
        if (item != null) {
          return (
            <th
              onClick={() => onCellClick(item)}
              key={uuidv4()}
              data-peer-id={item.id}
              className={[
                "tAnalysisAllocation-th ",
                "tAnalysisAllocation-th--where ",
                "tAnalysisAllocation-th--where",
                rateScale[item["rate"] != null ? item["rate"] : "U"][
                  "rateClass"
                ],
              ].join("")}
            >
              {where === "Country" ? (
                <WithTooltip uiParams={uiParams} peer={item}>
                  <img
                    src={`${staticFolder}${item["id"]}.png`}
                    height="24"
                    width="24"
                    alt={`${formatTaxon(item["id"], false, "where")}`}
                  />
                  {formatTaxon(item["id"], false, "where")}
                </WithTooltip>
              ) : (
                <WithTooltip uiParams={uiParams} peer={item}>
                  <div
                    className="tAnalysisAllocation-cellAreaRegion"
                    dangerouslySetInnerHTML={{
                      __html: formatTaxon(item["id"], false, "where").replace(
                        "-",
                        "<br/>"
                      ),
                    }}
                  ></div>
                </WithTooltip>
              )}
            </th>
          );
        } else {
          return <th key={uuidv4()} className="tAnalysisAllocation-th"></th>;
        }
      })}
    </tr>
  ) : (
    <></>
  );
};

const withFormatters = (Component) => {
  return function TableDesigner({
    data,
    uiParams,
    portfolioAssetTypes,
    onCellClick,
  }) {
    const environment = useEnvironment();
    const taxonomies = useMemo(() => {
      return environment.get("rawTaxonomies");
    }, [environment]);

    const formatTaxon = useCallback(
      (taxonId, isTitle, taxonType) => {
        if (!taxonType) {
          return taxonId;
        }

        isTitle = isTitle === undefined || isTitle == null ? false : isTitle;
        const taxonomyFields = environment.get("setup")["taxonomyFields"];

        const portfoliosAssetType =
          portfolioAssetTypes === "ETF" ? "ETF" : "security";
        const fieldKey =
          taxonType === "where"
            ? portfoliosAssetType !== "ETF"
              ? "country"
              : "etfgeo"
            : portfoliosAssetType !== "ETF"
            ? "sector"
            : "etfclass";
        var item = getTaxonById(
          taxonId,
          [taxonomies[taxonomyFields[portfoliosAssetType][fieldKey]]],
          null
        );

        if (item?.type) {
          switch (item["type"]) {
            case "Country": {
              return portfoliosAssetType !== "ETF" ? item["id"] : item.name;
            }
            default: {
              var plainText = formatTaxonPrefixingAncestor(
                getTaxonById(
                  taxonId,
                  [taxonomies[taxonomyFields[portfoliosAssetType][fieldKey]]],
                  null
                ),
                [taxonomies[taxonomyFields[portfoliosAssetType][fieldKey]]],
                taxonType === "where" ? "Area" : "1 Industry"
              );

              if (isTitle === true) {
                return plainText;
              } else {
                var tokens = plainText.split(" - ");

                if (tokens.length === 1) {
                  return tokens[0];
                } else {
                  return [
                    '<strong className="',
                    "tAnalysisAllocation-th--subject",
                    '">',
                    tokens[1],
                    '</strong><br/><span className="',
                    "tAnalysisAllocation-th--subSubject",
                    '">',
                    tokens[0],
                    "</span>",
                  ].join("");
                }
              }
            }
          }
        }
      },
      [environment, portfolioAssetTypes, taxonomies]
    );

    const rateScale = _rate["trendCaptureRating"];

    const handleClickEvent = useCallback(
      (cell) => onCellClick({ value: cell }),
      [onCellClick]
    );

    return (
      <Component
        data={data}
        uiParams={uiParams}
        formatTaxon={formatTaxon}
        rateScale={rateScale}
        onCellClick={handleClickEvent}
      />
    );
  };
};

const TableBody = ({ data, formatTaxon, uiParams, onCellClick }) => {
  const rateScale = _rate["trendCaptureRating"];

  return data ? (
    data.map((item, idx) => {
      // Skip cumulative row data
      if (idx === 0) {
        return <Fragment key={uuidv4()}></Fragment>;
      }

      return (
        <tr key={uuidv4()}>
          {item.map((cell, idx) => {
            if (idx === 0 && cell != null) {
              return (
                <th
                  onClick={() => onCellClick(cell)}
                  data-peer-id={cell.id}
                  style={{ minWidth: "20em" }}
                  key={uuidv4()}
                  className={[
                    "tPeerTable-tableCell--hasTooltip",
                    "tAnalysisAllocation-th ",
                    "tAnalysisAllocation-th--what ",
                    "tAnalysisAllocation-th--what",
                    rateScale[cell["rate"] != null ? cell["rate"] : "U"][
                      "rateClass"
                    ],
                  ].join("")}
                >
                  <WithTooltip peer={cell} uiParams={uiParams}>
                    <div
                      className="tAnalysisAllocation-th--whatContent"
                      dangerouslySetInnerHTML={{
                        __html: formatTaxon(cell["id"], false, "what"),
                      }}
                    ></div>
                  </WithTooltip>
                </th>
              );
            } else if (cell != null) {
              return (
                <td
                  data-peer-id={cell.id}
                  key={uuidv4()}
                  className={
                    "tAnalysisAllocation-td tPeerTable-tableCell--hasTooltip"
                  }
                  onClick={() => onCellClick(cell)}
                >
                  <BodyCellTCR params={uiParams} cellData={cell} />
                </td>
              );
            } else {
              return (
                <td
                  key={uuidv4()}
                  className={
                    "tAnalysisAllocation-td tAnalysisAllocation-td--empty"
                  }
                >
                  <EmprtyCell />
                </td>
              );
            }
          })}
        </tr>
      );
    })
  ) : (
    <></>
  );
};

const LevelMenu = ({ options, selectedValue, onValueChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = useCallback(
    (value) => {
      onValueChange(value);
      handleClose();
    },
    [onValueChange]
  );

  const label = useMemo(() => {
    return options.find((item) => item.value === selectedValue)?.label ?? "";
  }, [options, selectedValue]);

  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        <Typography sx={{ color: "#2a7090", fontSize: "1vw" }}>
          {label}
        </Typography>
        <ExpandMoreIcon sx={{ color: "#2a7090" }} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((item) => {
          return (
            <MenuItem key={uuidv4()} onClick={() => selectOption(item.value)}>
              <Typography>{item.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

const WidgetConstraintsBar = ({ onParamsChange }) => {
  const [analytic, setAnalytic] = useState("tcr");
  const [timeframe, setTimeframe] = useState("today");
  const [visualization, setVisualization] = useState("color");

  const { t } = useTranslation();

  const optionsSetSelected = useCallback((options, value) => {
    var found: any = false;
    var option: any = null;
    for (let i = 0, length = options.length; i < length; i++) {
      option = options[i];

      if (option["value"] === value) {
        option["selected"] = true;
        found = true;
      }
    }

    // if value is null or not found, the first option is set to
    // selected
    if (found === false) {
      options[0]["selected"] = true;
    }

    return options;
  }, []);

  const options = useCallback(
    (optionsType) => {
      switch (optionsType) {
        case "analytic": {
          return [
            {
              label: t("TCR"),
              selected: true,
              value: "tcr",
            },
            {
              label: t("AB_percentage"),
              value: "abPercentage",
            },
          ];
        }
        case "timeframe": {
          return [
            {
              label: t("Today"),
              title: t("Today"),
              value: "today",
            },
            {
              label: t("1_w"),
              title: t("1_Week"),
              value: "lastWeek",
            },
            {
              label: t("1_m"),
              title: t("1_Month"),
              value: "lastMonth",
            },
          ];
        }
        case "visualization_abPercentage": {
          return [
            {
              label: t("Value"),
              value: "percentage",
            },
          ];
        }
        case "visualization_abPercentage_changes": {
          return [
            {
              label: t("Changes"),
              value: "percentage",
            },
          ];
        }
        case "visualization_tcr": {
          var options = [
            {
              label: t("Default"),
              value: "color",
            },
            {
              label: t("Changes"),
              value: "change",
            },
            { label: t("Reversals"), value: "reversal" },
          ];

          return optionsSetSelected(options, visualization);
        }
        case "visualization_tcr_changesReversals": {
          options = [
            {
              label: t("Changes"),
              value: "change",
            },
            { label: t("Reversals"), value: "reversal" },
          ];

          return optionsSetSelected(options, visualization);
        }
        case "visualization_upgradesDowngrades": {
          return [
            {
              label: t("Numbers"),
              value: "number",
            },
            { label: t("Percentages"), value: "percentage" },
          ];
        }

        // no default
      }
    },
    [optionsSetSelected, t, visualization]
  );

  const analyticsOpts = useMemo(() => options("analytic"), [options]);
  const timeframeOpts = useMemo(() => options("timeframe"), [options]);
  const [vizOptions, setVizOptions] = useState(options("visualization_tcr"));

  const updateVizOptions = useCallback((opts) => {
    setVizOptions(opts);

    if (opts?.[0]?.value) {
      setVisualization(opts[0].value);
    }
  }, []);

  const listenerChange = useCallback(
    (widgetType: "analytic" | "timeframe" | "visualization", value) => {
      var _options: any = [];
      const _analytic = widgetType === "analytic" ? value : analytic;
      const _timeframe = widgetType === "timeframe" ? value : timeframe;
      let _visualization =
        widgetType === "visualization" ? value : visualization;
      if (widgetType === "analytic" || widgetType === "timeframe") {
        switch (_analytic) {
          case "abPercentage": {
            switch (_timeframe) {
              case "lastMonth":
              case "lastWeek": {
                _options = options("visualization_abPercentage_changes");

                _visualization = _options?.[0]?.value;
                updateVizOptions(_options);

                break;
              }
              case "today":
              default: {
                _options = options("visualization_abPercentage");

                _visualization = _options?.[0]?.value;
                updateVizOptions(_options);
              }
            }

            break;
          }
          case "upgradesDowngrades": {
            _options = options("visualization_upgradesDowngrades");

            _visualization = _options?.[0]?.value;
            updateVizOptions(_options);

            break;
          }
          case "tcr":
          default: {
            switch (_timeframe) {
              case "lastMonth":
              case "lastWeek": {
                _options = options("visualization_tcr_changesReversals");

                _visualization = _options?.[0]?.value;
                updateVizOptions(_options);
                break;
              }
              case "today":
              default: {
                _options = options("visualization_tcr");

                _visualization = _options?.[0]?.value;
                updateVizOptions(_options);
              }
            }
          }
        }
      }

      const widgetTypeCallback = {
        analytic: setAnalytic,
        timeframe: setTimeframe,
        visualization: setVisualization,
      };

      widgetTypeCallback[widgetType](value);

      const currentState = {
        analytic: _analytic,
        timeframe: _timeframe,
        visualization: _visualization,
      };

      onParamsChange(currentState);
    },
    [
      analytic,
      onParamsChange,
      options,
      timeframe,
      updateVizOptions,
      visualization,
    ]
  );

  return (
    <div className="tMarketConstraintsBar">
      <div className="tMarketConstraintsBar-layout">
        <div className="tMarketConstraintsBar-section">
          <label className="tMarketConstraintsBar-label">{t("Analytic")}</label>
          <div
            data-dojo-attach-point="widgetAnalytic"
            data-dojo-type="trendrating-widgets/form/radioButtonBar/RadioButtonBar"
          >
            <Box display={"flex"} gap={2}>
              {analyticsOpts.map((opt) => (
                <Box key={uuidv4()}>
                  <Typography
                    sx={
                      analytic === opt.value
                        ? {
                            color: "#2a7090",
                            borderBottom: "2px solid #2a7090",
                            fontSize: "1vw!important",
                            py: "8px",
                            cursor: "pointer",
                          }
                        : {
                            fontSize: "1vw!important",
                            py: "8px",
                            cursor: "pointer",
                          }
                    }
                    onClick={() => listenerChange("analytic", opt.value)}
                  >
                    {opt.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </div>
        </div>
        <div className="tMarketConstraintsBar-section">
          <label className="tMarketConstraintsBar-label">
            {t("Timeframe")}
          </label>
          <div
            data-dojo-attach-point="widgetTimeframe"
            data-dojo-type="trendrating-widgets/form/radioButtonGroup/RadioButtonGroup"
          >
            <ButtonGroup size="small" aria-label="Small button group">
              {timeframeOpts.map((opt) => (
                <Button
                  key={uuidv4()}
                  variant={timeframe === opt.value ? "contained" : "outlined"}
                  onClick={() => listenerChange("timeframe", opt.value)}
                >
                  {opt.label}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className="tMarketConstraintsBar-section">
          <label className="tMarketConstraintsBar-label">
            {t("Visualization")}
          </label>
          <div
            data-dojo-attach-point="widgetVisualization"
            data-dojo-props="hasToDisplayAllOptions: true"
            data-dojo-type="trendrating-widgets/form/radioButtonGroup/RadioButtonGroup"
          >
            <ButtonGroup size="small" aria-label="Small button group">
              {vizOptions.map((opt) => (
                <Button
                  key={uuidv4()}
                  variant={
                    visualization === opt.value ? "contained" : "outlined"
                  }
                  onClick={() => listenerChange("visualization", opt.value)}
                >
                  {opt.label}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

const BodyCellTCR = ({ params, cellData }) => {
  const formatter = useFormatter();
  const timeframe = params["timeframe"];
  const analytic = params["analyticKey"];
  const visualization = params["visualization"];
  const cssClassCellContentTcr = "tAnalysisAllocation-tableCellContentTcr";
  const cssClassCellContentArrow = "tPeerTable-arrow";
  const cssClassCellContentArrowAngleDowngrade = "tPeerTable-arrow--downgrade";
  const cssClassCellContentArrowAngleUpgrade = "tPeerTable-arrow--upgrade";
  const tcr = cellData["tcr"]["today"];

  const getTcrPrevious = useCallback((peer, analytic) => {
    switch (analytic) {
      case "tcr_lastWeek_change":
      case "tcr_lastWeek_color":
      case "tcr_lastWeek_reversal": {
        return peer["tcr"]["lastWeek"];
      }
      case "tcr_lastMonth_change":
      case "tcr_lastMonth_color":
      case "tcr_lastMonth_reversal": {
        return peer["tcr"]["lastMonth"];
      }
      case "tcr_today_change":
      case "tcr_today_reversal": {
        return peer["tcr"]["yesterday"];
      }
      case "tcr_today_color": {
        return peer["tcr"]["today"];
      }

      // no default
    }
  }, []);

  const formatRate = useCallback(
    (rate, isTitle) => {
      isTitle = isTitle === undefined || isTitle == null ? false : isTitle;

      return formatter.tcr(rate, isTitle ? "TEXT" : "HTML");
    },
    [formatter]
  );

  const tcrPrevious = useMemo(
    () => getTcrPrevious(cellData, analytic),
    [analytic, cellData, getTcrPrevious]
  );

  const formatNumber = useCallback(
    (value) => {
      return formatter.custom("number", {
        options: {
          decimals: 0,
          notAvailable: {
            input: 0,
            output: "-",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const formatPercentage = useCallback(
    (value, hasArrow) => {
      var formatted: any = [];

      if (hasArrow === true) {
        if (value > 0) {
          formatted.push('<i className="i-arrow-u"></i>');
        } else if (value < 0) {
          formatted.push('<i className="i-arrow-d"></i>');
        }
      }

      var percentage = formatter.custom("number", {
        options: {
          decimals: value === 1 ? 0 : 2,
          isPercentage: true,
          notAvailable: {
            input: 0,
            output: "-",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
      percentage =
        /00%$/gi.test(percentage) === true
          ? percentage.replace(".00%", "%")
          : percentage;

      formatted.push(percentage);

      return formatted.join(" ");
    },
    [formatter]
  );

  // 0 is B-
  const _tcr = tcr === 0 ? 0.1 : tcr;
  const _tcrPrevious = tcrPrevious === 0 ? 0.1 : tcrPrevious;

  if (
    analytic !== "tcr_lastMonth_color" &&
    analytic !== "tcr_lastWeek_color" &&
    analytic !== "tcr_today_color" &&
    analytic != null
  ) {
    if (_tcr !== _tcrPrevious || _tcr * _tcrPrevious < 0) {
      var cssClassDirection: any = null;

      switch (analytic) {
        case "tcr_lastWeek_change":
        case "tcr_lastMonth_change":
        case "tcr_today_change": {
          if (_tcr !== _tcrPrevious) {
            cssClassDirection =
              tcr > tcrPrevious
                ? ["i-arrow-u", cssClassCellContentArrow].join(" ")
                : ["i-arrow-d", cssClassCellContentArrow].join(" ");
          }

          if (cssClassDirection != null) {
            return (
              <WithTooltip peer={cellData} uiParams={params}>
                <div className={cssClassCellContentTcr}>
                  <span className={cssClassDirection}></span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatter.tcr(tcr, "HTML"),
                    }}
                  ></span>
                </div>
              </WithTooltip>
            );
          } else {
            return <EmprtyCell />;
          }
        }
        case "tcr_lastMonth_reversal":
        case "tcr_lastWeek_reversal":
        case "tcr_today_reversal": {
          if (_tcr * _tcrPrevious < 0) {
            cssClassDirection =
              tcr > tcrPrevious
                ? [
                    "i-arrow-angle",
                    cssClassCellContentArrow,
                    cssClassCellContentArrowAngleUpgrade,
                  ].join(" ")
                : [
                    "i-arrow-angle",
                    cssClassCellContentArrow,
                    cssClassCellContentArrowAngleDowngrade,
                  ].join(" ");
          }

          if (cssClassDirection != null) {
            return (
              <WithTooltip peer={cellData} uiParams={params}>
                <div className={cssClassCellContentTcr}>
                  <span className={cssClassDirection}></span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatter.tcr(tcr, "HTML"),
                    }}
                  ></span>
                </div>
              </WithTooltip>
            );
          } else {
            return <EmprtyCell />;
          }
        }

        case "abPercentage_lastMonth_percentage":
        case "abPercentage_lastWeek_percentage":
        case "abPercentage_today_percentage": {
          var ab = cellData["statistic"]["abPercentage"][timeframe];

          if (ab === 0) {
            return <EmprtyCell />;
          } else {
            return (
              <WithTooltip peer={cellData} uiParams={params}>
                <span
                  className="cellText"
                  dangerouslySetInnerHTML={{
                    __html: formatPercentage(
                      ab,
                      analytic === "abPercentage_today_percentage"
                        ? false
                        : true
                    ),
                  }}
                ></span>
              </WithTooltip>
            );
          }
        }
        case "upgradesDowngrades_lastMonth_number":
        case "upgradesDowngrades_lastWeek_number":
        case "upgradesDowngrades_today_number": {
          var downgrades =
            cellData["statistic"]["downgrades"][timeframe][visualization];
          var upgrades =
            cellData["statistic"]["upgrades"][timeframe][visualization];

          if (downgrades > 0 || upgrades > 0) {
            return (
              <WithTooltip peer={cellData} uiParams={params}>
                <div
                  className="cellText"
                  dangerouslySetInnerHTML={{
                    __html:
                      upgrades === 0
                        ? "&nbsp;"
                        : "Up " + formatNumber(upgrades),
                  }}
                ></div>
                <div
                  className="cellText"
                  dangerouslySetInnerHTML={{
                    __html:
                      downgrades === 0
                        ? "&nbsp;"
                        : "Up " + formatNumber(downgrades),
                  }}
                ></div>
              </WithTooltip>
            );
          } else {
            return <EmprtyCell />;
          }
        }
        case "upgradesDowngrades_lastMonth_percentage":
        case "upgradesDowngrades_lastWeek_percentage":
        case "upgradesDowngrades_today_percentage": {
          var percentageDowngrades =
            cellData["statistic"]["downgrades"][timeframe][visualization];
          var percentageUpgrades =
            cellData["statistic"]["upgrades"][timeframe][visualization];

          if (percentageDowngrades > 0 || percentageUpgrades > 0) {
            return (
              <WithTooltip peer={cellData} uiParams={params}>
                <div
                  className="cellText"
                  dangerouslySetInnerHTML={{
                    __html:
                      percentageUpgrades === 0
                        ? "&nbsp;"
                        : "Up " + formatPercentage(percentageUpgrades, false),
                  }}
                ></div>
                <div
                  className="cellText"
                  dangerouslySetInnerHTML={{
                    __html:
                      percentageDowngrades === 0
                        ? "&nbsp;"
                        : "Dn " + formatPercentage(percentageDowngrades, false),
                  }}
                ></div>
              </WithTooltip>
            );
          } else {
            return <EmprtyCell />;
          }
        }

        // no default
        default:
          return <EmprtyCell />;
      }
    } else {
      return <EmprtyCell />;
    }
  } else {
    let rate = formatRate(cellData["rate"], false);
    let percentage = formatter.custom("number", {
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: cellData["weight"],
      valueHelper: null,
    });

    return (
      <div className="tAnalysisAllocation-cellMarket">
        <WithTooltip peer={cellData} uiParams={params}>
          <span dangerouslySetInnerHTML={{ __html: rate }}></span>
          <span dangerouslySetInnerHTML={{ __html: percentage }}></span>
        </WithTooltip>
      </div>
    );
  }
};

const EmprtyCell = () => {
  return <div className="tAnalysisAllocation-cellMarket"></div>;
};

const WithTooltip = ({ children, uiParams, peer }) => {
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const format = useFormatter();

  const getData = useCallback(() => {
    var params = uiParams;
    var timeframe = params.timeframe;

    var tooltipData = {
      id: peer["id"],
      cardinality: peer["cardinality"],
      instrumentsAlert: {
        downgrades: peer["statistic"]["downgrades"][timeframe]["percentage"],
        downgradesCardinality:
          peer["statistic"]["downgrades"][timeframe]["number"],
        upgrades: peer["statistic"]["upgrades"][timeframe]["percentage"],
        upgradesCardinality: peer["statistic"]["upgrades"][timeframe]["number"],
      },
      instrumentsRating: {
        ab: peer["statistic"]["abPercentage"][timeframe],
        cd: peer["statistic"]["cdPercentage"][timeframe],
      },
      name: peer["name"] === "__ROOT__" ? t("World_wide") : peer["name"],
      changeReversal: {
        from:
          timeframe === "today"
            ? peer["tcr"]["yesterday"]
            : peer["tcr"][timeframe],
        to: peer["tcr"]["today"],
      },
      tcr: peer["tcr"]["today"],
      timeframe: timeframe,
    };

    setData(tooltipData);
  }, [peer, t, uiParams]);

  const tcr = useMemo(() => format.tcr(data?.["tcr"], "HTML"), [data, format]);
  const name = useMemo(
    () => (data?.["name"] == null ? "-" : data["name"].replace(",", "<br/>")),
    [data]
  );
  const timeframeValue = useMemo(() => {
    switch (data?.["timeframe"]) {
      case "lastMonth": {
        return t("Last_month_changes");
      }
      case "lastWeek": {
        return t("Last_week_changes");
      }

      case "today":
      default: {
        return "";
      }
    }
  }, [data, t]);

  const formatPercentage = useCallback(
    (value, hasArrow) => {
      var formatted: any = [];

      if (hasArrow === true) {
        if (value > 0) {
          formatted.push('<i className="i-arrow-u"></i>');
        } else if (value < 0) {
          formatted.push('<i className="i-arrow-d"></i>');
        }
      }

      var percentage = format.custom("number", {
        options: {
          decimals: value === 1 ? 0 : 2,
          isPercentage: true,
          notAvailable: {
            input: 0,
            output: "-",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
      percentage =
        /00%$/gi.test(percentage) === true
          ? percentage.replace(".00%", "%")
          : percentage;

      formatted.push(percentage);

      return formatted.join(" ");
    },
    [format]
  );

  const ab = useMemo(() => {
    const ab = data?.["instrumentsRating"]?.["ab"];
    switch (data?.["timeframe"]) {
      case "lastMonth":
      case "lastWeek": {
        return (
          (ab > 0
            ? '<i class="i-arrow-u"></i>'
            : ab < 0
            ? '<i class="i-arrow-d"></i>'
            : "") + formatPercentage(ab, false)
        );
      }
      case "today":
      default: {
        return formatPercentage(ab, false);
      }
    }
  }, [data, formatPercentage]);

  const cd = useMemo(
    () => formatPercentage(data?.["instrumentsRating"]?.["cd"], false),
    [data, formatPercentage]
  );

  const from = useMemo(() => {
    return data?.["changeReversal"]?.["from"];
  }, [data]);

  const to = useMemo(() => data?.["changeReversal"]?.["to"], [data]);

  return (
    <Tooltip
      onShow={getData}
      content={
        <Box p={1}>
          <div className="tPeerTooltipContent">
            <div className="tPeerTooltipContent-layout">
              <div className="tPeerTooltipContent-info">
                <div
                  className="tPeerTooltipContent-tcr"
                  data-dojo-attach-point="nodeTcr"
                  dangerouslySetInnerHTML={{ __html: tcr }}
                ></div>
              </div>
              <div className="tPeerTooltipContent-info">
                <div
                  className="tPeerTooltipContent-box tPeerTooltipContent-name"
                  data-dojo-attach-point="nodeName"
                  dangerouslySetInnerHTML={{ __html: name }}
                ></div>

                <div>
                  {t("Instruments")}
                  <span data-dojo-attach-point="nodeCardinality">
                    : {data?.cardinality}
                  </span>
                </div>

                <p
                  className="tPeerTooltipContent-timeframe"
                  data-dojo-attach-point="nodeTimeframe"
                >
                  {timeframeValue}
                </p>

                <div className="tPeerTooltipContent-box">
                  <div>
                    <strong className="format-rate format-rate--A">A</strong>{" "}
                    and{" "}
                    <strong className="format-rate format-rate--B">B</strong>:
                    <span
                      data-dojo-attach-point="nodeRatingAB"
                      dangerouslySetInnerHTML={{ __html: ab }}
                    ></span>
                  </div>
                  <div data-dojo-attach-point="nodeRatingCDContainer">
                    <strong className="format-rate format-rate--C">C</strong>{" "}
                    and{" "}
                    <strong className="format-rate format-rate--D">D</strong>:
                    <span
                      data-dojo-attach-point="nodeRatingCD"
                      dangerouslySetInnerHTML={{ __html: cd }}
                    ></span>
                  </div>
                </div>

                {data?.["changeReversal"] != null && from !== to && (
                  <div data-dojo-attach-point="nodeChangeReversal">
                    <span data-dojo-attach-point="nodeChangeReversalLabel">
                      {from > to ? t("Downgraded_from") : t("Upgraded_from")}
                    </span>{" "}
                    <span
                      data-dojo-attach-point="nodeChangeReversalFrom"
                      dangerouslySetInnerHTML={{
                        __html: format.tcr(from, "HTML"),
                      }}
                    ></span>{" "}
                    {t("to")}{" "}
                    <span
                      data-dojo-attach-point="nodeChangeReversalTo"
                      dangerouslySetInnerHTML={{
                        __html: format.tcr(to, "HTML"),
                      }}
                    ></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      }
      tooltipIcon={false}
      placement={"right"}
      interactive={true}
    >
      <div className="tAnalysisAllocation-cellMarket">{children}</div>
    </Tooltip>
  );
};
