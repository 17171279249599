import { Avatar, Box, Card, CardContent, Chip } from "@mui/material";
import React from "react";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";

type MomentumProps = {
  frame: any;
};

export default function Momentum({ frame }: MomentumProps) {
  const format = useFormatter();
  return (
    <Card sx={{ boxShadow: 3, flex: 2 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          pb: "8px !important",
        }}
      >
        <Box>Rating</Box>
        <Box display={"flex"} flexDirection="row" gap={1}>
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems={"flex-start"}
            gap={1}
          >
            <Chip
              sx={{
                width: "100%",
                borderColor: "transparent",
                justifyContent: "flex-start",
              }}
              variant="outlined"
              avatar={
                <Avatar
                  sx={{
                    color: "inherit",
                    backgroundColor: "transparent",
                    boxShadow: 2,
                  }}
                >
                  <span className="rate rate--A">A</span>
                </Avatar>
              }
              label={
                <strong>
                  {" "}
                  {_formatPercentage(frame ? frame.A : 0, format)}
                </strong>
              }
            />
            <Chip
              sx={{
                width: "100%",
                borderColor: "transparent",
                justifyContent: "flex-start",
              }}
              variant="outlined"
              avatar={
                <Avatar sx={{ backgroundColor: "transparent", boxShadow: 2 }}>
                  <span className="rate rate--C">C</span>
                </Avatar>
              }
              label={
                <strong>
                  {_formatPercentage(frame ? frame.C : 0, format)}
                </strong>
              }
            />
          </Box>
          <Box display={"flex"} flexDirection="column" gap={1}>
            <Chip
              sx={{
                width: "100%",
                borderColor: "transparent",
                justifyContent: "flex-start",
              }}
              variant="outlined"
              avatar={
                <Avatar sx={{ backgroundColor: "transparent", boxShadow: 2 }}>
                  <span className="rate rate--B">B</span>
                </Avatar>
              }
              label={
                <strong>
                  {" "}
                  {_formatPercentage(frame ? frame.B : 0, format)}
                </strong>
              }
            />

            <Chip
              sx={{
                width: "100%",
                borderColor: "transparent",
                justifyContent: "flex-start",
              }}
              variant="outlined"
              avatar={
                <Avatar sx={{ backgroundColor: "transparent", boxShadow: 2 }}>
                  <span className="rate rate--D">D</span>
                </Avatar>
              }
              label={
                <strong>
                  {" "}
                  {_formatPercentage(frame ? frame.D : 0, format)}
                </strong>
              }
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export const _formatPercentage = (value, format) => {
  return format.custom("number", {
    options: {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    },
    output: "HTML",
    value: value,
    valueHelper: null,
  });
};
