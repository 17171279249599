import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import InputNumber from "../../../../../../../../../components/InputNumber/InputNumber";

type InvestmentMinProps = {
  valueFromOutside: { min: any; max: any };
  dispatcher: Function;
  validationChecker: Function;
};

export default function Investment({
  valueFromOutside,
  dispatcher,
  validationChecker,
}: InvestmentMinProps) {
  const defaultValue = useMemo(() => {
    if (valueFromOutside.min == null && valueFromOutside.max == null) {
      return { min: 1, max: 1 };
    }
    return valueFromOutside;
  }, [valueFromOutside]);
  const [state, setState] = useState<any>(defaultValue);
  useEffect(() => {
    setState(valueFromOutside);
  }, [valueFromOutside]);

  useEffect(() => {
    dispatcher(state);
  }, [dispatcher, state]);
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent sx={{ pb: "16px !important", display: "flex", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography>Investment min</Typography>
          <InputNumber
            required
            maxWidth={100}
            disableGutters
            constraints={{ maxVal: 100 }}
            initVal={defaultValue.min}
            getIsValid={(e) => {
              validationChecker("weightInCashMin", !e);
            }}
            isPercentage={true}
            setOuterState={(e) => {
              if (e !== defaultValue.min) {
                dispatcher({ type: "SET_WEIGHT_IN_CASH_MIN", payload: e });
              }
            }}
            isFloating={true}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography>Investment max</Typography>
          <InputNumber
            maxWidth={100}
            required
            disableGutters
            constraints={{ maxVal: 100 }}
            initVal={defaultValue.max}
            getIsValid={(e) => validationChecker("weightInCashMax", !e)}
            isPercentage={true}
            setOuterState={(e) => {
              if (e !== defaultValue.max) {
                dispatcher({ type: "SET_WEIGHT_IN_CASH_MAX", payload: e });
              }
            }}
            isFloating={true}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
