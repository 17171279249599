import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { decodePeerId } from "../../../../../../../../../api/utils";
import { Spinner } from "../../../../../../../../../components/Spinner/Spinner";
import { InfoTooltip } from "../../../../../../alerts/InfoTooltip";
import DispersionChart from "../../../../../../analysisLists/analyze/tabs/TabDispersion/DispersionChart";
import {
  ActionsETFMarketsControls,
  DispersionByViewActionMarketsETF,
} from "../../../../AnalysisMarketsETF";
import { SelectorETFMarkets } from "../../../FiltersBarMarketDetail/FiltersBarMarketDetail";
import styles from "./../DispersionView/DispersionView.module.scss";
import DispersionByTable from "./DispersionByTable";

type DispersionByViewProps = {
  data: any;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  dispatch: (action: ActionsETFMarketsControls) => void;
  dispersionByDispatcher: (action: DispersionByViewActionMarketsETF) => void;
  dispersionByIntervals: 4 | 10 | 20;
  dispersionByPerformance: "3_months" | "6_months" | "12_months";
  dispersionByTrimOutliers: boolean;
  setDispersionBySort: (sorter) => void;
};

const performanceOptions = [
  {
    label: "3 Months",
    value: "3_months",
  },
  {
    label: "6 Months",
    value: "6_months",
  },
  {
    label: "12 Months",
    value: "12_months",
  },
];

const percentageOptions = [
  {
    label: "25%",
    value: 4,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "5%",
    value: 20,
  },
];

const chartTypeOptions = [
  {
    label: "Histogram",
    value: "histogram",
  },
  {
    label: "Range",
    value: "range",
  },
];

export default function DispersionByView({
  segment,
  data,
  dispatch,
  dispersionByDispatcher,
  dispersionByIntervals,
  dispersionByPerformance,
  dispersionByTrimOutliers,
  setDispersionBySort,
}: DispersionByViewProps) {
  const usage = window.App.usage;
  const [chartType, setChartType] = useState<"histogram" | "range">(
    "histogram"
  );
  const [sorter, setSorter] = useState<
    {
      dir: "desc" | "asc";
      field: string;
    }[]
  >([]);

  // Sync the sort to generate a dispersion report
  useEffect(() => {
    setDispersionBySort(sorter);
  }, [setDispersionBySort, sorter]);

  const setPerformaceTimeframe = useCallback(
    (value) => {
      dispersionByDispatcher({ type: "SET_PERFORMANCE", payload: value });
    },
    [dispersionByDispatcher]
  );

  const setInterval = useCallback(
    (value) => {
      dispersionByDispatcher({ type: "SET_INTERVALS", payload: value });
    },
    [dispersionByDispatcher]
  );

  const setTrimOutliers = useCallback(
    (value) => {
      dispersionByDispatcher({
        type: "SET_TRIM_OUTLIERS",
        payload: value,
      });
    },
    [dispersionByDispatcher]
  );

  const percentage: 25 | 10 | 5 = useMemo(() => {
    switch (dispersionByIntervals) {
      default:
      case 4:
        return 25;
      case 10:
        return 10;
      case 20:
        return 5;
    }
  }, [dispersionByIntervals]);

  const navigateDownToPeer = useCallback(
    (data) => {
      const {
        peer: { id },
      } = data;

      const { where, what, zDimension } = decodePeerId(id);

      const info = {
        action: "PEER",
        actionParam: {
          xDimension: where,
          yDimension: what,
          zDimension,
        },
        function: "MARKET_ETF",
      };

      usage.record(info);

      dispatch({
        type: "CHANGE_PEER",
        payload: { xDim: where, yDim: what },
      });
    },
    [dispatch, usage]
  );

  const onSort = useCallback((value) => {
    setSorter((prev) => {
      if (JSON.stringify(value) !== JSON.stringify(prev)) {
        return value;
      } else {
        return prev;
      }
    });
  }, []);

  const derivedData = useMemo(() => {
    return data?.dispersionBy ? Object.values(data.dispersionBy) : undefined;
  }, [data?.dispersionBy]);

  return (
    <Box
      minHeight={0}
      mt={1}
      display={"flex"}
      flex={1}
      boxShadow={3}
      p={1}
      borderRadius={2}
    >
      <Box flex={1} gap={1} display={"flex"} minWidth={0} minHeight={0}>
        <Grid item md={4} flex={1} display={"flex"}>
          <Card sx={{ flex: 1, display: "flex" }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Instruments Table */}
              {data?.dispersionBy ? (
                <>
                  {data?.peer?.info?.cardinality > 0 ? (
                    <>
                      {data?.peer?.info?.cardinality > 3 ? (
                        <DispersionByTable
                          percentage={percentage}
                          type={"ETF"}
                          segment={segment}
                          data={derivedData}
                          navigateDownPeer={navigateDownToPeer}
                          onTableSort={onSort}
                        />
                      ) : (
                        <Typography fontSize={"14px"} fontWeight={"bold"}>
                          The ETF you are lookng for has not enough constituents
                          to calculate dispersion (at least 4)
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                      Any ETF was found
                    </Typography>
                  )}
                </>
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={8} flex={1} display={"flex"}>
          {/* Dispersion data */}
          <Card sx={{ flex: 1, display: "flex" }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data?.dispersionBy ? (
                <>
                  {data?.peer?.info?.cardinality > 3 && (
                    <>
                      <Card
                        sx={{
                          marginBottom: "5px",
                          display: "flex",
                          border: "1px solid #2A7090",
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                            paddingTop: "5px",
                            paddingBottom: "10px!important",
                          }}
                        >
                          <SelectorETFMarkets
                            options={performanceOptions}
                            selectedOption={dispersionByPerformance}
                            selectOption={setPerformaceTimeframe}
                          />
                          <SelectorETFMarkets
                            selectOption={setInterval}
                            options={percentageOptions}
                            selectedOption={dispersionByIntervals}
                          />

                          <SelectorETFMarkets
                            selectOption={setChartType}
                            options={chartTypeOptions}
                            selectedOption={chartType}
                          />

                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                fontSize: "12px",
                              }}
                              control={
                                <Switch
                                  size="small"
                                  checked={dispersionByTrimOutliers}
                                  onChange={(e) =>
                                    setTrimOutliers(e.target.checked)
                                  }
                                />
                              }
                              classes={{
                                label: styles.switch__label,
                              }}
                              label="Trim Outliers"
                            />
                            <InfoTooltip
                              style={{
                                top: 5,
                                left: -12,
                              }}
                              title={""}
                              text="The outliers are securities that perform abnormally relative to the average of all stocks in the same set."
                            />
                          </div>
                        </CardContent>
                      </Card>
                      <Card>
                        <CardContent>
                          <DispersionChart
                            chartType={chartType}
                            data={data.dispersionBy}
                            interval={percentage}
                            timeframe={dispersionByPerformance as any}
                            sorter={sorter}
                          />
                        </CardContent>
                      </Card>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </Box>
  );
}
