import { useCallback, useEffect, useMemo, useState } from "react";
import { RankingUi2Api } from "../../../../../../../api/compute/RankingUi2Api";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
// import { CheckBox } from "../../../../../../../js/app/components/dijit/form/CheckBox";
// import { NumberSpinner } from "../../../../../../../js/app/components/dijit/form/NumberSpinner";
// import { RadioButton } from "../../../../../../../js/app/components/dijit/form/RadioButton";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Checkbox as ReactCheckbox,
  Typography,
} from "@mui/material";
import { useImmer, useImmerReducer } from "use-immer";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
import SelectNested from "../../../../../../../js/app/components/ui/form/SelectNested";
import MinMaxInputNumer from "./MinMaxInputNumer";
import { PredefinedRankingTemplate } from "./PredefinedRankingTemplate";
import { Strategy } from "./editorReducer";
import { State, rankingReducer, rankingState } from "./rankingReducer";

type MacroRotationHoldingRulesProps = {
  strategy: Strategy;
  dispatch: Function;
  more?: boolean;
};

type AdvancedCappingControlsStateType = {
  weightCappingSecurity: {
    weightCappedMax: number | null;
    weightCappedMin: number | null;
  };
  isWeightCappingEnabled: boolean;
  maxCashToHold: { value: number; isEnabled: boolean };
};

export default function MacroRotationHoldingRules({
  strategy,
  dispatch,
  more,
}: MacroRotationHoldingRulesProps) {
  /**
   * This method generate the correct array to pass as a key to the PredefinedRankingTemplateCLass
   */
  const generateKeyForSelectedValue = (ranking) => {
    let rankingFactor: any = {};
    let keyForSelectedValue: any = [];
    ranking.forEach((element) => {
      switch (element.property) {
        case "pr":
          if (element.function === "quantile") {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
              rankType: element.function,
              rankTypeParams: {
                n: element.functionParams.value,
                trimOutlier: false,
              },
            };
          } else {
            rankingFactor = {
              field: element.property,
              sort: element.operatorParams.value,
            };
          }
          break;
        default:
          rankingFactor = {
            field: element.property,
            sort: element.operatorParams.value,
          };
      }
      keyForSelectedValue.push(rankingFactor);
    });
    return keyForSelectedValue;
  };

  /**
   *
   * This method use the data structure of the global state that comes throw the props
   * and transpile it to the data structure of the state of this component. This is
   * necessary to syncronize the current component state with the global state
   *
   * @returns state
   */
  const mapRankingToState = useCallback(() => {
    let state: State = { keepValidPositions: true, prioritySelect: "" };

    const checkValidPosition = strategy?.ranking!.find(
      (item) => item.property === "exists"
    );

    if (checkValidPosition) {
      state.keepValidPositions = true;
    } else {
      state.keepValidPositions = false;
    }

    const prioritySelectState = strategy?.ranking!.filter(
      (item) => item.property !== "exists"
    );

    const unparsedPriorityKey =
      generateKeyForSelectedValue(prioritySelectState);

    const priorityKey = PredefinedRankingTemplate.get(
      "OPTION_LOOKUP",
      JSON.stringify(unparsedPriorityKey)
    );

    switch (priorityKey) {
      case "CLOSEST_TO_THE_MAXIMUM_NO_KEEP":
        state.prioritySelect = "CLOSEST_TO_THE_MAXIMUM";
        break;
      default:
        state.prioritySelect = priorityKey;
    }

    return state;
  }, [strategy?.ranking]);

  const [rankingRules, rankingDispatch] = useImmerReducer(
    rankingReducer,
    strategy ? mapRankingToState() : rankingState
  );

  const _strategy = useMemo(() => {
    return strategy;
  }, [strategy]);

  const _weightCappingSecurity = useMemo(() => {
    if (_strategy.allocation.weightCappingSecurity) {
      return _strategy.allocation.weightCappingSecurity;
    }
    return {
      weightCappedMin: null,
      weightCappedMax: null,
    };
  }, [_strategy.allocation.weightCappingSecurity]);

  const [advancedCappingControlsState, setAdvancedCappingControlsState] =
    useImmer<AdvancedCappingControlsStateType>({
      weightCappingSecurity: _weightCappingSecurity,
      isWeightCappingEnabled:
        _weightCappingSecurity.weightCappedMax &&
        _weightCappingSecurity.weightCappedMin
          ? true
          : false,
      maxCashToHold: { value: 0, isEnabled: false },
    });

  useEffect(
    () =>
      console.log("advancedCappingControlsState", advancedCappingControlsState),
    [advancedCappingControlsState]
  );

  const { keepValidPositions, prioritySelect } = rankingRules;
  const environment = useEnvironment();
  const envTransformation = environment.get("setup");
  const [blockValidPositions, updateBlockValidPositions] = useImmer(false);

  const isWeightCappingEnabled = useMemo(
    () => advancedCappingControlsState.isWeightCappingEnabled,
    [advancedCappingControlsState.isWeightCappingEnabled]
  );

  /**
   * Syncronize the component state with the global state
   */
  useEffect(() => {
    rankingDispatch({
      type: "SYNC_RANKING_WITH_STATE",
      payload: mapRankingToState(),
    });
    setAdvancedCappingControlsState((draft) => {
      if (
        strategy.allocation.weightInCash >= 0 &&
        strategy.allocation.weightInCash < 1
      ) {
        draft.maxCashToHold.value = strategy.allocation.weightInCash;
        draft.maxCashToHold.isEnabled = true;
      } else {
        return draft;
      }

      if (strategy.allocation.weightCappingSecurity != null) {
        draft.isWeightCappingEnabled = true;
        draft.weightCappingSecurity.weightCappedMax =
          strategy.allocation.weightCappingSecurity!.weightCappedMax;
        draft.weightCappingSecurity.weightCappedMin =
          strategy.allocation.weightCappingSecurity!.weightCappedMin;
      } else {
        return draft;
      }
    });

    const rating = strategy.selection!.find((item) => item.property === "rc")
      ?.operatorParams.value;
    const { A, B, C, D } = rating;

    if (A && B && C && D) {
      rankingDispatch({
        type: "SET_KEEP_VALID_POSITIONS",
        payload: false,
      });
      updateBlockValidPositions(true);
    } else {
      updateBlockValidPositions(false);
    }
  }, [
    dispatch,
    mapRankingToState,
    rankingDispatch,
    setAdvancedCappingControlsState,
    strategy,
    updateBlockValidPositions,
  ]);

  /**
   * Use the current state to track widgets activity state and translate the state map to the
   * Server data format. After that this format is translate to the new input object format and
   * is set in the global state by dispatching an action.
   */
  useEffect(() => {
    const serverFormatEncoder = new RankingUi2Api(envTransformation);
    let ranking: any = [];

    if (prioritySelect != null) {
      const priorities = PredefinedRankingTemplate.getLookUpForServer(
        prioritySelect,
        keepValidPositions
      );

      if (Array.isArray(priorities)) {
        ranking = ranking.concat(priorities);
      } else {
        ranking.push(priorities);
      }

      dispatch({
        type: "SET_RANKING",
        payload: serverFormatEncoder.decode(ranking),
      });
    }
  }, [
    dispatch,
    envTransformation,
    keepValidPositions,
    prioritySelect,
    rankingRules,
  ]);

  useEffect(() => {
    const { isWeightCappingEnabled, weightCappingSecurity, maxCashToHold } =
      advancedCappingControlsState;

    if (isWeightCappingEnabled) {
      dispatch({
        type: "SET_WEIGHT_CAPPING_SECURITY",
        payload: weightCappingSecurity,
      });
    } else {
      dispatch({ type: "SET_WEIGHT_CAPPING_SECURITY", payload: null });
    }

    if (maxCashToHold.isEnabled) {
      dispatch({
        type: "SET_WEIGHT_IN_CASH",
        payload: maxCashToHold.value,
      });
    } else {
      dispatch({
        type: "SET_WEIGHT_IN_CASH",
        payload: 1,
      });
    }
  }, [advancedCappingControlsState, dispatch]);

  const holdings = strategy?.strategy?.holdings ?? 30;
  const rebalanceFrequency = strategy?.strategy?.rebalance ?? "20_DAYS";

  const priorities: any[] = [];

  const strategyRanking = strategy?.ranking ?? [];
  strategyRanking.forEach((element) => {
    priorities.push({
      field: element.property,
      sort: element.operatorParams.value,
    });
  });

  const [minMaxWeightCappingSecurityKey, setMinMaxWeightCappingSecurityKey] =
    useState(Date.now());

  const [maxCashToHoldKey, setMaxCashToHoldKey] = useState(Date.now());

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">4</span> Holding rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>Securities to hold</Typography>
            <MinMaxInputNumer
              value={holdings}
              min={1}
              max={1000}
              stateSetter={(value) => {
                dispatch({
                  type: "SET_SECURITY_TO_HOLD",
                  payload: value,
                });
              }}
            />
          </Box>
        </li>
        <li className="form__field">
          <FormControl>
            <Typography>Rebalance frequency</Typography>
            <RadioGroup
              row
              value={rebalanceFrequency}
              onChange={(e) => {
                const value = e.target.value;
                dispatch({
                  type: "SET_REBALANCE_FREQUENCY",
                  payload: value,
                  event: true,
                });
              }}
            >
              <FormControlLabel
                value={"20_DAYS"}
                control={<Radio />}
                label={<Typography>Monthly</Typography>}
              />
              <FormControlLabel
                value={"60_DAYS"}
                control={<Radio />}
                label={<Typography>Quarterly</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </li>
        <li className="form__field">
          <label>Give priority to</label>{" "}
          <SelectNested globalState={strategy} dispatch={rankingDispatch} />
        </li>
        {prioritySelect !== "CLOSEST_TO_THE_MAXIMUM" ? (
          <li>
            <FormGroup>
              <FormControlLabel
                control={
                  <ReactCheckbox
                    onChange={(e) => {
                      const isKeepValidPositions = e.target.checked;
                      rankingDispatch({
                        type: "SET_KEEP_VALID_POSITIONS",
                        payload: isKeepValidPositions,
                      });
                    }}
                    checked={keepValidPositions}
                    disabled={blockValidPositions}
                  />
                }
                label={<Typography>Keep valid positions</Typography>}
              />
            </FormGroup>
          </li>
        ) : null}
        <CustomFieldCollapser
          isOpen={more ?? false}
          setIsOpen={(e) =>
            dispatch({ type: "MORE_HOLDING_RULES", payload: e })
          }
        >
          <li>
            <div className="form__more">
              <div className="form__field">
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <ReactCheckbox
                          onChange={(e) => {
                            const value = e.target.checked;
                            if (value) {
                              setAdvancedCappingControlsState((draft) => {
                                draft.weightCappingSecurity.weightCappedMax =
                                  20 / 100;
                                draft.weightCappingSecurity.weightCappedMin =
                                  1 / 100;
                              });
                            } else {
                              setAdvancedCappingControlsState((draft) => {
                                draft.weightCappingSecurity.weightCappedMax =
                                  null;
                                draft.weightCappingSecurity.weightCappedMin =
                                  null;
                              });
                            }
                            setAdvancedCappingControlsState((draft) => {
                              draft.isWeightCappingEnabled = value;
                            });
                            setMinMaxWeightCappingSecurityKey(Date.now());
                          }}
                          checked={isWeightCappingEnabled}
                        />
                      }
                      label={<Typography>% weight capped</Typography>}
                    />
                  </FormGroup>
                  <Box
                    key={minMaxWeightCappingSecurityKey}
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                  >
                    <Typography>Min </Typography>
                    <MinMaxInputNumer
                      value={
                        advancedCappingControlsState.weightCappingSecurity
                          .weightCappedMin! * 100
                      }
                      disabled={!isWeightCappingEnabled}
                      min={1}
                      max={100}
                      stateSetter={(value) => {
                        setAdvancedCappingControlsState((draft) => {
                          draft.weightCappingSecurity.weightCappedMin =
                            value / 100;
                        });
                      }}
                    />
                    <Typography>Max</Typography>
                    <MinMaxInputNumer
                      value={
                        advancedCappingControlsState.weightCappingSecurity
                          .weightCappedMax! * 100
                      }
                      disabled={!isWeightCappingEnabled}
                      min={1}
                      max={100}
                      stateSetter={(value) => {
                        setAdvancedCappingControlsState((draft) => {
                          draft.weightCappingSecurity.weightCappedMax =
                            value / 100;
                        });
                      }}
                    />
                  </Box>
                </Box>
              </div>
              <div className="form__field">
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ m: 0 }}
                      control={
                        <ReactCheckbox
                          onChange={(e) => {
                            const maxCashToHoldIsEnabled = e.target.checked;
                            setAdvancedCappingControlsState((draft) => {
                              if (maxCashToHoldIsEnabled) {
                                draft.maxCashToHold.isEnabled =
                                  maxCashToHoldIsEnabled;
                              } else {
                                draft.maxCashToHold.isEnabled =
                                  maxCashToHoldIsEnabled;
                                draft.maxCashToHold.value = 0;
                              }
                            });
                            setMaxCashToHoldKey(Date.now());
                          }}
                          checked={
                            advancedCappingControlsState.maxCashToHold.isEnabled
                          }
                        />
                      }
                      label={<Typography>Maximum cash to hold</Typography>}
                    />
                  </FormGroup>
                  <MinMaxInputNumer
                    key={maxCashToHoldKey}
                    disabled={
                      !advancedCappingControlsState.maxCashToHold.isEnabled
                    }
                    value={
                      (1 - advancedCappingControlsState.maxCashToHold.value) *
                      100
                    }
                    min={1}
                    max={100}
                    stateSetter={(value) => {
                      setAdvancedCappingControlsState((draft) => {
                        if (
                          advancedCappingControlsState.maxCashToHold.isEnabled
                        ) {
                          draft.maxCashToHold.value = 1 - value / 100;
                        }
                      });
                    }}
                  />
                </Box>
              </div>
            </div>
          </li>
        </CustomFieldCollapser>
      </ul>
    </fieldset>
  );
}
