import { Card, CardContent, Checkbox, Skeleton, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./InstrumentsBoxList.module.scss";
import { Entity } from "./../../../../js/app/pages/strategies/builder/editors/Advanced/Result/tabs/Holdings/utils/Entity";
import { useFormatter } from "../../../../hooks/useFormatter";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { Instruments } from "../../../../api/compute/Instruments";
import { Utils } from "../../../../api/compute/Utils";
import { ChartSerie } from "../../../../js/app/utils";
import { TDate } from "../../../../trendrating/date/TDate";
import { Chart } from "../../../Chart";
import { Properties } from "../../../../api/Properties";
import SecurityChartModal from "../../../SecurityChartModal/SecurityChartModal";
import { widgetsConfiguration } from "../../../../js/app/widgets/widgetsConfiguration";

type InstrumentsBoxListsProps = {
  data: any[];
  selectionHandler: (event, security) => any;
  selectedRows: any[];
  showCheckbox: boolean;
};

type SecurityBoxProps = {
  security: any;
  selectionHandler: (event, security) => any;
  selectedRowsMap: any;
  selectable: boolean;
};

type ExpiredChartProps = { security: any };

const flag = (milliseconds, rate) => {
  var entity = new Entity();
  var rateInfo = entity.rateScale[rate];
  var flag: any = [];
  switch (rate) {
    case 2:
    case 1:
    case -1:
    case -2:
      flag.push({
        x: milliseconds,
        title: rateInfo.label,
        text: rateInfo.label,
        fillColor: rateInfo.color,
      });
      break;
    // no default
  }
  return flag;
};

export function InstrumentsBoxLists({
  data,
  selectionHandler,
  selectedRows,
  showCheckbox,
}: InstrumentsBoxListsProps) {
  const fields = useMemo(
    () => ["name", "ticker", "rc", "dr", "type", "pr", "prr"],
    []
  );
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const [securities, setSecurities] = useState([]);

  const selectedRowsMap = useMemo(() => {
    return selectedRows.reduce(
      (prev, current) => ({ ...prev, [current.symbol]: true }),
      {}
    );
  }, [selectedRows]);

  const getData = useCallback(async () => {
    const symbols = data.map((item) => item.symbol);
    const properties = fields.map((p) => ({ date: null, property: p }));

    try {
      const response = await instrumentsAPI.fetch({
        symbols,
        properties,
        type: "security",
      });
      setSecurities(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [data, fields, instrumentsAPI]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ul className={styles.mainList}>
      {securities.map((security) => {
        return (
          <li key={uuidv4()} className={styles.listItem}>
            <SecurityBox
              selectable={showCheckbox}
              selectedRowsMap={selectedRowsMap}
              security={security}
              selectionHandler={selectionHandler}
            />
          </li>
        );
      })}
    </ul>
  );
}

const SecurityBox = ({
  security,
  selectionHandler,
  selectedRowsMap,
  selectable,
}: SecurityBoxProps) => {
  const [chart, setChart] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [securityForModal, setSecurityForModal] = useState(security);
  const entity = useMemo(() => new Entity(), []);
  const formatter = useFormatter();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const label = useMemo(
    () => new Properties({ properties: appSetup["properties"] }),
    [appSetup]
  );
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);

  const name = useMemo(() => security.name, [security]);
  const ticker = useMemo(() => security.ticker, [security.ticker]);

  const rate = useMemo(
    () => entity.get(security.rc, "RATE"),
    [entity, security.rc]
  );

  const datePrec = useMemo(
    () =>
      formatter.html(
        "dr",
        "rated_on_iso",
        security["dr"],
        null,
        security["type"]
      ),
    [formatter, security]
  );

  const pr = useMemo(
    () =>
      formatter.html(
        "pr",
        "performance_since_rated",
        security["pr"],
        null,
        security["type"]
      ),
    [formatter, security]
  );
  const labelDatePrec = useMemo(
    () => label.get("dr", 0, security["type"]),
    [label, security]
  );

  const getChart = useCallback(async () => {
    const data = await instrumentsAPI.chart({ symbol: security.symbol });
    setChart(data);
  }, [instrumentsAPI, security.symbol]);

  const info = useMemo(() => {
    let info =
      labelDatePrec + ": " + datePrec + " | Since " + rate["label"] + ": " + pr;

    if (
      security.prr &&
      ((security.rrr > 0 && security.rc > 0) ||
        (security.rrr < 0 && security.rc < 0))
    ) {
      let rate = entity.get(security.rrr, "RATE");
      const prr = formatter.html(
        "prr",
        "performance_previous_rate",
        security["prr"],
        null,
        security["type"]
      );
      info = info + " | Since " + rate["label"] + ": " + prr;
    }

    return info;
  }, [datePrec, entity, formatter, labelDatePrec, pr, rate, security]);

  useEffect(() => {
    getChart();
  }, [getChart]);

  const getSecurityProperiesByType = useCallback((response) => {
    const securityType =
      response?.["data"]?.[0]?.["type"]?.toLowerCase() ?? null;
    let properties;

    const config = widgetsConfiguration["widgets/security/Tooltip"];

    if (securityType) {
      switch (securityType) {
        case "etf":
        case "stock": {
          properties = config?.["properties_" + securityType];
          break;
        }
        default: {
          properties = config?.["properties"];
        }
      }

      return properties;
    }

    return null;
  }, []);

  const getInstrumentData = useCallback(async () => {
    const symbol = security?.symbol;

    if (!symbol) {
      return;
    }

    const properties: any = {
      properties: [
        {
          date: null,
          property: "type",
        },
      ],
      symbols: [symbol],
      type: "security",
    };

    const response = await instrumentsAPI.fetch(properties);
    const propertiesByType = getSecurityProperiesByType(response);

    return await instrumentsAPI.fetch({
      properties: propertiesByType,
      symbols: [symbol],
      type: "security",
    });
  }, [getSecurityProperiesByType, instrumentsAPI, security?.symbol]);

  const openModal = useCallback(async () => {
    const instrument = await getInstrumentData();

    setSecurityForModal(instrument?.data?.[0]);
    if (instrument) {
      setShowModal(true);
    }
  }, [getInstrumentData]);

  return (
    // HasCheckbox props has to be implemented and is useful for operations like add to new basket or portfolio
    <Card onClick={openModal} sx={{ flex: 1 }}>
      <CardContent>
        {selectable && (
          <Checkbox
            checked={selectedRowsMap?.[security.symbol] ?? false}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              event.stopPropagation();
              selectionHandler(event, security);
            }}
          />
        )}
        <SecurityChartModal
          security={securityForModal}
          showModal={showModal}
          environment={environment}
          onClose={() => {
            setShowModal(false);
          }}
        />
        <div className="viewer-tile__tile-title-container viewer-tile__tile-title-container--no-margin">
          <p className={"viewer-tile__tile-rate"}>
            <strong className={rate["class"]}>{rate["label"]}</strong>
          </p>
          <div className="viewer-tile__tile-title">
            <h1>{name + " (" + ticker + ")"}</h1>

            <p dangerouslySetInnerHTML={{ __html: info }}></p>
          </div>
        </div>
        <div className="viewer-tile__chart-container">
          {security.type === "ExpiredStock" ? (
            <ExpiredChart security={security} />
          ) : (
            <div
              className="viewer-tile__chart"
              style={{ backgroundImage: `url(${chart})` }}
            ></div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const ExpiredChart = ({ security }: ExpiredChartProps) => {
  const [chartParams, setChartParams] = useState<any>();
  const [chartConfig, setChartConfig] = useState<any>();

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(appSetup), [appSetup]);
  const utilityAPI = useMemo(() => new Utils(appSetup), [appSetup]);
  const chartSerie = useMemo(() => new ChartSerie(), []);

  const getChartParams = useCallback(async () => {
    try {
      const historyResponse = await instrumentsAPI.historyOf(security);
      const todayResponse = await utilityAPI.today();

      const today = todayResponse["today"];
      const history = historyResponse;

      const cutFromDay = today - 252; // Cut from last year

      const cuttedHistory: any = [];
      for (let i = 0; i < history.length; i++) {
        if (history[i].d >= cutFromDay) {
          cuttedHistory.push(history[i]);
        }
      }

      const serieList = chartSerie.trendrating2HighchartSerie(cuttedHistory);

      var params = {
        security,
        history: serieList,
        max: TDate.daysToMilliseconds(today),
        height: 240,
        isLogarithmic: false,
      };

      setChartParams(params);
    } catch (error) {
      console.error(error);
    }
  }, [chartSerie, instrumentsAPI, security, utilityAPI]);

  const buildChart = useCallback((params) => {
    const history = params.history;

    if (history) {
      // calculate signal flags
      const flagdata: any = [];
      let date: any = null;
      for (let i = history.length - 1; i >= 0; i--) {
        if (i >= 1 && history[i]["r"] !== history[i - 1]["r"]) {
          date = history[i]["x"];
          let flagdataPrec = flag(date, history[i]["r"]);
          flagdata.push(flagdataPrec[0]);
        }
      }
      flagdata.reverse();
      // re-arrange axis to show last signal correctly
      let maxT = params.max || history[history.length - 1][0];
      if (flagdata[0] !== undefined) {
        var maxF = flagdata[0].x;

        var last5Days = 5 * 24 * 3600 * 1000;
        if (maxF > maxT - last5Days) {
          maxT = maxT + last5Days;
        }
      }

      let series: any = [];
      let sPrice = {
        color: "#2F7ED8",
        type: "line",
        id: "history",
        name: "Expired Stock",
        data: history,
        yAxis: 0,
        index: 0,
        lineWidth: 1,
        enableMouseTracking: false,
        tooltip: {
          enabled: false,
        },
        turboThreshold: 0,
      };
      series.push(sPrice);

      var flags = {
        type: "flags",
        name: "Flags on series",
        data: flagdata,
        yAxis: 0,
        index: 1,
        color: "#d3d3d3",
        onSeries: "history",
        shape: "circlepin",
        width: 14,
        style: { color: "white" },
        states: { hover: { fillColor: "#395C84", text: "signal" } },
        turboThreshold: 0,
      };
      series.push(flags);

      const config = {
        chart: {
          animation: false,
          borderWidth: 0,
          height: params.height ? params.height : null,
          width: params.width ? params.width : null,
          marginTop: 0,
          renderTo: params.node,
          spacingTop: 0,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        interactiveMode: {
          isEnabled: false,
          plugins: [],
        },
        scrollbar: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        navigator: { enabled: false },
        plotOptions: {
          line: {
            animation: false,
            shadow: false,
          },
          series: {
            connectNulls: true,
          },
        },
        subtitle: {
          text: "Expired Stock",
        },
        tooltip: {
          shared: false,
        },
        xAxis: {
          type: "datetime",
          max: maxT,
          ordinal: false,
          plotLines: [
            {
              color: "#999",
              label: {
                style: {
                  color: "#666",
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: "9px",
                },
                text: "expired",
                verticalAlign: "middle",
                x: 4,
              },
              value: history[history.length - 1][0],
              width: 1,
            },
          ],
        },
        yAxis: {
          lineWidth: 1,
          gridLineWidth: 0,
          type: params.isLogarithmic ? "logarithmic" : "linear",
          opposite: false,
        },
        series: series,
      };

      setChartConfig(config);
    }
  }, []);

  useEffect(() => {
    getChartParams();
  }, [getChartParams]);

  useEffect(() => {
    if (chartParams) {
      buildChart(chartParams);
    }
  }, [buildChart, chartParams]);

  return chartConfig ? <Chart options={chartConfig} /> : <SkeletonLoader />;
};

const SkeletonLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
    </Stack>
  );
};
