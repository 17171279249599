import { Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  reactFormatter,
  TrendratingTable,
} from "../../../../../../../../../components/table/TrendratingTable";
import {
  formatCellBorderByTCR,
  turnSegmentInLabel,
} from "../MarketsETFTableTCR/MarketsETFTableTCR";
import styles from "./InstrumentTableForAnalytics.module.scss";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { ActionsETFMarketsControls } from "../../../../AnalysisMarketsETF";
import { encodePeerId } from "../../../../../../../../../api/utils";
import { CellComponent, ColumnDefinition } from "tabulator-tables";

type InstrumentsTableForAnalyticsProps = {
  data: any;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  nameFormatter: Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  paramsUpdate?: Function;
  resetContextualParams?: Function;
  type: "Stock" | "ETF";
};

type CustomCardinalityCellProps = {
  cell?: CellComponent;
  customCellEventHandler?: Function;
};

export default function InstrumentsTableForAnalytics({
  data,
  segment,
  nameFormatter,
  dispatch,
  paramsUpdate,
  resetContextualParams,
  type,
}: InstrumentsTableForAnalyticsProps) {
  const [tableData, setTableData] = useState([]);
  const usage = window.App.usage;

  const updateViewParams = useCallback(
    (e, cell: CellComponent) => {
      const data = cell.getData();
      const { what, where, type, size } = data as any;
      const peerId = encodePeerId({
        what,
        where,
        type,
        zDimension: size,
      });

      if (paramsUpdate) {
        paramsUpdate(peerId);
      }
    },
    [paramsUpdate]
  );

  const navigatePeerDown = useCallback(
    (e, rowData) => {
      e.stopPropagation();
      const where = rowData.where;
      const what = rowData.what;
      const size = rowData.size;

      const info = {
        action: "PEER",
        actionParam: {
          xDimension: where,
          yDimension: what,
          zDimension: size,
        },
        function: "MARKET_ETF",
      };

      usage.record(info);

      if (resetContextualParams) {
        resetContextualParams();
      }

      dispatch({
        type: "CHANGE_PEER",
        payload: { xDim: where, yDim: what },
      });
    },
    [dispatch, resetContextualParams, usage]
  );

  const cols: ColumnDefinition[] = useMemo(
    () => [
      {
        field: "name",
        title: `${turnSegmentInLabel(segment, type)}`,
        formatter: (cell) => {
          const formattedName = nameFormatter("name")(cell, segment);
          // timeframe is fixed to today
          const timeframe = 0;
          return formatCellBorderByTCR(
            cell,
            formattedName,
            timeframe,
            segment,
            type
          );
        },
        sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
          const aName = nameFormatter("name")(aRow, segment);
          const bName = nameFormatter("name")(bRow, segment);

          if (a === "__ROOT__" || aName === "Any") {
            return 1;
          }

          if (b === "__ROOT__" || bName === "Any") {
            return 1;
          }

          if (aName > bName) {
            return -1;
          } else if (aName < bName) {
            return 1;
          }

          return 0;
        },
        widthGrow: 2,
        cellClick: updateViewParams,
      },
      {
        field: "cardinality",
        title: type === "ETF" ? "ETFs" : "Stocks",
        headerHozAlign: "center",
        formatter: reactFormatter(
          <CustomCardinalityCell customCellEventHandler={navigatePeerDown} />
        ),
        sorter: (a, b, aRow, bRow) => {
          const aCard = aRow.getData().info.cardinality;
          const bCard = bRow.getData().info.cardinality;

          if (aCard > bCard) {
            return 1;
          } else if (bCard > aCard) {
            return -1;
          }

          return 0;
        },
        widthGrow: 1,
      },
    ],
    [nameFormatter, navigatePeerDown, segment, type, updateViewParams]
  );

  useEffect(() => {
    if (data != null) {
      // Assemble data that comings from response and formatting it to fit the table format.
      // the data object contains 2 keys one is for the peer overall info and the other contains
      // the peerChildren.
      // We need to put the overall peer's da
      const dataInTableFormat = Object.entries<any>(data).reduce(
        (prev: any, [key, value]) => {
          if (key === "peer") {
            if (type === "Stock") {
              value["name"] = "__ROOT__";
            }
            prev.push(value);
          } else if (key === "children") {
            prev = prev.concat(value?.data);
          }

          return prev;
        },
        []
      );

      setTableData(dataInTableFormat);
    }
  }, [data, type]);

  return (
    <Card sx={{ display: "flex", flex: 1, minHeight: 0 }}>
      <CardContent sx={{ width: "100%", minHeight: 0 }}>
        <TrendratingTable
          disableDefaultRowClick
          correction={50}
          options={{ ajaxSorting: false }}
          columns={cols}
          data={tableData}
          sorting={{ field: "name", direction: "desc" }}
        />
      </CardContent>
    </Card>
  );
}

const CustomCardinalityCell = ({
  cell,
  customCellEventHandler,
}: CustomCardinalityCellProps) => {
  const data = useMemo(() => cell?.getData(), [cell]);
  const cardinality = data?.["info"]?.["cardinality"];
  const [showIcon, setShowIcon] = useState(false);

  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      <div className={styles.custom__cell}>
        <div className={styles.custom__cell__number__column}>{cardinality}</div>
      </div>

      <div className={styles.custom__cell__icon}>
        {showIcon && (
          <div
            className={styles.custom__cell__icon__background}
            onClick={(e) =>
              customCellEventHandler && customCellEventHandler(e, data)
            }
          >
            <KeyboardDoubleArrowDownIcon
              className={styles.custom__cell__icon__background__icon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
