import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
type Props = {
  onChangePasswordHandler: Function;
};

const Password = forwardRef(({ onChangePasswordHandler }: Props, ref) => {
  const [firstField, setFirstField] = useState("");
  const [secondField, setSecondField] = useState("");
  const [passwordMatch, setPasswordMatch] = useState<null | boolean>(null);
  useEffect(() => {
    if (
      firstField !== "" &&
      firstField != null &&
      firstField.length >= 8 &&
      secondField !== "" &&
      secondField != null &&
      secondField.length >= 8
    ) {
      setPasswordMatch(firstField === secondField);
    } else {
      setPasswordMatch(false);
    }
  }, [firstField, secondField]);

  useImperativeHandle(
    ref,
    () => {
      return {
        resetTextFields: () => {
          setFirstField("");
          setSecondField("");
        },
      };
    },
    []
  );

  const [showPassword, setShowPassword] = useState(false);
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent
        sx={{
          pb: "16px !important",
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">
          <strong> Reset password</strong>
        </Typography>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography>New password</Typography>
            <OutlinedInput
              autoComplete="new-password"
              size="small"
              value={firstField}
              onChange={(e) => setFirstField(e.target.value)}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography>Retype new password</Typography>
            <OutlinedInput
              autoComplete="new-password"
              size="small"
              value={secondField}
              onChange={(e) => setSecondField(e.target.value)}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Button
            disabled={!passwordMatch}
            size="small"
            onClick={() => onChangePasswordHandler(firstField)}
          >
            Change
          </Button>
          <Typography>Password must be at least 8 characters</Typography>
        </Box>
      </CardContent>
    </Card>
  );
});

export default Password;
