import {
  Box,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Lists } from "../../../../../api/compute/Lists";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { v4 as uuidv4 } from "uuid";
import styles from "./WidgetsStyels.module.scss";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { handleTitle } from "../../../../../Utility/DocumentTitleHanlder";

type ListSelectProps = { changeList: (listId) => void; currentListId?: number };

export function ListSelect({ currentListId, changeList }: ListSelectProps) {
  const [currentList, setCurrentList] = useState<any>();
  const [loadingLists, setLoadingLists] = useState(false);
  const [collection, setCollection] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const open = Boolean(anchorEl);

  const firstMenuAnchorRef = useRef<any>(null);

  const listName = useMemo(() => {
    return currentList?.name ?? "";
  }, [currentList?.name]);

  useEffect(() => {
    if (currentList) {
      handleTitle({
        type: currentList?.type,
        name: currentList?.name ?? "",
      });
    }
  }, [currentList]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setCollection([]);
    setDropdownOpen(false);
  }, []);

  const environment = useEnvironment();
  const userId = useMemo(
    () => environment.get("setup")["account"]["user"]["id"],
    [environment]
  );

  const apiLists = useMemo(() => {
    return new Lists(environment.get("setup"));
  }, [environment]);

  const loadLists = useCallback(
    async (type: "BASKET" | "PORTFOLIO") => {
      setLoadingLists(true);

      try {
        const userCollectionIds = await apiLists._getFilter(type);
        const subscribed = await apiLists._getSubscribed();

        const listIds = [
          ...userCollectionIds,
          ...subscribed.map((pub) => pub.id),
        ];
        const response = await apiLists.portfolioFetch(listIds, [
          "name",
          "ownerId",
          "type",
        ]);

        const collection: any = [];

        for (const element of response) {
          if (element.type === type) {
            collection.push({
              name: element.name,
              id: element.id,
              isSubscribed: element.ownerId !== userId,
            });
          }
        }

        collection.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );

        setCollection(collection);
        setLoadingLists(false);
      } catch (error) {
        console.log(error);
        setLoadingLists(false);
      }
    },
    [apiLists, userId]
  );

  const loadBaskets = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      loadLists("BASKET");
    },
    [loadLists]
  );

  const loadPortfolios = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);

      loadLists("PORTFOLIO");
    },
    [loadLists]
  );

  const loadListName = useCallback(async () => {
    if (currentListId) {
      const response = await apiLists.portfolioFetch(
        [currentListId],
        ["name", "ownerId", "type"]
      );

      setCurrentList(response?.[0]);
    }
  }, [apiLists, currentListId]);

  const onSelectList = useCallback(
    (event, id) => {
      event.stopPropagation();
      changeList(id);
      handleClose();
    },
    [changeList, handleClose]
  );

  const openDropdown = useCallback((event) => {
    setDropdownOpen(true);
  }, []);

  useEffect(() => {
    loadListName();
  }, [loadListName]);

  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      {currentList != null && (
        <Typography variant="subtitle1">
          {listName}&nbsp;
          {userId !== currentList?.ownerId && (
            <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
          )}
        </Typography>
      )}
      <Box
        className={styles.fakeSelect}
        onClick={openDropdown}
        ref={firstMenuAnchorRef}
      >
        <Typography>Load</Typography>
        <ArrowDropDownOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
      </Box>

      <Menu
        sx={{ minWidth: "5em" }}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={firstMenuAnchorRef.current}
        open={dropdownOpen}
        onClose={handleClose}
      >
        <MenuItem disabled sx={{ display: "none" }}>
          Load
        </MenuItem>
        <MenuItem onClick={loadBaskets}>
          <Typography>Basket</Typography>
        </MenuItem>
        <MenuItem onClick={loadPortfolios}>
          <Typography>Portfolios</Typography>
        </MenuItem>
        <Menu
          sx={{ minWidth: "5em" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {loadingLists ? (
            <ListLoader />
          ) : (
            <>
              {collection.map((item) => (
                <MenuItem
                  key={uuidv4()}
                  onClick={(event) => onSelectList(event, item.id)}
                >
                  {item.isSubscribed && (
                    <span style={{ marginRight: "5px" }}>
                      <span className="sharedObjectIndicator"></span>
                    </span>
                  )}
                  <Typography>{item.name}</Typography>
                </MenuItem>
              ))}
            </>
          )}
        </Menu>
      </Menu>
    </Box>
  );
}

const ListLoader = () => {
  return (
    <Stack spacing={1} padding={1}>
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
      <Skeleton variant="rectangular" width={"100%"} height={10} />
    </Stack>
  );
};
