import { AppEnvironment } from "../../types/Defaults";
import { endpoints } from "../endpoints";
import { Preferences } from "./Preferences";

export class PageStatus extends Preferences {
  private preferenceStatus: any;
  private userId?: number;
  private preferenceId?: number;

  constructor(userId: number | undefined, environment: AppEnvironment) {
    super(environment);

    this.userId = userId;
    this.preferenceStatus = {};
    this.preferenceId = undefined;

    this.load();
  }

  private load() {
    this.preferenceStatus = {};
    this.preferenceId = undefined;
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url = endPointRoot + endpoints.storedObjects.select;
    const request = new XMLHttpRequest();
    request.open("POST", url, false);
    request.setRequestHeader("Content-Type", "application/json");

    request.send(
      JSON.stringify({
        searches: [
          {
            filters: [
              { dimension: "type", segments: ["PAGE_STATUS"] },
              { dimension: "ownerId", segments: [this.userId] },
            ],
          },
        ],
      })
    );

    if (request.status === 200) {
      const resp = JSON.parse(request.responseText);
      this.preferenceId = resp?.data?.ids?.[0];

      if (this.preferenceId != null) {
        url = endPointRoot + `/user/preferences/get/id?id=${this.preferenceId}`;

        request.open("GET", url, false);
        request.setRequestHeader("Content-Type", "application/json");
        request.send(null);

        if (request.status === 200) {
          const object = this._get(JSON.parse(request.responseText));

          if (object) {
            this.preferenceStatus = object;
          }
        }
      }
    }
  }

  async getObject(id?: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url: string = endPointRoot + endpoints.storedObjects.get;

    let params: any = {
      ownerId: this.userId,
      type: "PAGE_STATUS",
    };

    if (id != null) {
      url = endPointRoot + endpoints.storedObjects.getById;
      params = {
        id: id,
      };
    }

    const response = await this.prepareGet(url, params, null);
    return this._get(response);
  }

  async patch(patternArr, value) {
    this.load();

    if (!("data" in this.preferenceStatus)) {
      this.preferenceStatus = { data: {} };
    }

    let pointer = this.preferenceStatus.data;

    let key;
    let i;

    // If preference exists update it otherwise create it
    for (i = 0; i < patternArr.length - 1; i++) {
      key = patternArr[i];

      if (undefined === pointer[key] || typeof pointer[key] !== "object") {
        pointer[key] = {};
      }

      pointer = pointer[key];
    }

    pointer[patternArr[i]] = value;

    if (this.preferenceId != null) {
      await this.update(this.preferenceStatus, "PAGE_STATUS");
    } else {
      await this.createStatus(this.preferenceStatus.data);
    }
  }

  async get(patternArr: any) {
    if (!this.preferenceStatus) {
      return undefined;
    }

    let key;

    if (!("data" in this.preferenceStatus)) {
      this.preferenceStatus = { data: {} };
    }

    let pointer = this.preferenceStatus?.data ?? {};

    for (let i = 0; i < patternArr.length; i++) {
      key = patternArr[i];

      if (undefined !== pointer[key]) {
        pointer = pointer[key];
      } else {
        pointer = undefined;

        break;
      }
    }

    if (pointer) {
      return JSON.parse(JSON.stringify(pointer));
    } else {
      return undefined;
    }
  }

  async createStatus(statusObject) {
    const payload = {
      name: "__PAGE__STATUS",
      data: statusObject,
    };

    return await this.create(payload, "PAGE_STATUS");
  }
}
