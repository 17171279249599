export type HoldingsType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    sortBy: {
      property: string;
      descending: boolean;
    };
    top: {
      content: number;
      isEnabled: boolean;
    };
  };
  presentation: {
    columns: {
      label: string;
      property: string;
    }[];
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_headline_CONTENT"
    | "SET_CONTENT_headline_ISENABLED"
    | "SET_CONTENT_sortBy"
    | "SET_CONTENT_top_CONTENT"
    | "SET_CONTENT_top_ISENABLED"
    | "SET_PRESENTATION_columns";
  payload: any;
};

export const holdingsReducer = (draft: HoldingsType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_headline_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_headline_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_sortBy":
      draft.content.sortBy = action.payload;
      break;
  
    case "SET_CONTENT_top_CONTENT":
      draft.content.top.content = action.payload;
      break;
    case "SET_CONTENT_top_ISENABLED":
      draft.content.top.isEnabled = action.payload;
      break;
    case "SET_PRESENTATION_columns":
      if (
        JSON.stringify(action.payload) !==
        JSON.stringify(draft.presentation.columns)
      ) {
        draft.presentation.columns = action.payload;
      }
      break;

    default:
      break;
  }
};
