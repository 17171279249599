import { createContext, Dispatch, ReactNode, useState } from "react";
import { Environment } from "./Environment";

type AppContextProviderProps = {
    children: ReactNode;
    environment?: Environment;
};

export const AppContext = createContext<
    [boolean, Dispatch<boolean>] | undefined
>(undefined);

type AppConfiguration = {
    environment: Environment;
    setEnvironment: Function;
};
export const AppEnvironmentContext = createContext<
    AppConfiguration | undefined
>(undefined);

export const AppContextProvider = ({
    children,
    environment = new Environment(),
}: AppContextProviderProps) => {
    const [isReady, setReady] = useState(false);
    const [value, setValue] = useState(environment);

    const updateEnvironment = (environment: Environment) => {
        setValue(environment);
        setReady(true);
    };

    return (
        <AppContext.Provider value={[isReady, setReady]}>
            <AppEnvironmentContext.Provider
                value={{
                    environment: value,
                    setEnvironment: updateEnvironment,
                }}
            >
                {children}
            </AppEnvironmentContext.Provider>
        </AppContext.Provider>
    );
};
