import { useEffect, useState } from "react";
import InputNumberPercentage from "../../../../InputNumberPercentage/InputNumberPercentage";

type OptionNumberPercentageProps = {
  item: any;
  setOuterState: (val) => void;
  initialValue?: number;
};

export default function OptionNumberPercentage({
  item,
  setOuterState,
  initialValue,
}: OptionNumberPercentageProps) {
  const [value, setValue] = useState<number | null>(0);

  useEffect(() => {
    const updatedState = {
      augmentation: {
        value,
      },
      subject: item["value"]["subject"],
    };

    setOuterState(updatedState);
  }, [value, item, setOuterState]);

  return (
    <div className="tFormSelect-dialogContent">
      <label className="tFormSelect-dialogContent-label" data-dojo-attach-point="nodeLabel"></label>
      <InputNumberPercentage initVal={initialValue} setOuterState={setValue} />
    </div>
  );
}
