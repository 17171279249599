import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import ThemeButton from "./widgets/ThemeButton";

type Props = { initValue: "theme1" | "theme2"; onChangeTheme: Function };

export default function Theme({ initValue, onChangeTheme }: Props) {
  const init = useMemo(() => {
    if (initValue) {
      return initValue;
    }
    return "theme1";
  }, [initValue]);

  const radioHandler = useCallback(
    (e) => {
      onChangeTheme(e.target.value);
    },
    [onChangeTheme]
  );
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ p:1, pb:"8px !important" }}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="tr_subtitle_blue">Layout:</Typography>
          <Box display={"flex"} gap={2}>
            <RadioGroup
              onChange={radioHandler}
              value={init}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={"theme1"}
                label={
                  <ThemeButton
                    imgURL={
                      "/js/trendrating-report/resources/images/page_theme1.png"
                    }
                    title={"Blue & gray"}
                  />
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={"theme2"}
                label={
                  <ThemeButton
                    imgURL={
                      "/js/trendrating-report/resources/images/page_theme2.png"
                    }
                    title={"Black & white"}
                  />
                }
              />
            </RadioGroup>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
