/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/formatter/Strategy
 * @summary Format strategy parameters
 *
 */

import { getTaxonById } from "../../../api/compute/Taxon";
import { AppEnvironment } from "../../../types/Defaults";
import { RankingSelection } from "./RankingSelection";
import { StrategyOptions } from "./StrategyOptions";

export class Strategy {
  environment: AppEnvironment;
  format: AppEnvironment["formatter"];
  formatRankingSelection: any = null;
  formatType = "HTML";
  optionsRanking: any = null;
  optionsSelection: any = null;
  optionsSmartBeta: any = null;
  properties = null;

  taxonomyStock: any;
  taxonomyWhatEtf: any;
  taxonomyWhereEtf: any;
  taxonomyInvestmentRegionETF: any;
  taxonomyWhatStock: any;
  taxonomyWhere: any;

  _options: StrategyOptions;

  // taxonomyWhatEtf = null;
  // taxonomyWhatStock = null;
  // taxonomyWhere = null;
  // /**
  //  * @param {object} params - parameters
  //  */
  constructor(environment: AppEnvironment, params?: any) {
    if (environment == null) {
      throw new Error("[CRITICAL] No environment!");
    }
    this.environment = environment;
    this.format = this.environment.formatter;
    // formatType
    if (
      params != null &&
      "formatType" in params &&
      params["formatType"] != null
    ) {
      this.formatType = params["formatType"];
    } else {
      this.formatType = "HTML";
    }
    // // ranking
    const configurationBuilder =
      environment.configuration.get("strategyBuilder");
    this.optionsRanking = configurationBuilder.ranking.edit.options;

    this.optionsSelection = configurationBuilder.selection.edit.options;

    // smart beta
    const optionsSmartBeta =
      configurationBuilder.formAdvanced.widgets.smartBeta.options;
    this.optionsSmartBeta = {};
    for (let i = 0, length = optionsSmartBeta.length; i < length; i++) {
      const item = optionsSmartBeta[i];
      this.optionsSmartBeta[item["property"]["value"]] = item;
    }
    // // properties
    this.properties = environment.properties;
    this.formatRankingSelection = new RankingSelection({
      environment: environment,
      formatType: this.formatType,
    });

    const _taxonomies = environment.taxonomies;
    const taxonomyWhere: any = [];
    const txFields = environment.taxonomyFields;

    for (const key in _taxonomies[txFields["security"]["country"]]) {
      taxonomyWhere.push(_taxonomies[txFields["security"]["country"]][key]);
    }
    const taxonomyWhatEtf = Object.values(
      _taxonomies[txFields["ETF"]["etfclass"]]
    );
    const taxonomyWhatStock = Object.values(
      _taxonomies[txFields["security"]["sector"]]
    );
    this.taxonomyWhereEtf = Object.values(
      _taxonomies[txFields["security"]["country"]]
    );
    this.taxonomyStock = _taxonomies["StockClassification"];
    this.taxonomyWhatEtf = taxonomyWhatEtf;
    this.taxonomyWhatStock = taxonomyWhatStock;
    this.taxonomyWhere = taxonomyWhere;
    // this.taxonomyWhereEtf = Object.values(
    //   _taxonomies[txFields["security"]["domicile"]]
    // );
    this.taxonomyInvestmentRegionETF = Object.values(
      _taxonomies[txFields["ETF"]["etfgeo"]]
    );

    this._options = new StrategyOptions();
  }

  cappingPeer(value, isEtf) {
    const formatType = this.formatType;
    const formatOptions = {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    };
    let weightCapping = this.format.custom("number", {
      options: formatOptions,
      output: formatType,
      value: value["weightCappedMax"],
      valueHelper: null,
    });

    if (value["weightCappedMethod"] === "SUBSTITUTION") {
      weightCapping += " by switching constituents";
    }

    const options = this._options.getWeightCappingPeerLevel(
      "LABEL_LOOKUP",
      value["peerLevel"],
      isEtf
    );

    const peerLevel = value["peerLevel"] != null ? options : "Not set";
    const formatted = {
      label: [this.strong(peerLevel), "capping"].join(" "),
      value: this.strong(weightCapping),
    };
    return formatted;
  }
  cappingSecurity(value) {
    const formatType = this.formatType;
    const options = {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    };
    const formatted = [
      "Min",
      this.strong(
        this.format.custom("number", {
          options: options,
          output: formatType,
          value: value["weightCappedMin"],
          valueHelper: null,
        })
      ),
      "Max",
      this.strong(
        this.format.custom("number", {
          options: options,
          output: formatType,
          value: value["weightCappedMax"],
          valueHelper: null,
        })
      ),
    ].join(" ");
    return formatted;
  }
  cash(value) {
    let formatted = "#UNKNOWN";
    const formatType = this.formatType;
    const options = {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    };
    switch (value) {
      case 0: {
        formatted = "Flex";
        break;
      }
      case 1: {
        formatted = "Full";
        break;
      }
      default: {
        formatted = [
          "Min",
          this.format.custom("number", {
            options: options,
            output: formatType,
            value: value,
            valueHelper: null,
          }),
        ].join(" ");
      }
    }
    return this.strong(formatted);
  }

  cashMax(value) {
    const formatType = this.formatType;
    var options = {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    };
    var formatted = [
      value ? "Max" : null,
      this.strong(
        this.format.custom("number", {
          options: options,
          output: formatType,
          value: value,
          valueHelper: null,
        })
      ),
    ].join(" ");
    return this.strong(formatted);
  }
  currency(value) {
    let formatted = "#UNKNOWN";
    switch (value) {
      case "local": {
        formatted = "Local";
        break;
      }
      default: {
        formatted = value;
      }
    }
    return this.strong(formatted);
  }
  eligibility(value) {
    const sortBy = this._options.getEligibility(
      "LABEL_LOOKUP",
      value["sortBy"]
    );
    const formatted = [
      this.strong(value["cardinality"]),
      "Instruments".toLowerCase(),
      "ordered by",
      this.strong(sortBy),
    ].join(" ");
    return formatted;
  }
  /**
   * Get general type from all classification codes
   *
   * @param {array} value -  an array of ICB or ETFs classification codes
   *
   * @returns {string} returns a value indicating the composition of the array
   *                   Valid values: STOCK, MIXED, ETF or null if empty
   */
  getTypeFromCodes(value) {
    let type;
    const taxonomyWhatStock = this.taxonomyWhatStock;
    const taxonomyWhatEtf = this.taxonomyWhatEtf;
    for (let i = 0, length = value.length; i < length; i++) {
      const id = value[i];
      if (taxonomyWhatStock.find((node) => node.id === id)) {
        if (type == null || type === "STOCK") {
          type = "STOCK";
        } else {
          return "MIXED";
        }
      } else if (taxonomyWhatEtf.find((node) => node.id === id)) {
        if (type == null || type === "ETF") {
          type = "ETF";
        } else {
          return "MIXED";
        }
      } else {
        return "MIXED";
      }
    }
    return type;
  }
  hedgingStrategy(value) {
    let formatted = "#UNKNOWN";
    switch (value["hedgingStrategy"]) {
      case "HEDGING_ADVANCED": {
        formatted = "Advanced hedging";
        break;
      }
      case "HEDGING_FULL": {
        formatted = "Full hedging";
        break;
      }
      case "HEDGING_SMART": {
        formatted = "Smart hedging";
        break;
      }
    }
    return this.strong(formatted);
  }
  holdings(value) {
    return this.strong(value);
  }
  inceptionDate(value) {
    return this.strong(value);
  }
  inceptionValue(value) {
    return this.strong(value);
  }
  instrument(value) {
    return this.strong(value);
  }
  // /**
  //  * Format instrument type
  //  *
  //  * @param {object}  value - the value to be formatted
  //  * @param {boolean} value.domestic - true if instruments are domestic
  //  * @param {boolean} value.foreign - true if instruments are foreign
  //  * @param {string}  value.instrumentType - 'etf' or 'stock'
  //  * @param {array}   value.instrumentTypeSub - stock classification
  //  *
  //  * @returns {string} the formatted instrument type
  //  */
  instrumentType(value: any) {
    const formatted: any = [];
    if (value["domestic"] === true && value["foreign"] === false) {
      formatted.push(this.strong("Domestic"));
    }
    if (value["domestic"] === false && value["foreign"] === true) {
      formatted.push(this.strong("Foreign"));
    }
    switch (value["instrumentType"]) {
      case "etf": {
        formatted.push(
          this.strong(formatted.length === 1 ? "ETFs".toLowerCase() : "ETFs")
        );
        break;
      }
      case "stock": {
        formatted.push(
          this.strong(
            formatted.length === 1 ? "Stocks".toLowerCase() : "Stocks"
          )
        );
        break;
      }
      default: {
        formatted.push(this.strong("#UNKNOWN"));
      }
    }
    const length = value["instrumentTypeSub"].length;
    if (length > 0) {
      let subTypes: any = [];
      for (let i = 0; i < length; i++) {
        const item: any = this.taxonomyStock.find(
          (node) => node.id === value["instrumentTypeSub"][i]
        );
        subTypes.push(this.strong(item.name));
      }
      formatted.push(["(", subTypes.join(", "), ")"].join(""));
    }
    return formatted.join(" ");
  }
  leverage(value) {
    return this.strong(value);
  }
  /**
   * Format performance (price / total return)
   *
   * @param {string} value - one of 'NONE' or 'REBALANCE'
   *
   * @returns {string} the formatted rebalance frequency
   */
  performance(value) {
    let formatted = "";
    switch (value) {
      case "NONE": {
        formatted = "Price";
        break;
      }
      case "REBALANCE": {
        formatted = "Total return";
        break;
      }
      default: {
        formatted = "#UNKNOWN";
      }
    }
    return this.strong(formatted);
  }
  period(value) {
    const formatted = {
      label: "Period",
      value: "",
    };
    switch (value["type"]) {
      case "DAY": {
        formatted["value"] = this.strong(value["value"]);
        formatted["label"] = "Period from";
        break;
      }
      case "YEAR": {
        formatted["value"] = this.strong(value["value"] + " years");
        break;
      }
      default: {
        formatted["value"] = this.strong("#UNKNOWN");
      }
    }
    return formatted;
  }
  ranking(value: any[]) {
    return this._rankingSelection(value, this.optionsRanking);
  }
  /**
   * Format rebalance frequency
   *
   * @param {string} value - one of '05_DAYS', '20_DAYS' or '60_DAYS'
   *
   * @returns {string} the formatted rebalance frequency
   */
  rebalance(value) {
    let formatted = "";
    switch (value) {
      case "05_DAYS": {
        formatted = "Weekly";
        break;
      }
      case "20_DAYS": {
        formatted = "Monthly";
        break;
      }
      case "60_DAYS": {
        formatted = "Quarterly";
        break;
      }
      default: {
        formatted = "#UNKNOWN";
      }
    }
    return this.strong(formatted);
  }
  rotation(value) {
    let factor;
    switch (value["factor"]) {
      case "FACTOR_MOMENTUM": {
        factor = "Rating Breadth";
        break;
      }
      case "FACTOR_MARKET_CAP_NEUTRAL": {
        factor = "Market neutral";
        break;
      }
      case "FACTOR_MOMENTUM_GROWTH": {
        factor = "Rating Growth";
        break;
      }
      default: {
        factor = "#UNKNOWN";
      }
    }
    const rotate = this._options.getRotation("LABEL_LOOKUP", value["rotate"]);
    const formatted = [
      "Rotate",
      this.strong(rotate),
      "by",
      this.strong(factor),
    ].join(" ");
    return formatted;
  }
  selection(value: any[]) {
    return this._rankingSelection(value, this.optionsSelection);
  }
  smartBeta(value) {
    const formatType = this.formatType;
    const options = {
      decimals: 2,
      notAvailable: {
        input: null,
        output: "",
      },
    };
    const optionsSmartBeta = this.optionsSmartBeta;
    const formatted: any = [];
    for (let i = 0; i < value.length; i++) {
      const item = value[i];
      const itemFormatted: any = [];
      // weight
      itemFormatted.push(
        this.strong(
          this.format.custom("number", {
            options: options,
            output: formatType,
            value: item["weight"],
            valueHelper: null,
          })
        )
      );
      // property
      itemFormatted.push(
        this.strong(optionsSmartBeta[item["property"]]["property"]["label"])
      );
      // operator
      switch (item["property"]) {
        case "rc": {
          itemFormatted.push({
            A: this.strong(
              this.format.custom("number", {
                options: options,
                output: formatType,
                value: item["operator"]["A"],
                valueHelper: null,
              })
            ),
            B: this.strong(
              this.format.custom("number", {
                options: options,
                output: formatType,
                value: item["operator"]["B"],
                valueHelper: null,
              })
            ),
            C: this.strong(
              this.format.custom("number", {
                options: options,
                output: formatType,
                value: item["operator"]["C"],
                valueHelper: null,
              })
            ),
            D: this.strong(
              this.format.custom("number", {
                options: options,
                output: formatType,
                value: item["operator"]["D"],
                valueHelper: null,
              })
            ),
          });
          break;
        }
        default: {
          switch (item["operator"]) {
            case "bottom": {
              itemFormatted.push(this.strong("Bottom"));
              break;
            }
            case "middle": {
              itemFormatted.push(this.strong("Middle"));
              break;
            }
            case "top": {
              itemFormatted.push(this.strong("Top"));
              break;
            }
          }
        }
      }
      formatted.push(itemFormatted);
    }
    return formatted;
  }
  // utility function
  strong(value: string) {
    return "<strong>" + value + "</strong>";
  }
  /**
   * Format sectors
   *
   * @param {array} value -  an array of ICB or ETFs classification codes
   *
   * @returns {string} the formatted sectors
   */
  what(value) {
    const tokens: string[] = [];
    const whatTaxonomies = [this.taxonomyWhatStock, this.taxonomyWhatEtf];
    for (let i = 0, length = value.length; i < length; i++) {
      tokens.push(
        this._taxonFormat(
          getTaxonById(value[i], whatTaxonomies),
          whatTaxonomies
        )
      );
    }
    if (tokens.length === 0) {
      tokens.push("All");
    }
    tokens.sort();
    return this.strong(tokens.join(", "));
  }
  weightingSchema(value) {
    let formatted = "";
    switch (value) {
      case "WEIGHT_EQUAL": {
        formatted = "Equal weighted";
        break;
      }
      case "WEIGHT_MARKET_CAP": {
        formatted = "Market cap weighted";
        break;
      }
      default: {
        formatted = "#UNKNOWN";
      }
    }
    return this.strong(formatted);
  }
  weightingSchemaExistingPositions(value) {
    let formatted = "";
    switch (value) {
      case "WEIGHT_EXISTING_POSITIONS_KEEP": {
        formatted = "Keep weights";
        break;
      }
      case "WEIGHT_EXISTING_POSITIONS_REBALANCE": {
        formatted = "Rebalance weights";
        break;
      }
      default: {
        formatted = "#UNKNOWN";
      }
    }
    return this.strong(formatted);
  }
  /**
   * Format markets
   *
   * @param {object} value -  the parameters
   * @param {boolean} value.domestic - true if only domestic instruments
   *   must be selected, false otherwise
   * @param {boolean} value.foreign - true if only foreign instruments
   *   must be selected, false otherwise
   * @param {array} value.market - an array of market codes
   * @param {array} value.stockClassification - an array of stock
   *   classification codes
   *
   * @returns {string} the formatted markets
   */
  where(value) {
    const tokens: string[] = [];
    for (let i = 0, length = value.length; i < length; i++) {
      tokens.push(
        this.taxonomyWhere.find((node) => node.id === value[i])["name"]
      );
    }
    if (tokens.length === 0) {
      tokens.push("All");
    }
    tokens.sort();
    return this.strong(tokens.join(", "));
  }
  whereETF(value) {
    const tokens: string[] = [];
    for (let i = 0, length = value.length; i < length; i++) {
      let temp = value[i];
      let obj = this.taxonomyWhereEtf.filter((item) => item.id === temp);
      if (obj[0]) {
        tokens.push(obj[0].name);
      } else {
        tokens.push(temp);
      }
    }
    if (tokens.length === 0) {
      tokens.push("All");
    }
    tokens.sort();
    return this.strong(tokens.join(", "));
  }

  investmentRegionETF(value) {
    const tokens: string[] = [];
    for (let i = 0, length = value.length; i < length; i++) {
      let temp = value[i];
      let obj = this.taxonomyInvestmentRegionETF.filter(
        (item) => item.id === temp
      );
      if (obj[0]) {
        tokens.push(obj[0].name);
      } else {
        tokens.push(temp);
      }
    }
    if (tokens.length === 0) {
      tokens.push("All");
    }
    tokens.sort();
    return this.strong(tokens.join(", "));
  }

  // ------------------------------------------------- private methods
  _rankingSelection(value: any, options: any) {
    const smartFormatter: any = {
      quantile: {
        4: {
          equalTo: { 1: "Top Quartile", 4: "Bottom Quartile" },
          lessThan: {
            2: "Top quartile",
            3: "Top two quartiles",
            4: "Top three quartiles",
          },
          lessThanOrEqualTo: {
            1: "Top Quartile",
            2: "Top two Quartiles",
            3: "Top three Quartiles",
          },
          greaterThan: {
            2: "Bottom two Quartiles",
            3: "Bottom Quartile",
          },
          greaterThanOrEqualTo: {
            2: "Second Quartile",
            3: "Bottom two Quartiles",
            4: "Bottom Quartiles",
          },
        },
        5: {
          equalTo: { 1: "Top Quintile", 5: "Bottom Quintile" },
          lessThan: {
            2: "Top Quintile",
            3: "Top two Quintiles",
            4: "Top three Quintiles",
          },
          lessThanOrEqualTo: {
            1: "Top Quintile",
            2: "Top two Quintiles",
            3: "Top three Quintiles",
          },
          greaterThan: {
            3: "Bottom two Quintiles",
            4: "Bottom Quintile",
          },
          greaterThanOrEqualTo: {
            4: "Bottom two Quintiles",
            5: "Bottom Quintile",
          },
        },
      },
    };

    const formattedConstraints: any = [];
    const formatRankingSelection = this.formatRankingSelection;
    const propertyLabel = this._rankingSelectionPropertyLabel(options);
    for (let i = 0, length = value.length; i < length; i++) {
      const constraint = value[i];

      const foundFunction = smartFormatter[constraint.function];
      // console.log("--------------------------------");
      // console.log("--------------------------------");
      // console.log(constraint);
      // console.log("--------------------------------");
      // console.log("foundFunction", constraint.function, foundFunction);
      let foundOperatorParams = null;
      if (foundFunction != null && constraint.functionParams != null) {
        const foundFunctionParams =
          foundFunction[constraint.functionParams.value];
        // console.log(
        //     "foundFunctionParams",
        //     constraint.functionParams.value,
        //     foundFunctionParams
        // );
        if (foundFunctionParams != null) {
          const foundOperator = foundFunctionParams[constraint.operator];
          // console.log(
          //     "foundOperator",
          //     constraint.operator,
          //     foundOperator
          // );
          if (foundOperator != null) {
            if (constraint.operator === "equalTo") {
              if (constraint.operatorParams.value != null) {
                const operatorParams = constraint.operatorParams.value;
                if (operatorParams != null) {
                  foundOperatorParams = foundOperator[operatorParams];
                  // console.log(
                  //     "foundOperatorParams",
                  //     foundOperatorParams
                  // );
                }
              }
            } else {
              let foundParam: any = null;
              switch (constraint.operator) {
                case "greaterThan":
                  foundParam = "gt";
                  break;
                case "greaterThanOrEqualTo":
                  foundParam = "ge";
                  break;
                case "lessThan":
                  foundParam = "lt";
                  break;
                case "lessThanOrEqualTo":
                  foundParam = "le";
                  break;
              }
              // console.log(foundParam);

              if (
                foundParam != null &&
                constraint.operatorParams.value != null &&
                constraint.operatorParams.value.length > 0
              ) {
                const operatorParams =
                  constraint.operatorParams.value[0][foundParam];
                if (operatorParams != null) {
                  foundOperatorParams = foundOperator[operatorParams];
                  // console.log(
                  //     "foundOperatorParams",
                  //     foundOperatorParams
                  // );
                }
              }
            }
          }
        }
      }
      if (foundOperatorParams != null) {
        const formattedConstraint: any = [];
        // property
        formattedConstraint.push(
          formatRankingSelection.property(
            constraint["property"],
            propertyLabel,
            null
          )
        );
        formattedConstraint.push(foundOperatorParams);
        formattedConstraints.push(formattedConstraint);
      } else {
        let formattedConstraint: any = [];
        // property
        formattedConstraint.push(
          formatRankingSelection.property(
            constraint["property"],
            propertyLabel,
            null
          )
        );
        // function
        formattedConstraint.push(
          formatRankingSelection.function(
            constraint["property"],
            constraint["function"],
            constraint["functionParams"]
          )
        );
        // operator: interface must be unified
        // sortBy must be an type of operator and the value
        // must be encapsulated within an operatorParams
        //
        // for selection
        if ("operator" in constraint) {
          formattedConstraint.push(
            formatRankingSelection.operator(
              constraint["property"],
              constraint["function"],
              constraint["operator"],
              constraint["operatorParams"]
            )
          );
        }
        // ranking
        if ("sortBy" in constraint) {
          formattedConstraint.push(
            formatRankingSelection.operator(
              constraint["property"],
              constraint["function"],
              "sortBy",
              {
                value: constraint["sortBy"],
              }
            )
          );
        }
        // console.log(formattedConstraint);

        if (formattedConstraint.length > 2) {
          const [firstConstraint, ...otherConstraints] = formattedConstraint;
          formattedConstraint = [firstConstraint, otherConstraints.join(" ")];
        }

        formattedConstraints.push(formattedConstraint);
      }
    }
    return formattedConstraints;
  }
  _rankingSelectionPropertyLabel(options: any) {
    const propertyLabel: any = {};
    for (let i = 0, length = options.length; i < length; i++) {
      const option = options[i]["property"];
      propertyLabel[option["value"]] = option["label"];
    }
    return propertyLabel;
  }
  _taxonFormat(taxon, taxonomies) {
    let formatted = taxon["name"];
    if (taxon["type"] === "3 Sector") {
      const parent = getTaxonById(taxon["parent"], taxonomies);
      formatted = parent["name"] + " - " + formatted;
    }
    return formatted;
  }
}
