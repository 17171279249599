import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { MutableRefObject, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Environment } from "../../../../../Environment";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../api/compute/Taxon";
import { decodePeerId } from "../../../../../api/utils";
import Modal from "../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../deepClone";
import { Collector } from "../../../../trendrating-report/data/Collector";
import { Downloader } from "../../../../trendrating-report/downloader/DownloaderReact";
import { Generator } from "../../../../trendrating-report/generator/Generator";
import { PeerDetailStorage } from "../../../storage/PeerDetailStorage";
import { messageError } from "../../../utils";
import { PeerDetailSegments } from "../detail/PeerDetail";
import { defaultTemplateReportPeerWizard } from "./../../../../trendrating-report/defaultTemplateReportPeerWizard-ts";
import { MarketsStorage } from "../../../storage/MarketsStorage";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

type Peer = {
  alerts: {
    last3Months: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    lastMonth: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    lastWeek: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
    today: {
      downgrades: number;
      ratio: number;
      upgrades: number;
    };
  };
  id: string;
  info: {
    cardinality: number;
    marketcap: number;
  };
  name: string;
  size: string;
  statistic: {
    abPercentage: {
      today: number;
      yesterday: number;
      lastWeek: number;
      lastMonth: number;
    };
    cdPercentage: {
      today: number | null;
      yesterday: number | null;
      lastWeek: number | null;
      lastMonth: number | null;
    };
    downgrades: {
      today: {
        number: number | null;
        percentage: number | null;
      };
      yesterday: {
        number: number | null;
        percentage: number | null;
      };
      lastWeek: {
        number: number | null;
        percentage: number | null;
      };
      lastMonth: {
        number: number | null;
        percentage: number | null;
      };
    };
    ratings: {
      today: {
        A: {
          number: number | null;
          percentage: number | null;
        };
        B: {
          number: number | null;
          percentage: number | null;
        };
        C: {
          number: number | null;
          percentage: number | null;
        };
        D: {
          number: number | null;
          percentage: number | null;
        };
      };
      yesterday: number | null;
      lastWeek: number | null;
      lastMonth: number | null;
    };
    quantiles: {
      pm: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      pq: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      py: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
      pw: {
        q0: null;
        q1: null;
        q2: null;
        q3: null;
        q4: null;
      };
    };
    upgrades: {
      today: {
        number: number | null;
        percentage: number | null;
      };
      yesterday: {
        number: number | null;
        percentage: number | null;
      };
      lastWeek: {
        number: number | null;
        percentage: number | null;
      };
      lastMonth: {
        number: number | null;
        percentage: number | null;
      };
    };
  };
  tcr: {
    today: number;
    yesterday: number;
    lastMonth: number;
    lastWeek: number;
  };
  type: "Stock" | "ETF";
  what: string;
  where: string;
};

type DirectDispersionReportProps = {
  peer: Peer;
  environment: Environment;
  timeframe: "today" | "lastWeek" | "lastMonth";
  segment: PeerDetailSegments;
  title: string;
  dispersionByConstraints: {
    performanceTimeframe: "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    trimOutliers: boolean;
  };
  formatter: any;
  dispersionTabRef: MutableRefObject<any>;
};

type ListenerPrintParams = {
  dispersion: {
    intervals: number;
    timeframe: string;
  };
  focus: {
    focusOn: string;
    what: { label: string | null; type: string; value: string };
    hasIndustries: boolean;
  };
  reportType: string;
  size: string | null;
  timeframe: string;
  where: {
    label: string | null;
    type: string;
    value: string;
  };
};

const SORT_DICT = {
  top: "top",
  bottom: "bottom",
  mid: "middle",
  middle: "middle",
  dispersionAverageTop: "top",
  dispersionAverageBottom: "bottom",
  dispersionAverageMid: "middle",
  name: "name",
};

// It has been decided that microLarge and null are the same thing.
// microLarge is the default size
//
// Used for queries and PeerSize widget
//
const fixPeerSize = (value) => {
  if (value == null) {
    return "microLarge";
  }

  if (value === "microLarge") {
    return null;
  }

  return value;
};

export function DirectDispersionReport({
  peer,
  environment,
  timeframe,
  segment,
  dispersionByConstraints,
  title,
  formatter,
  dispersionTabRef,
}: DirectDispersionReportProps) {
  const { t } = useTranslation();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const storage = useMemo(
    () => new PeerDetailStorage(environment.get("setup")),
    [environment]
  );
  const [isLoading, setIsLoading] = useState(false);
  const { broadcast } = useBroadcast();

  /**
   * Get all details about what and where
   *
   * @param {string} peerId - the peer ID. It is a string with this
   *      structure
   *
   *          where-what-instrumentType-size
   *
   *      e.g. CH-50-Stock-microLarge
   *
   * @returns {object}
   */
  const getWhatWhereInfo = useCallback(
    (peerId: string) => {
      const peerIdTokens = decodePeerId(peerId);

      const peerType = peerIdTokens["type"].toLowerCase();
      const taxonomies = setup["taxonomies"];
      const fieldsMap = setup["taxonomyFields"];
      const what = peerIdTokens["what"];
      const whatStore = Object.values<any>(
        taxonomies[
          fieldsMap[peerType === "etf" ? "ETF" : "security"][
            peerType === "etf" ? "etfclass" : "sector"
          ]
        ]
      );
      const where = peerIdTokens["where"];
      const whereStore = Object.values<any>(
        taxonomies[
          fieldsMap[peerType === "etf" ? "ETF" : "security"][
            peerType === "etf" ? "etfgeo" : "country"
          ]
        ]
      );

      const whatType = whatStore.find((item) => item.id === what)["type"];
      const whereType = whereStore.find((item) => item.id === where)["type"];

      return {
        what: what,
        whatStore: whatStore,
        whatType: whatType,
        where: where,
        whereStore: whereStore,
        whereType: whereType,
      };
    },
    [setup]
  );

  const getUiState = useCallback(
    (target) => {
      const columnsAvailable = [];
      const wysiwygState: any = {
        actions: {
          constraints: null,
          rank: null,
          sortBy: null,
        },
        columns: null,
        dataTotalCount: 0,
        promiseCache: null,

        // 2020-12-10 Markets: new way to do stuff using storages
        storage: null,

        target: null,
        targetType: null,
      };

      if (target["name"] === "__ROOT__") {
        target["name"] = t("World_wide");
      }
      target["name"] = target["name"].replace(",", " - ");

      wysiwygState["storage"] = new MarketsStorage(environment.get("setup"));
      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "PEER";

      // Prepare data passed to action for use in wysiwyg
      wysiwygState.timeframe = timeframe;
      wysiwygState.trimOutliers = dispersionByConstraints.trimOutliers;
      wysiwygState.dispersionByConstraints = dispersionByConstraints;
      wysiwygState.segment = segment;
      wysiwygState.sortState = {
        descending: false,
        property: "_s_label",
      };
      const tableSorter = dispersionTabRef?.current?.getSorter() ?? null;

      if (tableSorter) {
        const sorter = tableSorter[0];
        const field = sorter?.field ? SORT_DICT?.[sorter.field] : "_s_label";
        wysiwygState.sortState = {
          descending: (sorter?.dir ?? "asc") === "desc",
          property: field,
        };
      }

      wysiwygState.title = title;

      const uiState = {
        columnsAvailable: columnsAvailable,
        wysiwygState: wysiwygState,
      };

      if (appConfig.isDebug === true) {
        console.log("uiState", uiState);
      }

      return uiState;
    },
    [
      dispersionByConstraints,
      dispersionTabRef,
      environment,
      segment,
      t,
      timeframe,
      title,
    ]
  );

  const injectPageBreakInTemplate = useCallback((index, sectionsList) => {
    const pageBreak = {
      type: "REPORT_COMMON_PAGE_BREAK",
      content: null,
      presentation: null,
      sections: null,
    };

    sectionsList.splice(index, 0, pageBreak);
  }, []);

  const getUserPreference = useCallback((user, printDialogValue) => {
    var userPreference: any =
      user?.preferences?.preferences?.report?.general ?? null;

    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }

    userPreference["theme"] = printDialogValue["theme"];

    return userPreference;
  }, []);

  const preparePrintParams = useCallback(
    (target) => {
      // template
      const template = defaultTemplateReportPeerWizard?.dispersion ?? undefined;

      if (template) {
        // executable sections
        const baseId = new Date().getTime();
        const executableSections = deepClone(template.configuration.sections);
        let section: any = null;
        // wysiwygState
        const wysiwygState = getUiState(target).wysiwygState;
        wysiwygState.storage = {
          peers: new MarketsStorage(environment.get("setup")),
        };
        /**
         * Check if the cluster dimension is Industry to insert a page break after the first
         * widget (chart). This avoid the wrong render of tables that will appear in the report
         * as if they were cut between pages.
         * In the others cases the page break will be injected after the first table.
         */
        if (segment === "3 Sector") {
          injectPageBreakInTemplate(2, executableSections);
        } else {
          injectPageBreakInTemplate(3, executableSections);
        }
        for (var i = 0, length = executableSections.length; i < length; i++) {
          section = executableSections[i];
          section.id = baseId + "-" + i;
          switch (section.type) {
            case "REPORT_DISPERSION_BY_CHART":
            case "REPORT_DISPERSION_BY_SECTORS": {
              section.content.trimOutliers =
                dispersionByConstraints.trimOutliers;
              section.content.segment = segment;
              section.content.intervals = Number(
                dispersionByConstraints.intervals
              );
              section.content.timeframe =
                dispersionByConstraints.performanceTimeframe;
              const defaultSort =
                segment !== "3 Level"
                  ? {
                      descending: false,
                      property: "_s_label",
                    }
                  : {
                      descending: true,
                      property: "_s_label",
                    };

              section.content.sort = wysiwygState.sortState ?? defaultSort;
              break;
            }
            case "REPORT_DISPERSION_TCR_TABLE": {
              section.content.segment = segment;
              section.content.timeframe =
                dispersionByConstraints.performanceTimeframe;
              const defaultSort =
                segment !== "3 Level"
                  ? {
                      descending: false,
                      property: "name",
                    }
                  : {
                      descending: true,
                      property: "name",
                    };
              section.content.sort = wysiwygState.sortState ?? defaultSort;
              break;
            }
            case "REPORT_DISPERSION_RATIO_TABLE": {
              section.content.segment = segment;
              section.content.timeframe = timeframe;
              break;
            }
            case "REPORT_COMMON_PARAGRAPH": {
              let timeframe = "today";
              switch (timeframe) {
                case "today":
                default:
                  break;
                case "lastWeek":
                  timeframe = "of the last 5 days";
                  break;
                case "lastMonth":
                  timeframe = "of the last 20 days";
                  break;
              }
              const text = section.content.text.replace(
                "{TIMEFRAME}",
                timeframe
              );
              section.content.text = text;
              break;
            }
            case "REPORT_COMMON_TITLE":
              let sgt = "";
              switch (segment) {
                case "1 Industry":
                default:
                  sgt =
                    target.type === "ETF"
                      ? "Dispersion by Asset Class"
                      : "Dispersion by Sectors";
                  break;
                case "3 Sector":
                  sgt =
                    target.type === "ETF"
                      ? "Dispersion by Specialty"
                      : "Dispersion by Industries";
                  break;
                case "3 Level":
                  sgt =
                    target.type === "ETF"
                      ? "Dispersion by Issuer"
                      : "Dispersion by Size";
                  break;

                case "4 Subsector":
                  sgt = target.type === "ETF" ? "Dispersion by Theme" : "";

                  break;
                case "Country":
                  sgt =
                    target.type === "ETF"
                      ? "Dispersion by Inv. Region"
                      : "Dispersion by Market";
                  break;
                case "Region":
                  sgt = "Dispersion by Regions";
                  break;
                case "Area":
                  sgt = "Dispersion by Area";
                  break;
              }
              let title = wysiwygState.title;
              title += "<br/>" + sgt;
              section["content"]["text"] = title;
            //no default
          }
        }
        // user preferences
        var environmentSetup = environment.get("setup");
        var user = environmentSetup["account"]["user"];
        var userPreference = getUserPreference(user, template.configuration);
        return {
          sections: executableSections,
          template: template,
          userPreference: userPreference,
          wysiwygState: wysiwygState,
        };
      }

      return undefined;
    },
    [
      dispersionByConstraints.intervals,
      dispersionByConstraints.performanceTimeframe,
      dispersionByConstraints.trimOutliers,
      environment,
      getUiState,
      getUserPreference,
      injectPageBreakInTemplate,
      segment,
      timeframe,
    ]
  );

  const prepareWizardParams = useCallback(
    (peer) => {
      const peerId = peer.id;
      const peerInfo = getWhatWhereInfo(peerId);
      const what = peerInfo.what;
      const whatType = peerInfo.whatType;
      const where = peerInfo.where;
      const whereType = peerInfo.whereType;

      const wizardParams = {
        dispersion: { intervals: 4, timeframe: "1_month" },
        focus: {
          focusOn: whatType,
          what: { label: null, type: whatType, value: what },
          hasIndustries: false,
        },
        reportType: "overview",
        size: fixPeerSize(peer.size),
        timeframe: "lastMonth",
        where: {
          label: null,
          type: whereType,
          value: where,
        },
      };

      switch (whatType) {
        case "0 root": {
          wizardParams.focus.focusOn = "1 Industry";

          break;
        }
        case "1 Industry":
        case "3 Sector":
        default: {
          // nothing to do
        }
      }

      switch (whereType) {
        case "World": {
          if (what === "ICB") {
            wizardParams.focus.focusOn = "Area";
          }

          break;
        }
        case "Area":
        case "Country":
        case "Region":
        default: {
          // nothing to do
        }
      }

      return wizardParams;
    },
    [getWhatWhereInfo]
  );

  const listenerPrint = useCallback(
    async (wizardParams: ListenerPrintParams, peerType) => {
      const type = peerType === "ETF" ? "ETF" : "Stock";
      const peerBasicInfo = {
        zDimension: wizardParams.size,
        type,
        what: wizardParams.focus.what.value,
        where: wizardParams.where.value,
      };
      const rawTaxonomies = environment.get("rawTaxonomies");
      const txFields = environment.get("taxonomyFields");

      const taxonomies = [rawTaxonomies["Markets"]];

      const response = await storage.getPeer(peerBasicInfo);

      const target = response;

      if (peer?.type === "ETF") {
        taxonomies.push(rawTaxonomies[txFields["ETF"]["etfgeo"]]);
      } else {
        taxonomies.push(rawTaxonomies[txFields["security"]["country"]]);
      }

      const regionLabel = formatTaxonPrefixingParent(
        getTaxonById(peerBasicInfo.where, taxonomies, null),
        taxonomies,
        "Region"
      );

      wizardParams["where"]["label"] = regionLabel;
      // Region are formatted in a special way, with the
      // parent Area combined in the name.
      // Needs to have what value as ICB
      if (
        wizardParams.focus.what.value === "ICB" &&
        wizardParams.where.type === "Region"
      ) {
        if (target) {
          target["name"] = wizardParams.where.label as string;
        }
      }

      const printParams: any = preparePrintParams(target);
      const data = new Collector(setup, {
        rankingCache: null,
        sections: deepClone(printParams.sections),
        template: deepClone(printParams.template),
        userPreference: printParams.userPreference,
        wysiwygState: printParams.wysiwygState,
      });

      try {
        const { response } = await data.retrieve();

        const report = new Generator(setup, {
          data: response,
          formatter: formatter,
          sections: deepClone(printParams.sections),
          template: deepClone(printParams.template),
          userPreference: printParams.userPreference,
          wysiwygState: printParams.wysiwygState,
        });

        await report.create();
        const pdf = await report.print();

        const downloader = new Downloader(report);
        downloader.download(pdf);
      } catch (error) {
        console.log(error);
      }
    },
    [environment, formatter, peer?.type, preparePrintParams, setup, storage]
  );

  const doAction = useCallback(async () => {
    // ***************** USAGE *****************
    var usage = window.App.usage;
    var info = {
      action: "REPORT",
      actionParam: null,
      function: "PEER_ANALYSIS",
    };
    usage.record(info);
    // ***************** USAGE *****************

    setIsLoading(true);
    try {
      const wizardParams = prepareWizardParams(peer);
      await listenerPrint(wizardParams, peer.type);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      const [channel, msg] = messageError("Failed to print the PDF report");
      broadcast(channel as string, msg);
    }
  }, [broadcast, listenerPrint, peer, prepareWizardParams]);

  return (
    <>
      {isLoading && (
        <Modal closeIcon={false}>
          <Box
            p={2}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress sx={{ color: "#2a7090" }} />
            <Typography>Printing report...</Typography>
          </Box>
        </Modal>
      )}
      <li
        onClick={doAction}
        className={"menu__item"}
        title="Print a dispersion report"
      >
        Dispersion Report
      </li>
    </>
  );
}
