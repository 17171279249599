import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { config } from "../../../../../../config-ts";
import { appCookie } from "../../../../../../Cookies";
import { Product as API } from "../../../../../../../../api/account/Product";

// type Props = {};

export default function Product() {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const actualProduct = environment.get("configuration").get("product");
  const user = useMemo(() => setup["account"]["user"], [setup]);
  const productAPI = useMemo(() => new API(setup), [setup]);

  const [Products, setProducts] = useState<any>(null);
  useEffect(() => {
    var requestProduct = productAPI.all();
    requestProduct.then((response) => setProducts(response));
  }, [productAPI]);

  const onSelectProduct = useCallback((productID) => {
    var url = config.router.base + "/";
    appCookie("productId", productID);
    window.location.href = url;
  }, []);

  const productSelect = useMemo(() => {
    if (Products != null) {
      if (user.group === "INTERNAL") {
        return (
          <Internal
            product={actualProduct}
            products={productAPI.normalize(Products)}
            onSelectProduct={onSelectProduct}
          />
        );
      }
      if (
        user["rootProduct"] != null &&
        user["rootProduct"]["productCode"] === "SYSTEMATIC_ENGINE_AND_PREMIUM"
      ) {
        return (
          <Customer
            product={actualProduct}
            products={Products}
            onSelectProduct={onSelectProduct}
          />
        );
      }
    } else {
      return null;
    }
  }, [Products, actualProduct, onSelectProduct, productAPI, user]);

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent
        sx={{
          pb: "16px !important",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="subtitle1">
          <strong>Solution</strong>
        </Typography>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Typography>Product</Typography>
          {productSelect}
        </Box>
        <Typography>
          After changing solution the page will be reloaded.
        </Typography>
      </CardContent>
    </Card>
  );
}

type InternalType = {
  product: any;
  products: any[] | null;
  onSelectProduct: Function;
};
const Internal = ({ product, products, onSelectProduct }: InternalType) => {
  return (
    <Select
      autoFocus={false}
      size="small"
      value={product.id}
      onChange={(e) => onSelectProduct(e.target.value)}
    >
      {products &&
        products.map((product, index) => (
          <MenuItem key={index} value={product.value}>
            <Typography>{product.label}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
};

type CustomerType = {
  product: any;
  products: any;
  onSelectProduct: Function;
};
const Customer = ({ product, products, onSelectProduct }: CustomerType) => {
  const data = useMemo(() => products["data"]["products"], [products]);

  const selectContent = useMemo(() => {
    let _products: any = [];
    const salesLabels = {
      PREMIUM: "PERFORMANCE MANAGEMENT PLATFORM",
      SYSTEMATIC_ENGINE: "STRATEGY MANAGEMENT SOLUTION",
    };
    const validFlavourCodes = ["PREMIUM", "SYSTEMATIC_ENGINE"];
    for (let i = 0; i < data.length; i++) {
      if (validFlavourCodes.indexOf(data[i]["productCode"]) !== -1) {
        _products.push({
          label: salesLabels[data[i]["productCode"]],
          value: data[i]["id"],
        });
      }
    }
    return _products;
  }, [data]);
  return (
    <Select
      onChange={(e) => onSelectProduct(e.target.value)}
      autoFocus={false}
      size="small"
      value={product.id}
    >
      {selectContent &&
        selectContent.map((product, index) => (
          <MenuItem key={index} value={product.value}>
            <Typography>{product.label}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
};
