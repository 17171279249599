/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/core/ObjectType
 * @summary Application entity types
 *
 */

export class ObjectType {
  static _defaultType = "security";

  /**
   * Flatten properties map
   * @param {object} properties - the objectLiteral that contains all properties (fieldsSetting.json)
   *
   * @returns {object} The flattened (all properties at first level) properties
   */
  static getFlattenedProperties(properties: any) {
    const flattened: any = {};
    const objectTypes = ObjectType.getApiTypes();

    for (let i = 0; i < objectTypes.length; i++) {
      const objectType = objectTypes[i];
      if (objectType in properties) {
        for (const property in properties[objectType]) {
          flattened[property] = properties[objectType][property];
          flattened[property]["property"] = property;
        }
      }
    }

    for (const property in properties) {
      if (objectTypes.indexOf(property) === -1) {
        flattened[property] = properties[property];
        flattened[property]["property"] = property;
      }
    }

    return flattened;
  }

  /**
   * Returns the list of API types
   *
   * @returns {array} The list of API types
   */
  static getApiTypes(): Array<string> {
    return [
      // 'BASKET',
      // 'PORTFOLIO',
      "Commodity",
      "Currency",
      "ETF",
      "Index",
      "Sector",
      "Stock",
    ];
  }

  static getDefaultType() {
    return this._defaultType;
  }

  /**
   * Map UI types with API types
   *
   * @param {string} type - the type of security. Supported values are
   *   'security' | 'stock' | 'sector' | 'etf' | 'index' | 'currency' |
   *   'commodity'. Default 'security'
   *
   * @returns {string} The API type
   */
  static typeMapper(type: string): string {
    switch (type) {
      case "ETF":
      case "Currency":
      case "Stock":
      case "Index":
      case "Sector":
      case "Commodity": {
        return type;
      }
      case "commodity": {
        return "Commodity";
      }
      case "currency": {
        return "Currency";
      }
      case "etf": {
        return "ETF";
      }
      case "index": {
        return "Index";
      }
      case "sector": {
        return "Sector";
      }
      case "stock": {
        return "Stock";
      }
      default: {
        return this._defaultType;
      }
    }
  }
}
