import { useCallback, useEffect, useMemo, useState } from "react";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { AvoidableLossTab } from "./AvoidableLossTab";

type TabEvidenceProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabEvidence({ dataManager }: TabEvidenceProps) {
  const [portfolio, setPortfolio] = useState<any>();

  const onComponentMount = useCallback(async () => {
    const response = await dataManager.get("evidence");

    setPortfolio(response);
  }, [dataManager]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  const portfolioId = useMemo(() => {
    return portfolio?.id ? JSON.stringify(portfolio?.id) : "";
  }, [portfolio?.id]);
  const listType = useMemo(() => {
    return portfolio?.type;
  }, [portfolio?.type]);
  const listName = useMemo(() => {
    return portfolio?.name;
  }, [portfolio?.name]);
  const listStatistics = useMemo(() => {
    return portfolio?.statistics;
  }, [portfolio?.statistics]);
  const numberOfStocks = useMemo(() => {
    return portfolio?.positions?.length;
  }, [portfolio?.positions?.length]);

  const cardinalityAB = useMemo(
    () =>
      (listStatistics?.cardinalityPerRating?.["A"] ?? 0) +
      (listStatistics?.cardinalityPerRating?.["B"] ?? 0),
    [listStatistics?.cardinalityPerRating]
  );

  const cardinalityCD = useMemo(() => {
    return (
      (listStatistics?.cardinalityPerRating?.["C"] ?? 0) +
      (listStatistics?.cardinalityPerRating?.["D"] ?? 0)
    );
  }, [listStatistics?.cardinalityPerRating]);

  const ABperc = useMemo(
    () => cardinalityAB / numberOfStocks,
    [cardinalityAB, numberOfStocks]
  );
  const CDperc = useMemo(
    () => cardinalityCD / numberOfStocks,
    [cardinalityCD, numberOfStocks]
  );

  const cardinalities = useMemo(
    () => ({
      AB: cardinalityAB,
      CD: cardinalityCD,
      "AB_%": ABperc,
      "CD_%": CDperc,
      numberOfStocks: numberOfStocks,
    }),
    [ABperc, CDperc, cardinalityAB, cardinalityCD, numberOfStocks]
  );

  return portfolio ? (
    <div>
      <AvoidableLossTab
        portfolioId={portfolioId}
        listType={listType}
        listName={listName}
        cardinalityInfo={cardinalities}
      />
    </div>
  ) : (
    <></>
  );
}
