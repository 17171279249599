import { InfoTooltip } from "../../InfoTooltip";
import { TablePeers } from "./TablePeers";

type MarketWidgetProps = {
    data: any[];
    timeframe: string;
    accordionHandler: Function;
    accordionState: boolean;
};

export function MarketWidget({
    data,
    timeframe,
    accordionHandler,
    accordionState,
}: MarketWidgetProps) {
    return (
        <>
            <div
                className="tNews-accordionTitle"
                onClick={() => accordionHandler()}
            >
                <h2 className="tNews-accordionTitle-title">
                    Aggregate universe - Markets & Sectors
                    <div className="tooltip-wrapper-icon">
                        <InfoTooltip
                            text={
                                "An overview on the investment universes recording the largest number of rating changes , tracking the rotation of money flow."
                            }
                            title={"Aggregated Universe – Markets and Sectors"}
                            callToAction={
                                "Bring your portfolio risk management to the next level"
                            }
                        />
                    </div>
                </h2>
                <span
                    className={`i-${
                        accordionState ? "down" : "up"
                    } tNews-accordionIcon`}
                ></span>
            </div>

            {accordionState && (
                <>
                    {data?.length ? (
                        <div className="table-peers-wrapper">
                            <TablePeers data={data} timeframe={timeframe} />
                        </div>
                    ) : (
                        <div className="empty-news-data">No alert found</div>
                    )}
                </>
            )}
        </>
    );
}
