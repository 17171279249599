import { AppEnvironment } from "../../types/Defaults";
import { _Base } from "../_Base";

export class Import extends _Base {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(environment: AppEnvironment) {
        super(environment);
    }

    async upload(url, params) {
        // axios.post(url, params.data);
        const resp = await this.preparePost(url, params.data);
        // const resp = await fetch(url, params);
        return resp;
    }

    separator() {
        var list = ["a", "b"];
        var listLocaleString = list.toLocaleString();
        return listLocaleString[1];
    }
}
