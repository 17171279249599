import { _StoredObjects } from "../../../../api/_StoredObjects";
import { Publications } from "../../../../api/compute/Publications";
import { Subscriptions } from "../../../../api/compute/Subscriptions";
import { AppEnvironment } from "../../../../types/Defaults";

export class RankUtilities {
  private environment: AppEnvironment;
  private preferencesAPI: _StoredObjects;
  private subscriptionsAPI: Subscriptions;
  private publicationsAPI: Publications;

  constructor(environment: AppEnvironment) {
    this.environment = environment;
    this.preferencesAPI = new _StoredObjects(environment);
    this.subscriptionsAPI = new Subscriptions(environment);
    this.publicationsAPI = new Publications(environment);
  }

  PREFERENCE_TYPE: "RANKING_ABOUT_TARGET" = "RANKING_ABOUT_TARGET";

  async getUserRanks() {
    const preferencesAPI = this.preferencesAPI;

    // 1. Get all preference ids of type RANKING_ABOUT_TARGET
    const ids = await preferencesAPI.select(this.PREFERENCE_TYPE);

    const subscribedRank = await this.subscriptionsAPI.get({ type: "ranking" });

    if (subscribedRank && subscribedRank.length) {
      const subscribedIds = subscribedRank.map((object) => object.id);
      ids.push(...subscribedIds);
    }

    // 2. Fetch name, ownerId and id of the preferences to prepare the <Brick /> button component
    const bricksData = await preferencesAPI.fetch({
      objectType: this.PREFERENCE_TYPE,
      ids,
      properties: ["ownerId", "name", "id"],
    });

    // Check if exist a last used template saved before we changed the behaviour of the last used mechanism and delete the template
    const userId = this.environment?.["account"]?.["user"]?.["id"];
    const lastUsedTag = "LAST_USED_RANK_" + userId;
    const lastUsedObject = bricksData.find((item) => item.name === lastUsedTag);
    const lastUsedId = lastUsedObject?.id ?? undefined;

    if (lastUsedId != null) {
      await this.deleteLastUsedTemplate({ id: lastUsedId });

      await this.getUserRanks();
    }

    return bricksData;
  }

  async getUserRanksIds() {
    const preferencesAPI = this.preferencesAPI;
    const ids = await preferencesAPI.select(this.PREFERENCE_TYPE);

    return ids;
  }

  async getSubscribedRanks() {
    const subscribedRank = await this.subscriptionsAPI.get({ type: "ranking" });

    return subscribedRank.map((sub) => sub.id);
  }

  async getPublicRanksIds() {
    const pubsIds = await this.publicationsAPI.select({ type: "ranking" });
    return pubsIds.map((pub) => pub.id);
  }

  async fetchRank(ids, properties) {
    const preferencesAPI = this.preferencesAPI;

    const data = await preferencesAPI.fetch({
      objectType: this.PREFERENCE_TYPE,
      ids,
      properties,
    });

    return data;
  }

  async getRankObject(id) {
    return await this.preferencesAPI.get(id, this.PREFERENCE_TYPE);
  }

  async deleteLastUsedTemplate(lastUsedTemplate) {
    if (lastUsedTemplate) {
      await this.preferencesAPI.remove(lastUsedTemplate);
    }
  }
}
