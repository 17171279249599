/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateTableScreening
 * @summary Requests for table screening templates
 *
 */

import { _TemplateTable } from "./_TemplateTable";

export class TemplateTableScreening extends _TemplateTable {
    storedObjectType = "TEMPLATE_TABLE_SCREENING" as const;
}
