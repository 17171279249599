import { Box, Card, CardContent } from "@mui/material";
import styles from "./AnalisysSecurity.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Storage } from "./Storage";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { deepClone } from "../../../../deepClone";
import ChartNew from "./widgets/ChartNew";
import { ErrorBoundary } from "../../../../ErrorBoundary";

type TabHistoricalProps = {
  value: any;
};

type InstruementsState = {
  instrument: {
    information: any;
    history: any;
  };
  benchmark: {
    information: any;
    history: any;
  };
  others: any[];
};

export function TabHistorical({ value }: TabHistoricalProps) {
  const [instruments, setInstruments] = useState<InstruementsState>({
    instrument: {
      information: null,
      history: null,
    },
    benchmark: {
      information: null,
      history: null,
    },
    others: [],
  });

  const environment = useEnvironment();

  const storage = useMemo(
    () => new Storage(environment.get("setup")),
    [environment]
  );

  const prepareValue = useCallback(() => {
    const instrument = deepClone(instruments);

    if (
      instrument?.["instrument"]?.["history"] != null &&
      instrument?.["instrument"]?.["history"]?.length
    ) {
      const value: any = [];
      const valueItem = {};

      valueItem["data"] = instrument?.["instrument"]?.["information"] ?? null;
      valueItem["prices"] = instrument?.["instrument"]?.["history"] ?? null;
      valueItem["name"] =
        instrument?.["instrument"]?.["information"]?.["ticker"] ?? null;
      valueItem["id"] =
        instrument?.["instrument"]?.["information"]?.["symbol"] ?? null;

      value.push(valueItem);

      return value;
    }
  }, [instruments]);

  const chartValue = useMemo(() => {
    const value = prepareValue();

    if (value != null && value.length) {
      return value;
    }

    return undefined;
  }, [prepareValue]);

  const dataDisplay = useCallback((state) => {
    setInstruments(deepClone(state));
  }, []);

  const dataPrepare = useCallback(
    (response) => {
      const state = {
        instrument: {
          information: null,
          history: null,
        },
        benchmark: {
          information: null,
          history: null,
        },
        others: [],
      };

      state["instrument"] = {
        information: value,
        history: response,
      };

      dataDisplay(state);
    },
    [dataDisplay, value]
  );

  const dataGet = useCallback(async () => {
    const instrument = value;
    const response = await storage.instrumentHistory(instrument);

    dataPrepare(response);
  }, [dataPrepare, storage, value]);

  const onPageMount = useCallback(() => {
    dataGet();
  }, [dataGet]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return (
    <ErrorBoundary fallback={<></>}>
      <Box className={styles.tabHistorical__main}>
        <Card className={styles.tabHistorical__main__card}>
          <CardContent className={styles.tabHistorical__main__card__content}>
            {chartValue != null && (
              <Box height={"100%"} width={"100%"}>
                <ChartNew value={chartValue} />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </ErrorBoundary>
  );
}
