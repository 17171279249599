import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { format as dateFormat } from "date-fns";
import { useMemo } from "react";

type DatepickerProps = {
  input: any;
  onChangeDate: Function;
  disableFuture?: boolean;
  isValid?: boolean;
  disabled?: boolean;
};
const DatePicker = ({
  input,
  onChangeDate,
  disableFuture,
  isValid,
  disabled = false,
}: DatepickerProps) => {
  const _input = useMemo(() => input, [input]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disabled={disabled}
        onChange={(value: any, keyboardInputValue: string | undefined) => {
          if (value != null) {
            if (value.$d instanceof Date && isFinite(value.$d)) {
              const returningDate = dateFormat(value.$d, "yyyy-MM-dd");
              onChangeDate(returningDate);
              return;
            }
          }
          onChangeDate(null);
        }}
        inputFormat="YYYY-MM-DD"
        value={_input}
        renderInput={(params) => (
          <TextField
            error={isValid != null ? isValid : false}
            sx={{
              svg: { color: "#2a7092" },
              "& .Mui-focused > .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2a7092 !important",
              },
            }}
            size="small"
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
