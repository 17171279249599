import {
  Box,
  Button,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { Properties } from "../../../../../../api/Properties";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import styles from "./EditorTable.module.scss";
import { Rule } from "./Rule";
import { useDragAndDrop } from "../../../../../../hooks/useDragAndDrop";

type EditorTableProps = {
  rankingTemplate: any;
  getUpdatedRules: (rules) => void;
  getIsTemplateChanged: (isChanged) => void;
};

const valueToLabel = (objectLiteral, options) => {
  var option: any = null;
  for (let i = 0, length = options.length; i < length; i++) {
    option = options[i];
    if (
      option.hasOwnProperty("children") &&
      option["children"] !== undefined &&
      option["children"] != null
    ) {
      valueToLabel(objectLiteral, option["children"]);
    } else {
      objectLiteral[option["value"]["subject"]] = option;
    }
  }
};

export function EditorTable({
  rankingTemplate,
  getUpdatedRules,
  getIsTemplateChanged,
}: EditorTableProps) {
  const [rules, setRules] = useState<{ r: Rule; i: number; value: any }[]>([]);
  const [rowActive, setRowActive] = useState<number | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isAddingRule, setIsAddingRule] = useState(false);

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const { dragEnter, dragOut, dragOver, dragStart, drop } =
    useDragAndDrop(rules);

  const onDrop = useCallback(
    (newRules) => {
      for (let i = 0; i < newRules.length; i++) {
        newRules[i]["i"] = i;
      }

      setRules(newRules);
      getIsTemplateChanged(true);
    },
    [getIsTemplateChanged]
  );

  const pageConfiguration = useMemo(
    () => appSetup["configuration"].get("analysisList"),
    [appSetup]
  );
  const configurationWidgetRanking = useMemo(
    () =>
      pageConfiguration["tabs"][pageConfiguration["tabsIndex"]["ranking"]][
        "widgets"
      ]["ranking"],
    [pageConfiguration]
  );
  const options = useMemo(
    () => ({
      create: configurationWidgetRanking["create"]["options"],
      edit: configurationWidgetRanking["edit"]["options"],
    }),
    [configurationWidgetRanking]
  );
  const templateRules = useMemo(
    () => rankingTemplate?.configuration?.ranking,
    [rankingTemplate?.configuration?.ranking]
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-ranking-rules" : undefined;

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buildRule = useCallback(
    (ruleValue) => {
      var optionsEdit = options["edit"];
      ruleValue = ruleValue !== undefined ? ruleValue : null;

      const rule = new Rule(optionsEdit, ruleValue);
      return rule;
    },
    [options]
  );

  const addRule = useCallback(
    (ruleInfo, i, rules) => {
      const rule = buildRule(ruleInfo);

      if (rule) {
        rules.push({ r: rule, i, value: rule.get() });
      }
    },
    [buildRule]
  );

  const loadTemplate = useCallback(() => {
    const rules: any = [];

    let rule: any = null;

    if (templateRules) {
      for (let i = 0; i < templateRules.length; i++) {
        rule = templateRules[i];

        addRule(rule, i, rules);
      }

      setRules(rules);
    }
  }, [addRule, templateRules]);

  const updateRules = useCallback(
    (newRule: { r: Rule; i: number; value: any }) => {
      setIsAddingRule(false);
      setRules((currentRules) => {
        const newRules: { r: Rule; i: number; value: any }[] = [];

        for (const rule of currentRules) {
          if (rule.i !== newRule.i) {
            newRules.push(rule);
          } else {
            newRules.push(newRule);
          }
        }

        return newRules;
      });
      setRowActive(undefined);
      getIsTemplateChanged(true);
    },
    [getIsTemplateChanged]
  );

  const onDeleteRow = useCallback(
    (rowIndex) => {
      let newRules = [...rules];

      newRules = newRules.filter((row) => row.i !== rowIndex);
      let r: any = null;

      for (let i = 0; i < newRules.length; i++) {
        r = newRules[i];

        r.i = i;
      }

      setRules(newRules);
      getIsTemplateChanged(true);
      setRowActive(undefined);
    },
    [getIsTemplateChanged, rules]
  );

  useEffect(() => {
    loadTemplate();
  }, [loadTemplate]);

  const onMoveUp = useCallback(
    (index) => {
      setRules((currentRules) => {
        const newRules = [...currentRules];

        const row = newRules[index];

        newRules.splice(index, 1);
        newRules.splice(index - 1, 0, row);

        let rule: any = null;

        for (let i = 0; i < newRules.length; i++) {
          rule = newRules[i];

          rule.i = i;
        }

        return newRules;
      });
      getIsTemplateChanged(true);
    },
    [getIsTemplateChanged]
  );

  const onMoveDown = useCallback(
    (index) => {
      setRules((currentRules) => {
        const newRules = [...currentRules];

        const row = newRules[index];

        newRules.splice(index, 1);
        newRules.splice(index + 1, 0, row);

        let rule: any = null;

        for (let i = 0; i < newRules.length; i++) {
          rule = newRules[i];

          rule.i = i;
        }

        return newRules;
      });
      getIsTemplateChanged(true);
    },
    [getIsTemplateChanged]
  );

  const optionToRuleValue = useCallback((option) => {
    const prototype = {
      function: option?.functions?.[0]?.value,
      functionParams: option?.functions?.[0]?.widget
        ? { value: option?.functions?.[0]?.widget?.value }
        : null,
      operator: option?.operators?.[0]?.[0]?.value ?? null,
      operatorParams: { value: option?.operators?.[0]?.[0]?.widget?.value },
      property: option?.property?.value,
    };

    return prototype;
  }, []);

  const openEditorOnLastAdded = useCallback(() => {
    const activeRowIndex = rules.length;

    setRowActive(activeRowIndex);
  }, [rules.length]);

  const onAddRow = useCallback(
    (option) => {
      setIsAddingRule(true);
      const ruleValue = optionToRuleValue(option);
      const rule = buildRule(ruleValue);

      setRules((currentRules) => {
        const newRules = [...currentRules];

        const ruleToPush = { r: rule, i: newRules.length, value: rule.get() };

        newRules.push(ruleToPush);

        return newRules;
      });

      setAnchorEl(null);
      getIsTemplateChanged(true);
      openEditorOnLastAdded();
    },
    [buildRule, getIsTemplateChanged, openEditorOnLastAdded, optionToRuleValue]
  );

  useEffect(() => {
    const updatedRules: any = [];
    let optionsIndex = null;

    for (const rule of rules) {
      const ruleValue = rule.value;

      optionsIndex = rule.r.getOptionIndex();

      const functionSubject = ruleValue?.widgetFunction?.value?.["subject"];
      const propertyValue = ruleValue?.widgetProperty?.value;
      const functionIndex =
        optionsIndex?.[propertyValue]["functions"][functionSubject];

      if (functionIndex != null) {
        const operator = Object.keys(
          optionsIndex![propertyValue]?.["operators"]?.[functionIndex]
        )?.[0];

        const value = {
          function: functionSubject,
          functionParams: ruleValue?.widgetFunction?.value["augmentation"],
          operator: operator,
          operatorParams: {
            value: ruleValue?.widgetSortBy?.value,
          },
          property: propertyValue,
        };

        updatedRules.push(value);
      }
    }

    getUpdatedRules(updatedRules);
  }, [getUpdatedRules, rules]);

  return (
    <ErrorBoundary fallback={<></>}>
      <ul className={styles.editorList}>
        {rules.map((rule) => {
          return (
            <EditorRow
              dragAndDropListeners={{
                dragEnter,
                dragOut,
                dragStart,
                dragOver,
                drop: (e) => drop(e, onDrop),
              }}
              setIsAddingRule={setIsAddingRule}
              isAddingRule={isAddingRule}
              rowActive={rowActive}
              setRowActive={setRowActive}
              rulesLen={rules.length}
              onMoveDown={onMoveDown}
              onMoveUp={onMoveUp}
              key={uuidv4()}
              onDeleteRow={onDeleteRow}
              params={rule}
              onFinishEditing={updateRules}
            />
          );
        })}
      </ul>
      <Box display={"flex"} justifyContent={"flex-end"} p={1}>
        <Button variant="contained" onClick={openPopover}>
          Add Rule
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box key={uuidv4()} display={"flex"} p={1} flexDirection={"column"}>
            <ul>
              {options.create.map((option) => {
                return (
                  <li
                    onClick={(e) => onAddRow(option)}
                    className={`${styles["popover-list-item"]}`}
                    key={uuidv4()}
                  >
                    {option?.property?.label ?? ""}
                  </li>
                );
              })}
            </ul>
          </Box>
        </Popover>
      </Box>
      <Box p={1}>
        <Typography sx={{ color: "#5f6368" }}>
          * Click to edit, drag and drop to change rules order
        </Typography>
      </Box>
      <Box
        className={`${styles.blurModal} ${
          rowActive != null ? styles.blurModal_active : ""
        }`}
      ></Box>
    </ErrorBoundary>
  );
}

const EditorRow = ({
  params,
  onFinishEditing,
  onDeleteRow,
  onMoveUp,
  onMoveDown,
  rulesLen,
  rowActive,
  setRowActive,
  isAddingRule,
  setIsAddingRule,
  dragAndDropListeners,
}: {
  params: { r: Rule; i: number; value: any };
  onFinishEditing: (rule: { r: Rule; i: number; value: any }) => void;
  onDeleteRow: (i: number) => void;
  onMoveDown: (index: number) => void;
  onMoveUp: (index) => void;
  rulesLen: number;
  rowActive: number | undefined;
  setRowActive: (n) => void;
  isAddingRule: boolean;
  setIsAddingRule: (value) => void;
  dragAndDropListeners: {
    dragStart: (e) => void;
    dragEnter: (e) => void;
    dragOut: (e) => void;
    dragOver: (e) => void;
    drop: (e, callback?) => void;
  };
}) => {
  const rule = useMemo(() => params.r, [params.r]);

  const [rowRule, setRowRule] = useState(params.value);
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);

  const propertyValue = useMemo(
    () => rowRule?.widgetProperty?.value,
    [rowRule?.widgetProperty?.value]
  );
  const functionValue = useMemo(
    () => rowRule?.widgetFunction?.value,
    [rowRule?.widgetFunction?.value]
  );
  const sortByValue = useMemo(
    () => rowRule?.widgetSortBy?.value,
    [rowRule?.widgetSortBy?.value]
  );

  // const propertyOptions = useMemo(() => {
  //   return rowRule?.widgetProperty?.options.filter(
  //     (item) => item.value !== rowRule.widgetProperty.value
  //   );
  // }, [rowRule?.widgetProperty?.options, rowRule?.widgetProperty.value]);
  const functionOptions = useMemo(() => {
    return rowRule?.widgetFunction?.options.filter(
      (item) => item.value !== rowRule.widgetFunction.value.subject
    );
  }, [rowRule?.widgetFunction?.options, rowRule?.widgetFunction.value]);
  const sortByOptions = useMemo(() => {
    return rowRule?.widgetSortBy?.options.filter(
      (item) => item.value !== rowRule.widgetSortBy.value
    );
  }, [rowRule?.widgetSortBy?.options, rowRule?.widgetSortBy.value]);

  const handleRowClick = useCallback(
    () => setRowActive(params.i),
    [params.i, setRowActive]
  );

  const deleteRow = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteRow(params.i);
    },
    [onDeleteRow, params.i]
  );

  const closeRowEditor = useCallback(
    (e) => {
      e.stopPropagation();

      if (isAddingRule) {
        deleteRow(e);
        setIsAddingRule(false);
      } else {
        setRowRule(params.value);
        setRowActive(undefined);
      }
    },
    [deleteRow, isAddingRule, params.value, setIsAddingRule, setRowActive]
  );

  const fnOptions = useMemo(() => {
    const opts = deepClone(rowRule?.widgetFunction?.options);

    for (let i = 0, length = opts.length; i < length; i++) {
      opts[i]["value"] = {
        augmentation: null,
        subject: opts[i]["value"],
      };
    }

    const _options = {};
    valueToLabel(_options, opts);

    const areOptionsEmpty = Object.keys(_options)?.length === 0;

    return areOptionsEmpty === true ? null : _options;
  }, [rowRule?.widgetFunction?.options]);

  const updateFnWidgetRule = useCallback(
    (value) => {
      if (value) {
        if (value.augmentation) {
          if (value?.augmentation?.value == null) {
            setIsApplyDisabled(true);
          } else {
            setIsApplyDisabled(false);
          }
        } else {
          setIsApplyDisabled(false);
        }

        const property = propertyValue;
        const optionsIndex = rule.getOptionIndex();
        const ruleOptions = rule.getOptions();

        const indexProperty = optionsIndex[property]["property"];
        const indexFunction =
          optionsIndex[property]["functions"][value["subject"]];

        const options = ruleOptions["operators"][indexProperty][indexFunction];

        setRowRule((current) => {
          if (current) {
            const updatedRule = deepClone(current);

            updatedRule.widgetSortBy.options = options;
            updatedRule.widgetSortBy.value = options[0]["value"];

            updatedRule.widgetFunction.value = value;

            return updatedRule;
          }

          return undefined;
        });
      }
    },
    [propertyValue, rule]
  );

  const onSelectFn = useCallback(
    (e, option) => {
      e.stopPropagation();
      const fn = fnOptions?.[option?.["value"]];

      const value =
        "type" in fn && fn.type === "augmented"
          ? {
              subject: fn.value.subject,
              augmentation: { value: fn.widget.value },
            }
          : fn.value;

      updateFnWidgetRule(value);
    },
    [fnOptions, updateFnWidgetRule]
  );

  const onFunctionValueChange = useCallback(
    (value) => {
      updateFnWidgetRule(value);
    },
    [updateFnWidgetRule]
  );

  // const onPropertyChange = useCallback(
  //   (value) => {
  //     const optionsIndex = rule.getOptionIndex();
  //     const rulesOptions = rule.getOptions();

  //     const index = optionsIndex[value]["property"];
  //     // functions
  //     const options = rulesOptions["functions"][index];

  //     setRowRule((current) => {
  //       if (current) {
  //         const newRule = deepClone(current);

  //         newRule.widgetProperty.value = value;

  //         newRule.widgetFunction["options"] = options;
  //         newRule.widgetFunction["value"] = {
  //           augmentation: null,
  //           subject: options[0]["value"],
  //         };

  //         newRule.widgetSortBy["options"] = rulesOptions["operators"][index][0];
  //         newRule.widgetSortBy["value"] =
  //           rulesOptions["operators"][index][0][0]["value"];

  //         return newRule;
  //       }
  //     });
  //   },
  //   [rule]
  // );

  const onSelectSortByValue = useCallback((value) => {
    setRowRule((current) => {
      if (current) {
        const newRule = deepClone(current);

        newRule.widgetSortBy.value = value;

        return newRule;
      }
    });
  }, []);

  const getRule = useCallback(() => {
    if (rowRule) {
      const ruleCopy = deepClone(rowRule);
      const optionsIndex = rule.getOptionIndex();
      const widgetPropertyValue = ruleCopy.widgetProperty.value;
      const widgetFunctionValue = ruleCopy.widgetFunction.value;
      const widgetSortByValue = ruleCopy.widgetSortBy.value;

      var indexFunction =
        optionsIndex[widgetPropertyValue]["functions"][
          widgetFunctionValue["subject"]
        ];

      // valid because ranking has only 1 operator per function.
      // For a more generic implementation see Selection widgets
      var operator = Object.keys(
        optionsIndex[widgetPropertyValue]["operators"][indexFunction]
      )[0];

      var value = {
        function: widgetFunctionValue["subject"],
        functionParams: widgetFunctionValue["augmentation"],
        isRemovable: true,
        operator: operator,
        operatorParams: {
          value: widgetSortByValue,
        },
        property: widgetPropertyValue,
      };

      return value;
    }
  }, [rowRule, rule]);

  const onApplyChanges = useCallback(
    (e) => {
      e.stopPropagation();
      const ruleUpdated = getRule();
      rule.updateValue(ruleUpdated);
      onFinishEditing({ r: rule, i: params.i, value: rule.get() });
    },
    [getRule, onFinishEditing, params.i, rule]
  );

  const onMoveRuleDown = useCallback(
    (e) => {
      e.stopPropagation();
      onMoveDown(params.i);
    },
    [onMoveDown, params.i]
  );

  const onMoveRuleUp = useCallback(
    (e) => {
      e.stopPropagation();
      onMoveUp(params.i);
    },
    [onMoveUp, params.i]
  );

  const appendixDict = {
    1: "st",
    2: "nd",
    3: "rd",
    default: "th",
  };

  return (
    <li
      draggable={rowActive !== params.i}
      id={`${params.i}`}
      onDragStart={dragAndDropListeners.dragStart}
      onDragEnter={dragAndDropListeners.dragEnter}
      onDragLeave={dragAndDropListeners.dragOut}
      onDragOver={dragAndDropListeners.dragOver}
      onDragEnd={dragAndDropListeners.drop}
      title="Click to edit, drag and drop to change rules order"
      onClick={handleRowClick}
      className={`${styles.editorListRowWrap} ${
        rowActive === params.i ? styles.activeRow : ""
      }`}
    >
      <Box className={styles.editorListRow}>
        <Box className={styles.editorListRowCellWrapperProperty}>
          <Typography sx={{ fontSize: "0.8vw" }}>
            {params.i + 1}
            <sup>
              {params.i + 1 < 4
                ? appendixDict[params.i + 1]
                : appendixDict["default"]}
            </sup>{" "}
            Level
          </Typography>
          {/* {rowActive === params.i && (
          <ul>
            {propertyOptions.map((item) => {
              return (
                <li key={uuidv4()} onClick={() => onPropertyChange(item.value)}>
                  {item.label}
                </li>
              );
            })}
          </ul>
        )} */}
        </Box>
        <Box
          className={`${styles.editorListRowCellWrapper} ${styles.editorListRowCellWrapperProperty}`}
        >
          <PropertyCell
            value={propertyValue}
            isActive={rowActive === params.i}
          />
          {/* {rowActive === params.i && (
          <ul>
            {propertyOptions.map((item) => {
              return (
                <li key={uuidv4()} onClick={() => onPropertyChange(item.value)}>
                  {item.label}
                </li>
              );
            })}
          </ul>
        )} */}
        </Box>

        <Box className={styles.editorListRowCellWrapperProperty}>
          <FunctionCell
            options={fnOptions}
            isActive={rowActive === params.i}
            value={functionValue}
            updateFnValue={onFunctionValueChange}
          />
          {rowActive === params.i && (
            <ul>
              {functionOptions.map((item) => {
                return (
                  <li onClick={(e) => onSelectFn(e, item)} key={uuidv4()}>
                    {item.label}
                  </li>
                );
              })}
            </ul>
          )}
        </Box>

        <Box
          className={styles.editorListRowCellWrapperProperty}
          position={"relative"}
        >
          <SortByCell
            functionValue={functionValue}
            isActive={rowActive === params.i}
            value={sortByValue}
            options={rowRule?.widgetSortBy?.options}
          />
          {rowActive === params.i && (
            <ul>
              {sortByOptions.map((item) => {
                return (
                  <li
                    onClick={() => onSelectSortByValue(item.value)}
                    key={uuidv4()}
                  >
                    {item.label}
                  </li>
                );
              })}
            </ul>
          )}
        </Box>

        <Box className={styles.editorListRowCellWrapper}>
          <Box>
            <span
              onClick={deleteRow}
              className={`i-delete ${styles.trashIconBtn}`}
            />
          </Box>
        </Box>
        <Box className={styles.editorListRowCellWrapper}>
          {params.i !== 0 && (
            <Box onClick={onMoveRuleUp}>
              <span className={`i-triangle-up ${styles.trashIconBtn}`} />
            </Box>
          )}
        </Box>
        <Box className={styles.editorListRowCellWrapper}>
          {params.i < rulesLen - 1 && (
            <Box onClick={onMoveRuleDown}>
              <span className={`i-triangle-down ${styles.trashIconBtn}`} />
            </Box>
          )}
        </Box>
      </Box>
      {rowActive === params.i && (
        <Box className={styles.editorFooter}>
          <Button
            variant="contained"
            onClick={onApplyChanges}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
          <Button variant="tr_button_cancel" onClick={closeRowEditor}>
            Cancel
          </Button>
        </Box>
      )}
    </li>
  );
};

const PropertyCell = ({ value, isActive }) => {
  const environment = useEnvironment();

  const properties = useMemo(
    () =>
      new Properties({ properties: environment.get("setup")["properties"] }),
    [environment]
  );

  return (
    <Box
      className={styles.editorListRowCell}
      style={isActive ? { color: "#2a7090", fontWeight: "bold" } : {}}
    >
      {properties.get(value, 0, "auto") ?? ""}
    </Box>
  );
};

const FunctionCell = ({ value, isActive, options, updateFnValue }) => {
  const label = useMemo(() => {
    var innerHTML = "-";

    if (options != null) {
      switch (value["subject"]) {
        case "quantile": {
          switch (value["augmentation"]["value"]) {
            case 4: {
              innerHTML = "Quartile";
              break;
            }
            case 5: {
              innerHTML = "Quintile";
              break;
            }
            case 10: {
              innerHTML = "Decile";
              break;
            }

            default:
              if (isActive) {
                innerHTML = "Quantile";
              } else {
                innerHTML = "Quantile (" + value["augmentation"]["value"] + ")";
              }
          }
          break;
        }
        case "outlier": {
          const option = options[value["subject"]];
          if (option["labelLong"]) {
            innerHTML = option["labelLong"];
          } else {
            innerHTML = option["label"];
          }

          if (
            value["augmentation"] != null &&
            value["augmentation"]["value"] != null &&
            !isActive
          ) {
            var outlierPercentValue = (
              parseFloat(value["augmentation"]["value"]) * 100
            ).toFixed(0);
            innerHTML += " (" + outlierPercentValue + "%)";
          }

          break;
        }
        default: {
          const option = options[value["subject"]];
          if (option["labelLong"]) {
            innerHTML = option["labelLong"];
          } else {
            innerHTML = option["label"];
          }
        }
      }
    }

    return innerHTML;
  }, [isActive, options, value]);

  const valueInfo = useMemo(() => options[value["subject"]], [options, value]);

  const onValueChange = useCallback(
    (fnValue) => {
      const currentValue = deepClone(value);
      currentValue["augmentation"]["value"] = fnValue;

      updateFnValue(currentValue);
    },
    [updateFnValue, value]
  );

  return (
    <Box
      className={styles.editorListRowCell}
      style={isActive ? { color: "#2a7090", fontWeight: "bold" } : {}}
    >
      <Box display={"flex"} alignItems={"center"} gap={1}>
        {label ?? ""}

        {isActive && (
          <Box maxWidth={"50%"}>
            <Augmentation
              value={value}
              params={valueInfo}
              updateValue={onValueChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SortByCell = ({ value, isActive, options, functionValue }) => {
  const label = useMemo(() => {
    if (options) {
      const optionsMap = options.reduce((prev, current) => {
        prev[current.value] = current.label;

        return prev;
      }, {});

      if (functionValue.subject === "quantile") {
        const sortLabel = optionsMap?.[value] ?? "";

        let quantileLabel = "";

        switch (functionValue["augmentation"]["value"]) {
          case 4: {
            quantileLabel = "quartile";
            break;
          }
          case 5: {
            quantileLabel = "quintile";
            break;
          }
          case 10: {
            quantileLabel = "decile";
            break;
          }

          default: {
            quantileLabel = "quantile";
          }
        }

        const updatedLabel = sortLabel.replace("quantile", quantileLabel);

        return updatedLabel;
      }

      return optionsMap?.[value] ?? "";
    }

    return "";
  }, [functionValue, options, value]);

  return (
    <Box
      className={styles.editorListRowCell}
      style={isActive ? { color: "#2a7090", fontWeight: "bold" } : {}}
    >
      {label}
    </Box>
  );
};

const Augmentation = ({ value, params, updateValue }) => {
  if (params && params.type === "augmented") {
    return (
      <AugmentationInput
        key={params.value["subject"]}
        subject={params.value["subject"]}
        updateValue={updateValue}
        type={params.widget.type}
        constraints={params.widget.constraints}
        initValue={value?.augmentation?.value}
      />
    );
  }

  return <></>;
};

const AugmentationInput = ({
  subject,
  type,
  constraints,
  initValue,
  updateValue,
}) => {
  const isInt = useMemo(() => subject !== "outlier", [subject]);
  const startValue = isInt ? initValue ?? 0 : (initValue ?? 0) * 100;

  const [inputValue, setInputValue] = useState(startValue);

  const [error, setError] = useState(false);

  const isValid = useCallback(
    (v) => {
      if (constraints) {
        const min = constraints?.min ?? -9999999999;
        const max = constraints?.max ?? 9999999999;

        const value = isInt ? v : v / 100;
        const isInRange = value >= min && value <= max;

        return isInRange;
      } else {
        return true;
      }
    },
    [constraints, isInt]
  );

  useEffect(() => {
    if (constraints) {
      const isInRange = isValid(inputValue);

      if (!isInRange) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [constraints, inputValue, isValid]);

  const onChangeValue = useCallback(
    (e) => {
      setInputValue(e.target.value);
      const parsed = isInt
        ? parseInt(e.target.value)
        : parseFloat(e.target.value);
      const value = e.target.value ? parsed : null;

      if (value != null && isValid(value)) {
        updateValue(isInt ? value : value / 100);
      } else {
        updateValue(null);
      }
    },
    [isInt, isValid, updateValue]
  );

  if (type === "number") {
    return (
      <Box
        component={"div"}
        sx={{
          "& > :not(style)": { m: 1, width: "auto" },
        }}
        display={"flex"}
        gap={"4px"}
        alignItems={"center"}
      >
        <TextField
          type={"number"}
          error={error}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingRight: "2px!important",
            },
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "input[type=number]": {
              MozAppearance: "textfield",
            },
            margin: "0!important",
          }}
          InputProps={
            !isInt
              ? {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  style: {
                    paddingRight: "0!important",
                  },
                }
              : {}
          }
          inputProps={{
            className: styles.inputNumber,
            step: isInt ? 1 : 0.01,
          }}
          onChange={onChangeValue}
          id="outlined-basic"
          variant="outlined"
          value={inputValue ?? ""}
          size={"small"}
        />
      </Box>
    );
  } else {
    return <></>;
  }
};
