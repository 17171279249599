import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PanelForLists } from "../../../../../../../../../../../components/PanelForLists/PanelForLists";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { Spinner } from "./Spinner";

type WhiteListPanelProps = {
  showPortfolio?: boolean;
  showBasket?: boolean;
  setWhiteListValue: (listId) => void;
  initValue: number | null;
  label?: string;
  changeBtnLabel?: string;
  subject?: string | null;
  closeIcon?: boolean;
};

const getArtcle = (subject: string | null) => {
  if (subject && subject !== "") {
    let _subject = subject.toLowerCase().trim();
    const vowels = ["a", "e", "i", "o", "u"];
    if (vowels.includes(_subject[0])) {
      return "an";
    } else {
      return "a";
    }
  }
  return "";
};

export function WhiteListPanel({
  showPortfolio = true,
  showBasket = true,
  closeIcon = false,
  setWhiteListValue,
  initValue,
  label,
  changeBtnLabel,
  subject,
}: WhiteListPanelProps) {
  const [listID, setListID] = useState<null | number>(null);
  useEffect(() => {
    setListID(initValue);
  }, [initValue]);

  const _subject = useMemo(() => {
    return subject ?? "";
  }, [subject]);
  const environment = useEnvironment();
  const userId = environment.get("setup")?.account?.user?.id;
  const apiList = environment.get("http")["lists"];
  const getActualListData = useCallback(
    async (listID) => {
      try {
        setIsLoading(true);
        setError(false);
        const listData = await apiList.portfolioFetch(
          [listID],
          ["name", "ownerId"]
        );
        if (listData[0]) {
          listData[0]["isReadOnly"] = listData[0].ownerId !== userId;
          delete listData[0]["ownerId"];
        }
        setSelectedList(listData[0]);
        setIsLoading(false);
        setError(false);
      } catch (err) {
        setIsLoading(false);
        setError(true);
      }
    },
    [apiList, userId]
  );

  const [selectedList, setSelectedList] = useState<any>(null);

  const [userLists, setUserLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (listID) {
      getActualListData(listID);
    }
  }, [getActualListData, listID]);

  const getLists = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);
      const lists = await apiList.get();
      setUserLists(lists.data);
      setIsLoading(false);
      setError(false);
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  }, [apiList]);

  useEffect(() => {
    if (initValue !== null) {
      // if a initial value is provided fetch users lists to expose the name of the list
      // that corresponds to it, otherwise the fetch will start when the button will be clicked
      // getLists();

      return () => {
        setIsLoading(false);
        setUserLists([]);
        setError(false);
      };
    }
  }, [initValue]);

  useEffect(() => {
    if (listID != null) {
      setWhiteListValue(listID);
    }
  }, [listID, setWhiteListValue]);

  const handleDialogOpening = useCallback(async () => {
    getLists().then(() => setShowDialog(true));
    getLists().then(() => setShowDialog(true));
  }, [getLists]);

  const listForPanel = useMemo(
    () =>
      userLists
        ?.map((list: any) => ({
          name: list.name,
          id: list.id,
          type:
            list.type.toLowerCase() === "portfolio" ? "Portfolios" : "Baskets",
          isSubscribed: list.isReadOnly ?? false,
        }))
        ?.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        ) ?? [],
    [userLists]
  );
  const categories = useMemo(() => {
    let arr: string[] = [];
    if (showPortfolio) {
      arr.push("Portfolios");
    }
    if (showBasket) {
      arr.push("Baskets");
    }
    return arr;
  }, [showBasket, showPortfolio]);

  return (
    <Box display={"flex"}>
      {selectedList != null && <SelectedItemLabel item={selectedList} />}
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mr={2}
        >
          <Spinner />
          {/* <Typography>Loading ...</Typography> */}
        </Box>
      ) : !error ? (
        <PanelForLists
          list={listForPanel}
          showDialog={showDialog}
          closeDialog={() => setShowDialog(false)}
          sectionsTag={categories}
          itemsPerColumn={20}
          selectItem={setListID}
          headerTitle={`Select ${getArtcle(_subject)} ${_subject}`}
          closeIcon={closeIcon}
        />
      ) : (
        <Typography sx={{ color: "red" }}>
          <strong>
            An error occured while loading user portfolios and baskets
          </strong>
        </Typography>
      )}

      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "#2a7092",
          "&:hover": {
            backgroundColor: "#00496d",
          },
          textTransform: "capitalize",
          marginRight: 1,
        }}
        disabled={error || isLoading}
        onClick={handleDialogOpening}
        type="button"
      >
        {selectedList == null
          ? label ?? `Select ${getArtcle(_subject)} ${_subject}`
          : changeBtnLabel ?? `Change ${_subject}`}
      </Button>
    </Box>
  );
}

const SelectedItemLabel = ({ item }) => {
  return item ? (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      mr={2}
    >
      <Typography sx={{ fontSize: "0.8vw" }} color={"primary"}>
        {item["name"]}{" "}
        {item.isReadOnly && (
          <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
        )}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};
