import React, { useMemo } from "react";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { Properties } from "../../../../../api/Properties";
import { useTranslation } from "react-i18next";
import { _formatAction } from "./utils";
import { ColumnDefinition } from "tabulator-tables";

type Props = {
  data: any[];
};

export default function DifferenceTable({ data }: Props) {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useFormatter();
  const { t } = useTranslation();
  const label = useMemo(() => {
    return new Properties({
      properties: envSetup["properties"],
    });
  }, [envSetup]);
  const differenceAllocationsColumns = useMemo(() => {
    let _columns: ColumnDefinition[] = [];

    if (!data) {
      return _columns;
    }
    _columns = [
      {
        field: "isin",
        title: label.get("isin", 0, null),
      },
      {
        field: "ticker",
        title: label.get("ticker", 0, null),
      },
      {
        field: "name",
        title: label.get("name", 0, null),
      },
      {
        field: "vc",
        title: label.get("vc", 0, null),
        formatter: (cell) => {
          return formatter.table("vc", "table", cell.getData());
        },
      },
      {
        field: "rc",
        title: label.get("rc", 0, null),
        formatter: (cell) => {
          return formatter.table("rc", "table", cell.getData());
        },
      },
      {
        field: "dr",
        formatter: (cell) => {
          return formatter.table("dr", "table", cell.getData());
        },
        title: label.get("dr", 0, null),
      },
      {
        field: "_synthAction",
        formatter: (cell) => {
          const data = cell.getData();
          const returningValue = _formatAction("HTML", data["_synthAction"], t);
          return returningValue ? returningValue.html : null;
        },
        title: "Action",
      },
      {
        field: "_synthActionValue",
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("number", {
            options: {
              hasPositiveSign: true,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: data["_synthActionValue"],
            valueHelper: null,
          });
        },
        title: "Quantity",
      },
    ];
    return _columns;
  }, [data, formatter, label, t]);
  return (
    <TrendratingTable
      disableDefaultRowClick
      columns={differenceAllocationsColumns ?? []}
      data={data ?? []}
      autoResize={true}
      tooltip={{ actions: { info: { enabled: true } } }}
      options={{
        ajaxSorting: false,
        columnHeaderVertAlign: "top",
      }}
    />
  );
}
