import { Card, CardContent } from "@mui/material";

export default function Help() {
  return (
    <Card
      sx={{
        width: "20%",
        boxShadow: 3,
        backgroundColor: "#f2f2f2",
      }}
    >
      <CardContent>
        <h3>Allocation constraints</h3>
        <br />
        <p>
          Decide the level of budget invested, the minimum and maximum weight for each position, and
          the maximum allocation permitted for a group such as market, sector, region etc.
        </p>
      </CardContent>
    </Card>
  );
}
