import { TemplateReportAlerts } from "../../../../../api/compute/TemplateReportAlerts";
import { TemplateReportPeer } from "../../../../../api/compute/TemplateReportPeer";
import { TemplateReportPortfolio } from "../../../../../api/compute/TemplateReportPortfolio";
import { TemplateReportRanking } from "../../../../../api/compute/TemplateReportRanking";
import { TemplateReportScreening } from "../../../../../api/compute/TemplateReportScreening";
import { TemplateReportStrategy } from "../../../../../api/compute/TemplateReportStrategy";
import { TemplateReportStrategyLongShort } from "../../../../../api/compute/TemplateReportStrategyLongShort";
import { TemplateReportSystematicPortfolios } from "../../../../../api/compute/TemplateReportSystematicPortfolios";
import { ColumnSet } from "../../../../../components/table/tableUtilities/ColumnSet";
import { deepClone } from "../../../../../deepClone";
import i18n from "../../../../../i18n";
import { Formatter } from "../../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../../types/Defaults";
import { Collector } from "../../../../trendrating-report/data/Collector";
import { DownloaderAndOpener } from "../../../../trendrating-report/downloader/DownloaderInOtherPage";
import { Generator } from "../../../../trendrating-report/generator/Generator";
import { Section } from "../../../../trendrating-report/widgets/Section";
import { FormOptions } from "../../../../trendrating-widgets/form/FormOptions";
import { _mergeWysiwygStateActions } from "../../../widgets/app-infrastructure/workflowBar/actions/report/utils";
import { themeBlueAndGrey } from "../../../widgets/dialog/report/themeBlueAndGrey-ts";

type ReportParamsType = {
  page: string;
  target: any;
  rankingCache?: any;
  strategyCache?: any;
  widgets?: any;
  storage?: any;
  usage?: any;
  timeframe?: any;
  trimOutliers?: any;
  dispersionByConstraints?: any;
  segment?: any;
  sortState?: any;
  title?: any;
  subject?: any;
  templateId: number | "ALERTS";
};

export class DirectReport {
  private environment: AppEnvironment;
  private reportParams: ReportParamsType | undefined;
  private http: {
    alertsReportTemplates: TemplateReportAlerts;
    portfolioReportTemplates: TemplateReportPortfolio;
    peerReportTemplates: TemplateReportPeer;
    rankingReportTemplates: TemplateReportRanking;
    screeningReportTemplates: TemplateReportScreening;
    strategyReportTemplates: TemplateReportStrategy;
    strategyLongShortReportTemplates: TemplateReportStrategyLongShort;
    systematicPortfoliosReportTemplates: TemplateReportSystematicPortfolios;
  };
  private formatter: Formatter;

  constructor(environment: AppEnvironment) {
    this.environment = environment;

    this.http = {
      alertsReportTemplates: new TemplateReportAlerts(environment),
      portfolioReportTemplates: new TemplateReportPortfolio(environment),
      peerReportTemplates: new TemplateReportPeer(environment),
      rankingReportTemplates: new TemplateReportRanking(environment),
      screeningReportTemplates: new TemplateReportScreening(environment),
      strategyReportTemplates: new TemplateReportStrategy(environment),
      strategyLongShortReportTemplates: new TemplateReportStrategyLongShort(
        environment
      ),
      systematicPortfoliosReportTemplates:
        new TemplateReportSystematicPortfolios(environment),
    };

    this.formatter = new Formatter(environment);
  }

  public setReportParams(params: ReportParamsType) {
    this.reportParams = params;
  }

  public async doAction() {
    if (!this.reportParams) {
      console.error(
        "Missing report params, to print a report please pass right params"
      );

      return;
    }

    const params = this.reportParams;
    const _uiState: any = this.getUIState(params);
    const _wysiwygState: any = _uiState?.wysiwygState;
    const alertsTemplates = await this.getDefaultTemplates();

    const template =
      params.templateId === "ALERTS"
        ? alertsTemplates[0]
        : await this.httpTemplates(params.page)?.get(params.templateId, 35);

    const templateToPrint = this.prepareTemplateForPrint(
      template,
      _wysiwygState
    );

    await this.print(templateToPrint);
    this.clearParams();
  }

  private print(printTemplate) {
    var template = printTemplate["template"];
    if (
      template == null ||
      template["configuration"] == null ||
      template["configuration"]["sections"] == null ||
      template["configuration"]["sections"].length === 0
    ) {
      //! hide modal
      return;
    }

    var environmentSetup = this.environment;
    var userPreference = this.getUserPreference(printTemplate);

    // executable template sections
    //
    // !!! TO BE CLONED !!!
    // data formating modifies sections in order to optimize
    // e.g. remove empty rows, etc
    var sections = printTemplate["executableSections"];
    var wysiwygState = printTemplate["wysiwygState"];
    var data = new Collector(environmentSetup, {
      rankingCache: wysiwygState.rankingCache,
      // rankingType: "instruments" /*//?is it always "instruments"*/,
      sections: deepClone(sections), // executable template sections
      template: deepClone(template),
      userPreference: userPreference,
      wysiwygState: wysiwygState,
    });

    return data.retrieve().then(
      ({ response, _wysiwygState }) => {
        var report: any = new Generator(environmentSetup, {
          data: response,
          formatter: this.formatter,
          sections: deepClone(sections), // executable template sections
          template: deepClone(template),
          userPreference: userPreference,
          wysiwygState: _wysiwygState,
        });
        return report
          .create()
          .print()
          .then(
            (response) => {
              var downloader = new DownloaderAndOpener(report);
              downloader.download(response);
            },
            (error) => {
              console.error(error);
            }
          );
      },
      (error) => {
        let output: any | string = null;
        if (error.error === "MAXIMUM_HOLDINGS_EXCEEDED") {
          output = [
            "Holdings (table): cannot be printed more than",
            error.maximum,
            "securities (total: " + error.actual + ").",
          ].join(" ");
        } else if (error.error === "MAXIMUM_CHART_HOLDINGS_EXCEEDED") {
          output = [
            "Holdings (charts): cannot be printed more than",
            error.maximum,
            "charts (total: " + error.actual + ").",
          ].join(" ");
        }
        console.log(output);
      }
    );
  }

  private getDefaultTemplates() {
    const _section = new Section(this.environment);
    var defaultTemplatesPromise = _section.getDefaultTemplate(
      this.reportParams?.page
    );
    return defaultTemplatesPromise.then((defaultTemplates) => {
      // Prepare correct structure
      var preparedDefaultTemplates: any[] = [];

      if (this.reportParams?.page === "analysisMarketsDetail") {
        let marketDetailDefault = defaultTemplates[0]["detailed"];
        preparedDefaultTemplates.push(
          this.httpTemplates("alerts")?.template(
            this.httpTemplates("alerts")?.decode(
              this.httpTemplates("alerts")?.migrate(marketDetailDefault)
            )
          )
        );

        marketDetailDefault = defaultTemplates[0]["dispersion"];
        preparedDefaultTemplates.push(
          this.httpTemplates("alerts")?.template(
            this.httpTemplates("alerts")?.decode(
              this.httpTemplates("alerts")?.migrate(marketDetailDefault)
            )
          )
        );
      } else {
        for (var i = 0, length = defaultTemplates.length; i < length; i++) {
          preparedDefaultTemplates.push(
            this.httpTemplates("alerts")?.template(
              this.httpTemplates("alerts")?.decode(
                this.httpTemplates("alerts")?.migrate(defaultTemplates[i])
              )
            )
          );
        }
      }

      return preparedDefaultTemplates;
    });
  }

  private getUserPreference(printDialogValue) {
    const preferences =
      this.environment.account.user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = printDialogValue["theme"];
    return userPreference;
  }

  private prepareTemplateForPrint(template, wysiwygState) {
    const printTemplate = deepClone(template);

    var theme: any = null;
    var pageTheme = printTemplate.configuration.theme;
    if (pageTheme === "theme1") {
      theme = themeBlueAndGrey;
    }
    printTemplate.configuration.theme = pageTheme;

    // Remove index field that is only used by ui
    if (printTemplate["index"] != null) {
      delete printTemplate["index"];
    }

    var _value = {
      executableSections: _mergeWysiwygStateActions(
        deepClone(printTemplate.configuration.sections),
        wysiwygState
      ),
      template: printTemplate,
      theme: theme,
      wysiwygState: wysiwygState,
    };

    return _value;
  }

  private _formatSize(value) {
    if (value === "microLarge") {
      return "All Cap";
    }

    const sizes = FormOptions.get("SIZE");
    const sizesMap = {};
    for (let i = 0, length = sizes.length; i < length; i++) {
      const item = sizes[i];
      sizesMap[item.value] = item;
    }

    // single interval
    let valueEnd = value;
    let valueStart = value;
    // multiple interval
    const cutoffIndex = value.search(/[A-Z]/g);
    if (cutoffIndex !== -1) {
      valueEnd = value.substring(cutoffIndex).toLowerCase();
      valueStart = value.substring(0, cutoffIndex);
    }

    if (valueStart === valueEnd) {
      return sizesMap[valueStart]["label"] + " Cap";
    }

    return [
      sizesMap[valueStart]["label"],
      "-",
      sizesMap[valueEnd]["label"],
      " Cap",
    ].join(" ");
  }

  private httpTemplates(page) {
    switch (page) {
      case "alerts":
        return this.http["alertsReportTemplates"];
      case "dispersionBasketTab":
      case "analysisList":
        return this.http["portfolioReportTemplates"];
      case "analysisMarkets":
        return this.http["peerReportTemplates"];
      case "analysisMarketsDetail":
        return this.http["peerReportTemplates"];
      case "ranking":
        return this.http["rankingReportTemplates"];
      case "screening":
        return this.http["screeningReportTemplates"];
      case "strategyBuilder":
        return this.http["strategyReportTemplates"];
      case "strategyLongShort":
        return this.http["strategyLongShortReportTemplates"];
      case "systematicPortfolios":
        return this.http["systematicPortfoliosReportTemplates"];
      default:
        return null;
    }
  }

  private getUIState(params) {
    // TODO long short strategy is part of strategyBuilder
    // temporary change of page type
    let _page = params.page;
    if (_page === "strategyLongShort") {
      _page = "strategyBuilder";
    }

    if (params.target == null) {
      return;
    }

    let _reportParams = { ...params };
    var columnsAvailable: any[] = [];
    var wysiwygState: any = {
      actions: {
        constraints: null,
        rank: null,
        sortBy: null,
      },
      columns: null,
      dataTotalCount: 0,
      promiseCache: null,

      // 2020-12-10 Markets: new way to do stuff using storages
      storage: null,

      target: null,
      targetType: null,
    };

    var environmentSetup = this.environment;
    var pageConfiguration = environmentSetup["configuration"].get(_page);
    var target = deepClone(_reportParams.target);
    switch (_page) {
      case "alerts": {
        wysiwygState["target"] = target;
        wysiwygState["timeframe"] = params.timeframe;
        wysiwygState["targetType"] = params.target.type;
        break;
      }
      case "dispersionBasketTab": {
        // columnsAvailable =
        //     pageConfiguration.tabs[1].widgets.viewer[
        //         "columns_available"
        //     ];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "LIST";

        const dispersionTabWidget =
          _reportParams.widgets?.["dispersion"] ?? null;

        if (dispersionTabWidget != null) {
          const dispersionParams = dispersionTabWidget.get("wysiwygState");

          const constraints = {
            intervals: dispersionParams?.intervals ?? 25,
            performanceTimeframe: dispersionParams?.period ?? "3_months",
          };
          wysiwygState.trimOutliers = dispersionParams?.trimOutliers ?? false;
          wysiwygState.dispersionByConstraints = constraints;
          wysiwygState.segment = dispersionParams?.segment ?? "Country";
          wysiwygState.segmentEtf = dispersionParams?.etfSegment ?? "etfgeo";
          wysiwygState.title = target["name"].replace(",", " - ");
        } else {
          const constraints = {
            intervals: 25,
            performanceTimeframe: "3_months",
          };
          wysiwygState.trimOutliers = false;
          wysiwygState.dispersionByConstraints = constraints;
          wysiwygState.segment = "Country";
          wysiwygState.segmentEtf = "etfgeo";
        }

        break;
      }
      case "analysisList": {
        const holdingTabConfig = pageConfiguration.tabs.find(
          (tab) => tab.id === "holdings"
        );
        columnsAvailable = holdingTabConfig.widgets.viewer["columns_available"];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "LIST";

        break;
      }
      case "analysisMarkets": {
        if (target["name"] === "__ROOT__") {
          target["name"] = i18n["World_wide"];
        }
        target["name"] = target["name"].replace(",", " - ");

        wysiwygState["storage"] = _reportParams.storage;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "PEER";

        break;
      }
      case "analysisMarketsDetail": {
        if (target["name"] === "__ROOT__") {
          target["name"] = i18n["World_wide"];
        }
        target["name"] =
          _reportParams.subject ?? target["name"]?.replace(",", " - ");

        if (target.size !== "microLarge") {
          const peerSize = target?.size;
          const sizeLabel = this._formatSize(peerSize);

          target["name"] += ` ${sizeLabel}`;
        }

        wysiwygState["storage"] = _reportParams.storage;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "PEER";

        // Prepare data passed to action for use in wysiwyg
        wysiwygState.timeframe = _reportParams.timeframe;
        wysiwygState.trimOutliers = _reportParams.trimOutliers;
        wysiwygState.dispersionByConstraints =
          _reportParams.dispersionByConstraints;
        wysiwygState.segment = _reportParams.segment;
        wysiwygState.sortState = _reportParams.sortState;
        wysiwygState.title = _reportParams.title;
        wysiwygState.subject = _reportParams.subject;

        break;
      }
      case "ranking": {
        columnsAvailable =
          pageConfiguration.widgets.viewer["columns_available"];

        // Note from Elia
        //
        // ranking (the target) is a container of other targets.
        // At the moment (2020-04-10) I do not have other ideas
        // to manage it in a coherent way with others kind of
        // targets
        var _target = deepClone(target["target"]["object"]);
        _target["name"] = target["name"];

        wysiwygState["target"] = _target;
        wysiwygState["targetType"] =
          target["target"]["type"] === "list" ? "LIST" : "SCREENING";

        break;
      }
      case "screening": {
        columnsAvailable =
          pageConfiguration.widgets.viewer["columns_available"];

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "SCREENING";

        break;
      }
      case "strategyBuilder":
      case "strategyLongShort": {
        columnsAvailable =
          pageConfiguration?.widgets?.viewer["columns_available"];

        // TODO - hard coded
        wysiwygState["actions"]["sortBy"] = {
          descending: true,
          property: "weight",
        };

        var columnSet = new ColumnSet({
          elements: {
            columns: pageConfiguration.widgets.viewer["columns"],
            columns_available:
              pageConfiguration.widgets.viewer["columns_available"],
          },
          properties: environmentSetup["properties"],
          customConfiguration: null,
        });
        wysiwygState["columns"] = columnSet.generate(null);

        wysiwygState["promiseCache"] = _reportParams.strategyCache;
        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "STRATEGY"; // "COMBINED_STRATEGY"
        wysiwygState["storage"] = _reportParams.storage;

        break;
      }
      case "systematicPortfolios": {
        columnsAvailable =
          pageConfiguration.tabs[1].widgets.viewer["columns_available"];

        columnSet = new ColumnSet({
          elements: {
            columns: pageConfiguration.tabs[1].widgets.viewer["columns"],
            columns_available:
              pageConfiguration.tabs[1].widgets.viewer["columns_available"],
          },
          properties: environmentSetup["properties"],
          customConfiguration: null,
        });
        wysiwygState["columns"] = columnSet.generate(null);

        // TODO - hard coded
        wysiwygState["actions"]["sortBy"] = {
          descending: true,
          property: "weight",
        };

        wysiwygState["target"] = target;
        wysiwygState["targetType"] = "SYSTEMATIC";

        break;
      } // no default
    }

    // constraints, sortBy and columns
    if (
      _reportParams.widgets != null &&
      _reportParams.widgets["table"] != null
    ) {
      wysiwygState["actions"]["constraints"] =
        _reportParams.widgets["table"].get("constraints");

      // Check for tables like holdings in portfolio analysis
      // Only if constraints are empty
      if (
        wysiwygState["actions"]["constraints"] == null &&
        _reportParams.widgets["table"] != null &&
        _reportParams.widgets["table"].widgetDataFilter != null
      ) {
        wysiwygState["actions"]["constraints"] =
          _reportParams.widgets["table"].widgetDataFilter.get("value");
      }

      let sortBy = _reportParams.widgets["table"].get("sort");
      //
      // 2021-06-11
      //
      // TODO This is legacy, maybe is not used anymore?
      if (sortBy == null) {
        sortBy = _reportParams.widgets["table"].get("sortBy");
      }
      wysiwygState["actions"]["sortBy"] = sortBy;

      wysiwygState["columns"] = _reportParams.widgets["table"].get("columns");
      wysiwygState["dataTotalCount"] =
        _reportParams.widgets["table"].get("dataTotalCount");
    }

    // TODO remove and refactor it with only wysiwygState["rankingCache"]
    if (_reportParams.rankingCache != null) {
      wysiwygState["actions"]["rank"] = {
        date: _reportParams.rankingCache["rankingParams"]["fromDate"],
        list: _reportParams.rankingCache["rankingParams"]["list"],
        rules: _reportParams.rankingCache["rankingParams"]["rules"],
        universe:
          _reportParams.rankingCache["rankingParams"]["againstUniverse"],
      };
    }

    // TODO patch, used in report Generator.js, please remove it later
    // and use only actions/rank
    wysiwygState["rankingCache"] = _reportParams.rankingCache;

    var uiState = {
      columnsAvailable: columnsAvailable,
      wysiwygState: wysiwygState,
    };

    if (appConfig.isDebug === true) {
      console.log("uiState", uiState);
    }

    return uiState;
  }

  private clearParams() {
    this.reportParams = undefined;
  }
}
