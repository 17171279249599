import {
  Box,
  MenuItem,
  Select as MuiSelect,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useEnvironment } from "../../../hooks/useEnvironment";
import Modal from "../../Modal/Modal";

const ModalComponent = ({ ...props }) => {
  return (
    <Modal
      customCss={{
        width: "auto",
        maxWidth: "1000px",
        minWidth: "310px",
      }}
      buttonsEnalbed={true}
      buttons={props.buttonsForModal}
      onClose={props.onClose}
      isDraggable={true}
      closeOnClickAway={false}
      bodyCustomClass={props.className}
      headerConfig={{
        headerContent: "Import from " + props.data.name,
      }}
      closeIcon={false}
    >
      {props.children}
    </Modal>
  );
};

const StyledModal = styled(ModalComponent)({
  flexDirection: "column",
  display: "flex",
  overflow: "hidden",
});

type Props = {
  data?: any;
  onClose: Function;
  onImport: Function;
  hideDialogUploadSupportingDescription: Function;
};
const getLabelsFromSheet = (cols) => {
  if (cols) {
    const newState = {};
    let option: any = null;
    let index = 0;
    for (const col of cols) {
      const fromTab = col.toLowerCase();
      option = selectOptions.find(
        (item) => item.label.toLowerCase() === fromTab.trim()
      );
      index = cols.indexOf(col);
      if (option != null) {
        newState[index] = option.value;
      }
    }
    return newState;
  }
};

export default function Table({
  data,
  onClose,
  onImport,
  hideDialogUploadSupportingDescription,
}: Props) {
  /* ----- */
  /* ----- */
  const [tabIndex, setTabIndex] = useState<number>(0);
  useEffect(() => {
    setColumns(
      data?.data[data.sheets?.[tabIndex].name][0].map((item) => item.value)
    );
  }, [data?.data, data.sheets, tabIndex]);

  const [columns, setColumns] = useState(() =>
    data?.data[data.sheets?.[tabIndex].name][0].map((item) => item.value)
  );

  const environment = useEnvironment();

  const taxonFields = environment.get("taxonomyFields");

  const [config, setConfig] = useState<Object>({});
  const [configDuplicated, setConfigDuplicated] = useState({
    duplicated: false,
    message: "",
  });
  const [emptyConfig, setEmptyConfig] = useState(true);
  const [startAtRow, setStartAtRow] = useState<number>(2);
  const [selectedMarket, setSelectedMarket] = useState("-");

  useEffect(() => {
    setConfig(getLabelsFromSheet(columns) ?? {});
  }, [columns]);

  useEffect(() => {
    if (Object.entries(config).length > 0) {
      setEmptyConfig(false);
    } else {
      setEmptyConfig(true);
    }
    selectedFieldsValidator(config, setConfigDuplicated);
  }, [config]);

  const close = useCallback(() => {
    onClose();
    console.log("PIPPPPPPP");
  }, [onClose]);

  const buttonsForModal = [
    {
      name: "import",
      disabled:
        !emptyConfig &&
        !configDuplicated.duplicated &&
        // to prevent the import without an identifier
        Object.values(config).some(
          (item) =>
            item === "bbs" ||
            item === "isin" ||
            item === "ric" ||
            item === "ticker" ||
            item === "valoren"
        )
          ? false
          : true,
      callback: () => {
        hideDialogUploadSupportingDescription();
        _import(
          data,
          config,
          startAtRow,
          selectedMarket,
          environment,
          tabIndex,
          setConfig,
          onClose,
          onImport
        );
      },
    },
    {
      name: "Cancel",
      callback: close,
      variant: "cancel",
    },
  ];

  const optionsForSelect = prepareOptionsForMarketSelect(taxonFields);

  return (
    <StyledModal buttonsForModal={buttonsForModal} onClose={close} data={data}>
      <Box display={"flex"} flexDirection={"column"} overflow={"hidden"}>
        {configDuplicated.duplicated && (
          <div style={{ marginBottom: 10, color: "red" }}>
            - {configDuplicated.message}
          </div>
        )}
        {emptyConfig && (
          <div style={{ marginBottom: 10, color: "red" }}>
            - You haven't selected any columns.
          </div>
        )}
        {!emptyConfig &&
        Object.values(config).some(
          (item) =>
            item === "bbs" ||
            item === "isin" ||
            item === "ric" ||
            item === "ticker" ||
            item === "valoren"
        )
          ? false
          : true && (
              <div style={{ marginBottom: 10, color: "red" }}>
                - Symbology is required.
              </div>
            )}
        <div>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <span>Data starts at row &nbsp;</span>
            <TextField
              value={startAtRow}
              size="small"
              type="number"
              inputProps={{ min: 1, max: data.sheets[0].rows, places: 0 }}
              // sx={{ width: "5em" }}
              required
              onChange={(e) => {
                let _value = parseInt(e.target.value);
                setStartAtRow(_value);
              }}
            />
            {/* <NumberSpinner
            dojoProps={{
              constraints: {
                min: 1,
                max: data.sheets[0].rows,
                places: 0,
              },
              style: "width: 6em",
              value: startAtRow,
              required: true,
            }}
            dojoEvents={{
              change: (e) => {
                setStartAtRow(e);
              },
            }}
          /> */}
          </Box>
          <div style={{ margin: "10px 0px" }}>
            <span>Bound to a single market &nbsp;</span>
            <MuiSelect
              // defaultValue={"-"}
              value={selectedMarket}
              size="small"
              onChange={(e) => {
                const _selectedMarket: any = e.target.value;
                setSelectedMarket(_selectedMarket);
              }}
            >
              {optionsForSelect.map((opt, index) => (
                <MenuItem key={index} value={opt.value}>
                  <Typography>{opt.label}</Typography>
                </MenuItem>
              ))}
            </MuiSelect>
            {/* <Select
            dojoProps={{
              options: optionsForSelect,
            }}
            dojoEvents={{
              change: (e) => {
                setSelectedMarket(e);
              },
            }}
          /> */}
          </div>
        </div>

        <div
          style={{
            height: "100%",
            maxHeight: "550px",
            overflow: "auto",
            position: "relative",
          }}
        >
          <table
            style={{
              width: "100%",
              overflow: "scroll",
              borderCollapse: "collapse",
            }}
            className="import__data"
          >
            {/* selects */}
            <thead>
              <tr>
                <th> </th>
                {columns.map((item, index) => (
                  <th key={index}>
                    <MuiSelect
                      autoFocus={false}
                      fullWidth
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value !== "-") {
                          setConfig({
                            ...config,
                            [index ?? "undefined"]: value,
                          });
                        } else {
                          if (config[index ?? ""]) {
                            const temp = { ...config };
                            delete temp[index ?? ""];
                            setConfig(temp);
                          }
                        }
                      }}
                      size="small"
                      value={config[index] != null ? config[index] : "-"}
                    >
                      {selectOptions.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                          <Typography>{opt.label}</Typography>
                        </MenuItem>
                      ))}
                    </MuiSelect>
                    {/* 
                  <Select
                    dojoProps={{
                      options: selectOptions,
                      value: config[index] != null ? config[index] : "-",
                      style: "width: 100%",
                    }}
                    dojoEvents={{
                      change: (e) => {
                        const value = e;
                        if (value !== "-") {
                          setConfig({
                            ...config,
                            [index ?? "undefined"]: value,
                          });
                        } else {
                          if (config[index ?? ""]) {
                            const temp = { ...config };
                            delete temp[index ?? ""];
                            setConfig(temp);
                          }
                        }
                      },
                    }}
                  /> */}
                  </th>
                ))}
              </tr>
              {/* a,b,c,d,.... */}
              <tr>
                <th> </th>
                {columns.map((item, index) => (
                  <th key={index}>{getColumnLabel(index)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.data[data.sheets?.[tabIndex].name]?.map((row, index) => {
                if (index < startAtRow - 1) {
                  return (
                    <tr key={index}>
                      <th>{index + 1}</th>
                      {row.map((col, index) => (
                        <th key={index} style={{ textAlign: "left" }}>
                          {col.value}
                        </th>
                      ))}
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index + 1}>
                      <th>{index + 1}</th>
                      {row.map((col, index) => {
                        //if regex find a % in the end of string apply this custom format for percentages
                        if (/%$/.test(col.format)) {
                          return (
                            <td key={index}>
                              {formatExcelPercentage(col.value, true)}
                            </td>
                          );
                        }
                        return <td key={index}>{col.value}</td>;
                      })}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", borderTopWidth: 0 }}>
          {data.sheets.map((item, index) => (
            <button
              key={index}
              style={
                index === tabIndex
                  ? {
                      border: "1px solid #ccc",
                      borderTop: "none",
                      padding: "8px 5px",
                      backgroundImage:
                        "linear-gradient(0deg,#fff 0,#fff 1px,hsla(0,0%,100%,0) 2px,#fff 7px)",
                    }
                  : { border: "none" }
              }
              onClick={() => {
                setTabIndex(index);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
      </Box>
    </StyledModal>
  );
}

//list of possible options for selects
const selectOptions = [
  {
    label: "Ignore",
    value: "-",
  },
  {
    label: "Bloomberg",
    value: "bbs",
  },
  {
    label: "ISIN",
    value: "isin",
  },
  {
    label: "Reuters",
    value: "ric",
  },
  {
    label: "Ticker",
    value: "ticker",
  },
  {
    label: "Valoren",
    value: "valoren",
  },
  {
    label: "Weight",
    value: "weight",
  },
  {
    label: "Weight (%)",
    value: "weight (%)",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Market",
    value: "market",
  },
];
const getColumnLabel = (number: number) => {
  var result = "";
  for (result; number >= 0; number = number / 26 - 1) {
    result = String.fromCharCode((number % 26) + 65) + result;
  }
  return result;
};
//check if there are duplicates in config
const selectedFieldsValidator = (config, messageFieldSetter) => {
  const symbologyLabels = ["bbs", "isin", "ric", "ticker", "valoren"];
  const state: any = config;

  const message = {
    genericDuplication:
      "You have selected at least 2 columns with the same identifier.",

    symbologyDuplicated: "You have selected 2 symbology instead of 1.",
    weightDuplication: "You have selected 2 indicators for the weight",
  };

  if (state != null) {
    const symbologyKeysInState: any = [];

    //Check for duplicates and exclude the possibility to have weight and weight (%)
    const isValueDuplicated = Object.values(state).some(
      (el, index) => Object.values(state).indexOf(el) !== index
    );

    const weightsInState = Object.values(state).filter((el: any) =>
      el.startsWith("weight")
    );

    const isWeightAlreadyInState = weightsInState.length > 1;

    for (const value of Object.values(state)) {
      if (symbologyLabels.some((item) => item === value)) {
        symbologyKeysInState.push(value);
      }
    }

    if (
      symbologyKeysInState.length > 1 ||
      isValueDuplicated ||
      isWeightAlreadyInState
    ) {
      if (symbologyKeysInState) {
        messageFieldSetter({
          duplicated: true,
          message: message.symbologyDuplicated,
        });
      }

      if (isWeightAlreadyInState) {
        messageFieldSetter({
          duplicated: true,
          message: message.weightDuplication,
        });
      }

      if (isValueDuplicated) {
        messageFieldSetter({
          duplicated: true,
          message: message.genericDuplication,
        });
      }
    } else {
      messageFieldSetter({ duplicated: false, message: "" });
    }
  }
};
const prepareOptionsForMarketSelect = (fields) => {
  const optionsForSelect: {
    label: string;
    value: string;
  }[] = [];
  optionsForSelect.push({ label: "Worldwide", value: "-" });
  var store = window.App.taxonomies[fields["security"]["country"]];

  const markets: any = [];

  for (const node of Object.values(store) as any) {
    if (node.type === "Country") {
      markets.push(node);
    }
  }

  markets.sort((a, b) => (a.id > b.id ? 1 : -1));

  for (let i = 0, length = markets.length; i < length; i++) {
    let item = markets[i];
    optionsForSelect.push({
      label: item.name,
      value: item.id,
    });
  }

  optionsForSelect.sort((a: any, b: any) => {
    a = a.label.toLowerCase();
    b = b.label.toLowerCase();
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }

    return 0;
  });

  return optionsForSelect;
};

const _import = (
  rawData,
  config,
  startAtRow: number,
  selectedMarket: any,
  environment,
  tabIndex,
  setConfig: Function,
  closeDialog: Function,
  onImport: Function
) => {
  var sheet = rawData?.sheets[tabIndex];
  var data = rawData.data[sheet.name];
  const selectedColState = config;
  let symbology: any = null;
  let colSymbol: any = null;
  let colWeight: any = null;
  let colWeightPercentage: any = null;
  let colCurrency: any = null;
  let colMarket: any = null;

  const isStateEmpty = Object.keys(selectedColState).length === 0;

  if (selectedColState == null || isStateEmpty) {
    // this._handleValidationError(true, "You haven't selected any columns.");
    // alert("You haven't selected any columns.");

    return;
  } else {
    // this._handleValidationError(false);
    var row = startAtRow - 1;
    const symbologyLabels = ["bbs", "isin", "ric", "ticker", "valoren"];

    for (const [key, value] of Object.entries(selectedColState)) {
      const matchedSymbologyValue = symbologyLabels.find((el) => el === value);

      if (matchedSymbologyValue != null) {
        colSymbol = parseInt(key);
        symbology = value;
      }

      if (value === "weight") {
        colWeight = parseInt(key);
      }

      if (value === "weight (%)") {
        colWeightPercentage = parseInt(key);
      }

      if (value === "currency") {
        colCurrency = parseInt(key);
      }

      if (value === "market") {
        colMarket = parseInt(key);
      }
    }

    colSymbol = colSymbol ? colSymbol : 0;
    colWeight = colWeight ? colWeight : 0;
    colCurrency = colCurrency ? colCurrency : 0;
    colMarket = colMarket ? colMarket : 0;
    colWeightPercentage = colWeightPercentage ? colWeightPercentage : 0;

    var country: any = null;
    if (selectedMarket !== "-") {
      country = selectedMarket;
    }

    var result: any = {
      instruments: [],
      market: country,
      symbology: symbology,
      trendratingFormat: sheet.trendratingFormat,
    };
    var item: any = null;
    var weight: any = 0;
    let currency: any = 0;

    for (var i = row, length = data.length; i < length; i++) {
      item = {
        label: null,
        country: null,
        currency: null,
        weight: null,
      };

      if (
        data[i][colSymbol] &&
        data[i][colSymbol].value !== "" &&
        data[i][colSymbol].value !== null
      ) {
        if (data[i][colSymbol].type === "n") {
          data[i][colSymbol].value = parseInt(data[i][colSymbol].value);
        }
        item.label = ("" + data[i][colSymbol].value).toUpperCase();
      }

      const isWeightSelected = Object.values(config).some(
        (el) => el === "weight"
      );

      const isWeightPercentageSelected = Object.values(config).some(
        (el) => el === "weight (%)"
      );

      const isCountrySelected = Object.values(config).some(
        (el) => el === "market"
      );

      const isCurrencySelected = Object.values(config).some(
        (el) => el === "currency"
      );

      if (isWeightSelected || isWeightPercentageSelected) {
        if (!isWeightSelected && isWeightPercentageSelected) {
          weight = parseFloat(data[i][colWeightPercentage].value);
        } else {
          const temp = data[i][colWeight].value / 100;
          weight = parseFloat(temp.toString());
        }
        item.weight = isNaN(weight) ? 0 : weight;
      } else {
        item.weight = 0;
      }

      if (isCurrencySelected) {
        currency = data[i][colCurrency].value;
        item.currency = currency;
      } else {
        item.currency = null;
      }

      if (isCountrySelected) {
        let market = data?.[i]?.[colMarket]?.value ?? "";

        //If country length is greather than 2 characters it means that is not an ISO code
        if (market.length > 2) {
          //Try to convert the string to an ISO code
          const taxonomies = environment.get("rawTaxonomies");
          const marketsTaxonomies = taxonomies["Markets"];
          const countryISOCode: any = Object.values(marketsTaxonomies).find(
            (el: any) => el.name === market
          );

          market = countryISOCode != null ? countryISOCode["iso"] : null;
        }

        item.country = market ?? "no-country";
      } else {
        item.country = null;
      }

      if (item.label) {
        result.instruments.push(item);
      }
    }

    setConfig({});

    onImport({
      bubbles: true,
      cancelable: true,
      value: result,
    });
    closeDialog();
  }
};

const formatExcelPercentage = (
  /*Float*/ value,
  /*Boolean*/ addPercentageSymbols
) => {
  var result = "";
  if (value) {
    result = (value * 100).toFixed(2);

    if (addPercentageSymbols) {
      result += "%";
    }
  }
  return result;
};
