import {
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  formatTaxonPrefixingParent,
  getChildrenAtLevel,
} from "../../../../../../api/compute/Taxon";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../../trendrating-widgets/form/FormOptions";
import PeerSize from "./PeerSize";

type Props = {
  where: {
    label: string;
    type: "Area" | "Region" | "Country" | "World";
    value: string;
    selected: boolean;
  };
  onChangeType: Function;
  onSelect: Function;
  size: any;
  onSetSize: Function;
};

export default function Markets({
  where,
  onChangeType,
  onSelect,
  size,
  onSetSize,
}: Props) {
  const formOptions = useMemo(() => FormOptions, []);
  const environment = useEnvironment();
  const taxonomies = useMemo(() => {
    let marketTX = environment.get("rawTaxonomies");
    let countryField = environment.get("taxonomyFields");
    countryField = countryField["security"]["country"];
    return marketTX[countryField];
  }, [environment]);
  const root = useMemo(() => {
    let root: any = null;
    for (let key in taxonomies) {
      if (taxonomies[key].parent == null) {
        root = taxonomies[key].id;
        break;
      }
    }
    return root;
  }, [taxonomies]);
  const getChildrenByLevel = useCallback(
    (level: "Area" | "Region" | "Country" | "World") => {
      const nodes = getChildrenAtLevel(root, level, taxonomies);
      return nodes;
    },
    [root, taxonomies]
  );
  const area = useMemo(
    () =>
      getChildrenByLevel("Area").sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [getChildrenByLevel]
  );
  const region = useMemo(() => {
    const data = getChildrenByLevel("Region");
    let result: any = [];
    data.forEach((element) => {
      result.push({
        name: formatTaxonPrefixingParent(element, [taxonomies], "Region"),
        id: element.id,
      });
    });
    return result.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }, [getChildrenByLevel, taxonomies]);
  const market = useMemo(
    () =>
      getChildrenByLevel("Country").sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [getChildrenByLevel]
  );

  const onSelectCard = useCallback(
    (e) => {
      onChangeType(e);
      switch (e) {
        case "Area":
          onSelect(area[0].id);
          break;
        case "Region":
          onSelect(region[0].id);
          break;
        case "Country":
          onSelect(market[0].id);
          break;
      }
    },
    [area, market, onChangeType, onSelect, region]
  );
  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent sx={{ p: 1, pb: "8px !important" }}>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="tr_subtitle_blue">Markets</Typography>
          <Box display={"flex"} justifyContent={"end"}>
            <PeerSize
              options={formOptions.get("SIZE")}
              value={size}
              onSetSize={(size) => onSetSize(size)}
            />
          </Box>
          <Box display={"flex"} gap={1}>
            <Card
              sx={{
                backgroundColor: where.type !== "World" ? "#eee" : "#fff",
                cursor: "pointer",
              }}
              onClick={() => onSelectCard("World")}
            >
              <CardContent
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography textAlign={"center"}>World wide</Typography>
              </CardContent>
            </Card>
            <MarketSelector
              onClick={(e) => {
                onSelectCard("Area");
              }}
              label={"Area"}
              disabled={where.type !== "Area"}
              items={area}
              defaultValue={where.type === "Area" ? where.value : undefined}
              onSelect={onSelect}
            />
            <MarketSelector
              onClick={() => onSelectCard("Region")}
              disabled={where.type !== "Region"}
              label={"Region"}
              defaultValue={where.type === "Region" ? where.value : undefined}
              items={region}
              onSelect={(e) => onSelect(e)}
            />
            <MarketSelector
              onClick={() => onSelectCard("Country")}
              defaultValue={where.type === "Country" ? where.value : undefined}
              disabled={where.type !== "Country"}
              label={"Market"}
              items={market}
              onSelect={(e) => onSelect(e)}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
type MarketSelectorType = {
  label: string;
  items: any[];
  onSelect: Function;
  disabled?: boolean;
  defaultValue?: any;
  onClick: Function;
};
const MarketSelector = ({
  label,
  items,
  onSelect,
  disabled = false,
  defaultValue,
  onClick,
}: MarketSelectorType) => {
  return (
    <Card
      onClick={(e) => onClick(e)} //! ***
      sx={{
        backgroundColor: disabled ? "#eee" : "#fff",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {label}
          <Select
            value={defaultValue ?? items[0].id}
            disabled={disabled}
            size="small"
            onChange={(e) => {
              onSelect(e.target.value);
            }}
          >
            <MenuItem disabled value={"-"}>
              <Typography>Select {label}</Typography>
            </MenuItem>
            {items.map((item, key) => (
              <MenuItem
                onClick={(e) => {
                  //! to prevent triggering the onclick at ***
                  e.stopPropagation();
                }}
                key={key}
                value={item.id}
              >
                <Typography>{item.name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </CardContent>
    </Card>
  );
};
