import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import { clone } from "../../deepClone";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../hooks/useTaxonomyByType";
import { FilterCard } from "../../js/app/pages/screening/FilterBar/FilterRowContraints";
import { MarketCapFilter } from "../../js/app/pages/screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import { TaxonomyFieldRow } from "../../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";
import { FilterSummary } from "./FilterSummary";
import { filterReducerStock, filterStockState } from "./filterReducer";
import { _getUserPreferences, getInitialState } from "./filterWidgetHelper";

type NewsFilterProps = {
  setFiltersStock: (filters) => void;
  saveFiltersStock?: (filters) => void;
  initState?: {
    where: string[];
    what: string[];
    size: {
      left: any;
      right: any;
    };
  };
};

export function FilterWidget({
  setFiltersStock,
  saveFiltersStock,
  initState,
}: NewsFilterProps) {
  const environment = useEnvironment();
  const savedNewsFilters = window.App.user.alertsNewsFilters?.filters ?? null;
  const userPreferencesFromSettings = _getUserPreferences(environment);

  const state =
    initState != null
      ? initState
      : getInitialState(
          filterStockState,
          savedNewsFilters,
          userPreferencesFromSettings,
          "stock"
        );

  const [filters, dispatch] = useImmerReducer(filterReducerStock, state);
  // const [peerSize, setPeerSize] = useState(filters.size);
  const [showEditor, setShowEditor] = useState<boolean>(false);

  useEffect(() => {
    if (initState) {
      dispatch({
        type: "REFRESH_INIT_STATE",
        payload: initState,
      });
    }
  }, [dispatch, initState]);

  const saveFiltersHandler = () => {
    if (saveFiltersStock) {
      saveFiltersStock(filters);
    }
    setShowEditor(false);
  };

  const { taxonomiesMapY, taxonomiesMapX, advancedNodesSelector } =
    useTaxonomyByType("stock");

  const selectCountry = useCallback(
    (e) => {
      const payload = {
        what: clone(filters.what),
        where: [],
        size: clone(filters.size),
      };

      if (e.length === 0) {
        dispatch({
          type: "SET_COUNTRY",
          payload: [] as any,
        });

        setFiltersStock(payload);

        return;
      }

      let val = advancedNodesSelector(e[0].value.segments, "country", true);
      dispatch({ type: "SET_COUNTRY", payload: val });

      payload["where"] = val;

      setFiltersStock(payload);
    },

    [
      advancedNodesSelector,
      dispatch,
      filters.size,
      filters.what,
      setFiltersStock,
    ]
  );

  const selectSector = useCallback(
    (e) => {
      const payload = {
        what: [],
        where: clone(filters.where),
        size: clone(filters.size),
      };

      if (e.length === 0) {
        dispatch({
          type: "SET_SECTORS",
          payload: [] as any,
        });

        setFiltersStock(payload);

        return;
      }
      let val = advancedNodesSelector(e[0].value.segments, "icb", true, [
        "1 Industry",
        "3 Sector",
      ]);
      dispatch({
        type: "SET_SECTORS",
        payload: val,
      });

      payload["what"] = val;

      setFiltersStock(payload);
    },
    [
      advancedNodesSelector,
      dispatch,
      filters.size,
      filters.where,
      setFiltersStock,
    ]
  );

  const selectSize = useCallback(
    (size) => {
      const payload: any = {
        what: clone(filters.what),
        where: clone(filters.where),
        size: { left: null, right: null },
      };

      if (size.le === null && size.ge === null) {
        dispatch({
          type: "SET_SIZE",
          payload: {
            left: null,
            right: null,
          },
        });

        setFiltersStock(payload);
      } else {
        dispatch({
          type: "SET_SIZE",
          payload: {
            left: null,
            right: null,
          },
        });

        payload["size"] = {
          left: size.ge * 1000000,
          right: size.le * 1000000,
        };

        setFiltersStock(payload);
      }
    },
    [dispatch, filters.what, filters.where, setFiltersStock]
  );

  return (
    <>
      {showEditor ? (
        <Card sx={{ boxShadow: 2 }}>
          <CardContent>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography>
                <strong>Filter by:</strong>
              </Typography>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  gap={1}
                  alignItems={"center"}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Box>
                      <TaxonomyFieldRow
                        showAllSelected={false}
                        hasMultipleChoices={true}
                        label={"Market"}
                        handleValuesSelection={selectCountry}
                        taxonomyMap={taxonomiesMapX}
                        selectedValues={filters.where ? filters.where : []}
                        taxonomyType={"security"}
                        taxonomyField={"country"}
                        phraseOnEmpty="World wide"
                      />
                    </Box>
                    <Box>
                      <TaxonomyFieldRow
                        showAllSelected={false}
                        hasMultipleChoices={true}
                        label={"Sector"}
                        handleValuesSelection={selectSector}
                        taxonomyMap={taxonomiesMapY}
                        taxonomyType={"security"}
                        taxonomyField={"icb"}
                        selectedValues={filters.what ? filters.what : []}
                        columnsDefinition={[
                          [{ taxonomyId: "20" }, { taxonomyId: "60" }],
                          [{ taxonomyId: "25" }, { taxonomyId: "50" }],
                          [
                            { taxonomyId: "15" },
                            { taxonomyId: "35" },
                            { taxonomyId: "10" },
                          ],
                          [{ taxonomyId: "40" }, { taxonomyId: "45" }],
                          [{ taxonomyId: "55" }, { taxonomyId: "30" }],
                        ]}
                        validTreeNodes={["1 Industry", "3 Sector"]}
                      />
                    </Box>
                    <FilterCard label={"Market Cap"}>
                      <MarketCapFilter
                        hasRangeSlider={false}
                        updateValue={selectSize}
                        initValue={{
                          le: parseInt(filters.size.right) / 1000000,
                          ge: parseInt(filters.size.left) / 1000000,
                        }}
                      />
                    </FilterCard>
                  </Box>
                </Box>
              </Box>
              <div className="tNews-filters-button-save-wrapper">
                <button
                  onClick={saveFiltersHandler}
                  className="tNews-filters-button-save"
                >
                  Done
                </button>
              </div>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <FilterSummary
          filterState={filters}
          openEditor={setShowEditor}
          widgetFilterType="stocks"
        />
      )}
    </>
  );
}
