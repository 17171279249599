import Block from "../../../../../../../../components/LayoutElements/Block/Block";
import { getInfoNodeData } from "../../../../../../../../components/SecurityTooltip/SecurityTooltipCore";
import { deepClone } from "../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import styles from "./../Alternatives.module.scss";

type AlternativesCardProps = {
  security: any;
  selected: boolean;
  hanldeActiveListItem: (v) => void;
  hasButtons?: boolean;
  onClickAdd?: (security) => void;
  hasCounter?: string | null;
  isInSellList?: boolean;
  removeFromReplaceList?: Function;
  buttonLabel?: string;
};

export function AlternativesCard({
  security,
  selected,
  hanldeActiveListItem,
  onClickAdd,
  hasButtons = false,
  hasCounter,
  removeFromReplaceList = () =>
    console.warn(
      "You are trying to remove an item from replace list but no handler was passed to AlternativesCard component"
    ),
  isInSellList = false,
  buttonLabel,
}: AlternativesCardProps) {
  const format = useFormatter();
  const environment = useEnvironment();
  const rawTaxonomies = environment.get("rawTaxonomies");
  const taxonFields = environment.get("taxonomyFields");

  const rate = security
    ? format.text(
        "rc",
        "rating",
        deepClone(security)?.["rc"],
        deepClone(security),
        deepClone(security)?.["type"]
      )
    : "";
  const rateDate = security
    ? format.text(
        "dr",
        "rated_on_iso",
        deepClone(security)?.["dr"],
        deepClone(security),
        deepClone(security)?.["type"]
      )
    : "";

  return (
    <Block
      className={`${styles.alternatives__card} ${
        selected ? styles["card-active"] : ""
      } ${hasButtons ? styles.card_with_buttons : ""} ${
        isInSellList ? "sold__card" : ""
      }`}
    >
      <li
        className={styles.position__list__item__card}
        onClick={() => hanldeActiveListItem(security.ticker)}
      >
        {hasCounter != null && (
          <p className={styles.position__list__item__card__counter}>
            {hasCounter}
          </p>
        )}
        <div className={styles.position__list__item__card__info}>
          <h1 className="securty-tooltip__ticker">{security.ticker}</h1>
          <h1 style={{ border: "none" }} className="securty-tooltip__name">
            {security.name}
          </h1>
          <p
            className="securty-tooltip__info"
            dangerouslySetInnerHTML={{
              __html: getInfoNodeData(
                rawTaxonomies,
                security,
                environment,
                taxonFields
              ),
            }}
          ></p>
          <p className={styles.position__list__item__card__info__rating}>
            <strong
              className={`tAnalysisOverview-rate format-rate format-rate--${rate}`}
            >
              {rate}
            </strong>{" "}
            from {rateDate}
          </p>
          {isInSellList ? (
            <button
              onClick={(e) => removeFromReplaceList(e, security)}
              title="Click to remove from replace list"
              className={styles.alternatives__sold__button__card}
            ></button>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.position__list__item__card__chart}>
          <img
            src={security.chartUri}
            alt={security.name}
            title={security.name}
          />
        </div>
        {hasButtons && onClickAdd && (
          <div className={styles.buttons_fade_wrapper}>
            <button
              style={{ minWidth: 200 }}
              onClick={() => onClickAdd(security)}
              className={styles.buttons_fade_wrapper_btn}
            >
              <p>
                {buttonLabel} <span className="i-arrow-r"></span>{" "}
                {security.ticker + " to replace list"}
              </p>
            </button>
          </div>
        )}
      </li>
    </Block>
  );
}
