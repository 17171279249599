import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { decodePeerId } from "../../../../../api/utils";
import { useTaxonomyByType } from "../../../../../hooks/useTaxonomyByType";
import { InfoTooltip } from "../../alerts/InfoTooltip";
import DispersionBlock from "../ETFs/widgets/ContentETFMarkets/widgets/DispersionView/DispersionBlock/DispersionBlock";
import InstrumentsTableForAnalytics from "../ETFs/widgets/ContentETFMarkets/widgets/InstrumentsTableForAnalytics/InstrumentsTableForAnalytics";
import { SelectorETFMarkets } from "../ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";
import { PeerDetailSegments } from "../detail/PeerDetail";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { styled } from "@mui/system";

type DispersionTabDetailProps = {
  id: string;
  data: any;
  type: "ETF" | "Stock";
  nameFormatterHelper: Function;
  segment: PeerDetailSegments;
  dispatch: (action) => any;
  updateParams?: (params: {
    peerId: string;
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    trimOutliers: boolean;
  }) => any;
  peerCardinality: number;
  onChangeParams: (params: {
    performance: "1_week" | "1_month" | "3_months" | "6_months" | "12_months";
    intervals: 4 | 10 | 20;
    trimOutliers: boolean;
  }) => void;
  isLoading?: boolean;
};

const StyledSwitch = styled((props: any) => (
  <FormControlLabel
    sx={{
      fontSize: "12px!important",
    }}
    control={
      <Switch
        sx={{
          fontSize: "12px!important",
        }}
        size="small"
        checked={props.trimOutliers}
        onChange={(e) =>
          props.handleParamsChange(e.target.checked, "trimOutliers")
        }
      />
    }
    classes={{
      label: props.className,
    }}
    label="Trim Outliers"
  />
))({
  fontSize: "12px !important",
});

const performanceOptions = [
  {
    label: "3 Months",
    value: "3_months",
  },
  {
    label: "6 Months",
    value: "6_months",
  },
  {
    label: "12 Months",
    value: "12_months",
  },
];

const percentageOptions = [
  {
    label: "25%",
    value: 4,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "5%",
    value: 20,
  },
];

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const DispersionTabDetail = forwardRef(
  (
    {
      data,
      type,
      nameFormatterHelper,
      segment,
      dispatch,
      updateParams,
      peerCardinality,
      onChangeParams,
      isLoading,
      id,
    }: DispersionTabDetailProps,
    ref
  ) => {
    const [performance, setPerformance] = useState<
      "1_week" | "1_month" | "3_months" | "6_months" | "12_months"
    >("6_months");
    const [trimOutliers, setTrimOutliers] = useState(false);
    const [intervals, setIntervals] = useState<4 | 10 | 20>(4);
    const [target, setTarget] = useState(id);
    const { rootNodeX, rootNodeY, taxonomiesMapY, taxonomiesMapX } =
      useTaxonomyByType(type === "ETF" ? "ETF" : "stock");
    const environment = useEnvironment();
    const showDispersionRating = useMemo(() => {
      const configuration =
        environment.get("account")["product"]["configuration"]["analysis_peer"];

      return (
        configuration?.tabs?.Stocks?.dispersion?.dipersionByRating ?? false
      );
    }, [environment]);

    useEffect(() => setTarget(id), [id]);

    const title = useMemo(() => {
      const { where, what, zDimension } = decodePeerId(target);
      const whatName = taxonomiesMapY[what]?.name ?? what;
      const whereName = taxonomiesMapX[where]?.name ?? where;

      let name = "";

      if (whatName !== rootNodeY) {
        name = whereName;
      }

      if (whereName !== rootNodeX.name) {
        name += ` - ${whatName}`;
      }

      if (zDimension !== "microLarge") {
        const sizes = zDimension.split(/(?=[A-Z])/);
        const from = sizes[0]?.toLowerCase();
        const to = sizes[1]?.toLowerCase();

        name += ` - ${capitalize(from)}`;

        if (to != null) {
          name += ` - ${capitalize(to)} Cap`;
        } else {
          name += ` Cap`;
        }
      }

      if (name.length === 0) {
        name = "Any";
      }

      return name;
    }, [target, rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY]);

    const handleParamsChange = useCallback(
      (value, field: "performance" | "intervals" | "trimOutliers") => {
        const params: any = { performance, intervals, trimOutliers };

        params[field] = value;

        switch (field) {
          case "performance": {
            setPerformance(value);

            break;
          }

          case "intervals": {
            setIntervals(value);

            break;
          }

          case "trimOutliers": {
            setTrimOutliers(value);

            break;
          }
        }

        onChangeParams(params);
      },
      [intervals, onChangeParams, performance, trimOutliers]
    );

    useImperativeHandle(ref, () => {
      return {
        getDispersionTabParams: () => ({
          performance,
          trimOutliers,
          intervals,
        }),
      };
    });

    const onTableRowClick = useCallback(
      (peerId) => {
        const params = {
          peerId,
          performance,
          intervals,
          trimOutliers,
        };

        setTarget(peerId);

        if (updateParams) {
          updateParams(params);
        }
      },
      [intervals, performance, trimOutliers, updateParams]
    );

    return isLoading === true ? (
      <DispersionSkeleton />
    ) : (
      <Grid
        container
        mt={1}
        display={"flex"}
        flex={1}
        boxShadow={3}
        p={1}
        borderRadius={2}
      >
        {peerCardinality > 0 ? (
          <Grid container item md={12} spacing={1} flex={1} display={"flex"}>
            {data?.dispersion ? (
              <Grid item md={3} flex={1} display={"flex"}>
                {/* Instruments Table */}
                {peerCardinality > 3 ? (
                  <InstrumentsTableForAnalytics
                    nameFormatter={nameFormatterHelper}
                    segment={segment as any}
                    type={type}
                    data={data}
                    dispatch={dispatch}
                    paramsUpdate={onTableRowClick}
                  />
                ) : (
                  <Card>
                    <CardContent>
                      <Typography fontSize={"14px"} fontWeight={"bold"}>
                        The {type} you are lookng for has not enough
                        constituents to calculate dispersion (at least 4)
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            ) : (
              <></>
            )}
            <Grid item md={9} flex={1} display={"flex"}>
              {/* Dispersion data */}
              <Card sx={{ flex: 1, display: "flex" }}>
                <CardContent
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "5px!important",
                  }}
                >
                  {data?.dispersion ? (
                    <>
                      {peerCardinality > 0 ? (
                        <>
                          {peerCardinality > 3 && (
                            <>
                              {data?.dispersion?.bottom?.cardinality === 0 &&
                              data?.dispersion?.top?.cardinality === 0 &&
                              data?.dispersion?.middle?.cardinality === 0 ? (
                                <Typography
                                  fontSize={"14px"}
                                  fontWeight={"bold"}
                                >
                                  {type === "ETF"
                                    ? "Not enough ETFs to calculate dispersion"
                                    : "Not enough Stocks to calculate dispersion"}
                                </Typography>
                              ) : (
                                <>
                                  <Card
                                    sx={{
                                      display: "flex",
                                      border: "1px solid #2A7090",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "space-between",
                                        paddingTop: "5px",
                                        paddingBottom: "5px!important",
                                      }}
                                    >
                                      <Typography>{title}</Typography>
                                      <SelectorETFMarkets
                                        options={performanceOptions}
                                        selectedOption={performance}
                                        selectOption={(value) =>
                                          handleParamsChange(
                                            value,
                                            "performance"
                                          )
                                        }
                                      />
                                      <SelectorETFMarkets
                                        selectOption={(value) =>
                                          handleParamsChange(value, "intervals")
                                        }
                                        options={percentageOptions}
                                        selectedOption={intervals}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <StyledSwitch
                                          handleParamsChange={
                                            handleParamsChange
                                          }
                                          trimOutliers={trimOutliers}
                                        />
                                        <InfoTooltip
                                          style={{
                                            top: 5,
                                          }}
                                          title={""}
                                          text="The outliers are securities that perform abnormally relative to the average of all stocks in the same set."
                                        />
                                      </div>
                                    </CardContent>
                                  </Card>
                                  <Box
                                    mt={1}
                                    flex={1}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    gap={1}
                                  >
                                    <DispersionBlock
                                      data={data.dispersion}
                                      intervalType="top"
                                      intervalValue={intervals}
                                      type={type}
                                      showRating={showDispersionRating}
                                    />
                                    <DispersionBlock
                                      data={data.dispersion}
                                      intervalType="middle"
                                      intervalValue={intervals}
                                      type={type}
                                      showRating={showDispersionRating}
                                    />
                                    <DispersionBlock
                                      data={data.dispersion}
                                      intervalType="bottom"
                                      intervalValue={intervals}
                                      type={type}
                                      showRating={showDispersionRating}
                                    />
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <Typography fontSize={"14px"} fontWeight={"bold"}>
                          Any {type} was found
                        </Typography>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={12} mt={1}>
            <Card>
              <CardContent>
                <Typography fontSize={"14px"} fontWeight={"bold"}>
                  Any {type} was found
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }
);

const DispersionSkeleton = () => {
  return (
    <Box p={1} mt={1} borderRadius={"4px"} boxShadow={3} display={"flex"}>
      <Box display={"flex"} flex={3} mr={1}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display={"flex"} flex={9}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={70} />
              <Skeleton variant="rectangular" width={"100%"} height={70} />
              <Skeleton variant="rectangular" width={"100%"} height={70} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
