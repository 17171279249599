import { useEffect, useMemo, useState } from "react";
import { useImmerReducer } from "use-immer";
import { ClusterAnalytics } from "../../../../../../../api/compute/ClusterAnalytics";
import { getTaxonById } from "../../../../../../../api/compute/Taxon";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import Controls from "./Controls";
import styles from "./Dispersion.module.scss";
import DispersionContent from "./DispersionContent";
import { ControlsState, controlsReducer, state } from "./TabControlsReducer";

type DispersionTabProps = {
  syncWysiwyg: (state) => void;
  list: {
    id: number;
    type: "BASKET" | "PORTFOLIO";
    assetType: any;
  };
};

const getAnalytic = (params) => {
  let analytic = "pq";

  switch (params["timeframe"]) {
    case "1_week":
      analytic = "pw";
      break;
    case "1_month":
      analytic = "pm";
      break;
    case "3_months":
      analytic = "pq";
      break;
    case "6_months":
      analytic = "ps";
      break;
    case "12_months":
      analytic = "py";
      break;
    default:
      throw new Error(`Unknown performance ${params["timeframe"]}`);
  }

  return analytic;
};

const getDispesion = async (
  dataManager: ClusterAnalytics,
  params,
  list,
  cluster,
  fetchRatingFields = false
) => {
  const analytic = getAnalytic(params);

  const analytics = [
    `${analytic}#avg#false`,
    `${analytic}#max#false`,
    `${analytic}#min#false`,
    "cardinality",
  ];

  if (fetchRatingFields != null && fetchRatingFields === true) {
    const ratingFields = [
      "A",
      "B",
      "C",
      "D",
      "N",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "N_%",
    ];

    analytics.push(...ratingFields);
  }

  const config = dataManager
    .createConfiguration()
    .analytics(analytics)
    .clusters(cluster)
    .method("DRILL_DOWN")
    .universeFromList([JSON.stringify(list?.id)], list?.type);

  const response = await config.fetchAnalytics();

  return dataManager.decodeAnalyticsList(response);
};

/**
 *
 * @param {params}
 * @param type "cumulative" | "segmentOnly" | "dispersionDrillDown"
 *
 * Returns the cluster configuration to get all data needed to print dispersion widgets.
 * We need this method because we have to make 3 calls to the cluster API. The reason
 * is that as well as dispersion, that is the result of a clusterization on a specific dimension,
 * we want to get the dispersion of the whole portfolio/basket. In addition to this we want to show
 * in dispersion's widgets, titles that cannot be clusterized, and to do that we call the cluster API to
 * get back the cardinality of this titles.
 */
const buildCluster = (
  { dimension, segment, analytic, interval, trimOutliers },
  type: "cumulative" | "segment" | "drilldown"
) => {
  let cluster: any = [];

  switch (type) {
    case "drilldown":
    default:
      cluster = [
        {
          dimension,
          transform: {
            function: "taxonomy",
            params: {
              level: segment,
            },
          },
        },
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: interval,
              trimOutliers: trimOutliers,
            },
          },
        },
      ];

      /**
       * If segment is equal to size is needed to change cluster semantic
       */
      if (segment === "3 Level") {
        const sizeCLuster: any = {
          dimension: "marketcap",
          transform: {
            function: "size",
          },
        };

        cluster.splice(0, 1, sizeCLuster);
      }

      break;

    case "cumulative":
      cluster = cluster = [
        {
          dimension: analytic,
          transform: {
            function: "topbottom",
            params: {
              perc: interval,
              trimOutliers: trimOutliers,
            },
          },
        },
      ];

      break;

    case "segment":
      const config =
        segment !== "3 Level"
          ? {
              dimension,
              transform: {
                function: "taxonomy",
                params: {
                  level: segment,
                },
              },
            }
          : {
              dimension: "marketcap",
              transform: {
                function: "size",
              },
            };
      cluster = [config];
  }

  return cluster;
};

const getAssetType = (list) => {
  if (!list) {
    return [];
  }

  const { assetType } = list;

  if (assetType != null && Object.keys(assetType).length) {
    const acceptedTypes = [
      "Stock",
      "ETF",
      "Commodity",
      "Index",
      "Sector",
      "Currency",
    ];

    const constituentsTypes: any = [];

    for (const key of Object.keys(assetType)) {
      if (acceptedTypes.includes(key)) {
        constituentsTypes.push(key);
      }
    }

    return constituentsTypes;
  }

  return [];
};

const getFieldFromSelector = (
  selector: ControlsState["etfSegment"] | ControlsState["segment"]
) => {
  switch (selector) {
    case "all":
    case "1 Industry":
      return "icb";
    case "3 Level":
      return "SizeClassification";
    case "3 Sector":
      return "industry";
    case "Area":
    case "Country":
    case "Region":
      return "country";
    case "all_etf":
    case "etfclass":
      return "etfclass";
    case "etfgeo":
      return "etfgeo";
  }
};

const getParams = (state: ControlsState, isOnlyEtf) => {
  if (state == null) {
    return;
  }

  const params = {
    segment: "",
    dimension: "",
    trimOutliers: state["trimOutliers"],
    interval: state["intervals"],
    timeframe: state["period"],
  };

  if (isOnlyEtf) {
    const segment = state["etfSegment"];

    switch (segment) {
      case "etfgeo":
        params["segment"] = "Country";
        params["dimension"] = "etfgeo";

        break;

      case "etfclass":
        params["dimension"] = "etfclass";
        params["segment"] = "1 Industry";

        break;

      case "all_etf":
        params["segment"] = "1 Industry";
        params["dimension"] = "etfclass";

        break;
    }
  } else {
    const segment = state["segment"];

    switch (segment) {
      case "Area":
      case "Country":
      case "Region":
        params["segment"] = segment;
        params["dimension"] = "country";

        break;

      case "all":
        params["segment"] = "1 Industry";
        params["dimension"] = "icb";

        break;

      case "1 Industry":
        params["segment"] = segment;
        params["dimension"] = "icb";

        break;

      case "3 Level":
        params["segment"] = segment;
        params["dimension"] = "marketcap";

        break;
    }
  }

  return params;
};

const mergeDispersionData = (rawData, list, env, isOnlyEtf, field) => {
  const { dispersion, cumulative, clusters } = rawData;
  let data = { ...dispersion };

  const cumulativeDispersion = {
    dispersion: cumulative ?? {},
    name: list?.type === "BASKET" ? "Basket" : "Portfolio",
    cardinality:
      cumulative?.top?.cardinality +
      cumulative?.middle?.cardinality +
      cumulative?.bottom?.cardinality,
  };

  if (Object.keys(cumulativeDispersion.dispersion).length) {
    data[list?.type === "BASKET" ? "Basket" : "Portfolio" ?? "All"] =
      cumulativeDispersion;
  }

  const rawTaxonomies = env.get("rawTaxonomies");
  const taxonFields = env.get("taxonomyFields");
  const adjustedField = getFieldFromSelector(field);
  const taxonField =
    taxonFields[isOnlyEtf ? "ETF" : "security"][adjustedField ?? ""];

  const taxonomy =
    field === "3 Level"
      ? rawTaxonomies["SizeClassification"]
      : rawTaxonomies[taxonField];
  const normalizeCluster = {};

  for (const [key, value] of Object.entries(clusters)) {
    const countryName = getTaxonById(key, [taxonomy])["name"];
    normalizeCluster[countryName] = value;
  }

  for (const [key, value] of Object.entries<any>(normalizeCluster)) {
    if (!(key in data)) {
      data[key] = {
        dispersion: {
          top: {
            average: null,
            max: null,
            min: null,
            cardinality: value.cardinality,
            dataTotalCount: value.dataTotalCount,
            data: value.data,
          },
          bottom: {
            average: null,
            max: null,
            min: null,
            cardinality: value.cardinality,
            dataTotalCount: value.dataTotalCount,
            data: value.data,
          },
          middle: {
            average: null,
            max: null,
            min: null,
            cardinality: value.cardinality,
            dataTotalCount: value.dataTotalCount,
            data: value.data,
          },
        },
        cardinality: value.cardinality,
        name: key,
      };
    } else {
      data[key] = {
        ...data[key],
        cardinality:
          data[key]?.dispersion?.top?.cardinality +
          data[key]?.dispersion?.middle?.cardinality +
          data[key]?.dispersion?.bottom?.cardinality,
      };
    }
  }

  return data;
};

export default function DispersionTab({
  syncWysiwyg,
  list,
}: DispersionTabProps) {
  const { row } = styles;
  const [dispersion, setDispersion] = useState<any>();
  const [isDispersionEmpty, setIsDispersionEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [assetType, setAssetType] = useState([]);
  const [error, setError] = useState<null | string>();
  const [pageState, setPageState] = useState<
    "content" | "loader" | "error" | "emptyData"
  >("loader");
  const [controlsState, dispatch] = useImmerReducer(controlsReducer, state);
  const environment = useEnvironment();

  const clusterAPI = useMemo(
    () => new ClusterAnalytics(environment.get("setup")),
    [environment]
  );

  /**
   * Sync state with the component parent to make UI state accessible from report generator
   */
  useEffect(() => {
    syncWysiwyg(controlsState);
  }, [controlsState, syncWysiwyg]);

  /**
   * Set the asset type to use right controls
   */
  useEffect(() => {
    setAssetType(getAssetType(list));
  }, [list]);

  /**
   * Get data dispersion on page load
   */
  useEffect(() => {
    const getList = async () => {
      setIsLoading(true);

      try {
        const assetType = getAssetType(list);
        const isBasketMultiTypes = assetType.length > 1;
        const isOnlyEtf = !isBasketMultiTypes && assetType[0] === "ETF";
        const params: any = getParams(controlsState, isOnlyEtf) ?? {};

        params["analytic"] = getAnalytic(params);

        const clusters = {
          drillDown: buildCluster(params, "drilldown"),
          cumulative: buildCluster(params, "cumulative"),
          segmentOnly: buildCluster(params, "segment"),
        };

        const cumulativeResponse = await getDispesion(
          clusterAPI,
          params,
          list,
          clusters.cumulative,
          true
        );

        const dispersion = await getDispesion(
          clusterAPI,
          params,
          list,
          clusters.drillDown
        );

        const segmentCluster = await getDispesion(
          clusterAPI,
          params,
          list,
          clusters.segmentOnly
        );

        const rawData = {
          dispersion,
          cumulative: cumulativeResponse,
          clusters: segmentCluster,
        };

        const aggregatedData = mergeDispersionData(
          rawData,
          list,
          environment,
          isOnlyEtf,
          isOnlyEtf ? controlsState.etfSegment : controlsState.segment
        );

        setIsLoading(false);

        /**
         * Check if the dispersion has found some cluster
         */
        if (Object.keys(aggregatedData ?? {}).length) {
          setIsDispersionEmpty(false);
        } else {
          setIsDispersionEmpty(true);
        }

        setDispersion(aggregatedData);
      } catch (error: any) {
        setIsLoading(false);
        //Handle the error UI side
        setError(error?.["message"]);

        //Log the error for developers
        console.log(error.message);
      }
    };

    getList();

    return () => {
      setIsLoading(false);
      setDispersion(null);
      setError(null);
      setIsDispersionEmpty(true);
    };
  }, [clusterAPI, controlsState, environment, list]);

  /**
   * Handle content based on page state
   */
  useEffect(() => {
    if (isLoading) {
      setPageState("loader");

      return;
    }

    if (error) {
      setPageState("error");

      return;
    }

    if (isDispersionEmpty) {
      setPageState("emptyData");

      return;
    }

    if (!isLoading && !error && !isDispersionEmpty) {
      setPageState("content");

      return;
    }
  }, [error, isDispersionEmpty, isLoading]);

  return (
    <section className={row}>
      <section className="tablePanel_wrapper">
        <Controls
          segment={controlsState["segment"]}
          timeframe={controlsState["period"]}
          interval={controlsState["intervals"]}
          dispatch={dispatch}
          viewType={controlsState["viewType"]}
          trimOutliers={controlsState["trimOutliers"]}
          constituentsType={assetType}
          etfSegment={controlsState["etfSegment"]}
          listType={list?.type ?? "PORTFOLIO"}
        />
        <DispersionContent
          dispersionData={dispersion}
          state={controlsState}
          contentToShow={pageState}
          constituentsType={assetType}
        />
      </section>
    </section>
  );
}
