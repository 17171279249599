import { deepClone } from "../../deepClone";
import { Environment } from "../../Environment";
import { FormOptions } from "../../trendrating/app/formatter/FormOptions";
import { FilterStateETF, FilterStocksState } from "./filterReducer";

export type ActionCallbacks = {
    where?: (
        value: FilterStocksState["where"],
        listItemColletion: JSX.Element[]
    ) => void;
    what?: (
        value: FilterStocksState["what"],
        listItemsCollection: JSX.Element[]
    ) => void;
    size?: (
        value: FilterStocksState["size"],
        listItemsCollection: JSX.Element[]
    ) => void;
    whereETF?: (
        value: FilterStateETF["whereETF"],
        listItemsCollection: JSX.Element[]
    ) => void;
    assetClass?: (
        value: FilterStateETF["assetClass"],
        listItemsCollection: JSX.Element[]
    ) => void;
    marketCap?: (
        value: FilterStateETF["marketCap"],
        listItemsCollection: JSX.Element[]
    ) => void;
};

export const getSizeOptions = (environment: Environment) => {
    return new FormOptions(environment.get("formatter")).getField("SIZE");
};

export const isElementIn = (targetList, element) => {
    const isIn =
        targetList.find((item) => item === element) != null ? true : false;
    return isIn;
};

export const renderActiveFilterList = (
    filterState: FilterStateETF | FilterStocksState,
    actions: ActionCallbacks
) => {
    const listItemsCollection: JSX.Element[] = [];

    for (const [key, value] of Object.entries(filterState)) {
        switch (key) {
            case "where":
                {
                    const list = value as FilterStocksState["where"];
                    if (list.length) {
                        actions[key]!(list, listItemsCollection);
                    }
                }
                break;

            case "what":
                {
                    const list = value as FilterStocksState["what"];
                    if (list.length) {
                        actions[key]!(list, listItemsCollection);
                    }
                }

                break;

            case "size":
                {
                    const list = value as FilterStocksState["size"];
                    if (list != null) {
                        actions[key]!(list, listItemsCollection);
                    }
                }
                break;

            case "whereETF":
                {
                    const list = value as FilterStateETF["whereETF"];
                    if (list.length) {
                        actions[key]!(list, listItemsCollection);
                    }
                }

                break;

            case "assetClass":
                {
                    const list = value as FilterStateETF["assetClass"];
                    if (list.length) {
                        actions[key]!(list, listItemsCollection);
                    }
                }

                break;

            case "marketCap": {
                const list = value as FilterStateETF["marketCap"];
                if (list != null) {
                    actions[key]!(list, listItemsCollection);
                }
            }
        }
    }

    return listItemsCollection;
};

export const getMarketCapLabel = (
    value: {
        left: number | null;
        right: number | null;
    } | null
) => {
    let label = "";
    const { left, right } = value ?? { left: null, right: null };
    const oneMillion = 10 ** 6;
    const oneBillion = 10 ** 9;

    const isBillion = (value) => {
        if (value >= oneBillion) {
            return true;
        } else {
            return false;
        }
    };

    if (left != null && right != null) {
        label = `${
            isBillion(left)
                ? "$" + (left / oneBillion).toFixed(0) + "B"
                : "$" + (left / oneMillion).toFixed(0) + "M"
        }-${
            isBillion(right)
                ? "$" + (right / oneBillion).toFixed(0) + "B"
                : "$" + (right / oneMillion).toFixed(0) + "M"
        }`;
        return label
    }

    if (left != null && right == null) {
        label = `${
            isBillion(left)
                ? "over $" + (left / oneBillion).toFixed(0) + "B"
                : "over $" + (left / oneMillion).toFixed(0) + "M"
        }`;
        return label
    }

    return "Any";
};

export const _getUserPreferences = (environment) => {
    var _preferences =
        environment.get("account")["user"]["preferences"]["preferences"] ??
        null;

    var preferences = {
        where: null,
    };

    if (_preferences?.screening?.markets != null) {
        var constraints = _preferences.screening.markets;

        if (constraints["ids"] != null) {
            preferences["where"] = constraints["ids"];
        }
    }

    return preferences;
};

export const getInitialState = (
    blankState,
    alertsSavedFilters,
    userPreferences,
    type
) => {
    let state = deepClone(blankState);

    if (type === "etf") {
        const etfFilters = alertsSavedFilters?.ETFsFilters ?? null;
        const savedPreferences = userPreferences?.where ?? null;

        if (etfFilters != null && etfFilters.whereETF.length) {
            state.whereETF = etfFilters.whereETF;
        } else if (savedPreferences != null && savedPreferences.length) {
            state.whereETF = savedPreferences;
        }

        state.assetClass = etfFilters?.assetClass ?? [];
        state.marketCap = etfFilters?.marketCap ?? { left: null, right: null };
    } else {
        const stockFilters = alertsSavedFilters?.stocksFilters ?? null;
        const savedPreferences = userPreferences?.where ?? null;

        if (stockFilters != null && stockFilters.where.length) {
            state.where = stockFilters.where;
        } else if (savedPreferences != null && savedPreferences.length) {
            state.where = savedPreferences;
        }

        state.what = stockFilters?.what ?? [];
        state.size = stockFilters?.size ?? { left: null, right: null };
    }

    return state;
};
