import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDefinition } from "tabulator-tables";
import { ErrorBoundary } from "../../../../../../../../../../ErrorBoundary";
import { Instruments } from "../../../../../../../../../../api/compute/Instruments";
import { Strategies } from "../../../../../../../../../../api/compute/Strategies";
import { LongShortHistogram } from "../../../../../../../../../../components/LongShortHistogram/LongShortHistogram";
import { deepClone } from "../../../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../trendrating/date/TDate";
import { widgetsConfiguration } from "../../../../../../../../widgets/widgetsConfiguration";
import { REPORT_COLUM_EVENET_NAME } from "../../../../../../longShort/CombineStrategies";
import Allocation from "./widgets/Allocation";
import Costituents from "./widgets/Costituents";
import FrameNavigator from "./widgets/FrameNavigator";
import Momentum from "./widgets/Momentum";
import Performance from "./widgets/Performance";
import Pie from "./widgets/Pie";
import Table from "./widgets/Table";
import Timeline from "./widgets/Timeline";

type Props = {
  data: any;
  hideRationaleBtn?: boolean;
};

const TABLE_EXPAND_EVENT_ID = "expand-holding-result-table";

async function getState(data, format, today) {
  const timelineData = prepareTimelineData(
    data.holdings,
    format,
    [...data.strategyResult.POS],
    today
  );
  const frame = timelineData.frames[timelineData?.frames?.length - 1];
  let newState = {
    contributions: null,
    contributionsFiltered: {
      holdings: null,
      peer: null,
    },
    main: data.holdings.data.main,
    long: data.holdings.data.long,
    short: data.holdings.data.short,
    frame: frame,
    frameDay: null,
    frameDayPrev: null,
    strategy: data.holdings.strategy,
    strategyResult: data.strategyResult,
    timelineData: timelineData,
  };

  var holdingsHistory = newState.strategyResult.POS;
  var day = TDate.millisecondsToDays(frame.day);
  var allocation: any = null;
  var framePrev: any = null;
  framePrev = holdingsHistory[holdingsHistory.length - 2];
  allocation = holdingsHistory[holdingsHistory.length - 1];
  allocation.currency = newState.strategy.params.strategy.currency;
  var __newState: any = deepClone(newState);
  __newState.contributionsFiltered = { peer: null };
  __newState.frame = frame;
  __newState.frameDay = day;
  __newState.framePrev = framePrev;
  return __newState;
}

export default function Holdings({ data, hideRationaleBtn = false }: Props) {
  const format = useFormatter();
  const environment = useEnvironment();
  const appEnvironment = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(
    () => new Strategies(appEnvironment),
    [appEnvironment]
  );
  const instrumentsAPI = useMemo(
    () => new Instruments(appEnvironment),
    [appEnvironment]
  );
  const [state, setState] = useState<any>(null);
  const [hasHedging, setHasHedging] = useState(false);
  const [properties, setProperties] = useState<string[]>([]);
  const [contribution, setContribution] = useState<any>([]);
  const [contributionHoldings, setContributionHoldings] = useState<any>([]);
  const [contributionData, setContributionData] = useState<any>();
  const [histogramData, setHistogramData] = useState<
    | {
        portfolioHoldings: { symbol: string; weight: number }[];
        portfolioLongHoldings: { symbol: string; weight: number }[];
        portfolioShortHoldings: { symbol: string; weight: number }[];
      }
    | undefined
  >();
  const [expandHoldings, setExpandHoldings] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  const { on, remove, dispatch } = useEventBus();

  useEffect(() => {
    // Make editor always visible when this tab is unmounted
    return () => dispatch(TABLE_EXPAND_EVENT_ID, { value: false });
  }, [dispatch]);

  const tableExpandListener = useCallback(() => {
    setExpandHoldings(!expandHoldings);
  }, [expandHoldings]);

  useEffect(() => {
    on(TABLE_EXPAND_EVENT_ID, tableExpandListener);

    return () => remove(TABLE_EXPAND_EVENT_ID, tableExpandListener);
  }, [on, remove, tableExpandListener]);

  const today = useMemo(
    () => appEnvironment.today.today,
    [appEnvironment.today.today]
  );

  useEffect(() => {
    setContributionHoldings(contribution);
  }, [contribution]);

  const strategy = useMemo(() => {
    return data.holdings.strategy;
  }, [data.holdings.strategy]);

  const strategyCurrency = useMemo(() => {
    return strategy.currency;
  }, [strategy.currency]);

  const HPOS = useMemo(() => {
    return data.strategyResult.POS;
  }, [data.strategyResult.POS]);

  const timelineData = useMemo(
    () => prepareTimelineData(data.holdings, format, [...HPOS], today),
    [HPOS, data.holdings, format, today]
  );
  const framesMap = useMemo(() => {
    return timelineData.frames.reduce(
      (prev, current) => ({ ...prev, [current.name]: current }),
      []
    );
  }, [timelineData.frames]);

  const collectDataForHistogram = useCallback(() => {
    const currentFrame = state.frame;
    const calendarHPOS = {};
    const pointInTime = TDate.millisecondsToDays(currentFrame.day);

    for (const POS of HPOS) {
      calendarHPOS[POS.d] = POS;
    }

    const portfolio = calendarHPOS?.[pointInTime]?.v ?? [];

    const portfolioLong: { symbol: string; weight: number }[] = [];
    const portfolioShort: { symbol: string; weight: number }[] = [];

    let weight: number | null = null;

    for (const allocation of portfolio) {
      weight = allocation.A;

      if (weight) {
        if (weight < 0) {
          portfolioShort.push({ symbol: allocation.S, weight });
        } else {
          portfolioLong.push({ symbol: allocation.S, weight });
        }
      }
    }

    setHistogramData({
      portfolioHoldings: portfolio.map((allocation) => ({
        symbol: allocation.S,
        weight: allocation.A,
      })),
      portfolioLongHoldings: portfolioLong,
      portfolioShortHoldings: portfolioShort,
    });
  }, [HPOS, state?.frame]);

  /**
   * Get strategy contribution for the given allocation
   *
   * @param {object} params - contributions request parameters
   * @param {string} currency - the currency
   * @param {number} d - day
   * @param {number} de - day end
   * @param {number} i - strategy value
   * @param {number} i_g - strategy gain
   * @param {object[]} v - holdings
   * @param {number}   v[].A - weight (Allocation)
   * @param {string}   v[].D - day
   * @param {string}   v[].S - symbol
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  const getContributionsAPI = useCallback(
    async (params, fields?) => {
      const contributions = await strategiesAPI.contributions(params);

      var holdings = contributions.v;

      var holdingsRawMap = {};
      var symbols: any = [];
      var item: any = null;
      for (let i = 0, length = holdings.length; i < length; i++) {
        item = holdings[i];

        if (item.S !== "CASH") {
          holdingsRawMap[item.S] = item;
          symbols.push(item.S);
        }
      }

      var widgetConfiguration =
        widgetsConfiguration["widgets/builder/holdings"];
      var properties = widgetConfiguration["properties"];

      if (fields) {
        properties = fields.map((item) => ({ date: null, property: item }));
      }

      // Add type to format columns
      properties = [...properties, { date: null, property: "type" }];

      const dates = [params.d];
      const responseInstruments = await instrumentsAPI.fetch(
        { type: "cube", properties, symbols, multiDates: dates },
        true
      );

      const fetchResult: { data: any[] } = {
        data: [],
      };

      for (const s in responseInstruments) {
        fetchResult.data.push({
          ...responseInstruments[s][params.d],
          symbol: s,
        });
      }

      // merge weights, ratings etc.
      var holding: any = null;
      holdings = [];
      var holdingsMap: any = {};
      var symbol: any = null;
      for (var i = 0, length = fetchResult.data.length; i < length; i++) {
        holding = fetchResult.data[i];

        symbol = holding.symbol;
        holding.dr = holdingsRawMap[symbol].D;
        holding.weight = holdingsRawMap[symbol].A;
        holding.performance = holdingsRawMap[symbol].G;
        holding.contribution = holdingsRawMap[symbol].C;
        holding.performanceCurrency = holdingsRawMap[symbol].GC;
        holding.contributionCurrency =
          (holdingsRawMap[symbol].GC - holdingsRawMap[symbol].G) *
          holdingsRawMap[symbol].A;
        holding.contributionMarket =
          holding["contribution"] - holding["contributionCurrency"];

        holdings.push(holding);

        holdingsMap[symbol] = holding;
      }

      var response = {
        data: contributions,
        holdings: holdings,

        // holdings can be filtered by clicking on
        // pie chart
        holdingsFiltered: holdings,

        holdingsMap: holdingsMap,
      };

      return response;
    },
    [instrumentsAPI, strategiesAPI]
  );

  const getDataOnLanding = useCallback(
    async (response, properties) => {
      const holdingsHistory = mapToIntervals(HPOS, today);
      const frame = response.frame;
      let day = TDate.millisecondsToDays(frame.day);
      let allocation: any = null;
      let item: any = null;

      for (let i = 0, length = holdingsHistory.length; i < length; i++) {
        item = holdingsHistory[i];
        if (day === item.d) {
          allocation = item;
          break;
        }
      }

      allocation.currency = strategyCurrency;

      try {
        const res = await getContributionsAPI(allocation, properties);

        // Default sort by contribution
        res?.holdings.sort((a, b) =>
          a.contribution > b.contribution ? -1 : 1
        );

        setContribution(res?.holdings ?? []);
        setContributionData(res);
        setReadyToRender(true);
      } catch (error) {
        console.log(error);
      }
    },
    [HPOS, getContributionsAPI, strategyCurrency, today]
  );

  useEffect(() => {
    getState(data, format, today).then((response) => {
      setState(response);

      // To avoid extra server calls the data will be fetched when table is rendered and give the columns that must be fetched
      // see at setInitialProperties method.
    });
  }, [data, format, today]);

  useEffect(() => {
    if (state) {
      if (
        (state.long && state.long.length) ||
        (state.long && state.long.length)
      ) {
        setHasHedging(true);
      }
    }
  }, [state]);

  useEffect(() => {
    if (hasHedging === true) {
      collectDataForHistogram();
    }
  }, [collectDataForHistogram, hasHedging]);

  const contributions = useCallback(
    async (fields?) => {
      const holdingsHistory = mapToIntervals(HPOS, today);
      const frame = state.frame;
      let day = TDate.millisecondsToDays(frame.day);
      let allocation: any = null;
      let item: any = null;

      for (let i = 0, length = holdingsHistory.length; i < length; i++) {
        item = holdingsHistory[i];
        if (day === item.d) {
          allocation = item;
          break;
        }
      }

      allocation.currency = strategyCurrency;

      try {
        const response = await getContributionsAPI(
          allocation,
          fields ?? properties
        );

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    [
      HPOS,
      getContributionsAPI,
      properties,
      state?.frame,
      strategyCurrency,
      today,
    ]
  );

  const onTimeframeChange = useCallback(
    (frameFromTimeline) => {
      const frame = framesMap[frameFromTimeline.name];
      _dataGetPieTable(
        frame,
        strategyCurrency,
        HPOS,
        setState,
        getContributionsAPI,
        setContribution,
        setContributionData,
        today,
        properties
      );
    },
    [HPOS, framesMap, getContributionsAPI, properties, strategyCurrency, today]
  );

  const getContribution = useCallback(
    async (fields?) => {
      const contributionsData = await contributions(fields);
      setContribution(contributionsData?.holdings ?? []);
      setContributionData(contributionsData);
    },
    [contributions]
  );

  const chartRef = useRef<any>(null);

  const handleContrubutionTableColsChange = useCallback(
    (fields) => {
      const properties = fields.map((items) => items.field);
      setProperties(properties);
      getContribution(properties);
      setReportColumns(
        fields.map((items) => ({ field: items.field, label: items.label }))
      );
    },
    [getContribution]
  );
  const [reportColumns, setReportColumns] = useState<any>([]);
  useEffect(() => {
    dispatch(REPORT_COLUM_EVENET_NAME, {
      columns: reportColumns,
    });
  }, [dispatch, reportColumns]);

  const setInitialProperties = useCallback(
    (cols: ColumnDefinition[]) => {
      const properties = cols.map((item) => item.field);
      const _temp = cols.map((items) => ({
        field: items.field,
        label: items.title,
      }));
      setReportColumns(_temp);
      setProperties(properties as string[]);
      getDataOnLanding(state, properties);
    },
    [getDataOnLanding, state]
  );

  /**
   * Gets the rationale for a frame
   *
   * @param {object} params - request parameters
   * @param {number} params.day - trendrating days
   * @param {object} params.framePrev - the frame
   * @param {object} params.strategy - the strategy
   *
   * @returns {dojo/promise/Promise} a promise fulfilled with the
   *       handled data of the response
   */
  // const _getRationale = useCallback(
  //   (params) => {
  //     var paramsRationale = {
  //       allocation: params.framePrev,
  //       day: params.day,
  //       properties: [
  //         {
  //           date: null,
  //           property: "currency",
  //         },
  //         {
  //           date: null,
  //           property: "name",
  //         },
  //         {
  //           date: null,
  //           property: "ticker",
  //         },
  //         {
  //           date: null,
  //           property: "symbol",
  //         },
  //         {
  //           date: null,
  //           property: "type",
  //         },
  //       ],
  //       strategy: params.strategy,
  //     };

  //     return strategiesAPI.rationale(paramsRationale);
  //   },
  //   [strategiesAPI]
  // );

  const pieHandlers = useMemo(() => {
    return {
      pieClick: (e) =>
        pieClickHandler(e, state, setState, contribution, setContribution),
    };
  }, [contribution, state]);

  return (
    <Box height={"100%"} display="flex" flexDirection={"column"} p={1}>
      {expandHoldings === false && (
        <>
          {state ? (
            <>
              <Timeline
                ref={chartRef}
                onTimeFrameChange={onTimeframeChange}
                timelineData={timelineData}
              />

              <Box display={"flex"} flexDirection={"row"} gap={1} paddingY={1}>
                <FrameNavigator
                  timelineData={timelineData}
                  frame={state?.frame?.frameIndex}
                  onTimeFrameChange={onTimeframeChange}
                  strategyRebalance={
                    state?.strategy?.params?.strategy?.rebalance
                  }
                />
                <Momentum frame={state?.frame} />

                {state?.frame?.cardinality != null ? (
                  <Costituents
                    constituents={state?.frame?.cardinality}
                    peer={state?.contributionsFiltered?.peer}
                  />
                ) : null}

                <Performance
                  state={state}
                  contributionData={contributionData}
                  contributionHoldings={contributionHoldings}
                />
                <Allocation
                  hideRationaleBtn={hideRationaleBtn}
                  state={state}
                  // getRationaleData={getRationale}
                  // getRationaleBase={_getRationale}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <Box
        display={"flex"}
        flexDirection={"row"}
        overflow="visible hidden"
        flex={1}
        width={"100%"}
        boxShadow={3}
        gap={1}
      >
        <ErrorBoundary fallback={<></>}>
          {expandHoldings === false && readyToRender ? (
            <>
              {hasHedging === true && histogramData ? (
                <Card sx={{ width: "30%", display: "flex", minHeight: 0 }}>
                  <CardContent sx={{ flex: 1 }}>
                    <LongShortHistogram
                      longHoldings={histogramData.portfolioLongHoldings}
                      shortHoldings={histogramData.portfolioShortHoldings}
                      diffHoldings={histogramData.portfolioHoldings}
                    />
                  </CardContent>
                </Card>
              ) : (
                <>
                  <Pie
                    date={HPOS?.[state?.frame?.frameIndex]?.d}
                    holdings={contributionHoldings}
                    handlers={pieHandlers}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <Card
            sx={{
              boxShadow: 3,
              height: "100%",
              width: expandHoldings === true ? "100%" : "70%",
            }}
          >
            <CardContent
              sx={{
                p: 1,
                pb: "8px !important",
                height: "100%",
                display: "flex",
                width: "100%",
              }}
            >
              <Table
                expandEventId={TABLE_EXPAND_EVENT_ID}
                setColumnsFields={setInitialProperties}
                onTableColsChangeHandler={handleContrubutionTableColsChange}
                data={contributionHoldings}
                rowClickHandler={(e: any) => {
                  chartRef?.current?.insertSerie({
                    holding: e,
                    strategyResult: state?.strategyResult,
                  });
                }}
              />
            </CardContent>
          </Card>
        </ErrorBoundary>
      </Box>
    </Box>
  );
}

const convertObjectDatesToMillis = (serie, hPOSmap) => {
  const result: any = [];

  for (const point of serie) {
    const hPOS = hPOSmap?.[point.d] ?? null;
    const d = hPOS?.d ?? null;

    point.d = d != null ? TDate.daysToMilliseconds(d) : null;
    point.de = TDate.daysToMilliseconds(point.d);

    result.push({ ...point });
  }

  return result;
};

const buildSeries = (target, format, rawData, serie) => {
  if (!rawData.length) {
    return;
  }

  var item: any = null;
  var name: any = null;
  var record: any = null;
  var trendrating: any = null;
  for (let i = 0, length = rawData.length; i < length; i++) {
    item = rawData[i];
    name = "";

    if (i === length - 1) {
      name = _formatDate(item.d, format);
    } else {
      name =
        _formatDate(item.d, format) +
        " - " +
        _formatDate(rawData[i + 1].d, format);
    }

    // meta info for event management
    trendrating = {
      allocation: item.A,
      cardinality: item.CARD,
      day: item.d,
      frameIndex: i,
      name: name,
      performance: item.P,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      NA: 0,
    };
    for (const rate in item.ratingWeights) {
      trendrating[rate] = item.ratingWeights[rate];
    }

    if (serie === "main") {
      target["frames"][i] = trendrating;

      // allocation
      record = {
        x: item.d,
        y: item.A,
        name: name,
        trendrating: trendrating,
      };
      target["allocation"].push(record);
      // cardinality
      record = {
        x: item.d,
        y: item.CARD,
        name: name,
        trendrating: trendrating,
      };
      target["cardinality"].push(record);
      // momentum
      for (const rate in item.ratingWeights) {
        record = {
          x: item.d,
          y: item.ratingWeights[rate],
          name: name,
          trendrating: trendrating,
        };
        target["momentum"][rate].push(record);
      }
      // performance
      record = {
        x: item.d,
        y: item.P,
        name: name,
        trendrating: trendrating,
      };
      target["performance"].push(record);
    } else {
      if (!(serie in target)) {
        target[serie] = [];
      }

      // allocation long/short
      record = {
        x: item.d,
        y: item.A,
        name: name,
        trendrating: trendrating,
      };

      target[serie].push(record);
    }
  }
};

const mapToIntervals = (hPos, today) => {
  let nextAllocation: any = null;
  let nextAllocationDate: any = null;

  return hPos.map((allocation, index) => {
    nextAllocation = hPos?.[index + 1];
    // The allocation end date corresponds to the new allocation start date
    nextAllocationDate = nextAllocation?.d ?? today;

    return { ...allocation, d: allocation.d, de: nextAllocationDate };
  });
};

const prepareTimelineData = (value, format, hPOS, today) => {
  var data: any = {
    frames: [],
    momentum: {
      A: [],
      B: [],
      C: [],
      D: [],
      NA: [],
    },
    cardinality: [],
    performance: [],
    allocation: [],
  };

  const hPosToIntervals = mapToIntervals(hPOS, today);

  // Map HPOS to retrive the d of the point who's the allocation data is referred
  const hPOSmap = hPosToIntervals.reduce((prev, current) => {
    prev[current.de] = current;

    return prev;
  }, {});

  let rawData = convertObjectDatesToMillis(deepClone(value.data.main), hPOSmap);
  let rawLongSerie = convertObjectDatesToMillis(
    deepClone(value.data.long),
    hPOSmap
  );
  let rawShortSerie = convertObjectDatesToMillis(
    deepClone(value.data.short),
    hPOSmap
  );

  // Object in JS are references types so passing it to this function updates it
  // remember to not clone the object in funcion body
  buildSeries(data, format, rawData, "main");
  buildSeries(data, format, rawLongSerie, "long");
  buildSeries(data, format, rawShortSerie, "short");

  return data;
};
const _formatDate = (milliseconds, format) => {
  return format.custom("date", {
    options: {
      format: ["d", "M", "Y"],
      isMillisecond: true,
      notAvailable: {
        input: null,
        output: "",
      },
      separator: " ",
    },
    output: "HTML",
    value: milliseconds,
    valueHelper: null,
  });
};
const _dataGetPieTable = (
  frame,
  strategyCurrency,
  HPOS,
  setState,
  getContribution,
  setContribution,
  setContributionData,
  today,
  fields?
) => {
  var holdingsHistory = mapToIntervals(HPOS, today);
  var day = TDate.millisecondsToDays(frame.day);
  var allocation: any = null;
  var framePrev: any = null;
  var item: any = null;
  for (let i = 0, length = holdingsHistory.length; i < length; i++) {
    item = holdingsHistory[i];
    if (day === item.d) {
      framePrev = i - 1 >= 0 ? holdingsHistory[i - 1] : item;
      allocation = item;
      break;
    }
  }
  allocation.currency = strategyCurrency;
  getContribution(allocation, fields).then((response) => {
    setContribution(response.holdings);
    setContributionData(response);

    setState((prevState) => {
      var newState = deepClone(prevState);

      newState.contributionsFiltered = {
        peer: null,
      };
      newState.frame = frame;
      newState.frameDay = day;
      newState.framePrev = framePrev;
      return newState;
    });
  });
};

// const getRationale = async (state, getRationaleFn) => {
//   var params = {
//     day: state.frameDay,
//     framePrev: state.framePrev,
//     strategy: state.strategy,
//   };
//   const returningValue = await getRationaleFn(params);
//   return returningValue;
// };

const pieClickHandler = (
  event,
  state,
  setState,
  contribution,
  setContribution
) => {
  var peer = event.value;
  var symbols = peer["constituents"];
  var holdingsMap: any = contribution.reduce(
    (prev, current) => ({ ...prev, [current.symbol]: current }),
    {}
  );
  var holdingsFiltered: any = [];
  for (let i = 0; i < symbols.length; i++) {
    holdingsFiltered.push(holdingsMap[symbols[i]]);
  }
  var newState = deepClone(state);
  setContribution(holdingsFiltered);
  newState.contributionsFiltered.peer = peer;
  setState(newState);
};
