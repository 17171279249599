export type BreakdownType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    dimension: string;
    rows: 11;
  };
  presentation: {
    holdings: boolean;
    totalWeight: boolean;
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_headline_CONTENT"
    | "SET_CONTENT_headline_ISENABLED"
    | "SET_CONTENT_dimension"
    | "SET_CONTENT_rows"
    | "SET_PRESENTATION_holdings"
    | "SET_PRESENTATION_totalWeight";
  payload: any;
};

export const breakdownReducer = (draft: BreakdownType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_headline_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_headline_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_dimension":
      draft.content.dimension = action.payload;
      break;
    case "SET_CONTENT_rows":
      draft.content.rows = action.payload;
      break;
    case "SET_PRESENTATION_holdings":
      draft.presentation.holdings = action.payload;
      break;
    case "SET_PRESENTATION_totalWeight":
      draft.presentation.totalWeight = action.payload;
      break;
    default:
      break;
  }
};
