/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/_currency
 * @summary Most important currency symbols
 *
 */
export const _currency = {
    /*
     * USD   $   $           &#36;       &#x24;  Dollar Sign
     * GBP   £   &pound;     &#163;      &#xA3;  Pound Sterling
     * JPY   ¥   &yen;       &#165;      &#xA4;  Yen Symbol
     * EUR   €   &euro;      &#128;      &#x80;  Euro Symbol
     */
    symbol: {
        EUR: "&euro;",
        GBP: "&pound;",
        JPY: "&yen;",
        USD: "$",
    },
};
