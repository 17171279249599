import { Box } from "@mui/material";
import { ListNews } from "./widgets/ListNews";
import { ReportDays } from "./widgets/ReportDays";
import UniverseNews from "./widgets/UniverseNews/UniverseNews";

type Props = {
  days: any;
  alerts: {
    baskets: any;
    pinned: any;
    portfolios: any;
    universe: any;
  };
  setDays: Function;
  setListNews: Function;
  setUniverse: Function;
};

export default function DailyReportV1({
  days,
  alerts,
  setDays,
  setListNews,
  setUniverse
}: Props) {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <ReportDays days={days} setDays={setDays} />
      <ListNews
        input={alerts.portfolios}
        type="PORTFOLIO"
        setListNews={setListNews}
      />
      <ListNews
        input={alerts.baskets}
        type="BASKET"
        setListNews={setListNews}
      />
      <UniverseNews setUniverse={setUniverse}  input={alerts.universe} />
    </Box>
  );
}
