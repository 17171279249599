/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/_StoredObjects
 * @summary Fixes the wrong server behaviour and provides an unified API to
 *      manage StoredObjects
 *
 * !!! IMPORTANT !!!
 * Classes that inherit _StoredObjects MUST define storedObjectType according to
 * supported server types
 *
 */

import { StoredObjectType } from "../types/Api";
import { deepClone } from "../deepClone";
import { endpoints } from "./endpoints";
import { _Base } from "./_Base";
export class _StoredObjects extends _Base {
  storedObjectType?: StoredObjectType;

  /**
   * Create a stored object
   *
   * @param {object} object
   * @param {string} object.name
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async create(object: any, objectType?: StoredObjectType | null) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.storedObjects.create;

    const _params = deepClone(object);
    if ("id" in _params) {
      delete _params.id;
    }

    // created/modified
    const timestamp = new Date().toISOString();
    _params.created = timestamp;
    _params.modified = timestamp;
    _params.ownerId = this.environment.account.user?.id;
    _params.type = objectType ? objectType : this.storedObjectType;

    const response = await this.preparePost(url, _params, null);
    return this._create(response);
  }

  /**
   *
   * @param id The preference object id
   * @param objectType The type of the preference object
   * @param userId ATTENTION this params is used only in the reports hub page to get shared reports templates from the user 35
   *
   * @returns return the found preference object
   */
  async get(id?: any, objectType?: StoredObjectType, userId?: number) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url: string = endPointRoot + endpoints.storedObjects.get;

    let params: any = {
      ownerId: userId ?? this.environment.account.user?.id,
      type: objectType ? objectType : this.storedObjectType,
    };

    if (id != null) {
      url = endPointRoot + endpoints.storedObjects.getById;
      params = {
        id: id,
      };
    }

    const response = await this.prepareGet(url, params, null);
    return this._get(response);
  }

  async search(payload) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url: string = endPointRoot + endpoints.storedObjects.select;

    try {
      const response = await this.preparePost(url, payload);
      return response?.data?.ids;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async select(objectType: StoredObjectType) {
    if (!objectType) {
      throw new Error("Preference type is not defined");
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url: string = endPointRoot + endpoints.storedObjects.select;
    const userId = this.environment.account.user?.id;

    const payload = {
      searches: [
        {
          filters: [
            {
              dimension: "type",
              segments: [objectType],
            },
            {
              dimension: "ownerId",
              segments: [userId],
            },
          ],
        },
      ],
    };

    try {
      const response = await this.preparePost(url, payload);
      return response?.data?.ids;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async fetch(params: {
    ids: number[];
    properties: string[];
    objectType: StoredObjectType;
  }) {
    if (!params.objectType) {
      throw new Error("Preference type is not defined");
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    let url: string = endPointRoot + endpoints.storedObjects.fetch;

    const payload: {
      classType: StoredObjectType;
      id: number[];
      extendedRequest: { onDemandResult: { dimension: string }[] };
    }[] = [
      {
        classType: params.objectType,
        id: params.ids,
        extendedRequest: {
          onDemandResult: [],
        },
      },
    ];

    const fields: { dimension: string }[] = [];

    for (const property of params.properties) {
      fields.push({ dimension: property });
    }

    payload[0].extendedRequest.onDemandResult = fields;

    try {
      const response = await this.preparePost(url, payload);

      return response?.data?.[0]?.rows;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async remove(object: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.storedObjects.remove;

    if ("id" in object && object.id != null) {
      const queryParams = { id: object.id };
      const response = await this.prepareGet(url, queryParams, null);
      return this._remove(response);
    }
  }

  async update(object: any, objectType?: StoredObjectType | null) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.storedObjects.update;

    const _params = deepClone(object);

    // created/modified
    const timestamp = new Date().toISOString();
    _params["created"] = _params["created"] == null ? null : _params["created"]; // managing old without attribute
    _params["modified"] = timestamp;
    _params["ownerId"] = this.environment.account.user?.id;
    _params["type"] = objectType ? objectType : this.storedObjectType;

    const result = await this.preparePost(url, _params, null);
    return this._update(result);
  }
  // ----------------------------------------------------- private methods
  _create(response: any) {
    const error = this.simulateHttpError(response);

    if (error != null) {
      return error;
    }

    return this._fix(response["data"]);
  }

  _get(response: any) {
    if ("rows" in response.data) {
      return response.data.rows.map((row: any) => this._fix(row));
    } else if ("preference" in response.data) {
      const error = this.simulateHttpError(response);

      if (error != null) {
        return error;
      }

      return this._fix(response.data.preference);
    }

    return [];
  }

  _fix(storedObject: any) {
    const _storedObject = deepClone(storedObject["object"]);
    _storedObject["id"] = storedObject["id"];
    _storedObject["ownerId"] = storedObject["ownerId"];

    if (storedObject["tags"] != null) {
      _storedObject["tags"] = storedObject["tags"];
    }

    return _storedObject;
  }

  _remove(response: any) {
    const error = this.simulateHttpError(response);

    if (error != null) {
      return error;
    }

    return { removed: true };
  }

  _update(response: any) {
    const error = this.simulateHttpError(response);

    if (error != null) {
      return error;
    }

    return this._fix(response["data"]);
  }
}
