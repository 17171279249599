/* eslint-disable no-template-curly-in-string */
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { Environment } from "../../../../../../../../Environment";
import { Preferences } from "../../../../../../../../api/account/Preferences";
import {
  dataInjector,
  extractSymbols,
} from "../../../../../../../../api/compute/commons";
import { Spinner } from "../../../../../../../../components/Spinner/Spinner";
import { deepClone } from "../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import { widgetsConfiguration } from "../../../../../../widgets/widgetsConfiguration";
import { Switch } from "@mui/material";

type TodayIsightProps = {
  holdings: any[];
  tcrToday: any;
  tcrYesterday: any;
};

type Actions =
  | { type: "SYNC_STATE"; payload: typeof initConfig }
  | {
      type: "SHOW_ALERT";
      payload: { values: boolean; updatePreferences: (state) => void };
    }
  | {
      type: "SHOW_NEW_HIGH";
      payload: { values: boolean; updatePreferences: (state) => void };
    }
  | {
      type: "SHOW_NEW_LOW";
      payload: { values: boolean; updatePreferences: (state) => void };
    };

const initConfig = {
  showAlert: true,
  showNewHigh: false,
  showNewLow: true,
};

const tranformStateInPreferences = (state: typeof initConfig) => {
  const PREFERENCES_KEY = {
    showAlert: "hasUpgradeDowngrade",
    showNewHigh: "hasNewHigh",
    showNewLow: "hasNewLow",
  };

  const preferences = {};

  for (const key in state) {
    preferences[PREFERENCES_KEY[key]] = state[key];
  }

  return preferences;
};

const reducer = (state: typeof initConfig, action: Actions) => {
  switch (action.type) {
    case "SYNC_STATE":
      return action.payload;

    case "SHOW_ALERT": {
      const newState = { ...state, showAlert: action.payload.values };
      action.payload.updatePreferences(tranformStateInPreferences(newState));
      return newState;
    }

    case "SHOW_NEW_HIGH": {
      const newState = { ...state, showNewHigh: action.payload.values };
      action.payload.updatePreferences(tranformStateInPreferences(newState));
      return newState;
    }

    case "SHOW_NEW_LOW": {
      const newState = { ...state, showNewLow: action.payload.values };
      action.payload.updatePreferences(tranformStateInPreferences(newState));
      return newState;
    }

    default:
      return state;
  }
};

const dataGet = async (env: Environment, symbols: string[]) => {
  const properties =
    widgetsConfiguration["widgets/portfolio/analysis/overview/news"][
      "properties"
    ];

  const params = {
    filters: [
      {
        dimension: "symbol",
        segments: symbols,
      },
    ],
    page: {
      page: 1,
      rows: symbols.length,
    },
  };

  const apiInstrument = env.get("http")["instruments"];
  return await apiInstrument.newFilterAndFetch(params, "security", properties);
};

const dataPrepareSortByRank = (a, b) => {
  if (a["rank0"] === b["rank0"]) {
    if (a["rank1"] === b["rank1"]) {
      return a["rank2"] > b["rank2"] ? -1 : 1;
    }
    return a["rank1"] > b["rank1"] ? -1 : 1;
  }
  return a["rank0"] > b["rank0"] ? 1 : -1;
};

const dataPrepare = (rawData, list) => {
  const portfolio = list;
  var _data = dataInjector(deepClone(rawData["data"]), portfolio["positions"], [
    "weight",
  ]);
  let dataArray: any = [];
  let hasNews: any = false;
  let isUpgrade: any = null;
  let item: any = null;
  let rankNewHL: any = null;
  let value: any = null;

  let RANK_0_DOWNGRADE: any = 1;
  let RANK_0_UPGRADE: any = 2;
  let RANK_0_NEW_LOW_12M: any = 3;
  let RANK_0_NEW_LOW_06M: any = 4;
  let RANK_0_NEW_LOW_03M: any = 5;
  let RANK_0_NEW_LOW_01M: any = 6;
  let RANK_0_NEW_HIGH_12M: any = 7;
  let RANK_0_NEW_HIGH_06M: any = 8;
  let RANK_0_NEW_HIGH_03M: any = 9;
  let RANK_0_NEW_HIGH_01M: any = 10;
  let RANK_0_DURATION: any = 11;
  let RANK_0_MAGNITUDE: any = 12;
  let RANK_0_UPI: any = 13;
  let RANK_0_UNDEFINED: any = Number.MAX_SAFE_INTEGER;

  for (let i = 0, length = _data.length; i < length; i++) {
    item = _data[i];
    // alerts
    if (item["lr"] === 0) {
      isUpgrade = item["rc"] > item["rrr"];
      dataArray.push({
        type: isUpgrade ? "Upgrade" : "Downgrade",
        rank0: isUpgrade ? RANK_0_UPGRADE : RANK_0_DOWNGRADE,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
      hasNews = true;
    }
    // new lows
    rankNewHL = RANK_0_UNDEFINED;
    value = item["lhl"];
    if (value <= -260) {
      rankNewHL = RANK_0_NEW_LOW_12M;
      hasNews = true;
    } else if (value <= -120) {
      rankNewHL = RANK_0_NEW_LOW_06M;
      hasNews = true;
    } else if (value <= -60) {
      rankNewHL = RANK_0_NEW_LOW_03M;
      hasNews = true;
    } else if (value <= -20) {
      rankNewHL = RANK_0_NEW_LOW_01M;
      hasNews = true;
    }

    if (value <= -20) {
      dataArray.push({
        type: "NewLow",
        rank0: rankNewHL,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
    }

    // new high
    rankNewHL = RANK_0_UNDEFINED;
    if (value >= 260) {
      rankNewHL = RANK_0_NEW_HIGH_12M;
      hasNews = true;
    } else if (value >= 120) {
      rankNewHL = RANK_0_NEW_HIGH_06M;
      hasNews = true;
    } else if (value >= 60) {
      rankNewHL = RANK_0_NEW_HIGH_03M;
      hasNews = true;
    } else if (value >= 20) {
      rankNewHL = RANK_0_NEW_HIGH_01M;
      hasNews = true;
    }

    if (value >= 20) {
      dataArray.push({
        type: "NewHigh",
        rank0: rankNewHL,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
    }

    // notifications: duration, magnitude, UPI
    if (item["lduration"] === 0) {
      dataArray.push({
        type: "Duration",
        rank0: RANK_0_DURATION,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
      hasNews = true;
    }

    if (item["lmagnitude"] === 0) {
      dataArray.push({
        type: "Magnitude",
        rank0: RANK_0_MAGNITUDE,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
      hasNews = true;
    }

    if (item["lupi"] === 0) {
      dataArray.push({
        type: "Upi",
        rank0: RANK_0_UPI,
        rank1: item["weight"],
        rank2: item["marketcap"],
        security: item,
      });
      hasNews = true;
    }
  }

  dataArray.sort(dataPrepareSortByRank);

  // it keeps the index of a symbol inside data in order to add
  // incrementally properties to a security
  var dataMatrix: any = {};

  var insights: any = [];
  var symbol: any = null;
  var dataItem: any = null;
  for (let i = 0; i < dataArray.length; i++) {
    item = dataArray[i];
    symbol = item["security"]["symbol"];
    if (!(symbol in dataMatrix)) {
      dataMatrix[symbol] = insights.length;
      dataItem = deepClone(item["security"]);
      dataItem["hasDowngrade"] = false;
      dataItem["hasDuration"] = false;
      dataItem["hasMagnitude"] = false;
      dataItem["hasNewHigh"] = false;
      dataItem["hasNewLow"] = false;
      dataItem["hasUpgrade"] = false;
      dataItem["hasUpi"] = false;
      insights.push(dataItem);
    } else {
      dataItem = insights[dataMatrix[symbol]];
    }
    dataItem["has" + item["type"]] = true;
  }

  var data = {
    hasNews: hasNews,
    insights: insights,
    tcr: {
      from: portfolio["tcrYesterday"],
      to: portfolio["tcrToday"],
    },
  };

  return data;
};

const setPreparedData = async (env, symbols, set, list) => {
  const rawData = await dataGet(env, symbols);
  const data = dataPrepare(rawData, list);

  set(data);
};

const dataDisplayRating = (item, format) => {
  const innerHTML: string[] = [];
  var options = {
    notAvailable: {
      input: 0,
      output: "-",
    },
  };
  innerHTML.push(
    format.custom("rating", {
      options: options,
      output: "HTML",
      value: item["rrr"],
      valueHelper: {
        rateChange: item["lrr"],
        rateDate: item["drr"],
        ratePrev: null,
      },
    })
  );
  innerHTML.push("to");
  innerHTML.push(
    format.custom("rating", {
      options: options,
      output: "HTML",
      value: item["rc"],
      valueHelper: {
        rateChange: item["lr"],
        rateDate: item["dr"],
        ratePrev: item["rrr"],
      },
    }) + "."
  );
  return innerHTML;
};

const displayConfiguration = (data, format, state: typeof initConfig) => {
  const list: { class: string; innerHTML: string }[] = [];

  if (data?.["hasNews"]) {
    let item = { class: "", innerHTML: "" };

    if (data["tcr"]["from"] !== data["tcr"]["to"]) {
      item = {
        class: "tAnalysisOverview-box--newsItem",
        innerHTML: [
          '<strong>Portfolio <abbr title="Trend Capture Rating">TCR</abbr></strong>',
          data["tcr"]["to"] > data["tcr"]["from"]
            ? "has been upgraded"
            : "has been downgraded",
          "from",
          format.tcr(data["tcr"]["from"], "HTML"),
          "to",
          format.tcr(data["tcr"]["to"], "HTML"),
        ].join(" "),
      };

      list.push(item);
    }

    for (const insight of data["insights"]) {
      // name
      let innerHTML = ["<strong>" + insight["name"] + "</strong>"];
      // alerts: downgrades and upgrades
      if (state.showAlert === true) {
        if (insight["hasDowngrade"] === true) {
          innerHTML.push("has been downgraded from");
          innerHTML = innerHTML.concat(dataDisplayRating(insight, format));
        } else if (insight["hasUpgrade"] === true) {
          innerHTML.push("has been upgraded from");
          innerHTML = innerHTML.concat(dataDisplayRating(insight, format));
        }
      }

      // new highs
      if (state.showNewHigh === true) {
        if (insight["hasNewHigh"] === true) {
          innerHTML.push(
            format.custom("newHighNewLow", {
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
                template: {
                  // eslint-disable-next-line no-template-curly-in-string
                  downgrade: "hits ${label} new high ${icon}",
                  upgrade: "hits ${label} new high ${icon}",
                },
              },
              output: "HTML",
              value: insight["lhl"],
              valueHelper: null,
            })
          );
        }
      }
      // new lows
      if (state.showNewLow === true) {
        if (insight["hasNewLow"] === true) {
          innerHTML.push(
            format.custom("newHighNewLow", {
              options: {
                notAvailable: {
                  input: null,
                  output: "",
                },
                template: {
                  downgrade: "hits ${label} new low ${icon}",
                  upgrade: "hits ${label} new low ${icon}",
                },
              },
              output: "HTML",
              value: insight["lhl"],
              valueHelper: null,
            })
          );
        }
      }

      if (innerHTML.length > 1) {
        item = {
          class: "tAnalysisOverview-box--newsItem",
          innerHTML: innerHTML.join(" "),
        };

        list.push(item);
      }
    }
  }

  return list;
};

export default function TodayInsight({
  holdings,
  tcrToday,
  tcrYesterday,
}: TodayIsightProps) {
  const [config, dispatch] = useReducer(reducer, initConfig);
  const [hasNews, setHasNews] = useState(true);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [newsList, setNewsList] = useState<
    { class: string; innerHTML: string }[]
  >([]);
  const environment = useEnvironment();
  const formatter = useFormatter();
  const apiPreferences = useMemo(
    () => new Preferences(environment.get("setup")),
    [environment]
  );

  const readPreferences = useCallback(async () => {
    let preferences = undefined;

    try {
      const response = await apiPreferences.get();

      if (response) {
        preferences = response?.preferences?.analysisList?.alerts ?? undefined;
      }
    } catch (error) {
      console.log(error);
    } finally {
      return preferences;
    }
  }, [apiPreferences]);

  const updatePreferences = useCallback(
    async ({
      hasUpgradeDowngrade,
      hasNewHigh,
      hasNewLow,
    }: {
      hasUpgradeDowngrade: boolean;
      hasNewLow: boolean;
      hasNewHigh: boolean;
    }) => {
      try {
        // Retrive original object from the Database
        const actualUserPreferences = await apiPreferences.get();

        // Update preferences
        if (actualUserPreferences?.preferences?.analysisList?.alerts) {
          actualUserPreferences.preferences.analysisList.alerts = {
            hasUpgradeDowngrade,
            hasNewHigh,
            hasNewLow,
          };
        }

        // Save the updated preferences
        apiPreferences.save(actualUserPreferences);
      } catch (error) {
        console.log(error);
      }
    },
    [apiPreferences]
  );

  useEffect(() => {
    if (data != null) {
      if ("hasNews" in data) {
        setHasNews(data["hasNews"]);
      }

      if (config != null) {
        setNewsList(displayConfiguration(data, formatter, config));
      }
    }
  }, [data, config, formatter]);

  useEffect(() => {
    if (holdings != null) {
      const symbols = extractSymbols(holdings);
      if (symbols.length === 0) {
        setHasNews(false);
        return;
      }

      const portfolio = { positions: holdings, tcrToday, tcrYesterday };

      try {
        setError(false);
        setIsLoading(true);
        setPreparedData(environment, symbols, setData, portfolio);
        setIsLoading(false);
      } catch (err) {
        setError(true);
        setIsLoading(false);
        console.log(err);
      }
    }

    return setData(null);
  }, [environment, holdings, tcrToday, tcrYesterday]);

  useEffect(() => {
    readPreferences().then((response: any) => {
      const alertsPreferences = response;
      const stateFromPreferences = {
        showAlert: alertsPreferences?.hasUpgradeDowngrade ?? false,
        showNewLow: alertsPreferences?.hasNewLow ?? false,
        showNewHigh: alertsPreferences?.hasNewHigh ?? false,
      };

      dispatch({ type: "SYNC_STATE", payload: stateFromPreferences });
    });
  }, [readPreferences]);

  const onToggleChange = useCallback(
    (e, type: "SHOW_ALERT" | "SHOW_NEW_LOW" | "SHOW_NEW_HIGH") => {
      dispatch({
        type,
        payload: {
          values: e,
          updatePreferences,
        },
      });
    },
    [updatePreferences]
  );

  return (
    <div className="tAnalysisOverview-box tAnalysisOverview-box--100 tAnalysisOverview-box--news">
      <h1 className="tAnalysisOverview-title">Today</h1>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", marginRight: 10, alignItems: "center" }}>
          <Switch
            size="small"
            value={config.showAlert}
            onChange={(e, checked) => onToggleChange(checked, "SHOW_ALERT")}
          />{" "}
          <span style={{ marginLeft: 5 }}>Alerts</span>
        </div>
        <div style={{ display: "flex", marginRight: 10, alignItems: "center" }}>
          <Switch
            size="small"
            value={config.showNewHigh}
            onChange={(e, checked) => onToggleChange(checked, "SHOW_NEW_HIGH")}
          />{" "}
          <span style={{ marginLeft: 5 }}>New Highs</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            size="small"
            value={config.showNewLow}
            onChange={(e, checked) => onToggleChange(checked, "SHOW_NEW_LOW")}
          />{" "}
          <span style={{ marginLeft: 5 }}>New Lows</span>
        </div>
      </div>
      {!collapse && (
        <>
          {!error && !isLoading ? (
            <ul
              className="tAnalysisOverview-news"
              data-dojo-attach-point="nodeNews"
            >
              {!hasNews ? (
                <li className="tAnalysisOverview-newsItem">
                  No data to display
                </li>
              ) : (
                newsList.map((news, index) => (
                  <li
                    style={{ marginTop: 5 }}
                    key={`${index}__${news.class}`}
                    className={news.class}
                    dangerouslySetInnerHTML={{
                      __html: news.innerHTML,
                    }}
                  ></li>
                ))
              )}
            </ul>
          ) : error && !isLoading ? (
            <Spinner />
          ) : (
            <p>Something gone wrong during loading, please retry later</p>
          )}
        </>
      )}
      <div
        className="tAnalysisOverview-collapser"
        onClick={() => setCollapse(!collapse)}
      >
        <span
          className="tAnalysisOverview-collapserLabel"
          style={{ marginRight: 5 }}
        >
          {!collapse ? "Collapse" : "Expand"}
        </span>
        <span
          className={`tAnalysisOverview-collapserIcon i-triangle-${
            !collapse ? "up" : "down"
          }`}
        ></span>
      </div>
    </div>
  );
}
