import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

type Props = {
  lists: any;
  state: any;
  todaysPortfolioHandler: Function;
};

const TodaysPortfolio = forwardRef(
  ({ lists, state, todaysPortfolioHandler }: Props, ref) => {
    const _lists = useMemo(() => lists, [lists]);
    const [radioValue, setRadioValue] = useState("strategy");
    const [choosenPortfolio, setChoosenPortfoliio] = useState(null);
    const [rebalanceTodayList, setRebalanceTodayList] = useState(false);
    const [rebalanceTodayStrategy, setRebalanceTodayStrategy] = useState(
      state.todayList.isRebalanceToday ?? false
    );
    const [isValid, setIsValid] = useState(true);

    const radioHandler = useCallback(
      (e) => {
        switch (e.target.value) {
          case "cash":
            setChoosenPortfoliio(null);
            setRebalanceTodayList(false);
            setRebalanceTodayStrategy(false);
            todaysPortfolioHandler({ radio: "cash", value: { cash: true } });
            break;
          case "strategy":
            setChoosenPortfoliio(null);
            setRebalanceTodayList(false);
            setRebalanceTodayStrategy(false);
            todaysPortfolioHandler({
              radio: "strategy",
              value: { isRebalanceToday: false },
            });

            break;
          case "list":
            setRebalanceTodayStrategy(false);
            todaysPortfolioHandler({
              radio: "list",
              value: {
                id: choosenPortfolio,
                isRebalanceToday: rebalanceTodayList,
              },
            });

            break;
        }
        setRadioValue(e.target.value);
      },
      [choosenPortfolio, rebalanceTodayList, todaysPortfolioHandler]
    );

    useEffect(() => {
      if (
        state?.priceLevel?.isBacktestingInherited &&
        state.todayList.cash != null
      ) {
        radioHandler({ target: { value: "strategy" } });
      }
    }, [
      radioHandler,
      state?.priceLevel?.isBacktestingInherited,
      state.todayList.cash,
    ]);

    const selectPortfolioHandler = useCallback(
      (e) => {
        setChoosenPortfoliio(e.target.value);
        setIsValid(true);
        todaysPortfolioHandler({
          radio: "list",
          value: {
            id: e.target.value,
            isRebalanceToday: rebalanceTodayList,
          },
        });
      },
      [rebalanceTodayList, todaysPortfolioHandler]
    );

    useImperativeHandle(
      ref,
      () => ({
        validate: (isValid: boolean) => {
          setIsValid(isValid);
        },
      }),
      []
    );

    return (
      <Card sx={{ flex: 1, boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box pb={1}>
            <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
              Today's portfolio
            </Typography>
          </Box>
          <Divider
            sx={{ width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
          <Box pt={1}>
            <FormControl>
              <RadioGroup value={radioValue} onChange={radioHandler}>
                <FormControlLabel
                  value="strategy"
                  control={<Radio />}
                  label={
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography sx={{ flex: 2 }}>
                        Strategy allocation
                      </Typography>
                      {radioValue === "strategy" &&
                        state?.priceLevel?.isBacktestingInherited && (
                          <FormGroup sx={{ flex: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    setRebalanceTodayStrategy(e.target.checked);
                                    todaysPortfolioHandler({
                                      radio: "strategy",
                                      value: {
                                        isRebalanceToday: e.target.checked,
                                      },
                                    });
                                  }}
                                  checked={rebalanceTodayStrategy}
                                />
                              }
                              label="Rebalance today"
                            />
                          </FormGroup>
                        )}
                    </Box>
                  }
                />
                {!state?.priceLevel?.isBacktestingInherited && (
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="100% cash until next rebalancing date"
                  />
                )}
                <FormControlLabel
                  sx={{
                    alignItems: radioValue === "list" ? "flex-start" : "center",
                  }}
                  value="list"
                  control={<Radio size="small" />}
                  label={
                    <Box display={"flex"} gap={1} flexDirection={"column"}>
                      <Typography>Portfolio</Typography>
                      {radioValue === "list" && (
                        <Box display={"flex"} flexDirection={"column"}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    setRebalanceTodayList(e.target.checked);
                                    todaysPortfolioHandler({
                                      radio: "list",
                                      value: {
                                        id: choosenPortfolio,
                                        isRebalanceToday: e.target.checked,
                                      },
                                    });
                                  }}
                                  checked={rebalanceTodayList}
                                />
                              }
                              label="Rebalance today"
                            />
                          </FormGroup>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Select
                              onChange={selectPortfolioHandler}
                              size="small"
                              error={!isValid}
                              defaultValue={"-"}
                            >
                              <MenuItem disabled value={"-"}>
                                <Typography>Select a portfolio</Typography>
                              </MenuItem>
                              {_lists.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                  <Typography>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.label,
                                      }}
                                    ></span>
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                            {!isValid && (
                              <FormHelperText error={!isValid}>
                                Please,Select a portfolio.
                              </FormHelperText>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    );
  }
);
export default TodaysPortfolio;
