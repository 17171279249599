import { Box, Divider, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  orientation: "landscape" | "portrait";
  isEditable: boolean;
  name: string;
  theme: "theme2" | "theme1";
  selected: boolean;
  onEdit: Function;
  onDelete: Function;
};

const formatTheme = (theme: "theme1" | "theme2") => {
  if (theme === "theme2") {
    return "Black and White";
  } else if (theme === "theme1") {
    return "Blue and Gray";
  } else {
    return null;
  }
};

export default function TemplateListItem({
  orientation,
  isEditable,
  name,
  theme,
  selected,
  onEdit,
  onDelete,
}: Props) {
  const imgBaseURL = useMemo(
    () => "/js/trendrating-report/resources/images/",
    []
  );
  const imgURLSufix = useMemo(() => {
    if (orientation === "landscape" && isEditable) {
      return "page_landscape.png";
    } else if (orientation === "portrait" && isEditable) {
      return "page_portrait.png";
    } else if (orientation === "landscape" && !isEditable) {
      return "page_landscape_default.png";
    } else if (orientation === "portrait" && !isEditable) {
      return "page_portrait_default.png";
    } else {
      return null;
    }
  }, [isEditable, orientation]);

  const [showControls, setShowControls] = useState(false);
  return (
    <Box
      display={"flex"}
      gap={2}
      onMouseLeave={() => setShowControls(false)}
      onMouseEnter={() => setShowControls(true)}
    >
      {/* <div onMouseLeave={}></div> */}
      <Box display={"flex"} alignItems={"center"}>
        <div></div>
        <img src={imgBaseURL + imgURLSufix} alt="orientation" />
      </Box>
      <Box display={"flex"} width={"100%"} flexDirection={"column"}>
        <Typography
          sx={{
            color:
              showControls && !selected
                ? "#2a7092"
                : selected
                ? "#fff"
                : "#000",
          }}
        >
          <strong>{name}</strong>
        </Typography>
        <Divider variant="fullWidth" />

        <Typography sx={{ color: selected ? "#fff" : "#000" }}>
          Orientation: {orientation}
        </Typography>
        <Typography sx={{ color: selected ? "#fff" : "#000" }}>Theme: {formatTheme(theme)}</Typography>
      </Box>
      {(showControls || selected) && (
        <Box display={"flex"} alignItems={"center"}>
          <EditIcon
            onClick={() => onEdit()}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color:
                  showControls && !selected
                    ? "#2a7092"
                    : showControls && selected
                    ? "#fff"
                    : "#000",
              },
            }}
          />
          {isEditable && (
            <DeleteIcon
              onClick={() => onDelete()}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color:
                    showControls && !selected
                      ? "#2a7092"
                      : showControls && selected
                      ? "#fff"
                      : "#000",
                },
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
