import { Environment } from "../../../../Environment";
import { MarketsStorage } from "../../storage/MarketsStorage";

type Params = {
  analytic: string;
  analyticKey: string;
  analyticType: string;
  instrumentType: "stock" | "ETF";
  zDimension: string;
  timeframe: string;
  dispersionTimeframe: string;
  visualization: string;
  whatSortBy: { descending: boolean; property: string };
  whatType: string;
  whereSortBy: { descending: boolean; property: string };
  whereType: string;
} | null;

export class AnalysisMarketsHelper {
  environment: Environment;
  storage;

  constructor(environment) {
    this.environment = environment;
    this.storage = new MarketsStorage(environment.get("setup"));
  }

  ANALYTIC_TYPE = "tcr";

  params: Params = null;
  peers = null;

  async dataGet(constraints) {
    if (constraints == null) {
      return null;
    }

    try {
      const params = this.prepareParams(constraints);

      if (!this.areConstraintsChanged(params)) {
        if (this.peers) {
          return this.peers;
        }
      }

      this.params = params;
      this.peers = await this.storage.get(params);

      return this.peers;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private prepareParams(constraints) {
    const valueConstraintsBar = constraints;
    const valueAnalytic = valueConstraintsBar["analytic"];
    const valueTimeframe = valueConstraintsBar["timeframe"];
    const valueVisualization = valueConstraintsBar["visualization"];
    // instrument type
    const valueInstrumentType = constraints.instrumentType;
    const valueThirdDimension =
      valueInstrumentType !== "ETF"
        ? this.storage.fixPeerSize(valueConstraintsBar["thirdDimension"])
        : valueConstraintsBar["thirdDimension"];
    // what
    let valueWhatSortBy = valueConstraintsBar["whatSortBy"];
    valueWhatSortBy =
      valueWhatSortBy == null || valueWhatSortBy === ""
        ? null
        : this.prepareParamsSortBy(valueWhatSortBy);

    let valueWhatType = valueConstraintsBar["whatType"];
    valueWhatType =
      valueWhatType == null || valueWhatType === "" ? null : valueWhatType;
    // where
    let valueWhereSortBy = valueConstraintsBar["whereSortBy"];
    valueWhereSortBy =
      valueWhereSortBy == null || valueWhereSortBy === ""
        ? null
        : this.prepareParamsSortBy(valueWhereSortBy);

    let valueWhereType = valueConstraintsBar["whereType"];
    valueWhereType =
      valueWhereType == null || valueWhereType === "" ? null : valueWhereType;

    var params = {
      analytic: valueAnalytic,
      analyticKey: [valueAnalytic, valueTimeframe, valueVisualization].join(
        "_"
      ),
      analyticType: this.ANALYTIC_TYPE,
      instrumentType: valueInstrumentType,
      zDimension: valueThirdDimension,
      timeframe: valueTimeframe,
      dispersionTimeframe: constraints.dispersionTimeframe,
      visualization: valueVisualization,
      whatSortBy: valueWhatSortBy,
      whatType: valueWhatType,
      whereSortBy: valueWhereSortBy,
      whereType: valueWhereType,
    };

    return params;
  }

  private prepareParamsSortBy(sortByString) {
    var tokens = sortByString.split("|");

    var sortBy = {
      descending: tokens[1] === "desc" ? true : false,
      property: tokens[0],
    };

    return sortBy;
  }

  areConstraintsChanged(newParams: Params) {
    if (newParams) {
      const {
        analytic,
        analyticKey,
        analyticType,
        instrumentType,
        zDimension,
        timeframe,
        visualization,
        dispersionTimeframe,
        whatSortBy,
        whatType,
        whereSortBy,
        whereType,
      } = newParams;
      if (
        analytic === this.params?.analytic &&
        analyticKey === this.params.analyticKey &&
        analyticType === this.params.analyticType &&
        instrumentType === this.params.instrumentType &&
        zDimension === this.params.zDimension &&
        timeframe === this.params.timeframe &&
        dispersionTimeframe === this.params.dispersionTimeframe &&
        visualization === this.params.visualization &&
        whatSortBy.property === this.params.whatSortBy.property &&
        whatSortBy.descending === this.params.whatSortBy.descending &&
        whatType === this.params.whatType &&
        whereSortBy.property === this.params.whereSortBy.property &&
        whereSortBy.descending === this.params.whereSortBy.descending &&
        whereType === this.params.whereType
      ) {
        return false;
      }

      return true;
    }

    return true;
  }
}
