import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type PerformanceProps = {
  strategyDispatch: Function;
  input: string | null;
};

export default function Performance({ strategyDispatch, input }: PerformanceProps) {
  const { t } = useTranslation();

  const [innerState, setInnerState] = useState(input);
  useEffect(() => {
    setInnerState(input);
    strategyDispatch({ type: "SET_PERFORMANCE", payload: input });
  }, [input, strategyDispatch]);

  return (
    <Card sx={{ height: "100%", flex: 1 }}>
      <CardContent>
        <FormControl
          onChange={(e: any) =>
            strategyDispatch({ type: "SET_PERFORMANCE", payload: e.target.value })
          }
        >
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <Typography variant="subtitle1">{t("page_builder_field_performance_label")}</Typography>
          </FormLabel>
          <RadioGroup value={innerState ?? "NONE"}>
            <FormControlLabel
              value="NONE"
              control={
                <Radio
                  size="small"
                  sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
                />
              }
              label={
                <span style={{ fontSize: "0.7vw" }}>
                  {t("page_builder_field_performance_label_price")}
                </span>
              }
            />
            <FormControlLabel
              value="REBALANCE"
              control={
                <Radio
                  sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
                  size="small"
                />
              }
              label={
                <span style={{ fontSize: "0.7vw " }}>
                  {t("page_builder_field_performance_label_total_return")}
                </span>
              }
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}
