import { useCallback, useMemo } from "react";
import { useTaxonomyByType } from "../../hooks/useTaxonomyByType";
import { FilterStateETF, FilterStocksState } from "./filterReducer";
import { getMarketCapLabel } from "./filterWidgetHelper";
import { Box, Typography } from "@mui/material";

type FilterSummaryProps = {
  filterState: unknown;
  openEditor: (value: boolean) => void;
  widgetFilterType: "stocks" | "ETFs";
};

export function FilterSummary({
  filterState,
  openEditor,
  widgetFilterType,
}: FilterSummaryProps) {
  const { getTaxonomyById } = useTaxonomyByType("stock");
  const { getTaxonomyById: extractTaxonomyETF } = useTaxonomyByType("ETF");

  const areSomeFiltersActive = useMemo(() => {
    const stockFilters = filterState as FilterStocksState;
    const etfFilters = filterState as FilterStateETF;
    if (widgetFilterType === "stocks") {
      const { where, what, size } = stockFilters;
      if (
        where.length ||
        what.length ||
        size!.left != null ||
        size!.right != null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      const { whereETF, assetClass, marketCap } = etfFilters;

      if (
        whereETF.length ||
        assetClass.length ||
        marketCap!.left != null ||
        marketCap!.right != null
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [filterState, widgetFilterType]);

  const render = useCallback(() => {
    const stockFilters = filterState as FilterStocksState;
    const etfFilters = filterState as FilterStateETF;
    if (widgetFilterType === "stocks") {
      const { where, what, size } = stockFilters;
      const wherePhrase = where.map(
        (id) => getTaxonomyById(id, "country").name
      );
      const whatPhrase = what.map((id) => getTaxonomyById(id, "icb").name);

      return (
        <Box display={"flex"} gap={1}>
          <Box>
            <Typography>
              <strong>Market:</strong> {wherePhrase.join(", ")}
            </Typography>
          </Box>
          <Box>
            <Typography>
              <strong>Sector:</strong>{" "}
              {whatPhrase.length > 0 ? whatPhrase.join(", ") : "Any"}
            </Typography>
          </Box>
          <Box>
            <Typography>
              <strong>Market Cap:</strong> {getMarketCapLabel(size)}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      const { whereETF, assetClass, marketCap } = etfFilters;
      const wherePhrase = whereETF.map(
        (id) => extractTaxonomyETF(id, "etfgeo").name
      );
      const whatPhrase = assetClass.map(
        (id) => extractTaxonomyETF(id, "etfclass").name
      );

      return (
        <Box display={"flex"} gap={1}>
          <Box flexWrap={"wrap"}>
            <Typography width={"100%"}>
              <strong>Investment Region:</strong> {wherePhrase.join(", ")}
            </Typography>
          </Box>

          <Box flexWrap={"wrap"}>
            <Typography>
              <strong>Asset Class:</strong>{" "}
              {whatPhrase.length > 0 ? whatPhrase.join(", ") : "Any"}
            </Typography>
          </Box>

          <Box flexWrap={"wrap"}>
            <Typography>
              <strong>Market Cap:</strong> {getMarketCapLabel(marketCap)}
            </Typography>
          </Box>
        </Box>
      );
    }
  }, [extractTaxonomyETF, filterState, getTaxonomyById, widgetFilterType]);

  const onClickButtonHandler = () => {
    openEditor(true);
  };

  const content = useMemo(() => {
    return render();
  }, [render]);

  return (
    <div className="active-filters-wrapper">
      <p className="active-filters-title">
        {areSomeFiltersActive ? "Filtered by:" : `Any ${widgetFilterType}`}
      </p>
      {/* <ul onClick={onClickButtonHandler} className={`active-filters-list`}>
        {listItems()!.collection}
        <button
          onClick={onClickButtonHandler}
          className="active-filters-button"
        >
          <span className="i-edit"></span>
        </button>
      </ul> */}
      {areSomeFiltersActive && content}
      <button onClick={onClickButtonHandler} className="active-filters-button">
        <span className="i-edit"></span>
      </button>
    </div>
  );
}
