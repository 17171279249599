import { ReactElement } from "react";
export type ListContentType = {
  value: string | number;
  content: string | ReactElement;
}[];
export const createPlaceholderFromNode = (
  draggingElementComputedStyle: any
) => {
  const element = document.createElement("div");
  element.style.width = "100%";
  element.style.height = draggingElementComputedStyle.height;
  element.style.display = "block";
  element.style.backgroundColor = "white";
  element.style.boxShadow = "inset 0px 0px 16px 2px rgba(0,0,0,0.1)";
  element.style.borderRadius = "5px";
  element.style.cursor = "grab";
  element.classList.add("draggable-placeholder");
  //! this event listener is needed to prevent
  //! the "return" animation wich can cause misanderstanding
  element.addEventListener("dragover", (event) => {
    event.preventDefault();
  });
  return element;
};
