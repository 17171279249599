import { Box, Card, CardContent, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import SecurityChartModal from "../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltipCore from "../../../../../components/SecurityTooltip/SecurityTooltipCore";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { ColumnDefinition } from "tabulator-tables";
import { createRoot } from "react-dom/client";

type Props = {
  positions: any[];
  tcrStart: number;
};

export default function StartingPortfolio({ positions, tcrStart }: Props) {
  const format = useFormatter();
  const [showSecurityChart, setShowSecurityChart] = useState(false);
  const environment = useEnvironment();
  const [securityFocus, setSecurityFocus] = useState<any>(null);

  const columns = useMemo<ColumnDefinition[]>(() => {
    return [
      {
        title: "Weight",
        field: "weight",
        formatter: (cell) => {
          let value = cell.getData();

          return format.custom("number", {
            options: {
              hasPositiveSign: false,
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: value["weight"],
            valueHelper: null,
          });
        },
      },
      {
        title: "Ticker",
        field: "ticker",
        formatter: (cell) => {
          let _data = cell.getRow().getData();
          return `<div style="display:flex;align-items:center; gap:8px" class="content">${_data?.ticker}</div>`;
        },
        cellMouseEnter: (e, cell) => {
          const cellElement = cell
            .getElement()
            .getElementsByClassName("content")[0];
          let _data = cell.getRow().getData();
          const nodeToAppend = document.createElement("span");
          nodeToAppend.classList.add(_data?.symbol);
          const root = createRoot(nodeToAppend);
          root.render(
            <>
              <SecurityTooltipCore
                symbol={_data?.symbol ?? ""}
                environment={environment}
                chartButtonHandler={(security) => {
                  setSecurityFocus(security);
                  setShowSecurityChart(true);
                }}
                setSecurityData={() => {}}
                additionalButtons={[
                  {
                    label: "Security analysis",
                    callback: () => {
                      setShowSecurityChart(false);
                      window.location.href = `/app/analysis/instrument/${_data?.symbol}/`;
                    },
                  },
                ]}
              />
            </>
          );
          cellElement.appendChild(nodeToAppend);
        },
        cellMouseLeave: (e, cell) => {
          const cellElement = cell.getElement();
          let _data = cell.getRow().getData();
          const element = cellElement.getElementsByClassName(_data?.symbol)[0];
          element.remove();
        },
      },
      {
        title: "Rating",
        field: "rc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("rc", "table", data);
        },
      },
      { title: "Name", field: "name" },

      {
        title: "Price",
        field: "vc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("vc", "table", data);
        },
      },
    ];
  }, [environment, format]);

  return (
    <Card sx={{ boxShadow: 3, flex: 1 }}>
      <CardContent
        sx={{
          overflow: "hidden",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          py: 1,
          px: 0,
          gap: 1,
        }}
      >
        {showSecurityChart && securityFocus != null && (
          <SecurityChartModal
            environment={environment}
            security={securityFocus}
            showModal={showSecurityChart}
            onClose={() => setShowSecurityChart(false)}
          />
        )}
        <Box px={1} display={"flex"} flexDirection={"column"} gap={1}>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <span
              dangerouslySetInnerHTML={{
                __html: format.tcr(tcrStart, "HTML") ?? "-",
              }}
            ></span>
            <Typography variant="subtitle1">Starting portfolio</Typography>
          </Box>
        </Box>
        <Box px={1} overflow={"auto"}>
          <TrendratingTable
            disableDefaultRowClick={true}
            columns={columns}
            data={positions}
            autoResize={false}
            options={{ ajaxSorting: false }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
