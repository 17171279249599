/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportSystematicPortfolios
 * @summary Manages systematic portfolios report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportSystematicPortfolios extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_SYSTEMATIC_PORTFOLIOS" as const;
}
