import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridOnIcon from "@mui/icons-material/GridOn";
import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { Lists } from "../../../../api/compute/Lists";
import { Strategies } from "../../../../api/compute/Strategies";
import Modal from "../../../../components/Modal/Modal";
import { deepClone } from "../../../../deepClone";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../hooks/useEventBus";
import { appCookie } from "../../Cookies";
import { config } from "../../config-ts";
import { LoadingCallbacks } from "../../storage/CombinedStrategiesStorage";
import { SystematicPortfoliosStorage } from "../../storage/SystematicPortfoliosStorage";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import { Loader } from "../strategies/builder/Loader";
import {
  LoaderContext,
  STRATEGIES_LOADER_EVENTS,
} from "../strategies/builder/StrategiesBuilder";
import { CombineSystematicProducts } from "./CombineSystematicProducts/CombineSystematicProducts";
import GridViewer from "./GridViewer/GridViewer";
import { SystematicList } from "./SystematicList/SystematicList";
import styles from "./SystematicPortfolios.module.scss";
import { DialogCreate } from "./dialog/DialogCreate";
import { ProductPage } from "./product/ProductPage";

type SmsTabs =
  | "userItems"
  | "subscribedItems"
  | "combinedItems"
  | "customAndSubscribedItems";
type ChangeViewBtnProps = {
  viewType: "table" | "tile";
  changeView: (type: "table" | "tile") => void;
};
type ViewType = "table" | "tile";

export const SystematicPortfolioContext =
  createContext<SystematicPortfoliosStorage>(undefined!);

export function SystematicPortfolios() {
  const [workflow, setWorkflow] = useState("s0");
  const [currentTab, changeTab] = useState<SmsTabs>("customAndSubscribedItems");
  const [view, changeView] = useState<ViewType>(
    appCookie("SMS_viewermode", null) ?? "tile"
  );
  const [productListKey, setProductListKey] = useState(
    new Date().getMilliseconds()
  );
  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();
  const environment = useEnvironment();
  const { dispatch } = useEventBus();
  const { broadcast } = useBroadcast();

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.systematic_portfolios;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const smsStorageAPI = useMemo(
    () => new SystematicPortfoliosStorage(appSetup),
    [appSetup]
  );
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);
  const tabs = useMemo(() => smsStorageAPI.getTabs(t), [smsStorageAPI, t]);
  const showShared = useMemo(() => {
    const cases = {
      userItems: false,
      subscribedItems: true,
      combinedItems: false,
      customAndSubscribedItems: false,
    };

    return cases[currentTab];
  }, [currentTab]);

  useEffect(() => {
    removeLoader();
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, tabKey: SmsTabs) => {
      changeTab(tabKey);

      const workflowMap = {
        userItems: "s0",
        customAndSubscribedItems: "s0",
        subscribedItems: "s1",
        combinedItems: "s3",
      };

      setWorkflow(workflowMap[tabKey]);
    },
    []
  );

  const handleChangeView = useCallback((newView: ViewType) => {
    appCookie("SMS_viewermode", newView);
    changeView(newView);
  }, []);

  const handleGoToProductSection = useCallback(
    (id) => {
      navigate(`/app/systematic-portfolios/${id}`);
    },
    [navigate]
  );

  const [showProgressBar, setShowProgressBar] = useState(false);

  const progressListener = useCallback((status) => {
    setShowProgressBar(true);
  }, []);

  const feedbackSuccessCreate = useCallback(
    (product) => {
      const text = `<strong>${product.name}</strong> has been created.`;

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast]
  );

  const refreshList = useCallback(() => {
    setProductListKey(new Date().getMilliseconds());
  }, []);

  const productCreate = useCallback(
    async (params, response?) => {
      if (response != null) {
        params["todayList"]["list"]["list"] = response;
        delete params["todayList"]["list"]["id"];
      }

      params["listeners"] = {
        progress: progressListener,
      };

      try {
        const responseCreate = await smsStorageAPI.smsAPI.create(params);
        setShowProgressBar(false);
        feedbackSuccessCreate(responseCreate);

        smsStorageAPI.invalidateCache();
        refreshList();
      } catch (error) {
        setShowProgressBar(false);
        const [channel, msg] = messageError(
          "The Product has not be created due to an error."
        );
        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      feedbackSuccessCreate,
      progressListener,
      refreshList,
      smsStorageAPI,
    ]
  );

  const progressBarInit = useCallback(
    (loaderFn: LoadingCallbacks, strategyName: string, isBenchmark = false) => {
      if (!loaderFn) {
        console.warn(
          "No loading behaviour is specified so the app not crash but cannot show the progress bar"
        );

        return;
      }

      let eventSourceId =
        strategiesAPI.generateBusId() + "_" + Math.floor(Math.random() * 1000);

      if (isBenchmark) {
        eventSourceId += "-2";
      }

      setTimeout(() =>
        strategiesAPI.status(eventSourceId, (chunk) => {
          if ("status" in chunk && chunk.status === "start") {
            loaderFn.startNew({
              min: chunk.d,
              max: chunk.de,
              point: chunk.d,
              id: eventSourceId,
              name: strategyName,
            });
          } else if (!("status" in chunk)) {
            loaderFn.update(eventSourceId, chunk.d);
          } else if ("status" in chunk && chunk.status === "stop") {
            loaderFn.complete(eventSourceId);
          }
        })
      );

      return eventSourceId;
    },
    [strategiesAPI]
  );
  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
    }),
    [dispatch]
  );

  const createNew = useCallback(
    async (createModalState) => {
      var params = deepClone(createModalState);

      // getting strategy
      var strategyId = params["basic"]["strategyId"];
      var strategy = await strategiesAPI.getById(strategyId);
      delete params["basic"]["strategyId"];
      delete params["priceLevel"]["fromDate"];

      const eventSourceId = progressBarInit(loadingBehaviours, strategy.name);

      if (eventSourceId) {
        strategy.params.busId = eventSourceId;
      }

      params["basic"]["strategy"] = strategy;
      // getting list
      if (params["todayList"]["list"] != null) {
        const response = await listsAPI.get(params["todayList"]["list"]["id"]);
        productCreate(params, response);
      } else {
        productCreate(params);
      }
    },
    [listsAPI, loadingBehaviours, productCreate, progressBarInit, strategiesAPI]
  );

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const openCreateDialog = useCallback(() => {
    setShowCreateDialog(true);
  }, []);

  const listenerActionManageSubscriptions = useCallback(() => {
    dispatch("open-preferences", {
      preferencesTab: "subscriptions",
      subscriptionsTab: "systematic",
    });
  }, [dispatch]);

  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    // "s0" on landing
    // "s3 on tab change"
    switch (workflow) {
      case "s0": {
        action = {
          componentJSX: (
            <li className={"menu__item"} onClick={openCreateDialog}>
              Create
            </li>
          ),
        };

        actions.push(action);

        break;
      }
      case "s3": {
        return;
      }

      case "s1": {
        action = {
          componentJSX: (
            <li
              className="menu__item"
              onClick={listenerActionManageSubscriptions}
            >
              Manage Subscriptions
            </li>
          ),
        };

        actions.push(action);

        break;
      }
    }

    var message = {
      from: "analysisMarkets",
      content: {
        actions: actions,
      },
    };

    // Handle workflow only on landing page because the handling of the workflow is given to children when they're instantiated
    if (params?.id == null) {
      broadcast(config["channels"]["workflow"]["input"], message);
    }
  }, [
    broadcast,
    listenerActionManageSubscriptions,
    openCreateDialog,
    params?.id,
    workflow,
  ]);

  return (
    <ErrorBoundary fallback={<ErrorComponet />}>
      <SystematicPortfolioContext.Provider value={smsStorageAPI}>
        <Box
          className={styles.systematicPortfoliosWrapper}
          position={"relative"}
        >
          {showProgressBar && (
            <Box
              position={"absolute"}
              left={0}
              top={0}
              right={0}
              bottom={0}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <ProgressBar loading={true} />
            </Box>
          )}
          {params?.id == null ? (
            <>
              {/* {currentTab !== "combinedItems" && (
                <ChangeViewBtn viewType={view} changeView={handleChangeView} />
              )} */}
              <Tabs
                sx={{ borderBottom: 1, borderColor: "divider" }}
                value={currentTab}
                onChange={handleTabChange}
              >
                {tabs.map((tab) => {
                  return (
                    <Tab
                      key={uuidv4()}
                      label={tab.label}
                      sx={{ color: "#000" }}
                      value={tab.value}
                    />
                  );
                })}
              </Tabs>
              <Box className={styles.systematicPortfoliosContent}>
                {view === "table" ? (
                  <ErrorBoundary fallback={<ErrorComponet />}>
                    {currentTab === "combinedItems" ? (
                      <CombineSystematicProducts />
                    ) : (
                      <GridViewer
                        refreshList={refreshList}
                        key={productListKey}
                        showShared={showShared}
                        storage={smsStorageAPI}
                        view={view}
                        handleChangeView={handleChangeView}
                        showProgressBar={showProgressBar}
                        setShowProgressBar={setShowProgressBar}
                      />
                    )}
                  </ErrorBoundary>
                ) : (
                  <>
                    {currentTab === "combinedItems" ? (
                      <CombineSystematicProducts />
                    ) : (
                      <SystematicList
                        key={productListKey}
                        onClickItem={handleGoToProductSection}
                        view={view}
                        handleChangeView={handleChangeView} // showShared={showShared}
                      />
                    )}
                  </>
                )}
              </Box>
            </>
          ) : (
            <ProductPage productId={params.id!} />
          )}
        </Box>
        {showCreateDialog && (
          <Modal bodyCustomClass={styles.modal} closeIcon={false}>
            <DialogCreate
              closeModal={() => setShowCreateDialog(false)}
              onDialogOk={createNew}
            />
          </Modal>
        )}
      </SystematicPortfolioContext.Provider>
    </ErrorBoundary>
  );
}

export const ChangeViewBtn = ({ changeView, viewType }: ChangeViewBtnProps) => {
  const changeTab = useCallback(
    (e) => {
      const newTab = viewType === "table" ? "tile" : "table";
      changeView(newTab);
    },
    [changeView, viewType]
  );

  return (
    <Button
      variant="outlined"
      onClick={changeTab}
      size="small"
      // className={styles.changeViewBtn}
    >
      {viewType === "table" ? (
        <FormatListBulletedIcon sx={{ color: "#2a7090" }} />
      ) : (
        <GridOnIcon sx={{ color: "#2a7090" }} />
      )}
    </Button>
  );
};

const ErrorComponet = () => {
  return (
    <Box className={styles.errorBoxSms}>
      <Card>
        <CardContent
          sx={{
            display: "felx",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>
            An error is blocking the page loading. Please refresh the browser,
            if the error persist contact our customers support
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

type ProgressBarProps = {
  loading: boolean;
};

const ProgressBar = ({ loading }: ProgressBarProps) => {
  const { on, remove, dispatch } = useEventBus();
  return loading ? (
    <LoaderContext.Provider
      value={{
        availableEvents: STRATEGIES_LOADER_EVENTS,
        on,
        remove,
        dispatch,
      }}
    >
      <Box width={"100%"} flex={1} sx={{ position: "relative" }}>
        <Loader />
      </Box>
    </LoaderContext.Provider>
  ) : (
    <></>
  );
};
