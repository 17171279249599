import React, { ReactChild, ReactNode, useRef } from "react";
import { useApiCallOnMount } from "../../../hooks/useApiCallOnMount";
import { useResizer } from "../../../hooks/useResizer";
import ComponentStatusWizard from "../../ComponentStatusWizard/ComponentStatusWizard";
import styles from "./Block.module.scss";

type BlockProps = {
  children: React.ReactChild | React.ReactChild[];
  styleProps?: React.CSSProperties;
  autoResize?: boolean;
  className?: any;
  serviceCaller?: (params: any) => any;
  cacheId?: string;
};

type WithStatusHandlerComponentProps = {
  errMsg?: string;
  errorComponent?: ReactChild | ReactChild[];
  customLoader?: ReactChild | ReactChild[];
  children?: ReactChild | ReactChild[];
};

export const withStatusHandler = (WrappedComponent, serviceCall, cacheId?) => {
  return ({
    errMsg,
    errorComponent,
    customLoader,
    children,
  }: WithStatusHandlerComponentProps) => {
    const [status, data] = useApiCallOnMount(serviceCall, cacheId);

    return (
      <ComponentStatusWizard
        status={status}
        errorMessage={errMsg}
        customErrComponent={errorComponent}
        customLoader={customLoader}
      >
        <WrappedComponent data={data}>{children}</WrappedComponent>
      </ComponentStatusWizard>
    );
  };
};

export default function Block({
  children,
  styleProps,
  autoResize = false,
  className,
  serviceCaller,
  cacheId,
}: BlockProps) {
  const { wrapper } = styles;
  const ctRef = useRef<HTMLDivElement>(null);
  useResizer({
    isEnable: autoResize,
    ref: ctRef,
  });

  return (
    <div ref={ctRef} className={`${wrapper} ${className}`} style={styleProps}>
      {(() => {
        if (serviceCaller != null) {
          const BlockWithApiCall = withStatusHandler(
            BlockWithStatus,
            serviceCaller,
            cacheId
          );

          return <BlockWithApiCall>{children}</BlockWithApiCall>;
        } else {
          return <>{children}</>;
        }
      })()}
    </div>
  );
}

const BlockWithStatus = ({
  children,
  data,
}: {
  children: ReactNode;
  data: any;
}) => {
  /**
   * Iterate throw all children and return them cloned to propagate props (data) recived from the
   * high order component, to the children component. Because the component that needs data comes
   * from children props and there's no otherway to pass him the data props.
   */
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement<any>(child, { data });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
