export const themeBlueAndGrey = {
  line: {
    color: "#DFDFDF",
  },
  table: {
    border: "#DFDFDF",
    head: {
      background_color: "#FFFFFF",
      border: "#FFFFFF",
      color: "#000000",
      separator: "#2a7092",
    },
    body: {
      background_color: ["#ffffff", "#FFFFFF"],
      border: "#aaaaaa",
      color: "#000000",
    },
  },
  text: {
    header_1: {
      color: "#2a7092",
    },
    title: {
      color: "#000000",
    },
    title_1: {
      color: "#000000",
    },
    title_2: {
      color: "#AAAAAA",
    },
  },
};
