import { Box, Typography } from "@mui/material";
import React from "react";

type Props = { imgURL: string; title: "Portrait" | "Landscape" };

export default function OrientationButton({ imgURL, title }: Props) {
  return (
    <Box display={"flex"} alignItems={"center"}>
      <img src={imgURL} alt={title}></img>
      <Typography>{title}</Typography>
    </Box>
  );
}
