import { Box, Typography } from "@mui/material";
import React from "react";

type Props = { imgURL: string; title: string };

export default function ThemeButton({ imgURL, title }: Props) {
  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <img width={60} src={imgURL} alt={title}></img>
      <Typography>{title}</Typography>
    </Box>
  );
}
