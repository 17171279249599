import { useCallback, useMemo, useRef } from "react";
import { Analytics, Entity } from "../api/compute/Analytics/Analytics";
import { useEnvironment } from "./useEnvironment";

export function useAnalytics() {
  const analytics = useRef<Analytics>();
  const environment = useEnvironment();

  const isReady = useCallback(() => analytics.current != null, []);

  const init = useCallback(
    async (firstEntity: Entity, secondEntity?: Entity) => {
      const setup = environment.get("setup");
      const analyticsInstance = await Analytics.initialize(
        setup,
        firstEntity,
        secondEntity
      );

      analytics.current = analyticsInstance;
    },
    [environment]
  );

  const changeEntity = useCallback(
    async (type: "H" | "B", entity?: Entity) => {
      if (isReady()) {
        await analytics.current?.swapEntity(type, entity);
      } else {
        console.warn("Analyitcs class non initialized");
      }
    },
    [isReady]
  );

  const get = useCallback(
    async (analyitics: string[]) => {
      if (isReady()) {
        const _analytics = await analytics.current?.getAnalytics(analyitics);
        return _analytics;
      } else {
        console.warn("Analyitcs class non initialized");
      }
    },
    [isReady]
  );

  const getCurves = useCallback(
    async (key: "H" | "B", what: "prices" | "allocations" | "components") => {
      if (isReady()) {
        const _curves = await analytics.current?.get(key, what);
        return _curves;
      } else {
        console.warn("Analyitcs class non initialized");
      }
    },
    [isReady]
  );

  const clearInstance = useCallback(() => {
    analytics.current = undefined;
  }, []);

  const analyticsMethods = useMemo(
    () => ({
      init,
      changeEntity,
      get,
      isReady,
      clearInstance,
      getCurves,
    }),
    [changeEntity, clearInstance, get, getCurves, init, isReady]
  );

  return analyticsMethods;
}
