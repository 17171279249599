import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip";
import { DownUpGradesColumns } from "../../../../../../js/app/components/ui/charts/DownUpGradesColumns";

type MarketsItemComponentProps = {
  data: any;
  timeframe: string;
};

export function MarketsItem({ data, timeframe }: MarketsItemComponentProps) {
  const cssClassIsDisabled = "tMarkets-itemDatum-isDisabled";
  const timeframeData =
    data && timeframe ? data.timeframes[timeframe] : undefined;
  const nodeDowngradeClass =
    timeframeData["downgrades"] > 0 ? "" : cssClassIsDisabled;
  const nodeUpgradeClass =
    timeframeData["upgrades"] > 0 ? "" : cssClassIsDisabled;
  const downgrades =
    timeframeData["downgrades"] > 0 ? timeframeData["downgrades"] : "-";
  const upgrades =
    timeframeData["upgrades"] > 0 ? timeframeData["upgrades"] : "-";

  const handleItemClick = (property: "downgrades" | "upgrades") => {
    let value = data;

    if (data["timeframes"][timeframe][property] > 0) {
      const instrumentType = value["id"];

      //************************** USAGE *****************************
      var usage = window.App.usage;
      var info = {
        action: property === "downgrades" ? "DOWNGRADE" : "UPGRADE",
        actionParam: instrumentType,
        function: "SCREENING",
      };
      usage.record(info);
      //************************** USAGE *****************************

      var uri = `/screening/${instrumentType}/${timeframe}/${property}/`;
      (window as any).__page_navigate(uri);
    }
  };

  const { t } = useTranslation();

  return (
    <li className="tMarkets-item">
      <Tooltip
        tooltipIcon={false}
        content={
          <DownUpGradesColumns
            hasDataLabels={true}
            hasLegend={true}
            hasTooltip={true}
            hasXAxisLabels={true}
            height={250}
            margin={null}
            value={data["timeframes"]}
            width={420}
            title={undefined}
          />
        }
      >
        <div className="tMarkets-itemName" data-dojo-attach-point="nodeLabel">
          {data.label}
        </div>
        <div className="tLayout">
          <div className="tLayout-item--1 tLayout-item--width-50">
            <div
              className={`tMarkets-itemDatum tMarkets-itemDatum--left ${nodeUpgradeClass}`}
              data-dojo-attach-point="nodeUpgrades"
              onClick={() => handleItemClick("upgrades")}
            >
              <div className="tMarkets-itemDatumLabel">{t("Upgrades")}</div>
              <div
                className={`tMarkets-itemDatumValue`}
                data-dojo-attach-point="nodeUpgradesValue"
              >
                {upgrades}
              </div>
            </div>
          </div>
          <div className="tLayout-item--1 tLayout-item--width-50">
            <div
              className={`tMarkets-itemDatum tMarkets-itemDatum--right ${nodeDowngradeClass}`}
              data-dojo-attach-point="nodeDowngrades"
              onClick={() => handleItemClick("downgrades")}
            >
              <div className="tMarkets-itemDatumLabel">{t("Downgrades")}</div>
              <div
                className={`tMarkets-itemDatumValue`}
                data-dojo-attach-point="nodeDowngradesValue"
              >
                {downgrades}
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </li>
  );
}
