import { Box } from "@mui/material";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import KeyRatios from "./widgets/KeyRatios";
import Performance from "./widgets/Performance";
import Risk from "./widgets/Risk";
import { useMemo } from "react";

type Props = {
  isUsedInCompareStrategies?: boolean;
  showChart?: boolean;
  value: {
    performance: {
      cumulative: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      annualized: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      yearlyAverage: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriods: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    risk: {
      maxDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      avgYearlyDrawdown: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      monthlyStdDev: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      maxConsecutiveLoosingPeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
    };
    keyRatios: {
      avgTurnover: {
        strategy: number;
        benchmark: number | null;
        delta: null | number;
      };
      sharpeRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sterlingRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      sortinoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      beta: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      trackingError: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      infoRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      treynorRatio: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      percentagePositivePeriod: {
        strategy: number;
        benchmark: number | null;
        delta: number | null;
      };
      winningPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      winningAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
      losingAvgPeriod: {
        strategy: number | null;
        benchmark: number | null;
        delta: number | null;
      };
    };
    period: string;
    hasBenchmark: boolean;
  };
};

export default function KeyFacts({
  value,
  showChart = true,
  isUsedInCompareStrategies,
}: Props) {
  const format = useFormatter();
  const benchmarkLabel = useMemo(() => {
    return isUsedInCompareStrategies === true ? "Strategy 2" : undefined;
  }, [isUsedInCompareStrategies]);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      height={"100%"}
      flex={1}
      justifyContent={"space-between"}
      overflow="auto"
      p={1}
      gap={1}
    >
      <Performance
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={value.performance}
        getChartData={(obj) =>
          getChartData(
            value.performance,
            obj.propertyName,
            value.hasBenchmark,
            `Performance - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format
          )
        }
      />
      <Risk
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={value.risk}
        getChartData={(obj) =>
          getChartData(
            value.risk,
            obj.propertyName,
            value.hasBenchmark,
            `Risk - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format,
            obj.isColorInvertedAdtDifference ?? obj.isColorInvertedAdtDifference
          )
        }
      />
      <KeyRatios
        benchmarkLabel={benchmarkLabel}
        showChart={showChart}
        value={value.keyRatios}
        getChartData={(obj) =>
          getChartData(
            value.keyRatios,
            obj.propertyName,
            value.hasBenchmark,
            `Key ratios - ${obj.propertyLabel}`,
            obj.FORMATTER_OPTIONS,
            format,
            obj.isColorInvertedAdtDifference ?? obj.isColorInvertedAdtDifference
          )
        }
      />
    </Box>
  );
}

const getChartData = (
  data,
  propertyName,
  hasBenchmark,
  label,
  _FORMATTER_OPTIONS,
  format,
  isColorInvertedAdtDifference?
) => {
  const getColorForInverted = (val) => {
    if (val > 0) {
      return "#f00000";
    } else {
      return "#008000";
    }
  };
  const series = [
    {
      _formatter: {
        type: "number",
        options: _FORMATTER_OPTIONS,
      },
      name: label,
      data: [
        {
          x: 0,
          y: data?.[propertyName].strategy,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].strategy)
              : null,
        },
        {
          x: 1,
          y: data?.[propertyName].benchmark,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].benchmark)
              : null,
        },

        {
          x: 2,
          y: data?.[propertyName].delta,
          color:
            isColorInvertedAdtDifference === true
              ? getColorForInverted(data?.[propertyName].delta)
              : null,
        },
      ],
    },
  ];
  const options = {
    chart: {
      animation: false,
      borderColor: "#d3d3d3",
      borderRadius: 0,
      height: "150",
      borderWidth: 1,
      type: "bar",
    },
    credits: { enabled: false },
    legend: { enabled: false },
    navigation: { buttonOptions: { enabled: false } },
    plotOptions: {
      bar: {
        animation: false,
        color: "#008000",
        negativeColor: "#f00000",
        states: { hover: { enabled: false } },
      },
    },
    xAxis: {
      categories:
        hasBenchmark === true
          ? ["Strategy", "Benchmark", "Difference"]
          : ["Strategy", "", ""],
      tickLength: 4,
      tickWidth: 0,
    },
    yAxis: {
      labels: {
        formatter: function () {
          const that: any = this;
          return format.custom("number", {
            options: _FORMATTER_OPTIONS,
            output: "HTML",
            value: that.value,
            valueHelper: null,
          });
        },
      },
      title: { text: null },
    },
    series: series,
    title: { text: label },
    tooltip: {
      formatter: function () {
        const that: any = this;
        var formatter = that.series.userOptions._formatter;
        var innerHTML = format.custom(formatter.type, {
          options: formatter.options,
          output: "HTML",
          value: that.point.y,
          valueHelper: null,
        });

        return that.x + "<br/><strong>" + innerHTML + "</strong>";
      },
    },
  };
  return options;
};
