import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SelectorETFMarkets } from "../../analysisMarkets/ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";

type ListEventsTitleProps = {
  selectedItem: any;
  deselectItem: Function;
  timeframe: "daily" | "weekly" | "monthly";
  setTimeframe: (timeframe) => void;
};

const OPTIONS = [
  {
    label: "Today",
    value: "daily",
  },
  {
    label: "Last Week",
    value: "weekly",
  },
  {
    label: "Last Month",
    value: "monthly",
  },
];

export function ListEventsTitle({
  selectedItem,
  deselectItem,
  timeframe,
  setTimeframe,
}: ListEventsTitleProps) {
  const { t } = useTranslation();

  const onChangeCheckbox = useCallback(
    (e) => {
      if (e.target.checked === false) {
        deselectItem();
      }
    },
    [deselectItem]
  );

  const onClickLabel = useCallback(() => deselectItem(), [deselectItem]);

  return (
    <h1 className="tListEventsTitle">
      <Box display={"flex"} alignItems={"center"} gap={1} height={"100%"}>
        <Typography
          onClick={onClickLabel}
          sx={{ fontSize: "16px" }}
          className="tListEventsTitle-title"
        >
          <strong>{t("c_header_events")}</strong>
        </Typography>

        <SelectorETFMarkets
          fontSize={16}
          selectOption={setTimeframe}
          selectedOption={timeframe}
          options={OPTIONS}
        />
        {selectedItem && (
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography
              onClick={onClickLabel}
              sx={{ fontSize: "16" }}
              className="tListEventsTitle-title"
            >
              <strong>in</strong>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked onChange={onChangeCheckbox} />
                }
                label={selectedItem.name}
              />
            </FormGroup>
          </Box>
        )}
      </Box>
    </h1>
  );
}
