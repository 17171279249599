import { Box } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ListItem from "./ListItem";
import { createPlaceholderFromNode, ListContentType } from "./utils";

type ListProps = {
  content: ListContentType;
  orientation: "vertical" | "horizontal";
  listPadding?: number;
  setState?: Function;
  gap?: number;
  listBorder?: string;
};

const List = forwardRef(
  (
    {
      content,
      orientation = "horizontal",
      listPadding = 1,
      setState,
      gap = 1,
      listBorder,
    }: ListProps,
    ref
  ) => {
    const [_content, setContent] = useState(content);
    useEffect(() => {
      if (content) {
        setContent(content);
      }
    }, [content]);
    const listRef = useRef<any>(null);
    const draggedRef = useRef<any>(null);
    // const ghostRef = useRef<any>(null);
    const targetRef = useRef<any>(null);
    const placeholderNodeRef = useRef<any>(null);

    //#region - state management
    const updateContent = useCallback(() => {
      // setContent((prev) => {
      //   let newOrderAray: any[] = [];
      //   const items: HTMLDivElement[] = Array.from(listRef.current.children);
      //   items.forEach((item) => {
      //     newOrderAray.push(item.dataset["value"]);
      //   });
      //   const arr: any = [];
      //   newOrderAray.forEach((value) => {
      //     prev.forEach((item, index) => {
      //       const str =
      //         typeof item.value === "number"
      //           ? item.value.toString()
      //           : item.value;
      //       if (str === value) {
      //         arr.push(prev[index]);
      //       }
      //     });
      //   });
      //   // if (setState) {
      //   //   setTimeout(() => setState(arr), 100);
      //   // }
      //   return arr;
      // });

      const contentCopy = [..._content];
      let newOrderAray: any[] = [];
      const items: HTMLDivElement[] = Array.from(listRef.current.children);
      items.forEach((item) => {
        newOrderAray.push(item.dataset["value"]);
      });
      const arr: any = [];
      newOrderAray.forEach((value) => {
        contentCopy.forEach((item, index) => {
          const str =
            typeof item.value === "number" ? item.value.toString() : item.value;
          if (str === value) {
            arr.push(contentCopy[index]);
          }
        });
      });
      if (setState) {
        setState(arr);
      }
      setContent(arr)
    }, [_content, setState]);

    useImperativeHandle(
      ref,
      () => ({
        getState: () => {
          return [..._content];
        },
        setState: (newContent: ListContentType) => {
          setContent(newContent);
        },
      }),
      [_content]
    );

    //#endregion

    //#region - drag handling
    const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
      const draggingElement = e.currentTarget;
      const draggingElementComputedStyle =
        window.getComputedStyle(draggingElement);
      draggedRef.current = draggingElement;
      // const draggingElementRect = draggingElement.getBoundingClientRect();
      //#region - copy dragging element into ref
      // ghostRef.current = draggingElement.cloneNode(true);
      //#endregion

      //#region - PLACEHOLDER MANAGEMENT
      //create placeholder and styling placeholder
      placeholderNodeRef.current = createPlaceholderFromNode(
        draggingElementComputedStyle
      );
      //#endregion

      draggingElement.classList.add("dragging");

      //#region - GHOST MANAGEMENT
      // ghost styling
      // ghostRef.current.style.width = draggingElementComputedStyle.width ;
      // ghostRef.current.style.height = draggingElementComputedStyle.height;
      // ghostRef.current.style.border = "dashed 1px red !important";
      // ghostRef.current.style.borderRadius = "5px";
      //---
      // append "ghost" ref to DOM othewise it wont be visible & set the ghost
      // ??document.body.append(ghostRef.current);
      // e.dataTransfer.setDragImage(
      //   ghostRef.current.clone(true),
      //   e.clientX - draggingElementRect.left,
      //   e.clientY - draggingElementRect.top
      // );
      //---

      //#endregion
    }, []);
    const onDragEnd = useCallback(
      (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.currentTarget.classList.remove("dragging");
        e.currentTarget.style.cursor = "grab";

        let placeholder = document.getElementsByClassName(
          "draggable-placeholder"
        )[0];
        //if
        if (!placeholder) {
          draggedRef.current.style.display = "block";
          // ghostRef.current.remove();
          // ghostRef.current = null;
          targetRef.current = null;
          placeholderNodeRef.current = null;
          draggedRef.current = null;
          return;
        }
        placeholder.parentElement?.insertBefore(e.currentTarget, placeholder);
        placeholder.remove();
        // ghostRef.current.remove();
        // important! makes dragged element appear
        draggedRef.current.style.display = "block";
        //---
        // ghostRef.current = null;
        targetRef.current = null;
        placeholderNodeRef.current = null;
        draggedRef.current = null;
        updateContent();
        
      },
      [updateContent]
    );
    const onDragOver = useCallback(
      (e: /*React.DragEvent<HTMLDivElement>*/ any) => {
        e.preventDefault();
        let draggable = e.currentTarget;
        targetRef.current = draggable.cloneNode(true);

        //#region - important! makes dragged element disapear and insert a placeholder in it's place
        draggedRef.current.style.display = "none";
        listRef.current.insertBefore(
          placeholderNodeRef.current,
          draggedRef.current
        );
        //#endregion

        if (!draggable.classList.contains("dragging")) {
          // when the mouse is over the draggable item if the mouse
          // is on the left of the middle placeholder will be placed on the left
          // otherwise if mouse is on the right of the middle of the item the
          // placeholder will be placed on the right of the item
          const bounding = draggable.getBoundingClientRect();
          const offset =
            orientation === "horizontal"
              ? (e.clientX - bounding.left) / bounding.width
              : (e.clientY - bounding.top) / bounding.height;
          if (offset < 0.5) {
            listRef.current.insertBefore(placeholderNodeRef.current, draggable);
          } else {
            listRef.current.insertBefore(
              placeholderNodeRef.current,
              draggable.nextElementSibling
            );
          }
        }
      },
      [orientation]
    );
    //#endregion

    const listItems = useMemo(() => {
      let returning = _content.map((item) => (
        <ListItem
          key={item.value}
          content={item.content}
          value={item.value}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
        />
      ));
      return returning;
    }, [_content, onDragEnd, onDragOver, onDragStart]);

    return (
      <Box
        position={"relative"}
        borderRadius={1}
        border={listBorder ?? undefined}
        p={listPadding}
        display={"flex"}
        gap={gap}
        justifyContent={"space-between"}
        flexDirection={orientation === "vertical" ? "column" : "row"}
        ref={listRef}
      >
        {listItems}
      </Box>
    );
  }
);
export default List;
