import { useEffect, useMemo, useState } from "react";
import { RadioButtonBar } from "../../../../../../components/RadioButtonBar/RadioButtonBar";
import { SortBy } from "./widgets/SortBy";

type PeerWhatConstraintsProps = {
  options: (sg: "what" | "whatSortBy") => any[];
  getConstraints: (state) => void;
  whatDefault: string;
  sortDefault: any;
};

export function PeerWhatConstraints({
  options,
  getConstraints,
  sortDefault,
  whatDefault,
}: PeerWhatConstraintsProps) {
  const [constraints, setConstraints] = useState({
    whatSort: sortDefault,
    what: whatDefault,
  });

  useEffect(() => {
    getConstraints(constraints);
  }, [constraints, getConstraints]);

  const whatOptions = useMemo(() => options("what"), [options]);
  const sortOptions = useMemo(() => options("whatSortBy"), [options]);

  return (
    <fieldset
      className="tPageAnalysisMarket-constraintsWhat"
      style={{ display: "flex", gap: "16px", alignItems: "center" }}
    >
      <RadioButtonBar
        options={whatOptions ?? []}
        initValue={constraints.what}
        onChange={(value) =>
          setConstraints({
            ...constraints,
            what: value,
          })
        }
      />
      <SortBy
        options={sortOptions ?? []}
        onChangeSort={(e) =>
          setConstraints({
            ...constraints,
            whatSort: e,
          })
        }
      />
    </fieldset>
  );
}
