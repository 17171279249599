/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/allocation
 * @summary Allocation
 *
 */

import { escapeEntity, sectionPieAllocation } from "./Generator";

export function allocation(input, section, datum, formatter, environment) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    const _section = allocationDetail(
        input,
        section,
        datum,
        formatter,
        environment
    );
    _sections.push(_section);

    return _sections;
}

function allocationDetail(input, section, data, formatter, environment) {
    const layout: any = {
        data: {
            layoutRow: [],
        },
        type: "layout2Col",
    };
    const row: any = [];
    const colorByPosition =
        section["presentation"]["colorByPosition"] != null
            ? section["presentation"]["colorByPosition"]
            : false;

    let paddingRows = 5; // maximum rows to show
    paddingRows = Math.max(
        paddingRows,
        data["first"] != null ? data["first"]["data"].length : 0
    );
    paddingRows = Math.max(
        paddingRows,
        data["second"] != null ? data["second"]["data"].length : 0
    );
    let rowsFromData;
    let rowsFromConfig;
    if (section["content"]["secondRows"] != null) {
        rowsFromData = 0;
        if (data["second"] != null) {
            rowsFromData = data["second"]["data"].length;
        }
        rowsFromConfig = parseInt(section["content"]["secondRows"], 10);
        paddingRows = Math.max(
            paddingRows,
            rowsFromData < rowsFromConfig ? rowsFromData : rowsFromConfig
        );
    }

    if (section["presentation"]["first"]) {
        const firstAllocation: any = {
            title: null,
            data: data["first"],
            taxonomy: null,
        };

        const txFields = environment.taxonomyFields;

        switch (section["content"]["firstType"]) {
            case "INDUSTRY":
                firstAllocation["title"] = "Sector allocation";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["sector"]],
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;
            case "SECTOR":
                firstAllocation["title"] = "Industry allocation";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["industry"]],
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;
            case "MARKET":
                firstAllocation["title"] = "Market allocation";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;
            case "REGION":
                firstAllocation["title"] = "Region allocation";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;
            case "AREA":
                firstAllocation["title"] = "Area allocation";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;

            case "INV_REGION":
                firstAllocation["title"] = "Inv. Region";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;

            case "ASSET_CLASS":
                firstAllocation["title"] = "Asset Class";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            case "SPECIALTY":
                firstAllocation["title"] = "Specialty";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            case "THEME":
                firstAllocation["title"] = "Theme";
                firstAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            // no default
        }
        const _section = sectionPieAllocation(
            firstAllocation["title"],
            firstAllocation["data"],
            firstAllocation["taxonomy"],
            paddingRows,
            colorByPosition,
            formatter
        );
        row.push(_section);
    }
    if (section["presentation"]["second"]) {
        const secondAllocation: any = {
            title: null,
            data: data["second"],
            taxonomy: null,
        };

        const txFields = environment["taxonomyFields"];

        switch (section["content"]["secondType"]) {
            case "INDUSTRY":
                secondAllocation["title"] = "Sector allocation";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["sector"]],
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;
            case "SECTOR":
                secondAllocation["title"] = "Industry allocation";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["industry"]],
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;
            case "MARKET":
                secondAllocation["title"] = "Market allocation";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;
            case "REGION":
                secondAllocation["title"] = "Region allocation";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;
            case "AREA":
                secondAllocation["title"] = "Area allocation";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["security"]["country"]],
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;

            case "INV_REGION":
                secondAllocation["title"] = "Inv. Region";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfgeo"]],
                ];
                break;

            case "ASSET_CLASS":
                secondAllocation["title"] = "Asset Class";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            case "SPECIALTY":
                secondAllocation["title"] = "Specialty";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            case "THEME":
                secondAllocation["title"] = "Theme";
                secondAllocation["taxonomy"] = [
                    environment["taxonomies"][txFields["ETF"]["etfclass"]],
                ];
                break;

            // no default
        }
        const _section = sectionPieAllocation(
            secondAllocation["title"],
            secondAllocation["data"],
            secondAllocation["taxonomy"],
            paddingRows,
            colorByPosition,
            formatter
        );
        row.push(_section);
    }

    layout.data.layoutRow.push(row);

    return layout;
}
