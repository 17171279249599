type RankOptions = {
  functions: any[];
  operators: any[];
  property: {
    builderGroup: string;
    isRemovable: boolean;
    label: string;
    tag: string;
    value: string;
  };
  value: any;
}[];

type RuleValue = {
  function: string;
  functionParams: any;
  operator: string;
  operatorParams: any;
  property: string;
};

export class Rule {
  private operativeOptions: { functions: any; operators: any; properties: any };
  private optionsIndex: any;

  constructor(private options: RankOptions, private value: RuleValue) {
    this.operativeOptions = {
      functions: [],
      operators: [],
      properties: [],
    };

    this.optionsIndex = {};

    this.init();
  }

  public get() {
    const options = this.operativeOptions;
    const optionsFunction = options["functions"];
    const optionsOperator = options["operators"];
    const optionsProperty = options["properties"];
    const value = this.value;

    let indexFunction = 0;
    let indexOperator = 0;
    let indexProperty = 0;

    if (value != null) {
      var optionsIndex = this.optionsIndex;
      var _function = value["function"];
      var _operator = value["operator"];
      var _operatorParams = value["operatorParams"]["value"];
      var _property = value["property"];

      if (optionsIndex[_property] == null) {
        console.error("Property " + _property + " does not exists.");

        return;
      }
      indexProperty = optionsIndex[_property]["property"];
      indexFunction = optionsIndex[_property]["functions"][_function];
      indexOperator =
        optionsIndex[_property]["operators"][indexFunction][_operator][
          _operatorParams
        ];
    }

    var valueProperty = optionsProperty[indexProperty]["value"];
    var valueFunction = {
      augmentation: value != null ? value["functionParams"] : null,
      subject: optionsFunction[indexProperty][indexFunction]["value"],
    };
    var valueSortBy =
      optionsOperator[indexProperty][indexFunction][indexOperator]["value"];

    return {
      widgetProperty: { options: optionsProperty, value: valueProperty },
      widgetFunction: {
        options: optionsFunction[indexProperty],
        value: valueFunction,
      },
      widgetSortBy: {
        options: optionsOperator[indexProperty][indexFunction],
        value: valueSortBy,
      },
    };
  }

  updateValue(value) {
    this.value = value;

    this.operativeOptions = {
      functions: [],
      operators: [],
      properties: [],
    };

    this.optionsIndex = {};

    this.init();
  }

  getOptionIndex() {
    return this.optionsIndex;
  }

  getOptions() {
    return this.operativeOptions;
  }

  private init() {
    this.prepareOptions();
  }

  private prepareOptions() {
    const _options = [...this.options];

    let option: any = null;
    let key: any = null;
    let _sortByOptionIndex: any = null;
    for (let i = 0, lengthI = _options.length; i < lengthI; i++) {
      option = _options[i];
      key = option["property"]["value"];
      // properties
      this.operativeOptions["properties"].push(option["property"]);
      this.optionsIndex[key] = {
        functions: {},
        operators: [],
        property: i,
      };
      // functions
      this.operativeOptions["functions"][i] = [];
      for (var j = 0, lengthJ = option["functions"].length; j < lengthJ; j++) {
        // enable augmented behaviour in select dropdown
        if ("widget" in option["functions"][j]) {
          option["functions"][j]["type"] = "augmented";
        }

        this.operativeOptions["functions"][i].push(option["functions"][j]);

        this.optionsIndex[key]["functions"][option["functions"][j]["value"]] =
          j;
      }
      // operator
      this.operativeOptions["operators"][i] = [];
      for (let j = 0; j < option["operators"].length; j++) {
        this.operativeOptions["operators"][i].push(
          option["operators"][j][0]["widget"]["options"]
        );
        _sortByOptionIndex = {};
        _sortByOptionIndex[option["operators"][j][0]["value"]] = {};
        for (
          var k = 0,
            lengthK = option["operators"][j][0]["widget"]["options"].length;
          k < lengthK;
          k++
        ) {
          _sortByOptionIndex[[option["operators"][j][0]["value"]] as any][
            option["operators"][j][0]["widget"]["options"][k]["value"]
          ] = k;
        }
        this.optionsIndex[key]["operators"].push(_sortByOptionIndex);
      }
    }
  }
}
