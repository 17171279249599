import { Box, Card, CardContent, Typography } from "@mui/material";

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { handleTitle } from "../../../../Utility/DocumentTitleHanlder";
import { _StoredObjects } from "../../../../api/_StoredObjects";
import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import { RankingUi2Api } from "../../../../api/compute/RankingUi2Api";
import { TableHelpers } from "../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { InstrumentsTable } from "../../../../components/InstrumentsTable/InstrumentsTable";
import { SortableBricks } from "../../../../components/SortableBricks/SortableBricks";
import { deepClone } from "../../../../deepClone";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { AppEnvironment } from "../../../../types/Defaults";
import { Export } from "../../components/app-infrastructure/workflowBar/actions/export/Export";
import { Remove } from "../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { config } from "../../config-ts";
import { DialogSaveComponent } from "../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import ReportButton from "../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { ClusterBricks } from "./ClusterBricks";
import styles from "./Rank.module.scss";
import { RankDialog } from "./RankDialog/RankDialog";
import { RankUtilities } from "./RankUtilities";
import {
  ActionRankContext,
  ActionRankContextType,
} from "./actions/ActionRankContext/ActionRankContext";

type uiStateSnapshot = {
  rules: any;
  highlightListId?: number;
  constraints: any;
  universeFrom: "screenerETF" | "screenerStock" | "whiteList";
};

const PREFERENCE_TYPE = "RANKING_ABOUT_TARGET";

export function Rank() {
  const [hasRankedOnLanding, sethasRankedOnLanding] = useState(false);
  const [userRanks, setUserRanks] = useState<
    { ownerId: number; name: string; id: number }[]
  >([]);
  const [currentRank, setCurrentRank] = useState();
  const [freezeSelection, setFreezeSelection] = useState(false);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [sorter, setSorter] = useState<{ field: string; rev: boolean }>({
    field: "rank",
    rev: false,
  });
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setIsLoading] = useState(true);
  const [tableKey, setTableKey] = useState(`tableWidget_${Date.now()}`);
  const [clusterBriksKey, setClusterBricksKey] = useState(
    `clusterWidget_${Date.now()}`
  );
  const [rankListKey, setRankListKey] = useState(`rankList_${Date.now()}`);
  const [rankFrom, setRankFrom] = useState<
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS"
    | undefined
  >();
  const [showRankDialog, setShowRankDialog] = useState(false);
  const [currentRankingParams, setCurrentRankingParams] = useState<any>();
  const [uiStateSnapshot, setUiStateSnapshot] = useState<uiStateSnapshot>();
  const [workflow, setWorkflow] = useState<"s0" | "s1" | "s2">("s1");
  const [showDialogSave, setShowDialogSave] = useState(false);
  const [dataTotalCount, setDataTotalCount] = useState(0);

  const { broadcast } = useBroadcast();

  // Used to restore the template id when a user starting create a rank and then it abort the operation
  const currentRankingTemplateRef = useRef(undefined);

  const rankContext = useContext(ActionRankContext);

  const rankReducer = useMemo(
    () => rankContext.rankReducer,
    [rankContext.rankReducer]
  );
  const rank = useMemo(() => rankContext.rank, [rankContext.rank]);
  const rankingCache = useMemo(
    () => rankContext.rankingCache,
    [rankContext.rankingCache]
  );
  const rankResults = useMemo(() => {
    const res = rankContext.rankResults;
    setDataTotalCount(res.dataTotalCount);

    return res;
  }, [rankContext.rankResults]);

  const environment = useEnvironment();
  const appEnvironment = useMemo(() => environment.get("setup"), [environment]);

  const preferencesAPI = useMemo(
    () => new _StoredObjects(appEnvironment),
    [appEnvironment]
  );

  const rankingDataEncoder = useMemo(
    () => new RankingUi2Api(appEnvironment),
    [appEnvironment]
  );

  const utils = useMemo(() => {
    return new RankUtilities(appEnvironment);
  }, [appEnvironment]);

  const configuration = useMemo(
    () => appEnvironment["configuration"],
    [appEnvironment]
  );
  const configurationScreening = useMemo(
    () => configuration.get("ranking"),
    [configuration]
  );

  const resourceToSave = useMemo(
    () => new RankBuilder(rankContext, appEnvironment),
    [appEnvironment, rankContext]
  );

  const isStartingFromScratch = useMemo(() => {
    return workflow === "s0";
  }, [workflow]);

  const userId = environment.get("account")["user"]["id"];

  const pageStatusAPI = useMemo(
    () => environment.get("preferenceStatus"),
    [environment]
  );

  // const lastUsedTemplate = useMemo(() => {
  //   return undefined;
  // }, []);

  const getColumns = useCallback(
    (_columns) => {
      const helper = new TableHelpers(environment.get("setup"));

      const rankColumns = helper.get("rank");
      const common = helper.get("columns");
      let cols = common.prepareInputColumns(_columns);

      let columnsToSet: any = [];

      if (cols) {
        const tableColumns = cols;

        columnsToSet = [];

        for (const viewerCol of tableColumns) {
          if ("customColConfiguration" in viewerCol) {
            switch (viewerCol.customColConfiguration) {
              case "rankConfiguration": {
                rankColumns.configureAsRankCol(
                  viewerCol,
                  columnsToSet,
                  () => {},
                  false
                );

                break;
              }
              default:
                console.warn(
                  `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                );
            }
          } else {
            const sortHandler = () => 0;

            columnsToSet.push(
              common.tabulatorColumn(viewerCol, sortHandler, undefined)
            );
          }
        }
      }

      return columnsToSet;
    },
    [environment]
  );

  // const isLastUsedSelected = useMemo(
  //   () => lastUsedTemplate != null && currentRank === lastUsedTemplate?.id,
  //   [currentRank, lastUsedTemplate]
  // );

  const getWidgetInfo = useCallback(
    (infoType: "columns" | "pagination" | "sortBy" | "dataTotalCount") => {
      switch (infoType) {
        case "pagination":
          return {
            page,
            rows: itemsPerPage,
          };

        case "sortBy":
          return {
            property: sorter.field,
            descending: sorter.rev,
          };
        case "dataTotalCount":
          return rankResults.dataTotalCount;

        case "columns": {
          let _column: any = null;
          let _columns: any = [...tableColumns];
          let column: any = null;
          let columns: any = [];

          _columns = getColumns(_columns);

          for (let i = 0, length = _columns.length; i < length; i++) {
            _column = _columns[i];

            if (_column["field"] !== undefined && _column["field"] != null) {
              column = {
                label: (_column?.["title"] ?? _column?.["label"] ?? "")
                  .replace(/<br\/>/gi, " - ")
                  .replace(/<br>/gi, " "),
                property: _column["field"],
              };
              columns.push(column);
            }
          }

          return columns;
        }

        default:
          console.log(`Unrecognized widget type: ${infoType}`);
      }
    },
    [
      getColumns,
      itemsPerPage,
      page,
      rankResults.dataTotalCount,
      sorter.field,
      sorter.rev,
      tableColumns,
    ]
  );

  const blockEditActions = useMemo(() => {
    const ownerId = currentRankingParams?.["ownerId"] ?? null;
    const isReadOnly = userId !== ownerId;

    return isReadOnly || currentRank == null;
  }, [currentRank, currentRankingParams, userId]);

  const clearState = useCallback(() => {
    rankContext.rankReducer({
      type: "SET_CONSTRAINTS",
      payload: { filters: [] },
    });
    rankContext.rankReducer({ type: "SET_RANKING_RULES", payload: undefined });
    rankContext.rankReducer({ type: "SET_LIST_HIGHLIGHT", payload: undefined });
    rankContext.rankReducer({
      type: "SET_UNIVERSE_FROM",
      payload: "screenerStock",
    });
  }, [rankContext]);

  const refreshUserRanks = useCallback(() => {
    setRankListKey(`rankList_${Date.now()}`);
  }, []);

  const refreshClustersBricks = useCallback(() => {
    setClusterBricksKey(`clusterWidget_${Date.now()}`);
  }, []);

  const refreshTable = useCallback(() => {
    // This is not a trick, table is an uncontrolled component so to refresh sort arrow or page number ecc is needed a refresh of the component
    // so to trigger it we need to update the key of the component
    setTableKey(`tableWidget_${Date.now()}`);
  }, []);

  const clearPage = useCallback(() => {
    refreshUserRanks();
    setCurrentRank(undefined);
    setCurrentRankingParams(undefined);
    refreshClustersBricks();
    refreshUserRanks();
  }, [refreshClustersBricks, refreshUserRanks]);

  const getRanksOrder = useCallback(async () => {
    const rankOrder = await pageStatusAPI.get(["rank", "ranksOrder"]);

    return rankOrder;
  }, [pageStatusAPI]);

  const getSavedTemplates = useCallback(async () => {
    const bricksData = await utils.getUserRanks();

    if (bricksData && bricksData.length) {
      const sortOrderMap = await getRanksOrder();

      if (sortOrderMap) {
        let aId: any = undefined;
        let bId: any = undefined;
        let aIndex: any = undefined;
        let bIndex: any = undefined;

        bricksData.sort((a, b) => {
          aId = a.id;
          bId = b.id;

          if (aId in sortOrderMap && bId in sortOrderMap) {
            aIndex = sortOrderMap[aId];
            bIndex = sortOrderMap[bId];

            return aIndex > bIndex ? 1 : -1;
          }

          return aId in sortOrderMap > (bId in sortOrderMap) ? -1 : 1;
        });
      }

      setUserRanks(bricksData);
      refreshClustersBricks();
      refreshUserRanks();
    } else {
      setWorkflow("s0");
      setUserRanks([]);
      clearPage();
    }

    return bricksData;
  }, [
    clearPage,
    getRanksOrder,
    refreshClustersBricks,
    refreshUserRanks,
    utils,
  ]);

  const disableRankingChoice = useCallback(() => setFreezeSelection(true), []);

  const releaseRankingChoice = useCallback(() => setFreezeSelection(false), []);

  const refreshRank = useCallback(
    async (rankingParams) => {
      setIsLoading(true);
      disableRankingChoice();

      try {
        await rank(rankingParams);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        releaseRankingChoice();
      }
    },
    [disableRankingChoice, rank, releaseRankingChoice]
  );

  const refreshSorter = useCallback(() => {
    setSorter((currentSort) => {
      if (
        JSON.stringify(currentSort) ===
        JSON.stringify({ field: "rank", rev: false })
      ) {
        return currentSort;
      } else {
        return { field: "rank", rev: false };
      }
    });
    refreshTable();
  }, [refreshTable]);

  const updateColumns = useCallback(
    (columns) => {
      rankReducer({
        type: "SET_COLUMNS",
        payload: columns,
      });
      setTableColumns(columns);
    },
    [rankReducer]
  );

  const onColumnsChange = useCallback(
    (columns) => {
      updateColumns(columns);

      refreshRank({
        sortField: sorter.field,
        rev: sorter.rev,
        page,
        columns: columns.map((col) => col.field),
      });
    },
    [page, refreshRank, sorter.field, sorter.rev, updateColumns]
  );

  const prepareRankingParams = useCallback(
    async (rankId) => {
      if (!rankId) {
        return;
      }

      const rankSchema = await utils.getRankObject(rankId);

      setCurrentRankingParams(rankSchema);
      const targetType = rankSchema.target.type;

      let constraints: any = undefined;

      if (targetType !== "instruments") {
        constraints = {
          constraints: [
            [
              {
                dimension: "COLLECTION",
                operator: "relation",
                segments: [rankSchema.target.object.id],
              },
              {
                dimension: "type",
                logicalOperator: "not",
                operator: "equals",
                segments: ["ExpiredStock"],
              },
            ],
          ],
        };

        rankReducer({ type: "SET_UNIVERSE_FROM", payload: "whiteList" });
      } else {
        constraints = rankSchema.target.object.constraints;

        let screenerType: "screenerStock" | "screenerETF" = "screenerStock";

        if ("constraints" in constraints) {
          for (const constraint of constraints.constraints) {
            for (const filter of constraint) {
              if (filter.dimension === "type") {
                const isEtfUniverse = filter.segments.some((f) => f === "ETF");

                if (isEtfUniverse) {
                  screenerType = "screenerETF";

                  break;
                }
              }
            }
          }
        } else {
          for (const filter of constraints.filters) {
            if (filter.dimension === "type") {
              const isEtfUniverse = filter.segments.some((f) => f === "ETF");

              if (isEtfUniverse) {
                screenerType = "screenerETF";

                break;
              }
            }
          }
        }

        rankReducer({ type: "SET_UNIVERSE_FROM", payload: screenerType });
      }

      if (rankSchema.against != null) {
        const againstId = rankSchema?.against?.object?.id ?? undefined;

        if (againstId != null) {
          rankReducer({ type: "SET_LIST_HIGHLIGHT", payload: againstId });
        }
      } else {
        rankReducer({ type: "SET_LIST_HIGHLIGHT", payload: undefined });
      }

      const rankingRules = rankingDataEncoder.decode(rankSchema.rules);
      rankReducer({ type: "SET_CONSTRAINTS", payload: constraints });
      rankReducer({ type: "SET_RANKING_RULES", payload: rankingRules });

      let templateColumns: any = undefined;

      if (
        "columns" in rankSchema &&
        rankSchema.columns != null &&
        rankSchema.columns.length
      ) {
        rankReducer({ type: "SET_COLUMNS", payload: rankSchema.columns });
        templateColumns = [];

        for (const column of rankSchema.columns) {
          if ("field" in column && column.field) {
            templateColumns.push(column.field);
          }
        }
      }

      let fromDate = null;

      if (rankSchema.fromDate != null) {
        rankReducer({ type: "SET_FROM_DATE", payload: rankSchema.fromDate });
        fromDate = rankSchema.fromDate;
        setRankFrom(rankSchema.fromDate);
      } else {
        // Reset date select in the table on template change
        setRankFrom(undefined);
        rankReducer({ type: "SET_FROM_DATE", payload: undefined });
      }

      const name = rankSchema.name;

      return {
        constraints,
        rules: rankingRules,
        templateColumns,
        fromDate,
        name,
      };
    },
    [rankReducer, rankingDataEncoder, utils]
  );

  const changeRankTemplate = useCallback(
    async (rankId) => {
      await pageStatusAPI.patch(["rank", "lastUsedTemplate"], rankId);
      setCurrentRank(rankId);
      disableRankingChoice();
      setIsLoading(true);

      try {
        const { constraints, rules, templateColumns, fromDate } =
          (await prepareRankingParams(rankId)) ?? {
            constraints: undefined,
            rules: [],
            templateColumns: undefined,
          };

        const payload = {
          constraints,
          rules,
          page: 1,
          sortField: "rank",
          rev: false,
        };

        let columns = undefined;

        // If columns are saved in template when template change columns has to be changed otherwise
        // Rank context has already the right columns
        if (templateColumns) {
          columns = templateColumns;

          payload["columns"] = columns;
        }

        payload["fromDate"] = fromDate;

        await rank(payload);

        refreshSorter();
        refreshClustersBricks();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        releaseRankingChoice();
      }
    },

    [
      disableRankingChoice,
      pageStatusAPI,
      prepareRankingParams,
      rank,
      refreshClustersBricks,
      refreshSorter,
      releaseRankingChoice,
    ]
  );

  const onChangePage = useCallback(
    (page) => {
      setPage(page);

      refreshRank({
        page,
        sortField: sorter.field,
        rev: sorter.rev,
      });
    },
    [refreshRank, sorter.field, sorter.rev]
  );

  const onSelectItemPerPage = useCallback(
    (itemsPerPageValue) => {
      setItemsPerPage(itemsPerPageValue);
      setPage(1);

      refreshRank({ itemsPerPage: itemsPerPageValue, page: 1 });
    },
    [refreshRank]
  );

  const changeRankDateFrom = useCallback(
    (dateId) => {
      setPage(1);
      setRankFrom(dateId);
      refreshSorter();

      refreshRank({
        rev: false,
        sortField: "rank",
        page: 1,
        fromDate: dateId,
      });
    },
    [refreshRank, refreshSorter]
  );

  const onDataSorted = useCallback(
    async ({ field, direction }) => {
      setSorter((current) => {
        const newSort = { field, rev: direction === "desc" };
        if (JSON.stringify(newSort) === JSON.stringify(current)) {
          return current;
        } else {
          return newSort;
        }
      });
      setPage(1);

      await refreshRank({
        rev: direction === "desc",
        sortField: field,
        page: 1,
      });
    },
    [refreshRank]
  );

  const listenerRemove = useCallback(
    async (deletedItem) => {
      const oldName = deletedItem?.name ?? "ranking";

      const message = `<strong>${oldName}</strong> has been deleted.`;

      const [channel, msg] = messageSuccess(message);

      broadcast(channel as string, msg);
      const freshUserRanks = await getSavedTemplates();

      const newRankId = freshUserRanks?.[0]?.id;

      changeRankTemplate(newRankId);
    },
    [broadcast, changeRankTemplate, getSavedTemplates]
  );

  const takeSnapShot = useCallback(() => {
    const currentUiState = rankContext.getCurrentState();

    setUiStateSnapshot(currentUiState);
  }, [rankContext]);

  const restartFromSnapshot = useCallback(() => {
    const snapshot = uiStateSnapshot;

    if (snapshot != null) {
      rankContext.rankReducer({
        type: "SET_CONSTRAINTS",
        payload: snapshot.constraints,
      });
      rankContext.rankReducer({
        type: "SET_RANKING_RULES",
        payload: snapshot.rules,
      });
      rankContext.rankReducer({
        type: "SET_LIST_HIGHLIGHT",
        payload: snapshot.highlightListId,
      });
      rankContext.rankReducer({
        type: "SET_UNIVERSE_FROM",
        payload: snapshot.universeFrom,
      });

      setUiStateSnapshot(undefined);
      const templateId = currentRankingTemplateRef.current;

      // Should be undefined every time that a user has completed a Rank in both cases of an edit action or create action
      if (templateId) {
        setCurrentRank(templateId);
      }
    }
  }, [rankContext, uiStateSnapshot]);

  const openSaveDialog = useCallback(() => {
    setShowDialogSave(true);
  }, []);

  const closeSaveDialog = useCallback(() => {
    setShowDialogSave(false);
  }, []);

  const openRankDialog = useCallback(() => {
    setShowRankDialog(true);
  }, []);

  const closeRankDialog = useCallback(() => {
    setShowRankDialog(false);
  }, []);

  const onCreate = useCallback(() => {
    takeSnapShot();
    setCurrentRank((id) => {
      // Take in a ref the value of the current id before clear it. This is done to keep memory of the last selected id
      // and it is used to restore the state if the user cancel the create operation
      currentRankingTemplateRef.current = id;
      return undefined;
    });

    // Avoid state cleaning if the rank doesn't exist yet
    if (!isStartingFromScratch) {
      clearState();
    }

    openRankDialog();
  }, [clearState, isStartingFromScratch, openRankDialog, takeSnapShot]);

  const getRankToSave = useCallback(
    async (name?: string) => {
      try {
        await resourceToSave.new();
        await resourceToSave.againtsList();
        await resourceToSave.columns();
        await resourceToSave.fromDate();
        await resourceToSave.name(name ?? "");
        await resourceToSave.rules();
        await resourceToSave.target();
        await resourceToSave.stringifiedRules();
        const rankObject = await resourceToSave.build();

        return rankObject;
      } catch (error) {
        console.error(error);

        return undefined;
      }
    },
    [resourceToSave]
  );

  const onSaveFailed = useCallback(
    (msg) => {
      const [channel, _msg] = messageError(msg);
      broadcast(channel as string, _msg);
    },
    [broadcast]
  );

  const onSaveSuccess = useCallback(
    async (message: string) => {
      setShowDialogSave(false);
      const [channel, msg] = messageSuccess(message);
      broadcast(channel as string, msg);
      await getSavedTemplates();

      refreshClustersBricks();
    },
    [broadcast, getSavedTemplates, refreshClustersBricks]
  );

  // const saveLastUsedTemplate = useCallback(async () => {
  //   const name = lastUsedId;

  //   const existsLastUsed = lastUsedTemplate != null;

  //   try {
  //     const rankToSave = await getRankToSave(name);

  //     // If already exists update it
  //     if (existsLastUsed) {
  //       rankToSave["id"] = lastUsedTemplate.id;
  //       rankToSave["ownerId"] = lastUsedTemplate.ownerId;
  //       await preferencesAPI.update(rankToSave, PREFERENCE_TYPE);
  //     } else {
  //       // Otherwise create it
  //       await preferencesAPI.create(rankToSave, PREFERENCE_TYPE);
  //     }

  //     // Refresh the list and populate the lastUsedTemplate if is necessary
  //     await getSavedTemplates();

  //     refreshClustersBricks();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [
  //   getRankToSave,
  //   getSavedTemplates,
  //   lastUsedId,
  //   lastUsedTemplate,
  //   preferencesAPI,
  //   refreshClustersBricks,
  // ]);

  const editRank = useCallback(
    async (name?: string) => {
      const currentRankCopy = deepClone(currentRankingParams);

      if (currentRankCopy) {
        const rankId = currentRankCopy.id;
        const rankName = name ?? currentRankCopy.name;
        const ownerId = currentRankCopy.ownerId;

        try {
          const rankToSave = await getRankToSave(rankName);
          rankToSave["id"] = rankId;
          rankToSave["ownerId"] = ownerId;

          const resp = await preferencesAPI.update(rankToSave, PREFERENCE_TYPE);
          // await deleteLastUsedTemplate();

          changeRankTemplate(resp.id);
          setWorkflow("s1");

          // *********************** USAGE ***********************
          var usage = window.App.usage;
          var info = {
            action: "CREATE",
            actionParam: JSON.stringify(rankToSave),
            function: "RANKING",
          };
          usage.record(info);
          // *********************** USAGE ***********************

          onSaveSuccess(
            `<strong>${resp.name} has been updated successfully</strong>`
          );
        } catch (error) {
          console.log(error);
          onSaveFailed(`Failed to save <strong>${name}</strong>.`);
        }
      }
    },
    [
      changeRankTemplate,
      currentRankingParams,
      // deleteLastUsedTemplate,
      getRankToSave,
      onSaveFailed,
      onSaveSuccess,
      preferencesAPI,
    ]
  );

  const createRank = useCallback(
    async (name: string) => {
      try {
        const rankToSave = await getRankToSave(name);

        const resp = await preferencesAPI.create(rankToSave, PREFERENCE_TYPE);
        // await deleteLastUsedTemplate();

        changeRankTemplate(resp.id);
        setWorkflow("s1");

        // *********************** USAGE ***********************
        var usage = window.App.usage;
        var info = {
          action: "CREATE",
          actionParam: JSON.stringify(rankToSave),
          function: "RANKING",
        };
        usage.record(info);
        // *********************** USAGE ***********************

        onSaveSuccess(
          `<strong>${resp.name} has been created successfully</strong>`
        );
      } catch (error) {
        console.log(error);
        onSaveFailed(`Failed to create <strong>${name}</strong>.`);
      }
    },
    [
      changeRankTemplate,
      // deleteLastUsedTemplate,
      getRankToSave,
      onSaveFailed,
      onSaveSuccess,
      preferencesAPI,
    ]
  );

  const onRankFinish = useCallback(async () => {
    setIsLoading(false);
    setRankFrom(undefined);
    refreshTable();
    refreshClustersBricks();

    // *********************** USAGE ***********************
    var usage = window.App.usage;
    var info = {
      action: "RANK",
      actionParam: null,
      function: "RANKING",
    };
    usage.record(info);
    // *********************** USAGE ***********************

    const workflowState = currentRank != null ? "s1" : "s2";
    setWorkflow(workflowState);

    if (currentRankingParams == null) {
      const currentUnsavedRankParams = await getRankToSave();

      setCurrentRankingParams(currentUnsavedRankParams);
    }

    // Clear the id of the last template selected
    // If the user has ranked it means that he has completed the create operation so now the current rank is the one that
    // the user is operating with
    currentRankingTemplateRef.current = undefined;
    // saveLastUsedTemplate();
  }, [
    currentRank,
    currentRankingParams,
    getRankToSave,
    refreshClustersBricks,
    refreshTable,
  ]);

  const onRanksOrderChange = useCallback(
    async (value) => {
      const orderMap = {};

      for (let i = 0; i < value.length; i++) {
        orderMap[value[i].id] = i;
      }

      await pageStatusAPI.patch(["rank", "ranksOrder"], orderMap);
    },
    [pageStatusAPI]
  );

  const handleFirstRank = useCallback(async () => {
    const lastUsed = await pageStatusAPI.get(["rank", "lastUsedTemplate"]);
    let lastUsedId = lastUsed ?? undefined;
    let isIdInUserRanks = false;

    if (lastUsedId != null) {
      for (const r of userRanks) {
        if (r.id === lastUsedId) {
          isIdInUserRanks = true;

          break;
        }
      }
    }

    const loadLastUsed = lastUsedId != null && isIdInUserRanks === true;

    const startTemplate = loadLastUsed ? lastUsedId : userRanks[0].id;

    changeRankTemplate(startTemplate);
    sethasRankedOnLanding(true);
  }, [changeRankTemplate, pageStatusAPI, userRanks]);

  // Removes the loader when page is loaded
  useEffect(() => {
    removeLoader();
  }, []);

  // Get the saved user Rank templates
  useEffect(() => {
    getSavedTemplates();
  }, [getSavedTemplates]);

  // Compute first ranking user template
  useEffect(() => {
    /**
     * Has Ranked on Landing is a flag that is used to compute the first rank automatically,
     * then the flag is set to false to avoid extra service call that are caused by dependecies changes
     *
     * The check on the existence of the table columns is used to wait unitl the columns are loaded in the use rank hook
     * when they are ready we can call the rank function (UI columns are needed by rank function to fetch right fields)
     *
     * */

    if (
      !hasRankedOnLanding &&
      tableColumns.length &&
      userRanks &&
      userRanks.length
    ) {
      handleFirstRank();
    }
  }, [handleFirstRank, hasRankedOnLanding, tableColumns.length, userRanks]);

  useEffect(() => {
    setTableColumns(rankResults.columns);
  }, [rankResults.columns]);

  /**
   * Handle workflow Bar
   */
  useEffect(() => {
    const actions: any = [];

    const handleEditDialogOpen = () => {
      takeSnapShot();
      openRankDialog();
    };

    const handleCreateDialogOpen = () => {
      onCreate();
    };

    const actionsParams = deepClone(currentRankingParams);

    const exportFileName =
      actionsParams != null
        ? actionsParams["name"] + ".csv"
        : "trendrating-ranking.csv";

    const actionsMap = {
      new: {
        componentJSX: (
          <li className="menu__item" onClick={handleCreateDialogOpen}>
            New
          </li>
        ),
      },
      edit: {
        componentJSX: (
          <li onClick={handleEditDialogOpen} className="menu__item">
            Edit
          </li>
        ),
      },
      save: {
        componentJSX: (
          <li onClick={openSaveDialog} className="menu__item">
            Save
          </li>
        ),
      },
      export:
        actionsParams != null ? (
          {
            componentJSX: (
              <Export
                rankingCache={rankingCache}
                fileName={exportFileName}
                label={"Export"}
                list={
                  actionsParams["target"]["type"] === "list"
                    ? actionsParams["target"]["object"]
                    : null
                }
                widgets={{
                  table: { get: getWidgetInfo },
                }}
              />
            ),
          }
        ) : (
          <></>
        ),
      report: {
        componentJSX: (
          <ReportButton
            page={"ranking"}
            target={actionsParams}
            rankingCache={rankingCache}
            title={(actionsParams as any)?.name ?? ""}
            usage={window.App.usage}
            widgets={{ table: { get: getWidgetInfo } }}
          />
        ),
      },
      delete:
        actionsParams != null ? (
          {
            componentJSX: (
              <Remove
                item={actionsParams}
                label={"Delete"}
                onRemoveDone={() => listenerRemove(actionsParams)}
              />
            ),
          }
        ) : (
          <></>
        ),
    };

    const workflows = {
      s0: ["new"],
      s1: ["new", "edit", "save", "export", "report", "delete"],
      s2: ["new", "edit", "save", "export", "report"],
    };

    let action: any = null;
    const userId = environment.get("account")["user"]["id"];
    const ownerId = actionsParams?.["ownerId"] ?? null;
    const isReadOnly = userId !== ownerId;

    for (const actionName of workflows[workflow]) {
      action = actionsMap[actionName];

      switch (actionName) {
        case "delete": {
          if (!isReadOnly) {
            actions.push(action);
          }

          break;
        }

        default:
          actions.push(action);
      }
    }

    var message = {
      from: "RANK",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    currentRankingParams,
    environment,
    getWidgetInfo,
    listenerRemove,
    onCreate,
    openRankDialog,
    openSaveDialog,
    rankingCache,
    takeSnapShot,
    workflow,
  ]);

  /**
   * Change the title based on the current Rank
   */
  useEffect(() => {
    handleTitle({
      type: PREFERENCE_TYPE,
      name: currentRankingParams?.name ?? "",
    });
  }, [currentRankingParams?.name]);

  const sortField = useMemo(() => sorter.field, [sorter.field]);
  const sortDir = useMemo(
    () => (sorter.rev === false ? "desc" : "asc"),
    [sorter.rev]
  );

  return (
    <Box className={styles.main_wrapper}>
      {showDialogSave && (
        <DialogSaveComponent
          item={{
            name: currentRankingParams?.["name"] ?? "",
          }}
          dialogType={"Rank"}
          onSave={blockEditActions === true ? null : editRank}
          onSaveAs={createRank}
          onRename={blockEditActions === true ? null : editRank}
          hide={closeSaveDialog}
        />
      )}
      {showRankDialog && (
        <RankDialog
          onCancel={restartFromSnapshot}
          closeDialog={closeRankDialog}
          onRankedCallback={onRankFinish}
          page={"rank"}
        />
      )}
      {/* Rank templates */}
      {isStartingFromScratch ? (
        <CreateMessage />
      ) : (
        <>
          <Row>
            {userRanks?.length ? (
              <SortableBricks
                key={rankListKey}
                selectedBrickId={currentRank}
                onClickBrick={changeRankTemplate}
                list={userRanks}
                disabled={freezeSelection}
                onDropFinish={onRanksOrderChange}
              />
            ) : (
              <></>
            )}
          </Row>

          {/* Rank Results Table */}
          <Row>
            <Card className={styles.tableWrapperRow}>
              <CardContent>
                <ClusterBricks key={clusterBriksKey} />
                <InstrumentsTable
                  key={tableKey}
                  tableProps={{
                    correction: 40,
                    tooltip: { actions: { info: { enabled: true } } },
                    sorting: {
                      field: sortField,
                      direction: sortDir,
                    },
                    options: {
                      ajaxSorting: false,
                      height: 500,
                    },
                  }}
                  tools={{
                    configurator: {
                      hasToSkipLastApplied: false,
                      defaultTemplateNameBase: "DEFAULT_SCREENING",
                      configurations: configurationScreening.widgets.viewer,
                      securityType: "security",
                      isSaveLastUsedConfigurationColumnsEnabled: true,
                    },
                    addToButton: true,
                    pagination: {
                      dataTotalCount: dataTotalCount,
                      itemsPerPage,
                      changePage: onChangePage,
                    },
                    viewAsListButton: true,
                    rowsNumberSelect: {
                      label: "Securities per page",
                      onChangeItemsPerPage: onSelectItemPerPage,
                      initialValue: itemsPerPage,
                    },
                    rank: {
                      showRankTools: true,
                      onChangeRankDate: changeRankDateFrom,
                      dateInitValue: rankFrom,
                    },
                  }}
                  columns={tableColumns}
                  tableData={rankResults?.data ?? []}
                  getInitColumns={updateColumns}
                  onColumnsChange={onColumnsChange}
                  useAutoSort={false}
                  handleSortManually={onDataSorted}
                  loading={loading}
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </Box>
  );
}

const CreateMessage = () => {
  return (
    <Card sx={{ height: "98%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: "1.5em", textAlign: "center" }}>
            Create your own ranking for your portfolios and investment
            universes.
            <br />
            Track your rankings to stay on top of new emerging trends.
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              style={{
                fontSize: "1.5em",
                marginBottom: "30px",
                marginTop: "50px",
                fontWeight: "bold",
              }}
            >
              Start Now
            </Typography>
            <div className="tDataViewer-arrowDownIndicator"></div>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const Row = ({ children }) => {
  return <Box className={styles.row}>{children}</Box>;
};

class RankBuilder {
  state: any;
  listAPI: Lists;
  instrumentsAPI: Instruments;
  context: ActionRankContextType;
  environment: AppEnvironment;
  rankingHelperAPI: RankingUi2Api;

  constructor(
    context: ActionRankContextType,
    environment: AppEnvironment,
    state?: any
  ) {
    this.context = context;
    this.environment = environment;
    this.state = state;

    this.listAPI = new Lists(environment);
    this.rankingHelperAPI = new RankingUi2Api(environment);
    this.instrumentsAPI = new Instruments(environment);
  }

  new() {
    return this.newState({});
  }

  async target() {
    const currentRankParams = this.context.getCurrentState();
    const universeFrom = currentRankParams.universeFrom;
    const currentConstraints = currentRankParams.constraints;
    const type = universeFrom === "whiteList" ? "list" : "instruments";

    let error = "Missing universe constraints";
    const syntaxType =
      "constraints" in currentConstraints ? "screening" : "select";

    if (type === "list") {
      if (syntaxType === "select") {
        if ("relations" in currentConstraints) {
          const listId = currentConstraints.relations?.[0].domain?.[0] ?? null;

          if (listId != null) {
            const listType = await this.getListType(listId);

            const target: any = {
              type,
              object: {
                type: listType,
                id: listId,
              },
            };

            return this.newState({ ...this.state, target });
          }
        }
      } else {
        let relationFilter: any = null;

        for (const constraint of currentConstraints.constraints) {
          for (const filter of constraint) {
            if (filter.operator === "relation") {
              relationFilter = filter;

              break;
            }
          }
        }

        if (relationFilter != null) {
          const listId = relationFilter?.segments?.[0];

          if (listId) {
            const listType = await this.getListType(listId);
            const target: any = {
              type,
              object: {
                type: listType,
                id: listId,
              },
            };

            return this.newState({ ...this.state, target });
          }
        }
      }

      throw new Error(error);
    } else {
      currentConstraints["page"] = {
        page: 1,
        rows: 3000,
      };

      if (syntaxType === "select") {
        if (!("sort" in currentConstraints)) {
          currentConstraints["sort"] = {
            dimension: "marketcap",
            rev:
              "justInTimeTops" in currentConstraints
                ? currentConstraints?.["justInTimeTops"]?.[0]?.["rev"] ?? false
                : false,
          };
        }

        const target = {
          type,
          object: {
            constraints:
              this.instrumentsAPI.convertToIndexBuilderSyntax(
                currentConstraints
              ),
          },
        };

        return this.newState({ ...this.state, target });
      } else {
        let topOperator: any = null;

        for (const constraint of currentConstraints.constraints) {
          for (const filter of constraint) {
            if (filter.operator === "top") {
              topOperator = filter;
            }

            break;
          }
        }

        if (!("sort" in currentConstraints)) {
          currentConstraints["sort"] = {
            dimension: "marketcap",
            rev: topOperator != null ? topOperator?.rev ?? false : false,
          };
        }

        const target = {
          type,
          object: {
            constraints: currentConstraints,
          },
        };

        return this.newState({ ...this.state, target });
      }
    }
  }

  async againtsList() {
    const currentRankParams = this.context.getCurrentState();
    const currentAgainstListId = currentRankParams.highlightListId;

    let againstList: {
      object: { type: "PORTFOLIO" | "BASKET"; id: number };
      type: "list";
    } | null = null;

    if (currentAgainstListId) {
      const type = await this.getListType(currentAgainstListId);
      againstList = {
        type: "list",
        object: { id: currentAgainstListId, type },
      };
    }

    return this.newState({ ...this.state, against: againstList });
  }

  columns() {
    const currentRankParams = this.context.getCurrentState();
    const currentColsFields = currentRankParams.columnsFields;
    const columnsHelper = new TableHelpers(this.environment);

    const columns = columnsHelper.generateFromFields(currentColsFields);

    return this.newState({ ...this.state, columns: columns });
  }

  fromDate() {
    const currentRankParams = this.context.getCurrentState();
    const fromDateValue = currentRankParams.fromDate;

    if (fromDateValue != null) {
      return this.newState({ ...this.state, fromDate: fromDateValue });
    } else {
      return this.newState({ ...this.state, fromDate: null });
    }
  }

  name(name: string) {
    return this.newState({ ...this.state, name });
  }

  rules() {
    const currentRankParams = this.context.getCurrentState();
    const currentRules = currentRankParams.rules;

    const encodedRules = this.rankingHelperAPI.encode(currentRules);

    return this.newState({ ...this.state, rules: encodedRules });
  }

  stringifiedRules() {
    const currentRankParams = this.context.getCurrentState();
    const currentRules = currentRankParams.rules;

    const rulesObject = {
      consfiguration: { ranking: currentRules },
    };

    return this.newState({
      ...this.state,
      stringifiedRules: JSON.stringify(rulesObject),
    });
  }

  build() {
    return this.state;
  }

  private async newState(modifiedState) {
    this.state = await modifiedState;

    return new RankBuilder(this.context, this.environment, this.state);
  }

  private async getListType(id) {
    try {
      const response = await this.listAPI.portfolioFetch([id], ["type"]);

      return response?.[0].type;
    } catch (error) {
      console.log(error);
    }
  }
}
