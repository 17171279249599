/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/Properties
 * @summary Retrieves a property label
 *
 */

export class Properties {
    properties: any = null;

    constructor({ properties }: any) {
        // NOTE: priority or property override is not managed
        // Should be?
        this.properties = {
            etf: {},
            index: {},
            security: {},
            stock: {},
        };
        const typeSpecificProperties = ["ETF", "Index", "Stock"];
        for (const property in properties) {
            if (typeSpecificProperties.includes(property)) {
                this.properties[property.toLowerCase()] = properties[property];
            } else {
                this.properties.security[property] = properties[property];
            }
        }
    }

    /**
     *
     * Get the label of the property
     *
     * @param {string} property - The property for which get the label
     * @param {number} labelIndex - the index of label to get. Default 0
     * @param {string} instrumentType - the type of instrument. If "auto",
     *      it looks for the first available match. Default "security"
     *
     * @returns {string} the label
     */
    get(property: string, labelIndex: number, instrumentType: string| null) {
        return this._get(property, labelIndex, instrumentType, "name");
    }

    getForSecurityType(
        securityType: string,
        property: string,
        labelIndex: number
    ) {
        console.log(
            "[TODO] getForSecurityType",
            securityType,
            property,
            labelIndex
        );

        return property;
    }

    getTag(property: string, labelIndex: number, instrumentType: string) {
        return this._get(property, labelIndex, instrumentType, "tags");
    }

    getBuilderGroup(property: string, instrumentType: string ) {
        return this._get(property, null, instrumentType, "builderGroup");
    }

    _get(
        property: string,
        labelIndex: number | null,
        instrumentType: string | null,
        key: string
    ) {
        const defaultInstrumentType = "security";

        instrumentType =
            instrumentType != null
                ? instrumentType.toLowerCase()
                : defaultInstrumentType;
        labelIndex =
            labelIndex !== undefined && labelIndex != null ? labelIndex : 0;
        const properties = this.properties;

        // auto option: looks for a match. The first match is returned.
        if (instrumentType === "auto") {
            for (const _instrumentType in properties) {
                if (property in properties[_instrumentType]) {
                    if (properties[_instrumentType][property][key] == null) {
                        return null;
                    }

                    if (
                        Array.isArray(
                            properties[_instrumentType][property][key]
                        )
                    ) {
                        return properties[_instrumentType][property][key][
                            labelIndex
                        ];
                    } else {
                        return properties[_instrumentType][property][key];
                    }
                }
            }
        }

        let propertyType =
            instrumentType in properties
                ? instrumentType
                : defaultInstrumentType;

        if (propertyType == null) {
            return property;
        }

        if (!(property in properties[propertyType])) {
            propertyType = defaultInstrumentType;
            if (!(property in properties[propertyType])) {
                return property;
            }
        }

        const label = properties[propertyType][property][key][labelIndex];
        if (label === undefined) {
            throw new Error(
                "LabelIndex " +
                    labelIndex +
                    " out of range: " +
                    JSON.stringify(properties[propertyType][property][key])
            );
        }

        return label;
    }
}
