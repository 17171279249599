import { _Base } from "../../../../../../api/_Base";
import { ClusterAnalytics } from "../../../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { formatTaxonPrefixingParent } from "../../../../../../api/compute/Taxon";
import { endpoints } from "../../../../../../api/endpoints";
import { Properties } from "../../../../../../api/Properties";
import { deepClone } from "../../../../../../deepClone";
import { AppEnvironment } from "../../../../../../types/Defaults";
import { areFloatsEqual } from "../../../../utils";

class FieldMap {
  list: any[];

  constructor(trasparency) {
    this.list = [];

    this.set(null, "name");
    this.set(null, "ticker");
    this.set(null, "type");

    let col, j, N;
    let idx = 0;

    for (let i in trasparency.columns) {
      col = trasparency.columns[i];
      for (j = 0, N = col.children.length; j < N; j++) {
        if (typeof col.children[j] === "string") {
          this.set(idx, col.children[j]);
        } else {
          this.set(idx, col.children[j].dimension, "fetch", col.label, idx);
        }

        idx++;
      }
    }
  }

  public set(
    idx: number | null,
    field: string,
    type?: string,
    section?: string,
    k?
  ): any {
    let record;

    record = {
      field,
      type,
      section,
      idx,
      key: this.computeKey(k, field, type, section),
    };

    this.list.push(record);

    return record;
  }

  private computeKey(idx, field: string, type?: string, section?: string) {
    let key = field;

    if (type != null) {
      key += "_" + type;
    }

    if (section != null) {
      key += "_" + section;
    }

    if (idx != null && idx !== 0) {
      key += "_" + idx;
    }

    return key;
  }

  public get(field, type?, section?) {
    for (const property of this.list) {
      if (
        property.field === field &&
        property.type === type &&
        property.section === section
      ) {
        return property;
      }
    }

    throw new Error(`Unmapped get:${field} ${type} ${section}`);
  }

  public getEx(field, k, type?, section?) {
    let key = this.computeKey(k, field, type, section);

    for (const record of this.list) {
      if (record.key === key) {
        return record;
      }
    }

    throw new Error(`Unmapped get:${field} ${type} ${section}`);
  }

  public getIdx(field, type, section) {
    const obj = this.get(field, type, section);

    return obj ? obj.idx : false;
  }

  public getKey(field, type, section) {
    let obj = this.get(field, type, section);

    return obj ? obj.key : false;
  }

  public getByKey(key) {
    for (const property of this.list) {
      if (property.key === key) {
        return property;
      }
    }

    throw new Error(`Unmapped getByKey:${key}`);
  }

  public exists(field, type?, section?) {
    for (const property of this.list) {
      if (
        property.field === field &&
        property.type === type &&
        property.section === section
      ) {
        return true;
      }
    }

    return false;
  }

  public setTrasparencyValueToRow(trasparencyRow, row, field, type, section) {
    let record = this.get(field, type, section);
    let idx = this.getIdx(field, type, section);

    if (record) {
      row[record.key] = trasparencyRow[idx];

      return row;
    }

    throw new Error(`Unmapped setTrasparencyValue:${field}`);
  }

  public fieldsToFetchFields(fields) {
    const fieldFetch = {};

    for (const obj of fields) {
      fieldFetch[obj.field] = true;
    }

    return Object.keys(fieldFetch);
  }

  public wrapFetchRowToRow(fetchRow, section) {
    const row = {};
    let record;

    for (let property in fetchRow) {
      if (this.exists(property)) {
        record = this.get(property);

        row[record.key] = fetchRow[property];
      } else {
        record = this.get(property, "fetch", section);

        this.setValueByField(property, fetchRow[property], row);
      }
    }

    return row;
  }

  private setValueByField(field, value, row) {
    for (let record of this.list) {
      if (record.field === field) {
        row[record.key] = value;
      }
    }
  }
}

type InfoStruct = {
  type: string;
  maxPositions: number;
  transparencyRowIndexBySymbol: any;
  transparencyFieldIdx: any;
  candidateIdx: number;
  costituentIdx: number;
  weightIdx: number;
  rankIdx: number;
  universe: {
    screening: { sort: any; page: any; constraints: any };
    fields: string[];
    columnsOverwrites: any;
  };
  selection: {
    fields: string[];
    columnsOverwrites: any;
  };
  ranking: {
    fields: string[];
    columnsOverwrites: any;
  };
  weighting: {
    fieldsToBeFetched: any;
    fields: any;
    columnsOverwrites: any;
    symbols: any;
  };
};

type DataInfo = { fields: any[]; columnsOverwrites: any; rows: any };

type DataStruct = {
  universe: DataInfo;
  selection: DataInfo;
  ranking: DataInfo;
  weighting: DataInfo;
  summary: {
    funnel: {
      universeDomain: {
        idx: any;
        label: string;
        colorTag: "universe";
        N: number;
        type: string;
      };
      universe: {
        idx: number;
        label: "Investment Universe";
        colorTag: "investmentUniverse";
        N: number;
        type: string;
      };
      entitled: {
        idx: any;
        label: "Entitled";
        colorTag: "entitled";
        N: number;
        type: string;
      };
      candidates: {
        idx: any;
        label: "Consitutents<br/>Before Capping";
        colorTag: "candidate";
        N: number;
        type: string;
      };
      costituents: {
        idx: any;
        label: "Costituents";
        colorTag: "constituents";
        N: number;
        type: string;
      };
    };
    positions: any;
    limits: {
      positions: { max: null; min: null };
      cluster: {
        max: null;
        countryLevel: null;
        sectorLevel: null;
        type: string;
      };
    };
  };
};

export class Transparency extends _Base {
  exantePortfolio: { d: number; v: any[] };
  exAnteSecurities: any;
  portfolio: any;
  date: number;
  strategy: any;
  trasparency: any;
  allocation: any;
  securitiesMap: any;
  universeSymbols: any;
  issues: any;
  info: InfoStruct | undefined;
  data: DataStruct | undefined;
  API: {
    strategies: Strategies;
    instruments: Instruments;
    clusters: ClusterAnalytics;
    properties: Properties;
  };
  environment: AppEnvironment;

  constructor(strategy, POS, allocationIdx, environment: AppEnvironment) {
    super(environment);

    this.environment = environment;

    const properties = environment.configuration.properties;

    this.API = {
      strategies: new Strategies(environment),
      instruments: new Instruments(environment),
      clusters: new ClusterAnalytics(environment),
      properties: new Properties({ properties }),
    };

    let allocation = POS[allocationIdx];

    if (allocationIdx > 0) {
      let exantePos = POS[allocationIdx - 1];
      let exAntePortfolio: any = [];
      let exAnteSecurities: any = [];
      for (let i = 0, N = exantePos.v.length; i < N; i++) {
        exAntePortfolio.push({ S: exantePos.v[i].S, A: exantePos.v[i].A });
        exAnteSecurities.push(exantePos.v[i].S);
      }
      this.exantePortfolio = { d: exantePos.d, v: exAntePortfolio };
      this.exAnteSecurities = exAnteSecurities;
    } else {
      this.exantePortfolio = { d: allocation.d, v: [] };
      this.exAnteSecurities = [];
    }

    this.portfolio = [];
    for (var idx in allocation.v) {
      if (allocation.v[idx].S !== "CASH") {
        // non dovrebbe servire ma male non fa
        this.portfolio.push({ S: allocation.v[idx].S, A: allocation.v[idx].A });
      }
    }

    this.date = allocation.d;
    this.strategy = this.API.strategies.prepareStrategyForRationale(strategy);

    this.trasparency = null;

    this.allocation = null;
    this.securitiesMap = {};
    this.universeSymbols = null;
    this.issues = [];
  }

  //-----------------------------------------------------
  async initialize() {
    this.initStruct();

    var dataPost = {
      verbose: true,
      strategy: this.strategy.params.strategy,
      universe: this.strategy.params.universe,
      date: this.date,
      exAntePortfolio: this.exantePortfolio,
    };

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.rationales;

    const response = await this.preparePost(url, dataPost);
    this.parseTransparencyDataInfo(response);
  }

  //-----------------------------------------------------
  private parseTransparencyDataInfo(data) {
    this.trasparency = data.data.trasparency;
    this.info!.transparencyFieldIdx = new FieldMap(this.trasparency);
    const fieldsMapping = this.info?.transparencyFieldIdx;
    this.info!.universe.fields = [
      fieldsMapping.get("name"),
      fieldsMapping.get("ticker"),
      fieldsMapping.get("type"),
    ];
    this.info!.selection.fields = [
      fieldsMapping.get("name"),
      fieldsMapping.get("ticker"),
      fieldsMapping.get("type"),
    ];
    this.info!.ranking.fields = [
      fieldsMapping.get("name"),
      fieldsMapping.get("ticker"),
      fieldsMapping.get("type"),
    ];
    this.universeSymbols = this.trasparency.symbols;

    let transparencyRowIndexBySymbol = {};
    let col, i, j, N, f, record;

    for (i in this.trasparency.symbols) {
      transparencyRowIndexBySymbol[this.trasparency.symbols[i]] = i;
    }
    // ----------- universe screening & fields
    var universe = this.strategy.params.universe.search;
    if (universe.justInTimeTops !== undefined) {
      for (let i = 0, N = universe.justInTimeTops.length; i < N; i++) {
        record = fieldsMapping.set(
          null,
          universe.justInTimeTops[i].dimension,
          "fetch",
          "universe"
        );
        this.info!.universe.fields.push(record);

        this.info!.universe.columnsOverwrites[record.key] = {
          formatInUniverse: true,
        };
      }
    }
    this.info!.universe.screening.page = universe.page;
    this.info!.universe.screening.sort = [universe.sort];
    let constraints: any = [];
    let filter;

    if (universe.filters !== undefined) {
      for (let i = 0, N = universe.filters.length; i < N; i++) {
        filter = universe.filters[i];
        constraints.push({
          dimension: filter.dimension,
          operator: "equals",
          segments: filter.segments,
        });

        if (filter["dimension"] === "type") {
          this.info!.type = filter["segments"][0];
        }
      }
    }

    if (universe.relations !== undefined) {
      for (let i = 0, N = universe.relations.length; i < N; i++) {
        filter = universe.relations[i];
        var collectionsIds: any = [];
        let strId: any = null;
        for (strId of filter.domain) {
          collectionsIds.push(parseInt(strId));
        }
        constraints.push({
          dimension: "COLLECTION",
          operator: "relation",
          segments: collectionsIds,
        });
      }
    }
    this.info!.universe.screening.constraints = [constraints];
    //---------------------------------

    this.info!.maxPositions =
      this.strategy.params.strategy.selectionRules.maxPositions;
    this.info!.transparencyRowIndexBySymbol = transparencyRowIndexBySymbol;
    // this.info!.transparencyFieldIdx = transparencyFieldIdx;

    let weightingInfo = this.requiredFetchingWeigthingFields();
    this.info!.weighting.fieldsToBeFetched = weightingInfo.fieldsToBeFetched;
    this.info!.weighting.fields = weightingInfo.fields;

    let rankingAdditionalField = this.additionalRankWeightFields();

    if (rankingAdditionalField != null) {
      record = fieldsMapping.set(
        -1,
        rankingAdditionalField,
        "fetch",
        "Ranking"
      );
      this.info!.ranking.fields.push(record);
    }

    let k = 0;

    for (i in this.trasparency.columns) {
      col = this.trasparency.columns[i];
      if (col.label === "Selection") {
        for (j = 0, N = col.children.length; j < N; j++) {
          f =
            typeof col.children[j] === "string"
              ? col.children[j]
              : col.children[j].dimension;
          if (f === "ticker" || f === "candidate") {
            k++;
            continue; // questo e' il ticker numerico che non vogliamo a video
          }
          let o = {
            k: k,
            formatByColor: true,
            quantile: false,
            top: false,
          };
          if (
            col.children[j].function !== undefined &&
            col.children[j].function === "quantile"
          ) {
            o.quantile = true;
          }
          if (
            col.children[j].operator !== undefined &&
            (col.children[j].operator === "top" ||
              col.children[j].operator === "bottom")
          ) {
            o.top = true;
          }
          record = fieldsMapping.getEx(f, k, "fetch", "Selection");
          this.info!.selection.fields.push(record);
          this.info!.selection.columnsOverwrites[record.key] = o;

          k++;
        }
      } else if (col.label === "Ranking") {
        for (j = 0, N = col.children.length; j < N; j++) {
          f = col.children[j];
          if (f === "ticker" || f === "candidate") {
            k++;
            continue; // questo e' il ticker numerico che non vogliamo a video
          }

          if (typeof f === "string") {
            record = fieldsMapping.get(f);
          } else {
            let o = {
              k: k,
              quantile: false,
            };
            if (f.rankType !== undefined && f.rankType === "quantile") {
              o.quantile = true;
            }

            record = fieldsMapping.getEx(f.dimension, k, "fetch", "Ranking");

            this.info!.ranking.columnsOverwrites[record.key] = o;
          }

          this.info!.ranking.fields.push(record);

          k++;
        }
      } else if (col.label === "Hold") {
        for (j = 0, N = col.children.length; j < N; j++) {
          f = col.children[j];
          // typeof col.children[j] === "string"
          // ? col.children[j]
          // : col.children[j].dimension;
          if (f === "ticker" || f === "candidate") {
            k++;
            continue; // questo e' il ticker numerico che non vogliamo a video
          }

          if (f === "holds") {
            record = fieldsMapping.get("holds");

            this.info!.ranking.fields.push(record);
            this.info!.ranking.columnsOverwrites[record.key] = {
              field: "holds",
              title: "From holding",
              align: "center",
            };
          }

          if (typeof f === "string") {
            // record = fieldsMapping.set(f);
            // record = fieldsMapping.get(f);
          } else {
            let o = {
              k: k,
              formatByColor: true,
              quantile: false,
              top: false,
            };
            if (
              col.children[j].function !== undefined &&
              col.children[j].function === "quantile"
            ) {
              o.quantile = true;
            }
            if (
              col.children[j].operator !== undefined &&
              (col.children[j].operator === "top" ||
                col.children[j].operator === "bottom")
            ) {
              o.top = true;
            }

            record = fieldsMapping.set(k, f.dimension, "fetch", "Ranking");
            this.info!.ranking.columnsOverwrites[record.key] = o;
            this.info!.ranking.fields.push(record);
          }

          k++;
        }
      } else {
        k++;
      }
    }

    record = fieldsMapping.get("candidate");

    this.info!.selection.fields.push(record);
    this.info!.selection.columnsOverwrites[record.key] = {
      field: "candidate",
      title: "Entitled",
      align: "center",
    };

    record = fieldsMapping.set(null, "rankchangedByCapping");
    this.info!.ranking.fields.push(record);
    this.info!.ranking.columnsOverwrites[record.key] = {
      field: "rankchangedByCapping",
      title: "",
      align: "center",
    };

    if (rankingAdditionalField != null) {
      const overwriteCol = this.overrideAdditionalTaxonColumn("Ranking");

      if (overwriteCol) {
        this.info!.ranking.columnsOverwrites[
          fieldsMapping.getKey(rankingAdditionalField, "fetch", "Ranking")
        ] = overwriteCol;
      }
    }
  }

  //---------------------------------------------------------------------
  private overrideAdditionalTaxonColumn(section: "Ranking" | "Weighting") {
    const col: any = {
      field: "",
      title: "",
      formatter: () => {},
      sorter: (a, b) => {},
    };

    const fieldsMapping = this.info?.transparencyFieldIdx;

    const sectorCappingLevel =
      this.strategy?.params?.strategy?.cappingRules?.rotation?.sectorLevel;
    const countryCappingLevel =
      this.strategy?.params?.strategy?.cappingRules?.rotation?.countryLevel;

    let cappingField: any = null;

    if (sectorCappingLevel != null) {
      cappingField = this.info!.type === "ETF" ? "etfclass" : "icb";
    } else if (countryCappingLevel != null) {
      cappingField = this.info!.type === "ETF" ? "etfgeo" : "country";
    }

    if (cappingField != null) {
      const taxonomies = this.environment["taxonomies"];
      const fields = this.environment["taxonomyFields"];

      const taxonomy =
        taxonomies[
          fields[this.info!.type === "ETF" ? "ETF" : "security"][cappingField]
        ];

      const cappingLevel = sectorCappingLevel ?? countryCappingLevel;

      const fieldsColumnInfo = {
        stocks: {
          Country: { title: "Market", field: "country" },
          Area: { title: "Area", field: "country" },
          Region: { title: "Region", field: "country" },
          "1 Industry": { title: "Sector", field: "icb" },
          "3 Sector": { title: "Industry", field: "icb" },
        },
        etf: {
          Country: { title: "Inv. Region", field: "etfgeo" },
          Area: { title: "Area", field: "etfgeo" },
          Region: { title: "Region", field: "etfgeo" },
          "1 Industry": { title: "Asset Class", field: "etfclass" },
          "3 Sector": { title: "Specialty", field: "etfclass" },
          "4 Subsector": { title: "Theme", field: "etfclass" },
        },
      };

      const fieldsInfoKey = this.info!.type === "ETF" ? "etf" : "stocks";
      col.title = fieldsColumnInfo[fieldsInfoKey][cappingLevel].title;
      col.field = fieldsMapping.getKey(
        fieldsColumnInfo[fieldsInfoKey][cappingLevel].field,
        "fetch",
        section
      );

      const formatter = (cell) => {
        const value = cell.getValue();

        if (cappingLevel === "3 Sector" || cappingLevel === "4 Subsector") {
          const v = formatTaxonPrefixingParent(
            taxonomy[value],
            [taxonomy],
            cappingLevel
          );

          return v;
        }

        let parent: any = taxonomy?.[value];

        while (parent.type !== cappingLevel) {
          parent = taxonomy[parent.parent];
        }

        return parent.name ?? value;
      };

      col.formatter = formatter;

      const sorter = (a, b) => {
        a = taxonomy[a].name;
        b = taxonomy[b].name;

        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }

        return 0;
      };

      col.sorter = sorter;

      return col;
    }
  }
  //---------------------------------------------------------------------
  private additionalRankWeightFields() {
    let strategy = this.strategy.params.strategy;

    if (strategy.cappingRules.rotation !== undefined) {
      let rotation = strategy.cappingRules.rotation;
      let countryLevel = rotation.countryLevel;
      let sectorLevel = rotation.sectorLevel;
      if (rotation.capValue !== undefined) {
        let additionalField = countryLevel ?? sectorLevel ?? null;

        if (additionalField != null) {
          const fields = {
            stocks: {
              Country: "country",
              Area: "country",
              Region: "country",
              "1 Industry": "icb",
              "3 Sector": "icb",
            },
            etf: {
              Country: "etfgeo",
              Area: "Area",
              Region: "Region",
              "1 Industry": "etfclass",
              "3 Sector": "etfclass",
              "4 Subsector": "etfclass",
            },
          };

          return fields[this.info!.type === "ETF" ? "etf" : "stocks"][
            additionalField
          ];
        }
      }
    }
  }
  //---------------------------------------------------------------------
  private requiredFetchingWeigthingFields() {
    let strategy = this.strategy.params.strategy;
    const fieldsMapping = this.info?.transparencyFieldIdx;
    let record;

    let fieldsToBeFetched = [
      fieldsMapping.get("name"),
      fieldsMapping.get("ticker"),
    ];
    let fields = [fieldsMapping.get("name"), fieldsMapping.get("ticker")];

    if (strategy.weightingRules.weightCriteria === "PROPORTIONAL_MARKETCAP") {
      record = fieldsMapping.set(null, "marketcap", "fetch", "Weighting");
      fieldsToBeFetched.push(record);
      fields.push(record);
    }
    if (strategy.weightingRules && strategy.weightingRules.factors) {
      // column factor only when there are factors
      let field;
      for (let factorIdx in strategy.weightingRules.factors) {
        field = strategy.weightingRules.factors[factorIdx].dimension;
        if (field !== undefined && field !== null) {
          record = fieldsMapping.set(null, field, "fetch", "Weighting");

          fieldsToBeFetched.push(record);
          fields.push(record);
        }
      }
    }

    let additionalTransparencyFields = {
      exWeight: { order: 1, field: "exWeight" },
      nWeight: { order: 2, field: "nWeight" },
      orWeight: { order: 3, field: "orWeight" },
      multifactor: { order: 4, field: "multifactor" },
      beforeCapWeight: { order: 6, field: "beforeCapWeight" },
      Weight: { order: 7, field: "Weight" },
    };

    let weigthingColumns = [additionalTransparencyFields["Weight"]];

    if (strategy.weightingRules.weightRule !== "REBALANCE") {
      // column ex-ante weigth only if there is exists in ranking and keep weigths
      weigthingColumns.push(additionalTransparencyFields["exWeight"]);
    }

    if (strategy.weightingRules && strategy.weightingRules.factors) {
      // column factor only when there are factors
      weigthingColumns.push(additionalTransparencyFields["multifactor"]);
    }

    if (
      strategy.cappingRules && // column capping only if there is capping
      ((strategy.cappingRules.maxAllocation &&
        strategy.cappingRules.maxAllocation !== 1.0) ||
        (strategy.cappingRules.minAllocation &&
          strategy.cappingRules.minAllocation !== 0.0) ||
        (strategy.cappingRules.capValue &&
          strategy.cappingRules.capValue !== 1.0) ||
        (strategy.cappingRules.minValue &&
          strategy.cappingRules.minValue !== 0.0) ||
        (strategy.cappingRules.rotation &&
          strategy.cappingRules.rotation.capValue &&
          strategy.cappingRules.rotation.capValue !== 1.0))
    ) {
      //			weigthingColumns.push(additionalTransparencyFields['capWeight']);
      weigthingColumns.push(additionalTransparencyFields["beforeCapWeight"]);

      if (
        strategy?.cappingRules?.rotation?.sectorLevel != null ||
        strategy?.cappingRules?.rotation?.countryLevel != null
      ) {
        const additionalWeightField = this.additionalRankWeightFields();

        const additionalFieldRecord = fieldsMapping.set(
          null,
          additionalWeightField,
          "fetch",
          "Weighting"
        );
        fieldsToBeFetched.push(additionalFieldRecord);
        fields.push(additionalFieldRecord);

        const overwriteCol = this.overrideAdditionalTaxonColumn("Weighting");

        if (overwriteCol) {
          this.info!.weighting.columnsOverwrites[additionalFieldRecord.key] =
            overwriteCol;
        }
      }
    }
    weigthingColumns.sort(function (a, b) {
      return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
    });

    for (let i = 0, N = weigthingColumns.length; i < N; i++) {
      record = fieldsMapping.get(weigthingColumns[i].field);
      fields.push(record);
    }

    return { fieldsToBeFetched: fieldsToBeFetched, fields: fields };
  }
  //---------------------------------------------------------------------
  public async set() {
    let symbol: any = null;
    const fieldsMapping = this.info?.transparencyFieldIdx;
    for (let i = 0, N = this.universeSymbols.length; i < N; i++) {
      this.securitiesMap[this.universeSymbols[i]] = { inUniverse: true };
    }

    const setType = (securities) => {
      let type: any = false;
      let securityType = "";

      for (const security of securities) {
        securityType = security.type;
        if (type === false) {
          type = securityType;
        } else {
          if (securityType !== type) {
            return "securities";
          }
        }
      }

      return securityType + "s";
    };

    const reply = await this.API.instruments.screening(
      this.info!.universe.screening,
      true
    );

    for (let i = 0, N = reply.data.length; i < N; i++) {
      symbol = reply.data[i];
      if (this.securitiesMap[symbol] === undefined) {
        this.securitiesMap[symbol] = { inUniverse: false };
      }
    }

    let replyFetch = await this.API.instruments.fetch(
      {
        properties: fieldsMapping
          .fieldsToFetchFields(this.info!.universe.fields)
          .map((p) => ({
            date: null,
            property: p,
          })),
        type: "cube",
        symbols: reply.data,
        multiDates: [this.date],
      },
      true
    );

    let rows: any = [];
    let row;
    let rowFetch;
    for (symbol in replyFetch) {
      rowFetch = replyFetch[symbol][this.date];
      row = fieldsMapping.wrapFetchRowToRow(rowFetch, "universe");
      row.symbol = symbol;
      row.inUniverse = this.securitiesMap[symbol].inUniverse;
      rows.push(row);
      this.securitiesMap[symbol] = row;
    }

    this.data!.universe.rows = rows;

    // selection & counters ------------------------------
    let info = this.info;

    // let funnel_entitled = 0;
    // let funnel_candidates = 0;
    // let funnel_costituents = 0;
    rows = this.trasparency.rows;
    let entitledIdx = fieldsMapping.getIdx("candidate");
    let costituentIdx = fieldsMapping.getIdx("costituent");
    let weightIdx = fieldsMapping.getIdx("Weight");
    let symbols = this.trasparency.symbols;

    const entitledRows: any = [];
    const candidatesRows: any = [];
    const constituentsRows: any = [];
    const universeRows: any = [];

    for (let i = 0, N = rows.length; i < N; i++) {
      universeRows.push(this.securitiesMap[symbols[i]]);

      if (rows[i][entitledIdx] === 1) {
        entitledRows.push(this.securitiesMap[symbols[i]]);
      }
      if (rows[i][costituentIdx] === 1) {
        candidatesRows.push(this.securitiesMap[symbols[i]]);
      }
      if (rows[i][weightIdx] !== null) {
        constituentsRows.push(this.securitiesMap[symbols[i]]);
      }
    }
    if (this.strategy.params.universe.search.relations !== undefined) {
      this.data!.summary.funnel.universeDomain.label = "White List";
    }

    let selectionRows = {
      universeDomain: this.data!.universe.rows,
      universe: universeRows,
      entitled: entitledRows,
      candidates: candidatesRows,
      costituents: constituentsRows,
    };

    let fieldsToBeFetched = fieldsMapping
      .fieldsToFetchFields(this.info!.selection.fields)
      .map((p) => ({
        date: null,
        property: p,
      }));

    replyFetch = await this.API.instruments.fetch(
      {
        type: "cube",
        symbols: this.universeSymbols,
        properties: fieldsToBeFetched,
        multiDates: [this.date],
      },
      true
    );

    let fields = this.info!.selection.fields;
    let record;
    rows = [];
    row = null;
    let col;
    let value;
    let trasparencyIdx;
    let trasparencyRow;
    // var Ncandidates = 0;

    let contraintIdx = 0;
    for (symbol in replyFetch) {
      row = fieldsMapping.wrapFetchRowToRow(
        replyFetch[symbol][this.date],
        "Selection"
      );
      row.symbol = symbol;
      trasparencyIdx = this.info!.transparencyRowIndexBySymbol[symbol];

      trasparencyRow = this.trasparency.rows[trasparencyIdx];
      record = fieldsMapping.get("candidate");
      let idx = fieldsMapping.getIdx("candidate");
      row[record.key] = trasparencyRow[idx];
      //   Ncandidates += row.candidate;

      for (let i = 0, N = fields.length; i < N; i++) {
        record = fields[i];
        col = this.info!.selection.columnsOverwrites[record.key];

        if (col === undefined || col.k === undefined) {
          continue;
        }
        if (this.data!.summary.funnel[record.key] == null) {
          const fieldsConfiguration = this.API.properties;
          const columnName = fieldsConfiguration.get(record.field, 0, "auto");

          this.data!.summary.funnel[record.key] = {
            idx: contraintIdx++,
            label: columnName,
            colorTag: "entitled",
            N: 0,
            type: "",
          };
        }
        if ((fields[i + 1] as any).field === "candidate") {
          // l'ultimo e' quello seguito dalla colonna candidate
          value = row[fieldsMapping.getKey("candidate")] === 1 ? 1 : null;
        } else {
          value = trasparencyRow[col.k + 1]; // guarda se e' a null quello dopo, nel caso il vincolo non e' passato
        }
        row[record.key + "_passed"] = value !== null;
        this.data!.summary.funnel[record.key].N += value !== null ? 1 : 0;
        if (value != null) {
          let key = record.key;
          if (!(key in selectionRows)) {
            selectionRows[key] = [];
          }

          selectionRows[key].push(row);
        }
        //------------ gestione valori dei quantili
        if (col.quantile) {
          row[record.key + "_quantile"] = trasparencyRow[col.k];
        }
        if (col.top) {
          row[record.key + "_top"] = trasparencyRow[col.k];
        }
      }
      rows.push(row);
    }

    rows.sort((a, b) => {
      let key = fieldsMapping.getKey("candidate");

      if (a[key] > b[key]) {
        return -1;
      } else if (a[key] < b[key]) {
        return 1;
      }

      return 0;
    });

    for (const field in this.data!.summary.funnel) {
      this.data!.summary.funnel[field].N = selectionRows[field].length;
      this.data!.summary.funnel[field].type = setType(selectionRows[field]);
    }

    this.data!.selection.rows = rows;

    let costituents: any = [];
    costituentIdx = fieldsMapping.getIdx("costituent");
    for (let i = 0, N = this.trasparency.rows.length; i < N; i++) {
      //			if (current.trasparency.rows[i][candidateIdx]==1) {
      //				entitled.push(current.trasparency.symbols[i]);
      //			}
      if (this.trasparency.rows[i][costituentIdx] === 1) {
        costituents.push(this.trasparency.symbols[i]);
      }
    }
    //		current.info.ranking.symbols= entitled;
    this.info!.weighting.symbols = costituents;
    //-------------- RANK
    fieldsToBeFetched = fieldsMapping
      .fieldsToFetchFields(this.info!.ranking.fields)
      .map((p) => ({
        date: null,
        property: p,
      }));

    const rankingSymbols: any = [];

    record = fieldsMapping.get("candidate");
    let idxEntitled = fieldsMapping.getIdx("candidate");
    let idxHolds = null;
    if (fieldsMapping.exists("holds")) {
      record = fieldsMapping.get("holds");
      idxHolds = fieldsMapping.getIdx("holds");
    }

    for (let i = 0; i < this.trasparency.rows.length; i++) {
      row = this.trasparency.rows[i];

      if (row[idxEntitled] === 1 || (idxHolds != null && row[idxHolds] === 1)) {
        rankingSymbols.push(this.trasparency.symbols[i]);
      }
    }

    replyFetch = await this.API.instruments.fetch(
      {
        properties: fieldsToBeFetched,
        symbols: rankingSymbols,
        type: "cube",
        multiDates: [this.date],
      },
      true
    );

    fields = this.info!.ranking.fields;
    rows = [];

    //  trasparencyRow;
    let data = this.trasparency;
    info = this.info;

    for (symbol in replyFetch) {
      rowFetch = replyFetch[symbol][this.date];

      row = fieldsMapping.wrapFetchRowToRow(rowFetch, "Ranking");

      row.symbol = symbol;
      trasparencyIdx = info!.transparencyRowIndexBySymbol[symbol];

      trasparencyRow = data.rows[trasparencyIdx];

      fieldsMapping.setTrasparencyValueToRow(trasparencyRow, row, "rank");
      fieldsMapping.setTrasparencyValueToRow(trasparencyRow, row, "costituent");

      if (fieldsMapping.exists("holds")) {
        fieldsMapping.setTrasparencyValueToRow(trasparencyRow, row, "holds");
      }

      if (row.holds && row.rank === null) {
        row.rank = -1;
      }

      for (let i = 0, N = this.info!.ranking.fields.length; i < N; i++) {
        record = this.info!.ranking.fields[i];
        col = this.info!.ranking.columnsOverwrites[record.key];

        if (col === undefined || col.k === undefined) {
          continue;
        }

        //------------ gestione valori dei quantili
        if (col.quantile) {
          row[record.key + "_quantile"] = trasparencyRow[col.k];
        }
        if (col.top) {
          row[record.key + "_top"] = trasparencyRow[col.k];
        }
      }

      row.rankchangedByCapping = 0;
      rows.push(row);
    }
    rows.sort(function (a, b) {
      // in 0 il rank piu basso, nulls alla fine
      if (a.rank === null && b.rank !== null) return 1;
      if (b.rank === null && a.rank !== null) return -1;
      if (b.rank === a.rank) return 0;
      return a.rank > b.rank ? 1 : -1;
    });
    var lastreplacedIdx = rows.length;
    for (var i = 0, N = rows.length; i < N; i++) {
      if (i < info!.maxPositions && rows[i].costituent === 0) {
        rows[i].rankchangedByCapping = -1;
      } else if (i > info!.maxPositions && rows[i].costituent === 1) {
        rows[i].rankchangedByCapping = 1;
        lastreplacedIdx = i;
      }
    }
    if (lastreplacedIdx !== rows.length) {
      for (let i = 0, N = rows.length; i < N; i++) {
        if (
          i >= info!.maxPositions &&
          i < lastreplacedIdx &&
          rows[i].costituent === 0
        ) {
          rows[i].rankchangedByCapping = 2;
        }
      }
    }
    var existsIsColumn = false;
    for (i = 0, N = fields.length; i < N; i++) {
      if (fields[i] === "exists") {
        existsIsColumn = true;
        break;
      }
    }
    if (existsIsColumn) {
      // se c'e' l'exists
      var exanteMap = {};
      for (let i = 0, N = this.exAnteSecurities.length; i < N; i++) {
        exanteMap[this.exAnteSecurities[i]] = true;
      }
      for (let i = 0, N = rows.length; i < N; i++) {
        symbol = rows[i].symbol;
        if (exanteMap[symbol] !== undefined) {
          rows[i].exists = 1;
        }
      }
    }

    this.data!.ranking.rows = rows;

    //-------------- WEIGHTING
    replyFetch = await this.API.instruments.fetch(
      {
        type: "cube",
        properties: fieldsMapping
          .fieldsToFetchFields(this.info!.weighting.fieldsToBeFetched)
          .map((p) => ({
            date: null,
            property: p,
          })),
        symbols: this.info!.weighting.symbols,
        multiDates: [this.date],
      },
      true
    );

    rows = [];

    let columnBeforeCappingIsRequired = false;
    data = this.trasparency;
    // let transparencyFieldIdx = this.info!.transparencyFieldIdx;
    for (symbol in replyFetch) {
      rowFetch = replyFetch[symbol][this.date];
      row = fieldsMapping.wrapFetchRowToRow(rowFetch, "Weighting");
      row.symbol = symbol;
      trasparencyIdx = this.info!.transparencyRowIndexBySymbol[symbol];
      trasparencyRow = data.rows[trasparencyIdx];
      let idx;
      for (let record of this.info!.weighting.fields) {
        idx = fieldsMapping.getIdx(record.field, record.type, record.section);
        if (idx !== null) {
          row[record.key] = trasparencyRow[idx];
        }
      }
      if (!areFloatsEqual(row.Weight, row.beforeCapWeight)) {
        columnBeforeCappingIsRequired = true; // ne basta uno diverso che metto la colonna
      }
      rows.push(row);
    }
    // se tutti i pesi finali sono uguali al beso before capping nascondo la colonna before capping
    if (columnBeforeCappingIsRequired === false) {
      for (i = 0, N = this.info!.weighting.fields.length; i < N; i++) {
        if (this.info!.weighting.fields[i].field === "beforeCapWeight") {
          this.info!.weighting.fields.splice(i, 1); // remove
          break;
        }
      }
    }
    this.data!.weighting.rows = rows;

    // SUMMARY
    var afterCappingIdx = fieldsMapping.getIdx("Weight");
    var beforeCappingIdx = fieldsMapping.getIdx("beforeCapWeight");
    var r;
    var before;
    var after;
    this.data!.summary.positions = [];
    for (i = 0, N = this.trasparency.rows.length; i < N; i++) {
      r = this.trasparency.rows[i];
      before = r[beforeCappingIdx];
      after = r[afterCappingIdx];
      symbol = this.trasparency.symbols[i];
      if (before !== null || after !== null) {
        if (before === null) before = 0;
        if (after === null) after = 0;
        this.data!.summary.positions.push({
          symbol: symbol,
          before: before,
          after: after,
          ticker: this.securitiesMap[symbol].ticker,
          name: this.securitiesMap[symbol].name,
        });
      }
    }
    var strategy = this.strategy.params.strategy;
    if (strategy.cappingRules.capValue !== undefined) {
      this.data!.summary.limits.positions.max = strategy.cappingRules.capValue;
    }
    if (
      strategy.cappingRules.minValue !== undefined &&
      strategy.cappingRules.minValue !== 0.01
    ) {
      // 0.01e' il default, non lo plotto
      this.data!.summary.limits.positions.min = strategy.cappingRules.minValue;
    }
    if (strategy.cappingRules.rotation !== undefined) {
      r = strategy.cappingRules.rotation;
      let countryLevel = r.countryLevel;
      let sectorLevel = r.sectorLevel;
      if (r.capValue !== undefined) {
        this.data!.summary.limits.cluster.max = r.capValue;
        this.data!.summary.limits.cluster.countryLevel = countryLevel;
        this.data!.summary.limits.cluster.sectorLevel = sectorLevel;
        this.data!.summary.limits.cluster.type = this.info!.type;
      }
    }
  }
  //---------------------------------------------------------------------
  private async warnings() {
    let warnings: string[] = [];
    const epsilon = 0.0001;
    let maxPositions =
      this.strategy.params.strategy.selectionRules.maxPositions;
    let strategyMinAllocation =
      this.strategy.params.strategy.cappingRules.minAllocation == null
        ? 0.0
        : this.strategy.params.strategy.cappingRules.minAllocation;
    let strategyMaxAllocation =
      this.strategy.params.strategy.cappingRules.maxAllocation == null
        ? 1.0
        : this.strategy.params.strategy.cappingRules.maxAllocation;
    var strategyMaxPositionCapping =
      this.strategy.params.strategy.cappingRules.capValue == null
        ? 1.0
        : this.strategy.params.strategy.cappingRules.capValue;
    var strategyMinPositionCapping =
      this.strategy.params.strategy.cappingRules.minValue == null
        ? 0.0
        : this.strategy.params.strategy.cappingRules.minValue;

    var type = "stock";
    var universe = this.strategy.params.universe.search;
    let filter: any = null;

    if (universe.filters !== undefined) {
      for (let i = 0, N = universe.filters.length; i < N; i++) {
        filter = universe.filters[i];

        if (filter["dimension"] === "type") {
          type = filter["segments"][0];
        }
      }
    }

    let checkClusterCapping = false;
    let clusterCappingMax = 0;
    let clusterCappingDimension;
    let clusterCappingLevel;
    let clusterCappingLabel;

    if (this.strategy.params.strategy.cappingRules.rotation !== undefined) {
      var r = this.strategy.params.strategy.cappingRules.rotation;
      if (r.capValue !== undefined) {
        checkClusterCapping = true;
        clusterCappingMax = r.capValue;
        if (r.countryLevel !== null) {
          clusterCappingDimension = type === "ETF" ? "etfgeo" : "country";
          clusterCappingLevel = r.countryLevel;
          clusterCappingLabel = clusterCappingLevel;
          clusterCappingLabel =
            clusterCappingLevel === "country" ? "market" : "region";
        } else {
          clusterCappingDimension = type === "ETF" ? "etfclass" : "icb";
          clusterCappingLevel = r.sectorLevel;
          clusterCappingLabel =
            clusterCappingLevel === "sector" ? "industry" : "sector";
        }
      }
    }
    let allocation = 0.0;
    let positions = 0;
    for (let idx in this.portfolio) {
      allocation += this.portfolio[idx].A;
      positions++;
    }
    // I) underallacated
    if (allocation < strategyMinAllocation - epsilon) {
      warnings.push("Underallocated");
    }

    // I) overallacated
    if (allocation > strategyMaxAllocation + epsilon) {
      warnings.push("Overallocated");
    }

    // II) less positions
    if (positions < maxPositions) {
      warnings.push("The portfolio contains " + positions + " positions");
    }

    // III position capping violated
    for (var idx in this.portfolio) {
      if (
        this.portfolio[idx].A < strategyMinPositionCapping - epsilon ||
        this.portfolio[idx].A > strategyMaxPositionCapping + epsilon
      ) {
        warnings.push(
          "There are positions that violate the capping limits required by the strategy"
        );
        break;
      }
    }
    /// IV sector/market capping violated
    if (checkClusterCapping) {
      const universe = this.portfolio.map((r) => ({
        symbol: r.S,
        weight: r.A,
      }));

      const clusterConfig = this.API.clusters
        .createConfiguration()
        .analytics(["weight"])
        .clusters([
          {
            dimension: clusterCappingDimension,
            transform: {
              function: "taxonomy",
              params: { level: clusterCappingLevel },
            },
          },
        ])
        .method("INTERSECTION")
        .universeFromPositions(universe);

      const reply = await clusterConfig.fetchAnalytics();
      var data = reply.clustersStats.stats;
      for (var cluster in data) {
        if (data[cluster].weight > clusterCappingMax + epsilon) {
          warnings.push(
            "the capping constraint on a single " +
              clusterCappingLabel +
              " cannot be satisfied"
          );
          break;
        }
      }
    }

    this.issues = warnings;
  }
  //----------------------------------------------------------------------------------------------
  async get(topic) {
    switch (topic) {
      case "warnings":
        await this.warnings();

        return this.issues;
      case "universe":
        return {
          fields: this.info!.universe.fields,
          overwrites: this.info!.universe.columnsOverwrites,
          rows: this.data!.universe.rows,
        };
      case "summary":
        return {
          funnel: this.data!.summary.funnel,
          date: this.date,
          positions: this.data!.summary.positions,
          limits: this.data!.summary.limits,
        };
      case "selection":
        return {
          fields: this.info!.selection.fields,
          overwrites: this.info!.selection.columnsOverwrites,
          rows: this.data!.selection.rows,
        };
      case "ranking":
        return {
          fields: this.info!.ranking.fields,
          overwrites: this.info!.ranking.columnsOverwrites,
          rows: this.data!.ranking.rows,
        };
      case "weighting":
        return {
          fields: this.info!.weighting.fields,
          overwrites: this.info!.weighting.columnsOverwrites,
          rows: this.data!.weighting.rows,
        };
      case "trasparency": {
        return deepClone(this.trasparency);
      }
      case "strategy": {
        return deepClone(this.strategy);
      }
    }
  }

  private initStruct() {
    this.info = {
      type: "stock",
      maxPositions: 0,
      transparencyRowIndexBySymbol: {},
      transparencyFieldIdx: undefined,
      candidateIdx: 0,
      costituentIdx: 0,
      weightIdx: 0,
      rankIdx: 0,
      universe: {
        screening: { sort: null, page: null, constraints: [] },
        fields: [],
        columnsOverwrites: {},
      },
      selection: {
        fields: [],
        columnsOverwrites: {},
      },
      ranking: {
        fields: [],
        columnsOverwrites: {},
      },
      weighting: {
        fieldsToBeFetched: [],
        fields: [],
        columnsOverwrites: {},
        symbols: [],
      },
    };
    this.data = {
      universe: { fields: [], columnsOverwrites: {}, rows: [] },
      selection: { fields: [], columnsOverwrites: {}, rows: [] },
      ranking: { fields: [], columnsOverwrites: {}, rows: [] },
      weighting: { fields: [], columnsOverwrites: {}, rows: [] },
      summary: {
        funnel: {
          universeDomain: {
            idx: -2,
            label: "Markets & Sectors",
            colorTag: "universe",
            N: 0,
            type: "securities",
          },
          universe: {
            idx: -1,
            label: "Investment Universe",
            colorTag: "investmentUniverse",
            N: 0,
            type: "securities",
          },
          entitled: {
            idx: null,
            label: "Entitled",
            colorTag: "entitled",
            N: 0,
            type: "securities",
          },
          candidates: {
            idx: 1002,
            label: "Consitutents<br/>Before Capping",
            colorTag: "candidate",
            N: 0,
            type: "securities",
          },
          costituents: {
            idx: 1003,
            label: "Costituents",
            colorTag: "constituents",
            N: 0,
            type: "securities",
          },
        },
        positions: [],
        limits: {
          positions: { max: null, min: null },
          cluster: {
            max: null,
            countryLevel: null,
            sectorLevel: null,
            type: "stock",
          },
        },
      },
    };
  }
}
