import { useRef, useState } from "react";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { Dropdown } from "../Dropdown/Dropdown";
import styles from "./InputSelect.module.scss";

const { input__select } = styles;

type InputSelectProps = {
  value: any;
  setValue: (value) => void;
  options: { label: string; value: string }[];
  offset?: number;
};

type OptionProps = {
  label: string;
  onClickHandler: InputSelectProps["setValue"];
};

export default function InputSelect({
  value,
  setValue,
  options,
  offset = 10,
}: InputSelectProps) {
  const attachPointNode = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleOutsideClick = (e) => {
    if (
      !attachPointNode?.current?.contains(e.target) &&
      !menuRef?.current?.contains(e.target)
    ) {
      setShowMenu(false);
    }
  };

  useDetectOutsideClick(handleOutsideClick);

  const onClickListItem = (item) => {
    setValue(item);
    setShowMenu(false);
  };

  return (
    <div>
      <h1
        className={input__select}
        onClick={() => setShowMenu(!showMenu)}
        ref={attachPointNode}
      >
        {value?.label ?? ""}{" "}
        <span className="tFormSelect-icon i-down-lite"></span>
      </h1>
      {showMenu && (
        <Dropdown
          targetRef={attachPointNode}
          ref={menuRef}
          offset={offset}
          customStyle={{ minWidth: 150 }}
        >
          {options.map((item, index) => (
            <Option
              key={`${index}__${item.label}`}
              label={item.label}
              onClickHandler={() => onClickListItem(item)}
            />
          ))}
        </Dropdown>
      )}
    </div>
  );
}

function Option({ label, onClickHandler }: OptionProps) {
  return <li onClick={onClickHandler}>{label}</li>;
}
