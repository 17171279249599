import { useCallback, useMemo, useState } from "react";
import { deepClone } from "../../../../../deepClone";
import Modal from "../../../../../components/Modal/Modal";
import { Box, Button, Typography } from "@mui/material";
import { AppEnvironment } from "../../../../../types/Defaults";
import { Lists } from "../../../../../api/compute/Lists";
import { messageError, messageSuccess } from "../../../utils";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { Users } from "../../../../../api/account/Users";
import { TValidator } from "../../../utils/Validator";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useNavigate } from "react-router-dom";
import { DialogSaveComponent } from "../../../ui/commons/DialogSave/DialogSaveComponent";

type ApplySwapProps = {
  label: string;
  target: { list: any; listOptimized: any };
};

export function ApplySwap({ label, target }: ApplySwapProps) {
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);

  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const isSaveActionEnabled = useMemo(() => {
    var listOptimized = deepClone(target.listOptimized);

    return !listOptimized.isReadOnly && listOptimized.id != null;
  }, [target.listOptimized]);

  const navigate = useNavigate();

  const closeWarningDialog = useCallback(() => setShowWarningDialog(false), []);
  const closeSaveDialog = useCallback(() => setShowSaveDialog(false), []);

  const { broadcast } = useBroadcast();

  const broadcastError = useCallback(
    (text) => {
      const [channel, message] = messageError(text);

      broadcast(channel as string, message);
    },
    [broadcast]
  );

  const broadcastSuccess = useCallback(
    (text) => {
      const [channel, message] = messageSuccess(text);

      broadcast(channel as string, message);
    },
    [broadcast]
  );

  const onCreateFinish = useCallback(
    (value) => {
      if (value != null) {
        let url = "/app/analysis/lists/";
        if (value?.id != null) {
          url += `${value.id}/analyze/overview`;
        }
        navigate(url);
        window.location.reload();
      } else {
        window.location.reload();
      }
    },
    [navigate]
  );

  const collectionSaveAPI = useMemo(
    () =>
      new CollectionSave(
        setup,
        broadcastError,
        broadcastSuccess,
        onCreateFinish
      ),
    [broadcastError, broadcastSuccess, onCreateFinish, setup]
  );

  const onSaveAs = useCallback(
    (inputValue) => {
      var listOptimized = deepClone(target.listOptimized);

      const item = listOptimized;
      const onSuccess = (data, action) => {
        collectionSaveAPI.successHandler(data);
      };

      collectionSaveAPI.saveAs(item, closeSaveDialog, inputValue, onSuccess);
    },
    [closeSaveDialog, collectionSaveAPI, target.listOptimized]
  );

  const save = useCallback(() => {
    var listOptimized = deepClone(target.listOptimized);

    if (isSaveActionEnabled) {
      const item = listOptimized;
      collectionSaveAPI.save(item, closeSaveDialog);

      collectionSaveAPI.successHandler(item);
    }
  }, [
    closeSaveDialog,
    collectionSaveAPI,
    isSaveActionEnabled,
    target.listOptimized,
  ]);

  const doAction = useCallback(() => {
    var listOptimized = deepClone(target.listOptimized);

    var listOptimizedWeight = 0;
    for (var i = 0, length = listOptimized.positions.length; i < length; i++) {
      listOptimizedWeight =
        listOptimizedWeight + listOptimized.positions[i].weight;
    }

    if (listOptimizedWeight > 0) {
      setShowSaveDialog(true);
    } else {
      setShowWarningDialog(true);
    }
  }, [target.listOptimized]);

  return (
    <>
      {showSaveDialog && (
        <DialogSaveComponent
          item={target.listOptimized}
          dialogType={"optimized portfolio"}
          onSave={isSaveActionEnabled ? save : null}
          onSaveAs={onSaveAs}
          onRename={null}
          hide={closeSaveDialog}
        />
      )}
      <WarningDialog show={showWarningDialog} onClose={closeWarningDialog} />
      <li className="menu__item" onClick={doAction}>
        {label}
      </li>
    </>
  );
}

const WarningDialog = ({ show, onClose }) => {
  return show ? (
    <Modal
      headerConfig={{ headerContent: "Warning", hasBackground: true }}
      closeIcon={false}
    >
      <Box p={1}>
        <Typography>
          <strong>The portfolio has weight equal to 0</strong>.<br />
          Please, set weights for positions.
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button variant="tr_button_cancel" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  ) : (
    <></>
  );
};

class CollectionSave {
  environment: AppEnvironment;
  listsAPI: Lists;
  userAPI: Users;
  broadcastError: (error) => void;
  broadcastSucces: (msg) => void;
  onPortfolioCreated: (item) => void;

  constructor(
    environment: AppEnvironment,
    broadcastError: (error) => void,
    broadcastSucces: (msg) => void,
    onSuccess: (item) => void
  ) {
    this.environment = environment;
    this.listsAPI = new Lists(environment);
    this.userAPI = new Users(environment);
    this.broadcastError = broadcastError;
    this.broadcastSucces = broadcastSucces;
    this.onPortfolioCreated = onSuccess;
  }

  errorHandler(error) {
    if (error) {
      console.error(error);
      this.broadcastError("A portfolio with this name already exists.");
    }
  }

  successHandler(item) {
    const message =
      "The " +
      item.type.toLowerCase() +
      " <strong>" +
      item.name +
      "</strong> has been saved.";

    this.broadcastSucces(message);

    //************************ USAGE ***************************
    var usage = window.App.usage;
    var info = {
      action: "SAVE",
      actionParam: JSON.stringify(item),
      function: "PORTFOLIO_EDIT",
    };
    usage.record(info);
    //************************ USAGE ***************************

    this.onPortfolioCreated(item);
  }

  prepareColletion(collectionRaw) {
    var list = this.listsAPI.getPrototype();
    list.id = collectionRaw.id ? collectionRaw.id : null;
    list.name = collectionRaw.name;
    list.positions = collectionRaw.positions;
    list.positionsExpired = collectionRaw.positionsExpired;
    list.positionsUnavailable = collectionRaw.positionsUnavailable;
    list.type = collectionRaw.type;
    list.weightsManagement = collectionRaw.weightsManagement;

    if (collectionRaw["benchmark"] != null) {
      if (typeof collectionRaw["benchmark"] === "string") {
        list.benchmark = collectionRaw["benchmark"];
      } else {
        list.benchmark = collectionRaw["benchmark"]["symbol"];
      }
    }

    return list;
  }

  async save(item, closeDialog) {
    var list = this.prepareColletion(item);
    const response = await this.listsAPI.update(list);

    if (response.status === "OK") {
      this.successHandler(list);
      closeDialog();
    } else {
      this.errorHandler(response);

      console.error(
        "\n",
        "-----------------------------------------------------",
        "\n",
        "Error saving portfolio",
        "\n",
        "-----------------------------------------------------",
        "\n",
        JSON.stringify(list),
        "\n\n",
        "-----------------------------------------------------"
      );

      var message = [
        "---------------------------------------------------",
        "             Auto generated message                ",
        "---------------------------------------------------",
        "Account ID: " + window.App.user.id,
        "Client    : " + window.App.user.client,
        "First name: " + window.App.user.firstName,
        "Last name : " + window.App.user.lastName,
        "Email     : " + window.App.user.email,
        "Type      : " + window.App.user.group,
        "Product   : " + window.App.user.product.codeName,
        "---------------------------------------------------",
        "Version   : " + window.App.system.version,
        "Node      : " + window.App.system.node,
        "---------------------------------------------------",
        "                                                   ",
        "Error saving portfolio                             ",
        "                                                   ",
        "---------------------------------------------------",
      ];

      var params = {
        fromName: window.App.user.firstName + " " + window.App.user.lastName,
        from: window.App.user.email,
        message: message.join("\n") + "\n\n" + JSON.stringify(list) + "\n\n",
      };

      const res = await this.userAPI.askSupport(params);

      if (res.status === "OK") {
        this.broadcastSucces("Support request sent successfully");
      } else {
        this.broadcastError("Error sending support request");
      }
    }
  }

  async saveAs(data, closeDialog, inputValue, success) {
    var item = deepClone(data);
    item.name = inputValue;

    item.id = null;
    var list = this.prepareColletion(item);

    const response = await this.listsAPI.create(list);
    if (response.status === "OK") {
      list = await this.listsAPI.get(response.data.id);
      success(list, "SAVE_AS");
      closeDialog();
    } else {
      this.errorHandler(response);
    }
  }

  async rename(item, closeDialog, inputValue, feedbackNode) {
    var list = this.prepareColletion(item);

    const validationResults = TValidator._name(inputValue);
    if (validationResults.isValid) {
      if (item.name !== inputValue) {
        list.name = inputValue.trim();
        const response = await this.listsAPI.update(list);
        if (response.status === "OK") {
          this.successHandler(list);
          closeDialog();
        } else {
          this.broadcastError(
            [
              "Error saving the ",
              list.type.toLowerCase(),
              " <strong>",
              list.name,
              "</strong>.",
            ].join("")
          );

          console.error(
            "\n",
            "-----------------------------------------------------",
            "\n",
            "Error saving portfolio",
            "\n",
            "-----------------------------------------------------",
            "\n",
            JSON.stringify(list),
            "\n\n",
            "-----------------------------------------------------"
          );

          var message = [
            "---------------------------------------------------",
            "             Auto generated message                ",
            "---------------------------------------------------",
            "Account ID: " + window.App.user.id,
            "Client    : " + window.App.user.client,
            "First name: " + window.App.user.firstName,
            "Last name : " + window.App.user.lastName,
            "Email     : " + window.App.user.email,
            "Type      : " + window.App.user.group,
            "Product   : " + window.App.user.product.codeName,
            "---------------------------------------------------",
            "Version   : " + window.App.system.version,
            "Node      : " + window.App.system.node,
            "---------------------------------------------------",
            "                                                   ",
            "Error saving portfolio                             ",
            "                                                   ",
            "---------------------------------------------------",
          ];

          var params = {
            fromName:
              window.App.user.firstName + " " + window.App.user.lastName,
            from: window.App.user.email,
            message:
              message.join("\n") + "\n\n" + JSON.stringify(list) + "\n\n",
          };

          await this.userAPI.askSupport(params);

          if (response.status === "OK") {
            console.log("Support request sent successfully");
          } else {
            console.error("Error sending support request");
          }
        }
      } else {
        var type = item.type.toLowerCase();
        var name = item.name;

        this.broadcastError(
          "A " + type + " called " + name + " already exists."
        );
      }
    } else {
      this.broadcastError(
        "Please, type a valid name. It must contains at least 3 characters."
      );
    }
  }
}
