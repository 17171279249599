import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { handleTitle } from "../../../../../Utility/DocumentTitleHanlder";
import { Lists } from "../../../../../api/compute/Lists";
import { PanelForLists } from "../../../../../components/PanelForLists/PanelForLists";
import { TaxonomySelect } from "../../../../../components/TaxonomySelect/TaxonomySelect";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { config } from "../../../config-ts";
import { RankUtilities } from "../../../pages/rank/RankUtilities";
import { removeLoader } from "../../../utils";
import alerts_example from "../reportsHub/reportsHub_svg/alerts_example.png";
import basket_example from "../reportsHub/reportsHub_svg/baskets_example.png";
import markets_example from "../reportsHub/reportsHub_svg/markets-example.png";
import portfolios_example from "../reportsHub/reportsHub_svg/portfolios_example.png";
import ranking_example from "../reportsHub/reportsHub_svg/rank_example.png";
import { ReportRankDialog } from "./ReportRankDialog";
import styles from "./ReportsHub.module.scss";
import alerts_icon from "./reportsHub_svg/alerts.svg";
import america_region from "./reportsHub_svg/america_region.svg";
import asia_icon from "./reportsHub_svg/arrow_barchart_2.svg";
import america_icon from "./reportsHub_svg/arrow_chart_up.svg";
import asia_region from "./reportsHub_svg/asia_region.svg";
import europe_icon from "./reportsHub_svg/barchart.svg";
import baskets_icon from "./reportsHub_svg/baskets.svg";
import europe_region from "./reportsHub_svg/europe_region.svg";
import portfolios_icon from "./reportsHub_svg/portfolio.svg";
import ranking_icon from "./reportsHub_svg/ranking.svg";
import singleMarket_alternative from "./reportsHub_svg/singleMarket_alternative.svg";
import market_icon from "./reportsHub_svg/single_market.svg";
import Modal from "../../../../../components/Modal/Modal";
import { useNavigate } from "react-router-dom";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

const tableRows = [
  [
    {
      label: "Markets",
      className:
        styles.reportsHubMain__content__card__content__table__row__cell__title,
      helpSubject: "empty",
    },
  ],
  [
    { label: "North America", helpSubject: "US" },
    {
      label: "",
      value: "R_D_A__microLarge__size",
      fileName: "US All Cap.",
      size: "All Cap",
      icon: "america",
      icon2: "americaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_A__large__sector",
      size: "Large",
      fileName: "US Large Cap.",
      icon: "america",
      icon2: "americaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_A__mid__sector",
      size: "Mid",
      fileName: "US Mid Cap.",
      icon: "america",
      icon2: "americaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_A__small__sector",
      size: "Small",
      fileName: "US Small Cap.",
      icon: "america",
      icon2: "americaRegion",
      example: "markets",
    },
  ],
  [
    { label: "Europe", helpSubject: "EU" },
    {
      label: "",
      value: "R_D_E__microLarge__sector",
      fileName: "All Cap.",
      size: "All Cap",
      icon: "europe",
      icon2: "europeRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_E__large__sector",
      fileName: "EU Large Cap.",
      size: "Large",
      icon: "europe",
      icon2: "europeRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_E__mid__sector",
      size: "Mid",
      fileName: "EU Mid Cap.",
      icon: "europe",
      icon2: "europeRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_E__small__sector",
      size: "Small",
      fileName: "EU Small Cap.",
      icon: "europe",
      icon2: "europeRegion",
      example: "markets",
    },
  ],
  [
    { label: "Asia", helpSubject: "ASIA" },
    {
      label: "",
      value: "R_D_P__microLarge__size",
      fileName: "All Cap.",
      size: "All Cap",
      icon: "asia",
      icon2: "asiaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_P__large__sector",
      fileName: "Large Cap.",
      size: "Large",
      icon: "asia",
      icon2: "asiaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_P__mid__sector",
      size: "Mid",
      fileName: "Mid Cap.",
      icon: "asia",
      icon2: "asiaRegion",
      example: "markets",
    },
    {
      label: "",
      value: "R_D_P__small__sector",
      size: "Small",
      fileName: "Small Cap.",
      icon: "asia",
      icon2: "asiaRegion",
      example: "markets",
    },
  ],
  [
    { label: "Single Market", helpSubject: "ASIA" },
    {
      label: "",
      value: "country__microLarge__markets",
      fileName: "All Cap.",
      size: "All Cap",
      icon: "singleMarket",
      icon2: "singleMarketAlternative",
      example: "markets",
    },
    {
      label: "",
      value: "country__large__markets",
      fileName: "Large Cap.",
      size: "Large",
      icon: "singleMarket",
      icon2: "singleMarketAlternative",
      example: "markets",
    },
    {
      label: "",
      value: "country__mid__markets",
      size: "Mid",
      fileName: "Mid Cap.",
      icon: "singleMarket",
      icon2: "singleMarketAlternative",
      example: "markets",
    },
    {
      label: "",
      value: "country__small__markets",
      size: "Small",
      fileName: "Small Cap.",
      icon: "singleMarket",
      icon2: "singleMarketAlternative",
      example: "markets",
    },
  ],
];

const iconsRepository = {
  america: america_icon,
  asia: asia_icon,
  asiaRegion: asia_region,
  americaRegion: america_region,
  europeRegion: europe_region,
  singleMarketAlternative: singleMarket_alternative,
  europe: europe_icon,
  singleMarket: market_icon,
  baskets: portfolios_icon,
  portfolios: baskets_icon,
  alerts: alerts_icon,
  ranking: ranking_icon,
  rankingAlternative: baskets_icon,
  basketAlternative: asia_icon,
};

const tooltipImagesRepo = {
  markets: markets_example,
  portfolios: portfolios_example,
  baskets: basket_example,
  alerts: alerts_example,
  ranking: ranking_example,
};

const collectionLabelDict = {
  Portfolios: "portfolio",
  Baskets: "basket",
  Rankings: "rank",
};

export function ReportsHub({ icon2 = false }: { icon2?: boolean }) {
  const environment = useEnvironment();
  const [collectionDialogConfig, setCollectionDialogConfig] = useState<{
    show: boolean;
    collection: { name; type: string; id: number; isSubscribed: boolean }[];
    title: string;
    collectionTag: "Portfolios" | "Baskets" | "Rankings" | "";
  }>({ show: false, collection: [], title: "", collectionTag: "" });
  const [portfolios, setPortfolios] = useState([]);
  const [baskets, setbaskets] = useState([]);

  const [countrySize, setCountrySize] = useState("large");
  const [showRankDialog, setShowRankDialog] = useState(false);
  const [showEmptyDialog, setShowEmptyDialog] = useState(false);

  const navigate = useNavigate();
  const { broadcast } = useBroadcast();

  const portfolioRowsConfiguration = useMemo(() => {
    return [
      {
        title: "Portfolios",
        rows: [
          {
            label: "",
            value: "portfolios",
            fileName: "",
            size: "",
            icon: "portfolios",
            icon2: "portfolios",
            example: "portfolios",
          },
        ],
      },
      {
        title: "Baskets",
        rows: [
          {
            label: "",
            value: "basket",
            fileName: "",
            size: "",
            icon: "baskets",
            icon2: "basketAlternative",
            example: "baskets",
          },
        ],
      },
      {
        title: "Alerts",
        rows: [
          {
            label: "",
            value: "alerts",
            fileName: "",
            size: "Today",
            icon: "alerts",
            icon2: "alerts",
            example: "alerts",
          },
          {
            label: "",
            value: "alerts_w",
            fileName: "",
            size: "Last Week",
            icon: "alerts",
            icon2: "alerts",

            example: "alerts",
          },
          {
            label: "",
            value: "alerts_m",
            fileName: "",
            size: "Last Month",
            icon: "alerts",
            icon2: "alerts",
            example: "alerts",
          },
        ],
      },
      {
        title: "Rankings",
        rows: [
          {
            label: "",
            value: "rank",
            fileName: "",
            size: "",
            icon: "ranking",
            icon2: "rankingAlternative",
            example: "ranking",
          },
        ],
      },
    ];
  }, []);

  const showCollectionDialog = useMemo(
    () => collectionDialogConfig.show,
    [collectionDialogConfig.show]
  );

  const collections = useMemo(
    () => collectionDialogConfig.collection,
    [collectionDialogConfig.collection]
  );

  const collectionDialogTitle = useMemo(
    () => collectionDialogConfig.title,
    [collectionDialogConfig.title]
  );

  const collectionTag = useMemo(
    () => collectionDialogConfig.collectionTag,
    [collectionDialogConfig.collectionTag]
  );

  const taxonomyPanelRef = useRef<any>(null);

  const closeCollectionDialog = useCallback(() => {
    setCollectionDialogConfig({
      show: false,
      collection: [],
      title: "",
      collectionTag: "",
    });
  }, []);

  const apiLists = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );

  const rankUtils = useMemo(
    () => new RankUtilities(environment.get("setup")),
    [environment]
  );

  useEffect(() => {
    handleTitle({ page: "REPORTS_HUB" });
    removeLoader();
  }, []);

  const openCountyChoice = useCallback(() => {
    if (taxonomyPanelRef.current) {
      taxonomyPanelRef.current.showTreePanel(true);
    }
  }, []);

  const hasToPrintDirectly = useCallback((cellInfo) => {
    const isAlerts = cellInfo.value.startsWith("alerts");
    const isPeerGroup =
      cellInfo.value !== "portfolios" &&
      cellInfo.value !== "basket" &&
      cellInfo.value !== "rank" &&
      !isAlerts;

    let isMacroPeerGroup = true;

    if (isPeerGroup) {
      const [, , type] = cellInfo.value.split("__");

      if (type === "markets") {
        isMacroPeerGroup = false;
      }
    }

    return (isPeerGroup && isMacroPeerGroup) || isAlerts;
  }, []);

  const getLists = useCallback(
    async (type: "portfolio" | "basket") => {
      const listType = type.toUpperCase() as "BASKET" | "PORTFOLIO";
      const userCollectionIds = await apiLists._getFilter(listType);
      const subscribed = await apiLists._getSubscribed();

      const listIds = [
        ...userCollectionIds,
        ...subscribed.map((pub) => pub.id),
      ];
      const response = await apiLists.portfolioFetch(listIds, [
        "name",
        "ownerId",
        "type",
      ]);

      const userId = environment.get("setup")["account"]["user"]["id"];

      const collection: any = [];

      for (const element of response) {
        if (element.type === type.toUpperCase()) {
          collection.push({
            name: element.name,
            type: element.type === "PORTFOLIO" ? "Portfolios" : "Baskets",
            id: element.id,
            isSubscribed: element.ownerId !== userId,
          });
        }
      }

      return collection;
    },
    [apiLists, environment]
  );

  const handleReportPrint = useCallback(
    async (cellInfo) => {
      const printDirect = hasToPrintDirectly(cellInfo);

      if (printDirect) {
        window.open(
          `/app/pdf/market/${cellInfo.value}`,
          "_blank",
          "rel=noopener noreferrer"
        );
      } else {
        switch (cellInfo.value) {
          case "portfolios":
            if (portfolios.length === 0) {
              setShowEmptyDialog(true);
            }

            setCollectionDialogConfig({
              title: "",
              collection: portfolios,
              collectionTag: "Portfolios",
              show: true,
            });

            break;

          case "basket":
            if (baskets.length === 0) {
              setShowEmptyDialog(true);
            }

            setCollectionDialogConfig({
              title: "",
              collection: baskets,
              collectionTag: "Baskets",
              show: true,
            });

            break;

          case "rank":
            setShowRankDialog(true);

            break;

          // Handle peer groups country level
          default: {
            const explodedId = cellInfo.value.split("__");
            const size = explodedId[1];
            setCountrySize(size);
            openCountyChoice();
          }
        }
      }
    },
    [baskets, hasToPrintDirectly, openCountyChoice, portfolios]
  );

  const closeEmptyDialog = useCallback(() => {
    setShowEmptyDialog(false);
  }, []);

  const printCollectionReport = useCallback(
    async (collectionId) => {
      const collectionTypeDict = {
        Portfolios: "portfolios",
        Baskets: "basket",
        Rankings: "rank",
      };

      const collectionType = collectionTypeDict?.[collectionTag];

      if (!collectionType) {
        console.warn("missing collection type, cannot print any report");
        return;
      }

      const cellInfo = {
        collectionId,
        value: collectionType,
      };

      closeCollectionDialog();
      window.open(
        `/app/pdf/collection/${cellInfo.value}/${cellInfo.collectionId}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    },
    [closeCollectionDialog, collectionTag]
  );

  const getRanks = useCallback(async () => {
    const userRanksIds = await rankUtils.getUserRanksIds();
    const subscribedRanks = await rankUtils.getSubscribedRanks();
    const rankIds = [...userRanksIds, ...subscribedRanks];

    const ranksInfo = await rankUtils.fetchRank(rankIds, [
      "name",
      "id",
      "ownerId",
    ]);

    if (!ranksInfo) {
      return [];
    }

    const userId = environment.get("account")["user"]["id"];

    const ranks: any = [];

    for (const element of ranksInfo) {
      if (element.name !== `LAST_USED_RANK_${userId}`) {
        ranks.push({
          name: element.name,
          type: "Rankings",
          id: element.id,
          isSubscribed: element.ownerId !== userId,
        });
      }
    }

    return ranks;
  }, [environment, rankUtils]);

  const printPdfCountry = useCallback(
    (value) => {
      const valueString = `${value}__${countrySize ?? "microLarge"}__markets`;
      const cellInfo = { value: valueString };

      window.open(
        `/app/pdf/market/${cellInfo.value}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    },
    [countrySize]
  );

  const clearWorkflow = useCallback(() => {
    var message = {
      from: "REPORTS_HUB",
      content: {
        actions: [],
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast]);

  const closeRankDialog = useCallback(() => setShowRankDialog(false), []);

  const goToCreate = useCallback(() => {
    let urls = {
      Rankings: "/app/rank",
      Portfolios: "/app/analysis/lists/create/portfolio/",
      Baskets: "/app/analysis/lists/create/basket/",
    };

    const url = urls[collectionTag];
    if (url) {
      navigate(url);
    }
  }, [collectionTag, navigate]);

  const onPageMount = useCallback(async () => {
    const portfolios = await getLists("portfolio");
    const baskets = await getLists("basket");

    setPortfolios(portfolios);
    setbaskets(baskets);

    clearWorkflow();
    handleTitle({ type: "REPORTS_HUB" });
  }, [clearWorkflow, getLists]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return (
    <>
      {showRankDialog ? (
        <ReportRankDialog
          closeModal={closeRankDialog}
          getLists={getLists}
          getRanks={getRanks}
        />
      ) : (
        <></>
      )}
      <TaxonomySelect
        ref={taxonomyPanelRef}
        taxonomyInfo={{
          type: "security",
          field: "country",
        }}
        panelTitle={"Select a market"}
        hasMultipleChoices={false}
        selectValue={printPdfCountry}
        extraTools={{
          size: {
            enabled: true,
            initState: countrySize,
            handleValueChange: setCountrySize,
          },
        }}
      >
        <></>
      </TaxonomySelect>
      {collections.length ? (
        <PanelForLists
          showDialog={showCollectionDialog}
          closeDialog={closeCollectionDialog}
          list={collections}
          sectionsTag={[collectionTag]}
          selectItem={printCollectionReport}
          headerTitle={collectionDialogTitle}
        />
      ) : (
        <>
          {showEmptyDialog && (
            <Modal closeOnClickAway onClose={closeEmptyDialog} closeIcon={true}>
              <Box
                padding={2}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
              >
                <Typography>
                  There aren't any {collectionLabelDict[collectionTag]} please
                  subscribe or create one
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Button onClick={goToCreate}>
                    Create a new {collectionLabelDict[collectionTag]}
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
        </>
      )}

      <Box className={styles.reportsHubMain}>
        <Box className={styles.reportsHubMain__content}>
          <Card className={styles.reportsHubMain__content__card}>
            <CardContent
              className={styles.reportsHubMain__content__card__content}
            >
              <PdfTable
                useAlternativeIcon={icon2}
                changeHelpSubject={() => {}}
                onCellClick={handleReportPrint}
                tableConfig={{
                  columns: [],
                  rows: tableRows,
                }}
              />
            </CardContent>
          </Card>
          <Box className={styles.reportsHubMain__content__cards__row}>
            {portfolioRowsConfiguration.map((element) => (
              <SingleChioceCard
                useAlternativeIcon={icon2}
                key={uuidv4()}
                printHandler={handleReportPrint}
                rows={element.rows}
                title={element.title}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

const SingleChioceCard = ({
  title,
  rows,
  printHandler,
  useAlternativeIcon,
}: {
  title: string;
  rows: any;
  useAlternativeIcon: boolean;
  printHandler: (cell) => void;
}) => {
  return (
    <Card className={styles.reportsHubMain__content__cards__row__card}>
      <CardContent
        className={styles.reportsHubMain__content__cards__row__card__content}
      >
        <Typography
          className={
            styles.reportsHubMain__content__cards__row__card__content__title
          }
        >
          {title}
        </Typography>
        <ul
          className={
            styles.reportsHubMain__content__cards__row__card__content__list
          }
        >
          {rows.map((row) => (
            <li
              key={uuidv4()}
              onClick={() => printHandler(row)}
              className={
                styles.reportsHubMain__content__cards__row__card__content__list__item
              }
            >
              <Box
                className={
                  styles.reportsHubMain__content__cards__row__card__content__list__item__content
                }
              >
                <Tooltip
                  tooltipIcon={false}
                  content={
                    <Box p={1}>
                      <img
                        alt={"pdf report example"}
                        src={tooltipImagesRepo[row.example]}
                        width={"100%"}
                      />
                    </Box>
                  }
                >
                  <img
                    className={styles.pdfImg}
                    alt="pdf-logo"
                    role="button"
                    src={
                      iconsRepository[useAlternativeIcon ? row.icon2 : row.icon]
                    }
                  />
                </Tooltip>
                {row?.size && (
                  <Typography sx={{ fontSize: "0.8vw" }}>{row.size}</Typography>
                )}
              </Box>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

const PdfTable = ({
  tableConfig,
  onCellClick,
  fullWidth = true,
  changeHelpSubject,
  useAlternativeIcon,
}: {
  tableConfig: { columns: any[]; rows: any[] };
  onCellClick: (value) => void;
  changeHelpSubject: (subject) => void;
  useAlternativeIcon: boolean;
  fullWidth?: boolean;
}) => {
  const calcColSpan = useCallback((row) => {
    const MAX_COLUMNS = 5;

    if (row.length < MAX_COLUMNS) {
      const emptyColumns = MAX_COLUMNS - row.length;
      return emptyColumns + 1;
    }

    return 1;
  }, []);

  const handleHelpTextCahnge = useCallback(
    (event) => {
      const tableRow = event.currentTarget;
      const firstCell = tableRow.firstChild;

      const subject = firstCell.dataset?.help_subject;

      if (subject) {
        changeHelpSubject(subject);
      }
    },
    [changeHelpSubject]
  );

  return (
    <table
      style={
        fullWidth != null && fullWidth === false
          ? {
              width: "auto",
            }
          : {}
      }
      className={styles.reportsHubMain__content__card__content__table}
    >
      <thead>
        {tableConfig.columns.map((row) => {
          return (
            <tr key={uuidv4()}>
              {row.map((col) => (
                <th key={uuidv4()} colSpan={col.colSpan ?? calcColSpan(row)}>
                  {col.label}
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {tableConfig.rows.map((row) => {
          return (
            <tr
              onMouseEnter={handleHelpTextCahnge}
              key={uuidv4()}
              className={
                styles.reportsHubMain__content__card__content__table__row
              }
            >
              {row.map((cell) => {
                if ("isCountryBtn" in cell) {
                  return (
                    <td
                      colSpan={cell?.colSpan ?? calcColSpan(row)}
                      onClick={() => onCellClick(cell)}
                      key={uuidv4()}
                      className={`${
                        styles.reportsHubMain__content__card__content__table__row__cell
                      } ${
                        styles.reportsHubMain__content__card__content__table__row__cell__pdf
                      } ${"className" in cell ? cell.className : ""}`}
                    >
                      <Button
                        variant={"contained"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <strong>{cell.label}</strong>
                        <OpenInNewIcon sx={{ fontSize: "0.8vw" }} />
                      </Button>
                    </td>
                  );
                } else if ("value" in cell) {
                  return (
                    <td
                      colSpan={cell?.colSpan ?? calcColSpan(row)}
                      onClick={() => onCellClick(cell)}
                      key={uuidv4()}
                      className={`${
                        styles.reportsHubMain__content__card__content__table__row__cell
                      } ${
                        styles.reportsHubMain__content__card__content__table__row__cell__pdf
                      } ${"className" in cell ? cell.className : ""}`}
                    >
                      <span>
                        <div className={styles.pdfIcon}>
                          <Tooltip
                            tooltipIcon={false}
                            content={
                              <Box p={1}>
                                <img
                                  alt={"pdf report example"}
                                  src={tooltipImagesRepo[cell.example]}
                                  width={"100%"}
                                />
                              </Box>
                            }
                          >
                            <img
                              className={styles.pdfImg}
                              alt="pdf-logo"
                              role="button"
                              src={
                                iconsRepository[
                                  useAlternativeIcon === true
                                    ? cell.icon2
                                    : cell.icon
                                ]
                              }
                            />
                          </Tooltip>
                          <Typography sx={{ fontSize: "0.8vw" }}>
                            {cell.size}
                          </Typography>
                        </div>
                      </span>
                    </td>
                  );
                } else {
                  return (
                    <td
                      data-help_subject={cell?.helpSubject}
                      colSpan={cell?.colSpan ?? calcColSpan(row)}
                      className={`${
                        styles.reportsHubMain__content__card__content__table__row__cell
                      } 
                      ${"className" in cell ? cell.className : ""}
                        `}
                      key={uuidv4()}
                      style={{ textAlign: "left", textTransform: "uppercase" }}
                    >
                      <strong>{cell.label}</strong>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
