/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportStrategy
 * @summary Manages strategy report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportStrategy extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_STRATEGY" as const;
}
