/* eslint-disable no-template-curly-in-string */
export const i18n = {
    "1_Day": "1 Day",
    "1_Month": "1 Month",
    "10_quantiles": "10 quantiles",
    "12_Months": "12 Months",
    "20_quantiles": "20 quantiles",
    "3_Months": "3 Months",
    "6_Months": "6 Months",
    "1_Week": "1 Week",
    "1_m": "1m",
    "1_w": "1w",
    "1M": "1M",
    "12M": "12M",
    "3M": "3M",
    "6M": "6M",
    // --------------------------------- A ---------------------------------
    AB_percentage: "AB%",
    All_cap: "All cap.",
    Apply: "Apply",
    Area_Region_Market: "Area / Region / Market",
    // --------------------------------- B ---------------------------------
    Bottom_10_percent: "Bottom <strong>10%</strong>",
    Bottom_25_percent: "Bottom <strong>25%</strong>",
    Bottom_5_percent: "Bottom <strong>5%</strong>",
    // --------------------------------- C ---------------------------------
    Cancel: "Cancel",
    Change: "Change",
    Current: "Current",
    cap: "cap.",
    // --------------------------------- D ---------------------------------
    Downgrades: "Downgrades",
    // --------------------------------- F ---------------------------------
    Focus_on: "Focus on",
    // --------------------------------- I ---------------------------------
    Industry: "Industry",
    // --------------------------------- M ---------------------------------
    Market: "Market",
    Mid_50_percent: "Mid <strong>50%</strong>",
    Mid_80_percent: "Mid <strong>80%</strong>",
    Mid_90_percent: "Mid <strong>90%</strong>",
    // --------------------------------- O ---------------------------------
    Overall_TCR: "Overall TCR&reg;",
    // --------------------------------- P ---------------------------------
    Performance: "Performance",
    // --------------------------------- P ---------------------------------
    Quartiles: "Quartiles",
    // --------------------------------- R ---------------------------------
    Rating_weights: "Rating weights",
    Ratio: "Ratio",
    Region: "Region",
    report_peer_dispersion_legend_$timeframe:
        "Average returns represent the dispersion of performance across the selected peer group over the last ${timeframe}. The greater the dispersion, the higher the potential to gain meaningful returns above the average return of the peer group. In periods of high dispersion, active managers have more opportunities to generate excess returns through positive stock selection.",
    report_peer_upgrades_downgrades_legend_$timeframe:
        "The up and down refer to the number of upgraded and downgraded securities over the last ${timeframe}.",
    // --------------------------------- S ---------------------------------
    Securities: "Securities",
    Sector: "Sector",
    Sector_Industry: "Sector / Industry",
    Show_details: "Show details",
    Show_industries: "Show industries",
    sortBy: "Choose column",
    // --------------------------------- T ---------------------------------
    TCR: "TCR",
    Timeframe: "Timeframe",
    Today: "Today",
    Top_10_percent: "Top <strong>10%</strong>",
    Top_25_percent: "Top <strong>25%</strong>",
    Top_5_percent: "Top <strong>5%</strong>",
    Top_Bottom: "Top / Bottom",
    // --------------------------------- U ---------------------------------
    Upgrades: "Upgrades",
};
