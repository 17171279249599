import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { ComparisonTableWidgetSelector } from "./ComparisonTableWidgetSelector";
import { ComparisonTableWidgetTable } from "./ComparisonTableWidgetTable";
import { PieChartWidget } from "./PieChartWidget";
import { SummaryTable } from "./SummaryTable";

export type Cluster =
  | "Country"
  | "1 Industry"
  | "3 Sector"
  | "Currency"
  | "etfgeo"
  | "AssetClass"
  | "Specialty"
  | "3 Level"
  | "Area"
  | "Region"
  | "Type";

type ComparisonTableWidgetProps = {
  clusterLevel: Cluster;
  fetchFilteredData: (filters, securities, setData) => void;
  name1: string;
  name2: string;
  setClusterLevel: (segment: Cluster) => void;
  summaryTableData: any;
  symbols: any;
  value: {
    pie: any;
    pie2: any;
    mainTableData: any;
  };
  positionsType:
    | "Sector"
    | "Currency"
    | "Commodity"
    | "Index"
    | "Stock"
    | "ETF"
    | "Instrument";
};

export function ComparisonTableWidget({
  clusterLevel,
  fetchFilteredData,
  name1,
  name2,
  setClusterLevel,
  summaryTableData,
  symbols,
  value,
  positionsType,
}: ComparisonTableWidgetProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [activeDrillDown, setActiveDrillDown] = useState(false);
  const [drillDownData, setDrillDownData] = useState(summaryTableData);
  const [pointerPosition, setPointerPosition] = useState<any>();
  const pointerElement = useRef<HTMLDivElement>(null);
  const pointerElementInsetBackground = useRef<HTMLDivElement>(null);
  const refTable = useRef<HTMLDivElement>(null);
  const refHidedTable = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveDrillDown(false);
  }, [value]);

  //Reset cluster level on change portfolio 1 or 2
  useEffect(() => {
    setClusterLevel(positionsType === "ETF" ? "etfgeo" : "Country");
  }, [name1, name2, positionsType, setClusterLevel]);

  useLayoutEffect(() => {
    const pointer = pointerElement.current;
    const pointerBackground = pointerElementInsetBackground.current;

    if (pointer != null && pointerBackground != null) {
      if (activeDrillDown && pointerPosition != null) {
        const table = refHidedTable.current;

        if (table != null) {
          const tableMeasures = table?.getBoundingClientRect();
          const tableDistanceFromTop = tableMeasures.top;
          const top = pointerPosition.y - tableDistanceFromTop;

          pointer.style.display = "block";
          pointerBackground.style.display = "block";
          pointer.style.left = "-15px";
          pointerBackground.style.left = "-14px";
          pointer.style.borderTop = `15px solid transparent`;
          pointer.style.borderBottom = `15px solid transparent`;
          pointer.style.borderRight = `15px solid #2a7092`;
          pointerBackground.style.borderTop = `14px solid transparent`;
          pointerBackground.style.borderBottom = `14px solid transparent`;
          pointerBackground.style.borderRight = `15px solid #fff`;
          pointer.style.top = `${top}px`;
          //Same position but + 1px to give a blue border to the arrow
          pointerBackground.style.top = `${top + 1}px`;
        }
      } else {
        pointer.style.display = "none";
        pointerBackground.style.display = "none";
      }
    }
  }, [activeDrillDown, pointerPosition]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setActiveDrillDown(false);
    setPointerPosition(null);
  };

  const decodeTitle = useMemo(() => {
    let title = "";

    switch (clusterLevel) {
      case "Country":
        title = "Markets";
        break;
      case "etfgeo":
        title = "Inv. Region";
        break;
      case "AssetClass":
        title = "Asset class";
        break;
      case "Specialty":
        title = "Specialty";
        break;
      case "1 Industry":
        title = "Sectors";
        break;
      case "3 Sector":
        title = "Industries";
        break;
      case "3 Level":
        title = "Size";
        break;
      case "Currency":
        title = "Currency";
        break;
      case "Region":
        title = "Regions";
        break;
      case "Area":
        title = "Areas";
        break;
      case "Type":
        title = "Type";
    }

    return title;
  }, [clusterLevel]);

  const rowClickHandler = useCallback(
    (e) => {
      if (e.type === "rowClick") {
        const eventValue = e.value.getData();
        const id = eventValue.id;
        const rowElement = e.value.getElement();
        rowElement.classList.add("selected");
        setPointerPosition(rowElement.getBoundingClientRect());
        e.value.select();
        setActiveDrillDown(true);

        const filters: any = {
          country: [],
          sector: [],
          industry: [],
          currency: [],
          size: [],
          region: [],
          area: [],
          type: [],
          etfclass: [],
          etfgeo: [],
          specialty: [],
        };

        switch (clusterLevel) {
          case "Country":
            filters["country"] = [id];

            break;

          case "Area":
            filters["area"] = [id];

            break;

          case "Region":
            filters["region"] = [id];

            break;

          case "etfgeo":
            filters["etfgeo"] = [id];

            break;

          case "AssetClass":
            filters["etfclass"] = [id];

            break;

          case "Specialty":
            filters["specialty"] = [id];

            break;
          case "1 Industry":
            filters["sector"] = [id];

            break;
          case "3 Sector":
            filters["industry"] = [id];

            break;
          case "Currency":
            filters["currency"] = [id];

            break;
          case "3 Level":
            filters["size"] = [id];

            break;
          case "Type":
            filters["type"] = [id];
        }

        fetchFilteredData(filters, symbols, setDrillDownData);
      }
    },
    [clusterLevel, fetchFilteredData, symbols]
  );

  useEffect(() => {
    setActiveDrillDown(false);
    setPointerPosition(null);
  }, [clusterLevel]);

  const accordionLabel = <h3>{`${decodeTitle}`}</h3>;
  const arrowDirection = isOpen ? "down" : "up";
  const widgetTitle = (
    <div className="accordion-compare-title">
      <div className="compare-portfolio-widget-title">
        {isOpen ? (
          <ComparisonTableWidgetSelector
            positionsType={positionsType}
            clusterLevel={clusterLevel}
            setClusterLevel={setClusterLevel}
          />
        ) : (
          accordionLabel
        )}
      </div>
      <span onClick={toggleAccordion} className={`i-${arrowDirection}`}></span>
    </div>
  );
  return (
    <div className="rating-weights-block">
      <Accordion titleJSX={widgetTitle} isOpen={isOpen}>
        <>
          <div
            style={{
              flex: 1,
              display: "flex",
              maxHeight: 400,
              minHeight: 250,
            }}
          >
            <CSSTransition
              in={activeDrillDown}
              timeout={600}
              appear
              nodeRef={refTable}
              classNames="controller-table"
            >
              <div className="piechart-block-content-wrapper" ref={refTable}>
                <div className="piechart-block-table">
                  <ComparisonTableWidgetTable
                    name1={name1}
                    name2={name2}
                    rowClickHandler={rowClickHandler}
                    title={decodeTitle}
                    value={value.mainTableData}
                  />
                </div>
              </div>
            </CSSTransition>

            <CSSTransition
              in={activeDrillDown}
              timeout={600}
              nodeRef={refHidedTable}
              classNames="drilldown"
              unmountOnExit
            >
              <div
                className={`summary-table-wrapper-drilldown blockWrapper ${
                  activeDrillDown ? "active-drilldown-table" : ""
                }`}
                ref={refHidedTable}
              >
                <div className="pointer-triangle" ref={pointerElement}></div>
                <div
                  className="pointer-triangle"
                  ref={pointerElementInsetBackground}
                ></div>
                <SummaryTable
                  rowClickHandler={() => {}}
                  data={drillDownData}
                  portfolio1Name={name1}
                  portfolio2Name={name2}
                  hasPrice={false}
                />
              </div>
            </CSSTransition>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
              }}
            >
              <PieChartWidget
                dataSerie={value.pie}
                name={name1}
                selector={clusterLevel}
                positionsType={positionsType}
              />

              <PieChartWidget
                dataSerie={value.pie2}
                name={name2}
                selector={clusterLevel}
                positionsType={positionsType}
              />
            </div>
          </div>
        </>
      </Accordion>
    </div>
  );
}
