import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Paginator from "../../../../../../../components/Paginator/Paginator";
import { Spinner } from "../../../../../../../components/Spinner/Spinner";
import { TrendratingTable } from "../../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { useResizer } from "../../../../../../../hooks/useResizer";
import { config } from "../../../../../config-ts";
import { PerformanceQuartilesTable } from "../../../../analysisMarkets/widgets/PerformanceSinceTrend/PerformanceSinceTrendTab";
import { AvoidableLossHelper } from "./AvoidableLossHelper";
import {
  CellComponent,
  ColumnComponent,
  ColumnDefinition,
  Options,
} from "tabulator-tables";
import { AvoidableLossFactsheetDownload } from "./Actions/AvoidableLossFactsheetDownload";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type AvoidableLossTabProps = {
  portfolioId: any;
  listType: "PORTFOLIO" | "BASKET";
  listName: string;
  cardinalityInfo: {
    AB: number;
    CD: number;
    "AB_%": number;
    "CD_%": number;
    numberOfStocks: number;
  };
};

const quartilesColorMap = {
  1: "#8fd8fc",
  2: "#abe1fc",
  3: "#d1efff",
  4: "#e5f6ff",
};

const useFetch = (id, type, cardinalityInfo) => {
  const [data, setData] = useState<any>();
  const [status, setStatus] = useState<"loading" | "success" | "failed">(
    "loading"
  );
  const [error, setError] = useState<any>();

  const env = useEnvironment();
  const setup = useMemo(() => env.get("setup"), [env]);
  const dataHelper = useMemo(
    () => new AvoidableLossHelper(setup, id, type),
    [id, setup, type]
  );

  const getData = useCallback(async () => {
    setStatus("loading");
    try {
      const loss = await dataHelper.getLoss();
      setData(loss);
      setStatus("success");
    } catch (error) {
      setError(error);
      setStatus("failed");
    }
  }, [dataHelper]);

  useEffect(() => {
    if (cardinalityInfo.CD >= 4) {
      getData();
    } else {
      setError(`The portfolio does not contain enough C&D rated securities`);
      setStatus("failed");
    }

    return () => setData(undefined);
  }, [cardinalityInfo.CD, getData]);

  return { data, status, dataHelper, retrivingDataError: error };
};

const Loader = () => {
  return (
    <Box
      display={"flex"}
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Spinner />
    </Box>
  );
};

export function AvoidableLossTab({
  portfolioId,
  listType,
  listName,
  cardinalityInfo,
}: AvoidableLossTabProps) {
  const { data, status, dataHelper, retrivingDataError } = useFetch(
    portfolioId,
    listType,
    cardinalityInfo
  );
  const [securities, setSecurities] = useState([]);

  const portfolioType = useMemo(
    () => data?.portfolioType ?? "Stock",
    [data?.portfolioType]
  );

  const formatter = useFormatter();

  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [dataTotalCount, setDataTotalCount] = useState(0);
  const [pageNmuber, setPageNumber] = useState(1);
  const [error, setError] = useState<undefined | string>(retrivingDataError);
  const [isLoading, setIsLoading] = useState(false);
  const [tableSort, setTableSort] = useState<any>({
    field: "prr",
    direction: "asc",
  });

  useEffect(() => {
    if (retrivingDataError) {
      setError(retrivingDataError);
    }
  }, [retrivingDataError]);

  const environment = useEnvironment();
  const containerRef = useRef<any>(null);

  useResizer({ ref: containerRef });

  const getSecurities = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {
        page: pageNmuber,
        itemsPerPage,
        sortField: tableSort.field,
        sortDir: tableSort.direction,
        symbols: data["CD"].securities,
      };

      const rows = await dataHelper.getSecurities(
        portfolioType,
        params,
        data?.["CD"]?.quartilesSymbols
      );

      setSecurities(rows.securities);
      setDataTotalCount(rows.total);
    } catch (error: any) {
      console.log(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [
    data,
    dataHelper,
    itemsPerPage,
    pageNmuber,
    portfolioType,
    tableSort.direction,
    tableSort.field,
  ]);

  const getPDFRows = useCallback(async () => {
    return await dataHelper.getSecurities(
      portfolioType,
      {
        page: 1,
        itemsPerPage: 30000,
        sortField: tableSort.field,
        sortDir: tableSort.direction,
        symbols: data["CD"].securities,
      },
      data?.["CD"]?.quartilesSymbols
    );
  }, [data, dataHelper, portfolioType, tableSort.direction, tableSort.field]);

  const properties = environment.get("properties");
  const tableLabels = useMemo(
    () => ({
      ticker: properties.get("ticker", 0, "auto"),
      name: properties.get("name", 0, "auto"),
      marketcap: properties.get("marketcap", 0, "auto"),
      rc: properties.get("rc", 0, "auto"),
      prr: properties.get("prr", 1, "auto"),
      drr: properties.get("drr", 0, "auto"),
      vc: properties.get("vc", 0, "auto"),
      market: properties.get(
        portfolioType === "ETF" ? "etfgeo" : "country",
        0,
        "auto"
      ),
      sector: properties.get(
        portfolioType === "ETF" ? "etfclass" : "industry",
        0,
        "auto"
      ),
      q: "Quartile",
    }),
    [portfolioType, properties]
  );

  const formatQuartilesPDF = useCallback((data) => {
    if (data?.q) {
      return `q:${data.q}`;
    }

    return '<span title="The quartile statistic only includes ratings older than two weeks">less than 2 weeks</span>';
  }, []);

  const formatQuartiles = useCallback((cell: CellComponent) => {
    const data: any = cell.getData();
    const cellElement = cell.getElement();

    if (data?.q) {
      cellElement.style.backgroundColor = quartilesColorMap[data.q];
      return `q:${data.q}`;
    }

    cellElement.style.fontSize = "0.6vw";
    cellElement.style.color = "#333";

    return '<span title="The quartile statistic only includes ratings older than two weeks">less than 2 weeks</span>';
  }, []);

  useEffect(() => {
    if (data) {
      getSecurities();
    }

    return () => {
      setSecurities([]);
    };
  }, [data, getSecurities]);

  const formatNumber = useCallback(
    (number: number | null, hasPositiveSign?: boolean) => {
      const applyPositiveSign =
        hasPositiveSign !== null ? hasPositiveSign : false;
      return formatter.custom("number", {
        options: {
          hasPositiveSign: applyPositiveSign,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: number,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const handleChangeItemsPerPage = useCallback((e) => {
    const value = e.target.value;
    setItemsPerPage(value);
    setPageNumber(1);
  }, []);

  const tableRef = useRef<any>(null);

  const sort = useCallback(
    (e, column: ColumnComponent) => {
      let field = column.getField();

      if (field === "q") {
        field = "prr";
      }

      const toggleDirection = field === tableSort?.field;
      let dir = "asc";

      if (toggleDirection) {
        dir = tableSort.direction === "asc" ? "desc" : "asc";
      }

      setTableSort({
        field: field,
        direction: dir,
      });

      if (pageNmuber !== 1) {
        setPageNumber(1);
      }
    },
    [pageNmuber, tableSort.direction, tableSort?.field]
  );

  const getFormatter = useCallback(
    (cell, property) => {
      const cellData = cell.getData();

      try {
        const value = formatter.table(property, "table", cellData);

        return value;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    [formatter]
  );

  const securitiesTableConfiguration: ColumnDefinition[] = useMemo(
    () => [
      {
        title: tableLabels.ticker,
        headerTooltip: tableLabels.ticker,
        field: "ticker",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        widthGrow: 1,
      },
      {
        title: tableLabels.name,
        headerTooltip: tableLabels.name,
        field: "name",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        widthGrow: 2,
      },
      {
        title: tableLabels.vc,
        headerTooltip: tableLabels.vc,
        field: "vc",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => getFormatter(cell, "vc"),
        widthGrow: 1,
      },
      {
        title: tableLabels.marketcap,
        headerTooltip: tableLabels.marketcap,
        field: "marketcap",
        formatter: (cell) => getFormatter(cell, "marketcap"),
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        widthGrow: 2,
      },
      {
        title: tableLabels.sector,
        headerTooltip: tableLabels.sector,
        field: portfolioType === "ETF" ? "etfclass" : "industry",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          return getFormatter(
            cell,
            portfolioType === "ETF" ? "etfclass" : "industry"
          );
        },
        widthGrow: 3,
      },

      {
        title: tableLabels.rc,
        headerTooltip: tableLabels.rc,
        field: "rc",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatter.custom("rating", {
            options: {
              notAvailable: {
                input: 0,
                output: "-",
              },
            },
            output: "HTML",
            value: data["rc"],
            valueHelper: {
              rateChange: null,
              rateDate: null,
              ratePrev: null,
            },
          });
        },
        widthGrow: 1,
      },

      {
        title: tableLabels.prr,
        headerTooltip: tableLabels.prr,
        field: "prr",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatNumber(data["prr"], true);
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.q,
        headerTooltip: tableLabels.q,
        field: "q",
        sorter: undefined,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => formatQuartiles(cell),
        widthGrow: 1,
      },
    ],
    [
      formatNumber,
      formatQuartiles,
      formatter,
      getFormatter,
      portfolioType,
      sort,
      tableLabels.marketcap,
      tableLabels.name,
      tableLabels.prr,
      tableLabels.q,
      tableLabels.rc,
      tableLabels.sector,
      tableLabels.ticker,
      tableLabels.vc,
    ]
  );

  const { broadcast } = useBroadcast();
  const tableOptions: Options = useMemo(
    () => ({ ajaxSorting: false, height: "500px" }),
    []
  );

  const firstQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["1"] ?? null, true),
    [data, formatNumber]
  );
  const secondQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["2"] ?? null, true),
    [data, formatNumber]
  );
  const thirdQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["3"] ?? null, true),
    [data, formatNumber]
  );
  const fourthQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["4"] ?? null, true),
    [data, formatNumber]
  );
  const cumulativeAvgCD = useMemo(
    () => formatNumber(data?.["CD"]?.peerAvg ?? null, true),
    [data, formatNumber]
  );

  useEffect(() => {
    switch (status) {
      case "loading": {
        setIsLoading(true);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
      case "success": {
        setIsLoading(true);
        break;
      }
    }
  }, [status]);

  useEffect(() => {
    //Workflow Bar
    const actions: any = [];
    const columns = securitiesTableConfiguration.map((col) => {
      if (col.field === "q") {
        return {
          label: col.title,
          property: col.field,
          customFormatter: formatQuartilesPDF,
        };
      } else {
        return {
          label: col.title,
          property: col.field,
        };
      }
    });

    let action: any = {
      componentJSX: (
        <AvoidableLossFactsheetDownload
          listType={listType}
          columns={columns}
          getData={getPDFRows}
          listName={listName}
          quartilesMap={data?.["CD"]}
          numberOfStocks={cardinalityInfo?.numberOfStocks}
          cardinalityCD={cardinalityInfo?.CD}
          cardinalityCDperc={cardinalityInfo?.["CD_%"]}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "AvoidableLossTab",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    cardinalityInfo,
    data,
    formatQuartilesPDF,
    getPDFRows,
    listName,
    listType,
    securitiesTableConfiguration,
  ]);

  const formatPercentage = useCallback(
    (value) => {
      var formatted = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "-",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
      return formatted;
    },
    [formatter]
  );

  return (
    <Box
      ref={containerRef}
      minHeight={0}
      display={"flex"}
      flex={1}
      flexDirection={"column"}
    >
      <Box minHeight={0} display={"flex"} flex={1} gap={1}>
        <Box
          display={"flex"}
          p={1}
          flex={3}
          borderRadius={"4px"}
          boxShadow={3}
          minHeight={0}
          minWidth={0}
          bgcolor={"white"}
          flexDirection={"column"}
        >
          {!error ? (
            <>
              <Box sx={{ display: isLoading ? "flex" : "none" }} flex={1}>
                <Loader />
              </Box>
              <Box flex={1} display={isLoading ? "none" : "flex"}>
                <Box
                  flex={1}
                  fontSize={"1.4vw"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  <span>
                    <strong className="rate rate--C">C</strong>
                    <strong className="rate rate--D">D</strong>
                  </span>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Paginator
                    dataTotalCount={dataTotalCount}
                    initValue={pageNmuber}
                    itemsPerPage={itemsPerPage}
                    handlePaginationChange={setPageNumber}
                  />
                </Box>
                {/* Items per page select */}
                <Box
                  flex={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Typography>Securities per page:</Typography>
                  <FormControl size="small">
                    <Select
                      sx={{ fontSize: "0.8vw", ml: 1 }}
                      labelId="itemsPerPage-select-small"
                      id="itemsPerPage-select-small"
                      value={itemsPerPage}
                      onChange={handleChangeItemsPerPage}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {!isLoading && (
                <Box
                  width={"100%"}
                  height={"100%"}
                  overflow={"hidden!important"}
                >
                  {data && (
                    <TrendratingTable
                      ref={tableRef}
                      tooltip={{
                        actions: { info: { enabled: true } },
                      }}
                      sorting={tableSort}
                      options={tableOptions}
                      autoResize={true}
                      data={securities ?? []}
                      columns={securitiesTableConfiguration}
                    />
                  )}
                </Box>
              )}
            </>
          ) : (
            <Card sx={{ marginTop: 1 }}>
              <CardContent>
                <span>
                  <strong>{error}</strong>
                </span>
              </CardContent>
            </Card>
          )}
        </Box>
        <Box
          mr={1}
          p={1}
          borderRadius={"4px"}
          boxShadow={3}
          minWidth={0}
          overflow={"auto"}
          minHeight={0}
          bgcolor={"white"}
        >
          <Box display={"flex"} flex={1} flexDirection={"column"} gap={1}>
            <PerformanceQuartilesTable
              label="Avoidable Losses"
              performanceAt={"sinceRated"}
              tabSelector={"CD"}
              hasMarginTop={true}
              firstQuartile={firstQuartileCD}
              secondQuartile={secondQuartileCD}
              thirdQuartile={thirdQuartileCD}
              fourthQuartile={fourthQuartileCD}
              cumulativeAvg={cumulativeAvgCD}
              outputInfo={"no-additional-info"}
              isCardActive={false}
            />
            <Card>
              <CardContent>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <span
                          style={{
                            flex: 1,
                            fontSize: "min(0.8vw, 16px)",
                          }}
                        >
                          Number of securities:
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span
                          style={{
                            flex: 4,
                            fontSize: "min(0.8vw, 16px)",
                          }}
                        >
                          {cardinalityInfo.numberOfStocks}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          style={{
                            flex: 1,
                            fontSize: "min(0.8vw, 16px)",
                          }}
                        >
                          C/D rated securities:
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span
                          style={{
                            flex: 4,
                            fontSize: "min(0.8vw, 16px)",
                          }}
                        >
                          {cardinalityInfo.CD} (
                          {formatPercentage(cardinalityInfo["CD_%"])})
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
