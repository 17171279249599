import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useImmerReducer } from "use-immer";
import Modal from "../../../../../../../../../components/Modal/Modal";
import RatingCheckbox from "../../../../../../../../../components/RatingCheckbox/RatingCheckbox";
import SelectionRulesWizzard from "../../../../../../../../../components/RuleWizzard/SelectionRulesWizzard/SelectionRulesWizzard";
import { hedgingReducer, HedgingType } from "./reducer";
import Leverage from "./Widgets/Leverage";
import SearchInstruments from "./Widgets/SearchInstruments";

type HedgingControllerProps = {
  options: any;
  valueFromOutside: HedgingType;
  onClose: Function;
  onCancel: Function;
};

export default function HedgingController({
  options,
  valueFromOutside,
  onClose,
  onCancel,
}: HedgingControllerProps) {
  const [hedgingState, dispatcher] = useImmerReducer(
    hedgingReducer,
    valueFromOutside
  );
  const [dynamicHedgingActive, setDynamicHedgingActive] = useState(
    valueFromOutside != null
  );
  const [showSelectionRules, setShowSelectionRules] = useState(false);

  useEffect(() => {
    if (dynamicHedgingActive === true && hedgingState == null) {
      const initval = {
        constraints: {
          hedgingStrategy: "HEDGING_FULL",
          value: [],
        },
        instrument: null,
        leverage: 1,
        inputError: false,
      };
      dispatcher({ type: "SET_STATE", payload: initval });
    }
  }, [dispatcher, dynamicHedgingActive, hedgingState, valueFromOutside]);

  useEffect(() => {
    if (hedgingState?.constraints.hedgingStrategy !== "HEDGING_ADVANCED") {
      setShowSelectionRules(false);
    }
  }, [hedgingState?.constraints.hedgingStrategy]);

  // useEffect(() => {
  //   console.log("hedgingState", hedgingState);
  // }, [hedgingState]);

  const confermButtonHandler = useMemo(() => {
    if (
      (JSON.stringify(valueFromOutside) !== JSON.stringify(hedgingState) &&
        hedgingState?.instrument != null &&
        hedgingState.leverage != null) ||
      (hedgingState == null && valueFromOutside != null)
    ) {
      return false;
    }
    return true;
  }, [hedgingState, valueFromOutside]);

  return (
    <Box
      sx={{ display: "flex", p: 2, gap: 2, height: "fit-content" }}
      width={"100%"}
    >
      <Box display={"flex"} flexDirection={"column"} width="80%" gap={2}>
        <Card sx={{ boxShadow: 3, height: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingBottom: "16px !important",
            }}
          >
            <FormControlLabel
              sx={{ gap: 1 }}
              control={
                <Switch
                  size="small"
                  checked={dynamicHedgingActive}
                  onChange={(e) => {
                    setDynamicHedgingActive(e.target.checked);
                    if (e.target.checked === false) {
                      dispatcher({ type: "SET_STATE", payload: null });
                    }
                  }}
                />
              }
              label="Dynamic Hedging"
            />
            <SearchInstruments
              dispatcher={dispatcher}
              value={hedgingState?.instrument!}
              dynamicHedgingActive={!dynamicHedgingActive}
            />
            <Leverage
              value={hedgingState?.leverage ?? null}
              dipatcher={(val) =>
                dispatcher({ type: "SET_LEVERAGE", payload: val })
              }
              dynamicHedgingActive={!dynamicHedgingActive}
            />

            <RadioGroup
              defaultValue={
                hedgingState?.constraints.hedgingStrategy ?? "HEDGING_FULL"
              }
              value={
                hedgingState?.constraints.hedgingStrategy ?? "HEDGING_FULL"
              }
              name="radio-buttons-group"
              onChange={(e) =>
                dispatcher({
                  type: "SET_HEDGING_STRATEGY",
                  payload: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="HEDGING_FULL"
                control={<Radio disabled={!dynamicHedgingActive} />}
                label="Full hedging"
              />
              <FormControlLabel
                value="HEDGING_SMART"
                control={<Radio disabled={!dynamicHedgingActive} />}
                label={
                  <Box gap={1} display={"flex"} alignItems={"center"}>
                    <span>{"Smart hedging"}</span>
                    <RatingCheckbox
                      isDisabled={
                        hedgingState?.constraints.hedgingStrategy ===
                          "HEDGING_SMART" && hedgingState != null
                          ? false
                          : true
                      }
                      stateGetter={(e) => {
                        if (
                          dynamicHedgingActive === true &&
                          hedgingState != null &&
                          hedgingState?.constraints.hedgingStrategy ===
                            "HEDGING_SMART"
                        )
                          dispatcher({ type: "SET_HEDGING_SMART", payload: e });
                      }}
                      initState={
                        hedgingState?.constraints?.value[0]?.operatorParams
                          ?.value ?? {
                          A: false,
                          B: false,
                          C: true,
                          D: true,
                        }
                      }
                    />
                  </Box>
                }
              />
              <FormControlLabel
                value="HEDGING_ADVANCED"
                control={<Radio disabled={!dynamicHedgingActive} />}
                label={
                  <Box gap={1} display={"flex"} alignItems={"center"}>
                    <span>Advanced hedging</span>
                    <Button
                      disabled={
                        hedgingState?.constraints.hedgingStrategy ===
                        "HEDGING_ADVANCED"
                          ? false
                          : true
                      }
                      onClick={() => setShowSelectionRules(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                }
              />
            </RadioGroup>
            {showSelectionRules && (
              <Modal
                closeIcon={false}
                buttonsEnalbed={false}
                isResizable={true}
                customCss={{
                  maxWidth: "80%",
                  maxHeight: "90%",
                }}
                headerConfig={{ headerContent: "Advanced hedging" }}
                onClose={() => setShowSelectionRules(false)}
              >
                <SelectionRulesWizzard
                  autoResize={false}
                  options={options}
                  titleRuleList={"Advanced hedging rules"}
                  onClose={(val) => {
                    dispatcher({ type: "SET_HEDGING_ADVANCED", payload: val });
                    console.log(val);
                    setShowSelectionRules(false);
                  }}
                  onCancel={() => setShowSelectionRules(false)}
                  rules={hedgingState?.constraints.value ?? []}
                  caller={null}
                />
              </Modal>
            )}
          </CardContent>
        </Card>

        <Card
          sx={{ display: "flex", justifyContent: "flex-end", boxShadow: 3 }}
        >
          <CardContent>
            <Box sx={{ gap: 2, display: "flex" }}>
              <Button
                disabled={confermButtonHandler}
                onClick={() => {
                  onClose(
                    hedgingState != null
                      ? {
                          constraints: hedgingState?.constraints,
                          instrument: hedgingState?.instrument,
                          leverage: hedgingState?.leverage,
                        }
                      : null
                  );
                }}
                type="button"
              >
                Apply rule
              </Button>

              <Button
                variant="tr_button_cancel"
                type="button"
                size="small"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ width: "20%", height: "100%" }}>
        <Card sx={{ boxShadow: 3, backgroundColor: "#f2f2f2" }}>
          <CardContent>
            <section>
              <h2>Hedging</h2>
              <p>
                Optional - Hedge the strategy to reduce the impact of negative
                events.
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Instrument:</strong> Select the instrument in which
                    a short position should be implemented.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Leverage:</strong> Specify leverage for the hedged
                    position.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Full hedging:</strong> A short position is
                    implemented to the full value of the invested budget taking
                    into account the Leverage assigned.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Smart Hedging:</strong> Select when a short position
                    is assumed based on the rating of the selected instrument.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Advanced Hedging:</strong> Custom rules that will
                    trigger the implementation of a short position based on the
                    set of factors used in the custom rules.
                  </p>
                </li>
              </ul>
            </section>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
