import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SHARED_OBJECT_TYPE_UI_TO_SERVER } from "../../../../../../api/compute/_CommonPublicationsSubscriptions";
import { Publications } from "../../../../../../api/compute/Publications";
import { Subscriptions } from "../../../../../../api/compute/Subscriptions";
import { SystematicProducts } from "../../../../../../api/compute/SystematicProducts";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { StrategiesStorage } from "../../../../storage/StrategiesStorage";
import { DialogSaveComponent } from "../../../../ui/commons/DialogSave/DialogSaveComponent";
import { messageError } from "../../../../utils";

type TrackPortfolioProps = {
  strategyId: number;
  strategyName: string;
  runManager: StrategiesStorage;
  strategyBenchmark: string;
  strategyCurrency: string;
  strategyGranularity: string;
  isReadOnly: boolean;
  save: (redirect?: boolean) => void;
  saveAs: (name: string, redirect?: boolean) => void;
  trackInfo: {
    action: "track" | "listTracked";
    strategyType: string;
    params?: any;
    behaviour:
      | "openSaveDialog"
      | "createSMS"
      | "subscribeSMS"
      | "subscribeStrategyAndSMS"
      | "subscribeAndCreateSMS";
  };
};

export function TrackPortfolio({
  strategyId,
  strategyName,
  runManager,
  strategyBenchmark,
  strategyCurrency,
  strategyGranularity,
  isReadOnly,
  save,
  saveAs,
  trackInfo,
}: TrackPortfolioProps) {
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [trackedSMS, setTrackedSMS] = useState<
    { name: string; isReadOnly: boolean; id: number }[]
  >([]);

  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const { broadcast } = useBroadcast();

  const navigate = useNavigate();

  const subscriptionsAPI = useMemo(() => new Subscriptions(setup), [setup]);
  const systematicProductsAPI = useMemo(
    () => new SystematicProducts(setup),
    [setup]
  );

  const goToTrackedSMS = useCallback(
    (id) => {
      navigate(`/app/systematic-portfolios/${id}`);
    },
    [navigate]
  );

  const subscribe = useCallback(
    async (objectId, type: keyof typeof SHARED_OBJECT_TYPE_UI_TO_SERVER) => {
      try {
        await subscriptionsAPI.create({
          id: objectId,
          type,
        });
      } catch (error: any) {
        console.error("error", error.message);
      }
    },
    [subscriptionsAPI]
  );

  const logUsage = useCallback(() => {
    // ****************** USAGE ********************
    var usage = window.App.usage;
    var info = {
      action: "START_TRACKING",
      actionParam: strategyId,
      function: "STRATEGY_BUILDER",
    };
    usage.record(info);
    // ****************** USAGE ********************
  }, [strategyId]);

  const closeSaveDialog = useCallback(() => {
    setShowSaveDialog(false);
  }, []);

  const create = useCallback(
    async (id?, name?) => {
      try {
        let HPOS: any = [];

        const curves = await runManager.getCurves();
        HPOS = curves.POS.H;
        const product = await systematicProductsAPI.wrapInputAndCreate(
          true,
          strategyBenchmark,
          strategyCurrency,
          HPOS,
          100,
          name ?? strategyName,
          strategyGranularity,
          0,
          id ?? strategyId
        );

        logUsage();
        goToTrackedSMS(product.id);
      } catch (error: any) {
        if (
          error.response.status === "KO" &&
          error.response.error === "DUPLICATE_ENTRY"
        ) {
          let message =
            "Cannot track the current strategy because a portfolio with this name already exists";
          const [channel, msg] = messageError(message);

          broadcast(channel as any, msg);
        } else {
          console.error(error);
        }
      }
    },
    [
      broadcast,
      goToTrackedSMS,
      logUsage,
      runManager,
      strategyBenchmark,
      strategyCurrency,
      strategyGranularity,
      strategyId,
      strategyName,
      systematicProductsAPI,
    ]
  );

  const subscribeStategy = useCallback(
    async () => await subscribe(strategyId, "strategy"),
    [strategyId, subscribe]
  );

  const subscribeSMS = useCallback(async () => {
    if (trackInfo.params?.portfolioId != null) {
      await subscribe(trackInfo.params?.portfolioId, "systematicPortfolio");

      logUsage();
      goToTrackedSMS(trackInfo.params?.portfolioId);
    }
  }, [goToTrackedSMS, logUsage, subscribe, trackInfo.params?.portfolioId]);

  const subscribeStrategyAndSMS = useCallback(async () => {
    await subscribeStategy();
    await subscribeSMS();
  }, [subscribeSMS, subscribeStategy]);

  const subscribeAndCreateSMS = useCallback(async () => {
    await subscribeStategy();
    await create();
  }, [create, subscribeStategy]);

  const trackingCallbacks = useMemo(
    () => ({
      openSaveDialog: () => setShowSaveDialog(true),
      createSMS: create,
      subscribeSMS,
      subscribeStrategyAndSMS,
      subscribeAndCreateSMS,
    }),
    [create, subscribeAndCreateSMS, subscribeSMS, subscribeStrategyAndSMS]
  );

  const handleTrack = useCallback(() => {
    trackingCallbacks[trackInfo.behaviour]();
  }, [trackInfo.behaviour, trackingCallbacks]);

  const saveStrategyAndTrack = useCallback(async () => {
    await save(false);
    await create();
  }, [create, save]);

  const cloneStrategyAndTrack = useCallback(
    async (name: string) => {
      const strategy: any = await saveAs(name, false);
      await create(strategy?.id, strategy?.name);
    },
    [create, saveAs]
  );

  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListPortfolios = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      const portfolioIds = trackInfo.params?.portfolioIds ?? null;

      if (portfolioIds != null && portfolioIds.length > 0) {
        if (portfolioIds.length === 1) {
          goToTrackedSMS(portfolioIds[0]);
        } else {
          openMenu(event);
          const response = await systematicProductsAPI.fetch({
            ids: portfolioIds,
            properties: ["name", "ownerId"],
          });

          const userId = environment.get("account").user.id;

          const trackedItems = response
            .map(
              (item) => ({
                name: item.name,
                id: item.id,
                isReadOnly: userId !== item.ownerId,
              }),
              []
            )
            .sort((a, b) => {
              if (a.isReadOnly !== b.isReadOnly) {
                return a.isReadOnly ? -1 : 1;
              }

              if (a.name > b.name) {
                return -1;
              } else if (a.name < b.name) {
                return 1;
              }

              return 0;
            }, []);

          setTrackedSMS(trackedItems);
        }
      }
    },
    [
      environment,
      goToTrackedSMS,
      systematicProductsAPI,
      trackInfo.params?.portfolioIds,
    ]
  );

  const doAction = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      if (trackInfo.action === "track") {
        handleTrack();
      } else {
        handleListPortfolios(event);
      }
    },
    [handleListPortfolios, handleTrack, trackInfo.action]
  );

  return (
    <>
      {showSaveDialog && (
        <DialogSaveBeforeTrack
          strategyID={strategyId}
          strategyName={strategyName}
          strategyIsReadOnly={isReadOnly}
          onSaveCallback={saveStrategyAndTrack}
          onSaveAsCallback={cloneStrategyAndTrack}
          close={closeSaveDialog}
        />
      )}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{ sx: { pt: 0 } }}
      >
        <Box mb={1} p={1} color={"white"} bgcolor={"#2a7090"}>
          <Typography>Tracked Portfolios</Typography>
        </Box>
        {trackedSMS.map((sms) => {
          return (
            <MenuItem key={uuidv4()} onClick={() => goToTrackedSMS(sms.id)}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                {sms.isReadOnly && (
                  <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                )}
                <Typography>{sms.name}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
      <li className="menu__item" onClick={doAction}>
        {trackInfo.action === "listTracked"
          ? "Tracking Portfolio"
          : "Start Tracking"}
      </li>
    </>
  );
}

const DialogSaveBeforeTrack = ({
  strategyName,
  strategyIsReadOnly,
  strategyID,
  onSaveCallback,
  onSaveAsCallback,
  close,
}) => {
  const handleSaveErrors = useCallback((err, updateErr) => {
    let errorMessage = "The strategy cannot be saved";
    if (err && err.response.error === "OBJECT_ALREADY_EXISTING") {
      errorMessage = "A strategy with this name already exists.";
    }

    updateErr(errorMessage);
  }, []);

  return (
    <DialogSaveComponent
      item={{ name: strategyName }}
      dialogType={"Strategy"}
      onSave={!strategyIsReadOnly && strategyID != null ? onSaveCallback : null}
      onSaveAs={onSaveAsCallback}
      onRename={null}
      hide={close}
      title="Save and Track the current Strategy"
      message="Before continue you need to save the current strategy"
      handleError={handleSaveErrors}
    />
  );
};

export const useTracker = () => {
  const trackParams = useRef<{
    action: "track" | "listTracked";
    strategyType:
      | "NEW"
      | "PERSONAL"
      | "MODIFIED"
      | "PUBLIC"
      | "SUBSCRIBED"
      | "NOT_SUBSCRIBED";
    params?: any;
  }>();
  const environment = useEnvironment();
  const publicationAPI = useMemo(
    () => new Publications(environment.get("setup")),
    [environment]
  );
  const systematicProductsAPI = useMemo(
    () => new SystematicProducts(environment.get("setup")),
    [environment]
  );
  const subscriptionsAPI = useMemo(
    () => new Subscriptions(environment.get("setup")),
    [environment]
  );

  const STRATEGY_TYPES = useMemo(
    () => ({
      new: "NEW",
      personal: "PERSONAL",
      modified: "MODIFIED",
      public: "PUBLIC",
      subscribed: "SUBSCRIBED",
      unsubscribed: "NOT_SUBSCRIBED",
    }),
    []
  );

  const getStrategyImplementations = useCallback(
    async (filters) => {
      const strategyImplementations = await systematicProductsAPI.select({
        searches: [
          {
            filters,
          },
        ],
        sort: { dimension: "name", rev: true },
      });

      return strategyImplementations;
    },
    [systematicProductsAPI]
  );

  const getUserStrategyImplementations = useCallback(
    async (strategyId) => {
      const filters = [
        {
          dimension: "ownerId",
          segments: [environment.get("account").user.id],
        },
        { dimension: "strategyId", segments: [parseInt(strategyId)] },
      ];

      return await getStrategyImplementations(filters);
    },
    [environment, getStrategyImplementations]
  );

  const getStrategyType = useCallback(
    (strategy) => {
      if (strategy == null) {
        return STRATEGY_TYPES.new;
      } else if (strategy.id == null) {
        if (strategy?.ownership == null) {
          return STRATEGY_TYPES.public;
        }
      } else {
        switch (strategy.ownership) {
          case "personal": {
            return STRATEGY_TYPES.personal;
          }
          case "subscribed":
            return STRATEGY_TYPES.subscribed;
          case "public":
            return STRATEGY_TYPES.unsubscribed;
          case "unknown": {
            return STRATEGY_TYPES.public;
          }
        }
      }
    },
    [
      STRATEGY_TYPES.new,
      STRATEGY_TYPES.personal,
      STRATEGY_TYPES.public,
      STRATEGY_TYPES.subscribed,
      STRATEGY_TYPES.unsubscribed,
    ]
  );

  const collectTrackInfo = useCallback(
    async (strategy, isChangedSomething?) => {
      const strategyType = isChangedSomething
        ? STRATEGY_TYPES.modified
        : getStrategyType(strategy);

      let trackInfo: any = null;

      switch (strategyType) {
        case STRATEGY_TYPES.new:
        case STRATEGY_TYPES.modified:
        case STRATEGY_TYPES.public: {
          trackInfo = {
            action: "track",
            strategyType: strategyType,
            params: null,
            behaviour: "openSaveDialog",
          };

          break;
        }
        case STRATEGY_TYPES.personal: {
          const personalStrategyImplementstions =
            await getUserStrategyImplementations(strategy.id);

          if (personalStrategyImplementstions.length) {
            trackInfo = {
              action: "listTracked",
              strategyType,
              params: { portfolioIds: personalStrategyImplementstions },
              behaviour: null,
            };
          } else {
            trackInfo = {
              action: "track",
              strategyType: strategyType,
              params: null,
              behaviour: "createSMS",
            };
          }

          break;
        }
        case STRATEGY_TYPES.subscribed: {
          const personalImplementations = await getUserStrategyImplementations(
            strategy.id
          );

          if (personalImplementations.length > 0) {
            trackInfo = {
              action: "listTracked",
              strategyType,
              params: { portfolioIds: personalImplementations },
              behaviour: null,
            };
          } else {
            // 1 - Check for public SMS that implements the strategy

            /* Call the service to get the shared SMS */
            const sharedSMS = await publicationAPI.get({
              type: "systematicPortfolio",
            });

            const filters = [
              { dimension: "id", segments: sharedSMS.map((item) => item.id) },
              { dimension: "strategyId", segments: [parseInt(strategy.id)] },
            ];

            /* Call select service to retrive the SMS that implements the strategy */
            const publicImplementations = await getStrategyImplementations(
              filters
            );

            if (publicImplementations.length > 0) {
              // 2 - Found some public portfolios that implements the strategy

              //? For a business rule the shared SMS should have a unique strategy so the response in this case
              //? admit only one result

              const subscriptions = await subscriptionsAPI.find(
                publicImplementations[0],
                "systematicPortfolio"
              );

              if (subscriptions?.id != null) {
                // if the strategy is implemented at least by one subscribed portfolio don't show track button
                trackInfo = {
                  action: "listTracked",
                  strategyType,
                  params: { portfolioIds: [subscriptions.objectId] },
                  behaviour: null,
                };
              } else {
                // (a public portfolio implements the strategy) show start traking button that will subscribe the SMS

                trackInfo = {
                  action: "track",
                  strategyType,
                  params: { portfolioId: publicImplementations[0] },
                  behaviour: "subscribeSMS",
                };
              }
            } else {
              // 3 - Not found anything so show the start tracking button

              trackInfo = {
                action: "track",
                strategyType: strategyType,
                params: null,
                behaviour: "createSMS",
              };
            }
          }

          break;
        }
        case STRATEGY_TYPES.unsubscribed: {
          // The strategy is not subscribed this means that the strategy cannot be implemented by a personal portfolio

          // Let's check if a public SMS implements the strategy
          const sharedSMS = await publicationAPI.get({
            type: "systematicPortfolio",
          });

          const filters = [
            { dimension: "id", segments: sharedSMS.map((item) => item.id) },
            { dimension: "strategyId", segments: [parseInt(strategy.id)] },
          ];

          /* Call select service to retrive the SMS that implements the strategy */
          const publicImplementations = await getStrategyImplementations(
            filters
          );

          if (publicImplementations.length > 0) {
            // At least one shared SMS implements the strategy, so show start tracking that will be subscribe both the strategy and the portfolio
            trackInfo = {
              action: "track",
              strategyType: strategyType,
              params: {
                portfolioId: publicImplementations[0],
                strategyId: strategy.id,
              },
              behaviour: "subscribeStrategyAndSMS",
            };
          } else {
            // No shared SMS implements the strategy so show start tracking button that will subscribe the strategy and create the product

            trackInfo = {
              action: "track",
              strategyType: strategyType,
              params: {
                smsId: publicImplementations[0],
                strategyId: strategy.id,
              },
              behaviour: "subscribeAndCreateSMS",
            };
          }

          break;
        }
      }

      trackParams.current = trackInfo;

      return trackInfo;
    },
    [
      STRATEGY_TYPES.modified,
      STRATEGY_TYPES.new,
      STRATEGY_TYPES.personal,
      STRATEGY_TYPES.public,
      STRATEGY_TYPES.subscribed,
      STRATEGY_TYPES.unsubscribed,
      getStrategyImplementations,
      getStrategyType,
      getUserStrategyImplementations,
      publicationAPI,
      subscriptionsAPI,
    ]
  );

  const getTrakingParams = useCallback(
    async (isChanged) => {
      if (isChanged) {
        const info = await collectTrackInfo(true);

        return info;
      } else {
        return trackParams.current;
      }
    },
    [collectTrackInfo]
  );

  return { collectTrackInfo, getTrakingParams };
};
