import { Box, Card, CardContent } from "@mui/material";
import { useRef } from "react";
import { useResizer } from "../../../hooks/useResizer";
import Wizzard from "./Wizzard";
import { UI_CONSTRAINTS_TYPE } from "../../../js/app/pages/strategies/builder/editors/Advanced/utils";

type StrategyRulesWizzardProps = {
  value: any;
  onClose: Function;
  onCancel: Function;
  UI_CONSTRAINTS:UI_CONSTRAINTS_TYPE
};

export default function StrategyRulesWizzard({
  value,
  onClose,
  onCancel,
  UI_CONSTRAINTS
}: StrategyRulesWizzardProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const wizzRef: any = useRef(null);
  const setStrategyRule = () => {
    const state = wizzRef?.current?.getWizzardState();
    onClose(state);
  };

  useResizer({
    ref: containerRef,
  });
  return (
    <div ref={containerRef}>
      <Box sx={{ gap: 2, padding: "20px", height: "max-content" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <Wizzard
            UI_CONSTRAINTS={UI_CONSTRAINTS}
              ref={wizzRef}
              onCancel={() => {
                onCancel();
              }}
              onApplyChanges={setStrategyRule}
              input={value}
            />
          </Box>
          <Box sx={{ maxWidth: "25%", boxShadow: 3 }}>
            <Help />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const Help = () => {
  return (
    <Card sx={{ height: "100%", boxShadow: 3, backgroundColor: "#f2f2f2" }}>
      <CardContent>
        <section>
          <h2>Strategy</h2>
          <br />
          <ul>
            <li>
              <p>
                <strong>Benchmark:</strong> select the benchmark.
              </p>
            </li>
            <li>
              <p>
                <strong>Index:</strong> select the index that best compares to
                the selected universe.
              </p>
            </li>
            <li>
              <p>
                <strong>Neutral Strategy:</strong> price return series of the
                market cap weighted investment universe.
              </p>
            </li>
            <li>
              <p>
                <strong>Currency:</strong> select the currency of the strategy.
                The currency of the strategy, it can either be local currency in
                which each stock’s return is measured in local currency, or it
                can be set to a desired currency in which case, the returns of
                all stocks will be converted to the selected base currency..
              </p>
            </li>
            <li>
              <p>
                <strong>Rebalance:</strong> select the frequency to rebalance
                the strategy/portfolio.
              </p>
            </li>
            <li>
              <p>
                <strong>Number of holdings:</strong> desired number of holdings
                in the strategy portfolio.
              </p>
            </li>
          </ul>
        </section>
      </CardContent>
    </Card>
  );
};
