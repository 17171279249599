/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/ClusterAnalytics
 * @summary ClusterAnalytics requests
 *
 */

import { Immutable } from "immer";
import {
  ListPosition,
  ServerFilter,
  ServerListPosition,
  ServerRelation,
} from "../../types/Api";
import { endpoints } from "../endpoints";
import { decodePeerId, encodePeerId } from "../utils";
import { _Base } from "../_Base";
import { getTaxonById } from "./Taxon";

type UniverseSearch = {
  search: {
    page: {
      page: number;
      rows: number;
    };
    filters: ServerFilter[];
    ranges?: any[];
    constraints?: any[];
  };
};

type UniverseList = {
  search: {
    page: {
      page: number;
      rows: number;
    };
    filters: ServerFilter[];
    relations: ServerRelation[];
  };
};

type UniversePositions = {
  v: ServerListPosition[];
};

type Universe = UniverseSearch | UniversePositions | UniverseList;

type ClusterAnalyticsMethod = "DRILL_DOWN" | "INTERSECTION";

type ClusterAnalyticsState = {
  analytics: string[];
  method: ClusterAnalyticsMethod;
  date?: number;
  clusters: any[];
  universe: Universe;
};

// Extends state with a specific universe, to use in defaultState
interface ClusterAnalyticsStateDefault extends ClusterAnalyticsState {
  universe: UniverseSearch;
}

const defaultState: Immutable<ClusterAnalyticsStateDefault> = {
  analytics: [],
  method: "DRILL_DOWN",
  clusters: [],
  universe: {
    search: {
      page: {
        page: 1,
        rows: 99999,
      },
      filters: [],
    } as const,
  },
} as const;

class ClusterAnalyticsConfiguration {
  constructor(
    private readonly clusterAnalytics: ClusterAnalytics,
    private readonly state: Immutable<ClusterAnalyticsState> = defaultState
  ) {}

  private createNewState(state) {
    return new ClusterAnalyticsConfiguration(this.clusterAnalytics, state);
  }

  analytics(analytics: string[]) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      analytics: analytics,
    };
    return this.createNewState(state);
  }

  /**
   * Will remove old clusters. If you use segment, use it after this
   * @param clusters
   * @returns
   */
  clusters(clusters: any[]) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      clusters: [...this.state.clusters, ...clusters],
    };
    return this.createNewState(state);
  }

  date(date: number) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      date,
    };
    return this.createNewState(state);
  }

  debug() {
    return this.state;
  }

  method(method: ClusterAnalyticsMethod) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      method: method,
    };
    return this.createNewState(state);
  }

  universeFromConstraints(constraints) {
    const state = { ...this.state };

    state.universe = { search: constraints };

    return this.createNewState(state);
  }

  universeFromFilters(
    filters: any[],
    ranges?: any[],
    relations?: any[],
    syntax?: "select" | "screening"
  ) {
    const state: Immutable<ClusterAnalyticsState> =
      ranges != null && ranges.length
        ? {
            ...this.state,
            universe:
              "search" in this.state.universe
                ? {
                    search: {
                      ...this.state.universe.search,
                      filters: [
                        ...this.state.universe.search.filters,
                        ...filters,
                      ],
                      ranges,
                    },
                  }
                : {
                    search: {
                      ...defaultState.universe.search,
                      filters: [
                        ...defaultState.universe.search.filters,
                        ...filters,
                      ],
                      ranges,
                    },
                  },
          }
        : {
            ...this.state,
            universe:
              "search" in this.state.universe
                ? {
                    search: {
                      ...this.state.universe.search,
                      filters: [
                        ...this.state.universe.search.filters,
                        ...filters,
                      ],
                    },
                  }
                : {
                    search: {
                      ...defaultState.universe.search,
                      filters: [
                        ...defaultState.universe.search.filters,
                        ...filters,
                      ],
                    },
                  },
          };

    if (relations && relations.length) {
      state.universe["search"]["relations"] = relations;
    }

    return this.createNewState(state);
  }

  universeFromPeer(peerId, ranges?: { dimension: string; segments: any[] }[]) {
    const decodedPeer = decodePeerId(peerId);
    const peerType = decodedPeer.type === "ETF" ? "ETF" : "security";

    // TODO Peer: uses Stock as default
    const filters: ServerFilter[] =
      peerType === "security"
        ? [
            {
              dimension: "type",
              segments: ["Stock"],
            },
            {
              dimension: "stockclass",
              segments: ["STOCK"],
            },
          ]
        : [
            {
              dimension: "type",
              segments: ["ETF"],
            },
          ];

    const taxonomies = this.clusterAnalytics.environment.taxonomies;
    const fieldsMap = this.clusterAnalytics.environment.taxonomyFields;
    const fieldX = peerType === "ETF" ? "etfgeo" : "country";
    const fieldY = peerType === "ETF" ? "etfclass" : "icb";
    const taxonomiesMapX = taxonomies[fieldsMap[peerType][fieldX]];
    const taxonomiesMapY = taxonomies[fieldsMap[peerType][fieldY]];

    const rootNodeX = Object.values<any>(taxonomiesMapX).find(
      (node) => node.parent == null
    )["id"];
    const rootNodeY = Object.values<any>(taxonomiesMapY).find(
      (node) => node.parent == null
    )["id"];
    const zDimension = decodedPeer["zDimension"];

    if (peerType !== "ETF") {
      if (decodedPeer["zDimension"] !== "microLarge") {
        filters.push({
          dimension: "sizeClassification",
          segments: [decodedPeer["zDimension"]],
        });
      }
    } else {
      filters.push({
        dimension: "subtype",
        segments: [zDimension],
      });
    }

    if (decodedPeer["what"] !== rootNodeY) {
      filters.push({
        dimension: fieldY,
        segments: [decodedPeer["what"]],
      });
    }

    if (decodedPeer["where"] !== rootNodeX) {
      filters.push({
        dimension: fieldX,
        segments: [decodedPeer["where"]],
      });
    }

    return this.universeFromFilters(filters, ranges);
  }

  universeFromPositions(positions: ListPosition[]) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      universe: {
        v: positions.map((position: any) => ({
          A: position.weight,
          S: position.symbol,
        })),
      },
    };
    return this.createNewState(state);
  }

  universeFromInstruments(symbols: string[], ranges?: any[]) {
    return this.universeFromFilters(
      [
        {
          dimension: "symbol",
          segments: symbols,
        },
      ],
      ranges
    );
  }

  universeFromList(listId, listType) {
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      universe:
        "search" in this.state.universe
          ? {
              search: {
                ...this.state.universe.search,
                filters: [],
                relations: [
                  {
                    domain: listId,
                    range: listType.toUpperCase(),
                  },
                ],
              },
            }
          : {
              search: {
                ...defaultState.universe.search,
                //   filters: [
                //       ...defaultState.universe.search.filters,
                //       ...filters,
                //   ],
              },
            },
    };
    return this.createNewState(state);
  }

  segment(segment: string, showAllLevels?: boolean) {
    let showAll = showAllLevels != null ? showAllLevels : false;

    let segmentCluster;
    switch (segment) {
      case "1 Industry":
        segmentCluster = {
          dimension: "icb",
          transform: {
            function: "taxonomy",
            params: {
              level: "1 Industry",
              showAll,
            },
          },
        };
        break;
      case "3 Sector":
        segmentCluster = {
          dimension: "icb",
          transform: {
            function: "taxonomy",
            params: {
              level: "3 Sector",
              showAll,
            },
          },
        };
        break;
      case "AssetClass":
        segmentCluster = {
          dimension: "etfclass",
          transform: {
            function: "taxonomy",
            params: {
              level: "1 Industry",
              showAll,
            },
          },
        };
        break;
      case "ETF_Subsector": {
        segmentCluster = {
          dimension: "etfclass",
          transform: {
            function: "taxonomy",
            params: {
              level: "4 Subsector",
              showAll,
            },
          },
        };
        break;
      }
      case "ETFSEGMENT":
        segmentCluster = {
          dimension: "etfclass",
          transform: {
            function: "taxonomy",
            params: {
              level: "0 root",
              showAll,
            },
          },
        };
        break;
      case "Specialty":
        segmentCluster = {
          dimension: "etfclass",
          transform: {
            function: "taxonomy",
            params: {
              level: "3 Sector",
              showAll,
            },
          },
        };
        break;
      case "Theme":
        segmentCluster = {
          dimension: "etfclass",
          transform: {
            function: "taxonomy",
            params: {
              level: "4 Subsector",
              showAll,
            },
          },
        };
        break;
      case "etfRootGeo":
        segmentCluster = {
          dimension: "etfgeo",
          transform: {
            function: "taxonomy",
            params: {
              level: "0 Root",
              showAll,
            },
          },
        };
        break;
      case "World":
      case "WWW":
        segmentCluster = {
          dimension: "country",
          transform: {
            function: "taxonomy",
            params: {
              level: "World",
              showAll,
            },
          },
        };
        break;
      case "Area":
        segmentCluster = {
          dimension: "country",
          transform: {
            function: "taxonomy",
            params: {
              level: "Area",
              showAll,
            },
          },
        };
        break;
      case "Region":
        segmentCluster = {
          dimension: "country",
          transform: {
            function: "taxonomy",
            params: {
              level: "Region",
              showAll,
            },
          },
        };
        break;
      case "Country":
        segmentCluster = {
          dimension: "country",
          transform: {
            function: "taxonomy",
            params: {
              level: "Country",
              showAll,
            },
          },
        };
        break;
      case "etfgeo":
        segmentCluster = {
          dimension: "etfgeo",
          transform: {
            function: "taxonomy",
            params: {
              level: "Country",
              showAll,
            },
          },
        };
        break;
      case "etfgeoArea":
        segmentCluster = {
          dimension: "etfgeo",
          transform: {
            function: "taxonomy",
            params: {
              level: "Area",
              showAll,
            },
          },
        };
        break;
      case "etfgeoRegion":
        segmentCluster = {
          dimension: "etfgeo",
          transform: {
            function: "taxonomy",
            params: {
              level: "Region",
              showAll,
            },
          },
        };
        break;
      case "Currency":
        segmentCluster = {
          dimension: "currency",
          transform: {
            function: "value",
          },
        };
        break;
      case "Type":
        segmentCluster = {
          dimension: "type",
          transform: {
            function: "value",
          },
        };
        break;
      //3 Level is the level of taxonomies SizeClassification(server side).
      //Here we don't use the taxonomy directly
      case "3 Level":
        segmentCluster = {
          dimension: "marketcap",
          transform: {
            function: "size",
          },
        };
        break;
      case "0 root":
      case "ICB":
        segmentCluster = {
          dimension: "icb",
          transform: {
            function: "taxonomy",
            params: {
              level: "0 root",
              showAll,
            },
          },
        };
        break;
      default:
        throw new Error(`Unknown segment ${segment}`);
    }
    const state: Immutable<ClusterAnalyticsState> = {
      ...this.state,
      clusters: [segmentCluster, ...this.state.clusters],
    };
    return this.createNewState(state);
  }

  async fetchAnalytics() {
    const response = await this.clusterAnalytics.analytics(this.state);
    return response?.data;
  }

  async fetchInfo() {
    const response = await this.clusterAnalytics.analytics(this.state);
    return response?.data?.clustersStats?.info;
  }

  async fetchQuantiles() {
    const response = await this.fetchInfo();
    return response?.quantiles;
  }
}

/**
 * analytics: "old" analytic to migrate
 * analytic: get analytics
 * info: get only info field
 * get: get full response from ClusterAnalytic API
 */
export class ClusterAnalytics extends _Base {
  decodeAnalyticName(originalName) {
    const serverToUiMap = {
      TOP: "top",
      MID: "middle",
      BOTTOM: "bottom",
      "#avg": "average",
      "#avg#false": "average",
      "#avg#true": "average",
      "#min": "min",
      "#min#false": "min",
      "#min#true": "min",
      "#max": "max",
      "#max#false": "max",
      "#max#true": "max",
      card: "cardinality",
    };

    // Check about server response and remap back
    // Es: BOTTOM -> extract bottom
    if (serverToUiMap[originalName]) {
      return serverToUiMap[originalName];
    }

    // Check about server response and remap back
    // Es: pq#max#false -> extract #max#false
    if (serverToUiMap[originalName.slice(originalName.indexOf("#"))]) {
      return serverToUiMap[originalName.slice(originalName.indexOf("#"))];
    }

    return originalName;
  }

  decodeAnalytics(clusterAnalytics, peer?, segment?) {
    // Prepare data
    let result: any = {};

    // Empty Peer
    if (clusterAnalytics?.axis?.[0]?.length === 0) {
      result["bottom"] = {
        average: 0,
        cardinality: 0,
        data: [],
        dataTotalCount: 0,
        max: 0,
        min: 0,
      };
      result["middle"] = {
        average: 0,
        cardinality: 0,
        data: [],
        dataTotalCount: 0,
        max: 0,
        min: 0,
      };
      result["top"] = {
        average: 0,
        cardinality: 0,
        data: [],
        dataTotalCount: 0,
        max: 0,
        min: 0,
      };
    }

    if (clusterAnalytics.axis.length === 1) {
      for (const clusterId of clusterAnalytics.axis[0]) {
        let analytic = this.decodeAnalyticName(clusterId);

        if (clusterAnalytics.clustersStats.stats[clusterId] == null) {
          console.log(`Missing stats for ${clusterId}`);
          continue;
        }

        result[analytic] = {};
        for (const [statKey, statValue] of Object.entries(
          clusterAnalytics.clustersStats.stats[clusterId]
        )) {
          result[analytic][this.decodeAnalyticName(statKey)] = statValue ?? 0;
        }
        const securities = clusterAnalytics.clustersStats.clusters[clusterId];
        result[analytic]["data"] = securities.map((item) => ({
          symbol: item,
        }));
        result[analytic]["dataTotalCount"] = securities.length;
      }
    } else {
      if (peer == null) {
        throw new Error("Missing peer");
      }
      const decodedPeer = decodePeerId(peer);
      let dimensionKey;
      if (segment) {
        switch (segment) {
          case "AssetClass":
          case "1 Industry":
            dimensionKey = "what";
            break;
          case "Theme":
          case "ETF_Subsector":
          case "Specialty":
          case "3 Sector":
            dimensionKey = "what";
            break;
          case "World":
            dimensionKey = "where";
            break;
          case "etfgeoArea":
          case "Area":
            dimensionKey = "where";
            break;
          case "etfgeoRegion":
          case "Region":
            dimensionKey = "where";
            break;
          case "etfgeo":
          case "Country":
            dimensionKey = "where";
            break;
          case "3 Level":
            dimensionKey = "zDimension";
            break;
          default:
            throw new Error(`Unknown segment ${segment}`);
        }
      }
      for (const dimension of clusterAnalytics.axis[0]) {
        let newPeer = {
          ...decodedPeer,
        };
        // Overwrite specific dimension key
        if (dimensionKey != null) {
          newPeer[dimensionKey] = dimension;
        }
        const peerId = encodePeerId(newPeer);

        const peerType = newPeer.type; // stock or etf
        const taxonomies = this.environment.taxonomies;
        const fieldsMap = this.environment.taxonomyFields;
        const fieldType = peerType === "ETF" ? "ETF" : "security";
        let field: string | null = null;

        if (dimensionKey != null) {
          if (dimensionKey === "what") {
            field = fieldType === "ETF" ? "etfclass" : "icb";
          } else if (dimensionKey === "where") {
            field = fieldType === "ETF" ? "etfgeo" : "country";
          }
        }

        const peerObject = {
          dispersion: {},
          name:
            field != null
              ? Object.values<any>(
                  taxonomies[fieldsMap[fieldType][field]]
                ).find((taxonomy) => taxonomy.id === dimension)?.name ??
                `No name found for ${dimension}`
              : null,
          peer: {
            id: peerId,
          },
        };

        if (dimensionKey === "zDimension") {
          const sizeClassification = taxonomies["SizeClassification"];
          const sizeNode = sizeClassification[newPeer[dimensionKey]];

          if (sizeNode) {
            peerObject.name = sizeNode.name;
          }
        }

        for (const analyticKey of clusterAnalytics.axis[1]) {
          let clusterId = `${dimension}|${analyticKey}`;
          let analytic = this.decodeAnalyticName(analyticKey);

          if (clusterAnalytics.clustersStats.stats[clusterId] == null) {
            console.log(`Missing stats for ${clusterId}`);
            continue;
          }

          peerObject["dispersion"][analytic] = {};
          for (const [statKey, statValue] of Object.entries(
            clusterAnalytics.clustersStats.stats[clusterId]
          )) {
            peerObject["dispersion"][analytic][
              this.decodeAnalyticName(statKey)
            ] = statValue ?? 0;
          }
          const securities = clusterAnalytics.clustersStats.clusters[clusterId];
          peerObject["dispersion"][analytic]["data"] = securities.map(
            (item) => ({
              symbol: item,
            })
          );
          peerObject["dispersion"][analytic]["dataTotalCount"] =
            securities.length;
        }

        result[peerId] = peerObject;
      }
    }

    return result;
  }

  decodeAnalyticsList(clusterAnalytics) {
    // Prepare data
    let result: any = {};

    // Empty cluster
    if (clusterAnalytics?.axis?.[0]?.length === 0) {
      /**
       * If no cluster was found return an empty object
       */
      return {};
    }

    const taxonFields = this.environment.taxonomyFields;
    const taxonomiesRaw = this.environment.taxonomies;

    if (clusterAnalytics.axis.length === 1) {
      for (const clusterId of clusterAnalytics.axis[0]) {
        let analytic = this.decodeAnalyticName(clusterId);

        if (clusterAnalytics.clustersStats.stats[clusterId] == null) {
          console.log(`Missing stats for ${clusterId}`);
          continue;
        }

        result[analytic] = {};
        for (const [statKey, statValue] of Object.entries(
          clusterAnalytics.clustersStats.stats[clusterId]
        )) {
          result[analytic][this.decodeAnalyticName(statKey)] = statValue ?? 0;
        }
        const securities = clusterAnalytics.clustersStats.clusters[clusterId];
        result[analytic]["data"] = securities.map((item) => ({
          symbol: item,
        }));
        result[analytic]["dataTotalCount"] = securities.length;
      }
    } else {
      const txWhat = taxonomiesRaw[taxonFields["security"]["sector"]];
      const txWhere = taxonomiesRaw[taxonFields["security"]["country"]];
      const txWhereEtf = taxonomiesRaw[taxonFields["ETF"]["etfgeo"]];
      const txWhatEtf = taxonomiesRaw[taxonFields["ETF"]["etfclass"]];
      const txSize = taxonomiesRaw["SizeClassification"];

      var taxonomies = [txWhat, txWhere, txWhatEtf, txWhereEtf, txSize];

      for (const dimension of clusterAnalytics.axis[0]) {
        let id: any = null;

        id = getTaxonById(dimension, taxonomies)["name"];

        const dispersionObject = {
          dispersion: {},
          name: id,
        };

        for (const analyticKey of clusterAnalytics.axis[1]) {
          let clusterId = `${dimension}|${analyticKey}`;
          let analytic = this.decodeAnalyticName(analyticKey);

          if (clusterAnalytics.clustersStats.stats[clusterId] == null) {
            console.log(`Missing stats for ${clusterId}`);
            continue;
          }

          dispersionObject["dispersion"][analytic] = {};
          for (const [statKey, statValue] of Object.entries(
            clusterAnalytics.clustersStats.stats[clusterId]
          )) {
            dispersionObject["dispersion"][analytic][
              this.decodeAnalyticName(statKey)
            ] = statValue ?? 0;
          }
          const securities = clusterAnalytics.clustersStats.clusters[clusterId];
          dispersionObject["dispersion"][analytic]["data"] = securities.map(
            (item) => ({
              symbol: item,
            })
          );
          dispersionObject["dispersion"][analytic]["dataTotalCount"] =
            securities.length;
        }

        result[id] = dispersionObject;
      }
    }

    return result;
  }

  /**
   * Get specific fields from a list of ids of type classType
   *
   * @param {string}   params.classType - server type of object
   * @param {object[]} params.ids - arrays of ids of classType items
   * @param {string[]} params.properties - list of properties to retrieve for each symbol
   * @returns
   */
  async analytics(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.clusterAnalytics.analytic;
    return this.preparePost(url, params, null);
  }

  /**
   * Get specific fields from a list of ids of type classType
   *
   * @param {string}   params.classType - server type of object
   * @param {object[]} params.ids - arrays of ids of classType items
   * @param {string[]} params.properties - list of properties to retrieve for each symbol
   * @returns
   */
  async info(params: any) {
    const response = await this.get(params);

    return response.data;
  }

  /**
   * Get specific fields from a list of ids of type classType
   *
   * @param {string}   params.classType - server type of object
   * @param {object[]} params.ids - arrays of ids of classType items
   * @param {string[]} params.properties - list of properties to retrieve for each symbol
   * @returns
   */
  async get(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.clusterAnalytics.analytic;
    const response = await this.preparePost(url, params, null);
    return response.data;
  }

  createConfiguration() {
    return new ClusterAnalyticsConfiguration(this);
  }

  //TODO: Delete after main class ClusterAnalytics is ready
  async getPieChartPeers(positionsAsAllocation) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.clusterAnalytics.analytic;
    const positions = positionsAsAllocation.map((position) => ({
      A: position["weight"],
      S: position["symbol"],
    }));
    const params = {
      method: "INTERSECTION",
      clusters: [
        {
          dimension: "icb",
          transform: {
            function: "taxonomy",
            params: {
              level: "1 Industry",
            },
          },
        },
      ],
      universe: {
        v: positions ?? [],
      },
      analytics: ["weight", "TCR"],
    };
    const response = await this.preparePost(url, params, null);

    // Normalize data
    return this.decodeAnalytics(response.data);
  }
}
