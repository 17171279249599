import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { config } from "../../../config-ts";
import { PortfolioAnalyzeStorage } from "../../../storage/PortfolioAnalyzeStorage";
import { removeLoader } from "../../../utils";
import { ListSelect } from "../widgets/ListSelect";
import { TabEvidence } from "./tabs/TabEvidence/TabEvidence";
import { TabAlternatives } from "./tabs/TabImprove/TabAlternatives";
import { TabCompare } from "./tabs/TabCompare/TabCompare";
import { TabDispersion } from "./tabs/TabDispersion/TabDispersion";
import { TabRanking } from "./tabs/TabRanking/TabRanking";
import { TabAllocation } from "./tabs/TabAllocation/TabAllocation";
import { TabHoldings } from "./tabs/TabHoldings/TabHoldings";
import { TabOverview } from "./tabs/TabOverview/TabOverview";
import { TabPointInTime } from "./tabs/TabPointInTime/TabPointInTime";
import { TabTrends } from "./tabs/TabTrends/TabTrends";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

type HeaderProps = {
  changeList: (listId) => void;
  currentListId?: number;
};

type ExtendedComponent =
  | typeof TabPointInTime
  | typeof TabEvidence
  | typeof TabAlternatives
  | typeof TabCompare
  | typeof TabDispersion
  | typeof TabRanking
  | typeof TabOverview
  | typeof TabHoldings
  | typeof TabAllocation
  | typeof TabTrends
  | typeof TabPointInTime;

type TabProps = {
  dataManager: PortfolioAnalyzeStorage;
};

type TabWithLog = {
  tabName: "compare" | "improve" | "rank";
};

const USAGE = {
  function: "PORTFOLIO_ANALYSIS",
};

const withCache = (Component: ExtendedComponent) => {
  return ({ dataManager }: TabProps) => {
    const { broadcast } = useBroadcast();
    const clearWorkflow = useCallback(() => {
      var message = {
        from: "analysisList",
        content: {
          actions: [],
        },
      };

      broadcast(config["channels"]["workflow"]["input"], message);
    }, [broadcast]);

    useEffect(() => {
      return () => {
        clearWorkflow();
      };
    }, [clearWorkflow]);

    return <Component dataManager={dataManager} />;
  };
};

const withLog = (Component: ExtendedComponent) => {
  return ({ dataManager, tabName }: TabProps & TabWithLog) => {
    const usage = useMemo(() => window.App.usage, []);
    const urlParams = useParams();

    const log = useCallback(() => {
      const functionMap = {
        rank: "PORTFOLIO_RANKING",
        compare: "PORTFOLIO_COMPARE",
        improve: "IMPROVE",
      };

      // ************** USAGE ****************
      var info = {
        action: "LANDING",
        actionParam: parseInt(urlParams.id!),
        function: functionMap[tabName],
      };
      usage.record(info);
      // ************** USAGE ****************
    }, [tabName, urlParams.id, usage]);

    useEffect(() => {
      log();
    }, [log]);

    return <Component dataManager={dataManager} />;
  };
};

const Allocation = withCache(TabAllocation);
const Overview = withCache(TabOverview);
const Ranking = withLog(withCache(TabRanking));
const Evidence = withCache(TabEvidence);
const Alternatives = withLog(withCache(TabAlternatives));
const Dispersion = withCache(TabDispersion);
const Compare = withLog(withCache(TabCompare));
const Holdings = withCache(TabHoldings);
const Trends = withCache(TabTrends);
const PointInTime = withCache(TabPointInTime);

export function AnalyzeList() {
  useEffect(() => {
    removeLoader();
  }, []);

  const [mainState, setMainState] = useState<any>();

  const environment = useEnvironment();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const urlParams = useParams();
  const listId = useMemo(() => {
    if (urlParams?.id) {
      return parseInt(urlParams?.id);
    }
  }, [urlParams?.id]);
  const TABS_DICT = useMemo(() => {
    return {
      uri: {
        allocation: "allocation",
        holdings: "holdings",
        pointInTime: "point-in-time",
        ranking: "ranking",
        trends: "trend-analysis",
        compare: "compare",
        dispersion: "dispersion",
        alternatives: "improve",
        sinceRated: "avoidable-loss",
        overview: "overview",
      },
      tab: {
        allocation: "allocation",
        holdings: "holdings",
        "point-in-time": "pointInTime",
        ranking: "ranking",
        "trend-analysis": "trends",
        compare: "compare",
        dispersion: "dispersion",
        improve: "alternatives",
        "avoidable-loss": "sinceRated",
        overview: "overview",
      },
    };
  }, []);
  const pageContextPivot = useMemo(() => {
    return TABS_DICT?.["tab"]?.[urlParams?.tab ?? "overview"];
  }, [TABS_DICT, urlParams?.tab]);

  const dataManager = useMemo(() => {
    return new PortfolioAnalyzeStorage(
      environment.get("setup"),
      parseInt(urlParams.id!)
    );
  }, [environment, urlParams.id]);

  const tabsStateRefresh = useCallback(
    (_tabsState) => {
      var index = 0;
      if (pageContextPivot != null) {
        switch (pageContextPivot) {
          case "allocation": {
            index = _tabsState.optionsMap.allocation;

            break;
          }
          case "holdings": {
            index = _tabsState.optionsMap.holdings;

            break;
          }
          case "point-in-time": {
            index = _tabsState.optionsMap.pointInTime;

            break;
          }
          case "ranking": {
            index = _tabsState.optionsMap.ranking;

            break;
          }
          case "trend-analysis": {
            index = _tabsState.optionsMap.trends;

            break;
          }
          case "compare": {
            index = _tabsState.optionsMap.compare;

            break;
          }
          case "dispersion": {
            index = _tabsState.optionsMap.dispersion;

            break;
          }

          case "avoidable-loss": {
            index = _tabsState.optionsMap.sinceRated;

            break;
          }

          case "improve": {
            index = _tabsState.optionsMap.alternatives;

            break;
          }

          case "overview":
          default: {
            index = _tabsState.optionsMap.overview;
          }
        }
      }

      _tabsState.current = _tabsState.options[index].value;
      for (const option of _tabsState.options) {
        option.selected = false;
      }

      _tabsState.options[index].selected = true;

      setMainState(_tabsState);
    },
    [pageContextPivot]
  );

  const tabsStateInit = useCallback(() => {
    const _tabsState: any = {
      current: null,
      options: [],
      optionsMap: {},
      widget: null,
      widgetConfiguration: {},
    };

    const configuration = environment.get("configuration")["configuration"];
    const analysisListTabs = configuration.pages.analysisList.tabs;
    let tab: any = null;
    for (var i = 0, length = analysisListTabs.length; i < length; i++) {
      tab = analysisListTabs[i];

      if (tab.enabled === true) {
        switch (tab.id) {
          case "allocation": {
            _tabsState.options.push({
              label: t("Allocation"),
              value: tab.id,
            });

            break;
          }
          case "holdings": {
            _tabsState.options.push({
              label: t("Holdings"),
              value: tab.id,
            });

            _tabsState.widgetConfiguration[tab.id] = tab.widgets;

            break;
          }
          case "overview": {
            _tabsState.options.push({
              label: t("Overview"),
              value: tab.id,
            });

            break;
          }
          case "compare": {
            _tabsState.options.push({
              label: "Compare",
              value: tab.id,
            });

            break;
          }
          case "dispersion": {
            _tabsState.options.push({
              label: "Performance dispersion",
              value: tab.id,
            });

            break;
          }
          case "sinceRated": {
            _tabsState.options.push({
              label: "Evidence",
              value: tab.id,
            });

            break;
          }
          case "alternatives": {
            _tabsState.options.push({
              label: "Improve",
              value: tab.id,
            });

            break;
          }
          case "pointInTime": {
            _tabsState.options.push({
              label: t("Point_in_time"),
              value: tab.id,
            });
            _tabsState.widgetConfiguration[tab.id] = tab.widgets;

            break;
          }
          case "ranking": {
            _tabsState.options.push({
              label: t("Ranking"),
              value: tab.id,
            });

            _tabsState.widgetConfiguration[tab.id] = tab.widgets;

            break;
          }
          case "trends": {
            _tabsState.options.push({
              label: t("Trend_analysis"),
              value: tab.id,
            });

            break;
          } // no default
        }
      }
    }
    // generate options map to access options by value
    let option: any = null;
    for (let i = 0, length = _tabsState.options.length; i < length; i++) {
      option = _tabsState.options[i];

      _tabsState.optionsMap[option.value] = i;
    }

    tabsStateRefresh(_tabsState);
  }, [environment, t, tabsStateRefresh]);

  const listenerListChange = useCallback(
    (listId) => {
      var uri = `/app/analysis/lists/${listId}/analyze/overview`;

      // *************************** USAGE ***************************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: listId,
        function: USAGE["function"],
      };
      usage.record(info);
      // *************************** USAGE ***************************

      navigate(uri);
    },
    [navigate]
  );

  const fromTabValueToUriToken = useCallback(
    (tabValue) => {
      return TABS_DICT?.["uri"]?.[tabValue] ?? "overview";
    },
    [TABS_DICT]
  );

  const listenerTabChange = useCallback(
    (event, tab) => {
      const uri = `/app/analysis/lists/${listId}/analyze/${fromTabValueToUriToken(
        tab
      )}/`;

      navigate(uri);
    },
    [fromTabValueToUriToken, listId, navigate]
  );

  const onPageMount = useCallback(() => {
    tabsStateInit();
  }, [tabsStateInit]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return (
    <article
      style={{
        height: "100%",
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div data-dojo-attach-point="nodeErrorMessage"></div>
      <Header currentListId={listId} changeList={listenerListChange} />
      <div className="tPageAnalysisList-tabs">
        <Tabs value={pageContextPivot} onChange={listenerTabChange}>
          {mainState?.options?.map((item) => (
            <Tab key={uuidv4()} label={item.label} value={item.value} />
          ))}
        </Tabs>
      </div>
      <Box height={"100%"} minHeight={0} p={1}>
        {pageContextPivot === "overview" && (
          <Overview dataManager={dataManager} />
        )}
        {pageContextPivot === "ranking" && (
          <Ranking tabName="rank" dataManager={dataManager} />
        )}
        {pageContextPivot === "sinceRated" && (
          <Evidence dataManager={dataManager} />
        )}
        {pageContextPivot === "alternatives" && (
          <Alternatives tabName="improve" dataManager={dataManager} />
        )}
        {pageContextPivot === "dispersion" && (
          <Dispersion dataManager={dataManager} />
        )}
        {pageContextPivot === "compare" && (
          <Compare tabName="compare" dataManager={dataManager} />
        )}
        {pageContextPivot === "holdings" && (
          <Holdings dataManager={dataManager} />
        )}
        {pageContextPivot === "allocation" && (
          <Allocation dataManager={dataManager} />
        )}
        {pageContextPivot === "trends" && <Trends dataManager={dataManager} />}
        {pageContextPivot === "pointInTime" && (
          <PointInTime dataManager={dataManager} />
        )}
      </Box>
    </article>
  );
}

const Header = ({ currentListId, changeList }: HeaderProps) => {
  return (
    <header data-dojo-attach-point="widgetHeader">
      <header className="tPageAnalysisListHeader">
        <ListSelect currentListId={currentListId} changeList={changeList} />
      </header>
    </header>
  );
};
