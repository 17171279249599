import { MenuItem, Select } from "@mui/material";

type TCRSelectType = {
  options:
    | {
        label: string;
        value: string;
      }[]
    | string[];
  defaultValue: any;
  setOutterState: Function;
  disabled?: boolean;
};
const ReportSelect = ({ options, defaultValue, setOutterState, disabled = false }: TCRSelectType) => {
  return (
    <Select disabled={ disabled} size="small" value={defaultValue} onChange={(e) => setOutterState(e.target.value)}>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {typeof option === "string" ? option : option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ReportSelect;
