import { ChangeEvent, useCallback, useEffect, useState } from "react";

type RatingCheckboxProps = {
  exclueRating?: ("A" | "B" | "C" | "D")[];
  stateGetter: (state) => any;
  initState?: { A?: boolean; B?: boolean; C?: boolean; D?: boolean };
  isDisabled?: boolean;
};

export default function RatingCheckbox({
  exclueRating,
  stateGetter,
  isDisabled = false,
  initState = { A: false, B: false, C: false, D: false },
}: RatingCheckboxProps) {
  const [fields, setFields] = useState(["A", "B", "C", "D"]);

  useEffect(() => {
    if (exclueRating != null && exclueRating.length) {
      setFields(
        fields.filter(
          (field) => !exclueRating.includes(field as "A" | "B" | "C" | "D")
        )
      );
    }
  }, [exclueRating, fields]);

  const [state, setState] = useState({
    A: initState?.A ?? false,
    B: initState?.B ?? false,
    C: initState?.C ?? false,
    D: initState?.D ?? false,
  });

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: "A" | "B" | "C" | "D") => {
      setState({ ...state, [key]: e.target.checked });
      stateGetter({ ...state, [key]: e.target.checked });
    },
    [state, stateGetter]
  );

  return (
    <div className="filter-bar__field-widget">
      <ul className="filter filter--horizontal" style={{ display: "flex" }}>
        {fields.map((field: any) => (
          <li
            key={`r_${field}`}
            className="filter__item"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor={`r_${field}`}>
              <strong className={`filter__label rate rate--${field}`}>
                {field}
              </strong>
            </label>
            <div>
              <input
                disabled={isDisabled}
                type="checkbox"
                onChange={(e) => onChangeHandler(e, field)}
                id={`r_${field}`}
                name={`r_${field}`}
                checked={state[field]}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
