import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { UI_CONSTRAINTS_TYPE } from "../../../../js/app/pages/strategies/builder/editors/Advanced/utils";
import { WhiteListPanel } from "../../../../js/app/pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/WhiteListPanel/WhiteListPanel";
import IndexEdit from "./IndexEdit";

type BenchmarkProps = {
  strategyDispatch: Function;
  input: string | null;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function Benchmark({
  strategyDispatch,
  input,
  UI_CONSTRAINTS,
}: BenchmarkProps) {
  const { t } = useTranslation();
  const [innerState, setInnerState] = useState<any>(null);

  const [indexEditDisabled, setIndexEditDisabled] = useState(true);

  const initBlendedBenchmark = useMemo(() => {
    if (input?.includes(":")) {
      let code = input.split(":");
      return parseInt(code[1]);
    }
    return null;
  }, [input]);

  useEffect(() => {
    if (input?.includes(":")) {
      //blended benchmark
      let code = input.split(":");
      let temp = {
        radio: "blended",
        value: code[1],
      };
      setInnerState(temp);
    } else if (input === "TRENDRATING_NEUTRAL_STRATEGY") {
      //neutral
      let temp = {
        radio: "neutral",
        value: "TRENDRATING_NEUTRAL_STRATEGY",
      };
      setInnerState(temp);
    } else if (input === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED") {
      //neutral
      let temp = {
        radio: "neutral_equal_weighted",
        value: "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED",
      };
      setInnerState(temp);
    } else if (input == null) {
      let temp = {
        radio: innerState?.radio,
        value: input,
      };
      setInnerState(temp);
    } else {
      //index
      let temp = {
        radio: "index",
        value: input,
      };
      setInnerState(temp);
    }
  }, [innerState?.radio, input]);

  useEffect(() => {
    if (innerState?.radio) {
      if (innerState.radio !== "index") {
        setIndexEditDisabled(true);
      } else {
        setIndexEditDisabled(false);
      }
    }
  }, [innerState?.radio]);

  return (
    <Card sx={{ height: "100%", flex: 2, overflow: "unset" }}>
      <CardContent>
        <FormControl sx={{ width: "100%" }}>
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <Typography variant="subtitle1">
              {t("page_builder_field_benchmark_label")}
            </Typography>
          </FormLabel>
          <RadioGroup
            onChange={(e) => {
              setInnerState({ radio: e.target.value, value: null });
              if (e.target.value === "neutral") {
                strategyDispatch({
                  type: "SET_BENCHMARK",
                  payload: "TRENDRATING_NEUTRAL_STRATEGY",
                });
              } else if (e.target.value === "neutral_equal_weighted") {
                strategyDispatch({
                  type: "SET_BENCHMARK",
                  payload: "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED",
                });
              } else {
                setInnerState({ radio: e.target.value, value: null });

                if (input != null) {
                  strategyDispatch({
                    type: "SET_BENCHMARK",
                    payload: null,
                  });
                }
              }
            }}
            value={innerState?.radio ?? "none"}
          >
            {UI_CONSTRAINTS.strategy.benchmarks.index.enabled && (
              <div style={{ display: "flex" }}>
                <Radio
                  value={"index"}
                  sx={{
                    color: "#2a7092",
                    "&.Mui-checked": { color: "#2a7092" },
                    alignItems: "baseline",
                  }}
                  size="small"
                  id="indexRadio"
                />
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label style={{ cursor: "pointer" }} htmlFor="indexRadio">
                    {t("page_builder_field_index_label")}
                  </label>
                  {!indexEditDisabled && innerState?.radio === "index" && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <IndexEdit
                        value={/*innerState.value*/ input ?? null}
                        onSelectIndex={(e) => {
                          if (e !== input) {
                            let temp = { ...innerState, value: e };
                            setInnerState(temp);
                            strategyDispatch({
                              type: "SET_BENCHMARK",
                              payload: e,
                            });
                          }
                        }}
                      />
                      <PredefinedBenchmarks
                        value={innerState.value}
                        listOfPredefinedBenchmarks={
                          UI_CONSTRAINTS.strategy.benchmarks.index.list
                        }
                        onSelect={(value) => {
                          strategyDispatch({
                            type: "SET_BENCHMARK",
                            payload: value,
                          });
                          setInnerState(value);
                        }}
                      />
                    </Box>
                  )}
                </span>
              </div>
            )}
            {UI_CONSTRAINTS.strategy.benchmarks.neutral.enabled && (
              <div>
                <Radio
                  id="neutralRadio"
                  value="neutral"
                  size="small"
                  sx={{
                    color: "#2a7092",
                    "&.Mui-checked": { color: "#2a7092" },
                  }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="neutralRadio">
                  {t("page_builder_field_neutral_label")}
                </label>
              </div>
            )}

            {UI_CONSTRAINTS.strategy.benchmarks.neutralEqual.enabled && (
              <div>
                <Radio
                  id="equal_weighted"
                  value="neutral_equal_weighted"
                  size="small"
                  sx={{
                    color: "#2a7092",
                    "&.Mui-checked": { color: "#2a7092" },
                  }}
                />
                <label style={{ cursor: "pointer" }} htmlFor="equal_weighted">
                  Neutral strategy equal weighted
                </label>
              </div>
            )}

            {UI_CONSTRAINTS.strategy.benchmarks.blended.enabled && (
              <div style={{ display: "flex" }}>
                <Radio
                  id="blendedRadio"
                  value="blended"
                  size="small"
                  sx={{
                    color: "#2a7092",
                    "&.Mui-checked": { color: "#2a7092" },
                  }}
                />
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label style={{ cursor: "pointer" }} htmlFor="blendedRadio">
                    Blended Benchmark
                  </label>
                  {innerState?.radio === "blended" && (
                    <WhiteListPanel
                      showBasket={false}
                      closeIcon={true}
                      setWhiteListValue={(e) => {
                        if (innerState.radio === "blended") {
                          strategyDispatch({
                            type: "SET_BENCHMARK",
                            payload: `COLLECTION:${e}`,
                          });
                        }
                      }}
                      initValue={initBlendedBenchmark}
                    />
                  )}
                </span>
              </div>
            )}

            <div>
              <Radio
                id="noneRadio"
                value="none"
                size="small"
                sx={{ color: "#2a7092", "&.Mui-checked": { color: "#2a7092" } }}
              />
              <label style={{ cursor: "pointer" }} htmlFor="noneRadio">
                None
              </label>
            </div>
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}

type PredefinedBenchmarksType = {
  listOfPredefinedBenchmarks: string[];
  onSelect: Function;
  value: string | null;
};
const PredefinedBenchmarks = ({
  value,
  listOfPredefinedBenchmarks,
  onSelect,
}: PredefinedBenchmarksType) => {
  const environment = useEnvironment();
  const http = useMemo(() => environment.get("http"), [environment]);

  const [securities, setSecurities] = useState<any>(null);
  useEffect(() => {
    http["instruments"]
      .fetch({
        properties: [{ date: null, property: "name" }],
        type: "security",
        symbols: listOfPredefinedBenchmarks,
      })
      .then((response) => setSecurities(response.data));
  }, [http, listOfPredefinedBenchmarks]);

  const defaultValue = useMemo(() => {
    if (value != null && listOfPredefinedBenchmarks.includes(value)) {
      return value;
    }
    return null;
  }, [listOfPredefinedBenchmarks, value]);

  return securities ? (
    <Box>
      <FormControl>
        <RadioGroup
          onChange={(e) => onSelect(e.target.value)}
          value={defaultValue}
        >
          {securities.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.symbol}
              control={<Radio size="small" />}
              label={item.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  ) : null;
};
