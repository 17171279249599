

export default function PinnedDialogHelp() {
  return (
    <>
      <p>
        Follow alerts related to your markets of interest. Select your favorite
        markets and sectors.
        <br />
        <br />
      </p>
      <p>
        These will be shown at the top of the page.
        <br />
        <br />
      </p>
      <p>
        It is possible to add or remove relevant markets, shown on alerts page.
      </p>
    </>
  );
}
