/* eslint-disable no-template-curly-in-string */

export const rebalanceInfo = (value, t, format) => {
  var formatDateOptions = {
    format: ["Y", "m", "D"],
    notAvailable: {
      input: null,
      output: "",
    },
    separator: "-",
  };
  var formatted = t("c_unknown");
  var replacements: any = null;

  switch (value["status"]) {
    case "overdue": {
      replacements = {
        date: format.custom("date", {
          options: formatDateOptions,
          output: "HTML",
          value: value["previous"],
          valueHelper: null,
        }),
        days: value["today"] - value["previous"],
      };
      const str = t("w_systematic_product_rebalance_overdue");
      formatted = str.replace("${date}", replacements.date);
      formatted = formatted.replace("${days}", replacements.days);
      break;
    }
    case "rebalance": {
      formatted = t("w_systematic_product_rebalance_today");
      break;
    }
    case "rebalanced": {
      if (value["next"] - value["today"] !== 0) {
        replacements = {
          date: format.custom("date", {
            options: formatDateOptions,
            output: "HTML",
            value: value["next"],
            valueHelper: null,
          }),
          days: value["next"] - value["today"],
        };
        const str = t("w_systematic_product_rebalance_next");
        formatted = str.replace("${date}", replacements.date);
        formatted = formatted.replace("${days}", replacements.days);
      } else {
        formatted = t("w_systematic_product_rebalanced_today");
      }

      break;
    }
  }
  return formatted;
};

export const rebalance = (value, t) => {
  var formatted = "";

  switch (value) {
    case "WEEKLY": {
      formatted = t("page_builder_field_rebalance_label_05_days");
      break;
    }
    case "MONTHLY": {
      formatted = t("page_builder_field_rebalance_label_20_days");
      break;
    }
    case "QUARTERLY": {
      formatted = t("page_builder_field_rebalance_label_60_days");
      break;
    }
    default: {
      formatted = t("common_unknown");
    }
  }
  return formatted;
};
export const performance = (value, t) => {
  var formatted = "";
  switch (value) {
    case "NONE": {
      formatted = t("page_builder_field_performance_label_price");
      break;
    }
    case "REBALANCE": {
      formatted = t("page_builder_field_performance_label_total_return");
      break;
    }
    default: {
      formatted = t("common_unknown");
    }
  }
  return formatted;
};
