import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Box, Divider, IconButton } from "@mui/material";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

type SimpleTextEditorProps = {
  minHeight?: string | number;
  initVal?: string;
};

const SimpleTextEditor = forwardRef(({ minHeight, initVal }: SimpleTextEditorProps, ref) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);

  const updateFormattingState = useCallback(() => {
    setIsBold(document.queryCommandState("bold"));
    setIsItalic(document.queryCommandState("italic"));
    setIsUnderlined(document.queryCommandState("underline"));
  }, []);

  const executeCommand = useCallback((command: string) => {
    document.execCommand(command);
    updateFormattingState();
  }, [updateFormattingState]);

  const getContent = useCallback(() => {
    return editorRef.current?.innerHTML || "";
  }, []);

  useEffect(() => {
    if (initVal) {
      editorRef.current!.innerHTML = initVal;
    }
  }, [initVal]);

  useImperativeHandle(ref, () => ({
    getEditorValue: getContent,
    setEditorValue: (value: string) => {
      if (editorRef.current) {
        editorRef.current.innerHTML = value;
      }
    }
  }), [getContent]);

  const handlePaste = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    document.execCommand("insertHTML", false, paste);
  }, []);

  const handleSelectionChange = useCallback(() => {
    updateFormattingState();
  }, [updateFormattingState]);

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [handleSelectionChange]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      borderRadius={1}
      boxShadow={3}
    >
      <Box p={1} display="flex" gap={1}>
        <IconButton
          onClick={() => executeCommand("bold")}
          style={{ backgroundColor: isBold ? 'lightgray' : 'transparent' }}
        >
          <FormatBoldIcon sx={{ fontSize: "0.9vw" }} />
        </IconButton>
        <IconButton
          onClick={() => executeCommand("italic")}
          style={{ backgroundColor: isItalic ? 'lightgray' : 'transparent' }}
        >
          <FormatItalicIcon sx={{ fontSize: "0.9vw" }} />
        </IconButton>
        <IconButton
          onClick={() => executeCommand("underline")}
          style={{ backgroundColor: isUnderlined ? 'lightgray' : 'transparent' }}
        >
          <FormatUnderlinedIcon sx={{ fontSize: "0.9vw" }} />
        </IconButton>
      </Box>
      <Divider />
      <Box
        p={1}
        minHeight={minHeight ?? 0}
        maxHeight="100%"
        overflow="hidden"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            fontSize: "inherit",
            overflow: "auto",
          }}
          contentEditable
          ref={editorRef}
          onPaste={handlePaste}
        >
          type here...
        </div>
      </Box>
    </Box>
  );
});

export default SimpleTextEditor;
