import { useTranslation } from "react-i18next";
import { SystematicProductFormatter } from "../../../../../trendrating/app/formatter/SystematicProduct";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Product } from "../../../storage/SystematicPortfoliosStorage";
import { ErrorBoundary } from "../../../../../ErrorBoundary";
import { Typography } from "@mui/material";
import { TDate } from "../../../../../trendrating/date/TDate";

type ProductSummaryProps = {
  product: Product;
};

export function ProductSummary({ product }: ProductSummaryProps) {
  const { t } = useTranslation();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const [productSummary, setProductSummary] = useState<any>();
  const formatter = useMemo(
    () => new SystematicProductFormatter(appSetup),
    [appSetup]
  );

  const getProductSummary = useCallback(async () => {
    const productObj = await product.summary();

    setProductSummary(productObj.summary);
  }, [product]);

  useEffect(() => {
    if (product) {
      getProductSummary();
    }
  }, [getProductSummary, product]);

  const updateTime = useMemo(() => {
    if (productSummary && productSummary?.dateEdit) {
      return TDate.dateToDays(new Date(productSummary.dateEdit));
    }
    return null;
  }, [productSummary]);

  return (
    <ErrorBoundary
      fallback={<Typography>Cannot retrive info about the product</Typography>}
    >
      {productSummary ? (
        <div className="wSystematicProductSummary">
          {productSummary.closingDate && (
            <p data-dojo-attach-point="nodeRelevant">
              <span className="wSystematicProductSummary-price">
                {formatter.price(productSummary.price)}
              </span>
              {t("w_systematic_product_list_item_summary_closing_date")}{" "}
              <span
                className="wSystematicProductSummary-closingDate"
                data-dojo-attach-point="nodeClosingDate"
              >
                {formatter.date(productSummary["closingDate"])}
              </span>
            </p>
          )}
          <table className="wSystematicProductSummary-details">
            <tbody>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("c_benchmark")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeBenchmark"
                >
                  {productSummary["benchmark"] != null
                    ? formatter.benchmark(productSummary.benchmark)
                    : t("c_not_set")}
                </td>
              </tr>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("c_rebalance")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeRebalance"
                >
                  {productSummary.rebalance
                    ? formatter.rebalance(productSummary.rebalance)
                    : t("c_not_set")}
                </td>
              </tr>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("c_currency")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeCurency"
                >
                  {productSummary.currency != null
                    ? formatter.currency(productSummary.currency)
                    : t("c_not_set")}
                </td>
              </tr>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("c_date_edit")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeDateEdit"
                >
                  {productSummary.dateEdit != null
                    ? formatter.date( updateTime/*productSummary.dateEdit*/)
                    : t("c_not_set")}
                </td>
              </tr>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("page_builder_field_performance_label")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodePerformance"
                >
                  {productSummary.performance != null
                    ? formatter.strategyPerformance(productSummary.performance)
                    : t("c_not_set")}
                </td>
              </tr>
              <tr>
                <th className="wSystematicProductSummary-detailsTh">
                  {t("c_auto_rebalance")}
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeAutoRebalance"
                >
                  {productSummary.autorebalance != null &&
                  productSummary.autorebalance === true
                    ? t("c_on")
                    : t("c_off")}
                </td>
              </tr>
              <tr data-dojo-attach-point="TR_ExpenseRatio">
                <th className="wSystematicProductSummary-detailsTh">
                  Management fees
                </th>
                <td
                  className="wSystematicProductSummary-detailsTd"
                  data-dojo-attach-point="nodeExpenseRatio"
                >
                  {productSummary.expenseRatio != null
                    ? formatter.formatPercentage(productSummary.expenseRatio)
                    : ("-")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
}
