type Props = {
    titleJSX: JSX.Element;
    children: React.ReactChild;
    isOpen: boolean;
};

export function Accordion({ titleJSX, children, isOpen }: Props) {
    return (
        <div className="accordion-parent-element">
            <div className="accordion-title-wrapper">{titleJSX}</div>
            {isOpen && <>{children}</>}
        </div>
    );
}
