export type AdvancedType = {
  allocation: {
    weightInCashMin: number;
    weightInCashMax: number;
    weightCappingPeer: null | {
      peerLevel: "Country" | "Region" | "Area" | "3 Sector" | "1 Industry";
      weightCappedMax: number;
      weightCappedMethod: null | "SUBSTITUTION";
    };
    weightCappingSecurity: null;
  };
  backtesting: {
    inceptionDate: string;
    inceptionValue: number;
    period: {
      type: "YEAR" | "DAY";
      value: null | 5 | 6 | 7 | 8 | 10 | 12 | 15;
    };
  };
  hedging: null | {
    constraints: {
      hedgingStrategy: "HEDGING_FULL" | "HEDGING_SMART" | "HEDGING_ADVANCED";
      value: [];
    };
    instrument: string;
    leverage: number;
  };
  holding: null | RuleType[];
  ranking: null | RuleType[];
  selection: null | RuleType[];
  strategy: {
    blacklist: any;
    benchmark: null | string;
    currency: string;
    holdings: number;
    performance: "NONE" | "REBALANCE";
    rebalance: "05_DAYS" | "20_DAYS" | "60_DAYS";
  };
  universe: {
    screening: any;
    selection: null | RuleType[];
    trimOutliers: boolean;
    whiteList: any;
    blacklist: string[];
  };
  weighting: {
    rotation: any;
    smartBeta: any;
    weightingSchema: any;
    weightingSchemaExistingPositions: any;
  };
  askBeforeInsightsRedirect: boolean;
};
export type RuleType = {
  function: string;
  functionParams: any;
  property: string;
  operator: string;
  operatorParams: any;
};

type ActionType = {
  type:
    | "SET_STRATEGY"
    | "SET_UNIVERSE"
    | "SET_SELECTION"
    | "SET_RANKING"
    | "SET_HOLDING"
    | "SET_WEIGHTING"
    | "SET_ALLOCATION"
    | "SET_BACKTESTING"
    | "SET_HEDGING"
    | "RESET"
    | "SET_ASKBEFOREINSIGHTSREDIRECT";
  payload: any;
};
export const advancedReducer = (draft: AdvancedType, action: ActionType) => {
  switch (action.type) {
    case "SET_STRATEGY":
      draft.strategy = action.payload;
      break;
    case "SET_UNIVERSE":
      draft.universe = action.payload;
      break;
    case "SET_SELECTION":
      draft.selection = action.payload;
      break;
    case "SET_RANKING":
      draft.ranking = action.payload;
      break;
    case "SET_HOLDING":
      draft.holding = action.payload;
      break;
    case "SET_WEIGHTING":
      draft.weighting = action.payload;
      break;
    case "SET_ALLOCATION":
      draft.allocation = action.payload;
      break;
    case "SET_BACKTESTING":
      draft.backtesting = action.payload;
      break;
    case "SET_HEDGING":
      draft.hedging = action.payload;
      break;
    case "RESET":
      return action.payload;

    case "SET_ASKBEFOREINSIGHTSREDIRECT":
      draft.askBeforeInsightsRedirect = action.payload;
      break;
  }
};
