import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";

type Props = {
  onChangeLanding: Function;
  options: { label: string; value: string }[];
  value: string;
};

export default function Home({ onChangeLanding, options, value }: Props) {
  return (
    <Card sx={{boxShadow:3}}>
      <CardContent sx={{pb:"16px !important"}}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="subtitle1"><strong> After login, go to</strong></Typography>
          <FormControl>
            <RadioGroup
              defaultValue={value}
              onChange={(e) => onChangeLanding(e.target.value)}
              row
            >
              {options.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  label={<Typography>{item.label}</Typography>}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
}
