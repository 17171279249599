import { useEffect, useState } from "react";
import Modal from "../../../../../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import Search from "../../../../../../widgets/ReactSearch/Search";
import BlacklistList from "./BlacklistList";
import styles from "./BlackList.module.scss";

type BlackListProps = {
  blacklist: any[];
  setBlacklist: (value) => void;
  children?: JSX.Element | JSX.Element[];
};
export type Item = {
  symbol: string;
  ticker: string;
  name: string;
  exchange: string;
  type: string;
};

const getInstrumentData = async (api, list, setState) => {
  const params: any = {
    properties: ["symbol", "name", "ticker", "exchange", "type"],
    symbols: list ?? [],
    type: "security",
  };

  params["properties"] = params.properties.map((property) => ({
    date: null,
    property,
  }));

  const response = await api.fetch(params);

  setState(response?.data ?? []);
};

export default function BlackList({
  blacklist,
  setBlacklist,
  children,
}: BlackListProps) {
  const [isBlackListModalVisible, setIsBlackListModalVisible] = useState(false);
  const [blacklistItems, setBlacklistItems] = useState<any[]>([]);
  const [state, setState] = useState(blacklist);
  const environment = useEnvironment();
  // --------
  const apiInstrument = environment.get("http").instruments;
  useEffect(() => {
    try {
      getInstrumentData(apiInstrument, state, setBlacklistItems);
    } catch (error) {
      console.log(error);
    }
  }, [apiInstrument, state]);

  // --------

  useEffect(() => {
    setState(blacklist);
  }, [blacklist]);

  const updateList = (itemName) => {
    const newList = blacklistItems?.filter((item) => item.name !== itemName);
    setState(newList.map((item) => item.symbol));
  };

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          alignItems: "end",
          cursor: "pointer",
          color: "#2a7092",
          width: "auto",
        }}
      >
        {!children ? (
          <>
            <span
              onClick={() => {
                setIsBlackListModalVisible(true);
              }}
              style={{ fontSize: "0.8vw" }}
            >
              Exclusion list
            </span>
            <i style={{ fontSize: "0.8vw" }} className="i-right-lite"></i>
          </>
        ) : (
          <div
            onClick={() => {
              setIsBlackListModalVisible(true);
            }}
          >
            {children}
          </div>
        )}
      </div>
      {isBlackListModalVisible && (
        <Modal
          closeIcon={false}
          bodyCustomClass={styles.modalBody}
          customCss={{ maxWidth: "20%", minWidth: 330, overflow: "unset" }}
          headerConfig={{
            headerContent: "Exclusion list",
            hasBorder: false,
            headerSubtitle:
              "Securities that will not be included in the universe",
          }}
          onClose={() => {
            setBlacklist(state);
            setIsBlackListModalVisible(false);
          }}
          buttonsEnalbed={true}
          buttons={[
            {
              name: "Set",
              callback: () => {
                setBlacklist(state);
                setIsBlackListModalVisible(false);
              },
              class: ["tFormButton--primary", "tFormButton"],
            },
          ]}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <BlacklistList updateList={updateList} data={blacklistItems} />

            <br />
            <p>&nbsp;Add security to the exclusion list</p>
            <Search
              lookUp="SECURITY"
              cleanOnSelect={true}
              hasRecents={true}
              showInstrumentInfoOnSelect={false}
              onSelectInstrument={(e) => {
                let arrTemp = state ? [...state] : [];
                if (e) {
                  arrTemp.push(e.symbol);
                  arrTemp = [...new Set(arrTemp)];
                  setState(arrTemp);
                }
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
