import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../../components/Modal/Modal";
import { InfoTooltip } from "../../InfoTooltip";
import PinnedContent from "./PinnedDialog/PinnedContent";
import { PinnedList } from "./PinnedList";
import styles from "./Pinned.module.scss";

type PinnedProps = {
  data: any;
  timeframe: string;
  onSavePreferences: Function;
};

export function Pinned({ data, timeframe, onSavePreferences }: PinnedProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [pinnedDialogIsOpen, setPinnedDialogIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleAccordionState = () => {
    setIsOpen(!isOpen);
  };

  const handleEditButtonClick = () => {
    setPinnedDialogIsOpen(true);
  };

  const closeBtnHandler = () => {
    setPinnedDialogIsOpen(false);
  };
  return (
    <>
      <div
        className="tPinnedObjects-accordionTitle"
        onClick={handleAccordionState}
      >
        <h2>
          Selected Markets
          <InfoTooltip
            text="Configure this section with the markets of your interest.  Stay on top of developments and be alerted on  new investment ideas  ( upgrades) and stocks to avoid ( downgrades )."
            callToAction="Bring your portfolio risk management to the next level"
            title="Selected markets"
            actionInfo="To configure with the desired Markets, please click on the ‘pencil icon’ on the right and add your selected countries."
          />
        </h2>
        <span
          className={`${
            isOpen ? "i-down" : "i-up"
          } tPinnedObjects-accordionIcon tPinnedObjects-accordionIcon--down`}
        ></span>
      </div>
      {isOpen && (
        <>
          <PinnedList data={data} timeframe={timeframe} />
          <button
            className="tFormButton tPinnedObjects-preferences--1"
            onClick={handleEditButtonClick}
          >
            <span className="i-edit"></span>
            <span className="a11y">{t("preferences")}</span>
          </button>
        </>
      )}
      {pinnedDialogIsOpen && (
        <Modal
          bodyCustomClass={styles.modalBody}
          onClose={(e) => {
            setPinnedDialogIsOpen(false);
          }}
          hasOverlay={true}
          headerConfig={{
            headerContent: "Relevant Markets",
            hasBorder: true,
          }}
          closeIcon={true}
        >
          <PinnedContent
            onCloseBtnHandler={closeBtnHandler}
            onSavePreferences={(event, state) =>
              onSavePreferences(event, state)
            }
          />
        </Modal>
      )}
    </>
  );
}
