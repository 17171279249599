import { useMemo } from "react";
import { IllustrationTCR } from "../../../../../../../../components/IllustrationTCR/IllustrationTCR";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";

type TcrProps = {
  tcrToday;
  tcrYesterday;
};

export function Tcr({ tcrToday, tcrYesterday }: TcrProps) {
  const format = useFormatter();

  const tcrHTML = useMemo(() => {
    if (tcrToday != null || tcrYesterday != null) {
      const innerHTML: any = [];
      if (tcrToday !== tcrYesterday) {
        innerHTML.push(format.tcr(tcrYesterday, "HTML"));
        innerHTML.push('<span class="tTableAlerts-arrow i-arrow-r"></span>');
      }
      innerHTML.push(format.tcr(tcrToday, "HTML"));
      innerHTML.push("</div>");
      return innerHTML.join("");
    }

    return "-";
  }, [format, tcrToday, tcrYesterday]);

  return (
    <div style={{ display: "flex", gap: "8px", flex: 1 }}>
      <div style={{ display: "flex", flex: 2 }}>
        <div data-dojo-attach-point="tcrIllustrationNode">
          <IllustrationTCR />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <h1 className="tAnalysisOverview-title">
          <abbr title="Trend Capture Rating">TCR</abbr>
        </h1>
        <p
          style={{ alignSelf: "center", justifySelf: "center", flex: 1 }}
          className="tAnalysisOverview-tcr"
          dangerouslySetInnerHTML={{ __html: tcrHTML }}
        ></p>
      </div>
    </div>
  );
}
