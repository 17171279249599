import { deepClone } from "../../deepClone";

/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/core/UtilsObject
 * @summary Extended Select to manage nested options
 *
 */

/**
 * Clones an array and returns iTarget concatenated with iSource
 *
 * @param {array} iTarget - the array target
 * @param {array} iSource - the array to be cloned
 *
 * @returns {array} iTarget: the array target
 */
export function cloneArray(iTarget: any[], iSource: any[]) {
    return iTarget.concat(deepClone(iSource));
}

/**
 * Clones an object literal and returns iTarget with all iSource
 * enumerable own properties listed in iTarget
 *
 * @param {array} iTarget - the array target
 * @param {array} iSource - the array to be cloned
 *
 * @returns {array} iTarget: the array target
 */
export function cloneObjectLiteral(iTarget: any, iSource: any) {
    var clonedITarget = deepClone(iTarget);
    for (const property in clonedITarget) {
        if (iSource.hasOwnProperty(property)) {
            clonedITarget[property] = iSource[property];
        }
    }
    return clonedITarget;
}

/**
 * Sort an array object by property (case insensitive)
 *
 * @param {array} arrayOfObject - the array to be sorted
 * @param {string} property - the property used to sort
 * @param {boolean} descending - if true, sort is descending. Default
 *   false
 *
 * @returns {void} it modifies the content of arrayOfObject
 */
export function sortBy(
    arrayOfObject: any[],
    property: string,
    descending: boolean = false
) {
    arrayOfObject.sort(function (a, b) {
        var itemA =
            typeof a[property] === "string"
                ? a[property].toLowerCase()
                : a[property];
        var itemB =
            typeof b[property] === "string"
                ? b[property].toLowerCase()
                : b[property];

        if (itemA > itemB) {
            return descending ? -1 : 1;
        }

        if (itemA < itemB) {
            return descending ? 1 : -1;
        }

        return 0;
    });
}

// /**
// * Add (patch) a property - value to the objectLiteral
// *
// * @param {object} objectLiteral - the object to be patched
// * @param {string} property - property to be added/updated
// * @param {mixed} value - the of the property
// */
// patchObjectLiteral: function(objectLiteral, property, value) {
//     if (objectLiteral.hasOwnProperty(property)) {
//         objectLiteral[property] = value;
//     }
// }
