import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import { useEffect, useMemo, useState } from "react";
import { fromAdaptedToExploded } from "../../utils";

type OptionRangeQuantileProps = {
  labelForRangeQuantile: string;
  meta: any;
  options: any[];
  value: any;
  outterStateSetter: Function;
};

// function valuetext(value: number) {
//   return `${value}°C`;
// }

// function rebase(V, minL, maxL, minX, maxX) {
//   return minX + ((maxX - minX) * (V - minL)) / (maxL - minL);
// }

const prepareLabel = (index, optionsLength, item, labelForRangeQuantile) => {
  if (index === 0) {
    return (
      <span>
        {item.label}
        <br />
        {`(Highest)`}
      </span>
    );
  } else if (index === optionsLength - 1) {
    return (
      <span>
        {item.label}
        <br />
        {`(Lowest)`}
      </span>
    );
  } else {
    return item.label;
  }
};

export default function OptionRangeQuantile({
  meta,
  options,
  labelForRangeQuantile,
  value,
  outterStateSetter,
}: OptionRangeQuantileProps) {
  const selectables = useMemo(
    () =>
      options.map((item, index) => ({
        value: 100 - index * (100 / options.length),
        label: prepareLabel(index, options.length, item, labelForRangeQuantile),
        configValue: parseInt(item.value),
      })),
    [labelForRangeQuantile, options]
  );

  const getConfigValueFromSliderResponse = (arr) => {
    if (arr[0] === arr[1]) {
      const resp = selectables.filter((item) => item.value === arr[0])[0];
      return [resp.configValue, resp.configValue];
    } else {
      const resp1 = selectables.filter((item) => item.value === arr[0])[0];
      const resp2 = selectables.filter((item) => item.value === arr[1])[0];
      return [resp1.configValue, resp2.configValue];
    }
  };

  const defaultValueForSlider = useMemo(
    () => fromValueToDefaultValue(value, selectables),
    [selectables, value]
  );

  const fromInternalValueToNormalizedValue = (arr) => {
    if (arr[0] === arr[1]) {
      return [{ ge: arr[0], le: arr[0] }];
    } else if (arr[0] > arr[1]) {
      return [{ ge: arr[1], le: arr[0] }];
    } else {
      return [{ ge: arr[0], le: arr[1] }];
    }
  };

  const [sliderValue, setSliderValue] = useState<any>(defaultValueForSlider);
  useEffect(() => {
    setSliderValue(defaultValueForSlider);
  }, [defaultValueForSlider]);

  // const styledSlider = styled(Slider)({
  //   color: '##2a7092',
  // })

  return (
    <div style={{ marginTop: 30 }}>
      {options.length > 0 && (
        <Stack sx={{ height: 300 }} spacing={1} direction="row">
          <Slider
            getAriaLabel={() => "Temperature"}
            orientation="vertical"
            value={sliderValue}
            valueLabelDisplay="off"
            marks={selectables}
            step={100 / selectables.length}
            max={selectables[0].value}
            sx={{ color: "#2a7092" }}
            min={selectables[selectables.length - 1].value}
            onChange={(e, v) => setSliderValue(v)}
            onChangeCommitted={(e, v) => {
              let temp = fromInternalValueToNormalizedValue(getConfigValueFromSliderResponse(v));
              outterStateSetter(temp);
              setSliderValue(v);
            }}
          />
        </Stack>
      )}
      {/*!! if options is empty*/}
      {options.length <= 0 && <>No options</>}
    </div>
  );
}

//utility

const fromValueToDefaultValue = (value: Object, selectables: any[]) => {
  /*
   * this function take the value passet from the outside
   * and return an arr of numbers required by slider's "defaultValue"
   */

  const vl = fromAdaptedToExploded(value, selectables.length);

  const start = parseInt(vl[0]["ge"]);
  const end = parseInt(vl[0]["le"]);
  let r1 = selectables.filter((item) => item.configValue === start)[0];
  let r2 = selectables.filter((item) => item.configValue === end)[0];
  return [r1.value, r2.value];
};
