import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { decodePeerId, encodePeerId } from "../../../../../api/utils";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { useResizer } from "../../../../../hooks/useResizer";
import { useTaxonomyByType } from "../../../../../hooks/useTaxonomyByType";
import { config } from "../../../config-ts";
import { DirectDispersionReport } from "../actions/DirectDispersionReport";
import { AnalysisMarketsETFHelper } from "./AnalysisMarketsETFHelper";
import AnalysisMarketsEtfTemplate from "./PageTemplate/AnalysisMarketsEtfTemplate";
import { BreadcrumbsRow } from "./widgets/BreadcrumbsRow/BreadcrumbsRow";
import { ContentETFMarkets } from "./widgets/ContentETFMarkets/ContentETFMarkets";
import { SelectorETFMarkets } from "./widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";
import { PeerPresetsRow } from "./widgets/PeerPresetsRow/PeerPresetsRow";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

type ControlsETFMarket = {
  timeframe: 0 | 4 | 19;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "4 Subsector"
    | "Country";
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  xDim: string;
  yDim: string;
  zDim: string;
};

export type ActionsETFMarketsControls =
  | { type: "SET_TIMEFRAME"; payload: ControlsETFMarket["timeframe"] }
  | { type: "SET_SEGMENT"; payload: ControlsETFMarket["segment"] }
  | { type: "SET_ANALYTIC"; payload: ControlsETFMarket["analytic"] }
  | {
      type: "CHANGE_PEER";
      payload: {
        xDim?: ControlsETFMarket["xDim"];
        yDim?: ControlsETFMarket["yDim"];
        zDim?: ControlsETFMarket["zDim"];
        segment?: ControlsETFMarket["segment"];
        analytic?: ControlsETFMarket["analytic"];
      };
    }
  | { type: "SET_Z_DIM"; payload: ControlsETFMarket["zDim"] };

type Preset = {
  label: string;
  etfClass: string;
  etfgeo: string;
  groupBy: {
    dimension: string;
    transform: {
      function: string;
      params: {
        level:
          | "WWW"
          | "1 Industry"
          | "3 Sector"
          | "4 Subsector"
          | "Area"
          | "Region"
          | "Country";
      };
    };
  };
  subtype?: string;
};

export type DispersionViewActionMarketsETF =
  | {
      type: "SET_PERFORMANCE";
      payload: "3_months" | "6_months" | "12_months";
    }
  | { type: "SET_TRIM_OUTLIERS"; payload: boolean }
  | { type: "SET_INTERVALS"; payload: 4 | 10 | 20 }
  | { type: "SET_PEER_ID"; payload: string | undefined }
  | { type: "RESET_TO_DEFAULT" };

export type DispersionByViewActionMarketsETF =
  | {
      type: "SET_PERFORMANCE";
      payload: "3_months" | "6_months" | "12_months";
    }
  | { type: "SET_TRIM_OUTLIERS"; payload: boolean }
  | { type: "SET_INTERVALS"; payload: 4 | 10 | 20 };

export type SecuritiesViewActionMarketsETF =
  | { type: "SET_SORT"; payload: { field: string; rev: boolean } }
  | { type: "SET_PAGE_TO_VIEW"; payload: number }
  | {
      type: "SET_ALERT";
      payload:
        | null
        | "Any"
        | "upgrades_today"
        | "upgrades_last_5_days"
        | "upgrades_last_20_days"
        | "upgrades_last_60_days"
        | "downgrades_today"
        | "downgrades_last_5_days"
        | "downgrades_last_20_days"
        | "downgrades_last_60_days"
        | "positive_movers"
        | "negative_movers";
    }
  | {
      type: "SET_RATING";
      payload: { A: boolean; B: boolean; C: boolean; D: boolean };
    }
  | { type: "SET_PEER_ID"; payload: string | undefined }
  | { type: "RESET_TO_DEFAULT" };

type DispersionParamsState = {
  performance: "3_months" | "6_months" | "12_months";
  trimOutliers: boolean;
  intervals: 4 | 10 | 20;
  peerId?: string;
};

type DispersionByParamsState = {
  performance: "3_months" | "6_months" | "12_months";
  trimOutliers: boolean;
  intervals: 4 | 10 | 20;
};

type SecuritiesParamsState = {
  sorter: { field: string; rev: boolean };
  pagination: { itemsPerPage: 1000; page: number };
  alert:
    | null
    | "Any"
    | "upgrades_today"
    | "upgrades_last_5_days"
    | "upgrades_last_20_days"
    | "upgrades_last_60_days"
    | "downgrades_today"
    | "downgrades_last_5_days"
    | "downgrades_last_20_days"
    | "downgrades_last_60_days"
    | "positive_movers"
    | "negative_movers";
  rating: { A: boolean; B: boolean; C: boolean; D: boolean };
  peerId?: string;
};

const TABS_NAME_DICT = {
  TCR: "tcr",
  dispersion: "dispersion",
  dispersionBySector: "dispersionBy",
  performanceSinceRated: "performanceSinceRated",
  ratingHistory: "history",
  securities: "securities",
};

/**
 * Those are defaults states to be loaded when a peer change
 */
const viewParams: {
  dispersion: DispersionParamsState;
  securities: SecuritiesParamsState;
  dispersionBy: DispersionByParamsState;
} = {
  dispersion: {
    performance: "6_months",
    trimOutliers: false,
    intervals: 4,
    peerId: undefined,
  },
  securities: {
    sorter: { field: "marketcap", rev: false },
    pagination: { itemsPerPage: 1000, page: 1 },
    alert: "Any",
    rating: { A: false, B: false, C: false, D: false },
    peerId: undefined,
  },
  dispersionBy: {
    performance: "6_months",
    trimOutliers: false,
    intervals: 4,
  },
};

const dispersionByReducer = (
  draft: DispersionByParamsState,
  action: DispersionByViewActionMarketsETF
) => {
  switch (action.type) {
    case "SET_PERFORMANCE":
      draft.performance = action.payload;

      break;

    case "SET_INTERVALS":
      draft.intervals = action.payload;

      break;

    case "SET_TRIM_OUTLIERS":
      draft.trimOutliers = action.payload;
  }
};

const dispersionReducer = (
  draft: DispersionParamsState,
  action: DispersionViewActionMarketsETF
) => {
  switch (action.type) {
    case "SET_PERFORMANCE":
      draft.performance = action.payload;

      break;

    case "SET_INTERVALS":
      draft.intervals = action.payload;

      break;

    case "SET_TRIM_OUTLIERS":
      draft.trimOutliers = action.payload;

      break;

    case "SET_PEER_ID":
      draft.peerId = action.payload;

      break;

    case "RESET_TO_DEFAULT":
      return viewParams.dispersion;
  }
};

const securitiesReducer = (
  draft: SecuritiesParamsState,
  action: SecuritiesViewActionMarketsETF
) => {
  switch (action.type) {
    case "SET_SORT": {
      draft.sorter = action.payload;

      break;
    }
    case "SET_ALERT":
      if (action.payload !== draft.alert) {
        draft.pagination.page = 1;
      }
      draft.alert = action.payload;

      break;

    case "SET_PAGE_TO_VIEW":
      draft.pagination.page = action.payload;

      break;

    case "SET_RATING":
      draft.rating.A = action.payload.A;
      draft.rating.B = action.payload.B;
      draft.rating.C = action.payload.C;
      draft.rating.D = action.payload.D;

      break;

    case "SET_PEER_ID":
      draft.peerId = action.payload;
      draft.pagination.page = 1;

      break;

    case "RESET_TO_DEFAULT":
      return viewParams.securities;
  }
};

export function AnalysisMarketsETF() {
  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");
  }, []);
  const usage = window.App.usage;

  const formatter = useFormatter();
  const { getNodeChildrenLevels } = useTaxonomyByType("ETF");
  const [queue, setQueue] = useState<any>([]);
  const environment = useEnvironment();
  const product = useMemo(
    () => environment.get("account")["product"],
    [environment]
  );
  const pageConfiguration = useMemo(
    () => product?.configuration?.["analysis_peer"],
    [product?.configuration]
  );
  const isSecurityTabEnabled = useMemo(() => {
    const tabsConfig = pageConfiguration.tabs["Stocks"];
    return tabsConfig?.securities?.enabled ?? false;
  }, [pageConfiguration.tabs]);

  const [dispersionReportParams, setDispersionReportParams] = useState({
    performance: "6_months",
    trimOutliers: false,
    intervals: 4,
  });

  const marketsETFControlsReducer = useCallback(
    (draft: ControlsETFMarket, action: ActionsETFMarketsControls) => {
      switch (action.type) {
        case "SET_TIMEFRAME":
          draft.timeframe = action.payload;

          break;

        case "SET_SEGMENT":
          draft.segment = action.payload;

          break;
        case "SET_ANALYTIC":
          draft.analytic = action.payload;

          break;

        case "CHANGE_PEER": {
          const peerInfo = {
            where: draft.xDim,
            what: draft.yDim,
            zDimension: draft.zDim,
            type: "ETF",
          };
          const peerBeforeChange = encodePeerId(peerInfo);
          // Used to pass the right old analytic to the nav queue before the next functions change
          // the tab view based on the smart drilldown logic
          const analyticBeforeChange = draft.analytic;

          const { xDim, yDim, zDim, segment, analytic } = action.payload;

          let options: any = [];

          if (segment == null) {
            const supportedLevels = [
              "1 Industry",
              "3 Sector",
              "4 Subsector",
              "Area",
              "Region",
              "Country",
            ];

            const yLevels = getNodeChildrenLevels(
              yDim ?? draft.yDim,
              "etfclass"
            );
            const xLevels = getNodeChildrenLevels(xDim ?? draft.xDim, "etfgeo");

            const xLevelsOrder = ["Area", "Region", "Country"];
            const orderMap = xLevelsOrder.reduce(
              (prev, current, index) => ({
                ...prev,
                [current]: index,
              }),
              {}
            );
            const sortedLevelsX = xLevels.sort(
              (a, b) => orderMap[a] - orderMap[b]
            );

            const nodeChildrenLevels = yLevels.concat(sortedLevelsX);

            for (const level of nodeChildrenLevels) {
              // check if level is supported
              if (supportedLevels.indexOf(level) !== -1) {
                let option: any = null;

                switch (level) {
                  case "1 Industry": {
                    option = {
                      value: level,
                      label: "Asset Class",
                    };

                    break;
                  }

                  case "3 Sector": {
                    option = {
                      value: level,
                      label: "Specialty",
                    };

                    break;
                  }

                  case "4 Subsector": {
                    option = {
                      value: level,
                      label: "Theme",
                    };

                    break;
                  }

                  case "Area": {
                    option = {
                      value: level,
                      label: "Areas",
                    };

                    break;
                  }

                  case "Region": {
                    option = {
                      value: level,
                      label: "Regions",
                    };

                    break;
                  }

                  case "Country": {
                    option = {
                      value: level,
                      label: "Markets",
                    };

                    break;
                  }
                }

                if (option != null) {
                  options.push(option);
                }
              }
            }

            if (options.length) {
              let areOnlyGeoOptions = true;

              for (const opt of options) {
                if (
                  opt.value === "1 Industry" ||
                  opt.value === "3 Sector" ||
                  opt.value === "4 Subsector"
                ) {
                  areOnlyGeoOptions = false;
                }
              }

              if (areOnlyGeoOptions) {
                draft.segment = options[options.length - 1].value;
                // If option are only on the geo dimension it means
                // that on the y dim we are at the leaf of the tree hierarchy
                // so we decided to restrict the list of tabs only to dispersion and
                // securities to make the UI much meaningful to the user. Always for
                // this reason in that case we start from the securities tab
                draft.analytic = "securities";
              } else {
                draft.segment = options[0].value;
              }
            }
          } else {
            draft.segment = segment;
          }

          if (yDim) {
            draft.yDim = yDim;
          }

          if (xDim) {
            draft.xDim = xDim;
          }

          if (zDim) {
            draft.zDim = zDim;
          }

          if (analytic) {
            draft.analytic = analytic;
          }

          if (!queue.some((navItem) => navItem.peer === peerBeforeChange)) {
            const updatedPeerInfo = {
              where: draft.xDim,
              what: draft.yDim,
              zDimension: draft.zDim,
              type: "ETF",
            };

            const updatedPeerId = encodePeerId(updatedPeerInfo);
            if (updatedPeerId !== peerBeforeChange) {
              setQueue([
                ...queue,
                {
                  peer: peerBeforeChange,
                  analytic: analyticBeforeChange,
                },
              ]);
            }
          }

          break;
        }

        case "SET_Z_DIM":
          draft.zDim = action.payload;

          break;

        default:
          return draft;
      }
    },
    [getNodeChildrenLevels, queue]
  );

  const controlsInit: ControlsETFMarket = useMemo(() => {
    const tabsFromConfig = pageConfiguration?.tabOrder;
    let firstTabAvailable = tabsFromConfig?.[0];

    if (!firstTabAvailable) {
      firstTabAvailable = "TCR";
    }

    const analytic = TABS_NAME_DICT[firstTabAvailable];

    return {
      timeframe: 19,
      segment: "1 Industry",
      analytic,
      xDim: "WWW",
      yDim: "ETFSEGMENT",
      zDim: "ALL",
    };
  }, [pageConfiguration]);

  const [controls, dispatch] = useImmerReducer(
    marketsETFControlsReducer,
    controlsInit
  );

  const getChildrenTypeOptions = useCallback(() => {
    let options: any = [];
    const supportedLevels = [
      "1 Industry",
      "3 Sector",
      "4 Subsector",
      "Area",
      "Region",
      "Country",
    ];

    const yLevels = getNodeChildrenLevels(controls.yDim, "etfclass");
    const xLevels = getNodeChildrenLevels(controls.xDim, "etfgeo");
    const xLevelsOrder = ["Area", "Region", "Country"];
    const orderMap = xLevelsOrder.reduce(
      (prev, current, index) => ({ ...prev, [current]: index }),
      {}
    );
    const sortedLevelsX = xLevels.sort((a, b) => orderMap[a] - orderMap[b]);

    const nodeChildrenLevels = yLevels.concat(sortedLevelsX);

    for (const level of nodeChildrenLevels) {
      // check if level is supported
      if (supportedLevels.indexOf(level) !== -1) {
        let option: any = null;

        switch (level) {
          case "1 Industry": {
            option = { value: level, label: "Asset Class" };

            break;
          }

          case "3 Sector": {
            option = { value: level, label: "Specialty" };

            break;
          }

          case "4 Subsector": {
            option = { value: level, label: "Theme" };

            break;
          }

          case "Area": {
            option = { value: level, label: "Areas" };

            break;
          }

          case "Region": {
            option = { value: level, label: "Regions" };

            break;
          }

          case "Country": {
            option = { value: level, label: "Markets" };

            break;
          }
        }

        if (option != null) {
          options.push(option);
        }
      }
    }

    return options;
  }, [controls.xDim, controls.yDim, getNodeChildrenLevels]);

  const [peerObject, setPeerObject] = useState<any>();

  const viewRef = useRef<HTMLDivElement>(null);

  const updateDispersionReportParams = useCallback((currentState, action) => {
    const target = { ...currentState };

    switch (action.type) {
      case "SET_PERFORMANCE": {
        target.performance = action.payload;

        break;
      }

      case "SET_INTERVALS":
        target.intervals = action.payload;

        break;

      case "SET_TRIM_OUTLIERS":
        target.trimOutliers = action.payload;
    }

    setDispersionReportParams(target);
  }, []);

  const dispersionByReducerDecorator = useCallback(
    (currentState, action) => {
      updateDispersionReportParams(currentState, action);
      dispersionByReducer(currentState, action);
    },
    [updateDispersionReportParams]
  );

  const dispersionReducerDecorator = useCallback(
    (currentState, action) => {
      updateDispersionReportParams(currentState, action);
      dispersionReducer(currentState, action);
    },
    [updateDispersionReportParams]
  );

  /* Reducers with view contextual params */
  const [dispersionParams, dispatchDispersion] = useImmerReducer(
    dispersionReducerDecorator,
    viewParams.dispersion
  );
  const [securitiesParams, dispatchSecurities] = useImmerReducer(
    securitiesReducer,
    viewParams.securities
  );
  const [dispersionByParams, dispatchDispersionBy] = useImmerReducer(
    dispersionByReducerDecorator,
    viewParams.dispersionBy
  );

  /** ********** Data get section ********** */
  const [status, setStatus] = useState("loading");

  const dataHandler = useMemo(
    () => new AnalysisMarketsETFHelper(environment),
    [environment]
  );

  // Reset params on peer change
  useEffect(() => {
    dispatchDispersion({ type: "RESET_TO_DEFAULT" });
    dispatchSecurities({ type: "RESET_TO_DEFAULT" });
  }, [
    controls.analytic,
    controls.xDim,
    controls.yDim,
    controls.zDim,
    dispatchDispersion,
    dispatchSecurities,
  ]);

  useEffect(() => {
    const getDataForPeerView = async () => {
      try {
        setStatus("loading");
        switch (controls.analytic) {
          case "tcr": {
            setPeerObject(null);

            const response = await dataHandler.getPeerDataByAnalytic(controls);

            setPeerObject(response);

            break;
          }

          case "dispersion": {
            setPeerObject(null);

            const response = await dataHandler.getPeerDataByAnalytic(
              controls,
              dispersionParams
            );

            setPeerObject(response);

            break;
          }

          case "securities": {
            setPeerObject(null);

            const response = await dataHandler.getPeerDataByAnalytic(
              controls,
              securitiesParams
            );

            setPeerObject(response);

            break;
          }

          case "dispersionBy": {
            setPeerObject(null);

            const response = await dataHandler.getPeerDataByAnalytic(
              controls,
              dispersionByParams
            );

            setPeerObject(response);

            break;
          }

          case "performanceSinceRated": {
            setPeerObject(null);

            const response = await dataHandler.getPeerDataByAnalytic(controls);

            setPeerObject(response);

            break;
          }
        }

        setStatus("completed");
      } catch (error) {
        console.log(error);
      }
    };

    getDataForPeerView();
  }, [
    dataHandler,
    controls,
    dispersionParams,
    securitiesParams,
    dispersionByParams,
  ]);

  /*********************************************/
  useResizer({ ref: viewRef, correction: 5 });

  const onClickBackTo = useCallback(
    ({
      peer,
      analytic,
    }: {
      peer: string;
      analytic: ControlsETFMarket["analytic"];
    }) => {
      const peerObject = decodePeerId(peer);
      const { where, what, zDimension } = peerObject;

      dispatch({
        type: "CHANGE_PEER",
        payload: {
          xDim: where,
          yDim: what,
          zDim: zDimension,
          analytic: analytic,
        },
      });
    },
    [dispatch]
  );

  const applyPreset = useCallback(
    (preset: Preset) => {
      const xDim = preset.etfgeo;
      const yDim = preset.etfClass;
      let zDim: undefined | string = undefined;
      const segment = preset.groupBy.transform.params.level;

      if (preset.subtype) {
        zDim = preset.subtype;
      }

      const info = {
        action: "PEER",
        actionParam: {
          xDimension: xDim ?? controls.xDim,
          yDimension: yDim ?? controls.yDim,
          zDimension: zDim ?? controls.zDim,
        },
        function: "MARKET_ETF",
      };

      usage.record(info);

      dispatch({
        type: "CHANGE_PEER",
        payload: { xDim, yDim, zDim, segment },
      });
    },
    [controls.xDim, controls.yDim, controls.zDim, dispatch, usage]
  );

  const showAssetClassSelector = useMemo(() => {
    const configuration =
      environment.get("account")?.product?.configuration?.["analysis_market"];
    const assetClasses = configuration.assetClasses;

    const isStockEnabled =
      "stock" in assetClasses && assetClasses.stock === true;
    const isEtfEnabled = "etf" in assetClasses && assetClasses.etf === true;

    return isStockEnabled && isEtfEnabled;
  }, [environment]);

  const { getTaxonomyById, fieldY, fieldX, getTaxonomyMap } =
    useTaxonomyByType("ETF");

  const getReportTitle = useCallback(() => {
    if (peerObject?.peer) {
      const decodedPeer = decodePeerId(peerObject?.peer?.id);
      const what = getTaxonomyById(decodedPeer.what, fieldY);
      const where = getTaxonomyById(decodedPeer.where, fieldX);

      const zDimTaxonMap = getTaxonomyMap("ETF", "subtype");
      const size = zDimTaxonMap[decodedPeer.zDimension]?.name;

      const rootNodeZ = Object.values<any>(zDimTaxonMap).find(
        (node) => node.parent == null
      );

      const title = `${
        what.parent == null ? "Any Asset class - " : what.name + " - "
      }  ${where.parent == null ? "Any Country" : where.name} ${
        size === rootNodeZ?.name ? "" : " - " + size
      }`;

      return title;
    }

    return "";
  }, [fieldX, fieldY, getTaxonomyById, getTaxonomyMap, peerObject?.peer]);

  // Sync dispersionBy table to get the sort criteria during report generation
  const [dispersionBySort, setDispersionBySort] = useState([
    { dir: "desc", field: "dispersionAverageTop" },
  ]);
  const dispersionByTabRefSorter = useMemo(
    () => ({
      current: {
        getSorter: () => dispersionBySort,
      },
    }),
    [dispersionBySort]
  );

  const { broadcast } = useBroadcast();

  // Workflow bar
  useEffect(() => {
    const actions: any = [];
    let action: any = null;
    const timeframeConversionMap = {
      0: "today",
      4: "lastWeek",
      19: "lastMonth",
    };

    if (Object.values(queue).length) {
      const action = {
        componentJSX: (
          <li
            className="menu__item"
            onClick={() => {
              const newQueue = deepClone(queue);
              const value = newQueue.pop();
              onClickBackTo(value);
              setQueue(newQueue);
            }}
          >
            Back
          </li>
        ),
      };

      actions.push(action);
    }

    const actionWorkflow = pageConfiguration.workflow;

    if (
      "dispersionReport" in actionWorkflow &&
      actionWorkflow["dispersionReport"]?.["enabled"] === true
    )
      action = {
        componentJSX: (
          <DirectDispersionReport
            dispersionTabRef={dispersionByTabRefSorter}
            formatter={formatter}
            dispersionByConstraints={{
              intervals: dispersionReportParams.intervals as any,
              performanceTimeframe: dispersionReportParams.performance as any,
              trimOutliers: dispersionReportParams.trimOutliers as any,
            }}
            title={getReportTitle() ?? peerObject?.peer?.name ?? ""}
            timeframe={timeframeConversionMap[controls.timeframe] as any}
            segment={controls.segment as any}
            environment={environment}
            peer={peerObject?.peer}
          />
        ),
      };

    actions.push(action);

    var message = {
      from: "analysisMarkets",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    controls.segment,
    controls.timeframe,
    dispersionByTabRefSorter,
    dispersionReportParams.intervals,
    dispersionReportParams.performance,
    dispersionReportParams.trimOutliers,
    environment,
    formatter,
    getReportTitle,
    onClickBackTo,
    pageConfiguration.workflow,
    peerObject?.peer,
    queue,
  ]);

  useEffect(() => {
    document.title = "Markets - Trendrating";
  }, []);

  const goToSecurityTabFromTcrBoxes = useCallback(
    (boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc") => {
      const timeframeLabel = {
        0: "_today",
        4: "_last_5_days",
        19: "_last_20_days",
      };

      switch (boxType) {
        case undefined:
        default:
          break;

        case "downgrades": {
          dispatchSecurities({
            type: "SET_ALERT",
            payload: `downgrades${timeframeLabel[controls.timeframe]}` as any,
          });

          break;
        }
        case "upgrades": {
          dispatchSecurities({
            type: "SET_ALERT",
            payload: `upgrades${timeframeLabel[controls.timeframe]}` as any,
          });

          break;
        }

        case "ab_perc": {
          dispatchSecurities({
            type: "SET_RATING",
            payload: { A: true, B: true, C: false, D: false },
          });

          break;
        }

        case "cd_perc": {
          dispatchSecurities({
            type: "SET_RATING",
            payload: { A: false, B: false, C: true, D: true },
          });

          break;
        }
      }

      dispatch({ type: "SET_ANALYTIC", payload: "securities" });
    },
    [controls.timeframe, dispatch, dispatchSecurities]
  );

  const showTcrTableOnLeaf = useMemo(() => {
    const allLevels = getNodeChildrenLevels(controls.yDim, "etfclass");
    const childrenLevels = allLevels.filter(
      (level) =>
        level === "1 Industry" ||
        level === "3 Sector" ||
        level === "4 Subsector"
    );

    if (childrenLevels.length === 0) {
      return false;
    } else {
      return true;
    }
  }, [controls.yDim, getNodeChildrenLevels]);

  // const segmentRef = useRef<any>(null);
  // // Take the exact moment when the yDim is a leaf in taxonomies and store in a ref the current segment.
  // // this let us to hide the table of the instruments in a view tab when the yDim has no children but we
  // // can rentroduce her when we change the segment. To get the segment change in securitiesView and in
  // // dispersion view we check if the stored segment when the peer became a leaf in the ydim is equal to
  // // the actual segment and only when is different we reintroduce the table.
  // useEffect(() => {
  //   const allLevels = getNodeChildrenLevels(controls.yDim, "etfclass");
  //   const childrenLevels = allLevels.filter(
  //     (level) =>
  //       level === "1 Industry" ||
  //       level === "3 Sector" ||
  //       level === "4 Subsector"
  //   );

  //   if (childrenLevels.length === 0 && segmentRef.current == null) {
  //     segmentRef.current = controls.segment;
  //   } else if (childrenLevels.length !== 0) {
  //     segmentRef.current = null;
  //   }
  // }, [controls.segment, controls.yDim, getNodeChildrenLevels]);

  // // Refresh the segment ref on change tab to be sure that the same logic for the appearence of the
  // // TCR table is applied for every tab available when the yDim is a leaf.
  // useEffect(() => {
  //   segmentRef.current = null;
  // }, [controls.analytic]);

  // Handle Back navigation to stocks analysis market
  const [pageSelector, setPageSelector] = useState<"stock" | "ETF">("ETF");
  const navigate = useNavigate();

  useEffect(() => {
    if (pageSelector === "stock") {
      navigate("/app/analysis/markets", { replace: false });
    }
  }, [navigate, pageSelector]);

  const selectorOptions = [
    { label: "Stock", value: "stock" },
    { label: "ETF", value: "ETF" },
  ];

  return (
    <Box
      ref={viewRef}
      px={1}
      minHeight={0}
      display={"flex"}
      //To show the boxshadow of the child
      overflow={"visible!important"}
      flexDirection={"column"}
    >
      {peerObject?.peer && (
        <Card sx={{ boxShadow: "none" }}>
          <CardContent
            sx={{
              display: "flex",
              paddingBottom: "10px!important",
              paddingTop: "0px!important",
              paddingLeft: "0px!important",
              flexDirection: "column",
            }}
          >
            <Box display={"flex"} alignItems={"center"} mb={1}>
              {showAssetClassSelector && (
                <Box
                  padding={"10px"}
                  pt={"20px"}
                  position={"relative"}
                  borderBottom={"1px solid #ddd"}
                  borderRight={"1px solid #ddd"}
                >
                  <Typography
                    fontSize={"90%"}
                    sx={{
                      position: "absolute",
                      top: "2px",
                      left: "2px",
                      color: "#2a7090",
                    }}
                  >
                    Type
                  </Typography>
                  <SelectorETFMarkets
                    options={selectorOptions}
                    selectedOption={pageSelector}
                    selectOption={(value) => {
                      if (value === "stock") {
                        const info = {
                          action: "LANDING",
                          function: "MARKET",
                        };

                        usage.record(info);
                      } else {
                        const info = {
                          action: "LANDING",
                          function: "MARKET_ETF",
                        };

                        usage.record(info);
                      }
                      setPageSelector(value);
                    }}
                    fontSize={14}
                  />
                </Box>
              )}
              <BreadcrumbsRow data={peerObject?.peer} dispatch={dispatch} />
            </Box>
            <PeerPresetsRow
              changePeer={applyPreset}
              navigationHistory={queue}
              backHandler={onClickBackTo}
            />
          </CardContent>
        </Card>
      )}
      <AnalysisMarketsEtfTemplate
        dispatch={dispatch}
        getOptionsByPeer={getChildrenTypeOptions}
        segment={controls.segment}
        analytic={controls.analytic}
        timeframe={controls.timeframe}
      >
        <ContentETFMarkets
          segmentReference={showTcrTableOnLeaf}
          goToSecuritiesTab={
            isSecurityTabEnabled ? goToSecurityTabFromTcrBoxes : undefined
          }
          dispatch={dispatch}
          analytic={controls.analytic}
          formatterHelper={dataHandler.tableFormatterByField}
          timeframe={controls.timeframe}
          status={status}
          data={peerObject}
          dispersionDispatcher={dispatchDispersion}
          dispersionIntervals={dispersionParams.intervals}
          dispersionPerformance={dispersionParams.performance}
          dispersionTrimOutliers={dispersionParams.trimOutliers}
          dispersionPeerId={dispersionParams.peerId}
          securitiesDispatcher={dispatchSecurities}
          securitiesPeerId={securitiesParams.peerId}
          securitiesCurrentPage={securitiesParams.pagination.page}
          currentSort={securitiesParams.sorter}
          rating={securitiesParams.rating}
          alert={securitiesParams.alert}
          dispersionByDispatcher={dispatchDispersionBy}
          dispersionByIntervals={dispersionByParams.intervals}
          dispersionByPerformance={dispersionByParams.performance}
          dispersionByTrimOutliers={dispersionByParams.trimOutliers}
          setDispersionBySort={setDispersionBySort}
          segment={controls.segment}
          xDimension={controls.xDim}
          yDimension={controls.yDim}
        />
      </AnalysisMarketsEtfTemplate>
    </Box>
  );
}
