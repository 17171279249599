type Props = { setID: (id) => void; lists: any[] };

export default function ListSelector({ setID, lists }: Props) {
    const baskets = lists?.filter((el) => el.type !== "PORTFOLIO") ?? null;
    const portfolios = lists?.filter((el) => el.type === "PORTFOLIO") ?? null;

    const onClickListItem = (e, list) => {
        const listName = e?.target?.textContent ?? null;
        const id =
            listName && lists
                ? list.find((el) => el.name === listName)?.id ?? null
                : null;

        setID(id);
    };

    const sortlist = (a, b) => {
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    return (
        <div className="portfolio-compare-selector-wrapper">
            <h2>Choose the portfolio or basket to compare with</h2>
            <div className="portfolio-selector-content">
                <div className="portfolio-list-wrapper">
                    <h2>Portfolios</h2>
                    <ul>
                        {portfolios &&
                            portfolios
                                .sort((a, b) => sortlist(a.name, b.name))
                                .map((el, i) => {
                                    return el.isReadOnly ? (
                                        <li
                                            key={`${i}__${el.symbol}`}
                                            onClick={(e) =>
                                                onClickListItem(e, portfolios)
                                            }
                                        >
                                            <span className="sharedObjectIndicator"></span>
                                            {el.name}
                                        </li>
                                    ) : (
                                        <li
                                            key={`${i}__${el.symbol}`}
                                            onClick={(e) =>
                                                onClickListItem(e, portfolios)
                                            }
                                        >
                                            {el.name}
                                        </li>
                                    );
                                })}
                    </ul>
                </div>
                <div className="portfolio-list-wrapper">
                    <h2>Baskets</h2>
                    <ul>
                        {baskets &&
                            baskets
                                .sort((a, b) => sortlist(a.name, b.name))
                                .map((el, i) => {
                                    return el.isReadOnly ? (
                                        <li
                                            key={`${i}__${el.symbol}`}
                                            onClick={(e) =>
                                                onClickListItem(e, baskets)
                                            }
                                        >
                                            <span className="sharedObjectIndicator"></span>
                                            {el.name}
                                        </li>
                                    ) : (
                                        <li
                                            key={`${i}__${el.symbol}`}
                                            onClick={(e) =>
                                                onClickListItem(e, baskets)
                                            }
                                        >
                                            {el.name}
                                        </li>
                                    );
                                })}
                    </ul>
                </div>
            </div>
        </div>
    );
}
