import { Box } from "@mui/material";
import { useCallback, useRef } from "react";
import Info from "./widgets/Info";

import { Users } from "../../../../../../../api/account/Users";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { config } from "../../../../../config-ts";
import Home from "./widgets/Home";
import Password from "./widgets/Password";
import Product from "./widgets/Product";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { appCookie } from "../../../../../Cookies";

type AccountProps = {
  landingPageDefault: string;
  landingPageOptions: { value: string; label: string }[];
  value: string;
  preferenceDispatcher: any;
  saving: any;
};

export default function Account({
  landingPageDefault,
  landingPageOptions,
  value,
  preferenceDispatcher,
  saving,
}: AccountProps) {
  const onChangeLandingHandler = (landing: string) => {
    preferenceDispatcher({
      type: "SET_LANDING_PAGE",
      payload: landing,
      saving: saving,
    });
  };

  const environment = useEnvironment();

  const passRef: any = useRef(null);
  const { broadcast } = useBroadcast();

  const _changePasswordResult = useCallback(
    (response) => {
      if (response.status === "OK") {
        passRef?.current?.resetTextFields();
        const message = {
          from: "resetPassword",
          content: {
            type: "success",
            text: "Password changed successfully.",
          },
        };
        broadcast(config["channels"]["feedback"]["input"], message);
      } else {
        const message = {
          from: "resetPassword",
          content: {
            type: "error",
            text: "Something goes wrong. Please, contact the Trendrating customer care.",
          },
        };
        broadcast(config["channels"]["feedback"]["input"], message);
      }
    },
    [broadcast]
  );

  const onChangePasswordHandler = useCallback(
    (newPassword: string) => {
      const user = new Users(environment.get("setup"));
      const accountInfo = environment.get("account");
      const userID = accountInfo.user.id;
      const pwdTrimmed = newPassword.trim();
      user.passwordReset(
        { id: userID, password: pwdTrimmed },
        _changePasswordResult
      );
    },
    [_changePasswordResult, environment]
  );

  const logoutHandler = useCallback(() => {
    // **************************** USAGE ******************************
    var usage = window.App.usage;
    var info = {
      action: "LOGOUT",
      actionParam: null,
      function: "LOGOUT",
    };
    usage.record(info);
    // **************************** USAGE ******************************

    // Reset redirect from page product selector
    appCookie("redirect-from-product-page", "");
    const user = new Users(environment.get("setup"));

    user.logout().then((response) => {
      window.location.href = config.routes.appAccount;
    });
  }, [environment]);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Info logoutHandler={logoutHandler} />
      <Home
        options={landingPageOptions}
        value={value != null ? value : landingPageDefault}
        onChangeLanding={onChangeLandingHandler}
      />
      <Password
        ref={passRef}
        onChangePasswordHandler={onChangePasswordHandler}
      />
      {<Product />}
    </Box>
  );
}
