import { Button } from "@mui/material";
import { styled } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Strategies } from "../../api/compute/Strategies";

type OpenInNewTabButtonProps = {
  type: "strategies" | "products";
  id: number;
};

const StyledBtn = styled((props: any) => {
  const navigateToresource = useCallback(
    (e) => {
      e.stopPropagation();

      const getType = () => {
        switch (props.type) {
          case "strategies":
            return "strategies";
          case "products":
            return "systematic-portfolios";
        }
      };
      const getEntityType = () => {
        switch (props.entity_type) {
          case "SMART_BETA":
            return "smart-beta";
          case "BUILDER":
            return "advanced";
          case "ALPHA":
            return "alpha";
          case "SECTOR_ROTATION":
            return "macro-rotation";
        }
      };
      let url: any = null;
      const typeOfObj = getType();
      switch (typeOfObj) {
        case "strategies":
          url = `/${typeOfObj}/builder/${getEntityType()}/${props.id}`;
          break;
        case "systematic-portfolios":
          url = `/${typeOfObj}/${props.id}`;
          break;
      }
      if (url) {
        const baseURL = window.location.origin + "/app";
        const tempLink = document.createElement("a");
        tempLink.href = baseURL + url;
        tempLink.target = "_blank";
        tempLink.click();
        tempLink.remove();
      }
    },
    [props.entity_type, props.id, props.type]
  );

  return (
    <Button
      title={`Open ${
        props.type === "strategies" ? "strategy" : "Systematic portfolio"
      } in a new tab`}
      className={props.className}
      onClick={navigateToresource}
      sx={{ height: "auto" }}
    >
      {props.children}
    </Button>
  );
})({
  borderRadius: "4px!important",
  borderColor: "1px solid #2a7090!important",
  backgroundColor: "white!important",
  padding: "3px!important",
  minWidth: "0!important",
  height: "auto",
});

export const OpenInNewTabButton = memo(
  ({ id, type }: OpenInNewTabButtonProps) => {
    const [entityType, setEntityType] = useState<string>();
    const environment = useEnvironment();
    const strategiesAPI = useMemo(
      () => new Strategies(environment.get("setup")),
      [environment]
    );
    const getEntityType = useCallback(async () => {
      const property = "entity_type";
      const prefix = "object.";
      const response = await strategiesAPI.fetch([id], [prefix + property]);

      const entityType =
        response?.data?.[0]?.rows?.[0]?.[property] ?? undefined;

      setEntityType(entityType);
    }, [id, strategiesAPI]);

    useEffect(() => {
      if (type === "strategies") {
        getEntityType();
      }
    }, [getEntityType, type]);

    return (
      <StyledBtn id={id} type={type} entity_type={entityType}>
        <OpenInNewIcon
          fontSize={"small"}
          sx={{
            fontSize: "0.7vw !important",
            color: "#2a7090",
            cursor: "pointer",
          }}
        />
      </StyledBtn>
    );
  }
);
