import { useCallback, useEffect, useMemo, useState } from "react";
import { Chart } from "../../../../../../../components/Chart";

type DispersionChartProps = {
  data: any;
  interval: 25 | 10 | 5;
  chartType: "histogram" | "range";
  timeframe: "3_months" | "6_months" | "12_months";
  sorter?: { dir: "desc" | "asc"; field: string }[];
};

type Item = {
  name: string;
  _s_label: string;
  top: number;
  topCardinality: number;
  middle: number;
  middleCardinality: number;
  bottom: number;
  bottomCardinality: number;
  maxTop: number;
  maxMiddle: number;
  maxBottom: number;
  minTop: number;
  minMiddle: number;
  minBottom: number;
  id: string | number;
};

const convertToSerie = (originalData: { [key: string]: any }) => {
  const serie: Item[] = [];

  if (originalData) {
    for (const [key, value] of Object.entries(originalData)) {
      if (
        value?.dispersion?.top?.average != null &&
        value?.dispersion?.middle?.average != null &&
        value?.dispersion?.bottom?.average != null
      ) {
        const item = {
          name: value["name"],
          _s_label: key,
          top: value?.["dispersion"]?.["top"]?.["average"] ?? 0,
          topCardinality: value?.["dispersion"]?.["top"]?.["cardinality"] ?? 0,
          middle: value?.["dispersion"]?.["middle"]?.["average"] ?? 0,
          middleCardinality:
            value?.["dispersion"]?.["middle"]?.["cardinality"] ?? 0,
          bottom: value?.["dispersion"]?.["bottom"]?.["average"] ?? 0,
          bottomCardinality:
            value?.["dispersion"]?.["bottom"]?.["cardinality"] ?? 0,
          maxTop: value?.["dispersion"]?.["top"]?.["max"] ?? 0,
          maxMiddle: value?.["dispersion"]?.["middle"]?.["max"] ?? 0,
          maxBottom: value?.["dispersion"]?.["bottom"]?.["max"] ?? 0,
          minTop: value?.["dispersion"]?.["top"]?.["min"] ?? 0,
          minMiddle: value?.["dispersion"]?.["middle"]?.["min"] ?? 0,
          minBottom: value?.["dispersion"]?.["bottom"]?.["min"] ?? 0,
          id: key,
        };

        serie.push(item);
      }
    }
  }

  return serie;
};

const adjustStep = (step) => {
  var i = 1;
  var m = step;
  var adjStep = 1;
  if (step < 1) {
    while (m < 1) {
      adjStep = Math.pow(10, i);
      m = m * adjStep;

      i++;
    }
  } else if (step >= 10 && step < 100) {
    adjStep = Math.pow(10, -1);
  }

  return adjStep;
};

const truncate = (val, n) => {
  let toFixed = val.toFixed(n);
  return Number.parseFloat(toFixed);
};

var floor = function (min, step) {
  var floor = min;
  var f = 0;
  if (min < 0) {
    while (f > min) {
      f -= step;
    }
    floor = f;
  } else if (min > 0) {
    while (f < min) {
      f += step;
    }
    floor = f - step;
  }

  return floor;
};

var getStep = function (min, max, desiredSteps) {
  //moltiplicatore sufficientemente grande per avere sicuramente estremi interi
  var mult = 10000000000000;
  var minMult = min * mult;
  var maxMult = max * mult;
  //calcoliamo lo step intero minimo che soddisfa la disequazione (max-min)/n<step
  var result = Math.ceil((maxMult - minMult) / desiredSteps);
  //step esatto
  var step = result / mult;
  //------------PLOT STEP

  return step;
};

var plot = function (min, max, step) {
  let i = 0;
  let x = min;
  while (x < max) {
    x = min + step * i;

    i++;
  }
};

const calculateTickerIntervalParams = (min: number, max: number) => {
  const desiredSteps = 10;

  const step = getStep(min, max, desiredSteps);
  //qui calcoliamo lo step arrotondato al decimale superiore
  const adjStep = adjustStep(step);

  const stepToRound = step * adjStep;
  //step arrotondato al decimale superiore
  const finalStep = Math.ceil(stepToRound) / adjStep;
  const finalMin = floor(min, finalStep);

  plot(finalMin, max, finalStep);

  return { finalMin, finalStep };
};

const generateChartConfig = (serie, interval, chartType, timeframe) => {
  const colors = {
    top: "#008000",
    middle: "#ff9900",
    bottom: "#ff0000",
  };

  let minInit = 0;
  let minRangeInit = 0;
  let maxInit = 0;
  let maxRangeInit = 0;

  const {
    min,
    max,
    minRange,
    maxRange,
    topSerie,
    midSerie,
    bottomSerie,
    topRangeSerie,
    midRangeSerie,
    bottomRangeSerie,
  } = prepareChartConfigSerie(
    serie,
    minInit,
    maxInit,
    minRangeInit,
    maxRangeInit
  );

  const { finalMin, finalStep } = calculateTickerIntervalParams(min, max);
  const { finalMin: finalMinRange, finalStep: finalStepRange } =
    calculateTickerIntervalParams(minRange, maxRange);

  const parsedInterval = interval;
  let intervalLabel = {
    top: "",
    middle: "",
    bottom: "",
  };
  switch (parsedInterval) {
    case 25:
      intervalLabel.top = "25%";
      intervalLabel.middle = "50%";
      intervalLabel.bottom = "25%";
      break;
    case 10:
      intervalLabel.top = "10%";
      intervalLabel.middle = "80%";
      intervalLabel.bottom = "10%";
      break;
    case 5:
      intervalLabel.top = "5%";
      intervalLabel.middle = "90%";
      intervalLabel.bottom = "5%";
      break;
    // no default
  }

  const seriesPrepared = [
    {
      name: `Top ${intervalLabel.top}`,
      color: colors.top,
      data: topSerie,
    },
    {
      name: `Mid ${intervalLabel.middle}`,
      color: colors.middle,
      data: midSerie,
    },
    {
      name: `Bottom ${intervalLabel.bottom}`,
      color: colors.bottom,
      data: bottomSerie,
    },
  ];

  const seriesRange = [
    {
      name: `Top ${intervalLabel.top}`,
      color: colors.top,
      data: topRangeSerie,
    },
    {
      name: `Mid ${intervalLabel.middle}`,
      color: colors.middle,
      data: midRangeSerie,
    },
    {
      name: `Bottom ${intervalLabel.bottom}`,
      color: colors.bottom,
      data: bottomRangeSerie,
    },
  ];

  let performanceLabel = "";
  switch (timeframe) {
    case "3_months":
      performanceLabel = "3 months";
      break;
    case "6_months":
      performanceLabel = "6 months";
      break;
    case "12_months":
      performanceLabel = "12 months";
      break;
    // no default
  }

  return chartType === "histogram"
    ? {
        chart: {
          animation: false,
          borderWidth: 0,
          height: serie.length < 3 ? 300 : undefined,
          type: "column",
        },
        title: {
          text: undefined,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: { enabled: true, verticalAlign: "top" },
        scrollbar: { enabled: false },
        navigator: { enabled: false },
        rangeSelector: {
          enabled: false,
        },
        xAxis: {
          visible: true,
          categories: serie.map((item) => item.name),
          startOnTick: true,
          endOnTick: true,
          gridLineColor: "#e6e6e6",
          gridLineWidth: 1,
          labels: { enabled: true },
          tickColor: "#e6e6e6",
        },
        yAxis: {
          tickPositioner: function () {
            const self: any = this;
            var positions: any = [],
              tick = finalMin,
              increment = finalStep;

            if (self.dataMax !== null && self.dataMin !== null) {
              for (tick; tick - increment < self.dataMax; tick += increment) {
                positions.push(truncate(tick, 1));
              }
            }
            return positions;
          },
          // title: { text: "Dispersion" },
          showLastLabel: true,
          opposite: false,
          labels: {
            formatter: function () {
              const self: any = this;
              return (self.value * 100).toFixed(0) + "%";
            },
          },
          startOnTick: false,
          endOnTick: false,
          maxPadding: 0,
          minPadding: 0,
        },
        series: seriesPrepared,
        tooltip: {
          shared: true,
          useHTML: true,
          outside: true,
          formatter: function () {
            const self: any = this;
            var s = "<b>" + self.x + "</b><br/>";
            var top = self.points[0].point;
            var mid = self.points[1].point;
            var bot = self.points[2].point;
            var item = top.custom; // it is the same for top, mid and bottom
            s =
              '<table class="tPeerDispersionBy-tooltip"><tr><th>' +
              self.x +
              "</th><th>Average</th><th>Stocks</th></tr>";
            s += `<tr><td><span style="color:${
              top.series.color
            }">● </span> Top ${intervalLabel.top}</td><td>${(
              item.top * 100
            ).toFixed(2)}%</td><td>${item.topCardinality}</td></tr>`;
            s += `<tr><td><span style="color:${
              mid.series.color
            }">● </span> Mid ${intervalLabel.middle}</td><td>${(
              item.middle * 100
            ).toFixed(2)}%</td><td>${item.middleCardinality}</td></tr>`;
            s += `<tr><td><span style="color:${
              bot.series.color
            }">● </span> Bottom ${intervalLabel.bottom}</td><td>${(
              item.bottom * 100
            ).toFixed(2)}%</td><td>${item.bottomCardinality}</td></tr>`;
            s += "</table>";
            return s;
          },
        },
        plotOptions: {
          series: {
            animation: false,
            dataSorting: {
              enabled: false,
            },
            states: { inactive: { opacity: 1 } },
          },
          column: {
            dataLabels: {
              enabled: false,
              format: "{y}%",
            },
            marker: { enabled: true },
            // stacking: "percent",
          },
        },
      }
    : {
        chart: {
          animation: false,
          borderWidth: 0,
          height: serie.length < 3 ? 300 : undefined,
          type: "columnrange",
        },
        title: {
          text: undefined,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: { enabled: true, verticalAlign: "top" },
        scrollbar: { enabled: false },
        navigator: { enabled: false },
        rangeSelector: {
          enabled: false,
        },
        xAxis: {
          visible: true,
          categories: serie.map((item) => item.name),
          startOnTick: true,
          endOnTick: true,
          gridLineColor: "#e6e6e6",
          gridLineWidth: 1,
          labels: { enabled: true },
          tickColor: "#e6e6e6",
        },
        yAxis: {
          tickPositioner: function () {
            const self: any = this;
            var positions: any = [],
              tick = finalMinRange,
              increment = finalStepRange;

            if (self.dataMax !== null && self.dataMin !== null) {
              for (tick; tick - increment < self.dataMax; tick += increment) {
                positions.push(truncate(tick, 1));
              }
            }
            return positions;
          },
          allowDecimals: true,
          title: { text: "Performance " + performanceLabel },
          plotLines: [
            {
              color: "gray",
              width: 2,
              value: 0,
            },
          ],
          // title: { text: "Dispersion" },
          showLastLabel: true,
          opposite: false,
          labels: {
            formatter: function () {
              const self: any = this;
              return (self.value * 100).toFixed(0) + "%";
            },
          },
          startOnTick: false,
          endOnTick: false,
          maxPadding: 0,
          minPadding: 0,
        },
        series: seriesRange,
        tooltip: {
          shared: true,
          useHTML: true,
          outside: true,
          formatter: function () {
            const self: any = this;
            var s = "<b>" + self.x + "</b><br/>";
            var top = self.points[0].point;
            var mid = self.points[1].point;
            var bot = self.points[2].point;
            var item = top.custom; // it is the same for top, mid and bottom
            s =
              '<table class="tPeerDispersionBy-tooltip"><tr><th>' +
              self.x +
              "</th><th>Min</th><th>Max</th><th>Average</th><th>Stocks</th></tr>";
            s += `<tr><td><span style="color:${
              top.series.color
            }">● </span> Top ${intervalLabel.top}</td><td>${(
              top.low * 100
            ).toFixed(2)}%</td><td>${(top.high * 100).toFixed(2)}%</td><td>${(
              item.top * 100
            ).toFixed(2)}%</td><td>${item.topCardinality}</td></tr>`;
            s += `<tr><td><span style="color:${
              mid.series.color
            }">● </span> Mid ${intervalLabel.middle}</td><td>${(
              mid.low * 100
            ).toFixed(2)}%</td><td>${(mid.high * 100).toFixed(2)}%</td><td>${(
              item.middle * 100
            ).toFixed(2)}%</td><td>${item.middleCardinality}</td></tr>`;
            s += `<tr><td><span style="color:${
              bot.series.color
            }">● </span> Bottom ${intervalLabel.bottom}</td><td>${(
              bot.low * 100
            ).toFixed(2)}%</td><td>${(bot.high * 100).toFixed(2)}%</td><td>${(
              item.bottom * 100
            ).toFixed(2)}%</td><td>${item.bottomCardinality}</td></tr>`;
            s += "</table>";
            return s;
          },
        },
        plotOptions: {
          series: {
            animation: false,
            dataSorting: {
              enabled: false,
            },
            states: { inactive: { opacity: 1 } },
          },
          columnrange: {
            animation: false,
            grouping: false,
            pointPadding: 0,
            pointPlacement: 0,
          },
        },
      };
};

const prepareChartConfigSerie = (series, min, max, minRange, maxRange) => {
  const topSerie: any = [];
  const midSerie: any = [];
  const bottomSerie: any = [];
  const topRangeSerie: any = [];
  const midRangeSerie: any = [];
  const bottomRangeSerie: any = [];

  for (const item of series) {
    topSerie.push({
      custom: item,
      y: item.top,
    });
    midSerie.push({
      custom: item,
      y: item.middle,
    });
    bottomSerie.push({
      custom: item,
      y: item.bottom,
    });

    topRangeSerie.push({
      custom: item,
      low: item.minTop,
      high: item.maxTop,
    });
    midRangeSerie.push({
      custom: item,
      low: item.maxBottom,
      high: item.minTop,
    });
    bottomRangeSerie.push({
      custom: item,
      low: item.minBottom,
      high: item.maxBottom,
    });

    // Prepare min / max
    min = Math.min(min, item.top, item.middle, item.bottom);
    max = Math.max(max, item.top, item.middle, item.bottom);

    // Prepare min / max
    minRange = Math.min(
      minRange,
      item.minTop,
      item.maxTop,
      item.maxBottom,
      item.minTop,
      item.minBottom,
      item.maxBottom
    );
    maxRange = Math.max(
      maxRange,
      item.minTop,
      item.maxTop,
      item.maxBottom,
      item.minTop,
      item.minBottom,
      item.maxBottom
    );
  }

  return {
    min,
    max,
    minRange,
    maxRange,
    topSerie,
    midSerie,
    bottomSerie,
    topRangeSerie,
    midRangeSerie,
    bottomRangeSerie,
  };
};

export default function DispersionChart({
  data,
  interval,
  chartType,
  timeframe,
  sorter,
}: DispersionChartProps) {
  const series = useMemo(() => convertToSerie(data), [data]);
  const [dataSorter, setDataSorter] = useState(sorter ?? []);
  const [serie, setSerie] = useState<any>([]);
  const [options, setOptions] = useState<any>();

  const sortByProperty = useCallback(
    (series, property, dir) =>
      series?.sort((a, b) => {
        if (a[property] > b[property]) {
          return dir === "asc" ? 1 : -1;
        } else if (a[property] < b[property]) {
          return dir === "asc" ? -1 : 1;
        }

        return 0;
      }),
    []
  );

  useEffect(() => {
    let sortProperty = "name";
    let dir = "asc";

    if (dataSorter.length) {
      sortProperty = dataSorter[0].field;
      dir = dataSorter[0].dir;
    }

    const sortedSerie = sortByProperty([...series], sortProperty, dir);
    setSerie(sortedSerie);
  }, [dataSorter, series, sortByProperty]);

  useEffect(() => setDataSorter(sorter ?? []), [sorter]);

  useEffect(() => {
    setOptions(generateChartConfig(serie, interval, chartType, timeframe));
  }, [chartType, interval, serie, timeframe]);

  return <Chart constructorType="chart" options={options} />;
}
