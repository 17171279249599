import { Preferences } from "../../../../api/account/Preferences";
import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import { News } from "../../../../api/compute/News";
import { NewsSelect } from "../../../../api/compute/NewsSelect";
import { Peers } from "../../../../api/compute/Peers";
import { deepClone } from "../../../../deepClone";
import { Environment } from "../../../../Environment";
import { widgetsConfiguration } from "../../widgets/widgetsConfiguration";
export class AlertsDataManager {
  environment: Environment;
  preferences: any;
  apiInstruments: Instruments;
  apiPeers: Peers;
  apiLists: Lists;
  apiNews: News;
  apiNewsSelect: NewsSelect;
  translate: Function;
  apiPreferences: Preferences;

  constructor(environment: Environment, t: Function) {
    this.environment = environment;
    this.preferences = environment.get("preferences").get();
    this.apiInstruments = environment.get("http")["instruments"];
    this.apiPeers = environment.get("http")["peers"];
    this.apiLists = environment.get("http")["lists"];
    this.apiNews = environment.get("http")["news"];
    this.apiNewsSelect = environment.get("http")["newsSelect"];
    this.apiPreferences = environment.get("http")["accountPreferences"];

    this.translate = t;
  }

  async getAlertPageData(
    timeframe: "today" | "lastWeek" | "lastMonth",
    endpoint:
      | "pinned"
      | "listsData"
      | "markets"
      | "peersAndInstrumentsNews"
      | "indexNews"
      | "sectorNews"
  ) {
    switch (endpoint) {
      default:
        return null;

      case "listsData":
        const listsData = await this.getListsAlerts(timeframe);
        return {
          portfoliosData: listsData.portfolios,
          portfoliosTotal: listsData.portfolios.length,
          basketsData: listsData.baskets,
          basketsTotal: listsData.baskets.length,
        };

      case "markets":
        const markets = await this.getMarketsAlerts();
        return { summaryAlertsData: markets };

      case "peersAndInstrumentsNews":
        const peersAndInstrumentsNews: any = await this.getNews(timeframe);
        return {
          marketsNewsData: peersAndInstrumentsNews.peers,
        };

      case "pinned":
        const pinned = await this.getPinnedObjects(timeframe);
        return {
          pinnedData: pinned,
        };

      case "indexNews":
        const indexNews = await this.getNewsUsingSelectAPI(timeframe, "Index");
        return {
          indicesData: indexNews.instruments.index,
        };

      case "sectorNews":
        const sectorNews = await this.getNewsUsingSelectAPI(
          timeframe,
          "Sector"
        );
        return {
          sectorsData: sectorNews.instruments.sector,
        };
    }
  }

  async getNewsFiltered(timeframe, type: "stock" | "etf", filters?) {
    const dataType = type === "stock" ? "Stock" : "ETF";
    const response = await this.apiNewsSelect.get(timeframe, dataType, filters);

    const normalizedResponse = await this.newsToUiModel(response, timeframe);
    return normalizedResponse?.["instruments"]?.[type] ?? null;
  }

  async savePinnedInPreferences(pinnedList) {
    const preferencesData = await this.apiPreferences.get();
    // Refresh preferences
    const userPreferences: any = {
      preferences: null,
    };

    if (preferencesData != null && preferencesData["preferences"] != null) {
      userPreferences["preferences"] = preferencesData["preferences"];
    } else {
      userPreferences["preferences"] = this.preferences;
    }

    userPreferences["preferences"]["alerts"]["pinned"] = pinnedList;

    return await this.apiPreferences.save(userPreferences);
  }

  async saveFiltersInPreferences(filters, type) {
    if (filters) {
      const data = filters;
      const userFilters = {
        filters: {
          stocksFilters: null,
          ETFsFilters: null,
        },
      };

      const { stocksFilters, ETFsFilters } = window.App.user.alertsNewsFilters
        .filters
        ? window.App.user.alertsNewsFilters.filters
        : {
            stocksFilters: { where: [], what: [], size: [] },
            ETFsFilters: {
              whereETF: [],
              assetClass: [],
              marketCap: { left: null, right: null },
            },
          };

      if (type === "stock") {
        userFilters.filters.stocksFilters = data;
        userFilters.filters.ETFsFilters = ETFsFilters;
      } else {
        userFilters.filters.stocksFilters = stocksFilters;
        userFilters.filters.ETFsFilters = data;
      }

      return await this.apiPreferences.save(userFilters, "ALERTS_NEWS_FILTER");
    }
  }

  private async getPinnedObjects(timeframe) {
    try {
      const pinnedObjects = this.preferences?.alerts?.pinned ?? [];
      const pinnedParams: any = {
        instruments: [],
        peers: [[]],
      };
      let pinned: any = null;

      for (var i = 0, length = pinnedObjects.length; i < length; i++) {
        pinned = pinnedObjects[i];

        switch (pinned["type"]) {
          case "instrument": {
            pinnedParams["instruments"].push(pinned["symbol"]);

            break;
          }
          case "peer": {
            pinnedParams["peers"][0].push({
              zDimension: pinned["size"],
              type: pinned["instrumentType"],
              what: pinned["what"],
              where: pinned["where"],
            });

            break;
          } // no default
        }
      }

      const askForInstruments = this.apiInstruments.fetch({
        properties: widgetsConfiguration["pinned/instruments"]["properties"],
        symbols: pinnedParams["instruments"],
        type: "security",
      });
      const askForPeers = this.apiPeers.get(pinnedParams["peers"]);
      const requests = [askForInstruments, askForPeers];

      const results = await Promise.all(requests);

      const pinnedRequests = {
        instruments: results?.[0] ?? null,
        peers: results?.[1] ?? null,
      };

      return this.pinnedDataToUiModel(pinnedRequests, timeframe);
    } catch (error) {
      console.log(error);
    }
  }

  private async getListsAlerts(timeframe) {
    const userListsIds = await this.apiLists._getFilter();
    const subscribed = await this.apiLists._getSubscribed();
    const ids = [...userListsIds, ...subscribed.map((item) => item.id)];
    const listsAlerts = await this.apiLists.alerts({
      listIds: ids,
    });

    return this.prepareListsToUiModel(listsAlerts, timeframe);
  }

  private async getMarketsAlerts() {
    const marketsAlertsResponse = await this.apiInstruments.alerts({
      instrumentTypes: ["currency", "etf", "index", "sector", "stock"],
      timeframes: ["last3Months", "lastMonth", "lastWeek", "today"],
    });

    return this.marketsResponseToUiModel(marketsAlertsResponse);
  }

  private async getNews(timeframe: "today" | "lastWeek" | "lastMonth") {
    const marketsNewsResponse = await this.apiNews.get(timeframe, null);

    return await this.newsToUiModel(marketsNewsResponse, timeframe);
  }

  private async getNewsUsingSelectAPI(timeframe, type) {
    const response = await this.apiNewsSelect.get(timeframe, type, null);

    return await this.newsToUiModel(response, timeframe);
  }

  private pinnedDataToUiModel(response, timeframe) {
    let pinnedObject: any = null;
    let pinnedObjects: any = [];
    let pinnedObjectsMap: any = {};

    // instruments
    let instrument: any = null;
    let instruments: any = response["instruments"]["data"];
    for (let i = 0, length = instruments.length; i < length; i++) {
      instrument = instruments[i];

      pinnedObject = {
        chartUri: this.apiInstruments.chart({
          symbol: instrument["symbol"],
        }),
        currency: instrument["currency"],
        date: instrument["dc"],
        id: instrument["symbol"],
        name: instrument["name"],
        price: instrument["vc"],
        rate: instrument["rc"],
        symbol: instrument["symbol"],
        ticker: instrument["ticker"],
        type: "instrument",
      };

      pinnedObjectsMap[pinnedObject["id"]] = pinnedObject;
    }

    let peer: any = null;
    let peers: any = response["peers"][0];
    for (let i = 0, length = peers.length; i < length; i++) {
      peer = peers[i];

      pinnedObject = {
        alerts: peer["alerts"][timeframe],
        chartData: peer["alerts"],
        id: [peer["where"], peer["what"], peer["size"]].join(","),
        instrumentType: peer["type"],
        size: peer["size"] === "microLarge" ? null : peer["size"],
        tcr: peer["tcr"][timeframe],
        type: "peer",
        what: peer["what"] === "ICB" ? null : peer["what"],
        where: peer["where"] === "WWW" ? null : peer["where"],
      };

      pinnedObjectsMap[pinnedObject["id"]] = pinnedObject;
    }

    // keep preference order
    let order: any = [];
    let pinnedObjectPreference: any = null;
    let pinnedObjectsPreferences: any = this.preferences.alerts?.pinned ?? [];
    for (var i = 0, length = pinnedObjectsPreferences.length; i < length; i++) {
      pinnedObjectPreference = pinnedObjectsPreferences[i];
      switch (pinnedObjectPreference["type"]) {
        case "instrument": {
          order.push(pinnedObjectPreference["symbol"]);

          break;
        }
        case "peer":
        default: {
          order.push(
            [
              pinnedObjectPreference["where"],
              pinnedObjectPreference["what"],
              pinnedObjectPreference["size"],
            ].join(",")
          );
        }
      }
    }

    for (let i = 0, length = order.length; i < length; i++) {
      if (order[i] in pinnedObjectsMap) {
        pinnedObjects.push(pinnedObjectsMap[order[i]]);
      } else {
        // instument not available
        // empty peer, temporary not available on the server
        pinnedObjectPreference = pinnedObjectsPreferences[i];
        switch (pinnedObjectPreference["type"]) {
          case "instrument": {
            pinnedObject = {
              chartUri: null,
              id: pinnedObjectPreference["symbol"],
              name: this.translate("Unavailable"),
              rate: 0,
              symbol: pinnedObjectPreference["symbol"],
              ticker: "-",
              type: "instrument",
            };

            break;
          }
          case "peer": {
            pinnedObject = {
              alerts: { downgrades: 0, upgrades: 0 },
              chartData: null,
              id: [
                pinnedObjectPreference["where"],
                pinnedObjectPreference["what"],
                pinnedObjectPreference["size"],
              ].join(","),
              instrumentType: pinnedObjectPreference["type"],
              size:
                pinnedObjectPreference["size"] === "microLarge"
                  ? null
                  : pinnedObjectPreference["size"],
              tcr: null,
              type: "peer",
              what:
                pinnedObjectPreference["what"] === "ICB"
                  ? null
                  : pinnedObjectPreference["what"],
              where:
                pinnedObjectPreference["where"] === "WWW"
                  ? null
                  : pinnedObjectPreference["where"],
            };

            break;
          }

          // no default
        }
        pinnedObjects.push(pinnedObject);
      }
    }

    return pinnedObjects;
  }

  private prepareListsToUiModel(response, currentTimeframe) {
    const timeframe = currentTimeframe;
    const dataAccessor = {
      upgrades: {
        today: "upgrades",
        lastWeek: "upgrades_W",
        lastMonth: "upgrades_M",
      },

      downgrades: {
        today: "downgrades",
        lastWeek: "downgrades_W",
        lastMonth: "downgrades_M",
      },
    };

    const upgradesAccessor = dataAccessor["upgrades"][timeframe];
    const downgradesAccessor = dataAccessor["downgrades"][timeframe];

    const alerts = {
      portfolios: response["portfolios"].map((list) => {
        const data = {
          tcr: {
            today: list["TCR"],
            yesterday: list["TCR_D"],
          },
          upgrades: list[upgradesAccessor],
          downgrades: list[downgradesAccessor],
          moversUp: list["ABnewHigh"],
          moversDown: list["CDnewLow"],
          name: list["name"],
          type: list["type"],
          id: list["id"],
          isReadOnly: list["isReadOnly"],
        };

        return data;
      }),

      baskets: response["baskets"].map((list) => {
        const data = {
          tcr: {
            today: list["TCR"],
            yesterday: list["TCR_D"],
          },
          upgrades: list[upgradesAccessor],
          downgrades: list[downgradesAccessor],
          moversUp: list["ABnewHigh"],
          moversDown: list["CDnewLow"],
          name: list["name"],
          type: list["type"],
          id: list["id"],
          isReadOnly: list["isReadOnly"],
        };

        return data;
      }),
    };

    return alerts;
  }

  private marketsResponseToUiModel(marketsResponse) {
    let response: any = [];
    let item: any = null;
    let key: any = null;
    let order: any = ["stock", "index", "etf", "sector", "currency"];

    for (let i = 0, length = order.length; i < length; i++) {
      key = order[i];
      item = {
        timeframes: deepClone(marketsResponse[key]),
        id: key,
        label: null,
      };

      switch (key) {
        case "currency": {
          item["label"] = this.translate("instrument_currency_plural");

          break;
        }
        case "etf": {
          item["label"] = this.translate("instrument_etf_plural");

          break;
        }
        case "index": {
          item["label"] = this.translate("instrument_index_plural");

          break;
        }
        case "sector": {
          item["label"] = this.translate("instrument_sector_plural");

          break;
        }
        case "stock": {
          item["label"] = this.translate("instrument_stock_plural");

          break;
        } // no default
      }

      response.push(item);
    }

    return response;
  }

  private async newsToUiModel(response, timeframe) {
    if (response.peers != null) {
      const marketsNewsResponse = await this.marketsNewsToUiModel(
        response["peers"],
        timeframe
      );

      const marketsNews = {
        instruments: this.instrumentsNewsToUiModel(response["instruments"]),
        peers: marketsNewsResponse,
      };
      return marketsNews;
    } else {
      var _response = {
        instruments: this.instrumentsNewsToUiModel(response["instruments"]),
      };
      return _response;
    }
  }

  private instrumentsNewsToUiModel(instruments) {
    //
    // Replace country, etfclass, etfgeo and icb codes with strings
    // This enables a correct client-side sorting
    //
    var instrumentsPrepared = {
      commodity: [],
      currency: [],
      etf: [],
      index: [],
      sector: [],
      stock: [],
    };

    var taxonomies = this.environment.get("setup")["taxonomies"];
    const taxonField = this.environment.get("setup")["taxonomyFields"];
    const format = this.environment.get("formatter");
    var taxonomyWhatEtf = taxonomies[taxonField["ETF"]["etfclass"]];
    var taxonomyWhatInstruments = taxonomies[taxonField["security"]["sector"]];
    var taxonomyWhere = taxonomies[taxonField["security"]["country"]];
    var taxonomiesETFWhere = taxonomies[taxonField["ETF"]["etfgeo"]];

    var _instrument: any = null;
    var _instruments: any = null;
    for (let instrumentType in instruments) {
      _instruments = deepClone(instruments[instrumentType]);

      for (var i = 0, length = _instruments.length; i < length; i++) {
        _instrument = _instruments[i];

        _instrument["country"] = format.custom("taxon", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomyWhere,
          },
          output: "TEXT",
          value: _instrument["country"],
          valueHelper: null,
        });

        _instrument["etfclass"] = format.custom("taxon", {
          options: {
            ancestorAtLevel: "1 Industry",
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomyWhatEtf,
          },
          output: "TEXT",
          value: _instrument["etfclass"],
          valueHelper: null,
        });

        _instrument["etfgeo"] = format.custom("taxon", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomiesETFWhere,
          },
          output: "TEXT",
          value: _instrument["etfgeo"],
          valueHelper: null,
        });

        // add industry property
        _instrument["industry"] = format.custom("taxon", {
          options: {
            ancestorAtLevel: "1 Industry",
            notAvailable: {
              input: null,
              output: "",
            },
            taxonomy: taxonomyWhatInstruments,
          },
          output: "TEXT",
          value: _instrument["icb"],
          valueHelper: null,
        });

        instrumentsPrepared[instrumentType].push(_instrument);
      }
    }

    return instrumentsPrepared;
  }

  private async marketsNewsToUiModel(peers, timeframe) {
    //
    // Sort by (multidimensional)
    //  - marketcap
    //  - a concatenation of industry (1 Industry) and sector
    //    (3 Sector) names. See getWhatLevel inner function
    //  - name
    //
    // Peers are sorted by parent marketcap: parent marketcap is set to
    // all children. If there are orphans, it will be performed an
    // additional requet to retrieve parent (marketcap info to be set)
    //
    // Multidimensional sort is performed widget side using dgrid/dstore
    //
    // Here data are only prepared
    //

    let _peer: any = null;
    let _peerNameMeta: any = null;
    let _peers: any = [];
    const baseFlagImageUrl = appConfig.baseUrlImages + "flags/";
    let peer: any = null;
    const taxonomies = this.environment.get("taxonomies");

    const whereRank = this.whereRank(taxonomies.array.where);
    const taxonUtils = this.environment.get("taxon");

    // helper function
    function getWhatLevel(_taxonomy, taxonId) {
      const taxon = _taxonomy.find((item) => item.id === taxonId);
      //
      // taxonomy works with hashmap. It needs to be refactored
      // with trendrating/core/Store
      //
      const whatTaxonomy = window.App["taxonomies"]["ICB"];

      if (taxon["type"] === "3 Sector") {
        const parentId: any = taxonUtils.getAncestorAtLevel(
          whatTaxonomy,
          taxonId,
          "1 Industry"
        );
        return [whatTaxonomy[parentId]["name"], taxon["name"]]
          .join("")
          .replace(/\s/gi, "");
      }

      return taxon["name"].replace(/\s/gi, "");
    }

    for (let i = 0, length = peers.length; i < length; i++) {
      _peer = peers[i];
      _peerNameMeta = taxonUtils.getWhatAndWhereLabel({
        what: _peer["what"] === "ICB" ? null : _peer["what"],
        whatTaxonomy: taxonomies.what,
        where: _peer["where"] === "WWW" ? null : _peer["where"],
        whereTaxonomy: taxonomies.where,
      });

      peer = {
        countryFlagUri:
          _peerNameMeta["whereType"] === "Country"
            ? baseFlagImageUrl + _peer["where"] + ".png"
            : null,

        downgrades: _peer["alerts"][timeframe]["downgrades"],
        id: _peer["id"],
        isOrphan: false,
        marketcap: _peer["info"]["marketcap"],
        name: null,
        nameMeta: _peerNameMeta,
        size: _peer["size"],
        tcr: _peer["tcr"]["today"],
        tcrPrevious:
          timeframe === "today"
            ? _peer["tcr"]["yesterday"]
            : _peer["tcr"][timeframe],
        type: "peer",
        upgrades: _peer["alerts"][timeframe]["upgrades"],
        what: _peer["what"] === "ICB" ? null : _peer["what"],
        whatLevel: getWhatLevel(taxonomies.what, _peer["what"]),
        where: _peer["where"],
        whereRank: whereRank[_peer["where"]],
      };

      _peers.push(peer);
    }

    // looking for orphans and lift them up to the parent level
    var parent = null;
    for (let i = 0, length = _peers.length; i < length; i++) {
      _peer = _peers[i];
      parent = _peer["parent"];

      if (_peer["parent"] != null && parent == null) {
        _peer["countryFlagUri"] = baseFlagImageUrl + _peer["where"] + ".png";
        _peer["isOrphan"] = true;
        _peer["parent"] = null;
      } else if (parent != null) {
        _peer["marketcap"] = parent["marketcap"];
      }
    }

    //
    // saving information in order to reconciliate orphans with
    // their parent marketcap
    //
    const orphans: any = {};
    // formatting name: this enables a correct client-side sorting
    for (let i = 0, length = _peers.length; i < length; i++) {
      _peer = _peers[i];

      if (_peer["nameMeta"]["what"] == null) {
        _peer["name"] = _peer["nameMeta"]["where"];
      } else {
        _peer["name"] = [
          _peer["nameMeta"]["where"],
          "-",
          _peer["nameMeta"]["what"],
        ].join(" ");
      }

      if (_peer["isOrphan"]) {
        if (!(_peer["where"] in orphans)) {
          orphans[_peer["where"]] = [];
        }
        orphans[_peer["where"]].push(_peer);
      }
    }

    if (Object.keys(orphans).length > 0) {
      const paramsPeer: any = [[]];
      for (let id in orphans) {
        paramsPeer[0].push({
          size: null,
          type: "Stock",
          what: null,
          where: id,
        });
      }

      const response = await this.apiPeers.get(paramsPeer);
      return this.getOrphansParents(response, orphans, _peers);
    } else {
      return _peers;
    }
  }

  private getOrphansParents(response, orphans, _peers) {
    const parents = response[0];
    let parent: any = null;
    let parentWhere: any = null;
    for (var i = 0, lengthI = parents.length; i < lengthI; i++) {
      parent = parents[i];
      parentWhere = parent["where"];

      for (var j = 0, lengthJ = orphans[parentWhere].length; j < lengthJ; j++) {
        // exploiting object reference:
        // it modifies _peers
        orphans[parentWhere][j]["marketcap"] = parent["info"]["marketcap"];
      }
    }

    return _peers;
  }

  private whereRank(whereArray) {
    let whereRank: any = {};

    const store = whereArray;
    const markets = store.filter((item) => item.type === "Country");

    let areaId: any = null;
    let market: any = null;

    for (let i = 0, length = markets.length; i < length; i++) {
      market = markets[i];
      const regionId = market["parent"];
      areaId = store.find((item) => item.id === regionId)["parent"];

      switch (areaId) {
        case "M_D": {
          whereRank[market["id"]] = 3;

          break;
        }
        case "M_E": {
          whereRank[market["id"]] = 2;

          break;
        }
        case "M_F": {
          whereRank[market["id"]] = 1;

          break;
        }
        default: {
          whereRank[market["id"]] = 0;
        }
      }
    }

    return whereRank;
  }
}
