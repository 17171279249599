import axios from "axios";
import insane from "insane";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ProductClass } from "../../../../types/Defaults";

type HelpContentGlossaryProps = {
  baseUrl: string;
  productClass: ProductClass;
};

type GlossaryContentProps = {
  children: ReactNode;
  html: string;
};

type GlossarySectionProps = {
  children: ReactNode;
  name: string;
  selectedSection: string | null;
  selectedSubsection: string | null;
  title: string;
};

type GlossarySubsectionProps = {
  name: string;
  selectedSection?: string | null;
  selectedSubsection?: string | null;
  title: string;
};

type SanitizedProps = {
  html: string;
};

const Sanitized = ({ html }: SanitizedProps) => (
  <div
    dangerouslySetInnerHTML={{
      __html: insane(html, {
        allowedAttributes: {
          img: ["alt", "class", "src"],
          span: ["class", "style"],
          div: ["class", "style"],
        },
        allowedTags: [
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "p",
          "strong",
          "em",
          "a",
          "b",
          "i",
          "span",
          "div",
          "br",
          "u",
          "img",
          "table",
          "thead",
          "tbody",
          "tfoot",
          "tr",
          "th",
          "td",
          "sup",
          "sub",
          "iframe",
        ],
      }),
    }}
  />
);

const GlossaryContent = ({ children, html }: GlossaryContentProps) => {
  return (
    <div className="tPageHelpIndex-contentPage tHelpPage" data-id="glossary">
      <div className="tHelpPage-content tHelpPage-content--dual">
        <div className="tHelpPage-content-sidebar">
          <ul className="tHelpTree">{children}</ul>
        </div>
        <div className="tHelpPage-content-area">
          <div className="tHelpPage-target">
            <Sanitized html={html} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GlossarySection = ({
  children,
  name,
  selectedSection,
  selectedSubsection,
  title,
}: GlossarySectionProps) => {
  return (
    <li
      className={`tHelpTree-item ${selectedSection === name ? "selected" : ""}`}
    >
      <NavLink to={`/app/help/glossary/${name}/`}>
        <span className="tHelpTree-title">{title}</span>&nbsp;
        <i className="tHelpTree-arrow"></i>
      </NavLink>
      <ul className="tHelpSubtree">
        {Children.map(children, (child) => {
          return isValidElement(child)
            ? cloneElement<any>(child, {
                selectedSection,
                selectedSubsection,
              })
            : child;
        })}
      </ul>
    </li>
  );
};

const GlossarySubsection = ({
  name,
  selectedSection,
  selectedSubsection,
  title,
}: GlossarySubsectionProps) => {
  return (
    <li
      className={`tHelpSubtree-item ${
        selectedSubsection === name ? "selected" : ""
      }`}
    >
      <NavLink to={`/app/help/glossary/${selectedSection}/${name}/`}>
        {title}
      </NavLink>
    </li>
  );
};

const loadSubsectionTemplate = (
  baseUrl: string,
  section: string | null,
  subsection: string | null
): Promise<string> => {
  if (section == null) {
    return Promise.resolve("");
  }
  var file = section;
  if (subsection != null) {
    file += "/" + subsection;
  } else {
    // TODO Cannot have empty content (for now)
    return Promise.resolve("");
  }

  return axios
    .get(baseUrl + file + ".txt", {
      responseType: "text",
      params: {
        ts: new Date().getTime(),
      },
    })
    .then((response) => {
      baseUrl = baseUrl.replace(/\/+$/, ""); // remove trailing (multiple) slashes
      /* eslint-disable no-template-curly-in-string */
      return response.data
        .replaceAll("${baseUrl}", baseUrl)
        .replaceAll("${mediaUrl}", baseUrl + "/images");
    })
    .catch((error) => {
      return "Cannot load content";
    });
};

export const HelpContentGlossary = ({
  baseUrl,
  productClass,
}: HelpContentGlossaryProps) => {
  const [html, setHtml] = useState("");
  const urlParams = useParams();
  const navigate = useNavigate();
  const { section, subsection } = urlParams;

  useEffect(() => {
    if (section == null) {
      navigate("trend-analytics");
    } else if (subsection == null) {
      switch (section) {
        case "trend-analytics":
          navigate("rating");
          break;
        case "strategy-builder-analytics":
          navigate("annualized-performance");
          break;
        case "fundamentals-analytics":
          navigate("price-earnings-ratio");
      }
    }
  }, [navigate, section, subsection]);

  useEffect(() => {
    let unmounted = false;
    setHtml("Loading...");
    loadSubsectionTemplate(baseUrl, section!, subsection!)
      .then((data) => {
        if (!unmounted) {
          setHtml(data);
        }
      })
      .catch((error: string) => {
        if (!unmounted) {
          setHtml("Cannot load content");
        }
      });
    return () => {
      unmounted = true;
    };
  }, [baseUrl, section, subsection]);

  return (
    <GlossaryContent html={html}>
      <GlossarySection
        name="trend-analytics"
        selectedSection={section!}
        selectedSubsection={subsection!}
        title="Trend Analytics"
      >
        <GlossarySubsection name="rating" title="Rating" />
        <GlossarySubsection name="smart-momentum" title="Smart Momentum" />
        <GlossarySubsection name="retracement" title="Retracement" />
        <GlossarySubsection name="trend-strength" title="Trend Strength" />
        <GlossarySubsection name="duration" title="Duration" />
        <GlossarySubsection name="magnitude" title="Magnitude" />
        <GlossarySubsection name="upi" title="UPI" />
        <GlossarySubsection
          name="trend-capture-rating"
          title="Trend Capture Rating"
        />
        <GlossarySubsection
          name="performance-since-trend"
          title="Performance since trend"
        />
        <GlossarySubsection
          name="performance-since-rated"
          title="Performance since rated"
        />
      </GlossarySection>
      <GlossarySection
        name="strategy-builder-analytics"
        selectedSection={section!}
        selectedSubsection={subsection!}
        title="Strategy Builder Analytics"
      >
        <GlossarySubsection
          name="annualized-performance"
          title="Annualized performance"
        />
        <GlossarySubsection
          name="average-yearly-drawdown"
          title="Average yearly drawdown"
        />
        <GlossarySubsection name="beta-capm" title="Beta (CAPM)" />
        <GlossarySubsection
          name="cumulative-performance"
          title="Cumulative performance"
        />
        <GlossarySubsection
          name="information-ratio"
          title="Information ratio"
        />
        <GlossarySubsection name="liquidity" title="Liquidity" />
        <GlossarySubsection
          name="losing-average-spread"
          title="Losing average spread"
        />
        <GlossarySubsection name="losing-months" title="Losing months" />
        <GlossarySubsection
          name="max-consecutive-negative-months"
          title="Max consecutive negative months"
        />
        <GlossarySubsection
          name="max-consecutive-positive-months"
          title="Max consecutive positive months"
        />
        <GlossarySubsection name="max-drawdown" title="Max Drawdown" />
        <GlossarySubsection
          name="monthly-standard-deviation"
          title="Monthly standard deviation"
        />
        <GlossarySubsection
          name="one-way-yearly-turnover"
          title="One-way yearly turnover"
        />
        <GlossarySubsection
          name="percent-positive-months"
          title="% positive months"
        />
        <GlossarySubsection name="sharpe-ratio" title="Sharpe ratio" />
        <GlossarySubsection name="sortino-ratio" title="Sortino ratio" />
        <GlossarySubsection name="sterling-ratio" title="Sterling ratio" />
        <GlossarySubsection name="tracking-error" title="Tracking error" />
        <GlossarySubsection name="treynor-ratio" title="Treynor ratio" />
        <GlossarySubsection
          name="winning-average-spread"
          title="Winning average spread"
        />
        <GlossarySubsection name="winning-months" title="Winning months" />
        <GlossarySubsection
          name="yearly-average-performance"
          title="Yearly average performance"
        />
      </GlossarySection>
      {productClass !== "standard" ? (
        <GlossarySection
          name="fundamentals-analytics"
          selectedSection={section!}
          selectedSubsection={subsection!}
          title="Fundamentals Analytics"
        >
          <GlossarySubsection name="price-earnings-ratio" title="P/E Ratio" />
          <GlossarySubsection name="price-sales-ratio" title="P/S Ratio" />
          <GlossarySubsection name="price-bookvalue-ratio" title="P/B Ratio" />
          <GlossarySubsection name="price-cashflow-ratio" title="P/CF Ratio" />
          <GlossarySubsection name="price-dividends-ratio" title="P/D Ratio" />
          <GlossarySubsection
            name="sales-3-months-growth-rate"
            title="3M Sales Growth"
          />
          <GlossarySubsection
            name="sales-12-months-growth-rate"
            title="12M Sales Growth"
          />
          <GlossarySubsection
            name="earnings-3-months-growth-rate"
            title="3M Earnings Growth"
          />
          <GlossarySubsection
            name="earnings-12-months-growth-rate"
            title="12M Earnings Growth"
          />
        </GlossarySection>
      ) : null}
    </GlossaryContent>
  );
};
