import { subYears, subDays, format } from "date-fns";
import { deepClone } from "../../../../../../../deepClone";
export const getYesterdayMinus_N_Years = (n: number) => {
  // today date
  const today = new Date();
  // today date - 8 years
  const minus_N_years = subYears(today, n);
  // Sottrai un giorno dalla data ottenuta
  const yesterdayMinus_N_years = subDays(minus_N_years, 1);
  return format(yesterdayMinus_N_years, "yyyy-MM-dd");
};

export const defaultStrategy = {
  allocation: {
    weightInCashMin: 1,
    weightInCashMax: 1,
    weightCappingPeer: null,
    weightCappingSecurity: null,
  },
  backtesting: {
    inceptionDate: null,
    inceptionValue: 100,
    period: {
      type: "YEAR",
      value: 10,
    },
  },
  hedging: null,
  ranking: [
    {
      function: "value",
      functionParams: null,
      isRemovable: true,
      operator: "sortByPerformance",
      operatorParams: {
        value: "desc",
      },
      property: "f_sps_CC_g_3",
    },
  ],
  selection: [
    {
      function: "value",
      functionParams: null,
      property: "rc",
      operator: "equalToRate",
      operatorParams: {
        value: {
          A: true,
          B: true,
          C: false,
          D: false,
        },
      },
    },
  ],
  holding: null,
  strategy: {
    benchmark: "SP500_SNC_USD_998434",
    currency: "local",
    performance: "NONE",
    rebalance: "20_DAYS",
    holdings: 30,
  },
  tracking: null,
  universe: {
    whiteList: null,
    selection: null,
    screening: {
      eligibility: {
        cardinality: 500,
        isEnabled: true,
        sortBy: "desc",
      },
      instrumentType: "stock",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: ["US"],
        stockClassification: [],
      },
      whereTarget: null,
    },
    trimOutliers: false,
    blacklist: null,
  },
  weighting: {
    rotation: null,
    smartBeta: null,
    weightingSchema: "WEIGHT_EQUAL",
    weightingSchemaExistingPositions: "WEIGHT_EXISTING_POSITIONS_REBALANCE",
  },
  askBeforeInsightsRedirect: false,
};
export type UI_CONSTRAINTS_TYPE = {
  strategy: {
    benchmarks: {
      index: {
        enabled: boolean;
        list: string[];
      };
      neutral: {
        enabled: boolean;
      };
      neutralEqual: {
        enabled: boolean;
      };
      blended: {
        enabled: boolean;
      };
    };
  };
  investementUnivese: {
    additionalContraints: {
      enabled: boolean;
    };
    filterOutlayers: {
      enabled: boolean;
      default: boolean;
    };
    whiteList: boolean;
  };
  holdingRules: {
    enabled: boolean;
  };
  hedging: {
    enabled: boolean;
  };
  weightingRules: {
    rotationRules: {
      enabled: boolean;
    };
  };
  backtesting: {
    inceptionValue: {
      enabled: boolean;
    };
  };
};

export const getDefaultStrategy = () => {
  const strategy = deepClone(defaultStrategy);
  // const defaultPeriodValue = strategy.backtesting.period.value;
  // const newInceptionDate = getYesterdayMinus_N_Years(defaultPeriodValue);
  // strategy.backtesting.inceptionDate = newInceptionDate;
  return strategy;
};
