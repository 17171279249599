/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/httpAll
 * @summary Multiple promises call, using a map
 *
 */

export const httpAll = async (promiseMap: Record<string, any> | any[]) => {
    // Manage special case with a simple array
    if (Array.isArray(promiseMap)) {
        return Promise.all(promiseMap);
    }

    const promises: any = [];
    const promisesKeys: string[] = [];

    Object.keys(promiseMap).forEach((key) => {
        promisesKeys.push(key);
        promises.push(promiseMap[key]);
    });

    // TODO it can be used allSettled, but the result will contain
    // a map with status: 'fulfilled' + 'value' or 'rejected' + 'reason'

    const response: any = await Promise.all(promises);

    const result: any = {};
    promisesKeys.forEach((key, index) => {
        result[key] = response[index];
    });
    return result;
};
