import { deepClone } from "../../../../../deepClone";

export type WidgetTag =
  | "universe"
  | "market"
  | "domicile"
  | "sector"
  | "size"
  | "rating"
  | "alert"
  | "newHL"
  | "customAnalytics";

type RatingState = {
  A: boolean;
  B: boolean;
  C: boolean;
  D: boolean;
};

type ConstraintsAnalyticsRange = {
  field: string;
  value: any;
  label: string;
  isPerc: boolean;
  widget: string;
}[];

export type WidgetInfo = {
  label: string;
  field: string | string[];
  display: { widget: string; structure?: any; widgetOptions?: any };
  hidden?: boolean;
  tagOrder?: { [key: string]: number };
};
export class FilterBarHelper {
  formatWidgetState(widgetType: WidgetTag) {
    switch (widgetType) {
      case "rating":
        return (st: RatingState) => this.mapRatingToClientApiSyntax(st);

      case "alert":
        return (alert: string) => this.mapAlertsInClientApiSyntax(alert);

      case "newHL":
        return (newHL) => this.mapNewHlInClientApiSyntax(newHL);

      case "customAnalytics":
        return (constraints) =>
          this.mapAnalyticsFiltersInClientApiSyntax(constraints);

      default:
        return () => null;
    }
  }

  getWidgetContextualParams(widget: WidgetInfo, formatter, usage, options) {
    const params: any = {
      formatter,
      usage,
      field: null,
    };

    let field: any = null;
    if (Array.isArray(widget.field)) {
      field = widget.field[0];
      params.fieldAddition = widget.field;
    } else {
      field = widget.field;
    }

    params.field = field;

    if (widget.display.structure) {
      if (Array.isArray(widget.display.structure)) {
        let taxonomies: any = [];

        for (
          var j = 0, lengthJ = widget.display.structure.length;
          j < lengthJ;
          j++
        ) {
          taxonomies.push([]);
          for (const key in window.App.taxonomies[
            widget.display.structure[j]
          ]) {
            taxonomies[j].push(
              window.App.taxonomies[widget.display.structure[j]][key]
            );
          }
        }

        params["options"] = deepClone(taxonomies);
      } else {
        let taxonomy: any = [];

        for (const key in window.App.taxonomies[widget.display.structure]) {
          taxonomy.push(window.App.taxonomies[widget.display.structure][key]);
        }
        params["options"] = deepClone(taxonomy);
      }
    }

    switch (field) {
      case "country": {
        if (
          window.App.user.preferences &&
          "preferences" in window.App.user.preferences &&
          "screening" in window.App.user.preferences.preferences &&
          window.App.user.preferences.preferences.screening &&
          "markets" in window.App.user.preferences.preferences.screening
        ) {
          var value = window.App.user.preferences.preferences.screening.markets;
          if (value != null) {
            params["value"] = {
              market: value.ids ? value.ids : [],
              stockClassification: [],
            };
          }
        }

        break;
      }
      case "lhl": {
        params["options"] = options.get("HIGH_LOW");
        break;
      }
      case "lr": {
        params["options"] = options.get("ALERT");
        break;
      }
      case "lduration": {
        params["options"] = options.get("NOTIFICATION");
        break;
      } // no default
    }

    var widgetOptions = widget.display.widgetOptions;
    if (widgetOptions) {
      for (const key in widgetOptions) {
        params[key] = widgetOptions[key];
      }
    }

    params["label"] = widget.label;

    if ("tagOrder" in widget) {
      params["tagOrder"] = widget.tagOrder;
    }

    return [widget.display.widget, params];
  }

  private mapRatingToClientApiSyntax(state: RatingState) {
    if (state) {
      // If there is at least one filter that is active
      if (
        state.A === true ||
        state.B === true ||
        state.C === true ||
        state.D === true
      ) {
        const value: {
          dimension: string;
          segments: any;
        } = { dimension: "rc", segments: [] };
        const remapKeysInRating: number[] = Object.entries(state).reduce(
          (prev: number[], [key, value]) => {
            if (value) {
              switch (key) {
                case "A":
                  prev.push(2);
                  break;

                case "B":
                  prev.push(1);
                  break;

                case "C":
                  prev.push(-1);
                  break;

                case "D":
                  prev.push(-2);
                  break;
              }
            }

            return prev;
          },
          []
        );

        const segment = remapKeysInRating.map((item) => ({
          le: item,
          ge: item,
        }));
        value.segments = segment;

        return { filter: [value], type: "ranges" };
      } else {
        return null;
      }
    }

    return null;
  }

  private mapAlertsInClientApiSyntax(alert: string) {
    let value: any = null;
    if (alert && alert !== "None") {
      switch (alert) {
        case "MOVERS_NEGATIVE": {
          // OLD Syntax before the inauguration of the mv analytic
          //
          // lr >= 19 && px == 0 && rc < 0
          //
          // value = [
          //     {
          //         dimension: "lr",
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 19,
          //             },
          //         ],
          //     },

          //     {
          //         dimension: "px", // px
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          //     {
          //         dimension: "rc",
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: null,
          //             },
          //         ],
          //     },
          // ];

          value = [{ dimension: "mv", segments: [{ ge: null, le: -19 }] }];

          break;
        }
        case "MOVERS_POSITIVE": {
          // OLD Syntax before the inauguration of the mv analytic
          //
          // lr >= 19 && px == 0 && rc >  0
          //
          // value = [
          //     {
          //         dimension: "lr", // lr
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 19,
          //             },
          //         ],
          //     },

          //     {
          //         dimension: "px", // px
          //         segments: [
          //             {
          //                 le: 0,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          //     {
          //         dimension: "rc", // rc
          //         segments: [
          //             {
          //                 le: null,
          //                 ge: 0,
          //             },
          //         ],
          //     },
          // ];

          value = [{ dimension: "mv", segments: [{ le: null, ge: 19 }] }];

          break;
        }
        default: {
          let rawValue: any = alert;
          let isUpgrade = /^U_\d{1,2}$/gi.test(rawValue);
          rawValue = rawValue.replace(/U_|D_/gi, "");
          value = [
            {
              dimension: "lr",
              segments: [
                {
                  le: Math.abs(rawValue),
                  ge: 0,
                },
              ],
            },
            {
              dimension: "direction",
              segments: [
                {
                  le: isUpgrade ? 4 : 0,
                  ge: !isUpgrade ? -4 : 0,
                },
              ],
            },
          ];
        }
      }
    }

    return { filter: value, type: "ranges" };
  }

  private mapNewHlInClientApiSyntax(hl) {
    var rawValue: any = parseInt(hl);
    var value: any = null;

    if (rawValue && rawValue !== "None") {
      if (rawValue > 0) {
        value = [
          {
            dimension: "lhigh",
            segments: [
              {
                le: null,
                ge: rawValue,
              },
            ],
          },
        ];
      } else {
        value = [
          {
            dimension: "llow",
            segments: [
              {
                le: null,
                ge: Math.abs(rawValue),
              },
            ],
          },
        ];
      }
    }

    return { filter: value, type: "ranges" };
  }

  private mapAnalyticsFiltersInClientApiSyntax(
    activeConstraints: ConstraintsAnalyticsRange
  ) {
    if (activeConstraints && activeConstraints.length) {
      const ranges: any = { type: "ranges", filter: [] };
      const filters: any = { type: "filters", filter: [] };

      activeConstraints.forEach((item) => {
        switch (item.widget) {
          case "taxonomyFilter": {
            filters.filter.push({
              dimension: item.field,
              segments: item.value,
            });
            break;
          }

          case "minMax": {
            if (item.field !== "tradedvalue") {
              ranges.filter.push({
                dimension: item.field,
                segments: [item.value],
              });
            } else {
              const value = {};

              if ("min" in item.value && item.value.min != null) {
                value["min"] = item.value.min * 1e6;
              }

              if ("max" in item.value && item.value.max != null) {
                value["max"] = item.value.max * 1e6;
              }

              ranges.filter.push({
                dimension: item.field,
                segments: [value],
              });
            }
            break;
          }

          case "newHighNewLow": {
            const filterHL = this.mapNewHlInClientApiSyntax(
              item.value ?? "None"
            );
            ranges.filter.push(...filterHL.filter);
          }
        }
      });

      const result: any = [];

      if (ranges.filter.length) {
        result.push(ranges);
      }

      if (filters.filter.length) {
        result.push(filters);
      }

      return result;
    }

    return null;
  }
}
