import InputNumber from "../InputNumber/InputNumber";

type InputNumberPercenteageProps = {
  inputLabel?: string;
  size?: "small" | "medium";
  initVal?: number;
  setOuterState: (val: number | null) => void;
  constraints?: {
    maxVal?: number;
    minVal?: number;
  };
};

export default function InputNumberPercentage({
  inputLabel = "",
  size = "small",
  initVal = 0,
  constraints,
  setOuterState,
}: InputNumberPercenteageProps) {
  return (
    <InputNumber
      getIsValid={(e) => console.log(e)}
      initVal={initVal}
      constraints={constraints}
      size={size}
      inputLabel={inputLabel}
      isPercentage={true}
      setOuterState={setOuterState}
      isFloating={true}
    />
  );
}
