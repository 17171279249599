import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../api/compute/Taxon";
import { Chart } from "../../../../../components/Chart";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { _rate } from "../../../../../trendrating/formatter/_rate";

type ListViewerTileItemAllocationProps = {
  value: any;
};

export function ListViewerTileItemAllocation({
  value,
}: ListViewerTileItemAllocationProps) {
  const nodeChartRef = useRef<HTMLDivElement>(null);
  const nodeFeedbackRef = useRef<HTMLDivElement>(null);
  const nodeWhereTable = useRef<HTMLTableSectionElement>(null);
  const nodeWhatTable = useRef<HTMLTableSectionElement>(null);
  const nodeTooltip = useRef<HTMLDivElement>(null);
  const domNode = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const formatter = useFormatter();
  const environment = useEnvironment();
  const rawTaxonomies = useMemo(() => {
    return environment.get("rawTaxonomies");
  }, [environment]);

  const [leftChartConfig, setLeftChartConfig] = useState<any>();
  const [rightChartConfig, setRightChartConfig] = useState<any>();

  const onPieMouseOver = useCallback(
    (item, event) => {
      const data = item;
      const ratingScale = _rate.trendCaptureRating;
      const taxonFields = environment.get("setup")["taxonomyFields"];
      var taxonomies = [
        rawTaxonomies[taxonFields["security"]["country"]],
        rawTaxonomies[taxonFields["security"]["sector"]],
        rawTaxonomies[taxonFields["ETF"]["etfgeo"]],
        rawTaxonomies[taxonFields["ETF"]["etfgeo"]],
      ];

      let rating = "-";
      let ratingClass = "U";
      if (data["rate"] != null) {
        rating = formatter.tcr(data["rate"], "HTML");
        ratingClass = ratingScale[data["rate"]]["rateClass"];
      }

      const cssClassBase = "tListViewerTileItemAllocation--";
      const cssClass = cssClassBase + ratingClass;

      var label = formatTaxonPrefixingParent(
        getTaxonById(data["id"], taxonomies, null),
        taxonomies,
        data["type"] === "where" ? "Region" : "3 Sector"
      );

      var weight = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: 0,
            output: 0,
          },
        },
        output: "HTML",
        value: data["weight"],
        valueHelper: null,
      });

      if (domNode.current) {
        domNode.current.classList.remove(
          cssClassBase + "A",
          cssClassBase + "B",
          cssClassBase + "C",
          cssClassBase + "D"
        );
        domNode.current.classList.add(cssClass);
      }

      var mouseCursorOffset = 10;
      const pieChart = event.target.graphic.element;
      var mousePositionX =
        pieChart.getBoundingClientRect().left +
        pieChart.getBoundingClientRect().width;
      var mousePositionY =
        pieChart.getBoundingClientRect().top + mouseCursorOffset;

      if (nodeTooltip.current) {
        nodeTooltip.current.style.left = mousePositionX + "px";
        nodeTooltip.current.style.top = mousePositionY + "px";

        nodeTooltip.current.classList.remove("hide");
        nodeTooltip.current.innerHTML = [
          rating,
          "<br/>",
          label,
          "<br/>",
          weight,
        ].join("");
      }
    },
    [environment, formatter, rawTaxonomies]
  );

  const onPieMouseOut = useCallback((event) => {
    if (nodeTooltip.current) {
      nodeTooltip.current.innerHTML = "";
      nodeTooltip.current.classList.add("hide");
    }
  }, []);

  const createChartConfig = useCallback(
    (data) => {
      const dataSerie: any = [];
      let item;
      let rateMeta;
      const ratingScale = _rate.trendCaptureRating;
      let totalWeight = 0;

      for (let i = 0, length = data.length; i < length; i++) {
        item = data[i];
        rateMeta = ratingScale[item.rate != null ? String(item.rate) : "U"];
        totalWeight = totalWeight + item["weight"];
        dataSerie.push({
          color: rateMeta["colorChart"],
          name: item["id"] === "OTHER" ? t("c_others") : rateMeta["label"],
          trendrating: item,
          y: item["weight"],
        });
      }

      if (totalWeight < 1) {
        rateMeta = ratingScale["CASH"];
        dataSerie.push({
          color: rateMeta["colorChart"],
          name: rateMeta["label"],
          trendrating: null,
          y: 1 - totalWeight,
        });
      }

      const config = {
        chart: {
          animation: false,
          margin: [0, 0, 0, 0],
          spacing: [0, 0, 0, 0],
          type: "pie",
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            borderColor: "#FFFFFF",
            borderWidth: 1,
            center: ["50%", "50%"],
            cursor: undefined,
            dataLabels: { enabled: false },
            innerSize: "60%",
            point: {
              events: {
                mouseOut: function (event) {
                  onPieMouseOut(event);
                },
                mouseOver: function (event) {
                  var item = this["trendrating"];
                  // skip cash
                  if (item != null) {
                    onPieMouseOver(item, event);
                  }
                },
              },
            },
            shadow: false,
            slicedOffset: 0,
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            name: "Rating",
            animation: false,
            data: dataSerie,
          },
        ],
        title: { text: null },
        tooltip: { enabled: false },
      };

      return config;
    },
    [onPieMouseOut, onPieMouseOver, t]
  );

  const dataDisplayTable = useCallback(
    (data, node) => {
      if (!node) {
        return;
      }

      node.innerHTML = "";

      const cssClassWhatWherePie = "tWhatWherePie";
      let tr: HTMLTableRowElement | null = null;
      let item: any = null;
      let innerHTML = "";
      let th: HTMLTableCellElement | null = null;
      let content: string | null = null;
      let td: HTMLTableCellElement | null = null;

      for (const element of data) {
        item = element;
        const key = item["id"];

        tr = document.createElement("tr");
        innerHTML = key === "OTHER" ? t("c_others") : key;

        th = document.createElement("th");

        th.classList.add(cssClassWhatWherePie + "-itemName");
        th.setAttribute("title", innerHTML);

        content =
          '<span class="' +
          cssClassWhatWherePie +
          '-itemNameValue">' +
          innerHTML +
          "</span>";

        th.innerHTML = content;
        tr.appendChild(th);

        td = document.createElement("td");

        td.classList.add(cssClassWhatWherePie + "-itemTcr");
        td.innerHTML =
          item["rate"] != null
            ? formatter.tcr(item["rate"], "HTML")
            : key === "OTHER"
            ? ""
            : "-";

        tr.appendChild(td);

        td = null;

        td = document.createElement("td");

        td.classList.add(cssClassWhatWherePie + "-itemWeight");
        td.innerHTML = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: 0,
              output: 0,
            },
          },
          output: "HTML",
          value: item["weight"],
          valueHelper: null,
        });

        tr.appendChild(td);
        node.appendChild(tr);
      }
    },
    [formatter, t]
  );

  const dataDisplay = useCallback(
    (data) => {
      const cssClass = "hide";
      const nodeChart = nodeChartRef.current ?? null;
      const nodeFeedback = nodeFeedbackRef.current ?? null;

      if (nodeChart && nodeFeedback) {
        if (data != null) {
          nodeChart.classList.remove(cssClass);
          nodeFeedback.classList.add(cssClass);

          nodeFeedback.innerHTML = "";

          const leftConfig = createChartConfig(data["where"]["complete"]);

          dataDisplayTable(data["where"]["cutted"], nodeWhereTable.current);

          const rightConfig = createChartConfig(data["what"]["complete"]);

          dataDisplayTable(data["what"]["cutted"], nodeWhatTable.current);

          setLeftChartConfig(leftConfig);
          setRightChartConfig(rightConfig);
        } else {
          nodeChart.classList.add(cssClass);
          nodeFeedback.classList.remove(cssClass);

          nodeFeedback.innerHTML = t("c_feedback_no_data");
        }
      }
    },
    [createChartConfig, dataDisplayTable, t]
  );

  // also in WhatWhereTable
  const dataPrepareSort = useCallback((data) => {
    // sort by rate (D->A), weight (10 -> 0)
    data.sort(function (a, b) {
      var sortByL1 = "rate";
      var sortByL2 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return 1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return -1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  }, []);

  // as in trendrating-report/portfolio/data/Data._allocationData
  // dataType: what | where
  const dataPrepareHelper = useCallback(
    (rawData, dataType) => {
      const data: any = {
        complete: [],
        cutted: [],
      };
      let item: any = null;
      let meaningful = 5;

      rawData.forEach((el) => {
        const item = Object.entries<any>(el).map(([key, value]) => ({
          id: key,
          rate: value["TCR"],
          weight: value["weight"],
          constituents: value["data"].map((el) => el.symbol),
          type: dataType,
        }))[0];

        data["complete"].push(item);
      });

      dataPrepareSort(data["complete"]);

      var length = Math.min(meaningful, data["complete"].length);
      for (let i = 0; i < length; i++) {
        data["cutted"].push(data["complete"][i]);
      }

      var threshold = meaningful + 1;
      if (data["complete"].length >= threshold) {
        var others = [...data["complete"]].splice(
          meaningful,
          data["complete"].length
        );
        item = {
          id: "OTHER",
          rate: null,
          weight: 0,
        };
        for (let i = 0; i < others.length; i++) {
          item["weight"] = item["weight"] + others[i]["weight"];
        }
        data["cutted"].push(item);
      }

      return data;
    },
    [dataPrepareSort]
  );

  const dataPrepare = useCallback(
    (rawData) => {
      if (rawData != null) {
        const data = {
          what: dataPrepareHelper(rawData["what"], "what"),
          where: dataPrepareHelper(rawData["where"], "where"),
        };
        dataDisplay(data);
      } else {
        dataDisplay(null);
      }
    },
    [dataDisplay, dataPrepareHelper]
  );

  useEffect(() => {
    if (value) {
      dataPrepare(value);
    }
  }, [dataPrepare, value]);

  return (
    <div
      ref={domNode}
      className="tListViewerTileItemAllocation tListViewerTileItemAllocation--A"
    >
      <div ref={nodeFeedbackRef}></div>
      <div className="tWhatWherePie--horizontal" ref={nodeChartRef}>
        <div className="tWhatWherePie-box">
          <div
            className="tWhatWherePie-content"
            style={{ flexDirection: "row" }}
          >
            <div className="tWhatWherePie-chart">
              {leftChartConfig && (
                <Chart options={leftChartConfig} constructorType="chart" />
              )}
            </div>
            <div className="tWhatWherePie-tableContainer">
              <table className="tWhatWherePie-table">
                <tbody ref={nodeWhereTable}></tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tWhatWherePie-box">
          <div
            className="tWhatWherePie-content"
            style={{ flexDirection: "row" }}
          >
            <div className="tWhatWherePie-chart">
              {rightChartConfig && (
                <Chart options={rightChartConfig} constructorType="chart" />
              )}
            </div>
            <div className="tWhatWherePie-tableContainer">
              <table className="tWhatWherePie-table">
                <tbody ref={nodeWhatTable}></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tListViewerTileItemAllocation-allocationTooltip hide"
        ref={nodeTooltip}
      ></div>
    </div>
  );
}
