import styles from "./../Alternatives.module.scss";
import ideaLamp from "./../../../../../../../../styles/css_new/img/lamp-idea.svg";

export function LandingSwapList() {
  return (
    <div className={styles.landing__swap__list}>
      {/* Using the tooltip class because we want the same style of tooltip info*/}
      <div className="tooltip-content-wrapper">
        <div className="tooltip-leftPanel">
          <img src={ideaLamp} alt="lamp-icon" className="alerts-tooltip-icon" />
        </div>
        <div className="tooltip-rightPanel">
          <h3 className="title">
            Improve portfolio by replacing the risky positions
          </h3>
          <p className="paragraph">
            Select one of existing positions and get the list of better
            securities. Choose one of them and add to replace list. When done,
            export the list or apply the replacements to the portfolio
          </p>
        </div>
      </div>
    </div>
  );
}
