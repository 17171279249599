/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportStrategyLongShort
 * @summary Manages long short strategy report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportStrategyLongShort extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_STRATEGY_LONG_SHORT" as const;
}
