import { useEffect, useMemo } from "react";
import { FilterWidget } from "../../../../../../components/FilterWidget/FilterWidget";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import {
  _getUserPreferences,
  getInitialState,
} from "../../../../../../components/FilterWidget/filterWidgetHelper";
import { filterStockState } from "../../../../../../components/FilterWidget/filterReducer";

type StocksWidgetProps = {
  data: any;
  stocksProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

export function StocksWidget({
  data,
  stocksProps,
  accordionHandler,
  accordionState,
}: StocksWidgetProps) {
  const { saveHandler, stateChangeHandler } = stocksProps;
  const formatter = useFormatter();
  const environment = useEnvironment();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = (event) => {
    if (securityLink !== "popup_instrument" && event.type === "rowClick") {
      const data = event.value.getData();
      const symbol = data?.symbol ?? null;
      if (symbol) {
        const url = `/app/analysis/instrument/${symbol}/`;
        window.location.href = url;
      }
    }
  };

  const defaultFilters = useMemo(() => {
    const savedNewsFilters = window.App.user.alertsNewsFilters?.filters ?? null;
    const userPreferencesFromSettings = _getUserPreferences(environment);

    return getInitialState(
      filterStockState,
      savedNewsFilters,
      userPreferencesFromSettings,
      "stock"
    );
  }, [environment]);

  useEffect(() => {
    stateChangeHandler(defaultFilters);
  }, [defaultFilters, stateChangeHandler]);

  return (
    <div className="tNews-item">
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          Stocks
          <div className="tooltip-wrapper-icon"></div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
        ></span>
      </div>
      {accordionState && (
        <div>
          <div>
            <FilterWidget
              setFiltersStock={stateChangeHandler}
              saveFiltersStock={saveHandler}
            />
          </div>
          {data && data.length ? (
            <div className="table-with-filters">
              <TrendratingTable
                autoResize={false}
                eventCallback={onRowClickHandler}
                tooltip={{
                  actions: {
                    info: {
                      enabled: securityLink === "popup_instrument",
                    },
                  },
                }}
                options={{
                  ajaxSorting: false,
                  movableColumns: false,
                  resizableColumns: false,
                }}
                columns={[
                  {
                    field: "ticker",
                    title: "Ticker",
                    resizable: false,
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 1,
                  },
                  {
                    field: "name",
                    title: "Name",
                    resizable: false,
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 3,
                  },
                  {
                    field: "rc",
                    title: "TCR",
                    resizable: false,
                    formatter: (cell) => formatterTcr(cell, formatter),
                  },
                  {
                    field: "country",
                    title: "Market",
                    resizable: false,
                    widthGrow: 2,
                  },
                  {
                    field: "industry",
                    resizable: false,
                    title: "Sector",
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 2,
                  },
                  {
                    field: "marketcap",
                    title: "Mtk. Cap.",
                    resizable: false,
                    formatter: (cell) => {
                      return (
                        formatter.table(
                          cell.getField(),
                          "table",
                          cell.getData()
                        ) ?? ""
                      );
                    },
                    widthGrow: 2,
                  },
                ]}
                data={data}
              />
            </div>
          ) : (
            <div className="empty-news-data">No alert found</div>
          )}
        </div>
      )}
    </div>
  );
}
