export type AllocationPieType = {
  content: {
    firstRows: number;
    firstType: string;
    headline: {
      content: string;
      isEnabled: boolean;
    };
    secondRows: number;
    secondType: string;
  };
  presentation: {
    colorByPosition: boolean;
    first: boolean;
    second: boolean;
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_FIRST_ROWS"
    | "SET_CONTENT_FIRST_TYPE"
    | "SET_CONTENT_SECOND_TYPE"
    | "SET_CONTENT_SECOND_ROWS"
    | "SET_PRESENTATION_COLORBYPOSITION"
    | "SET_PRESENTATION_FIRST"
    | "SET_PRESENTATION_SECOND";
  payload: any;
};

export const allocationPieReducer = (draft: AllocationPieType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_FIRST_ROWS":
      draft.content.firstRows = action.payload;
      break;
    case "SET_CONTENT_FIRST_TYPE":
      draft.content.firstType = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_SECOND_ROWS":
      draft.content.secondRows = action.payload;
      break;
    case "SET_CONTENT_SECOND_TYPE":
      draft.content.secondType = action.payload;
      break;
    case "SET_PRESENTATION_COLORBYPOSITION":
      draft.presentation.colorByPosition = action.payload;
      break;
    case "SET_PRESENTATION_FIRST":
      draft.presentation.first = action.payload;
      break;
    case "SET_PRESENTATION_SECOND":
      draft.presentation.second = action.payload;
      break;
    default:
      break;
  }
};
