import { useEffect, useRef, useState } from "react";
import { Preferences } from "../../api/account/Preferences";
import { Instruments } from "../../api/compute/Instruments";
import { Environment } from "../../Environment";
import {
  CurvePoint,
  TrendratingChart,
} from "../../js/app/pages/analysisSecurity/components/TrendratingChart";
import { Instrument } from "../../types/Api";
import Modal from "../Modal/Modal";
import { getInfoNodeData } from "../SecurityTooltip/SecurityTooltipCore";
import styles from "./SecurityChartModal.module.scss";

type SecurityChartModalProps = {
  security: Instrument | undefined;
  showModal: boolean;
  environment: Environment;
  onClose: (value) => void;
  showCountryLevel?: boolean;
};

const { subtitle } = styles;

const setHelper = async (params, callback, apiPreference: Preferences) => {
  var data: any = {
    name: "global",
    preferences: {},
    version: 1,
  };
  if (window.App.user.preferences) {
    // ID
    data.id = window.App.user.preferences.id;
    // name
    data.name = window.App.user.preferences.name;
    // version
    data.version = window.App.user.preferences.version;

    if (window.App.user.preferences.preferences) {
      data.preferences = window.App.user.preferences.preferences;
    }
  }

  for (let i = 0, length = params.length; i < length; i++) {
    data.preferences[params[i].key] = params[i].value;
  }

  if (callback) {
    await apiPreference.save(data);
    callback(data);
  } else {
    // return promise
    return apiPreference.save(data);
  }
};

const _post = (params, callback, callbackError, response, apiPreference) => {
  var key = "favorites";

  var favorite: any;
  var favorites: any = {
    data: [],
    index: {},
  };
  var index;
  var security: any;
  var size = 10;
  var symbols = params.symbols;
  var symbol;
  var timestamp: any;

  if (response && key in response.data && response.data[key] != null) {
    favorites = response.data[key];
  }

  for (let i = 0; i < symbols.length; i++) {
    symbol = symbols[i];
    timestamp = new Date().getTime();

    if (symbol in favorites["index"]) {
      // update timestamp
      index = favorites["index"][symbol];
      favorite = favorites["data"][index];

      favorite["timestamp"] = timestamp;
    } else {
      //
      if (favorites.data.length >= size) {
        makeSpace(favorites, symbols);
      }

      // insert new favorite
      favorite = {
        symbol: symbol,
        timestamp: timestamp,
      };

      favorites["data"].push(favorite);
    }
  }
  // sort
  favorites["data"].sort(function (a, b) {
    if (a.timestamp > b.timestamp) {
      return -1;
    }
    if (a.timestamp < b.timestamp) {
      return 1;
    }
    return 0;
  });
  // build index
  for (let i = 0; i < favorites["data"].length; i++) {
    security = favorites["data"][i];
    favorites["index"][security.symbol] = i;
  }

  var paramsTarget = {
    key: key,
    value: favorites,
  };

  setHelper([paramsTarget], callback, apiPreference);
};

const makeSpace = (favorites, symbols) => {
  // favorites are sorted by timestamp: newest -> oldest
  // size check: if the size limit is reached, the last
  // elements (oldest) are removed

  var size = 10;
  var overflow = favorites.data.length - size;
  var offset = favorites.data.length - overflow - symbols.length;

  favorites.data.splice(offset, symbols.length + overflow);

  // rebuild index
  favorites.index = {};
  for (let i = 0, length = favorites.data.length; i < length; i++) {
    const symbol = favorites.data[i].symbol;
    favorites.index[symbol] = i;
  }
};

const setPreferences = async (
  security: Instrument,
  environment: Environment
) => {
  const handleResponse = (response) => {
    if (response?.data?.favorites) {
      window.App.user.preferences.preferences.favorites =
        response.data.favorites;
    }
  };

  const symbols =
    security != null && "symbol" in security ? [security.symbol] : [];

  if (symbols.length === 0) {
    console.warn("Favorite: symbols not specified.", security);
    return;
  }

  const appEnv = environment.get("setup");
  const apiPreference = new Preferences(appEnv);
  const params = { symbols };
  const callback: any = (response) => {
    const data = {};
    for (const [key, value] of Object.entries(response)) {
      if (key === "preferences") {
        data["data"] = value;
      } else {
        data[key] = value;
      }
    }

    _post(params, handleResponse, null, data, apiPreference);
  };
  const response = await apiPreference.get();
  callback(response);
};

const getSecurityHistory = async (
  environment: Environment,
  security: any,
  setHistory: Function
) => {
  const params = { symbol: [security.symbol] };
  const appEnvironment = environment.get("setup");
  const apiInstrument = new Instruments(appEnvironment);
  const response = await apiInstrument.historyOf(params);

  if (response != null) {
    setHistory(response);
  } else {
    setHistory([]);
  }
};

const Header = ({ title, innerHtml, showSubtitle }) => {
  return (
    <div>
      {title}
      <br></br>
      {showSubtitle && (
        <span
          className={subtitle}
          dangerouslySetInnerHTML={{ __html: innerHtml }}
        ></span>
      )}
    </div>
  );
};

function SecurityChartModal({
  security,
  showModal,
  environment,
  onClose,
  showCountryLevel = true,
}: SecurityChartModalProps) {
  const title = security ? "(" + security.ticker + ") " + security.name : "";
  const [history, setHistory] = useState<CurvePoint[]>();
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentDim, setContentDim] = useState({
    width: "",
    height: "",
  });
  const taxonomies = environment.get("rawTaxonomies");
  const taxonFields = environment.get("taxonomyFields");
  let subTitle = getInfoNodeData(
    taxonomies,
    security,
    environment,
    taxonFields
  );
  subTitle = security?.type + " | " + subTitle;
  subTitle = subTitle.replace("<br/>", " | ");

  useEffect(() => {
    try {
      if (security && showModal) {
        setPreferences(security, environment);
        getSecurityHistory(environment, security, setHistory);
      }
    } catch (e) {}
  }, [environment, security, showModal]);

  useEffect(() => {
    const content: any = contentRef?.current ?? null;

    const action = () => {
      if (showModal) {
        const width =
          content && window.getComputedStyle(content).getPropertyValue("width");
        const height =
          content &&
          window.getComputedStyle(content).getPropertyValue("height");

        const areMeasuresChanging =
          height !== contentDim.height || width !== contentDim.width;
        if (areMeasuresChanging) {
          setContentDim({
            height: height,
            width: width,
          });
        }
      }
    };

    const interval = setInterval(() => action(), 500);

    return () => clearInterval(interval);
  }, [contentDim.height, contentDim.width, showModal]);

  return showModal ? (
    <Modal
      isDraggable={true}
      hasOverlay={false}
      headerConfig={{
        headerContent: (
          <Header
            showSubtitle={showCountryLevel}
            title={title}
            innerHtml={subTitle}
          />
        ),
      }}
      onClose={onClose}
      isResizable={true}
      customCss={{ minWidth: "20%", zIndex: 9999999 }}
      closeIcon={true}
    >
      <div
        ref={contentRef}
        style={{
          height: "100%",
          maxHeight: "100%",
        }}
      >
        {history && (
          <TrendratingChart
            fitContentInModal={true}
            excludePeriod={["1M", "3M", "MAX", "YTD", "R"]}
            enableRating={true}
            lastRating={true}
            enableLegend={false}
            value={
              [
                {
                  prices: history,
                  data: null,
                  id: security?.ticker,
                  name: security?.name,
                  type: "default",
                },
              ] ?? []
            }
          />
        )}
      </div>
    </Modal>
  ) : (
    <></>
  );
}

export default SecurityChartModal;
