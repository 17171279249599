/* eslint-disable import/no-amd */
/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Lists
 * @summary Requests for lists (baskets or portfolios)
 *
 */

import { deepClone } from "../../deepClone";
import { httpAll } from "../../httpAll";
import { TDate } from "../../trendrating/date/TDate";
import {
  Currency,
  IsoDate,
  List,
  ListPosition,
  ListType,
  ServerListPosition,
  Strategy,
} from "../../types/Api";
import { AppEnvironment } from "../../types/Defaults";
import { endpoints } from "../endpoints";
import { toInt } from "../utils";
import { dataInjector, extractSymbols } from "./commons";
import { Instruments } from "./Instruments";
import { Strategies } from "./Strategies";
import { Subscriptions } from "./Subscriptions";
import { Utils } from "./Utils";
import { _RationaleBase } from "./_RationaleBase";
import { ClusterAnalytics } from "./ClusterAnalytics";
export class Lists extends _RationaleBase {
  http: {
    instruments: Instruments;
    strategies: Strategies;
    subscriptions: Subscriptions;
    utils: Utils;
    clusters: ClusterAnalytics;
  };

  // It overwrites _Base constructor because of extra initialization
  // operations are needed
  constructor(environment: AppEnvironment) {
    super(environment);

    this.http = {
      instruments: new Instruments(environment),
      strategies: new Strategies(environment),
      subscriptions: new Subscriptions(environment),
      utils: new Utils(environment),
      clusters: new ClusterAnalytics(environment),
    };
  }

  /**
   * @deprecated
   *
   * Add a list to account lists
   *
   * HTTP verb: POST
   *
   * @param {object} params.list - a list to be added to the account lists
   */
  add({ list }: { list: any }) {
    this.deprecated("Lists", "add()", "create()");

    return this.create(list);
  }

  /**
   * Retrives alerts of given lists ids
   *
   * @param {object} params
   * @param {string} params.listIds - the ids of lists to retrieve
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async alerts({
    listIds,
    timeframe,
    additionalFields,
  }: {
    listIds: string[] | number[];
    timeframe?: "today" | "lastWeek" | "lastMonth";
    additionalFields?: { dimension: string }[];
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.alerts;
    const ids = listIds.map((id) => parseInt(id));

    const upgradesDowngradesMap = {
      today: [{ dimension: "upgrades" }, { dimension: "downgrades" }],
      lastWeek: [{ dimension: "upgrades_W" }, { dimension: "downgrades_W" }],
      lastMonth: [{ dimension: "upgrades_M" }, { dimension: "downgrades_M" }],
    };

    let params = [
      {
        classType: "COLLECTION",
        id: ids,
        extendedRequest: {
          onDemandResult: [
            {
              dimension: "type",
            },
            {
              dimension: "name",
            },
            {
              dimension: "TCR",
            },
            {
              dimension: "ABnewHigh",
            },
            {
              dimension: "CDnewLow",
            },
            {
              dimension: "TCR_D",
            },
            {
              dimension: "ownerId",
            },
          ],
        },
      },
    ];

    if (additionalFields != null && additionalFields.length) {
      let analytics = params[0].extendedRequest.onDemandResult;
      for (const field of additionalFields) {
        analytics.push(field);
      }
    }

    //Default get data for all timeframe but if a specific timeframe is passed to the function
    //use it to get data based on it.

    let extendedRequest: any = null;
    if (timeframe == null) {
      extendedRequest = params[0].extendedRequest.onDemandResult.concat(
        upgradesDowngradesMap["today"],
        upgradesDowngradesMap["lastWeek"],
        upgradesDowngradesMap["lastMonth"]
      );
    } else {
      extendedRequest = params[0].extendedRequest.onDemandResult.concat(
        upgradesDowngradesMap[timeframe]
      );
    }

    params[0].extendedRequest.onDemandResult = extendedRequest;

    const response = await this.preparePost(url, params, null);

    return this._normalizeAlerts(response);
  }

  async portfolioFetch(ids: number[], analytics: string[]) {
    if (!analytics.length) {
      return [];
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.fetch;

    let params = [
      {
        classType: "COLLECTION",
        id: ids,
        extendedRequest: {
          onDemandResult: analytics.map((analytic) => ({
            dimension: analytic,
          })),
        },
      },
    ];

    const response = await this.preparePost(url, params, null);

    const result = response?.["data"]?.[0]?.["rows"] ?? null;
    return result;
  }

  async getListAnalytics(id, analytics?: string[]) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.fetch;

    let params = [
      {
        classType: "COLLECTION",
        id: Array.isArray(id) ? id : [parseInt(id)],
        extendedRequest: {
          onDemandResult: [
            {
              dimension: "type",
            },
            {
              dimension: "name",
            },
            {
              dimension: "ownerId",
            },
          ],
        },
      },
    ];

    if (analytics != null) {
      for (const element of analytics) {
        params[0].extendedRequest.onDemandResult.push({
          dimension: element,
        });
      }
    }

    const response = await this.preparePost(url, params, null);
    const ownerId = response["data"]?.[0]?.["rows"]?.[0]?.["ownerId"];
    const userId = this.environment.account.user?.id;
    const isReadOnly = ownerId !== userId;

    const result = response["data"][0]["rows"];

    if (result.length) {
      result[0]["isReadOnly"] = isReadOnly;
    }

    return result;
  }

  /**
   * Compare two lists
   *
   * @param {object}   params - request parameters
   *
   * @param {object}   params.source - the source list
   * @param {string}   params.source.name - list name
   * @param {object[]} params.source.positions - list instruments
   * @param {object[]} params.source.positions[].symbol - instrument symbol
   * @param {object[]} params.source.positions[].weight - instrument weight
   *
   * @param {object}   params.target - the target list
   * @param {string}   params.target.name - list name
   * @param {object[]} params.target.positions - list instruments
   * @param {object[]} params.target.positions[].symbol - instrument symbol
   * @param {object[]} params.target.positions[].weight - instrument weight
   *
   * @param {number}   params.threshold - threshold used for
   *      increase/decrease
   * @param {number}   params.thresholdInOut - threshold used for buy/sell
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async compare({
    source,
    target,
    threshold,
    thresholdInOut,
  }: {
    source: Pick<List, "positions">;
    target: Pick<List, "positions">;
    threshold: number;
    thresholdInOut: number;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.comparisons;

    const _params = {
      from: source.positions,
      target: target.positions,
      threshold: threshold,
      thresholdInOut: thresholdInOut,
    };

    const response = await this.preparePost(url, _params, null);
    return this._normalizeCompare(response);
  }

  /**
   * Create a list (basket or portfolio)
   *
   * @param {object}   list - the list should be created (shape by getPrototype)
   * @param {string}   list.benchmark - benchmark symbol, or null
   * @param {string}   list.name - list name
   * @param {object[]} list.positions - list positions
   * @param {string}   list.positions[].symbol - instrument symbol
   * @param {number}   list.positions[].weight - instrument weight
   * @param {string}   list.type - one of "basket" or "portfolio"
   * @param {object}   list.weightsManagement - weights management
   * @param {string}   list.weightsManagement.currency
   * @param {Date}     list.weightsManagement.date
   * @param {string}   list.weightsManagement.type - "fixed" or "drifting"
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  create(list: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.create;

    const params: any = {
      benchmark: list.benchmark,
      currency: null,
      name: list.name,
      ownerId: this.environment.account.user?.id,
      positions: [],
      type: list["type"].toUpperCase(),
      weightsDate: null,
    };

    params.positions = list.positions.map((position: any) => ({
      symbol: position.symbol,
      weight: position.weight,
    }));

    if (list?.weightsManagement?.type === "drifting") {
      params.currency = list.weightsManagement.currency;
      params.weightsDate = list.weightsManagement.date;
    }

    return this.preparePost(url, params, null);
  }

  /**
   * Retrives global trends distributions
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  distributions() {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.distributions;
    return this.prepareGet(url, null, null);
  }

  async newGet(fields?: string[], type?: "portfolio" | "basket") {
    let listType: "BASKET" | "PORTFOLIO" | undefined = undefined;

    if (type != null) {
      listType = type.toUpperCase() as "BASKET" | "PORTFOLIO";
    }

    const userCollectionIds = await this._getFilter(listType);
    const subscribed = await this._getSubscribed();

    const listIds = [...userCollectionIds, ...subscribed.map((pub) => pub.id)];

    const defaultFields = ["name", "ownerId", "type"];

    fields =
      fields != null
        ? [...new Set([...defaultFields, ...fields])]
        : defaultFields;

    const response = await this.portfolioFetch(listIds, fields);

    const userId = this.environment?.["account"]?.["user"]?.["id"];

    const collection: any = [];

    for (const element of response) {
      if (type != null) {
        if (element.type === type.toUpperCase()) {
          collection.push({
            ...element,
            isReadOnly: element.ownerId !== userId,
          });
        }
      } else {
        collection.push({
          ...element,
          isReadOnly: element.ownerId !== userId,
        });
      }
    }

    return collection;
  }

  /**
   * Retrieves a list or all lists including properties
   *
   * @param {number}        id - The ID of the list to be retrieved.
   *      If null, all user lists are retrieved
   *
   * @param {array<string>} properties - the allowed properties are:
   *      "analytics" and "holdings".
   *      If null, only basic properties are retrieved: "name", "ownerId"
   *      and "type"
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async get(id?: any, properties?: any, skipSafeCheck?: boolean) {
    skipSafeCheck = skipSafeCheck ?? false;
    const defaultProperties = ["name", "ownerId", "type"];
    const mergedProperties =
      properties == null
        ? defaultProperties
        : properties.concat(defaultProperties);

    //
    // The idea is to use a reducer approach
    //
    // Every request of the chain accumulates the respone and the
    // last chain step acts like the reducer that products the final
    // output
    //
    const accumulator: any = {
      // additional data for user lists and subscribed lists
      listsMap: {},
      properties: mergedProperties,
    };

    const resourceId = Array.isArray(id) ? id[0] : id;

    if (resourceId != null) {
      try {
        const response = await httpAll({
          list: this._getById(accumulator, resourceId),
          subscribed: this._getSubscribed(accumulator),
        });

        let listRaw = response.list[0];
        const subscribed = response.subscribed;
        const userId = this.environment.account.user?.id;

        if (listRaw == null) {
          // Server returns 200 instead of 404 when a list is
          // not available (not exist or has been deleted)
          response["initiator"] = "trendrating/api/compute/Lists.get(id)";

          return this.simulateHttpError(response);
        }

        // Compare same value, if it is a string or number
        // don't care, value must be equal, even if type is
        // different
        if (listRaw.ownerId !== userId) {
          listRaw.isReadOnly = true;

          // Different ownerId, this is a shared object
          const subscribedIds = subscribed.map((item: any) => item.id);

          if (
            skipSafeCheck === false &&
            subscribedIds.indexOf(listRaw.id) === -1
          ) {
            // Object does not exists
            response["initiator"] = "trendrating/api/compute/Lists.get(id)";
            response["error"] = "OBJECT_NOT_SUBSCRIBED";

            return this.simulateHttpError(response);
          }
        }

        // Get positions source if requested
        if (
          "positions" in listRaw &&
          listRaw.positions &&
          listRaw.positions.length > 0
        ) {
          listRaw["positionsSource"] = [...listRaw.positions];
        }

        const listRawHoldings = await this._getHoldings(listRaw);
        // Save into accumulator
        listRaw.positions = listRawHoldings?.positionsToday;
        listRaw = this._prepareListAnalytics(listRaw);

        const keysToRemove = [
          "TCR",
          "TCR_D",
          "TCR_W",
          "TCR_M",
          "upgrades",
          "upgrades_W",
          "upgrades_M",
          "downgrades",
          "downgrades_W",
          "downgrades_M",
          "ABnewHigh",
          "CDnewLow",
          "A",
          "B",
          "C",
          "D",
          "N",
          "A_%",
          "B_%",
          "C_%",
          "D_%",
          "N_%",
          "benchmark",
          "positionsToday",
        ];

        for (const key of keysToRemove) {
          delete listRaw[key];
        }

        return this.normalize([listRaw], false);
        /**
         * Before the possibility of fetching positionsToday analytic, the client has to enter into
         * the merits of the issue related to weight drifting. For this reason we used the method _applyDrifting.
         *
         * Now to get the costituents of a portfolio we fetch the positionsToday key and the logic of apply drifting is
         * used only in the portfolio edit.
         */
        // return this._applyDrifting(normalizedResponse);
      } catch (error) {
        console.error(error);
        alert("An error occurs. See console for details");
      }
    }

    return httpAll({
      lists: this._get(accumulator),
      subscribed: this._getSubscribed(accumulator),
    }).then((response: any) => {
      const subscriptionApi = this.http["subscriptions"];
      return subscriptionApi
        .getDetails("subscriptions", response.subscribed)
        .then((subscribed) => {
          const lists = response.lists.concat(subscribed);

          // if (Object.keys(accumulator.listsMap).length > 0) {
          //     // there are data to merge
          //     for (const list of lists) {
          //         const additionalData = accumulator.listsMap[list.id];
          //         list.alerts = additionalData.alerts;
          //         list.movers = additionalData.statistics.movers;
          //         list.statistics = additionalData.statistics;
          //         list.tcr = additionalData.tcr;
          //     }
          // }

          const normalizedList: any = this.normalize(lists, true);

          // // TODO LEGACY update always the App data
          // if ((window as any)?.App?.user?.data?.collections != null) {
          //     (window as any).App.user.data.collections = new Store({
          //         data: normalizedList.data,
          //     });
          // }

          return normalizedList;
        });
    });
  }

  //! DEPRECATED
  /**
   * Compute point in time analytics
   *
   * @param {object} params - point in time parameters
   * @param {string} params.asOf - the date of point in time in ISO 8601
   * @param {object} params.list - the list
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async getPointInTime({ asOf, list }: { asOf: IsoDate; list: List }) {
    const serverStatus = await this.http["utils"].today();

    let dateSource = TDate.daysToIso8601(serverStatus.today);
    if (list?.weightsManagement?.type === "drifting") {
      dateSource = list.weightsManagement.date;
    }

    const responseWeights = await this._updateWeights(
      list.positions,
      list.weightsManagement.currency,
      dateSource,
      asOf
    );

    const analytics = [
      "TCR",
      "TCR_D",
      "TCR_W",
      "TCR_M",
      "upgrades",
      "upgrades_W",
      "upgrades_M",
      "downgrades",
      "downgrades_W",
      "downgrades_M",
      "ABnewHigh",
      "CDnewLow",
      "A",
      "B",
      "C",
      "D",
      "N",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "N_%",
    ];

    const pos = responseWeights.v.map((pos) => ({
      symbol: pos.S,
      weight: pos.A,
    }));

    const aggregateAnalytics = await this.http.clusters
      .createConfiguration()
      .analytics(analytics)
      .universeFromPositions(pos)
      .fetchAnalytics();

    const stats = aggregateAnalytics?.clustersStats?.stats?.ANY;

    const holdings: List["positions"] = [];
    const holdingsIndex: List["positionsIndex"] = {};
    for (let i = 0; i < responseWeights.v.length; i++) {
      const rawHolding = responseWeights.v[i];
      holdings.push({
        symbol: rawHolding.S,
        weight: rawHolding.A,
      });
      holdingsIndex[rawHolding.S] = i;
    }

    const listPointInTime = deepClone(list);
    listPointInTime.positions = holdings;
    listPointInTime.positionsIndex = holdingsIndex;
    const listFromStats = this._prepareListAnalytics(stats);

    // 2021-11-26 New field tcr outside .statistics
    if (listFromStats?.tcr?.TCR != null) {
      // statistics here cannot be undefined because the
      // _normalizeListStatistics return a full object
      listPointInTime.statistics!.tcr = {
        today: toInt(listFromStats.tcr, "TCR", null),
        yesterday: toInt(listFromStats.tcr, "TCR_D", null),
        lastMonth: toInt(listFromStats.tcr, "TCR_M", null),
        lastWeek: toInt(listFromStats.tcr, "TCR_W", null),
      };
    }

    listPointInTime.statistics = this._normalizeListStatistics(
      listFromStats.statistics
    );

    return listPointInTime;
  }

  async getRaw() {
    const response = await httpAll({
      lists: this._getFilter(),
      subscribed: this._getSubscribed(),
    });

    const userCollectionsIds = [...response.lists];

    for (const subscriptions of response?.subscribed) {
      if (subscriptions?.id) {
        userCollectionsIds.push(subscriptions?.id);
      }
    }

    return userCollectionsIds;
  }

  getPrototype(): List {
    return {
      alerts: null,
      benchmark: null,
      id: null,
      isReadOnly: false,
      movers: {
        down: 0,
        up: 0,
      },
      name: "",
      ownerId: null,
      positions: [],

      //
      // this is used only if list has drifting weights
      // (weightsManagement.type === "drifting")
      //
      // and positions contains updated weights
      //
      positionsSource: [],

      positionsIndex: {},
      positionsExpired: [],
      positionsUnavailable: [],
      statistics: null,
      type: "BASKET" as ListType, // use BASKET or PORTFOLIO
      updateTime: null,
      whatWhere: null,
      weightsManagement: {
        currency: null, // null if type === "fixed"
        date: null, // null if type === "fixed"
        type: "fixed", // or "drifting"
      },
    } as List;
  }

  /**
   *
   * @param {object}  params
   * @param {string}  params.listIds - the ids of list to retrieve
   * @param {boolean} params.normalize - if true, server normalizes
   *      weights to 100%
   * @param {boolean} params.peerWeights - if true, it includes peer info
   *      computation. Dafault false
   * @param {array}   params.properties - instrument properties to fetch
   * @param {string}  params.what - sector zoom
   * @param {string}  params.where - geographic zoom
   *
   */
  async index({
    listIds,
    normalize,
    peerWeights = false,
    what,
    where,
  }: {
    listIds: string[];
    normalize: boolean;
    peerWeights: boolean;
    what: any;
    where: any;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.analytics;

    const paramsIndex = {
      collectionIds: listIds,
      params_snapshot: {
        countryLevel: where,
        normalize: normalize,
        peerWeights: peerWeights,
        sectorLevel: what,
      },
    };

    const response = await this.preparePost(url, paramsIndex, null);

    return this._normalizeIndex(response);
  }

  /**
   * Normilize list/s in order to be suitable for UI
   *
   * @param {array.<object>} response
   *
   * @returns normalized list/s
   */
  normalize(response: any, forceAsArray: boolean = false) {
    const dataTotalCount = response.length;

    if (dataTotalCount === 1 && !forceAsArray) {
      // get single list
      return this._normalizeList(response[0]);
    } else {
      // user lists and subscribed lists
      const lists: any = {
        data: [],
        dataTotalCount: dataTotalCount,
      };

      for (let i = 0; i < dataTotalCount; i++) {
        lists["data"].push(this._normalizeList(response[i]));
      }

      return lists;
    }
  }

  /**
   * Optimize a list against a strategy
   *
   * @param {object} params
   * @param {string} params.approach - the approch to be used appling
   *      strategy. It can be "systematic" or "spot".
   *
   *      <p>Default "systematic"</p>
   *
   *      <ul>
   *          <li>
   *              spot: applies the stategy at today
   *          </li>
   *          <li>
   *              systematic: applies the stategy and rebalances weights
   *              to today
   *          </li>
   *      </ul>
   *
   * @param {object} params.list - the list
   * @param {string} params.method - the method to be used appling
   *      strategy. It can be "tactical" or "implementative".
   *
   *      <p>Default "implementative"</p>
   *
   *      <ul>
   *          <li>
   *              implementative: use the universe of the strategy
   *          </li>
   *          <li>
   *              tactical: replace the universe of the strategy with the
   *              list
   *          </li>
   *      </ul>
   *
   * @param {object} params.strategy - the strategy to be applied to the
   *      list
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  optimize(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.optimizations;

    return this._applyStrategy(url, params, false).then((response: any) =>
      this._normalizeOptimize(response)
    );
  }

  /**
   * Applies orders to a list
   *
   * @param {object}   params
   * @param {object}   params.list - the list on which apply orders
   * @param {object}   params.orders - the orders to apply
   * @param {Object[]} params.orders.buy array of instruments
   * @param {number}   params.orders.buy[].weight
   * @param {number}   params.orders.buy[].symbol
   * @param {Object[]} params.orders.sell array of instruments
   * @param {number}   params.orders.sell[].weight
   * @param {number}   params.orders.sell[].symbol
   *
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async orders(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.orders;

    const paramsOrders: any = {
      master: {
        positions: params.list.positions.map((position: any) => ({
          symbol: position.symbol,
          weight: position.weight,
        })),
      },
      buy: params.orders.buy.map((position: any) => ({
        symbol: position.symbol,
        quantity: position.weight,
      })),
      sell: params.orders.sell.map((position: any) => ({
        symbol: position.symbol,
        quantity: position.weight,
      })),
    };

    const response = await this.preparePost(url, paramsOrders, null);
    // let list =response.data.stockCollection;
    // list.tcr = {
    //   fact_rc_t_1: list.tcr?.TCR_D,
    //   fact_rc_t_20: list.tcr?.TCR_M,
    //   fact_rc_t_5: list.tcr?.TCR_W,
    //   rate: list.tcr?.TCR,
    //   fact_rc: list.tcr?.TCR,
    // };

    // return this._normalizeList(list);
    return this._normalizeList(response.data.stockCollection);
  }

  /**
   * Returns a rationale about a list optimization (transparency)
   *
   * @param {object} params
   * @param {string} params.approach - the approch to be used appling
   *      strategy. It can be "systematic" or "spot".
   *
   *      <p>Default "systematic"</p>
   *
   *      <ul>
   *          <li>
   *              spot: applies the stategy at today
   *          </li>
   *          <li>
   *              systematic: applies the stategy and rebalances weights
   *              to today
   *          </li>
   *      </ul>
   *
   * @param {object} params.list - the list
   * @param {array.<object>} params.properties - the properties to be
   *      fetched for instruments
   * @param {string} params.method - the method to be used appling
   *      strategy. It can be "tactical" or "implementative".
   *
   *      <p>Default "implementative"</p>
   *
   *      <ul>
   *          <li>
   *              implementative: use the universe of the strategy
   *          </li>
   *          <li>
   *              tactical: replace the universe of the strategy with the
   *              list
   *          </li>
   *      </ul>
   *
   * @param {object} params.strategy - the strategy to be applied to the
   *      list
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  rationale(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.rationales;

    const strategy = this.http["strategies"].prepareStrategyForRationale(
      params["strategy"]
    );

    return this._applyStrategy(url, params, true).then((response: any) =>
      this._normalizeRationale(
        strategy["params"],
        params["properties"],
        response
      )
    );
  }

  /**
   * Removes a list
   *
   * @param {object}   list - the list to be updated
   * @param {string}   list.id - list ID
   * @param {string}   list.name - list name
   *
   * @param {object[]} list.positions - list positions
   * @param {string}   list.positions[].symbol - instrument symbol
   * @param {number}   list.positions[].weight - instrument weight
   *
   * @param {object[]} list.positionsExpired - expired positions
   * @param {string}   list.positionsExpired[].symbol - instrument symbol
   * @param {number}   list.positionsExpired[].weight - instrument weight
   *
   * @param {object[]} list.positionsUnavailable - unavailable positions
   * @param {string}   list.positionsUnavailable[].symbol - instrument symbol
   * @param {number}   list.positionsUnavailable[].weight - instrument weight
   * @param {string}   list.type - one of "basket" or "portfolio"
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  remove(list: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.remove;

    const userId = this.environment.account.user?.id;

    const _params = {
      id: list["id"],
      ownerId: userId,
    };

    return this.prepareGet(url, _params, null);
  }

  /**
   * Updates a list
   *
   * @param {object}   list - the list to be updated
   * @param {string}   list.benchmark - benchmark symbol, or null
   * @param {number}   list.id - list ID
   * @param {string}   list.name - list name
   *
   * @param {object[]} list.positions - list positions
   * @param {string}   list.positions[].symbol - instrument symbol
   * @param {number}   list.positions[].weight - instrument weight
   *
   * @param {object[]} list.positionsExpired - expired positions
   * @param {string}   list.positionsExpired[].symbol - instrument symbol
   * @param {number}   list.positionsExpired[].weight - instrument weight
   *
   * @param {object[]} list.positionsUnavailable - unavailable positions
   * @param {string}   list.positionsUnavailable[].symbol - instrument symbol
   * @param {number}   list.positionsUnavailable[].weight - instrument weight
   *
   * @param {string}   list.type - one of "basket" or "portfolio"
   *
   * @param {object}   list.weightsManagement - weights management
   * @param {string}   list.weightsManagement.currency
   * @param {Date}     list.weightsManagement.date
   * @param {string}   list.weightsManagement.type - "fixed" or "drifting"
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  update(list: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.update;

    const params: any = {
      benchmark: list.benchmark,
      currency: null,
      id: list.id,
      name: list.name,
      ownerId: this.environment.account.user?.id,
      positions: [],
      type: list["type"].toUpperCase(),
      weightsDate: null,
    };

    params.positions = list.positions.map((position: any) => ({
      symbol: position.symbol,
      weight: position.weight,
    }));
    // Expired and unavailable positions are now not merged in the positions that
    // are saved in the portfolio - 19/07/2022
    // .concat(list?.positionsExpired ?? [])
    // .concat(list?.positionsUnavailable ?? []);

    if (list?.weightsManagement?.type === "drifting") {
      params.currency = list.weightsManagement.currency;
      params.weightsDate = list.weightsManagement.date;
    }

    return this.preparePost(url, params, null);
  }
  // ----------------------------------------------------- private methods
  /**
   * Apply drifting: if drifting is set, updates holdings weights
   *
   * Original holdings weights are stored in list.positionsSource
   *
   * @param {object} list - a list with getPrototype() shape
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   *
   * @ignore
   */
  async _applyDrifting(list: List) {
    if (list.weightsManagement.type === "drifting") {
      const serverStatus = await this.http["utils"].today();
      const dateSource = list.weightsManagement.date;
      const dateTarget = TDate.daysToIso8601(serverStatus.today);

      const response = await this._updateWeights(
        list.positions,
        list.weightsManagement.currency,
        dateSource!,
        dateTarget
      );
      list.positionsSource = deepClone(list.positions);

      const driftingWeights = response.v;
      const driftingWeightsMap: any = {};
      for (const holding of driftingWeights) {
        driftingWeightsMap[holding.S] = holding.A;
      }

      for (const holding of list.positions) {
        holding.weight = driftingWeightsMap[holding.symbol];
      }
    }
    return list;
  }

  /**
   * Apply a strategy to the list
   *
   * @param {string} url - the endpoint to call
   *
   * @param {object} params
   * @param {string} params.approach - the approch to be used appling
   *      strategy. It can be "systematic" or "spot".
   *
   *      <p>Default "systematic"</p>
   *
   *      <ul>
   *          <li>
   *              spot: applies the stategy at today
   *          </li>
   *          <li>
   *              systematic: applies the stategy and rebalances weights
   *              to today
   *          </li>
   *      </ul>
   *
   * @param {object} params.list - the list
   * @param {string} params.method - the method to be used appling
   *      strategy. It can be "tactical" or "implementative".
   *
   *      <p>Default "implementative"</p>
   *
   *      <ul>
   *          <li>
   *              implementative: use the universe of the strategy
   *          </li>
   *          <li>
   *              tactical: replace the universe of the strategy with the
   *              list
   *          </li>
   *      </ul>
   *
   * @param {object} params.strategy - the strategy to be applied to the
   *      list
   *
   * @param {boolean} isRationale - if true transparency is enabled.
   *      Default false
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   *
   * @ignore
   */
  async _applyStrategy(
    url: any,
    {
      approach,
      list,
      method,
      strategy,
    }: { approach: string; list: List; method: string; strategy: Strategy },
    isRationale: boolean = false
  ) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);

    const serverStatus = await this.http["utils"].today();
    const yesterday = serverStatus.today;

    const preparedStrategy =
      this.http["strategies"].prepareStrategyForRationale(strategy);

    const allocations = this._getPositionsAsAllocations(list.positions);

    // method: if tactical replace universe with the list
    if (preparedStrategy.entity_type !== "BUILDER" && method === "tactical") {
      preparedStrategy.params.universe = {
        search: {
          page: {
            page: 1,
            rows: list["positions"].length,
          },
          relations: [
            {
              range: list.type,
              domain: [String(list["id"])],
            },
          ],
          sort: {
            dimension: "marketcap",
            rev: true,
          },
        },
      };
    }

    const paramsApplyStrategy: any = {
      exAntePortfolio: {
        d: yesterday,
        v: allocations,
      },
      strategy: preparedStrategy["params"]["strategy"],
      universe: preparedStrategy["params"]["universe"],
      verbose: isRationale,
    };

    // approach management
    if (approach === "spot") {
      paramsApplyStrategy["date"] = yesterday;
    }

    const requestApplyStrategy = this.preparePost(url, paramsApplyStrategy);

    // approach management: if systematic, it need to update
    // weights at today
    if (approach === "systematic") {
      return requestApplyStrategy.then((ApplyStrategy) => {
        const urlWeightUpdates = endPointRoot + endpoints.lists.weightUpdates;

        const allocation = ApplyStrategy["data"]["allocation"]["v"];
        const currency = strategy["params"]["strategy"]["currency"];
        const d = ApplyStrategy["data"]["allocation"]["d"];

        const paramsWeightUpdates = {
          allocation: {
            d: d,
            v: allocation,
          },
          currency: currency,
          day: yesterday,
        };

        const requestWeightUpdates = this.preparePost(
          urlWeightUpdates,
          paramsWeightUpdates
        );

        return requestWeightUpdates.then((responseWeightUpdates) => {
          // replace systematic allocation with the
          // updated ones (at today)
          ApplyStrategy["data"]["allocation"] = responseWeightUpdates;

          return ApplyStrategy;
        });
      });
    }

    return requestApplyStrategy;
  }

  /**
   * Merges arrays removing duplicates
   *
   * @param {array.<array>} arrays
   */
  _arrayDistinct(arrays: any[]) {
    return Array.from(new Set(arrays.flat())).sort();
  }

  _get(accumulator?: any) {
    let chain = this._getFilter();

    // if (accumulator.properties.indexOf("analytics") !== -1) {
    //     chain = chain.then((listIds) =>
    //         this._getAnalytics(accumulator, listIds)
    //     );
    // }

    return chain.then((listIds) => this._getFetch(accumulator, listIds));
  }

  _prepareListAnalytics(listToPrepare) {
    const list = listToPrepare;

    //   alerts
    const alerts = {
      AT_Week_Upi_All_Rows: 0,
      AT_Week_Upgrades_All_Rows: list?.["upgrades_W"],
      AT_Week_Downgrades_All_Rows: list?.["downgrades_W"],
      AT_Month_Upgrades_All_Rows: list?.["upgrades_M"],
      AT_Month_Downgrades_All_Rows: list?.["downgrades_M"],
      AT_Month_Upi_All_Rows: 0,
      AT_Today_Upgrades_All_Rows: list?.["upgrades"],
      AT_Today_Downgrades_All_Rows: list?.["downgrades"],
      AT_Today_Upi_All_Rows: 0,
    };

    list.alerts = alerts;

    //Movers

    const movers = {
      moversUp: list?.ABnewHigh,
      moversDown: list?.CDnewLow,
    };

    list.movers = movers;

    //statistic

    const statistic = {
      movers,
      rate: list?.TCR,
      rateDayBefore: list?.TCR_D,
      cardinalityPerRating: {
        "0": list?.N,
        "1": list?.B,
        "2": list?.A,
        "-2": list?.D,
        "-1": list?.C,
      },
      weightsPerRating: {
        "1": list?.["B_%"],
        "2": list?.["A_%"],
        "-2": list?.["D_%"],
        "-1": list?.["C_%"],
      },
    };

    list.statistics = statistic;

    // TCR
    const tcr = {
      TCR_D: list?.TCR_D,
      TCR_M: list?.TCR_M,
      TCR_W: list?.TCR_W,
      TCR: list?.TCR,
    };

    list.tcr = tcr;

    return list;
  }

  // _getAnalytics(accumulator: any, listIds: any) {
  //     const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
  //     const url = endPointRoot + endpoints.lists.analytics;

  //     const params = {
  //         collectionIds: listIds,
  //     };

  //     return this.preparePost(url, params, null).then((response) => {
  //         const lists = response.data.list;
  //         for (const list of lists) {
  //             accumulator.listsMap[list.id] = list;
  //         }
  //         return listIds;
  //     });
  // }

  _getById(accumulator: any, id: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.getByIds;

    const analytics = [
      "TCR",
      "TCR_D",
      "TCR_W",
      "TCR_M",
      "upgrades",
      "upgrades_W",
      "upgrades_M",
      "downgrades",
      "downgrades_W",
      "downgrades_M",
      "ABnewHigh",
      "CDnewLow",
      "A",
      "B",
      "C",
      "D",
      "N",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "N_%",
      "benchmark",
      "positionsToday",
      "type",
      "ownerId",
      "name",
      "assetTypes",
      "weightsDate",
      "currency",
      "updateTime",
    ];

    if (
      "properties" in accumulator &&
      accumulator.properties != null &&
      accumulator.properties.length !== 0
    ) {
      accumulator.properties.forEach((property) => {
        if (!analytics.includes(property)) {
          analytics.push(property);
        }
      });
    }

    const analyticParam = analytics.map((analytic) => ({
      dimension: analytic,
    }));
    const params = [
      {
        classType: "COLLECTION",
        id: [id],
        extendedRequest: {
          onDemandResult: analyticParam,
        },
      },
    ];

    return this.preparePost(url, params, null).then(
      (response) => response.data[0].rows
    );
  }

  _getFetch(accumulator: any, listIds: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.getByIds;

    const params = [
      {
        classType: "COLLECTION",
        id: listIds,
        extendedRequest: {
          onDemandResult: [
            {
              dimension: "name",
            },
            {
              dimension: "ownerId",
            },
            {
              dimension: "type",
            },
          ],
        },
      },
    ];

    return this.preparePost(url, params, null).then(
      (response) => response.data[0].rows
    );
  }

  _getFilter(type?: "BASKET" | "PORTFOLIO") {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.getByIdsFilter;

    const userId = this.environment.account.user?.id;
    const params = {
      searches: [
        {
          filters: [
            {
              dimension: "ownerId",
              segments: [userId],
            },
          ],
        },
      ],
    };

    if (type) {
      params.searches[0].filters.push({
        dimension: "type",
        segments: [type as any],
      });
    }

    return this.preparePost(url, params, null).then(function _unbox(response) {
      return response.data.ids;
    });
  }

  /**
   *
   * @param {object} list - shaped as in getPrototype
   */
  _getHoldings(list: any) {
    const holdings = list.positionsToday;
    const holdingsSymbols = extractSymbols(holdings);

    const params = {
      properties: [
        {
          date: null,
          property: "type",
        },
      ],
      symbols: holdingsSymbols,
      type: "security" as const,
    };

    return this.http["instruments"]
      .fetch(params)
      .then((response: any) =>
        this._getHoldingsMergeAndPrepare(list, response)
      );
  }

  _getHoldingsMergeAndPrepare(list: any, response: any) {
    const positions = dataInjector(list["positionsToday"], response["data"], [
      "type",
    ]);

    // if an instrumnet has not type, it is unavailable
    for (let i = 0, length = positions.length; i < length; i++) {
      if (positions[i]["type"] == null) {
        positions[i]["type"] = "unavailable";
      }
    }

    list.positions = positions;

    return list;
  }

  _getPositionsAsAllocations(positions: any) {
    const _positions: any = [];
    for (let i = 0, length = positions.length; i < length; i++) {
      _positions.push({
        A: positions[i].weight,
        S: positions[i].symbol,
      });
    }

    return _positions;
  }

  _getSubscribed(accumulator?: any) {
    let chain = this.http["subscriptions"].get({
      type: "list",
    });

    // if (accumulator.properties.indexOf("analytics") !== -1) {
    //     chain = chain.then((responseSubscribed: any) => {
    //         const listIds = responseSubscribed.map((item: any) => item.id);

    //         return this._getAnalytics(accumulator, listIds).then(
    //             (/*response*/) => responseSubscribed
    //         );
    //     });
    // }

    return chain;
  }

  _normalizeAlerts(response: any) {
    const data = response["data"]["0"]["rows"];
    const userId = this.environment.account.user?.id;

    data.map((list) => (list["isReadOnly"] = list["ownerId"] !== userId));

    const result = {
      portfolios: data.filter((el) => el.type === "PORTFOLIO"),
      baskets: data.filter((el) => el.type === "BASKET"),
    };

    return result;
  }

  /**
   * Normalize compare response
   *
   * @param {object} response
   * @param {object} response.data
   * @param {object} response.data.stockCollectionsBalancing
   * @param {object} response.data.stockCollectionsBalancing.balance
   * @param {object} response.data.stockCollectionsBalancing.master
   * @param {object} response.data.stockCollectionsBalancing.operator
   * @param {string} response.status
   *
   * @ignore
   */
  _normalizeCompare(response: any) {
    const data = response["data"]["orders"];
    const _response = {
      diff: data["delta"],
      source: data["from"],
      // target: data["target"],
      target: this._normalizeList(data["target"]),

      // used in Systematic portfolio rebalance page
      result: response["data"]["final"],
      turnover: response["data"]["turnover"],
    };

    return _response;
  }

  _normalizeIndex(response: any) {
    const lists = response["data"]["list"];
    const userId = this.environment.account.user?.id;

    for (let i = 0, lengthI = lists.length; i < lengthI; i++) {
      const list = lists[i];
      list["ownerId"] = userId;
      // ------------------------------------------------------ alerts
      list["alerts"]["daily"] = list["daily"];
      list["alerts"]["monthly"] = list["monthly"];
      list["alerts"]["weekly"] = list["weekly"];

      list["alerts"]["daily"]["positions"] = [];
      list["alerts"]["monthly"]["positions"] = [];
      list["alerts"]["weekly"]["positions"] = [];

      delete list["daily"];
      delete list["monthly"];
      delete list["weekly"];
      // ---------------------------------------------------- holdings
      list["positions"] = list["positionsNotExpired"];

      delete list["positionsNotExpired"];
      // --------------------------------- what / where (peer weights)
      list["whatWhere"] = list["peersWeights"]
        ? {
            peers: {
              data: {
                peersWeights: list["peersWeights"],
              },
            },
          }
        : null;
      delete list["peersWeights"];

      lists[i] = this._normalizeList(list);
    }

    return lists;
  }

  /**
   *
   * Prepares the overview fo all alerts of the list removing duplicates
   *
   * @param {object}         source - alerts data
   *
   * @param {object}         source.downgrades
   * @param {array.<string>} source.downgrades.confirmation - array of symbols
   * @param {array.<string>} source.downgrades.reversal - array of symbols
   * @param {array.<string>} source.newHighs - array of symbols
   * @param {array.<string>} source.newLows - array of symbols
   * @param {array.<string>} source.notifications.duration - array of symbols
   * @param {array.<string>} source.notifications.magnitude - array of symbols
   * @param {array.<string>} source.upgrades.confirmation - array of symbols
   * @param {array.<string>} source.upgrades.reversal - array of symbols
   *
   * @param {array}          target - an array to store the result
   * @param {object}         instruments - a map where symbols are keys to
   *      retrive instruments data
   * @param {array.<string>} checklist - array of already inserted symbols
   *
   *
   * @ignore
   */
  _normalizeIndexAlerts(
    source: any,
    target: any,
    instruments: any[],
    checklist: any[]
  ) {
    // downgrades confirmation
    for (const symbol of source.downgrades.confirmation) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
    // downgrades reversal
    for (const symbol of source.downgrades.reversal) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
    // new highs - weekly data are not available
    if ("newHighs" in source) {
      for (const symbol of source.newHighs) {
        if (!checklist.includes(symbol)) {
          checklist.push(symbol);
          target.push(instruments[symbol]);
        }
      }
    } else {
      source.newHighs = [];
    }
    // new lows - weekly data are not available
    if ("newLows" in source) {
      for (const symbol of source.newLows) {
        if (!checklist.indexOf(symbol)) {
          checklist.push(symbol);
          target.push(instruments[symbol]);
        }
      }
    } else {
      source.newLows = [];
    }
    // notifications duration
    for (const symbol of source.notifications.duration) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
    // notifications magnitude
    for (const symbol of source.notifications.magnitude) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
    // upgrades confirmation
    for (const symbol of source.upgrades.confirmation) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
    // upgrades reversal
    for (const symbol of source.upgrades.reversal) {
      if (!checklist.includes(symbol)) {
        checklist.push(symbol);
        target.push(instruments[symbol]);
      }
    }
  }

  _normalizeList(rawList: any): List {
    const list = this.getPrototype();
    list.id = rawList.id;
    list.isReadOnly = rawList.isReadOnly ?? false;
    list.name = rawList.name;
    list.ownerId = rawList.ownerId;
    list.type = rawList.type;
    list.updateTime = rawList.updateTime;

    if (
      "positionsSource" in rawList &&
      rawList.positionsSource &&
      rawList.positionsSource.length
    ) {
      list.positionsSource = [...rawList.positionsSource];
    }

    // alerts
    if (rawList.alerts != null) {
      list.alerts = rawList.alerts;
    }
    // benchmark
    const benchmark = rawList.benchmark;
    if (benchmark != null) {
      if (typeof benchmark === "string") {
        list.benchmark = benchmark;
      } else if (Object.keys(benchmark).length > 0) {
        list.benchmark = benchmark.symbol;
      }
    }

    // movers
    if (rawList.movers != null) {
      list.movers.down = rawList.movers.moversDown;
      list.movers.up = rawList.movers.moversUp;
    }

    if (rawList.statistics?.movers != null) {
      list.movers.down = rawList.statistics.movers.moversDown;
      list.movers.up = rawList.statistics.movers.moversUp;
    }
    // positions: if a position has type ExpiredStock, it is expired
    // positions: if a position hasn't currency, it is unavailable
    if (
      "positions" in rawList &&
      rawList.positions != null &&
      rawList.positions?.length > 0
    ) {
      const positions = rawList.positions;
      list.positions = [];
      for (const positionSource of positions) {
        if (positionSource?.type === "ExpiredStock") {
          // Used in systematic porttfolio (history)
          // TODO check for use in systematic portfolio
          // Also used in Portfolio->Edit to maintain the positions
          list.positionsExpired.push({
            symbol: positionSource.symbol,
            weight: positionSource.weight,
          });
        } else if (positionSource?.type === "unavailable") {
          // Only used when saving again in Portfolio->Edit page
          // to not lose the unavailable positions
          list.positionsUnavailable.push({
            symbol: positionSource.symbol,
            weight: positionSource.weight,
          });
        } else {
          const positionTarget = {
            symbol: positionSource.symbol,
            weight: positionSource.weight,
          };

          list.positions.push(positionTarget);
          list.positionsIndex[positionSource.symbol] =
            list.positions.length - 1;
        }
      }
    }
    // statistics
    if ("statistics" in rawList && rawList.statistics != null) {
      list.statistics = this._normalizeListStatistics(rawList.statistics);
    }
    // 2021-11-26 New field tcr outside .statistics
    if ("tcr" in rawList && rawList.tcr != null && rawList.tcr.TCR != null) {
      if (list.statistics == null) {
        list.statistics = {
          tcr: {
            today: null,
            yesterday: null,
            lastMonth: null,
            lastWeek: null,
          },
          cardinalityPerRating: {
            "0": null,
            "1": null,
            "2": null,
            "-1": null,
            "-2": null,
          },
          weightPerRating: {
            "0": null,
            "1": null,
            "2": null,
            "-1": null,
            "-2": null,
          },
          weightTotal: null,
        };
      }
      list.statistics.tcr = {
        today: toInt(rawList.tcr, "TCR", null),
        yesterday: toInt(rawList.tcr, "TCR_D", null),
        lastMonth: toInt(rawList.tcr, "TCR_M", null),
        lastWeek: toInt(rawList.tcr, "TCR_W", null),
      };
    }
    // what / where information
    if (rawList.whatWhere != null) {
      list.whatWhere = rawList.whatWhere;
    }

    // synthetic fields suitable for widget manipulation
    list._s_name = list?.name?.toLowerCase() ?? null;
    list._s_tcr = list?.statistics?.tcr ?? null;

    // weightsManagement
    if (rawList.weightsDate != null) {
      list.weightsManagement.currency =
        rawList.currency == null ? "local" : rawList.currency;
      list.weightsManagement.date = rawList.weightsDate;
      list.weightsManagement.type = "drifting";
    }

    return list;
  }

  _normalizeListMovers(rawMovers: any) {
    return {
      down: rawMovers.moversDown,
      up: rawMovers.moversUp,
    };
  }

  _normalizeListStatistics(rawStatistics: any): List["statistics"] {
    // 2021-11-26
    // tcr will be filled after this method, because it is not inside
    // statistics field anymore
    const statistics: List["statistics"] = {
      tcr: {
        today: null,
        yesterday: null,
        lastMonth: null,
        lastWeek: null,
      },
      cardinalityPerRating: {
        "2": 0,
        "1": 0,
        "0": 0,
        "-1": 0,
        "-2": 0,
      },
      weightPerRating: {
        "2": 0,
        "1": 0,
        "0": 0,
        "-1": 0,
        "-2": 0,
      },
      weightTotal: 0,
    };

    if ("totalWeight" in rawStatistics && rawStatistics.totalWeight != null) {
      statistics.weightTotal = rawStatistics.totalWeight;
    }

    if ("cardinalityPerRating" in rawStatistics) {
      if ("2" in rawStatistics.cardinalityPerRating) {
        statistics.cardinalityPerRating["2"] =
          rawStatistics.cardinalityPerRating["2"];
      }
      if ("1" in rawStatistics.cardinalityPerRating) {
        statistics.cardinalityPerRating["1"] =
          rawStatistics.cardinalityPerRating["1"];
      }
      if ("0" in rawStatistics.cardinalityPerRating) {
        statistics.cardinalityPerRating["0"] =
          rawStatistics.cardinalityPerRating["0"];
      }
      if ("-1" in rawStatistics.cardinalityPerRating) {
        statistics.cardinalityPerRating["-1"] =
          rawStatistics.cardinalityPerRating["-1"];
      }
      if ("-2" in rawStatistics.cardinalityPerRating) {
        statistics.cardinalityPerRating["-2"] =
          rawStatistics.cardinalityPerRating["-2"];
      }
    }

    if ("weightsPerRating" in rawStatistics) {
      if ("2" in rawStatistics.weightsPerRating) {
        statistics.weightPerRating["2"] = rawStatistics.weightsPerRating["2"];
      }
      if ("1" in rawStatistics.weightsPerRating) {
        statistics.weightPerRating["1"] = rawStatistics.weightsPerRating["1"];
      }
      if ("0" in rawStatistics.weightsPerRating) {
        statistics.weightPerRating["0"] = rawStatistics.weightsPerRating["0"];
      }
      if ("-1" in rawStatistics.weightsPerRating) {
        statistics.weightPerRating["-1"] = rawStatistics.weightsPerRating["-1"];
      }
      if ("-2" in rawStatistics.weightsPerRating) {
        statistics.weightPerRating["-2"] = rawStatistics.weightsPerRating["-2"];
      }
    }

    return statistics;
  }

  _normalizeOptimize(response: any) {
    const data: any = [];
    const _data = response["data"]["allocation"]["v"];

    for (const _datum of _data) {
      data.push({
        symbol: _datum["S"],
        weight: _datum["A"],
      });
    }

    return data;
  }

  /**
   * Updates holdings weights to the given date
   *
   * @param {Array} holdings - [{symbol: "EXAMPLE", weight: 0.12}]
   * @param {string} currency - one of "local", "AUD", "CAD", "EUR", "GBP",
   *      "JPY" or "USD"
   * @param {string} dateSource - date in ISO 8601 format (e.g. 2020-01-01)
   * @param {string} dateTarget - date in ISO 8601 format (e.g. 2021-01-01)
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   *
   * @ignore
   */
  _updateWeights(
    holdings: ListPosition[],
    currency: Currency | null,
    dateSource: IsoDate,
    dateTarget: IsoDate
  ) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.lists.weightUpdates;

    const params = {
      allocation: {
        d: TDate.dateToDays(new Date(dateSource)),
        v: [] as ServerListPosition[],
      },
      currency: currency,
      day: TDate.dateToDays(new Date(dateTarget)),
    };

    params.allocation.v = holdings.map((holding) => ({
      A: holding.weight,
      S: holding.symbol,
    }));

    return this.preparePost(url, params);
  }
}
