import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import Modal from "../../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import CreateContentReact from "./CreateContentReact/CreateContentReact";

type DialogCreateProps = {
  onDialogOk: (event) => void;
  closeModal: Function;
};

export function DialogCreate({ onDialogOk, closeModal }: DialogCreateProps) {
  const [strategies, setStrategies] = useState([]);
  const [lists, setLists] = useState([]);

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);

  const getUserCollections = useCallback(async () => {
    function sortByCaseInsensitive(property) {
      return function (a, b) {
        var valueA = String(a[property]).toLowerCase();
        var valueB = String(b[property]).toLowerCase();

        if (valueA > valueB) {
          return 1;
        }

        if (valueA < valueB) {
          return -1;
        }

        return 0;
      };
    }

    try {
      const userLists = await listsAPI.get();
      const strategiesList = await strategiesAPI.getList([
        "object.entity_type",
      ]);
      const userStrategy: any = strategiesList;

      let item: any = null;

      const _lists = userLists?.data?.sort(sortByCaseInsensitive("name"));
      const lists: any = [];
      for (let i = 0, length = _lists.length; i < length; i++) {
        item = _lists[i];
        if (item["type"] === "PORTFOLIO") {
          lists.push({
            label:
              item["name"] +
              (item["isReadOnly"]
                ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
                : ""),
            value: item["id"],
          });
        }
      }

      const _strategies = userStrategy.sort(sortByCaseInsensitive("name"));
      const strategies: any = [];
      for (let i = 0, length = _strategies.length; i < length; i++) {
        item = _strategies[i];
        if (item["entity_type"] === "BUILDER") {
          strategies.push({
            label:
              item["name"] +
              (item["isReadOnly"]
                ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
                : ""),
            value: item["id"],
          });
        }
      }

      setLists(lists);
      setStrategies(strategies);
    } catch (error) {
      console.error(error);
    }
  }, [listsAPI, strategiesAPI]);

  useEffect(() => {
    getUserCollections();

    return () => {
      setStrategies([]);
      setLists([]);
    };
  }, [getUserCollections]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const contentRef = useRef<any>(null);
  const createHandler = useCallback(() => {
    let state = contentRef?.current?.getState();
    if (state && !state.basic.autoRebalance) {
      setShowConfirmationModal(true);
    } else if (state && state.basic.autoRebalance) {
      onDialogOk(state);
      closeModal();
    }
  }, [closeModal, onDialogOk]);

  const onEnabledAutoRebalanceConfirmation = useCallback(() => {
    let state = contentRef?.current?.getState();
    if (state != null) {
      setShowConfirmationModal(false);
      state.basic.autoRebalance = true;
      onDialogOk(state);
      closeModal();
    }
  }, [closeModal, onDialogOk]);

  const onKeepDisabledAutoRebalanceConfirmation = useCallback(() => {
    let state = contentRef?.current?.getState();
    if (state != null) {
      setShowConfirmationModal(false);
      onDialogOk(state);
      closeModal();
    }
  }, [closeModal, onDialogOk]);

  const [confirmationRadioValue, setConfirmationRadioValue] = useState<
    "enable" | "disable"
  >("disable");
  const onChangeConfirmationRadio = useCallback((e) => {
    let value: "enable" | "disable" = e.target.value;
    setConfirmationRadioValue(value);
  }, []);

  const onConfirm = useCallback(() => {
    switch (confirmationRadioValue) {
      case "enable":
        onEnabledAutoRebalanceConfirmation();
        break;
      case "disable":
        onKeepDisabledAutoRebalanceConfirmation();
        break;
    }
  }, [
    confirmationRadioValue,
    onEnabledAutoRebalanceConfirmation,
    onKeepDisabledAutoRebalanceConfirmation,
  ]);

  return (
    <Box
      data-s={"gfg"}
      height={"auto"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
      gap={1}
    >
      <Box
        display={"flex"}
        width={"100%"}
        height={"auto"}
        overflow={"auto"}
        position={"relative"}
      >
        <CreateContentReact
          ref={contentRef}
          lists={lists}
          strategies={strategies}
        />

        {showConfirmationModal && (
          <Modal
            buttonsEnalbed
            buttons={[
              {
                name: "Cancel",
                callback: () => setShowConfirmationModal(false),
                variant: "cancel",
              },
              {
                name: "Confirm",
                callback: onConfirm,
              },
            ]}
            closeIcon={false}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <div className="tHelp-itemTips">
                <Box p={1} display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography>
                    <span className="i-info"></span>{" "}
                    <strong>Auto rebalance</strong>
                  </Typography>
                  <Typography>
                    Trendrating offers the possibility to rebalance portfolios
                    automatically. This allows you to ensure that the portfolio
                    is updated on the rebalancing date without any intervention.
                    In this way you will be sure that the systematic strategy
                    has been applied correctly. However, in this way on the day
                    of rebalancing you will not be able to know the buy and sell
                    orders because the system will have already executed them
                    and it will be more difficult to implement the strategy on
                    your real portfolios.
                  </Typography>
                </Box>
              </div>

              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Typography>Do you want to enable auto rebalance?</Typography>
                <RadioGroup
                  onChange={onChangeConfirmationRadio}
                  value={confirmationRadioValue}
                >
                  <FormControlLabel
                    value="enable"
                    control={<Radio />}
                    label="I want to enable auto rebalance"
                  />
                  <FormControlLabel
                    value="disable"
                    control={<Radio />}
                    label="I want to rebalance manually myself"
                  />
                </RadioGroup>
              </Box>
            </Box>
          </Modal>
        )}
      </Box>
      <Box display={"flex"} justifyContent={"end"} width={"100%"} gap={2}>
        <Button onClick={createHandler}>Create</Button>
        <Button variant="tr_button_cancel" onClick={() => closeModal()}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
