import { Box, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { WhiteListPanel } from "../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/WhiteListPanel/WhiteListPanel";
import { ActionRankContext } from "../../actions/ActionRankContext/ActionRankContext";
import { RankUniverseSelection } from "../RankUniverseSelection/RankUniverseSelection";
import { SectionWithTitle } from "../TemplateRow/TemplateRow";

type OptionalRowProps = {
  page: "rank" | "screening" | "portfolioAnalysis";
};

export function OptionalRow({ page }: OptionalRowProps) {
  const { highlightListId, rankReducer } = useContext(ActionRankContext);
  const [widgetKey, setWidgetKey] = useState(Date.now());

  const refresh = useCallback(() => {
    setWidgetKey(Date.now());
  }, []);

  const setHighlightedList = useCallback(
    (id?: number) => {
      rankReducer({ type: "SET_LIST_HIGHLIGHT", payload: id });
    },
    [rankReducer]
  );

  const onRemovePortfolio = useCallback(() => {
    setHighlightedList(undefined);
    refresh();
  }, [refresh, setHighlightedList]);

  return page === "portfolioAnalysis" ? (
    <RankUniverseSelection isUsedAsOptional />
  ) : (
    <SectionWithTitle label="Optional">
      <ListHighlight
        highlightListId={highlightListId}
        widgetKey={widgetKey}
        setHighlightedList={setHighlightedList}
        onRemovePortfolio={onRemovePortfolio}
      />
    </SectionWithTitle>
  );
}

const ListHighlight = ({
  highlightListId,
  widgetKey,
  setHighlightedList,
  onRemovePortfolio,
}) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1} p={1}>
      <Typography>
        {highlightListId == null
          ? "Highlight the constituents of a portfolio or a basket"
          : "Highlight"}
      </Typography>
      <WhiteListPanel
        key={widgetKey}
        setWhiteListValue={setHighlightedList}
        initValue={highlightListId ?? null}
        closeIcon={true}
      />
      {highlightListId != null && (
        <span
          className="i-delete"
          onClick={onRemovePortfolio}
          role={"button"}
          style={{ fontSize: "1.3em", cursor: "pointer" }}
        />
      )}
    </Box>
  );
};
