import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import Search from "../../../../../widgets/ReactSearch/Search";
import DatePicker from "../../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";
import { InfoTooltip } from "../../../../alerts/InfoTooltip";
import { useTranslation } from "react-i18next";
import { TDate } from "../../../../../../../trendrating/date/TDate";

type Props = {
  name: string | null;
  weights: {
    currency: null | string;
    date: null | string;
    type: "fixed" | "drifting";
  } | null;
  setName: Function;
  setWeights: Function;
  benchmark: string | undefined;
  setBenchmark: Function;
  type: "PORTFOLIO" | "BASKET";
};

export default function Header({
  name,
  weights,
  setWeights,
  setName,
  benchmark,
  setBenchmark,
  type,
}: Props) {
  const { t } = useTranslation();
  const _weights = useMemo(() => {
    if (weights) {
      return weights;
    }
    return {
      currency: null,
      date: null,
      type: "fixed",
    };
  }, [weights]);
  const maxDate = useMemo(() => {
    let date: any = new Date();
    date.setDate(date.getDate() - 1);
    return TDate.dateToIso8601(date);
  }, []);
  useEffect(() => {
    if (_weights.type === "drifting" && _weights.date == null) {
      setWeights({
        ..._weights,
        currency: "local",
        date: maxDate,
      });
    }
  }, [_weights, maxDate, setWeights]);
  return (
    <Box display={"flex"} gap={1}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Typography>Name</Typography>
          <TextField
            size="small"
            placeholder="Set name"
            value={name ?? ""}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Typography>Benchmark</Typography>
          <Search
            showInstrumentInfoOnSelect={false}
            value={benchmark}
            onSelectInstrument={(e) => {
              if (e) {
                setBenchmark(e.symbol);
              } else {
                setBenchmark(null);
              }
            }}
          />
        </CardContent>
      </Card>

      {type === "PORTFOLIO" && (
        <Card sx={{ boxShadow: 3 }}>
          <CardContent
            sx={{ display: "flex", gap: 1, flexDirection: "column" }}
          >
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <Typography>Weights</Typography>
              <InfoTooltip text={t("Weights_management_tooltip")} title={""} />
            </Box>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setWeights({ currency: null, date: null, type: "fixed" });
                  }}
                  variant={_weights.type === "fixed" ? "contained" : undefined}
                >
                  Fixed
                </Button>
                <Button
                  onClick={() => {
                    setWeights({ ..._weights, type: "drifting" });
                  }}
                  variant={
                    _weights.type === "drifting" ? "contained" : undefined
                  }
                >
                  Drifting
                </Button>
              </ButtonGroup>
              {_weights.type === "drifting" && (
                <Box>
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Typography>Date</Typography>
                    <DatePicker
                      maxDate={maxDate}
                      input={_weights.date}
                      onChangeDate={(val) => {
                        setWeights({
                          ..._weights,
                          date: val,
                        });
                      }}
                    />
                    <Select
                      size="small"
                      sx={{
                        "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#2a7092 !important",
                        },
                      }}
                      onChange={(e) => {
                        setWeights({
                          ..._weights,
                          currency: e.target.value,
                        });
                      }}
                      value={_weights.currency}
                    >
                      <MenuItem value={"local"}>
                        <Typography>Local</Typography>
                      </MenuItem>
                      <MenuItem value={"USD"}>
                        <Typography>USD</Typography>
                      </MenuItem>
                      <MenuItem value={"EUR"}>
                        <Typography>EUR</Typography>
                      </MenuItem>
                      <MenuItem value={"GBP"}>
                        <Typography>GBP</Typography>
                      </MenuItem>
                      <MenuItem value={"CHF"}>
                        <Typography>CHF</Typography>
                      </MenuItem>
                      <MenuItem value={"CAD"}>
                        <Typography>CAD</Typography>
                      </MenuItem>
                      <MenuItem value={"JPY"}>
                        <Typography>JPY</Typography>
                      </MenuItem>
                      <MenuItem value={"AUD"}>
                        <Typography>AUD</Typography>
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
