/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyChart
 * @summary Strategy - Chart
 *
 */

import { escapeEntity } from "./Generator";

export function sectionsStrategyChart(input, section, datum, pageOrientation) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    let chartStyle = {
        height: 400,
        width: 632,
    };

    const orientation = pageOrientation;
    if (orientation === "portrait") {
        // Base
        chartStyle.height = 334;
        chartStyle.width = 530;
        // Adjust for different sizes
        if (datum["size"] == null || datum["size"] === "large") {
            // Already defined before, use it as default
        } else if (datum["size"] === "medium") {
            chartStyle["height"] = 280;
            chartStyle["width"] = 530;
        } else if (datum["size"] === "small") {
            chartStyle["height"] = 167;
            chartStyle["width"] = 265;
        }
    } else {
        chartStyle.height = 474;
        chartStyle.width = 750;
        if (datum["size"] == null || datum["size"] === "large") {
            // Already defined before, use it as default
        } else if (datum["size"] === "medium") {
            chartStyle["height"] = 400;
            chartStyle["width"] = 750;
        } else if (datum["size"] === "small") {
            chartStyle["height"] = 237;
            chartStyle["width"] = 375;
        }
    }

    // Set alignment
    chartStyle["align"] = datum["align"] || "left";

    const _section: any = {
        data: {
            head: [[]],
            body: [
                [
                    {
                        style: chartStyle,
                        value: datum.svg,
                    },
                ],
            ],
        },
        type: "chartCustom",
    };
    const layout = {
        data: {
            layoutRow: [[_section /*, ...*/]],
        },
        type: "layout",
    };
    _sections.push(layout);

    return _sections;
}
