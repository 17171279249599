import { Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import style from "./WizzardComponents.module.scss";

type WizzOperatorProps = {
  operatorId: string;
  operators: any[] | null;
  rState: any;
  options: any;
  dispatcher: Function;
  customStyle?: React.CSSProperties;
};

export default function WizzOperator({
  customStyle,
  operatorId,
  operators,
  rState,
  options,
  dispatcher,
}: WizzOperatorProps) {
  const defStyleClass = useCallback(
    (item) => {
      if (item["value"] === operatorId && rState["editing"]) {
        return "tFormSelectionAddRule-listItem--selected";
      } else {
        // if (item["value"] === operatorId && rState["editing"] === false) {
        //   return "tFormSelectionAddRule-listItem--selected";
        // } else {
        return "";
      }
    },
    [operatorId, rState]
  );

  const [target, setTarget] = useState<any>(null);

  useEffect(() => {
    if (rState["editing"] === false && target != null) {
      highlightSelectedWhenCreating(target, "tFormSelectionAddRule-listItem--selected");
    }
  });

  return (
    <Card style={customStyle ? customStyle : {}} sx={{ height: "100%", boxShadow: 3 }}>
      <CardContent>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Rank Order
        </Typography>
        <ul>
          {operators &&
            operators.map((item, index) => (
              <li className={/*`${style.listItem}`*/ defStyleClass(item)} key={index}>
                <span
                  onClick={(e: any) => {
                    onClickOnOperatorOption(rState, item, dispatcher);

                    setTarget(e.target);
                  }}
                  className={style.listItemContent}
                >
                  {item.label}
                </span>
              </li>
            ))}
        </ul>
      </CardContent>
    </Card>
  );
}

const onClickOnOperatorOption = (rState, prop, dispatcher) => {
  // const operator = prop.value;
  const operatorParams = {
    augmentation: {
      value: prop.value,
    },
    subject: prop.value,
  };
  // dispatcher({ type: "SET_OPERATOR", payload: operator });
  dispatcher({ type: "SET_OPERATOR_PARAMS", payload: operatorParams });
  dispatcher({
    type: "SET_EDITING",
    payload: false,
  });
  dispatcher({
    type: "SET_SHOW_VALUE_SECTION",
    payload: rState["field"]["function"] === "quantile" ? true : false,
  });
};

const highlightSelectedWhenCreating = (element: HTMLElement, classToApply: string) => {
  if (element) {
    let siblings: any = Array.from(element.parentNode!.parentNode!.childNodes);
    siblings.forEach((item: HTMLElement) => {
      if (item.classList.contains(classToApply)) {
        item.classList.remove(classToApply);
      }
    });
    element.parentElement!.classList.add(classToApply);
  }
};
