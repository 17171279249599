import { Card, CardContent, Switch } from "@mui/material";
import { AdvancedSelector } from "../../../../../../../components/AdvancedSelector/AdvancedSelector";
import { InfoTooltip } from "../../../../alerts/InfoTooltip";
import styles from "./Dispersion.module.scss";
import { useMemo } from "react";

type ControlsProps = {
  timeframe: string;
  interval: number;
  dispatch: Function;
  viewType: "histogram" | "range";
  segment:
    | "1 Industry"
    | "3 Sector"
    | "Country"
    | "Area"
    | "Region"
    | "3 Level"
    | "Currency"
    | "Type"
    | "all";
  trimOutliers: boolean;
  constituentsType: string[];
  etfSegment: "etfgeo" | "etfclass" | "specialty" | "all_etf";
  listType: "PORTFOLIO" | "BASKET";
};

const timeframeOptions = [
  {
    label: "3M",
    value: "3_months",
  },
  {
    label: "6M",
    value: "6_months",
  },
  {
    label: "12M",
    value: "12_months",
  },
];

const intervalOptions = [
  {
    label: "5%",
    value: 5,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "25%",
    value: 25,
  },
];

const viewTypeOptions = [
  { label: "Histogram", value: "histogram" },
  { label: "Range", value: "range" },
];

export default function Controls({
  timeframe,
  dispatch,
  interval,
  viewType,
  segment,
  trimOutliers,
  constituentsType,
  etfSegment,
  listType,
}: ControlsProps) {
  const { controls_block, controls_title_tooltip } = styles;

  const segmentOptions = useMemo(
    () => [
      {
        label: listType === "PORTFOLIO" ? "Portfolio" : "Basket",
        value: "all",
      },
      { label: "Market", value: "Country" },
      { label: "Sector", value: "1 Industry" },
      { label: "Size", value: "3 Level" },
    ],
    [listType]
  );

  const segmentOnETFsOptions = useMemo(
    () => [
      {
        label: listType === "PORTFOLIO" ? "Portfolio" : "Basket",
        value: "all_etf",
      },
      {
        label: "Inv. Region",
        value: "etfgeo",
      },
      {
        label: "Asset class",
        value: "etfclass",
      },
    ],
    [listType]
  );

  const onSetValue = (action: { type: string; payload: string | number }) => {
    dispatch(action);
  };

  const isBasketMultiTypes = constituentsType["length"] > 1;

  const isOnlyEtf = !isBasketMultiTypes && constituentsType[0] === "ETF";

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className={controls_block}>
          <p>Segment</p>
          {!isOnlyEtf ? (
            <AdvancedSelector
              actualValue={segment}
              setValue={(event) =>
                onSetValue({ type: "SET_SEGMENT", payload: event })
              }
              manuallyProvidedOptions={segmentOptions}
            />
          ) : (
            <AdvancedSelector
              actualValue={etfSegment}
              setValue={(event) =>
                onSetValue({
                  type: "SET_ETF_SEGMENT",
                  payload: event,
                })
              }
              manuallyProvidedOptions={segmentOnETFsOptions}
            />
          )}
        </div>
        <div className={controls_block}>
          <p>Performance</p>
          <AdvancedSelector
            actualValue={timeframe}
            setValue={(event) =>
              onSetValue({ type: "SET_PERIOD", payload: event })
            }
            manuallyProvidedOptions={timeframeOptions}
          />
        </div>
        <div className={controls_block}>
          <p>Top / Bottom</p>
          <AdvancedSelector
            actualValue={interval}
            setValue={(event) =>
              onSetValue({
                type: "SET_INTERVAL",
                payload: parseInt(event),
              })
            }
            manuallyProvidedOptions={intervalOptions}
          />
        </div>
        {(segment !== "all" || etfSegment !== "all_etf") && (
          <div className={controls_block}>
            <p>View Type</p>
            <AdvancedSelector
              actualValue={viewType}
              setValue={(event) =>
                onSetValue({
                  type: "SET_VIEW_TYPE",
                  payload: event,
                })
              }
              manuallyProvidedOptions={viewTypeOptions}
            />
          </div>
        )}
        <div className={controls_block}>
          <div className={controls_title_tooltip}>
            Filter outliers{" "}
            <InfoTooltip
              style={{ top: -10 }}
              title={""}
              text="The outliers are stocks that perform abnormally relative to the average of all stocks in the same set."
            />
          </div>
          <Switch
            size="small"
            value={trimOutliers}
            onChange={(e, _checked) =>
              dispatch({
                type: "SET_TRIM_OUTLIERS",
                payload: _checked,
              })
            }
          />
        </div>
      </CardContent>
    </Card>
  );
}
