import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../../trendrating/app/formatter/FormOptions";

type RangeVolatilityProps = {
  handleChange: (value) => void;
  initValue: { left: null | number; right: null | number };
};

export function RangeVolatility({
  handleChange,
  initValue,
}: RangeVolatilityProps) {
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const options = useMemo(() => new FormOptions(appSetup), [appSetup]);

  const initialValue = useMemo(() => {
    const intervalsOpts = options.getField("VOLATILITY");

    if (initValue) {
      const initValueCopy = { ...initValue };

      if (initValue["right"] != null) {
        initValue["right"] = initValue["right"] / 100;
      }

      if (initValue["left"] != null) {
        initValue["left"] = initValue["left"] / 100;
      }

      let leftIndex = -1;
      let rightIndex = -1;

      for (let i = 0, length = intervalsOpts.length; i < length; i++) {
        if (initValueCopy.left === intervalsOpts[i].ge) {
          leftIndex = i;
        }

        if (initValueCopy.right === intervalsOpts[i].le) {
          rightIndex = i;
        }
      }

      const newIntervals = [...intervalsOpts];
      let checkedCounter = 0;
      let areAllSelected = false;

      for (let i = 0, length = intervalsOpts.length; i < length; i++) {
        if (i >= leftIndex && i <= rightIndex) {
          newIntervals[i].checked = true;
          checkedCounter++;
        } else {
          newIntervals[i].checked = false;
        }
      }

      areAllSelected = checkedCounter === intervalsOpts.length;

      if (areAllSelected) {
        for (const interval of newIntervals) {
          interval["checked"] = false;
        }
      }

      return newIntervals;
    } else {
      return intervalsOpts;
    }
  }, [initValue, options]);

  const [intervals, setIntervals] = useState(initialValue);

  const disjointRemoval = useCallback(
    (/*Number*/ start, /*Number*/ end) => {
      setIntervals((current) => {
        const newIntervals = [...current];
        let checkedCounter = 0;
        let areAllSelected = false;

        for (let i = 0, length = intervals.length; i < length; i++) {
          if (i >= start && i <= end) {
            newIntervals[i].checked = true;
            checkedCounter++;
          } else {
            newIntervals[i].checked = false;
          }
        }

        areAllSelected = checkedCounter === intervals.length;

        if (areAllSelected) {
          for (const interval of newIntervals) {
            interval["checked"] = false;
          }
        }

        return newIntervals;
      });
    },
    [intervals]
  );

  const updateStatus = useCallback(
    (/*Number*/ intervalIndex) => {
      if (intervalIndex !== undefined) {
        var checked = !intervals[intervalIndex].checked;
        intervals[intervalIndex].checked = checked;
      }

      const value: any = {
        left: null,
        right: null,
      };

      var item: any = null;
      var startIndex = -1;
      var endIndex = -1;
      for (let i = 0, length = intervals.length; i < length; i++) {
        item = intervals[i];
        if (item.checked) {
          value.left = item.ge ? item.ge : null;
          startIndex = i;
          break;
        }
      }
      for (let i = intervals.length - 1; i >= 0; i--) {
        item = intervals[i];
        if (item.checked) {
          value.right = item.le ? item.le : null;
          endIndex = i;
          break;
        }
      }

      disjointRemoval(startIndex, endIndex);

      if (value.left != null) {
        value["left"] = value["left"] * 100;
      }

      if (value.right != null) {
        value["right"] = value["right"] * 100;
      }

      handleChange(value);
    },
    [disjointRemoval, handleChange, intervals]
  );

  const selectInterval = useCallback(
    (intervalIndex) => {
      updateStatus(intervalIndex);
    },
    [updateStatus]
  );

  return (
    <>
      <label>Volatility</label>
      <span>
        <span className="select-range" style={{ width: "100%" }}>
          <table className="select">
            <thead>
              <tr>
                {intervals.map((interval, index) => {
                  return (
                    <th
                      onClick={() => selectInterval(index)}
                      key={uuidv4()}
                      className={
                        interval.checked ? "interval checked" : "interval"
                      }
                      dangerouslySetInnerHTML={{ __html: interval.innerHTML }}
                      title={interval.title}
                    ></th>
                  );
                })}
              </tr>
            </thead>
          </table>
        </span>
      </span>
    </>
  );
}
