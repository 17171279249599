import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
  view: "table" | "tile";
  onViewChange: Function;
};

export default function PortfolioView({ view, onViewChange }: Props) {
  const VIEW_BOXES = "tile";
  const VIEW_TABLE = "table";
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent
        sx={{ pb: "16px !important", display: "flex", flexDirection: "column", gap:1 }}
      >
        <Box>
          <Typography variant="subtitle1">
            <strong> Portfolio&#39;s default view</strong>
          </Typography>
          <Typography>
            Decide how to visualize your lists of portfolios and baskets.
          </Typography>
        </Box>

        <RadioGroup
          onChange={(e) => onViewChange(e.target.value)}
          row
          value={view}
        >
          <FormControlLabel
            value={VIEW_BOXES}
            control={<Radio />}
            label="Boxes"
          />
          <FormControlLabel
            value={VIEW_TABLE}
            control={<Radio />}
            label="Table"
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
}
