import { Card, CardContent } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import DialogUpload from "../../../../../../../components/DialogUpload/DialogUpload";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { widgetsConfiguration } from "../../../../../widgets/widgetsConfiguration";
import { _listenerImportHelper } from "./utils";
import Matches from "../../../../../../../components/DialogUpload/widgets/Matches";

type Props = {
  addHoldingHandler: Function;
  setUniques: Function;
};

const ImportSection = forwardRef(
  ({ addHoldingHandler, setUniques }: Props, ref) => {
    const [matches, setMatches] = useState<any>(null);
    const environment = useEnvironment();
    const instrumentsAPI = useMemo(() => {
      return new Instruments(environment.get("setup"));
    }, [environment]);

    useImperativeHandle(
      ref,
      () => ({
        reset: () => setMatches(null),
      }),
      []
    );

    const _listenerUpload = useCallback(
      (obj) => {
        // domClass.remove(this.loader, "hide");
        let source = obj.value;
        let instruments: any = source["instruments"];
        let market: any = source["market"];
        let symbology: any = source["symbology"];

        let params: any = {
          page: {
            page: 1,
            rows: 999999,
          },
          filters: [
            {
              dimension: symbology,
              segments: [],
            },
            {
              dimension: "type",
              segments: [
                "Commodity",
                "Currency",
                "ETF",
                "Index",
                "Sector",
                "Stock",
              ],
            },
          ],
        };
        for (let i = 0, length = instruments.length; i < length; i++) {
          params["filters"][0]["segments"].push(
            instruments[i]["label"].toUpperCase()
          );
        }

        if (market != null) {
          params.filters.push({
            dimension: "country",
            segments: [market],
          });
        }

        var properties =
          widgetsConfiguration["widgets/analysis/collection/edit"][
            "properties"
          ];
        properties.push({
          date: null,
          property: symbology,
        });
        instrumentsAPI
          .filterAndFetch(params, "security", properties)
          .then((response) => {
            const _matches = _listenerImportHelper(source, response);
            setMatches(_matches);
            setUniques(_matches.unique);
          });
      },
      [instrumentsAPI, setUniques]
    );

    return (
      <Card
        sx={{ boxShadow: 3, height: "100%", maxHeight: "100%", width: "50%" }}
      >
        <CardContent
          sx={{
            overflow: "hidden",
            height: "100%",
            display: "flex",
            gap: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 0,
            position: "relative",
          }}
        >
          {matches == null && (
            <DialogUpload
              customStyle={{
                display: "inline-block",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
              supportingDescription={true}
              onImport={_listenerUpload}
            />
          )}
          {matches && (
            <Matches
              matches={matches}
              onAddFromMultiple={(e) => addHoldingHandler(e.value)}
            />
          )}
        </CardContent>
      </Card>
    );
  }
);

export default ImportSection;
