import { Box, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Instruments } from "../../../../../../api/compute/Instruments";
import Modal from "../../../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../hooks/useEventBus";
import { config } from "../../../../config-ts";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";

type DeleteScreeningDialogProps = {
  item: { id: number; name: string };
  onClose: Function;
};

export function DeleteScreeningDialog({
  item,
  onClose,
}: DeleteScreeningDialogProps) {
  const environment = useEnvironment();
  const appEnvironment = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsApi = useMemo(
    () => new Instruments(appEnvironment),
    [appEnvironment]
  );
  const { dispatch } = useEventBus();
  const { broadcast } = useBroadcast();

  const deleteTemplate = useCallback(async () => {
    const deleteTarget = item;

    if (deleteTarget && deleteTarget.id) {
      try {
        await instrumentsApi.removeScreeningTemplate(deleteTarget.id);

        const message = {
          from: "Routes",
          content: {
            type: "success",
            text: `<strong>${deleteTarget.name}</strong> successfully removed`,
          },
        };

        broadcast(config["channels"]["feedback"]["input"], message);
        dispatch("resource-deleted");
        onClose();
      } catch (error) {
        const message = {
          from: "Routes",
          content: {
            type: "error",
            text: `An error occured and <strong>${deleteTarget.name}</strong> was not removed`,
          },
        };

        broadcast(config["channels"]["feedback"]["input"], message);
      }
    }
  }, [broadcast, dispatch, instrumentsApi, item, onClose]);

  return item ? (
    <Modal
      closeIcon={false}
      onClose={onClose}
      headerConfig={{
        hasBackground: true,
        headerContent: `Delete ${item?.name ?? ""}`,
      }}
      buttonsEnalbed
      buttons={[
        { name: "Confirm", callback: deleteTemplate },
        { name: "Cancel", callback: () => onClose(), variant: "cancel" },
      ]}
    >
      <Box py={2}>
        <Typography sx={{ fontSize: "0.9vw" }}>
          Are you sure to delete <strong>{item?.name ?? ""}</strong>{" "}
          definitively?
        </Typography>
      </Box>
    </Modal>
  ) : (
    <></>
  );
}
