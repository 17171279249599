/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/SelectionUi2Api
 * @summary Converts UI selection paramters to Compute API parameters
 *
 */

import { deepClone } from "../../deepClone";

type SelectionUi2ApiProps = {
  transformations: any;
};

export class SelectionUi2Api {
  // selection widget transformations
  transformations: any;

  constructor(params: SelectionUi2ApiProps) {
    this.transformations = params?.transformations;
  }

  /**
   * Decode Compute API parameters to UI selection paramters
   *
   * @param {object[]} rules - selection rules
   * @param {string}   rules[].dimension - one of available security
   *   property (fieldsConfiguration.json)
   * @param {number}   rules[].n - if operator is 'top', it is the cardinality
   * @param {string}   rules[].operator - one of 'equals', 'range', 'top'
   * @param {boolean}  rules[].rev -  true if descending, false otherwise
   * @param {array}    rules[].segments - if operator is 'equals' or 'range'
   *   the set of relevant values
   * @param {object[]} rules[].transform - the applied function ('quantile')
   *   to the set defined by the operator
   * @param {string}   rules[].transform.function - only 'quantile' available
   * @param {object[]} rules[].transform.params - funtion parameters
   * @param {number}   rules[].transform.params.n - number of quantiles
   * @param {boolean}  rules[].transform.params.trimOutlier - always true
   * @param {number}   rules[].transform.params.withOutlierQuantile - always 0
   *
   * @returns {array} the rules in UI selection format
   */
  decode(rulesSource: any) {
    // see TODO - avoid multiple operation on values
    const rules = deepClone(rulesSource);

    const decoded: any = [];
    for (const rule of rules) {
      const _rule: any = {
        function: "value",
        functionParams: null,
        property: rule["dimension"],
      };

      /*
                    marketcap   - Market Capitalizazion
                    mc          - Smart momentum
                    pm          - Monthy performance
                    pq          - Monthy performance
                    pr          - Performance since rated
                    pt          - Performance since trend
                    px          - Retracement
                    py          - Yearly performance
                    pw          - Weekly performance
                    rc          - Rating
                    sd          - Volatility
                    tradedvalue - Liquidability
                */
      switch (rule.operator) {
        case "equals": {
          switch (rule.dimension) {
            case "rc": {
              _rule.operator = "equalToRate";

              const operatorParams = {
                A: false,
                B: false,
                C: false,
                D: false,
              };

              for (const segment of rule.segments) {
                switch (segment) {
                  case 2: {
                    operatorParams.A = true;

                    break;
                  }
                  case 1: {
                    operatorParams.B = true;

                    break;
                  }
                  case -1: {
                    operatorParams.C = true;

                    break;
                  }
                  case -2: {
                    operatorParams.D = true;

                    break;
                  }
                }
              }

              _rule.operatorParams = {
                value: operatorParams,
              };

              break;
            }
            case "marketcap": {
              //
              // 2021-06-07
              //
              // Previously it was loaded, then it was decided to
              // remove the equals from marketcap.
              // Below code to manage the conversion, but it was
              // decided to simply ignore this case, because
              // using equals in marketcap does not make sense.
              // _rule["operator"] = "rangeMarketCap";
              // _rule["operatorParams"] = {
              //     value: [this.rangeDecodeValue({
              //         "<=": rule["segments"][0],
              //         ">=": rule["segments"][0]
              //     })]
              // };

              break;
            }
            default: {
              if ("transform" in rule) {
                // quantile
                _rule.operator = "equalTo";
              } else {
                _rule.operator = "equalToPercentage";
              }
              _rule.operatorParams = {
                value: rule.segments[0],
              };
            }
          }

          break;
        }
        case "range": {
          switch (rule.dimension) {
            case "marketcap": {
              const _value = rule.segments[0];

              if (">=" in _value && "<=" in _value) {
                _rule.operator = "rangeMarketCap";
                _rule.operatorParams = {
                  value: [this.rangeDecodeValue(_value)],
                };
              } else {
                this.rangeDecodeRule(rule, _rule);
              }

              break;
            }
            case "pm":
            case "pq":
            case "pr":
            case "pt":
            case "px":
            case "py":
            case "pw":
            case "f_sps_CC_g_3":
            case "f_sps_CC_g_12":
            case "f_eps_CC_g_3":
            case "f_eps_CC_g_12": {
              this.rangeDecodeRule(rule, _rule);

              if (!("transform" in rule)) {
                // not quantile
                _rule.operator = "rangePercentage";
              }

              break;
            }
            case "sd": {
              this.rangeDecodeRule(rule, _rule);

              if (!("transform" in rule)) {
                // not quantile

                const _value = rule.segments[0];
                if (">=" in _value && "<=" in _value) {
                  _rule.operator = "rangeVolatility";
                  _rule.operatorParams = {
                    value: [this.rangeDecodeValue(_value)],
                  };
                } else {
                  this.rangeDecodeRule(rule, _rule);
                  _rule.operator = _rule.operator + "Percentage";
                }
              }

              break;
            }
            default: {
              this.rangeDecodeRule(rule, _rule);
            }
          }

          break;
        }
        case "top": {
          if (rule.rev === true) {
            _rule.operator = "top";
          } else {
            _rule.operator = "bottom";
          }

          _rule.operatorParams = {
            value: rule.n,
          };

          break;
        }
      }

      if ("transform" in rule) {
        switch (rule.transform.function) {
          case "quantile": {
            _rule.function = "quantile";
            _rule.functionParams = {
              value: rule.transform.params.n,
            };

            break;
          }
        }
      }

      decoded.push(_rule);
    }

    // value transformations
    const transformations = this.transformations;
    if (transformations != null) {
      for (const _decodedItem of decoded) {
        const _tranformation = transformations[_decodedItem.property];
        // has property transformations to be applied?
        // has function transformations to be applied?
        // has operator transformations to be applied?
        if (
          _tranformation != null &&
          _decodedItem.function in _tranformation &&
          _decodedItem.operator in _tranformation[_decodedItem.function]
        ) {
          if (Array.isArray(_decodedItem.operatorParams.value)) {
            // value (Array)
            for (let j = 0; j < _decodedItem.operatorParams.value.length; j++) {
              for (const key in _decodedItem.operatorParams.value[j]) {
                _decodedItem.operatorParams.value[j][key] =
                  _decodedItem["operatorParams"]["value"][j][key] != null
                    ? _decodedItem.operatorParams.value[j][key] /
                      _tranformation[_decodedItem.function][
                        _decodedItem.operator
                      ]
                    : null;
              }
            }
          } else {
            _decodedItem.operatorParams.value =
              _decodedItem.operatorParams.value != null
                ? _decodedItem.operatorParams.value /
                  _tranformation[_decodedItem.function][_decodedItem.operator]
                : null;
          }
        }
      }
    }

    return decoded;
  }

  /**
   *
   * Encode UI selection paramters to Compute API parameters
   *
   * @param {object[]} rules - selection rules
   * @param {string} rules[].function - one of 'threshold', 'outlier',
   *   'quantile'
   * @param {object} rules[].functionParams - function parameters
   * @param {any} rules[].functionParams.value - value of parameter
   * @param {string} rules[].property - one of available security
   *   property (fieldsConfiguration.json)
   * @param {string} rules[].sortBy -  one of 'asc', 'desc'
   *
   * @returns {array} the rules in Compute API format
   */
  encode(rulesT: any) {
    // see TODO - avoid multiple operation on values
    const rules = deepClone(rulesT);

    const encoded: any = [];
    const transformations = this.transformations;

    for (const rule of rules) {
      const _rule: any = {
        dimension: rule.property,
      };

      if (transformations != null) {
        const _tranformation = transformations[rule.property];
        // has property transformations to be applied?
        // has function transformations to be applied?
        // has operator transformations to be applied?
        if (
          _tranformation != null &&
          rule.function in _tranformation &&
          rule.operator in _tranformation[rule.function]
        ) {
          if (Array.isArray(rule.operatorParams.value)) {
            // value (Array)
            for (let j = 0; j < rule.operatorParams.value.length; j++) {
              for (let key in rule.operatorParams.value[j]) {
                rule.operatorParams.value[j][key] =
                  rule.operatorParams.value[j][key] != null
                    ? rule.operatorParams.value[j][key] *
                      _tranformation[rule.function][rule.operator]
                    : null;
              }
            }
          } else {
            // value (numeric)
            rule.operatorParams.value =
              rule.operatorParams.value != null
                ? rule.operatorParams.value *
                  _tranformation[rule.function][rule.operator]
                : null;
          }
        }
      }

      switch (rule.operator) {
        case "greaterThan":
        case "greaterThanPercentage":
        case "greaterThanOrEqualTo":
        case "greaterThanOrEqualToPercentage":
        case "lessThan":
        case "lessThanPercentage":
        case "lessThanOrEqualTo":
        case "lessThanOrEqualToPercentage":
        case "range":
        case "rangePercentage":
        case "rangeVolatility": {
          _rule.operator = "range";
          _rule.segments = [];

          const ranges = rule.operatorParams.value;

          for (let j = 0; j < ranges.length; j++) {
            const segmentSource = ranges[j];
            const segmentTarget = this.rangeEncodeValue(segmentSource);
            _rule.segments.push(segmentTarget);
          }

          break;
        }
        case "bottom": {
          _rule.n = rule.operatorParams.value;
          _rule.operator = "top";
          _rule.rev = false;

          break;
        }
        case "equalTo":
        case "equalToPercentage": {
          _rule.operator = "equals";
          _rule.segments = [];
          _rule.segments.push(rule.operatorParams.value);

          break;
        }
        case "equalToRate": {
          _rule.operator = "equals";
          _rule.segments = [];

          if (rule.operatorParams.value.A === true) {
            _rule.segments.push(2);
          }

          if (rule.operatorParams.value.B === true) {
            _rule.segments.push(1);
          }

          if (rule.operatorParams.value.C === true) {
            _rule.segments.push(-1);
          }

          if (rule.operatorParams.value.D === true) {
            _rule.segments.push(-2);
          }

          break;
        }
        case "rangeMarketCap": /* case 'rangeVolatility': */ {
          _rule.operator = "range";
          _rule.segments = [];

          const segmentSource = rule.operatorParams.value[0];
          const segmentTarget = this.rangeEncodeValue(segmentSource);

          _rule.segments.push(segmentTarget);

          break;
        }
        case "top": {
          _rule.n = rule.operatorParams.value;
          _rule.operator = "top";
          _rule.rev = true;

          break;
        }
      }

      switch (rule.function) {
        case "quantile": {
          _rule.transform = {
            function: "quantile",
            params: {
              n: rule.functionParams.value,
              trimOutlier: false,
              withOutlierQuantile: 0,
            },
          };

          break;
        }
      }

      switch (rule["property"]) {
        case "f_eps_CC_g_3":
        case "f_sps_CC_g_3": {
          _rule.computation = {
            function: "g",
            params: {
              lag: 3,
            },
          };

          break;
        }
        case "f_eps_CC_g_12":
        case "f_sps_CC_g_12": {
          _rule.computation = {
            function: "g",
            params: {
              lag: 12,
            },
          };

          break;
        }
        default: {
        }
      }

      encoded.push(_rule);
    }

    return encoded;
  }

  rangeDecodeRule(source: any, target: any) {
    /*
                    >   gt - greater than
                    <   lt - less than
                    >=  ge - greater than or equal to
                    <=  le - less than or equal to
                */
    const value = source.segments;
    // multiple ranges
    if (value.length > 1) {
      target.operator = "range";
      target.operatorParams = {
        value: [],
      };

      for (let j = 0; j < value.length; j++) {
        target.operatorParams.value.push(this.rangeDecodeValue(value[j]));
      }
    } else {
      // single range
      if (
        (">=" in value[0] || ">" in value[0]) &&
        ("<=" in value[0] || "<" in value[0])
      ) {
        target.operator = "range";
        target.operatorParams = {
          value: [this.rangeDecodeValue(value[0])],
        };
      }
      // greaterThanOrEqualTo
      else if (">=" in value[0]) {
        target.operator = "greaterThanOrEqualTo";
        target.operatorParams = {
          value: [this.rangeDecodeValue(value[0])],
        };
      }
      // greaterThan
      else if (">" in value[0]) {
        target.operator = "greaterThan";
        target.operatorParams = {
          value: [this.rangeDecodeValue(value[0])],
        };
      }
      // lessThanOrEqualTo
      else if ("<=" in value[0]) {
        target.operator = "lessThanOrEqualTo";
        target.operatorParams = {
          value: [this.rangeDecodeValue(value[0])],
        };
      }
      // lessThan
      else if ("<" in value[0]) {
        target.operator = "lessThan";
        target.operatorParams = {
          value: [this.rangeDecodeValue(value[0])],
        };
      }
    }
  }

  rangeDecodeValue(source: any) {
    const target: any = {};

    for (const key in source) {
      const keyValue = source[key] !== undefined ? source[key] : null;

      switch (key) {
        case "<": {
          target["lt"] = keyValue;

          break;
        }
        case "<=": {
          target["le"] = keyValue;

          break;
        }
        case ">": {
          target["gt"] = keyValue;

          break;
        }
        case ">=": {
          target["ge"] = keyValue;

          break;
        }
      }
    }

    return target;
  }

  rangeEncodeValue(source: any) {
    const target: any = {};

    for (const key in source) {
      /*
                    gt  >   - greater than
                    lt  <   - less than
                    ge  >=  - greater than or equal to
                    le  <=  - less than or equal to
                */
      switch (key) {
        case "ge": {
          target[">="] = source[key];

          break;
        }
        case "gt": {
          target[">"] = source[key];

          break;
        }
        case "le": {
          target["<="] = source[key];

          break;
        }
        case "lt": {
          target["<"] = source[key];

          break;
        }
      }
    }

    return target;
  }
}
