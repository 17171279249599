import { _valueGet } from "../../utils";

export type Action = {
  type:
    | "SET_FUNCTION"
    | "SET_FUNCTION_PARAMS"
    | "SET_PROPERTY"
    | "SET_OPERATOR"
    | "SET_OPERATOR_PARAMS"
    | "SET_OPTIONS_FUNCTION"
    | "SET_OPTIONS_OPERATOR"
    | "SET_SHOW_OPERATOR_SECTION"
    | "SET_SHOW_FUNCTION_SECTION"
    | "SET_SHOW_VALUE_SECTION"
    | "SET_EDITING"
    | "SET_INDEX"
    | "SET_RANGE_QUANTILE"
    | "ON_CLICK_OPERATOR_OPTION"
    | "ON_CLICK_PROPERTY_OPTION"
    | "changeOperativeInput"
    | "SET_errorAtInputValue";

  payload: any;
};

export type rStateType = {
  editing: boolean;
  field: {
    function: string | null;
    functionParams: any;
    property: string | null;
    operator: string | null;
    operatorParams: any;
  };
  index: number;
  showRangeQuantile: boolean;
  //   showFunctionSection: boolean;
  showOperatorSection: boolean;
  showValueSection: boolean;
  optionsOperator?: any;
  optionsFunction?: any;
  errorAtInputValue?: boolean;
};

export function rStateReducer(draft: rStateType, action: Action) {
  switch (action.type) {
    case "SET_FUNCTION":
      draft.field.function = action.payload;
      break;
    case "SET_FUNCTION_PARAMS":
      if (JSON.stringify(draft.field.functionParams) !== JSON.stringify(action.payload)) {
        draft.field.functionParams = action.payload;
      }
      break;
    case "SET_PROPERTY":
      draft.field.property = action.payload;
      break;
    case "SET_OPERATOR":
      draft.field.operator = action.payload;
      break;
    case "SET_OPERATOR_PARAMS":
      if (JSON.stringify(draft.field.operatorParams) !== JSON.stringify(action.payload)) {
        draft.field.operatorParams = action.payload;
      }
      break;
    case "SET_OPTIONS_FUNCTION":
      draft.optionsFunction = action.payload;
      break;
    case "SET_OPTIONS_OPERATOR":
      draft.optionsOperator = action.payload;
      break;
    case "SET_SHOW_OPERATOR_SECTION":
      draft.showOperatorSection = action.payload;
      break;
    case "SET_SHOW_VALUE_SECTION":
      draft.showValueSection = action.payload;
      break;
    case "SET_EDITING":
      draft.editing = action.payload;
      break;
    case "SET_INDEX":
      draft.index = action.payload;
      break;
    case "SET_RANGE_QUANTILE":
      draft.showRangeQuantile = action.payload;
      break;
    case "changeOperativeInput":
      draft = action.payload;
      return draft;
    case "ON_CLICK_OPERATOR_OPTION":
      draft.editing = false;
      let temp = _valueGet(
        draft,
        draft.field.property,
        action.payload.item.item.functionID,
        action.payload.item.item.value,
        action.payload.options._optionsIndex,
        action.payload.options._options
      );
      const { field, index } = temp;
      const { function: functionP, functionParams, operator, operatorParams } = field;
      draft.field.function = functionP;
      draft.field.functionParams = functionParams;
      draft.field.operator = operator;
      draft.field.operatorParams = operatorParams;
      draft.index = index;
      draft.errorAtInputValue = false;

      switch (functionP) {
        case "quartile":
        case "quintile":
        case "decile":
          draft.showRangeQuantile = true;
          draft.showValueSection = false;

          break;
        case "value":
          draft.showRangeQuantile = false;
          draft.showValueSection = true;
          break;
      }
      break;
    case "SET_errorAtInputValue":
      draft.errorAtInputValue = action.payload;
      break;
    case "ON_CLICK_PROPERTY_OPTION":
      draft.editing = false;
      let _temp = _valueGet(
        draft,
        action.payload.prop.value,
        null,
        null,
        action.payload.options._optionsIndex,
        action.payload.options._options
      );
      const {
        field: p_field,
        index: p_index,
        optionsFunction: p_optionsFunction,
        optionsOperator: p_optionsOperator,
      } = _temp;
      const {
        function: p_functionP,
        functionParams: p_functionParams,
        operator: p_operator,
        operatorParams: p_operatorParams,
        property: p_property,
      } = p_field;
      //   //?? why dispatching conditionally??
      //   //!! for the rules that have only 1 operator,
      //   //!! WizzOperator is not shown. so the
      //   //!! properties need to be seted here
      draft.field.function = p_optionsOperator.length <= 1 ? p_functionP : null;
      draft.field.functionParams = p_optionsOperator.length <= 1 ? p_functionParams : null;
      draft.field.property = p_property;
      draft.field.operator = p_optionsOperator.length <= 1 ? p_operator : null;
      draft.field.operatorParams = p_optionsOperator.length <= 1 ? p_operatorParams : null;
      draft.index = p_index;
      draft.optionsFunction = p_optionsFunction;
      draft.optionsOperator = p_optionsOperator;
      draft.showOperatorSection = p_optionsOperator.length <= 1 ? false : true;
      draft.showValueSection =
        p_optionsOperator.length <= 1 && p_optionsFunction.length <= 1 ? true : false;
      draft.showRangeQuantile = false;
      break;
    default:
      return draft;
  }
}
