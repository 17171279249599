import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { deepClone } from "../../../../../../../../../../deepClone";

type ReportDaysType = {
  days: any;
  setDays: Function;
};
const dayMap = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
};
export const ReportDays = ({ days, setDays }: ReportDaysType) => {
  const [dayState, setDayState] = useState(days ?? null);


  useEffect(() => {
    setDayState(days);
  }, [days]);

  const daysHandler = (e) => {
    let temp = deepClone(dayState);
    temp[e.target.name] = e.target.checked;

    if (!Object.values(temp).includes(false)) {
      setDaily(true);
    }
    setDays(temp);
    setDayState(temp);
  };



  const [daily, setDaily] = useState(() => {
    let arrdays = Object.values(days);
    for (let i = 0; i < arrdays.length; i++) {
      if (arrdays[i] === false) {
        return false;
      }
    }
    return true;
  });

  useEffect(() => {
    if (!Object.values(days).includes(false) ) {
      setDaily(true);
    }else{
      setDaily(false);
    }
  }, [days]);

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Typography>
          Set the days in which you want to get the report on markets and
          portfolios directly in your inbox
        </Typography>
        {dayState != null && (
          <Box display={"flex"} flexDirection={"column"}>
   

            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDaily(e.target.checked);
                    if (e.target.checked === true) {
                      let temp = deepClone(dayState);
                      Object.keys(dayMap).forEach((day) => {
                        temp[day] = true;
                      });
                      setDays(temp);
                      setDayState(temp);
                    }else{
                      let temp = deepClone(dayState);
                      Object.keys(dayMap).forEach((day) => {
                        temp[day] = false;
                      });
                      setDays(temp);
                      setDayState(temp);
                    }
                  }}
                  size="small"
                  checked={daily}
                />
              }
              label={"Daily"}
            />

            <FormControl
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={daysHandler}
            >
              {Object.keys(dayState).map((day) => (
                <FormControlLabel
                  key={day}
                  control={<Checkbox size="small" checked={dayState[day]} />}
                  label={dayMap[day]}
                  name={day}
                />
              ))}
            </FormControl>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
