/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/StrategyFormatter
 * @summary Trendrating strategies formatter. Format data suitable for HTML rendering,
 * text export (CSV, Excel) and PDF generation (ReportLab - www.reportlab.com)
 *
 * AppFormatterStrategy is a dependency that needs to be refactored / moved on another
 * package to remove the depencencies on window.App.
 *
 */

import { AppEnvironment } from "../../types/Defaults";
import { Strategy as AppFormatterStrategy } from "../app/formatter/Strategy";

export class StrategyFormatter {
    format: any;
    formatType: any;

    /**
     * @param {object} params - parameters
     */
    constructor(params: any) {
        if (params === undefined || params == null) {
            throw new Error("Missing parameters");
        }
        if (!("environment" in params) || params["environment"] == null) {
            throw new Error("Missing environment");
        }
        if ("formatType" in params && params["formatType"] != null) {
            this.formatType = params["formatType"];
        } else {
            this.formatType = "HTML";
        }

        this._prepareFormatter(params["environment"]);
    }

    getFormatter() {
        return this.format;
    }

    _prepareFormatter(environment: AppEnvironment) {
        var configurationBuilder =
            environment["configuration"].get("strategyBuilder");
        var taxonomyStock = environment["taxonomies"]["StockClassification"];
        var taxonomyWhere: any = [];
        const taxonomiesRaw = environment.taxonomies;
        const txFields = environment["taxonomyFields"];
        const txWhere = taxonomiesRaw[txFields["security"]["country"]];
        for (var key in txWhere) {
            taxonomyWhere.push(txWhere[key]);
        }
        var taxonomyWhatEtf = Object.values(
            taxonomiesRaw[txFields["ETF"]["etfclass"]]
        );
        var taxonomyWhatStock = Object.values(
            taxonomiesRaw[txFields["security"]["sector"]]
        );

        var taxonomies = {
            taxonomyStock: taxonomyStock,
            taxonomyWhatEtf: taxonomyWhatEtf,
            taxonomyWhatStock: taxonomyWhatStock,
            taxonomyWhere: taxonomyWhere,
        };

        this.format = new AppFormatterStrategy(environment, {
            formatType: this.formatType,
            optionsRanking: configurationBuilder.ranking.edit.options,
            optionsSelection: configurationBuilder.selection.edit.options,
            optionsSmartBeta:
                configurationBuilder.formAdvanced.widgets.smartBeta.options,
            product: environment["account"]["product"],
            properties: environment["properties"],
            taxonomies: taxonomies,
        });
    }
}
