/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/preferences
 * @summary Singleton that manages user preferences
 *
 */

import { defaultPreferences } from "../../api/account/defaultPreferences";
import { Preferences } from "../../api/account/Preferences";

export class AppPreferences {
  http: any;

  constructor() {
    this.http = null;
  }

  // check if the key exists and return it
  // if key does not exists, it checks if a default value is provided
  // otherwise returns null
  //
  // IMPORTANT: this method could act as a preference migrator entry point
  get(key) {
    if (key == null) {
      // Return the complete preferences
      var preferencesObject = this._preferences();
      return preferencesObject["preferences"];
    }
    switch (key) {
      case "alerts":
      case "analysisList":
      case "emailDigest":
      case "favorites":
      case "indexes":
      case "report":
      case "screening":
      default: {
        return this._get(key);
      }
    }
  }

  save(preferences) {
    var preferencesObject = {
      preferences: preferences,
    };
    return this.http["accountPreferences"].save(preferencesObject, null);
  }

  set(property, value) {
    switch (property) {
      case "environment": {
        this.http = {
          accountPreferences: new Preferences(value),
        };

        break;
      }
      default: {
        this[property] = value;
      }
    }
  }
  // ----------------------------------------------------- private methods
  _get(key) {
    var preferencesObject = this._preferences();
    var preferences = preferencesObject["preferences"];

    if (key in preferences) {
      return preferences[key];
    }

    if (key in defaultPreferences) {
      return defaultPreferences[key];
    }

    return null;
  }

  _preferences() {
    var preferences =
      window.App.user.preferences != null
        ? window.App.user.preferences
        : {
            preferences: defaultPreferences,
            id: null,
            name: this.http["accountPreferences"]["NAME"],
            version: this.http["accountPreferences"]["VERSION"],
          };

    // 2020-04-30 merging new sections
    if (preferences["preferences"]["alerts"] == null) {
      // Add default alerts section if missing
      preferences["preferences"]["alerts"] = defaultPreferences["alerts"];
    }
    if (!("pinned" in preferences["preferences"]["alerts"])) {
      preferences["preferences"]["alerts"]["pinned"] =
        defaultPreferences["alerts"]["pinned"];
    }
    // 2020-06-05 merging new sections
    if (preferences["preferences"]["screening"] == null) {
      // Add default screening section if missing
      preferences["preferences"]["screening"] = defaultPreferences["screening"];
    }
    if (!("markets" in preferences["preferences"]["screening"])) {
      preferences["preferences"]["screening"]["markets"] =
        defaultPreferences["screening"]["markets"];
    }

    return preferences;
  }
}
