/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/input/CollectLists
 * @summary Retrieves data to build report
 *
 */

import { AppEnvironment } from "../../../../types/Defaults";
import { WysiwygState } from "../Types";

export class CollectAlerts {
  constructor(
    protected readonly environment: AppEnvironment,
    protected readonly wysiwygState: WysiwygState
  ) {}

  async retrieve() {
    const wysiwygState = this.wysiwygState;
    // Check type so later the target is of correct type
    if (wysiwygState.targetType !== "ALERTS") {
      throw new Error("Invalid target type, must be ALERTS");
    }

    const computing: {
      alerts: {
        id: number;
        name: string;
        upgrades: number;
        downgrades: number;
        moversUp: number;
        moversDownNumber;
      }[];
    } = {
      alerts: [],
    };
    const response = {};
    const responseCompute = {};

    return {
      computing,
      response,
      responseCompute,
    };
  }
}
