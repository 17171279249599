import { useState } from "react";
import { EtfWidget } from "./EtfWidget";
import { IndicesWidget } from "./IndicesWidget";
import { MarketWidget } from "./MarketWidget";
import { StocksWidget } from "./StocksWidget";

type NewsProps = {
  data: {
    markets: any;
    sectors: any;
    indices: any;
    etfs: any;
    stocks: any;
  };
  timeframe: string;
  stocksProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
  etfsProps: {
    stateChangeHandler: (filters: any) => void;
    saveHandler: (filters: any) => void;
  };
};

export function News({ data, timeframe, stocksProps, etfsProps }: NewsProps) {
  const { markets, sectors, indices, etfs, stocks } = data;
  const indicesData = {
    indexTableData: indices,
    sectorsTableData: sectors,
  };
  const { saveHandler, stateChangeHandler } = stocksProps;
  const [showRowContent, setShowRowContent] = useState(true);
  const [showRowContent2, setShowRowContent2] = useState(true);

  const handleRowAccordion = () => setShowRowContent(!showRowContent);
  const handleRow2Accordion = () => setShowRowContent2(!showRowContent2);

  return (
    <div className="tNews--1">
      <div className="tNews-row__item">
        <div className="tNews-item">
          <MarketWidget
            data={markets}
            timeframe={timeframe}
            accordionHandler={handleRowAccordion}
            accordionState={showRowContent}
          />
        </div>
        <div className="tNews-item">
          <IndicesWidget
            data={indicesData}
            accordionHandler={handleRowAccordion}
            accordionState={showRowContent}
          />
        </div>
      </div>
      <div className="tNews-row__item">
        <StocksWidget
          data={stocks}
          stocksProps={{
            stateChangeHandler,
            saveHandler,
          }}
          accordionHandler={handleRow2Accordion}
          accordionState={showRowContent2}
        />

        <EtfWidget
          data={etfs}
          etfProps={etfsProps}
          accordionHandler={handleRow2Accordion}
          accordionState={showRowContent2}
        />
      </div>
    </div>
  );
}
