/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/utils
 * @summary Utilities
 *
 */

import { deepClone } from "../../deepClone";
import { TDate } from "../../trendrating/date/TDate";
import { config } from "./config-ts";

const CONSTRAINTS = {
  minEmailLength: 6,
  minPasswordLength: 2,
};

export function arePasswordsEqual(
  password: string,
  passwordCheck: string
): boolean {
  return password === passwordCheck;
}

export function isValidEmail(email: string): boolean {
  if (email.length >= CONSTRAINTS.minEmailLength) {
    return true;
  }

  return false;
}

export function isValidPassword(password: string): boolean {
  if (password.length >= CONSTRAINTS.minPasswordLength) {
    return true;
  }

  return false;
}

export function messageSuccess(text: any, response?: any, pinned = false) {
  var message = {
    content: {
      pinned: pinned,
      text: text,
      type: "success",
    },
    from: response,
  };

  if (response) {
    console.log(response);
  }

  const channel = config["channels"]["feedback"]["input"];

  return [channel, message];
}

export function messageError(text: any, error?: any, pinned = false) {
  var message = {
    content: {
      pinned: pinned,
      text: text,
      type: "error",
    },
    from: error,
  };

  if (error) {
    console.error(error);
  }

  const channel = config["channels"]["feedback"]["input"];

  return [channel, message];
}
export function messageWarning(text: any, error?: any, pinned = false) {
  var message = {
    content: {
      pinned: pinned,
      text: text,
      type: "warning",
    },
    from: error,
  };

  if (error) {
    console.warn(error);
  }

  const channel = config["channels"]["feedback"]["input"];

  return [channel, message];
}

/**
 *  Suitable to prepare data for bar widget: it uses quartiles
 *  to define cutting threshold
 */
export function normalizeAndCutOutliers(arrayOfObject: any, property: any) {
  if (arrayOfObject.length) {
    var rawData = deepClone(arrayOfObject);
    var LENGTH = rawData.length;
    rawData = rawData.sort(function (a: any, b: any) {
      var aAbs = a ? Math.log(Math.abs(a[property])) : a;
      var bAbs = b ? Math.log(Math.abs(b[property])) : b;

      if (aAbs > bAbs) {
        return -1;
      }
      if (aAbs < bAbs) {
        return 1;
      }

      return 0;
    });

    var maxIndex = Math.min(LENGTH - 1, Math.floor((3 / 4) * (LENGTH + 1)));
    var minIndex = Math.floor((1 / 4) * (LENGTH + 1));
    var max = rawData[maxIndex][property];
    var min = rawData[minIndex][property];
    var IQR = max - min;
    var threshold = Math.abs(IQR * 1.5);

    for (let i = 0, length = arrayOfObject.length; i < length; i++) {
      arrayOfObject[i]["_s_normalizationThreshold"] = threshold;
    }
  }

  return arrayOfObject;
}

// another implementation is in app/ui/chart/_SerieBase
// todo merge
export function scaleXY(
  V: number,
  minL: number,
  maxL: number,
  minX: number,
  maxX: number
) {
  return Math.round(minX + ((maxX - minX) * (V - minL)) / (maxL - minL));
}

// Hide the loader node from the pages when they are instantiated by the router
export function removeLoader() {
  document.getElementById("data-loader")?.classList.add("hide");
}

export class ChartSerie {
  /**
   * Rescale the serie using point as the start
   * @param {array} serie
   * @param {object} point - the starting point for resclale
   * @param {number} point.x - x coordinate
   * @param {number} point.y - y coordinate
   *
   * @returns {array} rescaled serie
   */
  rescale(serie, point) {
    var y = 0;
    for (let i = 0, length = serie.length; i < length; i++) {
      if (serie[i][0] === point.x) {
        y = serie[i][1];
        break;
      }
    }

    if (y === 0) {
      return serie;
    }

    var _serie: any = [];
    var rescaleFactor = point.y / y;
    for (let i = 0, length = serie.length; i < length; i++) {
      var n = serie[i][1] * rescaleFactor;
      _serie.push([serie[i][0], n]);
    }

    return _serie;
  }

  /**
   *
   * @param {array} data - the data to be converted
   * @param {number} cutoffDays - the number of days to be prepared
   */
  trendrating2HighchartSerie(data, cutoffDays?) {
    cutoffDays =
      cutoffDays === undefined || cutoffDays == null ? data.length : cutoffDays;
    //var cutoffIndex = Math.max(data.length - 1 - cutoffDays, 0);
    let cutoffIndex = Math.max(data.length - cutoffDays, 0);
    let tDate = TDate;

    let item: any = null;
    let serie: any = [];
    let serieItem: any = null;
    for (let i = data.length - 1; i >= cutoffIndex; i--) {
      item = data[i];

      serieItem = [
        parseFloat(tDate.daysToMilliseconds(item["d"]) as any),
        parseFloat(item["v"]),
      ];

      serie.push(serieItem);
    }

    serie.sort(function (a, b) {
      if (a[0] > b[0]) {
        return 1;
      }
      if (a[0] < b[0]) {
        return -1;
      }
      return 0;
    });

    return serie;
  }
}

/**
 *
 * @param num1 float
 * @param num2 float
 * @param precision
 *
 * @returns {boolean}
 *
 * This method is needed to compare 2 float in javascript because Javasctipt is not precise to evaluate the equality of
 * 2 float numbers
 */
export function areFloatsEqual(num1, num2, precision?) {
  if (precision == null) {
    return Math.abs(num1 - num2) <= 0.00001; // default precision
  }
  return Math.abs(num1 - num2) <= precision;
}
