import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks/useAxios";
import { SystemProvider } from "../../hooks/useSystem";
import "../../styles/index.scss";
import { AppCore } from "./AppCore";

export function App() {
    const navigate = useNavigate();

    useEffect(() => {
        (window as any).__page_navigate = (dest) => navigate("/app" + dest);
    }, [navigate]);

    useAxios();

    return (
        <SystemProvider>
            <AppCore />
        </SystemProvider>
    );
}
