import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { RowComponent } from "tabulator-tables";
import { TableHelpers } from "../../../../../../../../../../components/InstrumentsTable/Helpers/TableHelpers";
import { TrendratingTable } from "../../../../../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";

type SecuritiesTableProps = {
  data: any;
  selectAllRows: boolean;
  activateAddTo: Function;
  type: "ETF" | "Stock";
  onSortChange: (value: { field: string; rev: boolean }) => void;
  currentSort: { field: string; rev: boolean };
};

export const SecuritiesTable = memo(
  forwardRef(
    (
      {
        data,
        selectAllRows,
        activateAddTo,
        type,
        onSortChange,
        currentSort,
      }: SecuritiesTableProps,
      ref
    ) => {
      const tableData = useMemo(() => data?.data ?? null, [data]);

      const tableInstanceRef = useRef<any>(null);

      useEffect(() => {
        if (tableInstanceRef.current) {
          const tabulator = tableInstanceRef.current.getTabulatorRef();
          const rows = tabulator.getRows();

          for (const row of rows) {
            if (selectAllRows) {
              row.select();
            } else {
              row.deselect();
            }
          }
        }
      }, [selectAllRows]);

      const eventHandler = useCallback(
        (e) => {
          if (e.type === "rowSelected" || e.type === "rowDeselected") {
            const row = e.value as RowComponent;
            const table = row?.getTable();

            if (table) {
              const selectedRows = table.getSelectedRows();
              if (selectedRows.length) {
                activateAddTo(true);
              } else {
                activateAddTo(false);
              }
            }
          }
        },
        [activateAddTo]
      );

      useImperativeHandle(ref, () => ({
        getSelectedRows: () => {
          const tabulator = tableInstanceRef.current.getTabulatorRef();

          return tabulator.getSelectedData();
        },
      }));

      const environment = useEnvironment();
      const setup = useMemo(() => {
        return environment.get("setup");
      }, [environment]);
      const tableHelper = useMemo(() => new TableHelpers(setup), [setup]);

      const onSort: any = useCallback(
        (evt) => {
          const sorter = evt.value;

          const sortObj = {
            field: sorter.field,
            rev: sorter.direction === "desc",
          };

          onSortChange(sortObj);
        },
        [onSortChange]
      );

      const columns: any = useMemo(() => {
        const commonCols = tableHelper.generateFromFields(
          [
            "ticker",
            "name",
            "vc",
            "rc",
            "dr",
            type === "ETF" ? "etfclass" : "icb",
            "pr",
          ],
          onSort
        );

        const checkboxCol = {
          title: "",
          formatter: "rowSelection",
          widthGrow: 0.5,
          hozAlign: "center",
          headerHozAlign: "center",
          headerSort: false,
        };

        commonCols.unshift(checkboxCol);

        return commonCols;
      }, [onSort, tableHelper, type]);

      const table = useMemo(
        () => (
          <TrendratingTable
            ref={tableInstanceRef}
            data={tableData}
            autoResize={true}
            columns={columns}
            sorting={{
              field: currentSort.field,
              direction: currentSort.rev ? "asc" : "desc",
            }}
            tooltip={{
              actions: {
                info: { enabled: true },
              },
            }}
            correction={50}
            eventCallback={eventHandler}
            options={{ ajaxSorting: false, height: "1000px" }}
          />
        ),
        [columns, currentSort.field, currentSort.rev, eventHandler, tableData]
      );

      return tableData ? (
        <div
          style={{
            minHeight: 0,
            minWidth: 0,
            padding: "5px",
          }}
        >
          {table}
        </div>
      ) : (
        <p>No data to display</p>
      );
    }
  )
);
