/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/_TemplateReport
 * @summary Requests for report templates
 *
 */

import { deepClone } from "../../deepClone";
import { _StoredObjects } from "../_StoredObjects";

export class _TemplateReport extends _StoredObjects {
  /**
   * Decode server params in UI params
   */
  decode(object: any) {
    const decoded = this.migrate(deepClone(object));
    for (let i = 0; i < decoded.configuration.sections.length; i++) {
      decoded.configuration.sections[i].isEdited = false;
    }
    return decoded;
  }

  /**
   * Encode UI params in server params
   */
  encode(object: any) {
    let encoded = deepClone(object);
    // remove useless properties
    for (let i = 0; i < encoded.configuration.sections.length; i++) {
      delete encoded.configuration.sections[i].id;
      if ("isEdited" in encoded.configuration.sections[i]) {
        delete encoded.configuration.sections[i].isEdited;
      }
      if (encoded.isEditable == null) {
        encoded.isEditable = true; // Default setting is true
      }
    }
    if (encoded.action != null) {
      delete encoded.action;
    }
    if (encoded.index != null) {
      delete encoded.index;
    }
    return encoded;
  }

  async get(id?: any, userId?: any) {
    const response = await super.get(id, undefined, userId);
    if (Array.isArray(response) === true) {
      let templates: any = [];
      for (let i = 0; i < response.length; i++) {
        templates.push(this.decode(response[i]));
      }
      return templates;
    } else {
      return this.decode(response);
    }
  }

  /**
   * Return a normalized template. Defaults as editable (isEditable is true)
   *
   * @param {object} params
   * @param {object} params.configuration
   * @param {object} params.configuration.integration
   * @param {object} params.configuration.integration.portfolioAnalysis
   * @param {object} params.configuration.integration.screening
   * @param {object} params.configuration.integration.strategy
   * @param {object} params.configuration.integration.systematicProduct
   * @param {object} params.configuration.orientation
   * @param {object} params.configuration.sections
   * @param {object} params.configuration.theme
   * @param {object} params.id - id to use in template
   * @param {object} params.isEditable - id to use in template
   * @param {object} params.name - name to use in template
   */
  template(params: any) {
    let template = {
      configuration: {
        integration: {
          peer: null,
          portfolioAnalysis: null,
          screening: null,
          strategy: null,
          systematicProduct: null,
        },
        orientation: null,
        headerConfig: {
          logo: "small",
          date: true,
        },
        sections: [],
        theme: null,
      },
      id: params.id,
      isEditable: params.isEditable != null ? params.isEditable : true,
      name: params.name,
      ownerId: this.environment.account.user?.id,
      type: this.storedObjectType,
      version: "1.0",
    };

    if (params.configuration.headerConfig != null) {
      template.configuration.headerConfig = params.configuration.headerConfig;
    }

    if (params.configuration != null) {
      if (params.configuration.integration != null) {
        if (params.configuration.integration.peer != null) {
          template.configuration.integration.peer =
            params.configuration.integration.peer;
        }
        if (params.configuration.integration.portfolioAnalysis != null) {
          template.configuration.integration.portfolioAnalysis =
            params.configuration.integration.portfolioAnalysis;
        }
        if (params.configuration.integration.screening != null) {
          template.configuration.integration.screening =
            params.configuration.integration.screening;
        }
        if (params.configuration.integration.strategy != null) {
          template.configuration.integration.strategy =
            params.configuration.integration.strategy;
        }
        if (params.configuration.integration.systematicProduct != null) {
          template.configuration.integration.systematicProduct =
            params.configuration.integration.systematicProduct;
        }
      }
      if (params.configuration.orientation != null) {
        template.configuration.orientation = params.configuration.orientation;
      }
      if (params.configuration.sections != null) {
        template.configuration.sections = params.configuration.sections;
      }
      if (params.configuration.theme != null) {
        template.configuration.theme = params.configuration.theme;
      }
    }
    return template;
  }

  migrate(object: any) {
    // Use when extending templates

    for (let i = 0; i < object.configuration.sections.length; i++) {
      const section = object.configuration.sections[i];
      switch (section.type) {
        case "REPORT_STRATEGY_YEARLY_ANALYTICS":
          // 2022-01-20 Annualized field added
          if (section.content.annualized == null) {
            section.content.annualized = {
              content: "Annualized",
              isEnabled: true,
            };
          }
          break;

        // no default
      }
    }
    return object;
  }

  /**
   * Saves report template for portfolio
   *
   * @param {object} params - request parameters
   * @param {object} params.configuration - a report configuration
   * @param {number} params.id - if specified it means that the template
   *       already exits and has been modified. Otherwise a new template
   *       will be crated
   * @param {string} params.name - name of the template
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async save(object: any) {
    const encoded = this.encode(object);

    let response;
    if (object.id == null) {
      response = await this.create(encoded);
    } else {
      response = await this.update(encoded);
    }

    return this.decode(response);
  }
}
