import React, { useEffect, useState } from "react";
import ReactListItem from "./ReactListItem";

import style from "../PinnedDialog.module.scss";

type PinnedItem = { name: string; value: Object };
type ReactListProps = {
  data: PinnedItem[];
  updatePinnedContentState: Function;
  onSavePreferences: Function;
  onChangeList: Function;
};

export default function ReactList({
  data,
  updatePinnedContentState,
  onSavePreferences,
  onChangeList,
}: ReactListProps) {
  const [arr, setArr] = useState(data);

  const moveItemDown = (indexOfItem) => {
    const item = arr[indexOfItem];
    const index = indexOfItem;
    if (arr[index + 1]) {
      let arrTemp = [...arr];
      arrTemp.splice(index, 1);
      arrTemp.splice(index + 1, 0, item);
      updatePinnedContentState(arrTemp);
      const arrToSave = arrTemp.map((item) => item.value);
      onSavePreferences(arrToSave);
    }

    onChangeList(true);
  };

  const moveItemUp = (indexOfItem: number) => {
    const item = arr[indexOfItem];
    const index = indexOfItem;
    if (arr[index - 1]) {
      let arrTemp = [...arr];
      arrTemp.splice(index, 1);
      arrTemp.splice(index - 1, 0, item);
      updatePinnedContentState(arrTemp);

      const arrToSave = arrTemp.map((item) => item.value);
      onSavePreferences(arrToSave);
    }

    onChangeList(true);
  };

  const removeItem = (indexOfItem) => {
    const index = indexOfItem;
    let arrTemp = [...arr];
    arrTemp.splice(index, 1);
    updatePinnedContentState(arrTemp);

    onChangeList(true);
    const arrToSave = arrTemp.map((item) => item.value);
    onSavePreferences(arrToSave);
  };

  const focusNode = (ref: any) => {
    var arrElements = document.getElementsByClassName(ref.class);
    var reference = ref.reference.current;

    reference.classList.add("testFocus");
    for (let i = 0; i < arrElements.length; i++) {
      if (arrElements[i] !== reference) {
        arrElements[i].classList.remove("testFocus");
      }
    }

    window.addEventListener("click", (e) => {
      var clickedElement = e.target as HTMLElement;
      clickedElement.classList.forEach((e) => {
        var arr = e.split("-");
        const string = "^tFormListItem";
        const regexp = new RegExp(string);
        if (!regexp.test(arr[0])) {
          for (let i = 0; i < arrElements.length; i++) {
            if (arrElements[i].classList.contains("testFocus")) {
              arrElements[i].classList.remove("testFocus");
            }
          }
        }
      });
    });
  };

  useEffect(() => {
    setArr(data);
  }, [data]);

  return (
    <div className={style.tFormList}>
      <div className="tFormList-header">
        <div className="tFormList-headerRow">
          <div className="tFormList-headerCell tFormList-headerCell--name">
            Name
          </div>
        </div>
      </div>
      <div className={style.tFormListItems} data-dojo-attach-point="nodeItems">
        {arr?.length <= 0 && (
          <div className="tFormList-message">No items defined</div>
        )}
        {arr?.map((item, index) => (
          <ReactListItem
            click={focusNode}
            moveDown={moveItemDown}
            moveUp={moveItemUp}
            remove={removeItem}
            key={index}
            index={index}
            name={item.name}
          ></ReactListItem>
        ))}
      </div>
    </div>
  );
}
