import { Box } from "@mui/material";
import { ReactElement, useMemo } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";
import { UI_CONSTRAINTS_TYPE } from "../utils";

type Props = {
  value: any;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function WeightingRules({ value, UI_CONSTRAINTS }: Props) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const schema = useMemo(
    () => strategyFormat.weightingSchema(value.weightingSchema),
    [strategyFormat, value.weightingSchema]
  );

  const existingPositions = useMemo(
    () =>
      strategyFormat.weightingSchemaExistingPositions(
        value.weightingSchemaExistingPositions
      ),
    [strategyFormat, value.weightingSchemaExistingPositions]
  );

  const rotation = useMemo(() => {
    if (value?.rotation) {
      return strategyFormat.rotation(value.rotation);
    }
    return null;
  }, [strategyFormat, value.rotation]);

  const smartBeta = useMemo(() => {
    if (value?.smartBeta) {
      let rows: any[] = [];
      const formated = strategyFormat.smartBeta(value.smartBeta);
      formated.forEach((element, key) => {
        rows.push(
          <tr key={key}>
            <td dangerouslySetInnerHTML={{ __html: element[0] }}></td>
            <td dangerouslySetInnerHTML={{ __html: element[1] }}></td>
            <td>{formatSmartBeta(element[2])}</td>
          </tr>
        );
      });
      return rows;
    }
    return null;
  }, [strategyFormat, value.smartBeta]);


  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0, width: "100%" }}>
        <tbody>
          <tr>
            <td>Schema</td>
            <td dangerouslySetInnerHTML={{ __html: schema }}></td>
          </tr>
          <tr>
            <td>Existing positions</td>
            <td dangerouslySetInnerHTML={{ __html: existingPositions }}></td>
          </tr>

          {rotation  && UI_CONSTRAINTS.weightingRules.rotationRules.enabled ===true && (
            <tr>
              <td>Rotation rules</td>
              <td dangerouslySetInnerHTML={{ __html: rotation }}></td>
            </tr>
          )}

          {smartBeta && (
            <tr>
              <td>Smart beta</td>
              <td>
                <table>
                  <tbody>
                    {smartBeta.map((item: ReactElement) => item)}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
}

const formatSmartBeta = (value) => {
  let v = value;
  if (typeof value === "object") {
    v = (
      <div>
        <strong className="rate rate--A">A</strong>
        <span dangerouslySetInnerHTML={{ __html: value["A"] }}></span> <br />
        <strong className="rate rate--B">B</strong>
        <span dangerouslySetInnerHTML={{ __html: value["B"] }}></span>
        <br />
        <strong className="rate rate--C">C</strong>
        <span dangerouslySetInnerHTML={{ __html: value["C"] }}></span>
        <br />
        <strong className="rate rate--D">D</strong>
        <span dangerouslySetInnerHTML={{ __html: value["D"] }}></span>
      </div>
    );
  } else {
    v = <span dangerouslySetInnerHTML={{ __html: v }}></span>;
  }
  return v;
};
