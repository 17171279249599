import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { deepClone } from "../../../../../../../deepClone";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Select as MuiSelect,
} from "@mui/material";
import Select from "../commonWidgets/Select";
import { v4 as uuidv4 } from "uuid";

type PdChildrenTCRProps = {
  configObj: any;
};

const directionOptions = [
  {
    label: "Descending",
    value: "desc",
  },
  {
    label: "Ascending",
    value: "asc",
  },
];

const selectOptions = [
  {
    label: "Area / Region / Market",
    value: "where",
  },
  {
    label: "Sector / Industry",
    value: "what",
  },
];

const segmentOptions = [
  {
    value: "1 Industry",
    label: "Sector",
  },
  {
    value: "3 Sector",
    label: "Industry",
  },
  {
    value: "Country",
    label: "Country",
  },
  {
    value: "Region",
    label: "Region",
  },
  {
    value: "Area",
    label: "Area",
  },
  {
    value: "3 Level",
    label: "Size",
  },
];

const timeframeOptions = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "lastWeek",
    label: "Last Week",
  },
  {
    value: "lastMonth",
    label: "Last Month",
  },
];

const SORT_OPTIONS_DICT = {
  REPORT_BASKET_DISPERSION_BY_CHART: [
    { value: "_s_label", label: "Name" },
    { value: "top", label: "Top" },
    { value: "middle", label: "Mid" },
    { value: "bottom", label: "Bottom" },
  ],
  REPORT_BASKET_DISPERSION_BY_SECTORS: [
    { value: "_s_label", label: "Name" },
    { value: "top", label: "Top" },
    { value: "middle", label: "Mid" },
    { value: "bottom", label: "Bottom" },
  ],
  REPORT_DISPERSION_BY_SECTORS: [
    { value: "_s_label", label: "Name" },
    { value: "top", label: "Top" },
    { value: "middle", label: "Mid" },
    { value: "bottom", label: "Bottom" },
  ],
  REPORT_DISPERSION_BY_CHART: [
    { value: "_s_label", label: "Name" },
    { value: "top", label: "Top" },
    { value: "middle", label: "Mid" },
    { value: "bottom", label: "Bottom" },
  ],
  REPORT_DISPERSION_TCR_TABLE: [
    { value: "name", label: "Name" },
    { value: "TCR", label: "TCR" },
    { label: "Stocks Number", value: "cardinality" },
    { label: "AB%", value: "AB" },
    { label: "CD%", value: "CD" },
  ],
  REPORT_DISPERSION_RATIO_TABLE: [
    { value: "name", label: "Name" },
    { label: "Rating", value: "tcr" },
    { label: "Upgrades", value: "upgrades" },
    { label: "Downgrades", value: "downgrades" },
    { label: "Ratio", value: "ratio" },
  ],
};

const widgetEnabledMap = {
  REPORT_PEER_DISPERSION_CHILDREN: {
    groupBy: false,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: false,
    timeframe: false,
    useWysiwyg: false,
    sort: false,
    addPageBreak: false,
    showTimeframeInTitle: false,
  },
  REPORT_PEER_DISPERSION: {
    groupBy: false,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: false,
    timeframe: false,
    useWysiwyg: false,
    sort: false,
    addPageBreak: false,
    showTimeframeInTitle: false,
  },
  REPORT_BASKET_DISPERSION_BY_CHART: {
    groupBy: true,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: true,
    timeframe: false,
    useWysiwyg: true,
    sort: true,
    addPageBreak: true,
    showTimeframeInTitle: true,
  },
  REPORT_BASKET_DISPERSION_BY_SECTORS: {
    groupBy: true,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: true,
    timeframe: false,
    useWysiwyg: true,
    sort: true,
    addPageBreak: true,
    showTimeframeInTitle: true,
  },
  REPORT_DISPERSION_BY_SECTORS: {
    groupBy: true,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: true,
    timeframe: false,
    useWysiwyg: false,
    sort: true,
    addPageBreak: true,
    showTimeframeInTitle: true,
  },
  REPORT_DISPERSION_BY_CHART: {
    groupBy: true,
    performanceTimeframe: true,
    intervals: true,
    hideOnSingleResult: true,
    timeframe: false,
    useWysiwyg: false,
    sort: true,
    addPageBreak: true,
    showTimeframeInTitle: true,
  },
  REPORT_DISPERSION_TCR_TABLE: {
    groupBy: true,
    performanceTimeframe: false,
    intervals: false,
    hideOnSingleResult: true,
    timeframe: false,
    useWysiwyg: false,
    sort: true,
    addPageBreak: false,
    showTimeframeInTitle: false,
  },
  REPORT_DISPERSION_RATIO_TABLE: {
    groupBy: true,
    performanceTimeframe: false,
    intervals: false,
    hideOnSingleResult: true,
    timeframe: true,
    useWysiwyg: true,
    sort: true,
    addPageBreak: false,
    showTimeframeInTitle: false,
  },
};

const PdChildren = forwardRef(({ configObj }: PdChildrenTCRProps, ref) => {
  const [isEnabled, setIsEnabled] = useState(
    configObj.widgetValue.content.headline.isEnabled
  );
  const [content, setContent] = useState(
    configObj.widgetValue.content.headline.content
  );
  const [intervals, setIntervals] = useState(
    configObj.widgetValue.content.intervals
  );
  const [timeframe, setTimeframe] = useState(
    configObj.widgetValue.content.timeframe
  );
  const [focusOn, setFocusOn] = useState(
    configObj.widgetValue.content.focusOn ?? null
  );

  const [segment, setSegment] = useState(
    configObj.widgetValue.content?.segment
  );

  const [timeframePeer, setTimeframePeer] = useState(
    configObj?.widgetValue?.content?.timeframe ?? "today"
  );

  const [showOnsingleResult, setShowOnSingleResult] = useState(
    configObj?.widgetValue?.content?.hideIfOneResult ?? false
  );

  const [useWysiwyg, setUseWysiwyg] = useState(
    configObj.widgetValue?.content?.useWysiwyg ?? true
  );

  const [sortField, setSortField] = useState(
    configObj?.widgetValue?.content?.sort?.property ?? "name"
  );
  const [sortDesc, setSortDesc] = useState(
    (configObj?.widgetValue?.content?.sort?.descending ?? false) === true
      ? "desc"
      : "asc"
  );

  const [showTimeframeInTitle, setShowTimeframeInTitle] = useState(
    configObj?.widgetValue?.content?.showTimeframeInTitle ?? true
  );

  const [addPageBreak, setAddPageBreak] = useState(
    configObj?.widgetValue?.content?.addPageBreak ?? false
  );

  const changeSortDir = useCallback((event) => {
    setSortDesc(event.target.value);
  }, []);

  const changeSortField = useCallback((event) => {
    setSortField(event.target.value);
  }, []);

  const widgetsEnabled = useMemo(() => {
    const widgetId = configObj.widgetType;
    return widgetEnabledMap[widgetId];
  }, [configObj.widgetType]);

  const sortOptions = useMemo(() => {
    const widgetId = configObj.widgetType;
    return SORT_OPTIONS_DICT[widgetId];
  }, [configObj.widgetType]);

  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.headline.content = content;
      tempState.widgetValue.content.headline.isEnabled = isEnabled;
      tempState.widgetValue.content.intervals = intervals;
      tempState.widgetValue.content.showTimeframeInTitle = showTimeframeInTitle;

      /**
       * ? Beacuse the timeframe of a dispersion is expressed into a format that is different from the format of the timeframe
       * ? of the DISPERSION_RATIO_TABLE widget but the key (timeframe) in the template is the same, use the enabled widget to
       * ? set the right timeframe format
       */
      tempState.widgetValue.content.timeframe =
        widgetsEnabled.timeframe === true ? timeframePeer : timeframe;
      tempState.widgetValue.content.hideIfOneResult = showOnsingleResult;
      tempState.widgetValue.content.sort = {
        property: sortField,
        descending: sortDesc === "desc",
      };
      tempState.widgetValue.content.addPageBreak = addPageBreak;

      if ("useWysiwyg" in tempState.widgetValue.content) {
        tempState.widgetValue.content.useWysiwyg = useWysiwyg;
      }

      if (segment != null) {
        tempState.widgetValue.content.segment = segment;
      }

      if (configObj.widgetValue.content.focusOn == null) {
        return tempState;
      }
      tempState.widgetValue.content.focusOn = focusOn;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
                size="small"
              />
            }
            label={
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography>Headline</Typography>
                <TextField
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  size="small"
                  disabled={!isEnabled}
                />
              </Box>
            }
          />
        </CardContent>
      </Card>
      {widgetsEnabled.groupBy === false &&
      widgetsEnabled.performanceTimeframe === false &&
      widgetsEnabled.intervals === false &&
      widgetsEnabled.hideOnSingleResult === false &&
      widgetsEnabled.timeframe === false &&
      widgetsEnabled.useWysiwyg === false &&
      widgetsEnabled.sort === false ? (
        <></>
      ) : (
        <Card sx={{ boxShadow: 3 }}>
          <CardContent
            sx={{
              pb: "16px !important",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {focusOn != null ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"baseline"}
              >
                <Typography>Focus on</Typography>
                <Select
                  options={selectOptions}
                  defaultValue={focusOn}
                  setOutterState={(e) => setFocusOn(e)}
                />
              </Box>
            ) : null}

            {segment != null && widgetsEnabled.groupBy === true ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"baseline"}
              >
                <Typography>Group by</Typography>
                <Select
                  options={segmentOptions}
                  defaultValue={segment}
                  setOutterState={(e) => setSegment(e)}
                />
              </Box>
            ) : null}

            {widgetsEnabled.timeframe === true ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"baseline"}
              >
                <Typography>Timeframe</Typography>
                <Select
                  options={timeframeOptions}
                  defaultValue={timeframePeer}
                  setOutterState={(e) => setTimeframePeer(e)}
                />
              </Box>
            ) : null}

            {widgetsEnabled.performanceTimeframe === true ? (
              <Box>
                <Typography>Performance</Typography>
                <RadioGroup
                  row
                  value={timeframe}
                  onChange={(e) => {
                    setTimeframe(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="3_months"
                    control={<Radio />}
                    label="3M"
                  />
                  <FormControlLabel
                    value="6_months"
                    control={<Radio />}
                    label="6M"
                  />
                  <FormControlLabel
                    value="12_months"
                    control={<Radio />}
                    label="12M"
                  />
                </RadioGroup>
              </Box>
            ) : (
              <></>
            )}

            {widgetsEnabled.intervals === true ? (
              <Box>
                <Typography>Top / Bottom</Typography>
                <RadioGroup
                  row
                  value={intervals}
                  onChange={(e) => {
                    setIntervals(e.target.value);
                  }}
                >
                  <FormControlLabel value="20" control={<Radio />} label="5%" />
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="10%"
                  />
                  <FormControlLabel value="4" control={<Radio />} label="25%" />
                </RadioGroup>
              </Box>
            ) : (
              <></>
            )}

            {widgetsEnabled.sort ? (
              <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
                <Typography>Sort Property</Typography>
                <FormControl size={"small"}>
                  <MuiSelect
                    fullWidth
                    id="demo-simple-select"
                    value={sortField}
                    onChange={changeSortField}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem key={uuidv4()} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
                <Typography>Direction</Typography>
                <FormControl size={"small"}>
                  <MuiSelect
                    fullWidth
                    id="demo-simple-select"
                    value={sortDesc}
                    onChange={changeSortDir}
                  >
                    {directionOptions.map((item) => (
                      <MenuItem key={uuidv4()} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              </Box>
            ) : (
              <></>
            )}

            {widgetsEnabled.hideOnSingleResult === true ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={showOnsingleResult}
                    onChange={(e) => setShowOnSingleResult(e.target.checked)}
                    size="small"
                  />
                }
                label={
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Hide on single result</Typography>
                  </Box>
                }
              />
            ) : (
              <></>
            )}

            {widgetsEnabled.addPageBreak === true ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={addPageBreak}
                    onChange={(e) => setAddPageBreak(e.target.checked)}
                    size="small"
                  />
                }
                label={
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Add page break</Typography>
                  </Box>
                }
              />
            ) : (
              <></>
            )}

            {widgetsEnabled.showTimeframeInTitle === true ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={showTimeframeInTitle}
                    onChange={(e) => {
                      setShowTimeframeInTitle(e.target.checked);
                    }}
                    size="small"
                  />
                }
                label={
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Show timeframe in title</Typography>
                  </Box>
                }
              />
            ) : (
              <></>
            )}

            {widgetsEnabled.useWysiwyg === true ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={useWysiwyg}
                    onChange={(e) => setUseWysiwyg(e.target.checked)}
                    size="small"
                  />
                }
                label={
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Print what you see on the screen</Typography>
                  </Box>
                }
              />
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      )}
    </Box>
  );
});

export default PdChildren;
