import { MouseEvent, useRef, useState } from "react";
import { ProductClass } from "../../../../types/Defaults";
import { Button } from "@mui/material";

declare global {
  interface Window {
    App: any;
  }
}

type HelpContentContactProps = {
  environment: any;
  productClass: ProductClass;
};

export const HelpContentContact = ({
  environment,
  productClass,
}: HelpContentContactProps) => {
  const [text, setText] = useState("");
  const [hasFeedback, showFeedback] = useState<boolean>(false);
  const [hasFeedbackClose, showFeedbackClose] = useState<boolean>(false);
  const [feedback, setFeedback] = useState("");
  const textRef = useRef<HTMLTextAreaElement>(null);
  const feedbackRef = useRef<HTMLDivElement>(null);

  const sendSupportMessage = (event: MouseEvent) => {
    event.preventDefault();

    const rect = textRef.current?.getBoundingClientRect();
    if (rect !== undefined && feedbackRef.current != null) {
      feedbackRef.current.style.display = "table-cell";
      feedbackRef.current.style.height = `${rect.bottom - rect.top}px`;
      feedbackRef.current.style.width = `${rect.right - rect.left}px`;
    }

    showFeedback(true);
    setFeedback("<h1>Sending ...</h1>");

    var user = environment.get("account").user;
    var message = [
      "---------------------------------------------------",
      "Account ID: " + user.id,
      "Client    : " + user.client,
      "First name: " + user.firstName,
      "Last name : " + user.lastName,
      "Email     : " + user.email,
      "Type      : " + user.groups,
      "Product   : " + user.product.codeName,
      "---------------------------------------------------",
      "Version   : " + window.App.system.version,
      "Node      : " + window.App.system.node,
      "---------------------------------------------------",
    ];
    var params = {
      fromName: `${user.firstName} ${user.lastName}`,
      from: user.email,
      message: message.join("\n") + "\n\n" + text,
    };
    environment
      .get("http")
      ["accountUsers"].askSupport(params)
      .then(
        () => {
          setText("");
          setFeedback(
            "<h1>Message sent</h1><p>Thank you to help us improve Trendrating.</p><p>We'll be in touch.</p>"
          );
          showFeedbackClose(true);
        },
        () => {
          setFeedback(
            "<h1>Sorry, we got an error</h1><p>Please, call the Trendrating Customer Support.</p>"
          );
          showFeedbackClose(true);
        }
      );
  };

  return (
    <div className="tPageHelpIndex-contentPage tHelpPage" data-id="contact-us">
      <div className="help">
        <div className="help-layout-contact">
          <div className="help-contact">
            <div className="tPageHelpIndex-contact">
              <div>
                <h1>Get in touch with Trendrating</h1>
              </div>
              <div>
                <p>
                  Have a question? We will be happy to assist you.
                  <br />
                  Please describe the problem you’re having and give us some
                  details.
                </p>
                <div className="tPageHelpIndex-support">
                  {hasFeedback ? (
                    <div
                      className="tPageHelpIndex-support-feedback"
                      ref={feedbackRef}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feedback,
                        }}
                      />
                      <div className="button-bar">
                        {hasFeedbackClose ? (
                          <button
                            className="button"
                            onClick={() => showFeedback(false)}
                            type="button"
                          >
                            Close
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {!hasFeedback ? (
                    <form
                      action="#"
                      className="tPageHelpIndex-support-form form"
                      method="post"
                    >
                      <fieldset className="form__fieldset">
                        <ul className="form__field-list">
                          <li className="form__field">
                            <textarea
                              className="form__field-text"
                              cols={30}
                              onChange={(event) => setText(event.target.value)}
                              ref={textRef}
                              rows={10}
                              value={text}
                            ></textarea>
                          </li>
                          <li className="form__field button-bar">
                            <Button
                              sx={{ marginRight: "8px" }}
                              className="tFormButton tFormButton--primary"
                              onClick={sendSupportMessage}
                              type="button"
                            >
                              Send
                            </Button>
                            <Button
                              variant="tr_button_cancel"
                              className="tFormButton"
                              onClick={() => setText("")}
                              type="button"
                            >
                              Clear content
                            </Button>
                          </li>
                        </ul>
                      </fieldset>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="help-contact-numbers">
            <div className="tPageHelpIndex-contact-numbers">
              <strong>Global Support Numbers</strong>
              <div>London (UK) +44 (20) 3823 2215</div>
              <div>Boston (US) +1 508-315-6313</div>
              <div>Lugano (CH) +41 91 9106590</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
