import { useEffect, useState } from "react";
import { Navigate, NavLink, Outlet, Route, Routes } from "react-router-dom";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { ProductClass } from "../../../../types/Defaults";
import { HelpContentContact } from "./HelpContentContact";
import { HelpContentDownload } from "./HelpContentDownload";
import { HelpContentGlossary } from "./HelpContentGlossary";
import { HelpContentHome } from "./HelpContentHome";
import { HelpContentReleaseNotes } from "./HelpContentReleaseNotes";
import { HelpContentTutorials } from "./HelpContentTutorials";
import { HelpAlphaFinder } from "./HelpAlphaFinder";

type HelpProps = {
  staticAssets: string;
};

export const Help = ({ staticAssets }: HelpProps) => {
  const [productClass, setProductClass] = useState<ProductClass>("standard");
  const helpBaseUrl = "/help/";
  const environment = useEnvironment();

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.help;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  useEffect(() => {
    var environmentSetup = environment.get("setup");
    var productFlavourCode =
      environmentSetup["configuration"].get("product")["flavourCode"];

    if (productFlavourCode != null) {
      switch (productFlavourCode) {
        case "ADVANCED": {
          setProductClass("advanced");
          break;
        }
        case "PREMIUM": {
          setProductClass("premium");
          break;
        }
        case "STANDARD": {
          setProductClass("standard");
          break;
        }
        case "SYSTEMATIC_ENGINE":
        case "SYSTEMATIC_ENGINE_AND_PREMIUM": {
          setProductClass("systematic");
          break;
        }
        case "DISPERSION": {
          setProductClass("dispersion");
          break;
        }
        case "RISE": {
          setProductClass("rise");
        }
      }
    }
  }, [environment]);

  switch (productClass) {
    case "dispersion":
    case "advanced":
    case "rise": {
      return <HelpAlphaFinder staticAssets={staticAssets} />;
    }

    default: {
      return (
        <div className="tPageHelpIndex-wrap">
          <div className="tPageHelpIndex-sidebar">
            <ul className="tPageHelpIndex-menu">
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="getting-started"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Getting started
                </NavLink>
              </li>
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="tutorials"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Tutorials
                </NavLink>
              </li>
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="glossary"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Glossary
                </NavLink>
              </li>
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="release-notes"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Release notes
                </NavLink>
              </li>
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="desktop-applications"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Desktop applications
                </NavLink>
              </li>
              <li className="tPageHelpIndex-mainMenuItem">
                <NavLink
                  to="contact-us"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="tPageHelpIndex-content">
            <Routes>
              <Route path="glossary">
                <Route
                  index
                  element={
                    <HelpContentGlossary
                      baseUrl={helpBaseUrl}
                      productClass={productClass}
                    />
                  }
                ></Route>
                <Route path=":section">
                  <Route
                    index
                    element={
                      <HelpContentGlossary
                        baseUrl={helpBaseUrl}
                        productClass={productClass}
                      />
                    }
                  />
                  <Route
                    path=":subsection"
                    element={
                      <HelpContentGlossary
                        baseUrl={helpBaseUrl}
                        productClass={productClass}
                      />
                    }
                  ></Route>
                </Route>
              </Route>
              <Route
                path="release-notes"
                element={
                  <HelpContentReleaseNotes
                    productClass={productClass}
                    staticAssets={staticAssets}
                  />
                }
              ></Route>
              <Route
                path="desktop-applications"
                element={
                  <HelpContentDownload
                    productClass={productClass}
                    staticAssets={staticAssets}
                  />
                }
              ></Route>
              <Route
                path="contact-us"
                element={
                  <HelpContentContact
                    environment={environment}
                    productClass={productClass}
                  />
                }
              ></Route>
              <Route path="tutorials">
                <Route
                  index
                  element={
                    <HelpContentTutorials
                      baseUrl={helpBaseUrl}
                      productClass={productClass}
                    />
                  }
                ></Route>
                <Route path=":section">
                  <Route
                    index
                    element={
                      <HelpContentTutorials
                        baseUrl={helpBaseUrl}
                        productClass={productClass}
                      />
                    }
                  />
                  <Route
                    path=":subsection"
                    element={
                      <HelpContentTutorials
                        baseUrl={helpBaseUrl}
                        productClass={productClass}
                      />
                    }
                  />
                </Route>
              </Route>
              <Route
                path="getting-started"
                element={
                  <HelpContentHome
                    productClass={productClass}
                    staticAssets={staticAssets}
                  />
                }
              ></Route>
              <Route
                path="*"
                element={<Navigate to="getting-started" replace />}
              />
            </Routes>
            <Outlet />
          </div>
        </div>
      );
    }
  }
};
