/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/RankingUi2Api
 * @summary Converts UI ranking paramters to Compute API parameters
 *
 */

import { AppEnvironment } from "../../types/Defaults";
export class RankingUi2Api {
  // arbitrary big number: added because the threshold function was
  // not designed and added after
  thresholdValueMax = 99999;

  // fast access to decode the right operator for a
  // tuple (property, function)
  optionsMap: Record<string, Record<string, any>>;

  constructor(environment: AppEnvironment) {
    if (environment == null) {
      throw new Error("[CRITICAL] No environment!");
    }
    // ranking is used in screening, lists analysis and advanced builder
    //
    // I am making the assumption that for every property (factor)
    // functions and operators are exactly the same
    //
    // If they ask for different configuration for the same properties
    // it is necessary to know at trendrating/api which modules is
    // calling the API

    const product = environment?.account?.product?.configuration;
    if (product == null) {
      throw new Error("[CRITICAL] Cannot find product configuration!");
    }

    const analysisListTabsConfig = product.analysis_list.overview_tabs;
    const rankingTabConfig = analysisListTabsConfig.find(
      (tab) => tab.id === "ranking"
    );
    const optionsAnalysisList = rankingTabConfig.widgets.ranking.edit.options;
    const optionsScreening = product.screening.widgets.ranking.edit.options;
    const optionsStrategyBuilder =
      product.strategy_builder.enabled === true
        ? product.strategy_builder.ranking.edit.options
        : [];

    const options = optionsAnalysisList
      .concat(optionsScreening)
      .concat(optionsStrategyBuilder);

    const optionsMap: any = {};
    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      const property = option.property;
      if (!(property in optionsMap)) {
        optionsMap[property] = {};

        for (let j = 0; j < option.functions.length; j++) {
          optionsMap[property][option.functions[j]] = option.operators[j][0];
        }
      }
    }

    this.optionsMap = optionsMap;
  }

  /**
   *
   * Decode Compute API parameters to UI ranking paramters
   *
   * @param {object[]} rules - ranking rules
   * @param {string} rules[].rankType - one of 'inRange', 'quantile'
   * @param {object} rules[].rankTypeParams - function parameters
   * @param {string} rules[].dimension - one of available security
   *   property (fieldsConfiguration.json)
   * @param {boolean} rules[].rev -  true if descending, false otherwise
   *
   * @returns {array} the rules in UI ranking format
   */
  decode(rules: any) {
    const decoded: any = [];
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];

      const _rule: {
        function: string;
        functionParams: any;
        operator: string;
        operatorParams: {
          value: any;
        };
        property: string;
      } = {
        function: "",
        functionParams: null,
        operator: "",
        operatorParams: {
          value: rule["rev"] === true ? "desc" : "asc",
        },
        property: rule["dimension"],
      };

      switch (rule.rankType) {
        case "inRange": {
          if (rule["max"] === this.thresholdValueMax) {
            // threshold
            _rule.function = "threshold";
            _rule.functionParams = {
              value: rule["min"],
            };
          } else {
            // outlier
            _rule.function = "outlier";
            _rule.functionParams = {
              value: rule["max"],
            };
          }

          break;
        }
        case "quantile": {
          _rule.function = "quantile";
          _rule.functionParams = {
            value: rule["rankTypeParams"]["n"],
          };
          break;
        }
        default: {
          _rule["function"] = "value";
        }
      }

      _rule["operator"] = this.optionsMap[_rule.property][_rule.function];

      decoded.push(_rule);
    }

    return decoded;
  }

  /**
   *
   * Encode UI ranking paramters to Compute API parameters
   *
   * @param {object[]} rules - ranking rules
   * @param {string} rules[].function - one of 'threshold', 'outlier',
   *   'quantile'
   * @param {object} rules[].functionParams - function parameters
   * @param {any} rules[].functionParams.value - value of parameter
   * @param {string} rules[].property - one of available security
   *   property (fieldsConfiguration.json)
   * @param {string} rules[].sortBy -  one of 'asc', 'desc'
   *
   * @returns {array} the rules in Compute API format
   */
  encode(rules: any) {
    const encoded: any = [];

    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];

      const _rule: any = {
        dimension: rule["property"],
        rev: rule["operatorParams"]["value"] === "asc" ? false : true,
      };

      switch (rule["property"]) {
        case "f_eps_CC_g_3":
        case "f_sps_CC_g_3": {
          _rule["computation"] = {
            function: "g",
            params: {
              lag: 3,
            },
          };

          break;
        }
        case "f_eps_CC_g_12":
        case "f_sps_CC_g_12": {
          _rule["computation"] = {
            function: "g",
            params: {
              lag: 12,
            },
          };

          break;
        }
        case "ticker": {
          _rule["rankType"] = "string-to-double";

          break;
        }
        default: {
        }
      }

      switch (rule["function"]) {
        case "threshold": {
          _rule["rankType"] = "inRange";
          _rule["min"] = rule["functionParams"]["value"];
          _rule["max"] = this.thresholdValueMax;
          break;
        }
        case "outlier": {
          _rule["rankType"] = "inRange";
          _rule["min"] = -1 * rule["functionParams"]["value"];
          _rule["max"] = rule["functionParams"]["value"];
          break;
        }
        case "quantile": {
          _rule["rankType"] = "quantile";
          _rule["rankTypeParams"] = {
            n: rule["functionParams"]["value"],
            trimOutlier: false,
          };
          break;
        }
      }

      encoded.push(_rule);
    }

    return encoded;
  }
}
