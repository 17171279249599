import { useCallback, useEffect, useMemo, useState } from "react";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import Modal from "../../../../../../components/Modal/Modal";

type DialogDebugProps = {
  strategyFragments?: {
    params: any;
    type: string;
  };
  closeModal: () => void;
};

export function DialogDebug({
  strategyFragments,
  closeModal,
}: DialogDebugProps) {
  const [renderParams, setRenderParams] = useState<any>({
    input: null,
    output: null,
    type: null,
  });

  const environment = useEnvironment();

  const strategiesAPI = useMemo(() => {
    return new Strategies(environment.get("setup"));
  }, [environment]);

  const show = useMemo(() => {
    return strategyFragments != null;
  }, [strategyFragments]);

  const title = "Compute API request parameters";

  const isOneBox = useMemo(() => {
    const strategyType = renderParams.type;
    const params = strategyFragments?.params;
    return (
      strategyType !== "advanced" ||
      (strategyType === "advanced" &&
        params?.["strategy"]?.["benchmark"] !==
          strategiesAPI["SYMBOL_NEUTRAL_STRATEGY"] &&
        params?.["strategy"]?.["benchmark"] !==
          "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED")
    );
  }, [renderParams.type, strategiesAPI, strategyFragments?.params]);

  const onComponentMount = useCallback(() => {
    const params = strategyFragments?.params;
    const strategyType = strategyFragments?.type;

    var paramsEncoded = strategiesAPI.prepareParamsForRun(params, null);

    var input = {
      object: params,
      stringified: JSON.stringify(params),
    };

    var output = {
      object: paramsEncoded,
      stringified: JSON.stringify(paramsEncoded),
    };

    console.log("");
    console.log(
      "%cCompute API parameters",
      "background-color: #000; color: #FFF; font-weight: bold"
    );
    console.log("Input : ", input);
    console.log("Output: ", output);
    console.log("");

    setRenderParams({ output, input, type: strategyType });
  }, [strategiesAPI, strategyFragments?.params, strategyFragments?.type]);

  useEffect(() => {
    if (show) {
      onComponentMount();
    }
  }, [onComponentMount, show]);

  return show ? (
    <Modal
      customCss={{
        height: "auto",
        width: "auto",
        maxHeight: "none",
        maxWidth: "85vw",
      }}
      closeIcon={true}
      headerConfig={{ headerContent: title }}
      onClose={closeModal}
      closeOnClickAway
    >
      <div>
        {isOneBox ? (
          <textarea
            value={JSON.stringify(
              renderParams?.output?.["object"],
              undefined,
              4
            )}
            style={{ fontFamily: "monospace", height: "80vh", width: "50vw" }}
          />
        ) : (
          <div style={{ display: "flex" }}>
            <textarea
              value={JSON.stringify(
                renderParams?.output?.["object"],
                undefined,
                4
              )}
              style={{ fontFamily: "monospace", height: "80vh", width: "50vw" }}
            />
            <textarea
              value={JSON.stringify(
                strategiesAPI.prepareParamsForNeutralStrategy(
                  strategyFragments?.params
                ),
                undefined,
                4
              )}
              style={{
                backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='150px'><text x='0' y='15' fill='lightgrey' font-size='20'>Neutral strategy</text></svg>")`,
                backgroundPosition: "100% 1%",
                backgroundRepeat: "no-repeat",
                fontFamily: "monospace",
                height: "80vh",
                width: "50vw",
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  ) : (
    <></>
  );
}
