import { Box, Button, Card, CardContent } from "@mui/material";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { deepClone } from "../../../deepClone";
import { useResizer } from "../../../hooks/useResizer";
import SortableList from "../../SortableList/SortableList";
import { Help } from "./Help/Help";
import { helpTextobjs } from "./Help/helpItems";
import {
    creatingListItemContentForRuleList,
    getOptionsIndexAndInnerOptions,
} from "./utils";
import { Wizzard } from "./Wizzard/Wizzard";

type RankingRulesWizzardProps = {
    rules: [];
    options: any;
    titleRuleList: string | ReactElement;
    caller: string;
    onClose: Function;
    onCancel: Function;
    properties: any;
};

export default function RankingRulesWizzard({
    rules,
    options,
    titleRuleList,
    caller,
    onClose,
    properties,
    onCancel,
}: RankingRulesWizzardProps) {
    const wizzRef = useRef<any>(null);
    const [ruleList, setRuleList] = useState(() => {
        if (rules != null && rules.length > 0) {
            return rules.map((item, index) => ({ field: item, index: index }));
        }
        return [];
    });
    const [showWizzard, setShowWizzard] = useState(false);
    const [wizzardInput, setWizzardInput] = useState(null);

    const _op = useMemo(() => {
        const [_optionsIndex, _options] = getOptionsIndexAndInnerOptions(
            options["create"]
        );
        return { _optionsIndex: _optionsIndex, _options: _options };
    }, [options]);

    const [selectedFromSortableList, setSelectedFromSortableList] =
        useState<any>(null);
    const getSelectedFromSortableList = useCallback((item) => {
        //!! wizzard will never close if this is not defined as useCallback
        setSelectedFromSortableList(item);
    }, []);

    useEffect(() => {
        //if selectedFromSortableList != null && ruleList[selectedFromSortableList],
        //input for Wizzard is setted and wizzard is shown
        //every time that selectedFromSortableList change
        //else hide the wizzard
        if (
            selectedFromSortableList != null &&
            ruleList[selectedFromSortableList]
        ) {
            const data = ruleList[selectedFromSortableList]["field"];
            let val: any = {
                field: data,
                showOperatorSection: true,
                showFunctionSection: true,
                showValueSection: true,
                editing: true,
                index: selectedFromSortableList,
                errorAtInputValue: false,
            };
            setWizzardInput(val);
            setShowWizzard(true);
        } else {
            setShowWizzard(false);
        }
    }, [options, ruleList, selectedFromSortableList]);

    const addRuleToList = () => {
        let rule: any = null;
        if (wizzRef.current) {
            rule = wizzRef.current.getState();
        }
        if (rule == null) {
            return;
        }
        let _rule: any = deepClone(rule);
        // setRuleList((prev) => {
        //   let arrTemp = deepClone(prev);
        //   arrTemp["index"] = _rule;
        //   return arrTemp;
        // });

        if (_rule["field"]["functionParams"]["augmentation"] == null) {
            _rule["field"]["functionParams"] = null;
        } else {
            _rule["field"]["functionParams"] =
                _rule["field"]["functionParams"]["augmentation"];
        }

        _rule["field"]["operatorParams"] =
            _rule["field"]["operatorParams"]["augmentation"];
        setRuleList((prev) => {
            let arrTemp = deepClone(prev);
            arrTemp[_rule["index"]] = _rule;
            return arrTemp;
        });
    };

    //?-----------------------------------------------------
    //?-- Why use reft to set the internal state of Help?---
    //?-----------------------------------------------------
    const helpRef = useRef<any>(null);
    /*  
    -   if using a useState("st" for example) and passing a setState by props to 
    -   wizard to get the value of rState(internal state of wizzard)
    -   it will cause a loop because when updating st that is in this component 
    -   it will rerender and consequently will rerender wizzard which in turn will
    -   set st and so on... to avoid this loop, a ref is defined to <Help/> and we use
    -   imperative handle. inside <Help/> is defined a useState and i call the setState
    -   form <Help/> by the ref passing the param i want to set to useState
    */
    const getRuleToPushFromWizz = useCallback((state) => {
        if (state.showHelp) {
            setShowHelp(true);
            if (helpRef.current != null) {
                helpRef.current.setHelp(state);
            }
        } else {
            setShowHelp(false);
        }
    }, []);
    //?------------------------------
    //?------------------------------
    const [showHelp, setShowHelp] = useState(false);

    const addRuleButtonhandler = () => {
        const tempRule = {
            function: null,
            functionParams: null,
            property: null,
            operator: null,
            operatorParams: null,
        };
        let val: any = {
            field: tempRule,
            showOperatorSection: false,
            showFunctionSection: false,
            showValueSection: false,
            editing: false,
            index: ruleList.length,
            showHelp: false,
            errorAtInputValue: false,
            // selectedProperty: false,
            // selectedFunction: false,
            // selectedOperator: false,
        };
        setWizzardInput(val);
        setShowWizzard(true);
    };
    //!!----------------------------
    //! -------- rule list ---------
    //!!----------------------------
    const contentToSortableList = useMemo(() => {
        return ruleList.map((rule) => ({
            content: creatingListItemContentForRuleList(
                options["create"],
                rule.field
            ),
            value: rule.field,
        }));
    }, [options, ruleList]);
    //!!----------------------------

    //lock/unlock the "apply rules settings"
    const [areRulesChanged, setAreRulesChanged] = useState(false);
    useEffect(() => {
        //if rules != ruleList ==> settings has been changed, so anable the button
        if (ruleList) {
            let rr: any;
            if (rules) {
                rr = rules.map((item, index) => ({
                    field: item,
                    index: index,
                }));
            } else {
                rr = [];
            }

            if (JSON.stringify(rr) === JSON.stringify(ruleList)) {
                setAreRulesChanged(false);
            } else {
                setAreRulesChanged(true);
            }
        }
    }, [ruleList, rules]);

    const containerRef = useRef<HTMLDivElement>(null);
    useResizer({
        ref: containerRef,
    });
    return (
        <div
            ref={containerRef}
            style={{
                backgroundColor: "transparent",
                overflow: "hidden",
                height: "100%",
                padding: "20px",
                display: "flex",
                alignItems: "flex-start",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    maxHeight: "100%",
                }}
                style={{ width: "100%", backgroundColor: "transparent" }}
            >
                <Box
                    sx={{
                        bgcolor: "transparent",
                        display: "flex",
                        gap: 2,
                    }}
                    style={{
                        flexDirection: "column",
                        width: "80%",
                        minHeight: 0,
                    }}
                >
                    <Card sx={{ height: "100%", boxShadow: 3 }}>
                        <CardContent
                            sx={{
                                height: "100%",
                                "&:last-child": {
                                    paddingBottom: 2,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    pb: 0,
                                    height: "100%",
                                }}
                                style={{ flexDirection: "column" }}
                            >
                                <SortableList
                                    listContainerStyle={{
                                        border: "solid #2a7092 2px",
                                        overflow: "hidden",
                                    }}
                                    header={{
                                        headerTitle: (
                                            <h3
                                                style={{
                                                    fontWeight: "inherit",
                                                }}
                                            >
                                                {titleRuleList}
                                            </h3>
                                        ),
                                        headerStyle: {
                                            backgroundColor: "#2a7092",
                                            color: "#fff",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    listSetter={(obj) => {
                                        const arrTemp = obj.map(
                                            (item) => item.value
                                        );
                                        setRuleList(
                                            arrTemp.map((item, index) => ({
                                                field: item,
                                                index: index,
                                            }))
                                        );
                                    }}
                                    arrItems={contentToSortableList}
                                    isSortable={true}
                                    getSelectedItemToEdit={
                                        getSelectedFromSortableList
                                    }
                                    selectedItemFromOutside={
                                        selectedFromSortableList
                                    }
                                    emptyListStatement={"No rules defined"}
                                />

                                {!showWizzard && (
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            onClick={() =>
                                                addRuleButtonhandler()
                                            }
                                            type="button"
                                        >
                                            Add rule
                                        </Button>

                                        <span style={{ marginLeft: "auto" }}>
                                            <Button
                                                disabled={!areRulesChanged}
                                                onClick={() => {
                                                    //!!when close the component, it has to sent the ruleList to the upper level(dojo)
                                                    let _ruleList =
                                                        ruleList.map(
                                                            (item) => item.field
                                                        );
                                                    onClose(_ruleList);
                                                }}
                                                type="button"
                                            >
                                                Apply rules settings
                                            </Button>

                                            <Button
                                                sx={{
                                                    ml: 2,
                                                }}
                                                variant="tr_button_cancel"
                                                type="button"
                                                onClick={() => {
                                                    //just onmount the whole component
                                                    onCancel();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </span>
                                    </div>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                    {showWizzard && (
                        <Wizzard
                            onSetRule={() => {
                                addRuleToList();
                                setSelectedFromSortableList(null);
                            }}
                            onCancel={() => {
                                setSelectedFromSortableList(null);
                                setShowWizzard(false);
                            }}
                            outterStateSetter={getRuleToPushFromWizz}
                            ref={wizzRef}
                            options={_op}
                            input={wizzardInput}
                            // outterStateSetter={getRuleToPushFromWizz}
                        />
                    )}
                </Box>

                <Box
                    sx={{
                        bgcolor: "transparent",
                        display: "flex",
                        gap: 2,
                    }}
                    style={{ flexDirection: "column", width: "20%" }}
                >
                    <Card
                        sx={{
                            height: "100%",
                            backgroundColor: "#f2f2f2",
                            boxShadow: 3,
                        }}
                    >
                        <CardContent>
                            {/* <h3>Selection rules</h3>
              <p>
                Define the set of entitled securities. Entitled securities constitute the portion of
                the investment universe eligible to be included in the portfolio.
              </p> */}
                            {helpTextobjs[caller]?.["init"] ??
                                "no descriptions"}
                        </CardContent>
                    </Card>
                    <Help
                        ref={helpRef}
                        showHelp={showHelp && showWizzard}
                        caller={caller}
                    />
                </Box>
            </Box>
        </div>
    );
}
