import { Box, Typography } from "@mui/material";
import { useMemo } from "react";

type Props = {
  index: number;
  indexForSelection: number | null;
  item: any;
  selectItem: Function;
  setIndexForSelection?: Function;
};

export default function ListItemSecurity({
  index,
  indexForSelection,
  item,
  selectItem,
  setIndexForSelection,
}: Props) {
  const _indexForSelection = useMemo(
    () => indexForSelection,
    [indexForSelection]
  );

  return (
    <Box
      borderRadius={1}
      bgcolor={(indexForSelection !==null && index === _indexForSelection)? "#2a7092": "white"}
      key={index}
      onClick={() => {
        selectItem(item);
      }}
      onMouseOver={() => {
        if (setIndexForSelection) {
          setIndexForSelection(index);
        }
      }}
      display={"flex"}
      justifyContent={"space-between"}
      p={0.5}
      gap={2}
    >
      <Typography
        color={index === _indexForSelection? "white" : "black"}
      >
        {item.ticker}
      </Typography>
      <Typography
        color={index === _indexForSelection  ? "white" : "black"}
      >
        {item.name}
      </Typography>
      <Typography
        color={index === _indexForSelection  ? "white" : "black"}
      >
        {item.type}:{item.exchange}
      </Typography>
    </Box>
  );
}
