export const initValue = {
  dispersion: {
    intervals: "4",
    timeframe: "1_month",
  },
  focus: {
    focusOn: "Region",
    what: {
      label: "Any",
      type: "0 root",
      value: "ICB",
    },
    hasIndustries: false,
  },
  reportType: "overview",
  size: null,
  timeframe: "lastWeek",
  where: {
    // label: "Developed Markets",
    type: "Area",
    value: "M_D",
    selected: true,
  },
  headerConfig: {
    logo: "small",
    date: true,
  },
};
type ActionType = {
  payload: any;
  type:
    | "SET_WHERE_TYPE"
    | "SET_WHERE_VALUE"
    | "SET_REPORT_TYPE"
    | "SET_DISPERSION_INTERVALS"
    | "SET_DISPERSION_TIMEFRAME"
    | "SET_TIMEFRAME"
    | "SET_FOCUSON"
    | "SET_HASINDUSTRIES"
    | "SET_FOCUS_WHAT"
    | "SET_SIZE";
};
export const reportReducer = (draft: any, action: ActionType) => {
  switch (action.type) {
    case "SET_WHERE_TYPE":
      draft.where.type = action.payload;
      draft.where.value = null;
      break;
    case "SET_WHERE_VALUE":
      draft.where.value = action.payload;
      break;
    case "SET_REPORT_TYPE":
      draft.reportType = action.payload;
      break;
    case "SET_DISPERSION_INTERVALS":
      draft.dispersion.intervals = action.payload;
      break;
    case "SET_DISPERSION_TIMEFRAME":
      draft.dispersion.timeframe = action.payload;
      break;
    case "SET_TIMEFRAME":
      draft.timeframe = action.payload;
      break;
    case "SET_FOCUSON":
      draft.focus.focusOn = action.payload;
      break;
    case "SET_HASINDUSTRIES":
      draft.focus.hasIndustries = action.payload;
      break;
    case "SET_FOCUS_WHAT":
      draft.focus.what = action.payload;
      break;
    case "SET_SIZE":
      draft.size = action.payload;
      break;
    default:
      break;
  }
};
