import { Lists } from "../../api/compute/Lists";
export class UserListStorage {
  private apiList: Lists;

  constructor(apiList) {
    this.apiList = apiList;
  }

  private collections = null;

  async get(id?) {
    if (this.collections != null) {
      if (id) {
        return (this.collections as any).find(
          (collection) => collection.id === id
        );
      }
      return this.collections;
    }

    const response = await this.apiList.get();

    if (response.data) {
      this.collections = response.data;

      if (id) {
        return (this.collections as any).find(
          (collection) => collection.id === id
        );
      }

      return this.collections;
    }

    return null;
  }

  async invalidate() {
    this.collections = null;
  }
}
