/* eslint-disable no-template-curly-in-string */
/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-usage/Usage
 * @summary Track the usage of the application recording data on Keen IO
 *
 */

import axios, { AxiosRequestConfig } from "axios";

function ThrowExceptionParam(message = "Missing parameter") {
  const error = new Error(message);
  error.name = "MissingParameterException";
  throw error;
}

function ThrowExceptionAction(message = "Unknown action") {
  const error = new Error(message);
  error.name = "UnknownActionException";
  throw error;
}

function ThrowExceptionFunction(message = "Unknown function") {
  const error = new Error(message);
  error.name = "UnknownFunctionException";
  throw error;
}

type UsageProps = {
  clientIp: string;
  debug: boolean;
  product: string;
  productFlavorId: string;
  productVersion: string;
  recordMap: any;
  storeKey: string;
  storeUrl: string;
  userId: string;
  userType: string;
};

export class Usage {
  clientIp: string | null = null;
  debug = false;
  message: string | null = null;
  name: string | null = null;
  product: string | null = null;
  productFlavorId: string | null = null;
  productVersion: string | null = null;
  recordMap: any = null;
  storeKey: string | null = null;
  storeUrl: string | null = null;
  userId: string | null = null;
  userType: string | null = null;

  /**
   * @constructor
   *
   * @param {object} params - Parameters
   * @param {string} params.clientIp - The IP of the client
   * @param {boolean} params.debug - Debug messages on/off.
   *       Default false
   * @param {string} params.product - The name of the product for which
   *       record data
   * @param {number} params.productFlavorId - The ID of product flavor
   * @param {string} params.productVersion - The version of the product
   *       for which record data
   * @param {object} params.recordMap - The record map to be used
   * @param {string} params.storeKey - The key to write on store
   * @param {string} params.storeUrl - The url of the store on which
   *       record data
   * @param {number} params.userId - The of ID the user for whom
   *       recording usage
   * @param {string} params.userType - The of type the user for whom
   *       recording usage
   */
  constructor(params: UsageProps) {
    var missing: any = [];

    if (
      params === undefined ||
      params == null ||
      Object.keys(params).length === 0
    ) {
      ThrowExceptionParam();
    }

    if (params["clientIp"] != null) {
      this.clientIp = params["clientIp"];
    } else {
      missing.push("clientIp");
    }

    if (params["product"] != null) {
      this.product = params["product"];
    } else {
      missing.push("product");
    }

    if (params["productFlavorId"] != null) {
      this.productFlavorId = params["productFlavorId"];
    } else {
      missing.push("productFlavorId");
    }

    if (params["productVersion"] != null) {
      this.productVersion = params["productVersion"];
    } else {
      missing.push("productVersion");
    }

    if (params["recordMap"] != null) {
      this.recordMap = params["recordMap"];
    } else {
      missing.push("recordMap");
    }

    if (params["storeKey"] != null) {
      this.storeKey = params["storeKey"];
    } else {
      missing.push("storeKey");
    }

    if (params["storeUrl"] != null) {
      this.storeUrl = params["storeUrl"];
    } else {
      missing.push("storeUrl");
    }

    if (params["userId"] != null) {
      this.userId = params["userId"];
    } else {
      missing.push("userId");
    }

    if (params["userType"] != null) {
      this.userType = params["userType"];
    } else {
      missing.push("userType");
    }

    if (missing.length > 0) {
      ThrowExceptionParam("Missing parameters: " + missing.join(", "));
    }

    if (
      "debug" in params &&
      params["debug"] !== undefined &&
      params["debug"] != null
    ) {
      this.debug = params["debug"];
    }
  }

  /**
   * Records an usage event
   *
   * @param {object} info - The information to be recorded
   * @param {string} info.action - one of the action listed in
   *       recordMap
   * @param {string} info.actionParam - the action params
   * @param {string} info.function - one of the function listed in
   *       recordMap
   */
  record(info, callback) {
    var _info = {
      keen: {
        addons: [
          {
            name: "keen:ip_to_geo",
            input: {
              ip: "keen_ip",
            },
            output: "keen_ip_geo",
          },
          {
            name: "keen:ua_parser",
            input: {
              ua_string: "keen_ua",
            },
            output: "keen_ua_parsed",
          },
        ],
      },
      keen_ip: "${keen.ip}",
      keen_ua: "${keen.user_agent}",
      product: this.product,
      product_flavor_id: this.productFlavorId,
      product_version: this.productVersion,
      resolution: [window.screen.availWidth, window.screen.availHeight].join(
        "x"
      ),
      user_id: this.userId,
      user_type: this.userType,
      version: "2.0.0",
      viewport: [
        window.document.documentElement.clientWidth,
        window.document.documentElement.clientHeight,
      ].join("x"),
    };
    var recordMap: any = this.recordMap;

    if (info === undefined || info == null || Object.keys(info).length === 0) {
      ThrowExceptionParam(
        "Missing parameters: function and action are required"
      );
    }

    // action
    if (info["action"] in recordMap["action"]) {
      _info["action"] = recordMap["action"][info["action"]];
    } else {
      ThrowExceptionAction(info["action"] + ": unknown action");
    }
    // action param
    if (info["actionParam"] in recordMap["actionParam"]) {
      _info["action_param"] = recordMap["actionParam"][info["actionParam"]];
    } else {
      _info["action_param"] = info["actionParam"];
    }
    // function
    if (info["function"] in recordMap["function"]) {
      _info["function"] = recordMap["function"][info["function"]];
    } else {
      // 2022-02-09 Landing page can accept different functions
      // if the page is not managed correctly in Routes.js
      if (_info["action"] === recordMap.action.LANDING) {
        _info["function"] = info["function"];
      } else {
        ThrowExceptionFunction(info["function"] + ": unknown function");
      }
    }

    var reqParams: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 800,
      params: {
        api_key: this.storeKey,
        ts: new Date().getTime(),
      },
    };

    if (this.storeUrl == null) {
      console.log("No this.storeUrl");
      return;
    }

    var request;
    var sentBeacon;
    if (navigator.sendBeacon) {
      const params = new Blob([JSON.stringify(_info)], {
        type: "application/json",
      });
      sentBeacon = navigator.sendBeacon(
        this.storeUrl,
        params
      );
    } else {
      request = axios
        .post(this.storeUrl, JSON.stringify(_info), reqParams)
        .then((response) => response.data);
    }

    if (appConfig.isDebug) {
      if (request) {
        request.then(function (response) {
          console.log("[Usage] Record saved", _info, response);
        });
      } else {
        console.log(
          "[Usage] Record saved (beacon)",
          sentBeacon ?? "no beacon",
          _info
        );
      }
    }

    // Even in case of error, call callback
    if (request) {
      return request.then(
        () => (callback ? callback() : void 0),
        () => (callback ? callback() : void 0)
      );
    }

    if (callback) {
      callback();
    }
    // no request, just send the sentBeacon response
    return { beacon: sentBeacon };
  }
}
