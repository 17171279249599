export type HedgingType = null | {
  constraints: {
    hedgingStrategy: "HEDGING_FULL" | "HEDGING_SMART" | "HEDGING_ADVANCED";
    value: any;
  };
  instrument: string;
  leverage: number | null;
  inputError: boolean;
};

type HedgingAction = {
  type:
    | "SET_STATE"
    | "SET_INSTRUMENT"
    | "SET_HEDGING_STRATEGY"
    | "SET_LEVERAGE"
    | "SET_HEDGING_SMART"
    | "SET_HEDGING_ADVANCED"
    | "SET_INPUT_ERROR_HANDLING";
  payload: any;
};
export function hedgingReducer(draft: HedgingType, action: HedgingAction) {
  switch (action.type) {
    case "SET_INPUT_ERROR_HANDLING":
      draft!.inputError = action.payload;
      break;
    case "SET_STATE":
      draft = action.payload;
      return draft;
    case "SET_INSTRUMENT":
      draft!.instrument = action.payload;
      break;
    case "SET_HEDGING_STRATEGY":
      if (draft?.constraints) {
        draft!.constraints.hedgingStrategy = action.payload;
        switch (action.payload) {
          case "HEDGING_FULL":
          case "HEDGING_ADVANCED":
            draft.constraints.value = [];
            break;
          case "HEDGING_SMART":
            draft.constraints.value = [
              {
                function: "value",
                functionParams: null,
                property: "rc",
                operator: "equalToRate",
                operatorParams: {
                  value: { A: false, B: false, C: true, D: true },
                },
              },
            ];
            break;
        }
      }
      break;
    case "SET_HEDGING_ADVANCED":
      draft!.constraints.value = action.payload;
      break;
    case "SET_LEVERAGE":
      if (draft != null) {
        draft.leverage = action.payload;
      }

      break;
    case "SET_HEDGING_SMART":
      if (draft) {
        const obj = [
          {
            function: "value",
            functionParams: null,
            property: "rc",
            operator: "equalToRate",
            operatorParams: {
              value: action.payload,
            },
          },
        ];
        if (JSON.stringify(obj) !== JSON.stringify(draft.constraints.value)) {
          draft.constraints.value = obj;
        }
      }
      break;
  }
}
