/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/core/UtilsMath
 * @summary Common mathematical functions
 *
 */

import { deepClone } from "../../deepClone";

export class UtilsMath {
  /**
   *  Suitable to prepare data for bar widget: it uses quartiles
   *  to define cutting threshold
   */
  static normalizeAndCutOutliers(arrayOfObject: any[], property: string) {
    if (arrayOfObject.length) {
      var rawData = deepClone(arrayOfObject);
      var LENGTH = rawData.length;
      rawData = rawData.sort(function (a, b) {
        var aAbs = a ? Math.log(Math.abs(a[property])) : a;
        var bAbs = b ? Math.log(Math.abs(b[property])) : b;

        if (aAbs > bAbs) {
          return -1;
        }
        if (aAbs < bAbs) {
          return 1;
        }

        return 0;
      });

      var maxIndex = Math.min(LENGTH - 1, (3 / 4) * (LENGTH + 1));
      var minIndex = (1 / 4) * (LENGTH + 1);
      var max = rawData[maxIndex][property];
      var min = rawData[minIndex][property];
      var IQR = max - min;
      var threshold = Math.abs(IQR * 1.5);

      for (const obj of arrayOfObject) {
        obj["_s_normalizationThreshold"] = threshold;
      }
    }

    return arrayOfObject;
  }

  static normalize(arrayOfObject: any[], property: string) {
    if (arrayOfObject.length) {
      var rawData = deepClone(arrayOfObject);
      rawData = rawData.sort(function (a, b) {
        var aAbs = a ? Math.log(Math.abs(a[property])) : a;
        var bAbs = b ? Math.log(Math.abs(b[property])) : b;

        if (aAbs > bAbs) {
          return -1;
        }
        if (aAbs < bAbs) {
          return 1;
        }

        return 0;
      });

      var max = rawData[0][property];
      var threshold = Math.abs(max);

      for (const obj of arrayOfObject) {
        obj["_s_normalizationThreshold"] = threshold;
      }
    }

    return arrayOfObject;
  }

  // another implementation is in app/ui/chart/_SerieBase
  // todo merge
  static scaleXY(
    V: number,
    minL: number,
    maxL: number,
    minX: number,
    maxX: number
  ) {
    return Math.round(minX + ((maxX - minX) * (V - minL)) / (maxL - minL));
  }
}
