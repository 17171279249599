import { useRef } from "react";

type ListItemProps = {
  name: string;
  moveDown: Function;
  moveUp: Function;
  index: number;
  remove: Function;
  click: Function;
};

export default function ReactListItem({
  name,
  moveDown,
  index,
  moveUp,
  remove,
  click,
}: ListItemProps) {
  const triggerMoveDown = () => {
    moveDown(index);
  };

  const triggerMoveup = () => {
    moveUp(index);
  };

  const triggerRemove = () => {
    remove(index);
  };

  const reference = useRef() as React.RefObject<HTMLDivElement>;

  return (
    <div
      className="tFormListItem"
      ref={reference}
      onClick={() => {
        click({ class: "tFormListItem", reference: reference });
      }}
    >
      <div className="tFormListItem-layout">
        <div className="tFormListItem-content">
          <div className="tFormListItem-contentLabel">{name}</div>
        </div>
        <div className="tFormListItem-actions">
          <button
            onClick={triggerMoveup}
            className="tFormListItem-action tFormListItem-action--moveUp"
            data-dojo-attach-point="nodeButtonMoveUp"
            title="Move up"
          >
            <span className="i-triangle-up"></span>
          </button>
          <button
            onClick={triggerMoveDown}
            className="tFormListItem-action tFormListItem-action--moveDown"
            data-dojo-attach-point="nodeButtonMoveDown"
            title="Move down"
          >
            <span className="i-triangle-down"></span>
          </button>
          <button
            className="tFormListItem-action"
            data-dojo-attach-point="nodeButtonRemove"
            title="Remove"
            onClick={triggerRemove}
          >
            <span className="i-skip"></span>
          </button>
        </div>
      </div>
    </div>
  );
}
