/**
 * Trendrating Web Application Configuration
 */

export const config = {
    channels: {
        dialog: {
            input: "app/dialog/input",
            output: null,
        },
        feedback: {
            input: "app/feedback/input",
            output: null,
        },
        menu: {
            input: "app/menu/input",
            output: null,
        },
        preferences: {
            input: "app/preferences/input",
            output: null,
        },
        storage: {
            request: "app/storage/request",
            response: "app/storage/response",
        },
        workflow: {
            input: "app/workflow/input",
            output: null,
        },
    },
    cookies: {
        //
        // !!! WARNING !!!
        //
        // if you change something here, be sure to make the change even in
        // app-account/config
        //
        impersonate: {
            name: "t-i",
            props: {
                path: "/",
                samesite: "strict",
            },
        },
        productId: {
            name: "t-p-i",
            props: {
                path: "/",
                samesite: "strict",
            },
        },
        //
        // other app cookies
        //
        portfolioOverviewNews: {
            name: "t-p-o-n",
            props: {
                path: "/",
                samesite: "strict",
            },
        },
        tableRowsPerPage: {
            name: "t-t-r-p-p",
            props: {
                path: "/",
                samesite: "strict",
            },
        },
    },
    router: {
        base: "/app",
    },
    routes: {
        appAccount: "/",
        "/": "/",
        "/impersonate/:userId/:firstName/:lastName/":
            "/impersonate/:userId/:firstName/:lastName/",
        "/lead/": "/lead/",
        "/password-recovery/": "/password-recovery/",
        "/password-setup/:uid/": "/password-setup/:uid/",

        app: "/app/",
    },
};
