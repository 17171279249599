import { useCallback, useEffect, useMemo, useState } from "react";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import DispersionTab from "./DispersionTab";
import { useParams } from "react-router-dom";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { config } from "../../../../../config-ts";
import { clone } from "../../../../../../../deepClone";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type TabDispersionProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabDispersion({ dataManager }: TabDispersionProps) {
  const [portfolioInfo, setPortfolioInfo] = useState<{
    id: number;
    type: "BASKET" | "PORTFOLIO";
    assetType: any;
    name: string;
  }>();
  const [reportUiParams, setReportUiParams] = useState();
  const urlParams = useParams();

  const portfolioName = useMemo(
    () => portfolioInfo?.name ?? "",
    [portfolioInfo?.name]
  );

  const { broadcast } = useBroadcast();

  const onComponentMount = useCallback(async () => {
    const portfolio = await dataManager.get("dispersion");

    setPortfolioInfo(portfolio);
  }, [dataManager]);

  const portfolioId = useMemo(() => {
    if (urlParams.id) {
      return parseInt(urlParams.id);
    }
  }, [urlParams.id]);

  const manageWorkflow = useCallback(() => {
    let actions: any = [];
    let action: any = null;

    if (portfolioId != null) {
      action = {
        componentJSX: (
          <ReportButton
            page={"dispersionBasketTab"}
            target={{ id: portfolioId, name: portfolioName }}
            rankingCache={null}
            title={portfolioName ?? ""}
            usage={window.App.usage}
            widgets={{
              dispersion: {
                get: () => (reportUiParams ? clone(reportUiParams) : undefined),
              },
            }}
          />
        ),
      };
    }

    actions.push(action);

    var message = {
      from: "analysisList",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, portfolioId, portfolioName, reportUiParams]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  useEffect(() => {
    manageWorkflow();
  }, [manageWorkflow]);

  return (
    <div>
      {portfolioInfo && (
        <DispersionTab list={portfolioInfo} syncWysiwyg={setReportUiParams} />
      )}
    </div>
  );
}
