import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../deepClone";
import ReportSelect from "../commonWidgets/Select";
import {
  MomentumBreakdownType,
  momentumBreakdownReducer,
} from "./React_MomentumBreakdown_reducer";

const selectOptions = [
  {
    label: "Markets vs Sectors",
    value: "MARKET_INDUSTRY",
  },
  {
    label: "Markets vs Industries",
    value: "MARKET_SECTOR",
  },
  {
    label: "Regions vs Sectors",
    value: "REGION_INDUSTRY",
  },
  {
    label: "Regions vs Industries",
    value: "REGION_SECTOR",
  },
];

type MomentumBreakdownProps = {
  configObj: any;
};

const MomentumBreakdown = forwardRef(
  ({ configObj }: MomentumBreakdownProps, ref) => {
    const initState: MomentumBreakdownType = useMemo(
      () => ({
        content: configObj.widgetValue.content,
      }),
      [configObj.widgetValue.content]
    );
    const [state, dispatch] = useImmerReducer(
      momentumBreakdownReducer,
      initState
    );

 

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state.content;
        return tempState;
      },
    }));

    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.content.headline.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CONTENT_HEADLINE_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Headline"
              />
              <TextField
                value={state.content.headline.content}
                disabled={!state.content.headline.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CONTENT_HEADLINE_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
              <Typography>Data cross</Typography>
              <ReportSelect
                setOutterState={(e) =>
                  dispatch({
                    type: "SET_CONTENT_NUMBERS_DATACROSS",
                    payload: e,
                  })
                }
                options={selectOptions}
                defaultValue={state.content.dataCross}
              />
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.isBreadth}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_NUMBERS_IS_BREADTH",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Breadth"
            />
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default MomentumBreakdown;
