import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { deepClone } from "../../../../../../deepClone";
import styles from "./PeerSize.module.scss";

type Props = {
  options: any;
  value?: any;
  onSetSize: Function;
};

export default function PeerSize({ options, value = null, onSetSize }: Props) {
  const initValue = useMemo(() => value, [value]);
  const [intMap, setIntMap] = useState<any>(null);

  const isSelected = useCallback(
    (index: number) => {
      return intMap?.[index] ?? false;
    },
    [intMap]
  );

  //  if i select micro and then mid it will check if
  //  there's something between micor and mid and will set it on map as true
  const inclusionChecker = useCallback((map) => {
    let indexStart: any = -1;
    let indexEnd: any = -1;
    let obj = deepClone(map);
    for (const [key, value] of Object.entries(obj)) {
      if (value === true) {
        if (indexStart === -1) {
          indexStart = key;
        } else {
          indexEnd = key;
        }
      }
    }
    for (const key of Object.keys(obj)) {
      if (indexStart <= parseInt(key) && parseInt(key) <= indexEnd) {
        obj[key] = true;
      }
    }
    return obj;
  }, []);

  useEffect(() => {
    let checkboxMap: any = null;
    if (!initValue) {
      checkboxMap = options.reduce(
        (prev, current, index) => ({ ...prev, [index]: false }),
        {}
      );
    } else {
      var stringa = initValue;
      let values: any[] = stringa.split(/(?=[A-Z])/);
      values = values.map((item) => item.toLowerCase());
      let obj = {};
      for (let i = 0; i < options.length; i++) {
        obj[i] = values.includes(options[i].value) ? true : false;
      }
      checkboxMap = inclusionChecker(obj);
    }
    setIntMap(checkboxMap);
  }, [options, initValue, inclusionChecker]);

  const _getIndexAndValue = useCallback(
    (_map) => {
      const getInterval = (map) => {
        var opts = options;
        if (opts == null) {
          return null;
        }

        let indexEnd: any = null;
        let indexStart: any = null;
        let keys = Object.keys(map);
        keys.forEach((key) => {
          let i = parseInt(key);
          if (map[i.toString()] === true) {
            if (indexEnd == null || i > indexEnd) {
              indexEnd = i;
            }

            if (indexStart == null || i < indexStart) {
              indexStart = i;
            }
          }
        });
        return indexEnd != null && indexStart != null
          ? {
              end: indexEnd,
              start: indexStart,
            }
          : null;
      };
      var interval = getInterval(_map);
      if (interval == null) {
        return null;
      }

      var indexEnd = interval["end"];
      var indexStart = interval["start"];

      var opt = options;
      var valueEnd = opt[indexEnd]["value"];
      var valueStart = opt[indexStart]["value"];

      return {
        indexEnd: indexEnd,
        indexStart: indexStart,
        valueEnd: valueEnd,
        valueStart: valueStart,
      };
    },
    [options]
  );

  const getValue = useCallback(
    (map) => {
      var _value = _getIndexAndValue(map);

      if (_value == null) {
        return null;
      }

      var indexEnd = _value["indexEnd"];
      var indexStart = _value["indexStart"];
      var valueEnd = _value["valueEnd"];
      var valueStart = _value["valueStart"];

      if (valueStart === valueEnd) {
        return valueStart;
      }

      var opt = options;
      if (indexStart === 0 && indexEnd === opt.length - 1) {
        return null;
      }

      var value =
        valueStart + valueEnd.charAt(0).toUpperCase() + valueEnd.slice(1);

      return value;
    },
    [_getIndexAndValue, options]
  );

  const updateMap = useCallback(
    (index) => {
      let newMap = deepClone(intMap);
      newMap[index] = !newMap[index];
      newMap = inclusionChecker(newMap);
      setIntMap(newMap);
      const _size = getValue(newMap);
      onSetSize(_size);
    },
    [getValue, inclusionChecker, intMap, onSetSize]
  );

  return (
    <Box component={"ul"} display={"flex"}>
      {options.map((opt, index) => {
        return (
          <li
            onClick={() => updateMap(index)}
            key={index}
            title={options[index].title}
            className={`${styles.listItems} ${
              isSelected(index) ? styles.active : ""
            }`}
          >
            <Typography>{opt.label}</Typography>
          </li>
        );
      })}
    </Box>
  );
}
