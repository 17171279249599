import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { TaxonomyFieldRow } from "../../../../../pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";

type Props = {
  value: any[];
  preferenceDispatcher: Function;
  saving: Function;
  widgetTtle?:string;
};

export default function MarketsAnalysis({
  value,
  preferenceDispatcher,
  saving,
  widgetTtle = ""
}: Props) {

  const {
    taxonomiesMapX,
    advancedNodesSelector
  } = useTaxonomyByType("stock");
  const filterHandler = useCallback(
    (val) => {
      if (val.length === 0) {
        preferenceDispatcher({
          type: "SET_MARKETS_FILTERS",
          payload: [],
          saving: saving,
        });
      } else {
        const temp  = advancedNodesSelector(val[0].value.segments, "country", true);

        preferenceDispatcher({
          type: "SET_MARKETS_FILTERS",
          payload: temp,
          saving: saving,
        });
      }
    },
    [advancedNodesSelector, preferenceDispatcher, saving]
  );

  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important", display:"flex", flexDirection:"column", gap:1 }}>
          <Typography>
            Set specific markets to personalize your market view
          </Typography>
          {/* <TaxonomySelect
            taxonomyInfo={{
              type: "security",
              field: "country",
            }}
            panelTitle={""}
            defaultValue={value ?? []}
            onSetFilters={filterHandler}
          /> */}
          <TaxonomyFieldRow
            // label={widgetTtle}
            handleValuesSelection={filterHandler}
            taxonomyMap={taxonomiesMapX}
            selectedValues={value ?? []}
            taxonomyType={"security"}
            taxonomyField={"country"}
            phraseOnEmpty="World wide"

          />
        </CardContent>
      </Card>
    </Box>
  );
}
