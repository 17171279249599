import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import { SelectionUi2Api } from "../../../../../../../api/compute/SelectionUi2Api";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
// import { RangeMktCap } from "../../../../../../../js/app/components/ui/form/RangeMktCap";
import { RangeVolatility } from "../../../../../../../js/app/components/ui/form/RangeVolatility";
import { MarketCapFilter } from "../../../../screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import { selectionReducer, selectionState } from "./selectionReducer";

type SelectionRulesProps = {
  strategySelection: any;
  dispatch: Function;
  showAny: boolean;
  more?: boolean;
};

export default function SelectionRules({
  strategySelection,
  dispatch,
  showAny,
  more,
}: SelectionRulesProps) {
  const mapPropsToSelection = (strategySelection) => {
    let state = {
      rating: "A",
      marketCap: {
        value: { ">=": null, "<=": null },
      },
      volatility: {
        value: { ">=": null, "<=": null },
      },
      minLiquidity: null,
      performanceSinceRatedAbove: null,
      retracementAbove: null,
    };

    strategySelection?.forEach((element) => {
      switch (element.property) {
        case "rc":
          const { A, B, C, D } = element.operatorParams.value;
          if (A && !B && !C && !D) {
            state.rating = "A";
          }
          if (A && B && !C && !D) {
            state.rating = "AB";
          }
          if (A && B && C && D) {
            state.rating = "any";
          }
          break;
        case "marketcap":
          state.marketCap.value[">="] =
            element.operatorParams.value[0].ge ?? null;

          state.marketCap.value["<="] =
            element.operatorParams.value[0].le ?? null;

          break;
        case "sd":
          state.volatility.value[">="] =
            element.operatorParams.value[0].ge ?? null;
          state.volatility.value["<="] =
            element.operatorParams.value[0].le ?? null;
          break;
        case "tradedvalue":
          state.minLiquidity = element.operatorParams.value[0].ge;
          break;
        case "pr":
          state.performanceSinceRatedAbove = element.operatorParams.value[0].ge;
          break;
        case "px":
          state.retracementAbove = element.operatorParams.value[0].ge;
          break;
      }
    });
    return state;
  };

  const [selection, selectionDispatch] = useImmerReducer(
    selectionReducer,
    strategySelection ? mapPropsToSelection(strategySelection) : selectionState
  );

  const environment = useEnvironment();

  const envTransformation = environment
    .get("configuration")
    .get("strategyBuilder")["selection"]["edit"]["transformations"];

  useEffect(() => {
    selectionDispatch({
      type: "SYNC_SELECTION_WITH_STATE",
      payload: mapPropsToSelection(strategySelection),
    });
  }, [selectionDispatch, strategySelection]);

  useEffect(() => {
    const selectionRules: any[] = [];
    const serverFormatEncoder = new SelectionUi2Api(envTransformation);

    /**
     * Create the object of the rating field
     */
    let ratingObj: any = {
      dimension: "rc",
      operator: "equals",
      segments: [],
    };

    if (selection.rating.length) {
      switch (selection.rating) {
        case "A":
          ratingObj.segments = [2];
          break;
        case "AB":
          ratingObj.segments = [2, 1];
          break;
        case "any":
          ratingObj.segments = [2, 1, -1, -2];
          break;
      }

      selectionRules.push(ratingObj);
    }

    /**
     * Create the object of the market capitalization field
     */
    let marketCapObj: {
      dimension: string;
      operator: string;
      segments: { ">="?: number | null; "<="?: number | null }[];
    } = {
      dimension: "marketcap",
      operator: "range",
      segments: [],
    };

    let marketCapValue = {};

    if (selection.marketCap.value[">="] != null) {
      marketCapValue[">="] = selection.marketCap.value[">="];
    }

    if (selection.marketCap.value["<="] != null) {
      marketCapValue["<="] = selection.marketCap.value["<="];
    }

    marketCapObj.segments.push(marketCapValue);

    if (
      marketCapObj.segments.length &&
      (marketCapObj.segments[0][">="] != null ||
        marketCapObj.segments[0]["<="] != null)
    ) {
      selectionRules.push(marketCapObj);
    }

    /**
     * Create the object of the volatility field
     */
    let volatilityObj: {
      dimension: string;
      operator: string;
      segments: { [">="]?: number | null; ["<="]?: number | null }[];
    } = {
      dimension: "sd",
      operator: "range",
      segments: [],
    };

    let volatilityValue = {};

    if (selection.volatility.value[">="] != null) {
      volatilityValue[">="] = selection.volatility.value[">="];
    }

    if (selection.volatility.value["<="] != null) {
      volatilityValue["<="] = selection.volatility.value["<="];
    }

    volatilityObj.segments.push(volatilityValue);

    if (
      volatilityObj.segments.length &&
      (volatilityObj.segments[0][">="] != null ||
        volatilityObj.segments[0]["<="] != null)
    ) {
      selectionRules.push(volatilityObj);
    }

    /**
     * Create the object of the Min. Liquidity field
     */
    let minLiquidityObj: {
      dimension: string;
      operator: string;
      segments: [{ ">=": number | null }];
    } = {
      dimension: "tradedvalue",
      operator: "range",
      segments: [
        {
          ">=": selection.minLiquidity,
        },
      ],
    };

    if (minLiquidityObj.segments[0][">="] != null) {
      selectionRules.push(minLiquidityObj);
    }

    /**
     * Create the object of the Performance since rated above field
     */
    let prObj: {
      dimension: string;
      operator: string;
      segments: [{ ">=": number | null }];
    } = {
      dimension: "pr",
      operator: "range",
      segments: [
        {
          ">=": selection.performanceSinceRatedAbove,
        },
      ],
    };

    if (prObj.segments[0][">="] != null) {
      selectionRules.push(prObj);
    }

    /**
     * Create the object of the Performance retracement above field
     */
    let pxObj: {
      dimension: string;
      operator: string;
      segments: [{ ">=": number | null }];
    } = {
      dimension: "px",
      operator: "range",
      segments: [
        {
          ">=": selection.retracementAbove,
        },
      ],
    };

    if (pxObj.segments[0][">="] != null) {
      selectionRules.push(pxObj);
    }

    dispatch({
      type: "SET_SELECTION",
      payload: serverFormatEncoder.decode(selectionRules),
    });
  }, [dispatch, envTransformation, selection]);

  const rangeObject = {
    ge: selection.marketCap.value[">="],
    le: selection.marketCap.value["<="],
  };

  const rangeVolatilityObject = {
    ge: selection.volatility.value[">="],
    le: selection.volatility.value["<="],
  };

  const rangeMktValue = {
    left: rangeObject.ge ? rangeObject.ge * 1000000 : null,
    right: rangeObject.le ? rangeObject.le * 1000000 : null,
  };
  const rangeVolatility = {
    left: rangeVolatilityObject.ge ? rangeVolatilityObject.ge * 100 : null,
    right: rangeVolatilityObject.le ? rangeVolatilityObject.le * 100 : null,
  };

  const minLiquidity = {
    ge: selection.minLiquidity ? selection.minLiquidity / 1000 : null,
  };
  const pr = {
    ge: selection.performanceSinceRatedAbove
      ? /*selection.performanceSinceRatedAbove * 100*/ parseFloat(
          (selection.performanceSinceRatedAbove * 100).toFixed(2)
        )
      : null,
  };
  const px = {
    ge: selection.retracementAbove
      ? /*selection.retracementAbove * -100*/ parseFloat(
          (selection.retracementAbove * -100).toFixed(2)
        )
      : null,
  };

  const handleVolatilityChange = useCallback(
    (value) => {
      selectionDispatch({
        type: "SET_VOLATILITY",
        payload: {
          left: value.left ?? null,
          right: value.right ?? null,
        },
      });
    },
    [selectionDispatch]
  );

  const radioHandler = useCallback(
    (e) => {
      let value = e.target.value;
      if (value === "A") {
        selectionDispatch({
          type: "SET_RATING",
          payload: value,
          event: true,
        });
        if (showAny) {
          dispatch({
            type: "RESET_MACRO_SMART_BETA_SLIDER",
            payload: {
              A: 1,
              B: 1,
              C: 1,
              D: 1,
            },
          });
        }
      }
      if (value === "AB") {
        selectionDispatch({
          type: "SET_RATING",
          payload: value,
          event: true,
        });
      }
      if (value === "any") {
        selectionDispatch({
          type: "SET_RATING",
          payload: "any",
          event: true,
        });
      }
    },
    [dispatch, selectionDispatch, showAny]
  );

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">2</span> Selection rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <Box display={"flex"} alignItems={"center"}>
            <Typography>Rating</Typography>
            <FormControl>
              <RadioGroup onChange={radioHandler} value={selection.rating} row>
                <FormControlLabel
                  value="A"
                  control={<Radio />}
                  label={
                    <Typography>
                      <strong className="rate rate--A">A</strong>
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="AB"
                  control={<Radio />}
                  label={
                    <Typography>
                      <strong className="rate rate--A">A</strong>
                      <strong className="rate rate--B">B</strong>
                    </Typography>
                  }
                />
                {showAny && (
                  <FormControlLabel
                    value="any"
                    control={<Radio />}
                    label={<Typography>Any</Typography>}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </li>
        {showAny ? (
          <CustomFieldCollapser
            isOpen={more ?? false}
            setIsOpen={(e) => {
              dispatch({ type: "MORE_SELECTION_RULES", payload: e });
            }}
          >
            <>
              <li className="form__field">
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>Market capitalization</Typography>
                  <MarketCapFilter
                    initValue={{
                      le: rangeMktValue.right
                        ? rangeMktValue.right / 1000000
                        : null,
                      ge: rangeMktValue.left
                        ? rangeMktValue.left / 1000000
                        : null,
                    }}
                    updateValue={(e) => {
                      let left = e.ge ? e.ge * 1000000 : null;
                      let right = e.le ? e.le * 1000000 : null;
                      selectionDispatch({
                        type: "SET_MARKET_CAP",
                        payload: {
                          left: left,
                          right: right,
                        },
                      });
                    }}
                  />
                </Box>
                {/* <RangeMktCap
                  dojoEvents={{
                    change: (event) => {
                      selectionDispatch({
                        type: "SET_MARKET_CAP",
                        payload: {
                          left: event?.value?.left ?? null,
                          right: event?.value?.right ?? null,
                        },
                      });
                    },
                  }}
                  dojoProps={{
                    field: "marketcap",
                    style: "width: 99%",
                    value: rangeMktValue,
                  }}
                /> */}
              </li>
              <li className="form__field">
                <RangeVolatility
                  handleChange={handleVolatilityChange}
                  initValue={rangeVolatility}
                />
              </li>
              <li className="form__field">
                <div
                  className="form__more"
                  data-dojo-attach-event="collapser-resize:_collapserResize"
                  data-dojo-attach-point="_widgetRebalanceOptions"
                  data-dojo-type="app/ui/form/FieldCollapser"
                >
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Min. liquidity (USD Millions)</Typography>
                    <TextField
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_MIN_LIQUIDITY",
                          payload: e.target.value,
                        });
                      }}
                      sx={{ width: "15%" }}
                      size="small"
                      type="text"
                      value={minLiquidity.ge ?? ""}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label title="Average daily traded value in the last month">
                      Min. liquidity (USD Millions)
                    </label>{" "}
                    <TextBox
                      dojoEvents={{
                        change: (event) => {
                          selectionDispatch({
                            type: "SET_MIN_LIQUIDITY",
                            payload: event,
                          });
                        },
                      }}
                      dojoProps={{
                        style: "width: 15%",
                        type: "text",
                        value: minLiquidity.ge,
                      }}
                    />
                  </div> */}
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>Performance since rated above</Typography>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      size="small"
                      value={pr.ge ?? ""}
                      sx={{ width: "9em" }}
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_PERFORMANCE_SINCE_RATED_ABOVE",
                          payload: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label>Performance since rated above</label>{" "}
                    <NumberTextBox
                      dojoEvents={{
                        change: (event) =>
                          selectionDispatch({
                            type: "SET_PERFORMANCE_SINCE_RATED_ABOVE",
                            payload: event,
                          }),
                      }}
                      dojoProps={{
                        constraints: {
                          places: 2,
                        },
                        style: "width: 4em",
                        type: "text",
                        value: pr.ge,
                      }}
                    />{" "}
                    %
                  </div> */}
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>Retracement above</Typography>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      size="small"
                      title={
                        "Entitled securities retrace less than the given percentage. E.g. 'above 10' means that a securitiy with -9% retracement will be entitled, instead a securitiy with -11% retracement will be discarded"
                      }
                      value={px.ge ?? ""}
                      sx={{ width: "9em" }}
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_RETRACEMENT_ABOVE",
                          payload: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label>Retracement above</label>{" "}
                    <NumberTextBox
                      dojoEvents={{
                        change: (event) =>
                          selectionDispatch({
                            type: "SET_RETRACEMENT_ABOVE",
                            payload: event,
                          }),
                      }}
                      dojoProps={{
                        constraints: {
                          places: 2,
                        },
                        style: "width: 4em",
                        type: "text",
                        title:
                          "Entitled securities retrace less than the given percentage. E.g. 'above 10' means that a securitiy with -9% retracement will be entitled, instead a securitiy with -11% retracement will be discarded",
                        value: px.ge,
                      }}
                    />{" "}
                    %
                  </div> */}
                </div>
              </li>
            </>
          </CustomFieldCollapser>
        ) : (
          <>
            <li className="form__field">
              {/* <label>Market capitalization</label> */}
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>Market capitalization</Typography>
                <MarketCapFilter
                  initValue={{
                    le: rangeMktValue.right
                      ? rangeMktValue.right / 1000000
                      : null,
                    ge: rangeMktValue.left
                      ? rangeMktValue.left / 1000000
                      : null,
                  }}
                  updateValue={(e) => {
                    let left = e.ge ? e.ge * 1000000 : null;
                    let right = e.le ? e.le * 1000000 : null;
                    selectionDispatch({
                      type: "SET_MARKET_CAP",
                      payload: {
                        left: left,
                        right: right,
                      },
                    });
                  }}
                />
              </Box>
              {/* <RangeMktCap
                dojoEvents={{
                  change: (event) =>
                    selectionDispatch({
                      type: "SET_MARKET_CAP",
                      payload: {
                        left: event?.value?.left ?? null,
                        right: event?.value?.right ?? null,
                      },
                    }),
                }}
                dojoProps={{
                  field: "marketcap",
                  style: "width: 99%",
                  value: rangeMktValue,
                }}
              /> */}
            </li>
            <li className="form__field">
              <Box display={"flex"} flexDirection={"column"}>
                <RangeVolatility
                  handleChange={handleVolatilityChange}
                  initValue={rangeVolatility}
                />
              </Box>
            </li>
            <CustomFieldCollapser
              isOpen={more ?? false}
              setIsOpen={(e) => {
                dispatch({ type: "MORE_SELECTION_RULES", payload: e });
              }}
            >
              <li className="form__field">
                <div
                  className="form__more"
                  data-dojo-attach-event="collapser-resize:_collapserResize"
                  data-dojo-attach-point="_widgetRebalanceOptions"
                  data-dojo-type="app/ui/form/FieldCollapser"
                >
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography>Min. liquidity (USD Millions)</Typography>
                    <TextField
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_MIN_LIQUIDITY",
                          payload: e.target.value,
                        });
                      }}
                      sx={{ width: "15%" }}
                      size="small"
                      type="text"
                      value={minLiquidity.ge ?? ""}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label title="Average daily traded value in the last month">
                      Min. liquidity (USD Millions)
                    </label>{" "}
                    <TextBox
                      dojoEvents={{
                        change: (event) =>
                          selectionDispatch({
                            type: "SET_MIN_LIQUIDITY",
                            payload: event,
                          }),
                      }}
                      dojoProps={{
                        style: "width: 15%",
                        type: "text",
                        value: minLiquidity.ge,
                      }}
                    />
                  </div> */}
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>Performance since rated above</Typography>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      size="small"
                      value={pr.ge ?? ""}
                      sx={{ width: "9em" }}
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_PERFORMANCE_SINCE_RATED_ABOVE",
                          payload: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label>Performance since rated above</label>{" "}
                    <NumberTextBox
                      dojoEvents={{
                        change: (event) =>
                          selectionDispatch({
                            type: "SET_PERFORMANCE_SINCE_RATED_ABOVE",
                            payload: event,
                          }),
                      }}
                      dojoProps={{
                        constraints: {
                          places: 2,
                        },
                        style: "width: 4em",
                        type: "text",
                        value: pr.ge,
                      }}
                    />{" "}
                    %
                  </div> */}

                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>Retracement above</Typography>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      size="small"
                      title={
                        "Entitled securities retrace less than the given percentage. E.g. 'above 10' means that a securitiy with -9% retracement will be entitled, instead a securitiy with -11% retracement will be discarded"
                      }
                      value={px.ge ?? ""}
                      sx={{ width: "9em" }}
                      type="number"
                      inputProps={{ step: 0.01 }}
                      onChange={(e) => {
                        selectionDispatch({
                          type: "SET_RETRACEMENT_ABOVE",
                          payload: parseFloat(e.target.value),
                        });
                      }}
                    />
                  </Box>
                  {/* <div className="form__field">
                    <label>Retracement above</label>{" "}
                    <NumberTextBox
                      dojoEvents={{
                        change: (event) =>
                          selectionDispatch({
                            type: "SET_RETRACEMENT_ABOVE",
                            payload: event,
                          }),
                      }}
                      dojoProps={{
                        constraints: {
                          places: 2,
                        },
                        style: "width: 4em",
                        type: "text",
                        title:
                          "Entitled securities retrace less than the given percentage. E.g. 'above 10' means that a securitiy with -9% retracement will be entitled, instead a securitiy with -11% retracement will be discarded",
                        value: px.ge,
                      }}
                    />{" "}
                    %
                  </div> */}
                </div>
              </li>
            </CustomFieldCollapser>
          </>
        )}
      </ul>
    </fieldset>
  );
}
