import { Box, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Common } from "../../../../../../../api/compute/Common";
import { mergePropertiesToObjects } from "../../../../../../../api/compute/commons";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { httpAll } from "../../../../../../../httpAll";
import { _testingDataPrepare } from "./utils";
import StrategiesTab from "./widgets/StrategiesTab";
import Subscribable from "./widgets/Subscribable";

type Props = {
  subscribe: any;
  unsubscribe: Function;
  isPortfolio: boolean | null;
  store?: any;
  setStore: Function;
  type:
    | "list"
    | "ranking"
    | "strategy"
    | "systematicPortfolio"
    | "combinedStrategy"
    | "combinedProduct";
};

export default function TabsWrapper({
  subscribe,
  unsubscribe,
  store,
  isPortfolio,
  setStore,
  type,
}: Props) {
  const [options, setOptions] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const environment = useEnvironment();
  const http = environment.get("http");
  const _dataGet = useCallback(async () => {
    setIsLoading(true);
    let publications: any = null;
    let isRanking = false;
    let isSystematicPortfolio = false;

    switch (type) {
      //!ranking has list(portfolio and basket) as dependency
      //!systematic has strategy as dependency
      case "ranking":
        isRanking = true;
        let _pubRanking = await http["publications"].get({
          type: "ranking",
        });
        let _pubList = await http["publications"].get({
          type: "list",
        });
        publications = [..._pubRanking, ..._pubList];
        break;
      case "systematicPortfolio":
        isSystematicPortfolio = true;
        let _pubStrategy = await http["publications"].get({
          type: "strategy",
        });
        let _pubSystematicPortfolio = await http["publications"].get({
          type: "systematicPortfolio",
        });
        publications = [..._pubStrategy, ..._pubSystematicPortfolio];
        break;
      default:
        publications = await http["publications"].get({
          type: type,
        });
        break;
    }

    const subs = await http["subscriptions"].get({ type: type });
    const _subs = await http["subscriptions"].getDetails("subscriptions", subs);
    var requests = {
      publications: publications,
      subscriptions: _subs,
    };
    httpAll(requests)
      .then(async (response) => {
        let resp = response;
        if (type === "strategy" || type === "systematicPortfolio") {
          resp = await _dataGetTags(response, http, type);
        }
        if (type === "combinedProduct" || type === "combinedStrategy") {
          resp = await _dataGetTagsCombined(
            response,
            http,
            type,
            environment.get("setup")
          );
        }

        _testingDataPrepare(
          resp,
          setOptions,
          setStore,
          isPortfolio,
          isRanking,
          isSystematicPortfolio,
          type
        );
      })
      .then(() => setIsLoading(false));
  }, [environment, http, isPortfolio, setStore, type]);

  useEffect(() => {
    _dataGet();
  }, [_dataGet]);

  const widgetToRender = useMemo(() => {
    if (
      type === "strategy" ||
      type === "systematicPortfolio" ||
      type === "combinedStrategy" ||
      type === "combinedProduct"
    ) {
      return (
        <StrategiesTab
          key={uuidv4()}
          unsubscribe={unsubscribe}
          subscribe={subscribe}
          store={store}
          pubSubType={type}
          callback={_dataGet}
        />
      );
    }
    return (
      <Subscribable
        unsubscribe={(id) => unsubscribe(id, _dataGet)}
        subscribe={(arg) =>
          subscribe(arg).then(() => {
            _dataGet();
          })
        }
        input={options}
      />
    );
  }, [_dataGet, options, store, subscribe, type, unsubscribe]);

  return (
    <Box width={"100%"} height={"100%"}>
      {isLoading ? (
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        widgetToRender
      )}
    </Box>
  );
}

const _dataGetTags = (
  response,
  http,
  type: "strategy" | "systematicPortfolio"
) => {
  function mapId(item) {
    return item.id;
  }

  var publications = deepClone(response.publications);

  const key = type === "strategy" ? "strategies" : "systematicProducts";

  var requests = {
    temp: http[key].getTags(publications.map(mapId)),
  };

  return httpAll(requests).then(function (tagsResponse) {
    if (type === "strategy") {
      publications = mergePropertiesToObjects({
        properties: ["tags"],
        source: tagsResponse.temp,
        target: publications,
      });
    } else if (type === "systematicPortfolio") {
      publications = mergePropertiesToObjects({
        properties: ["strategyId", "strategyName", "tags"],
        source: tagsResponse.temp,
        target: publications,
      });
    }

    return {
      publications: publications,
      subscriptions: response.subscriptions,
    };
  }, publications);
};

const _dataGetTagsCombined = async (
  response,
  http,
  type: "combinedProduct" | "combinedStrategy",
  environment
) => {
  function mapId(item) {
    return item.id;
  }
  var publications = deepClone(response.publications);
  const TYPE =
    type === "combinedStrategy" ? "COMBINED_STRATEGY" : "COMBINED_PRODUCT";
  const common = new Common(environment);
  const resp = await common.fetch({
    classType: TYPE,
    ids: publications.map(mapId),
    properties: ["name", "tags"],
  });
  publications = mergePropertiesToObjects({
    properties: ["tags"],
    source: resp,
    target: publications,
  });
  return {
    publications: publications,
    subscriptions: response.subscriptions,
  };
};
