import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import OrientationButton from "./widgets/OrientationButton";

type Props = {
  onChangeOrientation: Function;
  initValue: "portrait" | "landscape";
};

export default function Orientation({ onChangeOrientation, initValue }: Props) {
  const init = useMemo(() => {
    if (initValue) {
      return initValue;
    }
    return "portrait";
  }, [initValue]);
  const radioHandler = useCallback(
    (e) => {
      onChangeOrientation(e.target.value);
    },
    [onChangeOrientation]
  );
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{p:1, pb: "8px !important" }}>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="tr_subtitle_blue">Orientation:</Typography>
          <Box display={"flex"} gap={2}>
            <RadioGroup
              onChange={radioHandler}
              value={init}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={"portrait"}
                label={
                  <OrientationButton
                    imgURL={
                      "/js/trendrating-report/resources/images/page_portrait.png"
                    }
                    title={"Portrait"}
                  />
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={"landscape"}
                label={
                  <OrientationButton
                    imgURL={
                      "/js/trendrating-report/resources/images/page_landscape.png"
                    }
                    title={"Landscape"}
                  />
                }
              />
            </RadioGroup>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
