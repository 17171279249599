/* eslint-disable no-template-curly-in-string */
export const defaultTemplateReportPortfolio = [
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_COVER",
          content: {
            subTitle: "",
            title: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: false,
            },
            marketAllocation: "MARKET",
            marketRows: 8,
            sectorAllocation: "SECTOR",
            sectorRows: 8,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: true,
            marketAllocation: false,
            sectorAllocation: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_ALLOCATION",
          content: {
            firstRows: 11,
            firstType: "MARKET",
            headline: {
              content: "",
              isEnabled: false,
            },
            secondRows: 12,
            secondType: "INDUSTRY",
          },
          presentation: {
            colorByPosition: false,
            first: true,
            second: true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_RATING_CHANGE",
          content: {
            headline: {
              content: "Rating Changes",
              isEnabled: true,
            },
            numberOfItems: 5,
          },
          presentation: {
            downgrade: true,
            upgrade: true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          content: {
            headline: {
              content: "New highs / New lows",
              isEnabled: true,
            },
            numberOfItems: 5,
          },
          presentation: {
            newHigh: true,
            newLow: true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "A&B Top performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "POSITIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "C&D Bottom performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "NEGATIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "weight",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Market",
                property: "country",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Summary Report",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_COVER",
          content: {
            subTitle: "",
            title: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: true,
            },
            marketAllocation: "MARKET",
            marketRows: 5,
            sectorAllocation: "INDUSTRY",
            sectorRows: 5,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: true,
            marketAllocation: false,
            sectorAllocation: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_ALLOCATION",
          content: {
            headline: {
              content: "",
              isEnabled: false,
            },
            firstRows: 11,
            firstType: "MARKET",
            secondType: "INDUSTRY",
            secondRows: 12,
          },
          presentation: {
            first: true,
            second: true,
            colorByPosition: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_RATING_CHANGE",
          content: {
            headline: {
              content: "Rating Changes",
              isEnabled: true,
            },
            numberOfItems: 5,
          },
          presentation: {
            downgrade: true,
            upgrade: true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          content: {
            headline: {
              content: "New highs / New lows",
              isEnabled: true,
            },
            numberOfItems: 5,
          },
          presentation: {
            newHigh: true,
            newLow: true,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "A&B Top performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "POSITIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "C&D Bottom performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "NEGATIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "weight",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Summary Report",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: false,
            },
            marketAllocation: "MARKET",
            marketRows: 5,
            sectorAllocation: "INDUSTRY",
            sectorRows: 5,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: false,
            marketAllocation: false,
            sectorAllocation: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_ALLOCATION",
          content: {
            headline: {
              content: "",
              isEnabled: false,
            },
            firstRows: 11,
            firstType: "MARKET",
            secondType: "INDUSTRY",
            secondRows: 12,
          },
          presentation: {
            first: true,
            second: true,
            colorByPosition: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN",
          content: {
            dataCross: "MARKET_INDUSTRY",
            headline: {
              content: "Breakdown",
              isEnabled: true,
            },
            isBreadth: true,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Allocation",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "weight",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            useWysiwyg: false,
            rank: false,
          },
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
          isEdited: false,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Detailed Holdings",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "weight",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
            rate: {},
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Duration",
                property: "duration",
              },
              {
                label: "Magnitude",
                property: "magnitude",
              },
              {
                label: "UPI",
                property: "upi",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: false,
            useWysiwyg: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Holdings - Print current view",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_RATING_CHANGE",
          content: {
            headline: {
              content: "Rating Changes",
              isEnabled: true,
            },
            numberOfItems: 10,
          },
          presentation: {
            downgrade: true,
            upgrade: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: {
              property: "dr",
              descending: true,
            },
            top: {
              content: 20,
              isEnabled: true,
            },
          },
          presentation: {
            columns: [
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "New High-Low",
                property: "lhl",
              },
              {
                label: "Since rated",
                property: "pr",
              },
              {
                label: "Last week",
                property: "pw",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: false,
            sortByRank: false,
            useWysiwyg: false,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: false,
    name: "Last 10 Upgrades/Downgrades",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
];
