// const marketcap_collection = {
//   rangeMarketCap: (
//     <section className="" data-help-topic="marketcap|rangeMarketCap">
//       <h3>Market cap</h3>
//       <p>Market cap in range: limit the investment universe by ranges of market capitalization.</p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> Important
//         </div>
//         <p>Consider to select larger size stocks for consistent but lower returns.</p>
//       </div>
//       <p>
//         Smaller-cap stocks historically generated higher risk-adjusted returns, you should expect
//         the size premium to be material and positive in the long run, it is less predictable in the
//         short term.
//       </p>
//     </section>
//   ),
//   greaterThan: (
//     <section className="" data-help-topic="marketcap|greaterThan">
//       <h3>Market cap</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of mkt cap. Only securities
//         above the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   lessThan: (
//     <section className="" data-help-topic="marketcap|lessThan">
//       <h3>Market cap</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of mkt cap. Only securities
//         below the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="marketcap|top">
//       <h3>Market cap</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by mkt cap. The default
//         value is 10. This means that only the top 10 highest mkt cap securities are included in the
//         investment universe.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="marketcap|bottom">
//       <h3>Market cap</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by mkt cap. The
//         default value is 10. This means that only the lowest 10 mkt cap securities are included in
//         the investment universe.
//       </p>
//     </section>
//   ),
// };
// const mc_collection = {
//   greaterThan: (
//     <section className="" data-help-topic="mc|greaterThan">
//       <h3>Smart Momentum - Value</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of smart momentum. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> How it works
//         </div>
//         <table>
//           <thead>
//             <tr>
//               <th>Rating</th>
//               <th>Range</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--A">A</span>
//               </td>
//               <td>2.0 and 3.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--B">B</span>
//               </td>
//               <td>1.0 and 2.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--C">C</span>
//               </td>
//               <td>-1.0 and -2.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--D">D</span>
//               </td>
//               <td>-2.0 and -3.0</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </section>
//   ),
//   lessThan: (
//     <section className="" data-help-topic="mc|lessThan">
//       <h3>Smart Momentum - Value</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of smart momentum. Only
//         securities below the entered value are included in the investment universe.
//       </p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> How it works
//         </div>
//         <table>
//           <thead>
//             <tr>
//               <th>Rating</th>
//               <th>Range</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--A">A</span>
//               </td>
//               <td>2.0 and 3.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--B">B</span>
//               </td>
//               <td>1.0 and 2.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--C">C</span>
//               </td>
//               <td>-1.0 and -2.0</td>
//             </tr>
//             <tr>
//               <td>
//                 <span className="format-rate format-rate--D">D</span>
//               </td>
//               <td>-2.0 and -3.0</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </section>
//   ),
//   range: (
//     <section className="" data-help-topic="mc|range">
//       <h3>Smart Momentum - Value</h3>
//       <p>
//         Between: limit the investment universe by separating the universe with one or two
//         segmentation of smart momentum.
//       </p>
//       <table className="tHelpItemTable">
//         <thead>
//           <tr>
//             <th>Rating</th>
//             <th>Perf. since trend</th>
//             <th>Smart Momentum score</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--A">500%</span>
//             </td>
//             <td>3.000</td>
//           </tr>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--A">100%</span>
//             </td>
//             <td>2.333</td>
//           </tr>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--A">50%</span>
//             </td>
//             <td>2.250</td>
//           </tr>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--A">10%</span>
//             </td>
//             <td>2.183</td>
//           </tr>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--A">0%</span>
//             </td>
//             <td>
//               <strong>2.167</strong>
//             </td>
//           </tr>
//           <tr>
//             <td>A</td>
//             <td>
//               <span className="format-rate format-rate--D">-10%</span>
//             </td>
//             <td>2.150</td>
//           </tr>
//         </tbody>
//       </table>
//       <table className="tHelpItemTable">
//         <thead>
//           <tr>
//             <th>Rating</th>
//             <th>Perf. since trend</th>
//             <th>Smart Momentum score</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>D</td>
//             <td>
//               <span className="format-rate format-rate--A">20%</span>
//             </td>
//             <td>-2.800</td>
//           </tr>
//           <tr>
//             <td>D</td>
//             <td>
//               <span className="format-rate">0%</span>
//             </td>
//             <td>-2.833</td>
//           </tr>
//           <tr>
//             <td>D</td>
//             <td>
//               <span className="format-rate format-rate--D">-20%</span>
//             </td>
//             <td>-2.867</td>
//           </tr>
//           <tr>
//             <td>D</td>
//             <td>
//               <span className="format-rate format-rate--D">-50%</span>
//             </td>
//             <td>-2.917</td>
//           </tr>
//           <tr>
//             <td>D</td>
//             <td>
//               <span className="format-rate format-rate--D">-100%</span>
//             </td>
//             <td>
//               <strong>-3.000</strong>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="mc|top">
//       <h3>Smart Momentum - Value</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by smart momentum. The
//         default value is 10. This means that the investment universe is composed only of the 10
//         securities with the highest smart momentum.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="mc|bottom">
//       <h3>Smart Momentum - Value</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by smart momentum.
//         The default value is 10. This means that the investment universe is composed only of the 10
//         securities with the lowest smart momentum.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="mc|rangeQuartile">
//       <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         Smart momentum score. Securities with the highest SM scores reside in Quantiles 1.By
//         selecting the 1 st quantile, the investment universe is composed only of the securities
//         belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="mc|rangeQuintile">
//       <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         Smart momentum score. Securities with the highest SM scores reside in Quantiles 1.By
//         selecting the 1 st quantile, the investment universe is composed only of the securities
//         belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="mc|rangeDecile">
//       <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         Smart momentum score. Securities with the highest SM scores reside in Quantiles 1.By
//         selecting the 1 st quantile, the investment universe is composed only of the securities
//         belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
// const sd_collection = {
//   rangeVolatility: (
//     <section className="" data-help-topic="sd|rangeVolatility">
//       <h3>Volatility: Value</h3>
//       <p>
//         Volatility is the annualized standard deviation of rolling 20 days returns.
//         <br />
//         Volatility in range: narrow the investment universe by ranges of volatility. Low &lt; 10%
//         Mid (10%- 50%) High &gt; 50%.
//       </p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> Important
//         </div>
//         <p>
//           Select stocks with volatility bellow 30% if you want a less risky strategy. Avoiding high
//           volatile stocks can limit the performance of the strategy during strong bull trends.
//         </p>
//       </div>
//     </section>
//   ),
//   greaterThanPercentage: (
//     <section className="" data-help-topic="sd|greaterThanPercentage">
//       <h3>Volatility: Value</h3>
//       <p>
//         Volatility is the annualized standard deviation of rolling 20 days returns.
//         <br />
//         Is greater than: limit the investment universe by adding a value of volatility. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   lessThanPercentage: (
//     <section className="" data-help-topic="sd|lessThanPercentage">
//       <h3>Volatility: Value</h3>
//       <p>
//         Volatility is the annualized standard deviation of rolling 20 days returns.
//         <br />
//         Is less than: limit the investment universe by adding a value of volatility. Only securities
//         below the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="sd|top">
//       <h3>Volatility: Value</h3>
//       <p>
//         Volatility is the annualized standard deviation of rolling 20 days returns.
//         <br />
//         Top: limit the investment universe by taking the Top "n" securities by volatility. The
//         default value is 10. This means that the investment universe is composed only of the 10
//         securities with the highest volatility.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="sd|bottom">
//       <h3>Volatility: Value</h3>
//       <p>
//         Volatility is the annualized standard deviation of rolling 20 days returns.
//         <br />
//         Bottom: limit the investment universe by taking the Bottom "n" securities by volatility. The
//         default value is 10. This means that the investment universe is composed only of the 10
//         securities with the lowest volatility.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="sd|rangeQuartile">
//       <h3>Volatility: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         volatility. Securities with the highest volatility reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="sd|rangeQuintile">
//       <h3>Volatility: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         volatility. Securities with the highest volatility reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="sd|rangeDecile">
//       <h3>Volatility: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         volatility. Securities with the highest volatility reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
// const px_collection = {
//   greaterThanPercentage: (
//     <section className="" data-help-topic="px|greaterThanPercentage">
//       <h3>Retracement: Value</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of retracement. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> Trendrating's recommendation
//         </div>
//         <p>
//           It is a good rule to define a retracement constraint no larger than a certain percentage,
//           for example no less than -15%
//         </p>
//       </div>
//     </section>
//   ),
//   lessThanPercentage: (
//     <section className="" data-help-topic="px|lessThanPercentage">
//       <h3>Retracement: Value</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of retracement. Only
//         securities below the entered value are included in the investment universe.
//       </p>
//       <div className="tHelp-itemTips">
//         <div className="tHelp-itemTips-top">
//           <span className="i-edu"></span> Trendrating's recommendation
//         </div>
//         <p>
//           It is a good rule to define a retracement constraint no larger than a certain percentage,
//           for example no less than -15%
//         </p>
//       </div>
//     </section>
//   ),
//   rangePercentage: (
//     <section className="" data-help-topic="px|rangePercentage">
//       <h3>Retracement: Value</h3>
//       <p>
//         Between: limit the investment universe by separating the universe with one or two
//         segmentation of retracement.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="px|top">
//       <h3>Retracement: Value</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by retracement. The
//         default value is 10. This means that the investment universe is composed only of the 10
//         securities with the highest retracement.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="px|bottom">
//       <h3>Retracement: Value</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by retracement.
//         The default value is 10. This means that the investment universe is composed only of the 10
//         securities with the lowest retracement.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="px|rangeQuartile">
//       <h3>Retracement: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         retracement. Securities with the highest retracement reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="px|rangeQuintile">
//       <h3>Retracement: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         retracement. Securities with the highest retracement reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="px|rangeDecile">
//       <h3>Retracement: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         retracement. Securities with the highest retracement reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
// const pr_collection = {
//   greaterThanPercentage: (
//     <section className="" data-help-topic="pr|greaterThanPercentage">
//       <h3>Performance since rated</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of performance. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   lessThanPercentage: (
//     <section className="" data-help-topic="pr|lessThanPercentage">
//       <h3>Performance since rated</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of performance. Only
//         securities below the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   rangePercentage: (
//     <section className="" data-help-topic="pr|rangePercentage">
//       <h3>Performance since rated</h3>
//       <p>
//         Between: limit the investment universe by separating the universe with one or two
//         segmentation of perf. since rated.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="pr|top">
//       <h3>Performance since rated</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by perf. since rated.
//         The default value is 10. This means that the investment universe is composed only of the 10
//         securities with the highest perf. since rated.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="pr|bottom">
//       <h3>Performance since rated</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by perf. since
//         rated. The default value is 10. This means that the investment universe is composed only of
//         the 10 securities with the lowest perf. since rated.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="pr|rangeQuartile">
//       <h3>Performance since rated: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since rated. Securities with the highest perf. since rated reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="pr|rangeQuintile">
//       <h3>Performance since rated: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since rated. Securities with the highest perf. since rated reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="pr|rangeDecile">
//       <h3>Performance since rated: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since rated. Securities with the highest perf. since rated reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
// const py_collection = {
//   greaterThanPercentage: (
//     <section className="" data-help-topic="py|greaterThanPercentage">
//       <h3>Performance last 12 months</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of performance. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   lessThanPercentage: (
//     <section className="" data-help-topic="py|lessThanPercentage">
//       <h3>Performance last 12 months</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of performance. Only
//         securities below the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   rangePercentage: (
//     <section className="" data-help-topic="py|rangePercentage">
//       <h3>Performance last 12 months</h3>
//       <p>
//         Between: limit the investment universe by separating the universe with one or two
//         segmentation of perf. over the last 12 months.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="py|top">
//       <h3>Performance last 12 months</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by perf. over the last
//         12 months. The default value is 10. This means that the investment universe is composed only
//         of the 10 securities with the highest perf. over the last 12 months.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="py|bottom">
//       <h3>Performance last 12 months</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by perf. over the
//         last 12 months. The default value is 10. This means that the investment universe is composed
//         only of the 10 securities with the lowest perf. over the last 12 months.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="py|rangeQuartile">
//       <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance over the last 12 months. Securities with the highest perf. over the last 12
//         months reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="py|rangeQuintile">
//       <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance over the last 12 months. Securities with the highest perf. over the last 12
//         months reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="py|rangeDecile">
//       <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance over the last 12 months. Securities with the highest perf. over the last 12
//         months reside in Quantiles 1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
// const pt_collection = {
//   greaterThanPercentage: (
//     <section className="" data-help-topic="pt|greaterThanPercentage">
//       <h3>Performance since trend</h3>
//       <p>
//         Is greater than: limit the investment universe by adding a value of performance. Only
//         securities above the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   lessThanPercentage: (
//     <section className="" data-help-topic="pt|lessThanPercentage">
//       <h3>Performance since trend</h3>
//       <p>
//         Is less than: limit the investment universe by adding a value of performance. Only
//         securities below the entered value are included in the investment universe.
//       </p>
//     </section>
//   ),
//   rangePercentage: (
//     <section className="" data-help-topic="pt|rangePercentage">
//       <h3>Performance since trend</h3>
//       <p>
//         Between: limit the investment universe by separating the universe with one or two
//         segmentation of perf. since trend.
//       </p>
//     </section>
//   ),
//   top: (
//     <section className="" data-help-topic="pt|top">
//       <h3>Performance since trend</h3>
//       <p>
//         Top: limit the investment universe by taking the Top "n" securities by perf. since trend.
//         The default value is 10. This means that the investment universe is composed only of the 10
//         securities with the highest perf. since trend.
//       </p>
//     </section>
//   ),
//   bottom: (
//     <section className="" data-help-topic="pt|bottom">
//       <h3>Performance since trend</h3>
//       <p>
//         Bottom: limit the investment universe by taking the Bottom "n" securities by perf. since
//         trend. The default value is 10. This means that the investment universe is composed only of
//         the 10 securities with the lowest perf. since trend.
//       </p>
//     </section>
//   ),
//   rangeQuartile: (
//     <section className="" data-help-topic="pt|rangeQuartile">
//       <h3>Performance since trend: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since trend. Securities with the highest perf. since trend reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeQuintile: (
//     <section className="" data-help-topic="pt|rangeQuintile">
//       <h3>Performance since trend: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since trend. Securities with the highest perf. since trend reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
//   rangeDecile: (
//     <section className="" data-help-topic="pt|rangeDecile">
//       <h3>Performance since trend: Quartile – Quintile - Decile</h3>
//       <p>
//         The quantiles method divides the universe into the number of selected quantiles using the
//         performance since trend. Securities with the highest perf. since trend reside in Quantiles
//         1.
//         <br />
//         By selecting the 1<sup>st</sup> quantile, the investment universe is composed only of the
//         securities belonging to the first quantile.
//       </p>
//     </section>
//   ),
// };
const ts_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Trend strength - Highest to lowest</h3>
        <p>Securities will be ranked from highest to lowest Trend strength.</p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Trend strength - Lowest to highest</h3>
        <p>Securities will be ranked from lowest to higher Trend strength.</p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Trend strength - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Trend strength. Securities with the highest Trend strength
          reside in Quantiles 1. The securities belonging in the top quantile
          will be the first selected for final portfolio inclusion, followed by
          the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Trend strength - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Trend strength. Securities with the highest Trend strength
          reside in Quantiles 1. The securities belonging in the bottom
          quantiles will be the first selected for final portfolio inclusion,
          followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_pe_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Earnings Ratio - Highest to lowest</h3>
        <p>Securities will be ranked from highest to lowest P/E ratio.</p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Earnings Ratio - Lowest to highest</h3>
        <p>Securities will be ranked from lowest to higher P/E ratio.</p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Earnings Ratio - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using P/E ratio. Securities with the highest P/E ratio reside in
          Quantiles 1. The securities belonging in the top quantile will be the
          first selected for final portfolio inclusion, followed by the
          securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Earnings Ratio - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using P/E ratio. Securities with the highest P/E ratio reside in
          Quantiles 1. The securities belonging in the bottom quantiles will be
          the first selected for final portfolio inclusion, followed by the
          securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_ps_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Sales Ratio - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Price/Sales ratio.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Sales Ratio - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Price/Sales ratio.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Sales Ratio - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Sales ratio. Securities with the highest Price/Sales ratio
          reside in Quantiles 1. The securities belonging in the top quantile
          will be the first selected for final portfolio inclusion, followed by
          the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Sales Ratio - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Sales ratio. Securities with the highest Price/Sales ratio
          reside in Quantiles 1. The securities belonging in the bottom
          quantiles will be the first selected for final portfolio inclusion,
          followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_pb_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Price/BookValue Ratio - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Price/BookValue
          ratio.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Price/BookValue Ratio - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Price/BookValue ratio.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Price/BookValue Ratio - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/BookValue ratio. Securities with the highest
          Price/BookValue ratio reside in Quantiles 1. The securities belonging
          in the top quantile will be the first selected for final portfolio
          inclusion, followed by the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Price/BookValue Ratio - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/BookValue ratio. Securities with the highest
          Price/BookValue ratio reside in Quantiles 1. The securities belonging
          in the bottom quantiles will be the first selected for final portfolio
          inclusion, followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_pc_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Cashflow Ratio - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Price/Cashflow ratio.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Cashflow Ratio - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Price/Cashflow ratio.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Cashflow Ratio - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Cashflow ratio. Securities with the highest Price/Cashflow
          ratio reside in Quantiles 1. The securities belonging in the top
          quantile will be the first selected for final portfolio inclusion,
          followed by the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Cashflow Ratio - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Cashflow ratio. Securities with the highest Price/Cashflow
          ratio reside in Quantiles 1. The securities belonging in the bottom
          quantiles will be the first selected for final portfolio inclusion,
          followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_pd_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="f_pd|sortByPerformance|desc">
        <h3>Price/Dividends Ratio - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Price/Dividends
          ratio.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="f_pd|sortByPerformance|asc">
        <h3>Price/Dividends Ratio - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Price/Dividends ratio.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Price/Dividends Ratio - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Dividends ratio. Securities with the highest
          Price/Dividends ratio reside in Quantiles 1. The securities belonging
          in the top quantile will be the first selected for final portfolio
          inclusion, followed by the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Price/Dividends Ratio - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Price/Dividends ratio. Securities with the highest
          Price/Dividends ratio reside in Quantiles 1. The securities belonging
          in the bottom quantiles will be the first selected for final portfolio
          inclusion, followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_sps_CC_g_3_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Sales 3 Months Growth Rate - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Sales Growth Rate.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Sales 3 Months Growth Rate - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Sales Growth Rate.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Sales 3 Months Growth Rate - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Sales Growth Rate. Securities with the highest Sales Growth Rate
          reside in Quantiles 1. The securities belonging in the top quantile
          will be the first selected for final portfolio inclusion, followed by
          the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="f_sps_CC_g_3|sortByQuantile|desc">
        <h3>Sales 3 Months Growth Rate - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Sales Growth Rate. Securities with the highest Sales Growth Rate
          reside in Quantiles 1. The securities belonging in the bottom
          quantiles will be the first selected for final portfolio inclusion,
          followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_sps_CC_g_12_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Sales 12 Months Growth Rate - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Sales Growth Rate.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Sales 12 Months Growth Rate - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Sales Growth Rate.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Sales 12 Months Growth Rate - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Sales Growth Rate. Securities with the highest Sales Growth Rate
          reside in Quantiles 1. The securities belonging in the top quantile
          will be the first selected for final portfolio inclusion, followed by
          the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Sales 12 Months Growth Rate - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Sales Growth Rate. Securities with the highest Sales Growth Rate
          reside in Quantiles 1. The securities belonging in the bottom
          quantiles will be the first selected for final portfolio inclusion,
          followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_eps_CC_g_3_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Earnings 3 Months Growth Rate - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Earnings Growth Rate.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Earnings 3 Months Growth Rate - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Earnings Growth Rate.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Earnings 3 Months Growth Rate - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Earnings Growth Rate. Securities with the highest Earnings
          Growth Rate reside in Quantiles 1. The securities belonging in the top
          quantile will be the first selected for final portfolio inclusion,
          followed by the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Earnings 3 Months Growth Rate - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Earnings Growth Rate. Securities with the highest Earnings
          Growth Rate reside in Quantiles 1. The securities belonging in the
          bottom quantiles will be the first selected for final portfolio
          inclusion, followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
const f_eps_CC_g_12_collection = {
  sortByPerformance: {
    desc: (
      <section data-help-topic="desc">
        <h3>Earnings 12 Months Growth Rate - Highest to lowest</h3>
        <p>
          Securities will be ranked from highest to lowest Earnings Growth Rate.
        </p>
      </section>
    ),
    asc: (
      <section data-help-topic="asc">
        <h3>Earnings 12 Months Growth Rate - Lowest to highest</h3>
        <p>
          Securities will be ranked from lowest to higher Earnings Growth Rate.
        </p>
      </section>
    ),
  },
  sortByQuantile: {
    asc: (
      <section data-help-topic="asc">
        <h3>Earnings 12 Months Growth Rate - Top quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Earnings Growth Rate. Securities with the highest Earnings
          Growth Rate reside in Quantiles 1. The securities belonging in the top
          quantile will be the first selected for final portfolio inclusion,
          followed by the securities belonging to lower quantiles.
        </p>
      </section>
    ),
    desc: (
      <section data-help-topic="desc">
        <h3>Earnings 12 Months Growth Rate - Bottom quantile first</h3>
        <p>
          Quantile: specify the number on quantiles. The quantiles method
          divides the valid securities into the number of desired quantiles
          using Earnings Growth Rate. Securities with the highest Earnings
          Growth Rate reside in Quantiles 1. The securities belonging in the
          bottom quantiles will be the first selected for final portfolio
          inclusion, followed by the securities belonging to higher quantiles.
        </p>
      </section>
    ),
  },
};
export const helpTextobjs = {
  RANKING_RULES: {
    init: (
      <>
        <h3>Ranking rules</h3>
        <p>
          This panel enables you to specify the rules that will be employed to
          rank the securities included in the investment universe.
        </p>

        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Get started
          </div>
          <p>Choose one of the factors listed on the left.</p>
        </div>
      </>
    ),
    exists: (
      <section>
        <h3>Existing positions</h3>
        <p>
          Specify whether securities that are currently in the portfolio as well
          as in the list of entitled securities, are to have priority in the
          ranking criterion:
        </p>
        <ul>
          <li>
            At the top: Existing positions will be the first selections during
            rebalancing
          </li>
          <li>
            At the bottom: Existing positions will be the last selections during
            rebalancing
          </li>
        </ul>
      </section>
    ),
    rc: (
      <section className="">
        <h3>Rating</h3>
        <p>
          The Rating identifies the absolute directional trend of each security.
          The rating scale ranges from
          <span className="format-rate format-rate--A">A</span> through
          <span className="format-rate format-rate--D">D</span>, where ratings
          of
          <span className="format-rate format-rate--A">A</span> and
          <span className="format-rate format-rate--B">B</span> signal an
          uptrend whilst ratings of
          <span className="format-rate format-rate--C">C</span> and
          <span className="format-rate format-rate--D">D</span> signal a
          downtrend. <span className="format-rate format-rate--B">B</span>{" "}
          rating denote starting trends while a rating of
          <span className="format-rate format-rate--A">A</span>
          confirms the uptrend.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            Select stocks with rating
            <span className="format-rate format-rate--A">A</span> or
            <span className="format-rate format-rate--B">B</span> avoiding
            rating of <span className="format-rate format-rate--C">C</span> and
            <span className="format-rate format-rate--D">D</span>.
            <span className="tHelp-itemTips-bottom"></span>
          </p>
        </div>
      </section>
    ),
    tradedValue: (
      <section>
        <h3>Liquidity (USD Millions)</h3>
        <p>
          Liquidity is the average traded value in USD Millions in the last
          month. Set a liquidity constraint in order to guarantee the
          executability of the strategy.
        </p>

        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            The best practice is to ask for liquidity over 3 time the AUM/number
            of positions, this this will allow to buy or sell a single position
            on the day of rebalancing.
          </p>
        </div>
      </section>
    ),
    marketcap: (
      <section>
        <h3>Market cap (USD Millions)</h3>
        <p>Ranking preference in terms of market capitalisation</p>
        <ul>
          <li>Largest to Smallest: Prefer large caps</li>
          <li>Smallest to Largest: Prefer small caps</li>
        </ul>
      </section>
    ),
    mc: (
      <section>
        <h3>Smart Momentum</h3>
        <p>
          Smart Momentum combines performance and trend direction all together
          when modelling the momentum factor. The smart momentum span in the
          range -3.0 , 3.0. Positive values denote up trends while negative
          values are for down trends. Stronger positive trends have higher smart
          momentum than positive weaker trends.
        </p>

        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> How it works
          </div>
          <table>
            <thead>
              <tr>
                <th>Rating</th>
                <th>Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="format-rate format-rate--A">A</span>
                </td>
                <td>2.0 and 3.0</td>
              </tr>
              <tr>
                <td>
                  <span className="format-rate format-rate--B">B</span>
                </td>
                <td>1.0 and 2.0</td>
              </tr>
              <tr>
                <td>
                  <span className="format-rate format-rate--C">C</span>
                </td>
                <td>-1.0 and -2.0</td>
              </tr>
              <tr>
                <td>
                  <span className="format-rate format-rate--D">D</span>
                </td>
                <td>-2.0 and -3.0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    ),
    sd: (
      <section>
        <h3>Volatility</h3>
        <p>
          Volatility is the annualized standard deviation of rolling 20 days
          returns. Volatility under 10% is very low, where stocks sowing
          volatility over 50% are very volatile. While volatility can fuel
          spikes in a stock’s price, it can result in plunges as well. Over the
          long term, it can be harder for a high volatility stock to make back
          what it’s lost.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Important
          </div>
          <p>
            Select stocks with volatility bellow 30% if you want a less risky
            strategy. Avoiding high volatile stocks can limit the performance of
            the strategy during strong bull trends.
          </p>
        </div>
      </section>
    ),
    sdlt: (
      <section>
        <h3>Volatility</h3>
        <p>
          Volatility&#40;Long Term&#41; is the annualized standard deviation of
          rolling weekly return of the last 3 years. Volatility under 10% is
          very low, where stocks sowing volatility over 50% are very volatile.
          While volatility can fuel spikes in a stock’s price, it can result in
          plunges as well. Over the long term, it can be harder for a high
          volatility stock to make back what it’s lost.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Important
          </div>
          <p>
            Select stocks with volatility bellow 30% if you want a less risky
            strategy. Avoiding high volatile stocks can limit the performance of
            the strategy during strong bull trends.
          </p>
        </div>
      </section>
    ),
    px: (
      <section>
        <h3>Retracement</h3>
        <p>
          Retracement is the return in the opposite direction from the highest
          price to today during positive trends and from lower price on down
          trends. Smaller retracements indicate that the trend is making new
          highs, while retrospectively highs often anticipate a change of
          direction. To discard stocks that could soon reverse the trend.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            It is a good rule to define a retracement constraint no larger than
            a certain percentage, for example no less than -15%
          </p>
        </div>
      </section>
    ),
    pr: (
      <section>
        <h3>Performance since rated</h3>
        <p>
          The performance since rated is the performance from when the current
          rating has been assigned. It is a measure of the magnitude of the
          fraction of the trend from when it has been detected. Too recent
          ratings may in some cases be reversed due to volatile market
          conditions.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            Ratings that have already shown significant performance are more
            likely to continue. However, trends with high performance will
            sooner or later end.
          </p>
        </div>
      </section>
    ),
    pt: (
      <section>
        <h3>Performance since trend</h3>
        <p>
          The performance since trend is the performance from the beginning of
          the trend regardless of when the current rating has been assigned.
          Define a performance constraint greater than a certain threshold if
          you want to enter a trend that is already in existence or, on the
          contrary, less than a certain value if you do not want to invest in
          already consumed trends.
        </p>
      </section>
    ),
    py: (
      <section>
        <h3>Performance last 12 months</h3>
        <p>
          The performance of the last 12 months is the naive momentum factor. It
          is quite erratic, as a fixed time window of twelve months can
          introduce an important delay in the evaluation of a new trend. A
          constraint that imposes the performance of the last year less than a
          certain maximum threshold or a certain minimum value can however be
          useful to avoid entering long-term trends that still have a
          significant performance from the start but that are worsening over the
          period most recent.
        </p>
      </section>
    ),
    lr: (
      <section>
        <h3>Days since rating</h3>
        <p>
          The number of days from the date when the Trend Capture model
          identified the directional trend and assigned the prevailing rating.
        </p>
      </section>
    ),
    lt: (
      <section>
        <h3>Days since trend</h3>
        <p>
          The number of days from the date when the prevailing trend actually
          commenced
        </p>
      </section>
    ),
    ticker: (
      <section>
        <h3>Ticker</h3>
        <p>
          Ranking preference on ticker based on alphabetical ordering either
          ascending or descending.
        </p>
      </section>
    ),
    ts: ts_collection,
    f_pe: f_pe_collection,
    f_ps: f_ps_collection,
    f_pb: f_pb_collection,
    f_pc: f_pc_collection,
    f_pd: f_pd_collection,
    f_sps_CC_g_3: f_sps_CC_g_3_collection,
    f_sps_CC_g_12: f_sps_CC_g_12_collection,
    f_eps_CC_g_3: f_eps_CC_g_3_collection,
    f_eps_CC_g_12: f_eps_CC_g_12_collection,
  },
};
