import { useMemo } from "react";
import { FilterTaxonomies } from "../../../../../../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { useTaxonomyByType } from "../../../../../../../../../../../../hooks/useTaxonomyByType";
import { deepClone } from "../../../../../../../../../../../../deepClone";

type SectorSelectorType = {
  segments: any[];
  sectorSelectionFilterHandler: any;
};
export const SectorSelector = ({ segments, sectorSelectionFilterHandler }: SectorSelectorType) => {
  //#region
  const { getTaxonomyMap,advancedNodesSelector } = useTaxonomyByType("stock");
  const icbTaxonomy = useMemo(() => {
    return getTaxonomyMap("security", "icb");
  }, [getTaxonomyMap]);
  const icbTaxonomyPanelConfig = useMemo(
    () => [
      [{ taxonomyId: "20" }, { taxonomyId: "60" }],
      [{ taxonomyId: "25" }, { taxonomyId: "50" }],
      [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
      [{ taxonomyId: "40" }, { taxonomyId: "45" }],
      [{ taxonomyId: "55" }, { taxonomyId: "30" }],
    ],
    []
  );
  //#endregion

  const validLevels = useMemo(() => ["1 Industry", "3 Sector"], []);

  const activeValues = useMemo(() => {
    if (segments.length) {
      if (segments.length > 3) {
        const firstSelected = icbTaxonomy?.[segments[0]]?.name ?? "";
        return [firstSelected, `+ ${segments.length - 1}`];
      } else {
        return segments.map((id) => icbTaxonomy?.[id]?.name ?? "");
      }
    }
    return [];
  }, [icbTaxonomy, segments]);
  return (
    <FilterTaxonomies
      filtersValues={segments}
      updateFilters={(value)=>{
        if(value.length>0){
          const temp = deepClone(value);
          const newSegments  = advancedNodesSelector(value[0].value.segments, "icb", true,validLevels);
          temp[0].value.segments = newSegments;
          sectorSelectionFilterHandler(temp) 
        }else{
          sectorSelectionFilterHandler([])
        }
      }
      }
      selectedFilters={activeValues}
      taxonomyToRender={{ type: "security", field: "icb" }}
      label={"Sector"}
      showIconCancel={false}
      validTreeNodes={["1 Industry", "3 Sector"]}
      columnsDefinition={icbTaxonomyPanelConfig}
    />
  );
};
