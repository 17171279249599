import { Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { deepClone } from "../../../../deepClone";
import { _valueGet } from "../utils";
import style from "./WizzardComponents.module.scss";
import WizzValue from "./WizzValue";

type WizzFunctionProps = {
  functionId: string;
  functions: any[] | null;
  rState: any;
  options: any;
  dispatcher: Function;
};

export default function WizzFunction({
  functionId,
  functions,
  rState,
  options,
  dispatcher,
}: WizzFunctionProps) {
  const defStyleClass = (item) => {
    if (item["value"] === functionId && rState["editing"]) {
      return "tFormSelectionAddRule-listItem--selected";
    } else {
      if (item["value"] === functionId && !rState["editing"] && rState["showOperatorSection"]) {
        return "tFormSelectionAddRule-listItem--selected";
      } else {
        return "";
      }
    }
  };

  const [selectedListItem, setSelectListItem] = useState<any>(() => {
    if (functions) {
      for (let i = 0; i < functions!.length; i++) {
        if (functions[i]["value"] === functionId) {
          return i;
        }
      }
    }
    return null;
  });

  return (
    <Card sx={{ height: "100%", boxShadow: 3 }}>
      <CardContent>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Rank by
        </Typography>
        <ul>
          {functions &&
            functions.map((item, index) => (
              <li className={`${style.listItem} ${defStyleClass(item)}`} key={index}>
                <span
                  onClick={(e: any) => {
                    onClickOnFunctionOption(rState, item, options, dispatcher);
                    setSelectListItem(index);
                    if (!rState["editing"]) {
                      highlightSelectedWhenCreating(
                        e.target,
                        "tFormSelectionAddRule-listItem--selected"
                      );
                    }
                  }}
                  className={style.listItemContent}
                >
                  {item.label}
                </span>
                {selectedListItem === index ? (
                  <WizzValue
                    constraints={getConstraints(rState, options)}
                    type={rState["field"]["function"]}
                    initVal={rState.field.functionParams?.augmentation?.value ?? null}
                    dispatcher={dispatcher}
                  />
                ) : (
                  <></>
                )}
              </li>
            ))}
        </ul>
      </CardContent>
    </Card>
  );
}

const onClickOnFunctionOption = (rState, prop, options, dispatcher) => {
  let tt = deepClone(rState);
  tt["editing"] = false;
  let temp = _valueGet(
    tt,
    tt["field"]["property"],
    prop.value,
    null,
    options._optionsIndex,
    options._options
  );
  const { field, index /*optionsFunction, optionsOperator, editing*/ } = temp;
  const { function: functionP, functionParams, operator /*operatorParams, property*/ } = field;
  dispatcher({ type: "SET_FUNCTION", payload: functionP });
  dispatcher({ type: "SET_FUNCTION_PARAMS", payload: functionParams });
  // dispatcher({ type: "SET_PROPERTY", payload: property });
  dispatcher({ type: "SET_OPERATOR", payload: operator });
  dispatcher({ type: "SET_OPERATOR_PARAMS", payload: null });
  dispatcher({ type: "SET_INDEX", payload: index });
  // dispatcher({ type: "SET_OPTIONS_FUNCTION", payload: optionsFunction });
  // dispatcher({ type: "SET_OPTIONS_OPERATOR", payload: optionsOperator });
  dispatcher({
    type: "SET_SHOW_OPERATOR_SECTION",
    payload: true,
  });
  dispatcher({
    type: "SET_SHOW_VALUE_SECTION",
    payload: false,
  });
  dispatcher({
    type: "SET_ERROR_AT_INPUT_VALUE",
    payload: false,
  });
  dispatcher({
    type: "SET_EDITING",
    payload: false,
  });
};

const highlightSelectedWhenCreating = (element: HTMLElement, classToApply: string) => {
  if (element) {
    let siblings: any = Array.from(element.parentNode!.parentNode!.childNodes);
    siblings.forEach((item: HTMLElement) => {
      if (item.classList.contains(classToApply)) {
        item.classList.remove(classToApply);
      }
    });
    element.parentElement!.classList.add(classToApply);
  }
};

const getConstraints = (_value, options) => {
  let objToReturn = {};
  let propertyId = _value["field"]["property"];
  let functionId = _value["field"]["function"];
  if (propertyId != null && functionId != null) {
    var propertyIndex = options["_optionsIndex"][propertyId]["property"];
    var propertyItem = options["_options"]["properties"][propertyIndex];
    objToReturn["propertyItem"] = propertyItem["label"];
    var functionIndex = options["_optionsIndex"][propertyId]["functions"][functionId];
    var functionItem = options["_options"]["functions"][propertyIndex][functionIndex];
    return functionItem.widget?.constraints;
  }
  return;
};
