import { useEffect, useState } from "react";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { Performance } from "./Performance";
import { PerformersTable } from "./PerformersTable";
import { RadioButtonBar } from "../../../../../../../components/RadioButtonBar/RadioButtonBar";

type PerformanceWidgetProps = {
  performance1: any;
  performance2: any;
  onChangePerformancePeriod: (period) => void;
  period: "pm" | "pq" | "ps" | "pd" | "pw" | "py";
  label1: string;
  label2: string;
};

const dropdownOptions = [
  { label: "1 Month", value: "pm" },
  { label: "3 Months", value: "pq" },
  { label: "6 Months", value: "ps" },
];

export function PerformanceWidget({
  performance1,
  performance2,
  onChangePerformancePeriod,
  label1,
  period,
  label2,
}: PerformanceWidgetProps) {
  const [timeframe, setTimeframe] = useState("pm");
  const [isOpen, setIsOpen] = useState(true);

  const onSelectTimeframe = (value) => {
    setTimeframe(value);
  };

  const toggleAccordion = () => setIsOpen(!isOpen);

  useEffect(() => {
    onChangePerformancePeriod(timeframe);
  }, [onChangePerformancePeriod, timeframe]);

  const arrowDirection = isOpen ? "down" : "up";

  const titleWidget1 = (
    <div className="accordion-compare-title">
      <div className="compare-portfolio-widget-title">
        <h3 className="performance-title">Performance {""}</h3>
        <RadioButtonBar
          initValue={timeframe}
          onChange={(value) => onSelectTimeframe(value)}
          options={dropdownOptions}
        />
      </div>
    </div>
  );

  const titleWidget2 = (
    <div className="accordion-compare-title">
      <div className="compare-portfolio-widget-title">
        <h3 className="performance-title">Performance {""}</h3>
        <RadioButtonBar
          initValue={timeframe}
          options={dropdownOptions}
          onChange={(value) => onSelectTimeframe(value)}
        />
      </div>
      <span onClick={toggleAccordion} className={`i-${arrowDirection}`}></span>
    </div>
  );

  return (
    <div className="compare-section-row">
      <div className="blockWrapper performance-block">
        <Accordion titleJSX={titleWidget1} isOpen={isOpen}>
          <Performance data={performance1}>
            <div className="performance-table-panel">
              <PerformersTable
                trend="Top"
                data={performance1.top5 ?? []}
                period={period}
                label={label1}
              />
              <PerformersTable
                trend="Bottom"
                data={performance1.bottom5 ?? []}
                period={period}
                label={label1}
              />
            </div>
          </Performance>
        </Accordion>
      </div>
      <div className="blockWrapper performance-block">
        <Accordion isOpen={isOpen} titleJSX={titleWidget2}>
          <Performance data={performance2}>
            <div className="performance-table-panel">
              <PerformersTable
                trend="Top"
                data={performance2.top5 ?? []}
                period={period}
                label={label2}
              />
              <PerformersTable
                trend="Bottom"
                data={performance2.bottom5 ?? []}
                period={period}
                label={label2}
              />
            </div>
          </Performance>
        </Accordion>
      </div>
    </div>
  );
}
