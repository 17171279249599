import { useEffect, useReducer, useState } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../../../../../trendrating/app/formatter/FormOptions";
// import { PeerSize } from "../../../../../../components/trendrating-widgets/form/peerSize/PeerSize";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { PeerSize } from "../../../../../../../../components/PeerSize/PeerSize";
import { useTaxonomyByType } from "../../../../../../../../hooks/useTaxonomyByType";
import Search from "../../../../../../widgets/ReactSearch/Search";
import { TaxonomyFieldRow } from "../../../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";
type AddPinnedSelectorProps = {
  updateSelectorStatus: Function;
};

const initialState = {
  market: null,
  sector: null,
  size: null,
  instrument: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "setMarket":
      return { ...state, market: action.payload, instrument: null };
    case "setSector":
      return { ...state, sector: action.payload, instrument: null };
    case "setSize":
      return { ...state, size: action.payload, instrument: null };
    case "setInstrument":
      return {
        instrument: action.payload,
        sector: null,
        market: null,
        size: null,
      };
    default:
      throw new Error();
  }
}

export default function AddPinnedSelector({
  updateSelectorStatus,
}: AddPinnedSelectorProps) {
  const environment = useEnvironment();

  const sizeOptions = new FormOptions(environment.get("formatter")).getField(
    "SIZE"
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const [size, setSize] = useState(null);

  useEffect(() => {
    dispatch({ type: "setSize", payload: size });
  }, [size]);

  useEffect(() => {
    updateSelectorStatus(state);
  }, [state, updateSelectorStatus]);

  useEffect(() => {
    console.log("state", state);
  }, [state]);
  const {
    taxonomiesMapX,
    taxonomiesMapY,
    // advancedNodesSelector
  } = useTaxonomyByType("stock");

  return (
    <>
      <Box display={"flex"} gap={2}>
        <TaxonomyFieldRow
          hasMultipleChoices={false}
          label={"Market"}
          handleValuesSelection={(e) => {
            dispatch({ type: "setMarket", payload: e });
          }}
          taxonomyMap={taxonomiesMapX}
          selectedValues={state.market ? [state.market] : []}
          taxonomyType={"security"}
          taxonomyField={"country"}
          phraseOnEmpty="World wide"
        />
        <TaxonomyFieldRow
          hasMultipleChoices={false}
          label={"Sectors"}
          handleValuesSelection={(e) => {
            dispatch({ type: "setSector", payload: e });
          }}
          taxonomyMap={taxonomiesMapY}
          taxonomyType={"security"}
          taxonomyField={"icb"}
          selectedValues={state.sector ? [state.sector] : []}
          columnsDefinition={[
            [{ taxonomyId: "20" }, { taxonomyId: "60" }],
            [{ taxonomyId: "25" }, { taxonomyId: "50" }],
            [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
            [{ taxonomyId: "40" }, { taxonomyId: "45" }],
            [{ taxonomyId: "55" }, { taxonomyId: "30" }],
          ]}
          validTreeNodes={["1 Industry", "3 Sector"]}
        />
      </Box>
      <Box>
        <Card>
          <CardContent
            sx={{
              p: 1,
              pb: "8px !important",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography>Size</Typography>

            <PeerSize
              isNullWhenAllAreSelected={true}
              onChangeValueHandler={(e: any) => {
                if (e) {
                  setSize(e);
                }
                if (e === undefined) {
                  setSize(null);
                }
              }}
              defaultValue={size ?? undefined}
              options={sizeOptions}
            />
          </CardContent>
        </Card>
      </Box>
      <div className="tPreferences-form-divider">
        <span>OR</span>
      </div>
      <div style={{ marginTop: 10 }}>
        <label style={{ display: "block" }} htmlFor="PinnedSelector-search">
          Instrument
        </label>
        <Search
          onSelectInstrument={(e) => {
            dispatch({
              type: "setInstrument",
              payload: e,
            });
          }}
          showInstrumentInfoOnSelect={false}
        />
      </div>
    </>
  );
}
