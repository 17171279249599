import { deepClone } from "../../../../../../../../deepClone";

export class WidgetsOptions {
  public getEligibility(type, t: Function, refValue?) {
    refValue = refValue ? refValue : null;
    var options = this._optionsEligibility(t);
    var prototype: any = null;

    switch (type) {
      case "LABEL_LOOKUP": {
        var key = this.getEligibility("OPTION_LOOKUP", refValue, t);

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        prototype = [options["desc"], options["asc"]];

        return deepClone(prototype);
      } // no default
    }
  }

  public getRotation(type, t: Function, refValue?) {
    refValue = refValue ? refValue : null;
    var options = this._optionsRotation(t);
    var prototype: any = null;

    switch (type) {
      case "LABEL_LOOKUP": {
        var key = this.getRotation("OPTION_LOOKUP", refValue, t);

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        prototype = [
          options["Country"],
          options["Region"],
          options["Area"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
        ];

        return deepClone(prototype);
      }
      case "OPTIONS_EXTENDED": {
        prototype = [
          options["Country"],
          options["Region"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
          options["Country__4 Subsector"],
          {
            label: "<em>More ...</em>",
            value: null,
            children: [
              options["Area"],
              options["3 Sector"],
              options["1 Industry"],
              options["Region__1 Industry"],
              options["Area__1 Industry"],
              options["Region__3 Sector"],
              options["Area__3 Sector"],
            ],
          },
        ];

        return deepClone(prototype);
      } // no default
    }

    return null;
  }

  public getWeightCappingPeerLevel(type, t: Function, refValue?) {
    refValue = refValue ? refValue : null;
    var options = this._optionsWeightCappingPeerLevel(t);
    var prototype: any = null;

    switch (type) {
      case "LABEL_LOOKUP": {
        var key = this.getWeightCappingPeerLevel("OPTION_LOOKUP", refValue, t);

        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        prototype = [
          options["Country"],
          options["Region"],
          options["Area"],
          options["3 Sector"],
          options["1 Industry"],
        ];

        return deepClone(prototype);
      } // no default

      case "OPTIONS_ETF": {
        const optionsEtf = this._optionsWeightCappingPeerLevelEtf(t);

        prototype = [
          optionsEtf["Country"],
          optionsEtf["Region"],
          optionsEtf["Area"],
          optionsEtf["4 Subsector"],
          optionsEtf["3 Sector"],
          optionsEtf["1 Industry"],
        ];

        return deepClone(prototype);
      }
    }

    return null;
  }
  // ----------------------------------------------------- private methods
  private _optionsEligibility(t: Function) {
    var options = {
      asc: {
        label: t("common_sort_by_lowest_marketcap"),
        value: "asc",
      },
      desc: {
        label: t("common_sort_by_highest_marketcap"),
        value: "desc",
      },
    };

    return options;
  }

  // TODO - use i18n for labels
  private _optionsRotation(t: Function) {
    var options = {
      Country: { label: "Markets", value: "Country" },

      Region: { label: "Regions", value: "Region" },

      Area: { label: "Areas", value: "Area" },

      "3 Sector": {
        label: t("Industries"),
        value: "3 Sector",
      },

      "1 Industry": {
        label: t("Sectors"),
        value: "1 Industry",
      },

      "Country__3 Sector": {
        label: t("Industries") + " in Markets",
        value: "Country__3 Sector",
      },

      "Country__1 Industry": {
        label: t("Sectors") + " in Markets",
        value: "Country__1 Industry",
      },

      "Region__1 Industry": {
        label: "Regions in " + t("Sectors"),
        value: "Region__1 Industry",
      },

      "Area__1 Industry": {
        label: "Area in " + t("Sectors"),
        value: "Area__1 Industry",
      },

      "Region__3 Sector": {
        label: "Regions in " + t("Industries"),
        value: "Region__3 Sector",
      },

      "Area__3 Sector": {
        label: "Area in " + t("Industries"),
        value: "Area__3 Sector",
      },
      "Country__4 Subsector": {
        label: "Themes in Markets (ETF)",
        value: "Country__4 Subsector",
      },
    };

    return options;
  }

  private _optionsWeightCappingPeerLevel(t: Function) {
    var options = {
      Country: { label: t("Markets"), value: "Country" },

      Region: { label: t("Regions"), value: "Region" },

      Area: { label: t("Areas"), value: "Area" },

      "3 Sector": {
        label: t("Industries"),
        value: "3 Sector",
      },

      "1 Industry": {
        label: t("Sectors"),
        value: "1 Industry",
      },
    };

    return options;
  }

  private _optionsWeightCappingPeerLevelEtf(t: Function) {
    var options = {
      Country: { label: "Investment Region", value: "Country" },

      Region: { label: t("Regions"), value: "Region" },

      Area: { label: t("Areas"), value: "Area" },

      "4 Subsector": {
        label: "Theme",
        value: "4 Subsector",
      },

      "3 Sector": {
        label: "Specialty",
        value: "3 Sector",
      },

      "1 Industry": {
        label: "Asset Class",
        value: "1 Industry",
      },
    };

    return options;
  }
}
