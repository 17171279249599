/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/PasswordSetupPage
 * @summary Page to setup a new password
 *
 */

import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { config } from "../../app/config-ts";
import {
  arePasswordsEqual,
  isValidEmail,
  isValidPassword,
  messageError,
  messageSuccess,
} from "../../app/utils";
import { useBroadcast } from "../../../hooks/useBroadcast";

declare const window: any;

type PasswordSetupPageProps = {
  storage: any;
};

export function PasswordSetupPage({ storage }: PasswordSetupPageProps) {
  const { t, ready } = useTranslation();
  const { uid } = useParams();
  let [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { broadcast } = useBroadcast();

  useEffect(() => emailInputRef.current?.focus(), []);

  useEffect(() => {
    if (ready) {
      document.title = `${t("Recover_your_password")} - Trendrating`;
    }
  }, [t, ready]);

  const handleInputChange = (event: ChangeEvent) => {
    const target: any = event.target!;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "login-email") {
      setEmail(value.trim());
    } else if (name === "login-password") {
      setPassword(value.trim());
    } else if (name === "login-password-check") {
      setPasswordCheck(value.trim());
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const paramsPasswordSetup = {
      email,
      password: password,
      passwordCheck: passwordCheck,
      uid: uid,
    };
    setup(paramsPasswordSetup);
  };

  const setup = ({ email, password, passwordCheck, uid }) => {
    const hasValidEmail = isValidEmail(email);
    const hasValidPassword = isValidPassword(password);
    const hasPasswordsEqual = arePasswordsEqual(password, passwordCheck);

    if (hasValidEmail && hasValidPassword && hasPasswordsEqual) {
      const [channel, msg] = messageSuccess(null);
      broadcast(channel as string, msg);
      setDisableForm(true);

      const paramsPasswordSetup = {
        email: email,
        password: password,
        uid: uid,
      };

      return storage["users"].passwordSetup(paramsPasswordSetup).then(
        (response) => {
          const paramsLogin = {
            email: email,
            password: password,
          };

          storage["users"].login(paramsLogin).then((response) => {
            window.location.href = config.routes.app;
          });
        },
        (error) => {
          setDisableForm(false);
          const [channel, msg] = messageError(
            t("Recovery_link_expired"),
            error
          );
          broadcast(channel as string, msg);
        }
      );
    }

    let errors = [];
    if (!hasValidEmail) {
      errors.push(t("Email_is_not_valid"));
    }

    if (!hasValidPassword) {
      errors.push(t("Password_is_not_valid"));
    }

    if (!hasPasswordsEqual) {
      errors.push(t("The_two_passwords_are_different"));
    }

    const [channel, msg] = messageError(errors.join("<br />"));
    broadcast(channel as string, msg);
  };

  return (
    <div className="PagePasswordSetup">
      <form
        action="#login"
        className="Form"
        method="post"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <legend>{t("Recover_your_password")}</legend>
          <ol>
            <li>
              <label htmlFor="login-email">{t("Email")}</label>
              <br />
              <input
                className="Input"
                id="login-email"
                name="login-email"
                onChange={handleInputChange}
                ref={emailInputRef}
                type="email"
                value={email}
              />
            </li>
            <li>
              <label htmlFor="login-password">{t("New_password")}</label>
              <br />
              <input
                className="Input"
                onChange={handleInputChange}
                id="login-password"
                name="login-password"
                type="password"
                value={password}
              />
            </li>
            <li>
              <label htmlFor="login-password-check">
                {t("Retype_new_password")}
              </label>
              <br />
              <input
                className="Input"
                onChange={handleInputChange}
                id="login-password-check"
                name="login-password-check"
                type="password"
                value={passwordCheck}
              />
            </li>
            <li className="Form-buttons">
              <button
                className="Button Button--primary"
                disabled={disableForm}
                type="submit"
              >
                {disableForm ? t("Logging_in") : t("Set")}
              </button>
            </li>
            <li className="Form-help">
              <Link to="/">{t("Back_to_login")}</Link>
              <span className="a11y">|</span>
              <a href={`mailto:${t("mailto")}`}>{t("Need_help")}</a>
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
}
