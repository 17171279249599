import { Preferences } from "../../api/account/Preferences";
import { Sessions } from "../../api/account/Sessions";
import { Users } from "../../api/account/Users";
import { ClusterAnalytics } from "../../api/compute/ClusterAnalytics";
import { CombinedProducts } from "../../api/compute/CombinedProducts";
import { CombinedStrategies } from "../../api/compute/CombinedStrategies";
import { CompareStrategies } from "../../api/compute/CompareStrategies";
import { ExposedAnalyticsScreening } from "../../api/compute/ExposedAnalyticsScreening";
import { Instruments } from "../../api/compute/Instruments";
import { Lists } from "../../api/compute/Lists";
import { News } from "../../api/compute/News";
import { NewsSelect } from "../../api/compute/NewsSelect";
import { Peers } from "../../api/compute/Peers";
import { Publications } from "../../api/compute/Publications";
import { Rankings } from "../../api/compute/Rankings";
import { Strategies } from "../../api/compute/Strategies";
import { Subscriptions } from "../../api/compute/Subscriptions";
import { SystematicProducts } from "../../api/compute/SystematicProducts";
import { TemplatePreferenceRank } from "../../api/compute/TemplatePreferenceRank";
import { TemplateReportPeer } from "../../api/compute/TemplateReportPeer";
import { TemplateReportPortfolio } from "../../api/compute/TemplateReportPortfolio";
import { TemplateReportRanking } from "../../api/compute/TemplateReportRanking";
import { TemplateReportScreening } from "../../api/compute/TemplateReportScreening";
import { TemplateReportStrategy } from "../../api/compute/TemplateReportStrategy";
import { TemplateReportStrategyLongShort } from "../../api/compute/TemplateReportStrategyLongShort";
import { TemplateReportSystematicPortfolios } from "../../api/compute/TemplateReportSystematicPortfolios";
import { TemplateTablePortfolio } from "../../api/compute/TemplateTablePortfolio";
import { TemplateTablePortfolioPointInTime } from "../../api/compute/TemplateTablePortfolioPointInTime";
import { TemplateTableScreening } from "../../api/compute/TemplateTableScreening";
import { TemplateTableSystematicProductAllocation } from "../../api/compute/TemplateTableSystematicProductAllocation";
import { TemplateTableSystematicProductHolding } from "../../api/compute/TemplateTableSystematicProductHolding";
import { Utils } from "../../api/compute/Utils";
import { Report } from "../../api/report/Report";
import i18n from "../../i18n";

export class Http {
  accountPreferences: any = null;
  accountSessions: any = null;
  accountUsers: any = null;
  // Publications and Subscriptions aka Shared Objects
  publications: any = null;
  subscriptions: any = null;
  clusterAnalytics: any = null;
  combinedProducts: any = null;
  combinedStrategies: any = null;
  environment: any = null;
  instruments: any = null;
  lists: any = null;
  news: any = null;
  newsSelect: any = null;
  peers: any = null;
  rankings: any = null;
  strategies: any = null;
  systematicProducts: any = null;
  peerReportTemplates: any = null;
  portfolioReportTemplates: any = null;
  portfolioTableTemplates: any = null;
  portfolioPointInTimeTableTemplates: any = null;
  rankingReportTemplates: any = null;
  rankingTemplates: any = null; // ranking rules
  reports: any = null;
  screeningReportTemplates: any = null;
  screeningTableTemplates: any = null;
  strategyReportTemplates: any = null;
  systematicProductAllocationTableTemplates: any = null;
  systematicProductHoldingTableTemplates: any = null;
  _dialogMessage: any = null;
  compareStrategies: any = null;
  utils: any = null;
  screeningExposedAnalytics: any = null;
  strategyLongShortReportTemplates: any = null;
  systematicPortfoliosReportTemplates: any = null;

  error = (error, object) => {
    if (appConfig.isDebug === true) {
      console.log(error);
    }

    // Deal with cancelled promises (from makeCancelable)
    if (error.isCanceled) {
      console.log("Request cancelled");
      return error;
    }

    if (error instanceof Error) {
      if (object != null) {
        object.then((result) => console.log("Request cancelled", result));
      }
      return error;
    }

    var status = error["status"];
    var responseStatus = error["response"]["status"];

    if (status == null) {
      status = responseStatus;
    }

    // request canceled
    if (status == null && error["message"] === "Request canceled") {
      return {
        message: "Request canceled",
      };
    }

    switch (status) {
      case 401:
        window.location.reload();
        break;
      case 409:
        console.error(
          "Object already exists",
          i18n["http_error_already_exists"]
        );
        break;
      default:
        console.error("Error", i18n["http_error_unknown"]);
        break;
    }
  };

  get(property) {
    switch (property) {
      case "environment": {
        return this.environment;
      }
      default: {
        throw new Error("Unsupported property: " + property);
      }
    }
  }

  set(property, value) {
    switch (property) {
      case "environment": {
        this._setEnvironmentAttr(value);

        break;
      }
      default: {
        throw new Error("Unsupported property: " + property);
      }
    }
  }

  _setEnvironmentAttr(environment) {
    this.environment = environment;

    this.accountPreferences = new Preferences(environment);
    this.accountSessions = new Sessions(environment);
    this.accountUsers = new Users(environment);

    // Publications and Subscriptions aka Shared Objects
    this.publications = new Publications(environment);
    this.subscriptions = new Subscriptions(environment);

    this.clusterAnalytics = new ClusterAnalytics(environment);

    this.combinedProducts = new CombinedProducts(environment);
    this.combinedStrategies = new CombinedStrategies(environment);
    this.compareStrategies = new CompareStrategies(environment);
    this.instruments = new Instruments(environment);
    this.lists = new Lists(environment);
    this.news = new News(environment);
    this.newsSelect = new NewsSelect(environment);
    this.peers = new Peers(environment);
    this.rankings = new Rankings(environment);
    this.utils = new Utils(environment);

    this.strategies = new Strategies(environment);
    this.systematicProducts = new SystematicProducts(environment);

    // table templates
    this.portfolioTableTemplates = new TemplateTablePortfolio(environment);
    this.portfolioPointInTimeTableTemplates =
      new TemplateTablePortfolioPointInTime(environment);
    this.screeningTableTemplates = new TemplateTableScreening(environment);
    this.systematicProductAllocationTableTemplates =
      new TemplateTableSystematicProductAllocation(environment);
    this.systematicProductHoldingTableTemplates =
      new TemplateTableSystematicProductHolding(environment);

    //  exposed analytics
    this.screeningExposedAnalytics = new ExposedAnalyticsScreening(environment);

    // ranking templates
    this.rankingTemplates = new TemplatePreferenceRank(environment);

    // report
    this.reports = new Report(environment);

    // report templates
    this.peerReportTemplates = new TemplateReportPeer(environment);
    this.portfolioReportTemplates = new TemplateReportPortfolio(environment);
    this.rankingReportTemplates = new TemplateReportRanking(environment);
    this.screeningReportTemplates = new TemplateReportScreening(environment);
    this.strategyReportTemplates = new TemplateReportStrategy(environment);
    this.strategyLongShortReportTemplates = new TemplateReportStrategyLongShort(
      environment
    );
    this.systematicPortfoliosReportTemplates =
      new TemplateReportSystematicPortfolios(environment);
  }
}

const http = new Http();

export default http;
