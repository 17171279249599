import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { SystematicProductFormatter } from "../../../../../trendrating/app/formatter/SystematicProduct";
import { TDate } from "../../../../../trendrating/date/TDate";
import { Product } from "../../../storage/SystematicPortfoliosStorage";
import { Typography } from "@mui/material";

type ProductPreviewAnalyticProps = {
  product: Product;
};

export function ProductPreviewAnalytic({
  product,
}: ProductPreviewAnalyticProps) {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = useState<any>();
  const [hasBenchmark, setHasBenchmark] = useState(false);
  const [showInceptionTotal, setShowInceptionTotal] = useState(true);
  const environment = useEnvironment();

  const getAnalytics = useCallback(async () => {
    const productObj = await product.previewAnalytics();

    if (productObj) {
      setAnalytics(productObj.analytics?.preview);

      if (productObj.analytics?.preview?.B) {
        setHasBenchmark(true);
      } else {
        setHasBenchmark(false);
      }
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      getAnalytics();

      const productInfo = product.info();
      const todayDateDays = TDate.today();
      const inceptionDateDays = productInfo["inceptionDate"];

      setShowInceptionTotal(todayDateDays - inceptionDateDays <= 260);
    }
  }, [getAnalytics, product]);

  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useMemo(
    () => new SystematicProductFormatter(appSetup),
    [appSetup]
  );

  return analytics ? (
    <div className="wSystematicProductAnalytic">
      <table>
        <thead>
          <tr>
            <th></th>
            <th
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeHeadList"
            >
              {t("c_portfolio")}
            </th>
            {hasBenchmark && (
              <th
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeHeadBenchmark"
              >
                {t("c_benchmark")}
              </th>
            )}
            {hasBenchmark && (
              <th
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeHeadDifference"
              >
                {t("c_difference")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_daily")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeDailyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.oneDay),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeDailyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.oneDay),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeDailyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.oneDay),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_weekly")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeWeeklyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.oneWeek),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeWeeklyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.oneWeek),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeWeeklyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.oneWeek),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_monthly")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeMonthlyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.oneMonth),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeMonthlyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.oneMonth),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeMonthlyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.oneMonth),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_quarterly")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeQuarterlyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.threeMonths),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeQuarterlyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.threeMonths),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeQuarterlyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.threeMonths),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_month_to_date")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeMtdList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.MTD),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeMtdBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.MTD),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeMtdDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.MTD),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_quarter_to_date")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeQtdList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.QTD),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeQtdBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.QTD),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeQtdDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.QTD),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_year_to_date")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeYtdList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.YTD),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYtdBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.YTD),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYtdDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.YTD),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              {t("c_analytics_yearly")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeYearlyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.oneYear),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.oneYear),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.oneYear),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              *{t("c_analytics_3_years")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeYearlyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.threeYears),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.threeYears),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.threeYears),
                }}
              ></td>
            )}
          </tr>
          <tr>
            <th
              style={{ textAlign: "left" }}
              className="wSystematicProductAnalytic-thRow"
            >
              *{t("c_analytics_5_years")}
            </th>
            <td
              style={{ textAlign: "right" }}
              data-dojo-attach-point="nodeYearlyList"
              dangerouslySetInnerHTML={{
                __html: formatter.performance(analytics?.H?.fiveYears),
              }}
            ></td>
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyBenchmark"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.B?.fiveYears),
                }}
              ></td>
            )}
            {hasBenchmark && (
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeYearlyDifference"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.D?.fiveYears),
                }}
              ></td>
            )}
          </tr>
          {showInceptionTotal === true ? (
            <tr data-dojo-attach-point="nodeInceptionTotal">
              <th
                style={{ textAlign: "left" }}
                className="wSystematicProductAnalytic-thRow"
              >
                {t("c_analytics_inception_total")}
              </th>
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeInceptionTotalList"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.H?.total),
                }}
              ></td>
              {hasBenchmark && (
                <td
                  style={{ textAlign: "right" }}
                  data-dojo-attach-point="nodeInceptionTotalBenchmark"
                  dangerouslySetInnerHTML={{
                    __html: formatter.performance(analytics?.B?.total),
                  }}
                ></td>
              )}
              {hasBenchmark && (
                <td
                  style={{ textAlign: "right" }}
                  data-dojo-attach-point="nodeInceptionTotalDifference"
                  dangerouslySetInnerHTML={{
                    __html: formatter.performance(analytics?.D?.total),
                  }}
                ></td>
              )}
            </tr>
          ) : (
            <tr data-dojo-attach-point="nodeInception">
              <th
                style={{ textAlign: "left" }}
                className="wSystematicProductAnalytic-thRow"
              >
                *{t("c_analytics_inception")}
              </th>
              <td
                style={{ textAlign: "right" }}
                data-dojo-attach-point="nodeInceptionList"
                dangerouslySetInnerHTML={{
                  __html: formatter.performance(analytics?.H?.annualized),
                }}
              ></td>
              {hasBenchmark && (
                <td
                  style={{ textAlign: "right" }}
                  data-dojo-attach-point="nodeInceptionBenchmark"
                  dangerouslySetInnerHTML={{
                    __html: formatter.performance(analytics?.B?.annualized),
                  }}
                ></td>
              )}
              {hasBenchmark && (
                <td
                  style={{ textAlign: "right" }}
                  data-dojo-attach-point="nodeInceptionDifference"
                  dangerouslySetInnerHTML={{
                    __html: formatter.performance(analytics?.D?.annualized),
                  }}
                ></td>
              )}
            </tr>
          )}
        </tbody>
      </table>

      <Typography sx={{ marginTop: 1, fontSize: "0.6vw" }}>
        * Annualized performance
      </Typography>
    </div>
  ) : (
    <></>
  );
}
