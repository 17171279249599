/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Rankings
 * @summary Requests for rankings (instruments and lists). It is used in
 *      screening, analysis-list and rank pages
 *
 * Screening
 *      1 - Standard ranking of a set of instruments
 *      2 - Today's ranking compared to a ranking in a previous date.
 *      3 - Today's ranking against a list: it computes which is the ranking of
 *          list holdings against the defined (by constraints) instruments set
 *
 * List
 *      1 - Standard ranking: the ranking of the list holdings
 *      2 - Ranking against an universe (working list, another list or a
 *          screening)
 *
 *      For both is available the possibility to compare today's ranking with
 *      the ranking in a previous date.
 *
 * DEVELOPMENT NOTES -----------------------------------------------------------
 *
 * Screening steps
 *      1 - It retrieves instruments according to the screening constraints. If
 *          the ranking is against a list, also the list is retrieved.
 *      2 - It runs the ranking.
 *          If a previous date has been selected, it does an additional ranking
 *          at that date. Data are merged and ranking delta is computed.
 *      3 - If the ranking is against list, data are merged accordingly. Only
 *          the holdings that match screening constraints will be merged.
 *
 * List steps
 *      1 - It retrieves universe's instruments according to the constraints
 *          (working list, another list, a screening)
 *      2 - It performs the union with the holdings of the working list
 *      3 - It runs ranking.
 *          If a previous date has been selected, it does an additional ranking
 *          for the date. Data are merged and ranking delta is computed.
 *      4 - Data merge. Only the holdings belonging to the working list
 *          will be merged.
 */

import { format } from "date-fns";
import { UtilsArray } from "../../trendrating/core/UtilsArray";
import { TDate } from "../../trendrating/date/TDate";
import { AppEnvironment } from "../../types/Defaults";
import { deepClone } from "../../deepClone";
import { endpoints } from "../endpoints";
import { httpAll } from "../../httpAll";
import { addQueryParam } from "../utils";
import { _StoredObjects } from "../_StoredObjects";
import { extractSymbols, symbolsToObjects } from "./commons";
import { Instruments } from "./Instruments";
import { Lists } from "./Lists";
import { RankingUi2Api } from "./RankingUi2Api";
import { Subscriptions } from "./Subscriptions";
import { Utils } from "./Utils";

/* static readonly: use this only when all references from older js code
does not reference the instance directly. */
export class Rankings extends _StoredObjects {
  http: any;
  storedObjectType = "RANKING_ABOUT_TARGET" as const;

  /* static readonly */ MARKET_CAP_MULTIPLIER = 1000000;

  // maximum ranking cardinality
  /* static readonly */ RANKING_CARDINALITY = 3000;

  // property and property prefixes used during data merge
  /* static readonly */ RANKING_PROPERTY = "rank";
  /* static readonly */ RANKING_PROPERTY_DELTA = "rankDelta";
  /* static readonly */ RANKING_PROPERTY_FROM_DATE = "rankFromDate";

  // property to inject holdings weight of a list
  /* static readonly */ RANKING_PROPERTY_LIST = "rankList";

  // It overwrites _Base constructor because of extra initialization
  // operations are needed
  constructor(environment: AppEnvironment) {
    super(environment);

    this.http = {
      instruments: new Instruments(environment),
      lists: new Lists(environment),
      subscriptions: new Subscriptions(environment),
      utils: new Utils(environment),
    };
  }

  // #region -------------------------------------------- data persistence

  async create(object: any) {
    const encodedObject = this.encode(object);
    const response = await super.create(encodedObject);
    return this.decode(response);
  }

  decode(object: any) {
    const decodedObject = deepClone(object);

    decodedObject["rules"] = this._decodeRankingRules(decodedObject["rules"]);

    switch (decodedObject["target"]["type"]) {
      case "instruments": {
        decodedObject["target"]["object"]["constraints"] =
          this._decodeConstraints(
            decodedObject["target"]["object"]["constraints"]
          );
        // not used
        // encodedObject["target"]["object"]["pagination"]
        // encodedObject["target"]["object"]["sortBy"]

        break;
      }
      case "list": {
        decodedObject["target"]["object"] = {
          id: decodedObject["target"]["object"]["id"],
          type: decodedObject["target"]["object"]["type"],
        };

        break;
      }
    }

    return decodedObject;
  }

  encode(object: any) {
    const encodedObject = deepClone(object);

    encodedObject["rules"] = this._encodeRankingRules(encodedObject["rules"]);

    let updatedTargetObject = {};

    switch (encodedObject["target"]["type"]) {
      case "instruments": {
        updatedTargetObject["constraints"] = this._encodeConstraints(
          encodedObject["target"]["object"]["constraints"]
        );
        // not used
        // encodedObject["target"]["object"]["pagination"]
        // encodedObject["target"]["object"]["sortBy"]

        break;
      }
      case "list": {
        updatedTargetObject = {
          id: encodedObject["target"]["object"]["id"],
          type: encodedObject["target"]["object"]["type"],
        };

        break;
      }
    }

    encodedObject["target"]["object"] = updatedTargetObject;

    return encodedObject;
  }

  async get(id: any) {
    const responseGet = await httpAll({
      rankings: super.get(id),
      subscriptions: this.http["subscriptions"]
        .get({
          type: "ranking",
        })
        .then((responseSubscriptions: any) => {
          //
          // Demonstration that inheritance is a bad idea.
          //
          // Use composition instead!!!
          //
          const __DIRTY__ = new _StoredObjects(this.environment);
          __DIRTY__.storedObjectType = this.storedObjectType;

          const requestsStoredAndSharedObjects: any = [];
          for (
            let i = 0, length = responseSubscriptions.length;
            i < length;
            i++
          ) {
            requestsStoredAndSharedObjects.push(
              __DIRTY__.get(responseSubscriptions[i].id)
            );
          }

          return httpAll(requestsStoredAndSharedObjects);
        }),
    });

    if (id) {
      // get(id)
      const ranking = responseGet.rankings;
      const subscriptionsIds: any[] = responseGet.subscriptions.map(
        (item: any) => item.id
      );

      ranking.isReadOnly = false;
      if (subscriptionsIds.includes(ranking.id)) {
        ranking.isReadOnly = true;
      }

      return this.decode(ranking);
    } else {
      // get()
      const response = responseGet.rankings;

      const length = responseGet.subscriptions.length;
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          const subscribedRanking = deepClone(responseGet.subscriptions[i]);

          subscribedRanking.isReadOnly = true;

          response.push(subscribedRanking);
        }
      }

      return response.map((item: any) => this.decode(item));
    }
  }

  getFromDateAsDays(fromDate: any) {
    const fromDateKeyValue = this._getFromDateInfo();
    for (let i = 0, length = fromDateKeyValue.length; i < length; i++) {
      const item = fromDateKeyValue[i];

      if (fromDate === item["key"]) {
        return item["value"];
      }
    }
    return null;
  }

  /**
   * Returns options suitable by date widgets
   *
   * @returns {array} - an array of objects
   *
   * {
   *     label: {string},
   *     value: {string}
   * }
   */
  getFromDateOptions(longFormat: any) {
    const options: any = [];

    if (longFormat == null) {
      longFormat = false;
    }

    const fromDateInfo = this._getFromDateInfo();
    const labels = {
      PREVIOUS_DAY: "Previous day",
      PREVIOUS_WEEK: "Last Friday",
      PREVIOUS_2_WEEKS: "Previous week",
      PREVIOUS_MONTH: "Last month",
      PREVIOUS_3_MONTHS: "Last 3 months",
    } as const;

    for (let i = 0, length = fromDateInfo.length; i < length; i++) {
      const item = fromDateInfo[i];

      options.push({
        label:
          (labels as any)[item["key"]] +
          (longFormat
            ? " - " + format(TDate.daysToDate(item["value"]), "EEEE MMM d yyyy")
            : ""),
        value: item["key"],
      });
    }

    return options;
  }

  // remove: function(object) {} is inherited from _StoredObjects,

  async update(object: any) {
    const encodedObject = this.encode(object);
    const response = await super.update(encodedObject);
    return this.decode(response);
  }
  // #endregion ----------------------------------------------------------

  // #region --------------------------------------- on the fly operations
  /**
   * Ranks instruments
   *
   * @param {object}   params - Ranking parameters
   * @param {object}   params.againstList - ranking against this list.
   *      Default null. The list is an instance returned by Lists.get(id)
   * @param {object}   params.constraints - screening constraints
   * @param {object}   params.constraints.filters -
   * @param {object}   params.constraints.page -
   * @param {object}   params.constraints.ranges -
   * @param {object}   params.constraints.sort -
   * @param {number}   params.fromDate - ranking begins from the specified
   *      date. Default null
   * @param {object[]} params.rules - ranking rules.
   *      Encodable with RankingUi2Api.encode()
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async instruments(params: any) {
    const paramsFilter = deepClone(params["constraints"]);
    paramsFilter["page"] = {
      page: 1,
      rows: this.RANKING_CARDINALITY,
    };

    const requests = {
      instruments: this.http["instruments"].screening(paramsFilter),
      list: null,
    };

    if (params["againstList"] != null) {
      requests["list"] = this.http["lists"].get(params["againstList"]["id"]);
    }

    const response = await httpAll(requests);
    return this._instruments(params, response);
  }

  /**
   * Ranks a list
   *
   * @param {object}   params - Ranking parameters
   * @param {object}   params.againstUniverse - ranking against this
   *      universe (filter constraints)
   * @param {object}   params.againstUniverse.filters -
   * @param {object}   params.againstUniverse.page -
   * @param {object}   params.againstUniverse.ranges -
   * @param {object}   params.againstUniverse.sort -
   * @param {number}   params.fromDate - ranking begins from the specified
   *      date. Default null
   * @param {object}   params.list - working list (response of
   *      Lists.get(id))
   * @param {object[]} params.rules - ranking rules.
   *      Encodable with RankingUi2Api.encode()
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async list(params: any) {
    let isAgainstItself = false;
    const paramsFilter = deepClone(params["againstUniverse"]);
    let againstListId: any = null;

    if (params["againstUniverse"] == null) {
      isAgainstItself = true;
    } else if ("relations" in paramsFilter) {
      // TODO - remove parseInt: server must acccept Integer
      againstListId = parseInt(paramsFilter["relations"][0]["domain"][0]);

      if (againstListId === params["list"]["id"]) {
        isAgainstItself = true;
      }
    }

    // against another list: avoid expired and unavailable
    if (!isAgainstItself && againstListId != null) {
      const list = await this.http["lists"].getListAnalytics(againstListId, [
        "positionsToday",
      ]);
      const symbolsList = extractSymbols(list?.[0]?.["positionsToday"]);
      return this._list(params, {
        data: symbolsList,
        dataTotalCount: symbolsList.length,
      });
    }

    // universe
    if (!isAgainstItself && againstListId == null) {
      const result = await this.http["instruments"].filter(paramsFilter);
      return this._list(params, result);
    }

    const symbolsList = extractSymbols(params["list"]["positions"]);
    return this._list(params, {
      data: symbolsList,
      dataTotalCount: symbolsList.length,
    });
  }

  /**
   * Ranks instruments and if fromDate is passed, it merges the data
   *
   * @param {object}   params - Ranking parameters
   * @param {number}   params.fromDate - ranking begins from the specified
   *      date. Default null
   * @param {object[]} instruments - instruments to rank
   * @param {string}   instruments[].symbol - the instrument symbol
   * @param {object[]} params.rules - ranking rules.
   *      Encodable with RankingUi2Api.encode()
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async ranking(params: any) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.rankings.runs;

    const encodedRules = this._encodeRankingRules(params["rules"]);
    const symbols = extractSymbols(params["instruments"]);

    const requests: any = {
      againstUniverse: null,
      rank: null,
      rankFromDate: null,
    };

    const paramsRank = {
      strategy: {
        rank: encodedRules,
      },
      symbols: symbols,
    };

    requests["rank"] = this.preparePost(url, paramsRank, null);

    if (params["fromDate"] != null) {
      const paramsRankFromDate = {
        date: params["fromDate"],
        strategy: {
          rank: encodedRules,
        },
        symbols: symbols,
      };

      requests["rankFromDate"] = this.preparePost(
        url,
        paramsRankFromDate,
        null
      );
    }

    const response = await httpAll(requests);
    const prefixRank = this.RANKING_PROPERTY;
    const prefixRankDelta = this.RANKING_PROPERTY_DELTA;
    const prefixRankFromDate = this.RANKING_PROPERTY_FROM_DATE;

    // normalize ranking data
    const rank: any = this._prepareRanking(
      params["rules"],
      prefixRank,
      response["rank"]["data"]
    );
    const rankFromDate: any = this._prepareRanking(
      params["rules"],
      prefixRankFromDate,
      response["rankFromDate"] != null ? response["rankFromDate"]["data"] : null
    );

    if (rankFromDate != null) {
      const rankMap: any = {};
      for (let i = 0, length = rank["data"].length; i < length; i++) {
        rankMap[rank["data"][i]["symbol"]] = i;
      }
      // merging data
      for (const rankFromDateItem of rankFromDate["data"]) {
        const index = rankMap[rankFromDateItem["symbol"]];
        rank["data"][index] = {
          ...rank["data"][index],
          ...rankFromDateItem,
        };
        // ranking delta
        rank["data"][index][prefixRankDelta] =
          rankFromDateItem[prefixRankFromDate] -
          rank["data"][index][prefixRank];
      }
    }

    const _response = {
      data: rank["data"],
      dataTotalCount: rank["data"].length,
    };

    return _response;
  }

  // #endregion ----------------------------------------------------------

  // ---------------------------------------------------------------------
  // ----------------------------------------------------- private methods
  // ---------------------------------------------------------------------

  // #region ------------------------------------------- decoders/encoders

  _decodeConstraints(encodedConstraints: any) {
    const decodedConstraints: any = {
      eligibility: {
        cardinality:
          "justInTimeTops" in encodedConstraints
            ? encodedConstraints["justInTimeTops"][0]["n"]
            : encodedConstraints["page"]["rows"],
        sortBy:
          "justInTimeTops" in encodedConstraints
            ? encodedConstraints["justInTimeTops"][0]["rev"] === true
              ? "desc"
              : "asc"
            : encodedConstraints["sort"]["rev"] === true
            ? "desc"
            : "asc",
      },
      instrumentType: null,
      size: null,
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        // Only ETFs: etfgeo
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
    };

    let _params = encodedConstraints["filters"];
    for (let i = 0, length = _params.length; i < length; i++) {
      const filter = _params[i];

      switch (filter["dimension"]) {
        case "country": {
          decodedConstraints["whereSource"]["market"] = filter["segments"];

          break;
        }
        case "etfgeo": {
          decodedConstraints["whereTarget"]["market"] = filter["segments"];

          break;
        }
        case "etfclass":
        case "icb": {
          decodedConstraints["what"] = filter["segments"];

          break;
        }
        case "stockclass": {
          decodedConstraints["whereSource"]["stockClassification"] =
            filter["segments"];

          break;
        }
        case "subtype": {
          const hasDomestic =
            filter["segments"].indexOf("Domestic Stock") !== -1;
          const hasForeign = filter["segments"].indexOf("Foreign Stock") !== -1;

          if (hasDomestic && hasForeign) {
            // Both set, do not set anything (= selecting all)
          } else if (hasDomestic) {
            decodedConstraints["whereSource"]["domestic"] = true;
          } else if (hasForeign) {
            decodedConstraints["whereSource"]["foreign"] = true;
          }

          break;
        }
        case "type": {
          decodedConstraints["instrumentType"] =
            filter["segments"][0].toLowerCase();

          break;
        }
      }
    }

    _params =
      encodedConstraints["ranges"] != null ? encodedConstraints["ranges"] : [];

    for (const range of _params) {
      switch (range["dimension"]) {
        case "marketcap": {
          decodedConstraints["size"] = {
            ge:
              range["segments"][0]["min"] != null
                ? range["segments"][0]["min"] / this.MARKET_CAP_MULTIPLIER
                : null,
            le:
              range["segments"][0]["max"] != null
                ? range["segments"][0]["max"] / this.MARKET_CAP_MULTIPLIER
                : null,
          };

          break;
        }
      }
    }

    return decodedConstraints;
  }

  _decodeRankingRules(encodedRules: any) {
    const ranking = new RankingUi2Api(this.environment);

    return ranking.decode(encodedRules);
  }

  _encodeConstraints(decodedConstraints: any) {
    const encodedConstraints = {
      justInTimeTops: [
        {
          dimension: "marketcap",
          n: decodedConstraints["eligibility"]["cardinality"],
          rev:
            decodedConstraints["eligibility"]["sortBy"] === "desc"
              ? true
              : false,
        },
      ],
      page: {
        page: 1,
        rows: this.RANKING_CARDINALITY,
      },
      sort: {
        dimension: "marketcap",
        rev:
          decodedConstraints["eligibility"]["sortBy"] === "desc" ? true : false,
      },
    };

    const _instrumentType = decodedConstraints["instrumentType"];
    let instrumentType: any = null;
    switch (_instrumentType) {
      case "etf": {
        instrumentType = ["ETF"];
        // etfclass
        if (decodedConstraints["what"].length > 0) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "etfclass",
            segments: decodedConstraints["what"],
          });
        }
        // etfgeo
        if (decodedConstraints["whereTarget"]["market"].length > 0) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "etfgeo",
            segments: decodedConstraints["whereTarget"]["market"],
          });
        }

        break;
      }
      case "stock": {
        instrumentType = ["Stock"];
        // icb
        if (decodedConstraints["what"].length > 0) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "icb",
            segments: decodedConstraints["what"],
          });
        }
        // domestic and/or foreign
        if (
          decodedConstraints["whereSource"]["domestic"] === true &&
          decodedConstraints["whereSource"]["foreign"] === false
        ) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "subtype",
            segments: ["Domestic Stock"],
          });
        }
        if (
          decodedConstraints["whereSource"]["domestic"] === false &&
          decodedConstraints["whereSource"]["foreign"] === true
        ) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "subtype",
            segments: ["Foreign Stock"],
          });
        }
        // stock classification
        if (
          decodedConstraints["whereSource"]["stockClassification"].length > 0
        ) {
          addQueryParam(encodedConstraints, "filters", {
            dimension: "stockclass",
            segments: decodedConstraints["whereSource"]["stockClassification"],
          });
        }

        break;
      }
    }
    // country
    if (decodedConstraints["whereSource"]["market"].length > 0) {
      addQueryParam(encodedConstraints, "filters", {
        dimension: "country",
        segments: decodedConstraints["whereSource"]["market"],
      });
    }
    // instrument type
    if (instrumentType != null) {
      addQueryParam(encodedConstraints, "filters", {
        dimension: "type",
        segments: instrumentType,
      });
    }
    // size
    if (
      decodedConstraints["size"] != null &&
      (decodedConstraints["size"]["ge"] != null ||
        decodedConstraints["size"]["le"] != null)
    ) {
      addQueryParam(encodedConstraints, "ranges", {
        dimension: "marketcap",
        segments: [
          {
            max:
              decodedConstraints["size"]["le"] != null
                ? decodedConstraints["size"]["le"] * this.MARKET_CAP_MULTIPLIER
                : null,
            min:
              decodedConstraints["size"]["ge"] != null
                ? decodedConstraints["size"]["ge"] * this.MARKET_CAP_MULTIPLIER
                : null,
          },
        ],
      });
    }

    return encodedConstraints;
  }

  _encodeFromDate(fromDate: any) {
    const fromDateKeyValue = this._getFromDateInfo();

    for (let i = 0, length = fromDateKeyValue.length; i < length; i++) {
      const item = fromDateKeyValue[i];
      if (fromDate === item["key"]) {
        return item["value"];
      }
    }
  }

  _encodeRankingRules(decodedRules: any) {
    const ranking = new RankingUi2Api(this.environment);

    return ranking.encode(decodedRules);
  }
  // #endregion ----------------------------------------------------------

  // #region ------------------------------------------------- instruments
  async _instruments(params: any, responseInstruments: any) {
    const symbols = responseInstruments["instruments"]["data"];
    const instruments = symbolsToObjects(symbols);

    const paramsRanking = {
      fromDate: this._encodeFromDate(params["fromDate"]),
      instruments: instruments,
      rules: params["rules"],
    };

    const response = await this.ranking(paramsRanking);
    return this._instrumentsDataMerge(params, responseInstruments, response);
  }

  _instrumentsDataMerge(params: any, responseInstruments: any, response: any) {
    const _response = response["data"];
    const againstList = params["againstList"];
    const list = responseInstruments["list"];

    if (list != null) {
      const rankingPropertyList = this.RANKING_PROPERTY_LIST;
      const symbols = extractSymbols(list["positions"]);

      for (let i = 0, length = _response.length; i < length; i++) {
        const instrument = _response[i];
        instrument[rankingPropertyList] = null;

        if (symbols.includes(instrument["symbol"])) {
          // weight of the instrument in againstList
          instrument[rankingPropertyList] =
            againstList["positions"][
              againstList["positionsIndex"][instrument["symbol"]]
            ]["weight"];
        }
      }
    }

    const ranking = this._createResponse(_response);

    return ranking;
  }
  // #endregion ----------------------------------------------------------

  // #region -------------------------------------------------------- list
  async _list(params: any, responseInstruments: any) {
    const symbolsAgainstUniverse = responseInstruments["data"];
    const symbolsList = extractSymbols(params["list"]["positions"]);

    const symbols = UtilsArray.union(symbolsList, symbolsAgainstUniverse);

    const instruments = symbolsToObjects(symbols);

    const paramsRanking = {
      fromDate: this._encodeFromDate(params["fromDate"]),
      instruments: instruments,
      rules: params["rules"],
    };

    const response = await this.ranking(paramsRanking);
    return this._listDataMerge(params, responseInstruments, response);
  }

  _listDataMerge(params: any, responseInstruments: any, response: any) {
    const _response: any = [];
    const symbolsList = extractSymbols(params["list"]["positions"]);
    for (let i = 0, length = response["data"].length; i < length; i++) {
      const instrument = response["data"][i];

      if (symbolsList.includes(instrument["symbol"])) {
        _response.push(instrument);
      }
    }

    const ranking = this._createResponse(_response);

    return ranking;
  }
  // #endregion ----------------------------------------------------------

  /**
   * Create the final ranking response
   *
   * @param {object} response
   *
   * @ignore
   */
  _createResponse(response: any) {
    const _response: any = {
      data: response,
      dataTotalCount: response.length,
      index: response.length > 0 ? {} : null,
    };

    for (let i = 0; i < response.length; i++) {
      const instrument = response[i];
      _response["index"][instrument["symbol"]] = i;
    }

    return _response;
  }

  /**
   * Get fromDate options.
   * @param {number}
   *
   * @returns {array} - an array of object suitable for other operations
   *
   * Objects have this structure
   *
   * {
   *     key: {string} - one of PREVIOUS_WEEK, PREVIOUS_2_WEEKS, PREVIOUS_MONTH, PREVIOUS_3_MONTHS
   *     value: {Date} - a Date object
   * }
   *
   * @ignore
   */
  _getFromDateInfo() {
    // relative timeframe inspired by keen.io API
    // https://keen.io/docs/api/#timeframe
    //
    //
    // We use valueOf() instead of getTime()
    //
    // getTime() was also fine but valueOf is better. Don't pass the
    // original date because there can be little time differences
    //
    // e.g. const firstMonday = new Date(startMonth.valueOf()); // etc.

    // TODO refactor with new API when available

    const listOfDates = this.environment.today;

    if (listOfDates == null || listOfDates["today"] == null) {
      if (appConfig.isDebug) {
        console.error("Missing listOfDates on environment: ", listOfDates);
      }
      return [];
    }

    const options: any = [];

    const yesterday = listOfDates["yeasterday"];
    const lastFriday = listOfDates["lastFriday"];
    const previousWeek = listOfDates["previousWeek"];
    const lastMonth = listOfDates["lastMonth"];
    const last3Months = listOfDates["last3Months"];

    options.push({
      key: "PREVIOUS_DAY",
      value: yesterday,
    });
    options.push({
      key: "PREVIOUS_WEEK",
      value: lastFriday,
    });
    options.push({
      key: "PREVIOUS_2_WEEKS",
      value: previousWeek,
    });
    options.push({
      key: "PREVIOUS_MONTH",
      value: lastMonth,
    });
    options.push({
      key: "PREVIOUS_3_MONTHS",
      value: last3Months,
    });

    // #endregion ------------------------------------------------------

    return options;
  }

  /**
   * Prepare ranking data
   *
   * @param {object[]} rules - ranking rules
   * @param {string}   prefix - prefix to be used for ranking properties
   * @param {object[]} data - raw ranking data
   *
   * @returns {object} - prepared ranking data
   *
   * @ignore
   */
  _prepareRanking(rules: any, prefix: any, data: any) {
    if (data == null) {
      return null;
    }

    const _data: any = [];
    for (const datum of data) {
      const _datum: any = {
        symbol: datum["key"],
      };
      _datum[prefix] = datum["rank"];
      // length of raw values is equal to length rules.
      // They are the results of rule functions
      const values = datum["values"];
      for (let j = 0; j < values.length; j++) {
        const value = values[j];
        _datum[prefix + "Function" + j] = rules[j]["function"];
        _datum[prefix + "Property" + j] = rules[j]["property"];
        _datum[prefix + "Value" + j] = value;
      }
      _data.push(_datum);
    }

    const result = {
      data: _data,
      dataTotalCount: _data.length,
    };

    return result;
  }
}
