// react-app-polyfill must be at top
// be careful, do not reorganize code here, react-app-polyfill must be at the top
import "core-js/features/string/replace-all";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { ThemeProvider } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AppContextProvider } from "./AppContextProvider";
import { AppRouter } from "./AppRouter";
import { Channel } from "./hooks/useBroadcast";
import i18n from "./i18n";
import { TR_theme } from "./js/app/theme/TR_theme";
import { ProvideActionModal } from "./js/app/utils/useActionModal";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

global.fetch = fetch;

const rootNode = document.getElementById("root");
const root = createRoot(rootNode!);

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <AppContextProvider>
      <ThemeProvider theme={TR_theme}>
        <Channel>
          <ProvideActionModal>
            <AppRouter />
          </ProvideActionModal>
        </Channel>
      </ThemeProvider>
    </AppContextProvider>
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
