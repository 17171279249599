import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { fromJsonToExcessReturn } from "../../../../../../../analysisSecurity/components/Chart";
import { TrendratingChart } from "../../../../../../../analysisSecurity/components/TrendratingChart";
import { ProductHistogramQuartersDiff } from "./ProductHistogramQuartersDiff";

type Props = {
  data: any;
  histogramData: any;
};

export default function Chart({ data, histogramData }: Props) {
  const benchmark = useMemo(() => {
    if (
      data.strategyInstrumentBenchmark != null &&
      data.historyBenchmark != null
    ) {
      return {
        strategyInstrumentBenchmark: data.strategyInstrumentBenchmark,
        historyBenchmark: data.historyBenchmark,
      };
    }
    return null;
  }, [data.historyBenchmark, data.strategyInstrumentBenchmark]);
  const strategy = useMemo(() => {
    if (data.strategy != null && data.historyStrategy != null) {
      return {
        strategy: data.strategy,
        historyStrategy: data.historyStrategy,
      };
    }
    return null;
  }, [data.historyStrategy, data.strategy]);

  const curves = useMemo(() => {
    if (strategy == null) {
      return [];
    }
    let temp: any = [
      {
        prices: strategy.historyStrategy,
        data: {
          ticker: strategy.strategy.name,
          currency: strategy.strategy.params.strategy.currency,
        },
        id: strategy.strategy.name,
        name: strategy.strategy.name,
        type: "default",
      },
    ];
    if (benchmark != null) {
      temp.push({
        prices: benchmark.historyBenchmark,
        data: {
          ticker: benchmark.strategyInstrumentBenchmark.ticker,
          currency: benchmark.strategyInstrumentBenchmark.currency,
        },
        id: "benchmark",
        name: benchmark.strategyInstrumentBenchmark.name,
        type: "benchmark",
      });
    }

    if ("components" in data) {
      if (data.components.long && data.components.long.length) {
        data.components.long.forEach((component) => {
          temp.push({
            prices: component.prices,
            id: component.name,
            name: component.name,
            type: "long",
          });
        });
      }

      if (data.components.short && data.components.short.length) {
        data.components.short.forEach((component) => {
          temp.push({
            prices: component.prices,
            id: component.name,
            name: component.name,
            type: "short",
          });
        });
      }
    }
    return temp;
  }, [benchmark, data, strategy]);

  const isExcessReturned = useCallback((strategy, benchmark) => {
    let result: any = null;

    if (benchmark != null) {
      result = fromJsonToExcessReturn(
        strategy.historyStrategy,
        benchmark.historyBenchmark
      );
    }

    return result;
  }, []);

  const showHistogram = useMemo(() => histogramData != null, [histogramData]);

  return showHistogram && benchmark != null ? (
    <Box height={"100%"} p={1}>
      <div style={{ float: "left", width: "28%", height: "100%" }}>
        <ProductHistogramQuartersDiff histogramData={histogramData} />
      </div>
      <div style={{ float: "right", width: "70%", height: "100%" }}>
        <TrendratingChart
          hasTooltip={false}
          hasMeasure={true}
          excludePeriod={["R"]}
          defaultPeriod={"MAX"}
          lastRating={true}
          enableLegend={true}
          enableExcess={isExcessReturned(strategy, benchmark)}
          excessCurveName={"Relative Performance"}
          autoResize={true}
          value={curves}
          resizeCorrection={20}
        />
      </div>
    </Box>
  ) : (
    <Box height={"100%"} p={1}>
      <TrendratingChart
        hasTooltip={false}
        hasMeasure={true}
        excludePeriod={["R"]}
        defaultPeriod={"MAX"}
        lastRating={true}
        enableLegend={true}
        enableExcess={isExcessReturned(strategy, benchmark)}
        excessCurveName={"Relative Performance"}
        autoResize={true}
        value={curves}
      />
    </Box>
  );
}
