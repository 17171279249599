import axios from "axios";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { ProductClass } from "../../../../types/Defaults";
import insane from "insane";
import { NavLink, useNavigate, useParams } from "react-router-dom";

type HelpContentTutorialsProps = {
  baseUrl: string;
  productClass: ProductClass;
};

type TutorialsContentProps = {
  children: ReactNode;
  html: string;
};

type TutorialsSectionProps = {
  children: ReactNode;
  name: string;
  selectedSection: string | null;
  selectedSubsection: string | null;
  title: string;
};

type TutorialsSubsectionProps = {
  name: string;
  selectedSection?: string | null;
  selectedSubsection?: string | null;
  title: string;
};

type SanitizedProps = {
  html: string;
};

const Sanitized = ({ html }: SanitizedProps) => (
  <div
    dangerouslySetInnerHTML={{
      __html: insane(html, {
        allowedAttributes: {
          img: ["alt", "class", "src"],
          span: ["class", "style"],
          div: ["class", "style"],
          script: ["src"],
        },
        allowedTags: [
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "p",
          "strong",
          "em",
          "a",
          "b",
          "i",
          "span",
          "div",
          "br",
          "u",
          "img",
          "table",
          "thead",
          "tbody",
          "tfoot",
          "tr",
          "th",
          "td",
          "sup",
          "sub",
          "iframe",
          "script",
        ],
      }),
    }}
  />
);

const TutorialsContent = ({ children, html }: TutorialsContentProps) => {
  return (
    <div className="tPageHelpIndex-contentPage tHelpPage" data-id="tutorials">
      <div className="tHelpPage-content tHelpPage-content--dual">
        <div className="tHelpPage-content-sidebar">
          <ul className="tHelpTree">{children}</ul>
        </div>
        <div className="tHelpPage-content-area">
          <div className="tHelpPage-target">
            <Sanitized html={html} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TutorialsSection = ({
  children,
  name,
  selectedSection,
  selectedSubsection,
  title,
}: TutorialsSectionProps) => {
  return (
    <li
      className={`tHelpTree-item ${selectedSection === name ? "selected" : ""}`}
    >
      {title && (
        <a href={`/app/help/tutorials/${name}/`}>
          <span className="tHelpTree-title">{title}</span>&nbsp;
          <i className="tHelpTree-arrow"></i>
        </a>
      )}
      <ul className="tHelpSubtree">
        {Children.map(children, (child) => {
          return isValidElement(child)
            ? cloneElement<any>(child, {
                selectedSection,
                selectedSubsection,
              })
            : child;
        })}
      </ul>
    </li>
  );
};

const TutorialsSubsection = ({
  name,
  selectedSection,
  selectedSubsection,
  title,
}: TutorialsSubsectionProps) => {
  return (
    <li
      className={`tHelpSubtree-item ${
        selectedSubsection === name ? "selected" : ""
      }`}
    >
      <NavLink to={`/app/help/tutorials/${selectedSection}/${name}/`}>
        {title}
      </NavLink>
    </li>
  );
};

const loadSubsectionTemplate = (
  baseUrl: string,
  section: string | null,
  subsection: string | null
): Promise<string> => {
  if (section == null) {
    return Promise.resolve("");
  }
  var file = section;
  if (subsection != null) {
    file += "/" + subsection;
  } else {
    // TODO Cannot have empty content (for now)
    return Promise.resolve("");
  }

  return axios
    .get(baseUrl + file + ".txt", {
      responseType: "text",
      params: {
        ts: new Date().getTime(),
      },
    })
    .then((response) => {
      baseUrl = baseUrl.replace(/\/+$/, ""); // remove trailing (multiple) slashes
      /* eslint-disable no-template-curly-in-string */
      return response.data
        .replaceAll("${baseUrl}", baseUrl)
        .replaceAll("${mediaUrl}", baseUrl + "/images");
    })
    .catch((error) => {
      return "Cannot load content";
    });
};

export const HelpContentTutorials = ({
  baseUrl,
  productClass,
}: HelpContentTutorialsProps) => {
  const [html, setHtml] = useState("");
  const urlParams = useParams();
  const navigate = useNavigate();

  const { section, subsection } = urlParams;

  //Handle default route in case some params in url are null
  useEffect(() => {
    if (section == null) {
      navigate("videos/rating-smart-retracement");
    } else if (subsection == null) {
      navigate("rating-smart-retracement");
    }
  }, [navigate, section, subsection]);

  useEffect(() => {
    let unmounted = false;
    setHtml("Loading...");
    loadSubsectionTemplate(baseUrl, section!, subsection!)
      .then((data) => {
        if (!unmounted) {
          setHtml(data);
        }
      })
      .catch((error: string) => {
        if (!unmounted) {
          setHtml("Cannot load content");
        }
      });
    return () => {
      unmounted = true;
    };
  }, [baseUrl, section, subsection]);

  return (
    <TutorialsContent html={html}>
      <TutorialsSection
        name="videos"
        selectedSection={section!}
        selectedSubsection={subsection!}
        title=""
      >
        <TutorialsSubsection
          name="rating-smart-retracement"
          title="Rating, Smart Momentum and Retracement"
        />
        <TutorialsSubsection name="portfolio-import" title="Portfolio Import" />
        <TutorialsSubsection
          name="editing-portfolio"
          title="Editing a Portfolio"
        />
        <TutorialsSubsection
          name="screening-tab-stocks"
          title="Screening Tab for idea generation - Stocks"
        />
        <TutorialsSubsection
          name="screening-tab-etfs"
          title="Screening Tab for idea generation - ETFs"
        />
        <TutorialsSubsection name="rank-tab" title="The Rank Tab" />
        <TutorialsSubsection
          name="navigating-markets-tab"
          title="Navigating the Markets Tab"
        />
        {productClass === "premium" ? (
          <TutorialsSubsection
            name="create-alpha-strategy"
            title="Create an Alpha strategy"
          />
        ) : null}
        {productClass === "premium" ? (
          <TutorialsSubsection
            name="create-smart-beta-strategy"
            title="Create a Smart Beta strategy"
          />
        ) : null}
        {productClass === "premium" ? (
          <TutorialsSubsection
            name="create-macro-rotation-strategy"
            title="Create a Macro Rotation strategy"
          />
        ) : null}
        {productClass !== "premium" && productClass !== "standard" ? (
          <TutorialsSubsection
            name="create-advanced-strategy"
            title="Create an Advanced Strategy"
          />
        ) : null}
        <TutorialsSubsection
          name="generate-report-portfolio"
          title="Generating a Report in Portfolio Analysis"
        />
        {productClass === "premium" || productClass === "standard" ? (
          <TutorialsSubsection
            name="portfolio-vs-benchmark"
            title="Portfolio Analysis vs Benchmark"
          />
        ) : null}
        {productClass !== "premium" && productClass !== "standard" ? (
          <TutorialsSubsection
            name="create-systematic-portfolio"
            title="Create a Systematic Portfolio"
          />
        ) : null}
      </TutorialsSection>
    </TutorialsContent>
  );
};
