import { Box, Card, CardContent, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../deepClone";

type HeadingProps = {
  configObj: any;
};

const Heading = forwardRef(({ configObj }: HeadingProps, ref) => {
  const [state, setState] = useState(configObj.widgetValue.content.text);

  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.text = state;
      return tempState;
    },
  }));


  return (
    <Box >
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <TextField
            size="small"
            label={"Heading"}
            defaultValue={state}
            onChange={(e) => setState(e.target.value)}
          />
        </CardContent>
      </Card>
    </Box>
  );
});

export default Heading;
