import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import Tippy from "@tippyjs/react";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { DataPreparation } from "../../_DataPreparation";

type OptimizeActionProps = {
  strategy: any;
};

export function OptimizeAction({ strategy }: OptimizeActionProps) {
  const enviroment = useEnvironment();
  const navigate = useNavigate();
  // const [items, setItems] = useState([]);
  const onSelectPortfolio = useCallback(
    (portfolioId) => {
      const url = `/app/analysis/lists/${portfolioId}/optimize/with-strategy/${strategy.id}/`;
      // ************** USAGE ****************
      var usage = window.App.usage;
      var info = {
        action: "LANDING",
        actionParam: strategy?.id,
        function: "PORTFOLIO_OPTIMIZE",
      };
      usage.record(info);
      // ************** USAGE ****************
      navigate(url);
    },
    [navigate, strategy]
  );

  const loadItems = useCallback(async () => {
    if (!strategy.id) {
      setPopupContent(
        <Box p={1}>
          <Typography>
            Save this strategy before using it to optimize a portfolio.
          </Typography>
        </Box>
      );
      return;
    }
    if (enviroment.setup) {
      const listsAPI = enviroment.setup.http.lists;
      let lists = await listsAPI.get();
      lists = lists.data;
      let _items = lists.filter(function (item) {
        if (item.type === "PORTFOLIO") {
          // exclude shared objects
          return !item.isReadOnly;
        }
        return false;
      });
      var dP = new DataPreparation();
      _items.sort(dP.sortByCaseInsensitive("name"));
      let menuItamsArr: any[] = [];
      _items.forEach((item) =>
        menuItamsArr.push(
          <MenuItem
            sx={{ textAlign: "start" }}
            onClick={() => onSelectPortfolio(item.id)}
          >
            {/* is this necessary??? */}
            {item.isReadOnly ? (
              <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
            ) : null}
            {/* -------------------- */}
            <Typography>{item.name}</Typography>
          </MenuItem>
        )
      );
      setPopupContent(
        <Box
          display={"flex"}
          flexDirection={"column"}
          overflow={"auto"}
          maxHeight={"50vh"}
        >
          {menuItamsArr}
        </Box>
      );
    }
  }, [enviroment.setup, onSelectPortfolio, strategy.id]);

  const loadingPortfolioComponent = useMemo(
    () => (
      <Box
        display={"flex"}
        gap={1}
        p={1}
        alignItems={"center"}
        maxHeight={"50%"}
        overflow={"auto"}
      >
        <CircularProgress size={"0.7vw"} />
        <Typography>Loading Portfolios...</Typography>
      </Box>
    ),
    []
  );
  const [popupContent, setPopupContent] = useState(loadingPortfolioComponent);

  return (
    <Tippy
      interactive
      zIndex={99999}
      trigger="click"
      theme="security-tooltip"
      onTrigger={loadItems}
      content={popupContent}
    >
      <li className="menu__item">Optimize portfolio</li>
    </Tippy>
  );
}
