import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Properties } from "../../../../../api/Properties";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { Box } from "@mui/material";
import styles from "../AnalisysSecurity.module.scss";

export const ChartInfo = forwardRef((props, ref) => {
  const [firstRow, setFirstRow] = useState<any>();
  const [secondRow, setSecondRow] = useState<any>();

  const formatter = useFormatter();

  useImperativeHandle(ref, () => ({
    widget: {
      set: (value) => {
        if (value) {
          const first = value?.[0];

          setFirstRow(first);

          if (value?.[1]) {
            setSecondRow(value[1]);
          } else {
            setSecondRow(undefined);
          }
        }
      },
      resize: () => {},
    },
  }));

  const environment = useEnvironment();
  const label = useMemo(
    () =>
      new Properties({
        properties: environment.get("setup")["properties"],
      }),
    [environment]
  );

  const date = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const points = firstRow?.["points"]?.["current"]?.["d"];

    return formatter.custom("date", {
      options: {
        format: ["D", "M", "Y"],
        isMillisecond: false,
        notAvailable: {
          input: null,
          output: "",
        },
        separator: " ",
      },
      output: "HTML",
      value: points,
      valueHelper: null,
    });
  }, [firstRow, formatter]);

  const price = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const vc: any = firstRow?.["information"];
    const copy = { ...vc };

    copy["vc"] = firstRow["points"]["current"]["v"];

    return formatter.table("vc", "table", copy);
  }, [firstRow, formatter]);

  const datePerf = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const value =
      firstRow["points"]["last"]["v"] / firstRow["points"]["current"]["v"] -
      1.0;

    return formatter.custom("number", {
      options: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  }, [firstRow, formatter]);

  const pr = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("pr", "table", object);
  }, [firstRow, formatter]);

  const pytd = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("pytd", "table", object);
  }, [firstRow, formatter]);

  const pw = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("pw", "table", object);
  }, [firstRow, formatter]);

  const pm = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("pm", "table", object);
  }, [firstRow, formatter]);

  const pq = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("pq", "table", object);
  }, [firstRow, formatter]);

  const py = useMemo(() => {
    if (!firstRow) {
      return "";
    }

    const object = firstRow?.["information"];

    return formatter.table("py", "table", object);
  }, [firstRow, formatter]);

  const price1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const vc: any = secondRow?.["information"];
    const copy = { ...vc };

    copy["vc"] = secondRow["points"]["current"]["v"];

    return formatter.table("vc", "table", copy);
  }, [secondRow, formatter]);

  const datePerf1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const value =
      secondRow["points"]["last"]["v"] / secondRow["points"]["current"]["v"] -
      1.0;

    return formatter.custom("number", {
      options: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  }, [secondRow, formatter]);

  const pr1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("pr", "table", object);
  }, [secondRow, formatter]);

  const pytd1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("pytd", "table", object);
  }, [secondRow, formatter]);

  const pw1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("pw", "table", object);
  }, [secondRow, formatter]);

  const pm1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("pm", "table", object);
  }, [secondRow, formatter]);

  const pq1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("pq", "table", object);
  }, [secondRow, formatter]);

  const py1 = useMemo(() => {
    if (!secondRow) {
      return "";
    }

    const object = secondRow?.["information"];

    return formatter.table("py", "table", object);
  }, [secondRow, formatter]);

  return (
    <Box p={1}>
      <table className={styles.infoTable}>
        <thead>
          <tr>
            <th rowSpan={2}></th>
            <th className={styles.infoCell} colSpan={2}>
              {date}
            </th>
            <th
              style={{ textAlign: "left" }}
              className={styles.infoCell}
              colSpan={6}
            >
              Performance
            </th>
          </tr>
          <tr>
            <th className={styles.infoCell}>{label.get("vc", 0, null)}</th>
            <th className={styles.infoCell}>
              Perf. <span>[to today]</span>
            </th>
            <th className={styles.infoCell}>{label.get("pr", 0, null)}</th>
            <th className={styles.infoCell}>{label.get("pytd", 0, null)}</th>
            <th className={styles.infoCell}>{label.get("pw", 0, null)}</th>
            <th className={styles.infoCell}>{label.get("pm", 0, null)}</th>
            <th className={styles.infoCell}>{label.get("pq", 0, null)}</th>
            <th className={styles.infoCell}>{label.get("py", 0, null)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.infoCell}>
              <strong
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span
                  className="field-ticker-indicator"
                  style={{ backgroundColor: firstRow?.color ?? "" }}
                ></span>
                <span>{firstRow?.information?.ticker ?? ""}</span>
              </strong>
            </td>
            <td
              style={{ textAlign: "right" }}
              className={styles.infoCell}
              dangerouslySetInnerHTML={{ __html: price }}
            ></td>
            <td
              style={{ textAlign: "right" }}
              className={styles.infoCell}
              dangerouslySetInnerHTML={{ __html: datePerf }}
            ></td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {pr}
            </td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {pytd}
            </td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {pw}
            </td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {pm}
            </td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {pq}
            </td>
            <td style={{ textAlign: "right" }} className={styles.infoCell}>
              {py}
            </td>
          </tr>
          {secondRow != null ? (
            <tr>
              <td className={styles.infoCell}>
                <strong
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span
                    className="field-ticker-indicator"
                    style={{ backgroundColor: secondRow?.color ?? "" }}
                  ></span>
                  <span>{secondRow?.information?.ticker ?? ""}</span>
                </strong>
              </td>
              <td
                style={{ textAlign: "right" }}
                className={styles.infoCell}
                dangerouslySetInnerHTML={{ __html: price1 }}
              ></td>
              <td
                style={{ textAlign: "right" }}
                className={styles.infoCell}
                dangerouslySetInnerHTML={{ __html: datePerf1 }}
              ></td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {pr1}
              </td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {pytd1}
              </td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {pw1}
              </td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {pm1}
              </td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {pq1}
              </td>
              <td style={{ textAlign: "right" }} className={styles.infoCell}>
                {py1}
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </Box>
  );
});
