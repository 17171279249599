import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

type StrategyNavigatorProps = {
  strategyType: any;
  listenerChangeStrategy: Function;
};

const ENTITY_TYPES = {
  alpha: "ALPHA",
  advanced: "BUILDER",
  macroRotation: "SECTOR_ROTATION",
  smartBeta: "SMART_BETA",
};

export default function StrategyNavigator({
  strategyType,
  listenerChangeStrategy,
}: StrategyNavigatorProps) {
  const environment = useEnvironment();
  const setup = environment.get("setup");
  const strategiesApi = useMemo(() => new Strategies(setup), [setup]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [open, setOpen] = useState(false);

  const [options, setOptions] = useState<
    { isReadOnly: boolean; label: string; value: number }[]
  >([]);

  const getOptions = useCallback(async () => {
    setLoadingOptions(true);
    try {
      const strategies: any = await strategiesApi.getList([
        "object.entity_type",
      ]);

      if (strategies != null && strategies.length) {
        const opts: { isReadOnly: boolean; label: string; value: number }[] =
          [];

        for (const strategy of strategies) {
          if (strategy["entity_type"] === ENTITY_TYPES[strategyType]) {
            opts.push({
              label: strategy.name,
              isReadOnly: strategy.isReadOnly,
              value: strategy.id,
            });
          }
        }

        setOptions(opts);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  }, [strategiesApi, strategyType]);

  const openDropdown = useCallback(() => {
    setOpen(true);
    getOptions();
  }, [getOptions]);

  const getStrategyName = useCallback((name, isReadOnly) => {
    if (isReadOnly === true) {
      return (
        <Typography variant="subtitle1">
          {name}&nbsp;
          <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
        </Typography>
      );
    }
    return <Typography variant="subtitle1">{name}</Typography>;
  }, []);

  const changeStrategy = useCallback(
    (obj) => listenerChangeStrategy(obj),
    [listenerChangeStrategy]
  );

  const handleStrategySelection = useCallback(
    (item) => {
      const objToPass = {
        value: item.value,
        isReadOnly: item.isReadOnly,
        label: item.label,
      };
      setOpen(false);
      changeStrategy(objToPass);
    },
    [changeStrategy]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Select
      size="small"
      variant="outlined"
      defaultValue={"load"}
      placeholder="Load"
      onOpen={openDropdown}
      onClose={handleClose}
      value="load"
      open={open}
    >
      <MenuItem disabled sx={{ display: "none" }} value={"load"}>
        Load
      </MenuItem>
      <MenuItem
        sx={{ fontStyle: "italic" }}
        value={"new"}
        onClick={() =>
          handleStrategySelection({
            value: undefined,
            isReadOnly: false,
            label: "Create New Strategy",
          })
        }
      >
        Create New Strategy
      </MenuItem>
      {!loadingOptions ? (
        <Box>
          {options
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item, index) => (
              <MenuItem
                key={`${item.value}_${index}`}
                value={item.value}
                onClick={() => handleStrategySelection(item)}
              >
                <Typography>
                  {getStrategyName(item.label, item.isReadOnly)}
                </Typography>
              </MenuItem>
            ))}
        </Box>
      ) : (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress sx={{ color: "#2a7090" }} />
        </Box>
      )}
    </Select>
  );
}
