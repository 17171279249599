import {
    Card,
    CardContent,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type CurrencyProps = {
    strategyDispatch: Function;
    input: string | null;
};

export default function Currency({ strategyDispatch, input }: CurrencyProps) {
    const { t } = useTranslation();
    const [innerState, setInnerState] = useState(input);

    useEffect(() => {
        strategyDispatch({ type: "SET_CURRENCY", payload: input });
        setInnerState(input);
    }, [input, strategyDispatch]);

    return (
        <Card sx={{ height: "100%", flex: 1 }}>
            <CardContent>
                <FormControl sx={{}} size="small">
                    <FormLabel
                        sx={{
                            "&.Mui-focused": {
                                color: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    >
                        <Typography variant="subtitle1">
                            {t("page_builder_field_currency_label")}
                        </Typography>
                    </FormLabel>

                    <Select
                        sx={{
                            mt: 1 /*color: "#2a7092"*/,
                            "&.Mui-focused > .MuiOutlinedInput-notchedOutline":
                                {
                                    borderColor: "#2a7092 !important",
                                },
                        }}
                        label="currency"
                        onChange={(e) => {
                            strategyDispatch({
                                type: "SET_CURRENCY",
                                payload: e.target.value,
                            });
                        }}
                        value={innerState ?? "local"}
                    >
                        <MenuItem value={"local"}>
                            <Typography>Local</Typography>
                        </MenuItem>
                        <MenuItem value={"USD"}>
                            <Typography>USD</Typography>
                        </MenuItem>
                        <MenuItem value={"EUR"}>
                            <Typography>EUR</Typography>
                        </MenuItem>
                        <MenuItem value={"GBP"}>
                            <Typography>GBP</Typography>
                        </MenuItem>
                        <MenuItem value={"CHF"}>
                            <Typography>CHF</Typography>
                        </MenuItem>
                        <MenuItem value={"CAD"}>
                            <Typography>CAD</Typography>
                        </MenuItem>
                        <MenuItem value={"JPY"}>
                            <Typography>JPY</Typography>
                        </MenuItem>
                        <MenuItem value={"AUD"}>
                            <Typography>AUD</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            </CardContent>
        </Card>
    );
}
