import { ReactNode, useMemo } from "react";
import { ProductClass } from "../../../../types/Defaults";
import { v4 as uuidv4 } from "uuid";

type HowToItemProps = {
  children: ReactNode;
  filename: string;
};

type HelpContentHomeProps = {
  productClass: ProductClass;
  staticAssets: string;
};

const HowToItem = ({ children, filename }: HowToItemProps) => {
  return (
    <li className="howTo-item">
      <a
        className="howTo-link"
        href={`${filename}`}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </li>
  );
};

export const HelpContentHome = ({
  productClass,
  staticAssets,
}: HelpContentHomeProps) => {
  const assetsUrl = `${staticAssets}/how-tos`;
  const noProductSystematic = productClass !== "systematic";
  const noProductStandard = productClass !== "standard";
  const isProductSystematic = productClass === "systematic";

  const tableConfiguration = useMemo(() => {
    return [
      [
        { value: "PORTFOLIO ANALYSIS", bold: true },
        { value: "Trend-Risk Control", bold: false },
        {
          value:
            "Implement a pragmatic and objective risk control framework across markets, individual securities (utilizing alerts), and portfolios by employed the Trend Combined Risk (TCR) score.",
          bold: false,
        },
        {
          value:
            "The model effectively identifies 80% of negative trends in a timely manner, resulting in substantial risk reduction for client portfolios.",
          bold: false,
        },
        {
          value:
            'Utilize the "evidence" tab within the Portfolio Analysis module to assess the Average Avoidable Loss (by excluding C and D rated positions) for any portfolio.',
          bold: false,
        },
      ],
      [
        { value: "RANK", bold: true },
        { value: "Idea Validation", bold: false },
        {
          value:
            "Generate a ranked list of holdings based on the strength and attractiveness of price trends within the screened investment universe or portfolio.",
          bold: false,
        },
        {
          value:
            "Cross reference with fundamental screening to validate positions for enhanced performance and risk control. Avoid building positions in stocks that are in emerging or confirmed down trends.",
          bold: false,
        },
        {
          value:
            "Employ the ranking functionality and integrate fundamentals (including value and growth metrics) with trend assessment to enhance your chances of identifying the next outperformers.",
          bold: false,
        },
      ],
      [
        { value: "SCREENING", bold: true },
        { value: "Investment Ideas ", bold: false },
        {
          value:
            "Identification of missed opportunities to help align value strategies with major market trends and themes. Complement internal ranking and scoring methodologies across selected investment lists.",
          bold: false,
        },
        {
          value:
            "Generating new investment ideas and screening for missed opportunities outside of your internal framework is essential for diversification and tapping into emerging trends, ultimately maximizing potential returns.",
          bold: false,
        },
        {
          value:
            "Generate fresh investment ideas by screening from over 20,000 listed securities across Developed, Emerging, and Frontier markets. Save screens and filters for future reference, facilitating workflow integration.",
          bold: false,
        },
      ],
      [
        { value: "MARKETS", bold: true },
        { value: "Markets and Sectors Monitoring", bold: false },
        {
          value:
            "Compute the aggregated rating of markets and sectors, based on the percentage of stocks rated A and B vs. C and D. The higher the rating in a sector the broader the participation of the stocks to a bull trend.",
          bold: false,
        },
        {
          value:
            "Comparing the rating across sectors can add intelligence for allocation and macro rotation strategies. Validate internal positioning and identify new emerging, liquidity-driven trends across sectors and industries in any country",
          bold: false,
        },
        {
          value:
            'Utilize the "Markets" feature to compare TCR and the percentage of A and B stocks. Increase exposure in areas of strength.',
          bold: false,
        },
      ],
      [
        { value: "MARKETS", bold: true },
        { value: "Performance Dispersion Tracking", bold: false },
        {
          value:
            "Track the progression of performance dispersion within your selected investment universe.",
          bold: false,
        },
        {
          value:
            "Measuring dispersion is crucial as it helps gauge the variability of performance within any investment universe, aiding in risk assessment, diversification strategy evaluation, and informed decision-making.",
          bold: false,
        },
        {
          value:
            "Compare dispersion over both short and long-term periods to capture important fluctuations in investor flows.",
          bold: false,
        },
      ],
      [
        { value: "STRATEGY BUILDER", bold: true },
        { value: "Performance Optimization", bold: false },
        {
          value:
            "Supports the research, design and back testing of a strategy powered by the Trendrating model and an additional number of factors and parameters including fundamentals.",
          bold: false,
        },
        {
          value:
            "The optimal blend of parameters, focusing solely on stocks with strong fundamentals and genuine positive trends, consistently yields superior returns. Additionally, this approach provides a secure and credible investment framework.",
          bold: false,
        },
        {
          value:
            "Examine the sample strategies accessible within the system. Observe how it is feasible to consistently outperform the benchmark. Craft your own successful strategy leveraging the unique insights into genuine alpha generation provided by Trendrating.",
          bold: false,
        },
      ],
    ];
  }, []);

  return (
    <div
      className="tPageHelpIndex-contentPage tHelpPage"
      data-id="getting-started"
    >
      <div className="help">
        {isProductSystematic === true && (
          <div className="help-layout">
            <div className="help-left" style={{ width: "100%" }}>
              <h1 style={{ marginBottom: "10px" }}>Use Cases and Benefits:</h1>
              <table className="help-useCases-table">
                <thead>
                  <tr className="help-useCases-table-header-row">
                    <td className="help-useCases-table-header-cell">
                      <strong>FUNCTIONALITIES</strong>
                    </td>
                    <td className="help-useCases-table-header-cell">
                      <strong>USE CASES</strong>
                    </td>
                    <td className="help-useCases-table-header-cell">
                      <strong>WHAT IS IT</strong>
                    </td>
                    <td className="help-useCases-table-header-cell">
                      <strong>WHY IT'S IMPORTANT</strong>
                    </td>
                    <td className="help-useCases-table-header-cell">
                      <strong>HOW IT WORKS</strong>
                    </td>
                  </tr>
                </thead>
                <tbody className="help-useCases-table-body">
                  {tableConfiguration.map((row) => {
                    return (
                      <tr
                        key={uuidv4()}
                        className="help-useCases-table-body-row"
                      >
                        {row.map((col) => (
                          <td
                            key={uuidv4()}
                            className="help-useCases-table-body-cell"
                          >
                            {col.value}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="help-layout">
          <div className="help-left">
            <h1 style={{ marginBottom: "10px" }}>How can we help?</h1>
          </div>
        </div>
        <div className="help-layout">
          <div className="help-left">
            <div className="help-box help-box--50">
              <h2>Portfolio</h2>
              <ol className="howTo">
                <HowToItem
                  filename={`${assetsUrl}/Trendrating--HOW_TO--Portfolio_import.pdf`}
                >
                  Portfolio import
                </HowToItem>

                {noProductSystematic ? (
                  <li
                    className="
                                    howTo-item
                                    no-product-systematicEngine
                                "
                  >
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Portfolio_analysis.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Portfolio analysis - Get started
                    </a>
                  </li>
                ) : null}
                {noProductSystematic ? (
                  <li
                    className="
                                    howTo-item
                                    no-product-systematicEngine
                                "
                  >
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Measure_your_portfolio_exposure.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Assess the risk exposure of your portfolios
                    </a>
                  </li>
                ) : null}
                {noProductSystematic ? (
                  <li
                    className="
                                    howTo-item
                                    no-product-systematicEngine
                                "
                  >
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Optimize_negative_rated_instruments_in_your_portfolios.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Optimize negative rated instruments in your portfolios
                    </a>
                  </li>
                ) : null}
              </ol>
            </div>
            <div className="help-box help-box--50">
              <h2>Rank</h2>
              <ol className="howTo">
                <li className="howTo-item">
                  <a
                    className="howTo-link"
                    href={`${assetsUrl}/Trendrating--HOW_TO--Rank-Get_started.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rank - Get started
                  </a>
                </li>
                <li className="howTo-item">
                  <a
                    className="howTo-link"
                    href={`${assetsUrl}/Trendrating--HOW_TO--Rank-Automatic_ranking.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Automatic Ranking
                  </a>
                </li>
                <li className="howTo-item">
                  <a
                    className="howTo-link"
                    href={`${assetsUrl}/Trendrating--HOW_TO--Rank-Screening_and_portfolio_analysis_ranking.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Screening and Portfolio Analysis rankings
                  </a>
                </li>
                <li className="howTo-item">
                  <a
                    className="howTo-link"
                    href={`${assetsUrl}/Trendrating--HOW_TO--Rank-Trend_analysis_ranking_-_Decision_rule_template.xlsx`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Trend Analysis Ranking - <i>Excel Template</i>
                  </a>
                </li>
              </ol>
            </div>

            {noProductSystematic ? (
              <div
                className="
                            help-box help-box--50
                            no-product-systematicEngine
                        "
              >
                <h2>New Ideas / New Opportunities</h2>
                <ol className="howTo">
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Validate_investment_ideas_and_or_identify_new_opportunities.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Validate investment ideas and-or identify new
                      opportunities
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Select_best_ETFs.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Select best ETFs
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Use_for_selection.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Select stocks for a specific area of interest
                    </a>
                  </li>
                </ol>
              </div>
            ) : null}
            {noProductSystematic ? (
              <div
                className="
                            help-box help-box--50
                            no-product-systematicEngine
                        "
              >
                <h2>Allocation</h2>
                <ol className="howTo">
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Risk_control_(market_sector_risk_assessment).pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Analyze and compare the quality of trends across different
                      markets and sectors
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Sector_analysis.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Analyze and track the evolution of trends for the
                      components of a sector
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Use_for_allocation.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Adjust your allocation across countries and sectors
                    </a>
                  </li>
                </ol>
              </div>
            ) : null}
            {noProductSystematic && noProductStandard ? (
              <div
                className="
                            help-box help-box--50
                            no-product-standard
                            no-product-systematicEngine
                        "
              >
                <h2>Strategies</h2>
                <ol className="howTo">
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Apha_strategy.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create an Alpha strategy
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Smart_beta_strategy.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a Smart Beta strategy
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Macro_rotation_strategy.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a Macro Rotation strategy
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Apply_a_strategy_to_a_portfolio.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apply a strategy to a portfolio
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Track_a_strategy.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Track a strategy
                    </a>
                  </li>
                </ol>
              </div>
            ) : null}
            {isProductSystematic ? (
              <div
                className="
                            help-box help-box--50
                            product-systematicEngine
                        "
              >
                <h2>Systematic Portfolios</h2>
                <ol className="howTo">
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Systematic_Management_Solution_-_User_Guide.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      User Guide
                    </a>
                  </li>
                </ol>
              </div>
            ) : null}
            {isProductSystematic ? (
              <div
                className="
                            help-box help-box--50
                            product-systematicEngine
                        "
              >
                <h2>Strategy Builder</h2>
                <ol className="howTo">
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Systematic_Management_Solution_-_Strategy_Builder_User_Guide.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      User Guide
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Systematic_Management_Solution_-_Glossary_of_Analytics___their_Impact_on_Portfolio_Construction.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Glossary of Analytics &amp; their Impact on Portfolio
                      Construction
                    </a>
                  </li>
                  <li className="howTo-item">
                    <a
                      className="howTo-link"
                      href={`${assetsUrl}/Trendrating--HOW_TO--Systematic_Management_Solution_-_Example_Strategies.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Example Strategies
                    </a>
                  </li>
                </ol>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
