import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  formatTaxonPrefixingParent,
  getAncestorByType,
} from "../../../../../../api/compute/Taxon";
import Paginator from "../../../../../../components/Paginator/Paginator";
import { Spinner } from "../../../../../../components/Spinner/Spinner";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { useResizer } from "../../../../../../hooks/useResizer";
import { useTaxonomyByType } from "../../../../../../hooks/useTaxonomyByType";
import { SelectorETFMarkets } from "../../ETFs/widgets/FiltersBarMarketDetail/FiltersBarMarketDetail";
import styles from "./PerformanceSinceTrendTab.module.scss";
import {
  CellComponent,
  ColumnComponent,
  ColumnDefinition,
} from "tabulator-tables";

type PerformanceSinceTrendTabProps = {
  data?: {
    AB: {
      peerAvg: number | null;
      quartileAvg: {
        1: null | number;
        2: null | number;
        3: null | number;
        4: null | number;
      };
      securities: string[];
      quartilesSymbols: {
        [key: string]: number;
      };
    };
    CD: {
      peerAvg: null | number;
      quartileAvg: {
        1: null | number;
        2: null | number;
        3: null | number;
        4: null | number;
      };
      securities: string[];
      quartilesSymbols: {
        [key: string]: number;
      };
    };
    peerInfo: {
      type: string;
      zDimension: string;
      where: string;
      what: string;
    };
    formatterSectorBy: string | null;
  };
  peerType: "Stock" | "ETF";
  isLoading: boolean;
};

type PerformanceSinceTrendTabContentProps = {
  data: PerformanceSinceTrendTabProps["data"];
  peerType: PerformanceSinceTrendTabProps["peerType"];
  performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months";
  setPerformanceAt?: (
    value: "sinceRated" | "3_months" | "6_months" | "12_months"
  ) => void;
  enablePerformanceSelection?: boolean;
};

type PerformanceQuartilesCardProps = {
  label?: string;
  tabSelector: "AB" | "CD";
  firstQuartile: string;
  secondQuartile: string;
  thirdQuartile: string;
  fourthQuartile: string;
  cumulativeAvg: string;
  outputInfo:
    | "no-additional-info"
    | "empty-universe"
    | "cannot-calculate-quartiles";
  hasMarginTop?: boolean;
  hasTitle?: boolean;
  isCardActive?: boolean;
  selectTab?: (tab: "AB" | "CD") => void;
  cardinality?: number | null;
  performanceAt: "sinceRated" | "3_months" | "6_months" | "12_months";
};

type ResizerWrapperProps = {
  children: JSX.Element | JSX.Element[];
};

const performanceOptions = [
  { label: "Since Rated", value: "sinceRated" },
  { label: "3 Months", value: "3_months" },
  { label: "6 Months", value: "6_months" },
  { label: "12 Months", value: "12_months" },
];

const performanceDict = {
  sinceRated: "pr",
  "3_months": "pq",
  "6_months": "ps",
  "12_months": "py",
};

const titleDict = {
  sinceRated: "Performance Since Rated",
  "3_months": "Performance Last 3 Months",
  "6_months": "Performance Last 6 Months",
  "12_months": "Performance Last 12 Months",
};

const quartilesColorMap = {
  1: "#8fd8fc",
  2: "#abe1fc",
  3: "#d1efff",
  4: "#e5f6ff",
};

export function PerformanceSinceTrendTab({
  data,
  peerType,
  isLoading,
}: PerformanceSinceTrendTabProps) {
  // Place here to avoid reset of the state at render cause the content is unmounted an rebuilt on during loading
  const [performanceAt, setPerformanceAt] = useState<
    "sinceRated" | "3_months" | "6_months" | "12_months"
  >("sinceRated");

  return (
    <ResizerWrapper>
      {data != null && !isLoading ? (
        <PerformanceSinceTrendTabContent
          performanceAt={performanceAt}
          setPerformanceAt={setPerformanceAt}
          peerType={peerType}
          data={data}
        />
      ) : (
        <PerformanceSinceRatedSkeleton />
      )}
    </ResizerWrapper>
  );
}

const PerformanceSinceTrendTabContent = ({
  data,
  peerType,
  performanceAt,
  setPerformanceAt,
  enablePerformanceSelection = false,
}: PerformanceSinceTrendTabContentProps) => {
  const [tabSelector, setTabSelector] = useState<"AB" | "CD">("AB");
  const formatter = useFormatter();
  const {
    taxonomiesMapX,
    taxonomiesMapY,
    rootNodeX,
    rootNodeY,
    getTaxonomyMap,
  } = useTaxonomyByType(peerType === "ETF" ? "ETF" : "stock");
  const [outputInfoAB, setOutputInfoAB] = useState<
    "no-additional-info" | "empty-universe" | "cannot-calculate-quartiles"
  >("no-additional-info");
  const [outputInfoCD, setOutputInfoCD] = useState<
    "no-additional-info" | "empty-universe" | "cannot-calculate-quartiles"
  >("no-additional-info");
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [pageNmuber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setError] = useState(false);
  const [tableSort, setTableSort] = useState({
    field: performanceDict[performanceAt],
    direction: tabSelector === "AB" ? "asc" : "desc",
  });
  const [securities, setSecurities] = useState<
    {
      symbol: string;
      pr: number;
      dr: number;
      rc: number;
      name: string;
      ticker: string;
    }[]
  >([]);
  const [totalSecurities, setTotalSecurities] = useState(0);
  const environment = useEnvironment();
  const apiInstruments = useMemo(
    () => environment.get("http")["instruments"],
    [environment]
  );
  const properties = environment.get("properties");
  const tableLabels = useMemo(
    () => ({
      ticker: properties.get("ticker", 0, "auto"),
      name: properties.get("name", 0, "auto"),
      rc: properties.get("rc", 0, "auto"),
      pr: properties.get(performanceDict[performanceAt], 0, "auto"),
      dr: properties.get("dr", 0, "auto"),
      market: properties.get(
        peerType === "ETF" ? "etfgeo" : "country",
        0,
        "auto"
      ),
      sector: properties.get("industry", 0, "auto"),
      q: "Quartile",
    }),
    [peerType, performanceAt, properties]
  );

  const { dispatch } = useEventBus();

  const getSecuritiesByCluster = useCallback(
    async (selector: "AB" | "CD") => {
      const peerType = data?.peerInfo?.type ?? "Stock";
      const yDim =
        data?.peerInfo?.what && data?.peerInfo?.what !== rootNodeY
          ? data?.peerInfo?.what
          : null;
      const xDim =
        data?.peerInfo?.where && data?.peerInfo?.where !== rootNodeX
          ? data?.peerInfo?.where
          : null;
      let zDimensionTaxonomies = null;
      let rootNodeZ: any = null;

      if (peerType === "ETF") {
        zDimensionTaxonomies = getTaxonomyMap("ETF", "subtype");
      } else {
        zDimensionTaxonomies =
          environment.get("setup")["taxonomies"]["SizeClassification"];
      }

      if (zDimensionTaxonomies) {
        rootNodeZ = Object.values<any>(zDimensionTaxonomies).find(
          (node) => node.parent == null
        );
      }

      const filterParams = {
        page: {
          page: pageNmuber,
          rows: itemsPerPage,
        },
        filters: [
          {
            dimension: "type",
            segments: [peerType === "ETF" ? "ETF" : "Stock"],
          },
        ],
        ranges: [
          {
            dimension: "rc",
            segments: [
              selector === "AB" ? { max: 2, min: 1 } : { max: -1, min: -2 },
            ],
          },
        ],
        sort: [
          {
            dimension:
              tableSort.field === "q"
                ? performanceDict[performanceAt]
                : tableSort.field,
            rev: tableSort.direction === "asc",
          },
        ],
      };

      if (peerType !== "ETF") {
        filterParams.filters.push({
          dimension: "stockclass",
          segments: ["STOCK"],
        });
      }

      if (xDim) {
        filterParams.filters.push({
          dimension: peerType === "ETF" ? "etfgeo" : "country",
          segments: [data?.peerInfo?.where ?? ""],
        });
      }

      if (yDim) {
        filterParams.filters.push({
          dimension: peerType === "ETF" ? "etfclass" : "icb",
          segments: [data?.peerInfo?.what ?? ""],
        });
      }

      if (
        data?.peerInfo.zDimension != null &&
        rootNodeZ != null &&
        data?.peerInfo.zDimension !== rootNodeZ.id
      ) {
        filterParams.filters.push({
          dimension: "sizeClassification",
          segments: [data?.peerInfo?.zDimension ?? ""],
        });
      }

      const properties = [
        { property: "symbol", date: null },
        { property: performanceDict[performanceAt], date: null },
        { property: "dr", date: null },
        { property: "rc", date: null },
        { property: "name", date: null },
        { property: "ticker", date: null },
        {
          property: peerType === "ETF" ? "etfgeo" : "country",
          date: null,
        },
        {
          property: peerType === "ETF" ? "etfclass" : "icb",
          date: null,
        },
      ];

      try {
        setIsLoading(true);
        setError(false);
        const response = await apiInstruments.newFilterAndFetch(
          filterParams,
          "security",
          properties,
          false
        );
        const total = response?.dataTotalCount ?? 0;
        setTotalSecurities(total);
        const securities = (response?.data ?? []).map((security) => ({
          ...security,
          q:
            data?.[tabSelector].quartilesSymbols?.[security?.symbol ?? ""] ??
            null,
        }));
        setSecurities(securities ?? []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    },
    [
      apiInstruments,
      data,
      environment,
      getTaxonomyMap,
      itemsPerPage,
      pageNmuber,
      performanceAt,
      rootNodeX,
      rootNodeY,
      tabSelector,
      tableSort.direction,
      tableSort.field,
    ]
  );

  const formatQuartiles = useCallback((cell: CellComponent) => {
    const data: any = cell.getData();
    const cellElement = cell.getElement();

    if (data?.q) {
      cellElement.style.backgroundColor = quartilesColorMap[data.q];
      return `q:${data.q}`;
    }

    cellElement.style.fontSize = "0.6vw";
    cellElement.style.color = "#333";

    return '<span title="The quartile statistic only includes ratings older than two weeks">less than 2 weeks</span>';
  }, []);

  useEffect(() => {
    if (data) {
      getSecuritiesByCluster(tabSelector);
    }
  }, [data, getSecuritiesByCluster, tabSelector]);

  useEffect(() => {
    if (data) {
      const selectedCluster = data[tabSelector];

      if (selectedCluster) {
        let info:
          | "no-additional-info"
          | "empty-universe"
          | "cannot-calculate-quartiles" = "no-additional-info";

        if (selectedCluster.securities.length < 4) {
          info = "cannot-calculate-quartiles";
        }

        if (!selectedCluster.peerAvg) {
          info = "empty-universe";
          setSecurities([]);
        }

        if (tabSelector === "AB") {
          setOutputInfoAB(info);
        } else {
          setOutputInfoCD(info);
        }
      }
    }
  }, [data, tabSelector]);

  const formatNumber = useCallback(
    (number: number | null, hasPositiveSign?: boolean) => {
      const applyPositiveSign =
        hasPositiveSign !== null ? hasPositiveSign : false;
      return formatter.custom("number", {
        options: {
          hasPositiveSign: applyPositiveSign,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "TEXT",
        value: number,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const handleTabSelection = useCallback(
    (value: "AB" | "CD") => {
      setPageNumber(1);
      setTableSort({
        field: performanceDict[performanceAt],
        direction: value === "AB" ? "asc" : "desc",
      });
      setTabSelector(value);
    },
    [performanceAt]
  );

  const handleChangeItemsPerPage = useCallback((e) => {
    const value = e.target.value;
    setItemsPerPage(value);
    setPageNumber(1);
  }, []);

  const tableRef = useRef<any>(null);

  const sort = useCallback(
    (e, column: ColumnComponent) => {
      let field = column.getField();

      if (field === "q") {
        field = performanceDict[performanceAt];
      }

      const toggleDirection = field === tableSort?.field;
      let dir = "asc";

      if (toggleDirection) {
        dir = tableSort.direction === "asc" ? "desc" : "asc";
      }

      setIsLoading(true);
      setTableSort({
        field: field,
        direction: dir,
      });

      if (pageNmuber !== 1) {
        setPageNumber(1);
      }
    },
    [pageNmuber, performanceAt, tableSort.direction, tableSort?.field]
  );

  const securitiesTableConfiguration: ColumnDefinition[] = useMemo(
    () => [
      {
        title: tableLabels.ticker,
        field: "ticker",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        widthGrow: 1,
      },
      {
        title: tableLabels.name,
        field: "name",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        widthGrow: 2,
      },
      {
        title: tableLabels.rc,
        field: performanceDict[performanceAt],
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatter.custom("rating", {
            options: {
              notAvailable: {
                input: 0,
                output: "-",
              },
            },
            output: "HTML",
            value: data["rc"],
            valueHelper: {
              rateChange: null,
              rateDate: null,
              ratePrev: null,
            },
          });
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.dr,
        field: "dr",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const data = cell.getData();
          return formatter.custom("date", {
            options: {
              format: ["D", "M", "Y"],
              isMillisecond: false,
              notAvailable: {
                input: "",
                output: "",
              },
              separator: " ",
            },
            output: "HTML",
            value: data["dr"],
            valueHelper: null,
          });
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.pr,
        field: performanceDict[performanceAt],
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const data: any = cell.getData();
          return formatNumber(data[performanceDict[performanceAt]], true);
        },
        widthGrow: 1,
      },
      {
        title: tableLabels.market,
        field: peerType === "ETF" ? "etfgeo" : "country",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const field = cell.getField();
          const cellData: any = cell.getData();

          return taxonomiesMapX?.[cellData[field]]?.name ?? cellData[field];
        },
        widthGrow: 2,
      },
      {
        title: tableLabels.sector,
        field: peerType === "ETF" ? "etfclass" : "icb",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => {
          const field = cell.getField();
          const cellData: any = cell.getData();

          const formatFieldBy = data?.formatterSectorBy ?? null;

          if (formatFieldBy) {
            const node = taxonomiesMapY[cellData[field]];
            const ancestor = getAncestorByType(
              cellData?.[field] ?? null,
              taxonomiesMapY,
              formatFieldBy
            );
            if (ancestor && ancestor.parent != null) {
              return ancestor?.name ?? cellData[field];
            } else {
              return node?.name ?? cellData[field];
            }
          }

          if (peerType === "ETF") {
            return (
              formatTaxonPrefixingParent(
                taxonomiesMapY[cellData[field]],
                [taxonomiesMapY],
                "3 Sector"
              ) ?? cellData[field]
            );
          } else {
            return taxonomiesMapY?.[cellData[field]]?.name ?? cellData[field];
          }
        },
        widthGrow: 3,
      },
      {
        title: tableLabels.q,
        field: "q",
        sorter: () => 0,
        headerClick: (event, column) => sort(event, column),
        formatter: (cell) => formatQuartiles(cell),
        widthGrow: 1,
      },
    ],
    [
      data?.formatterSectorBy,
      formatNumber,
      formatQuartiles,
      formatter,
      peerType,
      performanceAt,
      sort,
      tableLabels.dr,
      tableLabels.market,
      tableLabels.name,
      tableLabels.pr,
      tableLabels.q,
      tableLabels.rc,
      tableLabels.sector,
      tableLabels.ticker,
      taxonomiesMapX,
      taxonomiesMapY,
    ]
  );

  const tableOptions = useMemo(() => ({ ajaxSorting: false }), []);

  const firstQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["1"] ?? null, true),
    [data, formatNumber]
  );
  const secondQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["2"] ?? null, true),
    [data, formatNumber]
  );
  const thirdQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["3"] ?? null, true),
    [data, formatNumber]
  );
  const fourthQuartileAB = useMemo(
    () => formatNumber(data?.["AB"]?.quartileAvg?.["4"] ?? null, true),
    [data, formatNumber]
  );
  const cumulativeAvgAB = useMemo(
    () => formatNumber(data?.["AB"]?.peerAvg ?? null, true),
    [data, formatNumber]
  );

  const firstQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["1"] ?? null, true),
    [data, formatNumber]
  );
  const secondQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["2"] ?? null, true),
    [data, formatNumber]
  );
  const thirdQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["3"] ?? null, true),
    [data, formatNumber]
  );
  const fourthQuartileCD = useMemo(
    () => formatNumber(data?.["CD"]?.quartileAvg?.["4"] ?? null, true),
    [data, formatNumber]
  );
  const cumulativeAvgCD = useMemo(
    () => formatNumber(data?.["CD"]?.peerAvg ?? null, true),
    [data, formatNumber]
  );

  const onChangePerformanceAt = useCallback(
    (value) => {
      if (enablePerformanceSelection === true && setPerformanceAt) {
        setPerformanceAt(value);
      }
      dispatch("update-performance-since-trend-at", { performanceAt: value });
    },
    [dispatch, enablePerformanceSelection, setPerformanceAt]
  );

  return (
    <Box
      minHeight={0}
      display={"flex"}
      flex={1}
      flexDirection={"column"}
      minWidth={0}
    >
      <Box p={1} minHeight={0} display={"flex"} flex={1} minWidth={0}>
        <Box
          mr={1}
          p={1}
          borderRadius={"4px"}
          boxShadow={3}
          minWidth={0}
          overflow={"auto"}
          minHeight={0}
          // flexDirection={"column"}
          bgcolor={"white"}
        >
          {enablePerformanceSelection && (
            <Box display={"flex"} gap={1} alignItems={"center"} mb={1}>
              <Typography>Performance</Typography>
              <SelectorETFMarkets
                options={performanceOptions}
                selectedOption={performanceAt}
                selectOption={onChangePerformanceAt}
              />
            </Box>
          )}
          <PerformanceQuartilesTable
            tabSelector={"AB"}
            performanceAt={performanceAt}
            firstQuartile={firstQuartileAB}
            secondQuartile={secondQuartileAB}
            thirdQuartile={thirdQuartileAB}
            fourthQuartile={fourthQuartileAB}
            cumulativeAvg={cumulativeAvgAB}
            outputInfo={outputInfoAB}
            isCardActive={tabSelector === "AB"}
            selectTab={handleTabSelection}
            // cardinality={data?.["AB"]?.securities?.length ?? null}
          />

          <PerformanceQuartilesTable
            performanceAt={performanceAt}
            tabSelector={"CD"}
            hasMarginTop={true}
            firstQuartile={firstQuartileCD}
            secondQuartile={secondQuartileCD}
            thirdQuartile={thirdQuartileCD}
            fourthQuartile={fourthQuartileCD}
            cumulativeAvg={cumulativeAvgCD}
            outputInfo={outputInfoCD}
            isCardActive={tabSelector === "CD"}
            selectTab={handleTabSelection}
            // cardinality={data?.["CD"]?.securities?.length ?? null}
          />
        </Box>
        <Box
          display={"flex"}
          p={1}
          flex={3}
          borderRadius={"4px"}
          boxShadow={3}
          minHeight={0}
          minWidth={0}
          bgcolor={"white"}
          flexDirection={"column"}
        >
          {!err ? (
            <>
              <Box display={isLoading ? "flex" : "none"} flex={1}>
                <Loader />
              </Box>
              <Box display={isLoading ? "none" : "flex"} flex={1}>
                <Box
                  flex={1}
                  fontSize={"1.4vw"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb={2}
                >
                  {tabSelector === "AB" ? (
                    <span>
                      <strong className="rate rate--A">A</strong>
                      <strong className="rate rate--B">B</strong>
                    </span>
                  ) : (
                    <span onClick={() => handleTabSelection("CD")}>
                      <strong className="rate rate--C">C</strong>
                      <strong className="rate rate--D">D</strong>
                    </span>
                  )}
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Paginator
                    dataTotalCount={totalSecurities}
                    initValue={pageNmuber}
                    itemsPerPage={itemsPerPage}
                    handlePaginationChange={setPageNumber}
                  />
                </Box>
                {/* Items per page select */}
                <Box
                  flex={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Typography>Securities per page:</Typography>
                  <FormControl size="small">
                    <Select
                      sx={{ fontSize: "0.8vw", ml: 1 }}
                      labelId="itemsPerPage-select-small"
                      id="itemsPerPage-select-small"
                      value={itemsPerPage}
                      onChange={handleChangeItemsPerPage}
                    >
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {!isLoading && (
                <Box display={"flex"} minHeight={0} minWidth={0}>
                  <Box flex={1} minWidth={0}>
                    <TrendratingTable
                      ref={tableRef}
                      tooltip={{
                        actions: { info: { enabled: true } },
                      }}
                      sorting={tableSort}
                      options={tableOptions}
                      autoResize={true}
                      data={securities ?? []}
                      columns={securitiesTableConfiguration}
                    />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Card sx={{ marginTop: 1 }}>
              <CardContent>
                <span>
                  <strong style={{ color: "red" }}>
                    Sorry somenthing goes wrong while loading securities, if the
                    problem persists contact our customer support
                  </strong>
                </span>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ResizerWrapper = ({ children }: ResizerWrapperProps) => {
  const containerRef = useRef(null);

  useResizer({ ref: containerRef });
  return (
    <Box display={"flex"} flex={1} minWidth={0} ref={containerRef}>
      {children}
    </Box>
  );
};

const Loader = () => {
  return (
    <Box
      display={"flex"}
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Spinner />
    </Box>
  );
};

export const PerformanceQuartilesTable = ({
  outputInfo,
  tabSelector,
  firstQuartile,
  secondQuartile,
  thirdQuartile,
  fourthQuartile,
  cumulativeAvg,
  hasMarginTop = false,
  hasTitle = true,
  isCardActive,
  selectTab,
  cardinality,
  performanceAt,
  label,
}: PerformanceQuartilesCardProps) => {
  return (
    <table
      onClick={() => (selectTab ? selectTab(tabSelector) : undefined)}
      className={`${styles.quartiles__table} ${
        !selectTab ? styles.unhoverable : ""
      }  ${isCardActive ? styles.quartiles__table__active : ""}`}
    >
      <thead>
        <tr>
          <th>
            {tabSelector === "AB" ? (
              <span
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong className="rate rate--A">A</strong>
                <strong className="rate rate--B">B</strong>

                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {cardinality ?? ""}
                </p>
              </span>
            ) : (
              <span
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong className="rate rate--C">C</strong>
                <strong className="rate rate--D">D</strong>

                <p
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {cardinality ?? ""}
                </p>
              </span>
            )}
          </th>
          <th>
            {hasTitle && (
              <span>
                <span>{label ?? titleDict[performanceAt]}</span>
              </span>
            )}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "1st" : "4th"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? firstQuartile : fourthQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "2nd" : "3rd"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? secondQuartile : thirdQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "3rd" : "2nd"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? thirdQuartile : secondQuartile}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{
                flex: 1,
              }}
            >
              {tabSelector === "AB" ? "4th" : "1st"}
            </span>
            <span style={{ flex: 6, marginLeft: "5px" }}>Quartile</span>
          </td>
          <td>
            <span style={{ flex: 4 }}>
              {tabSelector === "AB" ? fourthQuartile : firstQuartile}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <span style={{ flex: 1 }}>Average:</span>
          </td>
          <td>
            <span style={{ flex: 1, textAlign: "right" }}>
              <strong>{cumulativeAvg}</strong>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

const PerformanceSinceRatedSkeleton = () => {
  return (
    <Box
      p={1}
      mt={1}
      borderRadius={"4px"}
      boxShadow={3}
      display={"flex"}
      flex={1}
    >
      <Box display={"flex"} flex={3} mr={1}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display={"flex"} flex={9}>
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />

              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />
              <Skeleton variant="rectangular" width={"100%"} height={300} />
              <Skeleton variant="rectangular" width={"100%"} height={30} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
