import RestoreIcon from "@mui/icons-material/Restore";
import { ClickAwayListener, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { decodePeerId } from "../../../../../../../api/utils";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import styles from "./BackNavigation.module.scss";

type BackNavigationProps = {
    navigationHistory: any;
    backHandler: ({
        peer,
        analytic,
    }: {
        peer: string;
        analytic:
            | "tcr"
            | "history"
            | "dispersion"
            | "securities"
            | "dispersionBy";
    }) => void;
};

export default function BackNavigation({
    navigationHistory,
    backHandler,
}: BackNavigationProps) {
    const options: any = useMemo(
        () => navigationHistory.reverse() ?? [],
        [navigationHistory]
    );

    const [open, setOpen] = useState(false);
    const drawerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { taxonomiesMapY, taxonomiesMapX, rootNodeX, rootNodeY } =
        useTaxonomyByType("ETF");

    const handleDrawerOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const goToSelectedPeer = useCallback(
        (option) => {
            setOpen(false);
            backHandler(option);
        },
        [backHandler]
    );

    const closeDrawer = useCallback((e) => {
        const target = e.target;

        if (target !== buttonRef.current) {
            setOpen(false);
        }
    }, []);

    return options.length ? (
        <div key={"top"} className={styles.widget}>
            <button
                ref={buttonRef}
                onClick={handleDrawerOpen}
                className={styles["button-back__navigator"]}
                title={"Recently viewed"}
            >
                <RestoreIcon
                    sx={{
                        fontSize: "18px!important",
                        color: "#2a7090!important",
                        pointerEvents: "none",
                    }}
                />
            </button>

            <ClickAwayListener onClickAway={closeDrawer}>
                <div
                    ref={drawerRef}
                    className={`${styles.drawer} ${
                        open ? styles["drawer--open"] : ""
                    }`}
                >
                    <Typography
                        sx={{
                            color: "#2A7091",
                            paddingBottom: "5px",
                            fontSize: "16px",
                            borderBottom: "1px solid #dddd",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <RestoreIcon
                            sx={{
                                fontSize: "18px!important",
                                color: "#2a7090!important",
                                marginRight: "5px",
                            }}
                        />
                        Recently viewed{" "}
                    </Typography>
                    <ul className={styles.option__list}>
                        {options.map((option) => {
                            const { what, where, zDimension } = decodePeerId(
                                option.peer
                            );
                            return (
                                <li
                                    onClick={() => goToSelectedPeer(option)}
                                    key={uuidv4()}
                                    style={{
                                        fontSize: "10px",
                                    }}
                                    value={option.peer}
                                >
                                    {what !== rootNodeY
                                        ? taxonomiesMapY?.[what]?.name ?? what
                                        : "All Asset Class"}
                                    {where !== rootNodeX
                                        ? " - " +
                                              taxonomiesMapX?.[where]?.name ??
                                          where
                                        : ""}
                                    {zDimension !== "ALL"
                                        ? " - " + zDimension
                                        : ""}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </ClickAwayListener>
        </div>
    ) : (
        <></>
    );
}
