/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/date/TDate
 * @summary Utils to manipulate Trendrating dates based on days
 *
 */
export class TDate {
  /**
   * Converts a native Date object to days (UTC) managing timezone
   *
   * @param {Date} dateObject - a native Date instance
   *
   * @returns {number} days
   */
  static dateToDays(dateObject: Date) {
    var milliseconds = dateObject.getTime();
    var timezoneOffset = dateObject.getTimezoneOffset(); // minutes

    var value = milliseconds - timezoneOffset * 60 * 1000;

    return this.millisecondsToDays(value);
  }

  /**
   * Convert a date object in an ISO 8601 string
   *
   * @param {Date} date
   *
   * @return {string} - an ISO 8601 string
   */
  static dateToIso8601(date: Date) {
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    var formatted = [year, this._prefixZero(month), this._prefixZero(day)].join(
      "-"
    );

    return formatted;
  }

  /**
   * Converts days into a native Date object
   *
   * @param {number} days - number of days
   *
   * @returns {Date} a native Date object
   */
  static daysToDate(days: number) {
    let date = new Date(this.daysToMilliseconds(days));
    date.setHours(0, 0, 0, 0); // Reset hour, min, seconds, ms
    return date;
  }

  /**
   * Convert days in an ISO 8601 string
   *
   * @param {number} days
   *
   * @return {string} - an ISO 8601 string
   */
  static daysToIso8601(days: number) {
    var milliseconds = this.daysToMilliseconds(days);
    var date = new Date(milliseconds);

    var day = date.getUTCDate();
    var month = date.getUTCMonth() + 1;
    var year = date.getUTCFullYear();

    var formatted = [year, this._prefixZero(month), this._prefixZero(day)].join(
      "-"
    );

    return formatted;
  }

  /**
   * Converts days in milliseconds
   *
   * @param {number} days - number of days
   *
   * @returns {number} milliseconds
   */
  static daysToMilliseconds(days: number) {
    var quotient = Math.floor(days / 5);
    var remainder = Math.floor(days % 5);
    var milliseconds = Math.floor(
      (quotient * 7 + remainder + 4) * 86400 * 1000
    );
    return milliseconds;
  }

  /**
   * Converts milliseconds in days excluding weekends
   *
   * @param {number} milliseconds - milliseconds
   *
   * @returns {number} days
   */
  static millisecondsToDays(milliseconds: number) {
    // http://stackoverflow.com/questions/4055633/what-does-double-tilde-do-in-javascript
    // ~ not: bitwise operator
    // ~~ -> (int)
    var seconds = milliseconds / 1000;
    var t = ~~(seconds / 86400) - 4;
    var quotient = ~~(t / 7);
    var remainder = ~~(t % 7);
    if (remainder === 6) {
      remainder = 5; // sunday as saturday
    }
    var days = quotient * 5 + remainder;
    return days;
  }

  /**
   * Return today as Trendrating days
   *
   * @returns {number} days
   */
  static today() {
    return this.dateToDays(new Date());
  }

  /**
   * Return yesterday as Trendrating days
   *
   * @returns {number} days
   */
  static yesterday() {
    var today = this.today();

    return today - 1;
  }

  getWeekDay(days) {
    var day = new Date(TDate.daysToMilliseconds(days));
    return day.getUTCDay();
  }
  getMonth(days) {
    var day = new Date(TDate.daysToMilliseconds(days));
    return day.getUTCMonth();
  }

  getForward1Week(millisecondsStart?) {
    var day =
      millisecondsStart !== undefined && millisecondsStart != null
        ? TDate.millisecondsToDays(millisecondsStart)
        : TDate.today();

    for (let i = 0; i < 6; i++) {
      if (this.getWeekDay(day + i) === 1) {
        return new Date(TDate.daysToMilliseconds(day + i));
      }
    }
    return new Date(TDate.daysToMilliseconds(day));
  }

  getForward1Month(millisecondsStart?) {
    var day =
      millisecondsStart !== undefined && millisecondsStart != null
        ? TDate.millisecondsToDays(millisecondsStart)
        : TDate.today();

    var dayMonth = this.getMonth(day);
    // why 25? We have still to investigate deeper about this
    // On 02 Oct 2018 (with old 21) gives the wrong date
    for (let i = 1; i < 25; i++) {
      if (dayMonth !== this.getMonth(day + i)) {
        return new Date(TDate.daysToMilliseconds(day + i));
      }
    }
    return new Date(TDate.daysToMilliseconds(day));
  }

  getForward3Months(millisecondsStart?) {
    var day =
      millisecondsStart !== undefined && millisecondsStart != null
        ? TDate.millisecondsToDays(millisecondsStart)
        : TDate.today();

    var dayMonth = this.getMonth(day);
    var nextDayMonth: any = null;
    // why 65? We have still to investigate deeper about this
    // On 05 Jan 2018 (with old 61) gives the wrong date
    for (let i = 1; i < 65; i++) {
      nextDayMonth = this.getMonth(day + i);
      if (dayMonth !== nextDayMonth && nextDayMonth % 3 === 0) {
        return new Date(TDate.daysToMilliseconds(day + i));
      }
    }
    return new Date(TDate.daysToMilliseconds(day));
  }

  // ----------------------------------------------------- private methods
  static _prefixZero(value: number) {
    if (value < 10) {
      return "0" + value;
    }

    return value;
  }
}
