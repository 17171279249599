/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/environment
 * @summary Singleton for Application environment
 *
 */

// import declare from "dojo/_base/declare";
// import Memory from "dstore/Memory";
// import Trackable from "dstore/Trackable";
import { Properties } from "./api/Properties";
import { AppEnvironment } from "./types/Defaults";
import { PageStatus } from "./api/account/PageStatus";

// const Store = declare([Memory, Trackable]);

export class Environment {
  setup: AppEnvironment | undefined;

  _propertiesInstance: any;

  constructor(environmentSetup?: AppEnvironment) {
    if (environmentSetup == null) {
      console.log("Initializing environment with default / empty values");
      this.setup = {
        ...((window as any).appConfig ?? {}),
        account: {
          product: null,
          user: null,
        },
        configuration: null,
        customerCare: {
          isImpersonating: null,
          userId: null,
        },
        properties: null,
        storage: null,
        taxonomies: null,
        taxonomyFields: null,
        taxonomiesUi: null,
        widgetsConfiguration: null,
        preferenceStatus: null,
      };
    } else {
      this.setup = environmentSetup;
      this._propertiesInstance = new Properties({
        properties: environmentSetup["properties"],
      });
      const _taxonomies = this.setup == null ? null : this.setup["taxonomies"];
      const _taxonFields =
        this.setup == null ? null : this.setup["taxonomyFields"];
      if (_taxonomies != null) {
        var size: any = [];
        var what: any = [];
        var whatEtf: any = [];
        var whatCutted = _taxonomies["ui_sectors"]["data"];
        var where: any = [];

        for (const whatEtfItem of Object.values(
          _taxonomies[_taxonFields["ETF"]["etfclass"]]
        )) {
          whatEtf.push(whatEtfItem);
        }

        for (const whatItem of Object.values(
          _taxonomies[_taxonFields["security"]["sector"]]
        )) {
          what.push(whatItem);
        }

        for (const whereItem of Object.values(
          _taxonomies[_taxonFields["security"]["country"]]
        )) {
          where.push(whereItem);
        }

        for (const sizeItem of Object.values(
          _taxonomies["SizeClassification"]
        )) {
          size.push(sizeItem);
        }

        // Mix of taxonomy for ui usage and direct type (stock, etf)
        this.setup.taxonomiesUi = {
          array: {
            size: size,
            what: what,
            whatCutted: whatCutted,
            whatEtf: whatEtf,
            where: where,
          },
          size: size,
          what: what,
          whatCutted: whatCutted,
          whatEtf: whatEtf,
          where: where,
          etf: {
            size: size,
            what: whatEtf,
            where: where,
          },
          stock: {
            size: size,
            what: whatCutted,
            where: where,
          },
        };
      }
    }
  }

  get(
    property:
      | "account"
      | "configuration"
      | "customerCare"
      | "formatter"
      | "http"
      | "preferences"
      | "properties"
      | "rawProperties"
      | "rawTaxonomies"
      | "taxon"
      | "taxonomies"
      | "taxonomyFields"
      | "storage"
      | "preferenceStatus"
      | "setup"
  ) {
    switch (property) {
      case "account":
        return this._getAccount();
      case "configuration":
        return this._getConfiguration();
      case "customerCare":
        return this._getCustomerCare();
      case "formatter":
        return this._getFormatter();
      case "http":
        return this._getHttp();
      case "preferences":
        return this._getPreferences();
      case "properties":
        return this._getProperties();
      case "rawProperties":
        return this._getRawProperties();
      case "rawTaxonomies":
        return this._getRawTaxonomies();
      case "taxon":
        return this._getTaxonomyFormatter();
      case "taxonomies":
        // returns normalized taxonomies wrapped in a dstore
        return this._getTaxonomies();
      case "taxonomyFields":
        return this._getTaxonomyFields();
      case "storage":
        return this._getStorage();
      case "preferenceStatus":
        return this._getPagePreference();
      case "setup":
        return this.setup;
      default:
        return (this as any)[property] as any;
    }
  }

  //
  // targetType value are
  // - "instruments" for screening
  // - "list" for basket/portfolio
  //
  getWidgetsConfigurationToRank(targetType: string) {
    var configurationWidgetRanking = null;
    var configurationWidgetViewer = null;
    var defaultTemplateNameBase: any = null;
    var environmentSetup = this.setup;
    var pageConfiguration: any = null;

    if (environmentSetup == null) {
      throw new Error("Missing environment setup");
    }

    switch (targetType) {
      case "instruments":
      case "list":
      default: {
        pageConfiguration = environmentSetup["configuration"].get("ranking");

        configurationWidgetRanking = pageConfiguration["widgets"]["ranking"];

        configurationWidgetViewer = pageConfiguration["widgets"]["viewer"];

        defaultTemplateNameBase = "DEFAULT_PORTFOLIO";
      }
      /* case "instruments": {
                    // not used
                    pageConfiguration = environmentSetup["configuration"].get(
                        "screening"
                    );
                    configurationWidgetRanking =
                        pageConfiguration["widgets"]["ranking"];

                    configurationWidgetViewer =
                        pageConfiguration["widgets"]["viewer"];

                    defaultTemplateNameBase = "DEFAULT_SCREENING";

                    break;
                }
                case "list":
                default: {
                    pageConfiguration = environmentSetup["configuration"].get(
                        "analysisList"
                    );

                    configurationWidgetRanking =
                        pageConfiguration["tabs"][
                            pageConfiguration["tabsIndex"]["ranking"]
                        ]["widgets"]["ranking"];

                    configurationWidgetViewer =
                        pageConfiguration["tabs"][
                            pageConfiguration["tabsIndex"]["ranking"]
                        ]["widgets"]["viewer"];

                    defaultTemplateNameBase = "DEFAULT_PORTFOLIO";
                } */
    }

    var widgetsRankConfiguration = {
      defaultTemplateNameBase: defaultTemplateNameBase,
      properties: environmentSetup["properties"],
      ranking: configurationWidgetRanking,
      targetType: targetType,
      viewer: configurationWidgetViewer,
    };

    return widgetsRankConfiguration;
  }

  set(property: string, value: any) {
    switch (property) {
      case "setup": {
        this.setup = value;

        break;
      }
      default: {
        throw new Error("Unsupported property: " + property);
      }
    }
  }
  // ----------------------------------------------------- private methods

  // Fallback to same field, used by render.tsx when injecting like http...

  _getPagePreference(): AppEnvironment["preferenceStatus"] {
    if (this.setup?.preferenceStatus instanceof PageStatus) {
      return this.setup.preferenceStatus;
    } else {
      const userId = this.setup?.account.user?.id;

      this.setup!.preferenceStatus = new PageStatus(userId, this.setup!);

      return this.setup?.preferenceStatus;
    }
  }

  _getAccount(): AppEnvironment["account"] {
    return this.setup == null || this.setup["account"] == null
      ? this["account"]
      : this.setup["account"];
  }

  _getConfiguration(): AppEnvironment["configuration"] {
    return this.setup == null || this.setup["configuration"] == null
      ? this["configuration"]
      : this.setup["configuration"];
  }

  _getCustomerCare(): AppEnvironment["customerCare"] {
    return this.setup == null || this.setup["customerCare"] == null
      ? this["customerCare"]
      : this.setup["customerCare"];
  }

  _getFormatter(): AppEnvironment["formatter"] {
    return this.setup == null || this.setup["formatter"] == null
      ? this["formatter"]
      : this.setup["formatter"];
  }

  _getTaxonomyFormatter(): AppEnvironment["taxon"] {
    return this.setup == null || this.setup["taxon"] == null
      ? this["taxon"]
      : this.setup["taxon"];
  }

  _getHttp(): AppEnvironment["http"] {
    return this.setup == null || this.setup["http"] == null
      ? this["http"]
      : this.setup["http"];
  }

  _getPreferences(): AppEnvironment["preferences"] {
    return this.setup == null || this.setup["preferences"] == null
      ? this["preferences"]
      : this.setup["preferences"];
  }

  _getProperties(): AppEnvironment["properties"] {
    return this._propertiesInstance;
  }

  _getRawProperties(): any {
    return this.setup == null
      ? null
      : this.setup["configuration"]["properties"];
  }

  _getRawTaxonomies(): AppEnvironment["taxonomies"] {
    return this.setup == null ? null : this.setup["taxonomies"];
  }

  _getStorage(): AppEnvironment["storage"] {
    return this.setup == null ? null : this.setup["storage"];
  }

  //
  // returns normalized taxonomies wrapped in a dstore
  //
  // don't support ETFs: refactoring needed
  //
  _getTaxonomies() {
    return this.setup == null ? null : this.setup["taxonomiesUi"];
  }

  _getTaxonomyFields() {
    return this.setup == null ? null : this.setup["taxonomyFields"];
  }
  // --------------------------------------------------- getters / setters
}
