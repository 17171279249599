import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { deepClone } from "../../../../../../../deepClone";

type Props = {
  templates: any;
  selectedTemplate: any;
  onSelectTemplate: Function;
  onRename: Function;
  onDelete: Function;
};

export default function FastAccess({
  templates,
  selectedTemplate,
  onSelectTemplate,
  onRename,
  onDelete,
}: Props) {
  const state: any[] = useMemo(() => {
    return deepClone(templates);
  }, [templates]);

  const [, setValue] = useState(selectedTemplate);
  useEffect(() => {
    setValue(selectedTemplate);
  }, [selectedTemplate]);

  const onSelectTemplateHandler = useCallback(
    (e) => {
      const template = state[e.target.value];
      onSelectTemplate(template);
    },
    [onSelectTemplate, state]
  );

  return (
    <Box
      display={"flex"}
      gap={1}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Box width={"100%"} display={"flex"}>
        <Select
          sx={{ width: "100%" }}
          onChange={onSelectTemplateHandler}
          size="small"
          value={selectedTemplate.index ?? "-"}
        >
          {state.map((template, index) => (
            <MenuItem key={index} value={index}>
              <Typography>
                {template.name}
                {!template.isEditable && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;',
                    }}
                  ></span>
                )}
              </Typography>
            </MenuItem>
          ))}
          {selectedTemplate.name === "LAST_USED_TEMPLATE" && (
            <MenuItem value={"-"} disabled>
              <Typography>Last used</Typography>
            </MenuItem>
          )}
        </Select>
      </Box>
      <Box display={"flex"} gap={1}>
        <Button
          disabled={
            selectedTemplate.id === null ||
            selectedTemplate.name === "LAST_USED_TEMPLATE"
          }
          onClick={() => onRename(selectedTemplate)}
        >
          Rename
        </Button>
        <Button
          disabled={
            selectedTemplate.id === null ||
            selectedTemplate.name === "LAST_USED_TEMPLATE"
          }
          onClick={() => onDelete(selectedTemplate)}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
}
