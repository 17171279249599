import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { Strategies } from "../../../../../../../../../../api/compute/Strategies";
import { messageError } from "../../../../../../../../utils";
import { Box } from "@mui/material";
import { StrategySummary } from "../../../../../../../../../../components/StrategySummary/StrategySummary";
import { Strategy } from "../../../../../../../../../../types/Api";
import { useBroadcast } from "../../../../../../../../../../hooks/useBroadcast";

type StrategyDefinitionProps = {
  id: string;
  id2: string;
};

export function StrategyDefinition({ id, id2 }: StrategyDefinitionProps) {
  const [strategies, setStrategies] = useState<{
    firstStrategy: null | Strategy;
    secondStrategy: null | Strategy;
  }>({
    firstStrategy: null,
    secondStrategy: null,
  });

  const environment = useEnvironment();
  const appSetup = environment.get("setup");
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const { broadcast } = useBroadcast();

  const getStrategies = useCallback(async () => {
    try {
      const strategy1 = await strategiesAPI.getById(id);
      const strategy2 = await strategiesAPI.getById(id2);

      setStrategies({ firstStrategy: strategy1, secondStrategy: strategy2 });
    } catch (error) {
      const [channel, msg] = messageError(error);
      broadcast(channel as string, msg);
    }
  }, [broadcast, id, id2, strategiesAPI]);

  useEffect(() => {
    getStrategies();
  }, [getStrategies]);

  return (
    <Box display={"flex"} gap={1} padding={1}>
      {strategies.firstStrategy && (
        <Box flex={1}>
          <Box mb={1} display={"flex"} gap={1} alignItems={"center"}>
            <strong>{strategies.firstStrategy.name}</strong>
            {strategies.firstStrategy.isReadOnly && (
              <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
            )}
          </Box>
          <StrategySummary strategy={strategies.firstStrategy} />
        </Box>
      )}
      {strategies.secondStrategy && (
        <Box flex={1}>
          <Box mb={1} display={"flex"} gap={1} alignItems={"center"}>
            <strong>{strategies.secondStrategy.name}</strong>
            {strategies.secondStrategy.isReadOnly && (
              <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
            )}
          </Box>
          <StrategySummary strategy={strategies.secondStrategy} />
        </Box>
      )}
    </Box>
  );
}
