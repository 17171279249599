import { Box, Card, CardContent } from "@mui/material";
import React, { useMemo } from "react";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import {
  _formatPeerName,
  _formatPercentage,
  _getHoldingsWeightedPerformanceNumDen,
} from "./utils";

type Props = {
  state: any;
  contributionData: any;
  contributionHoldings: any;
};

export default function Performance({
  state,
  contributionData,
  contributionHoldings,
}: Props) {
  const format = useFormatter();
  const environment = useEnvironment();

  const output = useMemo(() => {
    if (state) {
      return _renderBoxPerformance(
        state,
        format,
        environment,
        contributionData,
        contributionHoldings
      );
    }
  }, [contributionData, contributionHoldings, environment, format, state]);
  return output ? (
    <Card sx={{ boxShadow: 3, flex: 2 }}>
      <CardContent sx={{ overflow: "auto", p: 1, pb: "8px !important" }}>
        {output.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </CardContent>
    </Card>
  ) : null;
}

const _renderBoxPerformance = (
  state,
  format,
  environment,
  contributionData,
  contributionHoldings
) => {
  let output: React.ReactElement[] = [];
  let contributions = contributionData;

  let hasEdging = false;
  let hasLong = state.long.length > 0;
  let hasShort = state.short.length > 0;

  if (hasLong && hasShort) {
    hasEdging = true;
  }

  if (contributions) {
    var data = contributions.data;
    if (!hasEdging) {
      // Performance i_g
      output.push(
        <Box>
          <Box>Performance</Box>
          <table style={{ width: "100%", borderSpacing: 0 }}>
            <tbody>
              <tr>
                <td>
                  <strong> {_formatPercentage(data["i_g"], format)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      );
    } else {
      // Performance        Contribution
      // Strategy i_g
      // Hedging s_g        s_c
      // Portfolio l_g      l_c
      const date = data.de;

      const shortPortfolioData = state.short;
      const longPortfolioData = state.long;

      const reduceIntoDateMap = (arr) =>
        arr.reduce(
          (prev, current) => ({
            ...prev,
            [current.d]: current,
          }),
          {}
        );

      const shortMap = reduceIntoDateMap(shortPortfolioData);

      const longMap = reduceIntoDateMap(longPortfolioData);

      const shortAllocation = shortMap?.[date] ?? undefined;
      const longAllocation = longMap?.[date] ?? undefined;

      const shortWeight = shortAllocation.A;
      const longWeight = longAllocation.A;

      const shortContribution = shortAllocation.P;
      const longContribution = longAllocation.P;

      const shortPerformance = shortAllocation.P / shortWeight;
      const longPerformance = longAllocation.P / longWeight;

      output.push(
        <Box>
          <table
            style={{
              width: "100%",
              borderSpacing: 0,
              borderCollapse: "collapse",
            }}
          >
            <tbody>
              <tr style={{ borderBottom: "1px solid #ddd" }}>
                <td colSpan={4}>Performance</td>
                <td style={{ textAlign: "right" }}>
                  <strong>{_formatPercentage(data["i_g"], format)}</strong>
                </td>
              </tr>
              <tr>
                <td colSpan={5}>Contributions </td>
              </tr>

              <tr>
                <td>Long</td>
                <td style={{ textAlign: "right" }}>
                  {_formatPercentage(longWeight, format)}
                </td>
                <td style={{ textAlign: "center" }}>x</td>
                <td style={{ textAlign: "right" }}>
                  {_formatPercentage(longPerformance, format)}
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>{_formatPercentage(longContribution, format)}</strong>
                </td>
              </tr>

              <tr>
                <td>Short</td>
                <td style={{ textAlign: "right" }}>
                  {_formatPercentage(shortWeight, format)}
                </td>
                <td style={{ textAlign: "center" }}>x</td>
                <td style={{ textAlign: "right" }}>
                  {_formatPercentage(shortPerformance, format)}
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>
                    {_formatPercentage(shortContribution, format)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      );
    }
  } else {
    output.push(
      <Box>
        <Box>Performance</Box>
        <table style={{ width: "100%", borderSpacing: 0 }}>
          <tbody>
            <tr>
              <td>
                <strong>{_formatPercentage(0, format)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    );
  }

  var peer = state?.contributionsFiltered?.peer;
  var holdings = contributionHoldings;
  if (peer && holdings) {
    var numDen = _getHoldingsWeightedPerformanceNumDen(holdings);

    var totalPerformance = numDen.denominator
      ? numDen.numerator / numDen.denominator
      : 0;
    let performanceExtra = (
      <Box>
        {_formatPeerName(peer, environment)}
        {": "}
        <strong>{_formatPercentage(totalPerformance, format)}</strong>
      </Box>
    );
    output.push(performanceExtra);
  }

  return output;
};
