import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { useMemo } from "react";

type Props = {
  type: "overview" | "detailed";
  onSetType: Function;
};

export default function ReportType({ onSetType, type }: Props) {
  const buttons = useMemo(() => {
    let btns: any[] = [];
    const labels = ["overview", "detailed"];
    labels.forEach((btn, index) => {
      btns.push(
        <Button key={index}
          onClick={() => onSetType(btn)}
          variant={type === btn ? "contained" : undefined}
        >
          {btn === "detailed" ? "Detailed" : "Overview"}
        </Button>
      );
    });
    return btns;
  }, [onSetType, type]);
  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent sx={{ p: 1, pb: "8px !important" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography variant="tr_subtitle_blue">Select report type</Typography>
          <ButtonGroup>{buttons}</ButtonGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
