import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { Chart } from "../../../../../components/Chart";

type ChartPeerPerformanceProps = {
  data: any;
  field: string;
  security: any;
};

const barWidth = 21;
const colors = ["#bfdfef", "#a0cee5", "#87bfdb", "#75b2d1"];

const tooltip = {
  q0: "",
  q1: "Lowest quartile",
  q2: "Third quartile",
  q3: "Second quartile",
  q4: "Highest quartile",
};

export function ChartPeerPerformance({
  data,
  field,
  security,
}: ChartPeerPerformanceProps) {
  const [chartSetup, setChartSetup] = useState<any>();

  const format = useFormatter();

  const tooltipFormat = useMemo(
    () => ({
      formatter: function () {
        var options = {
          notAvailable: {
            input: null,
            output: "",
          },
          isPercentage: true,
        };

        const self: any = this;

        var security = self.point.series.userOptions.security;
        var securityText = "";
        if (security != null) {
          var securityField = self.point.series.userOptions.field;
          securityText =
            "<strong>" +
            security["ticker"] +
            "</strong>: " +
            format.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: parseFloat(security[securityField]),
              valueHelper: null,
            });
        }

        var high = format.custom("number", {
          options: options,
          output: "HTML",
          value: self.point.high,
          valueHelper: null,
        });
        var low = format.custom("number", {
          options: options,
          output: "HTML",
          value: self.point.low,
          valueHelper: null,
        });

        if (self.point.low == null || self.point.high == null) {
          return securityText;
        }

        return [
          securityText,
          "<br><br>",
          self.series.name,
          '<br/><span style="font-size:80%">From</span> <strong style="font-size:80%">',
          low,
          '</strong> to <strong style="font-size:80%">',
          high,
          "</strong>",
        ].join("");
      },
      hideDelay: 200,
      outside: true,
    }),
    [format]
  );

  const prepareData = useCallback(
    (/*Object*/ data, /*String*/ field, /*Object*/ security) => {
      if (data?.[field] == null) {
        console.trace(
          "ChartPeerPerformance: unknown field " + field + " in data"
        );
        return;
      }

      var quantiles = data[field];

      // Elaborate quantiles depending on case
      var q0 = quantiles["q0"];
      var q1 = quantiles["q1"];
      var q2 = quantiles["q2"];
      var q3 = quantiles["q3"];
      var q4 = quantiles["q4"];

      var series: any = [];

      // Check different cases
      if (q1 == null) {
        series = [
          {
            name: tooltip.q0,
            data: [[q0, q0]],
            color: colors[0],
            type: "columnrange",
          },
        ];
      } else if (q2 == null) {
        series = [
          {
            name: tooltip.q0,
            data: [[q0, q1]],
            color: colors[0],
            type: "columnrange",
          },
        ];
      } else if (q3 == null) {
        series = [
          {
            name: tooltip.q1,
            data: [[q0, q1]],
            color: colors[0],
            type: "columnrange",
          },
          {
            name: tooltip.q4,
            data: [[q1, q2]],
            color: colors[1],
            type: "columnrange",
          },
        ];
      } else if (q4 == null) {
        series = [
          {
            name: tooltip.q1,
            data: [[q0, q1]],
            color: colors[0],
            type: "columnrange",
          },
          {
            name: tooltip.q3,
            data: [[q1, q2]],
            color: colors[1],
            type: "columnrange",
          },
          {
            name: tooltip.q4,
            data: [[q2, q3]],
            color: colors[2],
            type: "columnrange",
          },
        ];
      } else {
        series = [
          {
            name: tooltip.q1,
            data: [[q0, q1]],
            color: colors[0],
            type: "columnrange",
          },
          {
            name: tooltip.q2,
            data: [[q1, q2]],
            color: colors[1],
            type: "columnrange",
          },
          {
            name: tooltip.q3,
            data: [[q2, q3]],
            color: colors[2],
            type: "columnrange",
          },
          {
            name: tooltip.q4,
            data: [[q3, q4]],
            color: colors[3],
            type: "columnrange",
          },
        ];
      }

      var valueIndex = 0;
      var value = parseFloat(security[field]);

      if (q1 == null || value <= q1) {
        valueIndex = 0;
      } else if (q2 == null || value <= q2) {
        valueIndex = 1;
      } else if (q3 == null || value <= q3) {
        valueIndex = 2;
      } else {
        valueIndex = 3;
      }
      series[valueIndex]["field"] = field;
      series[valueIndex]["security"] = security;
      if (
        q1 == null ||
        (q2 == null && q0 === q1) ||
        (q3 == null && q0 === q2) ||
        (q4 == null && q0 === q3) ||
        q0 === q4
      ) {
        series = [
          {
            name: "Peer group",
            data: [[0, q0]],
            color: colors[3],
            type: "scatter",
            marker: {
              symbol: "circle",
              radius: 16,
              lineColor: "white",
              lineWidth: 1,
            },
            field: field,
            security: security,
          },
        ];
      }

      // fix possible bug on missing values, ex. q4 = NaN
      if (q1 == null) {
        q4 = q0;
      } else if (q2 == null) {
        q4 = q1;
      } else if (q3 == null) {
        q4 = q2;
      } else if (q4 == null) {
        q4 = q3;
      }

      return {
        min: security[field] == null ? q0 : Math.min(q0, security[field]),
        max: security[field] == null ? q4 : Math.max(q4, security[field]),
        series: series,
      };
    },
    []
  );

  const onPageMount = useCallback(() => {
    var preparedData = prepareData(data, field, security);

    if (!preparedData) {
      return;
    }

    var yAxis = {
      min: preparedData.min,
      max: preparedData.max,
      labels: { enabled: false },
      endOnTick: false,
      gridLineWidth: 0,
      startOnTick: false,
      maxPadding: 0,
      minPadding: 0,
      lineWidth: 0,
      title: { text: null },
    };

    if (security[field] != null) {
      yAxis["plotLines"] = [
        {
          value: parseFloat(security[field]),
          color: "#2a7092",
          width: 3,
          zIndex: 99,
        },
      ];
    }

    const chartConfiguration = {
      chart: {
        borderWidth: 0,
        marginTop: 2,
        marginBottom: 2,
        height: 25,
        inverted: true,
      },
      legend: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: { text: null },
      tooltip: tooltipFormat,
      series: preparedData.series,
      xAxis: {
        categories: ["peer"],
        title: { text: null },
        labels: { enabled: false },
        lineWidth: 0,
        tickWidth: 0,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          animation: false,
          pointWidth: barWidth,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
        columnrange: {
          grouping: false,
          shadow: false,
          dataLabels: { enabled: false },
        },
      },
      security: security,
    };

    setChartSetup(chartConfiguration);
  }, [data, field, prepareData, security, tooltipFormat]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return chartSetup != null ? (
    <Chart constructorType="chart" options={chartSetup} />
  ) : (
    <></>
  );
}
