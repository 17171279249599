import { useEffect, useMemo } from "react";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { useProperties } from "../../../../../hooks/useProperties";
import { useTaxon } from "../../../../../hooks/useTaxon";

type TabOverviewStockProps = {
    value: any;
};

export function TabOverviewStock({ value }: TabOverviewStockProps) {
    const format = useFormatter();
    const properties = useProperties();
    const taxon = useTaxon();
    const environment = useEnvironment();
    const taxonomies = environment.get("rawTaxonomies");

    useEffect(() => {
        if (value == null) {
            return;
        }
    }, [value]);

    const profileTitle = useMemo(() => {
        if (value == null) {
            return null;
        }
        switch (value.type) {
            case "Stock":
                return "Stock Profile";
            case "ETF":
                return "ETF Profile";
            case "Index":
            case "Sector":
                return "Index Profile";
            default:
                return null;
        }
    }, [value]);

    const securityIndustry = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "Stock" || value.type === "Sector") {
            if (value.icb == null) {
                return "-";
            } else {
                return format.html(
                    "industry",
                    "industry",
                    value.icb,
                    null,
                    value.type
                );
            }
        }
        return null;
    }, [format, value]);

    const securitySector = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "Stock") {
            var sector = taxon.getAncestorAtLevel(
                taxonomies.ICB,
                value.icb,
                "3 Sector"
            );
            if (sector == null) {
                sector = taxon.getAncestorAtLevel(
                    taxonomies.ICB,
                    value.icb,
                    "1 Industry"
                );
            }
            if (sector != null && taxonomies.ICB[sector] != null) {
                return taxonomies.ICB[sector].name;
            } else {
                // Sector is null and there is not even the first level
                return "-";
            }
        }
        return null;
    }, [taxon, taxonomies, value]);

    const securityAssetClassRow = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "ETF") {
            var ancestor = taxon.getAncestorByLevel(
                taxonomies.ETFclassification,
                value.etfclass,
                "1"
            );
            var assetClass = "";
            if (ancestor != null) {
                var ancestorData = taxonomies.ETFclassification[ancestor];
                assetClass = ancestorData.name + " - ";
            }
            assetClass += format.html(
                "etfclass",
                "etfclass",
                value.etfclass,
                null,
                value.type
            );
            return assetClass;
        }
        return null;
    }, [format, taxon, taxonomies, value]);

    const securityInvestmentRegionRow = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "ETF") {
            return format.html(
                "etfgeo",
                "etfgeo",
                value.etfgeo,
                null,
                value.type
            );
        }
        return null;
    }, [format, value]);

    const securityIssuerRow = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "ETF") {
            return format.html(
                "subtype",
                "subtype",
                value.subtype,
                null,
                value.type
            );
        }
        return null;
    }, [format, value]);

    const securityMktCapRow = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "Stock" || value.type === "ETF") {
            return format.html(
                "marketcap",
                "marketcap",
                value.marketcap,
                null,
                value.type
            );
        }
        return null;
    }, [format, value]);

    const securityCurrencyRow = useMemo(() => {
        if (value == null) {
            return null;
        }
        if (value.type === "Index" || value.type === "Sector") {
            return format.html(
                "currency",
                "currency",
                value.currency,
                null,
                value.type
            );
        }
        return null;
    }, [format, value]);

    if (properties == null || value == null) {
        return <>Loading...</>;
    }

    if (
        value.type !== "Stock" &&
        value.type !== "ETF" &&
        value.type !== "Index" &&
        value.type !== "Sector"
    ) {
        return <></>; // Unsupported type
    }

    return (
        <div
            className={`tTable-wrap tTable-wrap--border ${
                profileTitle == null ? "hide" : ""
            }`}
            data-dojo-attach-point="nodeSecurityProfile"
        >
            <h2
                className="tTable-title"
                data-dojo-attach-point="nodeSecurityProfileTitle"
            >
                {profileTitle}
            </h2>
            <table className="tTable">
                <tbody>
                    {/* <!-- Stock --> */}
                    {securityIndustry != null && (
                        <tr data-dojo-attach-point="nodeSecurityIndustryRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityIndustryLabel"
                            >
                                Sector
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityIndustry"
                                    dangerouslySetInnerHTML={{
                                        __html: securityIndustry,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- Stock --> */}
                    {securitySector != null && (
                        <tr data-dojo-attach-point="nodeSecuritySectorRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecuritySectorLabel"
                            >
                                Industry
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecuritySector"
                                    dangerouslySetInnerHTML={{
                                        __html: securitySector,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- ETF --> */}
                    {securityAssetClassRow != null && (
                        <tr data-dojo-attach-point="nodeSecurityAssetClassRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityAssetClassLabel"
                            >
                                {properties.get("etfclass", 0, value.type)}
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityAssetClass"
                                    dangerouslySetInnerHTML={{
                                        __html: securityAssetClassRow,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- ETF --> */}
                    {securityInvestmentRegionRow != null && (
                        <tr data-dojo-attach-point="nodeSecurityInvestmentRegionRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityInvestmentRegionLabel"
                            >
                                {properties.get("etfgeo", 0, value.type)}
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityInvestmentRegion"
                                    dangerouslySetInnerHTML={{
                                        __html: securityInvestmentRegionRow,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- ETF --> */}
                    {securityIssuerRow != null && (
                        <tr data-dojo-attach-point="nodeSecurityIssuerRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityIssuerLabel"
                            >
                                {properties.get("subtype", 0, value.type)}
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityIssuer"
                                    dangerouslySetInnerHTML={{
                                        __html: securityIssuerRow,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- Stock, ETF --> */}
                    {securityMktCapRow != null && (
                        <tr data-dojo-attach-point="nodeSecurityMktCapRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityMktCapLabel"
                            >
                                {properties.get("marketcap", 2, value.type)}
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityMktCap"
                                    dangerouslySetInnerHTML={{
                                        __html: securityMktCapRow,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- Index, Sector --> */}
                    {securityCurrencyRow != null && (
                        <tr data-dojo-attach-point="nodeSecurityCurrencyRow">
                            <th
                                className="tTable-name"
                                data-dojo-attach-point="nodeSecurityCurrencyLabel"
                            >
                                {properties.get("currency", 0, value.type)}
                            </th>
                            <td className="tTable-value">
                                <div
                                    data-dojo-attach-point="nodeSecurityCurrency"
                                    dangerouslySetInnerHTML={{
                                        __html: securityCurrencyRow,
                                    }}
                                ></div>
                            </td>
                        </tr>
                    )}

                    {/* <!-- Stock, ETF, Index, Sector --> */}
                    <tr data-dojo-attach-point="nodeSecurityMarketRow">
                        <th
                            className="tTable-name"
                            data-dojo-attach-point="nodeSecurityMarketLabel"
                        >
                            {properties.get("country", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                data-dojo-attach-point="nodeSecurityMarket"
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "country",
                                        "market",
                                        value.country,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>

                    {/* <!-- Stock, ETF, Index, Sector --> */}
                    <tr data-dojo-attach-point="nodeSecurityTickerRow">
                        <th
                            className="tTable-name"
                            data-dojo-attach-point="nodeSecurityTickerLabel"
                        >
                            {properties.get("ticker", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                data-dojo-attach-point="nodeSecurityTicker"
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "ticker",
                                        "ticker",
                                        value.ticker,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
