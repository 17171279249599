import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type Props = {
  days: {
    "1": boolean;
    "2": boolean;
    "3": boolean;
    "4": boolean;
    "5": boolean;
  };
  setDays: Function;
};

type FrequencyType = "daily" | "weekly" | "never";
export default function DailyReportV2({ days, setDays }: Props) {
  const settingState = useCallback(():FrequencyType|null => {
    if (days["1"] && days["2"] && days["3"] && days["4"] && days["5"]) {
      return "daily";
    } else if (
      days["1"] &&
      !days["2"] &&
      !days["3"] &&
      !days["4"] &&
      !days["5"]
    ) {
      // Only monday
      return "weekly";
    } else if (
      !days["1"] &&
      !days["2"] &&
      !days["3"] &&
      !days["4"] &&
      !days["5"]
    ) {
      return "never";
    }
    return null;
  }, [days]);
  
  const [state, setState] = useState<FrequencyType | null>( () => {
      let returningValue = settingState();
      return returningValue;
    }
  );
  useEffect(() => {
    if (days != null) {
      let returningValue = settingState();
      setState(returningValue);
    }
  }, [days, settingState]);

  const onChangeFrequency = useCallback(
    (frequency: FrequencyType) => {
      if (frequency === "daily") {
        //every day is true
        setState("daily");
        setDays({
          "1": true,
          "2": true,
          "3": true,
          "4": true,
          "5": true,
        });
      } else if (frequency === "weekly") {
        // Only monday
        setState("weekly");
        setDays({
          "1": true,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
        });
      } else if (frequency === "never") {
        // every day is false
        setState("never");
        setDays({
          "1": false,
          "2": false,
          "3": false,
          "4": false,
          "5": false,
        });
      }
    },
    [setDays]
  );

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Typography>
          Set the frequency in which you want to get the report on markets and
          portfolios directly in your inbox
        </Typography>
        {state != null && (
          <PortfolioNews onChangeFrequency={onChangeFrequency} value={state} />
        )}
      </CardContent>
    </Card>
  );
}

type PortfolioNewsProps = { value: FrequencyType; onChangeFrequency: Function };
const PortfolioNews = ({ value, onChangeFrequency }: PortfolioNewsProps) => {
  const radioHandler = useCallback(
    (e) => {
      const value = e.target.value;
      const checked = e.target.checked;
      if (checked === true) {
        onChangeFrequency(value);
      }
    },
    [onChangeFrequency]
  );
  return (
    <Box>
      <RadioGroup value={value} row onChange={(e) => radioHandler(e)}>
        <FormControlLabel
          value={"daily"}
          control={<Radio size="small" />}
          label="Daily"
        />
        <FormControlLabel
          value={"weekly"}
          control={<Radio size="small" />}
          label="Weekly"
        />
        <FormControlLabel
          value={"never"}
          control={<Radio size="small" />}
          label="Never"
        />
      </RadioGroup>
    </Box>
  );
};
