/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/modelObjects/modelObjectTypes
 * @summary Trendrating Compute API object types
 *
 * "list" type is both for basket and portfolio
 */

export const modelObjectTypes = {
    // TODO - 2020-08-11
    // must be renamed: they are global, not only shared object types
    sharedObjects: {
        BASKET: "COLLECTION",
        COMBINED_STRATEGY: "COMBINED_STRATEGY",
        COMBINED_PRODUCT: "COMBINED_PRODUCT",
        PORTFOLIO: "COLLECTION",
        PREFERENCE_INDEX: "PREFERENCE_INDEX",
        RANKING_ABOUT_TARGET: "RANKING_ABOUT_TARGET",
        SYSTEMATIC_PRODUCT: "SYSTEMATIC_PRODUCT",

        basket: "COLLECTION",
        combinedStrategy: "COMBINED_STRATEGY",
        combinedProduct: "COMBINED_PRODUCT",
        portfolio: "COLLECTION",
        list: "COLLECTION",
        rankingAboutTarget: "RANKING_ABOUT_TARGET",
        strategies: "PREFERENCE_INDEX",
        strategyAdvanced: "PREFERENCE_INDEX",
        strategyAlpha: "PREFERENCE_INDEX",
        strategyMacroRotation: "PREFERENCE_INDEX",
        strategySmartBeta: "PREFERENCE_INDEX",
        systematicPortfolio: "SYSTEMATIC_PRODUCT",
        templateReportPortfolio: "TEMPLATE_REPORT_PORTFOLIO",
        templateReportScreening: "TEMPLATE_REPORT_SCREENING",
        templateReportStrategy: "TEMPLATE_REPORT_STRATEGY",
    },
};
