import { Lists } from "../../../api/compute/Lists";
import { clone } from "../../../deepClone";
import { AppEnvironment } from "../../../types/Defaults";

type CacheTabsKeys =
  | "overview"
  | "evidence"
  | "holdings"
  | "allocation"
  | "trend"
  | "ranking"
  | "compare"
  | "dispersion"
  | "improve"
  | "pointInTime"
  | "pointInTimePeriod";

export class PortfolioAnalyzeStorage {
  private listsAPI: Lists;
  private cache: {
    list: any;
    tabs: {
      overview:
        | undefined
        | {
            positions: any;
            assetType: any;
          };
      evidence: undefined | any;
      holdings: undefined | any;
      allocation: undefined | any;
      trend: undefined | any;
      ranking: undefined | any;
      compare: undefined | any;
      dispersion: undefined | any;
      improve: undefined | any;
      pointInTime: undefined | any;
    };
  };
  private portfolioId: number;
  private userId: number;

  constructor(environment: AppEnvironment, id: number) {
    this.listsAPI = new Lists(environment);
    this.portfolioId = id;
    this.userId = environment.account.user?.id!;

    this.cache = {
      list: {},
      tabs: {
        overview: undefined,
        evidence: undefined,
        holdings: undefined,
        allocation: undefined,
        trend: undefined,
        ranking: undefined,
        compare: undefined,
        dispersion: undefined,
        improve: undefined,
        pointInTime: undefined,
      },
    };
  }

  async get(view: CacheTabsKeys) {
    switch (view) {
      case "overview":
        return await this.overview();

      case "evidence":
        return await this.evidence();

      case "holdings":
        return await this.holdings();

      case "allocation":
        return await this.allocation();

      case "trend":
        return await this.trend();

      case "ranking":
        return await this.ranking();

      case "dispersion":
        return await this.dispersion();

      case "improve":
        return await this.improve();

      case "pointInTime":
        return await this.pointInTime();

      case "pointInTimePeriod":
        return await this.pointInTimePeriod();

      case "compare":
        return await this.compare();
    }
  }

  private async overview() {
    if (this.cache["tabs"]["overview"] != null) {
      return this.cache["tabs"]["overview"];
    }

    const listFields = [
      "positionsToday",
      "assetTypes",
      "TCR",
      "TCR_D",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "A",
      "B",
      "C",
      "D",
      "upgrades_M",
      "downgrades_M",
      "ownerId",
      "benchmark",
      "type",
      "name",
    ];

    await this.fetch(listFields);

    const overviewTabData = {
      id: this.portfolioId,
      name: this.cache.list["name"],
      positions: clone(this.cache.list["positionsToday"]),
      tcr: {
        statistics: {
          today: this.cache.list["TCR"],
          yesterday: this.cache.list["TCR_D"],
        },
      },
      cardinalityPerRating: {
        A: this.cache.list["A"] ?? 0,
        B: this.cache.list["B"] ?? 0,
        C: this.cache.list["C"] ?? 0,
        D: this.cache.list["D"] ?? 0,
      },
      weightsPerRating: {
        A: this.cache.list["A_%"] ?? 0,
        B: this.cache.list["B_%"] ?? 0,
        C: this.cache.list["C_%"] ?? 0,
        D: this.cache.list["D_%"] ?? 0,
      },
      lastMonthUpgrades: this.cache.list["upgrades_M"],
      lastMonthDowngrades: this.cache.list["downgrades_M"],
      benchmark: this.cache.list["benchmark"],
      type: this.cache.list["type"],
      isReadOnly: this.cache.list["ownerId"] !== this.userId,
      assetType: this.formatAssetType(this.cache.list["assetTypes"]),
    };

    return this.storeTabData("overview", overviewTabData);
  }

  private async evidence() {
    if (this.cache.tabs["evidence"] != null) {
      return this.cache.tabs["evidence"];
    }

    const listFields = [
      "positionsToday",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
      "A",
      "B",
      "C",
      "D",
      "type",
      "name",
      "positionsToday",
    ];

    await this.fetch(listFields);

    const evidenceTabData = {
      id: this.portfolioId,
      name: this.cache.list["name"],
      type: this.cache.list["type"],
      positions: this.cache.list["positionsToday"],
      statistics: {
        cardinalityPerRating: {
          A: this.cache.list["A"] ?? 0,
          B: this.cache.list["B"] ?? 0,
          C: this.cache.list["C"] ?? 0,
          D: this.cache.list["D"] ?? 0,
        },
        weightsPerRating: {
          A: this.cache.list["A_%"] ?? 0,
          B: this.cache.list["B_%"] ?? 0,
          C: this.cache.list["C_%"] ?? 0,
          D: this.cache.list["D_%"] ?? 0,
        },
      },
    };

    return this.storeTabData("evidence", evidenceTabData);
  }

  private async holdings() {
    if (this.cache.tabs["holdings"] != null) {
      return this.cache.tabs["holdings"];
    }

    const listFields = ["positionsToday", "ownerId", "name"];

    await this.fetch(listFields);

    const holdingsTabData = {
      positions: this.cache.list["positionsToday"],
      isReadOnly: this.cache.list["ownerId"] !== this.userId,
      name: this.cache.list["name"],
    };

    return this.storeTabData("holdings", holdingsTabData);
  }

  private async allocation() {
    if (this.cache.tabs["allocation"] != null) {
      return this.cache.tabs["allocation"];
    }

    const listFields = [
      "positionsToday",
      "type",
      "assetTypes",
      "name",
      "ownerId",
    ];

    await this.fetch(listFields);

    const allocationTabData = {
      id: this.portfolioId,
      positions: this.cache.list["positionsToday"],
      type: this.cache.list["type"],
      assetType: this.formatAssetType(this.cache.list["assetTypes"]),
      name: this.cache.list["name"],
      isReadOnly: this.cache.list["ownerId"] !== this.userId,
    };

    return this.storeTabData("allocation", allocationTabData);
  }

  private async trend() {
    if (this.cache.tabs["trend"] != null) {
      return this.cache.tabs["trend"];
    }

    const listFields = ["positionsToday"];

    await this.fetch(listFields);

    const trendTabData = {
      positions: this.cache.list["positionsToday"],
    };

    return this.storeTabData("trend", trendTabData);
  }

  private async ranking() {
    if (this.cache.tabs["ranking"] != null) {
      return this.cache.tabs["ranking"];
    }

    const listFields = ["type", "name", "positionsToday"];

    await this.fetch(listFields);

    const rankingTabData = {
      id: this.portfolioId,
      type: this.cache.list["type"],
      name: this.cache.list["name"],
      positions: this.cache.list["positionsToday"],
    };

    return this.storeTabData("ranking", rankingTabData);
  }

  private async dispersion() {
    if (this.cache.tabs["dispersion"] != null) {
      return this.cache.tabs["dispersion"];
    }

    const listFields = ["type", "assetTypes", "name"];

    await this.fetch(listFields);

    const dispersionTabData = {
      id: this.portfolioId,
      type: this.cache.list["type"],
      assetType: this.cache.list["assetTypes"],
      name: this.cache.list["name"],
    };

    return this.storeTabData("dispersion", dispersionTabData);
  }

  private async improve() {
    if (this.cache.tabs["improve"] != null) {
      return this.cache.tabs["improve"];
    }

    const listFields = [
      "positions",
      "name",
      "ownerId",
      "positionsToday",
      "type",
      "C",
      "D",
      "C_%",
      "D_%",
    ];

    await this.fetch(listFields);

    const improveTabData = {
      id: this.portfolioId,
      name: this.cache.list["name"],
      isReadOnly: this.cache.list["ownerId"] !== this.userId,
      positionsSource: this.cache.list["positions"],
      positions: this.cache.list["positionsToday"],
      type: this.cache.list["type"],
      cardinalityPerRating: {
        C: this.cache.list["C"] ?? 0,
        D: this.cache.list["D"] ?? 0,
      },
      weightsPerRating: {
        C: this.cache.list["C_%"] ?? 0,
        D: this.cache.list["D_%"] ?? 0,
      },
    };

    return this.storeTabData("improve", improveTabData);
  }

  private async pointInTime() {
    if (this.cache.tabs["pointInTime"] != null) {
      return this.cache.tabs["pointInTime"];
    }

    const listFields = ["positionsToday"];

    await this.fetch(listFields);

    const pointInTimeTabData = {
      positionsToday: this.cache.list["positionsToday"],
    };

    return this.storeTabData("pointInTime", pointInTimeTabData);
  }

  private async pointInTimePeriod() {
    if (this.cache.tabs["pointInTimePeriod"] != null) {
      return this.cache.tabs["pointInTimePeriod"];
    }

    const listFields = ["positionsToday", "name", "ownerId"];

    await this.fetch(listFields);

    const pointInTimeTabData = {
      positionsToday: this.cache.list["positionsToday"],
      id: this.portfolioId,
      name: this.cache.list["name"],
      isReadOnly: this.cache.list["ownerId"] !== this.userId,
    };

    return this.storeTabData("pointInTimePeriod", pointInTimeTabData);
  }

  private async compare() {
    if (this.cache.tabs["compare"] != null) {
      return this.cache.tabs["compare"];
    }

    const listFields = [
      "positionsToday",
      "TCR",
      "TCR_D",
      "type",
      "name",
      "assetTypes",
      "A",
      "B",
      "C",
      "D",
      "A_%",
      "B_%",
      "C_%",
      "D_%",
    ];

    await this.fetch(listFields);

    const compareTimeTabData = {
      positions: this.cache.list["positionsToday"],
      tcrToday: this.cache.list["TCR"],
      tcrYesterday: this.cache.list["TCR_D"],
      type: this.cache.list["type"],
      name: this.cache.list["name"],
      assetType: this.cache.list["assetTypes"],
      cardinalityPerRating: {
        A: this.cache.list["A"] ?? 0,
        B: this.cache.list["B"] ?? 0,
        C: this.cache.list["C"] ?? 0,
        D: this.cache.list["D"] ?? 0,
      },
      weightsPerRating: {
        A: this.cache.list["A_%"] ?? 0,
        B: this.cache.list["B_%"] ?? 0,
        C: this.cache.list["C_%"] ?? 0,
        D: this.cache.list["D_%"] ?? 0,
      },
    };

    return this.storeTabData("compare", compareTimeTabData);
  }

  private formatAssetType(assetTypeObj) {
    let positionsType = "Instrument";
    const assetType = assetTypeObj;
    const typesWhiteList = [
      "Stock",
      "ETF",
      "Commodity",
      "Index",
      "Sector",
      "Currency",
    ];
    const listTypes: string[] = [];

    for (const type of Object.keys(assetType ?? {})) {
      if (typesWhiteList.includes(type)) {
        listTypes.push(type);
      }
    }

    const isMultiTypes = listTypes.length > 1;

    if (!isMultiTypes) {
      positionsType = listTypes[0];
    }

    return positionsType;
  }

  private removeCachedFields(fieldsToFetch: string[]) {
    const fieldsToAsk: string[] = [];

    for (const field of fieldsToFetch) {
      if (!(field in this.cache["list"]) || this.cache["list"][field] == null) {
        fieldsToAsk.push(field);
      }
    }

    return fieldsToAsk;
  }

  private storeFetchedFields(response) {
    const result = response[0];

    for (const key in result) {
      this.cache["list"][key] = result[key];
    }
  }

  private storeTabData(key: CacheTabsKeys, value: any) {
    this.cache["tabs"][key] = value;

    return this.cache["tabs"][key];
  }

  private async fetch(fields: string[]) {
    try {
      const response = await this.listsAPI.portfolioFetch(
        [this.portfolioId],
        this.removeCachedFields(fields)
      );

      this.storeFetchedFields(response);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
}
