/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/App
 * @summary App account main component
 *
 */

import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Sessions } from "../../api/account/Sessions";
import { Users } from "../../api/account/Users";
import { useAxios } from "../../hooks/useAxios";
import { useEarlyEnvironment } from "../../hooks/useEnvironment";
import "../../styles/app-account/index.scss";
import { Feedback } from "../app/components/Feedback";
import { Loader } from "./components/Loader";
import { config } from "../app/config-ts";
import { ImpersonatePage } from "./pages/ImpersonatePage";
import { LeadPage } from "./pages/LeadPage";
import { LoginPage } from "./pages/LoginPage";
import { PasswordRecoveryPage } from "./pages/PasswordRecoveryPage";
import { PasswordSetupPage } from "./pages/PasswordSetupPage";
import { UnsupportedBrowserPage } from "./pages/UnsupportedBrowserPage";

function isModern() {
  const checkNode = window.document.createElement("a");
  let result = true;
  const has: boolean[] = [];

  // IE >= 8
  has.push(window.sessionStorage != null ? true : false);

  // IE >= 9
  has.push(checkNode.addEventListener != null ? true : false);

  // IE >= 10
  has.push(
    window.XMLHttpRequest && "upload" in new XMLHttpRequest() ? true : false
  );

  // IE >= 11
  has.push(!checkNode["attachEvent"] ? true : false);

  for (let i = 0; i < has.length; i++) {
    result = result && has[i];
  }

  return result;
}

export function AppAccount() {
  const { ready } = useTranslation();
  const environment = useEarlyEnvironment();
  const storage = useMemo(() => {
    if (environment == null) {
      return null;
    }
    return {
      sessions: new Sessions(environment.get("setup")),
      users: new Users(environment.get("setup")),
    };
  }, [environment]);

  useAxios(); // After feedback creation so the message is shown

  if (!isModern()) {
    return <UnsupportedBrowserPage />;
  }

  if (!ready || !environment) {
    return <Loader message="Loading..." />;
  }

  return (
    <div className="app-account">
      <header className="app-header">
        <h1 className="app-title">
          <a
            href="http://www.trendrating.com"
            title="Go to Trendrating official website"
          >
            <img
              src="/img/trendrating.png"
              alt="Trendrating"
              height="51"
              width="256"
            />
          </a>
        </h1>
        <h2 className="app-claim" id="app-claim">
          Cut the noise. Stay on top of trends.
        </h2>
      </header>
      <div className="feedback" id="app-feedback"></div>
      <Feedback channelInput={config.channels.feedback.input} />
      <main className="app-main">
        <Routes>
          <Route path="/lead" element={<LeadPage />} />
          <Route
            path="/password-recovery"
            element={<PasswordRecoveryPage storage={storage} />}
          />
          <Route
            path="/password-setup/:uid"
            element={<PasswordSetupPage storage={storage} />}
          />
          <Route
            path="/impersonate/:userId/:firstName/:lastName"
            element={<ImpersonatePage storage={storage} />}
          />
          <Route path="/" element={<LoginPage storage={storage} />} />
        </Routes>
      </main>
      <footer className="app-footer">
        <span
          className={`app-version ${
            process.env.REACT_APP_DEBUG === "true" ? "app-version--debug" : ""
          }`}
        >
          {process.env.REACT_APP_VERSION} -{" "}
          {(window as any).SERVER_NODE === "__SERVER_NODE__"
            ? "local"
            : (window as any).SERVER_NODE}
        </span>
        &copy; 2013 - {format(new Date(), "yyyy")} Trendrating SA. All rights
        are reserved.
      </footer>
    </div>
  );
}
