import {
  CSSProperties,
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
} from "react";
import styles from "./Dropdown.module.scss";
import { Portal } from "../Portal/Portal";

type DropdownProps = {
  targetRef: RefObject<HTMLElement>;
  offset?: number;
  children: ReactNode | ReactNode[];
  position?: "bottom" | "right";
  customStyle?: CSSProperties;
};

const { dropdown__menu } = styles;

export const Dropdown = forwardRef<HTMLUListElement, DropdownProps>(
  ({ targetRef, offset, children, position = "bottom", customStyle }, ref) => {
    const refList = ref != null && typeof ref !== "function" ? ref : null;

    useEffect(() => {
      const target = targetRef?.current ?? null;
      const menu = refList?.current;
      if (target && menu) {
        const targetDims = target.getBoundingClientRect();
        const menuDims = menu.getBoundingClientRect();
        const { left, top, width, height } = targetDims;
        const { height: menuHeight, width: menuWidth } = menuDims;
        const { innerHeight, innerWidth } = window;

        let flipY = false;
        let flipX = false;
        let updatedPositionY;
        let updatedPositionX;

        if (top + menuHeight > innerHeight) {
          flipY = true;

          //Has to be flipped top bacuse exceed bottom
          updatedPositionY = top - menuHeight;
        }

        const whiteSpace = offset ?? 0;

        if (left + width + whiteSpace > innerWidth) {
          flipX = true;

          //Has to be flipped left because exceed right
          updatedPositionX = left - whiteSpace - menuWidth;
        }

        if (position === "right") {
          menu.style.top = `${flipY ? updatedPositionY : top}px`;
          menu.style.left = `${
            flipX ? updatedPositionX : left + width + whiteSpace
          }px`;
        }

        if (position === "bottom") {
          menu.style.top = `${
            flipY ? updatedPositionY : top + height + whiteSpace
          }px`;
          menu.style.left = `${left + whiteSpace}px`;
        }
      }
    }, [targetRef, offset, refList, position]);

    return (
      <Portal>
        <ul style={{ ...customStyle }} className={dropdown__menu} ref={ref}>
          {children}
        </ul>
      </Portal>
    );
  }
);
