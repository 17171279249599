import { useCallback, useState } from "react";
import { DialogRelations } from "../../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { config } from "../../../../config-ts";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";

type UnsubscribeProps = {
  strategyToUnsubscribe: any;
  handleUnsubscribeFinish?: () => void;
};

export function Unsubscribe({
  strategyToUnsubscribe,
  handleUnsubscribeFinish,
}: UnsubscribeProps) {
  const [showDialogRelations, setShowDialogRelations] = useState(false);
  const { broadcast } = useBroadcast();

  const unsubscribe = useCallback(async () => {
    setShowDialogRelations(true);
  }, []);

  const closeRelationDialog = useCallback(
    () => setShowDialogRelations(false),
    []
  );

  const onUnsubscribeDone = useCallback(
    (item) => {
      var message = {
        from: "Strategy unsubscription",
        content: {
          type: "success",
          text: `${item?.name ?? "Shared object"} unsubscribed successfully.`,
        },
      };

      broadcast(config["channels"]["feedback"]["input"], message);

      if (handleUnsubscribeFinish) {
        handleUnsubscribeFinish();
      }
    },
    [broadcast, handleUnsubscribeFinish]
  );

  const onCancelUnsubscribe = useCallback(() => {
    closeRelationDialog();
  }, [closeRelationDialog]);

  return (
    <>
      {showDialogRelations && (
        <DialogRelations
          close={closeRelationDialog}
          itemToDelete={strategyToUnsubscribe}
          onRemoveDone={onUnsubscribeDone}
          isUnsubscribe={true}
          onCancel={onCancelUnsubscribe}
        />
      )}
      <li onClick={unsubscribe} className="menu__item">
        Unsubscribe
      </li>
    </>
  );
}
