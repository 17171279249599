export type RatingChangeType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    numberOfItems: number;
  };
  presentation: {
    downgrade: boolean;
    upgrade: boolean;
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_NUMBERS_OF_ITEMS"
    | "SET_PRESENTATION_DOWNGRADE"
    | "SET_PRESENTATION_UPGRADE";
  payload: any;
};

export const RatingChangeReducer = (draft: RatingChangeType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_NUMBERS_OF_ITEMS":
      draft.content.numberOfItems = action.payload;
      break;
    case "SET_PRESENTATION_DOWNGRADE":
      draft.presentation.downgrade = action.payload;
      break;
    case "SET_PRESENTATION_UPGRADE":
      draft.presentation.upgrade = action.payload;
      break;
    default:
      break;
  }
};
