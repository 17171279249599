import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { CompareStrategies as CompareStrategiesAPI } from "../../../../../../api/compute/CompareStrategies";
import { RapidMenu } from "../../../../../../components/RapidMenu/RapidMenu";
import { StrategyPicker } from "../../../../../../components/StrategyPicker/StrategyPicker";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { messageError } from "../../../../utils";
import { StrategySummaryModal } from "../../../../../../components/StrategySummary/StrategySummaryModal";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { useEventBus } from "../../../../../../hooks/useEventBus";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";

type CompareStrategiesProps = {
  title: string;
  description: string;
  icon: string;
  onRename: (resource) => any;
  onDelete: (resource) => any;
  onRun: (id, id2) => any;
  readLoadedCompare?: (compareStrategy) => any;
  readStrategiesToCompare?: (strategies: EditorState) => any;
  hasList?: boolean;
  hasRunButton?: boolean;
  buttonLabel?: string;
  frameType?: "legacy" | "primary";
  idStrategy1?: string;
  idStrategy2?: string;
};

type EditorState = {
  firstStrategyId: number | null;
  secondStrategyId: number | null;
};

type CompareListProps = {
  handleListItemClick: (value) => any;
  handleMenuActions: (action: string, resource) => any;
};

export function CompareStrategiesEditor({
  title,
  description,
  icon,
  buttonLabel = "",
  hasList = false,
  hasRunButton = false,
  frameType = "primary",
  idStrategy1,
  idStrategy2,
  readStrategiesToCompare,
  readLoadedCompare,
  onRun,
  onRename,
  onDelete,
}: CompareStrategiesProps) {
  const [initialsStrategy1Id, setInitialsStrategy1Id] = useState(idStrategy1);
  const [initialsStrategy2Id, setInitialsStrategy2Id] = useState(idStrategy2);

  const [strategiesToCompare, setStrategiesToCompare] = useState<EditorState>({
    firstStrategyId: null,
    secondStrategyId: null,
  });

  const [canRun, setCanRun] = useState(false);
  const [strategyToExpand, setStrategyToExpand] = useState<number>();

  const onPickerChange = useCallback(
    (strategyId: number, whichStrategy: number) => {
      const editorStateKey: { [key: number]: keyof EditorState } = {
        1: "firstStrategyId",
        2: "secondStrategyId",
      };

      setStrategiesToCompare((prevState) => {
        const updatedState = { ...prevState };

        updatedState[editorStateKey[whichStrategy]] = strategyId;

        return updatedState;
      });
    },
    []
  );

  const setFirstStrategy = useCallback(
    (strategyId) => {
      onPickerChange(strategyId, 1);
    },
    [onPickerChange]
  );

  const setSecondStrategy = useCallback(
    (strategyId) => {
      onPickerChange(strategyId, 2);
    },
    [onPickerChange]
  );

  // Handle button disabled attribute based on selected strategies
  useEffect(() => {
    setCanRun(
      strategiesToCompare.firstStrategyId != null &&
        strategiesToCompare.secondStrategyId != null
    );
  }, [
    strategiesToCompare.firstStrategyId,
    strategiesToCompare.secondStrategyId,
  ]);

  const onClickCompare = useCallback(() => {
    // ***************************** USAGE *****************************
    var usage = window.App.usage;
    var info = {
      action: "LANDING",
      actionParam: null,
      function: "STRATEGY_COMPARE",
    };
    usage.record(info);
    // ***************************** USAGE *****************************

    const { firstStrategyId, secondStrategyId } = strategiesToCompare;

    var uri = `/strategies/compare/${firstStrategyId}/${secondStrategyId}/run`;

    (window as any).__page_navigate(uri);
  }, [strategiesToCompare]);

  const showFirstStrategyDetails = useCallback(async () => {
    // We know that the value cannot be null because this function is called by a widget that is rendered
    // only if the strategyId is non null
    setStrategyToExpand(strategiesToCompare.firstStrategyId!);
  }, [strategiesToCompare.firstStrategyId]);

  const showSecondStrategyDetails = useCallback(async () => {
    // We know that the value cannot be null because this function is called by a widget that is rendered
    // only if the strategyId is non null
    setStrategyToExpand(strategiesToCompare.secondStrategyId!);
  }, [strategiesToCompare.secondStrategyId]);

  const onCloseStrategySummaryDialog = useCallback(() => {
    setStrategyToExpand(undefined);
  }, []);

  const selectCompareStrategy = useCallback(
    (value) => {
      const strategy1Id = value?.strategy1?.id;
      const strategy2Id = value?.strategy2?.id;

      /**
       * Update only the initial value of the strategy Picker because it's an uncontrolled component and this operation
       * automatically upload the state of strategiesToCompare variable.
       */
      setInitialsStrategy1Id(strategy1Id);
      setInitialsStrategy2Id(strategy2Id);

      if (readLoadedCompare) {
        readLoadedCompare(value);
      }
    },
    [readLoadedCompare]
  );

  const menuActionsHandler = useCallback(
    (action: string, resource) => {
      switch (action) {
        case "actionRun": {
          const id = resource?.strategy1?.id;
          const id2 = resource?.strategy2?.id;

          if (id && id2) {
            onRun(id, id2);
            selectCompareStrategy(resource);
          }

          break;
        }
        case "actionDelete": {
          if (resource && !resource["isReadOnly"]) {
            onDelete(resource);
          }

          break;
        }

        case "actionRename": {
          if (resource) {
            onRename(resource);
          }
          break;
        }
        default: {
          console.warn(`Unknown Action: ${action}`);
        }
      }
    },
    [onDelete, onRename, onRun, selectCompareStrategy]
  );

  useEffect(() => {
    if (readStrategiesToCompare) {
      readStrategiesToCompare(strategiesToCompare);
    }
  }, [readStrategiesToCompare, strategiesToCompare]);

  return (
    <Frame type={frameType}>
      <StrategySummaryModal
        strategyId={strategyToExpand}
        onClose={onCloseStrategySummaryDialog}
      />
      <h1 className="CompareStrategies-title">{title}</h1>

      <div className="CompareStrategies-cover">
        <div className="CompareStrategies-icon">
          <img alt="*" src={icon} height="48" width="48" />
        </div>
        <div className="CompareStrategies-description">{description}</div>
        <div style={{ visibility: "hidden" }} className="tFormButton">
          &nbsp;
        </div>
      </div>

      <form className="CompareStrategies-form">
        <fieldset className="CompareStrategies-fieldset">
          <legend className="CompareStrategies-legend"></legend>
          <ol className="CompareStrategies-fields">
            <li className="CompareStrategies-field">
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <label
                  className="CompareStrategies-label"
                  htmlFor={"strategy-1"}
                >
                  Strategy 1
                </label>
                <div data-dojo-attach-point="widgetStrategy1" id="strategy-1">
                  <StrategyPicker
                    id={initialsStrategy1Id}
                    apiKey="strategies"
                    updateStrategy={setFirstStrategy}
                  />
                </div>
                {strategiesToCompare.firstStrategyId && (
                  <span
                    className="CompareStrategies-info i-info"
                    onClick={showFirstStrategyDetails}
                  ></span>
                )}
              </Box>
            </li>
            <li className="CompareStrategies-field">
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <label className="CompareStrategies-label" htmlFor="strategy-2">
                  Strategy 2
                </label>
                <div data-dojo-attach-point="widgetStrategy2" id="strategy-2">
                  <StrategyPicker
                    apiKey="strategies"
                    id={initialsStrategy2Id}
                    updateStrategy={setSecondStrategy}
                  />
                </div>
                {strategiesToCompare.secondStrategyId && (
                  <span
                    className="CompareStrategies-info i-info"
                    onClick={showSecondStrategyDetails}
                  ></span>
                )}
              </Box>
            </li>
            {hasRunButton && (
              <li className="CompareStrategies-buttons">
                <button
                  disabled={!canRun}
                  className="tFormButton"
                  onClick={onClickCompare}
                  type="button"
                >
                  {buttonLabel}
                </button>
              </li>
            )}
          </ol>
        </fieldset>
      </form>
      {hasList ? (
        <div data-dojo-attach-point="nodeList">
          <CompareList
            handleListItemClick={selectCompareStrategy}
            handleMenuActions={menuActionsHandler}
          />
        </div>
      ) : (
        <></>
      )}
    </Frame>
  );
}

const Frame = ({
  type,
  children,
}: {
  children: JSX.Element | JSX.Element[];
  type: "legacy" | "primary";
}) => {
  return type === "legacy" ? (
    <div className="CompareStrategies-content">
      <ErrorBoundary
        fallback={
          <Typography>
            Cannot load content please try to refresh te page, if the error
            persist contact our customers support
          </Typography>
        }
      >
        {children}
      </ErrorBoundary>
    </div>
  ) : (
    <Card>
      <CardContent>
        {" "}
        <ErrorBoundary
          fallback={
            <Typography>
              Cannot load content please try to refresh te page, if the error
              persist contact our customers support
            </Typography>
          }
        >
          {children}
        </ErrorBoundary>
      </CardContent>
    </Card>
  );
};

export function CompareList({
  handleListItemClick,
  handleMenuActions,
}: CompareListProps) {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const [compareList, setCompareList] = useState<any>([]);
  const { on, remove } = useEventBus();

  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const compareAPI = useMemo(
    () => new CompareStrategiesAPI(appSetup),
    [appSetup]
  );

  const baseClass = "ListStrategies";
  const cssClassListItem = baseClass + "-listItem";
  const { broadcast } = useBroadcast();

  const getComparedList = useCallback(async () => {
    try {
      let _compareList = await compareAPI.select("COMPARED_STRATEGY");
      _compareList = await compareAPI.fetch({
        ids: _compareList,
        properties: ["name", "ownerId"],
        objectType: "PREFERENCE",
      });
      setCompareList(_compareList);
    } catch (error: any) {
      const [channel, msg] = messageError(error);
      broadcast(channel as string, msg);
      console.error(error?.message ?? "");
      setCompareList([]);
    }
  }, [broadcast, compareAPI]);

  useEffect(() => {
    getComparedList();
  }, [getComparedList]);

  useEffect(() => {
    on("refresh-compare-list", getComparedList);

    return () => {
      remove("refresh-compare-list", getComparedList);
    };
  }, [getComparedList, on, remove]);

  const actions: any = useMemo(
    () => [
      {
        label: "Run",
        type: "item",
        value: "actionRun",
      },
      {
        label: "Delete",
        type: "item",
        value: "actionDelete",
      },
      {
        label: "Rename",
        type: "item",
        value: "actionRename",
      },
    ],
    []
  );

  const getEntireObj = useCallback(
    async (id) => {
      return await compareAPI.get(id);
    },
    [compareAPI]
  );
  const wrapMenuActionHandler = useCallback(
    async (event, resource) => {
      const action = event.value.action;
      const _resource = await getEntireObj(resource.id);
      handleMenuActions(action, _resource);
    },
    [getEntireObj, handleMenuActions]
  );

  const userID = useMemo(() => {
    return environment.get("account").user?.id;
  }, [environment]);

  const onClickListItem = useCallback(
    async (resource) => {
      const _resource = await getEntireObj(resource.id);
      handleListItemClick(_resource);
    },
    [getEntireObj, handleListItemClick]
  );

  return (
    <div className="ListStrategies">
      <ol
        className="ListStrategies-list"
        style={{ marginTop: "8px" }}
        data-dojo-attach-point="nodeList"
      >
        {compareList.map((resource) => (
          <li
            key={uuidv4()}
            className={cssClassListItem}
            title={resource.ownerId !== userID ? t("Show") : t("Edit")}
            onClick={() => onClickListItem(resource)}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                {resource.name}
                {resource.ownerId !== userID && (
                  <div className="sharedObjectIndicator"></div>
                )}
              </Box>
              <RapidMenu
                smallBtn
                onOptionClickHandler={(evt) =>
                  wrapMenuActionHandler(evt, resource)
                }
                options={actions}
                isReadOnly={resource.ownerId !== userID}
              />
            </Box>
          </li>
        ))}
      </ol>
    </div>
  );
}
