import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TaxonomySelect } from "../TaxonomySelect/TaxonomySelect";
import { useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

type FilterTaxonomiesProps = {
    filtersValues: any[];
    updateFilters: (value) => void;
    selectedFilters: any[];
    taxonomyToRender: { field: string; type: string };
    panelTitle?: string;
    label: string;
    taxonomyWithAdditionalFilters?: boolean;
    filtersInit?: { domestic: boolean; foreign: boolean; type: string[] };
    showIconCancel: boolean;
    removeFilter?: (e?: any) => void;
    columnsDefinition?: { taxonomyId: string }[][];
    validTreeNodes?: string[];
    editorCloseFn?: (value?: any) => void;
};

export function FilterTaxonomies({
    filtersValues,
    updateFilters,
    selectedFilters,
    taxonomyToRender,
    panelTitle,
    label,
    taxonomyWithAdditionalFilters = false,
    filtersInit,
    showIconCancel,
    removeFilter,
    columnsDefinition,
    validTreeNodes,
    editorCloseFn,
}: FilterTaxonomiesProps) {
    const panelRef = useRef<any>(null);

    const handlePanelAppearence = useCallback((value: boolean) => {
        if (panelRef.current) {
            panelRef.current.showTreePanel(value);
        }
    }, []);

    const handleSelection = useCallback(
        (filters) => {
            updateFilters(filters);
        },
        [updateFilters]
    );

    return (
        <TaxonomySelect
            ref={panelRef}
            taxonomyInfo={taxonomyToRender}
            panelTitle={panelTitle ?? label}
            hasFilters={taxonomyWithAdditionalFilters}
            onSetFilters={handleSelection}
            defaultValue={filtersValues}
            initFiltersState={filtersInit}
            columnsDefinition={columnsDefinition}
            validTreeNodes={validTreeNodes}
        >
            <Box display={"flex"} position={"relative"}>
                <Card
                    onClick={() => handlePanelAppearence(true)}
                    sx={{
                        position: "relative",
                        border: "1px solid",
                        borderColor: "transparent",
                        transition: "0.5s",
                        cursor: "pointer",
                        "&:hover": {
                            borderColor: "#2a7090",
                        },
                        display: "flex",
                    }}
                >
                    {showIconCancel || editorCloseFn != null ? (
                        <Box
                            title={`Remove ${label ?? ""}`}
                            sx={{
                                position: "absolute",
                                right: "2px",
                                top: "2px",
                                cursor: "pointer",
                            }}
                        >
                            <CloseIcon
                                onClick={editorCloseFn ?? removeFilter}
                                sx={{
                                    fontSize: "14px",
                                    color: "black",
                                }}
                            />
                        </Box>
                    ) : (
                        <></>
                    )}
                    <CardContent
                        sx={{
                            padding: "2px 6px!important",
                            display: "flex",
                            flex: 1,
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            textAlign={"center"}
                        >
                            <Typography
                                sx={{ mr: showIconCancel ? "10px" : 0 }}
                            >
                                <strong>{label ?? ""}</strong>
                            </Typography>

                            <Box
                                flex={1}
                                component={"ul"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                flexDirection={"column"}
                                gap={0.2}
                            >
                                {selectedFilters.map((filter) => {
                                    return (
                                        <li key={uuidv4()}>
                                            <Typography>{filter}</Typography>
                                        </li>
                                    );
                                })}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </TaxonomySelect>
    );
}
