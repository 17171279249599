import React, { ReactElement } from "react";
import style from "./SortableList.module.scss";

type SortableListItemProps = {
  content: ReactElement;
  onSelect: Function;
  indexItem: number;
  //   selectedItem: number;
  moveItemDown: Function;
  moveItemUp: Function;
  removeItem: Function;
  showControls: boolean;
  lenList: number;
};

export default function SortableListItem({
  content,
  onSelect,
  indexItem,
  //   selectedItem,
  moveItemDown,
  moveItemUp,
  removeItem,
  showControls,
  lenList,
}: SortableListItemProps) {
  //   const [controls, setControls] = useState(false);
  //   const [isMouseOver, setIsMouseOver] = useState(false);

  //   useEffect(() => {
  //     if (isMouseOver) {
  //       setControls(true);
  //     } else {
  //       setControls(false);
  //     }
  //   }, [isMouseOver]);
  return (
    <li
      //   onMouseOver={() => {
      //     if (!isMouseOver) {
      //       setIsMouseOver(true);
      //     }
      //   }}
      onClick={(e: React.MouseEvent) => {
        //!if the click is over any of the controls editing wont open
        //!will open instead if the click is somewhere else
        const target: any = e.target;
        if (!target.parentNode.classList.contains(style.listItem__controls)) {
          onSelect(indexItem);
        }
      }}
      className={style.sortableList__listItem}
    >
      <span
        // onClick={() => onSelect(indexItem)}
        style={
          showControls
            ? {}
            : { width: "100%", height: "100%", display: "block" }
        }
        className={style.listItem__description}
      >
        {content}
      </span>
      {
        /*(controls || selectedItem === indexItem) &&*/ showControls && (
          <span className={style.listItem__controls}>
            {indexItem === 0 && <div>&nbsp;&nbsp;</div>}
            {!(indexItem < lenList - 1) && <div>&nbsp;&nbsp;</div>}
            {indexItem < lenList - 1 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => moveItemDown(indexItem)}
                className="i-triangle-down"
              ></span>
            )}
            {indexItem !== 0 && (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => moveItemUp(indexItem)}
                className="i-triangle-up"
              ></span>
            )}

            <span
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(indexItem)}
              className="i-delete"
            ></span>
          </span>
        )
      }
    </li>
  );
}
