import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PeerWhatConstraints } from "../ConstraintsBar/PeerWhatConstraints";
import { CellTooltip } from "./CellTooltip";

type PeerTableHeadProps = {
  params: any;
  data: any;
  storage: any;
  decorator: (data, cellType) => string;
  getTooltipData: Function;
  onCellClick: (peerId) => any;
  setFCellDims: (dims) => any;
  options: (type) => void;
  getConstraints: (state) => void;
  whatDefault: any;
  sortWhatDefault: any;
};

const baseFlagImageUrl = appConfig.baseUrlImages + "flags/";
const baseClass = "tPeerTable";
const cssClassCell = baseClass + "-tableCell";
const cssClassCellCompound = cssClassCell + "--compound";
const cssClassCellContentWrapper = cssClassCell + "ContentWrapper";
const cssClassCellContentWrapperWhere = cssClassCellContentWrapper + "--where";
const cssClassCellContentWhere = cssClassCell + "ContentWhere";
const cssClassCellHasTooltip = cssClassCell + "--hasTooltip";
const cssClassHeadAreaOrRegion = baseClass + "-table--areaOrRegion";

/**
 *
 * @param broadId {string} The brad market taxonmy id
 *
 *  This method conver the taxonomy name into a cutted string to use in the
 *  header cell of the market table as an icon
 *
 * Broad - Developed Europe
 * Broad - Developed Asia Pacific
 * Broad - Developed Americas
 * Broad - Emerging Asia
 * Broad - Eemerging Americas
 * Broad -Emerging  Europe
 */
const turnBroadTaxonIntoLabel = (
  broadId: "R_D_A_B" | "R_D_P_B" | "R_D_E_B" | "R_E_A_B" | "R_E_E_B" | "R_E_P_B"
) => {
  switch (broadId) {
    case "R_D_A_B":
      return "Developed <br> America <br> Broad";
    case "R_D_P_B":
      return "Developed <br> Asia <br> Broad";
    case "R_D_E_B":
      return "Developed <br> Europe <br> Broad";
    case "R_E_A_B":
      return "Emerging Americas <br> Broad";
    case "R_E_P_B":
      return "Emerging Asia <br> Broad";
    case "R_E_E_B":
      return "Emerging Europe <br> Broad";

    default:
      return broadId;
  }
};

const getCellContent = (params, cellData, tHeadRef, t) => {
  let cellContent = "";

  switch (params["whereType"]) {
    case "Area": {
      tHeadRef.current?.classList?.add(cssClassHeadAreaOrRegion);

      cellContent = cellData["name"];

      break;
    }

    case "Country": {
      let broadMarketIcon = "";

      if (cellData.type === "ETF" && cellData["name"].includes("Broad")) {
        broadMarketIcon = `<span style="font-size:10px; font-weight: 400">${turnBroadTaxonIntoLabel(
          cellData["where"]
        )}</span>`;
        cellContent = broadMarketIcon;
      } else {
        cellContent = [
          '<img src="',
          baseFlagImageUrl,
          cellData["where"],
          '.png" width="24" height="24" alt="',
          cellData["name"] + '"/> <span class="iso">',
          cellData["where"],
          "</span>",
        ].join("");
      }

      break;
    }
    case "Region": {
      // empty cell: what column

      cellContent = cellData["name"];

      break;
    }
    case "World": {
      cellContent = [
        '<img src="',
        baseFlagImageUrl,
        'world.png" width="24" height="24" alt="',
        t("World"),
        '"/> <span class="iso">',
        t("World"),
        "</span>",
      ].join("");

      break;
    }
    default: {
      cellContent = cellData["name"];
    }
  }

  return cellContent;
};

const HeadCell = ({
  data,
  params,
  tHeadRef,
  decorator,
  getTooltipData,
  storage,
  onCellClick,
  getConstraints,
  options,
  wDefault,
  sDefault,
}) => {
  const { t } = useTranslation();
  const dispersionTimeframe = params["dispersionTimeframe"];

  if (data == null) {
    return (
      <th key={"no - data"} className={baseClass + "-tableCellCross"}>
        <PeerWhatConstraints
          whatDefault={wDefault}
          sortDefault={sDefault}
          options={options}
          getConstraints={getConstraints}
        />
      </th>
    );
  } else {
    const cellContent = getCellContent(params, data, tHeadRef, t);

    return (
      <th
        onClick={() => onCellClick(data["id"])}
        key={`${data["id"]}`}
        className={`${[cssClassCell, cssClassCellHasTooltip].join(
          " "
        )} ${decorator(data, "cellWhere")}`}
        data-peer-id={data["id"]}
      >
        <div
          className={[
            cssClassCellContentWrapper,
            cssClassCellContentWrapperWhere,
          ].join(" ")}
        >
          <CellTooltip
            dispersionAnalytic={dispersionTimeframe}
            placement="bottom"
            getData={() => getTooltipData(data?.["id"], storage, params, t)}
          >
            <div
              className={cssClassCellContentWhere}
              dangerouslySetInnerHTML={{
                __html: cellContent,
              }}
            ></div>
          </CellTooltip>
        </div>
      </th>
    );
  }
};

export default function PeerTableHead({
  data,
  params,
  storage,
  decorator,
  getTooltipData,
  onCellClick,
  setFCellDims,
  options,
  getConstraints,
  whatDefault,
  sortWhatDefault,
}: PeerTableHeadProps) {
  const rowData = useMemo(() => data?.[0], [data]);
  const tHeadRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    const firstRow = tHeadRef.current?.firstChild;
    const firstTableCell = firstRow?.firstChild;

    setFCellDims((firstTableCell as any).getBoundingClientRect());
  }, [setFCellDims]);

  return (
    <thead ref={tHeadRef}>
      {params["whereType"] === "Region" && (
        <>
          {(() => {
            tHeadRef.current?.classList.add(cssClassHeadAreaOrRegion);
            var areas = storage.getAreas(params["instrumentType"]);
            return (
              <tr>
                <th></th>
                {areas?.map((area, index) => (
                  <th
                    key={area.id + "__" + index}
                    className={[cssClassCell, cssClassCellCompound].join(" ")}
                    colSpan={area?.["colSpan"]}
                    dangerouslySetInnerHTML={{
                      __html: [
                        '<div class="',
                        cssClassCellContentWrapper,
                        '">',
                        area?.["name"],
                        "</div>",
                      ].join(""),
                    }}
                  ></th>
                ))}
              </tr>
            );
          })()}
        </>
      )}
      <tr>
        {rowData?.map((cellData, i) => (
          <HeadCell
            key={`${cellData ? cellData.id : "cross-cell"}__${i}`}
            getTooltipData={getTooltipData}
            options={options}
            sDefault={sortWhatDefault}
            wDefault={whatDefault}
            tHeadRef={tHeadRef}
            decorator={decorator}
            storage={storage}
            getConstraints={getConstraints}
            onCellClick={onCellClick}
            data={cellData}
            params={params}
          />
        ))}
      </tr>
    </thead>
  );
}
