export type StrategyStateType = {
  benchmark: string | null;
  currency: "local" | "USD" | "EUR" | "GBP" | "CHF" | "CAD" | "JPY" | "AUD" | null;
  holdings: number | null;
  performance: "NONE" | "REBALANCE" | null;
  rebalance: "05_DAYS" | "20_DAYS" | "60_DAYS" | null;
  errorAtInputValue: boolean;
};

type ActionType =
  | "SET_BENCHMARK"
  | "SET_CURRENCY"
  | "SET_PERFORMANCE"
  | "SET_REBALANCE"
  | "SET_HOLDINGS"
  | "SET_STATE"
  | "SET_ERROR_AT_INPUT_VALUE";

type Action = {
  type: ActionType;
  payload: any;
};

export function reducer(draft: StrategyStateType, action: Action) {
  switch (action.type) {
    case "SET_BENCHMARK":
      draft.benchmark = action.payload;
      break;
    case "SET_CURRENCY":
      draft.currency = action.payload;
      break;
    case "SET_PERFORMANCE":
      draft.performance = action.payload;
      break;
    case "SET_REBALANCE":
      draft.rebalance = action.payload;
      break;
    case "SET_HOLDINGS":
      draft.holdings = action.payload;
      break;
    case "SET_STATE":
      draft.benchmark = action.payload.benchmark;
      draft.currency = action.payload.currency;
      draft.holdings = action.payload.holdings;
      draft.performance = action.payload.performance;
      draft.rebalance = action.payload.rebalance;
      break;
    case "SET_ERROR_AT_INPUT_VALUE":
      draft.errorAtInputValue = action.payload;
      break;
    // no default
  }
}
