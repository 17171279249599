import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Lists } from "../../api/compute/Lists";
import { Strategies } from "../../api/compute/Strategies";
import { SystematicProducts } from "../../api/compute/SystematicProducts";
import { useEnvironment } from "../../hooks/useEnvironment";
import { messageError } from "../../js/app/utils";
import { PanelForLists } from "../PanelForLists/PanelForLists";
import styles from "./StrategyPicker.module.scss";
import { useBroadcast } from "../../hooks/useBroadcast";

/**
 * This is designed as an uncontrolled component so the id props is to be passed only at first render to initialize the
 * widget. If the id changes the function that updates the parent component and this may cause a loop.
 */

type StrategyPickerProps = {
  id?: string;
  updateStrategy: (id) => void;
  apiKey: "strategies" | "lists" | "systematicProducts";
};

const resource = {
  strategies: { single: "Strategy", plural: "Strategies" },
  lists: { single: "Portfolio", plural: "Portfolios" },
  systematicProducts: { single: "Product", plural: "Products" },
};

export const StrategyPicker = memo(
  ({ id, updateStrategy, apiKey }: StrategyPickerProps) => {
    const [strategiesMap, updateStrategyMap] = useState({});

    const environment = useEnvironment();
    const setup = useMemo(() => environment.get("setup"), [environment]);

    const [isLoading, setIsLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedId, setSelectedId] = useState<any>();

    const { broadcast } = useBroadcast();

    const API = useMemo(() => {
      const singleton = {
        strategies: Strategies,
        lists: Lists,
        systematicProducts: SystematicProducts,
      };

      return new singleton[apiKey](setup);
    }, [apiKey, setup]);

    const getStrategies = useCallback(
      async (id?: string) => {
        const strategiesMap = {};

        setIsLoading(true);

        try {
          let strategies: any = null;

          switch (apiKey) {
            case "lists":
              // TODO: lists should be implemented
              break;
            case "systematicProducts":
              // TODO: sms should be used as strategies
              if (id != null) {
                strategies = await (API as SystematicProducts).getById(id);
              } else {
                strategies = await (API as SystematicProducts).get();
              }

              break;
            case "strategies":
              if (id != null) {
                strategies = await (API as Strategies).getById(id);
              } else {
                strategies = await (API as Strategies).getList();
              }
          }

          if (strategies) {
            if (id) {
              const strategy = strategies;

              updateStrategyMap((prevState) => {
                prevState[strategy.id] = strategy;

                return prevState;
              });

              setSelectedId(id);
            } else {
              // HORRIBLE TODO: make sms work as strategies
              const strategyList =
                apiKey === "systematicProducts" ? strategies.data : strategies;

              for (const strategy of strategyList) {
                strategiesMap[strategy.id] = strategy;
              }

              updateStrategyMap(strategiesMap);
            }
          }
        } catch (error) {
          const [channel, msg] = messageError(
            `Cannot load strategy with id: ${id}`
          );
          broadcast(channel as string, msg);
          setSelectedId(undefined);
        } finally {
          setIsLoading(false);
        }
      },
      [API, apiKey, broadcast]
    );

    useEffect(() => {
      if (id) {
        updateStrategy(id);
        getStrategies(id);
      }

      return () => {
        updateStrategyMap({});
        setIsLoading(false);
        setShowDialog(false);
      };
    }, [getStrategies, id, updateStrategy]);

    const onOpenPanel = useCallback(() => {
      setShowDialog(true);

      getStrategies();
    }, [getStrategies]);

    const closeDialog = useCallback(() => {
      setShowDialog(false);
    }, []);

    const panelOptions = useMemo(() => {
      return Object.values(strategiesMap)
        .map((strategy: any) => ({
          id: strategy.id,
          isSubscribed: strategy.isReadOnly,
          name: strategy.name,
          type: resource[apiKey]["plural"],
        }))
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (aName > bName) {
            return 1;
          } else if (aName < bName) {
            return -1;
          }

          return 0;
        });
    }, [apiKey, strategiesMap]);

    const onSelectStrategy = useCallback(
      (id) => {
        setSelectedId(id);

        updateStrategy(id);
      },
      [updateStrategy]
    );

    const strategyLabel = useMemo(() => {
      if (selectedId) {
        if (selectedId in strategiesMap && strategiesMap[selectedId]) {
          return strategiesMap[selectedId]?.["name"] ?? "";
        }
      }

      return `Select ${resource[apiKey].single}`;
    }, [apiKey, selectedId, strategiesMap]);

    return (
      <>
        <PanelForLists
          headerTitle={`Select ${resource[apiKey].single}`}
          selectItem={onSelectStrategy}
          showDialog={showDialog}
          isLoadingData={isLoading}
          closeDialog={closeDialog}
          list={panelOptions}
          sectionsTag={[resource[apiKey].plural]}
        />
        <Box
          flex={1}
          className={styles.strategyBoxHoverable}
          onClick={onOpenPanel}
        >
          <Typography>
            {strategyLabel}{" "}
            {selectedId &&
            selectedId in strategiesMap &&
            strategiesMap[selectedId]?.["isReadOnly"] === true ? (
              <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
            ) : (
              <></>
            )}
          </Typography>
        </Box>
      </>
    );
  }
);
