import { useEffect, useMemo, useState } from "react";
import { useImmerReducer } from "use-immer";
import { FilterSummary } from "../../../../../../components/FilterWidget/FilterSummary";
import {
  filterReducerETF,
  filterStateETF,
} from "../../../../../../components/FilterWidget/filterReducer";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  _getUserPreferences,
  getInitialState,
} from "../../../../../../components/FilterWidget/filterWidgetHelper";
import { useTaxonomyByType } from "../../../../../../hooks/useTaxonomyByType";
import { FilterCard } from "../../../screening/FilterBar/FilterRowContraints";
import { MarketCapFilter } from "../../../screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import { TaxonomyFieldRow } from "../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";

type NewsFilterETFProps = {
  setFiltersETF: (filters) => void;
  saveFiltersETF: (filters) => void;
};

export function NewsFilterETF({
  setFiltersETF,
  saveFiltersETF,
}: NewsFilterETFProps) {
  const environment = useEnvironment();
  const userPreferencesFromSettings = _getUserPreferences(environment);
  const savedNewsFilters = window.App.user.alertsNewsFilters?.filters ?? null;

  const state = getInitialState(
    filterStateETF,
    savedNewsFilters,
    userPreferencesFromSettings,
    "etf"
  );

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [filters, dispatch] = useImmerReducer(filterReducerETF, state);
  const { taxonomiesMapX, getTaxonomyMap, advancedNodesSelector } =
    useTaxonomyByType("ETF");

  const [peerSize, setPeerSize] = useState(filters.marketCap);

  useEffect(() => {
    dispatch({
      type: "SET_MARKET_CAP_ETF",
      payload: peerSize,
    });
  }, [dispatch, peerSize]);

  useEffect(() => {
    setFiltersETF(filters);
  }, [filters, setFiltersETF]);

  const saveFiltersHandler = () => {
    saveFiltersETF(filters);
    setShowEditor(false);
  };

  const assetClassTaxonomies = useMemo(
    () => getTaxonomyMap("ETF", "etfclass"),
    [getTaxonomyMap]
  );
  const assetClassColumns = useMemo(
    () => [
      [
        {
          taxonomyId: "ETF_EQ",
          children: [
            [
              { taxonomyId: "ETF_EQ_SR" },
              { taxonomyId: "ETF_EQ_FO" },
              { taxonomyId: "ETF_EQ_SZ" },
            ],
          ],
        },
        { taxonomyId: "ETF_AL" },
      ],
      [
        {
          taxonomyId: "ETF_FI",
          children: [
            [
              { taxonomyId: "ETF_FI_BM" },
              { taxonomyId: "ETF_FI_CO" },
              { taxonomyId: "ETF_FI_GO" },
              { taxonomyId: "ETF_FI_MU" },
              { taxonomyId: "ETF_FI_SO" },
            ],
          ],
        },
      ],
      [
        { taxonomyId: "ETF_AA" },
        { taxonomyId: "ETF_CU" },
        {
          taxonomyId: "ETF_CO",
          children: [
            [{ taxonomyId: "ETF_CO_AG" }, { taxonomyId: "ETF_CO_EN" }],
          ],
        },
      ],
    ],
    []
  );
  const assetClassValidLevels = useMemo(
    () => ["1 Industry", "3 Sector", "4 Subsector"],
    []
  );

  return (
    <>
      {showEditor ? (
        <Card sx={{ boxShadow: 2 }}>
          <CardContent>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Typography>
                <strong>Filter by:</strong>
              </Typography>
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  gap={1}
                  alignItems={"center"}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Box>
                      <TaxonomyFieldRow
                        showAllSelected={false}
                        hasMultipleChoices={true}
                        label={"Investment Region"}
                        handleValuesSelection={(e) => {
                          if (e.length === 0) {
                            dispatch({
                              type: "SET_COUNTRY_ETF",
                              payload: [] as any,
                            });
                            return;
                          }
                          let val = advancedNodesSelector(
                            e[0].value.segments,
                            "etfgeo",
                            true
                          );
                          dispatch({
                            type: "SET_COUNTRY_ETF",
                            payload: val,
                          });
                        }}
                        taxonomyMap={taxonomiesMapX}
                        selectedValues={
                          filters.whereETF ? filters.whereETF : []
                        }
                        taxonomyType={"ETF"}
                        taxonomyField={"etfgeo"}
                        phraseOnEmpty="World wide"
                      />
                    </Box>
                    <Box>
                      <TaxonomyFieldRow
                        showAllSelected={false}
                        hasMultipleChoices={true}
                        label={"Asset Class"}
                        handleValuesSelection={(e) => {
                          if (e.length === 0) {
                            dispatch({
                              type: "SET_ASSET_CLASS_ETF",
                              payload: [] as any,
                            });
                            return;
                          }
                          let val = advancedNodesSelector(
                            e[0].value.segments,
                            "etfclass",
                            true,
                            assetClassValidLevels
                          );

                          dispatch({
                            type: "SET_ASSET_CLASS_ETF",
                            payload: val,
                          });
                        }}
                        selectedValues={
                          filters.assetClass ? filters.assetClass : []
                        }
                        taxonomyMap={assetClassTaxonomies}
                        taxonomyType={"ETF"}
                        taxonomyField={"etfclass"}
                        phraseOnEmpty="World wide"
                        columnsDefinition={assetClassColumns}
                        validTreeNodes={assetClassValidLevels}
                      />
                    </Box>
                    <FilterCard label={"Market Cap"}>
                      <MarketCapFilter
                        hasRangeSlider={false}
                        updateValue={(size) => {
                          if (size.le === null && size.ge === null) {
                            setPeerSize({
                              left: null,
                              right: null,
                            });
                          } else {
                            setPeerSize({
                              left: size.ge * 1000000,
                              right: size.le * 1000000,
                            });
                          }
                        }}
                        initValue={{
                          le: filters.marketCap.right
                            ? parseInt(filters.marketCap.right) / 1000000
                            : null,
                          ge: filters.marketCap.left
                            ? parseInt(filters.marketCap.left) / 1000000
                            : null,
                        }}
                      />
                    </FilterCard>
                  </Box>
                </Box>
              </Box>
              <div className="tNews-filters-button-save-wrapper">
                <button
                  onClick={saveFiltersHandler}
                  className="tNews-filters-button-save"
                >
                  Done
                </button>
              </div>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <FilterSummary
          filterState={filters}
          openEditor={setShowEditor}
          widgetFilterType={"ETFs"}
        />
      )}
    </>
  );
}
