export const widgetsConfiguration = {
  // #region -------------------------------------------------- analysis/lists
  "analysis/lists/buy-and-sell": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "isin",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
    ],
  },
  // #endregion --------------------------------------------------------------

  // CSV
  "widgets/csv/builder/constituents": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "isin",
      },
      {
        date: null,
        property: "mc",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "px",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  "widgets/csv/portfolio/buy-sell": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "isin",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
    ],
  },
  // ------------------------------------------------------------------ alerts
  "pinned/instruments": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "vc",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  // #region --------------------------------------------- analysis-collection
  "widgets/analysis/collection/add-security": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  "widgets/analysis/collection/best-continuation": {
    properties: [
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "upi",
      },
    ],
  },
  "widgets/analysis/collection/cross-frequencies": {
    properties: [
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/distribution": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "lr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/duration-magnitude": {
    properties: [
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "lt",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pt",
      },
      {
        date: null,
        property: "ptx",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/edit": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "etfclass",
      },
      {
        date: null,
        property: "etfgeo",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  "widgets/analysis/collection/pmr": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "lr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/point-in-time/distribution": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "fm",
      },
      {
        date: null,
        property: "fq",
      },
      {
        date: null,
        property: "fy",
      },
      {
        date: null,
        property: "lr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/point-in-time/pmr": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "fm",
      },
      {
        date: null,
        property: "fq",
      },
      {
        date: null,
        property: "fy",
      },
      {
        date: null,
        property: "lr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/analysis/collection/trends-vs-performance": {
    properties: [
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "lt",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pt",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  // #endregion --------------------------------------------------------------
  // #region ------------------------------------------ new Portfolio Analysis
  "widgets/portfolio/analysis/allocation/WhatWherePosition": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "symbol",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/distribution": {
    properties: [
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/keyData": {
    properties: [
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/keyDataBenchmark": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/news": {
    properties: [
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "lhl",
      },
      /* {
                    'date': null,
                    'property': 'lhigh'
                },
                {
                    'date': null,
                    'property': 'llow'
                }, */
      {
        date: null,
        property: "lr",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "upi",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/performer": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "ps",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
    ],
  },
  "widgets/portfolio/analysis/overview/ratingChange": {
    properties: [
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
    ],
  },
  "widgets/portfolio/analysis/index/instruments": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "lhigh",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "llow",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "px",
      },
    ],
  },
  // #endregion --------------------------------------------------------------
  // ----------------------------------------------------------- analysis-peer
  "widgets/analysis/peer": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  // ------------------------------------------------------- analysis-security
  "widgets/analysis/security": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "drr",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "mc",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "prr",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "pw",
      },
      {
        date: null,
        property: "px",
      },
      {
        date: null,
        property: "py",
      },
      {
        date: null,
        property: "pytd",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "upi",
      },
      {
        date: null,
        property: "ts",
      },
      {
        date: null,
        property: "vc",
      },
    ],
    properties_etf: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "drr",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "etfclass",
      },
      {
        date: null,
        property: "etfgeo",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "mc",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "prr",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "pw",
      },
      {
        date: null,
        property: "px",
      },
      {
        date: null,
        property: "py",
      },
      {
        date: null,
        property: "pytd",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "subtype",
      },
      {
        date: null,
        property: "upi",
      },
      {
        date: null,
        property: "ts",
      },
      {
        date: null,
        property: "vc",
      },
    ],
    properties_stock: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "drr",
      },
      {
        date: null,
        property: "duration",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "magnitude",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "mc",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "prr",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "pw",
      },
      {
        date: null,
        property: "px",
      },
      {
        date: null,
        property: "py",
      },
      {
        date: null,
        property: "pytd",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "stockclass",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "upi",
      },
      {
        date: null,
        property: "ts",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  // ----------------------------------------------------------------- builder
  "widgets/builder/holdings": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "ticker",
      },
    ],
  },
  "widgets/builder/benchmark": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      } /* ,
                {
                    'date': null,
                    'property': 'type'
                } */,
    ],
  },
  // search
  "widgets/search/Favorite": {
    properties: [
      {
        date: null,
        property: "exchange",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      } /* ,
                {
                    'date': null,
                    'property': 'type'
                } */,
    ],
  },
  "widgets/search/Result": {
    properties_peer: [],
    properties_security: [
      {
        date: null,
        property: "exchange",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      } /* ,
                {
                    'date': null,
                    'property': 'type'
                } */,
    ],
  },
  "widgets/search/ResultAll": {
    properties_peer: [],
    properties_security: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "exchange",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  // security
  "widgets/security/FloatingChart": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "drr",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "prr",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/security/PerformanceOverview": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "pw",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "pq",
      },
      {
        date: null,
        property: "py",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
  "widgets/security/Tooltip": {
    properties: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "etfgeo",
      },
      {
        date: null,
        property: "etfclass",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "vc",
      },
    ],
    properties_etf: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "etfclass",
      },
      {
        date: null,
        property: "etfgeo",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "vc",
      },
    ],
    properties_stock: [
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "dc",
      },
      {
        date: null,
        property: "domicile",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "lhl",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  // systematic product
  "widgets/systematic-product/rebalance/diff": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "isin",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  "widgets/systematic-product/rebalance/last": {
    properties: [
      {
        date: null,
        property: "currency",
      },
      {
        date: null,
        property: "country",
      },
      {
        date: null,
        property: "icb",
      },
      {
        date: null,
        property: "isin",
      },
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "marketcap",
      },
      {
        date: null,
        property: "mc",
      },
      {
        date: null,
        property: "pm",
      },
      {
        date: null,
        property: "px",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "symbol",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "vc",
      },
    ],
  },
  // viewer
  "widgets/viewer/Tile": {
    properties: [
      {
        date: null,
        property: "name",
      },
      {
        date: null,
        property: "dr",
      },
      {
        date: null,
        property: "drr",
      },
      {
        date: null,
        property: "pr",
      },
      {
        date: null,
        property: "prr",
      },
      {
        date: null,
        property: "rc",
      },
      {
        date: null,
        property: "rrr",
      },
      {
        date: null,
        property: "ticker",
      },
      {
        date: null,
        property: "type",
      },
    ],
  },
};
