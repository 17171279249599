import { TrendratingTable } from "../../../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../../../hooks/useFormatter";

type PerformersTableProps = {
  data: any;
  trend: "Top" | "Bottom";
  period: "pd" | "pw" | "pm" | "pq" | "ps" | "py";
  label: string;
};

const columnFormatter = (cell, formatter, property) => {
  const object = cell.getData();

  return formatter.table(property, "table", object);
};

export function PerformersTable({
  data,
  trend,
  period,
  label,
}: PerformersTableProps) {
  const formatter = useFormatter();

  return (
    <div
      className="blockWrapper performance-block"
      style={{ flex: "1 0 auto" }}
    >
      <h2>{trend} 5 Performers</h2>
      {data.length ? (
        <div>
          <TrendratingTable
            autoResize={false}
            tooltip={{
              actions: {
                info: { enabled: true },
              },
            }}
            options={{
              resizableColumns: false,
              movableColumns: false,
              cellHozAlign: "left",
            }}
            columns={[
              {
                title: "Weight",
                field: "weight",
                formatter: (cell) => columnFormatter(cell, formatter, "weight"),
              },
              {
                title: "Ticker",
                field: "ticker",
              },
              {
                title: "Name",
                field: "name",
              },
              {
                title: "Price",
                field: "vc",
                formatter: (cell) => columnFormatter(cell, formatter, "vc"),
              },
              {
                title: "Rating",
                field: "rc",
                formatter: (cell) => columnFormatter(cell, formatter, "rc"),

                hozAlign: "center",
              },
              {
                title: `${label}`,
                field: period,
                formatter: (cell) => columnFormatter(cell, formatter, period),
              },
            ]}
            data={data}
          />
        </div>
      ) : (
        <p>No {trend} performer was found</p>
      )}
    </div>
  );
}
