import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../deepClone";
import { NewHighLowType, newHighLowReducer } from "./React_NewHighLow_reducer";

type Props = {
  configObj: any;
};

const NewHighLow = forwardRef(({ configObj }: Props, ref) => {
  const initState: NewHighLowType = useMemo(
    () => ({
      content: configObj.widgetValue.content,
      presentation: configObj.widgetValue.presentation,
    }),
    [configObj.widgetValue.content, configObj.widgetValue.presentation]
  );
  const [state, dispatch] = useImmerReducer(newHighLowReducer, initState);

 

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state.content;
      tempState.widgetValue.presentation = state.presentation;
      return tempState;
    },
  }));
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box display={"flex"}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.headline.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_HEADLINE_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              disabled={!state.content.headline.isEnabled}
              value={state.content.headline.content}
              onChange={(e) =>
                dispatch({
                  type: "SET_CONTENT_HEADLINE_CONTENT",
                  payload: e.target.value,
                })
              }
              size="small"
            />
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important", display:"flex", flexDirection:"column" }}>
          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.newHigh}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_NEWHIGH",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="New highs"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.newLow}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_NEW_LOW",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="New lows"
          />
          <Box display={"flex"} alignItems={"center"}>
            <Typography>Max rows:</Typography>
            <InputNumber
              initVal={state.content.numberOfItems}
              maxWidth={100}
              isPercentage={false}
              setOuterState={(e) =>
                dispatch({ type: "SET_CONTENT_NUMBERS_OF_ITEMS", payload: e })
              }
              isFloating={false}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default NewHighLow;
