import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import {
  TaxonomyFieldRow,
  TaxonomyFieldRowProps,
} from "../advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";
import { WhiteListPanel } from "../advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/WhiteListPanel/WhiteListPanel";
import { Strategy } from "./editorReducer";

type SelectUniverseProps = {
  params: Strategy;
  dispatch: Function;
  onSelect: (index: number) => void;
  selectedIndex: number;
};

export function SelectUniverse({
  params,
  dispatch,
  onSelect,
  selectedIndex,
}: SelectUniverseProps) {
  const environment = useEnvironment();
  const [strategy, setStrategy] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getList = useCallback(async () => {
    if (params?.universe?.whiteList?.id != null) {
      setIsLoading(true);
      const list = await window.App.user.data.collections.get(
        params?.universe?.whiteList?.id
      );
      if (list != null) {
        setStrategy(list);
      }
      setIsLoading(false);
    }
  }, [params?.universe?.whiteList?.id]);

  useEffect(() => {
    getList();
  }, [getList]);

  const isInternal =
    environment.get("account")?.user?.groups?.includes("INTERNAL") ?? false;

  const numberSpinnerConstraint = {
    min: 1,
    max: isInternal ? 10000 : 5000,
  };

  let universe = useMemo(() => {
    let _universe = {
      search: {
        filters: [
          {
            dimension: "type",
            segments: ["Stock"],
          },
          {
            dimension: "country",
            segments: params?.universe?.screening?.whereSource?.market ?? [],
          },
          {
            dimension: "stockclass",
            segments:
              params?.universe?.screening?.whereSource?.stockClassification ??
              [],
          },
          {
            dimension: "icb",
            segments: params?.universe?.screening?.what ?? [],
          },
        ],
      },
    };

    // Prepare subtype

    const domestic = params?.universe?.screening?.whereSource.domestic ?? false;
    const foreign = params?.universe?.screening?.whereSource.foreign ?? false;
    if (domestic && !foreign) {
      _universe.search.filters.push({
        dimension: "subtype",
        segments: ["Domestic Stock"],
      });
    } else if (!domestic && foreign) {
      _universe.search.filters.push({
        dimension: "subtype",
        segments: ["Foreign Stock"],
      });
    }

    return _universe;
  }, [params]);

  var taxonomy: any = [];
  const txFields = useMemo(
    () => environment.get("taxonomyFields"),
    [environment]
  );
  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  for (var key in taxonomies[txFields["security"]["country"]]) {
    taxonomy.push(taxonomies[txFields["security"]["country"]][key]);
  }

  const settingBasket = useCallback(
    (e) => {
      if (params.universe.whiteList?.id !== e) {
        window.App.user.data.collections.get(e).then((value) =>
          dispatch({
            type: "SELECT_BASKET",
            payload: {
              id: value.id,
              type: value.type,
            },
          })
        );
      }
    },
    [dispatch, params.universe.whiteList?.id]
  );
  const { taxonomiesMapX, taxonomiesMapY } = useTaxonomyByType("stock");

  const marketSegments = useMemo(() => {
    if (universe.search.filters.length > 0) {
      for (let i = 0; i < universe.search.filters.length; i++) {
        let item = universe.search.filters[i];
        if (item.dimension === "country") {
          return item.segments;
        }
      }
    }
    return [];
  }, [universe.search.filters]);
  const sectorSegments = useMemo(() => {
    if (universe.search.filters.length > 0) {
      for (let i = 0; i < universe.search.filters.length; i++) {
        let item = universe.search.filters[i];
        if (item.dimension === "icb") {
          return item.segments;
        }
      }
    }
    return [];
  }, [universe.search.filters]);
  const icbTaxonomyPanelConfig: TaxonomyFieldRowProps["columnsDefinition"] =
    useMemo(
      () => [
        [{ taxonomyId: "20" }, { taxonomyId: "60" }],
        [{ taxonomyId: "25" }, { taxonomyId: "50" }],
        [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
        [{ taxonomyId: "40" }, { taxonomyId: "45" }],
        [{ taxonomyId: "55" }, { taxonomyId: "30" }],
      ],
      []
    );
  const validLevels = useMemo(() => ["1 Industry", "3 Sector"], []);

  return (
    <div>
      <Tabs
        sx={{ paddingBottom: "8px" }}
        onChange={(e, value) => onSelect(value)}
        value={selectedIndex}
      >
        <Tab label={"Basket"} value={0} />
        <Tab label={"Peer group"} value={1} />
      </Tabs>
      {selectedIndex === 0 ? (
        <>
          {isLoading === true ? (
            "loading..."
          ) : (
            <WhiteListPanel
              subject={"White List"}
              setWhiteListValue={settingBasket}
              initValue={strategy?.["id"] ?? null}
              closeIcon={true}
            />
          )}
        </>
      ) : (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Box display={"flex"} gap={1}>
            <TaxonomyFieldRow
              label={"Market"}
              handleValuesSelection={(e) => {
                dispatch({
                  type: "SELECT_MARKET",
                  payload: e,
                });
              }}
              taxonomyMap={taxonomiesMapX}
              selectedValues={marketSegments}
              taxonomyType={"security"}
              taxonomyField={"country"}
              phraseOnEmpty="World wide"
            />

            <TaxonomyFieldRow
              label={"Sectors"}
              handleValuesSelection={(e) => {
                dispatch({
                  type: "SELECT_SECTOR",
                  payload: e[0].value.segments,
                });
              }}
              taxonomyMap={taxonomiesMapY}
              taxonomyType={"security"}
              taxonomyField={"icb"}
              selectedValues={sectorSegments}
              columnsDefinition={icbTaxonomyPanelConfig}
              validTreeNodes={validLevels}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography>Eligibility</Typography>
              <TextField
                InputProps={{ inputProps: { min: 0 } }}
                sx={{ width: "7em" }}
                type="number"
                size="small"
                value={
                  params?.universe?.screening?.eligibility?.cardinality ?? "200"
                }
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  if (
                    value > numberSpinnerConstraint.min &&
                    value < numberSpinnerConstraint.max
                  ) {
                    dispatch({
                      type: "SET_ELIGIBILITY",
                      payload: value,
                    });
                  } else if (value < numberSpinnerConstraint.min) {
                    dispatch({
                      type: "SET_ELIGIBILITY",
                      payload: numberSpinnerConstraint.min,
                    });
                  } else if (value > numberSpinnerConstraint.max) {
                    dispatch({
                      type: "SET_ELIGIBILITY",
                      payload: numberSpinnerConstraint.max,
                    });
                  }
                }}
              />
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <Select
                size="small"
                onChange={(e) => {
                  dispatch({
                    type: "SET_UNIVERSE_SORTING",
                    payload: e.target.value,
                  });
                }}
                value={
                  params?.universe?.screening?.eligibility?.sortBy ?? "desc"
                }
              >
                <MenuItem value="desc">Highest Mkt. Cap.</MenuItem>
                <MenuItem value="asc">Lowest Mkt. Cap.</MenuItem>
              </Select>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        dispatch({
                          type: "SET_ELIGIBILITY_ACTIVATION",
                          payload: e.target.checked,
                        });
                      }}
                      checked={
                        params?.universe?.screening?.eligibility?.isEnabled
                      }
                    />
                  }
                  label="At rebalance"
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}
