import { Box, Typography } from "@mui/material";
import Search from "../../../../../../../../widgets/ReactSearch/Search";

type SearchInstrumentsProps = {
  dynamicHedgingActive: boolean;
  value: string | null;
  dispatcher: Function;
};

export default function SearchInstruments({
  dispatcher,
  dynamicHedgingActive,
  value,
}: SearchInstrumentsProps) {
  return (
    <Box display={"flex"} alignItems="center" gap={1}>
      <Typography>Instruments</Typography>
      <Search
        value={value ?? undefined}
        onSelectInstrument={(e) => {
          dispatcher({ type: "SET_INSTRUMENT", payload: e.symbol });
        }}
        showInstrumentInfoOnSelect={false}
        hasRecents={false}
        disabled={dynamicHedgingActive}
      />
    </Box>
  );
}
