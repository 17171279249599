import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Search from "../../../../../widgets/ReactSearch/Search";
import { WhiteListPanel } from "../../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/WhiteListPanel/WhiteListPanel";
import {
  blendedBenchmarkTag,
  currencyMap,
} from "../../EditContentReact/Cards/BasicInfo";

type Props = {
  strategyHandler: (e) => void;
  nameHandler: (e) => void;
  currencyHandler: (e) => void;
  benchmarkHandler: any;
  strategies: any;
  state: any;
};

const BasicInfo = forwardRef(
  (
    {
      strategyHandler,
      nameHandler,
      currencyHandler,
      benchmarkHandler,
      strategies,
      state,
    }: Props,
    ref
  ) => {
    const _strategies = useMemo(() => strategies, [strategies]);
    const [nameIsValid, setNameIsValid] = useState(true);
    const [nameIsUnique, setNameIsUnique] = useState(true);
    const [strategyIsValid, setStrategyIsValid] = useState(true);
    const [benchmarkRadio, setBenchmarkRadio] = useState<any>(null);
    const _benchmark = useMemo(() => {
      if (state.basic.benchmark != null) {
        if (!state.basic.benchmark?.includes(blendedBenchmarkTag)) {
          return state.basic.benchmark;
        }
        const blendedValue = state.basic.benchmark.split(":");
        return blendedValue[1];
      }
      return null;
    }, [state.basic.benchmark]);

    useEffect(() => {
      let benchmark = state.basic.benchmark;
      if (benchmark != null) {
        if (!benchmark?.includes(blendedBenchmarkTag)) {
          setBenchmarkRadio("benchmark");
        } else {
          setBenchmarkRadio("blended_benchmark");
        }
      } else {
        if (benchmarkRadio == null) {
          setBenchmarkRadio("none");
        }
      }
    }, [benchmarkRadio, state.basic.benchmark]);

    useImperativeHandle(
      ref,
      () => ({
        validationName: (isValid: boolean) => {
          setNameIsValid(isValid);
          return;
        },
        validationNameUniqueness: (isUnique: boolean) => {
          setNameIsUnique(isUnique);
          return;
        },
        validationStrategy: (isStrategyValid: boolean) => {
          setStrategyIsValid(isStrategyValid);
          return;
        },
      }),
      []
    );
    const instrumentSearchHandler = useCallback(
      (e) => {
        if (e) {
          benchmarkHandler(e.symbol);
        } else {
          benchmarkHandler(null);
        }
      },
      [benchmarkHandler]
    );
    const BlendedBenchmarkDropDownHandler = useCallback(
      (value) => {
        benchmarkHandler(`${blendedBenchmarkTag}:${value}`);
      },
      [benchmarkHandler]
    );
    const handleBanchmarkRadio = useCallback(
      (radioValue) => {
        benchmarkHandler(null);
        setBenchmarkRadio(radioValue);
      },
      [benchmarkHandler]
    );

    const nameHelperText = useMemo(() => {
      let phrases: string[] = [];
      if (!nameIsValid) {
        phrases.push("Please, insert a name");
      }
      if (!nameIsUnique) {
        phrases.push("Please, choose a diferent name");
      }
      if (phrases.length > 0) {
        return (
          <div>
            {phrases.map((phrase, index) => (
              <Typography key={index}>{phrase}</Typography>
            ))}
          </div>
        );
      }
      return null;
    }, [nameIsUnique, nameIsValid]);

    return (
      <Card sx={{ flex: 1, boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box pb={1}>
            <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
              Systematic portfolio
            </Typography>
          </Box>
          <Divider
            sx={{ width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
          <Box display={"flex"} flexDirection={"column"} gap={1} pt={1}>
            <Box display={"flex"} gap={2}>
              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Name:</Typography>
                <TextField
                  error={!nameIsValid || !nameIsUnique}
                  helperText={nameHelperText}
                  onChange={nameHandler}
                  sx={{ fontSize: "0.7vw" }}
                  size="small"
                />
              </Box>

              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Strategy:</Typography>
                <Select
                  error={!strategyIsValid}
                  onChange={strategyHandler}
                  size="small"
                  defaultValue={0}
                >
                  <MenuItem value={0} disabled>
                    <Typography>select a strategy</Typography>
                  </MenuItem>
                  {_strategies.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Typography>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.label }}
                        ></span>
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                {!strategyIsValid && (
                  <FormHelperText error={!strategyIsValid}>
                    Please, select a strategy
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box display={"flex"} gap={2}>
              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Benchmark:</Typography>
                <RadioGroup
                  value={benchmarkRadio}
                  onChange={(e) => handleBanchmarkRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                  <FormControlLabel
                    value="benchmark"
                    control={<Radio />}
                    label={
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography display={"flex"} alignItems={"center"}>
                          Benchmark
                        </Typography>
                        {benchmarkRadio === "benchmark" && (
                          <>
                            <Search
                              value={_benchmark}
                              showInstrumentInfoOnSelect={false}
                              onSelectInstrument={instrumentSearchHandler}
                            />
                          </>
                        )}
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="blended_benchmark"
                    control={<Radio />}
                    label={
                      <Box display={"flex"} flexDirection={"row"} gap={1}>
                        <Typography display={"flex"} alignItems={"center"}>
                          Blended benchmark
                        </Typography>
                        {benchmarkRadio === "blended_benchmark" && (
                          <WhiteListPanel
                            closeIcon={true}
                            showBasket={false}
                            setWhiteListValue={BlendedBenchmarkDropDownHandler}
                            initValue={_benchmark ? parseInt(_benchmark) : null}
                          />
                        )}
                      </Box>
                    }
                  />
                </RadioGroup>
              </Box>
              <Box flex={1} display={"flex"} gap={1} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Currency:</Typography>
                <Select
                  size="small"
                  onChange={(e) => {
                    currencyHandler(e.target.value);
                  }}
                  value={state.basic.currency ?? "-"}
                >
                  <MenuItem disabled value={"-"}>
                    <Typography>Select a currency</Typography>
                  </MenuItem>
                  {currencyMap.map((currency, index) => (
                    <MenuItem key={index} value={currency.value}>
                      <Typography>
                        <span
                          dangerouslySetInnerHTML={{ __html: currency.label }}
                        ></span>
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
);
export default BasicInfo;
