import { FormControl, MenuItem, Select } from "@mui/material";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

type OptionsFilterProps = {
    widgetParams: { options: any[] };
    value: any;
    setValue: Function;
    fullWidth?: boolean;
    onFocus?: (arg?) => void;
    onBlur?: (arg?) => void;
    handleClickOnGroupLabel?: Function;
};

export function OptionsFilter({
    widgetParams,
    value,
    setValue,
    fullWidth = true,
    onFocus,
    onBlur,
    handleClickOnGroupLabel,
}: OptionsFilterProps) {
    const handleValueSelection = useCallback(
        (opt) => {
            // It means that is not the group label
            if (opt.labelShort != null) {
                setValue(opt.value);
            } else if (handleClickOnGroupLabel) {
                handleClickOnGroupLabel(opt, setValue);
            }
        },
        [handleClickOnGroupLabel, setValue]
    );
    return (
        <FormControl fullWidth={fullWidth} size="small">
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{
                    fontSize: "0.7vw",
                }}
                value={value}
                inputProps={{
                    sx: {
                        paddingTop: "5px !important",
                        paddingBottom: "5px !important",
                    },
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            >
                {widgetParams?.options?.map((opt) => (
                    <MenuItem
                        key={uuidv4()}
                        sx={{
                            fontSize: "0.7vw",
                            paddingLeft:
                                opt.labelShort != null && opt.value !== "None"
                                    ? 2
                                    : 1,
                        }}
                        value={opt.value}
                        onClick={() => handleValueSelection(opt)}
                    >
                        {opt?.labelShort ?? opt?.label ?? ""}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
