/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Common
 * @summary Common requests
 *
 */

import { _Base } from "../_Base";
import { endpoints } from "../endpoints";

export class Common extends _Base {
    /**
     * Get specific fields from a list of ids of type classType
     *
     * @param {string}   params.classType - server type of object
     * @param {object[]} params.ids - arrays of ids of classType items
     * @param {string[]} params.properties - list of properties to retrieve for each symbol
     * @returns
     */
    async fetch(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        const url = endPointRoot + endpoints.common.fetch;

        const properties = params.properties;
        // TODO resolve properties from UI to server
        // properties =
        //     properties != null
        //         ? this.resolveDependecies(properties)
        //         : this.properties;

        // properties = this._uiToBackendProperties(properties);

        // id is mandatory, needed to merge data
        if (properties.indexOf("id") === -1) {
            properties.push("id");
        }

        const _params = [
            {
                classType: params.classType,
                id: params.ids,
                extendedRequest: {
                    onDemandResult: properties.map((field: string) => ({
                        dimension: field,
                    })),
                },
            },
        ];

        const response = await this.preparePost(url, _params, null);
        return response.data[0].rows;
    }
}
