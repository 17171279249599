import Tippy, { TippyProps } from "@tippyjs/react";
import { CSSProperties, forwardRef, ReactNode, useEffect, useRef } from "react";

type TooltipProps = {
  content: any;
  parentStyles?: CSSProperties;
  children: JSX.Element | JSX.Element[];
  styles?: { iconDimension?: number; top?: number };
  tooltipIcon: boolean;
  trigger?: TippyProps["trigger"];
  theme?: "light-border" | "security-tooltip";
  interactive?: TippyProps["interactive"];
  triggerClose?: boolean;
  placement?: "top" | "bottom" | "right" | "left";
  onShow?: (params?: any) => any;
};

const NoIconTrigger = forwardRef<
  any,
  { children: ReactNode; styles?: CSSProperties }
>(({ children, styles }, ref) => {
  return (
    <div ref={ref} style={{ ...styles }}>
      {children}
    </div>
  );
});

const TooltipWithIcon = forwardRef<
  any,
  {
    style: any;
    trigger?: TooltipProps["trigger"];
  }
>(({ style, trigger }, ref) => {
  const { fontSize, top, left } = style ?? {
    iconDimension: 14,
    top: -15,
    left: -5,
  };

  const triggerClassName =
    trigger != null ? `tooltip-trigger-${trigger}` : "tooltip-trigger-hover";
  return (
    <div className={`tooltip-icon-wrapper ${triggerClassName}`}>
      <span
        className="i-help tooltip-icon"
        ref={ref}
        style={{
          fontSize: fontSize || 14,
          top: top || -15,
          left: left || -5,
        }}
      />
    </div>
  );
});

export function Tooltip({
  children,
  content,
  styles,
  tooltipIcon,
  trigger,
  theme = "light-border",
  interactive = false,
  triggerClose,
  placement = "right",
  parentStyles,
  onShow,
}: TooltipProps) {
  const tooltipRef = useRef<any>(null);

  useEffect(() => {
    if (triggerClose != null) {
      if (triggerClose) {
        tooltipRef?.current?._tippy?.destroy();
      }
    }
  }, [triggerClose]);

  useEffect(() => {});
  return (
    <Tippy
      ref={tooltipRef}
      interactive={interactive}
      content={content}
      theme={theme}
      maxWidth={"70em"}
      placement={placement}
      //With interactive mode on some iussues with z-index may happend so using appendTo: () => document.body
      //solve problems.
      appendTo={interactive ? () => document.body : "parent"}
      popperOptions={{
        strategy: "fixed",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom", "top"],
            },
          },
        ],
      }}
      trigger={trigger}
      onShow={onShow ? onShow : () => null}
    >
      {tooltipIcon ? (
        <TooltipWithIcon style={styles} trigger={trigger} />
      ) : (
        <NoIconTrigger styles={parentStyles}>{children}</NoIconTrigger>
      )}
    </Tippy>
  );
}
