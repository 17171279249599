/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/AppCore
 * @summary Main Application entry point and routing
 *
 */

import { useEffect, useMemo, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../components/ScrollToTop";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useSystem } from "../../hooks/useSystem";
import { appCookie } from "./Cookies";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Feedback } from "./components/Feedback";
import { PdfLoader } from "./components/pages/PdfLoader/PdfLoader";
import { PerformanceRise } from "./components/pages/PerformanceRise/PerformanceRise";
import { ReportsHub } from "./components/pages/reportsHub/ReportsHub";
import Tracked from "./components/pages/strategies/newTracked/Tracked";
import { config } from "./config-ts";
import { Page404 } from "./pages/404/Page_404";
import ProductPage from "./pages/account/products/ProductPage";
import { Alerts } from "./pages/alerts/Alerts";
import Optimize from "./pages/analysisLists/OptimizeNew/Optimize";
import { AnalyzeList } from "./pages/analysisLists/analyze/AnalyzeList";
import { PortfolioHome } from "./pages/analysisLists/widgets/PortfolioHome";
import Create from "./pages/analysisLists/widgets/reactCreateEdit/Create/Create";
import { AnalysisMarket } from "./pages/analysisMarkets/AnalysisMarket";
import { AnalysisMarketsETF } from "./pages/analysisMarkets/ETFs/AnalysisMarketsETF";
import { PeerDetail } from "./pages/analysisMarkets/detail/PeerDetail";
import { AnalisysSecurity } from "./pages/analysisSecurity/AnalisysSecurity";
import { HelpIndex } from "./pages/help/Index";
import { Rank } from "./pages/rank/Rank";
import { RankContextProvider } from "./pages/rank/actions/ActionRankContext/ActionRankContext";
import { Screening } from "./pages/screening/Screening";
import ReactIndex from "./pages/strategies/ReactIndex";
import StrategiesBuilder from "./pages/strategies/builder/StrategiesBuilder";
import { CompareStrategies } from "./pages/strategies/compare/CompareStrategies";
import { CombineStrategies } from "./pages/strategies/longShort/CombineStrategies";
import Rebalance from "./pages/systematicPortfolios/Rebalance_React/Rebalance";
import { SystematicPortfolios } from "./pages/systematicPortfolios/SystematicPortfolios";
import { ActionModal } from "./utils/useActionModal";
import { useResizer } from "../../hooks/useResizer";
import { deepClone } from "../../deepClone";
import { defaultPreferences } from "../../api/account/defaultPreferences";

const LANDING_PAGE_PRODUCTS = "account/products";

function normalizePreferences(response) {
  const clone = (iTarget, iSource) => {
    var source = deepClone(iSource);
    var target = deepClone(iTarget);
    for (var key in source) {
      target[key] = source[key];
    }
    return target;
  };

  var data: any = null;
  var preferences: any = null;
  var rawData: any = null;

  if (response.data.rows && response.data.rows.length) {
    rawData = response.data.rows[0].object;
    rawData.id = response.data.rows[0].id;
  } else if (response.data.object) {
    rawData = response.data.object;
    rawData.id = response.data.id;
  }

  if (rawData) {
    // preferences = Object.assign({}, rawData.preferences);
    preferences = deepClone(rawData.preferences);
    data = {
      id: rawData.id,
      name: rawData.name,
      data: {},
      version: rawData.preferences.version,
    };

    // migrate report 2017-09-18 - START
    for (var key in preferences) {
      if (key !== "report") {
        data.data[key] = preferences[key];
      }
    }

    if ("report" in preferences) {
      if (preferences["report"] == null) {
        data["data"]["report"] = {
          general: {
            disclaimer: null,
            logo: null,
            theme: null,
          },
          market: null,
          portfolio: null,
          screening: null,
          security: null,
          strategy: null,
        };
      } else if (!("general" in preferences["report"])) {
        data["data"]["report"] = {
          general: {
            disclaimer: null,
            logo: {
              base64: preferences["report"]["logo"]["base64"],
              url: null,
            },
            theme: null,
          },
          market: null,
          portfolio: null,
          screening: null,
          security: null,
          strategy: null,
        };
      } else {
        data["data"]["report"] = {
          general: clone(
            {
              disclaimer: null,
              logo: null,
              theme: null,
            },
            preferences["report"]["general"]
          ),
          market: null,
          portfolio: null,
          screening: null,
          security: null,
          strategy: null,
        };
      }
    } else {
      data["data"]["report"] = null;
    }
    // migrate report 2017-09-18 - END

    // migrate security analysis: old customers 2019-09-24 - START
    if (data["data"]["analysisSecurity"] == null) {
      data["data"]["analysisSecurity"] = defaultPreferences["analysisSecurity"];
    }
    // migrate security analysis: old customers 2019-09-24 - END
  } else {
    data = { data: defaultPreferences, name: "global" };
  }

  return data;
}

function getPadExtents(node: HTMLElement | null) {
  if (node) {
    const p = {
      l: parseInt(
        window.getComputedStyle(node, null).getPropertyValue("padding-left")
      ),
      t: parseInt(
        window.getComputedStyle(node, null).getPropertyValue("padding-top")
      ),
      r: parseInt(
        window.getComputedStyle(node, null).getPropertyValue("padding-right")
      ),
      b: parseInt(
        window.getComputedStyle(node, null).getPropertyValue("padding-bottom")
      ),
      h: null,
      w: null,
    };

    p["w"] = (p["l"] + p["r"]) as any;
    p["h"] = (p["t"] + p["b"]) as any;

    return p;
  }

  return { l: null, t: null, r: null, b: null, w: null, h: null };
}

function resize() {
  try {
    var viewport = { h: window.innerHeight };
    const headerElement = document.getElementById("app-header") as Element;
    const footerElement = document.getElementById("app-footer") as Element;

    var header = headerElement.getBoundingClientRect();
    var footer = footerElement.getBoundingClientRect();

    var main = document.getElementById("app-main");
    var mainPadding = getPadExtents(main);
    var mainPaddingH = mainPadding?.h ?? 0;
    if (mainPaddingH > 0) {
      // Reduce padding a bit to fix layout
      // Ideally with box-sizing: border-box the padding value
      // can be ignored, but in some pages, like the Rank (empty
      // without ranks) does go over at the bottom a bit.
      mainPaddingH /= 2;
    }

    if (
      viewport?.h != null &&
      header?.height != null &&
      footer?.height != null
    ) {
      var height = viewport.h - header.height - footer.height - mainPaddingH;
      if (main) {
        main.style.height = height + "px";
      }
    }
  } catch (exception) {
    // public area doesn't have header and footer
    if (appConfig.isDebug) {
      console.log("Resize: public area");
    }
  }
}

function getLandingPage(environment) {
  var account = environment.get("account");
  var product = account.product;
  var configuration = product.configuration;

  var legacyRoutes = window.App.routes;
  // default product landing page
  var landingPage = legacyRoutes.landing[configuration["landing_page"]];

  // TO BE MIGRATED
  var preferences =
    account.user.preferences == null
      ? null
      : normalizePreferences({
          data: {
            id: account.user.preferences.id,
            name: account.user.preferences.name,
            object: account.user.preferences,
            type: account.user.preferences.type,
          },
        });

  if (
    preferences != null &&
    preferences["data"] != null &&
    preferences["data"]["general"] != null &&
    preferences["data"]["general"]["home"] != null
  ) {
    var landingPageCustom = preferences["data"]["general"]["home"];

    var landingPageOptions = configuration["landing_page_options"];

    if (landingPageOptions.indexOf(landingPageCustom) !== -1) {
      landingPage = legacyRoutes.landing[landingPageCustom];
    }
  }

  if (product.productCode === "SYSTEMATIC_ENGINE_AND_PREMIUM") {
    // 2020-07-20 multiple products for the same
    // account
    var customerCare = environment.get("customerCare");

    var url = LANDING_PAGE_PRODUCTS;
    if (customerCare.isImpersonating === true) {
      url = url + "?impersonate=" + customerCare.userId;
    }

    // window.location.href = url; not needed, used navigate
    return url;
  }

  return landingPage;
}

const checkApplicationVersion = (environment) => {
  const http = environment.get("http");
  http.accountSessions.isValid().then(
    /* sessionValid */ (responseSession) => {
      http["utils"].version().then((responseVersion) => {
        //
        // Hot Swap
        //
        // force to reload the page: when a new
        // version is released and the user is using
        // an old one
        //
        if (responseVersion.version !== appConfig.app.version) {
          console.warn(
            "Different version on server",
            "Server",
            responseVersion.version,
            "Client",
            appConfig.app.version
          );
          if (process.env.NODE_ENV === "production") {
            console.log("Reloading page...");
            // eslint-disable-next-line no-self-assign
            window.location.href = window.location.href;
          } else {
            console.log(
              "If server version is updated, please refresh page to update the client."
            );
          }
        }
      });
    },
    /* sessionExpired */ (error) => {
      // login form
      window.location.href = config.routes.appAccount;
    }
  );
};

export function AppCore() {
  const { state } = useSystem(); // , dispatch
  const navigate = useNavigate();
  const location = useLocation();
  const environment = useEnvironment();
  const pagesConfiguration = useMemo(
    () =>
      state.isReady
        ? environment.get("configuration").configuration.pages
        : null,
    [environment, state.isReady]
  );

  // showIntroMode is used for example when selecting the product
  // It does hide the main menu bar and other widgets
  const showIntroMode = useMemo(() => {
    return location.pathname.startsWith(`/app/${LANDING_PAGE_PRODUCTS}`);
  }, [location]);

  useEffect(() => {
    if (environment.get("setup").customerCare.isImpersonating === true) {
      window.document.documentElement.classList.add("impersonate");
    } else {
      window.document.documentElement.classList.remove("impersonate");
    }
  }, [environment]);

  const mainNodeRef = useRef<any>(null);

  // Register resize event of the viewport
  useResizer({ ref: mainNodeRef });

  // Call resize on App start
  useEffect(() => {
    if (!state.isReady) {
      return;
    }

    resize();
  }, [state.isReady]);

  useEffect(() => {
    if (environment.get("setup").customerCare.isImpersonating === true) {
      window.document.documentElement.classList.add("impersonate");
    } else {
      window.document.documentElement.classList.remove("impersonate");
    }
  }, [environment]);

  // Cache management, invalidate cache if there is ?ic=1 as params
  // Updates on location change,
  useEffect(() => {
    if (!state.isReady) {
      return;
    }
    checkApplicationVersion(environment);
  }, [environment, location, state.isReady]);

  useEffect(() => {
    if (state.isReady) {
      var landingPage = getLandingPage(environment);

      // landingPage do not have /app prefix
      if (landingPage.startsWith(LANDING_PAGE_PRODUCTS)) {
        // window.location.pathname contain full path with /app
        if (
          !window.location.pathname.startsWith(
            `/app/${LANDING_PAGE_PRODUCTS}`
          ) &&
          window.location.pathname !== "/app/" &&
          window.location.pathname !== "/app"
        ) {
          console.log(`Saved location ${window.location.pathname}`);
          // Save in cookie
          appCookie(
            "redirect-from-product-page",
            encodeURI(window.location.pathname)
          );
          appCookie(
            "debug-redirect-from-product-page",
            encodeURI(window.location.pathname)
          );
        }
        navigate(landingPage);
        return;
      }

      if (
        window.location.pathname === "/app/" ||
        window.location.pathname === "/app"
      ) {
        console.log("/ RESET", window.location.pathname);
        // landing from login

        if (landingPage !== LANDING_PAGE_PRODUCTS) {
          try {
            navigate(landingPage);
          } catch (e) {
            navigate(landingPage);
          }
        } else {
          navigate(landingPage);
        }
      }
    }
  }, [environment, navigate, state.isReady]);

  if (!state.isLogged) {
    return (
      <div className="app-core">
        <div className="loader">
          <h1 className="loader__message">
            Trendrating is loading, please wait ...
          </h1>
          <div className="loader__submessage">Checking user access...</div>
        </div>
      </div>
    );
  }

  if (!state.hasProduct) {
    return (
      <div className="app-core">
        <div className="loader">
          <h1 className="loader__message">
            Trendrating is loading, please wait ...
          </h1>
          <div className="loader__submessage">
            Loading product configuration...
          </div>
        </div>
      </div>
    );
  }

  if (!state.hasEnvironment) {
    return (
      <div className="app-core">
        <div className="loader">
          <h1 className="loader__message">
            Trendrating is loading, please wait ...
          </h1>
          <div className="loader__submessage">Loading data...</div>
        </div>
      </div>
    );
  }

  if (!state.isReady) {
    return (
      <div className="app-core">
        <div className="loader">
          <h1 className="loader__message">
            Trendrating is loading, please wait ...
          </h1>
          <div className="loader__submessage">Loading application...</div>
        </div>
      </div>
    );
  }

  // TODO search and remove the domClass.remove(this.getNodeLoaderPage(), "hide");

  return (
    <div className="app-core">
      <ScrollToTop />
      <Header hide={showIntroMode} />
      <Feedback channelInput={config.channels.feedback.input} />
      <div className="app__main-wrapper" id="app-main-wrapper">
        <div id="data-loader" className="loader loader--data">
          <h1 className="loader__message" id="data-loader-message">
            Data is loading, please wait ...
          </h1>
          <div className="appLoaderAnimation" id="app-loader-animation">
            <div className="appLoaderAnimation-bar"></div>
          </div>
          <div
            className="loader__message-more"
            id="data-loader-message-more"
          ></div>
        </div>
        <main className="app__main" id="app-main" ref={mainNodeRef} role="main">
          <Routes>
            <Route path="account/products" element={<ProductPage />} />
            {pagesConfiguration.alerts.enabled && (
              <Route path="alerts">
                <Route index element={<Alerts />} />
                <Route path=":timeframe" element={<Alerts />} />
              </Route>
            )}
            {pagesConfiguration.analysisInstrument.enabled && (
              <Route path="analysis/instrument">
                <Route index element={<AnalisysSecurity />} />
                <Route path=":symbol" element={<AnalisysSecurity />} />
              </Route>
            )}
            {pagesConfiguration.performanceRise.enabled && (
              <Route path="performanceRise">
                <Route index element={<PerformanceRise />}></Route>
                <Route path="create/:type" element={<Create />} />
                <Route path=":id">
                  <Route path="edit" element={<Create />} />
                </Route>
              </Route>
            )}
            {pagesConfiguration.analysisList.enabled && (
              <Route path="analysis/lists">
                <Route index element={<PortfolioHome />}></Route>{" "}
                <Route path="create/:type" element={<Create />} />
                <Route path=":id">
                  <Route index element={<AnalyzeList />} />
                  <Route path="edit" element={<Create />} />
                  <Route path="analyze">
                    <Route index element={<AnalyzeList />} />
                    <Route path=":tab">
                      <Route index element={<AnalyzeList />} />
                      <Route path=":alert" element={<AnalyzeList />} />
                    </Route>
                  </Route>
                  <Route path="optimize">
                    <Route index element={<Optimize />} />
                    <Route
                      path="with-strategy/:strategyId"
                      element={<Optimize />}
                    />
                  </Route>
                </Route>
              </Route>
            )}
            {pagesConfiguration.analysisPeer.enabled && (
              <Route path="analysis/markets">
                <Route index element={<AnalysisMarket />} />
                <Route path="ETF" element={<AnalysisMarketsETF />} />
                <Route path=":id">
                  <Route index element={<PeerDetail />} />
                  <Route path=":constraintsMnemonic" element={<PeerDetail />} />
                </Route>
              </Route>
            )}
            <Route path="help/*" element={<HelpIndex />} />
            {pagesConfiguration.ranking.enabled && (
              <>
                <Route
                  path="rank"
                  element={
                    <RankContextProvider>
                      <Rank />
                    </RankContextProvider>
                  }
                />
              </>
            )}
            {pagesConfiguration.screening.enabled && (
              <>
                <Route path="screening">
                  <Route
                    index
                    element={
                      <RankContextProvider>
                        <Screening />
                      </RankContextProvider>
                    }
                  />
                  <Route path=":type">
                    <Route
                      index
                      element={
                        <RankContextProvider>
                          <Screening />
                        </RankContextProvider>
                      }
                    />
                    <Route path=":timeframe/:alert">
                      <Route
                        index
                        element={
                          <RankContextProvider>
                            <Screening />
                          </RankContextProvider>
                        }
                      />
                      <Route
                        path=":where/:what/:size"
                        element={
                          <RankContextProvider>
                            <Screening />
                          </RankContextProvider>
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </>
            )}
            {pagesConfiguration.strategyBuilder.enabled && (
              <>
                <Route path="strategies">
                  <Route index element={<ReactIndex />}></Route>

                  {/*  */}
                  <Route path="builder">
                    <Route index element={<StrategiesBuilder />} />
                    <Route path=":type">
                      <Route index element={<StrategiesBuilder />} />
                      <Route path=":id">
                        <Route index element={<StrategiesBuilder />} />
                        <Route path=":action" element={<StrategiesBuilder />} />
                      </Route>
                    </Route>
                  </Route>
                  {pagesConfiguration.strategyBuilder.formCompare.enabled && (
                    <Route path="compare">
                      <Route index element={<CompareStrategies />} />
                      <Route path=":id/:id2">
                        <Route index element={<CompareStrategies />} />
                        <Route path=":action" element={<CompareStrategies />} />
                      </Route>
                    </Route>
                  )}
                  {pagesConfiguration.strategyBuilder.formLongShort.enabled && (
                    <Route path="combined">
                      <Route
                        index
                        element={<CombineStrategies type={"strategies"} />}
                      />
                      <Route path=":id">
                        <Route
                          index
                          element={<CombineStrategies type={"strategies"} />}
                        />
                        <Route
                          path=":action"
                          element={<CombineStrategies type={"strategies"} />}
                        />
                      </Route>
                    </Route>
                  )}
                  {pagesConfiguration.strategyTracker.enabled && (
                    <Route path="tracked" element={<Tracked />} />
                  )}
                </Route>
              </>
            )}
            {pagesConfiguration.systematicPortfolios.enabled && (
              <Route path="systematic-portfolios">
                <Route index element={<SystematicPortfolios />} />
                <Route path=":id">
                  <Route index element={<SystematicPortfolios />} />
                  <Route path="rebalance" element={<Rebalance />} />
                </Route>
              </Route>
            )}
            {pagesConfiguration.reportsHub.enabled && (
              <Route path="reports-hub">
                <Route index element={<ReportsHub />} />
              </Route>
            )}
            <Route path="pdf">
              <Route index element={<PdfLoader />} />
              <Route path=":type" element={<PdfLoader />}>
                <Route path=":value" element={<PdfLoader />}>
                  <Route path=":collectionId" element={<PdfLoader />}>
                    <Route path=":theme" element={<PdfLoader />}></Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/" element={<div>ABCD HOME</div>} />
            <Route path="not-found" element={<Page404 />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </main>
      </div>
      <div id="app__security-window"></div>
      <Footer />

      <ActionModal />
    </div>
  );
}
