import { useTranslation } from "react-i18next";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { InfoTooltip } from "../../InfoTooltip";

type IndicesWidgetProps = {
  data: {
    indexTableData: any[];
    sectorsTableData: any[];
  };
  accordionHandler: Function;
  accordionState: boolean;
};

const formatterTcr = (cell, formatter) => {
  const object = cell.getData();
  const value = cell.getValue();

  const tcr = [
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: object["rrr"],
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
    '<span class="tTableAlerts-arrow i-arrow-r"></span>',
    formatter.custom("rating", {
      options: {
        hasTrendArrow: false,
        notAvailable: {
          input: 0,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: {
        rateChange: null,
        rateDate: null,
        ratePrev: null,
      },
    }),
  ].join(" ");

  return tcr;
};

const nameFormatter = (cell, formatter) => {
  return formatter.table(cell.getField(), "table", cell.getData()) ?? "";
};

export function IndicesWidget({
  data,
  accordionHandler,
  accordionState,
}: IndicesWidgetProps) {
  const { t } = useTranslation();
  const { indexTableData, sectorsTableData } = data ?? {
    indexTableData: [],
    sectorsTableData: [],
  };
  const sectorsData = deepClone(sectorsTableData);
  const indexData = deepClone(indexTableData);
  const formatter = useFormatter();

  //   -----
  const environment = useEnvironment();

  const securityLink =
    environment.get("configuration")["configuration"]["pages"]["alerts"][
      "securityLink"
    ];

  const onRowClickHandler = (event) => {
    if (securityLink !== "popup_instrument" && event.type === "rowClick") {
      const data = event.value.getData();
      const symbol = data?.symbol ?? null;
      if (symbol) {
        const url = `/app/analysis/instrument/${symbol}/`;
        window.location.href = url;
      }
    }
  };

  return (
    <>
      <div className="tNews-accordionTitle" onClick={() => accordionHandler()}>
        <h2 className="tNews-accordionTitle-title">
          {`Markets ${t("instrument_index_plural")} &
            ${t("instrument_sector_plural")} Indices`}
          <div className="tooltip-wrapper-icon">
            <InfoTooltip
              text={"A snapshot of trend developments in specific indices."}
              callToAction={
                "Bring your portfolio risk management to the next level"
              }
              title={"Markets indices & Sector indices"}
            />
          </div>
        </h2>
        <span
          className={`i-${accordionState ? "down" : "up"} tNews-accordionIcon`}
          data-dojo-attach-point="indexAccordionIcon"
        ></span>
      </div>
      {accordionState && (
        <div
          className="tNewsInstruments--indices--sectors-wrapper"
          style={{
            minHeight: 0,
            minWidth: 0,
            overflow: "hidden",
            height: "100%",
          }}
        >
          <div
            data-dojo-attach-point="nodeIndices"
            style={{
              minHeight: 0,
              minWidth: 0,
              height: "90%",
              width: "40%",
            }}
          >
            <h3 className="table-instruments-label">Markets indices</h3>
            {indexTableData?.length ? (
              // <div style={{ height: "100%" }}>
              <TrendratingTable
                data={indexData}
                autoResize={false}
                eventCallback={onRowClickHandler}
                options={{
                  ajaxSorting: false,
                  movableColumns: false,
                }}
                tooltip={{
                  actions: {
                    info: {
                      enabled: securityLink === "popup_instrument",
                    },
                  },
                }}
                columns={[
                  {
                    title: "Name",
                    field: "name",
                    resizable: false,
                    formatter: (cell) => nameFormatter(cell, formatter),
                  },
                  {
                    field: "rc",
                    title: "TCR",
                    resizable: false,
                    headerHozAlign: "center",
                    formatter: (cell) => formatterTcr(cell, formatter),
                  },
                ]}
              />
            ) : (
              // </div>
              <div
                data-dojo-attach-point="widgetEmptyIndices"
                className="empty-news-data"
              >
                No alert found for indices
              </div>
            )}
          </div>
          <div
            data-dojo-attach-point="nodeSectors"
            style={{
              minHeight: 0,
              minWidth: 0,

              height: "90%",
              width: "40%",
            }}
          >
            <h3 className="table-instruments-label">Sectors indices</h3>
            {sectorsTableData?.length ? (
              // <div style={{ height: "100%" }}>
              <TrendratingTable
                data={sectorsData}
                autoResize={false}
                eventCallback={onRowClickHandler}
                options={{
                  ajaxSorting: false,
                  movableColumns: false,
                  resizableColumns: false,
                }}
                tooltip={{
                  actions: {
                    info: {
                      enabled: securityLink === "popup_instrument",
                    },
                  },
                }}
                columns={[
                  {
                    title: "Name",
                    field: "name",
                    resizable: false,
                    formatter: (cell) => nameFormatter(cell, formatter),
                  },
                  {
                    resizable: false,
                    field: "rc",
                    title: "TCR",
                    headerHozAlign: "center",
                    formatter: (cell) => formatterTcr(cell, formatter),
                  },
                ]}
              />
            ) : (
              // </div>
              <div
                data-dojo-attach-point="widgetEmptySectors"
                className="empty-news-data"
              >
                No alert found for sectors
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
