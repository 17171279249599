import Close from "@mui/icons-material/Close";
import RSearch from "@mui/icons-material/Search";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Instruments } from "../../../../api/compute/Instruments";
import { Peers } from "../../../../api/compute/Peers";
import SecurityChartModal from "../../../../components/SecurityChartModal/SecurityChartModal";
import { TooltipContent } from "../../../../components/SecurityTooltip/SecurityTooltipCore";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../../../hooks/useTaxonomyByType";
import { useActionModal } from "../../utils/useActionModal";
import { widgetsConfiguration } from "../widgetsConfiguration";
import ListItemSecurity from "./ListItemSecurity";
import ModalContent from "./ModalContent";
import { getInstrumentData, getLabel } from "./utils";
import ListItemPeer from "./ListItemPeer";

type Props = {
  disabled?: boolean;
  value?: string;
  hasRecents?: boolean;
  cleanOnSelect?: boolean;
  showInstrumentInfoOnSelect?: boolean;
  placeHolder?: string;
  lookUp?: "PEER" | "SECURITY";
  onSelectInstrument: Function;
  formGrouopStyle?: CSSProperties;
};

const assetClassDropdownItems: { label: string; value: string }[] = [
  { label: "Any", value: "any" },
  { label: "Sectors", value: "Sector" },
  { label: "Stock", value: "Stock" },
  { label: "ETFs", value: "ETF" },
  { label: "Indices", value: "Index" },
  { label: "Currencies", value: "Currency" },
  { label: "Commodities", value: "Commodity" },
];
const instrumentTypeConstraint = [
  "Commodity",
  "Currency",
  "ETF",
  "Index",
  "Sector",
  "Stock",
];

const Search = forwardRef(
  (
    {
      disabled = false,
      value,
      hasRecents = true,
      cleanOnSelect = false,
      showInstrumentInfoOnSelect = true,
      lookUp = "SECURITY",
      placeHolder = "Search ISIN, Ticker, Name",
      onSelectInstrument,
      formGrouopStyle,
    }: Props,
    ref
  ) => {
    const _hasRecents = useMemo(() => {
      if (hasRecents && lookUp === "SECURITY") {
        return true;
      }
      return false;
    }, [hasRecents, lookUp]);
    const { setShowModal, setContent } = useActionModal();
    const [typingTimeout, setTypingTimeout] = useState<any>(null);
    const [inputContent, setInputContent] = useState<string | null>("");
    const environment = useEnvironment();
    const inputRef = useRef<any>(null);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [showPopper, setShowPopper] = useState(false);
    const [recents, setRecents] = useState<any>(null);
    const [showRecent, setShowRecent] = useState(false);
    const [showInstrumentInfo, setShowInstrumentInfo] = useState(false);
    const _itemsPerResult = 10;
    const [dataTotalCount, setDataTotalCount] = useState<any>(null);
    const taxonomyMarket = useMemo(
      () => window.App["taxonomies"]["markets"],
      []
    );
    const taxonomySector = useMemo(
      () => window.App["taxonomies"]["sectors"],
      []
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedInstrumetnData, setSelectedInstrumentData] =
      useState<any>(null);
    const [indexForSelection, setIndexForSelection] = useState<null | number>(
      null
    );
    const [allResults, setAllResults] = useState([]);
    const { taxonomiesMapX } = useTaxonomyByType("stock");
    const [dropdownValue, setDropdownValue] = useState<any>(null);
    const tippyRef = useRef<any>(null);
    //#region - APIS
    const apiInstrument = useMemo(
      () => new Instruments(environment.get("setup")),
      [environment]
    );
    const peersAPI = useMemo(() => {
      return new Peers(environment.get("setup"));
    }, [environment]);

    const instrumentsAPI = useMemo(() => {
      return new Instruments(environment.get("setup"));
    }, [environment]);

    //#endregion

    const textNodeRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setIndexForSelection(null);
    }, [searchResult]);

    //#region - SEARCH
    const search = useCallback(
      (input) => {
        setShowPopper(false);
        setSelectedInstrumentData(null);
        const perPage = window.App.user?.product?.configuration?.search
          ?.filtered
          ? 100
          : 10;
        if (lookUp === "SECURITY") {
          const params: any = {
            constraints: {
              instrumentType: [
                "Commodity",
                "Currency",
                "ETF",
                "Index",
                "Sector",
                "Stock",
              ],
              markets: null,
            },
            hasDataTotalCount: false,
            page: 1,
            perPage: perPage,
            query: input,
          };
          instrumentsAPI
            .searchAndFetch(
              params,
              "security",
              widgetsConfiguration["widgets/search/Result"][
                "properties_security"
              ] as any
            )
            .then(
              (result) => {
                setSearchResult(result.data);
                if (result.dataTotalCount) {
                  setDataTotalCount(result.dataTotalCount);
                }
                setShowPopper(true);
              },
              (error) => {
                setSearchResult(null);
                setShowPopper(true);
                console.error(error.message);
              }
            );
        }

        if (lookUp === "PEER") {
          const params: any = {
            constraints: {
              instrumentType: ["Stock"],
              markets: null,
            },
            hasDataTotalCount: false,
            page: 1,
            perPage: perPage,
            query: input,
          };
          peersAPI.searchAndFetch(params).then(
            (result) => {
              setSearchResult(result.data[0]);
              setDataTotalCount(result.dataTotalCount);
              setShowPopper(true);
            },
            (error) => {
              setSearchResult(null);
              console.error(error.message);
            }
          );
        }
      },

      [instrumentsAPI, lookUp, peersAPI]
    );
    //#endregion
    //#region - RESULT SELECTION
    const selectItem = useCallback(
      async (item) => {
        onSelectInstrument(item);
        if (!cleanOnSelect) {
          setSelectedItem(item);
        }
        if (lookUp === "SECURITY") {
          if (!cleanOnSelect) {
            setInputContent(item.name);
          }
          const instData = await getInstrumentData(apiInstrument, [
            item.symbol,
          ]);
          setSelectedInstrumentData(instData.data[0]);
          setSearchResult(null);
          setShowPopper(true);
        }
        if (lookUp === "PEER") {
          setInputContent(
            getLabel(item, "PEER", taxonomyMarket, taxonomySector)
          );
        }
        if (_hasRecents) {
          setShowRecent(false);
        }
        if (showInstrumentInfoOnSelect && lookUp === "SECURITY") {
          setShowInstrumentInfo(true);
        } else {
          setShowPopper(false);
        }
      },
      [
        onSelectInstrument,
        cleanOnSelect,
        lookUp,
        _hasRecents,
        showInstrumentInfoOnSelect,
        apiInstrument,
        taxonomyMarket,
        taxonomySector,
      ]
    );
    //#endregion

    useEffect(() => {
      if (value != null) {
        switch (lookUp) {
          case "SECURITY":
            apiInstrument
              .fetch({
                properties: [
                  {
                    date: null,
                    property: "name",
                  },
                ],
                type: "security",
                symbols: [value],
              })
              .then((res) => {
                selectItem(res.data[0]);
                // setInputContent(res.data[0].name);
              });
            break;
          case "PEER":
            break;
          default:
            return;
        }
      }
    }, [apiInstrument, lookUp, selectItem, value]);

    //#region - KEYBOARD LISTENER
    const arrowHandler = useCallback(
      (arrowDirection: "ArrowDown" | "ArrowUp") => {
        if (searchResult) {
          if (indexForSelection == null) {
            switch (arrowDirection) {
              case "ArrowDown":
                setIndexForSelection(0);
                return;
              case "ArrowUp":
                setIndexForSelection(searchResult?.length - 1);
                return;
            }
          }
          switch (arrowDirection) {
            case "ArrowUp":
              if (indexForSelection === 0) {
                setIndexForSelection(searchResult?.length - 1);
                return;
              } else {
                setIndexForSelection((prev) => {
                  return prev! - 1;
                });
                return;
              }
            case "ArrowDown":
              if (indexForSelection === searchResult?.length - 1) {
                setIndexForSelection(0);
                return;
              } else {
                setIndexForSelection((prev) => {
                  return prev! + 1;
                });
                return;
              }
          }
        } else {
          if (indexForSelection == null) {
            switch (arrowDirection) {
              case "ArrowDown":
                setIndexForSelection(0);
                return;
              case "ArrowUp":
                setIndexForSelection(recents?.length - 1);
                return;
            }
          }
          switch (arrowDirection) {
            case "ArrowUp":
              if (indexForSelection === 0) {
                setIndexForSelection(recents?.length - 1);
                return;
              } else {
                setIndexForSelection((prev) => {
                  return prev! - 1;
                });
                return;
              }
            case "ArrowDown":
              if (indexForSelection === recents?.length - 1) {
                setIndexForSelection(0);
                return;
              } else {
                setIndexForSelection((prev) => {
                  return prev! + 1;
                });
                return;
              }
          }
        }
      },
      [indexForSelection, recents?.length, searchResult]
    );
    const typingListener = useCallback(
      (e) => {
        if (
          e.ctrlKey ||
          e.shiftKey ||
          e.key === "Shift" ||
          e.key === "Control" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight"
        ) {
          return;
        }
        if (
          e.key === "Enter" &&
          searchResult &&
          searchResult.length === 1 &&
          (searchResult[0].name.toLowerCase() === inputContent?.toLowerCase() ||
            searchResult[0].ticker.toLowerCase() ===
              inputContent?.toLowerCase())
        ) {
          selectItem(searchResult[0]);
          setShowPopper(false);
          return;
        }
        if (e.key === "Enter" && indexForSelection != null) {
          if (searchResult) {
            selectItem(searchResult[indexForSelection]);
            setShowPopper(false);
            return;
          }

          selectItem(recents[indexForSelection]);
          setShowPopper(false);
          return;
        } else if (e.key === "Enter" && indexForSelection == null) {
          return;
        }
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          arrowHandler(e.key);
          return;
        }
        if (e.key === "Escape") {
          setShowPopper(false);
          setSelectedItem(null);
          setSearchResult(null);
          setIndexForSelection(null);
          setSelectedInstrumentData(null);
          setInputContent("");
          setShowInstrumentInfo(false);
          // e.target.blur();
          if (_hasRecents) {
            setShowRecent(false);
          }
          return;
        }
        setSelectedItem(null);
      },
      [
        searchResult,
        inputContent,
        indexForSelection,
        selectItem,
        recents,
        arrowHandler,
        _hasRecents,
      ]
    );
    //#endregion

    const handlingValueChanging = useCallback(
      (input) => {
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
        if (input) {
          setTypingTimeout(
            setTimeout(() => {
              search(input);
            }, 500)
          );
        }
      },
      [search, typingTimeout]
    );

    //#region - getting RECENTS
    const getRecents = useCallback(() => {
      let _recents =
        window.App.user?.preferences?.preferences?.favorites?.data ?? [];
      if (_recents.length > 0) {
        _recents = _recents.map((item) => item.symbol);
        const properties = {
          properties: [
            {
              date: null,
              property: "exchange",
            },
            {
              date: null,
              property: "name",
            },
            {
              date: null,
              property: "ticker",
            },
            {
              date: null,
              property: "type",
            },
            {
              date: null,
              property: "symbol",
            },
          ],
          symbols: _recents,
          type: "security",
          peers: [],
        };
        apiInstrument.fetch(properties as any).then((data) => {
          setRecents(data.data);
        });
      } else {
        setRecents([]);
      }
    }, [apiInstrument]);
    //#endregion

    //#region - RECENT CONTENT
    const recentContent = useMemo(() => {
      return (
        <>
          <Typography>
            <strong>Recents</strong>
          </Typography>
          <Box
            display={"flex"}
            sx={{ cursor: "pointer" }}
            flexDirection={"column"}
          >
            {recents && recents?.length > 0
              ? recents?.map((item, index) => (
                  <ListItemSecurity
                    key={index}
                    index={index}
                    indexForSelection={indexForSelection}
                    item={item}
                    setIndexForSelection={setIndexForSelection}
                    selectItem={selectItem}
                  />
                ))
              : "No security has been accessed yet!"}
          </Box>
        </>
      );
    }, [indexForSelection, recents, selectItem]);
    //#endregion

    const renderAll = useCallback(
      (
        input: string | undefined,
        _pageNumber: number | undefined,
        instrumentType: string[] | undefined,
        selectedMarkets: string[] | undefined
      ) => {
        let _selectedMarkets: any = null;
        if (selectedMarkets && selectedMarkets.length > 0) {
          _selectedMarkets = selectedMarkets;
        }

        if (instrumentType == null) {
          setDropdownValue("any");
        } else {
          setDropdownValue(instrumentType[0]);
        }

        if (_pageNumber !== undefined) {
          setPageNumber(_pageNumber);
        }
        setShowPopper(false);
        setShowModal(true);
        var params: any = {
          constraints: {
            instrumentType: instrumentType ?? instrumentTypeConstraint,
            markets: _selectedMarkets,
          },
          hasDataTotalCount: true,
          page: _pageNumber !== undefined ? _pageNumber : pageNumber,
          perPage: 25,
          query: input ?? inputContent,
        };
        const propertiesSecurity = widgetsConfiguration[
          "widgets/search/ResultAll"
        ]["properties_security"] as any;
        instrumentsAPI
          .searchAndFetch(params, "security", propertiesSecurity)
          .then((result) => {
            setAllResults(result.data);
            setDataTotalCount(result.dataTotalCount);
          });
      },
      [inputContent, instrumentsAPI, pageNumber, setShowModal]
    );

    const onFilterByAssetClass = useCallback(
      (
        assetClassType:
          | "any"
          | "Commodity"
          | "Currency"
          | "ETF"
          | "Index"
          | "Sector"
          | "Stock"
      ) => {
        if (assetClassType !== "any") {
          renderAll(undefined, 1, [assetClassType], undefined);
        } else {
          renderAll(undefined, 1, undefined, undefined);
        }
      },
      [renderAll]
    );
    const onSelectIntrumentFromTable = useCallback(
      async (instrument) => {
        onSelectInstrument(instrument);
        if (showInstrumentInfoOnSelect) {
          setShowModal(false);
          selectItem(instrument);
        } else {
          setSelectedItem(instrument);
          setShowModal(false);
          setInputContent(instrument.name);
        }
      },
      [onSelectInstrument, selectItem, setShowModal, showInstrumentInfoOnSelect]
    );
    const modalContent = useMemo(() => {
      return (
        <ModalContent
          onSelectInstrument={onSelectIntrumentFromTable}
          onFilterByAssetClass={onFilterByAssetClass}
          setShowModal={setShowModal}
          setPageNumber={setPageNumber}
          setInputContent={setInputContent}
          dropdownValue={dropdownValue}
          renderAll={renderAll}
          inputContent={inputContent ?? ""}
          assetClassDropdownItems={assetClassDropdownItems}
          pageNumber={pageNumber}
          dataTotalCount={dataTotalCount}
          taxonomiesMapX={taxonomiesMapX}
          allResults={allResults}
        />
      );
    }, [
      onSelectIntrumentFromTable,
      onFilterByAssetClass,
      setShowModal,
      dropdownValue,
      renderAll,
      inputContent,
      pageNumber,
      dataTotalCount,
      taxonomiesMapX,
      allResults,
    ]);

    //!updating modal's content
    useEffect(() => {
      if (allResults != null && allResults.length > 0) {
        setContent(modalContent);
      }
    }, [allResults, modalContent, setContent, dataTotalCount]);

    //#region - PEER/SECURITY AND PANEL CONTENT
    /*
    ! we have 3 differrent content:
    ! 1- recents
    ! 2- result of search (SECURITY OR PEER)
    ! 3- instrument info when an instrument is selected
  */
    const LookupPEER = useMemo(() => {
      if (lookUp === "PEER") {
        if (!searchResult || searchResult.length <= 0) {
          return (
            <Box>
              No result for <strong>{inputContent}</strong>!
            </Box>
          );
        } else {
          return (
            <Box
              display={"flex"}
              sx={{ cursor: "pointer" }}
              flexDirection={"column"}
            >
              <Box>
                <strong>Markets</strong>
              </Box>

              {searchResult.map((item, index) => {
                const market = item.where
                  ? taxonomyMarket.find((node) => node.item === item.where)
                  : null;
                const sector = item.what
                  ? taxonomySector.find((node) => node.id === item.what)
                  : null;
                let label: any = getLabel(
                  item,
                  "PEER",
                  taxonomyMarket,
                  taxonomySector
                );
                if ((market && !sector) || (!market && sector)) {
                  label = <strong>{label}</strong>;
                }
                return (
                  <ListItemPeer
                    key={uuidv4()}
                    selectItem={() => {
                      selectItem(item);
                    }}
                    indexForSelection={indexForSelection}
                    index={index}
                    label={label}
                    setIndexForSelection={setIndexForSelection}
                  />
                  // <Box
                  //   p={0.5}
                  //   borderRadius={1}
                  //   bgcolor={index === indexForSelection ? "#2a7092" : "white"}
                  // >
                  //   <Typography
                  //     color={index === indexForSelection ? "white" : "black"}
                  //   >
                  //     {label}
                  //   </Typography>
                  // </Box>
                );
              })}
              {dataTotalCount > _itemsPerResult ? (
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography>{`Best ${_itemsPerResult} matches of about ${dataTotalCount}`}</Typography>
                </Box>
              ) : null}
            </Box>
          );
        }
      } else {
        return null;
      }
    }, [
      dataTotalCount,
      indexForSelection,
      inputContent,
      lookUp,
      searchResult,
      selectItem,
      taxonomyMarket,
      taxonomySector,
    ]);
    const LookupSECURITY = useMemo(() => {
      if (lookUp === "SECURITY" && !selectedItem) {
        if (
          /*!searchResult ||*/ searchResult &&
          searchResult.length <= 0 &&
          inputContent?.length &&
          inputContent?.length > 0
        ) {
          return <Typography>No result for "{inputContent}"</Typography>;
        } else if (
          searchResult &&
          inputContent?.length &&
          inputContent?.length > 0
        ) {
          return (
            <Box
              display={"flex"}
              sx={{ cursor: "pointer" }}
              flexDirection={"column"}
            >
              {searchResult.map((item, index) => (
                <ListItemSecurity
                  setIndexForSelection={setIndexForSelection}
                  index={index}
                  indexForSelection={indexForSelection}
                  item={item}
                  selectItem={selectItem}
                />
              ))}
              {dataTotalCount > _itemsPerResult ? (
                <Box
                  pt={1}
                  borderTop={"1px #2A7090 solid"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography>{`Best ${_itemsPerResult} matches of about ${dataTotalCount}`}</Typography>
                  <Button
                    onClick={() =>
                      renderAll(undefined, undefined, undefined, undefined)
                    }
                  >
                    show all {/*results for {inputContent}*/}
                  </Button>
                </Box>
              ) : null}
            </Box>
          );
        }
      } else {
        return null;
      }
    }, [
      lookUp,
      selectedItem,
      searchResult,
      inputContent,
      dataTotalCount,
      indexForSelection,
      selectItem,
      renderAll,
    ]);

    const [showChart, setShowChart] = useState(false);
    const instrumentInfo = useMemo(
      () => (
        <>
          <TooltipContent
            isLoading={false}
            security={selectedInstrumetnData}
            environment={environment}
            showContextMenu={false}
            setShowContextMenu={() => {}}
            chartButtonHandler={() => {
              setShowPopper(false);
              setShowChart(true);
              setSelectedItem(null);
              setInputContent("");
            }}
            tooltipRef={undefined}
            enableAddToAction={true}
            error={false}
          />
        </>
      ),
      [environment, selectedInstrumetnData]
    );
    //#endregion

    useEffect(() => {
      if (showChart) {
        if (tippyRef?.current) {
          tippyRef.current.blur();
        }
      }
    }, [showChart]);

    const clickHandler = useCallback(() => {
      if (!selectedItem) {
        if (inputContent && inputContent?.length > 0) {
          search(inputContent);
          return;
        }
        if (_hasRecents) {
          getRecents();
          setShowRecent(true);
          setShowPopper(true);
        }
      } else {
        setShowRecent(false);
        setSelectedItem(null);
        search(selectedItem.name);
        setShowPopper(true);
      }
    }, [_hasRecents, getRecents, inputContent, search, selectedItem]);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          const inputRefNode = textNodeRef?.current;

          if (inputRefNode) {
            inputRefNode.focus();
          }
        },

        setFromOutSide: (symbol) => {
          switch (lookUp) {
            case "SECURITY":
              apiInstrument
                .fetch({
                  properties: [
                    {
                      date: null,
                      property: "name",
                    },
                  ],
                  type: "security",
                  symbols: [symbol],
                })
                .then(async (res) => {
                  const item = res.data[0];
                  if (!cleanOnSelect) {
                    setSelectedItem(item);
                  }
                  if (lookUp === "SECURITY") {
                    if (!cleanOnSelect) {
                      setInputContent(item.name);
                    }
                    const instData = await getInstrumentData(apiInstrument, [
                      item.symbol,
                    ]);
                    setSelectedInstrumentData(instData.data[0]);
                    setSearchResult(null);
                    setShowPopper(true);
                  } else {
                    setInputContent(
                      getLabel(item, "PEER", taxonomyMarket, taxonomySector)
                    );
                  }
                  if (_hasRecents) {
                    setShowRecent(false);
                  }
                  if (showInstrumentInfoOnSelect && lookUp === "SECURITY") {
                    setShowInstrumentInfo(true);
                  } else {
                    setShowPopper(false);
                  }

                  // setInputContent(res.data[0].name);
                });
              break;
            case "PEER":
              break;
            default:
              return;
          }
        },
      }),
      [
        _hasRecents,
        apiInstrument,
        cleanOnSelect,
        lookUp,
        showInstrumentInfoOnSelect,
        taxonomyMarket,
        taxonomySector,
      ]
    );

    return (
      <>
        <Tippy
          ref={tippyRef}
          onClickOutside={() => {
            setShowPopper(false);
            setSearchResult(null);
            setIndexForSelection(null);
            setSelectedItem(null);
            setInputContent("");
            // setSelectedInstrumentData(null);
            setShowInstrumentInfo(false);
            if (_hasRecents) {
              setShowRecent(false);
            }
          }}
          theme="security-tooltip"
          visible={showPopper}
          allowHTML
          interactive={true}
          maxWidth={"auto"}
          appendTo={document.body}
          content={
            <>
              <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"column"}
                p={1}
              >
                {lookUp === "SECURITY" && !showRecent && LookupSECURITY}
                {lookUp === "PEER" && !showRecent && LookupPEER}
                {selectedItem && showInstrumentInfo && instrumentInfo}
                {showRecent && recentContent}
              </Box>
              <SecurityChartModal
                security={selectedInstrumetnData}
                showModal={showChart}
                environment={environment}
                onClose={() => {
                  setShowChart(false);
                  setSelectedInstrumentData(null);
                }}
              />
            </>
          }
        >
          <TextField
            sx={{ ...formGrouopStyle }}
            disabled={disabled}
            type="text"
            name={lookUp}
            placeholder={placeHolder}
            onFocus={clickHandler}
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            autoFocus={false}
            aria-autocomplete="none"
            inputProps={{
              style: {
                fontSize: "0.7vw",
                height: "100%",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              },
            }}
            ref={inputRef}
            inputRef={textNodeRef}
            value={inputContent}
            onChange={(e) => {
              if (e.target.value === "") {
                setInputContent("");
                setSearchResult(null);
                setSelectedInstrumentData(null);
                setSelectedItem(null);
                if (_hasRecents) {
                  setShowRecent(true);
                }
              } else {
                setInputContent(e.target.value);
                if (_hasRecents) {
                  setShowRecent(false);
                }
                handlingValueChanging(e.target.value);
              }
            }}
            onKeyUp={(e) => {
              e.stopPropagation();
              typingListener(e);
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (!searchResult && !_hasRecents) {
                setShowPopper(false);
              }
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            InputProps={{
              style: { paddingRight: 2, backgroundColor: "white" },
              endAdornment: (
                <InputAdornment position="end" disableTypography>
                  <IconButton
                    onClick={() => {
                      setShowPopper(false);
                      setSelectedItem(null);
                      setSearchResult(null);
                      setIndexForSelection(null);
                      setSelectedInstrumentData(null);
                      setInputContent("");
                      setShowInstrumentInfo(false);
                      onSelectInstrument(null);
                    }}
                    disabled={selectedItem == null}
                    sx={{ m: 0, p: 0 }}
                    title={selectedItem ? "clear search" : "search"}
                  >
                    {selectedItem ? (
                      <Close color="primary" />
                    ) : (
                      <RSearch color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Tippy>
      </>
    );
  }
);

export default Search;
