import { Box } from "@mui/material";
import { useMemo } from "react";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Section } from "../../../../../../trendrating-report/widgets/Section";
import DragAndDropList from "../../../../../../trendrating-report/widgets/form/DragAndDropList";

type Props = {
  columnsAvailable: any;
  wysiwygState: any;
  page: string;
  addField:Function;
  isCustomizing: boolean;
  value: any;
  setter: Function;
};

export default function Preview({
  columnsAvailable,
  wysiwygState,
  page,
  isCustomizing,
  value,
  setter,
  addField
}: Props) {
  const environment = useEnvironment();
  const listContentObjs = useMemo(() => {
    let vv: any = deepClone(value);
    if (vv === undefined || vv == null) {
      vv = [[]];
    }

    const _section = new Section(environment.get("setup"));
    let listContentObjBuilder: any = [];

    for (let i = 0; i < vv[0].length; i++) {
      let obj = _section.getAsWidget(vv[0][i].type);
      listContentObjBuilder.push(obj);
      listContentObjBuilder[i].widgetValue = vv[0][i];
    }

    return listContentObjBuilder;

  }, [environment, value]);

  return (
    <Box overflow={"auto"}>
      <DragAndDropList
        listContentObjBuilder={listContentObjs}
        columnsAvailable={columnsAvailable}
        setter={setter}
        wysiwygState={wysiwygState}
        addField={addField}
        isCustomizing={isCustomizing}
        fullHeightBg={true}
      />
    </Box>
  );
}
