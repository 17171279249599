/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateTablePortfolioPointInTime
 * @summary Requests for table portfolio (Point In Time) templates
 *
 */

import { _TemplateTable } from "./_TemplateTable";

export class TemplateTablePortfolioPointInTime extends _TemplateTable {
    storedObjectType = "TEMPLATE_TABLE_PORTFOLIO_POINT_IN_TIME" as const;
}
