/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/LoginPage
 * @summary Login page
 *
 */

import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CookieSetOptions } from "universal-cookie";
import Modal from "../../../components/Modal/Modal";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { config } from "../../app/config-ts";
import { isValidEmail, isValidPassword, messageError } from "../../app/utils";
import { disclaimer } from "./disclaimer";
import { Box } from "@mui/material";

type LoginPageProps = {
  storage: any;
};

export function LoginPage({ storage }: LoginPageProps) {
  const cookieImpersonate = config.cookies.impersonate;
  const cookieProductId = config.cookies.productId;

  const [, setCookie] = useCookies([
    cookieImpersonate.name,
    cookieProductId.name,
  ]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);
  let [searchParams] = useSearchParams();

  const showDisclaimer = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideDisclaimer = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    if (
      cookieImpersonate == null ||
      cookieProductId == null ||
      storage == null
    ) {
      return;
    }

    function init() {
      // reset impersonate cookie
      setCookie(
        cookieImpersonate.name,
        "login-reset",
        cookieImpersonate.props as CookieSetOptions
      );
      setCookie(
        cookieProductId.name,
        "login-reset",
        cookieProductId.props as CookieSetOptions
      );
    }

    if (/\/impersonate\//.test(window.location.href)) {
      storage.users.logout().then(
        () => init(),
        () => init()
      );
    } else {
      storage.sessions.isValid().then(
        () => (window.location.href = config.routes.app),
        () => init()
      );
    }
  }, [
    cookieImpersonate,
    cookieProductId,
    setCookie,
    storage,
    storage?.sessions,
    storage?.users,
  ]);

  useEffect(() => emailInputRef.current?.focus(), []);

  useEffect(() => {
    if (ready) {
      document.title = `${t("Login")} - Trendrating`;
    }
  }, [t, ready]);

  const handlePasswordRecovery = (event) => {
    event.preventDefault();
    navigate("/password-recovery");
  };

  const handleInputChange = (event: ChangeEvent) => {
    const target: any = event.target!;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "login-email") {
      setEmail(value.trim());
    } else if (name === "login-password") {
      setPassword(value.trim());
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const paramsLogin = {
      email,
      password,
    };
    login(paramsLogin);
  };

  const { broadcast } = useBroadcast();

  const login = ({ email, password }) => {
    if (isValidEmail(email) && isValidPassword(password)) {
      setDisableForm(true);

      const paramsLogin = {
        email: email,
        password: password,
      };

      return storage["users"].login(paramsLogin).then(
        (response) => {
          if (response.group === "LEAD") {
            storage["users"].logout().then(() => {
              navigate(config.routes["/lead/"]);
            });
            return;
          }

          const next = searchParams.get("next");

          let targetUrl = config.routes.app;
          if (next != null) {
            targetUrl = next;
          }

          window.location.href = targetUrl;
        },
        (error) => {
          setDisableForm(false);
          console.log("error status code", error?.response?.status);

          switch (error?.response?.status) {
            case 504:
              {
                const [channel, msg] = messageError(t("Http_error_504"), error);
                broadcast(channel as string, msg);
              }
              break;
            default:
              const [channel, msg] = messageError(
                t("Wrong_email_and_or_password"),
                error
              );
              broadcast(channel as string, msg);
          }
        }
      );
    }

    const [channel, msg] = messageError(t("Wrong_email_and_or_password"));
    broadcast(channel as string, msg);
  };

  return (
    <div className="PageLogin">
      <form
        action="#login"
        className="Form"
        method="post"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <legend>{t("Login_with_your_Trendrating_account")}</legend>
          <ol>
            <li>
              <label htmlFor="login-email">{t("Email")}</label>
              <br />
              <input
                className="Input"
                onChange={handleInputChange}
                id="login-email"
                name="login-email"
                ref={emailInputRef}
                type="email"
                value={email}
              />
            </li>
            <li>
              <label htmlFor="login-password">{t("Password")}</label>
              <br />
              <input
                className="Input"
                onChange={handleInputChange}
                id="login-password"
                name="login-password"
                type="password"
                value={password}
              />
            </li>
            <li className="PageLogin-disclaimer">
              {t("Entering_You_agree_with_the_Trendrating")}{" "}
              <a onClick={showDisclaimer} href="#disclaimer">
                {t("Disclaimer")}
              </a>
              .
            </li>
            <li>
              <button
                className="Button Button--login"
                disabled={disableForm}
                type="submit"
              >
                {disableForm ? t("Logging_in") : t("Login")}
              </button>
            </li>
            <li className="Form-help">
              <a href="#password-recovery" onClick={handlePasswordRecovery}>
                {t("Forgot_password")}
              </a>
              <span className="a11y">|</span>
              <a href={`mailto:${t("mailto")}`}>{t("Need_help")}</a>
            </li>
          </ol>
        </fieldset>
      </form>
      {showModal && (
        <Modal
          headerConfig={{ headerContent: "Trendrating Disclaimer" }}
          onClose={hideDisclaimer}
          closeIcon={true}
        >
          <Box p={1}>
            <span dangerouslySetInnerHTML={{ __html: disclaimer }}></span>
          </Box>
        </Modal>
      )}
    </div>
  );
}
