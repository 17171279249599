import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { deepClone } from "../../../../../../../deepClone";
import { v4 as uuidv4 } from "uuid";

type MarketTCRProps = {
  configObj: any;
};

const DrillDownSettings = forwardRef(({ configObj }: MarketTCRProps, ref) => {
  const [isEnabled, setIsEnabled] = useState(
    configObj.widgetValue.content.headline.isEnabled
  );
  const [content, setContent] = useState(
    configObj.widgetValue.content.headline.content
  );

  const [segment, setSegment] = useState(configObj.widgetValue.content.segment);
  const [timeframe, setTimeframe] = useState(
    configObj.widgetValue.content?.timeframe ?? "today"
  );

  const drilldownOptions = useMemo(
    () => [
      { label: "Sector", value: "1 Industry" },
      { label: "Industry", value: "3 Sector" },
      { label: "Size", value: "3 Level" },
      { label: "Area", value: "Area" },
      { label: "Region", value: "Region" },
      { label: "Market", value: "Country" },
    ],
    []
  );

  const timeframeOpts = useMemo(
    () => [
      { label: "Today", value: "today" },
      { label: "Last 5 days", value: "lastWeek" },
      { label: "Last 20 days", value: "lastMonth" },
    ],
    []
  );

  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.headline.content = content;
      tempState.widgetValue.content.headline.isEnabled = isEnabled;
      tempState.widgetValue.content.segment = segment;
      tempState.widgetValue.content.timeframe = timeframe;
      return tempState;
    },
  }));

  const handleTimeframeChange = useCallback((event) => {
    setTimeframe(event.target.value);
  }, []);

  const handleChange = useCallback((event) => {
    setSegment(event.target.value);
  }, []);

  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
                size="small"
              />
            }
            label={
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography>Headline</Typography>
                <TextField
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  size="small"
                  disabled={!isEnabled}
                />
              </Box>
            }
          />
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 3, marginTop: "16px" }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
            <Typography>Group by</Typography>
            <FormControl size={"small"}>
              <Select
                id="demo-simple-select"
                value={segment}
                onChange={handleChange}
              >
                {drilldownOptions.map((item) => (
                  <MenuItem key={uuidv4()} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
            <Typography>Timeframe</Typography>
            <FormControl size={"small"}>
              <Select
                id="demo-simple-select"
                value={timeframe}
                onChange={handleTimeframeChange}
              >
                {timeframeOpts.map((item) => (
                  <MenuItem key={uuidv4()} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default DrillDownSettings;
