export type TableCustomizerType = {
  headline: null | {
    content: string;
    isEnabled: boolean;
  };
  rate: null | {
    A: boolean;
    B: boolean;
    C: boolean;
    D: boolean;
  };
  sortBy: null | {
    property: string;
    descending: boolean;
  };
  top: null | {
    content: number;
    isEnabled: boolean;
  };
  isEdited: boolean;
  columns: any[];
  rank: boolean;
  sortByRank: boolean | undefined;
  useWysiwyg: boolean;
};

export type ActionType = {
  type:
    | "SET_HEADELINE_CONTENT"
    | "SET_HEADLINE_ISENABLED"
    | "SET_TOP_CONTENT"
    | "SET_TOP_ISENABLED"
    | "SET_RATE"
    | "SET_SORT_BY_RANK"
    | "SET_RANK"
    | "SET_COLUMNS"
    | "SET_USEWYSIWYG"
    | "SET_SORT_BY";
  payload: any;
};

export const TableCustomizerReducer = (draft: TableCustomizerType, action: ActionType) => {
  switch (action.type) {
    case "SET_HEADELINE_CONTENT":
      if (draft.headline) {
        draft.headline.content = action.payload;
      }
      break;
    case "SET_HEADLINE_ISENABLED":
      if (draft.headline) {
        draft.headline.isEnabled = action.payload;
      }
      break;
    case "SET_TOP_CONTENT":
      if (draft.top) {
        draft.top.content = action.payload;
      }
      break;
    case "SET_TOP_ISENABLED":
      if (draft.top) {
        draft.top.isEnabled = action.payload;
      }
      break;
    case "SET_RATE":
      // if (draft.rate) {
      //   draft.rate = action.payload;
      // }
      draft["rate"] = action.payload;

      break;
    case "SET_SORT_BY_RANK":
      if (draft.sortByRank != null) {
        draft.sortByRank = action.payload;
      }
      break;
    case "SET_RANK":
      draft.rank = action.payload;
      break;
    case "SET_COLUMNS":
      if (JSON.stringify(action.payload) !== JSON.stringify(draft.columns)) {
        draft.columns = action.payload;
      }
      break;
    case "SET_USEWYSIWYG":
      if (action.payload === "true") {
        draft.useWysiwyg = true;
      }
      if (action.payload === "false") {
        draft.useWysiwyg = false;
      }
      break;
    case "SET_SORT_BY":
      draft.sortBy = action.payload;
      break;
  }
};
