import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Environment } from "../../Environment";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useResizer } from "../../hooks/useResizer";
import PieBox from "./PieBox";
import { ClusterAnalytics } from "../../api/compute/ClusterAnalytics";

type WhatWherePieProps = {
  holdings: { symbol: string; weight: number }[];
  isTcrMode?: boolean;
  isPieClickable: boolean;
  alignChartOnTop: boolean;
  handlers?: { pieClick?: (event) => any };
  optionsByAssetType?: { enabled: boolean; assetType: string };
  hasHorizontalLayout?: boolean;
  autoResize?: boolean;
  date?: number;
};
type DataGetParams = {
  holdings: WhatWherePieProps["holdings"];
  isTcrMode: boolean;
  what: string;
  where: string;
  environment: Environment;
  date?: number;
};

const SELECTOR_OPTIONS_LIST = {
  Instrument: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Sector",
      value: "1 Industry",
      taxonField: "sector",
    },
    {
      label: "Regions",
      value: "Region",
      taxonField: "country",
    },
    {
      label: "Areas",
      value: "Area",
      taxonField: "country",
    },
    {
      label: "Industry",
      value: "3 Sector",
      taxonField: "industry",
    },
    {
      label: "Type",
      value: "Type",
      taxonField: null,
    },
    {
      label: "Currency",
      value: "Currency",
      taxonField: null,
    },
    {
      label: "Size",
      value: "3 Level",
      taxonField: null,
    },
  ],
  ETF: [
    {
      label: "Inv. Region (ETF)",
      value: "etfgeo",
      taxonField: "etfgeo",
    },
    {
      label: "Theme (ETF)",
      value: "Theme",
      taxonField: "etfclass",
    },
    {
      label: "Asset class (ETF)",
      value: "AssetClass",
      taxonField: "etfclass",
    },
    {
      label: "Specialty (ETF)",
      value: "Specialty",
      taxonField: "etfclass",
    },
  ],
  Stock: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Sector",
      value: "1 Industry",
      taxonField: "sector",
    },
    {
      label: "Regions",
      value: "Region",
      taxonField: "country",
    },
    {
      label: "Areas",
      value: "Area",
      taxonField: "country",
    },
    {
      label: "Industry",
      value: "3 Sector",
      taxonField: "industry",
    },
    {
      label: "Currency",
      value: "Currency",
      taxonField: null,
    },
    {
      label: "Size",
      value: "3 Level",
      taxonField: "SizeClassification",
    },
  ],
  Index: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Regions",
      value: "Region",
      taxonField: "country",
    },
    {
      label: "Areas",
      value: "Area",
      taxonField: "country",
    },
  ],
  Commodity: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Currency",
      value: "Currency",
      taxonField: null,
    },
  ],
  Currency: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Currency",
      value: "Currency",
      taxonField: null,
    },
  ],
  Sector: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Sector",
      value: "1 Industry",
      taxonField: "sector",
    },
    {
      label: "Regions",
      value: "Region",
      taxonField: "country",
    },
    {
      label: "Areas",
      value: "Area",
      taxonField: "country",
    },
    {
      label: "Industry",
      value: "3 Sector",
      taxonField: "industry",
    },
  ],
  All: [
    {
      label: "Markets",
      value: "Country",
      taxonField: "country",
    },
    {
      label: "Sector",
      value: "1 Industry",
      taxonField: "sector",
    },
    {
      label: "Regions",
      value: "Region",
      taxonField: "country",
    },
    {
      label: "Areas",
      value: "Area",
      taxonField: "country",
    },
    {
      label: "Industry",
      value: "3 Sector",
      taxonField: "industry",
    },
    {
      label: "Currency",
      value: "Currency",
      taxonField: null,
    },
    {
      label: "Size",
      value: "3 Level",
      taxonField: "SizeClassification",
    },
    {
      label: "Inv. Region (ETF)",
      value: "etfgeo",
      taxonField: "etfgeo",
    },
    {
      label: "Asset class (ETF)",
      value: "AssetClass",
      taxonField: "etfclass",
    },
    {
      label: "Specialty (ETF)",
      value: "Specialty",
      taxonField: "etfclass",
    },
    {
      label: "Theme (ETF)",
      value: "Theme",
      taxonField: "etfclass",
    },
  ],
};

/**
 * Calls for data
 *
 * @param {array} holdings - Holdings list
 * @param {string} what - What segment
 * @param {object} params.list - Where Segment
 *
 * @returns {Promise} a promise fulfilled with the
 *       handled data of the response
 */
const dataGet = async ({
  holdings,
  what,
  where,
  isTcrMode,
  environment,
  date,
}: DataGetParams) => {
  if (holdings == null || !holdings.length) {
    return { what: [], where: [], positionsType: null };
  }
  const apiCluster = new ClusterAnalytics(environment.get("setup"));
  const analytics = ["weight"];

  if (isTcrMode) {
    analytics.push("TCR");
  }

  let responseWhat = apiCluster
    .createConfiguration()
    .segment(what, true)
    .method("INTERSECTION")
    .analytics(analytics)
    .universeFromPositions(holdings);

  if (date) {
    responseWhat = responseWhat.date(date);
  }

  responseWhat = await responseWhat.fetchAnalytics();

  let responseWhere = apiCluster
    .createConfiguration()
    .segment(where, true)
    .method("INTERSECTION")
    .analytics(analytics)
    .universeFromPositions(holdings);

  if (date) {
    responseWhere = responseWhere.date(date);
  }

  responseWhere = await responseWhere.fetchAnalytics();

  return dataPrepare(
    {
      what: responseWhat,
      where: responseWhere,
      positions: holdings,
    },
    isTcrMode,
    [what, where]
  );
};

const dataPrepare = async (rawData, isTcrMode, fields) => {
  const fieldMap = {
    "1 Industry": "what",
    "3 Sector": "what",
    "4 Subsector": "what",
    etfclass: "what",
    specialty: "what",
    etfgeo: "where",
    Country: "where",
    Area: "where",
    Region: "where",
    WWW: "where",
    ICB: "where",
  };
  const [yDim, xDim] = fields;

  var peerWeights = rawData;
  var data = {
    what: dataPrepareHelper(
      peerWeights["what"],
      fieldMap[yDim] ?? "what",
      yDim,
      isTcrMode
    ),
    where: dataPrepareHelper(
      peerWeights["where"],
      fieldMap[xDim],
      xDim,
      isTcrMode
    ),
    positionsType: null,
  };

  return data;
};

const dataPrepareHelper = (rawData, dataType, field, isTcrMode) => {
  var data: any = [];
  let weight: any = null;

  for (const [key, value] of Object.entries<any>(
    rawData["clustersStats"]["stats"]
  )) {
    weight = parseFloat(value.weight.toFixed(4));

    data.push({
      id: key,
      rate: value["TCR"],
      type: dataType,
      field,
      weight,
      constituents: rawData["clustersStats"]["clusters"][key],
    });
  }

  dataPrepareSort(data, isTcrMode);

  return data;
};

const dataPrepareSort = (data, isTcrMode) => {
  if (isTcrMode === true) {
    // sort by rate (D->A), weight (10 -> 0)
    data.sort(function (a, b) {
      var sortByL1 = "rate";
      var sortByL2 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return 1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return -1;
      }

      if (a[sortByL2] > b[sortByL2]) {
        return -1;
      }

      if (a[sortByL2] < b[sortByL2]) {
        return 1;
      }

      return 0;
    });
  } else {
    data.sort(function (a, b) {
      // sort by weight weight (10 -> 0)
      var sortByL1 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return -1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return 1;
      }

      return 0;
    });
  }
};

export function WhatWherePie({
  holdings,
  isTcrMode = true,
  isPieClickable,
  alignChartOnTop = true,
  handlers,
  optionsByAssetType,
  hasHorizontalLayout = true,
  autoResize = false,
  date,
}: WhatWherePieProps) {
  const environment = useEnvironment();
  const [data, setData] = useState<{ what: any; where: any }>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [what, setWhat] = useState<any>();
  const [where, setWhere] = useState<any>();
  const [whereOpts, setWhereOpts] = useState<any>();
  const [whatOpts, setWhatOpts] = useState<any>();
  const nodePieRef = useRef<HTMLDivElement>(null);
  const [trigger, setTrigger] = useState({});

  useEffect(() => {
    return () => {
      setData(undefined);
      setError(false);
      setIsLoading(false);
    };
  }, []);

  useResizer({
    ref: nodePieRef,
    isEnable: autoResize,
    trigger: trigger,
  });

  const handleOptionsList = useCallback(() => {
    if (optionsByAssetType != null && optionsByAssetType.enabled) {
      if (optionsByAssetType.assetType != null) {
        const options = SELECTOR_OPTIONS_LIST;
        const type = optionsByAssetType.assetType;
        setWhereOpts(options[type]);
        setWhatOpts(options[type]);

        setWhere(options[type][0]);
        setWhat(options[type][1]);
      }
    } else {
      setWhereOpts(SELECTOR_OPTIONS_LIST["All"]);
      setWhatOpts(SELECTOR_OPTIONS_LIST["All"]);

      setWhere(SELECTOR_OPTIONS_LIST["All"][0]);
      setWhat(SELECTOR_OPTIONS_LIST["All"][1]);
    }
  }, [optionsByAssetType]);

  useEffect(() => {
    handleOptionsList();
  }, [handleOptionsList]);

  useEffect(() => {
    const dataRetriving = async () => {
      setIsLoading(true);
      const params = {
        holdings,
        where: where.value,
        what: what.value,
        isTcrMode,
        environment,
        date,
      };
      try {
        const data = await dataGet(params);
        setData({ what: data?.what, where: data?.where });
        setIsLoading(false);
        if (autoResize) {
          setTrigger({});
        }
      } catch (error) {
        setIsLoading(false);
        setError(true);
        console.log(error);
      }
    };
    if (where && what) {
      setError(false);
      dataRetriving();
    }
  }, [autoResize, date, environment, holdings, isTcrMode, what, where]);

  return isLoading ? (
    <Box
      display={"flex"}
      flex={1}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress sx={{ color: "#2a7090" }} />
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  ) : error ? (
    <p>Something gone wrong during loading. Please retry later</p>
  ) : (
    <>
      <div
        ref={nodePieRef}
        className={`tWhatWherePie ${
          hasHorizontalLayout ? "tWhatWherePie--horizontal" : ""
        }`}
      >
        <PieBox
          isTcrMode={isTcrMode}
          value={where}
          setValue={setWhere}
          options={whereOpts ?? []}
          data={data?.where ?? null}
          isPieClickable={isPieClickable}
          alignChartOnTop={alignChartOnTop}
          onPieClick={handlers?.pieClick}
        />
        <PieBox
          isTcrMode={isTcrMode}
          value={what}
          setValue={setWhat}
          options={whatOpts ?? []}
          data={data?.what ?? null}
          isPieClickable={isPieClickable}
          alignChartOnTop={alignChartOnTop}
          onPieClick={handlers?.pieClick}
        />
      </div>
    </>
  );
}
