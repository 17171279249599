import { Menu } from "@mui/material";
import { useCallback, useState } from "react";
import SecurityChartModal from "../../../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltip from "../../../../../../../../components/SecurityTooltip/SecurityTooltip";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import styles from "./PerformerBox.module.scss";

type PerformerBoxProps = {
  data: any;
  itemsToDisplay: number;
  timeframe: string;
};

type PerformerBoxListItemProps = {
  rowData: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type PerformanceBarProps = { value: any; valueRescaled: any };

type ShowMoreButtonProps = {
  data: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type OverlayListProps = {
  data: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

type PerformerBoxListProps = {
  data: any;
  timeframe: string;
  readSecurity: (value) => void;
  setShowModal: (value) => void;
};

// !Props passed throw components are repetitive a better work can be done by using Context

export default function PerformerBoxTable({
  data,
  itemsToDisplay,
  timeframe,
}: PerformerBoxProps) {
  const environment = useEnvironment();
  const [showModal, setShowModal] = useState(false);
  const [securityFocus, setSecurityFocus] = useState<any>();
  const length = Math.min(data?.length, itemsToDisplay ?? 0);
  const rowToDisplay =
    length !== data.length
      ? data?.filter((row) => data.indexOf(row) < length)
      : data;
  const moreData =
    length !== data.length
      ? data?.filter((row) => data.indexOf(row) > length - 1)
      : [];

  const onCloseHandler = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <SecurityChartModal
        environment={environment}
        security={securityFocus}
        showModal={showModal}
        onClose={onCloseHandler}
      />
      <div className={styles["tAnalysisOverview-performerBox"]}>
        <PerformerBoxList
          data={rowToDisplay}
          timeframe={timeframe}
          readSecurity={setSecurityFocus}
          setShowModal={setShowModal}
        />
        {moreData.length !== 0 && (
          <ShowMoreButton
            timeframe={timeframe}
            data={moreData}
            readSecurity={setSecurityFocus}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </>
  );
}

const PerformerBoxList = ({
  timeframe,
  data,
  readSecurity,
  setShowModal,
}: PerformerBoxListProps) => {
  return (
    <ul>
      {data.map((row, index) => (
        <PerformerBoxListItem
          setShowModal={setShowModal}
          readSecurity={readSecurity}
          timeframe={timeframe}
          rowData={row}
          key={`${row?.["ticker"] ?? index}__${row?.["name"] ?? index}`}
        />
      ))}
    </ul>
  );
};

const PerformerBoxListItem = ({
  rowData,
  timeframe,
  readSecurity,
  setShowModal,
}: PerformerBoxListItemProps) => {
  const formatter = useFormatter();
  const [isTooltipIconVisible, setIsTooltipIconVisible] = useState(false);

  const onClickChartButton = () => {
    setShowModal(true);
    setIsTooltipIconVisible(false);
  };

  return (
    <li
      className={styles["tAnalysisOverview-performerBoxRow"]}
      title={rowData?.["name"]}
    >
      <div className={styles["tAnalysisOverview-performerBoxLabel"]}>
        <div
          onMouseEnter={() => setIsTooltipIconVisible(true)}
          onMouseLeave={() => setIsTooltipIconVisible(false)}
          className={styles["tAnalysisOverview-performerBoxLabelValue"]}
        >
          <strong style={{ marginRight: 5 }}>{rowData?.["ticker"]}</strong>
          {rowData?.["name"]}{" "}
          <SecurityTooltip
            symbol={rowData?.["symbol"] ?? ""}
            chartButtonHandler={onClickChartButton}
            setSecurity={readSecurity}
          >
            <span
              style={
                isTooltipIconVisible
                  ? { cursor: "pointer" }
                  : { visibility: "hidden" }
              }
              className="i-info"
            ></span>
          </SecurityTooltip>
        </div>
      </div>
      <div className={styles["tAnalysisOverview-performerBoxValue"]}>
        <div
          dangerouslySetInnerHTML={{
            __html: formatter.custom("number", {
              options: {
                colored: "positive",
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: rowData[timeframe],
              valueHelper: null,
            }),
          }}
        ></div>
        <div className={styles["tAnalysisOverview-performerBoxBar"]}>
          <PerformanceBar
            value={rowData[timeframe]}
            valueRescaled={rowData["_" + timeframe]}
          />
        </div>
      </div>
    </li>
  );
};

const PerformanceBar = ({ value, valueRescaled }: PerformanceBarProps) => {
  return (
    <div className="percentage-bar">
      <span className="percentage-bar__negative">
        {valueRescaled < 0 && (
          <span
            className={`percentage-bar__value percentage-bar__value--negative ${
              value < -1 ? "percentage-bar__value--outlierNegative" : ""
            }`}
            style={{ width: `${Math.abs(valueRescaled)}%` }}
          ></span>
        )}
      </span>
      <span className="percentage-bar__positive">
        {valueRescaled > 0 && (
          <span
            className={`percentage-bar__value percentage-bar__value--positive ${
              value > 1 ? "percentage-bar__value--outlierPositive" : ""
            }`}
            style={{ width: `${Math.abs(valueRescaled)}%` }}
          ></span>
        )}
      </span>
    </div>
  );
};

const ShowMoreButton = ({
  data,
  timeframe,
  readSecurity,
  setShowModal,
}: ShowMoreButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        style={{
          cursor: "pointer",
          alignSelf: "flex-end",
          marginLeft: "100%",
          transform: "translateX(-100%)",
        }}
        className="tAnalysisOverview-performerBoxButtonMore"
        onClick={handleClick}
      >
        <span className="i-down"></span>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <OverlayList
          readSecurity={readSecurity}
          setShowModal={setShowModal}
          data={data}
          timeframe={timeframe}
        />
      </Menu>
    </>
  );
};

const OverlayList = ({
  data,
  timeframe,
  readSecurity,
  setShowModal,
}: OverlayListProps) => {
  return (
    <div className="overlay">
      <PerformerBoxList
        data={data}
        timeframe={timeframe}
        readSecurity={readSecurity}
        setShowModal={setShowModal}
      />
    </div>
  );
};
