import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Report } from "../../../../../api/report/Report";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { deepClone } from "../../../../../deepClone";
import { UtilsMath } from "../../../../../trendrating/core/UtilsMath";
import { AppEnvironment } from "../../../../../types/Defaults";
import { messageError, removeLoader } from "../../../utils";
import { themeBlueAndGrey } from "../../../widgets/dialog/report/themeBlueAndGrey-ts";
import styles from "./PerformanceRise.module.scss";
import { ColumnComponent } from "tabulator-tables";
import {
  Dashboards,
  Insights,
  InsightsContext,
  InsightsState,
} from "../../../pages/strategies/builder/editors/Advanced/Result/tabs/Insights/Insights";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useAnalytics } from "../../../../../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import { Lists } from "../../../../../api/compute/Lists";
import { Strategies } from "../../../../../api/compute/Strategies";
import { Instruments } from "../../../../../api/compute/Instruments";
import {
  LoaderContext,
  STRATEGIES_LOADER_EVENTS,
} from "../../../pages/strategies/builder/StrategiesBuilder";
import { LoadingCallbacks } from "../../../storage/CombinedStrategiesStorage";
import { TDate } from "../../../../../trendrating/date/TDate";
import { Entity } from "../../../../../api/compute/Analytics/Analytics";
import { config } from "../../../config-ts";
import { escapeEntity } from "../../../../trendrating-report/generator/Generator";
import Search from "../../../widgets/ReactSearch/Search";
import { Loader } from "../../../pages/strategies/builder/Loader";
import axios from "axios";
import { useBroadcast } from "../../../../../hooks/useBroadcast";

type Sorters = {
  performances?: {
    field: 0 | 1 | 2 | 3;
    direction: "asc" | "desc";
  };
  differences?: {
    field: 0 | 1 | 2 | 3;
    direction: "asc" | "desc";
  };
  holdings?: {
    field: 0 | 1 | 2 | 3 | 4;
    direction: "asc" | "desc";
  };
};

type Holdings = {
  name: string;
  ticker: string;
  pq: number;
  ps: number;
  py: number;
}[];

type ResultsProps = {
  strategyAnalytics: any;
  formatters: any;
  activePortfolio?: any;
  showHistogram: boolean;
  portfolioHoldings?: Holdings;
  loadingPortfolioHoldings: boolean;
  currentTab: "portfolio" | "basket";
  isStrategySelected: boolean;
  onTableSortChange: (table, value) => void;
};

type ListItem = {
  id: number;
  name: string;
  isReadOnly: boolean;
  level?: "one" | "two" | "three";
  universe?: "US" | "EU" | "R_D_P";
};

type StrategySelectorProps = {
  userStrategies: ListItem[];
  // insightsStrategies: ListItem[];
  dashboards: Dashboards;
  loading: boolean;
  onSelectStrategy: (value) => void;
};

const sortByName = (a, b) => {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }

  return 0;
};

const sortTableRows = (a, b, propertyIdx, direction) => {
  const aValue = !isNaN(parseFloat(a[propertyIdx].value))
    ? parseFloat(a[propertyIdx].value)
    : a[propertyIdx].value;
  const bValue = !isNaN(parseFloat(b[propertyIdx].value))
    ? parseFloat(b[propertyIdx].value)
    : b[propertyIdx].value;

  if (aValue > bValue) {
    return direction === "desc" ? 1 : -1;
  } else if (aValue < bValue) {
    return direction === "desc" ? -1 : 1;
  }

  return 0;
};

const columnsFieldsStrategyTables = {
  threeMonths: "threeMonthsPerfAvg",
  sixMonths: "sixMonthsPerfAvg",
  oneYear: "oneYearPerfAvg",
};

const portfolioAnalyticsDict = {
  quarterPerfAvg: "pq#avg",
  sixMonthsPerfAvg: "ps#avg",
  yearlyPerfAvg: "py#avg",
  positionsToday: "positionsToday",
};

export function PerformanceRise() {
  const [tab, setTab] = useState<"portfolio" | "basket">("portfolio");
  const [collection, setCollection] = useState([]);
  const [loadingLists, setLoadingLists] = useState(true);
  const [showStrategies, setShowStrategies] = useState(false);
  const [userStrategies, setUserStrategies] = useState<ListItem[]>([]);
  // const [insightsStrategies, setInsightsStrategies] = useState<ListItem[]>([]);
  const [dashboards, setDashboards] = useState<Dashboards>({
    V1: undefined,
    V2: undefined,
    catalog: undefined,
    status: "loading",
  });
  const [selectedStrategy, setSelectedStrategy] = useState<string>("load");
  const [selectedStrategyObj, setSelectedStrategyObj] = useState<any>();
  const [loadingStrategies, setLoadingStrategies] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [analyticsResults, setAnalyticsResults] = useState<any>();
  const [activePortfolio, setActivePortfolio] = useState<any>();
  const [activePortfolioHoldings, setActivePortfolioHoldings] = useState<
    Holdings | undefined
  >();
  const [loadingHoldings, setLoadingHoldings] = useState(false);
  const [tablePerformaceFormatter, setTablePerformaceFormatter] = useState<
    "PERCENTAGE" | "HISTOGRAM"
  >("PERCENTAGE");
  const [benchmarkWgetKey, setBenchmarkWgetKey] = useState(Date.now());
  const [benchmark, setBenchmark] = useState<any>();

  const environment = useEnvironment();
  const formatter = useFormatter();
  const { get, init, isReady, changeEntity, clearInstance } = useAnalytics();
  const navigate = useNavigate();
  const { on, remove, dispatch } = useEventBus();

  const sorters = useRef<Sorters>({
    performances: undefined,
    differences: undefined,
    holdings: undefined,
  });

  const searchRef = useRef<any>(null);

  const setup = useMemo(() => environment.get("setup"), [environment]);

  const listsAPI = useMemo(() => new Lists(setup), [setup]);

  const { broadcast } = useBroadcast();

  const strategiesAPI = useMemo(() => {
    return new Strategies(setup);
  }, [setup]);

  const instrumentsAPI = useMemo(() => {
    return new Instruments(setup);
  }, [setup]);

  const isBenchmakSelected = useMemo(() => benchmark != null, [benchmark]);

  const isReportEmpty = useMemo(
    () => activePortfolio == null && analyticsResults == null,
    [activePortfolio, analyticsResults]
  );

  // const selectStrategyOptions = useMemo(
  //   () => [...userStrategies, ...insightsStrategies],
  //   [insightsStrategies, userStrategies]
  // );
  const selectStrategyOptions = useMemo(() => {
    const insightsStrategies = dashboards.V1;

    if (insightsStrategies) {
      const insightsWrappedStrategies: ListItem[] = insightsStrategies.map(
        (strategy) => ({
          id: strategy.info.id,
          name: strategy.info.label.join("</br>"),
          isReadOnly: true,
          level: strategy.info.levels as any,
        }),
        []
      );
      return [...userStrategies, ...insightsWrappedStrategies];
    }

    return [...userStrategies];
  }, [dashboards.V1, userStrategies]);

  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
    }),
    [dispatch]
  );

  const log = useCallback((action, params?) => {
    const usage = window.App.usage;

    var info = {
      action,
      actionParam: params ?? null,
      function: "PERFORMANCE_RISE",
    };
    usage.record(info);
  }, []);

  const getCurrentSorters = useCallback(() => {
    return sorters.current;
  }, []);

  const updateSorters = useCallback(
    (
      table: "performances" | "differences" | "holdings",
      sorter:
        | Sorters["performances"]
        | Sorters["differences"]
        | Sorters["holdings"]
    ) => {
      const currentSorters: any = getCurrentSorters();

      if (table in currentSorters) {
        currentSorters[table] = sorter;
      } else {
        currentSorters[table] = { ...sorter };
      }
    },
    [getCurrentSorters]
  );

  const formatPercentage = useCallback(
    (value) =>
      formatter.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      }),
    [formatter]
  );

  const showHistogram = useMemo(() => {
    return tablePerformaceFormatter === "HISTOGRAM";
  }, [tablePerformaceFormatter]);

  const formatters = useMemo(() => {
    return {
      bar: (cell) => {
        const data = cell.getData();
        const cellField = cell.getField();
        const cellValue = cell.getValue();

        const tresholdField = {
          [columnsFieldsStrategyTables["oneMonth"]]: "normalizedOneMonth",
          [columnsFieldsStrategyTables["threeMonths"]]: "normalizedThreeMonths",
          [columnsFieldsStrategyTables["sixMonths"]]: "normalizedSixMonths",
          [columnsFieldsStrategyTables["oneYear"]]: "normalizedOneYear",
        };

        const tresholdValue = data[tresholdField[cellField]];

        return formatter.custom("bar", {
          options: {
            hasPositiveSign: true,
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
            width: "10em!important",
          },
          output: "HTML",
          value: cellValue,
          valueHelper: {
            normalizationThreshold: tresholdValue,
          },
        });
      },
      percentageAligned: (cell) => {
        const html = formatPercentage(cell.getValue());

        return `<div style="margin-right: 11em">${html}</div>`;
      },
      percentage: (cell) => formatPercentage(cell.getValue()),
      name: (cell) => {
        const data = cell.getData();

        let id = data?.["id"] ?? null;
        if (id) {
          let selectedId: string | number | null = activePortfolio?.id ?? null;
          if (selectedId != null) {
            if (id === selectedId) {
              const row = cell.getRow();
              row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
            }
          }
        }

        if (data["isReadOnly"]) {
          return `<span class="sharedObjectIndicator sharedObjectIndicator--small"></span> ${data["name"]}`;
        }

        return data["name"];
      },
    };
  }, [activePortfolio?.id, formatPercentage, formatter]);

  const collectionTableColumns = useMemo(() => {
    return [
      {
        title: "",
        resizable: false,
        columns: [
          {
            title: "Name",
            field: "name",
            formatter: formatters.name,
            widthGrow: 2,
            resizable: false,
          },
        ],
      },
      {
        title: "Average Performance",
        resizable: false,
        columns: [
          {
            title: "3 Months",
            field: portfolioAnalyticsDict["quarterPerfAvg"],
            formatter: formatters.percentage,
            resizable: false,
          },
          {
            title: "6 Months",
            field: portfolioAnalyticsDict["sixMonthsPerfAvg"],
            formatter: formatters.percentage,
            resizable: false,
          },
          {
            title: "12 Months",
            field: portfolioAnalyticsDict["yearlyPerfAvg"],
            formatter: formatters.percentage,
            resizable: false,
          },
        ],
      },
    ];
  }, [formatters.name, formatters.percentage]);

  const progressBarInit = useCallback(
    (loaderFn: LoadingCallbacks, strategyName: string, isBenchmark = false) => {
      if (!loaderFn) {
        console.warn(
          "No loading behaviour is specified so the app not crash but cannot show the progress bar"
        );

        return;
      }

      let eventSourceId =
        strategiesAPI.generateBusId() + "_" + Math.floor(Math.random() * 1000);

      if (isBenchmark) {
        eventSourceId += "-2";
      }

      setTimeout(() =>
        strategiesAPI.status(eventSourceId, (chunk) => {
          if ("status" in chunk && chunk.status === "start") {
            loaderFn.startNew({
              min: chunk.d,
              max: chunk.de,
              point: chunk.d,
              id: eventSourceId,
              name: strategyName,
            });
          } else if (!("status" in chunk)) {
            loaderFn.update(eventSourceId, chunk.d);
          } else if ("status" in chunk && chunk.status === "stop") {
            loaderFn.complete(eventSourceId);
          }
        })
      );

      return eventSourceId;
    },
    [strategiesAPI]
  );

  const getDashboard = useCallback(async (url) => {
    try {
      return await axios.get(url);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getInisghtsV1 = useCallback(async () => {
    const endpointRoot = `${window.location.origin}/`;
    const url = endpointRoot + "static-assets/dashboard/main_insights.json";

    return getDashboard(url);
  }, [getDashboard]);

  const getInsightsV2 = useCallback(async () => {
    const dashboard_V2 = "insights_strategies_analytics.json";
    const endpointRoot = `${window.location.origin}/`;
    const url = endpointRoot + `static-assets/dashboard/${dashboard_V2}`;

    return getDashboard(url);
  }, [getDashboard]);

  const getInsightsDashboards = useCallback(async () => {
    const requestV1 = getInisghtsV1();
    const requestV2 = getInsightsV2();

    try {
      setDashboards((current) => {
        return { ...current, status: "loading" };
      });
      const [dashboardV1, dashboardV2]: any = await Promise.all([
        requestV1,
        requestV2,
      ]);
      setDashboards({
        V1: dashboardV1?.data,
        V2: dashboardV2?.data,
        catalog: undefined,
        status: "success",
      });
    } catch (error) {
      console.log(error);
      setDashboards((current) => {
        return { ...current, status: "error" };
      });
    }
  }, [getInisghtsV1, getInsightsV2]);

  const createPortfolio = useCallback(() => {
    navigate("/app/performanceRise/create/portfolio/");
  }, [navigate]);

  const createBasket = useCallback(() => {
    navigate("/app/performanceRise/create/basket/");
  }, [navigate]);

  const editPortfolio = useCallback(
    (portfolioId) => {
      navigate(`/app/performanceRise/${portfolioId}/edit`);
    },
    [navigate]
  );

  // const getName = useCallback((strategy) => {
  //   return strategy.info.label.join("</br>");
  // }, []);
  // const formatInsightsStrategiesName = useCallback(
  //   (strategy) => {
  //     const name = getName(strategy);
  //     return name;
  //   },
  //   [getName]
  // );
  const getStrategies = useCallback(async () => {
    setLoadingStrategies(true);

    try {
      const dashboardPromise = getInsightsDashboards();
      const strategiesPromise = strategiesAPI.getList(["object.entity_type"]);

      const response = await Promise.all([strategiesPromise, dashboardPromise]);

      const userStrategiesResponse: any = response[0];
      // const dashboardResponse = response[1];
      if (userStrategiesResponse) {
        const userStrategies = userStrategiesResponse
          .filter((strategy) => strategy.entity_type === "BUILDER")
          .sort(sortByName);

        setUserStrategies(userStrategies);
      }

      // if (dashboardResponse && dashboardResponse.data) {
      //   const insightsStrategies: any = [];
      //   for (const strategy of dashboardResponse.data) {
      //     insightsStrategies.push({
      //       name: formatInsightsStrategiesName(strategy),
      //       id: strategy.info.id,
      //       isReadOnly: true,
      //       universe: strategy.info.market,
      //       level: strategy.info.levels,
      //     });
      //   }
      //   insightsStrategies.sort(sortByName);
      //   setInsightsStrategies(insightsStrategies);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingStrategies(false);
    }
  }, [getInsightsDashboards, strategiesAPI]);

  const getUserLists = useCallback(async () => {
    setLoadingLists(true);
    try {
      const collections = await listsAPI.newGet(
        [
          portfolioAnalyticsDict["quarterPerfAvg"],
          portfolioAnalyticsDict["sixMonthsPerfAvg"],
          portfolioAnalyticsDict["yearlyPerfAvg"],
        ],
        tab
      );

      collections.sort(sortByName);

      setCollection(collections);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLists(false);
    }
  }, [listsAPI, tab]);

  const closeStrategyPanel = useCallback(() => {
    setShowStrategies(false);
  }, []);

  const openStrategyPanel = useCallback(() => {
    // toggle panel opening
    if (showStrategies === false) {
      setShowStrategies(true);
      getStrategies();
    } else {
      closeStrategyPanel();
    }
  }, [closeStrategyPanel, getStrategies, showStrategies]);

  const getDateFromPeriod = useCallback((yearsBack) => {
    const today = TDate.today();
    const yearsInDays = yearsBack * 260;
    const offset = 20;

    const daysBack = today - yearsInDays - offset;
    const date = TDate.dateToIso8601(TDate.daysToDate(daysBack));

    return date;
  }, []);

  const getSrategyPriceIndexParams = useCallback(
    (backtestParams, strategy) => {
      let startDate: any = undefined;

      if (
        "includeFromDay" in backtestParams.backtesting &&
        backtestParams.backtesting.includeFromDay != null
      ) {
        startDate = backtestParams.backtesting.includeFromDay;
      } else {
        startDate = getDateFromPeriod(backtestParams.backtesting.yearsBack!);
      }

      const strategyCurrency = strategy?.params?.strategy?.currency ?? "local";
      const rebalance = strategy?.params?.strategy?.rebalance ?? "20_DAYS";
      const granularity_dict = {
        "05_DAYS": "WEEKLY",
        "20_DAYS": "MONTHLY",
        "60_DAYS": "QUARTERLY",
      };
      const granularity = granularity_dict[rebalance];

      return { startDate, strategyCurrency, granularity };
    },
    [getDateFromPeriod]
  );

  const prepareBenchmarkEntity = useCallback(
    (strategy) => {
      const benchmarkTag = strategy.params.strategy.benchmark;

      if (benchmarkTag) {
        const backtestParams = strategiesAPI.prepareParamsForRun(
          strategy.params,
          undefined
        );

        const { startDate, granularity, strategyCurrency } =
          getSrategyPriceIndexParams(backtestParams, strategy);

        return {
          type: "INSTRUMENT",
          entity: {
            symbol: benchmarkTag,
            params: {
              inceptionDay: backtestParams.pricing.inceptionDay,
              inceptionValue: backtestParams.pricing.inceptionValue,
              method: backtestParams.pricing.method,
              spanGranularity: granularity,
              currency: strategyCurrency,
            },
          },
          includeFromDay: startDate,
        };
      }
    },
    [getSrategyPriceIndexParams, strategiesAPI]
  );

  const wrapAnalyticsResponse = useCallback((response) => {
    const analyticsDict = {
      H: {
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,H",
        avgPerfSixMonths: "r#null,null,FULL,MONTHLY,6,SOLAR,H",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,H",
      },
      B: {
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,B",
        avgPerfSixMonths: "r#null,null,FULL,MONTHLY,6,SOLAR,B",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,B",
      },
      D: {
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,DIFF",
        avgPerfSixMonths: "r#null,null,FULL,MONTHLY,6,SOLAR,DIFF",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,DIFF",
      },
    };

    if (response && response.data) {
      const result = {};

      for (const key in analyticsDict) {
        result[key] = {};

        for (const analyticKey in analyticsDict[key]) {
          result[key][analyticKey] =
            response.data?.[analyticsDict?.[key]?.[analyticKey]] != null
              ? Object.values(
                  response.data?.[analyticsDict?.[key]?.[analyticKey]]
                )?.[0]
              : undefined;
        }
      }

      return result;
    }
  }, []);

  const getAnalytics = useCallback(
    async (hasBenchmark) => {
      const analyticsMap = {
        H: [
          "r#null,null,FULL,MONTHLY,3,SOLAR,H",
          "r#null,null,FULL,MONTHLY,6,SOLAR,H",
          "r#null,null,FULL,YEARLY,1,SOLAR,H",
        ],
        B: [
          "r#null,null,FULL,MONTHLY,3,SOLAR,B",
          "r#null,null,FULL,MONTHLY,6,SOLAR,B",
          "r#null,null,FULL,YEARLY,1,SOLAR,B",
        ],
        D: [
          "r#null,null,FULL,MONTHLY,3,SOLAR,DIFF",
          "r#null,null,FULL,MONTHLY,6,SOLAR,DIFF",
          "r#null,null,FULL,YEARLY,1,SOLAR,DIFF",
        ],
      };

      const fields = [...analyticsMap["H"]];

      if (hasBenchmark) {
        fields.push(...analyticsMap["B"], ...analyticsMap["D"]);
      }

      const analytics = await get(fields);

      setAnalyticsResults(wrapAnalyticsResponse(analytics));
    },
    [get, wrapAnalyticsResponse]
  );

  const updateBenchmarkWidget = useCallback((value) => {
    if (searchRef.current) {
      searchRef.current.setFromOutSide(value);
    }
  }, []);

  const run = useCallback(
    async (strategyId) => {
      let currentStrategyId: any = null;

      setSelectedStrategy((prev) => {
        currentStrategyId = !isNaN(parseInt(prev)) ? parseInt(prev) : null;

        return strategyId;
      });
      setLoadingAnalytics(true);

      try {
        const strategy = await strategiesAPI.getById(strategyId);
        setSelectedStrategyObj(strategy);

        if (strategy) {
          let hasBenchmark = false;
          const hasToRefresh = strategyId !== currentStrategyId;

          if (hasToRefresh) {
            const eventSourceId = progressBarInit(
              loadingBehaviours,
              strategy.name
            );

            if (eventSourceId) {
              strategy.params.busId = eventSourceId;
            }

            const mainEntity: Entity = { type: "STRATEGY", entity: strategy };
            let benchmarkEntity: undefined | Entity = undefined;

            // If benchmark is not selected use the strategyBenchmark otherwise use the selected one
            const benchmarkOfStrategy = strategy.params.strategy.benchmark;
            if (isBenchmakSelected === true) {
              strategy.params.strategy.benchmark = benchmark;
            }

            if (benchmarkOfStrategy != null) {
              const isBlended = benchmarkOfStrategy.startsWith("COLLECTION:");
              const isNeutralStrategy =
                benchmarkOfStrategy === "TRENDRATING_NEUTRAL_STRATEGY";
              const isNeutralStrategyEqualWeighted =
                benchmarkOfStrategy ===
                "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";

              if (
                !isBlended &&
                !isNeutralStrategy &&
                !isNeutralStrategyEqualWeighted
              ) {
                if (isBenchmakSelected === false) {
                  log("RUN", { symbol: benchmarkOfStrategy });
                  updateBenchmarkWidget(benchmarkOfStrategy);
                }

                benchmarkEntity = prepareBenchmarkEntity(strategy) as Entity;
                hasBenchmark = true;
              }
            }

            await init(mainEntity, benchmarkEntity);
          }

          await getAnalytics(hasBenchmark);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingAnalytics(false);
      }
    },
    [
      benchmark,
      getAnalytics,
      init,
      isBenchmakSelected,
      loadingBehaviours,
      log,
      prepareBenchmarkEntity,
      progressBarInit,
      strategiesAPI,
      updateBenchmarkWidget,
    ]
  );

  const handleStrategySelect = useCallback(
    async (strategyId) => {
      log("RUN", { id: strategyId });
      run(strategyId);
      closeStrategyPanel();
    },
    [closeStrategyPanel, log, run]
  );

  const handleBenchmarkChange = useCallback(
    async (benchmarkSymbol) => {
      const symbol = benchmarkSymbol?.symbol ?? null;
      log("RUN", { symbol });
      setBenchmark(symbol);

      if (selectedStrategyObj && symbol != null) {
        const strategyClone = JSON.parse(JSON.stringify(selectedStrategyObj));

        // Update the benchmark
        strategyClone.params.strategy.benchmark = symbol;

        const benchmarkEntity = prepareBenchmarkEntity(strategyClone) as Entity;

        if (isReady()) {
          setLoadingAnalytics(true);
          try {
            await changeEntity("B", benchmarkEntity);

            await getAnalytics(true);
          } catch (error) {
            console.log(error);
          } finally {
            setLoadingAnalytics(false);
          }
        }
      } else if (symbol != null) {
        // Handle benchmark as a strategy if strategy is not selected
        clearInstance();

        setLoadingAnalytics(true);

        try {
          const benchmarkEntity: Entity = {
            type: "INSTRUMENT",
            entity: {
              symbol: symbol,
              params: {
                currency: "local",
              },
            },
            includeFromDay: getDateFromPeriod(15),
          };

          await init(benchmarkEntity);

          await getAnalytics(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingAnalytics(false);
        }
      } else {
        await changeEntity("B", undefined);
      }
    },
    [
      changeEntity,
      clearInstance,
      getAnalytics,
      getDateFromPeriod,
      init,
      isReady,
      log,
      prepareBenchmarkEntity,
      selectedStrategyObj,
    ]
  );

  const getHoldings = useCallback(
    async (symbols: string[]) => {
      if (symbols && symbols.length) {
        const properties = [
          { date: null, property: "ticker" },
          { date: null, property: "name" },
          { date: null, property: "pq" },
          { date: null, property: "ps" },
          { date: null, property: "py" },
        ];

        try {
          const response = await instrumentsAPI.fetch({
            symbols,
            properties,
            type: "security",
          });

          const holdings = response?.data ?? undefined;

          setActivePortfolioHoldings(holdings);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [instrumentsAPI]
  );

  const selectPortfolio = useCallback(
    async (row) => {
      const rowData = row.getData();

      setActivePortfolio(rowData);
      const portfolioId = rowData.id;

      log("RUN", { id: portfolioId });

      setActivePortfolioHoldings(undefined);
      setLoadingHoldings(true);

      try {
        const response = await listsAPI.portfolioFetch(
          [portfolioId],
          [portfolioAnalyticsDict["positionsToday"]]
        );

        const positions =
          response?.[0]?.[portfolioAnalyticsDict["positionsToday"]] ??
          undefined;

        if (positions && positions.length) {
          const symbols = positions.map((pos) => pos.symbol);
          await getHoldings(symbols);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingHoldings(false);
      }
    },
    [getHoldings, listsAPI, log]
  );

  const tableEventsHandler = useCallback(
    (event) => {
      if (event.type === "rowClick") {
        selectPortfolio(event.value);
      }
    },
    [selectPortfolio]
  );

  const refreshBenchmarkSearch = useCallback(() => {
    setBenchmarkWgetKey(Date.now());
  }, []);

  const clearOnTabChange = useCallback(() => {
    setActivePortfolio(undefined);
    setActivePortfolioHoldings(undefined);
    setSelectedStrategy("load");
    setSelectedStrategyObj(undefined);
    setAnalyticsResults(undefined);
    refreshBenchmarkSearch();
  }, [refreshBenchmarkSearch]);

  const changeTab = useCallback(
    (e, value) => {
      setTab(value);
      clearOnTabChange();
    },
    [clearOnTabChange]
  );

  const togglePerformanceFormatter = useCallback(() => {
    tablePerformaceFormatter === "PERCENTAGE"
      ? setTablePerformaceFormatter("HISTOGRAM")
      : setTablePerformaceFormatter("PERCENTAGE");
  }, [tablePerformaceFormatter]);

  const onPageMount = useCallback(async () => {
    removeLoader();
    getUserLists();
  }, [getUserLists]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  // handle workflow
  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    action = {
      componentJSX: (
        <li className="menu__item" onClick={createPortfolio}>
          Create Portfolio
        </li>
      ),
    };

    actions.push(action);

    action = {
      componentJSX: (
        <li className="menu__item" onClick={createBasket}>
          Create Basket
        </li>
      ),
    };

    actions.push(action);

    if (activePortfolio != null && activePortfolio.isReadOnly === false) {
      action = {
        componentJSX: (
          <li
            className="menu__item"
            onClick={() => editPortfolio(activePortfolio?.id)}
          >
            Edit
          </li>
        ),
      };

      actions.push(action);
    }

    var message = {
      from: "PORTFOLIO_HOME",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    activePortfolio,
    broadcast,
    createBasket,
    createPortfolio,
    editPortfolio,
  ]);

  const addPortfolioRow = useCallback(
    (appendNode, rowLabel) => {
      appendNode.push([
        {
          style: null,
          value: rowLabel,
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["quarterPerfAvg"]]
          ),
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["sixMonthsPerfAvg"]]
          ),
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["yearlyPerfAvg"]]
          ),
        },
      ]);
    },
    [activePortfolio, formatPercentage]
  );

  const addAnalyticsRow = useCallback(
    (appendNode, key, rowLabel) => {
      appendNode.push([
        { style: null, value: rowLabel },
        {
          style: null,
          value: formatPercentage(analyticsResults[key]["avgPerfQuarter"]),
        },
        {
          style: null,
          value: formatPercentage(analyticsResults[key]["avgPerfSixMonths"]),
        },
        {
          style: null,
          value: formatPercentage(analyticsResults[key]["avgPerfYearly"]),
        },
      ]);
    },
    [analyticsResults, formatPercentage]
  );

  const sortTable = useCallback(
    (sorterKey: "performances" | "differences" | "holdings", list) => {
      const sorters = getCurrentSorters();

      if (sorters[sorterKey] != null) {
        const sorter = sorters[sorterKey];

        if (sorter) {
          list.contextualParams.tableBody.sort((a, b) =>
            sortTableRows(a, b, sorter.field, sorter.direction)
          );
        }
      }
    },
    [getCurrentSorters]
  );

  const addDiffRow = useCallback(
    (appendNode, diffTargetKey, rowLabel) => {
      appendNode.push([
        {
          style: null,
          value: rowLabel,
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["quarterPerfAvg"]] -
              analyticsResults[diffTargetKey]["avgPerfQuarter"]
          ),
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["sixMonthsPerfAvg"]] -
              analyticsResults[diffTargetKey]["avgPerfSixMonths"]
          ),
        },
        {
          style: null,
          value: formatPercentage(
            activePortfolio[portfolioAnalyticsDict["yearlyPerfAvg"]] -
              analyticsResults[diffTargetKey]["avgPerfYearly"]
          ),
        },
      ]);
    },
    [activePortfolio, analyticsResults, formatPercentage]
  );

  const prepareAnalyticsTable = useCallback(() => {
    const sectionToAdd: any = [];

    if (analyticsResults) {
      if (benchmark != null && !selectedStrategyObj) {
        // prepare perf table
        const tableWget: any = {
          sectionId: "table",
          contextualParams: {
            tableHead: [
              [
                {
                  style: null,
                  value: "Performance",
                },
                {
                  style: null,
                  value: "3 Months",
                },
                {
                  style: null,
                  value: "6 Months",
                },
                {
                  style: null,
                  value: "12 Months",
                },
              ],
            ],
            tableBody: [],
          },
        };
        addAnalyticsRow(tableWget.contextualParams.tableBody, "H", "Benchmark");

        let hasDiffTable = false;

        if (activePortfolio) {
          hasDiffTable = true;

          addPortfolioRow(
            tableWget.contextualParams.tableBody,
            tab === "basket" ? "Basket Average" : "Portfolio Average"
          );
        }

        sortTable("performances", tableWget);

        sectionToAdd.push(tableWget);

        // Prepare diff table
        if (hasDiffTable) {
          const tableDiffWget: any = {
            sectionId: "table",
            contextualParams: {
              tableHead: [
                [
                  {
                    style: null,
                    value: "Differences",
                  },
                  {
                    style: null,
                    value: "3 Months",
                  },
                  {
                    style: null,
                    value: "6 Months",
                  },
                  {
                    style: null,
                    value: "12 Months",
                  },
                ],
              ],
              tableBody: [],
            },
          };

          addDiffRow(
            tableDiffWget.contextualParams.tableBody,
            "H",
            `${tab === "basket" ? "Basket" : "Portfolio"} Average vs Benchmark`
          );

          sortTable("differences", tableDiffWget);

          sectionToAdd.push(tableDiffWget);
        }
      } else {
        const tableWget: any = {
          sectionId: "table",
          contextualParams: {
            tableHead: [
              [
                {
                  style: null,
                  value: "Performance",
                },
                {
                  style: null,
                  value: "3 Months",
                },
                {
                  style: null,
                  value: "6 Months",
                },
                {
                  style: null,
                  value: "12 Months",
                },
              ],
            ],
            tableBody: [],
          },
        };

        for (const key in analyticsResults) {
          if (key !== "D") {
            addAnalyticsRow(
              tableWget.contextualParams.tableBody,
              key,
              key === "B" ? "Benchmark" : "Strategy"
            );
          }
        }

        const hasDiffTable =
          activePortfolio != null || analyticsResults["D"]["avgPerfQuarter"];

        if (activePortfolio) {
          addPortfolioRow(
            tableWget.contextualParams.tableBody,
            tab === "basket" ? "Basket Average" : "Portfolio Average"
          );
        }

        sortTable("performances", tableWget);

        sectionToAdd.push(tableWget);

        if (hasDiffTable) {
          const tableDiffWget: any = {
            sectionId: "table",
            contextualParams: {
              tableHead: [
                [
                  {
                    style: null,
                    value: "Differences",
                  },
                  {
                    style: null,
                    value: "3 Months",
                  },
                  {
                    style: null,
                    value: "6 Months",
                  },
                  {
                    style: null,
                    value: "12 Months",
                  },
                ],
              ],
              tableBody: [],
            },
          };

          addAnalyticsRow(
            tableDiffWget.contextualParams.tableBody,
            "D",
            "Strategy vs Benchmark"
          );

          if (activePortfolio != null) {
            addDiffRow(
              tableDiffWget.contextualParams.tableBody,
              "B",
              `${
                tab === "basket" ? "Basket" : "Portfolio"
              } Average vs Benchmark`
            );

            addDiffRow(
              tableDiffWget.contextualParams.tableBody,
              "H",
              `${tab === "basket" ? "Basket" : "Portfolio"} Average vs Strategy`
            );
          }

          sortTable("differences", tableDiffWget);

          sectionToAdd.push(tableDiffWget);
        }
      }
    }

    return sectionToAdd;
  }, [
    activePortfolio,
    addAnalyticsRow,
    addDiffRow,
    addPortfolioRow,
    analyticsResults,
    benchmark,
    selectedStrategyObj,
    sortTable,
    tab,
  ]);

  const prepareHoldingsTable = useCallback(() => {
    if (activePortfolioHoldings && activePortfolioHoldings.length) {
      const widget: any = {
        sectionId: "table",
        contextualParams: {
          tableHead: [
            [
              {
                style: null,
                value: "Ticker",
              },
              {
                style: null,
                value: "Name",
              },
              {
                style: null,
                value: "3 Months",
              },
              {
                style: null,
                value: "6 Months",
              },
              {
                style: null,
                value: "12 Months",
              },
            ],
          ],
          tableBody: [],
        },
      };

      for (const holding of activePortfolioHoldings) {
        widget.contextualParams.tableBody.push([
          { style: null, value: holding.ticker },
          { style: null, value: holding.name },
          { style: null, value: formatPercentage(holding.pq) ?? "" },
          { style: null, value: formatPercentage(holding.ps) ?? "" },
          { style: null, value: formatPercentage(holding.py) ?? "" },
        ]);
      }

      sortTable("holdings", widget);

      return widget;
    }
  }, [activePortfolioHoldings, formatPercentage, sortTable]);

  const buildPdfTitle = useCallback(async () => {
    const isPortfolioSelected = activePortfolio != null;
    const isStrategySelected = selectedStrategyObj != null;

    let benchmarkValue = null;

    if (isBenchmakSelected) {
      benchmarkValue = benchmark;
    } else if (isStrategySelected) {
      benchmarkValue = selectedStrategyObj.params.strategy.benchmark;
    }

    let title: string[] = [];

    if (isPortfolioSelected) {
      title.push(`Portfolio (${escapeEntity(activePortfolio.name)})`);
    }

    if (isStrategySelected) {
      title.push(`Strategy (${escapeEntity(selectedStrategyObj.name)})`);
    }

    if (benchmarkValue) {
      const fetchResponse = await instrumentsAPI.fetch({
        type: "security",
        symbols: [benchmarkValue],
        properties: [{ date: null, property: "name" }],
      });

      title.push(
        `Benchmark (${escapeEntity(fetchResponse?.data?.[0]?.name ?? "")})`
      );
    }

    return title.join(" vs ");
  }, [
    activePortfolio,
    benchmark,
    instrumentsAPI,
    isBenchmakSelected,
    selectedStrategyObj,
  ]);

  const downloadFactsheet = useCallback(async () => {
    try {
      const pdfTitle = await buildPdfTitle();

      const template: any = [
        {
          sectionId: "space",
        },
        {
          sectionId: "title",
          contextualParams: {
            text: pdfTitle,
          },
        },
        {
          sectionId: "space",
        },
        {
          sectionId: "space",
        },
      ];

      const holdingsTableSection = prepareHoldingsTable();
      const analyticsSection = prepareAnalyticsTable();

      if (analyticsSection && analyticsSection.length) {
        template.push(...analyticsSection);
      }

      if (holdingsTableSection) {
        template.push(
          {
            sectionId: "tableTitle",
            contextualParams: {
              text: "Holdings",
            },
          },
          holdingsTableSection
        );
      }

      template.push({ sectionId: "space" }, { sectionId: "disclaimer" });

      const pdf = new PDF(environment.get("setup"), template, pdfTitle);

      await pdf.print();
    } catch (error) {
      console.log(error);

      const [channel, msg] = messageError(error);
      broadcast(channel as string, msg);
    }
  }, [
    broadcast,
    buildPdfTitle,
    environment,
    prepareAnalyticsTable,
    prepareHoldingsTable,
  ]);

  const insightsStateRef = useRef<InsightsState>({
    selectedInsightsVersion: "V1",
    usedIn: "RISE",
    currentTab: "US",
    selectedStrategy: undefined,
    insightsV1State: {
      multiFactorTableSorter: { field: "name", rev: false },
      singleFactorTableSorter: { field: "name", rev: false },
      trStrategiesTableSorter: { field: "name", rev: false },
      bestByTableSorter: { field: "year_10_perf_H", rev: false },
      bestByRadioValue: "10_years_return",
      bestByActiveCheckbox: [],
      scrollToTargetId: undefined,
      handleRowClick: handleStrategySelect,
    },
    insightsV2State: {
      activeFilters: undefined,
      selectedColumn: "type",
      sorter: { field: "name", rev: false },
      hidedFields: [],
      handleRowClick: () => {},
    },
    catalogState: {
      activeFilters: [],
      sorter: { field: "name", rev: false },
      handleRowClick: () => {},
    },
  });

  const updateInsightsState = useCallback((value: InsightsState) => {
    insightsStateRef.current = value;
  }, []);

  const getInsightsState = useCallback(() => {
    return insightsStateRef.current;
  }, []);

  const insightsContext = useMemo(() => {
    return {
      state: insightsStateRef.current,
      get: getInsightsState,
      set: updateInsightsState,
    };
  }, [getInsightsState, updateInsightsState]);

  return (
    <Box className={styles.main}>
      <Card className={styles.main__card}>
        <CardContent className={styles.main__card__content}>
          <Box className={styles.main__card__content__listPanel}>
            <Box
              display={loadingLists ? "flex" : "none"}
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress sx={{ color: "#2a7090" }} />
            </Box>
            <Box
              display={loadingLists ? "none" : "flex"}
              flex={1}
              gap={"36px"}
              flexDirection={"column"}
            >
              <Tabs
                value={tab}
                onChange={changeTab}
                aria-label="Portfolios tabs"
                sx={{
                  minHeight: "0!important",

                  "& .Mui-selected": {
                    fontSize: "1vw",
                    color: "#2a7090!important",
                    borderBottomColor: "#2a7090!important",
                    paddingTop: "5px!important",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#2a7090!important",
                  },
                }}
              >
                <Tab
                  sx={{
                    color: "black!important",
                    fontSize: "1vw",
                    paddingTop: "5px!important",
                  }}
                  label={"Portfolios"}
                  value={"portfolio"}
                />
                <Tab
                  sx={{
                    color: "black!important",
                    fontSize: "1vw",
                    paddingTop: "5px!important",
                  }}
                  label={"Baskets"}
                  value={"basket"}
                />
              </Tabs>
              <Box minHeight={0} flex={1}>
                <TrendratingTable
                  disableDefaultRowClick
                  columns={collectionTableColumns as any}
                  data={collection}
                  eventCallback={tableEventsHandler}
                  autoResize={false}
                />
              </Box>
            </Box>
          </Box>
          <ClickAwayListener onClickAway={closeStrategyPanel}>
            <Card
              sx={{ boxShadow: 3 }}
              className={styles.main__card__content__strategyPanel}
            >
              <CardContent
                className={styles.main__card__content__strategyPanel__content}
              >
                <Box display={"flex"} gap={2}>
                  <Box
                    flex={2}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography variant="tr_subtitle_blue">
                      Strategy:
                    </Typography>

                    <Select
                      className={styles.customSelect}
                      sx={{ fontSize: "0.7vw", height: "100%", width: "100%" }}
                      size="small"
                      value={selectedStrategy}
                      open={false}
                      onOpen={openStrategyPanel}
                      onClose={closeStrategyPanel}
                      renderValue={(selectedId) => {
                        const strategy = selectStrategyOptions.find(
                          (item) => item.id === parseInt(selectedId)
                        );

                        if (strategy) {
                          return (
                            <Typography>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: strategy.name,
                                }}
                              ></span>{" "}
                              {strategy.isReadOnly ? (
                                <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          );
                        }

                        return "Select strategy";
                      }}
                    ></Select>
                  </Box>
                  <Box
                    flex={1}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Typography variant="tr_subtitle_blue">
                      Benchmark:
                    </Typography>
                    <Search
                      key={benchmarkWgetKey}
                      ref={searchRef}
                      showInstrumentInfoOnSelect={false}
                      onSelectInstrument={handleBenchmarkChange}
                    />
                  </Box>

                  <Box
                    flex={1}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                  >
                    <Typography
                      variant="tr_subtitle_blue"
                      visibility={"hidden"}
                    >
                      Download
                    </Typography>
                    <Box>
                      {!isReportEmpty && (
                        <Button variant="contained" onClick={downloadFactsheet}>
                          Download PDF
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box
                    flex={1}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                  >
                    <Typography variant="tr_subtitle_blue">
                      Histogram
                    </Typography>
                    <Box>
                      <Box
                        className={`${styles.iconButtonWidget} ${
                          showHistogram ? styles.iconButtonWidget__active : ""
                        }`}
                        onClick={togglePerformanceFormatter}
                        title="Show/hide histogram"
                      >
                        <span className="i-beta"></span>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {showStrategies ? (
                  <InsightsContext.Provider value={insightsContext}>
                    <StrategySelector
                      onSelectStrategy={handleStrategySelect}
                      userStrategies={userStrategies}
                      dashboards={dashboards}
                      loading={loadingStrategies}
                    />
                  </InsightsContext.Provider>
                ) : (
                  // <InsightsSection
                  //   dashboards={dashboards}
                  //   onRowClick={(json, name) => console.log(json, name)}
                  // />
                  <Box flex={1} display={"flex"} minHeight={0}>
                    {loadingAnalytics ? (
                      <LoaderContext.Provider
                        value={{
                          availableEvents: STRATEGIES_LOADER_EVENTS,
                          on,
                          remove,
                          dispatch,
                        }}
                      >
                        <Box flex={1} sx={{ position: "relative" }}>
                          <Loader />
                        </Box>
                      </LoaderContext.Provider>
                    ) : (
                      <Results
                        onTableSortChange={updateSorters}
                        portfolioHoldings={activePortfolioHoldings}
                        activePortfolio={activePortfolio}
                        strategyAnalytics={analyticsResults}
                        formatters={formatters}
                        showHistogram={showHistogram}
                        loadingPortfolioHoldings={loadingHoldings}
                        currentTab={tab}
                        isStrategySelected={selectedStrategyObj != null}
                      />
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>
          </ClickAwayListener>
        </CardContent>
      </Card>
    </Box>
  );
}

const StrategySelector = ({
  userStrategies,
  dashboards,
  loading,
  onSelectStrategy,
}: StrategySelectorProps) => {
  return (
    <Box className={styles.strategySelector__main}>
      {loading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flex={1}
        >
          <CircularProgress sx={{ color: "#2a7090" }} />
        </Box>
      ) : (
        <>
          <Box className={styles.strategySelector__main__leftPanel}>
            <Typography variant="tr_subtitle_blue">My Strategies</Typography>
            <ul className={styles.strategySelector__main__leftPanel__list}>
              <StrategiesGroup
                voices={userStrategies}
                updateValue={onSelectStrategy}
              />
            </ul>
          </Box>
          <Box className={styles.strategySelector__main__rightPanel}>
            <Insights
              askBeforeInsightsRedirect={false}
              dashboards={dashboards}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const StrategiesGroup = ({ voices, updateValue }) => {
  const ctx = useContext(InsightsContext);

  const handleClick = useCallback(
    (id) => {
      updateValue(id);

      const currentInsightsState = deepClone(ctx.get());
      currentInsightsState.selectedStrategy = undefined;
      currentInsightsState.currentTab = "US";
      currentInsightsState.selectedInsightsVersion = "V1";
      currentInsightsState.insightsV1State.scrollToTargetId = undefined;

      ctx.set(currentInsightsState);
    },
    [ctx, updateValue]
  );

  return voices.map((item) => (
    <li key={uuidv4()} onClick={() => handleClick(item.id)} value={""}>
      <Typography>
        <span>
          <span
            style={{ visibility: item.isReadOnly ? "visible" : "hidden" }}
            className={`sharedObjectIndicator sharedObjectIndicator--small`}
          ></span>{" "}
          {item.name}
        </span>
      </Typography>
    </li>
  ));
};

// const InsightsGroup = ({ strategies, onSelectValue }) => {
//   const keyToRowName = useMemo(
//     () => ({
//       one: "SINGLE <br /> FUNDAMENTAL",
//       two: "COMBINED <br /> FUNDAMENTALS",
//       three: "OPTIMIZED <br /> STRATEGIES",
//     }),
//     []
//   );

//   const tableConfig = useMemo(() => {
//     const strategiesGroups = {
//       one: { US: [], EU: [], R_D_P: [] },
//       two: { US: [], EU: [], R_D_P: [] },
//       three: { US: [], EU: [], R_D_P: [] },
//     };

//     for (const strategy of strategies) {
//       strategiesGroups[strategy.level][strategy.universe].push(strategy);
//     }

//     const table: any = [];

//     for (const key in strategiesGroups) {
//       table.push([
//         { name: keyToRowName[key] },
//         { strategies: strategiesGroups[key]["US"] },
//         { strategies: strategiesGroups[key]["EU"] },
//         { strategies: strategiesGroups[key]["R_D_P"] },
//       ]);
//     }

//     return table;
//   }, [keyToRowName, strategies]);

//   return (
//     <Box overflow={"auto"} width={"100%"}>
//       <table className={styles.insightsTableSelector}>
//         <thead>
//           <tr>
//             <th></th>
//             <th style={{ textAlign: "left" }}>United States</th>
//             <th style={{ textAlign: "left" }}>Europe</th>
//             <th style={{ textAlign: "left" }}>Asia Pacific</th>
//           </tr>
//         </thead>
//         <tbody>
//           {tableConfig.map((item) => {
//             return (
//               <tr key={uuidv4()}>
//                 {item.map((cell, index) => {
//                   if (index === 0) {
//                     return (
//                       <td
//                         style={{ verticalAlign: "center" }}
//                         key={uuidv4()}
//                         dangerouslySetInnerHTML={{ __html: cell.name }}
//                       ></td>
//                     );
//                   }
//                   return (
//                     <td key={uuidv4()} style={{ verticalAlign: "top" }}>
//                       <ul>
//                         {cell.strategies.map((strategy) => {
//                           return (
//                             <li
//                               key={uuidv4()}
//                               onClick={() => onSelectValue(strategy.id)}
//                               dangerouslySetInnerHTML={{
//                                 __html: strategy.name,
//                               }}
//                             ></li>
//                           );
//                         })}
//                       </ul>
//                     </td>
//                   );
//                 })}
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </Box>
//   );
// };

export const Results = ({
  strategyAnalytics,
  formatters,
  activePortfolio,
  showHistogram,
  loadingPortfolioHoldings,
  portfolioHoldings,
  currentTab,
  isStrategySelected,
  onTableSortChange,
}: ResultsProps) => {
  const list = useMemo(
    () => (currentTab === "basket" ? "Basket" : "Portfolio"),
    [currentTab]
  );

  const canShowDiffTable = useMemo(
    () => isStrategySelected || activePortfolio,
    [activePortfolio, isStrategySelected]
  );

  const addNormalizationTresholds = useCallback((data) => {
    const normalizations: any = {
      threeMonths: null,
      sixMonths: null,
      oneYear: null,
    };

    let normalizedData: any = null;

    for (const key in columnsFieldsStrategyTables) {
      normalizedData = UtilsMath.normalize(
        JSON.parse(JSON.stringify(data)),
        columnsFieldsStrategyTables[key]
      );

      // Get the normalization Threshold value
      normalizations[key] = normalizedData[0]["_s_normalizationThreshold"];
    }

    return data.map((cell) => ({
      ...cell,
      normalizedThreeMonths: normalizations["threeMonths"],
      normalizedSixMonths: normalizations["sixMonths"],
      normalizedOneYear: normalizations["oneYear"],
    }));
  }, []);

  const wrapInTable = useCallback(
    (table: "perf" | "delta" | "portfolio") => {
      if (!strategyAnalytics && (table === "delta" || table === "perf")) {
        return [];
      }

      if (table === "perf") {
        const strategyRows: any = [];

        if (isStrategySelected) {
          strategyRows.push({
            name: "Strategy",
            threeMonthsPerfAvg: strategyAnalytics?.["H"]?.["avgPerfQuarter"],
            sixMonthsPerfAvg: strategyAnalytics?.["H"]?.["avgPerfSixMonths"],
            oneYearPerfAvg: strategyAnalytics?.["H"]?.["avgPerfYearly"],
          });
          strategyRows.push({
            name: "Benchmark",
            threeMonthsPerfAvg: strategyAnalytics?.["B"]?.["avgPerfQuarter"],
            sixMonthsPerfAvg: strategyAnalytics?.["B"]?.["avgPerfSixMonths"],
            oneYearPerfAvg: strategyAnalytics?.["B"]?.["avgPerfYearly"],
          });
        } else if (strategyAnalytics != null) {
          // Takes analytics from H because benchmark and not strategy is set
          strategyRows.push({
            name: "Benchmark",
            threeMonthsPerfAvg: strategyAnalytics?.["H"]?.["avgPerfQuarter"],
            sixMonthsPerfAvg: strategyAnalytics?.["H"]?.["avgPerfSixMonths"],
            oneYearPerfAvg: strategyAnalytics?.["H"]?.["avgPerfYearly"],
          });
        }

        if (activePortfolio != null) {
          strategyRows.push({
            name: `${list} Average`,
            threeMonthsPerfAvg:
              activePortfolio?.[portfolioAnalyticsDict?.["quarterPerfAvg"]],
            sixMonthsPerfAvg:
              activePortfolio?.[portfolioAnalyticsDict?.["sixMonthsPerfAvg"]],
            oneYearPerfAvg:
              activePortfolio?.[portfolioAnalyticsDict?.["yearlyPerfAvg"]],
          });
        }

        return addNormalizationTresholds(strategyRows);
      } else if (table === "delta") {
        const strategyAndList: any = [];

        if (canShowDiffTable) {
          if (isStrategySelected) {
            strategyAndList.push({
              name: "Strategy vs Benchmark",
              threeMonthsPerfAvg: strategyAnalytics?.["D"]?.["avgPerfQuarter"],
              sixMonthsPerfAvg: strategyAnalytics?.["D"]?.["avgPerfSixMonths"],
              oneYearPerfAvg: strategyAnalytics?.["D"]?.["avgPerfYearly"],
            });
          }

          if (activePortfolio != null) {
            if (isStrategySelected) {
              const againstListRows = [
                {
                  name: `${list} Average vs Benchmark`,
                  threeMonthsPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["quarterPerfAvg"]
                    ] - strategyAnalytics?.["B"]?.["avgPerfQuarter"],
                  sixMonthsPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["sixMonthsPerfAvg"]
                    ] - strategyAnalytics?.["B"]?.["avgPerfSixMonths"],
                  oneYearPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["yearlyPerfAvg"]
                    ] - strategyAnalytics?.["B"]?.["avgPerfYearly"],
                },
                {
                  name: `${list} Average vs Strategy`,
                  threeMonthsPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["quarterPerfAvg"]
                    ] - strategyAnalytics?.["H"]?.["avgPerfQuarter"],
                  sixMonthsPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["sixMonthsPerfAvg"]
                    ] - strategyAnalytics?.["H"]?.["avgPerfSixMonths"],
                  oneYearPerfAvg:
                    activePortfolio?.[
                      portfolioAnalyticsDict?.["yearlyPerfAvg"]
                    ] - strategyAnalytics?.["H"]?.["avgPerfYearly"],
                },
              ];

              strategyAndList.push(...againstListRows);
            } else if (strategyAnalytics != null) {
              strategyAndList.push({
                name: `${list} Average vs Benchmark`,
                threeMonthsPerfAvg:
                  activePortfolio?.[
                    portfolioAnalyticsDict?.["quarterPerfAvg"]
                  ] - strategyAnalytics?.["H"]?.["avgPerfQuarter"],
                sixMonthsPerfAvg:
                  activePortfolio?.[
                    portfolioAnalyticsDict?.["sixMonthsPerfAvg"]
                  ] - strategyAnalytics?.["H"]?.["avgPerfSixMonths"],
                oneYearPerfAvg:
                  activePortfolio?.[portfolioAnalyticsDict?.["yearlyPerfAvg"]] -
                  strategyAnalytics?.["H"]?.["avgPerfYearly"],
              });
            }
          }

          return addNormalizationTresholds(strategyAndList);
        }
        return [];
      } else {
        if (portfolioHoldings) {
          const rows: any = [];

          portfolioHoldings?.forEach((security) => {
            rows.push({
              name: security.name,
              ticker: security.ticker,
              threeMonthsPerfAvg: security.pq,
              sixMonthsPerfAvg: security.ps,
              oneYearPerfAvg: security.py,
            });
          }, []);

          return addNormalizationTresholds(rows);
        }

        return [];
      }
    },
    [
      activePortfolio,
      addNormalizationTresholds,
      canShowDiffTable,
      isStrategySelected,
      list,
      portfolioHoldings,
      strategyAnalytics,
    ]
  );

  const showAnalyticsTable = useMemo(
    () => strategyAnalytics != null,
    [strategyAnalytics]
  );
  const showHelpText = useMemo(
    () => strategyAnalytics == null && portfolioHoldings == null,
    [portfolioHoldings, strategyAnalytics]
  );

  const tablePerformanceData = useMemo(() => {
    return wrapInTable("perf");
  }, [wrapInTable]);

  const tableDeltaData = useMemo(() => {
    return wrapInTable("delta");
  }, [wrapInTable]);

  const tableHoldingsData = useMemo(() => {
    return wrapInTable("portfolio");
  }, [wrapInTable]);

  const getFormatter = useCallback(
    (cell) => {
      return showHistogram
        ? formatters.bar(cell)
        : formatters.percentageAligned(cell);
    },
    [formatters, showHistogram]
  );

  const formatTitle = useCallback((cell) => {
    const value = cell.getValue();

    return `<div style="margin-right: 11em">${value}</div>`;
  }, []);

  const onSortPerfTable = useCallback(
    (sorter: { field: number; direction: "desc" | "asc" }) => {
      onTableSortChange("performances", sorter);
    },
    [onTableSortChange]
  );

  const onSortDeltaTable = useCallback(
    (sorter: { field: number; direction: "desc" | "asc" }) => {
      onTableSortChange("differences", sorter);
    },
    [onTableSortChange]
  );

  const onSortPortfolioTable = useCallback(
    (sorter: { field: number; direction: "desc" | "asc" }) => {
      onTableSortChange("holdings", sorter);
    },
    [onTableSortChange]
  );

  const prepareHeaderClickTapListener = useCallback(
    (eventCallback?: Function) => {
      return function headerClickTapListener(
        e: UIEvent,
        headerColumn: ColumnComponent
      ) {
        const table = headerColumn.getTable();
        const sorters = table.getSorters();

        let actualDirection =
          headerColumn.getDefinition().headerSortStartingDir;
        if (
          sorters.length > 0 &&
          sorters[0].field === headerColumn.getField()
        ) {
          actualDirection = sorters[0].dir === "asc" ? "desc" : "asc";
        }

        const columns = table.getColumns();

        let property = 0;

        for (let i = 0; i < columns.length; i++) {
          if (headerColumn.getField() === columns[i].getField()) {
            property = i;

            break;
          }
        }

        if (eventCallback) {
          eventCallback({
            direction: actualDirection,
            field: property,
          });
        }
      };
    },
    []
  );

  const getColumns = useCallback(
    (table: "perf" | "delta" | "portfolio") => {
      if (table === "portfolio") {
        return [
          {
            title: "Holdings",
            resizable: false,
            columns: [
              {
                title: "Ticker",
                field: "ticker",
                widthGrow: 0.2,
                resizable: false,
                headerClick:
                  prepareHeaderClickTapListener(onSortPortfolioTable),
              },
              {
                title: "Name",
                field: "name",
                widthGrow: 0.8,
                resizable: false,
                headerClick:
                  prepareHeaderClickTapListener(onSortPortfolioTable),
              },
            ],
          },
          {
            title: "",
            resizable: false,
            columns: [
              {
                title: "3 Months",
                field: columnsFieldsStrategyTables["threeMonths"],
                formatter: getFormatter,
                titleFormatter: formatTitle,
                headerHozAlign: "right",
                resizable: false,
                headerClick:
                  prepareHeaderClickTapListener(onSortPortfolioTable),
              },
              {
                title: "6 Months",
                field: columnsFieldsStrategyTables["sixMonths"],
                formatter: getFormatter,
                titleFormatter: formatTitle,
                headerHozAlign: "right",
                resizable: false,
                headerClick:
                  prepareHeaderClickTapListener(onSortPortfolioTable),
              },
              {
                title: "12 Months",
                field: columnsFieldsStrategyTables["oneYear"],
                formatter: getFormatter,
                titleFormatter: formatTitle,
                headerHozAlign: "right",
                resizable: false,
                headerClick:
                  prepareHeaderClickTapListener(onSortPortfolioTable),
              },
            ],
          },
        ];
      } else {
        const sorterCallback =
          table === "delta" ? onSortDeltaTable : onSortPerfTable;
        return [
          {
            title: table === "perf" ? "Performance" : "Differences",
            field: "name",
            resizable: false,
            headerClick: prepareHeaderClickTapListener(sorterCallback),
          },
          {
            title: "3 Months",
            field: columnsFieldsStrategyTables["threeMonths"],
            formatter: getFormatter,
            titleFormatter: formatTitle,
            headerHozAlign: "right",
            resizable: false,
            headerClick: prepareHeaderClickTapListener(sorterCallback),
          },
          {
            title: "6 Months",
            field: columnsFieldsStrategyTables["sixMonths"],
            formatter: getFormatter,
            titleFormatter: formatTitle,
            headerHozAlign: "right",
            resizable: false,
            headerClick: prepareHeaderClickTapListener(sorterCallback),
          },
          {
            title: "12 Months",
            field: columnsFieldsStrategyTables["oneYear"],
            formatter: getFormatter,
            titleFormatter: formatTitle,
            headerHozAlign: "right",
            resizable: false,
            headerClick: prepareHeaderClickTapListener(sorterCallback),
          },
        ];
      }
    },
    [
      formatTitle,
      getFormatter,
      onSortDeltaTable,
      onSortPerfTable,
      onSortPortfolioTable,
      prepareHeaderClickTapListener,
    ]
  );

  const tableColumnsPerf = useMemo(() => {
    return getColumns("perf") as any;
  }, [getColumns]);

  const tableColumnsDelta = useMemo(() => {
    return getColumns("delta") as any;
  }, [getColumns]);

  const tableColumnsPortfolio = useMemo(() => {
    return getColumns("portfolio") as any;
  }, [getColumns]);

  return (
    <Box className={styles.results__main}>
      {showHelpText ? (
        <Typography sx={{ marginTop: "8px!important" }}>
          Please Select a Strategy to analyze the returns delta
        </Typography>
      ) : (
        <Box
          flex={1}
          minHeight={0}
          display={"flex"}
          flexDirection={"column"}
          minWidth={0}
        >
          {showAnalyticsTable && (
            <>
              <Box>
                <TrendratingTable
                  disableDefaultRowClick
                  columns={tableColumnsPerf}
                  data={tablePerformanceData}
                  autoResize={false}
                />
              </Box>
              {canShowDiffTable && (
                <Box mt={2}>
                  <TrendratingTable
                    disableDefaultRowClick
                    columns={tableColumnsDelta}
                    data={tableDeltaData}
                    autoResize={false}
                  />
                </Box>
              )}
            </>
          )}

          {loadingPortfolioHoldings && (
            <Box
              mt={2}
              flex={1}
              minHeight={0}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <CircularProgress sx={{ color: "#2a7090" }} />
                <Typography>Loading Holdings...</Typography>
              </Box>
            </Box>
          )}

          {portfolioHoldings && (
            <Box mt={2} flex={1} minHeight={0}>
              <TrendratingTable
                disableDefaultRowClick
                columns={tableColumnsPortfolio}
                data={tableHoldingsData}
                autoResize={false}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

type ReportTemplateSchema = {
  sectionId:
    | "pageBreak"
    | "table"
    | "title"
    | "subtitle"
    | "space"
    | "tableTitle"
    | "disclaimer";
  contextualParams?: {
    text?: string;
    tableHead?: any[];
    tableBody?: any[];
  };
}[];

export class PDF {
  environment: AppEnvironment;
  reportAPI: Report;
  template: ReportTemplateSchema;
  reportTitle: string;

  constructor(
    environment: AppEnvironment,
    reportTemplate: ReportTemplateSchema,
    reportTitle: string
  ) {
    this.environment = environment;
    this.reportAPI = new Report(this.environment);
    this.template = reportTemplate;
    this.reportTitle = reportTitle;
  }

  widgets = {
    title: {
      data: {
        text: "",
      },
      type: "title",
    },
    subtitle: {
      data: {
        text: "",
      },
      type: "title2",
    },
    pageBreak: {
      data: null,
      type: "pageBreak",
    },
    space: {
      data: {
        text: "<br/><br/>",
      },
      type: "text",
    },
    tableTitle: {
      data: {
        text: "",
      },
      type: "header1",
    },
    table: {
      data: {
        body: [[]],
        head: [[]],
      },
      type: "table",
    },
    disclaimer: {
      data: {
        text: "",
      },
      type: "text",
    },
  };

  getUserPreference() {
    const preferences =
      this.environment.account.user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = themeBlueAndGrey;

    return userPreference;
  }

  async preparePrintParams() {
    let hasCover = false;
    let title = this.reportTitle;

    const fileName = "Trendrating - " + title.replace("/", "_") + ".pdf";

    const params = {
      content: await this.buildReportContent(),
      cover: hasCover,
      fileName: fileName,
      meta: {
        author: "Trendrating",
        title: title,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      userPreference: this.getUserPreference(),
    };

    return params;
  }

  async buildReportContent() {
    const content: any = [];
    let sectionId: any = null;

    for (const section of this.template) {
      sectionId = section.sectionId;

      switch (sectionId) {
        case "space":
          this.addSpace(content);
          break;

        case "title":
          this.addTitle(content, section);
          break;

        case "pageBreak":
          this.addPageBreak(content);
          break;

        case "disclaimer":
          await this.addDisclaimer(content);
          break;

        case "subTitle":
          this.addSubTitle(content, section);
          break;

        case "tableTitle":
          this.addTableTitle(content, section);
          break;

        case "table":
          this.addTable(content, section);
      }
    }

    return content;
  }

  addPageBreak(content) {
    content.push(this.widgets.pageBreak);
  }

  addSpace(content) {
    content.push(deepClone(this.widgets.space));
  }

  addTitle(content, section) {
    const titleWget = deepClone(this.widgets.title);

    titleWget.data.text = section.contextualParams.text;

    content.push(titleWget);
  }

  addSubTitle(content, section) {
    const subtitleWget = deepClone(this.widgets.subtitle);
    subtitleWget.data.text = section.contextualParams.text;

    content.push(subtitleWget);
  }

  addTableTitle(content, section) {
    const tableTitleWget = deepClone(this.widgets.tableTitle);

    tableTitleWget.data.text = section.contextualParams.text;

    content.push(deepClone(tableTitleWget));
  }

  addTable(content, section) {
    const table = deepClone(this.widgets.table);

    table.data.head = section?.contextualParams?.tableHead ?? [];
    table.data.body = section?.contextualParams?.tableBody ?? [];

    content.push(table);
  }

  async addDisclaimer(content) {
    const disclaimerWget = deepClone(this.widgets.disclaimer);

    let disclaimerText: any = this.getUserPreference().disclaimer;

    if (disclaimerText == null) {
      disclaimerText = await this.reportAPI.disclaimer();
    }

    disclaimerWget.data.text = disclaimerText;

    content.push(disclaimerWget);
  }

  async print() {
    try {
      const params = await this.preparePrintParams();

      const report = this.reportAPI;

      const response = await report.print(params);
      this.download(response, params.fileName);
    } catch (error: any) {
      console.log(error);
    }
  }

  download(response, fileName) {
    const blob = new Blob([response], {
      type: "application/pdf",
    });

    let downloadLink: HTMLAnchorElement | null = null;

    if ((navigator as any).msSaveBlob) {
      // IE 11
      downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", "#" + fileName);
      document.body.appendChild(downloadLink);

      downloadLink.addEventListener(
        "click",
        function (event) {
          (navigator as any).msSaveBlob(blob, fileName);
        },
        false
      );
    } else {
      downloadLink = document.createElement("a");

      const attributes = [
        { attr: "class", value: "a11y" },
        { attr: "href", value: window.URL.createObjectURL(blob) },
        { attr: "target", value: "_blank" },
      ];

      attributes.forEach((attribute) => {
        downloadLink?.setAttribute(attribute.attr, attribute.value);
      });

      document.body.appendChild(downloadLink);

      downloadLink.click();
      downloadLink.remove();
    }
  }
}
