import { useEffect, useRef, useState } from "react";

const apiStatus = {
    loading: "loading",
    complete: "complete",
    errored: "errored",
};

export const useApiCallOnMount = (service: () => any, cacheId?: string) => {
    const cache = useRef({});
    const [status, setStatus] = useState(apiStatus.loading);
    const [data, setData] = useState<any>();

    useEffect(() => {
        const promiseResolver = async () => {
            if (cacheId != null) {
                if (cache.current[cacheId]) {
                    setData(cache.current[cacheId]);
                    setStatus(apiStatus.complete);
                } else {
                    try {
                        const response = await service();
                        cache.current[cacheId] = response;
                        setData(response);
                        setStatus(apiStatus.complete);
                    } catch (err) {
                        setStatus(apiStatus.errored);
                    }
                }
            } else {
                try {
                    const response = await service();
                    setData(response);
                    setStatus(apiStatus.complete);
                } catch (err) {
                    setStatus(apiStatus.errored);
                }
            }
        };

        promiseResolver();

        return () => {
            setData(null);
        };
    }, [cacheId, service]);

    return [status, data];
};
