/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-usage/RecordMapTrendratingWebApp
 * @summary Map of available record entries
 *
 */
export const recordMap = {
  //
  // all names MUST be singular
  // keep them sorted alphabetically
  //
  action: {
    ADD_TO: "addTo",
    APPLY: "apply",
    BACK: "back",
    BROWSER_BACK: "browserBack",
    BROWSER_FORWARD: "browserForward",
    CHART: "chart",
    CHANGE_PASSWORD: "changePassword",
    CONSTRAINT: "constraint",
    CREATE: "create",
    DAILY_REPORT: "dailyReport",
    DELETE: "delete",
    DOWNGRADE: "downgrade",
    DUPLICATE: "duplicate",
    EDIT: "edit",
    EDIT_HISTORY: "editHistory",
    EXPORT: "export",
    EXPORT_CONSTITUENT: "exportConstituent",
    EXPORT_STRATEGY: "exportStrategy",
    LANDING: "landing",
    LOGIN: "login",
    LOGOUT: "logout",
    MOVERS_DOWN: "moversDown",
    MOVERS_UP: "moversUp",
    NEW: "new",
    NOTIFICATION: "notification",
    OPEN: "open",
    PREFERENCE: "preference",
    RANK: "rank",
    REPORT: "report",
    RUN: "run",
    SAVE: "save",
    SCREENING: "screening",
    START_TRACKING: "startTracking",
    STOP_TRACKING: "stopTracking",
    STRATEGIC_ALLOCATION: "strategicAllocation",
    SWITCH: "switch",
    TABLE_CONFIGURE: "tableConfigure",
    UPGRADE: "upgrade",
    VIEW: "view",
    PEER: "peer",
    PRINT: "print",
  } as const,
  actionParam: {
    AB_PERCENTAGE: "percentage",
    ALERT: "alert",
    ANY: "any",
    CHART: "chart",
    LAST_WEEK: "lastWeek",
    MARKET: "market",
    NUMBER: "number",
    PERCENTAGE: "percentage",
    RATING: "rating",
    SCREENING: "screening",
    TABLE: "table",
    TCR: "breadth",
    TCR_01: "tcrYesterday",
    TCR_20: "tcrLastMonth",
    TCR_20_REVERSAL: "tcrLastMonthReversal",
    TODAY: "today",
  } as const,
  function: {
    ALERT: "alert",
    HELP: "help",
    IMPROVE: "improve",
    LOGIN: "login",
    LOGOUT: "logout",
    MARKET: "market",
    MARKET_ETF: "market ETF",
    MESSAGE: "message",
    PEER_ANALYSIS: "peerAnalysis",
    PORTFOLIO_ANALYSIS: "portfolioAnalysis",
    PORTFOLIO_COMPARE: "portfolioCompare",
    PORTFOLIO_CREATE: "portfolioCreate",
    PORTFOLIO_HOME: "portfolioHome",
    PERFORMANCE_RISE: "performanceRise",
    PORTFOLIO_POINT_IN_TIME: "portfolioPointInTime",
    PORTFOLIO_EDIT: "portfolioEdit",
    PORTFOLIO_OPTIMIZE: "portfolioOptimize",
    PORTFOLIO_RANKING: "portfolioRanking",
    PREFERENCE: "preference",
    RANKING: "ranking",
    REPORTS_HUB: "reportsHub",
    SCREENING: "screening",
    SEARCH: "search",
    SECTOR_AND_INDUSTRY: "sectorAndIndustry",
    SECURITY_ANALYSIS: "securityAnalysis",
    STRATEGY: "strategy",
    STRATEGY_BUILDER: "strategyBuilder",
    STRATEGY_COMPARE: "strategyCompare",
    STRATEGY_LONG_SHORT: "strategyLongShort",
    SYSTEMATIC_PORTFOLIO: "systematicPortfolio", // specific SP
    SYSTEMATIC_PORTFOLIO_REBALANCE: "systematicPortfolioRebalance",
    SYSTEMATIC_PORTFOLIOS: "systematicPortfolios", // home
  } as const,
};
