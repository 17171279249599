import React, { useCallback, useMemo, useState } from "react";
import { messageError, messageSuccess } from "../../utils";
import { useEventBus } from "../../../../hooks/useEventBus";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { CompareStrategies } from "../../../../api/compute/CompareStrategies";
import { StrategySummaryModal } from "../../../../components/StrategySummary/StrategySummaryModal";
import { DialogSaveComponent } from "../../ui/commons/DialogSave/DialogSaveComponent";
import { Box, Button } from "@mui/material";
import { StrategyPicker } from "../../../../components/StrategyPicker/StrategyPicker";
import { CompareList } from "./widgets/compare/CompareStrategiesEditor";
import { useBroadcast } from "../../../../hooks/useBroadcast";

type EditorState = {
  firstStrategyId: number | null;
  secondStrategyId: number | null;
};

type CompareEditorType = {
  onRun: Function;
  onDelete: Function;
  onRename: Function;
};
export default function CompareEditor({
  onRun,
  onDelete,
  onRename,
}: CompareEditorType) {
  const [strategyToExpand, setStrategyToExpand] = useState<number>();
  const [strategiesToCompare, setStrategiesToCompare] = useState<EditorState>({
    firstStrategyId: null,
    secondStrategyId: null,
  });

  const { broadcast } = useBroadcast();

  const showFirstStrategyDetails = useCallback(async () => {
    // We know that the value cannot be null because this function is called by a widget that is rendered
    // only if the strategyId is non null
    setStrategyToExpand(strategiesToCompare.firstStrategyId!);
  }, [strategiesToCompare.firstStrategyId]);

  const showSecondStrategyDetails = useCallback(async () => {
    // We know that the value cannot be null because this function is called by a widget that is rendered
    // only if the strategyId is non null
    setStrategyToExpand(strategiesToCompare.secondStrategyId!);
  }, [strategiesToCompare.secondStrategyId]);

  const onCloseStrategySummaryDialog = useCallback(() => {
    setStrategyToExpand(undefined);
  }, []);

  const onPickerChange = useCallback(
    (strategyId: number, whichStrategy: number) => {
      const editorStateKey: { [key: number]: keyof EditorState } = {
        1: "firstStrategyId",
        2: "secondStrategyId",
      };

      setStrategiesToCompare((prevState) => {
        const updatedState = { ...prevState };

        updatedState[editorStateKey[whichStrategy]] = strategyId;

        return updatedState;
      });
    },
    []
  );

  const setFirstStrategy = useCallback(
    (strategyId) => {
      onPickerChange(strategyId, 1);
    },
    [onPickerChange]
  );

  const setSecondStrategy = useCallback(
    (strategyId) => {
      onPickerChange(strategyId, 2);
    },
    [onPickerChange]
  );

  const selectCompareStrategy = useCallback(
    (value) => {
      const strategy1Id = value?.strategy1?.id;
      const strategy2Id = value?.strategy2?.id;
      /**
       * Update only the initial value of the strategy Picker because it's an uncontrolled component and this operation
       * automatically upload the state of strategiesToCompare variable.
       */
      setFirstStrategy(strategy1Id);
      setSecondStrategy(strategy2Id);
    },
    [setFirstStrategy, setSecondStrategy]
  );

  const [itemToRename, setItemToRename] = useState<any>();

  const menuActionsHandler = useCallback(
    (action: string, resource) => {
      switch (action) {
        case "actionRun": {
          const id = resource?.strategy1?.id;
          const id2 = resource?.strategy2?.id;
          if (id && id2) {
            onRun(id, id2);
            selectCompareStrategy(resource);
          }
          break;
        }
        case "actionDelete": {
          if (resource && !resource["isReadOnly"]) {
            onDelete(resource);
          }
          break;
        }
        case "actionRename": {
          if (resource) {
            setItemToRename(resource);
          }
          break;
        }
        default: {
          console.warn(`Unknown Action: ${action}`);
        }
      }
    },
    [onDelete, onRun, selectCompareStrategy]
  );

  const wrapPromise = useCallback(
    async (action) => {
      try {
        await action();
      } catch (error: any) {
        let errorMessage = "";
        if (error && error.response.error === "OBJECT_ALREADY_EXISTING") {
          errorMessage = "A strategy with this name already exists.";
        } else if (error) {
          errorMessage = "Unknown error, please retry later.";
        }
        const [channel, msg] = messageError(errorMessage);
        broadcast(channel as string, msg);
      }
    },
    [broadcast]
  );

  const { dispatch } = useEventBus();
  const onSuccess = useCallback(
    (message: string) => {
      const [channel, msg] = messageSuccess(message);
      broadcast(channel as string, msg);
      dispatch("refresh-compare-list");
    },
    [broadcast, dispatch]
  );
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);

  const compareAPI = useMemo(() => {
    return new CompareStrategies(envSetup);
  }, [envSetup]);

  const onRenameAction = useCallback(
    async (name) => {
      wrapPromise(async () => {
        if (itemToRename != null) {
          // If resource is passed as argument use it intead of use the activeCompare state (default behaviour)
          const newCompareStrategy = { ...itemToRename };
          newCompareStrategy["name"] = name;
          const response = await compareAPI.update(newCompareStrategy);
          let successMessage = `<strong>${itemToRename.name}</strong> has been renamed as <strong>${response.name}</strong>`;
          setItemToRename(undefined);
          onSuccess(successMessage);
          if (onRename) {
            onRename();
          }
        }
      });
    },
    [compareAPI, itemToRename, onRename, onSuccess, wrapPromise]
  );

  return (
    <>
      <StrategySummaryModal
        strategyId={strategyToExpand}
        onClose={onCloseStrategySummaryDialog}
      />
      {itemToRename && (
        <DialogSaveComponent
          item={{
            name: itemToRename?.name ?? "",
          }}
          dialogType={"Compare Strategy"}
          onSave={null}
          onSaveAs={null}
          onRename={onRenameAction}
          hide={() => setItemToRename(undefined)}
        />
      )}
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} alignItems={"center"}>
              <label className="CompareStrategies-label" htmlFor={"strategy-1"}>
                Strategy 1
              </label>
              <div data-dojo-attach-point="widgetStrategy1" id="strategy-1">
                <StrategyPicker
                  id={
                    strategiesToCompare.firstStrategyId?.toString() ?? undefined
                  }
                  apiKey="strategies"
                  updateStrategy={setFirstStrategy}
                />
              </div>
              {strategiesToCompare.firstStrategyId && (
                <span
                  className="CompareStrategies-info i-info"
                  onClick={showFirstStrategyDetails}
                ></span>
              )}
            </Box>

            <Box display={"flex"} alignItems={"center"}>
              <label className="CompareStrategies-label" htmlFor={"strategy-1"}>
                Strategy 2
              </label>
              <div data-dojo-attach-point="widgetStrategy1" id="strategy-1">
                <StrategyPicker
                  id={
                    strategiesToCompare.secondStrategyId?.toString() ??
                    undefined
                  }
                  apiKey="strategies"
                  updateStrategy={setSecondStrategy}
                />
              </div>
              {strategiesToCompare.secondStrategyId && (
                <span
                  className="CompareStrategies-info i-info"
                  onClick={showSecondStrategyDetails}
                ></span>
              )}
            </Box>
          </Box>

          <Button
            disabled={
              !strategiesToCompare.firstStrategyId ||
              !strategiesToCompare.secondStrategyId
            }
            onClick={() =>
              onRun(
                strategiesToCompare.firstStrategyId,
                strategiesToCompare.secondStrategyId
              )
            }
          >
            Compare strategies
          </Button>
        </Box>
        <CompareList
          handleListItemClick={selectCompareStrategy}
          handleMenuActions={menuActionsHandler}
        />
      </Box>
    </>
  );
}
