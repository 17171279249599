export const objectsMap = {
  COMBINED_PRODUCT: "Combined systematic product",
  COMBINED_STRATEGY: "Combined strategy",
  PREFERENCE_INDEX: "Strategy",
  RANKING_ABOUT_TARGET: "Rank",
  PORTFOLIO: "Portfolio",
  BASKET: "Basket",
  SYSTEMATIC_PRODUCT: "Systematic product",
  SCREENING: "Screening",
  SECURITY_ANALYSIS: "Security analysis",
  REPORTS_HUB: "Reports Hub",
};

export const handleTitle = (obj) => {
  let page = objectsMap?.[obj.type] ?? "";
  document.title = `${page}${
    "name" in obj && obj.name != null ? `: ${obj.name}` : ""
  }`;
};
