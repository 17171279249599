/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/formatter/SystematicProduct
 * @summary Format systematic product parameters
 *
 */

import { AppEnvironment } from "../../../types/Defaults";
import { Formatter } from "../../utils/Formatter";

import i18n from "../../../i18n";

export class SystematicProductFormatter {
  format: Formatter;
  formatType = "HTML";

  constructor(environment: AppEnvironment) {
    this.format = new Formatter(environment);
  }

  benchmark(value) {
    const formatted = value["name"];

    return formatted;
  }

  currency(value) {
    let formatted = i18n.t("c_unknown");

    switch (value) {
      case "local": {
        formatted = i18n.t("c_currency_local");

        break;
      }
      default: {
        formatted = value;
      }
    }

    return formatted;
  }

  date(value) {
    const formatted = this.format.custom("date", {
      options: {
        format: ["Y", "m", "d"],
        notAvailable: {
          input: null,
          output: "",
        },
        separator: "-",
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });

    return formatted;
  }

  performance(value) {
    const formatted = this.format.custom("number", {
      options: {
        colored: "positive",
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "-",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });

    return formatted;
  }

  /**
   * Format performance (price / total return)
   *
   * @param {string} value - one of 'NONE' or 'REBALANCE'
   *
   * @returns {string} the formatted rebalance frequency
   */
  strategyPerformance(value) {
    let formatted = "";

    switch (value) {
      case "NONE": {
        formatted = i18n.t("page_builder_field_performance_label_price");

        break;
      }
      case "REBALANCE": {
        formatted = i18n.t("page_builder_field_performance_label_total_return");

        break;
      }
      default: {
        formatted = i18n.t("common_unknown");
      }
    }

    return formatted;
  }

  price(value) {
    var formatted = this.format.custom("number", {
      options: {
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });

    return formatted;
  }

  rebalance(value) {
    let formatted = i18n.t("c_unknown");
    switch (value) {
      case "MONTHLY": {
        formatted = i18n.t("c_rebalance_20_days");

        break;
      }
      case "QUARTERLY": {
        formatted = i18n.t("c_rebalance_60_days");

        break;
      }
      case "WEEKLY": {
        formatted = i18n.t("c_rebalance_05_days");

        break;
      }
      default: {
      }
    }

    return formatted;
  }

  formatPercentage(value) {
    return this.format.custom("number", {
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "-",
        },
      },
      output: "HTML",
      value: value,
      valueHelper: null,
    });
  }

  rebalanceInfo(value) {
    const formatDateOptions = {
      format: ["Y", "m", "D"],
      notAvailable: {
        input: null,
        output: "",
      },
      separator: "-",
    };
    let formatted = i18n.t("c_unknown");
    let replacements: any = null;

    switch (value["status"]) {
      case "overdue": {
        replacements = {
          date: this.format.custom("date", {
            options: formatDateOptions,
            output: "HTML",
            value: value["previous"],
            valueHelper: null,
          }),
          days: value["today"] - value["previous"],
        };
        formatted = `Rebalancing was to be done on ${replacements.date} ${replacements.days} days ago, please rebalance
          `;

        break;
      }
      case "rebalance": {
        formatted = i18n.t("w_systematic_product_rebalance_today");

        break;
      }
      case "rebalanced": {
        if (value["next"] - value["today"] !== 0) {
          replacements = {
            date: this.format.custom("date", {
              options: formatDateOptions,
              output: "HTML",
              value: value["next"],
              valueHelper: null,
            }),
            days: value["next"] - value["today"],
          };
          formatted = `Rebalancing is scheduled for ${replacements.date} in ${replacements.days} days`;
        } else {
          formatted = i18n.t("w_systematic_product_rebalanced_today");
        }

        break;
      } // no default
    }

    return formatted;
  }
}
