import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { deepClone } from "../../../../deepClone";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../hooks/useFormatter";
import { useTaxon } from "../../../../hooks/useTaxon";
import { FormOptions } from "../../../trendrating-widgets/form/FormOptions";
import styles from "./AnalisysSecurity.module.scss";
import { Storage } from "./Storage";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "../../../../ErrorBoundary";

const CONSTRAINTS_PRIORITY = {
  etf: [
    {
      property: "etfclass",
      widget: "widgetConstraint1",
    },
    {
      property: "etfgeo",
      widget: "widgetConstraint2",
    },
    {
      property: "country",
      widget: "widgetConstraint3",
    },
  ],
  default: [
    {
      property: "marketcap",
      widget: "widgetConstraint1",
    },
    {
      property: "icb",
      widget: "widgetConstraint3",
    },
    {
      property: "country",
      widget: "widgetConstraint2",
    },
  ],
};

type TabAlternativesProps = {
  value: any;
};

export function TabAlternatives({ value }: TabAlternativesProps) {
  const environment = useEnvironment();
  const taxon = useTaxon();
  const { t } = useTranslation();

  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  const txFields = useMemo(
    () => environment.get("setup")["taxonomyFields"],
    [environment]
  );
  const Options = useMemo(() => {
    return FormOptions;
  }, []);
  const storage = useMemo(
    () => new Storage(environment.get("setup")),
    [environment]
  );

  const getETFClass = useCallback(
    (etfclass) => {
      let data: any = [];

      let node = taxonomies[txFields["ETF"]["etfclass"]][etfclass];

      if (node.parent != null) {
        data.push(node);
        return data.concat(getETFClass(node.parent));
      }

      return data;
    },
    [taxonomies, txFields]
  );

  const renderFilters = useMemo(() => {
    let label: any = null;
    let property: any = null;
    let propertyValue: any = null;
    let _value: any = null;
    let widgetConstraint1: any = null;
    let widgetConstraint2: any = null;
    let widgetConstraint3: any = null;

    const instrument = value;

    if (instrument) {
      switch (instrument["type"]) {
        case "ETF": {
          // constraint 1: Asset class (etfclass)
          property = "etfclass";
          propertyValue = getETFClass(instrument[property]);
          _value = propertyValue[0];
          label = propertyValue[0]["name"];
          let title = "Asset Class: " + label;
          if (_value) {
            label =
              "Asset Class: " +
              "<strong>" +
              propertyValue[propertyValue.length - 1].name +
              " - " +
              label +
              "</strong>";
            title = "Asset Class: ";
            for (let i = propertyValue.length - 1; i >= 0; i--) {
              title = title + propertyValue[i]["name"];
              if (i !== 0) {
                title = title + " - ";
              }
            }

            widgetConstraint1 = {
              checked: true,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }
          // constraint 2: Investment region (etfgeo)
          property = "etfgeo";
          propertyValue =
            taxonomies[txFields["ETF"]["etfgeo"]][instrument[property]];
          _value = propertyValue;
          if (_value) {
            label =
              "Investment Region: <strong>" +
              propertyValue["name"] +
              "</strong>";
            title = "Investment Region: " + propertyValue["name"];

            widgetConstraint2 = {
              checked: true,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }

          // constraint 3: Market (country)
          property = "country";
          propertyValue =
            taxonomies[txFields["security"]["country"]][instrument[property]];
          _value = propertyValue;
          if (_value) {
            label = "listed in <strong>" + _value["name"] + "</strong>";
            title = "Market: " + _value["name"];

            widgetConstraint3 = {
              checked: false,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }

          break;
        }
        default: {
          // constraint 1: Market Cap (marketcap)
          property = "marketcap";
          propertyValue = Options.getOption(
            "MARKET_CAP",
            instrument[property] / 1e6
          );
          _value = propertyValue;
          if (_value) {
            label = "Mkt. Cap.: <strong>" + _value.label + "</strong>";
            let title = "Mkt. Cap.: " + label;

            widgetConstraint1 = {
              checked: true,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }
          // constraint 2: Market (country)
          property = "country";
          propertyValue =
            taxonomies[txFields["security"]["country"]][instrument[property]];
          _value = propertyValue;
          if (_value) {
            label = "Market: <strong>" + _value.name + "</strong>";
            let title = "Market: " + _value.name;

            widgetConstraint2 = {
              checked: true,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }
          // constraint 3: Sector (icb)
          property = "icb";
          var sectorId = taxon.getAncestorAtLevel(
            taxonomies[txFields["security"]["icb"]],
            instrument[property],
            "3 Sector"
          );
          _value = taxonomies[txFields["security"]["icb"]][sectorId];
          if (_value) {
            label = "Industry: <strong>" + _value.name + "</strong>";
            let title = "Industry: " + _value.name;

            widgetConstraint3 = {
              checked: true,
              label: '<span title="' + title + '">' + label + "</span>",
              name: property,
              value: _value,
            };
          }
        }
      }
    }

    return {
      widgetConstraint1,
      widgetConstraint2,
      widgetConstraint3,
    };
  }, [Options, getETFClass, taxon, taxonomies, txFields, value]);

  const [constraints, setConstraints] = useState(renderFilters);
  const [alternatives, setAlternatives] = useState([]);

  const getActiveConstraints = useCallback((constraints) => {
    let acc = 0;

    for (const item of Object.values<any>(constraints)) {
      if (item) {
        if (item.checked === true) {
          acc++;
        }
      }
    }

    return acc;
  }, []);

  const getSearchContext = useCallback(
    (constraints) => {
      const instrument = value;
      var interval;

      var searchContext: any = {
        filters: [
          {
            dimension: "type",
            segments: [instrument["type"]],
          },
        ],
        ranges: [
          {
            dimension: "rc",
            segments: [
              {
                max: 2,
                min: 2,
              },
            ],
          },
          {
            dimension: "pr",
            segments: [
              {
                max: null,
                min: 0,
              },
            ],
          },
        ],
        page: {
          page: 1,
          rows: 99999,
        },
      };

      var constraint1Property = "marketcap";
      var constraint2Property = "country";
      var constraint3Property = "icb";
      if (instrument["type"] === "ETF") {
        constraint1Property = "etfclass";
        constraint2Property = "etfgeo";
        constraint3Property = "country";
        var etfclassId = taxon.getAncestorAtLevel(
          taxonomies[txFields["ETF"]["etfclass"]],
          instrument["etfclass"],
          "1 Industry"
        );
        searchContext["filters"].push({
          dimension: "etfclass",
          segments: [etfclassId],
        });
      }

      if (instrument[constraint1Property]) {
        if (constraints?.widgetConstraint1?.["checked"] === true) {
          if (constraint1Property === "marketcap") {
            interval = Options.getOption(
              "MARKET_CAP",
              instrument[constraint1Property] / 1e6
            );
            searchContext.ranges.push({
              dimension: constraint1Property,
              segments: [
                {
                  max: interval?.le != null ? interval?.le * 1e6 : null,
                  min: interval?.ge != null ? interval?.ge * 1e6 : null,
                },
              ],
            });
          } else {
            searchContext.filters.push({
              dimension: constraint1Property,
              segments: [instrument[constraint1Property]],
            });
          }
        }
      }

      if (instrument[constraint2Property]) {
        if (constraints?.widgetConstraint2?.["checked"] === true) {
          searchContext.filters.push({
            dimension: constraint2Property,
            segments: [instrument[constraint2Property]],
          });
        }
      }

      if (instrument[constraint3Property]) {
        if (constraints?.widgetConstraint3?.["checked"] === true) {
          if (constraint3Property === "icb") {
            var sectorId = taxon.getAncestorAtLevel(
              window.App.taxonomies.ICB,
              instrument[constraint3Property],
              "3 Sector"
            );
            searchContext.filters.push({
              dimension: constraint3Property,
              segments: [sectorId],
            });
          } else {
            searchContext.filters.push({
              dimension: constraint3Property,
              segments: [instrument[constraint3Property]],
            });
          }
        }
      }

      return searchContext;
    },
    [Options, taxon, taxonomies, txFields, value]
  );

  const render = useCallback((value) => {
    setAlternatives(value);
  }, []);

  const dataDisplay = useCallback(
    (data) => {
      render(data);
    },
    [render]
  );

  const relaxConstraints = useCallback(
    async (activeConstraints) => {
      if (activeConstraints > 0) {
        var instrument: any = value;
        var securityType = instrument["type"] === "ETF" ? "etf" : "default";
        var priorities = CONSTRAINTS_PRIORITY[securityType];
        var widget: any = null;
        const currentState = renderFilters;

        for (let i = 0, length = priorities.length; i < length; i++) {
          widget = currentState[priorities[i]["widget"]];

          if (widget["checked"] === true) {
            widget["checked"] = false;

            break;
          }
        }

        setConstraints(currentState);
        const response = await storage.alternatives({
          constraints: getSearchContext(currentState),
          instrument: instrument,
        });

        dataDisplay(response["data"]);
      }
    },
    [dataDisplay, getSearchContext, renderFilters, storage, value]
  );

  const dataPrepare = useCallback(
    (response, activeConstraints, isFirstRender?) => {
      if (
        response["data"].length === 0 &&
        activeConstraints > 0 &&
        isFirstRender === true
      ) {
        relaxConstraints(activeConstraints);
      }

      dataDisplay(response["data"]);
    },
    [dataDisplay, relaxConstraints]
  );

  const dataGet = useCallback(
    async (constraints) => {
      const instrument = value;

      const response = await storage.alternatives({
        constraints: getSearchContext(constraints),
        instrument: instrument,
      });

      const activeConstraints = getActiveConstraints(constraints);

      dataPrepare(response, activeConstraints);
    },
    [dataPrepare, getActiveConstraints, getSearchContext, storage, value]
  );

  const populatePageOnLanding = useCallback(async () => {
    const isFirstRender = true;

    const instrument = value;
    const filters = renderFilters;

    const response = await storage.alternatives({
      constraints: getSearchContext(filters),
      instrument: instrument,
    });

    const activeConstraints = getActiveConstraints(filters);

    dataPrepare(response, activeConstraints, isFirstRender);
  }, [
    dataPrepare,
    getActiveConstraints,
    getSearchContext,
    renderFilters,
    storage,
    value,
  ]);

  const onPageMount = useCallback(() => {
    populatePageOnLanding();
  }, [populatePageOnLanding]);

  const listenerChange = useCallback(
    (
      key: "widgetConstraint1" | "widgetConstraint2" | "widgetConstraint3",
      checked: boolean
    ) =>
      setConstraints((current) => {
        const updated = deepClone(current);

        updated[key].checked = checked;
        dataGet(updated);

        return updated;
      }),
    [dataGet]
  );

  const listenerChangeConstraint1 = useCallback(
    (e, checked) => {
      listenerChange("widgetConstraint1", checked);
    },
    [listenerChange]
  );

  const listenerChangeConstraint2 = useCallback(
    (e, checked) => {
      listenerChange("widgetConstraint2", checked);
    },
    [listenerChange]
  );

  const listenerChangeConstraint3 = useCallback(
    (e, checked) => {
      listenerChange("widgetConstraint3", checked);
    },
    [listenerChange]
  );

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  useEffect(() => {
    setConstraints(renderFilters);
  }, [renderFilters]);

  return (
    <ErrorBoundary fallback={<></>}>
      <Box className={styles.tabAlternatives__main}>
        <Card className={styles.tabAlternatives__main__card}>
          <CardContent className={styles.tabAlternatives__main__card__content}>
            <Box
              className={
                styles.tabAlternatives__main__card__content__switch__container
              }
            >
              <FormGroup row>
                {constraints.widgetConstraint1 != null && (
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={constraints.widgetConstraint1.checked}
                        value={constraints.widgetConstraint1.value}
                        onChange={listenerChangeConstraint1}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: constraints.widgetConstraint1.label,
                        }}
                      ></span>
                    }
                  />
                )}
                {constraints.widgetConstraint2 != null && (
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={constraints.widgetConstraint2.checked}
                        value={constraints.widgetConstraint2.value}
                        onChange={listenerChangeConstraint2}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: constraints.widgetConstraint2.label,
                        }}
                      ></span>
                    }
                  />
                )}
                {constraints.widgetConstraint3 != null && (
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={constraints.widgetConstraint3.checked}
                        value={constraints.widgetConstraint3.value}
                        onChange={listenerChangeConstraint3}
                      />
                    }
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: constraints.widgetConstraint3.label,
                        }}
                      ></span>
                    }
                  />
                )}
              </FormGroup>
            </Box>
            <Box
              className={
                styles.tabAlternatives__main__card__content__scrollable__container
              }
            >
              <div className="tSecurityAnalysisTabMarket-alternatives">
                <ul
                  data-dojo-attach-point="nodeAlternatives"
                  className="tLayout tLayout--wrap tSecurityAnalysisAlternatives"
                >
                  {alternatives.map((alternative) => (
                    <AlternativeItem
                      key={uuidv4()}
                      alternative={alternative}
                      getETFClass={getETFClass}
                    />
                  ))}
                  {alternatives.length < 2 ? (
                    <li className="tSecurityAnalysisAlternative tLayout--width-50-spaced tSecurityAnalysisAlternative--infoBox">
                      <div className="tLayout tLayout--center">
                        {t("w_analysis_security_alternatives_empty")}
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ErrorBoundary>
  );
}

const AlternativeItem = ({ alternative, getETFClass }) => {
  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  const txFields = useMemo(
    () => environment.get("setup")["taxonomyFields"],
    [environment]
  );
  const formatter = useFormatter();
  const storage = useMemo(
    () => new Storage(environment.get("setup")),
    [environment]
  );

  const getIntervalLabel = useCallback(
    (marketcap, useMarketcapAsLabel?) => {
      var intervals: any = [
        {
          left: null,
          right: 300000000,
          checked: false,
          innerHTML: "Micro Cap",
          label: "Micro Cap ($50M-$300M)",
          node: null,
        },
        {
          left: 300000000,
          right: 2000000000,
          checked: false,
          innerHTML: "Small Cap",
          label: "Small Cap ($300M-$2B)",
          node: null,
        },
        {
          left: 2000000000,
          right: 10000000000,
          checked: false,
          innerHTML: "Mid Cap",
          label: "Mid Cap ($2B-$10B)",
          node: null,
        },
        {
          left: 10000000000,
          right: 200000000000,
          checked: false,
          innerHTML: "Large Cap",
          label: "Large Cap ($10B-$200B)",
          node: null,
        },
        {
          left: 200000000000,
          right: null,
          checked: false,
          innerHTML: "Mega Cap",
          label: "Mega Cap (over $200B)",
          node: null,
        },
      ];
      var interval: any = null;
      var label: any = null;

      if (!marketcap) {
        return "";
      }

      if (marketcap >= intervals[0].left && marketcap < intervals[0].right) {
        interval = intervals[0];
      } else if (
        marketcap >= intervals[1].left &&
        marketcap < intervals[1].right
      ) {
        interval = intervals[1];
      } else if (
        marketcap >= intervals[2].left &&
        marketcap < intervals[2].right
      ) {
        interval = intervals[2];
      } else if (
        marketcap >= intervals[3].left &&
        marketcap < intervals[3].right
      ) {
        interval = intervals[3];
      } else if (marketcap >= intervals[4].left) {
        interval = intervals[4];
      }

      if (!interval) {
        interval = {
          innerHTML: formatter.custom("numberBig", {
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: marketcap,
            valueHelper: null,
          }),
        };
      }

      if (useMarketcapAsLabel) {
        label =
          "Mkt. cap.: " +
          formatter.custom("numberBig", {
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: marketcap,
            valueHelper: null,
          });
      } else {
        label = interval.label;
      }

      return '<span title="' + label + '">' + interval.innerHTML + "</span>";
    },
    [formatter]
  );

  const itemNodes = useMemo(() => {
    const nodes: any = {
      type__market: null,
      rating: null,
      price: null,
      chart: null,
    };

    try {
      if (alternative) {
        let market =
          taxonomies[txFields["security"]["country"]][alternative["country"]];
        let country =
          taxonomies[txFields["security"]["domicile"]][alternative["domicile"]];

        let sector =
          taxonomies[txFields["security"]["domicile"]][alternative["icb"]];

        var innerHTML = "";
        switch (alternative["type"]) {
          case "Stock": {
            innerHTML =
              market.id !== country.id
                ? market.name + " (" + country.name + ")"
                : market.name;
            innerHTML +=
              "<br/>" +
              getIntervalLabel(alternative["marketcap"]) +
              "<br/>" +
              (sector ? " " + sector.name : "");
            break;
          }
          case "ETF": {
            innerHTML = market.name;

            market =
              taxonomies[txFields["ETF"]["etfgeo"]][alternative["etfgeo"]];
            sector = getETFClass(alternative["etfclass"]);

            innerHTML +=
              "<br/>" +
              (sector.length === 1
                ? sector[0].name
                : sector[sector.length - 1].name + " " + sector[0].name) +
              " : " +
              market.name;
            break;
          }
          default: {
            innerHTML = alternative["type"] + " : " + market.name;
          }
        }

        nodes["type__market"] = innerHTML;
        nodes["rating"] =
          formatter.html(
            "rc",
            "rating",
            alternative["rc"],
            alternative,
            alternative["type"]
          ) +
          " from " +
          formatter.html(
            "dr",
            "rated_on_iso",
            alternative["dr"],
            null,
            alternative["type"]
          );
        nodes["price"] = formatter.html(
          "vc",
          "price",
          alternative["vc"],
          alternative,
          alternative["type"]
        );

        // chart

        var params = {
          symbol: alternative["symbol"],
        };

        nodes["chart"] = storage.chart(params);
      }

      return nodes;
    } catch (error) {
      return nodes;
    }
  }, [
    alternative,
    formatter,
    getETFClass,
    getIntervalLabel,
    storage,
    taxonomies,
    txFields,
  ]);

  const name = useMemo(
    () => alternative["name"] + " (" + alternative["ticker"] + ")",
    [alternative]
  );

  return (
    <li className="tSecurityAnalysisAlternative tLayout--width-50-spaced">
      <div
        className="tSecurityAnalysisAlternative-name"
        dangerouslySetInnerHTML={{ __html: name }}
      ></div>
      <div className="tLayout">
        <div className="tSecurityAnalysisAlternative-info tLayout--width-40-spaced">
          <div
            className="tSecurityAnalysisAlternative-type-and-market"
            dangerouslySetInnerHTML={{ __html: itemNodes["type__market"] }}
          ></div>
          <div
            className="tSecurityAnalysisAlternative-rating"
            dangerouslySetInnerHTML={{ __html: itemNodes["rating"] }}
          ></div>
          <div
            className="tSecurityAnalysisAlternative-price"
            dangerouslySetInnerHTML={{ __html: itemNodes["price"] }}
          ></div>
        </div>
        <div className="tSecurityAnalysisAlternative-chart-container tLayout--width-60-spaced">
          {itemNodes && itemNodes?.chart != null && (
            <img
              className="tSecurityAnalysisAlternative-chart"
              title={name}
              alt={name}
              src={itemNodes?.chart ?? ""}
            />
          )}
        </div>
      </div>
    </li>
  );
};
