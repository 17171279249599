/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/_TemplateTable
 * @summary Requests for table templates
 *
 */

import { _StoredObjects } from "../_StoredObjects";

export class _TemplateTable extends _StoredObjects {
    /**
     * Saves report template for portfolio
     *
     * @param {object} params - request parameters
     * @param {object} params.configuration - a report configuration
     * @param {number} params.id - if specified it means that the template
     *       already exits and has been modified. Otherwise a new template
     *       will be crated
     * @param {string} params.name - name of the template
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    save(object: any) {
        if (object.id == null) {
            return this.create(object);
        }

        return this.update(object);
    }
}
