/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module components/table/TrendratingTable
 * @summary React Trendrating Table
 *
 * Please add auto-resize columns behaviour here.
 */

import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createRoot } from "react-dom/client";
import {
  ColumnComponent,
  ColumnDefinition,
  TabulatorFull as Tabulator,
  TabulatorFull,
} from "tabulator-tables"; //import Tabulator library
import { useImmer } from "use-immer";
import { ClusterAnalytics } from "../../api/compute/ClusterAnalytics";
import { deepClone } from "../../deepClone";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useResizer } from "../../hooks/useResizer";
import { AppEnvironment } from "../../types/Defaults";
import SecurityChartModal from "../SecurityChartModal/SecurityChartModal";
import { SecurityTooltipForTables } from "../SecurityTooltip/SecurityTooltip";
import { TableTooltip } from "./Tooltip/TableTooltip";

export type TrendratingTableProps = {
  autoResize?: boolean;
  columns: ColumnDefinition[];
  correction?: number;
  data: any[];
  eventCallback?: Function;
  sorting?: any;
  options?: any;
  disableDefaultRowClick?: boolean;
  tooltip?: {
    actions: {
      info: {
        enabled: boolean;
        customField?: string;
        customFormatter?: Function;
        listeners?: Function;
      };
    };
    column?: number;
  };
};

export function reactFormatter(JSX: any) {
  return function customFormatter(
    cell: any,
    formatterParams: any,
    onRendered: (callback: () => void) => void
  ) {
    // cell - the cell component
    // formatterParams - parameters set for the column
    // onRendered - function to call when the formatter has been rendered
    const renderFn = () => {
      const cellEl = cell.getElement();
      if (cellEl) {
        const formatterCell = cellEl.querySelector(".formatterCell");
        if (formatterCell) {
          const CompWithMoreProps = cloneElement(JSX, { cell });
          const root = createRoot(formatterCell);
          root.render(CompWithMoreProps);
        }
      }
    };

    onRendered(renderFn); // initial render only.

    // setTimeout(() => {
    //   renderFn(); // render every time cell value changed.
    // }, 0);
    return '<div class="formatterCell"></div>';
  };
}

const unmountReactFromTable = (tableRef) => {
  const table = tableRef.element;
  const reactNodes = table.querySelectorAll(".formatterCell");
  let root: any = null;

  for (const node of reactNodes) {
    root = createRoot(node);
    root.unmount();
  }
};

const tooltipForMultiHeaderTable = (
  columns,
  customField,
  env,
  enableAddTo,
  setSecurity,
  customLabelFormatter,
  openModal
) => {
  let columnWithTooltip = null;
  let sectionIndex = null;
  let colIndex = null;

  for (const el of columns) {
    if ("columns" in el) {
      for (const item of el?.columns) {
        if (item.field === "ticker" || item.field === "name") {
          columnWithTooltip = item;
          colIndex = el.columns.indexOf(item);
          sectionIndex = columns.indexOf(el);

          break;
        }
      }
    }
  }

  if (columnWithTooltip != null) {
    const updatedCol = overWriteTooltipCellFormatter(
      columnWithTooltip,
      customField,
      env,
      enableAddTo,
      setSecurity,
      customLabelFormatter,
      openModal
    );

    if (sectionIndex != null) {
      const section = columns[sectionIndex];

      if (colIndex != null) {
        section.columns.splice(colIndex, 1, updatedCol);
      }
    }
  }

  return columns;
};

const overWriteTooltipCellFormatter = (
  column,
  customField,
  env,
  enableAddTo,
  setSecurity,
  customLabelFormatter,
  openModal
) => {
  if (column != null) {
    const label = customField ?? column.field;
    return {
      ...column,
      formatter: reactFormatter(
        <SecurityTooltipForTables
          environment={env}
          chartButtonHandler={() => openModal(true)}
          enableAddTo={enableAddTo ?? false}
          setSecurity={setSecurity}
          labelKey={label}
          customFormatter={customLabelFormatter}
        />
      ),
    };
  }
};

const tooltipForSingleHeaderTable = (
  columns,
  customField,
  env,
  enableAddTo,
  setSecurity,
  customLabelFormatter,
  openModal
) => {
  let columnWithTooltip = columns?.find((item) => item.field === "ticker");

  // If no col ticker was found try to search for name col
  columnWithTooltip =
    columnWithTooltip ?? columns?.find((item) => item.field === "name");

  const index = columnWithTooltip ? columns.indexOf(columnWithTooltip) : null;

  if (columnWithTooltip != null) {
    const updatedCol = overWriteTooltipCellFormatter(
      columnWithTooltip,
      customField,
      env,
      enableAddTo,
      setSecurity,
      customLabelFormatter,
      openModal
    );

    if (index != null) {
      columns.splice(index, 1, updatedCol);
    }
  }

  return columns;
};

const handleTooltipGeneration = (
  columns: ColumnDefinition[],
  env,
  enableAddTo,
  openModal,
  setSecurity,
  customField?,
  customLabelFormatter?
) => {
  let hasDoubleHeader = false;

  for (const el of columns) {
    if (
      "columns" in el &&
      el.columns !== undefined &&
      (el.field === "ticker" || el.field === "name")
    ) {
      hasDoubleHeader = true;

      break;
    }
  }

  if (hasDoubleHeader) {
    return tooltipForMultiHeaderTable(
      columns,
      customField,
      env,
      enableAddTo,
      setSecurity,
      customLabelFormatter,
      openModal
    );
  } else {
    return tooltipForSingleHeaderTable(
      columns,
      customField,
      env,
      enableAddTo,
      setSecurity,
      customLabelFormatter,
      openModal
    );
  }
};

export const TrendratingTable = forwardRef(
  (
    {
      autoResize = true,
      columns = [],
      correction = 8,
      data = [],
      eventCallback,
      sorting,
      options = {},
      tooltip,
      disableDefaultRowClick = false,
    }: TrendratingTableProps = {
      disableDefaultRowClick: false,
      autoResize: true,
      columns: [], // Wrap all the columns in a useMemo to avoid the recreation of the object at every render
      correction: 8,
      data: [],
      eventCallback: undefined,
      sorting: undefined,
      options: {}, // Wrap all the options in a useMemo to avoid the recreation of the object at every render
    },
    ref
  ) => {
    const tableRef = useRef<HTMLDivElement>(null);
    const tableInstanceRef = useRef<Tabulator>();
    const [showModal, setShowModal] = useState(false);
    const [security, setSecurity] = useState<any>();
    const [securityForPanel, setSecurityForPanel] = useState<any>();
    const [resizeTrigger, setResizeTrigger] = useState({});
    const env = useEnvironment();
    const showTooltip = useMemo(() => {
      const show = securityForPanel != null;
      return show;
    }, [securityForPanel]);

    /**
     * Expose tabulator instance to parents widget that use trendrating table
     */
    useImperativeHandle(ref, () => ({
      getTabulatorRef: () => tableInstanceRef.current,
    }));

    const callEventCallback = useCallback(
      (type: string, value: any, callback?: Function, e?) => {
        callbackRef.current?.({
          type: type,
          value: value,
        });

        if (type === "rowClick" && disableDefaultRowClick === false) {
          if (e) {
            e.stopPropagation();
          }
          const data = value.getData();
          setSecurityForPanel(data);
        }

        if (callback) {
          callback();
        }
      },
      [disableDefaultRowClick]
    );

    const tableEvents = useMemo(() => {
      return {
        columnMoved: (column, columns) =>
          callEventCallback("columnMoved", {
            column: column,
            columns: columns,
          }),
        columnResized: (column) => callEventCallback("columnResized", column),
        dataLoaded: (data) => callEventCallback("dataLoaded", data),
        dataSorted: (sorters, rows) =>
          callEventCallback("dataSorted", {
            sorters: sorters,
            rows: rows,
          }),
        rowClick: (e, row) => {
          callEventCallback("rowClick", row, undefined, e);
        },
        rowSelected: (row) => callEventCallback("rowSelected", row),
        rowDeselected: (row) => callEventCallback("rowDeselected", row),
        dataChanged: (data) => callEventCallback("dataChanged", data),
        rowDeleted: (row) => callEventCallback("rowDeleted", row),
        rowMouseEnter: (e, row) => callEventCallback("rowMouseEnter", row),
        rowMouseLeave: (e, row) => callEventCallback("rowMouseLeave", row),
        tableBuilt: (e) => callEventCallback("tableBuilt", e),
      };
    }, [callEventCallback]);

    const registerEvents = useCallback(() => {
      const table = tableInstanceRef?.current;
      let key: any = "";

      if (table) {
        for (key in tableEvents) {
          table.on(key, tableEvents[key]);
        }
      }
    }, [tableEvents]);

    const [tableOptions, updateTableOptions] = useImmer<any>({
      ajaxRequesting: function (url, params) {
        return false; // disable ajax call, used to disable all sorting
      },
      columns: [],
      columnHeaderVertAlign: "middle",
      data: [],
      ajaxSorting: true /* disable to enable "local" sort */,
      height: "100%",
      maxHeight: "100%",
      index: "symbol",
      layout: "fitColumns",
      movableColumns: true,
      resizableColumns: true,
      reactiveData: false,
      selectableRows: "highlight",
    });
    const callbackRef = useRef<Function | undefined>(eventCallback);

    useEffect(() => {
      callbackRef.current = eventCallback;
    }, [eventCallback]);

    useResizer({
      correction: correction,
      ref: autoResize ? tableRef : undefined,
      trigger: resizeTrigger,
    });

    useEffect(() => {
      updateTableOptions((draft) => {
        if (options == null) {
          return;
        }
        if (draft == null) {
          return options;
        }
        for (const [key, value] of Object.entries(options)) {
          draft[key] = value;
        }
      });
    }, [options, updateTableOptions]);

    const onTooltipOpen = useCallback((value) => {
      const modalId = "trendrating__table__tooltip";

      const isModalVisible = document.getElementById(modalId) != null;

      if (isModalVisible) {
        setSecurityForPanel(undefined);
      }

      setSecurity(value);
    }, []);

    const setColumns = useCallback(() => {
      const hasTooltip = tooltip?.actions?.info?.enabled;
      let columnsUpdated: any = null;

      if (hasTooltip) {
        columnsUpdated = handleTooltipGeneration(
          columns,
          env,
          false,
          setShowModal,
          onTooltipOpen,
          tooltip?.actions?.info?.customField ?? null,
          tooltip?.actions?.info?.customFormatter ?? null
        );
      }

      return hasTooltip && columnsUpdated ? columnsUpdated : columns;
    }, [
      columns,
      env,
      onTooltipOpen,
      tooltip?.actions?.info?.customField,
      tooltip?.actions?.info?.customFormatter,
      tooltip?.actions?.info?.enabled,
    ]);

    useEffect(() => {
      // Get actual data (before destroying)
      let preparedOptions = tableOptions;

      preparedOptions = {
        ...tableOptions,
        columns: setColumns() ?? [],
        data: data ?? [],
      };

      let internalTableStatus: any = null;

      if (tableInstanceRef.current != null) {
        internalTableStatus = deepClone(
          (tableInstanceRef.current as any).status.getState() ?? null
        );
        // Destroy if there is already an instance, cannot update options
        // if the table is already created... (Tabulator limitation)
        unmountReactFromTable(tableInstanceRef.current);
        tableInstanceRef.current.destroy();
      }

      tableInstanceRef.current = new Tabulator(
        tableRef.current!,
        preparedOptions
      ) as any;

      (tableInstanceRef.current as any)["status"] = new TableStatus(
        tableInstanceRef.current!,
        env.get("setup"),
        internalTableStatus
      );

      tableInstanceRef.current!.on("tableBuilt", () => {
        if (
          tableInstanceRef?.current != null &&
          "setSort" in tableInstanceRef?.current
        ) {
          if (sorting) {
            const columns = preparedOptions.columns;
            if (columns && columns.length) {
              const sortingField = sorting?.field;
              let canApplySort = false;
              for (const column of columns) {
                if (column.field === sortingField) {
                  canApplySort = true;
                } else if (column.columns != null) {
                  if (
                    column.columns.some(
                      (subColumn) => subColumn.field === sortingField
                    ) === true
                  ) {
                    canApplySort = true;
                  }
                }
              }
              if (canApplySort) {
                tableInstanceRef.current?.setSort(
                  sorting?.field,
                  sorting.direction
                );
              }
            }
          }
        }

        if (
          tableInstanceRef?.current &&
          "getSorters" in tableInstanceRef?.current
        ) {
          const currentSort = tableInstanceRef?.current?.getSorters();

          if (currentSort && currentSort.length) {
            const sorter = currentSort?.[0];

            // /* columns needed to sync correctly sorting */
            if (preparedOptions.columns != null && sorter != null) {
              const sortableColumn = preparedOptions.columns.find(
                (column) => column.field === sorter.field
              );
              if (sortableColumn) {
                // Force update of arrow
                tableInstanceRef.current
                  ?.getColumn(sorter.field)
                  ?.getElement()
                  ?.setAttribute("aria-sort", sorter.dir);
              }
            }
          }
        }
      });

      registerEvents();
      setResizeTrigger({});

      return () => {
        tableInstanceRef.current?.destroy();
      };
    }, [data, env, registerEvents, setColumns, sorting, tableOptions]);

    useEffect(() => {
      return () => {
        unmountReactFromTable(tableInstanceRef.current);
        tableInstanceRef.current?.destroy();
        tableInstanceRef.current = undefined;
      };
    }, []);

    const showSecurityChart = useCallback((data) => {
      setSecurity(data);
      setShowModal(true);
    }, []);

    const closeTooltip = useCallback(() => {
      setSecurityForPanel(undefined);
    }, []);

    return (
      <>
        <TableTooltip
          environment={env}
          showTooltip={showTooltip}
          closeTooltip={closeTooltip}
          symbol={securityForPanel?.symbol ?? ""}
          handleChartAppearence={showSecurityChart}
        />
        <SecurityChartModal
          security={security}
          showModal={showModal}
          environment={env}
          onClose={() => setShowModal(false)}
        />
        <div ref={tableRef} />
      </>
    );
  }
);

class TableStatus {
  tableInstance: TabulatorFull;
  clustersAPI: ClusterAnalytics;
  state: any = {};

  constructor(
    table: TabulatorFull,
    environment: AppEnvironment,
    initialState?: any
  ) {
    this.tableInstance = table;
    this.clustersAPI = new ClusterAnalytics(environment);

    if (initialState != null) {
      this.state = initialState;

      if ("avgRow" in this.state) {
        this.tableInstance.on("tableBuilt", () => {
          this.addAvgRow();
        });
      }
    }
  }

  public updateState(key, value) {
    this.state[key] = value;
  }

  public get(key) {
    return this.state?.[key] ?? undefined;
  }

  public clear(key) {
    delete this.state[key];
  }

  public getState() {
    return deepClone(this.state);
  }

  async updateAvgRow(field, universeDefinition) {
    const universe =
      universeDefinition ?? this.get("currentUniverse") ?? undefined;

    if (universe) {
      const analytic = `${field}#avg#false`;
      let response: any = this.clustersAPI
        .createConfiguration()
        .analytics([analytic])
        .universeFromConstraints(universe);

      try {
        response = await response.fetchAnalytics();

        const avgValue = response?.clustersStats?.stats?.["ANY"]?.[analytic];
        const avgRow = this.get("avgRow");

        if (avgRow != null) {
          this.state["avgRow"][field] = avgValue;
        } else {
          this.state["avgRow"] = {};
          this.state["avgRow"][field] = avgValue;
        }

        const rows = this.tableInstance.getRows();
        const firstRow = rows[0];

        if (firstRow.isFrozen()) {
          // The frozen row already exists
          firstRow.update({ [field]: avgValue }).then(() => {
            this.removeCheckbox(firstRow);
          });
        } else {
          this.addAvgRow();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  clearAvgCell(col: ColumnComponent) {
    const avgRow = this.get("avgRow");
    const definition = col!.getDefinition();
    const field = definition.field!;

    if (!avgRow || !(field in avgRow)) {
      return;
    }

    delete avgRow[field];

    let i = 0;

    for (const key in avgRow) {
      if (avgRow[key] !== "Average") {
        i++;
      } else {
        continue;
      }
    }

    const isRowEmpty = i === 0;
    this.tableInstance = col.getTable();

    if (isRowEmpty) {
      // if (!rows.length) {
      //   const cells = col.getCells();
      //   let row: any = null;

      //   for (const cell of cells) {
      //     row = cell.getRow();

      //     if (row.isFrozen()) {
      //       row.delete();
      //       this.clear("avgRow");

      //       break;
      //     }
      //   }
      // } else {
      this.removeAvgRow();
      // }
    } else {
      // if (rows.length) {
      //   for (const r of rows) {
      //     // is the one to remove
      //     if (r.isFrozen()) {
      //       const cell = r.getCell(col);
      //       cell.setValue("");

      //       break;
      //     }
      //   }
      // } else {
      const cells = col.getCells();
      let row: any = null;

      for (const cell of cells) {
        row = cell.getRow();

        if (row.isFrozen()) {
          cell.setValue("");

          break;
        }
        // }
      }
    }
  }

  removeAvgRow() {
    let rowToDelete = this.get("avgRow");

    if (rowToDelete) {
      this.clear("avgRow");
    }

    const rows = this.tableInstance.getRows();

    if (rows.length) {
      for (const r of rows) {
        // is the one to remove
        if (r.isFrozen()) {
          r.delete();
        }
      }
    }
  }

  addAvgRow() {
    const avgRow = {};

    const rows = this.tableInstance.getRows();
    if (rows.length) {
      const rowCells = rows[0].getCells();
      let cField: any = null;
      let cell: any = null;
      const state = this.get("avgRow");
      let fieldOfColsWithAvg: string[] = [];

      for (let i = 0; i < rowCells.length; i++) {
        cell = rowCells[i];
        cField = cell.getField();

        if (cField != null) {
          if (i === 0) {
            avgRow[cField] = "Average";
          } else if (cField in state) {
            avgRow[cField] = state[cField];
            fieldOfColsWithAvg.push(cField);
          } else {
            avgRow[cField] = undefined;
          }
        } else {
          cField = rowCells[i + 1].getField();
          if (cField != null) {
            this.state["avgRow"][cField] = "Average";
          }
        }
      }

      this.tableInstance.addRow(avgRow, true).then((row) => {
        row.freeze();
        this.removeCheckbox(row);
      });
    }
  }

  removeCheckbox(row) {
    const cells = row.getCells();

    const firstCell = cells[0].getElement();

    const checkbox = firstCell.querySelector("input");

    if (checkbox) {
      checkbox.style.display = "none";
    }
  }
}
