import { AdvancedSelector } from "../../../../../../../components/AdvancedSelector/AdvancedSelector";
import { Cluster } from "./ComparisonTableWidget";

type ComparisonTableWidgetSelectorProps = {
  clusterLevel: Cluster;
  setClusterLevel: (segment: Cluster) => void;
  positionsType:
    | "Sector"
    | "Currency"
    | "Commodity"
    | "Index"
    | "Stock"
    | "ETF"
    | "Instrument";
};

export function ComparisonTableWidgetSelector({
  clusterLevel,
  setClusterLevel,
  positionsType,
}: ComparisonTableWidgetSelectorProps) {
  const onSetSelector = (value) => {
    setClusterLevel(value);
  };

  return (
    <AdvancedSelector
      actualValue={clusterLevel}
      setValue={onSetSelector}
      optionKey={positionsType}
    />
  );
}
