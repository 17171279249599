type CreatePortfolioOrBasketProps = {
    createListner: (value: "basket" | "portfolio") => void;
    type: "portfolio" | "basket";
};

export function CreatePortfolioOrBasket({
    createListner,
    type,
}: CreatePortfolioOrBasketProps) {
    const createListListner = (value: "basket" | "portfolio") => {
        createListner(value);
    };

    return (
        <div className="create-portfolio-basket-box">
            {type === "portfolio" ? (
                <button
                    className="tNews-filters-button-save"
                    onClick={() => createListListner("portfolio")}
                >
                    Create Portfolio
                </button>
            ) : (
                <button
                    className="tNews-filters-button-save"
                    onClick={() => createListListner("basket")}
                >
                    Create Basket
                </button>
            )}
        </div>
    );
}
