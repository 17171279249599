import { RefObject, useEffect } from "react";

export const useDraggable = (
    ref: RefObject<HTMLElement>,
    customEventTarget?: RefObject<HTMLElement>
) => {
    useEffect(() => {
        if (ref?.current) {
            const dialog = ref?.current;
            const target = customEventTarget
                ? customEventTarget?.current
                : dialog;
            if (target) {
                target.onmousedown = function (event) {
                    let shiftX =
                        event.clientX - dialog.getBoundingClientRect().left;
                    let shiftY =
                        event.clientY - dialog.getBoundingClientRect().top;

                    dialog.style.position = "absolute";
                    dialog.style.zIndex = "999999";
                    dialog.style.transform = "none";

                    moveAt(event.pageX, event.pageY);

                    // moves the dialog at (pageX, pageY) coordinates
                    // taking initial shifts into account
                    function moveAt(pageX, pageY) {
                        const left = pageX - shiftX;
                        const top = pageY - shiftY;
                        const winHeight = window.innerHeight;
                        const winWidth = window.innerWidth;
                        const dims = dialog.getBoundingClientRect();

                        if (
                            top > 0 &&
                            left > 0 &&
                            left + dims.width < winWidth &&
                            top + dims.height < winHeight
                        ) {
                            dialog.style.left = left + "px";
                            dialog.style.top = top + "px";
                        } else {
                            document.removeEventListener(
                                "mousemove",
                                onMouseMove
                            );
                        }
                    }

                    function onMouseMove(event) {
                        moveAt(event.pageX, event.pageY);
                    }

                    // move the dialog on mousemove
                    document.addEventListener("mousemove", onMouseMove);

                    // drop the dialog, remove unneeded handlers
                    target.onmouseup = function () {
                        document.removeEventListener("mousemove", onMouseMove);
                        target.onmouseup = null;
                    };

                    document.onmouseleave = function () {
                        document.removeEventListener("mousemove", onMouseMove);
                        target.onmouseup = null;
                    };
                };

                target.ondragstart = function () {
                    return false;
                };
            }
        }
    }, [customEventTarget, ref]);
};
