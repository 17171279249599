/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/compute/StrategyTranspiler
 * @summary Transpiles old strategies to the new strategy format.
 *      Converts into params suitable for the new engine.
 *
 */
export class StrategyTranspiler {
    // from app/core/Entity
    static rateScale = {
        2: { label: "A", class: "rate--A", color: "#008000", value: 2 },
        1: { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
        0: {
            label: "-",
            class: "rate--U",
            color: "#efefef",
            value: null,
        }, // in strategy 2018-08-21 (convert)
        "-1": { label: "C", class: "rate--C", color: "#f48400", value: -1 },
        "-2": { label: "D", class: "rate--D", color: "#f00000", value: -2 },
        A: { label: "A", class: "rate--A", color: "#008000", value: 2 },
        B: { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
        C: { label: "C", class: "rate--C", color: "#f48400", value: -1 },
        D: { label: "D", class: "rate--D", color: "#f00000", value: -2 },
        U: { label: "-", class: "rate--U", color: "#efefef", value: null },
    } as const;

    /**
     * Converts a migrated strategy in a strategy suitable for the new
     * engine: 2018-08-21
     */
    static convert(strategyParams2017: any) {
        var S0 = strategyParams2017;

        var S: any = {
            backtesting: {},
            pricing: {},
            strategy: {
                selectionRules: {},
                rank: [],
                weightingRules: {},
                cappingRules: {},
            },
            tracking: null,
            universe: {},
        };

        var oldStrategy = S0.indexContext;

        if ("busId" in oldStrategy) {
            S.busId = oldStrategy.busId;
        }

        if ("hedging" in oldStrategy) {
            S.hedging = {
                constraints: [
                    // not in UI
                    {
                        dimension: "rc",
                        operator: "equals",
                        segments: [-1, -2],
                    },
                ],
                enabled: true,
                leverage: 1, // not in UI
                symbol: oldStrategy.hedging.symbol,
            };
        }

        // 2018-11-27
        // S.trackingDay = oldStrategy.trackingDay;
        if (oldStrategy.trackingDay != null) {
            S.tracking = {
                trackingDay: oldStrategy.trackingDay,
            };
        }
        S.backtesting.includeFromDay = oldStrategy.includeFromDay;
        S.backtesting.reviewClosureType =
            oldStrategy.reviewStrategy.reviewClosureType;
        S.backtesting.reviewGranularity =
            oldStrategy.reviewStrategy.reviewGranularity;
        S.backtesting.reviewPeriodType =
            oldStrategy.reviewStrategy.reviewPeriodType;
        S.backtesting.yearsBack = oldStrategy.yearsBack;

        S.pricing.benchmark = oldStrategy.benchmark;
        S.pricing.inceptionDay = oldStrategy.inceptionDay;
        S.pricing.inceptionValue = oldStrategy.inceptionValue;
        // 2018-11-28 moved in backtesting
        // S.pricing.includeFromDay = oldStrategy.includeFromDay;
        S.pricing.initialCapital = oldStrategy.initialCapital;

        // 2018-11-27 moved in pricing
        // S.strategy.currency = oldStrategy.currency;

        // 2018-11-27 moved in backtesting
        // S.strategy.reviewGranularity = oldStrategy.reviewStrategy.reviewGranularity;

        // 2018-08-27 - START
        S.strategy.currency =
            oldStrategy.currency !== undefined && oldStrategy.currency != null
                ? oldStrategy.currency
                : "local";
        S.strategy.weightingRules.weightCriteria = "EQUAL_WEIGHTED";
        if (
            oldStrategy.reviewStrategy.weightCriteria &&
            oldStrategy.reviewStrategy.weightCriteria ===
                "PROPORTIONAL_MARKETCAP"
        ) {
            S.strategy.weightingRules.weightCriteria = "PROPORTIONAL_MARKETCAP";
        }
        // 2018-08-27 - END
        S.strategy.weightingRules.weightRule =
            oldStrategy.reviewStrategy.weightRule;
        // -------------------------------------------------------------
        // --------------------------------------------------- SELECTION
        // -------------------------------------------------------------
        S.strategy.selectionRules.maxPositions = oldStrategy.maxPositions;

        var oldSelection = oldStrategy.reviewStrategy.clusterStrategy[0];
        S.strategy.selectionRules.constraints = [];
        var constraint: any = {
            dimension: "rc",
            operator: "equals",
            segments: [],
        };
        var oldBuy = oldSelection.buyStrategy;
        for (var r = -2; r <= 2; r++) {
            if (oldBuy[(StrategyTranspiler.rateScale as any)[r]["label"]]) {
                constraint.segments.push(r);
            }
        }
        S.strategy.selectionRules.constraints.push(constraint);

        if (
            "pickingFilters" in oldSelection &&
            oldSelection["pickingFilters"] !== undefined &&
            oldSelection["pickingFilters"] != null
        ) {
            var items = oldSelection.pickingFilters;
            for (let i = 0, length = items.length; i < length; i++) {
                const item = items[i];

                let segment: any = {};

                // <2019-04-15
                if ("left" in item["range"] && item["range"]["left"] != null) {
                    segment[">="] = item["range"]["left"];
                }

                if (
                    "right" in item["range"] &&
                    item["range"]["right"] != null
                ) {
                    segment["<="] = item["range"]["right"];
                }
                // 2019-04-15/>

                constraint = {
                    dimension: item["field"],
                    operator: "range",
                    segments: [
                        segment,
                        // {
                        //     ">=": item["range"]["left"],
                        //     "<=": item["range"]["right"]
                        // }
                    ],
                };

                S.strategy.selectionRules.constraints.push(constraint);
            }
        }

        // 2018-11-28 new syntax
        // var ratingFilters = {
        //     'dimension': 'rc',
        //     'segments': []
        // };
        // var oldBuy = oldSelection.buyStrategy;
        // for (var r = -2; r <= 2; r++) {
        //     //if (oldBuy[theRateLabel(r)])
        //     if (oldBuy[this.rateScale[r]['label']])
        //         ratingFilters.segments.push(r);
        // }
        // S.strategy.selectionRules.filters = [ratingFilters];

        // var f;
        // if (undefined !== oldSelection.pickingFilters) {
        //     var N = oldSelection.pickingFilters.length;
        //     if (N > 0) {
        //         S.strategy.selectionRules.ranges = [];
        //     }
        //     var r;
        //     var minMax;
        //     for (let i = 0; i < N; i++) {
        //         f = oldSelection.pickingFilters[i];
        //         r = { 'dimension': f.field, 'segments': [] };
        //         minMax = {};
        //         if (f.range.left !== undefined) minMax.min = f.range.left;
        //         if (f.range.right !== undefined) minMax.max = f.range.right;
        //         r.segments.push(minMax);
        //         S.strategy.selectionRules.ranges.push(r);
        //     }
        // }
        // -------------------------------------------------------------
        // ----------------------------------------------------- RANKING
        // -------------------------------------------------------------
        if (undefined !== oldStrategy.reviewStrategy.priorities) {
            const oldPriorities = oldStrategy.reviewStrategy.priorities;
            // var pNew;
            for (let i = 0; i < oldPriorities.length; i++) {
                const p = oldPriorities[i];
                if (p.field !== undefined) {
                    p.dimension = p.field;
                    delete p.field;
                }
                if (p.sort !== undefined) {
                    p.rev = p.sort === "desc";
                    delete p.sort;
                }
                if (p.dimension === "ticker") {
                    p.rankType = "string-to-double";
                }
                // pNew = {
                //     'dimension': p.field,
                //     'rev': p.sort == 'desc'
                // };
                // if (pNew.dimension == 'ticker') {
                //     pNew.rankType = 'string-to-double';
                // }
                S.strategy.rank.push(p);
            }
        }
        // -------------------------------------------------------------
        // ---------------------------------------------------- UNIVERSE
        // -------------------------------------------------------------
        S.universe = S0.universe;
        if (oldStrategy.topCut !== undefined) {
            S.universe.search.justInTimeTops = [
                {
                    dimension: oldStrategy.topCut.sort.field,
                    rev: oldStrategy.topCut.sort.sort === "desc",
                    n: oldStrategy.topCut.top,
                },
            ];
        }
        // obsolete universe by collection id
        if (S.universe.collectionId !== undefined) {
            if (undefined === S.universe.search) {
                S.universe.search = {};
            }
            S.universe.search.relations = [
                {
                    range: "COLLECTION",
                    domain: [S.universe.collectionId.toString()],
                },
            ];
            delete S.universe.collectionId;
        }
        if (S.universe.search.page === undefined) {
            S.universe.search.page = {
                page: 1,
                rows: 9999,
            };
        }
        if (S.universe.search.sort === undefined) {
            S.universe.search.sort = {
                dimension: "marketcap",
                rev: true,
            };
        }
        // -------------------------------------------------------------
        // ----------------------------------------------------- CAPPING
        // -------------------------------------------------------------
        if (oldStrategy.reviewStrategy.capValue !== undefined) {
            S.strategy.cappingRules.capValue =
                oldStrategy.reviewStrategy.capValue;
        }
        if (oldStrategy.reviewStrategy.minValue !== undefined) {
            S.strategy.cappingRules.minValue =
                oldStrategy.reviewStrategy.minValue;
        }
        if (
            oldStrategy.reviewStrategy.weightCriteria ===
            "INVERSE_LINEAR_AVAILABLE"
        ) {
            S.strategy.cappingRules.minAllocation = 1.0;
            S.strategy.cappingRules.maxAllocation = 1.0;
        } else {
            S.strategy.cappingRules.minAllocation = 0.0;
            S.strategy.cappingRules.maxAllocation = 1.0;
        }
        // -------------------------------------------------------------
        // --------------------------------------------- CLUSTER FACTORS
        // -------------------------------------------------------------
        if (oldStrategy.reviewStrategy.clusterFactors !== undefined) {
            if (S.strategy.weightingRules.factors === undefined) {
                S.strategy.weightingRules.factors = [];
            }

            S.strategy.weightingRules.factors.push({
                name: "rating",
                dimension: "rc",
                bySegments: {
                    A: parseFloat(
                        oldStrategy.reviewStrategy.clusterFactors
                            .factorBySegments.A
                    ),
                    B: parseFloat(
                        oldStrategy.reviewStrategy.clusterFactors
                            .factorBySegments.B
                    ),
                    C: parseFloat(
                        oldStrategy.reviewStrategy.clusterFactors
                            .factorBySegments.C
                    ),
                    D: parseFloat(
                        oldStrategy.reviewStrategy.clusterFactors
                            .factorBySegments.D
                    ),
                    NA: 1.0,
                },
            });
        }
        // -------------------------------------------------------------
        // ------------------------------------- EXISTING REBALANCE RULE
        // -------------------------------------------------------------
        if (undefined === S.strategy.weightingRules.weightRule) {
            S.strategy.weightingRules.weightRule = "REBALANCE"; // in case of old pure beta it is undefined
        }

        // -------------------------------------------------------------
        // --------------------------------------------- SECTOR ROTATION
        // -------------------------------------------------------------
        if ("peerRotation" in oldStrategy.reviewStrategy) {
            if (S.strategy.weightingRules.factors === undefined) {
                S.strategy.weightingRules.factors = [];
            }

            var _factor: any = {
                name: "rotation",
            };

            if ("capValue" in oldStrategy.reviewStrategy.peerRotation) {
                _factor["capValue"] =
                    oldStrategy.reviewStrategy.peerRotation.capValue;
            }

            if ("countryLevel" in oldStrategy.reviewStrategy.peerRotation) {
                _factor["countryLevel"] =
                    oldStrategy.reviewStrategy.peerRotation.countryLevel;
            }

            if ("metricType" in oldStrategy.reviewStrategy.peerRotation) {
                _factor["metricType"] =
                    oldStrategy.reviewStrategy.peerRotation.metricType;
            }

            if ("sectorLevel" in oldStrategy.reviewStrategy.peerRotation) {
                _factor["sectorLevel"] =
                    oldStrategy.reviewStrategy.peerRotation.sectorLevel;
            }

            S.strategy.weightingRules.factors.push(_factor);

            // 2019-03-04 add to capping rules only if capValue is defined
            if ("capValue" in oldStrategy.reviewStrategy.peerRotation) {
                // 2019-02-05 rotation in capping rules
                S["strategy"]["cappingRules"]["rotation"] = {
                    capValue: _factor["capValue"],
                    countryLevel: _factor["countryLevel"],
                    name: _factor["name"],
                    sectorLevel: _factor["sectorLevel"],
                };
                // END - 2019-02-05 rotation in capping rules
            }
            // END - 2019-03-04 add to capping rules only if capValue is defined
        }

        // 2021-06-04
        // Pricing method - performance price / total return
        S.pricing.method = "none";
        if (oldStrategy.method != null) {
            if (oldStrategy.method === "REBALANCE") {
                S.pricing.method = "rebalance";
            }
        }

        return S;
    }

    /**
     * Migrates a strategy
     *
     * @param {object} storeHouseObject - the storeHouseObject
     *      (PREFERENCE_INDEX) to be migrated
     * @param {string} accountType - one of the account type of
     *      trendrating (e.g. 'INTERNAL')
     */
    static migrate(storeHouseObject: any, accountType: any) {
        // IMPORTANT - Liquidability: currencyVolume is not used anymore.
        // All values are converted in dollars

        var raw = storeHouseObject;
        var strategy = raw.object;
        var universeSearchPageRows = accountType === "INTERNAL" ? 3000 : 1001;

        strategy.tags = raw.tags || null; // NEW tagging support 2021-03-25

        if (strategy["version"] === "2.0") {
            strategy.id = raw.id;
            return strategy;
        }

        if (!("index" in strategy)) {
            // 2019-01-14
            // new strategies have params insted of index
            //
            // temporary workaround
            //
            if ("params" in strategy) {
                strategy["index"] = strategy["params"];
            } else {
                console.warn("Corrupted strategy: ", strategy.id, strategy);
                return null;
            }
        }

        // manage the oldest saved strategies
        if (!("id" in strategy)) {
            strategy.id = raw.id;
        }

        //This check is to avoid problems generated by strategies that are new nbut that are tagged with an old tag
        if ("indexContext" in strategy.index) {
            // old type
            if (strategy.indexType) {
                strategy.entity_type =
                    strategy.indexType === "alpha" ? "ALPHA" : "SMART_BETA";
                delete strategy.indexType;
            }

            // benchmark
            if (!("benchmark" in strategy.index.indexContext)) {
                strategy.index.indexContext.benchmark = null;
            }

            // includeFromDay
            if (!("includeFromDay" in strategy.index.indexContext)) {
                strategy.index.indexContext.includeFromDay = null;
            }

            // tracking day
            if (!strategy.index.indexContext.trackingDay) {
                strategy.index.indexContext.trackingDay = null;
            }

            // busId
            if ("busId" in strategy.index.indexContext) {
                delete strategy.index.indexContext.busId;
            }

            // collectionId
            if ("collectionId" in strategy.index.universe) {
                var collectionId = strategy.index.universe.collectionId;
                strategy.index.universe = {
                    search: {
                        page: {
                            page: 1,
                            rows: universeSearchPageRows,
                        },
                        relations: [
                            {
                                range: "BASKET", //'COLLECTION',
                                domain: [String(collectionId)],
                            },
                        ],
                        sort: {
                            dimension: "marketcap",
                            rev: true,
                        },
                    },
                };
            }

            // buyStrategy
            var rates = ["A", "B", "C", "D", "NA"];
            for (var rate in rates) {
                if (
                    strategy.index.indexContext.reviewStrategy
                        .clusterStrategy[0].buyStrategy[rates[rate]] ===
                    undefined
                ) {
                    strategy.index.indexContext.reviewStrategy.clusterStrategy[0].buyStrategy[
                        rates[rate]
                    ] = false;
                }
            }

            // smart beta: smart beta rules and weight rules
            if (strategy.entity_type === "SMART_BETA") {
                // var valueA =
                //     strategy.index.indexContext.reviewStrategy.clusterFactors
                //         .factorBySegments.A;
                // var valueB =
                //     strategy.index.indexContext.reviewStrategy.clusterFactors
                //         .factorBySegments.B;

                // v1.6.0 - point in time, liquidity, max cash, sector neutral
                strategy.index.indexContext.reviewStrategy.clusterStrategy[0].buyStrategy.NA =
                    false;

                // [2018-09-25] removed: what user decide is what we send to
                // server
                // if (valueA == 1 && valueB == 1) {
                //     // Mkt. Cap. weighted
                //     strategy.index.indexContext.reviewStrategy.weightCriteria = 'PROPORTIONAL_MARKETCAP';
                // }
                // else {
                //     // Equal weighted
                //     strategy.index.indexContext.reviewStrategy.weightCriteria = 'INVERSE_LINEAR_AVAILABLE';
                // }
            }
            // -------------------------------------------------------------
            // priorities [2017-03-15]
            // removing multiple 'exists' rules at the begining keeping those that
            // are in middle
            var areAtTheBeggining = true;
            var existIndexes: any = [];
            // var hasExists = false;
            if ("priorities" in strategy.index.indexContext.reviewStrategy) {
                // get array index of exists rules
                for (
                    let i = 0;
                    i <
                    strategy.index.indexContext.reviewStrategy.priorities
                        .length;
                    i++
                ) {
                    if (
                        areAtTheBeggining &&
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] === "exists"
                    ) {
                        existIndexes.push(i);
                    } else {
                        areAtTheBeggining = false;
                        if (
                            strategy.index.indexContext.reviewStrategy
                                .priorities[i]["field"] === "exists"
                        ) {
                            // hasExists = true;
                        }
                    }
                }
                // removing
                for (let i = 0; i < existIndexes.length; i++) {
                    strategy.index.indexContext.reviewStrategy.priorities.splice(
                        // the length of properties array changes at each remove
                        existIndexes[i] - i,
                        1
                    );
                }
            }

            // keep valid positions mode [2018-09-06]
            if (
                strategy.index.indexContext.reviewStrategy
                    .keepValidPositionsMode &&
                strategy.index.indexContext.reviewStrategy
                    .keepValidPositionsMode === "FILTER_RANK"
            ) {
                // looking for exists rules
                existIndexes = [];
                for (
                    var i = 0,
                        length =
                            strategy.index.indexContext.reviewStrategy
                                .priorities.length;
                    i < length;
                    i++
                ) {
                    if (
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] === "exists"
                    ) {
                        existIndexes.push(i);
                    }
                }
                // removing existing 'exists'
                for (let i = 0; i < existIndexes.length; i++) {
                    strategy.index.indexContext.reviewStrategy.priorities.splice(
                        // the length of properties array changes at each remove
                        existIndexes[i] - i,
                        1
                    );
                }

                strategy.index.indexContext.reviewStrategy.priorities.splice(
                    0,
                    0,
                    {
                        field: "exists",
                        sort: "desc",
                    }
                );

                delete strategy.index.indexContext.reviewStrategy
                    .keepValidPositions;
            }

            // keep valid position (deprecated) [2017-03-15]
            if (strategy.index.indexContext.reviewStrategy.keepValidPositions) {
                strategy.index.indexContext.reviewStrategy.keepValidPositionsMode =
                    "FILTER_RANK";
                // looking for exists rules [2018-09-06]
                existIndexes = [];
                for (
                    let i = 0;
                    i <
                    strategy.index.indexContext.reviewStrategy.priorities
                        .length;
                    i++
                ) {
                    if (
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] === "exists"
                    ) {
                        existIndexes.push(i);
                    }
                }
                // removing existing 'exists'
                for (let i = 0; i < existIndexes.length; i++) {
                    strategy.index.indexContext.reviewStrategy.priorities.splice(
                        // the length of properties array changes at each remove
                        existIndexes[i] - i,
                        1
                    );
                }

                strategy.index.indexContext.reviewStrategy.priorities.splice(
                    0,
                    0,
                    {
                        field: "exists",
                        sort: "desc",
                    }
                );
                // [2018-09-06]

                // [2018-05-02]
                delete strategy.index.indexContext.reviewStrategy
                    .keepValidPositions;
            }

            // [2018-10-25]
            if ("priorities" in strategy.index.indexContext.reviewStrategy) {
                for (
                    let i = 0;
                    i <
                    strategy.index.indexContext.reviewStrategy.priorities
                        .length;
                    i++
                ) {
                    if (
                        "rankTypeParams" in
                        strategy.index.indexContext.reviewStrategy.priorities[i]
                    ) {
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["sort"] = "asc";
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["rankTypeParams"]["n"] = 5;
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["rankTypeParams"]["trimOutlier"] = false;
                    }
                }
            }

            // [2018-10-15] topCut constraint
            // reproducibility [2018-09-06]
            if (strategy.entity_type !== "SMART_BETA") {
                var prioritiesLength =
                    strategy.index.indexContext.reviewStrategy.priorities
                        .length;
                if (
                    strategy.index.indexContext.reviewStrategy.priorities[
                        prioritiesLength - 1
                    ]["field"] !== "ticker" &&
                    "topCut" in strategy.index.indexContext
                ) {
                    strategy.index.indexContext.reviewStrategy.priorities.push({
                        field: "ticker",
                        sort: "asc",
                    });
                }
            }
            // -------------------------------------------------------------

            // START - property renaming [2017-11]
            if (
                "clusterFactors" in strategy.index.indexContext.reviewStrategy
            ) {
                if (
                    strategy.index.indexContext.reviewStrategy.clusterFactors
                        .dimension === "rate"
                ) {
                    strategy.index.indexContext.reviewStrategy.clusterFactors.dimension =
                        "rc";
                }
            }

            if ("priorities" in strategy.index.indexContext.reviewStrategy) {
                var priorities =
                    strategy.index.indexContext.reviewStrategy.priorities;
                var priority;
                for (let i = 0; i < priorities.length; i++) {
                    priority = priorities[i];
                    if (priority["field"] === "rate") {
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] = "rc";
                    }
                    if (priority["field"] === "trmomentum") {
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] = "mc";
                    }
                    if (priority["field"] === "volatility") {
                        strategy.index.indexContext.reviewStrategy.priorities[
                            i
                        ]["field"] = "sd";
                    }
                }
            }
            // END - property renaming [2017-11]

            // START - property renaming [2018-10-11]
            if (
                "pickingFilters" in
                strategy.index.indexContext.reviewStrategy.clusterStrategy[0]
            ) {
                var pickingFilters =
                    strategy.index.indexContext.reviewStrategy
                        .clusterStrategy[0].pickingFilters;
                var filter;
                for (let i = 0; i < pickingFilters.length; i++) {
                    filter = pickingFilters[i];
                    if (filter["field"] === "volatility") {
                        strategy.index.indexContext.reviewStrategy.clusterStrategy[0].pickingFilters[
                            i
                        ]["field"] = "sd";
                    }
                    if (filter["field"] === "pr") {
                        if (
                            strategy.index.indexContext.reviewStrategy
                                .clusterStrategy[0].pickingFilters[i]["range"][
                                "left"
                            ] > 1
                        ) {
                            // 2019-12-20 percentage: from 5 to 0.05
                            strategy.index.indexContext.reviewStrategy.clusterStrategy[0].pickingFilters[
                                i
                            ]["range"]["left"] =
                                strategy.index.indexContext.reviewStrategy
                                    .clusterStrategy[0].pickingFilters[i][
                                    "range"
                                ]["left"] / 100;
                        }
                    }
                }
            }
            // END - property renaming [2018-10-11]

            // [2018-10-17] weightRule
            if (
                strategy.index.indexContext.reviewStrategy.weightRule ===
                "KEEP_BALANCE"
            ) {
                strategy.index.indexContext.reviewStrategy.weightRule =
                    "KEEP_BALANCE_LOOSE";
            }

            // 2021-06-04
            // Pricing method - performance price / total return
            if (strategy.index.indexContext.method == null) {
                strategy.index.indexContext.method = "none";
            }

            strategy.params = strategy.index;
            delete strategy.index;

            // TODO The strategy is migrated but still have the
            // same version. Please check the validateAndConvert to
            // ensure the new version

            return strategy;
        } else {
            strategy.version = "2.0";

            return strategy;
        }
    }

    // check if a strategy has params in the last available format.
    // if not apply params conversion
    static validateAndConvert(strategy: any) {
        if ("indexContext" in strategy["params"]) {
            strategy["params"] = StrategyTranspiler.convert(strategy["params"]);
            // Always update version
            strategy["version"] = "2.0";
        }

        return strategy;
    }
}
