import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../../api/compute/Lists";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";

type StrategyProps = {
  value: {
    blacklist: any;
    benchmark: null | string;
    currency: string;
    holdings: number;
    performance: "NONE" | "REBALANCE";
    rebalance: "05_DAYS" | "20_DAYS" | "60_DAYS";
  };
};

export default function Strategy({ value }: StrategyProps) {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const _benchmark = useMemo(() => value.benchmark, [value.benchmark]);

  const [benchmark, setBenchmark] = useState("");
  useEffect(() => {
    benchmarkFormatter(_benchmark, envSetup, setBenchmark);
    return () => {
      setBenchmark("");
    };
  }, [_benchmark, envSetup]);
  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0 }}>
        <tbody>
          <tr>
            <td>Number of holdings</td>
            <td>
              <strong>{value.holdings}</strong>
            </td>
          </tr>
          <tr>
            <td>Rebalance</td>
            <td
              dangerouslySetInnerHTML={{
                __html: strategyFormat.rebalance(value.rebalance),
              }}
            ></td>
          </tr>
          <tr>
            <td>Currency</td>
            <td
              dangerouslySetInnerHTML={{
                __html: strategyFormat.currency(value.currency),
              }}
            ></td>
          </tr>
          <tr>
            <td>Performance</td>
            <td
              dangerouslySetInnerHTML={{
                __html: strategyFormat.performance(value.performance),
              }}
            ></td>
          </tr>
          <tr>
            <td>Benchmark</td>
            <td>
              <strong>{benchmark}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}

const benchmarkFormatter = (str, envSetup, setInstrument) => {
  if (str != null) {
    const blendedBenchmarkTag = "COLLECTION";
    if (str.includes(blendedBenchmarkTag)) {
      const splitValue = str.split(":");
      const benchmarkId = parseInt(splitValue?.[1]) ?? null;
      getListName(envSetup, benchmarkId).then((data) => setInstrument(data));
    } else if (str === "TRENDRATING_NEUTRAL_STRATEGY") {
      setInstrument("Neutral strategy");
    } else if (str === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED") {
      setInstrument("Neutral strategy equal weighted");
    } else {
      getInstrumentName(envSetup, str).then((data) => {
        setInstrument(data);
      });
    }
  } else {
    // TODO: workaround avoid strategy to be loaded twice
    setTimeout(() => {
      setInstrument("None");
    }, 100);
  }
};

const getInstrumentName = async (envSetup, value) => {
  let tt = new Instruments(envSetup);
  let gg = await tt.fetch({
    properties: [{ date: null, property: "name" }],
    symbols: [value],
    type: "security",
  });
  return gg.data[0].name;
};

const getListName = async (environment, listId) => {
  let tt = new Lists(environment);
  let gg = await tt.get(listId, null);
  return gg.name;
};
