import { useMemo } from "react";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { Properties } from "../../../../../api/Properties";
import { useTranslation } from "react-i18next";

type CompareItemLegendProps = {
  type: string;
};

export function CompareItemLegend({ type }: CompareItemLegendProps) {
  const environment = useEnvironment();
  const { t } = useTranslation();

  const label = useMemo(() => {
    const environmentSetup = environment.get("setup");
    return new Properties({
      properties: environmentSetup["properties"],
    });
  }, [environment]);

  const nodePerformanceSinceLabel = useMemo(() => "Since rated", []);
  const nodePerformanceLastWeekLabel = useMemo(() => {
    return label.get("pw", 0, type);
  }, [label, type]);
  const nodePerformanceLastYTDLabel = useMemo(() => {
    return label.get("pytd", 0, type);
  }, [label, type]);
  const nodePerformanceLastMonthLabel = useMemo(() => {
    return label.get("pm", 0, type);
  }, [label, type]);
  const nodePerformanceLast3MonthsLabel = useMemo(() => {
    return label.get("pq", 0, type);
  }, [label, type]);
  const nodePerformanceLast12MonthsLabel = useMemo(() => {
    return label.get("py", 0, type);
  }, [label, type]);
  const nodeIndustry = useMemo(() => {
    var languageCode = window.App.user.locale;

    return languageCode === "en-uk" ? t("common_industry") : t("common_sector");
  }, [t]);

  return (
    <div className="wSecurityAnalysisTabCompareItem wSecurityAnalysisTabCompareItem-legend">
      <div className="wSecurityAnalysisTabCompareItem-items">
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-ticker"></div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-type">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Type
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-currency">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Currency
          </label>
        </div>
        {type === "Stock" && (
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-industry wSecurityAnalysisTabCompareItem-item--doubleHeight"
            data-dojo-attach-point="nodeSecurityIndustryRow"
          >
            <label
              className="wSecurityAnalysisTabCompareItem-itemLabel"
              dangerouslySetInnerHTML={{ __html: nodeIndustry }}
            ></label>
          </div>
        )}

        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item--header">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Trend
          </label>
        </div>

        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-rate wSecurityAnalysisTabCompareItem-item--doubleHeight">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Rating
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-momentum wSecurityAnalysisTabCompareItem-item--doubleHeight">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Smart momentum
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-retracement wSecurityAnalysisTabCompareItem-item--doubleHeight">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Retracement
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-trendStrength wSecurityAnalysisTabCompareItem-item--doubleHeight">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Trend Strength
          </label>
        </div>

        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item--header">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            Performance
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-since">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceSinceLabel}
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lydt">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceLastYTDLabel}
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lw">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceLastWeekLabel}
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lm">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceLastMonthLabel}
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-l3m">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceLast3MonthsLabel}
          </label>
        </div>
        <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-type">
          <label className="wSecurityAnalysisTabCompareItem-itemLabel">
            {nodePerformanceLast12MonthsLabel}
          </label>
        </div>
      </div>
    </div>
  );
}
