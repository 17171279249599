import { Box } from "@mui/material";
import Search from "../../../../js/app/widgets/ReactSearch/Search";

type IndexEditProps = {
  onSelectIndex: Function;
  value?: any;
};

export default function IndexEdit({ onSelectIndex, value }: IndexEditProps) {
  return (
    <Box>
      <Search
        lookUp="SECURITY"
        value={value ?? null}
        showInstrumentInfoOnSelect={false}
        onSelectInstrument={(e) => {
          if (e?.symbol != null) {
            onSelectIndex(e.symbol);
            return;
          }
          onSelectIndex(null);
        }}
      />
    </Box>
  );
}
