import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { deepClone } from "../../../../../../../../../../../deepClone";
import { useTaxonomyByType } from "../../../../../../../../../../../hooks/useTaxonomyByType";
import { ScreenerPanelProps, TaxonomyFieldRow } from "./ScreenerPanel";
import styles from "./ScreenerPanel.module.scss";

const ScreenerETF = ({
  actualInvUniverse,
  setIsDisabled,
  setScreening,
}: Pick<
  ScreenerPanelProps,
  "actualInvUniverse" | "setScreening" | "setIsDisabled"
>) => {
  const initStateConfiguration = useMemo(
    () =>
      (actualInvUniverse?.instrumentType ?? null) === "etf"
        ? actualInvUniverse
        : {
            eligibility: {
              cardinality: null,
              isEnabled: true,
              sortBy: "desc",
            },
            instrumentType: "etf",
            what: null,
            whereSource: {
              domestic: null,
              foreign: null,
              market: null,
              stockClassification: [],
            },
            whereTarget: {
              domestic: null,
              foreign: null,
              market: null,
              stockClassification: [],
            },
            subtype: [],
          },
    [actualInvUniverse]
  );

  const {
    rootNodeX,
    taxonomiesMapX,
    // taxonomiesMapY,
    rootNodeY,
    advancedNodesSelector,
    getTaxonomyMap,
  } = useTaxonomyByType("ETF");
  const [selectedMarkets, setSelectedMarkets] = useState(
    initStateConfiguration?.whereTarget?.market &&
      initStateConfiguration?.whereTarget?.market.length
      ? initStateConfiguration?.whereTarget?.market
      : [rootNodeX]
  );
  const [selectedSectors, setSelectedSectors] = useState(
    initStateConfiguration?.what && initStateConfiguration?.what.length
      ? initStateConfiguration?.what
      : [rootNodeY]
  );
  // const [marketCapOrder, setMarketCapOrder] = useState(
  //   (initStateConfiguration?.eligibility?.sortBy ?? "desc") === "desc" ? 1 : 2
  // );
  // const [eligibility, setEligibility] = useState(
  //   initStateConfiguration?.eligibility?.cardinality ?? 200
  // );
  // const [isEligibilityValid, setIsEligibilityValid] = useState(true);

  const domicileTaxonomies = useMemo(
    () => getTaxonomyMap("security", "country"),
    [getTaxonomyMap]
  );
  const assetClassTaxonomies = useMemo(
    () => getTaxonomyMap("ETF", "etfclass"),
    [getTaxonomyMap]
  );
  const issuerTaxonomies = useMemo(
    () => getTaxonomyMap("ETF", "subtype"),
    [getTaxonomyMap]
  );
  const domicileRootNode = useMemo(
    () =>
      Object.values<any>(domicileTaxonomies).find(
        (node) => node.parent == null
      ),
    [domicileTaxonomies]
  );

  const [domicile, setDomicile] = useState(
    initStateConfiguration?.whereSource?.market &&
      initStateConfiguration?.whereSource?.market.length
      ? initStateConfiguration?.whereSource?.market
      : [domicileRootNode.id]
  );

  // useEffect(() => {
  //   setIsEligibilityValid(eligibility >= 1 && eligibility <= 5000);
  // }, [eligibility]);

  // const invalidEligibilityMessage = useMemo(() => {
  //   let message = "";

  //   if (!isEligibilityValid) {
  //     if (eligibility < 1) {
  //       message = "Min 1";
  //     } else if (eligibility > 5000) {
  //       message = "Max 5000";
  //     }
  //   }

  //   return message;
  // }, [eligibility, isEligibilityValid]);

  const validLevels = useMemo(
    () => ["1 Industry", "3 Sector", "4 Subsector"],
    []
  );

  const handleSelection = useCallback(
    (selectedValues, field, filterShowInTree, validValues?, type?) => {
      setIsDisabled(false);
      const returningValue = advancedNodesSelector(
        selectedValues[0]?.value?.segments,
        field,
        filterShowInTree,
        validValues,
        type
      );
      return returningValue;
    },
    [advancedNodesSelector, setIsDisabled]
  );
  const [issuer, setIssuer] = useState<any>(
    initStateConfiguration.subtype ?? ["ALL"]
  );

  const selectionCallbakcs = useMemo(
    () => ({
      marketsSelection: (selectedValues) =>
        setSelectedMarkets(handleSelection(selectedValues, "etfgeo", true)),
      assetClassSelector: (selectedValues) =>
        setSelectedSectors(
          handleSelection(selectedValues, "etfclass", true, validLevels)
        ),

      domicileSelector: (selectedValues) =>
        setDomicile(
          handleSelection(selectedValues, "country", true, null, "stock")
        ),
      issuerSelector: (selectedValues) =>
        setIssuer(handleSelection(selectedValues, "subtype", true)),
    }),
    [handleSelection, validLevels]
  );

  useEffect(() => {
    const universe = deepClone(initStateConfiguration);

    // universe["eligibility"]["cardinality"] = eligibility;
    // universe["eligibility"]["sortBy"] = marketCapOrder === 1 ? "desc" : "asc";
    universe["instrumentType"] = "etf";
    universe["what"] =
      selectedSectors.length === 1 && selectedSectors[0] === rootNodeY
        ? []
        : selectedSectors;
    universe["whereSource"]["market"] =
      domicile.length === 1 && domicile[0] === domicileRootNode.id
        ? []
        : domicile;
    if (universe["whereTarget"]) {
      universe["whereTarget"]["market"] =
        selectedMarkets.length === 1 && selectedMarkets[0] === rootNodeX
          ? []
          : selectedMarkets;
    }
    universe["subtype"] =
      issuer.length === 1 && issuer[0] === "WWW" ? [] : issuer;

    setScreening({ type: "SET_SCREENING", payload: universe as any });
  }, [
    initStateConfiguration,
    domicile,
    selectedMarkets,
    selectedSectors,
    setScreening,
    rootNodeY,
    domicileRootNode.id,
    rootNodeX,
    issuer,
  ]);

  const columns = useMemo(
    () => [
      [
        {
          taxonomyId: "ETF_EQ",
          children: [
            [
              { taxonomyId: "ETF_EQ_SR" },
              { taxonomyId: "ETF_EQ_FO" },
              { taxonomyId: "ETF_EQ_SZ" },
            ],
          ],
        },
        { taxonomyId: "ETF_AL" },
      ],
      [
        {
          taxonomyId: "ETF_FI",
          children: [
            [
              { taxonomyId: "ETF_FI_BM" },
              { taxonomyId: "ETF_FI_CO" },
              { taxonomyId: "ETF_FI_GO" },
              { taxonomyId: "ETF_FI_MU" },
              { taxonomyId: "ETF_FI_SO" },
            ],
          ],
        },
      ],
      [
        { taxonomyId: "ETF_AA" },
        { taxonomyId: "ETF_CU" },
        {
          taxonomyId: "ETF_CO",
          children: [
            [{ taxonomyId: "ETF_CO_AG" }, { taxonomyId: "ETF_CO_EN" }],
          ],
        },
      ],
    ],
    []
  );

  return (
    <div className={styles.screener__panel__content}>
      <Box display={"flex"} gap={2} flex={1}>
        <Box>
          <TaxonomyFieldRow
            // label={"Asset Class"}
            handleValuesSelection={selectionCallbakcs.assetClassSelector}
            taxonomyMap={assetClassTaxonomies}
            taxonomyType={"ETF"}
            taxonomyField={"etfclass"}
            selectedValues={selectedSectors}
            validTreeNodes={validLevels}
            columnsDefinition={columns}
          />
        </Box>

        <Box>
          <TaxonomyFieldRow
            // label={"Investment region"}
            handleValuesSelection={selectionCallbakcs.marketsSelection}
            taxonomyMap={taxonomiesMapX}
            selectedValues={selectedMarkets}
            taxonomyType={"ETF"}
            taxonomyField={"etfgeo"}
          />
        </Box>
        <Box>
          <TaxonomyFieldRow
            // label={"Issuer"}
            handleValuesSelection={selectionCallbakcs.issuerSelector}
            taxonomyMap={issuerTaxonomies}
            taxonomyType={"ETF"}
            taxonomyField={"subtype"}
            selectedValues={issuer}
          />
        </Box>

        <Box>
          <TaxonomyFieldRow
            // label={"Market"}
            handleValuesSelection={selectionCallbakcs.domicileSelector}
            taxonomyMap={domicileTaxonomies}
            taxonomyType={"security"}
            taxonomyField={"country"}
            selectedValues={domicile}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ScreenerETF;
