import { MenuItem, Select, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { extractSymbols } from "../../../../../../../../api/compute/commons";
import { widgetsConfiguration } from "../../../../../../widgets/widgetsConfiguration";
import { v4 as uuidv4 } from "uuid";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";

type RatingChangeProps = {
  holdings: any;
};

const OPTIONS = [
  {
    label: "Downgrades",
    value: "D",
  },
  {
    label: "Upgrades",
    value: "U",
  },
  {
    label: "Downgrades and upgrades",
    value: "D_U",
  },
];

export function RatingChange({ holdings }: RatingChangeProps) {
  const [selectValue, setSelectValue] = useState("D");
  const [data, setData] = useState<{
    D: any;
    D_U: any;
    U: any;
  }>({
    D: null,
    D_U: null,
    U: null,
  });

  const environment = useEnvironment();
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );

  const format = useFormatter();
  const values = useMemo(() => {
    return data?.[selectValue] ?? [];
  }, [data, selectValue]);

  const dataGet = useCallback(
    async (what) => {
      if (holdings) {
        var symbols = extractSymbols(holdings);
        var properties =
          widgetsConfiguration[
            "widgets/portfolio/analysis/overview/ratingChange"
          ]["properties"];

        var params = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          sort: {
            dimension: "lr",
            rev: false,
          },
          page: {
            page: 1,
            rows: symbols.length,
          },
        };

        switch (what) {
          case "D": {
            params["ranges"] = [
              {
                dimension: "direction",
                segments: [
                  {
                    max: 0,
                    min: -4,
                  },
                ],
              },
            ];
            break;
          }
          case "U": {
            params["ranges"] = [
              {
                dimension: "direction",
                segments: [
                  {
                    max: 4,
                    min: 0,
                  },
                ],
              },
            ];
            break;
          }
          default: {
            params["ranges"] = [
              {
                dimension: "direction",
                segments: [
                  {
                    max: 4,
                    min: -4,
                  },
                ],
              },
            ];
          }
        }

        params["ranges"].push({
          dimension: "lr",
          segments: [
            {
              max: 19,
              min: 0,
            },
          ],
        });

        const response = await instrumentsAPI.newFilterAndFetch(
          params,
          "security",
          properties
        );

        setData((prev) => {
          const newData = { ...prev };

          newData[what] = response?.data;

          return newData;
        });
      }
    },
    [holdings, instrumentsAPI]
  );

  const onChangeValue = useCallback(
    async (event) => {
      const value = event.target.value;

      if (data?.[value] == null) {
        await dataGet(value);
      }

      setSelectValue(value);
    },
    [data, dataGet]
  );

  const formatRatingChange = useCallback(
    (item) => {
      return [
        format.custom("rating", {
          options: {
            notAvailable: {
              input: 0,
              output: "-",
            },
          },
          output: "HTML",
          value: item["rrr"],
          valueHelper: {
            rateChange: item["lrr"],
            rateDate: item["drr"],
            ratePrev: null,
          },
        }),
        '<span class="i-arrow-r"></span>',
        format.custom("rating", {
          options: {
            notAvailable: {
              input: 0,
              output: "-",
            },
          },
          output: "HTML",
          value: item["rc"],
          valueHelper: {
            rateChange: item["lr"],
            rateDate: item["dr"],
            ratePrev: item["rrr"],
          },
        }),
      ].join(" ");
    },
    [format]
  );

  const formatRcDate = useCallback(
    (item) => {
      return format.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: item["dr"],
        valueHelper: null,
      });
    },
    [format]
  );

  const onComponentMount = useCallback(() => {
    dataGet("D");
  }, [dataGet]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return (
    <div className="tAnalysisOverview tAnalysisOverview--ratingChange">
      <h1 className="tAnalysisOverview-title">
        Recent{" "}
        <Select size="small" value={selectValue} onChange={onChangeValue}>
          {OPTIONS.map((item) => (
            <MenuItem key={uuidv4()} value={item.value}>
              <Typography>{item.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </h1>
      <div className="tAnalysisOverview-scrollable">
        <table className="tAnalysisOverview-table">
          <tbody data-dojo-attach-point="nodeTable">
            {values.map((item) => {
              return (
                <tr key={uuidv4()}>
                  <td
                    className="tAnalysisOverview-ratingChangeRate"
                    dangerouslySetInnerHTML={{
                      __html: formatRatingChange(item),
                    }}
                  ></td>
                  <td className="tAnalysisOverview-ratingChangeName">
                    {item.name}
                  </td>
                  <td
                    className="tAnalysisOverview-ratingChangeDate"
                    dangerouslySetInnerHTML={{ __html: formatRcDate(item) }}
                  ></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
