/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/Taxonomy
 * @summary Utils to manipulate taxons.
 *
 */
export class Taxonomy {
  //
  // taxonomy works with hashmap. It needs to be refactored
  // with trendrating/core/Store
  //
  getAncestorAtLevel(taxonomy: any, taxonId: any, taxonType: any): any {
    if (taxonomy[taxonId] === undefined) {
      return null;
    }

    if (
      taxonomy[taxonId].parent == null ||
      taxonomy[taxonId].type === taxonType
    ) {
      return taxonId;
    }

    return this.getAncestorAtLevel(
      taxonomy,
      taxonomy[taxonId].parent,
      taxonType
    );
  }

  //
  // taxonomy works with hashmap. It needs to be refactored
  // with trendrating/core/Store
  //
  getAncestorByLevel(taxonomy: any, taxonId: any, taxonLevel: any): any {
    var node = taxonomy[taxonId];
    var nodeLevelInt = parseInt(node.type.charAt(0));
    var taxonLevelInt = parseInt(taxonLevel.charAt(0));

    if (nodeLevelInt <= taxonLevelInt || node.parent == null) {
      return taxonId;
    }

    return this.getAncestorByLevel(taxonomy, node.parent, taxonLevel);
  }

  /**
   * ReadOnlySyncStore -> trendrating/core/Store
   *
   * @param {object} whatWhere - parameters
   * @param {string} what - taxonomy code
   * @param {ReadOnlySyncStore} whatTaxonomy - taxonomy
   * @param {string} where - country ISO code
   * @param {ReadOnlySyncStore} whereTaxonomy - markets taxonomy
   *
   * @returns {object} what and where strings
   */
  getWhatAndWhereLabel(whatWhere: any) {
    var value: any = {
      what: null,
      whatType: null,
      where: null,
      whereType: null,
    };

    // where
    var where =
      whatWhere["where"] != null
        ? whatWhere["whereTaxonomy"].find(
            (node) => node.id === whatWhere["where"]
          )
        : null;
    value["where"] = where == null ? null : where["name"];
    value["whereType"] = where == null ? null : where["type"];

    if (where != null && where["type"] === "Region") {
      var whereParent = whatWhere["whereTaxonomy"].find(
        (node) => node.id === where["parent"]
      );
      if (whereParent != null) {
        value["where"] = [whereParent["name"], ":", where["name"]].join("");
      }
    }
    // what
    var what =
      whatWhere["what"] != null
        ? whatWhere["whatTaxonomy"].find(
            (node) => node.id === whatWhere["what"]
          )
        : null;
    value["what"] = what == null ? null : what["name"];
    value["whatType"] = what == null ? null : what["type"];

    return value;
  }
}
