import { useCallback, useContext, useMemo, useState } from "react";
import { FilterTaxonomies } from "../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { deepClone } from "../../../../../../../deepClone";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FiltersContext } from "../../FilterRowContraints";

type DomicileFilterProps = {
    label: string;
};

export function DomicileFilter({ label }: DomicileFilterProps) {
    const context = useContext(FiltersContext);
    const [panelValues, setPanelValues] = useState<string[]>([]);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const taxonomy = useMemo(
        () => ({
            type: label !== "Issuer" ? "security" : "ETF",
            field: label !== "Issuer" ? "domicile" : "subtype",
        }),
        [label]
    );
    const { getTaxonomyMap, advancedNodesSelector } = useTaxonomyByType(
        label !== "Issuer" ? "stock" : "ETF"
    );
    const domicileTaxonomy = useMemo(() => {
        return getTaxonomyMap(taxonomy.type as any, taxonomy.field);
    }, [getTaxonomyMap, taxonomy]);
    const rootNode = useMemo(
        () =>
            Object.values<any>(domicileTaxonomy).find(
                (taxon) => taxon.parent == null
            ),
        [domicileTaxonomy]
    );

    const handleDomicileSelection = useCallback(
        (filtersDirty) => {
            const segments = deepClone(filtersDirty[0].value.segments);
            const filters = advancedNodesSelector(
                segments,
                taxonomy.field,
                true
            );

            const domicileFilter = {
                type: "filters",
                value: {
                    dimension: taxonomy.field,
                    segments: filters,
                },
            };

            if (label === "Issuer") {
                context?.updateIssuer([domicileFilter]);
            } else {
                context?.updateDomicile([domicileFilter]);
            }
        },
        [advancedNodesSelector, context, label, taxonomy.field]
    );

    const activeValues = useMemo(() => {
        const key = label !== "Issuer" ? "domicile" : "issuer";
        if (context && context[key]) {
            const values = context[key];
            const taxonomiesIds: string[] = [];

            if (values) {
                values.forEach((value) => {
                    taxonomiesIds.push(...value.value.segments);
                });

                setPanelValues(taxonomiesIds);
            }

            if (taxonomiesIds.length) {
                setShowCancelButton(true);
                if (taxonomiesIds.length > 3) {
                    const firstSelected =
                        domicileTaxonomy?.[taxonomiesIds[0]]?.name ?? "";
                    return [firstSelected, `+ ${taxonomiesIds.length - 1}`];
                } else {
                    return taxonomiesIds.map(
                        (id) => domicileTaxonomy?.[id]?.name ?? ""
                    );
                }
            }
        }

        setShowCancelButton(false);
        return rootNode?.name ? [rootNode.name] : [];
    }, [context, domicileTaxonomy, label, rootNode.name]);

    const clearFitlers = useCallback(
        (e) => {
            e.stopPropagation();

            if (label !== "Issuer") {
                context?.updateDomicile([]);
            } else {
                context?.updateIssuer([]);
            }
        },
        [context, label]
    );

    return (
        <FilterTaxonomies
            showIconCancel={showCancelButton}
            taxonomyToRender={taxonomy}
            updateFilters={handleDomicileSelection}
            filtersValues={panelValues}
            selectedFilters={activeValues}
            label={label}
            removeFilter={clearFitlers}
        />
    );
}
