export const defaultTemplateReportStrategyTracker = [
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "medium",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_STRATEGY_FACTS",
          content: {
            currency: {
              content: "",
              label: "Currency",
              isEnabled: true,
            },
            customText1: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText2: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText3: {
              content: "",
              label: "",
              isEnabled: false,
            },
            headline: {
              content: "Key Facts",
              isEnabled: true,
            },
            launchDate: {
              content: "",
              label: "Starting Date",
              isEnabled: false,
            },
            numberOfHoldings: {
              content: "",
              label: "Number of holdings",
              isEnabled: false,
            },
            performance: {
              content: "",
              label: "Performance",
              isEnabled: false,
            },
            rebalancingFrequency: {
              content: "",
              label: "Rebalancing frequency",
              isEnabled: true,
            },
            endingDate: {
              content: "",
              label: "As of date",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_STRATEGY_CHART",
          content: {
            benchmark: true,
            headline: {
              content: "Performance Chart",
              isEnabled: true,
            },
            title: {
              content: "",
              isEnabled: true,
            },
          },
          presentation: {
            align: "center",
            logAxis: true,
            legend: true,
            size: "large",
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Performance Details",
              isEnabled: true,
            },
            performance10Years: {
              content: "10 Yr",
              isEnabled: false,
              strict: false,
              annualized: true,
            },
            performance1Day: {
              content: "1 day",
              isEnabled: false,
            },
            performance1Month: {
              content: "1 Mo",
              isEnabled: false,
            },
            performance1Week: {
              content: "1 week",
              isEnabled: false,
            },
            performance1Year: {
              content: "1 Yr",
              isEnabled: false,
              strict: true,
              annualized: true,
            },
            performance3Months: {
              content: "3 Mo",
              isEnabled: false,
            },
            performance3Years: {
              content: "3 Yr",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance5Years: {
              content: "5 Yr",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performanceLTD: {
              content: "Starting date",
              isEnabled: true,
              annualized: true,
            },
            performanceMTD: {
              content: "MTD",
              isEnabled: false,
            },
            performanceQTD: {
              content: "QTD",
              isEnabled: false,
            },
            performanceYTD: {
              content: "YTD",
              isEnabled: false,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
        },
        {
          content: {
            annualized: {
              content: "Annualized",
              isEnabled: true,
            },
            averageYearlyDrawdown: {
              content: "Average yearly drawdown",
              isEnabled: true,
            },
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            beta: {
              content: "Beta",
              isEnabled: true,
            },
            cumulative: {
              content: "Cumulative",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headlineKeyRatio: {
              content: "Key Ratios",
              isEnabled: true,
            },
            headlinePerformance: {
              content: "Performance Summary",
              isEnabled: true,
            },
            headlineRisk: {
              content: "Risk",
              isEnabled: true,
            },
            maxConsecutiveNegativeMonths: {
              content: "Max consecutive negative months",
              isEnabled: false,
            },
            maxConsecutivePositiveMonths: {
              content: "Max consecutive positive months",
              isEnabled: false,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: true,
            },
            monthlyStandardDeviation: {
              content: "Monthly Standard Deviation",
              isEnabled: true,
            },
            oneWayTurnover: {
              content: "One Way Turnover",
              isEnabled: false,
            },
            percentPositiveMonths: {
              content: "% Positive Months",
              isEnabled: false,
            },
            sharpeRatio: {
              content: "Sharpe Ratio",
              isEnabled: true,
            },
            sortinoRatio: {
              content: "Sortino Ratio",
              isEnabled: true,
            },
            sterlingRatio: {
              content: "Sterling Ratio",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            informationRatio: {
              content: "Information Ratio",
              isEnabled: true,
            },
            trackingError: {
              content: "Tracking Error",
              isEnabled: true,
            },
            treynorRatio: {
              content: "Treynor ratio",
              isEnabled: true,
            },
            yearlyAverage: {
              content: "Yearly average",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          type: "REPORT_STRATEGY_KEY_FACTS",
        },
        {
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_SPACING",
        },
        {
          content: {
            firstRows: 10,
            firstType: "MARKET",
            headline: {
              content: "Allocation",
              isEnabled: true,
            },
            secondRows: 10,
            secondType: "INDUSTRY",
            sortByWeight: true,
          },
          presentation: {
            colorByPosition: true,
            first: true,
            second: true,
          },
          sections: null,
          type: "REPORT_COMMON_ALLOCATION",
        },
        {
          content: null,
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_PAGE_BREAK",
        },
        {
          content: {
            annualized: {
              content: "Annualized",
              isEnabled: true,
            },
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Calendar Year Analytics (%)",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: false,
            },
            monthlyStandardDeviation: {
              content: "Monthly return std dev.",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            turnover: {
              content: "One way turnover",
              isEnabled: false,
            },
            yearlyPerformance: {
              content: "Yearly Performance",
              isEnabled: true,
            },
            years: "ROWS",
          },
          presentation: null,
          sections: null,
          type: "REPORT_STRATEGY_YEARLY_ANALYTICS",
        },
        {
          content: {
            lines: 2,
          },
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_SPACING",
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: true,
    name: "Fact sheet ",
    ownerId: null,
    type: "TEMPLATE_REPORT_SYSTEMATIC_PORTFOLIOS",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_STRATEGY_FACTS",
          content: {
            currency: {
              content: "",
              label: "Currency",
              isEnabled: true,
            },
            customText1: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText2: {
              content: "",
              label: "",
              isEnabled: false,
            },
            customText3: {
              content: "",
              label: "",
              isEnabled: false,
            },
            headline: {
              content: "Key Facts",
              isEnabled: true,
            },
            launchDate: {
              content: "",
              label: "Starting Date",
              isEnabled: true,
            },
            numberOfHoldings: {
              content: "",
              label: "Number of holdings",
              isEnabled: false,
            },
            performance: {
              content: "",
              label: "Performance",
              isEnabled: false,
            },
            rebalancingFrequency: {
              content: "",
              label: "Rebalancing frequency",
              isEnabled: true,
            },
            endingDate: {
              content: "",
              label: "As of date",
              isEnabled: false,
            },
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_STRATEGY_CHART",
          content: {
            benchmark: true,
            headline: {
              content: "Hypothetical Performance chart",
              isEnabled: true,
            },
            title: {
              // eslint-disable-next-line no-template-curly-in-string
              content: "${name}",
              isEnabled: false,
            },
          },
          presentation: {
            align: "center",
            logAxis: true,
            legend: true,
            size: "large",
          },
          sections: null,
        },
        {
          content: {
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Performance",
              isEnabled: true,
            },
            performance10Years: {
              content: "10 years",
              isEnabled: false,
              strict: false,
              annualized: true,
            },
            performance1Day: {
              content: "1 day",
              isEnabled: false,
            },
            performance1Month: {
              content: "1 month",
              isEnabled: false,
            },
            performance1Week: {
              content: "1 week",
              isEnabled: false,
            },
            performance1Year: {
              content: "1 year",
              isEnabled: false,
              strict: true,
              annualized: true,
            },
            performance3Months: {
              content: "3 months",
              isEnabled: false,
            },
            performance3Years: {
              content: "3 years",
              isEnabled: true,
              strict: true,
              annualized: true,
            },
            performance5Years: {
              content: "5 years",
              isEnabled: true,
              strict: false,
              annualized: true,
            },
            performanceLTD: {
              content: "Starting date",
              isEnabled: true,
              annualized: true,
            },
            performanceMTD: {
              content: "MTD",
              isEnabled: false,
            },
            performanceQTD: {
              content: "QTD",
              isEnabled: false,
            },
            performanceYTD: {
              content: "YTD",
              isEnabled: false,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          type: "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE",
        },
        {
          content: {
            annualized: {
              content: "Annualized",
              isEnabled: true,
            },
            averageYearlyDrawdown: {
              content: "Average yearly drawdown",
              isEnabled: true,
            },
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            beta: {
              content: "Beta",
              isEnabled: true,
            },
            cumulative: {
              content: "Cumulative",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headlineKeyRatio: {
              content: "Key Ratios",
              isEnabled: true,
            },
            headlinePerformance: {
              content: "Performance Summary",
              isEnabled: true,
            },
            headlineRisk: {
              content: "Risk",
              isEnabled: true,
            },
            maxConsecutiveNegativeMonths: {
              content: "Max consecutive negative months",
              isEnabled: false,
            },
            maxConsecutivePositiveMonths: {
              content: "Max consecutive positive months",
              isEnabled: false,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: true,
            },
            monthlyStandardDeviation: {
              content: "Monthly Standard Deviation",
              isEnabled: true,
            },
            oneWayTurnover: {
              content: "One Way Turnover",
              isEnabled: false,
            },
            percentPositiveMonths: {
              content: "% Positive Months",
              isEnabled: false,
            },
            sharpeRatio: {
              content: "Sharpe Ratio",
              isEnabled: true,
            },
            sortinoRatio: {
              content: "Sortino Ratio",
              isEnabled: true,
            },
            sterlingRatio: {
              content: "Sterling Ratio",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            informationRatio: {
              content: "Information Ratio",
              isEnabled: true,
            },
            trackingError: {
              content: "Tracking Error",
              isEnabled: true,
            },
            treynorRatio: {
              content: "Treynor ratio",
              isEnabled: true,
            },
            yearlyAverage: {
              content: "Yearly average",
              isEnabled: true,
            },
          },
          presentation: null,
          sections: null,
          type: "REPORT_STRATEGY_KEY_FACTS",
        },
        {
          content: {
            firstRows: 8,
            firstType: "MARKET",
            headline: {
              content: "Allocation",
              isEnabled: true,
            },
            secondRows: 8,
            secondType: "INDUSTRY",
            sortByWeight: true,
          },
          presentation: {
            colorByPosition: true,
            first: true,
            second: true,
          },
          sections: null,
          type: "REPORT_COMMON_ALLOCATION",
        },
        {
          content: {
            annualized: {
              content: "Annualized",
              isEnabled: true,
            },
            benchmark: {
              content: "Benchmark",
              isEnabled: true,
            },
            delta: {
              content: "Difference",
              isEnabled: true,
            },
            headline: {
              content: "Calendar Year Analytics (%)",
              isEnabled: true,
            },
            maxDrawdown: {
              content: "Max drawdown",
              isEnabled: false,
            },
            monthlyStandardDeviation: {
              content: "Monthly return std dev.",
              isEnabled: true,
            },
            strategy: {
              content: "Strategy",
              isEnabled: true,
            },
            turnover: {
              content: "One way turnover",
              isEnabled: false,
            },
            yearlyPerformance: {
              content: "Yearly Performance",
              isEnabled: true,
            },
            years: "ROWS",
          },
          presentation: null,
          sections: null,
          type: "REPORT_STRATEGY_YEARLY_ANALYTICS",
        },
        {
          content: null,
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_PAGE_BREAK",
        },
        {
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: null,
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Currency",
                property: "currency",
              },
              {
                label: "Market",
                property: "country",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Performance (local)",
                property: "performance",
              },
              {
                label: "Contribution",
                property: "contribution",
              },
            ],
            rank: false,
            sortByRank: false,
            useWysiwyg: false,
          },
          sections: null,
          type: "REPORT_COMMON_SECURITY_TABLE",
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: true,
    name: "Detailed analytics and holdings",
    ownerId: null,
    type: "TEMPLATE_REPORT_SYSTEMATIC_PORTFOLIOS",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            rate: {
              A: false,
              B: false,
              C: false,
              D: false,
            },
            sortBy: {
              property: "marketcap",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Sector",
                property: "industry",
              },
              {
                label: "Market",
                property: "country",
              },
              {
                label: "Currency",
                property: "currency",
              },
              {
                label: "Contribution",
                property: "contribution",
              },
              {
                label: "Performance",
                property: "performanceCurrency",
              },
            ],
            rank: false,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            text: "The performance of the individual holdings is calculated from the last rebalancing date in the  reference currency.",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: true,
    name: "Holdings",
    ownerId: null,
    type: "TEMPLATE_REPORT_SYSTEMATIC_PORTFOLIOS",
    version: "1.0",
  },
];
