import { Box, Typography } from "@mui/material";

type Props = {
  indexForSelection: number | null;
  selectItem: Function;
  index: any;
  label: any;
  setIndexForSelection: Function;
};

export default function ListItemPeer({
  indexForSelection,
  setIndexForSelection,
  index,
  label,
  selectItem,
}: Props) {
  return (
    <Box
      onMouseOver={() => {
        if (setIndexForSelection) {
          setIndexForSelection(index);
        }
      }}
      onClick={() => selectItem()}
      p={0.5}
      borderRadius={1}
      bgcolor={index === indexForSelection ? "#2a7092" : "white"}
    >
      <Typography color={index === indexForSelection ? "white" : "black"}>
        {label}
      </Typography>
    </Box>
  );
}
