import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Typography,
} from "@mui/material";
import Tippy from "@tippyjs/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lists } from "../../../../../../api/compute/Lists";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

type Props = {
  open: boolean;
  onClickHandler: Function;
  onOptimize: Function;
};
export default function UpgradePositionRatings({
  onClickHandler,
  onOptimize,
  open,
}: Props) {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const listAPI = useMemo(() => new Lists(setup), [setup]);
  const [baskets, setBaskets] = useState<any[]>([]);
  const userId = useMemo(
    () => environment.get("account")?.user?.id,
    [environment]
  );

  const { t } = useTranslation();
  const onOptimizeHandler = useCallback(
    (approach: "WHOLE" | "BASKET", baskedID: number | null) => {
      if (baskedID == null && approach !== "BASKET") {
        onOptimize({
          approach: approach,
          method: "upgradePositionRatings",
          universe: [],
        });
      } else {
        onOptimize({
          approach: approach,
          method: "upgradePositionRatings",
          universe: [baskedID],
        });
      }
    },
    [onOptimize]
  );
  return (
    <Card
      sx={{ boxShadow: 3, backgroundColor: open ? "transparent" : "#f2f2f2" }}
    >
      <CardContent>
        <Box display={"flex"}>
          <Box
            sx={{ cursor: "pointer" }}
            display={"flex"}
            width={open ? "60%" : "100%"}
            flexDirection={"column"}
            onClick={() => {
              onClickHandler();
            }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <i
                style={{ fontSize: "2.5vw", color: open ? "#2A7090" : "#aaa" }}
                className="i-alternative"
              />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ color: open ? "#2A7090" : "#000" }}
                >
                  {open ? (
                    <strong>{t("Upgrade_position_ratings")}</strong>
                  ) : (
                    t("Upgrade_position_ratings")
                  )}
                </Typography>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Sell_D_and_C_rated_positions_swapping_them_to_A_rated"
                      ),
                    }}
                  ></span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {open && (
            <Box width={"40%"}>
              <Card sx={{ boxShadow: 1 }}>
                <CardContent
                  sx={{
                    p: 1,
                    pb: "8px !important",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography>Investment universe</Typography>
                  <Box display={"flex"} gap={1}>
                    <Button onClick={() => onOptimizeHandler("WHOLE", null)}>
                      Whole universe
                    </Button>
                    <Tippy
                      onTrigger={() => {
                        listAPI.get().then((response) => {
                          const _baskets = response.data.filter(
                            (item) => item.type === "BASKET"
                          );
                          _baskets.sort((a, b) => (a.name > b.name ? 1 : -1));
                          setBaskets(_baskets);
                        });
                      }}
                      trigger="click"
                      theme="security-tooltip"
                      interactive
                      content={
                        <Box overflow={"auto"} maxHeight={"50vh"}>
                          {baskets.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.id}
                              onClick={() => {
                                onOptimizeHandler("BASKET", parseInt(item.id));
                              }}
                            >
                              <Typography>
                                {item.name}{" "}
                                {item.ownerId !== userId && (
                                  <span
                                    className={
                                      "sharedObjectIndicator sharedObjectIndicator--small"
                                    }
                                  />
                                )}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Box>
                      }
                    >
                      <Button>From basket</Button>
                    </Tippy>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
