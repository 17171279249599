import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

type TypeNavigatorProps = {
  tabs: { label: string; value: string }[];
  onChangeStrategy: Function;
  value: string;
};

export default function TypeNavigator({
  tabs,
  onChangeStrategy,
  value,
}: TypeNavigatorProps) {
  const [innerValue, setInnerValue] = useState(value);
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setInnerValue(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={innerValue}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        {tabs.map((item, index) => (
          <Tab
            title={"Create New " + item.label}
            sx={{ color: "#000" }}
            //! default color must be black otherwise using grey the tab seems to be disabled
            key={index}
            value={item.value}
            label={item.label}
            onClick={() => {
              setInnerValue(item.value);
              onChangeStrategy({ value: item.value });
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
