/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/input/CollectScreenings
 * @summary Retrieves data to build report
 *
 */

import { ListPosition } from "../../../../types/Api";
import { AppEnvironment } from "../../../../types/Defaults";
import { WysiwygState } from "../Types";

export class CollectScreenings {
    constructor(
        protected readonly environment: AppEnvironment,
        protected readonly wysiwygState: WysiwygState
    ) {}

    async retrieve() {
        const wysiwygState = this.wysiwygState;
        // Check type so later the target is of correct type
        if (wysiwygState.targetType !== "SCREENING") {
            throw new Error("Invalid target type, must be SCREENING");
        }

        const computing: {
            instruments: ListPosition[];
        } = {
            instruments: [],
        };
        const response = {};
        const responseCompute = {};

        return {
            computing,
            response,
            responseCompute,
        };
    }
}
