import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { useBroadcast } from "../../hooks/useBroadcast";
import { config } from "./config-ts";
import { Box } from "@mui/material";

type WorkflowProps = {
  channelInput: string;
};

type WorkflowBarButtonProps = {
  onClickHandler: React.MouseEventHandler<HTMLLIElement>;
  label: string;
  className?: string;
};

export function Workflow({ channelInput }: WorkflowProps) {
  const [actions, setActions] = useState<any>([]);
  const containerRef = useRef<HTMLUListElement>(null);
  const [reactActions, setReactActions] = useState<any>([]);

  const withSubMenu = useMemo(
    () => Array.isArray(reactActions?.[0]),
    [reactActions]
  );

  const { subscribe, unsubscribe } = useBroadcast();

  const updateAction = useCallback((message) => {
    setActions(message.content.actions);
  }, []);

  useLayoutEffect(() => {
    subscribe(config["channels"]["workflow"]["input"], updateAction);

    return () =>
      unsubscribe(config["channels"]["workflow"]["input"], updateAction);
  }, [channelInput, subscribe, unsubscribe, updateAction]);

  useEffect(() => {
    const jsxActions: any = [];

    for (const action of actions) {
      if (Array.isArray(action)) {
        const actionGroup: any = [];
        for (const act of action) {
          actionGroup.push(act.componentJSX);
        }

        jsxActions.push(actionGroup);
      } else if (action.hasOwnProperty("componentJSX")) {
        // When react will be updated to react 18 this method will be deprecated and must be changed into createRoot method
        jsxActions.push(action.componentJSX);
      } else {
        console.log(
          "Cannot placeAt action: The widget is NOT migrated in React and is not a Dojo widget"
        );
      }
    }

    setReactActions(jsxActions);
  }, [actions]);

  return !withSubMenu ? (
    <ul
      className="app__workflow menu menu--horizontal menu--workflow"
      id="app-workflow"
      ref={containerRef}
    >
      {reactActions.map((act) => (
        <Fragment key={uuidv4()}>{act}</Fragment>
      ))}
    </ul>
  ) : (
    <ul className="menu menu--horizontal menu--workflow" id="app-workflow">
      <Box display={"flex"} width={"100%"}>
        {reactActions?.[0]?.length && (
          <Box flex={2} display={"flex"} justifyContent={"center"}>
            {reactActions?.[0]?.map((act) => (
              <Fragment key={uuidv4()}>{act}</Fragment>
            ))}
          </Box>
        )}
        {reactActions?.[1]?.length && (
          <Box
            key={uuidv4()}
            flex={1}
            display={"flex"}
            justifyContent={"center"}
            sx={{ borderLeft: "2px solid #ddd" }}
          >
            {reactActions?.[1]?.map((act) => (
              <Fragment key={uuidv4()}>{act}</Fragment>
            ))}
          </Box>
        )}
      </Box>
    </ul>
  );
}

export const WorkflowBarButton = ({
  onClickHandler,
  label,
  className,
}: WorkflowBarButtonProps) => {
  return (
    <li className={`menu__item ${className ?? ""}`} onClick={onClickHandler}>
      {label}
    </li>
  );
};
