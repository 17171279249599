import { useMemo } from "react";

type DowngradeUpgradeProps = {
  lastMonthUpgrades: number;
  lastMonthDownGrades: number;
};

export function DowngradeUpgrade({
  lastMonthUpgrades,
  lastMonthDownGrades,
}: DowngradeUpgradeProps) {
  const nodeDowngrade = useMemo(() => {
    return lastMonthDownGrades ?? "-";
  }, [lastMonthDownGrades]);

  const nodeUpgrade = useMemo(() => {
    return lastMonthUpgrades ?? "-";
  }, [lastMonthUpgrades]);

  return (
    <div>
      <h1 className="tAnalysisOverview-title">Last 20 days</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: "8px",
        }}
      >
        <div className="tAnalysisOverview-box tAnalysisOverview-box--downgradeUpgrade">
          <span className="format-number format-number--negative">&#9660;</span>
          Downgrades
          <span
            data-dojo-attach-point="nodeDowngrade"
            className="tAnalysisOverview-downgradeUpgradeValue"
          >
            {" "}
            {nodeDowngrade}
          </span>
        </div>
        <div className="tAnalysisOverview-box tAnalysisOverview-box--downgradeUpgrade">
          <span className="format-number format-number--positive">&#9650;</span>
          Upgrades
          <span
            data-dojo-attach-point="nodeUpgrade"
            className="tAnalysisOverview-downgradeUpgradeValue"
          >
            {" "}
            {nodeUpgrade}
          </span>
        </div>
      </div>
    </div>
  );
}
