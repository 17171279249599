import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../deepClone";
import {
  TopBottomPerformerType,
  topBottomPerformerReducer,
} from "./React_TopBottomPerformer_reducer";

type TopBottomPerformerProps = {
  configObj: any;
};

const TopBottomPerformer = forwardRef(({ configObj }: TopBottomPerformerProps, ref) => {
  const initState: TopBottomPerformerType = useMemo(
    () => ({
      content: configObj.widgetValue.content,
      presentation: configObj.widgetValue.presentation,
    }),
    [configObj.widgetValue.content, configObj.widgetValue.presentation]
  );
  const [state, dispatch] = useImmerReducer(topBottomPerformerReducer, initState);



  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state.content;
      tempState.widgetValue.presentation = state.presentation;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        <FormControlLabel
          control={
            <Switch
              checked={state.content.headline.isEnabled}
              onChange={(e) =>
                dispatch({ type: "SET_CONTENT_HEADLINE_ISENABLED", payload: e.target.checked })
              }
              size="small"
            />
          }
          label="Headline"
        />
        <TextField
          disabled={!state.content.headline.isEnabled}
          value={state.content.headline.content}
          onChange={(e) =>
            dispatch({ type: "SET_CONTENT_HEADLINE_CONTENT", payload: e.target.value })
          }
          size="small"
        />
      </Box>

      <RadioGroup
        value={state.content.type}
        onChange={(e) => dispatch({ type: "SET_CONTENT_TYPE", payload: e.target.value })}
      >
        <FormControlLabel value="POSITIVE" control={<Radio />} label=" Positive performers" />
        <FormControlLabel value="NEGATIVE" control={<Radio />} label="Negative performers" />
      </RadioGroup>

      <FormControlLabel
        control={
          <Switch
            checked={state.presentation["05_days"]}
            onChange={(e) => dispatch({ type: "SET_PRESENTATION_05", payload: e.target.checked })}
            size="small"
          />
        }
        label="5 days"
      />
      <FormControlLabel
        control={
          <Switch
            checked={state.presentation["20_days"]}
            onChange={(e) => dispatch({ type: "SET_PRESENTATION_20", payload: e.target.checked })}
            size="small"
          />
        }
        label="20 days"
      />
      <FormControlLabel
        control={
          <Switch
            checked={state.presentation["60_days"]}
            onChange={(e) => dispatch({ type: "SET_PRESENTATION_60", payload: e.target.checked })}
            size="small"
          />
        }
        label="60 days"
      />
      <Box display={"flex"} alignItems={"center"}>
        <Typography>Max rows:</Typography>
        <InputNumber
          initVal={state.content.numberOfItems}
          maxWidth={100}
          isPercentage={false}
          setOuterState={(e) => dispatch({ type: "SET_CONTENT_NUMBERS_OF_ITEMS", payload: e })}
          isFloating={false}
        />
      </Box>
    </Box>
  );
});

export default TopBottomPerformer;
