export class Entity{
    alertScale= {
        down: [
            { label: "", class: "alert1", value: -4 },
            { label: "", class: "alert2", value: -3 },
            { label: "", class: "alert3", value: -2 },
            { label: "", class: "alert4", value: -1 },
        ],
        up: [
            { label: "", class: "alert5", value: 1 },
            { label: "", class: "alert6", value: 2 },
            { label: "", class: "alert7", value: 3 },
            { label: "", class: "alert8", value: 4 },
        ],
        U: { label: "", class: "alertU", value: null },
    }

    /*
     * USD   $   $       &#36;   &#x24;  Dollar Sign
     * GBP   £   &pound; &#163;  &#xA3; 	Pound Sterling
     * JPY   ¥   &yen; 	&#165;  &#xA4; 	Yen Symbol
     * EUR   €   &euro; 	&#128;  &#x80; 	Euro Symbol
     */
    currency = {
        EUR: "&euro;",
        GBP: "&pound;",
        JPY: "&yen;",
        USD: "$",
    }

    rateScale = {
        "2": { label: "A", class: "rate--A", color: "#008000", value: 2 },
        "1": { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
        "0": {
            label: "-",
            class: "rate--U",
            color: "#efefef",
            value: null,
        }, // in strategy 2018-08-21 (convert)
        "-1": { label: "C", class: "rate--C", color: "#f48400", value: -1 },
        "-2": { label: "D", class: "rate--D", color: "#f00000", value: -2 },
        "A": { label: "A", class: "rate--A", color: "#008000", value: 2 },
        "B": { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
        "C": { label: "C", class: "rate--C", color: "#f48400", value: -1 },
        "D": { label: "D", class: "rate--D", color: "#f00000", value: -2 },
        "U": {
            label: "-",
            class: "rate--U",
            color: "#efefef",
            value: null,
        },
    }

    // Trend Capture Rating TCR
    rateScaleExtended = {
        "4": { label: "A", class: "rate--A", color: "#008000", value: 4 },
        "3": { label: "A-", class: "rate--A", color: "#008000", value: 3 },
        "2": { label: "B+", class: "rate--B", color: "#8bbc00", value: 2 },
        "1": { label: "B", class: "rate--B", color: "#8bbc00", value: 1 },
        "0": { label: "B-", class: "rate--B", color: "#8bbc00", value: 0 },
        "-1": {
            label: "C+",
            class: "rate--C",
            color: "#f48400",
            value: -1,
        },
        "-2": { label: "C", class: "rate--C", color: "#f48400", value: -2 },
        "-3": {
            label: "C-",
            class: "rate--C",
            color: "#f48400",
            value: -3,
        },
        "-4": {
            label: "D+",
            class: "rate--D",
            color: "#f00000",
            value: -4,
        },
        "-5": { label: "D", class: "rate--D", color: "#f00000", value: -5 },
        "U": {
            label: "-",
            class: "rate--U",
            color: "#efefef",
            value: null,
        },
    }

    secutityType = {
        stock: { singular: "Stock", plural: "Stocks" },
        sector: { singular: "Sector", plural: "Sectors" },
        cef: { singular: "CEF", plural: "CEFs" },
        etf: { singular: "ETF", plural: "ETFs" },
        index: { singular: "Index", plural: "Indices" },
        currency: { singular: "Currency", plural: "Currencies" },
        commodity: { singular: "Commodity", plural: "Commodities" },
    }

    public  get (value, domain:any) {
        var entity:any = null;
        var flag = domain;
        value = String(value);

        // alert
        var pattern = /^ALERT_/g;
        if (pattern.test(domain)) {
            flag = "ALERT";
        }

        switch (flag) {
            case "ALERT": {
                entity = this.alertScale["U"];
                switch (domain) {
                    case "ALERT_DOWN": {
                        if (value in this.alertScale.down) {
                            entity = this.alertScale.down[value];
                        }
                        break;
                    }
                    case "ALERT_UP": {
                        if (value in this.alertScale.up) {
                            entity = this.alertScale.up[value];
                        }
                        break;
                    } // no default
                }
                break;
            }
            case "RATE": {
                entity = this.rateScale["U"];
                if (value in this.rateScale) {
                    entity = this.rateScale[value];
                }
                break;
            }
            case "RATE_EXTENDED": {
                entity = this.rateScaleExtended["U"];
                if (value in this.rateScaleExtended) {
                    entity = this.rateScaleExtended[value];
                }
                break;
            }
            case "SECURITY_TYPE": {
                entity = this.secutityType[value];
                break;
            } // no default
        }
        return entity;
    }

}