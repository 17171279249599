import { useEffect } from "react";
import { useImmer } from "use-immer";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Checkbox as ReactCheckbox,
  Typography
} from "@mui/material";
import MinMaxInputNumer from "./MinMaxInputNumer";
import { Strategy } from "./editorReducer";

type SmartBetaHoldingsRulesProps = {
  state: Strategy;
  dispatch: Function;
  more?: boolean;
};

export default function SmartBetaHoldingsRules({
  state,
  dispatch,
  more = false,
}: SmartBetaHoldingsRulesProps) {
  const rebalanceFrequency = state?.strategy?.rebalance;

  const internalState = {
    cappingValue:
      state.allocation.weightCappingSecurity?.weightCappedMax ?? 0.01,
    checkBoxStatus:
      state.allocation.weightCappingSecurity != null ? true : false,
  };

  const [cappingState, updateCappingState] = useImmer(internalState);

  useEffect(() => {
    if (!cappingState.checkBoxStatus) {
      dispatch({
        type: "SET_SMART_BETA_CAPPING_CHECKBOX",
        payload: null,
      });
    } else {
      dispatch({
        type: "SET_SMART_BETA_CAPPING_CHECKBOX",
        payload: {
          weightCappedMax: cappingState.cappingValue,
          weightCappedMin: null,
        },
      });
    }
  }, [cappingState, dispatch]);

  const weigthCappedCheckBox =
    state?.allocation?.weightCappingSecurity != null ? true : false;

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">2</span> Holding rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <ReactCheckbox
                    onChange={(e) => {
                      const checked = e.target.checked;
                      updateCappingState((draft) => {
                        draft.checkBoxStatus = checked;
                      });
                    }}
                    checked={weigthCappedCheckBox}
                  />
                }
                label={
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>% weight capped at</Typography>
                  </Box>
                }
              />
            </FormGroup>
            <MinMaxInputNumer
              value={cappingState.cappingValue * 100}
              min={1}
              max={100}
              stateSetter={(value) => {
                updateCappingState((draft) => {
                  draft.cappingValue = value / 100;
                });
              }}
              disabled={!cappingState.checkBoxStatus}
            />
          </Box>
        </li>
        <li className="form__field">
          <FormControl>
            <Typography>Rebalance frequency</Typography>
            <RadioGroup
              value={rebalanceFrequency}
              onChange={(e) => {
                let value = e.target.value;
                dispatch({
                  type: "SET_SMART_BETA_REBALANCE_FREQUENCY",
                  payload: value,
                });
              }}
              row
            >
              <FormControlLabel
                value="05_DAYS"
                control={<Radio />}
                label={<Typography>Weekly</Typography>}
              />
              <FormControlLabel
                value="20_DAYS"
                control={<Radio />}
                label={<Typography>Monthly</Typography>}
              />
              <FormControlLabel
                value="60_DAYS"
                control={<Radio />}
                label={<Typography>Quarterly</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </li>
      </ul>
    </fieldset>
  );
}
