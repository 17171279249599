import { Box, Slider, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

type Props = {
  minValue?: number;
  maxValue?: number;
  step?: number;
  value?: number;
  paddingLeft?: number;
  onChangeValue?: Function;
};

export default function NewSlider({
  minValue = 0,
  maxValue = 100,
  step = 1,
  value = 0,
  paddingLeft = 1,
  onChangeValue,
}: Props) {
  const ValueLabelComponent = useCallback((value) => {
    return (
      <Tooltip enterTouchDelay={0} placement="top" title={value}>
        <Typography>{value}</Typography>
      </Tooltip>
    );
  }, []);

  const [_value, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <Box pl={paddingLeft}>
      <Slider
        aria-label="small"
        defaultValue={value}
        value={_value}
        valueLabelDisplay="auto"
        step={step}
        onChangeCommitted={(e: any, value: any) => {
          if (onChangeValue && e) {
            onChangeValue(parseInt(value));
          }
        }}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        valueLabelFormat={ValueLabelComponent}
        min={minValue}
        max={maxValue}
      />
    </Box>
  );
}
