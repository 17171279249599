import { ReactElement } from "react";
import { deepClone } from "../../../deepClone";
import { FormatRankingSelection } from "./FormatRankingSelection";
export const prepareDataForList = (arrOptions: any[], rules?: any[]) => {
  type ruleObj = {
    content: ReactElement;
  };
  let arr: any[] = [];
  if (rules) {
    for (let i = 0; i < rules?.length; i++) {
      for (let j = 0; j < arrOptions.length; j++) {
        if (rules?.[i].property === arrOptions[j].property.value) {
          const newObj: ruleObj = {
            content: <>{arrOptions[j].property.label}</>,
          };
          arr.push(newObj);
        }
      }
    }
  }

  return arr;
};

export const elementBuilder = (element: any, options: any, _optionsIndex) => {
  try {
    var optionsFunction = options["functions"];
    var optionsOperator = options["operators"];
    var optionsProperty = options["properties"];
    var value = element["field"];
    var indexFunction: any = 0;
    var indexProperty: any = 0;
    var indexOperator: any = 0;

    if (value != null) {
      var optionsIndex = _optionsIndex;
      var _function = value["function"];
      var _operator = value["operator"];
      var _property = value["property"];

      indexProperty = optionsIndex[_property]["property"];
      indexFunction = optionsIndex[_property]["functions"][_function];
      indexOperator =
        optionsIndex[_property]["operators"][indexFunction][_operator];
    }

    // var valueProperty = optionsProperty[indexProperty]["value"];
    var valueFunction = {
      augmentation: value != null ? value["functionParams"] : null,
      subject: optionsFunction[indexProperty][indexFunction]["value"],
    };
    var valueOperator = {
      augmentation: value != null ? value["operatorParams"] : null,
      subject:
        optionsOperator[indexProperty][indexFunction][indexOperator]["value"],
    };

    //definire il contenuto di property
    let outputProperty = <>{optionsProperty[indexProperty].label}</>;

    let outputFunction = builderContentFunction(
      optionsFunction[indexProperty],
      valueFunction
    );
    let outputOperatorPhrase = builderContentOperator(
      optionsOperator[indexProperty][indexFunction],
      valueOperator
    );
    const preparedObj: any = {};
    preparedObj.subject = element["field"].operator;
    preparedObj.augmentation = element["field"].operatorParams;
    var format = new FormatRankingSelection({
      properties: _optionsIndex,
    });

    let innerHTML = format.operator(
      null,
      null,
      preparedObj.subject,
      preparedObj.augmentation
    );

    let outputOperator = (
      <>
        <span>{outputOperatorPhrase}</span>{" "}
        <span dangerouslySetInnerHTML={{ __html: innerHTML }}></span>
      </>
    );

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>{outputProperty}</div>{" "}
        <div>
          {outputFunction} {outputOperator}
        </div>
      </div>
    );
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

/*---------------------------------*/
/*these 3 functions are used to build
the content of the field "Function"*/
/*---------------------------------*/
const builderContentFunction = (optionss, valueFunction) => {
  let options = deepClone(optionss);

  for (let i = 0, length = options.length; i < length; i++) {
    options[i]["value"] = {
      augmentation: null,
      subject: options[i]["value"],
    };
  }
  let _options = {};
  _valueToLabelFunction(_options, options);

  if (optionss.length === 1 && optionss[0]["value"] === "value") {
    return (
      <span style={{ display: "none" }}>
        {_setValueAttrFunction(valueFunction, _options)}
      </span>
    );
  } else {
    return <span>{_setValueAttrFunction(valueFunction, _options)}</span>;
  }
};
const _valueToLabelFunction = (objectLiteral, options) => {
  var option: any = null;
  for (let i = 0, length = options.length; i < length; i++) {
    option = options[i];
    if (
      option.hasOwnProperty("children") &&
      option["children"] !== undefined &&
      option["children"] != null
    ) {
      _valueToLabelFunction(objectLiteral, option["children"]);
    } else {
      objectLiteral[option["value"]["subject"]] = option;
    }
  }
};
const _setValueAttrFunction = (value, _options) => {
  if (_options == null) {
    return;
  }

  var innerHTML = "-";

  switch (value["subject"]) {
    case "quantile": {
      switch (value["augmentation"]["value"]) {
        case 4: {
          innerHTML = "Quartile";
          break;
        }
        case 5: {
          innerHTML = "Quintile";
          break;
        }
        case 10: {
          innerHTML = "Decile";
          break;
        }
        default: {
          innerHTML = "Quantile (" + value["augmentation"]["value"] + ")";
        }
      }
      break;
    }
    case "outlier": {
      const option = _options[value["subject"]];
      if (option["labelLong"]) {
        innerHTML = option["labelLong"];
      } else {
        innerHTML = option["label"];
      }
      if (
        value["augmentation"] != null &&
        value["augmentation"]["value"] != null
      ) {
        var outlierPercentValue = (
          parseFloat(value["augmentation"]["value"]) * 100
        ).toFixed(0);
        innerHTML += " (" + outlierPercentValue + "%)";
      }

      break;
    }
    default: {
      const option = _options[value["subject"]];
      if (option["labelLong"]) {
        innerHTML = option["labelLong"];
      } else {
        innerHTML = /*option["label"];*/ "";
      }
    }
  }
  return innerHTML;
};
/*+++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++*/

/*---------------------------------*/
/*these 3 functions are used to build
the content of the field "Operator"*/
/*---------------------------------*/
const builderContentOperator = (optionss, valueFunction) => {
  let options = deepClone(optionss);

  for (let i = 0, length = options.length; i < length; i++) {
    options[i]["value"] = {
      augmentation: null,
      subject: options[i]["value"],
    };
  }
  let _options = {};
  _valueToLabelOperator(_options, options);

  if (optionss.length === 1 && optionss[0]["value"] !== "value") {
    return "";
  } else {
    return <span>{_setValueAttrOperator(valueFunction, _options)}</span>;
  }
};
const _valueToLabelOperator = (objectLiteral, options) => {
  var option: any = null;
  for (let i = 0, length = options.length; i < length; i++) {
    option = options[i];
    if (
      option.hasOwnProperty("children") &&
      option["children"] !== undefined &&
      option["children"] != null
    ) {
      _valueToLabelFunction(objectLiteral, option["children"]);
    } else {
      objectLiteral[option["value"]["subject"]] = option;
    }
  }
};
const _setValueAttrOperator = (value, _options) => {
  if (_options == null) {
    return;
  }

  var innerHTML = "-";

  switch (value["subject"]) {
    case "quantile": {
      switch (value["augmentation"]["value"]) {
        case 4: {
          innerHTML = "Quartile";
          break;
        }
        case 5: {
          innerHTML = "Quintile";
          break;
        }
        case 10: {
          innerHTML = "Decile";
          break;
        }
        default: {
          innerHTML = "Quantile (" + value["augmentation"]["value"] + ")";
        }
      }
      break;
    }
    case "outlier": {
      const option = _options[value["subject"]];
      if (option["labelLong"]) {
        innerHTML = option["labelLong"];
      } else {
        innerHTML = option["label"];
      }
      if (
        value["augmentation"] != null &&
        value["augmentation"]["value"] != null
      ) {
        var outlierPercentValue = (
          parseFloat(value["augmentation"]["value"]) * 100
        ).toFixed(0);
        innerHTML += " (" + outlierPercentValue + "%)";
      }

      break;
    }
    default: {
      const option = _options[value["subject"]];
      if (option["labelLong"]) {
        innerHTML = option["labelLong"];
      } else {
        innerHTML = option["label"];
      }
    }
  }
  return innerHTML;
};
/*+++++++++++++++++++++++++++++++++++++++++++++++++++*/
/*+++++++++++++++++++++++++++++++++++++++++++++++++++*/

//this func return the object with 3 keys:
//functions,operators,properties
export const setOptionsAttr = (options: any) => {
  // let opt: any;
  if (options == null) {
    // opt = null;
    return;
  }

  // opt = options;

  var sourceOptions = deepClone(options);
  let _options: any = {
    functions: [],
    operators: [],
    properties: [],
  };
  let _optionsIndex: any = {};

  var option: any = null;
  var key: any = null;
  var _operatorOptionIndex: any = null;
  for (let i = 0, lengthI = sourceOptions.length; i < lengthI; i++) {
    option = sourceOptions[i];
    key = option["property"]["value"];
    // properties
    _options["properties"].push(option["property"]);
    _optionsIndex[key] = {
      functions: {},
      operators: [],
      property: i,
      value: option["value"],
    };
    // functions
    _options["functions"][i] = [];
    for (var j = 0, lengthJ = option["functions"].length; j < lengthJ; j++) {
      // enable augmented behaviour in select dropdown
      if ("widget" in option["functions"][j]) {
        option["functions"][j]["type"] = "augmented";
      }

      _options["functions"][i].push(option["functions"][j]);

      _optionsIndex[key]["functions"][option["functions"][j]["value"]] = j;
    }
    // operators
    _options["operators"][i] = [];
    for (let j = 0, lengthJ = option["operators"].length; j < lengthJ; j++) {
      _options["operators"][i].push(option["operators"][j]);

      _operatorOptionIndex = {};
      for (
        var k = 0, lengthK = option["operators"][j].length;
        k < lengthK;
        k++
      ) {
        _operatorOptionIndex[option["operators"][j][k]["value"]] = k;

        // enable augmented behaviour in select dropdown
        if ("widget" in option["operators"][j][k]) {
          option["operators"][j][k]["type"] = "augmented";
        }
      }
      _optionsIndex[key]["operators"].push(_operatorOptionIndex);
    }
  }
  return { _options: _options, _optionsIndex: _optionsIndex };
};

export const _valueGet = (
  wizzardInput,
  propertyId,
  functionId,
  operatorId,
  _optionsIndex,
  _options
) => {
  var value = wizzardInput;

  if (value == null) {
    value = {
      field: {
        function: null,
        functionParams: null,
        operator: null,
        operatorParams: null,
        property: null,
      },
    };
  }

  if (propertyId != null) {
    // ------------------------------------------------ property
    var propertyIndex = _optionsIndex[propertyId]["property"];
    var defaultValue = _optionsIndex[propertyId]["value"];
    // ------------------------------------------------ function
    var functionIndex = 0;
    var functionItem = _options["functions"][propertyIndex][functionIndex];
    functionId =
      functionId != null
        ? functionId
        : defaultValue != null
        ? defaultValue["function"]
        : null;
    if (functionId != null) {
      functionIndex = _optionsIndex[propertyId]["functions"][functionId];
      functionItem = _options["functions"][propertyIndex][functionIndex];
    }
    var functionParams: any = {
      augmentation: null,
      subject: functionItem["value"],
    };
    if (functionItem["type"] === "augmented") {
      functionParams["augmentation"] = {
        value: functionItem["widget"]["value"],
      };
    }
    // ------------------------------------------------ operator
    var operatorIndex = 0;
    var operatorItem =
      _options["operators"][propertyIndex][functionIndex][operatorIndex];
    operatorId =
      operatorId != null
        ? operatorId
        : defaultValue != null && functionId === defaultValue["function"]
        ? defaultValue["operator"]
        : null;
    if (operatorId != null) {
      operatorIndex =
        _optionsIndex[propertyId]["operators"][functionIndex][operatorId];
      operatorItem =
        _options["operators"][propertyIndex][functionIndex][operatorIndex];
    }

    /*
    !-------------------------------------------------------------------
    ! operatorParams must be setted depending on wizzardInput["editing"]
    !-------------------------------------------------------------------
    */
    var operatorParams: any = {};
    if (wizzardInput["editing"]) {
      if (functionItem["value"] === wizzardInput["field"]["function"]) {
        if (functionItem["value"] === "value") {
          operatorParams = {
            augmentation: {
              value: wizzardInput["field"]["operatorParams"]["value"],
            },
            subject: operatorItem["value"],
          };
        } else {
          operatorParams = {
            augmentation: {
              value:
                wizzardInput["field"]["operatorParams"]["augmentation"][
                  "value"
                ],
            },
            subject: wizzardInput["field"]["subject"],
          };
        }
      } else {
        operatorParams = {
          augmentation: {
            value: operatorItem["widget"]["value"],
          },
          subject: operatorItem["value"],
        };
      }
    } else {
      operatorParams = {
        augmentation: {
          value:
            defaultValue != null &&
            defaultValue["operator"] === operatorItem["value"] &&
            defaultValue["operatorParams"] != null
              ? defaultValue["operatorParams"]
              : operatorItem["widget"]["value"],
          // wizzardInput["field"]["operatorParams"]["augmentation"]["value"],
        },
        subject: operatorItem["value"],
      };
    }

    // if (
    //   /*wizzardInput["field"]["operatorParams"]["value"]*/ functionItem["value"] === "value" &&
    //   wizzardInput["editing"]
    // ) {
    //   operatorParams = {
    //     augmentation: {
    //       value: wizzardInput["field"]["operatorParams"]["value"],
    //     },
    //     subject: operatorItem["value"],
    //   };
    // } else if (wizzardInput["editing"] && functionItem["value"] !== "value") {
    //   operatorParams = {
    //     augmentation: {
    //       value:
    //         defaultValue != null &&
    //         defaultValue["operator"] === operatorItem["value"] &&
    //         defaultValue["operatorParams"] != null
    //           ? defaultValue["operatorParams"]
    //           : // : operatorItem["widget"]["value"],
    //             wizzardInput["field"]["operatorParams"]["augmentation"]["value"],
    //     },
    //     subject: operatorItem["value"],
    //   };
    // } else {
    //   operatorParams = {
    //     augmentation: {
    //       value:
    //         defaultValue != null &&
    //         defaultValue["operator"] === operatorItem["value"] &&
    //         defaultValue["operatorParams"] != null
    //           ? defaultValue["operatorParams"]
    //           : operatorItem["widget"]["value"],
    //       // wizzardInput["field"]["operatorParams"]["augmentation"]["value"],
    //     },
    //     subject: operatorItem["value"],
    //   };
    // }
    //!!--------------------------------------
    //!!--------------------------------------

    value = {
      showOperatorSection: wizzardInput["showOperatorSection"],
      showValueSection: wizzardInput["showValueSection"],
      showRangeQuantile: wizzardInput["showRangeQuantile"],
      field: {
        function: functionItem["value"],
        functionParams: functionParams,
        operator: operatorItem["value"],
        operatorParams: operatorParams,
        property: propertyId,
      },
      index: wizzardInput.index,
      editing: wizzardInput["editing"],
    };
  }

  let vv = deepClone(value);
  if (propertyId) {
    //getting the keys of obj that contains the possible selectables functions and operators
    vv["optionsFunction"] = Object.keys(_optionsIndex[propertyId]["functions"]);
    vv["optionsOperator"] = Object.keys(
      _optionsIndex[propertyId]["operators"][0]
    );

    if (vv["optionsFunction"].length <= 1) {
      vv["showFunctionSection"] = false;
    }
    if (vv["optionsOperator"].length <= 1) {
      vv["showOperatorSection"] = false;
    }
    if (["quartile", "quintile", "decile"].includes(vv["field"]["function"])) {
      vv["showValueSection"] = false;
    }
  }
  return vv;
};

export const _valueSet = (input, setterInput, key, value, params) => {
  params = params == null ? null : params;
  var keyParams = key + "Params";
  var clonedInput = deepClone(input);
  clonedInput["field"][key] = value;
  if (key !== "property") {
    clonedInput["field"][keyParams] = params;
  }
  setterInput(clonedInput);
};

export const decodeData = (obj) => {
  // const propertyId = objToDecode["field"]["property"];
  let objToDecode = deepClone(obj);
  const functionId = objToDecode["field"]["function"];
  // const operatorId = objToDecode["field"]["operator"];

  switch (functionId) {
    case "quartile":
    case "quintile":
    case "decile":
      objToDecode["field"]["function"] = "quantile";
      objToDecode["field"]["operator"] =
        objToDecode["field"]["operatorParams"]["subject"];

      break;
    case "value":
      //nothing
      break;
    default:
      objToDecode["field"]["function"] = null;
      break;
  }

  if (objToDecode["field"]["functionParams"] != null) {
    objToDecode["field"]["functionParams"] =
      objToDecode["field"]["functionParams"]["augmentation"];
  }

  objToDecode["field"]["operatorParams"] =
    objToDecode["field"]["operatorParams"]["augmentation"];

  // console.log("decoded:::::", objToDecode);
  return objToDecode;
};

export const prepareDataForEditorPreview = (_value, options) => {
  let objToReturn = {};
  let propertyId = _value["field"]["property"];
  let functionId = _value["field"]["function"];
  let operatorId = _value["field"]["operator"];

  if (propertyId === "getStarted") {
    return;
  }

  var propertyIndex = options["_optionsIndex"][propertyId]["property"];
  var propertyItem = options["_options"]["properties"][propertyIndex];
  objToReturn["propertyItem"] = propertyItem["label"];

  var functionIndex =
    options["_optionsIndex"][propertyId]["functions"][functionId];
  var functionItem =
    options["_options"]["functions"][propertyIndex][functionIndex];

  var item = deepClone(functionItem);
  var value = {
    augmentation: null,
    subject: item["value"],
  };

  if (item["type"] === "augmented") {
    value["augmentation"] =
      _value["field"]["functionParams"] != null
        ? _value["field"]["functionParams"]["augmentation"]
        : {
            value: item["widget"]["value"],
          };
  }
  item["value"] = value;
  objToReturn["functionItem"] = item;

  // if (
  //   functionId !== "decile" &&
  //   functionId !== "quartile" &&
  //   functionId !== "quintile"
  // ) {
  var operatorIndex =
    options["_optionsIndex"][propertyId]["operators"][functionIndex][
      operatorId
    ];
  var operatorItem =
    options["_options"]["operators"][propertyIndex][functionIndex][
      operatorIndex
    ];

  item = deepClone(operatorItem);
  // console.log("operatorItem", operatorItem);
  value = {
    augmentation: null,
    subject: item["value"],
  };
  if (item["type"] === "augmented") {
    value["augmentation"] =
      _value["field"]["operatorParams"] != null &&
      "augmentation" in _value["field"]["operatorParams"]
        ? _value["field"]["operatorParams"]["augmentation"]
        : {
            value: item["widget"]["value"],
          };
  }
  item["value"] = value;
  item["widget"]["label"] = item["label"];
  // }
  objToReturn["operatorItem"] = item;
  // objToReturn["value"] = value;
  // return item;
  objToReturn["propertyID"] = propertyId;
  return objToReturn;
};

export const quantileParametersAdapter = (objToAdapt, operator) => {
  let operatorValue = objToAdapt["field"]["operatorParams"]["augmentation"];
  var end = operatorValue.value[0]["le"];
  var start = operatorValue.value[0]["ge"];
  var augmentation: any = null;
  var subject: any = null;
  var nIles = operator["widget"]["options"].length;
  if (start === end) {
    augmentation = start;
    subject = "equalTo";
  } else if (start === 1 && end < nIles) {
    augmentation = [{ le: end }];
    subject = "lessThanOrEqualTo";
  } else if (start > 1 && end < nIles) {
    augmentation = operatorValue["value"];
    subject = "range";
  } else {
    augmentation = [{ ge: start }];
    subject = "greaterThanOrEqualTo";
  }
  operatorValue = {
    augmentation: {
      value: augmentation,
    },
    subject: subject,
  };
  objToAdapt["field"]["operatorParams"] = operatorValue;
  return objToAdapt;
};

export const fromAdaptedToExploded = (value, nIles) => {
  let vl: any = deepClone(value);
  if (Array.isArray(vl)) {
    if (vl[0]["ge"] == null) {
      vl[0]["ge"] = 1;
    } else if (vl[0]["le"] == null) {
      vl[0]["le"] = nIles;
    }
  } else {
    let obj = {
      ge: vl,
      le: vl,
    };

    vl = [obj];
  }

  return vl;
};

export const encoder = (objToEncode, create, edit) => {
  let obj = deepClone(objToEncode);
  if (obj["function"] === "quantile") {
    let _operator = obj["operator"];
    switch (obj["functionParams"]["value"]) {
      case 4:
        obj["function"] = "quartile";
        obj["operator"] = "rangeQuartile";
        break;
      case 5:
        obj["function"] = "quintile";
        obj["operator"] = "rangeQuintile";
        break;
      case 10:
        obj["function"] = "decile";
        obj["operator"] = "rangeDecile";
        break;
    }

    obj["functionParams"] = {
      augmentation: obj["functionParams"],
      subject: obj["function"],
    };
    obj["operatorParams"] = {
      augmentation: obj["operatorParams"],
      subject: _operator,
    };
  }

  return obj;
};

/*
{
    "function": "quantile",
    "functionParams": {
        "value": 4
    },
    "operator": "lessThanOrEqualTo",
    "operatorParams": {
        "value": [
            {
                "le": 2
            }
        ]
    },
    "property": "mc"
}
*/
