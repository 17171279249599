import { _Base } from "../_Base";
import { endpoints } from "../endpoints";

export class Product extends _Base {
  async allCore(callback?, callbackError?) {
    var url =
      this.getEndpointRoot({
        baseEndPoint: appConfig.rest.baseURL,
        domain: "",
      }) + endpoints.product.all;
    var request = this.prepareGet(url, null);
    if (callback && callbackError) {
      try {
        const response = await request;
        callback(response);
      } catch (error) {
        callbackError(error);
      }
      request.then(callback, callbackError);
    }

    return await request;
  }

  async all(callback?, callbackError?) {
    if (callback && callbackError) {
      const response = await this.allCore(() => {
        const data = this.normalize(response);
        callback(data);
      }, callbackError);
    } else {
      return await this.allCore();
    }
  }

  hasAdvanced() {
    if (window.App.user["group"] === "INTERNAL") {
      return true;
    }

    return false;
  }

  hasSystematicProduct() {
    if (window.App.user["group"] === "INTERNAL") {
      return true;
    }

    return false;
  }

  normalize(response) {
    var rawData = response.data.products;
    rawData.sort(function (a, b) {
      if (a.codeName > b.codeName) {
        return 1;
      }
      if (a.codeName < b.codeName) {
        return -1;
      }
      return 0;
    });

    var products: any = [];
    var item: any = null;
    for (let i = 0, length = rawData.length; i < length; i++) {
      item = rawData[i];
      if (item.productCode === "ADVANCED") {
        if (this.hasAdvanced() === true) {
          products.push({
            label: item.codeName,
            productCode: item.productCode,
            value: item.id,
          });
        }
      } else if (item.productCode === "SYSTEMATIC_ENGINE") {
        if (this.hasSystematicProduct() === true) {
          products.push({
            label: item.codeName,
            productCode: item.productCode,
            value: item.id,
          });
        }
      } else {
        products.push({
          label: item.codeName,
          productCode: item.productCode,
          value: item.id,
        });
      }
    }
    return products;
  }
}
