import { format as dateFormatter, formatISO, parseISO } from "date-fns";

export const getUpdateTime = (updateTime) => {
  const date = parseISO(formatISO(updateTime));
  return (
    "Last update: <strong>" +
    dateFormatter(date, "dd MMM yyyy") +
    " at " +
    dateFormatter(date, "kk mm").split(" ").join(":") +
    "</strong>"
  );
};

export const getTotalWeights = (positions) => {
  let total: number = 0;
  positions.forEach((element) => {
    if (element.weight) {
      total += parseFloat(element.weight);
    }
  });
  total = total * 100;
  return total;
};

const _dataGroupMatches = (source, response) => {
  var data = response["data"];
  var instruments = source["instruments"];
  var keys: any[] = [];
  const symbology = source?.symbology ?? "ticker";

  for (let i = 0, length = instruments.length; i < length; i++) {
    keys.push({
      label: instruments[i].label,
      weight: instruments[i].weight,
      positions: [],
      country: instruments[i].country,
      currency: instruments[i].currency,
    });
  }

  keys.forEach((element) => {
    data.forEach((item) => {
      const id = item[symbology];
      const tokens = id.split("|");
      if (tokens.includes(element.label)) {
        element.positions.push(item);
      }
    });
  });

  return keys;
};

export const _listenerImportHelper = (source, response) => {
  var data = _dataGroupMatches(source, response);
  var found: boolean = false;
  var item: any = null;
  var position: any = null;
  var matchesMultiple: any[] = [];
  var matchesUnique: any[] = [];
  var matchesUnrecognized: any[] = [];

  for (const obj of data) {
    found = false;
    item = obj;
    // single match
    if (item["positions"].length === 1) {
      position = item["positions"][0];
      position["weight"] =
        item["weight"] != null ? parseFloat(item["weight"]) : null;

      const country = item.country;
      const currency = item.currency;

      if (
        _isPositionValid(position, "country", country) &&
        _isPositionValid(position, "currency", currency)
      ) {
        matchesUnique.push(position);
      } else {
        matchesUnrecognized.push(item);
      }
    }
    // multiple matches
    if (item["positions"].length > 1) {
      for (var i = 0; i < item["positions"].length && !found; i++) {
        if (item.weight != null) {
          item["positions"][i]["weight"] = item.weight;
        }
        if (item.country != null && item.currency != null) {
          const itemCountry = item.country;
          const itemCurrency = item.currency;
          const desiredTitle = item["positions"].filter(
            (el) => el.country === itemCountry && el.currency === itemCurrency
          );

          if (desiredTitle != null && desiredTitle.length !== 0) {
            if (desiredTitle.length === 1) {
              found = true;
              matchesUnique.push(desiredTitle[0]);
            } else if (desiredTitle.length > 1) {
              found = false;
            }
          } else {
            /**
             * found variable need to be true to avouid that this title to be pushed in multiple match
             */
            found = true;
            item.positions = [];
          }
        } else if (item.country == null || item.currency == null) {
          if (item.country) {
            const specifiedCountry = item.country;
            const matchesPositons = item["positions"].filter(
              (el) => el.country === specifiedCountry
            );
            if (!matchesPositons.length) {
              /**
               * found variable need to be true to avouid that this title to be pushed in multiple match
               */
              found = true;
              item.positions = matchesPositons;
            } else {
              if (matchesPositons.length === 1) {
                position = matchesPositons[0];
                position["weight"] =
                  item["weight"] != null ? parseFloat(item["weight"]) : null;
                matchesUnique.push(position);
                found = true;
              } else if (matchesPositons.length !== 0) {
                found = false;
                item.positions = matchesPositons;
              }
            }
          } else if (item.currency) {
            const specifiedCurrency = item.currency;
            const matchesPositons = item["positions"].filter(
              (el) => el.currency === specifiedCurrency
            );

            if (!matchesPositons.length) {
              /**
               * found variable need to be true to avouid that this title to be pushed in multiple match
               */
              found = true;
              item.positions = matchesPositons;
            } else {
              if (matchesPositons.length === 1) {
                position = matchesPositons[0];
                position["weight"] =
                  item["weight"] != null ? parseFloat(item["weight"]) : null;
                matchesUnique.push(position);
                found = true;
              } else if (matchesPositons.length !== 0) {
                found = false;
                item.positions = matchesPositons;
              }
            }
          }
        }
      }
      if (!found) {
        matchesMultiple.push(item);
      }
    }
    // unknown match
    if (item["positions"].length === 0) {
      matchesUnrecognized.push(item);
    }
  }

  var matches = {
    multiple: matchesMultiple,
    unique: matchesUnique,
    unrecognized: matchesUnrecognized,
  };
  return matches;
};

const _isPositionValid = (position, key, filter) => {
  if (filter == null) {
    return true;
  }

  //Check that country is in ISO code format
  if (key === "country" && filter.length > 2) {
    return false;
  }

  if (position[key] === filter) {
    return true;
  } else {
    return false;
  }
};

export const _dataListMeta = (pos) => {
  var positions = pos;
  var cardinality = positions.length;
  var weight = 0;
  for (let i = cardinality - 1; i >= 0; i--) {
    weight = weight + positions[i]["weight"];
  }
  var meta = {
    cardinality: cardinality,
    weight: weight,
  };
  return meta;
};
