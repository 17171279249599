import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TaxonomySelect } from "../../../../../../../components/TaxonomySelect/TaxonomySelect";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FiltersContext } from "../../FilterRowContraints";

type MarketFilterProps = {
  widgetParams: any;
  type: string;
  field: string;
};

export function MarketFilter({ widgetParams, type, field }: MarketFilterProps) {
  const [panelInit, setPanelInit] = useState<string[]>([]);
  const [filtersInit, setFiltersInit] = useState<{
    domestic: boolean;
    foreign: boolean;
    type: string[];
  }>();
  const context = useContext(FiltersContext);
  const panelRef = useRef<any>(null);
  const { advancedNodesSelector, getTaxonomyMap } = useTaxonomyByType("stock");
  const taxonomiesMap = useMemo(() => {
    return getTaxonomyMap("security", "country");
  }, [getTaxonomyMap]);
  const stockClassTaxonomies = useMemo(() => {
    let txList = getTaxonomyMap("Stock", "stockclass");
    txList = txList.reduce((prev, current) => {
      prev[current.id] = current;

      return prev;
    }, {});

    return txList;
  }, [getTaxonomyMap]);
  const rootNode = useMemo(
    () =>
      Object.values<any>(taxonomiesMap).find((taxon) => taxon.parent == null),
    [taxonomiesMap]
  );

  const handlePanelAppearence = useCallback((value: boolean) => {
    if (panelRef.current) {
      panelRef.current.showTreePanel(value);
    }
  }, []);

  const handleSelection = useCallback(
    (values) => {
      if (values && values.length) {
        const result: any = [];

        values.forEach((filter) => {
          if (
            filter.value.dimension === field &&
            filter.value.segments.length
          ) {
            result.push({
              type: "filters",
              value: {
                dimension: field,
                segments: advancedNodesSelector(
                  filter.value.segments,
                  field,
                  true
                ),
              },
            });
          } else {
            result.push(filter);
          }
        });

        const valuesAsTreeNodes = result;

        context?.updateMarkets(valuesAsTreeNodes);
      }
    },
    [advancedNodesSelector, context, field]
  );

  const selectedValues = useMemo(() => {
    if (context && context.markets && context.markets.length) {
      const values: any = [];
      const panelSelectedCheckboxes: string[] = [];
      const marketsConstraints = context.markets;
      const marketsAdditionalFilters: typeof filtersInit = {
        domestic: false,
        foreign: false,
        type: [],
      };
      const additionalFiltersToShow: string[] = [];
      const stockclassType: string[] = [];

      marketsConstraints.forEach((constraint) => {
        if (constraint.value.segments.length) {
          constraint.value.segments.forEach((segment) => {
            switch (constraint.value.dimension) {
              case "country":
                values.push(taxonomiesMap?.[segment]?.name ?? "");
                panelSelectedCheckboxes.push(segment);
                break;

              case "stockclass":
                stockclassType.push(stockClassTaxonomies[segment]?.name ?? "");
                marketsAdditionalFilters.type.push(segment);

                break;

              case "subtype":
                additionalFiltersToShow.push(segment);
                if (segment === "Domestic Stock") {
                  marketsAdditionalFilters.domestic = true;
                } else {
                  marketsAdditionalFilters.foreign = true;
                }
            }
          });
        }
      });

      setPanelInit(panelSelectedCheckboxes);
      setFiltersInit(marketsAdditionalFilters);
      if (stockclassType.length) {
        const stockclassFilter = stockclassType.join(", ");
        additionalFiltersToShow.push(`(${stockclassFilter})`);
      }

      let result: string[] = [];

      if (values.length) {
        if (values.length > 3) {
          const firstSelected = values[0];
          result = [firstSelected, `+ ${values.length - 1}`];
        } else {
          result = values;
        }
      } else {
        result = [rootNode?.name ?? ""];
      }

      if (additionalFiltersToShow.length) {
        result.push(...additionalFiltersToShow);
      }

      return result;
    } else {
      return [rootNode?.name ?? ""];
    }
  }, [context, rootNode?.name, stockClassTaxonomies, taxonomiesMap]);

  const removeFilter = useCallback(() => {
    if (context?.markets && context.markets.length) {
      context.updateMarkets([]);
    }
  }, [context]);

  return (
    <TaxonomySelect
      ref={panelRef}
      taxonomyInfo={{ type: "security", field }}
      panelTitle={widgetParams.label}
      hasFilters={type !== "ETF"}
      onSetFilters={handleSelection}
      defaultValue={panelInit}
      initFiltersState={filtersInit}
    >
      <Card
        onClick={() => handlePanelAppearence(true)}
        sx={{
          position: "relative",
          border: "1px solid",
          borderColor: "transparent",
          transition: "0.5s",
          cursor: "pointer",
          "&:hover": {
            borderColor: "#2a7090",
          },
          display: "flex",
        }}
      >
        {context?.markets?.length ? (
          <Box
            sx={{
              position: "absolute",
              right: "2px",
              top: "2px",
              cursor: "pointer",
            }}
            title={`Remove ${widgetParams?.label ?? ""}`}
          >
            <CloseIcon
              onClick={removeFilter}
              sx={{
                fontSize: "14px",

                color: "black",
              }}
            />
          </Box>
        ) : (
          <></>
        )}
        <CardContent
          sx={{
            padding: "2px 6px!important",

            display: "flex",
            flex: 1,
          }}
        >
          <Box
            mr={context?.whiteList ? "16px" : 0}
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            flex={1}
          >
            <Typography mr={context?.markets?.length ? "10px" : 0}>
              <strong>{widgetParams?.label ?? ""}</strong>
            </Typography>
            {context?.markets && context?.markets.length > 3 ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                flex={1}
                justifyContent={"center"}
              >
                {" "}
                <Typography>Many countries</Typography>
              </Box>
            ) : (
              <Box
                component={"ul"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                flex={1}
                justifyContent={"center"}
                gap={0.2}
              >
                {selectedValues.map((filter) => {
                  return (
                    <li key={uuidv4()}>
                      <Typography>{filter}</Typography>
                    </li>
                  );
                })}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </TaxonomySelect>
  );
}
