import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Card, CardContent, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../../../../../../../../../deepClone";

type FrameNavigatorProps = {
  timelineData: any;
  frame: any;
  onTimeFrameChange: Function;
  strategyRebalance: any;
};

export default function FrameNavigator({
  timelineData,
  frame,
  onTimeFrameChange,
  strategyRebalance,
}: FrameNavigatorProps) {
  const { t } = useTranslation();
  const state = useMemo(
    () => ({
      currentFrameIndex: frame == null? timelineData?.frames?.length - 1 : frame,
      timelineData: timelineData,
    }),
    [frame, timelineData]
  );

  const boxFrameNavigatorLabel = useMemo(() => {
    switch (strategyRebalance) {
      case "05_DAYS": {
        return t("Week");
      }
      case "20_DAYS": {
        return t("Month");
      }
      case "60_DAYS": {
        return t("Quarter");
      }
    }
  }, [strategyRebalance, t]);

  const _frame = useMemo(() => {
    if (state.timelineData != null && state.currentFrameIndex != null) {
      return state.timelineData.frames[state.currentFrameIndex];
    }
  }, [state.currentFrameIndex, state.timelineData]);


  return (
    <Card sx={{ boxShadow: 3, flex:3 }}>
      <CardContent
        sx={{  p:1 ,pb:"8px !important", display: "flex", flexDirection: "column" }}
      >
        <Box display={"flex"} justifyContent="center">
          {boxFrameNavigatorLabel}
        </Box>
        <Box display={"flex"} flexDirection={"row"} justifyContent="center" alignItems="center">
          <IconButton
            onClick={() => _listenerClickPrev(state, onTimeFrameChange)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Box px={2}>
            <strong>{_frame && _frame.name}</strong>
          </Box>
          <IconButton
            onClick={() => _listenerClickNext(state, onTimeFrameChange)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

const _getNextIndex = (state) => {
  var currentFrameIndex = state.currentFrameIndex;
  var timeline = state.timelineData.frames;
  var nextFrameIndex = -1;
  if (currentFrameIndex < timeline.length - 1) {
    nextFrameIndex = currentFrameIndex + 1;
  }
  return nextFrameIndex;
};

const _getPrevIndex = (state) => {
  var currentFrameIndex = state.currentFrameIndex;
  var prevFrameIndex = -1;
  if (currentFrameIndex !== 0) {
    prevFrameIndex = currentFrameIndex - 1;
  }
  return prevFrameIndex;
};

const _listenerClickNext = (state, onTimeFrameChange) => {
  var nextFrameIndex = _getNextIndex(state);
  if (nextFrameIndex !== -1) {
    let newState = deepClone(state);
    newState.currentFrameIndex = nextFrameIndex;
    const timeline = newState.timelineData.frames;
    const frame = timeline[nextFrameIndex];
    onTimeFrameChange(frame);
  }
};

const _listenerClickPrev = (state, onTimeFrameChange) => {
  var prevFrameIndex = _getPrevIndex(state);
  if (prevFrameIndex !== -1) {
    var newState = deepClone(state);
    newState.currentFrameIndex = prevFrameIndex;
    var timeline = newState.timelineData.frames;
    var frame = timeline[prevFrameIndex];
    onTimeFrameChange(frame);
  }
};
