import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { BacktestWarningDialog } from "../../BacktestWarningDialog";

type Props = {
  onUpdateBacktesting: any;
  disabled: boolean;
};

export default function Backtesting({ onUpdateBacktesting, disabled }: Props) {
  const [show, setShow] = useState(false);
  return (
    <Card sx={{ flex: 1, boxShadow: 3 }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box pb={1}>
          <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
            Backtesting
          </Typography>
        </Box>
        <Divider
          sx={{ width: "100%", display: "block", m: 0 }}
          variant="middle"
        />
        <Box display={"flex"} gap={2} flexDirection={"column"}>
          <Typography>
            Overwrite historical portfolio with strategy backtesting
          </Typography>
          {!show && (
            <Box>
              <Button
                disabled={disabled}
                onClick={() => setShow(true)}
                size="small"
              >
                Run
              </Button>
            </Box>
          )}
        </Box>
        {show && (
          <BacktestWarningDialog
            onCancel={() => setShow(false)}
            onContinue={() => onUpdateBacktesting()}
          />
        )}
      </CardContent>
    </Card>
  );
}
