import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translation as enTranslation } from "./locales/en/translation";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        lng: "en",
        fallbackLng: "en",

        react: {
            useSuspense: false,
        },

        resources: {
            en: {
                translation: enTranslation,
            },
        },
    });

export default i18n;
