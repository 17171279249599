import { dataInjector } from "../../../../../../../../api/compute/commons";

type Period = "pd" | "pw" | "pm" | "pq" | "ps" | "py";

export class PerformanceData {
  constructor(public holdings) {}

  getPerformers = (period: Period, trend: "positive" | "negative") => {
    const holdings: any = this.holdings;
    let result: { [key: string]: any }[] = [];

    if (holdings != null) {
      const performers =
        trend === "positive"
          ? holdings.filter((item) => item[period] >= 0)
          : holdings.filter((item) => item[period] < 0);

      result = performers;
    }

    return result;
  };

  getPositivesNumber = (period: Period) => {
    const positiveNumbers = this.getPerformers(period, "positive").length;

    return positiveNumbers;
  };

  getNegativesNumber = (period) => {
    const negativeNumbers = this.getPerformers(period, "negative").length;

    return negativeNumbers;
  };

  getTopBottomFivePerformers = (
    period: Period,
    trend: "positive" | "negative",
    positions: any[]
  ) => {
    const performers = this.getPerformers(period, trend);
    const result = performers.sort((a, b) => {
      if (a[period] === null) {
        return 1;
      } else if (b[period] === null) {
        return -1;
      }

      const positiveSort = a[period] < b[period] ? 1 : -1;
      const negativeSort = a[period] > b[period] ? 1 : -1;

      return trend === "positive" ? positiveSort : negativeSort;
    });

    const first5 = result.filter((item) => result.indexOf(item) <= 4);

    return dataInjector(first5, positions, ["weight"]);
  };
}
