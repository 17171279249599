import { Card, CardContent, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useCallback, useMemo, useRef } from "react";
import { Spinner } from "../../../../../../../components/Spinner/Spinner";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { useResizer } from "../../../../../../../hooks/useResizer";
import {
  ActionsETFMarketsControls,
  DispersionByViewActionMarketsETF,
  DispersionViewActionMarketsETF,
  SecuritiesViewActionMarketsETF,
} from "../../AnalysisMarketsETF";
import styles from "./ContentETFMarkets.module.scss";
import DispersionByView from "./widgets/DispersionByView/DispersionByView";
import { DispersionView } from "./widgets/DispersionView/DispersionView";
import MarketsETFTableTCR from "./widgets/MarketsETFTableTCR/MarketsETFTableTCR";
import { SecuritiesView } from "./widgets/SecuritiesView/SecuritiesView";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Tooltip } from "../../../../../../../components/Tooltip/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import logoIcon from "./../../../../../../../styles/css_new/img/trendrating_icon.png";

type ContentETFMarketsProps = {
  status: string;
  data: any;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  segmentReference: any;
  timeframe: 0 | 4 | 19;
  formatterHelper: (field: TableField) => Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  analytic:
    | "tcr"
    | "history"
    | "dispersion"
    | "securities"
    | "dispersionBy"
    | "performanceSinceRated";
  dispersionDispatcher: (action: DispersionViewActionMarketsETF) => void;
  xDimension: string;
  yDimension: string;
  dispersionPerformance: "3_months" | "6_months" | "12_months";
  dispersionIntervals: 4 | 10 | 20;
  dispersionTrimOutliers: boolean;
  securitiesDispatcher: (action: SecuritiesViewActionMarketsETF) => void;
  rating: { A: boolean; B: boolean; C: boolean; D: boolean };
  currentSort: { field: string; rev: boolean };
  alert:
    | null
    | "Any"
    | "upgrades_today"
    | "upgrades_last_5_days"
    | "upgrades_last_20_days"
    | "upgrades_last_60_days"
    | "downgrades_today"
    | "downgrades_last_5_days"
    | "downgrades_last_20_days"
    | "downgrades_last_60_days"
    | "positive_movers"
    | "negative_movers";
  dispersionByDispatcher: (action: DispersionByViewActionMarketsETF) => void;
  dispersionByIntervals: 4 | 10 | 20;
  dispersionByPerformance: "3_months" | "6_months" | "12_months";
  dispersionByTrimOutliers: boolean;
  goToSecuritiesTab?: (
    boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc"
  ) => void;
  dispersionPeerId: string | undefined;
  securitiesPeerId: string | undefined;
  securitiesCurrentPage: number;
  setDispersionBySort: (sorter) => void;
};

type PeerInfoRowData = {
  abChanges: number;
  tcr: number;
  cardinality: number;
  marketCap: number;
  upgradesNumber: number;
  downgradesNumber: number;
  upgradesPercentage: number;
  downgradesPercentage: number;
  ab_perc: number;
  cd_perc: number;
};

type PeerInfoRowProps = {
  data: PeerInfoRowData;
  timeframe: 0 | 4 | 19;
  type: "stock" | "etf";
  goToSecuritiesTab?: (
    boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc"
  ) => void;
  tooltipConfig?: {
    enabled: boolean;
    tcr: string;
    upgrades: string;
    downgrades: string;
    stocks: string;
  };
};

type ContentWizardMarketsEtfProps = {
  analytic: ContentETFMarketsProps["analytic"];
  data: ContentETFMarketsProps["data"];
  segment: ContentETFMarketsProps["segment"];
  segmentReference: ContentETFMarketsProps["segmentReference"];
  timeframe: ContentETFMarketsProps["timeframe"];
  formatterHelper: ContentETFMarketsProps["formatterHelper"];
  dispatch: ContentETFMarketsProps["dispatch"];
  dispersionDispatcher: (params) => void;
  xDimension: ContentETFMarketsProps["xDimension"];
  yDimension: ContentETFMarketsProps["yDimension"];
  dispersionPerformance: "3_months" | "6_months" | "12_months";
  dispersionIntervals: 4 | 10 | 20;
  dispersionTrimOutliers: boolean;
  securitiesDispatcher: (action: SecuritiesViewActionMarketsETF) => void;
  rating: { A: boolean; B: boolean; C: boolean; D: boolean };
  currentSort: { field: string; rev: boolean };
  alert:
    | null
    | "Any"
    | "upgrades_today"
    | "upgrades_last_5_days"
    | "upgrades_last_20_days"
    | "upgrades_last_60_days"
    | "downgrades_today"
    | "downgrades_last_5_days"
    | "downgrades_last_20_days"
    | "downgrades_last_60_days"
    | "positive_movers"
    | "negative_movers";
  dispersionByDispatcher: (action: DispersionByViewActionMarketsETF) => void;
  dispersionByIntervals: 4 | 10 | 20;
  dispersionByPerformance: "3_months" | "6_months" | "12_months";
  dispersionByTrimOutliers: boolean;
  goToSecuritiesTab?: (
    boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc"
  ) => void;
  dispersionPeerId: string | undefined;
  securitiesPeerId: string | undefined;
  securitiesCurrentPage: number;
  setDispersionBySort: (sorter) => void;
};

type TableField =
  | "name"
  | "tcr"
  | "tcr_changes"
  | "abPerc"
  | "cdPerc"
  | "cardinality"
  | "ab_changes"
  | "cd_changes"
  | "upgrades"
  | "downgrades"
  | "upgrades_perc"
  | "downgrades_perc";

const timeframeLabel = (timeframe) => {
  switch (timeframe) {
    case 0:
    default:
      return "today";

    case 4:
      return "lastWeek";

    case 19:
      return "lastMonth";
  }
};

const TOOLTIP_DICT = {
  tooltipTCR:
    "Contact your Trendrating representative to upgrade to the Trendrating Alpha Finder system and monitor the Trend Combined Risk across your portfolios.",
  tooltipUpgrades:
    "Contact your Trendrating representative to upgrade to the Trendrating Alpha Finder system and access the full list of upgrades.",
  tooltipDowngrades:
    "Contact your Trendrating representative to upgrade to the Trendrating Alpha Finder system and access the full list of downgrades.",
  tooltipStocks:
    "Contact your Trendrating representative to upgrade to the Trendrating Alpha Finder system and access the full list of stocks.",
};

const CTA_FOCUS_ITEMS = [
  "Enhanced Risk Control on individual holdings as well as across portfolios.",
  "Validate your investment ideas.",
  "Spot new investment opportunities, combining strong fundamentals and positive trend.",
  "Rank the securities lists with your preferred parameters.",
  "Stay on top of the performance dispersion across the sectors and markets of your interest.",
  "Design, test and optimize your own investment strategy, discovering the best combination of parameters to maximize your alpha.",
  "Rich reporting capabilities.",
];

const CallToAction = ({ id }) => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} mb={3}>
        <Box
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"center"}
          borderRight={"2px solid #2a7090"}
          p={2}
        >
          <img
            style={{ width: "5em", height: "auto" }}
            alt="Trendrating logo"
            src={logoIcon}
          />
        </Box>
        <Box
          paddingLeft={2}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography sx={{ fontSize: "1.5em", marginBottom: "16px" }}>
            <strong>{TOOLTIP_DICT?.[id] ?? ""}</strong>
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ fontSize: "1.2em", marginBottom: "16px" }}>
        Our Premium Platform supports a broad range of tasks:
      </Typography>
      <ul style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {CTA_FOCUS_ITEMS.map((focusPoint) => (
          <li
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              fontSize: "1.2em",
            }}
          >
            <CheckIcon sx={{ color: "green" }} /> {focusPoint}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export const ContentETFMarkets = ({
  status,
  data,
  segment,
  timeframe,
  dispatch,
  analytic,
  formatterHelper,
  dispersionDispatcher,
  xDimension,
  yDimension,
  dispersionIntervals,
  dispersionTrimOutliers,
  dispersionPerformance,
  securitiesDispatcher,
  alert,
  rating,
  dispersionByDispatcher,
  dispersionByIntervals,
  dispersionByPerformance,
  dispersionByTrimOutliers,
  goToSecuritiesTab,
  segmentReference,
  dispersionPeerId,
  securitiesPeerId,
  securitiesCurrentPage,
  setDispersionBySort,
  currentSort,
}: ContentETFMarketsProps) => {
  const peerInfoRowData: PeerInfoRowData | undefined = useMemo(() => {
    const peer = data?.peer ?? null;
    const timeframeKey = timeframeLabel(timeframe);
    if (peer) {
      return {
        abChanges: peer?.["statistic"]?.["abChanges"][timeframeKey],
        tcr: peer?.tcr?.today,
        cardinality: peer?.info?.cardinality,
        marketCap: peer?.info?.marketcap,
        upgradesNumber: peer?.statistic?.upgrades?.[timeframeKey]?.number,
        upgradesPercentage:
          peer?.statistic?.upgrades?.[timeframeKey]?.percentage,
        downgradesNumber: peer?.statistic?.downgrades?.[timeframeKey]?.number,
        downgradesPercentage:
          peer?.statistic?.downgrades?.[timeframeKey]?.percentage,
        ab_perc: peer?.statistic?.["abPercentage"]?.[timeframeKey],
        cd_perc: peer?.statistic?.["cdPercentage"]?.[timeframeKey],
      };
    } else {
      return undefined;
    }
  }, [data?.peer, timeframe]);

  const environment = useEnvironment();
  const product = useMemo(
    () =>
      environment.get("account")["product"]["configuration"]["analysis_peer"],
    [environment]
  );
  const tooltipInfoBarConfig = useMemo(() => {
    return product.peerInfoBarTooltips;
  }, [product]);

  const isLoading = useMemo(() => {
    return status === "loading";
  }, [status]);

  return (
    <Box mt={1} display={"flex"} minHeight={0} className={styles.content__box}>
      <Box
        display={!isLoading ? "flex" : "none"}
        flexDirection={"column"}
        flex={1}
        minWidth={0}
        minHeight={0}
        gap={1}
      >
        {peerInfoRowData && (
          <PeerInfoRow
            tooltipConfig={tooltipInfoBarConfig}
            type="etf"
            goToSecuritiesTab={goToSecuritiesTab}
            data={peerInfoRowData}
            timeframe={timeframe}
          />
        )}
        <ContentWizardMarketsEtf
          securitiesPeerId={securitiesPeerId}
          dispersionPeerId={dispersionPeerId}
          xDimension={xDimension}
          yDimension={yDimension}
          dispatch={dispatch}
          data={data}
          segmentReference={segmentReference}
          segment={segment}
          timeframe={timeframe}
          analytic={analytic}
          formatterHelper={formatterHelper}
          dispersionDispatcher={dispersionDispatcher}
          dispersionIntervals={dispersionIntervals}
          dispersionPerformance={dispersionPerformance}
          dispersionTrimOutliers={dispersionTrimOutliers}
          securitiesDispatcher={securitiesDispatcher}
          alert={alert}
          rating={rating}
          dispersionByDispatcher={dispersionByDispatcher}
          dispersionByIntervals={dispersionByIntervals}
          dispersionByPerformance={dispersionByPerformance}
          dispersionByTrimOutliers={dispersionByTrimOutliers}
          goToSecuritiesTab={goToSecuritiesTab}
          securitiesCurrentPage={securitiesCurrentPage}
          setDispersionBySort={setDispersionBySort}
          currentSort={currentSort}
        />
      </Box>

      <Box
        flex={1}
        display={isLoading ? "flex" : "none"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Spinner />
      </Box>
    </Box>
  );
};

function ContentWizardMarketsEtf({
  analytic,
  dispatch,
  timeframe,
  formatterHelper,
  data,
  segment,
  dispersionDispatcher,
  dispersionPerformance,
  dispersionIntervals,
  dispersionTrimOutliers,
  securitiesDispatcher,
  rating,
  alert,
  dispersionByDispatcher,
  dispersionByIntervals,
  dispersionByPerformance,
  dispersionByTrimOutliers,
  segmentReference,
  goToSecuritiesTab,
  dispersionPeerId,
  securitiesPeerId,
  securitiesCurrentPage,
  setDispersionBySort,
  currentSort,
}: ContentWizardMarketsEtfProps) {
  const ctRef = useRef(null);
  useResizer({ ref: ctRef });
  switch (analytic) {
    default:
    case "tcr":
      return (
        <Grid
          item
          md={12}
          boxShadow={3}
          p={1}
          borderRadius={2}
          mt={1}
          minHeight={0}
          display={"flex"}
        >
          <MarketsETFTableTCR
            type={"ETF"}
            goToSecuritiesTab={goToSecuritiesTab}
            formatterHelper={formatterHelper}
            data={data}
            segment={segment}
            timeframe={timeframe}
            dispatch={dispatch}
          />
        </Grid>
      );

    // FOR NOW WE DON'T GIVE ACCESS TO RATING HISTORY TAB
    // case "history":
    //     return (
    //         <RatingHistoryView
    //             segment={segment}
    //             data={data}
    //             setParams={setParams}
    //             tableCellNameFormatter={formatterHelper}
    //             dispatch={dispatch}
    //         />
    //     );

    case "dispersion":
      // To calculate the dispersion at least are needed 4 elements
      return (
        <DispersionView
          segmentReference={segmentReference}
          segment={segment}
          data={data}
          dispersionDispatcher={dispersionDispatcher}
          tableCellNameFormatter={formatterHelper}
          dispatch={dispatch}
          performance={dispersionPerformance}
          intervals={dispersionIntervals}
          trimOutliers={dispersionTrimOutliers}
          dispersionPeerId={dispersionPeerId}
        />
      );

    case "securities":
      return (
        <SecuritiesView
          currentSort={currentSort}
          segmentReference={segmentReference}
          segment={segment}
          data={data}
          securitiesDispatcher={securitiesDispatcher}
          alert={alert}
          showTable={false}
          rating={rating}
          tableCellNameFormatter={formatterHelper}
          dispatch={dispatch}
          securitiesPeerId={securitiesPeerId}
          page={securitiesCurrentPage}
        />
      );

    case "dispersionBy":
      return (
        <DispersionByView
          segment={segment}
          data={data}
          dispatch={dispatch}
          dispersionByDispatcher={dispersionByDispatcher}
          dispersionByIntervals={dispersionByIntervals}
          dispersionByPerformance={dispersionByPerformance}
          dispersionByTrimOutliers={dispersionByTrimOutliers}
          setDispersionBySort={setDispersionBySort}
        />
      );

    // IMPLEMENTED BUT NOT USED AT THE MOMENT
    // case "performanceSinceRated":
    //     return (
    //         <Grid
    //             container
    //             mt={1}
    //             display={"flex"}
    //             flex={1}
    //             boxShadow={3}
    //             borderRadius={2}
    //             minHeight={0}
    //         >
    //             <Grid
    //                 container
    //                 item
    //                 md={12}
    //                 spacing={1}
    //                 flex={1}
    //                 display={"flex"}
    //                 overflow={"hidden"}
    //             >
    //                 <PerformanceSinceTrendTab
    //                     data={data?.performanceSinceRated}
    //                     peerType={"ETF"}
    //                 />
    //             </Grid>
    //         </Grid>
    //     );
  }
}

export const PeerInfoRow = ({
  data,
  timeframe,
  type,
  goToSecuritiesTab,
  tooltipConfig,
}: PeerInfoRowProps) => {
  const formatter = useFormatter();
  const formatOptions = useMemo(
    () => ({
      number: {
        decimals: 0,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
      percentage: {
        isPercentage: true,
        notAvailable: {
          input: 0,
          output: "-",
        },
      },
    }),
    []
  );

  const hasLink = useMemo(() => goToSecuritiesTab != null, [goToSecuritiesTab]);

  const handleLinkBtnClick = useCallback(
    (params) => {
      if (goToSecuritiesTab != null) {
        goToSecuritiesTab(params);
      }
    },
    [goToSecuritiesTab]
  );

  const abChangesLabel = useMemo(() => {
    // A and B changes
    let percentageABFrom: any = data?.abChanges;
    /* 0: no change, 1: positive, -1: negative */
    const percentageABChanges =
      percentageABFrom > 0 ? 1 : percentageABFrom < 0 ? -1 : 0;
    let color = "#000000"; // no change: 0
    if (percentageABChanges > 0) {
      color = "#008000";
    } else if (percentageABChanges < 0) {
      color = "#F00000";
    }
    percentageABFrom = formatter.custom("number", {
      options: formatOptions["percentage"],
      output: "HTML",
      value: percentageABFrom,
      valueHelper: null,
    });
    return (
      '<span style="color:' +
      color +
      ';">AB% changes:&nbsp;' +
      percentageABFrom +
      "</span>"
    );
  }, [data?.abChanges, formatOptions, formatter]);

  const tooltipEnabled = useMemo(() => {
    return tooltipConfig?.enabled ?? false;
  }, [tooltipConfig?.enabled]);

  return data ? (
    <Box
      width={"100%"}
      display={"flex"}
      boxShadow={3}
      p={1}
      borderRadius={2}
      flexDirection={"column"}
    >
      <Box display={"flex"}>
        {/* TCR */}
        {tooltipEnabled ? (
          <Tooltip
            parentStyles={{ display: "flex", flex: 1 }}
            content={
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>
                  <CallToAction id={tooltipConfig?.tcr ?? ""} />
                </Typography>
              </Box>
            }
            tooltipIcon={false}
          >
            <Card
              sx={{
                display: "flex",
                flex: 1,
                marginLeft: 0,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "5px!important",
                  paddingBottom: "5px!important",
                }}
              >
                <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                  <abbr title="Trend Capture Rating">TCR</abbr>
                </h2>
                <p
                  className="tPageAnalysisMarketWhereFacts-factValue"
                  dangerouslySetInnerHTML={{
                    __html: formatter.tcr(data.tcr, "HTML"),
                  }}
                ></p>
              </CardContent>
            </Card>
          </Tooltip>
        ) : (
          <Card
            sx={{
              display: "flex",
              flex: 1,
              marginLeft: 0,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "5px!important",
                paddingBottom: "5px!important",
              }}
            >
              <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                <abbr title="Trend Capture Rating">TCR</abbr>
              </h2>
              <p
                className="tPageAnalysisMarketWhereFacts-factValue"
                dangerouslySetInnerHTML={{
                  __html: formatter.tcr(data.tcr, "HTML"),
                }}
              ></p>
            </CardContent>
          </Card>
        )}

        {/* Cardinality */}

        {tooltipEnabled ? (
          <Tooltip
            parentStyles={{ display: "flex", flex: 1 }}
            content={
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>
                  <CallToAction id={tooltipConfig?.stocks ?? ""} />
                </Typography>
              </Box>
            }
            tooltipIcon={false}
          >
            <Card
              sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
              className={hasLink ? styles.clickcable__peer__card : ""}
              onClick={handleLinkBtnClick}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "5px!important",
                  paddingBottom: "5px!important",
                }}
              >
                <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                  {type === "etf" ? "ETFs" : "Stocks"}
                </h2>
                <p className="tPageAnalysisMarketWhereFacts-factValue">
                  {data?.cardinality}
                </p>
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Mkt. Cap.
                  <span
                    data-dojo-attach-point="elementMktCap"
                    dangerouslySetInnerHTML={{
                      __html: formatter.custom("numberBig", {
                        options: {
                          notAvailable: {
                            input: null,
                            output: "",
                          },
                        },
                        output: "HTML",
                        value: data?.marketCap,
                        valueHelper: null,
                      }),
                    }}
                  ></span>
                </p>
              </CardContent>
            </Card>
          </Tooltip>
        ) : (
          <Card
            sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
            className={hasLink ? styles.clickcable__peer__card : ""}
            onClick={handleLinkBtnClick}
          >
            <CardContent
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "5px!important",
                paddingBottom: "5px!important",
              }}
            >
              <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                {type === "etf" ? "ETFs" : "Stocks"}
              </h2>
              <p className="tPageAnalysisMarketWhereFacts-factValue">
                {data?.cardinality}
              </p>
              <p className="tPageAnalysisMarketWhereFacts-factInfo">
                Mkt. Cap.
                <span
                  data-dojo-attach-point="elementMktCap"
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("numberBig", {
                      options: {
                        notAvailable: {
                          input: null,
                          output: "",
                        },
                      },
                      output: "HTML",
                      value: data?.marketCap,
                      valueHelper: null,
                    }),
                  }}
                ></span>
              </p>
            </CardContent>
          </Card>
        )}

        {/* Upgrades */}
        {tooltipEnabled ? (
          <Tooltip
            parentStyles={{ display: "flex", flex: 1 }}
            content={
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>
                  <CallToAction id={tooltipConfig?.upgrades ?? ""} />
                </Typography>
              </Box>
            }
            tooltipIcon={false}
          >
            <Card
              sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
              className={hasLink ? styles.clickcable__peer__card : ""}
              onClick={() => handleLinkBtnClick("upgrades")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "5px!important",
                  paddingBottom: "5px!important",
                }}
              >
                <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                  Upgrades
                </h2>
                <p className="tPageAnalysisMarketWhereFacts-factValue">
                  <span
                    className="tPageAnalysisMarketWhereFacts-factValue--positive"
                    dangerouslySetInnerHTML={{
                      __html: formatter.custom("number", {
                        options: formatOptions["number"],
                        output: "HTML",
                        value: data?.upgradesNumber,
                        valueHelper: null,
                      }),
                    }}
                  />{" "}
                  {data?.upgradesPercentage > 0 && (
                    <span
                      className="tPageAnalysisMarketWhereFacts-factValueHelper"
                      dangerouslySetInnerHTML={{
                        __html: formatter.custom("number", {
                          options: formatOptions["percentage"],
                          output: "HTML",
                          value: data.upgradesPercentage,
                          valueHelper: null,
                        }),
                      }}
                    />
                  )}
                </p>
                {timeframe === 0 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last working day
                  </p>
                )}
                {timeframe === 4 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last 5 working day
                  </p>
                )}
                {timeframe === 19 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last 20 working day
                  </p>
                )}
              </CardContent>
            </Card>
          </Tooltip>
        ) : (
          <Card
            sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
            className={hasLink ? styles.clickcable__peer__card : ""}
            onClick={() => handleLinkBtnClick("upgrades")}
          >
            <CardContent
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "5px!important",
                paddingBottom: "5px!important",
              }}
            >
              <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                Upgrades
              </h2>
              <p className="tPageAnalysisMarketWhereFacts-factValue">
                <span
                  className="tPageAnalysisMarketWhereFacts-factValue--positive"
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: formatOptions["number"],
                      output: "HTML",
                      value: data?.upgradesNumber,
                      valueHelper: null,
                    }),
                  }}
                />
                {data?.upgradesPercentage > 0 && (
                  <span
                    className="tPageAnalysisMarketWhereFacts-factValueHelper"
                    dangerouslySetInnerHTML={{
                      __html: formatter.custom("number", {
                        options: formatOptions["percentage"],
                        output: "HTML",
                        value: data.upgradesPercentage,
                        valueHelper: null,
                      }),
                    }}
                  />
                )}
              </p>
              {timeframe === 0 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last working day
                </p>
              )}
              {timeframe === 4 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last 5 working day
                </p>
              )}
              {timeframe === 19 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last 20 working day
                </p>
              )}
            </CardContent>
          </Card>
        )}

        {/* Downgrades */}
        {tooltipEnabled ? (
          <Tooltip
            parentStyles={{ display: "flex", flex: 1 }}
            content={
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
                paddingLeft={2}
              >
                <Typography>
                  <CallToAction id={tooltipConfig?.downgrades ?? ""} />
                </Typography>
              </Box>
            }
            tooltipIcon={false}
          >
            <Card
              sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
              className={hasLink ? styles.clickcable__peer__card : ""}
              onClick={() => handleLinkBtnClick("downgrades")}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "5px!important",
                  paddingBottom: "5px!important",
                }}
              >
                <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                  Downgrades
                </h2>
                <p className="tPageAnalysisMarketWhereFacts-factValue">
                  <span
                    className="tPageAnalysisMarketWhereFacts-factValue--negative"
                    dangerouslySetInnerHTML={{
                      __html: formatter.custom("number", {
                        options: formatOptions["number"],
                        output: "HTML",
                        value: data?.downgradesNumber,
                        valueHelper: null,
                      }),
                    }}
                  />{" "}
                  {data?.downgradesPercentage > 0 && (
                    <span
                      className="tPageAnalysisMarketWhereFacts-factValueHelper"
                      dangerouslySetInnerHTML={{
                        __html: formatter.custom("number", {
                          options: formatOptions["percentage"],
                          output: "HTML",
                          value: data?.downgradesPercentage,
                          valueHelper: null,
                        }),
                      }}
                    />
                  )}
                </p>
                {timeframe === 0 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last working day
                  </p>
                )}
                {timeframe === 4 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last 5 working day
                  </p>
                )}
                {timeframe === 19 && (
                  <p className="tPageAnalysisMarketWhereFacts-factInfo">
                    Last 20 working day
                  </p>
                )}
              </CardContent>
            </Card>
          </Tooltip>
        ) : (
          <Card
            sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
            className={hasLink ? styles.clickcable__peer__card : ""}
            onClick={() => handleLinkBtnClick("downgrades")}
          >
            <CardContent
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "5px!important",
                paddingBottom: "5px!important",
              }}
            >
              <h2 className="tPageAnalysisMarketWhereFacts-factLabel">
                Downgrades
              </h2>
              <p className="tPageAnalysisMarketWhereFacts-factValue">
                <span
                  className="tPageAnalysisMarketWhereFacts-factValue--negative"
                  dangerouslySetInnerHTML={{
                    __html: formatter.custom("number", {
                      options: formatOptions["number"],
                      output: "HTML",
                      value: data?.downgradesNumber,
                      valueHelper: null,
                    }),
                  }}
                />{" "}
                {data?.downgradesPercentage > 0 && (
                  <span
                    className="tPageAnalysisMarketWhereFacts-factValueHelper"
                    dangerouslySetInnerHTML={{
                      __html: formatter.custom("number", {
                        options: formatOptions["percentage"],
                        output: "HTML",
                        value: data?.downgradesPercentage,
                        valueHelper: null,
                      }),
                    }}
                  />
                )}
              </p>
              {timeframe === 0 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last working day
                </p>
              )}
              {timeframe === 4 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last 5 working day
                </p>
              )}
              {timeframe === 19 && (
                <p className="tPageAnalysisMarketWhereFacts-factInfo">
                  Last 20 working day
                </p>
              )}
            </CardContent>
          </Card>
        )}

        {/* AB% */}
        <Card
          sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
          className={hasLink ? styles.clickcable__peer__card : ""}
          onClick={() => handleLinkBtnClick("ab_perc")}
        >
          <CardContent
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "5px!important",
              paddingBottom: "5px!important",
            }}
          >
            <h2 className="tPageAnalysisMarketWhereFacts-factLabel">AB%</h2>
            <p
              className="tPageAnalysisMarketWhereFacts-factValue"
              dangerouslySetInnerHTML={{
                __html: formatter.custom("number", {
                  options: formatOptions["percentage"],
                  output: "HTML",
                  value: data?.ab_perc,
                  valueHelper: null,
                }),
              }}
            />
            <span dangerouslySetInnerHTML={{ __html: abChangesLabel }} />
          </CardContent>
        </Card>

        {/* CD% */}
        <Card
          sx={{
            display: "flex",
            flex: 1,
            marginLeft: "10px",
          }}
          className={hasLink ? styles.clickcable__peer__card : ""}
          onClick={() => handleLinkBtnClick("cd_perc")}
        >
          <CardContent
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "5px!important",
              paddingBottom: "5px!important",
            }}
          >
            <h2 className="tPageAnalysisMarketWhereFacts-factLabel">CD%</h2>
            <p
              className="tPageAnalysisMarketWhereFacts-factValue"
              dangerouslySetInnerHTML={{
                __html: formatter.custom("number", {
                  options: formatOptions["percentage"],
                  output: "HTML",
                  value: data?.cd_perc,
                  valueHelper: null,
                }),
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  ) : (
    <></>
  );
};
