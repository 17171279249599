import { Card, CardContent } from "@mui/material";
import { forwardRef, memo, useImperativeHandle, useMemo, useRef } from "react";
import { Chart } from "../../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../../trendrating/date/TDate";
import { Entity } from "../utils/Entity";

type Props = {
  timelineData: any;
  onTimeFrameChange;
};

const entity = new Entity();
const _colors = {
  border: "#d3d3d3",
  lineGrid: "#d3d3d3",
  line: "#d3d3d3",
  securityDefault: "#2a7092", // #2F7ED8
  securityBenchmark: "#ffcc00", // #133458
  securityRateA: entity.get(2, "RATE").color,
  securityRateB: entity.get(1, "RATE").color,
  securityRateC: entity.get(-1, "RATE").color,
  securityRateD: entity.get(-2, "RATE").color,
  securityRateU: entity.get("U", "RATE").color,
  trendrating: "#2a7092", // #ffc001 #0da760,
  trendratingGold: "#ffc001",
  trendratingGrey: "#a3a3a3",
};

export const Timeline = memo(
  forwardRef(({ timelineData, onTimeFrameChange }: Props, ref) => {
    const _timelineData = useMemo(() => timelineData, [timelineData]);
    const chartRef = useRef<any>();
    const format = useFormatter();
    const previousSelected = "PERFORMANCE";

    useImperativeHandle(
      ref,
      () => ({
        insertSerie: (holdingExposure) => {
          if (holdingExposure == null) {
            return;
          }
          if (chartRef?.current?.get("HOLDING_EXPOSURE") != null) {
            chartRef?.current?.get("HOLDING_EXPOSURE").remove();
          }
          var data: any = [];
          var frame: any = null;
          var frames = _timelineData.frames;
          var framesRaw = holdingExposure.strategyResult.POS;
          var holding = holdingExposure.holding;
          var item: any = null;
          var symbol = holding.symbol;
          var x: any = null;
          var y: any = null;
          for (let i = 0, lengthI = framesRaw.length; i < lengthI; i++) {
            item = framesRaw[i];
            x = TDate.daysToMilliseconds(item.d);
            y = 0;
            for (let j = 0, lengthJ = item.v.length; j < lengthJ; j++) {
              if (item.v[j].S === symbol) {
                y = item.v[j].A * 100;
                break;
              }
            }

            frame = null;
            if (y !== 0) {
              for (const fr of frames) {
                if (fr.day === x) {
                  frame = fr;

                  break;
                }
              }
            }

            data.push({
              x: x,
              y: y,
              trendrating: frame,
            });
          }
          var serie = {
            color: _colors.securityDefault,
            data: data,
            dataGrouping: {
              enabled: false,
            },
            events: {
              legendItemClick: function (e) {
                const self: any = this;
                _listenerLegend(e, self.chart);
              },
            },
            fillColor: "#333",
            id: "HOLDING_EXPOSURE",
            lineWidth: 0,
            name: holding.ticker,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: "%",
            },
            type: "column",
            yAxis: 1,
          };
          chartRef?.current?.addSeries(serie);
          _listenerLegend(
            {
              preventDefault: function () {
                return false;
              },
              target: {
                options: {
                  id: "HOLDING_EXPOSURE",
                },
              },
            },
            chartRef.current
          );
        },
      }),
      [_timelineData.frames]
    );

    const options = useMemo(() => {
      if (_timelineData != null) {
        return getChartOptions(
          previousSelected,
          _timelineData,
          format,
          onTimeFrameChange
        );
      }
    }, [_timelineData, format, onTimeFrameChange]);

    return (
      <Card
        sx={{
          width: "100%",
          minHeight: 200,
          position: "relative",
          boxShadow: 3,
        }}
      >
        <CardContent sx={{ height: "100%", p: 1, pb: "8px !important" }}>
          {_timelineData != null ? (
            <Chart ref={chartRef} options={options} />
          ) : null}
        </CardContent>
      </Card>
    );
  })
);

const getChartOptions = (
  previousSelected,
  value,
  format,
  onTimeFrameChange
) => {
  var yAxis: any = [
    {
      endOnTick: false,
      id: "Y_AXYS_MOMENTUM",
      lineWidth: 1,
      max: 1,
      min: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "rating %" },
      type: "linear",
      visible: previousSelected === "MOMENTUM_A",
    },
    {
      endOnTick: false,
      id: "Y_AXYS_CARDINALITY",
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "Number of securities" },
      type: "linear",
      visible: previousSelected === "CARDINALITY",
    },
    {
      endOnTick: false,
      events: {
        afterSetExtremes: function (event) {
          var info = event;
          if (info.min && info.max) {
            var limit = Math.max(Math.abs(info.min), Math.abs(info.max));
            event.target.setExtremes(-1 * limit, limit);
          }
        },
      },
      id: "Y_AXYS_PERFORMANCE",
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "performance %" },
      type: "linear",
      visible: previousSelected == null || previousSelected === "PERFORMANCE",
    },
    {
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION",
      lineWidth: 1,
      max: 1,
      min: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION",
    },
  ];

  var data = value;

  var series: any = [
    _prepareSerie(data.performance, "PERFORMANCE", format, previousSelected),
    _prepareSerie(data.momentum.D, "MOMENTUM_D", format, previousSelected),
    _prepareSerie(data.momentum.C, "MOMENTUM_C", format, previousSelected),
    _prepareSerie(data.momentum.B, "MOMENTUM_B", format, previousSelected),
    _prepareSerie(data.momentum.A, "MOMENTUM_A", format, previousSelected),
    _prepareSerie(data.cardinality, "CARDINALITY", format, previousSelected),
    _prepareSerie(data.allocation, "ALLOCATION", format, previousSelected),
  ];

  if ("long" in data && data.long && data.long.length) {
    yAxis.push({
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION_LONG",
      lineWidth: 1,
      max: 1,
      min: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation long %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION_LONG",
    });

    series.push(
      _prepareSerie(data.long, "ALLOCATION_LONG", format, previousSelected)
    );
  }

  if ("short" in data && data.short && data.short.length) {
    yAxis.push({
      endOnTick: false,
      id: "Y_AXYS_ALLOCATION_SHORT",
      lineWidth: 1,
      max: 0,
      min: -1,
      reversed: true,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      title: { text: "allocation short %" },
      type: "linear",
      visible: previousSelected === "ALLOCATION_SHORT",
    });

    series.push(
      _prepareSerie(data.short, "ALLOCATION_SHORT", format, previousSelected)
    );
  }

  const listenerClick = (e) => onTimeFrameChange(e.point.options.trendrating);

  const options: Highcharts.Options = {
    chart: {
      alignTicks: false,
      animation: true,
      borderWidth: 0,
      margin: [0, 20, 50, 0],
      type: "stock",
      spacing: [0, 0, 0, 0],
      zoomType: "x",
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    legend: {
      enabled: true,
      labelFormatter: function () {
        const self: any = this;
        var name: any = self.name;
        switch (name) {
          case "Momentum A": {
            return "Rating";
          }
          default: {
            return name;
          }
        }
      },
    },
    navigator: { enabled: false },
    plotOptions: {
      column: {
        borderColor: "#fff",
        borderWidth: 1,
        enableMouseTracking: true,
        events: {
          click: listenerClick,
        },
        groupPadding: 0,
        pointPadding: 0,
        stacking: "normal",
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        animation: false,
      },
    },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    series: series,
    xAxis: {
      lineColor: _colors.line,
      tickColor: _colors.line,
    },
    yAxis: yAxis,
  };
  return options;
};
const _prepareSerie = (data, id, format, previousSelected) => {
  var tooltip: any = {
    number: { distance: 24, valueDecimals: 0 },
    percentage: {
      distance: 24,
      pointFormatter: function () {
        const self: any = this;
        return [
          '<span style="color:',
          self.series.color,
          '">\u25CF</span> ',
          self.series.name,
          " ",
          format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: self.y,
            valueHelper: null,
          }),
        ].join("");
      },
    },
  };
  var attributes = {
    ALLOCATION: {
      color: _colors.securityDefault,
      name: "Allocation",
      showInLegend: true,
      step: true,
      size: 2,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION",
      y: 3,
    },
    ALLOCATION_LONG: {
      color: "#2f863f",
      name: "Allocation long",
      negativeColor: "#f25437",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION_LONG",
      y: 4,
    },
    ALLOCATION_SHORT: {
      color: "#f25437",
      name: "Allocation Short",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "ALLOCATION_SHORT",
      y: 5,
    },
    CARDINALITY: {
      color: _colors.securityDefault,
      name: "# constituents",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["number"],
      visible: previousSelected === "CARDINALITY",
      y: 1,
    },
    MOMENTUM_A: {
      color: _colors.securityRateA,
      name: "Momentum A",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: 0,
    },
    MOMENTUM_B: {
      color: _colors.securityRateB,
      name: "Momentum B",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: 0,
    },
    MOMENTUM_C: {
      color: _colors.securityRateC,
      name: "Momentum C",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: 0,
    },
    MOMENTUM_D: {
      color: _colors.securityRateD,
      name: "Momentum D",
      showInLegend: false,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected === "MOMENTUM_A",
      y: 0,
    },
    PERFORMANCE: {
      color: "#2f863f",
      name: "Performance",
      negativeColor: "#f25437",
      showInLegend: true,
      size: 1,
      tooltip: tooltip["percentage"],
      visible: previousSelected == null || previousSelected === "PERFORMANCE",
      y: 2,
    },
  };

  var serie = {
    color: attributes[id].color,
    data: data,
    dataGrouping: {
      enabled: false,
    },
    events: {
      legendItemClick: function (e) {
        const self: any = this;
        _listenerLegend(e, self.chart);
      },
    },
    id: id,
    lineWidth: attributes[id].size,
    name: attributes[id].name,
    negativeColor: attributes[id].negativeColor
      ? attributes[id].negativeColor
      : null,
    showInLegend: attributes[id].showInLegend,
    step: attributes[id].step ? attributes[id].step : false,
    tooltip: attributes[id].tooltip,
    type: "column",
    turboThreshold: 0,
    visible: attributes[id].visible,
    yAxis: attributes[id].y,
  };
  return serie;
};
const _legendAllocation = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION").update({ visible: false });
  chart.get("ALLOCATION").setVisible(isVisible);
};

const _legendAllocationLong = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION_LONG").update({ visible: false });
  chart.get("ALLOCATION_LONG").setVisible(isVisible);
};

const _legendAllocationShort = (isVisible, chart) => {
  chart.get("Y_AXYS_ALLOCATION_SHORT").update({ visible: false });
  chart.get("ALLOCATION_SHORT").setVisible(isVisible);
};

const _legendCardinality = (isVisible, chart) => {
  chart.get("Y_AXYS_CARDINALITY").update({ visible: false });
  chart.get("CARDINALITY").setVisible(isVisible);
};
const _legendHolding = (isVisible, chart) => {
  var serie = chart.get("HOLDING_EXPOSURE");

  if (serie) {
    serie.setVisible(isVisible);
  }
};
const _legendMomentum = (isVisible, chart) => {
  chart.get("Y_AXYS_MOMENTUM").update({ visible: false });
  chart.get("MOMENTUM_A").setVisible(isVisible);
  chart.get("MOMENTUM_B").setVisible(isVisible);
  chart.get("MOMENTUM_C").setVisible(isVisible);
  chart.get("MOMENTUM_D").setVisible(isVisible);
};
const _legendPerformance = (isVisible, chart) => {
  chart.get("Y_AXYS_PERFORMANCE").update({ visible: false });
  chart.get("PERFORMANCE").setVisible(isVisible);
};
const _listenerLegend = (event, chartRef) => {
  event.preventDefault();
  var id = event.target.options.id;
  let chart = chartRef;

  const hasLongSerie = chart.get("Y_AXYS_ALLOCATION_LONG") != null;
  const hasShortSerie = chart.get("Y_AXYS_ALLOCATION_SHORT") != null;

  switch (id) {
    case "ALLOCATION": {
      _legendAllocation(true, chart);

      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "CARDINALITY": {
      _legendCardinality(true, chart);

      _legendAllocation(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "MOMENTUM_A": {
      _legendMomentum(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "PERFORMANCE": {
      _legendPerformance(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendHolding(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }
    case "HOLDING_EXPOSURE": {
      _legendHolding(true, chart);
      _legendPerformance(false, chart);
      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }

    case "ALLOCATION_LONG": {
      _legendAllocationLong(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);

      if (hasShortSerie) {
        _legendAllocationShort(false, chart);
      }

      break;
    }

    case "ALLOCATION_SHORT": {
      _legendAllocationShort(true, chart);

      _legendAllocation(false, chart);
      _legendCardinality(false, chart);
      _legendMomentum(false, chart);
      _legendPerformance(false, chart);
      _legendHolding(false, chart);

      if (hasLongSerie) {
        _legendAllocationLong(false, chart);
      }

      break;
    }

    // no default
  }
};

export default Timeline;
