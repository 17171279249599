/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/PasswordRecoveryPage
 * @summary Password recovery page
 *
 */

import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isValidEmail, messageError, messageSuccess } from "../../app/utils";
import { useBroadcast } from "../../../hooks/useBroadcast";

type PasswordRecoveryPageProps = {
  storage: any;
};

export function PasswordRecoveryPage({ storage }: PasswordRecoveryPageProps) {
  const [email, setEmail] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const { t, ready } = useTranslation();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { broadcast } = useBroadcast();

  useEffect(() => emailInputRef.current?.focus(), []);

  useEffect(() => {
    if (ready) {
      document.title = `${t("Recover_your_password")} - Trendrating`;
    }
  }, [t, ready]);

  const handleInputChange = (event: ChangeEvent) => {
    const target: any = event.target!;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "login-email") {
      setEmail(value.trim());
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const paramsPasswordRecovery = {
      email,
    };
    recover(paramsPasswordRecovery);
  };

  const recover = ({ email }) => {
    if (isValidEmail(email)) {
      const paramsPasswordRecovery = {
        email: email,
      };

      setDisableForm(true);

      return storage["users"].passwordRecovery(paramsPasswordRecovery).then(
        (response) => {
          setDisableForm(false);
          const [channel, msg] = messageSuccess(
            t(
              "We_sent_you_an_email_Please_check_your_mail_and_follow_instructions"
            ),
            response,
            true
          );
          broadcast(channel as string, msg);
        },
        (error) => {
          setDisableForm(false);
          const [channel, msg] = messageError(t("Email_is_not_valid"), error);
          broadcast(channel as string, msg);
        }
      );
    }

    const [channel, msg] = messageError(t("Email_is_not_valid"));
    broadcast(channel as string, msg);
  };

  return (
    <div className="PagePasswordRecovery">
      <form
        action="#login"
        className="Form"
        method="post"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <legend>{t("Recover_your_password")}</legend>
          <ol>
            <li>
              <label htmlFor="login-email">{t("Email")}</label>
              <br />
              <input
                className="Input"
                disabled={disableForm}
                id="login-email"
                name="login-email"
                onChange={handleInputChange}
                ref={emailInputRef}
                type="email"
                value={email}
              />
            </li>
            <li className="Form-buttons">
              <button
                className="Button Button--primary"
                disabled={disableForm}
                type="submit"
              >
                {disableForm ? t("Sending_email") : t("Recover")}
              </button>
            </li>
            <li className="Form-help">
              <Link to="/">{t("Back_to_login")}</Link>
              <span className="a11y">|</span>
              <a href={`mailto:${t("mailto")}`}>{t("Need_help")}</a>
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
}
