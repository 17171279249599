/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/account/Users
 * @summary Requests for users
 *
 */

import { deepClone } from "../../deepClone";
import { endpoints } from "../endpoints";
import { _Base } from "../_Base";
import { decodeUser } from "./Decoder";

export class Users extends _Base {
    /**
     * Send email to support
     *
     * @param {object} params - request parameters
     * @param {string} params.from - email address of the user
     * @param {string} params.fromName - Name and surname of the user
     * @param {string} params.message - email message
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    askSupport(params: any): any {
        const endPointRoot = this.getEndpointRoot(this.environment.api.rest);
        const url = endPointRoot + endpoints.accounts.users.askSupport;

        const paramsAskSupport = deepClone(params);
        paramsAskSupport.additionalCookies =
            this.environment.account.user?.additionalCookies;

        return this.preparePost(url, paramsAskSupport);
    }

    /**
     * Get users
     *
     * @param {object} params - request parameters
     * @param {Array}  params.ids - user ids to be retrieved
     * @param {Array}  params.properties - properties to be retrieved
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    get(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.users.get;

        const paramsGet: any = [
            {
                classType: "USER",
                extendedRequest: {
                    onDemandResult: [],
                },
                id: params["ids"] != null ? params["ids"] : null,
            },
        ];
        for (const property of params["properties"]) {
            paramsGet[0]["extendedRequest"]["onDemandResult"].push({
                dimension: property,
            });
        }

        return this.preparePost(url, paramsGet, null);
    }

    /**
     * Get users by filters (new API)
     *
     * @param {object} params - request parameters
     * @param {Array}  params.ids - (optional) user ids to be retrieved
     * @param {Array}  params.active - search by active status
     *
     * @returns {dojo/promise/Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async getByFilter(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        const url = endPointRoot + endpoints.accounts.users.getByIdsFilter;

        const _params: any = {
            searches: [
                {
                    filters: [],
                },
            ],
        };

        if (params != null) {
            if (params.ids != null) {
                _params.searches[0].filters.push({
                    dimension: "ownerId",
                    segments: params.ids,
                });
            }
            if (params.active != null) {
                _params.searches[0].filters.push({
                    dimension: "active",
                    segments: [params.active],
                });
            }
        }

        const response = await this.preparePost(url, _params, null);
        return response.data.ids;
    }

    /**
     * Impersonate another user
     *
     * @param {number} userId - the ID of the user to impersonate
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async impersonate(userId: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.users.impersonate;

        const response = await this.prepareGet(url, { id: userId }, null);
        return response.data.user;
    }

    /**
     * User login
     *
     * @param {object} params - login parameters
     * @param {string} params.email - user email
     * @param {string} params.password - user password
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async login({ email, password }: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const urlCookies = endPointRoot + endpoints.accounts.sessions.remove;
        const urlLogin = endPointRoot + endpoints.accounts.users.login;

        const basicAuth = this._basicAuth(email, password);

        const headers = {
            Authorization: basicAuth,
        };

        /*const removeCookiesResponse =*/ await this.prepareGet(
            urlCookies,
            null,
            null
        );

        const response = await this.preparePost(
            urlLogin,
            { email, password },
            headers
        );
        return decodeUser(response);
    }

    /**
     * User logout
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    logout() {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.users.logout;
        return this.prepareGet(url, null, null);
    }

    /**
     * Password recovery: call to begin the process
     *
     * @param {object} params - password recovery parameters
     * @param {string} params.email - user email
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async passwordRecovery(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        const url = endPointRoot + endpoints.accounts.users.passwordRecovery;

        const responsePasswordRecovery = await this.prepareGet(url, params);

        const paramsPasswordRecoveryMail = {
            code: responsePasswordRecovery.data.code,
            email: params.email,
            uid: responsePasswordRecovery.data.uid,
        };

        return this.passwordRecoveryMail(paramsPasswordRecoveryMail);
    }

    /**
     * Send email with steps to recover password
     *
     * @param {object} params - password recovery parameters
     * @param {number} params.code - generated by the server through
     *      passwordRecovery() call
     * @param {string} params.email - user email
     * @param {number} params.uid - generated by the server through
     *      passwordRecovery() call
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    passwordRecoveryMail(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.rest);
        const url =
            endPointRoot + endpoints.accounts.users.passwordRecoveryMail;

        return this.preparePost(url, params, null);
    }

    /**
     * Start new password setup
     *
     * @param {object} params - password setup parameters
     * @param {string} params.email - user email
     * @param {string} params.password - user password
     * @param {number} params.uid - generated by the server through
     *      passwordRecovery() call
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    async passwordSetup(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        const url = endPointRoot + endpoints.accounts.users.passwordSetupCheck;
        const urlPasswordSetupConfirm =
            endPointRoot + endpoints.accounts.users.passwordSetupConfirm;

        const response = await this.prepareGet(url, params);

        const uid = response.data ? response.data.uid : null;

        if (uid == null) {
            // link expired
            return Promise.reject({
                response: response,
                status: 410, // Gone
            });
        }

        const paramsPasswordSetupConfirm = {
            email: params.email,
            password: params.password,
            uid2: uid,
        };

        return this.prepareGet(
            urlPasswordSetupConfirm,
            paramsPasswordSetupConfirm
        );
    }

    async passwordReset(params: any, callBack: Function){
        const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
        const url = endPointRoot + "/user/resetpwd";
        // 'http://localhost:3000/trendrating-rest/api/user/resetpwd'
        const response = await this.prepareGet(url, params);
        callBack(response);
        // debugger;
    }

    _basicAuth(email: string, password: string) {
        const token = email + ":" + password;
        const textEncoder = new TextEncoder();
        const hash = window.btoa(
            String.fromCharCode(...new Uint8Array(textEncoder.encode(token)))
        );

        return "Basic " + hash;
    }
}
