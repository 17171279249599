import { Box, Button, Typography } from "@mui/material";

type BacktestWarningDialogProps = {
  onCancel: (event) => any;
  onContinue: (event) => any;
};

export function BacktestWarningDialog({
  onContinue,
  onCancel,
}: BacktestWarningDialogProps) {
  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      <Typography color={"warning"}>
        Please confirm the update on backtesting.{" "}
        <strong style={{color:"red"}}>The action is irreversible.</strong>
      </Typography>
      <Box display={"flex"} gap={2} justifyContent={"flex-start"}>
        <Button variant="contained" onClick={onContinue}>
          Continue
        </Button>
        <Button variant="tr_button_cancel" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
