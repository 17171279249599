import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { decodePeerId } from "../../../../../../../../../api/utils";
import { Spinner } from "../../../../../../../../../components/Spinner/Spinner";
import { useTaxonomyByType } from "../../../../../../../../../hooks/useTaxonomyByType";
import { InfoTooltip } from "../../../../../../alerts/InfoTooltip";
import {
  ActionsETFMarketsControls,
  DispersionViewActionMarketsETF,
} from "../../../../AnalysisMarketsETF";
import { SelectorETFMarkets } from "../../../FiltersBarMarketDetail/FiltersBarMarketDetail";
import InstrumentsTableForAnalytics from "../InstrumentsTableForAnalytics/InstrumentsTableForAnalytics";
import DispersionBlock from "./DispersionBlock/DispersionBlock";
import styles from "./DispersionView.module.scss";
import { useEnvironment } from "../../../../../../../../../hooks/useEnvironment";

type DispersionViewProps = {
  dispersionDispatcher: (action: DispersionViewActionMarketsETF) => void;
  data: any;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  tableCellNameFormatter: Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  performance: "3_months" | "6_months" | "12_months";
  intervals: 4 | 10 | 20;
  trimOutliers: boolean;
  segmentReference: any;
  dispersionPeerId: string | undefined;
};

type PeerTitleDescriptionProps = {
  data: any;
};

const performanceOptions = [
  {
    label: "3 Months",
    value: "3_months",
  },
  {
    label: "6 Months",
    value: "6_months",
  },
  {
    label: "12 Months",
    value: "12_months",
  },
];

const percentageOptions = [
  {
    label: "25%",
    value: 4,
  },
  {
    label: "10%",
    value: 10,
  },
  {
    label: "5%",
    value: 20,
  },
];

export function DispersionView({
  segment,
  data,
  dispersionDispatcher,
  tableCellNameFormatter,
  dispatch,
  intervals,
  performance,
  trimOutliers,
  segmentReference,
  dispersionPeerId,
}: DispersionViewProps) {
  const [showTcrTable, setShowTcrTable] = useState(false);

  // !ATTENTION: VERY PARTICULAR CASE
  // In this useEffect we take care about the appearence of the instrument table in this tab view.
  //
  // ?BUSINESS LOGIC:
  // When the xDim key, referred to the taxonomie's y axis, is setted to a node that  is a leaf, in taxonomie's hierarchy (has no children levels under itself) we want to hide the table with the resume of
  // instruments to avoid misunderstandigs by the user, because the switch of the drilldown from the yDimension
  // to the xDimension that can confuse.
  // But the drilldown possibilities are still valid,  infact we can cluster the peer by geo dimension. So
  // for this reason if a user want to change the drilldown segment to see a cluster by geo dimension, we need to
  // make the table newly visible.
  //
  // ?TECH SOLUTION
  // In the AnalysisMarketsETF.tsx file is explained the fist part related to the management of this case and everything is documented.
  // We have stored a reference to the selected drilldown segment when the yDim of the peer is changed
  // into a leaf.
  // We show the table only when the stored segment is different from the current drilldown segment,
  // because if they are different it means that the user has changed the drilldown segment. But if the user
  // come back on the Country segment that is the default for this case, the table would be hided, if we don't
  // modify the value of the reference.
  // So for this when the reference and the current segments are equals (it can happend only at first render)
  // we change the reference value into another that cannot ever match the segment value.
  // useEffect(() => {
  //   if (segmentReference.current !== segment) {
  //     setShowTcrTable(true);
  //   } else {
  //     segmentReference.current = "any";
  //   }
  // }, [segment, segmentReference]);
  useEffect(() => {
    setShowTcrTable(segmentReference);
  }, [segmentReference]);

  const updateParams = useCallback(
    (peerId) => {
      dispersionDispatcher({ type: "SET_PEER_ID", payload: peerId });
    },
    [dispersionDispatcher]
  );

  const setPerformaceTimeframe = useCallback(
    (value) => {
      dispersionDispatcher({ type: "SET_PERFORMANCE", payload: value });
    },
    [dispersionDispatcher]
  );

  const setInterval = useCallback(
    (value) => {
      dispersionDispatcher({ type: "SET_INTERVALS", payload: value });
    },
    [dispersionDispatcher]
  );

  const setTrimOutliers = useCallback(
    (value) => {
      dispersionDispatcher({ type: "SET_TRIM_OUTLIERS", payload: value });
    },
    [dispersionDispatcher]
  );

  const environment = useEnvironment();

  const showDispersionRating = useMemo(() => {
    const configuration =
      environment.get("account")["product"]["configuration"]["analysis_peer"];

    return configuration?.tabs?.ETF?.dispersion?.dipersionByRating ?? false;
  }, [environment]);

  return (
    <Box
      minHeight={0}
      display={"flex"}
      flex={1}
      boxShadow={3}
      p={1}
      borderRadius={2}
    >
      {data?.peer?.info?.cardinality > 0 ? (
        <Box minHeight={0} flex={1} gap={1} display={"flex"}>
          {data?.dispersion ? (
            <>
              {showTcrTable && (
                <Box minHeight={0} flex={1} display={"flex"}>
                  {/* Instruments Table */}
                  {data?.peer?.info.cardinality > 3 ? (
                    <InstrumentsTableForAnalytics
                      nameFormatter={tableCellNameFormatter}
                      segment={segment}
                      type={"ETF"}
                      data={data}
                      dispatch={dispatch}
                      paramsUpdate={updateParams}
                      resetContextualParams={() =>
                        dispersionDispatcher({
                          type: "RESET_TO_DEFAULT",
                        })
                      }
                    />
                  ) : (
                    <Card>
                      <CardContent>
                        <Typography fontSize={"14px"} fontWeight={"bold"}>
                          The ETF you are lookng for has not enough constituents
                          to calculate dispersion (at least 4)
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </Box>
              )}
            </>
          ) : (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          <Box flex={showTcrTable ? 3 : 1} minHeight={0} display={"flex"}>
            {/* Dispersion data */}
            <Card sx={{ flex: 1, display: "flex", minHeight: 0 }}>
              <CardContent
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "5px!important",
                  minHeight: 0,
                }}
              >
                {data?.dispersion ? (
                  <>
                    {data?.peer?.info.cardinality > 0 ? (
                      <>
                        {data?.peer?.info.cardinality > 3 && (
                          <>
                            {data.dispersion.bottom.cardinality === 0 &&
                            data.dispersion.top.cardinality === 0 &&
                            data.dispersion.middle.cardinality === 0 ? (
                              <Typography fontSize={"14px"} fontWeight={"bold"}>
                                Not enough ETFs to calculate dispersion
                              </Typography>
                            ) : (
                              <>
                                <Card
                                  sx={{
                                    display: "flex",
                                    border: "1px solid #2A7090",
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flex: 1,
                                      justifyContent: "space-between",
                                      paddingTop: "5px",
                                      paddingBottom: "5px!important",
                                    }}
                                  >
                                    <PeerTitleDescription
                                      data={dispersionPeerId ?? data?.peer?.id}
                                    />

                                    <SelectorETFMarkets
                                      options={performanceOptions}
                                      selectedOption={performance}
                                      selectOption={setPerformaceTimeframe}
                                    />
                                    <SelectorETFMarkets
                                      selectOption={setInterval}
                                      options={percentageOptions}
                                      selectedOption={intervals}
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <FormControlLabel
                                        sx={{
                                          fontSize: "12px!important",
                                        }}
                                        control={
                                          <Switch
                                            sx={{
                                              fontSize: "12px!important",
                                            }}
                                            size="small"
                                            checked={trimOutliers}
                                            onChange={(e) =>
                                              setTrimOutliers(e.target.checked)
                                            }
                                          />
                                        }
                                        classes={{
                                          label: styles.switch__label,
                                        }}
                                        label="Trim Outliers"
                                      />
                                      <InfoTooltip
                                        style={{
                                          top: 5,
                                        }}
                                        title={""}
                                        text="The outliers are securities that perform abnormally relative to the average of all stocks in the same set."
                                      />
                                    </div>
                                  </CardContent>
                                </Card>
                                <Box
                                  mt={1}
                                  flex={1}
                                  display={"flex"}
                                  flexDirection={"column"}
                                  gap={1}
                                >
                                  <DispersionBlock
                                    data={data.dispersion}
                                    intervalType="top"
                                    intervalValue={intervals}
                                    type={"ETF"}
                                    showRating={showDispersionRating}
                                  />
                                  <DispersionBlock
                                    data={data.dispersion}
                                    intervalType="middle"
                                    intervalValue={intervals}
                                    type={"ETF"}
                                    showRating={showDispersionRating}
                                  />
                                  <DispersionBlock
                                    data={data.dispersion}
                                    intervalType="bottom"
                                    intervalValue={intervals}
                                    type={"ETF"}
                                    showRating={showDispersionRating}
                                  />
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography fontSize={"14px"} fontWeight={"bold"}>
                        Any ETF was found
                      </Typography>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                )}
              </CardContent>
            </Card>
          </Box>
        </Box>
      ) : (
        <Grid item md={12} mt={1}>
          <Card>
            <CardContent>
              <Typography fontSize={"14px"} fontWeight={"bold"}>
                Any ETF was found
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Box>
  );
}

const PeerTitleDescription = ({ data }: PeerTitleDescriptionProps) => {
  const { rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY } =
    useTaxonomyByType("ETF");
  const name = useMemo(() => {
    const { where, what, zDimension } = decodePeerId(data);
    const whatName = taxonomiesMapY[what]?.name ?? what;
    const whereName = taxonomiesMapX[where]?.name ?? where;

    let name = "";

    if (whatName !== rootNodeY) {
      name = whatName;
    }

    if (whereName !== rootNodeX) {
      name += ` - ${whereName}`;
    }

    if (zDimension !== "ALL") {
      name += ` - ${zDimension}`;
    }

    if (name.length === 0) {
      name = "Any Asset Class";
    }

    return name;
  }, [data, rootNodeX, rootNodeY, taxonomiesMapX, taxonomiesMapY]);
  return <Typography>{name}</Typography>;
};
