import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputNumber from "../../../../../../../../../../components/InputNumber/InputNumber";

type LeverageProps = {
  dynamicHedgingActive: boolean;
  dipatcher: Function;
  value: number | null;
};

export default function Leverage({ dynamicHedgingActive, dipatcher, value }: LeverageProps) {
  const [state, setState] = useState(value);

  useEffect(() => {
    dipatcher(state);
  }, [dipatcher, state]);

  return (
    <Box display={"flex"} alignItems="center" gap={1}>
      <Typography>Leverage</Typography>
      <InputNumber
        maxWidth={70}
        constraints={{ maxVal: 1, minVal: 0 }}
        disableGutters={true}
        initVal={value ?? 1}
        isDisabled={dynamicHedgingActive}
        isPercentage={false}
        setOuterState={(e) => setState(e)}
        isFloating={true}
      />
    </Box>
  );
}
