/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/CompareStrategies
 * @summary Requests for Compare Strategies
 *
 */

import { _StoredObjects } from "../_StoredObjects";

export class CompareStrategies extends _StoredObjects {
    storedObjectType = "COMPARED_STRATEGY" as const;

    getPrototype() {
        return {
            id: null,
            name: null,
            strategy1: {
                id: null,
                value: 0,
            },
            strategy2: {
                id: null,
                value: 0,
            },
            type: this.storedObjectType,
        };
    }
}
