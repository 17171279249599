import {
  BrowserRouter as Router,
  generatePath,
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { AppAccount } from "./js/app-account/AppAccount";
import { App } from "./js/app/App";
import "./styles/index.scss";

/**
 * Redirect using the actual parameters.
 *
 * @param path - the destination path, in Route format
 * @returns
 */
export function NavigateTo({ path }: { path: string }) {
  const params = useParams();
  const resultPath = generatePath(path, params);
  return <Navigate to={resultPath} replace={true} />;
}

export function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/app/*" element={<App />} />
        <Route path="/analysis/security">
          <Route
            index
            element={<NavigateTo path="/app/analysis/instrument" />}
          />
          <Route
            path="/analysis/security/:symbol"
            element={<NavigateTo path="/app/analysis/instrument/:symbol" />}
          />
        </Route>
        <Route path="*" element={<AppAccount />} />
      </Routes>
    </Router>
  );
}
