export const defaultTemplateAlertsReport = [
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: null,
        systematicProduct: null,
      },
      orientation: "portrait",
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            // eslint-disable-next-line no-template-curly-in-string
            text: "What's new ${timeframe}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_LIST_ALERTS_TABLE",
          content: {
            headline: {
              content: "Baskets",
              isEnabled: true,
            },
            sortBy: {
              property: "TCR",
              descending: true,
            },
            timeframe: "today",
            listType: "BASKET",
          },
          presentation: {
            columns: [
              {
                label: "Name",
                property: "name",
              },
              {
                label: "TCR",
                property: "TCR",
              },
              {
                label: "Upgrades",
                property: "upgrades",
              },
              {
                label: "Downgrades",
                property: "downgrades",
              },
              {
                label: "Movers Up",
                property: "moversUp",
              },
              {
                label: "Movers Down",
                property: "moversDown",
              },
            ],
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          isEdited: false,
        },
        {
          type: "REPORT_LIST_ALERTS_TABLE",
          content: {
            headline: {
              content: "Portfolios",
              isEnabled: true,
            },
            sortBy: {
              property: "TCR",
              descending: true,
            },
            timeframe: "today",
            listType: "PORTFOLIO",
          },
          presentation: {
            columns: [
              {
                label: "Name",
                property: "name",
              },
              {
                label: "TCR",
                property: "TCR",
              },
              {
                label: "Upgrades",
                property: "upgrades",
              },
              {
                label: "Downgrades",
                property: "downgrades",
              },
              {
                label: "Movers Up",
                property: "moversUp",
              },
              {
                label: "Movers Down",
                property: "moversDown",
              },
            ],
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          content: {
            fontSize: "large",
            text: "<b><u>Notes</u></b>",
          },
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_PARAGRAPH",
        },
        {
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_SPACING",
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Tracking TCR (Trend Combined Risk) across different Portfolios/Baskets</b> involves documenting the percentage of stocks in a bull trend (AB%) versus those in a bear trend (CD%). The ratio between these two groups determines the TCR. The TCR level serves as an indicator of the strength or weakness within the particular Porftolios/Baskets.",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Upgrades and Downgrades,</b> shown within the tables, showcases the count of positive rating changes (upgrades) compared to negative rating changes (downgrades) during the specified time period.",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_PARAGRAPH",
          content: {
            fontSize: "large",
            text: "<b>Movers Up/Movers Down:</b> the movers are stocks in a positive/negative trend that have not retraced from the last issued rating.",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: false,
    name: "Alerts",
    ownerId: null,
    type: "TEMPLATE_REPORT_ALERTS",
    version: "1.0",
  },
];
