import { Box, Tab, Tabs } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  TrendratingTable,
  reactFormatter,
} from "../../../../../../../../../../components/table/TrendratingTable";
import { deepClone } from "../../../../../../../../../../deepClone";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import Scatter from "./Scatter";

type Props = {
  data: any;
  isUsedInCompare?: boolean;
};
const defaultFormatterType = "PERCENTAGE";

export const Analytics = ({ data, isUsedInCompare }: Props) => {
  type ChoosenTabType = "YEARLY" | "MONTHLY" | "QUARTERLY" | "SCATTER";
  const hasBenchmark = useMemo(() => {
    if (data.strategy.params.strategy.benchmark != null) {
      return true;
    }
    return false;
  }, [data.strategy.params.strategy.benchmark]);
  const [choosenTab, setChoosenTab] = useState<ChoosenTabType>("YEARLY");

  const scatterAndTableData = useMemo(() => {
    if (data) {
      return scatterDataPrepare(data.analytics);
    }
  }, [data]);

  const tableData = useMemo(() => {
    if (choosenTab === "YEARLY") {
      return scatterAndTableData.yearly;
    } else if (choosenTab === "MONTHLY") {
      return scatterAndTableData.monthly;
    } else if (choosenTab === "QUARTERLY") {
      return scatterAndTableData.quarterly;
    }
    return scatterAndTableData.chartScatter;
  }, [
    choosenTab,
    scatterAndTableData.chartScatter,
    scatterAndTableData.monthly,
    scatterAndTableData.quarterly,
    scatterAndTableData.yearly,
  ]);
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: ChoosenTabType) => {
      setChoosenTab(newValue);
    },
    []
  );
  const formatter = useFormatter();
  const renderCell = useCallback(
    (type) => {
      return (cell, formatterParams, onRendered) => {
        const object = cell.getData();
        const value = cell.getValue();
        if (value === "Annualized" || value === "average") {
          let row = cell.getRow();
          let element = row.getElement();
          element.style.fontWeight = 600;
        }
        if (value === "Annualized") {
          return value;
        }
        if (value === "average") {
          return "Average";
        }
        let innerHTML = "";
        switch (type) {
          case "DATE": {
            const tokens = value.split("_");
            const date = new Date(
              Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
            );
            innerHTML = formatter.custom("date", {
              options: {
                format: ["M", "Y"],
                isMillisecond: true,
                notAvailable: {
                  input: "",
                  output: "",
                },
                separator: " ",
              },
              output: "HTML",
              value: date.getTime(),
              valueHelper: null,
            });

            break;
          }
          case "DATE_QUARTER": {
            const tokens = value.split("_");
            const date = new Date(
              Date.UTC(parseInt(tokens[0]), parseInt(tokens[1]) - 1, 1)
            );
            var quarter = "";
            switch (tokens[1]) {
              case "01":
              case "02":
              case "03": {
                quarter = "Q1";
                break;
              }
              case "04":
              case "05":
              case "06": {
                quarter = "Q2";
                break;
              }
              case "07":
              case "08":
              case "09": {
                quarter = "Q3";
                break;
              }
              case "10":
              case "11":
              case "12": {
                quarter = "Q4";
                break;
              }
            }
            innerHTML =
              quarter +
              " " +
              formatter.custom("date", {
                options: {
                  format: ["Y"],
                  isMillisecond: true,
                  notAvailable: {
                    input: "",
                    output: "",
                  },
                  separator: " ",
                },
                output: "HTML",
                value: date.getTime(),
                valueHelper: null,
              });

            break;
          }
          case "PERCENTAGE": {
            innerHTML = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: value,
              valueHelper: {
                normalizationThreshold: 1,
              },
            });

            break;
          }
          case "PERCENTAGE_AND_BAR": {
            innerHTML = formatter.custom("bar", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "HTML",
              value: value,
              valueHelper: {
                normalizationThreshold: object["_s_normalizationThreshold"],
              },
            });

            break;
          }
        }

        return innerHTML;
      };
    },
    [formatter]
  );

  const [currentFormatterType, setCurrentFormatterType] =
    useState(defaultFormatterType);

  let baseClass = "StrategyAnalytics";
  var cssClassSwitch = baseClass + "-formatterSwitch";
  var cssClassSwitchOff = cssClassSwitch + "--off";
  var cssClassSwitchOn = cssClassSwitch + "--on";

  const componente = useCallback(
    (title) => (
      <>
        <span>{title}</span>
        <button
          className={[
            cssClassSwitch,
            currentFormatterType === "PERCENTAGE"
              ? cssClassSwitchOff
              : cssClassSwitchOn,
          ].join(" ")}
          onClick={() =>
            currentFormatterType === "PERCENTAGE"
              ? setCurrentFormatterType("PERCENTAGE_AND_BAR")
              : setCurrentFormatterType("PERCENTAGE")
          }
          title="Show/hide Difference histogram"
        >
          <span className="i-beta"></span>
        </button>
      </>
    ),
    [cssClassSwitch, cssClassSwitchOff, cssClassSwitchOn, currentFormatterType]
  );
  const prepareColumns = useCallback(
    (frameSize, formatterType, hasBenchmark) => {
      formatterType =
        formatterType == null ? defaultFormatterType : formatterType;

      var columns: any = [];
      var labelStrategy = "Strategy";
      var benchmark = isUsedInCompare === true ? "Strategy 2" : "Benchmark";

      switch (frameSize) {
        case "MONTHLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Month",
                    formatter: renderCell("DATE"),
                  },
                ],
              },
              {
                title: "Monthly performance",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyMonthlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "benchmarkMonthlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "deltaMonthlyPerformance",
                    title: "Difference",
                    formatter: renderCell(formatterType),
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Monthly performance")
                ),
              },
              {
                title: "Max drawdown",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
              {
                title: "Monthly return stdDev",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Month",
                formatter: renderCell("DATE"),
              },
              {
                field: "strategyMonthlyPerformance",
                title: "Monthly performance",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyVolatility",
                title: "Monthly return stdDev",
                formatter: renderCell("PERCENTAGE"),
              },
            ];
          }
          break;
        }
        case "QUARTERLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                sorter: "number",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Quarter",
                    formatter: renderCell("DATE_QUARTER"),
                  },
                ],
              },
              {
                title: "Quarterly performance",
                sorter: "number",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyQuarterlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "benchmarkQuarterlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "deltaQuarterlyPerformance",
                    title: "Difference",
                    widthGrow: 1,
                    formatter: renderCell(formatterType),
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Quarterly performance")
                ),
              },
              {
                title: "Max drawdown",
                sorter: "number",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
              {
                title: "Quarterly return stdDev",
                sorter: "number",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Quarter",
                formatter: renderCell("DATE_QUARTER"),
              },
              {
                field: "strategyQuarterlyPerformance",
                title: "Quarterly performance",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyVolatility",
                title: "Quarterly return stdDev",
                formatter: renderCell("PERCENTAGE"),
              },
            ];
          }

          break;
        }
        case "YEARLY": {
          if (hasBenchmark) {
            columns = [
              {
                title: "",
                columns: [
                  {
                    field: "timeFrame",
                    title: "Year",
                    formatter: (cell) => {
                      const value = cell.getValue();
                      if (value === "Annualized" || value === "average") {
                        let row = cell.getRow();
                        let element = row.getElement();
                        element.style.fontWeight = 600;
                      }
                      return value !== "average" ? value : "Average";
                    },
                  },
                ],
              },
              {
                title: "Yearly performance",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyYearlyPerformance",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "benchmarkYearlyPerformance",
                    title: benchmark,
                    formatter: renderCell("PERCENTAGE"),
                  },
                  {
                    sorter: "number",
                    field: "deltaYearlyPerformance",
                    title: "Difference",
                    formatter: renderCell(formatterType),
                  },
                ],
                titleFormatter: reactFormatter(
                  componente("Yearly performance")
                ),
              },
              {
                title: "Max drawdown",
                columns: [
                  {
                    sorter: "number",
                    field: "strategyMaxDrawdown",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
              {
                title: "Volatility",
                titleFormatter: (cell) => {
                  return '<span title="Monthly return stdDev">Volatility</span>';
                },
                columns: [
                  {
                    sorter: "number",
                    field: "strategyVolatility",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
              {
                title: "One way turnover",
                columns: [
                  {
                    sorter: "number",
                    field: "turnover",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
            ];
          } else {
            columns = [
              {
                field: "timeFrame",
                title: "Year",
              },
              {
                field: "strategyYearlyPerformance",
                title: "Yearly performance",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyMaxDrawdown",
                title: "Max drawdown",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                field: "strategyVolatility",
                title: "Volatility",
                formatter: renderCell("PERCENTAGE"),
              },
              {
                title: "One way turnover",
                columns: [
                  {
                    sorter: "number",
                    field: "turnover",
                    title: labelStrategy,
                    formatter: renderCell("PERCENTAGE"),
                  },
                ],
              },
            ];
          }

          break;
        }
      }
      // Common fields
      if (hasBenchmark) {
        columns[2].columns.push({
          field: "benchmarkMaxDrawdown",
          title: benchmark,
          formatter: renderCell("PERCENTAGE"),
        });
        columns[2].columns.push({
          field: "deltaMaxDrawdown",
          title: "Difference",
          formatter: renderCell("PERCENTAGE"),
        });
        columns[3].columns.push({
          field: "benchmarkVolatility",
          title: benchmark,
          formatter: renderCell("PERCENTAGE"),
        });
        columns[3].columns.push({
          field: "deltaVolatility",
          title: "Difference",
          formatter: renderCell("PERCENTAGE"),
        });
      }
      return columns;
    },
    [componente, isUsedInCompare, renderCell]
  );

  return (
    <Box p={1} flex={1} minHeight={0} minWidth={0}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: "divider" }}
        value={choosenTab}
        onChange={handleChange}
      >
        <Tab label="Yearly" value="YEARLY" />
        <Tab label="Monthly" value="MONTHLY" />
        <Tab label="Quarterly" value="QUARTERLY" />
        <Tab label="Scatter" value="SCATTER" />
      </Tabs>
      {choosenTab !== "SCATTER" && (
        <Box overflow={"auto"} height={"100%"} py={1} width={"100%"}>
          <TrendratingTable
            disableDefaultRowClick
            autoResize={true}
            columns={prepareColumns(
              choosenTab,
              currentFormatterType,
              hasBenchmark
            )}
            correction={20}
            data={tableData}
            options={{
              selectableRows: false,
              ajaxSorting: false,
              columnHeaderVertAlign: "top",
            }}
          />
        </Box>
      )}
      {choosenTab === "SCATTER" && <Scatter data={scatterAndTableData} />}
    </Box>
  );
};

const getAggregatedData = (data) => {
  const total = {
    equity: { StdDev: null, AnnualizedRateOfReturn: null },
    benchmark: { StdDev: null, AnnualizedRateOfReturn: null },
    diff: { StdDev: null, AnnualizedRateOfReturn: null },
  };

  const annualizedPerformances = data?.yearly?.find(
    (point) => point.timeFrame === "Annualized"
  );

  if (annualizedPerformances) {
    if (annualizedPerformances["strategyYearlyPerformance"]) {
      total["equity"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["strategyYearlyPerformance"];
    }

    if (annualizedPerformances["benchmarkYearlyPerformance"]) {
      total["benchmark"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["benchmarkYearlyPerformance"];
    }

    if (annualizedPerformances["deltaYearlyPerformance"]) {
      total["diff"]["AnnualizedRateOfReturn"] =
        annualizedPerformances["deltaYearlyPerformance"];
    }
  }

  const averagePerformances = data.yearly.find(
    (point) => point.timeFrame === "average"
  );

  if (averagePerformances) {
    if (averagePerformances["strategyVolatility"]) {
      total["equity"]["StdDev"] = averagePerformances["strategyVolatility"];
    }

    if (averagePerformances["benchmarkVolatility"]) {
      total["benchmark"]["StdDev"] = averagePerformances["benchmarkVolatility"];
    }

    if (averagePerformances["deltaVolatility"]) {
      total["diff"]["StdDev"] = averagePerformances["deltaVolatility"];
    }
  }

  return total;
};

const scatterDataPrepare = (value) => {
  let analytics: any = {
    chartScatter: null,
    monthly: null,
    quarterly: null,
    yearly: null,
  };

  analytics.chartScatter = {
    strategy: value.strategy,
    strategyInstrumentBenchmark: value.strategyInstrumentBenchmark,
    total: getAggregatedData(value.analytics),
    years: deepClone(value.analytics.yearly).filter(function (item) {
      if (item.timeFrame !== "Annualized" && item.timeFrame !== "average") {
        return true;
      }

      return false;
    }),
  };

  // compute normalizationThreshold to be used in bar formatter
  analytics.monthly = normalizeAndCutOutliers(
    deepClone(value.analytics.monthly),
    "deltaMonthlyPerformance"
  );
  analytics.quarterly = normalizeAndCutOutliers(
    deepClone(value.analytics.quarterly),
    "deltaQuarterlyPerformance"
  );
  analytics.yearly = normalizeAndCutOutliers(
    deepClone(value.analytics.yearly),
    "deltaYearlyPerformance"
  );

  return analytics;
};
const normalizeAndCutOutliers = (arrayOfObject, property) => {
  if (arrayOfObject.length) {
    var rawData = deepClone(arrayOfObject);
    var LENGTH = rawData.length;
    rawData = rawData.sort(function (a, b) {
      var aAbs = a ? Math.log(Math.abs(a[property])) : a;
      var bAbs = b ? Math.log(Math.abs(b[property])) : b;

      if (aAbs > bAbs) {
        return -1;
      }
      if (aAbs < bAbs) {
        return 1;
      }

      return 0;
    });

    var maxIndex = Math.min(
      LENGTH - 1,
      parseInt(((3 / 4) * (LENGTH + 1)).toString())
    );
    var minIndex = parseInt(((1 / 4) * (LENGTH + 1)).toString());
    var max = rawData[maxIndex][property];
    var min = rawData[minIndex][property];
    var IQR = max - min;
    var threshold = Math.abs(IQR * 1.5);

    for (var i = 0, length = arrayOfObject.length; i < length; i++) {
      arrayOfObject[i]["_s_normalizationThreshold"] = threshold;
    }
  }

  return arrayOfObject;
};
