/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/pages/help/pages/index/Index
 * @summary Index of help
 *
 */

import { useEffect } from "react";

import { Help } from "./Help";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { config } from "../../config-ts";

export function HelpIndex() {
  const { broadcast } = useBroadcast();

  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");

    var message = {
      from: "help",
      content: {
        actions: [],
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast]);

  return (
    <article className="tPageHelp">
      {/* eslint-disable-next-line no-undef */}
      <Help staticAssets={appConfig.baseUrlStaticAssets} />
    </article>
  );
}
