import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import SecurityChartModal from "../../../../../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltip from "../../../../../../../../../../components/SecurityTooltip/SecurityTooltip";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { TDate } from "../../../../../../../../../../trendrating/date/TDate";
import { config } from "../../../../../../../../config-ts";
import { Product } from "../../../../../../../../storage/SystematicPortfoliosStorage";
import { messageError, messageSuccess } from "../../../../../../../../utils";
import { useActionModal } from "../../../../../../../../utils/useActionModal";
import { AllocationEditor } from "./AllocationEditor/AllocationEditor";
import styles from "./HistoricalAllocation.module.scss";
import { useBroadcast } from "../../../../../../../../../../hooks/useBroadcast";
import Search from "../../../../../../../../widgets/ReactSearch/Search";

type HistoricalAllocationProps = {
  product: Product;
};

export function HistoricalAllocation({ product }: HistoricalAllocationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const formatter = useFormatter();
  const [columns, setColumns] = useState<any>([]);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [securityModalData, setSecurityModalData] = useState<any>();
  const [tableKey, setTableKey] = useState(Date.now());
  const { setShowModal, setContent, setModalTitle, setOptions } =
    useActionModal();
  const { broadcast } = useBroadcast();

  const environment = useEnvironment();

  const redrawTable = useCallback(() => {
    setTableKey(Date.now());
  }, []);

  const getCurrentData = useCallback(() => {
    return collectionData.current;
  }, []);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);

      const productObj = await product.history();

      if (productObj?.history) {
        setData(productObj.history);
        collectionData.current = productObj.history["prepared"]["collection"];
        // setCollection(productObj.history["prepared"]["collection"]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [product]);

  const datePropertyToDate = useCallback((dateProperty) => {
    return parseInt(dateProperty.replace("date_", ""));
  }, []);

  const formatDate = useCallback(
    (value) => {
      var formatted = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return formatted;
    },
    [formatter]
  );

  const formatWeight = useCallback(
    (value) => {
      var innerHTML = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          zero: false,
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return innerHTML;
    },
    [formatter]
  );

  const getColumns = useCallback(
    (data) => {
      const dateProperties = data.dateProperties;
      const allocationsStatus = data.allocationsStatus;

      let column: any = null;
      let columnDate: any = null;
      let columns = [
        [
          [
            {
              field: "name",
              title: t("c_instrument"),
              edited: false,
              sortable: false,
            },
          ],
        ],
      ];
      let allocationDate: any = null;
      let allocationStatus: any = null;
      const columnsDate: any = [];
      for (let i = 0, length = dateProperties.length; i < length; i++) {
        allocationDate = datePropertyToDate(dateProperties[i]);
        allocationStatus = allocationsStatus[dateProperties[i]]["status"];
        columnDate = formatDate(allocationDate);
        column = {
          edited: allocationStatus === "edited",
          field: dateProperties[i],
          title: columnDate,
          //   formatter: formatWeight,
          sortable: false,
        };
        columnsDate.push(column);
      }
      columns.push([columnsDate]);

      const columnsOrder: any = [];

      const firstColumn = columns[0][0][0];

      columnsOrder.push({
        field: firstColumn.field,
        title: firstColumn.title,
        edited: false,
      });

      // 2020 July 3 - Reverse order of date columns, data["columns"][1][0]
      for (let i = columns[1][0].length - 1; i >= 0; i--) {
        columnsOrder.push({
          field: columns[1][0][i].field,
          title: columns[1][0][i].title,
          edited: columns[1][0][i].edited,
        });
      }

      setColumns(columnsOrder);
    },
    [datePropertyToDate, formatDate, t]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data) {
      getColumns(data.prepared);
    }
  }, [data, getColumns]);

  const onSort = useCallback(
    (sort) => {
      // setTableRenderCicle({ bodyReady: false, headerReady: true });
      // setCollection((oldData) => {
      //   // const collection = [...oldData];
      //   const collection = oldData;

      const direction = sort.direction === "asc" ? 1 : -1;
      const collectionData = getCurrentData();
      collectionData.sort((a, b) => {
        if (!a[sort.field]) {
          return 1;
        }

        if (!b[sort.field]) {
          return -1;
        }

        if (a[sort.field] > b[sort.field]) {
          return direction;
        } else if (a[sort.field] < b[sort.field]) {
          return -direction;
        }
        return 0;
      });

      return collectionData;
      // });
    },
    [getCurrentData]
  );

  const sortRef = useRef<any>(undefined);
  const collectionData = useRef<any>(undefined);
  const loaderRef = useRef<any>();

  const showLoadPanel = useCallback(() => {
    const loaderNode = loaderRef.current;
    if (loaderNode) {
      loaderNode.style.height = "100%";
      loaderNode.style.width = "100%";
    }
  }, []);

  const headerSort = useCallback(
    (field: string) => {
      showLoadPanel();

      setTimeout(() => {
        const newSort: any = { field: field, direction: "desc" };

        const oldSort = sortRef.current;
        if (oldSort) {
          if (field === oldSort.field) {
            // Toggle direction

            const toggle = {
              desc: "asc",
              asc: "desc",
            };

            newSort["direction"] = toggle[oldSort.direction];
          }
        }

        const dataSorterd = onSort(newSort);
        sortRef.current = newSort;
        collectionData.current = dataSorterd;
        redrawTable();
        loaderRef.current.style.height = "0px";
        loaderRef.current.style.height = "0px";
      }, 100);
    },
    [onSort, redrawTable, showLoadPanel]
  );

  const openModal = useCallback(
    (content: JSX.Element) => {
      setOptions({
        stylesOptions: { bodyCustomClass: styles.modalBodyOverride },
      });
      setShowModal(true);
      setContent(content);
    },
    [setContent, setOptions, setShowModal]
  );

  const removeFeedback = useCallback(
    (date) => {
      const formattedDate = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: date,
        valueHelper: null,
      });
      const text = `<strong>${formattedDate}</strong> has been deleted.`;

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast, formatter]
  );

  const saveFeedback = useCallback(
    (operationType: "column" | "row", params) => {
      var text = t("c_unknown");

      switch (operationType) {
        case "column": {
          const formattedDate = formatter.custom("date", {
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: parseInt(params.date.replace("date_", "")),
            valueHelper: null,
          });
          text = `<strong>${formattedDate}</strong> has been updated.`;

          break;
        }
        case "row": {
          if (params["target"] == null) {
            text = `<strong>${params["source"]["name"]}</strong> has been deleted.`;
          } else {
            text = `<strong>${params["target"]["name"]}</strong> has replaced <strong>${params["source"]["name"]}</strong>`;
          }
          break;
        }

        // no default
      }

      const [channel, msg] = messageSuccess(text);
      broadcast(channel as string, msg);
    },
    [broadcast, formatter, t]
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const saveAllocation = useCallback(
    async (value) => {
      try {
        const response = await product.updateAllocation(value);

        //************************** USAGE *****************************
        var info = {
          action: "EDIT_HISTORY",
          actionParam: response.id,
          function: "SYSTEMATIC_PORTFOLIO",
        };
        var usage = window.App.usage;
        usage.record(info);
        //************************** USAGE *****************************

        closeModal();
        saveFeedback("column", { date: value.property });

        // Trigger refresh
        getData();
      } catch (error) {
        let errorMsg = "Cannot save changes, please retry.";
        if (getCurrentData().length === 0) {
          errorMsg = "Cannot save changes, allocation is empty";
        }
        const [channel, msg] = messageError(errorMsg);
        broadcast(channel as string, msg);
        closeModal();
      }
    },
    [broadcast, closeModal, getCurrentData, getData, product, saveFeedback]
  );

  const saveInsertAllocation = useCallback(
    async (value) => {
      try {
        const response = await product.addAllocation(value);

        //************************** USAGE *****************************
        var info = {
          action: "EDIT_HISTORY",
          actionParam: response.id,
          function: "SYSTEMATIC_PORTFOLIO",
        };
        var usage = window.App.usage;
        usage.record(info);
        //************************** USAGE *****************************

        closeModal();
        saveFeedback("column", { date: value.property });

        // Trigger refresh
        getData();
      } catch (error) {
        let errorMsg = "Cannot save changes, please retry.";
        if (value.collection.length === 0) {
          errorMsg = "Cannot save changes, allocation is empty";
        }
        const [channel, msg] = messageError(errorMsg);
        broadcast(channel as string, msg);
        closeModal();
      }
    },
    [broadcast, closeModal, getData, product, saveFeedback]
  );

  const listenerRemove = useCallback(
    async (dateProperty) => {
      try {
        await product.removeAllocation(dateProperty);
        closeModal();
        removeFeedback(datePropertyToDate(dateProperty));

        getData();
      } catch (error) {
        closeModal();

        const [channel, msg] = messageError(
          `Cannot remove allocation at ${formatDate(
            datePropertyToDate(dateProperty)
          )}`
        );

        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      closeModal,
      datePropertyToDate,
      formatDate,
      getData,
      product,
      removeFeedback,
    ]
  );

  const insertAllocation = useCallback(
    (e, col) => {
      e.stopPropagation();

      setModalTitle("Insert allocation");
      const property = col.field;

      openModal(
        <AllocationEditor
          onCancel={closeModal}
          onSave={saveInsertAllocation}
          collection={getCurrentData()}
          property={property}
          mode="insert"
          startEmpty={true}
        />
      );
    },
    [closeModal, getCurrentData, openModal, saveInsertAllocation, setModalTitle]
  );

  const editAllocation = useCallback(
    (e, col) => {
      e.stopPropagation();

      const collection = getCurrentData();
      const property = col.field;

      setModalTitle(`Edit ${col.title}`);
      openModal(
        <AllocationEditor
          mode="edit"
          onCancel={closeModal}
          onSave={saveAllocation}
          collection={collection}
          property={property}
        />
      );
    },
    [closeModal, getCurrentData, openModal, saveAllocation, setModalTitle]
  );

  const removeAllocation = useCallback(
    (e, col) => {
      e.stopPropagation();

      setShowModal(true);
      setModalTitle(`Remove ${col.title}`);
      setContent(
        <RemoveColumnModal
          onRemove={listenerRemove}
          onCancel={closeModal}
          property={col.field}
        />
      );
    },
    [closeModal, listenerRemove, setContent, setModalTitle, setShowModal]
  );

  const onReplaceInstrumentsSave = useCallback(
    async (target, source) => {
      try {
        await product.replaceInstrument(source, target);

        closeModal();
        saveFeedback("row", { target, source });

        getData();
      } catch (error) {
        closeModal();
      }
    },
    [closeModal, getData, product, saveFeedback]
  );

  const duplicateAllocation = useCallback(
    (e, col) => {
      setModalTitle("Insert allocation");

      openModal(
        <AllocationEditor
          onCancel={closeModal}
          onSave={saveInsertAllocation}
          collection={getCurrentData()}
          property={col.field}
          mode="insert"
        />
      );
    },
    [closeModal, getCurrentData, openModal, saveInsertAllocation, setModalTitle]
  );

  const actionImport = useCallback(() => {
    setModalTitle("Insert allocation");

    const yesterday = TDate.yesterday() - 1;
    const property = "date_" + yesterday;

    openModal(
      <AllocationEditor
        onCancel={closeModal}
        onSave={saveInsertAllocation}
        collection={getCurrentData()}
        property={property}
        mode="insert"
      />
    );
  }, [
    closeModal,
    getCurrentData,
    openModal,
    saveInsertAllocation,
    setModalTitle,
  ]);

  const onDeallocation = useCallback(
    (instrument) => {
      setModalTitle(`Deallocate ${instrument.name}`);
      setShowModal(true);
      setContent(
        <Box>
          <Typography>Are you sure to deallocate {instrument.name}</Typography>

          <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => onReplaceInstrumentsSave(null, instrument)}
            >
              Save
            </Button>
            <Button variant="tr_button_cancel" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      );
    },
    [
      closeModal,
      onReplaceInstrumentsSave,
      setContent,
      setModalTitle,
      setShowModal,
    ]
  );

  const onReplaceInstruments = useCallback(
    (instrument) => {
      setModalTitle(`Replace ${instrument.name}`);
      setShowModal(true);
      setContent(
        <ReplaceModalContent
          instrument={instrument}
          onSave={(target) => onReplaceInstrumentsSave(target, instrument)}
          onCancel={closeModal}
        />
      );
    },
    [
      closeModal,
      onReplaceInstrumentsSave,
      setContent,
      setModalTitle,
      setShowModal,
    ]
  );

  useEffect(() => {
    const info = product.info();

    setIsReadOnly(info.isReadOnly);
  }, [product]);

  useEffect(() => {
    var message = {
      from: "systematicProducts",
      content: {
        actions: [
          {
            componentJSX: (
              <li className={"menu__item"} onClick={actionImport}>
                Import
              </li>
            ),
          },
        ],
      },
    };

    if (isReadOnly) {
      message = {
        from: "systematicProducts",
        content: {
          actions: [],
        },
      };
    }
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [actionImport, broadcast, insertAllocation, isReadOnly]);

  return (
    <Box height={"100%"} p={1}>
      <SecurityChartModal
        environment={environment}
        showModal={showSecurityModal}
        security={securityModalData}
        onClose={() => setShowSecurityModal(false)}
      />
      <Card sx={{ width: "100%", height: "100%" }}>
        <CardContent
          sx={{ width: "100%", height: "100%", position: "relative" }}
        >
          <Box
            ref={loaderRef}
            style={{
              height: 0,
              width: 0,
              overflow: "hidden",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 3,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <CircularProgress sx={{ color: "#2a7090" }} />
              <Typography sx={{ fontWeight: "bold" }}>
                Sorting table...
              </Typography>
            </Box>
          </Box>
          {isLoading ? (
            <LoaderSkeleton />
          ) : (
            <Box
              display={"flex"}
              height={"100%"}
              width={"100%"}
              minWidth={0}
              minHeight={0}
              overflow={"auto"}
            >
              <table key={tableKey} className={styles.historyTable}>
                <thead>
                  <tr>
                    <TableHeader
                      columns={columns}
                      duplicateAllocation={duplicateAllocation}
                      editAllocation={editAllocation}
                      headerSort={headerSort}
                      insertAllocation={insertAllocation}
                      isReadOnly={isReadOnly}
                      removeAllocation={removeAllocation}
                      sortDirection={sortRef.current?.direction}
                      sortField={sortRef.current?.field}
                    />
                  </tr>
                </thead>
                <tbody>
                  <TableBody
                    collection={getCurrentData()}
                    columns={columns}
                    formatWeight={formatWeight}
                    isReadOnly={isReadOnly}
                    onDeallocation={onDeallocation}
                    onReplaceInstruments={onReplaceInstruments}
                    setShowSecurityModal={setShowSecurityModal}
                    setSecurityModalData={setSecurityModalData}
                  />
                </tbody>
              </table>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

const TableBody = memo(
  ({
    collection,
    columns,
    formatWeight,
    isReadOnly,
    onDeallocation,
    onReplaceInstruments,
    setShowSecurityModal,
    setSecurityModalData,
  }: any) => {
    return collection ? (
      collection.map((obj) => {
        return (
          <tr key={uuidv4()}>
            {columns.map((col, index) => {
              const styleCasses = {
                expired: styles.expired,
                rebalanced: styles.rebalanced,
                unavailable: styles.unavailable,
              };

              return index === 0 ? (
                <td
                  key={uuidv4()}
                  className={`${styles.firstColumn} ${
                    styleCasses[obj["status"]] ?? ""
                  }`}
                >
                  <Box className={styles.firstColumnContent}>
                    <Box className={styles.instrumentNameBox}>
                      <Typography>{obj["name"]}</Typography>
                      <Box className={styles.tooltipIcon}>
                        <SecurityTooltip
                          symbol={obj["symbol"]}
                          chartButtonHandler={() => setShowSecurityModal(true)}
                          setSecurity={(security) =>
                            setSecurityModalData(security)
                          }
                        >
                          <span className="i-info"></span>
                        </SecurityTooltip>
                      </Box>
                    </Box>
                    {!isReadOnly && (
                      <Box className={styles.firstColumnButtonBox}>
                        <InstrumentsOptions
                          replace={() => onReplaceInstruments(obj)}
                          deallocate={() => onDeallocation(obj)}
                        />
                      </Box>
                    )}
                  </Box>
                </td>
              ) : (
                <td
                  className={`${styleCasses[obj["status"]] ?? ""} ${
                    col.edited === true ? styles.edited : ""
                  }`}
                  key={uuidv4()}
                >
                  {formatWeight(obj[col.field])}
                </td>
              );
            })}
          </tr>
        );
      })
    ) : (
      <></>
    );
  }
);

const LoaderSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
    </Stack>
  );
};

const TableHeader = memo(
  ({
    columns,
    duplicateAllocation,
    editAllocation,
    headerSort,
    insertAllocation,
    isReadOnly,
    removeAllocation,
    sortDirection,
    sortField,
  }: any) => {
    return columns.map((col, index) => {
      const isFirstCol = index === 0;
      return (
        <th
          onClick={(e) => headerSort(col.field)}
          className={col.edited === true ? styles.edited : ""}
          key={uuidv4()}
        >
          <Box className={styles.headerCell}>
            {col.title}

            {isReadOnly === false && !isFirstCol && (
              <div className={styles.headerBtnBox}>
                <HeaderButton
                  duplicateAllocationHandler={(e) =>
                    duplicateAllocation(e, col)
                  }
                  editAllocationHandler={(e) => editAllocation(e, col)}
                  insertAllocationHandler={(e) => insertAllocation(e, col)}
                  removeAllocationHandler={(e) => removeAllocation(e, col)}
                />

                {sortField && sortField === col.field && (
                  <>
                    {sortDirection === "desc" ? (
                      <span>&#9660;</span>
                    ) : (
                      <span>&#9652;</span>
                    )}
                  </>
                )}
              </div>
            )}
          </Box>
        </th>
      );
    });
  }
);

const HeaderButton = ({
  insertAllocationHandler,
  editAllocationHandler,
  removeAllocationHandler,
  duplicateAllocationHandler,
}: {
  insertAllocationHandler: Function;
  editAllocationHandler: Function;
  removeAllocationHandler: Function;
  duplicateAllocationHandler: Function;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const editAllocation = useCallback(
    (e) => {
      editAllocationHandler(e);
      handleClose(e);
    },
    [editAllocationHandler, handleClose]
  );

  const insertAllocation = useCallback(
    (e) => {
      insertAllocationHandler(e);
      handleClose(e);
    },
    [handleClose, insertAllocationHandler]
  );

  const duplicateAllocation = useCallback(
    (e) => {
      duplicateAllocationHandler(e);
      handleClose(e);
    },
    [duplicateAllocationHandler, handleClose]
  );

  const removeAllocation = useCallback(
    (e) => {
      removeAllocationHandler(e);
      handleClose(e);
    },
    [handleClose, removeAllocationHandler]
  );

  return (
    <div>
      <Button
        className={styles.headerBtn}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{ fontSize: "0.7vw" }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={editAllocation}>Edit allocation</MenuItem>
        <MenuItem onClick={insertAllocation}>Insert allocation</MenuItem>
        <MenuItem onClick={duplicateAllocation}>Duplicate allocation</MenuItem>
        <MenuItem onClick={removeAllocation}>Remove allocation</MenuItem>
      </Menu>
    </div>
  );
};

const InstrumentsOptions = ({
  replace,
  deallocate,
}: {
  replace: () => void;
  deallocate: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const onReplace = useCallback(
    (e) => {
      replace();
      handleClose(e);
    },
    [handleClose, replace]
  );

  const onDeallocate = useCallback(
    (e) => {
      deallocate();
      handleClose(e);
    },
    [deallocate, handleClose]
  );

  return (
    <div>
      <Button
        className={styles.firstColumnButton}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{ fontSize: "0.7vw" }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onReplace}>Replace</MenuItem>
        <MenuItem onClick={onDeallocate}>Deallocate</MenuItem>
      </Menu>
    </div>
  );
};

const RemoveColumnModal = ({
  property,
  onRemove,
  onCancel,
}: {
  property: string;
  onRemove: Function;
  onCancel: Function;
}) => {
  const formatter = useFormatter();
  const formatDate = useCallback(
    (value) => {
      var formatted = formatter.custom("date", {
        options: {
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });

      return formatted;
    },
    [formatter]
  );

  const removeAllocation = useCallback(() => {
    onRemove(property);
  }, [onRemove, property]);

  const close = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Box>
      <Typography>
        Are you sure to delete allocation{" "}
        {formatDate(parseInt(property.replace("date_", "")))}
      </Typography>
      <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={removeAllocation}>
          Remove
        </Button>
        <Button variant="tr_button_cancel" onClick={close}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

const ReplaceModalContent = ({
  onSave,
  onCancel,
  instrument,
}: {
  onSave: (value) => void;
  onCancel: () => void;
  instrument: any;
}) => {
  const [selectedInstrument, setSelectedInstrument] = useState<any>();

  const selectInstrument = useCallback((value) => {
    if (value) {
      setSelectedInstrument(value);
    }
  }, []);

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <Typography>Replace with: </Typography>
        <Search
          showInstrumentInfoOnSelect={false}
          onSelectInstrument={selectInstrument}
        />
      </Box>
      <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
        <Button variant="contained" onClick={() => onSave(selectedInstrument)}>
          Save
        </Button>
        <Button variant="tr_button_cancel" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
