import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import { defaultTemplateAvoidableLossReport } from "../../../../../../../trendrating-report/defaultTemplateAvoidableLossReport";
import { deepClone } from "../../../../../../../../deepClone";
import Modal from "../../../../../../../../components/Modal/Modal";
import { Generator } from "../../../../../../../trendrating-report/generator/Generator";
import { Downloader } from "../../../../../../../trendrating-report/downloader/DownloaderReact";
import { themeBlueAndGrey } from "../../../../../../widgets/dialog/report/themeBlueAndGrey-ts";
import { Report } from "../../../../../../../../api/report/Report";

type AvoidableLossFactsheetDownloadProps = {
  getData: () => any;
  columns: {}[];
  listType: "BASKET" | "PORTFOLIO";
  listName: string;
  quartilesMap: any;
  numberOfStocks: number;
  cardinalityCD: number;
  cardinalityCDperc: number;
};

export function AvoidableLossFactsheetDownload({
  columns,
  getData,
  listType,
  listName,
  quartilesMap,
  numberOfStocks,
  cardinalityCD,
  cardinalityCDperc,
}: AvoidableLossFactsheetDownloadProps) {
  const [showConfirmDialog, setShowconfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const environment = useEnvironment();

  const formatter = useFormatter();

  const formatPercentage = useCallback(
    (value) => {
      var formatted = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "-",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
      return formatted;
    },
    [formatter]
  );

  const closeDialog = useCallback(() => setShowconfirmDialog(false), []);

  const getDataToPrint = useCallback(async () => {
    const securitiesResponse = await getData();
    return {
      headline: "Holdings",
      enableRank: false,
      columns: columns,
      position: securitiesResponse.securities,
      type: "REPORT_COMMON_SECURITY_TABLE",
    };
  }, [columns, getData]);

  const getDisclaimerSection = useCallback(async () => {
    const disclaimerObject = {
      text: "",
      type: "REPORT_COMMON_DISCLAIMER",
    };

    const apiReport = new Report(environment.get("setup"));

    disclaimerObject["text"] = await apiReport.disclaimer();

    return disclaimerObject;
  }, [environment]);

  const cardinalityData = useMemo(() => {
    return {
      headline: "",
      enableRank: false,
      columns: [
        {
          label: "Portfolio",
          property: "s_name",
          customFormatter: (value) => value["s_name"],
        },
        {
          label: "",
          property: "value",
          customFormatter: (value) => value["value"],
        },
      ],
      position: [
        {
          s_name: "Number of securities",
          value: JSON.stringify(numberOfStocks),
        },
        {
          s_name: "C/D rated securities",
          value: `${cardinalityCD} (${formatPercentage(cardinalityCDperc)})`,
        },
      ],
      type: "REPORT_COMMON_SECURITY_TABLE",
    };
  }, [cardinalityCD, cardinalityCDperc, formatPercentage, numberOfStocks]);

  const averageTableData = useMemo(() => {
    if (quartilesMap) {
      const quartiles = quartilesMap?.["quartileAvg"];
      const average = quartilesMap?.["peerAvg"];
      return {
        headline: "",
        enableRank: false,
        columns: [
          {
            label: "Avoidable Losses",
            property: "quartileName",
            customFormatter: (value) => {
              return value["quartileName"];
            },
          },
          {
            label: "",
            property: "value",
            customFormatter: (value) => {
              return formatPercentage(value["value"]);
            },
          },
        ],
        position: [
          {
            quartileName: "4th Quartile",
            value: quartiles["4"],
          },
          {
            quartileName: "3rd Quartile",
            value: quartiles["3"],
          },
          {
            quartileName: "2nd Quartile",
            value: quartiles["2"],
          },
          {
            quartileName: "1st Quartile",
            value: quartiles["1"],
          },
          {
            quartileName: "Average",
            value: average,
          },
        ],
        type: "REPORT_COMMON_SECURITY_TABLE",
      };
    } else {
      return [];
    }
  }, [formatPercentage, quartilesMap]);

  const getUiState = useCallback(() => {
    const columnsAvailable = [];
    const wysiwygState: any = {
      actions: {
        constraints: null,
        rank: null,
        sortBy: null,
      },
      columns: null,
      dataTotalCount: 0,
      promiseCache: null,

      // 2020-12-10 Markets: new way to do stuff using storages
      storage: null,

      target: null,
      targetType: "LIST",
    };

    wysiwygState.sortState = {
      descending: false,
      property: "_s_label",
    };

    wysiwygState.title = "Avoidable Losses Calculator";
    wysiwygState.target = {
      type: listType,
      name: listName,
    };

    const uiState = {
      columnsAvailable: columnsAvailable,
      wysiwygState: wysiwygState,
    };

    if (appConfig.isDebug === true) {
      console.log("uiState", uiState);
    }

    return uiState;
  }, [listName, listType]);

  const getUserPreference = useCallback((user, printDialogValue) => {
    var userPreference: any =
      user?.preferences?.preferences?.report?.general ?? null;

    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }

    userPreference["theme"] = printDialogValue["theme"];

    if (userPreference["theme"] === "theme2") {
      userPreference["theme"] = themeBlueAndGrey;
    } else {
      userPreference["theme"] = null;
    }

    return userPreference;
  }, []);

  const preparePrintParams = useCallback(() => {
    const template = defaultTemplateAvoidableLossReport;

    if (template) {
      // executable sections
      const baseId = new Date().getTime();
      const executableSections = deepClone(template.configuration.sections);
      let section: any = null;

      // wysiwygState
      const wysiwygState = getUiState().wysiwygState;

      for (var i = 0, length = executableSections.length; i < length; i++) {
        section = executableSections[i];
        section.id = baseId + "-" + i;

        switch (section.type) {
          case "REPORT_COMMON_TITLE":
            let title = wysiwygState.title;

            section["content"]["text"] = title;
            break;

          case "REPORT_COMMON_SECURITY_TABLE":
            section.content.sort = wysiwygState.sortState;
          //no default
        }
      }

      // user preferences
      var environmentSetup = environment.get("setup");
      var user = environmentSetup["account"]["user"];
      var userPreference = getUserPreference(user, template.configuration);
      return {
        sections: executableSections,
        template: template,
        userPreference: userPreference,
        wysiwygState: wysiwygState,
      };
    }
  }, [environment, getUiState, getUserPreference]);

  const download = useCallback(async () => {
    const printParams = preparePrintParams();

    const setup = environment.get("setup");
    const disclaimer = await getDisclaimerSection();

    if (printParams) {
      try {
        setIsLoading(true);
        const dataToPrint = await getDataToPrint();
        const report = new Generator(setup, {
          data: [
            {
              text: `Avoidable Losses on ${listName}`,
              type: "REPORT_COMMON_TITLE",
            },
            averageTableData,
            cardinalityData,
            dataToPrint,
            {
              lines: 1,
              type: "REPORT_COMMON_SPACING",
            },
            disclaimer,
          ],
          formatter: formatter,
          sections: deepClone(printParams.sections),
          template: deepClone(printParams.template),
          userPreference: printParams.userPreference,
          wysiwygState: printParams.wysiwygState,
        });

        await report.create();
        const pdf = await report.print();

        const downloader = new Downloader(report);
        downloader.download(pdf);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      throw new Error("Cannot print the report due to an unknown error");
    }
  }, [
    averageTableData,
    cardinalityData,
    environment,
    formatter,
    getDataToPrint,
    getDisclaimerSection,
    listName,
    preparePrintParams,
  ]);

  const doAction = useCallback(async () => {
    setShowconfirmDialog(true);
    setIsLoading(true);

    try {
      if (cardinalityCD > 300) {
        // The dialog will ask to confirm for portfolios that contains more than 300 titles
        setIsLoading(false);
      } else {
        await download();
        setIsLoading(false);
        closeDialog();
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [cardinalityCD, closeDialog, download]);

  return (
    <>
      {showConfirmDialog && (
        <Modal
          onClose={closeDialog}
          headerConfig={{ headerContent: "Download PDF", hasBackground: true }}
          closeIcon={false}
        >
          {isLoading ? (
            <Box
              p={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <CircularProgress sx={{ color: "#2a7090" }} />
              <Typography>printing...</Typography>
            </Box>
          ) : (
            <>
              <Box p={2}>
                <Typography>
                  The {listType === "PORTFOLIO" ? "portfolio" : "basket"}{" "}
                  contains more than 300 securities, do you want to print the
                  factsheet anyway?
                </Typography>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={1}
              >
                <Button variant="contained" onClick={download}>
                  Download
                </Button>
                <Button variant="tr_button_cancel" onClick={closeDialog}>
                  Close
                </Button>
              </Box>
            </>
          )}
        </Modal>
      )}
      <li onClick={doAction} className="menu__item">
        Download Factsheet
      </li>
    </>
  );
}
