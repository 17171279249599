import { Button, ButtonGroup } from "@mui/material";
import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type RadioButtonGroupProps = {
  options: { value: any; label: string }[];
  initValue?: any;
  onChange: (value) => void;
};

export function RadioButtonGroup({
  options,
  initValue,
  onChange,
}: RadioButtonGroupProps) {
  const [selected, setSelected] = useState(initValue ?? options?.[0]?.value);

  const handleChange = useCallback(
    (value) => {
      setSelected(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <ButtonGroup
      size="small"
      variant="outlined"
      aria-label="Basic button group"
    >
      {options.map((item) => (
        <Button
          sx={{ textOverflow: "nowrap" }}
          key={uuidv4()}
          variant={selected === item.value ? "contained" : "outlined"}
          onClick={() => handleChange(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
