import { useMemo } from "react";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { RadioButtonBar } from "../../../../../../../components/RadioButtonBar/RadioButtonBar";

type RatingWeightsProps = {
  cardinalityPerRating: { A: number; B: number; C: number; D: number };
  weightsPerRating: { A: number; B: number; C: number; D: number };
  hasDropDown: boolean;
  distributionType: "weights" | "holdings";
  setDistributionType: (value: "weights" | "holdings") => void;
};

export function RatingWeights({
  cardinalityPerRating,
  weightsPerRating,
  distributionType,
  setDistributionType,
  hasDropDown,
}: RatingWeightsProps) {
  const onSelectRatingType = (value) => {
    setDistributionType(value);
  };

  const number = cardinalityPerRating;
  const perc = weightsPerRating;
  const formatter = useFormatter();

  const data = useMemo(() => {
    const adjustedPercentage = Object.keys(perc).reduce((acc, currentValue) => {
      acc[currentValue] = formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "0",
          },
        },
        output: "TEXT",
        value:
          perc[currentValue] > 10
            ? perc[currentValue] / 100
            : perc[currentValue],
        valueHelper: null,
      });

      return acc;
    }, {});

    return distributionType === "weights" ? adjustedPercentage : number;
  }, [distributionType, formatter, number, perc]);

  return (
    <div className="blockWrapper rating-weights-block">
      <div className="compare-portfolio-widget-title">
        <h3>Rating </h3>
        {hasDropDown ? (
          <RadioButtonBar
            options={[
              { label: "weights", value: "weights" },
              { label: "holdings", value: "holdings" },
            ]}
            initValue={distributionType}
            onChange={(value) => {
              onSelectRatingType(value);
            }}
          />
        ) : (
          <p style={{ marginLeft: 5 }}>{distributionType}</p>
        )}
      </div>
      <ul className="ratings-box-list">
        <li className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
          <strong className="tAnalysisOverview-rate format-rate format-rate--D">
            D
          </strong>
          <span className="tAnalysisOverview-distributionValue small-font">
            {distributionType === "weights" ? `${data["D"]}` : data["D"]}
          </span>
        </li>
        <li className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
          <strong className="tAnalysisOverview-rate format-rate format-rate--C">
            C
          </strong>
          <span className="tAnalysisOverview-distributionValue small-font">
            {distributionType === "weights" ? `${data["C"]}` : data["C"]}
          </span>
        </li>
        <li className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
          <strong className="tAnalysisOverview-rate format-rate format-rate--B">
            B
          </strong>
          <span className="tAnalysisOverview-distributionValue small-font">
            {distributionType === "weights" ? `${data["B"]}` : data["B"]}
          </span>
        </li>
        <li className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
          <strong className="tAnalysisOverview-rate format-rate format-rate--A">
            A
          </strong>
          <span className="tAnalysisOverview-distributionValue small-font">
            {distributionType === "weights" ? `${data["A"]}` : data["A"]}
          </span>
        </li>
      </ul>
    </div>
  );
}
