import { useContext, useState, useCallback, useMemo } from "react";
import { FilterTaxonomies } from "../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { deepClone } from "../../../../../../../deepClone";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FiltersContext } from "../../FilterRowContraints";

type RegionFilterProps = { label: string };

export function RegionFilter({ label }: RegionFilterProps) {
    const context = useContext(FiltersContext);
    const [panelValues, setPanelValues] = useState<string[]>([]);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const taxonomy = useMemo(() => ({ type: "ETF", field: "etfgeo" }), []);
    const { getTaxonomyMap, advancedNodesSelector } = useTaxonomyByType("ETF");
    const etfgeoTaxonomy = useMemo(() => {
        return getTaxonomyMap(
            taxonomy.type as "ETF" | "security",
            taxonomy.field
        );
    }, [getTaxonomyMap, taxonomy.field, taxonomy.type]);
    const rootNode = useMemo(
        () =>
            Object.values<any>(etfgeoTaxonomy).find(
                (taxon) => taxon.parent == null
            ),
        [etfgeoTaxonomy]
    );

    const handleSelection = useCallback(
        (filtersDirty) => {
            const segments = deepClone(filtersDirty[0].value.segments);
            const filters = advancedNodesSelector(
                segments,
                taxonomy.field,
                true
            );

            const invRegionFilter = {
                type: "filters",
                value: {
                    dimension: taxonomy.field,
                    segments: filters,
                },
            };

            context?.updateInvRegion([invRegionFilter]);
        },
        [advancedNodesSelector, context, taxonomy.field]
    );

    const activeValues = useMemo(() => {
        if (context && context.invRegion) {
            const values = context.invRegion;
            const taxonomiesIds: string[] = [];

            values.forEach((value) => {
                taxonomiesIds.push(...value.value.segments);
            });

            setPanelValues(taxonomiesIds);

            if (taxonomiesIds.length) {
                setShowCancelButton(true);
                if (taxonomiesIds.length > 3) {
                    const firstSelected =
                        etfgeoTaxonomy?.[taxonomiesIds[0]]?.name ?? "";
                    return [firstSelected, `+ ${taxonomiesIds.length - 1}`];
                } else {
                    return taxonomiesIds.map(
                        (id) => etfgeoTaxonomy?.[id]?.name ?? ""
                    );
                }
            }
        }

        setShowCancelButton(false);
        return rootNode?.name ? [rootNode.name] : [];
    }, [context, etfgeoTaxonomy, rootNode]);

    const clearFitlers = useCallback(
        (e) => {
            e.stopPropagation();
            context?.updateInvRegion([]);
        },
        [context]
    );
    return (
        <FilterTaxonomies
            showIconCancel={showCancelButton}
            taxonomyToRender={taxonomy}
            updateFilters={handleSelection}
            filtersValues={panelValues}
            selectedFilters={activeValues}
            label={label}
            removeFilter={clearFitlers}
        />
    );
}
