export type ChartType = {
  content: {
    benchmark: boolean;
    headline: {
      content: string;
      isEnabled: boolean;
    };
    title: {
      content: string;
      isEnabled: boolean;
    };
  };
  presentation: {
    align: string;
    legend: boolean;
    logAxis: boolean;
    size: "large" | "medium" | "small";
  };
};

type ActionType = {
  type:
    | "SET_benchmark"
    | "SET_headline_CONTENT"
    | "SET_headline_ISENABLED"
    | "SET_title_CONTENT"
    | "SET_title_ISENABLED"
    | "SET_align"
    | "SET_legend"
    | "SET_logAxis"
    | "SET_size";
  payload: any;
};

export const chartReducer = (draft: ChartType, action: ActionType) => {
  switch (action.type) {
    case "SET_benchmark":
      draft.content.benchmark = action.payload;
      break;
    case "SET_headline_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_headline_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_title_CONTENT":
      draft.content.title.content = action.payload;
      break;
    case "SET_title_ISENABLED":
      draft.content.title.isEnabled = action.payload;
      break;
    case "SET_align":
      draft.presentation.align = action.payload;
      break;
    case "SET_legend":
      draft.presentation.legend = action.payload;
      break;
    case "SET_logAxis":
      draft.presentation.logAxis = action.payload;
      break;
    case "SET_size":
      draft.presentation.size = action.payload;
      break;
  }
};
