import { FormControl, ListSubheader, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styles from "./AlertsSelect.module.scss";

type AlertsSelectProps = {
  setValue: (value: Alerts) => void;
  initValue?: Alerts;
  showTitle?: boolean;
};

type Alerts =
  | null
  | "Any"
  | "upgrades_today"
  | "upgrades_last_5_days"
  | "upgrades_last_20_days"
  | "upgrades_last_60_days"
  | "downgrades_today"
  | "downgrades_last_5_days"
  | "downgrades_last_20_days"
  | "downgrades_last_60_days"
  | "positive_movers"
  | "negative_movers";

export function AlertsSelect({
  setValue,
  initValue,
  showTitle = true,
}: AlertsSelectProps) {
  const [selectedValue, setSelectedValue] = useState<Alerts>(
    initValue ?? "Any"
  );
  const [showLabel, setShowLabel] = useState(false);

  useEffect(() => {
    if (
      selectedValue?.includes("upgrades") ||
      selectedValue?.includes("downgrades") ||
      selectedValue?.includes("movers")
    ) {
      setShowLabel(true);
    } else {
      setShowLabel(false);
    }
  }, [selectedValue]);

  const onSelectValue = useCallback(
    (value) => {
      setSelectedValue(value);
      setValue(value);
    },
    [setValue]
  );

  return (
    <div className={styles.wrapper}>
      {showTitle && (
        <label
          style={{ marginRight: "8px", marginLeft: "8px" }}
          className="filter-bar__field-label"
        >
          Alerts
        </label>
      )}
      <FormControl sx={{ minWidth: 120 }} size={"small"} fullWidth>
        <Select
          onChange={(e) => onSelectValue(e.target.value as Alerts)}
          defaultValue="Any"
          id="grouped-select"
          value={selectedValue}
          classes={{ select: styles.root__select }}
        >
          <MenuItem value={"Any"}>
            <em>Any</em>
          </MenuItem>
          <ListSubheader>Upgrades</ListSubheader>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"upgrades_today"}
          >
            Today
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"upgrades_last_5_days"}
          >
            Last 5 days
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"upgrades_last_20_days"}
          >
            Last 20 days
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"upgrades_last_60_days"}
          >
            Last 60 days
          </MenuItem>

          <ListSubheader>Downgrades</ListSubheader>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"downgrades_today"}
          >
            Today
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"downgrades_last_5_days"}
          >
            Last 5 days
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"downgrades_last_20_days"}
          >
            Last 20 days
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"downgrades_last_60_days"}
          >
            Last 60 days
          </MenuItem>

          <ListSubheader>Movers</ListSubheader>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"positive_movers"}
          >
            Positives
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "12px!important" }}
            value={"negative_movers"}
          >
            Negatives
          </MenuItem>
        </Select>
      </FormControl>
      {showTitle && showLabel && (
        <strong>
          {selectedValue?.includes("upgrades")
            ? "Upgrades"
            : selectedValue?.includes("downgrades")
            ? "Downgrades"
            : "Movers"}
        </strong>
      )}
    </div>
  );
}
