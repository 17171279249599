import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TDate } from "../../../../../../../trendrating/date/TDate";
import DatePicker from "../DatePicker";

type Props = {
  state: any;
  initValueHandler: (e) => void;
  changeDataHandler: (e) => void;
  inheritBacktestingHandler: Function;
};

const PriceLevel = forwardRef(
  (
    {
      state,
      initValueHandler,
      changeDataHandler,
      inheritBacktestingHandler,
    }: Props,
    ref
  ) => {
    const _state = useMemo(() => state, [state]);
    const [isDateValid, setIsDateValid] = useState(true);

    const [initValueCheckboxValue, setInitValueCheckboxValue] = useState(() => {
      return _state.isBacktestingInherited === true &&
        _state.isBacktestingInheritedDate == null
        ? "yes"
        : _state.isBacktestingInherited &&
          _state.isBacktestingInheritedDate != null
        ? "yesFrom"
        : "no";
    });
    const datepickerInput = useMemo(() => {
      if (_state.fromDate && _state.isBacktestingInheritedDate) {
        const dateDays = _state.isBacktestingInheritedDate;
        const date = TDate.daysToDate(dateDays);
        const getYear = date.toLocaleString("default", { year: "numeric" });
        const getMonth = date.toLocaleString("default", { month: "2-digit" });
        const getDay = date.toLocaleString("default", { day: "2-digit" });
        const dateFormat = getYear + "-" + getMonth + "-" + getDay;
        return dateFormat;
        // return date;
      } else {
        // if (
        //   initValueCheckboxValue === "yesFrom" &&
        //   _state.isBacktestingInheritedDate === null
        // ) {
        //   let date = new Date();
        //   const getYear = date.toLocaleString("default", { year: "numeric" });
        //   const getMonth = date.toLocaleString("default", { month: "2-digit" });
        //   const getDay = date.toLocaleString("default", { day: "2-digit" });
        //   const dateFormat = getYear + "-" + getMonth + "-" + getDay;
        //   changeDataHandler(new Date(dateFormat));
        //   return date;
        // }
        return null;
      }
      // return null;
    }, [_state.fromDate, _state.isBacktestingInheritedDate]);
    const initValueCheckboxHandler = useCallback(
      (e) => {
        if (e.target.value === "yes" || e.target.value === "no") {
          changeDataHandler(null);
        }
        inheritBacktestingHandler(e.target.value);
        setInitValueCheckboxValue(e.target.value);
      },
      [changeDataHandler, inheritBacktestingHandler]
    );

    useImperativeHandle(
      ref,
      () => ({
        validateDate: (isValid: boolean) => {
          setIsDateValid(isValid);
        },
      }),
      []
    );

    return (
      <Card sx={{ flex: 1, boxShadow:3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box pb={1}>
            <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
              Price level
            </Typography>
          </Box>
          <Divider
            sx={{ width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
          <Box display={"flex"} flexDirection={"column"} gap={2} pt={1}>
            <Box flex={1} display={"flex"} flexDirection={"column"}>
              <Typography variant="tr_subtitle_blue">Initial value</Typography>
              <FormControl>
                <RadioGroup
                  value={_state.initialValue}
                  onChange={initValueHandler}
                  row
                >
                  <FormControlLabel
                    value="100"
                    control={<Radio size="small" />}
                    label="100"
                  />
                  <FormControlLabel
                    value="1000"
                    control={<Radio />}
                    label="1,000"
                  />
                  <FormControlLabel
                    value="10000"
                    control={<Radio />}
                    label="10,000"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box flex={3} display={"flex"} flexDirection={"column"}>
              <Typography variant="tr_subtitle_blue">Inherit back-testing history</Typography>
              <FormControl>
                <RadioGroup
                  value={initValueCheckboxValue}
                  onChange={initValueCheckboxHandler}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="yesFrom"
                    control={<Radio />}
                    label={
                      <>
                        Yes from{" "}
                        {initValueCheckboxValue === "yesFrom" ? ":" : "date"}
                        {initValueCheckboxValue === "yesFrom" && (
                          <>
                            <DatePicker
                              isValid={isDateValid}
                              input={datepickerInput}
                              onChangeDate={changeDataHandler}
                            />
                            {!isDateValid && (
                              <FormHelperText error={!isDateValid}>
                                Select a date
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </>
                    }
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
);
export default PriceLevel;
