import { Cookies } from "react-cookie";
import { config } from "./config-ts";

/**
 * Read or write a cookie
 *
 * If value is null, the function returns the current value of the
 * cookie. Otherwise sets the new value and returns it.
 *
 * @param {string} property - the property to read (real cookie name can
 *      be different: it is defined in config.js)
 * @param {number | string} value - the value to be set
 *
 * @returns {string} the value of the cookie. Returns null if property
 *      is not supported
 */
export function appCookie(property, value) {
  const cookies = new Cookies();

  value = value == null ? null : value;

  switch (property) {
    case "impersonate": {
      //
      // this cookie guarantees that the state param impersonate
      // is preserved even if the user reload the page
      //
      var cookieImpersonate = config.cookies.impersonate;

      if (value == null) {
        return cookies.get(cookieImpersonate.name);
      }

      cookies.set(cookieImpersonate.name, value, {
        path: cookieImpersonate.props.path,
        sameSite: cookieImpersonate.props.samesite as "strict",
      });

      return String(value);
    }
    case "portfolioOverviewNews": {
      //
      // Portfolio analysis overview news (aka Today section)
      //
      var cookiePortfolioOverviewNews = config.cookies.portfolioOverviewNews;

      if (value == null) {
        return cookies.get(cookiePortfolioOverviewNews.name);
      }

      cookies.set(cookiePortfolioOverviewNews.name, value, {
        path: cookiePortfolioOverviewNews.props.path,
        sameSite: cookiePortfolioOverviewNews.props.samesite as "strict",
      });

      return String(value);
    }
    case "productId": {
      //
      // this cookie guarantees that the state param productId is
      // preserved even if the user reload the page
      //
      var cookieProductId = config.cookies.productId;

      if (value == null) {
        return cookies.get(cookieProductId.name);
      }

      cookies.set(cookieProductId.name, value, {
        path: cookieProductId.props.path,
        sameSite: cookieProductId.props.samesite as "strict",
      });

      return String(value);
    }
    case "tableRowsPerPage": {
      //
      // Table widget: rows per page
      //
      var cookieTableRowsPerPage = config.cookies.tableRowsPerPage;

      if (value == null) {
        return cookies.get(cookieTableRowsPerPage.name);
      }

      cookies.set(cookieTableRowsPerPage.name, value, {
        path: cookieTableRowsPerPage.props.path,
        sameSite: cookieTableRowsPerPage.props.samesite as "strict",
      });

      return String(value);
    }
    default: {
      // Generic cookie set/get
      if (value == null) {
        return cookies.get(property);
      }

      cookies.set(property, value, {
        path: "/",
        sameSite: "strict",
      });

      return String(value);
    }
  }
}
