import { TrendratingChart } from "../components/TrendratingChart";

type ChartNewProps = {
  value: any;
};

export default function ChartNew({ value }: ChartNewProps) {
  return (
    <TrendratingChart
      value={value}
      defaultPeriod={"3Y"}
      excludePeriod={["MAX"]}
      hasMeasure={false}
      hasTooltip={false}
      enableRating={true}
      autoResize
      resizeCorrection={25}
    />
  );
}
