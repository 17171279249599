export type FactsType = {
  currency: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  customText1: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  customText2: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  customText3: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  headline: {
    content: string;
    isEnabled: boolean;
  };
  launchDate: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  numberOfHoldings: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  performance: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  rebalancingFrequency: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
  endingDate: {
    content: string;
    label: string;
    isEnabled: boolean;
  };
};

export type ActionType = {
  type:
    | "SET_CURRENCY_CONTENT"
    | "SET_CURRENCY_LABEL"
    | "SET_CURRENCY_ISENABLED"
    | "SET_CUSTOMTEXT_1_CONTENT"
    | "SET_CUSTOMTEXT_1_LABEL"
    | "SET_CUSTOMTEXT_1_ISENABLED"
    | "SET_CUSTOMTEXT_2_CONTENT"
    | "SET_CUSTOMTEXT_2_LABEL"
    | "SET_CUSTOMTEXT_2_ISENABLED"
    | "SET_CUSTOMTEXT_3_CONTENT"
    | "SET_CUSTOMTEXT_3_LABEL"
    | "SET_CUSTOMTEXT_3_ISENABLED"
    | "SET_HEADLINE_CONTENT"
    | "SET_HEADLINE_ISENABLED"
    | "SET_LAUNCH_DATE_CONTENT"
    | "SET_LAUNCH_DATE_LABEL"
    | "SET_LAUNCH_DATE_ISENABLED"
    | "SET_NUMBER_OF_HOLDINGS_CONTENT"
    | "SET_NUMBER_OF_HOLDINGS_LABEL"
    | "SET_NUMBER_OF_HOLDINGS_ISENABLED"
    | "SET_PERFORMANCE_CONTENT"
    | "SET_PERFORMANCE_LABEL"
    | "SET_PERFORMANCE_ISENABLED"
    | "SET_REBALANCING_FREQUENCY_CONTENT"
    | "SET_REBALANCING_FREQUENCY_LABEL"
    | "SET_REBALANCING_FREQUENCY_ISENABLED"
    | "SET_ENDINGDATE_CONTENT"
    | "SET_ENDINGDATE_LABEL"
    | "SET_ENDINGDATE_ISENABLED";

  payload: any;
};
export const factsReducer = (draft: FactsType, action: ActionType) => {
  switch (action.type) {
    case "SET_CURRENCY_CONTENT":
      draft.currency.content = action.payload;
      break;
    case "SET_CURRENCY_ISENABLED":
      draft.currency.isEnabled = action.payload;
      break;
    case "SET_CURRENCY_LABEL":
      draft.currency.label = action.payload;
      break;
    case "SET_CUSTOMTEXT_1_CONTENT":
      draft.customText1.content = action.payload;
      break;
    case "SET_CUSTOMTEXT_1_ISENABLED":
      draft.customText1.isEnabled = action.payload;
      break;
    case "SET_CUSTOMTEXT_1_LABEL":
      draft.customText1.label = action.payload;
      break;
    case "SET_CUSTOMTEXT_2_CONTENT":
      draft.customText2.content = action.payload;
      break;
    case "SET_CUSTOMTEXT_2_ISENABLED":
      draft.customText2.isEnabled = action.payload;
      break;
    case "SET_CUSTOMTEXT_2_LABEL":
      draft.customText2.label = action.payload;
      break;
    case "SET_CUSTOMTEXT_3_CONTENT":
      draft.customText3.content = action.payload;
      break;
    case "SET_CUSTOMTEXT_3_ISENABLED":
      draft.customText3.isEnabled = action.payload;
      break;
    case "SET_HEADLINE_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_HEADLINE_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_LAUNCH_DATE_CONTENT":
      draft.launchDate.content = action.payload;
      break;
    case "SET_LAUNCH_DATE_LABEL":
      draft.launchDate.label = action.payload;
      break;
    case "SET_LAUNCH_DATE_ISENABLED":
      draft.launchDate.isEnabled = action.payload;
      break;
    case "SET_NUMBER_OF_HOLDINGS_CONTENT":
      draft.numberOfHoldings.content = action.payload;
      break;
    case "SET_NUMBER_OF_HOLDINGS_LABEL":
      draft.numberOfHoldings.label = action.payload;
      break;
    case "SET_NUMBER_OF_HOLDINGS_ISENABLED":
      draft.numberOfHoldings.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_CONTENT":
      draft.performance.content = action.payload;
      break;
    case "SET_PERFORMANCE_LABEL":
      draft.performance.label = action.payload;
      break;
    case "SET_PERFORMANCE_ISENABLED":
      draft.performance.isEnabled = action.payload;
      break;
    case "SET_REBALANCING_FREQUENCY_CONTENT":
      draft.rebalancingFrequency.content = action.payload;
      break;
    case "SET_REBALANCING_FREQUENCY_LABEL":
      draft.rebalancingFrequency.label = action.payload;
      break;
    case "SET_REBALANCING_FREQUENCY_ISENABLED":
      draft.rebalancingFrequency.isEnabled = action.payload;
      break;
    case "SET_ENDINGDATE_CONTENT":
      draft.endingDate.content = action.payload;
      break;
    case "SET_ENDINGDATE_LABEL":
      draft.endingDate.label = action.payload;
      break;
    case "SET_ENDINGDATE_ISENABLED":
      draft.endingDate.isEnabled = action.payload;
      break;
  }
};
