import { useEffect, useRef } from "react";
import { useFormatter } from "../../../../../hooks/useFormatter";

type ListViewerTileItemExposureProps = {
  source: any;
  timeframe: string;
  viewType: "A_B_vs_C_D" | "A_B_C_D";
};

export function ListViewerTileItemExposure({
  source,
  timeframe,
  viewType,
}: ListViewerTileItemExposureProps) {
  const formatter = useFormatter();
  const nodeTcr = useRef<HTMLDivElement>(null);
  const nodeA = useRef<HTMLDivElement>(null);
  const nodeB = useRef<HTMLDivElement>(null);
  const nodeC = useRef<HTMLDivElement>(null);
  const nodeD = useRef<HTMLDivElement>(null);
  const nodeAB = useRef<HTMLDivElement>(null);
  const nodeCD = useRef<HTMLDivElement>(null);
  const nodeA_B_C_D = useRef<HTMLDivElement>(null);
  const nodeAB_CD = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const data = {
      exposureA: 0,
      exposureB: 0,
      exposureC: 0,
      exposureD: 0,
      exposureAB: 0,
      exposureCD: 0,
      tcr: null,
    };

    if (source != null) {
      data["exposureA"] = source?.["A_perc"] ?? 0;
      data["exposureB"] = source?.["B_perc"] ?? 0;
      data["exposureC"] = source?.["C_perc"] ?? 0;
      data["exposureD"] = source?.["D_perc"] ?? 0;

      data["exposureAB"] = data["exposureA"] + data["exposureB"];
      data["exposureCD"] = data["exposureC"] + data["exposureD"];

      data["tcr"] = source["TCR"];
      data["tcr_d"] = source["TCR_D"];
    }

    let tcrTimeframe = timeframe || "daily";
    if (tcrTimeframe === "daily") {
      tcrTimeframe = "yesterday";
    } else if (tcrTimeframe === "weekly") {
      tcrTimeframe = "lastWeek";
    } else if (tcrTimeframe === "monthly") {
      tcrTimeframe = "lastMonth";
    }
    const innerHTML: any = [];
    if (nodeTcr.current) {
      if (data["tcr"] != null) {
        if (data["tcr"] !== data["tcr_d"]) {
          innerHTML.push(formatter.tcr(data["tcr_d"], "HTML"));
          innerHTML.push('<span class="tTableAlerts-arrow i-arrow-r"></span>');

          nodeTcr.current.classList.add("different-past-tcr");
        } else {
          nodeTcr.current.classList.remove("different-past-tcr");
        }
        innerHTML.push(formatter.tcr(data["tcr"], "HTML"));
        innerHTML.push("</div>");
        nodeTcr.current.innerHTML = innerHTML.join("");
      } else {
        nodeTcr.current.innerHTML = "-";
      }
    }
    // A, B, C, D, AB, CD
    var formatOptions = {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
    };

    if (nodeA.current) {
      nodeA.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureA"] === 100 ? 1 : data["exposureA"],
        valueHelper: null,
      });
    }

    if (nodeB.current) {
      nodeB.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureB"] === 100 ? 1 : data["exposureB"],
        valueHelper: null,
      });
    }

    if (nodeC.current) {
      nodeC.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureC"] === 100 ? 1 : data["exposureC"],
        valueHelper: null,
      });
    }

    if (nodeD.current) {
      nodeD.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureD"] === 100 ? 1 : data["exposureD"],
        valueHelper: null,
      });
    }

    if (nodeAB.current) {
      nodeAB.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureAB"] === 100 ? 1 : data["exposureAB"],
        valueHelper: null,
      });
    }

    if (nodeCD.current) {
      nodeCD.current.innerHTML = formatter.custom("number", {
        options: formatOptions,
        output: "HTML",
        value: data["exposureCD"] === 100 ? 1 : data["exposureCD"],
        valueHelper: null,
      });
    }

    if (nodeAB_CD.current && nodeA_B_C_D.current) {
      switch (viewType) {
        case "A_B_vs_C_D": {
          nodeA_B_C_D.current.classList.add("hide");
          nodeAB_CD.current.classList.remove("hide");

          break;
        }
        case "A_B_C_D":
        default: {
          nodeA_B_C_D.current.classList.remove("hide");
          nodeAB_CD.current.classList.add("hide");
        }
      }
    }
  });

  return (
    <div className="tListViewerTileItemExposure">
      <div className="tLayout">
        <div className="tLayout--width-40">
          <div className="tListViewerTileItemExposure-tcr" ref={nodeTcr}>
            <strong className="rate rate--U">-</strong>
          </div>
        </div>
        <div className="tLayout--width-60">
          <div className="tListViewerTileItemExposure-percentage">
            <div
              className="tListViewerTileItemExposure-percentage-ABCD"
              ref={nodeA_B_C_D}
            >
              <div>
                <strong className="rate rate--A">A</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeA}
                >
                  0.00%
                </span>
              </div>
              <div>
                <strong className="rate rate--B">B</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeB}
                >
                  0.00%
                </span>
              </div>
              <div>
                <strong className="rate rate--C">C</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeC}
                >
                  0.00%
                </span>
              </div>
              <div>
                <strong className="rate rate--D">D</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeD}
                >
                  0.00%
                </span>
              </div>
            </div>
            <div
              className="tListViewerTileItemExposure-percentage-AB_CD hide"
              ref={nodeAB_CD}
            >
              <div className="tListViewerTileItemExposure-percentage-AB">
                <strong className="rate rate--A">A</strong>
                <strong className="rate rate--B">B</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeAB}
                >
                  0.00%
                </span>
              </div>
              <div className="tListViewerTileItemExposure-percentage-CD">
                <strong className="rate rate--C">C</strong>
                <strong className="rate rate--D">D</strong>
                <span
                  className="tListViewerTileItemExposure-percentage-value"
                  ref={nodeCD}
                >
                  0.00%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
