export const disclaimer = `<article>
    <p>
        The evaluations, rankings and information provided by Trendrating (the
        &quot;Services&quot;) are provided solely for the use of finance
        professionals (the &quot;Users&quot;) who have been issued a license to
        use the Services by Trendrating and who, by nature of their status as
        investment professions understand, or are expected to understand, the
        complexity of finance products, the functioning of the markets and the
        risks inherent in them.
    </p>
    <p>
        The Services are <strong>not</strong> offered for use by persons not
        employed or actively working as professionals in the finance and
        investment industry.
    </p>
    <p>
        The Services are to be used entirely at the risk of the Users. Included
        in the Services are forward-looking statements that are based on
        observations, assumptions and calculations that reflect Trendrating's
        expectations. Forward-looking statements are subject to numerous risks
        and uncertainties that could cause actual results to materially differ
        from those expectations.
    </p>
    <p>
        The Services are
        <strong>not intended to constitute a recommendation</strong> of any
        specific security or financial product and are to be implemented
        <strong
            >solely in conjunction with or as part of a complete investment
            evaluation</strong
        >
        conducted by the Users.
    </p>
    <p>
        Under no circumstances shall Trendrating, its officers, directors,
        employees or agents be liable for any damages, lost profits or
        investment losses that result in any way from use of the Services or any
        interruptions, errors or delays in the Services.
    </p>
</article>`;
