/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/ImpersonatePage
 * @summary Impersonate Login page
 *
 */

import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { config } from "../../app/config-ts";
import { isValidEmail, isValidPassword, messageError } from "../../app/utils";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { appCookie } from "../../app/Cookies";

declare const window: any;

type ImpersonatePageProps = {
  storage: any;
};

export function ImpersonatePage({ storage }: ImpersonatePageProps) {
  const { t, ready } = useTranslation();
  let [searchParams] = useSearchParams();
  const { userId, firstName, lastName } = useParams();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    appCookie("impersonate", "login-reset");
    appCookie("productId", "login-reset");
  }, []);

  useEffect(() => emailInputRef.current?.focus(), []);

  useEffect(() => {
    if (ready) {
      document.body.classList.add("app--impersonate");
      document.title = `${t("Impersonate")} - Trendrating`;
    }
  }, [t, ready]);

  useEffect(() => {
    const userName = firstName + " " + lastName;
    const nodeAppClaim = document.getElementById("app-claim");

    if (ready && nodeAppClaim != null) {
      nodeAppClaim.innerHTML = `${t(
        "Impersonate"
      )} <span class="PageImpersonate-user">${userName}</span>`;
    }
  }, [t, ready, firstName, lastName]);

  const handleInputChange = (event: ChangeEvent) => {
    const target: any = event.target!;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "login-email") {
      setEmail(value.trim());
    } else if (name === "login-password") {
      setPassword(value.trim());
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const paramsPasswordSetup = {
      email,
      password: password,
    };
    impersonate(paramsPasswordSetup);
  };

  const { broadcast } = useBroadcast();

  const impersonate = ({ email, password }) => {
    if (isValidEmail(email) && isValidPassword(password)) {
      setDisableForm(true);

      var paramsLogin = {
        email: email,
        password: password,
      };

      return storage["users"].login(paramsLogin).then(
        (response) => {
          // TODO: no lead check on impersonate
          // if (response.group === "LEAD") {
          //     storage["users"].logout().then(() => {
          //         router.push(config.routes["/lead/"]);
          //     });
          //     return;
          // }

          const targetUrl = config.routes.app;
          window.location.href = targetUrl + "?impersonate=" + userId;
        },
        (error) => {
          setDisableForm(false);
          const [channel, msg] = messageError(
            t("Wrong_email_and_or_password"),
            error
          );
          broadcast(channel as string, msg);
        }
      );
    }

    const [channel, msg] = messageError(t("Wrong_email_and_or_password"));
    broadcast(channel as string, msg);
  };

  return (
    <div className="PageImpersonate">
      <form
        action="#login"
        className="Form"
        method="post"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <legend>{t("Impersonate_using_your_Trendrating_account")}</legend>
          <ol>
            <li>
              <label htmlFor="login--email">{t("Email")}</label>
              <br />
              <input
                className="Input"
                id="login-email"
                name="login-email"
                onChange={handleInputChange}
                ref={emailInputRef}
                type="email"
                value={email}
              />
            </li>
            <li>
              <label htmlFor="login-password">{t("Password")}</label>
              <br />
              <input
                className="Input"
                id="login-password"
                name="login-password"
                onChange={handleInputChange}
                type="password"
                value={password}
              />
            </li>
            <li className="Form-buttons">
              <button
                className="Button Button--primary"
                disabled={disableForm}
                type="submit"
              >
                {disableForm ? t("Impersonating") : t("Impersonate")}
              </button>
            </li>
          </ol>
        </fieldset>
      </form>
    </div>
  );
}
