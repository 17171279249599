export const initStrategyBuildersState = {
  strategies: null,
  strategy: null,
  strategyId: null,
  strategyInstrumentBenchmark: null,
  strategyInstrumentHedging: null,
  strategyResult: null,
  strategyType: null,
};
export type StrategyBuilderState = {
  strategies: {
    advanced: any[];
    alpha: any[];
    macroRotation: any[];
    smartBeta: any[];
  } | null;
  strategy: any;
  strategyId: number | null;
  strategyInstrumentBenchmark: any;
  strategyInstrumentHedging: any;
  strategyResult: any;
  strategyType: "alpha" | "smartBeta" | "macroRotation" | "advanced" | null;
};

type ActionType = {
  type:
    | "RESET"
    | "SET_STRATEGY_TYPE"
    | "SET_STRATEGY_ID"
    | "SET_STRATEGIES"
    | "SET_INSTRUMENT_BENCHMARK"
    | "SET_STRATEGY"
    | "SET_STRATEGY_PARAMS"
    | "SET_STUFF_ON_ID_AVAILABLE"
    | "SET_STRATEGY_RESULT"
    | "RESET_STRATEGY_RESULT"
    | "SET_LISTS_AND_STRATEGIES"
    | "SET_INSTRUMENT_HEDGING";
  payload: any;
};

export const strategiesBuilderReducer = (
  draft: StrategyBuilderState,
  action: ActionType
) => {
  switch (action.type) {
    case "SET_STRATEGIES":
      draft.strategies = action.payload;
      break;
    case "SET_STRATEGY":
      draft.strategy = action.payload;
      break;
    case "SET_STRATEGY_TYPE":
      draft.strategyType = action.payload;
      break;
    case "SET_STRATEGY_ID":
      draft.strategyId = action.payload;
      break;
    case "SET_STRATEGY_PARAMS":
      draft.strategy.params = action.payload;
      break;
    case "RESET":
      return action.payload;
    case "SET_STUFF_ON_ID_AVAILABLE":
      draft.strategy = action.payload.strategy;
      draft.strategyInstrumentBenchmark = action.payload.instrumentBenchmark;
      draft.strategyInstrumentHedging = action.payload.instrumentHedging;
      break;
    case "SET_STRATEGY_RESULT":
      draft.strategyResult = action.payload;
      break;
    case "RESET_STRATEGY_RESULT":
      draft.strategyResult = action.payload;
      break;
  }
};
