import { Formatter } from "../../../trendrating/formatter/Formatter";
import { escapeEntity } from "./Generator";
import { i18n } from "../nls/i18n-report";
import { sortBy } from "../../../trendrating/core/UtilsObject";

export class SectionLists {
  format: any;

  constructor() {
    this.format = new Formatter();
  }

  _formatAverage(value) {
    return [
      "<strong>",
      this._formatPercentageWithSign(value),
      "</strong>",
    ].join("");
  }

  _formatPercentage(value, output?) {
    return this.format.number({
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: output ?? "",
        },
      },
      output: "PDF",
      value: value,
      valueHelper: null,
    });
  }

  _formatHeadline(headline) {
    return this.format
      .string({
        options: {
          hasToEscapeXmlEntities: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "PDF",
        value: headline,
        valueHelper: null,
      })
      .toUpperCase();
  }

  _formatPercentageWithSign(value) {
    return this.format.number({
      options: {
        hasPositiveSign: true,
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "PDF",
      value: value,
      valueHelper: null,
    });
  }

  _formatTimeframeDispersion(section) {
    const timeframeValue = section["content"]["timeframe"];

    switch (timeframeValue) {
      case "3_months": {
        return i18n["3_Months"];
      }
      case "6_months": {
        return i18n["6_Months"];
      }
      case "12_months": {
        return i18n["12_Months"];
      }
      case "1_month":
      default: {
        return i18n["1_Month"];
      }
    }
  }

  getLabelFromSegment(segment) {
    let label = "Sector";

    switch (segment) {
      case "1 Industry":
      default:
        return label;

      case "3 Sector":
        label = "Industry";

        break;
      case "3 Level":
        label = "Size";

        break;

      case "Country":
        label = "Market";

        break;

      case "Region":
        label = "Region";

        break;

      case "Area":
        label = "Area";

        break;

      case "etfgeo":
        label = "Inv. Region";

        break;

      case "etfclass":
        label = "Asset Class";

        break;
    }

    return label;
  }

  generateDispersionChartWysiwyg(data, wysiwyg, section, pageOrientation) {
    const isEtfOnly = data["isEtfOnly"] ?? false;
    const _sections: any = [];
    const useWysiwyg = section?.content?.useWysiwyg;

    const timeframeCut = useWysiwyg
      ? wysiwyg?.["dispersionByConstraints"]?.["performanceTimeframe"] ?? ""
      : section?.content?.timeframe;
    const segment = useWysiwyg
      ? isEtfOnly
        ? wysiwyg["segmentEtf"]
        : wysiwyg["segment"]
      : section?.content?.segment;

    if (section["content"]["headline"]["isEnabled"]) {
      const timeframeToAppend = timeframeCut.replace("_", " ");
      const rightSegment = this.getLabelFromSegment(segment);

      let title = escapeEntity(
        section["content"]["headline"]["content"]
          .replace("sector", rightSegment)
          .toUpperCase()
      );

      if (section["content"]["showTimeframeInTitle"]) {
        title += " " + timeframeToAppend.toUpperCase();
      }

      const _section = {
        data: {
          text: title,
        },
        type: "header1",
      };
      _sections.push(_section);
    }
    let chartStyle = {
      height: 400,
      width: 632,
    };
    const orientation = pageOrientation;
    if (orientation === "portrait") {
      // Base
      chartStyle.height = 334;
      chartStyle.width = 530;
    } else {
      chartStyle.height = 422;
      chartStyle.width = 750;
    }
    const _section: any = {
      data: {
        head: [[]],
        body: [
          [
            {
              style: chartStyle,
              value: data.svg,
            },
          ],
        ],
      },
      type: "chartCustom",
    };
    const layout = {
      data: {
        layoutRow: [[_section /*, ...*/]],
      },
      type: "layout",
    };
    _sections.push(layout);
    return _sections;
  }

  generateDispersionBasketTable({ data, isEtfOnly }, wysiwyg, section) {
    if (data.length === 0) {
      return null;
    }

    const format = this.format;
    const useWysiwyg = section?.content?.useWysiwyg ?? true;
    const sections: any = [];
    const segment = isEtfOnly ? wysiwyg["segmentEtf"] : wysiwyg["segment"];
    const intervals = useWysiwyg
      ? wysiwyg?.["dispersionByConstraints"]?.["intervals"] ?? 25
      : section?.content?.intervals;

    const intervalsTitle = {
      25: {
        title_top: "Top 25%",
        title_mid: "Mid 50%",
        title_bottom: "Bottom 25%",
      },
      10: {
        title_top: "Top 10%",
        title_mid: "Mid 80%",
        title_bottom: "Bottom 10%",
      },
      5: {
        title_top: "Top 5%",
        title_mid: "Mid 90%",
        title_bottom: "Bottom 5%",
      },
      4: {
        title_top: "Top 25%",
        title_mid: "Mid 50%",
        title_bottom: "Bottom 25%",
      },
      20: {
        title_top: "Top 5%",
        title_mid: "Mid 90%",
        title_bottom: "Bottom 5%",
      },
    };

    const segmentLabel = this.getLabelFromSegment(segment);

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const timeframeCut = useWysiwyg
        ? wysiwyg?.["dispersionByConstraints"]?.["performanceTimeframe"] ?? ""
        : section?.content?.timeframe;
      const decodedTimeframeCut = timeframeCut.replace("_", " ");

      let title = section["content"]["headline"]["content"].replace(
        "sector",
        segmentLabel
      );

      if (section?.["content"]?.["showTimeframeInTitle"]) {
        title += " " + decodedTimeframeCut.toUpperCase();
      }

      const _section = {
        data: {
          text: this._formatHeadline(title),
        },
        type: "header1",
      };
      sections.push(_section);
    }

    const layout: any = {
      data: {
        body: [],
        colWidths: [11, null, null, null], // centimeters
        head: [
          [
            {
              style: null,
              value: segmentLabel,
            },
            {
              style: {
                align: "center",
              },
              value: "Costituents",
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_top"],
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_mid"],
            },
            {
              style: {
                align: "center",
              },
              value: intervalsTitle[intervals]["title_bottom"],
            },
          ],
        ],
      },
      type: "table",
    };

    const dataArray = Object.values<any>(data);
    const sorter = section.content.sort;

    if (sorter != null) {
      const property = sorter.property;
      const rev = sorter.descending;

      if (property === "_s_label" || property === "name") {
        sortBy(dataArray, "name", rev);
      } else {
        dataArray.sort((a, b) => {
          if (
            a["dispersion"][property]["average"] >
            b["dispersion"][property]["average"]
          ) {
            return rev === true ? -1 : 1;
          } else if (
            a["dispersion"][property]["average"] <
            b["dispersion"][property]["average"]
          ) {
            return rev === true ? 1 : -1;
          }

          return 0;
        });
      }
    }

    for (const sector of dataArray) {
      const row = [
        {
          style: null,
          value: format.string({
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "PDF",
            value: sector["name"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: "center",
          },
          value: format.string({
            options: {
              notAvailable: {
                input: null,
                output: "-",
              },
            },
            output: "PDF",
            value: sector["cardinality"],
            valueHelper: null,
          }),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(
            sector["dispersion"]["top"]["average"],
            "-"
          ),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(
            sector["dispersion"]["middle"]["average"],
            "-"
          ),
        },
        {
          style: {
            align: "center",
          },
          value: this._formatPercentage(
            sector["dispersion"]["bottom"]["average"],
            "-"
          ),
        },
      ];

      layout["data"]["body"].push(row);
    }

    sections.push(layout);

    return sections;
  }

  generateDispersion(data, section, peerType) {
    const sections: any = [];

    if (
      data["bottom"] == null ||
      data["middle"] == null ||
      data["top"] == null
    ) {
      return null;
    }

    // header
    if (section["content"]["headline"]["isEnabled"]) {
      const _section = {
        data: {
          text: this._formatHeadline(section["content"]["headline"]["content"]),
        },
        type: "header1",
      };
      sections.push(_section);
    }
    //
    // It compares the current interval average to the other interval
    // averages and returns a color accordingly
    //
    // This function is implemented also in
    // app/pages/analysisMarkets/widgets/PeerDispersion._intervalCssClass
    //
    function intervalColor(average, averageThreshold1, averageThreshold2) {
      if (average > 0) {
        if (average > averageThreshold1 && average > averageThreshold2) {
          return "#008000"; // positive high
        }
        if (average > averageThreshold1 || average > averageThreshold2) {
          return "#399608"; // positive normal
        }
        return "#078c52"; // positive low
      }

      if (average < averageThreshold1 && average < averageThreshold2) {
        return "#b00000"; // negative high
      }

      if (average < averageThreshold1 || average < averageThreshold2) {
        return "#f00000"; // negative normal
      }

      return "#f24949"; // negative low
    }

    function intervalLabels(intervals) {
      switch (intervals) {
        case 10: {
          return {
            bottom: i18n["Bottom_10_percent"],
            middle: i18n["Mid_80_percent"],
            top: i18n["Top_10_percent"],
          };
        }
        case 20: {
          return {
            bottom: i18n["Bottom_5_percent"],
            middle: i18n["Mid_90_percent"],
            top: i18n["Top_5_percent"],
          };
        }
        case 4:
        default: {
          return {
            bottom: i18n["Bottom_25_percent"],
            middle: i18n["Mid_50_percent"],
            top: i18n["Top_25_percent"],
          };
        }
      }
    }

    function generateEmptyRows(numberOfEmptyRows) {
      const emptyRows: any = [];

      for (let i = 0; i < numberOfEmptyRows; i++) {
        emptyRows.push([]);
      }

      return emptyRows;
    }

    // Used to generate proportional table for each interval
    //
    // UI allows to choose among:
    //   - 4 quantiles (25%)
    //   - 10 quantiles (10%)
    //   - 20 quantiles (5%)
    //
    // The quantiles are grouped in top, middle and bottom iterval
    //
    // To represent on PDF we need at least a table of 2 rows for each
    // interval
    //
    // The table has 40 rows: 2 rows * 20 quantiles
    let rowsBottom: any = null;
    let rowsMiddle: any = null;
    let rowsTop: any = null;
    switch (section["content"]["intervals"]) {
      case 10: {
        rowsBottom = generateEmptyRows(2); // 4 -2
        rowsMiddle = generateEmptyRows(30); // 32 -2
        rowsTop = generateEmptyRows(2); // 4 -2

        break;
      }
      case 20: {
        rowsBottom = generateEmptyRows(0); // 2 - 2
        rowsMiddle = generateEmptyRows(34); // 36 - 2
        rowsTop = generateEmptyRows(0); // 2 - 2

        break;
      }
      case 4:
      default: {
        rowsBottom = generateEmptyRows(8); // 10 - 2
        rowsMiddle = generateEmptyRows(18); // 20 - 2
        rowsTop = generateEmptyRows(8); // 10 - 2
      }
    }

    const labels = intervalLabels(section["content"]["intervals"]);
    const top = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["top"],
            },
            {
              style: null,
              value:
                data["top"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: {
                align: "right",
              },
              value: "max:",
            },
            {
              style: null,
              value: this._formatPercentageWithSign(data["top"]["max"]),
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["top"]["average"]),
            },
          ],
        ]
          .concat(rowsTop)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: {
                  align: "right",
                },
                value: "min:",
              },
              {
                style: null,
                value: this._formatPercentageWithSign(data["top"]["min"]),
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["top"]["average"],
          data["bottom"]["average"],
          data["middle"]["average"]
        ),
        head: [],
      },
      type: "table",
    };
    const middle = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["middle"],
            },
            {
              style: null,
              value:
                data["middle"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: null,
              value: "&nbsp;",
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["middle"]["average"]),
            },
          ],
        ]
          .concat(rowsMiddle)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "&nbsp;",
              },
              {
                style: null,
                value: "&nbsp;",
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["middle"]["average"],
          data["bottom"]["average"],
          data["top"]["average"]
        ),
        head: [],
      },
      type: "table",
    };
    const bottom = {
      data: {
        body: [
          [
            {
              style: {
                fontSize: "large",
              },
              value: labels["bottom"],
            },
            {
              style: null,
              value:
                data["bottom"]["cardinality"] +
                (peerType === "ETF" ? " ETFs" : " stocks"),
            },
            {
              style: {
                align: "right",
              },
              value: "max:",
            },
            {
              style: null,
              value: this._formatPercentageWithSign(data["bottom"]["max"]),
            },
            {
              style: {
                align: "right",
              },
              value: "avg.",
            },
            {
              style: {
                align: "right",
                fontSize: "large",
              },
              value: this._formatAverage(data["bottom"]["average"]),
            },
          ],
        ]
          .concat(rowsBottom)
          .concat([
            [
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
              {
                style: {
                  align: "right",
                },
                value: "min:",
              },
              {
                style: null,
                value: this._formatPercentageWithSign(data["bottom"]["min"]),
              },
              {
                style: null,
                value: "",
              },
              {
                style: null,
                value: "",
              },
            ],
          ]),
        color: intervalColor(
          data["bottom"]["average"],
          data["middle"]["average"],
          data["top"]["average"]
        ),
        head: [],
      },
      type: "table",
    };

    const _section = {
      data: [[top], [middle], [bottom]],
      type: "peerDispersion",
    };
    sections.push(_section);

    // legend
    const _sectionLegend = {
      data: {
        style: {
          fontSize: "normal",
        },
        text:
          "<br/>" +
          i18n["report_peer_dispersion_legend_$timeframe"].replaceAll(
            // eslint-disable-next-line no-template-curly-in-string
            "${timeframe}",
            this._formatTimeframeDispersion(section)
          ),
      },
      type: "text",
    };
    sections.push(_sectionLegend);

    return sections;
  }
}
