/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/configuration/sectionProperties
 * @summary Fixed securities properties to be retrived for report sections
 */

import { IsoDate } from "../../../types/Api";

export type SectionProperty = {
    date: IsoDate | null;
    property: string;
};

type SectionPropertyWrapType =
    | "trendrating-report/common/security-chart"
    | "trendrating-report/portfolio/alert"
    | "trendrating-report/portfolio/new-high-new-low"
    | "trendrating-report/portfolio/rating-change"
    | "trendrating-report/portfolio/positive-negative-performer"
    | "trendrating-report/strategy/benchmark"
    | "trendrating-report/systematic-portfolios/benchmark";

type SectionPropertyWrap = Record<
    SectionPropertyWrapType,
    {
        properties: SectionProperty[];
    }
>;

export const sectionProperties: SectionPropertyWrap = {
    "trendrating-report/common/security-chart": {
        properties: [
            {
                date: null,
                property: "dr",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "pr",
            },
            {
                date: null,
                property: "prr",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "rrr",
            },
            {
                date: null,
                property: "ticker",
            },
        ],
    },
    "trendrating-report/portfolio/alert": {
        properties: [
            {
                date: null,
                property: "ticker",
            },
        ],
    },
    "trendrating-report/portfolio/new-high-new-low": {
        properties: [
            {
                date: null,
                property: "currency",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "lhl",
            },
            {
                date: null,
                property: "vc",
            },
        ],
    },
    "trendrating-report/portfolio/rating-change": {
        properties: [
            {
                date: null,
                property: "dr",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "rrr",
            },
        ],
    },
    "trendrating-report/portfolio/positive-negative-performer": {
        properties: [
            {
                date: null,
                property: "currency",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "pm",
            },
            {
                date: null,
                property: "pq",
            },
            {
                date: null,
                property: "pw",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "vc",
            },
        ],
    },
    "trendrating-report/strategy/benchmark": {
        properties: [
            {
                date: null,
                property: "dr",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "pr",
            },
            {
                date: null,
                property: "prr",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "rrr",
            },
            {
                date: null,
                property: "ticker",
            },
        ],
    },
    "trendrating-report/systematic-portfolios/benchmark": {
        properties: [
            {
                date: null,
                property: "currency",
            },
            {
                date: null,
                property: "name",
            },
            {
                date: null,
                property: "rc",
            },
            {
                date: null,
                property: "symbol",
            },
        ],
    },
};
