/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateReportPeer
 * @summary Manages peer analysis report templates
 *
 */

import { _TemplateReport } from "./_TemplateReport";

export class TemplateReportPeer extends _TemplateReport {
    storedObjectType = "TEMPLATE_REPORT_PEER" as const;
}
