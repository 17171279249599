import { useEffect } from "react";

export const useDetectOutsideClick = (handler: (e) => void) => {
    useEffect(() => {
        /**
         * Execute handler on click
         */
        function handleClickOutside(event) {
            handler(event);
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handler]);
};
