import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClusterAnalytics } from "../../../../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import { extractSymbols } from "../../../../../../../api/compute/commons";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { httpAll } from "../../../../../../../httpAll";
import { messageError } from "../../../../../utils";
import { ComparePortfolios } from "./ComparePortfolios";
import ListSelector from "./ListSelector";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { SummaryTableData } from "./compare_business_logic/SummaryTableData";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type TabCompareProps = {
  dataManager: PortfolioAnalyzeStorage;
};

const clone = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export function TabCompare({ dataManager }: TabCompareProps) {
  const [clusterLevel, setClusterLevel] = useState<
    | "Country"
    | "1 Industry"
    | "3 Sector"
    | "Currency"
    | "etfgeo"
    | "AssetClass"
    | "Specialty"
    | "3 Level"
    | "Area"
    | "Region"
    | "Type"
  >("Country");
  const [stateTableWidget, setStateTableWidget] = useState<{
    dataClusterPortfolio: any;
    dataClusterComparisonList: any;
  }>({
    dataClusterPortfolio: [],
    dataClusterComparisonList: [],
  });
  const [state, setState] = useState<{
    portfolio: any;
    comparisonList: any;
    holdings: any;
    holdingsComparison: any;
    compareListPerformance: any;
    performance: any;
  }>({
    portfolio: null,
    comparisonList: null,
    holdings: null,
    holdingsComparison: null,
    compareListPerformance: null,
    performance: null,
  });
  const [lists, setLists] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const environment = useEnvironment();
  const { broadcast } = useBroadcast();

  const apiCluster = useMemo(
    () => new ClusterAnalytics(environment.get("setup")),
    [environment]
  );
  const listsAPI = useMemo(
    () => new Lists(environment.get("setup")),
    [environment]
  );
  const instrumentsAPI = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  const [showListsSelector, setShowListsSelector] = useState(false);
  const [showCompareTab, setShowCompareTab] = useState(false);

  const dataPrepareSort = useCallback((data) => {
    data.sort(function (a, b) {
      // sort by weight weight (10 -> 0)
      var sortByL1 = "weight";

      if (a[sortByL1] > b[sortByL1]) {
        return -1;
      }

      if (a[sortByL1] < b[sortByL1]) {
        return 1;
      }

      return 0;
    });
  }, []);

  const dataPrepareHelper = useCallback(
    (rawData, dataType) => {
      var data: any = [];
      var item;

      for (var id in rawData) {
        item = rawData[id];
        data.push({
          cardinality: item["cardinality"],
          constituents: item["constituents"],
          id: id,
          rate: item["TCR"],
          type: dataType,
          weight: item["weight"],
        });
      }

      dataPrepareSort(data);

      return data;
    },
    [dataPrepareSort]
  );

  const getClustersData = useCallback(
    async (positions, segment) => {
      const positionsToday = positions ?? [];

      const params = ["TCR", "weight", "cardinality"];

      const response = await apiCluster
        .createConfiguration()
        .segment(segment)
        .method("INTERSECTION")
        .analytics(params)
        .universeFromPositions(positionsToday)
        .fetchAnalytics();

      const injectCostituents = (response) => {
        const stats = response["clustersStats"]["stats"];
        const clusters = response["clustersStats"]["clusters"];

        for (const [key, value] of Object.entries<any>(stats)) {
          value["constituents"] = clusters[key];
        }

        return stats;
      };

      const data = dataPrepareHelper(injectCostituents(response), "where");

      return data;
    },
    [apiCluster, dataPrepareHelper]
  );

  const getLists = useCallback(async () => {
    const userCollectionIds = await listsAPI._getFilter();
    const subscribed = await listsAPI._getSubscribed();

    const listIds = [...userCollectionIds, ...subscribed.map((pub) => pub.id)];
    const response = await listsAPI.portfolioFetch(listIds, [
      "name",
      "ownerId",
      "type",
    ]);

    const userId = environment.get("setup")["account"]["user"]["id"];

    const collection: any = [];

    for (const element of response) {
      collection.push({
        name: element.name,
        type: element.type,
        id: element.id,
        isReadOnly: element.ownerId !== userId,
      });
    }

    setLists(collection);
    setShowListsSelector(true);
  }, [environment, listsAPI]);

  const getHoldingsFields = useCallback(
    async (holdings) => {
      const symbols = holdings?.map((el) => el.symbol);
      const filterParams = {
        filters: [
          {
            dimension: "symbol",
            segments: symbols,
          },
        ],
        page: {
          page: 1,
          rows: 10000,
        },
      };

      // TODO: Fetch only required fields
      const response = await instrumentsAPI.newFilterAndFetch(
        filterParams,
        "security",
        undefined
      );

      return response?.data;
    },
    [instrumentsAPI]
  );

  const getPerformanceData = useCallback(
    async (timeframe, positions) => {
      if (positions != null) {
        const symbols = extractSymbols(positions ?? []);

        var paramsDowngrade = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          ranges: [
            {
              dimension: "rc",
              segments: [
                {
                  max: -1,
                  min: -2,
                },
              ],
            },
            {
              dimension: "lr",
              segments: [
                {
                  max: timeframe,
                  min: 0,
                },
              ],
            },
          ],
          page: {
            page: 1,
            rows: symbols.length,
          },
        };

        var paramsUpgrade = clone(paramsDowngrade);
        paramsUpgrade["ranges"][0]["segments"] = [
          {
            max: 2,
            min: 1,
          },
        ];

        var requestDowngrade = instrumentsAPI.screening(paramsDowngrade);
        var requestUpgrade = instrumentsAPI.screening(paramsUpgrade);

        return await httpAll({
          downgrade: requestDowngrade,
          upgrade: requestUpgrade,
        });
      }
    },
    [instrumentsAPI]
  );

  const getUpgradesDowngradesData = useCallback(
    async (positions, timeframe) => {
      if (!positions) {
        return;
      }

      const period = timeframe;
      let timeframeDecoded = 0;

      switch (period) {
        case "lastWeek":
          timeframeDecoded = 4;
          break;
        case "lastMonth":
          timeframeDecoded = 19;
          break;
        case "today":
        default:
          timeframeDecoded = 0;
      }

      const res = await getPerformanceData(timeframeDecoded, positions);

      const symbolsDowngrades = res.downgrade.data;
      const symbolsUpgrades = res.upgrade.data;

      return {
        symbolsDowngrades,
        symbolsUpgrades,
      };
    },
    [getPerformanceData]
  );

  const updateDowngradesUpgradesTimeframe = useCallback(
    async (timeframe) => {
      const portfolio = clone(state.portfolio);
      const portfolioTarget = clone(state.comparisonList);

      const upgradesDownGradesPortfolio = await getUpgradesDowngradesData(
        portfolio.positions,
        timeframe
      );
      const upgradesDownGradesPortfolioTarget = await getUpgradesDowngradesData(
        portfolioTarget.positions,
        timeframe
      );

      setState({
        ...state,
        performance: upgradesDownGradesPortfolio,
        compareListPerformance: upgradesDownGradesPortfolioTarget,
      });
    },
    [getUpgradesDowngradesData, state]
  );

  const getPortfolioById = useCallback(
    async (id) => {
      const portfolio = {
        positions: null,
        tcrToday: null,
        tcrYesterday: null,
        type: null,
        name: null,
        assetType: null,
        cardinalityPerRating: { A: 0, B: 0, C: 0, D: 0 },
        weightsPerRating: { A: 0, B: 0, C: 0, D: 0 },
      };

      try {
        const fields = [
          "positionsToday",
          "TCR",
          "TCR_D",
          "type",
          "name",
          "assetTypes",
          "A",
          "B",
          "C",
          "D",
          "A_%",
          "B_%",
          "C_%",
          "D_%",
        ];

        const response = await listsAPI.portfolioFetch([id], fields);

        if (response?.[0]) {
          const data = response[0];

          portfolio["name"] = data["name"];
          portfolio["positions"] = data["positionsToday"];
          portfolio["tcrToday"] = data["TCR"];
          portfolio["tcrYesterday"] = data["TCR_D"];
          portfolio["type"] = data["type"];
          portfolio["assetType"] = data["assetTypes"];
          portfolio["cardinalityPerRating"] = {
            A: data["A"] ?? 0,
            B: data["B"] ?? 0,
            C: data["C"] ?? 0,
            D: data["D"] ?? 0,
          };
          portfolio["weightsPerRating"] = {
            A: data["A_%"] ?? 0,
            B: data["B_%"] ?? 0,
            C: data["C_%"] ?? 0,
            D: data["D_%"] ?? 0,
          };
        }

        return portfolio;
      } catch (error) {
        console.log(error);
      } finally {
        return portfolio;
      }
    },
    [listsAPI]
  );

  const getPortfolio = useCallback(
    async (id?) => {
      const isBenchmarkPortfolio = id != null;

      const STATE_KEYS = {
        list: isBenchmarkPortfolio ? "comparisonList" : "portfolio",
        clusters: isBenchmarkPortfolio
          ? "dataClusterComparisonList"
          : "dataClusterPortfolio",
        holdings: isBenchmarkPortfolio ? "holdingsComparison" : "holdings",
        upgradesDowngrades: isBenchmarkPortfolio
          ? "compareListPerformance"
          : "performance",
      };
      const data = {
        state: {},
        aggregated: {},
      };

      let list: any = null;

      if (id) {
        list = await getPortfolioById(id);
        // needToRetrieve list fields
        // const listAnalytics = await dataManager.getListAnalytics(id);
        // list = dataManager.prepareList(listAnalytics, id);
      } else {
        list = await dataManager.get("compare");
      }

      const aggregatedStatistics = await getClustersData(
        list.positions,
        "Country"
      );

      const holdings = await getHoldingsFields(list.positions);
      const upgradesDownGrades = await getUpgradesDowngradesData(
        list.positions,
        "today"
      );

      data.state[STATE_KEYS["list"]] = list;
      data.state[STATE_KEYS["holdings"]] = holdings;
      data.state[STATE_KEYS["upgradesDowngrades"]] = upgradesDownGrades;

      data.aggregated[STATE_KEYS["clusters"]] = aggregatedStatistics;

      return data;
    },
    [
      dataManager,
      getClustersData,
      getHoldingsFields,
      getPortfolioById,
      getUpgradesDowngradesData,
    ]
  );

  const handleError = useCallback(
    (error) => {
      const [channel, msg] = messageError(JSON.stringify(error));
      broadcast(channel as string, msg);
      console.log(error);

      setShowCompareTab(false);
      setShowListsSelector(true);
    },
    [broadcast]
  );

  const changeTarget = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const target = await getPortfolio(id);

        setState((prev) => {
          return { ...prev, ...clone(target.state) };
        });

        setStateTableWidget((prev) => ({
          ...prev,
          ...clone(target.aggregated),
        }));
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [getPortfolio, handleError]
  );

  const compare = useCallback(
    async (targetListId) => {
      setIsLoading(true);
      setShowListsSelector(false);
      setShowCompareTab(true);

      try {
        const portfolio = await getPortfolio();
        const portfolioTarget = await getPortfolio(targetListId);

        const data = {
          ...clone(portfolio.state),
          ...clone(portfolioTarget.state),
        };
        const aggregatedData = {
          ...clone(portfolio.aggregated),
          ...clone(portfolioTarget.aggregated),
        };

        setState(data);
        setStateTableWidget(aggregatedData);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    },

    [getPortfolio, handleError]
  );

  const buildFilters = useCallback((filters, symbols) => {
    const params: any = {
      filters: [
        {
          dimension: "symbol",
          segments: symbols,
        },
      ],
      page: {
        page: 1,
        rows: 1000,
      },
      ranges: null,
    };

    const country = filters?.country ?? [];

    if (country.length) {
      params.filters.push({
        dimension: "country",
        segments: filters.country,
      });
    }

    const etfgeo = filters?.etfgeo ?? [];

    if (etfgeo.length) {
      params.filters.push({
        dimension: "etfgeo",
        segments: filters.etfgeo,
      });
    }

    const etfclass = filters?.etfclass ?? [];

    if (etfclass.length) {
      params.filters.push({
        dimension: "etfclass",
        segments: filters.etfclass,
      });
    }

    const specialty = filters?.specialty ?? [];

    if (specialty.length) {
      params.filters.push({
        dimension: "etfclass",
        segments: filters.specialty,
      });
    }

    const area = filters?.area ?? [];

    if (area.length) {
      params.filters.push({
        dimension: "country",
        segments: filters.area,
      });
    }

    const region = filters?.region ?? [];

    if (region.length) {
      params.filters.push({
        dimension: "country",
        segments: filters.region,
      });
    }

    const sector = filters?.sector ?? [];

    if (sector.length) {
      params.filters.push({
        dimension: "icb",
        segments: filters.sector,
      });
    }

    const industry = filters?.industry ?? [];

    if (industry.length) {
      params.filters.push({
        dimension: "icb",
        segments: filters.industry,
      });
    }

    const currency = filters?.currency ?? [];

    if (currency.length) {
      params.filters.push({
        dimension: "currency",
        segments: filters.currency,
      });
    }

    const type = filters?.type ?? [];

    if (type.length) {
      params.filters.push({
        dimension: "type",
        segments: filters.type,
      });
    }

    const size = filters?.size;
    params.ranges = [
      {
        dimension: "marketcap",
        segments: [
          {
            max: size.right ?? null,
            min: size.left ?? null,
          },
        ],
      },
    ];

    return params;
  }, []);

  const getFilteredDataTable = useCallback(
    async (filters, symbols, updateTableData) => {
      const params = buildFilters(filters, symbols);

      const response = await instrumentsAPI.screening(params);

      if (response != null) {
        let filteredData: any = [];
        const { data } = response;

        if (
          state.portfolio != null &&
          state.comparisonList != null &&
          state.holdings != null &&
          state.holdingsComparison != null
        ) {
          const dataTableUtils = new SummaryTableData(
            state.holdings,
            state.holdingsComparison,
            state.portfolio.positions,
            state.comparisonList.positions,
            state.portfolio.type,
            state.comparisonList.type
          );

          const tableData = dataTableUtils.get(environment.setup);

          if (tableData) {
            for (const position of data) {
              const positionData = tableData.find(
                (item) => item.symbol === position
              );

              if (positionData) {
                filteredData.push(positionData);
              }
            }
          }
        }

        updateTableData(filteredData);
      }
    },
    [
      buildFilters,
      environment.setup,
      instrumentsAPI,
      state.comparisonList,
      state.holdings,
      state.holdingsComparison,
      state.portfolio,
    ]
  );

  const onClusterLevelChange = useCallback(
    async (level) => {
      setClusterLevel(level);

      const portfolioPositions = state?.portfolio?.positions ?? [];
      const portfolioTargetPositions = state?.comparisonList?.positions ?? [];

      const portfolioCluster = await getClustersData(portfolioPositions, level);
      const portfolioTargetCluster = await getClustersData(
        portfolioTargetPositions,
        level
      );

      setStateTableWidget({
        dataClusterPortfolio: portfolioCluster,
        dataClusterComparisonList: portfolioTargetCluster,
      });
    },
    [
      getClustersData,
      state?.comparisonList?.positions,
      state?.portfolio?.positions,
    ]
  );

  const onPageMount = useCallback(async () => {
    getLists();
  }, [getLists]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return (
    <div style={{ height: "100%" }}>
      <div data-dojo-attach-point="loaderNode"></div>
      <div data-dojo-attach-point="mainNode" className="tAnalysisCompare">
        {showListsSelector && (
          <div
            data-dojo-attach-point="compareListSelectorNode"
            className="compare-portfolio-list-selector"
          >
            <ListSelector lists={lists} setID={compare} />
          </div>
        )}

        {showCompareTab && (
          <>
            {isLoading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                marginTop={"25vh"}
                overflow={"hidden"}
                justifyContent={"center"}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <CircularProgress sx={{ color: "#2a7090" }} />
                  <Typography>Comparing...</Typography>
                </Box>
              </Box>
            ) : (
              <ComparePortfolios
                clusterLevel={clusterLevel}
                data={state}
                onChangeUpgradesDowngradesTimeframe={
                  updateDowngradesUpgradesTimeframe
                }
                setComparisonListId={changeTarget}
                syncFilters={getFilteredDataTable}
                setClusterLevel={onClusterLevelChange}
                clusterState={stateTableWidget}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
