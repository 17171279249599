import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import styles from "./Alternatives.module.scss";
import { AlternativesDataManager } from "./AlternativesDataManager";
import AlternativesHeader from "./AlternativesHeader";
import { AlternativesList } from "./AlternativesList";
import { AlternativesPositionsList } from "./AlternativesPositionsList";
import {
  AlternativesState,
  defaultAlternativesState,
  reducerAlternatives,
} from "./AlternativesReducer";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { deepClone } from "../../../../../../../deepClone";
import { withStatusHandler } from "../../../../../../../components/LayoutElements/Block/Block";
import { useResizer } from "../../../../../../../hooks/useResizer";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { config } from "../../../../../config-ts";
import { Loader } from "../../../../../components/Loader";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { ExportOrders } from "../../../actions/ExportOrders";
import { ApplySwap } from "../../../actions/ApplySwap";

type AlternativesProps = {
  portfolioDataManagement: PortfolioAnalyzeStorage;
  data?: any;
};

const getSwappedPositions = (
  positions: AlternativesState["positions"],
  swapList: AlternativesState["swapList"]
) => {
  const posToSwap = deepClone(positions);

  let sellItemIndex: number | null = null;

  const { sell } = swapList;

  for (const sellItem of sell) {
    const itemInOriginalList = posToSwap.find(
      (pos) => pos.symbol === sellItem.item.symbol
    );
    sellItemIndex = posToSwap.indexOf(itemInOriginalList);
    if (sellItemIndex != null && sellItemIndex !== -1) {
      posToSwap[sellItemIndex]["symbol"] = sellItem.swapId;
    }
  }

  return posToSwap;
};

const getPortfolio = async (dataManager: PortfolioAnalyzeStorage) => {
  return await dataManager.get("improve");
};

export function Alternatives({ portfolioDataManagement }: AlternativesProps) {
  const AlternativesWithStatus = withStatusHandler(AlternativesContent, () =>
    getPortfolio(portfolioDataManagement)
  );

  return <AlternativesWithStatus customLoader={<Loader />} />;
}

const AlternativesContent = ({ data }: AlternativesProps) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [showSwapList, setShowSwapList] = useState(false);
  useResizer({ ref: rowRef });
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();

  const [state, dispatch] = useImmerReducer(
    reducerAlternatives,
    defaultAlternativesState
  );

  const environment = useEnvironment();
  const dataManager = useMemo(
    () => new AlternativesDataManager(environment),
    [environment]
  );

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PORTFOLIO", payload: data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (state.portfolio) {
      dispatch({
        type: "SET_POSITIONS",
        payload: state.portfolio?.positionsSource,
      });
    }
  }, [dispatch, state.portfolio]);

  // Workflow bar
  useEffect(() => {
    const actions: any = [];

    let workflowState = "s0";

    if (state.focusedSecurity != null) {
      workflowState = "s1";
    }

    if (showSwapList) {
      workflowState = "s2";
    }

    switch (workflowState) {
      case "s1": {
        const action = {
          componetJSX: (
            <li onClick={() => setShowSwapList(true)} className="menu__item">
              Open Replace List
            </li>
          ),
        };

        actions.push(action);

        break;
      }

      case "s2":
        let action: any = null;
        if (state.swapList.buy.length) {
          const listOptimized = deepClone(state.portfolio);
          listOptimized["positions"] = getSwappedPositions(
            state.positions,
            state.swapList
          );

          action = {
            componentJSX: (
              <ApplySwap
                label={"Apply replacement"}
                target={{ list: state.portfolio, listOptimized }}
              />
            ),
          };

          actions.push(action);

          action = {
            componentJSX: (
              <li
                className="menu__item"
                onClick={() => dispatch({ type: "CLEAR_SWAP_LIST" })}
              >
                Clear replace list
              </li>
            ),
          };

          actions.push(action);

          const orders = {
            buy: state.swapList.buy,
            sell: state.swapList.sell.map((el) => el.item),
          };
          action = {
            componentJSX: (
              <ExportOrders
                label={"Export to excel"}
                target={{ orders, list: state.portfolio }}
                title={t("Export_orders_to_MS_Excel")}
              />
            ),
          };

          actions.push(action);
        }

        if (showSwapList && state.focusedSecurity != null) {
          //Show the alternatives
          action = {
            componentJSX: (
              <li className="menu__item" onClick={() => setShowSwapList(false)}>
                Close replace list
              </li>
            ),
          };

          actions.push(action);
        }

      //no default
    }

    const message = {
      from: "ID",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    dispatch,
    showSwapList,
    state.focusedSecurity,
    state.portfolio,
    state.portfolio?.isReadOnly,
    state.positions,
    state.swapList,
    state.swapList.buy.length,
    t,
  ]);

  useEffect(() => {
    setShowSwapList(state.focusedSecurity == null);
  }, [dispatch, state.focusedSecurity]);

  return (
    <div>
      <AlternativesHeader portfolio={state?.portfolio} />
      <div className={styles.alternatives__content__wrapper} ref={rowRef}>
        <div className={styles.alternatives__content__left}>
          <AlternativesPositionsList
            DM={dataManager}
            mainDispatch={dispatch}
            positions={state?.portfolio?.positions ?? []}
            sellList={state.swapList.sell}
          />
        </div>
        <AlternativesList
          positions={state.portfolio?.positions ?? []}
          mainDispatch={dispatch}
          focusedSecurity={state.focusedSecurity}
          DM={dataManager}
          sellList={state.swapList.sell}
          buyList={state.swapList.buy}
          showSwapList={showSwapList}
          constraints={state.constraints}
        />
      </div>
    </div>
  );
};
