import { Box, Button, Card, CardContent } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../deepClone";
import Benchmark from "./components/Benchmark";
import Currency from "./components/Currency";
import Holdings from "./components/Holdings";
import Performance from "./components/Performance";
import Rebalance from "./components/Rebalance";
import { reducer, StrategyStateType } from "./strategyReducer";
import { UI_CONSTRAINTS_TYPE } from "../../../js/app/pages/strategies/builder/editors/Advanced/utils";

type WizzardProps = {
  input: StrategyStateType;
  onCancel: Function;
  onApplyChanges: Function;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};
const Wizzard = forwardRef(({ input, onCancel, onApplyChanges, UI_CONSTRAINTS }: WizzardProps, ref) => {
  const initReducerState: StrategyStateType = {
    benchmark: null,
    currency: null,
    performance: null,
    rebalance: null,
    holdings: null,
    errorAtInputValue: false,
  };

  const [strategyState, strategyDispatch] = useImmerReducer(reducer, initReducerState);

  useEffect(() => {
    strategyDispatch({ type: "SET_STATE", payload: input });
  }, [input, strategyDispatch]);

  useImperativeHandle(ref, () => ({
    getWizzardState: () => {
      const stateCloned = deepClone(strategyState);
      return stateCloned;
    },
  }));


  const compareState = useMemo(() => {
    if (strategyState.errorAtInputValue === true) {
      return true;
    }
    if (strategyState["benchmark"] !== input["benchmark"]) {
      return false;
    }
    if (strategyState["currency"] !== input["currency"]) {
      return false;
    }
    if (strategyState["holdings"] !== input["holdings"]) {
      return false;
    }
    if (strategyState["performance"] !== input["performance"]) {
      return false;
    }
    if (strategyState["rebalance"] !== input["rebalance"]) {
      return false;
    }
    return true;
  }, [input, strategyState]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          maxHeight: "100%",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
        style={{ width: "100%", backgroundColor: "transparent", height: "100%" }}
      >
        <Card sx={{ height: "100%", boxShadow: 3, overflow:"unset" }}>
          <CardContent sx={{ pb: "16px !important", height: "100%", display: "flex", gap: 2 }}>
            <Holdings strategyDispatch={strategyDispatch} input={strategyState.holdings} />
            <Rebalance strategyDispatch={strategyDispatch} input={strategyState.rebalance} />
            <Currency strategyDispatch={strategyDispatch} input={strategyState.currency} />
            <Performance strategyDispatch={strategyDispatch} input={strategyState.performance} />
            <Benchmark UI_CONSTRAINTS={UI_CONSTRAINTS} strategyDispatch={strategyDispatch} input={strategyState["benchmark"]} />
          </CardContent>
        </Card>

        <Card sx={{ overflow: "unset", boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important", display: "flex", justifyContent: "flex-end" }}>
            <Button type="button" onClick={() => onApplyChanges()} disabled={compareState}>
              Apply rule
            </Button>
            <Button
              sx={{
                ml: 2,
              }}
              variant="tr_button_cancel"
              type="button"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
});

export default Wizzard;
