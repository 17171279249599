import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ListSelect } from "../../../../../../../../../../components/ListSelect/ListSelect";
import { deepClone } from "../../../../../../../../../../deepClone";

type ListNewsType = {
  input: any;
  type: "PORTFOLIO" | "BASKET";
  setListNews: Function;
};
export const ListNews = ({ input, type, setListNews }: ListNewsType) => {
  const [state, setState] = useState(input);
  useEffect(() => {
    setState(input);
  }, [input]);

  const [radioValue, setRadioValue] = useState(() => {
    if (state?.uiParams != null) {
      if (state?.uiParams?.domain?.[0] === "-1") {
        return "all";
      }
      return "specific";
    } else {
      return "all";
    }
  });

  const radioHandler = (e) => {
    if (e.target.value === "all") {
      let temp = deepClone(state);
      temp["searchContext"] = {
        relations: [{ domain: ["-1"], range: type }],
      };
      temp["uiParams"] = {
        domain: ["-1"],
        range: type,
      };
      setState(temp);
      setListNews(temp, type);

      setRadioValue("all");
    }
    if (e.target.value === "specific") {
      setRadioValue("specific");
    }
  };

  const switchHandler = useCallback(
    (e) => {
      let temp = deepClone(state);
      temp.enabled = e.target.checked;
      if (temp.enabled === false) {
        delete temp.searchContext;
        delete temp.uiParams;
      } else {
        if (temp.searchContext == null && temp.uiParams == null) {
          temp["searchContext"] = {
            relations: [{ domain: ["-1"], range: type }],
          };
          temp["uiParams"] = {
            domain: ["-1"],
            range: type,
          };
        }
      }
      setState(temp);
      setListNews(temp, type);
    },
    [setListNews, state, type]
  );

  const seletionListHandler = useCallback(
    (ids: number[]) => {
      // ids to string
      let stringIDS: string[] = [];
      if (ids.length > 0) {
        ids.forEach((id) => {
          stringIDS.push(id.toString());
        });


        //! why to remove "-1" here?
        // since we don't want to change the state
        // if no list is selected, we will then we remove 
        // "-1" only when a list is selected
        if(stringIDS[0] === "-1"){
          stringIDS= stringIDS.slice(1)
        }
        //!------------------------


        let tempState = deepClone(state);
        tempState.searchContext.relations[0].domain = stringIDS;
        tempState.uiParams.domain = stringIDS;
        setListNews(tempState, type);
      }
    },
    [setListNews, state, type]
  );
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Typography variant="subtitle1">
          Receive News on {type === "BASKET" ? "Basket" : "Portfolios"}
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <FormControlLabel
            onChange={switchHandler}
            checked={state?.enabled}
            control={<Switch size="small" />}
            label="On"
          />
          <RadioGroup value={radioValue} onChange={radioHandler} row>
            <FormControlLabel
              control={<Radio />}
              disabled={!state?.enabled}
              label={`All ${type === "BASKET" ? "Baskets" : "Portfolios"}`}
              value={"all"}
            />
            <FormControlLabel
              disabled={!state?.enabled}
              control={<Radio />}
              label={`Set a specific ${
                type === "BASKET" ? "Basket" : "Portfolio"
              }`}
              value={"specific"}
            />
          </RadioGroup>
          {radioValue === "specific" && state.enabled && (
            <ListSelect
              selected={state?.searchContext?.relations?.[0]?.domain ?? []}
              label={type === "PORTFOLIO" ? "Portfolios" : "Baskets"}
              title={
                type === "PORTFOLIO" ? "Select portfolios" : "Select baskets"
              }
              onApplyClick={seletionListHandler}
              showBasket={type === "BASKET"}
              showPortfolios={type === "PORTFOLIO"}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
