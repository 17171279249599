/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/trendrating-report/_Base
 * @summary Base for object classes: automatic getters/setters for properties
 *
 */

export class _Base {
    constructor(params) {
        if (params != null) {
            for (var property in params) {
                this.set(property, params[property]);
            }
        }
    }

    get(property) {
        return this[property];
    }

    set(property, value) {
        this[property] = value;
    }

    sort(a, b, property, descending) {
        descending = descending === undefined ? false : descending;

        if (a[property] > b[property]) {
            return descending ? -1 : 1;
        }

        if (a[property] < b[property]) {
            return descending ? 1 : -1;
        }

        return 0;
    }
}
