import { memo, useCallback, useState } from "react";
import { useImmer } from "use-immer";
import { Accordion } from "../../../../../../../components/Accordion/Accordion";
import { SummaryTable } from "./SummaryTable";
import SummaryTableChart from "./SummaryTableChart";
import { FilterWidget } from "../../../../../../../components/FilterWidget/FilterWidget";

type SummaryTableWidgetProps = {
  data: any;
  portfolio: any;
  portfolio2: any;
  syncFilters: (filters, symbols, setTableData) => void;
  symbols: any[];
  positionsType: any;
};

export const SummaryTableWidget = memo(
  ({
    data,
    portfolio,
    portfolio2,
    syncFilters,
    symbols,
    positionsType,
  }: SummaryTableWidgetProps) => {
    const [chartData, setChartData] = useState({
      securityName: "",
      securityTicker: "",
      chartUri: "",
      weightP1: "",
      weightP2: "",
    });
    const [isOpen, setIsOpen] = useState(true);
    const [filters, setFilters] = useImmer<{
      where: string[];
      what: string[];
      size: { left: number | null; right: number | null };
    }>({
      where: [],
      what: [],
      size: { left: null, right: null },
    });
    const [tableData, setTableData] = useState(data ?? []);

    const toggleAccordion = () => setIsOpen(!isOpen);

    const arrowDirection = isOpen ? "down" : "up";
    const title = (
      <div className="accordion-compare-title">
        <h2>Holdings comparison</h2>
        <span
          onClick={toggleAccordion}
          className={`i-${arrowDirection}`}
        ></span>
      </div>
    );

    const updateFilters = useCallback(
      (value) => {
        setFilters(value);

        const preparedFilters = {
          [positionsType === "ETF" ? "etfgeo" : "country"]: value.where,
          [positionsType === "ETF" ? "etfclass" : "sector"]: value.what,
          size: value.size,
        };
        syncFilters(preparedFilters, symbols, setTableData);
      },
      [positionsType, setFilters, symbols, syncFilters]
    );

    return (
      <div className="compare-portfolio-sectionRow compare-portfolio-sectionRow--summary-table">
        <Accordion titleJSX={title} isOpen={isOpen}>
          <>
            <div className="blockWrapper">
              <FilterWidget
                setFiltersStock={updateFilters}
                initState={filters}
              />
            </div>
            {isOpen ? (
              <div
                style={{
                  display: "flex",
                  height: 500,
                  marginTop: 20,
                }}
              >
                <div className="summary-table-wrapper blockWrapper">
                  <SummaryTable
                    rowClickHandler={setChartData}
                    data={tableData}
                    portfolio1Name={portfolio ?? ""}
                    portfolio2Name={portfolio2 ?? ""}
                  />
                </div>
                <SummaryTableChart
                  portfolio={portfolio}
                  portfolio2={portfolio2}
                  data={chartData}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        </Accordion>
      </div>
    );
  }
);
