import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../../../api/compute/Taxon";
import { _rate } from "../../../../../../../../trendrating/formatter/_rate";

type Segment =
  | "Country"
  | "1 Industry"
  | "3 Sector"
  | "3 Level"
  | "Currency"
  | "Type"
  | "Region"
  | "etfgeo"
  | "AssetClass"
  | "Specialty"
  | "Area";

export class MarketsData {
  constructor(
    public firstSerie,
    public secondSerie,
    public firstSerieType,
    public secondSerieType,
    public environment
  ) {}

  prepareSerie = (data) => {
    if (!data.length) {
      return data;
    }
    const dataSerie: any = [];

    if (data != null) {
      let rateMeta: any = null;
      let totalWeight = 0;
      const ratingScale = _rate.trendCaptureRating;

      for (const item of data) {
        rateMeta = ratingScale[item.rate != null ? String(item.rate) : "U"];
        totalWeight = totalWeight + item["weight"];
        dataSerie.push({
          color: rateMeta["colorChart"],
          name: rateMeta["label"],
          trendrating: item,
          y: item["weight"],
        });
      }

      if (totalWeight < 1) {
        rateMeta = ratingScale["CASH"];
        dataSerie.push({
          color: rateMeta["colorChart"],
          name: rateMeta["label"],
          trendrating: null,
          y: 1 - totalWeight,
        });
      }
    }

    return dataSerie;
  };

  getData = (selector: Segment, positionsType: string) => {
    const firstSerie = this.firstSerie ?? [];
    const secondSerie = this.secondSerie ?? [];
    const firstSerieType = this.firstSerieType;
    const secondSerieType = this.secondSerieType;
    const env = this.environment;

    const taxonomies = env.get("rawTaxonomies");
    const taxonFieldsMap = env.get("taxonomyFields");

    let field = this.getFieldFromSelector(selector);

    // First value of the segment is Country so to avoid errors with ETFs we apply this check
    // When this part will be converted in react this logic has to be renewed
    if (positionsType === "ETF" && field === "country") {
      field = "etfgeo";
    }

    const taxonField =
      taxonFieldsMap[positionsType === "ETF" ? "ETF" : "security"][field];

    const taxonomy = taxonomies[taxonField];

    const aggregatedData = [].concat(firstSerie, secondSerie);

    const result = aggregatedData.length
      ? aggregatedData.map((el: any) => {
          let newMap = {
            id: el.id,
            rate1: firstSerie.find((item) => item.id === el.id)?.rate ?? null,
            rate2: secondSerie.find((item) => item.id === el.id)?.rate ?? null,
            weight1: firstSerie.find((item) => item.id === el.id)?.weight ?? 0,
            weight2: secondSerie.find((item) => item.id === el.id)?.weight ?? 0,
            weightDiff: 0,
            firstListType: firstSerieType,
            secondListType: secondSerieType,
          };

          //If the selector is Currency or Size is not needed to translate the id by using taxonomies
          if (
            selector === "3 Level" ||
            selector === "Currency" ||
            selector === "Type"
          ) {
            const value = el["id"];
            //Capitalize first letter if is Size selector
            newMap["segment"] =
              selector === "3 Level"
                ? value.replace(value[0], value[0].toUpperCase())
                : value;
          } else {
            newMap["segment"] = formatTaxonPrefixingParent(
              getTaxonById(el["id"], [taxonomy], 0),
              [taxonomy],
              selector === "Region" ? "Region" : "3 Sector"
            );
          }

          //Check if lists are basket and set the weight to null
          if (firstSerieType === "BASKET" || secondSerieType === "BASKET") {
            if (firstSerieType === "BASKET" && secondSerieType !== "BASKET") {
              newMap.weight1 = null;
              newMap.weightDiff = newMap.weight2;
            }
            if (firstSerieType !== "BASKET" && secondSerieType === "BASKET") {
              newMap.weight2 = null;
              newMap.weightDiff = newMap.weight1;
            }

            if (firstSerieType === "BASKET" && secondSerieType === "BASKET") {
              newMap.weight1 = null;
              newMap.weight2 = null;
              newMap.weightDiff = 0;
            }
          } else {
            newMap.weightDiff = newMap.weight1 - newMap.weight2;
          }

          return newMap;
        })
      : [];

    if (!result.length) {
    }

    return Array.from(new Set(result.map((item: any) => item.segment))).map(
      (segment) => {
        return result.find((el: any) => el.segment === segment);
      }
    );
  };

  getFieldFromSelector(selector: Segment) {
    switch (selector) {
      case "1 Industry":
        return "sector";
      case "Area":
      case "Region":
      case "Country":
        return "country";
      case "3 Level":
        return "SizeClassification";
      case "3 Sector":
        return "industry";
      case "etfgeo":
        return "etfgeo";
      case "Specialty":
      case "AssetClass":
        return "etfclass";

      default:
        return "country";
    }
  }
}
