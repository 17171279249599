import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../deepClone";
import { useFormatter } from "../../../hooks/useFormatter";
import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
type MatchesProps = {
  matches: any;
  onAddFromMultiple: Function;
  formRef?: any;
};

export default function Matches({ matches, onAddFromMultiple }: MatchesProps) {
  const [multiple, setMultiple] = useState<any[]>(matches.multiple);

  // console.log("multiple", multiple);
  // console.log("unique", matches.unique);
  // console.log("unrecognized", matches.unrecognized);

  const addHandler = (event, subItem, indexMultipleElement) => {
    event.value = subItem;
    onAddFromMultiple(event);

    let tempMultiple = deepClone(multiple);
    tempMultiple = tempMultiple.filter(
      (item) => tempMultiple.indexOf(item) !== indexMultipleElement
    );

    setMultiple(tempMultiple);
  };

  return (
    <Box
      height={"100%"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
    >
      {multiple.length > 0 && (
        <Box
          height={"100%"}
          overflow={"hidden"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Multiple
            onAddFromMultipleHandler={addHandler}
            multipleArray={multiple}
          />
        </Box>
      )}

      {matches.unrecognized.length > 0 && (
        <Box
          height={"100%"}
          p={2}
          overflow={"hidden"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Unrecognized unrecognizedArray={matches.unrecognized} />
        </Box>
      )}
    </Box>
  );
}

// *********************************
// *********unrecognized************
type unrecognizedProps = {
  unrecognizedArray: any[];
};
const Unrecognized = ({ unrecognizedArray }: unrecognizedProps) => {
  const { t } = useTranslation();
  const format = useFormatter();
  return (
    <>
      <Typography variant="subtitle1">{t("c_import_unrecognized")}</Typography>
      <Card sx={{ overflow: "hidden", height: "100%" }}>
        <CardContent sx={{ height: "100%", overflow: "hidden" }}>
          <Box overflow={"auto"} height={"100%"}>
            {unrecognizedArray.map((item, index) => (
              <Box key={index} display={"flex"} gap={2}>
                <Typography>
                  {format.custom("number", {
                    options: {
                      isPercentage: true,
                      notAvailable: {
                        input: null,
                        output: "",
                      },
                    },
                    output: "HTML",
                    value: item.weight,
                  })}
                </Typography>
                <Typography variant="tr_subtitle_blue">{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

// *********************************
// ************multiple*************
type multipleProps = {
  multipleArray: any[];
  onAddFromMultipleHandler: Function;
};
const Multiple = ({
  multipleArray,
  onAddFromMultipleHandler,
}: multipleProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="subtitle1" p={2} pb={0}>
        {t("c_import_multiple_matches")}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        p={2}
        overflow={"auto"}
      >
        {multipleArray.map((item, indexMultipleElement) => (
          <Card sx={{ overflow: "unset" }} key={indexMultipleElement}>
            <CardContent sx={{ display: "flex", gap: 2 }}>
              <Typography variant="tr_subtitle_blue">
                <strong>{item.label}</strong>
              </Typography>
              <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                {item.positions.map((subItem) => (
                  <Chip
                    label={
                      <Typography>
                        {subItem.ticker} {subItem.name} {subItem.currency}:
                        {subItem.type}
                      </Typography>
                    }
                    key={subItem.symbol}
                    onClick={(event: any) => {
                      onAddFromMultipleHandler(
                        event,
                        subItem,
                        indexMultipleElement
                      );
                    }}
                    size="small"
                    variant="filled"
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};
