import { Box } from "@mui/material";
import { useMemo } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";
import { RuleType } from "../AdvancedReducer";

type SelectionRulesProps = {
  value: null | RuleType[];
};

export default function SelectionRules({ value }: SelectionRulesProps) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const rules = useMemo(() => {
    if (value != null) {
      if (value.length > 0) {
        return strategyFormat.selection(value);
      }
    }
    return null;
  }, [strategyFormat, value]);
  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0, width: "100%" }}>
        <tbody>
          {rules != null ? (
            rules.map((item, index) => (
              <tr
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <td
                  dangerouslySetInnerHTML={{ __html: item[0] }}
                  style={{ display: "flex", width: "100%" }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{ __html: item[1] }}
                  style={{ display: "flex", width: "100%" }}
                ></td>
              </tr>
            ))
          ) : (
            <tr>
              <td>Not set</td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
}
