import { ReactElement } from "react";
import InputNumber from "../../../InputNumber/InputNumber";

type WizzValueProps = {
  type: string;
  dispatcher: Function;
  initVal?: any;
  constraints: any;
};

export default function WizzValue({ type, constraints, dispatcher, initVal }: WizzValueProps) {
  let output: ReactElement = <></>;
  switch (type) {
    case "quantile":
      output = (
        <InputNumber
          getIsValid={(e) => dispatcher({ type: "SET_ERROR_AT_INPUT_VALUE", payload: e })}
          initVal={initVal}
          isPercentage={false}
          setOuterState={(e) => {
            const vv = {
              augmentation: {
                value: e,
              },
              subject: type,
            };
            dispatcher({ type: "SET_FUNCTION_PARAMS", payload: vv });
          }}
          constraints={{ minVal: constraints?.min, maxVal: constraints?.max }}
          isFloating={false}
        />
      );
      break;
    case "outlier":
      output = (
        <InputNumber
          //?? multiplying constrainst by 100 because it arrives from the server like:
          //?? {min:0, max: 1}
          constraints={{ minVal: constraints?.min * 100, maxVal: constraints?.max * 100 }}
          //??------------------------------------------------------------------------
          getIsValid={(e) => dispatcher({ type: "SET_ERROR_AT_INPUT_VALUE", payload: e })}
          initVal={initVal}
          isPercentage={true}
          setOuterState={(e) => {
            const vv = {
              augmentation: {
                value: e,
              },
              subject: type,
            };
            dispatcher({ type: "SET_FUNCTION_PARAMS", payload: vv });
          }}
          isFloating={true}
        />
      );
      break;
    case "threshold":
      output = (
        <InputNumber
          //?? multiplying constrainst by 100 because it arrives from the server like:
          //?? {min:0, max: 1}
          constraints={{ minVal: constraints?.min * 100, maxVal: constraints?.max * 100 }}
          //??------------------------------------------------------------------------
          getIsValid={(e) => dispatcher({ type: "SET_ERROR_AT_INPUT_VALUE", payload: e })}
          isPercentage={true}
          setOuterState={(e) => {
            const vv = {
              augmentation: {
                value: e,
              },
              subject: type,
            };
            dispatcher({ type: "SET_FUNCTION_PARAMS", payload: vv });
          }}
          isFloating={true}
        />
      );
      break;
  }
  return <div key={type}>{output}</div>;
}
