import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./SystematicIndex.module.scss";
import { SystematicPortfolioContext } from "../SystematicPortfolios";
import { SystematicProductChart } from "../widgets/SystematicProductChart";
import { ProductSummary } from "../widgets/ProductSummary";
import { RebalanceInfo } from "../widgets/RebalanceInfo";
import { ProductPreviewAnalytic } from "../widgets/ProductPreviewAnalytic";
import { v4 as uuidv4 } from "uuid";

type SystematicIndexProps = {
  productId: any;
  onClickItem: (id) => void;
};

type LoaderCardProps = {
  rows: ("text" | "rect")[];
  textRowProps?: { height?: number };
  rectRowProps?: { height?: number };
};

export const SystematicIndex = forwardRef(
  ({ productId, onClickItem }: SystematicIndexProps, ref: any) => {
    const nodeRef = useRef<HTMLDivElement>(null);
    const storage = useContext(SystematicPortfolioContext);
    const [chartData, setChartData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [productInfo, setProductInfo] = useState<any>();

    /**
     * This load function is called from outside through the intersection observer
     */
    const load = useCallback(async () => {
      setIsLoading(true);
      let product = storage.getProduct(productId);

      // If the product has already loaded don't reload it
      if (!product) {
        await storage.setProduct(productId);
        product = storage.getProduct(productId);
      }

      setProductInfo(product.info());

      let productObject = await product.chart({ years: 10 });
      productObject = await product.rebalanceInfo();

      setChartData(productObject.chart);
      setIsLoading(false);
    }, [productId, storage]);

    useImperativeHandle(
      ref,
      () => ({
        load,
        get: () => nodeRef.current,
      }),
      [load]
    );

    const goToProduct = useCallback(() => {
      onClickItem(productId);
    }, [onClickItem, productId]);

    return (
      <Card
        ref={nodeRef}
        data-list-id={productId}
        className={styles.smsIndexCard}
        onClick={goToProduct}
      >
        <CardContent className={styles.smsIndexCardContent}>
          {isLoading ? (
            <LoaderCard rows={["text"]} />
          ) : (
            <Box>
              <Typography className={styles.productNameTitle}>
                {productInfo?.name}
              </Typography>{" "}
              {productInfo?.isReadOnly && (
                <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
              )}
            </Box>
          )}
          <RebalanceInfo
            enableClickableLink={false}
            product={storage.getProduct(productId)}
          />
          <Box className={styles.smsPreviewBox}>
            <Card className={styles.analyticCard}>
              <CardContent>
                {isLoading ? (
                  <LoaderCard
                    rows={["text", "text", "text", "text", "text", "text"]}
                  />
                ) : (
                  <ProductSummary product={storage.getProduct(productId)} />
                )}
              </CardContent>
            </Card>
            <Card className={styles.chartCard}>
              <CardContent className={styles.chartCardContent}>
                {isLoading ? (
                  <LoaderCard rows={["text", "rect", "rect", "rect", "rect"]} />
                ) : (
                  <SystematicProductChart
                    data={chartData}
                    chartInteractiveMode={{
                      isEnabled: false,
                      plugins: [],
                    }}
                    isLogarithmicMeasure
                  />
                )}
              </CardContent>
            </Card>
            <Card className={styles.analyticCard}>
              <CardContent>
                {isLoading ? (
                  <LoaderCard
                    rows={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                  />
                ) : (
                  <ProductPreviewAnalytic
                    product={storage.getProduct(productId)}
                  />
                )}
              </CardContent>
            </Card>
          </Box>
        </CardContent>
      </Card>
    );
  }
);

const LoaderCard = ({ rows, textRowProps, rectRowProps }: LoaderCardProps) => {
  return (
    <Stack spacing={1}>
      {rows.map((type) => {
        switch (type) {
          default:
            return <Fragment key={uuidv4()}></Fragment>;

          case "rect":
            return (
              <Skeleton
                key={uuidv4()}
                variant="rectangular"
                width={"100%"}
                height={rectRowProps?.height ?? 30}
              />
            );

          case "text": {
            return (
              <Skeleton
                key={uuidv4()}
                height={textRowProps?.height ?? 10}
                variant="text"
                sx={{
                  fontSize: "20px",
                  marginBottom: "20px",
                }}
              />
            );
          }
        }
      })}
    </Stack>
  );
};
