import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { MarketCapFilter } from "../../../../../../../../../pages/screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import DomicileSelector from "./widgets/DomicileSelector";
import MarketSelector from "./widgets/MarketSelector";
import { SectorSelector } from "./widgets/SectorSelector";
import { deepClone } from "../../../../../../../../../../../deepClone";

type Props = {
  input: any;
  setUniverse: Function;
};

export default function UniverseNews({ input, setUniverse }: Props) {
  const sectorsSegments = useMemo(() => {
    if (input?.searchContext != null) {
      let icbFilters = input?.searchContext?.filters?.filter(
        (item) => item.dimension === "icb"
      );
      if (icbFilters?.length > 0) {
        return icbFilters[0].segments;
      }
    }
    return [];
  }, [input]);
  const domicileSegments = useMemo(() => {
    if (input?.searchContext != null && input?.searchContext?.filters) {
      let domicileFilters = input?.searchContext?.filters?.filter(
        (item) => item.dimension === "domicile"
      );
      if (domicileFilters.length > 0) {
        return domicileFilters[0].segments;
      }
    }
    return [];
  }, [input]);
  const marketSegments = useMemo(() => {
    if (input?.searchContext != null && input?.searchContext?.filters) {
      let countryFilters = input?.searchContext?.filters?.filter(
        (item) => item.dimension === "country" || item.dimension === "subtype"
      );
      if (countryFilters.length > 0) {
        return countryFilters;
      }
    }
    return [];
  }, [input]);
  const marketCapSize = useMemo(() => {
    if (input?.searchContext?.ranges != null) {
      let obj = {
        ge: input.searchContext.ranges[0].segments[0].min / 1000000,
        le: input.searchContext.ranges[0].segments[0].max / 1000000,
      };
      return obj;
    }
    return null;
  }, [input?.searchContext?.ranges]);

  //#region callbacks
  const callbackMarket = useCallback(
    (universe) => {
      universe["uiParams"]["markets"] = null;
      if (marketSegments.length > 0) {
        if (universe["searchContext"]["filters"]) {
          marketSegments.forEach((item) => {
            universe["searchContext"]["filters"].push(item);
          });
        } else {
          universe["searchContext"]["filters"] = marketSegments;
        }
        let domestic: boolean = false;
        let foreign: boolean = false;
        let UIobj = {};
        marketSegments.forEach((item) => {
          if (item.dimension === "subtype") {
            if (item.segments[0] === "Domestic Stock") {
              domestic = true;
              foreign = false;
            } else {
              foreign = true;
              domestic = false;
            }
            UIobj["domestic"] = domestic;
            UIobj["foreign"] = foreign;
          } else if (item.dimension === "country") {
            UIobj["ids"] = item.segments;
          }
        });
        universe["uiParams"]["markets"] = UIobj;
      }
    },
    [marketSegments]
  );
  const callbackDomicile = useCallback(
    (universe) => {
      universe["uiParams"]["countries"] = null;
      if (domicileSegments.length > 0) {
        if (universe["searchContext"]["filters"]) {
          universe["searchContext"]["filters"].push({
            dimension: "domicile",
            segments: domicileSegments,
          });
        } else {
          universe["searchContext"]["filters"] = [
            {
              dimension: "domicile",
              segments: domicileSegments,
            },
          ];
        }
        universe["uiParams"]["countries"] = domicileSegments;
      }
    },
    [domicileSegments]
  );
  const callbackSector = useCallback(
    (universe) => {
      universe["uiParams"]["sectors"] = null;
      if (sectorsSegments.length > 0) {
        if (universe["searchContext"]["filters"]) {
          universe["searchContext"]["filters"].push({
            dimension: "icb",
            segments: sectorsSegments,
          });
        } else {
          universe["searchContext"]["filters"] = [
            {
              dimension: "icb",
              segments: sectorsSegments,
            },
          ];
        }
        universe["uiParams"]["sectors"] = sectorsSegments;
      }
    },
    [sectorsSegments]
  );
  const callbackMarketCap = useCallback(
    (universe) => {
      universe["uiParams"]["mktCapRange"] = null;
      if (marketCapSize) {
        const preparedValue = [
          {
            dimension: "marketcap",
            segments: [
              {
                max: marketCapSize.le * 1000000,
                min: marketCapSize.ge * 1000000,
              },
            ],
          },
        ];
        universe["searchContext"]["ranges"] = preparedValue;
        universe["uiParams"]["mktCapRange"] = [
          {
            type: "ranges",
            value: preparedValue,
          },
        ];
      }
    },
    [marketCapSize]
  );
  const callbackCleanUniverse = useCallback((universe) => {
    var counter = Object.keys(universe["searchContext"]).length;
    universe["searchContext"] = counter > 0 ? universe["searchContext"] : null;
    universe["uiParams"] = counter > 0 ? universe["uiParams"] : null;
  }, []);
  //#endregion

  //#region handlers
  const marketHandler = useCallback(
    (marketSelection: any[]) => {
      var isEnabled = input.enabled;
      var universe = {
        enabled: isEnabled,
        nhnl: false,
        notifications: true,
        rerating: true,
      };
      if (isEnabled) {
        universe["searchContext"] = {};
        universe["uiParams"] = {};
        universe["uiParams"]["markets"] = null;
        let marketFIlters: any[] = [];
        let UiIds: any[] = [];
        let foreign = false;
        let domestic = false;
        if (marketSelection.length > 0) {
          marketSelection.forEach(
            (item: {
              type: string;
              value: { dimension: string; segments: any[] };
            }) => {
              let obj = item.value;
              marketFIlters.push(obj);
              if (obj.dimension === "subtype") {
                if (obj.segments[0] === "Domestic Stock") {
                  domestic = true;
                  foreign = false;
                } else {
                  foreign = true;
                  domestic = false;
                }
              }
              if (obj.dimension === "country") {
                UiIds = obj.segments;
              }
            }
          );
          universe["searchContext"]["filters"] = marketFIlters;
          if (!universe["uiParams"]["markets"]) {
            universe["uiParams"]["markets"] = {};
          }
          universe["uiParams"]["markets"]["ids"] = UiIds;
          universe["uiParams"]["markets"]["domestic"] = domestic;
          universe["uiParams"]["markets"]["foreign"] = foreign;
        }
        // domicile
        callbackDomicile(universe);
        //sector
        callbackSector(universe);
        //mktCap
        callbackMarketCap(universe);
        //! avoid to save not relevant settings
        callbackCleanUniverse(universe);
        setUniverse(universe);
      }
    },
    [
      callbackCleanUniverse,
      callbackDomicile,
      callbackMarketCap,
      callbackSector,
      input.enabled,
      setUniverse,
    ]
  );
  const marketCapHandler = useCallback(
    (size: { le: any; ge: any }) => {
      var isEnabled = input.enabled;
      var universe = {
        enabled: isEnabled,
        nhnl: false,
        notifications: true,
        rerating: true,
      };
      if (isEnabled) {
        universe["searchContext"] = {};
        universe["uiParams"] = {};
        //--
        universe["uiParams"]["mktCapRange"] = null;
        if (size.le != null && size.ge != null) {
          const preparedValue = [
            {
              dimension: "marketcap",
              segments: [
                {
                  max: size?.le * 1000000,
                  min: size?.ge * 1000000,
                },
              ],
            },
          ];
          universe["searchContext"]["ranges"] = preparedValue;
          universe["uiParams"]["mktCapRange"] = preparedValue;
        }

        // domicile
        callbackDomicile(universe);
        //sector
        callbackSector(universe);
        //market
        callbackMarket(universe);

        //! avoid to save not relevant settings
        callbackCleanUniverse(universe);
        setUniverse(universe);
      }
    },
    [
      callbackCleanUniverse,
      callbackDomicile,
      callbackMarket,
      callbackSector,
      input.enabled,
      setUniverse,
    ]
  );
  const domicileHandler = useCallback(
    (domicileSelection: any[]) => {
      var isEnabled = input.enabled;
      var universe = {
        enabled: isEnabled,
        nhnl: false,
        notifications: true,
        rerating: true,
      };
      if (isEnabled) {
        universe["searchContext"] = {};
        universe["uiParams"] = {};
        universe["uiParams"]["countries"] = null;
        if (domicileSelection.length != null && domicileSelection.length > 0) {
          if (universe["searchContext"]["filters"]) {
            universe["searchContext"]["filters"].push(
              domicileSelection[0].value
            );
          } else {
            universe["searchContext"]["filters"] = [domicileSelection[0].value];
          }
          universe["uiParams"]["countries"] =
            domicileSelection[0].value.segments;
        }
        //sector
        callbackSector(universe);
        //mktCap
        callbackMarketCap(universe);
        //market
        callbackMarket(universe);
        //! avoid to save not relevant settings
        callbackCleanUniverse(universe);
        setUniverse(universe);
      }
    },
    [
      callbackCleanUniverse,
      callbackMarket,
      callbackMarketCap,
      callbackSector,
      input.enabled,
      setUniverse,
    ]
  );
  const sectorHandler = useCallback(
    (sectorSelection) => {
      var isEnabled = input.enabled;
      var universe = {
        enabled: isEnabled,
        nhnl: false,
        notifications: true,
        rerating: true,
      };
      if (isEnabled) {
        universe["searchContext"] = {};
        universe["uiParams"] = {};
        universe["uiParams"]["sectors"] = null;
        if (sectorSelection.length != null && sectorSelection.length > 0) {
          if (universe["searchContext"]["filters"]) {
            universe["searchContext"]["filters"].push(sectorSelection[0].value);
          } else {
            universe["searchContext"]["filters"] = [sectorSelection[0].value];
          }
          universe["uiParams"]["sectors"] = sectorSelection[0].value.segments;
        }
        //domicile
        callbackDomicile(universe);
        //mktCap
        callbackMarketCap(universe);
        //market
        callbackMarket(universe);
        //! avoid to save not relevant settings
        callbackCleanUniverse(universe);
        setUniverse(universe);
      }
    },
    [
      callbackCleanUniverse,
      callbackDomicile,
      callbackMarket,
      callbackMarketCap,
      input.enabled,
      setUniverse,
    ]
  );
  const switchHandler = useCallback(
    (e) => {
      let temp = deepClone(input);
      temp.enabled = e.target.checked;
      if (e.target.checked === false) {
        temp.searchContext = null;
        temp.uiParams = null;
      } else {
        temp["searchContext"] = null;
        temp["uiParams"] = null;
      }
      setUniverse(temp);
    },
    [input, setUniverse]
  );
  //#endregion

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent
        sx={{ pb: "16px !important", display: "flex", flexDirection:"column" }}
      >
        <Typography variant="subtitle1">
          Receive News of your investment universe
        </Typography>
        <Box display={"flex"}>
          <FormControlLabel
            control={
              <Switch
                onChange={switchHandler}
                checked={input.enabled}
                size="small"
              />
            }
            label="On"
          />
          {input.enabled && (
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <SectorSelector
                sectorSelectionFilterHandler={sectorHandler}
                segments={sectorsSegments}
              />
              <DomicileSelector
                domicileSelectionFilterHandler={domicileHandler}
                segments={domicileSegments}
              />
              <MarketSelector
                markets={marketSegments}
                marketSelectionFilterHandler={marketHandler}
              />

              <MarketCapFilter
                initValue={marketCapSize}
                hasRangeSlider={true}
                updateValue={marketCapHandler}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
