import { Box, Card, CardContent } from "@mui/material";
import styles from "./AnalisysSecurity.module.scss";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TrendratingChart } from "./components/TrendratingChart";
import { Storage } from "./Storage";
import { deepClone } from "../../../../deepClone";
import { CompareItem } from "./components/CompareItem";
import { CompareItemLegend } from "./components/CompareItemLegend";
import Search from "../../widgets/ReactSearch/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { v4 as uuidv4 } from "uuid";
import { httpAll } from "../../../../httpAll";
import { ErrorBoundary } from "../../../../ErrorBoundary";

type TabCompareProps = {
  value: any;
};

type InstruementsState = {
  instrument: {
    information: any;
    history: any;
  };
  benchmark: {
    information: any;
    history: any;
  };
  others: any[];
};

export function TabCompare({ value }: TabCompareProps) {
  const environment = useEnvironment();
  const [instruments, setInstruments] = useState<InstruementsState>({
    instrument: {
      information: null,
      history: null,
    },
    benchmark: {
      information: null,
      history: null,
    },
    others: [],
  });

  const searchRef = useRef<{ focus: () => void }>();
  const itemNodes = useRef({});

  const curves = useMemo(() => {
    if (instruments != null) {
      const curves: any[] = [];

      if (instruments.instrument?.information != null) {
        const security = instruments.instrument?.information;

        const firstCurve = {
          id: security.symbol,
          name: security.ticker,
          currency: security.currency,
          prices: instruments.instrument?.history ?? [],
          data: security,
        };

        curves.push(firstCurve);
      }

      if (instruments.others && instruments.others.length) {
        for (const security of instruments.others) {
          curves.push({
            id: security.information.symbol,
            name: security.information.ticker,
            currency: security.information.currency,
            prices: security.history ?? [],
            data: security.information,
          });
        }
      }

      return curves;
    }
    return [];
  }, [instruments]);

  const storage = useMemo(
    () => new Storage(environment.get("setup")),
    [environment]
  );

  const registerRef = useCallback((symbol) => {
    const newRef = createRef();

    itemNodes.current[symbol] = newRef;

    return newRef;
  }, []);

  const compareItems = useMemo(() => {
    let items: any = {};
    let item: any = null;

    const colors = [
      "#FF8000", // orange
      "#116160", // green
      "#F9468C", // pink
      "#00BEB9", // cyan
      "#863E85", // purple
    ];

    if (instruments.instrument && instruments.instrument.information) {
      item = {
        canDelete: false,
        color: "#2a7090",
        index: 0,
        type: instruments.instrument.information["type"],
        value: instruments.instrument.information,
      };

      items[instruments.instrument.information["symbol"]] = item;
    }
    if (instruments.others) {
      for (let i = 0; i < instruments.others.length; i++) {
        var other = instruments.others[i];
        if (other != null && other.information != null) {
          item = {
            canDelete: true,
            color: colors[i],
            index: i + 1,
            type: instruments.instrument.information["type"],
            value: other.information,
          };
          items[other.information["symbol"]] = item;
        }
      }
    }

    return Object.values(items);
  }, [instruments.instrument, instruments.others]);

  const displayRatings = useMemo(() => {
    const account = environment.get("account");
    const preferences = account.user.preferences.preferences;

    const userPreferences = preferences?.["analysisSecurity"];
    return userPreferences["compare"]["lastRating"];
  }, [environment]);

  const dataDisplay = useCallback((state) => {
    setInstruments(deepClone(state));
  }, []);

  const dataPrepare = useCallback(
    (history, state: InstruementsState) => {
      state["instrument"] = {
        information: value,
        history: history,
      };

      dataDisplay(state);
    },
    [dataDisplay, value]
  );

  const dataGet = useCallback(async () => {
    var instrument = value;

    // Reset instruments data
    let statePrototype = {
      instrument: {
        information: null,
        history: null,
      },
      benchmark: {
        information: null,
        history: null,
      },
      others: [],
    };
    var params = {
      symbol: instrument["symbol"],
    };

    const response = await storage.instrumentHistory(params);
    dataPrepare(response, statePrototype);
  }, [dataPrepare, storage, value]);

  const focusSearch = useCallback(() => {
    const searchInputRef = searchRef?.current;

    if (searchInputRef) {
      searchInputRef.focus();
    }
  }, []);

  const onPageMount = useCallback(() => {
    dataGet();
  }, [dataGet]);

  const instrumentExists = useCallback(
    (instrument) => {
      // Check if instrument was previously selected.
      // Does ignore the benchmark value (not used in this case)
      if (
        instruments.instrument != null &&
        instruments.instrument.information != null &&
        instruments.instrument.information["symbol"] === instrument["symbol"]
      ) {
        return true;
      }
      if (instruments.others != null && instruments.others.length > 0) {
        for (let i = 0; i < instruments.others.length; i++) {
          var item = instruments.others[i];
          if (
            item != null &&
            item.information["symbol"] === instrument["symbol"]
          ) {
            return true;
          }
        }
      }
      return false;
    },
    [instruments.instrument, instruments.others]
  );

  const flash = useCallback((node) => {
    node.classList.add("flashing");

    setTimeout(() => {
      node.classList.remove("flashing");
    }, 2000);
  }, []);

  const flashItem = useCallback(
    (instrument) => {
      const nodesMap = itemNodes?.current;

      if (nodesMap && instrument in nodesMap) {
        const nodeToFlash = nodesMap?.[instrument]?.current;

        if (nodeToFlash) {
          flash(nodeToFlash);
        }
      }
    },
    [flash]
  );

  const dataPrepareSelected = useCallback(
    (response) => {
      const instrumentsCopy = deepClone(instruments);

      instrumentsCopy["others"].push({
        information: response["information"],
        history: response["history"],
      });

      dataDisplay(instrumentsCopy);
    },
    [dataDisplay, instruments]
  );

  const dataGetSelected = useCallback(
    async (instrument) => {
      if (!compareItems || compareItems.length >= 4) {
        return;
      }

      if (
        instrumentExists({
          symbol: instrument["symbol"],
        })
      ) {
        flashItem(instrument["symbol"]);
        return;
      }

      var params = {
        symbol: instrument["symbol"],
      };
      const response = await httpAll({
        information: storage.instrument(params),
        history: storage.instrumentHistory(params),
      });

      dataPrepareSelected(response);
    },
    [compareItems, dataPrepareSelected, flashItem, instrumentExists, storage]
  );

  const addCompareItem = useCallback(
    (instrument) => {
      dataGetSelected(instrument);
    },
    [dataGetSelected]
  );

  const listenerDelete = useCallback(
    (value) => {
      var instrument = value;
      if (instrument != null) {
        const instrumentsCopy = deepClone(instruments);
        for (let i = 0; i < instrumentsCopy.others.length; i++) {
          var other = instrumentsCopy.others[i];
          if (
            other.information != null &&
            other.information["symbol"] === instrument["symbol"]
          ) {
            instrumentsCopy.others.splice(i, 1);
          }
        }

        dataDisplay(instrumentsCopy);
      }
    },
    [dataDisplay, instruments]
  );

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  return (
    <ErrorBoundary fallback={<></>}>
      <Box className={styles.tabCompare__main}>
        <Box className={styles.tabCompare__main__editor__box}>
          <Card className={styles.tabCompare__main__editor__box__card}>
            <CardContent
              className={styles.tabCompare__main__editor__box__card__content}
            >
              <Search
                ref={searchRef}
                onSelectInstrument={addCompareItem}
                showInstrumentInfoOnSelect={false}
              />
              <Box
                className={
                  styles.tabCompare__main__editor__box__card__content__table__box
                }
              >
                <div
                  className="tSecurityAnalysisTabCompare-search"
                  data-dojo-attach-point="widgetSearch"
                  data-dojo-type="app/widgets/form/Search"
                ></div>
                <div
                  className="tSecurityAnalysisTabCompare-compare"
                  data-dojo-attach-point="nodeCompare"
                >
                  {instruments?.instrument?.information?.["type"] != null && (
                    <CompareItemLegend
                      type={instruments.instrument.information["type"]}
                    />
                  )}
                  {compareItems &&
                    compareItems.length &&
                    compareItems.map((item: any, index) => {
                      const nodeRef = registerRef(item.value.symbol);

                      return (
                        <CompareItem
                          onRemove={listenerDelete}
                          ref={nodeRef}
                          key={uuidv4()}
                          canDelete={item.canDelete}
                          color={item.color}
                          value={item.value}
                          index={item.index}
                          type={instruments.instrument.information["type"]}
                          isLast={index === compareItems.length - 1}
                        />
                      );
                    })}
                </div>
                {compareItems && compareItems.length < 4 && (
                  <AddCircleOutlineIcon
                    role={"button"}
                    onClick={focusSearch}
                    sx={{ color: "#2a7090", cursor: "pointer" }}
                  />
                )}
              </Box>
              <ul className="tSecurityAnalysisTabCompare-itemDetails">
                {compareItems != null && compareItems.length > 0 ? (
                  compareItems.map((item: any) =>
                    item.index !== 0 ? (
                      <li
                        key={uuidv4()}
                        className="tSecurityAnalysisTabCompare-itemDetail"
                      >
                        {item.index + " - " + item.value["name"]}
                      </li>
                    ) : (
                      <></>
                    )
                  )
                ) : (
                  <></>
                )}
              </ul>
            </CardContent>
          </Card>
        </Box>
        <Box className={styles.tabCompare__main__chart__box}>
          <Card className={styles.tabCompare__main__chart__box__card}>
            <CardContent
              className={styles.tabCompare__main__chart__box__card__content}
            >
              <Box width={"100%"} height={"100%"}>
                <TrendratingChart
                  value={curves}
                  enableRating={displayRatings}
                  defaultPeriod="3Y"
                  hasMeasure={true}
                  hasTooltip={false}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
