import { Properties } from "../../../api/Properties";
import { deepClone } from "../../../deepClone";
import { ObjectType } from "../../../trendrating/core/ObjectType";

/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-widgets/table/generator/ColumnSet
 * @summary Generates columns set for tables
 *
 */

type Params = {
  customConfiguration?: any[] | null;
  elements?: any;
  columns_available?: any[];
  columns?: any;
  properties: any;
};

export class ColumnSet extends ObjectType {
  /*
   * Classical workflow
   *  1 - generate a column set
   *  2 - tag a column set
   *  3 - group a column set by tag
   */

  defaultType: any = "security";
  properties: any = null;

  _columns: any = null;
  _columnsAvailable: any = null;
  _columnsCustom: any = null;
  _elements: any = null;

  tags = {
    tag_data_allocation: "Allocation",
    tag_data_classification: "Classification",
    tag_data_market: "Market data",
    tag_data_momentum: "Trend",
    tag_data_performance: "Performance",
    tag_data_others: "Others",
    tag_data_fundamentals: "Fundamental (Stocks only)",
  };

  /**
   * Instantiate a generator
   *
   * @constructor
   *
   * @param {object} params - parameters
   * @param {array} params.customConfiguration - user defined configuration
   * @param {object} params.elements - the elements from which generate configuration
   * //param {object} params.elements.columns_sort - specifies the default sort
   * //param {boolean} params.elements.columns_sort.descending - sort descending
   * //param {string} params.elements.columns_sort.property - sorting property
   * @param {array} params.elements.columns_available - the properties available to be visualized in the table
   * @param {array} params.elements.columns_custom - user defined properties
   * @param {object} params.elements.columns - the default columns for each type of security
   * @param {object} params.properties - security properties (fieldsConfiguration.json)
   */
  constructor(params: Params) {
    super();

    if (params !== undefined && params != null) {
      if (
        params.hasOwnProperty("elements") &&
        params["elements"] !== undefined &&
        params["elements"] != null
      ) {
        this._elements = params.elements;
        this._columns = params.elements["columns"];
        this._columnsAvailable = params.elements["columns_available"];
      }

      if (
        params.hasOwnProperty("properties") &&
        params["properties"] !== undefined &&
        params["properties"] != null
      ) {
        this.properties = params["properties"];
      }

      if (
        params.hasOwnProperty("customConfiguration") &&
        params["customConfiguration"] !== undefined &&
        params["customConfiguration"] != null
      ) {
        this._columnsCustom = params["customConfiguration"];
      }
    }
  }

  /**
   * Generate the configuration for the given security type
   *
   * @param {string} type - the type of security. Supported values are
   *   'security' | 'stock' | 'sector' | 'etf' | 'index' | 'currency' |
   *   'commodity'. Default 'security'
   */
  generate(type) {
    if (this._columns == null || this._columnsAvailable == null) {
      return [];
    }

    type = ColumnSet.typeMapper(type); // CoreObjectType
    const defaultType = this.defaultType;

    const columns = deepClone(this._columns);
    const columnsAvailable = deepClone(this._columnsAvailable);
    const columnsAvailableMap = this._createArrayMap(columnsAvailable);
    const columnsCustom = deepClone(this._columnsCustom);
    const columnsDefault =
      columns[type] !== undefined && columns[type] != null
        ? columns[type]
        : columns[defaultType];
    const columnsSelected =
      columnsCustom == null ? columnsDefault : columnsCustom;
    const labels = new Properties({
      properties: this.properties,
    });

    let column: any;
    let configuration: any = [];
    let label: any = null;
    let labelPanel: any = null;
    let property: any;
    let orderIndex: any = -1;

    for (let i = 0, length = columnsAvailable.length; i < length; i++) {
      column = columnsAvailable[i];
      property = column["field"];

      // if orderIndex !== -1, the column is selected and her order
      // is orderIndex
      orderIndex = columnsSelected.indexOf(property);

      label = labels.get(
        property,
        columnsAvailableMap[property]["headerIndex"],
        "auto"
      );
      labelPanel = label;

      if ("panelIndex" in columnsAvailableMap[property]) {
        labelPanel = labels.get(
          property,
          columnsAvailableMap[property]["panelIndex"],
          "auto"
        );
      }

      var isPointInTime = false;
      if (
        "isPointInTime" in columnsAvailableMap[property] &&
        columnsAvailableMap[property]["isPointInTime"] === true
      ) {
        isPointInTime = true;
      }

      var hasHistogram = false;
      if ("hasHistogram" in columnsAvailableMap[property]) {
        hasHistogram = true;
      }

      column["hasHistogram"] = hasHistogram;
      column["isPointInTime"] = isPointInTime;
      column["label"] = label;
      column["labelPanel"] = labelPanel;
      column["order"] = orderIndex !== -1 ? orderIndex : null;
      column["property"] = property;
      column["selected"] = orderIndex !== -1 ? true : false;
      column["sort"] = null;
      column["sortable"] = false;

      configuration.push(column);
    }

    return configuration;
  }

  /**
   * Group columnSet elements by tag name
   *
   * @param {array} taggedColumnSet - a columnSet generated using tagColumns
   */
  groupByTag(taggedColumnSet) {
    let column: any = null;
    let columnSet: any = taggedColumnSet;
    let groups: any = {};

    for (let i = 0, length = columnSet.length; i < length; i++) {
      column = {
        label: columnSet[i]["label"],
        labelPanel: columnSet[i]["labelPanel"],
        selected: columnSet[i]["selected"],
        tag: columnSet[i]["tag"],
        value: columnSet[i]["property"],
      };

      if (!(column["tag"] in groups)) {
        groups[column["tag"]] = [];
      }

      groups[column["tag"]].push(column);
    }

    const columnGroups = [
      this.tags["tag_data_market"],
      this.tags["tag_data_classification"],
      this.tags["tag_data_momentum"],
      this.tags["tag_data_performance"],
      this.tags["tag_data_allocation"],
      this.tags["tag_data_fundamentals"],
      this.tags["tag_data_others"],
    ];
    let columnGroup: any = null;
    let columns: any = [];

    for (let i = 0; i < columnGroups.length; i++) {
      columnGroup = columnGroups[i];
      if (groups[columnGroup] !== undefined) {
        column = {
          children: groups[columnGroup],
          label: columnGroup,
          value: null,
        };
        columns.push(column);
      }
    }

    return columns;
  }

  /**
   * Add tag info to the columnSet generated using generate
   *
   * @param {array} generatedColumns - a columnSet generated using generate
   * @param {object} properties - fields info: name, formatters, tags ...
   */
  tagColumns(generatedColumnSet, properties) {
    const apiTypes = ColumnSet.getApiTypes();
    let column: any = null;
    const columnSet = deepClone(generatedColumnSet);
    let field: any = null;
    let isDone = false;
    let j: number = 0;
    let securityType: any;

    for (let i = 0, lengthI = columnSet.length; i < lengthI; i++) {
      column = columnSet[i];

      // Ignore specific elements, like fundamentals in standard product
      if (column.property === "IGNORE") {
        continue;
      }

      isDone = false;
      j = 0;

      while (!isDone) {
        field = null;

        if (j >= apiTypes.length) {
          if (appConfig.isDebug) {
            console.error(
              "[ERROR] Error in application settings: check maybe for array inside another array"
            );
          }
          return columnSet;
        }

        securityType = apiTypes[j];
        // retrieve field info
        if (
          securityType in properties &&
          column["field"] in properties[securityType]
        ) {
          field = properties[securityType][column["field"]];
        } else if (column["field"] in properties) {
          field = properties[column["field"]];
        }

        j++;

        if (field == null) {
          continue;
        }

        // retrieve tags info
        if ("tags" in field) {
          column["tag"] = this.tags[field["tags"][0]];
        } else {
          column["tag"] = this.tags["tag_others"];
        }

        isDone = true;
      }
    }

    return columnSet;
  }

  set(property, value) {
    switch (property) {
      case "customConfiguration": {
        this._columnsCustom = value;
        break;
      }
      // no default
    }
  }

  /**
   * @ignore
   *
   * Create an object literal using field as key
   */
  _createArrayMap(arrayObject) {
    var arrayMap = {};
    var item;

    for (let i = 0, length = arrayObject.length; i < length; i++) {
      item = arrayObject[i];
      arrayMap[item["field"]] = item;
    }

    return arrayMap;
  }

  /**
   * @ignore
   *
   */
  _selectItems(selected, targetArray) {
    var column;
    var property;
    for (let i = 0, length = targetArray.length; i < length; i++) {
      column = targetArray[i];
      property = column["field"];
      if (selected.indexOf(property) !== -1) {
        column["selected"] = true;
      }
    }
  }
}
