import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { deepClone } from "../../../../../../../deepClone";
import { TDate } from "../../../../../../../trendrating/date/TDate";
import DatePicker from "../../../../../pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/BacktestingController/components/DatePicker";

type Props = {
  input: any;
  onChange: Function;
};

export default function Analytics({ input, onChange }: Props) {
  const [state, setState] = useState<any>(() => {
    if (input) {
      return {
        from: {
          type: input.date ? "date" : "inception",
          value: input.date,
        },
        to: {
          type: input.lastDate ? "date" : "lastAvailable",
          value: input.lastDate,
        },
      };
    }
    return {
      from: {
        type: "inception",
        value: null,
      },
      to: {
        type: "lastAvailable",
        value: null,
      },
    };
  });

  const tDate = useMemo(() => TDate, []);

  const setOutterState = useCallback(
    (tempState) => {
      onChange({ from: tempState.from.value, to: tempState.to.value });
    },
    [onChange]
  );

  const updateDateHandler = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      var days = tDate.dateToDays(date);
      return days;
    },
    [tDate]
  );

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent
        sx={{p:1, pb:"8px !important", display: "flex", flexDirection: "column" }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="tr_subtitle_blue">Analytics</Typography>
          <Box display={"flex"} gap={1}>
            <Box>
              <Typography>From:</Typography>
              <RadioGroup
                value={state.from.type}
                onChange={(e) => {
                  const type = e.target.value;
                  const temp = deepClone(state);
                  temp.from.type = type;
                  if (type === "inception") {
                    temp.from.value = null;
                    setOutterState(temp);
                  }
                  setState(temp);
                }}
              >
                <FormControlLabel
                  value={"inception"}
                  control={<Radio size="small" />}
                  label={<Typography>Since inception</Typography>}
                />
                <FormControlLabel
                  value={"date"}
                  control={<Radio size="small" />}
                  label={
                    <Box display={"flex"}>
                      {state.from.type !== "date" && (
                        <Typography>date</Typography>
                      )}
                      {state.from.type === "date" && (
                        <DatePicker
                          input={
                            state.from.value
                              ? tDate.daysToDate(state.from.value).toISOString()
                              : null
                          }
                          onChangeDate={(e) => {
                            let dd = updateDateHandler(e);
                            if (dd < tDate.today()) {
                              let temp = deepClone(state);
                              temp.from.value = dd;
                              setOutterState(temp);
                              setState(temp);
                            }
                          }}
                        />
                      )}
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>

            <Box>
              <Typography>To:</Typography>
              <RadioGroup
                value={state.to.type}
                onChange={(e) => {
                  const type = e.target.value;
                  const temp = deepClone(state);
                  temp.to.type = type;
                  if (type === "lastAvailable") {
                    temp.to.value = null;
                    setOutterState(temp);
                  }
                  setState(temp);
                }}
              >
                <FormControlLabel
                  value={"lastAvailable"}
                  control={<Radio size="small" />}
                  label={<Typography>Last available</Typography>}
                />
                <FormControlLabel
                  value={"date"}
                  control={<Radio size="small" />}
                  label={
                    <Box display={"flex"}>
                      {state.to.type !== "date" && (
                        <Typography>date</Typography>
                      )}
                      {state.to.type === "date" && (
                        <DatePicker
                          input={
                            state.to.value
                              ? tDate.daysToDate(state.to.value).toISOString()
                              : null
                          }
                          onChangeDate={(e) => {
                            let dd = updateDateHandler(e);
                            if (state.from.type === "date") {
                              if (state.from.value > dd) {
                                throw new Error("days > lastDays");
                              }
                            }
                            if (dd <= tDate.today()) {
                              let temp = deepClone(state);
                              temp.to.value = dd;
                              setOutterState(temp);
                              setState(temp);
                            }
                          }}
                        />
                      )}
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
