export type YearToDateAnalyticsType = {
  content: {
    benchmark: {
      content: string;
      isEnabled: boolean;
    };
    delta: {
      content: string;
      isEnabled: boolean;
    };
    headline: {
      content: string;
      isEnabled: boolean;
    };
    maxDrawdown: {
      content: string;
      isEnabled: boolean;
    };
    monthlyPerformance: {
      content: string;
      isEnabled: boolean;
    };
    monthlyStandardDeviation: {
      content: string;
      isEnabled: boolean;
    };
    strategy: {
      content: string;
      isEnabled: boolean;
    };
  };
  presentation: {
    table: string;
  };
};

export type ActionType = {
  type:
    | "SET_CONTENT_benchmark_CONTENT"
    | "SET_CONTENT_benchmark_ISENABLED"
    | "SET_CONTENT_delta_CONTENT"
    | "SET_CONTENT_delta_ISENABLED"
    | "SET_CONTENT_headline_CONTENT"
    | "SET_CONTENT_headline_ISENABLED"
    | "SET_CONTENT_maxDrawdown_CONTENT"
    | "SET_CONTENT_maxDrawdown_ISENABLED"
    | "SET_CONTENT_monthlyPerformance_CONTENT"
    | "SET_CONTENT_monthlyPerformance_ISENABLED"
    | "SET_CONTENT_monthlyStandardDeviation_CONTENT"
    | "SET_CONTENT_monthlyStandardDeviation_ISENABLED"
    | "SET_CONTENT_strategy_CONTENT"
    | "SET_CONTENT_strategy_ISENABLED"
    | "SET_PRESENTATION_TABLE";
  payload: any;
};

export const yearToDateAnalyticsReducer = (draft: YearToDateAnalyticsType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_benchmark_CONTENT":
      draft.content.benchmark.content = action.payload;
      break;
    case "SET_CONTENT_benchmark_ISENABLED":
      draft.content.benchmark.isEnabled = action.payload;
      break;
    case "SET_CONTENT_delta_CONTENT":
      draft.content.delta.content = action.payload;
      break;
    case "SET_CONTENT_delta_ISENABLED":
      draft.content.delta.isEnabled = action.payload;
      break;
    case "SET_CONTENT_headline_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_headline_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_maxDrawdown_CONTENT":
      draft.content.maxDrawdown.content = action.payload;
      break;
    case "SET_CONTENT_maxDrawdown_ISENABLED":
      draft.content.maxDrawdown.isEnabled = action.payload;
      break;
    case "SET_CONTENT_monthlyPerformance_CONTENT":
      draft.content.monthlyPerformance.content = action.payload;
      break;
    case "SET_CONTENT_monthlyPerformance_ISENABLED":
      draft.content.monthlyPerformance.isEnabled = action.payload;
      break;
    case "SET_CONTENT_monthlyStandardDeviation_CONTENT":
      draft.content.monthlyStandardDeviation.content = action.payload;
      break;
    case "SET_CONTENT_monthlyStandardDeviation_ISENABLED":
      draft.content.monthlyStandardDeviation.isEnabled = action.payload;
      break;
    case "SET_CONTENT_strategy_CONTENT":
      draft.content.strategy.content = action.payload;
      break;
    case "SET_CONTENT_strategy_ISENABLED":
      draft.content.strategy.isEnabled = action.payload;
      break;
    case "SET_PRESENTATION_TABLE":
      draft.presentation.table = action.payload;
      break;
    default:
      break;
  }
};
