import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InputNumber from "../../../../../../../../../components/InputNumber/InputNumber";
import { WidgetsOptions } from "../../fields/WidgetsOptions";

type Props = {
  dispatcher: Function;
  peerLevel: string | null;
  weightCappedMin: any;
  weightCappedMax: any;
  weightCappedMethod: string | null;
  weightCappingPeer: any;
  isEtfUniverse: boolean;
};

export default function Capping({
  weightCappedMethod,
  weightCappedMax,
  weightCappedMin,
  peerLevel,
  weightCappingPeer,
  dispatcher,
  isEtfUniverse,
}: Props) {
  const inputExists = useMemo(() => {
    if (weightCappingPeer != null) {
      return true;
    }
    return false;
  }, [weightCappingPeer]);
  const [isSectionActive, setIsSectionActive] = useState(false);
  useEffect(() => {
    setIsSectionActive(inputExists);
  }, [inputExists]);
  const { t } = useTranslation();
  const capOptions = useMemo(() => {
    const options = new WidgetsOptions();
    const key = isEtfUniverse ? "OPTIONS_ETF" : "OPTIONS";
    return options.getWeightCappingPeerLevel(key, t);
  }, [isEtfUniverse, t]);

  const switchHandler = (e) => {
    setIsSectionActive(e.target.checked);
    if (e.target.checked === false) {
      dispatcher({ type: "SET_CAPPING_PEER_TO_NULL" });
    }
    if (e.target.checked === true && inputExists === false) {
      dispatcher({
        type: "INIT_CAPPING_PEER",
        payload: {
          weightCappedMax,
          weightCappedMin,
          peerLevel,
          weightCappedMethod,
        },
      });
    }
  };
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          gap={2}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              sx={{ width: "max-content", gap: 1 }}
              control={
                <Switch
                  size="small"
                  checked={isSectionActive}
                  onChange={(e) => switchHandler(e)}
                />
              }
              label={
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <CappingDropdown
                    key={peerLevel}
                    dispatch={(value) =>
                      dispatcher({
                        type: "SET_CAPPING_PEER_PEERLEVEL",
                        payload: value,
                      })
                    }
                    value={peerLevel}
                    options={capOptions}
                    isDisabled={!isSectionActive}
                  />
                  <Typography>Capping</Typography>
                </Box>
              }
            />
          </span>

          <Box
            style={{ display: "flex", alignItems: "center" }}
            sx={{ gap: 1 }}
          >
            <Typography>Max</Typography>
            <InputNumber
              maxWidth={100}
              disableGutters
              initVal={
                weightCappedMax != null
                  ? weightCappedMax
                  : inputExists
                  ? null
                  : 0.2
              }
              isDisabled={!isSectionActive}
              isPercentage={true}
              setOuterState={(val) => {
                dispatcher({ type: "SET_CAPPING_PEER_MAX", payload: val });
              }}
              isFloating={true}
            />
          </Box>

          <span style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              disabled={!isSectionActive}
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      dispatcher({
                        type: "SET_CAPPING_PEER_CAPPEDMETHOD",
                        payload: "SUBSTITUTION",
                      });
                    } else {
                      dispatcher({
                        type: "SET_CAPPING_PEER_CAPPEDMETHOD",
                        payload: null,
                      });
                    }
                  }}
                  checked={weightCappedMethod != null ? true : false}
                  size="small"
                />
              }
              label="Switch constituents instead of reducing weights "
            />
          </span>
        </Box>
      </CardContent>
    </Card>
  );
}

type CappingDropdownProps = {
  options: any;
  isDisabled: boolean;
  value: any;
  dispatch: Function;
};
const CappingDropdown = ({
  options,
  isDisabled,
  value,
  dispatch,
}: CappingDropdownProps) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  useEffect(() => {
    if (state != null && isDisabled === false) {
      dispatch(state);
    }
  }, [dispatch, isDisabled, state]);

  return (
    <Select
      disabled={isDisabled}
      size="small"
      value={value ?? options[0].value}
      onChange={(e) => {
        setState(e.target.value);
      }}
    >
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Typography> {item.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
