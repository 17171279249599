import { useFormatter } from "../../../../../hooks/useFormatter";
import { useProperties } from "../../../../../hooks/useProperties";

type TabOverviewTrendProps = {
    value: any;
};

export function TabOverviewTrend({ value }: TabOverviewTrendProps) {
    const format = useFormatter();
    const properties = useProperties();

    if (properties == null || value == null) {
        return <>Loading...</>;
    }

    if (
        value.type !== "Stock" &&
        value.type !== "ETF" &&
        value.type !== "Index" &&
        value.type !== "Sector"
    ) {
        return <></>; // Unsupported type
    }

    return (
        <div className="tTable-wrap tTable-wrap--border">
            <h2 className="tTable-title">Trend</h2>
            <table className="tTable">
                <tbody>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("rc", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "rc",
                                        "rating",
                                        value.rc,
                                        {
                                            rateChange: value.lr,
                                            rateDate: value.dr,
                                            ratePrev: value.rrr,
                                        },
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("mc", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "mc",
                                        "smart_momentum",
                                        value.mc,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("px", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "px",
                                        "retracement",
                                        value.px,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("dr", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "dr",
                                        "rated_on_human",
                                        value.dr,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("lr", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "lr",
                                        "days_from_current_rating",
                                        value.lr,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {value.lr == null
                                ? "Max of trend"
                                : value.rc >= 0
                                ? "Max of trend"
                                : "Min of trend"}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        value.lr == null
                                            ? "-"
                                            : format.custom("price", {
                                                  options: {
                                                      notAvailable: {
                                                          input: null,
                                                          output: "",
                                                      },
                                                  },
                                                  output: "HTML",
                                                  value:
                                                      value.vc /
                                                      (value.px + 1.0),
                                                  valueHelper: {
                                                      currency: value.currency,
                                                      date: null,
                                                  },
                                              }),
                                }}
                            ></div>
                        </td>
                    </tr>
                    <tr>
                        <th className="tTable-name">
                            {properties.get("ts", 0, value.type)}
                        </th>
                        <td className="tTable-value">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: format.html(
                                        "ts",
                                        "ts",
                                        value.ts,
                                        null,
                                        value.type
                                    ),
                                }}
                            ></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
