import { Box, Chip, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import Search from "../../../widgets/ReactSearch/Search";

type InstrumentSelectionProps = {
  setInstrument: Function;
  hasIndicator?: boolean;
};

export const InstrumentSelection = forwardRef(
  ({ setInstrument, hasIndicator = false }: InstrumentSelectionProps, ref) => {
    const [benchmark, setBenchmark] = useState<null | string>(null);


    return (
      <Box display={"flex"} gap={2} alignItems="center">
        <Typography sx={{ fontSize: "12px !important" }}>Benchmark</Typography>
        {benchmark == null ? (
            <Search
              showInstrumentInfoOnSelect={false}
              hasRecents={false}
              onSelectInstrument={(e) => {
                setInstrument(e);
                if (e != null) {
                  setBenchmark(e.name);
                } else {
                  setBenchmark(null);
                }
              }}
            />
        
        ) : null}

        {benchmark ? (
          <>
            <Chip
              sx={{ boxShadow: 3 }}
              label={
                <Typography sx={{ fontSize: "12px !important" }}>
                  {benchmark}
                </Typography>
              }
              variant="outlined"
              onDelete={() => {
                setInstrument(null);
                //setting benchmark to null to hide chip
                setBenchmark(null);
              }}
            />

            {hasIndicator && (
              <Typography
                sx={{ fontSize: "12px !important" }}
                ref={ref as any}
              ></Typography>
            )}
          </>
        ) : null}
      </Box>
    );
  }
);
