import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../../components/Modal/Modal";
import { deepClone } from "../../../../../deepClone";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import styles from "./ReportsHub.module.scss";
import { useNavigate } from "react-router-dom";

type ReportRankDialogProps = {
  getRanks: () => any;
  getLists: (value: "portfolio" | "basket") => any;
  closeModal: () => void;
};

export function ReportRankDialog({
  getRanks,
  getLists,
  closeModal,
}: ReportRankDialogProps) {
  const [ranks, setRanks] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [baskets, setbaskets] = useState([]);
  const [isLoadingCollections, setIsLoadingCollections] = useState(true);

  const hasPortfolios = useMemo(
    () => portfolios.length > 0,
    [portfolios.length]
  );
  const hasBaskets = useMemo(() => baskets.length > 0, [baskets.length]);
  const hasRanks = useMemo(() => {
    // Removed because generates confusion
    // ranks.length > 0

    // The variable still exists and return false to hide ranking panel
    return false;
  }, []);
  const hasNoCollection = useMemo(
    () => !hasBaskets && !hasPortfolios && !hasRanks,
    [hasBaskets, hasPortfolios, hasRanks]
  );
  const hasFinishLoading = useMemo(
    () => isLoadingCollections === false,
    [isLoadingCollections]
  );

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const pageConfiguration = useMemo(
    () => appSetup["configuration"].get("analysisList"),
    [appSetup]
  );
  const configurationWidgetRanking = useMemo(
    () =>
      pageConfiguration["tabs"][pageConfiguration["tabsIndex"]["ranking"]][
        "widgets"
      ]["ranking"],
    [pageConfiguration]
  );
  const templates = useMemo(() => {
    const defaultTemplateObject = {
      configuration: {
        ranking: deepClone(
          configurationWidgetRanking["edit"]["defaultTemplate"]
        ),
      },
      name: "Default Ranking",
      id: null,
    };

    let presetTemplates = [
      ...configurationWidgetRanking["edit"]["presetTemplates"],
      { ...defaultTemplateObject },
    ];

    return presetTemplates;
  }, [configurationWidgetRanking]);

  const taggedTemplates = useMemo(() => {
    return templates.filter((template) => "reports_hub_label" in template);
  }, [templates]);

  const onPanelOpen = useCallback(async () => {
    setIsLoadingCollections(true);
    const availableRanks = await getRanks();
    let portfolios = await getLists("portfolio");
    let baskets = await getLists("basket");

    portfolios = portfolios.filter((item) => item.type === "Portfolios");
    baskets = baskets.filter((item) => item.type === "Baskets");

    const sortCallback = (a, b) => {
      if (a.isSubscribed === b.isSubscribed) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }

      return a.isSubscribed > b.isSubscribed ? -1 : 1;
    };

    portfolios.sort(sortCallback);
    baskets.sort(sortCallback);
    availableRanks.sort(sortCallback);

    setRanks(availableRanks);
    setPortfolios(portfolios);
    setbaskets(baskets);
    setIsLoadingCollections(false);
  }, [getLists, getRanks]);

  useEffect(() => {
    onPanelOpen();
  }, [onPanelOpen]);

  return (
    <Modal
      closeIcon={false}
      headerConfig={{ hasBackground: true, headerContent: "Ranking" }}
      customCss={{ maxWidth: "98vw", width: "max-content" }}
      onClose={closeModal}
      closeOnClickAway
    >
      <Box className={styles.rankDialog__content}>
        {hasNoCollection && hasFinishLoading && <EmptyCollectionFallBack />}
        {hasRanks && (
          <CardList
            listType="rank"
            directClick
            list={ranks}
            title={"Your ranks"}
          />
        )}
        {hasPortfolios && (
          <CardList listType="portfolio" list={portfolios} title="Portfolios">
            <ButtonBox availableTemplates={taggedTemplates} />
          </CardList>
        )}
        {hasBaskets && (
          <CardList listType="basket" list={baskets} title="Baskets">
            <ButtonBox availableTemplates={taggedTemplates} />
          </CardList>
        )}
      </Box>
    </Modal>
  );
}

const ButtonBox = ({
  availableTemplates,
  id,
}: {
  availableTemplates: any[];
  id?: number;
}) => {
  const prepareString = useCallback(
    (theme) => {
      window.open(
        `/app/pdf/collection/rankThemed/${id}/${theme}`,
        "_blank",
        "rel=noopener noreferrer"
      );
    },
    [id]
  );

  return (
    <Box className={styles.buttonBox}>
      {availableTemplates.map((template) => (
        <Typography
          onClick={() => prepareString(template["reports_hub_label"])}
          key={uuidv4()}
          className={styles.btn}
        >
          {template["reports_hub_label"]}
        </Typography>
      ))}
    </Box>
  );
};

const EmptyCollectionFallBack = () => {
  const navigate = useNavigate();

  const goToBasketCreation = useCallback(() => {
    navigate("/app/analysis/lists/create/basket/");
  }, [navigate]);
  const goToPortfolioCreation = useCallback(() => {
    navigate("/app/analysis/lists/create/portfolio/");
  }, [navigate]);
  const goToRankCreation = useCallback(() => {
    navigate("/app/rank");
  }, [navigate]);

  return (
    <Box p={2} display={"flex"} flexDirection={"column"} gap={2}>
      <Typography>
        There aren't any baskets portfolios or ranks, please create or subscribe
        at least one to print a report
      </Typography>
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button onClick={goToBasketCreation}>Create Basket</Button>
        <Button onClick={goToPortfolioCreation}>Create Portfolio</Button>
        <Button onClick={goToRankCreation}>Create Rank</Button>
      </Box>
    </Box>
  );
};

const CardList = ({
  list,
  children,
  title,
  directClick = false,
  listType,
}: {
  list: any[];
  listType: "rank" | "portfolio" | "basket";
  children?: JSX.Element | JSX.Element[];
  title: string;
  directClick?: boolean;
}) => {
  const handleDirectClick = useCallback(
    (event, rankId) => {
      if (directClick === true) {
        window.open(
          `/app/pdf/collection/rank/${rankId}`,
          "_blank",
          "rel=noopener noreferrer"
        );

        event.stopPropagation();
      }
    },
    [directClick]
  );

  return (
    <Card className={styles.rankDialog__content__card}>
      <CardContent className={styles.rankDialog__content__card__content}>
        <table className={styles.rankDialog__content__card__content__table}>
          <thead>
            <tr
              className={styles.rankDialog__content__card__content__table__row}
            >
              <th
                className={
                  directClick === false
                    ? styles.rankDialog__content__card__content__table__row__head
                    : styles.rankDialog__content__card__content__table__row__head__fullWidht
                }
              >
                {title}
              </th>
              {directClick === false ? (
                <th
                  style={{ textAlign: "center" }}
                  className={
                    styles.rankDialog__content__card__content__table__row__head
                  }
                ></th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {list.map((item) => {
              const childrenWithProps = React.Children.map(
                children,
                (child) => {
                  // Checking isValidElement is the safe way and avoids a
                  // typescript error too.
                  if (React.isValidElement(child)) {
                    return React.cloneElement<any>(child, { id: item.id });
                  }
                  return child;
                }
              );

              return (
                <tr
                  onClick={(event) => handleDirectClick(event, item.id)}
                  title={item.name}
                  key={uuidv4()}
                  className={
                    styles.rankDialog__content__card__content__table__row
                  }
                >
                  <td
                    data-text={item.name}
                    className={`
                      ${styles.rankDialog__content__card__content__table__row__cell}
                      ${styles.rankDialog__content__card__content__table__row__cell__name}`}
                  >
                    <span
                      className={`${
                        directClick
                          ? styles.rankDialog__content__card__content__list__item__voice__directClick
                          : styles.rankDialog__content__card__content__list__item__voice
                      }`}
                    >
                      {item.isSubscribed && (
                        <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                      )}{" "}
                      {item.name}
                    </span>
                  </td>
                  <td
                    className={
                      styles.rankDialog__content__card__content__table__row__cell
                    }
                  >
                    {listType !== "rank" && (
                      <Box className={styles.floatingButtons}>
                        <Typography
                          sx={{ color: "#2a7090", marginBottom: "8px" }}
                        >
                          RANK {listType === "basket" ? "BASKET" : "PORTFOLIO"}{" "}
                          BY:
                        </Typography>
                        {childrenWithProps}
                      </Box>
                    )}
                    {childrenWithProps}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};
