import { widgetsConfiguration } from "../widgetsConfiguration";

export const getInstrumentData = async (apiInstrument, symbols) => {
  const properties = {
    properties: [
      {
        date: null,
        property: "type",
      },
    ],
    symbols: symbols,
    type: "security",
  };
  const response = await apiInstrument.fetch(properties);
  const propertiesByType = getSecurityProperiesByType(response);
  return await apiInstrument.fetch({
    properties: propertiesByType,
    symbols: symbols,
    type: "security",
  });
};
export const getSecurityProperiesByType = (response) => {
  const config = widgetsConfiguration["widgets/security/Tooltip"];
  if (response.data.length === 1) {
    const securityType =
      response?.["data"]?.[0]?.["type"]?.toLowerCase() ?? null;
    let properties;
    if (securityType) {
      switch (securityType) {
        case "etf":
        case "stock": {
          properties = config?.["properties_" + securityType];
          break;
        }
        default: {
          properties = config?.["properties"];
        }
      }
      return properties;
    }
  } else if (response.data.length > 1) {
    return config?.["properties"];
  }
  return null;
};

export const getLabel = (item, type, taxonomyMarket, taxonomySector) => {
  let label = "";

  switch (type) {
    case "PEER": {
      const market =
        item.where && item.where !== "WWW"
          ? taxonomyMarket.find((node) => node.id === item.where)
          : null;
      let marketParent: any = null;
      if (market && market.type === "Region") {
        marketParent = taxonomyMarket.find((node) => node.id === market.parent);
      }
      const sector =
        item.what && item.what !== "ICB"
          ? taxonomySector.find((node) => node.id === item.what)
          : null;
      if (market && sector) {
        if (marketParent) {
          label = `${marketParent.name}:${market.name} - ${sector.name}`;
        } else {
          label = `${market.name} - ${sector.name}`;
        }
      } else if (market) {
        if (marketParent) {
          label = marketParent.name + " - " + market.name;
        } else {
          label = market.name;
        }
      } else if (sector) {
        label = sector.name;
      }
      break;
    }
    case "SECURITY": {
      label = `${item.ticker} - ${item.name}`;
      break;
    } // no default
  }

  return label;
};
