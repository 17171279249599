import { Box, Button, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { Environment } from "../../Environment";
import { useInput } from "../../hooks/useInput";
import { config } from "../../js/app/config-ts";
import Modal from "../Modal/Modal";
import styles from "./CreateListDialog.module.scss";
import { newGetSymbols } from "./SecurityTooltipCore";
import { useBroadcast } from "../../hooks/useBroadcast";

type CreateListDialogProps = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  security: any;
  environment: Environment;
  type: "BASKET" | "PORTFOLIO";
  isForTippy?: boolean; //prop added to adapt component for popper
  onCreate?: Function; //prop added to adapt component for popper
  onCancel?: Function; //prop added to adapt component for popper
};

const { form, footer, content } = styles;

const createAndAdd = async (
  environment,
  securities,
  collection,
  onSuccess,
  onError
) => {
  const apiList = environment.get("http")["lists"];
  collection.positions = newGetSymbols(securities, collection.type, false);
  const response = await apiList.create(collection);
  if (response.status === "OK") {
    collection["id"] = response["data"]["id"];
    collection["isReadOnly"] = false;

    window.App.user.data.collections.invalidate();

    onSuccess(collection);
  } else {
    onError(collection);
  }
};

const successHandler = (collection, broadcast) => {
  var message = {
    from: "Routes",
    content: {
      type: "success",
      text:
        "The " +
        collection.type.toLowerCase() +
        " <strong>" +
        collection.name +
        "</strong> has been saved.",
    },
  };

  broadcast(config["channels"]["feedback"]["input"], message);

  // ********************* USAGE *********************
  var usage = window.App.usage;
  var info = {
    action: "ADD_TO",
    actionParam: collection["id"],
    function: "MESSAGE",
  };
  usage.record(info);
  // ********************* USAGE *********************
};

const errorHandler = (collection, broadcast) => {
  var message = {
    from: "Routes",
    content: {
      type: "error",
      text:
        "A " +
        collection.type.toLowerCase() +
        " called " +
        collection.name +
        " already exists.",
    },
  };

  broadcast(config["channels"]["feedback"]["input"], message);
};

export default function CreateListDialog({
  showModal,
  setShowModal,
  security,
  environment,
  type,
  isForTippy = false,
  onCreate,
  onCancel,
}: CreateListDialogProps) {
  const nameField = useInput("");
  //To avoid problems with async use callbback that cause an update on unmounted components
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const [textFieldValue, setTextfieldValue] = useState("");
  const { broadcast } = useBroadcast();

  const onSubmitHandler = useCallback(
    async (e) => {
      e.preventDefault();

      const basketName = isForTippy ? textFieldValue : nameField.value;
      const collection = {
        name: basketName,
        type: type,
      };

      // While waiting for yourAsyncFunction to be done,
      // It's possible that your component had been unmounted.

      // Therefore, you have to check if the component is still mounted before updating states
      if (mounted.current) {
        await createAndAdd(
          environment,
          Array.isArray(security) ? security : [security],
          collection,
          (data) => successHandler(data, broadcast),
          (data) => errorHandler(data, broadcast)
        );
      }

      if (!isForTippy) {
        setShowModal(false);
      }
      if (onCreate) {
        onCreate();
        setTextfieldValue("");
      }
    },
    [
      broadcast,
      environment,
      isForTippy,
      nameField.value,
      onCreate,
      security,
      setShowModal,
      textFieldValue,
      type,
    ]
  );

  return !isForTippy ? (
    <>
      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          hasOverlay={true}
          headerConfig={{
            headerContent: `Create a new ${
              type === "BASKET" ? "Basket" : "Portfolio"
            }`,
          }}
          customCss={{ width: "30%" }}
          closeIcon={false}
        >
          <div className={content}>
            <form className={form} onSubmit={(e) => onSubmitHandler(e)}>
              <label htmlFor="basket_create">Name</label>
              <div className={styles["form__input-wrapper"]}>
                <input autoFocus={true} id="basket_create" {...nameField} />
                <Button
                  disabled={nameField.value.length <= 0}
                  variant="contained"
                  type="submit"
                >
                  Create
                </Button>
              </div>
            </form>
          </div>
          <div className={footer}>
            <Button
              variant="tr_button_cancel"
              onClick={() => {
                setShowModal(false);
              }}
            >
              cancel
            </Button>
          </div>
        </Modal>
      )}
    </>
  ) : (
    <Box display={"flex"} flexDirection={"column"} gap={1} p={1}>
      <Typography textAlign={"left"}>
        {`Create a new ${type === "BASKET" ? "Basket" : "Portfolio"}`}
      </Typography>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <Typography>Name:</Typography>
        <TextField
          onChange={(e) => setTextfieldValue(e.target.value)}
          autoFocus
          size="small"
        />
      </Box>
      <Box display={"flex"} gap={1} justifyContent={"end"}>
        <Button
          variant="tr_button_cancel"
          onClick={() => {
            if (onCancel) {
              onCancel();
              setTextfieldValue("");
            }
          }}
        >
          cancel
        </Button>
        <Button
          disabled={textFieldValue.length <= 0}
          onClick={onSubmitHandler}
          variant="contained"
        >
          Create
        </Button>
      </Box>
    </Box>
  );
}
