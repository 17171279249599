/* eslint-disable no-template-curly-in-string */
export const defaultTemplateReportPortfolioRiskControl = [
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: false,
            },
            marketAllocation: "MARKET",
            marketRows: 8,
            sectorAllocation: "INDUSTRY",
            sectorRows: 8,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: true,
            marketAllocation: true,
            sectorAllocation: true,
          },
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW",
          content: {
            headline: {
              content: "New highs / New lows",
              isEnabled: true,
            },
            numberOfItems: 5,
          },
          presentation: {
            newHigh: true,
            newLow: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_PAGE_BREAK",
          content: null,
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "A&B Top performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "POSITIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_PERFORMER",
          content: {
            headline: {
              content: "C&D Bottom performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "NEGATIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
        },
        {
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_SPACING",
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "marketcap",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
          },
          presentation: {
            columns: [
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Market",
                property: "country",
              },
              {
                label: "Sector",
                property: "industry",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated On",
                property: "dr",
              },
              {
                label: "P/E Ratio",
                property: "f_pe",
              },
              {
                label: "12M Earnings Growth",
                property: "f_eps_CC_g_12",
              },
            ],
            rank: false,
            useWysiwyg: false,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: true,
    name: "Summary Report",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: false,
            },
            marketAllocation: "REGION",
            marketRows: 6,
            sectorAllocation: "INDUSTRY",
            sectorRows: 6,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: true,
            marketAllocation: true,
            sectorAllocation: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_ALLOCATION",
          content: {
            firstRows: 8,
            firstType: "MARKET",
            headline: {
              content: "",
              isEnabled: false,
            },
            secondRows: 8,
            secondType: "SECTOR",
          },
          presentation: {
            colorByPosition: false,
            first: true,
            second: true,
          },
          sections: null,
        },
        {
          content: {
            headline: {
              content: "C&D Bottom performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "NEGATIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
          type: "REPORT_PORTFOLIO_PERFORMER",
        },
        {
          content: null,
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_PAGE_BREAK",
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "rc",
              descending: false,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
            rate: {
              A: false,
              B: false,
              C: true,
              D: true,
            },
          },
          presentation: {
            columns: [
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Market Capitalization",
                property: "marketcap",
              },
              {
                label: "Market",
                property: "country",
              },
              {
                label: "Sector",
                property: "industry",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "P/E Ratio",
                property: "f_pe",
              },
              {
                label: "12M Earnings Growth",
                property: "f_eps_CC_g_12",
              },
            ],
            rank: false,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: true,
    name: "Risk Control",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_PORTFOLIO_TCR",
          content: {
            headline: {
              content: "TCR",
              isEnabled: false,
            },
            marketAllocation: "AREA",
            marketRows: 6,
            sectorAllocation: "ASSET_CLASS",
            sectorRows: 6,
          },
          presentation: {
            portfolioMomentumRating: true,
            ratingWeight: true,
            alert: true,
            marketAllocation: true,
            sectorAllocation: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_ALLOCATION",
          content: {
            firstRows: 8,
            firstType: "INV_REGION",
            headline: {
              content: "",
              isEnabled: false,
            },
            secondRows: 8,
            secondType: "SPECIALTY",
          },
          presentation: {
            colorByPosition: false,
            first: true,
            second: true,
          },
          sections: null,
        },
        {
          content: {
            headline: {
              content: "C&D Bottom performers",
              isEnabled: true,
            },
            numberOfItems: 5,
            type: "NEGATIVE",
          },
          presentation: {
            "05_days": true,
            "20_days": true,
            "60_days": true,
          },
          sections: null,
          type: "REPORT_PORTFOLIO_PERFORMER",
        },
        {
          content: null,
          presentation: null,
          sections: null,
          type: "REPORT_COMMON_PAGE_BREAK",
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "rc",
              descending: false,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
            rate: {
              A: false,
              B: false,
              C: true,
              D: true,
            },
          },
          presentation: {
            columns: [
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Market Capitalization",
                property: "marketcap",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Asset Class (ETF)",
                property: "etfclass",
              },
              {
                label: "Inv. Region (ETF)",
                property: "etfgeo",
              },
              {
                label: "Last 6 Months",
                property: "ps",
              },
              {
                label: "Last Month",
                property: "pm",
              },
            ],
            rank: false,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme1",
    },
    id: null,
    isEditable: true,
    name: "Risk Control - ETF",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
  {
    configuration: {
      integration: {
        peer: null,
        portfolioAnalysis: null,
        screening: null,
        strategy: {
          date: null,
        },
        systematicProduct: null,
      },
      orientation: "landscape",
      headerConfig: {
        logo: "small",
        date: true,
      },
      sections: [
        {
          type: "REPORT_COMMON_TITLE",
          content: {
            text: "${name}",
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_SECURITY_TABLE",
          content: {
            headline: {
              content: "Holdings",
              isEnabled: true,
            },
            sortBy: {
              property: "weight",
              descending: true,
            },
            top: {
              content: 5,
              isEnabled: false,
            },
            rate: {},
          },
          presentation: {
            columns: [
              {
                label: "Weight",
                property: "weight",
              },
              {
                label: "Ticker",
                property: "ticker",
              },
              {
                label: "Name",
                property: "name",
              },
              {
                label: "Price",
                property: "vc",
              },
              {
                label: "Rating",
                property: "rc",
              },
              {
                label: "Rated on",
                property: "dr",
              },
              {
                label: "Smart Momentum",
                property: "mc",
              },
              {
                label: "Retracement",
                property: "px",
              },
              {
                label: "Duration",
                property: "duration",
              },
              {
                label: "Magnitude",
                property: "magnitude",
              },
              {
                label: "UPI",
                property: "upi",
              },
              {
                label: "Market cap",
                property: "marketcap",
              },
              {
                label: "Sector",
                property: "industry",
              },
            ],
            rank: false,
            useWysiwyg: true,
          },
          sections: null,
        },
        {
          type: "REPORT_COMMON_SPACING",
          content: {
            lines: 1,
          },
          presentation: null,
          sections: null,
        },
        {
          type: "REPORT_COMMON_DISCLAIMER",
          content: null,
          presentation: null,
          sections: null,
        },
      ],
      theme: "theme2",
    },
    id: null,
    isEditable: true,
    name: "Holding - Print Current View",
    ownerId: null,
    type: "TEMPLATE_REPORT_PORTFOLIO",
    version: "1.0",
  },
];
