import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../../../../../ErrorBoundary";
import { Lists } from "../../../../../../api/compute/Lists";
import { ListConstraint } from "../../../../../../components/ListContraint/ListConstraint";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { MarketCapFilter } from "../../../screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import ScreenerETF from "../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerETF";
import ScreenerStock from "../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerStock";
import { ActionRankContext } from "../../actions/ActionRankContext/ActionRankContext";
import { SectionWithTitle } from "../TemplateRow/TemplateRow";
import styles from "./RankUniverseSelection.module.scss";

type RankUniverseSelectionProps = {
  isUsedAsOptional?: boolean;
};

const marketCapSelectValue = [
  { value: 0, label: "Highest Mkt. Cap." },
  { value: 1, label: "Lowest Mkt. Cap." },
];

const selectEncoder = (constraints) => {
  const target: any = {
    instrumentType: "stock",
    what: [],
    whereSource: {
      domestic: false,
      foreign: false,
      market: [],
      stockClassification: [],
    },
    whereTarget: {
      domestic: false,
      foreign: false,
      market: [],
      stockClassification: [],
    },
    subtype: [],
    targetList: null,
    marketCapRange: null,
    eligibilityRules: {
      cardinality:
        "justInTimeTops" in constraints
          ? constraints["justInTimeTops"][0]["n"]
          : 200,
      sortBy:
        "justInTimeTops" in constraints
          ? constraints?.["justInTimeTops"]?.[0]?.["rev"] === true
            ? "desc"
            : "asc"
          : "desc",
    },
  };

  if (!constraints) {
    return target;
  }

  for (const filter of constraints?.filters) {
    switch (filter.dimension) {
      case "etfgeo":
        target.whereTarget.market = filter.segments;
        break;
      case "country":
        target.whereSource.market = filter.segments;
        break;
      case "icb":
      case "etfclass":
        target.what = filter.segments;
        break;
      case "subtype":
        const subtype: any = [];

        for (const segment of filter.segments) {
          if (segment === "Domestic Stock") {
            target.whereSource.domestic = true;
          } else if (segment === "Foreign Stock") {
            target.whereSource.foreign = true;
          } else {
            subtype.push(segment);
          }
        }

        target.subtype = subtype;

        break;
      case "stockclass":
        target.whereSource.stockClassification = filter.segments;

        break;
      case "type":
        const type = filter?.["segments"]?.[0];

        if (type != null && type === "ETF") {
          target.instrumentType = "etf";
        }
        break;
    }
  }

  if ("ranges" in constraints) {
    for (const range of constraints.ranges) {
      if (range.dimension === "marketcap") {
        target.marketCapRange = {
          le:
            range?.segments?.[0]?.max != null
              ? range?.segments?.[0]?.max / 1e6
              : null,
          ge:
            range?.segments?.[0]?.min != null
              ? range?.segments?.[0]?.min / 1e6
              : null,
        };

        break;
      }
    }
  }

  if ("relations" in constraints) {
    const ids = constraints?.relations?.[0]?.domain;
    target.targetList = ids.map((id) => ({ id }));
  }

  return target;
};

const screeningEncoder = (constraints) => {
  const target: any = {
    instrumentType: "stock",
    what: [],
    whereSource: {
      domestic: false,
      foreign: false,
      market: [],
      stockClassification: [],
    },
    whereTarget: {
      domestic: false,
      foreign: false,
      market: [],
      stockClassification: [],
    },
    subtype: [],
    targetList: null,
    marketCapRange: null,
    eligibilityRules: {
      cardinality: 200,
      sortBy: "desc",
    },
  };

  if (!constraints) {
    return target;
  }

  for (const constraint of constraints.constraints) {
    for (const filter of constraint) {
      switch (filter.dimension) {
        case "etfgeo":
          target.whereTarget.market = filter.segments;
          break;
        case "country":
          target.whereSource.market = filter.segments;
          break;
        case "icb":
        case "etfclass":
          target.what = filter.segments;
          break;
        case "subtype":
          const subtype: any = [];

          for (const segment of filter.segments) {
            if (segment === "Domestic Stock") {
              target.whereSource.domestic = true;
            } else if (segment === "Foreign Stock") {
              target.whereSource.foreign = true;
            } else {
              subtype.push(segment);
            }
          }

          target.subtype = subtype;

          break;
        case "stockclass":
          target.whereSource.stockClassification = filter.segments;

          break;
        case "type":
          const type = filter?.["segments"]?.[0];

          if (type != null && type === "ETF") {
            target.instrumentType = "etf";
          }
          break;
        case "marketcap": {
          if (filter.operator === "range") {
            const max = filter?.segments?.[0]?.["<="] ?? null;
            const min = filter?.segments?.[0]?.[">="] ?? null;

            target.marketCapRange = {
              le: max != null ? max / 1e6 : null,
              ge: min != null ? min / 1e6 : null,
            };
          } else if (filter.operator === "top") {
            target.eligibilityRules = {
              cardinality: filter.n ?? 200,
              sortBy: filter.rev === true ? "desc" : "asc",
            };
          }

          break;
        }

        case "PORTFOLIO":
        case "BASKET":
        case "COLLECTION": {
          const ids = filter.segments;
          target.targetList = ids.map((id) => ({ id }));
        }
      }
    }
  }

  return target;
};

const constraintsEncoder = (constraints) => {
  const encoders = {
    selectSyntax: selectEncoder,
    screeningSyntax: screeningEncoder,
  };

  const emptyConstraints = Object.keys(constraints ?? {}).length === 0;

  if (!constraints || emptyConstraints === true) {
    return screeningEncoder(undefined);
  }

  const syntax =
    "constraints" in constraints ? "screeningSyntax" : "selectSyntax";

  return encoders[syntax](constraints);
};

const constraintsDecoder = (value) => {
  const filters: any = [
    {
      dimension: "type",
      segments: [value.instrumentType === "etf" ? "ETF" : "Stock"],
    },
  ];
  let ranges: any = null;
  let relations: any = null;

  if (value.targetList != null && value.targetList.length) {
    relations = [
      {
        domain: value.targetList.map((obj) => obj.id),
        range: "COLLECTION",
      },
    ];
  }

  const sectorDimension = value.instrumentType === "etf" ? "etfclass" : "icb";

  if (value.what != null && value.what.length) {
    filters.push({
      dimension: sectorDimension,
      segments: value.what,
    });
  }

  if (
    value?.whereTarget?.market != null &&
    value?.whereTarget?.market?.length
  ) {
    filters.push({
      dimension: "etfgeo",
      segments: value?.whereTarget?.market,
    });
  }

  if (
    (value?.subtype != null && value?.subtype?.length) ||
    value?.whereSource?.domestic !== value?.whereSource?.foreign
  ) {
    let subtypeFilter: any = {
      dimension: "subtype",
      segments: [],
    };

    if (value?.subtype != null && value?.subtype?.length) {
      subtypeFilter.segments.push(...value?.subtype);
    } else if (value?.whereSource?.domestic !== value?.whereSource?.foreign) {
      const segment =
        value?.whereSource?.domestic === true
          ? "Domestic Stock"
          : "Foreign Stock";
      subtypeFilter.segments.push(segment);
    }

    filters.push(subtypeFilter);
  }

  if (
    value?.whereSource?.stockClassification != null &&
    value?.whereSource?.stockClassification?.length
  ) {
    filters.push({
      dimension: "stockclass",
      segments: value?.whereSource?.stockClassification,
    });
  }

  if (
    value?.whereSource?.market != null &&
    value?.whereSource?.market?.length
  ) {
    filters.push({
      dimension: "country",
      segments: value?.whereSource?.market,
    });
  }

  const payload = {
    filters,
  };

  if (relations != null) {
    payload["relations"] = relations;
    payload["filters"].push({
      logicalOperator: "not",
      dimension: "type",
      segments: ["ExpiredStock"],
    });
  }

  if (value.marketCapRange != null) {
    const marketCapRangeFilter = {};
    const min =
      value?.marketCapRange?.ge != null
        ? value?.marketCapRange?.ge * 1e6
        : null;
    const max =
      value?.marketCapRange?.le != null
        ? value?.marketCapRange?.le * 1e6
        : null;

    if (min != null) {
      marketCapRangeFilter["min"] = min;
    }

    if (max != null) {
      marketCapRangeFilter["max"] = max;
    }

    if (min != null || max != null) {
      ranges = [
        {
          dimension: "marketcap",
          segments: [marketCapRangeFilter],
        },
      ];
    }
  }

  if (value.instrumentType !== "etf") {
    payload["justInTimeTops"] = [
      {
        dimension: "marketcap",
        n: value?.["eligibilityRules"]?.["cardinality"] ?? 200,
        rev:
          (value?.["eligibilityRules"]?.["sortBy"] ?? "desc") === "desc"
            ? true
            : false,
      },
    ];
  }

  if (ranges != null) {
    payload["ranges"] = ranges;
  }

  return payload;
};

export function RankUniverseSelection({
  isUsedAsOptional = false,
}: RankUniverseSelectionProps) {
  const {
    universeFrom,
    rankReducer,
    constraints,
    optionalConstraints,
    optionalUniverseFrom,
    optionalConstraintsEnabled,
  } = useContext(ActionRankContext);

  const defaultUniverseFromValue = useMemo(
    () => (isUsedAsOptional ? optionalUniverseFrom : universeFrom),
    [isUsedAsOptional, optionalUniverseFrom, universeFrom]
  );
  const defaultConstraintsValue = useMemo(
    () => (isUsedAsOptional ? optionalConstraints : constraints),
    [constraints, isUsedAsOptional, optionalConstraints]
  );

  const UPDATE_CONSTRAINTS_ACTION = useMemo(
    () => (isUsedAsOptional ? "SET_OPTIONAL_CONSTRAINTS" : "SET_CONSTRAINTS"),
    [isUsedAsOptional]
  );
  const UPDATE_UNIVERSE_FROM_ACTION = useMemo(
    () =>
      isUsedAsOptional ? "SET_OPTIONAL_UNIVERSE_FROM" : "SET_UNIVERSE_FROM",
    [isUsedAsOptional]
  );

  const [universeType, setUniverseType] = useState<
    "screenerStock" | "screenerETF" | "whiteList"
  >(defaultUniverseFromValue ?? "screenerStock");
  const [screenerInitState, setScreenerInitState] = useState(
    constraintsEncoder(defaultConstraintsValue)
  );
  const [isSectionActive, setIsSectionActive] = useState(
    optionalConstraintsEnabled ?? false
  );
  const [screenerEtfInitState, setScreenerEtfInitState] = useState(
    constraintsEncoder(defaultConstraintsValue)
  );
  const [whiteListState, setWhiteListState] = useState(
    constraintsEncoder(defaultConstraintsValue)?.targetList ?? []
  );
  const eligibilityCardinality = useMemo(
    () => screenerInitState?.eligibilityRules?.cardinality ?? 200,
    [screenerInitState?.eligibilityRules?.cardinality]
  );
  const [eligibilityInputKey, setEligibilityInputKey] = useState(Date.now());

  const marketCapOrder = useMemo(() => {
    return screenerInitState?.eligibilityRules?.sortBy === "desc" ? 0 : 1;
  }, [screenerInitState?.eligibilityRules?.sortBy]);

  const onSetScreenerStock = useCallback(() => {
    setUniverseType("screenerStock");
    rankReducer({
      type: UPDATE_UNIVERSE_FROM_ACTION,
      payload: "screenerStock",
    });
  }, [UPDATE_UNIVERSE_FROM_ACTION, rankReducer]);

  const onSetScreenerETF = useCallback(() => {
    setUniverseType("screenerETF");
    rankReducer({ type: UPDATE_UNIVERSE_FROM_ACTION, payload: "screenerETF" });
  }, [UPDATE_UNIVERSE_FROM_ACTION, rankReducer]);

  const onSetWhiteList = useCallback(() => {
    setUniverseType("whiteList");
    rankReducer({ type: UPDATE_UNIVERSE_FROM_ACTION, payload: "whiteList" });
  }, [UPDATE_UNIVERSE_FROM_ACTION, rankReducer]);

  const resetWhiteLists = useCallback(() => {
    setWhiteListState([]);
  }, []);

  const resetScreenerStock = useCallback(() => {
    setScreenerInitState({
      instrumentType: "stock",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      subtype: [],
      targetList: null,
      eligibilityRules: {
        cardinality: 200,
        sortBy: "desc",
      },
    });
  }, []);

  const resetEtfScreener = useCallback(() => {
    setScreenerEtfInitState({
      instrumentType: "etf",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      subtype: [],
    });
  }, []);

  const onRadioChange = useCallback(
    (event) => {
      const value = event.target.value;
      resetWhiteLists();
      resetScreenerStock();
      resetEtfScreener();

      switch (value) {
        case "screenerStock":
          onSetScreenerStock();

          break;

        case "screenerETF":
          onSetScreenerETF();

          break;

        case "whiteList":
          onSetWhiteList();

          break;

        default:
          console.warn(`Unhandled value: ${value}`);
      }
    },
    [
      onSetScreenerETF,
      onSetScreenerStock,
      onSetWhiteList,
      resetEtfScreener,
      resetScreenerStock,
      resetWhiteLists,
    ]
  );

  const onWhiteListChange = useCallback(
    (value) => {
      const payload: any = {
        filters: [],
      };

      const relations = [
        { domain: value.map((obj) => obj.id), range: "COLLECTION" },
      ];

      if (relations[0].domain.length) {
        payload["relations"] = relations;
        payload["filters"].push({
          logicalOperator: "not",
          dimension: "type",
          segments: ["ExpiredStock"],
        });
      }

      rankReducer({ type: UPDATE_CONSTRAINTS_ACTION, payload });
    },
    [UPDATE_CONSTRAINTS_ACTION, rankReducer]
  );

  const onConstraintsChange = useCallback(
    (actionScreening) => {
      const value = actionScreening.payload;

      const payload = constraintsDecoder(value);

      rankReducer({ type: UPDATE_CONSTRAINTS_ACTION, payload });
    },
    [UPDATE_CONSTRAINTS_ACTION, rankReducer]
  );

  const onChangeMarketCapFilter = useCallback(
    (value) => {
      setScreenerInitState((prev) => {
        const newState = { ...prev, marketCapRange: value };

        const action = { payload: { ...newState } };

        onConstraintsChange(action);

        return newState;
      });
    },
    [onConstraintsChange]
  );

  const stateModifier = useCallback(
    (prev, value) => {
      const newState = { ...prev, targetList: value };

      const action = { payload: { ...newState } };

      onConstraintsChange(action);

      return newState;
    },
    [onConstraintsChange]
  );

  const onChangeScreenerWhiteList = useCallback(
    (value, type: "stock" | "etf") => {
      const setCallback = (prev) => stateModifier(prev, value);
      if (type === "stock") {
        setScreenerInitState(setCallback);
      } else {
        setScreenerEtfInitState(setCallback);
      }
    },
    [stateModifier]
  );

  const updateEligibilityCard = useCallback(
    (value) => {
      const min = 1;
      const max = 3000;
      const empty = "";

      if (value === empty || parseInt(value) < min || parseInt(value) > max) {
        value = JSON.stringify(max);
      }

      setScreenerInitState((prev) => {
        const newState = {
          ...prev,
          eligibilityRules: {
            ...prev.eligibilityRules,
            cardinality: parseInt(value),
          },
        };

        const action = { payload: { ...newState } };

        onConstraintsChange(action);

        return newState;
      });
    },
    [onConstraintsChange]
  );

  const updateMktOrder = useCallback(
    (value) => {
      setScreenerInitState((prev) => {
        const newState = {
          ...prev,
          eligibilityRules: {
            ...prev.eligibilityRules,
            sortBy: value === 0 ? "desc" : "asc",
          },
        };

        const action = { payload: { ...newState } };

        onConstraintsChange(action);

        return newState;
      });
    },
    [onConstraintsChange]
  );

  const onChangeActiveSection = useCallback(
    (active) => {
      if (!active) {
        rankReducer({ type: UPDATE_CONSTRAINTS_ACTION, payload: undefined });
      }

      setIsSectionActive(active);
      rankReducer({
        type: "SET_OPTIONAL_CONSTRAINTS_ENABLED",
        payload: active,
      });
    },
    [UPDATE_CONSTRAINTS_ACTION, rankReducer]
  );

  const refreshEligibilityInput = useCallback(() => {
    setEligibilityInputKey(Date.now());
  }, []);

  return (
    <SectionWithTitle
      number={isUsedAsOptional ? undefined : 1}
      subtitle={
        isUsedAsOptional ? "(Optional)" : "Define your universe of securities"
      }
      label={isUsedAsOptional ? "Rank in this universe" : "Ranking universe"}
      handleSwitch={isUsedAsOptional ? onChangeActiveSection : undefined}
      isActive={isSectionActive}
    >
      <ErrorBoundary
        fallback={
          <Typography>
            An error occured and universe selection is temporally unavailable.
            If the issue persist please contact our castoer care service.
          </Typography>
        }
      >
        {!isUsedAsOptional || isSectionActive ? (
          <Box p={1} display={"flex"} alignItems={"center"} gap={2}>
            <FormControl>
              <RadioGroup
                onChange={onRadioChange}
                aria-labelledby="radio-universe-type"
                defaultValue={universeType}
                name="radio-universe-type"
              >
                <FormControlLabel
                  sx={{
                    fontWeight:
                      universeType === "whiteList" ? "bold" : "normal",
                  }}
                  value="whiteList"
                  control={<Radio />}
                  label="White list"
                />
                <FormControlLabel
                  sx={{
                    fontWeight:
                      universeType === "screenerStock" ? "bold" : "normal",
                  }}
                  value="screenerStock"
                  control={<Radio />}
                  label="Stocks"
                />
                <FormControlLabel
                  sx={{
                    fontWeight:
                      universeType === "screenerETF" ? "bold" : "normal",
                  }}
                  value="screenerETF"
                  control={<Radio />}
                  label="ETFs"
                />
              </RadioGroup>
            </FormControl>
            <Box>
              {universeType === "whiteList" && (
                <WhiteListAdapter
                  multipleChoice={false}
                  ids={whiteListState}
                  onValueChange={onWhiteListChange}
                />
              )}
              {universeType === "screenerETF" && (
                <Box display={"flex"} gap={2}>
                  <WhiteListAdapter
                    multipleChoice={true}
                    ids={screenerEtfInitState.targetList}
                    onValueChange={(value) =>
                      onChangeScreenerWhiteList(value, "etf")
                    }
                  />
                  <ScreenerETF
                    actualInvUniverse={screenerEtfInitState}
                    setScreening={onConstraintsChange}
                    setIsDisabled={() => {}}
                  />
                </Box>
              )}
              {universeType === "screenerStock" && (
                <Box display={"flex"} gap={2}>
                  <WhiteListAdapter
                    multipleChoice={true}
                    ids={screenerInitState?.targetList ?? []}
                    onValueChange={(action) =>
                      onChangeScreenerWhiteList(action, "stock")
                    }
                  />
                  <Box display={"flex"}>
                    <ScreenerStock
                      enableEligibility={false}
                      actualInvUniverse={screenerInitState as any}
                      setScreening={onConstraintsChange}
                      setIsDisabled={() => {}}
                    />
                  </Box>
                  <Card
                    sx={{
                      display: "flex",
                      position: "relative",
                      border: "1px solid",
                      borderColor: "transparent",
                      transition: "0.5s",
                      cursor: "pointer",
                      "&:hover": {
                        borderColor: "#2a7090",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        padding: "2px 6px!important",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <Box display={"flex"} flex={1} flexDirection={"column"}>
                        <Typography sx={{ textAlign: "center" }}>
                          <strong>Market Cap</strong>
                        </Typography>
                        <MarketCapFilter
                          initValue={screenerInitState?.marketCapRange ?? null}
                          updateValue={onChangeMarketCapFilter}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      display: "flex",
                      position: "relative",
                      border: "1px solid",
                      borderColor: "transparent",
                      transition: "0.5s",
                      cursor: "pointer",
                      "&:hover": {
                        borderColor: "#2a7090",
                      },
                    }}
                    title={"White List"}
                  >
                    <CardContent
                      sx={{
                        padding: "2px 6px!important",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography sx={{ textAlign: "center" }}>
                          <strong>Eligibility</strong>
                        </Typography>
                        <Box display={"flex"} gap={2} alignItems={"center"}>
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Typography>Top</Typography>
                            <TextField
                              onBlur={refreshEligibilityInput}
                              size="small"
                              key={eligibilityInputKey}
                              id="outlined-number"
                              type="number"
                              defaultValue={eligibilityCardinality}
                              onChange={(e) => {
                                updateEligibilityCard(e?.target?.value);
                              }}
                              sx={{
                                maxWidth: "150px",
                                marginRight: "10px",

                                ".MuiOutlinedInput-root > input": {
                                  padding: "2px 10px !important",
                                },
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 5000,
                                  style: {
                                    fontSize: "0.8vw",
                                  },
                                },
                              }}
                            />
                          </Box>

                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Typography>By</Typography>
                            <Select
                              labelId="market-cap-order"
                              id="market-cap-order"
                              classes={{ select: styles.root__select }}
                              defaultValue={marketCapOrder}
                              onChange={(e) => {
                                updateMktOrder(e.target.value as number);
                              }}
                              size={"small"}
                            >
                              {marketCapSelectValue.map((item) => (
                                <MenuItem
                                  sx={{ fontSize: "0.8vw!important" }}
                                  key={uuidv4()}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </ErrorBoundary>
    </SectionWithTitle>
  );
}

const WhiteListAdapter = ({ ids, onValueChange, multipleChoice }) => {
  const [whiteLists, setWhiteLists] = useState<any>();

  const environment = useEnvironment();
  const appSetup = useMemo(() => {
    return environment.get("setup");
  }, [environment]);

  const listsAPI = useMemo(() => new Lists(appSetup), [appSetup]);

  const getListsInfo = useCallback(async () => {
    if (ids && ids.length) {
      try {
        const response = await listsAPI.portfolioFetch(
          ids.map((obj) => obj.id),
          ["name", "type"]
        );

        setWhiteLists(response);
      } catch (error) {
        console.error(error);
      }
    } else {
      setWhiteLists([]);
    }
  }, [ids, listsAPI]);

  useEffect(() => {
    getListsInfo();
  }, [getListsInfo]);

  return whiteLists != null ? (
    <ListConstraint
      multipleChoice={multipleChoice}
      initWhiteLists={whiteLists}
      getSelecetedWhitelist={onValueChange}
    />
  ) : (
    <></>
  );
};
