import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { Section } from "../../../../../trendrating-report/widgets/Section";
import DragAndDropSections from "../../../../../trendrating-report/widgets/sectionSelector/DragAndDropSections";
import ReportHeaderOptions from "../ReportHeaderOptions";
import Analytics from "./Analytics/Analytics";
import FastAccess from "./FastAccess/FastAccess";
import Orientation from "./Orientation/Orientation";
import Preview from "./Preview/Preview";
import TemplateList from "./TemplateList/TemplateList";
import Theme from "./Theme/Theme";

type Props = {
  templates: any;
  value: any;
  columnsAvailable: any;
  wysiwygState: any;
  listenerPrintAndClose: Function;
  listenerPrint: Function;
  listenerCancel: Function;
  listenerSaveAs: Function;
  listenerSave: Function;
  page: any;
  listenerDelete: Function;
  listenerRename: Function;
};

export default function ReactContent({
  templates,
  value,
  page,
  columnsAvailable,
  wysiwygState,
  listenerPrintAndClose,
  listenerPrint,
  listenerCancel,
  listenerSave,
  listenerSaveAs,
  listenerDelete,
  listenerRename,
}: Props) {
  const environment = useEnvironment();
  const [isCustomizing, setIsCustominzing] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(value);
  // const readyToPrintData,
  const sectionInstance = useMemo(() => {
    return new Section(environment.get("setup"));
  }, [environment]);
  const withOrWithoutCover = useMemo(() => {
    let template = deepClone(selectedTemplate);
    //! if there's no lastused template take the first template available
    if (template == null) {
      template = deepClone(templates[0]);
      //!now the selectedTemplate is template
      setSelectedTemplate(template);
    }
    const sections = template.configuration.sections;
    let hasCover = false;
    sections.forEach((item) => {
      if (item.type === "REPORT_COMMON_COVER") {
        hasCover = true;
      }
    });
    let fields = sectionInstance.getDefaultFields(page);
    let _fieldsWithCover = deepClone(fields);
    let _fieldsWithoutCover = deepClone(fields);
    _fieldsWithoutCover.splice(0, 1);
    if (hasCover === false) {
      return _fieldsWithCover;
    } else {
      return _fieldsWithoutCover;
    }
  }, [page, sectionInstance, selectedTemplate, templates]);
  //#region -- handlers
  const addFieldToSections = useCallback(
    (obj) => {
      setSelectedTemplate((prev) => {
        let objToAdd = sectionInstance.getAsSection(obj.value);
        let temp = deepClone(prev);
        let arrSections = temp.configuration.sections;
        arrSections.splice(obj.position, 0, objToAdd);
        temp.configuration.sections = arrSections;
        return temp;
      });
    },
    [sectionInstance]
  );
  const changeSections = useCallback(
    (fields: any[]) => {
      let sections: any[] = [];
      fields.forEach((field) => {
        let section = sectionInstance.getAsSection(field.widgetType);
        section.content = field.widgetValue.content;
        section.presentation = field.widgetValue.presentation;
        section.sections = field.widgetValue.sections;
        sections.push(section);
      });

      if (
        JSON.stringify(selectedTemplate.configuration.sections) !==
        JSON.stringify(sections)
      ) {
        setSelectedTemplate((prev) => {
          let temp = deepClone(prev);
          temp.configuration.sections = sections;
          return temp;
        });
      }
    },
    [sectionInstance, selectedTemplate?.configuration?.sections]
  );
  const logoHandler = useCallback(
    (logo) => {
      if (selectedTemplate.configuration?.headerConfig?.logo !== logo) {
        let temp = deepClone(selectedTemplate);
        temp.configuration.headerConfig.logo = logo;
        setSelectedTemplate(temp);
      }
    },
    [selectedTemplate]
  );
  const dateHandler = useCallback(
    (date) => {
      if (selectedTemplate.configuration?.headerConfig?.date !== date) {
        let temp = deepClone(selectedTemplate);
        temp.configuration.headerConfig.date = date;
        setSelectedTemplate(temp);
      }
    },
    [selectedTemplate]
  );
  const themeHandler = useCallback(
    (theme) => {
      if (selectedTemplate.configuration?.theme !== theme) {
        let temp = deepClone(selectedTemplate);
        temp.configuration.theme = theme;
        setSelectedTemplate(temp);
      }
    },
    [selectedTemplate]
  );
  //#endregion

  const previewSetter = useCallback(
    (fields) => {
      if (isCustomizing) {
        changeSections(fields);
      }
    },
    [changeSections, isCustomizing]
  );

  const [showLoader, setShowLoader] = useState(false);
  return (
    <Box
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      p={1}
      gap={1}
      position={"relative"}
    >
      {showLoader && (
        <Box
          height={"100%"}
          width={"100%"}
          position={"absolute"}
          sx={{ bgcolor: "rgba(255, 255, 255, 0.8)" }}
          zIndex={1}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          left={0}
          top={0}
          gap={2}
          justifyContent={"center"}
        >
          <CircularProgress size={32} color="primary" />
          <Typography variant="subtitle1">
            Generating report, please wait...
          </Typography>
        </Box>
      )}

      <Box
        display={"flex"}
        gap={1}
        height={"100%"}
        flex={1}
        overflow={"hidden"}
      >
        {!isCustomizing && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            flex={1}
            sx={{ backgroundColor: "#f2f2f2" }}
          >
            <Box
              paddingY={1}
              paddingX={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <Typography>
                <strong> Templates</strong>
              </Typography>
            </Box>
            <TemplateList
              value={selectedTemplate}
              options={templates}
              onEdit={(template) => {
                setSelectedTemplate(template);
                setIsCustominzing(true);
              }}
              onSelect={(template) => {
                setSelectedTemplate(template);
              }}
              onDelete={(tempplateToDelete) => {
                listenerDelete(tempplateToDelete);
              }}
            />
          </Box>
        )}
        {isCustomizing && (
          <Box display={"flex"} flexDirection={"column"} flex={1} gap={1}>
            <Card sx={{ overflow: "unset", maxHeight:"60%", height:"auto", display:"flex" }}>
              <CardContent sx={{ p:0, display:"flex",flexDirection:"column",overflow:"hidden", gap:1, width:"100%" }}>
                <Box px={2}>

                
                <FastAccess
                  templates={templates}
                  selectedTemplate={selectedTemplate}
                  onSelectTemplate={(template) => {
                    setSelectedTemplate(template);
                  }}
                  onRename={listenerRename}
                  onDelete={listenerDelete}
                />
                </Box>
                <Box display={"block"} height={"100%"} overflow={"auto"} px={2} py={1}>
                  {["systematicPortfolios", "strategyBuilder"].includes(
                    page
                  ) && (
                    <Analytics
                      input={
                        selectedTemplate?.configuration?.integration?.strategy
                      }
                      onChange={(output: {
                        from: number | null;
                        to: number | null;
                      }) => {
                        let temp = deepClone(selectedTemplate);
                        temp.configuration.integration.strategy = {
                          date: output.from,
                          lastDate: output.to,
                        };
                        setSelectedTemplate(temp);
                      }}
                    />
                  )}
                  <Orientation
                    onChangeOrientation={(orientation) => {
                      if (
                        selectedTemplate.configuration?.orientation !==
                        orientation
                      ) {
                        let temp = deepClone(selectedTemplate);
                        temp.configuration.orientation = orientation;
                        setSelectedTemplate(temp);
                      }
                    }}
                    initValue={selectedTemplate.configuration?.orientation}
                  />
                  <Theme
                    initValue={selectedTemplate.configuration?.theme}
                    onChangeTheme={themeHandler}
                  />
                  <ReportHeaderOptions
                    setOutterState={() => {}}
                    logoSize={
                      selectedTemplate.configuration?.headerConfig?.logo
                    }
                    dateEnabled={
                      selectedTemplate.configuration?.headerConfig?.date
                    }
                    logoHandler={logoHandler}
                    dateHandler={dateHandler}
                  />
                </Box>
              </CardContent>
            </Card>
            <Box overflow={"auto"} maxHeight={"40%"}>
              <DragAndDropSections value={withOrWithoutCover} />
            </Box>
          </Box>
        )}
        <Box
          display={"flex"}
          height={"100%"}
          flexDirection={"column"}
          flex={1}
          overflow={"hidden"}
          sx={{ backgroundColor: "#f2f2f2" }}
        >
          <Box
            paddingY={1}
            paddingX={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography>
              <strong>Preview</strong>
            </Typography>
          </Box>
          <Preview
            key={Date.now().toString()}
            columnsAvailable={columnsAvailable}
            wysiwygState={wysiwygState}
            page={page}
            addField={(obj) => addFieldToSections(obj)}
            isCustomizing={isCustomizing}
            value={[selectedTemplate?.configuration?.sections ?? []]}
            setter={previewSetter}
          />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box gap={1} display={"flex"}>
          <Button onClick={() => setIsCustominzing(!isCustomizing)}>
            {!isCustomizing ? "Customize" : "Back to list"}
          </Button>
          {isCustomizing && (
            <Button
              onClick={() => {
                listenerSaveAs(selectedTemplate);
              }}
            >
              Save as
            </Button>
          )}

          {isCustomizing && selectedTemplate.isEditable && (
            <Button onClick={() => listenerSave(selectedTemplate)}>Save</Button>
          )}
        </Box>
        <Box display={"flex"} gap={1}>
          {isCustomizing && (
            <Button
              onClick={() => {
                setShowLoader(true);
                listenerPrint(selectedTemplate).then(() =>
                  setShowLoader(false)
                );
              }}
            >
              Print
            </Button>
          )}
          <Button
            onClick={() => {
              setShowLoader(true);
              listenerPrintAndClose(selectedTemplate).then(() =>
                setShowLoader(false)
              );
            }}
          >
            Print and close
          </Button>
          <Button onClick={() => listenerCancel()} variant="tr_button_cancel">
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
