import { Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { config } from "../../config-ts";
import { AlertsDataManager } from "./AlertsDataManager";
import { AlertsPage } from "./AlertsPage";
import { useBroadcast } from "../../../../hooks/useBroadcast";

type Timeframe = "today" | "lastWeek" | "lastMonth";

export function Alerts() {
  const params = useParams();
  const initTimeframeValue =
    "timeframe" in params && params.timeframe !== null
      ? (params.timeframe as Timeframe)
      : "today";
  const [error, setError] = useState(false);

  const environment = useEnvironment();
  const { t } = useTranslation();
  const alertsHelper = useMemo(
    () => new AlertsDataManager(environment, t),
    [environment, t]
  );

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.alerts;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  const getPageData = useCallback(
    async (
      endpoint:
        | "pinned"
        | "listsData"
        | "markets"
        | "peersAndInstrumentsNews"
        | "indexNews"
        | "sectorNews",
      timeframe
    ) => {
      try {
        return await alertsHelper.getAlertPageData(timeframe, endpoint);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    },
    [alertsHelper]
  );

  // Retrive data from Server
  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");
  }, []);

  const { broadcast } = useBroadcast();

  const savePinnedInPreferences = useCallback(
    async (pinned) => {
      const freshSavedPreferences = await alertsHelper.savePinnedInPreferences(
        pinned
      );

      /*
       * Update the local preferences object id with the server id if
       * there wasn't a copy when the program started.
       */
      if (window.App.user.preferences["id"] == null) {
        window.App.user.preferences["id"] = freshSavedPreferences["id"];
      }

      var message = {
        from: "Alerts Pinned",
        content: {
          type: "success",
          text: "Preferences saved successfully.",
        },
      };

      broadcast(config["channels"]["feedback"]["input"], message);
    },
    [alertsHelper, broadcast]
  );

  const listenerCreate = useCallback((listType: "basket" | "portfolio") => {
    const uri = `/analysis/lists/create/${listType}/`;
    (window as any).__page_navigate(uri);
  }, []);

  const handleFiltersChange = useCallback(
    async (filters, type: "stock" | "etf", setFunction, timeframe) => {
      const results = await alertsHelper.getNewsFiltered(
        timeframe,
        type,
        filters
      );

      setFunction(results);
    },
    [alertsHelper]
  );

  const saveFiltersInPreferences = useCallback(
    async (filters, type) => {
      const response = await alertsHelper.saveFiltersInPreferences(
        filters,
        type
      );
      window.App["user"]["alertsNewsFilters"]["filters"] = response.filters;
      var message = {
        from: "Alerts save preference",
        content: {
          type: "success",
          text: "Preferences saved successfully.",
        },
      };

      broadcast(config["channels"]["feedback"]["input"], message);
    },
    [alertsHelper, broadcast]
  );

  return !error ? (
    <AlertsPage
      timeframeInit={initTimeframeValue}
      getDataGateway={getPageData}
      onSavePreferences={savePinnedInPreferences}
      getNewsFiltered={handleFiltersChange}
      createListner={listenerCreate}
      onSaveFilters={saveFiltersInPreferences}
    />
  ) : (
    <Card>
      <CardContent>
        <Typography sx={{ color: "red" }}>
          Sorry an error occured during page loading. We suggest you to retry
          later and if the error persist please contact our support team
        </Typography>
      </CardContent>
    </Card>
  );
}
