import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { Cluster, ComparisonTableWidget } from "./ComparisonTableWidget";
import { PerformanceWidget } from "./PerformanceWidget";
import { RatingWeights } from "./RatingWeights";
import { SummaryTableWidget } from "./SummaryTableWidget";
import { TCRBlock } from "./TCRBlock";
import { UpgradesDowngradesBlock } from "./UpgradesDowngradesBlock";
import { ListSelect } from "../../../widgets/ListSelect";
import { Box } from "@mui/material";
import { MarketsData } from "./compare_business_logic/MarketsData";
import { SummaryTableData } from "./compare_business_logic/SummaryTableData";
import { PerformanceData } from "./compare_business_logic/performanceData";

type Period = "pd" | "pw" | "pm" | "pq" | "ps" | "py";

type AssetType =
  | "Sector"
  | "Currency"
  | "Commodity"
  | "Index"
  | "Stock"
  | "ETF"
  | "Instrument";

type ClusterSegment =
  | "Country"
  | "1 Industry"
  | "3 Sector"
  | "Currency"
  | "3 Level"
  | "Area"
  | "Region"
  | "etfgeo"
  | "AssetClass"
  | "Specialty"
  | "Type";

type ComparePortfoliosProps = {
  clusterLevel: Cluster;
  data: any;
  onChangeUpgradesDowngradesTimeframe: (value) => void;
  setComparisonListId: (id) => void;
  syncFilters: (filters, symbols, updateTableData) => void;
  setClusterLevel: (segment: ClusterSegment) => void;
  clusterState: any;
};

const getPositionsType: (portfolio, portfolio2) => AssetType = (
  portfolio,
  portfolio2
) => {
  /**
   * positionsType has instrument type for portfolios or baskets that are a mix of
   * stocks and ETFs.
   */
  let positionsType: AssetType = "Instrument";
  const assetType: AssetType = portfolio["assetType"];
  const assetType2: AssetType = portfolio2["assetType"];
  const typesWhiteList = [
    "Stock",
    "ETF",
    "Commodity",
    "Index",
    "Sector",
    "Currency",
  ];
  const listTypes: Array<AssetType> = [];

  for (const type of Object.keys(assetType) as Array<AssetType>) {
    if (typesWhiteList.includes(type)) {
      listTypes.push(type);
    }
  }

  //TODO: avoid this code duplication by using a short helper method.
  for (const type of Object.keys(assetType2) as Array<AssetType>) {
    if (typesWhiteList.includes(type)) {
      listTypes.push(type);
    }
  }

  const portfoliosTypes = [...new Set(listTypes)];

  const isMultiTypes = portfoliosTypes.length > 1;

  if (!isMultiTypes) {
    positionsType = listTypes[0];
  }

  return positionsType;
};

const extractHoldings = (symbols, list) => {
  let result: any = [];
  for (const symbol of symbols) {
    const item = list.find((el) => el.symbol === symbol);

    result.push(item);
  }

  return result;
};

const getDataForUpgradesDowngradesWidget = (performance, holdings) => {
  if (!performance || !holdings) {
    return;
  }

  const { symbolsDowngrades, symbolsUpgrades } = performance ?? {
    symbolsDowngrades: null,
    symbolsUpgrades: null,
  };

  let data = { upgrades: [], downgrades: [] };

  if (symbolsDowngrades && symbolsUpgrades) {
    data.upgrades = extractHoldings(symbolsUpgrades, holdings);
    data.downgrades = extractHoldings(symbolsDowngrades, holdings);
  }

  return data;
};

const getTCR = (tcrToday, tcrYesterday, format) => {
  let result;

  if (tcrToday != null || tcrYesterday) {
    const innerHTML: string[] = [];
    if (tcrToday !== tcrYesterday) {
      innerHTML.push(format.tcr(tcrYesterday, "HTML"));
      innerHTML.push('<span class="tTableAlerts-arrow i-arrow-r"></span>');
    }
    innerHTML.push(format.tcr(tcrToday, "HTML"));
    innerHTML.push("</div>");
    result = innerHTML.join("");
  } else {
    result = "-";
  }

  return result;
};

const getPeriodLabel = (period) => {
  let result = "";

  switch (period) {
    case "pm":
      result = "Last Month";
      break;
    case "pq":
      result = "Last 3 months";
      break;
    case "ps":
      result = "Last 6 months";
      break;
  }

  return result;
};

const dataSerieSorter = (a, b) => {
  if (a == null || b == null) {
    return 0;
  }

  if (a.trendrating != null && b.trendrating != null) {
    if (a.trendrating?.rate > b.trendrating?.rate) {
      return 1;
    }

    if (a.trendrating?.rate < b.trendrating?.rate) {
      return -1;
    }

    return 0;
  }

  return 0;
};

const prepareData = (
  data1,
  data2,
  env,
  clusterLevel,
  portfolio1,
  portfolio2
) => {
  const dataUtils = new MarketsData(
    data1,
    data2,
    portfolio1.type,
    portfolio2.type,
    env
  );

  const tableData = dataUtils.getData(
    clusterLevel,
    getPositionsType(portfolio1, portfolio2)
  );

  const dataSerie1 = dataUtils.prepareSerie(data1).sort(dataSerieSorter);

  const dataSerie2 = dataUtils.prepareSerie(data2).sort(dataSerieSorter);

  return {
    pie: dataSerie1,
    pie2: dataSerie2,
    mainTableData: tableData,
  };
};

export function ComparePortfolios({
  clusterLevel,
  data,
  onChangeUpgradesDowngradesTimeframe,
  setComparisonListId,
  syncFilters,
  clusterState,
  setClusterLevel,
}: ComparePortfoliosProps) {
  const {
    portfolio,
    performance,
    holdings,
    comparisonList: portfolio2,
    holdingsComparison: holdings2,
    compareListPerformance,
  } = data ?? {
    portfolio: {
      tcrToday: null,
      tcrYesterday: null,
      positions: null,
      type: null,
      name: null,
      assetType: null,
      cardinalityPerRating: { A: 0, B: 0, C: 0, D: 0 },
      weightsPerRating: { A: 0, B: 0, C: 0, D: 0 },
    },
    performance: null,
    holdings: null,
    portfolio2: {
      tcrToday: null,
      tcrYesterday: null,
      positions: null,
      type: null,
      name: null,
      assetType: null,
      cardinalityPerRating: { A: 0, B: 0, C: 0, D: 0 },
      weightsPerRating: { A: 0, B: 0, C: 0, D: 0 },
    },
    holdings2: null,
    dataClusterPortfolio: null,
  };

  const { dataClusterPortfolio, dataClusterComparisonList } = clusterState;

  const [period, setPeriod] = useState<Period>("pm");

  const formatter = useFormatter();
  const environment = useEnvironment();

  const [distributionType, setDistributionType] = useState<
    "weights" | "holdings"
  >("weights");

  const upgradesDowngradesWidgetData = getDataForUpgradesDowngradesWidget(
    performance,
    holdings
  );
  const upgradesDowngradesWidgetData2 = getDataForUpgradesDowngradesWidget(
    compareListPerformance,
    holdings2
  );

  const getTableData = useCallback(() => {
    const summaryTableUtils = new SummaryTableData(
      holdings,
      holdings2,
      portfolio?.positions,
      portfolio2?.positions,
      portfolio?.type,
      portfolio2.type
    );

    const env = environment.get("setup");

    return {
      dataTable: summaryTableUtils.get(env),
      symbols: summaryTableUtils.extractSymbolsFromDataTable(env),
    };
  }, [
    environment,
    holdings,
    holdings2,
    portfolio?.positions,
    portfolio?.type,
    portfolio2?.positions,
    portfolio2?.type,
  ]);

  const { dataTable, symbols } = getTableData();

  const performanceData2 = useMemo(
    () => new PerformanceData(holdings2),
    [holdings2]
  );
  const performanceData = useMemo(
    () => new PerformanceData(holdings),
    [holdings]
  );
  const top = useMemo(
    () => performanceData.getPositivesNumber(period),
    [performanceData, period]
  );
  const flop = useMemo(
    () => performanceData.getNegativesNumber(period),
    [performanceData, period]
  );

  const top2 = useMemo(
    () => performanceData2.getPositivesNumber(period),
    [performanceData2, period]
  );
  const flop2 = useMemo(
    () => performanceData2.getNegativesNumber(period),
    [performanceData2, period]
  );
  const positions = useMemo(
    () => portfolio?.positions ?? [],
    [portfolio?.positions]
  );

  const positions2 = useMemo(
    () => portfolio2?.positions ?? [],
    [portfolio2?.positions]
  );

  const [performance1, setPerformance1] = useState({
    upgrades: `${top}`,
    downgrades: `${flop}`,
    top5: performanceData.getTopBottomFivePerformers(
      period,
      "positive",
      positions ?? []
    ),
    bottom5: performanceData.getTopBottomFivePerformers(
      period,
      "negative",
      positions ?? []
    ),
  });

  const [performance2, setPerformance2] = useState({
    upgrades: `${top2}`,
    downgrades: `${flop2}`,
    top5: performanceData2.getTopBottomFivePerformers(
      period,
      "positive",
      positions2 ?? []
    ),
    bottom5: performanceData2.getTopBottomFivePerformers(
      period,
      "negative",
      positions2 ?? []
    ),
  });

  const onChangePerformancePeriod = useCallback((value) => {
    setPeriod(value);
  }, []);

  const tableData = useMemo(() => {
    return prepareData(
      dataClusterPortfolio,
      dataClusterComparisonList,
      environment,
      clusterLevel,
      portfolio,
      portfolio2
    );
  }, [
    dataClusterPortfolio,
    dataClusterComparisonList,
    environment,
    clusterLevel,
    portfolio,
    portfolio2,
  ]);

  useEffect(() => {
    setPerformance1({
      upgrades: `${performanceData.getPositivesNumber(period) ?? "-"}`,
      downgrades: `${performanceData.getNegativesNumber(period) ?? "-"}`,
      top5: performanceData.getTopBottomFivePerformers(
        period,
        "positive",
        positions
      ),
      bottom5: performanceData.getTopBottomFivePerformers(
        period,
        "negative",
        positions
      ),
    });
  }, [performanceData, period, positions]);

  useEffect(() => {
    setPerformance2({
      upgrades: `${performanceData2.getPositivesNumber(period) ?? "-"}`,
      downgrades: `${performanceData2.getNegativesNumber(period) ?? "-"}`,
      top5: performanceData2.getTopBottomFivePerformers(
        period,
        "positive",
        positions2
      ),
      bottom5: performanceData2.getTopBottomFivePerformers(
        period,
        "negative",
        positions2
      ),
    });
  }, [performanceData2, period, positions2]);

  const label1 = getPeriodLabel(period);
  const label2 = getPeriodLabel(period);

  return (
    <>
      <div className="compare-section-row">
        <div className="tcr-portfolio-panel-wrapper">
          <h2 className="portfolioName">{portfolio?.name}</h2>
          <div className="tcr-row">
            {portfolio ? (
              <>
                <TCRBlock holdings={portfolio?.positions.length}>
                  <p
                    className="tAnalysisOverview-tcr"
                    dangerouslySetInnerHTML={{
                      __html: getTCR(
                        portfolio?.tcrToday,
                        portfolio?.tcrYesterday,
                        formatter
                      ),
                    }}
                  ></p>
                </TCRBlock>
                <RatingWeights
                  distributionType={distributionType}
                  setDistributionType={setDistributionType}
                  hasDropDown={true}
                  cardinalityPerRating={portfolio.cardinalityPerRating}
                  weightsPerRating={portfolio.weightsPerRating}
                />
              </>
            ) : (
              <p>Data are Loading...</p>
            )}
          </div>
        </div>
        <div className="tcr-portfolio-panel-wrapper">
          <div className="portfolio-selector-widget">
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <h2 className="portfolioName">{portfolio2?.name}</h2>
              <ListSelect
                changeList={setComparisonListId}
                currentListId={undefined}
              />
            </Box>
          </div>
          <div className="tcr-row">
            {portfolio2 ? (
              <>
                <TCRBlock holdings={portfolio2?.positions.length}>
                  <p
                    className="tAnalysisOverview-tcr"
                    dangerouslySetInnerHTML={{
                      __html: getTCR(
                        portfolio2?.tcrToday,
                        portfolio2?.tcrYesterday,
                        formatter
                      ),
                    }}
                  ></p>
                </TCRBlock>
                <RatingWeights
                  hasDropDown={false}
                  distributionType={distributionType}
                  setDistributionType={setDistributionType}
                  cardinalityPerRating={portfolio2.cardinalityPerRating}
                  weightsPerRating={portfolio2.weightsPerRating}
                />
              </>
            ) : (
              <p>Data are Loading...</p>
            )}
          </div>
        </div>
      </div>

      <PerformanceWidget
        performance1={performance1}
        performance2={performance2}
        onChangePerformancePeriod={onChangePerformancePeriod}
        period={period}
        label1={label1}
        label2={label2}
      />
      <div className="compare-portfolio-sectionRow">
        <UpgradesDowngradesBlock
          onChangeTimeframe={onChangeUpgradesDowngradesTimeframe}
          dataList1={upgradesDowngradesWidgetData}
          dataList2={upgradesDowngradesWidgetData2}
        />
      </div>

      <div className="compare-portfolio-sectionRow">
        <ComparisonTableWidget
          positionsType={getPositionsType(portfolio, portfolio2)}
          clusterLevel={clusterLevel}
          fetchFilteredData={syncFilters}
          name1={portfolio?.name ?? ""}
          name2={portfolio2?.name ?? ""}
          setClusterLevel={setClusterLevel}
          summaryTableData={dataTable ?? []}
          symbols={symbols}
          value={tableData}
        />
      </div>
      <SummaryTableWidget
        symbols={symbols}
        syncFilters={syncFilters}
        data={dataTable ?? []}
        portfolio={portfolio?.name ?? ""}
        portfolio2={portfolio2?.name ?? ""}
        positionsType={getPositionsType(portfolio, portfolio2)}
      />
    </>
  );
}
