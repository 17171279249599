export type KeyfactsType = {
  annualized: {
    content: string;
    isEnabled: boolean;
  };
  averageYearlyDrawdown: {
    content: string;
    isEnabled: boolean;
  };
  benchmark: {
    content: string;
    isEnabled: boolean;
  };
  beta: {
    content: string;
    isEnabled: boolean;
  };
  cumulative: {
    content: string;
    isEnabled: boolean;
  };
  delta: {
    content: string;
    isEnabled: boolean;
  };
  headlineKeyRatio: {
    content: string;
    isEnabled: boolean;
  };
  headlinePerformance: {
    content: string;
    isEnabled: boolean;
  };
  headlineRisk: {
    content: string;
    isEnabled: boolean;
  };
  maxConsecutiveNegativeMonths: {
    content: string;
    isEnabled: boolean;
  };
  maxConsecutivePositiveMonths: {
    content: string;
    isEnabled: boolean;
  };
  maxDrawdown: {
    content: string;
    isEnabled: boolean;
  };
  monthlyStandardDeviation: {
    content: string;
    isEnabled: boolean;
  };
  oneWayTurnover: {
    content: string;
    isEnabled: boolean;
  };
  percentPositiveMonths: {
    content: string;
    isEnabled: boolean;
  };
  sharpeRatio: {
    content: string;
    isEnabled: boolean;
  };
  sortinoRatio: {
    content: string;
    isEnabled: boolean;
  };
  sterlingRatio: {
    content: string;
    isEnabled: boolean;
  };
  strategy: {
    content: string;
    isEnabled: boolean;
  };
  informationRatio: {
    content: string;
    isEnabled: boolean;
  };
  trackingError: {
    content: string;
    isEnabled: boolean;
  };
  treynorRatio: {
    content: string;
    isEnabled: boolean;
  };
  yearlyAverage: {
    content: string;
    isEnabled: boolean;
  };
};

type ActionType = {
  type:
    | "SET_annualized_CONTENT"
    | "SET_annualized_ISENABLED"
    | "SET_averageYearlyDrawdown_CONTENT"
    | "SET_averageYearlyDrawdown_ISENABLED"
    | "SET_benchmark_CONTENT"
    | "SET_benchmark_ISENABLED"
    | "SET_beta_CONTENT"
    | "SET_beta_ISENABLED"
    | "SET_cumulative_CONTENT"
    | "SET_cumulative_ISENABLED"
    | "SET_delta_CONTENT"
    | "SET_delta_ISENABLED"
    | "SET_headlineKeyRatio_CONTENT"
    | "SET_headlineKeyRatio_ISENABLED"
    | "SET_headlinePerformance_CONTENT"
    | "SET_headlinePerformance_ISENABLED"
    | "SET_headlineRisk_CONTENT"
    | "SET_headlineRisk_ISENABLED"
    | "SET_maxConsecutiveNegativeMonths_CONTENT"
    | "SET_maxConsecutiveNegativeMonths_ISENABLED"
    | "SET_maxConsecutivePositiveMonths_CONTENT"
    | "SET_maxConsecutivePositiveMonths_ISENABLED"
    | "SET_maxDrawdown_CONTENT"
    | "SET_maxDrawdown_ISENABLED"
    | "SET_monthlyStandardDeviation_CONTENT"
    | "SET_monthlyStandardDeviation_ISENABLED"
    | "SET_oneWayTurnover_CONTENT"
    | "SET_oneWayTurnover_ISENABLED"
    | "SET_percentPositiveMonths_CONTENT"
    | "SET_percentPositiveMonths_ISENABLED"
    | "SET_sharpeRatio_CONTENT"
    | "SET_sharpeRatio_ISENABLED"
    | "SET_sortinoRatio_CONTENT"
    | "SET_sortinoRatio_ISENABLED"
    | "SET_sterlingRatio_CONTENT"
    | "SET_sterlingRatio_ISENABLED"
    | "SET_strategy_CONTENT"
    | "SET_strategy_ISENABLED"
    | "SET_informationRatio_CONTENT"
    | "SET_informationRatio_ISENABLED"
    | "SET_trackingError_CONTENT"
    | "SET_trackingError_ISENABLED"
    | "SET_treynorRatio_CONTENT"
    | "SET_treynorRatio_ISENABLED"
    | "SET_yearlyAverage_CONTENT"
    | "SET_yearlyAverage_ISENABLED";
  payload: any;
};

export const keyfactsReducer = (draft: KeyfactsType, action: ActionType) => {
  switch (action.type) {
    case "SET_annualized_CONTENT":
      draft.annualized.content = action.payload;
      break;
    case "SET_annualized_ISENABLED":
      draft.annualized.isEnabled = action.payload;
      break;
    case "SET_averageYearlyDrawdown_CONTENT":
      draft.averageYearlyDrawdown.content = action.payload;
      break;
    case "SET_averageYearlyDrawdown_ISENABLED":
      draft.averageYearlyDrawdown.isEnabled = action.payload;
      break;
    case "SET_benchmark_CONTENT":
      draft.benchmark.content = action.payload;
      break;
    case "SET_benchmark_ISENABLED":
      draft.benchmark.isEnabled = action.payload;
      break;
    case "SET_beta_CONTENT":
      draft.beta.content = action.payload;
      break;
    case "SET_beta_ISENABLED":
      draft.beta.isEnabled = action.payload;
      break;
    case "SET_cumulative_CONTENT":
      draft.cumulative.content = action.payload;
      break;
    case "SET_cumulative_ISENABLED":
      draft.cumulative.isEnabled = action.payload;
      break;
    case "SET_delta_CONTENT":
      draft.delta.content = action.payload;
      break;
    case "SET_delta_ISENABLED":
      draft.delta.isEnabled = action.payload;
      break;
    case "SET_headlineKeyRatio_CONTENT":
      draft.headlineKeyRatio.content = action.payload;
      break;
    case "SET_headlineKeyRatio_ISENABLED":
      draft.headlineKeyRatio.isEnabled = action.payload;
      break;
    case "SET_headlinePerformance_CONTENT":
      draft.headlinePerformance.content = action.payload;
      break;
    case "SET_headlinePerformance_ISENABLED":
      draft.headlinePerformance.isEnabled = action.payload;
      break;
    case "SET_headlineRisk_CONTENT":
      draft.headlineRisk.content = action.payload;
      break;
    case "SET_headlineRisk_ISENABLED":
      draft.headlineRisk.isEnabled = action.payload;
      break;
    case "SET_maxConsecutiveNegativeMonths_CONTENT":
      draft.maxConsecutiveNegativeMonths.content = action.payload;
      break;
    case "SET_maxConsecutiveNegativeMonths_ISENABLED":
      draft.maxConsecutiveNegativeMonths.isEnabled = action.payload;
      break;
    case "SET_maxConsecutivePositiveMonths_CONTENT":
      draft.maxConsecutivePositiveMonths.content = action.payload;
      break;
    case "SET_maxConsecutivePositiveMonths_ISENABLED":
      draft.maxConsecutivePositiveMonths.isEnabled = action.payload;
      break;
    case "SET_maxDrawdown_CONTENT":
      draft.maxDrawdown.content = action.payload;
      break;
    case "SET_maxDrawdown_ISENABLED":
      draft.maxDrawdown.isEnabled = action.payload;
      break;
    case "SET_monthlyStandardDeviation_CONTENT":
      draft.monthlyStandardDeviation.content = action.payload;
      break;
    case "SET_monthlyStandardDeviation_ISENABLED":
      draft.monthlyStandardDeviation.isEnabled = action.payload;
      break;
    case "SET_oneWayTurnover_CONTENT":
      draft.oneWayTurnover.content = action.payload;
      break;
    case "SET_oneWayTurnover_ISENABLED":
      draft.oneWayTurnover.isEnabled = action.payload;
      break;
    case "SET_percentPositiveMonths_CONTENT":
      draft.percentPositiveMonths.content = action.payload;
      break;
    case "SET_percentPositiveMonths_ISENABLED":
      draft.percentPositiveMonths.isEnabled = action.payload;
      break;
    case "SET_sharpeRatio_CONTENT":
      draft.sharpeRatio.content = action.payload;
      break;
    case "SET_sharpeRatio_ISENABLED":
      draft.sharpeRatio.isEnabled = action.payload;
      break;
    case "SET_sortinoRatio_CONTENT":
      draft.sortinoRatio.content = action.payload;
      break;
    case "SET_sortinoRatio_ISENABLED":
      draft.sortinoRatio.isEnabled = action.payload;
      break;
    case "SET_sterlingRatio_CONTENT":
      draft.sterlingRatio.content = action.payload;
      break;
    case "SET_sterlingRatio_ISENABLED":
      draft.sterlingRatio.isEnabled = action.payload;
      break;
    case "SET_strategy_CONTENT":
      draft.strategy.content = action.payload;
      break;
    case "SET_strategy_ISENABLED":
      draft.strategy.isEnabled = action.payload;
      break;
    case "SET_informationRatio_CONTENT":
      draft.informationRatio.content = action.payload;
      break;
    case "SET_informationRatio_ISENABLED":
      draft.informationRatio.isEnabled = action.payload;
      break;
    case "SET_trackingError_CONTENT":
      draft.trackingError.content = action.payload;
      break;
    case "SET_trackingError_ISENABLED":
      draft.trackingError.isEnabled = action.payload;
      break;
    case "SET_treynorRatio_CONTENT":
      draft.treynorRatio.content = action.payload;
      break;
    case "SET_treynorRatio_ISENABLED":
      draft.treynorRatio.isEnabled = action.payload;
      break;
    case "SET_yearlyAverage_CONTENT":
      draft.yearlyAverage.content = action.payload;
      break;
    case "SET_yearlyAverage_ISENABLED":
      draft.yearlyAverage.isEnabled = action.payload;
      break;
    default:
      break;
  }
};
