import React, {
  CSSProperties,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Modal from "../../../components/Modal/Modal";

type ActionModalContextType = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  actionType: string | null;
  setActionType: (value: string) => void;
  actionData: any;
  setActionData: (value: any) => void;
  content: JSX.Element | JSX.Element[] | null;
  setContent: (modalBody: JSX.Element | JSX.Element[]) => void;
  modalTitle: string;
  setModalTitle: (value: string) => void;
  options: HookOptions | undefined;
  setOptions: (opts: HookOptions) => void;
};

const ActionModalContext = createContext<ActionModalContextType>(undefined!);

type HookOptions = {
  stylesOptions: {
    customCss?: CSSProperties;
    bodyCustomClass: string;
  };
};

const useProvideActionModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState<null | string>(null);
  const [actionData, setActionData] = useState<any>(null);
  const [content, setContent] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [options, setOptions] = useState<HookOptions>();

  useEffect(() => {
    if (showModal === false) {
      setContent(null);
      setOptions({ stylesOptions: { bodyCustomClass: "" } });
    }
  }, [showModal]);

  return {
    showModal,
    setShowModal,
    actionType,
    setActionType,
    actionData,
    setActionData,
    content,
    setContent,
    modalTitle,
    setModalTitle,
    options,
    setOptions,
  };
};

export const ProvideActionModal = ({ children }) => {
  const actionModal = useProvideActionModal();
  return (
    <ActionModalContext.Provider value={actionModal}>
      {children}
    </ActionModalContext.Provider>
  );
};

export const useActionModal = () => {
  return useContext(ActionModalContext);
};

export const ActionModal = () => {
  const { showModal, modalTitle, content, setShowModal, options } =
    useActionModal();

  return showModal ? (
    <Modal
      closeIcon={false}
      bodyCustomClass={options?.stylesOptions?.bodyCustomClass ?? ""}
      customCss={options?.stylesOptions?.customCss}
      headerConfig={{ headerContent: modalTitle }}
      closeOnClickAway={false}
      onClose={() => setShowModal(false)}
    >
      {content}
    </Modal>
  ) : (
    <></>
  );
};
