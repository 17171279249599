import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Popover,
  Typography,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { FilterTaxonomies } from "../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FormOptions } from "../../../../../../trendrating-widgets/form/FormOptions";
import { FiltersContext } from "../../FilterRowContraints";
import { OptionsFilter } from "../OptionFilter/OptionFilter";
import styles from "./../../FilterRowConstraints.module.scss";

type AnalyticsFiltersProps = {
  widgetParams: any;
  tabType: string;
  updateTableColumns: (fields: any) => any;
};

type EditorType = {
  label: string;
  field: string;
  inputParams: {
    type: string;
    min?: number;
    max?: number;
    decimals?: number;
    widget?: string;
  };
  value?: any;
} | null;

type IntervalEditorProps = {
  editor: EditorType;
  handleAwayClick: () => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleApplyClick: (value) => void;
};

type DomicileEditorProps = {
  handleApplyClick: (value) => void;
  handleAwayClick: (value) => void;
};

type ResultCardMinMaxProps = {
  constraint: {
    field: string;
    value: any;
    label: string;
    editorType: string;
    widget: string;
  };
  editTarget: string | null;
  editConstraint: (value: ResultCardMinMaxProps["constraint"]) => void;
  removeActiveConstraint: (
    e,
    value: ResultCardMinMaxProps["constraint"]
  ) => void;
};

type ResultNewHighNewLowCardProps = ResultCardMinMaxProps;

type ResultCardTaxonomyFilterProps = {
  constraint: {
    field: string;
    value: any;
    label: string;
    editorType: string;
    widget: string;
  };
  editTarget: string | null;
  editConstraint: (value: ResultCardMinMaxProps["constraint"]) => void;
  removeActiveConstraint: (
    e,
    value: ResultCardMinMaxProps["constraint"]
  ) => void;
  taxonomyType: string;
};

export function AnalyticsFilters({
  widgetParams,
  tabType,
  updateTableColumns,
}: AnalyticsFiltersProps) {
  const context = useContext(FiltersContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [editor, setEditor] = useState<EditorType>(null);
  const [list, updateList] = useState<
    {
      label: string;
      field: string;
      tag: string;
      input: {
        type: string;
        min?: number;
        max?: number;
        decimals?: number;
      };
    }[][]
  >([]);
  const [editTarget, setEditTarget] = useState<string | null>(null);

  const environment = useEnvironment();
  const properties = useMemo(
    () => environment.get("properties"),
    [environment]
  );
  const rawProperties = useMemo(() => {
    const p = properties.properties;
    const rawProperties = {
      ...p.stock,
      ...p.security,
    };

    return rawProperties;
  }, [properties.properties]);
  const popoverRef = useRef<any>(null);

  const fields = useMemo(() => {
    const fields = [...widgetParams?.field?.["security"]] ?? [];

    if (tabType in widgetParams?.field) {
      fields.push(...widgetParams?.field[tabType]);
    }

    return fields;
  }, [tabType, widgetParams?.field]);

  useEffect(() => {
    let fieldsProperties: any = [];
    let property: any = null;
    let field: string = "";

    for (const fieldObject of fields) {
      field = fieldObject.field;
      property = rawProperties?.[field]?.name?.[0] ?? "";

      if (field === "pr") {
        property = rawProperties?.[field]?.name?.[2] ?? "";
      }

      if (property) {
        fieldsProperties.push({
          label: property,
          field,
          tag: fieldObject.tag,
          input: rawProperties?.[field]?.input ?? null,
        });
      }
    }

    const groupedByTag: {
      label: string;
      field: string;
      tag: string;
      input: {
        type: string;
        min?: number;
        max?: number;
        decimals?: number;
      };
    }[][] = [];
    const order = widgetParams.tagOrder;
    fieldsProperties = fieldsProperties.map((field) => {
      if (field.tag in order) {
        return { ...field, rank: order[field.tag] };
      } else {
        return { ...field, rank: 99 };
      }
    });

    fieldsProperties.sort((a, b) =>
      a.rank === b.rank ? a.label < b.label : a.rank > b.rank ? 1 : -1
    );

    let tag = fieldsProperties[0].tag;
    let buffer: any = [];
    let isLastChild = false;

    for (const item of fieldsProperties) {
      if (tag === item.tag) {
        buffer.push(item);
      } else {
        tag = item.tag;
        groupedByTag.push([...buffer]);
        buffer = [item];
      }

      // If is last iteration push the buffer otherwise no one will push it
      isLastChild =
        fieldsProperties.indexOf(item) === fieldsProperties.length - 1;
      if (isLastChild) {
        groupedByTag.push([...buffer]);
        buffer = [];
      }
    }

    updateList(groupedByTag);
  }, [fields, rawProperties, widgetParams.tagOrder]);

  const openEditor = useCallback((editorParams: EditorType) => {
    setEditor(editorParams);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const editConstraint = useCallback(
    (constraint: {
      field: string;
      value: any;
      label: string;
      editorType: string;
      widget: string;
    }) => {
      setEditTarget(
        constraint.widget === "minMax" || constraint.widget === "newHighNewLow"
          ? constraint.field
          : constraint.value
      );
      const constraintConfig = rawProperties[constraint.field];

      if (constraintConfig) {
        const editorParams: EditorType = {
          label: constraint.label,
          field: constraint.field,
          inputParams: { type: constraintConfig?.input?.type ?? "" },
          value: constraint.value,
        };

        if (editorParams) {
          if ("min" in constraintConfig.input) {
            editorParams.inputParams["min"] = constraintConfig.input.min;
          }

          if ("max" in constraintConfig.input) {
            editorParams.inputParams["max"] = constraintConfig.input.max;
          }

          if ("decimals" in constraintConfig.input) {
            editorParams.inputParams["decimals"] =
              constraintConfig.input.decimals;
          }

          if ("widget" in constraintConfig.input) {
            editorParams.inputParams["widget"] = constraintConfig.input.widget;
          }

          openEditor(editorParams as EditorType);
        }
      }
    },
    [openEditor, rawProperties]
  );

  const addMinMaxFilter = useCallback((value, listObj) => {
    const { newList } = listObj;
    if ("max" in value.value && value.value.max == null) {
      delete value.value.max;
    }

    if ("min" in value.value && value.value.min == null) {
      delete value.value.min;
    }

    if ("min" in value.value || "max" in value.value) {
      listObj["newList"] = [...newList, value];
    }
  }, []);

  const addTaxonomyFilter = useCallback((value, listObj) => {
    if (value.value && "taxonomy" in value.value && "segments" in value.value) {
      const { segments } = value.value;

      const arr = [
        ...listObj["newList"].filter((v) => v.field !== value.field),
        {
          label: value.label,
          value: segments,
          field: "domicile",
          widget: "taxonomyFilter",
          isPerc: false,
        },
      ];

      listObj["newList"] = arr;
    } else {
      return;
    }
  }, []);

  const addNewHighNewLowFilter = useCallback((value, listObj) => {
    if (value.value && value.value !== "None") {
      let updatedList = [...listObj["newList"]];
      updatedList = updatedList.filter((item) => item.field !== value.field);
      updatedList.push(value);
      listObj["newList"] = updatedList;
    }
  }, []);

  const removeNewHighNewLowFilter = useCallback((value, listObj) => {
    if (value.value && value.value !== "None") {
      const updatedList = [...listObj["newList"]];

      listObj["newList"] = updatedList.filter(
        (item) => item.field !== value.field
      );
    }
  }, []);

  const removeMinMaxFilter = useCallback((value, listObj) => {
    listObj["newList"] = listObj["newList"].filter(
      (constraint) => constraint.field !== value.field
    );
  }, []);

  const removeTaxonomyFilter = useCallback((value, listObj) => {
    const id = value.value;
    listObj["newList"] = listObj["newList"].filter((item) => item.value !== id);
  }, []);

  const updateMinMaxFilter = useCallback(
    (value, listObj) => {
      const { newList } = listObj;
      // Replace always the old constraint with the new even if the analytic is not the same
      listObj["newList"] = newList.filter(
        (constraint) => constraint.field !== editTarget
      );

      if ("max" in value.value && value.value.max == null) {
        delete value.value.max;
      }

      if ("min" in value.value && value.value.min == null) {
        delete value.value.min;
      }

      listObj["newList"] = [...listObj["newList"], value];
      setEditTarget(null);
    },
    [editTarget]
  );

  const updateTaxonomyFilter = useCallback((value, listObj) => {
    const { newList } = listObj;

    listObj["newList"] = [
      ...newList.filter((item) => item.field !== value.field),
      {
        label: value.label,
        value: value.value.segments,
        field: "domicile",
        widget: "taxonomyFilter",
        isPerc: false,
      },
    ];
    setEditTarget(null);
  }, []);

  const updateNewHighNewLowFilter = useCallback(
    (value, listObj) => {
      if (value.value && value.value !== "None") {
        let updatedList = [...listObj["newList"]];
        updatedList = updatedList.filter(
          (item) => item.field !== value.field && item.field !== editTarget
        );
        updatedList.push(value);
        listObj["newList"] = updatedList;
      }
    },
    [editTarget]
  );

  const dataHandlers = useMemo(
    () => ({
      minMax: {
        add: addMinMaxFilter,
        update: updateMinMaxFilter,
        remove: removeMinMaxFilter,
      },
      newHighNewLow: {
        add: addNewHighNewLowFilter,
        update: updateNewHighNewLowFilter,
        remove: removeNewHighNewLowFilter,
      },
      taxonomyFilter: {
        add: addTaxonomyFilter,
        update: updateTaxonomyFilter,
        remove: removeTaxonomyFilter,
      },
    }),
    [
      addMinMaxFilter,
      addNewHighNewLowFilter,
      addTaxonomyFilter,
      removeMinMaxFilter,
      removeNewHighNewLowFilter,
      removeTaxonomyFilter,
      updateMinMaxFilter,
      updateNewHighNewLowFilter,
      updateTaxonomyFilter,
    ]
  );

  const updateConstraintsList = useCallback(
    (
      action: "add" | "remove" | "update",
      value: {
        field: string;
        value: any;
        label: string;
        editorType: string;
        widget: string;
      }
    ) => {
      if (!value || !value.value) {
        setEditor(null);
        return;
      }

      const activeConstraints = context?.fundamentalsConstraints ?? [];

      // Use an object instead directly the array because array's cannot be passed as reference into
      // functions so the changes on them cannot be done.
      const updatedList = { newList: [...activeConstraints] };

      const widgetType = rawProperties?.[value.field]?.input?.widget ?? null;

      if (!widgetType) {
        return;
      }

      dataHandlers[widgetType][action](value, updatedList);

      context?.updateFundamentalsConstraints(updatedList.newList);
      updateTableColumns(updatedList.newList);
      setEditor(null);
    },
    [context, dataHandlers, rawProperties, updateTableColumns]
  );

  const selectFundamental = useCallback(
    (e, property) => {
      if (property.field === editTarget) {
        setAnchorEl(null);
      }

      awayListenerStopLight.current = e.currentTarget;
      setAnchorEl(null);
      openEditor({
        label: property?.label ?? "",
        field: property?.field ?? "",
        inputParams: property.input,
        value: null,
      });
    },
    [editTarget, openEditor]
  );

  const handleApplyClick = useCallback(
    (value: any) => {
      const action = editTarget == null ? "add" : "update";
      updateConstraintsList(action, {
        field: editor?.field ?? "",
        label: editor?.label ?? "",
        value,
        editorType: editor?.inputParams?.type ?? "number",
        widget: editor?.inputParams?.widget ?? "",
      });
    },
    [
      editTarget,
      editor?.field,
      editor?.inputParams,
      editor?.label,
      updateConstraintsList,
    ]
  );

  const removeActiveConstraint = useCallback(
    (
      e,
      constraint: {
        field: string;
        value: any;
        label: string;
        editorType: string;
        widget: string;
      }
    ) => {
      e.stopPropagation();
      updateConstraintsList("remove", constraint);
    },
    [updateConstraintsList]
  );

  const handleCancelClick = useCallback(() => {
    setEditTarget(null);
    setEditor(null);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = open ? "screener-filter-popover" : undefined;
  const awayListenerStopLight = useRef<HTMLLIElement | null>(null);

  const handleAwayClick = useCallback(() => {
    // Does not trigger the away click listener on the list item click in the popover.
    // The reason behind this intricated network of ref is that the MUI popover uses portal
    // so whe you click on the list item the current target of the event is the #document
    // because portal carry the node outside the DOM. So to avoid the editor close when we click a list item
    // we need to store the reference of the list and the list item in a ref object.
    if (awayListenerStopLight.current && popoverRef.current) {
      if (popoverRef.current.contains(awayListenerStopLight.current)) {
        return;
      }
    } else {
      handleCancelClick();
    }
  }, [handleCancelClick]);

  const selectedValues = useMemo(() => {
    if (context?.fundamentalsConstraints) {
      return context?.fundamentalsConstraints;
    }

    return [];
  }, [context?.fundamentalsConstraints]);

  return (
    <Box display={"flex"}>
      <Box
        minWidth={0}
        flex={1}
        className={"tr_scrollbar_custom"}
        component={"ul"}
        display={"flex"}
        gap={0.5}
        mr={1}
        sx={{ overflowX: "auto", paddingBottom: "2px" }}
      >
        {selectedValues.map((constraint) => {
          if (constraint.widget === "minMax") {
            return (
              <ResultCardMinMax
                key={uuidv4()}
                constraint={constraint}
                editTarget={editTarget}
                editConstraint={editConstraint}
                removeActiveConstraint={removeActiveConstraint}
              />
            );
          }

          if (constraint.widget === "taxonomyFilter") {
            return (
              <ResultCardDomicileFilter
                key={uuidv4()}
                taxonomyType="security"
                editTarget={editTarget}
                editConstraint={editConstraint}
                removeActiveConstraint={removeActiveConstraint}
                constraint={constraint}
              />
            );
          }

          if (constraint.widget === "newHighNewLow") {
            return (
              <ResultNewHighNewLowCard
                key={uuidv4()}
                constraint={constraint}
                editTarget={editTarget}
                editConstraint={editConstraint}
                removeActiveConstraint={removeActiveConstraint}
              />
            );
          }

          return <></>;
        })}
      </Box>
      <Box mr={1} display={"flex"}>
        {!Boolean(editor) && (
          <Box alignSelf={"center"}>
            <Button
              onClick={handleClick}
              variant="contained"
              startIcon={<AddIcon />}
              size={"small"}
              sx={{
                height: "auto",
                paddingY: "2px!important",
              }}
            >
              Add filter
            </Button>
          </Box>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {list.map((group) => {
            return (
              <Box
                key={uuidv4()}
                display={"flex"}
                p={1}
                flexDirection={"column"}
              >
                <Typography sx={{ textTransform: "capitalize" }}>
                  <strong>{group?.[0]?.tag ?? ""}</strong>
                </Typography>
                <ul ref={popoverRef}>
                  {group.map((property) => (
                    <li
                      onClick={(e) => selectFundamental(e, property)}
                      className={`${styles["popover-list-item"]} ${
                        property.field === editTarget
                          ? styles["isInputDisabled"]
                          : ""
                      }`}
                      key={uuidv4()}
                    >
                      {property?.label ?? ""}
                    </li>
                  ))}
                </ul>
              </Box>
            );
          })}
        </Popover>
      </Box>
      {Boolean(editor) && (
        <>
          {(editor?.inputParams?.widget === "minMax" ||
            editor?.inputParams?.widget === "newHighNewLow") && (
            <IntervalEditor
              editor={editor}
              handleAwayClick={handleAwayClick}
              handleClick={handleClick}
              handleApplyClick={handleApplyClick}
            />
          )}
          {editor?.inputParams?.widget === "taxonomyFilter" && (
            <DomicileEditor
              handleAwayClick={handleAwayClick}
              handleApplyClick={handleApplyClick}
            />
          )}
        </>
      )}
    </Box>
  );
}

const ResultCardDomicileFilter = ({
  constraint,
  editConstraint,
  removeActiveConstraint,
  taxonomyType,
  editTarget,
}: ResultCardTaxonomyFilterProps) => {
  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  const fieldsMap = useMemo(
    () => environment.get("taxonomyFields"),
    [environment]
  );

  const labels = useMemo(() => {
    if (constraint) {
      if (constraint?.value?.length <= 3) {
        return constraint.value.map(
          (cnt) =>
            taxonomies?.[fieldsMap?.[taxonomyType]?.[constraint?.field]]?.[cnt]
              ?.name ?? ""
        );
      } else {
        return ["some domiciles"];
      }
    } else {
      return ["Any"];
    }
  }, [constraint, fieldsMap, taxonomies, taxonomyType]);

  return constraint.value.length && editTarget !== "domicile" ? (
    <Card
      onClick={() =>
        editConstraint({
          field: "domicile",
          value: constraint.value,
          label: "Domicile",
          editorType: "",
          widget: "taxonomyFilter",
        })
      }
      sx={{
        position: "relative",
        border: "1px solid",
        borderColor: "transparent",
        transition: "0.5s",
        cursor: "pointer",
        display: "flex",
        flex: 1,
        "&:hover": {
          borderColor: "#2a7090",
        },
      }}
      title={"Domicile"}
    >
      <CloseIcon
        onClick={(e) => removeActiveConstraint(e, constraint)}
        sx={{
          fontSize: "14px",
          position: "absolute",
          right: "2px",
          top: "2px",
          cursor: "pointer",
          color: "black",
        }}
      />
      <CardContent
        sx={{
          padding: "2px 6px!important",
          display: "flex",
          flex: 1,
        }}
      >
        <Box
          display={"flex"}
          flex={1}
          flexDirection={"column"}
          textAlign={"center"}
        >
          <Typography mr={"16px"}>
            <strong>{"Domicile"}</strong>
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            flex={1}
          >
            {labels.map((label) => (
              <Typography>{label}</Typography>
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

const ResultCardMinMax = ({
  constraint,
  editTarget,
  editConstraint,
  removeActiveConstraint,
}: ResultCardMinMaxProps) => {
  const formatter = useFormatter();
  const getValue = useCallback(
    (
      editorValue: string | { max: number | null; min: number | null } | null,
      editorType: string
    ) => {
      if (!editorValue) {
        return "";
      }

      if (typeof editorValue === "string") {
        return editorValue;
      }

      if ("min" in editorValue && "max" in editorValue) {
        if (editorValue.max && editorValue.min == null) {
          if (editorType === "perc") {
            const valueFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: editorValue.max,
            });

            return `Less than ${valueFormatted}`;
          } else if (editorType === "perc100") {
            const valueFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                multiplyBy100: false,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: editorValue.max,
            });

            return `Less than ${valueFormatted}`;
          } else {
            return `Less than ${editorValue.max}`;
          }
        }

        if (editorValue.max == null && editorValue.min) {
          if (editorType === "perc") {
            const valueFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: editorValue.min,
            });

            return `Greater than ${valueFormatted}`;
          } else if (editorType === "perc100") {
            const valueFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                multiplyBy100: false,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: editorValue.min,
            });

            return `Greater than ${valueFormatted}`;
          } else {
            return `Greater than ${editorValue.min}`;
          }
        }

        if (editorType === "perc") {
          const minFormatted = formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: editorValue.min,
          });
          const maxFormatted = formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: editorValue.max,
          });

          return `Min ${minFormatted} - Max ${maxFormatted}`;
        } else if (editorType === "perc100") {
          const minFormatted = formatter.custom("number", {
            options: {
              isPercentage: true,
              multiplyBy100: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: editorValue.min,
          });
          const maxFormatted = formatter.custom("number", {
            options: {
              isPercentage: true,
              multiplyBy100: false,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "TEXT",
            value: editorValue.max,
          });

          return `Min ${minFormatted} - Max ${maxFormatted}`;
        } else {
          return `Min ${editorValue.min} - Max ${editorValue.max}`;
        }
      }

      if (editorType !== "perc" && editorType !== "perc100") {
        if ("min" in editorValue && (editorValue as any).min != null) {
          return `Greater than ${(editorValue as any).min}`;
        } else if ((editorValue as any).max != null) {
          return `Less than ${(editorValue as any).max}`;
        }
      } else {
        if (editorType === "perc") {
          if ("min" in editorValue && (editorValue as any).min != null) {
            const minFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: (editorValue as any).min,
            });

            return `Greater than ${minFormatted}`;
          } else if ((editorValue as any).max != null) {
            const maxFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: (editorValue as any).max,
            });

            return `Less than ${maxFormatted}`;
          }
        } else if (editorType === "perc100") {
          if ("min" in editorValue && (editorValue as any).min != null) {
            const minFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                multiplyBy100: false,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: (editorValue as any).min,
            });

            return `Greater than ${minFormatted}`;
          } else if ((editorValue as any).max != null) {
            const maxFormatted = formatter.custom("number", {
              options: {
                isPercentage: true,
                multiplyBy100: false,
                notAvailable: {
                  input: null,
                  output: "",
                },
              },
              output: "TEXT",
              value: (editorValue as any).max,
            });

            return `Less than ${maxFormatted}`;
          }
        }
      }
    },
    [formatter]
  );

  return (
    <Box
      component={"li"}
      key={uuidv4()}
      whiteSpace={"nowrap"}
      flex={1}
      display={"flex"}
    >
      {constraint.field !== editTarget && (
        <Card
          onClick={() => editConstraint(constraint)}
          sx={{
            flex: 1,
            display: "flex",
            position: "relative",
            border: "1px solid",
            borderColor: "transparent",
            transition: "0.5s",
            cursor: "pointer",
            "&:hover": {
              borderColor: "#2a7090",
            },
          }}
          title={constraint.label}
        >
          <CloseIcon
            onClick={(e) => removeActiveConstraint(e, constraint)}
            sx={{
              fontSize: "14px",
              position: "absolute",
              right: "2px",
              top: "2px",
              cursor: "pointer",
              color: "black",
            }}
          />
          <CardContent
            sx={{
              padding: "2px 6px!important",
              display: "flex",
              flex: 1,
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              textAlign={"center"}
              flex={1}
            >
              <Typography mr={2}>
                <strong>{constraint.label}</strong>
              </Typography>
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>
                  {getValue(constraint.value, constraint.editorType)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

const ResultNewHighNewLowCard = ({
  constraint,
  editTarget,
  editConstraint,
  removeActiveConstraint,
}: ResultNewHighNewLowCardProps) => {
  const options = useMemo(() => FormOptions.get("HIGH_LOW"), []);
  const value = useMemo(() => {
    if (constraint.value) {
      return (
        options.find((item) => item.value === constraint.value)?.[
          "labelShort"
        ] ?? ""
      );
    }

    return "None";
  }, [constraint.value, options]);

  return (
    <Box
      component={"li"}
      display={"flex"}
      flex={1}
      key={uuidv4()}
      whiteSpace={"nowrap"}
    >
      {constraint.field !== editTarget && (
        <Card
          onClick={() => editConstraint(constraint)}
          sx={{
            position: "relative",
            border: "1px solid",
            borderColor: "transparent",
            transition: "0.5s",
            display: "flex",
            flex: 1,
            cursor: "pointer",
            "&:hover": {
              borderColor: "#2a7090",
            },
          }}
          title={`Remove ${constraint.label}`}
        >
          <CloseIcon
            onClick={(e) => removeActiveConstraint(e, constraint)}
            sx={{
              fontSize: "14px",
              position: "absolute",
              right: "2px",
              top: "2px",
              cursor: "pointer",
              color: "black",
            }}
          />
          <CardContent
            sx={{
              padding: "2px 6px!important",
              display: "flex",
              flex: 1,
            }}
          >
            <Box
              display={"flex"}
              flex={1}
              flexDirection={"column"}
              textAlign={"center"}
            >
              <Typography mr={2}>
                <strong>{constraint.label}</strong>
              </Typography>
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography>{value}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

const DomicileEditor = ({
  handleApplyClick,
  handleAwayClick,
}: DomicileEditorProps) => {
  const context = useContext(FiltersContext);
  const selectedValues = useMemo(() => {
    if (context && context.fundamentalsConstraints) {
      const value: any = context.fundamentalsConstraints.filter(
        (item) => item.field === "domicile"
      );

      return value.reduce((prev, current) => [...prev, ...current.value], []);
    } else {
      return [];
    }
  }, [context]);
  const taxonomy = useMemo(() => ({ type: "security", field: "domicile" }), []);
  const { advancedNodesSelector } = useTaxonomyByType("stock");

  const handleSelection = useCallback(
    (values) => {
      if (values) {
        const domicileObject = values?.[0]?.value ?? null;

        if (domicileObject) {
          const segments = domicileObject?.segments ?? [];

          if (segments.length) {
            const selectedNodes = advancedNodesSelector(
              segments,
              taxonomy.field,
              true
            );

            handleApplyClick({ taxonomy, segments: selectedNodes });
          }
        }
      }
    },
    [advancedNodesSelector, handleApplyClick, taxonomy]
  );

  return (
    <ClickAwayListener onClickAway={handleAwayClick}>
      <Box display={"flex"}>
        <FilterTaxonomies
          editorCloseFn={handleAwayClick}
          showIconCancel={false}
          taxonomyToRender={taxonomy}
          updateFilters={handleSelection}
          filtersValues={selectedValues ?? []}
          selectedFilters={["Select a domicile"]}
          label={"Domicile"}
        />
      </Box>
    </ClickAwayListener>
  );
};

const IntervalEditor = ({
  editor,
  handleAwayClick,
  handleClick,
  handleApplyClick,
}: IntervalEditorProps) => {
  const [min, setMin] = useState<number | null>();
  const [max, setMax] = useState<number | null>();
  const [hl, setHl] = useState(
    editor?.value ?? editor?.field === "lhigh" ? "20" : "-20"
  );
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (editor?.field !== "lhigh" && editor?.field !== "llow") {
      if (min != null || max != null) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(false);
    }
  }, [editor?.field, max, min]);

  const optionsHL = useMemo(
    () => ({
      lhigh: [
        {
          label: "1 month new high",
          value: "20",
          labelShort: "1 month",
        },
        {
          label: "3 months new high",
          value: "60",
          labelShort: "3 months",
        },
        {
          label: "6 months new high",
          value: "120",
          labelShort: "6 months",
        },
        {
          label: "12 months new high",
          value: "260",
          labelShort: "12 months",
        },
      ],
      llow: [
        {
          label: "1 month new low",
          value: "-20",
          labelShort: "1 month",
        },
        {
          label: "3 months new low",
          value: "-60",
          labelShort: "3 months",
        },
        {
          label: "6 months new low",
          value: "-120",
          labelShort: "6 months",
        },
        {
          label: "12 months new low",
          value: "-260",
          labelShort: "12 months",
        },
      ],
    }),
    []
  );
  const [stopAwayListener, setStopAwayListener] = useState(false);
  const filterValue = useMemo(() => {
    if (editor?.inputParams?.widget === "minMax") {
      return { min, max };
    } else {
      return hl;
    }
  }, [editor?.inputParams?.widget, hl, max, min]);

  useEffect(() => {
    if (editor == null) {
      setMin(null);
      setMax(null);
    }
  }, [editor]);

  const awayListener = useCallback(() => {
    if (!stopAwayListener) {
      handleAwayClick();
    }
  }, [handleAwayClick, stopAwayListener]);

  return (
    <ClickAwayListener onClickAway={awayListener}>
      <Box
        ml={1}
        display={"flex"}
        gap={1}
        alignItems={"center"}
        bgcolor={"white"}
        py={0.5}
        px={1}
        paddingRight={"20px!important"}
        borderRadius={"4px"}
        boxShadow={2}
        position={"relative"}
      >
        <CloseIcon
          onClick={awayListener}
          sx={{
            position: "absolute",
            top: "2px",
            fontSize: "0.8vw",
            right: "5px",
            cursor: "pointer",
          }}
        />
        <Typography
          sx={{
            cursor: "pointer",
            "&:hover": { color: "#2a7090" },
          }}
          onClick={handleClick}
        >
          {editor?.label ?? ""}
        </Typography>
        {editor?.inputParams?.widget === "minMax" ? (
          <>
            {editor?.inputParams.type === "perc100" ? (
              <>
                {/* CUSTOM EDITOR FOR VOLATILITY FIELD (sd) */}
                <Box>
                  <InputNumber
                    decimals={editor?.inputParams.decimals}
                    size={"tr_small"}
                    disableGutters={true}
                    constraints={{
                      minVal: editor?.inputParams?.min ?? undefined,
                      maxVal: editor?.inputParams?.max ?? undefined,
                    }}
                    isPercentage={true}
                    multiplyBy100={false}
                    isFloating={true}
                    initVal={
                      editor && "value" in editor
                        ? (editor as any)?.value?.min
                        : undefined
                    }
                    maxWidth={"5vw"}
                    setOuterState={(value) => setMin(value)}
                  />
                </Box>
                -
                <Box>
                  <InputNumber
                    decimals={editor?.inputParams.decimals}
                    constraints={{
                      minVal: editor?.inputParams?.min ?? undefined,
                      maxVal: editor?.inputParams?.max ?? undefined,
                    }}
                    disableGutters={true}
                    size={"tr_small"}
                    initVal={
                      editor && "value" in editor
                        ? (editor as any)?.value?.max
                        : undefined
                    }
                    isPercentage={true}
                    multiplyBy100={false}
                    maxWidth={"5vw"}
                    isFloating={Boolean(
                      editor?.inputParams.decimals &&
                        editor?.inputParams.decimals !== 0
                    )}
                    setOuterState={(value) => setMax(value)}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <InputNumber
                    decimals={editor?.inputParams.decimals}
                    size={"tr_small"}
                    disableGutters={true}
                    constraints={{
                      minVal: editor?.inputParams?.min ?? undefined,
                      maxVal: editor?.inputParams?.max ?? undefined,
                    }}
                    isPercentage={editor?.inputParams.type === "perc" ?? false}
                    isFloating={Boolean(
                      editor?.inputParams.decimals &&
                        editor?.inputParams.decimals !== 0
                    )}
                    initVal={
                      editor && "value" in editor
                        ? (editor as any)?.value?.min
                        : undefined
                    }
                    maxWidth={"5vw"}
                    setOuterState={(value) => setMin(value)}
                  />
                </Box>
                -
                <Box>
                  <InputNumber
                    decimals={editor?.inputParams.decimals}
                    constraints={{
                      minVal: editor?.inputParams?.min ?? undefined,
                      maxVal: editor?.inputParams?.max ?? undefined,
                    }}
                    disableGutters={true}
                    size={"tr_small"}
                    initVal={
                      editor && "value" in editor
                        ? (editor as any)?.value?.max
                        : undefined
                    }
                    isPercentage={editor?.inputParams.type === "perc" ?? false}
                    maxWidth={"5vw"}
                    isFloating={Boolean(
                      editor?.inputParams.decimals &&
                        editor?.inputParams.decimals !== 0
                    )}
                    setOuterState={(value) => setMax(value)}
                  />
                </Box>
              </>
            )}
          </>
        ) : (
          <OptionsFilter
            value={hl ?? "None"}
            fullWidth={false}
            setValue={setHl}
            widgetParams={{
              options: optionsHL[editor?.field ?? "lhigh"],
            }}
            onFocus={(e) => {
              setStopAwayListener(true);
            }}
            onBlur={(e) => {
              setStopAwayListener(false);
            }}
          />
        )}
        <Box display={"flex"} gap={1}>
          <Button
            onClick={() => handleApplyClick(filterValue)}
            variant="tr_button_cancel"
            size={"small"}
            disabled={isDisabled}
            sx={{
              height: "auto",
              paddingY: "2px!important",
            }}
          >
            Apply
          </Button>
          {/* //? POSSIBLE ENHANCEMENT */}
          {/* This cancel button works correctly as future enhancement we can use make it available
           * By using a props */}

          {/* <Button
                            onClick={handleCancelClick}
                            variant="tr_button_cancel"
                            size={"small"}
                            sx={{ height: "auto", paddingY: "2px!important" }}
                        >
                            Cancel
                        </Button> */}

          {/* //? -------------------- */}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
