import {
  Box,
  Button,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import styles from "./AnalisysSecurity.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PeerSize } from "../../../../components/PeerSize/PeerSize";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../trendrating/app/formatter/FormOptions";
import { Storage } from "./Storage";
import { httpAll } from "../../../../httpAll";
import { deepClone } from "../../../../deepClone";
import { useTaxon } from "../../../../hooks/useTaxon";
import { messageError } from "../../utils";
import { useFormatter } from "../../../../hooks/useFormatter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { v4 as uuidv4 } from "uuid";
import { handleTitle } from "../../../../Utility/DocumentTitleHanlder";
import { ChartPeerPerformance } from "./components/ChartPeerPerformance";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { useBroadcast } from "../../../../hooks/useBroadcast";

type TabRankProps = {
  value: any;
};

export function TabRank({ value }: TabRankProps) {
  const [rankingTemplateId, setRankingTemplateId] = useState<any>(null);
  const [whatWhereOptions, setWhatWhereOptions] = useState<any>([]);
  const [whatWhereValue, setWhatWhereValue] = useState<any>([]);
  const [peerSizeConstraints, setPeerSizeConstraints] = useState<any>();
  const [peerSizeValue, setPeerSizeValue] = useState<any>();
  const [data, setData] = useState<any>();
  const [rcView, setRcView] = useState<"weights" | "holdings">("weights");
  const [rankingTemplates, setRankingTemplates] = useState<any>();

  const { t } = useTranslation();
  const environment = useEnvironment();
  const format = useFormatter();

  const taxon = useTaxon();

  const storage = useMemo(
    () => new Storage(environment.get("setup")),
    [environment]
  );

  const showNodeCommonStockInfo = useMemo(() => {
    // Show a warning if the instrument is a Stock but is not a "Common Stock" (stockclass STOCK)
    return value["type"] === "Stock" && value["stockclass"] !== "STOCK";
  }, [value]);

  const name = useMemo(() => {
    let innerHTML = "";

    if (value != null) {
      if (value["ticker"] != null) {
        innerHTML = "<strong>" + value["ticker"] + "</strong> ";
      }
      if (value["type"] === "ETF") {
        innerHTML += t("c_in_segment");
      } else {
        innerHTML += t("c_in");
      }
    }

    return innerHTML;
  }, [t, value]);

  const showPeerSizeWidget = useMemo(() => {
    let show = false;

    if (value["type"] === "Stock") {
      if (value["marketcap"] == null) {
        show = false;
      } else {
        show = true;
      }
    } else {
      show = false;
    }

    return show;
  }, [value]);

  const sizeOpts = useMemo(
    () => new FormOptions(environment.get("formatter")).getField("SIZE"),
    [environment]
  );

  const peerName = useMemo(() => {
    const peer = data?.["peer"];

    if (peer != null) {
      return peer["name"];
    }

    return "";
  }, [data]);

  const formatNumber = useCallback(
    (value) => {
      return format.custom("number", {
        options: {
          decimals: 0,
          hasPositiveSign: false,
          isPercentage: false,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [format]
  );

  const formatPercent = useCallback(
    (value) => {
      return format.custom("number", {
        options: {
          decimals: 2,
          hasPositiveSign: false,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "0.00%",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [format]
  );

  const formatPerformance = useCallback(
    (value) => {
      return format.custom("number", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [format]
  );

  const rcDistributions = useMemo(() => {
    const prototype = {
      A: "-",
      B: "-",
      C: "-",
      D: "_",
    };

    const peer = data?.["peer"];

    if (peer != null) {
      const ratingStatistics = peer["statistic"]["ratings"]["today"];

      if (rcView === "holdings") {
        prototype.A = formatNumber(ratingStatistics["A"]["number"]);
        prototype.B = formatNumber(ratingStatistics["B"]["number"]);
        prototype.C = formatNumber(ratingStatistics["C"]["number"]);
        prototype.D = formatNumber(ratingStatistics["D"]["number"]);
      } else {
        prototype.A = formatPercent(ratingStatistics["A"]["percentage"]);
        prototype.B = formatPercent(ratingStatistics["B"]["percentage"]);
        prototype.C = formatPercent(ratingStatistics["C"]["percentage"]);
        prototype.D = formatPercent(ratingStatistics["D"]["percentage"]);
      }

      return prototype;
    }

    return prototype;
  }, [data, formatNumber, formatPercent, rcView]);

  const nodeTCR = useMemo(() => {
    const peer = data?.["peer"];

    if (peer != null) {
      return format.tcr(peer?.["tcr"]?.["today"], "HTML");
    }

    return "";
  }, [data, format]);

  const nodePerformanceTicker = useMemo(() => {
    if (value) {
      return format.html(
        "ticker",
        "ticker",
        value["ticker"],
        null,
        value["type"]
      );
    }

    return "";
  }, [format, value]);

  const nodePerformancePw = useMemo(() => {
    if (value) {
      return format.html(
        "pw",
        "performance_week",
        value["pw"],
        null,
        value["type"]
      );
    }

    return undefined;
  }, [format, value]);

  const nodePerformancePwLowHigh = useMemo(() => {
    const peer = data?.["peer"];
    const quantiles = data?.["quantiles"];

    if (peer != null && quantiles != null) {
      if (peer["statistic"]["quantiles"]["pw"] != null) {
        return {
          nodePerformancePwLow: formatPerformance(quantiles["pw"]["min"]),
          nodePerformancePwHigh: formatPerformance(quantiles["pw"]["max"]),
        };
      }
    }

    return undefined;
  }, [data, formatPerformance]);

  const nodePerformancePm = useMemo(() => {
    if (value) {
      return format.html(
        "pm",
        "performance_month",
        value["pm"],
        null,
        value["type"]
      );
    }

    return undefined;
  }, [format, value]);

  const nodePerformancePmLowHigh = useMemo(() => {
    const peer = data?.["peer"];
    const quantiles = data?.["quantiles"];

    if (peer != null && quantiles != null) {
      if (peer["statistic"]["quantiles"]["pm"] != null) {
        return {
          nodePerformancePmLow: formatPerformance(quantiles["pm"]["min"]),
          nodePerformancePmHigh: formatPerformance(quantiles["pm"]["max"]),
        };
      }
    }

    return undefined;
  }, [data, formatPerformance]);

  const nodePerformancePq = useMemo(() => {
    if (value) {
      return format.html(
        "pq",
        "performance_3_months",
        value["pq"],
        null,
        value["type"]
      );
    }

    return undefined;
  }, [format, value]);

  const nodePerformancePqLowHigh = useMemo(() => {
    const peer = data?.["peer"];
    const quantiles = data?.["quantiles"];

    if (peer != null && quantiles != null) {
      if (peer["statistic"]["quantiles"]["pq"] != null) {
        return {
          nodePerformancePqLow: formatPerformance(quantiles["pq"]["min"]),
          nodePerformancePqHigh: formatPerformance(quantiles["pq"]["max"]),
        };
      }
    }

    return undefined;
  }, [data, formatPerformance]);

  const nodePerformancePy = useMemo(() => {
    if (value) {
      return format.html(
        "py",
        "performance_12_months",
        value["py"],
        null,
        value["type"]
      );
    }

    return undefined;
  }, [format, value]);

  const nodePerformancePyLowHigh = useMemo(() => {
    const peer = data?.["peer"];
    const quantiles = data?.["quantiles"];

    if (peer != null && quantiles != null) {
      if (peer["statistic"]["quantiles"]["py"] != null) {
        return {
          nodePerformancePyLow: formatPerformance(quantiles["py"]["min"]),
          nodePerformancePyHigh: formatPerformance(quantiles["py"]["max"]),
        };
      }
    }

    return undefined;
  }, [data, formatPerformance]);

  const productConfiguration = useMemo(() => {
    const setup = environment.get("setup");

    const product = setup?.account?.product?.configuration;

    return product;
  }, [environment]);

  const nodePeerRankIndex = useMemo(() => {
    const rank = data?.["rank"];

    if (rank) {
      return parseInt(rank["rank"], 10) + 1;
    }

    return "";
  }, [data]);
  const nodePeerRankTotal = useMemo(() => {
    const rank = data?.["rank"];

    if (rank) {
      return rank["total"];
    }

    return "";
  }, [data]);

  const fixPeerSize = useCallback((value) => {
    if (value == null) {
      return "microLarge";
    }

    if (value === "microLarge") {
      return null;
    }

    return value;
  }, []);

  const dataPreparePeer = useCallback(
    (peer) => {
      var instrument = value;
      if (instrument != null && peer != null) {
        var instrumentType = instrument["type"];
        // #region ------------------------------------------  peer link
        switch (instrumentType) {
          case "Stock": {
            peer["link"] = `/analysis/markets/${peer["id"]}/`;

            break;
          }
          default: {
            peer["link"] = null;
          }
        }
        // #endregion --------------------------------------------------

        // #region ------------------------------------------ peer label
        var country: any = null;
        var sector: any = null;
        var sizeClassification: any = null;
        const taxonomies = environment.get("setup")["taxonomies"];
        const txFields = environment.get("setup")["taxonomyFields"];

        switch (instrumentType) {
          case "ETF": {
            if (peer["what"] != null) {
              sector = taxonomies[txFields["ETF"]["etfclass"]][peer["what"]];
            }

            if (peer["where"] != null) {
              country = taxonomies[txFields["ETF"]["etfgeo"]][peer["where"]];
            }

            break;
          }
          case "Commodity":
          case "Currency":
          case "Index":
          case "Sector":
          case "Stock": {
            if (peer["what"] != null) {
              sector = taxonomies[txFields["security"]["sector"]][peer["what"]];
            }

            if (peer["where"] != null) {
              country =
                taxonomies[txFields["security"]["country"]][peer["where"]];
            }

            break;
          }
          default: {
          }
        }

        if (peer["size"] != null) {
          sizeClassification = taxonomies["SizeClassification"][peer["size"]];
        }

        var labelPeer: any = [];
        peer["peerSize"] = null;
        switch (instrumentType) {
          case "Stock": {
            if (peer["where"] && peer["what"]) {
              if (parseInt(sector["type"].charAt(0), 10) > 0) {
                labelPeer.push(
                  "<strong>" +
                    country["name"] +
                    "</strong> - <strong>" +
                    sector["name"] +
                    "</strong>"
                );
              } else {
                labelPeer.push("<strong>" + country["name"] + "</strong>");
              }
            }
            if (!peer["where"] && peer["what"]) {
              labelPeer.push(sector["name"]);
            }
            if (peer["where"] && !peer["what"]) {
              labelPeer.push(country["name"]);
            }
            if (peer["size"] != null) {
              peer["peerSize"] = sizeClassification;
            }

            break;
          }
          case "ETF": {
            var assetClass = "";
            if (peer["what"] != null) {
              if (parseInt(sector["type"].charAt(0), 10) > 1) {
                var ancestor = taxon.getAncestorByLevel(
                  taxonomies[txFields["ETF"]["etfclass"]],
                  peer["what"],
                  "1"
                );
                if (ancestor != null) {
                  var ancestorData =
                    taxonomies[txFields["ETF"]["etfclass"]][ancestor];
                  assetClass = ancestorData["name"] + " : "; // Add space
                }
                assetClass += sector["name"];
              } else {
                assetClass = sector["name"];
              }
            }
            if (peer["where"] && peer["what"]) {
              labelPeer.push(
                "<strong>" +
                  assetClass +
                  "</strong> - <strong>" +
                  country["name"] +
                  "</strong>"
              );
            }
            if (!peer["where"] && peer["what"]) {
              labelPeer.push(assetClass);
            }
            if (peer["where"] && !peer["what"]) {
              labelPeer.push(country["name"]);
            }

            break;
          }
          case "Commodity":
          case "Currency":
          case "Index":
          case "Sector":
          default: {
            if (peer["where"]) {
              labelPeer.push(
                "<strong>" +
                  t("instrument_" + instrumentType.toLowerCase() + "_plural") +
                  " in " +
                  country["name"] +
                  "</strong>"
              );
            } else {
              labelPeer.push(
                t("instrument_" + instrumentType.toLowerCase() + "_plural")
              );
            }
          }
        }

        peer["name"] = labelPeer.join(" ");
      }
      // #endregion ------------------------------------------------------

      return peer;
    },
    [environment, t, taxon, value]
  );

  const { broadcast } = useBroadcast();

  const dataPrepareRank = useCallback(
    (rank) => {
      var instrument = value;
      var preparedRank: any = {
        items: null,
        position: null,
        rank: null,
        ranked: {
          top: null,
          near: null,
          worst: null,
        },
        symbol: null,
        total: null,
      };
      if (instrument != null) {
        var symbol = instrument["symbol"];
        preparedRank["symbol"] = symbol;
        if (rank != null) {
          preparedRank["total"] = rank["total"];
          // Find actual ranking
          var position = -1;
          for (let i = 0; i < rank.length; i++) {
            if (symbol === rank[i]["symbol"]) {
              // Never use that: position = parseInt(rank[i]["rank"], 10); it is wrong when there are multiple shared ids
              position = i; // Ignore rank because when ranking groups ids it does give an erroneous position
              break;
            }
          }
          if (position === -1) {
            var message =
              "Symbol " +
              symbol +
              " does not exists in rank result. Wrong query or bug.";
            const [channel, msg] = messageError(message);
            broadcast(channel as string, msg);
          }

          preparedRank["position"] = position;
          preparedRank["rank"] = rank[position]["rank"];

          // Prepare first, near, worst ranked
          preparedRank["ranked"]["top"] = rank.slice(0, 3);
          preparedRank["ranked"]["worst"] = rank.slice(rank.length - 3);
          if (rank.length > 6) {
            // Must be at least 7 element to have a near rank
            if (position >= 3) {
              if (position <= rank.length - 4) {
                preparedRank["ranked"]["near"] = [
                  rank[position - 1],
                  rank[position],
                  rank[position + 1],
                ];
              } else {
                // Already part of worst
              }
            } else {
              // Already part of top
            }
          }
        }
      }
      return preparedRank;
    },
    [broadcast, value]
  );

  const dataPrepareQuantiles = useCallback((quantiles) => {
    var preparedQuantiles = {};
    var timeframes = ["pw", "pm", "pq", "py"];
    for (let i = 0; i < timeframes.length; i++) {
      var timeframe = timeframes[i];
      var quantilesByTimeframe = quantiles[timeframe];
      if (quantilesByTimeframe != null) {
        preparedQuantiles[timeframe] = {};
        for (let j = 0; j < quantilesByTimeframe.length; j++) {
          preparedQuantiles[timeframe]["q" + j] = quantilesByTimeframe[j];

          if (j === 0) {
            preparedQuantiles[timeframe]["min"] = quantilesByTimeframe[j];
          }
          // Do not use if/elseif because j can be zero and can be length-1 = 0
          if (j === quantilesByTimeframe.length - 1) {
            preparedQuantiles[timeframe]["max"] = quantilesByTimeframe[j];
          }
        }
      }
    }
    return preparedQuantiles;
  }, []);

  const optionsWhatWhereBySector = useCallback((params) => {
    var peerMarkets = params["peerMarkets"];
    var peerSectors = params["peerSectors"];
    var marketTypes = ["Area", "Region", "Country"];
    var sectorTypes = ["1 Industry", "3 Sector"];
    var options: any = [];
    options.push({
      label: "Any Asset Class",
      value: "WWW-ICB",
    });
    if (peerMarkets != null) {
      for (let i = 0; i < marketTypes.length; i++) {
        var marketType = marketTypes[i];
        if (peerMarkets[marketType] != null) {
          options.push({
            label: peerMarkets[marketType]["name"],
            value: peerMarkets[marketType]["id"] + "-ICB",
          });
        }
      }
      if (peerSectors != null) {
        for (let i = 0; i < sectorTypes.length; i++) {
          var sectorType = sectorTypes[i];
          // Add header (simulate optgroup adding a separator then a option)
          options.push({
            type: "separator",
          });
          var sectorLabel = peerSectors[sectorType]["name"];
          if (parseInt(sectorType.charAt(0), 10) > 1) {
            if (peerSectors[sectorTypes[0]] != null) {
              sectorLabel =
                peerSectors[sectorTypes[0]]["name"] + " - " + sectorLabel;
            }
          }
          options.push({
            label: sectorLabel,
            value: "-" + peerSectors[sectorType]["id"],
          });
          // Add sectors
          for (let j = 0; j < marketTypes.length; j++) {
            const marketType = marketTypes[j];
            if (peerMarkets[marketType] != null) {
              options.push({
                label: peerMarkets[marketType]["name"],
                value:
                  peerMarkets[marketType]["id"] +
                  "-" +
                  peerSectors[sectorType]["id"],
              });
            }
          }
        }
      }
    }
    // Disable showing label near the select arrow, set an "empty" string (one space character to enable it)
    for (let i = 0; i < options.length; i++) {
      options[i]["labelLong"] = " ";
    }
    return options;
  }, []);

  const optionsWhatWhereByCountry = useCallback((params) => {
    var peerMarkets = params["peerMarkets"];
    var peerSectors = params["peerSectors"];
    var marketTypes = ["Area", "Region", "Country"];
    var sectorTypes = ["1 Industry", "3 Sector"];
    var options: any = [];
    options.push({
      label: "World Wide",
      value: "WWW-ICB",
    });
    if (peerSectors != null) {
      for (let j = 0; j < sectorTypes.length; j++) {
        var sectorType = sectorTypes[j];
        if (peerSectors[sectorType] != null) {
          options.push({
            label: peerSectors[sectorType]["name"],
            value: "WWW-" + peerSectors[sectorType]["id"],
          });
        }
      }
      if (peerMarkets != null) {
        for (let i = 0; i < marketTypes.length; i++) {
          var marketType = marketTypes[i];
          // Add header (simulate optgroup adding a separator then a option)
          options.push({
            type: "separator",
          });
          options.push({
            label: peerMarkets[marketType]["name"],
            value: peerMarkets[marketType]["id"] + "-ICB",
          });
          // Add sectors
          for (let j = 0; j < sectorTypes.length; j++) {
            const sectorType = sectorTypes[j];
            if (peerSectors[sectorType] != null) {
              options.push({
                label: peerSectors[sectorType]["name"],
                value:
                  peerMarkets[marketType]["id"] +
                  "-" +
                  peerSectors[sectorType]["id"],
              });
            }
          }
        }
      }
    }
    // Disable showing label near the select arrow, set an "empty" string (one space character to enable it)
    for (let i = 0; i < options.length; i++) {
      options[i]["labelLong"] = " ";
    }
    return options;
  }, []);

  const getRankingTemplates = useCallback(async () => {
    const response = await storage.rankingTemplates();

    const options = response["templates"].map(function (template) {
      return {
        label: template["name"],
        value: template["id"],
      };
    });

    setRankingTemplates(options);
  }, [storage]);

  const rankRows = useCallback(
    (key: "top" | "middle" | "worst") => {
      const rank = data?.["rank"];

      const LEVEL_DICT = {
        top: "top",
        middle: "near",
        worst: "worst",
      };

      const level = LEVEL_DICT[key];

      if (rank != null) {
        if (rank["ranked"][level] != null) {
          let ranked: any = null;
          const tableRows: any = [];

          for (let i = 0; i < rank["ranked"][level].length; i++) {
            ranked = rank["ranked"][level][i];

            tableRows.push(
              <tr
                key={uuidv4()}
                className={
                  ranked["symbol"] === rank["symbol"] ? "highlight" : ""
                }
              >
                <td className="align-left">{ranked["ticker"]}</td>
                <td className="align-left">{ranked["name"]}</td>
                <td>{parseInt(ranked["rank"], 10) + 1}</td>
              </tr>
            );
          }

          return tableRows;
        }
      }

      return [];
    },
    [data]
  );

  const topRankRows = useMemo(() => rankRows("top"), [rankRows]);
  const middleRankRows = useMemo(() => rankRows("middle"), [rankRows]);
  const bottomRankRows = useMemo(() => rankRows("worst"), [rankRows]);

  const renderWhatWhere = useCallback(
    (peer) => {
      var instrument = value;
      var params = {
        peerMarkets: {},
        peerSectors: {},
        peerSize: {},
      };
      const taxonomies = environment.get("setup")["taxonomies"];
      const txFields = environment.get("setup")["taxonomyFields"];
      var marketField = "country";
      switch (instrument["type"]) {
        case "Stock":
          params["peerSectors"] = {
            "3 Sector":
              taxonomies[txFields["security"]["industry"]][
                taxon.getAncestorAtLevel(
                  taxonomies[txFields["security"]["industry"]],
                  instrument["icb"],
                  "3 Sector"
                )
              ],
            "1 Industry":
              taxonomies[txFields["security"]["sector"]][
                taxon.getAncestorAtLevel(
                  taxonomies[txFields["security"]["sector"]],
                  instrument["icb"],
                  "1 Industry"
                )
              ],
          };
          break;
        case "ETF":
          marketField = "etfgeo";
          params["peerSectors"] = {
            "3 Sector":
              taxonomies[txFields["ETF"]["etfclass"]][
                taxon.getAncestorAtLevel(
                  taxonomies[txFields["ETF"]["etfclass"]],
                  instrument["etfclass"],
                  "3 Sector"
                )
              ],
            "1 Industry":
              taxonomies[txFields["ETF"]["etfclass"]][
                taxon.getAncestorAtLevel(
                  taxonomies[txFields["ETF"]["etfclass"]],
                  instrument["etfclass"],
                  "1 Industry"
                )
              ],
          };
          break;
        // no default
      }

      var peerMarketsCountry =
        instrument.type === "ETF"
          ? taxonomies[txFields["ETF"]["etfgeo"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["ETF"]["etfgeo"]],
                instrument[marketField],
                "Country"
              )
            ]
          : taxonomies[txFields["security"]["country"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["security"]["country"]],
                instrument[marketField],
                "Country"
              )
            ];
      if (peerMarketsCountry != null && peerMarketsCountry["id"] !== "WWW") {
        params["peerMarkets"]["Country"] = peerMarketsCountry;
      }
      var peerMarketsRegion =
        instrument.type === "ETF"
          ? taxonomies[txFields["ETF"]["etfgeo"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["ETF"]["etfgeo"]],
                instrument[marketField],
                "Region"
              )
            ]
          : taxonomies[txFields["security"]["country"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["security"]["country"]],
                instrument[marketField],
                "Region"
              )
            ];
      if (peerMarketsRegion != null && peerMarketsRegion["id"] !== "WWW") {
        params["peerMarkets"]["Region"] = peerMarketsRegion;
      }
      var peerMarketsArea =
        instrument.type === "ETF"
          ? taxonomies[txFields["ETF"]["etfgeo"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["ETF"]["etfgeo"]],
                instrument[marketField],
                "Area"
              )
            ]
          : taxonomies[txFields["security"]["country"]][
              taxon.getAncestorAtLevel(
                taxonomies[txFields["security"]["country"]],
                instrument[marketField],
                "Area"
              )
            ];

      if (peerMarketsArea != null && peerMarketsArea["id"] !== "WWW") {
        params["peerMarkets"]["Area"] = peerMarketsArea;
      }
      var options = [];
      if (instrument["type"] === "ETF") {
        options = optionsWhatWhereBySector(params);
      } else {
        options = optionsWhatWhereByCountry(params);
      }

      setWhatWhereOptions(options);
      // Adjust selected value from peer data
      var widgetWhatWherePeerValue = "";
      if (peer["where"] != null) {
        widgetWhatWherePeerValue = peer["where"] + "-";
      }

      if (peer["what"] != null) {
        widgetWhatWherePeerValue += peer["what"];
      } else {
        widgetWhatWherePeerValue += "ICB";
      }

      // Set default value
      setWhatWhereValue(options[options.length - 1]["value"]);

      // If there is an already set peer value (like a different combination of country and icb because
      // the peer group was too small), update the popup preset value
      if (widgetWhatWherePeerValue != null) {
        for (let i = 0; i < options.length; i++) {
          if (options[i]["value"] === widgetWhatWherePeerValue) {
            setWhatWhereValue(widgetWhatWherePeerValue);

            break;
          }
        }
      }
    },
    [
      environment,
      optionsWhatWhereByCountry,
      optionsWhatWhereBySector,
      taxon,
      value,
    ]
  );

  const dataPrepare = useCallback(
    (response) => {
      var instrument = value;

      var data = {
        peer: dataPreparePeer(response["peer"]),
        rank: dataPrepareRank(response["rank"]),
        quantiles: dataPrepareQuantiles(response["quantiles"]),
      };

      renderWhatWhere(response["peer"]);

      if (instrument["type"] === "Stock") {
        if (data["peer"] != null && data["peer"]["peerSize"] != null) {
          setPeerSizeConstraints(data["peer"]["peerSize"]["id"]);
          setPeerSizeValue(
            data["peer"]["peerSize"] != null
              ? data["peer"]["peerSize"]["id"]
              : null
          );
        }
      }

      setData(data);
    },
    [
      dataPreparePeer,
      dataPrepareQuantiles,
      dataPrepareRank,
      renderWhatWhere,
      value,
    ]
  );

  const dataGetRank = useCallback(
    async (params, responsePeer, lastUsedTemplateId?) => {
      var instrument = value;
      // Rank by instrument peer, not directly from instrument
      var rankParams = {
        type: params["type"],
        peer: responsePeer,
        templateId: lastUsedTemplateId ?? null,
      };

      // If there is no marketcap, remove sizeClassification from the request
      if (instrument["marketcap"] == null) {
        rankParams["peer"]["size"] = null;
      }
      rankParams["peer"]["size"] = fixPeerSize(rankParams["peer"]["size"]);
      // If there is no icb (Stock) or etfclass (ETF) just remove it from the request
      if (instrument["type"] === "Stock" && instrument["icb"] == null) {
        rankParams["peer"]["what"] = null;
      }
      if (instrument["type"] === "ETF" && instrument["etfclass"] == null) {
        rankParams["peer"]["what"] = null;
      }
      let response = await httpAll({
        rules: storage.rankingTemplate(rankParams),
        symbols: storage.peerSymbols(rankParams),
      });

      //
      // Managing ADR Stocks
      //
      // Peers contains only Common Stocks. We need to inject the
      // symbol of current instrument if it is not STOCK
      //
      // In this way we can compute the rank, that in any case is
      // not fully correct
      //
      var symbols = deepClone(response["symbols"]);
      if (instrument["stockclass"] !== "STOCK") {
        symbols.push(instrument["symbol"]);
      }

      response = await httpAll({
        dispersion1week: storage.dispersion({
          performanceTimeframe: "1_week",
          intervals: 4,
          symbols: symbols,
        }),
        dispersion1month: storage.dispersion({
          performanceTimeframe: "1_month",
          intervals: 4,
          symbols: symbols,
        }),
        dispersion3months: storage.dispersion({
          performanceTimeframe: "3_months",
          intervals: 4,
          symbols: symbols,
        }),
        dispersion12months: storage.dispersion({
          performanceTimeframe: "12_months",
          intervals: 4,
          symbols: symbols,
        }),
        rank: storage.rank({
          type: params["type"],
          rules: response["rules"],
          symbols: symbols,
          currentInstrument: instrument,
        }),
      });
      var responseRank = response.rank;
      var responseQuantiles = {
        pw: response.dispersion1week,
        pm: response.dispersion1month,
        pq: response.dispersion3months,
        py: response.dispersion12months,
      };

      responsePeer["type"] = "PEER"; // TODO Review this, do not use inside storage because it break the API call
      // TODO FIXME exceptions are ignore here, please check it
      try {
        return dataPrepare({
          peer: responsePeer,
          rank: responseRank,
          quantiles: responseQuantiles,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [dataPrepare, fixPeerSize, storage, value]
  );

  const dataGet = useCallback(async () => {
    var instrument = value;
    if (
      instrument["type"] !== "Commodity" &&
      instrument["type"] !== "Currency"
    ) {
      var params = {
        symbol: instrument["symbol"],
        type: instrument["type"],
      };
      var peerRequest =
        productConfiguration.analysis_instrument.peers.request[
          instrument["type"]
        ];
      if (peerRequest != null) {
        params["dimensions"] = peerRequest;
      }

      const response = await storage.peerByInstrument(instrument);

      await dataGetRank(params, response);
    }
  }, [
    dataGetRank,
    productConfiguration.analysis_instrument.peers.request,
    storage,
    value,
  ]);

  const dataGetPeer = useCallback(
    async (params, templateId) => {
      var instrument = value;
      if (
        instrument["type"] !== "Commodity" &&
        instrument["type"] !== "Currency"
      ) {
        const response = await storage.peer(params);

        dataGetRank(params, response, templateId);
      }
    },

    [dataGetRank, storage, value]
  );

  const preparePeerRankRequest = useCallback(
    (whatWhere, sizeClassification, rankingTemplateId) => {
      if (whatWhere != null) {
        var splittedValue = whatWhere.split("-");
        var country = splittedValue[0];
        var icb = splittedValue[1];
        var instrument = value;
        var params = {
          type: instrument["type"],
          classType: "peer",
        };
        switch (instrument["type"]) {
          case "Stock":
            if (country) {
              if (country === "WWW") {
                params["country"] = null;
              } else {
                params["country"] = country;
              }
            }
            if (icb) {
              if (icb === "ICB") {
                params["icb"] = null;
              } else {
                params["icb"] = icb;
              }
            }
            if (sizeClassification != null) {
              params["sizeClassification"] = sizeClassification;
            }
            break;
          case "ETF": {
            if (country) {
              if (country === "WWW") {
                params["etfgeo"] = null;
              } else {
                params["etfgeo"] = country;
              }
            }
            if (icb) {
              if (icb === "ICB") {
                params["etfclass"] = null;
              } else {
                params["etfclass"] = icb;
              }
            }
            break;
          }
          default:
            if (country) {
              if (country === "WWW") {
                params["country"] = null;
              } else {
                params["country"] = country;
              }
            }
            if (icb) {
              if (icb === "ICB") {
                params["icb"] = null;
              } else {
                params["icb"] = icb;
              }
            }
            break;
        }
        dataGetPeer(params, rankingTemplateId);
      }
    },
    [dataGetPeer, value]
  );

  const listenerChangeTemplate = useCallback(
    (id) => {
      setRankingTemplateId(id);
      preparePeerRankRequest(whatWhereValue, peerSizeValue, id);
    },
    [peerSizeValue, preparePeerRankRequest, whatWhereValue]
  );

  const listenerChangeDimensions = useCallback(
    (value) => {
      setWhatWhereValue(value);
      preparePeerRankRequest(value, peerSizeValue, rankingTemplateId);
    },
    [peerSizeValue, preparePeerRankRequest, rankingTemplateId]
  );

  const listenerChangeSize = useCallback(
    (value) => {
      preparePeerRankRequest(whatWhereValue, value, rankingTemplateId);
    },
    [preparePeerRankRequest, rankingTemplateId, whatWhereValue]
  );

  const onPageMount = useCallback(() => {
    dataGet();
  }, [dataGet]);

  useEffect(() => {
    onPageMount();
  }, [onPageMount]);

  useEffect(() => {
    getRankingTemplates();
  }, [getRankingTemplates]);

  useEffect(() => {
    handleTitle({
      type: "SECURITY_ANALYSIS",
      name: value?.ticker ?? undefined,
    });
  }, [value?.ticker]);

  return (
    <ErrorBoundary fallback={<></>}>
      <Box className={styles.tabRank__main}>
        <Box className={styles.tabRank__main__title__box}>
          <Card className={styles.tabRank__main__title__box__card}>
            <CardContent
              className={styles.tabRank__main__title__box__card__content}
            >
              <Box
                className={
                  styles.tabRank__main__title__box__card__content__main
                }
              >
                <span dangerouslySetInnerHTML={{ __html: name }}></span>
                <PeerCustomSelect
                  peerName={peerName}
                  options={whatWhereOptions ?? []}
                  selectValue={listenerChangeDimensions}
                />
                {showPeerSizeWidget && (
                  <PeerSize
                    options={sizeOpts}
                    isNullWhenAllAreSelected={true}
                    onClickItem={listenerChangeSize}
                    defaultValue={peerSizeConstraints}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box className={styles.tabRank__main__content__box}>
          <Box className={styles.tabRank__main__content__box__panel__1}>
            <Card
              className={styles.tabRank__main__content__box__panel__1__card}
            >
              <CardContent
                className={
                  styles.tabRank__main__content__box__panel__1__card__content
                }
              >
                <Box
                  className={
                    styles.tabRank__main__content__box__panel__1__card__content__title__box
                  }
                >
                  <Box
                    display={"flex"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography sx={{ fontSize: "1.5em" }}>Rank</Typography>
                    <RankTemlpateSelector
                      options={rankingTemplates}
                      onChangeTemplate={listenerChangeTemplate}
                    />
                  </Box>
                  <div className="tSecurityAnalysisPeerRank">
                    <span className="tSecurityAnalysisPeerRank-ranking">
                      {nodePeerRankIndex ?? "-"}
                    </span>{" "}
                    out of{" "}
                    <span className="tSecurityAnalysisPeerRank-total">
                      {nodePeerRankTotal ?? "-"}
                    </span>
                  </div>
                </Box>
                <Box
                  className={
                    styles.tabRank__main__content__box__panel__1__card__content__main__box
                  }
                >
                  {topRankRows.length ? (
                    <>
                      <h3
                        className="tTable-title"
                        data-dojo-attach-point="nodePeerRankTableTopTitle"
                      >
                        Top ranked
                      </h3>
                      <div
                        className="tTable-wrap"
                        data-dojo-attach-point="nodePeerRankTableTop"
                      >
                        <table className="tTable tTable--peerRanking">
                          <tbody data-dojo-attach-point="nodePeerRankTableTopBody">
                            {topRankRows.map((item) => item)}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {middleRankRows?.length ? (
                    <>
                      {" "}
                      <h3
                        className="tTable-title"
                        data-dojo-attach-point="nodePeerRankTableNearTitle"
                      >
                        Near ranked
                      </h3>
                      <div
                        className="tTable-wrap"
                        data-dojo-attach-point="nodePeerRankTableNear"
                      >
                        <table className="tTable tTable--peerRanking">
                          <tbody data-dojo-attach-point="nodePeerRankTableNearBody">
                            {middleRankRows.map((item) => item)}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {bottomRankRows?.length ? (
                    <>
                      <h3
                        className="tTable-title"
                        data-dojo-attach-point="nodePeerRankTableWorstTitle"
                      >
                        Worst ranked
                      </h3>
                      <div
                        className="tTable-wrap"
                        data-dojo-attach-point="nodePeerRankTableWorst"
                      >
                        <table className="tTable tTable--peerRanking">
                          <tbody data-dojo-attach-point="nodePeerRankTableWorstBody">
                            {bottomRankRows.map((item) => item)}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box className={styles.tabRank__main__content__box__panel__2}>
            <Box
              className={
                styles.tabRank__main__content__box__panel__2__row__rating
              }
            >
              <Card
                className={
                  styles.tabRank__main__content__box__panel__2__row__rating__rc__card
                }
              >
                <CardContent>
                  <Typography sx={{ fontSize: "1.5em" }}>TCR</Typography>
                  <div
                    style={{
                      fontSize: "320%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    dangerouslySetInnerHTML={{ __html: nodeTCR }}
                  ></div>
                </CardContent>
              </Card>
              <Card
                className={
                  styles.tabRank__main__content__box__panel__2__row__rating__rc__dist__card
                }
              >
                <CardContent
                  className={
                    styles.tabRank__main__content__box__panel__2__row__rating__rc__dist__card__content
                  }
                >
                  <Box
                    className={
                      styles.tabRank__main__content__box__panel__2__row__rating__rc__dist__card__content__title
                    }
                  >
                    <Typography sx={{ fontSize: "1.5em" }}>
                      Rating distribution -
                    </Typography>
                    <RcDistributionSelector selectValue={setRcView} />
                  </Box>
                  <Box
                    className={
                      styles.tabRank__main__content__box__panel__2__row__rating__rc__dist__card__content__main
                    }
                  >
                    <ul
                      className={
                        styles.tabRank__main__content__box__panel__2__row__rating__rc__dist__card__content__main__list
                      }
                    >
                      <li>
                        <div className="tSecurityAnalysisRatingDistribution-distribution">
                          <strong className="tSecurityAnalysisRatingDistribution-rate format-rate format-rate--A">
                            A
                          </strong>{" "}
                          <span>{rcDistributions.A}</span>
                        </div>
                      </li>
                      <li>
                        <div className="tSecurityAnalysisRatingDistribution-distribution">
                          <strong className="tSecurityAnalysisRatingDistribution-rate format-rate format-rate--B">
                            B
                          </strong>{" "}
                          <span>{rcDistributions.B}</span>
                        </div>
                      </li>
                      <li>
                        <div className="tSecurityAnalysisRatingDistribution-distribution">
                          <strong className="tSecurityAnalysisRatingDistribution-rate format-rate format-rate--C">
                            C
                          </strong>{" "}
                          <span>{rcDistributions.C}</span>
                        </div>
                      </li>
                      <li>
                        <div className="tSecurityAnalysisRatingDistribution-distribution">
                          <strong className="tSecurityAnalysisRatingDistribution-rate format-rate format-rate--D">
                            D
                          </strong>{" "}
                          <span>{rcDistributions.D}</span>
                        </div>
                      </li>
                    </ul>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box
              className={
                styles.tabRank__main__content__box__panel__2__row__performance
              }
            >
              <Card
                className={
                  styles.tabRank__main__content__box__panel__2__row__performance__card
                }
              >
                <CardContent>
                  <Typography sx={{ fontSize: "1.5em" }}>
                    Performance
                  </Typography>
                </CardContent>
                <Box p={2}>
                  <table
                    className="tTable"
                    style={{
                      marginBottom: "8px",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="tTable-header-label"
                          style={{ width: "25%" }}
                        ></th>
                        <th
                          style={{ width: "25%" }}
                          className="tTable-header-ticker"
                          dangerouslySetInnerHTML={{
                            __html: nodePerformanceTicker,
                          }}
                        ></th>
                        <th
                          className="tTable-header-min"
                          style={{ width: "52px", textAlign: "right" }}
                        >
                          Min
                        </th>
                        <th
                          className="tTable-header-market"
                          style={{ width: "210px", textAlign: "center" }}
                        ></th>
                        <th
                          className="tTable-header-max"
                          style={{ width: "52px", textAlign: "left" }}
                        >
                          Max
                        </th>
                        <th style={{ width: "10px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ textAlign: "left" }}
                          className="tTable-cell-label"
                        >
                          Last week
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {nodePerformancePw ?? "0.00%"}
                        </td>
                        <td>
                          {nodePerformancePwLowHigh?.nodePerformancePwLow ??
                            "0.00%"}
                        </td>
                        <td className="tTable-market">
                          <div className="tSecurityAnalysisPerformanceDistribution-peerChart-chart">
                            {data?.quantiles && (
                              <ChartPeerPerformance
                                data={data?.quantiles}
                                security={value}
                                field="pw"
                              />
                            )}
                          </div>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {nodePerformancePwLowHigh?.nodePerformancePwHigh ??
                            "0.00%"}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{ textAlign: "left" }}
                          className="tTable-cell-label"
                        >
                          Last month
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {nodePerformancePm ?? "0.00%"}
                        </td>
                        <td>
                          {nodePerformancePmLowHigh?.nodePerformancePmLow ??
                            "0.00%"}
                        </td>
                        <td className="tTable-market">
                          <div className="tSecurityAnalysisPerformanceDistribution-peerChart-chart">
                            {data?.quantiles && (
                              <ChartPeerPerformance
                                data={data?.quantiles}
                                security={value}
                                field="pm"
                              />
                            )}
                          </div>
                        </td>
                        <td
                          style={{ textAlign: "right" }}
                          data-dojo-attach-point="nodePerformancePmHigh"
                        >
                          {nodePerformancePmLowHigh?.nodePerformancePmHigh ??
                            "0.00%"}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{ textAlign: "left" }}
                          className="tTable-cell-label"
                        >
                          Last 3 months
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {nodePerformancePq ?? "0.00%"}
                        </td>
                        <td>
                          {nodePerformancePqLowHigh?.nodePerformancePqLow ??
                            "0.00%"}
                        </td>
                        <td className="tTable-market">
                          <div className="tSecurityAnalysisPerformanceDistribution-peerChart-chart">
                            {data?.quantiles && (
                              <ChartPeerPerformance
                                data={data?.quantiles}
                                security={value}
                                field="pq"
                              />
                            )}
                          </div>
                        </td>
                        <td
                          style={{ textAlign: "right" }}
                          data-dojo-attach-point="nodePerformancePqHigh"
                        >
                          {nodePerformancePqLowHigh?.nodePerformancePqHigh ??
                            "0.00%"}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{ textAlign: "left" }}
                          className="tTable-cell-label"
                        >
                          Last 12 months
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          data-dojo-attach-point="nodePerformancePy"
                        >
                          {nodePerformancePy ?? "0.00%"}
                        </td>
                        <td data-dojo-attach-point="nodePerformancePyLow">
                          {nodePerformancePyLowHigh?.nodePerformancePyLow ??
                            "0.00%"}
                        </td>
                        <td className="tTable-market">
                          <div className="tSecurityAnalysisPerformanceDistribution-peerChart-chart">
                            {" "}
                            {data?.quantiles && (
                              <ChartPeerPerformance
                                data={data?.quantiles}
                                security={value}
                                field="py"
                              />
                            )}
                          </div>
                        </td>
                        <td
                          style={{ textAlign: "right" }}
                          data-dojo-attach-point="nodePerformancePyHigh"
                        >
                          {nodePerformancePyLowHigh?.nodePerformancePyHigh ??
                            "0.00%"}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  {showNodeCommonStockInfo && (
                    <Typography sx={{ color: "#999" }}>
                      Analytics aggregate common stocks only
                    </Typography>
                  )}
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

const PeerCustomSelect = ({ peerName, options, selectValue }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangePeerLevel = useCallback(
    (value) => {
      selectValue(value);
      handleClose();
    },
    [selectValue]
  );

  const renderOptions = useCallback(() => {
    if (options && options.length) {
      const optionsList: any = [];

      const groupOptions = (currentIndex, actualList) => {
        const chunk: any = [];
        let option: any = undefined;
        let i = currentIndex;

        if (i < options.length) {
          for (i; i < options.length; i++) {
            option = options[i];

            if ("type" in option && option.type === "separator") {
              i++;

              break;
            }

            chunk.push(option);
          }

          actualList.push(chunk);

          groupOptions(i, actualList);
        }
      };

      groupOptions(0, optionsList);

      const items: any = [];
      let item: any = null;
      let tabIndent = 0;
      let isFirstItem = true;

      for (const group of optionsList) {
        for (let i = 0; i < group.length; i++) {
          item = group[i];
          isFirstItem = i === 0;

          tabIndent = isFirstItem ? 1 : 2;

          // Used to keep reference on item.value right
          const itemValue = item.value;

          items.push(
            <MenuItem
              disabled={item.value === "WWW-ICB"}
              key={uuidv4()}
              onClick={() => onChangePeerLevel(itemValue)}
              sx={{
                paddingLeft: tabIndent,
                fontSize: "0.7vw",
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              {isFirstItem ? <strong>{item.label}</strong> : item.label}
            </MenuItem>
          );
        }
      }

      return items;
    }

    return [];
  }, [onChangePeerLevel, options]);

  return (
    <Box>
      <Button
        id="peer-options-button"
        aria-controls={open ? "peer-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={
          styles.tabRank__main__title__box__card__content__main__peer__label
        }
      >
        <Box dangerouslySetInnerHTML={{ __html: peerName }}></Box>
      </Button>
      <Menu
        id="peer-options-menu"
        MenuListProps={{
          "aria-labelledby": "peer-options-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {renderOptions().map((item) => item)}
      </Menu>
    </Box>
  );
};

const RcDistributionSelector = ({ selectValue }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<"weights" | "holdings">("weights");

  const open = Boolean(anchorEl);
  const options = useMemo(
    () => [
      { label: "Weights", value: "weights" },
      { label: "Holdings", value: "holdings" },
    ],
    []
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeValue = useCallback(
    (value) => {
      selectValue(value);
      setValue(value);
      handleClose();
    },
    [selectValue]
  );
  return (
    <Box>
      <Button
        id="selector-options-button"
        aria-controls={open ? "selector-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={
          styles.tabRank__main__title__box__card__content__main__peer__label
        }
      >
        <Typography sx={{ fontSize: "1.5em" }}>
          {value === "holdings" ? "Holdings" : "Weights"}
        </Typography>
      </Button>
      <Menu
        id="selctor-customized-menu"
        MenuListProps={{
          "aria-labelledby": "selector-options-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item) => (
          <MenuItem
            key={uuidv4()}
            sx={{
              fontSize: "0.7vw",
              paddingBottom: 0,
              paddingTop: 0,
            }}
            onClick={() => onChangeValue(item.value)}
          >
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const RankTemlpateSelector = ({ onChangeTemplate, options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<any>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const btnLabel = useMemo(() => {
    if (value == null) {
      return "Default ranking";
    } else {
      const option = options.find((el) => el.value === value);

      return option.label;
    }
  }, [options, value]);

  const optionsList = useMemo(() => {
    if (options && options.length) {
      return options.map((opt) =>
        opt.value != null ? { ...opt } : { ...opt, label: "Default ranking" }
      );
    }

    return undefined;
  }, [options]);

  const onChangeValue = useCallback(
    (value) => {
      onChangeTemplate(value);
      setValue(value);
      handleClose();
    },
    [onChangeTemplate]
  );

  return (
    <Box>
      <Button
        id="ranking-options-button"
        aria-controls={open ? "ranking-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className={
          styles.tabRank__main__title__box__card__content__main__peer__label
        }
      >
        <Typography sx={{ fontSize: "1.5em" }}>{btnLabel}</Typography>
      </Button>
      <Menu
        id="selctor-customized-menu"
        MenuListProps={{
          "aria-labelledby": "ranking-options-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {optionsList &&
          optionsList.map((item) => (
            <MenuItem
              key={uuidv4()}
              sx={{
                fontSize: "0.7vw",
                paddingBottom: 0,
                paddingTop: 0,
              }}
              onClick={() => onChangeValue(item.value)}
            >
              <Typography>{item.label}</Typography>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
