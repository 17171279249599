import { useCallback, useContext, useMemo, useState } from "react";
import { FilterTaxonomies } from "../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { deepClone } from "../../../../../../../deepClone";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FiltersContext } from "../../FilterRowContraints";

type SectorFilterProps = {
  label: string;
};

export function SectorFilter({ label }: SectorFilterProps) {
  const context = useContext(FiltersContext);
  const [panelValues, setPanelValues] = useState<string[]>([]);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const taxonomy = useMemo(() => ({ type: "security", field: "icb" }), []);
  const { getTaxonomyMap, advancedNodesSelector } = useTaxonomyByType("stock");
  const icbTaxonomy = useMemo(() => {
    return getTaxonomyMap("security", "icb");
  }, [getTaxonomyMap]);
  const rootNode = useMemo(
    () => Object.values<any>(icbTaxonomy).find((taxon) => taxon.parent == null),
    [icbTaxonomy]
  );
  const validLevels = useMemo(() => ["1 Industry", "3 Sector"], []);
  const icbTaxonomyPanelConfig = useMemo(
    () => [
      [{ taxonomyId: "20" }, { taxonomyId: "60" }],
      [{ taxonomyId: "25" }, { taxonomyId: "50" }],
      [{ taxonomyId: "15" }, { taxonomyId: "35" }, { taxonomyId: "10" }],
      [{ taxonomyId: "40" }, { taxonomyId: "45" }],
      [{ taxonomyId: "55" }, { taxonomyId: "30" }],
    ],
    []
  );

  const handleDomicileSelection = useCallback(
    (filtersDirty) => {
      const segments = deepClone(filtersDirty?.[0]?.value?.segments);
      const filters = advancedNodesSelector(segments, "icb", true, validLevels);

      const domicileFilter = {
        type: "filters",
        value: {
          dimension: "icb",
          segments: filters,
        },
      };

      context?.updateSector([domicileFilter]);
    },
    [advancedNodesSelector, context, validLevels]
  );

  const activeValues = useMemo(() => {
    if (context && context.sector) {
      const values = context.sector;
      const taxonomiesIds: string[] = [];

      values.forEach((value) => {
        taxonomiesIds.push(...value.value.segments);
      });

      setPanelValues(taxonomiesIds);

      if (taxonomiesIds.length) {
        setShowCancelButton(true);
        if (taxonomiesIds.length > 3) {
          const firstSelected = icbTaxonomy?.[taxonomiesIds[0]]?.name ?? "";
          return [firstSelected, `+ ${taxonomiesIds.length - 1}`];
        } else {
          return taxonomiesIds.map((id) => icbTaxonomy?.[id]?.name ?? "");
        }
      }
    }

    setShowCancelButton(false);
    return rootNode?.name ? [rootNode.name] : [];
  }, [context, icbTaxonomy, rootNode]);

  const clearFitlers = useCallback(
    (e) => {
      e.stopPropagation();
      context?.updateSector([]);
    },
    [context]
  );

  return (
    <FilterTaxonomies
      showIconCancel={showCancelButton}
      taxonomyToRender={taxonomy}
      updateFilters={handleDomicileSelection}
      filtersValues={panelValues}
      selectedFilters={activeValues}
      label={label}
      removeFilter={clearFitlers}
      validTreeNodes={validLevels}
      columnsDefinition={icbTaxonomyPanelConfig}
    />
  );
}
