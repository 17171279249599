import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Chart } from "../../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../../hooks/useFormatter";
import {
  FORMATTER_OPTIONS,
  _formatNumber4Decimals,
  _formatNumber4DecimalsColored,
  _formatPercentageNoDecimals,
  _formatPercentageSignColored,
  _formatPercentage,
  existenceChecker,
} from "./utils";

type Props = {
  value: any;
  getChartData: any;
  showChart: boolean;
  benchmarkLabel?: string;
};

export default function KeyRatios({
  value,
  getChartData,
  showChart,
  benchmarkLabel,
}: Props) {
  const [hasBenchmarkInfo, setHasBenchmarkInfo] = useState(false);
  const [hasStrategyInfo, setHasStrategyInfo] = useState(false);
  const [hasDeltaInfo, setHasDeltaInfo] = useState(false);
  const formatter = useFormatter();

  useEffect(() => {
    const arr: any = Object.entries(value);
    //! checking if we have strategy, benchmark or delta info
    //! because we'll display stuff based on existence of info
    existenceChecker(arr, setHasStrategyInfo, "strategy");
    existenceChecker(arr, setHasBenchmarkInfo, "benchmark");
    existenceChecker(arr, setHasDeltaInfo, "delta");
  }, [value]);

  const getNumber = useCallback(
    (num: number | null, type: string) => {
      if (num == null) {
        return /*<td>&nbsp;</td>*/ null;
      }
      let formated = formatter.custom("number", {
        options: {
          colored: type !== "delta" ? null : "positive",
          isPercentage: false,
          notAvailable: {
            input: null,
            output: "",
          },
          decimals: 4,
        },
        output: "HTML",
        value: num,
        valueHelper: {
          normalizationThreshold: 1,
        },
      });
      return (
        <td
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: formated }}
        ></td>
      );
    },
    [formatter]
  );
  const getPercentageNumber = useCallback(
    (num: number | null, type: string) => {
      if (num == null) {
        return /*<td>&nbsp;</td>*/ null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatNumber4Decimals(num, formatter, FORMATTER_OPTIONS);
      } else {
        formated = _formatNumber4DecimalsColored(
          num,
          formatter,
          FORMATTER_OPTIONS
        );
      }

      return (
        <td
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: formated }}
        ></td>
      );
    },
    [formatter]
  );

  const getPercentagePositive = useCallback(
    (num: number | null, type: string) => {
      if (num == null) {
        return /*<td>&nbsp;</td>*/ null;
      }
      let formated: any = null;
      if (type === "strategy" || type === "benchmark") {
        formated = _formatPercentage(num, formatter, FORMATTER_OPTIONS);
      } else {
        formated = _formatPercentageSignColored(
          num,
          formatter,
          FORMATTER_OPTIONS
        );
      }

      return (
        <td
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: formated }}
        ></td>
      );
    },
    [formatter]
  );

  const getYearlyTurnover = useCallback(
    (num: number | null) => {
      if (num == null) {
        return /*<td>&nbsp;</td>*/ null;
      }
      let formated = _formatPercentageNoDecimals(
        num,
        formatter,
        FORMATTER_OPTIONS
      );
      return (
        <td
          style={{ textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: formated }}
        ></td>
      );
    },
    [formatter]
  );

  const [propertiesToGetChartData, setPropertiesToGetChartData] = useState<any>(
    {
      propertyName: "avgTurnover",
      propertyLabel: "One way yearly turnover",
      FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
    }
  );

  const chartData = useMemo(
    () => getChartData(propertiesToGetChartData),
    [getChartData, propertiesToGetChartData]
  );

  return (
    <Card sx={{ boxShadow: 3, overflow: "visible" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          pb: "8px !important",
          p: 1,
        }}
      >
        <Box>
          <Typography variant="subtitle1">Key ratios</Typography>
        </Box>
        <Box display={"flex"} gap={2}>
          <Card sx={{ boxShadow: 3, width: showChart ? "60%" : "100%" }}>
            <CardContent sx={{ pb: "8px !important", p: 1 }}>
              <table width={"100%"}>
                <thead>
                  <tr>
                    <td style={{ width: "40%" }}>&nbsp;</td>
                    {hasStrategyInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Strategy
                      </td>
                    )}
                    {hasBenchmarkInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        {benchmarkLabel ?? "Benchmark"}
                      </td>
                    )}
                    {hasDeltaInfo && (
                      <td style={{ textAlign: "right", width: "20%" }}>
                        Difference
                      </td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "avgTurnover",
                        propertyLabel: "One way yearly turnover",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>One way yearly turnover</td>
                    {hasStrategyInfo &&
                      getYearlyTurnover(value.avgTurnover.strategy)}
                    {hasBenchmarkInfo &&
                      getPercentageNumber(
                        value.avgTurnover.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getPercentageNumber(value.avgTurnover.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "sharpeRatio",
                        propertyLabel: "Sharpe ratio",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Sharpe ratio</td>
                    {hasStrategyInfo &&
                      getNumber(value.sharpeRatio.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.sharpeRatio.benchmark, "benchmark")}
                    {hasDeltaInfo &&
                      getNumber(value.sharpeRatio.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "sterlingRatio",
                        propertyLabel: "Sterling ratio",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Sterling ratio</td>
                    {hasStrategyInfo &&
                      getNumber(value.sterlingRatio.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.sterlingRatio.benchmark, "benchmark")}
                    {hasDeltaInfo &&
                      getNumber(value.sterlingRatio.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "sortinoRatio",
                        propertyLabel: "Sortino ratio",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Sortino ratio</td>
                    {hasStrategyInfo &&
                      getNumber(value.sortinoRatio.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.sortinoRatio.benchmark, "benchmark")}
                    {hasDeltaInfo &&
                      getNumber(value.sortinoRatio.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "beta",
                        propertyLabel: "Beta",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Beta</td>
                    {hasStrategyInfo &&
                      getNumber(value.beta.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.beta.benchmark, "benchmark")}
                    {hasDeltaInfo && getNumber(value.beta.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "trackingError",
                        propertyLabel: "Tracking error",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>Tracking error</td>
                    {hasStrategyInfo && (
                      <td style={{ textAlign: "right" }}>
                        {_formatPercentage(
                          value.trackingError.strategy,
                          formatter,
                          FORMATTER_OPTIONS
                        )}
                      </td>
                    )}
                    {/* <td>&nbsp;</td>
                    <td>&nbsp;</td> */}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "infoRatio",
                        propertyLabel: "Information ratio",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Information ratio</td>
                    {hasStrategyInfo &&
                      getNumber(value.infoRatio.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.infoRatio.benchmark, "benchmark")}
                    {hasDeltaInfo && getNumber(value.infoRatio.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "treynorRatio",
                        propertyLabel: "Treynor ratio",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.NUMBER_4_DECIMALS,
                      })
                    }
                  >
                    <td>Treynor ratio</td>
                    {hasStrategyInfo &&
                      getNumber(value.treynorRatio.strategy, "strategy")}
                    {hasBenchmarkInfo &&
                      getNumber(value.treynorRatio.benchmark, "benchmark")}
                    {hasDeltaInfo &&
                      getNumber(value.treynorRatio.delta, "delta")}
                  </tr>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setPropertiesToGetChartData({
                        propertyName: "percentagePositivePeriod",
                        propertyLabel: "% positive months",
                        FORMATTER_OPTIONS: FORMATTER_OPTIONS.PERCENTAGE,
                      })
                    }
                  >
                    <td>% positive months</td>
                    {hasStrategyInfo &&
                      getPercentagePositive(
                        value.percentagePositivePeriod.strategy,
                        "strategy"
                      )}
                    {hasBenchmarkInfo &&
                      getPercentagePositive(
                        value.percentagePositivePeriod.benchmark,
                        "benchmark"
                      )}
                    {hasDeltaInfo &&
                      getPercentagePositive(
                        value.percentagePositivePeriod.delta,
                        "delta"
                      )}
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
          {showChart && (
            <Card sx={{ boxShadow: 3, width: "40%" }}>
              <CardContent sx={{ pb: "8px !important", p: 1 }}>
                <Chart constructorType="chart" options={chartData} />
              </CardContent>
            </Card>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
