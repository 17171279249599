import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../deepClone";
import { targetNameVariable } from "./utils/utils";

type TitleProps = {
  configObj: any;
};

const Title = forwardRef(({ configObj }: TitleProps, ref) => {
  const [state, setState] = useState(configObj.widgetValue.content.text);

  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.text = state;
      return tempState;
    },
  }));
  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box display={"flex"} gap={1} flexDirection="column">
            <TextField
              size="small"
              value={state}
              defaultValue={state}
              label="Title"
              onChange={(e) => setState(e.target.value)}
            />
            <Button
              onClick={() => {
                setState(`${state}${targetNameVariable}`);
              }}
            >
              Add target's name to title
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Title;
