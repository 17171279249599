import { useMemo } from "react";
import { FilterTaxonomies } from "../../../../../../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { useEnvironment } from "../../../../../../../../../../../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../../../../../../../../../../../hooks/useTaxonomyByType";
import { deepClone } from "../../../../../../../../../../../../deepClone";

type DomicileSelectorProps = {
  segments: any;
  domicileSelectionFilterHandler: any;
};

export default function DomicileSelector({
  segments,
  domicileSelectionFilterHandler,
}: DomicileSelectorProps) {
  const taxonomy = useMemo(() => ({ type: "security", field: "domicile" }), []);
  const { advancedNodesSelector } = useTaxonomyByType("stock");

  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  const fieldsMap = useMemo(
    () => environment.get("taxonomyFields"),
    [environment]
  );

  const activeValues = useMemo(() => {
    if (segments) {
      if (segments?.length <= 3) {
        return segments?.map(
          (cnt) =>
            taxonomies?.[fieldsMap?.["security"]?.["domicile"]]?.[cnt]?.name ??
            ""
        );
      } else {
        return ["some domiciles"];
      }
    } else {
      return ["Any"];
    }
  }, [fieldsMap, segments, taxonomies]);

  return (
    <div>
      <FilterTaxonomies
        showIconCancel={false}
        taxonomyToRender={taxonomy}
        updateFilters={(value) => {
          if(value.length>0){
            const temp = deepClone(value);
            const newSegments  = advancedNodesSelector(value[0].value.segments, "domicile", true);
            temp[0].value.segments = newSegments;
            domicileSelectionFilterHandler(temp) 
          }else{
            domicileSelectionFilterHandler([]);
          }
        }}
        filtersValues={segments ?? []}
        selectedFilters={activeValues}
        label={"Domicile"}
      />
    </div>
  );
}
