import { useEffect, useMemo, useRef, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../hooks/useFormatter";
import style from "./ReactDialogRationale.module.scss";
import { Properties } from "../../../../../api/Properties";
import { deepClone } from "../../../../../deepClone";
import { useTranslation } from "react-i18next";
import { CSV } from "./../../../utils/SingletonCSV";
import { Skeleton, Stack } from "@mui/material";

type Props = {
  closeFn: (val) => void;
  isLoading: boolean;
  tableData?: any;
};

const formatterPercentage = (cell, formatter) => {
  return formatter.custom("number", {
    options: {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "",
      },
      zero: true,
    },
    output: "HTML",
    value: cell.getValue(),
    valueHelper: null,
  });
};

const removeFieldPrefix = (property) => {
  if (property) {
    const regex =
      /selection(_[0-9]+)?_|hold(_[0-9]+)?_|ranking(_[0-9]+)?_|weighting(_[0-9]+)?_/;
    let response = property.replace(regex, "");
    return response;
  }
};

const frmtr = (column, ff) => {
  //this function is used to generate dynamically the table columns
  switch (removeFieldPrefix(column.field)) {
    case "beforeCapWeight":
    case "capWeight":
    case "exWeight":
    case "multifactor":
    case "Weight": {
      return {
        title: column.label,
        field: column.field,
        formatter: (cell) => {
          if (cell.getValue() !== null) {
            return formatterPercentage(cell, ff);
          } else {
            cell.getElement().style.backgroundColor = "#eee";
            return "";
          }
        },
      };
    }
    case "candidate":
    case "holds":
    case "costituent": {
      return {
        title: column.label,
        field: column.field,
        formatter: (cell) => {
          if (cell.getValue() === 1) {
            return "<div style='text-align: center'><span style='background-color: #333;border-radius: 50%; display: inline-block; height: 10px; width: 10px'></span></div>";
          } else {
            cell.getElement().style.backgroundColor = "#eee";
            return "";
          }
        },
      };
    }
    case "exists": {
      return {
        title: column.label,
        field: column.field,
        formatter: (cell) => {
          if (cell.getValue() === 1) {
            return "true";
          } else {
            cell.getElement().style.backgroundColor = "#eee";
            return "";
          }
        },
      };
    }
    case "rank": {
      return {
        title: column.label,
        field: column.field,
        formatter: (cell) => {
          const row = cell.getRow();
          const selectionCell = row.getCell("selection_candidate");

          if (selectionCell) {
            const value = selectionCell.getValue();

            if (value !== 1) {
              cell.getElement().style.backgroundColor = "#eee";
              return "";
            }
            return cell.getValue() + 1;
          }

          return cell.getValue() + 1;
        },
      };
    }
    default: {
      let columnRule = column["rule"];
      if (column["function"] != null) {
        // overwrite (if exist) rule
        columnRule = {
          function: column["function"],
          operator: column["operator"],
        };
      }

      if (columnRule != null) {
        switch (columnRule["function"]) {
          case "outlier": {
            return {
              title: column.label,
              field: column.field,
              formatter: (cell) => {
                if (cell.getData()[column["field"]] === 1) {
                  return "";
                } else {
                  return "outlier";
                }
              },
            };
          }
          case "quantile": {
            return {
              title: column.label,
              field: column.field,
              formatter: (cell) => {
                if (cell.getData()[column["field"]]) {
                  return "q:" + cell.getData()[column["field"]];
                } else {
                  cell.getElement().style.backgroundColor = "#eee";
                  return "";
                }
              },
            };
          }
          case "threshold": {
            return {
              title: column.label,
              field: column.field,
              formatter: (cell) => {
                if (cell.getData()[column["field"]] === 1) {
                  return "over";
                } else {
                  return "under";
                }
              },
            };
          }
          case "value": {
            switch (columnRule["operator"]) {
              case "bottom":
              case "top": {
                return {
                  title: column.label,
                  field: column.field,
                  formatter: (cell) => {
                    if (cell.getData()[column.field]) {
                      return cell.getData()[column.field];
                    } else {
                      cell.getElement().style.backgroundColor = "#eee";
                      return "";
                    }
                  },
                };
              }
              case "value":
              default: {
                return {
                  title: column.label,
                  field: column.field,
                  formatter: (cell) => {
                    const temp = ff.rationale(
                      removeFieldPrefix(column["field"]),
                      column["field"],
                      cell.getData()
                    );
                    if (temp) {
                      return temp;
                    } else {
                      cell.getElement().style.backgroundColor = "#eee";
                      return "";
                    }
                  },
                };
              }
            }
          }
        }
      } else {
        return {
          title: column.label,
          field: column.field,
          formatter: (cell) => {
            const temp = ff.rationale(
              removeFieldPrefix(column["field"]),
              column["field"],
              cell.getData()
            );
            if (temp) {
              return temp;
            } else {
              cell.getElement().style.backgroundColor = "#eee";
              return "";
            }
          },
        };
      }
    }
  }
};

const dataPrepare = (data, lab, t, ff) => {
  var _data = deepClone(data);
  var _children: any = null;
  var _columns: any[] = _data["columns"];
  var _column: any = null;
  var columns: any[] = [];
  var field: any = null;
  var label = lab;
  for (var i = 0, lengthI = _columns.length; i < lengthI; i++) {
    _children = _columns[i]["children"];
    // parents
    columns.push({
      children: [],
      label: _columns[i]["label"],
      rule: _columns[i]["rule"],
    });
    // children

    const fieldToIgnore = ["currency", "nWeight", "orWeight", "symbol", "type"];

    const labelMap = {
      beforeCapWeight: t("c_before_capping"),
      candidate: t("c_entitled"),
      capWeight: t("c_after_capping"),
      costituent: t("c_constituent"),
      exWeight: t("c_ex_ante"),
      multifactor: t("c_smart_beta"),
      rank: t("c_rank"),
      Weight: t("c_weight"),
      hold_0_pq: t("c_holding"),
      hold_holds: t("c_holding_holds"),
    };

    for (var j = 0, lengthJ = _children.length; j < lengthJ; j++) {
      _column = _children[j];

      field = removeFieldPrefix(_column["field"]);

      const testField = field;
      if (fieldToIgnore.some((el) => el === testField)) {
        continue;
      }

      if (labelMap[field] != null) {
        _column["label"] = labelMap[field];
      } else {
        if (field === "tradedvalue") {
          _column["label"] = label.get(field, 1);
        } else if (field === "exists") {
          _column["label"] = label.get(field, 0);
        } else {
          _column["label"] = label.get(field, 0, "auto");
        }
      }

      _column["renderCell"] = frmtr(_column, ff);
      _column["renderCell"]["sorterParams"] = {
        alignEmptyValues: "bottom",
      };

      columns[i]["children"].push(_column);
    }
  }

  return (_data["columns"] = columns);
};

//data is tableData.data
const listenerExportCsvButton = (columns, data, format) => {
  var headerColumnLabels: any[] = [];
  var headerColumns: any[] = [];
  var result: any[] = [];

  columns.forEach((item) => {
    var columnWrapper = item;
    columnWrapper.children.forEach((col) => {
      headerColumnLabels.push(col.label);
      headerColumns.push(col);
    });
  });

  result.push(headerColumnLabels);

  data.forEach((item) => {
    var row: any[] = [];
    headerColumns.forEach((column) => {
      const key = column.field;
      if (key in item) {
        const value = item[key];
        switch (removeFieldPrefix(key)) {
          case "beforeCapWeight":
          case "capWeight":
          case "exWeight":
          case "multifactor":
          case "Weight": {
            if (value === -1) {
              row.push("");
            } else {
              row.push(
                format.custom("number", {
                  options: {
                    isPercentage: true,
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                    zero: false,
                  },
                  output: "TEXT",
                  value: value,
                  valueHelper: null,
                })
              );
            }

            break;
          }
          case "candidate":
          case "costituent": {
            if (value === 1) {
              row.push("true");
            } else {
              row.push("");
            }

            break;
          }
          case "exists": {
            if (value === 1) {
              row.push("true");
            } else {
              row.push("");
            }

            break;
          }
          case "rank": {
            row.push(value + 1);

            break;
          }
          default:
            let columnRule = column["rule"];
            if (column["function"] != null) {
              // overwrite (if exist) rule
              columnRule = {
                function: column["function"],
                operator: column["operator"],
              };
            }

            if (columnRule != null) {
              switch (columnRule["function"]) {
                case "outlier": {
                  if (value === 1) {
                    row.push("");
                  } else {
                    row.push("outlier");
                  }
                  // TODO this break was added later
                  break;
                }
                case "quantile": {
                  row.push("q:" + value);

                  break;
                }
                case "threshold": {
                  if (value === 1) {
                    row.push("over");
                  } else {
                    row.push("under");
                  }

                  break;
                }
                case "value": {
                  switch (columnRule["operator"]) {
                    case "bottom":
                    case "top": {
                      row.push(value);

                      break;
                    }
                    case "value":
                    default: {
                      row.push(
                        format.rationale(
                          // real property
                          removeFieldPrefix(key),
                          // alias property
                          key,
                          item,
                          "TEXT"
                        )
                      );
                    }
                  }

                  break;
                }

                // no default
              }
            } else {
              row.push(
                format.rationale(
                  // real property
                  removeFieldPrefix(key),
                  // alias property
                  key,
                  item,
                  "TEXT"
                )
              );
            }
        }
      } else {
        row.push("");
      }
    });
    result.push(row);
  });

  CSV.create(result, "rationale.csv");
};

const onClickExport = (columns, tableData, formatter) => {
  listenerExportCsvButton(columns, tableData?.data, formatter);
};

export default function ReactDialogRationale({
  closeFn,
  isLoading,
  tableData,
}: Props) {
  const [dataForExport, setDataForExport] = useState<any[]>([]);

  const formatter = useFormatter();
  const environment = useEnvironment();
  const [columns, setColumns] = useState<any[]>([]);

  const label = useMemo(
    () =>
      new Properties({
        properties: environment.get("setup")["properties"],
      }),
    [environment]
  );

  const { t } = useTranslation();

  useEffect(() => {
    let clmns: any = dataForExport.map((item) => {
      return {
        title: item.label,
        field: item.field ? item.field : "",
        columns: item.children.map((child) => child.renderCell),
      };
    });
    clmns = [
      {
        title: "",
        field: "index",
        formatter: (cell) => {
          const row = cell.getRow();
          return row.getPosition().toString();
        },
        widthGrow: 1,
      },
      ...clmns,
    ];
    setColumns(clmns);
  }, [dataForExport]);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    if (tableData != null) {
      const dd = dataPrepare(tableData, label, t, formatter);
      setDataForExport(dd);
    }
  }, [formatter, label, t, tableData]);

  const [updatedData, setUpdatedData] = useState([]);

  useEffect(() => {
    if (tableData) {
      const arr = tableData.data.map((item, index) => {
        return { ...item, index: index };
      });
      setUpdatedData(arr);
    }
  }, [tableData]);

  return (
    <Modal
      closeIcon={false}
      buttonsEnalbed={true}
      isResizable={true}
      isDraggable={true}
      buttons={[
        {
          name: "Export CSV",
          callback: () => {
            onClickExport(dataForExport, tableData, formatter);
          },
        },
        {
          name: "Cancel",
          callback: () => {
            closeFn(true);
          },
          variant: "cancel",
        },
      ]}
      hasOverlay={false}
      onClose={() => {
        closeFn(true);
      }}
      headerConfig={{ headerContent: "Rationale" }}
      customCss={{ maxWidth: "95%", width: "100%" }}
    >
      {isLoading ? (
        <RationaleLoader />
      ) : (
        <div ref={tableRef} className={style.reactRationale}>
          <TrendratingTable
            disableDefaultRowClick
            tooltip={{ actions: { info: { enabled: true } } }}
            options={{
              height: 500,
              ajaxSorting: false,
              layout: "fitColumns",
            }}
            autoResize={false}
            columns={columns ?? []}
            data={updatedData ?? []}
          />
        </div>
      )}
    </Modal>
  );
}

const RationaleLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={20} />
    </Stack>
  );
};
