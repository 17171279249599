import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../../../../../../components/Tooltip/Tooltip";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { DownUpGradesColumns } from "../../../../../../../js/app/components/ui/charts/DownUpGradesColumns";
import { Formatter } from "../../../../../../../trendrating/utils/Formatter";

type InstrumentProps = {
  data: any;
  timeframe: string;
};

const formatName = (value, formatter, environment, t) => {
  var name = {
    innerHTML: t("unknown"),
    title: null,
  };

  var taxonomies = environment.get("rawTaxonomies");

  name["innerHTML"] = formatter
    .pinnedName({
      item: {
        data: null,
        idInfo: value,
      },
      options: null,
    })
    .replace(/ - /g, "<br/>");
  var title = formatter.pinnedName({
    item: {
      data: null,
      idInfo: value,
    },
    options: {
      isShortened: false,
    },
    taxonomies: taxonomies,
  });
  if (name["innerHTML"] !== title) {
    name["title"] = title;
  }

  return name;
};

const prepareUriParams = (object) => {
  let urlParams: any = null;

  switch (object["type"].toLowerCase()) {
    case "commodity":
    case "currency":
    case "etf":
    case "index":
    case "instrument":
    case "sector":
    case "stock": {
      urlParams = { symbol: object["symbol"] };

      return urlParams;
    }
    case "peer": {
      urlParams = {
        size:
          object["size"] === "microLarge" || object["size"] == null
            ? "-"
            : object["size"],
        type: "stock_commons-stocks",
        what:
          object["what"] === "ICB" || object["what"] == null
            ? "-"
            : object["what"],
        where:
          object["where"] === "WWW" || object["where"] == null
            ? "-"
            : object["where"],
      };

      return urlParams;
    }
    default: {
      return urlParams;
    }
  }
};

export default function Peer({ data, timeframe }: InstrumentProps) {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const formatter = new Formatter(environment.get("setup"));
  const downgrades = data?.alerts?.downgrades ?? 0;
  const upgrades = data?.alerts?.upgrades ?? 0;
  const cssClassIsDisabled = "tPinnedObject-alert-isDisabled";
  const chart =
    data.chartData != null ? (
      <DownUpGradesColumns
        hasDataLabels={true}
        hasLegend={true}
        hasTooltip={true}
        hasXAxisLabels={true}
        height={250}
        margin={null}
        value={data["chartData"]}
        title={formatName(data, formatter, environment, t)["innerHTML"]}
        width={420}
      />
    ) : (
      <p>No data available</p>
    );
  const navigate = useNavigate();

  const handleButtonsClick = (property: "downgrades" | "upgrades") => {
    const value = { ...data };
    const uriParams = prepareUriParams(value);
    const { where, what, size, type } = uriParams;
    const uri = `screening/${type}/${timeframe}/${property}/${where}/${what}/${size}/`;

    navigate({
      pathname: `/app/${uri}`,
    });
  };

  return (
    <li className="tPinnedObject">
      <Tooltip content={chart} tooltipIcon={false}>
        <div className="tPinnedObject-spacer"></div>
        <div className="tPinnedObject-title">
          <div
            dangerouslySetInnerHTML={{
              __html: formatter.tcr(data["tcr"], "HTML"),
            }}
          ></div>
        </div>
        <div className="tPinnedObject-name">
          <span
            title={`${
              formatName(data, formatter, environment, t)?.title ?? ""
            }`}
            dangerouslySetInnerHTML={{
              __html: formatName(data, formatter, environment, t).innerHTML,
            }}
          ></span>
        </div>
        <div className="tPinnedObject-alerts tLayout">
          <div className="tLayout-item tLayout-item--width-50">
            <div
              className={`tPinnedObject-alert tPinnedObject-alert--left ${
                upgrades > 0 ? "" : cssClassIsDisabled
              }`}
              onClick={() => handleButtonsClick("upgrades")}
            >
              <div className="tPinnedObject-alertLabel">{t("upgrades")}</div>
              <div className="tPinnedObject-alertValue">
                {upgrades > 0 ? String(upgrades) : "-"}
              </div>
            </div>
          </div>
          <div className="tLayout-item tLayout-item--width-50">
            <div
              className={`tPinnedObject-alert tPinnedObject-alert--right ${
                downgrades > 0 ? "" : cssClassIsDisabled
              }`}
              onClick={() => handleButtonsClick("downgrades")}
            >
              <div className="tPinnedObject-alertLabel">{t("downgrades")}</div>
              <div className="tPinnedObject-alertValue">
                {downgrades > 0 ? String(downgrades) : "-"}
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </li>
  );
}
