export type AsOfTodayType = {
  benchmark: {
    content: string;
    isEnabled: boolean;
  };
  delta: {
    content: string;
    isEnabled: boolean;
  };
  headline: {
    content: string;
    isEnabled: boolean;
  };
  performance10Years: {
    content: string;
    isEnabled: boolean;
    strict: boolean;
    annualized: boolean;
  };
  performance1Day: {
    content: string;
    isEnabled: boolean;
  };
  performance1Month: {
    content: string;
    isEnabled: boolean;
  };
  performance1Week: {
    content: string;
    isEnabled: boolean;
  };
  performance1Year: {
    content: string;
    isEnabled: boolean;
    strict: boolean;
    annualized: boolean;
  };
  performance3Months: {
    content: string;
    isEnabled: boolean;
  };
  performance3Years: {
    content: string;
    isEnabled: boolean;
    strict: boolean;
    annualized: boolean;
  };
  performance5Years: {
    content: string;
    isEnabled: boolean;
    strict: boolean;
    annualized: boolean;
  };
  performanceLTD: {
    content: string;
    isEnabled: boolean;
    annualized: boolean;
  };
  performanceMTD: {
    content: string;
    isEnabled: boolean;
  };
  performanceQTD: {
    content: string;
    isEnabled: boolean;
  };
  performanceYTD: {
    content: string;
    isEnabled: boolean;
  };
  strategy: {
    content: string;
    isEnabled: boolean;
  };
};

export type ActionType = {
  type:
    | "SET_BENCHMARK_CONTENT"
    | "SET_BENCHMARK_ISENABLED"
    | "SET_DELTA_CONTENT"
    | "SET_DELTA_ISENABLED"
    | "SET_HEADLINE_CONTENT"
    | "SET_HEADLINE_ISENABLED"
    | "SET_PERFORMANCE_10_YEARS_CONTENT"
    | "SET_PERFORMANCE_10_YEARS_ISENABLED"
    | "SET_PERFORMANCE_10_YEARS_STRICT"
    | "SET_PERFORMANCE_10_YEARS_ANNUALIZED"
    | "SET_PERFORMANCE_1_DAY_CONTENT"
    | "SET_PERFORMANCE_1_DAY_ISENABLED"
    | "SET_PERFORMANCE_1_MONTH_CONTENT"
    | "SET_PERFORMANCE_1_MONTH_ISENABLED"
    | "SET_PERFORMANCE_1_WEEK_CONTENT"
    | "SET_PERFORMANCE_1_WEEK_ISENABLED"
    | "SET_PERFORMANCE_1_YEAR_CONTENT"
    | "SET_PERFORMANCE_1_YEAR_ISENABLED"
    | "SET_PERFORMANCE_1_YEARS_STRICT"
    | "SET_PERFORMANCE_1_YEARS_ANNUALIZED"
    | "SET_PERFORMANCE_3_MONTHS_CONTENT"
    | "SET_PERFORMANCE_3_MONTHS_ISENABLED"
    | "SET_PERFORMANCE_3_YEARS_CONTENT"
    | "SET_PERFORMANCE_3_YEARS_ISENABLED"
    | "SET_PERFORMANCE_3_YEARS_STRICT"
    | "SET_PERFORMANCE_3_YEARS_ANNUALIZED"
    | "SET_PERFORMANCE_5_YEARS_CONTENT"
    | "SET_PERFORMANCE_5_YEARS_ISENABLED"
    | "SET_PERFORMANCE_5_YEARS_STRICT"
    | "SET_PERFORMANCE_5_YEARS_ANNUALIZED"
    | "SET_PERFORMANCE_LTD_CONTENT"
    | "SET_PERFORMANCE_LTD_ISENABLED"
    | "SET_PERFORMANCE_LTD_ANNUALIZED"
    | "SET_PERFORMANCE_MTD_CONTENT"
    | "SET_PERFORMANCE_MTD_ISENABLED"
    | "SET_PERFORMANCE_QTD_CONTENT"
    | "SET_PERFORMANCE_QTD_ISENABLED"
    | "SET_PERFORMANCE_YTD_CONTENT"
    | "SET_PERFORMANCE_YTD_ISENABLED"
    | "SET_STRATEGY_CONTENT"
    | "SET_STRATEGY_ISENABLED";
  payload: any;
};

export const asOfTodayReducer = (draft: AsOfTodayType, action: ActionType) => {
  switch (action.type) {
    case "SET_BENCHMARK_CONTENT":
      draft.benchmark.content = action.payload;
      break;
    case "SET_BENCHMARK_ISENABLED":
      draft.benchmark.isEnabled = action.payload;
      break;
    case "SET_DELTA_CONTENT":
      draft.delta.content = action.payload;
      break;
    case "SET_DELTA_ISENABLED":
      draft.delta.isEnabled = action.payload;
      break;
    case "SET_HEADLINE_CONTENT":
      draft.headline.content = action.payload;
      break;
    case "SET_HEADLINE_ISENABLED":
      draft.headline.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_10_YEARS_CONTENT":
      draft.performance10Years.content = action.payload;
      break;
    case "SET_PERFORMANCE_10_YEARS_ISENABLED":
      draft.performance10Years.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_10_YEARS_STRICT":
      draft.performance10Years.strict = action.payload;
      break;
    case "SET_PERFORMANCE_10_YEARS_ANNUALIZED":
      draft.performance10Years.annualized = action.payload;
      break;
    case "SET_PERFORMANCE_1_DAY_CONTENT":
      draft.performance1Day.content = action.payload;
      break;
    case "SET_PERFORMANCE_1_DAY_ISENABLED":
      draft.performance1Day.isEnabled = action.payload;
      break;

    case "SET_PERFORMANCE_1_MONTH_CONTENT":
      draft.performance1Month.content = action.payload;
      break;
    case "SET_PERFORMANCE_1_MONTH_ISENABLED":
      draft.performance1Month.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_1_WEEK_CONTENT":
      draft.performance1Week.content = action.payload;
      break;
    case "SET_PERFORMANCE_1_WEEK_ISENABLED":
      draft.performance1Week.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_1_YEAR_CONTENT":
      draft.performance1Year.content = action.payload;
      break;
    case "SET_PERFORMANCE_1_YEAR_ISENABLED":
      draft.performance1Year.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_1_YEARS_STRICT":
      draft.performance1Year.strict = action.payload;
      break;
    case "SET_PERFORMANCE_1_YEARS_ANNUALIZED":
      draft.performance1Year.annualized = action.payload;
      break;
    case "SET_PERFORMANCE_3_MONTHS_CONTENT":
      draft.performance3Months.content = action.payload;
      break;
    case "SET_PERFORMANCE_3_MONTHS_ISENABLED":
      draft.performance3Months.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_3_YEARS_CONTENT":
      draft.performance3Years.content = action.payload;
      break;
    case "SET_PERFORMANCE_3_YEARS_ISENABLED":
      draft.performance3Years.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_3_YEARS_STRICT":
      draft.performance3Years.strict = action.payload;
      break;
    case "SET_PERFORMANCE_3_YEARS_ANNUALIZED":
      draft.performance3Years.annualized = action.payload;
      break;
    case "SET_PERFORMANCE_5_YEARS_CONTENT":
      draft.performance5Years.content = action.payload;
      break;
    case "SET_PERFORMANCE_5_YEARS_ISENABLED":
      draft.performance5Years.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_5_YEARS_STRICT":
      draft.performance5Years.strict = action.payload;
      break;
    case "SET_PERFORMANCE_5_YEARS_ANNUALIZED":
      draft.performance5Years.annualized = action.payload;
      break;
    case "SET_PERFORMANCE_LTD_CONTENT":
      draft.performanceLTD.content = action.payload;
      break;
    case "SET_PERFORMANCE_LTD_ANNUALIZED":
      draft.performanceLTD.annualized = action.payload;
      break;
    case "SET_PERFORMANCE_LTD_ISENABLED":
      draft.performanceLTD.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_MTD_CONTENT":
      draft.performanceMTD.content = action.payload;
      break;
    case "SET_PERFORMANCE_MTD_ISENABLED":
      draft.performanceMTD.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_QTD_CONTENT":
      draft.performanceQTD.content = action.payload;
      break;
    case "SET_PERFORMANCE_QTD_ISENABLED":
      draft.performanceQTD.isEnabled = action.payload;
      break;
    case "SET_PERFORMANCE_YTD_CONTENT":
      draft.performanceYTD.content = action.payload;
      break;
    case "SET_PERFORMANCE_YTD_ISENABLED":
      draft.performanceYTD.isEnabled = action.payload;
      break;
    case "SET_STRATEGY_CONTENT":
      draft.strategy.content = action.payload;
      break;
    case "SET_STRATEGY_ISENABLED":
      draft.strategy.isEnabled = action.payload;
      break;

    default:
      break;
  }
};
