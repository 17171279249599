/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app-account/pages/UnsupportedBrowserPage
 * @summary Unsupported browser page
 *
 */

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

declare const window: any;

export function UnsupportedBrowserPage() {
  const baseUrl = window.appConfig.baseUrl + "../img/user-agent/";
  const uaVersion = navigator.userAgent;
  const { t, ready } = useTranslation();

  useEffect(() => {
    if (ready) {
      document.title = `${t("Browser_not_supported")} - Trendrating`;
    }
  }, [t, ready]);

  return (
    <div className="PageUnsupportedBrowser">
      <h1>Sorry, you are using an old browser.</h1>

      <p>
        Trendrating is build on modern technologies. You have to update your
        browser or try another one. For the best experience we suggest:
      </p>

      <div className="PageUnsupportedBrowser-layout">
        <div className="PageUnsupportedBrowser-uaOption">
          <a
            className="PageUnsupportedBrowser-uaLink"
            href="https://www.microsoft.com/en-us/edge"
          >
            Microsoft Edge
            <img
              className="PageUnsupportedBrowser-uaLogo"
              src={`${baseUrl}microsoft_edge.png`}
              height="150"
              width="150"
              alt="Microsoft Edge logo"
            />
          </a>
        </div>
        <div className="PageUnsupportedBrowser-uaOption">
          <a
            className="PageUnsupportedBrowser-uaLink"
            href="https://www.google.com/chrome/"
          >
            Google Chrome
            <img
              className="PageUnsupportedBrowser-uaLogo"
              src={`${baseUrl}google_chrome.png`}
              height="150"
              width="150"
              alt="Google Chrome logo"
            />
          </a>
        </div>
        <div className="PageUnsupportedBrowser-uaOption">
          <a
            className="PageUnsupportedBrowser-uaLink"
            href="https://www.mozilla.org/en-US/firefox/new/"
          >
            Mozilla Firefox
            <img
              className="PageUnsupportedBrowser-uaLogo"
              src={`${baseUrl}mozilla_firefox.png`}
              height="150"
              width="150"
              alt="Mozilla Firefox logo"
            />
          </a>
        </div>
      </div>

      <p>
        More information about your current browser:
        <span className="PageUnsupportedBrowser-uaVersion">{uaVersion}</span>
      </p>
    </div>
  );
}
