import { Box } from "@mui/material";
import { useMemo, useRef } from "react";
import { Chart } from "../../../../../../../../../../components/Chart";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";

type Props = {
  data: any;
};

export default function Scatter({ data }: Props) {
  const chartRef = useRef(null);
  const format = useFormatter();

  const chartOptions = useMemo(
    () => getChartOptions(data.chartScatter, format) ,
    [data.chartScatter, format]
  );

  return (
    <Box width={"100%" } height={"100%"} position="relative">
        <Chart  constructorType={"chart"} ref={chartRef} options={chartOptions}/>
    </Box>
  );
}
const mapYearsToPoints = (propertyX, propertyY) => {
  return function (item) {
    var mapped = {
      name: item.timeFrame,
      x: item[propertyX],
      y: item[propertyY],
    };

    return mapped;
  };
};
const getChartOptions = (value, format) => {
  var data = value;
  var strategy = data.strategy;
  var strategyInstrumentBenchmark = data.strategyInstrumentBenchmark;
  var total = data.total;
  var years = data.years;

  // strategy
  var dataStrategyTotal: any = [];
  var dataStrategyYears: any = [];
  // strategy total
  dataStrategyTotal.push({
    name: strategy.name,
    x: total.equity.StdDev,
    y: total.equity.AnnualizedRateOfReturn,
  });
  // strategy years
  dataStrategyYears = years.map(
    mapYearsToPoints("strategyVolatility", "strategyYearlyPerformance")
  );
  // benchmark
  if (strategyInstrumentBenchmark) {
    var dataBenchmarkTotal: any = [];
    var dataBenchmarkYears: any = [];
    // benchmark total
    dataBenchmarkTotal.push({
      name: strategyInstrumentBenchmark.name,
      x: total.benchmark.StdDev,
      y: total.benchmark.AnnualizedRateOfReturn,
    });
    // benchmark years
    dataBenchmarkYears = years.map(
      mapYearsToPoints("benchmarkVolatility", "benchmarkYearlyPerformance")
    );
  }
  // computing global max and min
  var xMax: any = null;
  var xMin: any = null;
  var yMax: any = null;
  var yMin: any = null;
  if (strategyInstrumentBenchmark) {
    xMax =
      Math.max(total.equity.StdDev, total.benchmark.StdDev) +
      Math.abs(total.equity.StdDev - total.benchmark.StdDev) / 2;
    xMin =
      Math.min(total.equity.StdDev, total.benchmark.StdDev) -
      Math.abs(total.equity.StdDev - total.benchmark.StdDev) / 2;
    yMax =
      Math.max(
        total.equity.AnnualizedRateOfReturn,
        total.benchmark.AnnualizedRateOfReturn
      ) +
      Math.abs(
        total.equity.AnnualizedRateOfReturn -
          total.benchmark.AnnualizedRateOfReturn
      ) /
        2;
    yMin =
      Math.min(
        total.equity.AnnualizedRateOfReturn,
        total.benchmark.AnnualizedRateOfReturn
      ) -
      Math.abs(
        total.equity.AnnualizedRateOfReturn -
          total.benchmark.AnnualizedRateOfReturn
      ) /
        2;
  } else {
    xMax = total.equity.StdDev + Math.abs(total.equity.StdDev / 2);
    xMin = total.equity.StdDev - Math.abs(total.equity.StdDev / 2);
    yMax =
      total.equity.AnnualizedRateOfReturn +
      Math.abs(total.equity.AnnualizedRateOfReturn / 2);
    yMin =
      total.equity.AnnualizedRateOfReturn -
      Math.abs(total.equity.AnnualizedRateOfReturn / 2);
  }
  // computing local max and min
  var xMaxLocal: any = dataStrategyYears[0].x;
  var xMinLocal: any = dataStrategyYears[0].x;
  var yMaxLocal: any = dataStrategyYears[0].y;
  var yMinLocal: any = dataStrategyYears[0].y;
  var point: any = null;
  for (var i = 1, length = dataStrategyYears.length; i < length; i++) {
    point = dataStrategyYears[i];
    if (point.x > xMaxLocal) {
      xMaxLocal = point.x;
    }
    if (point.x < xMinLocal) {
      xMinLocal = point.x;
    }
    if (point.y > yMaxLocal) {
      yMaxLocal = point.y;
    }
    if (point.y < yMinLocal) {
      yMinLocal = point.y;
    }
  }
  if (strategyInstrumentBenchmark) {
    for (let i = 0; i < dataBenchmarkYears.length; i++) {
      point = dataBenchmarkYears[i];
      if (point.x > xMaxLocal) {
        xMaxLocal = point.x;
      }
      if (point.x < xMinLocal) {
        xMinLocal = point.x;
      }
      if (point.y > yMaxLocal) {
        yMaxLocal = point.y;
      }
      if (point.y < yMinLocal) {
        yMinLocal = point.y;
      }
    }
  }
  // computing chart borders keeping into account global and local max and min
  xMax = Math.max(xMax, xMaxLocal);
  xMin = Math.min(xMin, xMinLocal);
  yMax = Math.max(yMax, yMaxLocal);
  yMin = Math.min(yMin, yMinLocal);
  // preparing series
  var listenerPoint = _listenerPoint;
  var series: any = [];
  series.push({
    color: "#0da760",
    data: dataStrategyTotal,
    id: "SERIE_STRATEGY_TOTAL",
    marker: {
      symbol: "circle",
      radius: 8,
    },
    name: strategy.name,
    type: "scatter",
    zIndex: 9,
  });
  series.push({
    color: "#0da760",
    data: dataStrategyYears,
    events: {
      click: listenerPoint,
    },
    id: "SERIE_STRATEGY_YEARS",
    marker: {
      symbol: "circle",
      radius: 3,
    },
    name: "Years",
    showInLegend: false,
    type: "scatter",
    visible: true,
    zIndex: 7,
  });
  series.push({
    color: "rgba(13, 167, 96, .5)",
    data: _getVertex(dataStrategyYears),
    enableMouseTracking: false,
    events: {
      legendItemClick: (e) => _listenerLegend(e),
    },
    id: "SERIE_STRATEGY_POLYGON",
    lineWidth: 0,
    name: "Strategy consistency",
    type: "polygon",
    visible: true,
    zIndex: 5,
  });
  if (strategyInstrumentBenchmark) {
    series.push({
      color: "#2A7092",
      data: dataBenchmarkTotal,
      id: "SERIE_BENCHMARK_TOTAL",
      marker: {
        symbol: "circle",
        radius: 6,
      },
      name: strategyInstrumentBenchmark.name,
      type: "scatter",
      zIndex: 8,
    });
    series.push({
      color: "#2A7092",
      data: dataBenchmarkYears,
      events: {
        click: listenerPoint,
      },
      id: "SERIE_BENCHMARK_YEARS",
      marker: {
        symbol: "circle",
        radius: 3,
      },
      name: "Years",
      showInLegend: false,
      type: "scatter",
      visible: true,
      zIndex: 6,
    });
    series.push({
      color: "rgba(42, 112, 146, .3)",
      data: _getVertex(dataBenchmarkYears),
      enableMouseTracking: false,
      events: {
        legendItemClick: (e) => _listenerLegend(e),
      },
      id: "SERIE_BENCHMARK_POLYGON",
      lineWidth: 0,
      name: "Benchmark consistency",
      type: "polygon",
      visible: true,
      zIndex: 4,
    });
  }

  var xPlotLines: any = [
    {
      color: "#0da760",
      value: total.equity.StdDev,
      width: 2,
    },
  ];
  var yPlotLines: any = [
    {
      color: "#0da760",
      value: total.equity.AnnualizedRateOfReturn,
      width: 2,
    },
  ];

  if (strategyInstrumentBenchmark) {
    xPlotLines.push({
      color: "#2A7092",
      dashStyle: "Dash",
      value: total.benchmark.StdDev,
      width: 1,
    });
    yPlotLines.push({
      color: "#2A7092",
      dashStyle: "Dash",
      value: total.benchmark.AnnualizedRateOfReturn,
      width: 1,
    });
  }

  var formatOptionsPercentage = {
    isPercentage: true,
    notAvailable: {
      input: null,
      output: "",
    },
  };

  let chartOptions: Highcharts.Options = {
    chart: {
        height: "auto",
      // renderTo: this.domNode,
      type: "scatter",
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      scatter: {
        // shadow: true,
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      series: {
        cursor: "pointer",
      },
    },
    scrollbar: { enabled: false },
    series: series,
    title: {
      align: "left",
      style: {
        color: "#000",
        fontFamily: "Open Sans",
        fontWeight: "400",
      },
      text: "Return | Volatility profile",
    },
    tooltip: {
      headerFormat: "<span>{point.key}</span><br/>",
      pointFormatter: function () {
        return [
          "Return: <strong>" +
            format.custom("number", {
              options: formatOptionsPercentage,
              output: "HTML",
              value: this.y,
              valueHelper: null,
            }) +
            "</strong>",
          "Volatility: <strong>" +
            format.custom("number", {
              options: formatOptionsPercentage,
              output: "HTML",
              value: this.x,
              valueHelper: null,
            }) +
            "</strong>",
        ].join("<br/>");
      },
    },
    xAxis: {
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          return format.custom("number", {
            options: formatOptionsPercentage,
            output: "HTML",
            value: this.value,
            valueHelper: null,
          });
        },
        style: {
          color: "#000",
          fontFamily: "Open Sans",
        },
      },
      lineWidth: 0,
      max: xMax,
      min: xMin,
      plotLines: xPlotLines,
      tickWidth: 0,
      title: {
        text: "Annualized volatility",
        style: {
          color: "#000",
          fontFamily: "Open Sans",
          fontWeight: "400",
        },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        formatter: function () {
          return format.custom("number", {
            options: formatOptionsPercentage,
            output: "HTML",
            value: this.value,
            valueHelper: null,
          });
        },
        style: {
          color: "#000",
          fontFamily: "Open Sans",
        },
      },
      lineWidth: 0,
      max: yMax,
      min: yMin,
      plotLines: yPlotLines,
      tickWidth: 0,
      title: {
        text: "Annualized return",
        style: {
          color: "#000",
          fontFamily: "Open Sans",
          fontWeight: "400",
        },
      },
    },
  };
  return chartOptions;
};
const _listenerPoint = (event, chartObject) => {
  var point = event.point;
  let chart = event.target.chart;

  var serieYearsBenchmark = chart.get("SERIE_BENCHMARK_YEARS");
  var serieYearsStrategy = chart.get("SERIE_STRATEGY_YEARS");

  if (serieYearsBenchmark) {
    var dataBenchmark = serieYearsBenchmark.data;
    var dataStrategy = serieYearsStrategy.data;

    var serieCompare = chart.get("SERIE_COMPARE_YEARS");

    var data: any = [];
    for (let i = 0; i < dataStrategy.length; i++) {
      if (dataStrategy[i].options.name === point.options.name) {
        data.push({
          name: point.options.name,
          x: dataStrategy[i].x,
          y: dataStrategy[i].y,
        });
      }
    }
    for (let i = 0; i < dataBenchmark.length; i++) {
      if (dataBenchmark[i].options.name === point.options.name) {
        data.push({
          name: point.options.name,
          x: dataBenchmark[i].x,
          y: dataBenchmark[i].y,
        });
      }
    }

    var enabled = !(serieYearsBenchmark.visible && serieYearsStrategy.visible);

    if (serieCompare) {
      serieCompare.update({
        marker: {
          enabled: enabled,
          symbol: "circle",
        },
      });
      serieCompare.setData(data);
    } else {
      chartObject.addSeries({
        color: "red",
        dashStyle: "Dash",
        events: {
          click: function (event) {
            event.target.remove();
          },
        },
        data: data,
        id: "SERIE_COMPARE_YEARS",
        lineWidth: 1,
        marker: {
          enabled: enabled,
          symbol: "circle",
        },
        showInLegend: false,
        type: "line",
        zIndex: 20,
      });
    }
  } else {
    return;
  }
};
const _getVertex = (points) => {
  function removeMiddle(a, b, c) {
    var cross = (a.x - b.x) * (c.y - b.y) - (a.y - b.y) * (c.x - b.x);
    var dot = (a.x - b.x) * (c.x - b.x) + (a.y - b.y) * (c.y - b.y);
    return cross < 0 || (cross === 0 && dot <= 0);
  }

  points.sort(function (a, b) {
    return a.x !== b.x ? a.x - b.x : a.y - b.y;
  });

  var n = points.length;
  var hull: any = [];

  for (let i = 0; i < 2 * n; i++) {
    var j = i < n ? i : 2 * n - 1 - i;
    while (
      hull.length >= 2 &&
      removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])
    ) {
      hull.pop();
    }

    hull.push(points[j]);
  }

  hull.pop();

  return hull;
};
const _listenerLegend = (event) => {
  event.preventDefault();

  let chart = event.target.chart;
  var id = event.target.options.id;
  var isVisible = !event.target.visible;
  switch (id) {
    case "SERIE_BENCHMARK_POLYGON": {
      chart.get("SERIE_BENCHMARK_YEARS").setVisible(isVisible);
      chart.get("SERIE_BENCHMARK_POLYGON").setVisible(isVisible);
      break;
    }
    case "SERIE_STRATEGY_POLYGON": {
      chart.get("SERIE_STRATEGY_YEARS").setVisible(isVisible);
      chart.get("SERIE_STRATEGY_POLYGON").setVisible(isVisible);
      break;
    }
    // no default
  }
};
