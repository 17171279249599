import Block from "../../../../../../../components/LayoutElements/Block/Block";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import styles from "./Alternatives.module.scss";
import { AlternativesState } from "./AlternativesReducer";

type AlternativesHeaderProps = {
  portfolio: any;
};

const getStockCard = (portfolio: AlternativesState["portfolio"]) => {
  const result: any = {
    cardinality: { C: 0, D: 0 },
    perc: 0,
  };

  const statistics = portfolio ?? null;

  if (!statistics) {
    return result;
  }

  result["cardinality"]["C"] = statistics.cardinalityPerRating["C"];
  result["cardinality"]["D"] = statistics.cardinalityPerRating["D"];
  result["perc"] = Object.entries<any>(statistics.weightsPerRating).reduce(
    (prev, [key, value]) => {
      if (key === "C" || key === "D") {
        if (value) {
          return (prev += value);
        }
      }

      return prev;
    },
    0
  );
  return result;
};

const composeHeader = (format, portfolio) => {
  if (portfolio == null) {
    return "";
  }

  const stocksInfos = getStockCard(portfolio);

  const { cardinality, perc } = stocksInfos;

  const cStyled = `<strong
    class="tAnalysisOverview-rate format-rate format-rate--C"
>
    C
</strong>`;

  const dStyled = `<strong
    class="tAnalysisOverview-rate format-rate format-rate--D"
>
    D
</strong>`;

  let text = "There are ";

  if (
    (!cardinality.C || cardinality.C === 0) &&
    (!cardinality.D || cardinality.D === 0)
  ) {
    return (
      `This ${portfolio.type.toLowerCase()} does not contain any &nbsp` +
      cStyled +
      "&nbsp or &nbsp" +
      dStyled +
      "&nbsp rated securities"
    );
  }

  if (cardinality.C && cardinality.C !== 0) {
    text += `${cardinality.C} ${cStyled}`;
  }

  if (cardinality.D && cardinality.D !== 0) {
    if (cardinality.C && cardinality.C !== 0) {
      text += ` and ${cardinality.D} ${dStyled}`;
    } else {
      text += `${cardinality.D} ${dStyled}`;
    }
  }

  const weight = format.custom("number", {
    options: {
      hasPositiveSign: false,
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "0",
      },
    },
    output: "TEXT",
    value: perc,
    valueHelper: null,
  });

  text += `that match the ${weight} of the ${portfolio.type.toLowerCase()}`;

  return "<p>" + text + "</p>";
};

export default function AlternativesHeader({
  portfolio,
}: AlternativesHeaderProps) {
  const formatter = useFormatter();

  return (
    <Block styleProps={{ alignItems: "center" }}>
      <div
        className={styles.header__summary__portfolio__wrapper}
        dangerouslySetInnerHTML={{
          __html: composeHeader(formatter, portfolio),
        }}
      ></div>
    </Block>
  );
}
