const marketcap_collection = {
  rangeMarketCap: (
    <section className="" data-help-topic="marketcap|rangeMarketCap">
      <h3>Market cap</h3>
      <p>
        Market cap in range: limit the investment universe by ranges of market
        capitalization.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> Important
        </div>
        <p>
          Consider to select larger size stocks for consistent but lower
          returns.
        </p>
      </div>
      <p>
        Smaller-cap stocks historically generated higher risk-adjusted returns,
        you should expect the size premium to be material and positive in the
        long run, it is less predictable in the short term.
      </p>
    </section>
  ),
  greaterThan: (
    <section className="" data-help-topic="marketcap|greaterThan">
      <h3>Market cap</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of mkt
        cap. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="marketcap|lessThan">
      <h3>Market cap</h3>
      <p>
        Is less than: limit the investment universe by adding a value of mkt
        cap. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="marketcap|top">
      <h3>Market cap</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        mkt cap. The default value is 10. This means that only the top 10
        highest mkt cap securities are included in the investment universe.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="marketcap|bottom">
      <h3>Market cap</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by mkt cap. The default value is 10. This means that only the
        lowest 10 mkt cap securities are included in the investment universe.
      </p>
    </section>
  ),
};
const mc_collection = {
  greaterThan: (
    <section className="" data-help-topic="mc|greaterThan">
      <h3>Smart Momentum - Value</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of
        smart momentum. Only securities above the entered value are included in
        the investment universe.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> How it works
        </div>
        <table>
          <thead>
            <tr>
              <th>Rating</th>
              <th>Range</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="format-rate format-rate--A">A</span>
              </td>
              <td>2.0 and 3.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--B">B</span>
              </td>
              <td>1.0 and 2.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--C">C</span>
              </td>
              <td>-1.0 and -2.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--D">D</span>
              </td>
              <td>-2.0 and -3.0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="mc|lessThan">
      <h3>Smart Momentum - Value</h3>
      <p>
        Is less than: limit the investment universe by adding a value of smart
        momentum. Only securities below the entered value are included in the
        investment universe.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> How it works
        </div>
        <table>
          <thead>
            <tr>
              <th>Rating</th>
              <th>Range</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="format-rate format-rate--A">A</span>
              </td>
              <td>2.0 and 3.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--B">B</span>
              </td>
              <td>1.0 and 2.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--C">C</span>
              </td>
              <td>-1.0 and -2.0</td>
            </tr>
            <tr>
              <td>
                <span className="format-rate format-rate--D">D</span>
              </td>
              <td>-2.0 and -3.0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  ),
  range: (
    <section className="" data-help-topic="mc|range">
      <h3>Smart Momentum - Value</h3>
      <p>
        Between: limit the investment universe by separating the universe with
        one or two segmentation of smart momentum.
      </p>
      <table className="tHelpItemTable">
        <thead>
          <tr>
            <th>Rating</th>
            <th>Perf. since trend</th>
            <th>Smart Momentum score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--A">500%</span>
            </td>
            <td>3.000</td>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--A">100%</span>
            </td>
            <td>2.333</td>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--A">50%</span>
            </td>
            <td>2.250</td>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--A">10%</span>
            </td>
            <td>2.183</td>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--A">0%</span>
            </td>
            <td>
              <strong>2.167</strong>
            </td>
          </tr>
          <tr>
            <td>A</td>
            <td>
              <span className="format-rate format-rate--D">-10%</span>
            </td>
            <td>2.150</td>
          </tr>
        </tbody>
      </table>
      <table className="tHelpItemTable">
        <thead>
          <tr>
            <th>Rating</th>
            <th>Perf. since trend</th>
            <th>Smart Momentum score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>D</td>
            <td>
              <span className="format-rate format-rate--A">20%</span>
            </td>
            <td>-2.800</td>
          </tr>
          <tr>
            <td>D</td>
            <td>
              <span className="format-rate">0%</span>
            </td>
            <td>-2.833</td>
          </tr>
          <tr>
            <td>D</td>
            <td>
              <span className="format-rate format-rate--D">-20%</span>
            </td>
            <td>-2.867</td>
          </tr>
          <tr>
            <td>D</td>
            <td>
              <span className="format-rate format-rate--D">-50%</span>
            </td>
            <td>-2.917</td>
          </tr>
          <tr>
            <td>D</td>
            <td>
              <span className="format-rate format-rate--D">-100%</span>
            </td>
            <td>
              <strong>-3.000</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  ),
  top: (
    <section className="" data-help-topic="mc|top">
      <h3>Smart Momentum - Value</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        smart momentum. The default value is 10. This means that the investment
        universe is composed only of the 10 securities with the highest smart
        momentum.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="mc|bottom">
      <h3>Smart Momentum - Value</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by smart momentum. The default value is 10. This means that
        the investment universe is composed only of the 10 securities with the
        lowest smart momentum.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="mc|rangeQuartile">
      <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Smart momentum score. Securities with the highest SM
        scores reside in Quantiles 1.By selecting the 1 st quantile, the
        investment universe is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="mc|rangeQuintile">
      <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Smart momentum score. Securities with the highest SM
        scores reside in Quantiles 1.By selecting the 1 st quantile, the
        investment universe is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="mc|rangeDecile">
      <h3>Smart Momentum - Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Smart momentum score. Securities with the highest SM
        scores reside in Quantiles 1.By selecting the 1 st quantile, the
        investment universe is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};

const sd_collection = {
  rangeVolatility: (
    <section className="" data-help-topic="sd|rangeVolatility">
      <h3>Volatility: Value</h3>
      <p>
        Volatility is the annualized standard deviation of rolling 20 days
        returns.
        <br />
        Volatility in range: narrow the investment universe by ranges of
        volatility. Low &lt; 10% Mid (10%- 50%) High &gt; 50%.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> Important
        </div>
        <p>
          Select stocks with volatility bellow 30% if you want a less risky
          strategy. Avoiding high volatile stocks can limit the performance of
          the strategy during strong bull trends.
        </p>
      </div>
    </section>
  ),
  greaterThanPercentage: (
    <section className="" data-help-topic="sd|greaterThanPercentage">
      <h3>Volatility: Value</h3>
      <p>
        Volatility is the annualized standard deviation of rolling 20 days
        returns.
        <br />
        Is greater than: limit the investment universe by adding a value of
        volatility. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="sd|lessThanPercentage">
      <h3>Volatility: Value</h3>
      <p>
        Volatility is the annualized standard deviation of rolling 20 days
        returns.
        <br />
        Is less than: limit the investment universe by adding a value of
        volatility. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="sd|top">
      <h3>Volatility: Value</h3>
      <p>
        Volatility is the annualized standard deviation of rolling 20 days
        returns.
        <br />
        Top: limit the investment universe by taking the Top "n" securities by
        volatility. The default value is 10. This means that the investment
        universe is composed only of the 10 securities with the highest
        volatility.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="sd|bottom">
      <h3>Volatility: Value</h3>
      <p>
        Volatility is the annualized standard deviation of rolling 20 days
        returns.
        <br />
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by volatility. The default value is 10. This means that the
        investment universe is composed only of the 10 securities with the
        lowest volatility.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="sd|rangeQuartile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="sd|rangeQuintile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="sd|rangeDecile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const sdlt_collection = {
  rangeVolatility: (
    <section className="" data-help-topic="sd|rangeVolatility">
      <h3>Volatility: Value</h3>
      <p>
        Volatility&#40;Long Term&#41; is the annualized standard deviation of
        rolling weekly return of the last 3 years.
        <br />
        Volatility in range: narrow the investment universe by ranges of
        volatility. Low &lt; 10% Mid (10%- 50%) High &gt; 50%.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> Important
        </div>
        <p>
          Select stocks with volatility bellow 30% if you want a less risky
          strategy. Avoiding high volatile stocks can limit the performance of
          the strategy during strong bull trends.
        </p>
      </div>
    </section>
  ),
  greaterThanPercentage: (
    <section className="" data-help-topic="sd|greaterThanPercentage">
      <h3>Volatility: Value</h3>
      <p>
        Volatility&#40;Long Term&#41; is the annualized standard deviation of
        rolling weekly return of the last 3 years.
        <br />
        Is greater than: limit the investment universe by adding a value of
        volatility. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="sd|lessThanPercentage">
      <h3>Volatility: Value</h3>
      <p>
        Volatility&#40;Long Term&#41; is the annualized standard deviation of
        rolling weekly return of the last 3 years.
        <br />
        Is less than: limit the investment universe by adding a value of
        volatility. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="sd|top">
      <h3>Volatility: Value</h3>
      <p>
        Volatility&#40;Long Term&#41; is the annualized standard deviation of
        rolling weekly return of the last 3 years.
        <br />
        Top: limit the investment universe by taking the Top "n" securities by
        volatility. The default value is 10. This means that the investment
        universe is composed only of the 10 securities with the highest
        volatility.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="sd|bottom">
      <h3>Volatility: Value</h3>
      <p>
        Volatility&#40;Long Term&#41; is the annualized standard deviation of
        rolling weekly return of the last 3 years.
        <br />
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by volatility. The default value is 10. This means that the
        investment universe is composed only of the 10 securities with the
        lowest volatility.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="sd|rangeQuartile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="sd|rangeQuintile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="sd|rangeDecile">
      <h3>Volatility: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the volatility. Securities with the highest volatility
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const px_collection = {
  greaterThanPercentage: (
    <section className="" data-help-topic="px|greaterThanPercentage">
      <h3>Retracement: Value</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of
        retracement. Only securities above the entered value are included in the
        investment universe.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> Trendrating's recommendation
        </div>
        <p>
          It is a good rule to define a retracement constraint no larger than a
          certain percentage, for example no less than -15%
        </p>
      </div>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="px|lessThanPercentage">
      <h3>Retracement: Value</h3>
      <p>
        Is less than: limit the investment universe by adding a value of
        retracement. Only securities below the entered value are included in the
        investment universe.
      </p>
      <div className="tHelp-itemTips">
        <div className="tHelp-itemTips-top">
          <span className="i-edu"></span> Trendrating's recommendation
        </div>
        <p>
          It is a good rule to define a retracement constraint no larger than a
          certain percentage, for example no less than -15%
        </p>
      </div>
    </section>
  ),
  rangePercentage: (
    <section className="" data-help-topic="px|rangePercentage">
      <h3>Retracement: Value</h3>
      <p>
        Between: limit the investment universe by separating the universe with
        one or two segmentation of retracement.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="px|top">
      <h3>Retracement: Value</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        retracement. The default value is 10. This means that the investment
        universe is composed only of the 10 securities with the highest
        retracement.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="px|bottom">
      <h3>Retracement: Value</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by retracement. The default value is 10. This means that the
        investment universe is composed only of the 10 securities with the
        lowest retracement.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="px|rangeQuartile">
      <h3>Retracement: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the retracement. Securities with the highest retracement
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="px|rangeQuintile">
      <h3>Retracement: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the retracement. Securities with the highest retracement
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="px|rangeDecile">
      <h3>Retracement: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the retracement. Securities with the highest retracement
        reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const pr_collection = {
  greaterThanPercentage: (
    <section className="" data-help-topic="pr|greaterThanPercentage">
      <h3>Performance since rated</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of
        performance. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="pr|lessThanPercentage">
      <h3>Performance since rated</h3>
      <p>
        Is less than: limit the investment universe by adding a value of
        performance. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  rangePercentage: (
    <section className="" data-help-topic="pr|rangePercentage">
      <h3>Performance since rated</h3>
      <p>
        Between: limit the investment universe by separating the universe with
        one or two segmentation of perf. since rated.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="pr|top">
      <h3>Performance since rated</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        perf. since rated. The default value is 10. This means that the
        investment universe is composed only of the 10 securities with the
        highest perf. since rated.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="pr|bottom">
      <h3>Performance since rated</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by perf. since rated. The default value is 10. This means
        that the investment universe is composed only of the 10 securities with
        the lowest perf. since rated.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="pr|rangeQuartile">
      <h3>Performance since rated: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since rated. Securities with the highest
        perf. since rated reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="pr|rangeQuintile">
      <h3>Performance since rated: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since rated. Securities with the highest
        perf. since rated reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="pr|rangeDecile">
      <h3>Performance since rated: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since rated. Securities with the highest
        perf. since rated reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const py_collection = {
  greaterThanPercentage: (
    <section className="" data-help-topic="py|greaterThanPercentage">
      <h3>Performance last 12 months</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of
        performance. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="py|lessThanPercentage">
      <h3>Performance last 12 months</h3>
      <p>
        Is less than: limit the investment universe by adding a value of
        performance. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  rangePercentage: (
    <section className="" data-help-topic="py|rangePercentage">
      <h3>Performance last 12 months</h3>
      <p>
        Between: limit the investment universe by separating the universe with
        one or two segmentation of perf. over the last 12 months.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="py|top">
      <h3>Performance last 12 months</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        perf. over the last 12 months. The default value is 10. This means that
        the investment universe is composed only of the 10 securities with the
        highest perf. over the last 12 months.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="py|bottom">
      <h3>Performance last 12 months</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by perf. over the last 12 months. The default value is 10.
        This means that the investment universe is composed only of the 10
        securities with the lowest perf. over the last 12 months.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="py|rangeQuartile">
      <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance over the last 12 months. Securities with
        the highest perf. over the last 12 months reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="py|rangeQuintile">
      <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance over the last 12 months. Securities with
        the highest perf. over the last 12 months reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="py|rangeDecile">
      <h3>Performance last 12 months: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance over the last 12 months. Securities with
        the highest perf. over the last 12 months reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const pt_collection = {
  greaterThanPercentage: (
    <section className="" data-help-topic="pt|greaterThanPercentage">
      <h3>Performance since trend</h3>
      <p>
        Is greater than: limit the investment universe by adding a value of
        performance. Only securities above the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  lessThanPercentage: (
    <section className="" data-help-topic="pt|lessThanPercentage">
      <h3>Performance since trend</h3>
      <p>
        Is less than: limit the investment universe by adding a value of
        performance. Only securities below the entered value are included in the
        investment universe.
      </p>
    </section>
  ),
  rangePercentage: (
    <section className="" data-help-topic="pt|rangePercentage">
      <h3>Performance since trend</h3>
      <p>
        Between: limit the investment universe by separating the universe with
        one or two segmentation of perf. since trend.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="pt|top">
      <h3>Performance since trend</h3>
      <p>
        Top: limit the investment universe by taking the Top "n" securities by
        perf. since trend. The default value is 10. This means that the
        investment universe is composed only of the 10 securities with the
        highest perf. since trend.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="pt|bottom">
      <h3>Performance since trend</h3>
      <p>
        Bottom: limit the investment universe by taking the Bottom "n"
        securities by perf. since trend. The default value is 10. This means
        that the investment universe is composed only of the 10 securities with
        the lowest perf. since trend.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="pt|rangeQuartile">
      <h3>Performance since trend: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since trend. Securities with the highest
        perf. since trend reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="pt|rangeQuintile">
      <h3>Performance since trend: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since trend. Securities with the highest
        perf. since trend reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="pt|rangeDecile">
      <h3>Performance since trend: Quartile – Quintile - Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the performance since trend. Securities with the highest
        perf. since trend reside in Quantiles 1.
        <br />
        By selecting the 1<sup>st</sup> quantile, the investment universe is
        composed only of the securities belonging to the first quantile.
      </p>
    </section>
  ),
};
const ts_collection = {
  greaterThan: (
    <section className="" data-help-topic="ts|greaterThan">
      <h3>Trend strength - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Trend strength. Only securities
        above the entered value are included in the portion of the investment
        universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="ts|lessThan">
      <h3>Trend strength - Is less than</h3>
      <p>
        Limit the selection by adding a value of Trend strength. Only securities
        below the entered value are included in the portion of the investment
        universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="ts|range">
      <h3>Trend strength : Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Trend strength.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="ts|top">
      <h3>Trend strength - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Trend strength.
        The default value is 10. This means that the portion of the investment
        universe eligible to be included in the portfolio is composed only of
        the 10 securities with the highest Trend strength.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="ts|bottom">
      <h3>Trend strength - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Trend strength. The default value is 10. This means that the portion of
        the investment universe eligible to be included in the portfolio is
        composed only of the 10 securities with the lowest Trend strength.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="ts|rangeQuartile">
      <h3>Trend strength : Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Trend strength. Securities with the highest Trend
        strength reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="ts|rangeQuintile">
      <h3>Trend strength : Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Trend strength. Securities with the highest Trend
        strength reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="ts|rangeDecile">
      <h3>Trend strength : Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Trend strength. Securities with the highest Trend
        strength reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
};
const f_pe_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_pe|greaterThan">
      <h3>Price/Earnings Ratio - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Price/Earnings ratio. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_pe|lessThan">
      <h3>Price/Earnings Ratio - Is less than</h3>
      <p>
        Limit the selection by adding a value of Price/Earnings ratio. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_pe|range">
      <h3>Price/Earnings Ratio: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Price/Earnings ratio.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_pe|top">
      <h3>Price/Earnings Ratio - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Price/Earnings
        ratio. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Price/Earnings ratio.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_pe|bottom">
      <h3>Price/Earnings Ratio - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Price/Earnings ratio. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest
        Price/Earnings ratio.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_pe|rangeQuartile">
      <h3>Price/Earnings Ratio: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Earnings ratio. Securities with the highest
        Price/Earnings ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_pe|rangeQuintile">
      <h3>Price/Earnings Ratio: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Earnings ratio. Securities with the highest
        Price/Earnings ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_pe|rangeDecile">
      <h3>Price/Earnings Ratio: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Earnings ratio. Securities with the highest
        Price/Earnings ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
const f_ps_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_ps|greaterThan">
      <h3>Price/Sales Ratio - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Price/Sales ratio. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_ps|lessThan">
      <h3>Price/Sales Ratio - Is less than</h3>
      <p>
        Limit the selection by adding a value of Price/Sales ratio. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_ps|range">
      <h3>Price/Sales Ratio: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Price/Sales ratio.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_ps|top">
      <h3>Price/Sales Ratio - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Price/Sales
        ratio. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Price/Sales ratio.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_ps|bottom">
      <h3>Price/Sales Ratio - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Price/Sales ratio. The default value is 10. This means that the portion
        of the investment universe eligible to be included in the portfolio is
        composed only of the 10 securities with the lowest Price/Sales ratio.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_ps|rangeQuartile">
      <h3>Price/Sales Ratio: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Sales ratio. Securities with the highest
        Price/Sales ratio reside in Quantiles 1. By selecting the 1st quantile,
        the portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_ps|rangeQuintile">
      <h3>Price/Sales Ratio: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Sales ratio. Securities with the highest
        Price/Sales ratio reside in Quantiles 1. By selecting the 1st quantile,
        the portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_ps|rangeDecile">
      <h3>Price/Sales Ratio: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Sales ratio. Securities with the highest
        Price/Sales ratio reside in Quantiles 1. By selecting the 1st quantile,
        the portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
};
const f_pb_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_pb|greaterThan">
      <h3>Price/BookValue Ratio - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Price/BookValue ratio. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_pb|lessThan">
      <h3>Price/BookValue Ratio - Is less than</h3>
      <p>
        Limit the selection by adding a value of Price/BookValue ratio. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_pb|range">
      <h3>Price/BookValue Ratio: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Price/BookValue ratio.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_pb|top">
      <h3>Price/BookValue Ratio - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Price/BookValue
        ratio. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Price/BookValue ratio.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_pb|bottom">
      <h3>Price/BookValue Ratio - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Price/BookValue ratio. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest
        Price/BookValue ratio.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_pb|rangeQuartile">
      <h3>Price/BookValue Ratio: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/BookValue ratio. Securities with the highest
        Price/BookValue ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_pb|rangeQuintile">
      <h3>Price/BookValue Ratio: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/BookValue ratio. Securities with the highest
        Price/BookValue ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_pb|rangeDecile">
      <h3>Price/BookValue Ratio: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/BookValue ratio. Securities with the highest
        Price/BookValue ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
const f_pc_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_pc|greaterThan">
      <h3>Price/Cashflow Ratio - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Price/Cashflow ratio. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_pc|lessThan">
      <h3>Price/Cashflow Ratio - Is less than</h3>
      <p>
        Limit the selection by adding a value of Price/Cashflow ratio. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_pc|range">
      <h3>Price/Cashflow Ratio: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Price/Cashflow ratio.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_pc|top">
      <h3>Price/Cashflow Ratio - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Price/Cashflow
        ratio. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Price/Cashflow ratio.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_pc|bottom">
      <h3>Price/Cashflow Ratio - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Price/Cashflow ratio. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest
        Price/Cashflow ratio.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_pc|rangeQuartile">
      <h3>Price/Cashflow Ratio: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Cashflow ratio. Securities with the highest
        Price/Cashflow ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_pc|rangeQuintile">
      <h3>Price/Cashflow Ratio: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Cashflow ratio. Securities with the highest
        Price/Cashflow ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_pc|rangeDecile">
      <h3>Price/Cashflow Ratio: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Cashflow ratio. Securities with the highest
        Price/Cashflow ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
const f_pd_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_pd|greaterThan">
      <h3>Price/Dividends Ratio - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Price/Dividends ratio. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_pd|lessThan">
      <h3>Price/Dividends Ratio - Is less than</h3>
      <p>
        Limit the selection by adding a value of Price/Dividends ratio. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_pd|range">
      <h3>Price/Dividends Ratio: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Price/Dividends ratio.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_pd|top">
      <h3>Price/Dividends Ratio - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Price/Dividends
        ratio. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Price/Dividends ratio.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_pd|bottom">
      <h3>Price/Dividends Ratio - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Price/Dividends ratio. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest
        Price/Dividends ratio.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_pd|rangeQuartile">
      <h3>Price/Dividends Ratio: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Dividends ratio. Securities with the highest
        Price/Dividends ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_pd|rangeQuintile">
      <h3>Price/Dividends Ratio: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Dividends ratio. Securities with the highest
        Price/Dividends ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_pd|rangeDecile">
      <h3>Price/Dividends Ratio: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Price/Dividends ratio. Securities with the highest
        Price/Dividends ratio reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
const f_sps_CC_g_3_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_sps_CC_g_3|greaterThan">
      <h3>Sales 3 Months Growth Rate - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Sales Growth Rate. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_sps_CC_g_3|lessThan">
      <h3>Sales 3 Months Growth Rate - Is less than</h3>
      <p>
        Limit the selection by adding a value of Sales Growth Rate. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_sps_CC_g_3|range">
      <h3>Sales 3 Months Growth Rate: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Sales Growth Rate.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_sps_CC_g_3|top">
      <h3>Sales 3 Months Growth Rate - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Sales Growth
        Rate. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Sales Growth Rate.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_sps_CC_g_3|bottom">
      <h3>Sales 3 Months Growth Rate - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Sales Growth Rate. The default value is 10. This means that the portion
        of the investment universe eligible to be included in the portfolio is
        composed only of the 10 securities with the lowest Sales Growth Rate.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_sps_CC_g_3|rangeQuartile">
      <h3>Sales 3 Months Growth Rate: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_sps_CC_g_3|rangeQuintile">
      <h3>Sales 3 Months Growth Rate: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_sps_CC_g_3|rangeDecile">
      <h3>Sales 3 Months Growth Rate: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
};
const f_sps_CC_g_12_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_sps_CC_g_12|greaterThan">
      <h3>Sales 12 Months Growth Rate - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Sales Growth Rate. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_sps_CC_g_12|lessThan">
      <h3>Sales 12 Months Growth Rate - Is less than</h3>
      <p>
        Limit the selection by adding a value of Sales Growth Rate. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_sps_CC_g_12|range">
      <h3>Sales 12 Months Growth Rate: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Sales Growth Rate.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_sps_CC_g_12|top">
      <h3>Sales 12 Months Growth Rate - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Sales Growth
        Rate. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Sales Growth Rate.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_sps_CC_g_12|bottom">
      <h3>Sales 12 Months Growth Rate - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Sales Growth Rate. The default value is 10. This means that the portion
        of the investment universe eligible to be included in the portfolio is
        composed only of the 10 securities with the lowest Sales Growth Rate.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_sps_CC_g_12|rangeQuartile">
      <h3>Sales 12 Months Growth Rate: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_sps_CC_g_12|rangeQuintile">
      <h3>Sales 12 Months Growth Rate: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_sps_CC_g_12|rangeDecile">
      <h3>Sales 12 Months Growth Rate: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Sales Growth Rate. Securities with the highest Sales
        Growth Rate reside in Quantiles 1. By selecting the 1st quantile, the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the securities belonging to the first
        quantile.
      </p>
    </section>
  ),
};
const f_eps_CC_g_3_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_eps_CC_g_3|greaterThan">
      <h3>Earnings 3 Months Growth Rate - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Earning Growth Rate. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_eps_CC_g_3|lessThan">
      <h3>Earnings 3 Months Growth Rate - Is less than</h3>
      <p>
        Limit the selection by adding a value of Earning Growth Rate. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_eps_CC_g_3|range">
      <h3>Earnings 3 Months Growth Rate: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Earning Growth Rate.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_eps_CC_g_3|top">
      <h3>Earnings 3 Months Growth Rate - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Earnings Growth
        Rate. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Earning Growth Rate.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_eps_CC_g_3|bottom">
      <h3>Earnings 3 Months Growth Rate - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Earning Growth Rate. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest Earning
        Growth Rate.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_eps_CC_g_3|rangeQuartile">
      <h3>Earnings 3 Months Growth Rate: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_eps_CC_g_3|rangeQuintile">
      <h3>Earnings 3 Months Growth Rate: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_eps_CC_g_3|rangeDecile">
      <h3>Earnings 3 Months Growth Rate: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
const f_eps_CC_g_12_collection = {
  greaterThan: (
    <section className="" data-help-topic="f_eps_CC_g_12|greaterThan">
      <h3>Earnings 12 Months Growth Rate - Is greater than</h3>
      <p>
        Limit the selection by adding a value of Earning Growth Rate. Only
        securities above the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  lessThan: (
    <section className="" data-help-topic="f_eps_CC_g_12|lessThan">
      <h3>Earnings 12 Months Growth Rate - Is less than</h3>
      <p>
        Limit the selection by adding a value of Earning Growth Rate. Only
        securities below the entered value are included in the portion of the
        investment universe eligible to be included in the portfolio.
      </p>
    </section>
  ),
  range: (
    <section className="" data-help-topic="f_eps_CC_g_12|range">
      <h3>Earnings 12 Months Growth Rate: Between</h3>
      <p>
        Limit the selection by separating the universe with one or two
        segmentation of Earning Growth Rate.
      </p>
    </section>
  ),
  top: (
    <section className="" data-help-topic="f_eps_CC_g_12|top">
      <h3>Earnings 12 Months Growth Rate - Top</h3>
      <p>
        Limit the selection by taking the Top "n" securities by Earnings Growth
        Rate. The default value is 10. This means that the portion of the
        investment universe eligible to be included in the portfolio is composed
        only of the 10 securities with the highest Earning Growth Rate.
      </p>
    </section>
  ),
  bottom: (
    <section className="" data-help-topic="f_eps_CC_g_12|bottom">
      <h3>Earnings 12 Months Growth Rate - Bottom</h3>
      <p>
        Limit the investment universe by taking the Bottom "n" securities by
        Earning Growth Rate. The default value is 10. This means that the
        portion of the investment universe eligible to be included in the
        portfolio is composed only of the 10 securities with the lowest Earning
        Growth Rate.
      </p>
    </section>
  ),
  rangeQuartile: (
    <section className="" data-help-topic="f_eps_CC_g_12|rangeQuartile">
      <h3>Earnings 12 Months Growth Rate: Quartile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeQuintile: (
    <section className="" data-help-topic="f_eps_CC_g_12|rangeQuintile">
      <h3>Earnings 12 Months Growth Rate: Quintile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
  rangeDecile: (
    <section className="" data-help-topic="f_eps_CC_g_12|rangeDecile">
      <h3>Earnings 12 Months Growth Rate: Decile</h3>
      <p>
        The quantiles method divides the universe into the number of selected
        quantiles using the Earning Growth Rate. Securities with the highest
        Earning Growth Rate reside in Quantiles 1. By selecting the 1st
        quantile, the portion of the investment universe eligible to be included
        in the portfolio is composed only of the securities belonging to the
        first quantile.
      </p>
    </section>
  ),
};
export const helpTextobjs = {
  SELECTION_RULES: {
    init: (
      <>
        <h3>Selection rules</h3>
        <p>
          Define the set of entitled securities. Entitled securities constitute
          the portion of the investment universe eligible to be included in the
          portfolio.
        </p>
      </>
    ),
    rc: (
      <section className="">
        <h3>Rating</h3>
        <p>
          Limit the complete set of securities from which the strategy can pick.
          By default
          <span className="format-rate format-rate--A">A</span> and
          <span className="format-rate format-rate--B">B</span> are selected.
        </p>
        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            Select stocks with rating
            <span className="format-rate format-rate--A">A</span> or
            <span className="format-rate format-rate--B">B</span> avoiding
            rating of <span className="format-rate format-rate--C">C</span> and
            <span className="format-rate format-rate--D">D</span>.
            <span className="tHelp-itemTips-bottom"></span>
          </p>
        </div>
      </section>
    ),
    tradedValue: (
      <section className="" data-help-topic="tradedvalue">
        <h3>Liquidity (USD Millions)</h3>
        <p>
          Set minimum liquidity. Liquidity is the Average Daily Trading Value
          (ADTV) calculated over 20 days.
        </p>

        <div className="tHelp-itemTips">
          <div className="tHelp-itemTips-top">
            <span className="i-edu"></span> Trendrating's recommendation
          </div>
          <p>
            The best practice is to ask for liquidity over 3 time the AUM/number
            of positions, this this will allow to buy or sell a single position
            on the day of rebalancing.
          </p>
        </div>
      </section>
    ),
    marketcap: marketcap_collection,
    mc: mc_collection,
    sd: sd_collection,
    sdlt: sdlt_collection,
    px: px_collection,
    pr: pr_collection,
    pt: pt_collection,
    py: py_collection,
    ts: ts_collection,
    f_pe: f_pe_collection,
    f_ps: f_ps_collection,
    f_pb: f_pb_collection,
    f_pc: f_pc_collection,
    f_pd: f_pd_collection,
    f_sps_CC_g_3: f_sps_CC_g_3_collection,
    f_sps_CC_g_12: f_sps_CC_g_12_collection,
    f_eps_CC_g_3: f_eps_CC_g_3_collection,
    f_eps_CC_g_12: f_eps_CC_g_12_collection,
  },
  HOLDING_RULES: {
    init: (
      <>
        <h3>Holding rules</h3>
        <p>
          Positions in the current portfolio that meet these rules will be
          confirmed
        </p>
      </>
    ),
  },
};
