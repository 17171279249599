import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  exposure: "A_B_vs_C_D" | "A_B_C_D";
  where: "Country" | "Region" | "Area";
  what: "1 Industry" | "3 Sector";
  onWhereChange:Function;
  onWhatChange: Function;
  onExposureChange: Function;
};

export default function BoxContent({ exposure, where, what, onWhereChange, onWhatChange, onExposureChange }: Props) {
  const { t } = useTranslation();
  const allocationWhat = useMemo(() => {
    const languageCode = window.App.user.locale;
    let options = [
      {
        label:
          languageCode === "en-uk"
            ? t("common_industries")
            : t("common_sectors"),
        value: "1 Industry",
      },
      {
        label:
          languageCode === "en-uk"
            ? t("common_sectors")
            : t("common_industries"),
        value: "3 Sector",
      },
    ];
    return options;
  }, [t]);
  const allocationWhere = useMemo(
    () => [
      {
        label: t("w_preferences_analysis_list_allocation_markets"),
        value: "Country",
      },
      {
        label: t("w_preferences_analysis_list_allocation_regions"),
        value: "Region",
      },
      {
        label: t("w_preferences_analysis_list_allocation_areas"),
        value: "Area",
      },
    ],
    [t]
  );
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Typography variant="subtitle1">
          <strong> Box content</strong>
        </Typography>

        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Typography>Exposure</Typography>
          <RadioGroup row value={exposure} onChange={(e)=>onExposureChange(e.target.value)}>
            <FormControlLabel
              value="A_B_vs_C_D"
              control={<Radio />}
              label={ABvsCD}
            />
            <FormControlLabel
              value="A_B_C_D"
              control={<Radio />}
              label={ABCD}
            />
          </RadioGroup>
        </Box>

        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Typography>Pie charts view</Typography>
          {/*WHERE*/}
          <Select onChange={(e)=>onWhereChange(e.target.value)} size="small" value={where}>
            {allocationWhere.map((item) => (
              <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
          {/* WHAT */}
          <Select size="small" onChange={(e)=>onWhatChange(e.target.value)} value={what}>
            {allocationWhat.map((item) => (
              <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
        </Box>
      </CardContent>
    </Card>
  );
}

const ABvsCD = (
  <div>
    <span className="rate rate--A">A</span>
    <span className="rate rate--B">B</span>&nbsp;vs&nbsp;
    <span className="rate rate--C">C</span>
    <span className="rate rate--D">D</span>
  </div>
);

const ABCD = (
  <div>
    {" "}
    <span className="rate rate--A">A</span>,
    <span className="rate rate--B">B</span>,
    <span className="rate rate--C">C</span>,
    <span className="rate rate--D">D</span>
  </div>
);
