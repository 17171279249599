import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";
import { Environment } from "../Environment";
import { System } from "../js/app/System";
import { AppEnvironment } from "../types/Defaults";

type Action =
  | { type: "login"; payload: any }
  | { type: "logout" }
  | { type: "product"; payload: any }
  | { type: "ready"; payload: boolean }
  | { type: "environment"; payload: any };

type State = {
  hasProduct: boolean;
  hasEnvironment: boolean;
  isLogged: boolean;
  isReady: boolean;
  product?: AppEnvironment["account"]["product"];
  environment: Environment;
  loggedUser?: AppEnvironment["account"]["user"];
  user?: AppEnvironment["account"]["user"];
};

type SystemProps = {
  state: State;
  dispatch: any;
};

const initialSystemConfig: State = {
  hasProduct: false,
  hasEnvironment: false,
  isLogged: false,
  isReady: false,
  product: undefined,
  environment: new Environment(),
  loggedUser: undefined,
  user: undefined,
};

// Do not use immer here, the state object must be editable outside!
// TODO review this code and make use of dispatch action to edit rootProduct field
// so immer can be used again (because immer "locks" the object)
function systemReducer(state: State, action: Action) {
  // console.log("reducer: ", action.type, (action as any)?.payload, state);
  switch (action.type) {
    case "logout":
      state = {
        ...state,
        loggedUser: undefined,
        user: undefined,
        hasProduct: false,
        hasEnvironment: false,
        product: undefined,
        environment: new Environment(),
        isLogged: false,
        isReady: false,
      };
      break;
    case "product":
      console.log("compare user", state.user, action.payload);
      const user = action.payload;
      state = {
        ...state,
        hasProduct: true,
        product: user.product,
        user: user,
      };
      break;
    case "ready":
      state = {
        ...state,
        isReady: action.payload,
      };
      window.App.systemState = state;
      break;
    case "login":
      state = {
        ...state,
        loggedUser: action.payload,
        isLogged: true,
      };
      break;
    case "environment":
      const environment = new Environment(action.payload);
      state = {
        ...state,
        hasEnvironment: true,
        environment: environment,
      };
      break;
  }
  return state;
}

export const SystemContext = createContext<SystemProps | undefined>(undefined);

type SystemProviderProps = {
  children?: ReactNode;
  systemState?: State;
};
export function SystemProvider({ children, systemState }: SystemProviderProps) {
  const [state, dispatch] = useReducer(
    systemReducer,
    systemState ?? initialSystemConfig
  );
  // const environment = useEnvironment();
  const value = { state, dispatch };
  const systemApi = useMemo(() => new System(), []);
  const navigate = useNavigate();

  const redirectToLogin = useCallback(() => {
    if (window.location.pathname === "/") {
      // Do nothing
      return;
    }

    // login form
    let url = window.location.href;
    var pattern = RegExp("https?://" + appConfig.app.domain);
    var patternLocal = RegExp("https?://localhost:3000");
    var next = url.replace(pattern, "").replace(patternLocal, "");

    // Exclude double (or more) nexts (by bug...)
    let targetUrl = "/";
    if (next !== "" && !next.includes("next")) {
      targetUrl = `${targetUrl}?next=${next}`;
    }
    navigate(targetUrl);
  }, [navigate]);

  const onCheckSessionFail = useCallback(() => {
    dispatch({ type: "logout" });
    redirectToLogin();
  }, [redirectToLogin]);

  const handleTabSwitch = useCallback(
    async (user) => {
      if (document.visibilityState === "visible") {
        if (!user) {
          return;
        }

        try {
          let userLogged = await systemApi.checkSession();

          if (userLogged.id !== user.id) {
            window.location.reload();
          }
        } catch (error) {
          redirectToLogin();
        }
      }
    },
    [redirectToLogin, systemApi]
  );

  const start = useCallback(async () => {
    let user: any = null;

    document.onvisibilitychange = () => {
      handleTabSwitch(user);
    };

    // Try to login
    try {
      user = await systemApi.checkSession();
      console.log("checkSession", user);
      dispatch({ type: "login", payload: user });
    } catch (e: any) {
      onCheckSessionFail();
    }
  }, [handleTabSwitch, onCheckSessionFail, systemApi]);

  const loadProduct = useCallback(
    async (loggedUser: any) => {
      // Try to login
      try {
        const productAndUser = await systemApi.loadProduct(loggedUser);
        console.log("productAndUser", productAndUser);
        dispatch({ type: "product", payload: productAndUser });
      } catch (e: any) {
        // Error
        console.error(e);
      }
    },
    [dispatch, systemApi]
  );

  const loadEnvironment = useCallback(
    async (user: any) => {
      console.log("loadEnvironment", user);
      // Try to login
      try {
        const environment = await systemApi.loadEnvironment(user);
        dispatch({ type: "environment", payload: environment });
      } catch (e: any) {
        // Error
        console.error(e);
      }
    },
    [dispatch, systemApi]
  );

  // Boot sequence
  useEffect(() => {
    if (state.isReady) {
      return;
    }
    console.log("Loading data...");
    // first load
    start();
  }, [start, state.isReady]);

  useEffect(() => {
    if (state.isReady) {
      return;
    }
    if (state.isLogged) {
      console.log("Loading product...");
      loadProduct(state.loggedUser);
    }
  }, [loadProduct, state.isLogged, state.isReady, state.loggedUser]);

  useEffect(() => {
    if (state.isReady) {
      return;
    }
    if (state.hasProduct && !state.hasEnvironment) {
      console.log("Loading environment...");
      loadEnvironment(state.user);
    }
  }, [
    loadEnvironment,
    state.hasEnvironment,
    state.hasProduct,
    state.isReady,
    state.user,
  ]);

  useEffect(() => {
    if (state.isReady) {
      return;
    }
    if (state.hasEnvironment) {
      console.log("Ready");
      dispatch({ type: "ready", payload: true });
    }
  }, [state.environment, state.hasEnvironment, state.isReady]);

  return (
    <SystemContext.Provider value={value}>{children}</SystemContext.Provider>
  );
}

export function useSystem() {
  const context = useContext(SystemContext);
  if (context === undefined) {
    throw new Error("useSystem must be used within a SystemProvider");
  }
  return context;
}
