export type NewHighLowType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    numberOfItems: number;
  };
  presentation: {
    newHigh: boolean;
    newLow: boolean;
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_NUMBERS_OF_ITEMS"
    | "SET_PRESENTATION_NEW_LOW"
    | "SET_PRESENTATION_NEWHIGH";
  payload: any;
};

export const newHighLowReducer = (draft: NewHighLowType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_NUMBERS_OF_ITEMS":
      draft.content.numberOfItems = action.payload;
      break;
    case "SET_PRESENTATION_NEW_LOW":
      draft.presentation.newLow = action.payload;
      break;
    case "SET_PRESENTATION_NEWHIGH":
      draft.presentation.newHigh = action.payload;
      break;
    default:
      break;
  }
};
