import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { ErrorBoundary } from "../../ErrorBoundary";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Brick } from "../LayoutElements/Brick/Brick";
import styles from "./SortableBricks.module.scss";

type SortableBricksProps = {
  list: { ownerId: number; id: number; name: string }[];
  onClickBrick: (id: number) => any;
  selectedBrickId?: number;
  disabled?: boolean;
  onDropFinish?: (items) => void;
};

export function SortableBricks({
  list,
  onClickBrick,
  selectedBrickId,
  disabled,
  onDropFinish,
}: SortableBricksProps) {
  const environment = useEnvironment();
  const user = useMemo(
    () => environment?.["setup"]?.["account"]?.["user"],
    [environment]
  );

  const { items, drop, dragEnter, dragOut, dragOver, dragStart } =
    useDragAndDrop(list);

  const handleClick = useCallback(
    (id) => {
      if (disabled != null && disabled === false) {
        onClickBrick(id);
      }
    },
    [disabled, onClickBrick]
  );

  const handleDrop = useCallback(
    (e) => {
      drop(e, onDropFinish);
    },
    [drop, onDropFinish]
  );

  return (
    <ErrorBoundary fallback={<></>}>
      <Box className={styles.list_wrapper}>
        <ul className={styles.list}>
          {items.map((item, index) => (
            <li
              id={`${index}`}
              key={uuidv4()}
              draggable
              onDragStart={dragStart}
              onDragEnter={dragEnter}
              onDragLeave={dragOut}
              onDragOver={dragOver}
              onDragEnd={handleDrop}
            >
              <Brick
                titleAttr="click to execute, drag&drowp to change order"
                disabled={disabled}
                isActive={selectedBrickId === item.id}
                label={item.name}
                isSharedObject={user?.id !== item.ownerId}
                onClick={() => handleClick(item.id)}
              />
            </li>
          ))}
        </ul>
      </Box>
    </ErrorBoundary>
  );
}
