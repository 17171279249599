import {
  Box,
  Button,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TrendratingTable } from "../../../../components/table/TrendratingTable";
import { TaxonomyFieldRow } from "../../pages/strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/ScreenerPanel/ScreenerPanel";
import { useTaxonomyByType } from "../../../../hooks/useTaxonomyByType";
import { ColumnDefinition } from "tabulator-tables";

type Props = {
  setShowModal: Function;
  setPageNumber: Function;
  setInputContent: Function;
  renderAll: Function;
  onFilterByAssetClass: Function;
  inputContent: string;
  dropdownValue: string;
  assetClassDropdownItems: { label: string; value: string }[];
  pageNumber: number;
  dataTotalCount: any;
  taxonomiesMapX: any;
  allResults: any[];
  onSelectInstrument: Function;
};

export default function ModalContent({
  setPageNumber,
  setInputContent,
  renderAll,
  inputContent,
  assetClassDropdownItems,
  dataTotalCount,
  pageNumber,
  taxonomiesMapX,
  allResults,
  setShowModal,
  onFilterByAssetClass,
  dropdownValue,
  onSelectInstrument,
}: Props) {
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const { advancedNodesSelector } = useTaxonomyByType("stock");

  const columns: ColumnDefinition[] = [
    {
      title: "Ticker",
      field: "ticker",
    },
    { title: "Name", field: "name" },
    { title: "Asset class", field: "type" },
    {
      title: "Market",
      field: "country",
      formatter: (e) => {
        let value = e.getValue();
        var market = taxonomiesMapX[value];
        return market ? market.name : "";
      },
    },
  ];
  return (
    <Box
      maxHeight={500}
      height={"100%"}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
    >
      <Box display={"flex"} gap={2}>
        <TextField
          value={inputContent ?? ""}
          onChange={(e) => {
            setPageNumber(1);
            setInputContent(e.target.value);
            renderAll(
              e.target.value,
              1,
              dropdownValue !== "any" ? [dropdownValue] : undefined,
              selectedMarkets
            );
            // renderAll(e.target.value, 1, selectedMarkets);
          }}
          size="small"
        />
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>Asset class</Typography>
          <Select
            onChange={(e) => {
              onFilterByAssetClass(e.target.value);
            }}
            size="small"
            autoFocus={false}
            value={dropdownValue ?? "any"}
          >
            {assetClassDropdownItems.map((item, index) => (
              <MenuItem key={`${item}_${index}`} value={item.value}>
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>Market</Typography>
          <TaxonomyFieldRow
            label={"Markets"}
            handleValuesSelection={(e) => {
              setSelectedMarkets(
                advancedNodesSelector(e[0].value.segments, "country", true)
              );
              renderAll(
                undefined,
                1,
                dropdownValue !== "any" ? [dropdownValue] : undefined,
                advancedNodesSelector(e[0].value.segments, "country", true)
              );
            }}
            taxonomyMap={taxonomiesMapX}
            selectedValues={selectedMarkets}
            taxonomyType={"security"}
            taxonomyField={"country"}
            phraseOnEmpty="World wide"
          />
        </Box>
      </Box>
      <Box>
        <Pagination
          color="primary"
          page={pageNumber}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPageNumber(value);
            renderAll(
              undefined,
              value,
              dropdownValue !== "any" ? [dropdownValue] : undefined,
              selectedMarkets
            );
          }}
          count={
            dataTotalCount / 25 > parseInt((dataTotalCount / 25).toFixed())
              ? parseInt((dataTotalCount / 25).toFixed()) + 1
              : parseInt((dataTotalCount / 25).toFixed())
          }
        />
      </Box>
      <Box overflow={"auto"}>
        <TrendratingTable
          options={{
            ajaxSorting: false,
            movableColumns: false,
            height: "100%",
            rowClick: (e: any, row: any) => {
              // checking this condition because otherwise when clicking
              // the tooltip it will select the instrument and close the modal
              if (e.target.classList.contains("i-info")) {
                return;
              }
              onSelectInstrument(row.getData());
            },
          }}
          autoResize={false}
          columns={columns}
          data={allResults!}
          tooltip={{
            column: 0,
            actions: {
              info: {
                enabled: true,
              },
            },
          }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() => {
            setShowModal(false);
            setPageNumber(1);
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
