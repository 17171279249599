/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/_RationaleBase
 * @summary Base methods to manage Rationales
 *
 */

import { _Base } from "../_Base";
import { RankingUi2Api } from "./RankingUi2Api";
import { SelectionUi2Api } from "./SelectionUi2Api";

export class _RationaleBase extends _Base {
    // MUST be passed as constructor param of the class using this mixin

    // MUST be initialized by the class using this mixin
    //
    // MUST have an instance of trendrating/api/compute/Instruments
    //
    // e.g http["instuments"]
    http: any;

    // check if a property is relevant
    // avoid that UI displays empty columns or irrelevant/redundant data
    getRelevantRationaleProperties(strategyParamsEncoded: any) {
        const strategySection = strategyParamsEncoded["strategy"];
        const relevant = {
            // column capping only if there is capping
            beforeCapWeight: false,

            candidate: true,

            // column capping only if there is capping
            capWeight: false,

            costituent: true,
            exWeight: false,

            // column factor only when there are factors
            multifactor: false,

            name: true,

            // column ex-ante weigth only if there is exists in ranking and
            // keep weigths
            nWeight: false,

            orWeight: false,
            ticker: true,
            Weight: true,
        };
        // ex-ante
        if (
            strategySection.rank &&
            strategySection.weightingRules &&
            strategySection.weightingRules.weightRule !== "REBALANCE"
        ) {
            for (let i = 0; i < strategySection.rank.length; i++) {
                if (strategySection.rank[i].dimension === "exists") {
                    relevant.exWeight = true;
                    break;
                }
            }
        }
        // multifactor
        if (
            strategySection.weightingRules &&
            strategySection.weightingRules.factors
        ) {
            relevant.multifactor = true;
        }
        // capping
        if (
            strategySection.cappingRules &&
            ((strategySection.cappingRules.maxAllocation &&
                strategySection.cappingRules.maxAllocation !== 1.0) ||
                (strategySection.cappingRules.minAllocation &&
                    strategySection.cappingRules.minAllocation !== 0.0) ||
                (strategySection.cappingRules.capValue &&
                    strategySection.cappingRules.capValue !== 1.0) ||
                (strategySection.cappingRules.minValue &&
                    strategySection.cappingRules.minValue !== 0.0) ||
                (strategySection.cappingRules.rotation &&
                    strategySection.cappingRules.rotation.capValue &&
                    strategySection.cappingRules.rotation.capValue !== 1.0))
        ) {
            relevant.capWeight = true;
            relevant.beforeCapWeight = true;
        }

        return relevant;
    }

    async _normalizeRationale(
        strategyParamsEncoded: any,
        instrumentProperties: any,
        response: any
    ) {
        const _transparency = response["data"]["trasparency"];
        const _symbols = _transparency["symbols"];

        const paramsFetch = {
            properties: instrumentProperties,
            symbols: _symbols,
            type: "security",
        };

        const responseFetch = await this.http["instruments"].fetch(paramsFetch);

        const instruments = responseFetch.data;
        const instrumentsMap: any = {};
        for (let i = 0; i < instruments.length; i++) {
            const instrument = instruments[i];
            instrumentsMap[instrument["symbol"]] = instrument;
        }
        // ------------------------------------------------- columns
        const _columns = _transparency["columns"];
        const columns: any = [
            // compound columns for instrumentProperties
            {
                children: [],
                colspan: null,
                label: "",
            },
        ];
        for (let i = 0; i < instrumentProperties.length; i++) {
            columns[0]["children"].push({
                field: instrumentProperties[i]["property"],
                label: instrumentProperties[i]["property"],
            });
        }
        // rationale columns
        const properties: any = [];
        const relevantProperties = this.getRelevantRationaleProperties(
            strategyParamsEncoded
        );

        //
        // @param {string} | {object} chunk - the data to be processed
        // @param {number} index - used to make unique keys
        //
        function decodeAndWrapServerData(chunk: any, index: any) {
            const decoded: any = {
                function: null,
                hasRule: false,
                property: null,
            };

            if (typeof chunk === "string") {
                decoded["property"] = chunk;
            } else {
                decoded["function"] = chunk["function"];
                decoded["operator"] = chunk["operator"];
                decoded["property"] = index + "_" + chunk["dimension"];
                decoded["hasRule"] = true;
            }

            return decoded;
        }

        // rules: ranking and selection
        const rankingUi2Api = new RankingUi2Api(this.environment);
        const selectionUi2Api = new SelectionUi2Api(
            this.environment.configuration.get(
                "strategyBuilder"
            ).selection.edit.transformations
        );
        const rulesRanking =
            strategyParamsEncoded.strategy.rank != null
                ? rankingUi2Api.decode(strategyParamsEncoded.strategy.rank)
                : [];
        const rulesSelection =
            strategyParamsEncoded.strategy.selectionRules.constraints != null
                ? selectionUi2Api.decode(
                      strategyParamsEncoded.strategy.selectionRules.constraints
                  )
                : [];

        let rankIndex: any = null;
        // let weightIndex: any = null;
        for (let i = 0; i < _columns.length; i++) {
            const _children = _columns[i].children;
            const previousColspan =
                i === 0
                    ? {
                          from: 0,
                          to: _children.length - 1,
                      }
                    : _columns[i - 1].colspan;

            const column: any = {
                children: [],
                colspan: {
                    from: i === 0 ? 0 : previousColspan["to"] + 1,
                    to:
                        i === 0
                            ? _children.length - 1
                            : previousColspan["to"] + _children.length,
                },
                label: _columns[i]["label"],
                prefix: _columns[i]["label"].toLowerCase() + "_",
            };

            // save the current offset info for the next iteration
            _columns[i]["colspan"] = column["colspan"];

            let _columnOffset = 0;
            let columnCounter = 0;
            for (let j = 0; j < _children.length; j++) {
                const _childMeta = decodeAndWrapServerData(_children[j], j);
                const _property = _childMeta["property"];

                if (_property === "rank") {
                    rankIndex = columnCounter;
                }

                // if (_property === "Weight") {
                //     weightIndex = columnCounter;
                // }
                columnCounter++;

                properties.push(_property);
                if (
                    !(_property in relevantProperties) ||
                    (_property in relevantProperties &&
                        (relevantProperties as any)[_property] === true)
                ) {
                    const child: any = {
                        field: column["prefix"] + _property,
                        label: column["prefix"] + _property,
                        function: _childMeta["function"],
                        operator: _childMeta["operator"],
                        property: column["prefix"] + _property,
                        rule: null,
                    };

                    if (
                        column["prefix"] === "ranking_" &&
                        _childMeta["hasRule"] === true
                    ) {
                        child["rule"] = rulesRanking[j - _columnOffset];
                    } else if (
                        column["prefix"] === "selection_" &&
                        _childMeta["hasRule"] === true
                    ) {
                        child["rule"] = rulesSelection[j - _columnOffset];
                    } else {
                        // because there are properties with no
                        // linked rule
                        _columnOffset = _columnOffset + 1;
                    }

                    column["children"].push(child);
                }
            }
            columns.push(column);
        }
        // ---------------------------------------------------- rows
        function getColumnPrefix(index: any, columns: any) {
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                if (column["colspan"] != null) {
                    const colspan = column["colspan"];
                    if (index >= colspan["from"] && index <= colspan["to"]) {
                        return column["prefix"];
                    }
                }
            }

            return null;
        }

        const _rows = _transparency["rows"];

        for (let i = 0; i < _symbols.length; i++) {
            _rows[i].push(_symbols[i]);
        }

        // funnel
        _rows.sort(function (rowA: any, rowB: any) {
            let order = 1;

            for (let i = rowA.length - 2; i >= 0; i--) {
                if (i === rankIndex) {
                    order = -1;
                } else {
                    order = 1;
                }

                const cellA = rowA[i];
                const cellB = rowB[i];

                if (cellA === cellB) {
                    continue;
                }
                if (cellA == null) {
                    return order;
                }
                if (cellB == null) {
                    return -order;
                }
                return cellA > cellB ? -order : order;
            }

            return 0;
        });

        const _symbols2: any = [];
        const _symbolIndex = _rows[0].length - 1;
        for (let i = 0, length = _rows.length; i < length; i++) {
            _symbols2.push(_rows[i][_symbolIndex]);
        }

        const data: any = [];
        for (let i = 0; i < _rows.length; i++) {
            const _row = _rows[i];
            const instrument: any = {
                symbol: _symbols2[i],
            };
            // instrument properties
            for (const property in instrumentsMap[_symbols2[i]]) {
                instrument[property] = instrumentsMap[_symbols2[i]][property];
            }
            // rationale properties
            for (let j = 0; j < _row.length; j++) {
                const columnPrefix = getColumnPrefix(j, columns);

                if (columnPrefix != null) {
                    switch (properties[j]) {
                        // case "beforeCapWeight":
                        // case "capWeight":
                        // case "exWeight":
                        // case "multifactor":
                        // case "Weight": {
                        //     instrument[columnPrefix + properties[j]] = _row[j]
                        //         // _row[j] == null ? -1 : _row[j];

                        //     break;
                        // }
                        case "rc": {
                            instrument[columnPrefix + properties[j]] =
                                _row[j] == null ? 0 : _row[j];

                            break;
                        }
                        case "sd": {
                            // TODO - need to have a single point of truth
                            // for properties that have a "transformation"
                            // to be applied before can be used
                            //
                            // see selection configuration (transformation)
                            //
                            instrument[columnPrefix + properties[j]] =
                                _row[j] == null ? null : _row[j] / 100;

                            break;
                        }
                        default: {
                            instrument[columnPrefix + properties[j]] = _row[j];
                        }
                    }
                    // } else {
                    //      Re-inject the symbol, but it is already available
                    //      Code not removed for future review
                    //      instrument[properties[j]] = _row[j];
                }
            }
            data.push(instrument);
        }

        // check capping
        // if for all instruments beforeCapWeight is equal to
        // capWeight, columns must be removed (irrelevant)
        let isCapWeightRelevant = false;
        for (let i = 0; i < data.length; i++) {
            if (
                Math.abs(
                    data[i]["weighting_beforeCapWeight"] -
                        data[i]["weighting_capWeight"]
                ) > 0.001
            ) {
                isCapWeightRelevant = true;
                break;
            }
        }

        if (isCapWeightRelevant === false) {
            // removing columns
            for (let i = 0; i < columns.length; i++) {
                if (columns[i]["prefix"] === "weighting_") {
                    let children = columns[i]["children"];
                    let lengthJ = children.length; // Dynamic size, see loop
                    for (let j = 0; j < lengthJ; j++) {
                        if (
                            children[j]["field"] ===
                                "weighting_beforeCapWeight" ||
                            children[j]["field"] === "weighting_capWeight"
                        ) {
                            children.splice(j, 1);
                            // splice modifies the current array.
                            // We need to manually make the loop
                            // consistent
                            lengthJ = children.length;
                            j = j - 1;
                        }
                    }
                }
            }
        }

        /**
         * Add a sorting method to data that apply with a fallback chain:
         * - first order by weight
         * - if weight are equals order by rank
         */
        data.sort(function (rowA: any, rowB: any) {
            if (rowA["weighting_Weight"] === rowB["weighting_Weight"]) {
                if (rowA["ranking_rank"] == null) {
                    if (rowB["ranking_rank"] == null) {
                        return 0;
                    } else {
                        return 1;
                    }
                }

                if (rowB["ranking_rank"] == null) {
                    return -1;
                }

                return rowA["ranking_rank"] < rowB["ranking_rank"] ? -1 : 1;
            }
            return rowA["weighting_Weight"] > rowB["weighting_Weight"] ? -1 : 1;
        });

        const rationale = {
            columns: columns,
            data: data,
        };

        return rationale;
    }
}
