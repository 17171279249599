/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyFacts
 * @summary Strategy - Facts (Characteristic)
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity } from "./Generator";

export function sectionsFacts(input, section, datum, formatter) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    const _section = sectionFacts(input, section, datum, formatter);
    _sections.push(_section);

    return _sections;
}

function sectionFacts(input, section, data, formatter) {
    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [
                [
                    {
                        style: null,
                        value: "",
                    },
                    {
                        style: null,
                        value: "",
                    },
                ],
            ],
        },
        type: "table",
    };

    if (section["content"]["rebalancingFrequency"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value:
                    section["content"]["rebalancingFrequency"]["label"] ||
                    "Rebalancing frequency",
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section["content"]["rebalancingFrequency"]["content"] ||
                    data["rebalancingFrequency"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["currency"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value: section["content"]["currency"]["label"] || "Currency",
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section["content"]["currency"]["content"] ||
                    data["currency"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["numberOfHoldings"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value:
                    section["content"]["numberOfHoldings"]["label"] ||
                    "Number of holdings",
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section["content"]["numberOfHoldings"]["content"] ||
                    data["numberOfHoldings"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["launchDate"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value:
                    section["content"]["launchDate"]["label"] || "Launch date",
            },
        ];

        // Use the custom value from user, otherwise use the backtesting inception or
        // custom date used in print dialog.
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section["content"]["launchDate"]["content"] ||
                    data["launchDate"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    // We explicity want to put the ending date thought ending date does not exists, to make it
    // visible also in templates that was saved before add the ending date label.
    if (
        section["content"]?.["endingDate"] == null ||
        section["content"]["endingDate"]["isEnabled"]
    ) {
        const tableRow = [
            {
                style: null,
                value:
                    section["content"]?.["endingDate"]?.["label"] ||
                    "As of date",
            },
        ];

        // Use the custom value from user, otherwise use the backtesting inception or
        // custom date used in print dialog.
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section["content"]?.["endingDate"]?.["content"] ||
                    data["endingDate"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section?.content?.performance?.isEnabled) {
        const tableRow = [
            {
                style: null,
                value: section?.content?.performance?.label || "Performance",
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value:
                    section?.content?.performance?.content ||
                    data["performance"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["customText1"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value: section["content"]["customText1"]["label"],
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value: section["content"]["customText1"]["content"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["customText2"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value: section["content"]["customText2"]["label"],
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value: section["content"]["customText2"]["content"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    if (section["content"]["customText3"]["isEnabled"]) {
        const tableRow = [
            {
                style: null,
                value: section["content"]["customText3"]["label"],
            },
        ];
        tableRow.push({
            style: null,
            value: format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value: section["content"]["customText3"]["content"],
                valueHelper: null,
            }),
        });
        // Add row to table
        table["data"]["body"].push(tableRow);
    }
    return table;
}
