import React, { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../../deepClone";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

type SectorProps = {
  configObj: any;
};

const Sector = forwardRef(({ configObj }: SectorProps, ref) => {
  const [isEnabled, setIsEnabled] = useState(
    configObj.widgetValue.content.headline.isEnabled
  );
  const [content, setContent] = useState(
    configObj.widgetValue.content.headline.content
  );

  const [hasChildren, setHasChildren] = useState(
    configObj.widgetValue.content.headline.hasChildren
  );
  useImperativeHandle(ref, () => ({
    getState: () => {
      const tempState = deepClone(configObj);
      tempState.widgetValue.content.headline.content = content;
      tempState.widgetValue.content.headline.isEnabled = isEnabled;
      tempState.widgetValue.content.headline.hasChildren = hasChildren;
      return tempState;
    },
  }));
  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important", display:"flex", flexDirection:"column", gap:2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
                size="small"
              />
            }
            label={
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography>Headline</Typography>
                <TextField
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  size="small"
                  disabled={!isEnabled}
                />
              </Box>
            }
          />

          <FormControlLabel
            control={
              <Switch
                checked={hasChildren}
                onChange={(e) => setHasChildren(e.target.checked)}
                size="small"
              />
            }
            label="Show industries"
          />
        </CardContent>
      </Card>
    </Box>
  );
});

export default Sector;
