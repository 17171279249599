import { Properties } from "../../../api/Properties";
import i18n from "../../../i18n";
import { Formatter } from "../../../trendrating/formatter/Formatter";
import { FormOptions } from "./FormOptions";

export class FormatRankingSelection {
  formatType: any;
  label: any;

  // must be unified and have the same structure of selection
  optionsRanking: any;

  optionsSelection: any;
  properties: any;

  constructor(params) {
    if (params === undefined || params == null) {
      throw new Error("Missing parameters");
    }

    if (
      !("optionsRanking" in params) ||
      params["optionsRanking"] === undefined ||
      params["optionsRanking"] == null
    ) {
      if (appConfig.isDebug === true) {
        console.warn("Missing optionsRanking");
      }
    } else {
      this.optionsRanking = this._prepareOptionsRanking(
        params["optionsRanking"]
      );
    }

    if ("formatType" in params && params["formatType"] !== undefined) {
      this.formatType = params["formatType"];
    } else {
      this.formatType = "HTML";
    }

    // if (
    //     !('optionsSelection' in params) ||
    //     params['optionsSelection'] === undefined ||
    //     params['optionsSelection'] == null
    // ) {
    //     console.warn('Missing optionsSelection');
    // }
    // else {
    //     this.optionsSelection =
    //         this._prepareOptionsSelection(params['optionsSelection']);
    // }

    if (
      !("properties" in params) ||
      params["properties"] === undefined ||
      params["properties"] == null
    ) {
      throw new Error("Missing properties");
    }
    this.properties = params["properties"];
    this.label = new Properties({
      properties: this.properties,
    });
  }

  public function(property, functionName, functionParams) {
    let formatted = "";

    switch (functionName) {
      case "outlier": {
        formatted = i18n["form_dynamic_function_outlier"];

        break;
      }
      case "quantile": {
        switch (functionParams["value"]) {
          case 4: {
            formatted = i18n["form_dynamic_function_quartile"];
            break;
          }
          case 5: {
            formatted = i18n["form_dynamic_function_quintile"];
            break;
          }
          case 10: {
            formatted = i18n["form_dynamic_function_decile"];
            break;
          }
          default: {
            formatted =
              i18n["form_dynamic_function_quantile"] +
              " (" +
              functionParams["value"] +
              ")";
          }
        }

        break;
      }
      case "threshold": {
        formatted = i18n["form_dynamic_function_threshold"];

        break;
      }
      case "value": {
        //formatted = i18n['form_dynamic_function_value'];
        formatted = "";

        break;
      }
      default: {
        formatted = i18n["common_unknown"];
      }
    }

    return formatted;
  }

  public operator(property, functionName, operatorName, operatorParams) {
    var formatted: any = "";
    var _formatted: any = null; // for intermediate manipulation
    var options: any = FormOptions;

    switch (operatorName) {
      case "bottom": /* case "bottomPercentage": */ {
        // _formatted = operatorParams["value"];
        // formatted =
        //     _formatted > 0
        //         ? [
        //               i18n["form_dynamic_operator_bottom"],
        //               _formatted
        //           ].join(" ")
        //         : i18n["form_dynamic_operator_bottom"];

        formatted = this._formatBottomTop(
          operatorParams["value"],
          i18n["form_dynamic_operator_bottom"]
        );

        break;
      }
      // case "bottomPercentage": {
      //     _formatted = operatorParams["value"];
      //     formatted =
      //         _formatted > 0
      //             ? [
      //                   i18n["form_dynamic_operator_bottom"],
      //                   _formatted + "%"
      //               ].join(" ")
      //             : i18n["form_dynamic_operator_bottom"];

      //     break;
      // }
      case "equalTo": {
        _formatted = operatorParams["value"];
        formatted = [i18n["form_dynamic_operator_equalTo"], _formatted].join(
          " "
        );

        break;
      }
      case "equalToPercentage": {
        _formatted = operatorParams["value"];
        formatted = [
          i18n["form_dynamic_operator_equalTo"],
          _formatted + "%",
        ].join(" ");

        break;
      }
      case "equalToRate": {
        _formatted = [];

        if (operatorParams["value"]["A"] === true) {
          switch (this.formatType) {
            case "HTML": {
              _formatted.push('<strong class="rate rate--A">A</strong>');
              break;
            }
            default: {
              _formatted.push("A");
              break;
            }
          }
        }

        if (operatorParams["value"]["B"] === true) {
          switch (this.formatType) {
            case "HTML": {
              _formatted.push('<strong class="rate rate--B">B</strong>');
              break;
            }
            default: {
              _formatted.push("B");
              break;
            }
          }
        }

        if (operatorParams["value"]["C"] === true) {
          switch (this.formatType) {
            case "HTML": {
              _formatted.push('<strong class="rate rate--C">C</strong>');
              break;
            }
            default: {
              _formatted.push("C");
              break;
            }
          }
        }

        if (operatorParams["value"]["D"] === true) {
          switch (this.formatType) {
            case "HTML": {
              _formatted.push('<strong class="rate rate--D">D</strong>');
              break;
            }
            default: {
              _formatted.push("D");
              break;
            }
          }
        }

        if (_formatted.length === 0) {
          _formatted.push(i18n["common_any"]);
        }

        formatted = _formatted.join(", ");

        break;
      }
      case "greaterThan":
      case "greaterThanOrEqualTo":
      case "lessThan":
      case "lessThanOrEqualTo":
      case "range": {
        formatted = this._formatRange(operatorParams["value"]);

        break;
      }
      case "greaterThanPercentage":
      case "greaterThanOrEqualToPercentage":
      case "lessThanPercentage":
      case "lessThanOrEqualToPercentage":
      case "rangePercentage": {
        formatted = this._formatRangePercentage(operatorParams["value"]);

        break;
      }
      case "rangeMarketCap": {
        _formatted = options.getOption(
          "MARKET_CAP",
          operatorParams["value"][0]
        );

        if (
          _formatted["raw_value"]["ge"] == null &&
          _formatted["raw_value"]["le"] == null
        ) {
          formatted =
            _formatted["ge"] === _formatted["le"]
              ? _formatted["ge"]
              : _formatted["le"] == null
              ? _formatted["ge"]
              : [
                  i18n["form_dynamic_operator_between"],
                  _formatted["ge"],
                  "and",
                  _formatted["le"],
                ].join(" ");
        } else {
          formatted =
            _formatted["ge"] === _formatted["le"]
              ? _formatted["ge"]
              : _formatted["le"] == null
              ? _formatted["ge"]
              : [_formatted["ge"], "-", _formatted["le"]].join(" ");
        }

        break;
      }
      case "rangeVolatility": {
        _formatted = options.getOption(
          "VOLATILITY",
          operatorParams["value"][0]
        );

        formatted =
          _formatted["ge"] === _formatted["le"]
            ? _formatted["ge"]
            : _formatted["le"] == null
            ? _formatted["ge"]
            : [_formatted["ge"], "-", _formatted["le"]].join(" ");

        break;
      }
      case "sortByExists":
      case "sortByNumeric":
      case "sortByOutlier":
      case "sortByQuantile":
      case "sortByPerformance":
      case "sortByRating":
      case "sortByRetracement":
      case "sortByString":
      case "sortByThreshold": {
        _formatted = this.optionsRanking[property]["functions"][functionName];
        _formatted = _formatted["operators"][operatorName];
        formatted = _formatted[operatorParams["value"]]["label"];

        break;
      }
      case "top": /* case "topPercentage": */ {
        // _formatted = operatorParams["value"];
        // formatted =
        //     _formatted > 0
        //         ? [
        //               i18n["form_dynamic_operator_top"],
        //               _formatted
        //           ].join(" ")
        //         : i18n["form_dynamic_operator_top"];

        formatted = this._formatBottomTop(
          operatorParams["value"],
          i18n["form_dynamic_operator_top"]
        );

        break;
      }
      // case "topPercentage": {
      //     _formatted = operatorParams["value"];
      //     formatted =
      //         _formatted > 0
      //             ? [
      //                   i18n["form_dynamic_operator_top"],
      //                   _formatted + "%"
      //               ].join(" ")
      //             : i18n["form_dynamic_operator_top"];

      //     break;
      // }
      default: {
        formatted = i18n["common_unknown"] + " - " + operatorName;
      }
    }

    return formatted;
  }

  public property(property, propertyLabel, labelIndex) {
    labelIndex =
      labelIndex === undefined || labelIndex == null ? 0 : labelIndex;

    if (propertyLabel === undefined || propertyLabel == null) {
      var label = this.label;
      return label.get(property, labelIndex);
    }

    return propertyLabel[property];
  }
  // ----------------------------------------------------- private methods
  //
  public _formatBottomTop(value, label) {
    var formatted = [label, value].join(" ");

    return formatted;
  }

  public _formatRange(value) {
    var _formatted: any = value[0];
    var formatted: any = [];
    if (value.length > 1) {
      formatted.push(i18n["form_dynamic_operator_between"] + ":");
    }
    var keyCounter = 0;
    var start = null;
    var end = null;

    for (var key in _formatted) {
      switch (key) {
        case "ge":
        case "gt": {
          start = _formatted[key];

          break;
        }
        case "le":
        case "lt": {
          end = _formatted[key];

          break;
        } // no default
      }
      keyCounter++;
    }

    if (keyCounter === 1) {
      if ("ge" in _formatted) {
        // is greater than or equal to
        formatted.push(
          i18n["form_dynamic_operator_greaterThanOrEqualTo"],
          start
        );
      } else if ("gt" in _formatted) {
        // is greater than
        formatted.push(i18n["form_dynamic_operator_greaterThan"], start);
      } else if ("le" in _formatted) {
        // is less than or equal to
        formatted.push(i18n["form_dynamic_operator_lessThanOrEqualTo"], end);
      } else if ("lt" in _formatted) {
        // is less than
        formatted.push(i18n["form_dynamic_operator_lessThan"], end);
      }
    } else {
      // range
      formatted = [
        i18n["form_dynamic_operator_between"],
        start,
        i18n["common_and"],
        end,
      ];
    }

    if (value.length > 1) {
      formatted.push("...");
    }

    return formatted.join(" ");
  }

  public _formatRangePercentage(value) {
    var _formatted: any = value[0];
    var formatted: any = null;
    var keyCounter: any = 0;
    var start: any = null;
    var end: any = null;
    let ff = new Formatter();

    for (var key in _formatted) {
      switch (key) {
        case "ge":
        case "gt": {
          // start = (_formatted[key] * 100);
          start = ff.number({
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "-",
              },
            },
            output: "HTML",
            value: _formatted[key],
            valueHelper: null,
          });

          break;
        }
        case "le":
        case "lt": {
          end = ff.number({
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "-",
              },
            },
            output: "HTML",
            value: _formatted[key],
            valueHelper: null,
          });

          break;
        } // no default
      }
      keyCounter++;
    }

    if (keyCounter === 1) {
      if ("ge" in _formatted) {
        // is greater than or equal to
        formatted = [i18n["form_dynamic_operator_greaterThanOrEqualTo"], start];
      } else if ("gt" in _formatted) {
        // is greater than
        formatted = [i18n["form_dynamic_operator_greaterThan"], start];
      } else if ("le" in _formatted) {
        // is less than or equal to
        formatted = [i18n["form_dynamic_operator_lessThanOrEqualTo"], end];
      } else if ("lt" in _formatted) {
        // is less than
        formatted = [i18n["form_dynamic_operator_lessThan"], end];
      }
    } else {
      // range
      formatted = [
        i18n["form_dynamic_operator_between"],
        start,
        /*i18n["common_and"]*/ "and",
        end,
        value.length > 1 ? "..." : "",
      ];
    }

    return formatted.join(" ");
  }

  // create an inverted map that is accessible using
  // raw values (e.g. rc, quantile, desc)
  public _prepareOptionsRanking(options) {
    var itemFunction: any = null;
    var itemOperator: any = null;
    var keyFunction: any = null;
    var option: any = null;
    var optionsMap: any = {};
    var property: any = null;

    for (let i = 0, lengthI = options.length; i < lengthI; i++) {
      option = options[i];

      property = option["property"]["value"];

      optionsMap[property] = {
        functions: {},
      };

      // functions and operators (sortBy) have the same length
      // and there is a one-to-one relation among items
      for (var j = 0, lengthJ = option["functions"].length; j < lengthJ; j++) {
        itemFunction = option["functions"][j];
        // [0] because every function has only 1 operator
        itemOperator = option["operators"][j][0];

        keyFunction = itemFunction["value"];
        optionsMap[property]["functions"][keyFunction] = {
          label: itemFunction["label"],
          operators: {},
        };

        optionsMap[property]["functions"][keyFunction]["operators"][
          itemOperator["value"]
        ] = {};

        var key =
          optionsMap[property]["functions"][keyFunction]["operators"][
            itemOperator["value"]
          ];
        for (
          var k = 0, lengthK = itemOperator["widget"]["options"].length;
          k < lengthK;
          k++
        ) {
          key[itemOperator["widget"]["options"][k]["value"]] = {
            label: itemOperator["widget"]["options"][k]["label"],
          };
        }
      }
    }

    return optionsMap;
  }

  public _prepareOptionsSelection(options) {
    var optionsMap = {};

    return optionsMap;
  }
}
