import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../../../../components/Modal/Modal";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import Issues from "./Issues";
import { _getETFClass, _getInterval, _getIntervalLabel } from "./utils";
import { Instruments } from "../../../../../../api/compute/Instruments";
import { deepClone } from "../../../../../../deepClone";
import { getAncestorByType } from "../../../../../../api/compute/Taxon";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Search from "../../../../widgets/ReactSearch/Search";

type Props = {
  onClose: () => void;
  onSwap: Function;
  data: any;
  paramsWizard: any;
  startPositions: string[];
};
const holdingProperties = [
  "country",
  "marketcap",
  "symbol",
  "ticker",
  "pr",
  "drr",
  "lr",
  "icb",
  "type",
  "vc",
  "dr",
  "rc",
  "prr",
  "rrr",
  "etfgeo",
  "name",
  "domicile",
  "currency",
  "etfclass",
  "dc",
  "weight",
  "_s_where",
  "date",
];
export default function Swapper({
  onClose,
  data,
  paramsWizard,
  onSwap,
  startPositions,
}: Props) {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const instrumentsAPI = useMemo(() => new Instruments(envSetup), [envSetup]);
  const [alternatives, setAlternatives] = useState<any[]>([]);
  const [alternativesIndex, setAlternativesIndex] = useState(0);
  const _dataGet = useCallback(
    (filterParams) => {
      var holding = deepClone(data);
      var taxonomies = environment.get("rawTaxonomies");

      var paramsAlternatives: any = {
        constraints: {
          filters: [
            {
              dimension: "type",
              segments: [holding.type],
            },
          ],
          ranges: [
            {
              dimension: "rc",
              segments: [
                {
                  max: 2,
                  min: 2,
                },
              ],
            },
            {
              dimension: "pr",
              segments: [
                {
                  max: null,
                  min: 0,
                },
              ],
            },
          ],
          page: {
            page: 1,
            rows: 99999,
          },
        },
        instrument: holding,
        top: 20,
      };

      switch (holding.type) {
        case "ETF": {
          // ETF class
          if (filterParams.etfclass === true) {
            paramsAlternatives.constraints.filters.push({
              dimension: "etfclass",
              segments: [holding.etfclass],
            });

            paramsAlternatives.constraints.filters.push({
              dimension: "etfclass",
              segments: [
                getAncestorByType(
                  holding.etfclass,
                  taxonomies["ETFclassification"],
                  "1 Industry"
                ).id,
              ],
            });
          }

          // ETF Geo
          if (filterParams.etfgeo === true) {
            paramsAlternatives.constraints.filters.push({
              dimension: "etfgeo",
              segments: [holding.etfgeo],
            });
          }

          // ETF where
          if (filterParams.etfwhere === true) {
            paramsAlternatives.constraints.filters.push({
              dimension: "country",
              segments: [holding.country],
            });
          }

          break;
        }
        default: {
          // marketcap
          if (filterParams.marketcap === true) {
            var merketcapRange = _getInterval(holding.marketcap);

            //
            // 2021-02-26 Index instruments do not have
            //      marketcap
            //
            if (merketcapRange != null) {
              paramsAlternatives.constraints.ranges.push({
                dimension: "marketcap",
                segments: [
                  {
                    max: merketcapRange.right,
                    min: merketcapRange.left,
                  },
                ],
              });
            }
          }

          // what
          if (filterParams.what === true) {
            paramsAlternatives.constraints.filters.push({
              dimension: "icb",
              segments: [
                getAncestorByType(holding.icb, taxonomies["ICB"], "3 Sector")
                  .id,
              ],
            });
          }

          // where
          if (filterParams.where === true) {
            paramsAlternatives.constraints.filters.push({
              dimension: "country",
              segments: [holding.country],
            });
          }
        }
      }

      var universe = paramsWizard.universe;
      if (universe.length > 0) {
        paramsAlternatives.constraints.relations = [
          {
            domain: universe.map(function (item) {
              return String(item);
            }),
            range: "BASKET",
          },
        ];
      }

      return instrumentsAPI
        .alternativesTo(paramsAlternatives)
        .then((response) => {
          if (response.status === "OK") {
            //#region - se le proposte esistono già nello starting portfolio non farle vedere
            const filtered = response.data.filter(
              (symbol) => !startPositions.includes(symbol)
            );
            //#endregion
            setAlternatives(filtered);
          }
        });
    },
    [data, environment, instrumentsAPI, paramsWizard.universe, startPositions]
  );
  const [actualAlternative, setActualAlternative] = useState<any>(null);
  useEffect(() => {
    if (alternatives.length > 0) {
      instrumentsAPI
        .fetch({
          symbols: [alternatives[alternativesIndex]],
          type: "security",
          properties: holdingProperties.map((item) => ({
            date: null,
            property: item,
          })),
        })
        .then((response) => {
          if (response.data) {
            setActualAlternative(response.data[0]);
          }
        });
    }
  }, [alternatives, alternativesIndex, data.type, instrumentsAPI]);

  const onToggleSwapperFilterHandler = useCallback(
    (e) => {
      _dataGet(e);
    },
    [_dataGet]
  );
  const onSwapHandlerSearch = useCallback(
    async (e) => {
      const response = await instrumentsAPI.fetch({
        symbols: [e.symbol],
        type: "security",
        properties: holdingProperties.map((item) => ({
          date: null,
          property: item,
        })),
      });
      onSwap({
        buy: [response.data[0]],
        sell: [data],
      });
    },
    [data, instrumentsAPI, onSwap]
  );

  const onSwapHandlerButton = useCallback(() => {
    onSwap({
      buy: [actualAlternative],
      sell: [data],
    });
  }, [actualAlternative, data, onSwap]);
  return (
    <Modal
      closeOnClickAway={false}
      onClose={() => onClose()}
      closeIcon={false}
      headerConfig={{
        headerContent: (
          <Typography variant="subtitle1">
            Swap <strong>{data.name}</strong> to a similar stock with{" "}
            <strong className="rate rate--A">A</strong> rating
          </Typography>
        ),
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Issues
          hasLogo={true}
          isHighlighted
          hasWeight={false}
          data={data}
          onHold={() => {}}
          onSell={() => {}}
          onSwap={() => {}}
          hasActions={false}
        />
        <SwapperFilter data={data} onToggle={onToggleSwapperFilterHandler} />
        {alternatives.length > 0 && actualAlternative != null ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <IconButton
                onClick={() => setAlternativesIndex(alternativesIndex - 1)}
                disabled={alternativesIndex <= 0}
                size="small"
                color="primary"
              >
                <ArrowCircleLeftOutlinedIcon />
              </IconButton>
              <Typography>
                {alternativesIndex + 1} of {alternatives.length}
              </Typography>
              <IconButton
                onClick={() => setAlternativesIndex(alternativesIndex + 1)}
                disabled={alternativesIndex >= alternatives.length - 1}
                size="small"
                color="primary"
              >
                <ArrowCircleRightOutlinedIcon />
              </IconButton>
            </Box>
            <Issues
              hasLogo={true}
              hasWeight={false}
              data={actualAlternative}
              hasActions={false}
            />
            {actualAlternative != null && (
              <Box display={"flex"} justifyContent={"center"}>
                <Button onClick={onSwapHandlerButton}>
                  <Typography>
                    Sell <strong>{data.ticker}</strong> and buy{" "}
                    <strong>{actualAlternative.ticker}</strong>
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Typography>
            There are no trending indices in{" "}
            {formatWhere(data, environment, envSetup)},{" "}
            {formatWhat(data, environment, envSetup)}
          </Typography>
        )}
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <Typography>
            Or you can select manually which security to swap{" "}
            <strong>{data.ticker}</strong> with
          </Typography>
          <Search
            showInstrumentInfoOnSelect={false}
            onSelectInstrument={(e) => onSwapHandlerSearch(e)}
          />
        </Box>
      </Box>
    </Modal>
  );
}
type SwapperFilterType = {
  onToggle: Function;
  data: any;
};
const SwapperFilter = ({ data, onToggle }: SwapperFilterType) => {
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  const format = useFormatter();
  const [state, setState] = useState(() => {
    if (data.type !== "ETF") {
      return {
        marketcap: true,
        where: true,
        what: true,
      };
    } else {
      return {
        etfclass: true,
        etfgeo: true,
        etfwhere: true,
      };
    }
  });

  const switchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    },
    [state]
  );

  useEffect(() => onToggle(state), [onToggle, state]);
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Typography>Search only in:</Typography>
      <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
        <FormControlLabel
          control={
            <Switch
              checked={data.type !== "ETF" ? state.marketcap : state.etfclass}
              name={data.type !== "ETF" ? "marketcap" : "etfclass"}
              onChange={switchHandler}
              size="small"
            />
          }
          label={
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    data.type !== "ETF"
                      ? _getIntervalLabel(data.marketcap, null, format)
                      : formatWhat(data, environment, envSetup),
                }}
              />
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={data.type !== "ETF" ? state.where : state.etfgeo}
              onChange={switchHandler}
              name={data.type !== "ETF" ? "where" : "etfgeo"}
              size="small"
            />
          }
          label={
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    data.type !== "ETF"
                      ? formatWhere(data, environment, envSetup)
                      : formatEtfGeo(data, environment, envSetup),
                }}
              />
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={data.type !== "ETF" ? state.what : state.etfwhere}
              name={data.type !== "ETF" ? "what" : "etfwhere"}
              onChange={switchHandler}
              size="small"
            />
          }
          label={
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    data.type !== "ETF"
                      ? formatWhat(data, environment, envSetup)
                      : "Listed in " + formatWhere(data, environment, envSetup),
                }}
              />
            </Typography>
          }
        />
      </FormGroup>
    </Box>
  );
};
const formatWhat = (holding, environment, envSetup) => {
  var taxonomies = environment.get("rawTaxonomies");

  switch (holding.type) {
    case "ETF": {
      var tokens = _getETFClass(holding.etfclass, environment, envSetup);
      return tokens[tokens.length - 1].name + " " + tokens[0].name;
    }
    default: {
      return taxonomies["ICB"][holding.icb].name;
    }
  }
};
const formatWhere = (holding, environment, envSetup) => {
  var taxonomies = environment.get("rawTaxonomies");
  const taxonFields = envSetup["taxonomyFields"];

  var taxonomyWhere = taxonFields
    ? holding.type === "ETF"
      ? taxonomies[taxonFields["ETF"]["etfgeo"]]
      : taxonomies[taxonFields["security"]["country"]]
    : null;

  var where = taxonomyWhere?.[holding.country] ?? null;

  return where ? where.name : holding.country;
};
const formatEtfGeo = (holding, environment, envSetup) => {
  var taxonomies = environment.get("rawTaxonomies");
  const fieldsMap = envSetup["taxonomyFields"];
  var taxonomyWhere = taxonomies[fieldsMap["ETF"]["etfgeo"]];
  var where = taxonomyWhere[holding.etfgeo];
  return where.name;
};
