import AddPinnedSelector from "../widgets/AddPinnedSelector";
import PinnedDialogHelp from "../widgets/PinnedDialogHelp";

type PanelrightProps = {
  showSelector: boolean;
  updateSelectorStatus: Function;
};

export default function Panelright({
  showSelector = false,
  updateSelectorStatus,
}: PanelrightProps) {
  return (
    <>
      {!showSelector ? (
        <PinnedDialogHelp />
      ) : (
        <AddPinnedSelector updateSelectorStatus={updateSelectorStatus} />
      )}
    </>
  );
}
