import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { useFormatter } from "../../../../../hooks/useFormatter";

type CompareItemProps = {
  canDelete: boolean;
  color: any;
  index: any;
  type: string;
  value: any;
  isLast: boolean;
  onRemove: (instrument) => void;
};

export const CompareItem = forwardRef(
  (
    {
      type,
      value,
      index,
      color,
      canDelete,
      isLast,
      onRemove,
    }: CompareItemProps,
    ref: any
  ) => {
    const format = useFormatter();
    const nodeTickerRef = useRef<HTMLDivElement>(null);

    const checkSecurityDecorator = useCallback(
      (formattedField) => {
        if (value != null) {
          try {
            return formattedField;
          } catch (error) {
            return "";
          }
        }

        return "";
      },
      [value]
    );

    const nodePerformanceSince = useMemo(() => {
      return checkSecurityDecorator(
        format.text(
          "pr",
          "performance_since_rated",
          value["pr"],
          null,
          value["type"]
        )
      );
    }, [checkSecurityDecorator, format, value]);

    const nodePerformanceLastYTD = useMemo(() => {
      return checkSecurityDecorator(
        format.html(
          "pytd",
          "performance_ytd",
          value["pytd"],
          null,
          value["type"]
        )
      );
    }, [checkSecurityDecorator, format, value]);

    const nodePerformanceLastWeek = useMemo(() => {
      return checkSecurityDecorator(
        format.html("pw", "performance_week", value["pw"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodePerformanceLastMonth = useMemo(() => {
      return checkSecurityDecorator(
        format.html("pm", "performance_month", value["pm"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodePerformanceLast3Months = useMemo(() => {
      return checkSecurityDecorator(
        format.html(
          "pq",
          "performance_3_months",
          value["pq"],
          null,
          value["type"]
        )
      );
    }, [checkSecurityDecorator, format, value]);

    const nodePerformanceLast12Months = useMemo(() => {
      return checkSecurityDecorator(
        format.html(
          "py",
          "performance_12_months",
          value["py"],
          null,
          value["type"]
        )
      );
    }, [checkSecurityDecorator, format, value]);

    const ticker = useMemo(() => {
      let formattedTicker = format.html(
        "ticker",
        "ticker",
        value["ticker"],
        null,
        value["type"]
      );

      if (index != null && parseInt(index, 10) > 0) {
        formattedTicker += "<sup>" + index + "</sup>";
      }

      return checkSecurityDecorator(formattedTicker);
    }, [checkSecurityDecorator, format, index, value]);

    const nodeRate = useMemo(() => {
      return checkSecurityDecorator(
        format.html("rc", "rating", value["rc"], value, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeRateDate = useMemo(() => {
      return checkSecurityDecorator(
        format.html("dr", "rated_on_human", value["dr"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeMomentum = useMemo(() => {
      return checkSecurityDecorator(
        format.html("mc", "smart_momentum", value["mc"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeRetracement = useMemo(() => {
      return checkSecurityDecorator(
        format.html("px", "retracement", value["px"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeTrendStrength = useMemo(() => {
      return checkSecurityDecorator(
        format.html("ts", "ts", value["ts"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeType = useMemo(() => {
      return checkSecurityDecorator(
        format.html("type", "type", value["type"], null, value["type"])
      );
    }, [checkSecurityDecorator, format, value]);

    const nodeIndustry = useMemo(() => {
      if (value["type"] === "Stock") {
        return checkSecurityDecorator(
          format.html("industry", "industry", value["icb"], null, value["type"])
        );
      }

      return "";
    }, [checkSecurityDecorator, format, value]);

    const nodeCurrency = useMemo(() => {
      return checkSecurityDecorator(
        format.html(
          "currency",
          "currency",
          value["currency"],
          null,
          value["type"]
        )
      );
    }, [checkSecurityDecorator, format, value]);

    const handleBgStyles = useCallback(() => {
      const nodeTicker = nodeTickerRef?.current;

      if (!nodeTicker) {
        return;
      }

      if (color != null) {
        nodeTicker.style.backgroundColor = color;
        nodeTicker.style.color = "#ffffff";
      } else {
        nodeTicker.style.backgroundColor = "";
        nodeTicker.style.color = "inherited";
      }
    }, [color]);

    const deleteInstrument = useCallback(() => {
      onRemove(value);
    }, [onRemove, value]);

    useEffect(() => {
      handleBgStyles();
    }, [handleBgStyles]);

    return (
      <div
        ref={ref}
        className={`wSecurityAnalysisTabCompareItem ${
          isLast === true ? "last" : ""
        }`}
      >
        <div className="wSecurityAnalysisTabCompareItem-items">
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-ticker"
            data-dojo-attach-point="nodeTickerWrap"
            ref={nodeTickerRef}
          >
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-ticker"
              dangerouslySetInnerHTML={{ __html: ticker }}
            ></div>
            {canDelete === true && (
              <div
                className="wSecurityAnalysisTabCompareItem-itemAction wSecurityAnalysisTabCompareItem-itemAction-delete"
                data-dojo-attach-point="nodeDelete"
                onClick={deleteInstrument}
              >
                <i className="i-cancel"></i>
              </div>
            )}
          </div>
          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-type">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-type"
              dangerouslySetInnerHTML={{ __html: nodeType }}
            ></div>
          </div>
          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-currency">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-currency"
              dangerouslySetInnerHTML={{ __html: nodeCurrency }}
            ></div>
          </div>
          {type === "Stock" && (
            <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-industry wSecurityAnalysisTabCompareItem-item--doubleHeight">
              <div
                className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-industry"
                data-dojo-attach-point="nodeIndustry"
                dangerouslySetInnerHTML={{ __html: nodeIndustry }}
              ></div>
            </div>
          )}

          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item--header"></div>

          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-rate wSecurityAnalysisTabCompareItem-item--doubleHeight">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-rate"
              dangerouslySetInnerHTML={{ __html: nodeRate }}
            ></div>
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-rateDate"
              dangerouslySetInnerHTML={{ __html: nodeRateDate }}
            ></div>
          </div>
          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-momentum wSecurityAnalysisTabCompareItem-item--doubleHeight">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-momentum"
              dangerouslySetInnerHTML={{ __html: nodeMomentum }}
            ></div>
          </div>
          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-retracement wSecurityAnalysisTabCompareItem-item--doubleHeight">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-retracement"
              dangerouslySetInnerHTML={{ __html: nodeRetracement }}
            ></div>
          </div>
          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-retracement wSecurityAnalysisTabCompareItem-item--doubleHeight">
            <div
              className="wSecurityAnalysisTabCompareItem-itemContent wSecurityAnalysisTabCompareItem-itemContent-trendStrength"
              dangerouslySetInnerHTML={{ __html: nodeTrendStrength }}
            ></div>
          </div>

          <div className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item--header"></div>

          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-since wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceSince }}
          ></div>
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lydt wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceLastYTD }}
          ></div>
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lw wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceLastWeek }}
          ></div>
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-lm wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceLastMonth }}
          ></div>
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-l3m wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceLast3Months }}
          ></div>
          <div
            className="wSecurityAnalysisTabCompareItem-item wSecurityAnalysisTabCompareItem-item-l12m wSecurityAnalysisTabCompareItem-item--alignRight"
            dangerouslySetInnerHTML={{ __html: nodePerformanceLast12Months }}
          ></div>
        </div>
      </div>
    );
  }
);
