import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import SecurityChartModal from "../../../../../../components/SecurityChartModal/SecurityChartModal";
import SecurityTooltipCore from "../../../../../../components/SecurityTooltip/SecurityTooltipCore";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "tabulator-tables";
import { createRoot } from "react-dom/client";

type TacticalAndImplementType = {
  orders: any;
  onChangeOrder: Function;
};

const TacticalAndImplement = ({
  onChangeOrder,
  orders,
}: TacticalAndImplementType) => {
  const format = useFormatter();
  const environment = useEnvironment();
  const navigate = useNavigate();
  const weightFormatter = useCallback(
    (value) => {
      return format.custom("number", {
        options: {
          hasPositiveSign: false,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
        },
        output: "HTML",
        value: value["weight"],
        valueHelper: null,
      });
    },
    [format]
  );

  const columns = useMemo<ColumnDefinition[]>(() => {
    return [
      {
        title: "Weight",
        field: "weight",
        formatter: (cell) => {
          let value = cell.getData();
          return weightFormatter(value);
        },
      },
      { title: "Ticker", field: "ticker" },
      {
        title: "Rating",
        field: "rc",
        formatter: (cell, value, node) => {
          const data = cell.getData();
          return format.table("rc", "table", data);
        },
      },
      {
        title: "",
        field: "actions",
        formatter: (cell) => {
          const _data: any = cell.getData();
          const rowElement = cell.getRow().getElement();
          if (_data.from === "buy") {
            if (!_data.skip) {
              rowElement.style.backgroundColor = "#ddd";
            } else {
              rowElement.style.backgroundColor = "#fff";
            }
          } else {
            if (!_data.skip) {
              rowElement.style.backgroundColor = "#fff";
            } else {
              rowElement.style.backgroundColor = "#ddd";
            }
          }
          return `<div style="display:flex" class="content"></div>`;
        },
      },
    ];
  }, [format, weightFormatter]);
  const [showSecurityChart, setShowSecurityChart] = useState(false);
  const [securityFocus, setSecurityFocus] = useState<any>(null);

  const tableEventsHandler = useCallback(
    (e) => {
      switch (e.type) {
        case "rowMouseEnter": {
          const row = e.value;
          let cell = row.getCell("actions");
          const cellElement = cell
            .getElement()
            .getElementsByClassName("content")[0];
          let _data = cell.getRow().getData();
          const nodeToAppend = document.createElement("span");
          nodeToAppend.classList.add(_data?.symbol.split(" ").join("-"));
          const root = createRoot(nodeToAppend);
          root.render(
            <Box
              className="actionsContainer"
              style={{ visibility: "visible" }}
              display={"flex"}
              gap={1}
              justifyContent={"center"}
            >
              <SecurityTooltipCore
                symbol={_data?.symbol ?? ""}
                environment={environment}
                chartButtonHandler={(security) => {
                  setSecurityFocus(security);
                  setShowSecurityChart(true);
                }}
                setSecurityData={() => {}}
                additionalButtons={[
                  {
                    label: "Security analysis",
                    callback: () => {
                      navigate(`/app/analysis/instrument/${_data?.symbol}/`);
                    },
                  },
                ]}
              />

              <EditIcon
                onClick={() => {
                  const weightCell = cell.getRow().getCell("weight");
                  const element = weightCell.getElement();
                  const root = createRoot(element);
                  root.render(
                    <InputForWeight
                      initValue={weightCell.getValue()}
                      onBlur={(e) => {
                        let value = e.target.value;
                        onChangeOrder({
                          security: _data,
                          action: "editWeight",
                          payload: value,
                        });
                      }}
                    />
                  );
                }}
                sx={{ fontSize: "0.7vw" }}
              />
              {_data?.skip ? (
                <UndoIcon
                  onClick={() => {
                    onChangeOrder({
                      security: _data,
                      action: "skip",
                      payload: false,
                    });
                  }}
                  sx={{ fontSize: "0.7vw" }}
                />
              ) : (
                <DeleteIcon
                  onClick={() => {
                    onChangeOrder({
                      security: _data,
                      action: "skip",
                      payload: true,
                    });
                  }}
                  sx={{ fontSize: "0.7vw" }}
                />
              )}
            </Box>
          );
          cellElement.appendChild(nodeToAppend);

          break;
        }
        case "rowMouseLeave": {
          const row = e.value;
          let cell = row.getCell("actions");
          const cellElement = cell.getElement();
          let _data = cell.getRow().getData();
          const element = cellElement.getElementsByClassName(
            _data?.symbol.split(" ").join("-")
          )[0];
          element.remove();
          break;
        }
      }
    },
    [environment, navigate, onChangeOrder]
  );
  return (
    <Box display={"flex"} gap={1} height={"100%"} width={"100%"}>
      {showSecurityChart && securityFocus != null && (
        <SecurityChartModal
          environment={environment}
          security={securityFocus}
          showModal={showSecurityChart}
          onClose={() => setShowSecurityChart(false)}
        />
      )}
      <Card sx={{ boxShadow: 2, flex: 1 }}>
        <CardContent
          sx={{ overflow: "hidden", height: "100%", p: "8px !important" }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            height={"100%"}
            overflow={"hidden"}
          >
            <Typography variant="tr_subtitle_blue">
              <strong>Buy</strong>
            </Typography>
            <Box overflow={"auto"}>
              <TrendratingTable
                autoResize={false}
                eventCallback={tableEventsHandler}
                options={{
                  ajaxSorting: false,
                }}
                disableDefaultRowClick={true}
                columns={columns}
                data={orders?.buy}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 2, flex: 1 }}>
        <CardContent
          sx={{ overflow: "hidden", height: "100%", p: "8px !important" }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            height={"100%"}
            overflow={"hidden"}
          >
            <Typography variant="tr_subtitle_blue">
              <strong>Sell </strong>
            </Typography>
            <Box overflow={"auto"}>
              <TrendratingTable
                eventCallback={tableEventsHandler}
                autoResize={false}
                disableDefaultRowClick={true}
                options={{
                  ajaxSorting: false,
                }}
                columns={columns}
                data={orders?.sell}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default TacticalAndImplement;
type InputForWeightType = {
  initValue: number;
  onBlur: (e) => void;
};
const InputForWeight = ({ initValue, onBlur }: InputForWeightType) => {
  return (
    <input
      autoFocus
      onBlur={onBlur}
      defaultValue={(initValue * 100).toFixed(2)}
      type="number"
      step={0.01}
      style={{ width: "100%" }}
    />
  );
};
