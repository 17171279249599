/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateTableSystematicProductHolding
 * @summary Requests for systemati product holding table templates
 *
 */

import { _TemplateTable } from "./_TemplateTable";

export class TemplateTableSystematicProductHolding extends _TemplateTable {
    storedObjectType = "TEMPLATE_TABLE_SYSTEMATIC_PRODUCT_HOLDING" as const;
}
