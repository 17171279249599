import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useResizer } from "../../../../../../../../../hooks/useResizer";
import { WidgetsOptions } from "../../fields/WidgetsOptions";
import styles from "./WeightingRulesController.module.scss";
import SmartBetaTable from "./widgets/SmartBetaTable/SmartBetaTable";
import { UI_CONSTRAINTS_TYPE } from "../../../Advanced/utils";

type WeightingRulesControllerProps = {
  currentConfiguration: {
    rotation: null | { factor: string; rotate: string };
    smartBeta: { operator: any; weight: number; property: string }[] | null;
    weightingSchema: "WEIGHT_EQUAL" | "WEIGHT_MARKET_CAP";
    weightingSchemaExistingPositions:
      | "WEIGHT_EXISTING_POSITIONS_KEEP"
      | "WEIGHT_EXISTING_POSITIONS_REBALANCE";
  };
  options: any[];
  onCloseSelection: Function;
  onCancel: Function;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function WeightingRulesController({
  currentConfiguration,
  options,
  onCloseSelection,
  onCancel,
  UI_CONSTRAINTS,
}: WeightingRulesControllerProps) {
  const containerRef = useRef<any>(null);
  useResizer({ ref: containerRef });
  const [showSmartBeta, setShowSmartBeta] = useState(
    currentConfiguration.smartBeta != null
  );
  const [showRotationRules, setShowRotationRules] = useState(
    currentConfiguration.rotation != null
  );
  const { t } = useTranslation();
  const optionsGateway = useMemo(() => new WidgetsOptions(), []);
  const rotationOptions = useMemo(
    () => optionsGateway.getRotation("OPTIONS_EXTENDED", t),
    [optionsGateway, t]
  );
  const rotationFactorOptions = useMemo(
    () => [
      {
        label: "Rating Breadth",
        value: "FACTOR_MOMENTUM",
      },
      {
        label: "Rating Growth",
        value: "FACTOR_MOMENTUM_GROWTH",
      },
      {
        label: "Market neutral",
        value: "FACTOR_MARKET_CAP_NEUTRAL",
      },
    ],
    []
  );

  const weightingSchemaInit =
    currentConfiguration?.weightingSchema ?? "WEIGHT_EQUAL";
  const weightingSchemaExistingPositionsInit =
    currentConfiguration?.weightingSchemaExistingPositions ??
    "WEIGHT_EXISTING_POSITIONS_KEEP";

  const smartBetaRef =
    useRef<WeightingRulesControllerProps["currentConfiguration"]["smartBeta"]>(
      null
    );
  const rotationRulesRef =
    useRef<WeightingRulesControllerProps["currentConfiguration"]["rotation"]>(
      null
    );
  const weightingSchemaRef =
    useRef<
      WeightingRulesControllerProps["currentConfiguration"]["weightingSchema"]
    >(weightingSchemaInit);
  const existingPositionsRef = useRef<
    WeightingRulesControllerProps["currentConfiguration"]["weightingSchemaExistingPositions"]
  >(weightingSchemaExistingPositionsInit);

  const [changesMap, updateChangesMap] = useState({
    rotation: false,
    smartBeta: false,
    weightingSchema: false,
    existingPositions: false,
  });
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [isAllValid, setIsAllValid] = useState(true);

  const getRightSmartBetaOperator = useCallback(
    (option, property) => {
      const smartBetaSelectedValues = currentConfiguration?.smartBeta ?? null;
      let currentSmartBetaConfig: any = null;

      if (smartBetaSelectedValues) {
        currentSmartBetaConfig = smartBetaSelectedValues.reduce(
          (prev, current) => {
            prev[current.property] = current;

            return prev;
          },
          {}
        );
      }

      const operator = {
        constraints: option["functions"][0]["widget"]["constraints"],
        weight:
          currentSmartBetaConfig != null && property in currentSmartBetaConfig
            ? currentSmartBetaConfig[property]["weight"]
            : option["functions"][0]["widget"]["value"],
        value: null,
      };

      switch (option["operators"][0][0]["widget"]["type"]) {
        case "rate":
          operator.value =
            currentSmartBetaConfig != null && property in currentSmartBetaConfig
              ? currentSmartBetaConfig[property]["operator"]
              : option["operators"][0][0]["widget"]["value"];

          break;

        case "select":
          operator["options"] = option["operators"][0][0]["widget"]["options"];
          operator["value"] =
            currentSmartBetaConfig != null && property in currentSmartBetaConfig
              ? currentSmartBetaConfig[property]["operator"]
              : option["operators"][0][0]["widget"]["value"];

          break;
      }

      return operator;
    },
    [currentConfiguration?.smartBeta]
  );

  const smartBetaWidgetConfiguration = useCallback(() => {
    const smartBetaSelectedValues = currentConfiguration?.smartBeta ?? null;
    let currentSmartBetaConfig: any = null;
    const configurationMap: any = {};
    let property: any = null;

    if (smartBetaSelectedValues) {
      currentSmartBetaConfig = smartBetaSelectedValues.reduce(
        (prev, current) => {
          prev[current.property] = current;

          return prev;
        },
        {}
      );
    }

    for (const option of options) {
      property = option?.["property"]?.["value"] ?? "UNKNOWN";

      configurationMap[property] = {
        active:
          currentSmartBetaConfig != null && property in currentSmartBetaConfig
            ? true
            : false,
        operator: getRightSmartBetaOperator(option, property),
        label: option["property"]["label"],
      };
    }

    return configurationMap;
  }, [currentConfiguration.smartBeta, getRightSmartBetaOperator, options]);

  const handleShowSmartBeta = useCallback(
    (e) => {
      setShowSmartBeta(e.target.checked);
      if (!e.target.checked) {
        smartBetaRef.current = null;

        if (currentConfiguration.smartBeta == null) {
          updateChangesMap((prev) => ({ ...prev, smartBeta: false }));
        } else {
          updateChangesMap((prev) => ({ ...prev, smartBeta: true }));
        }
      }
    },
    [currentConfiguration.smartBeta]
  );

  const setSmartBeta = useCallback(
    (smartBeta) => {
      if (showSmartBeta) {
        const smartBetaValue = smartBeta?.length ?? null ? smartBeta : null;
        smartBetaRef.current = smartBetaValue;

        if (
          JSON.stringify(currentConfiguration.smartBeta) !==
          JSON.stringify(smartBetaValue)
        ) {
          updateChangesMap((prev) => ({ ...prev, smartBeta: true }));
        } else {
          updateChangesMap((prev) => ({ ...prev, smartBeta: false }));
        }
      }
    },
    [currentConfiguration.smartBeta, showSmartBeta]
  );

  const rotateInitialValue =
    currentConfiguration.rotation != null
      ? currentConfiguration.rotation.rotate
      : rotationOptions[0].value;
  const factorInitialValue =
    currentConfiguration.rotation != null
      ? currentConfiguration.rotation.factor
      : rotationFactorOptions[0].value;

  const [rotate, setRotate] = useState<string>(rotateInitialValue);
  const [factor, setFactor] = useState<string>(factorInitialValue);

  const [weightingSchema, setWeightingSchema] = useState(weightingSchemaInit);
  const [
    weightingSchemaExistingPositions,
    setWeightingSchemaExistingPositions,
  ] = useState(weightingSchemaExistingPositionsInit);

  const handleWeigthingSchemaChange = useCallback(
    (value) => {
      setWeightingSchema(value);
      weightingSchemaRef.current = value;
      if (currentConfiguration.weightingSchema !== value) {
        updateChangesMap({ ...changesMap, weightingSchema: true });
      } else {
        updateChangesMap({ ...changesMap, weightingSchema: false });
      }
    },
    [changesMap, currentConfiguration.weightingSchema]
  );

  const handleWeightingSchemaExistingPositionsChange = useCallback(
    (value) => {
      setWeightingSchemaExistingPositions(value);
      existingPositionsRef.current = value;

      if (currentConfiguration.weightingSchemaExistingPositions !== value) {
        updateChangesMap({ ...changesMap, existingPositions: true });
      } else {
        updateChangesMap({ ...changesMap, existingPositions: false });
      }
    },
    [changesMap, currentConfiguration.weightingSchemaExistingPositions]
  );

  const handleShowRotationRules = useCallback(
    (e) => {
      setShowRotationRules(e.target.checked);
      if (e.target.checked) {
        rotationRulesRef.current = {
          factor: rotationFactorOptions[0].value,
          rotate: rotationOptions[0].value,
        };

        if (currentConfiguration.rotation == null) {
          updateChangesMap({ ...changesMap, rotation: true });
        } else {
          updateChangesMap({ ...changesMap, rotation: false });
        }
      } else {
        rotationRulesRef.current = null;

        if (currentConfiguration.rotation != null) {
          updateChangesMap({ ...changesMap, rotation: true });
        } else {
          updateChangesMap({ ...changesMap, rotation: false });
        }
      }
    },
    [
      changesMap,
      currentConfiguration.rotation,
      rotationFactorOptions,
      rotationOptions,
    ]
  );

  const setRotation = useCallback(
    (value: string, type: "rotate" | "factor") => {
      if (type === "factor") {
        setFactor(value);

        if (currentConfiguration.rotation) {
          if (
            currentConfiguration.rotation.factor !== value ||
            currentConfiguration.rotation.rotate !== rotate
          ) {
            updateChangesMap({ ...changesMap, rotation: true });
          } else {
            updateChangesMap({ ...changesMap, rotation: false });
          }
        }
      } else {
        setRotate(value);

        if (currentConfiguration.rotation) {
          if (
            currentConfiguration.rotation.rotate !== value ||
            currentConfiguration.rotation.factor !== factor
          ) {
            updateChangesMap({ ...changesMap, rotation: true });
          } else {
            updateChangesMap({ ...changesMap, rotation: false });
          }
        }
      }
    },

    [changesMap, currentConfiguration.rotation, factor, rotate]
  );

  useEffect(() => {
    if (showRotationRules) {
      rotationRulesRef.current = {
        factor,
        rotate,
      };
    }
  }, [factor, rotate, showRotationRules]);

  const smartBetaInitConfig = useMemo(() => {
    return smartBetaWidgetConfiguration();
  }, [smartBetaWidgetConfiguration]);

  const getState = useCallback(() => {
    return {
      rotation: rotationRulesRef.current,
      smartBeta: smartBetaRef.current,
      weightingSchema: weightingSchemaRef.current,
      weightingSchemaExistingPositions: existingPositionsRef.current,
    };
  }, []);

  useEffect(() => {
    let isStateChanged = false;

    for (const value of Object.values(changesMap)) {
      if (value === true) {
        isStateChanged = true;
      }
    }

    setIsStateChanged(isStateChanged);
  }, [changesMap]);

  return (
    <Box
      flex={1}
      display={"flex"}
      p={2}
      ref={containerRef}
      alignItems={"flex-start"}
      minHeight={0}
      flexDirection={"column"}
    >
      <Box className={styles.animated__wrapper} gap={2}>
        <Box display={"flex"} flex={4} flexDirection={"column"} gap={1}>
          <Box display={"flex"} flex={1} gap={1} minHeight={0}>
            {/* Weighting schema */}
            <Card sx={{ display: "flex", flex: 1, minHeight: 0 }}>
              <CardContent
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  minHeight: 0,
                }}
              >
                <FormControl>
                  <FormLabel
                    focused={false}
                    id="weighting-schema-radio-buttons-group-label"
                  >
                    Weighting Schema:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="weighting-schema-radio-buttons-group-label"
                    value={false}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="WEIGHT_EQUAL"
                      sx={{ marginLeft: 0 }}
                      checked={weightingSchema === "WEIGHT_EQUAL"}
                      onChange={(e) =>
                        handleWeigthingSchemaChange("WEIGHT_EQUAL")
                      }
                      control={<Radio sx={{ padding: "5px" }} size="small" />}
                      label="Equal weighted"
                    />
                    <FormControlLabel
                      value="WEIGHT_MARKET_CAP"
                      checked={weightingSchema === "WEIGHT_MARKET_CAP"}
                      sx={{ marginLeft: 0 }}
                      onChange={(e) =>
                        handleWeigthingSchemaChange("WEIGHT_MARKET_CAP")
                      }
                      control={<Radio sx={{ padding: "5px" }} size="small" />}
                      label="Market cap weighted"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                  <FormLabel
                    focused={false}
                    id="existing-positions-radio-buttons-group-label"
                  >
                    Existing positions:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="existing-positions-radio-buttons-group-label"
                    value={false}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{ marginLeft: 0 }}
                      value="WEIGHT_EXISTING_POSITIONS_REBALANCE"
                      checked={
                        "WEIGHT_EXISTING_POSITIONS_REBALANCE" ===
                        weightingSchemaExistingPositions
                      }
                      onChange={() =>
                        handleWeightingSchemaExistingPositionsChange(
                          "WEIGHT_EXISTING_POSITIONS_REBALANCE"
                        )
                      }
                      control={<Radio sx={{ padding: "5px" }} size="small" />}
                      label="Rebalance weights"
                    />
                    <FormControlLabel
                      value="WEIGHT_EXISTING_POSITIONS_KEEP"
                      checked={
                        "WEIGHT_EXISTING_POSITIONS_KEEP" ===
                        weightingSchemaExistingPositions
                      }
                      sx={{ marginLeft: 0 }}
                      onChange={() =>
                        handleWeightingSchemaExistingPositionsChange(
                          "WEIGHT_EXISTING_POSITIONS_KEEP"
                        )
                      }
                      control={<Radio sx={{ padding: "5px" }} size="small" />}
                      label="Keep weights"
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
            {/* Smart Beta */}
            <Card
              sx={{
                display: "flex",
                flex: 2,
                mx: 1,
                minHeight: 0,
              }}
            >
              <CardContent sx={{ display: "flex", flex: 1, minHeight: 0 }}>
                <Box
                  display={"flex"}
                  flex={1}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                  minHeight={0}
                >
                  <FormControlLabel
                    sx={{
                      fontSize: "0.8vw!important",
                      marginLeft: 0,
                    }}
                    control={
                      <Switch
                        sx={{
                          fontSize: "0.8vw!important",
                          ".Mui-checked": {
                            color: "#2a7092 !important",
                          },
                        }}
                        size="small"
                        checked={showSmartBeta}
                        onChange={handleShowSmartBeta}
                      />
                    }
                    classes={{
                      label: styles.switch__label__info,
                    }}
                    label="Smart Beta"
                  />
                  <Collapse
                    in={showSmartBeta}
                    sx={{
                      maxHeight: "100%",
                      width: "100%",
                      ".MuiCollapse-wrapper": {
                        maxHeight: "100%",
                      },
                    }}
                  >
                    <Box
                      flex={1}
                      component={"fieldset"}
                      className={styles.outlined}
                      minHeight={0}
                    >
                      <legend className={styles.outlined__label}>
                        Smart Beta
                      </legend>
                      <Box className={styles.outlined__content}>
                        <SmartBetaTable
                          smartBetaConfiguration={smartBetaInitConfig}
                          setSmartBeta={setSmartBeta}
                          setIsValid={setIsAllValid}
                        />
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </CardContent>
            </Card>
            {/* Rotation Rules */}
            {UI_CONSTRAINTS.weightingRules.rotationRules.enabled === true && (
              <Card sx={{ display: "flex", flex: 1, minHeight: 0 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <FormControlLabel
                    sx={{ marginLeft: 0 }}
                    control={
                      <Switch
                        size={"small"}
                        checked={showRotationRules}
                        onChange={handleShowRotationRules}
                      />
                    }
                    label="Rotation rules"
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Collapse in={showRotationRules}>
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel
                          focused={false}
                          id={"Rotate-radio-buttons-group-label"}
                        >
                          Rotate:
                        </FormLabel>
                        <RadioGroup
                          row={false}
                          aria-labelledby={"Rotate-radio-buttons-group-label"}
                          value={false}
                          name="radio-buttons-group"
                        >
                          {rotationOptions.map((option) => {
                            if (option.value != null) {
                              return (
                                <FormControlLabel
                                  key={uuidv4()}
                                  onChange={() =>
                                    setRotation(option.value, "rotate")
                                  }
                                  value={option.value}
                                  control={
                                    <Radio
                                      sx={{
                                        padding: "5px",
                                      }}
                                      size="small"
                                    />
                                  }
                                  label={option.label}
                                  sx={{
                                    marginLeft: 0,
                                  }}
                                  checked={rotate === option.value}
                                />
                              );
                            } else {
                              return option.children.map((child) => (
                                <FormControlLabel
                                  key={uuidv4()}
                                  onChange={() =>
                                    setRotation(child.value, "rotate")
                                  }
                                  sx={{
                                    marginLeft: 0,
                                  }}
                                  value={child.value}
                                  control={
                                    <Radio
                                      sx={{
                                        padding: "5px",
                                      }}
                                      size="small"
                                    />
                                  }
                                  label={child.label}
                                  checked={rotate === child.value}
                                />
                              ));
                            }
                          })}
                        </RadioGroup>
                      </FormControl>

                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel
                          focused={false}
                          id={"Rotate-radio-buttons-group-label"}
                        >
                          Rotate:
                        </FormLabel>
                        <RadioGroup
                          row={false}
                          aria-labelledby={"Rotate-radio-buttons-group-label"}
                          value={false}
                          name="radio-buttons-group"
                        >
                          {rotationFactorOptions.map<any>((option) => {
                            return (
                              <FormControlLabel
                                key={uuidv4()}
                                onChange={() =>
                                  setRotation(option.value, "factor")
                                }
                                value={option.value}
                                control={
                                  <Radio
                                    sx={{
                                      padding: "5px",
                                    }}
                                    size="small"
                                  />
                                }
                                label={option.label}
                                sx={{
                                  marginLeft: 0,
                                }}
                                checked={factor === option.value}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Collapse>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>
          <Card sx={{ display: "flex", minHeight: 0 }}>
            <CardContent sx={{ flex: 1, paddingBottom: "16px!important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#2a7092",
                    "&:hover": {
                      backgroundColor: "#00496d",
                    },
                    ml: 2,
                    textTransform: "capitalize",
                  }}
                  onClick={() => onCloseSelection(getState())}
                  type="button"
                  disabled={!isStateChanged || !isAllValid}
                >
                  Apply rule
                </Button>

                <Button
                  sx={{
                    color: "#2a7092",
                    backgroundColor: "#fff",
                    ml: 2,
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#f2f2f2",
                    },
                  }}
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  cancel
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
        {/* Help Section */}
        <Help />
      </Box>
    </Box>
  );
}

const Help = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flex: 1,
        backgroundColor: "#f2f2f2",
        minHeight: 0,
        overflow: "hidden",
      }}
    >
      <CardContent
        sx={{
          boxShadow: 3,
          flex: 1,
          minHeight: 0,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
            backgroundColor: "transparent",
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#d3d3d3",
          },
        }}
      >
        <section>
          <h2 style={{ marginBottom: "16px" }}>Weighting rules</h2>
          <p style={{ marginBottom: "10px" }}>
            Specify the weighting schema that will be used to assign weights to
            securities selected for inclusion in the rebalanced portfolio.
          </p>
          <ul>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Schema:</strong> the two options are equal weighting and
                market cap weighting.
                <i>
                  Generally one would select equal weighted. Selecting market
                  cap weighting imposes a size and style in addition to trend
                  capture.
                </i>
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Existing Positions:</strong> the two options are to
                either to rebalance weights to the allowed position maximum at
                every rebalance period, or to allow weights to grow for valid
                securities.
                <i>
                  A feature of trend capture is that it allows a portfolio to
                  maximise participation in strong up-trends, so Keep weights is
                  generally a preferred option.
                </i>
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Smart Beta:</strong> weighting schema where a higher
                weight is assigned to a higher score on the factor(s) selected.
                The Smart Beta schema supports multi-factor selection and a
                higher score across all factors selected result in a higher
                weight in the rebalanced portfolio.
              </p>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <p>
                <strong>Rotation Rules:</strong> Rotate: select the rotation
                level. Rotation factor: the allocation across groups is
                implemented using one of the following factors:
              </p>
              <ul>
                <li>
                  - Rating Breadth: the relative distribution of uptrends versus
                  downtrends for each grouping decides the weight assigned to
                  each grouping.
                </li>
                <li>
                  - Rating Growth: the change in directional rating dictates the
                  allocation.
                </li>
                <li>
                  - Market neutral: the allocation weight for each group is held
                  in line with the weight allocation for each group in the
                  investment universe.
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </CardContent>
    </Card>
  );
};
