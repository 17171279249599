import { Box, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormatter } from "../../../../../../../../hooks/useFormatter";
import { v4 as uuidv4 } from "uuid";

type DistributionProps = {
  cardinalityPerRating: { A: number; B: number; C: number; D: number };
  weightsPerRating: { A: number; B: number; C: number; D: number };
};

var options = [
  {
    label: "weights",
    value: "PERCENTAGE",
  },
  {
    label: "holdings",
    value: "NUMBER",
  },
];

export function Distribution({
  cardinalityPerRating,
  weightsPerRating,
}: DistributionProps) {
  const [cache, setCache] = useState<{
    NUMBER: any;
    PERCENTAGE: any;
  }>({
    NUMBER: null,
    PERCENTAGE: null,
  });
  const [what, setWhat] = useState<"NUMBER" | "PERCENTAGE">("PERCENTAGE");
  const format = useFormatter();

  const dataPrepare = useCallback((what, data) => {
    const semplifiedData = {
      A: data["A"],
      B: data["B"],
      C: data["C"],
      D: data["D"],
    };

    setCache((prev) => ({ ...prev, [what]: semplifiedData }));
  }, []);

  const dataGet = useCallback(() => {
    switch (what) {
      case "NUMBER": {
        dataPrepare(what, cardinalityPerRating);
        break;
      }
      default: {
        // PERCENTAGE
        dataPrepare(what, weightsPerRating);
      }
    }
  }, [cardinalityPerRating, dataPrepare, weightsPerRating, what]);

  const handleChange = useCallback((event) => {
    const what = event?.target.value;

    setWhat(what);
  }, []);

  useEffect(() => {
    dataGet();
  }, [dataGet]);

  const A = useMemo(
    () =>
      what === "PERCENTAGE"
        ? format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: 0,
              },
            },
            output: "HTML",
            value: cache?.[what]?.A,
            valueHelper: null,
          })
        : cache?.[what]?.A ?? "-",
    [cache, format, what]
  );
  const B = useMemo(
    () =>
      what === "PERCENTAGE"
        ? format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: 0,
              },
            },
            output: "HTML",
            value: cache?.[what]?.B,
            valueHelper: null,
          })
        : cache?.[what]?.B ?? "-",
    [cache, format, what]
  );
  const C = useMemo(
    () =>
      what === "PERCENTAGE"
        ? format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: 0,
              },
            },
            output: "HTML",
            value: cache?.[what]?.C,
            valueHelper: null,
          })
        : cache?.[what]?.C ?? "-",
    [cache, format, what]
  );
  const D = useMemo(
    () =>
      what === "PERCENTAGE"
        ? format.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: 0,
              },
            },
            output: "HTML",
            value: cache?.[what]?.D,
            valueHelper: null,
          })
        : cache?.[what]?.D ?? "-",
    [cache, format, what]
  );

  return (
    <div>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <h1 className="tAnalysisOverview-title">Rating</h1>
        <Select size="small" value={what} onChange={handleChange}>
          {options.map((item) => (
            <MenuItem key={uuidv4()} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: "8px",
        }}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <div className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
            <strong className="tAnalysisOverview-rate format-rate format-rate--A">
              A
            </strong>
            <span
              className="tAnalysisOverview-distributionValue"
              data-dojo-attach-point="nodeRateA"
            >
              {A}
            </span>
          </div>
          <div className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
            <strong className="tAnalysisOverview-rate format-rate format-rate--B">
              B
            </strong>
            <span
              className="tAnalysisOverview-distributionValue"
              data-dojo-attach-point="nodeRateB"
            >
              {B}
            </span>
          </div>
        </div>

        <div style={{ display: "flex", gap: "8px" }}>
          <div className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
            <strong className="tAnalysisOverview-rate format-rate format-rate--C">
              C
            </strong>
            <span
              className="tAnalysisOverview-distributionValue"
              data-dojo-attach-point="nodeRateC"
            >
              {C}
            </span>
          </div>
          <div className="tAnalysisOverview-box tAnalysisOverview-box--distribution">
            <strong className="tAnalysisOverview-rate format-rate format-rate--D">
              D
            </strong>
            <span
              className="tAnalysisOverview-distributionValue"
              data-dojo-attach-point="nodeRateD"
            >
              {D}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
