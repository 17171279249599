import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { messageError } from "../js/app/utils";
import { useBroadcast } from "./useBroadcast";
import { appCookie } from "../js/app/Cookies";

export function useAxios() {
  const { t, ready } = useTranslation();
  const { broadcast } = useBroadcast();

  useEffect(() => {
    if (!ready) {
      return;
    }

    console.log("Initializing axios", axios.defaults, appConfig);
    // axios.defaults.baseURL = appConfig.app.domain;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    // axios.defaults.headers.common["Authorization"] = "AUTH TOKEN";

    const requestInterceptor = axios.interceptors.request.use(
      (request) => {
        // console.log(request);
        // Edit request config
        return request;
      },
      (error) => {
        // console.log(error);
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (
          response?.data?.status === "KO" &&
          response?.data?.error === "USER_NOT_ALLOWED"
        ) {
          // Try to reset impersonate cookie
          appCookie("impersonate", "login-reset");
          appCookie("productId", "login-reset");
          // this._error(response.data);
          console.log("error", response.data);
        }

        //console.log(response);
        // Edit response config
        return response;
      },
      (error) => {
        console.log("status", error?.response?.status);
        if (error?.response?.status === 401) {
          // TODO client not logged, see here to redirect to
          // app-account (login) page, but beware that there can
          // be special situation where it can be problematic
          // (for example when you are already on login page)

          // this._error(error);
          console.log("error", error);
          return Promise.reject(error);
        } else if (error?.response?.status === 504) {
          // this._error(error);
          const [channel, msg] = messageError(t("Http_error_504"), error);
          broadcast(channel as string, msg);
          return Promise.reject(error);
        } else {
          // console.log(error);
          return Promise.reject(error);
        }
      }
    );

    return () => {
      if (requestInterceptor) {
        axios.interceptors.request.eject(requestInterceptor);
      }
      if (responseInterceptor) {
        axios.interceptors.response.eject(responseInterceptor);
      }
    };
  }, [broadcast, ready, t]);
}
