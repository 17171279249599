import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { deepClone } from "../../../../../../../../deepClone";

type TagFiltersProps = {
  tags: {
    allName: string;
    id: string;
    name: string;
    type: string;
    display: string[];
    filters: {
      id: string;
      name: string;
      type: string;
    }[];
  }[];
  forceMultiple: boolean;
  filterKey: "table" | "filter" | "edit";
  setSelectedTags;
};

export default function TagFilters({
  tags,
  filterKey,
  setSelectedTags,
}: TagFiltersProps) {
  const tags_operative = useMemo(() => tags, [tags]);

  const onCheckFilter = useCallback(
    (groudID, filterID, checked) => {
      let tt: any[] = deepClone(tags_operative);
      let indexGroup = tt.findIndex((item) => item.id === groudID);
      let indexFilter = tt[indexGroup].filters.findIndex(
        (item) => item.id === filterID
      );
      tt[indexGroup].filters[indexFilter].selected = checked;
      let selected: string[] = [];
      tt.forEach((group) => {
        group.filters.forEach((filter) => {
          if (filter?.selected != null && filter?.selected === true) {
            selected.push(filter.id);
          }
        });
      });
      setSelectedTags(selected);
    },
    [setSelectedTags, tags_operative]
  );

  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      {tags_operative.map((item, index) => {
        if (item.display != null && item.display.indexOf(filterKey) !== -1) {
          return (
            <Card sx={{ boxShadow: 3 }} key={index}>
              <CardContent sx={{ pb: "16px !important" }}>
                <TagSection
                  groupID={item.id}
                  title={item.name}
                  filters={item.filters ?? []}
                  onCheckFilter={onCheckFilter}
                />
              </CardContent>
            </Card>
          );
        }
        return null;
      })}
    </Box>
  );
}

type TagSectionType = {
  title: string;
  filters: any[];
  groupID: string;
  onCheckFilter: Function;
};
const TagSection = ({
  title,
  filters,
  groupID,
  onCheckFilter,
}: TagSectionType) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      overflow={"auto"}
    >
      <Typography><strong>{title}</strong></Typography>
      <Box>
        <FormGroup>
          {filters.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item.selected != null ? item.selected : false}
                  value={item.id}
                  onChange={(e) =>
                    onCheckFilter(groupID, item.id, e.target.checked)
                  }
                />
              }
              label={<Typography sx={{textOverflow:"ellipsis"}} title={item.name}>{item.name}</Typography>}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};
