import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./DndList.module.scss";

type Props = {
  listContentObjBuilder: any;
  setOutterState: Function;
  listcustomStyle?: {
    gap?: number;
    direction?: "horizontal" | "vertical";
    listItempPadding?: number | string;
    itemWidth?: number;
  };
  showHandlers?: boolean;
  isDragable?: boolean;
  isSource?: boolean;
  addField?: Function;
  isSortable?: boolean;
};

export default function DndList({
  listContentObjBuilder,
  setOutterState,
  listcustomStyle,
  addField,
  showHandlers = false,
  isDragable = true,
  isSource = false,
  isSortable = true,
}: Props) {
  const [OperativeContent, setOperativeContent] = useState(
    listContentObjBuilder
  );

  useEffect(() => {
    setOperativeContent(listContentObjBuilder);
  }, [listContentObjBuilder]);

  useEffect(() => {
    setOutterState(OperativeContent);
  }, [OperativeContent, setOutterState]);

  const [draggingElement, setDraggingdElement] = useState<any>(null);
  const listRef = useRef<HTMLUListElement>(null);
  //*----------------------
  //*----------------------
  //* DRAG & DROP FUNCTIONS
  const dragOver = useCallback(
    (e: any) => {
      e.preventDefault();
      //!! this part is responsible for the place indicator management
      //!! if isSortable is false, we don't need to show the place indicator
      if (isSortable) {
        if (
          e.clientY <
          e.target.getBoundingClientRect().top + e.target.offsetHeight / 2
        ) {
          //insert before
          if (e.currentTarget.classList.contains(style.after)) {
            e.currentTarget.classList.remove(style.after);
          }
          e.currentTarget.classList.add(style.before);
        } else {
          //insert after
          if (e.currentTarget.classList.contains(style.before)) {
            e.currentTarget.classList.remove(style.before);
          }
          e.currentTarget.classList.add(style.after);
        }
      }
    },
    [isSortable]
  );
  const dragStart = useCallback(
    (e: any) => {
      setDraggingdElement(e.currentTarget.id);
      if (isDragable && isSource) {
        let data = JSON.stringify(
          OperativeContent[e.currentTarget.id].props.configObj
        );
        e.dataTransfer.setData("text", data);
      }
    },
    [OperativeContent, isDragable, isSource]
  );
  const drop = useCallback(
    (e: any) => {
      //! if list is not sortable on drop do nothing
      if (isSortable) {
        let newArr = [...OperativeContent];
        const currentPos = parseInt(draggingElement);
        const droppedPos = parseInt(e.currentTarget.id);
        const dataTransferResult = e.dataTransfer.getData("text");
        // addField is the function that will add the new obj to listContentObjBuilder
        // if addField != null means that list is droppable
        if (addField != null && dataTransferResult !== "") {
          // as it has been defined at dragStart, if (isDragable && isSource)
          // dataTransfer.getData() will be a string to be converted to json
          const dd = JSON.parse(dataTransferResult);

          //! no need to call setOperativeContent(that will the run the useEffect to setOutterState)
          //! because addField at dojo will rerender the whole react component and update everything
          if (
            e.clientY <
            e.target.getBoundingClientRect().top + e.target.offsetHeight / 2
          ) {
            addField({
              value: dd?.value ?? null,
              position: droppedPos,
            });
          } else {
            addField({
              value: dd?.value ?? null,
              position: droppedPos + 1,
            });
          }
          return;
        }

        let absDifference = Math.abs(currentPos - droppedPos);

        const arrChildren = Array.from(listRef.current!.children);
        arrChildren.forEach((item) => {
          item.classList.remove(style.hint);
          //   if (item.classList.contains(style.active)) {
          //     item.classList.remove(style.active);
          //   }
          if (item.classList.contains(style.before)) {
            item.classList.remove(style.before);
          }
          if (item.classList.contains(style.after)) {
            item.classList.remove(style.after);
          }
        });
        if (currentPos !== droppedPos) {
          const cliY = e.clientY;
          const midPoint =
            e.target.getBoundingClientRect().top + e.target.offsetHeight / 2;
          if (cliY < midPoint) {
            //---------insert before
            if (!(absDifference === 1 && currentPos < droppedPos)) {
              if (currentPos !== -1 && droppedPos !== -1) {
                const item = newArr.splice(currentPos, 1);
                newArr.splice(droppedPos, 0, item[0]);
                setOperativeContent(newArr);
              }
            }
          } else {
            //--------insert after
            if (!(absDifference === 1 && currentPos > droppedPos)) {
              if (currentPos !== -1 && droppedPos !== -1) {
                const item = newArr.splice(currentPos, 1);
                if (droppedPos > currentPos) {
                  newArr.splice(droppedPos, 0, item[0]);
                } else {
                  newArr.splice(droppedPos + 1, 0, item[0]);
                }
                setOperativeContent(newArr);
              }
            }
          }
        }
      }
    },
    [OperativeContent, addField, draggingElement, isSortable]
  );
  const dragEnter = (e: React.DragEvent) => {};
  const dragLeave = useCallback((e: any) => {
    if (e.currentTarget.classList.contains(style.before)) {
      e.currentTarget.classList.remove(style.before);
    }
    if (e.currentTarget.classList.contains(style.after)) {
      e.currentTarget.classList.remove(style.after);
    }
  }, []);
  //*----------------------
  //*----------------------
  const removeItem = (index) => {
    let arr = [...OperativeContent];
    arr.splice(index, 1);
    setOperativeContent(arr);
  };

  const addToEmptyListHandler = (e: any) => {
    if (OperativeContent.length <= 0) {
      if (addField != null && e.dataTransfer.getData("text") !== "") {
        let dd = JSON.parse(e.dataTransfer.getData("text"));
        addField({ value: dd.value, position: 0 });
        return;
      }
    }
  };

  return (
    <ul
      onDrop={(e: any) => addToEmptyListHandler(e)}
      onDragOver={(e) => e.preventDefault()}
      ref={listRef}
      style={{
        width: "100%",
        listStyle: "none",
        display: "flex",
        flexDirection:
          listcustomStyle != null && listcustomStyle.direction === "horizontal"
            ? "row"
            : listcustomStyle != null &&
              listcustomStyle.direction === "vertical"
            ? "column"
            : "column",
        gap: listcustomStyle != null ? listcustomStyle.gap : 0,
      }}
    >
      {OperativeContent.length > 0
        ? OperativeContent.map((item, index) => (
            <DndListItem
              key={index}
              style={{
                // position: "relative",
                padding:
                  listcustomStyle != null
                    ? listcustomStyle.listItempPadding
                    : 0,
                overflow: "hidden",
                // textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                width:
                  listcustomStyle != null
                    ? `${listcustomStyle.itemWidth}%`
                    : "auto",
              }}
              styleFromSCSS={style}
              dragLeave={dragLeave}
              dragEnter={dragEnter}
              drop={drop}
              dragOver={dragOver}
              index={index}
              dragStart={dragStart}
              item={item}
              showHandlers={showHandlers}
              OperativeContent={OperativeContent}
              removeItem={removeItem}
              isDragable={isDragable}
            />
          ))
        : "Empty list"}
    </ul>
  );
}

type DndListItemProps = {
  style: any;
  dragLeave: any;
  dragEnter: any;
  drop: any;
  dragOver: any;
  index: any;
  dragStart: any;
  item: any;
  showHandlers: any;
  OperativeContent: any;
  removeItem: any;
  styleFromSCSS: any;
  isDragable: boolean;
};
const DndListItem = ({
  style,
  dragEnter,
  dragLeave,
  dragOver,
  dragStart,
  drop,
  index,
  item,
  showHandlers,
  removeItem,
  styleFromSCSS,
  isDragable,
}: DndListItemProps) => {
  return (
    <li
      style={style}
      onDragLeave={(e) => {
        if (isDragable === true) {
          dragLeave(e);
        }
      }}
      onDragEnter={(e) => {
        if (isDragable === true) {
          dragEnter(e);
        }
      }}
      onDrop={(e) => {
        if (isDragable === true) {
          drop(e);
        }
      }}
      onDragOver={(e) => {
        if (isDragable === true) {
          dragOver(e);
        }
      }}
      className={
        isDragable === true
          ? styleFromSCSS.listItemSortable
          : styleFromSCSS.listItem
      }
      key={index}
      draggable={isDragable}
      id={index.toString()}
      onDragStart={(e) => {
        e.stopPropagation();
        if (isDragable === true) {
          dragStart(e);
        }
      }}
    >
      {item}
      {showHandlers === true && (
        <Box padding={1} sx={{ position: "absolute", right: 0, top: 0 }}>
          <span
            onClick={() => removeItem(index)}
            className={`${styleFromSCSS.closeBTN} i-skip`}
            style={{ color: "#000", cursor: "pointer" }}
          ></span>
        </Box>
      )}
    </li>
  );
};
