type LoaderProps = {
    message?: string;
};

export function Loader({ message = "Data is loading..." }: LoaderProps) {
    return (
        <div className="appLoaderAnimationWrapper appInnerLoaderAnimationWrapper">
            <div className="appLoaderAnimationContent">
                <div className="appLoaderAnimation-text">{message}</div>
                <div className="appLoaderAnimation">
                    <div className="appLoaderAnimation-bar"></div>
                </div>
                <div className="appLoaderAnimation-more"></div>
            </div>
        </div>
    );
}
