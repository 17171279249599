/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/endpoints
 * @summary Endpoints of Trendrating Stored Objects API
 *
 */

export const endpoints = {
  product: {
    all: "/product/all",
  },
  accounts: {
    sessions: {
      get: "/user/sessionInfo",
      remove: "/user/resetCookies",

      setProduct: "/user/compileproduct",
    },
    users: {
      askSupport: "/emails",

      // GET: /users/
      get: "/relay/fetch",
      getByIdsFilter: "/relay/select/user",

      impersonate: "/user/get",

      login: "/user/login",
      logout: "/user/logout",

      passwordRecovery: "/user/resetpwdrequest",
      passwordRecoveryMail: "/auth/recover",
      passwordSetupCheck: "/user/resetpwdvalidate",
      passwordSetupConfirm: "/user/resetpwdconfirm",
    },
  },
  clusterAnalytics: {
    analytic: "/stock/collection/clusterAnalytics",
  },
  // This is more related to securities / instruments
  common: {
    fetch: "/relay/fetch",

    // POST:
    analytics: "/stock/collection/priceAnalytics",

    // POST
    hPosAnalytics: "/stock/collection/hPosAnalytics",
  },
  instruments: {
    // GET: /instruments/alerts/
    alerts: "/stock/cache/calls/filtered",

    // GET: /instruments/:symbol/charts/
    charts: "/proxy/chart/svg/price/line",

    // GET: /instruments/:symbol/closest/
    closestTo: "/stock/security/rankclosest",

    // POST: /instruments/details/
    details: "/relay/fetch",

    // GET: /instruments/:symbol/histories/
    histories: "/stock/history/securities/rating/get",

    // GET: /instruments/:symbol/histories/?currencyTarget=<ISO code>
    historiesConverted: "/stock/history/securities/currency/convert",

    // POST: /instruments/rankings/
    rankings: "/stock/collection/rank/symbols",

    // POST: /instruments/searches/
    searches: "/stock/securities/indexedsearch/filtered",

    // POST: /instruments/subsets/
    subsets: "/stock/collection/select",

    // POST /instruments/screening
    screening: "/relay/screening",
  },
  lists: {
    // GET: /lists/ | /lists/:id/statistics/
    //
    // with params_snapshot prop it retrieves even peer information
    //
    analytics: "/stock/collection/portfolioAnalytics",

    // POST: /lists/alerts/
    alerts: "/relay/fetch",

    // POST: /lists/getListAnalytics/
    fetch: "/relay/fetch",

    // POST: /lists/comparisons/
    comparisons: "/stock/collection/orders",

    // POST: /lists/
    create: "/stock/collection/create",

    // GET: /lists/distributions
    // global trends distributions
    distributions: "/stock/trends/distribution",

    // GET: /lists/ | /lists/:id/
    getByIds: "/relay/fetch",
    getByIdsFilter: "/relay/select/collection",

    // POST: /lists/optimizations/
    optimizations: "/stock/collection/strategy/run",

    // POST: /lists/:id/orders
    orders: "/stock/collection/trade",

    // GET: /lists/:id/points-in-time/
    pointsInTime: "/stock/collection/weightedListAnalytics",

    // POST: /lists/rationales/
    rationales: "/stock/collection/strategy/run",

    // GET
    remove: "/stock/collection/delete",

    // POST: /lists/
    update: "/stock/collection/update",

    // POST: /lists/:id/weights/
    weightUpdates: "/stock/collection/weights/update",
  },
  news: {
    // GET: /news/:timeframe/
    get: "/stock/news/filtered",
  },
  newsSelect: {
    // GET: /news/:timeframe/
    get: "/stock/collection/select",
  },
  peers: {
    details: "/relay/fetch",
    // POST: /peers/dispersions/
    dispersions: "/stock/collection/quantiles",

    // GET: /peers/:symbol/
    ofInstrument: "/stock/peer/security",

    searches: "/stock/securities/indexedsearch",
    weights: "/stock/peersWeights",
  },
  rankings: {
    // POST: /rankings/runs/
    runs: "/stock/collection/rank/symbols",
  },
  reports: {
    disclaimers: {
      // GET: /reports/disclaimers/
      disclaimers: "/disclaimers/",
    },
    files: {
      // POST: /reports/files/
      files: "/files/",
    },
    logos: {
      // GET: /reports/logos/
      logos: "/logos/",
    },
  },
  pubSub: {
    commons: {
      // GET: no idea for an endpoint
      get: "/relay/fetch",
      objectGet: "/user/preferences/get/id",
    },
    publications: {
      // POST: /publications/
      create: "/share/publish",

      // GET: /publications/
      get: "/share/publish/list",

      // DELETE: /publications/
      remove: "/share/publish/delete/all",
    },
    subscriptions: {
      // POST: /subscriptions/
      create: "/share/subscribe",

      // GET: /subscriptions/
      get: "/share/subscribe/list",

      // GET /subscriptions
      find: "/share/subscribe/find",

      // DELETE: /subscriptions/
      remove: "/share/subscribe/delete",
    },
  },
  strategies: {
    // POST: /strategies/:id/analytics/
    analytics: "/stock/collection/analytics/detail",

    // POST, replaces old lazy
    backtest: "/stock/collection/strategy/backtest",

    // POST: /strategies/:id/combine/:id2/
    combine: "/stock/collection/strategy/combine",

    // POST combine 2 historical allocation series
    combineHistoricalPOS: "/stock/collection/hPosCombine",

    // POST: /strategies/:id/contributions/
    contributions: "/stock/collection/analytics/contributions",

    // GET: /strategies/
    // all strategies
    get: "/user/preferences/get",

    // POST: /select/preferences
    // all strategies id from an user
    strategySelect: "/relay/select/preferences",

    // GET: /strategies/:id/
    // single strategy
    get_id: "/user/preferences/get/id",

    // POST: /strategies/:id/holdings/
    holdings: "/stock/collection/analytics/contributions",

    // POST: /strategies/:id/holdings-history/
    holdingsHistory: "/stock/collection/analytics/historical",

    // POST: /strategies/:id/key-facts/
    keyFacts: "/stock/collection/analytics",

    // POST: /strategies/long-short/
    longShort: "/stock/collection/index/run/components",

    // POST: /strategies/:id/performances/
    performances: "/stock/collection/analytics",

    // POST: /strategies/rationales/
    rationales: "/stock/collection/strategy/run",

    // [DEPRECATED] POST
    runs: "/stock/collection/index/lazy",

    // POST, index run, replaces old runs (lazy)
    run: "/stock/collection/index/run",

    // POST /strategies/{id}/
    saveAndClearCache: "/user/preferences/save/strategy",

    // EventSource
    status: "/proxy/push/",
    statusLegacy: "/proxy/poll/",

    // GET: /strategies/tracked/:id/
    tracked: "/stock/collection/cache/index/prefs",

    // POST: /strategies/weights/
    weightUpdates: "/stock/collection/weights/update",
  },
  structures: {
    // GET: /taxonomies/
    // GET: /properties/
    get: "/proxy/cache/structures",
  },
  storedObjects: {
    // POST: /stored-objects/{id}/
    create: "/user/preferences/save",

    // GET: /stored-objects/
    get: "/user/preferences/get",

    // POST: /select-preferences
    select: "/relay/select/preferences",

    // POST: /relay/fetch
    fetch: "/relay/fetch",

    // GET: /stored-objects/{id}/
    getById: "/user/preferences/get/id",

    // DELETE: /stored-objects/{id}/
    remove: "/user/preferences/delete/object",

    // PATCH : /stored-objects/{id}/
    update: "/user/preferences/save",
  },
  systematicPortfolios: {
    addAllocation: "/portfolio/historical/allocation/add",

    addRebalance: "/portfolio/systematic/product/add/rebalance",

    create: "/portfolio/systematic/product/create",

    delete: "/portfolio/systematic/product/delete",

    select: "/relay/select/systematicProduct",

    get: "/portfolio/systematic/product/owner",
    //
    // 2021-12-02 - 2020-08-24
    // new filter and fetch: must replace "[GET]/systematic-products/"
    // must replace -> get: "/portfolio/systematic/product/owner",
    getByIdAutorebalanced: "/portfolio/systematic/product/select",
    //
    //

    getById: "/portfolio/systematic/product/get",

    rebalanceInfoById: "/portfolio/calendar/rebalance",

    removeAllocation: "/portfolio/systematic/product/allocation/remove",

    history: "/portfolio/historical/get",

    replaceConstituent: "/portfolio/historical/constituent/replace",

    update: "/portfolio/systematic/product/update",

    updateAllocation: "/portfolio/historical/allocation/update",

    updateAllocations: "/portfolio/historical/replaceAllocations",

    getFees: "/stock/collection/priceFee",
  },
  utils: {
    // POST: /utils/relations/
    relations: "/relation/action",

    // GET: /utils/today/
    today: "/stock/today",

    // GET: /utils/versions/
    versions: "/versions",
  },
};
