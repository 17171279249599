import { AppEnvironment } from "../../../types/Defaults";
import { ColumnsHelper } from "./ColumnsHelper";
import { RankingHelper } from "./RankingHelper";

export class TableHelpers {
  rank: RankingHelper;
  columns: ColumnsHelper;

  constructor(environment: AppEnvironment) {
    this.rank = new RankingHelper(environment);
    this.columns = new ColumnsHelper(environment);
  }

  get(helperName): RankingHelper & ColumnsHelper {
    const DICT = {
      rank: this.rank,
      columns: this.columns,
    };

    return DICT[helperName];
  }

  generateFromFields(fields: string[], sorter?: (a, b) => 1 | -1 | 0) {
    const targetCols: any = [];
    const sourceCols = this.columns.prepareInputColumns(fields);

    if (sourceCols) {
      const tableColumns = sourceCols;

      const fakeSorter = () => 0; //Is not used by reports
      const sorterFn = sorter ?? fakeSorter;

      for (const viewerCol of tableColumns) {
        if (viewerCol) {
          if ("customColConfiguration" in viewerCol) {
            switch (viewerCol.customColConfiguration) {
              case "rankConfiguration": {
                this.rank.configureAsRankCol(
                  viewerCol,
                  targetCols,
                  sorterFn,
                  false
                );

                break;
              }
              default:
                console.warn(
                  `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                );
            }
          } else {
            targetCols.push(
              this.columns.tabulatorColumn(viewerCol, sorterFn, undefined)
            );
          }
        }
      }
    }

    return targetCols;
  }
}
