import { Box, Button, ClickAwayListener } from "@mui/material";
import { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useDraggable } from "../../hooks/useDraggable";
import style from "./Modal.module.scss";

type ModalProps = {
  children?: React.ReactNode | React.ReactNode[];
  headerConfig?: {
    headerContent?: React.ReactNode | React.ReactNode[];
    hasBorder?: boolean;
    headerSubtitle?: string;
    hasBackground?: boolean;
  };
  onClose?: Function;
  onAccept?: Function;
  buttonsEnalbed?: boolean;
  buttons?: {
    name: string;
    callback: Function;
    variant?: string;
    class?: string[];
    style?: CSSProperties;
    disabled?: boolean;
    align?: "center" | "left" | "right";
  }[];
  hasOverlay?: boolean;
  isDraggable?: boolean;
  customCss?: CSSProperties;
  isResizable?: boolean;
  bodyCustomClass?: string;
  closeOnClickAway?: boolean;
  customWrapperCss?: CSSProperties;
  id?: string;
  closeIcon: boolean;
};

const onCloseHandler = (onCancel?: Function) => {
  if (onCancel != null) {
    onCancel();
  } else {
    console.warn(
      "You are trying to call onClose function without passing it through Modal component"
    );
  }
};

export default function Modal({
  children,
  headerConfig,
  onClose,
  buttonsEnalbed = false,
  hasOverlay = true,
  isDraggable = false,
  customCss,
  buttons,
  isResizable = false,
  bodyCustomClass,
  closeOnClickAway = true,
  customWrapperCss,
  id = "trendrating_modal",
  closeIcon,
}: ModalProps) {
  const refDrag = useRef<HTMLDivElement>(null);
  useDraggable(refDrag);

  const Modal = hasOverlay ? (
    <div
      style={customWrapperCss ?? undefined}
      className={style["wrapper-overlay"]}
    >
      <DialogCore
        isDraggable={isDraggable}
        headerConfig={headerConfig}
        onClose={onClose}
        buttonsEnalbed={buttonsEnalbed}
        isResizable={isResizable}
        customCss={customCss}
        buttons={buttons}
        bodyCustomClass={bodyCustomClass}
        closeOnClickAway={closeOnClickAway}
        id={id}
        closeIcon={closeIcon}
      >
        {children}
      </DialogCore>
    </div>
  ) : (
    <DialogCore
      isDraggable={isDraggable}
      headerConfig={headerConfig}
      onClose={onClose}
      buttonsEnalbed={buttonsEnalbed}
      isResizable={isResizable}
      customCss={customCss}
      buttons={buttons}
      bodyCustomClass={bodyCustomClass}
      closeOnClickAway={closeOnClickAway}
      id={id}
      closeIcon={closeIcon}
    >
      {children}
    </DialogCore>
  );

  return createPortal(Modal, document.body);
}

function DialogCore({
  isDraggable,
  headerConfig = { headerContent: "", hasBorder: true },
  onClose,
  children,
  buttonsEnalbed,
  customCss,
  isResizable,
  buttons,
  bodyCustomClass,
  closeOnClickAway,
  id,
  closeIcon,
}: Pick<
  ModalProps,
  | "isDraggable"
  | "headerConfig"
  | "onClose"
  | "children"
  | "buttonsEnalbed"
  | "customCss"
  | "isResizable"
  | "buttons"
  | "bodyCustomClass"
  | "closeOnClickAway"
  | "id"
  | "closeIcon"
>) {
  const refDrag = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  useDraggable(refDrag, headerRef);
  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef?.current && bodyRef?.current) {
      const header = headerRef.current;
      const body = bodyRef.current;
      const modal: any = header.parentNode;
      const modalDims = modal?.getBoundingClientRect();
      const headerDims = header?.getBoundingClientRect();
      const headerHeight = headerDims.height;
      const modalHeight = modalDims.height;

      const bodyHeight = modalHeight - headerHeight;
      const bodyHeightPercentage = `${(
        (bodyHeight / modalHeight) *
        100
      ).toFixed(2)}%`;

      body.style.height = bodyHeightPercentage;
    }
  });

  const close = useCallback(
    (e) => {
      e.stopPropagation();
      onCloseHandler(onClose);
    },
    [onClose]
  );

  const leftBtnsConfiguration = useMemo(() => {
    if (buttons) {
      return buttons.filter((btn) => btn.align === "left");
    }

    return [];
  }, [buttons]);

  const centerBtnsConfiguration = useMemo(() => {
    if (buttons) {
      return buttons.filter((btn) => btn.align === "center");
    }

    return [];
  }, [buttons]);
  const rightBtnsConfiguration = useMemo(() => {
    if (buttons) {
      return buttons.filter(
        (btn) => btn.align === "right" || btn.align == null
      );
    }

    return [];
  }, [buttons]);

  const { headerContent, hasBorder, headerSubtitle, hasBackground } =
    headerConfig;
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (closeOnClickAway && onClose) {
          onClose();
        }
      }}
    >
      <Box
        id={id}
        boxShadow={5}
        className={isResizable ? style["main-resizable"] : style.main}
        style={{ ...customCss }}
        ref={isDraggable ? refDrag : null}
        onClick={(e) => {
          // Stop propagation to parents (remember it's a portal)
          e.stopPropagation();
        }}
      >
        {closeIcon && (
          <span
            className={`i-cancel ${style.close__icon}`}
            onClick={close}
          ></span>
        )}
        {(headerContent || headerSubtitle) && (
          <div ref={headerRef} style={isDraggable ? { cursor: "grab" } : {}}>
            <div
              className={`${
                hasBorder ? style.modalHeaderBorder : style.modalHeader
              } ${hasBackground ? style.modalHeaderColored : ""}`}
            >
              {headerContent}
            </div>
            <div style={{ padding: "0px 10px" }}>{headerSubtitle}</div>
          </div>
        )}
        <div ref={bodyRef} className={`${style.body} ${bodyCustomClass}`}>
          {children}
        </div>
        {buttonsEnalbed && (
          <form className={style.btns}>
            {buttons && (
              <>
                <Box
                  className={`${style.btns__btnBox} ${style.btns__btnBox__left}`}
                >
                  {leftBtnsConfiguration.map((btn, index) => (
                    <Button
                      variant={
                        btn.variant === "cancel"
                          ? "tr_button_cancel"
                          : "contained"
                      }
                      disabled={btn.disabled ?? false}
                      key={index}
                      className={btn.class ? btn.class?.join(" ") : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        btn.callback();
                      }}
                    >
                      {" "}
                      {btn.name}
                    </Button>
                  ))}
                </Box>
                <Box
                  className={`${style.btns__btnBox} ${style.btns__btnBox__center}`}
                >
                  {centerBtnsConfiguration.map((btn, index) => (
                    <Button
                      variant={
                        btn.variant === "cancel"
                          ? "tr_button_cancel"
                          : "contained"
                      }
                      disabled={btn.disabled ?? false}
                      key={index}
                      className={btn.class ? btn.class?.join(" ") : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        btn.callback();
                      }}
                    >
                      {" "}
                      {btn.name}
                    </Button>
                  ))}
                </Box>
                <Box
                  className={`${style.btns__btnBox} ${style.btns__btnBox__right}`}
                >
                  {rightBtnsConfiguration.map((btn, index) => (
                    <Button
                      variant={
                        btn.variant === "cancel"
                          ? "tr_button_cancel"
                          : "contained"
                      }
                      disabled={btn.disabled ?? false}
                      key={index}
                      className={btn.class ? btn.class?.join(" ") : ""}
                      onClick={(e) => {
                        e.preventDefault();
                        btn.callback();
                      }}
                    >
                      {" "}
                      {btn.name}
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </form>
        )}
      </Box>
    </ClickAwayListener>
  );
}
