import { Card, CardContent, Typography } from "@mui/material";
import styles from "./PreviewCard.module.scss";


type PreviewCardType = {
  content: any;
  title: string;
  cardHandler: Function;
};
const PreviewCard = ({ content, title, cardHandler }: PreviewCardType) => {
  return (
    <Card
      sx={{ boxShadow: 3, }}
      className={styles.previewCard}
      onClick={() => cardHandler()}
    >
      <CardContent sx={{p:1,pb:"8px !important"}}>
        <Typography sx={{textTransform:"uppercase"}} color={"primary"}>{title}</Typography>
        {content}
      </CardContent>
    </Card>
  );
};

export default PreviewCard
