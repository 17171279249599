import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ImplementStrategy from "./ImplementStrategy";
import TacticalAllocation from "./TacticalAllocation";
import UpgradePositionRatings from "./UpgradePositionRatings";

type OptimizationsMethodologiesProps = {
  optimize: Function;
  setMetodology: Function;
};

export default function OptimizationsMethodologies({
  optimize,
  setMetodology,
}: OptimizationsMethodologiesProps) {
  const [optimizationMethodology, setOptimizationMethodology] = useState<
    "tacticalAllocation" | "implementStrategy" | "upgradePositionRatings" | null
  >(null);

  return (
    <Box px={1} display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="subtitle1">
        Choose the optimization methodology
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <TacticalAllocation
          onOptimize={optimize}
          open={optimizationMethodology === "tacticalAllocation"}
          onClickHandler={() => {
            if (optimizationMethodology === "tacticalAllocation") {
              setOptimizationMethodology(null);
              setMetodology(null);
            } else {
              setOptimizationMethodology("tacticalAllocation");
              setMetodology("tacticalAllocation");
            }
          }}
        />
        <ImplementStrategy
          onOptimize={optimize}
          open={optimizationMethodology === "implementStrategy"}
          onClickHandler={() => {
            if (optimizationMethodology === "implementStrategy") {
              setOptimizationMethodology(null);
              setMetodology(null);
            } else {
              setOptimizationMethodology("implementStrategy");
              setMetodology("implementStrategy");
            }
          }}
        />
        <UpgradePositionRatings
          onOptimize={optimize}
          onClickHandler={() => {
            if (optimizationMethodology === "upgradePositionRatings") {
              setOptimizationMethodology(null);
              setMetodology(null);
            } else {
              setOptimizationMethodology("upgradePositionRatings");
              setMetodology("upgradePositionRatings");
            }
          }}
          open={optimizationMethodology === "upgradePositionRatings"}
        />
      </Box>
    </Box>
  );
}
