import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { deepClone } from "../../../../../../../deepClone";

/*
Given an array of available properties, this components will return a
list item of checkbox to check
*/

type AvailableFieldsProps = {
  columnsAvailable: any;
  onchangeState: Function;
};

export default function AvailableFields({
  columnsAvailable,
  onchangeState,
}: AvailableFieldsProps) {
  const keys = useMemo(() => Object.keys(columnsAvailable), [columnsAvailable]);

  const prepareForChnage = (fieldID, tag, checkStatus) => {
    const temp = deepClone(columnsAvailable);
    temp[tag].forEach((item) => {
      if (item.field === fieldID) {
        item.selected = checkStatus;
      }
    });
    onchangeState(temp);
  };

  return (
    <Box display={"flex"} gap={2}>
      {keys.map((item) => (
        <FormGroup key={item}>
          <Typography>
            {" "}
            <strong>{item}</strong>
          </Typography>
          {columnsAvailable[item].map((it) => (
            <FormControlLabel
              key={it.field}
              control={
                <Checkbox
                  onChange={(e) =>
                    prepareForChnage(it.field, item, e.target.checked)
                  }
                  size="small"
                  checked={it.selected ?? false}
                />
              }
              label={it.label}
            />
          ))}
        </FormGroup>
      ))}
    </Box>
  );
}