import { useCallback } from "react";
import { CSV } from "../../../../utils/SingletonCSV";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { fromJsonToSerieAndMinMax } from "../../../../../../components/Chart";

type ExportProps = {
  data: { result: { combined: any }; strategy: any };
};

export function Export({ data }: ExportProps) {
  const formatter = useFormatter();

  const format = useCallback(
    (strategyCurve, benchmarkCurve) => {
      var lines: any = [];
      // header
      var header = ['"Date"', '"Index"'];
      if (benchmarkCurve) {
        header.push('"Benchmark"');
      }
      lines.push(header);
      // body
      var line: any = null;
      for (let i = 0, length = strategyCurve.length; i < length; i++) {
        line = [
          '"' +
            formatter.custom("date", {
              options: {
                isMillisecond: true,
                notAvailable: {
                  input: "",
                  output: "",
                },
              },
              output: "TEXT",
              value: strategyCurve[i][0],
              valueHelper: null,
            }) +
            '"',
          '"' +
            (strategyCurve[i][1] ? strategyCurve[i][1].toFixed(6) : "") +
            '"',
        ];

        if (benchmarkCurve && benchmarkCurve[i][1] !== "") {
          line.push('"' + benchmarkCurve[i][1].toFixed(6) + '"');
        }

        lines.push(line);
      }

      return lines;
    },
    [formatter]
  );

  const doAction = useCallback(() => {
    //**************** USAGE *******************
    var usage = window.App.usage;
    var info = {
      action: "EXPORT_STRATEGY",
      actionParam: data.strategy["id"],
      function: "STRATEGY_LONG_SHORT",
    };
    usage.record(info);
    //**************** USAGE *******************

    const res = data.result.combined;
    var _data = res.CURVES;
    var strategy = data.strategy;

    var priceBoundaries = { max: 0, min: 999999 };
    var strategyCurve = fromJsonToSerieAndMinMax(_data.H, priceBoundaries);
    var benchmarkCurve: any = null;
    if ("B" in _data && _data.B != null) {
      benchmarkCurve = fromJsonToSerieAndMinMax(_data.B, priceBoundaries);
    }

    // managing not aligned data
    if (benchmarkCurve && strategyCurve.length !== benchmarkCurve.length) {
      var hashTable = {};
      for (var i = 0, length = strategyCurve.length; i < length; i++) {
        hashTable[strategyCurve[i][0]] = {
          price: strategyCurve[i][1],
          benchmark: "",
        };
      }
      for (let i = 0; i < benchmarkCurve.length; i++) {
        if (benchmarkCurve[i][0] in hashTable) {
          hashTable[benchmarkCurve[i][0]].benchmark = benchmarkCurve[i][1];
        } else {
          hashTable[benchmarkCurve[i][0]] = {
            price: "",
            benchmark: benchmarkCurve[i][1],
          };
        }
      }
      benchmarkCurve = [];
      strategyCurve = [];
      var row: any = null;
      for (var timestamp in hashTable) {
        row = hashTable[timestamp];
        strategyCurve.push([parseInt(timestamp), row.price]);
        if (benchmarkCurve) {
          benchmarkCurve.push([parseInt(timestamp), row.benchmark]);
        }
      }
    }
    var lines = format(strategyCurve, benchmarkCurve);

    CSV.create(lines, strategy.name + ".csv");
  }, [data.result.combined, data.strategy, format]);

  return (
    <li
      className={"menu__item"}
      title="Export to MS Excel daily strategy values"
      onClick={doAction}
    >
      Export strategy
    </li>
  );
}
