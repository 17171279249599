import { CellComponent } from "tabulator-tables";
import { Formatter } from "../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../types/Defaults";
import { TableHelperCommon } from "./TableHelperCommon";
import { Properties } from "../../../api/Properties";
import { deepClone } from "../../../deepClone";

export class ColumnsHelper extends TableHelperCommon {
  formatter: Formatter;

  constructor(environment: AppEnvironment) {
    super(environment);

    this.formatter = environment.formatter;
  }

  tabulatorColumn(col, sortHandler, headerMenuDispatcher) {
    if ("skipTransformation" in col && col.skipTransformation === true) {
      delete col.skipTransformation;

      return col;
    }

    const headerMenu = this.prepareHeaderMenu(col, headerMenuDispatcher);

    const preparedCol = {
      field: col.field,
      title: col.label,
      formatter: (cell) => this.getFormatter(cell, col.field),
      sorter: () => 0, // () => 0 is a fake sorter used to avoid tabulator to sort data even if the sort is handled server side
      headerClick: this.prepareHeaderClickTapListener(sortHandler),
      headerSortStartingDir: this.getSortInitialDirection(
        col.field,
        "security"
      ),
      isPointInTime: "isPointInTime" in col ? col.isPointInTime : false,
    };

    if (headerMenu) {
      preparedCol["headerMenu"] = headerMenu;
    }

    return preparedCol;
  }

  prepareHeaderMenu(col, actionDispatcher) {
    const propertiesInstance = new Properties({
      properties: this.environment.properties,
    });
    const properties = {};

    for (const propertyGroup in propertiesInstance.properties) {
      for (const property in propertiesInstance.properties[propertyGroup]) {
        properties[property] = deepClone(
          propertiesInstance.properties[propertyGroup][property]
        );
      }
    }

    const property = properties?.[col.field];

    if (property) {
      if (
        "computable" in property &&
        property?.computable &&
        actionDispatcher != null
      ) {
        const menu = [
          { label: "&#956; Average", actionTag: "avg" },
          { label: "&#8593;	Sort Ascending", actionTag: "sortAsc" },
          { label: "&#8595;	Sort Descending", actionTag: "sortDesc" },
        ];
        return menu.map((menuItem) => ({
          ...menuItem,
          action: (e, column) =>
            actionDispatcher(
              {
                action: menuItem.label,
                field: col.field,
                actionTag: menuItem.actionTag,
              },
              column
            ),
        }));
      } else {
        return undefined;
      }
    }

    return undefined;
  }

  getFormatter(cell: CellComponent, property) {
    if (property === "contribution") {
      return this.formatter.custom("bar", {
        options: {
          hasPositiveSign: true,
          isPercentage: true,
          notAvailable: {
            input: null,
            output: "",
          },
          width: "2.25em",
        },
        output: "HTML",
        value: (cell.getData() as any).contribution,
        valueHelper: {
          normalizationThreshold: cell.getData()["_s_normalizationThreshold"],
        },
      });
    }

    const cellData = cell.getData();

    try {
      if (property) {
        const value = this.formatter.table(property, "table", cellData);

        return value;
      }

      return "";
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  addCustomColumnSorter(col, sorter) {
    return { ...col, headerClick: this.prepareHeaderClickTapListener(sorter) };
  }
}
