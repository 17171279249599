import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Fade,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Environment } from "../../../../../Environment";
import { styled } from "@mui/system";

type ListViewerTileItemMenuProps = {
  onOptionClickHandler: Function;
  optionType: "portfolio" | "basket";
  isReadOnly: boolean;
  smallBtn?: boolean;
  environment: Environment;
};

type DropdownMenuProps = {
  options: any;
  isReadOnly: boolean;
  onClickHandler: Function;
};

const StyledBtn = styled((props: any) => (
  <Button
    className={props.className}
    id="fade-button"
    aria-controls={props.open ? "fade-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={props.open ? "true" : undefined}
    onClick={props.handleClick}
  >
    {props.children}
  </Button>
))({
  borderRadius: "4px!important",
  borderColor: "1px solid #2a7090!important",
  backgroundColor: "white!important",
  padding: "3px!important",
  minWidth: "0!important",
  minHeight: 0,
});

export function ListViewerTileItemMenu({
  onOptionClickHandler,
  optionType,
  isReadOnly,
  environment,
  smallBtn = false,
}: ListViewerTileItemMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pageConfiguration = useMemo(
    () => environment.get("account")?.product?.configuration.analysis_list,
    [environment]
  );
  const { t } = useTranslation();

  const OPTIONS = useMemo(
    () => ({
      basket: [
        {
          label: t("c_action_analyze"),
          type: "item",
          value: "actionAnalyze",
        },
        {
          label: t("c_action_rank"),
          type: "item",
          value: "actionRank",
        },
        {
          label: t("c_action_compare"),
          type: "item",
          value: "actionCompare",
        },
        {
          label: t("c_action_edit"),
          type: "item",
          value: "actionEdit",
        },
        {
          label: t("c_action_rename"),
          type: "item",
          value: "actionRename",
        },
        {
          label: t("c_action_duplicate"),
          type: "item",
          value: "actionDuplicate",
        },
        {
          label: null,
          type: "separator",
          value: null,
        },
        {
          label: t("c_action_report"),
          type: "item",
          value: "actionReport",
        },
        {
          label: t("c_action_export"),
          type: "item",
          value: "actionExport",
        },
        {
          label: null,
          type: "separator",
          value: null,
        },
        {
          label: t("c_action_delete"),
          type: "item",
          value: "actionDelete",
        },
      ],
      portfolio: [
        {
          label: t("c_action_analyze"),
          type: "item",
          value: "actionAnalyze",
        },
        {
          label: t("c_action_optimize"),
          type: "item",
          value: "actionOptimize",
        },
        {
          label: t("c_action_compare"),
          type: "item",
          value: "actionCompare",
        },
        {
          label: t("c_action_rank"),
          type: "item",
          value: "actionRank",
        },
        {
          label: t("c_action_edit"),
          type: "item",
          value: "actionEdit",
        },
        {
          label: t("c_action_rename"),
          type: "item",
          value: "actionRename",
        },
        {
          label: t("c_action_duplicate"),
          type: "item",
          value: "actionDuplicate",
        },
        {
          label: null,
          type: "separator",
          value: null,
        },
        {
          label: t("c_action_report"),
          type: "item",
          value: "actionReport",
        },
        {
          label: t("c_action_export"),
          type: "item",
          value: "actionExport",
        },
        {
          label: null,
          type: "separator",
          value: null,
        },
        {
          label: t("c_action_delete"),
          type: "item",
          value: "actionDelete",
        },
      ],
    }),
    [t]
  );

  const optionsFromConfig = useMemo(() => {
    const actionsAvalilable = pageConfiguration.workflow;
    const optionsSet = OPTIONS[optionType];
    let options: any = [];
    let itemValue: any = null;

    for (const option of optionsSet) {
      itemValue =
        option["value"] != null
          ? option["value"].replace("action", "").toLowerCase()
          : null;

      if (
        itemValue == null ||
        !(itemValue in actionsAvalilable) ||
        (itemValue in actionsAvalilable &&
          actionsAvalilable[itemValue]["enabled"] === true)
      ) {
        options.push(option);
      }
    }

    return options;
  }, [OPTIONS, optionType, pageConfiguration.workflow]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onClickMenuOption = useCallback(
    (e, value) => {
      onOptionClickHandler(value);
      handleClose(e);
    },
    [onOptionClickHandler]
  );

  return (
    <div>
      <StyledBtn open={open} handleClick={handleClick}>
        <MenuIcon
          sx={
            smallBtn === true
              ? { color: "#2a7090", fontSize: "0.7vw" }
              : { color: "#2a7090", fontSize: "1vw" }
          }
        />
      </StyledBtn>{" "}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {optionType && OPTIONS[optionType] != null ? (
          <DropdownMenu
            options={optionsFromConfig}
            isReadOnly={isReadOnly}
            onClickHandler={onClickMenuOption}
          />
        ) : (
          <MenuItem>No options provided</MenuItem>
        )}
      </Menu>
    </div>
  );
}

const DropdownMenu = ({
  options,
  isReadOnly,
  onClickHandler,
}: DropdownMenuProps) => {
  const readOnlyFields = useMemo(
    () => [
      "actionAnalyze",
      "actionRank",
      "actionCompare",
      "actionDuplicate",
      "actionReport",
      "actionExport",
      "actionAnalyze",
    ],
    []
  );

  const preparedOptions = useMemo(() => {
    // Prevent duplicate separators
    const newOptions: any = [];
    let option: any = null;
    // Prepare new options
    if (isReadOnly) {
      var previousWasSeparator = false;
      for (let i = 0, length = options.length; i < length; i++) {
        option = options[i];
        if (
          option["type"] === "item" &&
          readOnlyFields.indexOf(option["value"]) === -1
        ) {
          continue; // Ignore non-read only fields actions
        }
        if (option["type"] === "separator") {
          if (previousWasSeparator) {
            continue; // Previous was already a separator, just move to the next elements
          }
          previousWasSeparator = true;
        } else {
          previousWasSeparator = false;
        }
        newOptions.push(option);
      }
      do {
        if (newOptions[newOptions.length - 1]["type"] === "separator") {
          newOptions.pop(); // Remove trailing separator
        } else {
          break;
        }
      } while (newOptions.length > 0);

      return newOptions;
    }

    return options;
  }, [isReadOnly, options, readOnlyFields]);

  const onClickOption = useCallback(
    (e, value) => {
      const event = e;
      event["value"] = { action: value };
      onClickHandler(e, event);
    },
    [onClickHandler]
  );

  return (
    <>
      {preparedOptions.map((option) => {
        switch (option.type) {
          case "item": {
            return (
              <MenuItem
                onClick={(e) => onClickOption(e, option.value)}
                key={uuidv4()}
              >
                <Typography>{option.label}</Typography>
              </MenuItem>
            );
          }

          case "separator": {
            return (
              <Divider key={uuidv4()} sx={{ display: "block!important" }} />
            );
          }

          default:
            return <Fragment key={uuidv4()}></Fragment>;
        }
      })}
    </>
  );
};
