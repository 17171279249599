import { useEffect, useMemo, useState } from "react";
import InputNumber from "../../../../InputNumber/InputNumber";
import { OuterState } from "../EditorPreview/EditorPreview";

type OptionNumberOperatorPercentageProps = {
  item: any;
  setOuterState: (val: OuterState) => void;
  initialValue?: any;
  constraints: any;
  getInputValidity: Function;
  inputConstraints: any;
};

const getKey = (item): string => {
  switch (item["subject"]) {
    case "lessThanPercentage":
      return "lt";
    case "greaterThanPercentage":
      return "gt";
  }
  return "";
};

const getInitialValue = (input, item): number => {
  let key: string = getKey(item);
  const valueToReturn = input["value"]?.[0]?.[key]??0;
  return valueToReturn;
};

export function OptionNumberOperatorPercentage({
  item,
  setOuterState,
  initialValue,
  constraints,
  getInputValidity,
  inputConstraints
}: OptionNumberOperatorPercentageProps) {
  const [value, setValue] = useState<number | null>(0);
  const validation = useMemo(() => constraints, [constraints]);

  const initVal = useMemo(() => {
    //!TODO: capire perché quando cambio da gt a lt il valore iniziale rimane sempre lo stesso e non va a 0
    let n = getInitialValue(initialValue, item);
    return n;
  }, [initialValue, item]);

  const operatorSign = useMemo(() => {
    switch (item["subject"]) {
      case "greaterThanPercentage":
        return (
          <p style={{ fontSize: "1rem" }}>
            <strong>{">"}</strong>
          </p>
        );
      case "lessThanPercentage":
        return (
          <p style={{ fontSize: "1rem" }}>
            <strong>{"<"}</strong>
          </p>
        );
    }
  }, [item]);

  useEffect(() => {
    const key: any = getKey(item);
    let updatedState = item;
    updatedState["augmentation"]["value"] = [{ [key]: value }];
    if (initVal !== updatedState["augmentation"]["value"][0][key]) {
      setOuterState(updatedState);
    }
  }, [initVal, item, setOuterState, value]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {operatorSign}
      <InputNumber
        isPercentage={true}
        isFloating={true}
        initVal={initVal}
        setOuterState={setValue}
        constraints={validation}
        getIsValid={(e) => getInputValidity(e)}
        decimals={inputConstraints?.decimals ?? undefined}/>
      
      {/* <InputNumberPercentage initVal={initVal} setOuterState={setValue} constraints={validation} /> */}
    </div>
  );
}
