import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import PeerControlsRow from "./widgets/PeerControlsRow";
import { PeerWhereConstraints } from "./widgets/PeerWhereConstraints";

type PeerConstraintsBarProps = {
  getConstraints: (value) => void;
  onSearch: (value) => void;
  optionsConstraints: (type) => any[];
  optionsControls: (type) => any[];
};

type Constraints = {
  analytic: string;
  thirdDimension: string | null;
  timeframe: string;
  dispersionTimeframe: "pq" | "ps" | "py";
  visualization: string;
  whereSortBy: string;
  whereType: string;
  instrumentType: "stock" | "ETF";
};

export type ActionsPeerConstraints =
  | { type: "SET_ANALYTIC"; payload: string }
  | { type: "SET_THIRD_DIM"; payload: string | null }
  | { type: "SET_TIMEFRAME"; payload: string }
  | { type: "SET_DISPERSION_TIMEFRAME"; payload: "pq" | "ps" | "py" }
  | { type: "SET_VISUALIZATION"; payload: string }
  | { type: "SET_WHERE_SORT"; payload: string }
  | { type: "SET_WHERE_TYPE"; payload: string }
  | { type: "SET_INSTRUMENT_TYPE"; payload: "stock" | "ETF" };

const reducer = (draft: Constraints, action: ActionsPeerConstraints) => {
  switch (action.type) {
    case "SET_ANALYTIC":
      draft.analytic = action.payload;

      break;

    case "SET_THIRD_DIM":
      draft.thirdDimension = action.payload;

      break;

    case "SET_TIMEFRAME":
      draft.timeframe = action.payload;

      break;

    case "SET_DISPERSION_TIMEFRAME":
      draft.dispersionTimeframe = action.payload;

      break;

    case "SET_VISUALIZATION":
      draft.visualization = action.payload;

      break;

    case "SET_WHERE_SORT":
      draft.whereSortBy = action.payload;

      break;

    case "SET_WHERE_TYPE":
      draft.whereType = action.payload;

      break;
  }
};

const PAGE_KEY = "analysis_market";

const TIMEFRAME_DICT = {
  1: "today",
  5: "lastWeek",
  20: "lastMonth",
};

export const PeerConstraintsBar = forwardRef<
  HTMLFormElement,
  PeerConstraintsBarProps
>(({ getConstraints, onSearch, optionsConstraints, optionsControls }, ref) => {
  const environment = useEnvironment();
  const configuration = useMemo(
    () => environment.get("account")?.product?.configuration[PAGE_KEY],
    [environment]
  );

  const getDefaults = useCallback(() => {
    const selected = optionsControls("analytic")?.[0]?.value;

    switch (selected) {
      default:
      case "tcr": {
        return {
          analytic: "tcr",
          thirdDimension: null,
          instrumentType: "stock",
          visualization: "change",
          dispersionTimeframe: "pq",
          timeframe:
            TIMEFRAME_DICT?.[configuration?.timeFrameDefault ?? 1] ?? "today",
          whereSortBy: "marketcap|desc",
          whereType: "Country",
        };
      }

      case "topBottom": {
        return {
          analytic: "topBottom",
          thirdDimension: null,
          instrumentType: "stock",
          dispersionTimeframe: "pq",
          visualization: "top_bottom",
          timeframe:
            TIMEFRAME_DICT?.[configuration?.timeFrameDefault ?? 1] ?? "today",
          whereSortBy: "marketcap|desc",
          whereType: "Country",
        };
      }

      case "abPercentage": {
        return {
          analytic: "abPercentage",
          thirdDimension: null,
          dispersionTimeframe: "pq",
          instrumentType: "stock",
          visualization: "change",
          timeframe:
            TIMEFRAME_DICT?.[configuration?.timeFrameDefault ?? 1] ?? "today",
          whereSortBy: "marketcap|desc",
          whereType: "Country",
        };
      }

      case "upgradesDowngrades": {
        return {
          analytic: "upgradesDowngrades",
          thirdDimension: null,
          dispersionTimeframe: "pq",
          instrumentType: "stock",
          visualization: "number",
          timeframe:
            TIMEFRAME_DICT?.[configuration?.timeFrameDefault ?? 1] ?? "today",
          whereSortBy: "marketcap|desc",
          whereType: "Country",
        };
      }
    }
  }, [configuration?.timeFrameDefault, optionsControls]);

  // Instrument type cannot be changed we dedided to handle ETFs in a different way than Stocks
  const initState: any = useMemo(() => getDefaults(), [getDefaults]);

  const [constraints, dispatch] = useImmerReducer(reducer, initState);

  useEffect(() => getConstraints(constraints), [constraints, getConstraints]);

  return (
    <form
      action="#"
      className="tPageAnalysisMarket-constraints"
      method="get"
      ref={ref}
    >
      <PeerControlsRow
        onSearch={onSearch}
        dispatch={dispatch}
        options={optionsControls}
        defaultState={getDefaults()}
        assetClass={constraints.instrumentType}
      />
      <PeerWhereConstraints
        dispatch={dispatch}
        options={optionsConstraints}
        assetClass={constraints.instrumentType ?? "stock"}
        where={constraints.whereType}
        sortBy={constraints.whereSortBy}
      />
    </form>
  );
});
