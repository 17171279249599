import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PanelForLists } from "../PanelForLists/PanelForLists";
import { v4 as uuidv4 } from "uuid";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Lists } from "../../api/compute/Lists";

type ListSelectProps = {
  showShared?: boolean;
  showBasket?: boolean;
  showPortfolios?: boolean;
  selected?: number[] | string[];
  label: string;
  title: string;
  disabled?: boolean;
  onApplyClick: (ids: number[]) => any;
};

const TAGS_MAP = {
  PORTFOLIO: "Portfolios",
  BASKET: "Baskets",
};

export function ListSelect({
  showBasket = true,
  showPortfolios = true,
  showShared = true,
  selected,
  disabled = false,
  title,
  label,
  onApplyClick,
}: ListSelectProps) {
  const [loading, setLoading] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState<
    { name: string; id: number; type: string; isSubscribed: boolean }[]
  >([]);
  const [listSelected, setListSelected] = useState<number[]>(
    selected?.map((item) => parseInt(item)) ?? []
  );
  const [listofNames, setListOfNames] = useState(["Select a list"]);
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const apiLists = useMemo(() => new Lists(setup), [setup]);

  const getUserCollections = useCallback(async () => {
    try {
      const collections = await window.App.user.data.collections.get();

      if (collections) {
        return collections;
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }, []);

  // ATTENTION may cause infinite loop, this useEffect should be deleted once the widget is not
  // used yet by dojo
  const panelRef = useRef<{ set: (ids: number[]) => void }>();
  useEffect(() => {
    if (selected && selected.length) {
      const ids = selected?.map((item) => parseInt(item));

      setListSelected(ids);
      if (panelRef.current) {
        panelRef.current.set(ids);
      }
    }
  }, [selected]);

  // --------------------------------------------------------------------------------------------

  const filterAndMapCollections = useCallback(
    (collections) => {
      const filtered: {
        name: string;
        id: number;
        type: string;
        isSubscribed: boolean;
      }[] = [];

      for (const collection of collections) {
        switch (collection.type) {
          case "BASKET": {
            if (showBasket === true) {
              if (collection.isReadOnly === true) {
                if (showShared === true) {
                  filtered.push({
                    name: collection.name,
                    id: collection.id,
                    type: TAGS_MAP[collection.type],
                    isSubscribed: collection.isReadOnly,
                  });
                }
              } else {
                filtered.push({
                  name: collection.name,
                  id: collection.id,
                  type: TAGS_MAP[collection.type],
                  isSubscribed: collection.isReadOnly,
                });
              }
            }

            break;
          }
          case "PORTFOLIO": {
            if (showPortfolios === true) {
              if (collection.isReadOnly === true) {
                if (showShared === true) {
                  filtered.push({
                    name: collection.name,
                    id: collection.id,
                    type: TAGS_MAP[collection.type],
                    isSubscribed: collection.isReadOnly,
                  });
                }
              } else {
                filtered.push({
                  name: collection.name,
                  id: collection.id,
                  type: TAGS_MAP[collection.type],
                  isSubscribed: collection.isReadOnly,
                });
              }
            }

            break;
          }
        }
      }

      return filtered;
    },
    [showBasket, showPortfolios, showShared]
  );

  const openDialogAndGetLists = useCallback(async () => {
    setLoading(true);

    try {
      setError(false);
      const collections = await getUserCollections();
      const filteredCollections = filterAndMapCollections(collections);
      setItems(filteredCollections);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
    setShowPanel(true);
  }, [filterAndMapCollections, getUserCollections]);

  const onApply = useCallback(
    (ids: number[]) => {
      setListSelected(ids);
      onApplyClick(ids);
      setShowPanel(false);
    },
    [onApplyClick]
  );

  const tags = useMemo(() => {
    const tags: string[] = [];

    if (showBasket === true) {
      tags.push("Baskets");
    }

    if (showPortfolios === true) {
      tags.push("Portfolios");
    }

    return tags;
  }, [showBasket, showPortfolios]);

  const getNames = useCallback(async () => {
    let names: string[] = [];

    if (!listSelected || listSelected?.length === 0) {
      setListOfNames(["Select a list"]);

      return;
    }

    if (items && items.length) {
      const selectedListMap = [...items].reduce(
        (prev, current) => ({ ...prev, [current.id]: current }),
        {}
      );
      for (const id of listSelected) {
        if (selectedListMap[id]) {
          names.push(selectedListMap[id]?.name ?? "");
        }
      }

      if (names.length <= 3) {
        setListOfNames(names);
      } else {
        const originalLength = names.length;
        names = names.slice(0, 3);
        names.push(`+ ${originalLength - 3}`);

        setListOfNames(names);
      }

      return;
    } else {
      const ids =
        selected?.map((item) =>
          typeof item === "string" ? parseInt(item) : item
        ) ?? [];
      if (ids && ids.length) {
        try {
          const response = await apiLists.portfolioFetch(ids, ["name"]);
          if (response && response.length) {
            let names: string[] = [];

            for (const info of response) {
              names.push(info?.name ?? "");
            }

            if (names.length <= 3) {
              setListOfNames(names);
            } else {
              const originalLength = names.length;
              names = names.slice(0, 3);
              names.push(`+ ${originalLength - 3}`);

              setListOfNames(names);
            }

            return;
          }
        } catch (error) {
          console.log(error);
        }
      }

      setListOfNames(["Select a list"]);
    }
  }, [apiLists, items, listSelected, selected]);

  useEffect(() => {
    getNames();
  }, [getNames]);

  return (
    <Card
      sx={{
        pointerEvents: disabled === true ? "none" : "auto",
        opacity: disabled === true ? 0.3 : 1,
        display: "flex",
        position: "relative",
        border: "1px solid",
        borderColor: "transparent",
        transition: "0.5s",
        cursor: "pointer",
        "&:hover": {
          borderColor: "#2a7090",
        },
      }}
      title={label}
    >
      <PanelForLists
        ref={panelRef}
        isLoadingData={loading}
        list={items}
        showDialog={showPanel}
        closeDialog={() => setShowPanel(false)}
        initialValue={listSelected}
        sectionsTag={tags}
        itemsPerColumn={20}
        selectItem={onApply}
        headerTitle={title}
        multipleChoice
      />
      <CardContent
        sx={{
          padding: "2px 6px!important",
          display: "flex",
          flex: 1,
        }}
      >
        <Box
          onClick={openDialogAndGetLists}
          display={"flex"}
          flexDirection={"column"}
          textAlign={"center"}
          flex={1}
        >
          <Typography>
            <strong>{label}</strong>
          </Typography>
          {error ? (
            <Typography sx={{ color: "red!important" }}>
              Cannot retrive lists
            </Typography>
          ) : (
            <Box
              flex={1}
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              {loading ? (
                <Typography>...loading</Typography>
              ) : (
                listofNames.map((name) => (
                  <Typography key={uuidv4()}>{name}</Typography>
                ))
              )}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
