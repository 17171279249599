import { Box, ListItemText, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import { deepClone } from "../../deepClone";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../hooks/useTaxonomyByType";
import Modal from "../Modal/Modal";
import styles from "./TaxonomySelect.module.scss";
import { PeerSize } from "../PeerSize/PeerSize";
import { FormOptions } from "../../trendrating/app/formatter/FormOptions";

type BranchProps = {
  branch: any;
  isRoot?: Boolean;
  checkedValues: string[];
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => void;
  hasMultipleChoices?: boolean;
  selectValue?: Function;
  selectedValue: any;
};

type OutterStateObject = {
  type: "filters";
  value: {
    dimension: TaxonomySelectProps["taxonomyInfo"]["field"];
    segments: string[];
  };
}[];

type TaxonomySelectProps = {
  taxonomyInfo: { type: string; field: string };
  panelTitle: string;
  onSetFilters?: (value: OutterStateObject) => void;
  defaultValue?: string[];
  children?: ReactNode;
  hasMultipleChoices?: boolean;
  selectValue?: Function;
  validTreeNodes?: string[];
  columnsDefinition?: { taxonomyId: string }[][];
  hasFilters?: boolean;
  initFiltersState?: {
    domestic: boolean;
    foreign: boolean;
    type: string[];
  };
  hideStockClassFilter?: boolean;
  extraTools?: {
    size: {
      enabled: boolean;
      initState: string;
      handleValueChange: (value) => void;
    };
  };
};

type NodeProps = {
  nodes: any[];
  checkedValues: string[];
  onChangeHandler: BranchProps["onChangeHandler"];
  hasMultipleChoice?: boolean;
  selectValue?: Function;
  selectedValue: any;
};

type FakeSelectProps = {
  showTree: (val: boolean) => void;
  filters: string[];
  field: TaxonomySelectProps["taxonomyInfo"]["field"];
  taxonomyMap: any;
};

type InvRegionTreeProps = {
  treeChildren: any;
  taxonList: [];
  setFilters: any;
  currentFilters: string[];
  hasMultipleChoice?: boolean;
  selectSingleValue: Function;
  singleValueSelected: any;
};

type TreeContentDefinedByConfigProps = {
  configuration: {
    taxonomyId: string;
    children?: TreeContentDefinedByConfigProps["configuration"];
  }[][];
  taxonList: any;
  field: string;
  validTreeNodes?: string[];
  onSubmit: Function;
  initFilters: string[];
  taxonType: string;
  hasMultipleChoice?: boolean;
  selectSingleValue?: (id: string) => any;
};

type CheckBoxSectionProps = {
  parentNode: any;
  onChangeHandler: Function;
  checkBoxMap: any;
  listOfChildren: any;
  childrenToExpand?: any;
  multiColNumber?: number;
  hasMultipleChoice?: boolean;
  selectSingle?: (id: string) => any;
};

const sumArrays = (arr1, arr2) => [...new Set([...arr1].concat(arr2))];

const subtractArrays = (arr1, arr2) =>
  arr1.reduce((prev, current) => {
    if (arr2.some((value) => value === current)) {
      return prev;
    } else {
      prev.push(current);
      return prev;
    }
  }, []);

const arrayToTree = (items, id = null, link = "parent") =>
  items
    .filter((item) => item[link] === id)
    .map((item) => {
      return { ...item, children: arrayToTree(items, item.id) };
    });

const levelsValidListBased = (list, validNodesList, taxonomies) => {
  const sortedTaxonomyByLevelAsc = list.sort((a, b) => {
    if (a.type > b.type) {
      return 1;
    } else if (a.type < b.type) {
      return -1;
    }

    return 0;
  });

  const filteredListWithRightParents: any = [];
  const rootNode = sortedTaxonomyByLevelAsc[0];
  const validValues = [...validNodesList, rootNode.type];

  for (const node of sortedTaxonomyByLevelAsc) {
    if (node.id === rootNode.id) {
      filteredListWithRightParents.push(node);

      continue;
    }

    const isNodeLevelRight = validValues.indexOf(node.type) !== -1;

    if (isNodeLevelRight && node.parent != null) {
      const parent = taxonomies[node.parent];
      const isParentLevelRight = validValues.indexOf(parent.type) !== -1;

      if (isParentLevelRight) {
        filteredListWithRightParents.push(node);
      } else {
        const newParent = taxonomies?.[parent?.parent] ?? null;
        node["parent"] = newParent?.id ?? null;

        filteredListWithRightParents.push(node);
      }
    }
  }

  return filteredListWithRightParents;
};

const prepareData = (taxonomies, field, validNodesList) => {
  /**
   * Eventually add a tag isLeaf = false for intermediate nodes
   */
  // let id = "";
  // let parent: any = null;

  // for (id in taxonomies) {
  //     parent = taxonomies[id]["parent"];

  //     if (parent) {
  //         taxonomies[parent].isLeaf = false;
  //     } else {
  //         // To handle the root node
  //         taxonomies[id].isLeaf = false;
  //     }
  // }

  let taxonomyList: any[] = Object.values(taxonomies);

  let list = taxonomyList.filter((taxonomy) => taxonomy.showInTree === true);

  if (validNodesList.length) {
    const sortedTaxonomyByLevelAsc = list.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      } else if (a.type < b.type) {
        return -1;
      }

      return 0;
    });

    const filteredListWithRightParents: any = [];
    const rootNode = sortedTaxonomyByLevelAsc[0];
    const validValues = [...validNodesList, rootNode.type];

    for (const node of sortedTaxonomyByLevelAsc) {
      if (node.id === rootNode.id) {
        filteredListWithRightParents.push(node);

        continue;
      }

      const isNodeLevelRight = validValues.indexOf(node.type) !== -1;

      if (isNodeLevelRight && node.parent != null) {
        const parent = taxonomies[node.parent];
        const isParentLevelRight = validValues.indexOf(parent.type) !== -1;

        if (isParentLevelRight) {
          filteredListWithRightParents.push(node);
        } else {
          const newParent = taxonomies?.[parent?.parent] ?? null;
          node["parent"] = newParent?.id ?? null;

          filteredListWithRightParents.push(node);
        }
      }
    }

    list = filteredListWithRightParents;
  }

  list.sort((a, b) => {
    // if (a.rank > b.rank) {
    //     return 1;
    // } else if (a.rank < b.rank) {
    //     return -1;
    // }

    if (a.name > b.name) {
      return 1;
    }
    if (b.name > a.name) {
      return -1;
    }

    return 0;
  });

  const tree = arrayToTree(list);

  return tree;
};

export const TaxonomySelect = forwardRef(
  (
    {
      taxonomyInfo,
      panelTitle,
      onSetFilters,
      defaultValue = [],
      children,
      hasMultipleChoices = true,
      selectValue,
      validTreeNodes = [],
      columnsDefinition,
      hasFilters = false,
      initFiltersState,
      hideStockClassFilter = false,
      extraTools,
    }: TaxonomySelectProps,
    ref
  ) => {
    // Using ref to avoid maximum update exceed
    // we don't use useState because we not need the mehod setValidLevels
    const validLevels = useRef(validTreeNodes);
    // Future enhancement add a taxonomy level dinamically by setting the state
    // const [validLevels, setValidLevels] = useState(validTreeNodes);
    // const [taxonomiesLevelsRange, setTaxonomiesLevelsRange] = useState([]);
    const [showTree, setShowTree] = useState(false);
    const [treeData, setTreeData] = useState(false);
    const environment = useEnvironment();
    const taxonomies = useMemo(
      () => environment.get("setup")["taxonomies"],
      [environment]
    );
    const fieldsMap = useMemo(
      () => environment.get("setup")["taxonomyFields"],
      [environment]
    );
    const { type, field } = useMemo(() => taxonomyInfo, [taxonomyInfo]);
    const taxonomy = useMemo(
      () => deepClone(taxonomies[fieldsMap[type][field]]),
      [field, fieldsMap, taxonomies, type]
    );

    const [filters, setFilters] = useState<OutterStateObject[0]>({
      type: "filters",
      value: {
        dimension: field,
        segments: defaultValue,
      },
    });

    const sizeOpts = useMemo(
      () => new FormOptions(environment.get("formatter")).getField("SIZE"),
      [environment]
    );

    /**
     * Future enhancement precalculate the range of taxonomy levels to
     * navigate the tree by adding levels
     */
    // useEffect(() => {
    //     if (validLevels.length) {
    //         const arrayOfLevelsFromTaxonomies = Object.values<any>(
    //             taxonomy
    //         ).map((taxonomyNode) => taxonomyNode.type);
    //         const levelsByTaxonomy = [
    //             ...new Set(arrayOfLevelsFromTaxonomies),
    //         ].sort();

    //     }
    // }, [taxonomy, validLevels.length]);

    const filterSetter = useCallback(
      (value) => {
        if (JSON.stringify(filters.value.segments) !== JSON.stringify(value)) {
          setFilters({
            ...filters,
            value: {
              ...filters.value,
              segments: value,
            },
          });
        }
      },
      [filters]
    );

    useEffect(() => {
      const tree = prepareData(taxonomy, field, validLevels.current);
      setTreeData(tree);
    }, [taxonomy, field, validLevels]);

    // This panel can be opend from any component that is given as a child but as
    // default behaviour the panel open process is binded to the Fake select component.
    // So to pass the method to the child component provided as a props (optional*) we need to expose
    // the setShowTree function in the ref object. In this way the child component can acced to this function
    // from outside.
    //
    // ? The other way to do this is by using the React.cloneElement function, with this method
    // ? you can pass props to the child object
    useImperativeHandle(ref, () => ({
      showTreePanel: (value: boolean) => setShowTree(value),
    }));

    const handleLabelClick = useCallback(
      (value) => {
        if (selectValue) {
          selectValue(value);
        }

        setShowTree(false);
      },
      [selectValue]
    );

    //Handle single value selechection when the panel is not in multiple choice format
    const [singleValueSelected, setSingleValueSelected] = useState(
      defaultValue ?? null
    );

    const stockClassificationTaxonomy = useMemo(
      () =>
        taxonomies[fieldsMap["Stock"]["stockclass"]].sort((a, b) => {
          if (a.name === "Any") {
            return -1;
          }

          if (b.name === "Any") {
            return 1;
          }

          return a.name > b.name ? 1 : -1;
        }),

      [fieldsMap, taxonomies]
    );
    const stockClassificationRootNode = stockClassificationTaxonomy.find(
      (item) => item.parent == null
    );
    const [isDomestic, setIsDomestic] = useState(
      initFiltersState?.domestic ?? false
    );
    const [isForeign, setIsForeign] = useState(
      initFiltersState?.foreign ?? false
    );
    const [stockClassType, setStockClassType] = useState<any>(
      initFiltersState && initFiltersState?.type?.length
        ? initFiltersState?.type
        : [stockClassificationRootNode.id]
    );

    // This state is needed to control the behaviour of the select cause material UI to render
    // the dropdown with the list items uses a react portal but the Modal component use react Portal too.
    // This cause a wrong behaviuour because on click the select component the ClickAwayListener of the
    // modal is called and cause the closure of the panel.
    // By using the state we stop the clickAwayListener and we reactivate it when the select loose the focus.
    const [closeOnClick, setCloseOnClick] = useState<boolean>(true);

    const onSubmit = useCallback(() => {
      if (onSetFilters) {
        let payload = filters.value.segments.length ? [filters] : [];

        if (hasFilters) {
          if (isDomestic && !isForeign) {
            payload.push({
              type: "filters",
              value: {
                dimension: "subtype",
                segments: ["Domestic Stock"],
              },
            });
          }

          if (!isDomestic && isForeign) {
            payload.push({
              type: "filters",
              value: {
                dimension: "subtype",
                segments: ["Foreign Stock"],
              },
            });
          }

          // Remove the root node (server fail if the segment selected is the root node) and
          // check if is empty
          const stockClassFilters = stockClassType.length
            ? stockClassType.filter(
                (item) => item !== stockClassificationRootNode.id
              )
            : [];
          if (stockClassFilters != null && stockClassFilters.length !== 0) {
            payload.push({
              type: "filters",
              value: {
                dimension: "stockclass",
                segments: stockClassFilters,
              },
            });
          }
        }

        onSetFilters(payload);
        setShowTree(false);
      }
    }, [
      filters,
      hasFilters,
      isDomestic,
      isForeign,
      onSetFilters,
      stockClassType,
      stockClassificationRootNode.id,
    ]);

    const onClickLabel = useCallback(
      (value) => {
        setSingleValueSelected(value);
        handleLabelClick(value);
      },
      [handleLabelClick]
    );

    const handleStockClassTypeChange = useCallback(
      (eventTarget, child) => {
        const clickedItem = child["props"]["data-id"];
        const values = eventTarget.value;

        if (clickedItem === stockClassificationRootNode.id) {
          if (values.includes(stockClassificationRootNode.id)) {
            setStockClassType(
              stockClassificationTaxonomy.map((item) => item.id)
            );
          } else {
            setStockClassType([]);
          }
        } else {
          const valuesActivationMap = stockClassificationTaxonomy.reduce(
            (prev, current) => ({
              ...prev,
              [current.id]: values.includes(current.id),
            }),
            {}
          );

          const children = stockClassificationTaxonomy.filter(
            (taxonomy) => taxonomy.parent === stockClassificationRootNode.id
          );
          let areAllChildrenSelected = true;
          for (const child of children) {
            if (valuesActivationMap[child.id] === false) {
              areAllChildrenSelected = false;
            }
          }

          valuesActivationMap[stockClassificationRootNode.id] =
            areAllChildrenSelected;

          const valuesToSet: string[] = [];

          for (const [key, value] of Object.entries<any>(valuesActivationMap)) {
            if (value === true) {
              valuesToSet.push(key);
            }
          }

          setStockClassType(valuesToSet);
        }
      },
      [stockClassificationRootNode.id, stockClassificationTaxonomy]
    );

    return (
      <>
        {showTree && (
          <Modal
            customCss={{
              maxWidth: "98%",
              maxHeight: "95vh",
              width: "auto",
            }}
            headerConfig={{
              headerContent: panelTitle,
              hasBackground: true,
            }}
            onClose={() => setShowTree(false)}
            buttonsEnalbed={true}
            buttons={[
              {
                name: "Apply",
                callback: hasMultipleChoices
                  ? onSubmit
                  : () => setShowTree(false),
                class: ["tFormButton tFormButton--primary"],
              },
              {
                name: "Cancel",
                callback: () => setShowTree(false),
                class: ["tFormButton tFormButton"],
                variant: "cancel",
              },
            ]}
            closeOnClickAway={closeOnClick}
            closeIcon={false}
          >
            {extraTools?.size.enabled === true ? (
              <Box display={"flex"} alignItems={"center"} gap={1} py={1}>
                <Typography sx={{ fontSize: "1vw" }}>Size:</Typography>
                <PeerSize
                  options={(sizeOpts as any) ?? []}
                  isNullWhenAllAreSelected={false}
                  onClickItem={extraTools.size.handleValueChange}
                  defaultValue={extraTools.size.initState}
                />
              </Box>
            ) : (
              <></>
            )}
            {columnsDefinition ? (
              <TreeContentDefinedByConfig
                hasMultipleChoice={hasMultipleChoices}
                taxonType={taxonomyInfo.type}
                field={field}
                taxonList={taxonomy}
                configuration={columnsDefinition}
                validTreeNodes={validTreeNodes}
                onSubmit={filterSetter}
                selectSingleValue={onClickLabel}
                initFilters={defaultValue}
              />
            ) : (
              <InvRegionTree
                currentFilters={filters.value.segments}
                setFilters={filterSetter}
                taxonList={taxonomy}
                treeChildren={treeData}
                hasMultipleChoice={hasMultipleChoices}
                selectSingleValue={onClickLabel}
                singleValueSelected={singleValueSelected}
              />
            )}
            {hasFilters && (
              <Box display={"flex"}>
                <Box display={"flex"} flex={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setIsDomestic(e.target.checked)}
                        size="small"
                        sx={{
                          padding: "0 10px",
                          color: blue[800],
                        }}
                        checked={isDomestic}
                      />
                    }
                    label={
                      <Typography fontWeight={"bold"}>Domestic</Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setIsForeign(e.target.checked)}
                        size="small"
                        sx={{
                          padding: "0 10px",
                          color: blue[800],
                        }}
                        checked={isForeign}
                      />
                    }
                    label={<Typography fontWeight={"bold"}>Foreign</Typography>}
                  />
                </Box>

                {hideStockClassFilter !== true && (
                  <Box display={"flex"} flex={1}>
                    <Select
                      onFocus={() => setCloseOnClick(false)}
                      onBlur={() => setCloseOnClick(true)}
                      onChange={(e, child) =>
                        handleStockClassTypeChange(e.target, child)
                      }
                      multiple
                      labelId="stockclass"
                      id="stockclass"
                      renderValue={(selected) =>
                        selected
                          .map(
                            (item) =>
                              stockClassificationTaxonomy.find(
                                (el) => el.id === item
                              ).name
                          )
                          .join(", ")
                      }
                      sx={{ fontSize: "0.8vw!important" }}
                      value={stockClassType}
                      size={"small"}
                    >
                      {stockClassificationTaxonomy.map((item) => (
                        <MenuItem
                          id={"avoid__click__away__modal__listener"}
                          data-id={item.id}
                          sx={{
                            fontSize: "0.8vw!important",
                          }}
                          key={uuidv4()}
                          value={item.id}
                        >
                          <Checkbox
                            name={item.id}
                            size="small"
                            checked={stockClassType.includes(item.id)}
                          />
                          <ListItemText
                            sx={{
                              fontSize: "0.8vw!important",
                            }}
                            primary={item.name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            )}
            {/* Future enhancement */}
            {/* {validLevels.current.length && (
                            <p className={styles.more__details__clickable}>
                                More Details
                            </p>
                        )} */}
          </Modal>
        )}
        {children ? (
          children
        ) : (
          <FakeSelect
            filters={filters.value.segments}
            showTree={setShowTree}
            field={taxonomyInfo.field}
            taxonomyMap={
              taxonomies[fieldsMap[taxonomyInfo.type][taxonomyInfo.field]]
            }
          />
        )}
      </>
    );
  }
);

const FakeSelect = ({
  showTree,
  filters,
  field,
  taxonomyMap,
}: FakeSelectProps) => {
  // This is a copy and paste of a dojo HTML input select used while
  // we don't migrate all widgets to new style

  const label = useMemo(() => {
    switch (field) {
      case "country":
        return "countries";

      case "etfclass":
        return "asset classes";

      case "etfgeo":
        return "regions";

      case "ETFProviders":
        return "issuers";

      case "domicile":
        return "domiciles";

      case "sector":
      case "icb":
        return "sectors";

      default:
        return "values";
    }
  }, [field]);

  return (
    <>
      <table className="dijit dijitReset dijitInline dijitLeft dijitSelect dijitValidationTextBox dijitDownArrowButton">
        <tbody onClick={() => showTree(true)} role="presentation">
          <tr role="presentation">
            <td
              className="dijitReset dijitStretch dijitButtonContents"
              role="presentation"
            >
              <div
                className="dijitReset dijitInputField dijitButtonText"
                data-dojo-attach-point="containerNode,textDirNode"
                role="presentation"
                title="United States"
              >
                {filters.length
                  ? filters.length > 1
                    ? `some ${label}`
                    : taxonomyMap?.[filters[0]]?.name ?? "Any"
                  : "Any"}
              </div>
              <div className="dijitReset dijitValidationContainer">
                <input
                  className="dijitReset dijitInputField dijitValidationIcon dijitValidationInner"
                  value="Χ "
                  type="text"
                  tabIndex={-1}
                  readOnly
                  role="presentation"
                />
              </div>
              <input
                type="hidden"
                data-dojo-attach-point="valueNode"
                value="None"
                aria-hidden="true"
              />
            </td>
            <td
              className="dijitReset dijitRight dijitButtonNode dijitArrowButton dijitDownArrowButton dijitArrowButtonContainer"
              data-dojo-attach-point="titleNode"
              role="presentation"
            >
              <input
                className="dijitReset dijitInputField dijitArrowButtonInner"
                value="▼ "
                type="text"
                tabIndex={-1}
                readOnly={true}
                role="presentation"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const InvRegionTree = ({
  treeChildren,
  taxonList,
  setFilters,
  currentFilters,
  hasMultipleChoice = true,
  selectSingleValue,
  singleValueSelected,
}: InvRegionTreeProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(currentFilters);

  useEffect(() => {
    setFilters(checkedValues);
  }, [checkedValues, setFilters]);

  const onChangeCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean, node: any) => {
      const id = node.id;
      const dynamicValues: any = [id];

      const isLeaf = !node.children.length;

      const treeCrawlerFromNode = (rootNode, operation, id = null) =>
        rootNode.children
          .filter((child) => child["parent"] === id)
          .map((subNode) => {
            operation(subNode);
            return {
              ...subNode,
              children: treeCrawlerFromNode(subNode, operation, subNode.id),
            };
          });

      if (!isLeaf) {
        const operation = (subNode) => dynamicValues.push(subNode.id);
        treeCrawlerFromNode(node, operation, node.id);
      }

      if (!checked && node.parent != null) {
        // remove checked from leaf and his parent
        dynamicValues.push(node.parent);

        let parentChain: any = [];
        let parent = node.parent;
        let currentNode: any = null;

        do {
          const parentId = parent;
          currentNode = Object.values(taxonList).filter(
            (tx: any) => tx.id === parentId
          );

          parent = currentNode[0].parent;

          parentChain.push(...currentNode);
        } while (parent !== null);

        dynamicValues.push(...parentChain.map((item) => item.id));
      }

      setCheckedValues((prev) =>
        checked
          ? sumArrays(prev, dynamicValues)
          : subtractArrays(prev, dynamicValues)
      );
    },
    [taxonList]
  );

  return (
    <div className={styles["tree-wrapper"]}>
      {treeChildren.map((branch) => (
        <Branch
          onChangeHandler={onChangeCheckbox}
          key={uuidv4()}
          branch={branch}
          isRoot={true}
          checkedValues={checkedValues}
          hasMultipleChoices={hasMultipleChoice}
          selectValue={selectSingleValue}
          selectedValue={singleValueSelected}
        />
      ))}
    </div>
  );
};

const Branch = ({
  branch,
  isRoot = false,
  checkedValues,
  onChangeHandler,
  hasMultipleChoices = true,
  selectValue,
  selectedValue,
}: BranchProps) => {
  const isLastBranch = useMemo(
    () => !branch.children.some((leaf) => leaf.children.length !== 0),
    [branch.children]
  );

  return (
    <fieldset
      style={
        isRoot || (!isLastBranch && branch.children.length > 4)
          ? {
              display: "flex",
              minWidth: 0,
            }
          : { display: "flex" }
      }
      className={"tFormSelectSectorContent-items"}
    >
      <legend
        className={`tFormSelectSectorContent-title ${styles["tab-style"]}`}
      >
        {hasMultipleChoices ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e, check) => onChangeHandler(e, check, branch)}
                size="small"
                sx={{ padding: "0 10px", color: blue[800] }}
                checked={checkedValues.some((value) => value === branch.id)}
              />
            }
            label={<Typography fontWeight={"bold"}>{branch.name}</Typography>}
          />
        ) : (
          <Typography
            onClick={() => selectValue && selectValue(branch.id)}
            fontWeight={"bold"}
            className={
              selectedValue === branch.id
                ? styles["single__chioce--selected"]
                : ""
            }
          >
            {branch.name}
          </Typography>
        )}
      </legend>
      {branch.children.length && (
        <Node
          onChangeHandler={onChangeHandler}
          checkedValues={checkedValues}
          nodes={branch.children}
          hasMultipleChoice={hasMultipleChoices}
          selectValue={selectValue}
          selectedValue={selectedValue}
        />
      )}
    </fieldset>
  );
};

const Node = ({
  nodes,
  checkedValues,
  onChangeHandler,
  hasMultipleChoice = true,
  selectValue,
  selectedValue,
}: NodeProps) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const styleObj: CSSProperties = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      minWidth: 0,
    }),
    []
  );

  return (
    <div ref={nodeRef} style={styleObj}>
      {nodes.map((node) => {
        if (node.children.length) {
          return <Fragment key={uuidv4()}></Fragment>;
        } else {
          return hasMultipleChoice ? (
            <FormControlLabel
              key={uuidv4()}
              sx={{ marginRight: "5px" }}
              control={
                <Checkbox
                  onChange={(e, check) => onChangeHandler(e, check, node)}
                  size="small"
                  sx={{ padding: "0 10px", color: blue[800] }}
                  checked={checkedValues.some((value) => value === node.id)}
                />
              }
              label={<Typography whiteSpace={"nowrap"}>{node.name}</Typography>}
            />
          ) : (
            <Typography
              key={uuidv4()}
              whiteSpace={"nowrap"}
              sx={{ marginLeft: "5px" }}
              className={`${styles.selectable__label} ${
                selectedValue === node.id
                  ? styles["single__chioce--selected"]
                  : ""
              }`}
              onClick={() => selectValue && selectValue(node.id)}
            >
              {node.name}
            </Typography>
          );
        }
      })}
      <div className={styles["custom-row-wrapper"]}>
        {nodes.map((node) => {
          if (node.children.length) {
            return (
              <Branch
                onChangeHandler={onChangeHandler}
                checkedValues={checkedValues}
                key={uuidv4()}
                branch={node}
                selectedValue={selectedValue}
                hasMultipleChoices={hasMultipleChoice}
                selectValue={selectValue}
              />
            );
          } else {
            return <Fragment key={uuidv4()}></Fragment>;
          }
        })}
      </div>
    </div>
  );
};

const TreeContentDefinedByConfig = ({
  taxonList,
  field,
  validTreeNodes,
  configuration,
  onSubmit,
  initFilters,
  taxonType,
  hasMultipleChoice = true,
  selectSingleValue,
}: TreeContentDefinedByConfigProps) => {
  const [checkboxesMap, updateCheckboxesMap] = useImmer<{
    [key: string]: {
      parent: string;
      id: string;
      active: boolean;
    };
  }>({});
  useEffect(() => {
    const checkedList = Object.values(checkboxesMap)
      .filter((node) => node.active)
      .map((node) => node.id);

    onSubmit(checkedList);
  }, [checkboxesMap, onSubmit]);

  const taxonomyList: any = useMemo(
    () => Object.values(taxonList),
    [taxonList]
  );
  const listFilteredByShowInTree = useMemo(
    () =>
      field === "subtype"
        ? taxonomyList
        : taxonomyList.filter((taxonomy) => taxonomy.showInTree === true),
    [field, taxonomyList]
  );

  const taxonomiesFilteredByValidLevels = useMemo(() => {
    return levelsValidListBased(
      listFilteredByShowInTree,
      validTreeNodes,
      taxonList
    );
  }, [listFilteredByShowInTree, taxonList, validTreeNodes]);

  const rootTaxonomyNode = useMemo(
    () => taxonomiesFilteredByValidLevels.find((node) => node.parent == null),
    [taxonomiesFilteredByValidLevels]
  );

  const rootChildrenNodes = useMemo(() => {
    const list: any = [];

    for (const column of configuration) {
      for (const widget of column) {
        list.push(widget.taxonomyId);
        if (widget.children) {
          widget.children.forEach((item) => {
            item.forEach((col) => {
              list.push(col.taxonomyId);
            });
          });
        }
      }
    }

    return list;
  }, [configuration]);

  const taxonomiesUsedInWidget = useMemo(() => {
    let list: any = [];
    list.push(rootTaxonomyNode);

    for (const node of taxonomiesFilteredByValidLevels) {
      if (rootChildrenNodes.includes(node.id)) {
        list.push(node);

        const children = taxonomiesFilteredByValidLevels.filter(
          (childNode) => childNode.parent === node.id
        );
        list = list.concat(children);
      }
    }

    return list;
  }, [rootChildrenNodes, rootTaxonomyNode, taxonomiesFilteredByValidLevels]);

  const { getChildrenByNode } = useTaxonomyByType(taxonType as any);

  const initialFilters = useRef<string[]>(
    hasMultipleChoice === false
      ? initFilters
      : initFilters.concat(
          getChildrenByNode(
            initFilters,
            taxonType,
            field,
            true,
            validTreeNodes
          ).map((element) => element.id)
        )
  );

  // Prepare the checkbox map
  useEffect(() => {
    if (taxonomiesUsedInWidget) {
      const valuesMap = {};

      for (const taxonomy of taxonomiesUsedInWidget) {
        valuesMap[taxonomy.id] = {
          id: taxonomy.id,
          parent: taxonomy.parent,
          active: initialFilters.current.includes(taxonomy.id),
        };
      }

      updateCheckboxesMap((draft) => valuesMap);
    }
  }, [taxonomiesUsedInWidget, updateCheckboxesMap]);

  const updateDraftOnCheckboxChange = useCallback(
    (
      draft: {
        [key: string]: {
          parent: string;
          id: string;
          active: boolean;
        };
      },
      taxonomyId: string,
      activeCheckbox: boolean
    ) => {
      const isDraftEmpty = Object.keys(draft).length === 0;

      if (isDraftEmpty) {
        return draft;
      }

      const node = draft[taxonomyId];
      node.active = activeCheckbox;

      const setChildNodesTrue = (parentId: any = null) => {
        return Object.values(draft)
          .filter((node) => node.parent === parentId)
          .forEach((child) => {
            child.active = activeCheckbox;
            setChildNodesTrue(child.id);
          });
      };

      setChildNodesTrue(node.id);

      let parent: any = draft?.[node.parent] ?? null;

      while (parent != null) {
        const parentId = parent.id;
        const children = Object.values(draft).filter(
          (element) => element.parent === parentId
        );
        let areAllActive = true;

        for (const child of children) {
          if (!child.active) {
            areAllActive = false;
          }
        }

        parent.active = areAllActive;

        parent = draft[parent.parent];
      }
    },
    []
  );

  const handleChange = useCallback(
    (e, taxonomyId) => {
      const isCheckBoxActive = e.target.checked;
      updateCheckboxesMap((draft) =>
        updateDraftOnCheckboxChange(
          draft,
          taxonomyId as string,
          isCheckBoxActive
        )
      );
    },
    [updateCheckboxesMap, updateDraftOnCheckboxChange]
  );

  const selectSingle = useCallback(
    (value) => {
      if (selectSingleValue) {
        selectSingleValue(value);
      }
    },
    [selectSingleValue]
  );

  return (
    <fieldset
      className={`tFormSelectSectorContent-items ${styles.fildset__by__config}`}
    >
      <legend
        className={`tFormSelectSectorContent-title ${styles["tab-style"]}`}
      >
        {hasMultipleChoice === true ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e, check) => handleChange(e, rootTaxonomyNode.id)}
                size="small"
                sx={{
                  padding: "0 10px",
                  color: blue[800],
                }}
                checked={checkboxesMap?.[rootTaxonomyNode?.id]?.active ?? false}
              />
            }
            label={
              <Typography fontWeight={"bold"}>
                {rootTaxonomyNode.name}
              </Typography>
            }
          />
        ) : (
          <Typography
            className={`${styles.singleSelectionLabel} ${
              checkboxesMap?.[rootTaxonomyNode?.id]?.active === true
                ? styles.isLabelActive
                : ""
            }`}
            fontWeight={"bold"}
            onClick={() => selectSingle(rootTaxonomyNode.id)}
          >
            {rootTaxonomyNode.name}
          </Typography>
        )}
      </legend>
      <div className={styles.content__by__config}>
        {configuration.map((column) => {
          return (
            <div key={uuidv4()} className={styles.content__by__config__column}>
              {column.map((colItem) => {
                const parentNode = taxonomiesFilteredByValidLevels.find(
                  (node) => node.id === colItem.taxonomyId
                );
                return (
                  <Box key={uuidv4()}>
                    <CheckBoxSection
                      checkBoxMap={checkboxesMap}
                      onChangeHandler={handleChange}
                      parentNode={parentNode}
                      listOfChildren={taxonomiesFilteredByValidLevels}
                      childrenToExpand={colItem.children}
                      hasMultipleChoice={hasMultipleChoice}
                      selectSingle={selectSingle}
                    />
                  </Box>
                );
              })}
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

const CheckBoxSection = ({
  parentNode,
  listOfChildren,
  onChangeHandler,
  checkBoxMap,
  childrenToExpand,
  multiColNumber,
  hasMultipleChoice = true,
  selectSingle,
}: CheckBoxSectionProps) => {
  const children = useMemo(() => {
    return listOfChildren.filter((child) => child.parent === parentNode.id);
  }, [listOfChildren, parentNode.id]);

  const branchNodes = useMemo(() => {
    const result: any = [];

    if (childrenToExpand) {
      childrenToExpand.forEach((col) => {
        col.forEach((item) => {
          result.push(item.taxonomyId);
        });
      });
    }

    return result;
  }, [childrenToExpand]);

  const sortedChildren = useMemo(() => {
    children.sort((a, b) => {
      const aId = a.id;
      const bId = b.id;

      if (branchNodes.includes(aId)) {
        return 1;
      }

      if (branchNodes.includes(bId)) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return children;
  }, [branchNodes, children]);

  return (
    <fieldset
      className={`tFormSelectSectorContent-items ${styles.fildset__by__config}`}
    >
      <legend
        className={`tFormSelectSectorContent-title ${styles["tab-style"]}`}
      >
        {hasMultipleChoice === true ? (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => onChangeHandler(e, parentNode.id)}
                size="small"
                sx={{ padding: "0 10px", color: blue[800] }}
                checked={checkBoxMap?.[parentNode.id]?.active ?? false}
              />
            }
            label={
              <Typography fontWeight={"bold"}>{parentNode.name}</Typography>
            }
          />
        ) : (
          <Typography
            className={`${styles.singleSelectionLabel} ${
              checkBoxMap?.[parentNode.id]?.active === true
                ? styles.isLabelActive
                : ""
            }`}
            fontWeight={"bold"}
            onClick={() => (selectSingle ? selectSingle(parentNode.id) : {})}
          >
            {parentNode.name}
          </Typography>
        )}
      </legend>
      <ul
        style={
          multiColNumber ? { columns: sortedChildren.length > 3 ? 2 : 1 } : {}
        }
        // className="tFormSelectSectorContent-items-list"
      >
        {sortedChildren.map((child, index) => {
          if (branchNodes.includes(child.id)) {
            return (
              <CheckBoxSection
                key={uuidv4()}
                parentNode={child}
                listOfChildren={listOfChildren}
                onChangeHandler={onChangeHandler}
                checkBoxMap={checkBoxMap}
                multiColNumber={2}
                hasMultipleChoice={hasMultipleChoice}
                selectSingle={selectSingle}
              />
            );
          }

          return (
            <li
              key={uuidv4()}
              className={styles.list__items__by__config}
              title={child.name}
              style={{ paddingLeft: "8px" }}
            >
              {hasMultipleChoice === true ? (
                <FormControlLabel
                  sx={{ marginRight: "5px", overflow: "hidden" }}
                  control={
                    <Checkbox
                      onChange={(e) => onChangeHandler(e, child.id)}
                      size="small"
                      sx={{
                        padding: "0 10px",
                        color: blue[800],
                      }}
                      checked={checkBoxMap?.[child.id]?.active ?? false}
                    />
                  }
                  label={
                    <Typography
                      minWidth={0}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {child.name}
                    </Typography>
                  }
                />
              ) : (
                <Typography
                  minWidth={0}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  className={`${styles.singleSelectionLabel} ${
                    checkBoxMap?.[child.id]?.active === true
                      ? styles.isLabelActive
                      : ""
                  }`}
                  onClick={() => (selectSingle ? selectSingle(child.id) : {})}
                >
                  {child.name}
                </Typography>
              )}
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};
