import { useEffect, useRef, useState } from "react";
import { useResizer } from "../../../../../../hooks/useResizer";
import { Instrument } from "./objects/Instrument";
import Peer from "./objects/Peer";

type PinnedListProps = {
  data: any;
  timeframe: string;
};

export function PinnedList({ data, timeframe }: PinnedListProps) {
  const [isButtonRightActive, setIsButtonRightActive] = useState(false);
  const [isButtonLeftActive, setIsButtonLeftActive] = useState(false);
  const [trigger, setTrigger] = useState({});

  const ref = useRef<HTMLUListElement>(null);
  const arrowRight = useRef<HTMLDivElement>(null);
  const arrowleft = useRef<HTMLDivElement>(null);

  useResizer({
    trigger,
    handler: () => {
      const container = ref.current;
      const buttonLeft = arrowleft.current;
      const buttonRight = arrowRight.current;
      const cssClassHide = "hide";
      const cssClassIsNotOverflowing = "tPinnedObjects-list-isNotOverflowing";

      if (container != null && buttonLeft != null && buttonRight != null) {
        const widthContainer = container.clientWidth;
        const widthContent = container.scrollWidth;

        if (widthContent <= widthContainer) {
          container.classList.add(cssClassIsNotOverflowing);
          buttonLeft.classList.add(cssClassHide);
          buttonRight.classList.add(cssClassHide);
        } else {
          container.classList.remove(cssClassIsNotOverflowing);
          buttonLeft.classList.remove(cssClassHide);
          buttonRight.classList.remove(cssClassHide);

          var scrollOffset = container.scrollLeft;
          setIsButtonRightActive(
            widthContainer + scrollOffset !== widthContent
          );

          setIsButtonLeftActive(scrollOffset !== 0);
        }
      }
    },
  });

  useEffect(() => {
    setTrigger({});
  }, [data]);

  const handleSideButtons = (button: "left" | "right") => {
    if (ref?.current) {
      const container = ref.current;
      if (button === "left") {
        container.scrollLeft -= 150;
      } else if (button === "right") {
        container.scrollLeft += 150;
      }
    }

    setTrigger({});
  };

  return (
    <div className="tPinnedObjects-content">
      <div className="tPinnedObjects-buttons" ref={arrowleft}>
        <button
          className={`tFormButton arrowsBtns ${
            isButtonLeftActive ? "" : "tFormButton-isDisabled"
          }`}
          disabled={!isButtonLeftActive}
          onClick={() => handleSideButtons("left")}
        >
          <span className="i-left"></span>
        </button>
      </div>

      <ul ref={ref} className="tPinnedObjects-list">
        {data.map((item, index) => {
          if (item.type === "instrument") {
            const name = item.name.toLowerCase();

            //It can happend that a pinned symbol has changed in that case
            //the pinned is not to display but has to kept in memory so
            //if the name is unavailable we don't display it.
            if (name !== "unavailable")
              return <Instrument key={`${index}__${item.id}`} data={item} />;
          } else if (item.type === "peer") {
            return (
              <Peer
                key={`${index}__${item.id}`}
                data={item}
                timeframe={timeframe}
              />
            );
          }
          return null;
        })}
      </ul>

      <div className="tPinnedObjects-buttons" ref={arrowRight}>
        <button
          className={`tFormButton arrowsBtns ${
            isButtonRightActive ? "" : "tFormButton-isDisabled"
          }`}
          onClick={() => handleSideButtons("right")}
          disabled={!isButtonRightActive}
        >
          <span className="i-right"></span>
        </button>
      </div>
    </div>
  );
}
