import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getIssues } from "../utils";
import Issues from "./Issues";

import { styled } from "@mui/system";
import { deepClone } from "../../../../../../deepClone";
import Swapper from "./Swapper";

type Props = {
  list: any;
  paramsWizard: any;
  onSell: Function;
  onHold: Function;
  onSwap: Function;
};

const StlyedScrollable = styled((props: any) => (
  <Box
    p={1}
    className={props.className}
    overflow={"auto"}
    width={"100%"}
    display={"flex"}
    gap={1}
    flexDirection={"column"}
  >
    {props.children}
  </Box>
))({
  "&::-webkit-scrollbar": {
    width: "5px",
    backgroundColor: "transparent",
    marginLeft: "5px",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#d3d3d3",
  },
});

export default function IssuesList({
  list,
  paramsWizard,
  onHold,
  onSell,
  onSwap,
}: Props) {
  const [issues, setIssues] = useState<any>(getIssues(list));

  useEffect(() => {
    setIssues(getIssues(list));
  }, [list]);

  const [itemToSwap, setItemToSwap] = useState(null);
  const [showSwapper, setShowSwapper] = useState(false);
  const toHideRef = useRef<any>(null);
  const holdings = useMemo(() => {
    let allIssues = [...issues.D.holdings, ...issues.C.holdings];
    const toHideSymbols: any[] = toHideRef?.current
      ? toHideRef?.current.map((item) => item.symbol)
      : [];
    let returning = allIssues.filter(
      (item) => !toHideSymbols.includes(item.symbol)
    );
    return returning;
  }, [issues.C.holdings, issues.D.holdings]);
  const hideAndUpdateIssues = useCallback(
    (itemToHide) => {
      toHideRef.current = Array.isArray(toHideRef.current)
        ? [...toHideRef.current, itemToHide]
        : [itemToHide];
      var rating = itemToHide.rc === -2 ? "D" : "C";
      const _issues = deepClone(issues);
      _issues[rating].total = _issues[rating].total - 1;
      _issues[rating].weight = _issues[rating].weight - itemToHide.weight;
      var index: any = null;
      for (
        var i = 0, length = _issues[rating].holdings.length;
        i < length;
        i++
      ) {
        if (_issues[rating].holdings[i].symbol === itemToHide.symbol) {
          index = i;
          break;
        }
      }
      _issues[rating].holdings.splice(index, 1);
      setIssues(_issues);
    },
    [issues]
  );

  const startPositionsSymbols = useMemo(() => {
    return list.positions.map((position) => position.symbol);
  }, [list.positions]);
  return (
    <>
      {showSwapper && itemToSwap && (
        <Swapper
          startPositions={startPositionsSymbols ?? []}
          onSwap={(swapOrder) => {
            setShowSwapper(false);
            onSwap(swapOrder);
            hideAndUpdateIssues(itemToSwap);
          }}
          paramsWizard={paramsWizard}
          onClose={() => setShowSwapper(false)}
          data={itemToSwap}
        />
      )}
      <StlyedScrollable>
        {holdings.map((item, index) => (
          <Issues
            key={index}
            data={item}
            onHold={() => {
              hideAndUpdateIssues(item);
              onHold();
            }}
            onSell={() => {
              onSell(item);
              hideAndUpdateIssues(item);
            }}
            onSwap={(itemToSwap) => {
              setItemToSwap(itemToSwap);
              setShowSwapper(true);
            }}
          />
        ))}
      </StlyedScrollable>
    </>
  );
}
