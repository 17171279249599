import { CombinedProducts } from "../../../../../../../api/compute/CombinedProducts";
import { CombinedStrategies } from "../../../../../../../api/compute/CombinedStrategies";
import { Lists } from "../../../../../../../api/compute/Lists";
import { Rankings } from "../../../../../../../api/compute/Rankings";
import { Strategies } from "../../../../../../../api/compute/Strategies";
import { Subscriptions } from "../../../../../../../api/compute/Subscriptions";
import { SystematicProducts } from "../../../../../../../api/compute/SystematicProducts";
import { Utils } from "../../../../../../../api/compute/Utils";
import { deepClone } from "../../../../../../../deepClone";
import { httpAll } from "../../../../../../../httpAll";
import { AppEnvironment } from "../../../../../../../types/Defaults";

export class RemoveResource {
  private resource: any;
  private isDryRun: boolean;
  private utilsAPI: Utils;
  private API: {
    combinedProducts: CombinedProducts;
    combinedStrategies: CombinedStrategies;
    strategies: Strategies;
    rankings: Rankings;
    subscriptions: Subscriptions;
    systematicProducts: SystematicProducts;
    lists: Lists;
  };
  private environment: AppEnvironment;

  constructor(environment: AppEnvironment, item: any, isDryRun?: boolean) {
    this.resource = item;
    this.isDryRun = isDryRun ?? true;
    this.environment = environment;
    this.utilsAPI = new Utils(environment);
    this.API = {
      combinedProducts: new CombinedProducts(environment),
      combinedStrategies: new CombinedStrategies(environment),
      strategies: new Strategies(environment),
      rankings: new Rankings(environment),
      subscriptions: new Subscriptions(environment),
      systematicProducts: new SystematicProducts(environment),
      lists: new Lists(environment),
    };
  }

  async remove() {
    if (this.isDryRun) {
      let params: { action: "unsubscribe" | "remove"; item: any } = {
        action: this.resource["isReadOnly"] === true ? "unsubscribe" : "remove",
        item: this.resource,
      };

      const response = await this.utilsAPI.relations(params);

      return await this.relations(response);
    }

    if (this.resource["isReadOnly"] === true) {
      // shared object
      return this.API["subscriptions"].remove(this.resource["subscriptionId"]);
    } else {
      switch (this.resource["type"]) {
        case "BASKET":
        case "PORTFOLIO": {
          return this.API["lists"].remove(this.resource);
        }
        case "COMBINED_PRODUCT": {
          return this.API["combinedProducts"].remove(this.resource);
        }
        case "COMBINED_STRATEGY": {
          return this.API["combinedStrategies"].remove(this.resource);
        }
        case "PREFERENCE_INDEX": {
          return this.API["strategies"].remove(this.resource);
        }
        case "RANKING_ABOUT_TARGET": {
          return this.API["rankings"].remove(this.resource);
        }
        case "SYSTEMATIC_PRODUCT": {
          return this.API["systematicProducts"].remove(this.resource);
        }
        default:
          break;
      }
    }
  }

  async relations(response) {
    const relations = response["data"]["relations"];
    var requests: Promise<any>[] = [];
    const http = this.API;
    for (let i = 0, length = relations.length; i < length; i++) {
      const chain = relations[i];

      // the last element of the chain is the real dependency
      // chain with multiple relation are related to shared items
      const relation = chain[chain.length - 1];

      switch (relation["type"]) {
        case "COMBINED_PRODUCT": {
          requests.push(
            this.prepareRequest(
              http["combinedProducts"].get(relation["id"]),
              chain
            )
          );

          break;
        }
        case "COMBINED_STRATEGY": {
          requests.push(
            this.prepareRequest(
              http["combinedStrategies"].get(relation["id"]),
              chain
            )
          );

          break;
        }
        case "PREFERENCE_INDEX": {
          requests.push(
            this.prepareRequest(
              http["strategies"].getById(relation["id"]),
              chain
            )
          );

          break;
        }
        case "RANKING_ABOUT_TARGET": {
          requests.push(
            this.prepareRequest(http["rankings"].get(relation["id"]), chain)
          );

          break;
        }
        case "SUBSCRIPTION": {
          var environment = this.environment;
          var userId = environment?.account?.user?.id;

          if (userId === http["publications"].PUBLISHER_USER_ID) {
            const req = async () => {
              const response = await http["accountUsers"].get({
                ids: [relation["userId"]],
                properties: ["firstName", "lastName", "email"],
              });

              var user = response.data[0].rows[0];

              return {
                name: [
                  user.firstName,
                  user.lastName,
                  "-",
                  user.email,
                  "- ID:",
                  user.id,
                ].join(" "),
                type: relation["type"],
              };
            };
            requests.push(req(), chain);
          }

          break;
        }
        case "SYSTEMATIC_PRODUCT": {
          requests.push(
            this.prepareRequest(
              http["systematicProducts"].get({
                id: relation["id"],
              }),
              chain
            )
          );

          break;
        } // no default
      }
    }

    const result = await httpAll(requests);

    return this._relations(result);
  }

  async prepareRequest(request, chain) {
    return request.then((response) => this.relationChain(response, chain));
  }

  relationChain(response, chain) {
    var _response = deepClone(response);
    _response["isSubscription"] = false;

    if (chain.length > 1) {
      _response["isSubscription"] = true;
    }

    return _response;
  }

  _relations(items) {
    var item: any = null;

    // item types use the same convention of
    // trendrating/api/compute/modelObjects/modelObjectTypes
    var data: any = { relations: [] };

    for (let i = 0, length = items.length; i < length; i++) {
      item = items[i];

      switch (item["type"]) {
        case "COMBINED_PRODUCT":
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "combinedProduct",
          });

          break;
        case "COMBINED_STRATEGY": {
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "combinedStrategy",
          });

          break;
        }
        case "PREFERENCE_INDEX": {
          var strategy: any = {
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: null,
          };

          switch (item["entity_type"]) {
            case "ALPHA": {
              strategy["type"] = "strategyAlpha";

              break;
            }
            case "BUILDER": {
              strategy["type"] = "strategyAdvanced";

              break;
            }
            case "SECTOR_ROTATION": {
              strategy["type"] = "strategyMacroRotation";

              break;
            }
            case "SMART_BETA": {
              strategy["type"] = "strategySmartBeta";

              break;
            }

            // no default
          }

          data["relations"].push(strategy);

          break;
        }
        case "RANKING_ABOUT_TARGET": {
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "rankingAboutTarget",
          });

          break;
        }
        case "SUBSCRIPTION": {
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "subscription",
          });

          break;
        }
        case "SYSTEMATIC_PRODUCT": {
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "systematicPortfolio",
          });

          break;
        }
        default:
          data["relations"].push({
            isSubscription: item["isSubscription"],
            name: item["name"],
            type: "unknown",
          });

          break;
      }
    }

    return data;
  }
}
