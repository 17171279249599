import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { FactsType, factsReducer } from "./React_Facts_reducer";

type FactsProps = {
  configObj: any;
};

const Facts = forwardRef(({ configObj }: FactsProps, ref) => {
  const initState: FactsType = useMemo(
    () => ({
      currency: configObj.widgetValue.content.currency,
      customText1: configObj.widgetValue.content.customText1,
      customText2: configObj.widgetValue.content.customText2,
      customText3: configObj.widgetValue.content.customText3,
      headline: configObj.widgetValue.content.headline,
      launchDate: configObj.widgetValue.content.launchDate,
      numberOfHoldings: configObj.widgetValue.content.numberOfHoldings,
      performance: configObj.widgetValue.content.performance,
      rebalancingFrequency: configObj.widgetValue.content.rebalancingFrequency,
      endingDate:
        configObj?.widgetValue?.content?.endingDate != null
          ? configObj?.widgetValue?.content?.endingDate
          : {
              content: "",
              isEnabled: false,
              label: "",
            },
    }),
    [
      configObj.widgetValue.content.currency,
      configObj.widgetValue.content.customText1,
      configObj.widgetValue.content.customText2,
      configObj.widgetValue.content.customText3,
      configObj.widgetValue.content.endingDate,
      configObj.widgetValue.content.headline,
      configObj.widgetValue.content.launchDate,
      configObj.widgetValue.content.numberOfHoldings,
      configObj.widgetValue.content.performance,
      configObj.widgetValue.content.rebalancingFrequency,
    ]
  );
  const [state, dispatch] = useImmerReducer(factsReducer, initState);

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box display={"flex"}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.headline.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_HEADLINE_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              value={state.headline.content}
              onChange={(e) =>
                dispatch({
                  type: "SET_HEADLINE_CONTENT",
                  payload: e.target.value,
                })
              }
              size="small"
            />
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            pb: "16px !important",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>&nbsp;</Box>
            <Box display={"flex"} gap={2}>
              <TextField
                size="small"
                value={"Row headline"}
                disabled
              ></TextField>
              <TextField
                size="small"
                value={"Row Content"}
                disabled
              ></TextField>
            </Box>
          </Box>
          {/* Rebalancing frequency */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.rebalancingFrequency.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_REBALANCING_FREQUENCY_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Rebalancing frequency"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                disabled={!state.rebalancingFrequency.isEnabled}
                value={state.rebalancingFrequency.label}
                onChange={(e) =>
                  dispatch({
                    type: "SET_REBALANCING_FREQUENCY_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />

              <TextField
                disabled={!state.rebalancingFrequency.isEnabled}
                value={state.rebalancingFrequency.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_REBALANCING_FREQUENCY_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Currency */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.currency.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CURRENCY_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Currency"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                value={state.currency.label}
                disabled={!state.currency.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CURRENCY_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />

              <TextField
                disabled={!state.currency.isEnabled}
                value={state.currency.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CURRENCY_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Number of holdings */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.numberOfHoldings.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_NUMBER_OF_HOLDINGS_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Number of holdings"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                value={state.numberOfHoldings.label}
                disabled={!state.numberOfHoldings.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_NUMBER_OF_HOLDINGS_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
              <TextField
                value={state.numberOfHoldings.content}
                disabled={!state.numberOfHoldings.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_NUMBER_OF_HOLDINGS_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Starting date */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) =>
                      dispatch({
                        type: "SET_LAUNCH_DATE_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    checked={state.launchDate.isEnabled}
                    size="small"
                  />
                }
                label="Starting date"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                value={state.launchDate.label}
                disabled={!state.launchDate.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_LAUNCH_DATE_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
              <TextField
                value={state.launchDate.content}
                disabled={!state.launchDate.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_LAUNCH_DATE_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* As of date */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.endingDate.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_ENDINGDATE_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="As of date"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                disabled={!state.endingDate.isEnabled}
                value={state.endingDate.label}
                onChange={(e) =>
                  dispatch({
                    type: "SET_ENDINGDATE_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
              <TextField
                value={state.endingDate.content}
                disabled={!state.endingDate.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_ENDINGDATE_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Performance */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.performance.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_PERFORMANCE_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Performance"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                value={state.performance.label}
                disabled={!state.performance.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PERFORMANCE_LABEL",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
              <TextField
                value={state.performance.content}
                disabled={!state.performance.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PERFORMANCE_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Custom text 1 */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.customText1.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CUSTOMTEXT_1_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Custom text"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                disabled={!state.customText1.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_1_LABEL",
                    payload: e.target.value,
                  })
                }
                value={state.customText1.label}
                size="small"
              />
              <TextField
                disabled={!state.customText1.isEnabled}
                value={state.customText1.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_1_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Custom text 2 */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.customText2.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CUSTOMTEXT_2_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Custom text"
              />
            </Box>

            <Box display={"flex"} gap={2}>
              <TextField
                disabled={!state.customText2.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_2_LABEL",
                    payload: e.target.value,
                  })
                }
                value={state.customText2.label}
                size="small"
              />
              <TextField
                disabled={!state.customText2.isEnabled}
                value={state.customText2.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_2_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>

          {/* Custom text 3 */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.customText3.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CUSTOMTEXT_3_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Custom text"
              />
            </Box>
            <Box display={"flex"} gap={2}>
              <TextField
                disabled={!state.customText3.isEnabled}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_3_LABEL",
                    payload: e.target.value,
                  })
                }
                value={state.customText3.label}
                size="small"
              />
              <TextField
                disabled={!state.customText3.isEnabled}
                value={state.customText3.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CUSTOMTEXT_3_CONTENT",
                    payload: e.target.value,
                  })
                }
                size="small"
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Facts;
