import { ColumnSet } from "../../../../../components/table/tableUtilities/ColumnSet";

export class InputStubReport {
  columns: any = null;
  filter: any = null;
  page: any = null;
  sort: any = null;

  constructor(params) {
    if ("columns" in params) {
      this.columns = params["columns"];
    }

    if ("filter" in params) {
      this.filter = params["filter"];
    }

    if ("page" in params) {
      this.page = params["page"];
    } else {
      this.page = {
        page: 1,
        rows: 1000,
      };
    }

    if ("sort" in params) {
      this.sort = params["sort"];
    } else {
      this.sort = {
        property: "marketcap",
        descending: true,
      };
    }
  }

  get = (property) => {
    return this[property];
  };
}

export const _getDefaultColumns = (environment, page) => {
  var properties = environment.properties;

  // default table columns
  var holdingsTabIndex =
    environment.setup.configuration.configuration.pages[page].tabsIndex
      .holdings;
  var elements =
    environment.setup.configuration.configuration.pages[page].tabs[
      holdingsTabIndex
    ]["widgets"]["viewer"];

  var columnSetFactory = new ColumnSet({
    customConfiguration: null,
    elements: elements,
    properties: properties,
  });
  var availableColumns = columnSetFactory.generate("security");

  var column: any = null;
  var defaultColumns: any[] = [];
  for (let i = 0, length = availableColumns.length; i < length; i++) {
    column = availableColumns[i];
    if (column["selected"] === true) {
      defaultColumns.push(column);
    }
  }
  defaultColumns.sort(function (a, b) {
    if (a["order"] > b["order"]) {
      return 1;
    }
    if (a["order"] < b["order"]) {
      return -1;
    }
    return 0;
  });

  return defaultColumns;
};
