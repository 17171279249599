/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyQuarterlyAnalytics
 * @summary Strategy - Quarterly Analytics
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity } from "./Generator";

export function sectionsQuarterlyAnalytics(input, section, datum, formatter) {
    const _sections: any = [];

    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    if (section["content"]["quarterlyPerformance"]["isEnabled"]) {
        if (section["content"]["quarterlyPerformance"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["quarterlyPerformance"]["content"],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsQuarterlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyQuarterlyPerformance",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsQuarterlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyQuarterlyPerformance",
                formatter
            );
            _sections.push(_section);
        }
    }
    if (section["content"]["maxDrawdown"]["isEnabled"]) {
        if (section["content"]["maxDrawdown"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["maxDrawdown"]["content"],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsQuarterlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyMaxDrawdown",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsQuarterlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyMaxDrawdown",
                formatter
            );
            _sections.push(_section);
        }
    }
    if (section["content"]["quarterlyStandardDeviation"]["isEnabled"]) {
        if (section["content"]["quarterlyStandardDeviation"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["quarterlyStandardDeviation"][
                        "content"
                    ],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsQuarterlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyVolatility",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsQuarterlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyVolatility",
                formatter
            );
            _sections.push(_section);
        }
    }

    return _sections;
}

function sectionsQuarterlyAnalyticsDetailByColumns(
    input,
    section,
    data,
    field,
    formatter
) {
    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };
    // const tableRow = null;

    // Organize years grouped by quarter
    const yearsByQuarter = {};
    const years: any = [];
    for (let i = 0; i < data["analytics"]["quarterly"].length; i++) {
        const item = data["analytics"]["quarterly"][i];
        const timeFrame = item["timeFrame"].split("_");
        const year = String(timeFrame[0]);
        let quarter = -1;
        switch (timeFrame[1]) {
            case "03": {
                quarter = 1;
                break;
            }
            case "06": {
                quarter = 2;
                break;
            }
            case "09": {
                quarter = 3;
                break;
            }
            case "12": {
                quarter = 4;
                break;
            }
        }
        if (quarter === -1) {
            continue;
        }

        if (!isNaN(parseInt(year, 10))) {
            if (yearsByQuarter[quarter] == null) {
                yearsByQuarter[quarter] = {};
            }
            yearsByQuarter[quarter][year] = item;
            if (years.indexOf(year) === -1) {
                years.push(year);
            }
        }
    }

    // Sort years
    years.sort(function (a, b) {
        return a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1;
    });

    // Prepare header
    table["data"]["head"][0].push({
        style: null,
        value: "",
    });
    for (let i = 0, length = years.length; i < length; i++) {
        table["data"]["head"][0].push({
            style: {
                align: "right",
            },
            value: years[i],
        });
    }

    for (const quarter in yearsByQuarter) {
        const tableRow: any = [];
        // Iterate for ALL years, even empty
        for (const yearIndex in years) {
            const year = years[yearIndex];
            /*
                            The column number is the actual year, subtracted by the first year (assume the
                                years are consecutive and incrementing) and add 1 to offset to the second
                                column. The first column is already used to show the name of the quarter.

                            Example: 2005, 2006, 2007

                            If there is 2005:
                                2005 - 2005 + 1 = 1 (column, first)

                            If there is 2007:
                                2007 - 2005 + 1 = 3 (column, last column actually in that case)
                        */
            const yearValue = parseInt(year, 10);
            if (!isNaN(yearValue)) {
                const yearValueIndex = yearValue - years[0] + 1;
                // Get item
                const item = yearsByQuarter[quarter][year];
                if (item == null) {
                    tableRow[yearValueIndex] = {
                        style: {
                            align: "right",
                        },
                        value: "",
                    };
                } else {
                    const timeFrame = item["timeFrame"].split("_");
                    // const date = new Date(
                    //     Date.UTC(
                    //         parseInt(timeFrame[0], 10),
                    //         parseInt(timeFrame[1], 10) - 1,
                    //         1
                    //     )
                    // );
                    // Set as first element
                    let quarter = -1;
                    let quarterLabel = "";
                    switch (timeFrame[1]) {
                        case "03": {
                            quarter = 1;
                            quarterLabel = "Q1";
                            break;
                        }
                        case "06": {
                            quarter = 2;
                            quarterLabel = "Q2";
                            break;
                        }
                        case "09": {
                            quarter = 3;
                            quarterLabel = "Q3";
                            break;
                        }
                        case "12": {
                            quarter = 4;
                            quarterLabel = "Q4";
                            break;
                        }
                    }
                    if (quarter === -1) {
                        continue;
                    }
                    tableRow[0] = {
                        style: null,
                        value: quarterLabel,
                    };
                    tableRow[yearValueIndex] = {
                        style: {
                            align: "right",
                        },
                        value: format.custom("number", {
                            options:
                                customPropertiesFormattingOptions["PERCENTAGE"],
                            output: "PDF",
                            value: item[field],
                            valueHelper: null,
                        }),
                    };
                }
            }
        }
        if (tableRow.length > 0) {
            table["data"]["body"].push(tableRow);
        }
    }

    return table;
}

function sectionsQuarterlyAnalyticsDetailByRows(
    input,
    section,
    data,
    field,
    formatter
) {
    const dates = data["analytics"]["quarterly"];

    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };

    // Head setup
    table["data"]["head"][0].push({
        style: null,
        value: "",
    });
    table["data"]["head"][0].push({
        style: {
            align: "right",
        },
        value: "Q1",
    });
    table["data"]["head"][0].push({
        style: {
            align: "right",
        },
        value: "Q2",
    });
    table["data"]["head"][0].push({
        style: {
            align: "right",
        },
        value: "Q3",
    });
    table["data"]["head"][0].push({
        style: {
            align: "right",
        },
        value: "Q4",
    });

    function initializeTableRowQuarters(year) {
        const tableRow = [
            {
                style: null,
                value: year != null ? year : "",
            },
        ];
        tableRow[1] = {
            style: null,
            value: "",
        };
        tableRow[2] = {
            style: null,
            value: "",
        };
        tableRow[3] = {
            style: null,
            value: "",
        };
        tableRow[4] = {
            style: null,
            value: "",
        };
        return tableRow;
    }

    let tableRow: any = [];
    let actualYear;
    for (let i = 0; i < dates.length; i++) {
        const item = dates[i];
        const timeFrame = item["timeFrame"].split("_");
        const year = String(timeFrame[0]);
        if (year !== "average" && year !== "Annualized") {
            if (actualYear !== year) {
                if (tableRow.length > 0) {
                    table["data"]["body"].push(tableRow);
                }
                actualYear = year; // Start of new year, new row
                tableRow = initializeTableRowQuarters(year);
            }
            let quarter = -1;
            switch (timeFrame[1]) {
                case "03": {
                    quarter = 1;
                    break;
                }
                case "06": {
                    quarter = 2;
                    break;
                }
                case "09": {
                    quarter = 3;
                    break;
                }
                case "12": {
                    quarter = 4;
                    break;
                }
            }
            if (quarter === -1) {
                continue;
            }
            tableRow[quarter] = {
                style: {
                    align: "right",
                },
                value: format.custom("number", {
                    options: customPropertiesFormattingOptions["PERCENTAGE"],
                    output: "PDF",
                    value: item[field],
                    valueHelper: null,
                }),
            };
        }
    }
    if (tableRow.length > 0) {
        table["data"]["body"].push(tableRow);
    }
    return table;
}
