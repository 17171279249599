import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Lists } from "../../../../../../../api/compute/Lists";
import { PanelForLists } from "../../../../../../../components/PanelForLists/PanelForLists";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { FiltersContext } from "../../FilterRowContraints";
import { v4 as uuidv4 } from "uuid";

type UniverseDefinitionFilterProps = {
    label: string;
};

const LIST_TYPE_DICT = {
    PORTFOLIO: "Portfolios",
    BASKET: "Baskets",
    Portfolios: "PORTFOLIO",
    Baskets: "BASKET",
};

export function UniverseDefinitionFilter({
    label,
}: UniverseDefinitionFilterProps) {
    const context = useContext(FiltersContext);
    const [showPanel, setShowPanel] = useState(false);
    const [loadingLists, setLoadingLists] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState(false);
    const [list, setList] = useState<
        | {
              name: string;
              id: number;
              type: string;
              isSubscribed: boolean;
          }[]
        | null
    >(null);
    const environment = useEnvironment();
    const apiLists: Lists = useMemo(
        () => environment.get("http")["lists"],
        [environment]
    );
    const [selectedList, setSelectedList] = useState<string[]>([]);

    const getSelectedItem = useCallback(async () => {
        if (context?.whiteList && context?.whiteList.length) {
            const whiteLists = context?.whiteList;
            const listsNames: string[] = [];

            for (const whiteList of whiteLists) {
                if (whiteList.id && !whiteList.name) {
                    setLoadingLabel(true);
                    const value = await apiLists.get(whiteList.id, ["name"]);

                    listsNames.push(value?.name ?? "-");
                    setLoadingLabel(false);
                } else if (whiteList.name) {
                    listsNames.push(whiteList.name);
                }
            }

            setSelectedList(listsNames);
        } else {
            setSelectedList([]);
        }
    }, [apiLists, context?.whiteList]);

    useEffect(() => {
        getSelectedItem();
    }, [getSelectedItem]);

    const removeWhiteList = useCallback(() => {
        if (context) {
            context?.updateWhiteList(null);
        }
    }, [context]);

    const handlePanelAppearence = useCallback((show: boolean) => {
        setShowPanel(show);
    }, []);

    const openDialogAndGetLists = useCallback(async () => {
        if (list != null) {
            handlePanelAppearence(true);
        } else {
            setLoadingLists(true);
            handlePanelAppearence(true);

            try {
                const lists = await apiLists.get();

                if (lists.data && lists.data.length) {
                    const listForPanel = lists.data.map((list) => ({
                        name: list.name,
                        id: list.id,
                        type: LIST_TYPE_DICT[list.type],
                        isSubscribed: list.isReadOnly,
                    }));
                    listForPanel.sort((a, b) => {
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        } else if (
                            a.name.toLowerCase() < b.name.toLowerCase()
                        ) {
                            return -1;
                        }

                        return 0;
                    });
                    setList(listForPanel);
                }
            } catch (error) {}

            setLoadingLists(false);
        }
    }, [apiLists, handlePanelAppearence, list]);

    const handleItemSelection = useCallback(
        (ids: number[]) => {
            if (context) {
                const idsList: { id: number; name: string; type: string }[] =
                    [];
                let listObj: any = undefined;
                ids.forEach((id) => {
                    listObj =
                        list?.find((listItem) => listItem.id === id) ?? null;
                    if (listObj) {
                        idsList.push({
                            id,
                            name: listObj.name,
                            type: LIST_TYPE_DICT[listObj.type],
                        });
                    }
                });

                context.updateWhiteList(idsList);
            }
        },
        [context, list]
    );

    const listsNames = useMemo(() => {
        if (selectedList.length) {
            if (selectedList.length > 3) {
                return [selectedList[0], `+ ${selectedList.length - 1}`];
            } else {
                return selectedList;
            }
        }

        return ["-"];
    }, [selectedList]);

    const panelInitialValue = useMemo(() => {
        if (context?.whiteList) {
            return context.whiteList.map((item) => item.id);
        }

        return [];
    }, [context?.whiteList]);

    return (
        <Card
            sx={{
                display: "flex",
                position: "relative",
                border: "1px solid",
                borderColor: "transparent",
                transition: "0.5s",
                cursor: "pointer",
                "&:hover": {
                    borderColor: "#2a7090",
                },
            }}
            title={label}
        >
            <PanelForLists
                isLoadingData={loadingLists}
                list={list ?? []}
                showDialog={showPanel}
                closeDialog={() => handlePanelAppearence(false)}
                initialValue={panelInitialValue}
                sectionsTag={["Portfolios", "Baskets"]}
                itemsPerColumn={20}
                selectItem={handleItemSelection}
                headerTitle={"Select a white list"}
                multipleChoice
            />
            {context?.whiteList && (
                <Box
                    title={"Remove white list"}
                    sx={{
                        position: "absolute",
                        right: "2px",
                        top: "2px",
                        cursor: "pointer",
                    }}
                >
                    <CloseIcon
                        onClick={removeWhiteList}
                        sx={{
                            fontSize: "14px",
                            color: "black",
                        }}
                    />
                </Box>
            )}
            <CardContent
                sx={{
                    padding: "2px 6px!important",
                    display: "flex",
                    flex: 1,
                }}
            >
                <Box
                    onClick={openDialogAndGetLists}
                    display={"flex"}
                    flexDirection={"column"}
                    textAlign={"center"}
                    flex={1}
                >
                    <Typography mr={context?.whiteList ? "16px" : 0}>
                        <strong>{label}</strong>
                    </Typography>
                    <Box
                        flex={1}
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                    >
                        {loadingLabel ? (
                            <Typography>...loading</Typography>
                        ) : (
                            listsNames.map((name) => (
                                <Typography key={uuidv4()}>{name}</Typography>
                            ))
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}
