/* eslint-disable no-template-curly-in-string */
import { Box, Card, CardContent, ThemeProvider } from "@mui/material";
import { format as fmtDate } from "date-fns";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ColumnDefinition } from "tabulator-tables";
import { Strategies } from "../../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../../api/compute/SystematicProducts";
import Modal from "../../../../../components/Modal/Modal";
import { RapidMenu } from "../../../../../components/RapidMenu/RapidMenu";
import { TrendratingTable } from "../../../../../components/table/TrendratingTable";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { TDate } from "../../../../../trendrating/date/TDate";
import { DialogRelations } from "../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { LoadingCallbacks } from "../../../storage/CombinedStrategiesStorage";
import { SystematicPortfoliosStorage } from "../../../storage/SystematicPortfoliosStorage";
import { TR_theme } from "../../../theme/TR_theme";
import { messageError, messageSuccess } from "../../../utils";
import { STRATEGIES_LOADER_EVENTS } from "../../strategies/builder/StrategiesBuilder";
import { SortBy } from "../../strategies/widgets/StrategiesList/StrategiesList";
import { DialogEdit } from "../dialog/DialogEdit";
import { ChangeViewBtn } from "../SystematicPortfolios";
import { rebalance, rebalanceInfo } from "./utils";

type GridViewerProps = {
  showShared: boolean | null;
  storage: SystematicPortfoliosStorage;
  view: "tile" | "table";
  handleChangeView: any;
  refreshList: any;
  showProgressBar: any;
  setShowProgressBar: any;
};

// Server side preference
const SORT_STATE_PATH = ["systematicProducts", "sort"];

export default function GridViewer({
  showShared,
  storage,
  view,
  handleChangeView,
  refreshList,
  showProgressBar,
  setShowProgressBar,
}: GridViewerProps) {
  const navigate = useNavigate();
  const { broadcast } = useBroadcast();

  const [products, setProducts] = useState<any>([]);
  const [sort, setSort] = useState<
    undefined | "name" | "name_rev" | "recent_first" | "recent_bottom"
  >(undefined);
  const { t } = useTranslation();
  const environment = useEnvironment();
  const pageStatusAPI = useMemo(
    () => environment.get("preferenceStatus"),
    [environment]
  );
  const userId = useMemo(
    () => environment.get("account")["user"]["id"],
    [environment]
  );
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);
  const smsAPI = useMemo(() => new SystematicProducts(appSetup), [appSetup]);

  const preferencesReady = useMemo(() => {
    return sort != null;
  }, [sort]);

  const prepareRebalanceInfo = useCallback((response) => {
    if (!response) {
      return undefined;
    }

    var data = {
      next: response["nextRebalance"],
      previous: response["previousRebalance"],
      status: response["rebalanceStatus"].toLowerCase(),
      today: response["today"],
    };

    return data;
  }, []);

  const getSorter = useCallback((sort) => {
    let property = "name";
    let rev = false;

    switch (sort) {
      case "name":
      default:
        break;

      case "name_rev":
        property = "name";
        rev = true;

        break;

      case "recent_bottom":
        property = "creationTime";
        rev = false;

        break;

      case "recent_first":
        property = "creationTime";
        rev = true;

        break;
    }

    return { property, rev };
  }, []);

  const getData = useCallback(async () => {
    let productIds: number[] = [];

    if (preferencesReady) {
      // if (showShared) {
      let subscribed = await storage.getSubscribedProducts();
      // } else {
      let custom = await storage.getUserProducts();
      // }
      productIds = [...custom, ...subscribed];

      const { property, rev } = getSorter(sort);

      const fields = [
        "name",
        "reviewGranularity",
        "updateTime",
        "currency",
        "autorebalance",
        "calendarRebalance",
        "ownerId",
      ];

      if (!fields.includes(property)) {
        fields.push(property);
      }

      const data = await storage.smsAPI.fetch({
        ids: productIds,
        properties: fields,
      });
      let formattedData = data.map((obj) => {
        obj["calendarRebalance"] = prepareRebalanceInfo(
          obj["calendarRebalance"]
        );
        return obj;
      });

      let aValue: any = null;
      let bValue: any = null;

      formattedData = formattedData.sort((a, b) => {
        aValue =
          property === "creationTime" ? a[property] : a[property].toLowerCase();
        bValue =
          property === "creationTime" ? b[property] : b[property].toLowerCase();

        if (aValue > bValue) {
          return rev === false ? 1 : -1;
        } else if (aValue < bValue) {
          return rev === false ? -1 : 1;
        }

        return 0;
      });

      setProducts(formattedData);
    }
  }, [getSorter, preferencesReady, prepareRebalanceInfo, sort, storage]);

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }

    return () => {
      setProducts([]);
    };
  }, [getData]);

  const setInitialSort = useCallback(async () => {
    const savedSort = await pageStatusAPI.get(SORT_STATE_PATH);
    let sortValue: any = "name";

    if (savedSort) {
      const field = savedSort?.property ?? "name";
      const rev = savedSort?.rev ?? false;

      if (field.startsWith("name")) {
        if (field === "name") {
          if (rev === true) {
            sortValue = "name_rev";
          } else {
            sortValue = "name";
          }
        }
      } else {
        if (rev === true) {
          sortValue = "recent_first";
        } else {
          sortValue = "recent_bottom";
        }
      }
    }

    setSort(sortValue);
  }, [pageStatusAPI]);

  const onSortChange = useCallback(
    async (sortValue) => {
      const sorter = getSorter(sortValue);
      await pageStatusAPI.patch(SORT_STATE_PATH, sorter);

      setSort(sortValue);
    },
    [getSorter, pageStatusAPI]
  );

  useEffect(() => {
    setInitialSort();
  }, [setInitialSort]);

  const format = useFormatter();
  const rapidMenuActions: any = useMemo(() => {
    return [
      {
        label: "Overview",
        type: "item",
        value: "actionOverview",
      },
      {
        label: "Edit",
        type: "item",
        value: "actionEdit",
      },
      {
        label: "Rebalance",
        type: "item",
        value: "actionRebalance",
      },
      {
        label: "Delete",
        type: "item",
        value: "actionDelete",
      },
      {
        label: "Unsubscribe",
        type: "item",
        value: "actionUnsubscribe",
      },
    ];
  }, []);
  const listenerOverview = useCallback((resource) => {
    const w = window as any;
    w.__page_navigate(`/systematic-portfolios/${resource.id}`);
  }, []);
  const listenerDelete = useCallback((resource) => {
    setItemToDelete(resource);
    setShowDeleteModal(true);
  }, []);

  const listenerUnsubscribe = useCallback((resource) => {
    setItemToDelete(resource);
    setShowDeleteModal(true);
  }, []);
  const [showEditPanel, setShowEditPanel] = useState(false);
  const listenerEdit = useCallback((resource) => {
    setItemToEdit(resource);
    setShowEditPanel(true);
  }, []);
  const wrapMenuActionHandler = useCallback(
    async (event, resource) => {
      event.stopPropagation();
      const action = event?.value.action;
      switch (action) {
        case "actionOverview": {
          listenerOverview(resource);

          break;
        }
        case "actionDelete": {
          let product = await storage.smsAPI.getById(resource.id);
          listenerDelete(product);
          break;
        }
        case "actionRebalance": {
          const url = `/app/systematic-portfolios/${resource.id}/rebalance/`;
          navigate(url);
          break;
        }
        case "actionEdit": {
          let product = await storage.smsAPI.getById(resource.id);
          listenerEdit(product);
          break;
        }
        case "actionUnsubscribe": {
          let product = await storage.smsAPI.getById(resource.id);
          product["pubSubType"] = "systematicPortfolio";
          listenerUnsubscribe(product);
          break;
        }
      }
    },
    [listenerDelete, listenerEdit, listenerOverview, listenerUnsubscribe, navigate, storage]
  );
  const columns: ColumnDefinition[] = [
    {
      title: "Name",
      field: "name",
      cssClass: "no-col-borders",
      headerHozAlign: "left",
      hozAlign: "left",
      widthGrow: 3,
      formatter: (cell, formatterParams, onRendered) => {
        let _value = cell.getValue();
        const _data: any = cell.getData();
        if (_data.ownerId !== userId) {
        }
        const customComponent = (
          <ThemeProvider theme={TR_theme}>
            <Box
              display={"flex"}
              gap={1}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Box>
                {_value}{" "}
                {_data.ownerId !== userId ? (
                  <div className="sharedObjectIndicator sharedObjectIndicator--small"></div>
                ) : null}
              </Box>
              <RapidMenu
                smallBtn
                availableOnlyForSharedObject={["actionUnsubscribe"]}
                availableForSharedObject={[
                  "actionOverview",
                  "actionUnsubscribe",
                ]}
                onOptionClickHandler={(evt) =>
                  wrapMenuActionHandler(evt, _data)
                }
                options={rapidMenuActions}
                isReadOnly={_data.ownerId !== userId}
              />
            </Box>
          </ThemeProvider>
        );

        const renderFn = () => {
          const cellEl = cell.getElement();
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell");
            if (formatterCell) {
              const CompWithMoreProps = cloneElement(customComponent, { cell });
              const root = createRoot(formatterCell);
              root.render(CompWithMoreProps);
            }
          }
        };

        onRendered(renderFn);
        return '<div class="formatterCell"></div>';
      },
    },
    {
      title: "Rebalance",
      field: "reviewGranularity",
      cssClass: "no-col-borders",
      hozAlign: "left",
      widthGrow: 2,
      formatter: (cell) => {
        return rebalance(cell.getData()["reviewGranularity"], t);
      },
    },
    {
      title: "Last edited",
      field: "updateTime",
      cssClass: "no-col-borders",
      hozAlign: "left",
      widthGrow: 2,
      formatter: (cell) => {
        return fmtDate(
          TDate.daysToDate(
            TDate.millisecondsToDays(cell.getData()["updateTime"])
          ),
          "dd LLL yyyy"
        );
      },
    },
    {
      title: "Currency",
      field: "currency",
      cssClass: "no-col-borders",
      hozAlign: "left",
      widthGrow: 2,
    },
    {
      title: "Auto-rebalance",
      field: "autorebalance",
      cssClass: "no-col-borders",
      hozAlign: "center",
      widthGrow: 1,

      formatter: (cell) => {
        if (cell.getData()["autorebalance"]) {
          return "On";
        } else {
          return "Off";
        }
      },
    },
    {
      title: "Rebalance status",
      field: "calendarRebalance.status",
      cssClass: "no-col-borders",
      hozAlign: "left",
      widthGrow: 4,
      formatter: (cell) => {
        if (cell.getData()["calendarRebalance"]["status"] === "overdue") {
          cell.getElement().style.color = "red";
        } else if (
          cell.getData()["calendarRebalance"]["status"] === "rebalance"
        ) {
          cell.getElement().style.color = "#ffc001";
        }
        return rebalanceInfo(cell.getData()["calendarRebalance"], t, format);
      },
    },
  ];
  const handleEventCallback = useCallback((event) => {
    if (event.type === "rowClick") {
      const w = window as any;
      w.__page_navigate(`/systematic-portfolios/${event.value.getData().id}`);
    }
  }, []);

  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const closeDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const { dispatch } = useEventBus();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<any>(null);
  const progressListener = useCallback(
    (status) => {
      setShowProgressBar(true);
    },
    [setShowProgressBar]
  );
  const progressBarInit = useCallback(
    (loaderFn: LoadingCallbacks, strategyName: string, isBenchmark = false) => {
      if (!loaderFn) {
        console.warn(
          "No loading behaviour is specified so the app not crash but cannot show the progress bar"
        );
        return;
      }
      let eventSourceId =
        strategiesAPI.generateBusId() + "_" + Math.floor(Math.random() * 1000);
      if (isBenchmark) {
        eventSourceId += "-2";
      }
      setTimeout(() =>
        strategiesAPI.status(eventSourceId, (chunk) => {
          if ("status" in chunk && chunk.status === "start") {
            loaderFn.startNew({
              min: chunk.d,
              max: chunk.de,
              point: chunk.d,
              id: eventSourceId,
              name: strategyName,
            });
          } else if (!("status" in chunk)) {
            loaderFn.update(eventSourceId, chunk.d);
          } else if ("status" in chunk && chunk.status === "stop") {
            loaderFn.complete(eventSourceId);
          }
        })
      );
      return eventSourceId;
    },
    [strategiesAPI]
  );
  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
    }),
    [dispatch]
  );
  const productEditBacktesting = useCallback(
    async (event) => {
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      var product = event.value;
      let message = `Canno update the backtesting of ${product.name} due to unknown error`;

      try {
        const strategy = await strategiesAPI.getById(product.strategyId);
        const eventSourceId = progressBarInit(loadingBehaviours, strategy.name);
        strategy.params.busId = eventSourceId;
        var params = {
          product: product,
          strategy: strategy,
          listeners: {
            progress: progressListener,
          },
        };
        setShowProgressBar(true);
        const result = await smsAPI.updateAllocations(params);
        message = `Backtesting of <strong>${result.name}</strong> has been updated.`;
        setShowProgressBar(false);
        storage.invalidateCache();
        storage.invalidateProduct(result.id);
        const [channel, msg] = messageSuccess(message);
        broadcast(channel as string, msg);
        refreshList();
      } catch (error) {
        console.log(error);
        setShowEditPanel(false);
        const [channel, msg] = messageError(message);
        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      loadingBehaviours,
      progressBarInit,
      progressListener,
      refreshList,
      setShowProgressBar,
      smsAPI,
      storage,
      strategiesAPI,
    ]
  );
  const productEdit = useCallback(
    async (e) => {
      const params = e.value;
      let feedback = "";
      try {
        const response = await smsAPI.update(params);
        feedback = `<strong>${response.name}</strong> has been updated.`;
        setShowEditPanel(false);
        storage.invalidateProduct(response.id);
        storage.invalidateCache();
        const [channel, msg] = messageSuccess(feedback);
        broadcast(channel as string, msg);
        // getProduct();
        refreshList();
      } catch (error) {
        console.log(error);
        feedback = `Cannot update ${params.basic.name} due to an unknown error.`;
        setShowEditPanel(false);
        const [channel, msg] = messageError(feedback);
        broadcast(channel as string, msg);
      }
    },
    [broadcast, refreshList, smsAPI, storage]
  );

  return (
    <>
      {showShared !== null && products != null && (
        <Box p={1} width={"100%"}>
          {showDeleteModal && (
            <DialogRelations
              onRemoveDone={() => {
                refreshList();
                const [channel, msg] = messageSuccess(
                  `<strong>${itemToDelete.name}</strong> has been deleted.`
                );
                broadcast(channel as string, msg);
                setItemToDelete(null);
              }}
              itemToDelete={itemToDelete}
              close={closeDeleteModal}
              isUnsubscribe={itemToDelete.ownerId !== userId}
              onCancel={closeDeleteModal}
            />
          )}
          {showEditPanel && (
            <Modal
              hasOverlay={!showProgressBar}
              // bodyCustomClass={styles.modal}
              closeIcon={false}
              customCss={{ display: showProgressBar ? "none" : "block" }}
            >
              <DialogEdit
                closeDialog={() => setShowEditPanel(false)}
                product={itemToEdit}
                onDialogOk={productEdit}
                onUpdateBacktesting={productEditBacktesting}
              />
            </Modal>
          )}

          <Box pb={1} width={"10%"} display={"flex"} gap={1}>
            <SortBy sort={sort ?? "name"} setSort={onSortChange} />
            <ChangeViewBtn viewType={view} changeView={handleChangeView} />
          </Box>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <TrendratingTable
                data={products}
                autoResize={true}
                columns={columns}
                eventCallback={handleEventCallback}
                options={{
                  height: "0px",
                  ajaxSorting: false,
                }}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
