import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Search from "../../../../../widgets/ReactSearch/Search";
import { WhiteListPanel } from "../../../../strategies/builder/editors/advancedWidgets/AdvancedFieldControllers/InvestmentUniverseController/widgets/WhiteListPanel/WhiteListPanel";

type Props = {
  strategies: any;
  strategyID: any;
  name: string;
  currency: string;
  benchmark: string | null;
  nameHandler: Function;
  // expenseRatio: any;
  strategyIDHandler: Function;
  // expenseRationHandler: Function;
  currencyHandler: Function;
  benchmarkHandler: Function;
};

export const currencyMap = [
  { value: "local", label: "local" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "CHF", label: "CHF" },
  { value: "CAD", label: "CAD" },
  { value: "JPY", label: "JPY" },
  { value: "AUD", label: "AUD" },
];
export const blendedBenchmarkTag = "COLLECTION";

const BasicInfo = forwardRef(
  (
    {
      strategies,
      strategyID,
      name,
      currency,
      nameHandler,
      strategyIDHandler,
      currencyHandler,
      benchmarkHandler,
      benchmark,
    }: Props,
    ref
  ) => {
    // const instrumentRef = useRef(null);
    const _benchmark = useMemo(() => {
      if (benchmark != null) {
        if (!benchmark?.includes(blendedBenchmarkTag)) {
          return benchmark;
        }
        const blendedValue = benchmark.split(":");
        return blendedValue[1];
      }
      return null;
    }, [benchmark]);

    const [benchmarkRadio, setBenchmarkRadio] = useState<any>(null);
    useEffect(() => {
      if (benchmark != null) {
        if (!benchmark?.includes(blendedBenchmarkTag)) {
          setBenchmarkRadio("benchmark");
        } else {
          setBenchmarkRadio("blended_benchmark");
        }
      } else {
        if (benchmarkRadio == null) {
          setBenchmarkRadio("none");
        }
      }
    }, [benchmark, benchmarkRadio]);

    const handleBanchmarkRadio = useCallback(
      (radioValue) => {
        benchmarkHandler(null);
        setBenchmarkRadio(radioValue);
      },
      [benchmarkHandler]
    );
    const BlendedBenchmarkDropDownHandler = useCallback(
      (value) => {
        benchmarkHandler(`${blendedBenchmarkTag}:${value}`);
      },
      [benchmarkHandler]
    );
    const instrumentSearchHandler = useCallback(
      (e) => {
        if (e) {
          benchmarkHandler(e.symbol);
        } else {
          benchmarkHandler(null);
        }
      },
      [benchmarkHandler]
    );

    const [isNameValid, setIsNameValid] = useState(true);
    const validateName = useCallback((name: string) => {
      if (name.length > 0) {
        setIsNameValid(true);
        return;
      }
      setIsNameValid(false);
    }, []);
    useImperativeHandle(
      ref,
      () => ({
        validateName: (isValid) => {
          setIsNameValid(isValid);
        },
      }),
      []
    );

    return (
      <Card sx={{ flex: 1, boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box pb={1}>
            <Typography sx={{ lineHeight: 1 }} variant="subtitle1">
              Systematic portfolio
            </Typography>
          </Box>
          <Divider
            sx={{ width: "100%", display: "block", m: 0 }}
            variant="middle"
          />
          <Box display={"flex"} flexDirection={"column"} gap={1} pt={1}>
            <Box display={"flex"} gap={2}>
              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Name:</Typography>
                <TextField
                  error={!isNameValid}
                  helperText={!isNameValid && <>Insert a valid name!</>}
                  value={name ?? ""}
                  onChange={(e) => {
                    validateName(e.target.value);
                    nameHandler(e.target.value);
                  }}
                  sx={{ fontSize: "0.7vw" }}
                  size="small"
                />
              </Box>
              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Strategy:</Typography>
                <Select
                  size="small"
                  onChange={(e) => {
                    strategyIDHandler(e.target.value);
                  }}
                  value={strategyID ?? "-"}
                >
                  <MenuItem value={"-"} disabled>
                    <Typography>Select a strategy</Typography>
                  </MenuItem>
                  {strategies.map((strategy, index) => (
                    <MenuItem key={index} value={strategy.value}>
                      <Typography>
                        <span
                          dangerouslySetInnerHTML={{ __html: strategy.label }}
                        ></span>
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display={"flex"} gap={2}>
              <Box flex={2} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Benchmark:</Typography>
                <RadioGroup
                  value={benchmarkRadio}
                  onChange={(e) => handleBanchmarkRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                  <FormControlLabel
                    value="benchmark"
                    control={<Radio />}
                    label={
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography display={"flex"} alignItems={"center"}>
                          Benchmark
                        </Typography>
                        {benchmarkRadio === "benchmark" && (
                          <Search
                            value={_benchmark ?? undefined}
                            hasRecents={false}
                            showInstrumentInfoOnSelect={false}
                            onSelectInstrument={instrumentSearchHandler}
                          />
                        )}
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="blended_benchmark"
                    control={<Radio />}
                    label={
                      <Box display={"flex"} flexDirection={"row"} gap={1}>
                        <Typography display={"flex"} alignItems={"center"}>
                          Blended benchmark
                        </Typography>
                        {benchmarkRadio === "blended_benchmark" && (
                          <WhiteListPanel
                            closeIcon={true}
                            showBasket={false}
                            setWhiteListValue={BlendedBenchmarkDropDownHandler}
                            initValue={_benchmark ? parseInt(_benchmark) : null}
                          />
                        )}
                      </Box>
                    }
                  />
                </RadioGroup>
              </Box>
              <Box flex={1} gap={1} display={"flex"} flexDirection={"column"}>
                <Typography variant="tr_subtitle_blue">Currency:</Typography>
                <Select
                  size="small"
                  onChange={(e) => {
                    currencyHandler(e.target.value);
                  }}
                  value={currency ?? "-"}
                >
                  <MenuItem disabled value={"-"}>
                    <Typography>Select a currency</Typography>
                  </MenuItem>
                  {currencyMap.map((currency, index) => (
                    <MenuItem key={index} value={currency.value}>
                      <Typography>
                        <span
                          dangerouslySetInnerHTML={{ __html: currency.label }}
                        ></span>
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
);
export default BasicInfo;

// type BlendedBenchmarkDropDownType = {
//   value: any;
//   onChangeDropDown: any;
// };
// export const BlendedBenchmarkDropDown = ({
//   value,
//   onChangeDropDown,
// }: BlendedBenchmarkDropDownType) => {
//   const [data, setData] = useState([]);
//   const environment = useEnvironment();
//   const appEnvironment = environment.get("setup");
//   const apiLists = useMemo(() => new Lists(appEnvironment), [appEnvironment]);

//   const getLists = useCallback(async () => {
//     const response = await apiLists.get();
//     let _data = response?.["data"] ?? [];
//     _data.sort((a, b) => a.type.localeCompare(b.type));
//     setData(_data);
//   }, [apiLists]);
//   useEffect(() => {
//     getLists();
//   }, [getLists]);

//   return (
//     <Select
//       value={value ?? 0}
//       onChange={(e) => {
//         const id = e.target.value;
//         onChangeDropDown(id);
//       }}
//       size="small"
//     >
//       <MenuItem disabled value={0}>
//         <Typography>Load</Typography>
//       </MenuItem>
//       {data.map((item: any) => (
//         <MenuItem key={item.id} value={item.id}>
//           <Typography>
//             <strong>
//               {item.type === "PORTFOLIO" ? "Portfolio" : "Basket"}
//             </strong>{" "}
//             - {item.name}{" "}
//             {item.isReadOnly && (
//               <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
//             )}
//           </Typography>
//         </MenuItem>
//       ))}
//     </Select>
//   );
// };
