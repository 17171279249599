import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../../components/Spinner/Spinner";
import { _rate } from "../../../../../../trendrating/formatter/_rate";
import { PeerTableBody } from "./PeerTableBody";
import PeerTableHead from "./PeerTableHead";

type PeerTableProps = {
  data: any;
  onCellClick: (peerId) => any;
  storage: any;
  setFCellDims: (dims) => any;
  getConstraints: (state) => void;
  options: (type) => any[];
  sortWhatDefault: any;
  whatDefault: any;
};

type TableConfig = {
  params: any;
  tableDataBody: any;
  tableDataHead: any;
};

const baseClass = "tPeerTable";

const prepareTooltipData = (params, peer, t) => {
  var timeframe = params["timeframe"];

  var tooltipData = {
    id: peer["id"],
    cardinality: peer["info"]["cardinality"],
    instrumentsAlert: {
      downgrades: peer["statistic"]["downgrades"][timeframe]["percentage"],
      downgradesCardinality:
        peer["statistic"]["downgrades"][timeframe]["number"],
      upgrades: peer["statistic"]["upgrades"][timeframe]["percentage"],
      upgradesCardinality: peer["statistic"]["upgrades"][timeframe]["number"],
    },
    dispersion: peer["statistic"]["dispersion"],
    instrumentsRating: {
      ab: peer["statistic"]["abPercentage"][timeframe],
      cd: peer["statistic"]["cdPercentage"][timeframe],
    },
    marketcap: peer["info"]["marketcap"],
    name: peer["name"] === "__ROOT__" ? t("World_wide") : peer["name"],
    changeReversal: {
      from:
        timeframe === "today"
          ? peer["tcr"]["yesterday"]
          : peer["tcr"][timeframe],
      to: peer["tcr"]["today"],
    },
    tcr: peer["tcr"]["today"],
    timeframe: timeframe,
  };

  return tooltipData;
};

const getTooltipData = async (peerId, storage, params, t) => {
  const response = await storage.getById(peerId);

  return prepareTooltipData(params, response, t);
};

/**
 * It decorates the node with the CSS classes according to data and
 * cellType
 *
 * @param {object}      data - the peer data
 * @param {HTMLElement} node - the cell of the table
 * @param {string}      cellType - one of "cell", "cellWhat" or
 *      "cellWhere". Default "cell"
 *
 * @ignore
 */
const dataDisplayCellDecorator = (data, cellType) => {
  cellType = cellType == null ? "cell" : cellType;

  var tcr = data["tcr"]["today"];
  var tcrCss = _rate["trendCaptureRating"]["U"];

  if (tcr != null) {
    tcrCss = _rate["trendCaptureRating"][tcr];
  }

  var cssClassCell = baseClass + "-tableCell";
  var cssClassCellTcrPrefix = baseClass + "-tableCell--tcr-";
  var cssClassCellWhat = baseClass + "-tableCell--what";
  var cssClassCellWhere = baseClass + "-tableCell--where";

  var cssClasses = [cssClassCell];

  switch (cellType) {
    case "cellWhat": {
      cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);
      cssClasses.push(cssClassCellWhat);

      break;
    }
    case "cellWhere": {
      cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);
      cssClasses.push(cssClassCellWhere);

      break;
    }
    case "cell": {
      cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);

      break;
    }
    case "cellEmpty":
    default: {
    }
  }

  return cssClasses.join(" ");
};

export const PeerTable = ({
  data,
  storage,
  onCellClick,
  setFCellDims,
  getConstraints,
  options,
  sortWhatDefault,
  whatDefault,
}: PeerTableProps) => {
  const [tableConfigData, setTableConfigData] = useState<TableConfig>({
    params: null,
    tableDataBody: null,
    tableDataHead: null,
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const [isReflowing, setIsReflowing] = useState(true);

  /**
   * Get table setup from data when they are ready
   */
  useEffect(() => {
    if (data != null) {
      setTableConfigData({
        params:
          data == null || data["paramsTable"] == null
            ? null
            : data["paramsTable"],
        tableDataBody:
          data == null || data["table"] == null ? null : data["table"]["body"],
        tableDataHead:
          data == null || data["table"] == null ? null : data["table"]["head"],
      });
    }
  }, [data]);

  useEffect(() => {
    const isReflowing =
      data == null ||
      tableConfigData.tableDataBody == null ||
      tableConfigData.tableDataHead == null;

    if (!isReflowing) {
      const isTableEmpty =
        tableConfigData.tableDataBody.length === 0 ||
        tableConfigData.tableDataHead.length === 0;

      setIsEmpty(isTableEmpty);
    }

    setIsReflowing(isReflowing);
  }, [data, tableConfigData.tableDataBody, tableConfigData.tableDataHead]);

  return isReflowing ? (
    <Spinner />
  ) : (
    <div className="tPeerTable">
      <table className="tPeerTable-table" data-dojo-attach-point="elementTable">
        {isEmpty ? (
          <EmptyTable />
        ) : (
          <>
            <PeerTableHead
              sortWhatDefault={sortWhatDefault}
              whatDefault={whatDefault}
              onCellClick={onCellClick}
              data={tableConfigData.tableDataHead}
              storage={storage}
              params={tableConfigData.params}
              decorator={(data, cellType) =>
                dataDisplayCellDecorator(data, cellType)
              }
              getTooltipData={getTooltipData}
              setFCellDims={setFCellDims}
              options={options}
              getConstraints={getConstraints}
            />
            <PeerTableBody
              data={tableConfigData.tableDataBody}
              params={tableConfigData.params}
              storage={storage}
              decorator={(data, cellType) =>
                dataDisplayCellDecorator(data, cellType)
              }
              getTooltipData={getTooltipData}
              onCellClick={onCellClick}
            />
          </>
        )}
      </table>
    </div>
  );
};

const EmptyTable = () => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr>
        <th>{t("no_data_to_display")}</th>
      </tr>
    </thead>
  );
};
