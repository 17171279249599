/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplatePreferenceRank
 * @summary Requests for rank preferences and templates
 *
 */

import { AppEnvironment } from "../../types/Defaults";
import { deepClone } from "../../deepClone";
import { _StoredObjects } from "../_StoredObjects";
import { RankingUi2Api } from "./RankingUi2Api";

export class TemplatePreferenceRank extends _StoredObjects {
  rankingUi2Api;
  storedObjectType = "PREFERENCE_RANK" as const;

  constructor(environment: AppEnvironment) {
    super(environment);
    this.rankingUi2Api = new RankingUi2Api(this.environment);
  }

  /**
   * transform raw data and prepare data suitable for UI
   */
  decode(object: any) {
    const _object = deepClone(object);
    _object["configuration"] = {
      ranking: this.rankingUi2Api.decode(_object["configuration"]),
    };

    return _object;
  }

  /**
   * transform UI data and prepare data suitable for server
   */
  encode(object: any) {
    const _object = deepClone(object);
    _object["configuration"] = this.rankingUi2Api.encode(
      _object["configuration"]["ranking"]
    );

    return _object;
  }

  /**
   * Retrieves table template for screening
   *
   * @param {object} params - request parameters
   * @param {object} params.user - a trendrating user
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async get(id?: number) {
    const response = await super.get(id);

    if (Array.isArray(response) === true) {
      const templates: any = [];

      for (let i = 0, length = response.length; i < length; i++) {
        templates.push(this.decode(response[i]));
      }

      return templates;
    } else {
      return this.decode(response);
    }
  }

  /**
   * Saves a ranking template
   *
   * @param {object} params - request parameters
   * @param {object} params.configuration - a ranking configuration
   * @param {object} params.foreignId - a foreign id reference to another template
   *                                   used for getting the last used template
   * @param {number} params.id - if specified it means that the template
   *       already exits and has been modified. Otherwise a new template
   *       will be crated
   * @param {string} params.name - name of the template
   * @param {object} params.user - a trendrating user
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async save(object: any) {
    const encoded = this.encode(object);

    let response;
    if (object["id"] == null) {
      response = await this.create(encoded);
    } else {
      response = await this.update(encoded);
    }

    return this.decode(response);
  }
}
