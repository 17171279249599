export type AlternativesState = {
    portfolio: any;
    actualList: any[];
    focusedSecurity: any;
    swapList: { sell: any[]; buy: any[] };
    positions: any[];
    constraints: {
        assetClassChecked: boolean;
        icbChecked: boolean;
        marketCapChecked: boolean;
        domicileChecked: boolean;
        countryChecked: boolean;
        etfGeoChecked: boolean;
        specialtyChecked: boolean;
        industryChecked: boolean;
    };
};

export type Action =
    | { type: "SET_PORTFOLIO"; payload: any }
    | { type: "SET_ACTUAL_LIST"; payload: any[] }
    | { type: "SET_FOCUSED_SECURITY"; payload: any }
    | { type: "ADD_TO_SWAP_LIST"; payload: { sell: any; buy: any } }
    | { type: "SET_POSITIONS"; payload: any }
    | { type: "REMOVE_FROM_SWAP_LIST"; payload: string }
    | { type: "UPDATE_CONTRAINTS"; payload: { key: string; value: boolean } }
    | { type: "CLEAR_SWAP_LIST" };

export const defaultAlternativesState: AlternativesState = {
    portfolio: null,
    actualList: [],
    focusedSecurity: null,
    swapList: { sell: [], buy: [] },
    positions: [],
    constraints: {
        assetClassChecked: true,
        icbChecked: true,
        marketCapChecked: false,
        domicileChecked: false,
        countryChecked: true,
        etfGeoChecked: true,
        specialtyChecked: true,
        industryChecked: true,
    },
};

export const reducerAlternatives = (
    draft: AlternativesState,
    action: Action
) => {
    switch (action.type) {
        default:
            return draft;

        case "SET_PORTFOLIO": {
            draft.portfolio = action.payload;

            break;
        }

        case "SET_ACTUAL_LIST": {
            draft.actualList = action.payload;

            break;
        }

        case "SET_FOCUSED_SECURITY": {
            draft.focusedSecurity = action.payload;

            break;
        }

        case "UPDATE_CONTRAINTS":
            const { key, value } = action.payload;

            if (
                (key === "icbChecked" || key === "assetClassChecked") &&
                value === false
            ) {
                if (key === "icbChecked") {
                    draft.constraints.industryChecked = false;
                }

                if (key === "assetClassChecked") {
                    draft.constraints.specialtyChecked = false;
                }

                draft.constraints.assetClassChecked = true;
            }

            draft.constraints[key] = value;
            break;

        case "ADD_TO_SWAP_LIST": {
            draft.swapList.buy.push(action.payload.buy);
            draft.swapList.sell.push({
                swapId: action.payload.buy.symbol,
                item: action.payload.sell[0],
            });

            break;
        }

        case "SET_POSITIONS": {
            draft.positions = action.payload;

            break;
        }

        case "REMOVE_FROM_SWAP_LIST":
            draft.swapList.buy = draft.swapList.buy.filter(
                (security) => security.symbol !== action.payload
            );
            draft.swapList.sell = draft.swapList.sell.filter(
                (sellItem) => sellItem.swapId !== action.payload
            );

            // If the list is empty return to initial state
            if (draft.swapList.buy.length === 0) {
                draft.focusedSecurity = null;
            }

            break;

        case "CLEAR_SWAP_LIST":
            draft.swapList.buy = [];
            draft.swapList.sell = [];

            break;
    }
};
