import { RefObject, useCallback, useLayoutEffect, useRef } from "react";

function position(node: any, includeScroll: boolean) {
    const boundingRect = node.getBoundingClientRect();
    let ret = {
        x: boundingRect.left,
        y: boundingRect.top,
        w: boundingRect.width,
        h: boundingRect.height,
    };
    if (includeScroll) {
        ret.x += window.scrollX ?? window.pageXOffset;
        ret.y += window.scrollY ?? window.pageYOffset;
    }
    return ret;
}

type useResizerProps = {
    ref?: RefObject<HTMLElement>;
    handler?: Function;
    correction?: number;
    isEnable?: boolean;
    trigger?: any;
};

/**
 *
 * @param trigger is used for refresh resizer externally, pass an empty object
 */
export function useResizer({
    ref,
    handler,
    correction = 8,
    isEnable = true,
    trigger,
}: useResizerProps) {
    const resizingId = useRef<NodeJS.Timeout | undefined>();

    const handleResize = useCallback(
        (event) => {
            if (isEnable) {
                if (event) {
                    event.stopPropagation();
                }

                if (resizingId.current) {
                    clearTimeout(resizingId.current);
                }

                resizingId.current = setTimeout(() => {
                    if (ref?.current != null) {
                        const node = ref.current;

                        var viewport = {
                            l: window.scrollX ?? window.pageXOffset,
                            t: window.scrollY ?? window.pageYOffset,
                            w: document.documentElement.clientWidth,
                            h: document.documentElement.clientHeight,
                        };

                        var domNodePos = position(node, true);

                        var footerPos = position(
                            document.getElementById("app-footer"),
                            false
                        );

                        const height =
                            viewport.h -
                            domNodePos.y -
                            footerPos.h -
                            correction;

                        node.style.height = height + "px";

                        node.style.overflowX = "hidden";
                        node.style.overflowY = "auto";
                    }

                    if (handler) {
                        handler();
                    }
                }, 200);
            }
        },
        [correction, handler, isEnable, ref]
    );

    useLayoutEffect(() => {
        if (isEnable) {
            window.addEventListener("resize", handleResize, { passive: true });

            // First load
            handleResize(null);
            if (ref?.current) {
                ref.current.style.removeProperty("height");
                ref.current.style.removeProperty("overflowX");
                ref.current.style.removeProperty("overflowY");
            }

            return () => window.removeEventListener("resize", handleResize);
        }
    }, [handleResize, isEnable, ref]);

    useLayoutEffect(() => {
        handleResize(null);
    }, [handleResize, trigger]);
}
