import { useMemo } from "react";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../../api/compute/Taxon";
import { Chart } from "../../../../../../../components/Chart";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Cluster } from "./ComparisonTableWidget";

type PieChartWidgetProps = {
  dataSerie: any;
  name: string;
  positionsType: string;
  selector: Cluster;
};

const getFieldFromSelector = (selector) => {
  switch (selector) {
    case "1 Industry":
      return "sector";
    case "Area":
    case "Region":
    case "Country":
      return "country";
    case "3 Level":
      return "SizeClassification";
    case "3 Sector":
      return "industry";
    case "etfgeo":
      return "etfgeo";
    case "Specialty":
    case "AssetClass":
      return "etfclass";

    default:
      return "country";
  }
};

export function PieChartWidget({
  dataSerie,
  name,
  positionsType,
  selector,
}: PieChartWidgetProps) {
  const environment = useEnvironment();
  const taxonomies = environment.get("rawTaxonomies");
  const fieldsMap = environment.get("taxonomyFields");

  const taxonomy = useMemo(() => {
    if (selector) {
      return selector === "3 Level"
        ? taxonomies[getFieldFromSelector(selector)]
        : taxonomies[
            fieldsMap[positionsType === "ETF" ? "ETF" : "security"][
              getFieldFromSelector(selector)
            ]
          ];
    }
  }, [fieldsMap, positionsType, selector, taxonomies]);

  const data = useMemo(() => dataSerie, [dataSerie]);

  const chartOptions = useMemo(
    () => ({
      navigator: { enabled: false },
      rangeSelector: { enabled: false },
      chart: {
        animation: false,
        margin: [20, 0, 0, 0],
        spacing: [0, 0, 0, 0],
        type: "pie",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        formatter: function (this) {
          const id = this.point.trendrating.id;
          const type = this.point.trendrating.type;

          const segment =
            selector !== "Type" && selector !== "Currency"
              ? formatTaxonPrefixingParent(
                  getTaxonById(id, [taxonomy], null),
                  [taxonomy],
                  type === "where" ? "Region" : "3 Sector"
                )
              : id;
          const weight = this.percentage.toFixed(2);

          return `${segment}: ${weight}%`;
        } as Function,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          borderColor: "#FFFFFF",
          borderWidth: 1,
          center: ["50%", "50%"],
          dataLabels: {
            enabled: false,
          },
          shadow: false,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      series: [
        {
          innerSize: "60%",
          type: "pie",
          name: "Rating",
          animation: false,
          data: data,
        },
      ],
      title: {
        text: `${name}`,
        style: {
          fontSize: "16px",
        },
      },
    }),
    [data, name, selector, taxonomy]
  );

  return (
    <div className="piechart-widget-wrapper">
      {data.length ? (
        <Chart constructorType="chart" options={chartOptions} />
      ) : (
        <p style={{ textAlign: "center" }}>Unclassified</p>
      )}
    </div>
  );
}
