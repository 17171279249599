import { ClusterAnalytics } from "../../../../../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import { AppEnvironment } from "../../../../../../../types/Defaults";

const PORTFOLIO_CONFORMATION = "assetTypes";
const EXPIRED_STOCK_KEY = "ExpiredStock";

export class AvoidableLossHelper {
  private portfolioId: string;
  private apiInstruments: Instruments;
  private apiCluster: ClusterAnalytics;
  private apiLists: Lists;
  private listType: "PORTFOLIO" | "BASKET";

  constructor(
    environment: AppEnvironment,
    id: string,
    listType: "PORTFOLIO" | "BASKET"
  ) {
    this.portfolioId = id;
    this.listType = listType;
    this.apiInstruments = new Instruments(environment);
    this.apiCluster = new ClusterAnalytics(environment);
    this.apiLists = new Lists(environment);
  }

  private async fetchAssetType() {
    return await this.apiLists.portfolioFetch(
      [parseInt(this.portfolioId)],
      [PORTFOLIO_CONFORMATION]
    );
  }

  async getLoss() {
    const data: any = {
      CD: {
        peerAvg: null,
        quartileAvg: {
          1: null,
          2: null,
          3: null,
          4: null,
        },
        securities: [],
        quartilesSymbols: {},
      },
      formatterSectorBy: null,
      portfolioType: "Stock",
      securitiesTotal: 0,
    };

    const analytic = "prr#avg#false";

    const clusters = [
      {
        dimension: "prr",
        transform: {
          function: "quantile",
          params: { n: 4, trimOutliers: false },
        },
      },
    ];

    try {
      let configuration = this.apiCluster
        .createConfiguration()
        .analytics([analytic])
        .clusters(clusters)
        .method("DRILL_DOWN")
        .universeFromFilters(
          [{ dimension: "rc", segments: ["-2", "-1"] }],
          undefined,
          [{ domain: [this.portfolioId], range: this.listType }]
        );

      const result = await configuration.fetchAnalytics();

      const stats = result?.clustersStats?.stats ?? {};

      for (const [key, value] of Object.entries<any>(stats)) {
        const splittedKey = key.split(".");
        const quartileNumber = parseInt(splittedKey["0"]);

        data["CD"].quartileAvg[quartileNumber] = value[analytic];
      }

      const tickersCD: any = [];

      const clustersResult = result?.clustersStats?.clusters ?? {};
      for (const [key, value] of Object.entries<any>(clustersResult)) {
        const splittedKey = key.split(".");

        const quartileAccessor = splittedKey[0];
        value.forEach(
          (symbol) =>
            (data.CD.quartilesSymbols[symbol] = parseInt(quartileAccessor))
        );
        tickersCD.push(...value);
      }

      data["CD"]["securities"] = tickersCD;

      // This is the key that came back from server when no cluster is specified
      // const noSpecifiedClustersKey = "ANY";

      if (tickersCD.length) {
        // configuration = this.apiCluster
        //   .createConfiguration()
        //   .analytics([analytic])
        //   .clusters([])
        //   .method("DRILL_DOWN")
        //   .universeFromInstruments(tickersCD);

        // const cumulativeAvgCD = await configuration.fetchAnalytics();

        // data.CD.peerAvg =
        //   cumulativeAvgCD.clustersStats.stats?.[noSpecifiedClustersKey]?.[
        //     analytic
        //   ] ?? null;

        let avgSum = 0;
        const clustersNumber = 4;

        for (const key in data["CD"].quartileAvg) {
          avgSum += data["CD"].quartileAvg[key];
        }

        let avg: any = avgSum / clustersNumber;
        data.CD.peerAvg = avg;
      }

      data["formatterSectorBy"] = "3 Sector";

      const fetchPortfolioInfo = await this.fetchAssetType();
      const portfolioConstituentsType =
        fetchPortfolioInfo?.[0]?.[PORTFOLIO_CONFORMATION];
      const type = this.getTypeByConformation(portfolioConstituentsType);
      const portfolioType = type === "etf" ? "ETF" : "Stock";
      data["portfolioType"] = portfolioType;

      if (data) {
        return data;
      }

      return undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  getTypeByConformation(assetTypes) {
    const types = assetTypes;

    if (EXPIRED_STOCK_KEY in types) {
      delete types[EXPIRED_STOCK_KEY];
    }

    const isMultiType = Object.keys(assetTypes).length > 1;

    if (!isMultiType) {
      const type = Object.keys(assetTypes)[0];

      return type === "ETF" ? "etf" : "stock";
    } else {
      return "stock";
    }
  }

  async getSecurities(
    type,
    { page, itemsPerPage, sortField, sortDir, symbols },
    quartilesMap
  ) {
    const filterParams = {
      page: {
        page: page,
        rows: itemsPerPage,
      },
      filters: [
        {
          dimension: "symbol",
          segments: symbols,
        },
      ],
      sort: [
        {
          dimension: sortField === "q" ? "prr" : sortField,
          rev: sortDir === "desc",
        },
        {
          dimension: "marketcap",
          rev: false,
        },
      ],
    };

    try {
      const universe = await this.apiInstruments.screening(filterParams);
      const total = universe?.dataTotalCount ?? 0;

      const rows = await this.fetchFields(universe.data, type);

      const securities = (rows?.data ?? []).map((security) => ({
        ...security,
        q: quartilesMap?.[security?.symbol ?? ""] ?? null,
      }));

      return {
        securities,
        total,
      };
    } catch (error: any) {
      throw new Error("Failed to calculate loss due to an unknown error");
    }
  }

  async fetchFields(symbols, type) {
    const properties = [
      { property: "symbol", date: null },
      { property: "prr", date: null },
      { property: "vc", date: null },
      { property: "marketcap", date: null },
      { property: "rc", date: null },
      { property: "name", date: null },
      { property: "ticker", date: null },
      { property: "type", date: null },
      { property: "currency", date: null },
      {
        property: type === "ETF" ? "etfclass" : "icb",
        date: null,
      },
    ];

    return await this.apiInstruments.fetch(
      {
        properties,
        type: "security",
        symbols,
      },
      true
    );
  }
}
