import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Preferences } from "../../../../../../api/account/Preferences";
import { Entity } from "../../../../../../api/compute/Analytics/Analytics";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { useAnalytics } from "../../../../../../hooks/useAnalytics";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { TDate } from "../../../../../../trendrating/date/TDate";
import { removeLoader } from "../../../../utils";
import TrackedItem from "./TrackedItem";
import { useNavigate } from "react-router-dom";
import { config } from "../../../../config-ts";
import { deepClone } from "../../../../../../deepClone";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";

export default function Tracked() {
  const navigate = useNavigate();
  const { broadcast } = useBroadcast();
  const [data, setData] = useState<any>(null);
  const analytics = useAnalytics();
  const environment = useEnvironment();
  //#region - APIs
  const preferencesAPI = useMemo(
    () => new Preferences(environment.get("setup")),
    [environment]
  );
  const strategiesAPI = useMemo(
    () => new Strategies(environment.get("setup")),
    [environment]
  );
  //#endregion
  const trackedIds = useMemo(
    () => preferencesAPI.getTrackedStrategies() ?? [],
    [preferencesAPI]
  );
  //#region - data get and functions
  const getSrategyPriceIndexParams = useCallback((backtestParams, strategy) => {
    let startDate: any = undefined;

    if (
      "includeFromDay" in backtestParams.backtesting &&
      backtestParams.backtesting.includeFromDay != null
    ) {
      startDate = backtestParams.backtesting.includeFromDay;
    } else {
      const today = TDate.today();
      const yearsInDays = backtestParams.backtesting.yearsBack! * 260;
      const offset = 20;

      const daysBack = today - yearsInDays - offset;
      const date = TDate.dateToIso8601(TDate.daysToDate(daysBack));

      startDate = date;
    }

    const strategyCurrency = strategy?.params?.strategy?.currency ?? "local";
    const rebalance = strategy?.params?.strategy?.rebalance ?? "20_DAYS";
    const granularity_dict = {
      "05_DAYS": "WEEKLY",
      "20_DAYS": "MONTHLY",
      "60_DAYS": "QUARTERLY",
    };
    const granularity = granularity_dict[rebalance];

    return { startDate, strategyCurrency, granularity };
  }, []);
  const prepareBenchmarkEntity = useCallback(
    (strategy) => {
      const benchmarkTag = strategy.params.strategy.benchmark;

      if (benchmarkTag) {
        const backtestParams = strategiesAPI.prepareParamsForRun(
          strategy.params,
          undefined
        );

        const { startDate, granularity, strategyCurrency } =
          getSrategyPriceIndexParams(backtestParams, strategy);

        return {
          type: "INSTRUMENT",
          entity: {
            symbol: benchmarkTag,
            params: {
              inceptionDay: backtestParams.pricing.inceptionDay,
              inceptionValue: backtestParams.pricing.inceptionValue,
              method: backtestParams.pricing.method,
              spanGranularity: granularity,
              currency: strategyCurrency,
            },
          },
          includeFromDay: startDate,
        };
      }
    },
    [getSrategyPriceIndexParams, strategiesAPI]
  );
  const wrapAnalyticsResponse = useCallback((response, trackingDate) => {
    const analyticsDict = {
      H: {
        avgPerfSinceDate: `r#null,null,FULL,NONE,${trackingDate},FULL,H`,
        avgPerfDaily: "r#null,null,FULL,DAILY,1,SOLAR,H",
        avgPerfMonhtly: "r#null,null,FULL,MONTHLY,1,SOLAR,H",
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,H",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,H",
        avgPerfYTD: "r#null,null,FULL,YEARLY,1,TD,H",
      },
      B: {
        avgPerfSinceDate: `r#null,null,FULL,NONE,${trackingDate},FULL,B`,
        avgPerfDaily: "r#null,null,FULL,DAILY,1,SOLAR,B",
        avgPerfMonhtly: "r#null,null,FULL,MONTHLY,1,SOLAR,B",
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,B",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,B",
        avgPerfYTD: "r#null,null,FULL,YEARLY,1,TD,B",
      },
      D: {
        avgPerfSinceDate: `r#null,null,FULL,NONE,${trackingDate},FULL,DIFF`,
        avgPerfDaily: "r#null,null,FULL,DAILY,1,SOLAR,DIFF",
        avgPerfMonhtly: "r#null,null,FULL,MONTHLY,1,SOLAR,DIFF",
        avgPerfQuarter: "r#null,null,FULL,MONTHLY,3,SOLAR,DIFF",
        avgPerfYearly: "r#null,null,FULL,YEARLY,1,SOLAR,DIFF",
        avgPerfYTD: "r#null,null,FULL,YEARLY,1,TD,DIFF",
      },
    };

    if (response && response.data) {
      const result = {};

      for (const key in analyticsDict) {
        result[key] = {};

        for (const analyticKey in analyticsDict[key]) {
          result[key][analyticKey] =
            response.data?.[analyticsDict?.[key]?.[analyticKey]] != null
              ? Object.values(
                  response.data?.[analyticsDict?.[key]?.[analyticKey]]
                )?.[0]
              : undefined;
        }
      }

      return result;
    }
  }, []);
  const [isSorted, setIsSorted] = useState(false);
  const checkSort = useCallback((arr) => {
    if (arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (
          arr[i].strategy.name?.localeCompare(arr[i + 1]?.strategy?.name) > 0
        ) {
          setIsSorted(false);
          return;
        }
      }
      setIsSorted(true);
      return;
    }
    setIsSorted(false);
  }, []);
  const _getData = useCallback(
    async (id) => {
      const strategy = await strategiesAPI.getById(id);
      const mainEntity: Entity = { type: "STRATEGY", entity: strategy };
      let hasBenchmark = false;
      let benchmarkEntity: undefined | Entity = undefined;
      const benchmark = strategy.params.strategy.benchmark;
      if (benchmark != null) {
        const isBlended = benchmark.startsWith("COLLECTION:");
        const isNeutralStrategy = benchmark === "TRENDRATING_NEUTRAL_STRATEGY";
        const isNeutralStrategyEqualWeighted =
          benchmark === "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";
        if (
          !isBlended &&
          !isNeutralStrategy &&
          !isNeutralStrategyEqualWeighted
        ) {
          benchmarkEntity = prepareBenchmarkEntity(strategy) as Entity;
          hasBenchmark = true;
        }
      }

      //#region
      let trackingDate: any = new Date(strategy.params.tracking.trackingDate);
      trackingDate = TDate.dateToDays(trackingDate);
      //#endregion
      analytics.clearInstance();
      await analytics.init(mainEntity, benchmarkEntity);
      const analyticsMap = {
        H: [
          `r#null,null,FULL,NONE,${trackingDate},FULL,H`,
          "r#null,null,FULL,DAILY,1,SOLAR,H",
          "r#null,null,FULL,MONTHLY,1,SOLAR,H",
          "r#null,null,FULL,MONTHLY,3,SOLAR,H",
          "r#null,null,FULL,YEARLY,1,SOLAR,H",
          "r#null,null,FULL,YEARLY,1,TD,H",
        ],
        B: [
          `r#null,null,FULL,NONE,${trackingDate},FULL,B`,
          "r#null,null,FULL,DAILY,1,SOLAR,B",
          "r#null,null,FULL,MONTHLY,1,SOLAR,B",
          "r#null,null,FULL,MONTHLY,3,SOLAR,B",
          "r#null,null,FULL,YEARLY,1,SOLAR,B",
          "r#null,null,FULL,YEARLY,1,TD,B",
        ],
        D: [
          `r#null,null,FULL,NONE,${trackingDate},FULL,DIFF`,
          "r#null,null,FULL,DAILY,1,SOLAR,DIFF",
          "r#null,null,FULL,MONTHLY,1,SOLAR,DIFF",
          "r#null,null,FULL,MONTHLY,3,SOLAR,DIFF",
          "r#null,null,FULL,YEARLY,1,SOLAR,DIFF",
          "r#null,null,FULL,YEARLY,1,TD,DIFF",
        ],
      };
      const fields = [...analyticsMap["H"]];
      if (hasBenchmark) {
        fields.push(...analyticsMap["B"], ...analyticsMap["D"]);
      }
      const _data = await analytics.get(fields);
      const _H = await analytics.getCurves("H", "prices");
      const _B = hasBenchmark ? await analytics.getCurves("B", "prices") : null;
      const curves = {
        H: _H,
        B: _B,
      };
      setData((prev) => {
        if (prev?.length === 0 || prev == null) {
          removeLoader();
        }
        if (prev) {
          let temp = [
            ...prev,
            {
              strategy: strategy,
              analytics: wrapAnalyticsResponse(_data, trackingDate),
              curves: curves,
            },
          ];
          checkSort(temp);
          return temp;
        } else {
          let temp = [
            {
              strategy: strategy,
              analytics: wrapAnalyticsResponse(_data, trackingDate),
              curves: curves,
            },
          ];
          checkSort(temp);
          return temp;
        }
      });
    },
    [
      analytics,
      checkSort,
      prepareBenchmarkEntity,
      strategiesAPI,
      wrapAnalyticsResponse,
    ]
  );
  const getData = useCallback(async () => {
    if (trackedIds) {
      for (let i = 0; i < trackedIds.length; i++) {
        await _getData(trackedIds[i]);
        SetNumberOfLoadings((prev) => {
          return prev - 1;
        });
      }
      setWorkflow("s1");
    }
  }, [_getData, trackedIds]);
  const sortDataByName = useCallback(() => {
    let temp = deepClone(data);
    if (!isSorted) {
      // sort by name A -> B, case insensitive
      temp.sort(function (a, b) {
        var nameA = a.strategy.name.toLowerCase();
        var nameB = b.strategy.name.toLowerCase();

        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
    } else {
      temp.sort(function (a, b) {
        var nameA = a.strategy.name.toLowerCase();
        var nameB = b.strategy.name.toLowerCase();

        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
    }
    setData([...temp]);
    setIsSorted(!isSorted);
  }, [data, isSorted]);
  //#endregion

  //#region - loading handling
  const [numberOfLoadings, SetNumberOfLoadings] = useState(
    trackedIds?.length ?? 0
  );
  const loadings = useMemo(() => {
    let arr: any = [];
    for (let i = 0; i < numberOfLoadings; i++) {
      arr.push(
        <Box
          key={i}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ width: "calc(50% - 4px)" }}
        >
          <CircularProgress />
        </Box>
      );
    }
    return arr;
  }, [numberOfLoadings]);
  //#endregion

  //#region - on land
  useEffect(() => {
    if (trackedIds && trackedIds.length > 0) {
      getData();
    } else {
      removeLoader();
    }
  }, [getData, trackedIds]);
  //#endregion

  const onStopTracking = useCallback((id) => {
    setData((prev) => {
      let temp = [...prev];
      temp = temp.filter((item) => item.strategy.id !== id);
      return temp;
    });
  }, []);

  const [workflow, setWorkflow] = useState("s0");
  useEffect(() => {
    const actions: any[] = [];

    switch (workflow) {
      case "s1":
        actions.push({
          componentJSX: (
            <li
              className="menu__item"
              onClick={() => navigate(`/app/strategies/builder/alpha/`)}
            >
              Create strategy
            </li>
          ),
        });
        break;
      default:
        break;
    }

    var message = {
      from: "TRACKED",
      content: {
        actions: actions,
      },
    };
    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, navigate, workflow]);

  return data != null ? (
    <Box
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      gap={1}
      py={1}
    >
      <Box px={1} display={"flex"} justifyContent={"end"}>
        <Button disabled={data && data.length <= 1} onClick={sortDataByName}>
          Sort {!isSorted ? "A -> Z" : "Z -> A"}
        </Button>
      </Box>
      <Box
        alignContent={"baseline"}
        height={"100%"}
        overflow={"auto"}
        px={1}
        py={1}
        display={"flex"}
        gap={1}
        flexWrap={"wrap"}
      >
        {data.map((item, index) => (
          <TrackedItem
            key={index}
            strategy={item.strategy}
            analytics={item.analytics}
            curves={item.curves}
            onStopTracking={onStopTracking}
          />
        ))}
        {loadings}
        {(!data?.length || data?.length === 0) && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
            width={"100%"}
          >
            <Typography>No strategy is tracked!</Typography>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
    >
      {(!data?.length || data?.length === 0) && trackedIds?.length <= 0 && (
        <Typography>No strategy is tracked!</Typography>
      )}
      {(!data?.length || data?.length === 0) && trackedIds?.length > 0 && (
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <CircularProgress />
          <Typography>Loading strategies...</Typography>
        </Box>
      )}
    </Box>
  );
}
