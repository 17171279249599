/**
 * @author Trendrating <info@trendrating.net>
 *
 * @summary Trendrating Chart component
 */

import { parseISO, subMonths, subYears } from "date-fns";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useResizer } from "../../../../../hooks/useResizer";
import { TDate } from "../../../../../trendrating/date/TDate";
import {
  Chart,
  calculateExcessReturn,
  daysToMilliseconds,
  fromJsonToSerieAndMinMaxAndRescale,
  millisecondsToDays,
} from "./Chart";
import { ChartInfo } from "./ChartInfo";
import { RadioButtonBar } from "../../../../../components/RadioButtonBar/RadioButtonBar";
import { Switch } from "@mui/material";

// function rescaleTo(history, historyFromSerie, readaptHistory, pboundaries) {
//     let indexOfFirstDay = 0;
//     for (let i = 0; i < history.length; i++) {
//         if (history[i].d >= readaptHistory[0].d) {
//             indexOfFirstDay = i;
//             break;
//         }
//     }

//     const translationFactor =
//         readaptHistory[0].v / historyFromSerie[indexOfFirstDay][1];

//     // domClass.remove(this._btnScaleNode, "hide");
//     return fromJsonToSerieAndMinMax(
//         readaptHistory,
//         pboundaries,
//         translationFactor
//     );
// }

function startDateFromPeriod(period, lastDate, startDate) {
  let result: number | undefined;
  if (Number.isNaN(startDate) || startDate == null) {
    startDate = 0;
  }
  if (period === "R") {
    return "R" as const;
  } else {
    if (lastDate != null) {
      const quot = Math.floor(lastDate / 5);
      const rem = Math.floor(lastDate % 5);
      const u = Math.floor((quot * 7 + rem + 4) * 86400 * 1000);
      const tmpDate = new Date(u);
      const Y = tmpDate.getFullYear();
      const d = tmpDate.getDate();
      const m = tmpDate.getMonth() + 1;
      const M = m < 10 ? "0" + m : "" + m;
      const D = d < 10 ? "0" + d : "" + d;
      const toStr = Y + "-" + M + "-" + D;
      const to = parseISO(toStr);
      if (to != null) {
        let from: Date;
        switch (period) {
          case "1Y":
            from = subYears(to, 1);
            break;
          case "3Y":
            from = subYears(to, 3);
            break;
          case "5Y":
            from = subYears(to, 5);
            break;
          case "10Y":
            from = subYears(to, 10);
            break;
          case "1M":
            from = subMonths(to, 1);
            break;
          case "3M":
            from = subMonths(to, 3);
            break;
          case "YTD":
            var ytdDate = Y - 1 + "-12-31";
            from = parseISO(ytdDate);
            break;
          case "MAX":
            from = TDate.daysToDate(startDate);
            break;
          default:
            from = to;
        }
        if (from != null) {
          const milliseconds = from.getTime();
          const seconds = milliseconds / 1000;
          const t = ~~(seconds / 86400) - 4;
          const quot = ~~(t / 7);
          const rem = ~~(t % 7);
          const d = quot * 5 + rem;
          result = Math.max(d, startDate);
        }
      }
    }
  }
  return result;
}

/**
 *
 * @param data Expect data sorted from old to new
 * @param lastOnly
 * @param startDate
 * @returns
 */
function fromJsonToRating(
  data: CurvePoint[],
  lastOnly: boolean,
  startDate
): Highcharts.SeriesFlagsOptions["data"] {
  const rateColor = {
    NA: "#8f4b4b",
    A: "#008000",
    B: "#8BBC00",
    C: "#F48400",
    D: "#F00000",
  };
  let serie: any = [];
  if (startDate == null) {
    startDate = 0;
  }
  let current = data[data.length - 1];
  if (current.r == null) {
    return serie;
  }
  // Start from end (minus the actual item).
  for (let i = data.length - 2; i >= 0; i--) {
    const item = data[i];
    // If there is no rating, just get the next value
    if (item.r == null) {
      continue;
    }
    // Ignore data that is previous the start date we want
    if (item.d < startDate) {
      continue;
    }
    // Change of rating
    if (item.r !== current.r) {
      let label = "";
      switch (current.r) {
        case 2:
          label = "A";
          break;
        case 1:
          label = "B";
          break;
        case -1:
          label = "C";
          break;
        case -2:
          label = "D";
          break;
        default:
          label = "NA";
          break;
      }
      serie.push({
        x: daysToMilliseconds(current.d),
        title: label,
        text: label,
        fillColor: rateColor[label],
        type: "flags",
      });
    }
    if (lastOnly && item.r * current.r! < 0) {
      break;
    }
    current = item;
  }
  // Resort correctly
  serie.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));
  return serie;
}

export type CurveType = "default" | "benchmark" | "long" | "short" | "excess";

export type CurvePoint = {
  d: number; // work-days, like 13000
  v: number; // value
  t?: number;
  r?: number; // rating
};

export type Curve = {
  data: any;
  name: string;
  prices: CurvePoint[];
  id: string;
  type: CurveType;
  currency?: string;
};

export type HighchartCurve = Curve & {
  color: string;
  serie: Highcharts.SeriesLineOptions["data"];
  ratingSerie: Highcharts.SeriesFlagsOptions["data"];
};

const chartColorCounter = {
  default: 0,
  long: 0,
  short: 0,
};

function getChartColor(type: CurveType, index: number = 0) {
  const primaryColor = "#2a7092";
  const benchmarkColor = "#ffcc00";
  const colors = [
    "#FF8000", // orange
    "#116160", // green
    "#F9468C", // pink
    "#00BEB9", // cyan
    "#863E85", // purple
  ];
  const combineColors = {
    long: ["#008000", "#8bbc00", "#0da760"],
    short: ["#f00000", "#f48400", "#934E00"],
  };
  const excessColor = "#23BF0C";

  switch (type) {
    case "benchmark":
      return benchmarkColor;
    case "long": {
      return combineColors.long[
        chartColorCounter.long++ % combineColors.long.length
      ];
    }
    case "short": {
      return combineColors.short[
        chartColorCounter.short++ % combineColors.short.length
      ];
    }
    case "excess":
      return excessColor;
    case "default":
    default: {
      if (index === 0) {
        return primaryColor;
      } else {
        return colors[chartColorCounter.default++ % colors.length];
      }
    }
  }
}

function prepareData(
  enableExcess: boolean,
  enableRating: boolean,
  selectedPeriod: Period | undefined,
  valueSorted: Curve[],
  lastRating: boolean,
  excessCurveName?: string
) {
  if (valueSorted.length === 0) {
    return {
      // benchmark,
      isExcessAvailable: false,
      canHistoryBeCut: false,
      hasScaleToPrice: false,
      curves: [],
      startDate: null,
    };
  }
  // or check value?.benchmark.name?
  // const hasBenchmark = value?.params?.benchmark != null ?? false;

  // Reset sort on prices
  try {
    let startDate = startDateFromPeriod(
      selectedPeriod,
      valueSorted[0].prices[valueSorted[0].prices.length - 1].d,
      valueSorted[0].prices[0].d
    );

    let showOnlyLastRating = false;
    let enableRatingOnlyForFirstCurve = !enableRating;

    if (enableRating && lastRating) {
      showOnlyLastRating = true;
    }

    if (startDate === "R") {
      enableRating = true;
      showOnlyLastRating = false;
      enableRatingOnlyForFirstCurve = true;
      var lastDay = valueSorted[0].prices[valueSorted[0].prices.length - 1];
      if (lastDay.r === undefined) {
        // unrated sec: resta come era;
      } else {
        let valueSortedLength = valueSorted[0].prices.length;
        for (let i = valueSortedLength - 1; i >= 0; i--) {
          // cerco il primo giorno di segno opposto e allungo di 5
          let day = valueSorted[0].prices[i];
          if (day.r == null || day.r * lastDay.r <= 0) {
            startDate =
              valueSorted[0].prices[Math.min(valueSortedLength - 1, i - 5)].d;
            break;
          }
        }
      }
    }

    const crossAtPoint = valueSorted[0].prices.find(
      (price) => price.d === startDate
    );

    // Reset color counter
    chartColorCounter.default = 0;
    chartColorCounter.long = 0;
    chartColorCounter.short = 0;

    // Prepare curves, first one is the master
    const curves: HighchartCurve[] = valueSorted.map((curve, index) => ({
      ...curve,
      color: getChartColor(curve.type, index),
      serie: fromJsonToSerieAndMinMaxAndRescale(
        curve.prices,
        valueSorted[0].prices,
        crossAtPoint,
        startDate
      ),
      ratingSerie:
        enableRating &&
        (!enableRatingOnlyForFirstCurve ||
          (enableRatingOnlyForFirstCurve && index === 0))
          ? fromJsonToRating(curve.prices, showOnlyLastRating, startDate)
          : [],
    }));

    let canHistoryBeCut = false;

    // Find if there is benchmark
    const benchmarkCurve = curves.find((curve) => curve.type === "benchmark");
    // const benchmarkCurveIndex = curves.findIndex(
    //     (curve) => curve.type === "benchmark"
    // );

    let isExcessAvailable = false;
    if (enableExcess) {
      let excessPrices;
      if (benchmarkCurve !== undefined && benchmarkCurve.prices.length > 0) {
        excessPrices = calculateExcessReturn(
          curves[0].prices,
          benchmarkCurve.prices
        );

        curves.splice(2, 0, {
          data: null,
          name: excessCurveName ?? "excess",
          prices: excessPrices,
          color: getChartColor("excess"),
          serie: fromJsonToSerieAndMinMaxAndRescale(
            excessPrices,
            curves[0].prices,
            crossAtPoint,
            startDate
          ),
          id: "excess",
          ratingSerie: [],
          type: "excess",
        });
        isExcessAvailable = true;
      }
    }

    const result = {
      // benchmark,
      isExcessAvailable,
      canHistoryBeCut,
      curves,
      startDate: startDate ?? curves?.[0]?.prices?.[0].d,
    };

    return result;
  } catch (error) {
    console.log(error);
    return {
      // benchmark,
      isExcessAvailable: false,
      canHistoryBeCut: false,
      hasScaleToPrice: false,
      curves: [],
      startDate: null,
    };
  }
}

type Period = "R" | "1Y" | "3Y" | "5Y" | "10Y" | "1M" | "3M" | "YTD" | "MAX";

type PeriodOption = {
  index?: number;
  label: string;
  selected?: boolean;
  value: Period;
};

function calculatePeriodOptions(priceList, selectedPeriod, excludePeriodList) {
  if (priceList == null) {
    return [];
  }

  let selectOptions: PeriodOption[] = [];
  var len = priceList.length;

  if (len > 5 * 260) {
    selectOptions.push({
      label: "10Y",
      value: "10Y",
      index: 100,
    });
  }
  if (len > 3 * 260) {
    selectOptions.push({ label: "5Y", value: "5Y", index: 50 });
  }
  if (len > 1 * 260) {
    selectOptions.push({ label: "3Y", value: "3Y", index: 30 });
  }
  if (len > 3 * 20) {
    selectOptions.push({ label: "1Y", value: "1Y", index: 10 });
  }
  if (len > 1 * 20) {
    selectOptions.push({ label: "3M", value: "3M", index: 3 });
  }
  if (len > 1) {
    selectOptions.push({ label: "1M", value: "1M", index: 1 });
  }
  selectOptions.push({ label: "YTD", value: "YTD", index: 9 });

  selectOptions.push({
    label: "MAX",
    value: "MAX",
    index: 999,
  });

  selectOptions.push({
    label: "Rating",
    value: "R",
    index: 9999,
  });

  selectOptions.sort((a: any, b: any) =>
    a["index"] < b["index"] ? -1 : a["index"] > b["index"] ? 1 : 0
  );
  if (selectedPeriod != null) {
    selectOptions.forEach((selectOption) => {
      if (selectOption.value === selectedPeriod) {
        selectOption.selected = true;
      }
    });
  }

  if (excludePeriodList != null) {
    return selectOptions.filter(
      (period) => !excludePeriodList.includes(period.value)
    );
  }

  return selectOptions;
}

type TrendratingChartProps = {
  children?: ReactNode;
  enableExcess?: boolean;
  enableRating?: boolean;
  defaultPeriod?: Period;
  measureFunction?: (data: any) => void;
  tooltipFunction?: (data: any) => void;
  hasMeasure?: boolean;
  hasTooltip?: boolean;
  value: Curve[];
  excludePeriod?: Period[];
  excessCurveName?: string;
  autoResize?: boolean;
  height?: string | number | null;
  lastRating?: boolean;
  enableLegend?: boolean;
  fitContentInModal?: boolean;
  resizeCorrection?: number;
};

export function TrendratingChart({
  children,
  enableExcess = false,
  enableRating = false,
  defaultPeriod = "1Y",
  measureFunction = undefined,
  tooltipFunction = undefined,
  hasMeasure = false,
  hasTooltip = false,
  value,
  excludePeriod,
  excessCurveName,
  autoResize = false,
  height = null,
  lastRating = false,
  enableLegend = true,
  fitContentInModal = false,
  resizeCorrection,
}: TrendratingChartProps) {
  const chartInfoRef = useRef<any>(null);
  const [curves, setCurves] = useState<HighchartCurve[]>();
  const [isLogarithmic, setLogarithmic] = useState(true);
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  const [isExcessAvailable, setIsExcessAvailable] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(defaultPeriod);
  const [periodOptions, setPeriodOptions] = useState<PeriodOption[]>([]);
  const [valueSorted, setValueSorted] = useState<Curve[]>();

  const chartRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  //********/
  // const [chartMeasureDialogIsVisible, setChartMeasureDialogIsVisible] =
  //     useState(false);
  // const [chartMeasureValue, setChartMeasureValue] = useState<Object>();
  //********/

  useResizer({
    isEnable: autoResize,
    ref: containerRef,
    correction: resizeCorrection,
    handler: () => {
      if (chartRef.current) {
        setTimeout(() => chartRef.current.reflow(), 0);
      }
    },
  });

  // Pre-sort input
  useEffect(() => {
    setValueSorted(
      value.map((curve) => ({
        ...curve,
        prices: [...curve.prices].sort((a, b) =>
          a.d > b.d ? 1 : b.d > a.d ? -1 : 0
        ),
      }))
    );
  }, [value]);

  // useEffect(() => {
  //     // clean up of all open dialogs
  //     return () => {
  //         // setChartMeasureDialogIsVisible(false);
  //     };
  // }, []);

  useEffect(() => {
    if (valueSorted === undefined) {
      return; // Not ready
    }

    setPeriodOptions(
      calculatePeriodOptions(
        valueSorted?.[0]?.prices,
        selectedPeriod,
        excludePeriod
      )
    );

    const excessLabel =
      excessCurveName !== undefined ? excessCurveName : undefined;

    const { curves, isExcessAvailable } = prepareData(
      enableExcess,
      enableRating,
      selectedPeriod,
      valueSorted,
      lastRating,
      excessLabel
    );
    setIsExcessAvailable(isExcessAvailable);
    setCurves(curves);
  }, [
    enableExcess,
    enableRating,
    selectedPeriod,
    valueSorted,
    excessCurveName,
    excludePeriod,
    lastRating,
  ]);

  const dayAtDate = useCallback(
    (id, date, returnNullIfEmpty) => {
      const prices = curves?.find((curve) => curve.id === id)?.prices ?? [];

      for (let i = 0; i < prices.length; i++) {
        // Direction: from lower to higher date
        if (prices[i].d >= date) {
          return prices[i];
        }
      }

      if (returnNullIfEmpty || prices.length === 0) {
        return null;
      }

      return prices[prices.length - 1];
    },
    [curves]
  );

  // TODO change to a defined type
  const getPointsAtDate: any = useCallback(
    (date) => {
      var points = {};
      if (curves == null) {
        return points;
      }
      const firstDateFromMainCurve = curves[0].serie?.[0]?.[0];
      if (firstDateFromMainCurve == null) {
        return points;
      }
      const firstDate = millisecondsToDays(firstDateFromMainCurve);
      curves?.forEach((curve) => {
        points[curve.id] = {
          current: dayAtDate(curve.id, date, true),
          last: curve.prices?.[curve.prices?.length - 1],
          first: dayAtDate(curve.id, firstDate, false),
        };
      });
      return points;
    },
    [curves, dayAtDate]
  );

  const handleMeasure = useCallback(
    (data) => {
      if (!hasMeasure) {
        console.warn("Measure callback called even if hasMeasure is disabled");
        return;
      }
      if (measureFunction != null) {
        return measureFunction(data);
      } else {
        const instruments: any = {};
        curves?.forEach((curve) => {
          instruments[curve.id] = {
            color: curve.color,
            information: curve.data,
            points: data?.instruments?.[curve.id],
          };
        });
      }
    },
    [curves, hasMeasure, measureFunction]
  );

  const handleTooltip = useCallback(
    (data) => {
      if (!hasTooltip) {
        console.warn("Tooltip callback called even if hasTooltip is disabled");
        return;
      }
      const instruments = curves?.map((curve) => ({
        color: curve.color,
        information: curve.data,
        points: data?.[curve.id],
      }));
      if (tooltipFunction != null) {
        return tooltipFunction(data);
      }
      chartInfoRef.current?.widget.set(instruments);
    },
    [curves, hasTooltip, tooltipFunction]
  );

  useEffect(() => {
    if (curves == null) {
      //Nothing to do
      return;
    }

    let axys: Highcharts.YAxisOptions[] = [
      {
        id: "Y_" + (curves?.[0]?.id ?? ""),
        labels: { align: "right", x: -3 },
        lineColor: "#d3d3d3",
        lineWidth: 1,
        type: isLogarithmic ? "logarithmic" : "linear",
        // minPadding: 0,
        // maxPadding: 0,
        startOnTick: true,
        endOnTick: false,
        opposite: false,
        showEmpty: false,
        title: {
          text: null,
        },
      },
    ];

    let series: (
      | Highcharts.SeriesLineOptions
      | Highcharts.SeriesFlagsOptions
    )[] = [];
    curves.forEach((curve, index) => {
      if ((curve?.serie?.length ?? 0) > 0) {
        series.push({
          visible:
            curve.type === "excess" ||
            curve.type === "long" ||
            curve.type === "short"
              ? false
              : true,
          allowPointSelect: true,
          clip: false,
          color: curve.color,
          custom: {
            prices: curve.prices,
            type: curve.type,
            currency: curve.currency,
          },
          data: curve.serie,
          dataGrouping: { enabled: false },
          enableMouseTracking: true,
          id: curve.id,
          name: curve.name,
          lineWidth: 2,
          marker: { enabled: false },
          states: {
            hover: {
              enabled: true,
              lineWidthPlus: 0,
            },
          },
          shadow: index === 0,
          showInLegend: true,
          tooltip: {
            valueDecimals: 2,
          },
          type: "line",
          yAxis: 0,
        } as Highcharts.SeriesLineOptions);
      }

      // Do not use enableRating here, it can be enabled if there are certain conditions met
      // Like using period "R"
      if ((curve?.ratingSerie?.length ?? 0) > 0) {
        series.push({
          allowOverlapX: true,
          clip: false,
          color: "#5F86B3",
          data: curve.ratingSerie,
          enableMouseTracking: true,
          id: `${curve.id}_rating`,
          name: `${curve.name}_rating`,
          onSeries: curve.id,
          shape: "circlepin",
          showInLegend: false,
          states: { hover: { enabled: false } },
          style: { color: "white" },
          type: "flags",
          visible: true, // !this.dual.enabled,
          width: 10,
          yAxis: 0,
          zIndex: 10,
        } as Highcharts.SeriesFlagsOptions);
      }
    });

    // fake series for legend
    // http://stackoverflow.com/questions/27510810/highcharts-make-the-legend-id-a-square-or-rectangle

    const options: Highcharts.Options = {
      credits: { enabled: false },
      chart: {
        alignTicks: false,
        animation: false,
        borderRadius: 0,
        borderWidth: 0,
        height: height && !autoResize ? height : null,
        // type: "line",
        zoomType: "x",
        // resetZoomButton: { theme: { display: "none" } },
        events: {
          selection: function (
            e: Highcharts.ChartSelectionContextObject & {
              resetSelection?: boolean;
            }
          ) {
            if (hasMeasure) {
              if (e.resetSelection) {
                return true;
              } else {
                var measure = {
                  dateFrom: millisecondsToDays(e.xAxis[0].min),
                  dateTo: millisecondsToDays(e.xAxis[0].max),
                  instruments: {},
                };
                var pointsFrom = getPointsAtDate(measure.dateFrom);
                var pointsTo = getPointsAtDate(measure.dateTo);
                var vFrom, vTo;
                for (let id in pointsFrom) {
                  if (
                    pointsTo[id] != null &&
                    pointsTo[id].current != null &&
                    pointsFrom[id].current != null
                  ) {
                    vFrom = pointsFrom[id].current.v;
                    vTo = pointsTo[id].current.v;
                    measure.instruments[id] = {
                      vFrom: vFrom,
                      vTo: vTo,
                      perf: vTo / vFrom - 1,
                    };
                  }
                }
                handleMeasure(measure);
                // return (e as any).originalEvent.shiftKey; // zoom only with shift
              }
            } else {
              return true;
            }
          },
        },
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: enableLegend,
        // itemHiddenStyle: {
        //     color: "#666",
        //     textDecoration: "none",
        // },
        // itemStyle: {
        //     color: "#2a7092",
        //     textDecoration: "underline",
        // },
        // idWidth: 10,
      },
      navigator: { enabled: false },
      rangeSelector: { enabled: false },
      scrollbar: { enabled: false },
      title: { text: undefined },
      plotOptions: {
        arearange: {
          fillOpacity: 0.2,
        },
        line: {
          animation: false,
        },
        series: {
          turboThreshold: 0,
          animation: false,
          connectNulls: false,
          cursor: "pointer",
          point: {
            events: {
              mouseOver: function (event: any) {
                var date = millisecondsToDays(event.target.x);
                if (hasTooltip) {
                  handleTooltip(getPointsAtDate(date));
                  return event.target.series.options.type === "flags";
                }

                return true;
              },
              // click: function (event) {
              //     var date = millisecondsToDays(event.point.x);
              //     console.log(date);
              //     // thisChart._setCrossPoint(date);
              //     // thisChart._draw();
              // },
              legendItemClick: function () {
                return false;
              },
            },
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          events: {
            legendItemClick: function (event) {
              // var current = this.index;
              var series = this.chart.series;

              // If there is excess active, use that logic
              if (isExcessAvailable) {
                if ((this.options as any).custom?.type === "excess") {
                  series
                    .filter(
                      (serie: any) => serie.options.custom?.type !== "excess"
                    )
                    .forEach((serie) => serie.setVisible(false));
                  series
                    .filter(
                      (serie: any) => serie.options.custom?.type === "excess"
                    )
                    .forEach((serie) => serie.setVisible(true));
                } else {
                  series
                    .filter(
                      (serie: any) => serie.options.custom?.type !== "excess"
                    )
                    .forEach((serie) => serie.setVisible(true));
                  series
                    .filter(
                      (serie: any) => serie.options.custom?.type === "excess"
                    )
                    .forEach((serie) => serie.setVisible(false));
                }
                return false;
              }
            },
          },
          marker: {
            enabled: true,
          },
        },
      },
      tooltip: {
        enabled: true, // callbackTooltip
        formatter: function (tooltip) {
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          if (this.series.options.type === "flags") {
            let date = new Date(this.point.x);
            return (
              '<div style="font-size:1.2em;font-weight:600;color:' +
              this.point.options.fillColor +
              '">' +
              this.point.options.title +
              "</div>" +
              '<div style="margin-top: 3px;">' +
              months[date.getUTCMonth()] +
              " " +
              date.getUTCDate() +
              " " +
              date.getUTCFullYear() +
              "</div>"
            );
          } else if (this.series.options.type === "line") {
            if (hasTooltip && hasMeasure) {
              return false; // Already send data out
            } else {
              let date = millisecondsToDays(this.point.x);
              let systemDate = new Date(this.point.x);

              let stringDate =
                months[systemDate.getUTCMonth()] +
                " " +
                systemDate.getUTCDate() +
                " " +
                systemDate.getUTCFullYear();
              let info = getPointsAtDate(date);
              let id = this.series.options.id;
              // TODO use external formatter instead of duplicating code
              let currency = this.series.options.custom?.currency ?? "local";
              let currencies = {
                EUR: "&euro;",
                GBP: "&pound;",
                JPY: "&yen;",
                USD: "$",
                local: "",
              };
              if (currencies[currency] != null) {
                currency = currencies[currency];
              }

              if (id == null) {
                return "";
              }

              return (
                '<span style="color:' +
                (this.series.options as any).color +
                ';">\u25CF</span> ' +
                stringDate +
                ": " +
                (currency ? currency : "") +
                " " +
                info[id].current.v.toFixed(4)
              );
            }
          } else {
            console.error("Tooltip is not supported with this type of line");
            return false;
          }
        },
        hideDelay: 100,
        split: false,
        shared: false,
        useHTML: true,
        valueDecimals: 2,
      },
      xAxis: {
        minRange: 1,
      },
      yAxis: axys,
      series: series,
    };

    setOptions(options);
  }, [
    curves,
    getPointsAtDate,
    hasMeasure,
    hasTooltip,
    handleMeasure,
    handleTooltip,
    isExcessAvailable,
    isLogarithmic,
    autoResize,
    height,
    enableLegend,
  ]);

  useEffect(() => {
    if (hasTooltip && curves != null) {
      const initialDate = curves?.[0].serie?.[0]?.[0];
      if (initialDate != null) {
        // Initial value
        handleTooltip(getPointsAtDate(millisecondsToDays(initialDate)));
      }
    }
    setTimeout(() => chartInfoRef.current?.widget.resize(), 0);
  }, [curves, getPointsAtDate, handleTooltip, hasTooltip]);

  // if (!options || !valueSorted) {
  //     return <></>;
  // }

  /*    on(
                window,
                "resize",
                lang.hitch(this, function () {
                    if (this._resizing) {
                        clearTimeout(this._resizing);
                    }
                    this._resizing = setTimeout(
                        lang.hitch(this, function () {
                            this.reflow();
                        }),
                        300
                    );
                })*/

  return (
    <div
      className="wSecurityAnalysisChart"
      style={
        fitContentInModal
          ? {
              position: "relative",
              width: "100%",
              height: "100%",
              maxHeight: "100%",
            }
          : {}
      }
    >
      <div className="wSecurityAnalysisChart-bar" style={{ height: "10%" }}>
        {children && (
          <div className="wSecurityAnalysisChart-bar-left">{children}</div>
        )}
        <div className="wSecurityAnalysisChart-bar-right">
          <RadioButtonBar
            className="wSecurityAnalysisChart-rangeSelector"
            onChange={(value) => {
              setSelectedPeriod(value);
            }}
            options={periodOptions}
            initValue={selectedPeriod}
          />{" "}
          <Switch
            size="small"
            value={isLogarithmic}
            onChange={() => setLogarithmic((logarithmic) => !logarithmic)}
          />{" "}
          <span>Log scale</span>
        </div>
      </div>
      <div
        className="wSecurityAnalysisChart-chartWrap"
        style={{ position: "relative", height: "90%" }}
      >
        <div
          className="wSecurityAnalysisChart-chart"
          data-dojo-attach-point="nodeChart"
          style={{ position: "relative", height: "100%" }}
          ref={containerRef}
        >
          <Chart options={options} ref={chartRef} />
        </div>
      </div>
      {hasTooltip && !tooltipFunction ? (
        <>
          <ChartInfo ref={chartInfoRef} />
        </>
      ) : null}
    </div>
  );
}
