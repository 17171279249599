/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/pages/analysisSecurity/widgets/TabOverview
 * @summary Overview Tab
 *
 */

import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import { ErrorBoundary } from "../../../../ErrorBoundary";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import Search from "../../widgets/ReactSearch/Search";
import { widgetsConfiguration } from "../../widgets/widgetsConfiguration";
import { TabOverviewStock } from "./components/TabOverviewStock";
import { TabOverviewTrend } from "./components/TabOverviewTrend";
import { Curve, TrendratingChart } from "./components/TrendratingChart";

type TabOverviewProps = {
  value: any;
};

type SearchData = {
  exchange: string;
  name: string;
  symbol: string;
  ticker: string;
  type: string;
};

type OverviewState = {
  primary: {
    ready: boolean;
    data: any | null;
    history: any | null;
    symbol: string | null;
    visible: boolean;
  };
  benchmark: {
    ready: boolean;
    data: any | null;
    history: any | null;
    symbol: string | null;
    visible: boolean;
  };
};

const initialState: OverviewState = {
  primary: {
    ready: false,
    data: null,
    history: null,
    symbol: null,
    visible: false,
  },
  benchmark: {
    ready: false,
    data: null,
    history: null,
    symbol: null,
    visible: false,
  },
};

type Action =
  | {
      type: "primary/load";
      payload: any;
    }
  | {
      type: "primary/updateHistory";
      payload: any;
    }
  | {
      type: "benchmark/load";
      payload: SearchData;
    }
  | {
      type: "benchmark/set";
      payload: any;
    }
  | {
      type: "benchmark/visible";
      payload: boolean;
    };

function reducer(draft: OverviewState, action: Action) {
  //! Warning: complex interaction with benchmark because the
  //!          search component is full of weird and multiple events
  switch (action.type) {
    case "primary/load":
      draft.primary.ready = false;
      draft.primary.symbol = action.payload.symbol;
      draft.primary.data = action.payload; // Primary: Data already part of payload
      draft.primary.history = null;
      break;
    case "primary/updateHistory":
      draft.primary.history = action.payload;
      draft.primary.ready = true;
      draft.primary.visible = true;
      break;
    case "benchmark/load":
      if (action.payload?.symbol === draft.benchmark.symbol) {
        return draft;
      }
      draft.benchmark.ready = false;
      draft.benchmark.symbol = action.payload?.symbol; // can be null
      draft.benchmark.data = null;
      draft.benchmark.history = null;
      break;
    case "benchmark/set":
      // check if data was already set (immer can't manage arrays with different reference)
      if (
        JSON.stringify(action.payload.data) ===
          JSON.stringify(draft.benchmark.data) &&
        JSON.stringify(action.payload.history) ===
          JSON.stringify(draft.benchmark.history)
      ) {
        return draft; // Do nothing
      }
      draft.benchmark.data = action.payload.data;
      draft.benchmark.history = action.payload.history;
      draft.benchmark.ready = true;
      draft.benchmark.visible = true;
      break;
    case "benchmark/visible":
      // Change only if it is already loaded
      if (draft.benchmark.ready) {
        draft.benchmark.visible = action.payload;
      }
      break;
  }
}

export function TabOverview({ value }: TabOverviewProps) {
  const [chartValue, setChartValue] = useState<Curve[]>();
  const environment = useEnvironment();
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: "primary/load",
      payload: value,
    });
  }, [dispatch, value]);

  const loadPrimary = useCallback(
    async (symbol) => {
      if (value == null) {
        return;
      }
      // const data = await environment.http["instruments"].fetch({
      //     properties:
      //         environment.widgetsConfiguration[
      //             "widgets/analysis/security"
      //         ]["properties"],
      //     symbols: [symbol],
      //     type: "security",
      // });
      const history = await environment.get("http")["instruments"].historyOf({
        symbol: symbol,
        years: 99,
      });
      dispatch({
        type: "primary/updateHistory",
        payload: history,
      });
    },
    [dispatch, environment, value]
  );

  const loadBenchmark = useCallback(
    async (symbol) => {
      const data = await environment.get("http")["instruments"].fetch({
        properties:
          widgetsConfiguration["widgets/analysis/security"]["properties"],
        symbols: [symbol],
        type: "security",
      });
      const history = await environment.get("http")["instruments"].historyOf({
        symbol: symbol,
        years: 99,
      });
      dispatch({
        type: "benchmark/set",
        payload: {
          data: data.data[0],
          history: history,
        },
      });
    },
    [dispatch, environment]
  );

  useEffect(() => {
    if (state.primary.symbol != null && !state.primary.ready) {
      loadPrimary(state.primary.symbol);
    }

    if (state.benchmark.symbol != null && !state.benchmark.ready) {
      loadBenchmark(state.benchmark.symbol);
    }
  }, [loadBenchmark, loadPrimary, state]);

  useEffect(() => {
    if (
      environment == null ||
      state == null ||
      !state.primary.ready ||
      state.primary.symbol == null
    ) {
      return;
    }

    // Must have at least the primary visible
    if (!state.primary.visible) {
      return;
    }

    const curves: Curve[] = [];
    // let instrument = response[0].data[0];

    curves.push({
      data: state.primary.data,
      name: state.primary.data.ticker,
      prices: state.primary.history,
      id: state.primary.symbol,
      type: "default",
    });

    if (
      state.benchmark.symbol != null &&
      state.benchmark.ready &&
      state.benchmark.visible
    ) {
      curves.push({
        data: state.benchmark.data,
        name: state.benchmark.data?.name ?? state.benchmark.symbol,
        prices: state.benchmark.history,
        id: state.benchmark.symbol,
        type: "benchmark",
      });
    }

    setChartValue(curves);
  }, [environment, state, value]);

  if (chartValue == null) {
    // || instrumentValue == null
    return <></>;
  }

  return (
    <ErrorBoundary fallback={<></>}>
      <Box display={"flex"} gap={1} flex={1}>
        <Box flex={3} display={"flex"}>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <TrendratingChart
                defaultPeriod={"R"}
                excludePeriod={["MAX"]}
                hasMeasure={true}
                hasTooltip={true}
                value={chartValue}
              >
                <Search
                  showInstrumentInfoOnSelect={false}
                  onSelectInstrument={(value) =>
                    dispatch({
                      type: "benchmark/load",
                      payload: value,
                    })
                  }
                />
              </TrendratingChart>
            </CardContent>
          </Card>
        </Box>
        <Box flex={1} gap={1} display={"flex"} flexDirection={"column"}>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <TabOverviewTrend value={value} />
            </CardContent>
          </Card>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <TabOverviewStock value={value} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
