import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Instruments } from "../../../../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";

type HedgingProps = {
  value: any;
};

export default function Hedging({ value }: HedgingProps) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();
  const [instrument, setInstrument] = useState(null);

  useEffect(() => {
    return () => {
      setInstrument(null);
    };
  }, []);

  useEffect(() => {
    if (value?.instrument) {
      getInstrumentName(environment, value).then((data) => {
        setInstrument(data);
      });
    }
  }, [environment, value]);

  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0, width: "100%" }}>
        <tbody>
          {value == null && (
            <tr>
              <td>Dynamic hedging</td>
              <td>
                <strong> Not set</strong>
              </td>
            </tr>
          )}
          {instrument && (
            <tr>
              <td>Instrument</td>
              <td>
                <strong>{instrument}</strong>
              </td>
            </tr>
          )}
          {value?.leverage && (
            <tr>
              <td>Leverage</td>
              <td>
                <strong>{value?.leverage}</strong>
              </td>
            </tr>
          )}
          {value?.constraints && (
            <tr>
              <td>Rules</td>
              <td
                dangerouslySetInnerHTML={{
                  __html: strategyFormat.hedgingStrategy(value?.constraints),
                }}
              ></td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
}

const getInstrumentName = async (environment, value) => {
  let tt = new Instruments(environment.get("setup"));
  let gg = await tt.fetch({
    properties: [{ date: null, property: "name" }],
    symbols: [value.instrument],
    type: "security",
  });
  return gg.data[0].name;
};
