/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyAsOfTodayPerformance
 * @summary Strategy - As of today performance
 *
 */

import { Strategies } from "../../../api/compute/Strategies";
import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity, substituteVariables } from "./Generator";

export function sectionsAsOfTodayPerformance(input, section, datum, formatter) {
  const _sections: any = [];

  if (section["content"]["headline"]["isEnabled"]) {
    const _section = {
      data: {
        text: escapeEntity(datum["headline"].toUpperCase()),
      },
      type: "header1",
    };
    _sections.push(_section);
  }

  const _section = sectionAsOfTodayPerformance(
    input,
    section,
    datum,
    formatter
  );
  _sections.push(_section);

  /*_section = {
                data: {
                    text: "* LTD: launch-to-date"
                },R
                type: "text"
            };
            _sections.push(_section);*/

  return _sections;
}

function sectionAsOfTodayPerformance(input, section, data, formatter) {
  const format = formatter;
  const table: any = {
    data: {
      body: [],
      head: [
        [
          {
            style: null,
            value: "",
          },
        ],
      ],
    },
    type: "table",
  };

  // Default state
  let hasStrategy = false;
  let hasBenchmark = false;
  let hasDelta = false;
  let use1Day = false;
  let use1Month = false;
  let use1Week = false;
  let use3Months = false;
  let useLTD = false;
  let useMTD = false;
  let useQTD = false;
  let useYTD = false;
  let use1Year = false;
  let use3Years = false;
  let use5Years = false;
  let use10Years = false;

  //
  if (section["content"] != null) {
    if (section["content"]["strategy"] != null) {
      hasStrategy = section["content"]["strategy"]["isEnabled"];
    }
    if (section["content"]["benchmark"] != null) {
      hasBenchmark =
        data["benchmark"] != null &&
        section["content"]["benchmark"]["isEnabled"];
    }
    if (section["content"]["delta"] != null) {
      hasDelta =
        data["delta"] != null && section["content"]["delta"]["isEnabled"];
    }
    if (section["content"]["performance1Day"] != null) {
      use1Day = section["content"]["performance1Day"]["isEnabled"];
    }
    if (section["content"]["performance1Month"] != null) {
      use1Month = section["content"]["performance1Month"]["isEnabled"];
    }
    if (section["content"]["performance1Week"] != null) {
      use1Week = section["content"]["performance1Week"]["isEnabled"];
    }
    if (section["content"]["performance3Months"] != null) {
      use3Months = section["content"]["performance3Months"]["isEnabled"];
    }
    if (section["content"]["performanceMTD"] != null) {
      useMTD = section["content"]["performanceMTD"]["isEnabled"];
    }
    if (section["content"]["performanceQTD"] != null) {
      useQTD = section["content"]["performanceQTD"]["isEnabled"];
    }
    if (section["content"]["performanceYTD"] != null) {
      useYTD = section["content"]["performanceYTD"]["isEnabled"];
    }
    if (section["content"]["performance1Year"] != null) {
      use1Year = section["content"]["performance1Year"]["isEnabled"];
    }
    if (section["content"]["performance3Years"] != null) {
      use3Years = section["content"]["performance3Years"]["isEnabled"];
    }
    if (section["content"]["performance5Years"] != null) {
      use5Years = section["content"]["performance5Years"]["isEnabled"];
    }
    if (section["content"]["performance10Years"] != null) {
      use10Years = section["content"]["performance10Years"]["isEnabled"];
    }
    if (section["content"]["performanceLTD"] != null) {
      useLTD = section["content"]["performanceLTD"]["isEnabled"];
    }
  }

  // Build Head
  if (use1Day) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance1Day"]["content"] || "1 D",
    });
  }
  if (use1Week) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance1Week"]["content"] || "1 W",
    });
  }
  if (useMTD) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performanceMTD"]["content"] || "MTD",
    });
  }
  if (use1Month) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance1Month"]["content"] || "1 Mo",
    });
  }
  if (useQTD) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performanceQTD"]["content"] || "QTD",
    });
  }
  if (use3Months) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance3Months"]["content"] || "3 Mo",
    });
  }
  if (useYTD) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performanceYTD"]["content"] || "YTD",
    });
  }
  if (use1Year) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance1Year"]["content"] || "1Yr",
    });
  }
  if (use3Years) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance3Years"]["content"] || "3Yr",
    });
  }
  if (use5Years) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance5Years"]["content"] || "5Yr",
    });
  }
  if (use10Years) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performance10Years"]["content"] || "10Yr",
    });
  }
  if (useLTD) {
    table["data"]["head"][0].push({
      style: {
        align: "right",
      },
      value: section["content"]["performanceLTD"]["content"] || "Starting date",
    });
  }

  // Portfolio
  if (hasStrategy) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["strategy"]["isEnabled"]
          ? substituteVariables(
              section["content"]["strategy"]["content"],
              input["target"]
            )
          : "Strategy",
      },
    ];
    if (use1Day) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance1Day"],
          valueHelper: null,
        }),
      });
    }
    if (use1Week) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance1Week"],
          valueHelper: null,
        }),
      });
    }
    if (useMTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performanceMTD"],
          valueHelper: null,
        }),
      });
    }
    if (use1Month) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance1Month"],
          valueHelper: null,
        }),
      });
    }
    if (useQTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performanceQTD"],
          valueHelper: null,
        }),
      });
    }
    if (use3Months) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance3Months"],
          valueHelper: null,
        }),
      });
    }
    if (useYTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performanceYTD"],
          valueHelper: null,
        }),
      });
    }
    if (use1Year) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance1Year"],
          valueHelper: null,
        }),
      });
    }
    if (use3Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance3Years"],
          valueHelper: null,
        }),
      });
    }
    if (use5Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance5Years"],
          valueHelper: null,
        }),
      });
    }
    if (use10Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performance10Years"],
          valueHelper: null,
        }),
      });
    }
    if (useLTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data["strategy"]["performanceLTD"],
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }

  // Benchmark
  if (hasBenchmark) {
    const benchmark = data["benchmark"];
    const isNeutralStrategy = benchmark === Strategies.SYMBOL_NEUTRAL_STRATEGY;
    const isNeutralEqualWeighted =
      benchmark === Strategies.SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED;

    const substituteVariablesNeutrals = (inputString) => {
      let substitutedString = inputString;
      if (isNeutralStrategy) {
        substitutedString = substitutedString.replaceAll(
          // eslint-disable-next-line no-template-curly-in-string
          "${name}",
          "Neutral strategy"
        );
      }
      if (isNeutralEqualWeighted) {
        substitutedString = substitutedString.replaceAll(
          // eslint-disable-next-line no-template-curly-in-string
          "${name}",
          "Neutral strategy equal weighted"
        );
      }
      substitutedString = escapeEntity(substitutedString);

      return substitutedString;
    };

    const getBenchmark = () => {
      if (isNeutralEqualWeighted || isNeutralStrategy) {
        let obj: any = {};
        obj["style"] = null;
        if (section["content"]["benchmark"]["isEnabled"] === true) {
          obj["value"] = substituteVariablesNeutrals(
            section["content"]["benchmark"]["content"]
          );
        } else {
          obj["value"] = "Benchmark";
        }
        return obj;
      } else {
        return {
          style: null,
          value: section["content"]["benchmark"]["isEnabled"]
            ? substituteVariables(
                section["content"]["benchmark"]["content"],
                data["benchmark"]
              )
            : "Benchmark",
        };
      }
    };
    const benchmarkObj = getBenchmark();
    const tableRow: any = [benchmarkObj];
    if (use1Day) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance1Day"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Week) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance1Week"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useMTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performanceMTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Month) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance1Month"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useQTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performanceQTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use3Months) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance3Months"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useYTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performanceYTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Year) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance1Year"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use3Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance3Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use5Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance5Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use10Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performance10Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useLTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["benchmarkPerformance"]?.["performanceLTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }

  // Delta
  if (hasDelta) {
    const tableRow: any = [
      {
        style: null,
        value: section["content"]["delta"]["content"] || "Difference",
      },
    ];
    if (use1Day) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance1Day"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Week) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance1Week"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useMTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performanceMTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Month) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance1Month"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useQTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performanceQTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use3Months) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance3Months"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useYTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performanceYTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use1Year) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance1Year"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use3Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance3Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use5Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance5Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (use10Years) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performance10Years"] ?? null,
          valueHelper: null,
        }),
      });
    }
    if (useLTD) {
      tableRow.push({
        style: {
          align: "right",
        },
        value: format.custom("number", {
          options: customPropertiesFormattingOptions["PERCENTAGE"],
          output: "PDF",
          value: data?.["delta"]?.["performanceLTD"] ?? null,
          valueHelper: null,
        }),
      });
    }
    table["data"]["body"].push(tableRow);
  }

  return table;
}
