import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Card, CardContent, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import {
  TrendratingTable,
  reactFormatter,
} from "../../../../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../../../../hooks/useEnvironment";
import { ActionsETFMarketsControls } from "../../../../AnalysisMarketsETF";
import styles from "../InstrumentsTableForAnalytics/InstrumentTableForAnalytics.module.scss";
import { _rate } from "./../../../../../../../../../trendrating/formatter/_rate";

type MarketsETFTableTCRProps = {
  type: "Stock" | "ETF";
  data: { peer: any; children: any };
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  timeframe: 0 | 4 | 19;
  formatterHelper: (field: TableField) => Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  goToSecuritiesTab?: (
    peer?: any,
    boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc"
  ) => void;
  hasPeerSummaryRow?: boolean;
};

type CustomNameCellProps = {
  cell?: CellComponent;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  fieldFormatter: Function;
  showExplicitPeerName: boolean;
  type: "Stock" | "ETF";
  buttonListener?: Function;
};

type UpDownCellProps = {
  cell?: CellComponent;
  getCellValue: Function;
  handleAnalyticClick: Function;
  boxType?: "upgrades" | "downgrades" | "ab_perc" | "cd_perc";
};

type TableField =
  | "name"
  | "tcr"
  | "tcr_changes"
  | "abPerc"
  | "cdPerc"
  | "cardinality"
  | "ab_changes"
  | "cd_changes"
  | "upgrades"
  | "downgrades"
  | "upgrades_perc"
  | "downgrades_perc";

export const turnSegmentInLabel = (sg, type: "Stock" | "ETF") => {
  if (type === "ETF") {
    switch (sg) {
      case "1 Industry":
        return "All Asset Classes";

      case "4 Subsector":
        return "All Themes";

      case "3 Sector":
        return "All Specialties";

      case "Area":
        return "All Areas";

      case "Region":
        return "All Regions";

      case "Country":
        return "All Countries";

      case "WWW":
        return "World Wide";

      default:
        return sg;
    }
  } else {
    switch (sg) {
      case "1 Industry":
        return "Sectors";

      case "3 Sector":
        return "Industries";

      case "3 Level":
        return "Size";

      case "Area":
        return "Areas";

      case "Region":
        return "Regions";

      case "WWW":
        return "World Wide";

      default:
        return sg;
    }
  }
};

const timeframeToPlainText = (timeframe) => {
  switch (timeframe) {
    case 0:
    default:
      return "today";

    case 4:
      return "lastWeek";

    case 19:
      return "lastMonth";
  }
};

export const formatCellBorderByTCR = (
  cell: CellComponent,
  plainText: string,
  timeframe: 0 | 4 | 19,
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector",
  type: "Stock" | "ETF"
) => {
  const cellElement = cell.getElement();
  const data = cell.getData();
  const tcr = (data as any).tcr[timeframeToPlainText(timeframe)];
  const rowPosition = cell.getRow().getPosition();

  // Get border color by TCR from _rate colors map
  const tcrMap = _rate;
  const borderColor =
    tcrMap?.["trendCaptureRating"]?.[tcr]?.["color"] ?? "transparent";

  cellElement.style.borderLeft = `2px solid ${borderColor}`;
  if (type === "ETF") {
    return rowPosition === 1 ? turnSegmentInLabel(segment, type) : plainText;
  } else {
    return plainText;
  }
};

export default function MarketsETFTableTCR({
  data,
  segment,
  timeframe,
  formatterHelper,
  dispatch,
  goToSecuritiesTab,
  hasPeerSummaryRow = true,
  type,
}: MarketsETFTableTCRProps) {
  const [tableData, setTableData] = useState([]);
  const usage = window.App.usage;
  const environment = useEnvironment();
  const product = useMemo(
    () =>
      environment.get("account")?.["product"]?.["configuration"]?.[
        "analysis_peer"
      ] ?? null,
    [environment]
  );

  useEffect(() => {
    if (data != null) {
      // Assemble data that comings from response and formatting it to fit the table format.
      // the data object contains 2 keys one is for the peer overall info and the other contains
      // the peerChildren.
      // We need to put the overall peer's da
      const dataInTableFormat = Object.entries<any>(data).reduce(
        (prev: any, [key, value]) => {
          if (key === "peer" && hasPeerSummaryRow) {
            value["name"] = `${value.name}`;
            prev.push(value ?? []);
          } else if (key === "children") {
            prev = prev.concat(value?.data ?? []);
          }

          return prev;
        },
        []
      );

      setTableData(dataInTableFormat);
    }
  }, [data, hasPeerSummaryRow]);

  const fieldFormatter = useCallback(
    (field: TableField) => {
      return formatterHelper(field);
    },
    [formatterHelper]
  );

  const tcrChangesCol = useMemo(() => {
    let col = {
      title: "AB% changes",
      formatter: (cell) =>
        fieldFormatter("ab_changes")(
          cell,
          timeframe,
          type === "ETF" ? "abChanges" : "abPercentage"
        ),
      sorter: (a, b, aRow, bRow) => {
        const timeSegment = timeframeToPlainText(timeframe);
        const aData = aRow.getData();
        const bData = bRow.getData();
        const aElement =
          aData["statistic"][type === "ETF" ? "abChanges" : "abPercentage"][
            timeSegment
          ];
        const bElement =
          bData["statistic"][type === "ETF" ? "abChanges" : "abPercentage"][
            timeSegment
          ];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    };

    if (product && product.TCRTableABvsCDColumn === "CD") {
      return {
        title: "CD% changes",
        formatter: (cell) =>
          fieldFormatter("cd_changes")(
            cell,
            timeframe,
            type === "ETF" ? "cdChanges" : "cdPercentage"
          ),
        sorter: (a, b, aRow, bRow) => {
          const timeSegment = timeframeToPlainText(timeframe);
          const aData = aRow.getData();
          const bData = bRow.getData();
          const aElement =
            aData["statistic"][type === "ETF" ? "cdChanges" : "cdPercentage"][
              timeSegment
            ];
          const bElement =
            bData["statistic"][type === "ETF" ? "cdChanges" : "cdPercentage"][
              timeSegment
            ];

          if (aElement > bElement) {
            return 1;
          } else if (aElement < bElement) {
            return -1;
          }

          return 0;
        },
        widthGrow: 1,
      };
    }

    return col;
  }, [fieldFormatter, product, timeframe, type]);

  const navigatePeerDown = useCallback(
    (peer) => {
      const { what, where, size } = peer;

      const info = {
        action: "PEER",
        actionParam: {
          xDimension: where,
          yDimension: what,
          zDimension: size,
        },
        function: "MARKET_ETF",
      };

      usage.record(info);

      dispatch({
        type: "CHANGE_PEER",
        payload: { xDim: where, yDim: what },
      });
    },
    [dispatch, usage]
  );

  const handleAnalyticClick = useCallback(
    (peer, boxType) => {
      if (type === "ETF") {
        navigatePeerDown(peer);
        if (goToSecuritiesTab) {
          goToSecuritiesTab(boxType);
        }
      } else {
        if (goToSecuritiesTab) {
          goToSecuritiesTab(peer, boxType);
        }
      }
    },
    [goToSecuritiesTab, navigatePeerDown, type]
  );

  const tableColumns: ColumnDefinition[] = [
    {
      title: turnSegmentInLabel(segment, type),
      field: "name",
      formatter: reactFormatter(
        <CustomNameCell
          segment={segment}
          showExplicitPeerName={data?.children == null || !hasPeerSummaryRow}
          fieldFormatter={fieldFormatter}
          // Undefined because the events is captured on the all cell and not only on the button (button is fake for ETF)
          buttonListener={type === "ETF" ? undefined : navigatePeerDown}
          type={type}
        />
      ),
      sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
        const aName = fieldFormatter("name")(aRow, segment);
        const bName = fieldFormatter("name")(bRow, segment);

        if (a === "__ROOT__" || aName === "Any") {
          return 1;
        }

        if (b === "__ROOT__" || bName === "Any") {
          return 1;
        }

        if (aName > bName) {
          return -1;
        } else if (aName < bName) {
          return 1;
        }

        return 0;
      },
      widthGrow: 3,
    },
    {
      title: "TCR",
      formatter: (cell) => fieldFormatter("tcr")(cell, 0),
      sorter: (a, b, aRow, bRow) => {
        const aElement = aRow.getData().tcr.today;
        const bElement = bRow.getData().tcr.today;

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: type === "ETF" ? "ETFs" : "Stocks",
      formatter: reactFormatter(
        <UpDownCell
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) => fieldFormatter("cardinality")(cell)}
        />
      ),
      sorter: (a, b, aRow, bRow) => {
        const aElement = aRow.getData().info.cardinality;
        const bElement = bRow.getData().info.cardinality;

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "AB%",
      formatter: reactFormatter(
        <UpDownCell
          boxType="ab_perc"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("abPerc")(cell, "abPercentage", timeframe)
          }
        />
      ),
      sorter: (a, b, aRow, bRow) => {
        const aElement =
          aRow.getData().statistic["abPercentage"][
            timeframeToPlainText(type === "ETF" ? timeframe : 0)
          ];
        const bElement =
          bRow.getData().statistic["abPercentage"][
            timeframeToPlainText(type === "ETF" ? timeframe : 0)
          ];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "CD%",
      formatter: reactFormatter(
        <UpDownCell
          boxType="cd_perc"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("abPerc")(cell, "cdPercentage", timeframe)
          }
        />
      ),

      sorter: (a, b, aRow, bRow) => {
        const aElement =
          aRow.getData().statistic["cdPercentage"][
            timeframeToPlainText(type === "ETF" ? timeframe : 0)
          ];
        const bElement =
          bRow.getData().statistic["cdPercentage"][
            timeframeToPlainText(type === "ETF" ? timeframe : 0)
          ];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "TCR Alert",
      formatter: (cell) => fieldFormatter("tcr_changes")(cell, timeframe),
      sorter: (a, b, aRow, bRow) => {
        const aElement = aRow.getData().tcr.today;
        const bElement = bRow.getData().tcr.today;

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 3,
    },
    { ...tcrChangesCol },
    {
      title: "Upgrades",
      formatter: reactFormatter(
        <UpDownCell
          boxType="upgrades"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("upgrades")(cell, "upgrades", timeframe)
          }
        />
      ),
      sorter: (a, b, aRow, bRow) => {
        const timeSegment = timeframeToPlainText(timeframe);
        const aElement =
          aRow.getData()["statistic"]["upgrades"][timeSegment]["number"];
        const bElement =
          bRow.getData()["statistic"]["upgrades"][timeSegment]["number"];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "Downgrades",
      formatter: reactFormatter(
        <UpDownCell
          boxType="downgrades"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("downgrades")(cell, "downgrades", timeframe)
          }
        />
      ),
      sorter: (a, b, aRow, bRow) => {
        const timeSegment = timeframeToPlainText(timeframe);
        const aElement =
          aRow.getData()["statistic"]["downgrades"][timeSegment]["number"];
        const bElement =
          bRow.getData()["statistic"]["downgrades"][timeSegment]["number"];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "Upgrades %",
      formatter: reactFormatter(
        <UpDownCell
          boxType="upgrades"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("upgrades_perc")(cell, "upgrades", timeframe)
          }
        />
      ),
      sorter: (a, b, aRow, bRow) => {
        const timeSegment = timeframeToPlainText(timeframe);
        const aElement =
          aRow.getData()["statistic"]["upgrades"][timeSegment]["percentage"];
        const bElement =
          bRow.getData()["statistic"]["upgrades"][timeSegment]["percentage"];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
    {
      title: "Downgrades %",
      formatter: reactFormatter(
        <UpDownCell
          boxType="downgrades"
          handleAnalyticClick={handleAnalyticClick}
          getCellValue={(cell) =>
            fieldFormatter("downgrades_perc")(cell, "downgrades", timeframe)
          }
        />
      ),

      sorter: (a, b, aRow, bRow) => {
        const timeSegment = timeframeToPlainText(timeframe);
        const aElement =
          aRow.getData()["statistic"]["downgrades"][timeSegment]["percentage"];
        const bElement =
          bRow.getData()["statistic"]["downgrades"][timeSegment]["percentage"];

        if (aElement > bElement) {
          return 1;
        } else if (aElement < bElement) {
          return -1;
        }

        return 0;
      },
      widthGrow: 1,
    },
  ];

  const handleTableEvents = useCallback(
    (e) => {
      if (e.type === "rowClick") {
        const row = e.value;

        if (type === "ETF") {
          navigatePeerDown(row.getData());
        }
      }
    },
    [navigatePeerDown, type]
  );

  return data?.peer?.info?.cardinality > 0 ? (
    <Card sx={{ minHeight: 0, width: "100%", minWidth: 0 }}>
      <CardContent sx={{ minHeight: 0, height: "100%", width: "100%" }}>
        {tableData && tableData.length ? (
          <TrendratingTable
            disableDefaultRowClick
            eventCallback={handleTableEvents}
            options={{
              selectableRows: false,
              ajaxSorting: false,
              height: 200,
            }}
            sorting={{ field: "name", direction: "desc" }}
            autoResize={true}
            columns={tableColumns}
            data={tableData}
          />
        ) : (
          <strong>No {type} was found with those filters</strong>
        )}
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardContent>
        <Typography fontSize={"14px"} fontWeight={"bold"}>
          Any {type} was found
        </Typography>
      </CardContent>
    </Card>
  );
}

const CustomNameCell = ({
  cell,
  segment,
  fieldFormatter,
  showExplicitPeerName,
  type,
  buttonListener,
}: CustomNameCellProps) => {
  const data = useMemo(() => cell?.getData(), [cell]);
  const name = fieldFormatter("name")(cell, segment);
  const [showIcon, setShowIcon] = useState(false);
  const row = cell?.getRow();
  const rowPosition = useMemo(() => row?.getPosition(), [row]);

  useEffect(() => {
    if (!showExplicitPeerName && rowPosition === 1) {
      const rowHtml = row?.getElement();

      rowHtml!.style.fontSize = "1.2em";
      rowHtml!.style.fontWeight = "bold";
      rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
    }
  }, [row, rowPosition, showExplicitPeerName]);

  useEffect(() => {
    const cellComp = cell?.getElement();
    const tcr = (data as any).tcr["today"];
    if (cellComp) {
      // Get border color by TCR from _rate colors map
      const tcrMap = _rate;
      const borderColor =
        tcrMap?.["trendCaptureRating"]?.[tcr]?.["color"] ?? "transparent";

      cellComp.style.borderLeft = `2px solid ${borderColor}`;
    }
  }, [cell, data]);

  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      {!showExplicitPeerName && rowPosition === 1
        ? `${turnSegmentInLabel(segment, type)}`
        : showExplicitPeerName
        ? (data as any).name
        : name}

      <div className={styles.custom__cell__icon}>
        {type === "ETF"
          ? rowPosition !== 1 &&
            showIcon && (
              // Has no listener because the event is captured on the all cell and not only on the button (button is fake for ETF)
              <div className={styles.custom__cell__icon__background}>
                <KeyboardDoubleArrowDownIcon
                  className={styles.custom__cell__icon__background__icon}
                />
              </div>
            )
          : showIcon && (
              <div
                className={styles.custom__cell__icon__background}
                onClick={() =>
                  buttonListener ? buttonListener(data) : () => {}
                }
              >
                <KeyboardDoubleArrowDownIcon
                  className={styles.custom__cell__icon__background__icon}
                />
              </div>
            )}
      </div>
    </div>
  );
};

const UpDownCell = ({
  cell,
  handleAnalyticClick,
  boxType,
  getCellValue,
}: UpDownCellProps) => {
  const cellValueHTML = getCellValue(cell);
  const data = cell?.getData() ?? { what: null, where: null };
  return (
    <div
      onClick={() => handleAnalyticClick(data, boxType)}
      className={styles.up__down__cell}
      dangerouslySetInnerHTML={{ __html: cellValueHTML }}
    ></div>
  );
};
