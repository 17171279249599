import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { format as dateFormat } from "date-fns";

type DatepickerProps = {
  input: string | null;
  onChangeDate: Function;
  disableFuture?: boolean;
  isValid?: boolean;
  disabled?: boolean;
  maxDate?:any;
  minDate?:any;
};
const DatePicker = ({
  input,
  onChangeDate,
  disableFuture,
  isValid,
  disabled = false,
  maxDate,
  minDate
}: DatepickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disabled={disabled}
        disableFuture={disableFuture}
        inputFormat="YYYY-MM-DD"
        value={input ?? null}
        onChange={(e: any) => {
          if (e != null) {
            if (e.$d instanceof Date && isFinite(e.$d)) {
              const returningDate = dateFormat(e.$d, "yyyy-MM-dd");
              onChangeDate(returningDate);
            }
          }
        }}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => (
          <TextField
            error={isValid != null ? isValid : false}
            sx={{
              svg: { color: "#2a7092" },
              "& .Mui-focused > .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2a7092 !important",
              },
            }}
            size="small"
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
