import { Box, Typography } from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { formatTaxonPrefixingParent } from "../../../../../../../api/compute/Taxon";
import { TaxonomySelect } from "../../../../../../../components/TaxonomySelect/TaxonomySelect";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { ActionsETFMarketsControls } from "../../AnalysisMarketsETF";
import styles from "./BreadcrumbsRow.module.scss";

// type TaxonomyBreadcrumbProps = {
//     field: "subtype" | "etfgeo" | "etfclass";
//     taxonomyId: "string";
//     taxonomiesMap?: any;
//     assetClassValidLevels?: string[];
//     setPeerDimension: (dim: string, dimType: "x" | "y" | "z") => void;
//     panelTitle: "Investment Region" | "Asset Class" | "Issuer";
// };

type BreadcrumbsRowProps = {
  data: any;
  dispatch: (action: ActionsETFMarketsControls) => void;
};

export function BreadcrumbsRow({ data, dispatch }: BreadcrumbsRowProps) {
  const { taxonomiesMapX, taxonomiesMapY, rootNodeY } =
    useTaxonomyByType("ETF");
  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("setup").taxonomies,
    [environment]
  );
  const usage = window.App.usage;
  const taxonomiesFields = useMemo(
    () => environment.get("setup").taxonomyFields,
    [environment]
  );
  const zDimTaxonomyMapETF = taxonomies[taxonomiesFields["ETF"]["subtype"]];

  const setDimensionToRootNode = useCallback(
    (dim, dimType: "x" | "y" | "z") => {
      const info = {
        action: "PEER",
        actionParam: {},
        function: "MARKET_ETF",
      };

      let params = {
        xDimension: data.where,
        yDimension: data.what,
        zDimension: data.size,
      };

      switch (dimType) {
        case "x": {
          params.xDimension = dim;

          break;
        }

        case "y": {
          params.yDimension = dim;

          break;
        }

        case "z": {
          params.zDimension = dim;
        }
      }

      info.actionParam = params;
      usage.record(info);

      const action: ActionsETFMarketsControls = {
        type: "CHANGE_PEER",
        payload: { [`${dimType}Dim`]: dim },
      };

      dispatch(action);
    },
    [data.size, data.what, data.where, dispatch, usage]
  );

  const assetClassValidLevels = useMemo(() => ["1 Industry", "3 Sector"], []);
  const taxonYPanelRef = useRef<any>(null);
  const taxonXPanelRef = useRef<any>(null);
  const taxonZPanelRef = useRef<any>(null);

  const handlePeerDimensionChange = useCallback(
    (dimValue, field) => {
      const dimensionId = { etfclass: "y", etfgeo: "x", subtype: "z" };

      setDimensionToRootNode(dimValue, dimensionId[field] as "x" | "y" | "z");
    },
    [setDimensionToRootNode]
  );

  const openPanel = useCallback((ref) => {
    if (ref.current) {
      ref.current.showTreePanel(true);
    }
  }, []);

  const labelY = useMemo(() => {
    if (data.what === rootNodeY) {
      return "Any Asset Class";
    } else {
      const node = taxonomiesMapY[data.what];

      switch (node.type as "1 Industry" | "3 Sector" | "4 Subsector") {
        case "1 Industry":
          return taxonomiesMapY?.[data.what]?.name ?? data.what;

        case "3 Sector":
          return formatTaxonPrefixingParent(node, [taxonomiesMapY], "3 Sector");

        case "4 Subsector":
          return formatTaxonPrefixingParent(
            node,
            [taxonomiesMapY],
            "4 Subsector"
          );

        default:
          return data.what;
      }
    }
  }, [data.what, rootNodeY, taxonomiesMapY]);

  const labelX = useMemo(() => {
    const node = taxonomiesMapX[data.where];

    switch (node.type as "World" | "Region" | "Area" | "Country") {
      case "World":
      case "Country":
      case "Area":
        return taxonomiesMapX?.[data.where]?.name ?? data.where;

      case "Region":
        return formatTaxonPrefixingParent(node, [taxonomiesMapX], "Region");

      default:
        return data.where;
    }
  }, [data.where, taxonomiesMapX]);

  const zRootNode = useMemo(
    () =>
      Object.values<any>(zDimTaxonomyMapETF).find((el) => el.parent == null),
    [zDimTaxonomyMapETF]
  );

  const labelZ = useMemo(() => {
    if (data.size === zRootNode.id) {
      return "Any Issuer";
    } else {
      return zDimTaxonomyMapETF[data.size].name;
    }
  }, [data.size, zDimTaxonomyMapETF, zRootNode.id]);

  return (
    <Box display={"flex"} flex={1} alignItems={"center"} gap={1} ml={2}>
      <TaxonomySelect
        ref={taxonYPanelRef}
        validTreeNodes={assetClassValidLevels}
        taxonomyInfo={{ type: "ETF", field: "etfclass" }}
        panelTitle={"Asset Class"}
        hasMultipleChoices={false}
        selectValue={(value) => handlePeerDimensionChange(value, "etfclass")}
        defaultValue={data.what}
      >
        <Box component={"fieldset"} className={styles.outlined__box}>
          <legend className={styles.outlined__box__label}>Asset Class</legend>
          <Box className={styles.outlined__box__content}>
            <Typography
              className={styles.title__label}
              onClick={() => openPanel(taxonYPanelRef)}
            >
              {labelY}
            </Typography>
          </Box>
        </Box>
      </TaxonomySelect>
      <TaxonomySelect
        ref={taxonXPanelRef}
        taxonomyInfo={{ type: "ETF", field: "etfgeo" }}
        panelTitle={"Investment Region"}
        hasMultipleChoices={false}
        selectValue={(value) => handlePeerDimensionChange(value, "etfgeo")}
        defaultValue={data.where}
      >
        <Box className={styles.outlined__box} component={"fieldset"}>
          <legend className={styles.outlined__box__label}>
            Investment Region
          </legend>
          <Box className={styles.outlined__box__content}>
            <Typography
              className={styles.title__label}
              onClick={() => openPanel(taxonXPanelRef)}
            >
              {labelX}
            </Typography>
          </Box>
        </Box>
      </TaxonomySelect>
      <TaxonomySelect
        ref={taxonZPanelRef}
        taxonomyInfo={{ type: "ETF", field: "subtype" }}
        panelTitle={"Issuer"}
        hasMultipleChoices={false}
        selectValue={(value) => handlePeerDimensionChange(value, "subtype")}
        defaultValue={data.size}
      >
        <Box
          className={styles.outlined__box}
          component={"fieldset"}
          onClick={() => openPanel(taxonZPanelRef)}
        >
          <legend className={styles.outlined__box__label}>Issuer</legend>
          <Box className={styles.outlined__box__content}>
            <Typography
              sx={data.size === zRootNode.id ? { visibility: "hidden" } : {}}
              className={styles.title__label}
            >
              {labelZ}
            </Typography>
          </Box>
        </Box>
      </TaxonomySelect>
    </Box>
  );
}

// const TaxonomyBreadcrumb = ({
//     field,
//     taxonomyId,
//     taxonomiesMap,
//     assetClassValidLevels,
//     setPeerDimension,
//     panelTitle,
// }: TaxonomyBreadcrumbProps) => {
//     const [taxonomyChain, setTaxonomyChain] = useState([]);
//     const taxonPanelRef = useRef<any>(null);

//     // Get Nodes chain of parents to build a breadcrumb based on taxonomies
//     useEffect(() => {
//         const chainFromParentToNode: any = [];
//         const node = taxonomiesMap[taxonomyId];
//         let parent: any = null;

//         chainFromParentToNode.push(node);

//         if (node.parent) {
//             parent = taxonomiesMap[node.parent];
//             // Put the parent in first position
//             chainFromParentToNode.unshift(parent);

//             // Update the node parent with the parent node of the parent
//             // till the parent is the root node, and at every iteration push
//             // the new parent to the array in first position to buil the parent chain
//             while (parent.parent != null) {
//                 parent = taxonomiesMap[parent.parent];
//                 chainFromParentToNode.unshift(parent);
//             }
//         }

//         let chain = chainFromParentToNode;

//         if (chainFromParentToNode.length > 1) {
//             // Removes the parent node to not show any label when the taxonomy selected is not the root node
//             chain = chainFromParentToNode.filter((node) => node.parent != null);
//         }

//         setTaxonomyChain(chain);
//     }, [field, taxonomiesMap, taxonomyId]);

//     const handlePeerDimensionChange = useCallback(
//         (dimValue) => {
//             const dimensionId = { etfclass: "y", etfgeo: "x", subtype: "z" };

//             setPeerDimension(dimValue, dimensionId[field] as "x" | "y" | "z");
//         },
//         [field, setPeerDimension]
//     );

//     const openPanel = useCallback(() => {
//         if (taxonPanelRef.current) {
//             taxonPanelRef.current.showTreePanel(true);
//         }
//     }, []);

//     return (
//         <TaxonomySelect
//             ref={taxonPanelRef}
//             validTreeNodes={assetClassValidLevels}
//             taxonomyInfo={{ type: "ETF", field }}
//             panelTitle={panelTitle}
//             hasMultipleChoices={false}
//             selectValue={handlePeerDimensionChange}
//         >
//             <Breadcrumbs aria-label="breadcrumb" separator="|">
//                 {taxonomyChain.map((node: any, index: number) => {
//                     const handleBreadcrumbClick = () => {
//                         openPanel();
//                     };

//                     return (
//                         <Typography
//                             key={uuidv4()}
//                             className={styles.breadcrumb__step}
//                             fontSize={"14px"}
//                             fontWeight={"bold"}
//                             onClick={handleBreadcrumbClick}
//                         >
//                             {taxonomiesMap?.[node.id]?.["name"]}
//                         </Typography>
//                     );
//                 })}
//             </Breadcrumbs>
//         </TaxonomySelect>
//     );
// };
