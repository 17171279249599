import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Strategies } from "../../../../../../api/compute/Strategies";
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

type Props = {
  onClickHandler: Function;
  open: boolean;
  onOptimize: Function;
};
const baseUrlImages = appConfig.baseUrlImages;

export default function TacticalAllocation({
  onOptimize,
  onClickHandler,
  open,
}: Props) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{ boxShadow: 3, backgroundColor: open ? "transparent" : "#f2f2f2" }}
    >
      <CardContent>
        <Box display={"flex"} gap={1}>
          <Box
            sx={{ cursor: "pointer" }}
            display={"flex"}
            width={open ? "60%" : "100%"}
            flexDirection={"column"}
            onClick={() => {
              onClickHandler();
            }}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <i
                style={{ fontSize: "2.5vw", color: open ? "#2A7090" : "#aaa" }}
                className="i-beta"
              />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ color: open ? "#2A7090" : "#000" }}
                >
                  {open ? (
                    <strong>{t("Tactical_allocation")}</strong>
                  ) : (
                    t("Tactical_allocation")
                  )}
                </Typography>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Apply_a_smart_beta_strategy_and_increase_A_rated_positions_decrease_D_and_C"
                      ),
                    }}
                  ></span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {open && (
            <Box width={"40%"}>
              <StrategyAndApproach
                entity_type={"SMART_BETA"}
                onOptimize={onOptimize}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

type StrategyAndApproachProps = {
  entity_type: "SMART_BETA" | "ALPHA";
  onOptimize: Function;
};
export const StrategyAndApproach = ({
  onOptimize,
  entity_type,
}: StrategyAndApproachProps) => {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(setup), [setup]);
  // const preferencesAPI = useMemo(() => new Preferences(setup), [setup]);
  const userId = useMemo(
    () => environment.get("account")["user"]["id"],
    [environment]
  );

  const [smartBetaStrategies, setSmartBetaStrategies] = useState<null | any[]>(
    null
  );
  const [selectedStrategy, setSelectedStrategy] = useState<any>(null);
  const [strategyComplete, setStrategyComplete] = useState<any>(null);
  const [rebalance, setRebalance] = useState<any>(null);
  const [loadingRebalance, setLoadingRebalance] = useState(false);
  const [loadingStrategies, setLoadingStrategies] = useState(false);

  // useEffect(() => {
  //   console.log("smartBetaStrategies", smartBetaStrategies);
  // }, [smartBetaStrategies]);

  const getDataToSelect = useCallback(async () => {
    setLoadingStrategies(true);
    strategiesAPI
      .getList(["type", "ownerId", "name", "object"])
      .then((response: any) => {
        const temp = response.filter(
          (item) => item.object.entity_type === entity_type
        );
        temp.forEach((element) => {
          delete element.object;
        });
        setSmartBetaStrategies(temp);
        setLoadingStrategies(false);
      });
  }, [entity_type, strategiesAPI]);

  const onSelectStrategy = useCallback(
    (_selectedStrategy) => {
      setLoadingRebalance(true);
      const id = _selectedStrategy?.id;
      strategiesAPI.getById(id).then((response) => {
        setStrategyComplete(response);
        let rebalance = response.params.strategy.rebalance;
        let formatted = "";
        switch (rebalance) {
          case "05_DAYS": {
            formatted = "Weekly";
            break;
          }
          case "20_DAYS": {
            formatted = "Monthly";
            break;
          }
          case "60_DAYS": {
            formatted = "Quarterly";
            break;
          }
          default: {
            formatted = "#UNKNOWN";
          }
        }
        const temp = (
          <Typography>
            <strong>{response.name}</strong> is{" "}
            <em>{formatted.toLowerCase()}</em> strategy
          </Typography>
        );
        setRebalance(temp);
        setLoadingRebalance(false);
      });
    },
    [strategiesAPI]
  );

  const onOptimizeHandler = useCallback(
    (approach) => {
      onOptimize({
        approach: approach,
        method: entity_type === "ALPHA" ? "implementative" : "tactical",
        strategy: strategyComplete,
      });
    },
    [entity_type, onOptimize, strategyComplete]
  );

  return (
    <Card sx={{ boxShadow: 1 }}>
      <CardContent
        sx={{
          p: 1,
          pb: "8px !important",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography>Strategy and approach</Typography>
        <Select
          size="small"
          defaultValue={"-"}
          onOpen={() => {
            if (smartBetaStrategies == null) {
              getDataToSelect();
            }
          }}
          onChange={(e) => {
            const temp = smartBetaStrategies?.filter(
              (item: any) => item.id === e.target.value
            );
            setRebalance(null);
            setSelectedStrategy(temp?.[0]);
            onSelectStrategy(temp?.[0]);
          }}
        >
          <MenuItem value={"-"} disabled>
            <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              select {entity_type === "ALPHA" ? "an Alpha" : "a Smart Beta"}{" "}
              strategy{" "}
              {loadingStrategies && <CircularProgress size={"0.9vw"} />}
            </Typography>
          </MenuItem>
          {smartBetaStrategies &&
            smartBetaStrategies.map((item: any, index) => (
              <MenuItem key={index} value={item.id}>
                <Typography>
                  {item.name}{" "}
                  {item.ownerId !== userId ? (
                    <span className="sharedObjectIndicator"></span>
                  ) : null}
                </Typography>
              </MenuItem>
            ))}
        </Select>
        {loadingRebalance ? (
          <Box>
            <CircularProgress size={"0.9vw"} />
          </Box>
        ) : (
          rebalance
        )}
        <Typography display={"flex"} gap={1}>
          Please select your optimization approach
          <Tooltip content={<TooltipContent />} tooltipIcon={false}>
            <span className="alerts-info-tooltip-icon i-help"></span>
          </Tooltip>
        </Typography>
        <Box display={"flex"} gap={1}>
          <Button
            onClick={() => onOptimizeHandler("systematic")}
            disabled={selectedStrategy == null}
          >
            Systematic
          </Button>
          <Button
            onClick={() => onOptimizeHandler("spot")}
            disabled={selectedStrategy == null}
          >
            Spot
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const TooltipContent = () => {
  return (
    <div className="optimization__tooltip">
      <h1 className="optimization__tooltip-title">
        <span className="optimization__tooltip-legend optimization__tooltip-legend--strict"></span>
        Systematic
      </h1>
      <Typography>
        The portfolio will be adjusted to the strategy's last revision date. If
        today is not the revision date, current holdings can mismatch the
        strategy rules.
      </Typography>

      <h1 className="optimization__tooltip-title">
        <span className="optimization__tooltip-legend optimization__tooltip-legend--loose"></span>
        Spot
      </h1>
      <Typography>
        The strategy rules will be applied as of today. The portfolio will
        comply with the strategies rules. However, if this is not the strategy
        revision date, the holdings may differ from the strategy constituents as
        of the last revision date.
      </Typography>

      <p>
        <img
          className="optimization__tooltip-img"
          alt="strict loose"
          src={` ${baseUrlImages}strict_loose.png`}
        />
      </p>
    </div>
  );
};
