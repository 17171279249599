/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/report/Report
 * @summary Requests for report
 *
 */

import { _Base } from "../_Base";
import { deepClone } from "../../deepClone";
import { endpoints } from "../endpoints";

export class Report extends _Base {
    /**
     * Retrieve default disclaimer
     */
    disclaimer() {
        const endPointRoot = this.getEndpointRoot(this.environment.api.report);
        const url = endPointRoot + endpoints.reports.disclaimers.disclaimers;
        return this.prepareGetText(url, null, null);
    }

    /**
     * Retrieve default logo
     */
    logo() {
        const endPointRoot = this.getEndpointRoot(this.environment.api.report);
        const url = endPointRoot + endpoints.reports.logos.logos;
        return this.prepareGetText(url, null, null);
    }

    /**
     * Generate PDF like buffer
     */
    print(params: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.report);
        const url = endPointRoot + "/";

        const paramsPrint = this._preparePrintParams(params);

        return this.preparePostBlob(url, paramsPrint, null);
    }

    /**
     * Generate PDF like a remote file
     */
    printOnFile(params: any) {
        var endPointRoot = this.getEndpointRoot(this.environment.api.report);
        var url = endPointRoot + endpoints.reports.files.files;

        var paramsPrint = this._preparePrintParams(params);

        return this.preparePost(url, paramsPrint, null);
    }

    _preparePrintParams(params: any) {
        var environment = this.environment;

        var paramsPrint = deepClone(params);
        paramsPrint.additionalCookies =
            environment.account.user?.additionalCookies;

        return paramsPrint;
    }
}
