import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {
  hasUpgradeDowngrade: boolean;
  onUpgradeDowngradeChange: Function;
  hasNewLow: boolean;
  onNewLowChange: Function;
  hasNewHigh: boolean;
  onNewHighChange: Function;
};

export default function RelevantEvents({
  hasUpgradeDowngrade,
  hasNewHigh,
  hasNewLow,
  onUpgradeDowngradeChange,
  onNewLowChange,
  onNewHighChange,
}: Props) {
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important", display:"flex", flexDirection:"column", gap:1 }}>
        <Box>

        <Typography variant="subtitle1">
          <strong> Today's Relevant Events </strong>
        </Typography>
        <Typography>Once in the Analyze, select which events are immediately showed in the Overview.</Typography>
        </Box>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => onUpgradeDowngradeChange(e.target.checked)}
                checked={hasUpgradeDowngrade}
              />
            }
            label=/*"Uptrends / Downtrends"*/"Alerts"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => onNewLowChange(e.target.checked)}
                checked={hasNewLow}
              />
            }
            label="New Lows"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={(e) => onNewHighChange(e.target.checked)}
                checked={hasNewHigh}
              />
            }
            label="New Highs"
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
}
