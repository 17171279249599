import { Box, Button, Card, CardContent } from "@mui/material";
import React, { useCallback, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

type Props = {
  strategyType: "alpha" | "macroRotation" | "smartBeta" | null;
};

export default function Help({ strategyType }: Props) {
  const [showSection, setShowSection] = useState(false);

  const getHelpSection = useCallback(() => {
    if (strategyType === "alpha") {
      return strategyAlpha;
    } else if (strategyType === "macroRotation") {
      return strategyMacroRotation;
    } else if (strategyType === "smartBeta") {
      return strategySmartBeta;
    }
  }, [strategyType]);

  return (
    <Box height={"100%"} width={"100%"} p={1}>
      <Card sx={{ height: "100%", overflow: "visible", position: "relative" }}>
      {showSection === true && (
        <HighlightOffIcon
          color="primary"
          sx={{
            position: "absolute",
            top: 0,
            transform: "translate(50%,-50%)",
            cursor: "pointer",
            right: 0,
            zIndex: 2,
          }}
          onClick={() => setShowSection(false)}
        />
      )}
      <CardContent
        sx={{ height: "100%", pb: "16px !important", position: "relative" }}
      >
        <Box
          height={"100%"}
          width={"100%"}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {showSection === false && (
            <Button onClick={() => setShowSection(true)}>
              <i className="i-edu" />
              &nbsp; See our user guide
            </Button>
          )}
          {showSection === true && getHelpSection()}
        </Box>
      </CardContent>
    </Card>
    </Box>
  );
}

const strategyAlpha = (
  <div className="EditorHelp-content">
    <h1 className="title1">
      <strong>Alpha Strategy</strong>
      <span className="titleDescription">
        Stocks selection based on Trend Ratings
      </span>
    </h1>
    <h2 className="title2">
      <span className="step">1</span> Universe
    </h2>
    <div className="textSection">
      <p>
        The investment universe defines the complete set of securities from
        which the strategy can pick.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Basket or a peer group</strong> (<strong>!</strong>
            required): select a basket or a peer group.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Basket</strong>: static list of securities from which
            securities entitled to be held in the portfolio will be drawn. The
            same set of securities will constitute the investment universe for
            each backtesting period.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Peer group</strong>: select markets and sectors from which
            securities can be selected to define the investment universe.
          </p>
          <p>
            Eligibility: controls to further narrow the investment universe. In
            the first control the number of securities to which to narrow the
            universe is specified, and the second set of controls provides the
            option to narrow by either &quot;Highest Mkt. Cap&quot; or by
            &quot;Lower Mkt. Cap&quot;. At rebalance: Eligibility criteria are
            applied historically.
          </p>
        </li>
      </ol>
    </div>

    <h2 className="title2">
      <span className="step">2</span> Selection rules
    </h2>
    <div className="textSection">
      <p>
        Define the set of entitled securities. Entitled securities constitute
        the portion of the investment universe eligible to be included in the
        portfolio.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Rating</strong>: by default
            <strong className="rate rate--A">A</strong> is selected for a
            prudent selection. Add the
            <strong className="rate rate--B">B</strong> for a more aggressive
            strategy.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Market cap</strong>: limit the selection by ranges of market
            capitalization.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Volatility</strong>: narrow the selection by volatility.
            Volatility is the annualized standard deviation of rolling 20 days
            returns. Low &lt; 10% Mid (10%-50%) High &gt; 50%.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Min liquidity</strong>: set minimum liquidity. Liquidity is
            the Average Daily Trading Value (ADTV) calculated over 20 days.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Perf. since rated</strong>: limit the selection by
            performance since rated.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Retracement</strong>: limit the selection by retracement.
          </p>
          <p>
            <em>
              Retracement offers insight into securities whose trends are
              potentially exhausted and thus ones to avoid in terms of position
              building as they have the capacity to behave contrary to
              expectations
            </em>
            .
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">3</span> Holding rules
    </h2>
    <div className="textSection">
      <p>Define the rules that will be applied to construct the portfolio.</p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Securities to hold</strong>: select the maximum number of
            positions to hold at any time.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>% weight capped at</strong>: set the maximum weight for each
            position.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Flex vs. Full</strong>: set the strategic allocation rule.
            Flex allows the strategy to stay in cash if there are few stocks
            rated <strong className="rate rate--A">A</strong>/
            <strong className="rate rate--B">B</strong>. Full is entirely
            invested at any time.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Rebalance frequency</strong>: select the frequency to
            rebalance the strategy/portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Give priority</strong>: select the priority that will be
            employed to rank the securities (
            <strong className="rate rate--A">A</strong>
            or <strong className="rate rate--A">A</strong>
            <strong className="rate rate--B">B</strong> rated) that are now
            classified as valid securities for inclusion in the final portfolio.
            The rule determines the relative attractiveness of each security.
            The first N° ranked securities will compose the final portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Keep valid positions</strong>: the rule will put at any
            rebalancing date on the top of the ranking all the securities that
            are already in the portfolio from the previous rebalance that still
            pass the selection rules.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Keep weights / Rebalance weights</strong>: the two options
            are either to rebalance weights to the allowed position maximum at
            every rebalance period, or to allow weights to grow for valid
            securities.
          </p>
          <p>
            <em>
              A feature of trend capture is that it allows a portfolio to
              maximise participation in strong up-trends, so Keep weights is
              generally a preferred option
            </em>
            .
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Maximum cash to hold</strong>: set the max weight on cash.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">4</span> Tracking
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Benchmark</strong>: select the benchmark that best compares
            to the selected universe.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Dynamic hedging</strong>: the strategy opens a short
            position on the benchmark when its rating is negative (
            <strong className="rate rate--C">C</strong>
            or <strong className="rate rate--D">D</strong>).
          </p>
        </li>
        <li className="item">
          <p>
            <strong>History</strong>: set the time frame for the backtest.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Currency</strong> : select the currency of the portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Inception date and value</strong>: the date at which the
            inception value will be assigned. The purpose of the inception date
            and value is purely visual. It has no impact on the actual
            performance of the strategy.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">Save the strategy</h2>
    <div className="textSection">
      <p className="text">
        Save the strategy by clicking <strong>Save</strong>, so you can use the
        strategy in the Optimization functionality of Portfolio Analysis.
      </p>
    </div>
  </div>
);

const strategyMacroRotation = (
  <div className="EditorHelp-content">
    <h1 className="title1">
      <strong>Macro Rotation Strategy</strong>
      <span className="titleDescription">Allocation based on Macro Trends</span>
    </h1>
    <h2 className="title2">
      <span className="step">1</span> Universe
    </h2>
    <div className="textSection">
      <p>
        The investment universe defines the complete set of securities from
        which the strategy can pick.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Basket or a peer group</strong> (<strong>!</strong>
            required): select a basket or a peer group.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Basket</strong>: static list of securities from which
            securities entitled to be held in the portfolio will be drawn. The
            same set of securities will constitute the investment universe for
            each backtesting period.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Peer group</strong>: select markets and sectors from which
            securities can be selected to define the investment universe.
          </p>
          <p>
            Eligibility: controls to further narrow the investment universe. In
            the first control the number of securities to which to narrow the
            universe is specified, and the second set of controls provides the
            option to narrow by either &quot;Highest Mkt. Cap&quot; or by
            &quot;Lower Mkt. Cap&quot;. At rebalance: Eligibility criteria are
            applied historically.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">2</span> Rotation rules
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Rotate</strong>: select the rotation level.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Rotation factor</strong>: the allocation across groups is
            implemented using one of the following factors:
          </p>
          <ol className="fieldHelp">
            <li className="item">
              <p>
                <strong>Momentum</strong>: the relative distribution of uptrends
                versus downtrends for each grouping decides the weight assigned
                to each grouping.
              </p>
            </li>
            <li className="item">
              <p>
                <strong>Momentum growth</strong>: the change in directional
                rating dictates the allocation.
              </p>
            </li>
            <li className="item">
              <p>
                <strong>Market neutral</strong>: the allocation weight for each
                group is held in line with the weight allocation for each group
                in the investment universe.
              </p>
            </li>
          </ol>
        </li>
        <li className="item">
          <p>
            <strong>Sub-portfolio % weight capped at</strong>: set the max
            capping for each sub-portfolio.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">3</span> Selection rules
    </h2>
    <div className="textSection">
      <p>
        Define the set of entitled securities. Entitled securities constitute
        the portion of the investment universe eligible to be included in the
        portfolio.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Rating</strong>: by default
            <strong className="rate rate--A">A</strong> is selected for a
            prudent selection. Add the
            <strong className="rate rate--B">B</strong> for a more aggressive
            strategy.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Market cap</strong>: limit the selection by ranges of market
            capitalization.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Volatility</strong>: narrow the selection by volatility.
            Volatility is the annualized standard deviation of rolling 20 days
            returns. Low &lt; 10% Mid (10%-50%) High &gt; 50%.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Min liquidity</strong>: set minimum liquidity. Liquidity is
            the Average Daily Trading Value (ADTV) calculated over 20 days.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Perf. since rated</strong>: limit the selection by
            performance since rated.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Retracement</strong>: limit the selection by retracement.
          </p>
          <p>
            <em>
              Retracement offers insight into securities whose trends are
              potentially exhausted and thus ones to avoid in terms of position
              building as they have the capacity to behave contrary to
              expectations
            </em>
            .
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">4</span> Holding rules
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Securities to hold</strong>: select the maximum number of
            positions to hold at any time.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>% weight capped at</strong>: set the maximum weight for each
            position.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Flex vs. Full</strong>: set the strategic allocation rule.
            Flex allows the strategy to stay in cash if there are few stocks
            rated <strong className="rate rate--A">A</strong>/
            <strong className="rate rate--B">B</strong>. Full is entirely
            invested at any time.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Rebalance frequency</strong>: select the frequency to
            rebalance the strategy/portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Give priority</strong>: select the priority that will be
            employed to rank the securities (
            <strong className="rate rate--A">A</strong>
            or <strong className="rate rate--A">A</strong>
            <strong className="rate rate--B">B</strong> rated) that are now
            classified as valid securities for inclusion in the final portfolio.
            The rule determines the relative attractiveness of each security.
            The first N° ranked securities will compose the final portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Keep valid positions</strong>: the rule will put at any
            rebalancing date on the top of the ranking all the securities that
            are already in the portfolio from the previous rebalance that still
            pass the selection rules.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Keep weights / Rebalance weights</strong>: the two options
            are either to rebalance weights to the allowed position maximum at
            every rebalance period, or to allow weights to grow for valid
            securities.
          </p>
          <p>
            <em>
              A feature of trend capture is that it allows a portfolio to
              maximise participation in strong up-trends, so Keep weights is
              generally a preferred option
            </em>
            .
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Maximum cash to hold</strong>: set the max weight on cash.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">5</span> Smart beta rules
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Weights</strong>: the two options are equal weighting and
            market cap weighting.
          </p>
          <p>
            <em>
              Generally one would select equal weighted. Selecting market cap
              weighting imposes a size and style in addition to trend capture.
            </em>
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">6</span> Tracking
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Benchmark</strong>: select the benchmark that best compares
            to the selected universe.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Dynamic hedging</strong>: the strategy opens a short
            position on the benchmark when its rating is negative (
            <strong className="rate rate--C">C</strong>
            or <strong className="rate rate--D">D</strong>).
          </p>
        </li>
        <li className="item">
          <p>
            <strong>History</strong>: set the time frame for the backtest.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Currency</strong> : select the currency of the portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Inception date and value</strong>: the date at which the
            inception value will be assigned. The purpose of the inception date
            and value is purely visual. It has no impact on the actual
            performance of the strategy.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">Save the strategy</h2>
    <div className="textSection">
      <p>
        Save the strategy by clicking <strong>Save</strong>, so you can use the
        strategy in the Optimization functionality of Portfolio Analysis.
      </p>
    </div>
  </div>
);

const strategySmartBeta = (
  <div className="EditorHelp-content">
    <h1 className="title1">
      <strong>Smart Beta Strategy</strong>
      <span className="titleDescription">
        Strategy weighting based on Trend Rating
      </span>
    </h1>
    <h2 className="title2">
      <span className="step">1</span> Universe
    </h2>
    <div className="textSection">
      <p>
        All the securities in the investment universe are holdings in the
        portfolio.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Basket or a peer group</strong> (<strong>!</strong>
            required): select a basket or a peer group.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Basket</strong>: static list of securities. The same set of
            securities will constitute the investment universe for each
            backtesting period.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Peer group</strong>: select markets and sectors from which
            securities can be selected to define the investment universe.
          </p>
          <p>
            Eligibility: controls to further narrow the investment universe. In
            the first control the number of securities to which to narrow the
            universe is specified, and the second set of controls provides the
            option to narrow by either &quot;Highest Mkt. Cap&quot; or by
            &quot;Lower Mkt. Cap&quot;. At rebalance: Eligibility criteria are
            applied historically.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">2</span> Holding rules
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>% weight capped at</strong>: set the maximum weight for each
            position.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Rebalance frequency</strong>: select the frequency to
            rebalance the strategy/portfolio.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">3</span> Smart beta rules
    </h2>
    <div className="textSection">
      <p>
        Overweight positions with positive momentum (rating
        <strong className="rate rate--A">A</strong> and
        <strong className="rate rate--B">B</strong>) to the detriment of
        positions with a negative (<strong className="rate rate--C">C</strong>{" "}
        and
        <strong className="rate rate--D">D</strong>) or lower momentum.
      </p>
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong className="rate rate--A">A</strong>
            <strong>&nbsp;and&nbsp;</strong>
            <strong className="rate rate--B">B</strong> (<strong>!</strong>
            required): decide the weighting multiplier for stocks rated
            <strong className="rate rate--A">A</strong>/
            <strong className="rate rate--B">B</strong>.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Weights</strong>: the two options are equal weighting and
            market cap weighting.
          </p>
          <p>
            <em>
              Generally one would select equal weighted. Selecting market cap
              weighting imposes a size and style in addition to trend capture.
            </em>
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">
      <span className="step">4</span> Tracking
    </h2>
    <div className="textSection">
      <ol className="fieldHelp">
        <li className="item">
          <p>
            <strong>Benchmark</strong>: select the benchmark that best compares
            to the selected universe.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Dynamic hedging</strong>: the strategy opens a short
            position on the benchmark when its rating is negative (
            <strong className="rate rate--C">C</strong>
            or <strong className="rate rate--D">D</strong>).
          </p>
        </li>
        <li className="item">
          <p>
            <strong>History</strong>: set the time frame for the backtest.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Currency</strong> : select the currency of the portfolio.
          </p>
        </li>
        <li className="item">
          <p>
            <strong>Inception date and value</strong>: the date at which the
            inception value will be assigned. The purpose of the inception date
            and value is purely visual. It has no impact on the actual
            performance of the strategy.
          </p>
        </li>
      </ol>
    </div>
    <h2 className="title2">Save the strategy</h2>
    <div className="textSection">
      <p className="text">
        Save the strategy by clicking <strong>Save</strong>, so you can use the
        strategy in the Optimization functionality of Portfolio Analysis.
      </p>
    </div>
  </div>
);
