export class Downloader {
  report: Generator;

  constructor(report) {
    this.report = report;
  }

  public download(response) {
    const report = this.report;

    const fileName = report["printParams"]["fileName"];
    const blob = new Blob([response], {
      type: "application/pdf",
    });

    let downloadLink: HTMLAnchorElement | null = null;

    if ((navigator as any).msSaveBlob) {
      // IE 11
      downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", "#" + fileName);
      document.body.appendChild(downloadLink);

      downloadLink.addEventListener(
        "click",
        function (event) {
          (navigator as any).msSaveBlob(blob, fileName);
        },
        false
      );
    } else {
      // let newWindow = window.open("/app/reports-hub");
      // newWindow!.onload = () => {
      //   newWindow!.location = URL.createObjectURL(blob);

      downloadLink = document.createElement("a");

      const attributes = [
        { attr: "class", value: "a11y" },
        // { attr: "download", value: fileName },
        { attr: "href", value: window.URL.createObjectURL(blob) },
        { attr: "target", value: "_blank" },
      ];

      attributes.forEach((attribute) => {
        downloadLink?.setAttribute(attribute.attr, attribute.value);
      });

      document.body.appendChild(downloadLink);

      downloadLink.click();
      downloadLink.remove();
    }
  }

  /**
   * Enable user to download the report (PDF) using a URI returned by
   * server
   *
   * @param {object} response - the response of the server after call
   *      generator.print()
   */
  public downloadFromUri(response) {
    const url = response["url"];

    const downloadLink = document.createElement("a");

    const attributes = [
      { attr: "class", value: "a11y" },
      { attr: "download", value: response["file_name"] },
      { attr: "href", value: url },
    ];

    attributes.forEach((attribute) => {
      downloadLink?.setAttribute(attribute.attr, attribute.value);
    });

    document.body.appendChild(downloadLink);

    downloadLink.click();
    downloadLink.remove();
  }
}
