import {
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { deepClone } from "../../../../../../../../deepClone";

type SummaryProps = {
  configObj: any;
};

const Summary = forwardRef(({ configObj }: SummaryProps, ref) => {
  const [content, setContent] = useState(
    configObj.widgetValue.content.headline.content
  );
  const [isEnabled, setIsEnabled] = useState(
    configObj.widgetValue.content.headline.isEnabled
  );

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content.headline = {
        content: content,
        isEnabled: isEnabled,
      };
      return tempState;
    },
  }));

  return (
    <Box>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box display={"flex"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Switch
                  checked={isEnabled}
                  onChange={(e) => setIsEnabled(e.target.checked)}
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              value={content}
              onChange={(e) => setContent(e.target.value)}
              disabled={!isEnabled}
              size="small"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Summary;
