import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import DnD from "../commonWidgets/DnD_Generic";
import { deepClone } from "../../../../../../../deepClone";

type CustomizableTableProps = {
  configObj: any;
};

const availableColumns = [
  {
    label: "Name",
    property: "name",
  },
  {
    label: "TCR",
    property: "tcr",
  },
  {
    label: "Stocks",
    property: "cardinality",
  },
  {
    label: "AB%",
    property: "abPercentage",
  },
  {
    label: "CD%",
    property: "cdPercentage",
  },
  {
    label: "TCR Alert",
    property: "tcrAlerts",
  },
  {
    label: "AB changes",
    property: "abChanges",
  },
  {
    label: "Upgrades",
    property: "upgrades",
  },
  {
    label: "Downgrades",
    property: "downgrades",
  },
  {
    label: "Upgrades %",
    property: "upgradesPerc",
  },
  {
    label: "Downgrades %",
    property: "downgradesPerc",
  },
];

const directionOptions = [
  {
    label: "Descending",
    value: "desc",
  },
  {
    label: "Ascending",
    value: "asc",
  },
];

export const CustomizableTable = forwardRef(
  ({ configObj }: CustomizableTableProps, ref) => {
    const templateColumns = useMemo(() => {
      return configObj?.widgetValue?.presentation?.columns ?? [];
    }, [configObj?.widgetValue?.presentation?.columns]);

    const drilldownOptions = useMemo(
      () => [
        { label: "Sector", value: "1 Industry" },
        { label: "Industry", value: "3 Sector" },
        { label: "Size", value: "3 Level" },
        { label: "Area", value: "Area" },
        { label: "Region", value: "Region" },
        { label: "Market", value: "Country" },
      ],
      []
    );

    const timeframeOpts = useMemo(
      () => [
        { label: "Today", value: "today" },
        { label: "Last 5 days", value: "lastWeek" },
        { label: "Last 20 days", value: "lastMonth" },
      ],
      []
    );

    const initColumnMap = useMemo(() => {
      const activeFields = {};

      for (const col of availableColumns) {
        activeFields[col.property] = false;
      }

      for (const selectedCol of templateColumns) {
        activeFields[selectedCol.property] = true;
      }

      return activeFields;
    }, [templateColumns]);

    const [state, setState] = useState({
      title: configObj?.widgetValue?.content?.headline?.content ?? "",
      showTimeframeInTitle:
        configObj?.widgetValue?.content?.headline?.showTimeframeInTitle ?? true,
      hideWhenSingleRow:
        configObj?.widgetValue?.content?.headline?.hideWhenSingleRow ?? false,
      segment: configObj?.widgetValue?.content?.segment ?? "1 Industry",
      timeframe: configObj?.widgetValue?.content?.timeframe ?? "lastMonth",
      columns: initColumnMap,
      sortField: configObj?.widgetValue?.content?.sort?.property ?? "tcr",
      sortDesc:
        (configObj?.widgetValue?.content?.sort?.descending ?? true) === true
          ? "desc"
          : "asc",
      addPageBreak: configObj?.widgetValue?.content?.addPageBreak ?? false,
    });

    const dndRef = useRef<any>(null);

    const initSelectedColumns = useMemo(() => {
      const columns: { label: string; field: string }[] = [];
      let col: any = null;
      for (const key in state.columns) {
        col = availableColumns.find((item) => item.property === key);
        if (state.columns[key] === true) {
          columns.push({ label: col.label, field: col.property });
        }
      }

      return columns;
    }, [state.columns]);

    const [selectedColumns, setSelectedColumns] = useState(initSelectedColumns);

    const sortOptions = useMemo(() => {
      return selectedColumns.map((item) => ({
        label: item.label,
        value: item.field,
      }));
    }, [selectedColumns]);

    const addColumn = useCallback(
      (value) => {
        setState({ ...state, columns: { ...state.columns, [value]: true } });
        const column = availableColumns.find((item) => item.property === value);

        if (column) {
          setSelectedColumns([
            ...selectedColumns,
            { label: column.label, field: column.property },
          ]);
        }
      },
      [selectedColumns, state]
    );

    const uncheckColumn = useCallback(
      (value) => {
        setState({ ...state, columns: { ...state.columns, [value]: false } });
        setSelectedColumns(
          selectedColumns.filter((item) => item.field !== value)
        );
      },
      [selectedColumns, state]
    );

    const changeTitle = useCallback(
      (event) => {
        setState({ ...state, title: event.target.value });
      },
      [state]
    );

    const changeSegment = useCallback(
      (event) => {
        setState({ ...state, segment: event.target.value });
      },
      [state]
    );

    const changeTimeframe = useCallback(
      (event) => {
        setState({ ...state, timeframe: event.target.value });
      },
      [state]
    );

    const enableTimeframeInTitle = useCallback(
      (event, checked) => {
        setState({ ...state, showTimeframeInTitle: checked });
      },
      [state]
    );

    const enablePageBreak = useCallback(
      (event, checked) => {
        setState({ ...state, addPageBreak: checked });
      },
      [state]
    );

    const changeHideWhenSingleRow = useCallback(
      (event, checked) => {
        setState({ ...state, hideWhenSingleRow: checked });
      },
      [state]
    );

    const changeSortField = useCallback(
      (event) => {
        setState({ ...state, sortField: event.target.value });
      },
      [state]
    );

    const changeSortDir = useCallback(
      (event) => {
        setState({ ...state, sortDesc: event.target.value });
      },
      [state]
    );

    const updateColumns = useCallback(
      (event, checked, field) => {
        const value = field;

        if (checked) {
          addColumn(value);
        } else {
          uncheckColumn(value);
        }
      },
      [addColumn, uncheckColumn]
    );

    useImperativeHandle(ref, () => ({
      getState: () => {
        const tempState = deepClone(configObj);
        tempState.widgetValue.content.headline.content = state.title;
        tempState.widgetValue.content.headline.showTimeframeInTitle =
          state.showTimeframeInTitle;
        tempState.widgetValue.content.segment = state.segment;
        tempState.widgetValue.content.timeframe = state.timeframe;
        tempState.widgetValue.content.hideWhenSingleRow =
          state.hideWhenSingleRow;
        tempState.widgetValue.content.sort = {
          property: state.sortField,
          descending: state.sortDesc === "desc",
        };
        tempState.widgetValue.content.addPageBreak = state.addPageBreak;

        const columns = dndRef.current.getInnerValue();

        tempState.widgetValue.presentation.columns = columns.map((item) => ({
          ...item,
          property: item.field,
        }));

        return tempState;
      },
    }));

    return (
      <Box display={"flex"} gap={1} flexDirection={"column"} minWidth={"40vw"}>
        <Box display={"flex"} gap={1} flex={1}>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <FormGroup
                sx={{ display: "felx", flexDirection: "column", gap: "8px" }}
              >
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Headline</Typography>
                  <TextField
                    onChange={changeTitle}
                    value={state.title}
                    size="small"
                  />
                </Box>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Show timeframe in headline</Typography>
                  <Switch
                    onChange={enableTimeframeInTitle}
                    checked={state.showTimeframeInTitle}
                    size="small"
                  />
                </Box>
                <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
                  <Typography>Group by</Typography>
                  <FormControl size={"small"}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      value={state.segment}
                      onChange={changeSegment}
                    >
                      {drilldownOptions.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
                  <Typography>Timeframe</Typography>
                  <FormControl size={"small"}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      value={state.timeframe}
                      onChange={changeTimeframe}
                    >
                      {timeframeOpts.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={1} display={"flex"} alignItems={"center"} gap={1}>
                  <Typography>Sort Property</Typography>
                  <FormControl size={"small"}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      value={state.sortField}
                      onChange={changeSortField}
                    >
                      {sortOptions.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography>Direction</Typography>
                  <FormControl size={"small"}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      value={state.sortDesc}
                      onChange={changeSortDir}
                    >
                      {directionOptions.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Hide on single result</Typography>
                  <Switch
                    onChange={changeHideWhenSingleRow}
                    checked={state.hideWhenSingleRow}
                    size="small"
                  />
                </Box>
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Add page break</Typography>
                  <Switch
                    onChange={enablePageBreak}
                    checked={state.addPageBreak}
                    size="small"
                  />
                </Box>
              </FormGroup>
            </CardContent>
          </Card>
          <Card sx={{ flex: 1 }}>
            <CardContent>
              <Typography>
                <strong>Available Columns</strong>
              </Typography>
              <ul>
                {availableColumns.map((item) => {
                  return (
                    <Box
                      component={"li"}
                      key={uuidv4()}
                      display={"flex"}
                      gap={2}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state?.columns?.[item.property] ?? false}
                            onChange={(e, checked) =>
                              updateColumns(e, checked, item.property)
                            }
                            size="small"
                          />
                        }
                        label={item.label}
                      />
                    </Box>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent sx={{ pb: "16px !important" }}>
              <Typography>Drag and drop column to change order.</Typography>
              <DnD
                ref={dndRef}
                direction={"horizontal"}
                listContentObjBuilder={selectedColumns}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
);
