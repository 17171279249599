import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import Highcharts from "highcharts/highstock";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../../../../components/Modal/Modal";
import { PeerSize } from "../../../../../../components/PeerSize/PeerSize";
import { deepClone } from "../../../../../../deepClone";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { FormOptions } from "../../../../../../trendrating/app/formatter/FormOptions";
import { _rate } from "../../../../../../trendrating/formatter/_rate";
import { MarketsStorage } from "../../../../storage/MarketsStorage";
import styles from "./StrategicAllocation.module.scss";
import { Report } from "../../../../../../api/report/Report";
import { Generator } from "../../../../../trendrating-report/generator/Generator";
import { Downloader } from "../../../../../trendrating-report/downloader/DownloaderReact";

type StrategicAllocationModalProps = {
  close: () => void;
};

export function StrategicAllocation() {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      {showModal && <StrategicAllocationModal close={closeModal} />}
      <li onClick={openModal} className="menu__item">
        Strategic Allocation
      </li>
    </>
  );
}

const StrategicAllocationModal = ({ close }: StrategicAllocationModalProps) => {
  const [size, setSize] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [previewData, setPreviewData] = useState<any>(null);

  const environment = useEnvironment();
  const appEnv = useMemo(() => environment.get("setup"), [environment]);
  const formOptions = useMemo(() => new FormOptions(appEnv), [appEnv]);
  const sizeOptions = useMemo(
    () => formOptions.getField("SIZE"),
    [formOptions]
  );

  const titleInputRef = useRef<HTMLInputElement>(null);
  const notesInputRef = useRef<HTMLTextAreaElement>(null);

  const storage = useMemo(() => new MarketsStorage(appEnv), [appEnv]);
  const formatter = useFormatter();

  const handleSizeChange = useCallback((size) => {
    setSize(size);
  }, []);

  const prepareParamsSortBy = useCallback((sortByString) => {
    var tokens = sortByString.split("_");

    var sortBy = {
      descending: tokens[1] === "desc" ? true : false,
      property: tokens[0],
    };

    return sortBy;
  }, []);

  const prepareParams = useCallback(() => {
    // analytic
    var valueAnalytic = "tcr_today";
    // instrument type
    var valueInstrumentType = "stock";
    // what
    var valueWhatSortBy = prepareParamsSortBy("name_asc");
    var valueWhatType = "1 Industry";
    // where
    var valueWhereSize = size;
    var valueWhereSortBy = prepareParamsSortBy("marketcap_desc");
    var valueWhereType = "Country";

    var params = {
      analytic: valueAnalytic,
      analyticType: "tcr" /* "alert" */,
      instrumentType: valueInstrumentType,
      zDimension: valueWhereSize,
      whatSortBy: valueWhatSortBy,
      whatType: valueWhatType,
      whereSortBy: valueWhereSortBy,
      whereType: valueWhereType,
    };

    return params;
  }, [prepareParamsSortBy, size]);

  const dataGet = useCallback(async () => {
    const params = prepareParams();
    const response = await storage.get(params);

    setData(response);
  }, [prepareParams, storage]);

  const onComponentMount = useCallback(() => {
    dataGet();
  }, [dataGet]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  const wrapSetPreviewData = useCallback((value) => {
    setPreviewData(value);
  }, []);

  const prepareDataSort = useCallback((property, a, b) => {
    var value1 = a[property];
    var value2 = b[property];

    if (value1 > value2) {
      return -1;
    }

    if (value1 < value2) {
      return 1;
    }

    return 0;
  }, []);

  const sections = useMemo(() => {
    if (previewData == null) {
      return null;
    }
    var cross: any = null;
    var data = previewData.formatted;
    var item: any = null;
    var sections: any = {
      cross: null,
      markets: null,
      sectors: null,
    };
    var serie: any = null;

    if (data.markets) {
      serie = [];
      for (var where in data.markets) {
        item = data.markets[where];
        serie.push({
          id: item.type === "Country" ? item.id : null, // for flags
          name: item.name,
          y: item.value,
        });
      }
      serie.sort((a, b) => prepareDataSort("y", a, b));
      sections.markets = {
        serie: serie,
        title: "Markets",
      };
    }

    if (data.sectors) {
      serie = [];
      for (var what in data.sectors) {
        item = data.sectors[what];
        serie.push({
          id: null,
          name: item.name,
          y: item.value,
        });
      }
      serie.sort((a, b) => prepareDataSort("y", a, b));
      sections.sectors = {
        serie: serie,
        title: "Sectors",
      };
    }

    if (data.markets && data.sectors) {
      sections.cross = {
        market_sector: [],
        sector_market: [],
      };

      for (const where in data.markets) {
        item = data.markets[where];
        cross = {
          id: item.id,
          name: item.name,
          content: [],
          value: item.value,
        };

        for (const what in item.sectors) {
          cross.content.push({
            name: item.sectors[what].name,
            value: item.sectors[what].value,
          });
        }
        cross.content.sort((a, b) => prepareDataSort("value", a, b));
        sections.cross.market_sector.push(cross);
      }
      sections.cross.market_sector.sort((a, b) =>
        prepareDataSort("value", a, b)
      );

      for (const what in data.sectors) {
        item = data.sectors[what];
        cross = {
          id: null,
          name: item.name,
          content: [],
          value: item.value,
        };

        for (const where in item.markets) {
          cross.content.push({
            name: item.markets[where].name,
            value: item.markets[where].value,
          });
        }

        cross.content.sort((a, b) => prepareDataSort("value", a, b));
        sections.cross.sector_market.push(cross);
      }
      sections.cross.sector_market.sort((a, b) =>
        prepareDataSort("value", a, b)
      );
    }

    return sections;
  }, [prepareDataSort, previewData]);

  const collectReportData = useCallback(() => {
    return {
      notes: notesInputRef.current?.value.trim(),
      sections,
      title: titleInputRef.current?.value.trim(),
    };
  }, [sections]);

  const _chart = useCallback(
    (serie, node) => {
      Highcharts.getOptions().plotOptions!.pie!.colors = (function () {
        var colors: any = [],
          base = "#2a7092";

        for (let i = 0; i < 9; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(
            (Highcharts.Color as any)(base)
              .brighten((i - 3) / 7)
              .get()
          );
        }
        return colors;
      })();

      return new Highcharts.Chart({
        chart: {
          animation: false,
          height: 250,
          margin: 0,
          renderTo: node,
          type: "pie",
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        title: { text: undefined },
        tooltip: {
          enabled: true,
          formatter: function () {
            var value = formatter.custom("number", {
              options: {
                decimals: 0,
                isPercentage: true,
                notAvailable: {
                  input: "",
                  output: "",
                },
              },
              output: "HTML",
              value: this.point.y,
              valueHelper: null,
            });

            return "<strong>" + this.point.name + "</strong>: " + value;
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              style: {
                color: "#000",
              },
            },
            shadow: false,
            states: {
              hover: {
                halo: {
                  size: 0,
                },
              },
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            name: "Rating",
            animation: false,
            data: serie,
          },
        ],
      });
    },
    [formatter]
  );

  const printChart = useCallback(
    (serie) => {
      const node = document.createElement("div");
      node.style.width = "600px";

      document.body.append(node);
      const chart = _chart(serie, node);
      const svg = chart.getSVG();
      chart.destroy();

      document.body.removeChild(node);

      return svg;
    },
    [_chart]
  );

  const format = useCallback(
    (value) => {
      var formatted = "";
      if (value === 100) {
        formatted = formatter.custom("number", {
          options: {
            decimals: 0,
            isPercentage: true,
            notAvailable: {
              input: "",
              output: "",
            },
          },
          output: "HTML",
          value: value,
          valueHelper: null,
        });
      } else {
        formatted = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: "",
              output: "",
            },
          },
          output: "HTML",
          value: value,
          valueHelper: null,
        });
      }

      return formatted;
    },
    [formatter]
  );

  const getUserPreference = useCallback(() => {
    const preferences =
      environment.get("account").user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = null;

    return userPreference;
  }, [environment]);

  /**
   * Final step on printing
   *
   * @param {object} template
   * @param {object} userPreference
   * @param {object} wysiwygState
   */
  const printTemplate = useCallback(
    (data, printSections, userPreference) => {
      // Add disclaimer from previous data
      printSections.push({
        type: "REPORT_COMMON_PAGE_BREAK",
      });
      printSections.push({
        type: "REPORT_COMMON_DISCLAIMER",
        text: userPreference.disclaimer,
      });

      var template = {
        configuration: {
          integration: {
            peer: null,
            portfolioAnalysis: null,
            screening: null,
            strategy: {
              date: null,
            },
            systematicProduct: null,
          },
          orientation: "portrait",
          sections: printSections,
          theme: null,
        },
        id: null,
        isEditable: false,
        name: data.title,
        ownerId: null,
        type: "TEMPLATE_REPORT_STRATEGY",
        version: "1.0",
      };

      var wysiwygState = {
        target: {
          name: "name",
        },
      };

      var report = new Generator(environment.get("setup"), {
        data: template.configuration.sections,
        formatter: format,
        sections: deepClone(template.configuration.sections),
        template: deepClone(template),
        userPreference: userPreference,
        wysiwygState: wysiwygState,
      });
      return report
        .create()
        .print()
        .then(
          (response) => {
            close();

            var downloader = new Downloader(report);
            downloader.download(response);
          },
          (error) => {
            // Error
            console.error(error);
          }
        );
    },
    [close, environment, format]
  );

  const print = useCallback(() => {
    const reportData = collectReportData();

    let sections = reportData.sections;

    if (sections == null) {
      sections = {};
    }

    const data: any = {
      author: environment.get("account")?.user?.firstName ?? "",
      notes: reportData.notes,
      sections: [],
      title: reportData.title || "Strategic allocation",
      subTitle: "Strategic allocation",
      type: null,
    };

    let dataSection: any = null;
    let item: any = null;
    let row: any = null;
    let subRow: any = null;
    let section: any = null;

    if (sections["cross"]) {
      data.type = "MARKET_SECTOR";
      // markets sectors
      section = sections["cross"]["market_sector"];
      dataSection = {
        title: "Market",
        rows: [],
        chart: printChart(sections["markets"].serie),
      };
      for (let i = 0, length = section.length; i < length; i++) {
        item = section[i];
        if (item.value) {
          row = [format(item.value), item.id, item.name];
          subRow = [];
          for (var j = 0, lengthJ = item.content.length; j < lengthJ; j++) {
            if (item.content[j].value) {
              subRow.push([
                format(item.content[j].value),
                item.content[j].name,
              ]);
            }
          }
          row.push(subRow);
          dataSection.rows.push(row);
        }
      }

      data.sections.push(dataSection);

      // sectors markets
      section = sections["cross"]["sector_market"];
      dataSection = {
        title: "Sector",
        rows: [],
        chart: printChart(sections["sectors"].serie),
      };
      for (let i = 0, length = section.length; i < length; i++) {
        item = section[i];
        if (item.value) {
          row = [format(item.value), null, item.name];
          subRow = [];
          for (let j = 0, lengthJ = item.content.length; j < lengthJ; j++) {
            if (item.content[j].value) {
              subRow.push([
                format(item.content[j].value),
                item.content[j].name,
              ]);
            }
          }
          row.push(subRow);
          dataSection.rows.push(row);
        }
      }

      data.sections.push(dataSection);
    } else if (sections["markets"]) {
      data.type = "MARKET";
      section = sections["markets"];
      dataSection = {
        title: section.title,
        rows: [],
        chart: printChart(section.serie),
      };
      for (let i = 0, length = section.serie.length; i < length; i++) {
        item = section.serie[i];
        if (item.y) {
          dataSection.rows.push([format(item.y), item.id, item.name]);
        }
      }

      data.sections.push(dataSection);
    } else if (sections["sectors"]) {
      data.type = "SECTOR";
      section = sections["sectors"];
      dataSection = {
        title: section.title,
        rows: [],
        chart: printChart(section.serie),
      };
      for (let i = 0, length = section.serie.length; i < length; i++) {
        item = section.serie[i];
        if (item.y) {
          dataSection.rows.push([format(item.y), null, item.name]);
        }
      }

      data.sections.push(dataSection);
    }

    // domClass.remove("data-loader", "hide");

    // ***************************** USAGE *****************************
    var usage = window.App.usage;
    var info = {
      action: "STRATEGIC_ALLOCATION",
      actionParam: null,
      function: "MARKET",
    };
    usage.record(info);
    // ***************************** USAGE *****************************

    var printSections: any = [];

    printSections.push({
      type: "REPORT_COMMON_TITLE",
      text: data.title,
    });

    // data.subTitle is not used anymore

    for (let i = 0; i < data.sections.length; i++) {
      printSections.push({
        type: "REPORT_STRATEGY_ALLOCATION",
        rows: data.sections[i].rows,
        svg: data.sections[i].chart,
        title: data.sections[i].title,
      });
    }

    if (data.notes != null && data.notes !== "") {
      printSections.push({
        type: "REPORT_COMMON_PARAGRAPH",
        fontSize: "normal",
        text: data.notes,
      });
    }

    // TODO Disclaimer generation Moved at the end of the print process, not clean

    var userPreference = getUserPreference();

    if (userPreference == null || userPreference.disclaimer == null) {
      const reportAPI = new Report(environment.get("setup"));
      reportAPI.disclaimer().then((response) => {
        if (userPreference == null) {
          userPreference = {
            disclaimer: response,
          };
        } else {
          userPreference.disclaimer = response;
        }
        printTemplate(data.title, printSections, userPreference);
      });
    } else {
      printTemplate(data.title, printSections, userPreference);
    }
  }, [
    collectReportData,
    environment,
    format,
    getUserPreference,
    printChart,
    printTemplate,
  ]);

  return (
    <Modal
      closeIcon={false}
      customCss={{
        width: "90vw",
        height: "auto",
        maxWidth: "none",
      }}
      headerConfig={{ headerContent: "Strategic Allocation" }}
      onClose={close}
      bodyCustomClass={styles.modalBody}
    >
      <Box className={styles.modal__main}>
        <Box className={styles.modal__main__left}>
          <Box className={styles.modal__main__left__selector}>
            <Typography>Size</Typography>
            <PeerSize onClickItem={handleSizeChange} options={sizeOptions} />
          </Box>
          <Box className={styles.modal__main__left__table__container}>
            <StrategicTable
              value={data}
              storage={storage}
              setCurrentSelection={wrapSetPreviewData}
            />
          </Box>
        </Box>
        <Box className={styles.modal__main__right}>
          <Card sx={{ width: "100%", display: "flex", flex: 1, minHeight: 0 }}>
            <CardContent
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                minHeight: 0,
                overflow: "auto",
              }}
            >
              <div className="tStrategicAllocation-previewTopTitle">
                <h1>Print preview</h1>
              </div>
              <Box marginTop={2} sx={{ flex: 1 }}>
                <TextField
                  inputRef={titleInputRef}
                  fullWidth
                  label="Title"
                  defaultValue={"Strategic Allocation"}
                  id="fullWidth"
                  size="small"
                  autoFocus
                />
              </Box>
              <div className="tStrategicAllocation-preview" style={{ flex: 3 }}>
                <div
                  data-dojo-attach-point="widgetPreview"
                  data-dojo-type="app/pages/analysisMarkets/widgets/StrategicPreview"
                >
                  {sections != null ? (
                    <Box
                      display={"flex"}
                      flex={1}
                      minHeight={0}
                      overflow={"auto"}
                      flexDirection={"column"}
                      gap={1}
                      p={1}
                    >
                      {sections.markets && !sections.sectors && (
                        <SectionPreview type={"markets"} sections={sections} />
                      )}
                      {!sections.markets && sections.sectors && (
                        <SectionPreview type={"sectors"} sections={sections} />
                      )}
                      {sections.cross && (
                        <SectionPreview type="cross" sections={sections} />
                      )}
                    </Box>
                  ) : (
                    <Box>
                      <div
                        className={
                          "tStrategicPreview-help tStrategicPreview-help--initialHelp"
                        }
                      >
                        Use momentum to calculate a winning allocation across
                        markets and/or sectors.
                        <br />
                        Just click on markets and/or sectors.
                      </div>
                    </Box>
                  )}
                </div>
              </div>
              <div
                className="tStrategicAllocation-previewBottom"
                style={{ flex: 1, marginTop: "8px" }}
              >
                <label>Notes</label>
                <br />
                <textarea
                  ref={notesInputRef}
                  className="form__field-text"
                  placeholder="Notes"
                  rows={3}
                  data-dojo-attach-point="nodeNotes"
                ></textarea>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} gap={1} mt={2}>
        <Button variant="contained" onClick={print}>
          Print
        </Button>
        <Button variant="tr_button_cancel" onClick={close}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

const baseClass = "tStrategicTable";
const cssClassCell = baseClass + "-tableCell";
const cssClassCellCompound = cssClassCell + "--compound";
const cssClassCellContentWrapper = cssClassCell + "ContentWrapper";
const cssClassCellContentWrapperWhere = cssClassCellContentWrapper + "--where";
const cssClassCellContentWhere = cssClassCell + "ContentWhere";
const cssClassCellHasTooltip = cssClassCell + "--hasTooltip";
const cssClassCellABPercentage = baseClass + "--abPercentage isDisabled";

let trArea: any = null;

const StrategicTable = memo(({ value, storage, setCurrentSelection }: any) => {
  const params = useMemo(() => value?.["paramsTable"], [value]);
  const tableDataBody = useMemo(() => value?.["table"]?.["body"], [value]);
  const tableDataHead = useMemo(() => value?.["table"]?.["head"], [value]);

  const formatter = useFormatter();
  const environment = useEnvironment();
  const { t } = useTranslation();

  const updatePreview = useCallback(
    (selection) => {
      setCurrentSelection(selection);
    },
    [setCurrentSelection]
  );

  const renderImage = useCallback((where, name) => {
    return (
      '<img src="' +
      appConfig.baseUrlImages +
      "/flags/" +
      where +
      '.png" width="24" height="24" alt="' +
      name +
      "/>"
    );
  }, []);

  const formatPercentage = useCallback(
    (value) => {
      return formatter.custom("number", {
        options: {
          isPercentage: true,
          notAvailable: {
            input: "",
            output: "0.00%",
          },
        },
        output: "HTML",
        value: value,
        valueHelper: null,
      });
    },
    [formatter]
  );

  const theadRef = useRef<HTMLTableSectionElement>(null);

  const tcrMeta = useMemo(() => _rate, []);

  const getDecorator = useCallback(
    (data, cellType) => {
      cellType = cellType == null ? "cell" : cellType;

      var tcr = data["tcr"]["today"];
      var tcrCss = tcrMeta["trendCaptureRating"]["U"];

      if (tcr != null) {
        tcrCss = tcrMeta["trendCaptureRating"][tcr];
      }

      var cssClassCell = baseClass + "-tableCell";
      var cssClassCellTcrPrefix = baseClass + "-tableCell--tcr-";
      var cssClassCellWhat = baseClass + "-tableCell--what";
      var cssClassCellWhere = baseClass + "-tableCell--where";

      var cssClasses = [cssClassCell];

      switch (cellType) {
        case "cellWhat": {
          cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);
          cssClasses.push(cssClassCellWhat);

          break;
        }
        case "cellWhere": {
          cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);
          cssClasses.push(cssClassCellWhere);

          break;
        }
        case "cell": {
          // cssClasses.push(cssClassCellTcrPrefix + tcrCss["tcrClass"]);

          break;
        }
        case "cellEmpty":
        default: {
        }
      }

      return cssClasses;
    },
    [tcrMeta]
  );

  const format = useCallback(
    (value) => {
      var formatted = "";
      if (value === 100) {
        formatted = formatter.custom("number", {
          options: {
            decimals: 0,
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: value,
        });
      } else {
        formatted = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: 0,
              output: "",
            },
          },
          output: "HTML",
          value: value,
        });
      }
      return formatted;
    },
    [formatter]
  );

  const prepareData = useCallback(
    (markets, sectors) => {
      const taxonomies = environment.get("setup")["taxonomies"];
      const txFields = environment.get("setup")["taxonomyFields"];
      let taxonomyWhat = taxonomies[txFields["security"]["sector"]];
      let taxonomyWhere = taxonomies[txFields["security"]["country"]];

      var cssClass: any = null;
      var data: any = {
        markets: null,
        sectors: null,
      };
      var node: any = null;
      var market: any = null;
      var marketsTotalAB: any = 0;
      var matrixTotalAB: any = 0;
      var sector: any = null;
      var sectorsTotalAB: any = 0;
      var value: any = null;

      // data
      // cross
      if (markets.length && sectors.length) {
        // totals
        for (let i = 0, lengthI = markets.length; i < lengthI; i++) {
          for (var j = 0, lengthJ = sectors.length; j < lengthJ; j++) {
            cssClass =
              '[data-peer-where="' +
              markets[i].where +
              '"][data-peer-what="' +
              sectors[j].what +
              '"]';

            node = nodeTable.current?.querySelectorAll(`${cssClass}`)?.[0];
            if (node) {
              value = parseFloat(node.dataset["peerAbAllocation"]);
              matrixTotalAB = matrixTotalAB + value;
            }
          }
        }
        data.markets = {};
        data.sectors = {};
        for (let i = 0, lengthI = markets.length; i < lengthI; i++) {
          market = markets[i];
          if (market.type === "ETF") {
            taxonomyWhere = taxonomies[txFields["ETF"]["etfgeo"]];
          }
          const marketInfo = taxonomyWhere[market.where];
          for (let j = 0, lengthJ = sectors.length; j < lengthJ; j++) {
            sector = sectors[j];
            if (sector.type === "ETF") {
              taxonomyWhere = taxonomies[txFields["ETF"]["etfclass"]];
            }
            const sectorInfo = taxonomyWhat[sector.what];
            cssClass =
              '[data-peer-where="' +
              market.where +
              '"][data-peer-what="' +
              sector.what +
              '"]';
            node = nodeTable.current?.querySelectorAll(`${cssClass}`)?.[0];

            if (node) {
              value = 0;
              if (matrixTotalAB) {
                value =
                  parseFloat(node.dataset["peerAbAllocation"]) / matrixTotalAB; // * 100
              }

              // cross markets
              if (!(market.where in data.markets)) {
                data.markets[market.where] = {
                  id: marketInfo.type === "Country" ? market.where : null,
                  name: marketInfo.name,
                  parent: marketInfo.parent,
                  type: marketInfo.type,
                  value: value,
                  sectors: {},
                };

                if (marketInfo.type === "Region") {
                  data.markets[market.where].name =
                    taxonomyWhere[marketInfo.parent].name +
                    " - " +
                    marketInfo.name;
                }
              } else {
                data.markets[market.where].value += value;
              }

              data.markets[market.where].sectors[sector.what] = {
                id: sector.what,
                name: sectorInfo.name,
                parent: sectorInfo.parent,
                type: sectorInfo.type,
                value: value,
              };
              // cross sectors
              if (!(sector.what in data.sectors)) {
                data.sectors[sector.what] = {
                  id: sector.what,
                  name: sectorInfo.name,
                  parent: sectorInfo.parent,
                  type: sectorInfo.type,
                  value: value,
                  markets: {},
                };
              } else {
                data.sectors[sector.what].value += value;
              }

              data.sectors[sector.what].markets[market.where] = {
                id: market.where,
                name: marketInfo.name,
                parent: marketInfo.parent,
                type: marketInfo.type,
                value: value,
              };

              if (marketInfo.type === "Region") {
                data.sectors[sector.what].markets[market.where].name =
                  taxonomyWhere[marketInfo.parent].name +
                  " - " +
                  marketInfo.name;
              }
            }
          }
        }
      } else {
        // totals
        // markets
        for (let i = 0, length = markets.length; i < length; i++) {
          marketsTotalAB =
            marketsTotalAB + markets[i].statistic.abPercentage["today"];
        }
        // sectors
        for (let i = 0, length = sectors.length; i < length; i++) {
          sectorsTotalAB =
            sectorsTotalAB + sectors[i].statistic.abPercentage["today"];
        }
        // markets
        if (marketsTotalAB) {
          // avoid 0 division
          data.markets = {};
          for (let i = 0; i < markets.length; i++) {
            market = markets[i];
            if (market.type === "ETF") {
              taxonomyWhere = taxonomies[txFields["ETF"]["etfgeo"]];
            }
            var marketInfo = taxonomyWhere[market.where];
            value = market.statistic.abPercentage["today"] / marketsTotalAB; // * 100
            data.markets[market.where] = {
              id: marketInfo.type === "Country" ? market.where : null,
              name: marketInfo.name,
              parent: marketInfo.parent,
              type: marketInfo.type,
              value: value,
              sectors: null,
            };

            if (marketInfo.type === "Region") {
              data.markets[market.where].name =
                taxonomyWhere[marketInfo.parent].name + " - " + marketInfo.name;
            }
          }
        }
        // sectors
        if (sectorsTotalAB) {
          // avoid 0 division
          data.sectors = {};
          for (let i = 0; i < sectors.length; i++) {
            sector = sectors[i];
            if (sector.type === "ETF") {
              taxonomyWhere = taxonomies[txFields["ETF"]["etfclass"]];
            }
            var sectorInfo = taxonomyWhat[sector.what];
            value = sector.statistic.abPercentage["today"] / sectorsTotalAB;
            data.sectors[sector.what] = {
              id: sector.what,
              name: sectorInfo.name,
              parent: sectorInfo.parent,
              type: sectorInfo.type,
              value: value,
              markets: null,
            };
          }
        }
      }

      return data;
    },
    [environment]
  );

  const selection = useRef<{
    markets: { data: any[] };
    sectors: { data: any[] };
    formatted: any;
  }>({
    markets: { data: [] },
    sectors: { data: [] },
    formatted: null,
  });

  const handleCellClick = useCallback(
    (event) => {
      if (!value || !value.store) {
        return;
      }

      const targetNode: HTMLElement = event.currentTarget;
      var peerId = targetNode.dataset["peerId"];
      if (peerId == null) {
        return;
      }

      var peer = value.store.find((item) => item.id === peerId);
      if (peer == null) {
        return;
      }

      var cssClass: any = null;
      var cssClassSelected = "isCellSelected";
      var cssClassDisabled = "isDisabled";
      var item: any = null;
      var node: any = null;
      var nodeContainer: any = null;

      // add/remove cell
      if (targetNode.classList.contains(cssClassSelected)) {
        targetNode.classList.remove(cssClassSelected);

        if (peer.where && (!peer.what || peer.what === "ICB")) {
          selection.current.markets.data =
            selection.current.markets.data.filter(
              (item) => item.where !== peer.where
            );
        } else {
          selection.current.sectors.data =
            selection.current.sectors.data.filter(
              (item) => item.what !== peer.what
            );
        }
      } else {
        targetNode.classList.add(cssClassSelected);
        if (peer.where && (!peer.what || peer.what === "ICB")) {
          selection.current.markets.data.push(peer);
        } else {
          selection.current.sectors.data.push(peer);
        }
      }

      // fresh data
      var markets = selection.current.markets.data;
      var sectors = selection.current.sectors.data;

      var abPercentageClass = "." + baseClass + "--abPercentage";

      // cleanup
      if (!targetNode.classList.contains(cssClassSelected)) {
        node = targetNode.querySelectorAll(`${abPercentageClass}`)[0];
        node.classList.add(cssClassDisabled);
        node.innerHTML = "";

        if (markets.length) {
          for (let i = 0, length = markets.length; i < length; i++) {
            var market = markets[i];
            cssClass =
              '[data-peer-where="' +
              market.where +
              '"][data-peer-what="' +
              peer.what +
              '"]';

            node = nodeTable.current?.querySelectorAll(`${cssClass}`)?.[0];
            if (node) {
              node.classList.remove("data__value--full");

              node = node.querySelectorAll(`${abPercentageClass}`)?.[0];

              if (node) {
                node.innerHTML = "";
              }
            }
          }
        }

        if (sectors.length) {
          for (let i = 0, length = sectors.length; i < length; i++) {
            var sector = sectors[i];
            cssClass =
              '[data-peer-where="' +
              peer.where +
              '"][data-peer-what="' +
              sector.what +
              '"]';
            node = nodeTable?.current?.querySelectorAll(`${cssClass}`)?.[0];

            if (node) {
              node.classList.remove("data__value--full");
              node = node?.querySelectorAll(`${abPercentageClass}`)?.[0];

              if (node) {
                node.innerHTML = "";
              }
            }
          }
        }
      }

      // set old AB value
      node = targetNode.querySelectorAll(`${abPercentageClass}`)?.[0];
      node.innerHTML = format(
        parseFloat(targetNode.dataset["peerAbAllocation"]!)
      );
      // new data and rendering
      selection.current.formatted = prepareData(markets, sectors);
      var data = selection.current.formatted;

      if (data.markets) {
        for (var where in data.markets) {
          cssClass = '[data-peer-where="' + where + '"][data-peer-what="ICB"]';
          nodeContainer = nodeTable.current?.querySelectorAll(
            `${cssClass}`
          )?.[0];
          node = nodeContainer.querySelectorAll(`${abPercentageClass}`)?.[0];
          if (node) {
            node.classList.remove(cssClassDisabled);
            const value = data.markets[where].value;
            node.innerHTML = format(value);
          }
        }
      }

      if (data.sectors) {
        for (var what in data.sectors) {
          cssClass = '[data-peer-where="WWW"][data-peer-what="' + what + '"]';

          nodeContainer = nodeTable.current?.querySelectorAll(
            `${cssClass}`
          )?.[0];
          node = nodeContainer?.querySelectorAll(`${abPercentageClass}`)?.[0];
          if (node) {
            node.classList.remove(cssClassDisabled);
            const value = data.sectors[what].value;
            node.innerHTML = format(value);
          }
        }
      }

      if (data.markets && data.sectors) {
        // sub info are the same in both maps
        for (const where in data.markets) {
          item = data.markets[where];
          for (const what in item.sectors) {
            const value = item.sectors[what].value;
            if (value != null && value > 0) {
              cssClass =
                '[data-peer-where="' +
                where +
                '"][data-peer-what="' +
                what +
                '"]';
              node = nodeTable.current?.querySelectorAll(`${cssClass}`)?.[0];
              if (node) {
                node.classList.add("data__value--full");

                node = node.querySelectorAll(`${abPercentageClass}`)?.[0];
                if (node) {
                  node.classList.remove(cssClassDisabled);
                  node.innerHTML = format(value);
                }
              }
            }
          }
        }
      }

      updatePreview(deepClone(selection.current));
    },
    [format, prepareData, updatePreview, value]
  );

  const nodeTable = useRef<Element>(null);

  return (
    <Box ref={nodeTable}>
      <table className="tStrategicTable-table">
        {value != null ? (
          <thead ref={theadRef}>
            <tr>
              {tableDataHead?.[0].map((cellData) => {
                if (cellData == null) {
                  return (
                    <th key={uuidv4()} className={baseClass + "-tableCellHead"}>
                      AB %
                    </th>
                  );
                } else {
                  var abPercentage =
                    cellData["statistic"]["abPercentage"]["today"];

                  let cellContent: any = null;

                  switch (params["whereType"]) {
                    case "Country": {
                      cellContent = [
                        renderImage(cellData.where, cellData.name),
                        '<span class="iso">',
                        cellData.where,
                        '</span> <span class="' +
                          cssClassCellABPercentage +
                          '">',
                        formatPercentage(abPercentage),
                        "</span>",
                      ].join("");

                      break;
                    }
                    case "Region": {
                      if (trArea == null) {
                        var areas = storage.getAreas(params["instrumentType"]);

                        const row = document.createElement("tr");
                        const emptyCell = document.createElement("th");

                        row.append(emptyCell);

                        let th: any = null;
                        for (const area of areas) {
                          th = document.createElement("th");
                          th.classList.add(
                            [cssClassCell, cssClassCellCompound].join(" ")
                          );

                          th.innerHTML = `${area["name"]}`;
                          row.append(th);
                        }

                        if (theadRef.current) {
                          theadRef.current.insertBefore(
                            row,
                            theadRef.current.firstChild
                          );
                        }
                      }

                      cellContent = "<span>" + cellData.name + "</span>";

                      cellContent +=
                        '<span class="' +
                        cssClassCellABPercentage +
                        '">' +
                        formatPercentage(abPercentage) +
                        "</span>";

                      break;
                    }
                    case "World": {
                      cellContent = [
                        renderImage(t("World"), t("World")),
                        ' <span class="iso">',
                        t("World"),
                        '</span> <span class="' +
                          cssClassCellABPercentage +
                          '">',
                        formatPercentage(abPercentage),
                        "</span>",
                      ].join("");

                      break;
                    }
                    default: {
                      cellContent = cellData["name"];
                    }
                  }

                  const classesTh = getDecorator(cellData, "cellWhere");

                  return (
                    <th
                      onClick={handleCellClick}
                      key={uuidv4()}
                      data-peer-id={cellData.id}
                      data-peer-ab-allocation={abPercentage}
                      data-peer-what={cellData.what}
                      data-peer-where={cellData.where}
                      data-peer-cardinality={cellData.info.cardinality}
                      className={[
                        cssClassCell,
                        cssClassCellHasTooltip,
                        ...classesTh,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          cssClassCellContentWrapper,
                          cssClassCellContentWrapperWhere,
                        ].join(" ")}
                      >
                        <div
                          className={cssClassCellContentWhere}
                          dangerouslySetInnerHTML={{ __html: cellContent }}
                        ></div>
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
        ) : (
          <thead>
            <tr>
              <th
                dangerouslySetInnerHTML={{ __html: t("no_data_to_display") }}
              ></th>
            </tr>
          </thead>
        )}
        <tbody>
          {tableDataBody?.map((row) => {
            return (
              <tr key={uuidv4()}>
                {row.map((cellData, index) => {
                  const isRowHead = index === 0;
                  let hasData = cellData.info.cardinality > 0 ? true : false;

                  var cssClassCellContentWrapper =
                    baseClass + "-tableCellContentWrapper";
                  var cssClassCellContentWrapperEmpty =
                    cssClassCellContentWrapper + "--empty";
                  var cssClassCellContentWrapperWhat =
                    cssClassCellContentWrapper + "--what";
                  var cssClassCellContentWhat =
                    baseClass + "-tableCellContentWhat";
                  var cssClassCellHasTooltip =
                    baseClass + "-tableCell--hasTooltip";
                  var cssClassCellABPercentage =
                    baseClass + "--abPercentage isDisabled";

                  let abPercentage =
                    cellData["statistic"]["abPercentage"]["today"];

                  if (hasData) {
                    if (isRowHead) {
                      const additionalClasses = getDecorator(
                        cellData,
                        "cellWhat"
                      );
                      return (
                        <td
                          onClick={handleCellClick}
                          key={uuidv4()}
                          data-peer-id={cellData.id}
                          data-peer-ab-allocation={abPercentage}
                          data-peer-what={cellData.what}
                          data-peer-where={cellData.where}
                          data-peer-cardinality={cellData.info.cardinality}
                          className={[
                            cssClassCellHasTooltip,
                            ...additionalClasses,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              cssClassCellContentWrapper,
                              cssClassCellContentWrapperWhat,
                            ].join(" ")}
                          >
                            <div
                              className={cssClassCellContentWhat}
                              dangerouslySetInnerHTML={{
                                __html:
                                  cellData["name"] === "__ROOT__"
                                    ? t("Any")
                                    : cellData.name +
                                      '<span class="' +
                                      cssClassCellABPercentage +
                                      '">' +
                                      formatPercentage(abPercentage) +
                                      "</span>",
                              }}
                            ></div>
                          </div>
                        </td>
                      );
                    } else {
                      const additionalClasses = getDecorator(cellData, "cell");
                      return (
                        <td
                          key={uuidv4()}
                          data-peer-id={cellData.id}
                          data-peer-ab-allocation={abPercentage}
                          data-peer-what={cellData.what}
                          data-peer-where={cellData.where}
                          data-peer-cardinality={cellData.info.cardinality}
                          className={[
                            cssClassCellHasTooltip,
                            ...additionalClasses,
                          ].join(" ")}
                        >
                          <span className={cssClassCellABPercentage}></span>
                        </td>
                      );
                    }
                  } else {
                    const emptyClasses = getDecorator(cellData, "cellEmpty");

                    return (
                      <td
                        key={uuidv4()}
                        data-peer-id={cellData.id}
                        data-peer-ab-allocation={abPercentage}
                        data-peer-what={cellData.what}
                        data-peer-where={cellData.where}
                        data-peer-cardinality={cellData.info.cardinality}
                        className={emptyClasses.join(" ")}
                      >
                        <div
                          className={[
                            cssClassCellContentWrapper,
                            cssClassCellContentWrapperEmpty,
                          ].join(" ")}
                        ></div>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
});

const SectionPreview = ({
  type,
  sections,
}: {
  type: "markets" | "sectors" | "cross";
  sections: any;
}) => {
  const formatter = useFormatter();

  const format = useCallback(
    (value) => {
      var formatted = "";
      if (value === 100) {
        formatted = formatter.custom("number", {
          options: {
            decimals: 0,
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: value,
        });
      } else {
        formatted = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: 0,
              output: "",
            },
          },
          output: "HTML",
          value: value,
        });
      }
      return formatted;
    },
    [formatter]
  );

  const baseImageUrl = window.appConfig.baseUrlImages;

  const chart = useCallback(
    (data, node, customSize) => {
      if (node) {
        Highcharts.getOptions().plotOptions!.pie!.colors = (function () {
          var colors: any = [],
            base = "#2a7092";
          for (let i = 0; i < 9; i += 1) {
            // Start out with a darkened base color (negative brighten), and end
            // up with a much brighter color
            colors.push(
              (Highcharts.Color as any)(base)
                .brighten((i - 3) / 7)
                .get()
            );
          }
          return colors;
        })();

        return new Highcharts.Chart({
          chart: {
            animation: false,
            height: customSize || 250,
            margin: 0,
            renderTo: node,
            type: "pie",
          },
          credits: { enabled: false },
          exporting: { enabled: false },
          title: { text: undefined },
          tooltip: {
            enabled: true,
            formatter: function () {
              var value = formatter.custom("number", {
                options: {
                  decimals: 0,
                  isPercentage: true,
                  notAvailable: {
                    input: "",
                    output: "",
                  },
                },
                output: "HTML",
                value: this.point.y,
                valueHelper: null,
              });

              return "<strong>" + this.point.name + "</strong>: " + value;
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                style: {
                  color: "#000",
                },
              },
              shadow: false,
              states: {
                hover: {
                  halo: {
                    size: 0,
                  },
                },
                inactive: {
                  opacity: 1,
                },
              },
            },
          },
          series: [
            {
              type: "pie",
              name: "Rating",
              animation: false,
              data: data,
            },
          ],
        });
      }
    },
    [formatter]
  );

  return (
    <Card>
      <CardContent>
        {type === "cross" ? (
          <>
            <h1 className="tStrategicPreview-table__title">
              {sections["markets"].title}
            </h1>
            <div className="layout-grid">
              <div className="layout-grid__col layout-grid__col--50">
                <table className="tStrategicPreview-table">
                  <tbody>
                    {sections["cross"]["market_sector"]?.map((item) => {
                      if (item.value > 0) {
                        return (
                          <>
                            <tr>
                              <td
                                className="tStrategicPreview-table__item-value"
                                rowSpan={2}
                                dangerouslySetInnerHTML={{
                                  __html: format(item.value),
                                }}
                              ></td>
                              <td
                                className="tStrategicPreview-table__item-name"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (item.id
                                      ? '<img class="tStrategicPreview-table__item-flag" src="' +
                                        baseImageUrl +
                                        "/flags/" +
                                        item.id +
                                        '.png"/>'
                                      : "") + item.name,
                                }}
                              ></td>
                            </tr>
                            <tr>
                              <td className="tStrategicPreview-table__subitems">
                                {item.content.map((subItem) => {
                                  if (subItem) {
                                    return (
                                      <p>
                                        <strong
                                          className="tStrategicPreview-table__subitem-value"
                                          dangerouslySetInnerHTML={{
                                            __html: format(subItem.value),
                                          }}
                                        ></strong>
                                        <span className="tStrategicPreview-table__subitem-name">
                                          {subItem.name}
                                        </span>
                                      </p>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })}
                              </td>
                            </tr>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <div className={"layout-grid__col layout-grid__col--50"}>
                <div className="tStrategicPreview-table__chart">
                  <div
                    ref={(node) => chart(sections["markets"].serie, node, 180)}
                  ></div>
                </div>
              </div>
            </div>
            <h1 className="tStrategicPreview-table__title">
              {sections["sectors"].title}
            </h1>
            <div className={"layout-grid"}>
              <div className="layout-grid__col layout-grid__col--50">
                <table className="tStrategicPreview-table">
                  <tbody>
                    {sections["cross"]["sector_market"]?.map((item) => {
                      if (item.value > 0) {
                        return (
                          <>
                            <tr>
                              <td
                                className="tStrategicPreview-table__item-value"
                                rowSpan={2}
                                dangerouslySetInnerHTML={{
                                  __html: format(item.value),
                                }}
                              ></td>
                              <td
                                className="tStrategicPreview-table__item-name"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (item.id
                                      ? '<img class="tStrategicPreview-table__item-flag" src="' +
                                        baseImageUrl +
                                        "/flags/" +
                                        item.id +
                                        '.png"/>'
                                      : "") + item.name,
                                }}
                              ></td>
                            </tr>
                            <tr>
                              <td className="tStrategicPreview-table__subitems">
                                {item.content.map((subItem) => {
                                  if (subItem) {
                                    return (
                                      <p>
                                        <strong
                                          className="tStrategicPreview-table__subitem-value"
                                          dangerouslySetInnerHTML={{
                                            __html: format(subItem.value),
                                          }}
                                        ></strong>
                                        <span className="tStrategicPreview-table__subitem-name">
                                          {subItem.name}
                                        </span>
                                      </p>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })}
                              </td>
                            </tr>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <div className="layout-grid__col layout-grid__col--50">
                <div className="tStrategicPreview-table__chart">
                  <div
                    ref={(node) => chart(sections["sectors"].serie, node, 180)}
                  ></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="tStrategicPreview-table__title">
              {sections[type].title}
            </h1>
            <div className="layout-grid">
              <div className="layout-grid__col layout-grid__col--50">
                <table className="tStrategicPreview-table">
                  <tbody>
                    {sections[type].serie.map((item) => {
                      if (item.y > 0) {
                        return (
                          <tr key={uuidv4()}>
                            <td
                              className="tStrategicPreview-table__item-value"
                              dangerouslySetInnerHTML={{
                                __html: format(item.y),
                              }}
                            ></td>
                            <td
                              className="tStrategicPreview-table__item-name"
                              dangerouslySetInnerHTML={{
                                __html:
                                  (item.id
                                    ? '<img class="tStrategicPreview-table__item-flag" src="' +
                                      baseImageUrl +
                                      "/flags/" +
                                      item.id +
                                      '.png"/>'
                                    : "") + item.name,
                              }}
                            ></td>
                          </tr>
                        );
                      }

                      return <></>;
                    })}
                  </tbody>
                </table>
              </div>
              <div className="layout-grid__col layout-grid__col--50">
                <div className="tStrategicPreview-table__chart">
                  <div
                    ref={(node) => chart(sections[type].serie, node, 180)}
                  ></div>
                </div>
              </div>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
