import { useCallback, useMemo } from "react";
import { SystematicProduct } from "../../../../../types/Api";
import { Product } from "../../../storage/SystematicPortfoliosStorage";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { config } from "../../../config-ts";
import { useFormatter } from "../../../../../hooks/useFormatter";
import { CSV } from "../../../utils/SingletonCSV";

type ExportChartActionProps = {
  //           properties,
  storage: Product;
  target: SystematicProduct;
};

export function ExportChartAction({ target, storage }: ExportChartActionProps) {
  const usage = useMemo(() => window.App.usage, []);
  const title = useMemo(() => `Export data of ${target?.name}`, [target?.name]);
  const { broadcast } = useBroadcast();
  const formatter = useFormatter();

  const logUsage = useCallback(() => {
    // **************** USAGE ******************
    var info = {
      action: "EXPORT",
      actionParam: target?.["id"],
      function: "SYSTEMATIC_PORTFOLIOS",
    };
    usage.record(info);
    // **************** USAGE ******************
  }, [target, usage]);

  const errorHandler = useCallback(
    (error) => {
      console.log(error);

      const product = target;

      var message = {
        from: "SYSTEMATIC_PRODUCT",
        content: {
          type: "error",
          text: `Error exporting data for systematic product <strong>${product.name}</strong>.`,
        },
      };

      broadcast(config["channels"]["feedback"]["input"], message);
    },
    [broadcast, target]
  );

  const format = useCallback(
    (strategyCurve, benchmarkCurve) => {
      var lines: any = [];
      // header
      var header = ['"Date"', '"Systematic Portfolio"'];
      if (benchmarkCurve) {
        header.push('"Benchmark"');
      }
      lines.push(header);
      // body
      var line: any = null;
      for (let i = 0, length = strategyCurve.length; i < length; i++) {
        line = [
          '"' +
            formatter.custom("date", {
              options: {
                isMillisecond: true,
                notAvailable: {
                  input: "",
                  output: "",
                },
              },
              output: "TEXT",
              value: strategyCurve[i][0],
              valueHelper: null,
            }) +
            '"',
          '"' + strategyCurve[i][1].toFixed(6) + '"',
        ];

        if (benchmarkCurve && benchmarkCurve[i][1] !== "") {
          line.push('"' + benchmarkCurve[i][1].toFixed(6) + '"');
        }

        lines.push(line);
      }

      return lines;
    },
    [formatter]
  );

  const csv = useCallback(
    (product, response) => {
      var strategyCurve: any = response.chart.list.serie;
      var benchmarkCurve: any = null;
      if (response.chart.benchmark != null) {
        benchmarkCurve = response.chart.benchmark.serie;
      }

      // managing not aligned data
      if (benchmarkCurve && strategyCurve.length !== benchmarkCurve.length) {
        var hashTable = {};
        for (var i = 0, length = strategyCurve.length; i < length; i++) {
          hashTable[strategyCurve[i][0]] = {
            price: strategyCurve[i][1],
            benchmark: "",
          };
        }
        for (let i = 0, length = benchmarkCurve.length; i < length; i++) {
          if (benchmarkCurve[i][0] in hashTable) {
            hashTable[benchmarkCurve[i][0]].benchmark = benchmarkCurve[i][1];
          } else {
            hashTable[benchmarkCurve[i][0]] = {
              price: "",
              benchmark: benchmarkCurve[i][1],
            };
          }
        }
        benchmarkCurve = [];
        strategyCurve = [];
        var row: any = null;
        for (var timestamp in hashTable) {
          row = hashTable[timestamp];
          strategyCurve.push([parseInt(timestamp), row.price]);
          if (benchmarkCurve) {
            benchmarkCurve.push([parseInt(timestamp), row.benchmark]);
          }
        }
      }
      var lines = format(strategyCurve, benchmarkCurve);

      CSV.create(lines, product.name + ".csv");
    },
    [format]
  );

  const doAction = useCallback(async () => {
    logUsage();

    try {
      const chart = await storage.chart({ years: 10 });
      csv(target, chart);
    } catch (error) {
      errorHandler(error);
    }
  }, [csv, errorHandler, logUsage, storage, target]);

  return (
    <li title={title} className="menu__item" onClick={doAction}>
      Export
    </li>
  );
}
