import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeLoader } from "../../utils";
import { config } from "../../config-ts";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { Users } from "../../../../api/account/Users";
import { useBroadcast } from "../../../../hooks/useBroadcast";

export function Page404() {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const { broadcast } = useBroadcast();
  const usersAPI = useMemo(() => {
    return new Users(environment.get("setup"));
  }, [environment]);

  useEffect(() => {
    removeLoader();
  }, []);

  useEffect(() => {
    var message = {
      from: "PAGE_404",
      content: {
        actions: [],
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast]);

  const listenerLink = useCallback(
    async (event) => {
      event.preventDefault();

      // **************************** USAGE ******************************
      var usage = window.App.usage;
      var info = {
        action: "LOGOUT",
        actionParam: null,
        function: "LOGOUT",
      };
      usage.record(info);
      // **************************** USAGE ******************************

      await usersAPI.logout();

      window.location.href = config.routes.appAccount;
    },
    [usersAPI]
  );

  return (
    <article className="tPage404 tPage">
      <h1>{t("Page_not_found")}</h1>
      <p
        dangerouslySetInnerHTML={{ __html: t("If_you_have_the_SMS_Premium_") }}
      ></p>
      <p>
        {t("If_you_think_something_is_not_working_properly")}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={listenerLink}>
          {" "}
          {t("Try_to_login_again")}
        </a>
        .
      </p>
      <p
        dangerouslySetInnerHTML={{
          __html:
            t("If_the_problem_persists_contact_the_Trendrating_Customer_Care") +
            ".",
        }}
      ></p>
    </article>
  );
}
