export type TCRType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    marketAllocation: string;
    marketRows: number;
    sectorAllocation: string;
    sectorRows: number;
  };
  presentation: {
    alert: boolean;
    marketAllocation: boolean;
    portfolioMomentumRating: boolean;
    ratingWeight: boolean;
    sectorAllocation: boolean;
  };
};
type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_MARKET_ALLOCATION"
    | "SET_CONTENT_MARKET_ROWS"
    | "SET_CONTENT_SECTOR_ALLOCATION"
    | "SET_CONTENT_SECTOR_ROWS"
    | "SET_PRESENTATION_ALERT"
    | "SET_PRESENTATION_MARKET_ALLOCATION"
    | "SET_PRESENTATION_PORTFOLIO_MOMENTUM_RATING"
    | "SET_PRESENTATION_RATING_WEIGHT"
    | "SET_PRESENTATION_SECTOR_ALLOCATION";
  payload: any;
};

export const tcrReducer = (draft: TCRType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_MARKET_ALLOCATION":
      draft.content.marketAllocation = action.payload;
      break;
    case "SET_CONTENT_MARKET_ROWS":
      draft.content.marketRows = action.payload;
      break;
    case "SET_CONTENT_SECTOR_ALLOCATION":
      draft.content.sectorAllocation = action.payload;
      break;
    case "SET_CONTENT_SECTOR_ROWS":
      draft.content.sectorRows = action.payload;
      break;
    case "SET_PRESENTATION_ALERT":
      draft.presentation.alert = action.payload;
      break;
    case "SET_PRESENTATION_MARKET_ALLOCATION":
      draft.presentation.marketAllocation = action.payload;
      break;
    case "SET_PRESENTATION_PORTFOLIO_MOMENTUM_RATING":
      draft.presentation.portfolioMomentumRating = action.payload;
      break;
    case "SET_PRESENTATION_RATING_WEIGHT":
      draft.presentation.ratingWeight = action.payload;
      break;
    case "SET_PRESENTATION_SECTOR_ALLOCATION":
      draft.presentation.sectorAllocation = action.payload;
      break;
    default:
      break;
  }
};
