/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/utils
 * @summary Utilities
 *
 */

const CONSTRAINTS = {
    minEmailLength: 6,
    minPasswordLength: 2,
};

export function arePasswordsEqual(
    password: string,
    passwordCheck: string
): boolean {
    return password === passwordCheck;
}

export function isValidEmail(email: string): boolean {
    if (email.length >= CONSTRAINTS.minEmailLength) {
        return true;
    }

    return false;
}

export function isValidPassword(password: string): boolean {
    if (password.length >= CONSTRAINTS.minPasswordLength) {
        return true;
    }

    return false;
}

/**
 * Add query parameters to a search context (filterAdvanced endpoint)
 *
 * Substitute the trendrating/api/compute/Query class and addParam method.
 *
 * @param {object} context - a search context
 * @param {string} key - the key in search context in which add
 *   parameter. If key doesn't exist, it is created. Key value is one
 *   of: filters, ranges, relations
 * @param {any} value - the parameter value
 */
export function addQueryParam(context: any, key: any, value: any) {
    if (!(key in context)) {
        context[key] = [];
    }

    context[key].push(value);
}

export function toFloat(
    object: Record<string, any>,
    property: string,
    emptyValue: number | null = null
): number | null {
    return object[property] != null ? parseFloat(object[property]) : emptyValue;
}

export function toInt(
    object: Record<string, any>,
    property: string,
    emptyValue: number | null = null
): number | null {
    return object[property] != null
        ? parseInt(object[property], 10)
        : emptyValue;
}

/**
 * Decode a peerId in order to be suitable for API requests
 *
 * @param {string} peerId - the peer ID. It is a string with this
 *      structure
 *
 *          where-what-instrumentType-size
 *
 *      e.g. CH-50-Stock-microLarge
 *
 * @returns {object}
 */
export function decodePeerId(peerId) {
    var tokens = peerId.split("-");

    var decodedId = {
        zDimension: tokens[3],
        type: tokens[2],
        where: tokens[0],
        what: tokens[1],
    };

    return decodedId;
}

/**
 * Generate a peer id
 *
 * @param {object} peerBasicInfo - basic information about a peer
 * @param {string} peerBasicInfo.size - one of microLarge, etc.
 * @param {string} peerBasicInfo.type - "ETF" or "Stock"
 * @param {string} peerBasicInfo.what - GICS id or ETFclassification id
 * @param {string} peerBasicInfo.where - MarketFinancial id
 *
 * @returns {string} - a peer id
 */
export function encodePeerId(peerBasicInfo) {
    var id = [
        peerBasicInfo["where"],
        peerBasicInfo["what"],
        peerBasicInfo["type"],
        peerBasicInfo["zDimension"],
    ].join("-");

    return id;
}
