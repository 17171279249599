/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/account/Sessions
 * @summary Requests for sessions
 *
 */

import { endpoints } from "../endpoints";
import { _Base } from "../_Base";
import { decodeUser } from "./Decoder";

export class Sessions extends _Base {
    /**
     * Checks if the current session is valid or expired
     */
    async isValid() {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.sessions.get;

        const response = await this.prepareGet(url, {
            testget: "check",
        });

        return decodeUser(response);
    }

    async logout() {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.sessions.remove;

        const response = await this.prepareGet(url, null);

        return response;
    }

    async setProduct(productId: any) {
        const endPointRoot = this.getEndpointRoot(this.environment.api.account);
        const url = endPointRoot + endpoints.accounts.sessions.setProduct;

        const response = await this.prepareGet(url, {
            productId: productId,
            userId: this.environment.account.user?.id,
        });
        return response;
    }
}
