import { Box, Card, CardContent } from "@mui/material";
import React, { useCallback } from "react";
import { useEnvironment } from "../../../../../../../../../../../hooks/useEnvironment";
import { _formatPeerName } from "./utils";

type Props = {
  constituents: number;
  peer: any;
};
export default function Costituents({ constituents, peer }: Props) {
  const environment = useEnvironment();
  const getPeer = useCallback(
    (peer) => {
      if (peer != null) {
        return _formatPeerName(peer, environment);
      }
      return null;
    },
    [environment]
  );
  return (
    <Card sx={{ boxShadow: 3, flex:1 }}>
      <CardContent sx={{ p:1 ,pb:"8px !important"}}>
        <Box>Costituents</Box>
        <Box><strong>{constituents ?? 0}</strong></Box>
        {peer ?( <Box>{getPeer(peer)}: <strong>{peer.constituents.length}</strong> </Box>): null}
      </CardContent>
    </Card>
  );
}