import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../../../deepClone";
import { useApiCallOnMount } from "../../../../hooks/useApiCallOnMount";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { FormOptions } from "../../../../trendrating/app/formatter/FormOptions";
import { Loader } from "../../components/Loader";
import { config } from "../../config-ts";
import { AnalysisMarketsHelper } from "./AnalysisMarketsHelper";
import { PeerConstraintsBar } from "./widgets/ConstraintsBar/PeerConstraintsBar";
import { PeerTable } from "./widgets/PeerTable/PeerTable";
import Report from "./actions/newReport/Report";
import { StrategicAllocation as StrategicAllocationNew } from "./actions/StrategicAllocation/StrategicAllocation";
import { MarketsStorage } from "../../storage/MarketsStorage";
import { useBroadcast } from "../../../../hooks/useBroadcast";

type MarketWizardProps = {
  status: "complete" | "loading" | "errored";
  children: JSX.Element | JSX.Element[];
};

const ORDER_DICT = {
  TCR: "tcr",
  "AB%": "abPercentage",
  "CD%": "cdPercentage",
  UpDown: "upgradesDowngrades",
  dispersion: "topBottom",
};

const prepareConstraintsMnemonic = (constraints) => {
  const valueConstraintsBar = constraints;
  let constraintsMnemonic: any = null;
  switch (valueConstraintsBar.timeframe) {
    case "today": {
      constraintsMnemonic = "today";

      break;
    }
    case "lastWeek": {
      constraintsMnemonic = "week";

      break;
    }
    case "lastMonth": {
      constraintsMnemonic = "month";

      break;
    }
    default: {
      constraintsMnemonic = null;

      break;
    }
  }

  return constraintsMnemonic;
};

const goTo = (peerId, constraints) => {
  const constraintsMnemonic = prepareConstraintsMnemonic(constraints);
  const uri =
    constraintsMnemonic == null
      ? `/analysis/markets/${peerId}/`
      : `/analysis/markets/${peerId}/${constraintsMnemonic}/`;

  (window as any).__page_navigate(uri);
};

export function AnalysisMarket() {
  const environment = useEnvironment();
  const { t } = useTranslation();
  const pageConfiguration = useMemo(
    () => environment.get("account").product?.configuration?.analysis_market,
    [environment]
  );

  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    const configuration = account?.product?.configuration.analysis_market;
    let title = configuration.menu_label.toLowerCase();
    const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
    document.title = titleCapitalized;
  }, [environment]);
  //#endregion

  //Constraints
  const [cState, setCstate] = useState({
    analytic: "tcr",
    instrumentType: "stock",
    thirdDimension: null,
    visualization: "change",
    timeframe: "today",
    dispersionTimeframe: "pq",
    whatType: "1 Industry",
    whatSortBy: "name|asc",
    whereSortBy: "marketcap|desc",
    whereType: "Country",
  });

  const storage = useMemo(
    () => new MarketsStorage(environment.get("setup")),
    [environment]
  );

  const { broadcast } = useBroadcast();

  useEffect(() => {
    document.getElementById("data-loader")?.classList.add("hide");

    //Workflow Bar
    const actions: any = [];
    let action: any = null;

    if (pageConfiguration?.allocation?.enabled === true) {
      action = {
        componentJSX: <StrategicAllocationNew />,
      };

      actions.push(action);
    }

    actions.push({
      componentJSX: (
        <li
          onClick={() => {
            setShowReportModal(true);
          }}
          className="menu__item"
        >
          Report
        </li>
      ),
    });

    if (cState.instrumentType === "ETF") {
      while (actions.length > 0) {
        actions.pop();
      }
    }

    var message = {
      from: "analysisMarkets",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    cState.instrumentType,
    cState.thirdDimension,
    cState.timeframe,
    environment,
    pageConfiguration?.allocation?.enabled,
    storage,
    t,
  ]);

  const AnalysisMarketsDataManager = useMemo(
    () => new AnalysisMarketsHelper(environment),
    [environment]
  );

  const getData = useCallback(async () => {
    return await AnalysisMarketsDataManager.dataGet(cState);
  }, [AnalysisMarketsDataManager, cState]);

  const [status, data] = useApiCallOnMount(getData);

  const onChangeConstraints = useCallback(
    ({
      whereType,
      whereSortBy,
      analytic,
      thirdDimension,
      visualization,
      timeframe,
      instrumentType,
      dispersionTimeframe,
    }) => {
      if (
        whereType &&
        whereSortBy &&
        analytic &&
        visualization &&
        timeframe &&
        instrumentType &&
        dispersionTimeframe
      ) {
        if (
          cState.analytic !== analytic ||
          cState.thirdDimension !== thirdDimension ||
          cState.timeframe !== timeframe ||
          cState.visualization !== visualization ||
          cState.whereSortBy !== whereSortBy ||
          cState.whereType !== whereType ||
          cState.instrumentType !== instrumentType ||
          cState.dispersionTimeframe !== dispersionTimeframe
        ) {
          setCstate({
            ...cState,
            analytic,
            thirdDimension,
            visualization,
            timeframe,
            whereSortBy,
            whereType,
            instrumentType,
            dispersionTimeframe,
          });
        }
      }
    },
    [cState]
  );

  const onChangeWhatConstraints = useCallback(
    ({ what, whatSort }) => {
      if (what && whatSort) {
        if (cState.whatType !== what || cState.whatSortBy !== whatSort) {
          setCstate((prevState) => ({
            ...prevState,
            whatType: what,
            whatSortBy: whatSort,
          }));
        }
      }
    },
    [cState.whatSortBy, cState.whatType]
  );

  const isDispersionActive = useCallback(() => {
    let isAvailable = false;

    try {
      const product = environment.get("account")["product"];
      const config = product.configuration;
      const availableAnalytics = config["analysis_market"].analyticsOrder;
      if (availableAnalytics.includes("dispersion")) {
        isAvailable = true;
      }
    } catch (error) {
      console.log(error);
      isAvailable = false;
    } finally {
      return isAvailable;
    }
  }, [environment]);

  const onSearch = useCallback(
    (id) => {
      goTo(id, cState);
    },
    [cState]
  );

  const constraintsBarRef = useRef<HTMLFormElement>(null);
  const [fCellDims, setFCellDims] = useState<any>();

  const appEnv = environment.get("formatter");
  const formOptions = useMemo(() => new FormOptions(appEnv), [appEnv]);

  useEffect(() => {
    if (constraintsBarRef.current != null) {
      const cBar = constraintsBarRef.current;
      const whereFieldset = cBar.getElementsByClassName(
        "tPageAnalysisMarket-constraintsWhere"
      )?.[0];

      if (whereFieldset != null && fCellDims) {
        (whereFieldset as any).style.marginLeft = `${fCellDims.width}px`;
      }
    }
  }, [fCellDims]);

  const optionsConstraints = useCallback(
    (widgetType) => {
      switch (widgetType) {
        case "analytic": {
          const analytics = [
            {
              label: t("TCR"),
              selected: true,
              value: "tcr_today",
            },
            {
              label: t("TCR_1_Day_Chages"),
              value: "tcr_change_yesterday",
            },
            {
              label: t("TCR_1_Week_Chages"),
              value: "tcr_change_lastWeek",
            },
            {
              label: t("TCR_1_Month_Chages"),
              value: "tcr_change_lastMonth",
            },
            {
              label: t("TCR_1_Week_Reversal"),
              value: "tcr_reversal_lastWeek",
            },
            {
              label: t("TCR_1_Month_Reversal"),
              value: "tcr_reversal_lastMonth",
            },
            {
              label: t("AB_percentage"),
              value: "tcr_ab_percentage",
            },
            {
              label: t("Upgrades_Downwrades"),
              value: "alert_upgradesDownwrades",
            },
            {
              label: t("Upgrades_Downwrades_percentage"),
              value: "alert_upgradesDownwrades_percentage",
            },
          ];

          return analytics;
        }
        case "instrumentType": {
          return [
            {
              label: t("instrument_stock"),
              value: "stock",
            },
            {
              label: t("instrument_etf"),
              value: "etf",
            },
          ];
        }
        case "what": {
          return [
            {
              label: "Any",
              value: "0 root",
            },
            {
              label:
                cState.instrumentType === "ETF" ? "Asset Class" : "Sectors",
              selected: true,
              value: "1 Industry",
            },
            {
              label:
                cState.instrumentType === "ETF" ? "Specialty" : "Industries",
              value: "3 Sector",
            },
          ];
        }
        case "where": {
          return [
            {
              label: t("World_wide"),
              value: "World",
            },
            {
              label: t("Areas"),
              value: "Area",
            },
            {
              label: t("Regions"),
              value: "Region",
            },
            {
              label: t("Markets"),
              selected: true,
              value: "Country",
            },
          ];
        }
        case "whereSize": {
          return formOptions.getField("SIZE");
        }
        case "whatSortBy": {
          return deepClone([
            {
              label: "Mkt. Cap. Highest ... Lowest",
              value: "marketcap|desc",
            },
            {
              label: "Mkt. Cap. Lowest ... Highest",
              value: "marketcap|asc",
            },
            { type: "separator" },
            {
              label: "TCR A ... D",
              value: "tcr|desc",
            },
            {
              label: "TCR D ... A",
              value: "tcr|asc",
            },
            { type: "separator" },
            {
              label: "Name A ... Z",
              selected: true,
              value: "name|asc",
            },
            {
              label: "Name Z ... A",
              value: "name|desc",
            },
          ]);
        }

        case "whereSortBy": {
          const options: any = deepClone([
            {
              label: "Mkt. Cap. Highest ... Lowest",
              value: "marketcap|desc",
            },
            {
              label: "Mkt. Cap. Lowest ... Highest",
              value: "marketcap|asc",
            },
            { type: "separator" },
            {
              label: "TCR A ... D",
              value: "tcr|desc",
            },
            {
              label: "TCR D ... A",
              value: "tcr|asc",
            },
            { type: "separator" },
            {
              label: "Name A ... Z",
              selected: true,
              value: "name|asc",
            },
            {
              label: "Name Z ... A",
              value: "name|desc",
            },
          ]);

          const dispersionOptions = [
            { type: "separator" },
            {
              label: "Dispersion Top Highest... Lowest",
              value: "topBottom_top|desc",
            },
            {
              label: "Dispersion Top Lowest... Highest",
              value: "topBottom_top|asc",
            },
            {
              label: "Dispersion Bottom Highest... Lowest",
              value: "topBottom_bottom|desc",
            },
            {
              label: "Dispersion Bottom Lowest... Highest",
              value: "topBottom_bottom|asc",
            },
          ];

          if (isDispersionActive()) {
            options.push(...dispersionOptions);
          }

          return options;
        }
        // no default
      }
    },
    [cState.instrumentType, formOptions, isDispersionActive, t]
  );

  const optionsControls = useCallback(
    (optionsType) => {
      switch (optionsType) {
        case "analytic": {
          const analytics = {
            tcr: {
              label: t("TCR"),
              value: "tcr",
            },
            abPercentage: {
              label: t("AB_percentage"),
              value: "abPercentage",
            },
            cdPercentage: { label: "CD%", value: "cdPercentage" },
            upgradesDowngrades: {
              label: t("Upgrades_Downwrades"),
              value: "upgradesDowngrades",
            },
            topBottom: { label: "Dispersion", value: "topBottom" },
          };

          const options: any = [];

          for (const opt of pageConfiguration.analyticsOrder) {
            if (ORDER_DICT[opt] != null) {
              options.push(analytics[ORDER_DICT[opt]]);
            }
          }

          // Make first value selected
          options[0]["selected"] = true;

          return options;
        }
        case "size": {
          return formOptions.getField("SIZE");
        }
        case "dispersionTimeframe": {
          return [
            {
              label: "3 Months",
              selected: true,
              title: "3 Months dispersion",
              value: "pq",
            },
            {
              label: "6 Months",
              title: "6 Months dispersion",
              value: "ps",
            },
            {
              label: "12 Months",
              title: "12 Months dispersion",
              value: "py",
            },
          ];
        }
        case "timeframe": {
          return [
            {
              label: t("Today"),
              selected: true,
              title: t("Today"),
              value: "today",
            },
            {
              label: t("1_w"),
              title: t("1_Week"),
              value: "lastWeek",
            },
            {
              label: t("1_m"),
              title: t("1_Month"),
              value: "lastMonth",
            },
          ];
        }
        case "visualization_abPercentage": {
          return [
            {
              label: t("Value"),
              selected: true,
              value: "percentage",
            },
          ];
        }
        case "visualization_abPercentage_changes": {
          return [
            {
              label: t("Changes"),
              selected: true,
              value: "percentage",
            },
          ];
        }
        case "visualization_tcr": {
          var options = [
            {
              label: t("Default"),
              value: "color",
            },
            {
              label: t("Changes"),
              selected: true,
              value: "change",
            },
            { label: t("Reversals"), value: "reversal" },
          ];

          return options;
        }
        case "visualization_tcr_changesReversals": {
          const options = [
            {
              label: t("Changes"),
              selected: true,
              value: "change",
            },
            { label: t("Reversals"), value: "reversal" },
          ];

          return options;
        }
        case "visualization_upgradesDowngrades": {
          return [
            {
              label: t("Numbers"),
              selected: true,
              value: "number",
            },
            { label: t("Percentages"), value: "percentage" },
          ];
        }

        case "visualization_upgradesDowngrades_number_only": {
          return [
            {
              label: t("Numbers"),
              selected: true,
              value: "number",
            },
          ];
        }

        case "visualization_topBottom_number": {
          return [
            {
              label: "Top/Bottom",
              selected: true,
              value: "top_bottom",
            },
            {
              label: "Top",
              value: "top",
            },
            {
              label: "Bottom",
              value: "bottom",
            },
          ];
        }
        // no default
      }
    },
    [formOptions, pageConfiguration.analyticsOrder, t]
  );

  const handleCellClick = useCallback(
    (id) => {
      // ***************** USAGE *****************
      var usage = window.App.usage;
      var info = {
        function: "PEER_ANALYSIS",
        actionParam: null,
        action: "LANDING",
      };
      usage.record(info);
      // ***************** USAGE *****************

      goTo(id, cState);
    },
    [cState]
  );

  const [showReportModal, setShowReportModal] = useState(false);

  return (
    <article className="tPageAnalysisMarket">
      <div style={{ height: "100%" }}>
        {showReportModal && (
          <Report
            storage={storage}
            closeReportModal={() => setShowReportModal(false)}
          />
        )}
        <MarketWizard status={status}>
          <div>
            <PeerConstraintsBar
              optionsControls={optionsControls}
              optionsConstraints={optionsConstraints}
              ref={constraintsBarRef}
              onSearch={onSearch}
              getConstraints={onChangeConstraints}
            />
          </div>
          <PeerTable
            sortWhatDefault={cState.whatSortBy}
            whatDefault={cState.whatType}
            options={optionsConstraints}
            getConstraints={onChangeWhatConstraints}
            data={data}
            onCellClick={handleCellClick}
            storage={storage}
            setFCellDims={setFCellDims}
          />
        </MarketWizard>
      </div>
    </article>
  );
}

const MarketWizard = ({ status, children }: MarketWizardProps) => {
  switch (status) {
    case "complete":
      return <>{children}</>;

    case "loading":
      return <Loader />;

    case "errored":
      return (
        <strong>
          An error occured, if the problem persist please contact our support
        </strong>
      );

    default:
      return <></>;
  }
};
