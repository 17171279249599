import { useTranslation } from "react-i18next";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../../hooks/useFormatter";

type TablePeersProps = {
  data: any[];
  timeframe: string;
};

const formatterTcr = (cell, formatter) => {
  const output = "HTML";
  const object = cell.getData();

  let tcr = formatter.tcr(object["tct"], output);

  if (object["tcr"] !== object["tcrPrevious"]) {
    tcr = [
      '<p class="tNewsPeers-tcrChange">',
      [
        formatter.tcr(object["tcrPrevious"], output),
        '<span class="tTableAlerts-arrow i-arrow-r"></span>',
        formatter.tcr(object["tcr"], output),
      ].join(" "),
      "</p>",
    ].join("");
  }

  return tcr;
};

const formatterCountryFlag = (cell) => {
  const object = cell.getData();
  const value = cell.getValue();

  if (object != null) {
    return `<div class="flag-tabulator-cell"><img class="tNewsPeers-countryFlag" src="${object["countryFlagUri"]}" alt="${object["name"]["where"]}" height="16" width="16" /><p>${value}</p></div>`;
  }

  return "";
};

const formatterAlert = (cell) => {
  const value = cell.getValue();
  if (value === 0) {
    return "-";
  }

  return String(value);
};

const prepareUriParams = (object) => {
  let urlParams: any = null;

  switch (object["type"].toLowerCase()) {
    case "commodity":
    case "currency":
    case "etf":
    case "index":
    case "instrument":
    case "sector":
    case "stock": {
      urlParams = { symbol: object["symbol"] };

      return urlParams;
    }
    case "peer": {
      urlParams = {
        size:
          object["size"] === "microLarge" || object["size"] == null
            ? "-"
            : object["size"],
        type: "stock_commons-stocks",
        what:
          object["what"] === "ICB" || object["what"] == null
            ? "-"
            : object["what"],
        where:
          object["where"] === "WWW" || object["where"] == null
            ? "-"
            : object["where"],
      };

      return urlParams;
    }
    default: {
      return urlParams;
    }
  }
};

const cellClickHandler = (
  cell,
  timeframe,
  property: "downgrades" | "upgrades" | "name"
) => {
  var peer = cell.getData();

  switch (property) {
    case "downgrades":
    case "upgrades": {
      var uriParams = prepareUriParams(peer);
      const { size, type, what, where } = uriParams;

      var uri = `/screening/${type}/${timeframe}/${property}/${where}/${what}/${size}/`;
      (window as any).__page_navigate(uri);

      break;
    }
    case "name": {
      let timeframeUriParam = "";

      if (timeframe === "lastWeek") {
        timeframeUriParam = "week/";
      }

      if (timeframe === "lastMonth") {
        timeframeUriParam = "month/";
      }

      const uri = `/analysis/markets/${peer.id}/` + timeframeUriParam;

      (window as any).__page_navigate(uri);

      break;
    }
  }
};

export function TablePeers({ data, timeframe }: TablePeersProps) {
  const { t } = useTranslation();
  const formatter = useFormatter();

  return (
    <div className="tNewsPeers">
      <TrendratingTable
        disableDefaultRowClick
        autoResize={false}
        options={{
          ajaxSorting: false,
          movableColumns: false,
          headerHozAlign: "center",
          resizableColumns: false,
        }}
        columns={[
          {
            field: "name",
            title: t("name"),
            formatter: (cell) => formatterCountryFlag(cell),
            widthGrow: 3,
            headerHozAlign: "left",
            cssClass: "no-col-borders",
            cellClick: (e, cell) => cellClickHandler(cell, timeframe, "name"),
            resizable: false,
          },
          {
            field: "tcr",
            formatter: (cell) => formatterTcr(cell, formatter),
            title: t("tcr"),
            widthGrow: 1,
            hozAlign: "center",
            cssClass: "no-col-borders",
            resizable: false,
          },
          {
            field: "upgrades",
            formatter: (cell) => formatterAlert(cell),
            title: t("upgrades"),
            widthGrow: 1,
            hozAlign: "center",
            cssClass: "no-col-borders clickable",
            cellClick: (e, cell) =>
              cellClickHandler(cell, timeframe, "upgrades"),
            resizable: false,
          },
          {
            field: "downgrades",
            formatter: (cell) => formatterAlert(cell),
            title: t("downgrades"),
            widthGrow: 1,
            hozAlign: "center",
            cssClass: "no-col-borders clickable",
            resizable: false,
            cellClick: (e, cell) =>
              cellClickHandler(cell, timeframe, "downgrades"),
          },
        ]}
        data={data}
      />
    </div>
  );
}
