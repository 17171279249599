import {
    Box,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useImmerReducer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import InputNumber from "../../../../../../../../../../../components/InputNumber/InputNumber";
import styles from "./SmartBetaTable.module.scss";

type SmartBetaTableProps = {
    smartBetaConfiguration: {
        [key: string]: {
            label: string;
            operator: {
                constraints: { max: number; min: number };
                value: any;
                weight: any;
                options?: any[];
            };
            active: boolean;
        };
    };
    setSmartBeta: (smartBetaValue: SmartBetaValue) => void;
    setIsValid: (isValid: boolean) => void;
};

type SmartBetaConfigAction =
    | {
          type: "SET_RULE_ACTIVE";
          payload: { key: string; value: boolean };
      }
    | { type: "SET_WEIGHT"; payload: { key: string; value: number } }
    | { type: "SET_OPERATOR_VALUE"; payload: { key: string; value: any } }
    | {
          type: "SET_RATING_VALUE";
          payload: {
              key: string;
              ratingKey: "A" | "B" | "C" | "D";
              value: any;
          };
      };

type SmartBetaValue = { operator: any; property: string; weight: number }[];

export default function SmartBetaTable({
    smartBetaConfiguration,
    setSmartBeta,
    setIsValid,
}: SmartBetaTableProps) {
    const smartBetaReducer = (
        draft: SmartBetaTableProps["smartBetaConfiguration"],
        action: SmartBetaConfigAction
    ) => {
        switch (action.type) {
            case "SET_RULE_ACTIVE": {
                draft[action.payload.key].active = action.payload.value;

                break;
            }

            case "SET_WEIGHT": {
                draft[action.payload.key].operator.weight =
                    action.payload.value;

                break;
            }

            case "SET_OPERATOR_VALUE": {
                draft[action.payload.key].operator.value = action.payload.value;

                break;
            }

            case "SET_RATING_VALUE": {
                draft[action.payload.key].operator.value[
                    action.payload.ratingKey
                ] = action.payload.value;

                break;
            }
        }
    };

    const [state, dispatch] = useImmerReducer(
        smartBetaReducer,
        smartBetaConfiguration
    );

    const getSmartBeta = useCallback(() => {
        const smartBetaSelectedRows: SmartBetaValue = [];

        for (const [key, value] of Object.entries<any>(state)) {
            if (value.active) {
                smartBetaSelectedRows.push({
                    operator: value.operator.value,
                    property: key,
                    weight: value.operator.weight,
                });
            }
        }

        return smartBetaSelectedRows;
    }, [state]);

    const onCheckboxChange = useCallback(
        (e, rowKey) => {
            e.stopPropagation();
            const active = e.target.checked;

            dispatch({
                type: "SET_RULE_ACTIVE",
                payload: { key: rowKey, value: active },
            });
        },
        [dispatch]
    );

    const handleRatingRowChange = useCallback(
        (value: number | null, key: "A" | "B" | "C" | "D", rowKey) => {
            dispatch({
                type: "SET_RATING_VALUE",
                payload: { key: rowKey, ratingKey: key, value: value },
            });

            if (value == null) {
                validationMapRef.current = {
                    ...validationMapRef.current,
                    [`${rowKey}__${key}`]: false,
                };
            } else {
                const validationObjectCopy = {
                    ...validationMapRef.current,
                };

                delete validationObjectCopy[`${rowKey}__${key}`];
                validationMapRef.current = validationObjectCopy;
            }
        },
        [dispatch]
    );

    const validationMapRef = useRef<{ [key: string]: boolean }>({});

    const handleWeightChange = useCallback(
        (value, rowKey, constraints) => {
            dispatch({
                type: "SET_WEIGHT",
                payload: { key: rowKey, value: value },
            });

            if (value == null) {
                validationMapRef.current = {
                    ...validationMapRef.current,
                    [rowKey]: false,
                };
            } else if (constraints) {
                const max = constraints?.max ?? null;
                const min = constraints?.min ?? null;

                if (max && min) {
                    if (!(value >= min && value <= max)) {
                        validationMapRef.current = {
                            ...validationMapRef.current,
                            [rowKey]: false,
                        };
                    } else {
                        const validationObjectCopy = {
                            ...validationMapRef.current,
                        };

                        delete validationObjectCopy[rowKey];
                        validationMapRef.current = validationObjectCopy;
                    }
                }
            }
        },
        [dispatch]
    );

    const handleOperatorValueChange = useCallback(
        (e, rowKey) => {
            const value = e.target.value;

            dispatch({
                type: "SET_OPERATOR_VALUE",
                payload: { key: rowKey, value },
            });
        },
        [dispatch]
    );

    useEffect(() => {
        const smartBeta = getSmartBeta();
        const areInputValid =
            Object.keys(validationMapRef.current).length === 0;

        setIsValid(areInputValid);
        setSmartBeta(smartBeta);
    }, [getSmartBeta, setIsValid, setSmartBeta]);

    return (
        <Box display={"flex"} flex={1} minHeight={0} flexDirection={"column"}>
            <Box
                display={"flex"}
                gap={1}
                p={1}
                borderBottom={"2px solid #ddd!important"}
                mb={1}
            >
                <Box justifyContent={"flex-end"} display={"flex"} flex={1}>
                    <Typography>
                        <strong>Weight</strong>
                    </Typography>
                </Box>
                <Box display={"flex"} flex={2}>
                    <Typography>
                        <strong>Analytic</strong>
                    </Typography>
                </Box>
                <Box display={"flex"} flex={2}>
                    <Typography>
                        <strong>Premium</strong>
                    </Typography>
                </Box>
            </Box>
            <ul className={styles.scrollable__list}>
                {Object.entries(state).map(([key, value], index) => (
                    <li key={index} className={styles.smart_beta_table_row}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            flex={1}
                            gap={1}
                        >
                            <Box display={"flex"} flex={1}>
                                <Checkbox
                                    size="small"
                                    sx={{ padding: "5px!important" }}
                                    checked={value.active}
                                    onChange={(value) =>
                                        onCheckboxChange(value, key)
                                    }
                                />
                                <InputNumber
                                    isDisabled={!value.active}
                                    size={"tr_small"}
                                    isPercentage={false}
                                    isFloating={false}
                                    setOuterState={(weight) =>
                                        handleWeightChange(
                                            weight,
                                            key,
                                            value.operator.constraints
                                        )
                                    }
                                    constraints={{
                                        maxVal: value.operator.constraints.max,
                                        minVal: value.operator.constraints.min,
                                    }}
                                    initVal={value.operator.weight}
                                />
                            </Box>
                            <Box flex={2}>
                                <Typography>{value.label}</Typography>
                            </Box>
                            <Box flex={1}>
                                {value.operator.options ? (
                                    <RowItemSelect
                                        isDisabled={!value.active}
                                        options={value.operator.options}
                                        value={value.operator.value}
                                        handleChange={(value) =>
                                            handleOperatorValueChange(
                                                value,
                                                key
                                            )
                                        }
                                    />
                                ) : (
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        flex={1}
                                    >
                                        {/* AB row */}
                                        <Box display={"flex"} gap={1}>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <strong className="rate rate--A">
                                                    A
                                                </strong>
                                                <InputNumber
                                                    disableGutters={true}
                                                    isDisabled={!value.active}
                                                    size={"tr_small"}
                                                    isPercentage={false}
                                                    isFloating={true}
                                                    setOuterState={(value) =>
                                                        handleRatingRowChange(
                                                            value,
                                                            "A",
                                                            key
                                                        )
                                                    }
                                                    initVal={
                                                        value.operator.value.A
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <strong className="rate rate--B">
                                                    B
                                                </strong>
                                                <InputNumber
                                                    disableGutters={true}
                                                    isDisabled={!value.active}
                                                    size={"tr_small"}
                                                    isPercentage={false}
                                                    isFloating={true}
                                                    setOuterState={(value) =>
                                                        handleRatingRowChange(
                                                            value,
                                                            "B",
                                                            key
                                                        )
                                                    }
                                                    initVal={
                                                        value.operator.value.B
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                        {/* CD row */}
                                        <Box display={"flex"} gap={1}>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <strong className="rate rate--C">
                                                    C
                                                </strong>
                                                <InputNumber
                                                    disableGutters={true}
                                                    isDisabled={!value.active}
                                                    size={"tr_small"}
                                                    isPercentage={false}
                                                    isFloating={true}
                                                    setOuterState={(value) =>
                                                        handleRatingRowChange(
                                                            value,
                                                            "C",
                                                            key
                                                        )
                                                    }
                                                    initVal={
                                                        value.operator.value.C
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <strong className="rate rate--D">
                                                    D
                                                </strong>
                                                <InputNumber
                                                    disableGutters={true}
                                                    isDisabled={!value.active}
                                                    size={"tr_small"}
                                                    isPercentage={false}
                                                    isFloating={true}
                                                    setOuterState={(value) =>
                                                        handleRatingRowChange(
                                                            value,
                                                            "D",
                                                            key
                                                        )
                                                    }
                                                    initVal={
                                                        value.operator.value.D
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </li>
                ))}
            </ul>
        </Box>
    );
}

const RowItemSelect = ({ isDisabled, options, value, handleChange }) => {
    return (
        <Box>
            <FormControl fullWidth>
                <Select
                    labelId="smart-beta-value-simple-select-label"
                    id="smart-beta-value-simple-select"
                    value={value}
                    disabled={isDisabled}
                    size="small"
                    sx={{
                        ".MuiSelect-select": {
                            paddingTop: "0!important",
                            paddingBottom: "0!important",
                            fontSize: "0.8vw!important",
                        },
                    }}
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <MenuItem key={uuidv4()} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
