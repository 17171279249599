import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Lists } from "../../../../../../../../api/compute/Lists";
import { formatTaxonPrefixingParent } from "../../../../../../../../api/compute/Taxon";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";
import { RuleType } from "../AdvancedReducer";
import { UI_CONSTRAINTS_TYPE } from "../utils";
import { Properties } from "../../../../../../../../api/Properties";

type InvestmentUniverseProps = {
  value: {
    screening: any;
    selection: null | RuleType[];
    trimOutliers: boolean;
    whiteList: any;
    blacklist: string[];
  };
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function InvestmentUniverse({
  value,
  UI_CONSTRAINTS,
}: InvestmentUniverseProps) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const setup = useMemo(() => environment.get("setup"), [environment]);
  const propertiesAPI = useMemo(
    () => new Properties({ properties: setup["properties"] }),
    [setup]
  );
  const taxonFields = useMemo(() => {
    return environment.get("setup")["taxonomyFields"];
  }, [environment]);
  const rawTax = useMemo(
    () => environment.get("setup")["taxonomies"],
    [environment]
  );
  const [listName, setListName] = useState<{
    name: string;
    isReadOnly: boolean;
  } | null>(null);
  const ETFProvider = useMemo(() => {
    return rawTax[taxonFields["ETF"]["subtype"]];
  }, [rawTax, taxonFields]);

  const whitelistID = useMemo(
    () => value?.whiteList?.id,
    [value?.whiteList?.id]
  );

  const getListName = useCallback(
    async (listId) => {
      let listAPI = new Lists(environment.get("setup"));
      let data = await listAPI.portfolioFetch([listId], ["name", "ownerId"]);
      const userId = environment.get("account")["user"]["id"];
      return {
        name: data[0]?.name ?? "",
        isReadOnly: userId !== data?.[0].ownerId,
      };
    },
    [environment]
  );

  useEffect(() => {
    if (whitelistID) {
      getListName(whitelistID).then((data) => {
        setListName(data);
      });
    } else {
      setListName(null);
    }

    return () => {
      setListName(null);
    };
  }, [getListName, whitelistID]);

  //---------------
  const additionalRules = useMemo(() => {
    if (value.selection != null) {
      if (value.selection.length > 0) {
        return strategyFormat.selection(value.selection);
      }
    }
    return null;
  }, [strategyFormat, value.selection]);
  const eligibility = useMemo(() => {
    if (
      value?.screening?.eligibility &&
      value?.screening?.eligibility.cardinality &&
      value?.screening?.instrumentType !== "etf" // don't display when instrumentType === "etf"
    ) {
      let returningValue = strategyFormat.eligibility(
        value.screening.eligibility
      );
      return returningValue;
    }
    return null;
  }, [
    strategyFormat,
    value?.screening?.eligibility,
    value?.screening?.instrumentType,
  ]);

  const sectorsLabel = useMemo(() => {
    let instrumentType = value?.screening?.instrumentType;
    if (!instrumentType) {
      return "";
    }
    let field = instrumentType === "etf" ? "etfclass" : "industry";
    const label = propertiesAPI.get(field, 0, "auto");
    return label ?? "";
  }, [propertiesAPI, value?.screening?.instrumentType]);
  const sectors = useMemo(() => {
    const instType =
      value?.screening?.instrumentType === "etf" ? "ETF" : "security";
    const taxonomy = environment.setup?.taxonomies;
    const fields = environment.setup?.taxonomyFields;
    const field = instType === "ETF" ? "etfclass" : "icb";
    let arrValue: any = [];
    let node: any = "";
    value.screening?.what?.forEach((id) => {
      node = taxonomy?.[fields?.[instType]?.[field]]?.[id] ?? null;

      if (node != null) {
        if (node.level !== "1 Industry") {
          node = formatTaxonPrefixingParent(
            node,
            [taxonomy[fields[instType][field]]],
            "3 Sector"
          );
        }
        arrValue.push(node);
      }
    });
    if (arrValue.length < 1) {
      return null;
    } else {
      return strategyFormat.strong(arrValue.sort().join(", "));
    }
  }, [
    environment.setup?.taxonomies,
    environment.setup?.taxonomyFields,
    strategyFormat,
    value.screening?.instrumentType,
    value.screening?.what,
  ]);

  const marketLabel = useMemo(() => {
    let instrumentType = value?.screening?.instrumentType;
    if (!instrumentType) {
      return "";
    }
    let field = instrumentType === "etf" ? "country" : "market";
    const label = propertiesAPI.get(field, 0, "auto");
    return label ?? "";
  }, [propertiesAPI, value?.screening?.instrumentType]);
  const markets = useMemo(() => {
    if (
      value?.screening?.whereSource != null &&
      value?.screening?.whereSource?.market?.length > 0
    ) {
      if (value?.screening?.instrumentType?.toLowerCase() !== "etf") {
        return strategyFormat.where(value.screening.whereSource.market);
      }
      return strategyFormat.whereETF(value.screening.whereSource.market);
    }
  }, [
    strategyFormat,
    value.screening?.instrumentType,
    value.screening?.whereSource,
  ]);

  const issuer = useMemo(() => {
    if (value?.screening?.subtype && value?.screening?.subtype?.length > 0) {
      let arr: string[] = [];
      value.screening?.subtype.forEach((item) => {
        let name = ETFProvider?.[item]?.name ?? "";
        if (name && name !== "") {
          arr.push(name);
        }
      });
      return arr.join(", ");
    }
    return undefined;
  }, [ETFProvider, value.screening?.subtype]);

  const invRegionLabel = useMemo(() => {
    let instrumentType = value?.screening?.instrumentType;
    if (!instrumentType || instrumentType !== "etf") {
      return "";
    }
    let field = "etfgeo";
    const label = propertiesAPI.get(field, 0, "auto");
    return label ?? "";
  }, [propertiesAPI, value?.screening?.instrumentType]);
  const investmentRegion = useMemo(() => {
    if (
      value?.screening?.whereTarget != null &&
      value?.screening?.whereTarget?.market?.length > 0
    ) {
      if (value?.screening?.instrumentType?.toLowerCase() !== "etf") {
        return strategyFormat.where(value.screening.whereTarget.market);
      }
      return strategyFormat.investmentRegionETF(
        value.screening.whereTarget.market
      );
    }
  }, [
    strategyFormat,
    value?.screening?.instrumentType,
    value?.screening?.whereTarget,
  ]);
  const instrumentType = useMemo(() => {
    if (value["screening"] != null && value["screening"] != null) {
      return strategyFormat.instrumentType({
        domestic: value["screening"]["whereSource"]["domestic"],
        foreign: value["screening"]["whereSource"]["foreign"],
        instrumentType: value["screening"]["instrumentType"],
        instrumentTypeSub:
          value["screening"]["whereSource"]["stockClassification"],
      });
    }
    return null;
  }, [strategyFormat, value]);
  //---------------

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <table style={{ borderSpacing: 0 }}>
        <tbody>
          {listName ? (
            <tr>
              <td>White list</td>
              <td>
                <strong>{listName.name}</strong>{" "}
                {listName.isReadOnly && (
                  <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                )}
              </td>
            </tr>
          ) : null}
          {instrumentType ? (
            <tr>
              <td>Instrument type</td>
              <td dangerouslySetInnerHTML={{ __html: instrumentType }}></td>
            </tr>
          ) : null}
          {sectors ? (
            <tr>
              <td>{sectorsLabel}</td>
              <td dangerouslySetInnerHTML={{ __html: sectors }}></td>
            </tr>
          ) : null}
          {investmentRegion ? (
            <tr>
              <td>
                {/*Investment region*/}
                {invRegionLabel}
              </td>
              <td dangerouslySetInnerHTML={{ __html: investmentRegion }}></td>
            </tr>
          ) : null}
          {issuer ? (
            <tr>
              <td>Issuer</td>
              <td dangerouslySetInnerHTML={{ __html: issuer }}></td>
            </tr>
          ) : null}

          {markets ? (
            <tr>
              <td>
                {/*Markets*/}
                {marketLabel}
              </td>
              <td dangerouslySetInnerHTML={{ __html: markets }}></td>
            </tr>
          ) : null}

          {eligibility ? (
            <tr>
              <td>Eligibility</td>
              <td dangerouslySetInnerHTML={{ __html: eligibility }}></td>
            </tr>
          ) : null}

          <tr>
            <td>Exclusion list</td>
            <td>{value.blacklist?.length ?? 0}</td>
          </tr>
          <tr>
            {UI_CONSTRAINTS.investementUnivese.additionalContraints.enabled ===
              true && (
              <td style={{ verticalAlign: "baseline" }}>Additional rules:</td>
            )}
            <td>
              {additionalRules != null &&
                UI_CONSTRAINTS.investementUnivese.additionalContraints
                  .enabled === true &&
                additionalRules.map((rule, index) => (
                  <div key={index}>
                    <strong>{index + 1}</strong> -{" "}
                    <span dangerouslySetInnerHTML={{ __html: rule[0] }} />{" "}
                    <span dangerouslySetInnerHTML={{ __html: rule[1] }} />
                  </div>
                ))}
            </td>
          </tr>
          {additionalRules == null &&
            UI_CONSTRAINTS.investementUnivese.additionalContraints.enabled ===
              true && (
              <tr>
                <td>No additional rules defined</td>
              </tr>
            )}
        </tbody>
      </table>
    </Box>
  );
}
