import { useCallback, useMemo } from "react";
import { Subscriptions } from "../../../../../../api/compute/Subscriptions";
import { useBroadcast } from "../../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";
import { messageError, messageSuccess } from "../../../../utils";

type SubscribeProps = {
  strategyId: number;
};

export function Subscribe({ strategyId }: SubscribeProps) {
  const { broadcast } = useBroadcast();

  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);

  const subscriptionsAPI = useMemo(() => new Subscriptions(setup), [setup]);

  const subscribe = useCallback(async () => {
    try {
      await subscriptionsAPI.create({
        id: strategyId,
        type: "strategy",
      });

      const [channel, msg] = messageSuccess(
        "Publication subscribed successfully."
      );
      broadcast(channel as string, msg);

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error: any) {
      const [channel, msg] = messageError(
        "Cannot subscribe the publication. Please contact Trendrating."
      );
      broadcast(channel as string, msg);
      console.error("error", error.message);
    }
  }, [broadcast, strategyId, subscriptionsAPI]);

  return (
    <li onClick={subscribe} className="menu__item">
      Subscribe
    </li>
  );
}
