import { Box, Card, CardContent, Skeleton, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { WhatWherePie } from "../../../../../../../../../../components/WhatWherePie/WhatWherePie";
import { Product } from "../../../../../../../../storage/SystematicPortfoliosStorage";
import { ProductPreviewAnalytic } from "../../../../../../../systematicPortfolios/widgets/ProductPreviewAnalytic";
import { ProductTcrOverview } from "../../../../../../../systematicPortfolios/widgets/ProductTcrOverview";
import { SystematicProductChart } from "../../../../../../../systematicPortfolios/widgets/SystematicProductChart";
import styles from "./ProductOverviewTab.module.scss";
import { StrategySummary } from "../../../../../../../../../../components/StrategySummary/StrategySummary";
import { ProductMeta } from "../../../../../../../systematicPortfolios/widgets/ProductMeta";
import { RebalanceInfo } from "../../../../../../../systematicPortfolios/widgets/RebalanceInfo";

type ProductOverviewTabProps = { product: Product };

export function ProductOverviewTab({ product }: ProductOverviewTabProps) {
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingPie, setLoadingPie] = useState(false);
  const [chartData, setChartData] = useState<any>();
  const [pieData, setPieData] = useState<any>();
  const [tcr, setTcr] = useState<any>();
  const [strategy, setStrategy] = useState();
  const [isReadOnly, setIsReadOnly] = useState(false);

  const getStrategy = useCallback(async () => {
    if (product) {
      const strategy = await product.getStrategy();

      if (strategy) {
        setStrategy(strategy);
      }
    }
  }, [product]);

  const getChartData = useCallback(async () => {
    if (product) {
      setLoadingChart(true);
      const data = await product.chart({ years: 10 });

      setChartData(data?.chart);

      setLoadingChart(false);
    }
  }, [product]);

  const getPieData = useCallback(async () => {
    if (product) {
      setLoadingPie(true);
      const data = (await product.snapshot()) as any;

      if (data) {
        const holdings = data.snapShot.positions;
        setPieData(holdings);
      }

      setLoadingPie(false);
    }
  }, [product]);

  const getTcrData = useCallback(async () => {
    if (product) {
      const data = (await product.snapshot()) as any;

      setTcr(data?.snapShot?.statistics?.tcr);
    }
  }, [product]);

  const getData = useCallback(async () => {
    // Make call synchronously otherwise use a mutex in the storage class to avoid data race on the cache variables
    await getChartData();
    await getPieData();
    await getTcrData();
    await getStrategy();
  }, [getChartData, getPieData, getStrategy, getTcrData]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (product) {
      const info = product.info();

      setIsReadOnly(info.isReadOnly);
    }
  }, [product]);

  return (
    <Box className={styles.mainWrapper}>
      <Box className={`${styles.col} ${styles["col-sx"]}`}>
        {!isReadOnly && <RebalanceInfo enableClickableLink product={product} />}
        <Card className={styles.chartCard}>
          <CardContent className={styles.chartCardContent}>
            {loadingChart ? (
              <LoaderSkeleton />
            ) : (
              <SystematicProductChart
                data={chartData}
                chartInteractiveMode={{
                  isEnabled: false,
                  plugins: [],
                }}
                isLogarithmicMeasure
              />
            )}
          </CardContent>
        </Card>
        <Card className={styles.pieCard}>
          <CardContent className={styles.pieCardContent}>
            {loadingPie ? (
              <LoaderSkeleton />
            ) : (
              <WhatWherePie
                holdings={pieData}
                isTcrMode={true}
                hasHorizontalLayout={true}
                isPieClickable={false}
                alignChartOnTop={true}
              />
            )}
          </CardContent>
        </Card>
      </Box>
      <Box className={`${styles.col} ${styles["col-dx"]}`}>
        <Box className={styles.anagraphicInfoCol}>
          <ProductTcrOverview tcrInfo={tcr} />
          <Card>
            <CardContent>
              <ProductPreviewAnalytic product={product} />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              {product&& /*&& strategy && chartData && pieData &&*/ (
                <ProductMeta
                  strategy={strategy}
                  product={product.info()}
                  benchmark={chartData?.benchmark??undefined}
                  holdings={pieData}
                />
              )}
            </CardContent>
          </Card>
        </Box>
        <Box className={styles.anagraphicInfoCol}>
          {strategy && (
            <StrategySummary
              strategy={strategy}
              options={{
                sections: { hasBacktesting: false, hasHedging: false },
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const LoaderSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
    </Stack>
  );
};
