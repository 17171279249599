import { LegacyRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Import } from "../../api/compute/Import";
import { useEnvironment } from "../../hooks/useEnvironment";
import { config } from "../../js/app/config-ts";
import Table from "./widgets/Table";
import { Button } from "@mui/material";
import { useBroadcast } from "../../hooks/useBroadcast";

type DialogUploadProps = {
  onImport: Function;
  customStyle?: object;
  supportingDescription?: boolean;
};

export default function DialogUpload({
  onImport,
  customStyle,
  supportingDescription,
}: DialogUploadProps) {
  //others
  const environment = useEnvironment();
  const imp = useMemo(
    () => new Import(environment.get("setup")),
    [environment]
  );

  const { broadcast } = useBroadcast();
  const { t } = useTranslation();
  //states
  const [spreadSheet, setSpreadSheet] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  //refs
  const formRef: LegacyRef<HTMLFormElement> = useRef(null);
  const inputRef: LegacyRef<HTMLInputElement> = useRef(null);

  //handlers
  const openFilePickerHandler = (event) => {
    event.preventDefault();
    inputRef?.current?.click();
  };
  const filePickerHandler = (event) => {
    upload(imp, environment, formRef.current, setSpreadSheet, broadcast);
    event.target.value = null;
  };

  useEffect(() => {
    if (spreadSheet) {
      setIsModalVisible(true);
    }
  }, [spreadSheet]);

  return (
    <div style={customStyle ? customStyle : {}}>
      <form ref={formRef}>
        <input
          type={"hidden"}
          name={"csv-separator"}
          value={imp.separator()}
        ></input>
        <input
          onChange={filePickerHandler}
          name="uploadedfile"
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
        ></input>
        <Button variant={"contained"} onClick={openFilePickerHandler}>
          {t("c_action_import")}
        </Button>
        {supportingDescription && (
          <div className="supportingDescription">
            Supported file formats: .csv . xlsx
          </div>
        )}
      </form>
      {isModalVisible && (
        <Table
          onImport={onImport}
          hideDialogUploadSupportingDescription={() => {
            formRef.current?.setAttribute(
              "style",
              "display: flex; justify-content: end;"
            );

            const arr: any = formRef.current?.querySelectorAll(
              ".supportingDescription"
            );
            if (arr[0]) {
              arr[0].style.display = "none";
            }
          }}
          onClose={() => {
            setIsModalVisible(false);
          }}
          data={spreadSheet}
        />
      )}
    </div>
  );
}
const upload = async (imp, env, form, spreadSheetSetter, broadcast) => {
  var account = env.get("account");
  var user = account.user;

  var formData = new FormData(form);
  formData.append("additionalCookies", JSON.stringify(user.additionalCookies));

  const params = {
    data: formData,
    preventCache: true,
  };

  const response = await imp.upload(
    window.App.routes["analysis-collection"]["import"],
    params
  );

  if (!response.isValid && !response.empty) {
    const message = {
      from: "Routes",
      content: {
        type: "error",
        text: "The uploaded file is not a MS Excel<sup>&reg;</sup> file or file is corrupted.",
      },
    };
    broadcast(config["channels"]["feedback"]["input"], message);
    return;
  }

  if (!response.status && !response.empty) {
    var message = {
      from: "Routes",
      content: {
        type: "error",
        text: "Sorry, an error occurs during upload. Please contact the customer care.",
      },
    };
    broadcast(config["channels"]["feedback"]["input"], message);
    return;
  }

  if (!response.empty && response.isValid) {
    const spreadsheet = response.spreadsheet;
    const temp: any = { name: response.name, ...spreadsheet };
    spreadSheetSetter(temp);
  }
};
