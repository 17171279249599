/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/TemplateTablePortfolio
 * @summary Requests for table portfolio templates
 *
 */

import { _TemplateTable } from "./_TemplateTable";

export class TemplateTablePortfolio extends _TemplateTable {
    storedObjectType = "TEMPLATE_TABLE_PORTFOLIO" as const;
}
