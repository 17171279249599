import { useEffect, useState } from "react";
import { TableAlertsComponent } from "./createEdit/TableAlertsComponent";

type ListViewerTileItemAlertsProps = {
  value: any;
  timeframe: any;
};

export function ListViewerTileItemAlerts({
  value,
  timeframe,
}: ListViewerTileItemAlertsProps) {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    setAlerts(value);
  }, [value]);

  return (
    <div className="tListViewerTileItemAlerts">
      <div className="tListViewerTileItemAlerts-scroller">
        <div data-dojo-attach-point="nodeTable">
          <TableAlertsComponent
            autoResize={false}
            timeframe={timeframe}
            alerts={alerts}
          />
        </div>
      </div>
    </div>
  );
}
