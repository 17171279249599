import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TrendratingTable } from "../../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../../hooks/useFormatter";
import { CreatePortfolioOrBasket } from "../../CreatePortfolioOrBasket";
import { ColumnDefinition } from "tabulator-tables";

type ListsReactProps = {
  data: any;
  timeframe: string;
  createListner: (listTipe: "basket" | "portfolio") => void;
  listType: "portfolio" | "basket";
  total: number;
};

const formatName = (cell) => {
  const cssClassName = "tTableLists-name";
  const cssClassNameA = "tTableLists-name--A";
  const cssClassNameB = "tTableLists-name--B";
  const cssClassNameC = "tTableLists-name--C";
  const cssClassNameD = "tTableLists-name--D";

  const value = cell.getValue();
  const node = cell.getElement();
  node.classList.add(cssClassName, "no-col-borders");
  const object = cell.getData();

  switch (object["tcr"]) {
    case 4:
    case 3: {
      node.classList.add(cssClassNameA);

      break;
    }
    case 2:
    case 1:
    case 0: {
      node.classList.add(cssClassNameB);

      break;
    }
    case -1:
    case -2:
    case -3: {
      node.classList.add(cssClassNameC);

      break;
    }
    case -4:
    case -5: {
      node.classList.add(cssClassNameD);

      break;
    }
    default: {
      // unrated
    }
  }

  return (
    value +
    (object["isReadOnly"]
      ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
      : "")
  );
};

const formatterTcr = (cell, format) => {
  const object = cell.getData();
  const { today, yesterday } = object["tcr"];

  let tcr = "";

  if (today !== yesterday) {
    tcr = [
      format.tcr(yesterday, "HTML"),
      '<span class="i-arrow-r"></span>',
      format.tcr(today, "HTML"),
    ].join(" ");
  } else {
    tcr = format.tcr(today, "HTML");
  }

  return tcr;
};

const formatterAlert = (cell, formatter) => {
  const value = cell.getValue();
  cell.getElement().classList.add("no-col-borders");

  return formatter.custom("number", {
    options: {
      isPercentage: false,
      decimals: 0,
      notAvailable: {
        output: "-",
      },
    },
    output: "TEXT",
    value,
    valueHelper: null,
  });
};

const handleCLickList = (e, cell, type, timeframe) => {
  const cellValue = cell.getValue();
  const alertType = type;
  const list = cell.getData();
  const listId = list["id"];
  let uri = "";

  const timeframOptions = {
    today: "today",
    lastWeek: "last-week",
    lastMonth: "last-month",
  };

  const uriParams = {
    alert: "",
    id: listId,
    tab: "holdings",
  };

  const info = {
    action: "LANDING",
    actionParam: listId,
    function: "PORTFOLIO_ANALYSIS",
  };

  if (cellValue > 0 && type !== "table-click") {
    switch (alertType) {
      case "table-click-downgrades": {
        info["action"] = "DOWNGRADE";
        uriParams.alert = [timeframOptions[timeframe], "downgrades"].join("-");
        uri = `/analysis/lists/${listId}/analyze/${uriParams.tab}/${uriParams.alert}/`;

        break;
      }
      case "table-click-movers-down": {
        info["action"] = "MOVERS_DOWN";
        uriParams.alert = "movers-down";

        uri = `/analysis/lists/${listId}/analyze/${uriParams.tab}/${uriParams.alert}/`;

        break;
      }
      case "table-click-movers-up": {
        info["action"] = "MOVERS_UP";
        uriParams.alert = "movers-up";

        uri = `/analysis/lists/${listId}/analyze/${uriParams.tab}/${uriParams.alert}/`;

        break;
      }
      case "table-click-upgrades": {
        info["action"] = "UPGRADE";
        uriParams.alert = [timeframOptions[timeframe], "upgrades"].join("-");

        uri = `/analysis/lists/${listId}/analyze/${uriParams.tab}/${uriParams.alert}/`;

        break;
      }
    }

    //     //************************** USAGE *****************************
    const usage = window.App.usage;
    usage.record(info);
    //     //************************** USAGE *****************************

    (window as any).__page_navigate(uri);
  } else if (type === "table-click") {
    uri = `/analysis/lists/${listId}/analyze/`;

    //************************** USAGE *****************************
    const usage = window.App.usage;
    usage.record(info);
    //************************** USAGE *****************************

    (window as any).__page_navigate(uri);
  }
};

/**
 *
 * @param {array} data
 * @returns lists that contains at least one alert
 */
const filterNoAlertsLists = (data) => {
  return data.filter(
    (list) =>
      list.upgrades !== 0 ||
      list.downgrades !== 0 ||
      list.moversUp !== 0 ||
      list.moversDown !== 0
  );
};

export function Lists({
  data,
  timeframe,
  createListner,
  listType,
  total,
}: ListsReactProps) {
  const { t } = useTranslation();
  const formatter = useFormatter();
  const cssClassMovers = "tTableLists-movers";
  const cssClassMoversDown = "tTableLists-moversDown";
  const cssClassMoversUp = "tTableLists-moversUp";

  const alerts = useMemo(() => filterNoAlertsLists(data), [data]);

  const columns: ColumnDefinition[] = useMemo(
    () => [
      {
        field: "name",
        title: t("name"),
        resizable: false,
        formatter: (cell) => formatName(cell),
        widthGrow: 3,
        cssClass: "no-col-borders",
        headerHozAlign: "left",
        hozAlign: "left",
        cellClick: (e, cell) =>
          handleCLickList(e, cell, "table-click", timeframe),
      },
      {
        field: "tcr",
        cssClass: "no-col-borders",
        formatter: (cell) => formatterTcr(cell, formatter),
        title: t("tcr"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        sorter: (a, b) => {
          const base = a.today ?? -9999;
          const controlValue = b.today ?? -9999;
          return base - controlValue;
        },
      },
      {
        field: "upgrades",
        cssClass: "no-col-borders clickable",
        formatter: (cell) => formatterAlert(cell, formatter),
        title: t("upgrades"),
        resizable: false,
        widthGrow: 1,
        hozAlign: "center",
        headerHozAlign: "center",
        cellClick: (e, cell) =>
          handleCLickList(e, cell, "table-click-upgrades", timeframe),
      },
      {
        field: "downgrades",
        cssClass: "no-col-borders clickable",
        formatter: (cell) => formatterAlert(cell, formatter),
        title: t("downgrades"),
        resizable: false,
        widthGrow: 1,
        headerHozAlign: "center",
        hozAlign: "center",
        cellClick: (e, cell) =>
          handleCLickList(e, cell, "table-click-downgrades", timeframe),
      },
      {
        title: t("movers"),
        resizable: false,
        headerHozAlign: "center",
        hozAlign: "center",
        cssClass: `${cssClassMovers} no-col-borders`,
        widthGrow: 1,
        columns: [
          {
            headerHozAlign: "center",
            cssClass: "no-col-borders clickable",
            hozAlign: "center",
            formatter: (cell) => formatterAlert(cell, formatter),
            resizable: false,
            title:
              '<span class="i-triangle-up ' + cssClassMoversUp + '"></span>',
            field: "moversUp",
            cellClick: (e, cell) =>
              handleCLickList(e, cell, "table-click-movers-up", timeframe),
            sorter: (a, b) => (a > b ? 1 : -1),
          },
          {
            headerHozAlign: "center",
            cssClass: "no-col-borders clickable",
            hozAlign: "center",
            field: "moversDown",
            formatter: (cell) => formatterAlert(cell, formatter),
            resizable: false,
            title:
              '<span class="i-triangle-down ' +
              cssClassMoversDown +
              '"></span>',

            cellClick: (e, cell) =>
              handleCLickList(e, cell, "table-click-movers-down", timeframe),
            sorter: (a, b) => (a > b ? 1 : -1),
          },
        ],
      },
    ],
    [formatter, t, timeframe]
  );

  return (
    <Box flex={1} minHeight={0} minWidth={0}>
      {alerts.length !== 0 ? (
        <TrendratingTable
          autoResize={false}
          disableDefaultRowClick
          options={{
            ajaxSorting: false,
            movableColumns: false,
            headerHozAlign: "right",
          }}
          columns={columns}
          data={alerts}
        />
      ) : total != null && total === 0 ? (
        <CreatePortfolioOrBasket
          type={listType}
          createListner={createListner}
        />
      ) : (
        <p>No alerts found</p>
      )}
    </Box>
  );
}
