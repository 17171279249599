export type TopBottomPerformerType = {
  content: {
    headline: {
      content: string;
      isEnabled: boolean;
    };
    numberOfItems: number;
    type: "POSITIVE" | "NEGATIVE";
  };
  presentation: {
    "05_days": boolean;
    "20_days": boolean;
    "60_days": boolean;
  };
};

type ActionType = {
  type:
    | "SET_CONTENT_HEADLINE_CONTENT"
    | "SET_CONTENT_HEADLINE_ISENABLED"
    | "SET_CONTENT_NUMBERS_OF_ITEMS"
    | "SET_CONTENT_TYPE"
    | "SET_PRESENTATION_05"
    | "SET_PRESENTATION_20"
    | "SET_PRESENTATION_60";
  payload: any;
};

export const topBottomPerformerReducer = (draft: TopBottomPerformerType, action: ActionType) => {
  switch (action.type) {
    case "SET_CONTENT_HEADLINE_CONTENT":
      draft.content.headline.content = action.payload;
      break;
    case "SET_CONTENT_HEADLINE_ISENABLED":
      draft.content.headline.isEnabled = action.payload;
      break;
    case "SET_CONTENT_NUMBERS_OF_ITEMS":
      draft.content.numberOfItems = action.payload;
      break;
    case "SET_CONTENT_TYPE":
      draft.content.type = action.payload;
      break;
    case "SET_PRESENTATION_05":
      draft.presentation["05_days"] = action.payload;
      break;
    case "SET_PRESENTATION_20":
      draft.presentation["20_days"] = action.payload;
      break;
    case "SET_PRESENTATION_60":
      draft.presentation["60_days"] = action.payload;
      break;
    default:
      break;
  }
};
