/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/formatter/_rate
 * @summary Rate colors, values and labels
 *
 */
export const _rate = {
    // --------------------------------------------------------------------------
    // IMPORTANT
    // --------------------------------------------------------------------------
    // because of PDF report information are duplicated also in CSS.
    // If you change colors here remember to update colors in CSS file.

    colors: {
        A: "#008000",
        B: "#8BBC00",
        C: "#F48400",
        D: "#F00000",
        U: "#D3D3D3", //'#EFEFEF'
    },

    rating: {
        "2": {
            class: "format-rate format-rate--A",
            color: "#008000",
            colorChart: "#008000",
            label: "A",
            value: 2,
        },
        "1": {
            class: "format-rate format-rate--B",
            color: "#8BBC00",
            colorChart: "#8BBC00",
            label: "B",
            value: 1,
        },
        "0": {
            class: "format-rate format-rate--U",
            color: "#000000",
            colorChart: "#D3D3D3",
            label: "-",
            value: 0,
        },
        "-1": {
            class: "format-rate format-rate--C",
            color: "#F48400",
            colorChart: "#F48400",
            label: "C",
            value: -1,
        },
        "-2": {
            class: "format-rate format-rate--D",
            color: "#F00000",
            colorChart: "#F00000",
            label: "D",
            value: -2,
        },
        "A": {
            class: "format-rate format-rate--A",
            color: "#008000",
            colorChart: "#008000",
            label: "A",
            value: 2,
        },
        "B": {
            class: "format-rate format-rate--B",
            color: "#8BBC00",
            colorChart: "#8BBC00",
            label: "B",
            value: 1,
        },
        "C": {
            class: "format-rate format-rate--C",
            color: "#F48400",
            colorChart: "#F48400",
            label: "C",
            value: -1,
        },
        "D": {
            class: "format-rate format-rate--D",
            color: "#F00000",
            colorChart: "#F00000",
            label: "D",
            value: -2,
        },
        "U": {
            class: "format-rate format-rate--U",
            color: "#000000",
            colorChart: "#D3D3D3",
            label: "-",
            value: 0,
        },
    },

    // TCR
    trendCaptureRating: {
        "4": {
            class: "format-rate format-rate--A",
            color: "#008000",
            colorChart: "#008000",
            label: "A",
            rateClass: "A", // deprecated: use tcrClass
            tcrClass: "A",
            value: 4,
        },
        "3": {
            class: "format-rate format-rate--A",
            color: "#008000",
            colorChart: "#008000",
            label: "A-",
            rateClass: "A", // deprecated: use tcrClass
            tcrClass: "A",
            value: 3,
        },
        "2": {
            class: "format-rate format-rate--B",
            color: "#8BBC00",
            colorChart: "#8BBC00",
            label: "B+",
            rateClass: "B", // deprecated: use tcrClass
            tcrClass: "B",
            value: 2,
        },
        "1": {
            class: "format-rate format-rate--B",
            color: "#8BBC00",
            colorChart: "#8BBC00",
            label: "B",
            rateClass: "B", // deprecated: use tcrClass
            tcrClass: "B",
            value: 1,
        },
        "0": {
            class: "format-rate format-rate--B",
            color: "#8BBC00",
            colorChart: "#8BBC00",
            label: "B-",
            rateClass: "B", // deprecated: use tcrClass
            tcrClass: "B",
            value: 0,
        },
        "-1": {
            class: "format-rate format-rate--C",
            color: "#F48400",
            colorChart: "#F48400",
            label: "C+",
            rateClass: "C", // deprecated: use tcrClass
            tcrClass: "C",
            value: -1,
        },
        "-2": {
            class: "format-rate format-rate--C",
            color: "#F48400",
            colorChart: "#F48400",
            label: "C",
            rateClass: "C", // deprecated: use tcrClass
            tcrClass: "C",
            value: -2,
        },
        "-3": {
            class: "format-rate format-rate--C",
            color: "#F48400",
            colorChart: "#F48400",
            label: "C-",
            rateClass: "C", // deprecated: use tcrClass
            tcrClass: "C",
            value: -3,
        },
        "-4": {
            class: "format-rate format-rate--D",
            color: "#F00000",
            colorChart: "#F00000",
            label: "D+",
            rateClass: "D", // deprecated: use tcrClass
            tcrClass: "D",
            value: -4,
        },
        "-5": {
            class: "format-rate format-rate--D",
            color: "#F00000",
            colorChart: "#F00000",
            label: "D",
            rateClass: "D", // deprecated: use tcrClass
            tcrClass: "D",
            value: -5,
        },
        "-9999": {
            class: "format-rate format-rate--U",
            color: "#000000",
            colorChart: "#D3D3D3",
            label: "-",
            rateClass: "U", // deprecated: use tcrClass
            tcrClass: "U",
            value: null,
        },
        "CASH": {
            class: "format-rate format-rate--cash",
            color: "#000000",
            colorChart: "#FFFFFF",
            label: "-",
            rateClass: "cash",
            value: null,
        },
        "U": {
            class: "format-rate format-rate--U",
            color: "#000000",
            colorChart: "#D3D3D3",
            label: "-",
            rateClass: "U",
            value: null,
        },
    },
};
