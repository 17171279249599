import { ClusterAnalytics } from "../../../api/compute/ClusterAnalytics";
import { Instruments } from "../../../api/compute/Instruments";
import { Lists } from "../../../api/compute/Lists";
import { getTaxonById } from "../../../api/compute/Taxon";
import { deepClone } from "../../../deepClone";
import { Environment } from "../../../Environment";
import { AppEnvironment } from "../../../types/Defaults";
import { Mutex } from "../../../Utility/Mutex";

type Collection = {
  id: number;
  type: "BASKET" | "PORTFOLIO";
  name: string;
  TCR: number;
  TCR_D: number;
  ABnewHigh: number;
  CDnewLow: number;
  ownerId: number;
  A_perc: number;
  B_perc: number;
  C_perc: number;
  D_perc: number;
  upgrades: number;
  downgrades: number;
};

type ListType = "PORTFOLIO" | "BASKET";

type UiTimeframe = "daily" | "monthly" | "weekly";
interface PortfolioHome {
  getCollections: (
    type: ListType,
    timeframe: UiTimeframe
  ) => Promise<Collection[] | { error: string }>;
  invalidateCollections: () => void;
  invalidateCachedIds: () => void;
  getAlerts: (ids: number[], timeframe: UiTimeframe) => Promise<any[]>;
  getCollectionsIds: (
    listType: ListType,
    sortCriteria?:
      | "name"
      | "name_rev"
      | "TCR"
      | "TCR_rev"
      | "CD"
      | "CD_rev"
      | "upgrades"
      | "downgrades"
      | "movers_up"
      | "movers_down"
  ) => Promise<number[]>;
  getListForTile: (id: number, timeframe: UiTimeframe) => Promise<any>;
}

export class PortfolioHomeStorage implements PortfolioHome {
  private userCollectionsIds: number[] | null;
  private collections: {
    portfolio: Collection[] | null;
    basket: Collection[] | null;
  };
  private mutex: Mutex;
  private apiList: Lists;
  private listsFieldsToRetriveToday = [
    "type",
    "ABnewHigh",
    "CDnewLow",
    "name",
    "ownerId",
    "TCR",
    "TCR_D",
    "A_%",
    "B_%",
    "C_%",
    "D_%",
    "upgrades",
    "downgrades",
  ];
  private listsFieldsToRetriveLastWeek = [
    "type",
    "ABnewHigh",
    "CDnewLow",
    "name",
    "ownerId",
    "TCR_W",
    "TCR_D",
    "A_%",
    "B_%",
    "C_%",
    "D_%",
    "upgrades_W",
    "downgrades_W",
  ];
  private listsFieldsToRetriveLastMonth = [
    "type",
    "ABnewHigh",
    "CDnewLow",
    "name",
    "ownerId",
    "TCR_M",
    "TCR_D",
    "A_%",
    "B_%",
    "C_%",
    "D_%",
    "upgrades_M",
    "downgrades_M",
  ];
  private apiInstrument: Instruments;
  private apiCluster: ClusterAnalytics;
  private setup: AppEnvironment;
  private preferences;

  constructor(private environment: Environment) {
    this.userCollectionsIds = null;
    this.collections = { portfolio: null, basket: null };
    this.mutex = new Mutex(1);
    this.setup = environment.get("setup");
    this.apiList = new Lists(this.setup);
    this.apiInstrument = new Instruments(this.setup);
    this.apiCluster = new ClusterAnalytics(this.setup);
    this.preferences =
      environment.get("account")["user"]["preferences"]["preferences"];
  }

  /**
   * Clear the cached collections
   */
  public invalidateCollections() {
    this.collections = { portfolio: null, basket: null };
  }

  /**
   * Clear the cached ids of lists
   */
  public invalidateCachedIds() {
    this.userCollectionsIds = null;
  }

  /**
   *
   * @param {string} listType PORTFOLIO or BASKET
   * @returns {Promise}
   *
   * Retrives user collections (subscribed included) filtered by type
   */
  public async getCollections(
    type: ListType,
    timeframe: UiTimeframe,
    id?: number
  ) {
    return this.mutex.runWithMutex(async () => {
      const typeLower = type.toLowerCase();
      if (this.collections[typeLower]) {
        if (id) {
          return (
            this.collections[typeLower].find((item) => item.id === id) ?? {}
          );
        }

        return this.collections[typeLower];
      }

      await this.getLists(type, timeframe);

      if (id) {
        return this.collections[typeLower].find((item) => item.id === id) ?? {};
      }

      return this.collections[typeLower];
    });
  }

  /**
   *
   * @param {Array} ids
   * @param {string} timeframe
   *
   * @returns securities of all user collections that have registered an upgrade, a downgrade or that moves up or down
   */
  public async getAlerts(ids: number[], timeframe: UiTimeframe) {
    const alertsSymbols = await this._getAlerts(ids, timeframe);
    const symbols = alertsSymbols?.data ?? [];
    const alerts = await this.getAlertsAnalytics(symbols);

    return alerts.data as any[];
  }

  /**
   *
   * @param {string} listType PORTFOLIO or BASKET
   *
   * @returns {Promise}
   *
   * Get the ids of the user collection merging his collections and his subscriptions
   */
  public async getCollectionsIds(
    listType: ListType,
    sortCriteria?:
      | "name"
      | "name_rev"
      | "TCR"
      | "TCR_rev"
      | "CD"
      | "CD_rev"
      | "AB"
      | "AB_rev"
      | "upgrades"
      | "downgrades"
      | "movers_up"
      | "movers_down",
    timeframe?: "daily" | "weekly" | "monthly"
  ) {
    if (this.userCollectionsIds) {
      return this.userCollectionsIds;
    }

    const userListsIds = await this.apiList._getFilter(listType);
    const userSubscriptions = await this.apiList._getSubscribed();
    const subscriptionsByType = await this.filterSubscriptionsByType(
      userSubscriptions,
      listType
    );

    const listsIds: number[] = [...userListsIds, ...subscriptionsByType];

    // If a sort criteria is passed get the analytics used to sort and sort ids by this criteria
    if (sortCriteria) {
      let criteria: string[] = ["name"];
      let suffix = "";

      if (timeframe) {
        if (timeframe === "weekly") {
          suffix = "_W";
        }

        if (timeframe === "monthly") {
          suffix = "_M";
        }
      }

      switch (sortCriteria) {
        default:
        case "name":
        case "name_rev":
          break;

        case "TCR":
        case "TCR_rev":
          criteria = ["TCR", "name"];

          break;

        case "CD":
        case "CD_rev":
          criteria = ["C_%", "D_%", "name"];

          break;

        case "AB":
        case "AB_rev":
          criteria = ["A_%", "B_%", "name"];

          break;

        case "upgrades":
          criteria = ["upgrades" + suffix, "name"];

          break;

        case "downgrades":
          criteria = ["downgrades" + suffix, "name"];

          break;

        case "movers_up":
          criteria = ["ABnewHigh", "name"];

          break;

        case "movers_down":
          criteria = ["CDnewLow", "name"];

          break;
      }

      try {
        const response = await this.apiList.portfolioFetch(listsIds, criteria);

        if (response) {
          const formattedResponse = response.map((item) => {
            const object = { ...item, id: item.id };

            if (sortCriteria === "CD" || sortCriteria === "CD_rev") {
              object["CD"] = (item?.["C_%"] ?? 0) + (item?.["D_%"] ?? 0);
            }

            if (sortCriteria === "AB" || sortCriteria === "AB_rev") {
              object["AB"] = (item?.["A_%"] ?? 0) + (item?.["B_%"] ?? 0);
            }

            object["name"] = item.name.toLowerCase();

            return object;
          });

          const sortedIds: number[] = formattedResponse.sort((a, b) => {
            switch (sortCriteria) {
              case "name": {
                return a.name > b.name ? 1 : -1;
              }

              case "name_rev": {
                return a.name > b.name ? -1 : 1;
              }

              case "TCR": {
                if (a.TCR > b.TCR) {
                  return 1;
                } else if (a.TCR < b.TCR) {
                  return -1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "TCR_rev": {
                if (a.TCR > b.TCR) {
                  return -1;
                } else if (a.TCR < b.TCR) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "CD": {
                const CD_a = a.CD;
                const CD_b = b.CD;

                if (CD_a > CD_b) {
                  return -1;
                } else if (CD_a < CD_b) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "CD_rev": {
                const CD_a = a.CD;
                const CD_b = b.CD;

                if (CD_a > CD_b) {
                  return 1;
                } else if (CD_a < CD_b) {
                  return -1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "AB": {
                const AB_a = a.AB;
                const AB_b = b.AB;

                if (AB_a > AB_b) {
                  return -1;
                } else if (AB_a < AB_b) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "AB_rev": {
                const AB_a = a.AB;
                const AB_b = b.AB;

                if (AB_a > AB_b) {
                  return 1;
                } else if (AB_a < AB_b) {
                  return -1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "upgrades": {
                if (
                  (a?.["upgrades" + suffix] ?? 0) >
                  (b?.["upgrades" + suffix] ?? 0)
                ) {
                  return -1;
                } else if (
                  (a?.["upgrades" + suffix] ?? 0) <
                  (b?.["upgrades" + suffix] ?? 0)
                ) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "downgrades": {
                if (
                  (a?.["downgrades" + suffix] ?? 0) >
                  (b?.["downgrades" + suffix] ?? 0)
                ) {
                  return -1;
                } else if (
                  (a?.["downgrades" + suffix] ?? 0) <
                  (b?.["downgrades" + suffix] ?? 0)
                ) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "movers_up": {
                if ((a?.ABnewHigh ?? 0) > (b?.ABnewHigh ?? 0)) {
                  return -1;
                } else if ((a?.ABnewHigh ?? 0) < (b?.ABnewHigh ?? 0)) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              case "movers_down": {
                if ((a?.CDnewLow ?? 0) > (b?.CDnewLow ?? 0)) {
                  return -1;
                } else if ((a?.CDnewLow ?? 0) < (b?.CDnewLow ?? 0)) {
                  return 1;
                }

                return a.name > b.name ? 1 : -1;
              }

              default:
                return 0;
            }
          });

          this.userCollectionsIds = sortedIds.map((element: any) => element.id);
        } else {
          this.userCollectionsIds = listsIds;
        }
      } catch (error) {
        this.userCollectionsIds = [];
      }
    } else {
      this.userCollectionsIds = listsIds;
    }

    return this.userCollectionsIds;
  }

  /**
   *
   * @param {number} id
   * @param {string} timeframe
   *
   * @returns a list object with the info for build a tile widget
   */
  public async getListForTile(id: number, timeframe: UiTimeframe) {
    const apiList = this.apiList;
    const response = await apiList.portfolioFetch(
      [id],
      [
        "positionsToday",
        "name",
        "ownerId",
        "TCR",
        "TCR_D",
        "A_%",
        "B_%",
        "C_%",
        "D_%",
        "type",
        "assetTypes",
      ]
    );

    const list: any = this.formatList(response)[0];

    if (!list) {
      return null;
    }

    const alerts = await this.getAlerts([list.id], timeframe);
    const allocation = await this.getAllocationData(
      list.positionsToday,
      list.assetTypes
    );

    list["alerts"] = alerts;
    list["pieChartData"] = allocation;

    return list;
  }

  /**
   *
   * @param {Array} positions
   * @param {Object} assetTypes
   *
   * @returns the allocation info related to the positiions of a list. It makes 2 clusters, one for the geography dimension
   * and one for the sector dimension.
   */
  private async getAllocationData(positions, assetTypes) {
    const apiCluster = this.apiCluster;
    const preferences = this.preferences;
    const { allocation } = preferences?.["analysisList"] ?? {
      allocation: null,
    };

    let whatSegment = allocation?.["what"] ?? "1 Industry";
    let whereSegment = allocation?.["where"] ?? "Country";

    let isAllEtf = false;

    if (
      assetTypes &&
      "ETF" in assetTypes &&
      Object.keys(assetTypes).length === 1
    ) {
      isAllEtf = true;
    }

    if (isAllEtf) {
      whereSegment = "etfgeo";
      whatSegment = "AssetClass";
    }

    const whereResponse = await apiCluster
      .createConfiguration()
      .segment(whereSegment, true)
      .method("INTERSECTION")
      .analytics(["weight", "TCR"])
      .universeFromPositions(positions)
      .fetchAnalytics();

    const whatResponse = await apiCluster
      .createConfiguration()
      .segment(whatSegment, true)
      .method("INTERSECTION")
      .analytics(["weight", "TCR"])
      .universeFromPositions(positions)
      .fetchAnalytics();

    const what = this.normalizeCluster(whatResponse);
    const where = this.normalizeCluster(whereResponse);

    const turnKeyInTaxonomy = (object, segment: "what" | "where") => {
      const taxonomies = this.environment.get("rawTaxonomies");
      const isEtf =
        Object.keys(assetTypes ?? {}).length === 1 &&
        Object.keys(assetTypes ?? {})[0] === "ETF";
      const taxonFields = this.environment.get("setup")["taxonomyFields"];
      const field =
        segment === "where"
          ? isEtf
            ? "etfgeo"
            : "country"
          : isEtf
          ? "etfclass"
          : "sector";

      const taxonomy = taxonFields
        ? taxonomies[taxonFields[isEtf ? "ETF" : "security"][field]]
        : null;

      const result = Object.entries(object).map(([key, value]) => {
        if (taxonomy) {
          const id = getTaxonById(key, [taxonomy], 0);

          return { [id["name"]]: value };
        } else {
          return key;
        }
      });

      return result;
    };

    return {
      what: turnKeyInTaxonomy(what, "what"),
      where: turnKeyInTaxonomy(where, "where"),
    };
  }

  /**
   *
   * @param {string} listType PORTFOLIO or BASKET
   *
   * @returns {Promise}
   *
   * Wrap the server calls to obtain user collections filtered by type
   */
  private async getLists(listType: ListType, timeframe: UiTimeframe) {
    const ids = await this.getCollectionsIds(listType);
    const userLists = await this.getCollectionsAnalytics(ids, timeframe);

    this.collections[listType.toLowerCase()] = userLists;

    return this.collections[listType.toLowerCase()];
  }

  /**
   *
   * @param {Array.number} ids
   * @returns {Array}
   *
   * Fetch the analytics of all collections to display data in a table where every row contains the
   * info of a collection.
   */
  private async getCollectionsAnalytics(ids, timeframe: UiTimeframe) {
    const analytics = this.getAnalyticsByTimeframe(timeframe);
    const response = await this.apiList.portfolioFetch(ids, analytics);

    return this.formatList(response);
  }

  /**
   *
   * @param {object} subscriptions
   * @param {string} type
   *
   * @returns {Array}
   *
   * Filter only the subscriptions by type because when we get it from the server the subscription comes
   * together unfiltered by type
   */
  private async filterSubscriptionsByType(
    subscriptions: {
      id: number;
      userId: number;
      objectType: "COLLECTION";
      objectId: number;
      ownerId: number;
    }[],
    type: ListType
  ) {
    const subscriptionIds = subscriptions.map(
      (subscription) => subscription.id
    );
    const subsWithType = await this.apiList.portfolioFetch(subscriptionIds, [
      "type",
    ]);

    if (subsWithType) {
      return subsWithType
        .filter((sub) => sub.type === type)
        .map((sub) => sub.id);
    }

    return [];
  }

  /**
   *
   * @param {Array} ids
   * @param {string} timeframe
   *
   * @returns a Promise from the server with the symbols of the securities that are an upgrade, a downgrade or that moves up or down
   */
  private async _getAlerts(ids: number[], timeframe: UiTimeframe) {
    const apiInstrument = this.apiInstrument;

    const period = this.timeframeToNumber(timeframe);

    const relationConstraint = {
      dimension: "COLLECTION",
      operator: "relation",
      segments: ids,
    };

    const notExpiredConstraint = {
      dimension: "type",
      logicalOperator: "not",
      operator: "equals",
      segments: ["ExpiredStock"],
    };

    const params = {
      constraints: [
        [
          relationConstraint,
          notExpiredConstraint,
          {
            dimension: "lr",
            operator: "range",
            segments: [
              {
                "<=": period,
              },
            ],
          },
        ],
        [
          relationConstraint,
          notExpiredConstraint,
          {
            dimension: "mv",
            operator: "range",
            segments: [
              {
                "<=": -19,
              },
              {
                ">=": 19,
              },
            ],
          },
        ],
      ],
      sort: [
        {
          dimension: "marketcap",
          rev: true,
        },
      ],
      page: {
        page: 1,
        rows: 100000,
      },
    };

    const isScreeningSyntax = true;
    return await apiInstrument.screening(params, isScreeningSyntax);
  }

  /**
   *
   * @param {Array} symbols
   *
   * @returns the securities with the analytics used to build a table to display the results
   */
  private async getAlertsAnalytics(symbols: string[]) {
    const apiInstrument = this.apiInstrument;

    const properties = [
      { date: null, property: "ticker" },
      { date: null, property: "name" },
      { date: null, property: "symbol" },
      { date: null, property: "rc" },
      { date: null, property: "lr" },
      { date: null, property: "rrr" },
      { date: null, property: "mv" },
    ];

    const fetchParams: {
      properties: { date: null; property: string }[];
      symbols: string[];
      type: "security";
    } = {
      properties,
      symbols: symbols,
      type: "security",
    };

    const alertsEnriched = await apiInstrument.fetch(fetchParams, true);

    return alertsEnriched;
  }

  /**
   *
   * @param timeframe
   * @returns timeframe changet into number expected to the server
   */
  private timeframeToNumber(timeframe: UiTimeframe) {
    switch (timeframe) {
      case "daily":
      default:
        return 0;
      case "weekly":
        return 4;
      case "monthly":
        return 19;
    }
  }

  /**
   *
   * @param {response} rawData
   * @returns {data} normalized with analytics and the costituents clusterized
   */
  private normalizeCluster(rawData) {
    const data = deepClone(rawData);

    const stats = data["clustersStats"]["stats"];

    for (const [key, value] of Object.entries<any>(stats)) {
      //Access to the clusters key of the cluster response
      const clusterKey = data["clustersStats"]["clusters"][key] ?? null;

      //Get the clusters positions and assign to stats key to have data to use as costituents
      if (clusterKey != null) {
        value["data"] = clusterKey;
      }
    }

    return stats;
  }

  /**
   *
   * @param {Array} response
   *
   * @returns an array of collections formatted in a way to be usable from the UI
   */
  private formatList(response) {
    const lists: Collection[] = [];
    const envSetup = this.environment.get("setup");
    const userId = envSetup.account.user?.id;

    for (const list of response) {
      if (list.ownerId !== userId) {
        list["isReadOnly"] = true;
      }

      list["A_perc"] = list["A_%"];
      list["B_perc"] = list["B_%"];
      list["C_perc"] = list["C_%"];
      list["D_perc"] = list["D_%"];

      list["_s_ab"] = (list?.["A_%"] ?? 0) + (list?.["B_%"] ?? 0);
      list["_s_cd"] = (list?.["C_%"] ?? 0) + (list?.["D_%"] ?? 0);

      delete list["A_%"];
      delete list["B_%"];
      delete list["C_%"];
      delete list["D_%"];

      lists.push(list);
    }

    return lists;
  }

  /**
   *
   * @param {stirng} timeframe
   *
   * @returns the correct set of analytics to fetch based on the selected timeframe
   */
  private getAnalyticsByTimeframe(timeframe: UiTimeframe) {
    switch (timeframe) {
      case "daily":
        return this.listsFieldsToRetriveToday;
      case "weekly":
        return this.listsFieldsToRetriveLastWeek;
      case "monthly":
        return this.listsFieldsToRetriveLastMonth;
    }
  }
}
