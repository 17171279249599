import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { targetNameVariable, targetTickerVariable } from "../../utils/utils";
import { CustomComponent } from "../React_KeyFacts/React_KeyFacts";
import {
  YearToDateAnalyticsType,
  yearToDateAnalyticsReducer,
} from "./React_YearToDateAnalytics_reducer";
type YearToDateAnalyticsProps = {
  configObj: any;
};

const YearToDateAnalytics = forwardRef(
  ({ configObj }: YearToDateAnalyticsProps, ref) => {
    const initState: YearToDateAnalyticsType = useMemo(
      () => ({
        content: {
          benchmark: configObj.widgetValue.content.benchmark,
          delta: configObj.widgetValue.content.delta,
          headline: configObj.widgetValue.content.headline,
          maxDrawdown: configObj.widgetValue.content.maxDrawdown,
          monthlyPerformance: configObj.widgetValue.content.monthlyPerformance,
          monthlyStandardDeviation:
            configObj.widgetValue.content.monthlyStandardDeviation,
          strategy: configObj.widgetValue.content.strategy,
        },
        presentation: {
          table: configObj.widgetValue.presentation.table,
        },
      }),
      [
        configObj.widgetValue.content.benchmark,
        configObj.widgetValue.content.delta,
        configObj.widgetValue.content.headline,
        configObj.widgetValue.content.maxDrawdown,
        configObj.widgetValue.content.monthlyPerformance,
        configObj.widgetValue.content.monthlyStandardDeviation,
        configObj.widgetValue.content.strategy,
        configObj.widgetValue.presentation.table,
      ]
    );
    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state.content;
        tempState.widgetValue.presentation = state.presentation;
        return tempState;
      },
    }));

    const [state, dispatch] = useImmerReducer(
      yearToDateAnalyticsReducer,
      initState
    );

 
    return (
      <Box display={"flex"} gap={2} flexDirection={"column"}>
        {/* headline */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box display={"flex"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.content.headline.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CONTENT_headline_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Headline"
              />
              <TextField
                value={state.content.headline.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_CONTENT_headline_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.content.headline.isEnabled}
                size="small"
              />
            </Box>
          </CardContent>
        </Card>

        {/* table */}

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography>Months shown on:</Typography>
              </Box>
              <Box>
                <Select
                  size="small"
                  value={state.presentation.table}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_PRESENTATION_TABLE",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"ROWS"}> Rows</MenuItem>
                  <MenuItem value={"COLUMNS"}>Columns</MenuItem>
                </Select>
              </Box>
            </Box>

            {/* portfolio */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.content.strategy.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CONTENT_strategy_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                  />
                }
                label="Portfolio"
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={2}
                justifyContent={"end"}
              >
                <Button
                  disabled={!state.content.strategy.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_CONTENT_strategy_CONTENT",
                      payload:
                        state.content.strategy.content !== ""
                          ? `${state.content.strategy.content} ${targetNameVariable}`
                          : `${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>
                <TextField
                  value={state.content.strategy.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_strategy_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.content.strategy.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* benchmark */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={state.content.benchmark.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_CONTENT_benchmark_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                  />
                }
                label="Benchmark"
              />

              <Box
                display={"flex"}
                alignItems={"center"}
                gap={2}
                justifyContent={"end"}
              >
                <Button
                  disabled={!state.content.benchmark.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_CONTENT_benchmark_CONTENT",
                      payload:
                        state.content.benchmark.content !== ""
                          ? `${state.content.benchmark.content} ${targetNameVariable}`
                          : `${targetNameVariable}`,
                    })
                  }
                >
                  Add target's name
                </Button>
                <Button
                  disabled={!state.content.benchmark.isEnabled}
                  onClick={() =>
                    dispatch({
                      type: "SET_CONTENT_benchmark_CONTENT",
                      payload:
                        state.content.benchmark.content !== ""
                          ? `${state.content.benchmark.content} ${targetTickerVariable}`
                          : `${targetTickerVariable}`,
                    })
                  }
                >
                  Add target's ticker
                </Button>
                <TextField
                  value={state.content.benchmark.content}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_benchmark_CONTENT",
                      payload: e.target.value,
                    })
                  }
                  disabled={!state.content.benchmark.isEnabled}
                  size="small"
                />
              </Box>
            </Box>

            {/* delta */}
            <CustomComponent
              switchLabel={"Delta"}
              switchChecked={state.content.delta.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_delta_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.content.delta.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_delta_CONTENT",
                  payload: e,
                })
              }
            />
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Enable</Typography>
              <TextField size="small" value={"Row headline"} disabled />
            </Box>
            {/* monthlyPerformance */}
            <CustomComponent
              switchLabel={"Monthly Returns"}
              switchChecked={state.content.monthlyPerformance.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_monthlyPerformance_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.content.monthlyPerformance.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_monthlyPerformance_CONTENT",
                  payload: e,
                })
              }
            />

            {/* maxDrawdown */}
            <CustomComponent
              switchLabel={"Max Drawdown"}
              switchChecked={state.content.maxDrawdown.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_maxDrawdown_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.content.maxDrawdown.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_maxDrawdown_CONTENT",
                  payload: e,
                })
              }
            />

            {/* monthlyStandardDeviation */}
            <CustomComponent
              switchLabel={"Standard Deviation"}
              switchChecked={state.content.monthlyStandardDeviation.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_monthlyStandardDeviation_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.content.monthlyStandardDeviation.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_CONTENT_monthlyStandardDeviation_CONTENT",
                  payload: e,
                })
              }
            />
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default YearToDateAnalytics;
