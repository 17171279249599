import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../deepClone";
import AvailableFields from "../commonWidgets/AvailableFields";

import TableCustomizer from "./TableCustomizer";
import { TableCustomizerReducer } from "./TableCustomizer_reducer";
import DnD from "../commonWidgets/DnD_Generic";

type HoldingsTableProps = {
  configObj: any;
  columnsAvailable: any;
  wysiwyg: any;
};

const HoldingsTable = forwardRef(
  ({ configObj, columnsAvailable, wysiwyg }: HoldingsTableProps, ref) => {
    const operativeConfigObj = useMemo(() => configObj, [configObj]);
    const operativeColumnsAvailable = useMemo(
      () => columnsAvailable,
      [columnsAvailable]
    );

    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);

        if (state.useWysiwyg === true) {
          tempState.widgetValue.presentation.columns = wysiwyg.columns;
          tempState.widgetValue.presentation.useWysiwyg = state.useWysiwyg;
          tempState.widgetValue.content.headline = state.headline;
          tempState.widgetValue.content.sortBy = wysiwyg.actions.sortBy;
          // tempState.widgetValue.content.top = state.top;
          return tempState;
        } else {
          tempState.widgetValue.content.headline = state.headline;
          tempState.widgetValue.presentation.useWysiwyg = state.useWysiwyg;
          tempState.widgetValue.presentation.columns =
            dndRef.current.getInnerValue();

          if (state.sortByRank === false) {
            tempState.widgetValue.content.sortBy = state.sortBy;
          } else {
            tempState.widgetValue.content.sortBy = {
              descending: false,
              property: "rank",
            };
          }
        }

        tempState.widgetValue.presentation.sortByRank = state.sortByRank;
        tempState.widgetValue.content.rate = state.rate;
        tempState.widgetValue.content.top = state.top;
        tempState.widgetValue.isEdited = state.isEdited;
        tempState.widgetValue.presentation.rank = state.rank;

        return tempState;
      },
    }));

    const filterColumnsOnAvalilableCol = useCallback(() => {
      const colAvailable: any = Object.values<any>(operativeColumnsAvailable);
      const savedTemplateCols =
        operativeConfigObj?.widgetValue?.presentation?.columns ?? [];
      const columnsInTemplate: any = [];

      if (savedTemplateCols.length) {
        const savedTemplateColsMap = savedTemplateCols.reduce(
          (prev, current) => {
            return { ...prev, [current.property]: current };
          },
          []
        );

        for (const col of colAvailable) {
          for (const row of col) {
            if (row.field in savedTemplateColsMap) {
              columnsInTemplate.push(savedTemplateColsMap[row.field]);
            }
          }
        }
      }

      return columnsInTemplate;
    }, [
      operativeColumnsAvailable,
      operativeConfigObj?.widgetValue?.presentation?.columns,
    ]);

    const [state, dispatch] = useImmerReducer(TableCustomizerReducer, {
      ...operativeConfigObj.widgetValue.content,
      isEdited: operativeConfigObj.widgetValue.isEdited,
      columns: filterColumnsOnAvalilableCol(),
      rank: operativeConfigObj.widgetValue.presentation.rank,
      sortByRank:
        operativeConfigObj.widgetValue.presentation.sortByRank ?? false,
      useWysiwyg: operativeConfigObj.widgetValue.presentation.useWysiwyg,
    });

    const [selectedColumns, setSelectedColumns] = useState(() => {
      let temp = deepClone(operativeColumnsAvailable);
      const selectedFieldsMap = state.columns.reduce(
        (prev, current) => ({ ...prev, [current.property]: current }),
        {}
      );

      for (const column of Object.values<any>(temp)) {
        for (const row of column) {
          if (row.field in selectedFieldsMap) {
            row["selected"] = true;
          }
        }
      }

      return temp;
    });

    const dndRef = useRef<any>(null);
    const [preSaved, setPreSaved] = useState(state.columns);
    useEffect(() => {
      const dndInnerValue = dndRef?.current?.getInnerValue();
      // every time that selectedColumns changes, we filter
      // the objs that has selected:true and compare the arr
      // with preSaved
      const arr: any[] = [];
      const keys = Object.keys(selectedColumns);
      keys.forEach((key) => {
        selectedColumns[key].forEach((element) => {
          if ("selected" in element && element.selected === true) {
            arr.push({ label: element.label, property: element.field });
          }
        });
      });
      if (
        JSON.stringify(preSaved) !== JSON.stringify(arr) &&
        preSaved.length === arr.length
      ) {
        //when this condition is satisfied it means that the order changed
        setPreSaved(preSaved);
      } else if (
        JSON.stringify(preSaved) !== JSON.stringify(arr) &&
        preSaved.length !== arr.length
      ) {
        //means that something has been added  or removed from the array
        if (preSaved.length > arr.length) {
          // something has been removed,
          // then we look for the difference between the arrays
          // and remove the difference from dndInnerValue and set the state.
          let set1 = dndInnerValue.map((item) => item.property);
          let set2 = arr.map((item) => item.property);
          let difference = set1.filter((x) => !set2.includes(x))[0];
          for (let i = 0; i < dndInnerValue.length; i++) {
            if (dndInnerValue[i].property === difference) {
              // removing from array the item
              let temp: any[] = deepClone(dndInnerValue);
              let firstHalf = temp.splice(0, i);
              let secondHalf = temp.splice(1);
              temp = firstHalf.concat(secondHalf);
              setPreSaved(temp);
              break;
            }
          }
        }
        if (preSaved.length < arr.length) {
          // something has been added,
          let set1 = dndInnerValue.map((item) => item.property);
          let set2 = arr.map((item) => item.property);
          let difference = set2.filter((x) => !set1.includes(x))[0];
          let temp: any[] = deepClone(dndInnerValue);
          keys.forEach((key) => {
            selectedColumns[key].forEach((element) => {
              if (element.field === difference) {
                temp.push({ label: element.label, property: element.field });
              }
            });
          });
          setPreSaved(temp);
        }
      }
    }, [preSaved, selectedColumns]);

    return (
      <Box display="flex" flexDirection={"column"} gap={2}>
        <Box display="flex" gap={2}>
          <Box display={"flex"} flexDirection="column" gap={2}>
            <Card sx={{ boxShadow: 3, flexGrow: 1 }}>
              <CardContent sx={{ pb: "16px !important" }}>
                <Box display={"flex"} alignItems="center">
                  <FormControlLabel
                    // disabled={state.useWysiwyg}
                    control={
                      <Switch
                        onChange={(e) => {
                          dispatch({
                            type: "SET_HEADLINE_ISENABLED",
                            payload: e.target.checked,
                          });
                        }}
                        defaultChecked={state.headline.isEnabled}
                        size="small"
                      />
                    }
                    label="Headline"
                  />
                  <TextField
                    size="small"
                    defaultValue={state.headline.content}
                    disabled={!state.headline.isEnabled}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_HEADELINE_CONTENT",
                        payload: e.target.value,
                      });
                    }}
                  />
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ flexGrow: 1, boxShadow: 3 }}>
              <CardContent sx={{ pb: "16px !important" }}>
                <RadioGroup
                  value={state.useWysiwyg ?? false}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_USEWYSIWYG",
                      payload: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio size="small" />}
                    label="Print what you see on the screen"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio size="small" />}
                    label="Customize table and columns"
                  />
                </RadioGroup>
              </CardContent>
            </Card>
            {!state.useWysiwyg && (
              <Card sx={{ boxShadow: 3, flexGrow: 3 }}>
                <CardContent sx={{ pb: "16px !important" }}>
                  <TableCustomizer
                    useWysiwyg={state.useWysiwyg}
                    input={state}
                    dispatcher={dispatch}
                  />
                </CardContent>
              </Card>
            )}
          </Box>

          {!state.useWysiwyg && (
            <Box>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent sx={{ pb: "16px !important" }}>
                  {selectedColumns != null && (
                    <AvailableFields
                      onchangeState={setSelectedColumns}
                      columnsAvailable={selectedColumns}
                    />
                  )}
                </CardContent>
              </Card>
            </Box>
          )}
        </Box>
        {!state.useWysiwyg && (
          <Box>
            <Card sx={{ boxShadow: 3 }}>
              <CardContent sx={{ pb: "16px !important" }}>
                <Typography>Drag and drop column to change order.</Typography>
                <DnD
                  ref={dndRef}
                  direction={"horizontal"}
                  listContentObjBuilder={preSaved}
                />
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    );
  }
);

export default HoldingsTable;
