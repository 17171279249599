import { deepClone } from "../../../deepClone";
import { TDate } from "../../../trendrating/date/TDate";
import { Formatter } from "../../../trendrating/formatter/Formatter";
import { List, Strategy, SystematicProduct } from "../../../types/Api";
import { AppEnvironment } from "../../../types/Defaults";
import { _Base } from "../../_Base";
import { endpoints } from "../../endpoints";
import { Instruments } from "../Instruments";
import { Lists } from "../Lists";
import { Strategies } from "../Strategies";
import { SystematicProducts } from "../SystematicProducts";

interface AnalyticsInterface {
  getAnalytics: (
    analyitics: string[],
    startDate?: number,
    endDate?: number
  ) => any;
  get: (
    who: "H" | "B",
    what: "prices" | "allocations" | "components"
  ) => Promise<any[] | undefined>;
  invalidate: (who: "H" | "B", what?: "entity" | "H_POS" | "H_PRICES") => void;
  aTag: (set: Set, metric: Metrics, serie: Serie, period?: Period) => string;
}

type EntityType =
  | "SMS"
  | "STRATEGY"
  | "LIST"
  | "INSTRUMENT"
  | "COMBINE_ENTITIES";

type ListEntity = {
  portfolio: List;
  params: {
    method?: string;
    spanGranularity?: string;
    inceptionValue?: number;
    inceptionDay?: string;
    currency: string;
  };
};

type CombineEntity = {
  entitiesToCombine: (
    | {
        type: EntityType;
        entity: {
          symbol: string;
          params: {
            method?: string;
            spanGranularity?: string;
            inceptionValue?: number;
            inceptionDay?: string;
            currency: string;
          };
        };
        allocation: number;
      }
    | { type: EntityType; entity: Strategy }
    | { type: EntityType; entity: SystematicProduct }
    | { type: EntityType; entity: ListEntity }
  )[];
  combineParams: {
    currency: string;
    date: string;
    allocation: { [id: string]: number };
    inceptionValue?: number;
    method?: string;
  };
};

export type Entity = {
  includeFromDay?: string;
  type: EntityType;
  entity:
    | {
        symbol: string;
        params: {
          method?: string;
          spanGranularity?: string;
          inceptionValue?: number;
          inceptionDay?: string;
          currency: string;
        };
      }
    | Strategy
    | SystematicProduct
    | ListEntity
    | CombineEntity;
};

type Cache = {
  H: {
    entity: Entity | undefined;
    H_POS: undefined | [];
    H_PRICES: undefined | [];
    components: undefined | [];
  };
  B: {
    entity: Entity | undefined;
    H_POS: undefined | [];
    H_PRICES: undefined | [];
    components: undefined | [];
  };
  analytics: { [tag: string]: any } | undefined;
  startDate: number | null;
  endDate: number | null;
};

type Metrics =
  | "perf"
  | "perf_L"
  | "perf_S"
  | "drawdown"
  | "volatility"
  | "totalperf"
  | "rating"
  | "constituents_count"
  | "constituents_count_S"
  | "constituents_count_L"
  | "constituents_weight"
  | "constituents_weight_S"
  | "constituents_weight_L"
  | "perfAVG"
  | "perfAVG_M"
  | "perfAVG_Q"
  | "winningP"
  | "avgDrawdown"
  | "avgDrawdown_M"
  | "avgDrawdown_Q"
  | "losingP"
  | "sharpe"
  | "sterling"
  | "beta"
  | "sortino"
  | "information"
  | "treynor"
  | "trackingError"
  | "percWinningP"
  | "omega"
  | "sharpeRolling"
  | "YTD"
  | "STD"
  | "QTD"
  | "MTD"
  | "Y:1"
  | "Q:1"
  | "M:1"
  | "M:3"
  | "W:1"
  | "D:1"
  | "Y:3"
  | "Y:5"
  | "Y:10"
  | "FULL"
  | "yearlyTurnover"
  | "turnoverSingleAllocations"
  | "globalTurnover"
  | "globalAnnualizedTurnover"
  | "performanceAnnualized"
  | "volatilityAvg"
  | "volatilityAvg_M"
  | "volatilityAvg_Q"
  | "rating_A"
  | "rating_B"
  | "rating_C"
  | "rating_D"
  | "rating_S_A"
  | "rating_S_B"
  | "rating_S_C"
  | "rating_S_D"
  | "rating_L_A"
  | "rating_L_B"
  | "rating_L_C"
  | "rating_L_D"
  | "rating_A_%"
  | "rating_B_%"
  | "rating_C_%"
  | "rating_D_%"
  | "rating_L_A_%"
  | "rating_L_B_%"
  | "rating_L_C_%"
  | "rating_L_D_%"
  | "rating_S_A_%"
  | "rating_S_B_%"
  | "rating_S_C_%"
  | "rating_S_D_%";

type Set = "details" | "keyFacts" | "current" | "pos" | "holdingsHistory";

type Serie = "H" | "B" | "D" | "H,B";

type Period = "Y" | "S" | "Q" | "M" | "W" | "D";

class Input extends _Base {
  apiInstrument: Instruments;
  apiList: Lists;
  apiStrategies: Strategies;
  apiSMS: SystematicProducts;
  setup: AppEnvironment;
  formatter: Formatter;
  combineComponents: any[] | undefined;

  constructor(setup: AppEnvironment) {
    super(setup);
    this.apiInstrument = new Instruments(setup);
    this.apiList = new Lists(setup);
    this.apiStrategies = new Strategies(setup);
    this.apiSMS = new SystematicProducts(setup);
    this.setup = setup;
    this.formatter = new Formatter();
    this.combineComponents = undefined;
  }

  /**
   *
   * @param {Entity} entity
   *
   * @returns the historical allocation of an Entity
   */
  protected async getHistoricPositions(entity: Entity | undefined) {
    if (!entity) {
      throw new Error(
        "Cannot retrtive historical positions of an undefined entity"
      );
    }

    switch (entity.type) {
      case "INSTRUMENT": {
        const currentEntity = entity.entity as {
          symbol: string;
          params: {
            method?: string;
            spanGranularity?: string;
            inceptionValue?: number;
            inceptionDay?: string;
            currency: string;
          };
        };

        if (!entity.includeFromDay) {
          throw new Error("Cannot calculate the serie, date is undefined");
        }

        const date = new Date(entity.includeFromDay) as any;
        const dateInDays = TDate.dateToDays(date);

        const priceLevelPositions = [
          {
            A: 1,
            S: currentEntity.symbol,
            D: dateInDays,
          },
        ];

        const params = {
          POS: [{ d: dateInDays, v: priceLevelPositions }],
          currency: currentEntity.params.currency ?? "local",
          includeFromDay: entity.includeFromDay,
          method: "none",
          inceptionValue: 100,
        };

        if (currentEntity.params.inceptionDay) {
          params["inceptionDay"] = currentEntity.params.inceptionDay;
        }

        if (currentEntity.params.inceptionValue) {
          params["inceptionValue"] = currentEntity.params.inceptionValue;
        }

        if (currentEntity.params.method) {
          params["method"] = currentEntity.params.method;
        }

        if (currentEntity.params.spanGranularity) {
          params["spanGranularity"] = currentEntity.params.spanGranularity;
        }

        return params;
      }

      case "LIST": {
        const currentEntity = entity.entity as ListEntity;
        const list = currentEntity.portfolio;
        if (list.id) {
          const positionsAtToday = await this.apiList.portfolioFetch(
            [list.id],
            ["positionsToday"]
          );

          if (!entity.includeFromDay) {
            throw new Error("Cannot calculate the serie, date is undefined");
          }

          const date = new Date(entity.includeFromDay) as any;
          const dateInDays = TDate.dateToDays(date);
          const positionsToday = positionsAtToday?.[0]?.positionsToday ?? [];

          const priceLevelPositions = positionsToday?.map((position) => ({
            A: position["weight"],
            S: position["symbol"],
            D: dateInDays,
          }));

          const params = {
            POS: [{ d: dateInDays, v: priceLevelPositions }],
            currency: currentEntity.params.currency ?? "local",
            includeFromDay: entity.includeFromDay,
            method: "none",
            inceptionValue: 100,
          };

          if (currentEntity.params.inceptionDay) {
            params["inceptionDay"] = currentEntity.params.inceptionDay;
          }

          if (currentEntity.params.inceptionValue) {
            params["inceptionValue"] = currentEntity.params.inceptionValue;
          }

          if (currentEntity.params.method) {
            params["method"] = currentEntity.params.method;
          }

          if (currentEntity.params.spanGranularity) {
            params["spanGranularity"] = currentEntity.params.spanGranularity;
          }

          return params;
        } else {
          throw new Error("List id was not passed");
        }
      }

      case "STRATEGY": {
        const strategy = entity.entity as Strategy;

        if (strategy) {
          const backtestParams: any = this.apiStrategies.prepareParamsForRun(
            strategy.params,
            undefined
          );

          try {
            const backtestResult = await this.apiStrategies.backtest(
              backtestParams
            );

            let POS: { d: number; v: { A: number; S: string }[] }[] = [];

            if (backtestResult?.data) {
              if (backtestParams.hedging != null) {
                const combinedWithHedging = await this.computeHedging(
                  backtestParams,
                  backtestResult.data.POS
                );

                if (combinedWithHedging) {
                  POS = combinedWithHedging;
                }
              } else {
                POS = backtestResult.data.POS;
              }

              const firstPOS = POS[0];
              const includeFrom = this.formatter.date({
                options: {
                  isMillisecond: false,
                  notAvailable: {
                    input: null,
                    output: null,
                  },
                  separator: "-",
                },
                output: "TEXT",
                value: firstPOS.d,
              });

              const params = {
                currency: backtestParams.strategy.currency,
                includeFromDay:
                  backtestParams.backtesting.includeFromDay ||
                  (entity.includeFromDay ?? includeFrom),
                inceptionDay: backtestParams.pricing.inceptionDay,
                inceptionValue: backtestParams.pricing.inceptionValue,
                method: backtestParams.pricing.method,
                POS,
              };

              // Params used for combine
              params["name"] = strategy.name;
              params["granularity"] = strategy.params.strategy.rebalance;

              return params;
            } else {
              throw new Error("Cannot retrive the backtest result");
            }
          } catch (error: any) {
            throw error;
          }
        } else {
          throw new Error("undefined strategy entity");
        }
      }

      case "SMS":
        const systematicProduct = entity.entity as SystematicProduct;

        if (systematicProduct) {
          const productStrategyId = systematicProduct.strategyId;
          const historicalPortfolioId = systematicProduct.historicalPortfolioId;

          try {
            if (productStrategyId && historicalPortfolioId) {
              const historicalPortfolio = await this.apiSMS.listHistory({
                id: historicalPortfolioId,
              });
              const strategy = await this.apiStrategies.getById(
                productStrategyId
              );
              const strategyParams: any =
                this.apiStrategies.prepareParamsForRun(
                  strategy.params,
                  undefined
                );

              const _date =
                historicalPortfolio.inceptionDate < TDate.today()
                  ? historicalPortfolio.inceptionDate
                  : undefined;

              const date = _date ? TDate.daysToDate(_date) : undefined;
              const inceptionDate = date
                ? TDate.dateToIso8601(date)
                : undefined;

              let firstPOS: any = undefined;

              if (historicalPortfolio.POS.length > 0) {
                firstPOS = historicalPortfolio.POS?.[0];
              } else {
                historicalPortfolio.POS = [
                  {
                    d: TDate.today(),
                    v: [],
                  },
                ];
              }
              const params = {
                currency: historicalPortfolio.currency,
                inceptionDay: inceptionDate,
                inceptionValue: historicalPortfolio.inceptionValue,
                method: strategyParams.pricing.method,
                POS: historicalPortfolio.POS,
                expenseRatio: systematicProduct.expenseRatio,
              };

              if (entity.includeFromDay) {
                params["includeFromDay"] = entity.includeFromDay;
              } else if (firstPOS != null) {
                params["includeFromDay"] = TDate.daysToIso8601(firstPOS.d);
              }

              // Params used for combine
              params["name"] = systematicProduct.name;
              params["granularity"] = strategy.params.strategy.rebalance;

              return params;
            } else {
              throw new Error(
                `Cannot retrive historical positions with this info: Strategy: ${productStrategyId}, historicalPortfolio: ${historicalPortfolioId}`
              );
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          throw new Error(
            "Cannot retrive historical positions of an undefined Systematic product"
          );
        }

      case "COMBINE_ENTITIES": {
        const entities = entity.entity as CombineEntity;
        const histories: any = [];
        const requests: Promise<any>[] = [];

        for (const en of entities.entitiesToCombine) {
          requests.push(this.getHistoricPositions(en));
        }

        let method = "none";

        try {
          const response = await Promise.all(requests);
          if (response) {
            let methods: string[] = [];

            this.updateCombineComponents(
              response.map((res, index) => {
                const history = deepClone(res);
                methods.push(res.method);
                history["weight"] = entities.combineParams.allocation[index];

                return history;
              })
            );

            // Remove duplicates
            methods = [...new Set(methods)];

            let areAllMethodsEquals = methods.length === 1;

            if (areAllMethodsEquals) {
              method = methods[0];
            } else {
              method = "none";
            }

            for (const [index, history] of response.entries()) {
              histories.push({
                POS: history.POS.map((item) => ({
                  v: item?.v,
                  d: item?.d,
                  de: item?.de,
                })),
                A: entities.combineParams.allocation[index] ?? 0,
              });
            }
          }
        } catch (error) {
          console.error(error);
        }

        const combineParams = {
          currency: entities?.combineParams?.currency ?? "local",
          portfolios: histories,
        };

        const combined = await this.apiStrategies.combineHistoricalPositions(
          combineParams
        );

        if (combined) {
          const { POS } = combined;

          return {
            currency: entities.combineParams?.currency ?? "local",
            includeFromDay:
              entity.includeFromDay ?? TDate.daysToIso8601(POS?.[0]?.d),
            inceptionDay: entities.combineParams.date,
            inceptionValue: entities.combineParams?.inceptionValue ?? 100,
            method,
            POS,
          };
        }
      }
    }
  }

  private async computeHedging(backtestJSON, H_POS) {
    const hedging = deepClone(backtestJSON.hedging);

    const hedgingStrategy = {
      backtesting: deepClone(backtestJSON.backtesting),
      pricing: {
        benchmark: null,
        inceptionDay: backtestJSON.pricing.inceptionDay,
        inceptionValue: 100,
        initialCapital: 1000000,
        method: "none",
      },
      strategy: {
        blackList: null,
        cappingRules: {
          maxAllocation: 1,
          minAllocation: 1,
        },
        currency: "local",
        rank: [],
        selectionRules: {
          maxPositions: 1,
          constraints: hedging?.constraints ?? [],
        },
        holdingRules: {},
        trimOutliers: false,
        weightingRules: {
          weightCriteria: "EQUAL_WEIGHTED",
          weightRule: "REBALANCE",
        },
      },
      traking: null,
      universe: {
        search: {
          page: { page: 1, rows: 20000 },
          sort: { dimension: "marketcap", rev: true },
          filters: [{ dimension: "symbol", segments: [hedging.symbol] }],
        },
      },
    };

    const hedging_POS = await this.apiStrategies.backtest(hedgingStrategy);

    if (hedging_POS && hedging_POS.status === "OK" && hedging_POS?.data) {
      const posToCombine = [
        { A: 1, POS: H_POS },
        { A: (hedging?.leverage ?? 1) * -1, POS: hedging_POS.data.POS },
      ];

      const combined = await this.apiStrategies.combineHistoricalPositions({
        currency: backtestJSON.strategy.currency,
        portfolios: posToCombine,
      });

      if (combined && combined.POS) {
        return combined.POS;
      }
    }
  }

  /**
   *
   * @param {Array} componentsHistories
   *
   * Update the variable that keep all the histories of every single component of a combine operation.
   * This is necessary to show the curve of the separate components of a combine curve.
   *
   */
  public updateCombineComponents(componentsHistories: any[] | undefined) {
    this.combineComponents = componentsHistories;
  }

  /**
   * Reads from combineComponents
   *
   * @returns A copy of the content of the combineComponents variable
   *
   */
  public getCombineComponents() {
    if (!this.combineComponents) {
      return this.combineComponents;
    }

    const copy: any[] = [];

    for (const componentHistory of this.combineComponents) {
      copy.push(deepClone(componentHistory));
    }

    return copy;
  }
}

/**
 * This class retrive the analyitics of 2 given entity. The purpouse about this structure is to abstract and unify the process of
 * the analytics computation.
 */
export class Analytics extends Input implements AnalyticsInterface {
  apiList: Lists;
  private cache: Cache;
  private turnover: Turnover;

  private constructor(environment: AppEnvironment) {
    const setup = environment;
    super(setup);

    this.cache = this.cacheInit();
    this.turnover = new Turnover(this.aTag, environment);
    this.apiList = new Lists(environment);
  }

  // #region Public Methods
  /**
   * This method acts like the constructor of the class but make possible to handle promises before the class Object is been returned.
   * To achive this we call the original contructor method by making an instance of the class, after that we load the entities
   * ensuring that the benchmark entity is loaded correctly according to the business logic (handle special cases like an empty SMS).
   * At the end we return the insance after we have waited the execution of all the promises.
   *
   * @param environment
   * @param {Entity} firstEntity the main entity (H)
   * @param {Entity} secondEntity the benchmark entity (B)
   *
   * @returns {Analytics} the analytics class instance preconfigured to handle special benchmark load cases (SMS)
   */
  static async initialize(
    environment: AppEnvironment,
    firstEntity: Entity,
    secondEntity?: Entity
  ) {
    const analytics = new Analytics(environment);

    try {
      analytics.load(firstEntity, "first");

      if (secondEntity) {
        if (firstEntity.type === "SMS") {
          await analytics.handleBenchmarkOnSMS(firstEntity, secondEntity);
        } else {
          analytics.load(secondEntity, "second");
        }
      }
    } catch (error) {
      throw new Error(error as string);
    } finally {
      return analytics;
    }
  }

  /**
   *
   * @param {Array} analyitics
   * @param {number} startDate
   * @param {number} endDate
   *
   * @returns the analytics of the entities passed in the constructor
   */
  public async getAnalytics(
    analyitics: string[],
    startDate?: number,
    endDate?: number
  ) {
    const startingDate = startDate ?? null;
    const endingDate = endDate ?? null;

    // If dates are equals to the dates in cache the curves are valid
    const datesValid =
      startingDate === this.cache.startDate &&
      endingDate === this.cache.endDate;

    // If dates are not valid the analytics are cleared because they refers to another curve
    if (!datesValid) {
      this.invalidate("general", "analytics");

      // Save in cache the new Dates
      this.cache["startDate"] = startingDate;
      this.cache["endDate"] = endingDate;
    }

    // 1. Check the analytics cache
    try {
      if (this.cache["analytics"]) {
        const uncachedAnalytics: string[] = [];

        // 2. Loop the analytics list passed as arguments and check if every analytic in the list exists in cache
        for (const tag of analyitics) {
          // 3. If the analytic is not in cache keep it in the uncachedAnalytics list
          if (!this.cache["analytics"][tag]) {
            uncachedAnalytics.push(tag);
          }
        }

        // 4. If the uncached list of analytics contains something it means that some analytics must be asked to the Server, otherwise
        //    return the cached analytics because everyone is already in cache
        if (uncachedAnalytics.length) {
          // 5. Ask for uncached analytics and update the cache
          const response = await this.updateAnalytics(uncachedAnalytics);

          if ("status" in response && response.status !== 200) {
            return response;
          }
        }

        // 6. Now the cache is the old one if every analytic was in cache or the updated cache with the old and new analytics
        return {
          data: this.cache["analytics"],
          errorDetails: null,
          status: 200,
        };
      } else {
        // 7. the cache is empty so by calling updateAnalytics we ensure that that a new request is done to the server
        //    and the results will be saved in the cache

        const response = await this.updateAnalytics(analyitics);

        if ("status" in response && response.status !== 200) {
          return response;
        }

        // 8. At this point the cache is filled with the asked analytics so we return it
        return {
          data: this.cache["analytics"],
          errorDetails: null,
          status: 200,
        };
      }
    } catch (error: any) {
      // 9. If an error occures return it and ensure that the caller will handle it
      const errorObj = {
        data: undefined,
        status: 500,
        errorDetails: error?.response ?? "Unknown error",
      };
      if ("status" in error) {
        errorObj["status"] = error.status;
      }

      return errorObj;
    }
  }

  /**
   * This method swap the selected entity and guarantees that the cache is cleared in the right way
   *
   * @param which The entity type to be replaced
   * @param entity The entity replace value
   *
   */
  public async swapEntity(which: "H" | "B", entity: Entity | undefined) {
    this.invalidate("general", "analytics");
    this.invalidate(which, "entity");

    if (entity != null) {
      if (which === "B") {
        const mainEntity = this.cache["H"]["entity"];
        if (mainEntity?.type === "SMS") {
          await this.handleBenchmarkOnSMS(mainEntity, entity);
        } else {
          this.load(entity, "second");
        }
      } else {
        this.load(entity, "first");
      }
    }
  }

  /**
   *
   * @param {string} who
   * @param {string} what
   *
   * @returns the desired curve about the prices or the historical allocations of an entity
   */
  public async get(
    who: "H" | "B",
    what: "prices" | "allocations" | "components",
    startDate?: number | null,
    endDate?: number | null
  ) {
    if (what === "components") {
      if (this.cache[who].entity?.type !== "COMBINE_ENTITIES") {
        console.warn(
          "You are trying to ask the histories of components but the entity is not a combine one"
        );
        return;
      }

      // Check for the H_POS in the cache because if they are filled it means that the histories are already been asked
      if (this.cache[who]["H_POS"]) {
        const componentsHistories = this.cache[who]["components"];

        if (componentsHistories && componentsHistories.length) {
          const components = await this.getComponentsCurve(componentsHistories);

          return components;
        }
      }
    } else {
      const curveType = what === "prices" ? "H_PRICES" : "H_POS";

      let curve: undefined | [] = undefined;

      try {
        // 1. Check if the desired informations are already cached
        if (
          this.cache[who][curveType] != null &&
          this.cache[who][curveType]?.length
        ) {
          // 2. The curve exists so can be returned to the caller function
          curve = this.cache[who][curveType];
        } else {
          // 3. The curve is empty so call getPriceHistory that will populate the missing curve
          await this.getPriceHistory();

          // 4. Now the curve is ready and has aready been cached so data about the asked curve can be returned to the caller
          curve = this.cache[who][curveType];
        }
      } catch (error) {
        throw new Error(`${what} about the curve ${who} were not found`);
      } finally {
        if (what === "prices" && curve != null) {
          return this.cutPriceCurve(startDate ?? null, endDate ?? null, curve);
        } else if (what === "allocations" && curve != null) {
          return this.cutHPOS(startDate ?? null, endDate ?? null, curve);
        }

        return [];
      }
    }
  }

  /**
   * This method ensure the correct way to load a benchmark on SMS.
   *
   * 1 - Retrive the Historical portfolio and cache it this will ensure that the historical portfolio is asked one time.
   * 2 - Check for the allocations
   * 3 - If the portfolio as no allocations (POS = []) change the syntax of the object by simulating an empty allocation
   *     and empty the benchmark because we cannot calculate analytics of the benchmark when the main entity has no allocations
   *
   * @param {Entity} firstEntity main entity (H)
   * @param {Entity} benchmarkEntityEntity benchmark entity (B)
   *
   * @returns {void}
   *
   */
  private async handleBenchmarkOnSMS(
    firstEntity: Entity,
    benchmarkEntityEntity: Entity
  ) {
    if (firstEntity.type === "SMS") {
      const systematicProduct = firstEntity.entity as SystematicProduct;
      const historicalPortfolioId = systematicProduct.historicalPortfolioId;

      try {
        if (historicalPortfolioId) {
          const historicalPortfolio = await this.apiSMS.listHistory({
            id: historicalPortfolioId,
          });

          if (historicalPortfolio) {
            if (historicalPortfolio.POS.length > 0) {
              this.set("H", "H_POS", historicalPortfolio.POS);
              this.load(benchmarkEntityEntity, "second");

              return;
            } else {
              const allInCash: any = [
                {
                  d: TDate.today(),
                  v: [],
                },
              ];
              this.set("H", "H_POS", allInCash);
              this.invalidate("B");

              return;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    return console.log(
      "The first entity is not of type SMS this is an error because this method can only work with first entities of type SMS"
    );
  }

  /**
   * This method check for benchmark existance.
   *
   * This actually is used in SMS to check if benchmark is set, is a particular condition to know more about that please
   * check the handleBenchmarkOnSMS method of this class
   *
   * @returns {boolean}
   */
  public checkBenchmark() {
    const B = this.cache["B"].entity;

    return B != null;
  }

  /**
   * This method cut an HPOS curve
   *
   * @param {number} startDate the start point of the curve
   * @param {number} endDate the last point of the curve
   * @param {Array} curve the curve to be cutted
   *
   * @returns {Array} the cutted curve
   */
  private cutHPOS(
    startDate: number | null,
    endDate: number | null,
    curve: any[]
  ) {
    if (endDate === null && startDate === null) {
      return curve;
    }

    if (endDate! <= startDate!) {
      return curve.slice(0, 3);
    }

    const startingPoint = startDate ?? -9999999;
    const endPoint = endDate ?? 9999999;

    curve = [...curve];
    const res: any = [];

    // Cut allocations
    let startingAllocationDate = -9999999;
    let endAllocationDate = 9999999;
    let startAllocationIndex = 0;

    for (const POS of curve) {
      if (POS.d <= startingPoint) {
        startAllocationIndex += 1;
        startingAllocationDate = POS.d;
      } else {
        break;
      }
    }

    for (
      startAllocationIndex;
      startAllocationIndex < curve.length;
      startAllocationIndex++
    ) {
      if (curve[startAllocationIndex].d <= endPoint) {
        endAllocationDate = curve[startAllocationIndex].d;
      } else {
        break;
      }
    }

    for (const allocation of curve) {
      if (
        allocation.d >= startingAllocationDate &&
        allocation.d <= endAllocationDate
      ) {
        res.push(allocation);
      }
    }

    return res;
  }

  /**
   *
   * @param startDate
   * @param endDate
   * @param curve
   *
   * @returns A copy of the price index curve cutted on the dates passed
   */
  private cutPriceCurve(
    startDate: number | null,
    endDate: number | null,
    curve: any[]
  ): any[] {
    if (startDate === null && endDate === null) {
      return curve;
    }

    let startIndex = -1;
    let endIndex = curve.length - 1;

    if (startDate === null) {
      startDate = curve[0].d;
    }

    if (endDate === null) {
      endDate = curve[endIndex].d;
    }

    if (endDate! <= startDate!) {
      return curve.slice(0, 3);
    }

    let d;
    curve = [...curve];

    for (let i = 0; i < curve.length; i++) {
      d = curve[i].d;

      if (startIndex === -1 && d >= startDate!) {
        startIndex = i;
      }

      if (d >= endDate!) {
        endIndex = i;

        break;
      }
    }

    const cuttedPriceH = curve.slice(Math.max(0, startIndex - 1), endIndex + 1);

    return cuttedPriceH;
  }

  /**
   *
   * @param {string} who
   * @param {string} what
   *
   * Clear the cache.
   *
   * It can clear the cache totally or partially but in the second case it sequentially clear all the cache keys that
   * depends on the cleared item.
   */
  public invalidate(
    who: "H" | "B" | "general",
    what?: "entity" | "H_POS" | "H_PRICES" | "analytics"
  ) {
    if (what) {
      // If the cache has to be cleared partially clear both the selected key and the ones that are related to it
      switch (what) {
        case "entity": {
          this.cache[who] = {
            entity: undefined,
            H_POS: undefined,
            H_PRICES: undefined,
            components: undefined,
          };
          this.cache.analytics = undefined;

          break;
        }

        case "H_POS": {
          this.cache[who]["H_POS"] = undefined;
          this.cache[who]["components"] = undefined;
          this.cache[who]["H_PRICES"] = undefined;
          this.cache["analytics"] = undefined;

          break;
        }

        case "H_PRICES": {
          this.cache[who]["H_PRICES"] = undefined;
          this.cache["analytics"] = undefined;

          break;
        }

        case "analytics": {
          this.cache["analytics"] = undefined;
        }
      }
    } else {
      // Hard reset of the cache
      this.cache[who] = {
        entity: undefined,
        H_POS: undefined,
        H_PRICES: undefined,
        components: undefined,
      };
      this.cache.analytics = undefined;
    }
  }

  /**
   *
   * @param {string} set
   * @param {string} metric
   * @param {string} serie
   * @param {stirng} period
   *
   * @returns a Tag that is a result of a mapping process that transform a semantic string into a key that identify a specific analytic.
   * This function is called both to build an analytic tag and to access to the object response keys
   */
  public aTag(
    set: Set,
    metric: Metrics,
    serie?: Serie,
    period?: Period,
    strict = false
  ) {
    let tag;

    const setAnalyticsTag = (
      metric: Metrics[keyof Metrics],
      serie: Serie[keyof Serie],
      sampling: string | number,
      samplingUnit: string | number,
      samplingMode: string,
      windows: Period[keyof Period],
      windowsUnit: string | number,
      windowsMode: string,
      params?: string | number
    ) => {
      var tag =
        metric +
        "#" +
        sampling +
        "," +
        samplingUnit +
        "," +
        samplingMode +
        "," +
        windows +
        "," +
        windowsUnit +
        "," +
        windowsMode +
        "," +
        serie;
      if (params !== undefined) {
        tag += "," + params;
      }
      return tag;
    };

    const mapPeriod = {
      Y: "YEARLY",
      S: "SYXMONTHS",
      Q: "QUARTERLY",
      M: "MONTHLY",
      W: "WEEKLY",
      D: "DAILY",
    };
    const metricMap = {
      perf: "r",
      drawdown: "md",
      volatility: "sd",
      volatilityAvg: "avg_sd_Y_M",
      volatilityAvg_M: "avg_sd_M_M",
      volatilityAvg_Q: "avg_sd_Q_M",
      totalperf: "r",
      perfAVG: "avg_r_Y",
      perfAVG_M: "avg_r_M",
      perfAVG_Q: "avg_r_Q",
      winningP: "winlose",
      avgDrawdown: "avg_md_Y",
      avgDrawdown_M: "avg_md_M",
      avgDrawdown_Q: "avg_md_Q",
      losingP: "winlose",
      sharpe: "sharpe",
      sterling: "sterling",
      beta: "beta",
      sortino: "sortino",
      information: "information",
      trackingError: "trackingError",
      treynor: "treynor",
      percWinningP: "winlose",
      omega: "omega",
      sharpeRolling: "sharpe",
    };

    const mapSerie = { H: "H", B: "B", D: "DIFF", "H,B": "H,B" };

    if (set === "details") {
      switch (metric) {
        case "sharpeRolling": {
          if (period) {
            tag = setAnalyticsTag(
              metricMap[metric],
              mapSerie[serie ?? "H"],
              mapPeriod[period],
              1,
              "CALENDAR",
              mapPeriod[period],
              12,
              "CALENDAR"
            );
          }

          break;
        }

        case "omega": {
          if (period) {
            tag = setAnalyticsTag(
              metricMap[metric],
              mapSerie[serie ?? "H"],
              mapPeriod[period],
              1,
              "CALENDAR",
              mapPeriod[period],
              12,
              "CALENDAR",
              "abs"
            );
          }

          break;
        }

        case "performanceAnnualized": {
          tag = setAnalyticsTag(
            metricMap["totalperf"],
            mapSerie[serie ?? "H"],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            mapPeriod[period ?? "Y"]
          );

          break;
        }
        case "volatilityAvg_M":
        case "volatilityAvg_Q":
        case "volatilityAvg": {
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL"
          );

          break;
        }

        case "volatility": {
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie ?? "H"],
            mapPeriod[period!],
            1,
            "CALENDAR",
            mapPeriod[period!],
            1,
            "CALENDAR",
            "MONTHLY"
          );

          break;
        }

        default: {
          if (period) {
            tag = setAnalyticsTag(
              metricMap[metric],
              mapSerie[serie ?? "H"],
              mapPeriod[period],
              1,
              "CALENDAR",
              mapPeriod[period],
              1,
              "CALENDAR"
            );
          }
        }
      }
    } else if (set === "keyFacts") {
      switch (metric) {
        case "totalperf":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie ?? "H"],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL"
          );
          break;
        case "perf":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie ?? "H"],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            "YEARLY"
          );

          break;
        case "perfAVG_M":
        case "perfAVG_Q":
        case "perfAVG":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL"
          );

          break;
        case "winningP":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            "true,1,false"
          );
          break;
        case "drawdown":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL"
          );
          break;
        case "avgDrawdown_M":
        case "avgDrawdown_Q":
        case "avgDrawdown":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL"
          );

          break;
        case "volatility":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            "MONTHLY"
          );
          break;
        case "losingP":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            "true,-1,false"
          );
          break;
        case "sharpe":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break;
        case "sterling":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break;
        case "beta":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break; //!!!!
        case "sortino":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break;
        case "information":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break;
        case "treynor":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "null"
          );
          break;
        case "trackingError": {
          tag = "trackingError#null,null,FULL,null,null,FULL,H,YEARLY,null,B";

          break;
        }
        case "percWinningP":
          tag = setAnalyticsTag(
            metricMap[metric],
            mapSerie[serie!],
            "null",
            "null",
            "FULL",
            "null",
            "null",
            "FULL",
            "false,1,true"
          );
          break;

        case "Y:1":
        case "Q:1":
        case "M:1":
        case "M:3":
        case "W:1":
        case "D:1":
        case "Y:3":
        case "Y:5":
        case "Y:10": {
          const mapMetric2Period = {
            "Y:1": "YEARLY",
            "Q:1": "QUARTERLY",
            "M:1": "MONTHLY",
            "M:3": "MONTHLY",
            "W:1": "WEEKLY",
            "D:1": "DAILY",
            "Y:3": "YEARLY",
            "Y:5": "YEARLY",
            "Y:10": "YEARLY",
          };

          const periodSplitted = metric.split(":");
          const periodValue = periodSplitted[1];

          const sampleType: string = strict === false ? "SOLAR" : "ROLLING";

          tag = setAnalyticsTag(
            "r",
            mapSerie[serie ?? "H"],
            "null",
            "null",
            "FULL",
            mapMetric2Period[metric],
            periodValue,
            sampleType,
            period ? mapPeriod[period] : undefined
          );
        }
      }
    } else if (set === "current") {
      const mapMetric2Period = {
        YTD: "YEARLY",
        STD: "SIXMONTH",
        QTD: "QUARTERLY",
        MTD: "MONTHLY",
        "Y:1": "YEARLY",
        "Q:1": "YEARLY",
        "M:1": "YEARLY",
        "W:1": "YEARLY",
        "D:1": "YEARLY",
        "Y:3": "YEARLY",
        "Y:5": "YEARLY",
        "Y:10": "YEARLY",
        FULL: "YEARLY",
      };

      tag = setAnalyticsTag(
        "r",
        mapSerie[serie!],
        "null",
        "null",
        "FULL",
        mapMetric2Period[metric],
        1,
        "TD"
      );
    } else if (set === "pos") {
      switch (metric) {
        case "yearlyTurnover": {
          tag = "turnover#YEARLY,1,CALENDAR,YEARLY,1,CALENDAR";

          break;
        }

        case "turnoverSingleAllocations": {
          tag = "turnover#NULL,NULL,NULL,NULL,NULL,NULL";

          break;
        }
        case "globalTurnover": {
          tag = "turnover#NULL,NULL,FULL,NULL,NULL,FULL";

          break;
        }
        case "globalAnnualizedTurnover": {
          tag = "turnover#NULL,NULL,FULL,NULL,NULL,FULL,YEARLY";

          break;
        }
      }
    } else if (set === "holdingsHistory") {
      const SIGNUM_DICTINOARY = {
        constituents_count_L: "1,",
        constituents_count_S: "-1,",
        constituents_count: "NULL,",
        constituents_weight_L: "1,",
        constituents_weight_S: "-1,",
        constituents_weight: "NULL,",
        rating_L_A: "1,",
        rating_L_B: "1,",
        rating_L_C: "1,",
        rating_L_D: "1,",
        "rating_L_A_%": "1,",
        "rating_L_B_%": "1,",
        "rating_L_C_%": "1,",
        "rating_L_D_%": "1,",
        rating_S_A: "-1,",
        rating_S_B: "-1,",
        rating_S_C: "-1,",
        rating_S_D: "-1,",
        "rating_S_A_%": "-1,",
        "rating_S_B_%": "-1,",
        "rating_S_C_%": "-1,",
        "rating_S_D_%": "-1,",
        rating_A: "NULL,",
        rating_B: "NULL,",
        rating_C: "NULL,",
        rating_D: "NULL,",
        "rating_A_%": "NULL,",
        "rating_B_%": "NULL,",
        "rating_C_%": "NULL,",
        "rating_D_%": "NULL,",
        perf_L: ",1",
        perf_S: ",-1",
        perf: "",
      };

      const signum = SIGNUM_DICTINOARY[metric];

      switch (metric) {
        case "constituents_count_L":
        case "constituents_count_S":
        case "constituents_count": {
          tag = `constituents#NULL,NULL,NULL,NULL,NULL,NULL,${signum}count`;
          break;
        }
        case "constituents_weight_L":
        case "constituents_weight_S":
        case "constituents_weight": {
          tag = `constituents#NULL,NULL,NULL,NULL,NULL,NULL,${signum}weight`;
          break;
        }
        case "rating_L_A":
        case "rating_L_B":
        case "rating_L_C":
        case "rating_L_D":
        case "rating_L_A_%":
        case "rating_L_B_%":
        case "rating_L_C_%":
        case "rating_L_D_%":
        case "rating_S_A":
        case "rating_S_B":
        case "rating_S_C":
        case "rating_S_D":
        case "rating_S_A_%":
        case "rating_S_B_%":
        case "rating_S_C_%":
        case "rating_S_D_%":
        case "rating_A":
        case "rating_B":
        case "rating_C":
        case "rating_D":
        case "rating_A_%":
        case "rating_B_%":
        case "rating_C_%":
        case "rating_D_%": {
          const ratingMap = {
            rating_L_A: "A",
            rating_L_B: "B",
            rating_L_C: "C",
            rating_L_D: "D",

            "rating_L_A_%": "A_%",
            "rating_L_B_%": "B_%",
            "rating_L_C_%": "C_%",
            "rating_L_D_%": "D_%",

            rating_S_A: "A",
            rating_S_B: "B",
            rating_S_C: "C",
            rating_S_D: "D",

            "rating_S_A_%": "A_%",
            "rating_S_B_%": "B_%",
            "rating_S_C_%": "C_%",
            "rating_S_D_%": "D_%",

            rating_A: "A",
            rating_B: "B",
            rating_C: "C",
            rating_D: "D",

            "rating_A_%": "A_%",
            "rating_B_%": "B_%",
            "rating_C_%": "C_%",
            "rating_D_%": "D_%",
          };

          const rating = ratingMap[metric];

          tag = `rating#NULL,NULL,NULL,NULL,NULL,NULL,${signum}${rating}`;

          break;
        }
        case "perf_L":
        case "perf_S":
        case "perf": {
          tag = `performance#NULL,NULL,NULL,NULL,NULL,NULL${signum}`;
        }
      }
    }

    return tag;
  }
  // #endregion

  // #region Private Methods
  /**
   *
   * @param {string} tag
   *
   * @returns the tag type and is needed to discriminate which tag must be asked to the Analytic service and who has to be
   * asked at the hPosAnalyticsService
   */
  private aTagType(tag: string) {
    const explodedTag = tag.split("#");
    const analytic = explodedTag[0];

    switch (analytic) {
      case "turnover":
      case "constituents":
      case "rating":
      case "performance":
        return "hPOS";

      default:
        return "price";
    }
  }

  /**
   *
   * @param {Array} analyiticsList
   *
   * @returns the analytics related to a single entity
   */
  private async updateAnalytics(analyticsList: string[]) {
    // 1. Declare a result variable that will be returned filled with data or undefined if an error occures
    let result: any = undefined;

    const analytics: string[] = [];
    let tagType: "price" | "hPOS" | undefined = undefined;

    // 2. Separate turnover analytics from the others (the must be asked to a different backend service)
    for (const analytic of analyticsList) {
      tagType = this.aTagType(analytic);

      if (tagType === "price") {
        analytics.push(analytic);
      } else {
        // 2.1 store the analytics about the turnover in the turnover class
        this.turnover.storeTurnoverAnalytic(analytic);
      }
    }

    try {
      // 3. Update the state with the H_POS and H_PRICE of the entities that are loaded
      await this.getPriceHistory();

      // 4. Now that we have the price histories of the loaded entities we fetch the required analytics
      const response = await this.computeAnalytics(analytics);

      if (response) {
        // 5. Control the cache, if is null or undefined (first time) initialize it to an empty object otherwise update it
        if (this.cache.analytics == null) {
          this.cache.analytics = {};
        }

        // 6. Update the cache with the analytics that comes from the server
        for (const [tag, analytic] of Object.entries<{ [tag: string]: any }>(
          response
        )) {
          this.cache.analytics[tag] = analytic;
        }

        // 8. Fill result variable with the data
        result = { ...this.cache["analytics"] };

        return result;
      }
    } catch (error: any) {
      return error;
    }
  }

  /**
   *
   * @param {Array} analyitics
   *
   * @returns {Promise} fullfilled with the analytics ased to the server
   */
  private async computeAnalytics(analyitics: string[]) {
    let H: any[] | undefined = this.cache.H.H_PRICES;
    let B: any[] | undefined = this.cache.B.H_PRICES;

    const hasToCutCurves =
      this.cache.startDate !== null || this.cache.endDate !== null;

    if (!H) {
      throw new Error("Main entitity(H) price level is not calculated yet");
    }

    // Benchmark curve doesn't need to be cutted cause this is done server side
    if (hasToCutCurves) {
      H = this.cutPriceCurve(this.cache.startDate, this.cache.endDate, H);
    }

    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.common.analytics;

    const payload = {
      H,
      stats: analyitics,
    };

    if (B != null) {
      payload["B"] = B;
    }

    let result: any = undefined;

    try {
      let H_POS: any = this.cache.H.H_POS;

      if (hasToCutCurves) {
        H_POS = this.cutHPOS(this.cache.startDate, this.cache.endDate, H_POS);
      }

      const turnoverAnalytics = this.turnover.get(
        H_POS,
        this.getEntityCurrency("H")
      );

      const promises: Promise<any>[] = [turnoverAnalytics];

      if (analyitics.length) {
        promises.push(this.preparePost(url, payload));
      }

      const response = await Promise.all(promises);

      if (response && response.length) {
        result = response.reduce((prev, current) => {
          if (current && current.stats) {
            prev = { ...prev, ...current.stats };
          }

          return prev;
        }, {});
      }
    } catch (error: any) {
      const errorObj = {
        data: undefined,
        status: 500,
        errorDetails: error?.response ?? "Unknown error",
      };
      if ("status" in error) {
        errorObj["status"] = error.status;
      }

      result = errorObj;
    } finally {
      return result;
    }
  }

  /**
   * This method retrive the curreny of an entity
   *
   * @param {string} curve The entity who the currency is referred
   *
   * @returns {string} the curreny of the desired entity
   */
  private getEntityCurrency(curve: "H" | "B") {
    const entity = this.cache[curve].entity as any;

    switch (entity?.type) {
      case "COMBINE_ENTITIES":
        return entity?.entity?.combineParams?.currency ?? "local";
      case "LIST":
        return entity?.entity?.params?.currency ?? "local";
      case "INSTRUMENT":
        return entity?.entity?.params?.currency ?? "local";
      case "SMS":
        return entity?.entity?.currency ?? "local";
      case "STRATEGY":
        return entity?.entity?.params?.strategy?.currency ?? "local";
    }
  }

  /**
   *
   * @param {Entity} entity
   * @param {string} which
   *
   * Load an entity in the cache
   */
  private load(entity: Entity, which: "first" | "second") {
    switch (which) {
      case "first": {
        this.cache.H.entity = entity;

        break;
      }
      case "second": {
        this.cache.B.entity = entity;

        break;
      }
      default:
        throw new Error(
          `Cannot load entity as ${which}. Only 2 entities can be loaded`
        );
    }
  }

  /**
   *
   * @returns an object which is the prototype of the cache
   */
  private cacheInit() {
    return {
      H: {
        entity: undefined,
        H_POS: undefined,
        H_PRICES: undefined,
        components: undefined,
      },
      B: {
        entity: undefined,
        H_POS: undefined,
        H_PRICES: undefined,
        components: undefined,
      },
      analytics: undefined,
      startDate: null,
      endDate: null,
    };
  }

  /**
   * To calculate the Analytics what is needed is a serie of historical prices. This function wrap the logic to retrive those data and
   * update the cache by setting in it the historical prices about the entities passed into the class constructor
   */
  private async getPriceHistory() {
    try {
      await this.getPriceOf("H");

      if (this.cache["B"]["entity"]) {
        await this.getPriceOf("B");
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   *
   * @param {string} curve
   *
   * Retrives and save in the cache the hsitorical prices of the specified entity
   */
  private async getPriceOf(curve: "H" | "B") {
    // 1. Check if in the cache the price levels are already asked
    if (this.cache[curve].H_PRICES) {
      // 2. If price levels of the specified entities are in cache return them
      return this.cache[curve].H_PRICES;
    } else {
      // 3. To get the price level of an entity is necessary at first to get the historical positions so let's check in the cache for it
      if (this.cache[curve].H_POS) {
        // 4. If the historical positions are in cache prepare the payload to send to the server to obtain the price level of a given
        //    entity
        const entity = this.cache[curve]["entity"];
        if (entity) {
          try {
            // 5. Collect the data to prepare the payload based on the entity type
            const params = await this.collectIndexRunParams(entity);
            const POS = this.cache[curve].H_POS;
            if (params) {
              params["POS"] = POS;
              // 6. Ask to the server the price level curve about the entity
              const priceLevel = await this.indexRun(params as any);

              // 7. Once the server send back the price level cache it
              this.set(curve, "H_PRICES", priceLevel);
              // 8. Retun the prices
              return this.cache[curve].H_PRICES;
            }
          } catch (error) {
            throw new Error("Cannot retrive params to calculate price index");
          }
        } else {
          throw new Error("Cannot get price level of an undefined entity");
        }
      } else {
        try {
          // Check if the curve target is the benchmark
          if (curve === "B") {
            // Check if a date for the curve start is passed
            const benchmark = this.cache?.[curve]["entity"];
            if (benchmark != null && benchmark.includeFromDay == null) {
              if ((this.cache["H"]["H_POS"] as any)?.[0]?.["d"]) {
                benchmark.includeFromDay = TDate.daysToIso8601(
                  (this.cache["H"]["H_POS"] as any)?.[0]?.["d"]
                );
              }
            }
          }

          // 9. If we are at this point it means that the cache is empty so before get the price levels we need to collect the
          //    Historical positions of the entitiy and that's what this function do
          const historicAllocation = await this.getHistoricPositions(
            this.cache[curve]["entity"]
          );

          // 10. If the entity is a combine one store the histories of every components because they're
          //     needed in the case we have to show the curve of a single component of the combine (chart tab for example)
          if (this.cache[curve]["entity"]?.type === "COMBINE_ENTITIES") {
            const componentsHistories = this.getCombineComponents();
            this.set(curve, "components", componentsHistories as any);

            // 11. Clear the variable that keep the components histores because we've stored it and now the variable must be empty
            //     to store the histories of the components in the case of another entity is a combine one.
            this.updateCombineComponents(undefined);
          }

          // 12. Check what type of entity is because instruments are managed in a different way so the serie of historical prices
          // is ready as it comes from the getHistoricPositions func. (Currecy conversion is already done)
          // if (this.cache?.[curve]?.["entity"]?.["type"] === "INSTRUMENT") {
          //   this.set(curve, "H_PRICES", historicAllocation);
          //   return historicAllocation;
          // } else {
          // 11. Update the cache with historic positions sent back by the server
          if (historicAllocation) {
            this.set(curve, "H_POS", historicAllocation!.POS);
            // 12. Now we can ask for price level
            const priceLevel = await this.indexRun(historicAllocation as any);
            // 13. Update the cache with the results
            this.set(curve, "H_PRICES", priceLevel);
            // 14. Finally return the price level
            return priceLevel;
          }
          // }
        } catch (error) {
          throw error;
        }
      }
    }
  }

  /**
   *
   * @param {string} who
   * @param {string} key
   * @param {stirng} value
   *
   * Update the cache
   */
  private set(
    who: "H" | "B",
    key: keyof Cache["H" | "B"],
    value?: Entity & []
  ) {
    this.cache[who][key] = value;
  }

  /**
   *
   * @param {Object} params
   *
   * @returns {Promise} with a price level curve
   */
  private async indexRun(params: {
    POS: [{ d: number; v: [{ A: number; S: number; D?: number }] }];
    currency: string;
    inceptionDay: string;
    inceptionValue: number;
    method: string;
    includeFromDay?: string;
    spanGranularity?: string;
    expenseRatio?: number;
  }) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.run;

    const payload = {
      POS: params.POS,
      currency: params.currency,
      inceptionDay: params.inceptionDay,
      inceptionValue: params.inceptionValue,
      method: params.method,
      includeFromDay: params?.includeFromDay ?? null,
    };

    if (params.spanGranularity) {
      payload["spanGranularity"] = params.spanGranularity;
    }

    const response = await this.preparePost(url, payload);

    let error: any = null;
    if (response["status"] === "KO") {
      error = this.simulateHttpError(response);
    } else if ("WARNING" in response["data"]) {
      error = this.simulateHttpError(response);
    }

    if (error != null) {
      return error;
    }

    if (params.expenseRatio && params.expenseRatio > 0) {
      return await this.apiSMS.getFees(
        params.expenseRatio,
        response.data.CURVES.H
      );
    } else {
      // Set explicit null value on B if absent
      return response.data.CURVES.H;
    }
  }

  /**
   *
   * @param {Array} componentsHistories
   *
   * Retrive the
   */
  private async getComponentsCurve(componentsHistories: any[]) {
    const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
    const url = endPointRoot + endpoints.strategies.longShort;
    const backtests = componentsHistories;

    const runs: Promise<any>[] = [];

    const weights = {};

    for (const [i, backtest] of backtests.entries()) {
      weights[i] = backtest.weight;
      runs.push(this.indexRun(backtest));
    }

    const prices = await Promise.all(runs);

    let granularity: any = null; // No fallback in case the granularity is missing

    if (prices) {
      const componentsRequest = prices.map((price, i) => {
        // Adjust granularity
        switch (componentsHistories[i].granularity) {
          case "05_DAYS": {
            granularity = "WEEKLY";

            break;
          }
          case "20_DAYS": {
            granularity = "MONTHLY";

            break;
          }
          case "60_DAYS": {
            granularity = "QUARTERLY";

            break;
          }
          default:
            break;
        }

        const paramObject = {
          granularity: granularity,
          inceptionValue: 100,
          components: [
            {
              A: weights[i],
              H: price,
            },
          ],
        };

        return this.preparePost(url, paramObject);
      });

      const components = await Promise.all(componentsRequest);

      if (components && components.length) {
        return components.map((component, i) => ({
          ...component.data,
          name: componentsHistories[i].name,
          type: weights[i] > 0 ? "long" : "short",
        }));
      }
    }
  }

  /**
   *
   * @param {Entity} entity
   *
   * @returns the an object with the params used as payload for the server call that retrives the price level of the desired entity
   */
  private async collectIndexRunParams(entity: Entity) {
    switch (entity.type) {
      default:
        return undefined;

      case "LIST": {
        const list = entity.entity as ListEntity;
        return list.params;
      }

      case "STRATEGY": {
        const strategy = entity.entity as Strategy;

        const backtestParams: any = this.apiStrategies.prepareParamsForRun(
          strategy.params,
          undefined
        );

        return {
          currency: backtestParams.strategy.currency,
          includeFromDay: backtestParams.backtesting.includeFromDay,
          inceptionDay: backtestParams.pricing.inceptionDay,
          inceptionValue: backtestParams.pricing.inceptionValue,
          method: backtestParams.pricing.method,
        };
      }

      case "SMS": {
        const systematicProduct = entity.entity as SystematicProduct;

        const productStrategyId = systematicProduct.strategyId;

        if (productStrategyId) {
          try {
            const strategy = await this.apiStrategies.getById(
              productStrategyId
            );
            const strategyParams: any = this.apiStrategies.prepareParamsForRun(
              strategy.params,
              undefined
            );

            const params = {
              currency: strategyParams.strategy.currency,
              includeFromDay: strategyParams.backtesting.includeFromDay,
              inceptionDay: strategyParams.pricing.inceptionDay,
              inceptionValue: strategyParams.pricing.inceptionValue,
              method: strategyParams.pricing.method,
              expenseRatio: systematicProduct.expenseRatio,
            };

            return params;
          } catch (error) {
            return undefined;
          }
        }
        return undefined;
      }
    }
  }

  // #endregion
}
class Turnover extends _Base {
  private analytics: string[];

  private aTag: (
    set: Set,
    metric: Metrics,
    serie: Serie,
    period?: Period
  ) => string;
  constructor(
    private tagBuilder: (
      set: Set,
      metric: Metrics,
      serie: Serie,
      period?: Period
    ) => string,
    private setup: AppEnvironment
  ) {
    super(setup);

    this.analytics = [];
    this.aTag = tagBuilder;
  }

  public storeTurnoverAnalytic(tag: string) {
    this.analytics.push(tag);
  }

  public async get(
    H: {
      i_g: number;
      de: number;
      d: number;
      v: { A: number; S: string; D: number }[];
    }[],
    currency: string
  ) {
    const turnoverAnalytics = this.analytics;

    if (!H || !H.length) {
      return;
    }

    const get = this.getTurnover(turnoverAnalytics, H, currency);

    let result: any = null;

    try {
      const response: any = await get;

      if (response) {
        result = response?.data ?? null;
      }
    } catch (error) {
      throw new Error("Somenthing went wrong retriving turnover analytics");
    } finally {
      this.analytics = [];
      return result;
    }
  }

  private async getTurnover(
    analytics: string[],
    H_POS: {
      i_g: number;
      de: number;
      d: number;
      v: { A: number; S: string; D: number }[];
    }[],
    currency
  ) {
    if (analytics && analytics.length) {
      const endPointRoot = this.getEndpointRoot(this.environment.api.compute);
      const url = endPointRoot + endpoints.common.hPosAnalytics;

      const payload = {
        POS: H_POS,
        currency,
        stats: analytics,
      };
      return await this.preparePost(url, payload);
    } else return {};
  }
}
