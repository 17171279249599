import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { BreakdownType, breakdownReducer } from "./React_Breakdown_reducer";
import { useImmerReducer } from "use-immer";
import ReportSelect from "../../commonWidgets/Select";
import InputNumber from "../../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../../deepClone";

type BreakdownProps = {
  configObj: any;
};

const selectOptions = [
  {
    label: "Sectors",
    value: "INDUSTRY",
  },
  {
    label: "Industries",
    value: "SECTOR",
  },
  {
    label: "Markets",
    value: "MARKET",
  },
  {
    label: "Regions",
    value: "REGION",
  },
  {
    label: "Areas",
    value: "AREA",
  },
];

const Breakdown = forwardRef(({ configObj }: BreakdownProps, ref) => {
  const initState: BreakdownType = useMemo(
    () => ({
      content: configObj.widgetValue.content,
      presentation: configObj.widgetValue.presentation,
    }),
    [configObj]
  );

  const [state, dispatch] = useImmerReducer(breakdownReducer, initState);
  useEffect(() => {
    console.log("state at Breakdown widget", state);
  }, [state]);

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state.content;
      tempState.widgetValue.presentation = state.presentation;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent sx={{ pb: "16px !important" }}>
          <Box display={"flex"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.headline.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_CONTENT_headline_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              value={state.content.headline.content}
              onChange={(e) =>
                dispatch({
                  type: "SET_CONTENT_headline_CONTENT",
                  payload: e.target.value,
                })
              }
              disabled={!state.content.headline.isEnabled}
              size="small"
            />
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            pb: "16px !important",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>Dimension</Typography>
            <ReportSelect
              setOutterState={(e) =>
                dispatch({ type: "SET_CONTENT_dimension", payload: e })
              }
              options={selectOptions}
              defaultValue={state.content.dimension}
            />
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.holdings}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_holdings",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label=" Number of Holdings"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.presentation.totalWeight}
                onChange={(e) =>
                  dispatch({
                    type: "SET_PRESENTATION_totalWeight",
                    payload: e.target.checked,
                  })
                }
                size="small"
              />
            }
            label="Total Weight"
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>Max items</Typography>
            <InputNumber
            maxWidth={100}
              initVal={state.content.rows}
              isPercentage={false}
              setOuterState={(e) =>
                dispatch({ type: "SET_CONTENT_rows", payload: e })
              }
              isFloating={false}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Breakdown;
