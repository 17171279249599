import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { config } from "../../../config-ts";
import { removeLoader } from "../../../utils";
import { appCookie } from "../../../Cookies";

const SALES_LABELS = {
  PREMIUM: "PERFORMANCE MANAGEMENT PLATFORM",
  SYSTEMATIC_ENGINE: "STRATEGY MANAGEMENT SOLUTION",
};
const VALID_FLAVOUR_CODES = ["PREMIUM", "SYSTEMATIC_ENGINE"];

export default function ProductPage() {
  const [products, setProducts] = useState<any[]>([]);
  useEffect(() => {
    removeLoader();
  }, []);

  useEffect(() => {
    const url = "/trendrating-rest/api/product/all";
    axios.get(url, undefined).then((response) => {
      let data = response["data"]["data"]["products"];
      var datum: any = null;
      var _products: any[] = [];
      var salesLabels = SALES_LABELS;
      var validFlavourCodes = VALID_FLAVOUR_CODES;
      for (let i = 0, length = data.length; i < length; i++) {
        datum = data[i];
        if (validFlavourCodes.indexOf(datum["productCode"]) !== -1) {
          _products.push({
            label: salesLabels[datum["productCode"]],
            value: datum["id"],
          });
        }
      }
      setProducts(_products);
    });
  }, []);

  const btnHandler = useCallback((id) => {
    const productId = id;
    appCookie("productId", productId);
    let targetUrl = config.router.base + "/";
    const redirectToUrl = appCookie("redirect-from-product-page", null);
    if (redirectToUrl != null && redirectToUrl !== "") {
      console.log(
        `%Found location ${decodeURI(redirectToUrl)}`,
        "color:blue; font-size:1.5em"
      );
      targetUrl = decodeURI(redirectToUrl);
    }

    appCookie("redirect-from-product-page", "");
    window.location.href = targetUrl; // needs reload
  }, []);

  return (
    <div className="tPageAccount-menu">
      <div className="tPageAccount-menuIcon">
        <img
          src="/img/trendrating_icon.png"
          height="128"
          width="128"
          alt="Trendrating"
        />
      </div>
      <h1 className="tPageAccount-menuTitle">Solutions</h1>
      <ul className="tPageAccount-products">
        {products.map((product, index) => (
          <li
            className="tPageAccount-product"
            onClick={() => btnHandler(product.value)}
            key={index}
            style={{ textAlign: "center" }}
          >
            <span className="tPageAccount-productLink">{product.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
