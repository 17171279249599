import { Box } from "@mui/material";
import React, { useCallback } from "react";
import RelevantEvents from "./widgets/RelevantEvents";
import PortfolioView from "./widgets/PortfolioView";
import BoxContent from "./widgets/BoxContent";
import { AnalysisStateType } from "./PortfolioAnalysis.reducer";

type Props = {
  value: AnalysisStateType;
  saving: Function;
  preferenceDispatcher: Function;
};

export default function PortfolioAnalysis({
  value,
  saving,
  preferenceDispatcher,
}: Props) {
  const onUpgradeDowngradeChange_handler = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_UPGRADES_DOWNGRADES",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );
  const onNewHigh_handler = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_NEW_HIGHS",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );
  const onNewLow_handler = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_NEW_LOWS",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );
  const onViewChange = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_VIEW",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  const onWhereChange = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_ANALYSISLIST_ALLOCATION_WHERE",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  const onWhatChange = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_ANALYSISLIST_ALLOCATION_WHAT",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  const onExposureChange = useCallback(
    (value) => {
      preferenceDispatcher({
        type: "SET_ANALYSISLIST_EXPOSURE_RATINGS",
        payload: value,
        saving: saving,
      });
    },
    [preferenceDispatcher, saving]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <PortfolioView onViewChange={onViewChange} view={value.view} />
      {value.view === "tile" && (
        <BoxContent
          where={value.allocation.where}
          onWhereChange={onWhereChange}
          what={value.allocation.what}
          onWhatChange={onWhatChange}
          exposure={value.exposure.ratings}
          onExposureChange={onExposureChange}
        />
      )}

      <RelevantEvents
        hasUpgradeDowngrade={value.alerts.hasUpgradeDowngrade}
        onUpgradeDowngradeChange={onUpgradeDowngradeChange_handler}
        hasNewLow={value.alerts.hasNewLow}
        onNewLowChange={onNewLow_handler}
        hasNewHigh={value.alerts.hasNewHigh}
        onNewHighChange={onNewHigh_handler}
      />
    </Box>
  );
}
