import { Box } from "@mui/material";
import { useState } from "react";
import {
  Dashboards,
  Insights,
} from "../editors/Advanced/Result/tabs/Insights/Insights";
import styles from "./StrategiesInsights.module.scss";
import { Catalog } from "../editors/Advanced/Result/tabs/Catalog/Catalog";

type StrategiesInsightsButtonGroupProps = {
  active?: "insights" | "catalog";
  handleBtnClick: (value: "insights" | "catalog") => void;
  topGutter?: boolean;
  withSpace?: boolean;
};

type StrategiesInsightsProps = {
  dashboard: Dashboards;
  askBeforeRedirect: boolean;
};

export function StrategiesInsightsButtonGroup({
  active,
  handleBtnClick,
  topGutter = false,
  withSpace = false,
}: StrategiesInsightsButtonGroupProps) {
  return (
    <Box
      className={`${styles.buttonGroup} ${
        withSpace ? styles.buttonGroup__withBorder : ""
      }`}
    >
      <button
        onClick={() => handleBtnClick("insights")}
        className={`${styles.buttonGroup__btn} ${
          active === "insights" ? styles.buttonGroup__btn__active : ""
        } ${topGutter ? styles.buttonGroup__btn__mt : ""}`}
      >
        Insights
      </button>
      <button
        onClick={() => handleBtnClick("catalog")}
        className={`${styles.buttonGroup__btn} ${
          active === "catalog" ? styles.buttonGroup__btn__active : ""
        } ${topGutter ? styles.buttonGroup__btn__mt : ""}`}
      >
        Directory
      </button>
    </Box>
  );
}

export function StrategiesInsights({
  dashboard,
  askBeforeRedirect,
}: StrategiesInsightsProps) {
  const [view, setView] = useState<"insights" | "catalog">("insights");

  return (
    <Box
      sx={{ width: "100%" }}
      height={"100%"}
      overflow={"hidden"}
      display="flex"
      flexDirection={"column"}
    >
      <Box px={1}>
        <StrategiesInsightsButtonGroup
          active={view}
          handleBtnClick={setView}
          topGutter
        />
      </Box>
      <Box width={"100%"} height={"100%"} overflow={"auto"} p={1}>
        {view === "insights" ? (
          <Insights
            dashboards={dashboard}
            askBeforeInsightsRedirect={askBeforeRedirect}
          />
        ) : (
          <Catalog
            catalogDashboard={dashboard.catalog}
            askBeforeRedirect={askBeforeRedirect}
          />
        )}
      </Box>
    </Box>
  );
}
