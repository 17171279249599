import { useCallback } from "react";
import styles from "../Alternatives.module.scss";
import { Action } from "../AlternativesReducer";
import { LandingSwapList } from "./LandingSwapList";
import { SwapDrawDown } from "./SwapDrawDown";

type SwapListProps = {
    fromList: any[];
    toList: any[];
    dispatch: (act: Action) => void;
};

export function SwapList({ fromList, toList, dispatch }: SwapListProps) {
    const onDelete = useCallback(
        (listItem, key: "symbol" | "swapId") => {
            dispatch({
                type: "REMOVE_FROM_SWAP_LIST",
                payload: listItem[key],
            });
        },
        [dispatch]
    );
    return fromList.length && toList.length ? (
        <div className={styles.dialog__swap__list__content}>
            <div className={styles.dialog__swap__list__content__column}>
                <div
                    className={
                        styles.dialog__swap__list__content__column__title__row
                    }
                >
                    <h2>Replace</h2>
                    <h2>with</h2>
                </div>
                <div
                    className={
                        styles.dialog__swap__list__content__column__content__row
                    }
                >
                    <SwapDrawDown
                        list={fromList.map((el) => ({
                            ...el.item,
                            swapId: el.swapId,
                        }))}
                    />
                    <SwapDrawDown
                        deleteHandler={(security) =>
                            onDelete(security, "symbol")
                        }
                        list={toList}
                    />
                </div>
            </div>
        </div>
    ) : (
        <LandingSwapList />
    );
}
