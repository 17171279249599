import { Card, CardContent, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { deepClone } from "../../../../../deepClone";
import RatingCheckbox from "../../../../RatingCheckbox/RatingCheckbox";
import { OptionNumber } from "../OptionNumber/OptionNumber";
import { OptionNumberOperator } from "../OptionNumberOperator/OptionNumberOperator";
import { OptionNumberOperatorPercentage } from "../OptionNumberOperatorPercentage/OptionNumberOperatorPercentage";
import OptionNumberPercentage from "./OptionNumberPercentage";
import OptionNumberSingleIntervalNew from "./OptionNumberSingleIntervalNew";
import { MarketCapFilter } from "../../../../../js/app/pages/screening/FilterBar/FilterWidgets/MarketCapFilter/MarketCapFilter";
import { RangeVolatility } from "../../../../../js/app/components/ui/form/RangeVolatility";
import { useEnvironment } from "../../../../../hooks/useEnvironment";

type EditorPreviewProps = {
  title: string;
  data: any;
  dispatcher: Function;
};

export default function EditorPreview({
  data,
  dispatcher,
  title,
}: EditorPreviewProps) {
  const [editorPreviewState, setEditorPreviewState] = useState<any>();

  useEffect(() => {
    setEditorPreviewState(data);
  }, [data]);

  //!!
  //!!
  //!!-------------------------------------------------------
  //!!-------------------------------------------------------
  //!!livello di sincronizzazione con il componente superiore
  useEffect(() => {
    if (editorPreviewState) {
      let _operatorParam = deepClone(editorPreviewState.operatorItem.value);
      dispatcher({
        type: "SET_OPERATOR_PARAMS",
        payload: _operatorParam,
      });
    }
  }, [dispatcher, editorPreviewState]);
  //!!-------------------------------------------------------
  //!!-------------------------------------------------------
  //!!
  //!!

  const setSubject = useCallback((subject) => {
    setEditorPreviewState((prev) => {
      prev["operatorItem"]["value"]["subject"] = subject;
      return prev;
    });
  }, []);

  const setValue = useCallback((value) => {
    setEditorPreviewState((prev) => {
      let temp = deepClone(prev);
      temp["operatorItem"]["value"]["augmentation"]["value"] = value;
      return temp;
    });
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <Card sx={{ height: "100%", boxShadow: 3 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          {!editorPreviewState ? (
            <></>
          ) : (
            <>
              <ComponentToShow
                dispatcher={dispatcher}
                field={data.propertyID}
                widgetConstraints={
                  editorPreviewState["operatorItem"]["widget"]["constraints"]
                }
                widgetType={
                  editorPreviewState["operatorItem"]["widget"]["type"]
                }
                itemType={editorPreviewState["operatorItem"]["type"]}
                setSubject={setSubject}
                setValue={setValue}
                subject={editorPreviewState["operatorItem"]["value"]["subject"]}
                value={
                  editorPreviewState["operatorItem"]["value"]["augmentation"][
                    "value"
                  ]
                }
              />
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export type OuterState = {
  augmentation: {
    value: any;
  };
  subject: string;
};

type ComponentToShowProps = {
  stateSetter?: any;
  subject: any;
  value: any;
  setSubject: Function;
  setValue: Function;
  itemType: string;
  widgetType: string;
  widgetConstraints: boolean;
  dispatcher: Function;
  field: string;
};
export const ComponentToShow = memo(
  ({
    field,
    setSubject,
    setValue,
    value,
    subject,
    itemType,
    widgetType,
    widgetConstraints,
    dispatcher,
  }: ComponentToShowProps) => {
    const item: OuterState = useMemo(
      () => ({
        augmentation: { value: value },
        subject: subject,
      }),
      [subject, value]
    );
    const rangeVolatilityInitValue = useMemo(
      () =>
        widgetType === "rangeVolatility"
          ? {
              left: item["augmentation"].value[0].ge
                ? item["augmentation"].value[0].ge * 100
                : null,
              right: item["augmentation"].value[0].le
                ? item["augmentation"].value[0].le * 100
                : null,
            }
          : { left: null, right: null },
      [item, widgetType]
    );

    const environment = useEnvironment();
    const propertiesAPI = useMemo(
      () => environment.get("rawProperties"),
      [environment]
    );
    const itemsConstraints = useMemo(
      () => propertiesAPI[field]?.input,
      [field, propertiesAPI]
    );

    const componentToReturn = useMemo(() => {
      switch (itemType) {
        case "augmented": {
          switch (widgetType) {
            case "number": {
              if (item != null && item["subject"] === "outlier") {
                // Outlier is a special case where the number is
                // managed as not a percentage by the server, but
                // it needs to be shown as percentage
                return (
                  <OptionNumberPercentage
                    initialValue={item.augmentation.value!}
                    item={item}
                    setOuterState={(v) => {
                      setValue(v["augmentation"]["value"]);
                    }}
                  />
                );
              } else {
                return (
                  <OptionNumber
                    getInputValidity={(e) =>
                      dispatcher({ type: "SET_errorAtInputValue", payload: e })
                    }
                    constraints={widgetConstraints}
                    //? why prop key to this component?
                    /* this component wont rerender, and will keep
                  the internal state event if props has changed.
                  in this case i can use the prop key with the value
                  <top|bottom> to rerender the component and resetting the internal state. 
                  there is no side effect in this case once there will 
                  be only one of this component and the available keys are 2*/
                    key={item.subject}
                    item={item}
                    initialValue={item.augmentation.value}
                    setOuterState={(v) => {
                      setValue(v["augmentation"]["value"]);
                    }}
                  />
                );
              }
            }
            case "numberPercentage": {
              /*
              ? why this case is never used???
              ? checked with breakpoint on 
              ? trendrating-widgets/form/instrumentSetConstraints/create/_FactoryOptionMixin.js
              ? selecting all options in the the old wizz but no case found
              */
              return (
                <OptionNumberPercentage
                  initialValue={item.augmentation.value!}
                  item={item}
                  setOuterState={(v) => {
                    setValue(v["augmentation"]["value"]);
                  }}
                />
              );
            }

            /* case "numberHidden": {
              !! the case numberHidden was used by the old wizzard 
              !! when ,for example, rangeQuantile was selected and
              !! no input was needed. with the new wizz it shoul never
              !! run into the wizzard. 
              // alert("--");
              // return (
              //   <OptionNumberHiddenWidget
              //     dojoEvents={{
              //       change: (e) => {
              //         console.log("testttt", e);
              //       },
              //     }}
              //     dojoProps={{ item: item, value: null }}
              //   />
              // );
              return <></>;
            }*/
            case "numberOperator": {
              return (
                <OptionNumberOperator
                  inputConstraints={itemsConstraints}
                  getInputValidity={(e) =>
                    dispatcher({ type: "SET_errorAtInputValue", payload: e })
                  }
                  constraints={widgetConstraints}
                  //? why prop key to this component?
                  /*thi component is used to greaterThan and lessThan
                  this component wont rerender, and will keep
                  the internal state event if props has changed.
                  in this case i can ise the prop key with the value
                  <greaterThan|lessThan> to rerender the component and resetting the internal state. 
                  */
                  key={subject}
                  item={item}
                  setOuterState={(v: OuterState) => {
                    setValue(v["augmentation"]["value"]);
                  }}
                  initialValue={item["augmentation"]}
                />
              );
            }
            case "numberOperatorPercentage": {
              return (
                <OptionNumberOperatorPercentage
                  inputConstraints={itemsConstraints}
                  getInputValidity={(e) =>
                    dispatcher({ type: "SET_errorAtInputValue", payload: e })
                  }
                  constraints={widgetConstraints}
                  //? why prop key to this component?
                  /*thi component is used to greaterThanPercentage and 
                  lessThanPercentage.the
                  component wont rerender, and will keep
                  the internal state event if props has changed.
                  in this case i can ise the prop key with the value
                  <greaterThanPercentage|lessThanPercentage> to rerender the component and resetting the 
                  internal state.
                  */
                  key={subject}
                  item={item}
                  setOuterState={(v: OuterState) => {
                    setValue(v["augmentation"]["value"]);
                  }}
                  initialValue={item["augmentation"]}
                />
              );
            }
            case "numberSingleInterval": {
              return (
                <OptionNumberSingleIntervalNew
                  getInputValidity={(e) =>
                    dispatcher({ type: "SET_errorAtInputValue", payload: e })
                  }
                  constraints={widgetConstraints}
                  item={item}
                  type={"number"}
                  stateSetter={(v) => {
                    setValue(v["augmentation"]["value"]);
                  }}
                />
              );
            }
            case "numberSingleIntervalPercentage": {
              return (
                <OptionNumberSingleIntervalNew
                  getInputValidity={(e) =>
                    dispatcher({ type: "SET_errorAtInputValue", payload: e })
                  }
                  constraints={widgetConstraints}
                  item={item}
                  type={"percentage"}
                  stateSetter={(v) => {
                    setValue(v["augmentation"]["value"]);
                  }}
                />
              );
            }
            case "rangeMarketCap": {
              return (
                <MarketCapFilter
                  updateValue={(v) => {
                    //final value has to be an array of objs,
                    //then we pass the value into an array
                    setValue([v]);
                  }}
                  hasRangeSlider={false}
                  initValue={item["augmentation"]["value"][0]}
                />
              );
            }

            // case "rangeQuantile": {
            //   return <></>;
            // }

            case "rangeVolatility": {
              return (
                <RangeVolatility
                  handleChange={(value) => {
                    setValue([
                      {
                        ge: value.left ? value.left / 100 : null,
                        le: value.right ? value.right / 100 : null,
                      },
                    ]);
                  }}
                  initValue={rangeVolatilityInitValue}
                />
              );
            }

            case "rate": {
              return (
                <RatingCheckbox
                  stateGetter={(v) => {
                    setValue(v);
                  }}
                  initState={value}
                />
              );
            }

            default:
              return <></>;
          }
          // break;
        }
        default: {
          return <div>---</div>;
        }
      }
    }, [
      dispatcher,
      item,
      itemType,
      itemsConstraints,
      rangeVolatilityInitValue,
      setValue,
      subject,
      value,
      widgetConstraints,
      widgetType,
    ]);
    return componentToReturn;
  }
);
