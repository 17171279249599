type TCRBlockProps = {
    children: any;
    holdings: number;
};

export function TCRBlock({ children, holdings }: TCRBlockProps) {
    return (
        <div className="blockWrapper tcr-block">
            <h3>TCR</h3>
            <div className="tcr-data">{children}</div>
            <div className="key-data">
                <p>{`Holdings: ${holdings}`}</p>
            </div>
        </div>
    );
}
