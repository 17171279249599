import { Box } from "@mui/material";
import { useMemo } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";
import { UI_CONSTRAINTS_TYPE } from "../utils";

type BacktestingProps = {
  value: any;
  UI_CONSTRAINTS: UI_CONSTRAINTS_TYPE;
};

export default function Backtesting({
  value,
  UI_CONSTRAINTS,
}: BacktestingProps) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const period = useMemo(
    () => strategyFormat.period(value.period),
    [strategyFormat, value.period]
  );

  const inceptionValue = useMemo(
    () => strategyFormat.inceptionDate(value.inceptionValue),
    [strategyFormat, value.inceptionValue]
  );

  const inceptionDate = useMemo(() => {
    if (value.inceptionDate) {
      return strategyFormat.inceptionDate(value.inceptionDate);
    }
    return null;
  }, [strategyFormat, value.inceptionDate]);

  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0, width: "100%" }}>
        <tbody>
          <tr>
            <td dangerouslySetInnerHTML={{ __html: period.label }}></td>
            <td dangerouslySetInnerHTML={{ __html: period.value }}></td>
          </tr>
          {UI_CONSTRAINTS.backtesting.inceptionValue.enabled === true && inceptionDate != null &&(
            <tr>
              <td>Inception date</td>
              <td dangerouslySetInnerHTML={{ __html: inceptionDate }}></td>
            </tr>
          )}

          {UI_CONSTRAINTS.backtesting.inceptionValue.enabled === true && (
            <tr>
              <td>Inception value</td>
              <td dangerouslySetInnerHTML={{ __html: inceptionValue }}></td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
}
