import { Card, CardContent, Grid } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { decodePeerId } from "../../../../../../../../../api/utils";
import { useTaxonomyByType } from "../../../../../../../../../hooks/useTaxonomyByType";
import { ActionsETFMarketsControls } from "../../../../AnalysisMarketsETF";
import InstrumentsTableForAnalytics from "../InstrumentsTableForAnalytics/InstrumentsTableForAnalytics";
import RatingHistoryChart from "./RatingHistoryChart";

type RatingHistoryViewProps = {
  setParams: (params, id?: string) => void;
  data: any;
  segment: "WWW" | "1 Industry" | "3 Sector" | "Area" | "Region" | "Country";
  tableCellNameFormatter: Function;
  dispatch: (action: ActionsETFMarketsControls) => void;
  type: "Stock" | "ETF";
  id: string;
};

const colors = {
  border: "#d3d3d3",
  grid: "#d3d3d3",
  instrumentDefault: "#2a7092",
  instrumentRateA: "#008000",
  instrumentRateB: "#8bbc00",
  instrumentRateC: "#f48400",
  instrumentRateD: "#f00000",
  instrumentRateU: "#d3d3d3",
  line: "#d3d3d3",
  trendrating: "#2a7092",
};

/**
 *
 * @param {date} date
 * @param {number} years
 * @returns the date provided subtracted from the selected number of years
 */
const subtractYears = (date, years) => {
  // Clone date avoid to modify original object
  const cloneDate = new Date(date);
  cloneDate.setFullYear(cloneDate.getFullYear() - years);
  return cloneDate;
};

/**
 *
 * @param {array} history
 * @param {number} years
 * @returns the history slice beween today and the selected period
 */
const sliceHistory = (history, years) => {
  const historySlice: any = [];
  if (history.length === 0) {
    return historySlice;
  }

  const today = new Date(history[history.length - 1].t);
  const endInterval = subtractYears(today, years);
  const start = today.getTime();
  const end = endInterval.getTime();

  let itemDate: any = null;
  for (let i = 0, length = history.length; i < length; i++) {
    itemDate = history[i].t;
    if (itemDate <= start && itemDate >= end) {
      historySlice.push(history[i]);
    }
  }

  return historySlice;
};

const mergeStateToGetKey = (isPerc: boolean, areSingleMetrics: boolean) => {
  const buildMetricsMap = {
    numberFormat: {
      0: "_NUMBER",
      1: "_PERCENTAGE",
    },
    clusterMetric: {
      0: "AB_CD",
      1: "A_B_C_D",
    },
  };

  const isPercToBinary = isPerc ? 1 : 0;
  const areSingleMetricsToBinary = areSingleMetrics ? 1 : 0;
  const numberFormat = buildMetricsMap["numberFormat"][isPercToBinary];
  const metric = buildMetricsMap["clusterMetric"][areSingleMetricsToBinary];
  const combinedStateConstraints = metric + numberFormat;

  return combinedStateConstraints;
};

/**
 *
 * @param {boolean} isPerc
 * @param {boolean} areSingleMetrics
 * @param {number} years
 *
 * Get as input state constraints and use them to build an object with the params that
 * will be used to fetch data
 */
const turnStateInParams = (
  isPerc: boolean,
  areSingleMetrics: boolean,
  years: number
) => {
  const params: {
    metrics: string[];
    years: number;
  } = {
    metrics: [],
    years,
  };

  const combinedStateConstraints = mergeStateToGetKey(isPerc, areSingleMetrics);

  switch (combinedStateConstraints) {
    case "AB_CD_NUMBER": {
      params.metrics.push("abHistory");
      params.metrics.push("cdHistory");

      break;
    }
    case "A_B_C_D_NUMBER": {
      params.metrics.push("ratingHistory");

      break;
    }
    case "A_B_C_D_PERCENTAGE": {
      params.metrics.push("ratingHistoryPerc");

      break;
    }
    case "AB_CD_PERCENTAGE":
    default: {
      params.metrics.push("abHistoryPerc");
      params.metrics.push("cdHistoryPerc");

      break;
    }
  }

  return params;
};

/**
 *
 * @param {array} history
 * @param {string} property
 * @returns the serie to send to the chart
 */
const getSerieArea = (history, property) => {
  var tooltipOptions = {
    disabled: {
      enabled: false,
    },
    number: {
      valueDecimals: 0,
    },
    percentage: {
      valueDecimals: 2,
      valueSuffix: "%",
    },
  };

  var configuration = {
    A_n: {
      color: colors.instrumentRateA,
      enableMouseTracking: true,
      name: "A",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 0,
    },
    B_n: {
      color: colors.instrumentRateB,
      enableMouseTracking: true,
      name: "B",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 0,
    },
    C_n: {
      color: colors.instrumentRateC,
      enableMouseTracking: true,
      name: "C",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 0,
    },
    D_n: {
      color: colors.instrumentRateD,
      enableMouseTracking: true,
      name: "D",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 0,
    },
    N_n: {
      color: colors.instrumentRateU,
      enableMouseTracking: true,
      name: "NA",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: false,
      y: 0,
    },
    AB_n: {
      color: colors.instrumentRateA,
      enableMouseTracking: true,
      name: "AB",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 1,
    },
    CD_n: {
      color: colors.instrumentRateD,
      enableMouseTracking: true,
      name: "CD",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 1,
    },
    A_p: {
      color: colors.instrumentRateA,
      enableMouseTracking: true,
      name: "A",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 2,
    },
    B_p: {
      color: colors.instrumentRateB,
      enableMouseTracking: true,
      name: "B",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 2,
    },
    C_p: {
      color: colors.instrumentRateC,
      enableMouseTracking: true,
      name: "C",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 2,
    },
    D_p: {
      color: colors.instrumentRateD,
      enableMouseTracking: true,
      name: "D",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 2,
    },
    N_p: {
      color: colors.instrumentRateU,
      enableMouseTracking: true,
      name: "NA",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: false,
      y: 2,
    },
    AB_p: {
      color: colors.instrumentRateA,
      enableMouseTracking: true,
      name: "AB",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 3,
    },
    CD_p: {
      color: colors.instrumentRateD,
      enableMouseTracking: true,
      name: "CD",
      size: 1,
      tooltip: tooltipOptions.disabled,
      type: "area",
      visible: true,
      y: 3,
    },
  };

  var data: any = [];
  for (let i = 0, length = history.length; i < length; i++) {
    data.push([history[i].t, history[i][property]]);
  }

  var serie = {
    color: configuration[property].color,
    data: data,
    dataGrouping: { units: [["day", [1]]] },
    id: property,
    lineWidth: configuration[property].size,
    name: configuration[property].name,
    type: configuration[property].type,
    visible: configuration[property].visible,
    yAxis: configuration[property].y,
  };

  return serie;
};

/**
 *
 * @returns The y axis configuration of the chart
 */
const getYAxis = () => {
  return [
    {
      endOnTick: false,
      gridLineColor: colors["grid"],
      id: "history-a-b-c-d-number",
      labels: {
        enabled: true,
      },
      lineColor: colors["line"],
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      showLastLabel: true,
      startOnTick: false,
      title: { text: "rating", enabled: false },
      type: "linear",
      visible: true,
    },
    {
      endOnTick: false,
      gridLineColor: colors["grid"],
      id: "history-ab-cd-number",
      labels: {
        enabled: true,
      },
      lineColor: colors["line"],
      lineWidth: 1,
      opposite: false,
      showEmpty: false,
      showLastLabel: true,
      startOnTick: false,
      title: { text: "AB vs CD", enabled: false },
      type: "linear",
      visible: true,
    },
    {
      endOnTick: false,
      gridLineColor: colors["grid"],
      id: "history-a-b-c-d-percentage",
      labels: {
        enabled: true,
        formatter: function () {
          var adjustedPercentage = (this as any).value * 100;
          return adjustedPercentage + "%";
        },
      },
      lineColor: colors["line"],
      lineWidth: 1,
      min: 0,
      max: 1,
      opposite: false,
      showEmpty: false,
      showLastLabel: true,
      startOnTick: false,
      type: "linear",
      title: { text: "rating %", enabled: false },
      visible: true,
    },
    {
      endOnTick: false,
      gridLineColor: colors["grid"],
      id: "history-ab-cd-percentage",
      labels: {
        enabled: true,
        formatter: function () {
          var adjustedPercentage = (this as any).value * 100;
          return adjustedPercentage + "%";
        },
      },
      lineColor: colors["line"],
      lineWidth: 1,
      min: 0,
      max: 1,
      opposite: false,
      plotLines: [
        {
          value: 50,
          width: 1,
          color: colors["line"],
          dashStyle: "ShortDot",
          zIndex: 4,
        },
      ],
      showEmpty: false,
      showLastLabel: true,
      startOnTick: false,
      title: {
        text: "AB% vs CD%",
        enabled: false,
      },
      type: "linear",
      visible: true,
    },
    {
      endOnTick: false,
      id: "historyBenchmark",
      showEmpty: false,
      showLastLabel: true,
      startOnTick: false,
      type: "linear",
      visible: false,
    },
  ];
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const RatingHistoryView = forwardRef(
  (
    {
      setParams,
      data,
      segment,
      dispatch,
      tableCellNameFormatter,
      type,
      id,
    }: RatingHistoryViewProps,
    ref
  ) => {
    const [isPerc, setIsPerc] = useState(true);
    const [areSingleMetrics, setAreSingleMetrics] = useState(false);
    const [years, setYears] = useState(1);
    const [historySerie, setHistorySerie] = useState([]);
    const [target, setTarget] = useState(id);
    const { getTaxonomyById } = useTaxonomyByType(
      type === "ETF" ? "ETF" : "stock"
    );

    useImperativeHandle(ref, () => {
      return {
        getHistoryTabParams: () =>
          turnStateInParams(isPerc, areSingleMetrics, years),
      };
    });

    useEffect(() => {
      setTarget(id);
    }, [id, segment]);

    const title = useMemo(() => {
      const peerInfo = decodePeerId(target);
      const marketField = type === "ETF" ? "etfgeo" : "country";
      const sectorField = type === "ETF" ? "etfclass" : "icb";

      const market = getTaxonomyById(peerInfo.where, marketField)?.name ?? "";
      const sector = getTaxonomyById(peerInfo.what, sectorField)?.name ?? "";

      let title = `${market}`;

      if (sector !== "Any") {
        title += ` - ${sector}`;
      }

      if (peerInfo.zDimension !== "microLarge") {
        const sizes = peerInfo.zDimension.split(/(?=[A-Z])/);
        const from = sizes[0]?.toLowerCase();
        const to = sizes[1]?.toLowerCase();

        title += ` - ${capitalize(from)}`;

        if (to != null) {
          title += ` - ${capitalize(to)} Cap`;
        } else {
          title += ` Cap`;
        }
      }

      return title;
    }, [getTaxonomyById, target, type]);

    const analyticType = useMemo(
      () => mergeStateToGetKey(isPerc, areSingleMetrics),
      [areSingleMetrics, isPerc]
    );

    const updateParamsOnTableClick = useCallback(
      (peerId) => {
        const params = turnStateInParams(isPerc, areSingleMetrics, years);
        setTarget(peerId);
        setParams(params, peerId);
      },
      [areSingleMetrics, isPerc, setParams, years]
    );

    const updateChartParams = (
      value,
      paramType: "perc" | "metric" | "year"
    ) => {
      let params: {
        metrics: string[];
        years: number;
      } | null = null;

      switch (paramType) {
        case "perc": {
          setIsPerc(value);
          params = turnStateInParams(value, areSingleMetrics, years);
          break;
        }

        case "metric": {
          setAreSingleMetrics(value);
          params = turnStateInParams(isPerc, value, years);
          break;
        }

        case "year": {
          setYears(value);
          params = turnStateInParams(isPerc, areSingleMetrics, value);
          break;
        }
      }

      if (target) {
        setParams(params, target);
      } else {
        setParams(params);
      }
    };

    useEffect(() => {
      if (data?.history ?? null) {
        const history = sliceHistory(data.history, years);

        const seriesArea: any = [];
        switch (analyticType) {
          case "AB_CD_NUMBER": {
            seriesArea.push(getSerieArea(history, "CD_n"));
            seriesArea.push(getSerieArea(history, "AB_n"));

            break;
          }
          case "A_B_C_D_NUMBER": {
            seriesArea.push(getSerieArea(history, "D_n"));
            seriesArea.push(getSerieArea(history, "C_n"));
            seriesArea.push(getSerieArea(history, "B_n"));
            seriesArea.push(getSerieArea(history, "A_n"));

            break;
          }
          case "A_B_C_D_PERCENTAGE": {
            seriesArea.push(getSerieArea(history, "D_p"));
            seriesArea.push(getSerieArea(history, "C_p"));
            seriesArea.push(getSerieArea(history, "B_p"));
            seriesArea.push(getSerieArea(history, "A_p"));

            break;
          }
          case "AB_CD_PERCENTAGE":
          default: {
            seriesArea.push(getSerieArea(history, "CD_p"));
            seriesArea.push(getSerieArea(history, "AB_p"));

            break;
          }
        }

        setHistorySerie(seriesArea);
      }
    }, [analyticType, areSingleMetrics, data, isPerc, years]);

    return (
      <Grid
        container
        mt={2}
        display={"flex"}
        flex={1}
        boxShadow={3}
        p={1}
        borderRadius={2}
      >
        <Grid container item md={12} spacing={2} flex={1} display={"flex"}>
          <Grid item md={3} flex={1} display={"flex"}>
            {/* Instruments Table */}
            {data?.children?.data?.length ? (
              <InstrumentsTableForAnalytics
                nameFormatter={tableCellNameFormatter}
                segment={segment}
                data={data}
                dispatch={dispatch}
                type={type}
                paramsUpdate={updateParamsOnTableClick}
              />
            ) : (
              <Card sx={{ display: "flex", flex: 1 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <strong>No {type} was found with those filters</strong>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item md={9} flex={1} display={"flex"}>
            {/* Rating history chart */}
            <RatingHistoryChart
              setIsPerc={(value) => updateChartParams(value, "perc")}
              setAreSingleMetrics={(value) =>
                updateChartParams(value, "metric")
              }
              setYears={(value) => updateChartParams(value, "year")}
              years={years}
              areSingleMetrics={areSingleMetrics}
              historySerie={historySerie ?? null}
              yAxisConfiguration={getYAxis()}
              colors={colors}
              history={data?.history ?? null}
              analyticType={analyticType}
              title={title}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
