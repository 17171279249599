type SelectionState = {
  rating: string;
  marketCap: {
    value: { ">="?: any; "<="?: any };
  };
  volatility: {
    value: {
      ">="?: any;
      "<="?: any;
    };
  };
  minLiquidity: number | null;
  performanceSinceRatedAbove: number | null;
  retracementAbove: number | null;
};

export const selectionState: SelectionState = {
  rating: "A",
  marketCap: {
    value: { ">=": null, "<=": null },
  },
  volatility: {
    value: { ">=": null, "<=": null },
  },
  minLiquidity: null,
  performanceSinceRatedAbove: null,
  retracementAbove: null,
};

type Action =
  | {
      type: "SET_RATING";
      payload: string;
      event: boolean;
    }
  | {
      type: "SET_MARKET_CAP";
      payload: { left: number | null; right: number | null };
    }
  | {
      type: "SET_VOLATILITY";
      payload: { left: number | null; right: number | null };
    }
  | { type: "SET_MIN_LIQUIDITY"; payload: string | number | null }
  | { type: "SET_PERFORMANCE_SINCE_RATED_ABOVE"; payload: number | null }
  | { type: "SET_RETRACEMENT_ABOVE"; payload: number | null }
  | { type: "SYNC_SELECTION_WITH_STATE"; payload: SelectionState };

export const selectionReducer = (draft: SelectionState, action: Action) => {
  switch (action.type) {
    case "SYNC_SELECTION_WITH_STATE":
      return action.payload;
    case "SET_RATING":
      if (action.event) {
        draft.rating = action.payload;
      }
      break;
    case "SET_MARKET_CAP":
      const leftMKT =
        action.payload.left != null ? action.payload.left / 1000000 : null;
      const rightMKT =
        action.payload.right != null ? action.payload.right / 1000000 : null;

      draft.marketCap.value[">="] = leftMKT;
      draft.marketCap.value["<="] = rightMKT;

      break;
    case "SET_VOLATILITY":
      const leftVolatility =
        action.payload.left != null ? action.payload.left / 100 : null;
      const rightVolatility =
        action.payload.right != null ? action.payload.right / 100 : null;

      draft.volatility.value = {
        ">=": leftVolatility,
        "<=": rightVolatility,
      };

      break;
    case "SET_MIN_LIQUIDITY":
      if (action.payload != null) {
        let minLiquidity = parseInt(action.payload as string, 10);
        draft.minLiquidity = isNaN(minLiquidity) ? null : minLiquidity * 1000;
      } else {
        draft.minLiquidity = null;
      }
      break;
    case "SET_PERFORMANCE_SINCE_RATED_ABOVE":
      if (action.payload != null) {
        draft.performanceSinceRatedAbove = !isNaN(action.payload!)
          ? /*action.payload! / 100*/
            parseFloat(
              parseFloat((action.payload! / 100).toString()).toFixed(5)
            )
          : null;
      } else {
        draft.performanceSinceRatedAbove = null;
      }
      break;
    case "SET_RETRACEMENT_ABOVE":
      if (action.payload != null) {
        draft.retracementAbove = !isNaN(action.payload!)
          ? /*action.payload! / -100*/ parseFloat(
              parseFloat((action.payload! / -100).toString()).toFixed(5)
            )
          : null;
      } else {
        draft.retracementAbove = null;
      }
      break;
    default:
      return draft;
  }
};
