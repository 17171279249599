import { format } from "date-fns";
import { deepClone } from "../../../../../../../deepClone";

export type Strategy = {
  allocation: {
    weightInCash: number;
    weightCappingPeer: null | {
      peerLevel: string;
      weightCappedMin: null | number;
      weightCappedMax: null | number;
    };
    weightCappingSecurity: null | {
      weightCappedMax: null | number;
      weightCappedMin: null | number;
    };
  };
  backtesting: {
    inceptionDate: null | string;
    inceptionValue: null | number;
    period: null | {
      type: string;
      value: number;
    };
  };
  strategy: {
    benchmark: null | string;
    currency: string;
    holdings: number;
    performance: string;
    rebalance: string;
  };
  hedging: null | {
    constraints: {
      hedgingStrategy: string;
      value: [
        {
          function: string;
          functionParams: null;
          property: string;
          operator: string;
          operatorParams: {
            value: any;
          };
        }
      ];
    };
    instrument: string;
    leverage: number;
  };
  ranking:
    | null
    | {
        function: string;
        functionParams: null;
        operator: string;
        operatorParams: {
          value: any;
        };
        property: string;
      }[];
  selection:
    | null
    | {
        function: string;
        functionParams: null;
        property: string;
        operator: string;
        operatorParams: {
          value: any;
        };
      }[];

  tracking: any;
  universe: {
    screening: null | {
      eligibility: {
        cardinality: number;
        isEnabled: boolean;
        sortBy: string;
      };
      instrumentType: string;
      what: any[];
      whereSource: {
        domestic: boolean;
        foreign: boolean;
        market: any[];
        stockClassification: any[];
      };
      whereTarget: {
        domestic: boolean;
        foreign: boolean;
        market: any[];
        stockClassification: any[];
      };
    };
    selection: any;
    whiteList: null | { id: number; type: string };
  };
  weighting: {
    rotation?: null | { factor: string; rotate: string };
    smartBeta?:
      | null
      | [
          {
            operator: {
              A: number;
              B: number;
              C: number;
              D: number;
            };
            property: string;
          }
        ];
    weightingSchema: null | string;
    weightingSchemaExistingPositions: null | string;
  };
  more?: any;
};

type Action =
  | {
      type:
        | "MORE_SELECTION_RULES"
        | "MORE_TRACKING"
        | "MORE_ROTATION_RULES"
        | "MORE_HOLDING_RULES"
        | "MORE_SMART_BETA_RULES";
      payload: boolean;
    }
  | { type: "SELECT_BASKET"; payload: { id: number; type: string } }
  | {
      type: "LOAD_STRATEGY";
      payload: Strategy;
    }
  | { type: "SELECT_MARKET"; payload: any }
  | { type: "SELECT_SECTOR"; payload: any }
  | { type: "SET_ELIGIBILITY"; payload: number }
  | { type: "SET_UNIVERSE_SORTING"; payload: string }
  | { type: "SET_ELIGIBILITY_ACTIVATION"; payload: boolean }
  | { type: "SET_RATING_A"; payload: boolean }
  | { type: "SET_RATING_AB"; payload: boolean }
  | { type: "SET_MARKET_CAP"; payload: { left: number; right: number } }
  | { type: "SET_VOLATILITY"; payload: { left: number; right: number } }
  | { type: "SET_MIN_LIQUIDITY"; payload: number }
  | { type: "SET_PERFORMANCE_SINCE_RATED_ABOVE"; payload: any }
  | { type: "SET_RETRACEMENT"; payload: any }
  | { type: "SET_SECURITY_TO_HOLD"; payload: number }
  | { type: "SET_INVESTMENT_FLEX" }
  | { type: "SET_INVESTMENT_FULL" }
  | {
      type: "SET_ENABLE_WEIGHT_CAPPING";
      payload: boolean;
      weightCappedMax: number;
    }
  | { type: "SET_WEIGHT_CAPPING"; payload: number }
  | { type: "SET_REBALANCE_FREQUENCY"; payload: any; event: boolean }
  | { type: "SET_PRIORITY"; payload: any }
  | { type: "SET_SELECTION"; payload: any }
  | { type: "SET_RANKING"; payload: any }
  | { type: "SET_EXISTING_POSITIONS"; payload: boolean; value: string }
  | { type: "SET_WEIGHT_IN_CASH"; payload: number }
  | { type: "SET_BENCHMARK_SEARCH"; payload: string }
  | { type: "SET_HISTORY"; payload: Strategy["backtesting"]["period"] }
  | { type: "SET_PERFORMACE"; payload: string }
  | { type: "SET_HEADGING"; payload: any }
  | { type: "SET_CURRENCY"; payload: string }
  | { type: "SET_INCEPTION_DATE"; payload: any }
  | { type: "SET_INCEPTION_VALUE"; payload: string }
  | { type: "SET_SMART_BETA_CAPPING_CHECKBOX"; payload: any }
  | { type: "SET_SMART_BETA_REBALANCE_FREQUENCY"; payload: any }
  | {
      type: "SET_SMART_BETA_RULES";
      payload: {
        weightingSchema: string;
        A: number;
        B: number;
      };
    }
  | { type: "SET_ROTATION"; payload: string }
  | { type: "SET_ROTATION_FACTOR"; payload: string }
  | {
      type: "SET_SUB_PORTFOLIO_CAPPING_PEER";
      payload: {
        peerLevel: string;
        weightCappedMin: null;
        weightCappedMax: number;
      } | null;
    }
  | {
      type: "SET_WEIGHT_CAPPING_SECURITY";
      payload: {
        weightCappedMax: number;
        weightCappedMin: number;
      } | null;
    }
  | {
      type: "SET_WEIGHTING_SCHEMA";
      payload: "WEIGHT_EQUAL" | "WEIGHT_MARKET_CAP";
    }
  | { type: "SET_SMART_BETA_RULES_MACRO"; payload: { A: number; B: number } }
  | {
      type: "RESET_MACRO_SMART_BETA_SLIDER";
      payload: { A: number; B: number; C: number; D: number };
    };

// const _getDate = (type?) => {
//   var date = new Date();
//   var today = date.getUTCDay();
//   var delta = 1;

//   switch (type) {
//     case "TRACKING":
//     case "INCEPTION":
//     default: {
//       switch (today) {
//         case 0: {
//           delta = 2;
//           break;
//         } // Sunday
//         case 1: {
//           delta = 3;
//           break;
//         } // Monday
//         default: {
//           delta = 1;
//         } // Other days
//       }
//       date.setDate(date.getUTCDate() - delta);
//     }
//   }

//   return date;
// };

// const date = _getDate();

export const smartBetaDefaultState: Strategy = {
  allocation: {
    weightInCash: 0,
    weightCappingPeer: null,
    weightCappingSecurity: null,
  },
  backtesting: {
    inceptionDate: "2021-11-01",
    inceptionValue: 100,
    period: {
      type: "YEAR",
      value: 8,
    },
  },
  strategy: {
    benchmark: null,
    currency: "local",
    holdings: 9999,
    performance: "NONE",
    rebalance: "20_DAYS",
  },
  hedging: null,
  ranking: [],
  selection: [
    {
      function: "value",
      functionParams: null,
      property: "rc",
      operator: "equalToRate",
      operatorParams: {
        value: {
          A: true,
          B: true,
          C: true,
          D: true,
        },
      },
    },
  ],
  tracking: null,
  universe: {
    screening: {
      eligibility: {
        cardinality: 200,
        isEnabled: false,
        sortBy: "desc",
      },
      instrumentType: "stock",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
    },
    selection: null,
    whiteList: null,
  },
  weighting: {
    rotation: null,
    smartBeta: [
      {
        operator: {
          A: 1,
          B: 1,
          C: 1,
          D: 1,
        },
        property: "rc",
      },
    ],
    weightingSchema: "WEIGHT_MARKET_CAP",
    weightingSchemaExistingPositions: "WEIGHT_EXISTING_POSITIONS_REBALANCE",
  },
  more: {
    holdingRules: false,
    smartBetaRules: false,
    tracking: false,
  },
};

export const initialEditorState: Strategy = {
  allocation: {
    weightInCash: 0,
    weightCappingPeer: null,
    weightCappingSecurity: null,
  },
  backtesting: {
    inceptionDate: /*format(date, "yyyy-MM-dd")*/ null,
    inceptionValue: 100,
    period: {
      type: "YEAR",
      value: 8,
    },
  },
  strategy: {
    benchmark: null,
    currency: "local",
    holdings: 30,
    performance: "NONE",
    rebalance: "20_DAYS",
  },
  hedging: null,
  ranking: [
    {
      function: "value",
      functionParams: null,
      operator: "sortByExists",
      operatorParams: {
        value: "desc",
      },
      property: "exists",
    },
    {
      function: "value",
      functionParams: null,
      operator: "sortByPerformance",
      operatorParams: {
        value: "desc",
      },
      property: "pr",
    },
    {
      function: "value",
      functionParams: null,
      operator: "sortByString",
      operatorParams: {
        value: "asc",
      },
      property: "ticker",
    },
  ],
  selection: [
    {
      function: "value",
      functionParams: null,
      property: "rc",
      operator: "equalToRate",
      operatorParams: {
        value: {
          A: true,
          B: false,
          C: false,
          D: false,
        },
      },
    },
  ],
  tracking: null,
  universe: {
    screening: {
      eligibility: {
        cardinality: 200,
        isEnabled: false,
        sortBy: "desc",
      },
      instrumentType: "stock",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
    },
    selection: null,
    whiteList: null,
  },
  weighting: {
    rotation: null,
    smartBeta: null,
    weightingSchema: "WEIGHT_EQUAL",
    weightingSchemaExistingPositions: "WEIGHT_EXISTING_POSITIONS_KEEP",
  },
  more: {
    universe: false,
    selectionRules: false,
    holdingRules: false,
    tracking: false,
  },
};

export const macroRotationInitialState: Strategy = {
  allocation: {
    weightInCash: 1,
    weightCappingPeer: null,
    weightCappingSecurity: null,
  },
  backtesting: {
    inceptionDate: "2021-11-05",
    inceptionValue: 100,
    period: {
      type: "YEAR",
      value: 8,
    },
  },
  strategy: {
    benchmark: null,
    currency: "local",
    holdings: 30,
    performance: "NONE",
    rebalance: "20_DAYS",
  },
  hedging: null,
  ranking: [
    {
      function: "value",
      functionParams: null,
      operator: "sortByExists",
      operatorParams: {
        value: "desc",
      },
      property: "exists",
    },
    {
      function: "value",
      functionParams: null,
      operator: "sortByPerformance",
      operatorParams: {
        value: "desc",
      },
      property: "pr",
    },
    {
      function: "value",
      functionParams: null,
      operator: "sortByString",
      operatorParams: {
        value: "asc",
      },
      property: "ticker",
    },
  ],
  selection: [
    {
      function: "value",
      functionParams: null,
      property: "rc",
      operator: "equalToRate",
      operatorParams: {
        value: {
          A: true,
          B: false,
          C: false,
          D: false,
        },
      },
    },
  ],
  tracking: null,
  universe: {
    screening: {
      eligibility: {
        cardinality: 200,
        isEnabled: false,
        sortBy: "desc",
      },
      instrumentType: "stock",
      what: [],
      whereSource: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
      whereTarget: {
        domestic: false,
        foreign: false,
        market: [],
        stockClassification: [],
      },
    },
    selection: null,
    whiteList: null,
  },
  weighting: {
    rotation: {
      factor: "FACTOR_MOMENTUM",
      rotate: "Country",
    },
    smartBeta: [
      {
        operator: {
          A: 1,
          B: 1,
          C: 1,
          D: 1,
        },
        property: "rc",
      },
    ],
    weightingSchema: "WEIGHT_EQUAL",
    weightingSchemaExistingPositions: "WEIGHT_EXISTING_POSITIONS_REBALANCE",
  },
  more: {
    universe: false,
    rotationRules: false,
    selectionRules: false,
    holdingRules: false,
    smartBetaRules: false,
    tracking: false,
  },
};

/**
 * The action payload possibly can be null or undefined because the strategy object
 * is loading or is missing, for example when a user wants to build an empty strategy.
 * For this reason, at this point, is important to check if the strategy object is not
 * null or undefined and if is null the default state will be set, otherwise will be set
 * the strategy object. But before this step we need to check if the strategy is referred
 * to basket portfolio or peer group to avoid errors during the switch from a tab to another.
 *
 * These errors could be caused by a misconfiguration of the state resolved through this check
 *
 */
export const universeTabConfigHelper = (defaultState, actionPayload) => {
  if (actionPayload != null) {
    let clonedStrategy = deepClone(actionPayload);
    if (clonedStrategy.more == null) {
      clonedStrategy["more"] = defaultState.more;
    }
    if (
      clonedStrategy.universe.screening == null &&
      defaultState.more != null
    ) {
      clonedStrategy.universe.screening = defaultState.universe.screening;
    }

    if (clonedStrategy.universe.whiteList == null) {
      clonedStrategy.universe.whiteList = defaultState.universe.whiteList;
    }

    return clonedStrategy;
  } else {
    return deepClone(defaultState);
  }
};

export const editorReducer = (draft: Strategy, action: Action) => {
  if (draft == null && action.type !== "LOAD_STRATEGY") {
    console.warn("Draft is undefined");
    return draft;
  }

  switch (action.type) {
    case "MORE_SELECTION_RULES":
      draft.more.selectionRules = action.payload;
      break;
    case "MORE_TRACKING":
      draft.more.tracking = action.payload;
      break;
    case "MORE_ROTATION_RULES":
      draft.more.rotationRules = action.payload;
      break;
    case "MORE_HOLDING_RULES":
      draft.more.holdingRules = action.payload;
      break;
    case "MORE_SMART_BETA_RULES":
      draft.more.smartBetaRules = action.payload;
      break;
    case "LOAD_STRATEGY":
      if (JSON.stringify(draft) !== JSON.stringify(action.payload)) {
        return action.payload;
      } else return draft;

    case "SET_SELECTION":
      /**
       * Check if draft and payload are eaquals because useImmer can't establish
       * if the elements are equals if they're of type Array.
       * The draft has to be updated only if it has to change, this check avoid an infinite loop
       *
       */
      const draftSelectionStr = JSON.stringify(draft.selection);
      const payloadStr = JSON.stringify(action.payload);
      if (draftSelectionStr !== payloadStr) {
        draft.selection = action.payload;
      }
      break;
    case "SET_RANKING":
      /**
       * Check if draft and payload are eaquals because useImmer can't establish
       * if the elements are equals if they're of type Array.
       * The draft has to be updated only if it has to change, this check avoid an infinite loop
       *
       */
      const draftRankingStr = JSON.stringify(draft.ranking);
      const rankingUpdatedStr = JSON.stringify(action.payload);

      if (draftRankingStr !== rankingUpdatedStr) {
        draft.ranking = action.payload;
      }
      break;
    case "SELECT_BASKET":
      draft.universe.whiteList = action.payload;
      break;
    case "SELECT_MARKET":
      /**
       * Check if user has selected domestic or foreign by checking if exists an object with
       * dimension = "subtype".
       */
      const doesSubtypeExists = action.payload.find(
        (item) => item.value.dimension === "subtype"
      );

      if (doesSubtypeExists != null) {
        const subtypeValue = doesSubtypeExists.value.segments[0];

        if (subtypeValue === "Foreign Stock") {
          draft.universe.screening!.whereSource.foreign = true;
          draft.universe.screening!.whereSource.domestic = false;
        }

        if (subtypeValue === "Domestic Stock") {
          draft.universe.screening!.whereSource.domestic = true;
          draft.universe.screening!.whereSource.foreign = false;
        }
      } else {
        draft.universe.screening!.whereSource.domestic = false;
        draft.universe.screening!.whereSource.foreign = false;
      }

      //Looking for the country object

      const countryValue = action.payload.find(
        (item) => item.value.dimension === "country"
      );

      if (countryValue != null) {
        draft.universe.screening!.whereSource.market =
          countryValue.value.segments;
      } else {
        draft.universe.screening!.whereSource.market = [];
      }

      const stockClassification = action.payload.find(
        (item) => item.value.dimension === "stockclass"
      );

      if (stockClassification != null) {
        draft.universe.screening!.whereSource.stockClassification =
          stockClassification.value.segments;
      } else {
        draft.universe.screening!.whereSource.stockClassification = [];
      }
      break;
    case "SELECT_SECTOR":
      draft.universe.screening!.what = action.payload;
      break;
    case "SET_ELIGIBILITY":
      draft.universe.screening!.eligibility.cardinality = action.payload;
      break;
    case "SET_UNIVERSE_SORTING":
      draft.universe.screening!.eligibility.sortBy = action.payload;
      break;
    case "SET_ELIGIBILITY_ACTIVATION":
      if (draft.universe.screening!.eligibility) {
        draft.universe.screening!.eligibility.isEnabled = action.payload;
      }
      break;
    case "SET_SECURITY_TO_HOLD":
      draft.strategy.holdings = action.payload;
      break;
    case "SET_INVESTMENT_FLEX":
      draft.allocation.weightInCash = 0;
      break;
    case "SET_INVESTMENT_FULL":
      draft.allocation.weightInCash = 1;
      break;
    case "SET_ENABLE_WEIGHT_CAPPING":
      if (action.payload) {
        draft.allocation.weightCappingSecurity = {
          weightCappedMax: action.weightCappedMax,
          weightCappedMin: null,
        };
      } else {
        draft.allocation.weightCappingSecurity = null;
      }
      break;
    case "SET_WEIGHT_CAPPING":
      draft.allocation.weightCappingSecurity!.weightCappedMax = action.payload;
      break;
    case "SET_REBALANCE_FREQUENCY":
      if (action.event) {
        draft.strategy.rebalance = action.payload;
      }
      break;
    case "SET_EXISTING_POSITIONS":
      if (action.payload) {
        draft.weighting.weightingSchemaExistingPositions = action.value;
      }
      break;
    case "SET_WEIGHT_IN_CASH":
      draft.allocation.weightInCash = action.payload;
      break;
    case "SET_BENCHMARK_SEARCH":
      draft.strategy.benchmark = action.payload;
      break;
    case "SET_HISTORY":
      if (draft.backtesting.period != null) {
        draft.backtesting.period.type = action.payload!.type;
        draft.backtesting.period.value = action.payload!.value;
      } else {
        draft.backtesting.period = action.payload;
      }
      break;
    case "SET_PERFORMACE":
      draft.strategy.performance = action.payload;
      break;
    case "SET_HEADGING":
      draft.hedging = action.payload;
      break;
    case "SET_CURRENCY":
      draft.strategy.currency = action.payload.toUpperCase();
      break;
    case "SET_INCEPTION_DATE":
      draft.backtesting.inceptionDate = format(action.payload, "yyyy-MM-dd");
      break;
    case "SET_INCEPTION_VALUE":
      const value = parseFloat(action.payload);
      if (Number.isNaN(value)) {
        draft.backtesting.inceptionValue = 100;
      } else {
        draft.backtesting.inceptionValue = value;
      }
      break;
    case "SET_SMART_BETA_CAPPING_CHECKBOX":
      const draftCappingRulesStr = JSON.stringify(
        draft.allocation.weightCappingSecurity
      );
      const actionPayloadStr = JSON.stringify(action.payload);

      if (draftCappingRulesStr !== actionPayloadStr) {
        draft.allocation.weightCappingSecurity = action.payload;
      }
      break;
    case "SET_SMART_BETA_REBALANCE_FREQUENCY":
      draft.strategy.rebalance = action.payload;
      break;
    case "SET_SMART_BETA_RULES":
      draft.weighting.weightingSchema = action.payload.weightingSchema;
      draft.weighting.smartBeta![0].operator.A = action.payload.A;
      draft.weighting.smartBeta![0].operator.B = action.payload.B;
      break;
    case "SET_ROTATION":
      draft.weighting.rotation!.rotate = action.payload;
      break;
    case "SET_ROTATION_FACTOR":
      draft.weighting.rotation!.factor = action.payload;
      break;
    case "SET_SUB_PORTFOLIO_CAPPING_PEER":
      draft.allocation.weightCappingPeer = action.payload;
      break;
    case "SET_WEIGHT_CAPPING_SECURITY":
      draft.allocation.weightCappingSecurity = action.payload;
      break;
    case "SET_WEIGHTING_SCHEMA":
      draft.weighting.weightingSchema = action.payload;
      break;
    case "SET_SMART_BETA_RULES_MACRO":
      draft.weighting.smartBeta![0].operator.A = action.payload.A;
      draft.weighting.smartBeta![0].operator.B = action.payload.B;
      break;
    case "RESET_MACRO_SMART_BETA_SLIDER":
      draft.weighting.smartBeta![0].operator.A = action.payload.A;
      draft.weighting.smartBeta![0].operator.B = action.payload.B;
      draft.weighting.smartBeta![0].operator.C = action.payload.C;
      draft.weighting.smartBeta![0].operator.D = action.payload.D;
      break;
    default:
      return draft;
  }
};
