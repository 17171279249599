import { ReactNode } from "react";
import { ProductClass } from "../../../../types/Defaults";
import { HelpContentReleaseNotesPremium } from "./HelpContentReleaseNotesPremium";
import { HelpContentReleaseNotesStandard } from "./HelpContentReleaseNotesStandard";
import { HelpContentReleaseNotesSystematic } from "./HelpContentReleaseNotesSystematic";

type HelpContentReleaseNotesProps = {
  productClass: ProductClass;
  staticAssets: string;
};

type RenderViewProps = {
  productClass: ProductClass;
};

type ReleaseNoteItemProps = {
  children: ReactNode;
};

type ReleaseNoteSectionProps = {
  children?: ReactNode;
  title: string;
  type: string;
};

type ReleaseNoteProps = {
  children?: ReactNode;
  filename?: string;
  title: string;
};

<li>From today you can backtest strategies at total return level.</li>;

export const ReleaseNoteItem = ({ children }: ReleaseNoteItemProps) => {
  return <li>{children}</li>;
};

export const ReleaseNoteSection = ({
  children,
  type,
  title,
}: ReleaseNoteSectionProps) => {
  return (
    <>
      <p>
        <strong>{type}</strong>: {title}
      </p>
      {children ? <ul>{children}</ul> : <></>}
    </>
  );
};

export const ReleaseNote = ({
  children,
  filename,
  title,
}: ReleaseNoteProps) => {
  return (
    <div className="releaseNote">
      <h2 className="releaseNote-title">{title}</h2>
      <div className="releaseNote-content">{children}</div>
      {filename ? (
        <div className="releaseNote-readMore">
          <a
            className="howTo-link"
            href={`${filename}`}
            target="_blank"
            rel="noreferrer"
          >
            Read more
          </a>
        </div>
      ) : null}
    </div>
  );
};

export const HelpContentReleaseNotes = ({
  productClass,
  staticAssets,
}: HelpContentReleaseNotesProps) => {
  const assetsUrl = `${staticAssets}/release-notes`;

  const RenderView = ({ productClass }: RenderViewProps) => {
    switch (productClass) {
      case "standard":
        return <HelpContentReleaseNotesStandard assetsUrl={assetsUrl} />;
      case "premium":
        return <HelpContentReleaseNotesPremium assetsUrl={assetsUrl} />;
      case "advanced":
      case "systematic":
        return <HelpContentReleaseNotesSystematic assetsUrl={assetsUrl} />;
      default: {
        return <></>;
      }
    }
  };

  return (
    <div
      className="tPageHelpIndex-contentPage tHelpPage"
      data-id="release-notes"
    >
      <div className="help">
        <div className="help-layout">
          <div className="help-left">
            <RenderView productClass={productClass} />
          </div>
        </div>
      </div>
    </div>
  );
};
