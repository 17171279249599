import { Box } from "@mui/material";
import { useMemo } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { StrategyFormatter } from "../../../../../../../../trendrating/formatter/StrategyFormatter";

type AllocationProps = {
  value: any;
  isETF: boolean;
};

export default function Allocation({ value, isETF }: AllocationProps) {
  const environment = useEnvironment();
  const strategyFormat = new StrategyFormatter({
    environment: environment.setup,
  }).getFormatter();

  const cappingPeer = useMemo(() => {
    if (value?.weightCappingPeer != null) {
      return strategyFormat.cappingPeer(value?.weightCappingPeer, isETF);
    }
    return null;
  }, [isETF, strategyFormat, value?.weightCappingPeer]);

  const cappingSecurity = useMemo(() => {
    if (value?.weightCappingSecurity != null) {
      return strategyFormat.cappingSecurity(value?.weightCappingSecurity);
    }
    return null;
  }, [strategyFormat, value?.weightCappingSecurity]);

  const cashMin = useMemo(
    () => strategyFormat.cash(value?.weightInCashMin),
    [strategyFormat, value?.weightInCashMin]
  );

  const cashMax = useMemo(
    () => strategyFormat.cashMax(value?.weightInCashMax),
    [strategyFormat, value?.weightInCashMax]
  );

  return (
    <Box display={"flex"}>
      <table style={{ borderSpacing: 0, width: "100%" }}>
        <tbody>
          <tr>
            <td>Invested</td>
            <td
              dangerouslySetInnerHTML={{ __html: `${cashMin} ${cashMax}` }}
            ></td>
          </tr>

          {cappingSecurity && (
            <tr>
              <td>Constituents capping</td>
              <td dangerouslySetInnerHTML={{ __html: cappingSecurity }}></td>
            </tr>
          )}

          <tr>
            <td dangerouslySetInnerHTML={{ __html: cappingPeer?.label }}></td>
            <td dangerouslySetInnerHTML={{ __html: cappingPeer?.value }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
}
