import { useState, useEffect, useMemo } from "react";
import InputNumber from "../../../../InputNumber/InputNumber";

type OptionNumberOperatorProps = {
  item: any;
  setOuterState: Function;
  initialValue?: any;
  constraints: any;
  getInputValidity: Function;
  inputConstraints: any;
};
const getKey = (item): string => {
  switch (item["subject"]) {
    case "lessThan":
      return "lt";
    case "greaterThan":
      return "gt";
  }
  return "";
};

const getInitialValue = (input, item): number => {
  let key: string = getKey(item);
  const valueToReturn = input["value"]?.[0]?.[key]??0;
  return valueToReturn;
};

export function OptionNumberOperator({
  item,
  setOuterState,
  initialValue,
  constraints,
  getInputValidity,
  inputConstraints
}: OptionNumberOperatorProps) {
  const [val, setVal] = useState<number | null>(0);
  const initVal = useMemo(() => {
    //!TODO: capire perché quando cambio da gt a lt il valore iniziale rimane sempre lo stesso e non va a 0
    let n = getInitialValue(initialValue, item);
    return n;
  }, [initialValue, item]);

  useEffect(() => {
    const key: any = getKey(item);
    let updatedState = item;
    updatedState["augmentation"]["value"] = [{ [key]: val }];
    if (initVal !== updatedState["augmentation"]["value"][0][key]) {
      setOuterState(updatedState);
    }
  }, [val, item, setOuterState, initVal]);

  const operatorSign = useMemo(() => {
    switch (item["subject"]) {
      case "greaterThan":
        return (
          <p style={{ fontSize: "1rem" }}>
            <strong>{">"}</strong>
          </p>
        );
      case "lessThan":
        return (
          <p style={{ fontSize: "1rem" }}>
            <strong>{"<"}</strong>
          </p>
        );
    }
  }, [item]);

  // If you want to set constraints use item["widget"]["constraints"]

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {operatorSign}
      <InputNumber
        getIsValid={(e) => getInputValidity(e)}
        constraints={constraints}
        setOuterState={setVal}
        isPercentage={false}
        isFloating={inputConstraints?.decimals !== undefined ? true : false}
        initVal={initVal}
        decimals={inputConstraints?.decimals ?? undefined}/>
    </div>
  );
}
