import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { getChildrenAtLevel } from "../../../../../../api/compute/Taxon";
import { useEnvironment } from "../../../../../../hooks/useEnvironment";

type Props = {
  focusOn: "Region" | "1 Industry" | "3 Sector";
  focusWhat: {
    label: string;
    type: string;
    value: string;
  };
  marketType: "Area" | "Region" | "Country" | "World";
  hasIndustries: boolean;
  onSetFocusOn: Function;
  onSetHasIndustries: Function;
  onSetFocusWhat: Function;
};

export default function Focus({
  focusOn,
  onSetFocusOn,
  hasIndustries,
  onSetHasIndustries,
  marketType,
  onSetFocusWhat,
  focusWhat,
}: Props) {
  const environment = useEnvironment();
  const taxonomies = useMemo(() => {
    let icbTX = environment.get("rawTaxonomies");
    let icbField = environment.get("taxonomyFields");
    icbField = icbField["security"]["icb"];
    return icbTX[icbField];
  }, [environment]);
  const root = useMemo(() => {
    let root: any = null;
    for (let key in taxonomies) {
      if (taxonomies[key].parent == null) {
        root = taxonomies[key].id;
        break;
      }
    }
    return root;
  }, [taxonomies]);
  const getChildrenByLevel = useCallback(
    (level: "1 Industry" | "3 Sector") => {
      const nodes = getChildrenAtLevel(root, level, taxonomies);
      return nodes;
    },
    [root, taxonomies]
  );
  const firstRadio = useMemo(() => {
    switch (marketType) {
      case "World":
        return (
          <FormControlLabel
            value="Area"
            control={<Radio />}
            label={
              <Box>
                <Typography>Area</Typography>
              </Box>
            }
          />
        );
      case "Area":
        return (
          <FormControlLabel
            value="Region"
            control={<Radio />}
            label={
              <Box>
                <Typography>Region</Typography>
              </Box>
            }
          />
        );
      case "Region":
        return (
          <FormControlLabel
            value="Country"
            control={<Radio />}
            label={
              <Box>
                <Typography>Market</Typography>
              </Box>
            }
          />
        );
      default:
        return null;
    }
  }, [marketType]);

  const contentForSector = useMemo(
    () =>
      getChildrenByLevel("1 Industry").sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [getChildrenByLevel]
  );

  const contentForIndustry = useMemo(
    () =>
      getChildrenByLevel("3 Sector").sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [getChildrenByLevel]
  );

  const onSelectOption = useCallback(
    (value, type) => {
      if (value === "ICB") {
        onSetFocusWhat({
          label: "Any",
          type: "0 root",
          value: "ICB",
        });
        return;
      }
      onSetHasIndustries(false);
      let obj: any = null;
      switch (type) {
        case "1 Industry":
          obj = contentForSector.filter((item) => item.id === value)[0];
          if (obj) {
            onSetFocusWhat({
              label: obj.name,
              value: value,
              type: type,
            });
          }
          break;
        case "3 Sector":
          obj = contentForIndustry.filter((item) => item.id === value)[0];
          if (obj) {
            onSetFocusWhat({
              label: obj.name,
              value: value,
              type: type,
            });
          }
          break;
      }
    },
    [contentForIndustry, contentForSector, onSetFocusWhat, onSetHasIndustries]
  );

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent sx={{ p: 1, pb: "8px !important" }}>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography variant="tr_subtitle_blue">Focus</Typography>
          <RadioGroup
            onChange={(e) => {
              onSetFocusOn(e.target.value);
              switch (e.target.value) {
                case "Region":
                case "Area":
                case "Country":
                  onSetFocusWhat({
                    label: "Any",
                    type: "0 root",
                    value: "ICB",
                  });
                  onSetFocusOn(e.target.value);
                  onSetHasIndustries(false);
                  break;
                case "3 Sector":
                  onSetFocusOn(e.target.value);
                  onSetHasIndustries(false);
                  onSetFocusWhat({
                    value: contentForIndustry[0].id,
                    label: contentForIndustry[0].name,
                    type: "3 Sector",
                  });
                  break;
                default:
                  onSetFocusOn(e.target.value);
                  onSetFocusWhat({
                    label: "Any",
                    type: "0 root",
                    value: "ICB",
                  });
                  break;
              }
            }}
            value={focusOn}
            row
          >
            {firstRadio}
            <FormControlLabel
              value="1 Industry"
              control={<Radio />}
              label={
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Sector</Typography>
                  {focusOn === "1 Industry" && (
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <Select
                        value={focusWhat.value}
                        onChange={(e) =>
                          onSelectOption(e.target.value, "1 Industry")
                        }
                        size="small"
                      >
                        <MenuItem value={"ICB"}>
                          <Typography>Any</Typography>
                        </MenuItem>
                        {contentForSector.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Typography>{item.name}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      {focusWhat.value === "ICB" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) =>
                                onSetHasIndustries(e.target.checked)
                              }
                              checked={hasIndustries}
                            />
                          }
                          label={<Typography>Show Industries</Typography>}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              }
            />
            <FormControlLabel
              value="3 Sector"
              control={<Radio />}
              label={
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Industry</Typography>
                  {focusOn === "3 Sector" && (
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <Select
                        value={focusWhat.value}
                        onChange={(e) =>
                          onSelectOption(e.target.value, "3 Sector")
                        }
                        size="small"
                      >
                        <MenuItem value={"ICB"}>
                          <Typography>Any</Typography>
                        </MenuItem>
                        {contentForIndustry.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Typography>{item.name}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                </Box>
              }
            />
          </RadioGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
