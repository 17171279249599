import { useCallback } from "react";
import { ActionsETFMarketsControls } from "../../../../AnalysisMarketsETF";
import { SelectorETFMarkets } from "../../../FiltersBarMarketDetail/FiltersBarMarketDetail";

type TimeframeControlProps = {
  timeframe: 0 | 4 | 19;
  dispatch: (action: ActionsETFMarketsControls) => void;
};

const timeframeOptions = [
  { label: "Today", value: 0 },
  { label: "Last week", value: 4 },
  { label: "Last month", value: 19 },
];

export function TimeframeControl({
  timeframe,
  dispatch,
}: TimeframeControlProps) {
  const setTimeframe = useCallback(
    (value) => dispatch({ type: "SET_TIMEFRAME", payload: value }),
    [dispatch]
  );

  return (
    <SelectorETFMarkets
      options={timeframeOptions}
      selectOption={setTimeframe}
      selectedOption={timeframe}
      // buttonList={true}
    />
  );
}
