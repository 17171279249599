import { Mutex } from "../../../Utility/Mutex";
import { Analytics } from "../../../api/compute/Analytics/Analytics";
import { SystematicProducts } from "../../../api/compute/SystematicProducts";
import { Formatter } from "../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../types/Defaults";
import { StrategyComputedTabType } from "../pages/strategies/builder/editors/Advanced/Result/Result";
import {
  CombineEntitiesEngine,
  CombinedStrategiesStorage,
  LoadingCallbacks,
} from "./CombinedStrategiesStorage";

export class CombinedProductsStorage
  extends CombinedStrategiesStorage
  implements CombineEntitiesEngine
{
  smsApi: SystematicProducts;
  formatter: Formatter;
  mutex: Mutex;

  constructor(
    environment: AppEnvironment,
    loadingBehaviours: LoadingCallbacks
  ) {
    super(environment, loadingBehaviours);

    const appSetup = environment;
    this.smsApi = new SystematicProducts(appSetup);
    this.formatter = new Formatter(appSetup);
    this.mutex = new Mutex();
  }

  async getFinestGranularity() {
    console.log(this.entities);

    const ids: any = [];

    for (const en of this.entities) {
      ids.push(en.id);
    }

    let granularities = await this.smsApi.fetch({
      ids,
      properties: ["reviewGranularity"],
    });

    const GRANULARITY_ENCODER = {
      WEEKLY: 1,
      MONTHLY: 2,
      QUARTERLY: 3,
    };

    const GRANULARITY_RESOLVER = {
      1: "WEEKLY",
      2: "MONTHLY",
      3: "QUARTERLY",
    };

    granularities = granularities.map(
      (result) => GRANULARITY_ENCODER[result["reviewGranularity"]]
    );

    const granularityId = Math.min(...granularities);

    const granularity = GRANULARITY_RESOLVER[granularityId];

    return granularity;
  }

  public async prepareEntitiesForCombine() {
    const weightsMap = {};
    const systematicProducts: { type: "SMS"; entity: any }[] = [];

    for (const [i, productInfo] of this.entities.entries()) {
      if (productInfo.id) {
        weightsMap[i] = productInfo.weight;
      }

      try {
        const systematicProduct = await this.smsApi.getById(productInfo.id);

        if (systematicProduct) {
          systematicProducts.push({
            type: "SMS",
            entity: systematicProduct,
          });
        }
      } catch (error) {
        console.error(error);
        const errorDetails = {
          status: 500,
          errorDetails: { code: "UNIVERSE_EMPTY" },
        };
        throw errorDetails;
      }
    }

    const firstEntity: any = {
      type: "COMBINE_ENTITIES",
      entity: {
        entitiesToCombine: systematicProducts,
        combineParams: {
          currency: this.combineParams?.currency ?? "local",
          date: undefined,
          allocation: weightsMap,
        },
      },
      includeFromDay: undefined,
    };

    let benchmarkEntity: any = undefined;

    if (this.combineParams && this.combineParams.benchmark) {
      benchmarkEntity = await this.fromBenchmarkToEntitySMS(
        this.combineParams.benchmark
      );
    }

    this.analyticsCollector = await Analytics.initialize(
      this.environment,
      firstEntity,
      benchmarkEntity
    );
  }

  public async getAnalytics(tab: StrategyComputedTabType): Promise<any> {
    const response = await super.getAnalytics(tab);

    (this.loadingCallbacks as any).stopPreload();
    return response;
  }

  protected async fromBenchmarkToEntitySMS(benchmarkTag: string): Promise<any> {
    const params = this.combineParams;

    const granularity = await this.getFinestGranularity();

    if (benchmarkTag.startsWith(this.BLENDED_TAG)) {
      const explodedTag = benchmarkTag.split(":");
      const portfolioId = explodedTag[1];

      const list = await this.apiLists.get(parseInt(portfolioId));

      this.benchmarkInfo = { name: list?.name ?? "", symbol: benchmarkTag };

      const entity = {
        portfolio: list,
        params: {
          startDate: undefined,
          inceptionDay: undefined,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
        },
      };

      return { type: "LIST", entity };
    }

    try {
      const response = await this.apiInstruments.fetch({
        symbols: [benchmarkTag],
        type: "security",
        properties: [{ date: null, property: "name" }],
      });

      const instrumentName = response?.data?.[0]?.name ?? "";

      this.benchmarkInfo = { name: instrumentName, symbol: benchmarkTag };
    } catch (error) {
      console.log(error);
    }

    return {
      type: "INSTRUMENT",
      entity: {
        symbol: benchmarkTag,
        params: {
          startDate: undefined,
          inceptionDay: undefined,
          inceptionValue: 100,
          method: "none",
          spanGranularity: granularity,
          currency: params?.currency,
        },
      },
    };
  }
}
