import { Card, CardContent } from "@mui/material";
import { useMemo } from "react";
import { useFormatter } from "../../../../../hooks/useFormatter";

type ProductTcrOverviewProps = { tcrInfo: any };

export function ProductTcrOverview({ tcrInfo }: ProductTcrOverviewProps) {
  const formatter = useFormatter();
  const innerHTML = useMemo(
    () => formatter.tcr(tcrInfo, "HTML"),
    [formatter, tcrInfo]
  );

  return (
    <Card>
      <CardContent>
        <div className="wSystematicProductTcr">
          <p className="wSystematicProductTcr-content">
            <span
              className="wSystematicProductTcr-title"
              data-dojo-attach-point="nodeTitle"
            >
              TCR
            </span>
            <span
              className="wSystematicProductTcr-tcr"
              data-dojo-attach-point="nodeTcr"
              dangerouslySetInnerHTML={{ __html: innerHTML }}
            ></span>
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
