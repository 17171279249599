/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/account/Preferences
 * @summary Requests for account preferences
 *
 */

import { deepClone } from "../../deepClone";
import { StoredObjectType } from "../../types/Api";
import { _StoredObjects } from "../_StoredObjects";
import { defaultPreferences } from "./defaultPreferences";

export class Preferences extends _StoredObjects {
  NAME = "__GLOBAL_ACCOUNT_PREFERENCES__";
  VERSION = "2.0";

  storedObjectType = "PREFERENCE_GENERIC" as const;

  clone(target: any, source: any) {
    const clonedSource = deepClone(source);
    const clonedTarget = deepClone(target);
    for (const key in clonedSource) {
      clonedTarget[key] = clonedSource[key];
    }
    return clonedTarget;
  }

  /**
   * Decode server params in UI params
   */
  decode(object: any, type?: StoredObjectType) {
    let decoded = deepClone(object);

    decoded["name"] =
      type === "ALERTS_NEWS_FILTER"
        ? "__ALERTS_NEWS_ACCOUNT_FILTERS__"
        : this.NAME;
    decoded["version"] = this.VERSION;

    return decoded;
  }

  /**
   * Encode UI params in server params
   */
  encode(object: any, type: StoredObjectType | null) {
    var encoded = deepClone(object);
    var accountPreferences = this.environment.account.user?.preferences;
    var accountNewsFilters = this.environment.account.user?.alertsNewsFilters;

    if (type !== "ALERTS_NEWS_FILTER") {
      encoded["id"] = accountPreferences != null ? accountPreferences.id : null;
    } else {
      encoded["id"] = accountNewsFilters != null ? accountNewsFilters.id : null;
    }

    encoded["name"] =
      type === "ALERTS_NEWS_FILTER"
        ? "__ALERTS_NEWS_ACCOUNT_FILTERS__"
        : this.NAME;
    encoded["version"] = this.VERSION;

    if (encoded["preferences"] != null) {
      var preferences = encoded["preferences"];
      // #region ----------------------- migrate report 2017-09-18 - START
      if ("report" in preferences) {
        if (preferences["report"] == null) {
          encoded["preferences"]["report"] = {
            general: {
              disclaimer: null,
              logo: null,
              theme: null,
            },
          };
        } else if (!("general" in preferences["report"])) {
          encoded["preferences"]["report"] = {
            general: {
              disclaimer: null,
              logo: {
                base64: preferences["report"]["logo"]["base64"],
                url: null,
              },
              theme: null,
            },
          };
        } else {
          encoded["preferences"]["report"] = {
            general: this.clone(
              {
                disclaimer: null,
                logo: null,
                theme: null,
              },
              preferences["report"]["general"]
            ),
          };
        }
      } else {
        encoded["preferences"]["report"] = null;
      }
    }

    // #endregion ---------------------- migrate report 2017-09-18 - END

    return encoded;
  }

  async get(type?: StoredObjectType) {
    const response = await super.get(null, type);

    if (response.length === 0) {
      return this.decode({
        preferences: defaultPreferences,
      });
    }

    return this.decode(response[0], type);
  }

  /**
   * User's tracked strategies
   *
   * @returns {array|null}
   */
  getTrackedStrategies() {
    var account = this.environment.account;
    var preferences = account.user?.preferences?.preferences;

    if (preferences?.indexes && preferences.indexes.length > 0) {
      return preferences.indexes.map((item: string) => parseInt(item, 10));
    }

    return null;
  }

  /**
   * Saves user preferences
   *
   * @param {object} params - request parameters
   * @param {object} params.preferences - user peferences
   * @param {number} params.id - if specified it means that the template
   *       already exits and has been modified. Otherwise a new template
   *       will be crated
   * @param {string} params.name - name of the template
   *
   * @returns {Promise} a promise fulfilled with the
   *       handled data of the response
   */
  async save(object: any, objectType?: StoredObjectType | null) {
    const type = objectType ?? null;
    var encoded = this.encode(object, type);

    let response;
    if (encoded["id"] == null) {
      response = await this.create(encoded, type);
    } else {
      response = await this.update(encoded, type);
    }

    return this.decode(response);
  }

  /**
   *
   * @param {string} key
   * @param {any} value
   */
  async setPreferenceKey(key: string, value: any) {
    var account = this.environment.account;

    if (
      account == null ||
      account.user == null ||
      account.user.preferences == null
    ) {
      return null;
    }

    const preferencesToUpdate = deepClone(account.user.preferences);
    if (preferencesToUpdate.preferences == null) {
      return null;
    }
    preferencesToUpdate.preferences[key] = value;

    const preferencesUpdated = await this.save(preferencesToUpdate);

    account.user.preferences = preferencesUpdated;

    return preferencesUpdated;
  }

  getRecentlyVisitedSecurity = async () => {
    const response = await this.get();

    if (response) {
      return response?.preferences?.favorites?.data ?? [];
    }
  };

  addToRecentSecurityQueue = async (symbol: string) => {
    if (!symbol) {
      return;
    }

    const handleResponse = (response) => {
      if (response?.data?.favorites) {
        window.App.user.preferences.preferences.favorites =
          response.data.favorites;
      }
    };

    const symbols = [symbol];

    if (symbols.length === 0) {
      console.warn("Favorite: symbols not specified.", symbol);
      return;
    }

    const params = { symbols };
    const callback: any = (response) => {
      const data = {};
      for (const [key, value] of Object.entries(response)) {
        if (key === "preferences") {
          data["data"] = value;
        } else {
          data[key] = value;
        }
      }

      this._post(params, handleResponse, null, data);
    };

    const response = await this.get();
    callback(response);
  };

  _post(params, callback, callbackError, response) {
    var key = "favorites";

    var favorite: any;
    var favorites: any = {
      data: [],
      index: {},
    };
    var index;
    var security: any;
    var size = 10;
    var symbols = params.symbols;
    var symbol;
    var timestamp: any;

    if (response && key in response.data && response.data[key] != null) {
      favorites = response.data[key];
    }

    for (let i = 0; i < symbols.length; i++) {
      symbol = symbols[i];
      timestamp = new Date().getTime();

      if (symbol in favorites["index"]) {
        // update timestamp
        index = favorites["index"][symbol];
        favorite = favorites["data"][index];

        favorite["timestamp"] = timestamp;
      } else {
        //
        if (favorites.data.length >= size) {
          this.makeSpace(favorites, symbols);
        }

        // insert new favorite
        favorite = {
          symbol: symbol,
          timestamp: timestamp,
        };

        favorites["data"].push(favorite);
      }
    }
    // sort
    favorites["data"].sort(function (a, b) {
      if (a.timestamp > b.timestamp) {
        return -1;
      }
      if (a.timestamp < b.timestamp) {
        return 1;
      }
      return 0;
    });
    // build index
    for (let i = 0; i < favorites["data"].length; i++) {
      security = favorites["data"][i];
      favorites["index"][security.symbol] = i;
    }

    var paramsTarget = {
      key: key,
      value: favorites,
    };

    this.saveRecentQueue([paramsTarget], callback);
  }

  makeSpace(favorites, symbols) {
    // favorites are sorted by timestamp: newest -> oldest
    // size check: if the size limit is reached, the last
    // elements (oldest) are removed

    var size = 10;
    var overflow = favorites.data.length - size;
    var offset = favorites.data.length - overflow - symbols.length;

    favorites.data.splice(offset, symbols.length + overflow);

    // rebuild index
    favorites.index = {};
    for (let i = 0, length = favorites.data.length; i < length; i++) {
      const symbol = favorites.data[i].symbol;
      favorites.index[symbol] = i;
    }
  }

  async saveRecentQueue(params, callback) {
    var data: any = {
      name: "global",
      preferences: {},
      version: 1,
    };
    if (window.App.user.preferences) {
      // ID
      data.id = window.App.user.preferences.id;
      // name
      data.name = window.App.user.preferences.name;
      // version
      data.version = window.App.user.preferences.version;

      if (window.App.user.preferences.preferences) {
        data.preferences = window.App.user.preferences.preferences;
      }
    }

    for (let i = 0, length = params.length; i < length; i++) {
      data.preferences[params[i].key] = params[i].value;
    }

    if (callback) {
      await this.save(data);
      callback(data);
    } else {
      // return promise
      return this.save(data);
    }
  }
}
