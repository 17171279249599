import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { targetNameVariable } from "../../utils/utils";
import { ChartType, chartReducer } from "./React_Chart_reducer";

type ChartProps = {
  configObj;
};

const sizeOptions = [
  { value: "large", label: "large" },
  { value: "medium", label: "medium" },
  { value: "SMALL", label: "small" },
];
const alignOptions = ["left", "right", "center"];

const Chart = forwardRef(({ configObj }: ChartProps, ref) => {
  const initState: ChartType = useMemo(
    () => ({
      content: configObj.widgetValue.content,
      presentation: configObj.widgetValue.presentation,
    }),
    [configObj.widgetValue.content, configObj.widgetValue.presentation]
  );
  const [state, dispatch] = useImmerReducer(chartReducer, initState);

  useImperativeHandle(ref, () => ({
    getState: () => {
      let tempState = deepClone(configObj);
      tempState.widgetValue.content = state.content;
      tempState.widgetValue.presentation = state.presentation;
      return tempState;
    },
  }));

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            pb: "16px !important",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.headline.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_headline_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Headline"
            />
            <TextField
              value={state.content.headline.content}
              onChange={(e) =>
                dispatch({
                  type: "SET_headline_CONTENT",
                  payload: e.target.value,
                })
              }
              disabled={!state.content.headline.isEnabled}
              size="small"
            />
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ boxShadow: 3 }}>
        <CardContent
          sx={{
            gap: 2,
            display: "flex",
            flexDirection: "column",
            pb: "16px !important",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography>Size:</Typography>
              <Select
                size="small"
                value={state.presentation.size}
                onChange={(e) =>
                  dispatch({ type: "SET_size", payload: e.target.value })
                }
              >
                {sizeOptions.map((item: { value: string; label: string }, index) => (
                  <MenuItem key={index} value={item.value}> {item.label}</MenuItem>
                ))}
              </Select>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography>Align:</Typography>
              <Select
                size="small"
                value={state.presentation.align}
                onChange={(e) =>
                  dispatch({ type: "SET_align", payload: e.target.value })
                }
              >
                {alignOptions.map((item, index) => (
                  <MenuItem key={index} value={item}> {item}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.benchmark}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_benchmark",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Benchmark"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.presentation.legend}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_legend",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Legend"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.presentation.logAxis}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_logAxis",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Log Axis"
            />
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={state.content.title.isEnabled}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_title_ISENABLED",
                      payload: e.target.checked,
                    })
                  }
                  size="small"
                />
              }
              label="Title"
            />

            <Box
              gap={2}
              display="flex"
              justifyContent={"end"}
              alignItems="center"
            >
              <Button
                disabled={!state.content.title.isEnabled}
                onClick={() => {
                  dispatch({
                    type: "SET_title_CONTENT",
                    payload:
                      state.content.title.content !== ""
                        ? `${state.content.title.content} ${targetNameVariable}`
                        : `${targetNameVariable}`,
                  });
                }}
              >
                Add target's name to title
              </Button>
              <TextField
                value={state.content.title.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_title_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.content.title.isEnabled}
                size="small"
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});

export default Chart;
