import { Box } from "@mui/material";
import { deepClone } from "../../../../../../../../../../../deepClone";
import { HoldingsResultTable } from "../../../../../../results/HoldingsResultTable";
import { ColumnDefinition } from "tabulator-tables";

type TableProps = {
  data: any;
  rowClickHandler: Function;
  onTableColsChangeHandler: Function;
  setColumnsFields: (cols: ColumnDefinition[]) => any;
  expandEventId: string;
};

export default function Table({
  data,
  rowClickHandler,
  onTableColsChangeHandler,
  setColumnsFields,
  expandEventId,
}: TableProps) {
  return (
    <Box
      maxHeight={"100%"}
      flex={1}
      minHeight={0}
      minWidth={0}
      display={"flex"}
    >
      <HoldingsResultTable
        expandEventId={expandEventId}
        setColumnsFields={setColumnsFields}
        onTableColsChangeHandler={onTableColsChangeHandler}
        data={normalizeAndCutOutliers(data ?? [], "contribution")}
        rowClickHandler={(e: any) => rowClickHandler(e)}
      />
    </Box>
  );
}

const normalizeAndCutOutliers = (arrayOfObject, property) => {
  if (arrayOfObject.length) {
    var rawData = deepClone(arrayOfObject);
    var LENGTH = rawData.length;
    rawData = rawData.sort(function (a, b) {
      var aAbs = a ? Math.log(Math.abs(a[property])) : a;
      var bAbs = b ? Math.log(Math.abs(b[property])) : b;

      if (aAbs > bAbs) {
        return -1;
      }
      if (aAbs < bAbs) {
        return 1;
      }

      return 0;
    });

    var maxIndex = Math.min(LENGTH - 1, parseInt(`${(3 / 4) * (LENGTH + 1)}`));
    var minIndex = parseInt(`${(1 / 4) * (LENGTH + 1)}`);
    var max = rawData[maxIndex][property];
    var min = rawData[minIndex][property];
    var IQR = max - min;
    var threshold = Math.abs(IQR * 1.5);

    for (var i = 0, length = arrayOfObject.length; i < length; i++) {
      arrayOfObject[i]["_s_normalizationThreshold"] = threshold;
    }
  }

  return arrayOfObject;
};
