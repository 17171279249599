import { Card, CardContent, Typography } from "@mui/material";
import style from "./WizzardComponents.module.scss";

type WizzOperatorsProps = {
  operators: any;
  dispatcher: Function;
  operatorId: string | null;
  isEditing: boolean;
  showValueSection: boolean;
  rState: any;
  options: any;
};

export default function WizzOperators({
  operators,
  dispatcher,
  operatorId,
  isEditing,
  showValueSection,
  rState,
  options,
}: WizzOperatorsProps) {
  return (
    <Card sx={{ height: "100%", boxShadow: 3 }}>
      <CardContent>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Choose the Criterion
        </Typography>
        <ul>
          {operators &&
            operators.map((item, index) => (
              <li
                className={`${style.listItem} ${
                  item["value"] === operatorId && isEditing === true
                    ? "tFormSelectionAddRule-listItem--selected"
                    : item["value"] === operatorId && !isEditing && showValueSection
                    ? "tFormSelectionAddRule-listItem--selected"
                    : ""
                }`}
                key={index}
              >
                <span
                  onClick={(e: any) => {
                    dispatcher({
                      type: "ON_CLICK_OPERATOR_OPTION",
                      payload: { item: item, options: options },
                    });
                    if (!isEditing) {
                      highlightSelectedWhenCreating(
                        e.target,
                        "tFormSelectionAddRule-listItem--selected"
                      );
                    }
                  }}
                  className={style.listItemContent}
                >
                  {item.label}
                </span>
              </li>
            ))}
        </ul>
      </CardContent>
    </Card>
  );
}

const highlightSelectedWhenCreating = (element: HTMLElement, classToApply: string) => {
  if (element) {
    let siblings: any = Array.from(element.parentNode!.parentNode!.childNodes);
    siblings.forEach((item: HTMLElement) => {
      if (item.classList.contains(classToApply)) {
        item.classList.remove(classToApply);
      }
    });
    element.parentElement!.classList.add(classToApply);
  }
};
