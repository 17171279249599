import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Highcharts from "highcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ClusterAnalytics } from "../../api/compute/ClusterAnalytics";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useFormatter } from "../../hooks/useFormatter";
import { Chart } from "../Chart";

type LongShortHistogramProps = {
  shortHoldings: { symbol: string; weight: number }[];
  longHoldings: { symbol: string; weight: number }[];
  diffHoldings: { symbol: string; weight: number }[];
  onClickSerie?: (value: {
    constituents: string[];
    id: string;
    field: string;
  }) => any;
};

const OPTIONS = [
  {
    label: "Markets",
    value: "Country",
    taxonField: "country",
    taxonType: "security",
  },
  {
    label: "Sector",
    value: "1 Industry",
    taxonField: "sector",
    taxonType: "security",
  },
  {
    label: "Regions",
    value: "Region",
    taxonField: "country",
    taxonType: "security",
  },
  {
    label: "Areas",
    value: "Area",
    taxonField: "country",
    taxonType: "security",
  },
  {
    label: "Industry",
    value: "3 Sector",
    taxonField: "industry",
    taxonType: "security",
  },
  {
    label: "Currency",
    value: "Currency",
    taxonField: null,
    taxonType: null,
  },
  {
    label: "Size",
    value: "3 Level",
    taxonField: "SizeClassification",
    taxonType: "Stock",
  },
  {
    label: "Inv. Region (ETF)",
    value: "etfgeo",
    taxonField: "etfgeo",
    taxonType: "ETF",
  },
  {
    label: "Asset class (ETF)",
    value: "AssetClass",
    taxonField: "etfclass",
    taxonType: "ETF",
  },
  {
    label: "Specialty (ETF)",
    value: "Specialty",
    taxonField: "etfclass",
    taxonType: "ETF",
  },
  {
    label: "Theme (ETF)",
    value: "Theme",
    taxonField: "etfclass",
    taxonType: "ETF",
  },
];

const StyledSelect = styled(({ value, children, className }: any) => (
  <Select
    value={value}
    size="small"
    variant="outlined"
    classes={{ select: className }}
  >
    {children}
  </Select>
))({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

export function LongShortHistogram({
  longHoldings,
  shortHoldings,
  diffHoldings,
  onClickSerie,
}: LongShortHistogramProps) {
  const [level, setLevel] = useState("Country");
  const [histogramData, setHistogramData] = useState<
    {
      long: { weight: number; constituents: string[] };
      short: { weight: number; constituents: string[] };
      diff: { weight: number; constituents: string[] };
      _id: string;
    }[]
  >([]);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  const optionsMap = useMemo(
    () =>
      OPTIONS.reduce((prev, current) => {
        prev[current.value] = current;

        return prev;
      }, {}),
    []
  );

  const environment = useEnvironment();
  const appEnvironment = useMemo(() => environment.get("setup"), [environment]);
  const formatter = useFormatter();
  const taxonomies = environment.get("rawTaxonomies");
  const taxonFields = environment.get("taxonomyFields");

  const categories = useMemo(() => {
    if (histogramData.length) {
      return histogramData.map((objSerie) => objSerie["category"]);
    }

    return [];
  }, [histogramData]);

  const clusterAnalyticsAPI = useMemo(
    () => new ClusterAnalytics(appEnvironment),
    [appEnvironment]
  );

  const getCluster = useCallback(
    async (holdings) => {
      if (holdings == null || !holdings.length) {
        return;
      }
      const apiCluster = clusterAnalyticsAPI;
      const analytics = ["weight", "name", "type"];

      const response = await apiCluster
        .createConfiguration()
        .segment(level, true)
        .method("INTERSECTION")
        .analytics(analytics)
        .universeFromPositions(holdings)
        .fetchAnalytics();

      return response;
    },
    [clusterAnalyticsAPI, level]
  );

  const getDataForSeries = useCallback(async () => {
    let longResult: any = null;
    let shortResult: any = null;
    let diffResult: any = null;

    const promises: Promise<any>[] = [
      getCluster(longHoldings),
      getCluster(shortHoldings),
      getCluster(diffHoldings),
    ];

    try {
      const response = await Promise.all(promises);

      if (response) {
        longResult = response[0];
        shortResult = response[1];
        diffResult = response[2];

        const data: {
          _id: string;
          long: { weight: number; constituents: string[] };
          short: { weight: number; constituents: string[] };
          diff: { weight: number; constituents: string[] };
        }[] = [];

        const longStats = longResult?.["clustersStats"]?.["stats"] ?? undefined;
        const shortStats =
          shortResult?.["clustersStats"]?.["stats"] ?? undefined;
        const diffStats = diffResult?.["clustersStats"]?.["stats"] ?? undefined;

        // The diff serie is the serie that is longer than the others so loop on it

        if (diffStats) {
          for (const [key, value] of Object.entries<any>(diffStats)) {
            data.push({
              _id: key,
              long: {
                weight: longStats?.[key]?.["weight"] ?? 0,
                constituents:
                  longResult?.["clustersStats"]?.["clusters"]?.[key] ?? [],
              },
              diff: {
                weight: value["weight"],
                constituents:
                  diffResult?.["clustersStats"]?.["clusters"]?.[key] ?? [],
              },
              short: {
                weight: shortStats?.[key]?.["weight"] ?? 0,
                constituents:
                  shortResult?.["clustersStats"]?.["clusters"]?.[key] ?? [],
              },
            });
          }
        }

        const taxonType = optionsMap[level].taxonType;
        const taxonField = optionsMap[level].taxonField;

        const taxonomy =
          taxonField === "SizeClassification"
            ? taxonomies[taxonField]
            : taxonomies[taxonFields[taxonType][taxonField]];

        const histoData = data
          .map((obj) => {
            obj["category"] = taxonField
              ? formatter.custom("taxon", {
                  options: {
                    notAvailable: {
                      input: null,
                      output: "",
                    },
                    taxonomy,
                  },
                  output: "TEXT",
                  value: obj._id,
                  valueHelper: null,
                })
              : obj._id;

            return obj;
          }, [])
          .sort((a, b) => (a["category"] > b["category"] ? -1 : 1));

        setHistogramData(histoData);
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [
    diffHoldings,
    formatter,
    getCluster,
    level,
    longHoldings,
    optionsMap,
    shortHoldings,
    taxonFields,
    taxonomies,
  ]);

  const labelsConfigNoFlags: any = useMemo(
    () => ({
      step: 1,
      reserveSpace: true,
      useHTML: true,
      enabled: true,
      padding: 0,
      formatter: function () {
        const self: any = this;
        let fontSize = "12px";
        if (categories.length < 10) {
          fontSize = "12px";
        } else if (categories.length <= 12) {
          fontSize = "10px";
        } else {
          fontSize = "6px";
        }
        return `<div title="${self.value}"><p style="font-size: ${fontSize}; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${self.value}</p></div>`;
      },
    }),
    [categories.length]
  );

  const buildChartConfig = useCallback(() => {
    const options: Highcharts.Options = {
      exporting: { enabled: false },
      chart: {
        type: "bar",
        animation: false,
      },
      title: {
        text: undefined,
      },

      credits: { enabled: false },

      xAxis: [
        {
          reversed: false,
          labels: labelsConfigNoFlags,
          categories: categories,
        },
      ],
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: 0.05,
        labels: {
          enabled: false,
          formatter: function () {
            const self: any = this;
            const value =
              self.value < 0
                ? "-" + Math.abs((self.value as number) * 100).toFixed(2) + "%"
                : Math.abs((self.value as number) * 100).toFixed(2) + "%";
            return value;
          },
        },
        plotLines: [
          {
            color: "darkgrey",
            width: 2,
            value: 0,
            label: {
              text: "Short",
              verticalAlign: "bottom",
              textAlign: "right",
              align: "left",

              x: -10,
              rotation: 0,
              style: {
                color: "red",
                fontWeight: "normal",
              },
            },
          },
          {
            color: "green",
            width: 0,
            value: 0,
            label: {
              text: "Long",
              verticalAlign: "bottom",
              textAlign: "left",
              align: "right",
              x: 10,
              rotation: 0,
              style: {
                color: "green",
                fontWeight: "normal",
              },
            },
          },
        ],
      },

      legend: { enabled: false },
      tooltip: {
        backgroundColor: "white",
        borderRadius: 0,
        shadow: false,
        pointFormatter: function () {
          const series = this.series;

          const perc = formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: 0,
                output: 0,
              },
            },
            output: "TEXT",
            value: this.y,
            valueHelper: null,
          });

          return `<p>${series.name}: <b style="color:${this.color}">${perc}</b></p><br/>`;
        },
        shared: true,
      },

      series: [
        {
          stacking: "normal",
          animation: false,
          maxPointWidth: 25,
          type: "bar",
          name: "Long",
          data: histogramData.map((value) => ({
            constituents: value.long.constituents,
            y: value.long.weight,
            _id: value._id,
            color: "green",
          })),
          events: {
            click: function (event) {
              const point: any = event.point;

              if ("constituents" in point) {
                const symbols = point?.constituents ?? [];

                if (onClickSerie) {
                  onClickSerie({
                    constituents: symbols as string[],
                    field: level,
                    id: point._id,
                  });
                }
              }
            },
          },
        },
        {
          stacking: "normal",
          maxPointWidth: 25,
          type: "bar",
          name: "short",
          animation: false,
          data: histogramData.map((value) => ({
            constituents: value.short.constituents,
            y: value.short.weight,
            _id: value._id,
            color: "red",
          })),
          events: {
            click: function (event) {
              const point: any = event.point;

              if ("constituents" in point) {
                const symbols = point?.constituents ?? [];

                if (onClickSerie) {
                  onClickSerie({
                    constituents: symbols as string[],
                    field: level,
                    id: point._id,
                  });
                }
              }
            },
          },
        },
        {
          maxPointWidth: 25,
          type: "bar",
          name: "Net",
          animation: false,
          data: histogramData.map((value) => ({
            constituents: value.diff.constituents,
            y: value.diff.weight,
            _id: value._id,
            color: "#194b88",
          })),
          events: {
            click: function (event) {
              const point: any = event.point;

              if ("constituents" in point) {
                const symbols = point?.constituents ?? [];

                if (onClickSerie) {
                  onClickSerie({
                    constituents: symbols as string[],
                    field: level,
                    id: point._id,
                  });
                }
              }
            },
          },
        },
      ],
    };
    setChartOptions(options);
  }, [
    categories,
    formatter,
    histogramData,
    labelsConfigNoFlags,
    level,
    onClickSerie,
  ]);

  useEffect(() => {
    buildChartConfig();
  }, [buildChartConfig]);

  useEffect(() => {
    getDataForSeries();
  }, [getDataForSeries]);

  return (
    <Box height={"100%"}>
      <StyledSelect value={level}>
        {OPTIONS.map((option) => (
          <MenuItem
            key={uuidv4()}
            value={option.value}
            onClick={() => setLevel(option.value as any)}
          >
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </StyledSelect>
      {chartOptions && (
        <Box height={"90%"} width={"100%"}>
          <Chart constructorType="chart" options={chartOptions} />
        </Box>
      )}
    </Box>
  );
}
