import { deepClone } from "../../../deepClone";

export const creatingListItemContentForRuleList = (create: any, rule) => {
  const [optionsIndex, options] = getOptionsIndexAndInnerOptions(create);
  //!getting property name
  const property_ID = rule["property"];
  const property_INDEX = optionsIndex[property_ID]["property"];
  const property_LABEL = options["properties"][property_INDEX]["label"];
  //!getting function name
  const function_ID = rule["function"];
  const function_INDEX = optionsIndex[property_ID]["functions"][function_ID];
  const function_LABEL = options["functions"][property_INDEX][function_INDEX]["label"];
  let f_params: any = null;
  switch (function_ID) {
    case "quantile":
      f_params = rule?.functionParams?.value ? parseInt(rule?.functionParams?.value) : null;
      break;
    case "threshold":
    case "outlier":
      f_params = rule?.functionParams?.value ? parseFloat(rule?.functionParams?.value) : null;
      f_params = f_params * 100 + "%";
      break;
  }
  //!getting operator name
  const operator_ID = rule["operator"];
  const operator_INDEX =
    optionsIndex[property_ID]["operators"][function_INDEX][operator_ID][
      rule["operatorParams"]["value"]
    ];
  const operator_LABEL =
    options["operators"][property_INDEX][function_INDEX][operator_INDEX]["label"];
  const listItem = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <span>{property_LABEL}</span>
      <span>
        {function_LABEL}
        {f_params ? <> ({f_params})</> : null}
      </span>
      <span>{operator_LABEL}</span>
    </div>
  );
  return listItem;
};

export const getOptionsIndexAndInnerOptions = (options) => {
  var _options = deepClone(options);

  let innerOptions: any = {
    functions: [],
    operators: [],
    properties: [],
  };
  let _optionsIndex = {};

  var option: any = null;
  var key: any = null;
  var _sortByOptionIndex: any = null;
  for (let i = 0, lengthI = _options.length; i < lengthI; i++) {
    option = _options[i];
    key = option["property"]["value"];
    // properties
    innerOptions["properties"].push(option["property"]);
    _optionsIndex[key] = {
      functions: {},
      operators: [],
      property: i,
    };
    // functions
    innerOptions["functions"][i] = [];
    for (var j = 0, lengthJ = option["functions"].length; j < lengthJ; j++) {
      // enable augmented behaviour in select dropdown
      if ("widget" in option["functions"][j]) {
        option["functions"][j]["type"] = "augmented";
      }

      innerOptions["functions"][i].push(option["functions"][j]);

      _optionsIndex[key]["functions"][option["functions"][j]["value"]] = j;
    }
    // operator
    innerOptions["operators"][i] = [];
    for (let j = 0; j < option["operators"].length; j++) {
      innerOptions["operators"][i].push(option["operators"][j][0]["widget"]["options"]);
      _sortByOptionIndex = {};
      _sortByOptionIndex[option["operators"][j][0]["value"]] = {};
      for (
        var k = 0, lengthK = option["operators"][j][0]["widget"]["options"].length;
        k < lengthK;
        k++
      ) {
        _sortByOptionIndex[option["operators"][j][0]["value"]][
          option["operators"][j][0]["widget"]["options"][k]["value"]
        ] = k;
      }
      _optionsIndex[key]["operators"].push(_sortByOptionIndex);
    }
  }
  return [_optionsIndex, innerOptions];
};

export const _valueGet = (
  wizzardInput,
  propertyId,
  functionId,
  operatorId,
  _optionsIndex,
  _options
) => {
  var value = wizzardInput;

  if (value == null) {
    value = {
      field: {
        function: null,
        functionParams: null,
        operator: null,
        operatorParams: null,
        property: "getStarted",
      },
      index: null,
    };
  }

  if (propertyId != null) {
    // ------------------------------------------------ property
    var propertyIndex = _optionsIndex[propertyId]["property"];
    var defaultValue = _optionsIndex[propertyId]["value"];
    // ------------------------------------------------ function
    var functionIndex = 0;
    var functionItem = _options["functions"][propertyIndex][functionIndex];
    functionId =
      functionId != null ? functionId : defaultValue != null ? defaultValue["function"] : null;
    if (functionId != null) {
      functionIndex = _optionsIndex[propertyId]["functions"][functionId];
      functionItem = _options["functions"][propertyIndex][functionIndex];
    }
    var functionParams: any = {
      augmentation: null,
      subject: functionItem["value"],
    };

    if (functionItem["type"] === "augmented") {
      functionParams["augmentation"] = {
        value: functionItem["widget"]["value"],
      };
    }
    if (wizzardInput["editing"]) {
      functionParams["augmentation"] = wizzardInput.field?.functionParams?.value
        ? { value: wizzardInput.field?.functionParams?.value }
        : null;
    }
    // ------------------------------------------------ operator
    var operatorIndex = 0;
    var operatorItem = _options["operators"][propertyIndex][functionIndex][operatorIndex];
    operatorId =
      operatorId != null
        ? operatorId
        : defaultValue != null && functionId === defaultValue["function"]
        ? defaultValue["operator"]
        : Object.keys(_optionsIndex[propertyId]["operators"][functionIndex])[0];
    if (
      operatorId != null &&
      wizzardInput["field"]["function"] &&
      wizzardInput["field"]["operatorParams"]
    ) {
      if (wizzardInput["editing"] === true) {
        const ii = wizzardInput["field"]["operatorParams"]["value"];
        operatorIndex = _optionsIndex[propertyId]["operators"][functionIndex][operatorId][ii];
      } else {
        const ii = wizzardInput["field"]["operatorParams"]["subject"];
        operatorIndex = _optionsIndex[propertyId]["operators"][functionIndex][operatorId][ii];
      }
      // operatorIndex =
      //   wizzardInput["editing"] === true
      //     ? _optionsIndex[propertyId]["operators"][functionIndex][operatorId][
      //         wizzardInput["field"]["operatorParams"]["value"]
      //       ]
      //     : _optionsIndex[propertyId]["operators"][functionIndex][operatorId][
      //         wizzardInput["field"]["operatorParams"]["subject"]
      //       ];
      operatorItem = _options["operators"][propertyIndex][functionIndex][operatorIndex];
    }
    var operatorParams = {
      augmentation: {
        value:
          defaultValue != null &&
          defaultValue["operator"] === operatorItem["value"] &&
          defaultValue["operatorParams"] != null
            ? defaultValue["operatorParams"]
            : operatorItem["value"] ?? operatorItem["value"],
      },
      subject: operatorItem["value"] ?? operatorItem["value"],
    };

    value = {
      ...wizzardInput,
      field: {
        function: functionItem["value"],
        functionParams: functionParams,
        operator: operatorId,
        operatorParams: operatorParams,
        property: propertyId,
      },
    };
  }

  return value;
};
