const LENGTH_NAME = 3;
const LENGTH_PASSWORD = 2;

export class TValidator {
  static email(address) {
    address = this.trim(address);
    var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/gi;

    return pattern.test(address);
  }

  /**
   * Check name validity. Suitable for Lists, Reports, Strategies and all
   * Model Objects stored on DB
   *
   * Rules
   *  - length must be at least 3 characters
   *  - characters must be letters, numbers, space, _, &, -, (, ), [ and ].
   *
   * @param {string} name - the name to be validated
   *
   * @returns {object} - validation result
   *
   * {
   *     issues: {array<string>},
   *     isValid: {boolean},
   *     sanitized: {string}
   * }
   */
  static _name(name) {
    name = this.trim(name);
    var issues: any = [];
    var isValid = true;

    if (name.length < LENGTH_NAME) {
      issues.push(`Name must be long at least ${LENGTH_NAME} characters.`);
      isValid = isValid && false;
    }

    var pattern = /^[a-z0-9_&\-[\]()\s]+$/gi;
    if (name.length >= LENGTH_NAME && pattern.test(name) === false) {
      issues.push('Name cannot contain /,\\,:,*,?,",<,>,|');
      isValid = isValid && false;
    }

    var result = {
      issues: issues,
      isValid: isValid,
      sanitized: name,
    };

    return result;
  }

  static password(password) {
    password = this.trim(password);

    return password.length >= LENGTH_PASSWORD ? true : false;
  }

  static trim(string) {
    return string.replace(/^\s* | \s*$/gi, "");
  }
}
