/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyMonthlyAnalytics
 * @summary Strategy - Monthly Analytics
 *
 */

import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity } from "./Generator";

export function sectionsMonthlyAnalytics(input, section, datum, formatter) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    if (section["content"]["monthlyPerformance"]["isEnabled"]) {
        if (section["content"]["monthlyPerformance"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["monthlyPerformance"]["content"],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsMonthlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyMonthlyPerformance",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsMonthlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyMonthlyPerformance",
                formatter
            );
            _sections.push(_section);
        }
    }
    if (section["content"]["maxDrawdown"]["isEnabled"]) {
        if (section["content"]["maxDrawdown"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["maxDrawdown"]["content"],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsMonthlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyMaxDrawdown",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsMonthlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyMaxDrawdown",
                formatter
            );
            _sections.push(_section);
        }
    }
    if (section["content"]["monthlyStandardDeviation"]["isEnabled"]) {
        if (section["content"]["monthlyStandardDeviation"]["content"]) {
            const _section = {
                data: {
                    text: section["content"]["monthlyStandardDeviation"][
                        "content"
                    ],
                },
                type: "header1",
            };
            _sections.push(_section);
        }
        if (section["content"]["years"] === "ROWS") {
            const _section = sectionsMonthlyAnalyticsDetailByRows(
                input,
                section,
                datum,
                "strategyVolatility",
                formatter
            );
            _sections.push(_section);
        } else {
            const _section = sectionsMonthlyAnalyticsDetailByColumns(
                input,
                section,
                datum,
                "strategyVolatility",
                formatter
            );
            _sections.push(_section);
        }
    }

    return _sections;
}

function sectionsMonthlyAnalyticsDetailByColumns(
    input,
    section,
    data,
    field,
    formatter
) {
    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };

    // Organize years grouped by month
    const yearsByMonth: any = {};
    const years: any = [];
    for (
        let i = 0, length = data["analytics"]["monthly"].length;
        i < length;
        i++
    ) {
        const item = data["analytics"]["monthly"][i];
        const timeFrame = item["timeFrame"].split("_");
        const year = String(timeFrame[0]);
        const month = parseInt(timeFrame[1], 10);

        if (!isNaN(parseInt(year, 10))) {
            if (yearsByMonth[month] == null) {
                yearsByMonth[month] = {};
            }
            yearsByMonth[month][year] = item;
            if (years.indexOf(year) === -1) {
                years.push(year);
            }
        }
    }

    // Sort years
    years.sort(function (a, b) {
        return a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1;
    });

    // Prepare header
    table["data"]["head"][0].push({
        style: null,
        value: "",
    });
    for (let i = 0; i < years.length; i++) {
        table["data"]["head"][0].push({
            style: {
                align: "right",
            },
            value: years[i],
        });
    }

    for (const month in yearsByMonth) {
        const tableRow: any = [];
        // Iterate for ALL years, even empty
        for (const yearIndex in years) {
            const year = years[yearIndex];
            /*
                            The column number is the actual year, subtracted by the first year (assume the
                                years are consecutive and incrementing) and add 1 to offset to the second
                                column. The first column is already used to show the name of the month.

                            Example: 2005, 2006, 2007

                            If there is 2005:
                                2005 - 2005 + 1 = 1 (column, first)

                            If there is 2007:
                                2007 - 2005 + 1 = 3 (column, last column actually in that case)
                        */
            const yearValue = parseInt(year, 10);
            if (!isNaN(yearValue)) {
                const yearIndex = yearValue - years[0] + 1;
                // Get item
                const item = yearsByMonth[month][year];
                if (item == null) {
                    tableRow[yearIndex] = {
                        style: {
                            align: "right",
                        },
                        value: "",
                    };
                } else {
                    const timeFrame = item["timeFrame"].split("_");
                    const date = new Date(
                        Date.UTC(
                            parseInt(timeFrame[0], 10),
                            parseInt(timeFrame[1], 10) - 1,
                            1
                        )
                    );
                    // Set as first element
                    tableRow[0] = {
                        style: null,
                        value: format.custom("date", {
                            options: {
                                format: ["M"],
                                isMillisecond: true,
                                notAvailable: {
                                    input: "",
                                    output: "",
                                },
                                separator: " ",
                            },
                            output: "HTML",
                            value: date.getTime(),
                            valueHelper: null,
                        }),
                    };
                    tableRow[yearIndex] = {
                        style: {
                            align: "right",
                        },
                        value: format.custom("number", {
                            options:
                                customPropertiesFormattingOptions["PERCENTAGE"],
                            output: "PDF",
                            value: item[field],
                            valueHelper: null,
                        }),
                    };
                }
            }
        }
        if (tableRow.length > 0) {
            table["data"]["body"].push(tableRow);
        }
    }

    return table;
}

function sectionsMonthlyAnalyticsDetailByRows(
    input,
    section,
    data,
    field,
    formatter
) {
    const dates = data["analytics"]["monthly"];

    const format = formatter;
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };
    // Head setup
    table["data"]["head"][0].push({
        style: null,
        value: "",
    });
    for (let i = 1; i <= 12 /* months */; i++) {
        const date = new Date(Date.UTC(new Date().getUTCFullYear(), i - 1, 1));
        table["data"]["head"][0].push({
            style: {
                align: "right",
            },
            value: format.custom("date", {
                options: {
                    format: ["M"],
                    isMillisecond: true,
                    notAvailable: {
                        input: "",
                        output: "",
                    },
                    separator: " ",
                },
                output: "HTML",
                value: date.getTime(),
                valueHelper: null,
            }),
        });
    }

    function initializeTableRowMonths(year) {
        const tableRow = [
            {
                style: null,
                value: year != null ? year : "",
            },
        ];
        for (let i = 1; i <= 12 /* months */; i++) {
            tableRow[i] = {
                style: null,
                value: "",
            };
        }
        return tableRow;
    }

    let tableRow: any = [];
    let actualYear: any = null;
    for (let i = 0; i < dates.length; i++) {
        const item = dates[i];
        const timeFrame = item["timeFrame"].split("_");
        const year = String(timeFrame[0]);
        if (year !== "average" && year !== "Annualized") {
            if (actualYear !== year) {
                if (tableRow.length > 0) {
                    table["data"]["body"].push(tableRow);
                }
                actualYear = year; // Start of new year, new row
                tableRow = initializeTableRowMonths(year);
            }
            const month = parseInt(timeFrame[1], 10);
            tableRow[month] = {
                style: {
                    align: "right",
                },
                value: format.custom("number", {
                    options: customPropertiesFormattingOptions["PERCENTAGE"],
                    output: "PDF",
                    value: item[field],
                    valueHelper: null,
                }),
            };
        }
    }
    if (tableRow.length > 0) {
        table["data"]["body"].push(tableRow);
    }
    return table;
}
