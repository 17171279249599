import { Box } from "@mui/material";
import { TrendratingTable } from "../../../../../../../components/table/TrendratingTable";

type Props = {
  columns: any[];
  rows: any[];
};

export default function Table({ columns, rows }: Props) {
  return (
    <Box overflow={"auto"} flex={1}>
      <TrendratingTable
        disableDefaultRowClick={true}
        options={{
          ajaxSorting: false,
        }}
        autoResize={true}
        columns={columns}
        data={rows}
      />
    </Box>
  );
}
