import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ColumnSet } from "../../../../../../../components/table/tableUtilities/ColumnSet";
import { deepClone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../../../hooks/useEventBus";
import { Section } from "../../../../../../trendrating-report/widgets/Section";
import ReportWizzard from "./ReportWizzard";
import style from "../../../../../../../hooks/useReport.module.scss";

type Props = {
  page: string;
  target: any;
  title?: string;
  usage: any;
  storage?: any;
  rankingCache: any;
  widgets: any;
  strategyCache: any;
  doActionOnLoad?: boolean;
  modalChannelFromDojo?: any;
};

export default function ReactDojoReport({
  page,
  target,
  title,
  usage,
  storage,
  rankingCache,
  widgets,
  strategyCache,
  doActionOnLoad,
  modalChannelFromDojo,
}: Props) {
  const environment = useEnvironment();
  const setup = useMemo(() => environment.get("setup"), [environment]);
  const productFlavourCode = useMemo(
    () => setup["configuration"].get("product")["flavourCode"],
    [setup]
  );
  const http = useMemo(() => environment.get("http"), [environment]);

  const httpTemplates = useMemo(() => {
    switch (page) {
      case "dispersionBasketTab":
      case "analysisList":
        return http["portfolioReportTemplates"];
      case "analysisMarkets":
        return http["peerReportTemplates"];
      case "analysisMarketsDetail":
        return http["peerReportTemplates"];
      case "ranking":
        return http["rankingReportTemplates"];
      case "screening":
        return http["screeningReportTemplates"];
      case "strategyBuilder":
        return http["strategyReportTemplates"];
      case "strategyLongShort":
        return http["strategyLongShortReportTemplates"];
      case "systematicPortfolios":
        return http["systematicPortfoliosReportTemplates"];
      default:
        return null;
    }
  }, [http, page]);

  const getDefaultTemplates = useCallback(() => {
    const _section = new Section(setup);
    var defaultTemplatesPromise = _section.getDefaultTemplate(page);
    return defaultTemplatesPromise.then((defaultTemplates) => {
      // Prepare correct structure
      var preparedDefaultTemplates: any[] = [];

      if (page === "analysisMarketsDetail") {
        const marketDetailDefault = defaultTemplates[0]["detailed"];
        preparedDefaultTemplates.push(
          httpTemplates.template(
            httpTemplates.decode(httpTemplates.migrate(marketDetailDefault))
          )
        );
      } else {
        for (var i = 0, length = defaultTemplates.length; i < length; i++) {
          preparedDefaultTemplates.push(
            httpTemplates.template(
              httpTemplates.decode(httpTemplates.migrate(defaultTemplates[i]))
            )
          );
        }
      }

      return preparedDefaultTemplates;
    });
  }, [httpTemplates, page, setup]);

  // Channel has to be used to access the context of modal action by a component that is
  // rendered as a child of a dojo class. Otherwise if the component is rendered as react
  // child it use actionModal context directly (as usually).

  const dojoContext = useRef<any>(null);
  const getDojoContext = useCallback(() => dojoContext.current, []);
  const { t } = useTranslation();
  const doAction = useCallback(() => {
    const _uiState = _getUiState(
      page,
      {
        page,
        target,
        title,
        usage,
        storage,
        rankingCache,
        widgets,
        strategyCache,
      },
      environment,
      t
    );
    const ctx = getDojoContext();
    if (!ctx) {
      //!------------------------------
      console.error("no context provided to action report!");
      return;
    } else {
      httpTemplates.get().then(
        //! get saved templates
        (response) => {
          let obj = _templateDataPrepare(
            response,
            httpTemplates,
            productFlavourCode
          );
          let defaultTemplatesPromise = getDefaultTemplates();
          //!get default templates
          defaultTemplatesPromise.then((defaultTemplates) => {
            //! set the content of modal and showing it
            ctx.setContent(
              <ReportWizzard
                data={{
                  columnsAvailable: _uiState?.columnsAvailable ?? [],
                  defaultTemplates: defaultTemplates,
                  templates: obj.templates,
                  lastUsedTemplate: obj.lastUsedTemplate,
                  lastUsedTemplateName: "LAST_USED_TEMPLATE",
                  page: page,
                  wysiwygState: _uiState?.wysiwygState,
                }}
                httpTemplates={httpTemplates}
                refresh={() => {
                  doAction();
                }}
                rankingCache={rankingCache}
              />
            );
            ctx.setModalTitle(`Create a PDF report for ${title}`);
            ctx.setShowModal(true);
            ctx.setOptions({
              stylesOptions: {
                customCss: { maxWidth: "60%", height: "100%" },
                bodyCustomClass: style["full-height"],
              },
            });
          });
        },
        (error) => console.log(error)
      );
    }
  }, [
    environment,
    getDefaultTemplates,
    getDojoContext,
    httpTemplates,
    page,
    productFlavourCode,
    rankingCache,
    storage,
    strategyCache,
    t,
    target,
    title,
    usage,
    widgets,
  ]);

  //!!.....
  const dojoDoAction = useCallback(
    (e) => {
      dojoContext.current = e.detail.ctx;
      doAction();
    },
    [doAction]
  );
  const { on, remove } = useEventBus();
  useEffect(() => {
    on("click-on-dojo-li-report-button", dojoDoAction);

    /*
      to add report to stratey builder tab we need these 
      variables(doActionOnLoad and modalChannelFromDojo).
      once index.js of strategies is ported in react we can
      use the useReport path instead of this one.
    */
    if (doActionOnLoad != null && doActionOnLoad === true) {
      dojoDoAction({ detail: { ctx: modalChannelFromDojo } });
    }
    return () => {
      remove("click-on-dojo-li-report-button", dojoDoAction);
    };
  }, [
    doAction,
    doActionOnLoad,
    dojoDoAction,
    modalChannelFromDojo,
    on,
    remove,
  ]);
  //!!.....

  return (
    <span>
      <p>Report</p>
    </span>
  );
}

const _templateDataPrepare = (response, httpTemplates, productFlavourCode) => {
  let templates: any[] = [];
  let lastUsedTemplate = null; // clean state
  if (response != null && response.length > 0) {
    for (let i = 0; i < response.length; i++) {
      var template = httpTemplates.template(deepClone(response[i])); // Normalize
      if (template["name"] === "LAST_USED_TEMPLATE") {
        template["isEditable"] = false; // Disable save because this object does not exist in template list

        // Clean template from invalid widgets
        if (
          template["configuration"] != null &&
          template["configuration"]["sections"] != null
        ) {
          var sections: any[] = [];
          for (
            var j = 0, lengthJ = template["configuration"]["sections"].length;
            j < lengthJ;
            j++
          ) {
            var section = template["configuration"]["sections"][j];
            // Filter out unsupported widgets
            if (
              productFlavourCode !== "SYSTEMATIC_ENGINE" &&
              section["type"] === "REPORT_STRATEGY_SUMMARY"
            ) {
              continue;
            }

            sections.push(section);
          }
          template["configuration"]["sections"] = sections;
        }

        lastUsedTemplate = template;
      } else {
        templates.push(template);
      }
    }
  }
  return { templates, lastUsedTemplate };
};

export const _getUiState = (page, reportParams, environment, translate) => {
  // TODO long short strategy is part of strategyBuilder
  // temporary change of page type
  let _page = page;
  if (_page === "strategyLongShort") {
    _page = "strategyBuilder";
  }

  if (reportParams.target == null) {
    return;
  }

  let _reportParams = { ...reportParams };
  var columnsAvailable: any[] = [];
  var wysiwygState: any = {
    actions: {
      constraints: null,
      rank: null,
      sortBy: null,
    },
    columns: null,
    dataTotalCount: 0,
    promiseCache: null,

    // 2020-12-10 Markets: new way to do stuff using storages
    storage: null,

    target: null,
    targetType: null,
  };

  var environmentSetup = environment.get("setup");
  var pageConfiguration = environmentSetup["configuration"].get(_page);
  var target = deepClone(_reportParams.target);
  switch (_page) {
    case "dispersionBasketTab": {
      // columnsAvailable =
      //     pageConfiguration.tabs[1].widgets.viewer[
      //         "columns_available"
      //     ];

      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "LIST";

      const dispersionTabWidget = _reportParams.widgets?.["dispersion"] ?? null;

      if (dispersionTabWidget != null) {
        const dispersionParams = dispersionTabWidget.get("wysiwygState");

        const constraints = {
          intervals: dispersionParams?.intervals ?? 25,
          performanceTimeframe: dispersionParams?.period ?? "3_months",
        };
        wysiwygState.trimOutliers = dispersionParams?.trimOutliers ?? false;
        wysiwygState.dispersionByConstraints = constraints;
        wysiwygState.segment = dispersionParams?.segment ?? "Country";
        wysiwygState.segmentEtf = dispersionParams?.etfSegment ?? "etfgeo";
        wysiwygState.title = target["name"].replace(",", " - ");
      } else {
        const constraints = {
          intervals: 25,
          performanceTimeframe: "3_months",
        };
        wysiwygState.trimOutliers = false;
        wysiwygState.dispersionByConstraints = constraints;
        wysiwygState.segment = "Country";
        wysiwygState.segmentEtf = "etfgeo";
      }

      break;
    }
    case "analysisList": {
      const holdingsTabConfig = pageConfiguration.tabs.find(
        (tab) => tab.id === "holdings"
      );
      columnsAvailable = holdingsTabConfig.widgets.viewer["columns_available"];

      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "LIST";

      break;
    }
    case "analysisMarkets": {
      if (target["name"] === "__ROOT__") {
        target["name"] = translate["World_wide"];
      }
      target["name"] = target["name"].replace(",", " - ");

      wysiwygState["storage"] = _reportParams.storage;
      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "PEER";

      break;
    }
    case "analysisMarketsDetail": {
      if (target["name"] === "__ROOT__") {
        target["name"] = translate["World_wide"];
      }
      target["name"] =
        _reportParams.subject ?? target["name"].replace(",", " - ");

      wysiwygState["storage"] = _reportParams.storage;
      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "PEER";

      // Prepare data passed to action for use in wysiwyg
      wysiwygState.timeframe = _reportParams.timeframe;
      wysiwygState.trimOutliers = _reportParams.trimOutliers;
      wysiwygState.dispersionByConstraints =
        _reportParams.dispersionByConstraints;
      wysiwygState.segment = _reportParams.segment;
      wysiwygState.sortState = _reportParams.sortState;
      wysiwygState.title = _reportParams.title;
      wysiwygState.subject = _reportParams.subject;

      break;
    }
    case "ranking": {
      columnsAvailable = pageConfiguration.widgets.viewer["columns_available"];

      // Note from Elia
      //
      // ranking (the target) is a container of other targets.
      // At the moment (2020-04-10) I do not have other ideas
      // to manage it in a coherent way with others kind of
      // targets
      var _target = deepClone(target["target"]["object"]);
      _target["name"] = target["name"];

      wysiwygState["target"] = _target;
      wysiwygState["targetType"] =
        target["target"]["type"] === "list" ? "LIST" : "SCREENING";

      break;
    }
    case "screening": {
      columnsAvailable = pageConfiguration.widgets.viewer["columns_available"];

      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "SCREENING";

      break;
    }
    case "strategyBuilder":
    case "strategyLongShort": {
      columnsAvailable = pageConfiguration.widgets.viewer["columns_available"];

      // TODO - hard coded
      wysiwygState["actions"]["sortBy"] = {
        descending: true,
        property: "weight",
      };

      var columnSet = new ColumnSet({
        elements: {
          columns: pageConfiguration.widgets.viewer["columns"],
          columns_available:
            pageConfiguration.widgets.viewer["columns_available"],
        },
        properties: environmentSetup["properties"],
        customConfiguration: null,
      });
      wysiwygState["columns"] = columnSet.generate(null);

      wysiwygState["promiseCache"] = _reportParams.strategyCache;
      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "STRATEGY"; // "COMBINED_STRATEGY"
      wysiwygState["storage"] = _reportParams.storage;

      break;
    }
    case "systematicPortfolios": {
      columnsAvailable =
        pageConfiguration.tabs[1].widgets.viewer["columns_available"];

      columnSet = new ColumnSet({
        elements: {
          columns: pageConfiguration.tabs[1].widgets.viewer["columns"],
          columns_available:
            pageConfiguration.tabs[1].widgets.viewer["columns_available"],
        },
        properties: environmentSetup["properties"],
        customConfiguration: null,
      });
      wysiwygState["columns"] = columnSet.generate(null);

      // TODO - hard coded
      wysiwygState["actions"]["sortBy"] = {
        descending: true,
        property: "weight",
      };

      wysiwygState["target"] = target;
      wysiwygState["targetType"] = "SYSTEMATIC";

      break;
    } // no default
  }

  // constraints, sortBy and columns
  if (_reportParams.widgets != null && _reportParams.widgets["table"] != null) {
    wysiwygState["actions"]["constraints"] =
      _reportParams.widgets["table"].get("constraints");

    // Check for tables like holdings in portfolio analysis
    // Only if constraints are empty
    if (
      wysiwygState["actions"]["constraints"] == null &&
      _reportParams.widgets["table"] != null &&
      _reportParams.widgets["table"].widgetDataFilter != null
    ) {
      wysiwygState["actions"]["constraints"] =
        _reportParams.widgets["table"].widgetDataFilter.get("value");
    }

    let sortBy = _reportParams.widgets["table"].get("sort");
    //
    // 2021-06-11
    //
    // TODO This is legacy, maybe is not used anymore?
    if (sortBy == null) {
      sortBy = _reportParams.widgets["table"].get("sortBy");
    }
    wysiwygState["actions"]["sortBy"] = sortBy;

    wysiwygState["columns"] = _reportParams.widgets["table"].get("columns");
    wysiwygState["dataTotalCount"] =
      _reportParams.widgets["table"].get("dataTotalCount");
  }

  // TODO remove and refactor it with only wysiwygState["rankingCache"]
  if (_reportParams.rankingCache != null) {
    wysiwygState["actions"]["rank"] = {
      date: _reportParams.rankingCache["rankingParams"]["fromDate"],
      list: _reportParams.rankingCache["rankingParams"]["list"],
      rules: _reportParams.rankingCache["rankingParams"]["rules"],
      universe: _reportParams.rankingCache["rankingParams"]["againstUniverse"],
    };
  }

  // TODO patch, used in report Generator.js, please remove it later
  // and use only actions/rank
  wysiwygState["rankingCache"] = _reportParams.rankingCache;

  var uiState = {
    columnsAvailable: columnsAvailable,
    wysiwygState: wysiwygState,
  };

  if (appConfig.isDebug === true) {
    console.log("uiState", uiState);
  }

  return uiState;
};
