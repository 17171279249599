import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CombinedStrategies } from "../../../../api/compute/CombinedStrategies";
import { Strategies } from "../../../../api/compute/Strategies";
import { Subscriptions } from "../../../../api/compute/Subscriptions";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { httpAll } from "../../../../httpAll";
import { messageError, messageSuccess, removeLoader } from "../../utils";
import CompareEditor from "./CompareEditor";
import ListGeneric from "./ListGeneric";
import { DataPreparation } from "./_DataPreparation";
import { StrategiesList } from "./widgets/StrategiesList/StrategiesList";
import { useReport } from "../../../../hooks/useReport";
import { StrategiesStorage } from "../../storage/StrategiesStorage";
import { StrategySummaryModal } from "../../../../components/StrategySummary/StrategySummaryModal";
import { CompareStrategies } from "../../../../api/compute/CompareStrategies";
import { deepClone } from "../../../../deepClone";
import { CombinedStrategiesStorage } from "../../storage/CombinedStrategiesStorage";
import { Preferences } from "../../../../api/account/Preferences";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import { DialogRelations } from "../../components/app-infrastructure/workflowBar/actions/remove/Remove";

type StateType = {
  advanced: any;
  alpha: any;
  combined: any;
  compare: any;
  macroRotation: any;
  smartBeta: any;
};

const getKey = (type) => {
  switch (type) {
    case "BUILDER":
      return "advanced";
    case "ALPHA":
      return "alpha";
    case "SECTOR_ROTATION":
      return "macroRotation";
    case "SMART_BETA":
      return "macroRotation";
    case "COMBINED_STRATEGY":
      return "combined";
    default:
      return undefined;
  }
};

export default function ReactIndex() {
  // removing loader and setting tab title
  useEffect(() => {
    removeLoader();
  }, []);

  const dP = useMemo(() => new DataPreparation(), []);
  const navigate = useNavigate();
  const { directDoAction, setReportParams } = useReport({
    page: "strategyBuilder",
  });

  //#region
  const environment = useEnvironment();
  const envSetup = useMemo(() => environment.get("setup"), [environment]);
  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    try {
      const configuration = account?.product?.configuration?.strategy_builder;
      let title = configuration.menu_label.toLowerCase();
      const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
      document.title = titleCapitalized;
    } catch (error) {
      document.title = "Trend Rating";
    }
  }, [environment]);
  //#endregion
  const runManager = useMemo(() => new StrategiesStorage(envSetup), [envSetup]);
  //#region - setting tab title
  useEffect(() => {
    const account = environment.get("account");
    try {
      const configuration = account?.product?.configuration?.strategy_builder;
      let title = configuration.menu_label.toLowerCase();
      const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1);
      document.title = titleCapitalized;
    } catch (error) {
      document.title = "Trend Rating";
    }
  }, [environment]);
  //#endregion
  const combinedRunMAnager = useMemo(
    () =>
      new CombinedStrategiesStorage(envSetup, {
        startNew: () => {},
        update: () => {},
        complete: () => {},
      }),
    [envSetup]
  );

  const configurationBuilder = useMemo(
    () => envSetup["configuration"].get("strategyBuilder"),
    [envSetup]
  );
  //#endregion

  //#region APIS
  const combinedStrategiesAPI = useMemo(() => {
    return new CombinedStrategies(envSetup);
  }, [envSetup]);
  const strategiesAPI = useMemo(() => {
    return new Strategies(envSetup);
  }, [envSetup]);
  const subscriptionAPI = useMemo(() => {
    return new Subscriptions(envSetup);
  }, [envSetup]);

  const compareAPI = useMemo(() => {
    return new CompareStrategies(envSetup);
  }, [envSetup]);

  const preferencesAPI = useMemo(() => new Preferences(envSetup), [envSetup]);
  //#endregion

  const [state, setState] = useState<StateType | null>(null);
  const { broadcast } = useBroadcast();

  const getData = useCallback(async () => {
    const strategiesIDS = await strategiesAPI.getUserStrategiesIDs();
    let combinedIDS: number[] = await combinedStrategiesAPI.select(
      "COMBINED_STRATEGY"
    );
    let combinedSubscripted = await subscriptionAPI.get({
      type: "combinedStrategy",
    });

    combinedIDS = [
      ...combinedIDS,
      ...combinedSubscripted.map((item) => item.id),
    ];

    let alpha: any[] = [];
    let advanced: any[] = [];
    let macroRotation: any[] = [];
    let smartBeta: any[] = [];
    let combined: any[] = [];

    preferencesAPI
      .fetch({
        ids: [...strategiesIDS, ...combinedIDS],
        properties: ["type", "ownerId", "name", "object"],
        objectType: "PREFERENCE",
      })
      .then(
        (stuff) => {
          const appendToCategory = (type, obj) => {
            switch (type) {
              case "BUILDER":
                advanced.push(obj);
                break;
              case "SMART_BETA":
                smartBeta.push(obj);
                break;
              case "ALPHA":
                alpha.push(obj);
                break;
              case "SECTOR_ROTATION":
                macroRotation.push(obj);
                break;
              default:
                combined.push(obj);
                break;
            }
          };
          for (let i = 0; i < stuff.length; i++) {
            const item = stuff[i];
            const obj = {
              name: item.name,
              ownerId: item.ownerId,
              type: item.type,
              id: item.id,
              entity_type: item.object.entity_type,
            };

            if ("modified" in item.object) {
              obj["modified"] = item.object["modified"];
            } else {
              obj["modified"] = null;
            }

            appendToCategory(obj.entity_type, obj);
          }
          const _state = {
            advanced: advanced.sort(dP.sortByCaseInsensitive("name")),
            alpha: alpha.sort(dP.sortByCaseInsensitive("name")),
            combined:
              combined == null
                ? null
                : combined.sort(dP.sortByCaseInsensitive("name")),
            compare: null,
            macroRotation: macroRotation.sort(dP.sortByCaseInsensitive("name")),
            smartBeta: smartBeta.sort(dP.sortByCaseInsensitive("name")),
          };
          setState(_state);
        },
        (error) => {
          const _state = {
            advanced: advanced,
            alpha: alpha,
            combined: combined,
            compare: null,
            macroRotation: macroRotation,
            smartBeta: smartBeta,
          };
          setState(_state);
          const [channel, msg] = messageError("error on loading strategies");
          broadcast(channel as string, msg);
          console.error(error?.message ?? "");
        }
      );
  }, [
    broadcast,
    combinedStrategiesAPI,
    dP,
    preferencesAPI,
    strategiesAPI,
    subscriptionAPI,
  ]);

  //#region  INIT - detting initial data

  useEffect(() => {
    getData();
  }, [getData]);
  //#endregion

  const _listenerCreate = useCallback(
    (strategyType) => {
      var uri = "/404/";

      switch (strategyType) {
        case "advanced":
        case "alpha":
        case "macroRotation":
        case "smartBeta": {
          uri = `/app/strategies/builder/${dP.strategyTypeToUriFragment(
            strategyType
          )}/new`;

          break;
        }
        case "combined": {
          uri = "/app/strategies/combined/";

          break;
        } // no default
      }

      navigate(uri);
    },
    [dP, navigate]
  );

  const _getCombinedReportAdjustStrategies = useCallback(
    (strategyCombined, responseGet) => {
      var paramsCompute = {
        strategy1: null,
        strategy2: null,
      };

      // #region ---------------------------- Adjust strategy 1 parameters
      var strategy1 = deepClone(responseGet.strategy1);
      // Adjust benchmark
      var benchmark: any = null;
      if (strategyCombined["benchmark"] == null) {
        // Used to remove benchmark on strategies
        benchmark = null;
      } else if (typeof strategyCombined["benchmark"] === "string") {
        benchmark = strategyCombined["benchmark"];
      } else if (strategyCombined["benchmark"]["item"] != null) {
        benchmark = strategyCombined["benchmark"]["item"]["symbol"];
      }
      strategy1["params"]["strategy"]["benchmark"] = benchmark;
      // Adjust currency, if missing do not change the
      // strategy value
      if (strategyCombined["currency"] != null) {
        strategy1["params"]["strategy"]["currency"] =
          strategyCombined["currency"];
      }
      // Adjust period, if missing do not change the
      // strategy value
      if (strategyCombined["period"] != null) {
        strategy1["params"]["backtesting"]["period"] =
          strategyCombined["period"];
      }

      paramsCompute["strategy1"] = strategy1;
      // #endregion ------------------------------------------------------

      // #region ---------------------------- Adjust strategy 2 parameters
      var strategy2 = deepClone(responseGet.strategy2);
      // Erase the benchmark on strategy 2
      strategy2["params"]["strategy"]["benchmark"] = null;
      // Adjust currency, if missing use the one from
      // strategy 1
      if (strategyCombined["currency"] != null) {
        strategy2["params"]["strategy"]["currency"] =
          strategyCombined["currency"];
      } else {
        strategy2["params"]["strategy"]["currency"] =
          strategyCombined["params"]["strategy"]["currency"];
      }
      // Adjust period, if missing use the one from
      // strategy 1
      if (strategyCombined["period"] != null) {
        strategy2["params"]["backtesting"]["period"] =
          strategyCombined["period"];
      } else {
        strategy2["params"]["backtesting"]["period"] =
          strategy1["params"]["backtesting"]["period"];
      }

      paramsCompute["strategy2"] = strategy2;
      // #endregion ------------------------------------------------------

      return paramsCompute;
    },
    []
  );

  const getCombinedReportData = useCallback(
    async (strategyCombined) => {
      let _strategyCombined = { ...strategyCombined };

      //#region - if strategyCombines is shared then load full data.
      if (
        "isReadOnly" in strategyCombined &&
        strategyCombined.isReadOnly === true
      ) {
        _strategyCombined = await combinedStrategiesAPI.get(
          strategyCombined.id
        );
      }
      //#endregion

      return httpAll({
        strategy1: strategiesAPI.getById(_strategyCombined.strategy1.id),
        strategy2: strategiesAPI.getById(_strategyCombined.strategy2.id),
      }).then((responseGet) => {
        var strategiesAdjusted: any = _getCombinedReportAdjustStrategies(
          _strategyCombined,
          responseGet
        );

        var params = {
          benchmark: _strategyCombined["benchmark"],
          strategy1: strategiesAdjusted["strategy1"],
          percentage1: _strategyCombined["strategy1"]["value"],
          period: _strategyCombined["period"],
          strategy2: strategiesAdjusted["strategy2"],
          percentage2: _strategyCombined["strategy2"]["value"],
        };

        var currentRun = strategiesAPI.longShort(params).then((response) => {
          return {
            data: response["combined"],
          };
        });

        var strategyProxyForReport = deepClone(strategiesAdjusted["strategy1"]);
        var name =
          _strategyCombined.name == null
            ? strategiesAdjusted["strategy1"]["name"] +
              " - " +
              strategiesAdjusted["strategy2"]["name"]
            : _strategyCombined.name;
        strategyProxyForReport["name"] = name;

        var data = {
          currentRun: currentRun,
          strategyCombined: _strategyCombined,
          strategyProxyForReport: strategyProxyForReport,
        };

        return data;
      });
    },
    [_getCombinedReportAdjustStrategies, combinedStrategiesAPI, strategiesAPI]
  );

  const [strategyToExpand, setStrategyToSummary] = useState(undefined);
  const onCloseStrategySummaryDialog = useCallback(() => {
    setStrategyToSummary(undefined);
  }, []);

  const getWholeObj = useCallback(
    async (obj) => {
      // strategy has entity_type property, combined has type property
      const type = obj.entity_type ? obj.entity_type : obj.type;
      let entireObject: any = undefined;
      switch (type) {
        case "BUILDER":
        case "ALPHA":
        case "SECTOR_ROTATION":
        case "SMART_BETA":
          entireObject = await strategiesAPI.getById(obj.id);
          break;
        case "COMBINED_STRATEGY":
          entireObject = await combinedStrategiesAPI.get(obj.id);
      }
      return entireObject;
    },
    [combinedStrategiesAPI, strategiesAPI]
  );

  const removeObjFromState = useCallback(
    (obj) => {
      // strategy has entity_type property, combined has type property
      const type = obj.entity_type ? obj.entity_type : obj.type;
      let _key = getKey(type);
      const _state: any = state ? deepClone(state) : null;
      if (_state && _key) {
        _state[_key] = _state[_key].filter((item) => item.id !== obj.id);
        setState(_state);
      }
    },
    [state]
  );

  const [removeStrategyTarget, setRemoveStrategyTarget] = useState<any>();
  const showRelationsDialog = useMemo(
    () => removeStrategyTarget != null,
    [removeStrategyTarget]
  );

  const onRemoveStrategy = useCallback(
    async (strategy) => {
      const entireObj = await getWholeObj(strategy);
      setRemoveStrategyTarget(entireObj);
    },
    [getWholeObj]
  );

  const closeDialogRelations = useCallback(() => {
    setRemoveStrategyTarget(undefined);
  }, []);

  const onReport = useCallback(
    async (strategy) => {
      const _strategy = await getWholeObj(strategy);
      switch (strategy.type) {
        case "COMBINED_STRATEGY": {
          let reportData = await getCombinedReportData(_strategy);
          const resource = reportData.strategyCombined;

          if (resource) {
            const dataForTable: any = [];

            for (const key in resource) {
              if (key.startsWith("strategy")) {
                dataForTable.push({
                  id: resource[key]["id"],
                  weight: resource[key]["value"],
                });
              }
            }

            combinedRunMAnager.setEntitites(dataForTable);

            const backtestParams = {
              benchmark: resource.benchmark?.item?.symbol ?? null,
              currency: resource.currency,
              period: {
                period: {
                  type: resource?.period?.type ?? "YEAR",
                  value: resource?.period?.value ?? 8,
                },
              },
            };

            combinedRunMAnager.setCombineParams(backtestParams);
          }
          await combinedRunMAnager.prepareEntitiesForCombine();

          const _valueToSet: any = {
            page: "strategyLongShort",
            rankingCache: null,
            target: resource,
            title: resource.name,
            usage: {
              function: "STRATEGY_LONG_SHORT",
            },
            storage: combinedRunMAnager,
            widgets: null,
          };

          setReportParams(_valueToSet);
          setTimeout(() => {
            directDoAction(_valueToSet);
          });

          // *************** USAGE ***************
          const usage = window.App.usage;
          const info = {
            action: "REPORT",
            actionParam: _strategy.id,
            function: "STRATEGY_LONG_SHORT",
          };
          usage.record(info);
          // *************** USAGE ***************
          break;
        }

        case "ALPHA":
        case "BUILDER":
        case "SECTOR_ROTATION":
        case "SMART_BETA":
        default: {
          runManager.set(_strategy, {
            startNew: () => {},
            update: () => {},
            complete: () => {},
          });
          await runManager.processStrategy();
          const valueToSet: any = {
            strategyCache: null,
            rankingCache: null,
            page: "strategyBuilder",
            target: _strategy,
            storage: runManager,
            usage: { function: "STRATEGY_BUILDER" },
            name: _strategy.name,
            widgets: null,
          };

          setReportParams(valueToSet);
          setTimeout(() => {
            directDoAction(valueToSet);
          });
          // *********************** USAGE ***********************
          const usage = window.App.usage;
          const info = {
            action: "REPORT",
            actionParam: _strategy.id,
            function: "STRATEGY_BUILDER",
          };
          usage.record(info);
          // *********************** USAGE ***********************
          break;
        }
      }
    },
    [
      combinedRunMAnager,
      directDoAction,
      getCombinedReportData,
      getWholeObj,
      runManager,
      setReportParams,
    ]
  );

  const onRename = useCallback(
    (obj) => {
      const type = obj.entity_type ? obj.entity_type : obj.type;
      let _key = getKey(type);
      let _state = state ? deepClone(state) : null;
      if (_key && _state) {
        const index = _state[_key].findIndex((item) => item.id === obj.id);
        // removing the keys we don't need
        const keys = Object.keys(_state[_key][index]);
        const newObj = {};
        for (let i = 0; i < keys.length; i++) {
          newObj[keys[i]] = obj[keys[i]];
        }
        //----------------------------
        _state[_key][index] = newObj;
        setState(_state);
      }
    },
    [state]
  );

  //#region CARDs
  const advancedStrategyCard = useMemo(() => {
    if (configurationBuilder["formAdvanced"]["enabled"] === true) {
      return (
        <ListGeneric
          headerTitle={"Advanced strategy"}
          description=""
          logoURL={"/img/advanced.png"}
          actionButton={() => _listenerCreate("advanced")}
          actionButtonTitle={"Create Advanced Strategy"}
          child={
            state?.advanced.length > 0 ? (
              <>
                <StrategySummaryModal
                  strategyId={strategyToExpand}
                  onClose={onCloseStrategySummaryDialog}
                />
                <StrategiesList
                  params={{ items: state?.advanced }}
                  listenerRemove={onRemoveStrategy}
                  listenerReport={onReport}
                  listenerSummary={(strategy) => {
                    setStrategyToSummary(strategy.id);
                  }}
                  strategyType={"advanced"}
                  onRenameCallback={onRename}
                />
              </>
            ) : (
              <Box
                width={"100%"}
                height={"100%"}
                p={2}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Typography align="center">
                  Create your first advanced strategy!
                </Typography>
              </Box>
            )
          }
        />
      );
    }
    return null;
  }, [
    _listenerCreate,
    configurationBuilder,
    onCloseStrategySummaryDialog,
    onRemoveStrategy,
    onRename,
    onReport,
    state?.advanced,
    strategyToExpand,
  ]);

  const combinedStrategiesCard = useMemo(() => {
    if (configurationBuilder["formLongShort"]["enabled"] === true) {
      return (
        <ListGeneric
          headerTitle={"Combined Strategies"}
          description={""}
          logoURL={"/img/longshort.png"}
          actionButton={() => _listenerCreate("combined")}
          actionButtonTitle={"Create Combined Strategies"}
          child={
            state?.combined.length > 0 ? (
              <StrategiesList
                params={{ items: state?.combined }}
                listenerRemove={onRemoveStrategy}
                listenerReport={onReport}
                listenerSummary={undefined}
                strategyType={"combined"}
                onRenameCallback={onRename}
              />
            ) : (
              <Box
                width={"100%"}
                height={"100%"}
                p={2}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Typography align="center">
                  Create your first combined strategy!
                </Typography>
              </Box>
            )
          }
        />
      );
    }
    return null;
  }, [
    _listenerCreate,
    configurationBuilder,
    onRemoveStrategy,
    onRename,
    onReport,
    state?.combined,
  ]);

  const [keyToUpdateCompare, setKeyToUpdateCompare] = useState(
    Date.now().toString()
  );
  const compareStrategiesCard = useMemo(() => {
    if (configurationBuilder["formCompare"]["enabled"] === true) {
      return (
        <ListGeneric
          key={keyToUpdateCompare}
          headerTitle={"Compare Strategies"}
          description=""
          logoURL={"/img/strategy_compare.png"}
          actionButton={undefined}
          actionButtonTitle={"Compare Strategies"}
          child={
            <Box px={2}>
              <CompareEditor
                onRun={(id1, id2) => {
                  let uri = `/app/strategies/compare/${id1}/${id2}/run`;
                  navigate(uri);
                }}
                onDelete={(e) => {
                  compareAPI.remove(e).then((response: any) => {
                    if (response.removed) {
                      const [channel, msg] = messageSuccess(
                        `${e.name} successfully removed!`
                      );
                      broadcast(channel as string, msg);
                      getData();
                    } else {
                      const [channel, msg] = messageError(
                        `could not remove ${e.name}!`
                      );
                      broadcast(channel as string, msg);
                    }
                  });
                }}
                onRename={() => {
                  // getData();
                  //! instead of refresh with getData() that will refresh state
                  //! we refresh only the widget
                  //! this is needed because CompareList in CompareEditor
                  //! refreshes the list only when it's loaded the first time
                  setKeyToUpdateCompare(Date.now().toString());
                }}
              />
            </Box>
          }
        />
      );
    }
    return null;
  }, [
    broadcast,
    compareAPI,
    configurationBuilder,
    getData,
    keyToUpdateCompare,
    navigate,
  ]);

  const alphaStrategyCard = useMemo(() => {
    if (configurationBuilder["formAlpha"]["enabled"] === true) {
      return (
        <ListGeneric
          headerTitle={"Alpha strategy"}
          description="Stocks selection based on Trend Ratings"
          logoURL={"/img/alpha.png"}
          actionButton={() => _listenerCreate("alpha")}
          actionButtonTitle={"Create Alpha Strategy"}
          child={
            state?.alpha.length > 0 ? (
              <StrategiesList
                params={{ items: state?.alpha }}
                listenerRemove={onRemoveStrategy}
                listenerReport={onReport}
                listenerSummary={(strategy) => {
                  setStrategyToSummary(strategy.id);
                }}
                strategyType={"alpha"}
                onRenameCallback={onRename}
              />
            ) : (
              <Box
                width={"100%"}
                height={"100%"}
                p={2}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Typography align="center">
                  Create your first alpha strategy!
                </Typography>
              </Box>
            )
          }
        />
      );
    }
    return null;
  }, [
    _listenerCreate,
    configurationBuilder,
    onRemoveStrategy,
    onRename,
    onReport,
    state?.alpha,
  ]);

  const smartBetaStrategyCard = useMemo(() => {
    if (configurationBuilder["formSmartBeta"]["enabled"] === true) {
      return (
        <ListGeneric
          headerTitle={"Smart Beta strategy"}
          description="Strategy weighting based on Trend Rating"
          logoURL={"/img/smart_beta.png"}
          actionButton={() => _listenerCreate("smartBeta")}
          actionButtonTitle={"Create Smart Beta Strategy"}
          child={
            state?.smartBeta.length > 0 ? (
              <StrategiesList
                params={{ items: state?.smartBeta }}
                listenerRemove={onRemoveStrategy}
                listenerReport={onReport}
                listenerSummary={(strategy) => {
                  setStrategyToSummary(strategy.id);
                }}
                strategyType={"smartBeta"}
                onRenameCallback={onRename}
              />
            ) : (
              <Box
                width={"100%"}
                height={"100%"}
                p={2}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Typography align="center">
                  Create your first smart beta strategy!
                </Typography>
              </Box>
            )
          }
        />
      );
    }
    return null;
  }, [
    _listenerCreate,
    configurationBuilder,
    onRemoveStrategy,
    onRename,
    onReport,
    state?.smartBeta,
  ]);

  const macroRotationStrategyCard = useMemo(() => {
    if (configurationBuilder["formMacroRotation"]["enabled"] === true) {
      return (
        <ListGeneric
          headerTitle={"Macro Rotation strategy"}
          description="Allocation based on Macro Trends"
          logoURL={"/img/sector_rotation.png"}
          actionButton={() => _listenerCreate("macroRotation")}
          actionButtonTitle={"Create Macro Rotation Strategy"}
          child={
            state?.macroRotation.length > 0 ? (
              <StrategiesList
                params={{ items: state?.macroRotation }}
                listenerRemove={onRemoveStrategy}
                listenerReport={onReport}
                listenerSummary={(strategy) => {
                  setStrategyToSummary(strategy.id);
                }}
                strategyType={"macroRotation"}
                onRenameCallback={onRename}
              />
            ) : (
              <Box
                width={"100%"}
                height={"100%"}
                p={2}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Typography align="center">
                  Create your first macro rotation strategy!
                </Typography>
              </Box>
            )
          }
        />
      );
    }
    return null;
  }, [
    _listenerCreate,
    configurationBuilder,
    onRemoveStrategy,
    onRename,
    onReport,
    state?.macroRotation,
  ]);

  //#endregion

  return (
    <Box
      p={1}
      display={"flex"}
      width={"100%"}
      height={"100%"}
      overflow={"hidden"}
      gap={2}
    >
      {showRelationsDialog && (
        <DialogRelations
          close={closeDialogRelations}
          itemToDelete={removeStrategyTarget}
          onRemoveDone={(e) => removeObjFromState(e.value)}
          isUnsubscribe={false}
        />
      )}
      {advancedStrategyCard}
      {combinedStrategiesCard}
      {compareStrategiesCard}
      {alphaStrategyCard}
      {smartBetaStrategyCard}
      {macroRotationStrategyCard}
    </Box>
  );
}
