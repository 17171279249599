export type ControlsState = {
  segment:
    | "Country"
    | "1 Industry"
    | "3 Sector"
    | "Currency"
    | "3 Level"
    | "Area"
    | "Region"
    | "all"
    | "Type";
  intervals: 25 | 10 | 5;
  period: "3_months" | "6_months" | "12_months";
  viewType: "histogram" | "range";
  trimOutliers: boolean;
  etfSegment: "etfgeo" | "etfclass" | "specialty" | "all_etf";
};

// Default state values
export const state: ControlsState = {
  segment: "all",
  intervals: 25,
  period: "3_months",
  viewType: "histogram",
  trimOutliers: false,
  etfSegment: "all_etf",
};

type Action =
  | {
      type: "SET_SEGMENT";
      payload: ControlsState["segment"];
    }
  | {
      type: "SET_ETF_SEGMENT";
      payload: ControlsState["etfSegment"];
    }
  | {
      type: "SET_INTERVAL";
      payload: ControlsState["intervals"];
    }
  | {
      type: "SET_PERIOD";
      payload: ControlsState["period"];
    }
  | {
      type: "SET_VIEW_TYPE";
      payload: ControlsState["viewType"];
    }
  | {
      type: "SET_TRIM_OUTLIERS";
      payload: boolean;
    };

export const controlsReducer = (draft: ControlsState, action: Action) => {
  switch (action["type"]) {
    case "SET_SEGMENT":
      draft["segment"] = action["payload"];

      break;

    case "SET_ETF_SEGMENT":
      draft["etfSegment"] = action["payload"];

      break;

    case "SET_INTERVAL":
      draft["intervals"] = action["payload"];

      break;

    case "SET_PERIOD":
      draft["period"] = action["payload"];

      break;

    case "SET_VIEW_TYPE":
      draft["viewType"] = action["payload"];

      break;

    case "SET_TRIM_OUTLIERS":
      draft["trimOutliers"] = action["payload"];

      break;

    default:
      return draft;
  }
};
