import { useContext } from "react";
import { AppContext, AppEnvironmentContext } from "../AppContextProvider";
import { SystemContext } from "./useSystem";

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error(
            `useAppContext must be called within AppContextProvider`
        );
    }
    return context;
};

export const useEarlyEnvironment = () => {
    const context = useContext(AppEnvironmentContext);
    if (context === undefined) {
        throw new Error(
            `useEarlyEnvironment must be called within AppContextProvider`
        );
    }
    return context.environment;
};

export const useEnvironment = () => {
    const context = useContext(SystemContext);
    if (context === undefined) {
        throw new Error(
            `useEnvironment must be called within AppContextProvider`
        );
    }
    return context.state.environment;
};

export const useAppEnvironmentContext = () => {
    const context = useContext(AppEnvironmentContext);
    if (context === undefined) {
        throw new Error(
            `useEnvironment must be called within AppContextProvider`
        );
    }
    return context;
};
