import { useContext, useCallback } from "react";
import RatingCheckbox from "../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { FiltersContext } from "../../FilterRowContraints";

export function RangeRateFilter() {
    const context = useContext(FiltersContext);
    const initState = context?.ratingFilterState ?? {
        A: false,
        B: false,
        C: false,
        D: false,
    };

    const updateRatingFilter = useCallback(
        (value: { A: boolean; B: boolean; C: boolean; D: boolean }) => {
            if (
                JSON.stringify(value) !==
                JSON.stringify(context?.ratingFilterState)
            ) {
                context?.updateRatingFilter(value);
            }
        },
        [context]
    );

    return (
        <RatingCheckbox
            initState={initState}
            stateGetter={updateRatingFilter}
        />
    );
}
