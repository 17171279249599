export function Loader({ message }) {
    return (
        <div className="appLoaderAnimationWrapper">
            <div id="data-loader" className="loader loader--data">
                <div className="loader__message" id="data-loader-message">
                    {message}
                </div>
                <div className="appLoaderAnimation" id="app-loader-animation">
                    <div className="appLoaderAnimation-bar"></div>
                </div>
                <div
                    className="loader__message-more"
                    id="data-loader-message-more"
                ></div>
            </div>
        </div>
    );
}
