import { useCallback, useEffect, useMemo, useState } from "react";
import { Environment } from "../../../Environment";
import Modal from "../../Modal/Modal";
import { TooltipContent } from "../../SecurityTooltip/SecurityTooltipCore";
import { Instruments } from "../../../api/compute/Instruments";

type TableTooltipProps = {
  environment: Environment;
  showTooltip: boolean;
  closeTooltip: () => void;
  symbol: string;
  handleChartAppearence: (value) => void;
};

const PROPERTIES = [
  { date: null, property: "type" },
  { date: null, property: "name" },
  { date: null, property: "ticker" },
  { date: null, property: "symbol" },
  { date: null, property: "country" },
  { date: null, property: "etfgeo" },
  { date: null, property: "icb" },
  { date: null, property: "etfclass" },
  { date: null, property: "vc" },
  { date: null, property: "dc" },
  { date: null, property: "lhl" },
  { date: null, property: "rc" },
  { date: null, property: "dr" },
  { date: null, property: "currency" },
  { date: null, property: "marketcap" },
  { date: null, property: "domicile" },
];

export function TableTooltip({
  environment,
  showTooltip,
  closeTooltip,
  symbol,
  handleChartAppearence,
}: TableTooltipProps) {
  const [data, setData] = useState<any>();

  const instrumentsAPI = useMemo(() => {
    const setup = environment.get("setup");
    return new Instruments(setup);
  }, [environment]);

  const getSecurityInfo = useCallback(async () => {
    if (symbol && symbol.length) {
      try {
        const response = await instrumentsAPI.fetch(
          {
            properties: PROPERTIES,
            symbols: [symbol],
            type: "security",
          },
          true
        );

        setData(response?.data?.[0] ?? undefined);
      } catch (error) {
        console.log(error);
      }
    }
  }, [instrumentsAPI, symbol]);

  const handleChartBtnClick = useCallback(() => {
    handleChartAppearence(data);
    closeTooltip();
  }, [closeTooltip, data, handleChartAppearence]);

  // Fetch security info on page load
  useEffect(() => {
    getSecurityInfo();
  }, [getSecurityInfo]);

  return showTooltip ? (
    <Modal
      id={"trendrating__table__tooltip"}
      headerConfig={{
        headerContent: <></>,
      }}
      isDraggable
      hasOverlay={false}
      closeOnClickAway={true}
      onClose={closeTooltip}
      bodyCustomClass={"no-padding__body"}
      customCss={{ minWidth: "30em", maxWidth: 0, width: "auto" }}
      closeIcon={true}
    >
      <TooltipContent
        isLoading={false}
        security={data}
        environment={environment}
        showContextMenu={false}
        setShowContextMenu={() => {}}
        chartButtonHandler={handleChartBtnClick}
        tooltipRef={undefined}
        enableAddToAction={false}
        error={false}
      />
    </Modal>
  ) : (
    <></>
  );
}
