import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ColumnDefinition } from "tabulator-tables";
import { v4 as uuidv4 } from "uuid";
import { Instruments } from "../../api/compute/Instruments";
import { Lists } from "../../api/compute/Lists";
import { extractSymbols } from "../../api/compute/commons";
import { useBroadcast } from "../../hooks/useBroadcast";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useEventBus } from "../../hooks/useEventBus";
import { messageError, messageSuccess } from "../../js/app/utils";
import Paginator from "../Paginator/Paginator";
import { PanelForLists } from "../PanelForLists/PanelForLists";
import CreateListDialog from "../SecurityTooltip/CreateListDialog";
import { newGetSymbols } from "../SecurityTooltip/SecurityTooltipCore";
import {
  TableColumnsEditor,
  TableColumnsEditorProps,
} from "../table/ColumnsEditor/TableColumnsEditor";
import {
  TrendratingTable,
  TrendratingTableProps,
} from "../table/TrendratingTable";
import { TableHelpers } from "./Helpers/TableHelpers";
import styles from "./InstrumentsTable.module.scss";
import { InstrumentsBoxLists } from "./widgets/InstrumentsBoxList/InstrumentsBoxLists";

type InstrumentsTableProps = {
  tableProps: Omit<TrendratingTableProps, "eventCallback" | "columns" | "data">;
  useAutoSort?: boolean;
  tools?: {
    addToButton?: boolean;
    expandTable?: { eventName: string };
    configurator?: Omit<TableColumnsEditorProps, "setTableColumns">;
    customTools?: {
      children?: JSX.Element;
    };
    pagination?: {
      dataTotalCount: number;
      itemsPerPage: number;
      sortUniverse?: any[];
      changePage: (page: number) => any;
    };
    rowsNumberSelect?: {
      onChangeItemsPerPage: (value: number) => void;
      label?: string;
      initialValue?: number;
    };
    viewAsListButton?: boolean;
    rank?: {
      showRankTools: boolean;
      onChangeRankDate: (arg) => void;
      dateInitValue?:
        | "PREVIOUS_DAY"
        | "PREVIOUS_WEEK"
        | "PREVIOUS_2_WEEKS"
        | "PREVIOUS_MONTH"
        | "PREVIOUS_3_MONTHS";
    };
  };
  tableHandlersOverride?: { [tableEventName: string]: Function };
  columns?: any[];
  tableData: any[];
  onColumnsChange?: (cols: ColumnDefinition[]) => any;
  getInitColumns?: (cols: ColumnDefinition[]) => any;
  handleSortResult?: (sortedData, sortField, sortDirection) => any;
  handleSortManually?: (sorter: {
    direction: "asc" | "desc";
    field: string;
  }) => any;
  activeFilters?: {
    filter: [{ dimension: string; segments: any[] }];
    type: "fitlers" | "ranges";
  }[];
  loading?: boolean;
  rankPage?: "screening" | "rank" | "analysisList";

  headerMenuActionsListener?: (
    payload: {
      action: string;
      field: string;
    },
    column
  ) => void;
};

export const InstrumentsTable = forwardRef(
  (
    {
      tableProps,
      tools,
      columns,
      useAutoSort,
      onColumnsChange,
      tableData,
      getInitColumns,
      handleSortResult,
      activeFilters,
      handleSortManually,
      tableHandlersOverride,
      loading,
      rankPage,
      headerMenuActionsListener,
    }: InstrumentsTableProps,
    ref
  ) => {
    const environment = useEnvironment();
    const [expanded, setExpanded] = useState(false);
    const [data, setData] = useState<any>(tableData ?? []);
    // const [, setFields] = useState<string[]>([]);
    const [cols, setCols] = useState<ColumnDefinition[]>([]);
    const [paginationReset, triggerPaginationReset] = useState(Date.now());
    const [loadingData, setIsLoadingData] = useState(false);
    const [viewAs, setViewAs] = useState<"table" | "list">("table");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [showPortfolioModal, setShowPortfolioModal] = useState(false);
    const [showBasketModal, setShowBasketModal] = useState(false);
    const [panelList, setPanelList] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userCollections, setUserCollection] = useState<any>([]);
    const [rankHighlight, setRankHighlight] = useState(true);

    const fieldsRef = useRef([]);

    const updateFields = useCallback((values) => {
      // setFields(values);
      fieldsRef.current = values;
    }, []);

    const open = Boolean(anchorEl);
    const listsAPI = useMemo(
      () => new Lists(environment.get("setup")),
      [environment]
    );
    const helper = useMemo(
      () => new TableHelpers(environment.get("setup")),
      [environment]
    );

    const handleClickAddTo = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      },
      []
    );

    const handleClose = useCallback((e) => {
      e.stopPropagation();
      setAnchorEl(null);
    }, []);

    const { dispatch } = useEventBus();
    const tableRef = useRef<any>();

    const dispatchHeaderMenuAction = useCallback(
      (payload: { action: string; field: string }, column) => {
        if (headerMenuActionsListener) {
          headerMenuActionsListener(payload, column);
        }
      },
      [headerMenuActionsListener]
    );

    useImperativeHandle(
      ref,
      () => {
        return { getInstance: () => tableRef.current.getTabulatorRef() };
      },
      []
    );

    const addToDisabled = useMemo(
      () => selectedRows.length === 0,
      [selectedRows.length]
    );

    const instrumentsAPI = useMemo(
      () => new Instruments(environment.get("setup")),
      [environment]
    );
    const propertiesMap = useMemo(() => {
      const propertiesDivided = environment.get("properties")?.properties;

      if (propertiesDivided) {
        return {
          ...propertiesDivided.etf,
          ...propertiesDivided.index,
          ...propertiesDivided.security,
          ...propertiesDivided.stock,
        };
      }

      return {};
    }, [environment]);

    const serverSortHandler = useCallback(
      async (sortEvent) => {
        setIsLoadingData(true);
        let fieldsFromState: any = fieldsRef.current;
        const properties: any = [];

        for (const field of fieldsFromState) {
          if (field) {
            properties.push({
              date: null,
              property: field,
            });
          }
        }

        let actualData: any = null;
        let symbols: string[] = [];

        if (tools?.pagination == null) {
          setData((current) => {
            symbols = extractSymbols(current);
            actualData = [...current];
            return current;
          });
        } else {
          actualData = tools.pagination.sortUniverse;
          symbols = extractSymbols(tools.pagination?.sortUniverse ?? []);
        }

        const params: any = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],

          page: { page: 1, rows: 20000 },
        };

        if (activeFilters && activeFilters.length) {
          for (const filterObj of activeFilters) {
            if (!(filterObj["type"] in params)) {
              params[filterObj["type"]] = [];
            }

            params[filterObj["type"]] = [
              ...params[filterObj["type"]],
              ...filterObj.filter,
            ];
          }
        }

        const sortField = sortEvent?.value?.field;
        const direction = sortEvent?.value?.direction ?? "asc";

        if (sortField) {
          const propertyField = propertiesMap[sortField];

          if (propertyField) {
            if (
              "requireInjection" in propertyField &&
              propertyField.requireInjection
            ) {
              const sortId = `${sortField}${Date.now()}:${sortField}`;
              params["sort"] = [
                { dimension: sortId, rev: direction === "desc" },
                { dimension: "marketcap", rev: true },
              ];
              params["injestion"] = {
                data: actualData.map((item) => ({
                  symbol: item.symbol,
                  value: JSON.stringify(item[sortField]),
                })),
                type: "number",
                field: sortId,
              };
            } else {
              params["sort"] = [
                {
                  dimension: propertyField.backendPropertySort,
                  rev: direction === "desc",
                },
              ];
            }
          }
        }

        try {
          const response = await instrumentsAPI.newFilterAndFetch(
            params,
            "security",
            properties
          );

          if (response && response.data) {
            if (handleSortResult) {
              handleSortResult(response.data, sortField, direction);
            } else {
              setData(response.data);
            }
          }

          triggerPaginationReset(Date.now());
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingData(false);
        }
      },

      [
        activeFilters,
        handleSortResult,
        instrumentsAPI,
        propertiesMap,
        tools?.pagination,
      ]
    );

    const onChangeRankHighlight = useCallback(
      () => setRankHighlight((current) => !current),
      []
    );

    const customSortBehaviour = useCallback(
      (sortEvent) => {
        if (handleSortManually) {
          handleSortManually(sortEvent.value);
          triggerPaginationReset(Date.now());
        } else {
          console.warn(
            "A custom sorter event handler is invoked but no handler was passed to InstrumentTable component"
          );
        }
      },
      [handleSortManually]
    );

    const tranformToTabulatorCols = useCallback(
      (cols) => {
        const rankColumns = helper.get("rank");
        const common = helper.get("columns");

        let columnsToSet: any = [];

        if (cols) {
          const tableColumns = cols;

          columnsToSet = [];

          for (const viewerCol of tableColumns) {
            if ("customColConfiguration" in viewerCol) {
              switch (viewerCol.customColConfiguration) {
                case "rankConfiguration": {
                  rankColumns.configureAsRankCol(
                    viewerCol,
                    columnsToSet,
                    customSortBehaviour,
                    rankHighlight
                  );

                  break;
                }
                default:
                  console.warn(
                    `${viewerCol.customColConfiguration} is not a valid configuration for columns`
                  );
              }
            } else {
              const sortHandler =
                useAutoSort === true ? serverSortHandler : customSortBehaviour;

              const headerDispatcher =
                headerMenuActionsListener != null
                  ? dispatchHeaderMenuAction
                  : undefined;

              columnsToSet.push(
                common.tabulatorColumn(viewerCol, sortHandler, headerDispatcher)
              );
            }
          }

          if (tools?.addToButton === true) {
            columnsToSet.unshift({
              title: "",
              formatter: "rowSelection",
              widthGrow: 0.5,
              hozAlign: "center",
              headerHozAlign: "center",
              headerSort: false,
            });
          }
        }

        return columnsToSet;
      },
      [
        customSortBehaviour,
        dispatchHeaderMenuAction,
        headerMenuActionsListener,
        helper,
        rankHighlight,
        serverSortHandler,
        tools?.addToButton,
        useAutoSort,
      ]
    );

    const updateTableColumns = useCallback(
      (cols) => {
        setCols((current) => {
          const newCols = tranformToTabulatorCols(cols.tableColumns);
          if (JSON.stringify(current) === JSON.stringify(newCols)) {
            return current;
          } else {
            return newCols;
          }
        });

        if (onColumnsChange) {
          onColumnsChange(cols.tableColumns);
        }
      },
      [onColumnsChange, tranformToTabulatorCols]
    );

    const toggleView = useCallback(() => {
      setViewAs((currentValue) => {
        if (currentValue === "list") {
          return "table";
        } else {
          return "list";
        }
      });
    }, []);

    const checkForOverrides = useCallback(
      (e, defaultHandler?) => {
        if (tableHandlersOverride) {
          const eventType = e.type;
          const lookup = eventType in tableHandlersOverride;

          if (lookup) {
            tableHandlersOverride[e.type](e);

            return;
          }
        }

        if (defaultHandler) {
          defaultHandler(e);
        }
      },
      [tableHandlersOverride]
    );

    const onRowSelectionDefault = useCallback((e) => {
      const data = e?.value?.getData();

      if (data) {
        setSelectedRows((currentSelectedRows) => [
          ...currentSelectedRows,
          data,
        ]);
      }
    }, []);

    const onRowDeselectionDefault = useCallback((e) => {
      const data = e?.value?.getData();

      if (data) {
        setSelectedRows((currentSelectedRows) => {
          const selectedRowsCopy = [...currentSelectedRows];

          return selectedRowsCopy.filter((row) => row.symbol !== data.symbol);
        });
      }
    }, []);

    const handleTableEvents = useCallback(
      (e) => {
        switch (e.type) {
          case "rowClick": {
            checkForOverrides(e);
            break;
          }

          case "rowSelected": {
            checkForOverrides(e, onRowSelectionDefault);

            break;
          }

          case "rowDeselected": {
            checkForOverrides(e, onRowDeselectionDefault);
          }
        }
      },
      [checkForOverrides, onRowDeselectionDefault, onRowSelectionDefault]
    );

    useEffect(() => {
      setCols((currentCols) => {
        const columnsHelper = helper.get("columns");
        const newCols = currentCols.map((col) => {
          const sorter =
            useAutoSort === true ? serverSortHandler : customSortBehaviour;
          return columnsHelper.addCustomColumnSorter(col, sorter);
        });

        if (JSON.stringify(currentCols) === JSON.stringify(newCols)) {
          return currentCols;
        } else {
          return newCols;
        }
      });
    }, [customSortBehaviour, helper, serverSortHandler, useAutoSort]);

    const getUserCollections = useCallback(async () => {
      const userCollectionsIds = await listsAPI.getRaw();
      const userObjectsResponse = await listsAPI.portfolioFetch(
        userCollectionsIds,
        ["ownerId", "name", "type"]
      );

      const userId = environment.get("account")?.user?.id;

      const collections: any = [];
      let isSubscribed: any = null;

      for (const collection of userObjectsResponse) {
        isSubscribed = collection.ownerId !== userId;

        if (!isSubscribed) {
          collections.push({
            ...collection,
            type: collection.type === "BASKET" ? "Baskets" : "Portfolios",
          });
        }
      }

      setUserCollection(collections);
    }, [environment, listsAPI]);

    const onSelectAll = useCallback(() => {
      const table = tableRef?.current?.getTabulatorRef();

      if (table) {
        const rows = table.getRows();

        for (const row of rows) {
          row.select();
        }
      }

      setSelectedRows(data);
    }, [data]);

    const onDeselectAll = useCallback(() => {
      const table = tableRef?.current?.getTabulatorRef();

      if (table) {
        const rows = table.getRows();

        for (const row of rows) {
          row.deselect();
        }
      }

      setSelectedRows([]);
    }, []);

    const onCheckboxChange = useCallback(
      (evt, checked) => {
        if (checked === true) {
          onSelectAll();
        } else {
          onDeselectAll();
        }
      },
      [onDeselectAll, onSelectAll]
    );

    const onClickAddTo = useCallback(
      (e) => {
        handleClickAddTo(e);
      },
      [handleClickAddTo]
    );

    const addToActionsDispatch = useCallback(
      async (
        e,
        action: "createBasket" | "createPortfolio" | "selectExisting"
      ) => {
        switch (action) {
          case "createPortfolio": {
            setShowPortfolioModal(true);
            break;
          }

          case "createBasket": {
            setShowBasketModal(true);
            break;
          }

          case "selectExisting": {
            await getUserCollections();
            setPanelList(true);
            break;
          }
        }

        handleClose(e);
      },
      [getUserCollections, handleClose]
    );

    const { broadcast } = useBroadcast();

    const addToHelper = useCallback(
      async (positions, response) => {
        const list = response;
        const newPositions = positions;

        list["positions"] = list["positions"].concat(newPositions);

        try {
          await listsAPI.update(list);

          //Use the non updated list to get name and type used to be sended to the topic
          const oldList = list;

          const message = {
            from: "Routes",
            content: {
              type: "success",
              text:
                "Item/s saved in " +
                oldList.type.toLowerCase() +
                " <strong>" +
                oldList.name +
                "</strong>.",
            },
          };

          const [channel, msg] = messageSuccess(message.content.text);
          broadcast(channel as string, msg);

          //********************** USAGE *************************
          var usage = window.App.usage;
          var info = {
            action: "ADD_TO",
            actionParam: list["id"],
            function: "MESSAGE",
          };
          usage.record(info);
          //********************** USAGE *************************
        } catch (error) {
          const [channel, msg] = messageError(
            `Failed to update <strong>${list.name}</strong>`
          );

          broadcast(channel as string, msg);
        }
      },
      [broadcast, listsAPI]
    );

    const onAddToExisting = useCallback(
      async (selectedId) => {
        const selectedCollection = userCollections.find(
          (item) => item.id === selectedId
        );
        const collectionType =
          selectedCollection.type === "Portfolios" ? "PORTFOLIO" : "BASKET";
        const response = await listsAPI.get(selectedId);
        var newPositions = newGetSymbols(
          [...selectedRows],
          collectionType,
          true
        );
        await addToHelper(newPositions, response);
      },
      [addToHelper, listsAPI, selectedRows, userCollections]
    );

    useEffect(() => {
      if (columns) {
        const columnsHelper = helper.get("columns");
        setCols((current) => {
          const newCols = tranformToTabulatorCols(
            columnsHelper.prepareInputColumns(columns)
          );

          if (JSON.stringify(current) === JSON.stringify(newCols)) {
            return current;
          } else {
            return newCols;
          }
        });
      }
    }, [columns, helper, tranformToTabulatorCols]);

    useEffect(() => {
      if (tableData) {
        setData(tableData);
      }
    }, [tableData]);

    useEffect(() => {
      const updatedFields: any = cols.map((item) => item.field);
      updateFields(updatedFields);
    }, [cols, updateFields]);

    const wrapSetInitEditorCol = useCallback(
      (initCols) => {
        // If present remove the checkbox column to check if security columns are empty
        let securityColumns = cols?.filter(
          (col) => col.formatter !== "rowSelection"
        );
        // Set only once if cols does not exists
        if (!securityColumns || !securityColumns.length) {
          setCols((current) => {
            const newCols = tranformToTabulatorCols(initCols);

            if (JSON.stringify(current) === JSON.stringify(newCols)) {
              return current;
            } else {
              return newCols;
            }
          });
          if (getInitColumns) {
            getInitColumns(tranformToTabulatorCols(initCols));
          }
        }
      },
      [cols, getInitColumns, tranformToTabulatorCols]
    );

    const fireExpandEvent = useCallback(() => {
      setExpanded(!expanded);
      dispatch(tools?.expandTable?.eventName);
    }, [dispatch, expanded, tools?.expandTable?.eventName]);

    const wrapItemsPerPageSelection = useCallback(
      (value) => {
        tools?.rowsNumberSelect?.onChangeItemsPerPage(value);
        triggerPaginationReset(Date.now());
      },
      [tools?.rowsNumberSelect]
    );

    const showRankChangesFrom = useCallback(
      (
        value:
          | undefined
          | "PREVIOUS_DAY"
          | "PREVIOUS_WEEK"
          | "PREVIOUS_2_WEEKS"
          | "PREVIOUS_MONTH"
          | "PREVIOUS_3_MONTHS"
      ) => {
        if (tools?.rank) {
          tools?.rank?.onChangeRankDate(value);
        }
        triggerPaginationReset(Date.now());
      },
      [tools?.rank]
    );

    const selectionHandlerForBox = useCallback(
      (event, security) => {
        const checked = event.target.checked;
        let eventWrap = {
          value: {
            getData: () => security,
          },
        };

        if (checked) {
          onRowSelectionDefault(eventWrap);
        } else {
          onRowDeselectionDefault(eventWrap);
        }
      },
      [onRowDeselectionDefault, onRowSelectionDefault]
    );

    const InstruemntBox = useMemo(
      () => (
        <InstrumentsBoxLists
          selectedRows={selectedRows}
          selectionHandler={selectionHandlerForBox}
          data={data}
          showCheckbox={tools?.addToButton ?? false}
        />
      ),
      [data, selectedRows, selectionHandlerForBox, tools?.addToButton]
    );

    return (
      <Box
        flex={1}
        pb={2}
        minWidth={0}
        display={"flex"}
        flexDirection={"column"}
        sx={{ transition: "0.5s" }}
      >
        <Box mb={1} display={"flex"} justifyContent={"space-between"}>
          <Box flex={1} gap={1} display={"flex"} alignItems={"center"}>
            {tools && "addToButton" in tools && (
              <Box className={styles.addToBox}>
                <PanelForLists
                  showDialog={panelList}
                  closeDialog={() => setPanelList(false)}
                  list={userCollections}
                  sectionsTag={["Portfolios", "Baskets"]}
                  selectItem={onAddToExisting}
                  headerTitle={"Collections"}
                />
                {showPortfolioModal && (
                  <CreateListDialog
                    showModal={showPortfolioModal}
                    setShowModal={setShowPortfolioModal}
                    security={selectedRows}
                    environment={environment}
                    type={"PORTFOLIO"}
                  />
                )}

                {showBasketModal && (
                  <CreateListDialog
                    showModal={showBasketModal}
                    setShowModal={setShowBasketModal}
                    security={selectedRows}
                    environment={environment}
                    type={"BASKET"}
                  />
                )}
                <Checkbox
                  onChange={onCheckboxChange}
                  size="small"
                  sx={{ padding: "3px" }}
                />
                <Button
                  onClick={onClickAddTo}
                  disabled={addToDisabled}
                  sx={{ padding: "3px 5px", minHeight: 0, height: "auto" }}
                  variant="contained"
                  size="small"
                >
                  Add to
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    onClick={(e) => addToActionsDispatch(e, "createBasket")}
                  >
                    New Basket
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => addToActionsDispatch(e, "createPortfolio")}
                  >
                    New Portfolio
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => addToActionsDispatch(e, "selectExisting")}
                  >
                    Select an existing list
                  </MenuItem>
                </Menu>
              </Box>
            )}
            {tools && "expandTable" in tools && (
              <div className="tableColumnConfigurator">
                <button
                  className={styles.iconButtonWidget}
                  title="Expand table"
                  onClick={fireExpandEvent}
                >
                  {expanded ? (
                    <CloseFullscreenIcon sx={{ fontSize: "0.7vw" }} />
                  ) : (
                    <OpenInFullIcon sx={{ fontSize: "0.7vw" }} />
                  )}
                </button>
              </div>
            )}
            {tools && "configurator" in tools && (
              <TableColumnsEditor
                {...tools.configurator!}
                setTableColumns={updateTableColumns}
                setInitCols={wrapSetInitEditorCol}
              />
            )}
            {tools && "customTools" in tools && (
              <>{tools.customTools?.children}</>
            )}
          </Box>

          <Box flex={1}>
            {tools && "pagination" in tools && (
              <Paginator
                key={paginationReset}
                dataTotalCount={tools.pagination!.dataTotalCount}
                itemsPerPage={tools.pagination!.itemsPerPage}
                handlePaginationChange={tools.pagination!.changePage}
              />
            )}
          </Box>

          <Box
            flex={1}
            justifyContent={"flex-end"}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            {tools?.rowsNumberSelect && (
              <ItemsPerPageSelect
                label={tools.rowsNumberSelect.label}
                onChange={wrapItemsPerPageSelection}
                initialValue={tools.rowsNumberSelect.initialValue}
              />
            )}
            {tools?.rank?.showRankTools && (
              <>
                <RankDateSelect
                  label={"Show Changes From:"}
                  onChange={showRankChangesFrom}
                  dateInitValue={tools.rank.dateInitValue}
                />
                <div className="tableColumnConfigurator">
                  <button
                    className={styles.iconButtonWidget}
                    title="Highlighted view"
                    onClick={onChangeRankHighlight}
                  >
                    <span className="i-color"></span>
                  </button>
                </div>
              </>
            )}
            {tools?.viewAsListButton && (
              <div className="tableColumnConfigurator">
                <button
                  className={styles.iconButtonWidget}
                  title="Customize table fields"
                  onClick={toggleView}
                >
                  <span className="i-chart"></span>
                </button>
              </div>
            )}
          </Box>
        </Box>
        {viewAs === "table" ? (
          <>
            {loadingData ||
              (loading && (
                <Box minHeight={0} flex={1} display={"flex"} minWidth={0}>
                  <Box flex={1} minWidth={0} minHeight={0}>
                    <TableLoader />
                  </Box>
                </Box>
              ))}
            <Box
              minHeight={0}
              flex={1}
              display={loadingData || loading ? "none" : "flex"}
              minWidth={0}
            >
              <Box flex={1} minWidth={0} minHeight={0}>
                <TrendratingTable
                  eventCallback={handleTableEvents}
                  ref={tableRef}
                  {...tableProps}
                  columns={cols}
                  data={data}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>{InstruemntBox}</>
        )}
      </Box>
    );
  }
);

const StyledSelect = styled(
  (props: { children: any; className?: any; value: any }) => (
    <Select
      value={props.value}
      fullWidth
      size="small"
      variant="outlined"
      classes={{ select: props.className }}
    >
      {props.children}
    </Select>
  )
)({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

const ItemsPerPageSelect = ({
  onChange,
  label,
  initialValue,
}: {
  onChange: (n: number) => void;
  label?: string;
  initialValue?: number;
}) => {
  const [value, setValue] = useState(initialValue ?? 25);

  const options = useMemo(
    () => [
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
      { label: 1000, value: 1000 },
    ],
    []
  );

  const onValueChage = useCallback(
    (value) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        {label && <Typography whiteSpace={"nowrap"}>{label}</Typography>}
        <StyledSelect value={value}>
          {options.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => onValueChage(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
};

const RankDateSelect = ({
  onChange,
  label,
  dateInitValue,
}: {
  onChange: (
    s:
      | "PREVIOUS_DAY"
      | "PREVIOUS_WEEK"
      | "PREVIOUS_2_WEEKS"
      | "PREVIOUS_MONTH"
      | "PREVIOUS_3_MONTHS"
      | undefined
  ) => void;
  label?: string;
  dateInitValue?:
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS";
}) => {
  const [value, setValue] = useState<
    | "PREVIOUS_DAY"
    | "PREVIOUS_WEEK"
    | "PREVIOUS_2_WEEKS"
    | "PREVIOUS_MONTH"
    | "PREVIOUS_3_MONTHS"
    | "no_changes"
  >(dateInitValue ?? "no_changes");

  const options = useMemo(
    () => [
      { label: "No changes", value: "no_changes" },
      { label: "Previous day", value: "PREVIOUS_DAY" },
      { label: "Last Friday", value: "PREVIOUS_WEEK" },
      { label: "Previous week", value: "PREVIOUS_2_WEEKS" },
      { label: "Last month", value: "PREVIOUS_MONTH" },
      { label: "Last 3 months", value: "PREVIOUS_3_MONTHS" },
    ],
    []
  );

  const onValueChage = useCallback(
    (value) => {
      setValue(value);

      let v = value;

      if (value === "no_changes") {
        v = undefined;
      }

      onChange(v);
    },
    [onChange]
  );

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        {label && <Typography whiteSpace={"nowrap"}>{label}</Typography>}
        <StyledSelect value={value}>
          {options.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => onValueChage(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>{" "}
      </Box>
    </Box>
  );
};

const TableLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "20px",
          marginBottom: "20px",
        }}
      />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
      <Skeleton variant="rectangular" width={"100%"} height={30} />
    </Stack>
  );
};
