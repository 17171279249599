import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../deepClone";
import { CustomComponent } from "../React_KeyFacts/React_KeyFacts";
import {
  QuarterlyAnalyticsType,
  quarterlyAnalyticsReducer,
} from "./React_QuarterlyAnalytics_reducer";

type QuarterlyAnalyticsProps = {
  configObj: any;
};

const QuarterlyAnalytics = forwardRef(
  ({ configObj }: QuarterlyAnalyticsProps, ref) => {
    const initState: QuarterlyAnalyticsType = useMemo(
      () => ({
        headline: configObj.widgetValue.content.headline,
        maxDrawdown: configObj.widgetValue.content.maxDrawdown,
        quarterlyPerformance:
          configObj.widgetValue.content.quarterlyPerformance,
        quarterlyStandardDeviation:
          configObj.widgetValue.content.quarterlyStandardDeviation,
        years: configObj.widgetValue.content.years,
      }),
      [
        configObj.widgetValue.content.headline,
        configObj.widgetValue.content.maxDrawdown,
        configObj.widgetValue.content.quarterlyPerformance,
        configObj.widgetValue.content.quarterlyStandardDeviation,
        configObj.widgetValue.content.years,
      ]
    );

    const [state, dispatch] = useImmerReducer(
      quarterlyAnalyticsReducer,
      initState
    );


    useImperativeHandle(ref, () => ({
      getState: () => {
        let tempState = deepClone(configObj);
        tempState.widgetValue.content = state;
        return tempState;
      },
    }));
    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            <Box display={"flex"} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.headline.isEnabled}
                    onChange={(e) =>
                      dispatch({
                        type: "SET_headline_ISENABLED",
                        payload: e.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label="Headline"
              />
              <TextField
                value={state.headline.content}
                onChange={(e) =>
                  dispatch({
                    type: "SET_headline_CONTENT",
                    payload: e.target.value,
                  })
                }
                disabled={!state.headline.isEnabled}
                size="small"
              />
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ pb: "16px !important" }}>
            {/* table */}
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>Years shown on:</Typography>
                <Select
                  size="small"
                  value={state.years}
                  onChange={(e) =>
                    dispatch({ type: "SET_years", payload: e.target.value })
                  }
                >
                  <MenuItem value={"ROWS"}> Rows</MenuItem>
                  <MenuItem value={"COLUMNS"}>Columns</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Enable</Typography>
              <TextField size="small" value={"Row headline"} disabled />
            </Box>

            <CustomComponent
              switchLabel={"Return"}
              switchChecked={state.quarterlyPerformance.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_quarterlyPerformance_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.quarterlyPerformance.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_quarterlyPerformance_CONTENT",
                  payload: e,
                })
              }
            />

            <CustomComponent
              switchLabel={"Max Drawdown"}
              switchChecked={state.maxDrawdown.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_maxDrawdown_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.maxDrawdown.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_maxDrawdown_CONTENT",
                  payload: e,
                })
              }
            />

            <CustomComponent
              switchLabel={"Standard Deviation"}
              switchChecked={state.quarterlyStandardDeviation.isEnabled}
              switchCallback={(e) =>
                dispatch({
                  type: "SET_quarterlyStandardDeviation_ISENABLED",
                  payload: e,
                })
              }
              textValue={state.quarterlyStandardDeviation.content}
              textCallback={(e) =>
                dispatch({
                  type: "SET_quarterlyStandardDeviation_CONTENT",
                  payload: e,
                })
              }
            />
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default QuarterlyAnalytics;
