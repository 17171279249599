import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { httpAll } from "../../../../../../../httpAll";
import { config } from "../../../../../config-ts";
import { messageError, messageSuccess } from "../../../../../utils";
import { PreferenceContext } from "../../Preferences";
import TabsWrapper from "./TabsWrapper";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { DialogRelations } from "../../../../../components/app-infrastructure/workflowBar/actions/remove/Remove";

type SubscriptionsProps = {
  input: any;
};

export default function Subscriptions({ input }: SubscriptionsProps) {
  const environment = useEnvironment();
  const http = environment.get("http");
  const subscriptionsAvailable = useMemo(() => {
    const product = environment.get("account")["product"];
    return product.configuration.subscriptions;
  }, [environment]);
  const { broadcast } = useBroadcast();

  //#region - _store
  /*
  ! _store --> {lists:[], rankings:[],strategies:[], systematicPortfolios}.
  ! built by _dataPrepare. 
  */
  const [_store, setStore] = useState<any>(null);
  //#endregion

  const modalCTX = useContext(PreferenceContext);
  //#region - tabs handling
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue) => {
      modalCTX.setSubscriptionTab(newValue);
    },
    [modalCTX]
  );

  //! to ho hide tabs combined strategy and combined product if nothing has been shared
  //! we usa 2 state to find out if there are any combined shared.
  //! based on the 2 states we build the tabs
  const [hasCombinedStrategy, setHasCombinedStrategy] = useState(false);
  const [hasCombinedProduct, setHasCombinedProduct] = useState(false);
  useEffect(() => {
    let _hasCombinedStrategy = false;
    let _hasCombinedProduct = false;
    http["publications"]
      .get({
        type: "combinedStrategy",
      })
      .then((response) => {
        _hasCombinedStrategy = response.length > 0 ? true : false;
        return http["publications"]
          .get({
            type: "combinedProduct",
          })
          .then((resp) => {
            _hasCombinedProduct = resp.length > 0 ? true : false;
            setHasCombinedProduct(_hasCombinedProduct);
            setHasCombinedStrategy(_hasCombinedStrategy);
          });
      });
  }, [http]);

  const tabs = useMemo<null | any[]>(() => {
    let arrTabs: any = [];
    // Tab Portfolios
    if (
      "portfolios" in subscriptionsAvailable &&
      subscriptionsAvailable["portfolios"] === true
    ) {
      arrTabs.push({
        label: "Portfolios",
        value: "portfolio",
      });
    }
    // Tab Baskets
    if (
      "baskets" in subscriptionsAvailable &&
      subscriptionsAvailable["baskets"] === true
    ) {
      arrTabs.push({
        label: "Baskets",
        value: "basket",
      });
    }
    // Tab Rankings
    if (
      "rankings" in subscriptionsAvailable &&
      subscriptionsAvailable["rankings"] === true
    ) {
      arrTabs.push({
        label: "Rankings",
        value: "ranking",
      });
    }
    // Tab Systematic Portfolios
    if (
      "systematics" in subscriptionsAvailable &&
      subscriptionsAvailable["systematics"] === true
    ) {
      arrTabs.push({
        label: "Systematic Portfolios",
        value: "systematic",
      });
    }
    // Tab Strategies
    if (
      "strategies" in subscriptionsAvailable &&
      subscriptionsAvailable["strategies"] === true
    ) {
      arrTabs.push({
        label: "Strategies",
        value: "strategy",
      });
    }
    //tab combined strategy
    if (
      "combinedStrategies" in subscriptionsAvailable &&
      subscriptionsAvailable["combinedStrategies"] === true &&
      hasCombinedStrategy
    ) {
      arrTabs.push({
        label: "Combined Strategies",
        value: "combinedStrategy",
      });
    }
    //tab combined product
    if (
      "combinedSystematics" in subscriptionsAvailable &&
      subscriptionsAvailable["combinedSystematics"] === true &&
      hasCombinedProduct
    ) {
      arrTabs.push({
        label: "Combined Systematics Portfolios",
        value: "combinedProduct",
      });
    }
    return arrTabs;
  }, [hasCombinedProduct, hasCombinedStrategy, subscriptionsAvailable]);
  //#endregion

  //#region dependencies checkers
  const _checkDependencyList = useCallback(
    (listId) => {
      const list_s_id = "list_" + listId;
      let list = _store?.filter((item) => item._s_id === list_s_id);
      list = list[0];
      var dependencies = {
        hasList: true,
        list: list == null ? null : list,
      };
      return dependencies;
    },
    [_store]
  );
  const _checkDependencyStrategy = useCallback(
    async (strategyId) => {
      const returningValue = await http["strategies"]
        .getById(strategyId)
        .then((strategy) => {
          let dependencies = {
            hasList: false,
            hasStrategy: false,
            list: null,
            strategy: null,
          };
          const strategy_s_id = "strategy_" + strategy.id;
          const dependencyStrategy: any = _store?.filter(
            (item) => item._s_id === strategy_s_id
          )[0];
          dependencies.hasStrategy = true;
          dependencies.strategy =
            dependencyStrategy == null ? null : dependencyStrategy;
          if (strategy["params"]["universe"]["whiteList"] != null) {
            const dependencyList = _checkDependencyList(
              strategy["params"]["universe"]["whiteList"]["id"]
            );
            dependencies.hasList = dependencyList.hasList;
            dependencies.list = dependencyList.list;
            return dependencies;
          }
          return dependencies;
        });
      return returningValue;
    },
    [_checkDependencyList, _store, http]
  );
  const _checkDependencyRanking = useCallback(
    async (rankingId) => {
      const returningValue = await http["rankings"]
        .get(rankingId)
        .then((ranking) => {
          if (ranking["target"]["type"] === "list") {
            return _checkDependencyList(ranking["target"]["object"]["id"]);
          }
          var dependencies = {
            hasList: false,
            list: null,
          };
          return dependencies;
        });
      return returningValue;
    },
    [_checkDependencyList, http]
  );
  //#endregion

  const subscribe = useCallback(
    async (id) => {
      let promiseChainDependency: any = null;
      const item = _store.filter((obj) => obj.id === id)[0];
      switch (modalCTX?.subscriptionTab ?? "portfolio") {
        case "strategy":
          promiseChainDependency = _checkDependencyStrategy(item.id).then(
            (dependencies) => {
              if (dependencies.hasList === true && dependencies.list == null) {
                // error: the list to be subscribed is not
                // published or not available
                const [channel, msg] = messageError("Publication not found");
                broadcast(channel as string, msg);
                console.log("pubblication not found", {
                  response: dependencies,
                  status: 404, // Publication not found
                });
                throw new Error("Publication not found");
              }
              let requests = {
                list: null,
                strategy: http["subscriptions"].create({
                  id: item.id,
                  type: item.pubSubType,
                }),
              };
              if (dependencies.list) {
                requests.list = http["subscriptions"].create({
                  id: dependencies.list.id,
                  type: dependencies.list.pubSubType,
                });
              }
              return httpAll(requests);
            }
          );
          break;
        case "systematic":
          promiseChainDependency = http["systematicProducts"]
            .get({
              id: item["id"],
            })
            .then((responseSystematicProduct) => {
              const strategyId = responseSystematicProduct["strategyId"];
              return _checkDependencyStrategy(strategyId).then(
                (dependencies) => {
                  if (
                    (dependencies.hasList === true &&
                      dependencies.list == null) ||
                    (dependencies.hasStrategy === true &&
                      dependencies.strategy == null)
                  ) {
                    // error: the list/strategy to be subscribed is not
                    // published or not available
                    const [channel, msg] = messageError(
                      "Publication not found"
                    );
                    broadcast(channel as string, msg);
                    console.log("pubblication not found", {
                      response: dependencies,
                      status: 404, // Publication not found
                    });
                    throw new Error("Publication not found");
                  }
                  var requests = {
                    list: null,
                    strategy: http["subscriptions"].create({
                      id: dependencies.strategy.id,
                      type: dependencies.strategy.pubSubType,
                    }),
                    systematicPortfolio: http["subscriptions"].create({
                      id: item.id,
                      type: item.pubSubType,
                    }),
                  };
                  if (dependencies.list) {
                    requests.list = http["subscriptions"].create({
                      id: dependencies.list.id,
                      type: dependencies.list.pubSubType,
                    });
                  }
                  return httpAll(requests).then((res) => {
                    return res;
                  });
                }
              );
            });
          break;
        case "ranking":
          promiseChainDependency = _checkDependencyRanking(item.id).then(
            (dependencies) => {
              if (dependencies.hasList === true && dependencies.list == null) {
                // error: the list to be subscribed is not
                // published or not available

                const [channel, msg] = messageError("Publication not found");
                broadcast(channel as string, msg);
                console.log("pubblication not found", {
                  response: dependencies,
                  status: 404, // Publication not found
                });
                throw new Error("Publication not found");
              }
              var requests = {
                list: null,
                strategy: http["subscriptions"].create({
                  id: item.id,
                  type: item.pubSubType,
                }),
              };

              if (dependencies.list) {
                requests.list = http["subscriptions"].create({
                  id: dependencies.list.id,
                  type: dependencies.list.pubSubType,
                });
              }

              return httpAll(requests);
            }
          );
          break;
        case "basket":
        case "portfolio":
          promiseChainDependency = http["subscriptions"].create({
            id: item.id,
            type: item.pubSubType,
          });
          break;
        case "combinedProduct":
          promiseChainDependency = http["subscriptions"].create({
            id: item.id,
            type: "combinedProduct",
          });
          break;
        case "combinedStrategy":
          promiseChainDependency = http["subscriptions"].create({
            id: item.id,
            type: "combinedStrategy",
          });
          break;
        default:
          return;
      }

      return promiseChainDependency.then(
        (response) => {
          const [channel, msg] = messageSuccess(
            "Publication subscribed successfully."
          );
          broadcast(channel as string, msg);
        },
        (error) => {
          const [channel, msg] = messageError(
            "Cannot subscribe the publication. Please contact Trendrating."
          );
          broadcast(channel as string, msg);
          console.error("error", error.message);
          // _dataGet();
        }
      );
    },
    [
      _checkDependencyRanking,
      _checkDependencyStrategy,
      _store,
      broadcast,
      http,
      modalCTX?.subscriptionTab,
    ]
  );

  const [removeTarget, setRemoveTarget] = useState<any>(undefined);
  const [unsubscribeCallback, setUnsubscribeCallback] = useState<any>();

  const showDialogRelations = useMemo(
    () => removeTarget != null,
    [removeTarget]
  );

  const closeRelationDialog = useCallback(() => setRemoveTarget(undefined), []);

  const onCancelUnsubscribe = useCallback(async () => {
    if (unsubscribeCallback) {
      unsubscribeCallback();
    }
  }, [unsubscribeCallback]);

  const onUnsubscribeDone = useCallback(async () => {
    var message = {
      from: "Strategy unsubscription",
      content: {
        type: "success",
        text: "Shared object unsubscribed successfully.",
      },
    };
    broadcast(config["channels"]["feedback"]["input"], message);

    if (unsubscribeCallback) {
      unsubscribeCallback();
    }
  }, [broadcast, unsubscribeCallback]);

  const unsubscribe = useCallback(
    (id, callback) => {
      const item = _store.filter((obj) => obj.id === id)[0];
      const setCallback = () => {
        return () => callback();
      };
      setUnsubscribeCallback(setCallback);
      setRemoveTarget(item);
    },
    [_store]
  );
  const sectionToRender = useMemo(() => {
    switch (modalCTX.subscriptionTab) {
      case "portfolio":
        if (subscriptionsAvailable["portfolios"]) {
          return (
            <>
              <TabsWrapper
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={true}
                setStore={setStore}
                type={"list"}
              />
            </>
          );
        }
        return null;
      case "basket":
        if (subscriptionsAvailable["baskets"]) {
          return (
            <>
              <TabsWrapper
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={false}
                setStore={setStore}
                type={"list"}
              />
            </>
          );
        }
        return null;
      case "ranking":
        if (subscriptionsAvailable["rankings"]) {
          return (
            <>
              <TabsWrapper
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={null}
                setStore={setStore}
                type={"ranking"}
              />
            </>
          );
        }
        return null;
      case "strategy":
        if (subscriptionsAvailable["strategies"]) {
          return (
            <>
              <TabsWrapper
                subscribe={subscribe}
                store={_store}
                unsubscribe={unsubscribe}
                isPortfolio={null}
                setStore={setStore}
                type={"strategy"}
              />
            </>
          );
        }
        return null;
      case "systematic":
        if (subscriptionsAvailable["systematics"]) {
          return (
            <>
              <TabsWrapper
                store={_store}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={null}
                setStore={setStore}
                type={"systematicPortfolio"}
              />
            </>
          );
        }
        return null;

      case "combinedStrategy":
        if (subscriptionsAvailable["combinedStrategies"]) {
          return (
            <>
              <TabsWrapper
                store={_store}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={null}
                setStore={setStore}
                type={"combinedStrategy"}
              />
            </>
          );
        }
        return null;

      case "combinedProduct":
        if (subscriptionsAvailable["combinedSystematics"]) {
          return (
            <>
              <TabsWrapper
                store={_store}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                isPortfolio={null}
                setStore={setStore}
                type={"combinedProduct"}
              />
            </>
          );
        }
        return null;
    }
  }, [
    _store,
    modalCTX.subscriptionTab,
    subscribe,
    subscriptionsAvailable,
    unsubscribe,
  ]);

  return (
    <Box
      className="123456"
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      overflow={"hidden"}
    >
      {showDialogRelations && (
        <DialogRelations
          close={closeRelationDialog}
          itemToDelete={removeTarget}
          onRemoveDone={onUnsubscribeDone}
          isUnsubscribe={true}
          onCancel={onCancelUnsubscribe}
        />
      )}
      <Typography sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
        This section shows the documents that have been shared by Trendrating
        and that can be subscribed for use in your user account. These items are
        in read-only mode, you can make a copy in order to edit the content.
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {tabs != null && (
          <Tabs
            value={modalCTX?.subscriptionTab ?? "portfolio"}
            onChange={handleChangeTab}
            sx={{ "& .MuiTabs-flexContainer": { overflow: "auto" } }}
            TabIndicatorProps={{ hidden: true }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={<Typography>{tab.label}</Typography>}
                value={tab.value}
              />
            ))}
          </Tabs>
        )}
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        overflow={"hidden"}
      >
        {sectionToRender}
      </Box>
    </Box>
  );
}
