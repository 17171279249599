/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategyBreakdown
 * @summary Strategy - Breakdown
 *
 */

import { getTaxonById } from "../../../api/compute/Taxon";
import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity, format, formatTaxon } from "./Generator";

export function sectionsBreakdown(
    input,
    section,
    datum,
    formatter,
    environment
) {
    const _sections: any = [];
    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    const txFields = environment["taxonomyFields"];
    const taxonomies = environment["taxonomies"];
    const txWhere = taxonomies[txFields["security"]["country"]];
    const txWhatSector = taxonomies[txFields["security"]["sector"]];
    const txWhatIndustry = taxonomies[txFields["security"]["industry"]];
    const txWhatEtf = taxonomies[txFields["ETF"]["etfclass"]];
    const txWhereEtf = taxonomies[txFields["ETF"]["etfgeo"]];

    if (section["content"]["dimension"]) {
        const breakdown: any = {
            title: null,
            data: datum["data"],
            taxonomies: null,
        };
        switch (section["content"]["dimension"]) {
            case "INDUSTRY":
                breakdown["title"] = "Sector";
                breakdown["taxonomies"] = [txWhatSector, txWhatEtf];
                break;
            case "SECTOR":
                breakdown["title"] = "Industry";
                breakdown["taxonomies"] = [txWhatIndustry, txWhatEtf];
                break;
            case "MARKET":
                breakdown["title"] = "Market";
                breakdown["taxonomies"] = [txWhere, txWhereEtf];
                break;
            case "REGION":
                breakdown["title"] = "Region";
                breakdown["taxonomies"] = [txWhere, txWhereEtf];
                break;
            case "AREA":
                breakdown["title"] = "Area";
                breakdown["taxonomies"] = [txWhere, txWhereEtf];
                break;
        }
        const _section = sectionsBreakdownDetail(
            input,
            section,
            breakdown,
            formatter
        );
        _sections.push(_section);
    }

    return _sections;
}

function sectionsBreakdownDetail(input, section, data, formatter) {
    const table: any = {
        data: {
            body: [],
            head: [[]],
        },
        type: "table",
    };

    table["data"]["head"][0].push({
        style: null,
        value: data["title"],
    });

    if (section["presentation"]["holdings"]) {
        table["data"]["head"][0].push({
            style: {
                align: "right",
            },
            value: "No. of holdings",
        });
    }

    if (section["presentation"]["totalWeight"]) {
        table["data"]["head"][0].push({
            style: {
                align: "right",
            },
            value: "Total weight",
        });
    }

    for (let i = 0; i < data["data"].length; i++) {
        const tableRow: any = [];
        const item = data["data"][i];

        if (item.id !== "OTHER") {
            tableRow.push({
                style: null,
                value: formatTaxon(
                    getTaxonById(data["data"][i]["id"], data["taxonomies"]),
                    data["taxonomies"]
                ),
            });
        } else {
            tableRow.push({
                style: null,
                value: "Others",
            });
        }

        if (section["presentation"]["holdings"]) {
            tableRow.push({
                style: {
                    align: "right",
                },
                value: formatter.custom("number", {
                    options: customPropertiesFormattingOptions["NUMBER"],
                    output: "PDF",
                    value: item.card,
                    valueHelper: null,
                }),
            });
        }

        if (section["presentation"]["totalWeight"]) {
            tableRow.push({
                style: {
                    align: "right",
                },
                value: format(
                    "weight",
                    {
                        weight: item.weight,
                        type: "PEER",
                    },
                    formatter
                ),
            });
        }
        // Add row to table
        table["data"]["body"].push(tableRow);
    }

    return table;
}
