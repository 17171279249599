export type Action = {
  type:
    | "SET_FUNCTION"
    | "SET_FUNCTION_PARAMS"
    | "SET_PROPERTY"
    | "SET_OPERATOR"
    | "SET_OPERATOR_PARAMS"
    | "SET_OPTIONS_FUNCTION"
    | "SET_OPTIONS_OPERATOR"
    | "SET_SHOW_OPERATOR_SECTION"
    | "SET_SHOW_FUNCTION_SECTION"
    | "SET_SHOW_VALUE_SECTION"
    | "SET_EDITING"
    | "SET_INDEX"
    | "SET_RANGE_QUANTILE"
    | "changeOperativeInput"
    | "SET_SHOW_HELP"
    | "SET_ERROR_AT_INPUT_VALUE";
  payload: any;
};

export type rStateType = {
  editing: boolean;
  field: {
    function: string | null;
    functionParams: any;
    property: string | null;
    operator: string | null;
    operatorParams: any;
  };
  index: number;
  showFunctionSection: boolean;
  showOperatorSection: boolean;
  showValueSection: boolean;
  showHelp: boolean;
  errorAtInputValue: boolean;
};

export function rStateReducer(draft: rStateType, action: Action) {
  switch (action.type) {
    case "SET_FUNCTION":
      draft.field.function = action.payload;
      break;
    case "SET_FUNCTION_PARAMS":
      if (JSON.stringify(draft.field.functionParams) !== JSON.stringify(action.payload)) {
        draft.field.functionParams = action.payload;
      }
      break;
    case "SET_PROPERTY":
      draft.field.property = action.payload;
      break;
    case "SET_OPERATOR":
      draft.field.operator = action.payload;
      break;
    case "SET_OPERATOR_PARAMS":
      if (JSON.stringify(draft.field.operatorParams) !== JSON.stringify(action.payload)) {
        draft.field.operatorParams = action.payload;
      }
      break;
    // case "SET_OPTIONS_FUNCTION":
    //   draft.optionsFunction = action.payload;
    //   break;
    // case "SET_OPTIONS_OPERATOR":
    //   draft.optionsOperator = action.payload;
    //   break;
    case "SET_SHOW_OPERATOR_SECTION":
      draft.showOperatorSection = action.payload;
      break;
    case "SET_SHOW_FUNCTION_SECTION":
      draft.showFunctionSection = action.payload;
      break;
    case "SET_SHOW_VALUE_SECTION":
      draft.showValueSection = action.payload;
      break;
    case "SET_EDITING":
      draft.editing = action.payload;
      break;
    case "SET_INDEX":
      draft.index = action.payload;
      break;
    case "SET_SHOW_HELP":
      draft.showHelp = action.payload;
      break;
    case "SET_ERROR_AT_INPUT_VALUE":
      draft.errorAtInputValue = action.payload;
      break;
    case "changeOperativeInput":
      draft = action.payload;
      return draft;
    default:
      return draft;
  }
}
