import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RadioButtonBar } from "../../../../../../../components/RadioButtonBar/RadioButtonBar";
import { ActionsPeerConstraints } from "../PeerConstraintsBar";
import { SortBy } from "./SortBy";

type PeerWhereConstraintsProps = {
  dispatch: (act: ActionsPeerConstraints) => void;
  options: (type) => any[];
  assetClass: "stock" | "ETF";
  where: string;
  sortBy: string;
};

export function PeerWhereConstraints({
  dispatch,
  options,
  assetClass,
  where,
  sortBy,
}: PeerWhereConstraintsProps) {
  const { t } = useTranslation();

  const whereOptions = useMemo(() => options("where"), [options]);
  const sortOptions = useMemo(() => options("whereSortBy"), [options]);

  return (
    <>
      <fieldset
        className="tPageAnalysisMarket-constraintsWhere"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <RadioButtonBar
            options={whereOptions ?? []}
            initValue={where}
            onChange={(value) =>
              dispatch({
                type: "SET_WHERE_TYPE",
                payload: value,
              })
            }
          />
          <SortBy
            rotate
            options={sortOptions ?? []}
            onChangeSort={(e) =>
              dispatch({ type: "SET_WHERE_SORT", payload: e })
            }
          />
        </Box>
        <p className="tPageAnalysisMarket-note">
          {assetClass === "ETF"
            ? "Security Type: ETF"
            : t("Security_type_Common_Stock")}
        </p>
      </fieldset>
    </>
  );
}
