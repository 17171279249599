import {
    Box,
    Card,
    CardContent,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import InputNumber from "../../../../../../../components/InputNumber/InputNumber";
import { deepClone } from "../../../../../../../deepClone";
import ReportSelect from "../commonWidgets/Select";
import {
    AllocationPieType,
    allocationPieReducer,
} from "./React_AllocationPie_reducer";

type AllocationProps = {
    configObj: any;
};
const selectOptions = [
    {
        label: "Sectors",
        value: "INDUSTRY",
    },
    {
        label: "Industries",
        value: "SECTOR",
    },
    {
        label: "Markets",
        value: "MARKET",
    },
    {
        label: "Regions",
        value: "REGION",
    },
    {
        label: "Areas",
        value: "AREA",
    },
    {
        label: "Inv. Region",
        value: "INV_REGION",
    },
    {
        label: "Asset Class",
        value: "ASSET_CLASS",
    },
    {
        label: "Specialty",
        value: "SPECIALTY",
    },
    {
        label: "Theme",
        value: "THEME",
    },
];
const AllocationPie = forwardRef(({ configObj }: AllocationProps, ref) => {
    const initState: AllocationPieType = useMemo(
        () => ({
            content: configObj.widgetValue.content,
            presentation: configObj.widgetValue.presentation,
        }),
        [configObj.widgetValue.content, configObj.widgetValue.presentation]
    );
    const [state, dispatch] = useImmerReducer(allocationPieReducer, initState);

    useImperativeHandle(ref, () => ({
        getState: () => {
            let tempState = deepClone(configObj);
            tempState.widgetValue.content = state.content;
            tempState.widgetValue.presentation = state.presentation;
            return tempState;
        },
    }));

    let baseURL = useMemo(() => window.location.origin, []);

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Card>
                <CardContent
                    sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                        boxShadow: 3,
                    }}
                >
                    <Box display={"flex"}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.content.headline.isEnabled}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_CONTENT_HEADLINE_ISENABLED",
                                            payload: e.target.checked,
                                        })
                                    }
                                    size="small"
                                />
                            }
                            label="Headline"
                        />
                        <TextField
                            value={state.content.headline.content}
                            disabled={!state.content.headline.isEnabled}
                            onChange={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_HEADLINE_CONTENT",
                                    payload: e.target.value,
                                })
                            }
                            size="small"
                        />
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.presentation.colorByPosition}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_COLORBYPOSITION",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                        }
                        label="Colors by position"
                    />
                </CardContent>
            </Card>

            <Card>
                <CardContent sx={{ display: "flex", gap: 2, boxShadow: 3 }}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                    >
                        <Box display={"flex"} alignItems={"center"}>
                            <Switch
                                checked={state.presentation.first}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_FIRST",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                            <ReportSelect
                                disabled={!state.presentation.first}
                                options={selectOptions}
                                setOutterState={(e) =>
                                    dispatch({
                                        type: "SET_CONTENT_FIRST_TYPE",
                                        payload: e,
                                    })
                                }
                                defaultValue={state.content.firstType}
                            />
                        </Box>
                        <img
                            alt="pie chart"
                            height={100}
                            width={100}
                            src={`${baseURL}/js/trendrating-report/resources/images/allocation1.png`}
                        />
                        <Typography>Max number of slices: </Typography>
                        <InputNumber
                            // maxWidth={100}
                            isPercentage={false}
                            initVal={state.content.firstRows}
                            setOuterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_FIRST_ROWS",
                                    payload: e,
                                })
                            }
                            isFloating={false}
                        />
                    </Box>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                    >
                        <Box display={"flex"} alignItems={"center"}>
                            <Switch
                                checked={state.presentation.second}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PRESENTATION_SECOND",
                                        payload: e.target.checked,
                                    })
                                }
                                size="small"
                            />
                            <ReportSelect
                                disabled={!state.presentation.second}
                                options={selectOptions}
                                setOutterState={(e) =>
                                    dispatch({
                                        type: "SET_CONTENT_SECOND_TYPE",
                                        payload: e,
                                    })
                                }
                                defaultValue={state.content.secondType}
                            />
                        </Box>
                        <img
                            alt="pie chart"
                            height={100}
                            width={100}
                            src={`${baseURL}/js/trendrating-report/resources/images/allocation2.png`}
                        />
                        <Typography>Max number of slices: </Typography>
                        <InputNumber
                            initVal={state.content.secondRows}
                            // maxWidth={100}
                            isPercentage={false}
                            setOuterState={(e) =>
                                dispatch({
                                    type: "SET_CONTENT_SECOND_ROWS",
                                    payload: e,
                                })
                            }
                            isFloating={false}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
});

export default AllocationPie;
