/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/CombinedStrategies
 * @summary Requests for Combined Strategies
 *
 */

import { _StoredObjects } from "../_StoredObjects";

export class CombinedStrategies extends _StoredObjects {
    storedObjectType = "COMBINED_STRATEGY" as const;

    getPrototype() {
        return {
            benchmark: null,
            currency: null,
            id: null,
            name: null,
            period: null,
            strategy1: {
                id: null,
                value: 0,
            },
            strategy2: {
                id: null,
                value: 0,
            },
            type: this.storedObjectType,
        };
    }
}
