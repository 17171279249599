import { Box, Button, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { deepClone } from "../../../deepClone";
import { useResizer } from "../../../hooks/useResizer";
import SortableList from "../../SortableList/SortableList";
import {
  decodeData,
  elementBuilder,
  encoder,
  quantileParametersAdapter,
  setOptionsAttr,
} from "./utils";
import { Help } from "./widgets/Help/Help";
import { helpTextobjs } from "./widgets/Help/helpItems";
import { Wizzard, _dataDisplayOperators } from "./widgets/Wizzard/Wizzard";

type Props = {
  options: any;
  titleRuleList: string;
  onClose: Function;
  onCancel: Function;
  rules: any;
  caller: string | null;
  autoResize?: boolean;
  startsWithWizzardOpen?: boolean;
};

const listContentForListItem = (ruleList, opt) => {
  let arr: any = [];
  if (ruleList != null) {
    ruleList.forEach((item) => {
      const objTemp = {
        content: elementBuilder(item, opt?._options, opt?._optionsIndex),
        value: item,
      };
      arr.push(objTemp);
    });
    return arr;
  }
  return arr;
};

export default function SelectionRulesWizzard({
  onClose,
  rules,
  options,
  onCancel,
  caller,
  titleRuleList,
  autoResize = true,
  startsWithWizzardOpen,
}: Props) {
  const startsWizzardsOpen = useRef<boolean>(startsWithWizzardOpen ?? false);
  const wizzRef = useRef<any>(null);
  const [ruleList, setRuleList] = useState(() => {
    if (rules != null && rules.length > 0) {
      return rules.map((item, index) => ({ field: item, index: index }));
    }
    return [];
  });

  const [wizzardInput, setWizzardInput] = useState(null);
  const [showWizzard, setShowWizzard] = useState(startsWizzardsOpen.current);
  //********************************************
  //* sortableList related functions & status
  //********************************************
  const opt = useMemo(() => setOptionsAttr(options["edit"]), [options]);
  const obj = useMemo(() => listContentForListItem(ruleList, opt), [opt, ruleList]);
  const [selectedFromSortableList, setSelectedFromSortableList] = useState<any>(null);

  useEffect(() => {
    //if selectedFromSortableList != null && ruleList[selectedFromSortableList],
    //input for Wizzard is setted and wizzard is shown
    //every time that selectedFromSortableList change
    //else hide the wizzard
    if (selectedFromSortableList != null && ruleList[selectedFromSortableList]) {
      const test = ruleList[selectedFromSortableList]["field"];
      let data = encoder(test, setOptionsAttr(options["create"]), setOptionsAttr(options["edit"]));
      let val: any = {
        field: data,
        showOperatorSection: true,
        showValueSection: true,
        editing: true,
        index: selectedFromSortableList,
        errorAtInputValue: false,
        showRangeQuantile: data["function"] !== "value" ? true : false,
      };
      setWizzardInput(val);
      setShowWizzard(true);
    } else {
      setShowWizzard(false);
    }
  }, [options, ruleList, selectedFromSortableList]);

  //********************************************
  //* wizzard related functions & status
  //********************************************
  const addRuleButtonhandler = useCallback(() => {
    const tempRule = {
      function: null,
      functionParams: null,
      property: null,
      operator: null,
      operatorParams: null,
    };
    let val: any = {
      field: tempRule,
      showOperatorSection: false,
      showFunctionSection: false,
      showValueSection: false,
      errorAtInputValue: false,
    };
    //!! the index is needed when saving the obj
    val["index"] = ruleList.length;
    val["editing"] = false;
    setWizzardInput(val);
    setShowWizzard(true);
  }, [ruleList.length]);

  useEffect(() => {
    if (startsWizzardsOpen.current === true) {
      addRuleButtonhandler();
    }
  }, [addRuleButtonhandler]);

  //?-----------------------------------------------------
  //?-- Why use ref to set the internal state of Help?---
  //?-----------------------------------------------------
  const helpRef = useRef<any>(null);
  /*  
    -   if using a useState("st" for example) and passing a setState by props to 
    -   wizard to get the value of rState(internal state of wizzard)
    -   it will cause a loop because when updating st that is in this component 
    -   it will rerender and consequently will rerender wizzard which in turn will
    -   set st and so on... to avoid this loop, a ref is defined to <Help/> and we use
    -   imperative handle. inside <Help/> is defined a useState and i call the setState
    -   form <Help/> by the ref passing the param i want to set to useState
    */
  const getRuleToPushFromWizz = useCallback((state) => {
    if (helpRef.current != null) {
      helpRef.current.setHelp(state);
    }
  }, []);
  //?------------------------------
  //?------------------------------

  const addRuleToList = () => {
    let rule: any = null;
    if (wizzRef.current) {
      rule = wizzRef.current.getState();
    }
    if (rule == null) {
      return;
    }
    let _rule: any = deepClone(rule);
    //!!---------------------------------------
    //!!last changes before send the state out
    //  if function == quantile, the object needs
    //  to be adapted by quantileParametersAdapter()
    if (
      rule["field"]["function"] === "quartile" ||
      rule["field"]["function"] === "quintile" ||
      rule["field"]["function"] === "decile"
    ) {
      const _options: any = setOptionsAttr(options["create"]);

      const funcID = _rule["field"]["function"];
      let temp: any = _dataDisplayOperators(rule, _options._optionsIndex, _options._options, null);
      const arg = temp.filter((item) => item.item.functionID === funcID)[0]["item"];
      _rule = quantileParametersAdapter(rule, arg);
    }
    //!!---------------------------------------
    const decoded = decodeData(_rule);
    setRuleList((prev) => {
      let arrTemp = deepClone(prev);
      arrTemp[decoded["index"]] = decoded;

      return arrTemp;
    });
  };
  //**********************
  //**********************

  const getSelectedFromSortableList = useCallback((item) => {
    //!! wizzard will never close if this is not defined as useCallback
    setSelectedFromSortableList(item);
  }, []);

  //lock/unlock the "apply rules settings"
  const [areRulesChanged, setAreRulesChanged] = useState(false);
  useEffect(() => {
    //if rules != ruleList ==> settings has been changed, so anable the button
    if (ruleList) {
      let rr: any;
      if (rules) {
        rr = rules.map((item, index) => ({
          field: item,
          index: index,
        }));
      } else {
        rr = [];
      }

      if (JSON.stringify(rr) === JSON.stringify(ruleList)) {
        setAreRulesChanged(false);
      } else {
        setAreRulesChanged(true);
      }
    }
  }, [ruleList, rules]);

  const containerRef = useRef<HTMLDivElement>(null);
  useResizer({
    isEnable: autoResize,
    ref: containerRef,
  });

  return  (
    <div
      ref={containerRef}
      style={{
        backgroundColor: "transparent",
        overflow: "hidden",
        height: "100%",
        padding: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          maxHeight: "100%",
        }}
        style={{ width: "100%", backgroundColor: "transparent" }}
      >
        <Box
          sx={{
            bgcolor: "transparent",
            display: "flex",
            gap: 2,
          }}
          style={{ flexDirection: "column", width: caller != null ? "80%" : "100%" }}
        >
          <Card sx={{ height: "100%", boxShadow: 3, minHeight:130 }}>
            <CardContent
              sx={{
                height: "100%",
                "&:last-child": {
                  paddingBottom: 2,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  pb: 0,
                  height: "100%",
                }}
                style={{ flexDirection: "column" }}
              >
                <SortableList
                  listContainerStyle={{
                    border: "solid #2a7092 2px",
                    overflow: "hidden",
                  }}
                  header={{
                    headerTitle: (
                      <h3
                        style={{
                          fontWeight: "inherit",
                        }}
                      >
                        {titleRuleList}
                      </h3>
                    ),
                    headerStyle: {
                      backgroundColor: "#2a7092",
                      color: "#fff",
                      fontWeight: "bold",
                    },
                  }}
                  listSetter={(obj) => {
                    const arrTemp = obj.map((item) => item.value);
                    setRuleList(arrTemp);
                  }}
                  arrItems={obj}
                  isSortable={true}
                  getSelectedItemToEdit={getSelectedFromSortableList}
                  selectedItemFromOutside={selectedFromSortableList}
                  emptyListStatement={"No rules defined"}
                />

                {(!showWizzard || areRulesChanged) &&  (
                  <div style={{ display: "flex" }}>
                    <Button onClick={() => addRuleButtonhandler()} type="button">
                      Add rule
                    </Button>

                    <span style={{ marginLeft: "auto" }}>
                      <Button
                        disabled={!areRulesChanged}
                        onClick={() => {
                          //!!when close the component, it has to sent the ruleList to the upper level(dojo)
                          let _ruleList = ruleList.map((item) => item.field);
                          onClose(_ruleList);
                        }}
                        type="button"
                      >
                        Apply rules settings
                      </Button>

                      <Button
                        sx={{
                          ml: 2,
                        }}
                        variant="tr_button_cancel"
                        type="button"
                        onClick={() => {
                          //just onmount the whole component
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </span>
                  </div>
                )}
              </Box>
            </CardContent>
          </Card>
          {showWizzard && wizzardInput != null ? (
            <>
              <Wizzard
                ref={wizzRef}
                options={setOptionsAttr(options["create"])}
                input={wizzardInput}
                outterStateSetter={getRuleToPushFromWizz}
                onSelect={() => {
                  addRuleToList(/*ruleToPush*/);
                  setSelectedFromSortableList(null);
                  startsWizzardsOpen.current = false;
                  // setRuleToPush(null);
                }}
                onCancel={() => {
                  setSelectedFromSortableList(null);
                  // setRuleToPush(null);
                  setShowWizzard(false);
                }}
              />
            </>
          ) : null}
        </Box>
        {caller != null ? (
          <Box
            sx={{
              bgcolor: "transparent",
              display: "flex",
              gap: 2,
            }}
            style={{ flexDirection: "column", width: "20%" }}
          >
            <Card
              sx={{
                height: "100%",
                backgroundColor: "#f2f2f2",
                boxShadow: 3,
              }}
            >
              <CardContent>
                {/* <h3>Selection rules</h3>
              <p>
                Define the set of entitled securities. Entitled securities constitute the portion of
                the investment universe eligible to be included in the portfolio.
              </p> */}
                {helpTextobjs[caller]?.["init"] ?? "no descriptions"}
              </CardContent>
            </Card>
            {showWizzard && <Help ref={helpRef} caller={caller} />}
          </Box>
        ) : null}
      </Box>
    </div>
  );
}
