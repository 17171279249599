/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/widgets/app-infrastructure/menu/items
 * @summary Available menu items
 *
 */
import { recordMap } from "../trendrating-usage/recordMap";

export type MenuItemConfiguration = {
  description: string;
  isEnabled: boolean;
  menu_label: string;
  title: string;
  module: string;
  uri: string;
  excluded?: string[];
  usageFunction: keyof typeof recordMap.function;
};

export const menu: MenuItemConfiguration[] = [
  {
    description: "Alerts on Portfolios, Baskets and Investment Universe",
    isEnabled: false,
    menu_label: "Alerts",
    module: "alerts",
    title: "Alerts",
    uri: "alerts",
    usageFunction: "ALERT",
  },
  {
    description: "Create and manage systematic portfolios",
    isEnabled: false,
    menu_label: "Systematic portfolios",
    module: "systematic_portfolios",
    title: "Systematic portfolios",
    uri: "systematic-portfolios",
    usageFunction: "SYSTEMATIC_PORTFOLIOS",
  },
  {
    description: "Overview of Stock markets",
    isEnabled: false,
    menu_label: "Markets",
    module: "analysis_market",
    title: "Markets",
    uri: "analysis/markets",
    usageFunction: "MARKET",
  },
  {
    description: "Visualize data screening",
    isEnabled: false,
    menu_label: "Screening",
    module: "screening",
    title: "Screening",
    uri: "screening",
    usageFunction: "SCREENING",
  },
  {
    description: "Portfolio analysis",
    isEnabled: false,
    menu_label: "Portfolio analysis",
    module: "analysis_list",
    uri: "analysis/lists",
    title: "Portfolio analysis",
    usageFunction: "PORTFOLIO_HOME",
  },
  {
    description: "Portfolio analysis",
    isEnabled: false,
    menu_label: "Portfolio analysis",
    module: "performanceRise",
    uri: "performanceRise",
    title: "Portfolio analysis",
    usageFunction: "PERFORMANCE_RISE",
  },
  {
    description: "Security analysis",
    isEnabled: false,
    menu_label: "Security analysis",
    module: "analysis_instrument",
    title: "Security analysis",
    uri: "analysis/instrument",
    usageFunction: "SECURITY_ANALYSIS",
  },
  {
    description: "Manage your custom rankings",
    isEnabled: false,
    menu_label: "Rank",
    module: "ranking",
    title: "Ranking",
    uri: "rank",
    usageFunction: "RANKING",
  },
  {
    description: "An overview on your tracked strategies",
    isEnabled: false,
    menu_label: "Strategies",
    module: "strategy_tracker",
    title: "Strategies",
    uri: "strategies/tracked",
    usageFunction: "STRATEGY",
  },
  {
    description: "Build your strategy",
    excluded: ["strategies/tracked"],
    isEnabled: false,
    menu_label: "Strategy builder",
    module: "strategy_builder",
    title: "Strategy builder",
    uri: "strategies",
    usageFunction: "STRATEGY_BUILDER",
  },
  {
    description: "Download reports on specified investment universe",
    isEnabled: true,
    menu_label: "REPORTS HUB",
    module: "reports_hub",
    title: "Reports hub",
    uri: "reports-hub",
    usageFunction: "REPORTS_HUB",
  },
  {
    description: "Knowledge base and user guide",
    isEnabled: true,
    menu_label: "Help",
    module: "help",
    title: "Help",
    uri: "help",
    usageFunction: "HELP",
  },
];
