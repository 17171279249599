import React, { useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../../../../../hooks/useEnvironment";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

type Props = {
  logoutHandler: Function;
};

export default function Info({logoutHandler}: Props) {
  const environment = useEnvironment();
  const http = environment.get("http");
  const accountInfo = environment.get("account");
  const firstNameLastName = useMemo(
    () => `${accountInfo.user.firstName} ${accountInfo.user.lastName}`,
    [accountInfo.user.firstName, accountInfo.user.lastName]
  );
  const [systemInfo, setSystemInfo] = useState(() => {
    const app = window.App;
    const system = app.system;
    let returningValue = `${system.version} - ${system.node}`;
    return returningValue;
  });
  const [title, setTitle] = useState<any>();
  useEffect(() => {
    http.utils.version().then((result) => {
      setTitle(
        "Server response: " +
          result.version +
          " - Environment: " +
          result.environment
      );

      if (result.environment !== "PROD") {
        setSystemInfo((prev) => {
          return (
            prev +
            " (server: " +
            result.version +
            " - " +
            result.environment +
            ")"
          );
        });
      }
    });
  }, [http.utils, setSystemInfo]);
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} gap={2}>
            <Typography variant="h5">{firstNameLastName}</Typography>
            <Button onClick={() =>logoutHandler()}><Typography>Logout</Typography></Button>
          </Box>
          <Typography>
            Version:{" "}
            <span
              title={title}
              className={
                process.env.REACT_APP_DEBUG === "true"
                  ? "app__version--debug"
                  : ""
              }
            >
              {systemInfo}
            </span>
          </Typography>
          <Typography>
            &copy; 2013-{new Date().getFullYear()} Trendrating SA
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
