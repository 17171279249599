import { useCallback, useEffect, useMemo, useState } from "react";
import { WhatWherePie } from "../../../../../../../components/WhatWherePie/WhatWherePie";
import TodayInsight from "./widgets/TodayInsight";
import { Tcr } from "./widgets/Tcr";
import { DowngradeUpgrade } from "./widgets/DowngradeUpgrade";
import { Distribution } from "./widgets/Distribution";
import { KeyData } from "./widgets/KeyData";
import { PerformerWidget } from "./widgets/PerformerWidget";
import { RatingChange } from "./widgets/RatingChange";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import { Box, CircularProgress, Typography } from "@mui/material";
import { config } from "../../../../../config-ts";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";

type TabOverviewProps = {
  dataManager: PortfolioAnalyzeStorage;
};

export function TabOverview({ dataManager }: TabOverviewProps) {
  const [portfolio, setPortfolio] = useState<any>();
  const environment = useEnvironment();

  const configuration = useMemo(() => {
    return environment.get("account").product.configuration;
  }, [environment]);

  const configurationWorkflow = useMemo(() => {
    return configuration["analysis_list"]["workflow"];
  }, [configuration]);

  const holdings = useMemo(
    () => portfolio?.positions ?? [],
    [portfolio?.positions]
  );

  const assetType = useMemo(() => {
    return portfolio?.assetType ?? "Stock";
  }, [portfolio?.assetType]);

  const tcrToday = useMemo(
    () => portfolio?.tcr?.statistics?.today,
    [portfolio?.tcr?.statistics?.today]
  );
  const tcrYesterday = useMemo(
    () => portfolio?.tcr?.statistics?.yesterday,
    [portfolio?.tcr?.statistics?.yesterday]
  );

  const weightsPerRating = useMemo(
    () => portfolio?.weightsPerRating ?? { A: 0, B: 0, C: 0, D: 0 },
    [portfolio?.weightsPerRating]
  );

  const cardinalityPerRating = useMemo(
    () => portfolio?.cardinalityPerRating ?? { A: 0, B: 0, C: 0, D: 0 },
    [portfolio?.cardinalityPerRating]
  );

  const lastMonthUpgrades = useMemo(
    () => portfolio?.lastMonthUpgrades,
    [portfolio?.lastMonthUpgrades]
  );
  const lastMonthDowngrades = useMemo(
    () => portfolio?.lastMonthDowngrades,
    [portfolio?.lastMonthDowngrades]
  );

  const benchmark = useMemo(() => portfolio?.benchmark, [portfolio?.benchmark]);

  const portfolioType = useMemo(() => portfolio?.type, [portfolio?.type]);

  const portfolioId = useMemo(() => portfolio?.id, [portfolio?.id]);

  const portfolioName = useMemo(() => portfolio?.name, [portfolio?.name]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToEdit = useCallback(() => {
    const uri = `/app/analysis/lists/${portfolioId}/edit/`;

    // *************************** USAGE ***************************
    var usage = window.App.usage;
    var info = {
      action: "LANDING",
      actionParam: portfolioId,
      function: "PORTFOLIO_EDIT",
    };
    usage.record(info);
    // *************************** USAGE ***************************

    navigate(uri);
  }, [navigate, portfolioId]);

  const goToOptimize = useCallback(() => {
    const uri = `/app/analysis/lists/${portfolioId}/optimize/`;

    // *************************** USAGE ***************************
    var usage = window.App.usage;
    var info = {
      action: "LANDING",
      actionParam: portfolioId,
      function: "PORTFOLIO_OPTIMIZE",
    };
    usage.record(info);
    // *************************** USAGE ***************************

    navigate(uri);
  }, [navigate, portfolioId]);

  const isReadOnly = useMemo(
    () => portfolio?.isReadOnly,
    [portfolio?.isReadOnly]
  );

  const onComponentMount = useCallback(async () => {
    const list = await dataManager.get("overview");

    setPortfolio(list);
  }, [dataManager]);

  const portfolioReady = useMemo(() => portfolio != null, [portfolio]);
  const { broadcast } = useBroadcast();

  const manageWorkflow = useCallback(() => {
    let actions: any = [];
    let action: any = null;

    if (!isReadOnly) {
      action = {
        componentJSX: (
          <li className="menu__item" onClick={goToEdit}>
            {t("Edit")}
          </li>
        ),
      };

      actions.push(action);
    }
    // optimize
    if (!isReadOnly && configurationWorkflow["optimize"]["enabled"] === true) {
      if (portfolioType === "PORTFOLIO") {
        action = {
          componentJSX: (
            <li className="menu__item" onClick={goToOptimize}>
              {t("Optimize")}
            </li>
          ),
        };

        actions.push(action);
      }
    }

    action = {
      componentJSX: (
        <ReportButton
          page={"analysisList"}
          target={{ id: portfolioId }}
          rankingCache={null}
          title={portfolioName ?? ""}
          usage={window.App.usage}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "analysisList",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    configurationWorkflow,
    goToEdit,
    goToOptimize,
    isReadOnly,
    portfolioId,
    portfolioName,
    portfolioType,
    t,
  ]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  useEffect(() => {
    manageWorkflow();
  }, [manageWorkflow]);

  return portfolioReady ? (
    <div style={{ height: "100%", overflow: "auto" }}>
      <div>
        <div data-dojo-attach-point="todayInsightReact">
          <TodayInsight
            holdings={holdings}
            tcrToday={tcrToday}
            tcrYesterday={tcrYesterday}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <div className="tAnalysisOverview-box">
          <div data-dojo-attach-point="widgetTcrNode">
            <Tcr tcrYesterday={tcrYesterday} tcrToday={tcrToday} />
          </div>
        </div>
        <div className="tAnalysisOverview-box">
          <div
            data-dojo-attach-point="widgetDistribution"
            data-dojo-type="app/pages/analysisLists/analyze/overview/Distribution"
          >
            <Distribution
              weightsPerRating={weightsPerRating}
              cardinalityPerRating={cardinalityPerRating}
            />
          </div>
        </div>
        <div className="tAnalysisOverview-box">
          <div
            data-dojo-attach-point="widgetDowngradeUpgrade"
            data-dojo-type="app/pages/analysisLists/analyze/overview/DowngradeUpgrade"
          >
            <DowngradeUpgrade
              lastMonthDownGrades={lastMonthDowngrades}
              lastMonthUpgrades={lastMonthUpgrades}
            />
          </div>
        </div>
        <div className="tAnalysisOverview-box">
          <div data-dojo-attach-point="widgetKeyData">
            <KeyData
              holdingsLength={holdings.length ?? 0}
              benchmarkSymbol={benchmark}
              portfolioId={portfolioId}
              portfolioType={portfolioType}
            />
          </div>
        </div>
      </div>
      <div className="tAnalysisOverview-layout tAnalysisOverview-layout--marginTop">
        <div className="tAnalysisOverview-box tAnalysisOverview-box--100">
          <div data-dojo-attach-point="reactPerformerWidget">
            <PerformerWidget portfolioId={portfolioId} />
          </div>
        </div>
      </div>
      <div
        className="tAnalysisOverview-layout tAnalysisOverview-layout--marginTop"
        style={{ display: "flex", gap: "8px" }}
      >
        <div className="tAnalysisOverview-box" style={{ flex: 1 }}>
          <div>
            <WhatWherePie
              holdings={holdings}
              alignChartOnTop={false}
              optionsByAssetType={{ enabled: true, assetType: assetType }}
              isPieClickable={false}
            />
          </div>
        </div>
        <div className="tAnalysisOverview-box" style={{ flex: 1 }}>
          <div>
            <RatingChange holdings={holdings} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Box
      display={"flex"}
      flex={1}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        flex={1}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <CircularProgress sx={{ color: "#2a7090" }} />{" "}
        <Typography>Loading...</Typography>
      </Box>
    </Box>
  );
}
