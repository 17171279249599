import { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deepClone } from "../../deepClone";
import { useEnvironment } from "../../hooks/useEnvironment";
import { MenuItem } from "./MenuItem";
import { MenuItemConfiguration, menu } from "./_menu";

export function Menu() {
  const [items, setItems] = useState<typeof menu>([]);
  const environment = useEnvironment();
  const { t } = useTranslation();

  useEffect(() => {
    if (environment == null) {
      console.log("Menu: environment not ready");
      return;
    }
    const account = environment.get("account");
    const configuration = account?.product?.configuration;
    const strategyTrackerConfiguration = environment
      .get("configuration")
      .get("strategyTracker");
    const user = account?.user;
    if (
      configuration == null ||
      strategyTrackerConfiguration == null ||
      user == null
    ) {
      console.log("Menu: not ready");
      return;
    }

    const order = configuration.mainMenuOrder;
    const options = deepClone(menu);
    options.sort((a, b) => {
      return order.indexOf(a.module) > order.indexOf(b.module) ? 1 : -1;
    });

    const items: MenuItemConfiguration[] = [];
    for (const option of options) {
      option.isEnabled = configuration?.[option.module]?.enabled ?? false;

      if (option.isEnabled) {
        option.menu_label =
          t(configuration[option.module].menu_label) ??
          configuration[option.module].menu_label;
        const userPreferences = user?.preferences?.preferences;
        if (
          option.module === "strategy_tracker" &&
          strategyTrackerConfiguration.enabled
        ) {
          if (userPreferences?.["indexes"]?.length > 0) {
            items.push(option);
          }
        } else {
          items.push(option);
        }
      }
    }
    setItems(items);
  }, [environment, t]);

  const handleClick: (item: MenuItemConfiguration) => MouseEventHandler =
    (item) => () => {
      if (environment?.get("setup")?.customerCare?.isImpersonating === false) {
        var usage = window.App.usage;
        var info = {
          action: "LANDING",
          actionParam: null,
          function: item.usageFunction,
        };
        usage.record(info);
      }
    };

  return (
    <nav aria-label="Main Navigation" className="app__menu" role="navigation">
      <ul id="app-menu" className="menu menu--horizontal menu--main">
        {items.map((item) => (
          <MenuItem key={item.module} item={item} handleClick={handleClick} />
        ))}
      </ul>
    </nav>
  );
}
