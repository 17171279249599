type CreateEditType = {
  initvalue:any;
  id: string | number | null;
  type: "PORTFOLIO" | "BASKET";
  name: string;
  benchmark: string | null;
  weightsManagement: {
    currency: null | string;
    date: null | string;
    type: "fixed" | "drifting";
  };
  positions: {
    symbol: string;
    weight: number;
  }[];
  updateTime: number | null;
};

type ActionType = {
  type:
    | "INIT_EDIT"
    | "SET_NAME"
    | "SET_WEIGHTS"
    | "SET_BENCHMARK"
    | "SET_POSITIONS"
    | "SET_INIT_POSITIONS";
  payload: any;
};

export const createEditReducer = (
  draft: CreateEditType,
  action: ActionType
): any => {
  switch (action.type) {
    case "SET_NAME":
      draft.name = action.payload;
      break;
    case "SET_WEIGHTS":
      draft.weightsManagement = action.payload;
      break;
    case "SET_BENCHMARK":
      draft.benchmark = action.payload;
      break;
    case "SET_POSITIONS":
      draft.positions = action.payload;
      break;
    case "INIT_EDIT":
      const list = action.payload;
      const _draft = {
        initvalue: {
          id: list.id,
          name: list.name,
          type: list.type,
          benchmark: list.benchmark,
          weightsManagement: list.weightsManagement,
          positions: [],
          updateTime: list.updateTime,
        },
        id: list.id,
        name: list.name,
        type: list.type,
        benchmark: list.benchmark,
        weightsManagement: list.weightsManagement,
        positions: [],
        updateTime: list.updateTime,
      };
      return _draft;
    case "SET_INIT_POSITIONS":
      draft.initvalue.positions = action.payload;
      break;
    default:
      break;
  }
};
