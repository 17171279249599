import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal/Modal";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import AllocationPie from "./fields/reactComponents/React_Allocation/React_AllocationPie";
import Cover from "./fields/reactComponents/React_Cover";
import Heading from "./fields/reactComponents/React_Heading";
import HoldingsCharts from "./fields/reactComponents/React_HoldingCharts/React_HoldingsCharts";
import HoldingsTable from "./fields/reactComponents/React_HoldingsTable/React_HoldingsTable";
import MomentumBreakdown from "./fields/reactComponents/React_MomentumBreakdown/React_MomentumBreakdown";
import NewHighLow from "./fields/reactComponents/React_NewHighLow/React_NewHighLow";
import Paragraph from "./fields/reactComponents/React_Paragraph/React_Paragraph";
import RatingChange from "./fields/reactComponents/React_RatingChange/React_RatingChange";
import VerticalSpacing from "./fields/reactComponents/React_Spacing";
import TCR from "./fields/reactComponents/React_TCR/React_TCR";
import Title from "./fields/reactComponents/React_Title";
import TopBottomPerformer from "./fields/reactComponents/React_TopBottomPerformer/React_TopBottomPerformer";
import Market from "./fields/reactComponents/peer/React_Market";
import AsOfTodayPerformance from "./fields/reactComponents/strategy/React_AsOfTodayPerformance/React_AsOfTodayPerformance";
import Breakdown from "./fields/reactComponents/strategy/React_Breakdown/React_Breakdown";
import Chart from "./fields/reactComponents/strategy/React_Chart/React_Chart";
import Facts from "./fields/reactComponents/strategy/React_Facts/React_Facts";
import Holdings from "./fields/reactComponents/strategy/React_Holdings/React_Holdings";
import KeyFacts from "./fields/reactComponents/strategy/React_KeyFacts/React_KeyFacts";
import MonthlyAnalytics from "./fields/reactComponents/strategy/React_MonthlyAnalytics/React_MonthlyAnalytics";
import QuarterlyAnalytics from "./fields/reactComponents/strategy/React_QuarterlyAnalytics/React_QuarterlyAnalytics";
import YearToDateAnalytics from "./fields/reactComponents/strategy/React_YearToDateAnalytics/React_YearToDateAnalytics";
import YearlyAnalitics from "./fields/reactComponents/strategy/React_YearlyAnalytics/React_YearlyAnalitics";
import Summary from "./fields/reactComponents/strategy/React_summary/React_Summary";
import Sector from "./fields/reactComponents/peer/React_Sector";
import PdChildren from "./fields/reactComponents/peer/React_PerformaceDispersion";
import DrillDownSettings from "./fields/reactComponents/peer/React_Drilldown";
import { CustomizableTable } from "./fields/reactComponents/peer/CustomizableTable";
type SettingsFactoryProps = {
  configObj: any;
  panelID: string;
  wysiwygState: any;
  onCancelFunc: Function;
  setOutterState: Function;
  columnsAvailable: any;
};

const getPropertiesToColumnsAvailable = (
  columnsAvailable: any[],
  env,
  translator
) => {
  const arrProps: Object = env.setup.properties;
  //* unpacking elements from arrProps["ETF"]
  if (arrProps["ETF"] != null) {
    const keysETF = Object.keys(arrProps["ETF"]);
    keysETF.forEach((key) => {
      arrProps[key] = arrProps["ETF"][key];
    });
    delete arrProps["ETF"];
  }
  //*****************************************

  //* unpacking elements from arrProps["ETF"]
  if (arrProps["Stock"]) {
    const keysStock = Object.keys(arrProps["Stock"]);
    keysStock.forEach((key) => {
      arrProps[key] = arrProps["Stock"][key];
    });
    delete arrProps["Stock"];
  }
  //*****************************************

  //* retrieve the tags
  const tagSet = new Set();
  columnsAvailable.forEach((element) => {
    const field = element.field;
    if (arrProps[field] != null) {
      if (arrProps[field]["tags"] != null) {
        tagSet.add(arrProps[field]["tags"][0]);
      }
    }
  });
  //*****************************************

  //* creating the obj which every key is an array
  let obj: Object = {};
  Array.from(tagSet).forEach((element: any) => {
    obj[translator(element)] = [];
  });
  columnsAvailable.forEach((element) => {
    if (arrProps[element.field]["tags"][0] != null) {
      const tag = arrProps[element.field]["tags"][0];
      let test = translator(tag);
      if (element.panelIndex != null) {
        obj[test].push({
          ...element,
          label: arrProps[element.field]["name"][element.panelIndex],
        });
      } else {
        obj[test].push({
          ...element,
          label: arrProps[element.field]["name"][0],
        });
      }
    }
  });
  //*****************************************
  return obj;
};

export default function SettingsFactory({
  onCancelFunc,
  configObj,
  panelID,
  setOutterState,
  columnsAvailable,
  wysiwygState,
}: SettingsFactoryProps) {
  useEffect(() => {
    //* showing .trReportPrintStyle when the modal closes
    return () => {
      const arrElements: any = Array.from(
        document.getElementsByClassName("trReportPrintStyle")
      );
      if (arrElements.length > 0) {
        arrElements.forEach((element) => {
          element.style.display = "block";
        });
      }
    };
  });

  //!!---------------------------
  //!!---------------------------
  //? why to hide this element?
  //* because .trReportPrintStyle keep on
  //* stealing the focus thus inputs can't
  //* be filled

  const arrElements: any = Array.from(
    document.getElementsByClassName("trReportPrintStyle")
  );
  if (arrElements.length > 0) {
    arrElements.forEach((element) => {
      element.style.display = "none";
    });
  }
  //!!---------------------------
  //!!---------------------------

  //-------------------------------------------------------------
  //-------------------------------------------------------------
  // the prop columnsAvailable arrives without the property label
  // we must retrieve this property thus we will use the environment
  // variable to get it
  const { t } = useTranslation();
  const env = useEnvironment();
  const columnsAvailableWithPropertiesneeded = useMemo(() => {
    return getPropertiesToColumnsAvailable(columnsAvailable, env, t);
  }, [columnsAvailable, env, t]);
  //-------------------------------------------------------------
  //-------------------------------------------------------------

  const widgetRef = useRef<any>(null);
  const widgetToRender = useMemo(() => {
    let WidgetToReturn: any = null;
    switch (panelID) {
      case "REPORT_COMMON_TITLE":
        WidgetToReturn = <Title ref={widgetRef} configObj={configObj} />;
        break;
      case "REPORT_COMMON_SPACING":
        WidgetToReturn = (
          <VerticalSpacing ref={widgetRef} configObj={configObj} />
        );
        break;
      case "REPORT_COMMON_HEADER_1":
        WidgetToReturn = <Heading ref={widgetRef} configObj={configObj} />;
        break;
      case "REPORT_COMMON_SECURITY_CHART":
        WidgetToReturn = (
          <HoldingsCharts
            ref={widgetRef}
            configObj={configObj}
            columns={wysiwygState.columns}
          />
        );
        break;
      case "REPORT_COMMON_SECURITY_TABLE":
        WidgetToReturn = (
          <HoldingsTable
            wysiwyg={wysiwygState}
            ref={widgetRef}
            columnsAvailable={columnsAvailableWithPropertiesneeded}
            configObj={configObj}
          />
        );
        break;

      case "REPORT_COMMON_COVER":
        WidgetToReturn = <Cover ref={widgetRef} configObj={configObj} />;
        break;
      case "REPORT_COMMON_PARAGRAPH":
        WidgetToReturn = <Paragraph ref={widgetRef} configObj={configObj} />;
        break;
      case "REPORT_PORTFOLIO_TCR":
        WidgetToReturn = <TCR configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_COMMON_ALLOCATION":
        WidgetToReturn = (
          <AllocationPie configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_CUSTOMIZABLE_PEER_TABLE":
        WidgetToReturn = (
          <CustomizableTable configObj={configObj} ref={widgetRef} />
        );
        break;

      case "REPORT_PORTFOLIO_RATING_CHANGE":
        WidgetToReturn = <RatingChange configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_PORTFOLIO_NEW_HIGH_NEW_LOW":
        WidgetToReturn = <NewHighLow configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_PORTFOLIO_PERFORMER":
        WidgetToReturn = (
          <TopBottomPerformer configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_PORTFOLIO_MOMENTUM_BREAKDOWN":
        WidgetToReturn = (
          <MomentumBreakdown configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_FACTS":
        WidgetToReturn = <Facts configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_STRATEGY_AS_OF_TODAY_PERFORMANCE":
        WidgetToReturn = (
          <AsOfTodayPerformance configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_KEY_FACTS":
        WidgetToReturn = <KeyFacts configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_STRATEGY_YEAR_TO_DATE_ANALYTICS":
        WidgetToReturn = (
          <YearToDateAnalytics configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_YEARLY_ANALYTICS":
        WidgetToReturn = (
          <YearlyAnalitics configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_MONTHLY_ANALYTICS":
        WidgetToReturn = (
          <MonthlyAnalytics configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_QUARTERLY_ANALYTICS":
        WidgetToReturn = (
          <QuarterlyAnalytics configObj={configObj} ref={widgetRef} />
        );
        break;
      case "REPORT_STRATEGY_BREAKDOWN":
        WidgetToReturn = <Breakdown configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_STRATEGY_SUMMARY":
        WidgetToReturn = <Summary configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_STRATEGY_CHART":
        WidgetToReturn = <Chart configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_STRATEGY_HOLDINGS":
        WidgetToReturn = (
          <Holdings
            configObj={configObj}
            ref={widgetRef}
            columnsAvailable={columnsAvailableWithPropertiesneeded}
          />
        );
        break;
      case "REPORT_PEER_TCR":
      case "REPORT_PEER_WHERE_TCR_CHANGES":
      case "REPORT_PEER_WHERE_OVERVIEW":
      case "REPORT_PEER_WHERE_AB_CHANGES":
      case "REPORT_PEER_WHERE_UPGRADES_DOWNGRADES":
        WidgetToReturn = <Market configObj={configObj} ref={widgetRef} />;
        break;

      case "REPORT_PEER_TCR_FOCUS":
      case "REPORT_PEER_FOCUS_UPGRADES_DOWNGRADES":
        WidgetToReturn = (
          <DrillDownSettings ref={widgetRef} configObj={configObj} />
        );
        break;

      case "REPORT_PEER_WHAT_OVERVIEW":
      case "REPORT_PEER_WHAT_TCR_CHANGES":
      case "REPORT_PEER_WHAT_AB_CHANGES":
      case "REPORT_PEER_WHAT_UPGRADES_DOWNGRADES":
      case "REPORT_PEER_OVERVIEW":
      case "REPORT_PEER_UPGRADES_DOWNGRADES":
      case "REPORT_PEER_TCR_CHANGES":
      case "REPORT_PEER_AB_CHANGES":
        WidgetToReturn = <Sector configObj={configObj} ref={widgetRef} />;
        break;
      case "REPORT_PEER_DISPERSION_CHILDREN":
      case "REPORT_DISPERSION_TCR_TABLE":
      case "REPORT_DISPERSION_RATIO_TABLE":
      case "REPORT_PEER_DISPERSION":
      case "REPORT_BASKET_DISPERSION_BY_CHART":
      case "REPORT_BASKET_DISPERSION_BY_SECTORS":
      case "REPORT_DISPERSION_BY_SECTORS":
      case "REPORT_DISPERSION_BY_CHART":
        WidgetToReturn = <PdChildren configObj={configObj} ref={widgetRef} />;
        break;
    }
    return WidgetToReturn;
  }, [columnsAvailableWithPropertiesneeded, configObj, panelID, wysiwygState]);

  return (
    <Modal
      closeIcon={false}
      onClose={() => onCancelFunc()}
      buttonsEnalbed
      closeOnClickAway={false}
      customCss={{ maxWidth: "70%", width: "auto" }}
      buttons={[
        {
          name: "Confirm",
          callback: () => {
            const updatedState = widgetRef?.current?.getState();
            setOutterState(updatedState);
          },
        },
        { name: "Cancel", callback: () => onCancelFunc(), variant: "cancel" },
      ]}
      headerConfig={{ headerContent: configObj.widgetLabel + " settings" }}
    >
      {widgetToRender}
    </Modal>
  );
}
