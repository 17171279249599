import { Box, Card, CardContent } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import style from "./SortableList.module.scss";
import SortableListItem from "./SortableListItem";

type SortableListProps = {
  isSortable: boolean;
  arrItems: any[];
  listSetter: Function;
  header?: { headerTitle?: string | React.ReactElement; headerStyle?: React.CSSProperties };
  listContainerStyle?: React.CSSProperties;
  getSelectedItemToEdit: Function;
  selectedItemFromOutside: any;
  emptyListStatement?: string;
};

//********************************
//* edit list functions
//********************************
const moveItemDown = (indexOfItem, arr, setArr) => {
  const item = arr[indexOfItem];
  const index = indexOfItem;
  if (arr[index + 1]) {
    let arrTemp = [...arr];
    arrTemp.splice(index, 1);
    arrTemp.splice(index + 1, 0, item);
    setArr(arrTemp);
  }
};
const moveItemUp = (indexOfItem, arr, setArr) => {
  const item = arr[indexOfItem];
  const index = indexOfItem;
  if (arr[index - 1]) {
    let arrTemp = [...arr];
    arrTemp.splice(index, 1);
    arrTemp.splice(index - 1, 0, item);
    setArr(arrTemp);
  }
};
const removeItem = (indexOfItem, arr, setArr) => {
  const index = indexOfItem;
  let arrTemp = [...arr];
  arrTemp.splice(index, 1);
  setArr(arrTemp);
};
//********************************
//********************************

const selectListItem = (selectedItem, listRef) => {
  if (selectedItem != null) {
    const list = listRef?.current?.children;
    const li: any = listRef?.current?.children[selectedItem];
    if (li != null) {
      for (let i = 0; i < list!.length; i++) {
        if (list?.[i].classList.contains(style.selectedItem)) {
          list?.[i].classList.remove(style.selectedItem);
        }
      }
      li.classList.add(style.selectedItem);
    } else {
      return;
    }
  }
};

export default function SortableList({
  isSortable,
  arrItems,
  listSetter,
  getSelectedItemToEdit,
  listContainerStyle,
  header,
  selectedItemFromOutside,
  emptyListStatement,
}: SortableListProps) {
  const [selectedItem, setSelectedItem] = useState<any>(selectedItemFromOutside);
  useEffect(() => setSelectedItem(selectedItemFromOutside), [selectedItemFromOutside]);
  const listRef = useRef<HTMLUListElement>(null);
  const list = useMemo(() => arrItems, [arrItems]);

  //********************************
  //* edit list triggers
  //********************************
  const moveItemDownTrigger = (i: number) => {
    moveItemDown(i, list, listSetter);
    setSelectedItem(null);
  };
  const moveItemUpTrigger = (i: number) => {
    moveItemUp(i, list, listSetter);
    setSelectedItem(null);
  };
  const removeItemTrigger = (i: number) => {
    removeItem(i, list, listSetter);
    setSelectedItem(null);
  };
  //********************************
  //********************************
  useEffect(() => {
    if (selectedItem == null) {
      const list = listRef?.current?.children;
      if (list != null) {
        for (let i = 0; i < list!.length; i++) {
          if (list?.[i].classList.contains(style.selectedItem)) {
            list?.[i].classList.remove(style.selectedItem);
          }
        }
      }
    }
  }, [selectedItem]);

  const getSelectedItem = useCallback(
    (v) => {
      getSelectedItemToEdit(v);
    },
    [getSelectedItemToEdit]
  );

  useEffect(() => {
    if (selectedItem != null) {
      selectListItem(selectedItem, listRef);
      getSelectedItem(selectedItem);
    } else {
      getSelectedItem(null);
    }
  }, [getSelectedItem, selectedItem]);

  const baselistContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: 4,
  };
  return (
    <Card
      style={
        listContainerStyle
          ? { ...baselistContainerStyle, ...listContainerStyle }
          : baselistContainerStyle
      }
    >
      <div style={header?.headerStyle ? header.headerStyle : {}}>
        <Box style={{ padding: "8px 16px" }}>{header ? header.headerTitle : ""}</Box>
      </div>
      <CardContent sx={{ maxHeight: "100%", overflow: "auto" }}>
        {list.length > 0 ? (
          <ul ref={listRef} className={style.sortableList}>
            {list.map((listItem, index) => (
              <SortableListItem
                key={index}
                lenList={list.length}
                // selectedItem={selectedItem}
                indexItem={index}
                onSelect={(v) =>
                  setSelectedItem((prev) => {
                    if (v === prev) {
                      return null;
                    } else {
                      return v;
                    }
                  })
                }
                content={listItem.content}
                moveItemDown={moveItemDownTrigger}
                moveItemUp={moveItemUpTrigger}
                removeItem={removeItemTrigger}
                showControls={isSortable}
              />
            ))}
          </ul>
        ) : (
          <div style={{ backgroundColor: "#fff" }}>
            {emptyListStatement ? emptyListStatement : "empty list"}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
