/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/generator/strategySummary
 * @summary Strategy - Summary
 *
 * 13 Aug 2020
 * Warning: depdendency on trendrating-widgets for i18n, please refactor
 *
 */

import { Strategies } from "../../../api/compute/Strategies";
import { StrategyFormatter } from "../../../trendrating/formatter/StrategyFormatter";
import { customPropertiesFormattingOptions } from "./customPropertiesFormattingOptions";
import { escapeEntity } from "./Generator";

export function sectionsSummary(input, section, datum, formatter, environment) {
    const _sections: any = [];

    if (section["content"]["headline"]["isEnabled"]) {
        const _section = {
            data: {
                text: escapeEntity(datum["headline"].toUpperCase()),
            },
            type: "header1",
        };
        _sections.push(_section);
    }

    const strategyFormat = new StrategyFormatter({
        environment: environment,
        formatType: "PDF",
    }).getFormatter();

    const params = datum["params"];
    // strategy
    _sections.push(sectionStrategy(params, strategyFormat, datum, formatter));

    // investment universe
    _sections.push(sectionUniverse(params, strategyFormat, datum, formatter));

    // selection rules
    _sections.push(sectionSelection(params, strategyFormat, datum, formatter));

    // ranking rules
    const _section = sectionRanking(params, strategyFormat, datum, formatter);
    if (_section != null) {
        _sections.push(_section);
    }

    // weighting rules
    _sections.push(sectionWeighting(params, strategyFormat, datum, formatter));

    // allocation constraints
    _sections.push(sectionAllocation(params, strategyFormat, datum, formatter));

    // Show only if not systematic
    if (!datum["isSystematic"]) {
        if (datum["hasCustomStartingDate"]) {
            // No backtesting for custom starting / launch date
        } else {
            // backtesting
            _sections.push(
                sectionBacktesting(params, strategyFormat, datum, formatter)
            );
        }

        // hedging
        const _section = sectionHedging(
            params,
            strategyFormat,
            datum,
            formatter
        );
        if (_section != null) {
            _sections.push(_section);
        }
    }

    return _sections;
}

function buildTable(title?) {
    const table = {
        data: {
            body: [],
            head: [
                [
                    {
                        style: null,
                        value: title != null ? title : "",
                    },
                    {
                        style: null,
                        value: "",
                    },
                ],
            ],
        },
        type: "table",
    };
    return table;
}
/**
 * Add a row, if the length is > 2, the second, third... columns are merged in a single one
 * The table is made to have 3 columns, the third is always empty.
 *
 * TODO: make the print systema aware of the SUMMARY table type, and make a custom style
 * instead of this hack of using 3 columns (33% space for each content)
 *
 * @param {Object} table
 * @param {Array} row
 */
function addRowToTable(table, row, formatter) {
    const format = formatter;
    const adjustedRow: any = [];

    adjustedRow.push({
        style: null,
        value: format.custom("string", {
            options: customPropertiesFormattingOptions["DEFAULT"],
            output: "PDF",
            value: row[0],
            valueHelper: null,
        }),
    });

    // Add first and second,third...concatenated result
    const output: any = [];
    for (let i = 1; i < row.length; i++) {
        const value = ("" + row[i]).trim();
        if (value === "") {
            continue; // Ignore empty sections
        }
        output.push(
            format.custom("string", {
                options: customPropertiesFormattingOptions["DEFAULT"],
                output: "PDF",
                value: value,
                valueHelper: null,
            })
        );
    }
    adjustedRow.push({
        style: null,
        value: output.join(" - "),
    });

    table["data"]["body"].push(adjustedRow);
    return table;
}

function sectionAllocation(params, strategyFormat, data, formatter) {
    const table = buildTable("Allocation constraints");
    const value = params["allocation"];

    addRowToTable(
        table,
        ["Invested", strategyFormat.cash(value["weightInCash"])],
        formatter
    );
    if (value["weightCappingSecurity"] != null) {
        addRowToTable(
            table,
            [
                "Constituents capping",
                strategyFormat.cappingSecurity(value["weightCappingSecurity"]),
            ],
            formatter
        );
    }
    // capping peer
    if (value["weightCappingPeer"] != null) {
        const formattedPeer = strategyFormat.cappingPeer(
            value["weightCappingPeer"]
        );
        addRowToTable(
            table,
            [formattedPeer["label"], formattedPeer["value"]],
            formatter
        );
    }
    return table;
}

function sectionBacktesting(params, strategyFormat, data, formatter) {
    const table = buildTable("Backtesting");
    const value = params["backtesting"];

    const formatted = strategyFormat.period(value["period"]);
    addRowToTable(table, [formatted["label"], formatted["value"]], formatter);
    addRowToTable(
        table,
        [
            "Inception date",
            strategyFormat.inceptionDate(value["inceptionDate"]),
        ],
        formatter
    );
    addRowToTable(
        table,
        [
            "Inception value",
            strategyFormat.inceptionDate(value["inceptionValue"]),
        ],
        formatter
    );
    return table;
}

function sectionHedging(
    params: any,
    strategyFormat: any,
    data: any,
    formatter: any
) {
    const value = params["hedging"];
    if (value != null) {
        const table = buildTable("Hedging");
        if (data["instrumentHedging"] != null) {
            addRowToTable(
                table,
                [
                    "Instrument",
                    strategyFormat.strong(data["instrumentHedging"]["name"]),
                ],
                formatter
            );
        }
        addRowToTable(
            table,
            ["Leverage", strategyFormat.leverage(value["leverage"])],
            formatter
        );
        addRowToTable(
            table,
            ["Rules", strategyFormat.hedgingStrategy(value["constraints"])],
            formatter
        );
        return table;
    }
    return null;
}

function sectionRanking(params, strategyFormat, data, formatter) {
    if (params["ranking"] != null) {
        const table = buildTable("Ranking rules");
        const rows = strategyFormat.ranking(params["ranking"]);
        for (let i = 0; i < rows.length; i++) {
            addRowToTable(table, rows[i], formatter);
        }
        return table;
    }
    return null;
}

function sectionSelection(params, strategyFormat, data, formatter) {
    const table = buildTable("Selection rules");
    sectionSelectionHelper(
        params["selection"],
        table,
        strategyFormat,
        null,
        true,
        formatter
    );
    return table;
}

function sectionSelectionHelper(
    value,
    table,
    strategyFormat,
    introSubheader,
    showNotSet,
    formatter
) {
    if (value != null) {
        if (introSubheader != null) {
            addRowToTable(table, [introSubheader], formatter);
        }
        const rows = strategyFormat.selection(value);
        for (let i = 0; i < rows.length; i++) {
            addRowToTable(table, rows[i], formatter);
        }
    } else {
        if (showNotSet) {
            addRowToTable(table, ["Not set"], formatter);
        }
    }
}

function sectionStrategy(params, strategyFormat, data, formatter) {
    const table = buildTable();
    const value = params["strategy"];

    // name

    // TODO OLD: never used anywhere, old code
    // if (this.hasNameInStrategyFieldset === true) {
    //     addRowToTable(table, ["Name", strategy["name"]], formatter);
    // }

    if (data["benchmark"] != null) {
        if (data["benchmark"] === Strategies.SYMBOL_NEUTRAL_STRATEGY) {
            addRowToTable(
                table,
                ["Benchmark", strategyFormat.strong("Neutral strategy")],
                formatter
            );
        } else {
            addRowToTable(
                table,
                ["Benchmark", strategyFormat.strong(data["benchmark"]["name"])],
                formatter
            );
        }
    } else {
        addRowToTable(
            table,
            ["Benchmark", strategyFormat.strong("Not set")],
            formatter
        );
    }
    // currency
    addRowToTable(
        table,
        ["Currency", strategyFormat.currency(value["currency"])],
        formatter
    );
    // rebalance
    addRowToTable(
        table,
        ["Rebalance", strategyFormat.rebalance(value["rebalance"])],
        formatter
    );
    // nr. holdings
    addRowToTable(
        table,
        ["Number of holdings", strategyFormat.strong(value["holdings"])],
        formatter
    );
    // performance
    addRowToTable(
        table,
        ["Performance", strategyFormat.performance(value["performance"])],
        formatter
    );
    return table;
}

function sectionUniverse(params, strategyFormat, data, formatter) {
    const table = buildTable("Investment universe");
    const value = params["universe"];

    // ------------------------------------------------------- screening
    if (value["screening"] != null) {
        addRowToTable(
            table,
            [
                "Instrument type",
                strategyFormat.instrumentType({
                    domestic: value["screening"]["whereSource"]["domestic"],
                    foreign: value["screening"]["whereSource"]["foreign"],
                    instrumentType: value["screening"]["instrumentType"],
                    instrumentTypeSub:
                        value["screening"]["whereSource"][
                            "stockClassification"
                        ],
                }),
            ],
            formatter
        );

        // investment region ETF
        if (
            value["screening"]["whereTarget"] != null &&
            value["screening"]["whereTarget"]["market"].length > 0
        ) {
            addRowToTable(
                table,
                [
                    "Investment region",
                    strategyFormat.where(
                        value["screening"]["whereTarget"]["market"]
                    ),
                ],
                formatter
            );
        }
        // markets
        if (value["screening"]["whereSource"]["market"].length > 0) {
            addRowToTable(
                table,
                [
                    "Markets",
                    strategyFormat.where(
                        value["screening"]["whereSource"]["market"]
                    ),
                ],
                formatter
            );
        }
        // sectors
        if (value["screening"]["what"].length > 0) {
            addRowToTable(
                table,
                ["Sectors", strategyFormat.what(value["screening"]["what"])],
                formatter
            );
        }
        // eligibility
        addRowToTable(
            table,
            [
                "Eligibility",
                strategyFormat.eligibility(value["screening"]["eligibility"]),
            ],
            formatter
        );
    }
    // ------------------------------------------------------------ list
    if (data["whiteList"] != null) {
        addRowToTable(
            table,
            ["White list", strategyFormat.strong(data["whiteList"]["name"])],
            formatter
        );
    }
    // ------------------------------------ additional rules (selection)
    sectionSelectionHelper(
        value["selection"],
        table,
        strategyFormat,
        "<i>Additional rules</i>",
        false,
        formatter
    );
    return table;
}

function sectionWeighting(params, strategyFormat, data, formatter) {
    const table = buildTable("Weighting rules");
    const value = params["weighting"];
    addRowToTable(
        table,
        ["Schema", strategyFormat.weightingSchema(value["weightingSchema"])],
        formatter
    );
    addRowToTable(
        table,
        [
            "Existing positions",
            strategyFormat.weightingSchemaExistingPositions(
                value["weightingSchemaExistingPositions"]
            ),
        ],
        formatter
    );
    if (value["smartBeta"] != null) {
        const formatted = strategyFormat.smartBeta(value["smartBeta"]);
        if (formatted.length > 0) {
            const rows: any = [];
            for (let i = 0; i < formatted.length; i++) {
                const _row = formatted[i];
                rows.push(
                    [
                        _row[0],
                        _row[1],
                        typeof _row[2] === "object"
                            ? "A: " +
                              _row[2]["A"] +
                              ", B: " +
                              _row[2]["B"] +
                              ", C: " +
                              _row[2]["C"] +
                              ", D: " +
                              _row[2]["D"]
                            : _row[2],
                    ].join(" - ")
                );
            }
            addRowToTable(table, ["Smart beta", rows.join("<br/>")], formatter);
        }
    }
    if (value["rotation"] != null) {
        addRowToTable(
            table,
            ["Rotation rules", strategyFormat.rotation(value["rotation"])],
            formatter
        );
    }
    return table;
}
