import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  // CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import style from "./WizzardComponents.module.scss";

type WizzPropertiesProps = {
  properties: any[];
  options: any;
  dispatcher: Function;
  sortedList: any[];
  customStyle?: React.CSSProperties;
  propertyId: string | null;
  isEditing: boolean;
  rState: any;
};

export default function WizzProperties({
  isEditing,
  properties,
  propertyId,
  options,
  dispatcher,
  sortedList,
  customStyle,
  rState,
}: WizzPropertiesProps) {
  const [propertiesToAccordion, setPropertiesToAccordion] = useState<any>(null);
  const [expandedPanel, setExpandedPanel] = useState("");

  useEffect(() => {
    /* 
      creating an obj that has sortedList elements
      as keys and Object[] as value
    */
    if (properties) {
      const obj: any = {};
      sortedList.forEach((topic) => {
        let arr: any = [];
        properties.forEach((item) => {
          if (item["item"]["builderGroup"] === topic) {
            arr.push(item);
          }
          //to identify the accordion that has to be open initially
          if (item["value"] === propertyId) {
            setExpandedPanel(item["item"]["builderGroup"]);
          }
        });
        obj[topic] = arr;
      });
      setPropertiesToAccordion(obj);
    }
  }, [properties, propertyId, sortedList]);

  const handleAccordionChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : "");
    },
    []
  );
  return (
    <div style={customStyle ? customStyle : {}}>
      <Card sx={{ height: "100%", boxShadow: 3 }}>
        <CardContent>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Select a Factor
          </Typography>
          {propertiesToAccordion &&
            sortedList.map((item, index) => {
              return (
                <Accordion
                  sx={{ marginBottom: 0 }}
                  key={index}
                  onChange={handleAccordionChange(item)}
                  expanded={expandedPanel === item}
                >
                  <AccordionSummary
                    sx={{
                      margin: 0,
                      minHeight: "unset !important",
                      "& .Mui-expanded": {
                        margin: "0 !important",
                        marginTop: "20px !important",
                      },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle1">{item}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {propertiesToAccordion[item].map((prop, indexx) => (
                        <li
                          key={indexx}
                          className={
                            prop["value"] === propertyId && isEditing
                              ? "tFormSelectionAddRule-listItem--selected"
                              : prop["value"] === propertyId && isEditing === false
                              ? "tFormSelectionAddRule-listItem--selected"
                              : ""
                          }
                        >
                          <span
                            onClick={(e: any) => {
                              dispatcher({
                                type: "ON_CLICK_PROPERTY_OPTION",
                                payload: { prop: prop, options: options },
                              });
                              if (!isEditing) {
                                highlightSelectedWhenCreating(
                                  e.target,
                                  "tFormSelectionAddRule-listItem--selected"
                                );
                              }
                            }}
                            className={style.listItemContent}
                          >
                            {prop.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
}

const highlightSelectedWhenCreating = (element: HTMLElement, classToApply: string) => {
  if (element) {
    let siblings: any = Array.from(element.parentNode!.parentNode!.childNodes);
    siblings.forEach((item: HTMLElement) => {
      if (item.parentElement!.classList.contains(classToApply)) {
        item.parentElement!.classList.remove(classToApply);
      }
    });
    element.parentElement!.classList.add(classToApply);
  }
};
