import { useCallback, useState } from "react";

type CustomFieldCollapserProps = {
  children: JSX.Element;
  isOpen?: boolean;
  setIsOpen?: Function;
};

export default function CustomFieldCollapser({
  children,
  isOpen,
  setIsOpen,
}: CustomFieldCollapserProps) {
  const [collapserState, setCollapserState] = useState(isOpen ?? false);
  const collapserHandler = useCallback(() => {
    setCollapserState(!collapserState);
    if (setIsOpen) {
      setIsOpen(!collapserState);
    }
  }, [collapserState, setIsOpen]);

  return (
    <div className="field_collapser">
      <div
        className={`field_collapser_controls ${
          collapserState ? "" : "bottom-space"
        }`}
        onClick={collapserHandler}
      >
        <p>{collapserState ? "Less" : "More"}</p>
        <span className={`i-${collapserState ? "up" : "down"}`}></span>
      </div>
      {collapserState ? children : null}
    </div>
  );
}
