/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating/api/compute/Structures
 * @summary Requests for common data structures: taxonomies and properties
 *
 */

import { endpoints } from "../endpoints";
import { _Base } from "../_Base";

export class Structures extends _Base {
    _cache: any; // avoid useless requests

    /**
     * Retrieves configuration for instruments properties
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    properties() {
        return this._cacheManager().then((response: any) =>
            this._prepareProperties(response)
        );
    }

    /**
     * Retrieves application tags
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    tags() {
        return this._cacheManager().then((response: any) =>
            this._prepareTags(response)
        );
    }

    /**
     * Retrieves application taxonomies
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    taxonomies() {
        return this._cacheManager().then((response: any) =>
            this._prepareTaxonomies(response)
        );
    }

    /**
     * Retrieves application taxonomies fields
     *
     * @returns {Promise} a promise fulfilled with the
     *       handled data of the response
     */
    taxonomyFields() {
        return this._cacheManager().then((response: any) =>
            this._prepareTaxonomyFields(response)
        );
    }

    /**
     * Cut out levels that must not visible in widgets.
     * Applied to ICB taxonomy
     *
     * @param {array} taxonomy
     *
     * @returns {array} the taxonomy with cutted levels
     */
    whatUi(taxonomies: any) {
        var EtfException = {
            E_1: "",
            E_2: "",
            E_3: "",
            E_4: "",
        };

        const nodes: any = [];
        const ids: any = [];
        let root = null;
        for (const taxonomy of taxonomies) {
            if (taxonomy.id in EtfException) {
                continue;
            }
            if (taxonomy.parent == null) {
                root = taxonomy;
            }

            if (taxonomy.parent === "ICB" && taxonomy.showInTree) {
                nodes.push(taxonomy);
                ids.push(taxonomy.id);
            }
        }

        const level2: any = [];
        const level2ids: any = [];
        for (let i = 0; i < taxonomies.length; i++) {
            const index = ids.indexOf(taxonomies[i].parent);
            if (index !== -1) {
                level2.push(taxonomies[i]);
                level2ids.push(taxonomies[i].id);
            }
        }

        for (let i = 0; i < taxonomies.length; i++) {
            const index = level2ids.indexOf(taxonomies[i].parent);
            if (index !== -1) {
                taxonomies[i].parent = level2[index].parent;
                nodes.push(taxonomies[i]);
            }
        }

        nodes.push(root);

        nodes.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        });

        const data: any = [];
        for (const item of nodes) {
            data.push({
                parent: item.parent ? item.parent : null,
                name: item.name,
                id: item.id,
                checked: false,
                showInTree: item.showInTree,
                type: item.type,
            });
        }

        return data;
    }

    /**
     * Cleanup and sort taxons
     * Applied to Markets and MarketsFinancial taxonomies
     *
     * @param {array} taxonomy
     *
     * @returns {array} the taxonomy with cutted levels
     */
    where(taxonomy: any) {
        var data: any = [];
        for (let i = 0, length = taxonomy.length; i < length; i++) {
            const item = taxonomy[i];

            data.push({
                parent: item.parent ? item.parent : null,
                name: item.name,
                id: item.id,
                iso: item.iso ? item.iso : null,
                checked: false,
                showInTree: item.showInTree,
                type: item.type,
            });
        }

        data.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

        return data;
    }
    // ----------------------------------------------------- private methods
    _cacheManager() {
        if (this._cache == null) {
            var endPointRoot = this.getEndpointRoot(
                this.environment.api.compute
            );
            var url = endPointRoot + endpoints.structures.get;

            this._cache = this.prepareGet(url, null, null);
        }

        return this._cache;
    }

    _prepareProperties(response: any) {
        return response["data"]["fields"];
    }

    _prepareTags(response: any) {
        return response["data"]["tags"];
    }

    _prepareTaxonomyFields(response: any) {
        const currentFields = response.data.fields;
        const taxonomyByField = { security: {} };

        let f: any = null;
        let f2: any = null;
        let taxonomyName: any = null;
        let type: any = null;

        for (const [fieldKey, fieldValue] of Object.entries(currentFields)) {
            f = fieldValue;

            if (f.formatter != null) {
                for (const value of Object.values(f.formatter)) {
                    if ("taxonomyName" in (value as any)) {
                        taxonomyName = (value as any)["taxonomyName"];
                        taxonomyByField["security"][fieldKey] = taxonomyName;
                    }
                }
            } else {
                type = fieldKey;

                for (const [key, value] of Object.entries(f)) {
                    f2 = value;

                    if (f2?.formatter != null) {
                        for (const valueF2 of Object.values(f2.formatter)) {
                            if ("taxonomyName" in (valueF2 as any)) {
                                if (taxonomyByField[type] == null) {
                                    taxonomyByField[type] = {};
                                }
                                taxonomyName = (valueF2 as any)["taxonomyName"];
                                taxonomyByField[type][key] = taxonomyName;
                            }
                        }
                    }
                }
            }
        }

        return taxonomyByField;
    }

    _prepareTaxonomies(response: any) {
        const _taxonomies = response.data.structures;
        const taxonomies: any = {};

        for (const taxonomyName in _taxonomies) {
            taxonomies[taxonomyName] = [];

            const _taxonomy = _taxonomies[taxonomyName];
            for (let id in _taxonomy) {
                const taxon = _taxonomy[id];

                // cleanup: useless properties
                delete taxon["benchmark"];
                delete taxon["children"];
                delete taxon["isCountry"];
                delete taxon["rankUnique"];
                delete taxon["tag"];

                taxonomies[taxonomyName].push(taxon);
            }
        }

        return taxonomies;
    }
}
