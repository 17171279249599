/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module app/pages/strategies/builder/editors/Rotation
 * @summary Rotation options
 *
 */

import { deepClone } from "../../../../../../../deepClone";

export class Rotation {
  static options = {
    Country: { label: "Markets", value: "Country" },

    Region: { label: "Regions", value: "Region" },

    Area: { label: "Areas", value: "Area" },

    "3 Sector": {
      label: "Industries",
      value: "3 Sector",
    },

    "1 Industry": {
      label: "Sectors",
      value: "1 Industry",
    },

    "Country__3 Sector": {
      label: "Industries in Markets",
      value: "Country__3 Sector",
    },

    "Country__1 Industry": {
      label: "Sectors in Markets",
      value: "Country__1 Industry",
    },

    "Region__1 Industry": {
      label: "Regions in Sectors",
      value: "Region__1 Industry",
    },

    "Area__1 Industry": {
      label: "Area in Sectors",
      value: "Area__1 Industry",
    },

    "Region__3 Sector": {
      label: "Regions in Industries",
      value: "Region__3 Sector",
    },

    "Area__3 Sector": {
      label: "Area in Industries",
      value: "Area__3 Sector",
    },
  } as const;

  static get(type, refValue) {
    refValue = refValue ? refValue : null;
    var options = Rotation.options;
    var prototype: any = null;

    switch (type) {
      case "LABEL_LOOKUP": {
        var key = this.get("OPTION_LOOKUP", refValue);
        return options[key].label;
      }
      case "OPTION_LOOKUP": {
        if (refValue in options) {
          return refValue;
        }

        return null;
      }
      case "OPTIONS": {
        prototype = [
          options["Country"],
          options["Region"],
          options["Area"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
        ];
        return deepClone(prototype);
      }
      case "OPTIONS_EXTENDED": {
        prototype = [
          options["Country"],
          options["Region"],
          options["Country__3 Sector"],
          options["Country__1 Industry"],
          // {
          //     label: "<em>More ...</em>",
          //     value: null,
          //     children: [
          options["Area"],
          options["3 Sector"],
          options["1 Industry"],
          options["Region__1 Industry"],
          options["Area__1 Industry"],
          options["Region__3 Sector"],
          options["Area__3 Sector"],
          //     ],
          // },
        ];
        return deepClone(prototype);
      }
    }

    return null;
  }
}
