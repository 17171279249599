import { useEffect, useState } from "react";
import { deepClone } from "../deepClone";

export const useInputNumberValidation = (obj: Object) => {
  const [state, setState] = useState(obj);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const values: boolean[] = Object.values(state);
    if (values.includes(false)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [state]);

  const setStateKey = (key: string, value: boolean) => {
    if (state[key] != null) {
      let temp = deepClone(state);
      temp[key] = value;
      if (JSON.stringify(temp) !== JSON.stringify(state)) {
        setState(temp);
      }
    } else {
      console.log(`key '${key}' is not valid!`);
    }
  };

  return { isStateValid: isValid, validationWatcher: setStateKey };
};
