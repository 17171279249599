import { useCallback, useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../../api/compute/Taxon";
import { extractForDataIngestion } from "../../../../../../../api/compute/commons";
import { ColumnsHelper } from "../../../../../../../components/InstrumentsTable/Helpers/ColumnsHelper";
import { TrendratingTable } from "../../../../../../../components/table/TrendratingTable";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { Box, CircularProgress, Typography } from "@mui/material";

type WhatWherePositionsProps = {
  portfolioId: number;
  assetType: any;
  type: string;
  portfolioHoldings: any;
  dimensions?: {
    type: "what" | "where" | "whatWhere";
    id: string;
  };
};

export function WhatWherePositions({
  type,
  assetType,
  dimensions,
  portfolioId,
  portfolioHoldings,
}: WhatWherePositionsProps) {
  const [holdings, setHoldings] = useState([]);
  const [loading, setLoading] = useState(false);

  const environment = useEnvironment();

  const formatTaxon = useCallback(
    (taxonId, assetType, field) => {
      const taxonomies = environment.get("setup")["taxonomies"];
      const taxonFields = environment.get("setup")["taxonomyFields"];
      const taxonKey =
        taxonFields?.[assetType]?.[field] ?? taxonFields?.["security"]?.[field];
      const label =
        assetType && field
          ? formatTaxonPrefixingParent(
              getTaxonById(taxonId, [taxonomies[taxonKey]], 0),
              taxonomies,
              null
            )
          : null;
      return label ?? taxonId;
    },
    [environment]
  );

  const tableTitle = useMemo(() => {
    const portfoliosAssetType = assetType === "ETF" ? "ETF" : "security";

    if (dimensions) {
      switch (dimensions.type) {
        case "what":
          return formatTaxon(
            dimensions.id[0],
            portfoliosAssetType,
            portfoliosAssetType === "ETF" ? "etfclass" : "icb"
          );

        case "where": {
          return formatTaxon(
            dimensions.id[0],
            portfoliosAssetType,
            portfoliosAssetType === "ETF" ? "etfgeo" : "country"
          );
        }

        case "whatWhere": {
          const [what, where] = dimensions.id[0].split(",");

          return `${formatTaxon(
            what,
            portfoliosAssetType,
            portfoliosAssetType === "ETF" ? "etfclass" : "icb"
          )} - ${formatTaxon(
            where,
            portfoliosAssetType,
            portfoliosAssetType === "ETF" ? "etfgeo" : "country"
          )}`;
        }
      }
    }

    return "All positions";
  }, [assetType, dimensions, formatTaxon]);
  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );

  const columnsHelper = useMemo(
    () => new ColumnsHelper(environment.get("setup")),
    [environment]
  );

  const tableColumns = useMemo(() => {
    const columnsPrototype: any = [
      {
        field: "ticker",
        title: "Ticker",
      },
      {
        field: "name",
        title: "Name",
      },
      {
        field: "rc",
        title: "Rating",
        formatter: (cell) => columnsHelper.getFormatter(cell, "rc"),
      },
      {
        field: "dr",
        title: "Rated on",
        formatter: (cell) => columnsHelper.getFormatter(cell, "dr"),
      },
    ];
    var columns = columnsPrototype;

    if (type === "PORTFOLIO") {
      columns = [
        {
          field: "weight",
          title: "Weight",
          formatter: (cell) => columnsHelper.getFormatter(cell, "weight"),
        },
      ].concat(columns);
    }

    return columns;
  }, [columnsHelper, type]);

  const getData = useCallback(async () => {
    if (!portfolioId) {
      return;
    }

    const sorter = {
      field: "weight",
      rev: true,
    };

    setLoading(true);

    try {
      const properties = [
        { date: null, property: "ticker" },
        { date: null, property: "name" },
        { date: null, property: "rc" },
        { date: null, property: "dr" },
      ];

      const payload: any = {
        filters: [
          {
            dimension: "type",
            logicalOperator: "not",
            operator: "equals",
            segments: ["ExpiredStock"],
          },
        ],
        relations: [{ domain: [portfolioId], range: type }],
        page: {
          page: 1,
          rows: 20000,
        },
        sort: [],
      };

      if (dimensions) {
        const filters: any = [];

        switch (dimensions.type) {
          case "what":
            filters.push({
              dimension: assetType === "ETF" ? "etfclass" : "icb",
              segments: dimensions.id,
            });

            break;
          case "where":
            filters.push({
              dimension: assetType === "ETF" ? "etfgeo" : "country",
              segments: dimensions.id,
            });

            break;

          case "whatWhere":
            const [what, where] = dimensions.id[0].split(",");
            filters.push(
              {
                dimension: assetType === "ETF" ? "etfclass" : "icb",
                segments: [what],
              },
              {
                dimension: assetType === "ETF" ? "etfgeo" : "country",
                segments: [where],
              }
            );
        }

        if (filters.length) {
          payload["filters"].push(...filters);
        }
      }

      if (sorter.field === "weight") {
        payload.sort = [{ dimension: "marketcap", rev: false }];

        const symbolsAndWeight = extractForDataIngestion(
          portfolioHoldings,
          "symbol",
          "weight"
        );
        var sortPropertyId = portfolioId + ":weight";
        payload["injestion"] = {
          data: symbolsAndWeight,
          field: sortPropertyId,
          type: "number",
        };
        payload["sort"].splice(0, 0, {
          dimension: sortPropertyId,
          rev: sorter.rev,
        });
      }

      let response: any = await instrumentsAPI.screening(payload);

      if (response.data) {
        response = await instrumentsAPI.fetch({
          type: "security",
          symbols: response.data,
          properties,
        });

        const positionsIndex = portfolioHoldings.reduce((prev, current) => {
          prev[current.symbol] = current.weight;

          return prev;
        }, []);

        for (const security of response.data) {
          security.weight = positionsIndex[security.symbol];
        }

        setHoldings(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    assetType,
    dimensions,
    instrumentsAPI,
    portfolioHoldings,
    portfolioId,
    type,
  ]);

  const onComponentMount = useCallback(async () => {
    getData();
  }, [getData]);

  useEffect(() => {
    onComponentMount();
  }, [onComponentMount]);

  return loading ? (
    <Box
      display={"flex"}
      flex={1}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        gap={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress sx={{ color: "#2a7090" }} />
        <Typography>Loading positions...</Typography>
      </Box>
    </Box>
  ) : (
    <div>
      <h1
        className="tAnalysisOverview-title"
        data-dojo-attach-point="nodeTitle"
      >
        {tableTitle}
      </h1>
      <div>
        <div data-dojo-attach-point="nodeTable">
          <TrendratingTable
            columns={tableColumns}
            data={holdings}
            correction={30}
          />
        </div>
      </div>
    </div>
  );
}
