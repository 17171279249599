import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { ActionRankContext } from "../actions/ActionRankContext/ActionRankContext";
import styles from "./RankDIalog.module.scss";
import { RankUniverseSelection } from "./RankUniverseSelection/RankUniverseSelection";
import { TemplateRow } from "./TemplateRow/TemplateRow";
import Modal from "../../../../../components/Modal/Modal";

type RankDialogProps = {
  closeDialog: () => void;
  page: "screening" | "rank" | "portfolioAnalysis";
  onRankedCallback?: (arg?: any) => any;
  onCancel?: () => any;
  showWithoutPanel?: boolean;
};

type RankEditorProps = {
  computing: boolean;
  page: "screening" | "rank" | "portfolioAnalysis";
  currentTemplateId: number;
  setRankRules: (rules) => void;
  rankingRules: any[] | undefined;
  onRankBtnClick: (value?) => void;
  onClickCancelBtn: (value) => void;
};

type SectionProps = {
  type: "portfolio" | "universe" | "template";
  children: JSX.Element | JSX.Element[];
};

export function RankDialog({
  closeDialog,
  page,
  onRankedCallback,
  onCancel,
  showWithoutPanel = false,
}: RankDialogProps) {
  const [error, setError] = useState<undefined | string>();
  const [computing, setComputing] = useState(false);
  const { rank, currentTemplateId, rankingRules, rankReducer } =
    useContext(ActionRankContext);
  const [rankRules, setRankRules] = useState<any>([]);

  const onRankBtnClick = useCallback(async () => {
    setComputing(true);
    rankReducer({ type: "SET_RANKING_RULES", payload: rankRules });
    try {
      await rank({
        rules: rankRules,
        fromDate: undefined,
        sortField: "rank",
        rev: false,
      });

      if (onRankedCallback) {
        onRankedCallback();
      }

      closeDialog();
    } catch (error: any) {
      let msg = "";

      switch (error.message) {
        case "code_1":
          msg =
            "Ranking is supported only for universes with no more than 3000 securities.";
          break;

        case "code_2":
          msg = "Empty universe, please change universe constraints.";
          break;

        default:
          msg =
            "An error occures please retry. (more info into developers tools console)";
      }

      setError(msg);

      if (onCancel) {
        onCancel();
      }
    } finally {
      setComputing(false);
    }
  }, [closeDialog, onCancel, onRankedCallback, rank, rankReducer, rankRules]);

  const closeErrorDialog = useCallback(() => {
    setError(undefined);
    closeDialog();
  }, [closeDialog]);

  const onClickCancelBtn = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    closeDialog();
  }, [closeDialog, onCancel]);

  return error !== undefined ? (
    <Modal
      onClose={closeErrorDialog}
      closeIcon={false}
      headerConfig={{ headerContent: "Warning", hasBackground: true }}
    >
      <Typography>{error}</Typography>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button variant="tr_button_cancel" onClick={closeErrorDialog}>
          Cancel
        </Button>
      </Box>
    </Modal>
  ) : showWithoutPanel === true ? (
    <Box className={styles.rankModalBody}>
      <RankEditor
        computing={computing}
        page={page}
        currentTemplateId={currentTemplateId}
        setRankRules={setRankRules}
        rankingRules={rankingRules}
        onRankBtnClick={onRankBtnClick}
        onClickCancelBtn={onClickCancelBtn}
      />
    </Box>
  ) : (
    <Modal
      closeIcon={false}
      bodyCustomClass={styles.rankModalBody}
      closeOnClickAway={false}
      customCss={{
        height: "auto",
        maxWidth: computing ? "20%" : "70%",
        backgroundColor: "#fafafa",
      }}
      headerConfig={{ headerContent: "RANKING" }}
      onClose={onClickCancelBtn}
    >
      <RankEditor
        computing={computing}
        page={page}
        currentTemplateId={currentTemplateId}
        setRankRules={setRankRules}
        rankingRules={rankingRules}
        onRankBtnClick={onRankBtnClick}
        onClickCancelBtn={onClickCancelBtn}
      />
    </Modal>
  );
}

const Section = ({ type, children }: SectionProps) => {
  return <Box className={styles.section}>{children}</Box>;
};

const RankEditor = ({
  computing,
  page,
  currentTemplateId,
  setRankRules,
  rankingRules,
  onRankBtnClick,
  onClickCancelBtn,
}: RankEditorProps) => {
  return (
    <>
      {computing ? (
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box display={"flex"} gap={1} alignItems={"center"} padding={2}>
            <CircularProgress sx={{ color: "#2a7090" }} />
            <Typography>Computing Rank...</Typography>
          </Box>
        </Box>
      ) : (
        <Box className={styles.mainWrapper}>
          {page === "rank" && (
            <Section type="universe">
              <RankUniverseSelection />
            </Section>
          )}
          <Section type="template">
            <TemplateRow
              idInit={currentTemplateId}
              getRules={setRankRules}
              currentRules={rankingRules}
              page={page}
            />
          </Section>
          <Box className={styles.dialogButtons}>
            <Button variant="contained" onClick={onRankBtnClick}>
              Rank
            </Button>
            <Button variant="tr_button_cancel" onClick={onClickCancelBtn}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
