type SummaryTableChartProps = {
    data: any;
    portfolio: any;
    portfolio2: any;
};

export default function SummaryTableChart({
    data,
    portfolio,
    portfolio2,
}: SummaryTableChartProps) {
    return (
        <div className="summary-table-chart-section blockWrapper">
            <p className="security-name">{`${data.securityTicker} ${data.securityName}`}</p>
            <div>
                {data.weightP1 && data.weightP2 && (
                    <>
                        <div className="security-table-charts-weights">
                            <p>{`${portfolio}: ${data.weightP1}%`}</p>
                            <p>{`${portfolio2}: ${data.weightP2}%`}</p>
                        </div>
                    </>
                )}
                <div className="security-chart-summary-table">
                    {data.chartUri && (
                        <img alt="security-chart" src={data.chartUri} />
                    )}
                </div>
            </div>
        </div>
    );
}
