import { Box } from "@mui/system";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from "react";
import { _rate } from "../../trendrating/formatter/_rate";
import { Chart } from "../Chart";
import InputSelect from "../InputSelect/InputSelect";
import PieLegend from "./PieLegend";

type PieBoxProps = {
  value: { label: string; value: string; taxonField: string };
  setValue: (value) => void;
  options: { label: string; value: string }[];
  data: any;
  isTcrMode: boolean;
  isPieClickable: boolean;
  alignChartOnTop: boolean;
  onPieClick?: (event) => any;
};

const getChartColors = () => {
  let colors: any = undefined;
  const baseColor = "#2a7092";
  // Array.map needs to have a value to iterate
  colors = Array(25)
    .fill(baseColor)
    .map((item, index) =>
      Highcharts.color(baseColor)
        .brighten((index - 5) / 20)
        .get()
    );

  return colors;
};

const buildChartConfig = (
  data,
  isTcrMode,
  isPieClickable,
  alignChartOnTop,
  onPieClick
) => {
  var dataSerie: any = [];

  let item: any = null;
  let rateMeta: any = null;
  const ratingScale = _rate.trendCaptureRating;
  let totalWeight = 0;

  for (let i = 0, length = data.length; i < length; i++) {
    item = data[i];
    rateMeta = ratingScale[item.rate != null ? String(item.rate) : "U"];
    totalWeight = totalWeight + item["weight"];
    dataSerie.push({
      color: isTcrMode ? rateMeta["colorChart"] : null,
      name: rateMeta["label"],
      trendrating: item,
      y: item["weight"],
    });
  }

  if (totalWeight < 1) {
    rateMeta = ratingScale["CASH"];
    dataSerie.push({
      color: rateMeta["colorChart"],
      name: rateMeta["label"],
      trendrating: null,
      y: 1 - totalWeight,
    });
  }

  const options = {
    chart: {
      animation: false,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      type: "pie",
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      pie: {
        colors: isTcrMode ? null : getChartColors(),
        allowPointSelect: false,
        borderColor: "#FFFFFF",
        borderWidth: 1,
        center: ["50%", "50%"],
        cursor: isPieClickable ? "pointer" : undefined,
        dataLabels: { enabled: false },
        point: {
          events: {
            click: function (event) {
              event.stopPropagation();
              var item = event.point.options.trendrating;
              // skip cash
              if (isPieClickable && item != null) {
                event.value = item;
                onPieClick(event);
              }
            },
          },
        },
        shadow: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "Rating",
        innerSize: "50%",
        animation: false,
        data: dataSerie,
      },
    ],
    title: { text: null },
    tooltip: { enabled: false },
  };

  if (alignChartOnTop === true) {
    options["chart"]["height"] = "100%";
    options["plotOptions"]["pie"]["size"] = "100%";
    options["plotOptions"]["pie"]["slicedOffset"] = 5;
  }

  return options;
};

export default function PieBox({
  data,
  value,
  setValue,
  options,
  isTcrMode,
  isPieClickable,
  alignChartOnTop,
  onPieClick,
}: PieBoxProps) {
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    if (data) {
      const chartConfig = buildChartConfig(
        data,
        isTcrMode,
        isPieClickable,
        alignChartOnTop,
        onPieClick
      );

      setConfig(chartConfig);
    }
  }, [alignChartOnTop, data, isPieClickable, isTcrMode, onPieClick]);

  return value ? (
    <div className="tWhatWherePie-box">
      <div className="tWhatWherePie-content">
        <h1 className="tWhatWherePie-title">
          <InputSelect value={value} setValue={setValue} options={options} />
        </h1>

        <Box display={"flex"} flex={1} height={"100%"} minHeight={0}>
          {config && (
            <div className="tWhatWherePie-chart" style={{ overflow: "hidden" }}>
              <Chart options={config} constructorType="chart" />
            </div>
          )}
          <PieLegend
            selectedValue={value?.value ?? undefined}
            data={data}
            isTcrMode={isTcrMode}
            taxonField={value.taxonField}
            onClickHandler={onPieClick}
          />
        </Box>
      </div>
    </div>
  ) : (
    <></>
  );
}
