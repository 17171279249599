import GridOnIcon from "@mui/icons-material/GridOn";
import ListIcon from "@mui/icons-material/List";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { PortfolioHomeStorage } from "../../../storage/PortfolioHomeStorage";
import { ListEventsTitle } from "./ListEventsTitle";
import { ListTable } from "./ListTable";
import { ListViewerTile } from "./ListViewerTile";
import { PortfolioHomeContext } from "./PortfolioHome";
import { SortBy } from "./SortBy";
import { TableAlertsComponent } from "./createEdit/TableAlertsComponent";

type PortfolioProps = {
  storage: PortfolioHomeStorage;
  updateWorkflow: (wf) => void;
  setSelectedList: (list) => void;
  selectedList: any;
  setSort: (sorter) => void;
  sort:
    | "name"
    | "name_rev"
    | "TCR"
    | "TCR_rev"
    | "CD"
    | "CD_rev"
    | "AB"
    | "AB_rev"
    | "upgrades"
    | "downgrades"
    | "movers_up"
    | "movers_down";
  viewAs: "table" | "tile";
  onChangeView: (view) => void;
  setTimeframe: (timeframe) => void;
};

const LIST_TYPE: "PORTFOLIO" | "BASKET" = "PORTFOLIO";

export function Portfolio({
  storage,
  updateWorkflow,
  setSelectedList,
  selectedList,
  sort,
  setSort,
  viewAs,
  onChangeView,
  setTimeframe,
}: PortfolioProps) {
  const { t } = useTranslation();
  const context = useContext(PortfolioHomeContext);
  const timeframe = useMemo(
    () => context?.timeframe ?? "daily",
    [context?.timeframe]
  );
  const [userLists, setUserLists] = useState<number[]>();
  const [alerts, setAlerts] = useState();
  const [resumeTableAlerts, setResumeTableAlerts] = useState();
  const [alertOfSingleList, setAlertOfSingleList] = useState<[] | undefined>(
    undefined
  );
  const [isLoadingLeftTable, setIsLoadingLeftTable] = useState(true);
  const [isLoadingRightTable, setIsLoadingRightTable] = useState(true);
  const { on, remove } = useEventBus();

  const getAlerts = useCallback(
    async (ids: number[]) => {
      const response = await storage.getAlerts(ids, timeframe);
      setIsLoadingRightTable(false);

      return response;
    },
    [storage, timeframe]
  );

  const dataGet = useCallback(async () => {
    updateWorkflow("s2");
    setIsLoadingRightTable(true);

    if (viewAs === "table") {
      const lists = await storage.getCollections(LIST_TYPE, timeframe);
      const ids = lists.map((list) => list.id);
      setIsLoadingLeftTable(true);
      setResumeTableAlerts(lists);
      const alerts = await getAlerts(ids);
      setAlerts(alerts as any);
    } else {
      storage.invalidateCachedIds();
      const lists = await storage.getCollectionsIds(LIST_TYPE, sort, timeframe);
      setUserLists(lists);
      const alerts = await getAlerts(lists);
      setAlerts(alerts as any);
    }
    setIsLoadingLeftTable(false);

    updateWorkflow("s0");
  }, [getAlerts, storage, timeframe, updateWorkflow, viewAs, sort]);

  useEffect(() => {
    dataGet();
  }, [dataGet]);

  const deselectItem = useCallback(() => {
    setSelectedList(undefined);
    updateWorkflow("s0");
  }, [setSelectedList, updateWorkflow]);

  const onListSelection = useCallback(
    async (list) => {
      if ("alerts" in list) {
        setAlertOfSingleList(list.alerts);
        return;
      } else {
        setIsLoadingRightTable(true);
        const alertsOfList: any = await storage.getAlerts([list.id], timeframe);

        setIsLoadingRightTable(false);
        setAlertOfSingleList(alertsOfList);
      }
    },
    [storage, timeframe]
  );

  const selectTableRow = useCallback(
    (list) => {
      updateWorkflow("s1");

      setSelectedList(list);
      onListSelection(list);
    },
    [onListSelection, setSelectedList, updateWorkflow]
  );

  const onClickItem = useCallback(
    (event) => {
      const list = event["value"];

      setSelectedList(list);
      onListSelection(list);

      updateWorkflow("s1");
    },
    [onListSelection, setSelectedList, updateWorkflow]
  );

  useEffect(() => {
    setSelectedList(undefined);
  }, [setSelectedList, timeframe]);

  useEffect(() => {
    storage.invalidateCollections();
  }, [storage, timeframe]);

  const refresh = useCallback(() => {
    storage.invalidateCachedIds();
    storage.invalidateCollections();
    dataGet();
  }, [dataGet, storage]);

  useEffect(() => {
    on("refresh-view", refresh);

    return () => remove("refresh-view", refresh);
  }, [on, refresh, remove]);

  return (
    <Box className="tPageAnalysisListsPortfolio" p={1}>
      {userLists && Object.keys(userLists).length === 0 ? (
        <div className="tPageAnalysisListsPortfolio-feedback">
          {t("c_feedback_no_portfolios")}
        </div>
      ) : (
        <div className="tLayout">
          <div className="tLayout--width-50-spaced">
            <Box
              minWidth={0}
              bgcolor={"white"}
              padding={1}
              boxShadow={3}
              borderRadius={"6px"}
              height={"100%"}
              minHeight={"82vh"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"50px"}
              >
                <Box display={"flex"} alignItems={"center"} flex={1}>
                  <h1>
                    <Typography sx={{ fontSize: "16px" }}>
                      <strong> {t("c_list_portfolio_monitoring")}</strong>
                    </Typography>
                  </h1>
                </Box>
                <Box display={"flex"} gap={1} marginRight={"15px"}>
                  <SortBy sort={sort} setSort={setSort} />
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography>View by:</Typography>
                    <ListIcon
                      onClick={onChangeView}
                      sx={{
                        cursor: "pointer",
                        color: "#2a7090",
                        fontSize: "1.2vw",
                        paddingBottom: "2px",
                        borderBottom:
                          viewAs === "tile"
                            ? "2px solid #2a7090"
                            : "2px solid transparent",
                      }}
                    />

                    <GridOnIcon
                      onClick={onChangeView}
                      sx={{
                        cursor: "pointer",
                        color: "#2a7090",
                        fontSize: "1vw",
                        paddingBottom: "2px",
                        borderBottom:
                          viewAs === "table"
                            ? "2px solid #2a7090"
                            : "2px solid transparent",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <div className="____tPageAnalysisListsPortfolio-scrollable">
                <div className="tPageAnalysisListsPortfolio-viewerContainer">
                  {viewAs === "tile" ? (
                    <div className="tPageAnalysisListsPortfolio-viewer">
                      <Box border={"1px solid #ddd"}>
                        <ListViewerTile
                          storage={storage}
                          lists={userLists}
                          timeframe={timeframe}
                          onClickTileItem={onClickItem}
                        />
                      </Box>
                    </div>
                  ) : (
                    <div
                      className="tPageAnalysisListsPortfolio-viewer"
                      data-dojo-attach-point="nodeContainerViewerTable"
                    >
                      <div className="tPageAnalysisListsPortfolio-viewerTable tPageAnalysisListsPortfolio-scrollable">
                        {isLoadingLeftTable ? (
                          <Stack spacing={1}>
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "20px",
                                marginBottom: "20px",
                              }}
                            />

                            {/* For other variants, adjust the size with `width` and `height` */}
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={"100%"}
                              height={30}
                            />
                          </Stack>
                        ) : (
                          <ListTable
                            sort={sort}
                            setSort={setSort}
                            timeframe={timeframe}
                            listType={LIST_TYPE}
                            data={resumeTableAlerts}
                            rowClickListener={selectTableRow}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>

          <div className="tLayout--width-50-spaced">
            <Box
              minWidth={0}
              bgcolor={"white"}
              padding={1}
              boxShadow={3}
              borderRadius={"6px"}
            >
              <ListEventsTitle
                selectedItem={selectedList}
                deselectItem={deselectItem}
                timeframe={timeframe}
                setTimeframe={setTimeframe}
              />
              <Box
                className="tPageAnalysisListsPortfolio-journal tPageAnalysisListsPortfolio-scrollable"
                minHeight={"100%"}
              >
                {isLoadingRightTable ? (
                  <Stack spacing={1}>
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={30}
                    />
                  </Stack>
                ) : (
                  <TableAlertsComponent
                    timeframe={timeframe}
                    autoResize={true}
                    alerts={selectedList ? alertOfSingleList ?? [] : alerts}
                  />
                )}
              </Box>
            </Box>
          </div>
        </div>
      )}
    </Box>
  );
}
