import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironment } from "../../../../hooks/useEnvironment";
import { config } from "../../config-ts";
import { HelpContentAdvancedGlossary } from "./HelpContentAdvancedGlossary";
import { HelpContentContact } from "./HelpContentContact";
import { HelpContentDownload } from "./HelpContentDownload";
import { useBroadcast } from "../../../../hooks/useBroadcast";
import styles from "./Help.module.scss";

type HelpAlphaFinderProps = {
  staticAssets: string;
};

type ContentType =
  | "PDF|alerts"
  | "PDF|dispersionOpportunities"
  | "PDF|investmentIdeas"
  | "PDF|portfolioRiskControl"
  | "PDF|strategyBuilder"
  | "PDF|strategyTracker"
  | "PDF|rank"
  | "PDF|alphaDispersion"
  | "PDF|reportsHub"
  | "PDF|performanceRise"
  | "desktopApplications"
  | "createYourReports"
  | "glossary"
  | "contactUs";

const PDF_HELP_ROOT = "/help/";

const PDF_DICTIONARY = {
  dispersionOpportunities: "Dispersion Opportunities.pdf",
  investmentIdeas: "Investment Ideas.pdf",
  portfolioRiskControl: "Portfolio Risk Control.pdf",
  strategyBuilder: "Strategy Builder.pdf",
  strategyTracker: "Strategy Tracker.pdf",
  rank: "Rank.pdf",
  alphaDispersion: "Alpha Dispersion.pdf",
  reportsHub: "Reports Hub.pdf",
  alerts: "Alerts.pdf",
  performanceRise: "Performance Rise.pdf",
};

const TABLE_CONFIG = {
  advanced: [
    {
      rowId: "PDF|alerts",
      module: "alerts",
      title: "ALERTS",
      whatMessage:
        "The Alerts offer a signal of change in the rating. Therefore, you can see upgrades and downgrades across the investment universe of your interest, as well as for all your portfolios and baskets. The upgrades from a C or D to an A or B can identify a positive trend reversal. The downgrades from A or B to C or D is signaling the possibility of a negative trend reversal.",
      whyMessage:
        "Use the Alerts to be promptly informed about rating changes, therefore new trends developments.",
    },
    {
      module: "analysis_market",
      rowId: "PDF|dispersionOpportunities",
      title: "DISPERSION OPPORTUNITIES",
      whatMessage:
        "To gain valuable insights into investment opportunities and risks, it is important to analyze and compare the performance dispersion across various markets and sectors. This involves examining the returns of different markets and sectors over a given period to determine which ones are performing better or worse.",
      whyMessage:
        "The dispersion analysis can provide a clear understanding of the range of returns across an investment universe. By analyzing this data, you can identify sectors or markets that are performing particularly well or poorly, and adjust your investment approach accordingly.",
    },
    {
      module: "screening",
      rowId: "PDF|investmentIdeas",
      title: "INVESTMENT IDEAS",
      whatMessage:
        "To identify promising investment opportunities, it is essential to establish preferred selection rules and check multiple quality boxes. Investors should combine solid fundamental analysis with factual validation of price trends to spot new opportunities and validate ideas. Here, you can create and save selected lists based on your own investment approach, such as value, growth, dividend, low volatility, size, or bottom fishing strategies.",
      whyMessage:
        "Developing a well-conceived set of selection rules can improve the probability of profiting from performance dispersion and capturing the best-performing stocks. By combining best practice rules, you can maximize your returns and generate alpha.",
    },
    {
      module: "ranking",
      rowId: "PDF|rank",
      title: "RANK",
      whatMessage:
        "Ranking investment universes based on the quality of price trends can consistently help identify potential outperformers and emerging trend risks. Our default ranking criteria is determined by considering a combination of factors, including the Rating grade (trend direction), Smart Momentum (trend magnitude), Retracement (trend risk), and Trend Strength (trend consistency). It is also possible to include fundamentals within the ranking criteria.",
      whyMessage:
        "It can be challenging to identify strong investment opportunities on a consistent basis. Investors are able to order their baskets or portfolios of interest, by combining multiple analytics offered by Trendrating. Evaluating an investment universe through clearly defined criteria can establish a reliable decision-making framework that remains consistent over time. This comprehensive approach enables us to acquire a deeper understanding of trends and mitigate the hazards linked to depending solely on a single metric.",
    },
    {
      module: "analysis_list",
      rowId: "PDF|portfolioRiskControl",
      title: "PORTFOLIO RISK CONTROL",
      whatMessage:
        "Trendrating's new dimension of risk control involves measuring the portfolio's exposure to securities in bear trends and receiving prompt alerts about excessive risks. Adding factual and pragmatic metrics strengthens risk management by providing an objective way to monitor and assess risk. With this tool, you can stay in control of new risks across all of your portfolios efficiently, identifying portfolios that require changes. By implementing this risk control mechanism, you can proactively adjust your portfolios and avoid significant losses. Overall, this new dimension of risk control provides you with a valuable tool to manage your portfolios more effectively and efficiently. It allows you to make informed decisions based on reliable data, thereby minimizing risk and maximizing returns.",
      whyMessage:
        'The larger the portfolio allocation to falling stocks, the greater the risk of losses. This is because the performance of stocks in negative trends is typically weaker, and a larger allocation to these stocks can result in significant losses. To help mitigate this risk, you can check the "Trend Capture Rating" (TCR), which measures the allocation across stocks in positive vs. negative trends. The correlation between the TCR and performance is evident, as a higher TCR typically corresponds with better portfolio performance. When the TCR falls below a B+ rating, it signals an excessive exposure to underperforming securities. This can serve as a warning sign, indicating the need to rebalance your portfolios and reduce exposure to underperforming stocks.',
    },
    {
      module: "performanceRise",
      rowId: "PDF|performanceRise",
      title: "PORTFOLIOS",
      whatMessage: "Upload and analyze your portfolios.",
      whyMessage:
        "Track the performance of your portfolios and individual holdings, assess the quality and identify weak areas.",
    },
    {
      module: "strategy_builder",
      rowId: "PDF|strategyBuilder",
      title: "STRATEGY BUILDER",
      whatMessage:
        "Discover what mix of parameters, (fundamental, performance, size, volatility, trend, etc.) work better across different markets and sectors.",
      whyMessage:
        "Customize any of the proposed strategies in the Insights section. Select a specific strategy to load the rules, run, analyze the key ratios and personalize it as you prefer, according to your investment mandate. Alternatively, create your investment strategy from the start.",
    },
    {
      module: "systematic_portfolios",
      rowId: "PDF|strategyTracker",
      title: "STRATEGY TRACKER",
      whatMessage:
        "After designing, optimizing, and validating an investment strategy, you can save and track it. The process is scalable and supports tracking several strategies, which can also be combined.",
      whyMessage:
        "The tracking functionality of the platform is designed to alert you when it is time to reallocate your investment strategy. By continuously monitoring the performance of the securities in the portfolio and comparing them against predefined criteria, Strategy Tracker can identify when it is necessary to make changes to the strategy in order to optimize returns and minimize risk.",
    },
    {
      module: "reports_hub",
      rowId: "PDF|reportsHub",
      title: "REPORTS HUB",
      whatMessage:
        "Introducing the new Reports Hub, designed to streamline report creation through pre-defined templates tailored for specific markets, sectors, and individual portfolios. Whether analyzing dispersion and market trends, gaining sector-specific insights, or ranking individual portfolios, the Reports Hub ensures a seamless experience with its user-friendly interface.",
      whyMessage:
        "This efficient and resourceful solution offers a quick, convenient way to generate professional reports, perfectly aligning with the unique needs of specific market segments.",
    },
  ],
};

export function HelpAlphaFinder({ staticAssets }: HelpAlphaFinderProps) {
  const [expand, setExand] = useState(false);

  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const product = useMemo(() => appSetup?.["account"]?.["product"], [appSetup]);
  const tableRows = useMemo(() => {
    const productConfiguration = product.configuration;

    const tableRows: {
      module: string;
      rowId: string;
      title: string;
      whatMessage: string;
      whyMessage: string;
    }[] = [];

    for (const row of TABLE_CONFIG.advanced) {
      if (productConfiguration[row.module].enabled) {
        tableRows.push(row);
      }
    }

    return tableRows;
  }, [product]);

  const [content, setContent] = useState<ContentType>(
    (tableRows?.[0].rowId as any) ?? "PDF|dispersionOpportunities"
  );

  const helpType = useMemo(() => {
    if (!product) {
      return undefined;
    }

    const helpConfig = product?.["configuration"]?.["help"] ?? undefined;

    if (helpConfig) {
      return helpConfig?.["help_type"] ?? undefined;
    }

    return undefined;
  }, [product]);

  const onExpand = useCallback(() => setExand(!expand), [expand]);

  useEffect(() => {
    if (helpType === "dispersion") {
      setContent("PDF|alphaDispersion");
    }
  }, [helpType]);

  const { broadcast } = useBroadcast();

  //Workfow Actions
  useEffect(() => {
    const actions: any =
      helpType === "dispersion"
        ? [
            {
              componentJSX: (
                <li
                  className={"menu__item"}
                  onClick={() => setContent("contactUs")}
                >
                  Contact Us
                </li>
              ),
            },
          ]
        : [
            {
              componentJSX: (
                <li
                  className={"menu__item"}
                  onClick={() => setContent("desktopApplications")}
                >
                  Desktop Applications
                </li>
              ),
            },
            {
              componentJSX: (
                <li
                  className={"menu__item"}
                  onClick={() => setContent("glossary")}
                >
                  Glossary
                </li>
              ),
            },
            {
              componentJSX: (
                <li
                  className={"menu__item"}
                  onClick={() => setContent("contactUs")}
                >
                  Contact Us
                </li>
              ),
            },
          ];

    var message = {
      from: "HELP",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [broadcast, helpType]);

  return (
    <Box className={styles.rootNode}>
      {!expand && helpType !== "dispersion" && (
        <Box boxShadow={3} className={styles.tablePanel}>
          <Box display={"flex"} minHeight={0} overflow={"auto"}>
            <table className={styles.table}>
              <thead className={styles.stickyHeader}>
                <tr>
                  <th className={styles.headerCol_sm}></th>
                  <th className={styles.headerCol_lg}>
                    <Typography className={styles.heading}>What</Typography>
                  </th>
                  <th className={styles.headerCol_lg}>
                    <Typography className={styles.heading}>Why</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((row) => {
                  return (
                    <tr
                      className={`${styles.tableRow} ${
                        content === row.rowId ? styles.selectedRow : ""
                      }`}
                      onClick={() => setContent(row.rowId as any)}
                    >
                      <td
                        className={`${styles.verticalAlignedTop} ${styles.borderedCell}`}
                      >
                        <Typography className={styles.heading}>
                          {row.title}
                        </Typography>
                      </td>
                      <td className={styles.verticalAlignedTop}>
                        {row.whatMessage}
                      </td>
                      <td className={styles.verticalAlignedTop}>
                        {row.whyMessage}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}

      <Box className={styles.contentPanel} boxShadow={3}>
        <ContentWizard
          onExpand={onExpand}
          staticAssets={staticAssets}
          contentType={content}
        />
      </Box>
    </Box>
  );
}

const ContentWizard = ({
  contentType,
  staticAssets,
  onExpand,
}: {
  contentType: ContentType;
  staticAssets: string;
  onExpand: () => void;
}) => {
  const environment = useEnvironment();

  switch (contentType) {
    default: {
      return <></>;
    }

    case "PDF|alerts":
    case "PDF|dispersionOpportunities":
    case "PDF|investmentIdeas":
    case "PDF|portfolioRiskControl":
    case "PDF|strategyBuilder":
    case "PDF|strategyTracker":
    case "PDF|alphaDispersion":
    case "PDF|reportsHub":
    case "PDF|performanceRise":
    case "PDF|rank": {
      const name = contentType.split("|");

      return <ViewerPDF staticAssets={staticAssets} fileNameType={name[1]} />;
    }

    case "glossary": {
      return (
        <HelpContentAdvancedGlossary
          onExpand={onExpand}
          staticAssets={staticAssets}
        />
      );
    }

    case "desktopApplications":
      return (
        <HelpContentDownload
          productClass={"advanced"}
          staticAssets={staticAssets}
        />
      );

    case "contactUs": {
      return (
        <HelpContentContact
          environment={environment}
          productClass={"advanced"}
        />
      );
    }
  }
};

const ViewerPDF = ({
  fileNameType,
  staticAssets,
}: {
  fileNameType: string;
  staticAssets: string;
}) => {
  const fileName = PDF_HELP_ROOT + encodeURI(PDF_DICTIONARY[fileNameType]);

  return (
    <Box className={styles.embedContainer}>
      <Box className={styles.embedTitleBox}>
        <Typography className={styles.heading}>How</Typography>
      </Box>
      <embed
        style={{ borderRadius: "6px" }}
        width={"100%"}
        height={"100%"}
        type="application/pdf"
        src={`${staticAssets}${fileName}#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&zoom=100&view=FitH&ts=${Date.now()}`}
        onContextMenu={() => false}
      ></embed>
    </Box>
  );
};
