import { useCallback, useEffect, useRef, useState } from "react";
// import { HorizontalRule } from "../../../../../../../js/app/components/dijit/form/HorizontalRule";
// import { HorizontalSlider } from "../../../../../../../js/app/components/dijit/form/HorizontalSlider";
// import { NumberTextBox } from "../../../../../../../js/app/components/dijit/form/NumberTextBox";
// import { RadioButton } from "../../../../../../../js/app/components/dijit/form/RadioButton";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useImmer } from "use-immer";
import CustomFieldCollapser from "../../../../../../../js/app/components/ui/form/CustomFieldCollapser";
import MinMaxInputNumer from "./MinMaxInputNumer";
import NewSlider from "./NewSlider";

type SmartBetaRulesProps = {
  state: any;
  dispatch: Function;
  more?: boolean;
};

export default function SmartBetaRules({
  state,
  dispatch,
  more = false,
}: SmartBetaRulesProps) {
  const [widgetsState, updateWidgetsState] = useImmer({
    radio: state.weighting.weightingSchema,
    A: state.weighting.smartBeta[0].operator.A,
    B: state.weighting.smartBeta[0].operator.B,
  });
  const [textBox, updateTextBox] = useImmer({
    maximum: 10,
    discreteValues: 10,
  });
  const [textBox_B, updateTextBox_B] = useImmer({
    maximum: 10,
    discreteValues: 10,
  });

  const horizontalSliderOnchangeHandler = (
    sliderKey: string,
    stateKey: string,
    value: number
  ) => {
    if (widgetsState[stateKey] === 1 && value === 1) {
      updateWidgetsState((draft) => {
        draft.radio = "WEIGHT_MARKET_CAP";
        draft[sliderKey] = value;
      });
    } else {
      updateWidgetsState((draft) => {
        draft.radio = "WEIGHT_EQUAL";
        draft[sliderKey] = value;
      });
    }
  };

  const updateRangeLimit = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      switch (sliderKey) {
        case "A":
          updateTextBox((draft) => {
            draft.discreteValues = value + 1;
            draft.maximum = value + 1;
            if (ref.current != null) {
              ref.current.widget.set("count", value + 1);
              ref.current.widget.buildRendering();
            }
          });
          break;
        case "B":
          updateTextBox_B((draft) => {
            draft.discreteValues = value + 1;
            draft.maximum = value + 1;
            if (refB.current != null) {
              refB.current.widget.set("count", value + 1);
              refB.current.widget.buildRendering();
            }
          });
          break;
      }
    },
    [updateTextBox, updateTextBox_B]
  );

  const syncSliderAtRender = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      if (value > 10 && value < 100) {
        updateRangeLimit(value, sliderKey);
      }
    },
    [updateRangeLimit]
  );

  const sliderMaxValueChangeHandler = useCallback(
    (value: number, sliderKey: "A" | "B") => {
      switch (sliderKey) {
        case "A":
          if (value === textBox.maximum) {
            if (textBox.maximum < 100) {
              updateRangeLimit(value, sliderKey);
            }
          }
          break;
        case "B":
          if (value === textBox_B.maximum) {
            if (textBox_B.maximum < 100) {
              updateRangeLimit(value, sliderKey);
            }
          }
          break;
      }
    },
    [textBox.maximum, textBox_B.maximum, updateRangeLimit]
  );

  useEffect(() => {
    const payload = {
      A: widgetsState.A,
      B: widgetsState.B,
      weightingSchema: widgetsState.radio,
    };
    dispatch({ type: "SET_SMART_BETA_RULES", payload });

    /**
     * Update the range limit when the value of the widget is greater than the maximum but
     * less than 100. This check is important when the user save a value that exceed the max,
     * because when we load that value if there's not some check, at the render the the maximum
     * value of the widget will not be updated and the widget will render itself in a wrong way.
     */
    syncSliderAtRender(widgetsState.A, "A");
    syncSliderAtRender(widgetsState.B, "B");

    /**
     * Update the widget limit when the user set a value that is greather than 10
     */
    sliderMaxValueChangeHandler(widgetsState.A, "A");
    sliderMaxValueChangeHandler(widgetsState.B, "B");
  }, [dispatch, sliderMaxValueChangeHandler, syncSliderAtRender, widgetsState]);

  const ref = useRef<any>(null);
  const refB = useRef<any>(null);

  const [InputBKey, setInputBKey] = useState(Date.now());
  const [InputAKey, setInputAKey] = useState(Date.now());

  return (
    <fieldset className="form__fieldset form__fieldset--builder">
      <legend className="form__legend form__legend--builder">
        <span className="builder__step">3</span> Smart beta rules
      </legend>
      <ul className="form__field-list">
        <li className="form__field">
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>
              <strong className="rate rate--A builder-smart-beta__rate">
                A
              </strong>
            </Typography>
            <Box width={"100%"}>
              <NewSlider
                minValue={1}
                maxValue={100}
                onChangeValue={(val) => {
                  horizontalSliderOnchangeHandler("A", "B", val);
                  setInputAKey(Date.now());
                }}
                value={widgetsState.A}
              />
            </Box>
            <MinMaxInputNumer
              key={InputAKey}
              value={widgetsState.A}
              min={1}
              max={100}
              stateSetter={(val) => {
                horizontalSliderOnchangeHandler("A", "B", val * 1);
              }}
            />
          </Box>
        </li>
        <li className="form__field">
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography>
              <strong className="rate rate--B builder-smart-beta__rate">
                B
              </strong>
            </Typography>
            <Box width={"100%"}>
              <NewSlider
                minValue={1}
                maxValue={100}
                onChangeValue={(val) => {
                  horizontalSliderOnchangeHandler("B", "A", val);
                  setInputBKey(Date.now());
                }}
                value={widgetsState.B}
              />
            </Box>
            <MinMaxInputNumer
              key={InputBKey}
              value={widgetsState.B}
              min={1}
              max={100}
              stateSetter={(val) => {
                horizontalSliderOnchangeHandler("B", "A", val * 1);
              }}
            />
          </Box>
        </li>
        <CustomFieldCollapser
          isOpen={more ?? false}
          setIsOpen={(e) =>
            dispatch({ type: "MORE_SMART_BETA_RULES", payload: e })
          }
        >
          <li className="form__field">
            <FormControl>
              <Typography>Weights</Typography>
              <RadioGroup
                row
                value={widgetsState.radio}
                onChange={(e) => {
                  const val = e.target.value;
                  updateWidgetsState((draft) => {
                    draft.radio = val;
                  });
                }}
              >
                <FormControlLabel
                  value="WEIGHT_EQUAL"
                  control={<Radio />}
                  label={<Typography>Equal weighted</Typography>}
                />
                <FormControlLabel
                  value="WEIGHT_MARKET_CAP"
                  control={<Radio />}
                  label={<Typography>Mkt. Cap. weighted</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </li>
        </CustomFieldCollapser>
      </ul>
    </fieldset>
  );
}
