import { Box, Card, CardContent, Typography } from "@mui/material";
import { Instruments } from "../../api/compute/Instruments";
import { Lists } from "../../api/compute/Lists";
import { widgetsConfiguration } from "../../js/app/widgets/widgetsConfiguration";
import { StrategyFormatter } from "../../trendrating/formatter/StrategyFormatter";
import { Strategy } from "../../types/Api";
import { AppEnvironment } from "../../types/Defaults";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../hooks/useEnvironment";
import { v4 as uuidv4 } from "uuid";
import styles from "./StrategySummaryModal.module.scss";

type StrategySummaryProps = {
  strategy: Strategy;
  options?: {
    sections?: {
      hasBacktesting: boolean;
      hasHedging: boolean;
    };
  };
};

type ContentSectionProps = {
  strategy?: Strategy;
  params: Strategy["params"];
  section: Sections;
};

type SectionCardProps = {
  section: Sections;
  tableConfiguration: TableConfiguration;
};

type TableConfiguration = { title: string | null; value: string | null }[];

type Sections =
  | "strategy"
  | "universe"
  | "selection"
  | "holding"
  | "ranking"
  | "weighting"
  | "allocation"
  | "backtesting"
  | "hedging";

type SectionsTitles =
  | "Strategy"
  | "Investment Universe"
  | "Selection Rules"
  | "Holding Rules"
  | "Ranking Rules"
  | "Weighting Rules"
  | "Allocation Constraints"
  | "Backtesting"
  | "Hedging";

type TitleDictionary = { [key in Sections]: SectionsTitles };

interface SectionConfigInterface {
  get: () => (
    params: Strategy["params"],
    strategy: Strategy
  ) => Promise<TableConfiguration> | TableConfiguration;
}

export function StrategySummary({ strategy, options }: StrategySummaryProps) {
  const hasBacktesting = useMemo(
    () =>
      options && options.sections && "hasBacktesting" in options.sections
        ? options.sections.hasBacktesting
        : true,
    [options]
  );

  const hasHedging = useMemo(
    () =>
      options && options.sections && "hasHedging" in options.sections
        ? options.sections.hasHedging
        : true,
    [options]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <ContentSection
        section="strategy"
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section="universe"
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section={"selection"}
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section={"holding"}
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section={"ranking"}
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section={"weighting"}
        strategy={strategy}
        params={strategy.params}
      />
      <ContentSection
        section={"allocation"}
        strategy={strategy}
        params={strategy.params}
      />
      {hasBacktesting && (
        <ContentSection
          section={"backtesting"}
          strategy={strategy}
          params={strategy.params}
        />
      )}
      {hasHedging && (
        <ContentSection
          section={"hedging"}
          strategy={strategy}
          params={strategy.params}
        />
      )}
    </Box>
  );
}

const ContentSection = ({ strategy, params, section }: ContentSectionProps) => {
  const [sectionConfiguration, setSectionConfiguration] =
    useState<TableConfiguration>([]);
  const environment = useEnvironment();
  const { t } = useTranslation();

  const appSetup = useMemo(() => environment.get("setup"), [environment]);

  const Section = useMemo(
    () => new SectionConfig(section, appSetup, t),
    [appSetup, section, t]
  );

  const getSectionConfiguration = useCallback(async () => {
    try {
      const config = await Section.get()(params, strategy!);

      setSectionConfiguration(config);
    } catch (error) {
      console.error(error);
    }
  }, [Section, params, strategy]);

  useEffect(() => {
    getSectionConfiguration();
  }, [getSectionConfiguration]);

  return (
    <SectionCard section={section} tableConfiguration={sectionConfiguration} />
  );
};

const SectionCard = ({ section, tableConfiguration }: SectionCardProps) => {
  const TITLES_DICT: TitleDictionary = useMemo(
    () => ({
      strategy: "Strategy",
      universe: "Investment Universe",
      selection: "Selection Rules",
      holding: "Holding Rules",
      ranking: "Ranking Rules",
      weighting: "Weighting Rules",
      allocation: "Allocation Constraints",
      backtesting: "Backtesting",
      hedging: "Hedging",
    }),
    []
  );

  return (
    <Card>
      <CardContent sx={{ paddingBottom: "10px!important" }}>
        <Typography className={styles.sectionTitle}>
          {TITLES_DICT[section]}
        </Typography>
        <Box>
          <table>
            <tbody>
              {tableConfiguration.map((row) => (
                <tr key={uuidv4()}>
                  {row.title && (
                    <td dangerouslySetInnerHTML={{ __html: row.title }}></td>
                  )}
                  {row.value && (
                    <td dangerouslySetInnerHTML={{ __html: row.value }}></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </CardContent>
    </Card>
  );
};

class SectionConfig implements SectionConfigInterface {
  private format: StrategyFormatter["format"];
  private listsAPI: Lists;
  private instrumentAPI: Instruments;

  _SYMBOL_NEUTRAL_STRATEGY = "TRENDRATING_NEUTRAL_STRATEGY";
  _SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED =
    "TRENDRATING_NEUTRAL_STRATEGY_EQUAL_WEIGHTED";
  BLENDED_BENCHMARK_TAG = "COLLECTION";

  constructor(
    private sectionId: Sections,
    private environment: AppEnvironment,
    private t: any
  ) {
    this.format = new StrategyFormatter({ environment }).getFormatter();
    this.listsAPI = new Lists(environment);
    this.instrumentAPI = new Instruments(environment);
  }

  public get() {
    const sectionGetterMap = {
      strategy: (params, strategy) => this.sectionsStrategy(params, strategy),
      universe: (params) => this.sectionsUniverse(params),
      selection: (params) => this.sectionsSelection(params),
      holding: (params) => this.sectionsHolding(params),
      ranking: (params) => this.sectionsRanking(params),
      weighting: (params) => this.sectionsWeighting(params),
      allocation: (params) => this.sectionsAllocation(params),
      backtesting: (params) => this.sectionsBacktesting(params),
      hedging: (params) => this.sectionsHedging(params),
    };

    const getConfiguration = sectionGetterMap?.[this.sectionId];

    return getConfiguration;
  }

  private translate(stringToTranslate: string) {
    return this.t(stringToTranslate);
  }

  private async sectionsStrategy(
    params: Strategy["params"],
    strategy: Strategy
  ) {
    const configurations: { title: string; value: string }[] = [];
    const value = params["strategy"];

    const benchmarkRow = {
      title: this.translate("page_builder_field_benchmark_label"),
      value: this.format.strong(this.t("common_not_set")),
    };

    if (value["benchmark"] != null) {
      if (
        value["benchmark"] === this._SYMBOL_NEUTRAL_STRATEGY ||
        value["benchmark"] === this._SYMBOL_NEUTRAL_STRATEGY_EQUAL_WEIGHTED ||
        value["benchmark"].includes(this.BLENDED_BENCHMARK_TAG)
      ) {
        switch (value["benchmark"].includes(this.BLENDED_BENCHMARK_TAG)) {
          case false:
            benchmarkRow.value = this.format.strong(
              this.t("page_builder_field_neutral_label")
            );

            break;

          case true: {
            const benchmark = value?.["benchmark"];
            const splitBenchmark = benchmark.split(":");
            const blendedTarget = parseInt(splitBenchmark[1]);

            if (blendedTarget != null) {
              try {
                const response = await this.listsAPI.portfolioFetch(
                  [blendedTarget],
                  ["name"]
                );
                const listName = response?.[0]?.name ?? "";
                benchmarkRow.value = this.format.strong(listName);
              } catch (error) {
                console.error(error);
                benchmarkRow.value = "No benchmark info available";
              }
            }

            break;
          }

          default:
            break;
        }
      } else {
        var fetchProperties =
          widgetsConfiguration[
            "widgets/portfolio/analysis/overview/keyDataBenchmark"
          ]["properties"];

        try {
          const response = await this.instrumentAPI.fetch({
            properties: fetchProperties,
            symbols: [value["benchmark"]],
            type: "security",
          });

          const instrument = response["data"][0];

          benchmarkRow.value = this.format.instrument(instrument["name"]);
        } catch (error) {
          console.error(error);
          benchmarkRow.value = "No benchmark info available";
        }
      }
    }

    configurations.push(benchmarkRow);

    const currencyRow = {
      title: this.translate("page_builder_field_currency_label"),
      value: this.format.currency(value["currency"]),
    };

    configurations.push(currencyRow);

    const performanceRow = {
      title: this.translate("page_builder_field_performance_label"),
      value: this.format.performance(value["performance"]),
    };

    configurations.push(performanceRow);

    const rebalanceRow = {
      title: this.translate("page_builder_field_rebalance_label"),
      value: this.format.rebalance(value["rebalance"]),
    };

    configurations.push(rebalanceRow);

    if (strategy.entity_type !== "SMART_BETA") {
      const holdingTresholdRow = {
        title: this.translate("page_builder_field_holdings_threshold"),
        value: this.format.holdings(value["holdings"]),
      };

      configurations.push(holdingTresholdRow);
    }

    return configurations;
  }

  private async sectionsUniverse(params: Strategy["params"]) {
    const configurations: { title: string | null; value: string | null }[] = [];
    const value = params["universe"];

    if (value["screening"] != null) {
      const instrumentTypeRow = {
        title: this.translate("page_builder_universe_label_instrument_type"),
        value: this.format.instrumentType({
          domestic: value["screening"]["whereSource"]["domestic"],
          foreign: value["screening"]["whereSource"]["foreign"],
          instrumentType: value["screening"]["instrumentType"],
          instrumentTypeSub:
            value["screening"]["whereSource"]["stockClassification"],
        }),
      };

      configurations.push(instrumentTypeRow);

      // ETF
      if (
        value["screening"]["whereTarget"] != null &&
        value["screening"]["whereTarget"]["market"].length > 0
      ) {
        const investmentRegionRow = {
          title: this.translate("common_investment_region"),
          value: this.format.where(value["screening"]["whereTarget"]["market"]),
        };

        configurations.push(investmentRegionRow);
      }

      if (value["screening"]["whereSource"]["market"].length > 0) {
        const marketRow = {
          title: this.translate("common_markets"),
          value: this.format.where(value["screening"]["whereSource"]["market"]),
        };

        configurations.push(marketRow);
      }

      if (value["screening"]["what"].length > 0) {
        const sectorRow = {
          title: this.translate("common_sectors"),
          value: this.format.what(value["screening"]["what"]),
        };

        configurations.push(sectorRow);
      }

      const eligibilityRow = {
        title: this.translate("page_builder_universe_label_eligibility"),
        value: this.format.eligibility(value["screening"]["eligibility"]),
      };

      configurations.push(eligibilityRow);
    }

    if (value["whiteList"] != null) {
      const whiteListRow = {
        title: this.translate("page_builder_universe_label_white_list"),
        value: "",
      };

      try {
        const response = await this.listsAPI.portfolioFetch(
          [value["whiteList"]["id"]],
          ["name", "ownerId"]
        );
        const listName = response?.[0]?.name ?? "";
        const userId = this.environment.account.user?.id;
        const ownerId = response?.[0]?.ownerId ?? null;
        const isReadOnly = ownerId !== userId;
        whiteListRow.value = this.format.strong(
          listName +
            (isReadOnly
              ? ' <span class="sharedObjectIndicator sharedObjectIndicator--small"></span>&nbsp;'
              : "")
        );
      } catch (error) {
        console.error(error);
        whiteListRow.value = "No benchmark info available";
      }

      configurations.push(whiteListRow);
    }

    if (value.selection != null && value.selection.length) {
      // Separate to render them as nested table
      const additionalRulesRowTitle = {
        title: this.translate("page_builder_universe_label_selection") + ":",
        value: null,
      };

      const additionalRulesRowValue = {
        value: this.sectionSelectionsRulesHelper(value.selection),
        title: null,
      };

      configurations.push(additionalRulesRowTitle);
      configurations.push(additionalRulesRowValue);
    } else {
      configurations.push({
        title: this.translate("page_builder_universe_label_selection") + ":",
        value: this.sectionSelectionsRulesHelper(null),
      });
    }

    return configurations;
  }

  private sectionsSelection(params) {
    const value = params["selection"];

    return [
      {
        value: this.sectionSelectionsRulesHelper(value) as string,
        title: null,
      },
    ];
  }

  private sectionsHolding(params) {
    const value = params["holding"];

    return [
      {
        value: this.sectionSelectionsRulesHelper(value) as string,
        title: null,
      },
    ];
  }

  private sectionsRanking(params) {
    const value = params["ranking"];
    const configurations: { title: string | null; value: string | null }[] = [];

    if (value != null) {
      const rows = this.format.ranking(value);

      for (const row of rows) {
        configurations.push({
          title: row[0],
          value: `<strong>${row[1]}</strong>`,
        });
      }
    } else {
      configurations.push({
        value: this.translate("common_not_set"),
        title: null,
      });
    }

    return configurations;
  }

  private sectionsWeighting(params) {
    const value = params["weighting"];
    const configurations: { title: string | null; value: string | null }[] = [];

    const weightingSchemaRow = {
      title: this.translate("page_builder_field_weighting_schema_label"),
      value: this.format.weightingSchema(value["weightingSchema"]),
    };

    configurations.push(weightingSchemaRow);

    const existingPositionsRow = {
      title: this.translate(
        "page_builder_field_weighting_schema_existing_positions_label"
      ),
      value: this.format.weightingSchemaExistingPositions(
        value["weightingSchemaExistingPositions"]
      ),
    };

    configurations.push(existingPositionsRow);

    if (value["smartBeta"] != null) {
      const smartBetaTitleRow = {
        title: this.translate("page_builder_field_smart_beta_label") + ":",
        value: null,
      };
      configurations.push(smartBetaTitleRow);
      const formatted = this.format.smartBeta(value["smartBeta"]);
      const tableRow: string[] = [];

      for (const rule of formatted) {
        tableRow.push(
          `<tr><td>${rule[0]}</td><td>${
            rule[1]
          }</td>${this.smartBetaThirdColumnHelper(rule[2])}</tr>`
        );
      }

      const table = `<table><tbody>${tableRow.join("")}</tbody></table>`;

      configurations.push({ value: table, title: null });
    }

    if (value["rotation"] != null) {
      const rotationRow = {
        title: this.translate("page_builder_field_rotation_label"),
        value: this.format.rotation(value["rotation"]),
      };

      configurations.push(rotationRow);
    }

    return configurations;
  }

  private sectionsAllocation(params) {
    const configurations: { title: string; value: string }[] = [];
    const value = params["allocation"];

    //#region
    const investmentMinRow = {
      title: this.translate("page_builder_field_cash_label_preview"),
      value: this.format.cash(value["weightInCash"]),
    };
    configurations.push(investmentMinRow);
    //#endregion

    //#region
    if (value["weightCappingSecurity"] != null) {
      const weightCappingSecurityRow = {
        title: "Constituents capping",
        value: this.format.cappingSecurity(value["weightCappingSecurity"]),
      };
      configurations.push(weightCappingSecurityRow);
    }
    //#endregion

    //#region
    if (value["weightCappingPeer"] != null) {
      const formattedPeer = this.format.cappingPeer(value["weightCappingPeer"]);

      const cappingPeerRow = {
        title: formattedPeer["label"],
        value: formattedPeer["value"],
      };

      configurations.push(cappingPeerRow);
    }
    //#endregion

    return configurations;
  }

  private sectionsBacktesting(params) {
    const value = params["backtesting"];
    const configuraitons: { title: string; value: string }[] = [];

    var formatted = this.format.period(value["period"]);

    const periodRow = {
      title: formatted["label"],
      value: formatted["value"],
    };

    configuraitons.push(periodRow);

    const inceptionDateRow = {
      title: this.translate("page_builder_field_inception_date_label"),
      value: this.format.inceptionDate(value["inceptionDate"]),
    };

    configuraitons.push(inceptionDateRow);

    const inceptionValueRow = {
      title: this.translate("page_builder_field_inception_value_label"),
      value: this.format.inceptionDate(value["inceptionValue"]),
    };

    configuraitons.push(inceptionValueRow);

    return configuraitons;
  }

  private async sectionsHedging(params) {
    const value = params["hedging"];
    const configurations: { title: string | null; value: string | null }[] = [];

    const emptyHedgingRow = {
      title: this.translate("page_builder_field_hedging_dynamic_label"),
      value: this.format.strong(this.translate("common_not_set")),
    };

    if (value != null) {
      const hedgingInstrumentRow = {
        title: this.translate("common_instrument"),
        value: "Cannot retrive info about the instrument",
      };

      try {
        const fetchProperties =
          widgetsConfiguration[
            "widgets/portfolio/analysis/overview/keyDataBenchmark"
          ]["properties"];

        const response = await this.instrumentAPI.fetch({
          properties: fetchProperties,
          symbols: [value["instrument"]],
          type: "security",
        });

        const instrument = response["data"][0];
        hedgingInstrumentRow["value"] = this.format.instrument(
          instrument["name"]
        );

        configurations.push(hedgingInstrumentRow);
      } catch (error) {
        console.error(error);
      }

      const leverageRow = {
        title: this.translate("page_builder_field_hedging_leverage_label"),
        value: this.format.leverage(value["leverage"]),
      };

      configurations.push(leverageRow);

      const hedgingRulesRow = {
        title: this.translate("page_builder_field_hedging_rules_label"),
        value: this.format.hedgingStrategy(value["constraints"]),
      };

      configurations.push(hedgingRulesRow);
    } else {
      configurations.push(emptyHedgingRow);
    }

    return configurations;
  }

  private smartBetaThirdColumnHelper(value) {
    if (typeof value === "object") {
      return (
        "<td>" +
        [
          '<strong class="rate rate--A">A</strong> ',
          value["A"],
          "<br/>",
          '<strong class="rate rate--B">B</strong> ',
          value["B"],
          "<br/>",
          '<strong class="rate rate--C">C</strong> ',
          value["C"],
          "<br/>",
          '<strong class="rate rate--D">D</strong> ',
          value["D"],
        ].join("") +
        "</td>"
      );
    } else {
      return `<td>${value}</td>`;
    }
  }

  private sectionSelectionsRulesHelper(value) {
    if (value != null) {
      const rows = this.format.selection(value);
      const rowsHTML: string[] = [];
      let row;

      for (let i = 0; i < rows.length; i++) {
        row = rows[i];
        rowsHTML.push(
          `<tr><td>${i + 1} - </td><td>${row[0]}</td><td><strong>${
            row[1]
          }</strong></td></tr>`
        );
      }

      return `<table><tbody>${rowsHTML.join("")}</tbody></table>`;
    } else {
      return this.translate("common_not_set");
    }
  }
}
