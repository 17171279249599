import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type SortingSelectType = {
  options: any[];
  value: any;
  onSortChange: (sort) => void;
};

const StyledSelect = styled((props: any) => (
  <Select
    value={props.state}
    fullWidth
    size="small"
    variant="outlined"
    classes={{ select: props.className }}
  >
    {props.children}
  </Select>
))({
  fontSize: "12px !important",
  paddingTop: "2px !important",
  paddingBottom: "2px !important",
});

const SortingSelect = ({ options, value, onSortChange }: SortingSelectType) => {
  const encodedValue = useMemo(() => {
    if (value == null) {
      return "currentSort";
    }
    const dir = value.descending === true ? "desc" : "asc";
    return `${value.property}_${dir}`;
  }, [value]);
  const [state, setState] = useState(encodedValue);
  useEffect(() => {
    setState(encodedValue);
  }, [encodedValue]);
  const preparedOptions = useMemo(() => {
    let arr: any[] = [{ label: "Current sort", value: "currentSort" }];
    let stringBuff: any = [];
    options.forEach((opt) => {
      Object.entries<any>(opt.sortBy).forEach(([key, value]) => {
        stringBuff[0] = opt.label;
        stringBuff[1] = value.label;

        arr.push({
          label: stringBuff.join(": "),
          value: `${opt.property}_${key}`,
        });
      });
      stringBuff = [];
    });

    return arr;
  }, [options]);
  const changeSort = useCallback(
    (val) => {
      setState(val);
      if (val === "currentSort") {
        onSortChange(null);
        return;
      }
      const [property, dir] = val.split("_");
      const descending = dir === "desc";
      const actPayload = { property, descending };

      onSortChange(actPayload);
    },
    [onSortChange]
  );
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box display={"flex"} flex={1} gap={1} alignItems={"center"}>
        <StyledSelect state={state}>
          {preparedOptions.map((option) => (
            <MenuItem
              key={uuidv4()}
              value={option.value}
              onClick={() => changeSort(option.value as any)}
            >
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
};

export default SortingSelect;
